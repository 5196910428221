import PropTypes from 'prop-types';
import { Toggle } from 'base-components';

const propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

const TitleToggle = ({
  value = undefined,
  onChange = undefined,
  name = undefined,
  title = 'showTitle',
  ...props
}) => (
  <Toggle
    title={title}
    name={name}
    label="Show Title"
    disableRipple
    value={value}
    onChange={onChange}
    {...props}
  />
);

const AutoRefreshToggle = ({
  value = undefined,
  onChange = undefined,
  name = undefined,
  title = 'autoRefresh',
  ...props
}) => (
  <Toggle
    title={title}
    name={name}
    label="Auto Refresh"
    disableRipple
    value={value}
    onChange={onChange}
    {...props}
  />
);

const LoopPlaybackToggle = ({
  value = undefined,
  onChange = undefined,
  name = undefined,
  title = 'loopPlayback',
  ...props
}) => (
  <Toggle
    title={title}
    name={name}
    label="Loop Playback"
    disableRipple
    value={value}
    onChange={onChange}
    {...props}
  />
);

const EnabledToggle = ({ ...props }) => (
  <Toggle label="Enabled" title="Enabled" disableRipple {...props} />
);
TitleToggle.propTypes = propTypes;

AutoRefreshToggle.propTypes = propTypes;

LoopPlaybackToggle.propTypes = propTypes;

EnabledToggle.propTypes = propTypes;

export { TitleToggle, AutoRefreshToggle, LoopPlaybackToggle, EnabledToggle };
