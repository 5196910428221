import { post } from 'util/WebRequest';

const SERVICE = 'FileUploadService';

class FileUploadService {
  static post(params) {
    const formData = new FormData();
    formData.append('uploadFile', params);
    return post(SERVICE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => response);
  }
}
export default FileUploadService;
