import { Controller, useFormContext } from 'react-hook-form';
import { DateTimePicker, type DateTimePickerProps } from 'base-components';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';

type FormDateTimePickerProps = FormFieldType<DateTimePickerProps>;

const FormDateTimePicker: React.FC<FormDateTimePickerProps> = ({
  name,
  rules,
  defaultValue,
  disabled,
  shouldUnregister,
  ...rest
}: FormDateTimePickerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      disabled={disabled}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <DateTimePicker
          {...rest}
          {...field}
          error={Boolean(fieldState.error)}
          required={rest?.required || Boolean(rules?.required)}
          helperText={fieldState.error?.message || ''}
        />
      )}
    />
  );
};

export default FormDateTimePicker;
