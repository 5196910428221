import { Component } from 'react';
import { Grid, Typography } from 'base-components';

import DateUtils from 'util/DateUtils';
import StatusIcon from '../StatusIcon';
import TestPropType from '../TestPropType';

class TestInformation extends Component {
  static propTypes = {
    test: TestPropType.isRequired,
  };

  renderItem = (label, value) => (
    <>
      <Grid item xs={4}>
        <Typography
          component="span"
          variant="caption"
          color="textPrimary"
          align="left"
        >
          <b>{label}</b>
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          component="span"
          variant="caption"
          color="textPrimary"
          align="right"
        >
          {value}
        </Typography>
      </Grid>
    </>
  );

  render() {
    const { test } = this.props;
    const runBy = test.user
      ? `${test.user.firstname} ${test.user.lastname}`
      : '';
    const startDate = test.startDate || '';
    const endDate = test.endDate || '';

    return (
      <Grid container spacing={1}>
        {this.renderItem(
          'Status',
          <StatusIcon justifyContent="flex-end" status={test.status} showText />
        )}
        {this.renderItem('Device Name', test.deviceName)}
        {this.renderItem('Device Id', test.deviceId)}
        {this.renderItem('Ip Address', test.ipAddress)}
        {this.renderItem('Duration', `${test.duration} minutes`)}
        {this.renderItem('Run By', runBy)}
        {this.renderItem(
          'Date Started',
          DateUtils.formatDateAsString(new Date(startDate))
        )}
        {this.renderItem(
          'Date Completed',
          DateUtils.formatDateAsString(new Date(endDate))
        )}
      </Grid>
    );
  }
}

export default TestInformation;
