import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
} from 'react';

type PipContextType = {
  isSupported: boolean;
  pipWindow: Window | null;
  requestPipWindow: (width: number, height: number) => Promise<void>;
  closePipWindow: () => void;
};

const PipContext = createContext<PipContextType | undefined>(undefined);

type PipProviderProps = {
  children: React.ReactNode;
};

export function PipProvider({ children }: PipProviderProps) {
  // Detect if the feature is available.
  const isSupported = 'documentPictureInPicture' in window;

  // Expose pipWindow that is currently active
  const [pipWindow, setPipWindow] = useState<Window | null>(null);

  // Close pipWidnow programmatically
  const closePipWindow = useCallback(() => {
    if (pipWindow != null) {
      pipWindow.close();
      setPipWindow(null);
    }
  }, [pipWindow]);

  // Open new pipWindow
  const requestPipWindow = useCallback(
    async (width: number, height: number) => {
      // We don't want to allow multiple requests.
      if (pipWindow != null) {
        return;
      }

      // @ts-expect-error - TypeScript does not know about this feature yet
      const pip = await window.documentPictureInPicture.requestWindow({
        width,
        height,
      });

      // Detect when window is closed by user
      pip.addEventListener('pagehide', () => {
        setPipWindow(null);
      });

      // It is important to copy all parent window styles. Otherwise, there would be no CSS available at all
      // https://developer.chrome.com/docs/web-platform/document-picture-in-picture/#copy-style-sheets-to-the-picture-in-picture-window
      [...document.styleSheets].forEach((styleSheet) => {
        try {
          const cssRules = [...styleSheet.cssRules]
            .map((rule) => rule.cssText)
            .join('');
          const style = document.createElement('style');

          style.textContent = cssRules;
          pip.document.head.appendChild(style);
        } catch (e) {
          const link = document.createElement('link');
          if (styleSheet.href == null) {
            return;
          }

          link.rel = 'stylesheet';
          link.type = styleSheet.type;
          link.media = styleSheet.media.toString();
          link.href = styleSheet.href;
          pip.document.head.appendChild(link);
        }
      });

      setPipWindow(pip);
    },
    [pipWindow]
  );

  const value = useMemo(
    () => ({
      isSupported,
      pipWindow,
      requestPipWindow,
      closePipWindow,
    }),
    [closePipWindow, isSupported, pipWindow, requestPipWindow]
  );

  return <PipContext.Provider value={value}>{children}</PipContext.Provider>;
}

export default function usePipWindow(): PipContextType {
  const context = useContext(PipContext);

  if (context === undefined) {
    throw new Error('usePipWindow must be used within a PipContext');
  }

  return context;
}
