import { PureComponent } from 'react';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';

import { DenseThemeProvider } from '@onc/theme';
import { Typography } from 'base-components';
import UserDetailsService from 'domain/services/UserDetailsService';
import SeaTube from './SeaTube';
import SeaTubeSearchTreeNode from './SeaTubeSearchTreeNode';
import SeaTubeResourceTypes from './util/SeaTubeResourceTypes';

const styles = () => ({
  loadingContainer: {
    height: `100%`,
    width: `100%`,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
});
class SeaTubeContainer extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    seatubeSearch: PropTypes.string,
  };

  static defaultProps = {
    classes: undefined,
    seatubeSearch: '/SeaTubeSearch',
  };

  constructor(props) {
    super(props);
    // Parse URL params
    const parsedLocation = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const resourceTypeId = parsedLocation.resourceTypeId
      ? parseInt(parsedLocation.resourceTypeId, 10)
      : undefined;
    const resourceId = parsedLocation.resourceId
      ? parseInt(parsedLocation.resourceId, 10)
      : undefined;

    const time = parsedLocation.time ? moment(parsedLocation.time) : undefined;
    const currentTimestamp = parsedLocation.currentTimestamp
      ? moment(parsedLocation.currentTimestamp)
      : undefined;
    const playlistLineId = parsedLocation.playlistLineId
      ? parseInt(parsedLocation.playlistLineId, 10)
      : undefined;
    const annotationId = parsedLocation.annotationId
      ? parseInt(parsedLocation.annotationId, 10)
      : undefined;
    this.state = {
      resourceTypeId,
      resourceId,
      time,
      currentTimestamp,
      playlistLineId,
      annotationId,
      isLoggedIn: undefined,
      currentUserId: 0,
    };
  }

  componentDidMount() {
    this.checkAuthenticated();
  }

  checkAuthenticated = async () => {
    const dmasUserId = await UserDetailsService.getUserId();

    this.setState({ isLoggedIn: dmasUserId > 0, currentUserId: dmasUserId });
  };

  renderSeaTube = () => {
    const {
      resourceTypeId,
      resourceId,
      currentTimestamp,
      playlistLineId,
      time,
      annotationId,
      isLoggedIn,
      currentUserId,
    } = this.state;
    const { classes, seatubeSearch } = this.props;
    if (isLoggedIn === undefined) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} size={80} />
        </div>
      );
    }

    // the linter doesn't like declarations in switch cases
    let dest = 'app/playlists';

    switch (resourceTypeId) {
      case SeaTubeResourceTypes.DIVE:
        return (
          <SeaTube
            diveId={Number(resourceId)}
            currentTimestamp={currentTimestamp}
            time={time}
            isLoggedIn={isLoggedIn}
            currentUserId={currentUserId}
            annotationId={annotationId}
            seatubeSearch={seatubeSearch}
          />
        );
      case SeaTubeResourceTypes.PLAYLIST:
        if (resourceId) {
          dest += `/${resourceId}`;

          if (playlistLineId) {
            dest += `?clipId=${playlistLineId}`;
          }
        }

        window.open(dest, '_self');
        return <></>;

      case SeaTubeResourceTypes.SEARCH_TREE_NODE:
        return (
          <SeaTubeSearchTreeNode
            searchTreeNodeId={Number(resourceId)}
            currentTimestamp={currentTimestamp}
            time={time}
            isLoggedIn={isLoggedIn}
            currentUserId={currentUserId}
            seatubeSearch={seatubeSearch}
          />
        );

      default:
        return (
          <span>
            <Typography>Invalid resourceTypeId provided.</Typography>
          </span>
        );
    }
  };

  render() {
    return <DenseThemeProvider>{this.renderSeaTube()}</DenseThemeProvider>;
  }
}

export default withStyles(styles)(SeaTubeContainer);
