import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import {
  RefreshButton,
  EditIconButton,
} from 'domain/AppComponents/IconButtons';
import DeviceDetailsLink from 'domain/AppComponents/link/DeviceDetailsLink';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import { FloatRightButtonLowStyles } from 'library/CompositeComponents/button/CommonButtonStyles';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';

import DriverScheduleConfig from './DriverScheduleConfig';
import DriverScheduleService from './DriverScheduleService';

const styles = {
  button: FloatRightButtonLowStyles,
};

const headers = [
  { title: ' ', name: 'icons' },
  { title: 'ID', name: 'driverScheduleId' },
  { title: 'Description', name: 'description' },
  { title: 'Device ID', name: 'deviceId' },
  { title: 'Device ID For Search', name: 'hiddenDeviceIdForSearch' },
  { title: 'Enabled', name: 'enabled' },
  { title: 'Interface Name', name: 'interfaceName' },
  { title: 'Method Name', name: 'methodName' },
  { title: 'Schedule Year', name: 'scheduleYear' },
  { title: 'Schedule Month', name: 'scheduleMonth' },
  { title: 'Schedule Day of Month', name: 'scheduleDayOfMonth' },
  { title: 'Schedule Hour', name: 'scheduleHour' },
  { title: 'Schedule Minute', name: 'scheduleMinute' },
  { title: 'Schedule Second', name: 'scheduleSecond' },
  { title: 'Repeat Day', name: 'repeatDay' },
  { title: 'Repeat Hour', name: 'repeatHour' },
  { title: 'Repeat Minute', name: 'repeatMinute' },
  { title: 'Repeat Second', name: 'repeatSecond' },
  { title: 'Param1', name: 'param1' },
  { title: 'Param2', name: 'param2' },
  { title: 'Param3', name: 'param3' },
  { title: 'Param4', name: 'param4' },
  { title: 'Param5', name: 'param5' },
  { title: 'End Date', name: 'endDate' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
];

const tableColumnExtensions = [
  {
    columnName: 'icons',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'driverScheduleId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'deviceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'hiddenDeviceIdForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'enabled',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'interfaceName',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'methodName',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'scheduleYear',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'scheduleMonth',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'scheduleDayOfMonth',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'scheduleHour',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'scheduleMinute',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'scheduleSecond',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'repeatDay',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'repeatHour',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'repeatMinute',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'repeatSecond',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'param1',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'param2',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'param3',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'param4',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'param5',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'endDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
];

class DriverScheduleTable extends PureComponent {
  columnSizeInfo = [
    { columnName: 'icons', width: ColumnInfo.mini },
    { columnName: 'driverScheduleId', width: ColumnInfo.mini },
    { columnName: 'description', width: ColumnInfo.mini },
    { columnName: 'deviceId', width: ColumnInfo.mini },
    { columnName: 'hiddenDeviceIdForSearch', width: ColumnInfo.mini },
    { columnName: 'enabled', width: ColumnInfo.mini },
    { columnName: 'interfaceName', width: ColumnInfo.mini },
    { columnName: 'methodName', width: ColumnInfo.mini },
    { columnName: 'scheduleYear', width: ColumnInfo.mini },
    { columnName: 'scheduleMonth', width: ColumnInfo.mini },
    { columnName: 'scheduleDayOfMonth', width: ColumnInfo.mini },
    { columnName: 'scheduleHour', width: ColumnInfo.mini },
    { columnName: 'scheduleMinute', width: ColumnInfo.mini },
    { columnName: 'scheduleSecond', width: ColumnInfo.mini },
    { columnName: 'repeatDay', width: ColumnInfo.mini },
    { columnName: 'repeatHour', width: ColumnInfo.mini },
    { columnName: 'repeatMinute', width: ColumnInfo.mini },
    { columnName: 'repeatSecond', width: ColumnInfo.mini },
    { columnName: 'param1', width: ColumnInfo.mini },
    { columnName: 'param2', width: ColumnInfo.mini },
    { columnName: 'param3', width: ColumnInfo.mini },
    { columnName: 'param4', width: ColumnInfo.mini },
    { columnName: 'param5', width: ColumnInfo.mini },
    { columnName: 'endDate', width: ColumnInfo.mini },
    { columnName: 'modifyBy', width: ColumnInfo.medium },
    { columnName: 'modifyDate', width: ColumnInfo.medium },
  ];

  disabledSort = [{ columnName: 'icons', sortingEnabled: false }];

  driverScheduleMap = [];

  static propTypes = {
    permission: PropTypes.string.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      rowToBeEdited: undefined,
      openCreateDialog: false,
    };
  }

  async componentDidMount() {
    const { onError } = this.props;
    this.driverScheduleMap = await DriverScheduleService.getAll()
      .then((result) => result)
      .catch((result) => {
        onError(result);
      });
    this.refreshTableData();
  }

  titleContent = () => <Typography variant="h6">Driver Schedule</Typography>;

  actionContent = () => {
    const { permission, classes } = this.props;
    const renderActionRefreshButton = () => (
      <RefreshButton onClick={this.refresh} />
    );
    if (permission !== 'RW') {
      return renderActionRefreshButton();
    }
    return (
      <>
        {renderActionRefreshButton()}
        <ContainedButton
          translationKey="device.addDriverSchedule"
          startIcon={<Add />}
          className={classes.button}
          onClick={this.handleAddRole}
          TooltipProps={{ title: 'Add Driver Schedule' }}
        />
      </>
    );
  };

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError } = this.props;
    DriverScheduleService.getAll()
      .then((result) => {
        this.buildTableRows(result, this.driverScheduleMap);
      })
      .catch((result) => {
        onError(result);
      });
  };

  icons = (row) => {
    const { permission } = this.props;
    if (permission !== 'RW') {
      return <></>;
    }

    return (
      <>
        <EditIconButton
          onClick={() => {
            this.setState({
              openCreateDialog: true,
              rowToBeEdited: row,
            });
          }}
          aria-label="Edit Driver Schedule"
          size="small"
        />
      </>
    );
  };

  addEventDialogue = () => {
    const { openCreateDialog, rowToBeEdited } = this.state;
    const { permission } = this.props;
    if (!openCreateDialog) {
      return <></>;
    }
    return (
      <DriverScheduleConfig
        permission={permission}
        title={rowToBeEdited ? 'Edit Driver Schedule' : 'Add Driver Schedule'}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        driverSchedule={rowToBeEdited}
      />
    );
  };

  // open dialog to add new resource role
  handleAddRole = () =>
    this.setState({ openCreateDialog: true, rowToBeEdited: undefined });

  // cancel add/edit and close dialog
  handleCancel = () =>
    this.setState({
      openCreateDialog: false,
      rowToBeEdited: undefined,
    });

  handleSave = async (form) => {
    const { onError, onInfo } = this.props;
    const { rowToBeEdited } = this.state;
    const updatedInfo = form;
    // add all row data that is missing from form back into the post
    if (rowToBeEdited) {
      updatedInfo.driverScheduleId = rowToBeEdited.driverScheduleId;

      await DriverScheduleService.update(updatedInfo)
        .then((response) => {
          this.setState({ openCreateDialog: false });
          if (response.data.statusCode === 0) {
            onInfo('Saved Successfully');
          } else if (response.data.statusCode === 9001) {
            onError(response.data.message);
          } else if (response.data.statusCode === 101) {
            onError(`Device ID doesn't exist in database`);
          } else {
            onError(
              `Failed to save with error code: ${response.data.statusCode}`
            );
          }
        })
        .then(() => {
          this.refresh();
        })
        .catch((response) => {
          onError(response.message);
        });
    } else {
      await DriverScheduleService.create(updatedInfo)
        .then((response) => {
          this.setState({ openCreateDialog: false });
          if (response.data.statusCode === 0) {
            onInfo('Saved Successfully');
          } else if (response.data.statusCode === 9001) {
            onError(response.data.message);
          } else if (response.data.statusCode === 101) {
            onError(`Device ID doesn't exist in database`);
          } else {
            onError(
              `Failed to save with error code: ${response.data.statusCode}`
            );
          }
        })
        .then(() => {
          this.refresh();
        })
        .catch((response) => {
          onError(response.message);
        });
    }
  };

  compareDeviceId = (a, b) => {
    const idA = a.props.deviceId;
    const idB = b.props.deviceId;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  buildTableRows(driverSchedules) {
    this.setState({
      tableRows: driverSchedules.map((driverSchedule) => {
        const driverScheduleWithIcons = {
          ...driverSchedule,
          deviceId: <DeviceDetailsLink deviceId={driverSchedule.deviceId} />,
          hiddenDeviceIdForSearch: driverSchedule.deviceId,
        };
        if (driverScheduleWithIcons.enabled) {
          driverScheduleWithIcons.enabled = 'True';
        } else {
          driverScheduleWithIcons.enabled = 'False';
        }
        driverScheduleWithIcons.modifyDate = DateFormatUtils.formatDate(
          driverSchedule.modifyDate,
          'full'
        );
        if (driverScheduleWithIcons.endDate != null) {
          driverScheduleWithIcons.endDate = DateFormatUtils.formatDate(
            driverSchedule.endDate,
            'full'
          );
        }
        driverScheduleWithIcons.icons = this.icons(driverScheduleWithIcons);
        return driverScheduleWithIcons;
      }),
    });
  }

  render() {
    const { permission } = this.props;

    const { tableRows } = this.state;
    if (!tableRows) return undefined;

    return (
      <>
        {this.addEventDialogue()}
        <Panel title={this.titleContent()} actionContent={this.actionContent()}>
          <SortableTable
            searchBarMoveable={permission === 'RW'}
            pageSize={50}
            searchable
            columnSizes={this.columnSizeInfo}
            columnExtensions={tableColumnExtensions}
            elevation={0}
            stripedRows
            columns={headers}
            rows={tableRows}
            sortExtensions={[
              {
                columnName: 'deviceId',
                compare: this.compareDeviceId,
              },
            ]}
            disabledSort={this.disabledSort}
            hiddenColumns={['hiddenDeviceIdForSearch']}
          />
        </Panel>
      </>
    );
  }
}
export default withStyles(styles)(DriverScheduleTable);
