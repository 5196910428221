/* eslint-disable max-classes-per-file */
import { type QuickButtonAttribute } from 'base-components';
import { Attribute } from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import { post, get, patch, deleteImpl } from 'util/WebRequest';

export class MatrixAttribute {
  attributeId = -1;

  excluded = false;

  groupName = '';

  inherited = false;

  name = '';

  value = '';

  taxonomyMatrixAttributeId = 0;

  constructor(payload: any) {
    Object.assign(this, payload);
  }

  // Converting this to a form attribute
  toQuickButtonAttribute = (): QuickButtonAttribute => ({
    taxonomyAttributeId: this.attributeId,
    attributeValue: this.value === 'null' ? null : this.value,

    // Values that don't map...
    // We'll need to compare against a list of attribute Options to get the
    // proper values for these
    dataType: 'String',
    taxonomyAttributeLineId: -1,
    modifyBy: {},
    modifyDate: 'unknown',
    taxonButtonSetLineId: -1,
  });

  // Converting this to a form attribute
  toAttribute = (): Attribute => ({
    attributeId: this.attributeId,
    name: this.name,
    value: this.value === 'null' ? null : this.value,
    groupName: this.groupName,

    // Values that don't map...
    // We'll need to compare against a list of attribute Options to get the
    // proper values for these
    dataType: 'String',
    groupId: -1,
    taxonomyAttributeLineId: -1,
  });
}

export class TaxonMatrixAttributes {
  inheritedAttributes: MatrixAttribute[] = [];

  inheritedOverrideableAttributes: MatrixAttribute[] = [];

  localAttributes: MatrixAttribute[] = [];

  overrideableAttributes: MatrixAttribute[] = [];

  constructor(payload: any) {
    Object.assign(this, payload);
  }

  getCurrentAttributes = (withExcludedAttributes = false) => {
    const {
      inheritedAttributes,
      inheritedOverrideableAttributes,
      localAttributes,
      overrideableAttributes,
    } = this;
    const attributeMap = new Map<number, MatrixAttribute>();

    // For inherited attributes, only consider the first instance since it is assumed
    // the array is ordered by taxon hierarchy

    inheritedAttributes.forEach((attribute) => {
      if (withExcludedAttributes || !attributeMap.has(attribute.attributeId)) {
        attributeMap.set(attribute.attributeId, attribute);
      }
    });

    inheritedOverrideableAttributes.forEach((attribute) => {
      if (
        (!attributeMap.has(attribute.attributeId) && !withExcludedAttributes) ||
        !attribute.excluded
      ) {
        attributeMap.set(attribute.attributeId, attribute);
      }
    });

    // For local attributes, they should always override whatever is inherited

    overrideableAttributes.forEach((attribute) => {
      attributeMap.set(attribute.attributeId, attribute);
    });

    localAttributes.forEach((attribute) => {
      const attrCopy = { ...attribute };
      if (
        (withExcludedAttributes && attributeMap.has(attribute.attributeId)) ||
        attrCopy.excluded
      ) {
        attrCopy.inherited = true;
      }
      attributeMap.set(attribute.attributeId, attrCopy);
    });

    // Convert to an array and filter out excluded attributes
    const matrixAttributeArray: MatrixAttribute[] = !withExcludedAttributes
      ? Array.from(attributeMap, ([, val]) => new MatrixAttribute(val)).filter(
          (attr) => !attr.excluded
        )
      : Array.from(attributeMap, ([, val]) => new MatrixAttribute(val));

    return matrixAttributeArray;
  };
}

class TaxonAttributeService {
  static getAttributes = async (
    taxonomyId: number,
    taxonId: number
  ): Promise<TaxonMatrixAttributes> => {
    const queryPath =
      isNaN(taxonId) || taxonId === null
        ? `internal/taxonomies/${taxonomyId}/taxons/attributes`
        : `internal/taxonomies/${taxonomyId}/taxons/${taxonId}/attributes`;
    const response = await get(queryPath);
    return new TaxonMatrixAttributes(response.data);
  };

  static async create(
    taxonId: number,
    taxonomyId: number,
    attributeId: number,
    defaultAttributeValue: string,
    isExcluded: boolean
  ) {
    const response = await post(
      `internal/taxonomies/${taxonomyId}/taxons/${taxonId}/attributes`,
      {
        attributeId,
        defaultAttributeValue,
        isExcluded,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async update(
    taxonomyMatrixAttributeId: number,
    taxonomyMatrixId: number,
    attributeId: number,
    defaultAttributeValue: string,
    isExcluded: boolean
  ) {
    const response = await patch(
      `internal/taxonomies/taxons/attributes/${taxonomyMatrixAttributeId}`,
      {
        taxonomyMatrixId,
        attributeId,
        defaultAttributeValue,
        isExcluded,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async delete(taxonomyMatrixAttributeId: number) {
    const response = await deleteImpl(
      `internal/taxonomies/taxons/attributes/${taxonomyMatrixAttributeId}`
    );
    return response.data;
  }
}

export default TaxonAttributeService;
