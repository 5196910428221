import moment from 'moment';

import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

const GET_CAMPAIGN = 7;
const CREATE_CAMPAIGN = 1;
const UPDATE_CAMPAIGN = 2;

const DigitalFishersService = {
  getCampaign: async (campaignId) => {
    const response = await get('DigitalFishersService', {
      operation: GET_CAMPAIGN,
      dfCampaignId: campaignId,
    });
    const payload = parseDmasAPIResponse(response);
    const { playList, ...rest } = payload.campaign;
    return {
      ...rest,
      startDate: moment(payload.campaign.startDate, moment.ISO_8601).toDate(),
      endDate: moment(payload.campaign.endDate, moment.ISO_8601).toDate(),
    };
  },

  createCampaign: async (campaign) => {
    const response = await post('DigitalFishersService', {
      operation: CREATE_CAMPAIGN,
      campaign: JSON.stringify(campaign),
    });
    const payload = parseDmasAPIResponse(response);
    return {
      ...payload.campaign,
      startDate: moment(payload.campaign.startDate, moment.ISO_8601).toDate(),
      endDate: moment(payload.campaign.endDate, moment.ISO_8601).toDate(),
    };
  },

  updateCampaign: async (campaign) => {
    const response = await post('DigitalFishersService', {
      operation: UPDATE_CAMPAIGN,
      campaign: JSON.stringify(campaign),
    });
    const payload = parseDmasAPIResponse(response);
    return {
      ...payload.campaign,
      startDate: moment(payload.campaign.startDate, moment.ISO_8601).toDate(),
      endDate: moment(payload.campaign.endDate, moment.ISO_8601).toDate(),
    };
  },
};

export default DigitalFishersService;
