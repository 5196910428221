/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useState, useEffect } from 'react';

import * as React from 'react';
import { TranslationType } from '@onc/i18n';
import { ExpandMore } from '@onc/icons';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from 'base-components';
import { FilterBase, FilterEvent, FilterValue } from './Filter';
import { ContainedButton, TextButton } from '../button/Buttons';

const styles = {
  summary: {
    marginLeft: 'auto',
    marginRight: 1,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    marginLeft: 1,
  },
  actions: {
    marginLeft: 'auto',
  },
};

interface FilterGroupProps extends FilterBase {
  /** Text to use on the submit button. Default of "submit" */
  submitTranslationKey?: TranslationType;
  /** Callback for when the Submit button is clicked */
  onSubmit?: (event: FilterEvent) => void;
  /** Title for this FilterGroup */
  title?: string;
  children: ReactNode;
  /** Name to target in events. Should be unique for all filters */
  name: string;
  /** Icon to prepend to the title */
  icon?: ReactNode;
  /**
   * Actions should be small buttons or other clickable things. They will be
   * added to the under the title in the AccordianSummary section
   */
  actions?: ReactNode;
  /** Initial value of this filter group */
  initialValue?: FilterValue;
  /** Determines whether or not the AccordianSummary renders */
  includeSummaryBar?: boolean;
  /** For example adding an export button beside the filter and reset button */
  extraButtons?: ReactNode;
}

const FilterGroup: React.FC<FilterGroupProps> = ({
  children,
  title = '',
  icon = undefined,
  name,
  collapsible = true,
  expanded,
  submitTranslationKey = undefined,
  onReset,
  onChange,
  onSubmit = undefined,
  value,
  initialValue = undefined,
  actions = undefined,
  includeSummaryBar = true,
  extraButtons = undefined,
}: FilterGroupProps) => {
  const [valueState, setValueState] = useState<FilterValue | undefined>(
    initialValue
  );

  // Default to expanded unless being controlled by the expanded prop
  const [expandedState, setExpandedState] = useState<boolean | undefined>(
    expanded === undefined ? collapsible : true
  );

  // onChange handler for Filters
  const onFilterChange = (event: any) => {
    const eventValue = event.target.value;
    const filterName = event.target.name;

    setValueState({ ...valueState, [filterName]: eventValue });
    if (onChange) {
      onChange({
        target: { name, value: { ...valueState, [filterName]: eventValue } },
      });
    }
  };

  // Expanded state changed (if component expansion is controlled)
  useEffect(
    () => (expanded !== undefined ? setExpandedState(expanded) : undefined),
    [expanded]
  );

  // Set the valueState if component is controlled
  useEffect(() => {
    value ? setValueState(value) : setValueState(initialValue);
  }, [value]);

  // Only render ResetButton if onReset is defined
  const renderResetButton = () => {
    if (onReset) {
      return (
        <TextButton
          translationKey="common.buttons.reset"
          data-label="filterGroupReset"
          onClick={(event) => onReset(event)}
        />
      );
    }
    return undefined;
  };

  // Recursively looks for Filter children and updates their props
  const renderChildren = (childElements: ReactNode): any[] | null | undefined =>
    React.Children.map(childElements, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }
      const elementChild: React.ReactElement = child;
      const grandChildren = elementChild.props.children;
      const childName = elementChild.props.name;
      if (childName) {
        return React.cloneElement(elementChild, {
          onChange: onFilterChange,
          filter: valueState,
          value: valueState ? valueState[childName] : undefined,
        });
      }
      if (grandChildren) {
        return React.cloneElement(elementChild, {
          children: renderChildren(grandChildren),
        });
      }
      return elementChild;
    });

  // Renders a "submit" button with configurable text using the submitText prop
  const renderSubmit = () => {
    if (onSubmit) {
      return (
        <ContainedButton
          translationKey={submitTranslationKey || 'common.buttons.submit'}
          type="submit"
          onClick={() => onSubmit({ target: { name, value: valueState } })}
        />
      );
    }
    return undefined;
  };

  return (
    <Accordion
      onChange={() => setExpandedState(!expandedState)}
      expanded={expandedState}
      aria-label={title}
      role="search"
    >
      {includeSummaryBar && (
        <AccordionSummary
          expandIcon={collapsible ? <ExpandMore name="expand" /> : undefined}
          aria-label={`${title} header`}
        >
          <Box sx={styles.flexContainer}>
            {icon}
            <Typography sx={styles.title}>{title}</Typography>
            <Box sx={styles.actions}>{actions}</Box>
          </Box>
        </AccordionSummary>
      )}
      <AccordionDetails style={{ display: 'block' }}>
        {renderChildren(children)}
      </AccordionDetails>
      <AccordionActions>
        {renderResetButton()}
        {extraButtons}
        {renderSubmit()}
      </AccordionActions>
    </Accordion>
  );
};

export default FilterGroup;
