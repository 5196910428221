import * as React from 'react';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { AddIconButton } from 'domain/AppComponents/IconButtons';

interface Props {
  onCreate: () => void;
}

const pluginDependencies = [{ name: 'Toolbar' }];

const AddButtonPlugin: React.VFC<Props> = ({ onCreate }: Props) => (
  <Plugin dependencies={pluginDependencies}>
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <TemplateConnector>
        {() => <AddIconButton onClick={onCreate} style={{ width: '48px' }} />}
      </TemplateConnector>
    </Template>
  </Plugin>
);

export default AddButtonPlugin;
