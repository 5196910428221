import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import QaqcAutotestsService from 'domain/services/QaqcAutotestsService';

class QaqcFinderStationDropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    stations: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getStations();
  }

  getStations = () => {
    QaqcAutotestsService.getQAQCStations()
      .then((results) => {
        const stations = results.map((station) => ({
          label: station.searchTreeNodeName,
          value: station.searchTreeNodeId,
        }));
        this.setState({
          stations: [{ label: 'Find All', value: 0 }, ...stations],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const { disabled, initialValue, value, onChange, ...rest } = this.props;
    const { stations } = this.state;
    return (
      <Dropdown
        disabled={disabled}
        initialValue={initialValue}
        label="Station"
        options={stations}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }
}
export default QaqcFinderStationDropdown;
