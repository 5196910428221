import { memo } from 'react';
import * as React from 'react';
import MuiTextareaAutosize from '@mui/material/TextareaAutosize';

interface Props {
  defaultValue?: string;
  style?: React.CSSProperties;
  onChange?: () => void;

  // Material UI props.
  // See https://mui.com/api/textarea-autosize/
  maxRows?: number | string; // required for a vertical scrollbar to appear
  minRows?: number | string;

  // A selection of html props.
  // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
  widthByNumCharacters?: number; // can be used as an alternative to CSS width
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string; // the placeholder text if there's no value
  wrap?: 'hard' | 'soft' | 'off'; // note that 'off' is non-standard
}

const TextAreaAutosize: React.VFC<Props> = ({
  defaultValue = '',
  style = undefined,
  maxRows = undefined,
  minRows = 5, // vertical scrollbar doesn't look good at lower values
  widthByNumCharacters = undefined,
  disabled = false,
  readOnly = false,
  placeholder = '',
  wrap = 'soft', // 'soft' is also the default if unspecified
  onChange = () => {},
}) => (
  <MuiTextareaAutosize
    defaultValue={defaultValue}
    maxRows={maxRows}
    minRows={minRows}
    cols={widthByNumCharacters}
    disabled={disabled}
    readOnly={readOnly}
    placeholder={placeholder}
    style={style}
    onChange={onChange}
    wrap={wrap}
  />
);

export default memo(TextAreaAutosize);
