import { patch, post, get } from 'util/WebRequest';
import { DmasUser } from './UserDetailsService';

export interface ConnectorType {
  connectorTypeId?: number;
  name: string;
  description: string;
  modifyBy?: DmasUser;
  modifyDate?: string;
}

class ConnectorTypeService {
  static get = async (connectorTypeId: number): Promise<ConnectorType> => {
    const response = await get(
      `/internal/cables/connectors/types/${connectorTypeId}`
    );
    return response.data;
  };

  static getAll = async (): Promise<ConnectorType[]> => {
    const response = await get('/internal/cables/connectors/types');
    return response.data;
  };

  static createConnectorType = async (
    params: ConnectorType
  ): Promise<ConnectorType> => {
    const response = await post('/internal/cables/connectors/types', params, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  };

  static updateConnectorType = async (
    params: ConnectorType
  ): Promise<ConnectorType> => {
    const response = await patch(
      `/internal/cables/connectors/types/${params.connectorTypeId}`,
      params,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  };
}

export default ConnectorTypeService;
