import { useState } from 'react';
import * as React from 'react';
import { Grid } from '@mui/material';
import { Autocomplete, TextField } from 'base-components';
import { useCreateNewBatchMutation } from 'domain/AppComponents/batch/hooks/BatchTaskMutationHooks';
import {
  usePAMTaskDetails,
  useBatchTaskTypeAutocomplete,
} from 'domain/AppComponents/batch/hooks/BatchTaskStateHooks';
import {
  BATCH_TASK_TYPES,
  BATCH_TASK_TYPES_PAMGUARD_ONLY,
  DOI,
  PAMGUARD,
} from 'domain/AppComponents/batch/util/BatchUtils';
import { FormDialog } from 'domain/AppComponents/dialogs/FormDialog';
import BatchTaskDetailTextFields from './BatchTaskDetailTextFields';

interface BatchDialogProps {
  onClose: () => void;
  open?: boolean;
}

const BatchAddDialog = ({ onClose, open = false }: BatchDialogProps) => {
  const [name, setName] = useState('');
  const [reason, setReason] = useState('');
  const {
    taskType,
    setTaskType,
    taskTypeInput,
    setTaskTypeInput,
    pamguardPrivilege,
  } = useBatchTaskTypeAutocomplete();
  const { taskDetails, setTaskDetails } = usePAMTaskDetails();

  const createNewBatchMutation = useCreateNewBatchMutation();

  return (
    <FormDialog
      open={open}
      title="Add Batch"
      onClose={() => onClose()}
      confirmButtonTranslationKey="taskmanagement.addBatch"
      onSubmit={() => {
        createNewBatchMutation.mutate({
          taskType: taskType ?? '',
          name,
          reason,
          taskDetails,
        });
        onClose();
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            name="Batch Type"
            translationKey="taskmanagement.batchType"
            value={taskType}
            onChange={(event, newValue) => {
              setTaskType(newValue);
            }}
            inputValue={taskTypeInput}
            onInputChange={(event, newValue) => {
              setTaskTypeInput(newValue);
            }}
            id="batch-dialog-autocomplete"
            options={
              pamguardPrivilege === 'RW'
                ? BATCH_TASK_TYPES_PAMGUARD_ONLY
                : BATCH_TASK_TYPES
            }
            getOptionLabel={(label: string) => label}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="Batch Name"
            translationKey="taskmanagement.batchName"
            value={name}
            onChange={(event: React.BaseSyntheticEvent) =>
              setName(event.target.value)
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required={taskType === DOI}
            name="Reason"
            translationKey="common.textfields.reason"
            value={reason}
            onChange={(event: React.BaseSyntheticEvent) =>
              setReason(event.target.value)
            }
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
        {taskType === PAMGUARD ? (
          <BatchTaskDetailTextFields
            taskDetails={taskDetails}
            onTextFieldChange={(newTaskDefinitionDetails) =>
              setTaskDetails(newTaskDefinitionDetails)
            }
          />
        ) : null}
      </Grid>
    </FormDialog>
  );
};
export default BatchAddDialog;
