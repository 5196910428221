import { Grid, TextField } from 'base-components';

const BatchTaskDetailTextFields = (props) => {
  const { taskDetails, onTextFieldChange, ...rest } = props;

  const taskDetailTextFieldChange = (event) => {
    const newTaskDetails = taskDetails.map((detail) => {
      if (detail.key === event.target.name) {
        const newDetail = detail;
        newDetail.value = event.target.value;
        return newDetail;
      }

      return detail;
    });

    onTextFieldChange(newTaskDetails);
  };

  const renderTaskDetails = () => {
    if (taskDetails) {
      taskDetails.sort((a, b) => (a.key > b.key ? 1 : -1));
      return taskDetails.map((taskDetail) => {
        if (taskDetail.key.toLowerCase().includes('password')) {
          return (
            <Grid item xs={12} key={taskDetail.key}>
              <TextField
                name={taskDetail.key}
                translationKey="common.textfields.variable"
                translationOptions={{ variableName: taskDetail.key }}
                value={taskDetail.value}
                onChange={taskDetailTextFieldChange}
                fullWidth
                type="password"
                {...rest}
              />
            </Grid>
          );
        }
        return (
          <Grid item xs={12} key={taskDetail.key}>
            <TextField
              name={taskDetail.key}
              translationKey="common.textfields.variable"
              translationOptions={{ variableName: taskDetail.key }}
              value={taskDetail.value}
              onChange={taskDetailTextFieldChange}
              fullWidth
              {...rest}
            />
          </Grid>
        );
      });
    }

    return null;
  };

  return renderTaskDetails();
};

export default BatchTaskDetailTextFields;
