import { useState, useEffect } from 'react';
import * as React from 'react';
import { Add } from '@onc/icons';
import {
  AnchoredHiddenMenu,
  CloseableTab,
  Divider,
  IconButton,
  MenuItem,
  Tabs,
  TabContext,
  Box,
} from 'base-components';

const styles = {
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
  },
  addTabButton: {
    marginLeft: 1,
  },
};

export interface TabData {
  label: string;
  id: string; // Need to be a string since that's what TabContext requires
  value: any;
}

type Props = {
  currentTab: number;
  onChangeTab: (index: number, tab?: TabData) => void;
  onAddTab?: (tab: TabData) => void;
  onRemoveTab?: (index: number) => void;
  options: TabData[];
  selectedTabs?: TabData[];
  children: any[];
};

const ConfigurableTabs: React.VFC<Props> = ({
  currentTab,
  options,
  selectedTabs = undefined,
  onChangeTab,
  onAddTab = () => {},
  onRemoveTab = () => {},
  children,
}: Props) => {
  const [selectedTabsState, setSelectedTabsState] = useState<TabData[]>(
    selectedTabs || []
  );
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedTabs) {
      setSelectedTabsState(selectedTabs);
    }
  }, [selectedTabs]);

  useEffect(() => {
    if (selectedTabsState[currentTab]) {
      setSelectedId(selectedTabsState[currentTab].id);
    } else {
      setSelectedId('');
    }
  }, [selectedTabsState, currentTab]);

  const handleTabChange = (event: React.ChangeEvent<any>, value: number) => {
    onChangeTab(value, selectedTabsState[value]);
  };

  const handleAddTab = (tab: TabData) => {
    if (onAddTab) {
      onAddTab(tab);
    } else {
      const updatedTabs = [...selectedTabsState];
      updatedTabs.push(tab);
      onChangeTab(updatedTabs.length - 1, updatedTabs[updatedTabs.length - 1]);
      setSelectedTabsState(updatedTabs);
    }
  };

  const getUnusedOptions = () =>
    options.filter(
      (tab: TabData) =>
        !selectedTabsState.find((selectedTab) => selectedTab.id === tab.id)
    );

  const handleRemoveTab = (index: number) => {
    if (onRemoveTab) {
      onRemoveTab(index);
    }
    const updatedTabs = [...selectedTabsState];
    updatedTabs.splice(index, 1);
    if (currentTab > index) {
      onChangeTab(currentTab - 1, updatedTabs[currentTab - 1]);
    }
    if (currentTab && currentTab > updatedTabs.length - 1) {
      onChangeTab(updatedTabs.length - 1, updatedTabs[updatedTabs.length - 1]);
    }
    setSelectedTabsState(updatedTabs);
  };

  const renderTabs = () =>
    selectedTabsState
      ? selectedTabsState.map((tab, index) => (
          <CloseableTab
            onClose={handleRemoveTab}
            index={index}
            label={tab.label}
          />
        ))
      : undefined;

  const AddTabButton = (
    <IconButton
      data-test="add-tab-button"
      sx={styles.addTabButton}
      disabled={getUnusedOptions().length < 1}
      aria-label="New Tab"
    >
      <Add />
    </IconButton>
  );

  return (
    <TabContext value={selectedId || ''}>
      <Box sx={styles.flexContainer}>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          onChange={handleTabChange}
          textColor="primary"
        >
          {renderTabs()}
        </Tabs>
        <AnchoredHiddenMenu
          variant="custom"
          customAnchor={AddTabButton}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {getUnusedOptions().map((tab: TabData) => (
            <MenuItem onClick={() => handleAddTab(tab)} value={tab.value}>
              {tab.label}
            </MenuItem>
          ))}
        </AnchoredHiddenMenu>
      </Box>
      <Divider />
      {children}
    </TabContext>
  );
};

export default ConfigurableTabs;
