import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Typography } from 'base-components';
import { XChangeSensor } from 'domain/Apps/community-fishers/CommunityFishersDevicePackageTypes';

import SortableTable from 'library/CompositeComponents/table/SortableTable';
import SensorDetailsLink from '../link/SensorDetailsLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      width: 'fit-content',
      'margin-top': theme.spacing(2),
    },
  })
);

type Props = {
  xChangeSensors: XChangeSensor[];
};

const CommunityFishersXChangeSensorTable: React.FC<Props> = ({
  xChangeSensors,
}: Props) => {
  const classes = useStyles();

  if (!xChangeSensors || xChangeSensors.length < 1) {
    return null;
  }

  const headers = [
    { title: 'Sensor Id', name: 'sensorIdLink' },
    { title: 'Serial Number', name: 'sensorSerialNumber' },
    { title: 'Date From', name: 'dateFrom' },
    { title: 'Sensor Type', name: 'sensorType' },
  ];

  const rows = xChangeSensors.map((xChangeSensor) => ({
    ...xChangeSensor,
    sensorIdLink: <SensorDetailsLink sensorId={xChangeSensor.sensorId} />,
  }));

  return (
    <Box aria-label="X Change Sensors" className={classes.box}>
      <Typography varient="h3">X-Change Sensor Table</Typography>
      <SortableTable
        wordWrapEnabled
        columns={headers}
        rows={rows}
        elevation={5}
      />
    </Box>
  );
};

export default CommunityFishersXChangeSensorTable;
