import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { LabelledCheckbox } from 'base-components';
import Util from 'domain/AppComponents/manual-entry/ManualEntryLogic';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import TaxonAttributeService from 'domain/services/TaxonAttributeService';
import { TaxonomyAttributeJson } from 'domain/services/TaxonomyAttributeService';
import {
  ClearButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import DraggableToolbox from '../annotations/entry/DraggableToolbox';
import { TaxonOption } from '../dropdowns/TaxonAsyncAutocomplete';
import ManualEntryAttributes, {
  EntryAttributesSection,
} from '../manual-entry/ManualEntryAttributes';
import ManualEntryGeneral, {
  EntryGeneralSection,
} from '../manual-entry/ManualEntryGeneral';
import ManualEntryResource from '../manual-entry/ManualEntryResource';
import { ONC_DATA } from '../organization-details/OrganizationServiceData';
import {
  ManualEntryErrors,
  ManualEntryFormType,
} from '../sea-tube/manual-entry/ManualEntryForm';

type Props = {
  device: {
    deviceId: number;
    deviceName: string;
  };
  open: boolean;
  onClose: () => void;
};

const CompromisedAnnotationEntry = ({ device, open, onClose }: Props) => {
  const emptyForm: ManualEntryFormType = {
    resourceSection: {
      resourceTypeId: SeaTubeResourceTypes.DEVICE_DATA,
      resourceId: device.deviceId,
    },
    generalSection: {
      startDate: null,
      endDate: null,
      taxonomyId: 1100,
      taxon: null,
      comment: '',
      shared: false,
      flagged: false,
    },
    attributesSection: {
      attributes: [],
    },
  };
  const { onInfo, onError } = useSnackbars();
  const [formState, setFormState] = useState<ManualEntryFormType>(emptyForm);
  const [errors, setErrors] = useState<ManualEntryErrors>({
    startDateError: undefined,
    endDateError: undefined,
    attributeErrors: {},
    taxonError: undefined,
  });
  const { data: attributeOptions } = useGet<
    TaxonomyAttributeJson[],
    { filterSelectable: boolean }
  >(
    'internal/taxonomies/attributes',
    { operation: undefined, transform: (response) => response.data },
    { filterSelectable: true }
  );
  const { mutate: createAnnotation } = usePost<{
    annotation: string;
    organizationId?: number;
  }>(
    'AnnotationServiceV3',
    {
      onSuccess: () => {
        onInfo('Annotation Saved');
        // set to clear the taxon field properly
        setFormState({
          ...emptyForm,
          generalSection: { ...emptyForm.generalSection, taxonomyId: null },
        });
        onClose();
      },
    },
    1
  );
  // TaxonAttributeService (getting attrs for taxon)
  const [, , fetchAttributes] = useWebService({
    method: TaxonAttributeService.getAttributes,
    onError,
  });

  useEffect(() => {
    // reset the taxonomy after a save/clear
    if (!formState.generalSection.taxonomyId) {
      setFormState(emptyForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.generalSection]);

  const handleGeneralSectionChange = useCallback(
    (updatedSection: EntryGeneralSection) => {
      setFormState((prevState) =>
        Util.handleFormSectionChange(
          prevState,
          'generalSection',
          updatedSection
        )
      );
    },
    []
  );

  const handleAttributesSectionChange = useCallback(
    (updatedSection: EntryAttributesSection) => {
      setFormState((prevState) =>
        Util.handleFormSectionChange(
          prevState,
          'attributesSection',
          updatedSection
        )
      );
    },
    []
  );

  const handleTaxonChange = async (val: TaxonOption) => {
    const updatedForm = await Util.handleTaxonFormChange(
      val,
      formState,
      fetchAttributes,
      attributeOptions
    );
    // override potential resource attribute changes to stay
    // with the deviceId
    updatedForm.resourceSection = {
      resourceTypeId: SeaTubeResourceTypes.DEVICE_DATA,
      resourceId: device.deviceId,
    };
    setFormState(updatedForm);
  };

  const handleCheckboxChange = (key: string) => {
    const updatedSection = _.cloneDeep(formState.generalSection);
    updatedSection[key] = !updatedSection[key];
    handleGeneralSectionChange(updatedSection);
  };

  const handleSubmit = () => {
    const formErrors = Util.performFormValidation(
      formState,
      undefined,
      undefined,
      false
    );
    setErrors(formErrors);
    if (!_.isEmpty(formErrors)) {
      onError('Could not save annotation. The form contains errors.');
      return;
    }
    const annotation = Util.convertFormToServiceData(formState, {
      annotationSource: 'Oceans 3.0 Users',
      annotationSourceId: 3,
    });
    createAnnotation({
      annotation: JSON.stringify(annotation),
      organizationId: ONC_DATA.organizationId,
    });
  };

  const renderCheckboxes = () => (
    <>
      <LabelledCheckbox
        label="Shared"
        value={formState.generalSection.shared}
        onChange={() => handleCheckboxChange('shared')}
      />
      <LabelledCheckbox
        label="Flagged"
        value={formState.generalSection.flagged}
        onChange={() => handleCheckboxChange('flagged')}
      />
    </>
  );

  if (open) {
    return (
      <DraggableToolbox
        title="Create Compromised Instrument Annotation"
        initiallyExpanded
        onClose={onClose}
        actions={
          <>
            {renderCheckboxes()}
            <ClearButton
              onClick={() =>
                setFormState({
                  ...emptyForm,
                  generalSection: {
                    ...emptyForm.generalSection,
                    taxonomyId: null,
                  },
                })
              }
            />
            <SaveButton
              onClick={handleSubmit}
              disabled={!formState.generalSection.startDate}
            />
          </>
        }
      >
        <ManualEntryResource
          value={{
            resourceTypeId: SeaTubeResourceTypes.DEVICE_DATA,
            resourceId: device.deviceId,
          }}
          errors={errors}
          device={device}
        />
        <ManualEntryGeneral
          value={formState.generalSection}
          onChange={handleGeneralSectionChange}
          onTaxonChange={handleTaxonChange}
          onErrorChange={setErrors}
          errors={errors}
          startAndEndDates={!!device}
        />
        <ManualEntryAttributes
          value={formState.attributesSection}
          attributeOptions={attributeOptions}
          onChange={handleAttributesSectionChange}
          errors={errors}
        />
      </DraggableToolbox>
    );
  }
  return null;
};

export default CompromisedAnnotationEntry;
