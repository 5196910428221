enum BroadcastChannel {
  Annotation,
  AnnotationDelete,
  AudioTime,
  AutoPlayFlag,
  Counter,
  CurrentTimestamp,
  DataFileId,
  EarthquakeList,
  FlacData,
  LastEditedAnnotationId,
  LayoutUpdate,
  PlaylistLineId,
  QuickButton,
  RefreshAnnotations,
  RefreshMatrixId,
  RefreshVideos,
  SearchTreeNodeId,
  SeekToTimestamp,
  SelectedAnnotationId,
  Taxon,
  Taxonomy,
  Timestamp,
  VideoIntervals,
  WindowController,
}

export default BroadcastChannel;
