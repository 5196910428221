import { useContext, useState } from 'react';
import moment from 'moment';
import { FilterList } from '@onc/icons';
import { Dialog, DialogContent, DialogTitle } from 'base-components';
import DateFilter from 'domain/AppComponents/annotations/filters/DateFilter';

import { EndeavourEarthquakeExportRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import EndeavourEarthquakeExportService from 'domain/services/EndeavourEarthquakeExportService';
import { Parameters } from 'domain/services/EndeavourEarthquakeService';
import {
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import { FilterValue } from 'library/CompositeComponents/filter/Filter';
import FilterGroup from 'library/CompositeComponents/filter/FilterGroup';

import useWebService from 'util/hooks/useWebService';
import EndeavourContext from '../context/EndeavourContext';
import DepthFilter from '../filters/DepthFilter';
import EarthquakeDepthFilter from '../filters/EarthquakeDepthFilter';
import MagnitudeFilter from '../filters/MagnitudeFilter';
import StatusFilter from '../filters/StatusFilter';

const EarthquakeFilterWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const initialFilter = {
    dateFilter: {
      fromDate: moment
        .utc(new Date(new Date().setDate(new Date().getDate() - 7)))
        .startOf('day'),
      toDate: moment.utc(new Date()).startOf('day'),
    },

    magnitudeFilter: { minMag: null, maxMag: null },
    depthFilter: { minDepth: null, maxDepth: null },
    statusFilter: { status: 'all' },
    depthTypeFilter: { depthType: 'all' },
  };
  const { id } = props;
  const { setFilters } = useContext(EndeavourContext);
  const [expanded, setExpanded] = useState('');
  const [filter, setFilter] = useState<FilterValue>(initialFilter);
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
  const [exportFormat, setExportFormat] = useState<string>(undefined);

  const [, , exportEarthquakes] = useWebService({
    method: EndeavourEarthquakeExportService.exportEarthquakes,
  });

  const handleExpand = (filterName: string) => {
    filterName === expanded ? setExpanded('') : setExpanded(filterName);
  };

  const getQueryParams = (filterObj) => {
    const parameters: Parameters = {};
    if (exportFormat) {
      parameters.format = exportFormat;
    }
    if (filterObj.statusFilter.status === 'completeOnly') {
      parameters.isComplete = true;
    } else if (filterObj.statusFilter.status === 'incompleteOnly') {
      parameters.isComplete = false;
    }

    if (filterObj.depthTypeFilter.depthType === 'fixedDepthOnly') {
      parameters.isFixed = true;
    } else if (filterObj.depthTypeFilter.depthType === 'variableDepthOnly') {
      parameters.isFixed = false;
    }

    if (filterObj.dateFilter.fromDate !== null)
      parameters.startTime = filterObj.dateFilter.fromDate.toISOString();
    if (filterObj.dateFilter.toDate !== null)
      parameters.endTime = filterObj.dateFilter.toDate.toISOString();
    if (
      filterObj.depthFilter.minDepth !== null &&
      filterObj.depthFilter.minDepth !== ''
    )
      parameters.minDepth = filterObj.depthFilter.minDepth;
    if (
      filterObj.depthFilter.maxDepth !== null &&
      filterObj.depthFilter.maxDepth !== ''
    )
      parameters.maxDepth = filterObj.depthFilter.maxDepth;
    if (
      filterObj.magnitudeFilter.minMag !== null &&
      filterObj.magnitudeFilter.minMag !== ''
    )
      parameters.minMagnitude = filterObj.magnitudeFilter.minMag;
    if (
      filterObj.magnitudeFilter.maxMag !== null &&
      filterObj.magnitudeFilter.maxMag !== ''
    )
      parameters.maxMagnitude = filterObj.magnitudeFilter.maxMag;
    return parameters;
  };

  const applyFiltersFunc = () => {
    const params = getQueryParams(filter);
    setFilters(params);
  };

  const renderExtraButtons = () => (
    <ContainedButton
      onClick={() => setOpenExportDialog(true)}
      translationKey="seatube.export"
    />
  );

  const handleExport = () => {
    setOpenExportDialog(false);
    const params = getQueryParams(filter);
    exportEarthquakes(params);
  };

  return (
    <Widget key={id} title="Endeavour Filter" {...props}>
      <Dialog
        open={openExportDialog}
        onClose={() => setOpenExportDialog(false)}
        fullWidth
      >
        <DialogTitle>Select Export Format</DialogTitle>
        <DialogContent>
          <EndeavourEarthquakeExportRadioGroup
            name="endeavourExport"
            onChange={(e) => setExportFormat(e.target.value)}
          />
          <ContainedButton
            onClick={() => handleExport()}
            translationKey="common.buttons.confirm"
          />
          <TextButton
            onClick={() => setOpenExportDialog(false)}
            translationKey="common.buttons.close"
          />
        </DialogContent>
      </Dialog>
      <FilterGroup
        name="endeavourFilters"
        onChange={(e) =>
          e.target.value ? setFilter(e.target.value) : undefined
        }
        onReset={() => {
          setFilters(getQueryParams(initialFilter));
          setFilter(initialFilter);
        }}
        onSubmit={() => applyFiltersFunc()}
        icon={<FilterList color="action" />}
        initialValue={initialFilter}
        value={filter}
        submitTranslationKey="common.buttons.filter"
        includeSummaryBar={false}
        extraButtons={renderExtraButtons()}
      >
        <MagnitudeFilter
          name="magnitudeFilter"
          expanded={expanded === 'magnitudeFilter'}
          onExpand={handleExpand}
        />
        <DepthFilter
          name="depthFilter"
          expanded={expanded === 'depthFilter'}
          onExpand={handleExpand}
        />
        <DateFilter
          name="dateFilter"
          expanded={expanded === 'dateFilter'}
          onExpand={handleExpand}
        />
        <StatusFilter
          name="statusFilter"
          expanded={expanded === 'statusFilter'}
          onExpand={handleExpand}
        />
        <EarthquakeDepthFilter
          name="depthTypeFilter"
          expanded={expanded === 'depthTypeFilter'}
          onExpand={handleExpand}
        />
      </FilterGroup>
    </Widget>
  );
};

EarthquakeFilterWidget.widgetKey = 'endeavour-earthquake-filters';
EarthquakeFilterWidget.widgetTitle = 'Earthquake Filters';
EarthquakeFilterWidget.defaultDataGrid = {
  i: 'endeavour-earthquake-filter',
  x: 0,
  y: 0,
  w: 2,
  h: 6,
};

export default EarthquakeFilterWidget;
