import { useEffect } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { DateTimePicker, Dropdown } from 'base-components';
import { FilterProps } from './TableFilterFactory';

const styles = (theme: Theme) =>
  createStyles({
    flexItem: {
      margin: theme.spacing(0.5),
    },
  });

const operatorOptions = [
  {
    key: 'after',
    label: 'after',
    value: 'after',
  },
  {
    key: 'before',
    label: 'before',
    value: 'before',
  },
  {
    key: 'equals',
    label: 'equals',
    value: 'equals',
  },
  {
    key: 'isNull',
    label: 'is null',
    value: 'isNull',
  },
  {
    key: 'isNotNull',
    label: 'is not null',
    value: 'isNotNull',
  },
];

const TableDateFilter: React.VFC<FilterProps> = ({
  operator,
  value,
  classes,
  handleChangeOperator,
  handleChangeValue,
}) => {
  useEffect(() => {
    if (operator === undefined) {
      handleChangeOperator(operatorOptions[0].value);
    }
  }, [operator, handleChangeOperator]);

  const handleDateChange = (val: Moment) => {
    if (val) {
      return handleChangeValue(val.toISOString());
    }
    return handleChangeValue('');
  };

  return (
    <>
      <Dropdown
        label="Operator"
        name="operator"
        data-test="operator"
        fullWidth
        className={classes ? classes.flexItem : undefined}
        value={operator}
        options={operatorOptions}
        onChange={(e) => handleChangeOperator(e.target.value)}
      />
      <DateTimePicker
        translationKey="common.datepickers.date"
        onChange={handleDateChange}
        className={classes ? classes.flexItem : undefined}
        value={moment.utc(value)}
        fullWidth
      />
    </>
  );
};

export default withStyles(styles)(TableDateFilter);
