import { parseDmasAPIResponse, get } from 'util/WebRequest';

class ItemTypeService {
  static getMany() {
    return ItemTypeService.get({ operation: 5 });
  }

  static get(params) {
    return get('CableItemTypeService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default ItemTypeService;
