import { useContext, useEffect, useState } from 'react';
import { LinearProgress, Typography } from 'base-components';
import TaxonService from 'domain/services/TaxonService';
import { TaxonomyItem, TaxonItem } from 'domain/Widgets/TaxonomyTreeWidget';

import TreeMenuItem from 'library/CompositeComponents/tree-menu/TreeMenuItem';
import { TaxonomyContext } from './TaxonomyApp';

type Props = {
  divTitle: string;
  taxonomy: TaxonomyItem | null;
  taxon: TaxonItem | null;
  selectedTaxonomyMatrixId: number;
  onError: (message: string) => void;
};

const TaxonomyTreeItem = ({
  divTitle,
  taxonomy,
  taxon,
  selectedTaxonomyMatrixId,
  onError,
}: Props) => {
  const [taxons, setTaxons] = useState<TaxonItem[] | null>(null);

  const {
    taxon: taxonInContext,
    matrixId,
    setMatrixId,
    clickTaxonomyItem,
  } = useContext(TaxonomyContext);

  const renderTaxons = () => {
    if (!taxons) {
      return <LinearProgress />;
    }
    if (taxons.length === 0) {
      return null;
    }
    taxons.sort((taxonA: TaxonItem, taxonB: TaxonItem) =>
      taxonA.commonName.localeCompare(taxonB.commonName)
    );
    return taxons.map((txn) => (
      <TaxonomyTreeItem
        key={txn.taxonomyMatrixId}
        divTitle={`taxon-${txn.commonName}-${txn.taxonomyMatrixId}`}
        taxonomy={taxonomy}
        taxon={txn}
        selectedTaxonomyMatrixId={selectedTaxonomyMatrixId}
        onError={onError}
      />
    ));
  };

  const fetchTaxons = async () => {
    clickTaxonomyItem(
      taxon !== null ? taxon.taxonomyMatrixId : taxonomy.taxonomyMatrixId,
      taxonomy,
      taxon
    );
    try {
      setTaxons(
        await TaxonService.getTaxonChildren(
          taxonomy.taxonomyId,
          taxon?.taxonId || null
        )
      );
    } catch (error: any) {
      if (onError) {
        onError(error.message);
      }
    }
  };

  useEffect(() => {
    if (matrixId !== 0) {
      // Delete and Refresh (when a taxon is updated) case
      if (taxons && taxons.find((txn) => txn.taxonomyMatrixId === matrixId)) {
        const taxonToSelect = taxonInContext || taxon;
        fetchTaxons().then(() => {
          clickTaxonomyItem(
            taxonToSelect
              ? taxonToSelect.taxonomyMatrixId
              : taxonomy.taxonomyMatrixId,
            taxonomy,
            taxonToSelect
          );
          setMatrixId(0);
        });
      }
      // Add case
      else if (matrixId === taxon?.taxonomyMatrixId && taxonInContext) {
        fetchTaxons().then(() =>
          clickTaxonomyItem(
            taxonInContext.taxonomyMatrixId,
            taxonomy,
            taxonInContext
          )
        );
        setMatrixId(0);
      } else if (
        !taxon &&
        matrixId === taxonomy.taxonomyMatrixId &&
        taxonInContext
      ) {
        fetchTaxons().then(() =>
          clickTaxonomyItem(
            taxonInContext.taxonomyMatrixId,
            taxonomy,
            taxonInContext
          )
        );
        setMatrixId(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixId]);

  return (
    <div id={divTitle}>
      <TreeMenuItem
        label={
          <Typography variant="body1">
            {taxon !== null ? taxon.commonName : taxonomy.taxonomyName}
          </Typography>
        }
        key={
          taxon !== null ? taxon.taxonomyMatrixId : taxonomy.taxonomyMatrixId
        }
        selected={
          selectedTaxonomyMatrixId ===
          (taxon !== null ? taxon.taxonomyMatrixId : taxonomy.taxonomyMatrixId)
        }
        expand={false}
        onClick={fetchTaxons}
      >
        {renderTaxons()}
      </TreeMenuItem>
    </div>
  );
};

export default TaxonomyTreeItem;
