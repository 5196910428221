import { Dialog } from 'base-components';
import { CableTypeRow } from 'domain/AppComponents/cabletype/CableTypeTableLogic';
import { ConnectorTypeRow } from 'domain/Apps/connectortype/ConnectorTypeTableLogic';
import ExtensionService from 'domain/services/ExtensionService';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import CableListingForm from './CableListingForm';

type CableListingDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  cableTypeList: CableTypeRow[];
  connectorTypeList: ConnectorTypeRow[];
};

const CableListingCreateDialog = ({
  open,
  onClose,
  onSuccess,
  cableTypeList,
  connectorTypeList,
}: CableListingDialogProps) => {
  const { onInfo, onError } = useSnackbars();

  const [, , createCableListing] = useWebService({
    method: ExtensionService.create,
  });

  const handleSubmit = ({
    cableName,
    cableType,
    connectorType1,
    connectorType2,
    length,
    colorBand,
    characteristics,
    jira,
  }) =>
    createCableListing({
      comment: cableName,
      cableTypeId: cableType?.cableTypeId,
      connectorTypeId1: connectorType1?.connectorTypeId,
      connectorTypeId2: connectorType2?.connectorTypeId,
      length,
      colorBand,
      characteristics,
      jira,
    })
      .then((response) => {
        if (response.extensionId !== undefined) {
          onInfo('Cable saved successfully!');
          onClose();
          onSuccess();
        } else {
          onError('Cable failed to save');
        }
      })
      .catch((err) => {
        onError(err);
      });

  return (
    <Dialog open={open}>
      <CableListingForm
        onSubmit={handleSubmit}
        onClose={onClose}
        cableTypeList={cableTypeList}
        connectorTypeList={connectorTypeList}
      />
    </Dialog>
  );
};

export default CableListingCreateDialog;
