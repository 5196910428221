import { memo, Children } from 'react';
import * as React from 'react';
import { Stepper, StepDisplayInfo } from 'base-components';

interface LinearOncStepperProps {
  stepNum?: number; // 1-indexed
  displayInfo: StepDisplayInfo[];
  showLabelBelow?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const LinearOncStepper: React.VFC<LinearOncStepperProps> = ({
  stepNum = 1,
  displayInfo,
  showLabelBelow = false,
  children,
}) => {
  const steps = Children.toArray(children);

  return (
    <>
      <Stepper
        displayInfo={displayInfo}
        showLabelBelow={showLabelBelow}
        stepNum={stepNum}
        orientation="horizontal"
      />
      {/* Continue to display the final step if the final step is complete. */}
      {stepNum >= steps.length ? steps[steps.length - 1] : steps[stepNum - 1]}
    </>
  );
};

export default memo(LinearOncStepper);
