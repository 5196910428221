import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class DataRatingService {
  static getOne(dataRatingId) {
    return DataRatingService.get({ operation: 4, dataRatingId });
  }

  static getManyByDeviceId(deviceId) {
    return DataRatingService.get({ operation: 5, deviceId });
  }

  static getManyBySensorId(sensorId) {
    return DataRatingService.get({ operation: 5, sensorId });
  }

  static get(params) {
    return get('DataRatingService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(params) {
    return post('DataRatingService', {
      operation: 1,
      ...params,
    }).then((response) => response);
  }

  static update(params) {
    return post('DataRatingService', {
      operation: 2,
      ...params,
    }).then((response) => response);
  }

  static delete(dataRatingId) {
    return post('DataRatingService', {
      operation: 3,
      dataRatingId,
    }).then((response) => response);
  }
}
export default DataRatingService;
