/*
 * Manual entry is put at the bottom so if the user has annotations permissions it
 * slides up rather than rendering in the top left and shuffling down
 */
const defaultLayout = {
  name: 'Public',
  key: 'default-public-layout',
  permission: true,
  selected: false,
  isPublic: true,
  layout: {
    lg: [
      {
        h: 14,
        i: 'seatube-map',
        w: 3,
        x: 0,
        y: 0,
        minH: 9,
        minW: 2,
      },
      {
        h: 14,
        i: 'seatube-video',
        w: 5,
        x: 3,
        y: 0,
        minH: 9,
        minW: 2,
      },
      {
        h: 23,
        i: 'annotation-list',
        w: 4,
        x: 8,
        y: 0,
        minH: 9,
        minW: 4,
      },
      {
        h: 9,
        i: 'annotation-entry',
        w: 8,
        x: 0,
        y: 50,
        minH: 9,
        minW: 4,
      },
      {
        h: 9,
        i: 'seatube-sensor-display',
        w: 8,
        x: 0,
        y: 14,
        minH: 9,
        minW: 3,
      },
    ],
    md: [
      {
        h: 14,
        i: 'seatube-map',
        w: 3,
        x: 0,
        y: 0,
        minH: 9,
        minW: 2,
      },
      {
        h: 14,
        i: 'seatube-video',
        w: 5,
        x: 3,
        y: 0,
        minH: 9,
        minW: 2,
      },
      {
        h: 23,
        i: 'annotation-list',
        w: 4,
        x: 8,
        y: 0,
        minH: 9,
        minW: 4,
      },
      {
        h: 9,
        i: 'annotation-entry',
        w: 8,
        x: 0,
        y: 50,
        minH: 9,
        minW: 4,
      },
      {
        h: 9,
        i: 'seatube-sensor-display',
        w: 8,
        x: 0,
        y: 14,
        minH: 9,
        minW: 3,
      },
    ],
    sm: [
      {
        h: 10,
        i: 'seatube-map',
        w: 3,
        x: 0,
        y: 10,
        minH: 10,
        minW: 3,
      },
      {
        h: 10,
        i: 'seatube-video',
        w: 3,
        x: 0,
        y: 0,
        minH: 10,
        minW: 3,
      },
      {
        h: 10,
        i: 'annotation-list',
        w: 3,
        x: 3,
        y: 0,
        minH: 10,
        minW: 3,
      },
      {
        h: 10,
        i: 'annotation-entry',
        w: 6,
        x: 0,
        y: 50,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'seatube-sensor-display',
        w: 3,
        x: 3,
        y: 10,
        minH: 10,
        minW: 3,
      },
    ],
    xs: [
      {
        h: 10,
        i: 'seatube-video',
        w: 3,
        x: 0,
        y: 0,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'annotation-list',
        w: 3,
        x: 0,
        y: 10,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'seatube-map',
        w: 3,
        x: 0,
        y: 20,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'annotation-entry',
        w: 3,
        x: 0,
        y: 50,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'seatube-sensor-display',
        w: 3,
        x: 0,
        y: 30,
        minH: 9,
        minW: 3,
      },
    ],
    xxs: [
      {
        h: 10,
        i: 'seatube-video',
        w: 3,
        x: 0,
        y: 0,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'annotation-list',
        w: 3,
        x: 0,
        y: 10,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'seatube-map',
        w: 3,
        x: 0,
        y: 20,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'annotation-entry',
        w: 3,
        x: 0,
        y: 50,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'seatube-sensor-display',
        w: 3,
        x: 0,
        y: 30,
        minH: 9,
        minW: 3,
      },
    ],
  },
};

export default defaultLayout;
