import * as React from 'react';
import { TableRowDetail as GridTableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { ExpandRowProps } from './ExpandRow';

const TableRowDetail: React.VFC<ExpandRowProps> = (props: ExpandRowProps) => {
  const { RowDetail, ToggleCellComponent } = props;
  if (ToggleCellComponent) {
    return (
      <GridTableRowDetail
        contentComponent={RowDetail}
        toggleCellComponent={ToggleCellComponent}
      />
    );
  }
  return <GridTableRowDetail contentComponent={RowDetail} />;
};
export default TableRowDetail;
