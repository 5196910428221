import { parseDmasAPIResponse, get } from 'util/WebRequest';

class SearchHistoryService {
  static getSearchHistoryWithDOI(filter, pagination) {
    const params = {
      operation: 5,
      filter: undefined,
      firstIndex: undefined,
      numberOfRecords: undefined,
      sortColumn: undefined,
    };
    if (filter) {
      params.filter = {
        dateTo:
          filter.queryDateFilter.toDate !== ''
            ? filter.queryDateFilter.toDate
            : undefined,
        dateFrom:
          filter.queryDateFilter.fromDate !== ''
            ? filter.queryDateFilter.fromDate
            : undefined,
      };
    }
    if (pagination) {
      params.firstIndex = pagination.firstIndex;
      params.numberOfRecords = pagination.numberOfRecords;
      params.sortColumn = pagination.sortOrder;
    }
    return get('SearchHistoryService', params).then(
      (
        response //
      ) => parseDmasAPIResponse(response) //
    );
  }
}
export default SearchHistoryService;
