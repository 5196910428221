import bs from 'binary-search';

class Search {
  /**
   * Documentation for search comparator
   *
   * @template A, B
   * @callback binarySearchComparator
   * @param {A} a
   * @param {B} b
   * @param {number} [index]
   * @param {A[]} [inputArray]
   * @returns {-1 | 0 | 1}
   */

  /**
   * Simple binary search. starting/ending indexes refer to the search range on
   * the inputArray
   *
   * @template A, B
   * @param {A[]} inputArray
   * @param {B} targetValue
   * @param {binarySearchComparator<A, B>} comparator
   * @param {number} [startingIndex]
   * @param {number} [endingIndex]
   * @returns {number} Index of values, or -1 for not-found
   */
  static binarySearch(
    inputArray,
    targetValue,
    comparator,
    startingIndex,
    endingIndex
  ) {
    /* istanbul ignore next */
    return bs(inputArray, targetValue, comparator, startingIndex, endingIndex);
  }
}

export default Search;
