import DeviceInfo from 'domain/Apps/device-listing/DeviceInfo';
import DeviceType from 'domain/Apps/device-type/DeviceType';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

const DEVICE_TYPE_SERVICE = 'DeviceTypeService';

class DeviceTypeService {
  static get(params) {
    return get('DeviceTypeService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getAllDeviceTypes(): Promise<DeviceInfo[]> {
    return DeviceTypeService.get({ operation: 5 });
  }

  static getDeviceTypeById(deviceTypeId: number): Promise<DeviceInfo[]> {
    return DeviceTypeService.get({ operation: 4, deviceTypeId });
  }

  static createDeviceType(deviceType: DeviceType) {
    const parameters = {
      operation: 1,
      payload: JSON.stringify({
        deviceType: {
          deviceTypeId: deviceType.deviceTypeId,
          deviceTypeName: deviceType.deviceTypeName,
          deviceTypeCode: deviceType.deviceTypeCode,
          description: deviceType.description,
        },
      }),
    };
    return post(DEVICE_TYPE_SERVICE, parameters);
  }

  static updateDeviceType(deviceType: DeviceType) {
    const parameters = {
      operation: 2,
      payload: JSON.stringify({
        deviceType: {
          deviceTypeId: deviceType.deviceTypeId,
          deviceTypeName: deviceType.deviceTypeName,
          deviceTypeCode: deviceType.deviceTypeCode,
          description: deviceType.description,
        },
      }),
    };
    return post(DEVICE_TYPE_SERVICE, parameters);
  }
}
export default DeviceTypeService;
