import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Add } from '@onc/icons';
import TaskTableStyledCell from 'domain/AppComponents/batch/task-table/TaskTableStyledCell';
import DeviceDetailsLink from 'domain/AppComponents/link/DeviceDetailsLink';
import SensorCalibrationService from 'domain/services/SensorCalibrationService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const headers = [
  { title: 'Calibration ID', name: 'calibrationId' },
  { title: 'Calibration Name', name: 'calibrationName' },
  { title: 'Formula', name: 'formula' },
  { title: 'Date From', name: 'dateFrom' },
  { title: 'Description', name: 'description' },
  { title: 'Triggering Device ID', name: 'calibrationTriggerDeviceId' },
  { title: 'Exclude QAQC IDs', name: 'excludeQAQCs' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
];

const tableColumnExtensions = [
  {
    columnName: 'calibrationId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'calibrationName',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'formula',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'dateFrom',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'calibrationTriggerDeviceId',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'excludeQAQCs',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class CalibrationTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    sensorId: PropTypes.number.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
      addButton: PropTypes.string,
    }).isRequired,
  };

  compareId = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refreshTableData = () => {
    const { onError, sensorId } = this.props;
    this.setState({ isLoading: true });
    SensorCalibrationService.getMany(sensorId)
      .then((result) => {
        this.setState({ isLoading: false });
        this.buildTableRows(result);
      })
      .catch((result) => {
        this.setState({ isLoading: false });
        onError(result);
      });
  };

  idLink = (id) => {
    const { sensorId } = this.props;
    return (
      <a
        href={`${Environment.getDmasUrl()}/Calibration?sensorId=${sensorId}&calibrationId=${id}`}
      >
        {id}
      </a>
    );
  };

  buildTableRows = (calibrations) => {
    const { permission } = this.props;
    this.setState({
      tableRows: calibrations.map((data) => {
        const calibrationFor = {
          calibrationId:
            permission === 'RW'
              ? this.idLink(data.calibration.calibrationId)
              : data.calibration.calibrationId,
          calibrationName: data.calibration.calibrationName,
          dateFrom: DateFormatUtils.formatDate(
            data.calibration.dateFrom,
            'full'
          ),
          formula: data.calibration.correctionFormula,
          modifyBy: `${data.calibration.modifyByName} (${data.calibration.modifyBy})`,
          modifyDate: DateFormatUtils.formatDate(
            data.calibration.modifyDate,
            'full'
          ),
        };
        calibrationFor.description =
          permission === 'RW' ? data.calibration.description : undefined;
        calibrationFor.calibrationTriggerDeviceId =
          permission === 'RW' ? (
            <DeviceDetailsLink
              deviceId={data.calibration.calibrationTriggerDeviceId}
            />
          ) : undefined;
        calibrationFor.excludeQAQCs =
          permission === 'RW' ? data.calibration.excludeQAQCs : undefined;

        return calibrationFor;
      }),
    });
  };

  addCalibrationButton = (sensorId) => {
    const { permission, classes } = this.props;

    if (permission === 'RW') {
      return (
        <div className={classes.button}>
          <a
            href={`${Environment.getDmasUrl()}/Calibration?sensorId=${sensorId}`}
          >
            <ContainedButton
              translationKey="device.addCalibration"
              startIcon={<Add />}
              className={classes.addButton}
            />
          </a>
        </div>
      );
    }

    return null;
  };

  render() {
    const { permission, sensorId } = this.props;
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    return (
      <>
        {this.addCalibrationButton(sensorId)}
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={10}
          cellComponent={TaskTableStyledCell}
          rows={tableRows}
          stripedRows
          sortExtensions={
            permission === 'RW'
              ? [{ columnName: 'dspdId', compare: this.compareId }]
              : []
          }
          hiddenColumns={
            permission !== 'RW'
              ? ['description', 'calibrationTriggerDeviceId', 'excludeQAQCs']
              : []
          }
          defaultSorting={[{ columnName: 'dateFrom', direction: 'desc' }]}
          isLoading={isLoading}
        />
      </>
    );
  }
}

export default CalibrationTable;
