import { post, get } from 'util/WebRequest';

class EEWRegistrationService {
  static getSubscriptions = () => get('EEWRegistrationService', {});

  static register = (
    notificationType,
    notificationAddress,
    latitude,
    longitude
  ) =>
    post('EEWRegistrationService', {
      notificationType,
      notificationAddress,
      latitude,
      longitude,
    });

  static subscribe = (notificationType, destination, isActive) =>
    post('EEWRegistrationService', {
      notificationType,
      destination,
      isActive,
      operation: 1,
      requestType: 'subscription',
    });

  static testScenario = (epicentre, magnitude) =>
    post('EEWRegistrationService', {
      epicentre,
      magnitude,
      operation: 1,
      requestType: 'scenario',
    });
}

export default EEWRegistrationService;
