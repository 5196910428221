import { ReactNode } from 'react';
import { useTheme } from '@mui/styles';
import { Box } from 'base-components';

type PageWithPaddingProps = {
  children: ReactNode;
};

const PageWithPadding = ({ children }: PageWithPaddingProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        paddingRight: {
          xs: theme.spacing(1),
          sm: theme.spacing(4),
          md: theme.spacing(8),
          lg: theme.spacing(16),
        },
        paddingLeft: {
          xs: theme.spacing(1),
          sm: theme.spacing(4),
          md: theme.spacing(8),
          lg: theme.spacing(16),
        },
        paddingTop: { sm: theme.spacing(4) },
      }}
    >
      {children}
    </Box>
  );
};

export default PageWithPadding;
