import { ComponentType, useState } from 'react';

import moment, { Moment } from 'moment';

interface HocProps {
  onTime: (Date) => void;
  onMeta?: (Metadata) => void;
}

interface ChildProps {
  onTime: (number) => void;
  onMeta: (Metadata) => void;
}

// There are a couple other types "Metadata" can have, but I haven't defined
// them here
type Metadata = {
  metadataType: 'id3' | 'discontinuity';
  metadataTime: number;
  metadata: {
    [index: string]: string;
  };
};

let hasMeta = false;

const withProgramDateTime =
  <P extends ChildProps>(VideoComponent: ComponentType<P>) =>
  // eslint-disable-next-line react/require-default-props
  (props: HocProps) => {
    const { onMeta, onTime } = props;

    const [programDateTime, setProgramDateTime] = useState<Moment>(moment());
    const [elapsedTime, setElapsedTime] = useState<number>(0);

    const handleTimeChange = (currentElapsedTime: number) => {
      onTime(
        programDateTime
          .clone()
          .add({ seconds: currentElapsedTime - elapsedTime })
          .toDate()
      );
    };

    const handleMetadataChange = (metadata: Metadata) => {
      if (metadata.metadataType === 'id3' && metadata.metadata.TDRC) {
        hasMeta = true;

        const dateTime = moment(metadata.metadata.TDRC);
        setProgramDateTime(dateTime);
        setElapsedTime(metadata.metadataTime);

        onTime(dateTime.toDate());
      } else if (!hasMeta && metadata.metadataType === 'discontinuity') {
        setProgramDateTime(moment());
      }

      if (onMeta) onMeta(metadata);
    };

    return (
      <VideoComponent
        {...(props as P)}
        onTime={handleTimeChange}
        onMeta={handleMetadataChange}
      />
    );
  };

export default withProgramDateTime;
