// Renders the LiveExpeditionEmbed component wrapped in a theme provider

import * as React from 'react';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import qs from 'qs';
import { oceans3Theme } from '@onc/theme';
import LiveExpeditionEmbed from './LiveExpeditionEmbed';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface Props {
  match: {
    params: {
      cruiseId: string;
    };
  };
}

interface Parameters {
  maxAnnotations?: number;
  preferredExpeditionName?: string;
  livestreamUrl?: string;
}

// eslint-disable-next-line react/require-default-props
const LiveExpeditionContainer: React.VFC<Props> = (props: Props) => {
  const params: Parameters = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }) as Parameters;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={oceans3Theme}>
        <LiveExpeditionEmbed {...props} {...params} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default LiveExpeditionContainer;
