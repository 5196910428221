/* eslint-disable react-hooks/exhaustive-deps */
import { AnyCnameRecord } from 'dns';
import { useContext, useEffect, useState } from 'react';

import Moment from 'moment';
import { Info } from '@onc/icons';
import {
  DateTimePicker,
  Checkbox,
  IconButton,
  FormControlLabel,
  TextField,
} from 'base-components';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import CopyLinkButton from 'library/CompositeComponents/share/CopyLinkButton';
import Environment from 'util/Environment';
import useBroadcast from 'util/hooks/useBroadcast';
import { useLocalStorage } from 'util/hooks/useStorage';
import useWebService from 'util/hooks/useWebService';
import EarthquakeContext from './EarthquakeContext';
import EarthquakeCatalogService from './service/EarthquakeCatalogService';

import { GenericInfoDialog } from '../dialogs/Dialogs';

type EarthquakeFilter = {
  minMag?: number;
  maxMag?: number;
  dateFrom?: Date;
  dateTo?: Date;
  confirmedDetections?: boolean;
  unconfirmedDetections?: boolean;
  missedDetections?: boolean;
};

const EarthquakeFilterWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const [filteredEarthquakeList, , fetchFilteredEarthquakes] = useWebService({
    method: EarthquakeCatalogService.getFilteredEarthquakes,
  });

  const { id } = props;
  const { earthquakes, dashboardId } = useContext(EarthquakeContext);

  const defaultFilter = {
    minMag: 0,
    maxMag: 10,
    dateFrom: null,
    dateTo: null,
    confirmedDetections: true,
    unconfirmedDetections: true,
    missedDetections: true,
  };

  const [broadCastFilters, setBroadCastFilters] =
    useLocalStorage<EarthquakeFilter>('eew-catalog-filter', defaultFilter);

  const [, setEarthquakeList] = useBroadcast<AnyCnameRecord>(
    dashboardId,
    BroadcastChannel.EarthquakeList,
    earthquakes,
    id
  );

  useEffect(() => {
    setBroadCastFilters(defaultFilter);
    setEarthquakeList(earthquakes);
  }, []);

  useEffect(() => {
    fetchFilteredEarthquakes(broadCastFilters);
  }, [broadCastFilters]);

  useEffect(() => {
    if (filteredEarthquakeList != null) {
      setEarthquakeList(filteredEarthquakeList.earthquakes);
    }
  }, [filteredEarthquakeList]);

  const [confirmedDetections, setConfirmedDetections] = useState<boolean>(true);

  const [unconfirmedDetections, setUnconfirmedDetections] =
    useState<boolean>(true);

  const [missedDetections, setMissedDetections] = useState<boolean>(true);

  const [dateFrom, setDateFrom] = useState<Date>();

  const [dateTo, setDateTo] = useState<Date>();

  const [filterUrl, setFilterUrl] = useState<string>();

  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>();

  const [minMag, setMinMag] = useState<number>(0);

  const [maxMag, setMaxMag] = useState<number>(10);

  const applyFiltersFunc = () => {
    let tempUrl = `${Environment.getDmasUrl()}/EarthquakeCatalog?`;
    setFilterUrl(`${Environment.getDmasUrl()}/EarthquakeCatalog?`);

    if (minMag != null) {
      setFilterUrl(`${filterUrl}minMag=${minMag}&`);
      tempUrl += `minMag=${minMag}&`;
    }

    if (maxMag != null) {
      setFilterUrl(`${filterUrl}minMag=${maxMag}&`);
      tempUrl += `maxMag=${maxMag}&`;
    }

    if (dateFrom && !isNaN(dateFrom.getDate())) {
      setDateFrom(dateFrom);
      setFilterUrl(`${filterUrl}dateFrom=${Moment(dateFrom).toISOString()}&`);
      tempUrl += `${filterUrl}dateFrom=${Moment(dateFrom).toISOString()}&`;
    }
    if (dateTo && !isNaN(dateTo.getDate())) {
      setDateTo(dateTo);
      tempUrl += `${filterUrl}dateFrom=${Moment(dateTo).toISOString()}&`;
      setFilterUrl(`${filterUrl}dateFrom=${Moment(dateTo).toISOString()}&`);
    }

    tempUrl += `missedDetections=${missedDetections}&confirmedDetections=${confirmedDetections}&unconfirmedDetections=${unconfirmedDetections}`;

    setFilterUrl(tempUrl);

    setBroadCastFilters({
      minMag,
      maxMag,
      dateFrom,
      dateTo,
      confirmedDetections,
      unconfirmedDetections,
      missedDetections,
    });
  };

  return (
    <Widget
      key={id}
      title="Earthquake Filter"
      {...props}
      actionComponents={[
        <IconButton
          Icon={Info}
          onClick={() => setOpenInfoDialog(true)}
          aria-label="Filter Information"
        />,
      ]}
    >
      <GenericInfoDialog
        open={openInfoDialog}
        onClose={() => setOpenInfoDialog(false)}
        title="Filter Information"
        message="You can filter an earthquake detection on magnitude, origin time, and detection type.
         The filters are applied when you hit the apply button at the bottom of the panel. 
         The filters can also be shared which will copy a url that loads the page with the applied filters set. 
         A missed detection is not detected by ONC but is detected by another organization.
         An unconfirmed detection is detected by ONC but is not detected by any other organization.
         A confirmed detection is detected by ONC and at least one other organization"
      />
      <form onSubmitCapture={applyFiltersFunc}>
        <div>
          <TextField
            translationKey="earthquake.minMag"
            title="minMag"
            type="number"
            defaultValue={0}
            onChange={(e) =>
              setMinMag(e.target.value !== '' ? Number(e.target.value) : null)
            }
            error={minMag === null}
          />
          <TextField
            translationKey="earthquake.maxMag"
            title="maxMag"
            type="number"
            defaultValue={10}
            onChange={(e) =>
              setMaxMag(e.target.value !== '' ? Number(e.target.value) : null)
            }
            error={maxMag === null}
          />
          <DateTimePicker
            translationKey="common.datepickers.startDate"
            onChange={(newDateFrom) => {
              setDateFrom(newDateFrom.local().toDate());
            }}
          />
          <DateTimePicker
            translationKey="common.datepickers.endDate"
            onChange={(newDateTo) => {
              setDateTo(newDateTo.local().toDate());
            }}
          />
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setMissedDetections(!missedDetections);
                  }}
                  checked={missedDetections}
                />
              }
              label="Missed Detections"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setUnconfirmedDetections(!unconfirmedDetections);
                  }}
                  checked={unconfirmedDetections}
                />
              }
              label="Unconfirmed Detections"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setConfirmedDetections(!confirmedDetections);
                  }}
                  checked={confirmedDetections}
                />
              }
              label="Confirmed Detections"
            />
          </div>
        </div>
        <div>
          <TextButton
            onClick={applyFiltersFunc}
            translationKey="seatube.applyFilter"
          />
        </div>
      </form>
      <CopyLinkButton shareUrl={filterUrl} buttonSize="small" />
    </Widget>
  );
};

EarthquakeFilterWidget.widgetKey = 'earthquake-filters';
EarthquakeFilterWidget.widgetTitle = 'Earthquake Filter';
EarthquakeFilterWidget.defaultDataGrid = {
  i: 'earthquake-filter',
  x: 0,
  y: 0,
  w: 2,
  h: 6,
};

export default EarthquakeFilterWidget;
