import useGet from 'util/hooks/useDmasAPI/useGet';
import { get, parseDmasAPIResponse } from 'util/WebRequest';

type PrimarySensorIdsPerSensorType = {
  [key: number]: number[];
};

export type SearchTreeNodeDetailsPayload = {
  datasetReferenceFlag: boolean;
  description: string; // string containing html
  els: SearchTreeNodeDetailsPayload[];
  id: number;
  name: string;
  primarySensorIdsPerSensorTypeMap: PrimarySensorIdsPerSensorType;
  siteDeviceIds: [];
  siteDeviceSubsets: [];
  stationCode: string;
  type: number | string;
};

export const useSearchTreeNodeDetails = (params: {
  searchTreeNodeId: number;
}) =>
  useGet<SearchTreeNodeDetailsPayload, { searchTreeNodeId: number }>(
    'seatube/details',
    1,
    params
  );

class SearchTreeNodeService {
  static getSearchTreeNodeDetails = async (searchTreeNodeId, onError) => {
    try {
      const payload = await get('seatube/details', {
        searchTreeNodeId,
      }).then((response) => parseDmasAPIResponse(response));

      return payload;
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }

    return undefined;
  };
}

export default SearchTreeNodeService;
