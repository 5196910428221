import { memo } from 'react';
import * as React from 'react';
import { TextAreaAutosize, Typography } from 'base-components';

interface Props {
  header?: string;
  value?: string;
  maxRows?: number;
  minRows?: number;
  textAreaStyle?: React.CSSProperties;
  wrap?: 'hard' | 'soft' | 'off';
}

const StepPartTextDisplayer: React.VFC<Props> = ({
  header = '',
  value = '',
  maxRows = 0,
  minRows = 0,
  textAreaStyle = undefined,
  wrap = 'off',
}) => (
  <>
    {header && <Typography variant="h6">{header}</Typography>}
    <TextAreaAutosize
      readOnly
      defaultValue={value}
      style={textAreaStyle}
      maxRows={maxRows}
      minRows={minRows}
      wrap={wrap}
    />
  </>
);

export default memo(StepPartTextDisplayer);
