import { Component, Fragment } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Edit, Delete, Link } from '@onc/icons';
import {
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from 'base-components';

import DateFormatUtils from 'util/DateFormatUtils';
import ObjectUtils from 'util/ObjectUtils';
import AnnotationActions from './AnnotationActions';

const styles = (theme) => ({
  lineBase: {
    cursor: 'pointer',
  },
  selected: {
    background: '#c5debb !important',
  },

  toBeReviewed: {
    background: '#ea9999',
  },
  lastEdited: {
    background: theme.palette.primary.main, // light blue
  },
  editing: {
    backgroundColor: theme.palette.primary.light, // lighter blue
  },
  attributes: {
    display: 'inline-block',
    textIndent: theme.spacing(2),
  },
  blockSpan: {
    display: 'block',
  },
  annotationTimestamp: {
    marginRight: '16px',
    minWidth: '0',
    marginTop: '0',
    position: 'relative',
    top: '50%',
  },
  timestamp: {
    color: theme.palette.grey[700],
  },
  listItemTextRoot: {
    marginBottom: '0',
    marginTop: '0',
    flex: 'none',
    wordWrap: 'break-word',
  },
  tooltip: {
    display: 'flex',
    width: '100%',
  },
});

const FULL_TIMESTAMP_WIDTH = 133;
const DEFAULT_TIMESTAMP_WIDTH = 36;
const ICON_WIDTH = 26;
const ANNOTATION_PADDING_WIDTH = 16;

class AnnotationLine extends Component {
  static generateLocationString(lat, lon, heading, depth) {
    let coordinateText = '';
    let elevationText = '';

    if (!lat && !lon && !depth && !heading) {
      return undefined;
    }

    if (lat === 0 && lon === 0) {
      coordinateText = 'LAT LON: N/A';
    } else {
      // LATITUDE & LONGITUDE
      coordinateText = `LAT LON: ${Number.parseFloat(lat).toFixed(
        6
      )}, ${Number.parseFloat(lon).toFixed(6)}, `;
    }

    // DEPTH
    elevationText = 'Depth: ';
    elevationText += depth !== 0 ? `${Math.round(depth)}m` : 'N/A';

    // HEADING
    elevationText += ', Heading: ';
    elevationText += heading !== 0 ? `${heading}°` : 'N/A';

    return `${coordinateText} ${elevationText}`;
  }

  static getAnnotationContentWidth(iconArray, fullTimestamp, review) {
    let buffer = 0;
    // Timestamp
    if (fullTimestamp) {
      buffer += FULL_TIMESTAMP_WIDTH;
    } else {
      buffer += DEFAULT_TIMESTAMP_WIDTH;
    }

    // Inner left padding
    buffer += ANNOTATION_PADDING_WIDTH; // Padding between time and content

    // Icons
    const iconsShowing = iconArray.filter(Boolean).length;
    if (iconsShowing > 0) {
      // Action icon padding
      buffer += ANNOTATION_PADDING_WIDTH;

      if (iconsShowing > 2) {
        // Compression into a single three-dot menu
        buffer += ICON_WIDTH;
      } else {
        // However many icons there are
        buffer += iconsShowing * ICON_WIDTH;
      }
    }
    // Vote Icons
    if (review) {
      buffer += 2 * ICON_WIDTH;
    }
    return buffer;
  }

  static propTypes = {
    annotation: PropTypes.shape({
      annotationId: PropTypes.number,
      comment: PropTypes.string,
      createdBy: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      createdDate: PropTypes.string,
      depth: PropTypes.number,
      heading: PropTypes.number,
      lat: PropTypes.number,
      lon: PropTypes.number,
      modifiedBy: PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      displayText: PropTypes.node,
      modifiedDate: PropTypes.string,
      startDate: PropTypes.string,
      taxons: PropTypes.arrayOf(
        PropTypes.shape({
          attributes: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              value: PropTypes.string,
            })
          ),
          taxonomyCode: PropTypes.string,
          annotationReview: PropTypes.shape({
            reviewValue: PropTypes.string,
          }),
        })
      ),
      toBeReviewed: PropTypes.bool,
    }).isRequired,
    classes: PropTypes.shape({
      annotationTimestamp: PropTypes.string,
      attributes: PropTypes.string,
      blockSpan: PropTypes.string,
      left: PropTypes.string,
      lineBase: PropTypes.string,
      listItemTextRoot: PropTypes.string,
      right: PropTypes.string,
      selected: PropTypes.string,
      timestamp: PropTypes.string,
      toBeReviewed: PropTypes.string,
      tooltip: PropTypes.string,
      editing: PropTypes.string,
      lastEdited: PropTypes.string,
    }).isRequired,
    key: PropTypes.number,
    onAnnotationClick: PropTypes.func,
    onAnnotationEditClick: PropTypes.func,
    onAnnotationDeleteClick: PropTypes.func,
    selected: PropTypes.bool,
    displayFullTimestamp: PropTypes.bool,
    displayPositionalData: PropTypes.bool,
    displayModifiedBy: PropTypes.bool,
    displayCopyLink: PropTypes.bool,
    displayEditIcon: PropTypes.bool,
    displayDeleteIcon: PropTypes.bool,
    displayVoteIcons: PropTypes.bool,
    displayCreatedBy: PropTypes.bool,
    displayTaxonAttributes: PropTypes.bool,
    highlightToBeReviewed: PropTypes.bool,
    deletePermissions: PropTypes.bool,
    annotationPermissions: PropTypes.bool,
    reviewPermissions: PropTypes.bool,
    onCopyLink: PropTypes.func,
    onReviewClick: PropTypes.func,
    style: PropTypes.shape({
      height: PropTypes.number,
      left: PropTypes.number,
      position: PropTypes.string,
      top: PropTypes.number,
      width: PropTypes.string,
    }),
    lastEditedAnnotationId: PropTypes.number,
    editAnnotationId: PropTypes.number,
  };

  static defaultProps = {
    key: undefined,
    onAnnotationClick: undefined,
    onAnnotationEditClick: undefined,
    onAnnotationDeleteClick: undefined,
    onCopyLink: undefined,
    onReviewClick: undefined,
    selected: false,
    displayFullTimestamp: false,
    displayPositionalData: false,
    displayModifiedBy: false,
    displayCopyLink: true,
    displayEditIcon: false,
    displayDeleteIcon: false,
    displayVoteIcons: false,
    displayCreatedBy: false,
    displayTaxonAttributes: false,
    highlightToBeReviewed: false,
    deletePermissions: false,
    annotationPermissions: false,
    reviewPermissions: false,
    style: undefined,
    lastEditedAnnotationId: undefined,
    editAnnotationId: undefined,
  };

  sortAttributes = (attributes) =>
    attributes.sort((a, b) => (a.name < b.name ? -1 : 1));

  getContentStyle = () => {
    const {
      displayCopyLink,
      displayEditIcon,
      displayDeleteIcon,
      displayVoteIcons,
      displayFullTimestamp,
      annotation,
    } = this.props;
    const buffer = AnnotationLine.getAnnotationContentWidth(
      [displayCopyLink, displayEditIcon, displayDeleteIcon],
      displayFullTimestamp,
      displayVoteIcons &&
        annotation.taxons &&
        annotation.taxons[0].taxonomyCode === 'WoRMS'
    );
    return { width: `calc(100% - ${buffer}px` };
  };

  renderDisplayText = () => (
    <>
      {this.renderTaxon()}
      {this.renderComment()}
      {this.renderPositionalTags()}
      {this.renderUserText()}
    </>
  );

  renderTaxon = () => {
    const { classes, annotation, displayTaxonAttributes } = this.props;
    const { taxons, displayText } = annotation;

    let taxonText;
    let attributeText;

    if (taxons) {
      taxonText = (
        <Typography variant="body2" className={classes.blockSpan}>
          {displayText}
        </Typography>
      );
      if (taxons[0].attributes && displayTaxonAttributes) {
        const attributes = ObjectUtils.deepClone(taxons[0].attributes);
        attributeText = this.sortAttributes(attributes).map(
          ({ name, value }) => (
            <Fragment key={`${name}-${value}`}>
              <span className={classes.attributes}>{`${name}: ${value}`}</span>
              <br />
            </Fragment>
          )
        );
        attributeText = (
          <Typography variant="body2">{attributeText}</Typography>
        );
      }

      return (
        <div>
          {taxonText}
          {attributeText}
        </div>
      );
    }

    return null;
  };

  renderComment = () => {
    const { annotation, classes } = this.props;
    let finalComment = annotation.comment;
    if (annotation.comment === '') {
      if (!annotation.taxons) finalComment = '-';
      else return undefined;
    }
    return (
      <div>
        <Typography variant="body2">
          <span className={classes.blockSpan}>{finalComment}</span>
        </Typography>
      </div>
    );
  };

  renderUserText = () => {
    const { annotation, classes, displayCreatedBy, displayModifiedBy } =
      this.props;
    if (!displayCreatedBy && !displayModifiedBy) return undefined;

    const { createdBy, createdDate, modifiedBy, modifiedDate } = annotation;
    const creator = displayCreatedBy ? (
      <span className={classes.blockSpan}>
        <Typography variant="caption">{`Created By: ${createdBy.firstName} ${
          createdBy.lastName
        } (${DateFormatUtils.formatDate(createdDate, 'full')})`}</Typography>
      </span>
    ) : undefined;

    const modifier =
      displayModifiedBy && createdDate !== modifiedDate ? (
        <span className={classes.blockSpan}>
          <Typography variant="caption">
            {`Modified By: ${modifiedBy.firstName} ${
              modifiedBy.lastName
            } (${DateFormatUtils.formatDate(modifiedDate, 'full')})`}
          </Typography>
        </span>
      ) : undefined;

    return (
      <div>
        {creator}
        {modifier}
      </div>
    );
  };

  renderPositionalTags = () => {
    const { annotation, classes, displayPositionalData } = this.props;
    const { lat, lon, depth, heading } = annotation;

    if (!displayPositionalData) {
      return undefined;
    }

    const positionText = AnnotationLine.generateLocationString(
      lat,
      lon,
      heading,
      depth
    );

    return (
      <div>
        <span className={classes.blockSpan}>
          <Typography variant="caption">
            <span>{positionText}</span>
          </Typography>
        </span>
      </div>
    );
  };

  renderAnnotationActions = () => {
    const {
      annotation,
      annotationPermissions,
      deletePermissions,
      reviewPermissions,
      onAnnotationDeleteClick,
      onAnnotationEditClick,
      displayCopyLink,
      displayEditIcon,
      displayDeleteIcon,
      displayVoteIcons,
      onCopyLink,
      onReviewClick,
    } = this.props;
    const { taxons } = annotation;
    const options = [];
    // Copy Link
    if (displayCopyLink) {
      options.push({
        label: 'Copy Link',
        onClick: () => onCopyLink(annotation),
        icon: Link,
      });
    }
    // Edit Action
    if (annotationPermissions && displayEditIcon) {
      options.push({
        label: 'Edit Annotation',
        onClick: () => onAnnotationEditClick(annotation),
        icon: Edit,
      });
    }
    // Delete Action
    if (deletePermissions && displayDeleteIcon) {
      options.push({
        label: 'Delete Annotation',
        onClick: () => onAnnotationDeleteClick(annotation),
        icon: Delete,
      });
    }

    let reviewProps;
    if (
      reviewPermissions &&
      displayVoteIcons &&
      taxons &&
      taxons[0].taxonomyCode === 'WoRMS'
    ) {
      const { annotationReview } = taxons[0];
      reviewProps = {
        annotationId: annotation.annotationId,
        reviewValue: annotationReview
          ? annotationReview.reviewValue.toLowerCase()
          : undefined,
        onReviewClick,
      };
    }
    return <AnnotationActions options={options} {...reviewProps} />;
  };

  render() {
    const {
      annotation,
      classes,
      key,
      onAnnotationClick,
      selected,
      displayFullTimestamp,
      highlightToBeReviewed,
      style,
      lastEditedAnnotationId,
      editAnnotationId,
    } = this.props;
    const timeFormat = displayFullTimestamp ? 'full' : 'time-no-seconds';
    const timestamp = DateFormatUtils.formatDate(
      annotation.startDate,
      timeFormat
    );

    let annotationClasses = classes.lineBase;
    annotationClasses += selected ? ` ${classes.selected}` : '';
    if (highlightToBeReviewed)
      annotationClasses += annotation.toBeReviewed
        ? ` ${classes.toBeReviewed}`
        : '';
    const handleAnnotationActionsClick = (e) => e.stopPropagation();

    if (annotation.annotationId === editAnnotationId)
      annotationClasses += ` ${classes.editing}`;
    else if (annotation.annotationId === lastEditedAnnotationId)
      annotationClasses += ` ${classes.lastEdited}`;

    return (
      <ListItem
        style={style}
        className={annotationClasses}
        key={key}
        divider
        alignItems="flex-start"
        onClick={onAnnotationClick}
      >
        <Tooltip
          id="jump-tooltip"
          title={`Jump to ${timestamp}`}
          placement="left"
          disableTouchListener
          disableFocusListener
        >
          <span className={classes.tooltip}>
            <ListItemIcon className={classes.annotationTimestamp}>
              <Typography variant="body2" className={classes.timestamp}>
                {timestamp}
              </Typography>
            </ListItemIcon>
            <ListItemText
              className={classes.listItemTextRoot}
              style={this.getContentStyle()}
              primary={this.renderDisplayText()}
            />
          </span>
        </Tooltip>
        <ListItemSecondaryAction onClick={handleAnnotationActionsClick}>
          {this.renderAnnotationActions()}
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default withStyles(styles)(AnnotationLine);
