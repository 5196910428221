import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class ObisConfigurationService {
  static getObisSettings = () =>
    get('ObisService').then((response) => parseDmasAPIResponse(response));

  static saveObisSettings = ({
    apiUrl,
    contextPath,
    distanceOffset,
    depthOffset,
  }) =>
    post('ObisService', {
      operation: 2,
      apiUrl,
      contextPath,
      distanceOffset,
      depthOffset,
    }).then((response) => response.data);
}

export default ObisConfigurationService;
