import * as React from 'react';
import { createStyles, withStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { HelpOutline } from '@onc/icons';
import { Popover, TextField, Tooltip } from 'base-components';

type Props = {
  anchorEl: HTMLHeadingElement | null;
  value: string;
  onChange: (val: string) => void;
  onClose: () => void;
  error: boolean;
  classes: ClassNameMap;
};

const STYLES = () =>
  createStyles({
    menu: {
      padding: 0,
    },
    keyboardEntry: {
      margin: 0,
      width: '200px',
    },
  });

const HelpAdornment = (
  <Tooltip
    title="Enter the index of the button and submit with the Enter key.  Alternatively, enter the index of the tab you want to navigate to, followed by a decimal and then the index of the button.  For example: 2.13"
    placement="top"
  >
    <span>
      <HelpOutline color="action" />
    </span>
  </Tooltip>
);

const KeyboardEntryTextField: React.VFC<Props> = (props: Props) => {
  const { anchorEl, value, onChange, onClose, error, classes } = props;

  return (
    <Popover
      id="test"
      open={anchorEl}
      className={classes.menu}
      elevation={4}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <TextField
        autoFocus
        translationKey="seatube.keyboardEntry"
        id="keyboard-entry"
        InputLabelProps={{ shrink: !!anchorEl }}
        InputProps={{ endAdornment: HelpAdornment }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={classes.keyboardEntry}
        error={error}
      />
    </Popover>
  );
};

export default withStyles(STYLES)(KeyboardEntryTextField);
