import { useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add, Search } from '@onc/icons';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputAdornment,
  TextField,
} from 'base-components';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import {
  CloneIconButton,
  DeleteIconButton,
} from 'domain/AppComponents/IconButtons';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import Panel from 'library/CompositeComponents/panel/Panel';
import TaxonButtonSetService from '../../services/TaxonButtonSetService';

const styles = (theme) => ({
  listContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  searchBarContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

const TaxonButtonSetList = ({
  onError,
  selectedConfig = undefined,
  setSelectedConfig,
  onInfo,
  classes,
  taxonButtonSetConfigList,
  reloadConfigList,
}) => {
  TaxonButtonSetList.propTypes = {
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    setSelectedConfig: PropTypes.func.isRequired,
    selectedConfig: PropTypes.shape({
      name: PropTypes.string,
      taxonButtonSetHdrId: PropTypes.number,
    }),
    classes: PropTypes.shape({
      contentContainer: PropTypes.string,
      item: PropTypes.string,
      searchBarContainer: PropTypes.string,
      listContainer: PropTypes.string,
    }).isRequired,
    taxonButtonSetConfigList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    reloadConfigList: PropTypes.func.isRequired,
  };

  const [filterText, setFilterText] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const nameExists = (name) =>
    taxonButtonSetConfigList.some((buttonSet) => buttonSet.name === name);

  const getUniqueName = (name) => {
    let newName = name;
    let attempt = 1;
    for (;;) {
      if (!nameExists(newName)) {
        break;
      }
      newName = `${name} (${attempt})`;
      attempt += 1;
    }
    return newName;
  };

  const handleAddButtonSetConfig = () => {
    const name = getUniqueName('New Button Set');
    TaxonButtonSetService.create(name)
      .then((response) => {
        reloadConfigList(response);
        onInfo(`Successfully created "${name}"`);
      })
      .catch((error) => {
        onError(error.response?.data?.message);
      });
  };

  const handleCloneButtonSetConfig = () => {
    const name = getUniqueName(selectedConfig.name);
    const clonedButtonSetConfig = { ...selectedConfig };
    clonedButtonSetConfig.name = name;
    clonedButtonSetConfig.enabled = false;
    clonedButtonSetConfig.taxonButtonSetHdrId = null;
    const buttons = clonedButtonSetConfig.buttons.filter(
      (button) => button.label !== ''
    );
    const finalLines = processTaxonButtonSetLines(buttons);
    clonedButtonSetConfig.taxonButtonSetLines = finalLines;
    TaxonButtonSetService.clone(clonedButtonSetConfig).then((response) => {
      reloadConfigList(response);
      onInfo(`Successfully cloned "${selectedConfig.name}"`);
    });
  };

  const processTaxonButtonSetLines = (buttons) => {
    const finalLines = [];

    buttons.forEach((button) => {
      const { attributes, ...rest } = button;

      if (!attributes) {
        finalLines.push({ ...rest, taxonButtonSetLineId: null });
      } else {
        attributes.forEach((attr) => {
          const {
            attributeValue,
            dataType,
            taxonomyAttributeId,
            taxonomyAttributeLineId,
          } = attr;

          switch (dataType) {
            case 'Select':
              finalLines.push({
                ...rest,
                taxonomyAttributeId,
                taxonomyAttributeLineId,
              });
              break;
            case 'String':
              finalLines.push({ ...rest, taxonomyAttributeId, attributeValue });
              break;
            default:
              break;
          }
        });
      }
    });

    return finalLines;
  };

  const handleDeleteButtonSetConfig = () => {
    setDeleteDialogOpen(false);
    TaxonButtonSetService.delete(selectedConfig.taxonButtonSetHdrId)
      .then(() => {
        reloadConfigList();
        setSelectedConfig(undefined);
        onInfo(`Deleted Taxon Button Set "${selectedConfig.name}"`);
      })
      .catch((error) => {
        onError(error.response?.data?.message);
      });
  };

  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

  const renderItemActions = (isSelected, canDelete) => {
    const actionArray = [];
    actionArray.push(
      <CloneIconButton key="clone" onClick={handleCloneButtonSetConfig} />
    );
    if (canDelete) {
      actionArray.push(
        <DeleteIconButton
          key="delete"
          onClick={() => setDeleteDialogOpen(true)}
        />
      );
    }
    if (isSelected) {
      return <ListItemSecondaryAction>{actionArray}</ListItemSecondaryAction>;
    }
    return null;
  };

  const renderButtonSetConfigs = () => {
    const filteredList = taxonButtonSetConfigList
      .filter((item) =>
        item.name.toLowerCase().includes(filterText.toLowerCase())
      )
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    return filteredList.map((buttonSetConfig) => {
      const isSelected =
        selectedConfig &&
        selectedConfig.taxonButtonSetHdrId ===
          buttonSetConfig.taxonButtonSetHdrId;
      return (
        <ListItem
          button
          disableRipple
          onClick={() => setSelectedConfig(buttonSetConfig)}
          selected={isSelected}
          key={buttonSetConfig.taxonButtonSetHdrId}
          aria-label={buttonSetConfig.name}
        >
          <ListItemText primary={buttonSetConfig.name} />
          {renderItemActions(isSelected, buttonSetConfig.deletePermission)}
        </ListItem>
      );
    });
  };

  return (
    <Panel
      title="Taxon Button Sets"
      actionContent={
        <ContainedButton
          translationKey="taxonomy.addButtonSet"
          startIcon={<Add />}
          onClick={() => handleAddButtonSetConfig()}
        />
      }
    >
      <DeleteDialog
        onCancel={handleDeleteDialogClose}
        onDelete={handleDeleteButtonSetConfig}
        title="Delete Button Set"
        message={`Are you sure you want to delete ${selectedConfig?.name}?`}
        open={deleteDialogOpen}
      />
      <div className={classes.searchBarContainer}>
        <TextField
          translationKey="common.search"
          title="searchField"
          fullWidth
          onChange={(e) => setFilterText(e.target.value)}
          value={filterText}
          className={classes.item}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="action" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.listContainer}>
        <List className={classes.item} dense>
          {renderButtonSetConfigs()}
        </List>
      </div>
    </Panel>
  );
};

export default withStyles(styles)(TaxonButtonSetList);
