import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class LiveDafTestService {
  static getAll() {
    return get('LiveDafTestService', {
      operation: '1',
    }).then((response) => Array.from(parseDmasAPIResponse(response)));
  }

  static getOne(testId) {
    return get('LiveDafTestService', {
      operation: '2',
      testId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(deviceId, duration, ipAddress, comment) {
    return post('LiveDafTestService', {
      operation: '1',
      deviceId,
      duration,
      ipAddress,
      comment,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static update(test, comment) {
    return post('LiveDafTestService', {
      operation: '2',
      testId: test.dafTestId,
      comment,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static cancel(testId) {
    return post('LiveDafTestService', {
      operation: '6',
      testId,
    }).then((response) => parseDmasAPIResponse(response));
  }
}

export default LiveDafTestService;
