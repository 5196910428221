import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

export interface Organization {
  organizationId: number;
  organizationName: string;
  modifyBy: number;
  modifyDate: string;
  organizationEmail?: string;
  rorId?: string;
  logoSmall?: string;
  logoBig?: string;
  organizationType?: string;
  parentOrganization?: Organization;
}

export interface OrganizationPermission {
  organizationName: string;
  organizationId: number;
  isAdmin: boolean;
}

class OrganizationService {
  static getBroadSearchOrganizations = () => [
    { label: 'All', value: 0 },
    { label: 'ONC', value: 134 },
    { label: 'NOAA', value: 340 },
  ];

  static getOrganizations = async (): Promise<Organization[]> => {
    const response = await get('OrganizationService');
    return parseDmasAPIResponse(response);
  };

  static getOrganizationPermissions = async (
    organizationIds: string
  ): Promise<{
    isDmasAdmin: boolean;
    userAdminInfo: OrganizationPermission[];
  }> => {
    const response = await get('OrganizationService', {
      method: 2,
      organizationIds,
    });
    return parseDmasAPIResponse(response);
  };

  static getAllAdditionalInfo() {
    return get('OrganizationService', {
      method: 3,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static createOrganization(organization) {
    const parameters = {
      operation: 1,
      payload: JSON.stringify({
        organization: {
          id: organization.id,
          name: organization.name,
          organizationEmail: organization.organizationEmail,
          organizationUrl: organization.organizationUrl,
          organizationPhone: organization.organizationPhone,
          rorId: organization.rorId,
          logoUrl: organization.logoUrl,
          logoSmall: organization.logoSmall,
          logoBig: organization.logoBig,
          organizationType: organization.type,
          defaultCitation: organization.defaultCitation,
          parentOrganization: organization.parentOrganization,
        },
      }),
    };
    return post('OrganizationService', parameters).then((response) =>
      parseDmasAPIResponse(response)
    );
  }

  static updateOrganization(organization) {
    const parameters = {
      operation: 2,
      payload: JSON.stringify({
        organization: {
          id: organization.id,
          name: organization.name,
          organizationEmail: organization.organizationEmail,
          organizationUrl: organization.organizationUrl,
          organizationPhone: organization.organizationPhone,
          rorId: organization.rorId,
          logoUrl: organization.logoUrl,
          logoSmall: organization.logoSmall,
          logoBig: organization.logoBig,
          organizationType: organization.type,
          defaultCitation: organization.defaultCitation,
          parentOrganization: organization.parentOrganization,
        },
      }),
    };
    return post('OrganizationService', parameters).then((response) =>
      parseDmasAPIResponse(response)
    );
  }
}

export default OrganizationService;
