/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

import * as React from 'react';
import { TabPanel } from '@mui/lab';
import { ConfigurableTabs, TabData, Loading } from '@onc/composite-components';
import {
  type QuickButtonAttribute,
  type QuickEntryButton,
} from 'base-components';
import ButtonSet from 'domain/Apps/seatube/quick-entry/ButtonSet';
import TaxonAttributeService from 'domain/services/TaxonAttributeService';
import TaxonButtonSetService from 'domain/services/TaxonButtonSetService';
import useWebService from 'util/hooks/useWebService';
import KeyboardEntryPopover from './KeyboardEntryPopover';
import Util from './TabbedButtonSetUtil';

type Props = {
  showIndexes?: boolean;
  onSubmit: (button: QuickEntryButton) => void;
  quickSave: boolean;
  hasFocus?: boolean;
  initialIndex?: number;
  initialTabs?: TabData[];
  onIndexChange?: (index: number) => void;
  onTabsChange?: (tabs: TabData[]) => void;
};

const TabbedButtonSetSelector = ({
  showIndexes = undefined,
  hasFocus = true,
  onSubmit,
  quickSave,
  initialTabs = [],
  initialIndex = 0,
  onIndexChange = () => {},
  onTabsChange = () => {},
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);
  const [selectedTabs, setSelectedTabs] = useState<TabData[]>(initialTabs);
  const [keyboardEntry, setKeyboardEntry] = useState<string>('');
  const [entryError, setEntryError] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLHeadingElement | null>(
    null
  );
  const divRef = useRef<HTMLHeadingElement>(null);

  const [buttonData, , fetchButtonSets] = useWebService({
    method: TaxonButtonSetService.getAll,
  });

  const [, , fetchAttributes] = useWebService({
    method: TaxonAttributeService.getAttributes,
  });

  const handleSubmit = async (button: QuickEntryButton) => {
    const updatedButton = { ...button };
    updatedButton.quickSave = quickSave;
    if (button.taxonomyId && button.taxonId) {
      const attributeData = await fetchAttributes(
        button.taxonomyId,
        button.taxonId
      );
      const matrixAttributes = attributeData.getCurrentAttributes();
      const attributes: QuickButtonAttribute[] = matrixAttributes.map((attr) =>
        attr.toQuickButtonAttribute()
      );
      updatedButton.attributes = attributes;
      return onSubmit(updatedButton);
    }
    return onSubmit(updatedButton);
  };

  useEffect(() => {
    onIndexChange(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (selectedTabs) {
      // Remove any values from the selected tabs
      const updatedTabs = selectedTabs.map((tab) => ({
        ...tab,
        value: undefined,
      }));
      onTabsChange(updatedTabs);
    }
  }, [selectedTabs]);

  useEffect(() => {
    fetchButtonSets(true);
  }, []);

  useEffect(() => {
    if (isNaN(keyboardEntry as any)) {
      return setEntryError(true);
    }
    const { buttonSetIndex, buttonIndex } = Util.getInputArray(keyboardEntry);
    if (buttonSetIndex !== undefined) {
      if (
        buttonSetIndex > selectedTabs.length ||
        !selectedTabs[buttonSetIndex]
      ) {
        return setEntryError(true);
      }
      if (buttonSetIndex >= 0) {
        setCurrentIndex(Number(buttonSetIndex));
      }
    }
    if (buttonIndex && selectedTabs[currentIndex]) {
      const currentButton = selectedTabs[currentIndex].value.buttons.find(
        (button: QuickEntryButton) => button.sequenceNumber === buttonIndex
      );
      if (currentButton === undefined || currentButton.label === '') {
        return setEntryError(true);
      }
    }
    return setEntryError(false);
  }, [keyboardEntry, buttonData]);

  useEffect(() => {
    const handleKeyboardEntrySubmit = () => {
      const { buttonIndex } = Util.getInputArray(keyboardEntry);
      if (selectedTabs[currentIndex] && !entryError) {
        const currentButton = selectedTabs[currentIndex].value.buttons.find(
          (button: QuickEntryButton) => button.sequenceNumber === buttonIndex
        );
        handleSubmit(currentButton);
      }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === '`' && hasFocus) {
        setAnchorEl(anchorEl ? null : divRef.current);
        e.preventDefault();
        e.stopPropagation();
      }
      if (e.key === 'Enter' && anchorEl && !entryError) {
        handleKeyboardEntrySubmit();
        setAnchorEl(null);
        setKeyboardEntry('');
      }
    };

    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [anchorEl, entryError, keyboardEntry, hasFocus]);

  const handleTabChange = (index: number) => {
    setCurrentIndex(index);
  };

  const handleAddTab = (tab: TabData) => {
    const updatedTabs = [...selectedTabs];
    updatedTabs.push(tab);
    handleTabChange(updatedTabs.length - 1);
    setSelectedTabs(updatedTabs);
  };

  const handleRemoveTab = (index: number) => {
    const updatedTabs = [...selectedTabs];
    updatedTabs.splice(index, 1);
    if (currentIndex > index) {
      handleTabChange(currentIndex - 1);
    }
    if (currentIndex && currentIndex > updatedTabs.length - 1) {
      handleTabChange(updatedTabs.length - 1);
    }
    setSelectedTabs(updatedTabs);
  };

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  const renderTabPanels = () =>
    buttonData ? (
      buttonData.map((buttonSet) => (
        <TabPanel
          value={buttonSet.taxonButtonSetHdrId.toString()}
          sx={{ padding: 0 }}
        >
          <ButtonSet
            onButtonClick={handleSubmit}
            currentButtonSet={buttonSet.buttons}
            showIndexes={showIndexes}
          />
        </TabPanel>
      ))
    ) : (
      <></>
    );

  if (buttonData === undefined) {
    return <Loading />;
  }

  return (
    <div ref={divRef}>
      <ConfigurableTabs
        currentTab={currentIndex}
        onChangeTab={handleTabChange}
        options={
          buttonData
            ? buttonData
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((set) => ({
                  label: set.name,
                  id: set.taxonButtonSetHdrId.toString(),
                  value: set,
                }))
            : []
        }
        onAddTab={handleAddTab}
        onRemoveTab={handleRemoveTab}
        selectedTabs={selectedTabs}
      >
        <KeyboardEntryPopover
          value={keyboardEntry}
          onChange={setKeyboardEntry}
          onClose={handleClosePopper}
          anchorEl={anchorEl}
          error={entryError}
        />

        {renderTabPanels()}
      </ConfigurableTabs>
    </div>
  );
};

export default TabbedButtonSetSelector;
