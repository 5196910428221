import { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { Autocomplete, DateTimePicker, Grid, Paper } from 'base-components';
import {
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import { FloatRightButtonWideStyles } from 'library/CompositeComponents/button/CommonButtonStyles';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const DEVICE_DEFAULT = 'All Devices';
const REVIEW_STATUS_DEFAULT = 'Any';
const REVIEW_STATUS_FALSE = 'In Progress';
const REVIEW_STATUS_TRUE = 'Reviewed';
const STATION_DEFAULT = 'All Stations';

const STYLES = (theme) => ({
  gridPadding: {
    paddingLeft: theme.spacing(),
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  paper: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  filter: {
    width: 375,
    display: 'block',
  },
  formButtons: FloatRightButtonWideStyles,
});

const SiteDeviceSubsetSelector = ({
  classes = undefined,
  selectionCallBack,
  selectedSubsets = [],
  siteDeviceSubsets,
}) => {
  const [siteDeviceSubsetSelectValue, setSiteDeviceSubsetSelectValue] =
    useState(null);
  const [stations, setStations] = useState([]);
  const [stationSelectValue, setStationSelectValue] = useState(STATION_DEFAULT);
  const [devices, setDevices] = useState([]);
  const [deviceSelectValue, setDeviceSelectValue] = useState(DEVICE_DEFAULT);
  const [dateFrom, setDateFrom] = useState(new Date(2017, 0, 1));
  const [dateTo, setDateTo] = useState(new Date());
  const [reveiwStatuses] = useState([
    REVIEW_STATUS_DEFAULT,
    REVIEW_STATUS_TRUE,
    REVIEW_STATUS_FALSE,
  ]);
  const [reviewStatusSelectValue, setReviewStatusSelectValue] = useState(
    REVIEW_STATUS_DEFAULT
  );

  useEffect(() => {
    const newDevices = siteDeviceSubsets
      .map((subset) => subset.deviceId)
      .filter((id) => id !== undefined)
      .filter((value, index, self) => self.indexOf(value) === index)
      .concat(DEVICE_DEFAULT)
      .map((deviceId) => `${deviceId}`);
    setDevices(newDevices);

    const newStations = siteDeviceSubsets
      .map((subset) => subset.stationCode)
      .filter((code) => code !== undefined)
      .filter((value, index, self) => self.indexOf(value) === index)
      .concat(STATION_DEFAULT);
    setStations(newStations);
  }, [siteDeviceSubsets]);

  const onSubsetChange = (event, subset) => {
    setSiteDeviceSubsetSelectValue(subset);
    if (!subset) {
      return;
    }
    selectionCallBack([subset]);
  };

  const addAllFilteredSubsets = () => {
    selectionCallBack(getFilteredSubsets());
  };

  const handleDateFromChange = (date) => {
    if (date === null || isNaN(date.toDate().getTime())) {
      return;
    }
    setDateFrom(date.toDate());
  };

  const handleDateToChange = (date) => {
    if (date === null || isNaN(date.toDate().getTime())) {
      return;
    }
    setDateTo(date.toDate());
  };

  const onReviewStatusChange = (event, value) => {
    if (!value) {
      return;
    }
    setReviewStatusSelectValue(value);
  };

  const onStationChange = (event, value) => {
    if (!value) {
      return;
    }
    setStationSelectValue(value);
  };

  const onDeviceChange = (event, value) => {
    if (!value) {
      return;
    }
    setDeviceSelectValue(value);
  };

  const label = (ID) => `${ID}`;

  const resetFilters = () => {
    setSiteDeviceSubsetSelectValue(null);
    setDateFrom(new Date(2017, 0, 1));
    setDateTo(new Date());
    setStationSelectValue(STATION_DEFAULT);
    setReviewStatusSelectValue(REVIEW_STATUS_DEFAULT);
    setDeviceSelectValue(DEVICE_DEFAULT);
  };

  const getFilteredSubsets = () => {
    // remove subsets allready selected
    let filteredSubsets = siteDeviceSubsets.filter(
      (el) => !Array.from(selectedSubsets).includes(el)
    );
    // filter by date time
    filteredSubsets = filteredSubsets.filter(
      (cast) =>
        (!dateFrom ||
          new Date(cast.startDate).getTime() >= dateFrom.getTime()) &&
        (!dateTo || new Date(cast.startDate).getTime() <= dateTo.getTime())
    );
    // filter by  device
    if (deviceSelectValue !== DEVICE_DEFAULT) {
      filteredSubsets = filteredSubsets.filter(
        (subset) => subset.deviceId === parseInt(deviceSelectValue, 10)
      );
    }
    // filter by station
    if (stationSelectValue !== STATION_DEFAULT) {
      filteredSubsets = filteredSubsets.filter(
        (subset) => subset.stationCode === stationSelectValue
      );
    }
    // Filter by review status
    if (reviewStatusSelectValue === REVIEW_STATUS_TRUE) {
      filteredSubsets = filteredSubsets.filter((subset) => subset.castReviewed);
    } else if (reviewStatusSelectValue === REVIEW_STATUS_FALSE) {
      filteredSubsets = filteredSubsets.filter(
        (subset) => !subset.castReviewed
      );
    }
    return filteredSubsets;
  };

  return (
    <Paper className={classes.paper}>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid container item xs={12} md={6} lg={4}>
          <Grid item xs={12} className={classes.gridPadding}>
            <Autocomplete
              multiple={false}
              options={stations}
              onChange={onStationChange}
              value={stationSelectValue}
              virtualized
              getOptionLabel={label}
              translationKey="device.station"
              name="StationAutocomplete"
              className={classes.filter}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridPadding}>
            <Autocomplete
              multiple={false}
              options={devices}
              onChange={onDeviceChange}
              value={deviceSelectValue}
              virtualized
              getOptionLabel={label}
              translationKey="device.device"
              name="DeviceAutocomplete"
              className={classes.filter}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6} lg={4}>
          <Grid item xs={12} className={classes.gridPadding}>
            <DateTimePicker
              translationKey="common.datepickers.dateFrom"
              value={moment.utc(dateFrom)}
              onChange={handleDateFromChange}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridPadding}>
            <DateTimePicker
              translationKey="common.datepickers.dateTo"
              value={moment.utc(dateTo)}
              onChange={handleDateToChange}
              minDateTime={moment.utc(dateFrom)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={4}>
          <Grid item xs={12} md={6} lg={12} className={classes.gridPadding}>
            <Autocomplete
              multiple={false}
              options={reveiwStatuses}
              onChange={onReviewStatusChange}
              value={reviewStatusSelectValue}
              virtualized
              getOptionLabel={label}
              translationKey="device.reviewStatus"
              name="ReviewAutocomplete"
              className={classes.filter}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={12} className={classes.gridPadding}>
            <div className={classes.filter}>
              <Autocomplete
                virtualized
                getOptionLabel={(siteDeviceSubset) => {
                  if (siteDeviceSubset) {
                    return `${siteDeviceSubset.siteDeviceSubsetName} (${siteDeviceSubset.siteDeviceSubsetId})`;
                  }
                  return '';
                }}
                name="SiteDeviceSubsetListAutocomplete"
                onChange={onSubsetChange}
                options={getFilteredSubsets()}
                value={siteDeviceSubsetSelectValue}
                translationKey="device.siteDeviceSubsetSelection"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="flex-start" justifyContent="flex-end">
        <Grid item xs={12} md={6} lg={4} className={classes.gridPadding}>
          <div className={classes.formButtons}>
            <TextButton
              translationKey="common.buttons.resetFilters"
              onClick={resetFilters}
            />
            <ContainedButton
              translationKey="device.addToTable"
              startIcon={<Add />}
              onClick={addAllFilteredSubsets}
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

SiteDeviceSubsetSelector.propTypes = {
  classes: PropTypes.shape({
    gridPadding: PropTypes.string,
    formButtons: PropTypes.string,
  }),
  selectionCallBack: PropTypes.func.isRequired,
  selectedSubsets: PropTypes.arrayOf(
    PropTypes.shape({
      castReviewed: PropTypes.bool,
      endDate: PropTypes.string, // "2018-01-01T01:24:34.000Z"
      generationType: PropTypes.number, // "automated"
      referenceDepth: PropTypes.number, // 100.000000000000000
      referenceLat: PropTypes.number, // 49.398352504970640
      referenceLon: PropTypes.number, // -124.558354807672120
      siteDeviceId: PropTypes.number, // 1200682
      siteDeviceSubsetId: PropTypes.number, // 3820
      siteDeviceSubsetName: PropTypes.string, // BMP-001
      siteDeviceSubsetType: PropTypes.number, // UP_CAST
      startDate: PropTypes.string, // "2018-01-01T01:22:34.000Z"
    })
  ),
  siteDeviceSubsets: PropTypes.arrayOf(
    PropTypes.shape({
      castReviewed: PropTypes.bool,
      endDate: PropTypes.string, // "2018-01-01T01:24:34.000Z"
      generationType: PropTypes.number, // "automated"
      referenceDepth: PropTypes.number, // 100.000000000000000
      referenceLat: PropTypes.number, // 49.398352504970640
      referenceLon: PropTypes.number, // -124.558354807672120
      siteDeviceId: PropTypes.number, // 1200682
      siteDeviceSubsetId: PropTypes.number, // 3820
      siteDeviceSubsetName: PropTypes.string, // BMP-001
      siteDeviceSubsetType: PropTypes.number, // UP_CAST
      startDate: PropTypes.string, // "2018-01-01T01:22:34.000Z"
    })
  ).isRequired,
};

export default withStyles(STYLES)(withSnackbars(SiteDeviceSubsetSelector));
