import { purple, green, yellow } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const oncOtherTheme = createTheme({
  palette: {
    primary: purple,
    secondary: green,
    error: yellow,
  },
});

export default oncOtherTheme;
