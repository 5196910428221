import { Box } from 'base-components';
import EarthquakeNotificationRegistrationPage from './EarthquakeNotificationRegistrationPage';

const EarthquakeNotificationRegistrationContainer: React.FC = () => (
  <Box>
    <EarthquakeNotificationRegistrationPage />
  </Box>
);

export default EarthquakeNotificationRegistrationContainer;
