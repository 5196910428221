import React from 'react';
import { SkipPrevious } from '@onc/icons';

import { IconButton } from 'base-components';

type PrevVideoButtonProps = {
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  containerRef?: HTMLDivElement;
};

const PrevVideoButton: React.FC<PrevVideoButtonProps> = ({
  disabled = false,
  onClick,
  containerRef = undefined,
}: PrevVideoButtonProps) => (
  <IconButton
    disabled={disabled}
    color="inherit"
    onClick={onClick}
    aria-label="Previous Video"
    tooltipProps={{
      slotProps: {
        popper: { container: containerRef },
      },
    }}
  >
    <SkipPrevious color="inherit" />
  </IconButton>
);

export default PrevVideoButton;
