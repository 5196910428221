import { Component } from 'react';

import { Menu } from 'base-components';

type TreeMenuProps = {
  getTreePath?: (treePath: string) => void;
  children?: import('react').ReactNode;
};

type TreeMenuState = {
  treePath: string;
};
class TreeMenu extends Component<TreeMenuProps, TreeMenuState> {
  static defaultProps = {
    getTreePath: () => '',
    children: undefined,
  };

  constructor(props) {
    super(props);

    this.state = { treePath: null };
  }

  // This is being used in a test method but the linter doesn't catch that
  // eslint-disable-next-line react/no-unused-class-component-methods
  getTreePath = () => {
    const { treePath } = this.state;
    const { getTreePath } = this.props;

    return getTreePath(treePath);
  };

  render() {
    const { children } = this.props;
    return <Menu menuList>{children}</Menu>;
  }
}

export default TreeMenu;
