import { createContext } from 'react';

export interface EarthquakeContextInterface {
  earthquakes: any;
  siteMap: any;
  visibleEarthquakes: any;
  detectionArea: any;
  dashboardId: string;
}

const EarthquakeContext = createContext<EarthquakeContextInterface>({
  earthquakes: [],
  siteMap: [],
  visibleEarthquakes: [],
  detectionArea: [],
  dashboardId: '10',
});

export default EarthquakeContext;
