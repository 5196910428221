import GeospatialSearchHelper from 'domain/Apps/geospatial-search/community-fisher/GeospatialSearchHelper';

class StationProvider {
  stations = [];

  constructor(
    getCasts,
    getUnassignedCasts,
    assignedCastData,
    unassignedCastData
  ) {
    if (getUnassignedCasts && unassignedCastData) {
      const formatedData = unassignedCastData.map((cast) => ({
        label: cast.siteDeviceSubsetName,
        x: cast.referenceLon,
        y: cast.referenceLat,
      }));
      this.stations = this.stations.concat(formatedData);
    }
    if (getCasts && assignedCastData) {
      const castAreas =
        GeospatialSearchHelper.generateLabelPosition(assignedCastData);
      this.stations = this.stations.concat(castAreas);
    }
  }

  search({ query }) {
    return this.stations.filter((station) =>
      station.label.toUpperCase().includes(query.toUpperCase())
    );
  }
}

export default StationProvider;
