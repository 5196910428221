import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class DeviceProcurementService {
  static get(deviceId) {
    return get('DeviceProcurementService', {
      operation: 4,
      deviceId,
    }).then((response) => response);
  }

  static create(deviceId, params) {
    return post('DeviceProcurementService', {
      operation: 1,
      deviceId,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static update(deviceId, params) {
    return post('DeviceProcurementService', {
      operation: 2,
      deviceId,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default DeviceProcurementService;
