import ContinuousVideoFiles from './ContinuousVideoFiles';
import SeaTubeSubPlaylist from './SeaTubeSubPlaylist';

class SeaTubePlaylistFiles extends ContinuousVideoFiles {
  static from(json) {
    if (json.datafiles.length === 0) {
      return [];
    }

    // Use map to preserve order of video files
    const fileMap = new Map();

    json.datafiles.forEach((file) => {
      if (!fileMap.has(file.playlistLineId)) {
        fileMap.set(file.playlistLineId, []);
      }
      fileMap.get(file.playlistLineId).push(file);
    });

    const newFiles = Array.from(fileMap.values()).map(
      (files) =>
        new SeaTubePlaylistFiles(
          json.availableResolutions,
          json.datafileResolution,
          files
        )
    );
    return newFiles;
  }

  constructor(qualityOptions, currentQuality, files) {
    super(qualityOptions, currentQuality);

    this.dataFiles = files
      .map((file) => ({
        ...file,
        dateFrom: new Date(file.dateFrom).getTime(),
        dateTo: new Date(file.dateTo).getTime(),
      }))
      .sort((a, b) => a.dateFrom - b.dateFrom);

    this.playlistLineId = this.dataFiles[0].playlistLineId;
    this.dataFileIndex = 0;
  }

  /* *****************
   * Cursor movement *
   ***************** */

  selectClipAtTime = (secondsSinceStart) => {
    if (secondsSinceStart === undefined) return undefined;

    const ms = this.getDateFrom() + secondsSinceStart * 1000;
    const index = this.dataFiles.findIndex(
      (file) => ms >= file.dateFrom && ms < file.dateTo
    );

    if (index < 0) return undefined;

    this.dataFileIndex = index;
    return this.dataFiles[this.dataFileIndex];
  };

  selectNextClip = () => {
    if (this.dataFileIndex >= this.dataFiles.length - 1)
      this.dataFileIndex = -1;

    this.dataFileIndex += 1;
    return this.dataFiles[this.dataFileIndex];
  };

  /* ****************
   * Get real dates *
   **************** */

  // Internal function
  getDateFrom = () => this.dataFiles[0].dateFrom;

  // Internal function
  getDateTo = () => this.dataFiles[this.dataFiles.length - 1].dateTo;

  getDateAt = (secondsSinceStart) => {
    if (secondsSinceStart < 0 || secondsSinceStart > this.getDuration())
      return undefined;

    return new Date(this.getDateFrom() + secondsSinceStart * 1000);
  };

  /* ********************************************
   * Get elapsed time (seconds of video played) *
   ******************************************** */

  getDuration = () => (this.getDateTo() - this.getDateFrom()) / 1000;

  getDeviceIdAt = () => this.dataFiles[0].deviceId;

  getTimeElapsedAtDate = (date) => {
    if (date === undefined) return undefined;

    const ms = date instanceof Date ? date.getTime() : date * 1000;
    if (ms < this.getDateFrom() || ms >= this.getDateTo()) return undefined;

    return (ms - this.getDateFrom()) / 1000;
  };

  /**
   * Return the start of the current clip, in seconds since the start of the
   * playlist line. Note that the above means that if the current clip is the
   * first clip, and the playlist line starts within the clip, then this returns
   * a negative number. This is deliberate so that (elapsed -
   * files.getTimeElapsedToCurrentClip()) returns a value after the start of the
   * playlist line.
   */
  getTimeElapsedToCurrentClip = () =>
    this.getTimeElapsedToClipAt(this.dataFileIndex);

  getTimeElapsedToNextClip = () =>
    this.getTimeElapsedToClipAt(this.dataFileIndex + 1) || 0;

  getTimeElapsedToPreviousClip = () =>
    this.getTimeElapsedToClipAt(this.dataFileIndex - 1) || 0;

  getTimeElapsedToFirstClip = () => this.getTimeElapsedToClipAt(0);

  getTimeElapsedToFinalClip = () =>
    this.getTimeElapsedToClipAt(this.dataFiles.length - 1);

  // Internal function
  getTimeElapsedToClipAt = (index) => {
    if (index < 0 || index >= this.dataFiles.length) return undefined;

    return (
      (this.dataFiles[index].dateFrom - this.getDateFrom()) / 1000 -
      (this.dataFiles[index].start || 0)
    );
  };

  /** Return the information map for the video player info menu */
  getInfoMap = () => {
    const infoMap = new Map();
    const dataFile = this.dataFiles[this.dataFileIndex];
    infoMap.set('Path', dataFile.fullname);
    infoMap.set('Device Id', dataFile.deviceId);
    infoMap.set('File Name', dataFile.datafileName);
    return infoMap;
  };

  /* ********************
   * JWPlayer playlists *
   ******************** */

  buildPlaylist = (length = 1) => {
    const playlist = new SeaTubeSubPlaylist();
    playlist.start = this.getTimeElapsedToCurrentClip();

    for (let i = 0; i < length; i += 1) {
      if (this.dataFileIndex + i >= this.dataFiles.length) break;

      const file = this.dataFiles[this.dataFileIndex + i];
      playlist.addFromRaw(file.datafileName, file);
    }

    return playlist;
  };
}

export default SeaTubePlaylistFiles;
