import { makeStyles } from '@mui/styles';
import { OpenInNew } from '@onc/icons';
import { Link } from 'base-components';

const useStyles = makeStyles({
  centerIcon: {
    display: 'flex',
    alignItems: 'center',
  },
});

type OpenInNewLinkProps = {
  href?: string;
  children?: React.ReactNode;
};
const OpenInNewLink = ({
  href = '',
  children = undefined,
}: OpenInNewLinkProps) => {
  const classes = useStyles();
  return (
    <Link className={classes.centerIcon} href={href} target="_blank">
      {children}&nbsp; <OpenInNew fontSize="small" />
    </Link>
  );
};

export default OpenInNewLink;
