import PropTypes from 'prop-types';

import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import Environment from 'util/Environment';

const getEarthquakeDetailUrl = (earthquakeId) =>
  `${Environment.getDmasUrl()}/EarthquakeDetail?earthquakeId=${earthquakeId}`;

const openEarthquakeDetail = (earthquakeId) => {
  global.open(getEarthquakeDetailUrl(earthquakeId), '_self');
};

const handleOpenEarthquakeDetail = (earthquakeId) => () => {
  openEarthquakeDetail(earthquakeId);
};

// eslint-disable-next-line react/require-default-props
const EarthquakeDetailLink = ({ earthquakeId, children = undefined }) => (
  <OpenInNewLink href={getEarthquakeDetailUrl(earthquakeId)}>
    {children}
  </OpenInNewLink>
);

EarthquakeDetailLink.propTypes = {
  earthquakeId: PropTypes.number.isRequired,
  children: PropTypes.node,
};

export { handleOpenEarthquakeDetail, openEarthquakeDetail };
export default EarthquakeDetailLink;
