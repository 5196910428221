export type UserOrganization = {
  userId: number;
  firstname: string;
  lastname: string;
  email: string;
  organizationId: number;
  organizationName: string;
  modifyDate: string;
  modifyBy: string;
};

export type UserOrganizationRow = {
  userId: number;
  organizationId: number;
  userInfo: string;
  organizationInfo: string;
  modifyBy: string;
  modifyDate: string;
};

export const transformUserOrganizationRows = (
  userOrganizations: UserOrganization[]
): UserOrganizationRow[] =>
  userOrganizations.map((item) => ({
    userId: item.userId,
    organizationId: item.organizationId,
    userInfo: `${item.userId} ${item.firstname} ${item.lastname} (${item.email})`,
    organizationInfo: `${item.organizationId} ${item.organizationName}`,
    modifyBy: item.modifyBy,
    modifyDate: item.modifyDate,
  }));
