import { useState } from 'react';
import { makeStyles } from '@mui/styles';

import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { Grid, Paper, RouterLink, Typography } from 'base-components';
import PlaylistsDropdown from 'domain/AppComponents/dropdowns/PlaylistsDropdown';
import {
  CancelButton,
  OutlinedButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';
import {
  DFManagementEditorProps,
  CampaignPayload,
  TaxonsPayload,
} from './DFManagementEditor';
import DigitalFishersService from '../../DigitalFishersService';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
  imageUpload: {
    display: 'none',
  },
  img: {
    maxHeight: theme.spacing(16),
  },
  loadingContainer: {
    height: '90px',
    width: '160px',
    textAlign: 'center',
  },
}));

const ChooseImageButton = ({
  className,
  onChange,
}: {
  className: string;
  onChange: (changeEvent: any) => void;
}) => (
  <OutlinedButton translationKey="common.buttons.chooseImage" component="label">
    <input
      className={className}
      type="file"
      onChange={(event) => onChange(event)}
    />
  </OutlinedButton>
);

type CampaignValuesProps = {
  campaign: {
    active: boolean;
    campaignName: string;
    dfCampaignId: number;
    startDate: Moment;
    endDate: Moment;
    missionStatement: string;
    missionTitle: string;
    formSectionId?: number;
    videos?: string;
    playlistHdrId: number;
    taxonomyMatrixId?: number;
  };
  taxonsArr: Array<{ label: string; value: number }>;
};

type CampaignPost = CampaignValuesProps['campaign'] & {
  campaignImage: string;
};

type DFManagementEditorFormProps = DFManagementEditorProps & {
  campaignData: CampaignPayload;
  taxonsData: TaxonsPayload;
  playlistName?: string;
  onError: (message: string) => void;
  onInfo: (message: string) => void;
};

type DFManagementEditorFormMethods = {
  campaignName: string;
  MissionTitle: string;
  MissionStatement: string;
  Video: string;
  playlistHdrId: { key: number; label: string; value: number };
  FormSection: number;
  campaignRoot: { label: string; value: number };
  startDate: Moment;
  endDate: Moment;
  active: boolean;
};

const DFManagementEditorForm = ({
  landingPath,
  match = undefined,
  campaignData,
  taxonsData,
  playlistName = '',
  onError,
  onInfo,
}: DFManagementEditorFormProps) => {
  const classes = useStyles();

  const [campaignImage, setCampaignImage] = useState<string>(
    campaignData?.campaign.campaignImage || undefined
  );
  const [redirect, setRedirect] = useState<boolean>(false);

  const formatTaxons = () => {
    const taxonsFormatted = taxonsData.map((taxon) => ({
      label: taxon.commonName,
      value: taxon.taxonomyMatrixId,
    }));

    return taxonsFormatted;
  };

  const campaignValues: CampaignValuesProps = {
    campaign: campaignData
      ? {
          ...campaignData.campaign,
          startDate: moment(campaignData.campaign.startDate),
          endDate: moment(campaignData.campaign.endDate),
          playlistHdrId: campaignData.campaign.playlistHdrId
            ? campaignData.campaign.playlistHdrId
            : undefined,
        }
      : {
          active: false,
          campaignName: '',
          dfCampaignId: 0,
          startDate: moment(),
          endDate: moment(),
          missionStatement: '',
          missionTitle: '',
          formSectionId: 0,
          videos: '',
          taxonomyMatrixId: undefined,
          playlistHdrId: undefined,
        },
    taxonsArr: formatTaxons(),
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formMethods = useForm<DFManagementEditorFormMethods>({
    defaultValues: {
      campaignName: campaignValues.campaign.campaignName,
      MissionTitle: campaignValues.campaign.missionTitle,
      MissionStatement: campaignValues.campaign.missionStatement,
      Video: campaignValues.campaign.videos,
      playlistHdrId: {
        key: campaignValues.campaign.playlistHdrId,
        value: campaignValues.campaign.playlistHdrId,
        label: playlistName,
      },
      FormSection: campaignValues.campaign.formSectionId,
      campaignRoot: {
        label: campaignValues.campaign.taxonomyMatrixId
          ? campaignValues.taxonsArr.find(
              (taxon) =>
                taxon.value === campaignValues.campaign.taxonomyMatrixId
            ).label
          : undefined,
        value: campaignValues.campaign.taxonomyMatrixId,
      },
      startDate: campaignValues.campaign.startDate,
      endDate: campaignValues.campaign.endDate,
      active: campaignValues.campaign.active,
    },
    mode: 'onBlur',
  });

  const handleSave: (values: DFManagementEditorFormMethods) => void = (
    values
  ) => {
    const formattedCampaign: CampaignPost = {
      ...campaignValues.campaign,
      active: values.active,
      campaignName: values.campaignName,
      startDate: moment(values.startDate),
      endDate: moment(values.endDate),
      missionStatement: values.MissionStatement,
      missionTitle: values.MissionTitle,
      formSectionId: values.FormSection,
      videos: values.Video,
      taxonomyMatrixId: values.campaignRoot?.value,
      playlistHdrId: values.playlistHdrId ? values.playlistHdrId.key : null,
      campaignImage,
    };

    let promise;
    if (Number(match.params.campaignId) === 0) {
      promise = DigitalFishersService.createCampaign(formattedCampaign);
    } else {
      promise = DigitalFishersService.updateCampaign(formattedCampaign);
    }

    promise
      .then(() => {
        onInfo(
          'Save Successful! Redirecting you to Campaign Management page...'
        );
        setRedirect(true);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const handleImageChange = (event) => {
    const fileData = new FileReader();
    fileData.onloadend = (imageEvent) => {
      if (typeof imageEvent.target.result !== 'string') {
        const decoder = new TextDecoder('utf-8');
        setCampaignImage(decoder.decode(imageEvent.target.result));
      } else {
        setCampaignImage(imageEvent.target.result);
      }
    };
    fileData.readAsDataURL(event.target.files[0]);
  };

  const renderVideoTextField = () => {
    if (
      campaignValues.campaign.dfCampaignId !== 0 &&
      (!campaignValues.campaign.videos || campaignValues.campaign.videos === '')
    )
      return <></>;
    return (
      <Grid item xs={12} md={7} lg={7}>
        <FormTextField
          name="Video"
          translationKey="digitalFishers.videoSegments"
          fullWidth
          multiline
          maxRows={6.5}
          rules={{
            validate: {
              verifyVideoField: () => {
                campaignValues.campaign.videos = formMethods.getValues().Video;
                if (
                  (!campaignValues.campaign.videos ||
                    campaignValues.campaign.videos === '') &&
                  (!campaignValues.campaign.playlistHdrId ||
                    isNaN(campaignValues.campaign.playlistHdrId))
                ) {
                  return 'Video Segments are required if no playlist selected.';
                }
                return true;
              },
            },
          }}
        />
      </Grid>
    );
  };

  const renderPlaylistsDropdown = () => {
    if (
      campaignValues.campaign.dfCampaignId !== 0 &&
      (!campaignValues.campaign.playlistHdrId ||
        campaignValues.campaign.playlistHdrId === 0)
    )
      return <></>;
    return (
      <Grid item xs={12} md={7} lg={7}>
        <PlaylistsDropdown
          name="playlistHdrId"
          fullWidth
          rules={{
            validate: {
              verifyPlaylistField: () => {
                campaignValues.campaign.playlistHdrId = formMethods.getValues()
                  .playlistHdrId
                  ? formMethods.getValues().playlistHdrId.key
                  : undefined;
                if (
                  (campaignValues.campaign.videos === undefined ||
                    campaignValues.campaign.videos === '') &&
                  !campaignValues.campaign.playlistHdrId
                ) {
                  return 'Playlist is required if no video segments entered.';
                }
                return true;
              },
            },
          }}
        />
      </Grid>
    );
  };

  const renderCampaignRootTaxonSelect = () => {
    if (
      campaignValues.campaign.dfCampaignId !== 0 &&
      (!campaignValues.campaign.taxonomyMatrixId ||
        campaignValues.campaign.taxonomyMatrixId === 0)
    )
      return <></>;
    return (
      <Grid item xs={12} md={7} lg={7}>
        <FormAutocomplete
          id="standard-select"
          fullWidth
          translationKey="digitalFishers.campaignRootTaxon"
          name="campaignRoot"
          options={campaignValues.taxonsArr}
          rules={{
            validate: {
              verifyTaxonField: () => {
                campaignValues.campaign.taxonomyMatrixId =
                  formMethods.getValues().campaignRoot.value;
                if (!campaignValues.campaign.taxonomyMatrixId) {
                  return 'Campaign Root Taxon is required.';
                }
                return true;
              },
            },
            required: 'Campaign Root Taxon is required.',
          }}
        />
      </Grid>
    );
  };

  const renderFormSectionField = () => {
    if (campaignValues.campaign.formSectionId === 0) return <></>;
    return (
      <Grid item xs={12} md={7} lg={7}>
        <FormTextField
          name="FormSection"
          translationKey="digitalFishers.formSectionId"
          fullWidth
          disabled
        />
      </Grid>
    );
  };

  if (redirect) {
    return <Redirect to={landingPath} />;
  }

  return (
    <Paper>
      <Form onSubmit={handleSave} formMethods={formMethods}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} lg={7}>
            <Typography
              variant="h6"
              gutterBottom
            >{`Campaign ID: ${campaignValues.campaign.dfCampaignId}`}</Typography>
          </Grid>
          <Grid item xs={12} md={7} lg={7}>
            <FormTextField
              name="campaignName"
              translationKey="digitalFishers.campaignName"
              fullWidth
              rules={{
                required: 'Campaign Name is required.',
              }}
            />
          </Grid>
          <Grid item xs={12} md={7} lg={7}>
            <FormTextField
              name="MissionTitle"
              translationKey="digitalFishers.missionTitle"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={7} lg={7}>
            <FormTextField
              name="MissionStatement"
              translationKey="digitalFishers.missionStatement"
              fullWidth
              multiline
              maxRows={6.5}
            />
          </Grid>
          {renderVideoTextField()}
          {renderPlaylistsDropdown()}
          {renderCampaignRootTaxonSelect()}
          {renderFormSectionField()}
          <Grid item xs={12} md={7} lg={7}>
            <FormDateTimePicker
              translationKey="common.datepickers.startDate"
              name="startDate"
            />
          </Grid>
          <Grid item xs={12} md={7} lg={7}>
            <FormDateTimePicker
              translationKey="common.datepickers.endDate"
              name="endDate"
            />
          </Grid>
          <Grid item xs={2} md={7} lg={7}>
            <ChooseImageButton
              className={classes.imageUpload}
              onChange={handleImageChange}
            />
          </Grid>
          <Grid item xs={10} md={7} lg={7}>
            <img
              className={classes.img}
              alt=""
              src={campaignImage}
              aria-label="campaign-image"
            />
          </Grid>
          <Grid item xs={12} md={7} lg={7}>
            <FormToggle
              label="Activate"
              name="active"
              disabled={campaignValues.campaign.formSectionId !== 0}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          direction="row"
        >
          <SaveButton type="submit" />
          <CancelButton component={RouterLink} to={landingPath} />
        </Grid>
      </Form>
    </Paper>
  );
};

export default DFManagementEditorForm;
