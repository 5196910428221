import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useUpdateBatchMutation } from 'domain/AppComponents/batch/hooks/BatchTaskMutationHooks';
import { useBatchTaskTypeAutocomplete } from 'domain/AppComponents/batch/hooks/BatchTaskStateHooks';
import {
  BATCH_TASK_TYPES_PAMGUARD_ONLY,
  BATCH_TASK_TYPES,
  BATCH_TASK_TYPES_MAP,
} from 'domain/AppComponents/batch/util/BatchUtils';
import FormDialog from 'library/CompositeComponents/dialog/FormDialog_new';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

export interface BatchUpdateData {
  batchTaskType: string;
  batchName: string;
  batchReason: string;
  taskId: number;
}
interface BatchDialogProps {
  open: boolean;
  onClose: () => void;
  data?: BatchUpdateData;
}

type BatchUpdateTextFields = {
  batchType: any;
  batchName: string;
  batchReason: string;
};

const BatchUpdateDialog = ({
  open,
  onClose,
  data = { batchName: '', batchReason: '', taskId: 0, batchTaskType: '' },
}: BatchDialogProps) => {
  const { batchName, batchReason, taskId, batchTaskType } = data;
  // use the task type from the give batch, or default to the page type (DOI for task management, PAM for PAMGuard)
  const { setTaskType, taskTypeInput, setTaskTypeInput, pamguardPrivilege } =
    useBatchTaskTypeAutocomplete(batchTaskType);

  const updateBatchMutation = useUpdateBatchMutation();

  const formMethods = useForm<BatchUpdateTextFields>({
    mode: 'onBlur',
    defaultValues: { batchName, batchReason, batchType: batchTaskType },
  });
  const { handleSubmit, reset } = formMethods;
  const onSubmit = (values: BatchUpdateTextFields) => {
    updateBatchMutation.mutate({
      taskId,
      name: values.batchName,
      reason: values.batchReason,
    });
    onClose();
  };

  useEffect(() => {
    setTaskType(BATCH_TASK_TYPES_MAP.get(batchTaskType));
    reset({
      batchName,
      batchReason,
      batchType: BATCH_TASK_TYPES_MAP.get(batchTaskType),
    });
  }, [batchName, batchReason, batchTaskType, reset, setTaskType]);

  return (
    <FormDialog
      open={open}
      title="Update Batch"
      formMethods={formMethods}
      onClose={() => onClose()}
      confirmButtonTranslationKey="common.buttons.update"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormAutocomplete
            name="batchType"
            inputValue={taskTypeInput}
            translationKey="taskmanagement.batchType"
            onInputChange={(event, newValue) => {
              setTaskTypeInput(newValue);
            }}
            id="batch-dialog-autocomplete"
            options={
              pamguardPrivilege === 'RW'
                ? BATCH_TASK_TYPES_PAMGUARD_ONLY
                : BATCH_TASK_TYPES
            }
            getOptionLabel={(label: string) => label}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="batchName"
            translationKey="taskmanagement.batchName"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="batchReason"
            translationKey="taskmanagement.batchReason"
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};
export default BatchUpdateDialog;
