import { useContext } from 'react';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../../library/CompositeComponents/dashboard/DashboardTypes';
import EndeavourContext from '../context/EndeavourContext';
import MagnitudeOverTime from '../plots/MagnitudeOverTime';

const EndeavourMagnitudePlotWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;
  const { earthquakes } = useContext(EndeavourContext);

  return (
    <Widget key={id} title="Earthquake Magnitude Plot" {...props}>
      <MagnitudeOverTime earthquakes={earthquakes} />
    </Widget>
  );
};

EndeavourMagnitudePlotWidget.widgetKey = 'endeavour-earthquake-magnitude-plot';
EndeavourMagnitudePlotWidget.widgetTitle = 'Earthquake Magnitude Plot';
EndeavourMagnitudePlotWidget.defaultDataGrid = {
  i: 'endeavour-earthquake-magnitude-plot',
  x: 0,
  y: 0,
  w: 6,
  h: 5,
};

export default EndeavourMagnitudePlotWidget;
