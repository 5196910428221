import { cloneElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import VideoGenerator from './VideoGenerator';

class VideoPlayer extends PureComponent {
  static propTypes = {
    isLive: PropTypes.bool,
    onTime: PropTypes.func,
    classes: PropTypes.objectOf(PropTypes.string),
  };

  static defaultProps = {
    isLive: false,
    onTime: undefined,
    classes: { root: '' },
  };

  state = {};

  componentDidMount() {
    this.getVideoComponent();
  }

  componentDidUpdate(prevProps) {
    const { isLive } = this.props;
    if (isLive !== prevProps.isLive) {
      this.getVideoComponent();
    }
  }

  componentWillUnmount() {
    const { onTime } = this.props;
    if (onTime) {
      onTime(undefined);
    }
  }

  getVideoComponent = () => {
    this.setState({ VideoComponent: VideoGenerator.build(this.props) });
  };

  render() {
    const { VideoComponent } = this.state;
    const { classes } = this.props;

    if (VideoComponent) {
      return (
        <div className={classes.root}>
          <div style={{ height: '100%', width: '100%' }}>
            {cloneElement(VideoComponent, { ...this.props })}
          </div>
        </div>
      );
    }
    return null;
  }
}

export default VideoPlayer;
