import ObjectUtils from 'util/ObjectUtils';
import { parseDmasAPIResponse, post } from 'util/WebRequest';

class VideoSnapshotService {
  static createSnapshots(params) {
    let snapshotParams = {};
    if (params) {
      snapshotParams = ObjectUtils.deepClone(params);
    }
    snapshotParams.operation = 1;

    return post('VideoSnapshotService', snapshotParams, {}).then((response) =>
      parseDmasAPIResponse(response)
    );
  }
}

export default VideoSnapshotService;
