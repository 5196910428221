import { useEffect, useState } from 'react';
import * as React from 'react';
import { withStyles, createStyles } from '@mui/styles';
import { WarningAlert } from '@onc/composite-components';
import { Grid } from 'base-components';
import { RemoveFilterConfirmation } from 'domain/AppComponents/dialogs/Dialogs';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import { FilterEvent } from 'library/CompositeComponents/filter/Filter';
import AddAnnotationFilterButton from './AddAnnotationFilterButton';
import AnnotationFilters from './new-filters/AnnotationFilters';
import GeneralFilter from './new-filters/GeneralFilter';
import SeaTubeSearchFilterButtons from './SeaTubeSearchFilterButtons';

interface Filter {
  label: string;
  name: string;
  FilterComponent: React.ComponentType<any>;
}
const styles = () =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    flexGrow: {
      flexGrow: 1,
    },
  });

type Props = {
  initialFilterValues?: {
    cruiseIds: number[];
    diveIds: number[];
    comment: string;
  };
  filter: any;
  name: string;
  onChange: (e: FilterEvent) => void;
  onImageExportClick?: (e: object) => void;
  onSubmit: (e: object) => void;
  onReset: (e: object) => void;
  onDiveOptionsLoaded?: (e: object) => void;
  creatorOptions: object[];
  modifierOptions: object[];
  context: 'search' | 'list';
  classes: any;
  isLoggedIn: boolean;
  actionValue?: boolean;
  tabValue?: number;
};

const AnnotationFilter: React.VFC<Props> = ({
  filter,
  onChange,
  initialFilterValues = undefined,
  onImageExportClick = undefined,
  onDiveOptionsLoaded = undefined,
  actionValue = undefined,
  name,
  onSubmit,
  onReset,
  creatorOptions,
  modifierOptions,
  context,
  classes,
  isLoggedIn,
  tabValue = undefined,
}: Props) => {
  const [additionalFilters, setAdditionalFilters] = useState<Filter[]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [targetFilter, setTargetFilter] = useState<{
    name: string;
    label: string;
  }>({ name: '', label: '' });

  useEffect(() => {
    const updatedFilters: Filter[] = [];
    Object.keys(filter).forEach((key) => {
      const filterObj: Filter | undefined = AnnotationFilters.OPTIONS.find(
        (option) => option.name === key
      );
      if (filterObj) {
        updatedFilters.push(filterObj);
      }
    });
    setAdditionalFilters(updatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = (event: FilterEvent) => {
    onChange({
      target: {
        value: { ...filter, [event.target.name]: event.target.value },
        name,
      },
    });
  };

  const handleAddFilter = (newFilter: any) => {
    setAdditionalFilters(additionalFilters.concat(newFilter));
  };

  const handleCloseConfirmation = () => {
    setShowConfirmationDialog(false);
  };

  const handleRemoveFilterClick = (item: Filter) => {
    setTargetFilter(item);
    setShowConfirmationDialog(true);
  };

  const handleRemoveFilter = () => {
    let updatedFilters = [...additionalFilters];

    updatedFilters = updatedFilters.filter(
      (val: any) => val.name !== targetFilter.name
    );

    if (filter[targetFilter.name]) {
      // eslint-disable-next-line no-param-reassign
      delete filter[targetFilter.name];
    }

    setAdditionalFilters(updatedFilters);
    handleCloseConfirmation();
  };

  const renderSeaTubeSearchButtons = () => (
    <SeaTubeSearchFilterButtons
      filter={filter}
      isLoggedIn={isLoggedIn}
      onImageExportClick={onImageExportClick}
      onSubmit={onSubmit}
      onReset={onReset}
    />
  );

  const renderAdditionalFilters = () =>
    additionalFilters.map((item: Filter) => (
      <Grid item xs={12} key={item.name}>
        <div className={classes.flexContainer}>
          <div className={classes.flexGrow}>
            <item.FilterComponent
              value={filter[item.name]}
              onChange={handleFilterChange}
              name={item.name}
              hideIcon
              className={classes.flexGrow}
            />
          </div>
          <DeleteIconButton
            size="medium"
            onClick={() => handleRemoveFilterClick(item)}
          />
        </div>
      </Grid>
    ));

  const renderLoggedInWarningAlert = () => {
    const shouldRenderAlert =
      context === 'search' && !isLoggedIn && !actionValue;
    return shouldRenderAlert ? (
      <Grid item xs={12}>
        <WarningAlert>
          You must be logged in to access functions such as exporting and
          playlist management
        </WarningAlert>
      </Grid>
    ) : undefined;
  };

  return (
    <>
      <RemoveFilterConfirmation
        open={showConfirmationDialog}
        filterLabel={targetFilter.label}
        onCancel={handleCloseConfirmation}
        onConfirm={handleRemoveFilter}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GeneralFilter
            initialFilterValues={initialFilterValues}
            onDiveOptionsLoaded={onDiveOptionsLoaded}
            value={filter}
            onChange={onChange}
            creatorOptions={creatorOptions}
            modifierOptions={modifierOptions}
            name="general"
            context={context}
            tabValue={tabValue}
          />
        </Grid>
        {renderAdditionalFilters()}
        <Grid item xs={12}>
          <AddAnnotationFilterButton
            filters={additionalFilters}
            onFilterSelect={handleAddFilter}
          />
        </Grid>
        {renderLoggedInWarningAlert()}
        {context === 'search' ? renderSeaTubeSearchButtons() : undefined}
      </Grid>
    </>
  );
};

export default withStyles(styles)(AnnotationFilter);
