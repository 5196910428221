/* eslint-disable react/no-unused-prop-types */
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import * as React from 'react';
import { Portal, SnackbarContent } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { Close } from '@onc/icons';
import { IconButton } from 'base-components';

type SnackbarOptions = {
  /**
   * An optional action to display in the snackbar. Appears to the right of the
   * message. Generally is a button
   */
  action?: React.ReactNode;
  /**
   * The duration in milliseconds to show the snackbar. Setting this to null
   * will cause the snackbar to stay open until the user closes it.
   */
  duration?: number;
};

// Define an interface for the snackbar object
interface SnackbarObject {
  message: string;
  variant: 'error' | 'info';
  duration: number;
  action?: React.ReactNode;
  onClose?: () => void;
}

// Define an interface for the snackbar state
interface SnackbarState {
  queue: SnackbarObject[];
}

// Define a type for the snackbar action
type SnackbarAction =
  | { type: 'enqueue'; payload: SnackbarObject }
  | { type: 'dequeue' };

// Define an interface for the snackbar context props
// Extend the WithStyles interface to get access to the classes prop
interface SnackbarContextProps extends WithStyles<typeof STYLES> {
  onError: (message: string, options?: SnackbarOptions) => void;
  onInfo: (message: string, options?: SnackbarOptions) => void;
}

interface SnackbarProviderProps {
  children?: React.ReactNode;
  classes: { error: string; info: string };
}

// Define styles for the SnackbarContent component
const STYLES = (theme: Theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
  });

// Create a context for the snackbar component
const SnackbarContext = createContext<SnackbarContextProps>({
  onError: () => {},
  onInfo: () => {},
  classes: { error: '', info: '' },
});

// Create a hook to use the snackbar context
export const useSnackbars = () => useContext(SnackbarContext);

// Define a reducer function for the snackbar state
const reducer = (state: SnackbarState, action: SnackbarAction) => {
  switch (action.type) {
    case 'enqueue':
      return { queue: [...state.queue, action.payload] };
    case 'dequeue':
      return { queue: state.queue.slice(1) };
    default:
      return state;
  }
};

// Define a provider component for the snackbar context
const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  classes,
  children = undefined,
}) => {
  const [state, dispatch] = useReducer(reducer, { queue: [] });
  const [open, setOpen] = React.useState(false);

  // This is the same dirty trick withSnackbars uses to give the snackbar
  // an animation when the new snackbar opens.  It renders null for one render
  // cycle, then renders the snackbar.
  useEffect(() => {
    if (!open) {
      setOpen(true);
    }
  }, [open]);

  // Define a function to handle closing of the snackbar
  const handleSnackbarClose = () => {
    setOpen(false);
    dispatch({ type: 'dequeue' });
  };

  // Function to enqueue an info message in the snackbar queue
  const onInfo = (message: string, options?: SnackbarOptions) => {
    dispatch({
      type: 'enqueue',
      payload: {
        message,
        variant: 'info',
        duration: options?.duration === undefined ? 4000 : options?.duration,
        action: options?.action,
      },
    });
  };

  // Function to enqueue an error message in the snackbar queue
  const onError = (message: string, options?: SnackbarOptions) => {
    dispatch({
      type: 'enqueue',
      payload: {
        message,
        variant: 'error',
        duration: options?.duration === undefined ? 6000 : options?.duration,
        action: options?.action,
      },
    });
  };

  // Get the current snackbar object from state
  const current = state.queue[0];

  // Memoize the value of the context provider to prevent unnecessary re-renders
  const value = useMemo(() => ({ onInfo, onError, classes }), [classes]);

  // Render a close icon button for the snackbar if duration is null
  const closeIcon = (
    <IconButton
      aria-label="Close"
      onClick={handleSnackbarClose}
      color="inherit"
    >
      <Close />
    </IconButton>
  );

  // Render the snackbar provider
  const persist = current?.duration === null;
  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {current && open ? (
        <Portal>
          <Snackbar
            open
            onClose={persist ? null : handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={current.duration}
          >
            <SnackbarContent
              className={classes[current.variant]}
              message={current.message}
              action={[current.action, persist && closeIcon]}
            />
          </Snackbar>
        </Portal>
      ) : (
        <></>
      )}
    </SnackbarContext.Provider>
  );
};

export default withStyles(STYLES)(SnackbarProvider);
