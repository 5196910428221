import React from 'react';
import { Menu } from '@mui/material';
import { Save, Dashboard, SaveAs } from '@onc/icons';
import {
  IconButton,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  MenuItem,
} from 'base-components';

import {
  Layout,
  LayoutApplication,
  useDeleteLayout,
  useOwnerLayoutsForApp,
} from 'domain/AppComponents/Dashboard/LayoutService';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { OncLayouts } from './DashboardTypes';
import OverwriteWidgetLayoutDialog from './OverwriteWidgetLayoutDialog';
import SaveWidgetLayoutDialog from './SaveWidgetLayoutDialog';

export type LayoutOption = {
  label: string;
  layout: OncLayouts;
};

type WidgetLayoutMenuProps = {
  onChange: (layout: OncLayouts) => void;
  currentLayout: OncLayouts;
  applicationId?: LayoutApplication;
  predefinedLayouts?: LayoutOption[];
};

const WidgetLayoutMenu: React.FC<WidgetLayoutMenuProps> = ({
  onChange,
  predefinedLayouts = undefined,
  currentLayout,
  applicationId = undefined,
}: WidgetLayoutMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSaveAs, setOpenSaveAs] = React.useState(false);
  const [overwriteLayout, setOverwriteLayout] = React.useState<Layout | null>(
    null
  );

  const [deleteLayout, setDeleteLayout] = React.useState<Layout | null>(null);
  const { onInfo, onError } = useSnackbars();

  const { data: userLayouts, refetch: refetchLayouts } = useOwnerLayoutsForApp({
    resourceId: applicationId,
  });

  const { mutate: handleDeleteLayout } = useDeleteLayout(
    () => {
      onInfo('Layout Deleted');
      setDeleteLayout(null);
      setAnchorEl(null);
      refetchLayouts();
    },
    () => onError('Error saving layout')
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLayoutChange = (layout: OncLayouts) => {
    onChange(layout);
    handleClose();
  };

  const handleSaveAsLayout = () => {
    setOpenSaveAs(true);
    handleClose();
  };

  const renderPredefinedLayouts = () => {
    if (!predefinedLayouts) return null;
    return (
      <>
        <ListSubheader sx={{ width: 250 }}>Pre-Defined Layouts</ListSubheader>
        {predefinedLayouts.map((layoutOption) => (
          <MenuItem
            key={layoutOption.label}
            onClick={() => handleLayoutChange(layoutOption.layout)}
          >
            {layoutOption.label}
          </MenuItem>
        ))}
      </>
    );
  };

  const renderUserLayouts = () => {
    if (!applicationId || !Environment.isUserLoggedIn()) return null;
    return (
      <>
        <Divider light />
        <MenuItem onClick={handleSaveAsLayout}>
          <ListItemIcon>
            <SaveAs fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Save As..." />
        </MenuItem>

        {!!userLayouts?.layouts.length && (
          <>
            <Divider sx={{ mb: '0px !important' }} />
            <ListSubheader sx={{ width: 250 }}>Saved Layouts</ListSubheader>
          </>
        )}

        {userLayouts?.layouts?.map((layoutOption) => (
          <MenuItem
            aria-label={layoutOption.layoutName}
            key={layoutOption.layoutId}
            onClick={() =>
              handleLayoutChange(
                JSON.parse(layoutOption.widgetLayout) as OncLayouts
              )
            }
            sx={{
              '&:hover .secondary-action': { visibility: 'visible' },
            }}
          >
            <ListItemText primary={layoutOption.layoutName} />
            <ListItemSecondaryAction
              className="secondary-action"
              sx={{
                visibility: 'hidden', // Initially hidden
              }}
            >
              <IconButton
                aria-label="Save"
                onClick={(e) => {
                  setOverwriteLayout(layoutOption);
                  e.stopPropagation();
                }}
              >
                <Save fontSize="small" />
              </IconButton>
              <DeleteIconButton
                iconProps={{ fontSize: 'small' }}
                onClick={(e) => {
                  setDeleteLayout(layoutOption);
                  e.stopPropagation();
                }}
              />
            </ListItemSecondaryAction>
          </MenuItem>
        ))}
      </>
    );
  };

  if (!applicationId) return null;

  return (
    <>
      <SaveWidgetLayoutDialog
        applicationId={applicationId}
        currentLayout={currentLayout}
        open={openSaveAs}
        onClose={() => {
          setOpenSaveAs(false);
          setAnchorEl(null);
        }}
        onSave={() => {
          setOpenSaveAs(false);
          setAnchorEl(null);
          refetchLayouts();
        }}
      />
      <OverwriteWidgetLayoutDialog
        applicationId={applicationId}
        currentLayout={currentLayout}
        layout={overwriteLayout}
        onClose={() => {
          setOverwriteLayout(null);
          setAnchorEl(null);
        }}
        onSave={() => {
          setOverwriteLayout(null);
          setAnchorEl(null);
          refetchLayouts();
        }}
      />

      <DeleteDialog
        open={!!deleteLayout}
        onCancel={() => {
          setDeleteLayout(null);
          setAnchorEl(null);
        }}
        onDelete={() => {
          handleDeleteLayout({
            resourceTypeId: 2000,
            resourceId: applicationId,
            layoutId: deleteLayout?.layoutId,
          });
        }}
        title="Delete Layout?"
        message={`Are you sure you wish to delete the layout "${deleteLayout?.layoutName}"?`}
      />
      <IconButton
        onClick={handleClick}
        aria-label="Layouts"
        aria-controls={open ? 'layout-select-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Dashboard sx={{ color: '#FFF' }} />
      </IconButton>
      <Menu
        id="layout-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiMenu-list': { paddingTop: 0 } }}
      >
        {renderPredefinedLayouts()}
        {renderUserLayouts()}
      </Menu>
    </>
  );
};

export default WidgetLayoutMenu;
