import * as React from 'react';
import { Icon } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import {
  CloudDoneOutlined,
  CloudOff,
  CloudUploadOutlined,
  CloudQueue,
} from '@onc/icons';
import { CircularProgress } from 'base-components';
import { DataciteStatus } from './DataciteStatus';

const useStyles = makeStyles(() =>
  createStyles({
    circularProgress: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: `none`,
    },
  })
);

interface RelatedIdentifierStatusIconProps {
  dataciteStatus: number;
  isLoading: boolean;
}

const RelatedIdentifierStatusIcon: React.FC<
  RelatedIdentifierStatusIconProps
> = ({ dataciteStatus, isLoading }: RelatedIdentifierStatusIconProps) => {
  const classes = useStyles();
  if (isLoading && dataciteStatus !== DataciteStatus.UPLOADED) {
    return (
      <CircularProgress
        aria-label="Loading"
        className={classes.circularProgress}
        size={25}
      />
    );
  }
  switch (dataciteStatus) {
    case DataciteStatus.NOT_SENT:
      return (
        <Icon
          title="Has not been sent to datacite"
          color="primary"
          aria-label="Has not been sent to datacite"
        >
          <CloudUploadOutlined />
        </Icon>
      );
    case DataciteStatus.REQUEST_SENT:
      return (
        <Icon
          title="Datacite is currently processing"
          color="primary"
          aria-label="Datacite is currently processing"
        >
          <CloudQueue />
        </Icon>
      );
    case DataciteStatus.ERROR:
      return (
        <Icon
          title="An error occurred updating datacite"
          color="error"
          aria-label="An error occurred updating datacite"
        >
          <CloudUploadOutlined />
        </Icon>
      );
    case DataciteStatus.UPLOADED:
      return (
        <Icon
          title="Available on datacite"
          color="primary"
          aria-label="Available on datacite"
        >
          <CloudDoneOutlined />
        </Icon>
      );
    default:
      return (
        <Icon
          title="Datacite status not available"
          color="primary"
          aria-label="Datacite status not available"
        >
          <CloudOff />
        </Icon>
      );
  }
};

export default RelatedIdentifierStatusIcon;
