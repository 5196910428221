import {
  type ForwardRefExoticComponent,
  type FunctionComponent,
  type ReactNode,
  type RefAttributes,
} from 'react';
import {
  createContainerComponent,
  createControlHook,
  createElementHook,
  createElementObject,
  extendContext,
} from '@react-leaflet/core';
import { Control } from 'leaflet';
import { ControlledLayerProps } from 'react-leaflet';
import LeafletLayersControl from './LeafletLayersControl';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface LayersControlProps extends Control.LayersOptions {
  /** Id of the Map object in leaflet. TODO: Can get by useMap instead */
  mapId: string;
  /** Tag to append to the default id used by leaflet */
  idTag: string;
  /** Size of the button. Passed down to https://v4.mui.com/api/fab/ */
  size?: 'small' | 'medium' | 'large';
  /** Color of the Button. Passed down to https://v4.mui.com/api/fab/ */
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  /** ID used in the HTML element's container */
  containerId?: string;
  /** React Children */
  children?: ReactNode;
  /** Whether the Menu is expanded or not */
  collapsed?: boolean;
}

const useLayersControlElement = createElementHook<
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Control.Layers,
  LayersControlProps
>(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ({ children: _c, ...options }, ctx) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const control = new LeafletLayersControl(undefined, undefined, options);
    return createElementObject(
      control,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      extendContext(ctx, { layersControl: control })
    );
  },
  (control, props, prevProps) => {
    if (props.collapsed !== prevProps.collapsed) {
      if (props.collapsed === true) {
        control.collapse();
      } else {
        control.expand();
      }
    }
  }
);

const useLayersControl = createControlHook(useLayersControlElement);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const LayersControl: ForwardRefExoticComponent<
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  LayersControlProps & RefAttributes<Control.Layers>
> & {
  BaseLayer: FunctionComponent<ControlledLayerProps>;
  Overlay: FunctionComponent<ControlledLayerProps>;
} = createContainerComponent(useLayersControl);

export default LayersControl;
