import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ListItem, ListItemText } from 'base-components';

const styles = {
  root: {
    maxHeight: 'max-content',
    flexGrow: 1,
    alignItems: 'normal',
    minHeight: '120px',
  },
  itemContainer: {
    maxHeight: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      overflowY: 'auto',
    },
  },
};

/**
 * An infoItem that will grow up to the size of its contents, but only if space
 * is available. If space isn't available, it will create its own scrollbar as
 * required.
 *
 * Designed to be used inside of a "ResizingInfoList" to work properly
 *
 * Note that this class does have a minimum height, and thus will not shrink
 * below that size even if its contents are very small.
 */
class InfoItemScrollableMultiParagraph extends PureComponent {
  static propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.string,
    classes: PropTypes.shape({
      root: PropTypes.string,
      itemContainer: PropTypes.string,
    }),
  };

  static defaultProps = {
    primary: '',
    secondary: '',
    classes: {},
  };

  // parses a string and replaces line breaks with <br />, returning the resulting jsx
  renderText = (text) => (
    <>
      {[].concat(
        ...text
          .split('\n')
          .map((item, index, list) => [
            item,
            index < list.length - 1 ? (
              <br key={'paragraphBreak'.concat(index)} />
            ) : undefined,
          ])
      )}
    </>
  );

  render() {
    const { primary, secondary, classes } = this.props;
    return (
      <ListItem className={classes.root}>
        <ListItemText
          primary={primary}
          secondary={this.renderText(secondary)}
          className={classes.itemContainer}
        />
      </ListItem>
    );
  }
}

export default withStyles(styles)(InfoItemScrollableMultiParagraph);
