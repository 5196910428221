/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { Slider, Typography, TextField } from 'base-components';
import Filter, { FilterEvent } from 'library/CompositeComponents/filter/Filter';

type Props = {
  value: { minimumReviews?: string; positiveReviewPercentage?: number };
  name: string;
  onChange: (event: FilterEvent) => void;
};

const ReviewsFilter = (props: Props): ReactElement => {
  const { onChange, name, value } = props;

  const parsedValue = {
    minimumReviews: value?.minimumReviews || '',
    positiveReviewPercentage: value?.positiveReviewPercentage || 0,
  };

  return (
    <Filter title="Reviews" onChange={onChange} name={name} value={parsedValue}>
      <Grid direction="row" container spacing={1}>
        <Grid item xs={12}>
          <TextField
            translationKey="seatube.minimumReviews"
            type="number"
            name="minimumReviews"
            value={value?.minimumReviews}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" id="positiveReviewPercentage">
            Positive Reviews
          </Typography>
          <Slider
            aria-labelledby="positiveReviewPercentage"
            name="positiveReviewPercentage"
            marks={[
              { label: '0%', value: 0 },
              { label: '25%', value: 25 },
              { label: '50%', value: 50 },
              { label: '75%', value: 75 },
              { label: '100%', value: 100 },
            ]}
            step={null}
            onChange={undefined}
            value={value?.positiveReviewPercentage}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default ReviewsFilter;
