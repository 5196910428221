import {
  useState,
  useEffect,
  useReducer,
  ChangeEvent,
  useCallback,
} from 'react';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';

import { Dropdown } from 'base-components';
import { SensorValue } from 'domain/services/SeatubeSensorDataService';
import TimeSeriesChart, {
  type OnDataSelectionChange,
} from 'library/BaseComponents/time-series-charts/TimeSeriesChart';
import CookieUtils from 'util/CookieUtils';

type DropdownOption = {
  label: string;
  value: string;
};

type DateRange = [string, string];

type SensorPlotDisplayProps = {
  currentDate: Moment;
  startDate: Moment;
  endDate: Moment;
  plottableData: {
    intervalDate: string;
    sensorValues: SensorValue[];
  }[];
};

const MARGIN = { l: 60, r: 60, t: 30, b: 0 };

const useStyles = makeStyles((theme) => ({
  dropdown: {
    width: '300px',
    margin: theme.spacing(),
  },
  dropdownRight: {
    width: '300px',
    margin: theme.spacing(),
    float: 'right',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  chart: {
    width: '100%',
    height: '100%',
  },
  flexShrink: {
    flexShrink: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

function dateRangeReducer(
  currentDateRange: DateRange,
  action: { type: string; data: { dateRange: DateRange } }
) {
  if (action.type === 'set') {
    return action.data.dateRange;
  }
  return currentDateRange;
}

const SensorPlotDisplay: React.FC<SensorPlotDisplayProps> = ({
  currentDate,
  startDate,
  endDate,
  plottableData,
}: SensorPlotDisplayProps) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [currentSensor, setCurrentSensor] = useState<string>('');
  const [currentSensor2, setCurrentSensor2] = useState<string>('');
  const [seriesArray, setSeriesArray] = useState([]);
  const [dateRange, dispatchDateRangeUpdate] = useReducer(dateRangeReducer, [
    moment.utc(startDate).format(),
    moment.utc(endDate).format(),
  ]);

  const classes = useStyles();

  const orderSensors = (a: DropdownOption, b: DropdownOption) => {
    const { label: labelA } = a;
    const { label: labelB } = b;
    if (labelA < labelB) return -1;
    return 1;
  };

  useEffect(() => {
    if (plottableData.length === 0) {
      return;
    }
    const dropdownOptionsUnsorted = [];
    const newSeries = [];
    plottableData.forEach((dataSet) => {
      dataSet.sensorValues.forEach((sensor) => {
        const sensorName = `${sensor.sensorName} (${sensor.sensorId})`;
        // If a series for this sensor already exists,
        // add this point
        if (newSeries[sensorName]) {
          newSeries[sensorName].points.push([sensor.sampleDate, sensor.value]);
          // If not, add the series to the dropdown options
          // and create a new entry in series for it
        } else {
          newSeries[sensorName] = {
            name: sensor.sensorName,
            uom: sensor.uom,
            sensorId: sensor.sensorId,
            columns: ['time', 'value'],
            points: [],
          };
          dropdownOptionsUnsorted.push({
            label: sensorName,
            value: sensorName,
          });
          newSeries[sensorName].points.push([sensor.sampleDate, sensor.value]);
        }
      });
    });

    // Adds the inverted depth option
    const depthSensor = Object.entries(newSeries).find(
      ([, sensor]) => sensor.name === 'Depth'
    );

    if (depthSensor) {
      const invertedDepth = {
        ...depthSensor[1],
      };
      invertedDepth.points = invertedDepth.points.map(([date, value]) => [
        date,
        -value,
      ]);
      newSeries[`${invertedDepth.name} Inverted (${invertedDepth.sensorId})`] =
        invertedDepth;
      dropdownOptionsUnsorted.push({
        label: `${invertedDepth.name} Inverted (${invertedDepth.sensorId})`,
        value: `${invertedDepth.name} Inverted (${invertedDepth.sensorId})`,
      });
    }
    // ----------------------------
    const newDropdownOptions = dropdownOptionsUnsorted.sort(orderSensors);
    const cookieLeftAxis = CookieUtils.getCookie(
      'sensor-plot-left-axis'
    )?.toString();
    if (
      cookieLeftAxis &&
      newDropdownOptions.find((item) => item.label === cookieLeftAxis)
    ) {
      setCurrentSensor(cookieLeftAxis);
    }
    const cookieRightAxis = CookieUtils.getCookie(
      'sensor-plot-right-axis'
    )?.toString();
    if (
      cookieRightAxis &&
      newDropdownOptions.find((item) => item.label === cookieRightAxis)
    ) {
      setCurrentSensor2(cookieRightAxis);
    }
    setSeriesArray(newSeries);
    setDropdownOptions(newDropdownOptions);
  }, [plottableData]);

  /**
   * Select the sensor, and create a time series out of the series array created
   * in this.createSeries()
   */
  const handleSensorChange = (event: ChangeEvent<HTMLInputElement>) => {
    CookieUtils.saveCookie('sensor-plot-left-axis', event.target.value);
    setCurrentSensor(event.target.value);
  };

  const handleSensor2Change = (event: ChangeEvent<HTMLInputElement>) => {
    CookieUtils.saveCookie('sensor-plot-right-axis', event.target.value);
    setCurrentSensor2(event.target.value);
  };

  const getTitle = (sensor: string) => {
    if (sensor && seriesArray && seriesArray[sensor]) {
      return `${seriesArray[sensor].name} (${seriesArray[sensor].uom})`;
    }
    return '';
  };

  const generateTraces = () => {
    const traces = [];
    if (seriesArray[currentSensor]) {
      traces.push({
        name: getTitle(currentSensor),
        data: {
          times: seriesArray[currentSensor].points.map((point) => point[0]),
          values: seriesArray[currentSensor].points.map((point) => point[1]),
        },
        color: '#4DB3D0',
        width: 2,
        yaxisSide: 'left',
      });
    }

    if (seriesArray[currentSensor2]) {
      traces.push({
        name: getTitle(currentSensor2),
        data: {
          times: seriesArray[currentSensor2].points.map((point) => point[0]),
          values: seriesArray[currentSensor2].points.map((point) => point[1]),
        },
        width: 2,
        color: '#BADC76',
        yaxisSide: 'right',
      });
    }

    return traces;
  };

  const onDataSelectionChange: OnDataSelectionChange = useCallback(
    (action, axesRanges) => {
      if (action === 'reset') {
        dispatchDateRangeUpdate({
          type: 'set',
          data: {
            dateRange: [
              moment.utc(startDate).format(),
              moment.utc(endDate).format(),
            ],
          },
        });
      } else if (action === 'daterangechange') {
        dispatchDateRangeUpdate({
          type: 'set',
          data: { dateRange: axesRanges.xaxis },
        });
      } else if (action === 'autoscale') {
        dispatchDateRangeUpdate({
          type: 'set',
          data: {
            dateRange: [
              plottableData[0].intervalDate,
              plottableData[plottableData.length - 1].intervalDate,
            ],
          },
        });
      }
    },
    [startDate, endDate, plottableData]
  );

  const renderChart = () => {
    if (startDate && endDate && currentDate) {
      return (
        <TimeSeriesChart
          dateRange={dateRange}
          onDataSelectionChange={onDataSelectionChange}
          leftYaxisTitle={getTitle(currentSensor)}
          rightYaxisTitle={getTitle(currentSensor2)}
          subChart={{
            traces: generateTraces(),
            title: '',
          }}
          margin={MARGIN}
          resamplePeriod={30}
          classes={{ root: classes.chart }}
          shapes={[
            {
              type: 'line',
              x0: moment.utc(currentDate).format(),
              y0: 0,
              x1: moment.utc(currentDate).format(),
              yref: 'paper',
              y1: 1,
              line: {
                color: 'grey',
                width: 1,
                dash: 'dot',
              },
            },
          ]}
        />
      );
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <div className={classes.flexShrink}>
        <Dropdown
          disabled={dropdownOptions.length === 0}
          options={dropdownOptions}
          onChange={handleSensorChange}
          value={currentSensor}
          label={
            dropdownOptions.length === 0
              ? 'Waiting for Sensors to load...'
              : 'Left Axis'
          }
          className={classes.dropdown}
        />
        <Dropdown
          disabled={dropdownOptions.length === 0}
          options={dropdownOptions}
          onChange={handleSensor2Change}
          value={currentSensor2}
          label={
            dropdownOptions.length === 0
              ? 'Waiting for Sensors to load...'
              : 'Right Axis'
          }
          className={classes.dropdownRight}
        />
      </div>
      <div className={classes.flexGrow}>{renderChart()}</div>
    </div>
  );
};

export default SensorPlotDisplay;
