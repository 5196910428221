import * as React from 'react';
import { RowDetailState } from '@devexpress/dx-react-grid';

export interface ExpandRowProps {
  expandedRows: (string | number)[];
  handleExpandRowChange?: (expandedRowIds: (string | number)[]) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  ToggleCellComponent?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  RowDetail?: any;
}

const ExpandRow: React.VFC<ExpandRowProps> = ({
  expandedRows,
  handleExpandRowChange = () => {},
}) => {
  if (handleExpandRowChange) {
    return (
      <RowDetailState
        expandedRowIds={expandedRows}
        onExpandedRowIdsChange={handleExpandRowChange}
      />
    );
  }
  return <RowDetailState defaultExpandedRowIds={expandedRows} />;
};
export default ExpandRow;
