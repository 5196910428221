import React from 'react';
import { Forward30, Replay30 } from '@onc/icons';
import { IconButton } from 'base-components';

type VideoSeekOptionsProps = {
  containerRef?: HTMLElement;
  /* handleForward callback */
  handleForward: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /* handleBack callback */
  handleBack: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const VideoSeekOptions: React.FC<VideoSeekOptionsProps> = ({
  containerRef = undefined,
  handleForward,
  handleBack,
}) => (
  <div>
    <IconButton
      aria-label="Back 30 Seconds"
      onClick={handleBack}
      color="inherit"
      tooltipProps={{
        slotProps: {
          popper: { container: containerRef },
        },
      }}
      sx={{ mr: 1 }} // Add margin right to next button
    >
      <Replay30 />
    </IconButton>
    <IconButton
      aria-label="Forward 30 Seconds"
      onClick={handleForward}
      color="inherit"
      tooltipProps={{
        slotProps: {
          popper: { container: containerRef },
        },
      }}
    >
      <Forward30 />
    </IconButton>
  </div>
);

export default VideoSeekOptions;
