interface KeyboardInput {
  buttonSetIndex: number | undefined;
  buttonIndex: number | undefined;
}

export interface MatrixServiceResponse {
  inheritedAttributes: MatrixAttribute[];
  inheritedOverrideableAttributes: MatrixAttribute[];
  localAttributes: MatrixAttribute[];
  overrideableAttributes: MatrixAttribute[];
}

export interface MatrixAttribute {
  attributeId: number;
  excluded: boolean;
  groupName: string;
  inherited: boolean;
  name: string;
  taxonomyMatrixAtrributeId: number;
  value: any;
}

class TabbedButtonSetUtil {
  static hasInput = (input: string) => input !== undefined && input !== '';

  static getInputArray = (input: string): KeyboardInput => {
    if (input.includes('.')) {
      const parts = input.split('.');
      return {
        buttonSetIndex: Number(parts[0]) - 1,
        buttonIndex: this.hasInput(parts[1]) ? Number(parts[1]) : undefined,
      };
    }
    return {
      buttonSetIndex: undefined,
      buttonIndex: this.hasInput(input) ? Number(input) : undefined,
    };
  };
}

export default TabbedButtonSetUtil;
