import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
} from 'base-components';
import DataProductOptionService from 'domain/Apps/openApi/service/DataProductOptionService';
import {
  ContainedButton,
  CancelButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import 'rapidoc';
import LocalStorageUtil from 'util/LocalStorageUtil';

const styles = () => ({
  root: {
    margin: 'auto',
  },
  buttons: {
    float: 'right',
    minWidth: '100px',
    maxHeight: '60px',
  },
});

class OpenApiPage extends PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    onInfo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayAllDPO: true,
      currentRequest: undefined,
      displayLoginPrompt: false,
      dpRequestId: '',
      dpRunId: '',
    };
  }

  initPage() {
    const interval = setInterval(() => {
      const { shadowRoot } = document.getElementsByTagName('rapi-doc')[0];
      if (
        shadowRoot &&
        shadowRoot.querySelector('api-request') &&
        shadowRoot.querySelector('api-request').shadowRoot
      ) {
        const requestPage = shadowRoot.querySelector('api-request');
        const originalClearButton = requestPage.shadowRoot.querySelector(
          '[part*="btn-clear"]'
        );
        if (originalClearButton) {
          originalClearButton.style.marginTop = '-15px';
          originalClearButton.style.float = 'right';
          clearInterval(interval);
          const newParentDiv =
            requestPage.shadowRoot.querySelector('.table-title');
          newParentDiv.appendChild(originalClearButton);
        }
        this.popuplateParameters(`link-get-${requestPage.path}`, 100);
      }
    }, 100);
  }

  componentDidMount() {
    const rapidocEl = document.getElementsByTagName('rapi-doc')[0];
    if (!rapidocEl) {
      return;
    }
    this.initPage();
    rapidocEl.addEventListener('before-try', (e) => {
      const token = Environment.getDmasUserToken();
      if (!token) {
        this.setState({ displayLoginPrompt: true });
      } else {
        this.setState({ currentRequest: e });
      }
    });

    rapidocEl.addEventListener('after-try', (e) => {
      const requestPage = document
        .getElementsByTagName('rapi-doc')[0]
        .shadowRoot.querySelector('api-request');
      const responseButton = requestPage.shadowRoot.querySelector(
        '[part*="clear-response"]'
      );
      const urlButton = responseButton.cloneNode();
      urlButton.innerText = 'COPY URL';
      urlButton.style.marginLeft = '5px';
      urlButton.onclick = () => this.copyURL(e.detail.request.url);
      responseButton.parentElement.appendChild(urlButton);

      if (e.detail.responseBody.dpRequestId) {
        const { dpRequestId } = e.detail.responseBody;
        this.setState({ currentRequest: undefined, dpRequestId });
        return;
      }
      if (e.detail.responseBody[0] && e.detail.responseBody[0].dpRunId) {
        const { dpRunId, status } = e.detail.responseBody[0];
        if (status === 'queued' || status.includes('running')) {
          setTimeout(() => {
            const tryButton =
              requestPage.shadowRoot.querySelector('[part*="btn-try"]');
            if (requestPage.path.includes('dataProductDelivery/run')) {
              tryButton.click();
            }
          }, 5000);
        }
        this.setState({ currentRequest: undefined, dpRunId });
        return;
      }
      if (
        e.detail.response &&
        e.detail.response.status === 200 &&
        e.detail.responseHeaders['content-disposition'] &&
        e.detail.responseHeaders['content-disposition'].includes('filename')
      ) {
        const filename =
          e.detail.responseHeaders['content-disposition'].split('filename=')[1];
        e.detail.response.blob().then((blob) => {
          // Create a new URL object for the blob data
          const blobUrl = window.URL.createObjectURL(blob);
          // Create a new anchor element with the download attribute
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = filename;
          // Simulate a click on the anchor element to start the download
          a.click();
          // Revoke the blob URL to free up memory
          window.URL.revokeObjectURL(blobUrl);
          const codeTags = document
            .getElementsByTagName('rapi-doc')[0]
            .shadowRoot.querySelectorAll('api-request')[0]
            .shadowRoot.querySelectorAll('code');
          // should be three code tags one for response, responseHeaders, and CURL
          // we want to delete the response tab code tag but
          // if the user is downloading an image the code tag is replace by two buttons
          // in that case dont delete any code tags
          if (codeTags.length === 3) {
            codeTags[0].parentElement.parentElement.remove();
          }
        });
      }
      this.setState({ currentRequest: undefined });
    });

    this.addOnClick();
  }

  copyURL = (url) => {
    const { onInfo } = this.props;
    navigator.clipboard.writeText(url);
    onInfo('copied URL to clipboard');
  };

  toggleDPO = () => {
    const { displayAllDPO } = this.state;
    const newDisplay = !displayAllDPO;
    const params = document
      .getElementsByTagName('rapi-doc')[0]
      .shadowRoot.querySelectorAll('api-request')[0]
      .shadowRoot.querySelectorAll('div.param-name');
    for (let i = 0; i < params.length; i += 1) {
      const element = params[i];
      if (element.textContent.includes('dpo_')) {
        if (newDisplay) {
          element.closest('tr').style.display = '';
          element.closest('tr').nextElementSibling.style.display = '';
        } else {
          element.closest('tr').style.display = 'none';
          element.closest('tr').nextElementSibling.style.display = 'none';
          element.closest('tr').querySelectorAll('input')[0].value = '';
        }
      }
    }
    this.setState({ displayAllDPO: newDisplay });
  };

  filterDPO = () => {
    const { onError } = this.props;
    const vals = {};
    const params = document
      .getElementsByTagName('rapi-doc')[0]
      .shadowRoot.querySelectorAll('api-request')[0]
      .shadowRoot.querySelectorAll('div.param-name');
    // get values from all the non dpo fields
    for (let i = 0; i < params.length; i += 1) {
      const element = params[i];
      if (element.textContent.includes('dataProductCode')) {
        vals.dataProductCode = element
          .closest('tr')
          .querySelectorAll('input')[0].value;
      }
      if (element.textContent.includes('extension')) {
        vals.extension = element
          .closest('tr')
          .querySelectorAll('input')[0].value;
      }
      if (element.textContent.includes('dateFrom')) {
        vals.dateFrom = element
          .closest('tr')
          .querySelectorAll('input')[0].value;
      }
      if (element.textContent.includes('dateTo')) {
        vals.dateTo = element.closest('tr').querySelectorAll('input')[0].value;
      }
      if (element.textContent.includes('locationCode')) {
        vals.locationCode = element
          .closest('tr')
          .querySelectorAll('input')[0].value;
      }
      if (element.textContent.includes('deviceCategoryCode')) {
        vals.deviceCategoryCode = element
          .closest('tr')
          .querySelectorAll('input')[0].value;
      }
      if (element.textContent.includes('deviceCode')) {
        vals.deviceCode = element
          .closest('tr')
          .querySelectorAll('input')[0].value;
      }
      if (element.textContent.includes('propertyCode')) {
        vals.propertyCode = element
          .closest('tr')
          .querySelectorAll('input')[0].value;
      }
    }
    const reduced = Object.fromEntries(
      Object.entries(vals).filter(([, v]) => v !== '')
    );
    DataProductOptionService.getOptions(reduced)
      .then((payload) => {
        const documentFields = document
          .getElementsByTagName('rapi-doc')[0]
          .shadowRoot.querySelectorAll('api-request')[0]
          .shadowRoot.querySelectorAll('div.param-name');
        for (let i = 0; i < documentFields.length; i += 1) {
          const element = documentFields[i];
          if (element.textContent.includes('dpo_')) {
            let validOption = false;
            for (let j = 0; j < payload.length; j += 1) {
              if (element.textContent.includes(payload[j].option)) {
                element.closest('tr').style.display = '';
                element.closest('tr').nextElementSibling.style.display = '';
                validOption = true;
                break;
              }
            }
            if (!validOption) {
              element.closest('tr').style.display = 'none';
              element.closest('tr').nextElementSibling.style.display = 'none';
              element.closest('tr').querySelectorAll('input')[0].value = '';
            }
          }
        }
        this.setState({ displayAllDPO: false });
      })
      .catch((response) => {
        const errors = [];
        for (let i = 0; i < response.response.data.errors.length; i += 1) {
          const error = response.response.data.errors[i];
          errors.push(`${error.errorMessage}: ${error.parameter}`);
        }
        onError(
          `failed to filter data product options: ${errors.join(' and ')}`
        );
      });
  };

  addOnClick = () => {
    const interval = setInterval(() => {
      const { shadowRoot } = document.getElementsByTagName('rapi-doc')[0];
      if (shadowRoot) {
        const navBarItems = shadowRoot.querySelectorAll('[id^="link-get-"]');
        if (navBarItems.length !== 0) {
          clearInterval(interval);
          for (let i = 0; i < navBarItems.length; i += 1) {
            const item = navBarItems[i];
            item.onclick = this.saveParameters;
          }
        }
      }
    }, 100);
  };

  hideFillExample(selectedPage) {
    if (
      selectedPage.includes('dataProductDelivery/run') ||
      selectedPage.includes('dataProductDelivery/status') ||
      selectedPage.includes('dataProductDelivery/download') ||
      selectedPage.includes('dataProductDelivery/restart') ||
      selectedPage.includes('dataProductDelivery/cancel')
    ) {
      document
        .getElementsByTagName('rapi-doc')[0]
        .shadowRoot.querySelector('api-request')
        .shadowRoot.querySelector('[part*="btn-fill"]').style.display = 'none';
    } else {
      document
        .getElementsByTagName('rapi-doc')[0]
        .shadowRoot.querySelector('api-request')
        .shadowRoot.querySelector('[part*="btn-fill"]').style.display = '';
    }
  }

  changeTryToRun(selectedPage) {
    if (selectedPage.includes('dataProductDelivery/run')) {
      document
        .getElementsByTagName('rapi-doc')[0]
        .shadowRoot.querySelector('api-request')
        .shadowRoot.querySelector('[part*="btn-try"]').innerHTML = 'RUN';
    } else {
      document
        .getElementsByTagName('rapi-doc')[0]
        .shadowRoot.querySelector('api-request')
        .shadowRoot.querySelector('[part*="btn-try"]').innerHTML = 'TRY';
    }
  }

  popuplateParameters = (elementId, time) => {
    const { dpRequestId, dpRunId } = this.state;
    const pageToParams = LocalStorageUtil.getItem(`open-api-page-to-params`);
    const interval = setInterval(() => {
      const { shadowRoot } = document.getElementsByTagName('rapi-doc')[0];
      if (shadowRoot && shadowRoot.getElementById(elementId)) {
        clearInterval(interval);
        this.changeTryToRun(elementId);
        this.hideFillExample(elementId);
        if (!pageToParams || !pageToParams[elementId]) {
          return;
        }
        const val = pageToParams[elementId];
        const params = document
          .getElementsByTagName('rapi-doc')[0]
          .shadowRoot.querySelectorAll('api-request')[0]
          .shadowRoot.querySelectorAll('div.param-name');
        for (let i = 0; i < params.length; i += 1) {
          const param = params[i];
          const paramName = param.textContent.trim();
          if (paramName.includes('dpRequestId')) {
            param.closest('tr').querySelectorAll('input')[0].value =
              dpRequestId;
          } else if (paramName.includes('dpRunId')) {
            param.closest('tr').querySelectorAll('input')[0].value = dpRunId;
          } else if (
            val &&
            Object.prototype.hasOwnProperty.call(val, paramName)
          ) {
            param.closest('tr').querySelectorAll('input')[0].value =
              val[paramName];
          }
        }
      }
    }, time);
  };

  saveParameters = (clickEvent) => {
    let pageToParams = LocalStorageUtil.getItem(`open-api-page-to-params`);
    if (!pageToParams) {
      pageToParams = {};
    }
    const requestElement = document
      .getElementsByTagName('rapi-doc')[0]
      .shadowRoot.querySelectorAll('api-request');
    if (requestElement.length !== 0) {
      const { path } = requestElement[0];
      const params = document
        .getElementsByTagName('rapi-doc')[0]
        .shadowRoot.querySelectorAll('api-request')[0]
        .shadowRoot.querySelectorAll('div.param-name');
      const map = {};
      for (let i = 0; i < params.length; i += 1) {
        const element = params[i];
        const paramName = element.textContent.trim();
        map[paramName] = element
          .closest('tr')
          .querySelectorAll('input')[0].value;
      }
      pageToParams[`link-get-${path}`] = map;
      LocalStorageUtil.setItem(`open-api-page-to-params`, pageToParams);
    }
    if (clickEvent.currentTarget.id) {
      this.popuplateParameters(clickEvent.currentTarget.id, 100);
    }
  };

  closeDialog = () => {
    const { currentRequest } = this.state;
    if (currentRequest) {
      currentRequest.detail.controller.abort();
    }
    this.setState({ currentRequest: undefined });
  };

  render() {
    const { displayAllDPO, currentRequest, displayLoginPrompt } = this.state;
    const { classes } = this.props;
    let loading = false;
    if (currentRequest) {
      loading = true;
    }
    const styling = `   rapi-doc::part(btn){ border-radius: 15px; }
    rapi-doc::part(btn) {
      background-color: #129DC0;
      color: #fff;
      border-color: #129DC0;
      border-radius: 2px;
    }
    rapi-doc::part(btn-selected-response-status) {
      background-color: #BADC76;
      color: #fff;
      border-color: #BADC76;
    }
    div.nav-bar-paths-under-tag {
      margin: 50px;
      color: #129DC0
    }`;
    const token = Environment.getDmasUserToken();
    const params = {};
    if (token) {
      params['api-key-name'] = 'token';
      params['api-key-location'] = 'query';
      params['api-key-value'] = token;
      params['allow-authentication'] = 'false';
    }
    params['allow-authentication'] = 'false';
    const redirect = encodeURIComponent(`${window.location.href}`);
    return (
      <div>
        <Dialog open={loading}>
          <DialogTitle>Processing API Request</DialogTitle>
          <DialogContent className={classes.root}>
            <div>
              <CircularProgress color="primary" />
            </div>
            <div>
              <CancelButton onClick={this.closeDialog} />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={displayLoginPrompt}>
          <DialogTitle>Login Required</DialogTitle>
          <DialogContent className={classes.root}>
            <div>
              <span>
                To try the API you must{' '}
                <Link
                  onClick={this.saveParameters}
                  href={`${Environment.getDmasUrl()}/Login?service=${redirect}`}
                >
                  log in
                </Link>
                .
              </span>
            </div>
            <div>
              <TextButton
                translationKey="common.buttons.close"
                onClick={() => this.setState({ displayLoginPrompt: false })}
              />
            </div>
          </DialogContent>
        </Dialog>
        <rapi-doc
          spec-url={`${Environment.getDmasUrl()}/api/definition`}
          primary-color="#000000"
          {...params}
          nav-bg-color="#ededed"
          nav-text-color="#000000"
          bg-color="#FFFFFF"
          render-style="focused"
          font-size="largest"
          show-header="false"
          allow-server-selection="false"
          schema-style="table"
          sort-tags="false"
          sort-endpoints-by="none"
          show-method-in-nav-bar="as-colored-block"
          regular-font={('Roboto', 'Helvetica', 'Arial', 'sans-serif')}
          allow-search="false"
          allow-advanced-search="false"
        >
          <p slot="get-/locations">
            retrieves a list of location names and location codes
          </p>
          <p slot="get-/locations/tree">
            {' '}
            The getTree method returns a hierarchical representation of the ONC
            Search Tree Nodes. The Search Tree is used in Oceans 3.0 to organize
            Instruments and Variables by Location so that users can easily drill
            down by place name or mobile platform name to find the instruments
            or properties they are interested in.
          </p>
          <p slot="get-/deviceCategories">
            {' '}
            The API deviceCategories service returns all device categories
            defined in Oceans 3.0 that meet a filter criteria. A Device Category
            represents an instrument type classification such as CTD
            (Conductivity, Temperature & Depth Instrument) or BPR (Bottom
            Pressure Recorder). Devices from a category can record data for one
            or more properties (variables). The primary purpose of this service,
            is to find device categories that have the data you want to access;
            the service provides the deviceCategoryCode you can use when
            requesting a data product via the dataProductDelivery web service.
          </p>
          <p slot="get-/deployments">
            {' '}
            The deployments discovery web service returns all deployments
            defined in Oceans 3.0 which meet the filter criteria, where a
            deployment is the installation of a device at a location. The
            deployments service assists in knowing when and where specific types
            of data are available. The primary purpose for the deployments
            service is to find the dates and locations of deployments and use
            the dateFrom and dateTo datetimes when requesting a data product
            using the dataProductDelivery web service.
          </p>
          <p slot="get-/properties">
            {' '}
            The API properties service returns all properties defined in Oceans
            3.0 that meet a filter criteria. Properties are observable phenomena
            (aka, variables) and are the common names given to sensor types
            (i.e., oxygen, pressure, temperature, etc) The primary purpose of
            this service, is to find the available properties of the data you
            want to access; the service provides the propertyCode that you can
            use to request a data product via the dataProductDelivery web
            service.
          </p>
          <p slot="get-/devices">
            {' '}
            The API devices returns all the devices defined in Oceans 3.0 that
            meet a set of filter criteria. Devices are instruments that have one
            or more sensors that observe a property or phenomenon with a goal of
            producing an estimate of the value of a property. Devices are
            uniquely identified by a device code and can be deployed at multiple
            locations during their lifespan. The primary purpose of the devices
            service is to find devices that have the data you are interested in
            and use the deviceCode when requesting a data product using the
            dataProductDelivery web service.
          </p>
          <p slot="get-/dataProducts">
            {' '}
            The API dataProducts service returns all data products defined in
            Oceans 3.0 that meet a filter criteria. Data Products are
            downloadable representations of ONC observational data, provided in
            formats that can be easily ingested by analytical or visualization
            software. The primary purpose of this service is to identify which
            Data Products and Formats (file extensions) are available for the
            Locations, Devices, Device Categories or Properties of interest. Use
            the dataProductCode and extension when requesting a data product via
            the dataProductDelivery web service.
          </p>
          <p slot="get-/scalardata/device">
            {' '}
            Returns scalar data in JSON format by given device code.
          </p>
          <p slot="get-/scalardata/location">
            {' '}
            Returns scalar data in JSON format by given location code and device
            category code.
          </p>
          <p slot="get-/rawdata/device">
            {' '}
            Retrieve raw data for a given device. A date range is optional–if
            not specified, data from all time will be returned within (possibly
            default) row and size limits.
          </p>
          <p slot="get-/rawdata/location">
            {' '}
            Retrieve the raw data at a given location for the given device
            category. A date range is optional–when not specified, data from all
            time will be returned within (possibly default) row and size limits.
          </p>
          <p slot="get-/archivefile/location">
            {' '}
            Get a list of files available in Oceans 3.0 Archiving System for a
            given location code and device category code. The list of filenames
            can be filtered by time range.
          </p>
          <p slot="get-/archivefile/device">
            {' '}
            Get a list of files available in Oceans 3.0 Archiving System for a
            given device code. The list of filenames can be filtered by time
            range.
          </p>
          <p slot="get-/archivefile/download">
            {' '}
            Download a file from Oceans 3.0 Archiving System by specifying the
            file name. The file will be downloaded without any compression. Many
            files in the archive are compressed for storage, uncompressing these
            files takes time on the server and increases data volume to
            transfer. We will address this performance issue in a future update.
          </p>
          <p slot="get-/dataProductDelivery/request">
            {' '}
            The request method creates a search and returns information
            regarding the number of files, file sized, compressed file sized,
            and estimated download times as well as a request id that can be
            used to generate the data product using the run method.
          </p>
          <div
            className={classes.buttons}
            slot="get-/dataProductDelivery/request"
          >
            <ContainedButton
              translationKey="openApi.filterDPO"
              startIcon={<Add />}
              onClick={this.filterDPO}
            />
            <ContainedButton
              translationKey={
                displayAllDPO ? 'openApi.hideAllDPO' : 'openApi.displayAllDPO'
              }
              startIcon={<Add />}
              onClick={this.toggleDPO}
            />
          </div>
          <p slot="get-/dataProductDelivery/status">
            {' '}
            The status method returns data about the status of the request. You
            can periodically press the try button to get current status.
          </p>
          <p slot="get-/dataProductDelivery/run">
            {' '}
            The run method runs the data product created by a call to the
            request method. Pressing the run button again after the run has
            started will return the runs status.
          </p>
          <p slot="get-/dataProductDelivery/download">
            {' '}
            The download method downloads a file for the specified data product
            run request. The file to download is specified by index, with the
            first valid index being 1 and the last being the total number of
            files generated by the request. If the data product delivery process
            has not completed you can periodically press the try button to get
            current status
          </p>
          <p slot="get-/dataProductDelivery/cancel">
            {' '}
            The cancel method cancels currently running searchs.
          </p>
          <p slot="get-/dataProductDelivery/restart">
            {' '}
            The restart method restarts searchs cancelled by the data product
            cancel method.
          </p>
          <p slot="auth">
            {' '}
            Enter your oceannetworks.ca user token above and press SET or login
            to authenticate{' '}
          </p>
          <style slot="header">{styling}</style>
        </rapi-doc>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(OpenApiPage));
