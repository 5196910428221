import { Snackbar } from 'base-components';

const ErrorSnackbar = (props) => (
  <Snackbar duration={6000} variant="error" {...props} />
);

const InfoSnackbar = (props) => (
  <Snackbar duration={4000} variant="info" {...props} />
);

const ActionSnackbar = (props) => (
  <Snackbar duration={10000} variant="actionSnackbar" {...props} />
);

const LoadingSnackbar = (props) => (
  <Snackbar duration={null} variant="loading" {...props} />
);

export { ErrorSnackbar, InfoSnackbar, ActionSnackbar, LoadingSnackbar };
