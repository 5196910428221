import * as React from 'react';
import { Table, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles } from '@mui/styles';

export interface SelectionProps {
  selectByRowClick?: boolean;
  highlightRow: boolean;
  showSelectionColumn: boolean;
  selection?: any[];
  onChange?: (selected: any[]) => void;
  rowComponent?: any;
  showSelectAll?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      backgroundColor: '#FFF',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
    },
    selected: {
      backgroundColor: theme.palette.secondary.light,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  })
);

const TableRow = ({
  tableRow,
  highlighted,
  onToggle,
  children,
  selectByRowClick,
}: TableSelection.RowProps) => {
  const classes = useStyles();
  return (
    <Table.Row
      row={tableRow.row}
      tableRow={tableRow}
      onClick={selectByRowClick ? onToggle : null}
      className={highlighted ? classes.selected : classes.root}
      aria-label={tableRow.rowId}
    >
      {children}
    </Table.Row>
  );
};

// eslint-disable-next-line react/require-default-props
const RowSelection: React.VFC<SelectionProps> = (props: SelectionProps) => (
  <TableSelection rowComponent={TableRow} {...props} />
);
export default RowSelection;
