import { memo } from 'react';
import TaskStatus from 'domain/AppComponents/batch/util/TaskStatus';
import { StackTraceDialog } from 'domain/AppComponents/dialogs/Dialogs';
import DialogChip from 'library/CompositeComponents/chips/DialogChip';

const infoStatus = [TaskStatus.OPEN.value, TaskStatus.QUEUED.value];
const errorStatus = [
  TaskStatus.COMPLETED_WITH_ERRORS.value,
  TaskStatus.CANCELLED.value,
  TaskStatus.ABORTED.value,
];

const secondaryStatus = [TaskStatus.RUNNING.value];

const getStatusType = (status) => {
  if (errorStatus.includes(status)) {
    return 'error';
  }
  if (infoStatus.includes(status)) {
    return 'info';
  }
  if (secondaryStatus.includes(status)) {
    return 'secondary';
  }
  return 'default';
};

const BatchStatusChip = ({
  status,
  errorMessage = undefined,
}: {
  status: any;
  errorMessage?: string;
}) => (
  <DialogChip
    label={TaskStatus.getLabelByValue(status) ?? ''}
    type={getStatusType(status)}
    dialog={
      errorMessage ? (
        <StackTraceDialog
          open={false}
          onClose={() => {}}
          title="Error Message"
          errorMessage={errorMessage}
        />
      ) : null
    }
  />
);

export default memo(BatchStatusChip);
