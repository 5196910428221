import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from 'base-components';
import {
  NextIconButton,
  ReplayIconButton,
  PreviousIconButton,
} from 'domain/AppComponents/IconButtons';

type EndCardProps = {
  onReplay: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onNext?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onPrevious?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'rgb(0,0,0)',
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'white',
    fontSize: '5rem',
  },
}));

const PlaylistItemEndCard: React.VFC<EndCardProps> = ({
  onNext = undefined,
  onPrevious = undefined,
  onReplay,
}: EndCardProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container} role="region" aria-label="End Card">
      <Box sx={{ pointerEvents: onPrevious ? 'auto' : 'none' }}>
        <PreviousIconButton
          aria-label="Previous Video"
          iconProps={{ className: classes.icon }}
          onClick={onPrevious}
          sx={{
            visibility: onPrevious ? 'visible' : 'hidden',
          }}
        />
      </Box>

      <ReplayIconButton
        aria-label="Replay Video"
        iconProps={{ className: classes.icon }}
        onClick={onReplay}
      />

      <Box sx={{ pointerEvents: onNext ? 'auto' : 'none' }}>
        <NextIconButton
          aria-label="Next Video"
          iconProps={{ className: classes.icon }}
          onClick={onNext}
          sx={{
            visibility: onNext ? 'visible' : 'hidden',
          }}
        />
      </Box>
    </div>
  );
};

export default PlaylistItemEndCard;
