import * as React from 'react';
import { Group, Person, Public } from '@onc/icons';
import { Grid, LabelledCheckbox } from 'base-components';
import { AnnotationOwnerRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import Filter, {
  FilterImplementationProps,
  FilterValue,
  SummaryValues,
} from 'library/CompositeComponents/filter/Filter';
import Environment from 'util/Environment';

export interface OwnerFilterValue extends FilterValue {
  owner: 'myAnnotations' | 'sharedAnnotations';
  isShared?: boolean;
  isPrivate?: boolean;
  isFlagged?: boolean;
  isUnflagged?: boolean;
}

interface Props extends FilterImplementationProps {
  value?: OwnerFilterValue;
}

const OwnerFilter: React.FC<Props> = ({
  onChange,
  name,
  value = {
    owner: 'sharedAnnotations',
    isShared: true,
    isPrivate: true,
    isFlagged: true,
    isUnflagged: true,
  },
  onExpand,
  expanded,
}: Props) => {
  // docs say it return '' but can return undefined, 0 when not logged in
  const userLoggedIn =
    Environment.getDmasUserId() !== undefined &&
    Environment.getDmasUserId() !== '' &&
    Environment.getDmasUserId() !== 0;

  const renderSummaryValues = () => {
    let summaryValues: SummaryValues[] = [];
    if (value) {
      if (value.owner === 'sharedAnnotations') {
        return [{ label: 'Shared', icon: <Public /> }];
      }
      if (value.owner === 'myAnnotations') {
        summaryValues = [{ label: 'My Annotations', icon: <Person /> }];
        if (
          value.isShared &&
          value.isPrivate &&
          value.isFlagged &&
          value.isUnflagged
        ) {
          return [{ label: 'All My Annotations', icon: <Person /> }];
        }
        !value.isShared || summaryValues.push({ label: 'Shared' });
        !value.isPrivate || summaryValues.push({ label: 'Private' });
        !value.isFlagged || summaryValues.push({ label: 'Flagged' });
        !value.isUnflagged || summaryValues.push({ label: 'Unflagged' });
      }
    }
    return summaryValues;
  };

  const disableCheckboxes = !value || value.owner !== 'myAnnotations';

  const renderMyAnnotationCheckboxes = () => {
    if (userLoggedIn) {
      return (
        <>
          <LabelledCheckbox
            name="isShared"
            label="Shared"
            disabled={disableCheckboxes}
          />
          <LabelledCheckbox
            name="isPrivate"
            label="Private"
            disabled={disableCheckboxes}
          />
          <LabelledCheckbox
            name="isFlagged"
            label="Flagged"
            disabled={disableCheckboxes}
          />
          <LabelledCheckbox
            name="isUnflagged"
            label="Unflagged"
            disabled={disableCheckboxes}
          />
        </>
      );
    }
    return null;
  };

  return (
    <Filter
      title="Owner"
      icon={<Group data-test="filterIcon" color="action" />}
      value={value || undefined}
      name={name}
      summaryValues={renderSummaryValues()}
      onChange={onChange}
      onExpand={onExpand}
      expanded={expanded}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AnnotationOwnerRadioGroup
            name={name}
            onChange={onChange}
            userLoggedIn={userLoggedIn}
          />
        </Grid>
        <Grid item xs={12} />
        {renderMyAnnotationCheckboxes()}
      </Grid>
    </Filter>
  );
};

export default OwnerFilter;
