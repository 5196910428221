import Environment from 'util/Environment';
import { get } from 'util/WebRequest';

const ISO19115XML_SERVICE = 'ISO19115XMLService';

class ISO19115XMLService {
  static getISO19115XmlServiceCallForCast = (
    siteDeviceSubsetId: number,
    fileName: string
  ) =>
    `${Environment.getDmasUrl()}/${ISO19115XML_SERVICE}?siteDeviceSubsetId=${siteDeviceSubsetId}&fileName=${fileName}`;

  static getISO19115XmlBlobForCast = (
    siteDeviceSubsetId: number,
    fileName?: string
  ) =>
    get(
      ISO19115XML_SERVICE,
      { siteDeviceSubsetId, fileName },
      { responseType: 'blob' }
    );
}

export default ISO19115XMLService;
