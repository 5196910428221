import { useEffect, useState } from 'react';
import * as React from 'react';

import { type DefaultValues, useForm } from 'react-hook-form';
import { Close, Settings } from '@onc/icons';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from 'base-components';

import Form from 'library/CompositeComponents/form/Form';
import Panel from 'library/CompositeComponents/panel/Panel';
import { ConfigFields, DashboardWidgetProps } from './DashboardTypes';
import { SaveButton, CancelButton } from '../button/Buttons';

export const WidgetMenuItem = (
  key: string,
  onClick: (...args: any[]) => void,
  label: string,
  Icon: React.FunctionComponent,
  disabled: boolean
) => (
  <MenuItem key={key} disabled={disabled} onClick={onClick}>
    <ListItemIcon style={{ minWidth: '40px' }}>
      <Icon />
    </ListItemIcon>
    <ListItemText>{label}</ListItemText>
  </MenuItem>
);

type WidgetMenuCheckboxProps = {
  key: string;
  onClick: (...args: any[]) => void;
  label: string;
  checked: boolean;
};

export const WidgetMenuItemCheckbox: React.FC<WidgetMenuCheckboxProps> = ({
  key,
  onClick,
  label,
  checked,
}: WidgetMenuCheckboxProps) => (
  <MenuItem key={key} onClick={onClick}>
    <ListItemIcon style={{ minWidth: '40px' }}>
      <Checkbox data-test={key} style={{ padding: '0px' }} checked={checked} />
    </ListItemIcon>
    <ListItemText>{label}</ListItemText>
  </MenuItem>
);

interface WidgetProps<TConfig = any> extends DashboardWidgetProps {
  title: string;
  titleComponents?: React.ReactNode;
  children?: any;
  MenuItems?: JSX.Element[];
  actionComponents?: JSX.Element[];
  config?: TConfig;
  onChangeConfig?: (config: TConfig) => void;
  ConfigFieldsComponent?: ConfigFields<TConfig>;
  loading?: boolean;
  ariaLabel?: string;
  hideHeader?: boolean;
  collapseHeader?: boolean;
  reduceHeader?: boolean;
  onRemoveAction?: () => void;
  closeMenuItems?: boolean;
  isConfigOpen?: boolean;
  showMenu?: boolean;
  onCloseConfig?: () => void;
}

const Widget = <TConfig = any,>({
  id,
  onRemove,
  onRemoveAction = undefined,
  title,
  titleComponents = undefined,
  config = undefined,
  onChangeConfig = () => {},
  onInteract,
  ConfigFieldsComponent = undefined,
  MenuItems = undefined,
  children = undefined,
  loading = undefined,
  actionComponents = undefined,
  ariaLabel = undefined,
  widgetKey,
  isStatic = false,
  hideHeader = false,
  collapseHeader = undefined,
  reduceHeader = false,
  closeMenuItems = false,
  isConfigOpen = undefined,
  showMenu = false,
  onCloseConfig = () => {},
}: WidgetProps<TConfig>) => {
  const [showConfig, setShowConfig] = useState(false);

  const formMethods = useForm<TConfig>({
    mode: 'onBlur',
    defaultValues: config as DefaultValues<TConfig>,
  });

  // Handle config appearance and removal for data viewer charts
  const [prevIsConfigOpen, setPrevIsConfigOpen] = useState(isConfigOpen);
  if (isConfigOpen !== prevIsConfigOpen) {
    setPrevIsConfigOpen(isConfigOpen);
    setShowConfig(isConfigOpen);
  }

  const handleConfigSave = (formData: TConfig) => {
    if (onChangeConfig) {
      onChangeConfig(formData);
    }
    onCloseConfig?.();
    setShowConfig(false);
  };

  const handleConfigCancel = () => {
    formMethods.reset(config); // Reset form to initial values
    onCloseConfig?.();
    setShowConfig(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => formMethods.reset(config), [config]);

  // Menu Item for removing the widget
  const RemoveMenuItem = WidgetMenuItem(
    'remove',
    () => {
      onRemove(id);
      onRemoveAction && onRemoveAction();
    },
    'Remove',
    Close,
    false
  );

  // Menu item that appears if the widget has a config component
  const ConfigMenuItem = WidgetMenuItem(
    'config',
    () => setShowConfig(true),
    'Settings',
    Settings,
    false
  );

  const handleInteract = () => {
    onInteract(id, widgetKey);
  };

  useEffect(() => {
    document
      .getElementById(`widget-${id}`)
      .addEventListener('mousedown', handleInteract);
    return () => {
      document
        .getElementById(`widget-${id}`)
        ?.removeEventListener('mousedown', handleInteract);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderConfig = () => {
    if (ConfigFieldsComponent) {
      return (
        <Dialog open={showConfig} maxWidth="sm">
          <DialogTitle>{`${title} Settings`}</DialogTitle>
          <Form formMethods={formMethods} onSubmit={handleConfigSave}>
            <DialogContent>
              <ConfigFieldsComponent formMethods={formMethods} />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={handleConfigCancel} />
              <SaveButton />
            </DialogActions>
          </Form>
        </Dialog>
      );
    }
    return undefined;
  };

  // Builds the list of menu items under the three dot menu
  const renderMenuItems = () => {
    const menuItems: JSX.Element[] = [];
    if (ConfigFieldsComponent) {
      menuItems.push(ConfigMenuItem);
    }
    if (MenuItems) {
      MenuItems.forEach((Item) => menuItems.push(Item));
    }
    if (!isStatic) {
      menuItems.push(RemoveMenuItem);
    }

    return menuItems.length > 0 ? menuItems : undefined;
  };

  return (
    <Panel
      ariaLabel={ariaLabel || (typeof title === 'string' ? title : undefined)}
      title={<Typography variant="subtitle1">{title}</Typography>}
      titleComponents={titleComponents}
      menu={showMenu ? null : renderMenuItems()}
      headerDraggable={!isStatic}
      id={id}
      menuProps={{
        anchorOrigin: { vertical: 'bottom' },
        closeOnClick: closeMenuItems,
      }}
      loading={loading}
      actionContent={actionComponents}
      hideHeader={hideHeader}
      collapseHeader={collapseHeader}
      reduceHeader={reduceHeader}
    >
      {showConfig ? renderConfig() : null}
      {children}
    </Panel>
  );
};

export default Widget;
