import { Component } from 'react';
import * as React from 'react';
import { Icon } from '@mui/material';
import { IconButton, ListItemIcon, Menu, MenuItem } from 'base-components';

interface OperatorOption {
  label: string;
  value: string;
  icon: React.ComponentType<any> | string;
}

type Props = {
  value: OperatorOption;
  options: OperatorOption[];
  onChange: (newProps: any, index: number) => void;
  index: number;
};
type State = any;
class OperatorIconButton extends Component<Props, State> {
  state = {
    menuOpen: false,
    anchorEl: undefined,
  };

  handleOpenMenu = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      menuOpen: true,
    });
  };

  handleOperatorChange = (option: OperatorOption) => {
    const { index, onChange } = this.props;
    onChange({ operator: option }, index);
    this.setState({ menuOpen: false });
  };

  static renderIcon = (option: OperatorOption) => {
    if (option.icon) {
      return typeof option.icon === 'string' ? (
        <Icon style={{ lineHeight: '26px', textAlign: 'center' }}>
          {option.icon}
        </Icon>
      ) : (
        <option.icon />
      );
    }
    return undefined;
  };

  render() {
    const { value, options } = this.props;
    const { menuOpen, anchorEl } = this.state;
    return (
      <>
        <IconButton
          aria-describedby="change-operator"
          size="medium"
          onClick={this.handleOpenMenu}
          aria-label="Change Operator"
        >
          {OperatorIconButton.renderIcon(value)}
        </IconButton>
        <Menu
          open={menuOpen}
          id="change-operator"
          anchorEl={anchorEl}
          onClose={() => this.setState({ menuOpen: false })}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => this.handleOperatorChange(option)}
            >
              <ListItemIcon>
                {OperatorIconButton.renderIcon(option)}
              </ListItemIcon>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default OperatorIconButton;
