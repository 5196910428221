import { useState } from 'react';
import { Autocomplete, Grid, TextField } from 'base-components';
import { FormDialog } from 'domain/AppComponents/dialogs/FormDialog';
import DOIDatasetService from '../doi-util/DOIDatasetService';
import type { RelatedIdentifier } from './RelatedIdentifier';

interface RelatedIdentifierDialogProps {
  doidataset: string;
  relatedIdentifier?: RelatedIdentifier;
  onClose: () => void;
  refreshRelatedIdentifiers: (
    doidataset: string
  ) => Promise<RelatedIdentifier[]>;
  onError: (message: any, callback?: any) => void;
}

interface Option {
  label: string;
  value: string;
  key: string;
}

const relatedIdentifierTypeOptions = [
  { label: 'ARK', value: 'ARK', key: 'ARK' },
  { label: 'arXiv', value: 'arXiv', key: 'arXiv' },
  { label: 'bibcode', value: 'bibcode', key: 'bibcode' },
  { label: 'DOI', value: 'DOI', key: 'DOI' },
  { label: 'EAN13', value: 'EAN13', key: 'EAN13' },
  { label: 'EISSN', value: 'EISSN', key: 'EISSN' },
  { label: 'Handle', value: 'Handle', key: 'Handle' },
  { label: 'IGSN', value: 'IGSN', key: 'IGSN' },
  { label: 'ISBN', value: 'ISBN', key: 'ISBN' },
  { label: 'ISSN', value: 'ISSN', key: 'ISSN' },
  { label: 'ISTC', value: 'ISTC', key: 'ISTC' },
  { label: 'LISSN', value: 'LISSN', key: 'LISSN' },
  { label: 'LSID', value: 'LSID', key: 'LSID' },
  { label: 'PMID', value: 'PMID', key: 'PMID' },
  { label: 'PURL', value: 'PURL', key: 'PURL' },
  { label: 'UPC', value: 'UPC', key: 'UPC' },
  { label: 'URL', value: 'URL', key: 'URL' },
  { label: 'URN', value: 'URN', key: 'URN' },
  { label: 'w3id', value: 'w3id', key: 'w3id' },
];

const relationTypeOptions = [
  { label: 'IsCitedBy', value: 'IsCitedBy', key: 'IsCitedBy' },
  { label: 'Cites', value: 'Cites', key: 'Cites' },
  { label: 'IsSupplementTo', value: 'IsSupplementTo', key: 'IsSupplementTo' },
  {
    label: 'IsSupplementedBy',
    value: 'IsSupplementedBy',
    key: 'IsSupplementedBy',
  },
  { label: 'Continues', value: 'Continues', key: 'Continues' },
  { label: 'IsDescribedBy', value: 'IsDescribedBy', key: 'IsDescribedBy' },
  { label: 'Describes', value: 'Describes', key: 'Describes' },
  { label: 'HasMetadata', value: 'HasMetadata', key: 'HasMetadata' },
  { label: 'IsMetadataFor', value: 'IsMetadataFor', key: 'IsMetadataFor' },
  { label: 'HasVersion', value: 'HasVersion', key: 'HasVersion' },
  { label: 'IsVersionOf', value: 'IsVersionOf', key: 'IsVersionOf' },
  { label: 'IsNewVersionOf', value: 'IsNewVersionOf', key: 'IsNewVersionOf' },
  {
    label: 'IsPreviousVersionOf',
    value: 'IsPreviousVersionOf',
    key: 'IsPreviousVersionOf',
  },
  { label: 'IsPartOf', value: 'IsPartOf', key: 'IsPartOf' },
  { label: 'HasPart', value: 'HasPart', key: 'HasPart' },
  { label: 'IsPublishedIn', value: 'IsPublishedIn', key: 'IsPublishedIn' },
  { label: 'IsReferencedBy', value: 'IsReferencedBy', key: 'IsReferencedBy' },
  { label: 'References', value: 'References', key: 'References' },
  { label: 'IsDocumentedBy', value: 'IsDocumentedBy', key: 'IsDocumentedBy' },
  { label: 'Documents', value: 'Documents', key: 'Documents' },
  { label: 'IsCompiledBy', value: 'IsCompiledBy', key: 'IsCompiledBy' },
  { label: 'Compiles', value: 'Compiles', key: 'Compiles' },
  {
    label: 'IsVariantFormOf',
    value: 'IsVariantFormOf',
    key: 'IsVariantFormOf',
  },
  {
    label: 'IsOriginalFormOf',
    value: 'IsOriginalFormOf',
    key: 'IsOriginalFormOf',
  },
  { label: 'IsIdenticalTo', value: 'IsIdenticalTo', key: 'IsIdenticalTo' },
  { label: 'IsReviewedBy', value: 'IsReviewedBy', key: 'IsReviewedBy' },
  { label: 'Reviews', value: 'Reviews', key: 'Reviews' },
  { label: 'IsDerivedFrom', value: 'IsDerivedFrom', key: 'IsDerivedFrom' },
  { label: 'IsSourceOf', value: 'IsSourceOf', key: 'IsSourceOf' },
  { label: 'IsRequiredBy', value: 'IsRequiredBy', key: 'IsRequiredBy' },
  { label: 'Requires', value: 'Requires', key: 'Requires' },
  { label: 'IsObsoletedBy', value: 'IsObsoletedBy', key: 'IsObsoletedBy' },
];

const resourceTypeOptions = [
  { label: 'Audiovisual', value: 'Audiovisual', key: 'Audiovisual' },
  { label: 'Book', value: 'Book', key: 'Book' },
  { label: 'BookChapter', value: 'BookChapter', key: 'BookChapter' },
  { label: 'Collection', value: 'Collection', key: 'Collection' },
  {
    label: 'ComputationalNotebook',
    value: 'ComputationalNotebook',
    key: 'ComputationalNotebook',
  },
  {
    label: 'ConferencePaper',
    value: 'ConferencePaper',
    key: 'ConferencePaper',
  },
  {
    label: 'ConferenceProceeding',
    value: 'ConferenceProceeding',
    key: 'ConferenceProceeding',
  },
  { label: 'DataPaper', value: 'DataPaper', key: 'DataPaper' },
  { label: 'Dataset', value: 'Dataset', key: 'Dataset' },
  { label: 'Dissertation', value: 'Dissertation', key: 'Dissertation' },
  { label: 'Event', value: 'Event', key: 'Event' },
  { label: 'Image', value: 'Image', key: 'Image' },
  {
    label: 'InteractiveResource',
    value: 'InteractiveResource',
    key: 'InteractiveResource',
  },
  { label: 'Journal', value: 'Journal', key: 'Journal' },
  { label: 'JournalArticle', value: 'JournalArticle', key: 'JournalArticle' },
  { label: 'Model', value: 'Model', key: 'Model' },
  {
    label: 'OutputManagementPlan',
    value: 'OutputManagementPlan',
    key: 'OutputManagementPlan',
  },
  { label: 'PeerReview', value: 'PeerReview', key: 'PeerReview' },
  { label: 'PhysicalObject', value: 'PhysicalObject', key: 'PhysicalObject' },
  { label: 'Preprint', value: 'Preprint', key: 'Preprint' },
  { label: 'Report', value: 'Report', key: 'Report' },
  { label: 'Service', value: 'Service', key: 'Service' },
  { label: 'Software', value: 'Software', key: 'Software' },
  { label: 'Sound', value: 'Sound', key: 'Sound' },
  { label: 'Standard', value: 'Standard', key: 'Standard' },
  { label: 'Text', value: 'Text', key: 'Text' },
  { label: 'Workflow', value: 'Workflow', key: 'Workflow' },
  { label: 'Other', value: 'Other', key: 'Other' },
];

const createLabelValueKeyObject = (value: string): Option => ({
  label: value,
  value,
  key: value,
});

const AutoCompleteField = (props) => (
  <Grid item xs={12}>
    <Autocomplete
      fullWidth
      required
      getOptionLabel={(option) => (option ? option.label : undefined)}
      {...props}
    />
  </Grid>
);

const RelatedIdentifierDialog = ({
  doidataset,
  relatedIdentifier = {
    relatedIdentifierType: '',
    relationType: '',
    resourceTypeGeneral: '',
    content: '',
  } as RelatedIdentifier,
  onClose,
  refreshRelatedIdentifiers,
  onError,
}: RelatedIdentifierDialogProps) => {
  const [relatedIdentifierType, setRelatedIdentifierType] = useState<Option>(
    createLabelValueKeyObject(relatedIdentifier?.relatedIdentifierType)
  );
  const [relationType, setRelationType] = useState<Option>(
    createLabelValueKeyObject(relatedIdentifier?.relationType)
  );
  const [resourceType, setResourceType] = useState<Option>(
    createLabelValueKeyObject(relatedIdentifier?.resourceTypeGeneral)
  );
  const [content, setContent] = useState<string>(relatedIdentifier?.content);
  return (
    <FormDialog
      open
      title={relatedIdentifier ? 'Edit' : 'Create'}
      confirmButtonTranslationKey="common.buttons.save"
      onClose={() => onClose()}
      onSubmit={async () => {
        try {
          relatedIdentifier
            ? await DOIDatasetService.updateRelatedIdentifier(
                doidataset,
                relatedIdentifierType.value,
                relationType.value,
                resourceType.value,
                content,
                relatedIdentifier.relatedIdentifierId
              )
            : await DOIDatasetService.createRelatedIdentifier(
                doidataset,
                relatedIdentifierType.value,
                relationType.value,
                resourceType.value,
                content
              );
          await refreshRelatedIdentifiers(doidataset);
          onClose();
        } catch (e) {
          onError(e);
        }
      }}
    >
      <Grid container spacing={1}>
        <AutoCompleteField
          translationKey="doi.relatedIdentifierType"
          name="relatedIdentifierType"
          title="relatedIdentifierType"
          value={relatedIdentifierType}
          options={relatedIdentifierTypeOptions}
          onChange={(e: any) => {
            setRelatedIdentifierType(
              createLabelValueKeyObject(e?.target?.value?.value)
            );
          }}
        />
        <AutoCompleteField
          translationKey="doi.relationType"
          name="relationType"
          title="relationType"
          value={relationType}
          options={relationTypeOptions}
          onChange={(e: any) => {
            setRelationType(createLabelValueKeyObject(e?.target?.value?.value));
          }}
        />
        <AutoCompleteField
          translationKey="doi.resourceType"
          name="resourceType"
          title="resourceType"
          value={resourceType}
          options={resourceTypeOptions}
          onChange={(e: any) => {
            setResourceType(createLabelValueKeyObject(e?.target?.value?.value));
          }}
        />
        <Grid item xs={12}>
          <TextField
            required
            translationKey="doi.relatedIdentifier"
            name="relatedIdentifier"
            type="string"
            fullWidth
            defaultValue=""
            value={content}
            onChange={(e: any) => {
              setContent(e?.target?.value);
            }}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default RelatedIdentifierDialog;
