import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { GetApp } from '@onc/icons';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  TableOld as Table,
  Typography,
} from 'base-components';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import PanelHeader from 'library/CompositeComponents/panel/PanelHeader';
import Environment from 'util/Environment';
import ProductMenu from './ProductMenu';
import {
  ProductNames,
  SiteDeviceSubsetProduct,
} from '../definitions/GeospatialSearchTypes';

const Styles = (theme: Theme) =>
  createStyles({
    formButtons: {
      flexDirection: 'row-reverse',
      display: 'flex',
      paddingRight: theme.spacing(3),
    },
    tableStyle: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  });

interface Props extends WithStyles<typeof Styles> {
  products: SiteDeviceSubsetProduct[];
  onCloseClick: () => void;
  onDeleteClick: (product: ProductNames, extension: string) => any;
  onDownloadClick: (product: ProductNames, extension: string) => void;
  onAddClick: (selectedItem: ProductNames, extension: string) => void;
}

const SiteDeviceSubsetProductTableDialog: React.VFC<Props> = (props: Props) => {
  const {
    products,
    onCloseClick,
    onDownloadClick,
    onDeleteClick,
    onAddClick,
    classes,
  } = props;

  const renderActionIcons = (product: SiteDeviceSubsetProduct) => ({
    ...product,
    actions: (
      <>
        {product.dpRunId && product.status === 'Completed' ? (
          <a
            href={`${Environment.getDmasUrl()}/SearchResultService?id=${
              product.dpRunId
            }`}
          >
            <IconButton title="Download" aria-label="Download Data Product">
              <GetApp />
            </IconButton>
          </a>
        ) : (
          <IconButton
            aria-label="Download Data Product"
            onClick={() =>
              onDownloadClick(product.productName, product.extension)
            }
          >
            <GetApp fontSize="small" />
          </IconButton>
        )}
        <DeleteIconButton
          aria-label="Delete Data Product"
          onClick={() => onDeleteClick(product.productName, product.extension)}
        />
      </>
    ),
  });

  const columns = [
    {
      name: 'productName',
      title: 'Product',
    },
    {
      name: 'productType',
      title: 'Product Type',
    },
    {
      name: 'status',
      title: 'Status',
    },
    {
      name: 'actions',
      title: 'Actions',
    },
  ];

  const rowsToRender = products.reduce<
    {
      actions: JSX.Element;
      productName: ProductNames;
      isSelected: boolean;
      status: string;
      productType: string;
    }[]
  >((selectedProductsWithActions, product) => {
    if (product.isSelected) {
      selectedProductsWithActions.push(renderActionIcons(product));
    }
    return selectedProductsWithActions;
  }, []);

  const optionsToRender = products.reduce<SiteDeviceSubsetProduct[]>(
    (nonSelectedProducts, product) => {
      if (!product.isSelected) {
        nonSelectedProducts.push(product);
      }
      return nonSelectedProducts;
    },
    []
  );

  const headerComp = (
    <Typography variant="h5">Select Products for Cast</Typography>
  );
  return (
    <>
      <Dialog open maxWidth="lg" fullWidth>
        <DialogContent />
        <PanelHeader title={headerComp} />
        <div className={classes.tableStyle}>
          <Table sortable columns={columns} rows={rowsToRender} pageSize={10} />
        </div>
        <DialogActions>
          <ProductMenu onAddClick={onAddClick} options={optionsToRender} />
          <TextButton
            translationKey="common.buttons.close"
            onClick={onCloseClick}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(Styles)(SiteDeviceSubsetProductTableDialog);
