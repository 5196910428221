import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  RadioButton,
  RadioGroup,
} from 'base-components';
import SeaTubeSearchExportFormat from 'domain/AppComponents/seatube/search/SeaTubeSearchExportFormat';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
};

// TODO: type exportFormat as ExportFormat and title as string once this is a typescript file
const exportFormatToFormControlLabel = (exportFormat, title) => (
  <FormControlLabel
    name={exportFormat.name}
    value={exportFormat.name}
    control={<RadioButton name={title} />}
    label={exportFormat.label}
  />
);

const ExportOptionsRadioGroup = ({
  onChange,
  value = undefined,
  title = 'Export Options',
  name = 'exportOptions',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    {exportFormatToFormControlLabel(SeaTubeSearchExportFormat.CSV, title)}
    {exportFormatToFormControlLabel(SeaTubeSearchExportFormat.JSON, title)}
    {exportFormatToFormControlLabel(
      SeaTubeSearchExportFormat.DARWIN_CORE,
      title
    )}
    {exportFormatToFormControlLabel(
      SeaTubeSearchExportFormat.INGESTION_CSV,
      title
    )}
  </RadioGroup>
);
ExportOptionsRadioGroup.propTypes = propTypes;

const DataSourceRadioGroup = ({
  onChange,
  value = undefined,
  title = 'dataSource',
  name = 'dataSource',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      value="playlist"
      control={<RadioButton name={title} />}
      label="Playlist"
    />
    <FormControlLabel
      value="deviceId"
      control={<RadioButton name={title} />}
      label="Device Id"
    />
    <FormControlLabel
      value="liveStream"
      control={<RadioButton name={title} />}
      label="Live Stream"
    />
  </RadioGroup>
);
DataSourceRadioGroup.propTypes = propTypes;

const TimeSourceRadioGroup = ({
  onChange,
  value = undefined,
  title = 'timeSource',
  name = 'timeSource',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      value="latestClip"
      control={<RadioButton name={title} />}
      label="Latest Clip"
    />
    <FormControlLabel
      name="timeSource"
      value="timeRange"
      control={<RadioButton name={title} />}
      label="Time Range"
    />
  </RadioGroup>
);
TimeSourceRadioGroup.propTypes = propTypes;

const TimeRangeRadioGroup = ({
  onChange,
  value = undefined,
  title = 'timeRange',
  name = 'timeRange',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      value="5"
      control={<RadioButton name={title} />}
      label="Five Minutes"
    />
    <FormControlLabel
      name="timeRange"
      value="1440"
      control={<RadioButton name={title} />}
      label="Daily"
    />
  </RadioGroup>
);
TimeRangeRadioGroup.propTypes = propTypes;

const TimeRangeRadioGroupWithWeek = ({
  onChange,
  value = undefined,
  title = 'timeRange',
  name = 'timeRange',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      value="5"
      control={<RadioButton name={title} />}
      label="Five Minutes"
    />
    <FormControlLabel
      name="timeRange"
      value="1440"
      control={<RadioButton name={title} />}
      label="Daily"
    />
    <FormControlLabel
      name="timeRange"
      value="10080"
      control={<RadioButton name={title} />}
      label="Weekly"
    />
  </RadioGroup>
);
TimeRangeRadioGroupWithWeek.propTypes = propTypes;

const RePostprocessRadioGroup = ({
  onChange,
  value = undefined,
  title = 'rePostProcess',
  name = 'rePostProcess',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      name="yesButton"
      value="yes"
      control={<RadioButton name={title} />}
      label="Yes"
    />
    <FormControlLabel
      name="noButton"
      value="no"
      control={<RadioButton name={title} />}
      label="No, Re-Post-Process Using Reference Latitude and Longitude"
    />
  </RadioGroup>
);
RePostprocessRadioGroup.propTypes = propTypes;

const CastReviewRadioGroup = ({
  onChange,
  value = undefined,
  title = 'rePostProcess',
  name = 'rePostProcess',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      name="reviewedButton"
      value="true"
      control={<RadioButton name={title} />}
      label="Reviewed"
    />
    <FormControlLabel
      name="notReviewedButton"
      value="false"
      control={<RadioButton name={title} />}
      label="In Progress"
    />
  </RadioGroup>
);
CastReviewRadioGroup.propTypes = propTypes;

const HydrophoneToolboxRadioGroup = ({
  onChange = undefined,
  value = undefined,
  title = 'automaticOptions',
  name = 'automaticOptions',
  defaultOptionLabel = undefined,
  customOptionLabel = undefined,
  presetValue = undefined,
  customValue = undefined,
  presetClick = undefined,
  customClick = undefined,
}) => (
  <FormControl>
    <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
      <FormControlLabel
        name="presetOption"
        value={presetValue}
        control={<RadioButton name={title} onChange={presetClick} />}
        label={defaultOptionLabel}
      />
      <FormControlLabel
        name="customOption"
        value={customValue}
        control={<RadioButton name={title} onChange={customClick} />}
        label={customOptionLabel}
      />
    </RadioGroup>
  </FormControl>
);

HydrophoneToolboxRadioGroup.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  defaultOptionLabel: PropTypes.string,
  customOptionLabel: PropTypes.string,
  presetValue: PropTypes.string,
  customValue: PropTypes.string,
  presetClick: PropTypes.func,
  customClick: PropTypes.func,
};

const renderMyAnnotations = (userLoggedIn, title) => {
  if (userLoggedIn) {
    return (
      <FormControlLabel
        name="myAnnotations"
        value="myAnnotations"
        control={<RadioButton name={title} />}
        label="My Annotations"
      />
    );
  }
  return null;
};

const AnnotationOwnerRadioGroup = ({
  onChange = undefined,
  value = undefined,
  title = 'owner',
  name = 'owner',
  userLoggedIn = false,
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      name="sharedAnnotations"
      value="sharedAnnotations"
      control={<RadioButton name={title} />}
      label="Shared Annotations"
    />
    {renderMyAnnotations(userLoggedIn, title)}
  </RadioGroup>
);
AnnotationOwnerRadioGroup.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  userLoggedIn: PropTypes.bool,
};

const EndeavourEarthquakeCompleteRadioGroup = ({
  onChange = undefined,
  value = undefined,
  title = 'status',
  name = 'status',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      name="all"
      value="all"
      control={<RadioButton name={title} />}
      label="All"
    />
    <FormControlLabel
      name="completeOnly"
      value="completeOnly"
      control={<RadioButton name={title} />}
      label="Complete Only"
    />
    <FormControlLabel
      name="incompleteOnly"
      value="incompleteOnly"
      control={<RadioButton name={title} />}
      label="Incomplete Only"
    />
  </RadioGroup>
);
EndeavourEarthquakeCompleteRadioGroup.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
};

const EndeavourEarthquakeExportRadioGroup = ({
  onChange = undefined,
  value = undefined,
  title = 'Export',
  name = 'export',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      name="json"
      value="json"
      control={<RadioButton name={title} />}
      label="JSON"
    />
    <FormControlLabel
      name="csv"
      value="csv"
      control={<RadioButton name={title} />}
      label="CSV"
    />
  </RadioGroup>
);
EndeavourEarthquakeExportRadioGroup.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
};

const EndeavourEarthquakeFixedDepthRadioGroup = ({
  onChange = undefined,
  value = undefined,
  title = 'depthType',
  name = 'depthType',
}) => (
  <RadioGroup title={title} name={name} onChange={onChange} value={value} row>
    <FormControlLabel
      name="all"
      value="all"
      control={<RadioButton name={title} />}
      label="All"
    />
    <FormControlLabel
      name="fixedDepthOnly"
      value="fixedDepthOnly"
      control={<RadioButton name={title} />}
      label="Fixed Depth Only"
    />
    <FormControlLabel
      name="variableDepthOnly"
      value="variableDepthOnly"
      control={<RadioButton name={title} />}
      label="Variable Depth Only"
    />
  </RadioGroup>
);
EndeavourEarthquakeFixedDepthRadioGroup.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
};

const ChartWidgetRawCleanRadioGroup = ({
  onChange = undefined,
  value = undefined,
  name = '',
}) => (
  <RadioGroup row onChange={onChange} value={value} name={name}>
    <FormControlLabel
      value="raw"
      name="rawdata"
      control={
        <RadioButton
          size="small"
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: '1rem',
            },
          }}
        />
      }
      label="Raw Data"
      sx={{
        '& .MuiFormControlLabel-label': {
          fontSize: '14px',
        },
      }}
    />
    <FormControlLabel
      value="clean"
      name="cleandata"
      control={
        <RadioButton
          size="small"
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: '1rem',
            },
          }}
        />
      }
      label="Clean Data"
      sx={{
        '& .MuiFormControlLabel-label': {
          fontSize: '14px',
        },
      }}
    />
  </RadioGroup>
);

ChartWidgetRawCleanRadioGroup.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};

export {
  DataSourceRadioGroup,
  TimeSourceRadioGroup,
  TimeRangeRadioGroup,
  TimeRangeRadioGroupWithWeek,
  HydrophoneToolboxRadioGroup,
  ExportOptionsRadioGroup,
  RePostprocessRadioGroup,
  CastReviewRadioGroup,
  AnnotationOwnerRadioGroup,
  EndeavourEarthquakeCompleteRadioGroup,
  EndeavourEarthquakeExportRadioGroup,
  EndeavourEarthquakeFixedDepthRadioGroup,
  ChartWidgetRawCleanRadioGroup,
};
