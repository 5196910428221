import { forwardRef } from 'react';
import BaseReactPlayer, { ReactPlayerProps } from 'react-player/lazy';

type BaseReactPlayerProps = Pick<
  ReactPlayerProps,
  | 'id'
  | 'ref'
  | 'url'
  | 'width'
  | 'height'
  | 'controls'
  | 'playing'
  | 'volume'
  | 'muted'
  | 'playbackRate'
  | 'onEnded'
  | 'onError'
  | 'onPause'
  | 'onPlay'
  | 'onProgress'
  | 'onReady'
  | 'onSeek'
>;

const ReactPlayer = forwardRef<BaseReactPlayer, BaseReactPlayerProps>(
  (props, ref) => {
    const { url, ...rest } = props;
    return (
      <BaseReactPlayer
        url={url}
        ref={ref}
        width="100%"
        height="100%"
        controls
        playing
        volume={0} // mute by default to enable autoplay
        progressInterval={1000}
        {...rest}
      />
    );
  }
);

export default ReactPlayer;
