import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ContinuousVideoFiles from 'library/CompositeComponents/video/ContinuousVideoFiles';
import DateFormatUtils from 'util/DateFormatUtils';
import VideoInformationDialog from './VideoInformationDialog';

const withDataFiles = (VideoComponent) => {
  /**
   * Wrapper component for the ContinuousVideo component
   *
   * Manages state for VideoPlayerMenu, UTCTimestamp, and UTCTooltip.
   */
  class WithDataFiles extends PureComponent {
    static propTypes = {
      files: PropTypes.instanceOf(ContinuousVideoFiles).isRequired,
      playerId: PropTypes.string.isRequired,
      onQualityChange: PropTypes.func.isRequired,
      onError: PropTypes.func.isRequired,
      onInfo: PropTypes.func.isRequired,
      onPlaylistComplete: PropTypes.func,
      onVideoLoad: PropTypes.func,
      onNext: PropTypes.func,
      onPrevious: PropTypes.func,
      onTime: PropTypes.func,
      onSeek: PropTypes.func,
      seekTo: PropTypes.number,
      isLoggedIn: PropTypes.bool,
      diveId: PropTypes.number,
      playlistHdrId: PropTypes.number,
      playlistLineId: PropTypes.number,
      searchTreeNodeId: PropTypes.number,
      snapshotQuality: PropTypes.string,
    };

    static defaultProps = {
      onPlaylistComplete: undefined,
      onNext: undefined,
      onPrevious: undefined,
      onTime: undefined,
      onSeek: undefined,
      seekTo: undefined,
      isLoggedIn: false,
      diveId: undefined,
      playlistHdrId: undefined,
      playlistLineId: null,
      searchTreeNodeId: undefined,
      onVideoLoad: undefined,
      snapshotQuality: undefined,
    };

    constructor(props) {
      super(props);

      this.state = {
        ready: false,
        searching: false,
        currentSpeed: 1,
        currentQuality: props.files.currentQuality,
        qualityOptions: props.files.qualityOptions,
        menuOpen: false,
        shareOpen: false,
        menuAnchorEl: undefined,
        recording: false,
        takeSnapshots: false,
        time: props.files.getDateAt(0),
        seekTo: props.seekTo,
        files: props.files,
        showVideoInfo: false,
      };
    }

    componentDidUpdate(prev) {
      const { seekTo, files } = this.props;
      const { seekTo: prevSeekTo, files: prevFiles } = prev;
      const { time } = this.state;

      /**
       * SeekTo and Files changing at the same time happens in playlists. Don't
       * update seekTo to time/1000 unless ONLY the files change.
       */
      if (seekTo !== prevSeekTo && files !== prevFiles) {
        this.setState({ seekTo, files });
      } else if (seekTo !== prevSeekTo) {
        this.setState({ seekTo });
      } else if (files !== prevFiles) {
        this.setState({ files }, () => {
          this.setState({
            seekTo: time / 1000,
          });
        });
      }
    }

    getInfoMap = () => {
      const { playerId } = this.props;
      const { time, files } = this.state;
      const infoMap = files.getInfoMap(time);

      // Add quality info to the map from JWPlayer
      if (
        window.jwplayer &&
        window.jwplayer(playerId) &&
        window.jwplayer(playerId).getVisualQuality
      ) {
        const qualityInfo = window.jwplayer(playerId).getVisualQuality();
        if (qualityInfo && qualityInfo.level) {
          const { width, height, label } = qualityInfo.level;
          infoMap.set('Resolution', `${width} x ${height} (${label})`);
        }
      }

      return infoMap;
    };

    handlePlayerReady = (continuousVideoAPI) => {
      this.handleSkipForward = continuousVideoAPI.handleSkipForward;
      this.handleSkipBackward = continuousVideoAPI.handleSkipBackward;
      this.handleNextClip = continuousVideoAPI.handleNextClip;
      this.handlePreviousClip = continuousVideoAPI.handlePreviousClip;
      this.handleFirstClip = continuousVideoAPI.handleFirstClip;
      this.handleLatestClip = continuousVideoAPI.handleLatestClip;
      this.setState({ ready: true });
    };

    /**
     * Updates the Timestamp as the video progresses
     *
     * @param {Date} time - The current time
     */
    handleTimeChange = (time) => {
      const { onTime } = this.props;
      if (onTime) onTime(time);
      this.setState({ time });
    };

    handleToggleMenuOpen = (event) => {
      this.setState((prev) => ({
        menuAnchorEl: prev.menuOpen ? null : event.currentTarget,
        menuOpen: !prev.menuOpen,
      }));
    };

    handleMenuChange = (name) => (option) => {
      this.setState({ [name]: option, menuOpen: false, menuAnchorEl: null });
    };

    handleQualityChange = (resolution) => {
      const { currentQuality } = this.state;
      if (currentQuality === resolution) {
        this.setState({ menuOpen: false });
      } else {
        const { onQualityChange } = this.props;
        this.setState(
          {
            menuOpen: false,
            shareOpen: false,
            menuAnchorEl: null,
            currentSpeed: 1,
            currentCaptions: 0,
            currentQuality: resolution,
            recording: false,
            startTime: null,
            endTime: null,
            seekTo: undefined,
          },
          () => {
            onQualityChange(resolution);
          }
        );
      }
    };

    handleCaptionListChange = (captionOptions, currentCaptions) => {
      this.setState({ captionOptions, currentCaptions });
    };

    handleStartRecording = () => {
      const { time } = this.state;
      this.setState({
        recording: true,
        startTime: time,
      });
    };

    handleStopRecording = () => {
      const { onError } = this.props;
      const { time, startTime } = this.state;

      if (startTime > time) {
        onError(
          `End time cannot be before the start time (${DateFormatUtils.formatDate(
            startTime,
            'full'
          )}) of the recording!`
        );
        return;
      }

      this.setState({
        recording: false,
        endTime: time,
      });
    };

    handleRecordingComplete = () => {
      this.setState({
        startTime: null,
        endTime: null,
      });
    };

    handleTakeSnapshot = () => {
      this.setState({ takeSnapshots: true });
    };

    handleSnapshotComplete = () => {
      this.setState({ takeSnapshots: false });
    };

    handleShowVideoInfo = () => {
      this.setState({ showVideoInfo: true });
    };

    handleCloseVideoInfo = () => {
      this.setState({ showVideoInfo: false });
    };

    handleToggleShareOpen = (event) => {
      this.setState((prev) => ({
        shareAnchorEl: prev.shareOpen ? null : event.currentTarget,
        shareOpen: !prev.shareOpen,
      }));
    };

    render() {
      const {
        isLoggedIn,
        playerId,
        onSeek,
        onPlaylistComplete,
        onError,
        onInfo,
        onNext,
        onPrevious,
        diveId,
        playlistHdrId,
        playlistLineId,
        searchTreeNodeId,
        onVideoLoad,
        snapshotQuality,
      } = this.props;
      const {
        captionOptions,
        currentCaptions,
        currentQuality,
        currentSpeed,
        endTime,
        menuAnchorEl,
        menuOpen,
        playlist,
        qualityOptions,
        ready,
        recording,
        shareAnchorEl,
        shareOpen,
        startTime,
        takeSnapshots,
        time,
        seekTo,
        files,
        showVideoInfo,
      } = this.state;

      return (
        <>
          <VideoInformationDialog
            container={document.getElementById(playerId)}
            open={showVideoInfo}
            onClose={this.handleCloseVideoInfo}
            infoMap={this.getInfoMap()}
            title="SeaTube Video Information"
          />
          <VideoComponent
            id={playerId}
            playerId={playerId}
            files={files}
            ready={ready}
            time={time}
            isLoggedIn={isLoggedIn}
            onError={onError}
            onInfo={onInfo}
            videoProps={{
              playlist,
              speed: currentSpeed,
              captions: currentCaptions,
              onCaptionListChange: this.handleCaptionListChange,
              onPlayerReady: this.handlePlayerReady,
              onTime: this.handleTimeChange,
              onVideoLoad,
              onPlaylistComplete,
              onSeek,
              seekTo,
            }}
            menuProps={{
              anchorEl: menuAnchorEl,
              onClose: this.handleToggleMenuOpen,
              open: menuOpen,
              qualityProps: {
                currentQuality,
                qualityOptions,
                onQualityChange: this.handleQualityChange,
              },
              speedProps: {
                currentSpeed,
                onSpeedChange: this.handleMenuChange('currentSpeed'),
              },
              captionProps: {
                currentCaptions,
                captionOptions,
                onCaptionChange: this.handleMenuChange('currentCaptions'),
              },
            }}
            buttonProps={{
              onToggleMenuOpen: this.handleToggleMenuOpen,
              onSkipForward: this.handleSkipForward,
              onSkipBackward: this.handleSkipBackward,
              onNextClip: this.handleNextClip,
              onPreviousClip: this.handlePreviousClip,
              onFirstClip: this.handleFirstClip,
              onLatestClip: this.handleLatestClip,
              onNext,
              onPrevious,
            }}
            recordingProps={{
              files,
              recording,
              startTime,
              endTime,
              onStart: this.handleStartRecording,
              onStop: this.handleStopRecording,
              onClose: this.handleRecordingComplete,
            }}
            videoInformationProps={{
              onClick: this.handleShowVideoInfo,
              onClose: this.handleCloseVideoInfo,
            }}
            snapshotProps={{
              takeSnapshots,
              snapshotQuality,
              onSnapshot: this.handleTakeSnapshot,
              onSnapshotComplete: this.handleSnapshotComplete,
              deviceId: files.getDeviceIdAt(files.getTimeElapsedAtDate(time)),
            }}
            shareProps={{
              open: shareOpen,
              popoverAnchorEl: shareAnchorEl,
              onShare: this.handleToggleShareOpen,
              onClose: this.handleToggleShareOpen,
              time,
              onInfo,
              diveId,
              playlistHdrId,
              playlistLineId,
              searchTreeNodeId,
            }}
          />
        </>
      );
    }
  }
  return WithDataFiles;
};

export default withDataFiles;
