import { useState, useEffect } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Typography } from 'base-components';
import NetworkConsoleService from 'domain/AppComponents/system-status-message/NetworkConsoleService';

const STYLES = (theme: Theme) =>
  createStyles({
    systemStatus: {
      position: 'absolute',
      top: '5%',
      left: theme.spacing(0),
      right: theme.spacing(0),
      zIndex: 10,

      color: 'black',
      width: 'fit-content',
      maxWidth: '60%',
      border: '#FF9F00 thin solid',
      borderRadius: '0.25rem',

      fontSize: '18px',
      textAlign: 'center',

      margin: '0 auto',
      padding: '5px',
    },
    systemStatusHov: {
      backgroundColor: '#FFFFCC',
      opacity: 0.5,
    },
    systemStatusNoHov: {
      backgroundColor: '#FF9F00',
    },
  });

type SystemStatusMessageProps = WithStyles<typeof STYLES>;

const SystemStatusMessageBanner: React.FC<SystemStatusMessageProps> = (
  props: SystemStatusMessageProps
) => {
  const { classes } = props;

  const [message, setMessage] = useState('');
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    NetworkConsoleService.getSystemStatusMessage().then((result) => {
      setMessage(result);
    });
  }, []);

  if (message && message !== '') {
    return (
      <Typography
        data-test="systemStatusMessageBanner"
        // Note: We currently allow markup in our System Status Message so this should only ever be internally used
        // If we have a better method or can get a sanitize library this might be safer
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: message }}
        className={
          hovered
            ? `${classes.systemStatus} ${classes.systemStatusHov}`
            : `${classes.systemStatus} ${classes.systemStatusNoHov}`
        }
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      />
    );
  }
  return null;
};

export default withStyles(STYLES)(SystemStatusMessageBanner);
