import { post, get } from 'util/WebRequest';

class DriverScheduleService {
  static getAll = () =>
    get('DriverScheduleService', {
      operation: 5,
    }).then((payload) => payload.data.payload);

  static getOne = () =>
    get('DriverScheduleService', {
      operation: 4,
    }).then((payload) => payload.data.payload);

  static create = (payload) =>
    post('DriverScheduleService', {
      operation: 1,
      driverScheduleId: payload.driverScheduleId,
      description: payload.description,
      deviceId: payload.deviceId,
      enabled: payload.enabled,
      interfaceName: payload.interfaceName,
      methodName: payload.methodName,
      scheduleYear: payload.scheduleYear || undefined,
      scheduleMonth: payload.scheduleMonth || undefined,
      scheduleDayOfMonth: payload.scheduleDayOfMonth || undefined,
      scheduleHour: payload.scheduleHour || undefined,
      scheduleMinute: payload.scheduleMinute || undefined,
      scheduleSecond: payload.scheduleSecond || undefined,
      repeatDay: payload.repeatDay || undefined,
      repeatHour: payload.repeatHour || undefined,
      repeatMinute: payload.repeatMinute || undefined,
      repeatSecond: payload.repeatSecond || undefined,
      param1: payload.param1,
      param2: payload.param2,
      param3: payload.param3,
      param4: payload.param4,
      param5: payload.param5,
      endDate: payload.endDate,
    });

  static update = (payload) =>
    post('DriverScheduleService', {
      operation: 2,
      driverScheduleId: payload.driverScheduleId,
      description: payload.description,
      deviceId: payload.deviceId,
      enabled: payload.enabled,
      interfaceName: payload.interfaceName,
      methodName: payload.methodName,
      scheduleYear: payload.scheduleYear || undefined,
      scheduleMonth: payload.scheduleMonth || undefined,
      scheduleDayOfMonth: payload.scheduleDayOfMonth || undefined,
      scheduleHour: payload.scheduleHour || undefined,
      scheduleMinute: payload.scheduleMinute || undefined,
      scheduleSecond: payload.scheduleSecond || undefined,
      repeatDay: payload.repeatDay || undefined,
      repeatHour: payload.repeatHour || undefined,
      repeatMinute: payload.repeatMinute || undefined,
      repeatSecond: payload.repeatSecond || undefined,
      param1: payload.param1,
      param2: payload.param2,
      param3: payload.param3,
      param4: payload.param4,
      param5: payload.param5,
      endDate: payload.endDate,
    });
}
export default DriverScheduleService;
