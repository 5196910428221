import { OncLayouts } from 'library/CompositeComponents/dashboard/DashboardTypes';

const annotationLayout: OncLayouts = {
  lg: [
    {
      w: 8,
      h: 7,
      x: 4,
      y: 0,
      i: 'button-set-1',
    },
    {
      w: 4,
      h: 14,
      x: 0,
      y: 4,
      i: 'annotation-list-1',
    },
    {
      w: 8,
      h: 17,
      x: 4,
      y: 3,
      i: 'manual-entry-1',
    },
    {
      w: 4,
      h: 10,
      x: 0,
      y: 0,
      i: 'dive-video-1',
    },
  ],
  md: [
    {
      w: 10,
      h: 7,
      x: 0,
      y: 0,
      i: 'button-set-1',
    },
    {
      w: 5,
      h: 17,
      x: 5,
      y: 3,
      i: 'annotation-list-1',
    },
    {
      w: 5,
      h: 17,
      x: 0,
      y: 3,
      i: 'manual-entry-1',
    },
    {
      w: 10,
      h: 17,
      x: 0,
      y: 10,
      i: 'dive-video-1',
    },
  ],
  sm: [
    {
      w: 8,
      h: 7,
      x: 0,
      y: 6,
      i: 'button-set-1',
    },
    {
      w: 8,
      h: 24,
      x: 0,
      y: 15,
      i: 'annotation-list-1',
    },
    {
      w: 8,
      h: 14,
      x: 0,
      y: 9,
      i: 'manual-entry-1',
    },
    {
      w: 8,
      h: 14,
      x: 0,
      y: 0,
      i: 'dive-video-1',
    },
  ],
  xs: [
    {
      w: 6,
      h: 10,
      x: 0,
      y: 5,
      i: 'button-set-1',
    },
    {
      w: 6,
      h: 14,
      x: 0,
      y: 14,
      i: 'annotation-list-1',
    },
    {
      w: 6,
      h: 24,
      x: 0,
      y: 9,
      i: 'manual-entry-1',
    },
    {
      w: 6,
      h: 24,
      x: 0,
      y: 0,
      i: 'dive-video-1',
    },
  ],
};

export default annotationLayout;
