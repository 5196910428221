import { Children, cloneElement, PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ResizableJWPlayer } from 'base-components';
import SeaTubeSubPlaylist from 'library/CompositeComponents/video/SeaTubeSubPlaylist';

const styles = {
  '@global': {
    // Display the chapter time, not the time time (whih is unset?)
    '& .jw-time-time': {
      display: 'none !important',
    },
    '& .jw-time-chapter': {
      display: 'inline-block !important',
    },

    // Allow the time tooltip to overflow the video container
    '& .jw-controls,[class*="jw-breakpoint-"]': {
      overflow: 'visible !important',
    },
    '& .jw-breakpoint-1,.jw-breakpoint-2,.jw-breakpoint-3': {
      '& .jw-button-container': {
        flexFlow: 'row nowrap !important',
      },
      '& .jw-reset.jw-spacer': {
        padding: '4px !important',
      },
    },
    '& .jw-breakpoint-0,.jw-flag-small-player': {
      '& div[aria-label="Mute"], div[aria-label="Unmute"]': {
        display: 'none !important',
      },

      '& .jw-text-countdown': {
        display: 'none !important',
      },

      '& .jw-button-container': {
        flexFlow: 'row nowrap !important',
        '& .jw-icon': {
          width: '30px !important',
        },
      },
    },
    '& .jw-controlbar': {
      maxHeight: 'none !important',
      backgroundColor: 'rgba(0, 0, 0, 0.6) !important',
    },
    '& .jw-button-container': {
      flexFlow: 'row wrap !important',
      '& .jw-button-image': {
        maxWidth: '24px !important',
      },
    },
    '& div[aria-label="Volume"]': {
      // Greater than the z-index of ZoomInSeekbar
      zIndex: '999 !important',
    },
    '& .jw-reset.jw-spacer': {
      order: '10 !important',
    },
    '& div[aria-label="Fullscreen"], div[aria-label="Exit Fullscreen"]': {
      order: '14 !important',
    },
    /*
    We need to add display: none as actually removing the settings button from the
    DOM causes an issue with playlists. By just hiding the button, we are able to resolve
    the issue.
  */
    '& .jw-icon-settings': {
      display: 'none !important',
    },

    '& div[aria-label="Settings"]': {
      order: '13 !important',
    },
    '& div[aria-label="Picture in Picture (PiP)"]': {
      order: '14 !important',
    },
    '& .jw-text-duration': {
      order: '9 !important',
    },
    '& .jw-text-elapsed': {
      order: '8 !important',
    },
    '& .jw-text-countdown': {
      order: '7 !important',
    },
    '& div[aria-label="Mute"], div[aria-label="Unmute"]': {
      order: '6 !important',
    },
    '& div[aria-label="Forward 30 Seconds"]': {
      order: '5 !important',
    },
    '& div[aria-label="Back 30 Seconds"]': {
      order: '4 !important',
    },
    '& div[aria-label="Next"]': {
      order: '3 !important',
    },
    '& div[aria-label="Pause"], div[aria-label="Play"], div[aria-label="Stop"]':
      {
        order: '2 !important',
      },
    '& div[aria-label="Next Clip"]': {
      order: '4 !important',
    },
    '& div[aria-label="Previous Clip"]': {
      order: '1 !important',
      '& svg': {
        transform: 'scaleX(-1) !important',
      },
    },
    '& div[aria-label="Latest Clip"]': {
      order: '4 !important',
    },
    '& div[aria-label="First Clip"]': {
      order: '0 !important',
      '& svg': {
        transform: 'scaleX(-1) !important',
      },
    },
    '& .jw-icon-rewind': {
      display: 'none !important',
    },
    '& div[aria-label="Closed Captions"]': {
      display: 'none !important',
    },
  },
};

class VideoSubPlaylist extends PureComponent {
  static propTypes = {
    // Required
    playerId: PropTypes.string.isRequired,
    playlist: PropTypes.instanceOf(SeaTubeSubPlaylist).isRequired,
    // Booleans
    autoPlay: PropTypes.bool,
    isLive: PropTypes.bool,
    muted: PropTypes.bool,
    showControls: PropTypes.bool,
    showPlaylistOverlay: PropTypes.bool,
    showRateControls: PropTypes.bool,
    // Functions
    onTime: PropTypes.func,
    onPlayerReady: PropTypes.func,
    onCaptionListChange: PropTypes.func,
    // Other
    aspectratio: PropTypes.string,
    className: PropTypes.string,
    speed: PropTypes.number,
    captions: PropTypes.number,
    classes: PropTypes.objectOf(PropTypes.string),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    buttons: PropTypes.shape({
      add: PropTypes.arrayOf(
        PropTypes.shape({
          img: PropTypes.node.isRequired,
          tooltip: PropTypes.string.isRequired,
          callback: PropTypes.func.isRequired,
          id: PropTypes.string.isRequired,
          btnClass: PropTypes.string,
        })
      ),
      remove: PropTypes.arrayOf(PropTypes.string),
    }),
    onPlaylistItemComplete: PropTypes.func.isRequired,
    onMeta: PropTypes.func,
  };

  static defaultProps = {
    autoPlay: false,
    muted: true,
    isLive: false,
    showControls: true,
    showRateControls: true,
    showPlaylistOverlay: false,
    onTime: null,
    onPlayerReady: null,
    onCaptionListChange: null,
    onMeta: () => {},
    aspectratio: undefined,
    captions: 0,
    children: undefined,
    classes: undefined,
    className: '',
    buttons: {
      add: undefined,
      remove: undefined,
    },
    speed: 1,
  };

  constructor(props) {
    super(props);

    this.state = {
      playerReady: false,
    };

    this.playlist = props.playlist;
  }

  componentDidUpdate(prev) {
    const {
      buttons: { add, remove },
      captions,
      playlist,
      speed,
    } = this.props;

    if (
      remove &&
      JSON.stringify(prev.buttons.remove) !== JSON.stringify(remove)
    ) {
      this.removeButtons(remove);
    }

    if (add && JSON.stringify(prev.buttons.add) !== JSON.stringify(add)) {
      this.addCustomButtons(add);
    }

    if (JSON.stringify(prev.playlist) !== JSON.stringify(playlist)) {
      this.handleCaptionListChange();
      this.setSpeed(speed);
    }

    if (prev.captions !== captions) {
      this.setCaptions(captions);
    }

    if (prev.speed !== speed) {
      this.setSpeed(speed);
    }
  }

  handleTimeChange = (event) => {
    const { onTime } = this.props;
    if (!onTime) return;
    onTime(event.position);
  };

  handlePlayerReady = () => {
    const {
      onPlayerReady,
      buttons: { add, remove },
    } = this.props;

    this.handleCaptionListChange();

    if (remove) {
      this.removeButtons(remove);
    }
    if (add) {
      this.addCustomButtons(add);
    }

    if (onPlayerReady)
      onPlayerReady({
        querySelector: this.querySelector,
        finish: this.finish,
        stop: this.stop,
        load: this.load,
      });
    this.setState({
      playerReady: true,
    });
  };

  handleCaptionListChange = () => {
    const { onCaptionListChange } = this.props;
    const player = this.getPlayer();
    if (!player || !onCaptionListChange) return;
    onCaptionListChange(player.getCaptionsList(), player.getCurrentCaptions());
  };

  setSpeed = (speed) => {
    const player = this.getPlayer();
    if (!player) return;
    player.setPlaybackRate(speed);
  };

  setCaptions = (index) => {
    const player = this.getPlayer();
    if (!player || index < 0) return;
    player.setCurrentCaptions(index);
  };

  getPlayer = () => {
    const { playerId } = this.props;
    return window.jwplayer ? window.jwplayer(playerId) : undefined;
  };

  getPlayerContainer = () => {
    const player = this.getPlayer();
    return player ? player.getContainer() : undefined;
  };

  querySelector = (query) => {
    const playerContainer = this.getPlayerContainer();
    return playerContainer ? playerContainer.querySelector(query) : undefined;
  };

  finish = () => {
    const player = this.getPlayer();
    return player ? player.dispatchEvent('playlistComplete') : undefined;
  };

  stop = () => {
    const player = this.getPlayer();
    if (!player) return;

    player.stop();
  };

  play = () => {
    const player = this.getPlayer();
    return player ? player.play() : undefined;
  };

  pause = () => {
    const player = this.getPlayer();
    return player ? player.pause() : undefined;
  };

  load = (playlist, seekTime) => {
    const player = this.getPlayer();
    if (!player) return;

    if (playlist.start === this.playlist.start) {
      player.seek(seekTime);
    } else {
      this.pendingSeekTime = seekTime;
      this.playlist = playlist;

      // JWPlayer sets the state "complete" when a sub-playlist ends, and
      // *may* pause the player at the same time
      const keepPlaying = player.getState() === 'complete';
      player.load(playlist.contents);
      if (keepPlaying) player.play();
    }

    if (playlist.isReset) {
      player.stop();
    }
  };

  /**
   * If {@link seek} selected a new file in the playlist, seek within that item
   * after it's been loaded.
   *
   * FIXME: JWPlayer's on('meta') passes some args to the callback that will be
   * needed if props.onMeta is defined
   */
  handleMetadataChange = () => {
    const { onMeta } = this.props;

    const player = this.getPlayer();
    if (player && this.pendingSeekTime) player.seek(this.pendingSeekTime);
    this.pendingSeekTime = undefined;

    onMeta();
  };

  getFullscreen = () => {
    const player = this.getPlayer();
    return player ? player.getFullscreen() : undefined;
  };

  addCustomButtons = (customButtons) => {
    const player = this.getPlayer();
    if (!player) return;
    customButtons.forEach((customButton) => {
      const { img, tooltip, callback, id, btnClass } = customButton;
      player.addButton(img, tooltip, callback, id, btnClass);
    });
  };

  removeButtons = (buttons) => {
    const player = this.getPlayer();
    if (!player) return; // TODO handle error
    buttons.forEach((buttonId) => {
      player.removeButton(buttonId);
    });
  };

  renderVideoPlayerChildren = () => {
    const { children } = this.props;
    const { playerReady } = this.state;

    if (!playerReady) return undefined;

    const elements = Children.toArray(children).map((element) =>
      cloneElement(element, {
        querySelector: this.querySelector,
        play: this.play,
        pause: this.pause,
        getFullscreen: this.getFullscreen,
      })
    );

    return elements;
  };

  render() {
    const {
      aspectratio,
      autoPlay,
      children,
      classes,
      className,
      muted,
      onPlaylistItemComplete,
      onTime,
      showControls,
      showPlaylistOverlay,
      showRateControls,
      playlist,

      ...otherProps
    } = this.props;

    return (
      <>
        <ResizableJWPlayer
          {...otherProps}
          className={`${className} ${classes.root}`}
          customProps={{
            controls: showControls,
            mute: muted,
            playbackRateControls: showRateControls,
            aspectratio,
            nextUpDisplay: showPlaylistOverlay,
          }}
          isAutoPlay={autoPlay}
          playlist={playlist.contents}
          onReady={this.handlePlayerReady}
          onTime={this.handleTimeChange}
          onCaptionsList={this.handleCaptionListChange}
          onComplete={onPlaylistItemComplete}
          onMeta={this.handleMetadataChange}
        />
        {this.renderVideoPlayerChildren()}
      </>
    );
  }
}
export default withStyles(styles)(VideoSubPlaylist);
