const isInvalidFilterValue = (value) => {
  if (Array.isArray(value)) {
    if (value.length === 2) {
      if (value[0] === undefined && value[1] === undefined) {
        return true;
      }
    } else if (value.length === 0) {
      return true;
    }
  }

  if (typeof value === 'string' && value === '') {
    return true;
  }

  if (value === undefined || value === false) {
    return true;
  }

  return false;
};

class FilterUtil {
  static handleFilterChange = (filterToModify, filterName, value) => {
    const filter = { ...filterToModify };

    if (filter[filterName] && isInvalidFilterValue(value)) {
      delete filter[filterName];
    } else if (!isInvalidFilterValue(value)) {
      // MultipleChipSelect requires entire option objects, so
      // store only their value
      if (
        Array.isArray(value) &&
        value.every(isNaN) &&
        filterName !== 'attributes'
      ) {
        const newValue = value.map((option) => option.value);
        filter[filterName] = newValue;
      } else {
        filter[filterName] = value;
      }
    }
    return filter;
  };
}

export default FilterUtil;
