import DeviceCategories from 'domain/Apps/seatube/util/DeviceCategories';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

const FIXED_CAMERA_TREE_OPERATION = 15;

type FixedCameraLocationTreeParameters = {
  label: string;
  deviceCategoryId: number;
};

export type SearchTreeNode = {
  html: string;
  latitude?: number;
  longitude?: number;
  searchTreeNodeId: number;
  siteDepth?: number;
  wasDeviceFound: boolean;
  children?: SearchTreeNode[];
};

export const useFixedCameraLocations = () =>
  useGet<SearchTreeNode, FixedCameraLocationTreeParameters>(
    'SearchTreeService',
    15,
    {
      label: 'Fixed Video Cameras',
      deviceCategoryId: DeviceCategories.FIXED_CAMERA,
    }
  );

class SearchTreeService {
  static getFixedCameraTree() {
    return get('SearchTreeService', {
      operation: FIXED_CAMERA_TREE_OPERATION,
      deviceCategoryId: DeviceCategories.FIXED_CAMERA,
      label: 'Fixed Cameras',
    }).then((response) => parseDmasAPIResponse(response));
  }
}

export default SearchTreeService;
