import PropTypes from 'prop-types';

import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import Environment from 'util/Environment';

const getUrl = (props) => {
  const { siteDeviceId, siteDeviceSubsetId } = props;

  if (siteDeviceSubsetId) {
    return `${Environment.getDmasUrl()}/SiteDeviceSubsetMaintenance?siteDeviceId=${siteDeviceId}&siteDeviceSubsetId=${siteDeviceSubsetId}`;
  }
  return `${Environment.getDmasUrl()}/SiteDeviceSubsetMaintenance?siteDeviceId=${siteDeviceId}`;
};

const redirectToSiteDeviceSubset = (props) => {
  global.open(getUrl(props), '_self');
};

const handleRedirectToSiteDeviceSubset = (props) => () => {
  redirectToSiteDeviceSubset(props);
};

const SiteDeviceSubsetLink = ({
  children = undefined,
  siteDeviceId = undefined,
  siteDeviceSubsetId = undefined,
}) => (
  <OpenInNewLink href={getUrl({ siteDeviceId, siteDeviceSubsetId })}>
    {children}
  </OpenInNewLink>
);

SiteDeviceSubsetLink.propTypes = {
  children: PropTypes.node,
  siteDeviceId: PropTypes.number,
  siteDeviceSubsetId: PropTypes.number,
};

export {
  handleRedirectToSiteDeviceSubset,
  redirectToSiteDeviceSubset,
  SiteDeviceSubsetLink,
  getUrl,
};
export default SiteDeviceSubsetLink;
