import { FC } from 'react';
import {
  LeafletIcon,
  Marker,
  Polygon,
  LeafletTooltip as Tooltip,
} from 'base-components';
import CastPopupv2 from 'domain/Apps/geospatial-search/community-fisher/CastPopupv2';
import GeospatialSearchHelper from 'domain/Apps/geospatial-search/community-fisher/GeospatialSearchHelper';
import { useSnackbars } from 'util/hooks/useSnackbars';
import {
  SelectedStation,
  SiteDeviceSubset,
} from '../../definitions/GeospatialSearchTypes';

type CFMarkerProps = {
  /** Station information */
  station: SelectedStation;
  /** Function to filter casts */
  filterCasts: (siteDeviceSubsets: SiteDeviceSubset[]) => SiteDeviceSubset[];
  /** Callback when downloading casts */
  downloadAllUnrestrictedCastsFromStation: (stationCode: string) => void;
  /** Whether or not to hide the Polygon on the map */
  hideCFPolygons: boolean;
  isDecommissioned: boolean;
};
const CFMarkerv2: FC<CFMarkerProps> = ({
  station,
  filterCasts,
  downloadAllUnrestrictedCastsFromStation,
  hideCFPolygons,
  isDecommissioned,
}) => {
  const { onInfo, onError } = useSnackbars();

  if (station.positions.length < 1) return null;
  const filteredCasts = filterCasts(station.siteDeviceSubsets);
  const errorMessage = station.positionError
    ? ' - Invalid geospatial area'
    : '';
  if (filteredCasts.length < 1) return null;
  return (
    <Marker
      position={
        GeospatialSearchHelper.calculateCenterPoint(station.positions) as any
      }
      key={station.id}
      icon={isDecommissioned ? LeafletIcon.GREY : LeafletIcon.DEFAULT}
      // @ts-expect-error does not affect operation, used to recolour icons properly
      className={
        isDecommissioned
          ? 'decommissioned_casting_station_marker'
          : 'active_casting_station_marker'
      }
    >
      <CastPopupv2
        code={station.code}
        name={station.name}
        dateFrom={station.dateFrom}
        dateTo={station.dateTo}
        searchTreeNodeId={station.id}
        casts={filteredCasts}
        onError={onError}
        onInfo={onInfo}
        isAssigned
        downloadAllUnrestrictedCastsFromStation={
          downloadAllUnrestrictedCastsFromStation
        }
      />
      <Tooltip permanent direction="right" interactive>
        {`${station.name || station.code}${errorMessage || ''}`}
      </Tooltip>
      {!hideCFPolygons && (
        <Polygon
          positions={station.positions}
          key={station.id}
          color={isDecommissioned ? 'grey' : '#3388ff'} // 3388ff is the default leaflet polygon colouring.
        >
          <CastPopupv2
            code={station.code}
            name={station.name}
            dateFrom={station.dateFrom}
            dateTo={station.dateTo}
            searchTreeNodeId={station.id}
            casts={filteredCasts}
            onError={onError}
            onInfo={onInfo}
            isAssigned
            downloadAllUnrestrictedCastsFromStation={
              downloadAllUnrestrictedCastsFromStation
            }
          />
          <Tooltip direction="left" interactive>
            {`has ${filteredCasts.length}  
          ${filteredCasts.length === 1 ? 'cast' : 'casts'}
           `}
          </Tooltip>
        </Polygon>
      )}
    </Marker>
  );
};

export default CFMarkerv2;
