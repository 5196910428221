import { get } from 'util/WebRequest';

const SERVICE = 'api/deployments';

class DeploymentsWebServiceAPI {
  static get(params) {
    return get(SERVICE, params);
  }
}
export default DeploymentsWebServiceAPI;
