import { Sorting } from '@devexpress/dx-react-grid';
import { ShowDialogIndicator } from './DashboardTableTypes';

export const COLUMNS = [
  { name: 'layoutName', title: 'Title' },
  { name: 'ownerName', title: 'Owner' },
  { name: 'modifyBy', title: 'Modified By' },
  { name: 'modifyDate', title: 'Modified Date' },
  { name: 'actions', title: 'Actions' },
];

export const COLUMN_EXTENSIONS = [
  { columnName: 'title', width: '35%', wordWrapEnabled: true },
  {
    columnName: 'ownerName',
    width: '22%',
    wordWrapEnabled: true,
  },
  { columnName: 'modifyDate', width: '16%' },
  {
    columnName: 'actions',
    width: '20%',
    sortingEnabled: false,
  },
];

export const DEFAULT_SORTING: Sorting[] = [
  {
    columnName: 'layoutName',
    direction: 'asc',
  },
];

export const COLUMN_SORTING = [
  {
    columnName: 'layoutName',
    compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
  },
];

export const SORTING_COLUMN_EXTENSIONS = [
  { columnName: 'actions', sortingEnabled: false },
];

export const PAGE_SIZES = [10, 15, 50, 100];
export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGESIZE = 15;

export const ICON_STYLES = {
  icons: {
    verticalAlign: 'bottom',
    display: 'inline',
    paddingLeft: '10px',
  },
};

export const DEFAULT_DIALOG_INDICATOR: ShowDialogIndicator = {
  dialog: 'NONE',
};

export const LAYOUT_SERVICE = 'LayoutService';
export const DASHBOARD_RESOURCE_TYPE_ID = 2000;
export const DASHBOARD_RESOURCE_ID = 1;
