import Moment from 'moment';

class DateFormatUtils {
  static formatDate(date, type) {
    switch (type) {
      case 'full':
        return Moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
      case 'date':
        return Moment(date).utc().format('YYYY-MM-DD');
      case 'time':
        return Moment(date).utc().format('HH:mm:ss');
      case 'time-no-seconds':
        return Moment(date).utc().format('HH:mm');
      case 'time-with-month-name-string':
        return Moment(date).utc().format('DD-MMM-YYYY');
      case 'milliseconds':
        return Moment(date).utc().format('YYYY-MM-DD HH:mm:ss.SSS');
      case 'ISO8601_EXTENDED':
        return Moment(date).utc().toISOString();
      default:
        return null;
    }
  }
}
export default DateFormatUtils;
