import { useState, useEffect, useMemo, ReactNode } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from 'base-components';
import EndeavourEarthquakeService from 'domain/services/EndeavourEarthquakeService';
import useWebService from 'util/hooks/useWebService';
import EndeavourContext from './EndeavourContext';

const EndeavourProvider = ({ children }: { children: ReactNode }) => {
  const [earthquakes, setEarthquakes] = useState<any[]>([]);
  const [mapSelectedEarthquakes, setMapSelectedEarthquakes] = useState<any[]>(
    []
  );
  const [mapSites, setMapSites] = useState<any[]>([]);
  const [dashboardId] = useState<string>('1');
  const [selectedEarthquake, setSelectedEarthquake] = useState<
    number | undefined
  >(undefined);
  const [filters, setFilters] = useState<any>({
    minDepth: -50,
    maxDepth: 50,
    minMagnitude: -50,
    maxMagnitude: 50,
    startTime: new Date(
      new Date().setDate(new Date().getDate() - 7)
    ).toISOString(),
    endTime: new Date().toISOString(),
  });
  const [filteredEarthquakeList] = useState<any[]>([]);

  const [result, loading, fetchFilteredEarthquakes] = useWebService({
    method: EndeavourEarthquakeService.getEarthquakes,
  });

  useEffect(() => {
    if (result) {
      // Assuming result has properties `earthquakes` and `mapSites`
      setEarthquakes(result.earthquakes || []);
      setMapSites(result.mapSites || []);
    }
  }, [result]);

  useEffect(() => {
    fetchFilteredEarthquakes(filters);
  }, [filters, fetchFilteredEarthquakes]);

  const contextValue = useMemo(
    () => ({
      earthquakes,
      mapSites,
      dashboardId,
      selectedEarthquake,
      setSelectedEarthquake,
      mapSelectedEarthquakes,
      setMapSelectedEarthquakes,
      filters,
      setFilters,
      filteredEarthquakeList,
    }),
    [
      earthquakes,
      mapSites,
      dashboardId,
      selectedEarthquake,
      mapSelectedEarthquakes,
      filters,
      filteredEarthquakeList,
    ]
  );

  return (
    <EndeavourContext.Provider value={contextValue}>
      {children}

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress size={80} />
      </Backdrop>
    </EndeavourContext.Provider>
  );
};

export default EndeavourProvider;
