import { post } from 'util/WebRequest';

class FileManagementService {
  static getFileStateChange(fileIds, fileState) {
    const params = {
      operation: 2,
      fileIds,
      fileState,
    };
    return post('FileManagementService', params);
  }
}

export default FileManagementService;
