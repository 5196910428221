import * as React from 'react';
import { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Add } from '@onc/icons';
import { Menu, MenuItem } from 'base-components';
import { OutlinedButton } from 'library/CompositeComponents/button/Buttons';
import { FILTER_OPTIONS } from './DataSourceWidgetConstants';

const useStyles = makeStyles(() =>
  createStyles({
    filterButton: {
      marginTop: '5px',
      margin: 'auto',
    },
  })
);

interface Props {
  handleAddFilter: (any) => void;
  getFilterStatus: (any) => boolean;
}

const DataSourceSelectionWidgetMenu: React.FC<Props> = (props: Props) => {
  const { handleAddFilter, getFilterStatus } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const classes = useStyles();

  const handleOpenMenu = (event: any) => {
    setIsMenuOpen(!isMenuOpen);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <OutlinedButton
        translationKey="common.buttons.addFilter"
        startIcon={<Add />}
        fullWidth
        onClick={handleOpenMenu}
        className={classes.filterButton}
        aria-describedby="add-filter"
      />
      <Menu
        open={isMenuOpen}
        id="add-filter"
        anchorEl={anchorEl}
        onClose={() => setIsMenuOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {FILTER_OPTIONS.map((option) => (
          <MenuItem
            key={option.name}
            onClick={() => handleAddFilter(option)}
            disabled={getFilterStatus(option)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DataSourceSelectionWidgetMenu;
