import { ArrowRight } from '@onc/icons';

type SeekbarArrowRightProps = {
  text: string;
};

// TODO: Change styles to sx so that they respect theming
const SeekbarArrowRight = ({ text }: SeekbarArrowRightProps) => (
  <div
    style={{
      float: 'right',
      position: 'absolute',
      right: '0px',
      top: '0.2em',
    }}
  >
    <div style={{ textAlign: 'right' }}>
      <ArrowRight />
      <div style={{ paddingRight: '5px', paddingBottom: '-5px' }}>{text}</div>
    </div>
  </div>
);

export default SeekbarArrowRight;
