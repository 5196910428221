import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Typography } from 'base-components';
import DeviceCategorySelect from 'domain/AppComponents/dropdowns/DeviceCategorySelect';
import DeviceTypeSelect from 'domain/AppComponents/dropdowns/DeviceTypeSelect';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import DeviceListingTable from './DeviceListingTable';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
  dropdown: {
    margin: theme.spacing(1),
    minWidth: '400px',
  },
});

const defaultState = -1;

type Props = {
  onError: (message: any, callback?: any) => void;
  classes: { root: string; dropdown: string };
  deviceId: number;
};

const DeviceListingPage = (props: Props) => {
  const { onError, classes, deviceId } = props;

  useEffect(() => {
    if (deviceId) {
      onError(`invalid deviceId: ${deviceId}`);
    }
  }, [deviceId, onError]);

  const [deviceCategoryId, setDeviceCategoryId] =
    useState<number>(defaultState);
  const [deviceTypeId, setDeviceTypeId] = useState<number>(defaultState);

  const handleCategoryChange = (event) => {
    setDeviceCategoryId(event.target.value);
  };

  const handleDeviceTypeIdChange = (event) => {
    setDeviceTypeId(event.target.value);
  };

  return (
    <div>
      <Panel className={classes.root}>
        <Typography variant="h6" className={classes.root}>
          Filter Options:
        </Typography>
        <DeviceCategorySelect
          className={classes.dropdown}
          value={deviceCategoryId}
          prependOption={[{ label: 'All', value: -1, key: -1 }]} // might end up being blank as in seach nothing
          onChange={(event) => {
            handleCategoryChange(event);
          }}
          onError={onError}
        />
        <DeviceTypeSelect
          className={classes.dropdown}
          onError={onError}
          onChange={(event) => {
            handleDeviceTypeIdChange(event);
          }}
          value={deviceTypeId}
          prependOption={[{ label: 'All', value: -1, key: -1 }]}
        />
      </Panel>
      <Panel className={classes.root}>
        <DeviceListingTable
          deviceCategoryId={deviceCategoryId}
          deviceTypeId={deviceTypeId}
          onError={onError}
          permission={Environment.getDmasUserPrivilege()}
        />
      </Panel>
    </div>
  );
};

export default withStyles(styles)(withSnackbars(DeviceListingPage));
