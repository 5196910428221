import { createContext, useMemo, useState } from 'react';
import * as React from 'react';
import BatchCancelDialog from 'domain/AppComponents/batch/dialog/BatchCancelDialog';
import BatchDeleteDialog, {
  BatchDeleteData,
} from 'domain/AppComponents/batch/dialog/BatchDeleteDialog';
import BatchTaskUpdateDialog from 'domain/AppComponents/batch/dialog/BatchTaskUpdateDialog';
import BatchUpdateDialog, {
  BatchUpdateData,
} from 'domain/AppComponents/batch/dialog/BatchUpdateDialog';
import { BatchTaskInfoDialog } from 'domain/AppComponents/dialogs/Dialogs';
import { BatchDialogContextType } from '../hooks/BatchTaskStateHooks';

interface BatchDialogContextProps {
  children: React.ReactNode;
}

export const BatchDialogContext = createContext<BatchDialogContextType>({
  setBatchToUpdate: undefined,
  setBatchToDelete: undefined,
  setBatchToCancel: undefined,
  setBatchTaskInfo: undefined,
  setBatchTaskToEdit: undefined,
});

const BatchTaskDialogProvider: React.FC<BatchDialogContextProps> = ({
  children,
}) => {
  const [batchToUpdate, setBatchToUpdate] = useState<
    BatchUpdateData | undefined
  >(undefined);
  const [batchToDelete, setBatchToDelete] = useState<
    BatchDeleteData | undefined
  >(undefined);
  const [batchToCancel, setBatchToCancel] = useState<number | undefined>(
    undefined
  );
  const [batchTaskinfo, setBatchTaskInfo] = useState(undefined);
  const [batchTaskToEdit, setBatchTaskToEdit] = useState<
    | {
        taskId: number;
        input: any;
      }
    | undefined
  >(undefined);

  const component = useMemo(
    () => (
      <BatchDialogContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          setBatchToUpdate,
          setBatchToDelete,
          setBatchToCancel,
          setBatchTaskInfo,
          setBatchTaskToEdit,
        }}
      >
        {children}
      </BatchDialogContext.Provider>
    ),
    [children]
  );

  return (
    <>
      {component}
      <BatchUpdateDialog
        open={!!batchToUpdate}
        onClose={() => setBatchToUpdate(undefined)}
        data={batchToUpdate}
      />
      <BatchDeleteDialog
        open={!!batchToDelete}
        onClose={() => setBatchToDelete(undefined)}
        data={batchToDelete}
      />
      <BatchCancelDialog
        open={!!batchToCancel}
        onClose={() => setBatchToCancel(undefined)}
        taskId={batchToCancel}
      />
      <BatchTaskInfoDialog
        open={!!batchTaskinfo}
        onClose={() => setBatchTaskInfo(undefined)}
        data={batchTaskinfo}
      />
      <BatchTaskUpdateDialog
        open={!!batchTaskToEdit}
        onClose={() => setBatchTaskToEdit(undefined)}
        taskId={batchTaskToEdit?.taskId}
        defaultInput={batchTaskToEdit?.input}
      />
    </>
  );
};

export default BatchTaskDialogProvider;
