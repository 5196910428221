import {
  Children,
  cloneElement,
  ReactNode,
  useEffect,
  useState,
  isValidElement,
  ReactElement,
} from 'react';
import { PaperProps } from '@mui/material';
import MaterialMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import MaterialMenuList, { type MenuListProps } from '@mui/material/MenuList';
import { ArrowForwardIos } from '@onc/icons';

import MenuItem from './MenuItem';
import SequentialSubMenu from './SequentialSubMenu';
import ListItemText from '../list/ListItemText';

type MenuProps = Omit<MuiMenuProps, 'open'> & {
  /** Id to pass down to the dom */
  id?: string;
  /** Name of classes to be applied to this menu */
  onExited?: () => void;
  /** Whether the menu is a list or not */
  menuList?: boolean;
  /** Whether tocollapse sequential menus on close */
  collapseOnClose?: boolean;
  /** React children */
  children?: ReactNode;
  /** Props to pass down to menuList */
  menuListProps?: MenuListProps;
  /** Updates the position of the menu when a SequentialSubMenu opens/closes */
  updatePosition?: boolean;
  /** For non-list menus this controls whether or not thje menu is open */
  open?: boolean;
  /** Callback for closing the menu */
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  /** Props to add to the paper component the menu renders on top of */
  PaperProps?: PaperProps;
};

/**
 * Material Menu that also allows custom SequentialSubMenus and
 * ExpansionSubMenus
 */
const Menu = ({
  onExited = undefined,
  menuListProps = {},
  children = null,
  menuList = false,
  collapseOnClose = false,
  updatePosition = false,
  open = true,
  ...rest
}: MenuProps) => {
  const [sequentialMenus, setSequentialMenus] = useState<
    Record<string, { active: boolean }>
  >({});
  const [updatingPosition, setUpdatingPosition] = useState<boolean>(false);

  useEffect(() => {
    // This is like componentDidMount
    Children.forEach(children, (child) => {
      if (!isValidElement(child)) return child;

      if (child.type === SequentialSubMenu) {
        setSequentialMenus((prevState) => ({
          ...prevState,
          [child.props.menuTitle]: { active: false },
        }));
      }

      return child;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updatingPosition) {
      setUpdatingPosition(false);
    }
  }, [updatingPosition]);

  const sequentialMenuActive = () => {
    let retVal = false;
    Object.keys(sequentialMenus).forEach((key) => {
      if (sequentialMenus[key].active) {
        retVal = true;
      }
    });

    return retVal;
  };

  const handleExited = () => {
    const newSequentialMenus = JSON.parse(JSON.stringify(sequentialMenus));

    if (collapseOnClose) {
      Object.keys(newSequentialMenus).forEach((key) => {
        newSequentialMenus[key].active = false;
      });
      setSequentialMenus(newSequentialMenus);
    }

    if (onExited) onExited();
  };

  const renderMenuList = () => {
    // Eslint is disabled here because the return is actually consistent.
    // sequentialMenuActive() only returns true when the first condition is also true
    /* eslint-disable consistent-return */
    const newChildren = Children.map(children, (child) => {
      if (!isValidElement(child)) return child;

      if (child.type === SequentialSubMenu) {
        if (
          sequentialMenus[child.props.menuTitle] &&
          sequentialMenus[child.props.menuTitle].active
        ) {
          return cloneElement(
            child as ReactElement<{ onBackClick?: () => void }>,
            {
              onBackClick: () => {
                setSequentialMenus((prevSequentialMenus) => ({
                  ...prevSequentialMenus,
                  [child.props.menuTitle]: { active: false },
                }));

                setUpdatingPosition(true);
              },
            }
          );
        }

        if (!sequentialMenuActive()) {
          return (
            <MenuItem
              onClick={() => {
                setSequentialMenus((prevSequentialMenus) => ({
                  ...prevSequentialMenus,
                  [child.props.menuTitle]: { active: true },
                }));

                setUpdatingPosition(true);
              }}
            >
              <ListItemText primary={child.props.menuTitle} />
              <ArrowForwardIos fontSize="small" />
            </MenuItem>
          );
        }
      }

      if (!sequentialMenuActive()) {
        return child;
      }
    });

    if (menuList) {
      return (
        <MaterialMenuList {...menuListProps}>{newChildren}</MaterialMenuList>
      );
    }

    // Only render if the position is not updating
    if (!updatePosition || !updatingPosition) {
      return (
        <MaterialMenu
          TransitionProps={{ onExited: handleExited }}
          open={open}
          {...rest}
        >
          {newChildren}
        </MaterialMenu>
      );
    }
    return null;
  };

  return renderMenuList();
};

export default Menu;
