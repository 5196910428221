import { Component } from 'react';
import PropTypes from 'prop-types';
import LayoutPropType from 'library/CompositeComponents/grid-layout/LayoutPropType';
import ResponsiveGridLayout from 'library/CompositeComponents/grid-layout/ResponsiveGridLayout';
import { post } from 'util/WebRequest';

const EMPTY_LAYOUT = JSON.stringify({
  lg: [],
  md: [],
  sm: [{ w: 1, h: 1, x: 0, y: 0, i: 'null', moved: false, static: false }],
  xs: [],
  xxs: [],
});
const BUILD_LAYOUT = JSON.stringify({
  lg: [{ w: 1, h: 1, x: 0, y: 0, i: 'null', moved: false, static: false }],
  md: [],
  sm: [],
  xs: [],
  xxs: [],
});

class DBSaveResponsiveLayout extends Component {
  static propTypes = {
    layoutKey: PropTypes.number.isRequired,
    layouts: PropTypes.shape({
      lg: LayoutPropType,
      md: LayoutPropType,
      sm: LayoutPropType,
      xs: LayoutPropType,
      xxs: LayoutPropType,
    }).isRequired,
    layoutName: PropTypes.string.isRequired,
    isPublic: PropTypes.bool.isRequired,
    onLayoutChange: PropTypes.func.isRequired,
    isStatic: PropTypes.bool,
  };

  static defaultProps = {
    isStatic: true,
  };

  constructor(props) {
    super(props);
    const { layoutName, layouts } = this.props;
    this.state = {
      layoutArray: layouts,
      currentLayouts: layouts,
      dashboardName: layoutName,
    };
  }

  /**
   * Saves newLayouts to local storage and updates state.
   *
   * @param newLayouts - Layouts object to save to local storage and set as new
   *   layouts state
   */
  updateLayoutAndSaveToDB = (newLayouts) => {
    const { layoutKey, layoutName, isPublic, onLayoutChange, isStatic } =
      this.props;
    if (!isStatic) {
      const clonedLayout = { ...newLayouts };
      const layoutToSave = JSON.stringify(clonedLayout);
      this.setState({ layoutArray: newLayouts });
      if (
        layoutToSave.localeCompare(BUILD_LAYOUT) !== 0 &&
        layoutToSave.localeCompare(EMPTY_LAYOUT) !== 0
      ) {
        return post('LayoutService', {
          operation: 2,
          layoutId: layoutKey,
          widgetLayout: layoutToSave,
          layoutName,
          isPublic,
        })
          .then((response) => {
            const { widgetLayout, layoutName: dashboardName } =
              response.data.payload.layout;
            this.setState({
              currentLayouts: widgetLayout,
              dashboardName,
            });
            onLayoutChange(newLayouts);
            return Promise.resolve();
          })
          .catch((error) => Promise.reject(error));
      }
      return Promise.resolve();
    }
    return undefined;
  };

  render() {
    const { currentLayouts } = this.state;
    const { onLayoutChange, ...rest } = this.props;
    return (
      <ResponsiveGridLayout
        layouts={currentLayouts}
        onLayoutChange={(layout, allLayouts) =>
          this.updateLayoutAndSaveToDB(allLayouts)
        }
        {...rest}
      />
    );
  }
}

export default DBSaveResponsiveLayout;
