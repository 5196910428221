import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Divider } from 'base-components';
import DateUtils from 'util/DateUtils';
import ContinuousVideoFiles from './ContinuousVideoFiles';

class UTCTooltip extends PureComponent {
  static propTypes = {
    playerId: PropTypes.string.isRequired,
    files: PropTypes.instanceOf(ContinuousVideoFiles).isRequired,
    querySelector: PropTypes.func,
  };

  static defaultProps = {
    querySelector: () => {},
  };

  constructor(props) {
    super(props);

    this.observer = null;

    this.state = {
      time: null,
    };
  }

  componentDidMount() {
    const { querySelector } = this.props;

    const tooltip = querySelector('.jw-slider-time .jw-tooltip-time');
    if (!tooltip) return;

    this.observer = new MutationObserver(this.handleMutation);
    this.observer.observe(tooltip, { attributes: true });
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleMutation = (mutations) => {
    for (const mutation of mutations) {
      if (mutation.target.classList.contains('jw-open')) {
        const span = mutation.target.querySelector('span.jw-text.jw-reset');
        if (span) {
          this.handleTooltipChange(span.textContent);
        }
      }
    }
  };

  handleTooltipChange = (time) => {
    const { files } = this.props;
    this.setState({ time: files.getDateAt(this.hmsToSeconds(time)) });
  };

  /** Converts HH:mm:ss or mm:ss to seconds */
  hmsToSeconds = (time) => {
    const values = time.split(':');
    let sec = 0;
    let digit = 1;

    while (values.length > 0) {
      sec += digit * parseInt(values.pop(), 10);
      digit *= 60;
    }
    return sec;
  };

  render() {
    const { playerId, querySelector } = this.props;
    const { time } = this.state;

    const container = querySelector(
      '.jw-slider-time .jw-slider-container .jw-tooltip-time .jw-time-tip'
    );
    if (!container) return <></>;

    const ToolTip = (
      <>
        <Divider light />
        <div
          id={`${playerId}-utc-tooltip`}
          className="jw-icon jw-icon-inline jw-text utc-timestamp"
          style={{ whiteSpace: 'nowrap', width: 'auto' }}
          aria-label="tooltip"
        >
          {DateUtils.formatDateAsString(time)}
        </div>
        <Divider light />
        <div
          id={`${playerId}-utc-tooltip-prompt`}
          className="jw-icon jw-icon-inline jw-text utc-timestamp"
          style={{ whiteSpace: 'nowrap', width: 'auto' }}
          aria-label="tooltip"
        >
          Drag up to zoom
        </div>
      </>
    );
    return createPortal(ToolTip, container);
  }
}

export default UTCTooltip;
