import { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import PropTypes, { number } from 'prop-types';
import { TreeItem, TreeView, withCircularProgress } from 'base-components';

const DEVICE_CATEGORY_TYPE = 100;
const SENSOR_TYPE = 102;
const SITE_DEVICE_TYPE = 2;
const LOCATION_TYPE = 11;
const MIN_SEARCH_CHARS = 3;

const useStyles = makeStyles(() =>
  createStyles({
    disableTransition: {
      transition: `none`,
    },
  })
);

function DataSourceTreeData({
  selectNode,
  selectedNodes = [],
  filterText = '',
  data = undefined,
  deviceCategories = [],
  sensorTypes = [],
  siteDevices = undefined,
  editData = undefined,
  treeType,
}) {
  DataSourceTreeData.propTypes = {
    selectedNodes: PropTypes.arrayOf(PropTypes.string),
    selectNode: PropTypes.func.isRequired,
    deviceCategories: PropTypes.arrayOf(number),
    sensorTypes: PropTypes.arrayOf(number),
    filterText: PropTypes.string,
    treeType: PropTypes.oneOf([
      'instrumentsByLocation',
      'propertiesByLocation',
      'locationTree',
    ]).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    siteDevices: PropTypes.arrayOf(PropTypes.number),
    editData: PropTypes.shape({
      pathName: PropTypes.arrayOf(PropTypes.string),
      sensortypeid: PropTypes.number,
      els: PropTypes.arrayOf(PropTypes.shape()),
    }),
  };

  const classes = useStyles();
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [filteredTree, setFilteredTree] = useState(undefined);
  const [textFilteredTree, setTextFilteredTree] = useState(undefined);

  const isNodeLeaf = (item) => {
    if (item.type) {
      if (treeType === 'instrumentsByLocation') {
        return item.type === DEVICE_CATEGORY_TYPE;
      }
      if (treeType === 'propertiesByLocation') {
        return item.type === SENSOR_TYPE;
      }
      if (treeType === 'locationTree') {
        return item.type === DEVICE_CATEGORY_TYPE;
      }
    }

    return false;
  };

  const getNodeData = (id) => {
    let node;
    const findNode = (nodeId, treeItem) =>
      treeItem.forEach((item) => {
        if (item.nodeId === nodeId) {
          node = item;
        }
        if (Array.isArray(item.els)) {
          if (item.els.length) {
            findNode(nodeId, item.els);
          }
        }
        return null;
      });
    findNode(id, data);
    return node;
  };

  const getItemName = (item) => {
    if (filterText.length >= MIN_SEARCH_CHARS) {
      const matchIndex = item.name
        .toLowerCase()
        .search(filterText.toLowerCase());
      if (matchIndex > -1) {
        return (
          <>
            {item.name.slice(0, matchIndex)}
            <mark>
              {item.name.slice(matchIndex, matchIndex + filterText.length)}
            </mark>
            {item.name.slice(matchIndex + filterText.length, item.name.length)}
          </>
        );
      }
    }
    return item.name;
  };

  const renderItems = (treeData) => {
    if (treeData) {
      return treeData.map((item) => {
        const isLeaf = isNodeLeaf(item);
        if (
          treeType === 'locationTree' &&
          (item.type !== LOCATION_TYPE || !item.els)
        ) {
          return null;
        }
        return (
          <TreeItem
            TransitionProps={{
              classes: { container: classes.disableTransition },
            }}
            label={
              isNodeLeaf(item) ? <b>{getItemName(item)}</b> : getItemName(item)
            }
            nodeId={item.nodeId}
            key={item.nodeId}
          >
            {isLeaf || !item.els ? null : renderItems(item.els)}
          </TreeItem>
        );
      });
    }
    return null;
  };

  // ns = neptune searchable
  const isSearchable = (item) => item.ns && item.ns === 'Y';

  const toggleNodeExpand = (event, nodeIds) => setExpandedNodes(nodeIds);

  const toggleNodeSelect = (event, value) => {
    const node = getNodeData(value);
    if (treeType === 'locationTree' && node.type === LOCATION_TYPE) {
      selectNode(node);
    } else if (isNodeLeaf(node)) {
      selectNode(node);
    }
  };

  // Auto-expands a node when in "Edit" mode
  useEffect(
    () => {
      const nodesToExpand = [];
      const expandNodes = (result, node) => {
        const item = { ...node };
        if (item.pathName.join() === editData.pathName.join()) {
          if (editData.sensortypeid) nodesToExpand.push(item.nodeId);
          item.els = editData.els;
          result.push(item);
          selectNode(item);
        }
        if (Array.isArray(node.els)) {
          const els = node.els.reduce(expandNodes, []);
          if (els.length) {
            item.els = els;
            nodesToExpand.push(item.nodeId);
            result.push(item);
          }
        }
        return result;
      };
      if (filteredTree && editData) {
        filteredTree.reduce(expandNodes, []);
        setExpandedNodes(nodesToExpand);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredTree, editData]
  );

  // Apply Filters to tree
  useEffect(() => {
    const matchesSensorType = (sensorList) => {
      if (sensorTypes.length || deviceCategories.length) {
        return sensorList.some(
          (sensor) =>
            isSearchable(sensor) && sensorTypes.includes(sensor.sensortypeid)
        );
      }
      return true;
    };
    const matchesDeviceCategory = (item) => {
      if (sensorTypes.length || deviceCategories.length) {
        return item.deviceCategoryCode && deviceCategories.includes(item.id);
      }
      return true;
    };
    const matchesSiteDevices = (siteDeviceList) => {
      if (siteDevices) {
        return siteDeviceList.some(
          (siteDevice) =>
            isSearchable(siteDevice) &&
            siteDevices.includes(siteDevice.sitedeviceid)
        );
      }
      return true;
    };
    const filterDuplicateSensors = (sensorList) => {
      const sensorMap = new Map();
      let incomingSensorDate = 0;
      sensorList.forEach((sensorNode) => {
        if (sensorMap.has(sensorNode.sensorCodeName)) {
          // save existing node as latest
          const existingNode = sensorMap.get(sensorNode.sensorCodeName);
          let latestNode;
          const existingSensorDate = new Date(existingNode.datefrom).getTime();
          incomingSensorDate = new Date(sensorNode.datefrom).getTime();
          if (incomingSensorDate > existingSensorDate) {
            latestNode = sensorNode;
            latestNode.siteDeviceList = existingNode.siteDeviceList;
            latestNode.siteDeviceList.push(
              {
                deviceId: existingNode.deviceId,
                siteDeviceId: existingNode.sitedeviceid,
              },
              {
                deviceId: latestNode.deviceId,
                siteDeviceId: latestNode.sitedeviceid,
              }
            );
          } else {
            existingNode.siteDeviceList.push({
              deviceId: sensorNode.deviceId,
              siteDeviceId: sensorNode.sitedeviceid,
            });
            latestNode = existingNode;
          }
          sensorMap.set(sensorNode.sensorCodeName, latestNode);
        } else {
          const newNode = sensorNode;
          newNode.siteDeviceList = [
            {
              deviceId: sensorNode.deviceId,
              siteDeviceId: sensorNode.sitedeviceid,
            },
          ];
          sensorMap.set(sensorNode.sensorCodeName, newNode);
        }
      });
      return Array.from(sensorMap.values());
    };
    const getNodes = (result, node) => {
      const item = { ...node };
      if (node.type === DEVICE_CATEGORY_TYPE) {
        const siteDeviceList = node.els;
        let sensorList = []
          .concat(
            ...siteDeviceList.map((siteDevice) => {
              const newItems = siteDevice.els.map((elsItem) => {
                // add dateFrom
                const newElsItem = elsItem;
                newElsItem.datefrom = siteDevice.datefrom;
                return newElsItem;
              });
              return newItems;
            })
          )
          .filter((sensor) => sensor.archived === 'Y');
        sensorList = filterDuplicateSensors(sensorList);
        if (
          matchesSiteDevices(siteDeviceList) &&
          (matchesSensorType(sensorList) || matchesDeviceCategory(item))
        ) {
          item.els = sensorList;
          result.push(item);
        }
      }
      if (node.type === LOCATION_TYPE && Array.isArray(node.els)) {
        const els = node.els.reduce(getNodes, []);
        if (els.length) {
          item.els = els;
          result.push(item);
        }
      }

      return result;
    };
    setFilteredTree(data.reduce(getNodes, []));
  }, [data, deviceCategories, sensorTypes, siteDevices]);

  // Text Search on Tree
  useEffect(() => {
    const expanded = [];
    const matchesSearchText = (item) => {
      if (filterText.length >= MIN_SEARCH_CHARS) {
        if (treeType === 'instrumentsByLocation') {
          return [SENSOR_TYPE, SITE_DEVICE_TYPE].includes(item.type)
            ? false
            : item.name.toLowerCase().includes(filterText.toLowerCase());
        }
        if (treeType === 'propertiesByLocation') {
          return [SITE_DEVICE_TYPE].includes(item.type)
            ? false
            : item.name.toLowerCase().includes(filterText.toLowerCase());
        }
        if (treeType === 'locationTree') {
          return [SITE_DEVICE_TYPE].includes(item.type)
            ? false
            : item.name.toLowerCase().includes(filterText.toLowerCase());
        }
      }
      return true;
    };
    const filterOnText = (result, item) => {
      let matched = false;
      let els = [];
      if (matchesSearchText(item)) {
        matched = true;
        result.push(item);
      }
      if (Array.isArray(item.els)) {
        els = item.els.reduce(filterOnText, []);
        if (els.length) {
          expanded.push(item.nodeId);
          if (!matched) {
            result.push({ ...item, els });
          }
        }
      }
      return result;
    };

    const openNodes = () => {
      if (filterText.length >= MIN_SEARCH_CHARS) {
        setTextFilteredTree(filteredTree.reduce(filterOnText, []));
        setExpandedNodes(expanded);
      } else {
        setTextFilteredTree(filteredTree);
      }
    };

    const debouncer = setTimeout(() => {
      openNodes();
    }, 250);
    return () => {
      clearTimeout(debouncer);
    };
  }, [filterText, filteredTree, treeType]);

  return (
    <TreeView
      selected={selectedNodes}
      expanded={expandedNodes}
      onNodeToggle={toggleNodeExpand}
      onNodeSelect={toggleNodeSelect}
      style={{ overflowY: 'auto' }}
    >
      {renderItems(textFilteredTree)}
    </TreeView>
  );
}

export default withCircularProgress(DataSourceTreeData);
