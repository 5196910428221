import BatchCancelAction from 'domain/AppComponents/batch/actions/BatchCancelAction';
import BatchDeleteAction from 'domain/AppComponents/batch/actions/BatchDeleteAction';
import BatchEditAction from 'domain/AppComponents/batch/actions/BatchEditAction';
import BatchRunAction from 'domain/AppComponents/batch/actions/BatchRunAction';
import TaskStatus from 'domain/AppComponents/batch/util/TaskStatus';
import { useBatchDialogsContext } from '../hooks/BatchTaskStateHooks';

const BatchRowActions = ({
  taskId,
  status,
  taskType,
  isRunnable,
  isBatchTask,
  input = undefined,
  batchName = undefined,
  batchReason = undefined,
}: {
  taskId: number;
  status: number;
  taskType: string;
  isRunnable: boolean;
  isBatchTask: boolean;
  input?: string;
  batchName?: string;
  batchReason?: string;
}) => {
  const {
    setBatchToUpdate,
    setBatchTaskToEdit,
    setBatchToDelete,
    setBatchToCancel,
  } = useBatchDialogsContext();
  if (taskType === 'Batch') {
    return (
      <>
        <BatchEditAction
          onEdit={() =>
            isBatchTask
              ? setBatchTaskToEdit!({
                  taskId,
                  input,
                })
              : setBatchToUpdate?.({
                  batchName: batchName ?? '',
                  batchReason: batchReason ?? '',
                  batchTaskType: taskType,
                  taskId,
                })
          }
        />
        <BatchCancelAction
          onCancel={() => setBatchToCancel?.(taskId)}
          disabled={status !== TaskStatus.OPEN.value}
        />
      </>
    );
  }

  if (taskType === 'DOI Dataset Group' || taskType === 'Trigger Group')
    return <></>;

  return (
    <>
      {status === TaskStatus.OPEN.value ? (
        <BatchRunAction isRunnable={isRunnable} taskId={taskId} />
      ) : null}
      {status === TaskStatus.OPEN.value ? (
        <BatchEditAction
          onEdit={() =>
            isBatchTask
              ? setBatchTaskToEdit?.({
                  taskId,
                  input,
                })
              : setBatchToUpdate?.({
                  batchName: batchName ?? '',
                  batchReason: batchReason ?? '',
                  batchTaskType: taskType,
                  taskId,
                })
          }
        />
      ) : null}
      {status === TaskStatus.CANCELLING.value ? (
        <BatchCancelAction
          onCancel={() => setBatchToCancel?.(taskId)}
          disabled
        />
      ) : null}
      {[TaskStatus.RUNNING.value, TaskStatus.QUEUED.value].includes(status) ? (
        <BatchCancelAction
          onCancel={() => setBatchToCancel?.(taskId)}
          disabled={false}
        />
      ) : null}
      {![
        TaskStatus.RUNNING.value,
        TaskStatus.QUEUED.value,
        TaskStatus.CANCELLING.value,
        TaskStatus.ABORTED.value,
      ].includes(status) ? (
        <BatchDeleteAction
          onClick={() => setBatchToDelete?.({ status, taskId })}
        />
      ) : null}
    </>
  );
};

export default BatchRowActions;
