import { Children, cloneElement, PureComponent } from 'react';
import { Formik, Field, Form as FormikForm } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';

import FormValidation from 'library/CompositeComponents/FormValidation';
import { ClearButton, SaveButton, TextButton } from './button/Buttons';

/**
 * @deprecated When using new forms use the one located in
 *   BaseComponents/form/Form.tsx instead. See the style guide's Form in
 *   storybook (npm run storybook) for usage
 */
class Form extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    onClear: PropTypes.func,
    useDefaultButtons: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
    buttonBarStyle: PropTypes.objectOf(PropTypes.string),
    clearAfterSave: PropTypes.bool,
    updateValues: PropTypes.arrayOf(PropTypes.shape({})),
    onUpdateFinish: PropTypes.func,
    afterSaveClear: PropTypes.func,
  };

  static defaultProps = {
    onClear: undefined,
    onReset: undefined,
    useDefaultButtons: false,
    buttonBarStyle: undefined,
    clearAfterSave: false,
    updateValues: null,
    onUpdateFinish: null,
    afterSaveClear: undefined,
  };

  getFormChildren = (children) => {
    if (Array.isArray(children)) {
      return children;
    }
    return [children, undefined];
  };

  getButtonStyles = (buttons) => {
    if (buttons) {
      return [
        buttons.props.children,
        buttons.props.style,
        buttons.props.className,
      ];
    }

    return [undefined, undefined, undefined];
  };

  callMultipleChanges = (e, child, formikHandleChange) => {
    if (child.props && child.props.onChange) {
      child.props.onChange(e);
    }
    formikHandleChange(e);
  };

  callMultipleBlurs = (e, child, formikHandleBlur) => {
    if (child.props && child.props.onBlur) {
      child.props.onBlur(e);
    }
    formikHandleBlur(e);
  };

  validator = new FormValidation();

  updateFunction = null;

  state = { buttonClicked: false };

  componentDidUpdate() {
    const { updateValues, onUpdateFinish } = this.props;
    // If update values is passed in, we should loop through the array of update values
    // and use formiks update function to update all the values
    if (updateValues) {
      updateValues.forEach((value) => {
        this.updateFunction(value.title, value.value);
      });
      onUpdateFinish();
    }
  }

  renderFields = (fieldChildren, errors, touched, handleBlur, setFieldValue) =>
    Children.map(fieldChildren, (child) => {
      const renderThis = this.deepRender(child);
      if (child.props.onClick) {
        return child;
      }
      if (child.props.title) {
        return (
          <div>
            {Children.map(renderThis, (cmon) =>
              this.checkIfField(
                cmon,
                errors,
                touched,
                handleBlur,
                setFieldValue
              )
            )}
          </div>
        );
      }
      return (
        <child.type {...child.props}>
          {Children.map(renderThis, (cmon) =>
            this.checkIfField(cmon, errors, touched, handleBlur, setFieldValue)
          )}
        </child.type>
      );
    });

  // It's possible to put a button in the form, so lets make sure its a field to render
  checkIfField = (child, errors, touched, handleBlur, setFieldValue) => {
    if (child && child.props && child.props.title) {
      return (
        <Field name={child.props.title}>
          {({ field }) =>
            this.renderIndividualField(
              field,
              errors,
              touched,
              handleBlur,
              child,
              setFieldValue
            )
          }
        </Field>
      );
    }
    return child;
  };

  deepRender = (children) => {
    if (
      (children &&
        children.props &&
        typeof children.props.children === 'string') ||
      (children && children.props && children.props.onClick)
    ) {
      return children;
    }
    return Children.map(children, (child) => {
      if (child && child.props && child.props.children) {
        if (
          (child.props && typeof child.props.children === 'string') ||
          (child.props && child.props.onClick)
        ) {
          return child;
        }
        return this.deepRender(child.props.children);
      }
      return child;
    });
  };

  renderIndividualField = (
    field,
    errors,
    touched,
    handleBlur,
    child,
    setFieldValue
  ) => {
    const { buttonClicked } = this.state;
    if (child.type.name === 'Toggle') {
      return cloneElement(child, {
        ...field,
        checked: field.value,
        helperText:
          // If there is an error and the input was touched
          // set the helper text to the error
          errors[child.props.title] &&
          (touched[child.props.title] || buttonClicked)
            ? errors[child.props.title]
            : child.props.helperText,
        error:
          errors[child.props.title] &&
          (touched[child.props.title] || buttonClicked),
        onBlur: (e) => this.callMultipleBlurs(e, child, handleBlur),
        // This onChange, as it's a toggle, will be called with the event so doesn't
        // need special handling. Leaving it as is to reduce code changes in
        // old, possibly to be replace code.
        onChange: (e) => this.callMultipleChanges(e, child, field.onChange),
      });
    }
    return cloneElement(child, {
      ...field,
      value: field.value || (field.value === 0 ? '0' : ''),
      setFieldValue,
      helperText:
        // If there is an error and the input was touched
        // set the helper text to the error
        errors[child.props.title] &&
        (touched[child.props.title] || buttonClicked)
          ? errors[child.props.title]
          : child.props.helperText,
      error:
        errors[child.props.title] &&
        (touched[child.props.title] || buttonClicked),
      onBlur: (e) => this.callMultipleBlurs(e, child, handleBlur),
      onChange: (e) =>
        this.callMultipleChanges(
          e,
          child,
          this.handleOnChange(setFieldValue, field)
        ),
    });
  };

  handleButtonClicked = (callBack) => {
    this.setState({ buttonClicked: true });
    callBack();
  };

  renderDefaultButtons = (resetForm, handleSubmit, isSubmitting) => {
    const { useDefaultButtons, buttonBarStyle } = this.props;

    if (useDefaultButtons) {
      return (
        <div style={buttonBarStyle}>
          <ClearButton
            onClick={() =>
              this.handleButtonClicked(() => this.clearForm(resetForm))
            }
            disabled={isSubmitting}
          />
          <TextButton
            translationKey="common.buttons.reset"
            onClick={() =>
              this.handleButtonClicked(() => this.resetForm(resetForm))
            }
            disabled={isSubmitting}
          />
          <SaveButton disabled={isSubmitting} onClick={handleSubmit} />
        </div>
      );
    }

    return undefined;
  };

  renderButtons = (
    buttonChildren,
    values,
    isSubmitting,
    resetForm,
    submitForm,
    buttons
  ) => {
    // Failsafe to allow buttons that don't contain an immediate child ancestor.
    const realButtonChildren = buttonChildren || buttons;

    return Children.map(realButtonChildren, (child) => {
      let onClick = null;
      if (
        child.props.children?.includes('Clear') ||
        child.type.displayName === 'ClearButton'
      ) {
        onClick = () => this.clearForm(resetForm);
      }
      if (
        child.props.children?.includes('Reset') ||
        child.type.displayName === 'ResetButton'
      ) {
        onClick = () => this.resetForm(resetForm);
      }
      if (child.props.children?.includes('Save')) {
        onClick = submitForm;
      }
      return cloneElement(child, {
        // If there is a click handler on the button, use that one. Else use formiks
        onClick: (event) => this.getOnClick(child, values, onClick, event),
        disabled: child.props.disabled ? true : isSubmitting,
      });
    });
  };

  getOnClick = (child, values, onClick) => {
    if (child.props.onClick) {
      this.handleButtonClicked(() => child.props.onClick(values));
    }

    if (onClick) onClick();

    return undefined;
  };

  clearForm = (callBack) => {
    callBack(this.validator.clearValues);
    const { onClear } = this.props;
    if (onClear) onClear();
  };

  resetForm = (callBack) => {
    callBack(this.validator.initialValues);
    const { onReset } = this.props;
    if (onReset) onReset();
  };

  handleSubmit = (values, setSubmitting, resetForm) => {
    const { onSubmit, clearAfterSave, afterSaveClear } = this.props;

    // Expecting either no response, or a promise that evaluates to true/false
    const promise = onSubmit(values);
    // Clear the form if either it was requested, or if it is set to always clear
    if (clearAfterSave) {
      this.clearForm(resetForm);
      if (afterSaveClear) {
        afterSaveClear();
      }
    } else if (promise) {
      promise.then((clearRequested) => {
        if (clearRequested) {
          this.clearForm(resetForm);
          if (afterSaveClear) {
            afterSaveClear();
          }
        }
      });
    }
    setSubmitting(false);
  };

  handleOnChange = (setFieldValue, field) => (e) => {
    // onChange is usually called with the event, but in the case of a date picker
    // it is called with a moment object. We need to check if it is a moment object.
    if (moment.isMoment(e)) {
      setFieldValue(field.name, e);
    }
    field.onChange(e);
  };

  render() {
    const { children } = this.props;

    const [fields, buttons] = this.getFormChildren(children);
    const [buttonChildren, buttonStyle, buttonClass] =
      this.getButtonStyles(buttons);

    const fieldChildren = fields.props.children;
    this.validator.buildValidationSchema(fieldChildren);

    const fieldWrapper = children[0] || children;

    return (
      <div>
        <Formik
          initialValues={this.validator.initialValues}
          enableReinitialize
          validationSchema={this.validator.schema}
          onSubmit={(values, { setSubmitting, resetForm }) =>
            this.handleSubmit(values, setSubmitting, resetForm)
          }
        >
          {({
            isSubmitting,
            errors,
            touched,
            handleBlur,
            resetForm,
            handleSubmit,
            values,
            setFieldValue,
            submitForm,
          }) => {
            this.updateFunction = setFieldValue;
            return (
              <FormikForm>
                <fieldWrapper.type {...fieldWrapper.props}>
                  {this.renderFields(
                    fieldChildren,
                    errors,
                    touched,
                    handleBlur,
                    setFieldValue
                  )}
                </fieldWrapper.type>
                <div style={buttonStyle} className={buttonClass}>
                  {this.renderDefaultButtons(
                    resetForm,
                    handleSubmit,
                    isSubmitting
                  )}
                  {this.renderButtons(
                    buttonChildren,
                    values,
                    isSubmitting,
                    resetForm,
                    submitForm,
                    buttons
                  )}
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    );
  }
}
export default Form;
