import React, { useState } from 'react';
import { ExpandMore, Tune } from '@onc/icons';
import {
  Box,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'base-components';
import { useDashboardStateContext } from './context/DashboardStateProvider';
import { DashboardConfigComponent } from './DashboardTypes';

type DashboardStateAccordionProps = {
  title: string;
  ConfigComponent: DashboardConfigComponent<any>;
};

const DashboardStateAccordion: React.FC<DashboardStateAccordionProps> = ({
  title,
  ConfigComponent,
}) => {
  const [expanded, setExpanded] = useState(true);

  const { dashboardState, setDashboardState } = useDashboardStateContext();

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((currentValue) => !currentValue)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore data-test="expand" name="expand" />}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/* Inner Box for Icon and Title */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tune sx={{ mr: 1 }} /> {/* Add margin to the right of the icon */}
            {`${title} Configuration`}
          </Box>
          {!expanded && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                flexDirection: 'row-reverse',
              }}
            >
              {Object.entries(dashboardState)
                .reverse() // Reverse the entries to maintain the order
                .map(([key, value]) => (
                  <Chip
                    key={key}
                    label={`${value}`}
                    color="primary"
                    sx={{ m: 0.25 }}
                  />
                ))}
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <ConfigComponent config={dashboardState} onChange={setDashboardState} />
      </AccordionDetails>
    </Accordion>
  );
};

export default DashboardStateAccordion;
