import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Divider, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DenseThemeProvider } from '@onc/theme';
import { Tab } from 'base-components';

import CableListingTab from 'domain/AppComponents/cablelisting/CableListingTab';
import { transformCableListingToRows } from 'domain/AppComponents/cablelisting/CableListingTableLogic';
import { transformCableTypeRows } from 'domain/AppComponents/cabletype/CableTypeTableLogic';
import CableTypeService from 'domain/services/CableTypeService';
import ConnectorTypeService from 'domain/services/ConnectorTypeService';
import ExtensionService from 'domain/services/ExtensionService';
import useWebService from 'util/hooks/useWebService';
import CableTypeTab from '../../AppComponents/cabletype/CableTypeTab';
import ConnectorTypeTab from '../connectortype/ConnectorTypeTab';
import { transformConnectorTypeRows } from '../connectortype/ConnectorTypeTableLogic';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    devicePackageInfoPage: {
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(),
        paddingLeft: theme.spacing(),
      },
    },
  })
);

const CablesManagementPage = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState<string>('cableListing');
  const handleTabChange = (event, value: string) => setTabValue(value);

  const [cableListings, , fetchCableListings] = useWebService({
    method: ExtensionService.getMany,
    parser: transformCableListingToRows,
  });

  const [connectorTypes, , fetchConnectorTypes] = useWebService({
    method: ConnectorTypeService.getAll,
    parser: transformConnectorTypeRows,
  });

  const [cableTypes, , fetchCableTypes] = useWebService({
    method: CableTypeService.getAll,
    parser: transformCableTypeRows,
  });

  useEffect(() => {
    fetchCableListings();
    fetchConnectorTypes();
    fetchCableTypes();
  }, [fetchCableListings, fetchCableTypes, fetchConnectorTypes]);

  const loadConnectorTypes = () => {
    fetchConnectorTypes();
  };

  const loadCableTypes = () => {
    fetchCableTypes();
  };

  return (
    <DenseThemeProvider>
      <div className={classes.devicePackageInfoPage}>
        <TabContext value={tabValue}>
          <TabList
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            onChange={handleTabChange}
          >
            <Tab label="Cable Listing" value="cableListing" />
            <Tab label="Cable Type" value="cableType" />
            <Tab label="Connector Type" value="connectorType" />
          </TabList>
          <Divider />
          <TabPanel value="cableListing">
            <CableListingTab
              cableListings={cableListings}
              connectorTypes={connectorTypes}
              cableTypes={cableTypes}
              fetchCableListings={fetchCableListings}
            />
          </TabPanel>
          <TabPanel value="connectorType">
            <ConnectorTypeTab
              connectorTypes={connectorTypes}
              handleSuccess={loadConnectorTypes}
            />
          </TabPanel>
          <TabPanel value="cableType">
            <CableTypeTab
              cableTypes={cableTypes}
              handleSuccess={loadCableTypes}
            />
          </TabPanel>
        </TabContext>
      </div>
    </DenseThemeProvider>
  );
};

export default CablesManagementPage;
