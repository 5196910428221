import { Component } from 'react';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ExpandMore } from '@onc/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
} from 'base-components';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

const styles = (theme) => ({
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  clipStatisticsDetails: {
    display: 'block',
  },
  tableTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
});

class DFManagementStatistics extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        campaignId: PropTypes.string,
      }),
    }),
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      heading: PropTypes.string,
      secondaryHeading: PropTypes.string,
      tableTitle: PropTypes.string,
      clipStatisticsDetails: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    match: undefined,
  };

  roundMeanAndStdev = (stats) => {
    if (!stats) return undefined;
    return {
      ...stats,
      mean: Number.parseFloat(stats.mean).toFixed(2),
      standardDeviation: Number.parseFloat(stats.standardDeviation).toFixed(2),
    };
  };

  state = {
    campaignName: undefined,
    numClips: 0,
    numViews: 0,
    numAnnotations: 0,
    numUsers: 0,
    clipData: undefined,
    userStatistics: undefined,
    viewStatistics: undefined,
    annotationStatistics: undefined,
    usersPerCountry: [],
    top5Users: [],
    overallOpen: true,
    usersByCountryOpen: false,
    top5UsersOpen: false,
    clipOpen: false,
  };

  componentDidMount() {
    this.getBaseStatistics();
    this.getComplexStatistics();
  }

  getBaseStatistics = async () => {
    const { match, onError } = this.props;
    try {
      const response = await get('DFStatisticsService', {
        campaignId: match.params.campaignId,
      });
      const payload = parseDmasAPIResponse(response);
      if (payload) {
        const top5Users = payload.top5Users.map((user) => ({
          ...user,
          name: `${user.firstName} ${user.lastName}`,
        }));
        this.setState({
          numAnnotations: payload.numAnnotations,
          numUsers: payload.numUsers,
          numViews: payload.numViews,
          numClips: payload.numClips,
          usersPerCountry: payload.usersPerCountry,
          top5Users,
          campaignName: payload.campaignName,
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  getComplexStatistics = async () => {
    const { match, onError } = this.props;
    try {
      const response = await get('DFStatisticsService', {
        campaignId: match.params.campaignId,
        complex: true,
      });
      const payload = parseDmasAPIResponse(response);
      if (payload) {
        const userStatistics = this.roundMeanAndStdev(payload.userStatistics);
        const viewStatistics = this.roundMeanAndStdev(payload.viewStatistics);
        const annotationStatistics = this.roundMeanAndStdev(
          payload.annotationStatistics
        );
        this.setState({
          clipData: payload.clipData,
          userStatistics,
          viewStatistics,
          annotationStatistics,
        });
      } else {
        this.setState({
          clipData: undefined,
          userStatistics: undefined,
          viewStatistics: undefined,
          annotationStatistics: undefined,
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  handleChange = (panel) => (event, isExpanded) => {
    const { clipData } = this.state;
    if (panel === 'clipOpen' && !clipData) return;
    this.setState({ [panel]: !!isExpanded });
  };

  renderClipData = () => {
    const { clipData, userStatistics, viewStatistics, annotationStatistics } =
      this.state;
    const { classes } = this.props;
    const rows = clipData.map((clip) => ({
      deviceId: clip.deviceId,
      segmentStart: clip.segmentStart,
      segmentDuration: clip.segmentDuration,
      annotationCount: clip.annotationCount,
      uniqueUserCount: clip.uniqueUserCount,
      timesPlayed: clip.timesPlayed,
    }));
    return (
      <>
        <div>
          <SortableTable
            stripedRows
            columns={[
              { name: 'description', title: 'Description' },
              { name: 'n', title: 'n' },
              { name: 'min', title: 'Min' },
              { name: 'max', title: 'Max' },
              { name: 'mean', title: 'Mean' },
              { name: 'sum', title: 'Sum' },
              { name: 'standardDeviation', title: 'Standard Deviation' },
            ]}
            rows={[
              { description: 'Annotations Per Clip', ...annotationStatistics },
              { description: 'Views Per Clip', ...viewStatistics },
              { description: 'Unique Users Per Clip', ...userStatistics },
            ]}
            columnBands={[
              {
                title: 'Statistics',
                children: [
                  { columnName: 'n' },
                  { columnName: 'min' },
                  { columnName: 'max' },
                  { columnName: 'mean' },
                  { columnName: 'sum' },
                  { columnName: 'standardDeviation' },
                ],
              },
            ]}
          />
        </div>
        <Typography className={classes.tableTitle}>Clip Breakdown</Typography>
        <div>
          <SortableTable
            stripedRows
            columns={[
              { name: 'deviceId', title: 'Device ID' },
              { name: 'segmentStart', title: 'Segment Start' },
              { name: 'segmentDuration', title: 'Segment Duration' },
              { name: 'annotationCount', title: 'Annotations' },
              { name: 'uniqueUserCount', title: 'Unique Users' },
              { name: 'timesPlayed', title: 'Views' },
            ]}
            rows={rows}
            columnBands={[
              {
                title: 'Counts',
                children: [
                  { columnName: 'uniqueUserCount' },
                  { columnName: 'annotationCount' },
                  { columnName: 'timesPlayed' },
                ],
              },
            ]}
          />
        </div>
      </>
    );
  };

  render() {
    const {
      numAnnotations,
      numUsers,
      overallOpen,
      clipOpen,
      usersByCountryOpen,
      top5UsersOpen,
      usersPerCountry,
      campaignName,
      top5Users,
      clipData,
      numViews,
      numClips,
    } = this.state;
    const { classes } = this.props;
    return (
      <Paper>
        <Accordion
          expanded={overallOpen}
          onChange={this.handleChange('overallOpen')}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Overall Statistics</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SortableTable
              stripedRows
              columns={[
                { name: 'campaignName', title: 'Campaign Name' },
                { name: 'numAnnotations', title: 'Annotations' },
                { name: 'numClips', title: 'Clips' },
                { name: 'numUsers', title: 'Unique Users' },
                { name: 'numViews', title: 'Views' },
              ]}
              rows={[
                {
                  campaignName,
                  numAnnotations,
                  numUsers,
                  numViews,
                  numClips,
                },
              ]}
              columnBands={[
                {
                  title: 'Total',
                  children: [
                    { columnName: 'numAnnotations' },
                    { columnName: 'numUsers' },
                    { columnName: 'numClips' },
                    { columnName: 'numViews' },
                  ],
                },
              ]}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          id="user-by-country-expansion-panel"
          expanded={usersByCountryOpen}
          onChange={this.handleChange('usersByCountryOpen')}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Users by Country</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SortableTable
              stripedRows
              columns={[
                { name: 'countryName', title: 'Country' },
                { name: 'numUsers', title: 'Number of Users' },
              ]}
              rows={usersPerCountry}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={top5UsersOpen}
          onChange={this.handleChange('top5UsersOpen')}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Top Five Users</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SortableTable
              stripedRows
              columns={[
                { name: 'id', title: 'User ID' },
                { name: 'name', title: 'Name' },
                { name: 'email', title: 'Email' },
                { name: 'annotations', title: 'Annotation Count' },
              ]}
              rows={top5Users}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={clipOpen} onChange={this.handleChange('clipOpen')}>
          <AccordionSummary
            expandIcon={
              clipData ? <ExpandMore /> : <CircularProgress size={25} />
            }
          >
            <Typography className={classes.heading}>Clip Statistics</Typography>
            {!clipData && (
              <Typography className={classes.secondaryHeading}>
                Computing... this may take a couple minutes, thank you for your
                patience.
              </Typography>
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.clipStatisticsDetails}>
            {clipOpen && this.renderClipData()}
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

export default withStyles(styles)(withSnackbars(DFManagementStatistics));
