import { useState } from 'react';
import {
  Sorting,
  Grouping,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import { Link } from 'base-components';
import StatelessTable from 'library/BaseComponents/table/StatelessTable';

interface Props {
  dataSourceType: string;
  dataSources: any[];
  setSelectedDataSource: (key: number) => void;
  setSelectedDataSourceDevice: (deviceId: number) => void;
}

const renderLinkCell = (row, columnName) => (
  <Link href={row.deviceLink} target="_blank">
    {row[columnName]}
  </Link>
);

const DataSourceWidgetTable: React.FC<Props> = (props: Props) => {
  const {
    dataSourceType,
    dataSources,
    setSelectedDataSource,
    setSelectedDataSourceDevice,
  } = props;

  const deviceColumns = [
    {
      name: 'name',
      title: 'Device Name',
      getCellValue: (row) => renderLinkCell(row, 'name'),
    },
    { name: 'deviceId', title: 'Device ID' },
    { name: 'locationCode', title: 'Location Code' },
    { name: 'location', title: 'Location' },
    { name: 'latestDeployment', title: 'Latest Deployment' },
  ];

  const propertyColumns = [
    { name: 'name', title: 'Property Name' },
    {
      name: 'deviceName',
      title: 'Device Name',
      getCellValue: (row) => renderLinkCell(row, 'deviceName'),
    },
    { name: 'deviceId', title: 'Device ID' },
    { name: 'locationCode', title: 'Location Code' },
    { name: 'location', title: 'Location' },
    { name: 'deploymentRange', title: 'Deployment Range' },
  ];

  const defaultDeviceColumnWidths = [
    { columnName: 'name', width: 180 },
    { columnName: 'deviceId', width: 100 },
    { columnName: 'locationCode', width: 132 },
    { columnName: 'location', width: 140 },
    { columnName: 'latestDeployment', width: 160 },
  ];

  const defaultPropertyColumnWidths = [
    { columnName: 'name', width: 150 },
    { columnName: 'deviceName', width: 180 },
    { columnName: 'deviceId', width: 110 },
    { columnName: 'locationCode', width: 110 },
    { columnName: 'location', width: 140 },
    { columnName: 'deploymentRange', width: 140 },
  ];

  const [selection, setSelection] = useState<number[]>([]);

  const [columnWidths, setColumnWidths] = useState<
    TableColumnWidthInfo[] | null
  >(
    dataSourceType === 'device'
      ? defaultDeviceColumnWidths
      : defaultPropertyColumnWidths
  );

  const deviceColumnExtensions = [
    { columnName: 'name', wordWrapEnabled: true, groupingEnabled: false },
    { columnName: 'deviceId', wordWrapEnabled: true, groupingEnabled: true },
    {
      columnName: 'locationCode',
      wordWrapEnabled: true,
      groupingEnabled: true,
    },
    { columnName: 'location', wordWrapEnabled: true, groupingEnabled: true },
    {
      columnName: 'latestDeployment',
      wordWrapEnabled: true,
      groupingEnabled: true,
    },
  ];

  const propertyColumnExtensions = [
    { columnName: 'name', wordWrapEnabled: true, groupingEnabled: true },
    { columnName: 'deviceName', wordWrapEnabled: true, groupingEnabled: false },
    { columnName: 'deviceId', wordWrapEnabled: false, groupingEnabled: true },
    {
      columnName: 'locationCode',
      wordWrapEnabled: true,
      groupingEnabled: true,
    },
    { columnName: 'location', wordWrapEnabled: true, groupingEnabled: true },
    {
      columnName: 'deploymentRange',
      wordWrapEnabled: true,
      groupingEnabled: true,
    },
  ];

  const handleSelectRow = (selectedRows: number[]) => {
    const lastSelectedRow =
      selectedRows.length > 0 ? selectedRows[selectedRows.length - 1] : -1;
    const selectedData = dataSources.find(
      (data) => data.key === lastSelectedRow
    );
    const selectedDevice = selectedData ? selectedData.deviceId : -1;

    setSelectedDataSourceDevice(selectedDevice);
    setSelectedDataSource(lastSelectedRow);
    setSelection([lastSelectedRow]);
  };

  const [sorting, setSorting] = useState<Sorting[]>([
    { columnName: 'name', direction: 'asc' },
  ]);
  const [grouping, setGrouping] = useState<Grouping[]>([]);

  return (
    <StatelessTable
      columnExtensions={
        dataSourceType === 'device'
          ? deviceColumnExtensions
          : propertyColumnExtensions
      }
      resize={{
        columnWidths,
        handleColumnWidths: setColumnWidths,
      }}
      rows={dataSources}
      columns={dataSourceType === 'device' ? deviceColumns : propertyColumns}
      getRowId={(row) => row.key}
      showNumberOfRecords
      sort={{
        sorting,
        handleSortingChange: setSorting,
      }}
      group={{
        grouping,
        columnExtensions:
          dataSourceType === 'device'
            ? deviceColumnExtensions
            : propertyColumnExtensions,
        handleGroupingChange: setGrouping,
      }}
      selection={{
        selection,
        onChange: handleSelectRow,
        selectByRowClick: true,
        highlightRow: true,
        showSelectionColumn: false,
      }}
      searchable
    />
  );
};

export default DataSourceWidgetTable;
