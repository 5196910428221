const defaultLayout = {
  xl: [
    {
      w: 3,
      h: 24,
      x: 0,
      y: 0,
      i: 'taxonomy-tree-1',
      moved: false,
      static: false,
    },
    {
      w: 9,
      h: 24,
      x: 3,
      y: 0,
      i: 'taxonomy-details-1',
      moved: false,
      static: false,
    },
  ],
  lg: [
    {
      w: 3,
      h: 24,
      x: 0,
      y: 0,
      i: 'taxonomy-tree-1',
      moved: false,
      static: false,
    },
    {
      w: 9,
      h: 24,
      x: 3,
      y: 0,
      i: 'taxonomy-details-1',
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 4,
      h: 24,
      x: 0,
      y: 0,
      i: 'taxonomy-tree-1',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 24,
      x: 4,
      y: 0,
      i: 'taxonomy-details-1',
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 3,
      h: 24,
      x: 0,
      y: 0,
      i: 'taxonomy-tree-1',
      moved: false,
      static: false,
    },
    {
      w: 5,
      h: 24,
      x: 3,
      y: 0,
      i: 'taxonomy-details-1',
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 6,
      h: 12,
      x: 0,
      y: 0,
      i: 'taxonomy-tree-1',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 12,
      x: 0,
      y: 0,
      i: 'taxonomy-details-1',
      moved: false,
      static: false,
    },
  ],
};

const defaultWidgets = [
  {
    i: 'taxonomy-tree-1',
    x: 0,
    y: 99999,
    w: 3,
    h: 10,
    widgetKey: 'taxonomy-tree',
    title: '',
  },
  {
    i: 'taxonomy-details-1',
    x: 0,
    y: 99999,
    w: 9,
    h: 10,
    widgetKey: 'taxonomy-details',
    title: '',
  },
];

export { defaultLayout, defaultWidgets };
