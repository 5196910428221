import { useForm } from 'react-hook-form';
import { Box, Dropdown, Grid, MultiSelect } from 'base-components';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';

const classes = {
  container: {
    overflowX: 'hidden',
    marginRight: 1,
    marginLeft: 4,
    minWidth: '10vw',
  },
  submitButton: {
    marginLeft: 3,
  },
};

type SystemCommandSelectorDetailsProps = {
  onParamChange: (paramName, valueExtractor) => (event) => void;
  onSubmit: () => void;
  args: object;
  sysCommand: {
    systemcommand: string;
    className: string;
    parameters: {
      name: string;
      required: boolean;
      type: string;
      allowed: string[];
    }[];
  };
};

const SystemCommandSelectorDetails = ({
  onParamChange,
  onSubmit,
  args,
  sysCommand,
}: SystemCommandSelectorDetailsProps) => {
  const formMethods = useForm({ mode: 'onChange' });

  /**
   * Helper function for rendering the selectors for each item in our menu.
   * Metrics Type requires a multi-select. Unfortunately there's no indication
   * of this in the System Command Message at all. Unless we add a parameter to
   * the system command message*, then we just need to explicitly check by
   * name.
   *
   * - Modifying System Command messages will require careful testing since we may
   *   break activemq.
   */
  const formatDrop = (parameter) => {
    const { allowed, name: parameterName } = parameter;

    const val = args[parameterName];
    if (parameterName === 'Metrics Type') {
      return (
        <Grid item xs={4}>
          <MultiSelect
            label={parameterName}
            onChange={onParamChange(parameterName, (value) =>
              value.filter((str) => str !== '').join(',')
            )}
            key={parameterName}
            options={allowed.map((command) => ({
              value: command.valueOf(),
              label: command.valueOf(),
              key: command.valueOf(),
            }))}
            value={(val || '').trim().split(',')}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={4}>
        <Dropdown
          disabled={false}
          key={parameterName}
          fullWidth
          helperText=""
          label={`${parameterName}...`}
          id="id"
          options={allowed.map((command) => ({
            value: command.valueOf(),
            label: command.valueOf(),
            key: command.valueOf(),
          }))}
          value={(val || '').trim()}
          onChange={onParamChange(parameterName, undefined)}
        />
      </Grid>
    );
  };

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods}>
      <Box sx={classes.container}>
        <Grid container direction="row" spacing={2}>
          {sysCommand.parameters.map((parameter) => formatDrop(parameter))}
        </Grid>
      </Box>
      <Box sx={classes.submitButton}>
        <TextButton type="submit" translationKey="common.buttons.submit" />
      </Box>
    </Form>
  );
};

export default SystemCommandSelectorDetails;
