import { useState } from 'react';
import { Box } from '@mui/material';
import moment, { Moment } from 'moment';
import { UseFormReturn } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from 'base-components';
import { openSiteDevice } from 'domain/AppComponents/link/SiteDeviceLink';
import SiteDeviceSubsetDeleteDialog from 'domain/AppComponents/site-device-maintenance/SiteDeviceSubsetDeleteDialog';
import SiteDeviceSubsetService, {
  SiteDeviceSubset,
} from 'domain/services/SiteDeviceSubsetService';
import {
  CancelButton,
  ContainedButton,
  DeleteButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import DelayUtil from 'util/DelayUtils';
import Environment from 'util/Environment';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import { SiteDeviceSubsetMaintenanceForm } from './SiteDeviceSubsetMaintenance';
import SiteDeviceSubsetTable from './SiteDeviceSubsetTable';

const classes = {
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    marginBottom: 1,
  },
  alertBoxPadding: {
    paddingLeft: 3,
    paddingRight: 3,
  },
};

type SiteDeviceSubsetMaintenanceFormSubmissionProps = {
  siteDeviceId: number;
  siteDeviceSubsetId: number;
  geospatialAreaWKT: string;
  startDate: Moment;
  endDate: Moment;
  rePostProcessOption: string;
  checkboxChecked: boolean;
  isCast: boolean;
  isExistingSubsetBeingEdited: boolean;
  isCommentRequired: () => boolean;
  hasOnlyCastReviewedStatusChanged: () => boolean;
  hasStartDateChanged: () => boolean;
  hasEndDateChanged: () => boolean;
  hasRefLatChanged: () => boolean;
  hasRefLonChanged: () => boolean;
  formMethods: UseFormReturn<SiteDeviceSubsetMaintenanceForm, any, undefined>;
};

const SiteDeviceSubsetMaintenanceFormSubmission = ({
  siteDeviceId,
  siteDeviceSubsetId,
  geospatialAreaWKT,
  startDate,
  endDate,
  rePostProcessOption,
  checkboxChecked,
  isCast,
  isExistingSubsetBeingEdited,
  isCommentRequired,
  hasOnlyCastReviewedStatusChanged,
  hasStartDateChanged,
  hasEndDateChanged,
  hasRefLatChanged,
  hasRefLonChanged,
  formMethods,
}: SiteDeviceSubsetMaintenanceFormSubmissionProps) => {
  // variable initialization
  const { onInfo, onError } = useSnackbars();

  const [overlappingSiteDeviceSubsets, setOverlappingSiteDeviceSubsets] =
    useState<unknown[]>(undefined);
  const [renderDateAlertBox, setRenderDateAlertBox] = useState<boolean>(false);
  const [renderOverlappingSubsetAlert, setRenderOverlappingSubsetAlert] =
    useState<boolean>(false);
  const [renderSubsetDeletionAlert, setRenderSubsetDeletionAlert] =
    useState<boolean>(false);
  const [renderDeleteButtonDialog, setRenderDeleteButtonDialog] =
    useState<boolean>(false);

  const formatErrorMessage = (message, error) => {
    if (error.statusCode === 9001) {
      onError(`${message} ${error}`);
    } else {
      onError(message);
    }
  };

  // get requests
  const [, , fetchOverlapSiteDevice] = useWebService({
    method: SiteDeviceSubsetService.getOverlappingSiteDeviceSubsetsBySiteDevice,
    onError,
    parser: (response) => {
      const result = response.records.filter(
        (subset) => subset.siteDeviceSubsetId !== siteDeviceSubsetId
      );
      setOverlappingSiteDeviceSubsets(result);
      return result;
    },
  });

  const [, , fetchOverlapDevice] = useWebService({
    method: SiteDeviceSubsetService.getOverlappingSiteDeviceSubsetsByDevice,
    onError,
    parser: (response) => {
      const result = response.records;
      setOverlappingSiteDeviceSubsets(result);
      return result;
    },
  });

  // A whole lot of post requests

  // =======================================================================
  const { mutate: createCastAndProcessData } = usePost<{
    deviceId: number;
    startDate: Date | string;
    endDate: Date | string;
    siteDeviceSubsetTypeId: number;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        setRenderDateAlertBox(false);
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to create cast.', error);
      },
    },
    1
  );

  const { mutate: setCastReviewedStatus } = usePost<{
    siteDeviceSubsetId: number;
    castReviewed: boolean;
    method: string;
    comment?: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        setRenderDateAlertBox(false);
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to set cast reviewed status.', error);
      },
    },
    2
  );

  const { mutate: editCastStartEndDates } = usePost<{
    siteDeviceSubsetId: number;
    startDate: Date | string;
    endDate: Date | string;
    comment: string;
    method: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        setRenderDateAlertBox(false);
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to edit start and end date.', error);
      },
    },
    2
  );

  const { mutate: editCastStartDate } = usePost<{
    siteDeviceSubsetId: number;
    startDate: Date | string;
    comment: string;
    method: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        setRenderDateAlertBox(false);
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to edit start date.', error);
      },
    },
    2
  );

  const { mutate: editCastEndDate } = usePost<{
    siteDeviceSubsetId: number;
    endDate: Date | string;
    comment: string;
    method: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        setRenderDateAlertBox(false);
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to edit end date.', error);
      },
    },
    2
  );

  const { mutate: editCastRefLatLon } = usePost<{
    siteDeviceSubsetId: number;
    referenceLat: number;
    referenceLon: number;
    comment: string;
    method: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        setRenderDateAlertBox(false);
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to edit ref lat and lon.', error);
      },
    },
    2
  );

  const { mutate: editCastRefLat } = usePost<{
    siteDeviceSubsetId: number;
    referenceLat: number;
    comment: string;
    method: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        setRenderDateAlertBox(false);
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to edit ref lat.', error);
      },
    },
    2
  );

  const { mutate: editCastRefLon } = usePost<{
    siteDeviceSubsetId: number;
    referenceLon: number;
    comment: string;
    method: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        setRenderDateAlertBox(false);
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to edit ref lon.', error);
      },
    },
    2
  );

  const { mutate: reprocessCastRefLatLon } = usePost<{
    siteDeviceSubsetId: number;
    comment: string;
    method: string;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        setRenderDateAlertBox(false);
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to rePostProcess cast.', error);
      },
    },
    2
  );

  const { mutate: saveSiteDeviceSubsetWithoutProcessingData } =
    usePost<SiteDeviceSubset>(
      'SiteDeviceSubsetService',
      {
        onSuccess: () => {
          setRenderDateAlertBox(false);
          openSiteDevice({ siteDeviceId });
        },
        onError: (error) => {
          setRenderDateAlertBox(false);
          formatErrorMessage('Site Device Subset not saved.', error);
        },
      },
      1
    );

  const { mutate: deleteSiteDeviceSubsetCast } = usePost<{
    siteDeviceSubsetId: number;
  }>(
    'CastSiteDeviceSubsetService',
    {
      onSuccess: () => {
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to delete Site Device Subset.', error);
      },
    },
    3
  );

  const { mutate: deleteSiteDeviceSubset } = usePost<{
    siteDeviceSubsetId: number;
    type: string;
  }>(
    'SiteDeviceSubsetService',
    {
      onSuccess: () => {
        openSiteDevice({ siteDeviceId });
      },
      onError: (error) => {
        formatErrorMessage('Failed to delete Site Device Subset.', error);
      },
    },
    3
  );
  // =======================================================================

  // misc helper functions

  const shouldRenderRadioGroup = () => {
    if (isExistingSubsetBeingEdited) {
      return (
        isCast &&
        !(hasStartDateChanged() || hasEndDateChanged()) &&
        !(hasRefLatChanged() || hasRefLonChanged())
      );
    }
    return false;
  };

  const rePostProcessConditionsNotMet = (cast) =>
    // conditions are not met if if button not clicked for radio group
    // and buttons are rendered and comment is required but not filled in
    (rePostProcessOption.length <= 0 && cast && shouldRenderRadioGroup()) ||
    (isCommentRequired() && formMethods.getValues().comment === '');

  const shouldDisableSaveButton = (cast) =>
    // disable save button for casts if re-post-process conditions not met and cast reviewed status is not changing
    rePostProcessConditionsNotMet(cast) && !hasOnlyCastReviewedStatusChanged();

  // save handling

  const saveSubsetLogic = (values) => {
    if (!isExistingSubsetBeingEdited && isCast) {
      // create
      createCastAndProcessData({
        deviceId: values.deviceId,
        startDate: values.startDate?.toDate(),
        endDate: values.endDate?.toDate(),
        siteDeviceSubsetTypeId: values.siteDeviceSubsetType,
      });
      return;
    }
    if (isCast && isExistingSubsetBeingEdited) {
      // reviewing cast
      if (hasOnlyCastReviewedStatusChanged()) {
        setCastReviewedStatus({
          siteDeviceSubsetId,
          castReviewed: checkboxChecked,
          method: 'review',
          ...(values.comment?.length <= 0 ? {} : { comment: values.comment }),
        });
        return;
      }
      // start and end date edit
      if (hasStartDateChanged() && hasEndDateChanged()) {
        editCastStartEndDates({
          siteDeviceSubsetId,
          startDate: values.startDate?.toDate(),
          endDate: values.endDate?.toDate(),
          comment: values.comment,
          method: 'editStartEndDates',
        });
        return;
      }

      // start date edit
      if (hasStartDateChanged()) {
        editCastStartDate({
          siteDeviceSubsetId,
          startDate: values.startDate?.toDate(),
          comment: values.comment,
          method: 'editStartDate',
        });
        return;
      }

      // end date edit
      if (hasEndDateChanged()) {
        editCastEndDate({
          siteDeviceSubsetId,
          endDate: values.endDate?.toDate(),
          comment: values.comment,
          method: 'editEndDate',
        });
        return;
      }

      // ref lat lon edit
      if (hasRefLatChanged() && hasRefLonChanged()) {
        editCastRefLatLon({
          siteDeviceSubsetId,
          referenceLat:
            values.referenceLat === ''
              ? undefined
              : Number(values.referenceLat),
          referenceLon:
            values.referenceLon === ''
              ? undefined
              : Number(values.referenceLon),
          comment: values.comment,
          method: 'editRefLatLon',
        });
        return;
      }

      // ref lat edit
      if (hasRefLatChanged()) {
        editCastRefLat({
          siteDeviceSubsetId,
          referenceLat:
            values.referenceLat === ''
              ? undefined
              : Number(values.referenceLat),
          comment: values.comment,
          method: 'editRefLat',
        });
        return;
      }

      // ref lon edit
      if (hasRefLonChanged()) {
        editCastRefLon({
          siteDeviceSubsetId,
          referenceLon:
            values.referenceLon === ''
              ? undefined
              : Number(values.referenceLon),
          comment: values.comment,
          method: 'editRefLon',
        });
        return;
      }

      // repostproccess, default is rePostProcess using scalar lat lon
      reprocessCastRefLatLon({
        siteDeviceSubsetId,
        comment: values.comment,
        method:
          rePostProcessOption === 'no' && shouldRenderRadioGroup()
            ? 'reprocessRefLatLon'
            : 'reprocess',
      });
      return;
    }

    // non cast save
    const params = {
      type: 'siteDeviceSubset',
      siteDeviceId: values.siteDeviceId,
      startDate: values.startDate?.toDate(),
      endDate: values.endDate?.toDate(),
      siteDeviceSubsetTypeId: values.siteDeviceSubsetType,
      generationTypeId: values.generationType,
      siteDeviceSubsetName: values.siteDeviceSubsetName,
      referenceLat:
        values.referenceLat === '' ? undefined : Number(values.referenceLat),
      referenceLon:
        values.referenceLon === '' ? undefined : Number(values.referenceLon),
      referenceDepth: values.referenceDepth,
      comment: values.comment,
      ...(isNaN(siteDeviceSubsetId) ? {} : { siteDeviceSubsetId }),
      ...(geospatialAreaWKT ? { geospatialArea: geospatialAreaWKT } : {}),
    };
    saveSiteDeviceSubsetWithoutProcessingData(params);
  };

  // save dialog boxes

  const renderAlertBoxForDates = () => {
    if (renderDateAlertBox) {
      const currentDate = moment.utc().format('YYYY-MM-DD');
      let dialogMessage = '';
      if (startDate.format('YYYY-MM-DD') === currentDate) {
        dialogMessage = dialogMessage.concat('Start date is set to today. ');
      }
      if (endDate.format('YYYY-MM-DD') === currentDate) {
        dialogMessage = dialogMessage.concat('End date is set to today. ');
      }
      dialogMessage = dialogMessage.concat('Are you sure this is correct?');

      return (
        <Dialog open={renderDateAlertBox} maxWidth="sm" fullWidth>
          <DialogTitle>Start Date or End Date set to Today</DialogTitle>
          <DialogContent />
          <DialogContentText sx={classes.alertBoxPadding}>
            {dialogMessage}
          </DialogContentText>
          <DialogActions>
            <CancelButton onClick={() => setRenderDateAlertBox(false)} />
            <ContainedButton
              translationKey="common.buttons.confirm"
              onClick={() => {
                setRenderDateAlertBox(false);
                setRenderOverlappingSubsetAlert(true);
              }}
            />
          </DialogActions>
        </Dialog>
      );
    }
    return <></>;
  };

  const renderAlertBoxForOverlappingSubsets = () => {
    // Disable save if overlappingSiteDeviceSubsets has not been populated yet or overlapping subsets exist
    const disableSave =
      !overlappingSiteDeviceSubsets || overlappingSiteDeviceSubsets.length > 0;
    if (renderOverlappingSubsetAlert && !isExistingSubsetBeingEdited) {
      return (
        <Dialog open={renderOverlappingSubsetAlert} maxWidth="lg" fullWidth>
          <DialogTitle>
            New subsets with overlap cannot be saved. The following Site Device
            Subsets are overlapping with the new Site Device Subset:
          </DialogTitle>
          <DialogContent />
          <SiteDeviceSubsetTable
            siteDeviceId={siteDeviceId}
            onInfo={onInfo}
            onError={onError}
            siteDeviceSubsetRows={overlappingSiteDeviceSubsets}
            renderActionItems={false}
            pageSize={10}
          />
          <DialogActions>
            <CancelButton
              onClick={() => setRenderOverlappingSubsetAlert(false)}
            />
            <Tooltip
              title={
                disableSave
                  ? 'Overlapping subsets exist, the new subset cannot be saved'
                  : ''
              }
            >
              <span>
                <SaveButton
                  id="overlappingSubsetSaveButton"
                  onClick={formMethods.handleSubmit(saveSubsetLogic)}
                  disabled={disableSave}
                />
              </span>
            </Tooltip>
          </DialogActions>
        </Dialog>
      );
    }
    return <> </>;
  };

  const renderAlertBoxForSubsetDeletion = () => {
    if (renderSubsetDeletionAlert) {
      return (
        <Dialog open={renderSubsetDeletionAlert} maxWidth="lg" fullWidth>
          <DialogTitle>
            The following Site Device Subsets will be deleted after saving:
          </DialogTitle>
          <DialogContent />
          <SiteDeviceSubsetTable
            siteDeviceId={siteDeviceId}
            onInfo={onInfo}
            onError={onError}
            siteDeviceSubsetRows={overlappingSiteDeviceSubsets || []}
            renderActionItems={false}
            pageSize={10}
          />
          <DialogActions>
            <CancelButton onClick={() => setRenderSubsetDeletionAlert(false)} />
            <SaveButton
              id="overlappingSubsetSaveButton"
              onClick={formMethods.handleSubmit(saveSubsetLogic)}
            />
          </DialogActions>
        </Dialog>
      );
    }
    return <> </>;
  };

  const handleDelete = async () => {
    if (isCast) {
      deleteSiteDeviceSubsetCast({
        siteDeviceSubsetId,
      });
    } else {
      deleteSiteDeviceSubset({
        siteDeviceSubsetId,
        type: 'siteDeviceSubset',
      });
    }
  };

  const renderDeleteDialog = () => {
    if (renderDeleteButtonDialog) {
      return (
        <SiteDeviceSubsetDeleteDialog
          onCancel={() => setRenderDeleteButtonDialog(false)}
          onDelete={handleDelete}
          siteDeviceSubsetId={siteDeviceSubsetId}
        />
      );
    }
    return <></>;
  };

  // save button function

  const handleSave = async (values) => {
    let displayAlert = false;
    // existing cast end date change
    if (
      isCast &&
      isExistingSubsetBeingEdited &&
      (hasStartDateChanged() || hasEndDateChanged())
    ) {
      displayAlert = true;
      await fetchOverlapSiteDevice(
        siteDeviceId,
        startDate?.toDate(),
        endDate?.toDate()
      );
      setRenderSubsetDeletionAlert(true);
    }
    // new cast dates are not for today
    const currentDateString = moment.utc().format('YYYY-MM-DD');
    if (
      !isExistingSubsetBeingEdited &&
      currentDateString !== startDate.format('YYYY-MM-DD') &&
      currentDateString !== endDate.format('YYYY-MM-DD')
    ) {
      displayAlert = true;
      if (isCast) {
        await fetchOverlapDevice(
          values.deviceId,
          startDate?.toDate(),
          endDate?.toDate()
        );
      } else {
        await fetchOverlapSiteDevice(
          siteDeviceId,
          startDate?.toDate(),
          endDate?.toDate()
        );
      }

      setRenderOverlappingSubsetAlert(true);
    }
    // new cast dates are for today
    if (
      !isExistingSubsetBeingEdited &&
      (currentDateString === startDate.format('YYYY-MM-DD') ||
        currentDateString === endDate.format('YYYY-MM-DD'))
    ) {
      displayAlert = true;
      if (isCast) {
        await fetchOverlapDevice(
          values.deviceId,
          startDate?.toDate(),
          endDate?.toDate()
        );
      } else {
        await fetchOverlapSiteDevice(
          siteDeviceId,
          startDate?.toDate(),
          endDate?.toDate()
        );
      }
      setRenderDateAlertBox(true);
    }
    if (!displayAlert) {
      saveSubsetLogic(values);
    }
  };

  return (
    <>
      <Box sx={classes.formButtons}>
        {Environment.getDmasUserPrivilege() === 'RW' ? (
          <SaveButton
            id="saveButton"
            type="submit"
            disabled={shouldDisableSaveButton(isCast)}
            onClick={formMethods.handleSubmit(
              DelayUtil.throttle((values) => handleSave(values), 2000)
            )}
          />
        ) : (
          <></>
        )}
        <CancelButton
          onClick={() => {
            openSiteDevice({
              siteDeviceId:
                formMethods.getValues().siteDeviceId !== ''
                  ? Number(formMethods.getValues().siteDeviceId)
                  : siteDeviceId,
            });
          }}
        />
        {isExistingSubsetBeingEdited ? (
          <DeleteButton
            onClick={() => {
              setRenderDeleteButtonDialog(true);
            }}
            disabled={!isExistingSubsetBeingEdited}
          />
        ) : (
          <> </>
        )}
      </Box>
      {renderAlertBoxForDates()}
      {renderAlertBoxForOverlappingSubsets()}
      {renderAlertBoxForSubsetDeletion()}
      {renderDeleteDialog()}
    </>
  );
};

export default SiteDeviceSubsetMaintenanceFormSubmission;
