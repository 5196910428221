import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { Public, Business } from '@onc/icons';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from 'base-components';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import { OrganizationPermission } from 'domain/services/OrganizationService';
import { GLOBAL_USER } from './GlobalConfigConstants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      color: theme.palette.primary.main,
    },
    flexContainer: {
      display: 'flex',
      height: '100%',
    },
    flexItem: {
      flexGrow: 1,
    },
  })
);

type OrganizationListProps = {
  value: string | number;
  onChange: (ownerId: number, ownerType: number, permission: boolean) => void;
  organizations: OrganizationPermission[];
  isDmasAdmin: boolean;
};

const OrganizationList: React.FC<OrganizationListProps> = ({
  value,
  onChange,
  organizations,
  isDmasAdmin,
}: OrganizationListProps) => {
  const classes = useStyles();

  const renderListItem = (
    name: string,
    ownerId: number,
    ownerType: number,
    ListIcon,
    permission: boolean
  ) => (
    <ListItem
      button
      key={name}
      selected={value === ownerId}
      onClick={() => onChange(ownerId, ownerType, permission)}
      classes={{ selected: classes.selected }}
    >
      <ListItemIcon>
        <ListIcon color={value === name ? 'primary' : 'inherit'} />
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );

  const renderOrganizationListItems = () => {
    if (organizations.length) {
      const items = organizations.map((organization) =>
        renderListItem(
          organization.organizationName,
          organization.organizationId,
          SeaTubeResourceTypes.ORGANIZATION,
          Business,
          isDmasAdmin || organization.isAdmin
        )
      );
      return items;
    }
    return <></>;
  };

  return (
    <div className={classes.flexContainer}>
      <List
        className={classes.flexItem}
        subheader={
          <ListSubheader component="div">Configurations</ListSubheader>
        }
      >
        {renderListItem(
          'Global',
          GLOBAL_USER.ID,
          GLOBAL_USER.OWNER_TYPE,
          Public,
          isDmasAdmin
        )}
        {renderOrganizationListItems()}
      </List>
      <Divider orientation="vertical" flexItem />
    </div>
  );
};

export default OrganizationList;
