import { ReactNode } from 'react';
import { PathProps, createPathComponent } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';

// Copied from @react-leaflet/core/lib/component.d.ts
type PropsWithChildren = {
  children?: ReactNode;
};

interface PathWithChildrenProps extends PathProps, PropsWithChildren {}

interface MarkerClusterGroupProps extends PathWithChildrenProps {
  maxClusterRadius?: number;
  iconCreateFunction?: (cluster: any) => L.Icon | L.DivIcon;
  clusterPane?: string;
  spiderfyDistanceMultiplier?: number;
  singleMarkerMode?: boolean;
  chunkedLoading?: boolean;
  chunkInterval?: number;
  chunkDelay?: number;
  chunkProgress?: (
    processed: number,
    total: number,
    elapsedTime: number
  ) => void;
  polygonOptions?: L.PathOptions;
  onClusterclick?: (cluster: any) => void;
  onClusterdblclick?: (cluster: any) => void;
  onClustercontextmenu?: (cluster: any) => void;
}

const MarkerClusterGroup = createPathComponent(
  ({ children: _c, ...props }: MarkerClusterGroupProps, ctx) => {
    const clusterProps = {};
    const clusterEvents = {};

    // Splitting props and events to different objects
    Object.entries(props).forEach(([propName, prop]) => {
      propName.startsWith('on')
        ? (clusterEvents[propName] = prop)
        : (clusterProps[propName] = prop);
    });

    // Creating markerClusterGroup Leaflet element
    // eslint-disable-next-line new-cap
    const markerClusterGroup = new (L as any).markerClusterGroup(clusterProps);

    // Initializing event listeners
    Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
      const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
      markerClusterGroup.on(clusterEvent, callback);
    });

    return {
      instance: markerClusterGroup,
      context: { ...ctx, layerContainer: markerClusterGroup },
    };
  }
);

export default MarkerClusterGroup;
