import MaterialTypography from '@mui/material/Typography';
import { type TypographyProps } from '@mui/material/Typography';
import { TOptions, useTranslation, TranslationType } from '@onc/i18n';

export type TypographyPropTypes = TypographyProps & {
  /**
   * Typography label from the json file
   * util/i18n/src/translation/en/translation.json
   */
  translationKey?: TranslationType;

  /** Options to pass in with the translation. */
  translationOptions?: TOptions;
};

const TypographyWithTranslation = ({
  translationKey = undefined,
  translationOptions = undefined,
  ...rest
}: TypographyPropTypes) => {
  const { t } = useTranslation();

  return (
    <MaterialTypography {...rest}>
      {t(translationKey, translationOptions)}
    </MaterialTypography>
  );
};
export default TypographyWithTranslation;
