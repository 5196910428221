import { useEffect, useState } from 'react';
import * as React from 'react';
import useSnapshots from 'domain/Apps/seatube/util/useSnapshots';
import ImageListDialog from 'library/CompositeComponents/image-list-dialog/ImageListDialog';
import DateUtils from 'util/DateUtils';

type Props = {
  takeSnapshots: boolean;
  deviceId: number;
  time: string;
  numSnapshots?: number;
  snapshotQuality?: string;
  videoContainer?: HTMLDivElement;
  onSnapshotComplete: () => void;
  getFullscreen?: () => boolean;
};

const Snapshots: React.FC<Props> = ({
  takeSnapshots,
  deviceId,
  // time, numSnapshots required but provided at the time of taking the snapshot
  time,
  numSnapshots = 9,
  snapshotQuality = undefined,
  onSnapshotComplete,
  getFullscreen = undefined,
  videoContainer = undefined,
}: Props) => {
  const [title, setTitle] = useState('');

  const handleOnClose = () => {
    onSnapshotComplete();
    setTitle('');
  };

  const [snapshotUrls, loading] = useSnapshots(
    deviceId,
    time,
    numSnapshots,
    numSnapshots,
    snapshotQuality
  );

  useEffect(() => {
    let snapshotTimestamp = DateUtils.formatDateAsString(time);
    if (snapshotTimestamp === null) {
      snapshotTimestamp = time;
    }
    setTitle(`Snapshots for ${snapshotTimestamp}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Only return the video container when fullscreen so it renders on top of the
   * player
   */
  const getVideoContainer = () => {
    if (!getFullscreen()) return undefined;
    let container;
    if (videoContainer) {
      container = videoContainer;
    } else {
      container = document.querySelector('.jw-wrapper');
    }
    return getFullscreen() ? container : undefined;
  };

  return (
    <ImageListDialog
      open={takeSnapshots}
      onClose={handleOnClose}
      numSnapshots={numSnapshots}
      imageUrls={snapshotUrls}
      title={title}
      container={getVideoContainer()}
      loading={loading}
    />
  );
};

export default Snapshots;
