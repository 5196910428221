/*
 * The id's of these status's map to what the TaskHistoryService uses for the filters
 */

interface TaskStatusEntry {
  value: number;
  label: string;
}

const TaskStatus = {
  OPEN: {
    value: 8,
    label: 'Open',
  },
  QUEUED: {
    value: 1,
    label: 'Queued',
  },
  RUNNING: {
    value: 2,
    label: 'Running',
  },
  CANCELLING: {
    value: 7,
    label: 'Cancelling',
  },
  COMPLETED: {
    value: 3,
    label: 'Completed',
  },
  CANCELLED: {
    value: 5,
    label: 'Cancelled',
  },
  ABORTED: {
    value: 4,
    label: 'Error',
  },
  COMPLETED_WITH_ERRORS: {
    value: 6,
    label: 'CompletedWithError',
  },

  /**
   * Return the label of a TaskStatus by it's value
   *
   * @param value A value from any task status
   * @returns The label of a TaskStatus or undefined if not found
   */
  getLabelByValue: (value: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [key, entry] of Object.entries(TaskStatus)) {
      if ((<TaskStatusEntry>entry)?.value === value) {
        return (<TaskStatusEntry>entry)?.label;
      }
    }
    return undefined;
  },
};

export default TaskStatus;
