import { Autocomplete, type AutocompleteProps } from 'base-components';
import useSensors from 'domain/AppComponents/Dashboard/chart-widget/hooks/useSensors';
import type Sensor from 'domain/AppComponents/Dashboard/chart-widget/types/Sensor';
import type { Moment } from 'moment';

type SensorAutocompleteProps = {
  value: Sensor | null;
  deviceCode?: string;
  onChange?: (newSensor: Sensor) => void;
  fullWidth?: boolean;
  dateFrom?: Moment;
  dateTo?: Moment;
} & Omit<
  AutocompleteProps,
  'options' | 'getOptionLabel' | 'isOptionEqualToValue' | 'label'
>;

const SensorAutocomplete: React.FC<SensorAutocompleteProps> = ({
  value,
  deviceCode = '',
  onChange = () => {},
  fullWidth = false,
  dateFrom = undefined,
  dateTo = undefined,
  disabled = false,
  required = false,
}: SensorAutocompleteProps) => {
  const sensors = useSensors(deviceCode, dateFrom, dateTo);

  return (
    <Autocomplete
      options={sensors}
      onChange={(event, newSensor) => onChange(newSensor)}
      value={value}
      noOptionsText="No Sensors Available"
      getOptionLabel={(option) => `${option.sensorName} (${option.sensorCode})`}
      isOptionEqualToValue={(a, b) => a.sensorName === b.sensorName}
      translationKey="device.sensor"
      name="sensorAutocomplete"
      fullWidth={fullWidth}
      disabled={disabled}
      required={required}
    />
  );
};

export default SensorAutocomplete;
