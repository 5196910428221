/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect } from 'react';

import * as React from 'react';
import { Autocomplete } from 'base-components';
import CruiseService, { CruiseJSON } from 'domain/services/CruiseService';
import DiveListingService, {
  DiveJSON,
} from 'domain/services/DiveListingService';
import { FilterEvent } from 'library/CompositeComponents/filter/Filter';
import useWebService from 'util/hooks/useWebService';
import ObjectUtils from 'util/ObjectUtils';

interface LogItem {
  label: string;
  value: number;
  type: 'dive' | 'deck';
}

interface LogChipSelectProps {
  id: string;
  name: string;
  onChange?: (e: FilterEvent) => void;
  disabled?: boolean;
  value?: LogItem[];
  cruiseIds?: number[];
  onDiveOptionsLoaded?: (e: object) => void;
}

const LogChipSelect: React.FC<LogChipSelectProps> = ({
  id,
  name,
  onChange = () => {},
  disabled = false,
  value = [],
  cruiseIds = [],
  onDiveOptionsLoaded = () => {},
}) => {
  const handleLogsChange = (event: FilterEvent) => {
    onChange(event);
  };

  const [dives, loadingDives, fetchDives] = useWebService({
    method: DiveListingService.getDives,
    parser: (response: { dives: DiveJSON[] }) => response.dives,
  });

  const [cruises, loadingCruises, fetchCruises] = useWebService({
    method: CruiseService.getCruises,
    parser: (response: { cruises: CruiseJSON[] }) => response.cruises,
  });

  useEffect(() => {
    fetchDives();
    fetchCruises();
  }, []);

  // Handles updating the labels of the selected logs since they are loaded in asynchronously
  useEffect(() => {
    const newValues = [];
    if (!loadingDives && !loadingCruises && cruiseIds.length) {
      value?.forEach((log) => {
        if (log.type === 'dive') {
          const dive = dives?.find((option) => option.diveId === log.value);
          if (dive) {
            newValues.push({
              label: dive.referenceDiveId,
              value: dive.diveId,
              type: 'dive',
            });
          }
        }
        if (log.type === 'deck') {
          const cruise = cruises?.find(
            (option) => option.cruiseId === log.value
          );
          if (cruise) {
            newValues.push({
              label: `${cruise.cruiseName} - Deck Log`,
              value: cruise.cruiseId,
              type: 'deck',
            });
          }
        }
      });
      if (newValues.length) {
        handleLogsChange({
          target: {
            name: 'logs',
            value: newValues,
          },
        });
      }
    }
  }, [cruises, dives, loadingDives, loadingCruises, cruiseIds]);

  const getOptions = () => {
    const diveOptions =
      dives
        ?.filter((dive) => cruiseIds.includes(dive.cruiseId))
        .map((dive) => ({
          label: dive.referenceDiveId,
          value: dive.diveId,
          type: 'dive',
        })) || [];
    const cruiseOptions =
      cruises
        ?.filter((cruise) => cruiseIds.includes(cruise.cruiseId))
        .map((cruise) => ({
          label: `${cruise.cruiseName} - Deck Log`,
          value: cruise.cruiseId,
          type: 'deck',
        })) || [];
    return [...diveOptions, ...cruiseOptions];
  };

  useEffect(() => {
    onDiveOptionsLoaded(getOptions());
  }, [cruiseIds, dives, cruises]);

  return (
    <Autocomplete
      id={id}
      onChange={handleLogsChange}
      options={getOptions()}
      getOptionLabel={(option) => option?.label}
      isOptionEqualToValue={(option: LogItem, val: LogItem) =>
        option.value === val.value && option.type === val.type
      }
      translationKey="seatube.logs"
      name={name || 'logs-dropdown'}
      multiple
      fullWidth
      value={value}
      disabled={disabled}
    />
  );
};

export default memo(LogChipSelect, (pre, next) =>
  ObjectUtils.isEqual(pre, next, ['onDiveOptionsLoaded', 'onChange'])
);
