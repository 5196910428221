import React, { useCallback, useEffect, useState } from 'react';
import { SvgIconProps } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import { ErrorSnackbar } from '@onc/composite-components';
import { Warning, CheckCircle } from '@onc/icons';
import { CircularProgress, Tooltip, OncIconButtonProps } from 'base-components';

import LastReadingJSONService from 'domain/AppComponents/Dashboard/LastReadingJSONService';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import { WidgetTitle } from '../Titles';

const AUTO_REFRESH_INTERVAL_MILLIS = 60000;

const READING_NUM_DECIMALS = 2;

const HEADERS = [
  {
    title: 'Sensor',
    name: 'sensor',
  },
  {
    title: 'Reading',
    name: 'reading',
  },
  {
    title: 'Updated (UTC)',
    name: 'updated',
  },
  {
    title: ' ',
    name: 'dataAgeIcon',
  },
];

type Props = Partial<Omit<OncIconButtonProps, 'children'>> & {
  iconProps?: SvgIconProps;
};

interface DataWarningIconProps extends Props {
  ageLimit: string;
}

const DataWarningIcon = ({ Icon, ageLimit, ...rest }: DataWarningIconProps) => (
  <Tooltip
    title={
      Icon === Warning
        ? `Data is older than ${ageLimit}`
        : `Data is newer than ${ageLimit}`
    }
  >
    <Icon
      size="small"
      Icon={Icon}
      color={Icon === Warning ? 'warning' : 'success'}
      {...rest}
    />
  </Tooltip>
);

const useStyles = makeStyles(() =>
  createStyles({
    circularProgress: {},
    contentDiv: {},
    tablePaper: { height: '100%' },
  })
);

type LatestReadingsWidgetDisplayProps = {
  showTitle: boolean;
  title: string;
  sensorIds: string;
  autoRefresh: boolean;
  dataAgeLimit?: number;
  simpleDateEnabled?: boolean;
  dataWarningEnabled?: boolean;
};

const LatestReadingsWidgetDisplay: React.FC<
  LatestReadingsWidgetDisplayProps
> = ({
  showTitle,
  title,
  sensorIds,
  autoRefresh,
  // epoch is ms since epoch. Age limit should be ms since data created
  // 1 year in ms = 31536000000
  // 30 days in ms = 2592000000
  // 1 week in ms = 604800000
  // 1 day in ms = 86400000
  // 1 hour in ms = 3600000
  dataAgeLimit = 31536000000,
  simpleDateEnabled = false,
  dataWarningEnabled = false,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [autoRefreshVar, setAutoRefreshVar] = useState(null);
  const [rows, setRows] = useState([]);

  const addIconButton = (icon, ageLimit) => {
    const currentTimeInMS = Date.now();
    const msToDate = moment(currentTimeInMS - ageLimit);
    const simpleDate = msToDate.fromNow();
    return (
      <DataWarningIcon onClick={() => {}} Icon={icon} ageLimit={simpleDate} />
    );
  };

  const addDateTooltip = useCallback(
    (date) => {
      if (date === null) {
        return undefined;
      }
      const dateString = DateFormatUtils.formatDate(date, 'full');
      const simpleDate = date.fromNow();
      return simpleDateEnabled ? (
        <Tooltip title={dateString}>
          <div>{simpleDate}</div>
        </Tooltip>
      ) : (
        <Tooltip title={simpleDate}>
          <div>{dateString}</div>
        </Tooltip>
      );
    },
    [simpleDateEnabled]
  );

  const checkIfDataOutdated = useCallback(
    (date) => {
      if (!dataWarningEnabled || date === null) {
        return undefined;
      }
      const dataDateTime = date.valueOf();
      const currentTime = moment.utc().valueOf();
      const diff = currentTime - dataDateTime;
      const dataAgeLimitInMilliseconds =
        parseInt(String(dataAgeLimit), 10) * 24 * 60 * 60 * 1000;
      if (diff < dataAgeLimitInMilliseconds) {
        return addIconButton(CheckCircle, dataAgeLimitInMilliseconds);
      }
      return addIconButton(Warning, dataAgeLimitInMilliseconds);
    },
    [dataAgeLimit, dataWarningEnabled]
  );

  const processLastReadingsData = useCallback(
    (response) => {
      if (!response.data || response.data.error) {
        const errorMsg = 'System error or no data found for specified sensors';
        setError(errorMsg);
        return Promise.resolve(new Error(errorMsg));
      }
      const newRows = response.data.map((reading) => {
        const dateFromValue = moment.utc(reading.lastReading.dateFrom);
        return {
          sensor: `${reading.sensorName} (${reading.sensorUnit})`,
          reading: reading.lastReading.correctedValue
            ? reading.lastReading.correctedValue.toFixed(READING_NUM_DECIMALS)
            : '',
          dataAgeIcon: checkIfDataOutdated(dateFromValue),
          updated: addDateTooltip(dateFromValue),
        };
      });
      setRows(newRows);
      return Promise.resolve();
    },
    [addDateTooltip, checkIfDataOutdated]
  );

  const fetchLatestReadings = useCallback(() => {
    const formattedSensorIds = sensorIds.replace(/\s+/g, '');
    if (formattedSensorIds === '') {
      setError('No sensors found');
      return Promise.resolve(new Error('No sensors found'));
    }
    return LastReadingJSONService.get({ sensors: formattedSensorIds })
      .then((response) => processLastReadingsData(response) as Promise<Error>)
      .catch((newError) => {
        setError(newError);
        return Promise.resolve(newError);
      });
  }, [processLastReadingsData, sensorIds]);

  const fetchLatestReadingsOnInterval = useCallback(() => {
    fetchLatestReadings()
      .then(() => {
        if (autoRefresh) {
          const newAutoRefreshVar = setInterval(
            fetchLatestReadings,
            AUTO_REFRESH_INTERVAL_MILLIS
          );
          setAutoRefreshVar(newAutoRefreshVar);
        }
        setLoading(false);
        return Promise.resolve();
      })
      .catch((newError) => {
        setLoading(false);
        setError(newError);
        return Promise.resolve(newError);
      });
  }, [autoRefresh, fetchLatestReadings]);

  useEffect(() => {
    setLoading(true);
    fetchLatestReadingsOnInterval();
    return () => {
      if (autoRefreshVar) {
        clearInterval(autoRefreshVar);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchLatestReadingsOnInterval]);

  const renderTitle = () => {
    if (showTitle) {
      return <WidgetTitle titleText={title} />;
    }
    return <></>;
  };

  const renderError = () => {
    if (error) {
      return <ErrorSnackbar message={error} />;
    }
    return <></>;
  };

  if (loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <>
      {renderError()}
      {renderTitle()}
      <div className={classes.contentDiv}>
        <SortableTable
          columns={HEADERS}
          rows={rows}
          classes={{ root: classes.tablePaper }}
        />
      </div>
    </>
  );
};

export default LatestReadingsWidgetDisplay;
