import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';
import Panel from 'library/CompositeComponents/panel/Panel';
import MultiQueueTree from './MultiQueueTree';

const styles = {};

class MultiQueueTreePanel extends PureComponent {
  static propTypes = {
    selectNode: PropTypes.func.isRequired,
    selectedNode: NodePropType,
    treeData: PropTypes.arrayOf(NodePropType),
  };

  static defaultProps = {
    selectedNode: undefined,
    treeData: undefined,
  };

  state = { runClicked: false, runId: undefined };

  render() {
    const { selectedNode, selectNode, treeData } = this.props;
    return (
      <Panel title="Multi Queue Tree">
        <MultiQueueTree
          treeData={treeData}
          selectedNode={selectedNode}
          selectNode={selectNode}
        />
      </Panel>
    );
  }
}

export default withStyles(styles)(MultiQueueTreePanel);
