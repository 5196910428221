import { memo } from 'react';
import MaterialSlider, {
  SliderProps as MuiSliderProps,
} from '@mui/material/Slider';

type SliderProps = MuiSliderProps & {
  /** What to do when the slider is changed */
  onChange: (event, value: number | number[], activeThumb?: number) => void;
  /** Minimum possible slider value */
  valueLabelDisplay?: string;
};

/** Wrapped Material UI slider */
const Slider = ({
  name,
  onChange,
  min = 0,
  max = 100,
  valueLabelDisplay = 'off',
  ...rest
}: SliderProps) => (
  <MaterialSlider
    {...rest}
    min={min}
    max={max}
    valueLabelDisplay={valueLabelDisplay}
    onChange={(event, value) => {
      const newEvent = {
        target: {
          ...event.target,
          name,
          value,
        },
      };
      onChange(newEvent, value);
    }}
  />
);

export default memo(Slider);
