import React, { useRef, useState } from 'react';
import { SliderValueLabelProps, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VolumeDown, VolumeOff, VolumeUp } from '@onc/icons';
import { IconButton, Slider } from 'base-components';

const useStyles = makeStyles<Theme, { isSliderShown: boolean }>((theme) => ({
  volumeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sliderContainer: {
    width: ({ isSliderShown }) => (isSliderShown ? '60px' : '0px'),
    transition: 'width 0.3s ease', // Smooth transition when showing/hiding slider
    marginRight: theme.spacing(),
  },
}));

function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip arrow enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

type SeamlessVideoVolumeProps = {
  volume: number;
  setVolume: (volume: number) => void;
  containerRef?: HTMLElement;
};

const SeamlessVideoVolume: React.FC<SeamlessVideoVolumeProps> = ({
  volume,
  setVolume,
  containerRef = undefined,
}) => {
  const [isSliderShown, setIsSliderShown] = useState(false);
  const classes = useStyles({ isSliderShown });
  const prevVolume = useRef(1);

  const handleVolumeChange = (event: Event, value: any) => {
    const newVolume = value / 100;
    prevVolume.current = newVolume > 0 ? newVolume : prevVolume.current;
    setVolume(newVolume);
  };

  const handleVolumeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (volume === 0) {
      setVolume(prevVolume.current);
    } else {
      prevVolume.current = volume;
      setVolume(0);
    }
    e.stopPropagation();
  };

  const renderVolumeButton = () => {
    if (volume > 0.5) {
      return <VolumeUp />;
    }
    if (volume === 0) {
      return <VolumeOff />;
    }
    return <VolumeDown />;
  };

  return (
    <div
      className={classes.volumeContainer}
      onMouseEnter={() => {
        setIsSliderShown(true);
      }}
      onMouseLeave={() => {
        setIsSliderShown(false);
      }}
    >
      <IconButton
        aria-label={volume === 0 ? 'Unmute' : 'Mute'}
        onClick={handleVolumeClick}
        color="inherit"
        tooltipProps={{
          slotProps: {
            popper: { container: containerRef },
          },
          placement: 'top',
        }}
        sx={{ mr: 2 }} // Add margin right to next button
      >
        {renderVolumeButton()}
      </IconButton>
      <div className={classes.sliderContainer}>
        {isSliderShown && (
          <Slider
            aria-label="Volume Position"
            name="volume-slider"
            onChange={handleVolumeChange}
            value={Math.floor(volume * 100)}
            valueLabelDisplay="auto"
            step={1}
            size="small"
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SeamlessVideoVolume;
