import { memo } from 'react';
import * as React from 'react';
import {
  Stepper as MuiStepper,
  Step as MuiStep,
  StepLabel,
  StepContent,
} from '@mui/material';
import Typography from '../text/Typography';

export interface StepDisplayInfo {
  /** Display label shown after the number */
  label: string;
  /** Description of the step */
  description?: string;
  /** When set to true the step will be shown as as having an error */
  hasError?: boolean;
}

interface StepperProps {
  /** Whether or not the stepper is linear (ie: Must be done in order) */
  isNonLinear?: boolean;
  /** When true the label is shown below, when false it's shown to the right */
  showLabelBelow?: boolean;
  /**
   * The current step number. Should be controlled by the parent. Set to -1 to
   * disable numbered steps
   */
  stepNum?: number; // 1-indexed
  /** Configuration for each the step passed as an array */
  displayInfo: StepDisplayInfo[];
  /** Horizontal steps will be displayed left to right, vertical up to down */
  orientation?: 'horizontal' | 'vertical';
}

/** A Material UI Stepper that includes multiple steps & there content */
const Stepper: React.VFC<StepperProps> = ({
  isNonLinear = false,
  showLabelBelow = false,
  stepNum = 1,
  displayInfo,
  orientation = 'horizontal',
}) => (
  // Note that StepContent is only designed for vertical orientation.
  // Material's <Step> should have a unique key property, according to warnings.
  <MuiStepper
    activeStep={stepNum - 1}
    alternativeLabel={showLabelBelow}
    nonLinear={isNonLinear}
    orientation={orientation}
  >
    {displayInfo.map((info) => (
      <MuiStep key={info.label}>
        <StepLabel error={info.hasError}>{info.label}</StepLabel>
        {info.description && orientation === 'vertical' && (
          <StepContent>
            <Typography>{info.description}</Typography>
          </StepContent>
        )}
      </MuiStep>
    ))}
  </MuiStepper>
);

export default memo(Stepper);
