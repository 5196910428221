import { FC, memo } from 'react';
import { makeStyles } from '@mui/styles';
import { Error } from '@onc/icons';
import {
  List,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
  VirtualList,
} from 'base-components';
import {
  ServiceAnnotationV3,
  PublicAnnotation,
} from 'domain/services/AnnotationService';
import { SeaTubePermissionObj } from 'domain/services/SeaTubePermissionsService';
import { AnnotationListConfig } from 'domain/Widgets/AnnotationListWidget';
import { VideoInterval } from 'library/CompositeComponents/video/SeamlessVideoUtil';
import AnnotationListItem from './AnnotationListItem';

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  emptyText: {
    color: theme.palette.text.disabled,
  },
}));

export type ListAnnotation = {
  // Core fields
  annotationId: number;
  comment: string;
  startDate: string;
  toBeReviewed: boolean;
  resourceId?: number;
  resourceTypeId?: number;

  // Location data
  lat?: number;
  lon?: number;
  depth?: number;
  heading?: number;

  // User data
  createdBy: {
    userCode: string | number;
    firstName: string;
    lastName: string;
  };
  createdDate: string;
  modifiedBy: {
    userCode: string | number;
    firstName: string;
    lastName: string;
  };
  modifiedDate: string;
  isPublic?: boolean;

  // Taxonomy data
  taxons?: {
    displayText: string;
    taxonUrl?: string;
    taxonId: number;
    taxonomyId: number;
    taxonomyCode: string;
    attributes?: {
      attributeId: number;
      dataType: string;
      groupId: number;
      groupName: string;
      name: string;
      value: string;
    }[];
    annotationReview?: {
      reviewValue: string;
    };
  }[];
};

interface VirtualAnnotationListProps {
  annotations?: ListAnnotation[];
  editAnnotationId?: number;
  isLiveMode?: boolean;
  lastEditedAnnotationId?: number;
  memoizedConfig: AnnotationListConfig;
  permissions?: SeaTubePermissionObj;
  scrollToIndex: number;
  selectedAnnotationId: number;
  videoIntervals: VideoInterval[];
  onClick: (annotationId: ListAnnotation) => void;
  onClickDelete: (annotationId: ListAnnotation) => void;
  onCopyLink: (annotation: ListAnnotation) => void;
  onEdit: (annotationId: ListAnnotation) => void;
  onAnnotationReviewClick: (id: number, value: 'up' | 'down') => void;
}

const VirtualAnnotationList: FC<VirtualAnnotationListProps> = ({
  annotations = undefined,
  editAnnotationId = undefined,
  isLiveMode = false,
  lastEditedAnnotationId = 0,
  memoizedConfig,
  permissions = undefined,
  scrollToIndex,
  selectedAnnotationId,
  videoIntervals,
  onClick,
  onClickDelete,
  onCopyLink,
  onEdit,
  onAnnotationReviewClick,
}) => {
  const classes = useStyles();

  if (annotations === undefined || permissions === undefined) {
    return (
      <List>
        {Array.from({ length: 20 }).map(() => (
          <>
            <Divider />
            <ListItem>
              <ListItemAvatar style={{ paddingRight: '8px' }}>
                <Skeleton width="100%" height="20px" />
              </ListItemAvatar>
              <ListItemText>
                <Skeleton width="100%" height="20px" />
              </ListItemText>
            </ListItem>
          </>
        ))}
      </List>
    );
  }

  if (annotations?.length === 0) {
    return (
      <div className={classes.emptyContainer}>
        <Error color="disabled" fontSize="large" />
        <Typography variant="h6" className={classes.emptyText}>
          No Annotations
        </Typography>
      </div>
    );
  }

  return (
    <VirtualList scrollToIndex={scrollToIndex} scrollToAlignment="center">
      <Divider />
      {annotations?.map((annotation) => (
        <AnnotationListItem
          permissions={permissions}
          annotation={annotation}
          key={annotation.annotationId}
          lastEditedAnnotationId={lastEditedAnnotationId}
          editAnnotationId={editAnnotationId}
          selected={selectedAnnotationId === annotation.annotationId}
          onClick={onClick}
          onDelete={onClickDelete}
          onCopyLink={onCopyLink}
          onEdit={onEdit}
          onReviewClick={onAnnotationReviewClick}
          config={memoizedConfig}
          isLiveMode={isLiveMode}
          videoIntervals={videoIntervals}
        />
      ))}
    </VirtualList>
  );
};

export const serviceToListAnnotation = (
  serviceAnnotation: ServiceAnnotationV3
): ListAnnotation => ({
  // Core fields
  annotationId: serviceAnnotation.annotationId,
  comment: serviceAnnotation.comment,
  startDate: serviceAnnotation.startDate,
  toBeReviewed: serviceAnnotation.toBeReviewed,
  resourceId: serviceAnnotation.resourceId,
  resourceTypeId: serviceAnnotation.resourceTypeId,

  // Location data
  lat: serviceAnnotation.lat,
  lon: serviceAnnotation.lon,
  depth: serviceAnnotation.depth,
  heading: serviceAnnotation.heading,

  // User data
  createdBy: {
    userCode: serviceAnnotation.createdBy.userId,
    firstName: serviceAnnotation.createdBy.firstName,
    lastName: serviceAnnotation.createdBy.lastName,
  },
  createdDate: serviceAnnotation.createdDate,
  modifiedBy: {
    userCode: serviceAnnotation.modifiedBy?.userId,
    firstName: serviceAnnotation.modifiedBy?.firstName,
    lastName: serviceAnnotation.modifiedBy?.lastName,
  },
  modifiedDate: serviceAnnotation.modifiedDate,

  // Taxonomy data
  taxons: serviceAnnotation.taxons?.map((taxon) => ({
    displayText: taxon.displayText,
    taxonUrl: taxon.taxonUrl,
    taxonId: taxon.taxonId,
    taxonomyCode: taxon.taxonomyCode,
    taxonomyId: taxon.taxonomyId,
    attributes: taxon.attributes?.map((attr) => ({
      name: attr.name,
      value: attr.value,
      groupName: attr.groupName,
      groupId: attr.groupId,
      attributeId: attr.attributeId,
      dataType: attr.dataType,
    })),
    annotationReview: taxon.annotationReview
      ? {
          reviewValue: taxon.annotationReview.reviewValue,
        }
      : undefined,
  })),
});

export const publicToListAnnotation = (
  publicAnnotation: PublicAnnotation
): ListAnnotation => ({
  // Core fields
  annotationId: publicAnnotation.annotationId,
  comment: publicAnnotation.comment,
  startDate: publicAnnotation.dateFrom,
  toBeReviewed: publicAnnotation.toBeReviewed,

  // Location data
  lat: null, // These fields don't exist in PublicAnnotation
  lon: null,
  depth: null,
  heading: null,

  // User data
  createdBy: {
    userCode: publicAnnotation.createdBy.userId, // Using id until codes implemented
    firstName: publicAnnotation.createdBy.firstName,
    lastName: publicAnnotation.createdBy.lastName,
  },
  createdDate: publicAnnotation.createdDate,
  modifiedBy: {
    userCode: publicAnnotation.modifiedBy.userId, // Using id until codes implemented
    firstName: publicAnnotation.modifiedBy.firstName,
    lastName: publicAnnotation.modifiedBy.lastName,
  },
  modifiedDate: publicAnnotation.modifiedDate,

  isPublic: publicAnnotation.isPublic,

  // Taxonomy data
  taxons: publicAnnotation.taxons
    ? [
        {
          displayText: publicAnnotation.taxons[0].taxon,
          taxonomyCode: publicAnnotation.taxons[0].taxonomyCode,
          taxonId: publicAnnotation.taxons[0].taxonId,
          taxonomyId: publicAnnotation.taxons[0].taxonomyId,
          attributes: publicAnnotation.taxons[0].attributes.map((attr) => ({
            name: attr.name,
            value: attr.value,
            groupName: attr.group,
            groupId: attr.groupId,
            attributeId: attr.attributeId,
            dataType: attr.dataType,
          })),
          // No review data in PublicAnnotation
          annotationReview: undefined,
        },
      ]
    : null,
});

export default memo(VirtualAnnotationList);
