import { post } from 'util/WebRequest';

const SERVICE = 'TranslationFileService';

class TranslationFileService {
  static post(file, encoding) {
    const formData = new FormData();
    formData.append('uploadFile', file);
    formData.append('encoding', encoding);
    return post(SERVICE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => response);
  }
}
export default TranslationFileService;
