/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */

import { Dispatch, SetStateAction, useEffect, useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      state = action.value;
      return state;

    default:
      throw new Error();
  }
}

export default function useStorage<Type>(
  name: string,
  initialValue: Type,
  storage: Storage
): [Type, Dispatch<SetStateAction<Type>>, () => void] {
  const getInitialValue = () => {
    try {
      // Get from storage by key
      const item = storage.getItem(name);
      if (item !== null) {
        return JSON.parse(item);
      }
    } catch (error) {
      // Do nothing
    }
    return initialValue;
  };

  const [state, dispatch] = useReducer(reducer, getInitialValue());

  useEffect(() => {
    try {
      // Get from storage by key
      const item = storage.getItem(name);
      if (item) {
        dispatch({ type: 'set', value: JSON.parse(item) });
      } else if (initialValue) {
        storage.setItem(name, JSON.stringify(initialValue));
      }
    } catch (error) {
      // If error, just return
    }
  }, []);

  const handleStorageChange = (event) => {
    if (event.key === name && event.newValue) {
      dispatch({ type: 'set', value: JSON.parse(event.newValue) });
    }
  };

  useEffect(() => {
    const item = storage.getItem(name);
    if (item && JSON.parse(item)) {
      dispatch({ type: 'set', value: JSON.parse(item) });
    }
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [name]);

  const deleteValue = () => {
    try {
      // Save to storage
      if (typeof window !== 'undefined') {
        storage.removeItem(name);
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  const setState = (value) => {
    try {
      // Save state
      dispatch({ type: 'set', value });
      // Save to storage
      if (typeof window !== 'undefined') {
        storage.setItem(name, JSON.stringify(value));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  return [state, setState, deleteValue];
}

export function useLocalStorage<Type>(name: string, initialValue: Type) {
  return useStorage<Type>(name, initialValue, window.localStorage);
}

export function useSessionStorage<Type>(name: string, initialValue: Type) {
  return useStorage<Type>(name, initialValue, window.sessionStorage);
}
