import { FormAutocompleteProps } from 'library/CompositeComponents/form/FormAutocomplete';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { useSnackbars } from 'util/hooks/useSnackbars';

type ItemSelectDropdownProps = Omit<
  FormAutocompleteProps,
  'options' | 'translationKey' | 'translationOptions'
> & { label: string };

type ItemSelectPayload = [{ itemId: number; description: string }];

const ItemSelect = ({ ...props }: ItemSelectDropdownProps) => {
  const { onError } = useSnackbars();

  const itemData = useGet<ItemSelectPayload>('CableItemService', {
    operation: 5,
    options: {
      retry: 1,
    },
  });

  if (itemData.isLoading) {
    return <FormDropdown {...props} options={[]} />;
  }

  if (itemData.error) {
    onError(itemData.error.message);
    return <></>;
  }

  const options = itemData?.data?.map((option) => ({
    label: option.description,
    value: option.itemId,
    key: option.itemId.toString(),
  }));

  return <FormDropdown options={options} {...props} />;
};

export default ItemSelect;
