import { PictureInPictureAlt } from '@onc/icons';
import { IconButton } from 'base-components';

type PictureInPictureButtonProps = {
  onClick: () => void;
};

const PictureInPictureButton: React.FC<PictureInPictureButtonProps> = ({
  onClick,
}: PictureInPictureButtonProps) => (
  <IconButton
    color="inherit"
    onClick={onClick}
    aria-label="Toggle Picture-in-Picture"
  >
    <PictureInPictureAlt />
  </IconButton>
);

export default PictureInPictureButton;
