import { Component } from 'react';
import PropTypes from 'prop-types';

import { Clear } from '@onc/icons';
import { Dropdown, IconButton } from 'base-components';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import { get } from 'util/WebRequest';

class TaxonomySelect extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    name: PropTypes.string,
    title: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    helperText: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    name: undefined,
    title: undefined,
    onBlur: undefined,
    onChange: undefined,
    onClear: undefined,
    helperText: undefined,
    className: undefined,
    value: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { options: [] };
  }

  componentDidMount() {
    this.getTaxonomyOptions();
  }

  getTaxonomyOptions = () => {
    const { onError } = this.props;
    return get('internal/taxonomies')
      .then((response) => {
        const { data } = response;
        const options = data;
        const filteredData = data.filter(
          (option) => option.taxonomyName !== 'WoRDSS'
        );
        filteredData.forEach((option, index) => {
          const { taxonomyName, taxonomyId } = option;
          const taxonomyIdStr = taxonomyId.toString();
          options[index].key = `taxon-${taxonomyIdStr}-${taxonomyName}`;
          options[index].label = taxonomyName;
          options[index].value = taxonomyIdStr;
        });
        this.setState({ options });
      })
      .catch((error) => {
        onError(error);
      });
  };

  render() {
    const {
      name,
      title,
      onBlur,
      onChange,
      helperText,
      className,
      value,
      onClear,
      ...rest
    } = this.props;
    const { options } = this.state;
    return (
      <Dropdown
        options={options}
        name={name}
        title={title}
        onBlur={onBlur}
        onChange={onChange}
        helperText={helperText}
        className={className}
        label="Taxonomy"
        value={value}
        inputProps={{ 'aria-label': 'taxonomy selector' }}
        InputProps={{
          endAdornment: value ? (
            <IconButton style={{ marginRight: '15px' }} onClick={onClear}>
              <Clear color="error" />
            </IconButton>
          ) : null,
        }}
        {...rest}
      />
    );
  }
}

export default withSnackbars(TaxonomySelect);
