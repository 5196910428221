import { Component } from 'react';
import PropTypes, { number } from 'prop-types';
import { Grid, TextField, Typography } from 'base-components';
import DataSourceTreeData from './DataSourceTreeData';

class DataSourceTree extends Component {
  static propTypes = {
    selectedNodes: PropTypes.arrayOf(PropTypes.string),
    selectNode: PropTypes.func.isRequired,
    deviceCategories: PropTypes.arrayOf(number),
    sensorTypes: PropTypes.arrayOf(number),
    siteDevices: PropTypes.arrayOf(number),
    treeType: PropTypes.oneOf([
      'instrumentsByLocation',
      'propertiesByLocation',
      'locationTree',
    ]).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    editData: PropTypes.shape({}),
  };

  state = {
    filterText: '',
  };

  static defaultProps = {
    selectedNodes: [],
    deviceCategories: [],
    sensorTypes: [],
    startDate: undefined,
    endDate: undefined,
    siteDevices: undefined,
    data: undefined,
    editData: undefined,
  };

  render() {
    const {
      selectedNodes,
      data,
      deviceCategories,
      sensorTypes,
      selectNode,
      siteDevices,
      treeType,
      startDate,
      endDate,
      editData,
    } = this.props;
    const { filterText } = this.state;
    return (
      <Grid
        container
        spacing={1}
        wrap="nowrap"
        direction="column"
        alignItems="stretch"
        style={{ height: '100%' }}
      >
        <Grid item>
          <Typography variant="subtitle1">
            {treeType === 'locationTree' ? '' : 'Data Source Tree'}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            translationKey="common.search"
            name="searchInput"
            data-test="search-input"
            fullWidth
            onChange={(e) => this.setState({ filterText: e.target.value })}
            value={filterText}
          />
        </Grid>
        <Grid item xs style={{ overflowY: 'auto' }}>
          <DataSourceTreeData
            filterText={filterText}
            data={data}
            stillLoading={!data}
            deviceCategories={deviceCategories}
            sensorTypes={sensorTypes}
            selectNode={selectNode}
            siteDevices={siteDevices}
            selectedNodes={selectedNodes}
            treeType={treeType}
            startDate={startDate}
            endDate={endDate}
            editData={editData}
          />
        </Grid>
      </Grid>
    );
  }
}

export default DataSourceTree;
