import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Link, Typography } from 'base-components';
import LandingPageButtons from 'domain/AppComponents/landing-page/LandingPageButtons';
import SystemStatusMessageBanner from 'domain/AppComponents/system-status-message/SystemStatusMessageBanner';
import ONCLogo from 'domain/Apps/oceans-3-landing-page/images/ONC_Primary.jpg';

const STYLES = (theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary.dark,
      minHeight: 'calc(150px + 2.5vw)',
      width: '100%',
      zIndex: 0,
    },
    logoSpace: {
      backgroundColor: theme.palette.primary.main,
      height: 'calc(70px + 2.6vw)',
      width: 'calc(70px + 2.6vw)',
      left: '5%',
      zIndex: 2,
      position: 'absolute',
    },
    logo: {
      width: '100%',
      height: '100%',
    },
    logoLink: {
      width: '100%',
      height: '100%',
      zIndex: 10,
      position: 'absolute',
    },
    buttonHeader: {
      backgroundColor: theme.palette.primary.contrastText,
      width: '100%',
      height: '60px',
      position: 'absolute',
      zIndex: 1,
    },
    bannerTitle: {
      fontFamily: 'Rajdhani',
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      zIndex: 3,
      fontSize: 'calc(20px + 1.2vw)',
      lineHeight: '1.0',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    bannerInfoText: {
      fontSize: 'calc(15px + 0.5vw)',
      color: 'white',
      fontWeight: 500,
      zIndex: 3,
      lineHeight: '1.0',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '75%',
    },
    buttons: {
      right: theme.spacing(2),
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
      zIndex: 3,
    },
    bannerTitleDiv: {
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
      top: theme.spacing(10),
    },
  });

type LandingPageHeaderProps = WithStyles<typeof STYLES>;

const LandingPageHeader: React.FC<LandingPageHeaderProps> = ({ classes }) => (
  <div className={classes.header}>
    <SystemStatusMessageBanner />
    <div className={classes.logoSpace}>
      <Link className={classes.logoLink} href="https://www.oceannetworks.ca/" />
      <img src={ONCLogo} alt="" className={classes.logo} />
    </div>
    <div className={classes.buttonHeader}>
      <div className={classes.buttons}>
        <LandingPageButtons />
      </div>
    </div>
    <div className={classes.bannerTitleDiv}>
      <Typography classes={{ h3: classes.bannerTitle }} variant="h3">
        Oceans 3.0 Data Portal
      </Typography>
      <Typography classes={{ h5: classes.bannerInfoText }} variant="h5">
        Search, preview, download and visualize data from cabled observatories,
        mobile platforms and autonomous instruments.
      </Typography>
    </div>
  </div>
);

export default withStyles(STYLES)(LandingPageHeader);
