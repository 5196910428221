import { PureComponent } from 'react';
import { TableOld as Table, TableOldProps } from 'base-components';

type TableProps = TableOldProps & {
  defaultSorting?: any;
  pageSize?: number;
  totalRecords?: number;
  currentPage?: number;
  remotePaging?: boolean;
  classes?: any;
};

// @deprecated prefer stateless table
class SortableTable extends PureComponent<TableProps> {
  static defaultProps = {
    defaultSorting: [],
    pageSize: 0,
    totalRecords: 0,
    currentPage: undefined,
    remotePaging: false,
    classes: undefined,
  };

  render() {
    const { defaultSorting, pageSize, remotePaging, classes } = this.props;
    return (
      <Table
        sortable
        sort={defaultSorting}
        pageSize={pageSize}
        remotePaging={remotePaging}
        classes={classes}
        {...this.props}
      />
    );
  }
}

export default SortableTable;
