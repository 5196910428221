import { createContext } from 'react';
import { PublicCruiseJSON } from 'domain/services/CruiseService';
import { DiveDetailsJSON } from 'domain/services/DiveService';

export enum SeaTubeLogType {
  DIVE,
  EXPEDITION,
}

export interface SeaTubeLogContextProps {
  startDate: string;
  endDate: string;
  cruiseId: number;
  logType: SeaTubeLogType;
  dive?: DiveDetailsJSON;
  cruise?: PublicCruiseJSON;
}

const SeaTubeLogContext = createContext<SeaTubeLogContextProps>({
  startDate: '',
  endDate: '',
  cruiseId: -1,
  logType: undefined,
  dive: undefined,
  cruise: undefined,
});

export default SeaTubeLogContext;
