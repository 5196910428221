import { useState, useEffect } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles, createStyles } from '@mui/styles';
import { Autocomplete, Grid } from 'base-components';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import FormFieldValue from './FormFieldValue';
import FormFieldParser, { FormFieldType } from '../util/FormFieldParser';

const STYLES = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    deleteIcon: {
      marginLeft: theme.spacing(),
    },
  });

export type FormFieldLineValue = {
  fieldGroup?: { label: string; value: string };
  field?: { label: string; value: string };
  fieldValue?: any;
  fieldType?: FormFieldType;
};

type Props = {
  onChange: (index: number, e: FormFieldLineValue) => void;
  onRemove: (e: any) => void;
  value?: FormFieldLineValue;
  data?: any;
  index: number;
  classes: any;
  numLines: number;
};

const FormFieldLine: React.VFC<Props> = ({
  classes,
  value = undefined,
  data = [],
  index,
  numLines,
  onChange,
  onRemove,
}: Props) => {
  const [groupOptions, setGroupOptions] = useState<object[]>(
    FormFieldParser.getGroupOptions(data)
  );
  const [fieldOptions, setFieldOptions] = useState<object[]>(
    FormFieldParser.getFieldOptions(data)
  );

  useEffect(() => {
    let currentField: { label: string; value: string } | null = null;
    let currentFieldGroup: { label: string; value: string } | null = null;
    if (value && value.field) {
      currentField = value.field;
    }
    if (value && value.fieldGroup) {
      currentFieldGroup = value.fieldGroup;
    }
    setGroupOptions(FormFieldParser.getGroupOptions(data, currentField));
    setFieldOptions(FormFieldParser.getFieldOptions(data, currentFieldGroup));
  }, [data, value]);

  const handleChange = (field, val, fieldType) => {
    const updatedValue = { ...value };
    updatedValue[field] = val;
    if (fieldType) {
      updatedValue.fieldType = fieldType;
    }
    onChange(index, updatedValue);
  };

  const handleFieldChange = (val) => {
    const updatedValue = { ...value };
    updatedValue.field = val;
    if (val && val.value) {
      const { type } = data.get(val.value);
      // set the default selection to 'true' for booleans, undefined for other
      // input types
      if (type === 'boolean') {
        updatedValue.fieldValue = true;
        // the field type is normally set while setting the value: users won't typically have to do that since it's already true, so do it now
        updatedValue.fieldType = 'boolean';
      } else {
        updatedValue.fieldValue = undefined;
      }
    }

    onChange(index, updatedValue);
  };

  return (
    <div className={classes.flexContainer} data-test="fieldLine">
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <Autocomplete
            name="fieldGroup"
            getOptionLabel={(option) => (option ? option.label : undefined)}
            isOptionEqualToValue={(option, val) => option.value === val.value}
            translationKey="annotations.fieldGroup"
            options={groupOptions}
            onChange={(e) =>
              handleChange('fieldGroup', e.target.value, undefined)
            }
            value={value && value.fieldGroup ? value.fieldGroup : null}
            data-test="fieldGroup"
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            name="field"
            getOptionLabel={(option) => (option ? option.label : undefined)}
            isOptionEqualToValue={(option, val) => option.value === val.value}
            translationKey="annotations.field"
            options={fieldOptions}
            onChange={(e) => handleFieldChange(e.target.value)}
            value={value && value.field ? value.field : null}
            data-test="field"
          />
        </Grid>

        <Grid item xs={4}>
          <FormFieldValue
            data={data.get(
              value && value.field ? value.field.value : undefined
            )}
            onChange={(val, type) => handleChange('fieldValue', val, type)}
            value={value && value.fieldValue ? value.fieldValue : undefined}
            name="fieldValue"
            data-test="fieldValue"
            label="Value"
            disabled={false}
          />
        </Grid>
      </Grid>
      <DeleteIconButton
        onClick={() => onRemove(index)}
        disabled={numLines <= 1}
        className={classes.deleteIcon}
        data-test="deleteLine"
      />
    </div>
  );
};

export default withStyles(STYLES)(FormFieldLine);
