import RequestSupport from 'domain/Apps/support-request/RequestSupport';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class RequestSupportService {
  static get(params) {
    return get('SupportRequestService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getAllRequestSupports(): Promise<RequestSupport[]> {
    return RequestSupportService.get({ operation: 5 });
  }
}
export default RequestSupportService;
