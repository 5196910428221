import { useContext } from 'react';
import * as React from 'react';

import {
  PLOT_DATA_PRODUCT,
  XML_DATA_PRODUCT,
  COR_AND_CSV_DATA_PRODUCT,
  ANNOTATION_DATA_PRODUCT,
  NOT_DOWNLOADED,
  UNASSIGNED_CASTS,
  COMPLETED,
} from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchConstants';
import CastDataContext from 'domain/Apps/geospatial-search/CastDataContext';
import SiteDeviceSubsetDownloadTableDialog from 'domain/Apps/geospatial-search/community-fisher/SiteDeviceSubsetDownloadTableDialog';
import SelectedStationsContext from 'domain/Apps/geospatial-search/SelectedStationsContext';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { SiteDeviceSubset } from '../definitions/GeospatialSearchTypes';

interface Props {
  onCloseClick: () => void;
  filterCasts: (input: SiteDeviceSubset[]) => SiteDeviceSubset[];
  childCheckBoxOnChange: (input: string) => void;
}

const LassoSelectionTable: React.VFC<Props> = ({
  onCloseClick,
  filterCasts,
  childCheckBoxOnChange,
}: Props) => {
  const { updateDate, downloadedCasts } = useContext(CastDataContext);
  const { selectedStations } = useContext(SelectedStationsContext);
  const { onInfo, onError } = useSnackbars();

  const addProductsToCast = (subset) => {
    // check in downloadedCasts to see if cast has been previously downloaded,
    // if so use previous products
    const castFromListOfDownloadedCasts = downloadedCasts.find(
      (downloadedSubset) =>
        downloadedSubset.siteDeviceSubsetId === subset.siteDeviceSubsetId
    );
    if (castFromListOfDownloadedCasts) {
      return castFromListOfDownloadedCasts.productsForCast;
    }
    // not downloaded before, create initial state of products
    const productsForCast = [
      {
        productName: COR_AND_CSV_DATA_PRODUCT,
        isSelected: true,
        status: NOT_DOWNLOADED,
        productType: 'COR Data File',
        extension: 'cor',
      },
      {
        productName: PLOT_DATA_PRODUCT,
        isSelected: true,
        status: NOT_DOWNLOADED,
        productType: 'Profile Plot',
        extension: 'png',
      },
      {
        productName: ANNOTATION_DATA_PRODUCT,
        isSelected: true,
        status: NOT_DOWNLOADED,
        productType: 'Annotation File',
        extension: 'csv',
      },
      {
        productName: COR_AND_CSV_DATA_PRODUCT,
        isSelected: true,
        status: NOT_DOWNLOADED,
        productType: 'CSV Data File',
        extension: 'csv',
      },
    ];

    if (subset.isAssigned) {
      productsForCast.push({
        productName: XML_DATA_PRODUCT,
        isSelected: true,
        status: NOT_DOWNLOADED,
        productType: 'Meta Data File',
        extension: 'xml',
      });
    }
    return productsForCast;
  };

  const areAllProductsDownloadedForCast = (subset) => {
    const castFromListOfDownloadedCasts = downloadedCasts.find(
      (downloadedSubset) =>
        downloadedSubset.siteDeviceSubsetId === subset.siteDeviceSubsetId
    );
    if (!castFromListOfDownloadedCasts) {
      return false;
    }
    return castFromListOfDownloadedCasts.productsForCast.every(
      (product) => product.status === COMPLETED
    );
  };

  const updateSiteDeviceSubsetDownloadTable = () => {
    // from the selected stations, remove all the date filtered casts from seleced stations
    // and remove all all the non-checkboxed casts
    const selectedStationswithFilteredCasts = selectedStations.map(
      ({ siteDeviceSubsets, ...rest }) => ({
        ...rest,
        siteDeviceSubsets: filterCasts(siteDeviceSubsets).filter(
          (siteDeviceSubset) => siteDeviceSubset.checkboxSelected
        ),
      })
    );

    // Gives each siteDeviceSubset the location code property from the station along with a boolean isAssigned
    const selectedStationAndSubsets = selectedStationswithFilteredCasts
      .filter((selectedStation) => selectedStation)
      .map((selectedStation) => ({
        ...selectedStation,
        siteDeviceSubsets: selectedStation.siteDeviceSubsets.map((subset) => ({
          ...subset,
          code: selectedStation.code,
          isAssigned: selectedStation.name !== UNASSIGNED_CASTS,
        })),
      }));

    // flattens the array
    const subsetsInLassoSelection = selectedStationAndSubsets
      .map((selectionStation) => [...selectionStation.siteDeviceSubsets])
      .reduce(
        (previousValue, currentValue) => previousValue.concat(currentValue),
        []
      );

    // removes any casts that currently have all their products downloaded (no need to suggest downloading again)
    const selectedSubsetsForDownload = subsetsInLassoSelection.filter(
      (subset) => !areAllProductsDownloadedForCast(subset)
    );

    // Adds product to each cast and initial status
    const siteDeviceSubsetDownloadTableRows = selectedSubsetsForDownload.map(
      (subset) => ({
        ...subset,
        status: NOT_DOWNLOADED,
        productsForCast: addProductsToCast(subset),
      })
    );
    return siteDeviceSubsetDownloadTableRows;
  };

  return (
    <SiteDeviceSubsetDownloadTableDialog
      onInfo={onInfo}
      onError={onError}
      siteDeviceSubsetRows={updateSiteDeviceSubsetDownloadTable()}
      onCloseClick={onCloseClick}
      key={updateDate}
      childCheckBoxOnChange={childCheckBoxOnChange}
    />
  );
};

export default LassoSelectionTable;
