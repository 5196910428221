import { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
} from 'base-components';
import PlaylistsDropdown from 'domain/AppComponents/dropdowns/PlaylistsDropdown';
import PlaylistService from 'domain/services/PlaylistService';

import FormTextField from 'library/CompositeComponents/form/FormTextField';
import DialogForm from './DialogForm';
import PlaylistLineService from '../../services/PlaylistLineService';

const NEW_STATE = 'new';
const EXISTING_STATE = 'existing';
const INITIAL_STATE = {
  contentState: undefined,
  numberOfPlaylists: 0,
};

/**
 * SavePlaylistDialog component that lets user choose which PlaylistHdr to save
 * their PlaylistLine(s) to.
 */
class SavePlaylistDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    playlistLines: PropTypes.arrayOf(
      PropTypes.shape({
        playlistLineName: PropTypes.string,
        resourceId: PropTypes.number.isRequired,
        resourceTypeId: PropTypes.number.isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
        endDate: PropTypes.instanceOf(Date).isRequired,
      })
    ),
    onExited: PropTypes.func,
    onSave: PropTypes.func,
    onInfo: PropTypes.func,
    onError: PropTypes.func,
    playlistName: PropTypes.string,
    playlistDescription: PropTypes.string,
    isCloneAction: PropTypes.bool,
    excludePlaylistHdrId: PropTypes.number,
  };

  static defaultProps = {
    playlistLines: null,
    onExited: undefined,
    onSave: undefined,
    onInfo: undefined,
    onError: undefined,
    playlistName: undefined,
    playlistDescription: undefined,
    isCloneAction: false,
    excludePlaylistHdrId: undefined,
  };

  state = INITIAL_STATE;

  componentDidUpdate(prevProps) {
    const { open, isCloneAction } = this.props;

    if (open && prevProps.open !== open) {
      if (isCloneAction) {
        this.handleNewPlaylistClicked();
      }
      this.getNumberOfPlaylists();
    }
  }

  getNumberOfPlaylists = () => {
    const { onError, excludePlaylistHdrId } = this.props;
    return PlaylistService.getPlaylists()
      .then((payload) => {
        const filteredPayload = payload.filter(
          (playlist) => playlist.playlistHdrId !== excludePlaylistHdrId
        );
        if (filteredPayload && filteredPayload.length > 0) {
          this.setState({
            numberOfPlaylists: filteredPayload.length,
          });
        }
      })
      .catch(() => {
        this.setState(
          {
            numberOfPlaylists: 0,
          },
          () => {
            if (onError) {
              onError('Unable to retrieve playlists');
            }
          }
        );
      });
  };

  createNewPlaylist = async (values) => {
    const { playlistLines, onError } = this.props;

    try {
      const payload = await PlaylistService.createNewPlaylist({
        playlistHdrName: values.playlistHdrName,
        playlistHdrDescription: values.playlistHdrDescription,
        isPublic: false,
      });
      await PlaylistLineService.create(
        payload.playlistHdrId,
        playlistLines.map((line) => ({
          ...line,
          resourceTypeId: line.resource?.resourceTypeId || line.resourceTypeId,
          resourceId: line.resource?.resourceId || line.resourceId,
          dateFrom: line.dateFrom || line.startDate,
          dateTo: line.dateTo || line.endDate,
        }))
      );
      this.handleSaveSuccess(`Created playlist ${payload.playlistHdrName}.`);
    } catch (e) {
      onError(e.message);
    }
  };

  addToExistingPlaylist = (values) => {
    const { playlistLines, onError } = this.props;

    return PlaylistLineService.create(
      values.playlistHdrId.key,
      playlistLines.map((line) => ({
        ...line,
        startDate: line.dateFrom || line.startDate,
        endDate: line.dateTo || line.endDate,
      }))
    )
      .then(() => {
        this.handleSaveSuccess(`Clip successfully added to playlist.`);
      })
      .catch((error) => {
        if (onError) {
          onError(error.message);
        }
      });
  };

  saveAndClose = () => {
    const { onClose, onSave } = this.props;
    if (onSave) {
      onSave();
    }
    onClose();
  };

  handleSaveSuccess = (message) => {
    const { onInfo } = this.props;
    if (onInfo) {
      onInfo(message);
    }
    this.saveAndClose();
  };

  handleExited = () => {
    const { onExited } = this.props;
    this.setState(INITIAL_STATE, onExited);
  };

  handleNewPlaylistClicked = () => {
    this.setState({
      contentState: NEW_STATE,
    });
  };

  handleExistingPlaylistClicked = () => {
    this.setState({
      contentState: EXISTING_STATE,
    });
  };

  handleCancel = () => {
    const { isCloneAction, onClose } = this.props;
    if (isCloneAction) {
      onClose();
    } else {
      this.setState({
        contentState: undefined,
        existingPlaylistName: '',
      });
    }
  };

  renderExistingPlaylistOption = () => {
    const { numberOfPlaylists } = this.state;
    if (numberOfPlaylists > 0) {
      return (
        <ListItem button onClick={this.handleExistingPlaylistClicked}>
          <ListItemText>Existing Playlist</ListItemText>
        </ListItem>
      );
    }
    return null;
  };

  renderSelectOptions = () => (
    <>
      <DialogTitle>Add to Playlist</DialogTitle>
      <DialogContent>
        <ListItem button onClick={this.handleNewPlaylistClicked}>
          <ListItemText>New Playlist</ListItemText>
        </ListItem>
        {this.renderExistingPlaylistOption()}
      </DialogContent>
    </>
  );

  renderNewPlaylist = () => {
    const { playlistName, playlistDescription } = this.props;

    const defaultValues = {
      playlistHdrName: playlistName ? `(Copy of) ${playlistName}` : undefined,
      playlistHdrDescription: playlistDescription,
    };

    return (
      <>
        <DialogTitle>Add to New Playlist</DialogTitle>
        <DialogForm
          primaryButtonTranslationKey="common.buttons.create"
          onSubmit={this.createNewPlaylist}
          onCancel={this.handleCancel}
          defaultValues={defaultValues}
        >
          <FormTextField
            name="playlistHdrName"
            translationKey="seatube.playlistName"
            fullWidth
            rules={{ required: 'A playlist name is required' }}
            defaultValue={
              playlistName ? `(Copy of) ${playlistName}` : undefined
            }
          />
          <FormTextField
            name="playlistHdrDescription"
            translationKey="seatube.playlistDescription"
            fullWidth
            multiline
            minRows={3}
            defaultValue={playlistDescription}
          />
        </DialogForm>
      </>
    );
  };

  renderExistingPlaylist = () => {
    const { excludePlaylistHdrId } = this.props;

    return (
      <>
        <DialogTitle>Add to Existing Playlist</DialogTitle>
        <DialogForm
          primaryButtonTranslationKey="common.buttons.add"
          onSubmit={this.addToExistingPlaylist}
          onCancel={this.handleCancel}
        >
          <DialogContentText>
            Note: Locked Playlists will not appear below
          </DialogContentText>
          <PlaylistsDropdown
            fullWidth
            excludePlaylistHdrId={excludePlaylistHdrId}
          />
        </DialogForm>
      </>
    );
  };

  renderDialogContent = () => {
    const { contentState } = this.state;
    switch (contentState) {
      case NEW_STATE:
        return this.renderNewPlaylist();
      case EXISTING_STATE:
        return this.renderExistingPlaylist();
      default:
        return this.renderSelectOptions();
    }
  };

  render() {
    const { playlistLines, onExited, onInfo, onError, onSave, ...dialogProps } =
      this.props;
    return (
      <Dialog
        className="onc-panel-content"
        TransitionProps={{ onExited: this.handleExited }}
        {...dialogProps}
      >
        {this.renderDialogContent()}
      </Dialog>
    );
  }
}

export default SavePlaylistDialog;
