class LocalStorageUtil {
  static getItem = (name: string, defaultValue: any) => {
    const item = window.localStorage.getItem(name);
    if (item && JSON.parse(item)) {
      return JSON.parse(item);
    }
    return defaultValue;
  };

  static setItem = (name: string, value: any) => {
    window.localStorage.setItem(name, JSON.stringify(value));
  };
}

export default LocalStorageUtil;
