import { CableType } from 'domain/services/CableTypeService';

export interface CableTypeRow {
  cableTypeId: number;
  cableTypeName: string;
  description: string;
  modifyBy: string;
  modifyDate: string;
}

const formatModifyDate = (modifyDate: string) => {
  const strArr = modifyDate.split('T');
  strArr[1] = strArr[1].substring(0, 8);
  return `${strArr[0]} ${strArr[1]}`;
};

export const transformCableTypeRows = (
  cableTypes: CableType[]
): CableTypeRow[] =>
  cableTypes.map((cableType) => ({
    cableTypeId: cableType.cableTypeId,
    cableTypeName: cableType.name,
    description: cableType.description,
    modifyBy: `${cableType.modifyBy.firstname} ${cableType.modifyBy.lastname}`,
    modifyDate: formatModifyDate(cableType.modifyDate),
  }));

export const transformRowToSyncForm = (row: CableTypeRow): CableType => ({
  cableTypeId: row.cableTypeId,
  name: row.cableTypeName,
  description: row.description,
});
