import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import NamePlateMaintenanceRO from 'domain/AppComponents/device-nameplate-maintenance/NamePlateMaintenanceRO';
import NamePlateMaintenanceRW from 'domain/AppComponents/device-nameplate-maintenance/NamePlateMaintenanceRW';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(2),
  },
  panelDiv: {
    margin: theme.spacing(1),
  },
});

const defaultState = {};

class NamePlateMaintenance extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
      panelDiv: PropTypes.string,
      roDivider: PropTypes.string,
    }).isRequired,
    namePlateId: PropTypes.number,
    deviceId: PropTypes.number,
    history: PropTypes.shape().isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    namePlateId: undefined,
    deviceId: undefined,
  };

  constructor(props) {
    super(props);
    const { namePlateId, deviceId } = this.props;
    this.state = {
      namePlateId,
      deviceId,
      ...defaultState,
    };
  }

  render() {
    const { namePlateId, deviceId, onError, history, classes } = this.props;

    if (Environment.getDmasUserPrivilege() === 'RW') {
      return (
        <div className={classes.panelDiv}>
          <NamePlateMaintenanceRW
            namePlateId={namePlateId}
            deviceId={deviceId}
            history={history}
          />
        </div>
      );
    }
    return (
      <div className={classes.panelDiv}>
        <NamePlateMaintenanceRO
          namePlateId={namePlateId}
          deviceId={deviceId}
          history={history}
          onError={onError}
        />
      </div>
    );
  }
}
export default withStyles(STYLES)(withSnackbars(NamePlateMaintenance));
