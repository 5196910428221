import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Loading } from '@onc/composite-components';
import { Box, Divider, ErrorPage, Grid, Typography } from 'base-components';
import {
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';

import useGet from 'util/hooks/useDmasAPI/useGet';
import { ADCPNortekPresets, ADCPRDIPresets } from './ADCPToolbox';
import {
  INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK,
  INDICES_OF_DATA_PRODUCT_OPTIONS_RDI,
  RESOURCE_ID_FOR_NORTEK_ADCP_SEARCH_OPTIONS,
  RESOURCE_ID_FOR_RDI_ADCP_SEARCH_OPTIONS,
} from './util/DataPlayerConstants';

const classes = {
  root: {
    padding: 0,
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
  },
  dropdownPadding: {
    paddingTop: 1,
    paddingBottom: 1,
    minWidth: 35,
  },
};

type FormServicePayload = {
  0: [
    {
      fields: [
        {
          choices: [
            {
              label: string;
              value: string;
            },
          ];
          label: string;
        },
      ];
    },
  ];
};

export type ADCPToolboxFormType = {
  velocityBinMapping: string;
  horizontalCurrentPlotLimits: string;
  verticalCurrentPlotLimits: string;
  backscatterLowerPlotLimits: string;
  backscatterUpperPlotLimits: string;
  backscatterColourMap: string;
  threeBeamSolution: string;
  lowCorrelationScreenThreshold: string;
  errCorrelationScreenThreshold: string;
  falseTargetMaxThreshold: string;
};

type ADCPToolboxFormProps = {
  onADCPFilterSubmit: (values: ADCPToolboxFormType) => void;
  adcpNortekPresetOptions?: ADCPNortekPresets;
  adcpRdiPresetOptions?: ADCPRDIPresets;
  resourceId?: number;
  isRdi: boolean;
};

const ADCPToolboxForm = ({
  onADCPFilterSubmit,
  adcpNortekPresetOptions = undefined,
  adcpRdiPresetOptions = undefined,
  resourceId = undefined,
  isRdi,
}: ADCPToolboxFormProps) => {
  const [renderedOnce, setRenderedOnce] = useState<boolean>(false);

  const formMethods = useForm<ADCPToolboxFormType>({ mode: 'onBlur' });

  const adcpFormData = useGet<
    FormServicePayload,
    {
      formTypeId: number;
      resourceTypeId: number;
      sourceId: number;
      resourceId: number;
    }
  >(
    'FormService',
    {
      operation: undefined,
    },
    {
      formTypeId: 2,
      resourceTypeId: 1500,
      sourceId: 3,
      resourceId:
        resourceId ||
        (isRdi
          ? RESOURCE_ID_FOR_RDI_ADCP_SEARCH_OPTIONS
          : RESOURCE_ID_FOR_NORTEK_ADCP_SEARCH_OPTIONS),
    }
  );

  if (adcpFormData.isLoading) {
    return <Loading />;
  }

  if (adcpFormData.error) {
    return <ErrorPage />;
  }

  const fieldData = adcpFormData.data?.[0]?.[0]?.fields;
  const optionsIndexObjectToUseThisDevice = isRdi
    ? INDICES_OF_DATA_PRODUCT_OPTIONS_RDI
    : INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK;

  const velocityBinMappingDropdown =
    fieldData[optionsIndexObjectToUseThisDevice.VELOCITY_BIN_MAPPING_IDX];
  const horizontalCurrentPlotLimitsDropdown =
    fieldData[optionsIndexObjectToUseThisDevice.HORIZONTAL_CURRENT_LIMITS_IDX];
  const verticalCurrentPlotLimitsDropdown =
    fieldData[optionsIndexObjectToUseThisDevice.VERTICAL_CURRENT_LIMITS_IDX];
  const backscatterLowerPlotLimitsDropdown =
    fieldData[optionsIndexObjectToUseThisDevice.LOWER_BACKSCATTER_LIMIT_IDX];
  const backscatterUpperPlotLimitsDropdown =
    fieldData[optionsIndexObjectToUseThisDevice.UPPER_BACKSCATTER_LIMIT_IDX];
  const backscatterColourMapDropdown =
    fieldData[optionsIndexObjectToUseThisDevice.BACKSCATTER_COLOUR_IDX];
  const threeBeamSolutionDropdown =
    fieldData[optionsIndexObjectToUseThisDevice.THREE_BEAM_SOLUTIONS_IDX];
  const lowCorrelationScreenThresholdDropdown =
    fieldData[
      optionsIndexObjectToUseThisDevice.LOW_CORRELATION_SCREEN_THRESHOLD_IDX
    ];
  const errCorrelationScreenThresholdDropdown =
    fieldData[
      optionsIndexObjectToUseThisDevice.ERR_CORRELATION_SCREEN_THRESHOLD_IDX
    ];
  const falseTargetMaxThresholdDropdown =
    fieldData[optionsIndexObjectToUseThisDevice.FALSE_TARGET_THRESHOLD_MAX_IDX];

  const renderDropdownFromDropdownObject = (dropdownObject, name) => (
    <FormDropdown
      name={name}
      label={dropdownObject?.label}
      options={dropdownObject?.choices?.map((choice) => ({
        label: choice?.label,
        value: choice?.value,
        key: choice?.value,
      }))}
      sx={classes.dropdownPadding}
      fullWidth
    />
  );

  const renderDropdowns = () => (
    <>
      {renderDropdownFromDropdownObject(
        velocityBinMappingDropdown,
        'velocityBinMapping'
      )}
      {renderDropdownFromDropdownObject(
        horizontalCurrentPlotLimitsDropdown,
        'horizontalCurrentPlotLimits'
      )}
      {renderDropdownFromDropdownObject(
        verticalCurrentPlotLimitsDropdown,
        'verticalCurrentPlotLimits'
      )}
      {renderDropdownFromDropdownObject(
        backscatterLowerPlotLimitsDropdown,
        'backscatterLowerPlotLimits'
      )}
      {renderDropdownFromDropdownObject(
        backscatterUpperPlotLimitsDropdown,
        'backscatterUpperPlotLimits'
      )}
      {renderDropdownFromDropdownObject(
        backscatterColourMapDropdown,
        'backscatterColourMap'
      )}
      {/** These fields are only rendered if device is RDI */}
      {isRdi ? (
        renderDropdownFromDropdownObject(
          threeBeamSolutionDropdown,
          'threeBeamSolution'
        )
      ) : (
        <></>
      )}
      {isRdi ? (
        renderDropdownFromDropdownObject(
          lowCorrelationScreenThresholdDropdown,
          'lowCorrelationScreenThreshold'
        )
      ) : (
        <></>
      )}
      {isRdi ? (
        renderDropdownFromDropdownObject(
          errCorrelationScreenThresholdDropdown,
          'errCorrelationScreenThreshold'
        )
      ) : (
        <></>
      )}
      {isRdi ? (
        renderDropdownFromDropdownObject(
          falseTargetMaxThresholdDropdown,
          'falseTargetMaxThreshold'
        )
      ) : (
        <></>
      )}
    </>
  );

  /**
   * UseForm needs to be called non-conditionally, but values are undefined
   * while useGet is querying, so we reset and re-render after useGets are done
   */
  if (!renderedOnce) {
    const adcpPresetOptions = (
      isRdi ? adcpRdiPresetOptions : adcpNortekPresetOptions
    ) as ADCPRDIPresets; // Compiler errors if type isn't overridden

    formMethods.reset({
      velocityBinMapping:
        adcpPresetOptions?.velocityBinMapping ||
        velocityBinMappingDropdown?.choices?.[0]?.value,
      horizontalCurrentPlotLimits:
        adcpPresetOptions?.horizontalCurrentPlotLimits ||
        horizontalCurrentPlotLimitsDropdown?.choices?.[0]?.value,
      verticalCurrentPlotLimits:
        adcpPresetOptions?.verticalCurrentPlotLimits ||
        verticalCurrentPlotLimitsDropdown?.choices?.[0]?.value,
      backscatterLowerPlotLimits:
        adcpPresetOptions?.backscatterLowerPlotLimits ||
        backscatterLowerPlotLimitsDropdown?.choices?.[0]?.value,
      backscatterUpperPlotLimits:
        adcpPresetOptions?.backscatterUpperPlotLimits ||
        backscatterUpperPlotLimitsDropdown?.choices?.[0]?.value,
      backscatterColourMap:
        adcpPresetOptions?.backscatterColourMap ||
        backscatterColourMapDropdown?.choices?.[0]?.value,
      threeBeamSolution:
        adcpPresetOptions?.threeBeamSolution ||
        threeBeamSolutionDropdown?.choices?.[0]?.value,
      lowCorrelationScreenThreshold:
        adcpPresetOptions?.lowCorrelationScreenThreshold ||
        lowCorrelationScreenThresholdDropdown?.choices?.[0]?.value,
      errCorrelationScreenThreshold:
        adcpPresetOptions?.errCorrelationScreenThreshold ||
        errCorrelationScreenThresholdDropdown?.choices?.[0]?.value,
      falseTargetMaxThreshold:
        adcpPresetOptions?.falseTargetMaxThreshold ||
        falseTargetMaxThresholdDropdown?.choices?.[0]?.value,
    });
    setRenderedOnce(true);
  }

  return (
    <Box sx={classes.root}>
      <Typography variant="subtitle1" color="inherit">
        Data Product Options
      </Typography>
      <Divider />
      <Form onSubmit={onADCPFilterSubmit} formMethods={formMethods}>
        <Grid container direction="row">
          <Grid item xs={12}>
            {renderDropdowns()}
          </Grid>
        </Grid>
        <Box sx={classes.formButtons}>
          <ContainedButton
            translationKey="common.buttons.apply"
            type="submit"
          />
          <TextButton
            translationKey="common.buttons.reset"
            onClick={() => {
              formMethods.reset();
            }}
          />
        </Box>
      </Form>
    </Box>
  );
};

export default ADCPToolboxForm;
