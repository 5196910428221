import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Divider, Grid, Typography } from 'base-components';
import ErddapDatasetLinks from 'domain/AppComponents/datasets/ErddapDatasetLinks';
import SiteDeviceDOIDatasetLinks from 'domain/AppComponents/datasets/SiteDeviceDOIDatasetLinks';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import SiteDeviceSubsetTable from 'domain/Apps/site-device-maintenance/SiteDeviceSubsetTable';
import SiteDeviceService from 'domain/services/SiteDeviceService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateUtils from 'util/DateUtils';
import { handleRedirect } from './RedirectLink';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(1),
  },
});

const defaultState = {
  siteName: undefined,
  dateFrom: DateUtils.formatDateAsString(new Date()),
  comment: undefined,
  offsetLongitude: undefined,
  offsetPitch: undefined,
  offsetRoll: undefined,
  offsetLatitude: undefined,
  offsetDepth: undefined,
  offsetHeading: undefined,
  modifyBy: undefined,
  modifyDate: undefined,
};

class SiteDeviceMaintenanceRO extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
      roDivider: PropTypes.string,
    }).isRequired,
    siteDeviceId: PropTypes.number,
    siteId: PropTypes.number,
    deviceId: PropTypes.number,
    isFromSite: PropTypes.bool,
    onError: PropTypes.func.isRequired,
  };

  renderOptionalField = (label, value, title) => {
    if (value !== undefined && value !== '') {
      return (
        <MarginedReadOnlyField
          labelText={label}
          valueText={value}
          title={title}
        />
      );
    }
    return null;
  };

  static defaultProps = {
    siteDeviceId: undefined,
    deviceId: undefined,
    siteId: undefined,
    isFromSite: false,
  };

  constructor(props) {
    super(props);
    const { siteDeviceId, deviceId, siteId } = this.props;
    this.state = {
      siteDeviceId,
      deviceId,
      siteId,
      ...defaultState,
    };
  }

  componentDidMount() {
    this.getSiteDeviceService();
  }

  getSiteDeviceService = () => {
    const { onError } = this.props;
    const { siteDeviceId } = this.state;

    if (!siteDeviceId || isNaN(siteDeviceId)) return;

    SiteDeviceService.getOne(siteDeviceId)
      .then((payload) => {
        const { dateFrom, offsetLat, offsetLong, ...rest } = payload;
        this.setState({
          dateFrom: DateUtils.formatDateAsString(new Date(payload.dateFrom)),
          offsetLatitude: payload.offsetLat,
          offsetLongitude: payload.offsetLong,
          ...rest,
        });
      })
      .catch((e) => {
        onError(e.message);
        this.setState(defaultState);
      });
  };

  renderOptionalFields = () => {
    const {
      comment,
      offsetRoll,
      offsetPitch,
      offsetHeading,
      offsetLatitude,
      offsetLongitude,
      offsetDepth,
    } = this.state;
    return (
      <>
        {this.renderOptionalField('Comment', comment, 'commentField')}
        {this.renderOptionalField(
          'Offset Latitude (Degrees)',
          offsetLatitude,
          'latitudeField'
        )}
        {this.renderOptionalField(
          'Offset Longitude (Degrees)',
          offsetLongitude,
          'longitudeField'
        )}
        {this.renderOptionalField(
          'Offset Depth (Meters)',
          offsetDepth,
          'depthField'
        )}
        {this.renderOptionalField(
          'Offset Heading (Degrees)',
          offsetHeading,
          'headingField'
        )}
        {this.renderOptionalField(
          'Offset Pitch (Degrees)',
          offsetPitch,
          'pitchField'
        )}
        {this.renderOptionalField(
          'Offset Roll (Degrees)',
          offsetRoll,
          'rollField'
        )}
      </>
    );
  };

  render() {
    const { classes, isFromSite } = this.props;
    const {
      siteDeviceId,
      deviceId,
      siteId,
      siteName,
      dateFrom,
      modifyBy,
      modifyDate,
    } = this.state;

    let siteDeviceText = siteDeviceId;
    let color;
    if (isNaN(siteDeviceId)) {
      color = 'textSecondary';
      siteDeviceText = 'New';
    }
    return (
      // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
      <div>
        <Panel
          title={
            <>
              <Typography variant="body1">Site Device Entry ID:</Typography>
              <Typography variant="body1" color={color}>
                {siteDeviceText}
              </Typography>
            </>
          }
        >
          <Grid
            container
            direction="row"
            className={classes.entryFormContainer}
          >
            <Grid item xs={6}>
              <MarginedReadOnlyField
                labelText="Device Id"
                valueText={deviceId.toString()}
                title="deviceIdTextField"
              />
              <MarginedReadOnlyField
                labelText="Site Name"
                valueText={siteName}
                title="siteNameTextField"
              />
              <MarginedReadOnlyField
                labelText="Start Date"
                valueText={dateFrom}
                title="startDateTextField"
              />
              {this.renderOptionalFields()}
              <MarginedReadOnlyField
                labelText="Modified By"
                valueText={modifyBy}
                title="modifyByField"
              />
              <MarginedReadOnlyField
                labelText="Modified Date"
                valueText={modifyDate}
                title="modifyDateField"
              />
              <SiteDeviceDOIDatasetLinks siteDeviceId={siteDeviceId} />
              <ErddapDatasetLinks siteDeviceId={siteDeviceId} />
            </Grid>
          </Grid>
          <div className={classes.formButtons}>
            <ContainedButton
              translationKey="common.buttons.done"
              onClick={handleRedirect({ isFromSite, siteId, deviceId })}
            />
          </div>
          <Divider variant="middle" className={classes.roDivider} />
          <SiteDeviceSubsetTable siteDeviceId={siteDeviceId} />
        </Panel>
      </div>
    );
  }
}
export default withStyles(STYLES)(withSnackbars(SiteDeviceMaintenanceRO));
