import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class TMTaskService {
  static getTask(taskId) {
    return get('TMTaskService', {
      taskId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getUserPrivilege(taskDefinitionId) {
    return get('TMTaskService', {
      operation: 4,
      taskDefinitionId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getPamguardBatchMonitoringPrivilege(taskDefinitionId) {
    return get('TMTaskService', {
      operation: 5,
      taskDefinitionId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getLatestRunByTaskDefinitionId(taskDefinitionId) {
    return get('TMTaskService', {
      operation: 1,
      taskDefinitionId,
    });
  }

  static getAllRunsByTaskDefinitionId(taskDefinitionId) {
    return get('TMTaskService', {
      operation: 2,
      taskDefinitionId,
    });
  }

  static createBatch(input) {
    return post('TMTaskService', {
      operation: 1,
      createOperation: 1,
      input,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static createTaskForBatch(parentTaskId, taskDefinitionId, input) {
    return post('TMTaskService', {
      operation: 1,
      createOperation: 2,
      parentTaskId,
      taskDefinitionId,
      input,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static createDOITask(batchId, input) {
    return post('TMTaskService', {
      operation: 1,
      createOperation: 3,
      batchId,
      input,
    });
  }

  static createPamguardBatchTask(taskDefinitionId, input, taskInput) {
    return post('TMTaskService', {
      operation: 1,
      createOperation: 4,
      taskDefinitionId,
      input,
      taskInput,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static updateTaskInput(taskId, input) {
    return post('TMTaskService', {
      operation: 2,
      updateOperation: 1,
      taskId,
      input,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static detachTask(taskId) {
    return post('TMTaskService', {
      operation: 2,
      updateOperation: 2,
      taskId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static delete(taskId) {
    return post('TMTaskService', {
      operation: 3,
      taskId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static updateTaskSequenceNumber(taskId, newSequenceNumber) {
    return post('TMTaskService', {
      operation: 2,
      updateOperation: 3,
      taskId,
      newSequenceNumber,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static updateTaskAutorun(taskId, newAutorun) {
    return post('TMTaskService', {
      operation: 2,
      updateOperation: 4,
      taskId,
      autorun: newAutorun,
    }).then((response) => parseDmasAPIResponse(response));
  }
}

export default TMTaskService;
