import {
  cloneElement,
  Children,
  isValidElement,
  Component,
  ReactElement,
} from 'react';
import { MoreVert } from '@onc/icons';

import { IconButton, Menu } from 'base-components';

const moreVertIcon = () => <MoreVert />;

interface PopoverOrigin {
  /** Vertical attachment. Can only be 'top' | 'center' | 'bottom' */
  vertical: 'top' | 'center' | 'bottom';
  /** Horizontal attachment. Can only be 'left' | 'right' | 'center' */
  horizontal: 'left' | 'right' | 'center';
}

type AnchoredHiddenMenuProps = {
  /** @deprecated Prop forwarded to Menu to be used in popovers. */
  getContentAnchorEl?: boolean;
  /**
   * The point at which the popover will attach to the anchor's origin. See
   * https://mui.com/material-ui/api/popover/
   */
  transformOrigin?: PopoverOrigin;
  /**
   * The point where the popover's achorEl will attach to. See
   * https://mui.com/material-ui/api/popover/
   */
  anchorOrigin?: PopoverOrigin;
  /**
   * A custom variant allows the customAnchor prop to be used to change the
   * anchor. iconButton will only allow an icon button as the anchor.
   */
  variant?: 'custom' | 'iconButton';

  /** A custom anchor function */
  customAnchor?: any;

  /** The icon to use as the anchor for the menu */
  icon?: any;

  /** React.Children. Should be some type of material Menu or similar */
  children?: any;

  /**
   * Use container when you want the AnchoredHiddenMenu to render to a specific
   * div, and not just document.body. This is useful for things like a
   * fullscreen video and a fullscreen map, otherwise the menu is hidden from
   * view when fullscreen.
   */
  container?: any;

  /** Callback for when the anchor element changes */
  anchorElChanged?: (e: any) => void;
  /** Material UI class name to style icons */
  iconClasses?: string;
  /** Material UI class name to style menus */
  menuClasses?: string;
  /** Whether or not the menu should close on click away or not */
  closeOnClick?: boolean;
  /** Label of the button */
  buttonLabel?: string;
  /** Custom tooltip if needed */
  tooltip?: string;
  /** Custom tooltip if needed */
  tooltipPosition?:
    | 'top'
    | 'left'
    | 'bottom'
    | 'right'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  /** Aria label for the button */
  'aria-label'?: string;

  /* Material style classname */
  className?: string;
};

type AnchoredHiddenMenuState = {
  anchorEl: any;
};

/** Creates a hidden menu that opens when the anchor element is clicked */
class AnchoredHiddenMenu extends Component<
  AnchoredHiddenMenuProps,
  AnchoredHiddenMenuState
> {
  static defaultProps = {
    anchorElChanged: undefined,
    container: undefined,
    variant: 'iconButton',
    children: null,
    icon: moreVertIcon,
    iconClasses: '',
    menuClasses: '',
    customAnchor: () => {},
    getContentAnchorEl: undefined,
    anchorOrigin: undefined,
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    closeOnClick: true,
    buttonLabel: undefined,
    tooltip: 'More',
    tooltipPosition: 'bottom',
    'aria-label': undefined,
    className: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  onClose = (event) => {
    if (typeof event?.stopPropagation === 'function') {
      event.stopPropagation();
    }
    this.setState({ anchorEl: null });
    const { anchorElChanged } = this.props;
    if (anchorElChanged) {
      anchorElChanged(null);
    }
  };

  onClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    const { anchorElChanged } = this.props;
    if (anchorElChanged) {
      anchorElChanged(event.currentTarget);
    }
    event.preventDefault();
    if (typeof event?.stopPropagation === 'function') {
      event.stopPropagation();
    }
  };

  renderAnchor = () => {
    const {
      variant,
      icon,
      iconClasses,
      customAnchor,
      buttonLabel,
      tooltip,
      container,
      tooltipPosition,
      'aria-label': ariaLabel,
    } = this.props;

    if (variant === 'iconButton') {
      const Icon = icon;
      return (
        <IconButton
          // eslint-disable-next-line react/destructuring-assignment
          aria-label={ariaLabel || tooltip}
          className={iconClasses}
          onClick={this.onClick}
          // @ts-expect-error passes down to something non-ts
          label={buttonLabel}
          tooltipProps={{
            placement: tooltipPosition,
            PopperProps: { container },
          }}
        >
          <Icon />
        </IconButton>
      );
    }
    if (variant === 'custom') {
      return cloneElement(customAnchor, {
        onClick: this.onClick,
      });
    }

    throw new Error('Expected one of custom or icon for prop variant');
  };

  /** Recursively add this.onClose to every MenuItem's onClick method */
  renderChildren = (children) => {
    const { closeOnClick } = this.props;
    return Children.map(children, (child) => {
      if (!isValidElement(child)) {
        return child;
      }

      // @ts-expect-error display name sometimes does exist
      if (child.type.displayName === 'MenuItem') {
        return cloneElement(child as ReactElement<any>, {
          onClick: (event) => {
            (child.props as any).onClick && (child.props as any).onClick(event);
            if (closeOnClick) {
              this.onClose(event);
            }
          },
        });
      }

      if ((child.props as any).children) {
        return cloneElement(child as ReactElement<any>, {
          children: this.renderChildren((child.props as any).children),
        });
      }

      return child;
    });
  };

  render() {
    const {
      children,
      container,
      menuClasses,
      getContentAnchorEl,
      variant,
      customAnchor,
      icon,
      anchorElChanged,
      iconClasses,
      closeOnClick,
      anchorOrigin,
      transformOrigin,
      tooltip,
      buttonLabel,
      ...otherProps
    } = this.props;
    const { anchorEl } = this.state;

    return (
      <>
        {this.renderAnchor()}
        <Menu
          anchorEl={anchorEl}
          className={menuClasses}
          open={Boolean(anchorEl)}
          onClose={this.onClose}
          disableAutoFocusItem
          // @ts-expect-error Deprecated but we don't know if it still get's used
          getContentAnchorEl={getContentAnchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          container={container}
          {...otherProps}
        >
          {this.renderChildren(children)}
        </Menu>
      </>
    );
  }
}

export default AnchoredHiddenMenu;
