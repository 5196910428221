import { memo } from 'react';
import * as React from 'react';
import { Typography } from 'base-components';

interface Props {
  header: string;
  description?: string;
}

const StepHeader: React.VFC<Props> = ({ header, description = '' }) => (
  // TODO: what are ONC's typographic standards? use them here
  <>
    <Typography variant="h6">{header}</Typography>
    {description && <Typography variant="body2">{description}</Typography>}
  </>
);

export default memo(StepHeader);
