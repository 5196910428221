import { parseDmasAPIResponse, get } from 'util/WebRequest';

// Used in the operation 25 call of this service
export interface PublicCruiseJSON {
  organizationId: number;
  organizationName: string;
  cruiseId: number;
  cruiseDescription: string;
  cruiseName: string;
  startDate: string;
  endDate: string;
  livestreamUrl: string;
  livestreamDeviceId: number;
}

export interface CruiseJSON {
  organizationId: number;
  organizationName: string;
  cruiseId: number;
  cruiseDescription: string;
  cruiseName: string;
  startDate: string;
  endDate: string;
  platformId: number;
  countryOfDepartureCode: string;
  countryOfReturnCode: string;
  oceanAreaCode: string;
  southBound: string;
  eastBound: string;
  westBound: string;
  northBound: string;
  startDateStrWithDateTime: string;
  endDateStrWithDateTime: string;
  startDateStr: string;
  dmasuser: {
    firstName: string;
    lastName: string;
  };
  divePlan: string;
  reference: string;
  portOfReturnCode: string;
  modifiedBy: string;
  modifyBy: number;
  modifyDate: string;
  platformName: string;
  endDateStr: string;
  observatoryId: number;
  currentCruise: 'Y' | 'N';
  datasetAccessRestrictionCode: string;
  liveStream1Url: string;
  liveStream2Url: string;
  liveStream3Url: string;
  liveStream4Url: string;

  liveStream1Name: string;
  liveStream2Name: string;
  liveStream3Name: string;
  liveStream4Name: string;

  platformMappingCode: string;
  chiefScientist: string;
  platformClassCode: string;
  modifyDateStr: string;
  portOfDepartureCode: string;

  // Plenty more not important at the moment;
}

class CruiseService {
  static getResourceOptions = async (cruiseId, diveId = undefined) => {
    const response = await get(
      'CruiseService',
      {
        operation: 22,
        cruiseId,
        diveId,
      },
      null
    );
    return parseDmasAPIResponse(response);
  };

  static getCruise = async (cruiseId: number): Promise<CruiseJSON> => {
    const response = await get('CruiseService', {
      operation: 10,
      cruiseId,
    });
    return parseDmasAPIResponse(response);
  };

  static getCruisePublic = async (
    cruiseId: number
  ): Promise<PublicCruiseJSON> => {
    const response = await get('CruiseService', {
      operation: 25,
      cruiseId,
    });
    return parseDmasAPIResponse(response);
  };

  static getCruises = async (date?: string) => {
    const response = await get(
      'CruiseService',
      {
        operation: 11,
        date,
      },
      null
    );
    return parseDmasAPIResponse(response);
  };
}

export default CruiseService;
