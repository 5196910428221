import * as React from 'react';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { Typography } from 'base-components';

interface Props {
  title: string;
}

const pluginDependencies = [{ name: 'Toolbar' }];

const TitlePlugin: React.VFC<Props> = ({ title }: Props) => (
  <Plugin name="TitlePlugin" dependencies={pluginDependencies}>
    <Template name="toolbarContent">
      <TemplateConnector>
        {() => (
          <div
            style={{
              float: 'left',
              order: -1,
              marginRight: '24px',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography variant="h6">{title}</Typography>
          </div>
        )}
      </TemplateConnector>
      <TemplatePlaceholder />
    </Template>
  </Plugin>
);

export default TitlePlugin;
