import { useEffect, useMemo, useRef, useState, FC } from 'react';
import * as React from 'react';
import {
  IntegratedGrouping,
  IntegratedSorting,
  IntegratedSelection,
  Column,
  DataTypeProvider,
  DataTypeProviderProps,
  SelectionState,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  DragDropProvider,
  Table,
  TableHeaderRow,
  TableGroupRow,
  Toolbar,
  ColumnChooser,
  GroupingPanel,
  VirtualTable,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles } from '@mui/styles';
import { DragHandle, ViewColumn } from '@onc/icons';
import {
  AnchoredHiddenMenu,
  ExpandableSearchBar,
  IconButton,
  Paper,
} from 'base-components';
import AddButtonPlugin from './plugins/AddToolbarButton';
import ColumnGrouping, { GroupingProps } from './plugins/ColumnGrouping';
import ColumnReordering, { ReorderingProps } from './plugins/ColumnReordering';
import ColumnResizing, { ResizingProps } from './plugins/ColumnResizing';
import ColumnSorting, { SortingProps } from './plugins/ColumnSorting';
import ColumnVisibility, {
  ColumnVisibilityProps,
} from './plugins/ColumnVisibility';
import ExpandRow, { ExpandRowProps } from './plugins/ExpandRow';
import TableFilterLogic from './plugins/filter/filters/TableFilterLogic';
import ToolbarFilter, {
  getEmptyFilter,
  TableFilterProps,
} from './plugins/filter/ToolbarFilter';
import ToolbarFilterState from './plugins/filter/ToolbarFilterState';
import FixedColumn, { FixedColumnProps } from './plugins/FixedColumn';
import Pagination, { PagingProps } from './plugins/Pagination';
import RefreshContent from './plugins/RefreshToolbarButton';
import ClearLocalStorage from './plugins/ResetToolbarButton';
import RowCountText from './plugins/RowCountText';
import RowSelection, { SelectionProps } from './plugins/RowSelection';
import SortingLabelComponent from './plugins/SortingLabelComponent';
import TableRowDetail from './plugins/TableRowDetail';
import TitlePlugin from './plugins/TitlePlugin';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      // Put the group icon to the left of the header text
      '& > div > div > div > table > thead > tr > th > div ': {
        flexDirection: 'row-reverse',
      },
      '& > div > div > div > table > tbody > tr > td': {
        padding: '0px 8px',
        height: '40px',
      },
      '& > div > div > div > table ': {
        marginBottom: '0px !important',
      },
      // Adds some slight spacing to icons in the toolbar
      '& > div > div > span ': {
        maxHeight: '48px !important',
      },
      '& div[class*="TableHeaderCell"]': {
        fontWeight: 'bold',
      },
    },
    toolbarIcon: {
      width: '48px',
    },
  })
);

export interface DataFormatProvider extends DataTypeProviderProps {
  name: string;
}

interface GroupButtonProps {
  disabled: boolean;
  onGroup: React.MouseEventHandler<HTMLButtonElement>;
}

// eslint-disable-next-line react/prop-types
const GroupButton = ({ disabled, onGroup }: GroupButtonProps) => {
  if (!disabled) {
    return (
      <IconButton
        onClick={onGroup}
        size="small"
        data-test="group-button"
        style={{ marginRight: '8px' }}
        aria-label="Drag to group columns"
      >
        <DragHandle color="action" fontSize="small" />
      </IconButton>
    );
  }
  return null;
};

export type TableColumnDataTypes =
  | 'String'
  | 'Number'
  | 'Boolean'
  | 'Date'
  | 'Select'
  | 'Integer'
  | 'Other'
  | undefined;

export interface TableColumn extends Column {
  dataType?: TableColumnDataTypes;
  onFilterColumn?: (groupIndex: number, lineIndex: number) => void;
}

const Root = (props: Grid.RootProps) => (
  <Grid.Root {...props} style={{ height: '100%' }} />
);

const ColumnVisibilityComponent =
  (classes) =>
  ({ children }: ColumnChooser.OverlayProps) => (
    <AnchoredHiddenMenu
      aria-label="Open Column Chooser"
      tooltip="Open Column Chooser"
      icon={ViewColumn}
      iconClasses={classes.toolbarIcon}
    >
      {children}
    </AnchoredHiddenMenu>
  );

// Define the CustomSortingLabel component outside of StatelessTable
const createSortingLabelComponent = (sort: SortingProps) =>
  React.memo((props: TableHeaderRow.SortLabelProps) => (
    <SortingLabelComponent {...props} sort={sort} />
  ));

interface Props {
  rows: any[];
  columns: TableColumn[];
  columnExtensions?: Table.ColumnExtension[];
  reorder?: ReorderingProps;
  resize?: ResizingProps;
  fixed?: FixedColumnProps;
  visible?: ColumnVisibilityProps;
  sort?: SortingProps;
  group?: GroupingProps;
  expandRow?: ExpandRowProps;
  searchable?: boolean;
  columnFormatProviders?: DataFormatProvider[];
  virtual?: {
    virtualized: boolean;
    rowHeight?: number;
  };
  messages?: {
    noData: string;
  };
  paging?: PagingProps;
  filter?: TableFilterProps;
  selection?: SelectionProps;
  title?: string;
  permission?: string;
  onCreate?: () => void;
  onReset?: () => void;
  onRefresh?: () => void;
  getRowId?: (row: any) => string;
  scrollToRow?: number;
  cellComponent?: any;
  /* Use a custom render function for the table's header row. This should only be used if you absolutely need a custom component such as an Icon.
     You can change what renders per column basis by matching props.column.name to those defined is the prop column of StatelessTable.

     Function should return a <TableHeaderRow.Cell>
  */
  headerCellComponent?: FC<TableHeaderRow.CellProps>;
  showNumberOfRecords?: boolean;
  expandSearchBar?: boolean;
  onExpand?: (condition: boolean) => void;
  className?: string;
  filterButtons?: React.ReactNode[];
}

const createSearchInputComponent = (
  expandSearchBar: boolean,
  onExpand: (condition: boolean) => void,
  searchValueState: string
) =>
  React.memo((props: SearchPanel.InputProps) => (
    <ExpandableSearchBar
      {...props}
      expandSearchBar={expandSearchBar}
      onExpand={onExpand}
      searchValue={searchValueState}
    />
  ));

const StatelessTable: React.VFC<Props> = ({
  rows,
  cellComponent = undefined,
  headerCellComponent = (props: TableHeaderRow.CellProps) => (
    <TableHeaderRow.Cell aria-label={props.column.title} {...props} />
  ),
  columns,
  columnExtensions = undefined,
  reorder = undefined,
  resize = undefined,
  fixed = undefined,
  visible = undefined,
  sort = undefined,
  group = undefined,
  columnFormatProviders = undefined,
  expandRow = undefined,
  searchable: search = false,
  virtual = undefined,
  messages = {
    noData: 'No data',
  },
  filter = undefined,
  selection = undefined,
  scrollToRow = undefined,
  title = undefined,
  permission = undefined,
  onCreate = undefined,
  onReset = undefined,
  onRefresh = undefined,
  getRowId = (row: any) => row.id,
  paging = undefined,
  showNumberOfRecords = false,
  expandSearchBar = false,
  onExpand = undefined,
  className = '',
  filterButtons = undefined,
}) => {
  const groupable = !!group;
  const sortable = !!sort;

  let filterLogic = TableFilterLogic.defaultFilter;
  if (filter && filter.filterFn) {
    filterLogic = filter.filterFn;
  }

  const allRows = rows;
  const [filteredRows, setFilteredRows] = useState(rows);
  const [searchValueState, setSearchValue] = useState('');
  const tableRef = useRef<any>();

  const [appliedFilter, setAppliedFilter] = useState(
    filter ? filter.filterValue : getEmptyFilter()
  );

  const classes = useStyles();

  useEffect(() => {
    if (filter && filter.filterValue) {
      setAppliedFilter(filter.filterValue);
    }
  }, [filter]);

  useEffect(
    () => {
      setFilteredRows(
        TableFilterLogic.getFilteredRows(rows, appliedFilter, filterLogic)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appliedFilter, rows]
  );

  useEffect(() => {
    if (tableRef && tableRef.current && scrollToRow) {
      tableRef.current.scrollToRow(scrollToRow);
    }
  }, [tableRef, scrollToRow, rows]);

  // Use the created HOC
  const CustomSortingLabel = useMemo(
    () => createSortingLabelComponent(sort),
    [sort]
  );

  const showToolbar =
    visible ||
    groupable ||
    filter ||
    search ||
    title ||
    showNumberOfRecords ||
    (permission && onCreate) ||
    onRefresh;

  const CustomSearchInput = useMemo(
    () =>
      createSearchInputComponent(expandSearchBar, onExpand, searchValueState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandSearchBar]
  );

  return (
    <Paper className={`${className} ${classes.root}`}>
      <Grid
        rows={filteredRows}
        columns={columns}
        getRowId={getRowId}
        rootComponent={Root}
      >
        {filter && (
          <ToolbarFilterState
            filter={appliedFilter}
            filterForm={filter.filterForm}
            onChange={filter.onChange || setAppliedFilter}
            onFormChange={filter.onFormChange}
          />
        )}
        {search && (
          <SearchState
            value={searchValueState}
            onValueChange={setSearchValue}
          />
        )}
        {search && <IntegratedFiltering />}
        <DragDropProvider />
        {sort && (
          <ColumnSorting
            sorting={sort.sorting}
            columnExtensions={sort.columnExtensions}
            handleSortingChange={sort.handleSortingChange}
          />
        )}

        {selection && (
          <SelectionState
            selection={selection.selection}
            onSelectionChange={selection.onChange}
          />
        )}
        {sortable && (
          <IntegratedSorting columnExtensions={sort.customSorting} />
        )}
        {group && (
          <ColumnGrouping
            grouping={group.grouping}
            columnExtensions={group.columnExtensions}
            handleGroupingChange={group.handleGroupingChange}
          />
        )}
        {groupable && <IntegratedGrouping />}
        {paging && (
          <Pagination
            defaultCurrentPage={paging.defaultCurrentPage}
            currentPage={paging.currentPage}
            onCurrentPageChange={paging.onCurrentPageChange}
            pageSize={paging.pageSize}
            onPageSizeChange={paging.onPageSizeChange}
            totalCount={paging.totalCount}
            pageSizes={paging.pageSizes}
            containerComponent={paging.containerComponent}
          />
        )}
        {expandRow && (
          <ExpandRow
            expandedRows={expandRow.expandedRows}
            handleExpandRowChange={expandRow.handleExpandRowChange}
            ToggleCellComponent={expandRow?.ToggleCellComponent}
          />
        )}
        {columnFormatProviders &&
          columnFormatProviders.map((provider) => (
            <DataTypeProvider
              key={provider.name}
              for={provider.for}
              formatterComponent={provider.formatterComponent}
            />
          ))}
        {virtual ? (
          <VirtualTable
            estimatedRowHeight={virtual.rowHeight || 45}
            height="auto"
            columnExtensions={columnExtensions}
            ref={tableRef}
            messages={messages}
          />
        ) : (
          <Table columnExtensions={columnExtensions} />
        )}
        {resize && (
          <ColumnResizing
            columnWidths={resize.columnWidths}
            handleColumnWidths={resize.handleColumnWidths}
            columnExtensions={resize.columnExtensions}
            resizingMode={resize.resizingMode}
          />
        )}
        {visible && (
          <ColumnVisibility
            hiddenColumnNames={visible.hiddenColumnNames}
            handleChangeVisibility={visible.handleChangeVisibility}
            disableColumnNames={visible.disableColumnNames}
          />
        )}
        {reorder && <ColumnReordering {...reorder} />}
        {cellComponent && (
          <Table
            cellComponent={cellComponent}
            columnExtensions={columnExtensions}
          />
        )}
        <TableHeaderRow
          showSortingControls={sortable}
          showGroupingControls={groupable}
          groupButtonComponent={GroupButton}
          sortLabelComponent={CustomSortingLabel}
          cellComponent={headerCellComponent}
        />
        {expandRow && <TableRowDetail {...expandRow} />}
        {selection && <IntegratedSelection />}
        {selection && <RowSelection {...selection} />}
        {groupable && <TableGroupRow />}
        {fixed && (
          <FixedColumn
            leftColumns={fixed.leftColumns}
            rightColumns={fixed.rightColumns}
          />
        )}
        {showToolbar && <Toolbar />}
        {title && <TitlePlugin title={title} />}
        {filter && !filter.hidden && (
          <ToolbarFilter
            filteredRows={filteredRows}
            allRows={allRows}
            filterButtons={filterButtons}
          />
        )}
        {(!filter || filter?.hidden) && showNumberOfRecords && (
          <RowCountText rows={allRows} />
        )}
        {visible && (
          <ColumnChooser
            toggleButtonComponent={() => null}
            overlayComponent={ColumnVisibilityComponent(classes)}
          />
        )}
        {search && <SearchPanel inputComponent={CustomSearchInput} />}
        {onReset && <ClearLocalStorage onReset={onReset} />}
        {onCreate && permission === 'RW' && (
          <AddButtonPlugin onCreate={onCreate} />
        )}
        {onRefresh && <RefreshContent onRefresh={onRefresh} />}
        {groupable && (
          <GroupingPanel showGroupingControls showSortingControls={!!sort} />
        )}
      </Grid>
    </Paper>
  );
};
export default StatelessTable;
