import PropTypes from 'prop-types';

import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import Environment from 'util/Environment';

const getDeviceListingUrl = (deviceId, tabName) => {
  if (tabName == null) {
    return `${Environment.getDmasUrl()}/DeviceListing?DeviceId=${deviceId}`;
  }
  return `${Environment.getDmasUrl()}/DeviceListing?DeviceId=${deviceId}#${tabName}`;
};

const openDeviceDetails = (deviceId, tabName) => {
  global.open(getDeviceListingUrl(deviceId, tabName), '_self');
};

const handleOpenDeviceDetails = (deviceId, tabName) => () => {
  openDeviceDetails(deviceId, tabName);
};

const DeviceDetailsLink = ({
  deviceId,
  children = undefined,
  tabName = null,
}) => (
  <OpenInNewLink href={getDeviceListingUrl(deviceId, tabName)}>
    {children || deviceId}
  </OpenInNewLink>
);

DeviceDetailsLink.propTypes = {
  deviceId: PropTypes.number.isRequired,
  children: PropTypes.node,
  tabName: PropTypes.string,
};

export { handleOpenDeviceDetails, openDeviceDetails };
export default DeviceDetailsLink;
