import moment, { Moment } from 'moment';
import { ListAnnotation } from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';
import { ServiceAnnotationV3 } from 'domain/services/AnnotationService';

class AnnotationUtil {
  /*
   * A function that processes annotations. If an annotation has a
   * taxon, the function will construct and attach the final displayText to the
   * annotation.
   * The function will populate a deck log annotation with a logName of "Deck Log"
   */
  static processAnnotations = (annotations, showComment) => {
    const processed = annotations.map((annotation) => {
      const processedAnnotation = { ...annotation };
      const { diveName } = annotation;
      processedAnnotation.logName = diveName || 'Deck Log';
      processedAnnotation.displayText = this.constructDisplayText(
        annotation,
        showComment
      );
      return processedAnnotation;
    });

    return processed;
  };

  static constructDisplayText = (annotation, showComment) => {
    const { comment, taxons } = annotation;
    let finalText = comment ? <span>{comment}</span> : undefined;

    if (taxons) {
      const taxon = taxons[0];

      finalText = (
        <span className="taxon-info">
          {taxon.displayText} [{this.constructTaxonomyCode(taxon)}]
          {showComment ? ` ${comment}` : ''}
        </span>
      );
    }

    return finalText;
  };

  static constructTaxonomyCode = (taxon) => {
    const { taxonUrl, taxonomyCode } = taxon;

    if (taxonUrl) {
      return (
        <a href={taxonUrl} target="_blank" rel="noopener noreferrer">
          {taxonomyCode}
        </a>
      );
    }

    return taxonomyCode;
  };

  static getAttributeValue = (attribute) => {
    const { operator } = attribute;
    // Parse object in the case of select options
    const dataType = attribute.attribute?.dataType;
    if (dataType === 'Number' || dataType === 'Integer') {
      const isNumber =
        !isNaN(Number(attribute.filterValue)) && attribute.filterValue !== '';
      return isNumber ? attribute.filterValue : undefined;
    }
    // Don't bother setting the value if the operator asks for all or null values
    if (operator) {
      if (operator.value === 'exists' || operator.value === 'isNull') {
        return undefined;
      }
    }
    // Parse object in the case of select options
    if (Array.isArray(attribute.filterValue)) {
      return attribute.filterValue
        .map((item) => item.value.toString())
        .join(',');
    }
    return attribute.filterValue;
  };

  static buildAnnotationFilter = (filter) => {
    const newFilter: any = {};

    // Comment
    if (filter.comment && filter.comment.length > 0) {
      newFilter.filterText = filter.comment;
    }
    // Creators
    if (filter.creators) {
      newFilter.creators = filter.creators.map((creator) => creator.value);
    }
    // Modifiers
    if (filter.modifiers) {
      newFilter.modifiers = filter.modifiers.map((modifier) => modifier.value);
    }
    // ToBeReviewed
    if (filter.toBeReviewed === true) {
      newFilter.toBeReviewed = true;
    }

    // Depth
    if (filter.depth) {
      newFilter.depth = [filter.depth.greaterThan, filter.depth.lessThan];
    }

    // Obis Count
    if (filter.obisCount) {
      newFilter.obisCount = [
        filter.obisCount.greaterThan,
        filter.obisCount.lessThan,
      ];
    }

    // Attributes
    if (filter.attributes) {
      newFilter.attributes = filter.attributes.map((attribute) => ({
        attributeId: attribute.attribute?.value,
        operation: attribute.operator ? attribute.operator.value : undefined,
        value: this.getAttributeValue(attribute),
      }));
    }

    // Taxonomy
    if (filter.taxonomy) {
      newFilter.parentTaxonomyId = filter.taxonomy.taxonomy;
      if (filter.taxonomy.taxon) {
        newFilter.parentTaxonId = filter.taxonomy.taxon.taxonId;
      }
    }

    // Date
    if (filter.date) {
      newFilter.startDate = filter.date.fromDate;
      newFilter.endDate = filter.date.toDate;
    }

    // Reviews
    if (filter.reviews) {
      newFilter.minimumReviews = filter.reviews.minimumReviews;
      newFilter.positiveReviewPercentage =
        filter.reviews.positiveReviewPercentage.toString();
    }

    for (const prop in newFilter) {
      if (
        newFilter[prop] === null ||
        newFilter[prop] === undefined ||
        (Array.isArray(newFilter[prop]) && !newFilter[prop].length)
      ) {
        delete newFilter[prop];
      }
    }
    return newFilter;
  };

  static findClosestDescending = (
    annotations: ServiceAnnotationV3[] | ListAnnotation[],
    timestampSeconds: number
  ): number => {
    for (let i = 0; i < annotations.length; i += 1) {
      const annotationTime = moment.utc(annotations[i].startDate).unix();
      if (annotationTime <= timestampSeconds) {
        return i;
      }
    }
    return annotations.length - 1; // Default to the last index if none found
  };

  static findClosestAscending = (
    annotations: ServiceAnnotationV3[] | ListAnnotation[],
    timestampSeconds: number
  ): number => {
    let selectedIndex = 0;
    for (let i = 0; i < annotations.length; i += 1) {
      const annotationTime = moment.utc(annotations[i].startDate).unix();
      if (annotationTime > timestampSeconds) {
        return i - 1 >= 0 ? i - 1 : 0;
      }
      selectedIndex = i;
    }
    return selectedIndex;
  };

  static selectClosestAnnotationToTimestamp = (
    annotations: ServiceAnnotationV3[] | ListAnnotation[],
    timestamp: Moment,
    sortMostRecent: boolean,
    setSelectedAnnotation: (annotationId: number) => void
  ) => {
    if (!annotations || annotations.length === 0) {
      return 0;
    }

    const timestampSeconds = Math.floor(moment(timestamp).unix());
    let selectedIndex: number;

    if (sortMostRecent) {
      selectedIndex = this.findClosestDescending(annotations, timestampSeconds);
    } else {
      selectedIndex = this.findClosestAscending(annotations, timestampSeconds);
    }

    const selectedAnnotation = annotations[selectedIndex];

    if (selectedAnnotation) {
      setSelectedAnnotation(selectedAnnotation.annotationId ?? undefined);
    }

    return selectedIndex;
  };
}

export default AnnotationUtil;
