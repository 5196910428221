/* eslint-disable @typescript-eslint/no-unused-expressions */
import _ from 'lodash';
import moment from 'moment';
import {
  TableFilter,
  TableFilterGroup,
  TableFilterLine,
} from '../ToolbarFilter';

/* eslint-disable no-unused-expressions */
type FilterFnType = (row: any, filterLine: TableFilterLine) => boolean;

class TableFilterLogic {
  static getFilteredRows = (
    rows: any[],
    filter: TableFilter | undefined,
    filterFn?: FilterFnType
  ) => {
    if (filter && filter.enabled) {
      const filterFunc = filterFn || this.defaultFilter;
      if (!this.filterIsValid(filter)) {
        return rows;
      }
      if (filter.logic === 'And') {
        return this.andFilterLogic(rows, filter.filterGroups, filterFunc);
      }
      return this.orFilterLogic(rows, filter.filterGroups, filterFunc);
    }
    return rows;
  };

  static andFilterLogic = (
    rows: any[],
    filterGroups: TableFilterGroup[],
    filterFn: FilterFnType
  ) => {
    const groupRows: any[] = [];
    filterGroups.forEach((group) => {
      if (group.logic === 'And') {
        groupRows.push(
          this.andFilterGroupLogic(rows, group.filterLines, filterFn)
        );
      } else {
        groupRows.push(
          this.orFilterGroupLogic(rows, group.filterLines, filterFn)
        );
      }
    });
    return _.intersection(...groupRows);
  };

  static orFilterLogic = (
    rows: any[],
    filterGroups: TableFilterGroup[],
    filterFn: FilterFnType
  ) => {
    const groupRows: any[] = [];
    filterGroups.forEach((group) => {
      if (group.logic === 'And') {
        groupRows.push(
          this.andFilterGroupLogic(rows, group.filterLines, filterFn)
        );
      } else {
        groupRows.push(
          this.orFilterGroupLogic(rows, group.filterLines, filterFn)
        );
      }
    });
    return _.union(...groupRows);
  };

  static andFilterGroupLogic = (
    rows: any[],
    filterLines: TableFilterLine[],
    filterFn: FilterFnType
  ) => {
    const matchedRows: any[] = [];
    rows.forEach((row) => {
      let matched = true;
      filterLines.forEach((filterLine) => {
        filterFn(row, filterLine) ? null : (matched = false);
      });
      matched ? matchedRows.push(row) : null;
    });
    return matchedRows;
  };

  static orFilterGroupLogic = (
    rows: any[],
    filterLines: TableFilterLine[],
    filterFn: FilterFnType
  ) => {
    const matchedRows: any[] = [];
    rows.forEach((row) => {
      let matched = false;
      filterLines.forEach((filterLine) => {
        filterFn(row, filterLine) ? (matched = true) : null;
      });
      matched ? matchedRows.push(row) : null;
    });
    return matchedRows;
  };

  static defaultFilter = (row: any, filterLine: TableFilterLine) => {
    const { column, operator, value } = filterLine;
    const columnVal = row[filterLine.column];
    if (column) {
      switch (filterLine.dataType) {
        case 'String':
          return this.matchesStringFilter(columnVal, operator, value);

        case 'Number':
          return this.matchesNumberFilter(columnVal, operator, value);

        case 'Boolean':
          return this.matchesBooleanFilter(columnVal, operator, value);

        case 'Select':
          return this.matchesSelectFilter(columnVal, operator, value);

        case 'Date':
          return this.matchesDateFilter(columnVal, operator, value);

        default:
          return this.matchesStringFilter(columnVal, operator, value);
      }
    }
    return true;
  };

  static matchesDateFilter = (
    columnVal: string,
    operator: string | null | undefined,
    value: any
  ) => {
    switch (operator) {
      case 'before':
        return moment.utc(columnVal).isBefore(moment.utc(value), 'second');

      case 'after':
        return moment.utc(columnVal).isAfter(moment.utc(value), 'second');

      case 'equals':
        return moment.utc(columnVal).isSame(moment.utc(value), 'second');

      case 'isNull':
        return this.isNull(columnVal);

      case 'isNotNull':
        return !this.isNull(columnVal);

      default:
        return true;
    }
  };

  static matchesStringFilter = (
    columnVal: string,
    operator: string | null | undefined,
    value: any
  ) => {
    switch (operator) {
      case 'contains':
        return columnVal && columnVal.includes(value);

      case 'doesNotContain':
        return columnVal && !columnVal.includes(value);

      case 'equals':
        return columnVal === value;

      case 'doesNotEqual':
        return columnVal !== value;

      case 'isNull':
        return this.isNull(columnVal);

      case 'isNotNull':
        return !this.isNull(columnVal);

      default:
        return true;
    }
  };

  static matchesNumberFilter = (
    columnVal: string,
    operator: string | null | undefined,
    value: any
  ) => {
    switch (operator) {
      case 'equals':
        return Number(columnVal) === Number(value);

      case 'lessThan':
        return Number(columnVal) < Number(value);

      case 'lessThanEqualTo':
        return Number(columnVal) <= Number(value);

      case 'greaterThan':
        return Number(columnVal) > Number(value);

      case 'greaterThanEqualTo':
        return Number(columnVal) >= Number(value);

      case 'isNull':
        return this.isNull(columnVal);

      case 'isNotNull':
        return !this.isNull(columnVal);

      default:
        return true;
    }
  };

  static matchesBooleanFilter = (
    columnVal: boolean,
    operator: string | null | undefined,
    value: any
  ) => {
    switch (operator) {
      case 'is':
        if (value === 'true') {
          return columnVal === true;
        }
        if (value === 'false') {
          return columnVal === false;
        }
        return this.isNull(columnVal);

      case 'isNot':
        if (value === 'true') {
          return columnVal === false || this.isNull(columnVal);
        }
        if (value === 'false') {
          return columnVal === true || this.isNull(columnVal);
        }

        return !this.isNull(columnVal);

      default:
        return true;
    }
  };

  static matchesSelectFilter = (
    columnVal: boolean,
    operator: string | null | undefined,
    value: any
  ) => {
    switch (operator) {
      case 'isOneOf':
        return value.includes(columnVal);

      case 'isNotOneOf':
        return !value.includes(columnVal);

      case 'isNull':
        return this.isNull(columnVal);

      case 'isNotNull':
        return !this.isNull(columnVal);

      default:
        return true;
    }
  };

  static isNull = (val: any) =>
    typeof val === 'undefined' || val === null || val.toString().trim() === '';

  static filterIsValid = (filter) => {
    if (filter && filter.filterGroups.length) {
      let filterLines = 0;
      filter.filterGroups.forEach((group) =>
        group.filterLines.forEach((line) => {
          line.column ? (filterLines += 1) : null;
        })
      );
      return !!filterLines;
    }
    return false;
  };
}

export default TableFilterLogic;
