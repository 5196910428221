import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid } from 'base-components';
import {
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';

const propTypes = {
  onChange: PropTypes.func.isRequired,
};

const SingleCheckbox = ({ onChange }) => <Checkbox onChange={onChange} />;

SingleCheckbox.propTypes = propTypes;

const LabeledCheckBoxWithModifyByModifyDate = ({
  onChange,
  label,
  modifyBy = undefined,
  modifyDate = undefined,
  checked = false,
  disabled = false,
  showMetaData = false,
}) => {
  let modifyDateComp;
  let modifyByComp;
  if (showMetaData) {
    modifyDateComp = (
      <Grid item>
        <ModifyDate date={modifyDate} />
      </Grid>
    );
    modifyByComp = (
      <Grid item>
        <ModifyBy username={modifyBy} />
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              disabled={disabled}
            />
          }
          label={label}
          labelPlacement="end"
        />
      </Grid>
      {modifyByComp}
      {modifyDateComp}
    </Grid>
  );
};

LabeledCheckBoxWithModifyByModifyDate.propTypes = {
  label: PropTypes.string.isRequired,
  modifyBy: PropTypes.string,
  modifyDate: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  showMetaData: PropTypes.bool,
  ...propTypes,
};

export default SingleCheckbox;
export { LabeledCheckBoxWithModifyByModifyDate };
