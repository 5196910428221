import { Component } from 'react';

import PamguardPSFUploadComponent from 'domain/AppComponents/pamguard/PamguardPSFUploadComponent';
import PageWithPadding from 'library/CompositeComponents/page/PageWithPadding';

class PamguardPSFUploadPage extends Component {
  render() {
    return (
      <PageWithPadding>
        <PamguardPSFUploadComponent />
      </PageWithPadding>
    );
  }
}

export default PamguardPSFUploadPage;
