import { useEffect, useState } from 'react';
import * as React from 'react';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from 'base-components';

import {
  CancelButton,
  ContainedButton,
} from 'library/CompositeComponents/button/Buttons';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

import AnnotationTableLogic from './AnnotationTableLogic';
import TableAnnotation, { Attribute } from './TableAnnotation';

type Props = {
  open: boolean;
  rows: TableAnnotation[];
  onSelect: (attribute: any) => void;
  onCancel: () => void;
};

const ALL_ATTRIBUTES = {
  groupId: -1,
  groupName: '(All Attributes)',
};

const AttributeSelectDialog: React.VFC<Props> = (props: Props) => {
  const { rows, open, onCancel, onSelect } = props;
  const [group, setGroup] = useState<any>(ALL_ATTRIBUTES);
  const [attribute, setAttribute] = useState<any>(null);
  const [attributeList, setAttributeList] = useState<(Attribute | undefined)[]>(
    []
  );
  const [groupList, setGroupList] = useState<any[]>([]);

  useEffect(() => {
    const attributes = AnnotationTableLogic.getUniqueAttributes(rows);
    const groups = AnnotationTableLogic.getUniqueGroups(attributes);
    groups.push(ALL_ATTRIBUTES);
    setAttributeList(attributes);
    setGroupList(groups);
  }, [rows]);

  const filterAttributes = (attributeListItem: any) => {
    const groupId = group ? group.groupId : -1;
    if (groupId === -1) {
      return true;
    }
    if (attributeListItem.groupId === groupId) {
      return true;
    }
    return false;
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onCancel}>
      <DialogTitle>Select Attribute</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              translationKey="seatube.group"
              name="group"
              fullWidth
              value={group}
              getOptionLabel={(option: any) => option.groupName}
              isOptionEqualToValue={(option: any, selected: any) =>
                option.groupId === selected.groupId
              }
              options={groupList}
              onChange={(e: any) => setGroup(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              translationKey="seatube.attribute"
              name="attribute"
              data-test="attribute"
              fullWidth
              value={attribute || null}
              getOptionLabel={(option: Attribute) => option.name}
              isOptionEqualToValue={(option: Attribute, selected: Attribute) =>
                option.attributeId === selected.attributeId
              }
              onChange={(e: any) => setAttribute(e.target.value)}
              options={attributeList.filter((item) => filterAttributes(item))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onCancel} />
        <ContainedButton
          disabled={!attribute}
          onClick={() => onSelect(attribute)}
          translationKey="common.buttons.select"
        />
      </DialogActions>
    </Dialog>
  );
};

export default withSnackbars(AttributeSelectDialog);
