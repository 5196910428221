import { useState, useEffect } from 'react';
import DeviceWebServiceWithToken from 'domain/services/DeviceWebServiceWithToken';

export function getDevicesAsync(setDevices, getDevices, deviceCategoryCodes) {
  const results = [];
  // Make multiple service calls for each Device Category Call
  if (deviceCategoryCodes) {
    for (const deviceCategoryCode of deviceCategoryCodes) {
      results.push(getDevices(deviceCategoryCode));
    }
    return Promise.allSettled(results).then((responses) => {
      setDevices(
        []
          .concat(...responses.map(({ value }) => value))
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      );
    });
  }
  // Return All Devices
  return Promise.resolve(getDevices(undefined)).then((result) =>
    setDevices(result)
  );
}

function useFilteredDevices(
  deviceCategoryCodes,
  propertyCode,
  locationCode,
  deviceCode,
  dateFrom,
  dateTo
) {
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    async function getDevices(deviceCategoryCode) {
      const deviceList = [];
      await DeviceWebServiceWithToken.get({
        method: 'get',
        deviceCategoryCode,
        propertyCode,
        locationCode,
        deviceCode,
        dateFrom: dateFrom ? new Date(dateFrom) : undefined,
        dateTo: dateTo ? new Date(dateTo) : undefined,
      })
        .then((response) => {
          response.data.map((record) =>
            deviceList.push({
              deviceId: record.deviceId,
              name: record.deviceName,
              deviceCode: record.deviceCode,
              deviceCategoryCode: record.deviceCategoryCode,
              dataRating: record.dataRating,
            })
          );
          setDevices(deviceList);
        })
        // Return a empty list if no device data is found
        .catch(() => setDevices([]));
      return deviceList;
    }
    getDevicesAsync(setDevices, getDevices, deviceCategoryCodes);
  }, [
    deviceCategoryCodes,
    propertyCode,
    locationCode,
    deviceCode,
    dateFrom,
    dateTo,
  ]);
  return devices;
}
export default useFilteredDevices;
