import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { InfoOutlined } from '@onc/icons';
import { Link } from 'base-components';

const STYLES = (theme: Theme) =>
  createStyles({
    info: {
      color: theme.palette.primary.contrastText,
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      width: theme.spacing(5),
      height: theme.spacing(5),
      zIndex: 3,
    },
    infoIcon: {
      position: 'absolute',
      bottom: theme.spacing(0),
    },
  });

interface PanelCardInfoLinkProps extends WithStyles<typeof STYLES> {
  infoUrl?: string;
  highlighted: boolean;
  enableHighlight: () => void;
  disableHighlight: () => void;
  titleText: string;
  setCursor: (cursorType: string) => void;
  cursorType: string;
}

const PanelCardInfoLink: React.FC<PanelCardInfoLinkProps> = ({
  infoUrl = undefined,
  classes,
  highlighted,
  enableHighlight,
  disableHighlight,
  titleText,
  setCursor,
  cursorType,
}) => {
  const setHelpCursor = () => {
    setCursor('help');
    enableHighlight();
  };
  const setLinkCursor = () => {
    setCursor('cursor');
    disableHighlight();
  };
  if (infoUrl) {
    return (
      <Link
        title={`${titleText} Wiki`}
        onMouseEnter={setHelpCursor}
        onMouseLeave={setLinkCursor}
        onFocus={setHelpCursor}
        onBlur={setLinkCursor}
        className={classes.info}
        style={{ cursor: cursorType }}
        href={infoUrl}
        target="_blank"
      >
        {highlighted === true ? (
          <InfoOutlined className={classes.infoIcon} />
        ) : (
          <></>
        )}
      </Link>
    );
  }
  return null;
};

export default withStyles(STYLES)(PanelCardInfoLink);
