import { Component } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete } from 'base-components';
import SearchTreeService from 'domain/services/SearchTreeService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

class SearchTreeNodeChipSelect extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    value: [],
  };

  state = {
    searchTreeNodeOptions: [],
    isLoading: true,
    error: false,
  };

  componentDidMount() {
    this.loadFixedCameraOptions();
  }

  loadFixedCameraOptions = async () => {
    const { onError } = this.props;
    SearchTreeService.getFixedCameraTree()
      .then((payload) => {
        const options = this.createOptionsFromResponse(payload);
        this.setState({ searchTreeNodeOptions: options, isLoading: false });
      })
      .catch(() => {
        onError('Error loading fixed cameras');
        this.setState({ error: true });
      });
  };

  createOptionsFromResponse = (payload) => {
    const options = [];
    this.getNodesWithDevices(payload, options);
    return options.sort((a, b) => a.label.localeCompare(b.label));
  };

  getNodesWithDevices = (node, options) => {
    if (node.wasDeviceFound) {
      options.push({
        label: `${node.html} (${node.searchTreeNodeId})`,
        value: node.searchTreeNodeId,
        key: node.searchTreeNodeId,
      });
    } else {
      for (let i = 0; i < node.children.length; i += 1) {
        this.getNodesWithDevices(node.children[i], options);
      }
    }
  };

  render() {
    const { id, onChange, value } = this.props;
    const { searchTreeNodeOptions, isLoading, error } = this.state;

    if (error) {
      return (
        <Autocomplete
          id={id}
          name="searchTreeNodeIds"
          onChange={onChange}
          options={[]}
          translationKey="seatube.errorLoadingLocation"
          value="Error"
          disabled
        />
      );
    }

    return (
      <Autocomplete
        id={id}
        name="searchTreeNodeIds"
        onChange={onChange}
        options={searchTreeNodeOptions}
        getOptionLabel={(option) => (option.label ? option.label : '')}
        translationKey={isLoading ? 'seatube.loading' : 'seatube.location'}
        value={isLoading ? [] : value}
        disabled={isLoading}
      />
    );
  }
}

export default withSnackbars(SearchTreeNodeChipSelect);
