import React, { useEffect, useState } from 'react';
import { Map as LeafletMap } from 'leaflet';
import ONCMap, { Marker, MarkerConfig } from 'domain/AppComponents/map/ONCMap';
import {
  SearchTreeNode,
  useFixedCameraLocations,
} from 'domain/services/SearchTreeService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import MapContext from 'library/CompositeComponents/map/MapContext';
import useBroadcast from 'util/hooks/useBroadcast';
import BroadcastChannel from '../BroadcastChannel';

type TreePathNodeInfo = {
  searchTreeNodeId: number;
  wasDeviceFound: boolean;
};

const FIXED_CAMERA_MAP_ID = 'fixed-camera-map';
const map = {
  mapRef: React.createRef<LeafletMap>(),
  mapId: FIXED_CAMERA_MAP_ID,
};

const FixedCameraMapWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId } = props;
  const defaultFilter = {
    searchTreeNodeId: 11, // Fixed Cameras
    wasDeviceFound: false,
  };
  const { dashboardState } = useDashboardState();
  const { locationData } = dashboardState;
  const [currentFilter] = useState<TreePathNodeInfo[]>([defaultFilter]);
  const [markerConfig, setMarkerConfig] = useState<MarkerConfig>(null);
  const [searchTreeNodeId, setSearchTreeNodeId] = useBroadcast<number>(
    dashboardId,
    BroadcastChannel.SearchTreeNodeId,
    undefined,
    id
  );

  useEffect(() => {
    if (map?.mapRef.current && markerConfig) {
      const latlngs: [number, number][] = markerConfig?.markers?.map(
        (point) => [point.lat, point.lon]
      ) as [number, number][];
      map?.mapRef.current.fitBounds(latlngs, { padding: [50, 50] });
    }
  }, [markerConfig]);

  useEffect(() => {
    const minLon = locationData?.bbox?.minLon;
    const minLat = locationData?.bbox?.minLat;
    const maxLon = locationData?.bbox?.maxLon;
    const maxLat = locationData?.bbox?.maxLat;

    // Only continue if we have the bbox values
    if (
      minLon === undefined ||
      minLat === undefined ||
      maxLon === undefined ||
      maxLat === undefined
    )
      return;

    if (map?.mapRef.current) {
      map.mapRef.current.flyToBounds(
        [
          [minLat, minLon],
          [maxLat, maxLon],
        ],
        { duration: 1 }
      );
    }
  }, [locationData]);

  const {
    data: searchTreePayload,
    invalidateQuery: fetchSearchTreeData,
    isLoading,
  } = useFixedCameraLocations();

  useEffect(() => {
    fetchSearchTreeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // zoom map to selected marker
    if (searchTreeNodeId && map?.mapRef.current) {
      const marker = markerConfig?.markers?.find(
        (target) => target.markerId === searchTreeNodeId
      );
      const currentZoom = map?.mapRef.current.getZoom();
      if (marker) {
        map?.mapRef.current.flyTo(
          [marker.lat, marker.lon],
          currentZoom < 8 ? 8 : currentZoom,
          {
            duration: 1,
          }
        );
      }
    }
  }, [markerConfig?.markers, searchTreeNodeId]);

  const filterConfig = (config: SearchTreeNode[], path: TreePathNodeInfo[]) => {
    if (!path || path.length === 0) return config;
    const newPath = path.slice(0);
    let newConfig;
    const identifier = newPath.shift();
    if (!identifier.wasDeviceFound) {
      newConfig = config.find(
        (element) => element.searchTreeNodeId === identifier.searchTreeNodeId
      );
    } else {
      return config.filter(
        (element) => element.searchTreeNodeId === identifier.searchTreeNodeId
      );
    }
    return filterConfig(newConfig.children, newPath);
  };

  const buildMarkerInfo = (leaf: SearchTreeNode) => {
    const {
      latitude: lat,
      longitude: lon,
      html: tooltip,
      searchTreeNodeId: markerId,
    } = leaf;
    if (!lat || !lon) return undefined;
    const onClick = () => {
      setSearchTreeNodeId(markerId);
    };

    return { markerId, lat, lon, tooltip, onClick };
  };

  const traverseBranch = (branch: SearchTreeNode, markers: Marker[]) => {
    if (branch.searchTreeNodeId && !branch?.children) {
      markers.push(buildMarkerInfo(branch));
    } else {
      branch.children.map((child) => traverseBranch(child, markers));
    }
  };

  const buildFixedCameraMarkers = () => {
    if (!searchTreePayload) return undefined;
    const markers = [];
    filterConfig([searchTreePayload], currentFilter).map((branch) =>
      traverseBranch(branch, markers)
    );
    const actualMarkers = markers.filter((marker) => marker);
    if (actualMarkers.length < 1) return undefined;
    return {
      name: 'Fixed Cameras',
      checked: true,
      markers: actualMarkers,
      onClick: () => {},
    };
  };

  useEffect(() => {
    setMarkerConfig(buildFixedCameraMarkers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTreePayload]);

  return (
    <Widget key={id} loading={isLoading} title="Map" {...props}>
      <MapContext.Provider value={map}>
        <ONCMap
          mapId={FIXED_CAMERA_MAP_ID}
          markerConfig={markerConfig}
          selectedMarkerId={searchTreeNodeId}
          maxZoom={12}
        />
      </MapContext.Provider>
    </Widget>
  );
};

FixedCameraMapWidget.widgetKey = 'fixed-camera-map-widget';
FixedCameraMapWidget.widgetTitle = 'Map';
FixedCameraMapWidget.defaultDataGrid = {
  i: 'fixed-camera-map-widget',
  x: 3,
  y: 0,
  w: 6,
  h: 9,
};

export default FixedCameraMapWidget;
