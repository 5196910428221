import { DenseThemeProvider } from '@onc/theme';
import EndeavourProvider from './context/EndeavourProvider';
import EndeavourEarthquakeDashboard from './dashboard/EndeavourEarthquakeDashboard';

const EndeavourEarthquakeCatalogPage = () => (
  <DenseThemeProvider>
    <EndeavourProvider>
      <EndeavourEarthquakeDashboard />
    </EndeavourProvider>
  </DenseThemeProvider>
);
export default EndeavourEarthquakeCatalogPage;
