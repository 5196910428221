import moment from 'moment';
import Environment from 'util/Environment';

import {
  DAILY_TIMERANGE,
  WEEKLY_TIMERANGE,
  NUMBER_PLOTS_INITIAL_LOAD,
  IMAGE_WIDTH,
  SCROLLING_DIRECTION,
  ADCP_PRODUCT_CODES,
  HYDROPHONE_DEVICE_CATEGORY_CODE,
  ERROR_CODE,
  ERROR_LEVEL,
  DATA_STATE,
  HYDROPHONE_PRODUCT_CODE,
  EXPECTED_WINDOW_SIZE,
  WIDGET_IMAGE_CONTAINER_OFFSET,
  WIDGET_IMAGE_ITEM_DIVISOR,
  WIDGET_IMAGE_ITEM_GAP,
  WIDGET_IMAGE_OFFSET,
  INITIAL_WIDGET_WIDTH,
  WIDGET_WINDOWSIZE_TO_PIXEL_RATIO,
} from './DataPlayerConstants';
import adcpNoDataSplash from '../img/adcp-splash-screen-noData.jpg';
import adcpRestrictedDataSplash from '../img/adcp-splash-screen-restrictedData.jpg';
import adcpRetrievingDataSplash from '../img/adcp-splash-screen-retrievingData.jpg';
import adcpUnknownErrorSplash from '../img/adcp-splash-screen-unknownError.jpg';
import hydrophoneNoDataSplash from '../img/hydrophone-splash-screen-noData.jpg';
import hydrophoneRestrictedDataSplash from '../img/hydrophone-splash-screen-restrictedData.jpg';
import hydrophoneRetrievingDataSplash from '../img/hydrophone-splash-screen-retrievingData.jpg';
import hydrophoneUnknownErrorSplash from '../img/hydrophone-splash-screen-unknownError.jpg';

const images = {
  adcpRetrievingDataSplash,
  adcpNoDataSplash,
  adcpRestrictedDataSplash,
  adcpUnknownErrorSplash,
  hydrophoneRetrievingDataSplash,
  hydrophoneNoDataSplash,
  hydrophoneRestrictedDataSplash,
  hydrophoneUnknownErrorSplash,
};

class DataPlayerHelper {
  /** Helper functions for use in the DataPlayer */
  static timeRangesMissingData = [];

  static isSearchDateInsideOfEmbargo(
    groupId,
    searchStartDate,
    searchEndDate,
    embargoStartDate,
    embargoEndDate
  ) {
    if (groupId === 0 && embargoStartDate === null && embargoEndDate === null) {
      return true;
    }

    if (
      searchStartDate.isSameOrAfter(moment(embargoStartDate)) &&
      searchStartDate.isSameOrBefore(moment(embargoEndDate))
    ) {
      return true;
    }
    if (
      searchEndDate.isSameOrAfter(moment(embargoStartDate)) &&
      searchEndDate.isSameOrBefore(moment(embargoEndDate))
    ) {
      return true;
    }
    return false;
  }

  static checkIfUserHasAccess(
    userGroups,
    restrictions,
    searchDataProductFormatDeviceId,
    searchStartDate,
    searchEndDate
  ) {
    for (
      let userGroupIndex = 0;
      userGroupIndex < userGroups.length;
      userGroupIndex += 1
    ) {
      for (
        let restrictionIndex = 0;
        restrictionIndex < restrictions.length;
        restrictionIndex += 1
      ) {
        if (
          (restrictions[restrictionIndex].groupId ===
            userGroups[userGroupIndex] ||
            userGroups[userGroupIndex] === 0) &&
          restrictions[restrictionIndex].resourceType.resourceTypeId === 1500 &&
          restrictions[restrictionIndex].resourceId ===
            searchDataProductFormatDeviceId &&
          this.isSearchDateInsideOfEmbargo(
            restrictions[restrictionIndex].groupId,
            searchStartDate,
            searchEndDate,
            restrictions[restrictionIndex].dataEmbargoStartDate,
            restrictions[restrictionIndex].dataEmbargoEndDate
          ) &&
          (restrictions[restrictionIndex].privilege === 'NONE' ||
            restrictions[restrictionIndex].privilege === 'RO')
        ) {
          return false;
        }
      }
    }
    return true;
  }

  static sortImagesByDateFromAndRemoveSplash = (files, newFiles) => {
    const filesWithSplashImagesWithSameStartDateRemoved =
      this.removeRetrievingSplashImagesWithSameDateAsImages(files, newFiles);
    // sort the files by datefrom
    return this.sortElementsByDateFrom(
      filesWithSplashImagesWithSameStartDateRemoved,
      newFiles
    );
  };

  static sortElementsByDateFrom = (existingElements, newElements) =>
    existingElements
      .concat(newElements)
      .sort((a, b) => Date.parse(a.dateFrom) - Date.parse(b.dateFrom));

  static removeRetrievingSplashImagesWithSameDateAsImages = (
    files,
    newFiles
  ) => {
    // This needs to be able to handle arrays and single files. Splash images are loaded into
    // the image buffer in one operation and this function is passed an array,
    // while requested images are loaded in one at a time
    if (Array.isArray(newFiles)) {
      const startDates = newFiles.map((newFile) =>
        Date.parse(newFile.dateFrom)
      );
      const filesThatDidNotMatchDateFrom = files.filter(
        (file) => !startDates.includes(Date.parse(file.dateFrom))
      );
      return filesThatDidNotMatchDateFrom;
    }

    const filesThatDidNotMatchDateFrom = files.filter(
      (file) => Date.parse(file.dateFrom) !== Date.parse(newFiles.dateFrom)
    );
    return filesThatDidNotMatchDateFrom;
  };

  static getStartEndDateAndOffset = (
    startDate,
    startTimeRange,
    isDashboard,
    widgetImageWidth,
    rootWidth
  ) => {
    // shift firstDate date back in time from startDate by half of the total time range requested
    const firstDate = moment.utc(startDate).clone(); // convert to UTC and clone for calculations below
    const timeRange = Number(startTimeRange);
    const totalTimeRequested = NUMBER_PLOTS_INITIAL_LOAD * timeRange;
    firstDate.subtract(totalTimeRequested / 2, 'minutes');

    // move a day forward if the start time is betweeen 12 and 20 hours to correct start date from changing to the wrong day
    // causing the offset to be incorrect
    if (
      startTimeRange === WEEKLY_TIMERANGE &&
      moment.utc(startDate).hour() > 11 &&
      moment.utc(startDate).hour() < 20
    ) {
      firstDate.add(DAILY_TIMERANGE, 'minutes');
    }

    // set a reference date for shifting firstDate, either minutes from start of hour for 5 min plots, or minutes
    // from start of day for daily plots, or to midnight on sunday for weekly plots
    const timeSinceLastReferenceMinutes = this.getTimeSinceLastReference(
      firstDate,
      timeRange
    );

    // shift firstDate back in time to 5 min mark for 5 min plots, ie 12:57:12 gets shifted to 12:55:00
    // or shift to start of day for daily plots
    // this is written in a way that we can have requests for n min plots which will be needed in the future for zooming
    // -1 prevents searches with a start time more than 1/2 of the time range from being put on the wrong day
    const numberOfDivisions = Math.floor(
      timeSinceLastReferenceMinutes / timeRange - 1
    );
    // timeShiftMinutes is the number of minutes that firstDate needs to be shifted back to be on 5 min mark, or start of day
    // an example for 5 min plot and daily plot are given below
    // 5 min example
    // if firstDate is 2020-04-10 07:12:30 the timeSinceLastRefrenceMinutes will be 12 minutes (minutes from start of hour)
    // numberOfDivisions = 2, so numberOfDivisions * Number(timeRange) is 10 min
    // 10 min minus timeSinceLastRefrenceMinutes gives us -2
    // -2 minus secondsValueOnfirstDate/60 = -2.5
    // adding -2.5 minutes to the firstDate results in time 2020-04-10 07:10:00 which is what is desired

    // daily example
    // if firstDate is 2020-04-10 07:12:30 the timeSinceLastRefrenceMinutes will be 432 minutes (minutes since start of day)
    // numberOfDivisions = 0, so numberOfDivisions * Number(timeRange) is 0 min
    // 0 min minus timeSinceLastRefrenceMinutes gives us -432
    // -432 minus secondsValueOnfirstDate/60 = -432.5
    // adding -432.5 minutes to the firstDate results in time 2020-04-10 00:00:00 which is what is desired
    const timeShiftMinutes =
      numberOfDivisions * timeRange -
      timeSinceLastReferenceMinutes -
      firstDate.second() / 60 -
      firstDate.milliseconds() / 60000;
    firstDate.utc().add(timeShiftMinutes, 'minutes');
    const lastDate = firstDate.utc().clone().add(totalTimeRequested, 'minutes');
    // calculate additional offset required to place image at time requested
    // this is calculated by first determining the percentage a 5 min/daily plot request time was shifted when the
    // firstDate was changed above (Also since we support odd and even number of images we need to have an offset that is half the time of an image if odd and 0 if even
    // this is due to the total timerange/2 not being an integer value and thus will always have an shift even if the time requested
    // is 00:00:00 so account for the timerange/2 not being an integer by compensating with half an image width)
    // If the time was shifted 3.5 minutes backwards that corresponds to 70% of the image.
    // The translate for the dataviewer is calculated in initializeSpectrogramFiles to set the
    // centre of the images requested at the centre of the dataviewer, however the times of the images requested have been
    // shifted back by 70% therefore we need to shift the images forward in time (to the right) by 70% of an image so that
    // the time that was requested is again at the centre

    // NOTE: Set offsetCorrectionDueToOddNumberImages to 0 if NUMBER_PLOTS_INITIAL_LOAD is even. Otherwise, offsetCorrectionDueToOddNumberImages = Number(timeRange) / 2
    const offsetCorrectionDueToOddNumberImages = timeRange / 2;
    // Allow the timeline to scale with window size
    const windowWidth = window.innerWidth * 0.96;
    const screenDifference = (EXPECTED_WINDOW_SIZE - windowWidth) / 2;
    let translateOffset = Math.round(
      (Math.abs(timeShiftMinutes + offsetCorrectionDueToOddNumberImages) /
        timeRange) *
        IMAGE_WIDTH +
        screenDifference
    );

    // Determine screen difference offset for widget, with respect to dataplayer root width
    let widgetOffset = 0;
    widgetOffset =
      WIDGET_IMAGE_OFFSET +
      (rootWidth - INITIAL_WIDGET_WIDTH) * WIDGET_WINDOWSIZE_TO_PIXEL_RATIO;
    const widgetWidthDiff = INITIAL_WIDGET_WIDTH - rootWidth;
    // Ratio for increasing the offset for shrunken widget resolutions.
    const offsetIncrease = Math.abs(Math.floor(widgetWidthDiff / 27));
    if (rootWidth < INITIAL_WIDGET_WIDTH && widgetWidthDiff > 27) {
      widgetOffset += offsetIncrease;
    } else if (rootWidth < INITIAL_WIDGET_WIDTH && widgetWidthDiff < 27) {
      // if the widget did not shrink by more than 27 pixels, the offset is by an additional 2 pixels.
      widgetOffset += 2;
    }

    // Determine translate percentage for 5 minute plots in the widget
    const timeShiftMod = timeShiftMinutes % timeRange;
    const widgetTranslatePercentage = Math.abs(timeShiftMod / timeRange);
    const widgetImageRightMargin =
      (-widgetImageWidth + WIDGET_IMAGE_CONTAINER_OFFSET) /
        WIDGET_IMAGE_ITEM_DIVISOR -
      WIDGET_IMAGE_ITEM_GAP;
    const croppedWidgetImageWidth = widgetImageWidth + widgetImageRightMargin;
    if (isDashboard) {
      Math.round(
        (translateOffset =
          widgetTranslatePercentage * croppedWidgetImageWidth - widgetOffset)
      );
    }

    const timeRangeSetup = {
      firstDate,
      lastDate,
      translateOffset,
      windowWidth,
    };
    return timeRangeSetup;
  };

  static getTimeSinceLastReference = (firstDate, timeRange) => {
    let timeSinceLastReferenceMinutes;
    const firstDateUTC = moment.utc(firstDate).clone();
    if (timeRange === Number(DAILY_TIMERANGE)) {
      timeSinceLastReferenceMinutes =
        firstDateUTC.minute() + firstDate.hour() * 60;
    } else if (timeRange === Number(WEEKLY_TIMERANGE)) {
      timeSinceLastReferenceMinutes =
        firstDateUTC.utc().toDate().getUTCDay() * DAILY_TIMERANGE +
        firstDateUTC.utc().minute() +
        firstDateUTC.utc().hour() * 60 -
        // Subtract a day from calculation, UTC starts its first day of week on Sunday
        Number(DAILY_TIMERANGE);
    } else {
      timeSinceLastReferenceMinutes = firstDate.minute();
    }
    return timeSinceLastReferenceMinutes;
  };

  static shiftDateFrom = (dateFrom, deltaMinutes) => {
    // this applies the same algorithm that is in getStartEndDateAndOffset without shifting by total time requested, calulating dateTo,
    // or calculating translateOffset
    const timeSinceLastReferenceMinutes = this.getTimeSinceLastReference(
      dateFrom,
      deltaMinutes
    );

    const numberOfDivisions = Math.floor(
      timeSinceLastReferenceMinutes / deltaMinutes
    );

    const timeShiftMinutes =
      numberOfDivisions * Number(deltaMinutes) -
      timeSinceLastReferenceMinutes -
      dateFrom.second() / 60;

    return dateFrom.add(timeShiftMinutes, 'minutes');
  };

  static generateDates = (startDate, endDate, timeRange, direction) => {
    const minutes = Number(timeRange);
    let dateFrom = startDate.utc().clone();
    let dateTo = endDate.utc().clone();

    if (timeRange === DAILY_TIMERANGE || timeRange === WEEKLY_TIMERANGE) {
      dateFrom = moment.utc(startDate.toDate().setUTCHours(0, 0, 0));
      dateTo = moment.utc(endDate.toDate().setUTCHours(0, 0, 0));
    }
    if (timeRange === WEEKLY_TIMERANGE) {
      dateFrom.day('Sunday');
      dateTo.day('Saturday');
    }
    if (direction === SCROLLING_DIRECTION.STATIONARY) {
      // alternate dates
      return this.generateDatesForStationary(startDate, endDate, minutes);
    }
    if (direction === SCROLLING_DIRECTION.LEFT) {
      // endDate to startDate
      return this.generateDatesForLeftScroll(startDate, dateTo, minutes);
    }
    if (direction === SCROLLING_DIRECTION.RIGHT) {
      // startDate to endDate
      return this.generateDatesForRightScroll(endDate, dateFrom, minutes);
    }
    return [];
  };

  static generateDatesForRightScroll = (
    dateToBounds,
    initialDateFrom,
    minutesToAdd
  ) => {
    const dateRanges = [];
    let dateFrom = initialDateFrom.utc().clone();
    let dateTo = initialDateFrom.utc().clone().add(minutesToAdd, 'minutes');
    while (dateTo <= dateToBounds) {
      dateRanges.push({ dateFrom, dateTo });
      dateFrom = dateTo.utc().clone();
      dateTo = dateTo.utc().clone().add(minutesToAdd, 'minutes');
    }
    return dateRanges;
  };

  static generateDatesForLeftScroll = (
    dateFromBounds,
    initialDateTo,
    minutesToSubtract
  ) => {
    const dateRanges = [];
    let dateTo = initialDateTo.utc().clone();
    let dateFrom = initialDateTo
      .utc()
      .clone()
      .subtract(minutesToSubtract, 'minutes');
    while (dateFromBounds <= dateFrom) {
      dateRanges.push({ dateFrom, dateTo });
      dateTo = dateFrom.utc().clone();
      dateFrom = dateFrom.utc().clone().subtract(minutesToSubtract, 'minutes');
    }
    return dateRanges;
  };

  static generateDatesForStationary = (
    dateFromBounds,
    dateToBounds,
    deltaMinutes
  ) => {
    const dateRanges = [];
    // Calculate the midpoint date between between the dateFrom and dateTo bounds. We use it for the initial dateFrom.
    let dateFrom = moment.utc(
      new Date((dateFromBounds.valueOf() + dateToBounds.valueOf()) / 2)
    );

    // since we have an odd number of images requested we need to shift to the 5 min/start of day mark or else the dateFrom will be halfway between request points
    dateFrom = DataPlayerHelper.shiftDateFrom(
      dateFrom,
      deltaMinutes,
      DAILY_TIMERANGE
    );
    let dateTo = dateFrom.utc().clone().add(deltaMinutes, 'minutes');
    // These variables are used to insert the initial values into the list.
    let newDateFrom;
    let newDateTo = dateTo.utc().clone();
    dateTo = dateFrom.utc().clone();
    // boolean to determine whether we go right or left (add or subtract)
    let subtract = true;
    dateFrom = dateTo.utc().clone();
    let counter = 0;
    while (
      counter < NUMBER_PLOTS_INITIAL_LOAD &&
      (dateFromBounds < dateFrom || dateTo < dateToBounds)
    ) {
      counter += 1;
      if (subtract) {
        dateRanges.push({ dateFrom: dateTo, dateTo: newDateTo });
        dateTo = newDateTo.utc().clone();
        newDateFrom = dateFrom.utc().clone().subtract(deltaMinutes, 'minutes');
      } else {
        dateRanges.push({ dateFrom: newDateFrom, dateTo: dateFrom });
        dateFrom = newDateFrom.utc().clone();
        newDateTo = dateTo.utc().clone().add(deltaMinutes, 'minutes');
      }
      subtract = !subtract;
    }
    return dateRanges;
  };

  /** Return splash image for appropriate device and dataState */
  static handleSplashImage = (deviceCategoryCode, dataState) => {
    let splashImage;
    switch (dataState) {
      case DATA_STATE.RETRIEVING:
        if (deviceCategoryCode === HYDROPHONE_DEVICE_CATEGORY_CODE) {
          splashImage = images.hydrophoneRetrievingDataSplash;
        } else {
          splashImage = images.adcpRetrievingDataSplash;
        }
        break;
      case DATA_STATE.RESTRICTED:
        if (deviceCategoryCode === HYDROPHONE_DEVICE_CATEGORY_CODE) {
          splashImage = images.hydrophoneRestrictedDataSplash;
        } else {
          splashImage = images.adcpRestrictedDataSplash;
        }
        break;
      case DATA_STATE.NO_DATA:
        if (deviceCategoryCode === HYDROPHONE_DEVICE_CATEGORY_CODE) {
          splashImage = images.hydrophoneNoDataSplash;
        } else {
          splashImage = images.adcpNoDataSplash;
        }
        break;
      default:
        if (deviceCategoryCode === HYDROPHONE_DEVICE_CATEGORY_CODE) {
          splashImage = images.hydrophoneUnknownErrorSplash;
        } else {
          splashImage = images.adcpUnknownErrorSplash;
        }
    }
    return splashImage;
  };

  /**
   * Return splash image in same format as dataproductdelivery files, based on
   * device and type of error
   */
  static handleSplashImageForRequestAndDownloadError = (
    imageRequestError,
    deviceCategoryCode
  ) => {
    let dataState;
    switch (imageRequestError.errorType) {
      case ERROR_CODE.NO_DATA:
        dataState = DATA_STATE.NO_DATA;
        break;
      case ERROR_CODE.RESTRICTED:
        dataState = DATA_STATE.RESTRICTED;
        break;
      default:
        dataState = DATA_STATE.UNKNOWN;
        break;
    }
    return {
      url: this.handleSplashImage(deviceCategoryCode, dataState),
      dateFrom: imageRequestError.dateFrom,
      dateTo: imageRequestError.dateTo,
      key: imageRequestError.dateFrom,
      filename: dataState,
    };
  };

  /**
   * There are three diffrent types of noData/restricted responses the request
   * service if the device was not deployed during requested time. the request
   * service is there not a file in AD for that day. the download service if
   * there is a file in AD for that day but not data for the specific time
   * requested
   *
   *     This helper loads failed requests with an error code, dateFrom, and dateTo. the error code is
   *      then used in DataViewer when building the results array to load it with appropriate splash images
   */
  static handleImageRequestAndDownloadError = (
    response,
    levelErrorOccured,
    onError
  ) => {
    let errorType;
    if (levelErrorOccured === ERROR_LEVEL.IMAGE_REQUEST_WITH_ERROR_CODE) {
      response.dateFrom = response.config.params.dateFrom;
      response.dateTo = response.config.params.dateTo;
      switch (response.response.data.errors[0].errorCode) {
        case ERROR_CODE.NO_DATA:
          errorType = ERROR_CODE.NO_DATA;
          break;
        case ERROR_CODE.RESTRICTED:
          errorType = ERROR_CODE.RESTRICTED;
          break;
        default:
          errorType = ERROR_CODE.UNKNOWN;
          onError(response);
          break;
      }
    } else if (
      levelErrorOccured === ERROR_LEVEL.IMAGE_REQUEST_WITHOUT_ERROR_CODE
    ) {
      response.dateFrom = response.config.params.dateFrom;
      response.dateTo = response.config.params.dateTo;
      switch (response.data) {
        case 'no data available for the selected type.':
          errorType = ERROR_CODE.NO_DATA;
          break;
        case 'Supplied deviceCategoryCode does not have corresponding dataProductCode and extension.':
          errorType = ERROR_CODE.RESTRICTED;
          break;
        default:
          errorType = ERROR_CODE.UNKNOWN;
          onError(response);
          break;
      }
    } else {
      // download request, return a splash image instead of a request with an error code
      response.dateFrom = response.downloadStatus.dateFrom;
      response.dateTo = response.downloadStatus.dateTo;
      switch (response.error.message) {
        case 'Request failed with status code 404':
          errorType = ERROR_CODE.NO_DATA;
          break;
        default:
          errorType = ERROR_CODE.UNKNOWN;
          onError(response);
          break;
      }
    }
    return {
      dateFrom: response.dateFrom,
      dateTo: response.dateTo,
      errorType,
    };
  };

  static filterPreGeneratedSpectra(dataFileResponse, timeRange) {
    // Remove all thumbnails and small images from the list.
    const filteredResponse = dataFileResponse.data.records.filter((record) => {
      if (timeRange === DAILY_TIMERANGE) {
        return record.filename.includes('spect-DAILY');
      }
      return !(
        record.filename.includes('spect-thumb') ||
        record.filename.includes('spect-small') ||
        record.filename.includes('spect-DAILY')
      );
    });
    return filteredResponse;
  }

  static getAdFilePath = () => `${Environment.getDmasUrl()}/AdFile?filename=`;

  static mapPreGeneratedSpecta(records) {
    return records.map((record) => ({
      dateFrom: record.dateFrom,
      dateTo: record.dateTo,
      filename: record.filename,
      fullPath: record.fullPath,
      url: `${this.getAdFilePath()}${record.filename}`,
      key: record.filename,
    }));
  }

  static formatMissingImageTimes() {
    const missingTimesSorted = DataPlayerHelper.sortElementsByDateFrom(
      [],
      this.timeRangesMissingData
    );
    // extract dateTos
    const dateToMissingTimes = missingTimesSorted.map(
      (missingTime) => missingTime.dateTo
    );
    // extract dateFroms
    const dateFromMissingTimes = missingTimesSorted.map(
      (missingTime) => missingTime.dateFrom
    );
    // get dateFroms and dateTos that are not in both dateFromMissingTimes and dateToMissingTimes
    // these represent the start and end times of unique data gaps
    // in set form: !(dateFromMissingTimes ∩ dateToMissingTimes)
    const startDatesOfDataGaps = dateFromMissingTimes.filter(
      (missingTime) => !dateToMissingTimes.includes(missingTime)
    );
    const endDatesOfDataGaps = dateToMissingTimes.filter(
      (missingTime) => !dateFromMissingTimes.includes(missingTime)
    );
    // build a string of datagaps using the above found start and end dates
    let dataGaps = '';
    for (let i = 0; startDatesOfDataGaps.length > i; i += 1) {
      const gapToAdd =
        i === 0
          ? `There is a data gap from ${startDatesOfDataGaps[i]} to ${endDatesOfDataGaps[i]}`
          : `, and ${startDatesOfDataGaps[i]} to ${endDatesOfDataGaps[i]}`;
      dataGaps = dataGaps.concat(gapToAdd);
    }
    return dataGaps;
  }

  static addToMissingTimes(dateFrom, dateTo) {
    this.timeRangesMissingData.push({ dateFrom, dateTo });
  }

  static clearMissingTimes() {
    this.timeRangesMissingData = [];
  }

  /**
   * We support all of the dataProductCodes defined in ADCP_PRODUCT_CODES for
   * ADCP devices. The codes are mutially exclusive. Find the code this device
   * uses.
   */
  static getSupportedDataProduct(dataProductDiscoveryResponse) {
    const dataProduct = dataProductDiscoveryResponse.data.filter(
      (possibleDataProduct) =>
        ADCP_PRODUCT_CODES.includes(possibleDataProduct.dataProductCode) ||
        possibleDataProduct.dataProductCode === HYDROPHONE_PRODUCT_CODE
    );
    if (dataProduct.length !== 1) {
      return undefined;
    }
    return dataProduct[0];
  }

  static buildReqParamsForAdcpPlotRequest(
    deviceCode,
    startDate,
    endDate,
    dataProductCode,
    dataProductOptionsForServiceCall,
    isRdiDevice
  ) {
    // build base request
    const requestParams = {
      method: 'request',
      dataProductCode,
      dateFrom: startDate.toDate(),
      dateTo: endDate.toDate(),
      deviceCode,
      extension: 'png',
      dpo_velocityBinmapping:
        dataProductOptionsForServiceCall.velocityBinMapping,
      dpo_horizontalcurrentplotlimits:
        dataProductOptionsForServiceCall.horizontalCurrentPlotLimits,
      dpo_verticalcurrentplotlimits:
        dataProductOptionsForServiceCall.verticalCurrentPlotLimits,
      dpo_backScatterColourmap:
        dataProductOptionsForServiceCall.backscatterColourMap,
      dpo_backscatterLowerPlotLimits:
        dataProductOptionsForServiceCall.backscatterLowerPlotLimits,
      dpo_backscatterUpperPlotLimits:
        dataProductOptionsForServiceCall.backscatterUpperPlotLimits,
      dpo_imageSize: dataProductOptionsForServiceCall.imageSize,
    };

    // if it is a rdiDevice then add the dataproduct options for RDI devices
    if (isRdiDevice) {
      requestParams.dpo_corScreen =
        dataProductOptionsForServiceCall.lowCorrelationScreenThreshold;
      requestParams.dpo_3beam =
        dataProductOptionsForServiceCall.threeBeamSolution;
      requestParams.dpo_errVelScreen =
        dataProductOptionsForServiceCall.errCorrelationScreenThreshold;
      requestParams.dpo_falseTarScreen =
        dataProductOptionsForServiceCall.falseTargetMaxThreshold;
    }

    return requestParams;
  }

  static buildReqParamsForHydrophonePlotRequest(
    deviceCode,
    startDate,
    endDate,
    dataProductCode,
    dataProductOptionsForServiceCall,
    timeRange
  ) {
    let spectrogramCollation;
    if (timeRange === DAILY_TIMERANGE) {
      spectrogramCollation = 'Daily';
    } else if (timeRange === WEEKLY_TIMERANGE) {
      spectrogramCollation = 'Weekly';
    } else {
      spectrogramCollation = 'Adjacent';
    }
    return {
      method: 'request',
      dataProductCode,
      dateFrom: startDate.toDate(),
      dateTo: endDate.toDate(),
      deviceCode,
      extension: 'png',
      dpo_spectrogramColourPalette:
        dataProductOptionsForServiceCall.colourPalette,
      dpo_lowerColourLimit: dataProductOptionsForServiceCall.lowerColourLimit,
      dpo_upperColourLimit: dataProductOptionsForServiceCall.upperColourLimit,
      dpo_spectrogramSource: dataProductOptionsForServiceCall.spectrogramSource,
      dpo_hydrophoneAcquisitionMode:
        dataProductOptionsForServiceCall.hydrophoneDataAcquisition,
      dpo_hydrophoneDataDiversionMode:
        dataProductOptionsForServiceCall.hydrophoneDataDiversion,
      dpo_hydrophoneChannel: dataProductOptionsForServiceCall.hydrophoneChannel,
      dpo_spectrogramFrequencyUpperLimit:
        dataProductOptionsForServiceCall.spectrogramFrequencyUpperLimit,
      dpo_spectrogramConcatenation: spectrogramCollation,
    };
  }
}

export default DataPlayerHelper;
