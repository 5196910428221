import { useState, MouseEvent } from 'react';
import * as React from 'react';

import { ArrowDropDown } from '@onc/icons';
import { IconButton, Menu, MenuItem } from 'base-components';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';

interface Props {
  onClick: (valueClicked: string) => void;
  initialValue: string;
  options: string[];
  classes: { buttonGroupStyle: string; buttonStyle: string };
}

const SelectButton: React.VFC<Props> = ({
  onClick,
  initialValue,
  classes,
  options,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [value, setValue] = useState<string>(initialValue);

  const handleExpandClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (
    _event: MouseEvent<HTMLLIElement>,
    item: string
  ) => {
    handleClose();
    setValue(item);
  };

  const handleButtonClick = () => {
    onClick(value);
  };

  const createMenuItems = (option: string) => (
    <MenuItem
      onClick={(event) => handleOptionClick(event, option)}
      key={option}
    >
      {option}
    </MenuItem>
  );

  const menuItems = options.map(createMenuItems);
  return (
    <>
      <ContainedButton
        translationKey="device.downloadValue"
        translationOptions={{ value }}
        onClick={handleButtonClick}
        className={classes.buttonStyle}
        aria-label="Select Button"
      />
      <IconButton
        size="small"
        onClick={handleExpandClick}
        className={classes.buttonGroupStyle}
        aria-label="Menu Button"
      >
        <ArrowDropDown />
      </IconButton>
      <Menu open={menuOpen} onClose={handleClose} anchorEl={anchorEl}>
        {menuItems}
      </Menu>
    </>
  );
};

export default SelectButton;
