import { useState, useEffect } from 'react';
import * as React from 'react';
import SeaTubePlaylistFiles from 'library/CompositeComponents/video/SeaTubePlaylistFiles';
import PlaylistItemEndCard from './PlaylistItemEndCard';
import VideoNotFoundError from './VideoNotFoundError';

interface WithPlaylistLineIdProps {
  autoPlay: boolean;
  files: SeaTubePlaylistFiles[];
  playerId: string;
  playlistLineId: number;
  onQualityChange: (resolution: any) => void;
  onVideoChange: (playlistLineId: number) => void;
}

const withPlaylistLineId = () => (VideoComponent: React.ComponentType<any>) => {
  const WithPlaylistLineId: React.FC<WithPlaylistLineIdProps> = ({
    autoPlay,
    files,
    playerId,
    playlistLineId,
    onQualityChange,
    onVideoChange,
  }: WithPlaylistLineIdProps) => {
    // State  Variables
    const [seekTo, setSeekTo] = useState<number | undefined>(undefined);
    const [index, setIndex] = useState<number>(0);
    const [showEndCard, setShowEndCard] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [fileState, setFileState] = useState<
      SeaTubePlaylistFiles[] | undefined
    >(files);

    // Update the current index and seekTo values whenever files or playlistLineId changes
    useEffect(() => {
      setError(false);
      const date = files[0]?.getDateAt(0);
      const newIndex = files?.findIndex(
        (line) => line.playlistLineId === playlistLineId
      );
      if (!date || newIndex === -1 || !files[newIndex]) {
        setError(true);
        return;
      }
      setFileState(files);
      setIndex(newIndex);
      setSeekTo(files[newIndex].getDateAt(0).getTime() / 1000);
    }, [playlistLineId, files]);

    const updateSeekTo = (elapsed: number) => {
      setSeekTo(fileState[index].getDateAt(elapsed).getTime() / 1000);
    };

    const handleVideoLoad = () => {
      setShowEndCard(false);
      window.jwplayer(playerId).play();
    };

    const handleReplay = () => {
      updateSeekTo(0);
      setShowEndCard(false);
    };

    const handleNext = () => {
      if (fileState.length === 0) return;
      let nextFile = fileState[0];
      if (index < fileState.length - 1) {
        nextFile = fileState[index + 1];
      }
      updateSeekTo(0);
      onVideoChange(nextFile.playlistLineId);
    };

    const handlePrevious = () => {
      if (fileState.length === 0) return;
      let previousFile = fileState[0];
      if (index > 0) {
        previousFile = fileState[index - 1];
      }
      onVideoChange(previousFile.playlistLineId);
    };

    const handlePlaylistComplete = () => {
      updateSeekTo(0.1);
      autoPlay ? handleNext() : setShowEndCard(true);
    };

    if (error || files.length === 0) return <VideoNotFoundError />;

    const hasNext = index < fileState.length - 1;
    const hasPrevious = index > 0;

    return (
      <>
        {showEndCard && (
          <PlaylistItemEndCard
            onNext={hasNext && handleNext}
            onPrevious={hasPrevious && handlePrevious}
            onReplay={handleReplay}
          />
        )}
        <VideoComponent
          playerId={playerId}
          onVideoLoad={handleVideoLoad}
          playlistLineId={playlistLineId}
          files={fileState[index]}
          onQualityChange={onQualityChange}
          onPlaylistComplete={handlePlaylistComplete}
          onNext={hasNext && handleNext}
          onPrevious={hasPrevious && handlePrevious}
          seekTo={seekTo}
        />
      </>
    );
  };

  return WithPlaylistLineId;
};

export default withPlaylistLineId;
