import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

const DEFAULT_SITE_DEVICE_SUBSET_SERVICE = 'SiteDeviceSubsetService';
const CAST_SITE_DEVICE_SUBSET_SERVICE = 'CastSiteDeviceSubsetService';
const UPDATE_OPERATION = 2;

export interface SiteDeviceSubset {
  type: string;
  siteDeviceId: number;
  startDate: Date;
  endDate: Date;
  siteDeviceSubsetTypeId: number;
  generationTypeId: number;

  siteDeviceSubsetId?: number;
  siteDeviceSubsetName?: string;
  referenceLat?: number;
  referenceLon?: number;
  referenceDepth?: number;
  geospatialArea?: string;
  comment?: string;
}

class SiteDeviceSubsetService {
  /*
   * internal helpers
   */
  static getSiteDeviceSubsetServiceToUse = async (
    siteDeviceSubsetType: number
  ) => {
    const castSiteDeviceSubsetTypes = await this.getCastSiteDeviceSubsetTypes();
    return castSiteDeviceSubsetTypes.includes(siteDeviceSubsetType)
      ? CAST_SITE_DEVICE_SUBSET_SERVICE
      : DEFAULT_SITE_DEVICE_SUBSET_SERVICE;
  };

  /*
   * Get requests in order of operation number -> use DEFAULT_SITE_DEVICE_SUBSET_SERVICE
   */
  static getAllSiteDeviceSubsets = (expanded?: boolean) =>
    get(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      { operation: 5, expanded },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static getSiteDeviceSubsetsForSiteDevice = (siteDeviceId: number) =>
    get(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 7,
        siteDeviceId,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static getGeospatialArea = (siteDeviceSubsetId: number) =>
    get(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 8,
        siteDeviceSubsetId,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static getSiteDeviceSubsetTypes = () =>
    get(DEFAULT_SITE_DEVICE_SUBSET_SERVICE, { operation: 9 }, undefined).then(
      (response) => parseDmasAPIResponse(response)
    );

  static getGenerationTypes = () =>
    get(DEFAULT_SITE_DEVICE_SUBSET_SERVICE, { operation: 10 }, undefined).then(
      (response) => parseDmasAPIResponse(response)
    );

  static getOverlappingSiteDeviceSubsetsBySiteDevice = (
    siteDeviceId: number,
    startDate: Date,
    endDate: Date
  ) =>
    get(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 11,
        siteDeviceId,
        startDate,
        endDate,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static getOverlappingSiteDeviceSubsetsByDevice = (
    deviceId: number,
    startDate: Date,
    endDate: Date
  ) =>
    get(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 16,
        deviceId,
        startDate,
        endDate,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static getCastSiteDeviceSubsetTypes = () =>
    get(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 12,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static getCastReviewed = (siteDeviceSubsetId: number) =>
    get(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 13,
        siteDeviceSubsetId,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static getUnassignedCasts = () =>
    get(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 14,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static getRestrictionInfo = (
    siteDeviceSubsetId: number,
    dataProductFormatId: number
  ) =>
    get(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 15,
        siteDeviceSubsetId,
        dataProductFormatId,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  /*
   * Cast only post service calls, parse the response to throw an error if a failing status code is returned
   */
  static editCastStartEndDates = (
    siteDeviceSubsetId: number,
    startDate: Date | string,
    endDate: Date | string,
    comment: string
  ) =>
    post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: UPDATE_OPERATION,
        method: 'editStartEndDates',
        siteDeviceSubsetId,
        startDate,
        endDate,
        comment,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static editCastStartDate = (
    siteDeviceSubsetId: number,
    startDate: Date | string,
    comment: string
  ) =>
    post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: UPDATE_OPERATION,
        method: 'editStartDate',
        siteDeviceSubsetId,
        startDate,
        comment,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static editCastEndDate = (
    siteDeviceSubsetId: number,
    endDate: Date | string,
    comment: string
  ) =>
    post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: UPDATE_OPERATION,
        method: 'editEndDate',
        siteDeviceSubsetId,
        endDate,
        comment,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static editCastRefLatLon = (
    siteDeviceSubsetId: number,
    referenceLat: number,
    referenceLon: number,
    comment: string
  ) =>
    post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: UPDATE_OPERATION,
        method: 'editRefLatLon',
        siteDeviceSubsetId,
        referenceLat,
        referenceLon,
        comment,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static editCastRefLat = (
    siteDeviceSubsetId: number,
    referenceLat: number,
    comment: string
  ) =>
    post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: UPDATE_OPERATION,
        method: 'editRefLat',
        siteDeviceSubsetId,
        referenceLat,
        comment,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static editCastRefLon = (
    siteDeviceSubsetId: number,
    referenceLon: number,
    comment: string
  ) =>
    post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: UPDATE_OPERATION,
        method: 'editRefLon',
        siteDeviceSubsetId,
        referenceLon,
        comment,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static reprocessCast = (siteDeviceSubsetId: number, comment: string) =>
    post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: UPDATE_OPERATION,
        method: 'reprocess',
        siteDeviceSubsetId,
        comment,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static reprocessCastRefLatLon = (
    siteDeviceSubsetId: number,
    comment: string
  ) =>
    post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: UPDATE_OPERATION,
        method: 'reprocessRefLatLon',
        siteDeviceSubsetId,
        comment,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static setCastReviewedStatus = (
    siteDeviceSubsetId: number,
    castReviewed: boolean,
    comment?: string
  ) => {
    const params: {
      siteDeviceSubsetId: number;
      castReviewed: boolean;
      comment?: string;
    } = {
      siteDeviceSubsetId,
      castReviewed,
    };
    // include comment if it has been set
    if (comment !== undefined && comment !== '') {
      params.comment = comment;
    }
    return post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: UPDATE_OPERATION,
        method: 'review',
        ...params,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));
  };

  static createCastAndProcessData = (
    deviceId: number,
    startDate: Date | string,
    endDate: Date | string,
    siteDeviceSubsetTypeId: number
  ) =>
    post(
      CAST_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 1,
        deviceId,
        startDate,
        endDate,
        siteDeviceSubsetTypeId,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  /*
   * Cast and non cast, methods
   * will use siteDeviceSubsetType to find correct service to use
   */
  static deleteSiteDeviceSubset = async (
    siteDeviceSubsetTypeId: number,
    siteDeviceSubsetId: number
  ) => {
    const serviceToUse = await this.getSiteDeviceSubsetServiceToUse(
      siteDeviceSubsetTypeId
    );
    const operation = 3;
    return post(
      serviceToUse,
      {
        type: 'siteDeviceSubset', // type is only needed for non casts but it is fine to include for casts as well
        operation,
        siteDeviceSubsetId,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));
  };

  /*
   * non cast post
   */
  static saveSiteDeviceSubsetWithoutProcessingData = (
    params: SiteDeviceSubset
  ) =>
    post(
      DEFAULT_SITE_DEVICE_SUBSET_SERVICE,
      {
        operation: 1,
        ...params,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));
}

export default SiteDeviceSubsetService;
