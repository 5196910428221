class BroadSearchGroupingGenerator {
  static getDefaultGrouping = (generator) => {
    switch (generator) {
      case 'GenerateByTaxons':
        return [{ columnName: 'taxonPaths' }];
      case 'GenerateByResources':
        return [{ columnName: 'videoResourceTypeName' }];
      case 'GenerateByOrganizations':
      case 'SplitCommentOverAllResources':
      case 'SplitCommentOverPages':
        return [{ columnName: 'videoResourceName' }];
      default:
        return [];
    }
  };
}

export default BroadSearchGroupingGenerator;
