import * as React from 'react';
import L from 'leaflet';

interface Props {
  position: L.ControlPosition;
  children?: React.ReactNode;
  container?: React.HTMLAttributes<HTMLDivElement>;
  prepend?: boolean;
}

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

/**
 * Code taken from
 * https://github.com/chris-m92/react-leaflet-custom-control/blob/master/src/Control.tsx
 * This code should wrap other base components in this directory for use with
 * Maps. See ZoomControl for an example
 */
const Control = ({
  position,
  container = undefined,
  prepend = false,
  children = undefined,
}: Props): JSX.Element => {
  const [portalRoot, setPortalRoot] = React.useState<any>(
    document.createElement('div')
  );
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  const controlContainerRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    const targetDiv = document.getElementsByClassName(positionClass);
    setPortalRoot(targetDiv[0]);
  }, [positionClass]);

  React.useEffect(() => {
    if (
      portalRoot !== null &&
      !portalRoot.contains(controlContainerRef.current)
    ) {
      if (prepend !== undefined && prepend === true) {
        portalRoot.prepend(controlContainerRef.current);
      } else {
        portalRoot.append(controlContainerRef.current);
      }
    }
  }, [portalRoot, prepend, controlContainerRef]);

  const className = `${container?.className?.concat(' ') || ''}leaflet-control`;
  return (
    <div {...container} ref={controlContainerRef} className={className}>
      {children}
    </div>
  );
};

export default Control;
