import { parseDmasAPIResponse, get } from 'util/WebRequest';

const SERVICE = 'UserManagementService';

export interface UserManagementJSON {
  dmasUserId: number;
  firstname: string;
  lastname: string;
  email: string;
  oncDepartment?: string;
  phone?: string;
  cellphone?: string;
  title?: string;
}

class UserManagementService {
  static getUserDetails = async (userId: number) => {
    const response = await get(SERVICE, { operation: 3, userId }, {});
    return parseDmasAPIResponse(response);
  };

  static getUsersInGroup = async (groupId: number) => {
    const response = await get(SERVICE, { operation: 6, groupId }, null);
    return parseDmasAPIResponse(response);
  };
}

export default UserManagementService;
