export const AbsoluteRightButtonStyle = (theme) => ({
  right: theme.spacing(1),
  minWidth: theme.spacing(32),
  maxHeight: theme.spacing(5),
  position: 'absolute',
  zIndex: '4',
});

export const FloatRightButtonStyles = {
  float: 'right',
  minWidth: '150px',
  maxHeight: '40px',
};

export const FloatRightButtonWideStyles = {
  float: 'right',
  minWidth: '100px',
  maxHeight: '60px',
};

export const FloatRightButtonLowStyles = {
  right: '8px',
  minWidth: '270px',
  maxHeight: '40px',
  top: '56px',
  position: 'absolute',
  zIndex: '4',
};
