import { DataTypeProvider } from '@devexpress/dx-react-grid';

const BooleanFormatter = ({ value }: DataTypeProvider.ValueFormatterProps) => {
  if (value === null || typeof value === 'undefined') {
    return null;
  }
  return value ? <> True </> : <> False </>;
};

export default BooleanFormatter;
