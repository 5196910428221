import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ErrorSnackbar } from '@onc/composite-components';
import { post, get } from 'util/WebRequest';

const OPERATION_GET = 4;
const OPERATION_UPDATE = 2;

const DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

const withWidgetId = (widgetId) => (Widget) => {
  class WithWidgetId extends PureComponent {
    static propTypes = {
      onConfigFinish: PropTypes.func,
    };

    static defaultProps = {
      onConfigFinish: () => {},
    };

    reviver = (_, value) => {
      if (typeof value === 'string' && DATE_FORMAT.test(value)) {
        return new Date(value);
      }
      return value;
    };

    getSaveWebRequest = (values) => {
      const widgetConfig = JSON.stringify(values);
      return post('/WidgetService', {
        operation: OPERATION_UPDATE,
        widgetConfig,
        widgetId,
      });
    };

    state = {
      layoutId: undefined,
      widgetConfig: undefined,
      config: undefined,
      error: null,
    };

    componentDidMount() {
      this.fetchWidgetConfig();
    }

    handleSave = async (values) => {
      const { onConfigFinish } = this.props;
      await this.saveWidgetConfig(values);
      onConfigFinish(values);
    };

    fetchWidgetConfig = async () =>
      get('/WidgetService', {
        operation: OPERATION_GET,
        widgetId,
      })
        .then((response) => {
          this.updateStateFromResponse(response);
        })
        .catch((error) => {
          this.setState({ error });
        });

    saveWidgetConfig = async (values) =>
      this.getSaveWebRequest(values)
        .then((response) => {
          this.updateStateFromResponse(response);
        })
        .catch((error) => {
          this.setState({ error });
        });

    updateStateFromResponse = (response) => {
      if (response.data.statusCode === 0) {
        if (response.data.payload.widget) {
          const config = JSON.parse(
            response.data.payload.widget.widgetConfig,
            this.reviver
          );
          this.setState({
            layoutId: response.data.payload.widget.layoutId,
            widgetConfig: response.data.payload.widget.widgetConfig,
            config,
          });
        } else {
          this.setState({ error: 'no data found' });
        }
      } else {
        // There was an error
        this.setState({ error: response.data });
      }
    };

    render() {
      const { config, error } = this.state;

      let errorComp = null;
      if (error) {
        errorComp = <ErrorSnackbar message={error.message} />;
      }

      let widgetComp = '';
      if (config) {
        widgetComp = (
          <Widget onSave={this.handleSave} {...config} {...this.props} />
        );
      }

      if (config || error) {
        return (
          <>
            {errorComp}
            {widgetComp}
          </>
        );
      }
      return null;
    }
  }

  return WithWidgetId;
};

export default withWidgetId;
