import useFetch from 'util/hooks/useFetch';

type DevicesParams = {
  deviceCode?: string;
  deviceId?: number;
  deviceName?: string;
  includeChildren?: boolean;
  dataProductCode?: string;
  locationCode?: string;
  deviceCategoryCode?: string;
  propertyCode?: string;
  dateFrom?: string;
  dateTo?: string;
};

type DataRating = {
  dateFrom: string;
  dateTo: string | null;
  samplePeriod: number;
  sampleSize: number;
};

type Device = {
  deviceCode: string;
  deviceId: number;
  deviceName: string;
  deviceCategoryCode: string;
  deviceLink: string;
  dataRating: DataRating;
  cvTerm: object;
};

const useDevices = (params: DevicesParams) =>
  useFetch<Device[], DevicesParams>(
    '/apiproxy/devices',
    { queryKey: ['devices'] },
    { params },
    (response) => response.data as Device[]
  );

export default useDevices;
