class Time {
  /** Converts a numeric value in seconds to a time representation ##:##:##. */
  static toTimeString(inputNumber) {
    const value = Math.round(inputNumber);
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = value - hours * 3600 - minutes * 60;

    let result = seconds.toString();
    if (seconds < 10) {
      result = `0${result}`;
    }
    result = `${minutes}:${result}`;
    if (minutes < 10) {
      result = `0${result}`;
    }

    if (hours === 0) return result;
    result = `${hours}:${result}`;
    if (hours < 10) result = `0${result}`;
    return result;
  }

  /**
   * Currently assumes time is of the format
   *
   * - 00:00 OR,
   * - 00:00:00
   */
  static getTimestampAsSeconds(time) {
    let timestamp = time;
    if (time.split(':').length === 2) {
      timestamp = `00:${timestamp}`;
    }
    return new Date(`1970-01-01T${timestamp}Z`).getTime() / 1000;
  }

  static convertSecondsToMilliseconds(seconds) {
    return seconds * 1000;
  }

  static convertMillisecondsToSeconds(milliseconds) {
    return milliseconds / 1000;
  }
}

export default Time;
