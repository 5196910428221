import useMediaQuery from '@mui/material/useMediaQuery';
import { withStyles, withTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BugReport } from '@onc/icons';
import { DenseThemeProvider } from '@onc/theme';
import { Chip, Grid } from 'base-components';

import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateUtils from 'util/DateUtils';
import TestActionsBar from './TestActionsBar';
import Status from '../Status';
import StatusIcon from '../StatusIcon';

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },

  fabContainer: {
    position: 'fixed',
  },
});
const TestHistoryTable = (props) => {
  const { theme, handleRowClicked, handleStopTest, historyList, match } = props;
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const formatJiraChips = (jiraIssues) =>
    jiraIssues.map((item) => (
      <Chip
        key={item}
        icon={<BugReport />}
        label={item}
        onClick={() =>
          window.open(`https://jira.oceannetworks.ca/browse/${item}`)
        }
        color="secondary"
        clickable
      />
    ));

  const getColumns = () => {
    if (mediumScreen) {
      return [
        { title: 'Status', name: 'status' },
        { title: 'Test Id', name: 'dafTestId' },
        { title: 'Device Id', name: 'deviceId' },
        { title: 'Device Type Id', name: 'deviceTypeId' },
        { title: ' ', name: 'actions' },
      ];
    }

    if (smallScreen) {
      return [
        { title: 'Status', name: 'status' },
        { title: 'Test Id', name: 'dafTestId' },
        { title: 'Device Id', name: 'deviceId' },
        { title: 'Device Type Id', name: 'deviceTypeId' },
      ];
    }

    return [
      { title: 'Status', name: 'status' },
      { title: 'Test Id', name: 'dafTestId' },
      { title: 'Device Id', name: 'deviceId' },
      { title: 'Device Type Id', name: 'deviceTypeId' },
      { title: 'Date Started', name: 'startDate' },
      { title: 'Date Completed', name: 'endDate' },
      { title: 'Run By', name: 'user' },
      { title: 'Jira Issues', name: 'jiraIssues' },
      { title: ' ', name: 'actions' },
    ];
  };

  const getStatusIcon = (status) => (
    <StatusIcon status={status} showText={!smallScreen} />
  );

  const compareById = (a, b) => {
    const priorityA = a.props.children;
    const priorityB = b.props.children;
    if (priorityA === priorityB) {
      return 0;
    }
    return priorityA < priorityB ? -1 : 1;
  };

  const filterById = (value, filter) =>
    String(value.props.children).includes(String(filter.value));

  const compareByStatus = (a, b) => {
    const statusWeights = {
      RUNNING: 3,
      CANCELLING: 2,
      COMPLETED: 1,
      ERROR: 0,
    };
    const priorityA = statusWeights[a.props.status];
    const priorityB = statusWeights[b.props.status];
    if (priorityA === priorityB) {
      return 0;
    }
    return priorityA < priorityB ? -1 : 1;
  };

  const filterByStatus = (value, filter) => {
    if (
      value.props.status === Status.CANCELLING &&
      'STOPPING'.startsWith(filter.value.toUpperCase())
    ) {
      return true;
    }
    if (
      value.props.status === Status.COMPLETED &&
      'PASSED'.startsWith(filter.value.toUpperCase())
    ) {
      return true;
    }
    return value.props.status.startsWith(filter.value.toUpperCase());
  };

  const filterByJiraTicket = (value, filter) =>
    value &&
    value.some((ticket) =>
      String(ticket.key)
        .toUpperCase()
        .includes(String(filter.value).toUpperCase())
    );

  const renderTableRows = () =>
    historyList
      .filter((test) => test.user.dmasUserId !== 21837)
      .map((test) => ({
        dafTestId: (
          <Link to={`${match.path}/test/${test.dafTestId}`}>
            {test.dafTestId}
          </Link>
        ),
        deviceId: test.deviceId || '',
        deviceTypeId: test.device.deviceType.deviceTypeId || '',
        startDate: DateUtils.formatDateAsString(new Date(test.startDate)),
        endDate: DateUtils.formatDateAsString(new Date(test.endDate)),
        status: getStatusIcon(test.status),
        user: `${test.user.firstname} ${test.user.lastname}`,
        jiraIssues: formatJiraChips(test.jiraIssues),
        actions: <TestActionsBar test={test} handleStopTest={handleStopTest} />,
      }));

  const columnExtensions = [
    { columnName: 'jiraIssues', wordWrapEnabled: true },
    { columnName: 'dafTestId', wordWrapEnabled: true },
    { columnName: 'deviceId', wordWrapEnabled: true },
    { columnName: 'deviceTypeId', wordWrapEnabled: true },
    { columnName: 'status', wordWrapEnabled: true },
    { columnName: 'startDate', wordWrapEnabled: true },
    { columnName: 'endDate', wordWrapEnabled: true },
    { columnName: 'user', wordWrapEnabled: true },
    { columnName: 'actions', wordWrapEnabled: true },
  ];

  return (
    <DenseThemeProvider>
      <Grid container>
        <Grid item xs={12}>
          <SortableTable
            columns={getColumns()}
            rows={renderTableRows()}
            pageSize={50}
            searchable
            rowOnClick={(row) => {
              handleRowClicked(row.dafTestId.props.children);
            }}
            IntegratedFilteringExtensions={[
              {
                columnName: 'dafTestId',
                predicate: filterById,
              },
              {
                columnName: 'status',
                predicate: filterByStatus,
              },
              {
                columnName: 'jiraIssues',
                predicate: filterByJiraTicket,
              },
            ]}
            columnExtensions={columnExtensions}
            sort={[
              {
                columnName: 'dafTestId',
                direction: 'desc',
              },
              {
                columnName: 'status',
                direction: 'desc',
              },
            ]}
            sortExtensions={[
              {
                columnName: 'dafTestId',
                compare: compareById,
              },
              {
                columnName: 'status',
                compare: compareByStatus,
              },
            ]}
          />
        </Grid>
      </Grid>
    </DenseThemeProvider>
  );
};

TestHistoryTable.propTypes = {
  theme: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  handleRowClicked: PropTypes.func.isRequired,
  handleStopTest: PropTypes.func.isRequired,
  historyList: PropTypes.arrayOf(
    PropTypes.shape({
      dafTestId: PropTypes.number,
      deviceId: PropTypes.number,
      device: PropTypes.shape({
        deviceType: PropTypes.shape({
          deviceTypeId: PropTypes.number,
        }),
      }),
      duration: PropTypes.shape({
        seconds: PropTypes.number.isRequired,
      }).isRequired,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default withTheme(withStyles(styles)(TestHistoryTable));
