import { parseDmasAPIResponse, get } from 'util/WebRequest';

class QaqcAppliedTestsService {
  static get(sensorId) {
    return get('QaqcAppliedTestsService', {
      sensorId,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default QaqcAppliedTestsService;
