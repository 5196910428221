import { parseDmasAPIResponse, get } from 'util/WebRequest';

class LastSensorReadingsService {
  static get(deviceid) {
    return get('LastSensorReadingsService', {
      deviceid,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default LastSensorReadingsService;
