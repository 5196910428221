/* eslint-disable react/no-unused-prop-types */
import { makeStyles, createStyles } from '@mui/styles';
import { Moment } from 'moment';
import {
  ToggleButton,
  Collapse,
  Grid,
  TypographyWithTranslation,
} from 'base-components';
import { type option } from 'domain/AppComponents/dropdowns/PlaylistsDropdown';
import PlaylistsDropdown from 'domain/AppComponents/dropdowns/PlaylistsDropdown';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';
import FormToggleButtonGroup from 'library/CompositeComponents/form/FormToggleButtonGroup';
import { validateUrl } from 'library/CompositeComponents/form/FormValidator';
import {
  FormDataAvailabilityChart,
  FormDataSourceList,
  FormDeviceAutocomplete,
} from './VideoWidgetFormControllers';

const useStyles = makeStyles(() =>
  createStyles({
    formButtons: {
      marginTop: 10,
      float: 'right',
    },
    titleField: {
      marginTop: 0,
    },
  })
);

const EXTENSIONS = ['mov', 'avi', 'mp4', 'ogg', 'mpg', 'mp2'];

export type VideoWidgetConfigProps = {
  title?: string;
  liveStreamUrl?: string;
  playlistHdr?: option;
  device?: {
    deviceId: number;
    name: string;
    deviceCode: string;
  };
  timeSource?: string;
  locations?: {
    deviceCategoryCode: string;
    stationCode: string;
    pathName: string[];
    els: { deviceId: number; sitedeviceid: number }[];
  }[];
  loopPlayback?: boolean;
  showTitle: boolean;
  dataSource: string;
  startDate: Moment;
  endDate: Moment;
};

export type VideoWidgetFormType = Omit<
  VideoWidgetConfigProps,
  'endDate' | 'startDate'
> & {
  endDate?: Moment;
  startDate?: Moment;
};

const VideoWidgetFormContents = ({
  dataSource,
  timeSource = 'latestClip',
  device = {
    deviceId: undefined,
    name: undefined,
    deviceCode: undefined,
  },
  locations = [],
  showTitle,
}: VideoWidgetFormType) => {
  const classes = useStyles();

  const renderLoopPlaybackToggle = () => {
    if (
      (dataSource === 'deviceId' || dataSource === 'location') &&
      timeSource === 'latestClip'
    ) {
      return (
        <Grid item xs={12}>
          <FormToggle name="loopPlayback" label="Loop Playback" disableRipple />
        </Grid>
      );
    }
    return undefined;
  };

  const renderDeviceDataAvailability = () => (
    <Collapse in={device !== undefined}>
      <FormDataAvailabilityChart
        name="timeRange"
        canSelectDateRange
        dataSources={[
          {
            dataSourceType: 'device',
            deviceCode: device ? device.deviceCode : undefined,
            extensions: EXTENSIONS,
          },
        ]}
      />
    </Collapse>
  );

  const renderLocationDataAvailability = () => {
    const newDeviceMap = [];
    locations.forEach((node) => {
      newDeviceMap.push({
        dataSourceType: 'location',
        locationCode: node.stationCode,
        deviceCategoryCode: node.deviceCategoryCode,
        extensions: EXTENSIONS,
      });
    });
    return (
      <Collapse in={locations && locations.length > 0}>
        <FormDataAvailabilityChart
          name="timeRange"
          canSelectDateRange
          dataSources={newDeviceMap}
        />
      </Collapse>
    );
  };

  const renderDataAvailability = () => {
    if (dataSource === 'location') {
      return renderLocationDataAvailability();
    }
    if (dataSource === 'deviceId') {
      return renderDeviceDataAvailability();
    }
    return null;
  };

  const renderDatePickers = () => (
    <Collapse in={timeSource === 'timeRange'}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormDateTimePicker
            translationKey="common.datepickers.startDate"
            fullWidth
            name="startDate"
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateTimePicker
            translationKey="common.datepickers.endDate"
            fullWidth
            name="endDate"
          />
        </Grid>
      </Grid>
    </Collapse>
  );

  const renderDateSelect = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {renderDataAvailability()}
      </Grid>
      <Grid item xs={12}>
        <TypographyWithTranslation
          variant="subtitle1"
          translationKey="dashboards.dateSelect"
        />
        <FormToggleButtonGroup exclusive requireSelection name="timeSource">
          <ToggleButton value="latestClip">Latest</ToggleButton>
          <ToggleButton value="timeRange">Date Range</ToggleButton>
        </FormToggleButtonGroup>
        {renderDatePickers()}
      </Grid>
    </Grid>
  );

  const renderDataSourceSelection = () => {
    if (dataSource === 'playlist') {
      return <PlaylistsDropdown name="playlistHdr" fullWidth />;
    }
    if (dataSource === 'location') {
      return (
        <>
          <FormDataSourceList
            name="locations"
            propertyPreset={['Video']}
            treeTypePreset="instrumentsByLocation"
          />
          {renderDateSelect()}
        </>
      );
    }
    if (dataSource === 'liveStream') {
      return (
        <FormTextField
          name="liveStreamUrl"
          translationKey="seatube.liveStreamUrl"
          fullWidth
          rules={{
            required: 'A url is required!',
            validate: (liveStreamUrl) =>
              validateUrl(liveStreamUrl, 'A valid URL is required'),
          }}
        />
      );
    }
    if (dataSource === 'deviceId') {
      return (
        <>
          <Grid item xs={12}>
            <FormDeviceAutocomplete name="device" />
          </Grid>
          {renderDateSelect()}
        </>
      );
    }
    return null;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormToggle label="Show Title" name="showTitle" />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showTitle}>
          <FormTextField
            fullWidth
            translationKey="common.textfields.title"
            name="title"
            className={classes.titleField}
          />
        </Collapse>
      </Grid>
      <TypographyWithTranslation
        variant="subtitle1"
        translationKey="dashboards.dataSource"
      />
      <Grid item xs={12}>
        <FormToggleButtonGroup
          exclusive
          name="dataSource"
          label="Data Source"
          requireSelection
        >
          <ToggleButton value="deviceId"> Device Id </ToggleButton>
          <ToggleButton value="liveStream"> Livestream </ToggleButton>
          <ToggleButton value="location"> Location </ToggleButton>
          <ToggleButton value="playlist"> Playlist </ToggleButton>
        </FormToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        {renderDataSourceSelection()}
        {renderLoopPlaybackToggle()}
      </Grid>
    </Grid>
  );
};

export default VideoWidgetFormContents;
