import { Component } from 'react';
import { withTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { Responsive, WidthProvider } from 'react-grid-layout';
import LayoutPropType from './LayoutPropType';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './GridLayout.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// Material UI theme object to get breakpoint values
/** See https://github.com/STRML/react-grid-layout for full props and usage. */
class ResponsiveGridLayout extends Component {
  static propTypes = {
    breakpoints: PropTypes.shape({
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
      xs: PropTypes.number,
      xxs: PropTypes.number,
    }),
    cols: PropTypes.shape({
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
      xs: PropTypes.number,
      xxs: PropTypes.number,
    }),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    isStatic: PropTypes.bool,
    layouts: PropTypes.shape({
      lg: LayoutPropType,
      md: LayoutPropType,
      sm: LayoutPropType,
      xs: LayoutPropType,
      xxs: LayoutPropType,
    }),
    theme: PropTypes.shape({
      breakpoints: PropTypes.shape({
        values: PropTypes.shape({
          xl: PropTypes.number,
          lg: PropTypes.number,
          md: PropTypes.number,
          sm: PropTypes.number,
          xs: PropTypes.number,
        }),
      }),
    }).isRequired,
  };

  static defaultProps = {
    breakpoints: {},

    // Taken from https://material.io/design/layout/responsive-layout-grid.html#breakpoints
    cols: {
      lg: 12,
      md: 12,
      sm: 12,
      xs: 8,
      xxs: 4,
    },
    isStatic: true,
    layouts: {},
  };

  /**
   * Wrap child in div because react-grid-layout works best with divs.
   *
   * @param child React child
   * @returns Div containing child parameter, or returns child if already a div
   */
  wrapChild = (child) => {
    if (child.type !== 'div') {
      let newChild;
      // Dashboard widgets which have custom default sizes for each widget type defined in dataGrid prop
      if (child.props.dataGrid) {
        const { dataGrid } = child.props;
        newChild = (
          // Add the custom sizing to the div around the widget
          <div key={child.key} data-grid={dataGrid}>
            {child}
          </div>
        );
      } else {
        newChild = <div key={child.key}>{child}</div>;
      }
      return newChild;
    }
    return child;
  };

  /**
   * Iterates through children and wraps them in a div if they are not already
   * one.
   *
   * @returns Either one wrapped child or an array of wrapped children,
   *   depending on the amount of children.
   */
  renderChildren = () => {
    const { children } = this.props;

    if (!Array.isArray(children)) {
      return this.wrapChild(children);
    }

    return children.map((child) =>
      // Wrap non-divs so that they work with layout.
      this.wrapChild(child)
    );
  };

  /**
   * Generates breakpoints object based on breakpoints prop and material ui
   * theme breakpoints. Makes sure that all breakpoints are covered by filling
   * any missing breakpoints from props with material ui default breakpoints
   * (see
   * https://material-ui.com/customization/default-theme/?expend-path=$.breakpoints.values).
   *
   * @returns Object of breakpoints for each breakpoint: lg, md, sm, xs, xxs
   */
  generateBreakpoints = () => {
    const { breakpoints, theme } = this.props;

    /* Insert joke where material is trying to make their
     * breakpoints feel fat with their sizings.
     */
    const layoutBreakpoints = {
      lg: breakpoints.lg || theme.breakpoints.values.xl,
      md: breakpoints.md || theme.breakpoints.values.lg,
      sm: breakpoints.sm || theme.breakpoints.values.md,
      xs: breakpoints.xs || theme.breakpoints.values.sm,
      xxs: breakpoints.xxs || theme.breakpoints.values.xs,
    };
    return layoutBreakpoints;
  };

  render() {
    const { breakpoints, children, cols, isStatic, ...otherProps } = this.props;

    const layoutBreakpoints = this.generateBreakpoints();
    return (
      <ResponsiveReactGridLayout
        className="layout"
        breakpoints={layoutBreakpoints}
        cols={cols}
        isDraggable={!isStatic}
        isResizable={!isStatic}
        onResizeStop={() => window.dispatchEvent(new Event('resize'))}
        onResize={() => window.dispatchEvent(new Event('resize'))}
        {...otherProps}
      >
        {this.renderChildren()}
      </ResponsiveReactGridLayout>
    );
  }
}

export default withTheme(ResponsiveGridLayout);
