import { Link } from 'base-components';

type SortableLinkProps = {
  id: number;
  onClick: () => any;
};

const SortableLink = (props: SortableLinkProps) => {
  const { id, onClick } = props;
  return <Link onClick={() => onClick()}>{id}</Link>;
};

export default SortableLink;
