import { createContext } from 'react';
import { SeaTubePermissionObj } from 'domain/services/SeaTubePermissionsService';

const SeaTubePermissionContext = createContext<SeaTubePermissionObj>({
  annotationPrivilege: false,
  deleteAnnotationPrivilege: false,
  selfDeleteAnnotationPrivilege: false,
  selfDeletePublicAnnotations: false,
  reviewPrivilege: false,
});

export default SeaTubePermissionContext;
