import StatelessTable from 'library/BaseComponents/table/StatelessTable';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import EEWRegistrationService from './service/EEWRegistrationService';

type Props = {
  onInfo: (message: string) => void;
  onError: (message: string) => void;
};

const EarthquakeScenarioTable: React.FC<Props> = ({
  onInfo,
  onError,
}: Props) => {
  const columns = [
    { name: 'scenario', title: 'Scenario' },
    { name: 'magnitude', title: 'Magnitude' },
    { name: 'epicentre', title: 'Epicentre' },
    { name: 'test', title: ' ' },
  ];

  const scenarios = [
    { scenario: '1', magnitude: '8.2', epicentre: '48.5,-126.1' },
    { scenario: '2', magnitude: '5.4', epicentre: '46.23,-121.16' },
  ];

  const callService = async (row) => {
    await EEWRegistrationService.testScenario(
      row.epicentre,
      row.magnitude
    ).then((response) => {
      if (response.data.statusCode === 0) {
        onInfo('Requested test notification');
      } else {
        onError('Failed to request test notification');
      }
    });
  };

  const formatRows = (rows) =>
    rows.map((row) => {
      const formattedRow = { ...row };
      formattedRow.test = (
        <ContainedButton
          translationKey="earthquake.testNotification"
          onClick={() => callService(row)}
        />
      );
      return formattedRow;
    });

  return (
    <StatelessTable
      rows={formatRows(scenarios)}
      columns={columns}
      title="Preset Test Scenarios"
    />
  );
};

export default withSnackbars(EarthquakeScenarioTable);
