import {
  TreeView as MaterialTreeView,
  TreeViewProps,
} from '@mui/x-tree-view/TreeView';
import { ExpandMore, ChevronRight } from '@onc/icons';

/**
 * Material TreeView with the default Collape, Expand Icons set to how ONC
 * generally displays trees
 *
 * @param {any} props
 */
const TreeView = ({
  defaultExpandIcon = <ChevronRight />,
  defaultCollapseIcon = <ExpandMore />,
  ...rest
}: TreeViewProps<any>) => (
  <MaterialTreeView
    defaultCollapseIcon={defaultCollapseIcon}
    defaultExpandIcon={defaultExpandIcon}
    {...rest}
  />
);

export default TreeView;
