import moment from 'moment';
import ScalarDataService from 'domain/services/ScalarDataService';
import { type LegendItem } from 'library/CompositeComponents/chart/Legend';
import {
  ChartQuality,
  isScalarDataChartLocationSource,
  type ResamplePeriod,
  type ResampleType,
  type ScalarDataChartDeploymentSource,
  type ScalarDataChartLocationSource,
} from './types/TimeSeriesScalarDataChart.types';
import type { DateRange } from 'domain/hooks/useDateRange';

type CancellableRequestParameters = {
  dateRange: DateRange;
  chartQuality?: ChartQuality;
  resamplePeriod: ResamplePeriod;
  resampleType: ResampleType;
  signal: AbortSignal;
};

export const LEGEND_MAPPING: LegendItem[] = [
  {
    name: '0',
    tooltipName: 'No Quality Control Flag',
    colour: 'rgb(130, 107, 199)',
  },
  { name: '1', tooltipName: 'Good', colour: 'rgb(30, 144, 255)' },
  { name: '2', tooltipName: 'Probably Good', colour: 'rgb(0, 255, 0)' },
  { name: '3', tooltipName: 'Probably Bad', colour: 'rgb(255, 177, 0)' },
  { name: '4', tooltipName: 'Bad', colour: 'rgb(230, 25, 75)' },
  { name: '5', tooltipName: '???', colour: 'rgb(255, 255, 255)' },
  { name: '6', tooltipName: 'Averaged - Bad', colour: 'rgb(28, 28, 28)' },
  { name: '7', tooltipName: 'Averaged - Good', colour: 'rgb(240, 50, 230)' },
  {
    name: '8',
    tooltipName: 'Interpolated - Good',
    colour: 'rgb(216, 188, 154)',
  },
  { name: '9', tooltipName: 'Data Missing', colour: 'rgb(95, 113, 63)' },
];

export const LEGEND_TITLE = 'QAQC Legend';
export const SHOW_LEGEND_TITLE = `Toggle ${LEGEND_TITLE}`;

const SUBSAMPLEPERIODS_NO_TIDE_ALIASING = [
  0,
  1,
  5,
  10,
  15,
  30,
  60,
  300,
  600,
  900,
  1800,
  3600,
  7200,
  2 * 24 * 3600,
  3 * 24 * 3600,
];

const NUMBER_PROPERTY_COLORS = 3;

const PROPERTY_CODE_COLOR_MAP = new Map([
  ['oxygen', ['#006699', '#339ACD', '#99CCFF']], // Dark Blue
  ['transmission', ['#990099', '#FF66FF', '#FF99FF']], // Purple
  ['dissolvedgaspressure', ['#00C1C1', '#17E6E6', '#33FFFF']], // Green Blue
  ['seawatertemperature', ['#990000', '#CD3333', '#FF8080']], // Red
  ['conductivity', ['#CC6600', '#FF8D1A', '#FFCC66']], // Orange
  ['pressure', ['#00769A', '#29A9CD', '#66DFFF']], // Dark Blue/Green
  ['salinity', ['#003366', '#3674B3', '#9ABCFF']], // Navy
  ['nitrateconcentration', ['#009900', '#29CD29', '#66FF66']], // Green
  ['co2concentrationlinearized', ['#009966', '#33CC99', '#66FFCC']], // Green-Blue
  ['internalpressure', ['#666666', '#999999', '#CCCCCC']], // Grey
  ['totalpressure', ['#000000', '#808080', '#C0C0C0']], // Black
]);

const BACKUP_COLORS = [
  '#FF0000',
  '#373798',
  '#00ABFF',
  '#2B00FF',
  '#9500FF',
  '#FF0095',
  '#CC9933',
  '#6EAB50',
];

const DEFAULT_COLOR = '#ABCDEF';

class ChartUtils {
  static getDataRating(devices) {
    let dataRating = 0;
    // Get largest sample period
    devices.forEach((device) => {
      device.dataRating.forEach((dr) => {
        const { samplePeriod } = dr;
        if (samplePeriod > dataRating) {
          dataRating = samplePeriod;
        }
      });
    });
    return dataRating;
  }

  static getResampling(points, dataRating, dateRange, chartQuality) {
    let resampleType;
    let resamplePeriod;

    // Calculate number of milliseconds / pixel
    const timeSpaninMS = dateRange[1] - dateRange[0];
    const secondsPerPoint = timeSpaninMS / 1000 / points;

    // Get the absolute diff in seconds between the data rating and seconds
    // per point. If it's less than a second then no need to do resampling
    const secondsPerPointDataRatingDiff = Math.abs(
      dataRating - secondsPerPoint
    );

    if (secondsPerPointDataRatingDiff >= 1) {
      let closestPeriod = 0;

      // Determine resampling if the span per point is greater than the data rating
      if (secondsPerPoint > dataRating) {
        SUBSAMPLEPERIODS_NO_TIDE_ALIASING.forEach((p) => {
          if (
            Math.abs(secondsPerPoint - p) <
            Math.abs(secondsPerPoint - closestPeriod)
          ) {
            closestPeriod = p;
          }
        });
      }

      if (closestPeriod > 0) {
        const secondsPerPointClosestPeriodDiff = Math.abs(
          secondsPerPoint - closestPeriod
        );

        if (secondsPerPointDataRatingDiff > secondsPerPointClosestPeriodDiff) {
          resamplePeriod = closestPeriod;
          resampleType = 'avgMinMax';
        }
      }
      if (chartQuality === 'raw' && resamplePeriod) {
        resampleType = 'minMax';
      }
    }

    return { resampleType, resamplePeriod };
  }

  /* Returns the largest date range for displaying unsummarized data in the chart. */
  static getUnsummarizedDateRange = (
    dateRange,
    chartWidth,
    dataRating
  ): DateRange => {
    const medianDate = new Date(
      (dateRange[0].toDate().getTime() + dateRange[1].toDate().getTime()) / 2
    );
    const firstGreaterResamplePeriod = SUBSAMPLEPERIODS_NO_TIDE_ALIASING.find(
      (p) => p > dataRating
    );
    const pointCoverage = dataRating * chartWidth;
    const averageCoverage = firstGreaterResamplePeriod * chartWidth;
    const diff = averageCoverage - pointCoverage;
    const halfDiff = diff / 2;
    const totalTimeSpan = pointCoverage + (halfDiff - 1);

    const newStart = medianDate.valueOf() - (totalTimeSpan / 2) * 1000;
    const newEnd = medianDate.valueOf() + (totalTimeSpan / 2) * 1000;
    const unSummarizedDateRange: DateRange = [
      moment.utc(new Date(newStart)),
      moment.utc(new Date(newEnd)),
    ];

    return unSummarizedDateRange;
  };

  static getPropertyColors(propertyCodes) {
    let miscellaneousCount = 0;
    const propertyCodeCountMap = new Map(
      [...PROPERTY_CODE_COLOR_MAP.keys()].map((propertyCode) => [
        propertyCode,
        0,
      ])
    );

    return propertyCodes.map((propertyCode) => {
      let color;

      if (PROPERTY_CODE_COLOR_MAP.has(propertyCode)) {
        let count = propertyCodeCountMap.get(propertyCode);
        if (count < NUMBER_PROPERTY_COLORS) {
          color = PROPERTY_CODE_COLOR_MAP.get(propertyCode)[count];
          count += 1;
          propertyCodeCountMap.set(propertyCode, count);
        }
      }

      if (color === undefined) {
        if (miscellaneousCount < BACKUP_COLORS.length) {
          color = BACKUP_COLORS[miscellaneousCount];
        } else {
          color = DEFAULT_COLOR;
        }
        miscellaneousCount += 1;
      }

      return color;
    });
  }

  static setColorOpacity = (color, percent) => {
    // Convert hex to RGB and calculate % to lighten by
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Calculate alpha channel value (opacity)
    const alpha = Math.round(255 * (percent / 100));

    // Convert RGB values back to hex
    const rr = r.toString(16).padStart(2, '0').toUpperCase();
    const gg = g.toString(16).padStart(2, '0').toUpperCase();
    const bb = b.toString(16).padStart(2, '0').toUpperCase();
    const aa = alpha.toString(16).padStart(2, '0').toUpperCase();

    return `#${rr}${gg}${bb}${aa}`;
  };

  static createCancellableDeviceRequest = ({
    source,
    dateRange,
    chartQuality,
    resampleType,
    resamplePeriod,
    signal,
  }: {
    source: ScalarDataChartDeploymentSource;
  } & CancellableRequestParameters) =>
    ScalarDataService.getByDeviceAndSensor(
      source.deviceCode,
      signal,
      source.sensorCategoryCode ? source.sensorCategoryCode : '',
      moment.utc(dateRange[0]).toDate(),
      moment.utc(dateRange[1]).toDate(),
      80000,
      'Array',
      undefined,
      false,
      chartQuality,
      resampleType,
      resamplePeriod,
      true,
      undefined,
      true
    );

  static createCancellableLocationRequest = ({
    source,
    resampleType,
    resamplePeriod,
    dateRange,
    chartQuality,
    signal,
  }: {
    source: ScalarDataChartLocationSource;
  } & CancellableRequestParameters) =>
    ScalarDataService.getByLocationControlledAbortable(
      source.locationCode,
      source.deviceCategoryCode,
      source.propertyCode,
      moment.utc(dateRange[0]).toDate(),
      moment.utc(dateRange[1]).toDate(),
      chartQuality,
      resampleType,
      resamplePeriod,
      true,
      signal,
      true,
      false
    );

  static createCancellableRequest = (
    source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource,
    params: CancellableRequestParameters
  ) =>
    isScalarDataChartLocationSource(source)
      ? ChartUtils.createCancellableLocationRequest({ source, ...params })
      : ChartUtils.createCancellableDeviceRequest({ source, ...params });
}

export default ChartUtils;
