import { createTheme } from '@mui/material/styles';

const oceans3Theme = createTheme({
  palette: {
    // Oceans 3.0 branding colours
    primary: {
      main: 'rgb(18, 157, 192)', // light blue
      dark: '#1D428A', // dark blue
      // black: '#231F20', // 'rgb(35, 31, 32, 1)'
      // red: '#E4002B', // 'rgb(228, 0, 43, 1)'
      // yellow: '#E1CD00', // 'rgb(225, 205, 0, 1)'
      contrastText: '#FFF', // white
    },
    secondary: {
      main: '#888B8D', // dark grey
    },
    highlight: {
      main: '#97D700', // bright green
    },
  },
});

export default oceans3Theme;
