import { get } from 'util/WebRequest';
import calculateUptime from './Uptime';

class SystemStatusService {
  /**
   * Unfortunately SystemStatusService isn't built in the most efficient way
   * possible. The top most keys are the services, and their values represent
   * the many jvms. This means in order to figure out which jvms implement which
   * services, we have to reverse engineer the list from the object keys ex.
   *
   * ```javascript
   *  // we have:
   *  {
   *     A:{
   *        a:{...},
   *        b:{...},
   *        c:{...},
   *     },
   *     B:{
   *        a:{...},
   *        d:{...},
   *     }
   *  }
   *  // we want:
   *  {
   *      a: [A, B],
   *      b: [A],
   *      c: [A],
   *      d: [B]
   *  }
   * ```
   *
   * Since the old Status Console depends on GET /SystemStatusService, we can't
   * refactor the GET request _yet_.
   */
  static parseServices = (payload) =>
    Object.entries(payload).reduce((accumulator, currEntry) => {
      const [serviceName, serviceObj] = currEntry;
      const rowIds = Object.keys(serviceObj);
      for (let i = 0; i < rowIds.length; i += 1) {
        const rowId = rowIds[i];
        if (!accumulator[rowId]) {
          accumulator[rowId] = [];
        }
        accumulator[rowId].push(serviceName);
      }
      return accumulator;
    }, {});

  /** Parses the TaskManagerService to determine if a jvm is dequeuing or not */
  static parseQueueStatus = (array) =>
    array.reduce((acc, curr) => {
      const [rowId, rowObj] = curr;
      const { isDequeuing } = rowObj.stats.dequeuingStatus;
      const result = isDequeuing || false;
      acc[rowId] = result ? 'Dequeuing' : 'Not Dequeuing';
      return acc;
    }, {});

  static formatGetRequest = (payload) => {
    const queuedStatus = this.parseQueueStatus(
      Object.entries(payload.TaskManagerService)
    );
    const services = this.parseServices(payload);
    const result = this.renderRows(
      Object.entries(payload.SystemCommandService),
      services,
      queuedStatus
    );
    return result;
  };

  /* production data has blank version column for most machines, and this will condense
     the table, while still displaying the most relevant information */
  static mergeVersionRevision = (systemStatusMessages) => {
    systemStatusMessages.sort((a, b) => a.hostname.localeCompare(b.hostname));
    return systemStatusMessages.map((systemStatusMessage) => {
      const { revision, branch, ...rest } = systemStatusMessage;
      return {
        version: branch ? `${revision} (${branch})` : revision,
        ...rest,
      };
    });
  };

  static get = () =>
    get('SystemStatusService', {}).then((response) => {
      const { data } = response;
      const { statusCode } = data;
      if (statusCode === 0 && data.payload) {
        return this.mergeVersionRevision(this.formatGetRequest(data.payload));
      }
      return null;
    });

  /** Generates the Array of objects used to display the table */
  static renderRows = (array, services, queueStatus) =>
    array.map((row) => {
      const [rowId, rowObj] = row;
      return {
        ...rowObj,
        rowId,
        systemRole: rowObj.systemRole.split(',').join(', '),
        services: services[rowId].join(', '),
        // queue status is only applicable for jvms that use the TaskManagerService. If a jvm doesn't use TMS, display N/A
        dequeuingStatus: queueStatus[rowId] || 'N/A',
        uptime: calculateUptime(
          rowObj.stats.startDate,
          rowObj.stats.statusDate
        ),
      };
    });
}

export default SystemStatusService;
