import { Component } from 'react';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class DeviceConsoleService extends Component {
  static get(deviceId) {
    return get('DeviceConsoleService', {
      operation: '1',
      deviceids: deviceId,
      polling: false,
    })
      .then((response) => parseDmasAPIResponse(response))
      .catch((err) => err);
  }
}

export default DeviceConsoleService;
