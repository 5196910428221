import { useQueries } from '@tanstack/react-query';
import { AvailabilityChart, Box, Typography } from 'base-components';
import DataAvailabilityWebService from 'domain/services/DataAvailabilityWebService';
import DataAvailabilityChartUtil from './DataAvailabilityChartUtil';

const styles = {
  root: {
    height: '120px',
    marginTop: 1,
  },
  typography: {
    marginBottom: '-20px',
    display: 'block',
    position: 'relative',
    zIndex: 1,
    width: (theme) => theme.spacing(19),
  },
};

type DeviceSourceParameters = {
  dataSourceType: 'device';
  deviceCode: string;
};
type LocationSourceParameters = {
  dataSourceType: 'location';
  locationCode: string;
  deviceCategoryCode?: string;
};
export type DataSource = (DeviceSourceParameters | LocationSourceParameters) & {
  dataProductCode?: string;
  extensions?: string[];
};

type DataAvailabilityChartProps = {
  dataSources: DataSource[];
  canSelectDateRange?: boolean;
  onDateRangeSelected?: (event: any) => void;
  backgroundColour?: string;
  showTitle?: boolean;
};

const getDataSourceValues = (source) => {
  const { dataSourceType = undefined, dataProductCode = undefined } = source;
  const deviceCode =
    dataSourceType === 'device' ? source.deviceCode : undefined;
  const locationCode =
    dataSourceType === 'location' ? source.locationCode : undefined;
  const deviceCategoryCode =
    dataSourceType === 'location' ? source.deviceCategoryCode : undefined;

  return {
    deviceCode,
    locationCode,
    deviceCategoryCode,
    dataProductCode,
    dataSourceType,
  };
};

const DataAvailabilityChartV2 = ({
  dataSources,
  canSelectDateRange = false,
  onDateRangeSelected = () => {},
  backgroundColour = undefined,
  showTitle = true,
}: DataAvailabilityChartProps) => {
  const allSources = dataSources.flatMap((source) =>
    (source.extensions ? source.extensions : ['']).map((extension) => ({
      ...getDataSourceValues(source),
      extension,
    }))
  );

  const dataAvailabilityResponse = useQueries({
    queries: allSources.map((source) => ({
      queryKey: ['data-availability', source],
      queryFn: () =>
        DataAvailabilityWebService.get({
          extension: source.extension,
          deviceCode: source.deviceCode,
          locationCode: source.locationCode,
          deviceCategoryCode: source.deviceCategoryCode,
          dataProductCode: source.dataProductCode,
          includeEmptyDays: false,
        }),
    })),
  });

  const isLoading = dataAvailabilityResponse.some((result) => result.isLoading);
  const isError = dataAvailabilityResponse.some((result) => result.isError);

  const availabilityDateRanges = [];
  if (isLoading === false && isError === false) {
    dataAvailabilityResponse.forEach((response) => {
      response.data.data.availableDataProducts.forEach((availability) =>
        availabilityDateRanges.push({
          dateFrom: new Date(availability.dateFrom),
          dateTo: new Date(availability.dateTo),
        })
      );
    });
  }

  return (
    <Box sx={styles.root}>
      {showTitle ? (
        <Typography variant="subtitle1" sx={styles.typography}>
          Data Availability
        </Typography>
      ) : null}
      <AvailabilityChart
        availabilityDateRanges={availabilityDateRanges}
        displayDateRange={DataAvailabilityChartUtil.getAvailableRange(
          availabilityDateRanges
        )}
        stillLoading={isLoading}
        canSelectDateRange={canSelectDateRange}
        onDateRangeSelected={onDateRangeSelected}
        displayDefaultTitle={false}
        backgroundColour={backgroundColour}
      />
    </Box>
  );
};

export default DataAvailabilityChartV2;
