import { MessageMatch } from './VirtualChatLogList';

/** Represents a segment of text with associated styles. */
export type Segment = {
  text: string;
  start: number;
  end: number;
  styles: {
    bold?: boolean;
    link?: string;
    highlight?: boolean;
    currentMatch?: boolean;
    color?: string;
  };
};

/** Represents a range within text and the styles to apply to that range. */
export type Range = {
  start: number;
  end: number;
  styles: { [key: string]: any };
};

/**
 * Generates an array of ranges from message matches to apply highlighting.
 *
 * @param relevantMatches - Array of matches relevant to the current message.
 * @returns Array of Range objects indicating where matches occur in the text.
 */
export const getMatchRanges = (relevantMatches: MessageMatch[]): Range[] =>
  relevantMatches.map((match) => ({
    start: match.matchStart,
    end: match.matchEnd,
    styles: { highlight: true, currentMatch: match.isCurrentMatch },
  }));

/**
 * Identifies all URLs in the given text and returns their ranges.
 *
 * @param text - The text to search for URLs.
 * @returns Array of Range objects where URLs are located in the text.
 */
export const getURLRanges = (text: string): Range[] => {
  const ranges: Range[] = [];
  const regex = /https?:\/\/[^\s]+/g;
  let match = regex.exec(text);
  while (match !== null) {
    ranges.push({
      start: match.index,
      end: match.index + match[0].length,
      styles: { link: match[0] },
    });
    match = regex.exec(text);
  }
  return ranges;
};

/**
 * Identifies mentions of users in the text and returns their ranges with
 * styles.
 *
 * @param text - The text to search for user mentions.
 * @param chatUserMap - Array of chat users with their username and color.
 * @param coloredUsernames - Flag indicating if usernames should be colored.
 * @returns Array of Range objects where user mentions occur in the text.
 */
export const getMentionRanges = (
  text: string,
  chatUserMap: { username: string; color: string }[],
  coloredUsernames: boolean
): Range[] => {
  const ranges: Range[] = [];
  const wordRegex = /@?[\w]+/g;
  let match = wordRegex.exec(text);
  while (match !== null) {
    const word = match[0];
    const username = word.startsWith('@') ? word.substring(1) : word;
    const user = chatUserMap.find((u) => u.username === username);
    if (user) {
      const color = coloredUsernames ? user.color : undefined;
      ranges.push({
        start: match.index,
        end: match.index + word.length,
        styles: { bold: true, color },
      });
    }
    match = wordRegex.exec(text);
  }
  return ranges;
};

/**
 * Processes text segments with given ranges, splitting and styling them
 * accordingly.
 *
 * @param segments - The initial segments of text.
 * @param ranges - The ranges that define where styles should be applied.
 * @returns Array of new segments with styles applied.
 */
export const processSegmentsWithRanges = (
  segments: Segment[],
  ranges: Range[]
): Segment[] => {
  let resultSegments = segments;

  for (const range of ranges) {
    const newSegments: Segment[] = [];

    for (const segment of resultSegments) {
      // No overlap
      if (segment.end <= range.start || segment.start >= range.end) {
        newSegments.push(segment);
      } else {
        // Overlap exists
        if (segment.start < range.start) {
          newSegments.push({
            text: segment.text.slice(0, range.start - segment.start),
            start: segment.start,
            end: range.start,
            styles: segment.styles,
          });
        }

        const overlapStart = Math.max(segment.start, range.start);
        const overlapEnd = Math.min(segment.end, range.end);
        newSegments.push({
          text: segment.text.slice(
            overlapStart - segment.start,
            overlapEnd - segment.start
          ),
          start: overlapStart,
          end: overlapEnd,
          styles: { ...segment.styles, ...range.styles },
        });

        if (range.end < segment.end) {
          newSegments.push({
            text: segment.text.slice(range.end - segment.start),
            start: range.end,
            end: segment.end,
            styles: segment.styles,
          });
        }
      }
    }

    resultSegments = newSegments;
  }

  return resultSegments;
};

/**
 * Retrieves the style object for a given username based on the user map and
 * settings.
 *
 * @param username - The username to get the style for.
 * @param chatUserMap - Array of chat users with their username and color.
 * @param coloredUsernames - Flag indicating if usernames should be colored.
 * @returns CSSProperties object containing the color style.
 */
export const getUserStyle = (
  username: string,
  chatUserMap: { username: string; color: string }[],
  coloredUsernames: boolean
): React.CSSProperties => {
  const user = chatUserMap.find((item) => item.username === username);
  if (user && coloredUsernames) {
    return { color: user.color };
  }
  return { color: '#000' };
};
