import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ResponsiveLayout from 'library/CompositeComponents/grid-layout/DBSaveResponsiveLayout';
import LayoutPropType from 'library/CompositeComponents/grid-layout/LayoutPropType';

const STYLES = {
  layoutContainer: {},
};

const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 150 };

const cols = { lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 };

class DashboardLayout extends Component {
  static propTypes = {
    layoutId: PropTypes.number.isRequired,
    layoutName: PropTypes.string.isRequired,
    layouts: PropTypes.shape({
      lg: LayoutPropType,
      md: LayoutPropType,
      sm: LayoutPropType,
      xs: LayoutPropType,
      xxs: LayoutPropType,
    }).isRequired,
    isStatic: PropTypes.bool.isRequired,
    isPublic: PropTypes.bool.isRequired,
    classes: PropTypes.shape({ layoutContainer: PropTypes.string }).isRequired,
    onLayoutChange: PropTypes.func.isRequired,
    ownerId: PropTypes.number,
    children: PropTypes.node,
    useInitialLayouts: PropTypes.bool,
    preventCollision: PropTypes.bool,
  };

  static defaultProps = {
    useInitialLayouts: false,
    preventCollision: false,
    ownerId: undefined,
    children: <></>,
  };

  render() {
    const {
      layoutId,
      layoutName,
      children,
      layouts,
      isStatic,
      preventCollision,
      useInitialLayouts,
      ownerId,
      isPublic,
      classes,
      onLayoutChange,
    } = this.props;
    return (
      <ResponsiveLayout
        className={classes.layoutContainer}
        onLayoutChange={onLayoutChange}
        breakpoints={breakpoints}
        cols={cols}
        layouts={layouts}
        layoutKey={layoutId}
        layoutName={layoutName}
        ownerId={ownerId}
        isPublic={isPublic}
        isStatic={isStatic}
        preventCollision={preventCollision}
        useInitialLayouts={useInitialLayouts}
        draggableHandle="#panelHeader"
        rowHeight={25}
      >
        {children}
      </ResponsiveLayout>
    );
  }
}
export default withStyles(STYLES)(DashboardLayout);
