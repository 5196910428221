import moment, { Moment } from 'moment';
import ChatLogIngestionParsingMethods, {
  ParsingMethod,
} from './ChatLogIngestionParsingMethods';

class ChatLogIngestionAnalyzer {
  parsingMethod: ParsingMethod;

  strictIntRegex = /^[-+]?(\d+|Infinity)$/;

  constructor(parsingMethod: ParsingMethod) {
    this.parsingMethod = parsingMethod;
  }

  /*
   * From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt#a_stricter_parse_function
   * because the normal parseInt will happily return 2 for parseInt('2withCharacters', 10).
   */
  strictParseInt(value: string): number {
    if (this.strictIntRegex.test(value)) {
      return Number(value);
    }
    return NaN;
  }

  isIntInRangeInclusive(
    numAsString: string,
    min: number,
    max: number
  ): boolean {
    const num = this.strictParseInt(numAsString);
    return !isNaN(num) && num >= min && num <= max;
  }

  isYearMonthDaySuspicious(
    year: string,
    month: string,
    day: string,
    selectedDate?: Moment | null
  ): string | undefined {
    if (
      this.parsingMethod ===
      ChatLogIngestionParsingMethods.separatorSpaceSquareTimeAngleUsernameMessage
    ) {
      // this parsing method doesn't have these
      if (year || month || day) {
        return 'Parsing method does not support a year, month, day value.';
      }
    } else if (
      this.parsingMethod ===
      ChatLogIngestionParsingMethods.separatorCommaMDYDateTimeUsernameMessage
    ) {
      // it's suspicious if any of these are missing
      // This seems to get triggered if anything is missing (ex. Username)
      if (!year || !month || !day) {
        return 'There was an error parsing the message.';
      }

      const yearNum = this.strictParseInt(year);
      // The current earliest dive dateFrom is in 1980.
      if (isNaN(yearNum) || yearNum < 1980) {
        return `There was a problem parsing the year: ${yearNum}`;
      }

      if (!this.isIntInRangeInclusive(month, 1, 12)) {
        return `There was a problem parsing the month: ${month}`;
      }

      // can add month-specific logic later if desired
      if (!this.isIntInRangeInclusive(day, 1, 31)) {
        return `There was a problem parsing the day: ${day}`;
      }

      const monthNum = this.strictParseInt(month);
      const dayNum = this.strictParseInt(day);

      // message should be in UTC, so set as UTC
      const messageDate = new Date();
      // this function uses January = 0, so subtract 1.
      messageDate.setUTCFullYear(yearNum, monthNum - 1, dayNum);

      // it's suspicious if the message's date doesn't match the user's selected date
      if (selectedDate) {
        if (
          messageDate.getUTCFullYear() !== selectedDate.utc().year() ||
          messageDate.getUTCMonth() !== selectedDate.utc().month() ||
          messageDate.getUTCDate() !== selectedDate.utc().date()
        ) {
          return `The date on the message does not match the selected date: ${selectedDate
            .utc()
            .format('YYYY[-]MM[-]DD')}`;
        }
      }

      // it's suspicious if the chat log message couldn't have occurred yet
      const now = new Date();
      if (now < messageDate) {
        return `The timestamp on the message could not have occurred yet.`;
      }
    }

    return undefined;
  }

  areValuesSuspicious(
    groups: { [key: string]: string },
    selectedDate?: Moment | null
  ): string | undefined {
    const { year, month, day, hour, minute, second, username, message } =
      groups;
    const dateError = this.isYearMonthDaySuspicious(
      year,
      month,
      day,
      selectedDate
    );
    if (dateError) {
      return dateError;
    }

    // it's suspicious if any of these are missing
    if (!hour || !minute || !second) {
      return 'There was an error attempting to parse the time.';
    }

    if (!this.isIntInRangeInclusive(hour, 0, 23)) {
      return `The hour value is out of range.  Expected hour to be between 0 and 23 but was: ${hour}.`;
    }

    if (!this.isIntInRangeInclusive(minute, 0, 59)) {
      return `The minute value is out of range.  Expected minute to be between 0 and 59 but was: ${minute}.`;
    }

    if (!this.isIntInRangeInclusive(second, 0, 59)) {
      return `The second value is out of range.  Expected second to be between 0 and 59 but was: ${second}.`;
    }

    // this parsing method always has a username, so it's suspicious if it's missing
    if (
      !username &&
      this.parsingMethod ===
        ChatLogIngestionParsingMethods.separatorCommaMDYDateTimeUsernameMessage
    ) {
      return 'There was an error attempting to parse the username.';
    }

    if (!message) {
      return 'There was an error attempting to parse the message.';
    }

    return undefined;
  }

  isInTimeRange(
    groups: { [key: string]: string },
    selectedDate: Moment | null | undefined,
    dateRangeFrom: Moment | null | undefined,
    dateRangeTo: Moment | null | undefined
  ): boolean {
    const { year, month, day, hour, minute, second } = groups;

    // these are required for chat log lines
    if (!hour || !minute || !second) {
      return false;
    }

    let subjectMoment: Moment;

    // start by setting the date portion
    if (year && month && day) {
      subjectMoment = moment
        .utc() //
        .year(this.strictParseInt(year)) //
        .month(this.strictParseInt(month) - 1) // January = 0
        .date(this.strictParseInt(day));
    } else if (selectedDate) {
      // if the chat log line has insufficient date info, use the user's selected date
      subjectMoment = selectedDate.clone(); // clone so the original Moment is not modified
    } else {
      subjectMoment = moment.utc(); // just some default in case selectedDate is somehow null or undefined
    }
    // then set the time portion
    // (only the time info from the groups are considered because the user cannot select a time)
    subjectMoment //
      .utc() //
      .startOf('day') // default things to 0 in case the int parse returns an NaN for a setter, which is a no-op
      .hour(this.strictParseInt(hour)) //
      .minute(this.strictParseInt(minute)) //
      .second(this.strictParseInt(second));

    if (!dateRangeFrom && !dateRangeTo) {
      return true;
    }

    if (!dateRangeFrom) {
      return subjectMoment.isBefore(dateRangeTo);
    }

    if (!dateRangeTo) {
      return subjectMoment.isSameOrAfter(dateRangeFrom);
    }

    // third parameter, if defined, limits granularity to a unit other than the default: milliseconds
    // fourth parameter specifies range inclusivity and exclusivity
    return subjectMoment.isBetween(dateRangeFrom, dateRangeTo, undefined, '[)');
  }
}

export default ChatLogIngestionAnalyzer;
