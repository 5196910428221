import PropTypes from 'prop-types';

/**
 * Class defining the layouts PropType. This is based off react-grid-layout's
 * layouts for ResponsiveGridLayout.
 */
export default PropTypes.shape({
  // item's key
  dafTestId: PropTypes.number,
  status: PropTypes.string,
  deviceId: PropTypes.number.isRequired,
  ipAddress: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  validDeviceId: PropTypes.bool,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      stage: PropTypes.string,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          status: PropTypes.string,
        })
      ),
    })
  ),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  jiraIssues: PropTypes.arrayOf(PropTypes.string),
  piggybackDevices: PropTypes.shape(),
  plotDetails: PropTypes.shape(),
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
});
