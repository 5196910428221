import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { ShowChart } from '@onc/icons';
import {
  Divider,
  HelpLink,
  IconButton,
  SizeMe,
  Typography,
} from 'base-components';
import { NOAA_DATA } from 'domain/AppComponents/organization-details/OrganizationServiceData';
import SensorReadingDisplay from 'domain/AppComponents/table/SensorReadingDisplay';
import SensorPlotDisplay from 'domain/Apps/seatube/sensor-plot/SensorPlotDisplay';

import Panel from 'library/CompositeComponents/panel/Panel';
import DateFormatUtils from 'util/DateFormatUtils';
import Search from 'util/Search';
import { parseDmasAPIResponse, get } from 'util/WebRequest';
import { getFormattedUnit } from './util/SensorUtil';
import SeaTubeResourceTypes from '../util/SeaTubeResourceTypes';

/**
 * Sensors that have a preferred index in sensor display @readonly @enum
 * {number}
 */
const PREFERRED_SENSORS = { Latitude: 0, Longitude: 1, Heading: 2, Depth: 3 };

const styles = {
  root: {
    height: '100%',
    width: '100%',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

class SeaTubeSensorDisplayPanel extends PureComponent {
  static propTypes = {
    diveId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    currentDate: PropTypes.instanceOf(Moment),
    classes: PropTypes.shape({
      root: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    menu: PropTypes.node,
    startDate: PropTypes.instanceOf(Moment),
    endDate: PropTypes.instanceOf(Moment),
    organizationId: PropTypes.number,
  };

  static defaultProps = {
    currentDate: undefined,
    startDate: undefined,
    endDate: undefined,
    menu: undefined,
    organizationId: undefined,
  };

  formatNumberForDisplay = (value) => Number.parseFloat(value).toFixed(6);

  convertO2Unit = (value) => (Number.parseFloat(value) / 0.7).toFixed(6);

  /**
   * @param {{
   *   intervalDateObj: Moment.Moment;
   * }} sample
   * @param {Moment.Moment} time
   */
  compareByDate = (sample, time) => {
    const { intervalDateObj } = sample;
    const endInterval = intervalDateObj.clone().add(30, 'seconds');
    if (time.isBefore(intervalDateObj)) return 1;
    if (time.isAfter(endInterval)) return -1;
    /* else */ return 0;
  };

  compareByName = (a, b) => {
    let { sensorName: nameA } = a;
    let { sensorName: nameB } = b;
    nameA = nameA.toLowerCase();
    nameB = nameB.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    /* else */ return 0;
  };

  renderGraphButton = (onClick) => (
    <IconButton onClick={onClick} aria-label="Show Graph" Icon={ShowChart} />
  );

  /**
   * @type {{
   *   isPlotToggled: boolean;
   *   error: undefined | Error;
   *   sensorData: Array;
   *   plottableData: Array;
   * }}
   */
  state = {
    isPlotToggled: false,
    error: undefined,
    sensorData: [],
    plottableData: [],
  };

  componentDidMount() {
    this.computeServiceRequest();
  }

  static get PREFERRED_SENSORS() {
    return PREFERRED_SENSORS;
  }

  computeServiceRequest = async () => {
    const { diveId: resourceId, organizationId } = this.props;
    return get(
      'seatube/sensordata',
      {
        resourceTypeId: SeaTubeResourceTypes.DIVE,
        resourceId,
      },
      {}
    )
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => {
        const plottableData =
          organizationId === NOAA_DATA.organizationId
            ? this.convertSamples(payload)
            : payload;
        this.setState({ plottableData });
        return this.formatSamples(payload);
      })
      .then((result) => {
        const sensorData =
          organizationId === NOAA_DATA.organizationId
            ? this.convertSamples(result)
            : result;
        this.setState({ sensorData });
      })
      .catch((error) => this.setState({ error }));
  };

  /** If the data is for NOAA, convert O2 from ml/L to mg/L by mg/L = (ml/L)/0.7 */
  convertSamples = (payload) =>
    payload.map((sample) => ({
      ...sample,
      sensorValues: sample.sensorValues.map((sensorValue) => {
        if (
          sensorValue.sensorName === 'Oxygen Concentration' &&
          sensorValue.uom.toLowerCase() === 'ml/l'
        ) {
          return {
            ...sensorValue,
            uom: 'mg/l',
            value: this.convertO2Unit(sensorValue.value),
          };
        }
        return { ...sensorValue };
      }),
    }));

  /**
   * Calculate a date object for every interval: used for the later Binary
   * Search format numbers for display: our requirements ask for 6 decimals of
   * precision
   */
  formatSamples = (payload) =>
    payload.map((sample) => ({
      ...sample,
      intervalDateObj: Moment(sample.intervalDate),
      sensorValues: sample.sensorValues.map((sensorValue) => ({
        ...sensorValue,
        value: this.formatNumberForDisplay(sensorValue.value),
      })),
    }));

  /**
   * For our Sensor Reading display, Latitude, Longitude, Heading and Depth have
   * preferred indexes. The rest of the sensors are ordered alphabetically
   */
  reorderSensorValues = (sensorValues) => {
    const preferred = [];
    const others = [];
    sensorValues.forEach((value) => {
      const index = PREFERRED_SENSORS[value.sensorName];
      if (index !== undefined) preferred[index] = value;
      else others.push(value);
    });
    others.sort(this.compareByName);
    preferred.push(...others);
    // strip unused positions generated by ordering of preferred
    return preferred.filter((ele) => ele !== undefined);
  };

  togglePlotDisplay = () =>
    this.setState((prevState) => ({
      isPlotToggled: !prevState.isPlotToggled,
    }));

  getRowsForSensorValueDisplay = () => {
    const { currentDate } = this.props;
    const { sensorData: rows } = this.state;

    if (!currentDate || rows.length === 0) return [];

    // This gets "some" index that is within the most recent 30 seconds
    // 30 seconds compensates for the 30 second packages received from the server
    const mostRecentDateObj = currentDate.clone().subtract(30, 'seconds');
    let index = Search.binarySearch(
      rows,
      mostRecentDateObj,
      this.compareByDate
    );
    if (index >= rows.length || index < 0) {
      return [];
    }
    // Make sure we have the most recent good index
    while (
      index < rows.length &&
      mostRecentDateObj > rows[index].intervalDateObj
    ) {
      index += 1;
    }
    index -= 1;

    const result = [];
    const sensorValues = [];
    const sensorNamesFound = [];
    const leastRecentDateObj = mostRecentDateObj
      .clone()
      .subtract(30, 'seconds');
    // Iterate through all data within the past 30 seconds
    while (index >= 0 && rows[index].intervalDateObj > leastRecentDateObj) {
      rows[index].sensorValues.forEach((value) => {
        sensorValues.push(value);
      });
      index -= 1;
    }
    let tempReading;
    sensorValues.forEach((reading) => {
      if (reading !== undefined) {
        // Ensure we only include a given sensor once
        const { sensorName } = reading;
        if (!sensorNamesFound.includes(sensorName)) {
          sensorNamesFound.push(sensorName);
          tempReading = { ...reading };
          tempReading.sampleDate = DateFormatUtils.formatDate(
            tempReading.sampleDate,
            'full'
          );
          tempReading.value = `${reading.value}${getFormattedUnit(
            reading.uom
          )}`;
          result.push(tempReading);
        }
      }
    });
    return this.reorderSensorValues(result);
  };

  renderSensorValueDisplay = () => {
    const inputRows = this.getRowsForSensorValueDisplay();
    return <SensorReadingDisplay rows={inputRows} />;
  };

  renderSensorPlotDisplay = () => {
    const { currentDate, startDate, endDate } = this.props;
    const { plottableData } = this.state;
    return (
      <SizeMe>
        <SensorPlotDisplay
          currentDate={Moment(currentDate)}
          startDate={Moment(startDate)}
          endDate={Moment(endDate)}
          plottableData={plottableData}
        />
      </SizeMe>
    );
  };

  renderDisplayContent = () => {
    const { isPlotToggled } = this.state;
    return isPlotToggled
      ? this.renderSensorPlotDisplay()
      : this.renderSensorValueDisplay();
  };

  generatePanelProps = () => {
    const { isPlotToggled } = this.state;
    const { currentDate, classes } = this.props;

    const toggleProps = isPlotToggled
      ? { onBackButtonClick: this.togglePlotDisplay }
      : { actionContent: this.renderGraphButton(this.togglePlotDisplay) };

    const title = `Sensor Readings: ${
      currentDate ? DateFormatUtils.formatDate(currentDate, 'full') : ''
    }`;
    toggleProps.title = (
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
    );
    toggleProps.headerDraggable = true;

    return toggleProps;
  };

  renderMenu = () => {
    const { menu } = this.props;
    return (
      <div>
        <HelpLink url="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help#SeaTubeV3Help-SensorReadingsWidget" />
        <Divider />
        {menu}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Panel
        id="seatube-sensor-display-panel"
        className={classes.root}
        menu={this.renderMenu()}
        menuProps={{
          buttonLabel: 'Sensor Readings Menu',
        }}
        {...this.generatePanelProps()}
      >
        {this.renderDisplayContent()}
      </Panel>
    );
  }
}

export default withStyles(styles)(SeaTubeSensorDisplayPanel);
