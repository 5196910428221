import { PureComponent } from 'react';
import { ErrorSnackbar } from '@onc/composite-components';
import SeaTubeMediaFiles from 'library/CompositeComponents/video/SeaTubeMediaFiles';

let totalDuration = 0;

const withArchiveFiles = (files) => (VideoComponent) => {
  class WithArchiveFiles extends PureComponent {
    // --- Some helper methods --- //
    getDeviceCode = (file) => file.deviceCode;

    getFileSuffix = (file) => {
      const fileNamePattern = /(.+)_(\d{8}T\d{6}\.\d{3}Z)(.*)\.(.+)/;
      const matches = file.filename.match(fileNamePattern);
      return `${matches[3]}.${matches[4]}`;
    };

    getFileDurationInSeconds = (file) =>
      Math.abs(
        (new Date(file.dateTo).getTime() - new Date(file.dateFrom).getTime()) /
          1000
      );

    buildArchivePath = (file) => `${file.archiveLocation}/${file.path}/`;

    getTotalDuration = (dataFiles) => {
      let duration = 0;
      for (const file of dataFiles) {
        duration += Number(file[1]);
      }
      return duration;
    };

    getDateInSeconds = (dateString) => new Date(dateString).getTime() / 1000;

    state = { playlist: undefined, error: undefined };

    componentDidMount() {
      this.buildMediaFiles(files);
    }

    // 1. Builds SeaTubeMediaFiles structure
    buildMediaFiles = (fileList) => {
      totalDuration = 0;
      const seatubeMediaFiles = SeaTubeMediaFiles.from({
        availableResolutions: [
          {
            code: 'S',
            description: 'Low',
          },
        ],
        mediaFiles: this.populateMediaFiles(fileList),
        resolution: 'S',
        totalDurationSeconds: this.getTotalDuration(
          this.populateDataFiles(fileList)
        ),
      });
      this.setState({ mediaFiles: seatubeMediaFiles });
    };

    // 2. Converts the archiveFile list into a mediaFile list
    populateMediaFiles = (fileList) => {
      let i = 0;
      const mediaFiles = [];
      // Start iterating over file list
      while (fileList[i]) {
        const fileSuffix = this.getFileSuffix(fileList[i]);
        const currentDeviceCode = this.getDeviceCode(fileList[i]);
        const dataFileList = [];

        // Iterate over fileList until a new device is found
        while (fileList[i]) {
          const deviceCode = this.getDeviceCode(fileList[i]);
          if (currentDeviceCode !== deviceCode) {
            break;
          }
          dataFileList.push(fileList[i]);
          i += 1;
        }

        const dateStartSeconds = this.getDateInSeconds(
          dataFileList[0].dateFrom
        );
        const dataFiles = this.populateDataFiles(
          dataFileList,
          dateStartSeconds
        );
        mediaFiles.push({
          count: dataFileList.length,
          dataFileDurationSeconds: this.getTotalDuration(dataFiles),
          dataFiles,
          dateStartSeconds,
          defaultFileNamePostFix: fileSuffix,
          deviceCode: currentDeviceCode,
          deviceId: currentDeviceCode,
        });
      }
      return mediaFiles;
    };

    // 3. Converts the files for each mediaFile object into dataFile objects
    populateDataFiles = (fileList, dateStartSeconds) => {
      const dataFiles = fileList.map((file) => {
        const duration = this.getFileDurationInSeconds(file);
        const dataFile = [
          `${this.getDateInSeconds(file.dateFrom) - dateStartSeconds}`,
          `${duration}`,
          this.buildArchivePath(file),
          `${totalDuration}`,
        ];
        totalDuration += duration;
        return dataFile;
      });
      return dataFiles;
    };

    // ------------------------- //

    render() {
      const { error, mediaFiles } = this.state;

      if (error) {
        return <ErrorSnackbar message={error.message} />;
      }

      if (mediaFiles) {
        return (
          <VideoComponent
            onQualityChange={() => {}}
            files={mediaFiles}
            {...this.props}
          />
        );
      }
      return null;
    }
  }
  return WithArchiveFiles;
};

export default withArchiveFiles;
