import SensorType from './SensorType';

export type SensorTypeRow = {
  sensorTypeId: number;
  sensorTypeName: string;
  unitOfMeasureId: number;
  unitOfMeasureName: string;
  description: string;
  scalar: boolean;
  averageable: boolean;
  sensorTypeCode: string;
  modifyBy: string;
  modifyDate: string;
};

export const transformSensorTypeRows = (
  sensorTypes: SensorType[]
): SensorTypeRow[] =>
  sensorTypes.map((sensorType) => ({
    sensorTypeId: sensorType.sensorTypeId,
    sensorTypeName: sensorType.sensorTypeName,
    unitOfMeasureId: sensorType.unitOfMeasure.unitOfMeasureId,
    unitOfMeasureName: sensorType.unitOfMeasure.unitOfMeasureName,
    description: sensorType.description,
    scalar: sensorType.scalar,
    averageable: sensorType.averageable,
    sensorTypeCode: sensorType.sensorTypeCode,
    modifyBy: sensorType.modifyBy,
    modifyDate: sensorType.modifyDate,
  }));

export const transformRowToSyncForm = (row: SensorTypeRow): SensorType => ({
  sensorTypeId: row.sensorTypeId,
  sensorTypeName: row.sensorTypeName,
  sensorTypeCode: row.sensorTypeCode,
  unitOfMeasure: {
    unitOfMeasureId: row.unitOfMeasureId,
    unitOfMeasureName: row.unitOfMeasureName,
  },
  description: row.description,
  scalar: row.scalar,
  averageable: row.averageable,
});
