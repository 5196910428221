/* eslint-disable @typescript-eslint/no-unused-vars */
class ContinuousVideoFiles {
  constructor(qualityOptions, currentQuality) {
    this.qualityOptions = qualityOptions.sort((a, b) =>
      b.code.localeCompare(a.code)
    );
    this.currentQuality = currentQuality;
  }

  /* *****************
   * Cursor movement *
   ***************** */

  /** Set the cursor to the clip at the given number of seconds of video elapsed */
  selectClipAtTime = (secondsSinceStart) => {
    throw new Error('Not implemented');
  };

  /**
   * Advance the cursor to the next clip.
   *
   * @see {@link selectClipAtTime}
   */
  selectNextClip = () => {
    throw new Error('Not implemented');
  };

  /* ****************
   * Get real dates *
   **************** */

  /* TODO
   * @returns {Date} the clock time partway through the video. If the elapsed time
   * is after the end of the video, return the time at the end of the last
   * file
   */
  getDateAt = (secondsSinceStart) => {
    throw new Error('Not implemented');
  };

  /* ********************************************
   * Get elapsed time (seconds of video played) *
   ******************************************** */

  /** @retuns {number} the complete duration */
  getDuration = () => {
    throw new Error('Not implemented');
  };

  /* TODO
   * @returns {number} the device of the media file at the given time
   */
  getDeviceIdAt = (secondsSinceStart) => {
    throw new Error('Not implemented');
  };

  /* TODO
   * @param {number|Date} date
   * @returns {number} the number of seconds of video elapsed to the given date
   */
  getTimeElapsedAtDate = (date) => {
    throw new Error('Not implemented');
  };

  /**
   * @param {number | Date} date
   * @returns {Date} The date at the start of the file after the given date, or
   *   undefined if the argument is in or after the last file
   */
  // NOTE: this returns undefined because I'm too lazy to implement it for
  // SeaTubePlaylistFiles
  getStartDateOfClipAfter = (date) => undefined;

  /**
   * @retuns {Date} the date at the end of the file before the given date, or
   * undefined if the param is in or before the first file
   */
  // NOTE: this returns undefined because I'm too lazy to implement it for
  // SeaTubePlaylistFiles
  getEndDateOfClipBefore = (date) => undefined;

  /**
   * Return the start of the current clip, in seconds since the start of the
   * first clip
   */
  getTimeElapsedToCurrentClip = () => {
    throw new Error('Not implemented');
  };

  /**
   * Return the elapsed time to the next media clip, or reset the player and
   * return 0 if this is the last clip.
   */
  getTimeElapsedToNextClip = () => {
    throw new Error('Not implemented');
  };

  /**
   * Return the previous media clip, or throw new Error('Not implemented') if
   * this is the beginning
   */
  getTimeElapsedToPreviousClip = () => {
    throw new Error('Not implemented');
  };

  /** Return the first video clip */
  getTimeElapsedToFirstClip = () => {
    throw new Error('Not implemented');
  };

  /** Return the last video clip */
  getTimeElapsedToFinalClip = () => {
    throw new Error('Not implemented');
  };

  getInfoMap = () => new Map();

  /* ********************
   * JWPlayer playlists *
   ******************** */

  /**
   * Build a new JWPlayer playlist array, starting from the current file
   * inclusive
   */
  buildPlaylist = (length) => {
    throw new Error('Not implemented');
  };

  /**
   * Given a fraction [0,1) of the progress through the video, return the clock
   * time.
   *
   * @returns {Date}
   */
  getDateAtFractionalTime = (fractionalProgress) =>
    this.getDateAt(this.getDuration() * fractionalProgress);
}

export default ContinuousVideoFiles;
