import { MutableRefObject } from 'react';
import { Sorting } from '@devexpress/dx-react-grid';
import { useQuery } from '@tanstack/react-query';
import TaskDefinitionService from 'domain/AppComponents/batch/service/TaskDefinitionService';
import TaskHistoryService from 'domain/AppComponents/batch/service/TaskHistoryService';
import TMTaskService from 'domain/AppComponents/batch/service/TMTaskService';
import { BatchServiceResponse } from 'domain/AppComponents/batch/types/BatchServiceResponse';
import { BatchTaskServiceResponse } from 'domain/AppComponents/batch/types/BatchTaskServiceResponse';
import {
  PAGE_SIZE,
  PAM_TASK_DEFINITION_ID,
} from 'domain/AppComponents/batch/util/BatchUtils';
import { SortingProps } from 'library/BaseComponents/table/plugins/ColumnSorting';
import { parseDmasAPIResponse } from 'util/WebRequest';

export const PAMGUARD_BATCH_MONITOR_PRIVILEGE_QUERY_NAME =
  'PamguardBatchMonitorPrivilegeQuery';
export const usePamguardBatchMonitorPrivilegeQuery = () =>
  useQuery(
    ['PAMGUARD_BATCH_MONITOR_PRIVILEGE_QUERY_NAME'],
    async () => TMTaskService.getPamguardBatchMonitoringPrivilege(392),
    {
      placeholderData: 'RW',
      refetchOnWindowFocus: false,
    }
  );

export const PAM_TASK_DEFINITION_QUERY_NAME = 'getPAMTaskDefinition';
export const usePAMTaskDefinitionQuery = () =>
  useQuery(
    [PAM_TASK_DEFINITION_QUERY_NAME],
    async () => {
      const response = await TaskDefinitionService.getTaskDefinition(
        PAM_TASK_DEFINITION_ID
      );
      return parseDmasAPIResponse(response);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const TASK_DEFINITION_QUERY_NAME = 'getTaskDefinition';
export const useTaskDefinitionQuery = (taskDefinitionId?: number) =>
  useQuery(
    [TASK_DEFINITION_QUERY_NAME, taskDefinitionId],
    async () => {
      if (!taskDefinitionId) return { data: {} };
      const response =
        await TaskDefinitionService.getTaskDefinition(taskDefinitionId);
      return parseDmasAPIResponse(response);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

export const BATCHABLE_TASK_QUERY_NAME = 'getBatchableTasks';
export const useBatchableTasksQuery = () =>
  useQuery(
    [BATCHABLE_TASK_QUERY_NAME],
    async () => {
      const response = await TaskDefinitionService.getBatchableTasks();
      return response
        .map((row) => ({
          label: row.name,
          value: row.taskDefinitionId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

export const CHILD_TASK_PLACEHOLDER: BatchTaskServiceResponse = {
  childTasks: [],
  totalRecords: 0,
};
export const TOP_LEVEL_CHILD_TASKS_QUERY_NAME = 'getTopLevelBatchTask';
export const useTopLevelChildTasksQuery = (
  parentTaskId: number | undefined,
  currentPage: number,
  pageSize: number,
  filter: number[],
  sorting: Sorting[],
  search?: string
) =>
  useQuery(
    [
      TOP_LEVEL_CHILD_TASKS_QUERY_NAME,
      parentTaskId,
      currentPage,
      pageSize,
      filter,
      sorting,
      search,
    ],
    async () => {
      if (!parentTaskId) return CHILD_TASK_PLACEHOLDER;
      if (!sorting[0]?.columnName) {
        const response = await TaskHistoryService.getTopLevelChildTasks(
          parentTaskId,
          currentPage,
          pageSize,
          filter,
          undefined,
          false,
          search
        );
        return parseDmasAPIResponse(response) as BatchTaskServiceResponse;
      }
      const fieldName = !sorting[0]?.columnName.localeCompare('taskType')
        ? 'taskTypeId'
        : sorting[0]?.columnName;
      const response = await TaskHistoryService.getTopLevelChildTasks(
        parentTaskId,
        currentPage,
        pageSize,
        filter,
        fieldName,
        true,
        search
      );
      return parseDmasAPIResponse(response) as BatchTaskServiceResponse;
    },
    {
      keepPreviousData: true,
      placeholderData: CHILD_TASK_PLACEHOLDER,
      refetchOnWindowFocus: false,
      refetchInterval: 2500,
    }
  );

export const BATCH_TASK_PLACEHOLDER = { totalRecords: 0, records: [] };
export const BATCH_TASK_QUERY_NAME = 'getBatchTask';
export const useBatchTaskQuery = (
  currentPage: number,
  selectedFilters: string,
  taskType: string | null,
  sorting: MutableRefObject<SortingProps>,
  search?: string
) =>
  useQuery(
    [
      BATCH_TASK_QUERY_NAME,
      currentPage,
      selectedFilters,
      taskType,
      sorting,
      search,
    ],
    async () => {
      const response = await TaskHistoryService.getBatchTasks(
        currentPage * PAGE_SIZE,
        selectedFilters,
        PAGE_SIZE,
        taskType,
        sorting.current.sorting,
        search
      );
      return parseDmasAPIResponse(response) as BatchServiceResponse;
    },
    {
      keepPreviousData: true,
      placeholderData: BATCH_TASK_PLACEHOLDER,
      refetchOnWindowFocus: false,
      refetchInterval: 2500,
    }
  );
