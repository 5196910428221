import { makeStyles } from '@mui/styles';
import { Tooltip, Typography } from 'base-components';

import DateUtils from 'util/DateUtils';
import CollapsibleText from '../playlist-playback/CollapsibleText';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}));

const PlaylistNameFormatter = ({ row }: any) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2">{row.playlistName}</Typography>

      <CollapsibleText
        id={`${row.id}-description`}
        text={row?.description}
        linesToShow={2}
        variant="body2"
        color="textSecondary"
      />
    </div>
  );
};

const PlaylistDateFormatter = ({ value }: any) => {
  const classes = useStyles();
  return (
    <Tooltip title={value} placement="top-start">
      <div className={classes.container}>
        {DateUtils.timeAgo(new Date(value))}
      </div>
    </Tooltip>
  );
};

export { PlaylistNameFormatter, PlaylistDateFormatter };
