import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from 'base-components';
import DeviceCategorySelect from 'domain/AppComponents/dropdowns/DeviceCategorySelect';
import LocationDropdown from 'domain/AppComponents/dropdowns/LocationDropdown';
import PrincipalInvestigatorDropdown from 'domain/AppComponents/dropdowns/PrincipalInvestigatorDropdown';
import RegionDropdown from 'domain/AppComponents/dropdowns/RegionDropdown';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import {
  OutlinedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import DropdownWrapper from 'library/CompositeComponents/form/DropdownWrapper';
import Form from 'library/CompositeComponents/form/Form';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import ResponsiveDataGridLayout from 'library/CompositeComponents/grid-layout/ResponsiveDataGridLayout';

const classes = {
  field: {
    marginBottom: 2,
    paddingRight: 10,
  },
  parameterDiv: {
    paddingLeft: 2,
  },
};

type DeviceSearchParameterTabForm = {
  region: number;
  location: number;
  deviceCategory: number;
  deviceId: string;
  deviceName: string;
  ipAddress: string;
  dnsName: string;
  serialNumber: string;
  securityTier: number;
  principalInvestigator: number;
};

type DeviceSearchParameterTabProps = {
  privilege: string;
  onSearchClick: () => void;
};

const DeviceSearchParameterTab = ({
  privilege,
  onSearchClick,
}: DeviceSearchParameterTabProps) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const formMethods = useForm<DeviceSearchParameterTabForm>({
    mode: 'onBlur',
    defaultValues: {
      region: -1,
      location: -1,
      deviceCategory: -1,
      deviceId: '',
      deviceName: '',
      ipAddress: '',
      dnsName: '',
      serialNumber: '',
      securityTier: -1,
      principalInvestigator: -1,
    },
  });
  let regionId = formMethods.watch('region');

  const doneLoading = () => {
    setDisabled(false);
  };

  const renderIpField = () => {
    if (privilege === 'RW') {
      return (
        <FormTextField
          sx={classes.field}
          name="ipAddress"
          translationKey="device.ipAddress"
          fullWidth
        />
      );
    }
    return <></>;
  };

  const renderSecurityTierField = () => {
    if (privilege === 'RW') {
      return (
        <FormDropdown
          sx={classes.field}
          fullWidth
          name="securityTier"
          options={[
            { value: -1, label: 'All', key: '-1' },
            {
              value: 3,
              label: 'Low',
              key: '3',
            },
            {
              value: 2,
              label: 'Medium',
              key: '2',
            },
            {
              value: 1,
              label: 'High',
              key: '1',
            },
          ]}
          label="Security Tier"
        />
      );
    }
    return <></>;
  };

  const onResetClick = () => {
    formMethods.reset();
  };

  return (
    <Form onSubmit={onSearchClick} formMethods={formMethods}>
      <Box sx={classes.parameterDiv}>
        <Typography variant="subtitle1" sx={classes.field}>
          Location Parameters
        </Typography>
        <ResponsiveDataGridLayout
          formFields={[
            <RegionDropdown
              fullWidth
              sx={classes.field}
              disabled={disabled}
              name="region"
              label="Region"
              rules={{
                validate: {
                  verifyRegion: () => {
                    regionId = formMethods.getValues().region;
                    return true;
                  },
                },
              }}
            />,
            <LocationDropdown
              name="location"
              label="Location"
              regionId={regionId}
              fullWidth
              sx={classes.field}
              doneLoading={doneLoading}
              disabled={disabled}
            />,
          ]}
        />
        <Typography variant="subtitle1" sx={classes.field}>
          Device Parameters
        </Typography>
        <ResponsiveDataGridLayout
          formFields={[
            <DropdownWrapper
              DropdownComponent={DeviceCategorySelect}
              onError={() => {}}
              fullWidth
              sx={classes.field}
              prependOption={[{ label: 'All', value: -1, key: -1 }]}
              disabled={disabled}
              name="deviceCategory"
            />,
            <FormTextField
              sx={classes.field}
              fullWidth
              name="deviceId"
              translationKey="device.deviceId"
              type="number"
            />,
            <FormTextField
              sx={classes.field}
              name="deviceName"
              translationKey="device.deviceName"
              fullWidth
            />,
            renderIpField(),
            <FormTextField
              sx={classes.field}
              name="dnsName"
              translationKey="device.dnsName"
              fullWidth
            />,
            <FormTextField
              sx={classes.field}
              name="serialNumber"
              translationKey="device.serialNumber"
              fullWidth
            />,
            renderSecurityTierField(),
          ]}
        />
        <Typography variant="subtitle1" sx={classes.field}>
          Other
        </Typography>
        <ResponsiveDataGridLayout
          formFields={[
            <PrincipalInvestigatorDropdown
              sx={classes.field}
              name="principalInvestigator"
              label="Principal Investigator"
              disabled={disabled}
              fullWidth
            />,
          ]}
        />
        <ReadOnlyField
          valueText={new Date().toString()}
          labelText="Filter Date"
          title="filterDate"
        />
      </Box>
      <TextButton
        translationKey="common.buttons.reset"
        onClick={onResetClick}
      />
      <OutlinedButton
        translationKey="common.buttons.runSearch"
        type="submit"
        disabled={disabled}
      />
    </Form>
  );
};

export default DeviceSearchParameterTab;
