import { Autocomplete, type AutocompleteProps } from 'base-components';
import useFilteredDevices from 'domain/hooks/useFilteredDevices';

interface Device {
  name: string;
  deviceId: number;
}

type DeviceAutocompleteProps = {
  value: Device | null;
  onChange: (newDevice: Device) => void;
  multiple?: boolean;
  deviceCategoryCodes?: string[];
  propertyCode?: string;
  locationCode?: string;
  required?: boolean;
} & Omit<
  AutocompleteProps,
  'options' | 'getOptionLabel' | 'isOptionEqualToValue' | 'value' | 'onChange'
>;

const DeviceAutocomplete: React.FC<DeviceAutocompleteProps> = ({
  onChange,
  value,
  multiple = false,
  deviceCategoryCodes = undefined,
  propertyCode = undefined,
  locationCode = undefined,
  required = false,
  ...autocompleteProps
}: DeviceAutocompleteProps) => {
  const devices = useFilteredDevices(
    deviceCategoryCodes,
    propertyCode,
    locationCode
  );

  return (
    <Autocomplete
      {...autocompleteProps}
      multiple={multiple}
      options={devices}
      onChange={(event, newDevice) => onChange(newDevice)}
      value={value}
      virtualized
      noOptionsText="No Devices Available"
      getOptionLabel={(device: Device) =>
        `${device.name} ${device.deviceId ? `(${device.deviceId})` : ''}`
      }
      isOptionEqualToValue={(a: Device, b: Device) => a.deviceId === b.deviceId}
      name="Device"
      translationKey="device.device"
      required={required}
    />
  );
};

export default DeviceAutocomplete;
