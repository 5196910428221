import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import { Box, Chip, Select, InputLabel, MenuItem } from 'base-components';

const styles = {
  container: {
    margin: '1vh 0 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  multiselect: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

type MultiSelectProps = {
  label?: string;
  onChange: (event) => void;
  options: {
    key: string;
    value: string;
    label: string;
  }[];
  value: string | string[];
};

const MultiSelect = ({
  label = '',
  onChange,
  options,
  ...rest
}: MultiSelectProps) => (
  <FormControl sx={styles.container}>
    <InputLabel htmlFor={`select-multiple-${label}`}>{label}</InputLabel>
    <Select
      multiple
      sx={styles.multiselect}
      input={<Input id={`select-multiple-${label}`} />}
      renderValue={(sel: any) => (
        <Box sx={styles.chips}>
          {sel.map((val) => (
            <Chip key={val} label={val} />
          ))}
        </Box>
      )}
      onChange={onChange}
      {...rest}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default MultiSelect;
