import { useState, useEffect } from 'react';
import LocationsWebServiceWithToken from 'domain/services/LocationsWebServiceWithToken';

function useFilteredLocations(deviceCategoryCode, propertyCode, deviceCode) {
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    const locationList = [];
    LocationsWebServiceWithToken.get({
      method: 'get',
      deviceCategoryCode,
      propertyCode,
      deviceCode,
    })
      .then((response) => {
        response.data.map((record) =>
          locationList.push({
            deployments: record.deployments,
            name: record.locationName,
            depth: record.depth,
            description: record.description,
            locationCode: record.locationCode,
          })
        );
        setLocations(locationList);
      })
      // Return a empty list if no location data is found
      .catch(() => {
        setLocations([]);
      });
    return () => setLocations([]);
  }, [deviceCategoryCode, propertyCode, deviceCode]);
  return locations;
}
export default useFilteredLocations;
