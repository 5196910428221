import { useEffect } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles } from '@mui/styles';
import { Dropdown } from 'base-components';
import { FilterProps } from './TableFilterFactory';

const styles = (theme: Theme) =>
  createStyles({
    flexItem: {
      margin: theme.spacing(0.5),
    },
  });

const operatorOptions = [
  {
    key: 'is',
    label: 'is',
    value: 'is',
  },
  {
    key: 'isNot',
    label: 'is not',
    value: 'isNot',
  },
];

const valueOptions = [
  {
    key: 'true',
    label: 'true',
    value: 'true',
  },
  {
    key: 'false',
    label: 'false',
    value: 'false',
  },
  {
    key: 'null',
    label: 'null',
    value: 'null',
  },
];

const TableBooleanFilter: React.VFC<FilterProps> = ({
  operator,
  value,
  classes,
  handleChangeOperator,
  handleChangeValue,
}) => {
  useEffect(() => {
    if (operator === undefined) {
      handleChangeOperator(operatorOptions[0].value);
    }
  }, [operator, handleChangeOperator]);
  return (
    <>
      <Dropdown
        label="Operator"
        name="operator"
        fullWidth
        className={classes ? classes.flexItem : undefined}
        value={operator}
        options={operatorOptions}
        onChange={(e) => handleChangeOperator(e.target.value)}
      />
      <Dropdown
        label="Value"
        name="value"
        fullWidth
        className={classes ? classes.flexItem : undefined}
        value={value}
        options={valueOptions}
        onChange={(e) => handleChangeValue(e.target.value)}
      />
    </>
  );
};

export default withStyles(styles)(TableBooleanFilter);
