import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link, Tab, Tabs, Tooltip } from 'base-components';
import DataFileService from 'domain/AppComponents/Dashboard/DataFileService';
import FileManagementActionPage from 'domain/Apps/file-management/FileManagementActionPage';
import FileManagementParameters from 'domain/Apps/file-management/FileManagementParameters';
import FileManagementQueryResults from 'domain/Apps/file-management/FileManagementQueryResults.jsx';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import TabPanel from '../device-search/TabPanel';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
});

const selectedFileMap = {};
const defaultDevice = { value: 0, label: '' };

const defaultState = {
  device: defaultDevice,
  tabValue: 0,
  rowsForActionsTab: [],
  selections: [],
  fileSearchValue: '',
  deviceName: '',
  tableRows: [],
  totalDeviceRecords: 0,
  startIndex: 0,
  numberofrecords: 0,
  timeRangeValue: 3,
  isLoading: false,
  isRowCountLoading: false,
};

class FileManagementPage extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  componentDidMount() {
    const { timeRangeValue } = this.state;
    this.handleTimeRangeValueChange({ target: { value: timeRangeValue } });
  }

  // call to get the files for a device
  getDeviceFiles = async (
    startIndex,
    numberofrecords,
    currentFileStateLetter
  ) => {
    const { onError } = this.props;
    const { device, dateFrom, dateTo, fileSearchValue } = this.state;

    this.setState({ isLoading: true });

    if (currentFileStateLetter) {
      for (const key of Object.keys(selectedFileMap)) {
        selectedFileMap[key].fileState = currentFileStateLetter;
      }
    }

    dateFrom.setMilliseconds(0);
    dateTo.setMilliseconds(0);

    try {
      // call to get the actual files
      const response = await DataFileService.getFilesForDevice(
        device.value,
        dateFrom,
        dateTo,
        fileSearchValue,
        startIndex,
        numberofrecords
      );
      this.buildTableRows(response);
    } catch (e) {
      onError(e.message);
    }
    this.setState({
      isLoading: false,
      startIndex,
      numberofrecords,
    });
  };

  // building the table with options derived from getDevices
  buildTableRows = (response) => {
    const tableRows = response.data.records.map((file) => {
      const tableRow = {
        id: file.dataFileId,
        fileId: file.dataFileId,
        deviceId: file.deviceId,
        deviceName: file.deviceName,
        fileNameForSearch: file.filename,
        fileName: (
          <Link href={`AdFile?filename=${file.filename}`} download>
            {file.filename}
          </Link>
        ),
        fileState: file.fileState,
        startDate: file.dateFrom,
        endDate: file.dateTo,
        fileSize: `${file.compressedSize.toLocaleString()}/${file.unCompressedSize.toLocaleString()}`,
        unCompressedSize: file.unCompressedSize,
        key: file.dataFileId.toString(),
      };
      if (selectedFileMap[file.dataFileId]) {
        selectedFileMap[file.dataFileId] = tableRow;
      }
      return tableRow;
    });
    this.setState({ tableRows }, this.updateActionsTable);
  };

  handleFileSearchChange = (event) => {
    this.setState({ fileSearchValue: event.target.value });
  };

  handleStartDateSelectorChange = (value) => {
    this.setState({ dateFrom: value, timeRangeValue: 5 });
  };

  handleEndDateSelectorChange = (value) => {
    this.setState({ dateTo: value, timeRangeValue: 5 });
  };

  handleDeviceDropDownChange = (event, device) => {
    this.setState({
      device: device || defaultDevice,
    });
  };

  // Used in unit testing
  // eslint-disable-next-line react/no-unused-class-component-methods
  handleAutocompleteInputChange = (event, val, reason) => {
    if (reason === 'input') {
      this.setState({ deviceName: val });
    }
  };

  handleTabChange = (_event, value) => {
    this.setState({ tabValue: value });
  };

  handleFileNameAndSelectDeviceReset = () => {
    this.setState({ fileSearchValue: '', device: defaultDevice });
  };

  runSearch = async () => {
    const { onError } = this.props;

    try {
      this.setState({ tabValue: 1 });
    } catch (e) {
      onError(e.message);
      this.setState({ isLoading: false });
    }
  };

  handleEndDateTimeReset = () => {
    const { dateTo, dateFrom } = this.state;
    const endTimeReset = new Date(dateTo.setUTCHours(0, 0, 0, 0));
    const startTimeReset = new Date(dateFrom.setUTCHours(0, 0, 0, 0));
    this.setState({
      dateTo: endTimeReset,
      dateFrom: startTimeReset,
    });
  };

  handleTimeRangeValueChange = (event) => {
    let dateFrom;
    const dateTo = new Date();
    switch (event.target.value) {
      case 0:
        dateFrom = new Date(dateTo - 10 * 60 * 1000);
        break;
      case 1:
        dateFrom = new Date(dateTo - 60 * 60 * 1000 * 2);
        break;
      case 2:
        dateFrom = new Date(dateTo - 60 * 60 * 1000 * 24);
        break;
      case 3:
        dateFrom = new Date(dateTo - 60 * 60 * 1000 * 24 * 7);
        break;
      default:
        dateFrom = new Date(2005, 11, 31, 16);
        break;
    }
    this.setState({
      timeRangeValue: event.target.value,
      dateFrom,
      dateTo,
    });
  };

  updateActionsTable = () => {
    const { selections } = this.state;
    const updatedRowsForActionsTab = [];
    for (const fileId of selections) {
      if (selectedFileMap[fileId]) {
        updatedRowsForActionsTab.push(selectedFileMap[fileId]);
      }
    }
    this.setState({ rowsForActionsTab: updatedRowsForActionsTab });
  };

  handleSelectionChangeForQueryResults = (selectedFileIds) => {
    const { tableRows } = this.state;
    for (const key in selectedFileMap) {
      if (!selectedFileIds.includes(parseInt(key, 10))) {
        delete selectedFileMap[key];
      }
    }
    for (const selectedFileId of selectedFileIds) {
      if (!selectedFileMap[selectedFileId.toString()]) {
        const row = tableRows.find(
          (tableRow) => tableRow.fileId === selectedFileId
        );
        if (row) {
          selectedFileMap[selectedFileId] = row;
        }
      }
    }
    this.handleSelectionChange(selectedFileIds, Object.values(selectedFileMap));
  };

  handleSelectionChange = (selections, rowsToAddForActionsTab) => {
    this.setState({
      selections,
      rowsForActionsTab: rowsToAddForActionsTab,
    });
  };

  handleRemoveAllRows = () => {
    this.setState({
      selections: [],
      rowsForActionsTab: [],
    });
  };

  handleRemoveSelectedRow = (selectedFileId) => {
    const { selections } = this.state;
    const newSelectionArray = selections.filter(
      (fileId) => fileId !== selectedFileId
    );
    this.handleSelectionChangeForQueryResults(newSelectionArray);
  };

  renderTab = (title, disabled) => {
    if (disabled) {
      return (
        <Tab
          style={{ pointerEvents: 'auto' }}
          label={
            <Tooltip title="A Device must be selected">
              <span>{`${title}`}</span>
            </Tooltip>
          }
          disabled
        />
      );
    }
    return <Tab label={title} />;
  };

  render() {
    const { classes } = this.props;
    const {
      device,
      tabValue,
      rowsForActionsTab,
      selections,
      dateFrom,
      dateTo,
      fileSearchValue,
      isLoading,
      isRowCountLoading,
      tableRows,
      totalDeviceRecords,
      startIndex,
      numberofrecords,
      timeRangeValue,
    } = this.state;

    const disabled = device.value === 0;
    return (
      <Panel classes={classes}>
        <Tabs
          value={tabValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Query Parameters" />
          {this.renderTab('Query Results', disabled)}
          <Tab label="Download/Action" />;
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FileManagementParameters
            onDeviceDropDownChange={this.handleDeviceDropDownChange}
            device={device}
            onStartDateSelectorChange={this.handleStartDateSelectorChange}
            onEndDateSelectorChange={this.handleEndDateSelectorChange}
            onFileSearchChange={this.handleFileSearchChange}
            fileSearchValue={fileSearchValue}
            onFileNameAndSelectDeviceReset={
              this.handleFileNameAndSelectDeviceReset
            }
            onSearchButtonClick={this.runSearch}
            dateFrom={dateFrom}
            dateTo={dateTo}
            onEndDateTimeReset={this.handleEndDateTimeReset}
            onTimeRangeChange={this.handleTimeRangeValueChange}
            timeRangeValue={timeRangeValue}
            disabled={disabled}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FileManagementQueryResults
            selections={selections}
            getDeviceFiles={this.getDeviceFiles}
            isLoading={isLoading}
            isRowCountLoading={isRowCountLoading}
            tableRows={tableRows}
            onSelectionChange={this.handleSelectionChangeForQueryResults}
            totalDeviceRecords={totalDeviceRecords}
            updateActionsTable={this.updateActionsTable}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <FileManagementActionPage
            rowsForActionsTab={rowsForActionsTab}
            selections={selections}
            removeSelectedRow={this.handleRemoveSelectedRow}
            removeAllRows={this.handleRemoveAllRows}
            getDeviceFiles={this.getDeviceFiles}
            startIndex={startIndex}
            numberofrecords={numberofrecords}
          />
        </TabPanel>
      </Panel>
    );
  }
}

export default withStyles(styles)(withSnackbars(FileManagementPage));
