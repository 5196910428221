import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InfoItem from 'library/CompositeComponents/list-items/InfoItem';
import DateUtils from 'util/DateUtils';

class InfoItemDate extends PureComponent {
  static propTypes = {
    primary: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
  };

  render() {
    const { primary, date } = this.props;
    const timeStamp = DateUtils.formatDateAsString(date);
    return <InfoItem primary={primary} secondary={timeStamp} />;
  }
}

export default InfoItemDate;
