import { PureComponent } from 'react';
import { FabProps } from '@mui/material';
import { FloatingActionButton as Fab } from 'base-components';

interface MapFABProps extends Omit<FabProps, 'ref'> {
  /**
   * ID of the Map from leaflet. TODO: Switch to getting this from useMap in
   * react-leaflet
   */
  mapId?: string;
  /** What to do when the button is clicked */
  onClick: (event: any) => void;
  /** Add this to the ID generated by Leaflet */
  idTag: string;
  // see Tooltip Base Component for props to pass in
  TooltipProps: {
    /** Tooltip title */
    title: string;
  };
  /** Exposed parts of Leaflet's map. TODO: Switch to useMap from ReactLeaflet */
  map?: any;
  /** ID to use for this container */
  containerId?: string;
  /** Standard aria-label for screen readers */
  ariaLabel?: string;
  /**
   * Ignore the pointer in and out events. Useful if you need to disable
   * dragging on click
   */
  ignorePointerInAndOut?: boolean;

  /** React children */
  children?: JSX.Element;

  /** Leaflet classes on the container */
  containerClasses?: any;

  /** When disabled this appears greyed out and is uninteractable */
  disabled?: boolean;

  /** Size of the button such as small or medium */
  size?: any;

  /** Material className */
  className?: string;
}

type MapFABState = {
  tooltipProps: any;
};

/**
 * MapFAB, a base FAB button to add to leaflet maps.
 *
 * See MapFABControl if you want to add a custom button to the map.
 */
class MapFAB extends PureComponent<MapFABProps, MapFABState> {
  static defaultProps = {
    mapId: '',
    containerId: undefined,
    ariaLabel: '',
    map: undefined,
    ignorePointerInAndOut: false,
    children: undefined,
    containerClasses: undefined,
    disabled: false,
    size: undefined,
    className: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      tooltipProps: this.getTooltipProps(),
    };
  }

  componentDidUpdate(prevProps) {
    const { TooltipProps } = this.props;

    if (TooltipProps !== prevProps.TooltipProps) {
      this.updateTooltipProps();
    }
  }

  getTooltipProps = () => {
    const { TooltipProps, containerId } = this.props;

    return { ...TooltipProps, containerId };
  };

  updateTooltipProps = () => {
    this.setState({
      tooltipProps: this.getTooltipProps(),
    });
  };

  /** Disables map from zooming in or panning on button hover. */
  handlePointerOver = () => {
    const { map, ignorePointerInAndOut } = this.props;
    if (ignorePointerInAndOut) return;

    if (map) {
      map.dragging.disable();
      map.doubleClickZoom.disable();
    }
  };

  /** Enables map to zoom/pan once leaves button. */
  handlePointerOut = () => {
    const { map, ignorePointerInAndOut } = this.props;
    if (ignorePointerInAndOut) return;

    if (map) {
      map.dragging.enable();
      map.doubleClickZoom.enable();
    }
  };

  onButtonClick = (e) => {
    const { onClick, ignorePointerInAndOut } = this.props;
    if (!ignorePointerInAndOut) {
      this.handlePointerOut();
    }

    onClick(e);
  };

  render() {
    const {
      ariaLabel,
      idTag,
      mapId,
      TooltipProps,
      containerId,
      onClick,
      ignorePointerInAndOut,
      ...otherProps
    } = this.props;

    const { tooltipProps } = this.state;

    return (
      <Fab
        id={`${mapId}-${idTag}`}
        className={`${mapId}-leaflet-${idTag}-mdl leaflet-bar-mdl`}
        // @ts-expect-error not exposed in Fab but seems to get passed down far enough to still work
        TooltipProps={tooltipProps}
        aria-label={ariaLabel || tooltipProps.title}
        onPointerOver={this.handlePointerOver}
        onPointerOut={this.handlePointerOut}
        onClick={this.onButtonClick}
        {...otherProps}
      />
    );
  }
}
export default MapFAB;
