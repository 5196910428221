import { memo } from 'react';
import * as React from 'react';
import { withStyles, createStyles } from '@mui/styles';
import { TableOld as Table } from 'base-components';
import { ParsingErrorLine } from '../wizards/chat-log-ingestion/ChatLogIngestionStepper';

type Props = {
  errorMessages: ParsingErrorLine[];
  classes: { stepDiv: string };
};

const styles = () =>
  createStyles({
    stepDiv: {
      margin: '25px 0px',
      padding: '0px 24px',
    },
  });

const columns = [
  { name: 'number', title: '#' },
  { name: 'line', title: 'Original Line' },
  { name: 'error', title: 'Error Message' },
];
const columnExtensions = [
  {
    columnName: 'number',
    width: 100,
    align: 'right',
    groupingEnabled: false,
  },
  {
    columnName: 'line',
    wordWrapEnabled: true,
    sortingEnabled: false,
    groupingEnabled: false,
  },
  {
    columnName: 'error',
    wordWrapEnabled: true,
    width: 350,
  },
];

const ChatLogErrorTable: React.VFC<Props> = (props: Props) => {
  const { errorMessages, classes } = props;

  return (
    <div className={classes.stepDiv}>
      <Table
        height={350}
        columns={columns}
        rows={errorMessages}
        columnExtensions={columnExtensions}
        sortable
        disabledSort={columnExtensions}
        groupable
        GroupingStateProps={{ columnExtensions }}
      />
    </div>
  );
};

export default withStyles(styles)(memo(ChatLogErrorTable));
