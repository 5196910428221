import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { LoadingButton, TextField, Tooltip } from 'base-components';

const styles = (theme) => ({
  createButton: {
    width: '100%',
    textAlign: 'end',
  },
  nameField: {
    width: '40%',
  },
  buttonOne: {
    paddingRight: theme.spacing(),
  },
});

const SeaTubeNewPlaylistTab = ({
  classes,
  newPlaylistName,
  paddingBefore,
  paddingAfter,
  onPaddingBeforeChange,
  onPaddingAfterChange,
  onNewPlaylistNameChange,
  onCreateSubmit,
  disabled,
  isLoggedIn,
  loadingPlaylist,
}) => {
  const isPaddingTimeBothZero = paddingBefore === 0 && paddingAfter === 0;
  const isButtonDisabled =
    !isLoggedIn ||
    disabled ||
    isPaddingTimeBothZero ||
    paddingBefore < 0 ||
    paddingAfter < 0;

  return (
    <>
      <TextField
        className={classes.nameField}
        translationKey="seatube.playlistName"
        value={newPlaylistName}
        onChange={onNewPlaylistNameChange}
      />
      <br />
      <TextField
        className={classes.buttonOne}
        id="padding-time-before"
        translationKey="seatube.paddingTimeBefore"
        type="number"
        value={paddingBefore}
        onChange={onPaddingBeforeChange}
        error={paddingBefore < 0 || isPaddingTimeBothZero}
        helperText={
          isPaddingTimeBothZero ? `Padding Time can't both be zero` : ''
        }
      />
      <TextField
        id="padding-time-after"
        translationKey="seatube.paddingTimeAfter"
        type="number"
        value={paddingAfter}
        onChange={onPaddingAfterChange}
        error={paddingAfter < 0 || isPaddingTimeBothZero}
        helperText={
          isPaddingTimeBothZero ? `Padding Time can't both be zero` : ''
        }
      />
      <br />
      <br />
      <div className={classes.createButton}>
        <Tooltip
          id="login-tooltip"
          title={
            isLoggedIn ? '' : 'You must be logged in to create a new playlist!'
          }
          disableHoverListener={isLoggedIn}
          disableTouchListener
          disableFocusListener
        >
          <span>
            <LoadingButton
              variant="contained"
              onClick={onCreateSubmit}
              disabled={isButtonDisabled}
              translationKey="seatube.createPlaylist"
              loading={loadingPlaylist}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );
};

SeaTubeNewPlaylistTab.propTypes = {
  newPlaylistName: PropTypes.string.isRequired,
  paddingBefore: PropTypes.number.isRequired,
  paddingAfter: PropTypes.number.isRequired,
  onPaddingBeforeChange: PropTypes.func.isRequired,
  onPaddingAfterChange: PropTypes.func.isRequired,
  onNewPlaylistNameChange: PropTypes.func.isRequired,
  onCreateSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    createButton: PropTypes.string,
    nameField: PropTypes.string,
    buttonOne: PropTypes.string,
  }).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loadingPlaylist: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SeaTubeNewPlaylistTab);
