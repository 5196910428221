import { useState } from 'react';
import * as React from 'react';
import { useTheme } from '@mui/styles';
import {
  ResponsiveProps,
  Responsive,
  WidthProvider,
  Layout,
  Layouts,
} from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '../grid-layout/GridLayout.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface Props extends ResponsiveProps {
  children: React.ReactNode;
  cols?: Record<string, number>;
  breakpoints?: Record<string, number>;
}

const ResponsiveGridLayoutWrapper: React.VFC<Props> = ({
  children,
  breakpoints = {},
  cols = {
    lg: 16,
    md: 12,
    sm: 12,
    xs: 8,
    xxs: 4,
  },
  ...props
}: Props) => {
  const theme = useTheme();

  /**
   * Generates breakpoints object based on breakpoints prop and material ui
   * theme breakpoints. Makes sure that all breakpoints are covered by filling
   * any missing breakpoints from props with material ui default breakpoints
   * (see
   * https://material-ui.com/customization/default-theme/?expend-path=$.breakpoints.values).
   *
   * @returns Object of breakpoints for each breakpoint: lg, md, sm, xs, xxs
   */
  const generateBreakpoints = () => {
    /* Insert joke where material is trying to make their
     * breakpoints feel fat with their sizings.
     */
    const layoutBreakpoints = {
      lg: breakpoints.lg || theme.breakpoints.values.xl,
      md: breakpoints.md || theme.breakpoints.values.lg,
      sm: breakpoints.sm || theme.breakpoints.values.md,
      xs: breakpoints.xs || theme.breakpoints.values.sm,
      xxs: breakpoints.xxs || theme.breakpoints.values.xs,
    };
    return layoutBreakpoints;
  };

  const [layouts, setLayouts] = useState<Layouts | undefined>(undefined);

  /**
   * Wrap child in div because react-grid-layout works best with divs.
   *
   * @param child React child
   * @returns Div containing child parameter, or returns child if already a div
   */
  const wrapChild = (child: any) => {
    if (child && child.type !== 'div') {
      let newChild;
      // Dashboard widgets which have custom default sizes for each widget type defined in dataGrid prop
      if (child.props.dataGrid) {
        const { dataGrid } = child.props;
        newChild = (
          // Add the custom sizing to the div around the widget
          <div key={child.key} data-grid={dataGrid}>
            {child}
          </div>
        );
      } else {
        newChild = <div key={child.key}>{child}</div>;
      }
      return newChild;
    }
    return child;
  };

  /**
   * Iterates through children and wraps them in a div if they are not already
   * one.
   *
   * @returns Either one wrapped child or an array of wrapped children,
   *   depending on the amount of children.
   */
  const renderChildren = () => {
    if (!Array.isArray(children)) {
      return wrapChild(children);
    }

    return children.map((child) =>
      // Wrap non-divs so that they work with layout.
      wrapChild(child)
    );
  };

  const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
    setLayouts(allLayouts);
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      breakpoints={generateBreakpoints()}
      onLayoutChange={onLayoutChange}
      layouts={layouts}
      cols={cols}
      resizeHandles={['se', 's', 'e']}
      {...props}
    >
      {renderChildren()}
    </ResponsiveGridLayout>
  );
};

export default ResponsiveGridLayoutWrapper;
