import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import DeviceIpService from 'domain/services/DeviceIpService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import { FloatRightButtonLowStyles } from 'library/CompositeComponents/button/CommonButtonStyles';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const styles = {
  button: FloatRightButtonLowStyles,
};

const headers = [
  { title: 'IP ID', name: 'ipId' },
  { title: 'DNS Name', name: 'dnsName' },
  { title: 'IP Address', name: 'ipAddress' },
  { title: 'IP Port', name: 'ipPort' },
  { title: 'Date From', name: 'dateFrom' },
  { title: 'Date To', name: 'dateTo' },
  { title: 'IP Type', name: 'ipType' },
  { title: 'MAC Address', name: 'macAddress' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'IP ID For Search', name: 'IPIDForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'ipId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'dnsName',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'ipAddress',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'ipPort',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'dateFrom',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'dateTo',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'ipType',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'macAddress',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'IPIDForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class DeviceIpTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
    }).isRequired,
  };

  compareDate = (a, b) => {
    const regex = '/-/g';
    if (a === undefined && b === undefined) return 0;
    if (a === undefined) return 1;
    if (b === undefined) return -1;
    const priorityA = new Date(a.replace(regex, ' '));
    const priorityB = new Date(b.replace(regex, ' '));
    if (priorityA.getTime() === priorityB.getTime()) {
      return 0;
    }
    return priorityA < priorityB ? -1 : 1;
  };

  compareIpId = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError, deviceId } = this.props;
    this.setState({ isLoading: true });
    DeviceIpService.getMany(deviceId)
      .then((result) => {
        this.buildTableRows(result);
      })
      .catch((result) => {
        onError(result);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  actionContent = () => {
    const { deviceId, permission, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceActionRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    if (permission !== 'RW') {
      return <DeviceActionRefreshButton />;
    }
    return (
      <>
        <DeviceActionRefreshButton />
        <a href={`${Environment.getDmasUrl()}/IP?deviceId=${deviceId}`}>
          <ContainedButton
            translationKey="device.addIp"
            startIcon={<Add />}
            className={classes.button}
          />
        </a>
      </>
    );
  };

  deviceIpLink = (ipId) => {
    const { deviceId } = this.props;
    return (
      <a
        href={`${Environment.getDmasUrl()}/IP?IpId=${ipId}&deviceId=${deviceId}`}
      >
        {ipId}
      </a>
    );
  };

  buildTableRows = (ipList) => {
    this.setState({
      tableRows: ipList.map((entry) => {
        const { ip, dmasUser, ipType } = entry;
        const ipFor = {
          ...ip,
          ipId: this.deviceIpLink(ip.ipId),
          ipType: ipType.ipTypeName,
          dateFrom: DateFormatUtils.formatDate(
            ip.dateFrom,
            'time-with-month-name-string'
          ),
          dateTo:
            ip.dateTo !== '' && ip.dateTo !== undefined
              ? DateFormatUtils.formatDate(
                  ip.dateTo,
                  'time-with-month-name-string'
                )
              : undefined,
          modifyDate: DateFormatUtils.formatDate(ip.modifyDate, 'full'),
          modifyBy: `${dmasUser.firstname} ${dmasUser.lastname}`,
          IPIDForSearch: ip.ipId,
        };
        return ipFor;
      }),
    });
  };

  render() {
    const { permission } = this.props;
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    return (
      <Panel
        title={<Typography variant="h6">IP List</Typography>}
        actionContent={this.actionContent()}
      >
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={10}
          rows={tableRows}
          searchable
          searchBarMoveable={permission === 'RW'}
          stripedRows
          sort={[
            {
              columnName: 'dateFrom',
              direction: 'desc',
            },
          ]}
          sortExtensions={[
            {
              columnName: 'dateFrom',
              compare: this.compareDate,
            },
            {
              columnName: 'dateTo',
              compare: this.compareDate,
            },
            {
              columnName: 'ipId',
              compare: this.compareIpId,
            },
          ]}
          hiddenColumns={['IPIDForSearch']}
          isLoading={isLoading}
        />
      </Panel>
    );
  }
}

export default withStyles(styles)(DeviceIpTable);
