import * as React from 'react';
import {
  Autocomplete,
  LabelledCheckbox,
  NestedItem,
  NestedItemType,
  TextField,
} from 'base-components';
import NestedMenuSelect from 'library/CompositeComponents/select/NestedMenuSelect';
import { FormField, FormFieldMenuItem } from '../util/FormFieldParser';

type Props = {
  data: FormField;
  onChange: (e: any, type?: string) => void;
  disabled: boolean;
  value: any;
  name: string;
  label: string;
};

const FormFieldValue: React.VFC<Props> = (props: Props) => {
  const { data, onChange, value, name, label, disabled } = props;

  const parseMenuItems = (menuItems: FormFieldMenuItem[]) => {
    const newMenuItems: NestedItem[] = [];
    menuItems.forEach((item) => {
      const newItem: NestedItemType = {
        label: item.text,
        key: item.value || item.text,
        value: item.value || item.text,
      };
      if (item.submenu) {
        newItem.children = parseMenuItems(item.submenu.itemdata);
      }
      newMenuItems.push(new NestedItem(newItem));
    });
    return newMenuItems;
  };

  const getSelectedOption = (options, val) =>
    options ? options.find((option) => option.value === val) : undefined;

  const renderAutocomplete = (overrideProps) => (
    <Autocomplete
      name={name}
      translationKey="common.textfields.variable"
      translationOptions={{ variableName: label }}
      options={data.choices || []}
      onChange={(e) =>
        e.target.value
          ? onChange(e.target.value.value, data.type)
          : onChange(null, undefined)
      }
      getOptionLabel={(option) => (option.label ? option.label : 'undefined')}
      value={getSelectedOption(data.choices, value) || null}
      data-test="valueAutocomplete"
      disabled={disabled}
      {...overrideProps}
    />
  );

  const addOptions = (e) => {
    if (e.target.value.value) {
      onChange(e.target.value.value, data.type);
    } else {
      const newOption = {
        value: e.target.value,
        label: e.target.value,
      };
      if (data.choices) {
        data.choices.push(newOption);
      }
      onChange(e.target.value, data.type);
    }
  };

  const renderFreeSoloAutocomplete = (overrideProps) => (
    <Autocomplete
      freeSolo
      autoSelect
      translationKey="common.textfields.variable"
      translationOptions={{ variableName: label }}
      name={name}
      options={data.choices || []}
      onChange={(e) =>
        e.target.value ? addOptions(e) : onChange(null, undefined)
      }
      getOptionLabel={(option) => (option.label ? option.label : value)}
      value={getSelectedOption(data.choices, value) || null}
      data-test="valueFreeSoloAutocomplete"
      disabled={disabled}
      {...overrideProps}
    />
  );

  const renderType = () => {
    const multiSelectProps = {
      multiple: true,
      value: Array.isArray(value) ? value : [],
      onChange: (e) => onChange(e.target.value, data.type),
    };

    switch (data ? data.type : undefined) {
      case 'multiselect':
        return renderAutocomplete(multiSelectProps);
      case 'autocomplete':
        return renderFreeSoloAutocomplete({});
      case 'select':
        return renderAutocomplete({});
      case 'boolean':
        return (
          <LabelledCheckbox
            name={name}
            label={data.rightLabel || 'True?'}
            onChange={() => onChange(!value, data.type)}
            value={!!value}
            data-test="valueCheckbox"
            disabled={disabled}
          />
        );
      case 'menu':
        return (
          <NestedMenuSelect
            label={label}
            name={name}
            onChange={(e) => onChange(e.target.value, data.type)}
            value={value || null}
            menuItems={data.menuItems ? parseMenuItems(data.menuItems) : []}
            disabled={disabled}
          />
        );
      case 'text':
        return (
          <TextField
            name={name}
            fullWidth
            value={value || ''}
            onChange={(e) => onChange(e.target.value, data.type)}
            translationKey="common.textfields.variable"
            translationOptions={{ variableName: label }}
            disabled={disabled}
          />
        );
      default:
        return undefined;
    }
  };

  return <>{renderType()}</>;
};

export default FormFieldValue;
