import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { LoadingSnackbar } from '@onc/composite-components';
import { Tab, Tabs } from 'base-components';

import DeviceService from 'domain/services/DeviceService';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';
import DeviceSearchParameterTab from './DeviceSearchParameterTab';
import DeviceSearchResultsTab from './DeviceSearchResultsTab';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  tableDiv: {
    margin: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
});

class DeviceSearchPage extends PureComponent {
  defaultState = {
    value: 0,
    regionId: -1,
    locationId: -1,
    deviceCategoryId: -1,
    deviceId: undefined,
    deviceName: undefined,
    ip: undefined,
    dns: undefined,
    serialnr: undefined,
    tier: -1,
    pi: -1,
    disabled: true,
  };

  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.defaultState,
      devices: [],
    };
  }

  componentDidMount() {
    this.getDevices();
  }

  getDevices = () => {
    const { onError } = this.props;
    const effectivedate = new Date();

    const effectivedateDateStr = DateFormatUtils.formatDate(
      effectivedate,
      'ISO8601_EXTENDED'
    );

    DeviceService.getByEffectiveDate(effectivedateDateStr)
      .then((response) => {
        this.setState({ devices: response.data, disabled: false });
      })
      .catch(() => {
        this.setState({ disabled: false });
        onError('Failed to load ');
      });
  };

  tabChange = (_event, value) => {
    this.setState({ value });
  };

  onSearchClick = (form) => {
    this.setState({
      value: 1,
      regionId: form.region,
      locationId: form.location,
      deviceCategoryId: form.deviceCategory,
      deviceId: Number(form.deviceId),
      deviceName: form.deviceName,
      ip: form.ipAddress,
      dns: form.dnsName,
      serialnr: form.serialNumber,
      tier: form.securityTier,
      pi: form.principalInvestigator,
    });
  };

  getResultsClass = () => {
    const { value } = this.state;
    const { classes } = this.props;

    return value === 1 ? undefined : classes.hide;
  };

  loadingDataSnackBar = () => {
    const { disabled } = this.state;
    if (disabled) {
      return <LoadingSnackbar message="Loading search data..." />;
    }
    return null;
  };

  render() {
    const { classes, onInfo } = this.props;
    const {
      value,
      regionId,
      locationId,
      deviceCategoryId,
      deviceId,
      deviceName,
      ip,
      dns,
      serialnr,
      tier,
      pi,
      devices,
      disabled,
    } = this.state;
    return (
      <div className={classes.root}>
        <Panel classes={{ root: classes.root }}>
          <Tabs
            value={value}
            onChange={this.tabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Query Parameters" disabled={disabled} />
            <Tab label="Results" disabled={disabled} />
          </Tabs>
          <div className={value === 1 ? classes.hide : undefined}>
            <DeviceSearchParameterTab
              privilege={Environment.getDmasUserPrivilege()}
              onSearchClick={this.onSearchClick}
              onInfo={onInfo}
            />
          </div>
          <div className={this.getResultsClass()}>
            <DeviceSearchResultsTab
              devices={devices}
              regionId={regionId}
              locationId={locationId}
              deviceCategoryId={deviceCategoryId}
              deviceId={deviceId}
              deviceName={deviceName}
              ip={ip}
              dns={dns}
              serialnr={serialnr}
              tier={tier}
              pi={pi}
              classes={classes}
              permission={Environment.getDmasUserPrivilege()}
            />
          </div>
        </Panel>
        {this.loadingDataSnackBar()}
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(DeviceSearchPage));
