export const RESOURCE_ID_FOR_NORTEK_ADCP_SEARCH_OPTIONS = 2410;
export const RESOURCE_ID_FOR_RDI_ADCP_SEARCH_OPTIONS = 60;
export const RESOURCE_ID_FOR_HYDROPHONE_SEARCH_OPTIONS = 31761;
export const NUMBER_OF_ADCP_SEARCH_PARAMETERS = {
  NUMBER_SEARCH_OPTIONS_NORTEK: 7,
  NUMBER_SEARCH_OPTIONS_RDI: 11,
};

// For parsing data product options
const VELOCITY_BIN_MAPPING_IDX = 0;
const HORIZONTAL_CURRENT_LIMITS_IDX = 1;
const VERTICAL_CURRENT_LIMITS_IDX = 2;
export const INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK = {
  VELOCITY_BIN_MAPPING_IDX,
  HORIZONTAL_CURRENT_LIMITS_IDX,
  VERTICAL_CURRENT_LIMITS_IDX,
  LOWER_BACKSCATTER_LIMIT_IDX: 3,
  UPPER_BACKSCATTER_LIMIT_IDX: 4,
  BACKSCATTER_COLOUR_IDX: 5,
  IMAGE_SIZE_IDX: 6,
};
export const INDICES_OF_DATA_PRODUCT_OPTIONS_RDI = {
  VELOCITY_BIN_MAPPING_IDX,
  HORIZONTAL_CURRENT_LIMITS_IDX,
  VERTICAL_CURRENT_LIMITS_IDX,
  THREE_BEAM_SOLUTIONS_IDX: 3,
  LOW_CORRELATION_SCREEN_THRESHOLD_IDX: 4,
  ERR_CORRELATION_SCREEN_THRESHOLD_IDX: 5,
  FALSE_TARGET_THRESHOLD_MAX_IDX: 6,
  LOWER_BACKSCATTER_LIMIT_IDX: 7,
  UPPER_BACKSCATTER_LIMIT_IDX: 8,
  BACKSCATTER_COLOUR_IDX: 9,
  IMAGE_SIZE_IDX: 10,
};
export const FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE = {
  COLOUR_PALETTE_ID: '163',
  UPPER_COLOUR_LIMIT_ID: '164',
  LOWER_COLOUR_LIMIT_ID: '166',
  SPECTROGRAM_SOURCE_ID: '119',
  HYDROPHONE_DATA_ACQUISITION_ID: '135',
  HYDROPHONE_DATA_DIVERSION_ID: '55',
  HYDROPHONE_CHANNEL_ID: '54',
  SPECTROGRAM_CONCATENATION_ID: '159',
  SPECTROGRAM_FREQUENCY_UPPER_LIMIT_ID: '191',
};

// This is currently what MatLab image sizes are but this should be retrieved dynamically
export const IMAGE_WIDTH = 932;
export const SECONDS_PER_FIVE_MINUTES = 300;

// Default size of the widget when on a 1680 width window resolution. Used as a baseline for aligning widget images to the startDate.
export const INITIAL_WIDGET_WIDTH = 635;
// Size of initial images before resize in the dataplayer widget
export const INITIAL_WIDGET_IMAGE_WIDTH = 540.453125;

// Values used to dynamically calculate container and image item margins for the widget
export const WIDGET_IMAGE_CONTAINER_OFFSET = 522;
export const WIDGET_IMAGE_ITEM_DIVISOR = 5;
export const WIDGET_IMAGE_ITEM_GAP = 121;
// Offset to calculate the inital translate for the widget
// Denotes # of pixels images are offset by default
export const WIDGET_IMAGE_OFFSET = 24;
/* Ratio calculated by taking the difference of initial dataplayer root sizes between 1680 and 1920 window resolutions.
Used to account for widget size changes as a result of its window resolution (50/95) */
export const WIDGET_WINDOWSIZE_TO_PIXEL_RATIO = 0.526;

// This is for daily plots
export const DAILY_TIMERANGE = '1440';
export const WEEKLY_TIMERANGE = '10080';
export const SECONDS_PER_DAY = 86400;

// Set number of plots for initial generation
export const NUMBER_PLOTS_INITIAL_LOAD = 21;
export const EXPECTED_WINDOW_SIZE = 2075;

// When there is less than this many spectra to the end or start it will load more
export const IMAGE_LOAD_BUFFER = 2;
export const HYDROPHONE_DEVICE_CATEGORY_CODE = 'HYDROPHONE';
export const ADCP_DEVICE_CATEGORY_CODES = [
  'ADCP1MHZ',
  'ADCP600KHZ',
  'ADCP300KHZ',
];
// object that links device categories with device category ids (device category ids are not returned in service calls)
export const DEVICE_CATEGORY_CODE_DEVICE_CATEGORY_ID_OBJ = {
  HYDROPHONE: 19,
  ADCP1MHZ: 102,
  ADCP600KHZ: 71,
  ADCP300KHZ: 70,
};

export const RDI = 'RDI';

export const NUMBER_OF_FILES_WHEN_PUSHING_SHIFTING = 5;
// 5 * 5 minute files.
export const MINUTES_WHEN_PUSHING_SHIFTING = 25;

export const SCROLLING_DIRECTION = {
  STATIONARY: 'stationary',
  LEFT: 'left',
  RIGHT: 'right',
};

export const DATA_STATE = {
  RETRIEVING: 'retrieving',
  NO_DATA: 'noData',
  RESTRICTED: 'restricted',
  UNKNOWN: 'unknown',
};

// error codes
export const ERROR_CODE = {
  NO_DATA: 33,
  RESTRICTED: 71,
  UNKNOWN: -1,
};

export const RETRIEVING_SPLASH_FILENAME = 'retrievingSplashImage';

// used to determine what level threw the error in the error handler
export const ERROR_LEVEL = {
  IMAGE_REQUEST_WITH_ERROR_CODE: 'imageRequestWithErrorCode',
  IMAGE_REQUEST_WITHOUT_ERROR_CODE: 'imageRequestNoErrorCode',
  IMAGE_DOWNLOAD: 'imageDownload',
};

// data product codes
export const OPERATION_GETDATAFILES_WITH_RESTRICTION_CHECK = 9;
export const SPECTROGRAM_PREGEN_FIVE_MINUTE_PNG_DATA_PRODUCT_FORMAT_ID = 53;
export const SPECTROGRAM_PREGEN_DAILY_PNG_DATA_PRODUCT_FORMAT_ID = 275;

// Nortek and RDI have diffrent dataprouct codes for current plots
export const ADCP_PRODUCT_CODES = ['NDCP', 'RDCUP'];
export const HYDROPHONE_PRODUCT_CODE = 'HSD';

// make a map that links the above dataproduct codes to specific dataproductformatIds
const DATA_PRODUCT_CODE_DATA_PRODUCT_FORMAT_ID_MAP = new Map();
DATA_PRODUCT_CODE_DATA_PRODUCT_FORMAT_ID_MAP.set(ADCP_PRODUCT_CODES[0], 57); // Nortek
DATA_PRODUCT_CODE_DATA_PRODUCT_FORMAT_ID_MAP.set(ADCP_PRODUCT_CODES[1], 10); // RDI
DATA_PRODUCT_CODE_DATA_PRODUCT_FORMAT_ID_MAP.set(HYDROPHONE_PRODUCT_CODE, 53); // Hydrophone
export { DATA_PRODUCT_CODE_DATA_PRODUCT_FORMAT_ID_MAP };

export const OPERATION_GETDEVICECAT_SITEDEVICE_ID = 17;
export const OPERATION_GETDATAPRODUCTFORMATDEVICE_FROM_SITEDEVICE_ID = 7;

// dataproduct extension
export const IMAGE_FILE_EXTENSION = 'png';

// service strings
export const ZAP_SERVICE = 'ZapImageService';
export const ADCP_SERVICE = 'AdcpImageService';
export const API_DATA_PRODUCT_SERVICE = 'api/dataProductDelivery';
// This url will cache the request calls
export const PERFORM_REQUEST_SERVICE_CALL = 'api/acousticDataViewerImage';
export const API_DATA_PRODUCT_DISCOVERY_SERVICE = 'api/dataProducts';
export const API_DATA_FILE_SERVICE = 'DataFileService';
export const NON_TOKEN_DEVICE_SERVICE = 'DeviceService';

// Hydrophone radio buttons
export const CUSTOM_HYDROPHONE_LIMIT_OPTION = '0';
export const DEFAULT_HYDROPHONE_LIMIT_OPTION = '-1';

// used to parse date strings returned by DMAS service calls
export const DATE_FORMAT_DMAS_SERVICE_CALLS = 'DD-MMM-YYYY HH:mm:ss';
export const REGEX_TO_GET_DIGITS_AFTER_DP = 'DP\\D*(\\d+)'; // Looks for first number after DP
export const REGEX_TO_CHECK_IF_DEVICE_DEPLOYED_AND_GET_DATE =
  /^\d{1,2}-[A-Za-z]{3}-\d{4}\s\d{2}:\d{2}:\d{2}\sto\scurrent$/;
