import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import CruiseService from 'domain/services/CruiseService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

class ResourceSelect extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    name: PropTypes.string,
    title: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cruiseId: PropTypes.number,
    diveId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onOptionsLoad: PropTypes.func,
    resourceTypeId: PropTypes.number,
  };

  static defaultProps = {
    name: undefined,
    title: undefined,
    onBlur: undefined,
    onChange: undefined,
    helperText: undefined,
    className: undefined,
    value: undefined,
    cruiseId: undefined,
    diveId: undefined,
    onOptionsLoad: undefined,
    resourceTypeId: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { instrumentOptions: [] };
  }

  componentDidMount() {
    const { cruiseId, diveId } = this.props;
    this.getResourceOptions(cruiseId, diveId);
  }

  shouldComponentUpdate(nextProps) {
    const { cruiseId, diveId } = this.props;
    if (nextProps.cruiseId !== cruiseId || nextProps.diveId !== diveId) {
      this.getResourceOptions(nextProps.cruiseId, nextProps.diveId);
    }
    return true;
  }

  getResourceOptions = async (cruiseId, diveId) => {
    const { onError, onOptionsLoad } = this.props;

    try {
      const payload = await CruiseService.getResourceOptions(cruiseId, diveId);
      const { InstrumentList } = payload;
      let instrumentOptions = InstrumentList;
      InstrumentList.forEach((option, index) => {
        instrumentOptions[index].label =
          `${option.instrumentName} (${option.instrumentId})`;
        instrumentOptions[index].value = option.instrumentId.toString();
      });
      instrumentOptions = [
        {
          instrumentId: -1,
          instrumentName: 'empty',
          label: '(none)',
          value: '-1',
        },
        ...instrumentOptions,
      ];
      this.setState({ instrumentOptions }, onOptionsLoad);
    } catch (error) {
      onError(error);
    }
  };

  render() {
    const {
      name,
      title,
      onBlur,
      onChange,
      helperText,
      className,
      value,
      resourceTypeId,
      diveId,
      ...rest
    } = this.props;
    const { instrumentOptions } = this.state;
    let currentOptions = [];
    if (resourceTypeId === SeaTubeResourceTypes.DIVE) {
      currentOptions = [{ label: `Dive ${diveId}`, value: diveId }];
    } else {
      currentOptions = instrumentOptions;
    }
    return (
      <Dropdown
        options={currentOptions}
        name={name}
        title={title}
        onBlur={onBlur}
        onChange={onChange}
        helperText={helperText}
        className={className}
        label="Resource"
        value={value}
        {...rest}
      />
    );
  }
}

export default withSnackbars(ResourceSelect);
