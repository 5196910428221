import { useState } from 'react';
import { Box, TabProps } from '@mui/material';
import { Close } from '@onc/icons';
import { IconButton, Tab } from 'base-components';

const styles = {
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  closeIcon: {
    marginLeft: 1,
    marginRight: -1,
    marginTop: '-2px', // Weird spacing between the tab text and icon button
  },
};

interface CloseableTabProps extends TabProps {
  /**
   * Callback when the tab is closed. Parent will need to handle removing this
   * from the list of <Tabs /> children
   */
  onClose: (index: number) => void;
  /** Index of the tab for ordering */
  index: number;
}

const CloseableTab = (props: CloseableTabProps) => {
  const { index, label, onClose } = props;
  const [isHovered, setIsHovered] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose(index);
  };

  const renderLabel = (text) => (
    <Box sx={styles.flexContainer}>
      {text}
      <IconButton
        aria-label="Close Tab"
        sx={styles.closeIcon}
        size="small"
        disableRipple
        onClick={handleClose}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onFocus={() => setIsHovered(true)}
        onBlur={() => setIsHovered(false)}
      >
        <Close fontSize="small" color={isHovered ? 'error' : 'action'} />
      </IconButton>
    </Box>
  );
  return <Tab {...props} label={renderLabel(label)} />;
};

export default CloseableTab;
