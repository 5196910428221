import { useState } from 'react';
import DeploymentsActionFormatter from 'domain/AppComponents/synchronizer-management/formatters/DeploymentsActionFormatter';
import BooleanFormatter from 'library/BaseComponents/table/formatters/BooleanFormatter';
import TableFilterLogic from 'library/BaseComponents/table/plugins/filter/filters/TableFilterLogic';
import { TableFilter } from 'library/BaseComponents/table/plugins/filter/ToolbarFilter';
import StatelessTable, {
  TableColumn,
} from 'library/BaseComponents/table/StatelessTable';
import { useLocalStorage } from 'util/hooks/useStorage';
import { SensorTypeRow } from './SensorTypeTableLogic';

type SensorTypeTableProps = {
  rows: SensorTypeRow[];
  permission: string;
  onEdit: (row: SensorTypeRow) => void;
  onCreate: () => void;
};

const SensorTypeTable: React.FC<SensorTypeTableProps> = ({
  rows,
  permission,
  onEdit,
  onCreate,
}: SensorTypeTableProps) => {
  const storageKey = 'sensor-type-table';
  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'startDate', direction: 'asc' }],
    hiddenColumnNames: ['unitOfMeasureName', 'modifyBy', 'modifyDate'],
  };

  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  // Filtering
  const [filter, setFilter] = useState<TableFilter>(config.filter);

  const columns: TableColumn[] = [
    { title: 'Sensor Type ID', name: 'sensorTypeId' },
    { title: 'Sensor Type Name', name: 'sensorTypeName' },
    {
      title: 'Unit of Measure ID',
      name: 'unitOfMeasureId',
      dataType: 'String',
    },
    {
      title: 'Unit of Measure Name',
      name: 'unitOfMeasureName',
      dataType: 'String',
    },
    { title: 'Description', name: 'description', dataType: 'String' },
    { title: 'Is Scalar', name: 'scalar', dataType: 'Boolean' },
    { title: 'Is Averageable', name: 'averageable', dataType: 'Boolean' },
    { title: 'Sensor Type Code', name: 'sensorTypeCode', dataType: 'String' },
    { title: 'Modified By', name: 'modifyBy', dataType: 'String' },
    { title: 'Modified Date', name: 'modifyDate', dataType: 'String' },
    { title: 'Actions', name: 'actions', dataType: 'Other' },
  ];

  const getDisabledColumnNames = () => {
    if (permission !== 'RW') {
      return ['actions'];
    }
    return [];
  };

  return (
    <StatelessTable
      rows={rows}
      columns={columns}
      getRowId={(row) => row.sensorTypeId}
      searchable
      columnFormatProviders={[
        {
          name: 'IsScalar',
          for: ['scalar'],
          formatterComponent: BooleanFormatter,
        },
        {
          name: 'IsAverageable',
          for: ['averageable'],
          formatterComponent: BooleanFormatter,
        },
        {
          name: 'ActionFormatter',
          for: ['actions'],
          formatterComponent: ({ row }) =>
            DeploymentsActionFormatter({ row, onEdit }),
        },
      ]}
      sort={{
        sorting,
        customSorting: [
          {
            columnName: 'sensorTypeName',
            compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
          {
            columnName: 'unitOfMeasureName',
            compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
          {
            columnName: 'description',
            compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
          {
            columnName: 'sensorTypeCode',
            compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
        ],
        handleSortingChange: setSorting,
      }}
      paging={{ pageSize: 15, pageSizes: [15, 30, 60, 300] }}
      fixed={{
        leftColumns: [],
        rightColumns: ['actions'],
      }}
      virtual={{
        virtualized: true,
      }}
      visible={{
        hiddenColumnNames: getDisabledColumnNames(),
        disableColumnNames: getDisabledColumnNames(),
      }}
      permission={permission}
      onCreate={onCreate}
      filter={{
        filterValue: filter,
        onChange: setFilter,
        filterFn: TableFilterLogic.defaultFilter,
        hidden: false,
      }}
    />
  );
};

export default SensorTypeTable;
