import { useContext, useEffect, useState } from 'react';
import { Add, Delete } from '@onc/icons';
import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import DefaultAttributeForm from 'domain/Apps/taxonomy/DefaultAttributeForm';
import DefaultAttributeTable from 'domain/Apps/taxonomy/DefaultAttributeTable';
import TaxonAddForm from 'domain/Apps/taxonomy/TaxonAddForm';
import TaxonEditForm from 'domain/Apps/taxonomy/TaxonEditForm';
import TaxonFileUploadConfig from 'domain/Apps/taxonomy/TaxonFileUploadConfig';
import {
  TaxonomyContext,
  UserPrivilegeContext,
} from 'domain/Apps/taxonomy/TaxonomyApp';
import TaxonProperties from 'domain/Apps/taxonomy/TaxonProperties';
import TaxonomyNodeService from 'domain/services/TaxonomyNodeService';
import TaxonomyService from 'domain/services/TaxonomyService';
import TaxonService from 'domain/services/TaxonService';
import {
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget from '../../library/CompositeComponents/dashboard/Widget';
import TaxonomyForm from '../Apps/taxonomy/TaxonomyForm';

const TaxonomyDetailWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const userPrivilege = useContext(UserPrivilegeContext);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
  const {
    taxon,
    setTaxon,
    taxonomy,
    setTaxonomy,
    setMatrixId,
    refreshTaxonomies,
    setRefreshTaxonomies,
  } = useContext(TaxonomyContext);

  const { onError, onInfo } = useSnackbars();

  const [, deleteTaxonLoading, deleteTaxon] = useWebService({
    method: TaxonService.removeTaxon,
    onError,
  });
  const [, deleteTaxonomyLoading, deleteTaxonomy] = useWebService({
    method: TaxonomyService.deleteTaxonomy,
    onError,
  });

  const [descendantCount, setDescendantCount] = useState<number>();

  const handleAddTaxonButtonClicked = () => {
    setTaxon({
      allowedAttributes: [],
      commonName: '',
      deprecated: null,
      englishNames: [],
      information: '',
      jsonTaxonData: null,
      modifiedBy: null,
      modifiedDate: null,
      nodeTypeId: 36,
      originTaxonomyId: taxonomy.taxonomyId,
      referenceId: '',
      taxonId: null,
      // This is needed as the parentTaxonomyMatrixId in TaxonAddForm
      taxonomyMatrixId: taxon
        ? taxon.taxonomyMatrixId
        : taxonomy.taxonomyMatrixId,
      version: '1',
    });
  };

  const [originTaxonomy, , fetchOriginTaxonomy] = useWebService({
    method: TaxonomyService.getTaxonomy,
    onError,
  });

  useEffect(() => {
    taxon?.originTaxonomyId && fetchOriginTaxonomy(taxon.originTaxonomyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxon]);

  const isEditEnabled = () => {
    if (userPrivilege === 'RO') return false;
    if (!taxonomy?.editable) return false;
    return true;
  };

  const renderRWButtons = () => [
    <TextButton
      translationKey={
        taxon === null ? 'taxonomy.deleteTaxonomy' : 'taxonomy.deleteTaxon'
      }
      startIcon={<Delete />}
      onClick={() => setIsDeleteDialogOpen(true)}
    />,
    <ContainedButton
      translationKey="taxonomy.addTaxon"
      startIcon={<Add />}
      onClick={handleAddTaxonButtonClicked}
    />,
    <ContainedButton
      translationKey="taxonomy.uploadTaxonCSV"
      onClick={() => setIsUploadOpen(true)}
    />,
  ];

  const handlePostDelete = (
    name: string,
    taxonomyMatrixId: number,
    isTaxon: boolean,
    payload
  ) => {
    setIsDeleteDialogOpen(false);
    if (payload && payload.statusCode !== 9001) {
      const wasDeleted = isTaxon
        ? payload.deleteSuccessful
        : payload.wasDeleted;
      if (!wasDeleted) {
        isTaxon
          ? onError(
              `The delete operation could not be completed because the following taxon(s) are used in annotations: ${payload.nonRemovableTaxonNames.join(
                ', '
              )}`
            )
          : onError(`Failed to delete ${name} taxonomy`);
      } else {
        setTaxon(null);
        !isTaxon && setTaxonomy(null);
        setMatrixId(taxonomyMatrixId);
        onInfo(`${name} has been deleted`);
      }
    }
  };

  const handleDelete = () => {
    taxon !== null
      ? deleteTaxon(taxonomy.taxonomyId, taxon.taxonId).then((payload) => {
          handlePostDelete(
            taxon.commonName,
            taxon.taxonomyMatrixId,
            true,
            payload
          );
        })
      : deleteTaxonomy(taxonomy.taxonomyId).then((payload) => {
          handlePostDelete(
            taxonomy.taxonomyName,
            taxonomy.taxonomyMatrixId,
            false,
            payload
          );
        });
  };

  const fetchCount = async () => {
    if (taxonomy?.taxonomyId)
      try {
        setDescendantCount(
          await TaxonService.getTaxonDescendantsCount(
            taxonomy.taxonomyId,
            taxon?.taxonId || null
          )
        );
      } catch (error: any) {
        onError(error.message);
      }
  };

  useEffect(() => {
    fetchCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxon, taxonomy]);

  const [nodeTypes, , fetchNodeTypes] = useWebService({
    method: TaxonomyNodeService.getNodeTypes,
    onError,
  });

  const [taxonomies, , fetchTaxonomies] = useWebService({
    method: TaxonomyService.getAll,
    onError,
  });

  useEffect(() => {
    fetchNodeTypes();
    fetchTaxonomies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshTaxonomies) {
      fetchTaxonomies();
      setRefreshTaxonomies(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTaxonomies]);

  const constructDeleteMessage = () => {
    let numberMessage = '';
    if (descendantCount > 0) {
      numberMessage = numberMessage.concat(' ', `and its ${descendantCount}`);
      descendantCount === 1
        ? (numberMessage = numberMessage.concat(' ', 'child'))
        : (numberMessage = numberMessage.concat(' ', 'children'));
    }
    return `Are you sure you want to delete this ${taxon !== null ? 'taxon' : 'taxonomy'}${numberMessage}?`;
  };

  const renderForm = () => {
    if (taxon === null) {
      return (
        <TaxonomyForm
          taxonomy={taxonomy}
          taxonomies={taxonomies}
          isEditEnabled={isEditEnabled()}
          onError={onError}
          onInfo={onInfo}
        />
      );
    }
    if (userPrivilege === 'RW' && taxon?.taxonId === null) {
      return (
        <TaxonAddForm
          taxon={taxon}
          taxonomies={taxonomies}
          nodeTypes={nodeTypes}
          onError={onError}
          onInfo={onInfo}
        />
      );
    }
    return (
      <TaxonEditForm
        taxonomy={taxonomy}
        taxon={taxon}
        nodeTypes={nodeTypes}
        isEditEnabled={isEditEnabled()}
        onError={onError}
        onInfo={onInfo}
      />
    );
  };

  return (
    <Widget
      title=""
      ariaLabel="Taxonomy Details"
      actionComponents={taxonomy?.taxonomyId !== null ? renderRWButtons() : []}
      hideHeader={!isEditEnabled()}
      {...props}
    >
      {isUploadOpen && (
        <div id="upload-taxon-csv-dialog">
          <TaxonFileUploadConfig onClose={() => setIsUploadOpen(false)} />
        </div>
      )}
      {!!taxonomy && renderForm()}
      {!!taxon?.taxonId &&
        taxonomy?.taxonomyId &&
        (isEditEnabled() ? (
          <DefaultAttributeForm
            taxonomy={taxonomy}
            taxon={taxon}
            onError={onError}
            onInfo={onInfo}
          />
        ) : (
          <DefaultAttributeTable
            taxonomy={taxonomy}
            taxon={taxon}
            onError={onError}
          />
        ))}
      {taxon !== null &&
        taxon.jsonTaxonData &&
        originTaxonomy &&
        !originTaxonomy.editable && (
          <TaxonProperties jsonTaxonData={taxon.jsonTaxonData} />
        )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          open={isDeleteDialogOpen}
          title={`Delete ${
            taxon !== null ? taxon.commonName : taxonomy.taxonomyName
          }?`}
          onDelete={handleDelete}
          onCancel={() => setIsDeleteDialogOpen(false)}
          message={constructDeleteMessage()}
          loading={deleteTaxonLoading || deleteTaxonomyLoading}
        />
      )}
    </Widget>
  );
};
TaxonomyDetailWidget.widgetKey = 'taxonomy-details';
TaxonomyDetailWidget.widgetTitle = 'TaxonomyDetails';
TaxonomyDetailWidget.defaultDataGrid = {
  i: 'taxonomy-details',
  x: 0,
  y: 0,
  w: 3,
  h: 5,
};
export default TaxonomyDetailWidget;
