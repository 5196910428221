import { parseDmasAPIResponse, get } from 'util/WebRequest';

export interface FormFieldParameters {
  formTypeId: number;
  sourceIds?: string;
  resourceTypeId?: number;
  resourceId?: number;
}

const SERVICE = 'FormService';

class FormService {
  static get = (params: FormFieldParameters) =>
    get(SERVICE, params, {}).then((response) => parseDmasAPIResponse(response));

  static getFormFields = (params: FormFieldParameters) =>
    FormService.get(params);
}
export default FormService;
