import Clock from '@mui/icons-material/AccessTime';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AddAPhoto from '@mui/icons-material/AddAPhoto';
import Add from '@mui/icons-material/AddOutlined';
import Apps from '@mui/icons-material/Apps';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Assignment from '@mui/icons-material/Assignment';
import Bookmarks from '@mui/icons-material/Bookmarks';
import Brush from '@mui/icons-material/Brush';
import BugReport from '@mui/icons-material/BugReport';
import Build from '@mui/icons-material/Build';
import Business from '@mui/icons-material/Business';
import Cancel from '@mui/icons-material/Cancel';
import Chat from '@mui/icons-material/Chat';
import Check from '@mui/icons-material/Check';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Close from '@mui/icons-material/Close';
import CloudDone from '@mui/icons-material/CloudDone';
import CloudDoneOutlined from '@mui/icons-material/CloudDoneOutlined';
import CloudOff from '@mui/icons-material/CloudOff';
import CloudQueue from '@mui/icons-material/CloudQueue';
import CloudUpload from '@mui/icons-material/CloudUpload';
import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined';
import Code from '@mui/icons-material/Code';
import Dashboard from '@mui/icons-material/Dashboard';
import DateRange from '@mui/icons-material/DateRange';
import Delete from '@mui/icons-material/Delete';
import Description from '@mui/icons-material/Description';
import DirectionsBoatOutlined from '@mui/icons-material/DirectionsBoat';
import DnsIcon from '@mui/icons-material/Dns';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import Done from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import DragHandle from '@mui/icons-material/DragHandle';
import DragIndicator from '@mui/icons-material/DragIndicator';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import Edit from '@mui/icons-material/Edit';
import Error from '@mui/icons-material/Error';
import EventAvailable from '@mui/icons-material/EventAvailable';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import FastForwardIcon from '@mui/icons-material/FastForward';
import Favorite from '@mui/icons-material/Favorite';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import FileCopy from '@mui/icons-material/FileCopyOutlined';
import FilterAlt from '@mui/icons-material/FilterAlt';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import Folder from '@mui/icons-material/Folder';
import FolderOpen from '@mui/icons-material/FolderOpen';
import Forward30 from '@mui/icons-material/Forward30';
import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import GetApp from '@mui/icons-material/GetApp';
import Group from '@mui/icons-material/Group';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Info from '@mui/icons-material/Info';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Layers from '@mui/icons-material/Layers';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import Link from '@mui/icons-material/Link';
import List from '@mui/icons-material/List';
import LiveVideo from '@mui/icons-material/LiveTv';
import LocationOn from '@mui/icons-material/LocationOn';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Map from '@mui/icons-material/MapOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import MenuBook from '@mui/icons-material/MenuBook';
import Minimize from '@mui/icons-material/Minimize';
import MoreHoriz from '@mui/icons-material/MoreHorizOutlined';
import MoreVert from '@mui/icons-material/MoreVertOutlined';
import Movie from '@mui/icons-material/Movie';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NetworkCheck from '@mui/icons-material/NetworkCheck';
import OpenInBrowser from '@mui/icons-material/OpenInBrowser';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Pause from '@mui/icons-material/Pause';
import Person from '@mui/icons-material/Person';
import PictureInPictureAlt from '@mui/icons-material/PictureInPictureAlt';
import PlayArrow from '@mui/icons-material/PlayArrow';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import Public from '@mui/icons-material/Public';
import PushPin from '@mui/icons-material/PushPin';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Refresh from '@mui/icons-material/Refresh';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import Remove from '@mui/icons-material/RemoveOutlined';
import Replay from '@mui/icons-material/Replay';
import Replay30 from '@mui/icons-material/Replay30';
import Router from '@mui/icons-material/Router';
import Save from '@mui/icons-material/Save';
import SaveAs from '@mui/icons-material/SaveAs';
import Search from '@mui/icons-material/Search';
import Settings from '@mui/icons-material/Settings';
import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore';
import Share from '@mui/icons-material/Share';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShowChart from '@mui/icons-material/ShowChart';
import SkipNext from '@mui/icons-material/SkipNext';
import SkipPrevious from '@mui/icons-material/SkipPrevious';
import SlowMotionVideo from '@mui/icons-material/SlowMotionVideo';
import SubdirectoryArrowRight from '@mui/icons-material/SubdirectoryArrowRight';
import TableView from '@mui/icons-material/TableView';
import TextFields from '@mui/icons-material/TextFields';
import Theaters from '@mui/icons-material/Theaters';
import ThumbDown from '@mui/icons-material/ThumbDown';
import ThumbDownOutlined from '@mui/icons-material/ThumbDownOutlined';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined';
import Tune from '@mui/icons-material/Tune';
import Update from '@mui/icons-material/Update';
import VerticalAlignBottom from '@mui/icons-material/VerticalAlignBottom';
import Videocam from '@mui/icons-material/Videocam';
import VideocamOff from '@mui/icons-material/VideocamOff';
import ViewColumn from '@mui/icons-material/ViewColumn';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeOff from '@mui/icons-material/VolumeOff';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Warning from '@mui/icons-material/Warning';
import WarningOutlined from '@mui/icons-material/WarningOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import SvgIcon, { SvgIconTypeMap } from '@mui/material/SvgIcon';

// Custom made icons
import CopyIcon from './icons/CopyIcon';
import CsvIcon from './icons/CsvIcon';
import {
  ToggleLegendIcon,
  MaterialZoomIcon,
  MaterialSettingsIcon,
  MaterialRemoveIcon,
} from './icons/PlotlyIcons';
import XmlIcon from './icons/XmlIcon';

export type MaterialIconType = OverridableComponent<SvgIconTypeMap> & {
  muiName: string;
};

// This get's around some errors in storybook while keeping the named export the same around our codebase
const Copy = CopyIcon;
// Comes from FilterListOff icon in MUI v5
const FilterListOff = (props) => (
  <SvgIcon {...props}>
    <path d="M10.83 8H21V6H8.83l2 2zm5 5H18v-2h-4.17l2 2zM14 16.83V18h-4v-2h3.17l-3-3H6v-2h2.17l-3-3H3V6h.17L1.39 4.22 2.8 2.81l18.38 18.38-1.41 1.41L14 16.83z" />
  </SvgIcon>
);

const SplitScreen = (props) => (
  <SvgIcon {...props}>
    <path d="M18 4v5H6V4h12m0-2H6c-1.1 0-2 .9-2 2v5c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 13v5H6v-5h12m0-2H6c-1.1 0-2 .9-2 2v5c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-5c0-1.1-.9-2-2-2z" />
  </SvgIcon>
);

const Event = (props) => (
  <SvgIcon {...props}>
    <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
  </SvgIcon>
);

const CalendarToday = (props) => (
  <SvgIcon {...props}>
    <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
  </SvgIcon>
);

// Exported icons
export {
  AccountCircle,
  Add,
  AddAPhoto,
  Apps,
  ArrowBack,
  ArrowBackIos,
  ArrowDropUp,
  ArrowDropDown,
  ArrowForward,
  ArrowForwardIos,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
  Assignment,
  Bookmarks,
  Brush,
  BugReport,
  Build,
  Business,
  CalendarToday,
  Cancel,
  Chat,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Clear,
  Clock,
  Close,
  CloudDone,
  CloudDoneOutlined,
  CloudOff,
  CloudQueue,
  CloudUpload,
  CloudUploadOutlined,
  Code,
  Copy,
  Dashboard,
  DateRange,
  Delete,
  Description,
  DirectionsBoatOutlined,
  DnsIcon,
  DnsOutlinedIcon,
  Done,
  DragHandle,
  DragIndicator,
  DynamicFeedIcon,
  Edit,
  Error,
  Event,
  EventAvailable,
  ExpandLess,
  ExpandMore,
  ExploreIcon,
  ExploreOutlinedIcon,
  FastForwardIcon,
  Forward30,
  Favorite,
  FiberManualRecord,
  FileCopy,
  FilterList,
  FilterAlt,
  FilterListOff,
  FirstPage,
  Folder,
  FolderOpen,
  Fullscreen,
  FullscreenExit,
  GetApp,
  Group,
  HelpIcon,
  HelpOutline,
  Info,
  InfoOutlined,
  Layers,
  LibraryBooks,
  Link,
  List,
  LiveVideo,
  LocationOn,
  Lock,
  LockOpen,
  Map,
  MenuBook,
  MenuIcon,
  Minimize,
  MoreHoriz,
  MoreVert,
  Movie,
  NavigateBefore,
  NavigateNext,
  NetworkCheck,
  OpenInBrowser,
  OpenInNew,
  Pause,
  Person,
  PictureInPictureAlt,
  PlayArrow,
  PlayCircleOutline,
  Public,
  PushPin,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Refresh,
  Replay30,
  Remove,
  RemoveCircle,
  Replay,
  Router,
  Save,
  SaveAs,
  Search,
  Settings,
  SettingsBackupRestore,
  Share,
  ShoppingCartIcon,
  ShowChart,
  SkipNext,
  SkipPrevious,
  SlowMotionVideo,
  SplitScreen,
  SubdirectoryArrowRight,
  TableView,
  TextFields,
  Theaters,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
  Tune,
  Update,
  VerticalAlignBottom,
  Videocam,
  VideocamOff,
  ViewColumn,
  VolumeDown,
  VolumeOff,
  VolumeUp,
  Warning,
  WarningOutlined,
  XmlIcon,
  CsvIcon,
  DownloadIcon,
  // Plotly icons
  ToggleLegendIcon,
  MaterialZoomIcon,
  MaterialSettingsIcon,
  MaterialRemoveIcon,
};
