import { useState } from 'react';
import { Box } from '@mui/material';
import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { Loading } from '@onc/composite-components';
import { ErrorPage, Grid, Typography } from 'base-components';

import {
  CancelButton,
  DeleteButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import DropdownWrapper from 'library/CompositeComponents/form/DropdownWrapper';
import Form from 'library/CompositeComponents/form/Form';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

import Panel from 'library/CompositeComponents/panel/Panel';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import ActionDropdown from '../dropdowns/ActionDropdown';
import CruiseDropdown from '../dropdowns/CruiseDropdown';
import DeviceCategorySelect from '../dropdowns/DeviceCategorySelect';
import DeviceDropdown from '../dropdowns/DeviceDropdown';
import { ModifyBy, ModifyDate } from '../Form/Fields/ReadOnlyFields';
import {
  handleOpenDeviceDetails,
  openDeviceDetails,
} from '../link/DeviceDetailsLink';

type DeviceActionPostProps = {
  deviceId: number;
  actionDescription: string;
  actionCost: number;
  cruise: number;
  actionId: number;
  performedDateTime: string;
};

type DeviceActionPayload = Array<{
  actionCost: number;
  actionId: number;
  actionName: string;
  cruiseId: number;
  cruiseName: string;
  datePerformed: string;
  description: string;
  deviceActionId: number;
  deviceCategoryId: number;
  deviceCategoryName: string;
  deviceId: number;
  deviceName: string;
  engineeringListId: string;
  modifyByName: string;
  modifyDate: string;
  needsCruise: boolean;
}>;

type DevicePayload = {
  devices: {
    device: {
      name: string;
      categoryid: number;
    };
  };
};

type DeviceActionForm = {
  deviceCategory: number;
  device: number;
  actionName: number;
  cruiseName: number;
  performedDate: Moment;
  actionCost: number;
  description: string;
};

type DeviceActionMaintenanceRWProps = {
  deviceActionId?: number;
  deviceId?: number;
};

const DeviceActionMaintenanceRW = ({
  deviceActionId = undefined,
  deviceId = undefined,
}: DeviceActionMaintenanceRWProps) => {
  const classes = {
    entryFormContainer: {
      paddingLeft: 3,
    },
    formButtons: {
      flexDirection: 'row-reverse',
      display: 'flex',
      paddingRight: 2,
    },
  };
  const { onInfo, onError } = useSnackbars();
  const [rendered, setRendered] = useState<boolean>(false);

  const deviceActionData = useGet<
    DeviceActionPayload,
    { deviceActionId: number }
  >(
    'DeviceActionService',
    {
      operation: 4,
      options: {
        enabled: !!deviceActionId,
        retry: 1,
      },
    },
    {
      deviceActionId,
    }
  );

  const deviceData = useGet<
    DevicePayload,
    { deviceid: number; operationtype: number; network: number }
  >(
    'DeviceService',
    {
      operation: 2,
      transform: (response) => {
        const { data } = response;
        if (!data.payload) {
          throw new Error(data);
        }
        return data.payload;
      },
      options: {
        enabled: !!deviceId && !deviceActionId,
        retry: 1,
      },
    },
    {
      deviceid: deviceId,
      operationtype: 2,
      network: -1,
    }
  );

  const disableDeviceDropdowns = !!deviceActionId || !!deviceId;

  let device = deviceId;
  if (!device) {
    device = deviceActionData?.data?.[0]?.deviceId;
  }

  const { mutate: saveDeviceActionData } = usePost<DeviceActionPostProps, any>(
    'DeviceActionService',
    {
      onSuccess: () => {
        onInfo('Device Action saved!');
        openDeviceDetails(device, 'da_tab');
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    1
  );

  const { mutate: updateDeviceActionData } = usePost<
    DeviceActionPostProps,
    any
  >(
    'DeviceActionService',
    {
      onSuccess: () => {
        onInfo('Device Action updated!');
        openDeviceDetails(device, 'da_tab');
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    2
  );

  const { mutate: deleteDeviceActionData } = usePost<
    { deviceActionId: number },
    any
  >(
    'DeviceActionService',
    {
      onSuccess: () => {
        onInfo('Device Action deleted!');
        openDeviceDetails(device, 'da_tab');
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    3
  );

  const formMethods = useForm<DeviceActionForm>({
    defaultValues: {
      deviceCategory: undefined,
      device,
      actionName: undefined,
      cruiseName: undefined,
      performedDate: undefined,
      actionCost: undefined,
      description: undefined,
    },
    mode: 'onChange',
  });

  // Device field should clear on device catergory change
  formMethods.register('device');
  const { onChange: deviceCategoryOnChange } = formMethods.register(
    'deviceCategory',
    { onChange: () => formMethods.resetField('device') }
  );
  const deviceCategoryId = formMethods.watch('deviceCategory');

  if (!deviceId && !deviceActionId) {
    device = formMethods.watch('device');
  }

  if (
    (deviceActionId && deviceActionData.isLoading) ||
    (deviceId && !deviceActionId && deviceData.isLoading)
  ) {
    return <Loading />;
  }

  if (
    (deviceActionId && deviceActionData.error) ||
    (deviceId && !deviceActionId && deviceData.error) ||
    deviceData.data?.devices?.device === null ||
    deviceActionData.data?.length <= 0
  ) {
    return <ErrorPage />;
  }

  const parametersForPostReq = (values: DeviceActionForm) => {
    const params = {
      deviceId: disableDeviceDropdowns ? device : values.device,
      actionDescription:
        values.description === '' ? undefined : values.description,
      actionCost: values.actionCost ? Number(values.actionCost) : undefined,
      cruise: values.cruiseName,
      actionId: values.actionName,
      performedDateTime: values.performedDate.toISOString(),
      ...(!deviceActionId || isNaN(deviceActionId) ? {} : { deviceActionId }),
    };
    return params;
  };

  // Buttons cannot take the mutate from usePost as a param
  const handleDelete = () => {
    deleteDeviceActionData({ deviceActionId });
  };

  const handleSubmit = (values: DeviceActionForm) => {
    /*
     * Manual validation is needed here instead of using Controller's
     * `rules` prop -- I think due to some compatibility errors between
     * Controller and the dropdowns we're using. The error I get is
     * `elm.focus is not a function`.
     */
    if (!disableDeviceDropdowns && !values.deviceCategory) {
      onError('A device category is required!');
    } else if (!disableDeviceDropdowns && !values.device) {
      onError('A device is required!');
    } else {
      const props = parametersForPostReq(values);
      if (!deviceActionId || isNaN(deviceActionId)) {
        saveDeviceActionData(props);
      } else {
        updateDeviceActionData(props);
      }
    }
  };

  if (!rendered) {
    formMethods.reset({
      deviceCategory:
        (deviceActionData?.data?.length > 0
          ? deviceActionData?.data[0].deviceCategoryId
          : undefined) || deviceData?.data?.devices?.device?.categoryid,
      device,
      actionName:
        deviceActionData?.data?.length > 0
          ? deviceActionData?.data[0].actionId
          : undefined,
      cruiseName:
        deviceActionData?.data?.length > 0
          ? deviceActionData?.data[0].cruiseId
          : 0,
      performedDate:
        deviceActionData?.data?.length > 0
          ? moment(deviceActionData?.data[0].datePerformed)
          : moment(),
      actionCost:
        deviceActionData?.data?.length > 0
          ? deviceActionData?.data[0].actionCost
          : undefined,
      description:
        deviceActionData?.data?.length > 0
          ? deviceActionData?.data[0].description
          : undefined,
    });
    setRendered(true);
  }

  return (
    // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
    <div>
      <Panel
        title={
          <>
            <Typography variant="body1">Device Action Entry ID:</Typography>
            <Typography
              variant="body1"
              sx={{
                color: !deviceActionId ? 'text.secondary' : 'text.primary',
              }}
            >
              {!deviceActionId ? 'New' : deviceActionId}
            </Typography>
          </>
        }
      >
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <Grid container direction="row" sx={classes.entryFormContainer}>
            <Grid item xs={6}>
              <DropdownWrapper
                name="deviceCategory"
                title="deviceCategory"
                DropdownComponent={DeviceCategorySelect}
                disabled={disableDeviceDropdowns}
                fullWidth
                onChange={deviceCategoryOnChange}
              />
              <DropdownWrapper
                name="device"
                title="device"
                fullWidth
                DropdownComponent={DeviceDropdown}
                deviceCategoryId={deviceCategoryId}
                disabled={!deviceCategoryId || disableDeviceDropdowns}
              />
              <DropdownWrapper
                name="actionName"
                title="actionName"
                fullWidth
                DropdownComponent={ActionDropdown}
              />
              <DropdownWrapper
                name="cruiseName"
                title="cruiseName"
                fullWidth
                DropdownComponent={CruiseDropdown}
              />
              <FormDateTimePicker
                translationKey="device.performedDate"
                fullWidth
                name="performedDate"
              />
              <FormTextField
                translationKey="device.actionCost"
                type="number"
                name="actionCost"
                fullWidth
              />
              <FormTextField
                translationKey="common.textfields.description"
                name="description"
                fullWidth
                multiline
                minRows={4}
                maxRows={8}
              />
              <ModifyBy
                username={
                  deviceActionData?.data?.length > 0
                    ? deviceActionData?.data[0]?.modifyByName
                    : undefined
                }
              />
              <ModifyDate
                date={
                  deviceActionData?.data?.length > 0
                    ? new Date(
                        deviceActionData?.data[0]?.modifyDate
                      ).toLocaleString()
                    : undefined
                }
              />
            </Grid>
          </Grid>
          <Box sx={classes.formButtons}>
            <SaveButton type="submit" />
            <CancelButton onClick={handleOpenDeviceDetails(device, 'da_tab')} />
            <DeleteButton
              onClick={handleDelete}
              disabled={isNaN(deviceActionId)}
            />
          </Box>
        </Form>
      </Panel>
    </div>
  );
};

export default DeviceActionMaintenanceRW;
