import moment, { Moment } from 'moment';
import { SeamlessVideoFile } from 'library/CompositeComponents/video/SeamlessVideoUtil';
import SeaTubeMediaFiles from 'library/CompositeComponents/video/SeaTubeMediaFiles';
import Environment from 'util/Environment';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

const SERVICE_URL = 'seatube/videos';

export type SeaTubeResolution = {
  code: string;
  description: string;
};

type DataFile = [
  string, // The offset from the startDate the video begins in seconds
  string, // The duration in seconds of the video
  string, // The path to the video file in AD
  string, // The offset from the start of the first video (this only looks at duration of clips and ignores gaps)
  string?, // Some offset in milliseconds.  It's unclear what this is for.
];

export type SeaTubeVideoServicePayload = {
  availableResolutions: SeaTubeResolution[];
  mediaFiles: {
    count: number;
    dataFileDurationSeconds: number;
    dataFiles: DataFile[];
    dateStartSeconds: number;
    defaultFileNamePostFix: string;
    deviceCode: string;
    deviceId: number;
  }[];
  resolution?: string;
  totalDurationSeconds: number;
};

export type SeamlessVideoInfo = {
  availableResolutions: SeaTubeResolution[];
  files: SeamlessVideoFile[];
  startDate: Moment;
  currentQuality: string;
};

type FixedCameraParams = {
  searchTreeNodeId: number;
  resolution: string;
};
class SeaTubeVideoService {
  /**
   * Function that gets all common video resolutions between dives
   *
   * @param {string} diveIds - A comma-separated string of diveIds
   */
  static getCommonResolutions = async (diveIds) =>
    get(SERVICE_URL, {
      operation: 2,
      diveIds,
    }).then((response) => parseDmasAPIResponse(response));

  /**
   * Function that gets all available resolutions for a device / time range
   *
   * @param {number} deviceId - A device id
   * @param {string} dateFrom - The start date of the video
   * @param {string} dateTo - The end date of the video
   */
  static getAvailableResolutions = async (deviceId, dateFrom, dateTo) =>
    get(SERVICE_URL, {
      operation: 3,
      deviceId,
      dateFrom,
      dateTo,
    }).then((response) => parseDmasAPIResponse(response));

  /** Get all media files for the given dive ID */
  static getByDiveId = async (diveId, resolution) =>
    get(SERVICE_URL, {
      diveId,
      resolution,
    }).then((response) =>
      SeaTubeMediaFiles.from(parseDmasAPIResponse(response))
    );

  /** Get all media files for the given dive ID */
  static getSeamlessByDiveId = async (diveId, resolution?) =>
    get(SERVICE_URL, {
      diveId,
      resolution,
    }).then((response) => parseDmasAPIResponse(response));

  /** Get all media files for the given dive ID */
  static getBySearchTreeNodeId = async (searchTreeNodeId, resolution) =>
    get(SERVICE_URL, {
      searchTreeNodeId,
      resolution,
    }).then((response) =>
      SeaTubeMediaFiles.from(parseDmasAPIResponse(response))
    );

  /** Helper functions and parsers */
  static toSeamlessVideoInfo = (
    payload: SeaTubeVideoServicePayload
  ): SeamlessVideoInfo => {
    if (!payload.mediaFiles.length) {
      return {
        availableResolutions: payload.availableResolutions,
        currentQuality: payload.resolution,
        files: [],
        startDate: null,
      };
    }
    const {
      dataFiles,
      deviceCode,
      defaultFileNamePostFix,
      dateStartSeconds,
      deviceId,
    } = payload.mediaFiles[0];
    const startDate = moment.utc(dateStartSeconds * 1000);

    return {
      availableResolutions: payload.availableResolutions,
      currentQuality: payload.resolution,
      files:
        // localOffset is not used in the calculation
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        dataFiles?.map(([offset, duration, path, localOffset, fileMilli]) => {
          const fileName = this.buildFileName(
            deviceCode,
            defaultFileNamePostFix,
            startDate
              .clone()
              .add(parseFloat(offset), 'seconds')
              .add(parseFloat(fileMilli) || 0, 'milliseconds')
          );
          return {
            offset: parseFloat(offset),
            duration: parseFloat(duration),
            fileName,
            path,
            url: this.buildUrl(path, fileName),
            deviceId,
          };
        }) || [],
      startDate,
    };
  };

  static buildFileName = (
    deviceCode: string,
    defaultFileNamePostFix: string,
    dateFrom: Moment
  ) =>
    `${deviceCode}_${dateFrom.toISOString().replace(/[-:]/gi, '')}${defaultFileNamePostFix}`;

  static buildUrl = (path: string, fileName: string) =>
    `${Environment.getMediaBaseUrl()}${path}${fileName}/playlist.m3u8`;
}

export const useFixedCameraVideos = (params: FixedCameraParams) =>
  useGet<SeamlessVideoInfo, FixedCameraParams>(
    SERVICE_URL,
    {
      operation: 1,
      transform: (response) =>
        SeaTubeVideoService.toSeamlessVideoInfo(response.data.payload),
    },
    params
  );

export default SeaTubeVideoService;
