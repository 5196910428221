import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import { FloatRightButtonLowStyles } from 'library/CompositeComponents/button/CommonButtonStyles';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';
import PortService from './PortService';

const styles = {
  button: FloatRightButtonLowStyles,
};

const headers = [
  { title: 'Device Port ID', name: 'devicePortId' },
  { title: 'Device Port Label', name: 'devicePortLabel' },
  { title: 'Device Port Code', name: 'devicePortCode' },
  { title: 'Direction', name: 'direction' },
  { title: 'Power', name: 'power' },
  { title: 'Voltage', name: 'voltage' },
  { title: 'Protocol', name: 'protocol' },
  { title: 'Breaker Number', name: 'breakerNumber' },
  { title: 'Switch Port Number', name: 'switchPortNumber' },
  { title: 'Piggyback In Port', name: 'piggybackInPort' },
  { title: 'Multiple Connection', name: 'multipleConnection' },
  { title: 'Modified Date', name: 'lastModified' },
  { title: 'Modified By', name: 'modifiedBy' },
  { title: 'Device Port ID For Search', name: 'devicePortIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'devicePortId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'devicePortLabel',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'devicePortCode',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'direction',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'power',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'voltage',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'protocol',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'breakerNumber',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'switchPortNumber',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'piggybackInPort',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'multipleConnection',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'lastModified',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'modifiedBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'devicePortIdForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class PortTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      button: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError, deviceId } = this.props;
    this.setState({ isLoading: true });
    PortService.getMany(deviceId)
      .then((result) => {
        this.buildTableRows(result);
      })
      .catch((result) => {
        onError(result);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  actionContent = () => {
    const { deviceId, permission, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const DevicePortRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    if (permission !== 'RW') {
      return <DevicePortRefreshButton />;
    }
    return (
      <>
        <DevicePortRefreshButton />
        <a href={`${Environment.getDmasUrl()}/DevicePort?Deviceid=${deviceId}`}>
          <ContainedButton
            translationKey="device.addDevicePort"
            startIcon={<Add />}
            className={classes.button}
          />
        </a>
      </>
    );
  };

  buildTableRows = (ports) => {
    this.setState({
      tableRows: ports.map((port) => {
        const portFor = {
          ...port,
          devicePortId: this.devicePortLink(port.devicePortId),
          piggybackInPort: port.piggybackInPort ? 'Y' : 'N',
          multipleConnection: port.multipleConnection ? 'Y' : 'N',
          lastModified: DateFormatUtils.formatDate(port.modifyDate, 'full'),
          modifiedBy: port.modifyBy,
          devicePortIdForSearch: port.devicePortId,
        };
        return portFor;
      }),
    });
  };

  comparePortId = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  devicePortLink(portId) {
    const { deviceId } = this.props;
    return (
      <a
        href={`${Environment.getDmasUrl()}/DevicePort?portid=${portId}&Deviceid=${deviceId}`}
      >
        {portId}
      </a>
    );
  }

  render() {
    const { permission } = this.props;
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    return (
      <Panel
        title={<Typography variant="h6">Device Port List</Typography>}
        actionContent={this.actionContent()}
      >
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={20}
          rows={tableRows}
          searchable
          searchBarMoveable={permission === 'RW'}
          sortExtensions={[
            { columnName: 'devicePortId', compare: this.comparePortId },
          ]}
          stripedRows
          hiddenColumns={['devicePortIdForSearch']}
          isLoading={isLoading}
        />
      </Panel>
    );
  }
}

export default withStyles(styles)(PortTable);
