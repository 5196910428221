import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@onc/composite-components';
import SeaTubeVideoService from 'domain/services/SeaTubeVideoService';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Video from 'library/CompositeComponents/video/Video';
import Environment from 'util/Environment';

const TUTORIAL = 'web/tutorials/seatube_intro.mp4/playlist.m3u8';

const withDiveId = (diveId) => (VideoComponent) => {
  class WithDiveId extends PureComponent {
    static propTypes = {
      onError: PropTypes.func.isRequired,
    };

    state = {
      error: false,
    };

    componentDidMount() {
      this.getFiles();
    }

    getFiles = async (resolution) => {
      const { onError } = this.props;

      try {
        const files = await SeaTubeVideoService.getByDiveId(diveId, resolution);
        this.setState({ files });
      } catch (error) {
        onError(error.message);
        this.setState({ error: true });
      }
    };

    handleResolutionChange = (resolution) => {
      this.getFiles(resolution);
    };

    render() {
      const { error, files } = this.state;

      if (error) {
        return (
          <Video
            playerId="tutorial"
            file={`${Environment.getMediaBaseUrl()}/${TUTORIAL}`}
            autoPlay
          />
        );
      }

      if (files) {
        return (
          <VideoComponent
            diveId={diveId}
            files={files}
            onQualityChange={this.handleResolutionChange}
            {...this.props}
          />
        );
      }

      return <Loading size={50} />;
    }
  }

  return withSnackbars(WithDiveId);
};

export default withDiveId;
