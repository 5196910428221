import { parseDmasAPIResponse, post, get } from 'util/WebRequest';
import type { RelatedIdentifier } from '../doi-dataset-landing-page/RelatedIdentifier';

const SERVICE_NAME = 'DOIDatasetService';
class DOIDatasetService {
  static get(params) {
    return get(SERVICE_NAME, {
      doidataset: params.doidataset,
      queryPid: params.queryPid,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getSearchHistoryWithDOI(filter) {
    const params: any = {
      operation: 3,
    };
    if (filter) {
      params.filter = {
        dateTo:
          filter.queryDateFilter.toDate !== ''
            ? filter.queryDateFilter.toDate
            : undefined,
        dateFrom:
          filter.queryDateFilter.fromDate !== ''
            ? filter.queryDateFilter.fromDate
            : undefined,
      };
    }
    return get(SERVICE_NAME, params).then(
      (
        response //
      ) => parseDmasAPIResponse(response) //
    );
  }

  static getDatasetForSiteDevice(siteDeviceId) {
    return get(SERVICE_NAME, {
      operation: 2,
      siteDeviceId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getDatasetForSiteDeviceSubset(siteDeviceSubsetId) {
    return get(SERVICE_NAME, {
      operation: 4,
      siteDeviceSubsetId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static update(doidataset, deprecateMessage) {
    return post(SERVICE_NAME, {
      operation: 2,
      doidataset,
      deprecateMessage,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(params) {
    return post(SERVICE_NAME, {
      operation: 1,
      siteDeviceId: params.siteDeviceId,
      dataciteEnv: params.dataciteEnv,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static resyncDOIWithDatacite(doidataset: string) {
    return post(SERVICE_NAME, {
      operation: 2,
      resyncDOIWithDatacite: true,
      doidataset,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getRelatedIdentifiersForDOIDataset(
    doidataset: string
  ): Promise<RelatedIdentifier[]> {
    return get(SERVICE_NAME, { doidataset, operation: 103 }).then((response) =>
      parseDmasAPIResponse(response)
    );
  }

  static deleteRelatedIdentifier(
    doidataset: string,
    relatedIdentifierId: number
  ): Promise<{ message: string }> {
    return post(SERVICE_NAME, {
      doidataset,
      relatedIdentifierId,
      operation: 101,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static async updateRelatedIdentifier(
    doidataset: string,
    relatedIdentifierType: string,
    relationType: string,
    resourceTypeGeneral: string,
    relatedIdentifier: string,
    relatedIdentifierId: number
  ): Promise<RelatedIdentifier> {
    return post(SERVICE_NAME, {
      operation: 102,
      doidataset,
      relatedIdentifierType,
      relationType,
      resourceTypeGeneral,
      relatedIdentifier,
      relatedIdentifierId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static createRelatedIdentifier(
    doidataset: string,
    relatedIdentifierType: string,
    relationType: string,
    resourceTypeGeneral: string,
    relatedIdentifier: string
  ) {
    return post(SERVICE_NAME, {
      operation: 100,
      doidataset,
      relatedIdentifierType,
      relationType,
      resourceTypeGeneral,
      relatedIdentifier,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default DOIDatasetService;
