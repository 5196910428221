import { useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type TextEditorProps = {
  htmlValue: string;
  toolbarClassName?: string;
  textEditorClassName?: string;
  name?: string;
  setFieldValue?: (name: string, func: () => void) => void;
  editMode?: boolean;
  onSaveButtonClick?: () => void;
  title?: string;
};

const convertHtmlToDraft = (htmlValue) => {
  const contentBlock = htmlToDraft(htmlValue);

  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const editorState = EditorState.createWithContent(contentState);

  return editorState;
};

const convertDraftToHtml = (editorState) =>
  draftToHtml(convertToRaw(editorState.getCurrentContent()));

const TextEditor = ({
  htmlValue,
  toolbarClassName = undefined,
  textEditorClassName = undefined,
  name = undefined,
  setFieldValue = undefined,
  editMode = false,
  onSaveButtonClick = undefined,
  title = undefined,
}: TextEditorProps) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  // Eslint is disabled to mimic the constructor from when this was a class based component. Doing it any other way seems to break it
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setEditorState(convertHtmlToDraft(htmlValue)), []);

  const onEditorStateChange = (editorStateToChange) => {
    setEditorState(editorStateToChange);
    setFieldValue(name, convertDraftToHtml(editorStateToChange));
  };

  return (
    <Editor
      name={name}
      editorState={editorState}
      toolbarClassName={toolbarClassName}
      wrapperClassName="wrapperClassName"
      editorClassName={textEditorClassName}
      onEditorStateChange={onEditorStateChange}
      editMode={editMode}
      onSaveButtonClick={onSaveButtonClick}
      title={title}
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'fontFamily',
          'list',
          'textAlign',
          'colorPicker',
          'history',
          'link',
        ],
        inline: {
          inDropdown: true,
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        list: {
          inDropdown: true,
        },
        textAlign: {
          inDropdown: true,
        },
        blockType: {
          inDropdown: true,
          options: [
            'Normal',
            'H1',
            'H2',
            'H3',
            'H4',
            'H5',
            'H6',
            'Blockquote',
            'Code',
          ],
        },
        fontSize: {
          inDropdown: true,
          options: [
            '8',
            '9',
            '10',
            '11',
            '12',
            '14',
            '16',
            '18',
            '20',
            '24',
            '30',
            '36',
            '48',
            '54',
            '60',
            '66',
            '72',
            '78',
            '84',
            '90',
            '96',
            '102',
          ],
        },
      }}
    />
  );
};

export default TextEditor;
