import DateFilter from 'domain/AppComponents/annotations/filters/DateFilter';
import AttributeFilter from './attribute-filter/AttributeFilter';
import DepthFilter from './DepthFilter';
import ObiscountFilter from './ObisCountFilter';
import ReviewsFilter from './ReviewsFilter';
import TaxonomyFilter from './TaxonomyFilter';

class AnnotationFilters {
  static ATTRIBUTE_FILTER = {
    label: 'Attributes',
    name: 'attributes',
    FilterComponent: AttributeFilter,
  };

  static DATE_FILTER = {
    label: 'Date',
    name: 'date',
    FilterComponent: DateFilter,
  };

  static DEPTH_FILTER = {
    label: 'Depth',
    name: 'depth',
    FilterComponent: DepthFilter,
  };

  static OBIS_FILTER = {
    label: 'OBIS Count',
    name: 'obisCount',
    FilterComponent: ObiscountFilter,
  };

  static REVIEWS_FILTER = {
    label: 'Reviews',
    name: 'reviews',
    FilterComponent: ReviewsFilter,
  };

  static TAXONOMY_FILTER = {
    label: 'Taxonomy',
    name: 'taxonomy',
    FilterComponent: TaxonomyFilter,
  };

  static OPTIONS = [
    AnnotationFilters.ATTRIBUTE_FILTER,
    AnnotationFilters.DATE_FILTER,
    AnnotationFilters.DEPTH_FILTER,
    AnnotationFilters.OBIS_FILTER,
    AnnotationFilters.REVIEWS_FILTER,
    AnnotationFilters.TAXONOMY_FILTER,
  ];
}

export default AnnotationFilters;
