// eslint-disable-next-line import/prefer-default-export
export const ATTRIBUTE_LABEL_CODE = {
  A629: 'InstrumentMin for ',
  A630: 'InstrumentMax for ',
  A712: 'GradientThreshold for ',
  A713: 'SpikeThreshold for ',
  A819: 'HalfWindowPeriods for ',
  A820: 'HampelThreshold for ',
  A956: 'SpeedThreshold for ',
  R629: 'RegionMin for ',
  R630: 'RegionMax for ',
  S700: 'StudyAreaMin for ',
  S701: 'StudyAreaMax for ',
  S702: 'StudyAreaA1 for ',
  S703: 'StudyAreaA2 for ',
  S704: 'StudyAreaA3 for ',
};
