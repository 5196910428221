import React, {
  useState,
  useMemo,
  createContext,
  PropsWithChildren,
  useReducer,
  useContext,
} from 'react';
import widgetConfigMapReducer, {
  WidgetConfigMap,
  type WidgetConfigMapAction,
} from './WidgetConfigMapReducer';

export type DashboardStateContextValue<TDashboardState = any> = {
  dashboardState: TDashboardState;
  setDashboardState: React.Dispatch<React.SetStateAction<TDashboardState>>;
};

const DashboardStateContext = createContext<
  DashboardStateContextValue | undefined
>(undefined);

export type WidgetConfigMapContextValue = {
  widgetConfigMap: WidgetConfigMap;
  dispatch: React.Dispatch<WidgetConfigMapAction>;
};

const WidgetConfigMapContext = createContext<
  WidgetConfigMapContextValue | undefined
>(undefined);

/**
 * Provider component for dashboard state and widget config management
 *
 * @template TDashboardState - Type of dashboard state
 * @param {object} props - The props object
 * @param {TDashboardState} [props.initialState] - Initial dashboard state
 * @param {React.ReactNode} [props.children] - Child components to render
 */
const DashboardStateProvider = <TDashboardState = any,>({
  initialState = {} as TDashboardState,
  children = null,
}: PropsWithChildren<{ initialState?: TDashboardState }>) => {
  const [dashboardState, setDashboardState] =
    useState<TDashboardState>(initialState);

  const [widgetConfigMap, dispatch] = useReducer(
    widgetConfigMapReducer,
    new Map<string, any>()
  );

  const value = useMemo(
    () => ({
      dashboardState,
      setDashboardState,
    }),
    [dashboardState]
  );

  const widgetConfigMapValue = useMemo(
    () => ({
      widgetConfigMap,
      dispatch,
    }),
    [widgetConfigMap]
  );

  return (
    <DashboardStateContext.Provider value={value}>
      <WidgetConfigMapContext.Provider value={widgetConfigMapValue}>
        {children}
      </WidgetConfigMapContext.Provider>
    </DashboardStateContext.Provider>
  );
};

export const useDashboardStateContext = <TDashboardState = any,>() => {
  const context: DashboardStateContextValue<TDashboardState> = useContext(
    DashboardStateContext
  );

  if (!context) {
    throw new Error(
      'Dashboard config hooks must be used within a DashboardStateContext'
    );
  }

  return context;
};

export const useWidgetConfigMapContext = () => {
  const context = useContext(WidgetConfigMapContext);

  if (!context) {
    throw new Error(
      'Dashboard config hooks must be used within a WidgetConfigMapContext'
    );
  }

  return context;
};

export default DashboardStateProvider;
