import { useState, FC, useEffect, useMemo, useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { TreeItem, TreeView } from 'base-components';

const MIN_SEARCH_CHARS = 3;

const useStyles = makeStyles(() =>
  createStyles({
    highlight: {
      fontWeight: 'bold',
      backgroundColor: 'yellow',
    },
  })
);

type Props = {
  filterText: string;
  treeData: Location[];
  setSelectedNodes: (any) => void;
};

type Location = {
  children?: Location[];
  description?: string;
  hasDeviceData?: string;
  hasPropertyData?: string;
  locationCode?: string;
  locationName?: string;
};

const LocationsTreeData: FC<Props> = ({
  filterText,
  treeData,
  setSelectedNodes,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleNodeExpand = (e, nodeIds) => setExpanded(nodeIds);

  // Return a filtered tree, partial matching on location name
  const filterTreeData = useCallback(
    (tree) => {
      const filteredTreeData = [];
      tree.forEach((node) => {
        if (
          node.locationName.toLowerCase().includes(filterText.toLowerCase())
        ) {
          filteredTreeData.push(node);
        }
        // Recursively filter children
        if (node.children) {
          const filteredChildren = filterTreeData(node.children);
          if (filteredChildren.length > 0) {
            filteredTreeData.push({ ...node, children: filteredChildren });
          }
        }
      });
      return filteredTreeData;
    },
    [filterText]
  );

  const filteredTree = useMemo(() => {
    if (filterText?.length >= MIN_SEARCH_CHARS) {
      return filterTreeData(treeData);
    }
    return treeData;
  }, [filterText, treeData, filterTreeData]);

  // Expand all matched nodes after search
  useEffect(() => {
    const expandNodes = (nodes, searchText, expandedNodes, index = '') => {
      nodes.forEach((node, i) => {
        const nodeIndex = index + i;
        if (
          node.locationName.toLowerCase().includes(searchText.toLowerCase())
        ) {
          // Add node ancestors to be expanded
          let ancestorIndex = '';
          for (let j = 0; j < index.length; j += 2) {
            ancestorIndex += index[j];
            expandedNodes.push(ancestorIndex);
            ancestorIndex += '-';
          }
          expandedNodes.push(nodeIndex);
        }
        if (node.children && node.children.length > 0) {
          expandNodes(
            node.children,
            searchText,
            expandedNodes,
            `${nodeIndex}-`
          );
        }
      });
      return expandedNodes;
    };

    if (filterText?.length >= MIN_SEARCH_CHARS) {
      const newExpandedNodes = expandNodes(filteredTree, filterText, []);
      setExpanded(newExpandedNodes);
    } else {
      setExpanded([]);
    }
  }, [filterText, filteredTree]);

  const handleNodeSelect = (e, nodeId) => {
    const indices = nodeId.split('-').map(Number);
    let locationNode: Location[] | Location = filteredTree;
    // Iterate through location children to reach the selected node
    for (let i = 0; i < indices.length; i += 1) {
      const index = indices[i];
      const isLastIndex = i === indices.length - 1;
      // If it's the last index (final location), return it
      if (isLastIndex) {
        locationNode = locationNode[index];
        break;
      }
      if (locationNode[index]?.children) {
        locationNode = locationNode[index].children;
      }
    }
    setSelectedNodes(locationNode);
  };

  const renderItems = (data, nodeIdFn = (index) => `${index}`) => {
    if (!data) return null;
    return data.map((node, index) => {
      let label = node.locationName;
      if (filterText) {
        const matchIndex = label
          .toLowerCase()
          .indexOf(filterText.toLowerCase());
        // Highlights matching text
        if (matchIndex !== -1) {
          label = (
            <>
              {label.substring(0, matchIndex)}
              <span className={classes.highlight}>
                {label.substring(matchIndex, matchIndex + filterText.length)}
              </span>
              {label.substring(matchIndex + filterText.length)}
            </>
          );
        }
      }
      return (
        <TreeItem
          TransitionProps={{
            timeout: 0,
          }}
          key={nodeIdFn(index)}
          nodeId={nodeIdFn(index)}
          label={label}
        >
          {node.children &&
            renderItems(
              node.children,
              (childIndex) => `${nodeIdFn(index)}-${childIndex}`
            )}
        </TreeItem>
      );
    });
  };

  return (
    <TreeView
      expanded={expanded}
      onNodeToggle={handleNodeExpand}
      onNodeSelect={handleNodeSelect}
    >
      {renderItems(filteredTree)}
    </TreeView>
  );
};

export default LocationsTreeData;
