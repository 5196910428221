import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { DenseThemeProvider } from '@onc/theme';
import BatchContainer from 'domain/AppComponents/batch/batch-table/BatchContainer';
import BatchTaskDialogProvider from 'domain/AppComponents/batch/providers/BatchTaskDialogProvider';
import BatchTaskTypeProvider from 'domain/AppComponents/batch/providers/BatchTaskTypeProvider';

interface BatchContainerProps {
  taskType?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { padding: theme.spacing(2) },
  })
);

const BatchManagement = ({ taskType = 'BATCH_TASK' }: BatchContainerProps) => {
  const classes = useStyles();

  return (
    <BatchTaskTypeProvider taskType={taskType}>
      <BatchTaskDialogProvider>
        <DenseThemeProvider>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore DenseThemeProvider expects specific typing in strict, but seems to work without it */}
          <div className={classes.root}>
            <BatchContainer />
          </div>
        </DenseThemeProvider>
      </BatchTaskDialogProvider>
    </BatchTaskTypeProvider>
  );
};

export default BatchManagement;
