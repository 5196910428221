import { FormattedTask } from 'domain/AppComponents/batch/types/FormattedTask';
import DateUtils from 'util/DateUtils';

const getBatchRows: (records?: FormattedTask[]) => any[] = (records = []) =>
  records.map((row) => ({
    id: row.task.taskId,
    taskId: row.task.taskId,
    batchName: row.taskInput ? row.taskInput.batchName : 'N/A',
    reason: row.taskInput ? row.taskInput.reason : 'N/A',
    dateStarted: DateUtils.formatDateAsString(
      new Date(row.task.dateStarted ?? '')
    ),
    dateCompleted: DateUtils.formatDateAsString(
      new Date(row.task.dateCompleted ?? '')
    ),
    taskType: row.taskType,
    errorMessage: row.errorMessage,
    input: row.taskInput,
    status: row.task.status,
    shouldPage: row.shouldPage,
  }));

export default getBatchRows;
