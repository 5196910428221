import { SvgIconProps } from '@mui/material';
import {
  Add,
  PlayArrow,
  Replay,
  SkipNext,
  SkipPrevious,
  Code as EmbedUrlIcon,
  Copy as CopyIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  FilterList as FilterListIcon,
  InfoOutlined,
  List as ListIcon,
  Map as MapIcon,
  MenuBook,
  PlayCircleOutline as PlayIcon,
  Refresh as RefreshIcon,
  Remove as RemoveIcon,
  Search as SearchIcon,
  Link,
} from '@onc/icons';
import { IconButton, OncIconButtonProps } from 'base-components';

type Props = Partial<Omit<OncIconButtonProps, 'children'>> & {
  iconProps?: SvgIconProps;
};

interface ExternalLinkIconButtonProps extends Props {
  link: string;
  tooltipTitle?: string;
  icon: React.ElementType;
  linkProps?: any;
  href?: string;
  target?: string;
}

const ExternalLinkIconButton = ({
  link,
  tooltipTitle = '',
  icon: Icon,
  iconProps = undefined,
  ...otherProps
}: ExternalLinkIconButtonProps) => (
  <a href={link} target="_blank" rel="noreferrer" aria-label={tooltipTitle}>
    <IconButton {...otherProps} color="primary" aria-label={tooltipTitle}>
      <Icon {...iconProps} />
    </IconButton>
  </a>
);

export { ExternalLinkIconButton };

// These used to exist in a different IconButtons folder. Added them here instead to maintain 1 file

const make = (
  props: Props,
  Icon: React.ComponentType,
  defaultAriaLabel?: string
) => (
  <IconButton aria-label={defaultAriaLabel || props['aria-label']} {...props}>
    <Icon {...props.iconProps} />
  </IconButton>
);

export const AddIconButton = (props: Props) => make(props, Add, 'Add');
export const CancelIconButton = (props: Props) =>
  make(props, CancelIcon, 'Cancel');
export const CloneIconButton = (props: Props) => make(props, CopyIcon, 'Clone');
export const CopyLinkIconButton = (props: Props) =>
  make(props, Link, 'Copy Link to Clipboard');
export const DeleteIconButton = (props: Props) =>
  make(props, DeleteIcon, 'Delete');
export const EditIconButton = (props: Props) => make(props, EditIcon, 'Edit');
export const EmbedUrlIconButton = (props: Props) =>
  make(props, EmbedUrlIcon, 'Embed');
export const GeospatialAreaIconButton = (props: Props) =>
  make(props, MapIcon, 'Define Geospatial Area');
export const InfoIconButton = (props: Props) =>
  make(props, InfoOutlined, 'Info');
export const ListIconButton = (props: Props) => make(props, ListIcon, 'List');
export const NextIconButton = (props: Props) => make(props, SkipNext, 'Next');
export const OpenDeckLogIconButton = (props: Props) =>
  make(props, MenuBook, 'Open Deck Log');
export const PlayArrowIconButton = (props: Props) =>
  make(props, PlayArrow, 'Play');
export const PlayIconButton = (props: Props) => make(props, PlayIcon, 'Play');
export const PreviousIconButton = (props: Props) =>
  make(props, SkipPrevious, 'Previous');
export const RefreshButton = (props: Props) =>
  make(props, RefreshIcon, 'Refresh');
export const RemoveIconButton = (props: Props) =>
  make(props, RemoveIcon, 'Remove');
export const ReplayIconButton = (props: Props) => make(props, Replay, 'Replay');
export const ShowFilterButton = (props: Props) =>
  make(props, FilterListIcon, 'Show Filter');

export const SearchIconButton = (props: Props) =>
  make(props, SearchIcon, 'Search');
