import * as React from 'react';
import { Box } from '@mui/material';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  Chat,
  DateRange,
  DirectionsBoatOutlined,
  LocationOn,
  Person,
  Business,
  type MaterialIconType,
} from '@onc/icons';
import { Typography } from 'base-components';
import { PublicCruiseJSON } from 'domain/services/CruiseService';
import { DiveJSON } from 'domain/services/DiveListingService';
import OrganizationLogo from 'library/CompositeComponents/logo/OrganizationLogo';

interface Props extends WithStyles<typeof styles> {
  cruise: PublicCruiseJSON;
  dive?: DiveJSON;
}

interface LineProps extends WithStyles<typeof styles> {
  Icon: MaterialIconType;
  id: string;
  label: string;
  value: string;
}

const styles = () =>
  createStyles({
    details: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
    },
    layoutIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    layoutLogo: {
      height: '50px',
      position: 'relative',
    },
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      objectFit: 'contain',
    },
    layoutText: {
      display: 'flex',
      flexDirection: 'column',
      wordBreak: 'break-word',
      marginLeft: '5px',
      '& p': {
        lineHeight: '1.0',
      },
    },
  });

const LiveExpeditionDetailLine: React.VFC<LineProps> = ({
  Icon,
  id,
  label,
  value,
  classes,
}: LineProps) =>
  value ? (
    <div className={classes.layoutIcon}>
      <Icon color="action" />
      <div className={classes.layoutText}>
        <Typography
          id={`lee-${id}-label`}
          variant="caption"
          color="textSecondary"
        >
          {label}
        </Typography>
        <Typography id={`lee-${id}-data`} variant="body1" color="textPrimary">
          <Box whiteSpace="pre-line">{value}</Box>
        </Typography>
      </div>
    </div>
  ) : (
    <></>
  );

const LiveExpeditionDetails: React.VFC<Props> = ({
  cruise,
  dive = null,
  classes,
}: Props) => {
  const renderDiveDetails = () => (
    <div className={classes.details}>
      <div className={classes.layoutLogo}>
        <OrganizationLogo id={cruise.organizationId} className={classes.img} />
      </div>
      <LiveExpeditionDetailLine
        Icon={Business}
        id="dive-organization"
        label="Organization:"
        value={cruise.organizationName}
        classes={classes}
      />
      <LiveExpeditionDetailLine
        Icon={DirectionsBoatOutlined}
        id="dive-name"
        label="Dive Name:"
        value={dive.referenceDiveId}
        classes={classes}
      />
      <LiveExpeditionDetailLine
        Icon={Person}
        id="dive-lead"
        label="Dive Lead:"
        value={dive.scientistName}
        classes={classes}
      />
      <LiveExpeditionDetailLine
        Icon={LocationOn}
        id="dive-area"
        label="Area:"
        value={dive.area}
        classes={classes}
      />

      <LiveExpeditionDetailLine
        Icon={Chat}
        id="dive-description"
        label="Description:"
        value={dive.diveComment}
        classes={classes}
      />
    </div>
  );

  const renderExpeditionDetails = () => (
    <div className={classes.details}>
      <div className={classes.layoutLogo}>
        <OrganizationLogo id={cruise.organizationId} className={classes.img} />
      </div>
      <LiveExpeditionDetailLine
        Icon={Business}
        id="cruise-organization"
        label="Organization:"
        value={cruise.organizationName}
        classes={classes}
      />
      <LiveExpeditionDetailLine
        Icon={DirectionsBoatOutlined}
        id="cruise-name"
        label="Expedition Name:"
        value={cruise.cruiseName}
        classes={classes}
      />
      <LiveExpeditionDetailLine
        Icon={DateRange}
        id="cruise-date-started"
        label="Date Started:"
        value={cruise.startDate}
        classes={classes}
      />
      <LiveExpeditionDetailLine
        Icon={DateRange}
        id="cruise-date-ended"
        label="Date Ended:"
        value={cruise.endDate}
        classes={classes}
      />
      <LiveExpeditionDetailLine
        Icon={Chat}
        id="cruise-description"
        label="Description:"
        value={cruise.cruiseDescription}
        classes={classes}
      />
    </div>
  );

  return dive ? renderDiveDetails() : renderExpeditionDetails();
};

export default withStyles(styles)(LiveExpeditionDetails);
