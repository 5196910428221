import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import { Station } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';

class QaqcDetailsStationDropdown extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    permission: PropTypes.string.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    stations: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getStations();
  }

  getStation = () => {
    const { value } = this.props;
    const { stations } = this.state;
    const currStation = stations.find((station) => station.value === value);

    if (!currStation || value === 0) return '';
    return currStation.label;
  };

  getStations = () => {
    QaqcTestDetailsService.getQAQCTDStation()
      .then((results) => {
        const stations = results.map((station) => ({
          label: station.searchTreeNodeName,
          value: station.searchTreeNodeId,
        }));
        this.setState({
          stations: [{ label: 'Select', value: 0 }, ...stations],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const {
      classes,
      disabled,
      initialValue,
      permission,
      value,
      onChange,
      ...rest
    } = this.props;
    const { stations } = this.state;

    if (permission === 'RW') {
      return (
        <Dropdown
          disabled={disabled}
          initialValue={initialValue}
          label="Station"
          options={stations}
          value={value}
          onChange={onChange}
          {...rest}
        />
      );
    }

    return (
      <Station
        classes={{ root: classes.roFieldRoot }}
        station={this.getStation()}
      />
    );
  }
}
export default QaqcDetailsStationDropdown;
