/**
 * Provides the fields for date from and date to for the
 * AgreementSiteDevicesTable and EditAgreementSiteDeviceDialog, as per the
 * variant prop and the licence value for AgreementSiteDevicesTable.
 *
 * When the date from or date to for the agreement site device are null, they
 * are then derived from the site device the agreement is associated with. This
 * is identified for the user in the UI by being italicized and surrounded with
 * a tooltip that provides context.
 *
 * A similar tooltip is provided for the licence field where the agreement site
 * devices licence is null and the licence derived from the agreement is used.
 */
import { ForwardedRef, ReactNode, useEffect, useState } from 'react';
import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { ReadOnlyTextField, Tooltip, Typography } from 'base-components';
import { Licence } from 'domain/services/AgreementSiteDeviceService';
import DateFormatUtils from 'util/DateFormatUtils';

const useStyles = makeStyles(() =>
  createStyles({
    italicText: {
      fontStyle: 'italic',
    },
    tableCellText: {
      fontSize: '0.875rem',
    },
  })
);

type AgreementSiteDeviceDateProps = {
  variant: 'table' | 'form';
  label?: string;
  date?: Date;
  siteDeviceDate?: Date;
};

type DerivedTooltipProps = {
  children: ReactNode;
  title: string;
};

const DerivedTooltip = React.forwardRef(
  (
    { children, title }: DerivedTooltipProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Tooltip title={title}>
      <div ref={ref}>{children}</div>
    </Tooltip>
  )
);

const FormDate = ({
  label,
  text,
  className,
}: {
  label: string;
  text: string;
  className: string;
}) => (
  <ReadOnlyTextField label={label} value={text} inputClassName={className} />
);

const TableDate = ({
  text,
  className,
}: {
  text: string;
  className: string;
}) => <Typography className={className}>{text}</Typography>;

export const AgreementSiteDeviceDate = ({
  variant,
  label = '',
  date = undefined,
  siteDeviceDate = undefined,
}: AgreementSiteDeviceDateProps) => {
  const classes = useStyles();

  const [text, setText] = useState<string>();
  const [className, setClassName] = useState<string>('');

  useEffect(() => {
    let additionalClasses = variant === 'table' ? [classes.tableCellText] : [];

    if (date) {
      setText(DateFormatUtils.formatDate(date, 'full'));
    } else {
      additionalClasses = [...additionalClasses, classes.italicText];
      if (siteDeviceDate) {
        setText(DateFormatUtils.formatDate(siteDeviceDate, 'full'));
      } else {
        setText('Now');
      }
    }

    setClassName(additionalClasses.join(' '));
  }, [date, siteDeviceDate, variant, setClassName, setText, classes]);

  const DisplayComponent =
    variant === 'form' ? (
      <FormDate label={label} text={text} className={className} />
    ) : (
      <TableDate text={text} className={className} />
    );

  return date ? (
    DisplayComponent
  ) : (
    <DerivedTooltip title="Derived from Site Device">
      {DisplayComponent}
    </DerivedTooltip>
  );
};

type AgreementSiteDeviceLicenceProps = {
  licence?: Licence;
  agreementLicence: Licence;
};

const formatLicence = (l: Licence) => `${l.licenceAbbreviation}`;

export const AgreementSiteDeviceLicence = ({
  licence = undefined,
  agreementLicence,
}: AgreementSiteDeviceLicenceProps) => {
  const classes = useStyles();

  const [text, setText] = useState<string>();
  const [className, setClassName] = useState<string>('');

  useEffect(() => {
    let additionalClasses = [classes.tableCellText];

    if (licence) {
      setText(formatLicence(licence));
    } else {
      additionalClasses = [...additionalClasses, classes.italicText];
      setText(formatLicence(agreementLicence));
    }

    setClassName(additionalClasses.join(' '));
  }, [licence, agreementLicence, setClassName, setText, classes]);

  const DisplayComponent = (
    <Typography className={className}>{text}</Typography>
  );

  return licence ? (
    DisplayComponent
  ) : (
    <DerivedTooltip title="Derived from Organization Agreement">
      {DisplayComponent}
    </DerivedTooltip>
  );
};
