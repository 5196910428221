import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Warning } from '@onc/icons';
import { Tooltip, Typography } from 'base-components';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';

import TreeMenuItem from 'library/CompositeComponents/tree-menu/TreeMenuItem';
import TreeActionContent from './TreeActionContent';

const styles = (theme) => ({
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: '1.25em',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: theme.spacing(),
  },
});

const DiveMenuItem = ({
  item,
  path,
  expeditionId,
  onMenuItemClicked,
  onSearchButtonClicked,
  onWatchButtonClicked,
  selectedNodeId,
  classes,
}) => {
  const newPath = [...path, { diveId: item.id }];

  return (
    <TreeMenuItem
      onClick={() => onMenuItemClicked(newPath, item.nodeId)}
      key={item.id}
      label={
        <div style={{ display: 'flex' }}>
          <TreeActionContent
            onWatchButtonClicked={onWatchButtonClicked}
            onSearchButtonClicked={onSearchButtonClicked}
            resourceTypeId={SeaTubeResourceTypes.DIVE}
            resourceId={item.id}
            expeditionId={expeditionId}
          />
          {item.ready ? null : (
            <Tooltip title="Dive is not SeaTube ready">
              <Warning className={classes.warningIcon} />
            </Tooltip>
          )}
          <Typography variant="body1" style={{ margin: 'auto 0' }}>
            {item.title}
          </Typography>
        </div>
      }
      selected={selectedNodeId === item.nodeId}
    />
  );
};

DiveMenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    nodeId: PropTypes.number,
    ready: PropTypes.bool,
  }).isRequired,
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  expeditionId: PropTypes.number.isRequired,
  onMenuItemClicked: PropTypes.func.isRequired,
  onSearchButtonClicked: PropTypes.func.isRequired,
  onWatchButtonClicked: PropTypes.func.isRequired,
  selectedNodeId: PropTypes.number.isRequired,
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(DiveMenuItem);
