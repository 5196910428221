import { forwardRef } from 'react';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';

/**
 * This is a utility class that allows older react-router versions to be
 * compatible with MUI V5's component
 */
const RouterLink = forwardRef<any, RouterLinkProps>((props, ref) => (
  <Link innerRef={ref} to={props.to} {...props} role={undefined} />
));

export default RouterLink;
