/* eslint-disable no-underscore-dangle */
/* Ignoring underscores because we are overriding Leaflet things that use them. */
import { useState, FC, useContext } from 'react';

import { Fullscreen, FullscreenExit } from '@onc/icons';
import { ControlPosition } from 'leaflet';
import { useMap } from 'react-leaflet';

import Control from './Control';
import MapFAB from './MapFAB';

import 'leaflet-fullscreen';
import { MaterialControlProps } from './MaterialControlProps';
import MapContext from '../MapContext';

interface FullscreenControlProps extends MaterialControlProps {
  /** Callback to use when Fullscreen is entered or exited */
  onFullscreenChange?: (isFullscreen: any) => void;
}
const FullscreenControl: FC<FullscreenControlProps> = ({
  options = { position: 'topleft' as ControlPosition },
  onFullscreenChange = undefined,
  prepend = false,
  containerId = undefined,
  size = undefined,
  ...rest
}) => {
  const [isFullscreen, setFullscreen] = useState(false);
  const { mapId } = useContext(MapContext);
  const map = useMap();

  const handleFullscreenClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    map.toggleFullscreen();
    setFullscreen(!isFullscreen);
    onFullscreenChange(isFullscreen);
  };

  return (
    <Control position={options.position} prepend={prepend}>
      <MapFAB
        TooltipProps={{
          title: isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen',
        }}
        onClick={handleFullscreenClick}
        map={map}
        mapId={mapId}
        idTag="fullscreen-control"
        size={size}
        containerId={containerId}
        {...rest}
      >
        {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
      </MapFAB>
    </Control>
  );
};

export default FullscreenControl;
