type SeekbarTooltipProps = {
  cursorVal: string;
  height: number;
  position: number;
};

// TODO: Change styles to sx so that they respect theming
const SeekbarTooltip = ({
  cursorVal,
  height,
  position,
}: SeekbarTooltipProps) => (
  <span
    style={{
      position: 'absolute',
      left: `${position}px`,
      cursor: 'pointer',
      width: 'auto',
      margin: '-88px',
      bottom: `${height + 150}px`,
      zIndex: '999',
      pointerEvents: 'none',
    }}
  >
    <div
      style={{
        backgroundColor: 'white',
        height: '35px',
        width: '180px',
        textAlign: 'center',
        padding: '11px',
        fontSize: '15px',
      }}
    >
      {cursorVal}
    </div>
    <div
      style={{
        width: '0',
        height: '0',
        marginLeft: '85px',
      }}
    />
    <div
      style={{
        width: '1px',
        background: 'black',
        height: `${height + 61}px`,
        position: 'absolute',
        left: '50%',
      }}
    />
  </span>
);

export default SeekbarTooltip;
