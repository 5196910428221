import { Component } from 'react';
import { Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid, Link, TableOld as Table, Typography } from 'base-components';
import {
  RelatedIdentifiersTable,
  RelatedIdentifiersTableAdmin,
} from 'domain/AppComponents/doi-dataset-landing-page/RelatedIdentifiersTable';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import { READ_WRITE } from 'util/PermissionsUtils';

const CONTRIBUTORS_NAME = 'Contributors';
const GEOLOCATIONS_NAME = 'Geolocations';
const FUNDING_NAME = 'Funding References';
const CREATORS_NAME = 'Creators';
const RELATED_IDENTIFIER_NAME = 'Related Identifiers';
const KEYS = [
  'Title',
  'DOI',
  'Abstract',
  CREATORS_NAME,
  FUNDING_NAME,
  'Publisher',
  'Publication Year',
  'Resource Type',
  'Rights',
  'Formats',
  GEOLOCATIONS_NAME,
  CONTRIBUTORS_NAME,
  RELATED_IDENTIFIER_NAME,
];
const NO_FORMATS_AVAILABLE = 'No formats available';

const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  keyStyle: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    color: 'rgba(0, 0, 0, 0.54)',
    transformOrigin: 'top left',
    marginTop: theme.spacing(2),
  },
  indentLevel: {
    paddingRight: theme.spacing(2),
  },
});

class DOIDataFields extends Component {
  static propTypes = {
    doidata: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
      ])
    ),
    organizations: PropTypes.objectOf(PropTypes.string),
    classes: PropTypes.shape({
      keyStyle: PropTypes.string,
      indentLevel: PropTypes.string,
      root: PropTypes.string,
      headingStyle: PropTypes.string,
      addIdentifierButtonStyle: PropTypes.string,
    }),
    onError: PropTypes.func,
  };

  static defaultProps = {
    doidata: undefined,
    organizations: undefined,
    classes: undefined,
    onError: () => {},
  };

  /**
   * Round to 6 decimal places. Number.EPSILON fixes floating point shenanigans
   *
   * @param coordinate Number to be rounded
   * @returns Number rounded to 6 decimal places
   */
  roundGeoLocation = (coordinate) =>
    `${Math.round((coordinate + Number.EPSILON) * 1000000) / 1000000}`;

  generateDateRowsFromJson = (dates) =>
    dates.map((date) => ({
      key: `Date ${date.dateType}`,
      value: date.content,
    }));

  renderUndefined = (key, value, classes) => (
    <Grid
      key={key}
      className={classes.indentLevel}
      container
      direction="column"
      name={key}
    >
      <MarginedReadOnlyField
        labelText={key}
        valueText="undefined"
        title={key}
      />
    </Grid>
  );

  generateFormatString = (formats) => {
    if (!formats) return NO_FORMATS_AVAILABLE;
    const { format } = formats;
    return Array.isArray(format) ? format.join(' ') : format;
  };

  renderMarginedReadOnlyFields = (key, value, classes) => (
    <Grid
      key={key}
      className={classes.indentLevel}
      container
      direction="column"
      name={key}
    >
      <MarginedReadOnlyField labelText={key} valueText={value} title={key} />
    </Grid>
  );

  generateCreatorsObject = (creators) =>
    // Only a single creator exists if creators is not an array.
    Array.isArray(creators)
      ? creators.map((creator) => ({
          type: creator.creatorName.nameType,
          value: this.checkForOrganizationRORId(creator.creatorName.content),
        }))
      : {
          type: creators.creatorName.nameType,
          value: this.checkForOrganizationRORId(creators.creatorName.content),
        };

  generateContributorsObjects = (contributors) =>
    // Only a single contributor exists if it is not an array.
    Array.isArray(contributors)
      ? contributors.map((contributor) => ({
          type: contributor.contributorType,
          value: this.checkForOrganizationRORId(
            contributor.contributorName.content
          ),
        }))
      : {
          type: contributors.contributorType,
          value: this.checkForOrganizationRORId(
            contributors.contributorName.content
          ),
        };

  generateGeolocationObjects = (geoLocations) => {
    // Only a single geoLocations exists if it is not an array
    if (Array.isArray(geoLocations)) {
      return [].concat(
        ...geoLocations.map((geoLocation) =>
          this.parseGeoLocationsFromArray(geoLocation)
        )
      );
    }
    if (Object.keys(geoLocations)[0] === 'geoLocationBox') {
      return this.parseOutGeoLocationBox(geoLocations.geoLocationBox);
    }
    return {
      type: Object.keys(geoLocations)[0],
      value: this.parseOutPointsFromGeolocation(
        geoLocations[Object.keys(geoLocations)[0]]
      ),
    };
  };

  parseGeoLocationsFromArray = (geoLocation) => {
    if (Object.keys(geoLocation)[0] === 'geoLocationBox') {
      return this.parseOutGeoLocationBox(
        geoLocation[Object.keys(geoLocation)[0]]
      );
    }
    return {
      type: Object.keys(geoLocation)[0],
      value: this.parseOutPointsFromGeolocation(
        geoLocation[Object.keys(geoLocation)[0]]
      ),
    };
  };

  parseOutGeoLocationBox = (geoLocationBox) => [
    {
      type: 'North Bounding Latitude',
      value: this.roundGeoLocation(geoLocationBox.northBoundLatitude),
    },
    {
      type: 'South Bounding Latitude',
      value: this.roundGeoLocation(geoLocationBox.southBoundLatitude),
    },
    {
      type: 'East Bounding Longitude',
      value: this.roundGeoLocation(geoLocationBox.eastBoundLongitude),
    },
    {
      type: 'West Bounding Longitude',
      value: this.roundGeoLocation(geoLocationBox.westBoundLongitude),
    },
  ];

  parseOutPointsFromGeolocation = (geoLocation) =>
    Array.isArray(geoLocation.polygonPoint)
      ? geoLocation.polygonPoint
          .map(
            (point) =>
              `(${this.roundGeoLocation(
                point.pointLatitude
              )}, ${this.roundGeoLocation(point.pointLongitude)})`
          )
          .join(', ')
      : `(${this.roundGeoLocation(
          geoLocation.pointLatitude
        )}, ${this.roundGeoLocation(geoLocation.pointLongitude)})`;

  generateFundingReferences = (fundingReferences) => {
    if (fundingReferences == null) {
      return {
        type: 'Funding Reference',
        value: 'No funder',
      };
    }
    if (Array.isArray(fundingReferences.fundingReference)) {
      return fundingReferences.fundingReference.map((fundingRef) => ({
        type: 'Funding Reference',
        value: this.checkForOrganizationRORId(fundingRef.funderName),
      }));
    }
    if (
      !fundingReferences.fundingReference ||
      !fundingReferences.fundingReference.funderName
    ) {
      return {
        type: 'Funding Reference',
        value: 'No funder',
      };
    }
    return {
      type: 'Funding Reference',
      value: this.checkForOrganizationRORId(
        fundingReferences.fundingReference.funderName
      ),
    };
  };

  renderItem = (key, value) => {
    const { classes } = this.props;
    if (
      key === FUNDING_NAME ||
      key === CREATORS_NAME ||
      key === CONTRIBUTORS_NAME ||
      key === GEOLOCATIONS_NAME
    ) {
      const convertedValue = Array.isArray(value) ? value : [value];
      return (
        <Grid
          key={`${key} - ${value}`}
          item
          xs={12}
          className={classes.indentLevel}
          name={key}
        >
          <Typography align="left" className={classes.keyStyle}>
            {key}
          </Typography>
          <Divider />
          <Table
            rows={convertedValue.map((row) => ({
              type: row.type,
              value: row.value,
            }))}
            columns={[
              { name: 'type', title: 'type' },
              { name: 'value', title: 'value' },
            ]}
            showHeaderRow={false}
            elevation={0}
          />
        </Grid>
      );
    }
    if (key === RELATED_IDENTIFIER_NAME) {
      const { doidata, onError } = this.props;
      return (
        <Grid
          key={`${key} - ${value}`}
          item
          xs={12}
          className={classes.indentLevel}
          name={key}
        >
          <Typography align="left" className={classes.keyStyle}>
            {key}
          </Typography>
          {Environment.getDmasUserPrivilege() === READ_WRITE ? (
            <RelatedIdentifiersTableAdmin
              doidataset={doidata.identifier.content}
              onError={onError}
            />
          ) : (
            <RelatedIdentifiersTable
              relatedIdentifiers={
                doidata?.relatedIdentifiers?.relatedIdentifier
                  ? doidata.relatedIdentifiers.relatedIdentifier
                  : []
              }
            />
          )}
        </Grid>
      );
    }
    if (typeof value !== 'undefined') {
      return this.renderMarginedReadOnlyFields(key, value, classes);
    }
    return this.renderUndefined(key, value, classes);
  };

  generateRows = (doidata) => {
    let rows = [
      {
        key: KEYS[0],
        value: doidata.titles.title,
      },
      {
        key: KEYS[1],
        value: doidata.identifier.content,
      },
      {
        key: KEYS[2],
        value: doidata.descriptions.description.content,
      },
      {
        key: KEYS[3],
        value: this.generateCreatorsObject(doidata.creators.creator),
      },
    ];

    rows = rows.concat(this.generateDateRowsFromJson(doidata.dates.date));
    rows.push(
      {
        key: KEYS[4],
        value: this.generateFundingReferences(doidata.fundingReferences),
      },
      {
        key: KEYS[5],
        value: this.checkForOrganizationRORId(doidata.publisher),
      },
      {
        key: KEYS[6],
        value: doidata.publicationYear,
      },
      {
        key: KEYS[7],
        value: doidata.resourceType.content,
      },
      {
        key: KEYS[8],
        value: doidata.rightsList.rights,
      },
      {
        key: KEYS[9],
        value: this.generateFormatString(doidata.formats),
      },
      {
        key: KEYS[10],
        value: this.generateGeolocationObjects(
          doidata.geoLocations.geoLocation
        ),
      },
      {
        key: KEYS[11],
        value: this.generateContributorsObjects(
          doidata.contributors.contributor
        ),
      },
      {
        key: KEYS[12],
        value: doidata.relatedIdentifiers
          ? doidata.relatedIdentifiers.relatedIdentifier
          : [],
      }
    );
    return rows;
  };

  checkForOrganizationRORId(organization) {
    const { organizations } = this.props;
    if (organizations[organization]) {
      return <Link href={organizations[organization]}>{organization}</Link>;
    }
    return organization;
  }

  render() {
    const { classes, doidata } = this.props;
    if (doidata) {
      const rows = this.generateRows(doidata).map((row) =>
        this.renderItem(row.key, row.value)
      );
      return (
        <>
          <Typography variant="h6">DataCite Metadata</Typography>
          <div className={classes.root}>{rows}</div>
        </>
      );
    }
    return null;
  }
}
export default withStyles(styles)(withSnackbars(DOIDataFields));
