import moment from 'moment';
import DataViewerDashboardConfig, {
  type DataViewerDashboardConfigType,
} from 'domain/Apps/data-viewer/config/DataViewerDashboardConfig';
import TimeSeriesScalarDataChartWidget from 'domain/Widgets/TimeSeriesScalarDataChartWidget';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import Dashboard from 'library/CompositeComponents/dashboard/Dashboard';
import { type WidgetOption } from 'library/CompositeComponents/dashboard/DashboardTypes';

export const CHART_WIDGET_NAME = 'Chart';
const initialDashboardState: DataViewerDashboardConfigType = {
  startDate: moment.utc().subtract(1, 'week'),
  endDate: moment.utc(),
  dateRangeIsInherited: true,
  preset: '7d',
};

const widgetOptions: WidgetOption[] = [
  {
    Component: TimeSeriesScalarDataChartWidget,
    label: CHART_WIDGET_NAME,
    multiple: true,
  },
];
const widgetLibrary: WidgetLibrary = new WidgetLibrary(widgetOptions);

const DataViewerDashboard = () => (
  <Dashboard
    title="Data Viewer"
    id="data-viewer-dashboard"
    widgetLibrary={widgetLibrary}
    ConfigComponent={DataViewerDashboardConfig}
    initialDashboardState={initialDashboardState}
  />
);

export default DataViewerDashboard;
