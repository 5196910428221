import { Chip } from 'base-components';
import ShowAllContentFormatter from 'library/BaseComponents/table/formatters/ShowAllContentFormatter';
import { Attribute } from '../TableAnnotation';

const AttributeFormatter = ({ row, value, selected }: any) => {
  let chips = [];
  if (value) {
    chips = value.map((attribute: Attribute) => (
      <Chip
        variant="outlined"
        style={{ margin: '3px 0px' }}
        key={attribute.name}
        label={
          <>
            <b>{`${attribute.name}: `}</b>
            {`${attribute.value} `}
          </>
        }
      />
    ));
  }
  return ShowAllContentFormatter({ row, value: [chips], selected });
};

export default AttributeFormatter;
