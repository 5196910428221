export const DEFAULT_DEVICE_CATEGORY = {
  description: '',
  deviceCategoryCode: '',
  name: 'All',
};

export const DEFAULT_DEVICE = {
  deviceId: undefined,
  deviceCode: '',
  deviceCategoryCode: '',
  dataRating: [],
  deviceLink: '',
  name: 'All',
};

export const DEFAULT_LOCATION = {
  description: '',
  deployments: 0,
  depth: 0,
  locationCode: undefined,
  name: 'All Available',
};

export const DEFAULT_PROPERTY = {
  description: '',
  name: 'All',
  propertyCode: '',
};

export const DEFAULT_TIME_PERIOD = 'all';

export const FILTER_OPTIONS = [
  { name: 'Device' },
  { name: 'Device Category' },
  { name: 'Location' },
  { name: 'Property' },
  { name: 'Time Period' },
];
