import { Component, createRef } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Loading } from '@onc/composite-components';
import { Description, Bookmarks, XmlIcon, GetApp, CsvIcon } from '@onc/icons';
import EmptyCastPlot from 'assets/images/emptyCastPlot.png';
import {
  IconButton,
  Dialog,
  Lightbox,
  Popup,
  Typography,
} from 'base-components';
import {
  NOT_DOWNLOADED,
  TASK_COMPLETE,
} from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchConstants';
import CastPreviewImage from 'domain/Apps/geospatial-search/community-fisher/CastPreviewImage';
import AnnotationService from 'domain/services/AnnotationService';
import ISO19115XMLService from 'domain/services/ISO19115XMLService';
import SiteDeviceSubsetService from 'domain/services/SiteDeviceSubsetService';
import Annotationlist from 'library/CompositeComponents/annotation-list/AnnotationList';

import DateFormatUtils from 'util/DateFormatUtils';
import DateUtils from 'util/DateUtils';
import Environment from 'util/Environment';
import { parseDmasAPIResponse, get } from 'util/WebRequest';
import CastDataContext from '../CastDataContext';
// This css fix is needed to fix what leaflet is auto applying to <p> elements.
import './CastPopup.css';

const TIMEOUT = 5000;
const ERROR = 'Error';
const SEARCH_HDR_COMPLETED = 'COMPLETED';
const CAST_DATA_PRODUCT_FORMAT_ID = 249;
const DEVICE_RESOURCE_TYPE_ID = 2;
const PRODUCT_INDICES = {
  cor: 0,
  png: 1,
  xml: 3,
  csv: 4,
};

const styles = (theme) => ({
  gridWidth: {
    width: 62,
  },
  firstCardButtons: {
    paddingTop: theme.spacing(),
    color: theme.palette.primary.main,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSubheader: {
    zIndex: 2,
    padding: 0,
  },
  listItem: {
    zIndex: 1,
    paddingRight: theme.spacing(0.5),
  },
  buttonContainerRight: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
    height: theme.spacing(6),
  },
  buttonContainerLeft: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    height: theme.spacing(6),
  },
  castIconQuadrant: {
    marginTop: theme.spacing(0.25),
  },
  popup: {
    width: 'max-content',
  },
  // hard override of leaflet <a> tag colour with primary colour
  linkButtonPrimaryOverride: {
    color: `${theme.palette.primary.main} !important`,
  },
  thinButton: {
    padding: 0,
    margin: 0,
  },
  dialogPaper: {
    minHeight: theme.spacing(20),
    maxHeight: theme.spacing(70),
    minWidth: theme.spacing(20),
    maxWidth: theme.spacing(70),
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
  },
  col: {
    flex: '1',
    padding: '1em',
  },
});

class CastPopupv2 extends Component {
  static contextType = CastDataContext;

  static propTypes = {
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    isAssigned: PropTypes.bool.isRequired,
    downloadAllUnrestrictedCastsFromStation: PropTypes.func,
    casts: PropTypes.arrayOf(
      PropTypes.shape({
        endDate: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.instanceOf(Date),
        ]), // "2018-01-01T01:24:34.000Z"
        generationType: PropTypes.string, // "automated"
        referenceDepth: PropTypes.number, // 100.000000000000000
        referenceLat: PropTypes.number, // 49.398352504970640
        referenceLon: PropTypes.number, // -124.558354807672120
        siteDeviceId: PropTypes.number, // 1200682
        siteDeviceSubsetId: PropTypes.number, // 3820
        siteDeviceSubsetName: PropTypes.string, // BMP-001
        siteDeviceSubsetType: PropTypes.string, // UP_CAST
        startDate: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.instanceOf(Date),
        ]), // "2018-01-01T01:22:34.000Z"
        code: PropTypes.string,
        name: PropTypes.string,
        checkboxSelected: PropTypes.bool,
        accessRestrictionLevel: PropTypes.string,
      })
    ),
    classes: PropTypes.shape({
      list: PropTypes.string,
      gridWidth: PropTypes.string,
      firstCardButtons: PropTypes.string,
      linkButtonPrimaryOverride: PropTypes.string,
      listSubheader: PropTypes.string,
      listItem: PropTypes.string,
      buttonContainerLeft: PropTypes.string,
      buttonContainerRight: PropTypes.string,
      popup: PropTypes.string,
      dialogPaper: PropTypes.string,
      castIconQuadrant: PropTypes.string,
      row: PropTypes.string,
      col: PropTypes.string,
    }),
  };

  static defaultProps = {
    casts: [],
    classes: undefined,
    downloadAllUnrestrictedCastsFromStation: undefined,
    dateFrom: undefined,
    dateTo: undefined,
  };

  state = {
    displayList: false,
    displayAnnotations: false,
    imageOpen: false,
    xmlUrl: undefined,
    corUrl: undefined,
    csvUrl: undefined,
    imageUrl: null,
    imageError: false,
    annotations: undefined,
    castStatuses: new Map(),
    restrictionInfo: undefined,
    popupRef: createRef(),
    currentCasts: [],
    unrestrictedCastAvailable: false,
    restrictedCastsAvailable: false,
    openCast: undefined,
  };

  downloadXmlFirstCard = () => {
    const { xmlUrl } = this.state;
    window.location.href = xmlUrl;
  };

  downloadCsvFirstCard = () => {
    const { csvUrl } = this.state;
    window.location.href = csvUrl;
  };

  downloadCorFirstCard = () => {
    const { corUrl } = this.state;
    window.location.href = corUrl;
  };

  generateImageUrl = (preview) => {
    if (preview && preview.fileName) {
      return `${Environment.getDmasUrl()}/${preview.rootUrl}/${
        preview.fileName
      }`;
    }
    return null;
  };

  convertAnnotationFormat = (annotation) => {
    const { annotationSummary, modifiedByUser, createdByUser, ...rest } =
      annotation;
    return {
      heading: 0,
      comment: annotationSummary,
      modifiedBy: {
        firstName: modifiedByUser.firstname,
        lastName: modifiedByUser.lastname,
      },
      createdBy: {
        firstName: createdByUser.firstname,
        lastName: createdByUser.lastname,
      },
      ...rest,
    };
  };

  componentDidMount() {
    const { casts, isAssigned } = this.props;
    const { popupRef } = this.state;

    // eslint-disable-next-line no-underscore-dangle
    if (popupRef?.current?._source) {
      // eslint-disable-next-line no-underscore-dangle
      popupRef.current._source.on('popupopen', this.onOpenPopup);
    }
    this.generateCastState();

    if (isAssigned === false) {
      this.setState({
        openCast: casts[0],
        unrestrictedCastAvailable: casts[0].accessRestrictionLevel === 'RW',
        restrictedCastsAvailable: casts[0].accessRestrictionLevel === 'RO',
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { casts } = this.props;
    const { displayList } = this.state;

    const newCastsSubsetList = casts.map((cast) => cast.siteDeviceSubsetId);
    const prevCastsSubsetList = prevProps.casts.map(
      (cast) => cast.siteDeviceSubsetId
    );

    if (
      newCastsSubsetList.length !== prevCastsSubsetList.size ||
      !newCastsSubsetList.every((item) => prevCastsSubsetList.includes(item))
    ) {
      if (displayList) {
        this.generateCastState(this.refreshDisplayList);
      }
      this.generateCastState();
    }
  }

  getDataPreviewObject = async () => {
    const { onError, casts } = this.props;
    const { openCast, restrictedCastsAvailable, unrestrictedCastAvailable } =
      this.state;

    if (unrestrictedCastAvailable) {
      let payload;
      try {
        payload = await get('DataPreviewService', {
          operation: 22,
          siteDeviceSubsetId: openCast.siteDeviceSubsetId,
        }).then((response) => parseDmasAPIResponse(response));
        if (payload) {
          this.setState({
            xmlUrl: ISO19115XMLService.getISO19115XmlServiceCallForCast(
              openCast.siteDeviceSubsetId,
              `cast${openCast.siteDeviceSubsetId}`
            ),
            corUrl: this.generateUrlForPregeneratedFiles(
              payload.preview,
              'COR'
            ),
            csvUrl: this.generateUrlForPregeneratedFiles(
              payload.preview,
              'CSV'
            ),
            imageUrl: this.generateImageUrl(payload.preview),
          });
        }
      } catch (error) {
        if (error.statusCode !== 403) {
          onError(error.message);
        } else {
          this.getRestrictionInfo(casts[0]);
        }
      }
    }
    if (restrictedCastsAvailable) {
      this.getRestrictionInfo(
        casts.find((cast) => cast.accessRestrictionLevel === 'RO')
      );
    }
  };

  /**
   * Convert casts prop to a state so that we can also store the png and cor
   * data product run results. In the future, would be nice to have this stored
   * in the backend somewehere so generations don't have to constantly happen
   * (and so that we don't need this state object).
   */
  generateCastState = (callback) => {
    const { casts } = this.props;
    const { currentCasts } = this.state;
    const castsList = casts.map((cast) => cast.siteDeviceSubsetId);

    // remove from the current list any casts that don't exist in the new filter
    let proposedCurrentCasts = [...currentCasts].filter((currentCast) =>
      castsList.includes(currentCast.siteDeviceSubsetId)
    );

    const tempCasts = [...casts].filter(
      (newCast) =>
        !proposedCurrentCasts.some(
          (currentCast) =>
            newCast.siteDeviceSubsetId === currentCast.siteDeviceSubsetId
        )
    );

    // create new casts that didn't exist while on previous filter
    const newCasts = tempCasts.map((cast) => ({
      ...cast,
      png: { dpRequestId: undefined, dpRunId: undefined, status: undefined },
      cor: { dpRequestId: undefined, dpRunId: undefined, status: undefined },
      xml: { dpRequestId: undefined, dpRunId: undefined, status: undefined },
      csv: { dpRequestId: undefined, dpRunId: undefined, status: undefined },
    }));

    // assemble the proposed new cast list for the popup
    if (newCasts) {
      proposedCurrentCasts = proposedCurrentCasts
        .concat(newCasts)
        .sort((x, y) => x.startDate < y.startDate);
    }

    // compare the current cast list and the proposed cast list
    const newCurrentCastList = proposedCurrentCasts.map(
      (cast) => cast.siteDeviceSubsetId
    );
    const oldCurrentCastList = currentCasts.map(
      (cast) => cast.siteDeviceSubsetId
    );

    // only update if necessary
    const shouldUpdate =
      newCurrentCastList.length !== oldCurrentCastList.length ||
      !newCurrentCastList.every((item) => oldCurrentCastList.includes(item));
    if (shouldUpdate) {
      this.setState(
        {
          currentCasts: proposedCurrentCasts,
          openCast: proposedCurrentCasts.find(
            (cast) => cast.accessRestrictionLevel === 'RW'
          ),
          unrestrictedCastAvailable: casts.some(
            (cast) => cast.accessRestrictionLevel === 'RW'
          ),
          restrictedCastsAvailable: casts.some(
            (cast) => cast.accessRestrictionLevel === 'RO'
          ),
        },
        callback || (() => {})
      );
    }
  };

  /** Generates most recent cast url. */
  generateUrlForPregeneratedFiles = (preview, nameOfPregeneratedFile) => {
    const { onError } = this.props;
    if (!preview || !preview.fileLinksSOO) {
      return undefined;
    }
    const fileName = preview.fileLinksSOO.filter(
      (fileLink) => fileLink.first === nameOfPregeneratedFile
    );
    if (fileName.length < 1) {
      onError(
        `Could not get pre-generated ${nameOfPregeneratedFile} for most recent cast at this location`
      );
      return undefined;
    }

    return `${Environment.getDmasUrl()}/${fileName[0].second}`;
  };

  getRestrictionInfo = async (cast) => {
    await SiteDeviceSubsetService.getRestrictionInfo(
      cast.siteDeviceSubsetId,
      CAST_DATA_PRODUCT_FORMAT_ID
    ).then((payload) => {
      this.setState({
        restrictionInfo: payload,
        restrictedCastsAvailable: true,
      });
    });
  };

  /**
   * Checks status of data product.
   *
   * @param {object} cast
   * @param {number} cast.extension.dpRunId
   * @param {number} cast.extension.dpRequestId
   * @param {string} extension
   */
  checkStatus = (cast, extension) => {
    const { updateDownloadedCastsFromSingleCast } = this.context;
    const { onError, onInfo, isAssigned } = this.props;
    const { dpRequestId } = cast[extension];
    let castCopy = cast;
    return get('apiproxy/dataProductDelivery', {
      method: 'status',
      dpRequestId,
    })
      .then((response) => {
        const { searchHdrStatus, newSearches } = response.data;
        const { taskStatus } = newSearches[0];
        if (searchHdrStatus !== SEARCH_HDR_COMPLETED) {
          if (castCopy[extension].status !== taskStatus) {
            castCopy = this.updateCastStatus(
              castCopy,
              extension,
              taskStatus,
              castCopy[extension].dpRunId,
              castCopy[extension].dpRequestId,
              undefined
            );
          }
          // keep polling until completed
          setTimeout(() => {
            this.checkStatus(castCopy, extension);
          }, TIMEOUT);
        } else {
          updateDownloadedCastsFromSingleCast(
            this.updateCastStatus(
              castCopy,
              extension,
              taskStatus,
              castCopy[extension].dpRunId,
              castCopy[extension].dpRequestId,
              undefined
            ),
            isAssigned
          );

          const completionMessage = `Generation of ${extension} finished for ${cast.siteDeviceSubsetName} with the following status: ${taskStatus}.`;

          if (taskStatus !== TASK_COMPLETE) {
            // task does not successfully complete (completed with errors, cancelled, ...)
            onError(completionMessage);
          } else {
            onInfo(completionMessage);
          }
        }
      })
      .catch((error) => {
        const errorMessage = Environment.isUserLoggedIn()
          ? error.message
          : 'Please try logging in to access this data product.';
        onError(errorMessage);
        this.updateCastStatus(cast, extension, ERROR);
      });
  };

  /**
   * Updates status of a specific cast. TODO: Find better way to store the
   * cor/png generations for each cast.
   *
   * @param {object} cast
   * @param {string} extension
   * @param {string} status
   * @param {number} dpRunId
   * @param {number} dpRequestId
   * @param {string} restrictedMessage
   */
  updateCastStatus = (
    cast,
    extension,
    status,
    dpRunId,
    dpRequestId,
    restrictedMessage
  ) => {
    const { onError } = this.props;
    const { currentCasts } = this.state;

    const castIndex = currentCasts.indexOf(cast);
    if (castIndex < 0) {
      onError('Invalid cast supplied.');
      return {};
    }

    const casts = [...currentCasts];
    casts[castIndex][extension] = {
      status,
      dpRunId,
      restrictedMessage,
      dpRequestId,
    };
    this.setState({
      currentCasts: casts,
    });
    return casts[castIndex];
  };

  handleAnnotationButtonClick = (cast) => {
    this.generateAnnotationsForCast(cast);
    this.toggleDisplayAnnotationState();
  };

  generateAnnotationsForCast = (cast) => {
    const { onError } = this.props;
    AnnotationService.getAnnotationsForCast(
      cast.siteDeviceSubsetId,
      cast.startDate,
      cast.endDate,
      onError
    ).then((response) => {
      const annotations = response
        ? response.naiveAnnotationList.map((annotation) =>
            this.convertAnnotationFormat(annotation)
          )
        : [];
      this.setState({ annotations, castThatIsShowingAnnotations: cast });
    });
  };

  toggleDisplayAnnotationState = () => {
    const { displayAnnotations } = this.state;
    this.setState({ displayAnnotations: !displayAnnotations });
  };

  generatePlotComponent = () => {
    const { code, classes, downloadAllUnrestrictedCastsFromStation } =
      this.props;
    const { imageUrl, openCast, unrestrictedCastAvailable } = this.state;

    const shouldRenderDownloadAllButton =
      unrestrictedCastAvailable &&
      downloadAllUnrestrictedCastsFromStation !== undefined;

    const title = `${code}'s lastest plot`;
    let plotTitle = 'Cast Plot';
    const dateFormat = 'full';

    if (openCast) {
      plotTitle = `Cast plot from ${DateFormatUtils.formatDate(
        openCast.startDate,
        dateFormat
      )} to ${DateFormatUtils.formatDate(openCast.endDate, dateFormat)}`;
    }
    return (
      <>
        <div
          onClick={this.openImage}
          onKeyPress={this.openImage}
          role="button"
          tabIndex="0"
        >
          {this.generateCastPreview(plotTitle)}
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <div className={classes.buttonContainerLeft}>
              {this.renderDownloadTxtButton()}
              {this.renderDownloadCsvButton()}
              {this.renderDownloadXmlButton()}
            </div>
          </div>

          <div className={classes.col}>
            <div className={classes.buttonContainerRight}>
              {shouldRenderDownloadAllButton && (
                <IconButton
                  aria-label={
                    Environment.isUserLoggedIn()
                      ? 'Download All Available Data'
                      : 'Please login to use Download All functionality'
                  }
                  Icon={GetApp}
                  size="small"
                  onClick={() => downloadAllUnrestrictedCastsFromStation(code)}
                  className={classes.firstCardButtons}
                  disabled={!Environment.isUserLoggedIn()}
                />
              )}
              {this.renderDisplayAnnotationsForPreview()}
            </div>
          </div>
        </div>

        {this.renderImage(imageUrl, title)}
      </>
    );
  };

  generateCastPreview = (plotTitle) => {
    const {
      restrictionInfo,
      imageUrl,
      unrestrictedCastAvailable,
      restrictedCastsAvailable,
    } = this.state;

    let srcImage = imageUrl;
    if (imageUrl === null) {
      srcImage = EmptyCastPlot;
    }
    if (srcImage === undefined) {
      return <Loading size={50} />;
    }

    // if there is at least one visible cast that the user can generate dataproducts from

    return (
      <>
        {unrestrictedCastAvailable && (
          <CastPreviewImage
            onError={this.castPlotNotFoundError}
            title={plotTitle}
            src={srcImage}
          />
        )}
        {restrictedCastsAvailable && (
          <>
            <h3>
              {unrestrictedCastAvailable
                ? 'This Station Also Contains Restricted Data'
                : 'Data Restricted'}
            </h3>
            {restrictionInfo}
          </>
        )}
      </>
    );
  };

  handleAnnotationSort = (sortByMostRecent) => {
    const { annotations } = this.state;
    let sortedAnnotations = annotations;
    if (!annotations) {
      return;
    }
    if (sortByMostRecent) {
      sortedAnnotations = annotations.sort((a, b) =>
        DateUtils.isDateBefore(a.startDate, b.startDate)
      );
    } else {
      sortedAnnotations = annotations.sort((a, b) =>
        DateUtils.isDateAfter(a.startDate, b.startDate)
      );
    }
    this.setState({
      annotations: sortedAnnotations,
    });
  };

  renderDisplayAnnotationsForPreview = () => {
    const { openCast, unrestrictedCastAvailable } = this.state;
    const { classes } = this.props;
    if (unrestrictedCastAvailable) {
      return (
        <IconButton
          aria-label="Display Annotations"
          Icon={Bookmarks}
          size="small"
          onClick={() => this.handleAnnotationButtonClick(openCast)}
          className={classes.firstCardButtons}
        />
      );
    }
    return null;
  };

  handleAnnotationDownload = () => {
    const { castThatIsShowingAnnotations } = this.state;
    if (castThatIsShowingAnnotations !== undefined) {
      let str = `${Environment.getDmasUrl()}/AnnotationServiceV3?operation=8&resourceTypeId=2700&annotationSources=3&`;
      str = str.concat(
        `resourceId=${castThatIsShowingAnnotations.siteDeviceSubsetId}&`
      );
      str = str.concat(`fromDate=${castThatIsShowingAnnotations.startDate}&`);
      str = str.concat(`toDate=${castThatIsShowingAnnotations.endDate}`);

      window.location.href = str;
    }
  };

  renderDownloadTxtButton = () => {
    const { classes } = this.props;
    const { corUrl } = this.state;
    if (!corUrl) return null;
    return (
      <IconButton
        aria-label="Download .COR"
        Icon={Description}
        id="downloadCOR"
        size="small"
        onClick={() => this.downloadCorFirstCard()}
        className={classes.firstCardButtons}
      />
    );
  };

  renderDownloadCsvButton = () => {
    const { classes } = this.props;
    const { currentCasts, restrictionInfo, csvUrl } = this.state;
    if (
      currentCasts === undefined ||
      currentCasts.length <= 1 ||
      restrictionInfo ||
      !csvUrl
    ) {
      return null;
    }
    return (
      <IconButton
        aria-label="Download CSV"
        Icon={CsvIcon}
        id="downloadCsv"
        size="small"
        onClick={() => this.downloadCsvFirstCard()}
        className={classes.firstCardButtons}
      />
    );
  };

  renderDownloadXmlButton = () => {
    const { openCast } = this.state;
    const { classes, isAssigned } = this.props;
    if (!isAssigned || !openCast) {
      return null;
    }
    return (
      <IconButton
        aria-label="Download ISO19115 XML Metadata file"
        Icon={XmlIcon}
        id="downloadXml"
        size="small"
        onClick={() => this.downloadXmlFirstCard()}
        className={classes.firstCardButtons}
      />
    );
  };

  refreshDisplayList = () => {
    const { castStatuses, currentCasts } = this.state;
    const { downloadedCasts } = this.context;

    // if "completed" (will no longer try to update through checkstatus loop)
    // remove it if not found within downloadedCasts
    for (let index = 0; index < currentCasts.length; index += 1) {
      let cast = currentCasts[index];
      let completed = true;
      for (const type of Object.keys(PRODUCT_INDICES)) {
        if (completed) {
          completed =
            cast[type].status === TASK_COMPLETE ||
            cast[type].status === undefined;
        }
      }
      if (completed) {
        for (const type of Object.keys(PRODUCT_INDICES)) {
          cast = this.updateCastStatus(
            cast,
            type,
            undefined,
            undefined,
            undefined,
            undefined
          );
        }
      }
    }

    for (const downloadedCast of downloadedCasts) {
      let cast = currentCasts.find(
        (someCast) =>
          downloadedCast.siteDeviceSubsetId === someCast.siteDeviceSubsetId
      );
      if (cast) {
        for (const [type, index] of Object.entries(PRODUCT_INDICES)) {
          const productStatus = downloadedCast.productsForCast[index].status;
          cast = this.updateCastStatus(
            cast,
            type,
            productStatus === NOT_DOWNLOADED ? undefined : productStatus,
            downloadedCast.productsForCast[index].dpRunId,
            downloadedCast.productsForCast[index].dpRequestId,
            undefined
          );
        }
      }
    }
    if (castStatuses.size === 0) {
      const ids = currentCasts.slice(1).map((cast) => cast.siteDeviceSubsetId);
      ids.forEach((id) => this.generateStatuses(id));
    }
    this.forceUpdate();
  };

  generateStatuses = async (siteDeviceSubsetId) => {
    const { castStatuses } = this.state;
    const payload =
      await SiteDeviceSubsetService.getCastReviewed(siteDeviceSubsetId);
    castStatuses.set(siteDeviceSubsetId, payload.castReviewed);
    this.setState({ castStatuses });
  };

  onOpenPopup = () => {
    this.getDataPreviewObject();
  };

  openImage = () => {
    const { imageError } = this.state;
    if (!imageError) {
      this.setState({ imageOpen: true });
    }
  };

  closeImage = () => {
    this.setState({ imageOpen: false });
  };

  castPlotNotFoundError = (ev) => {
    this.setState({ imageError: true });
    // eslint-disable-next-line no-param-reassign
    ev.target.src = EmptyCastPlot;
  };

  renderImage = (imageSrc, title) => {
    const { imageOpen } = this.state;

    if (!imageOpen || !imageSrc) return null;
    return (
      <Lightbox
        onClose={this.closeImage}
        imageUrls={[imageSrc]}
        initialImageUrl={imageSrc}
        imageTitle={title}
      />
    );
  };

  render() {
    const { classes, code, name, dateFrom, dateTo } = this.props;
    const { displayAnnotations, annotations, popupRef } = this.state;

    const component = this.generatePlotComponent();

    return (
      <>
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          onClose={this.toggleDisplayAnnotationState}
          open={displayAnnotations}
          fullScreen
        >
          <Annotationlist
            loadAnnotations={() => {}}
            name="castAnnotationlist"
            onToggleFilter={() => {}}
            setJumpToIndex={() => {}}
            onListItemClick={() => {}}
            onAnnotationSort={this.handleAnnotationSort}
            onDownloadClick={this.handleAnnotationDownload}
            listItems={annotations || []}
            totalListItems={annotations ? annotations.length : 0}
            resourceTypeId={DEVICE_RESOURCE_TYPE_ID}
            loading={annotations === undefined}
            renderRefreshButton={false}
            renderDownloadButton
            annotationIndex={0}
            fullTimestamp
            menuOptions={{
              'List Options': {
                sortAnnotations: 'Sort By Most Recent',
              },
              'Annotation Options': {
                displayCreatedBy: 'Created By',
                displayFullTimestamp: 'Full Timestamp',
                displayModifiedBy: 'Modified By',
                displayPositionalData: 'Positional Data',
              },
            }}
          />
        </Dialog>
        <Popup
          interactive
          className={classes.popup}
          maxWidth={400}
          closeOnClick={false}
          ref={popupRef}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 'bold' }}
          >{`Area: ${name} [${code}]`}</Typography>
          <Typography variant="body2">
            {dateFrom &&
              `Began service on: ${new Date(dateFrom).toDateString()}`}
          </Typography>
          <Typography variant="body2">
            {dateTo && `Decommissioned on: ${new Date(dateTo).toDateString()}`}
          </Typography>
          {component}
        </Popup>
      </>
    );
  }
}

export default withStyles(styles)(CastPopupv2);
