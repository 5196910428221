import { Typography } from 'base-components';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';

import TreeMenuItem from 'library/CompositeComponents/tree-menu/TreeMenuItem';
import TreeActionContent from './TreeActionContent';
import { DeckLogTreeNode } from './VideoTreeItem';

type Props = {
  item: DeckLogTreeNode;
  path: (string | { expeditionId: number })[];
  expeditionId: number;
  onMenuItemClicked: (
    path: (string | { expeditionId: number })[],
    id: number,
    ready?: boolean
  ) => void;
  onDeckLogButtonClicked: () => void;
  selectedNodeId: number;
};

const DeckLogMenuItem = ({
  item,
  path,
  expeditionId,
  onMenuItemClicked,
  onDeckLogButtonClicked,
  selectedNodeId,
}: Props) => {
  const newPath = [...path, { expeditionId: item.id }];

  return (
    <TreeMenuItem
      onClick={() => onMenuItemClicked(newPath, item.nodeId, true)}
      key={item.id}
      label={
        <div style={{ display: 'flex' }}>
          <TreeActionContent
            onDeckLogButtonClicked={onDeckLogButtonClicked}
            resourceTypeId={SeaTubeResourceTypes.EXPEDITION}
            resourceId={item.id}
            expeditionId={expeditionId}
          />
          <Typography variant="body1" style={{ margin: 'auto 0' }}>
            {`${item.html}`}
          </Typography>
        </div>
      }
      selected={selectedNodeId === item.nodeId}
    />
  );
};

export default DeckLogMenuItem;
