import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import DriverScheduleTable from './DriverScheduleTable';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
  tableDiv: {
    margin: theme.spacing(1),
  },
});

class DriverSchedulePage extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  render() {
    const { classes, onInfo, onError } = this.props;
    return (
      <div className={classes.tableDiv}>
        <DriverScheduleTable
          permission={Environment.getDmasUserPrivilege()}
          className={classes.root}
          stripedRows
          onInfo={onInfo}
          onError={onError}
        />
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(DriverSchedulePage));
