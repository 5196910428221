import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ErrorSnackbar } from '@onc/composite-components';
import { Card, CardMedia } from 'base-components';
import TMTaskService from 'domain/AppComponents/batch/service/TMTaskService';
import { WidgetTitle } from 'domain/AppComponents/Dashboard/Titles';
import {
  WidgetConfigError,
  getWidgetConfigErrorMessage,
} from 'domain/AppComponents/Dashboard/widget-error/WidgetConfigError';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import { parseDmasAPIResponse } from 'util/WebRequest';

const STYLES = {
  contentDiv: {
    display: 'block',
    '& ins': {
      textDecoration: 'underline',
    },
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
    maxHeight: '96%',
  },
};

class UserDefinedTasksWidgetDisplay extends Component {
  static propTypes = {
    showTitle: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    userDefinedTasksImagePath: PropTypes.string,
    selectedTaskDefinition: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
    userDefinedTasksType: PropTypes.string,
    userDefinedTasksRunSelection: PropTypes.shape({
      imageName: PropTypes.string,
    }),
    imageHoverOver: PropTypes.string,
    classes: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    userDefinedTasksImagePath: undefined,
    selectedTaskDefinition: undefined,
    userDefinedTasksRunSelection: undefined,
    imageHoverOver: undefined,
    userDefinedTasksType: 'latest',
  };

  state = {
    srcURL: undefined,
  };

  componentDidMount() {
    this.getUserDefinedTasksSrcURL();
  }

  renderTitle = () => {
    const { showTitle, title } = this.props;

    if (showTitle) {
      return <WidgetTitle titleText={title} />;
    }
    return null;
  };

  getLatestTaskRun = async () => {
    const { selectedTaskDefinition } = this.props;

    const latestTaskRun = await TMTaskService.getLatestRunByTaskDefinitionId(
      selectedTaskDefinition.value
    ).then((response) => parseDmasAPIResponse(response));

    return latestTaskRun.task;
  };

  getUserDefinedTasksSrcURL = async () => {
    const {
      userDefinedTasksRunSelection,
      selectedTaskDefinition,
      userDefinedTasksImagePath,
      onError,
    } = this.props;

    if (!selectedTaskDefinition) {
      return;
    }

    let imageName;

    if (userDefinedTasksRunSelection) {
      // eslint-disable-next-line prefer-destructuring
      imageName = userDefinedTasksRunSelection.imageName;
    } else {
      const latestRun = await this.getLatestTaskRun();
      if (latestRun) {
        imageName = latestRun.input['image.name'];
      } else {
        onError(
          `No run found for task ${selectedTaskDefinition.label}. Please run this task definition before setting up a user defined tasks widget`
        );
      }
    }

    this.setState({
      srcURL: `${Environment.getDmasUrl()}/pub/user${Environment.getDmasUserId()}/${
        selectedTaskDefinition.label
      }/${imageName}/${userDefinedTasksImagePath}`,
    });
  };

  getInvalidFields = () => {
    const {
      userDefinedTasksImagePath,
      userDefinedTasksRunSelection,
      selectedTaskDefinition,
      userDefinedTasksType,
    } = this.props;
    const invalidFields = [];

    if (!userDefinedTasksImagePath) {
      invalidFields.push('Image Path');
    }
    if (!userDefinedTasksRunSelection && userDefinedTasksType === 'custom') {
      invalidFields.push('Run Selection');
    }
    if (!selectedTaskDefinition) {
      invalidFields.push('Task Definition');
    }
    return invalidFields;
  };

  renderDisplayContent = () => {
    const message = getWidgetConfigErrorMessage(this.getInvalidFields());
    // returns null if there are no-invalid fields
    if (message) {
      return (
        <>
          <ErrorSnackbar message={message} />
          <WidgetConfigError message={message} />
        </>
      );
    }
    const { imageHoverOver } = this.props;
    const { srcURL } = this.state;
    return (
      <Card>
        <CardMedia component="img" src={srcURL} title={imageHoverOver} />
      </Card>
    );
  };

  render() {
    return (
      <>
        {this.renderTitle()}
        {this.renderDisplayContent()}
      </>
    );
  }
}

export default withStyles(STYLES)(withSnackbars(UserDefinedTasksWidgetDisplay));
