import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Dropdown, LoadingButton, TextField, Tooltip } from 'base-components';

const styles = (theme) => ({
  dropdown: {
    width: '40%',
  },
  updateButton: {
    width: '100%',
    textAlign: 'end',
  },
  buttonOne: {
    paddingRight: theme.spacing(),
  },
});

const SeaTubeExistingPlaylistTab = ({
  classes,
  playlists,
  selectedPlaylist = undefined,
  paddingBefore,
  paddingAfter,
  onPaddingBeforeChange,
  onPaddingAfterChange,
  onSelectedPlaylistChange,
  onUpdateSubmit,
  disabled,
  isLoggedIn,
  loadingPlaylist,
}) => {
  const isPaddingTimeBothZero = paddingBefore === 0 && paddingAfter === 0;
  const isButtonDisabled =
    !isLoggedIn ||
    disabled ||
    isPaddingTimeBothZero ||
    paddingBefore < 0 ||
    paddingAfter < 0;

  const hasLockedPlaylist = playlists.some((playlist) => playlist.locked);

  return (
    <>
      <Dropdown
        className={classes.dropdown}
        options={playlists.filter((playlist) => !playlist.locked)}
        label="Select a Playlist"
        value={selectedPlaylist}
        onChange={onSelectedPlaylistChange}
        helperText={
          hasLockedPlaylist ? 'Locked playlists are not shown here' : ''
        }
      />
      <br />
      <TextField
        className={classes.buttonOne}
        id="padding-time-before"
        translationKey="seatube.paddingTimeBefore"
        type="number"
        value={paddingBefore}
        onChange={onPaddingBeforeChange}
        error={paddingBefore < 0 || isPaddingTimeBothZero}
        helperText={
          isPaddingTimeBothZero ? `Padding Time can't both be zero` : ''
        }
      />
      <TextField
        id="padding-time-after"
        translationKey="seatube.paddingTimeAfter"
        type="number"
        value={paddingAfter}
        onChange={onPaddingAfterChange}
        error={paddingAfter < 0 || isPaddingTimeBothZero}
        helperText={
          isPaddingTimeBothZero ? `Padding Time can't both be zero` : ''
        }
      />
      <br />
      <br />
      <div className={classes.updateButton}>
        <Tooltip
          id="login-tooltip"
          title={
            isLoggedIn
              ? ''
              : 'You must be logged in to update an existing playlist!'
          }
          disableHoverListener={isLoggedIn}
          disableTouchListener
          disableFocusListener
        >
          <span>
            <LoadingButton
              variant="contained"
              onClick={onUpdateSubmit}
              disabled={isButtonDisabled}
              translationKey="seatube.updatePlaylist"
              loading={loadingPlaylist}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );
};

SeaTubeExistingPlaylistTab.propTypes = {
  paddingBefore: PropTypes.number.isRequired,
  paddingAfter: PropTypes.number.isRequired,
  onPaddingBeforeChange: PropTypes.func.isRequired,
  onPaddingAfterChange: PropTypes.func.isRequired,
  onSelectedPlaylistChange: PropTypes.func.isRequired,
  onUpdateSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  classes: PropTypes.shape({
    dropdown: PropTypes.string,
    updateButton: PropTypes.string,
    buttonOne: PropTypes.string,
  }).isRequired,
  selectedPlaylist: PropTypes.number,
  isLoggedIn: PropTypes.bool.isRequired,
  loadingPlaylist: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SeaTubeExistingPlaylistTab);
