import { useEffect, useState, useCallback } from 'react';
import * as React from 'react';
import { useSnackbars } from 'util/hooks/useSnackbars';
import EarthquakeScenarioTable from './EarthquakeScenarioTable';
import EEWRegistrationService from './service/EEWRegistrationService';
import { Subscription } from './SubscriptionData';
import SubscriptionTable from './SubscriptionTable';

const EarthquakeNotificationRegistrationPage: React.FC = () => {
  const { onError } = useSnackbars();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const fetchSubData = useCallback(async () => {
    try {
      const subs = await EEWRegistrationService.getSubscriptions();
      setSubscriptions(subs.data.payload);
    } catch (error: any) {
      onError(error.message);
    }
  }, [onError]);

  useEffect(() => {
    fetchSubData();
  }, [fetchSubData]);

  return (
    <>
      <SubscriptionTable subscriptions={subscriptions} onSave={fetchSubData} />
      <EarthquakeScenarioTable />
    </>
  );
};

export default EarthquakeNotificationRegistrationPage;
