/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import * as React from 'react';
import { ExpandMore } from '@onc/icons';
import {
  Checkbox,
  Chip,
  Switch,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from 'base-components';
import { Moment } from 'moment';
import { ClearButton, TextButton } from '../button/Buttons';

const styles = {
  summary: {
    marginLeft: 'auto',
    marginRight: 1,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    marginLeft: 1,
  },
  chip: {
    marginLeft: 1,
  },
};

export interface FilterValue {
  /**
   * The 'key' in the FilterValue interface is a string that represents the name
   * of a particular filter option. For example, if you have a color filter with
   * options like 'red', 'blue', 'green', etc., each color will be a key in the
   * FilterValue object. The type of the value associated with each key (i.e.,
   * the actual value of the filter option) can be of any type -- it could be a
   * string, a number, a boolean, etc. This flexibility allows us to handle a
   * wide variety of filter types and options.
   */
  [key: string]: any;
}

export interface FilterEvent {
  target: {
    /** New value for a filter */
    value?: FilterValue;
    /** Target name of a filter to change */
    name: string;
  };
}

export interface SummaryValues {
  /** What to label a particular summary value */
  label: string;
  /** An optional icon to add to a summary */
  icon?: ReactElement;
}

/**
 * Prop interface for actual implementations of a specific Filter such as a Date
 * Filter
 */
export interface FilterImplementationProps {
  /** Callback for when a filter is changed */
  onChange?: (event: FilterEvent) => void;
  /** Name of the filter which is needed to target a filter */
  name: string;
  /** Current value of the filter */
  value?: any;
  /** Whether or not the filter is expanded */
  expanded?: boolean;
  /** Callback used when the filter is expanded */
  onExpand?: ((name: string, expanded: boolean) => void) | undefined;
}

export interface FilterBase {
  /** Holds the current value of the filter */
  value?: FilterValue;
  /** Determines whether or not the filter can be collapsed */
  collapsible?: boolean;
  /** Determines whether the filter is in the expanded state or not */
  expanded?: boolean;
  /**
   * None will not show summary values, all will show all of them in full,
   * collaped will show them if you expand it
   */
  showSummary?: 'none' | 'all' | 'collapsed';
  /** Array of summary values to be used in the accordian summary */
  summaryValues?: SummaryValues[];
  /** Callback for when the filter is expanded */
  onExpand?: ((name: string, expanded: boolean) => void) | undefined;
  /** Callback for when the reset button is clicked */
  onReset?: (event: FilterEvent) => void;
  /** Callback for when the filter has any change */
  onChange?: (event: FilterEvent) => void;
  /** Callback for when the clear button is pressed */
  onClear?: (event: object) => void;
}

interface FilterProps extends FilterBase {
  /** Title of a specific filter */
  title: string;
  children: ReactNode;
  /** The name of the filter. Used for event targetting */
  name: string;
  /** Optional icon to put before the title of the filter */
  icon?: ReactNode;
}

const Filter: React.FC<FilterProps> = ({
  children,
  collapsible = true,
  title,
  expanded = undefined,
  icon = undefined,
  name,
  showSummary = 'collapsed',
  onReset = undefined,
  onChange = undefined,
  onClear = undefined,
  onExpand = undefined,
  summaryValues = undefined,
  value = undefined,
}: FilterProps): React.ReactElement => {
  // Default to expanded unless parent requests otherwise
  const [expandedState, setExpandedState] = useState<boolean | undefined>(
    expanded === undefined ? true : expanded
  );

  useEffect(
    () => (expanded !== undefined ? setExpandedState(expanded) : undefined),
    [expanded]
  );

  // Event handler for any of the components in the Filter
  // The parameter will either be an event or a Moment object
  // as the DateTimePicker calls its onChange with the new value only
  const onFilterChange = (
    event:
      | {
          target: {
            name: string;
            value: string | boolean;
            checked: boolean;
            type: string;
          };
        }
      | Moment,
    optionalName?: string
  ) => {
    let targetName: string;
    let targetValue: string | boolean | Moment;

    if ('target' in event) {
      targetName = event.target.name;
      targetValue = event.target.value;

      // In the case of checkboxes, need to set the value to event.target.checked
      if (event.target.type === 'checkbox') {
        targetValue = event.target.checked;
      }
    } else {
      targetName = optionalName;
      targetValue = event;
    }

    if (onChange) {
      onChange({
        target: { name, value: { ...value, [targetName]: targetValue } },
      });
    }
  };

  // Only render ClearButton if onClear is defined
  const renderClearButton = () =>
    onClear ? <ClearButton onClick={onClear} /> : undefined;

  // Only render ResetButton if onReset is defined
  const renderResetButton = () => {
    if (onReset) {
      return (
        <TextButton
          translationKey="common.buttons.reset"
          data-test="filterReset"
          onClick={(event) => onReset(event)}
        />
      );
    }
    return undefined;
  };

  // Renders the summary values depending on what is set in the showSummary prop
  const renderSummaryValues = () => {
    let chips: JSX.Element[] = [];
    if (summaryValues) {
      chips = summaryValues.map((summaryValue) => (
        <Chip
          icon={summaryValue.icon}
          key={summaryValue.label}
          sx={styles.chip}
          label={summaryValue.label}
          data-test="summaryValue"
          color="primary"
        />
      ));
    }
    switch (showSummary) {
      case 'all':
        return chips;
      case 'collapsed':
        if (!expanded && !expandedState) {
          return chips;
        }
        return undefined;
      case 'none':
        return undefined;
      default:
        return undefined;
    }
  };

  const renderActions = () => {
    if (onReset || onClear) {
      return (
        <AccordionActions>
          {renderResetButton()} {renderClearButton()}
        </AccordionActions>
      );
    }
    return undefined;
  };

  /**
   * This function will recursively render the children, looking for the "name"
   * prop to assign the onChange and value to the correct child
   */
  const renderChildren = (childElements: ReactNode): any[] | null | undefined =>
    React.Children.map(childElements, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }
      const elementChild: React.ReactElement = child;
      const grandChildren = elementChild.props.children;
      const childName = elementChild.props.name;

      // Handles a FormFieldLabel with a control prop
      // Ideally, we will use a wrapped component so this will not be needed
      // See Toggle.jsx for example
      const { control } = elementChild.props;
      if (control) {
        const controlArray = renderChildren(control);
        let newControl;
        if (controlArray && controlArray.length) {
          [newControl] = controlArray;
        }
        const renderedGrandchildren = renderChildren(grandChildren);
        return React.cloneElement(elementChild, {
          control: newControl,
          children: renderedGrandchildren,
          onChange: onFilterChange,
          value: value ? value[childName] : undefined,
        });
      }

      // Handles an input that has a name
      if (childName) {
        return React.cloneElement(elementChild, {
          children: renderChildren(grandChildren),
          onChange: onFilterChange,
          value: value ? value[childName] : undefined,
          checked:
            value &&
            (elementChild.type === Checkbox || elementChild.type === Switch)
              ? value[childName]
              : undefined,
        });
      }

      // Handles the grandchildren
      if (grandChildren) {
        return React.cloneElement(elementChild, {
          children: renderChildren(grandChildren),
        });
      }
      return child;
    });

  /** Render method. As you can tell, a Filter is mostly just an Accordion */
  return (
    <Accordion
      onChange={(e, open) =>
        onExpand ? onExpand(name, open) : setExpandedState(!expandedState)
      }
      expanded={expandedState}
    >
      <AccordionSummary
        aria-label={name}
        expandIcon={
          collapsible ? (
            <ExpandMore data-test="expand" name="expand" />
          ) : undefined
        }
      >
        <Box sx={styles.flexContainer}>
          {icon}
          <Typography sx={styles.title}>{title}</Typography>
          <Box sx={styles.summary}>
            {summaryValues ? renderSummaryValues() : undefined}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{renderChildren(children)}</AccordionDetails>
      {renderActions()}
    </Accordion>
  );
};

export default Filter;
