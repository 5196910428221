import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { ArrowDropDown, ArrowDropUp } from '@onc/icons';
import { Grid, IconButton } from 'base-components';
import { useChangeBatchTaskSequenceNumberMutation } from 'domain/AppComponents/batch/hooks/BatchTaskMutationHooks';

export type BatchOrderActionsProps = {
  taskId: number;
  sequenceNumber: number;
  canIncrement: boolean;
  canDecrement: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    adornmentStart: {
      marginRight: 0,
    },
    adornmentEnd: {
      marginLeft: 0,
    },
    arrowIcon: {
      margin: 'auto',
    },
    sequenceNumber: {
      height: '100%',
    },
  })
);

const BatchOrderActions: React.FunctionComponent<BatchOrderActionsProps> = ({
  taskId,
  sequenceNumber,
  canIncrement,
  canDecrement,
}) => {
  const updateSequenceNumberMutation =
    useChangeBatchTaskSequenceNumberMutation();
  const style = useStyles();
  return (
    <Grid container direction="row" xs={8}>
      <Grid item sm={3}>
        <Grid container alignItems="center" justifyContent="center">
          {canDecrement && (
            <IconButton
              onClick={() =>
                updateSequenceNumberMutation.mutate({
                  taskId,
                  newSequenceNumber: sequenceNumber - 1,
                })
              }
              aria-label="moveTaskUp"
            >
              <ArrowDropUp />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid item sm={2}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={style.sequenceNumber}
        >
          {sequenceNumber}
        </Grid>
      </Grid>
      <Grid item sm={3}>
        <Grid container alignItems="center" justifyContent="center">
          {canIncrement && (
            <IconButton
              onClick={() =>
                updateSequenceNumberMutation.mutate({
                  taskId,
                  newSequenceNumber: sequenceNumber + 1,
                })
              }
              aria-label="moveTaskDown"
            >
              <ArrowDropDown />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BatchOrderActions;
