import ReactHtmlParser from 'react-html-parser';

import { ONC_DATA } from 'domain/AppComponents/organization-details/OrganizationServiceData';
import { LocationDetails } from 'domain/services/LocationsWebServiceWithToken';
import ResizingInfoList from 'library/CompositeComponents/list-container/ResizingInfoList';
import InfoItem from 'library/CompositeComponents/list-items/InfoItem';
import InfoItemScrollable from 'library/CompositeComponents/list-items/InfoItemScrollable';
import InfoItemWithIcon from 'library/CompositeComponents/list-items/InfoItemWithIcon';
import OrganizationLogo from 'library/CompositeComponents/logo/OrganizationLogo';

type SearchTreeNodeDetailsProps = {
  searchTreeNodeDetails?: LocationDetails;
};

export const defaultDetails: LocationDetails = {
  deployments: undefined,
  locationName: 'Fixed Video Cameras',
  depth: undefined,
  bbox: {
    maxDepth: undefined,
    maxLat: undefined,
    maxLon: undefined,
    minDepth: undefined,
    minLat: undefined,
    minLon: undefined,
  },
  description:
    'The Fixed Video Camera Locations page lists sites where fixed cameras at the seafloor and time-lapse cameras at coastal shore stations produce video recordings.',
  hasDeviceData: 'true',
  lon: undefined,
  locationCode: 'FXCMR',
  hasPropertyData: 'false',
  lat: undefined,
  dataSearchURL: '',
};

const SearchTreeNodeDetails = ({
  searchTreeNodeDetails = defaultDetails,
}: SearchTreeNodeDetailsProps) => {
  const { depth, description, lat, locationName, locationCode, lon } =
    searchTreeNodeDetails;
  return (
    <ResizingInfoList>
      <InfoItemWithIcon>
        <OrganizationLogo id={ONC_DATA.organizationId} />
        <InfoItem primary="Search Tree Node" secondary={locationName} />
      </InfoItemWithIcon>
      <InfoItem primary="Code" secondary={locationCode} />
      <InfoItemScrollable
        primary="Search Tree Node Description"
        secondary={description ? ReactHtmlParser(description) : '(none)'}
      />
      <InfoItem primary="Latitude" secondary={lat} />
      <InfoItem primary="Longitude" secondary={lon} />
      <InfoItemScrollable primary="Depth" secondary={depth} />
    </ResizingInfoList>
  );
};

export default SearchTreeNodeDetails;
