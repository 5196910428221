import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class TicketManagerService {
  static create = (project, summary, fields) =>
    post('TicketManagerService', {
      operation: 1,
      project,
      summary,
      ...fields,
    }).then(parseDmasAPIResponse);

  static attachFile = (issueKey, file) => {
    const formData = new FormData();
    formData.append('operation', 2);
    formData.append('key', issueKey);
    formData.append('file', file.file);

    return post('TicketManagerService', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  static getRequestTypes = (project) =>
    get('TicketManagerService', {
      operation: 2,
      project,
    }).then(parseDmasAPIResponse);
}

export default TicketManagerService;
