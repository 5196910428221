import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { TableOld as Table } from 'base-components';

const styles = (theme) => ({
  table: {
    paddingBottom: theme.spacing(2),
  },
});

class DOITriggerTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    classes: PropTypes.shape({
      table: PropTypes.string,
      headingStyle: PropTypes.string,
    }).isRequired,
  };

  renderRows() {
    const { data } = this.props;
    return data.map((row) => ({
      who: row.who,
      type: row.type,
      details: row.details,
    }));
  }

  render() {
    const { classes } = this.props;
    const COLUMNS = [
      { name: 'type', title: 'Type' },
      { name: 'who', title: 'User' },
      { name: 'details', title: 'Details' },
    ];
    const PAGE_SIZE = 5;

    return (
      <Table
        className={classes.table}
        columns={COLUMNS}
        rows={this.renderRows()}
        pageSize={PAGE_SIZE}
        elevation={0}
      />
    );
  }
}
export default withStyles(styles)(DOITriggerTable);
