import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

const SERVICE = 'DiveListingService';

export interface DiveListingPayload {
  dives: DiveJSON[];
}

export interface DiveJSON {
  area: string;
  cruiseId: number;
  dateFrom: string;
  dateTo: string;
  defaultDeviceId: number;
  deviceId: number;
  diveComment: string;
  diveId: number;
  isDiveLive: boolean;
  modifyBy: number;
  modifyDate: string;
  ready: boolean;
  referenceDiveId: string;
  scientistId: number;
  scientistName: string;
}

interface GetDivesParameters {
  cruiseId?: number;
  seaTubeDivesOnly?: boolean;
  date?: string;
}

class DiveListingService {
  static getDives = (params?: GetDivesParameters) =>
    get(SERVICE, { operation: 11, ...params }, {}).then((response) =>
      parseDmasAPIResponse(response)
    );

  static getDive = (diveId: number) =>
    get(SERVICE, { operation: 10, diveId }, {}).then((response) =>
      parseDmasAPIResponse(response)
    );

  static createDive = (params) =>
    post(SERVICE, { operation: 1, ...params }, {}).then((response) =>
      parseDmasAPIResponse(response)
    );

  static updateDive = (params) =>
    post(SERVICE, { operation: 2, ...params }, {}).then((response) =>
      parseDmasAPIResponse(response)
    );

  static deleteDive = (diveId) => post(SERVICE, { operation: 3, diveId }, {});
}

export default DiveListingService;
