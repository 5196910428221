import * as React from 'react';
import { TableColumnVisibility } from '@devexpress/dx-react-grid-material-ui';

export interface ColumnVisibilityProps {
  hiddenColumnNames: string[];
  disableColumnNames?: string[];
  handleChangeVisibility?: (hiddenColumnNames: string[]) => void;
}

const ColumnVisibility: React.VFC<ColumnVisibilityProps> = ({
  hiddenColumnNames,
  disableColumnNames = undefined,
  handleChangeVisibility = undefined,
}) => {
  const columnExtensions = disableColumnNames
    ? disableColumnNames.map((columnName) => ({
        columnName,
        togglingEnabled: false,
      }))
    : [];

  if (handleChangeVisibility) {
    return (
      <TableColumnVisibility
        hiddenColumnNames={hiddenColumnNames}
        columnExtensions={columnExtensions}
        onHiddenColumnNamesChange={handleChangeVisibility}
      />
    );
  }
  return (
    <TableColumnVisibility
      defaultHiddenColumnNames={hiddenColumnNames}
      columnExtensions={columnExtensions}
    />
  );
};
export default ColumnVisibility;
