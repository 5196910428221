import { createPortal } from 'react-dom';

type PipWindowProps = {
  pipWindow: Window;
  children: React.ReactNode;
};

export default function PipWindow({ pipWindow, children }: PipWindowProps) {
  return createPortal(children, pipWindow.document.body);
}
