import {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
  SetStateAction,
  Dispatch,
} from 'react';
import {
  IntegratedSorting,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import { useQueryClient } from '@tanstack/react-query';
import {
  usePAMTaskDefinitionQuery,
  usePamguardBatchMonitorPrivilegeQuery,
} from 'domain/AppComponents/batch/hooks/BatchTaskQueryHooks';
import { BatchDialogContext } from 'domain/AppComponents/batch/providers/BatchTaskDialogProvider';
import { BatchContext } from 'domain/AppComponents/batch/providers/BatchTaskTypeProvider';
import { FilteringProps } from 'domain/AppComponents/batch/util/BatchFilters';
import {
  BATCH_TASK_TYPES_MAP,
  PAGE_SIZE,
} from 'domain/AppComponents/batch/util/BatchUtils';
import { SortingProps } from 'library/BaseComponents/table/plugins/ColumnSorting';
import { PagingProps } from 'library/BaseComponents/table/plugins/Pagination';
import { BatchDeleteData } from '../dialog/BatchDeleteDialog';
import { BatchUpdateData } from '../dialog/BatchUpdateDialog';

export const useBatchTaskType = () => {
  const context = useContext<{ taskType: string | null }>(BatchContext);

  if (!context) {
    throw new Error(
      'Cannot call useBatchTaskType in a component that is not a child of the BatchManagement Component'
    );
  }

  return context;
};

export interface BatchDialogContextType {
  setBatchToUpdate?: Dispatch<SetStateAction<BatchUpdateData | undefined>>;
  setBatchToDelete?: Dispatch<SetStateAction<BatchDeleteData | undefined>>;
  setBatchToCancel?: Dispatch<SetStateAction<number | undefined>>;
  setBatchTaskInfo?: Dispatch<SetStateAction<any>> | undefined;
  setBatchTaskToEdit?: Dispatch<
    SetStateAction<{ taskId: number; input: any } | undefined>
  >;
}
export const useBatchDialogsContext = () => {
  const context = useContext<BatchDialogContextType>(BatchDialogContext);

  if (!context) {
    throw new Error(
      'useBatchDialogs can only be used in children of a BatchDialogContext'
    );
  }

  return context;
};

export const useBatchTaskTypeAutocomplete = (batchTaskType?: string) => {
  const { taskType: pageTaskType } = useBatchTaskType();
  const taskTypeSelection = batchTaskType
    ? BATCH_TASK_TYPES_MAP.get(batchTaskType)
    : BATCH_TASK_TYPES_MAP.get(pageTaskType ?? '');
  const [taskType, setTaskType] = useState(taskTypeSelection);
  const [taskTypeInput, setTaskTypeInput] = useState(taskTypeSelection);

  const { data: pamguardPrivilege } = usePamguardBatchMonitorPrivilegeQuery();

  return {
    taskType,
    setTaskType,
    taskTypeInput,
    setTaskTypeInput,
    pamguardPrivilege,
  };
};

export const usePAMTaskDetails = () => {
  const { data: pamResult, isLoading } = usePAMTaskDefinitionQuery();
  const [taskDetails, setTaskDetails] = useState([]);

  useEffect(() => {
    if (pamResult?.taskDetail) {
      setTaskDetails(pamResult.taskDetail);
    }
  }, [isLoading, pamResult?.taskDetail]);

  return { taskDetails, setTaskDetails };
};

export const useBatchPaging = (
  initialPage: number,
  selectedBatchId?: number
): PagingProps => {
  const [currentPage, setCurrentPage] = useState(initialPage);

  useEffect(() => {
    // always show the first page when a provided batch is changed
    setCurrentPage(0);
  }, [selectedBatchId]);

  const currentPageForPaging = currentPage === 0 ? 1 : currentPage;

  let totalCount;
  if (currentPageForPaging <= 2) {
    totalCount = PAGE_SIZE * 5;
  } else {
    totalCount = PAGE_SIZE * (currentPageForPaging + 2);
  }

  return {
    currentPage,
    onCurrentPageChange: (newPage) => setCurrentPage(newPage),
    pageSize: PAGE_SIZE,
    totalCount,
  };
};

export const useBatchSorting: (
  queryName: string,
  columnExtensions: SortingState.ColumnExtension[],
  defaultSorting?: Sorting,
  curtomSorting?: IntegratedSorting.ColumnExtension[]
) => MutableRefObject<SortingProps> = (
  queryName,
  columnExtensions,
  defaultSorting,
  customSorting = []
) => {
  const queryClient = useQueryClient();
  const sorting = useRef<SortingProps>({
    sorting: defaultSorting ? [defaultSorting] : [],
    columnExtensions,
    handleSortingChange: async (newSorting) => {
      sorting.current.sorting = newSorting;
      await queryClient.invalidateQueries({
        queryKey: [queryName],
      });
    },
    customSorting,
  });
  return sorting;
};

export const useBatchFiltering: (
  defaultFilters: number[],
  filters: { value: number; label: string }[]
) => FilteringProps = (defaultFilters, filters) => {
  const [selectedFilters, setSelectedFilters] =
    useState<number[]>(defaultFilters);

  const selectAll = useCallback(() => {
    const filterValues = filters.map((filter) => filter.value);
    setSelectedFilters(filterValues);
  }, [filters]);

  const clearAll = useCallback(() => {
    setSelectedFilters([]);
  }, []);

  return { selectedFilters, setSelectedFilters, filters, selectAll, clearAll };
};

export const useBatchSearching: (
  queryKey: string
) => [string, (newValue: string) => void] = (queryKey: string) => {
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState('');
  const setValue = async (newValue: string) => {
    setSearchValue(newValue);
    await queryClient.invalidateQueries({
      queryKey: [queryKey],
    });
  };
  return [searchValue, setValue];
};
