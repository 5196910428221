import { OncLayouts } from 'library/CompositeComponents/dashboard/DashboardTypes';

const defaultPlaybackLayout: OncLayouts = {
  lg: [
    { w: 12, h: 3, x: 0, y: 0, i: 'fixed-camera-date-picker-widget' },
    { w: 6, h: 12, x: 0, y: 0, i: 'fixed-camera-video' },
    { w: 3, h: 12, x: 9, y: 0, i: 'fixed-camera-clip-list-widget' },
  ],
  md: [],
  sm: [],
  xs: [],
};

export default defaultPlaybackLayout;
