import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class CruiseDropdown extends Component {
  static propTypes = {
    validation: PropTypes.func,
    title: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    validation: () => {},
    disabled: false,
    initialValue: undefined,
  };

  sortCruises = (a, b) => {
    if (a.label === b.label) return 0;
    return a.label < b.label ? -1 : 1;
  };

  state = {
    cruises: [],
  };

  componentDidMount() {
    this.getSites();
  }

  getSites = () => {
    get('CruiseService', { operation: 11, dropdownOptions: true })
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => {
        const cruiseList = payload.cruises.map(({ label, value }) => ({
          label,
          value,
        }));
        cruiseList.sort(this.sortCruises);
        this.setState({
          cruises: [{ label: 'No Cruise', value: 0 }].concat(cruiseList),
        });
      });
  };

  render() {
    const { title, initialValue, validation, disabled } = this.props;
    const { cruises } = this.state;
    return (
      <Dropdown
        label="Cruise Name"
        options={cruises}
        title={title}
        initialValue={initialValue}
        validation={validation}
        disabled={disabled}
        {...this.props}
      />
    );
  }
}
export default CruiseDropdown;
