import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const XmlIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    {/* <g>
          <path d="M23.5 18h-1.5v-3.5c0 -0.276 -0.224 -0.5 -0.5 -0.5S21 14.224 21 14.5v4c0 0.276 0.224 0.5 0.5 0.5h2c0.276 0 0.5 -0.224 0.5 -0.5S23.776 18 23.5 18z" />
          <path d="M19.658 14.026c-0.204 -0.069 -0.429 0.002 -0.557 0.175L18 15.667l-1.1 -1.466c-0.129 -0.172 -0.352 -0.243 -0.557 -0.175c-0.204 0.068 -0.343 0.259 -0.343 0.473v4c0 0.276 0.224 0.5 0.5 0.5c0.276 0 0.5 -0.224 0.5 -0.5v-2.5l0.6 0.8c0.189 0.252 0.612 0.252 0.8 0l0.6 -0.8v2.5c0 0.276 0.224 0.5 0.5 0.5c0.276 0 0.5 -0.224 0.5 -0.5v-4C20 14.285 19.862 14.095 19.658 14.026z" />
          <path d="M13.625 16.501l1.276 -1.701c0.166 -0.221 0.121 -0.533 -0.1 -0.7c-0.221 -0.165 -0.533 -0.121 -0.7 0.1l-1.1 1.467l-1.101 -1.467c-0.167 -0.221 -0.479 -0.267 -0.7 -0.1c-0.221 0.166 -0.266 0.479 -0.1 0.7l1.276 1.701l-1.275 1.699c-0.166 0.221 -0.121 0.533 0.1 0.7c0.089 0.067 0.195 0.1 0.299 0.1c0.152 0 0.302 -0.069 0.401 -0.2l1.1 -1.466l1.099 1.466c0.098 0.131 0.248 0.2 0.4 0.2c0.105 0 0.211 -0.033 0.3 -0.1c0.221 -0.166 0.266 -0.479 0.1 -0.7L13.625 16.501z" />
          <path d="M17.5 13c0.276 0 0.5 -0.224 0.5 -0.5v-12C18 0.224 17.776 0 17.5 0H6.5c-0.067 0 -0.132 0.014 -0.193 0.039c-0.029 0.012 -0.051 0.034 -0.077 0.051c-0.028 0.019 -0.06 0.032 -0.084 0.056l-6 6c-0.023 0.023 -0.036 0.053 -0.053 0.081c-0.019 0.027 -0.041 0.05 -0.053 0.08C0.014 6.368 0 6.433 0 6.5v17C0 23.776 0.224 24 0.5 24h17c0.276 0 0.5 -0.224 0.5 -0.5v-3c0 -0.276 -0.224 -0.5 -0.5 -0.5c-0.276 0 -0.5 0.224 -0.5 0.5v2.5H1V7h5.5c0.276 0 0.5 -0.224 0.5 -0.5V1h10v11.5C17 12.776 17.224 13 17.5 13zM6 6H1.707L6 1.707V6z" />
        </g> */}
    <g>
      <path d="M23.027 8.324h-3.387V5.189L14.105 0H0.973v24h18.667v-3.494h3.387V8.324zM21.027 18.506H7.027V10.324h14V18.506z" />
      <path d="M7.949 16.251L8.829 16.251L9.82 14.761L10.806 16.251L11.68 16.251L10.243 14.198L11.727 12.086L10.853 12.086L9.82 13.635L8.782 12.086L7.902 12.086L9.386 14.198Z" />
      <path d="M12.953 13.494L14.027 15.547L14.467 15.547L15.54 13.494L15.54 16.251L16.35 16.251L16.35 12.086L15.488 12.086L14.244 14.456L13.012 12.086L12.144 12.086L12.144 16.251L12.953 16.251Z" />
      <path d="M20.152 15.542L18.028 15.542L18.028 12.086L17.218 12.086L17.218 16.251L20.152 16.251Z" />
    </g>
  </SvgIcon>
);

export default XmlIcon;
