import { useEffect, useState } from 'react';
import { DiffEditor } from '@monaco-editor/react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeviceParserDefinitionService from 'domain/services/DeviceParserDefinitionService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import './MonacoDiffEditor.css';
import Panel from 'library/CompositeComponents/panel/Panel';
import { useSnackbars } from 'util/hooks/useSnackbars';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  pdContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  footerContainer: {
    display: 'flex',
    float: 'left',
    width: '100%',
    paddingTop: '5px',
    paddingLeft: '10px',
  },
  buttonContainer: {
    flex: '0 0 50%',
  },
  footerButton: {
    marginLeft: '5px',
  },
}));

interface ParserDefinitionViewPageProps {
  parserDefinitionId: number;
  deviceId: number;
  privilege?: string;
}

const ParserDefinitionViewPage = ({
  parserDefinitionId,
  deviceId,
  privilege = 'RO',
}: ParserDefinitionViewPageProps) => {
  const [current, setCurrent] = useState('');
  const [inProgress, setInProgress] = useState('');
  const [old, setOld] = useState('');
  const [histories, setHistories] = useState([]);
  const [localEditor, setLocalEditor] = useState<any>();
  const [parserDefinitionIdState, setParserDefinitionIdState] =
    useState(parserDefinitionId);

  const classes = useStyles();
  const { onInfo, onError } = useSnackbars();

  const getData = (id) => {
    DeviceParserDefinitionService.getWithHistory(id)
      .then((response) => {
        if (response.parserDefinitionHistories.length > 0) {
          setOld(response.parserDefinitionHistories[0].definition);
        }
        if (response.parserDefinition) {
          setCurrent(response.parserDefinition.parserDefinition);
        }
        setHistories(response.parserDefinitionHistories);
      })
      .catch(() => {
        onError('Error in loading Parser Definition!');
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const editorDidMount = (editor, monaco) => {
    setLocalEditor(editor);
  };

  useEffect(() => {
    getData(parserDefinitionIdState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHistoryChange = (e) => {
    const id = parseInt(e.target.value, 10);
    if (histories) {
      setOld(histories[id].definition);
    }
  };

  const handleUpdateClick = () => {
    let parserDefinitionJSON;
    if (localEditor) {
      parserDefinitionJSON = localEditor.getModifiedEditor().getValue();
      setInProgress(parserDefinitionJSON);
    }

    const batchId = (
      document.getElementById('batch-drop-down') as HTMLInputElement
    ).value;
    if (parserDefinitionJSON && current !== parserDefinitionJSON) {
      DeviceParserDefinitionService.update(
        parserDefinitionIdState,
        deviceId,
        parserDefinitionJSON,
        batchId
      )
        .then((response) => {
          getData(parserDefinitionIdState);
          if (response.errors && response.errors.length > 0) {
            onError('Error in parser definition - Run Tests for details');
          } else if (response.warnings && response.warnings.length > 0) {
            onInfo(
              'Parser Definition Updated, warnings found - Run Tests for details'
            );
          } else {
            onInfo('Parser Definition Updated Successfully');
            setInProgress('');
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } else {
      onInfo('No changes made');
    }
  };

  const handleCreate = () => {
    let parserDefinitionJSON;
    if (localEditor) {
      parserDefinitionJSON = localEditor.getModifiedEditor().getValue();
      setInProgress(parserDefinitionJSON);
    }

    const batchId = (
      document.getElementById('batch-drop-down') as HTMLInputElement
    ).value;

    if (parserDefinitionJSON && current !== parserDefinitionJSON) {
      DeviceParserDefinitionService.create(
        deviceId,
        parserDefinitionJSON,
        batchId
      )
        .then((response) => {
          const newParserDefinitionId = response?.parserDefinitionId;
          setParserDefinitionIdState(newParserDefinitionId);
          getData(newParserDefinitionId);

          if (response.errors && response.errors.length > 0) {
            onError('Error in parser definition - Run Tests for details');
          } else if (response.warnings && response.warnings.length > 0) {
            onInfo(
              'Parser Definition Updated, warnings found - Run Tests for details'
            );
          } else {
            onInfo('Parser Definition Updated Successfully');
            setInProgress('');
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } else {
      onInfo('No changes made');
    }
  };

  const handleCancelClick = () => {
    if (inProgress !== '') {
      setInProgress('');
    } else {
      localEditor.getModifiedEditor().setValue(current);
    }
    onInfo('Parser definition reset to original value');
  };

  const handleRunTestsClick = () => {
    let update;
    if (localEditor) {
      update = localEditor.getModifiedEditor().getValue();
      setInProgress(update);
    }

    DeviceParserDefinitionService.runTests(deviceId, update)
      .then((response) => {
        let allGood = true;
        if (response.errors) {
          for (const m of response.errors) {
            onError(m);
            allGood = false;
          }
        }
        if (response.warnings) {
          for (const w of response.warnings) {
            onInfo(`Warning - ${w}`);
            allGood = false;
          }
        }
        if (allGood) {
          onInfo('All tests passed');
        }
      })
      .catch((error) => {
        onError(error.message);
      });
  };

  const renderEmptyPageMessage = () => {
    if (privilege === 'RO' && parserDefinitionIdState === 0) {
      return (
        <Panel
          title={
            <Typography variant="body1">No Parser Definition Found</Typography>
          }
        />
      );
    }
    return undefined;
  };

  const renderButtons = () => {
    if (privilege === 'RW') {
      if (parserDefinitionIdState !== 0) {
        return (
          <div className={classes.footerButton}>
            <ContainedButton
              translationKey="common.buttons.update"
              onClick={handleUpdateClick}
            />
            <ContainedButton
              translationKey="common.buttons.cancel"
              onClick={handleCancelClick}
            />
            <ContainedButton
              translationKey="device.runTests"
              onClick={handleRunTestsClick}
            />
          </div>
        );
      }
      return (
        <div className={classes.footerButton}>
          <ContainedButton
            translationKey="common.buttons.create"
            onClick={handleCreate}
          />
        </div>
      );
    }
    return undefined;
  };

  const renderOldVersion = () => {
    if (privilege === 'RW' && parserDefinitionIdState !== 0) {
      return (
        <div>
          <Typography> Old Version: </Typography>
          <select className="" onChange={handleHistoryChange}>
            {histories.map((history, key) => (
              <option key={history.label} value={key}>
                {history.label}
              </option>
            ))}
          </select>
        </div>
      );
    }
    return undefined;
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.pdContainer}>
          <div className={classes.buttonContainer}>
            {renderEmptyPageMessage()}
          </div>
          <DiffEditor
            original={old}
            modified={inProgress === '' ? current : inProgress}
            language="json"
            onMount={(editor, monaco) => editorDidMount(editor, monaco)}
            options={{
              originalEditable: false,
              readOnly: false,
            }}
          />
        </div>
        <hr />
        <div className={classes.footerContainer}>
          <div className={classes.buttonContainer}>{renderButtons()}</div>
          <div className={classes.buttonContainer}>{renderOldVersion()}</div>
        </div>
      </div>
    </>
  );
};
export default ParserDefinitionViewPage;
