import DOIDatasetLinks from 'domain/AppComponents/datasets/DOIDatasetLinks';
import DOIDatasetService from 'domain/AppComponents/doi-util/DOIDatasetService';

interface Props {
  siteDeviceSubsetId?: number;
}

const SiteDeviceDOIDatasetLinks: React.FC<Props> = ({
  siteDeviceSubsetId = undefined,
}: Props) => (
  <DOIDatasetLinks
    resourceId={siteDeviceSubsetId}
    fetchDOIDataset={(resourceId: number) =>
      DOIDatasetService.getDatasetForSiteDeviceSubset(resourceId)
    }
  />
);

export default SiteDeviceDOIDatasetLinks;
