import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  useTheme,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type DenseThemeProviderProps = {
  children?: React.ReactNode;
};

const DenseThemeProvider: React.FC<DenseThemeProviderProps> = ({
  children = <></>,
}: DenseThemeProviderProps) => {
  const theme = useTheme();
  const isDense = useMediaQuery(theme.breakpoints.up('md'));

  const seaTubeTheme = (outerTheme) => {
    const components = {};

    if (isDense) {
      const defaultPropsMapping = {
        MuiFilledInput: { defaultProps: { size: 'small' } },
        MuiFormControl: { defaultProps: { margin: 'dense' } },
        MuiFormHelperText: { defaultProps: { margin: 'dense' } },
        MuiIconButton: { defaultProps: { size: 'small' } },
        MuiInputBase: { defaultProps: { margin: 'dense' } },
        MuiInputLabel: { defaultProps: { margin: 'dense' } },
        MuiOutlinedInput: { defaultProps: { margin: 'dense' } },
        MuiFab: { defaultProps: { size: 'small' } },
        MuiChip: { defaultProps: { size: 'small' } },
        MuiTable: { defaultProps: { size: 'small' } },
        MuiTextField: { defaultProps: { size: 'small' } },
        MuiToolbar: { defaultProps: { variant: 'dense' } },
        MuiList: { defaultProps: { dense: true } },
        MuiListItem: { defaultProps: { dense: true } },
        MuiMenuItem: { defaultProps: { dense: true } },
        MuiToggleButtonGroup: { defaultProps: { size: 'small' } },
        Pager: { styleOverrides: { root: { padding: '0px' } } },
      };

      // Merge defaultProps into components
      Object.entries(defaultPropsMapping).forEach(([component, props]) => {
        components[component] = {
          ...components[component],
          ...props,
        };
      });
    }

    return createTheme({
      ...outerTheme,
      components: {
        ...(outerTheme.components || {}),
        ...components,
      },
    });
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={seaTubeTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default DenseThemeProvider;
