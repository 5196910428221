import { CancelIconButton } from 'domain/AppComponents/IconButtons';

const BatchCancelAction = ({
  onCancel,
  disabled,
}: {
  onCancel: () => void;
  disabled: boolean;
}) => <CancelIconButton onClick={() => onCancel()} disabled={disabled} />;

export default BatchCancelAction;
