/* eslint-disable no-underscore-dangle */
/* Ignoring underscores because we are overriding Leaflet things that use them. */

import { FC, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Add, Remove } from '@onc/icons';
import { ControlPosition } from 'leaflet';
import { useMap } from 'react-leaflet';
import Control from './Control';
import MapFAB from './MapFAB';
import { MaterialControlProps } from './MaterialControlProps';
import MapContext from '../MapContext';

const useStyles = makeStyles({
  zoomOut: { marginRight: '0px !important' },
  zoomIn: { marginRight: '0px !important', marginTop: '0px !important' },
});
// eslint-disable-next-line react/require-default-props
const ZoomControl: FC<MaterialControlProps> = ({
  idTag = 'zoom',
  options = { position: 'topright' as ControlPosition },
  prepend = false,
  size = undefined,
  containerId = undefined,
  ...rest
}) => {
  const { mapId } = useContext(MapContext);
  const classes = useStyles();
  const map = useMap();
  const [zoomInDisabled, setZoomInDisabled] = useState(
    map.getZoom() === map.getMaxZoom()
  );
  const [zoomOutDisabled, setZoomOutDisabled] = useState(
    map.getZoom() === map.getMinZoom()
  );

  map.on('zoomend', () => {
    setZoomInDisabled(map.getZoom() === map.getMaxZoom());
    setZoomOutDisabled(map.getZoom() === map.getMinZoom());
  });
  const zoomIn = () => {
    map.zoomIn();
  };

  const zoomOut = () => {
    map.zoomOut();
  };

  const position: ControlPosition = options?.position ?? 'topleft';

  return (
    <Control position={position} prepend={prepend}>
      <div id={`${mapId}-${idTag}-leaflet-control-zoom`}>
        <MapFAB
          containerClasses={`${classes.zoomIn} leaflet-control`}
          idTag={`${idTag}-in`}
          mapId={mapId}
          map={map}
          ariaLabel="Zoom In"
          TooltipProps={{ title: 'Zoom In' }}
          disabled={zoomInDisabled}
          onClick={zoomIn}
          size={size}
          containerId={containerId}
          {...rest}
        >
          <Add />
        </MapFAB>
        <MapFAB
          containerClasses={`${classes.zoomOut} leaflet-control`}
          mapId={mapId}
          map={map}
          idTag={`${idTag}-out`}
          ariaLabel="Zoom Out"
          TooltipProps={{ title: 'Zoom Out' }}
          disabled={zoomOutDisabled}
          onClick={zoomOut}
          size={size}
          containerId={containerId}
          {...rest}
        >
          <Remove />
        </MapFAB>
      </div>
    </Control>
  );
};

export default ZoomControl;
