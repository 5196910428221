import { useState, VFC } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import {
  Grid,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  TypographyWithTranslation,
} from 'base-components';
import DataAvailabilityChart from 'domain/AppComponents/charts/DataAvailabilityChart';
import { WidgetTitle } from 'domain/AppComponents/Dashboard/Titles';
import {
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import { useSnackbars } from 'util/hooks/useSnackbars';
import DataSourceSelectionWidgetFilters, {
  Device,
  DeviceCategory,
  Property,
  Location,
} from './DataSourceSelectionWidgetFilters';
import DataSourceSelectionWidgetHelper from './DataSourceSelectionWidgetHelper';
import DataSourceSelectionWidgetMenu from './DataSourceSelectionWidgetMenu';
import {
  DEFAULT_DEVICE_CATEGORY,
  DEFAULT_PROPERTY,
  DEFAULT_DEVICE,
  DEFAULT_LOCATION,
  DEFAULT_TIME_PERIOD,
} from './DataSourceWidgetConstants';
import DataSourceWidgetContainer from './DataSourceWidgetContainer';
import DraggableLocationTree from './DraggableLocationTree';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataSources: {
      marginTop: theme.spacing(),
    },
    sourceButton: {
      marginRight: 0,
    },
    buttongroup: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
    },
  })
);

type DataSourceSelectionWidgetDisplayProps = {
  title: string;
};

const DataSourceSelectionWidgetDisplay: VFC<
  DataSourceSelectionWidgetDisplayProps
> = ({ title }) => {
  // View, menu, data source list states
  const [widgetView, setWidgetView] = useState('filters');
  const [selectedDataSource, setSelectedDataSource] = useState(-1);
  const [selectedDataSourceDevice, setSelectedDataSourceDevice] = useState(-1);

  // Filter values
  const [dataSourceType, setDataSourceType] = useState('device');
  const [deviceValue, setDeviceValue] = useState<Device>(DEFAULT_DEVICE);
  const [deviceCategoryValue, setDeviceCategoryValue] =
    useState<DeviceCategory>(DEFAULT_DEVICE_CATEGORY);
  const [locationValue, setLocationValue] =
    useState<Location>(DEFAULT_LOCATION);
  const [propertyValue, setPropertyValue] =
    useState<Property>(DEFAULT_PROPERTY);
  const [timePeriod, setTimePeriod] = useState('all');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Filter states
  const [showDeviceFilter, setShowDeviceFilter] = useState(false);
  const [showDeviceCategoryFilter, setShowDeviceCategoryFilter] =
    useState(false);
  const [showLocationFilter, setShowLocationFilter] = useState(false);
  const [showPropertyFilter, setShowPropertyFilter] = useState(false);
  const [showTimeFilter, setShowTimeFilter] = useState(false);
  const [isTreeOpen, setIsTreeOpen] = useState(false);

  // Data source fetching states & active filters
  const [isFetching, setIsFetching] = useState(false);
  const [dataSources, setDataSources] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  const classes = useStyles();
  const { onError } = useSnackbars();

  // Organize filter data props
  const filterProps = (setShowFilter, setValue, showFilter?) => ({
    setShowFilter,
    setValue,
    showFilter,
  });

  const filterData = {
    devicecategory: filterProps(
      setShowDeviceCategoryFilter,
      setDeviceCategoryValue,
      showDeviceCategoryFilter
    ),
    property: filterProps(
      setShowPropertyFilter,
      setPropertyValue,
      showPropertyFilter
    ),
    device: filterProps(setShowDeviceFilter, setDeviceValue, showDeviceFilter),
    location: filterProps(
      setShowLocationFilter,
      setLocationValue,
      showLocationFilter
    ),
    timeperiod: filterProps(setShowTimeFilter, setTimePeriod),
  };

  const handleDataSourceTypeChange = (e, dataSourceTypeValue) => {
    if (dataSourceTypeValue !== null) setDataSourceType(dataSourceTypeValue);
  };

  const handleValueChange =
    (setStateFunction, defaultValue) => (event, value) => {
      setStateFunction(value === null ? defaultValue : value);
    };

  const handleDeviceChange = handleValueChange(setDeviceValue, DEFAULT_DEVICE);

  const handleDeviceCategoryChange = handleValueChange(
    setDeviceCategoryValue,
    DEFAULT_DEVICE_CATEGORY
  );

  const handleLocationChange = handleValueChange(
    setLocationValue,
    DEFAULT_LOCATION
  );
  const handlePropertyChange = handleValueChange(
    setPropertyValue,
    DEFAULT_PROPERTY
  );

  const handleStartDateChange = (date) => {
    setStartDate(moment(date));
  };

  const handleEndDateChange = (date) => {
    setEndDate(moment(date));
  };

  const handleTimeChange = (event) => {
    const timePeriods = {
      day: 'days',
      week: 'weeks',
      month: 'months',
      year: 'years',
    };
    if (event.target.value === 'all') {
      setStartDate(null);
      setEndDate(null);
    } else if (event.target.value !== 'custom') {
      const dateTo = new Date();
      const dateFrom = moment(dateTo).subtract(
        1,
        timePeriods[event.target.value]
      );
      setStartDate(dateFrom.toDate());
      setEndDate(dateTo);
    }
    setTimePeriod(event.target.value);
  };

  const closeTree = () => {
    setIsTreeOpen(false);
  };

  const handleShowLocationTree = () => {
    isTreeOpen ? closeTree() : setIsTreeOpen(true);
  };

  // Map filter with its showFilter setter
  const showFilterMap = {
    Device: setShowDeviceFilter,
    'Device Category': setShowDeviceCategoryFilter,
    Location: setShowLocationFilter,
    Property: setShowPropertyFilter,
    'Time Period': setShowTimeFilter,
  };

  const filterDefault = {
    device: DEFAULT_DEVICE,
    property: DEFAULT_PROPERTY,
    location: DEFAULT_LOCATION,
    devicecategory: DEFAULT_DEVICE_CATEGORY,
    timePeriod: DEFAULT_TIME_PERIOD,
  };

  const handleAddFilter = (option) => {
    // Show filter based on the menu option
    const setShowFilter = showFilterMap[option.name];
    if (setShowFilter) {
      setShowFilter(true);
    }
    setActiveFilters([...activeFilters, option]);
  };

  const onRemoveFilter = (option, filterType) => {
    // Remove filter and restore its default value
    setActiveFilters(
      activeFilters.filter((filter) => filter.name !== option.name)
    );
    filterData[filterType].setShowFilter(false);
    filterData[filterType].setValue(filterDefault[filterType]);
  };

  // Renders the appropriate filter based on the selected option
  const renderFilter = (option) => (
    <DataSourceSelectionWidgetFilters
      option={option}
      deviceValue={deviceValue}
      deviceCategoryValue={deviceCategoryValue}
      locationValue={locationValue}
      propertyValue={propertyValue}
      handleDeviceChange={handleDeviceChange}
      handleDeviceCategoryChange={handleDeviceCategoryChange}
      handleLocationChange={handleLocationChange}
      handlePropertyChange={handlePropertyChange}
      handleShowLocationTree={handleShowLocationTree}
      handleTimeChange={handleTimeChange}
      isTreeOpen={isTreeOpen}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
      handleStartDateChange={handleStartDateChange}
      handleEndDateChange={handleEndDateChange}
      onRemoveFilter={onRemoveFilter}
    />
  );

  const renderSelectedFilters = () =>
    activeFilters.map((filter) => renderFilter(filter));

  const getFilterStatus = (option) => {
    // Determines if a filter can be selected from the menu
    const filterStatusMap = {
      Device: showDeviceFilter,
      'Device Category': showDeviceCategoryFilter,
      Location: showLocationFilter,
      Property: showPropertyFilter,
      'Time Period': showTimeFilter,
    };
    return filterStatusMap[option.name] || false;
  };

  const handleTreeNodeSelection = (source) => {
    if (!source[0].locationCode) {
      return;
    }
    const newLocation = {
      deployments: 0,
      name: source[0].locationName,
      depth: 0,
      description: '',
      locationCode: source[0].locationCode,
    };
    setLocationValue(newLocation);
  };

  const handleReset = () => {
    setDeviceCategoryValue(DEFAULT_DEVICE_CATEGORY);
    setPropertyValue(DEFAULT_PROPERTY);
    setDeviceValue(DEFAULT_DEVICE);
    setLocationValue(DEFAULT_LOCATION);
    setStartDate(undefined);
    setEndDate(undefined);
    setTimePeriod('all');
  };

  const handleGetSources = async () => {
    // Clear previous source selection before fetching
    setSelectedDataSource(-1);
    setSelectedDataSourceDevice(-1);
    setIsFetching(true);
    const sources = await (
      dataSourceType === 'device'
        ? DataSourceSelectionWidgetHelper.fetchDeviceDataSources
        : DataSourceSelectionWidgetHelper.fetchPropertyDataSources
    )(
      deviceCategoryValue.deviceCategoryCode,
      propertyValue.propertyCode,
      locationValue.locationCode,
      deviceValue.deviceCode,
      startDate,
      endDate,
      onError
    );
    setDataSources(sources);
    setIsFetching(false);
    setWidgetView('datasources');
  };

  const isGetSourcesDisabled = () =>
    // Disables get sources button if no filters are specified or already fetching sources
    (deviceCategoryValue.name === 'All' &&
      propertyValue.name === 'All' &&
      deviceValue.name === 'All' &&
      locationValue.name === 'All Available') ||
    isFetching;

  if (widgetView === 'datasources') {
    return (
      <>
        <WidgetTitle titleText={title} />
        <DataAvailabilityChart
          deviceId={
            selectedDataSourceDevice !== -1
              ? selectedDataSourceDevice
              : undefined
          }
        />
        <Grid className={classes.dataSources}>
          <TypographyWithTranslation translationKey="dashboards.dataSources" />
          <DataSourceWidgetContainer
            dataSources={dataSources}
            dataSourceType={dataSourceType}
            selectedDataSource={selectedDataSource}
            setSelectedDataSource={setSelectedDataSource}
            setSelectedDataSourceDevice={setSelectedDataSourceDevice}
            setWidgetView={setWidgetView}
          />
        </Grid>
      </>
    );
  }

  return (
    <>
      <WidgetTitle titleText={title} />
      {isTreeOpen && (
        <DraggableLocationTree
          title="Location Tree"
          isInitiallyExpanded
          onClose={closeTree}
          onSelectNodes={handleTreeNodeSelection}
          deviceCategory={deviceCategoryValue.deviceCategoryCode}
          property={propertyValue.propertyCode}
          device={deviceValue.deviceCode}
          dateFrom={startDate}
          dateTo={endDate}
        />
      )}
      <div>
        <TypographyWithTranslation translationKey="dashboards.dataSourceType" />
        <ToggleButtonGroup
          className={classes.buttongroup}
          exclusive
          fullWidth
          onChange={(e, selectedSourceType) =>
            handleDataSourceTypeChange(e, selectedSourceType)
          }
          value={dataSourceType}
        >
          <ToggleButton value="device">Device</ToggleButton>
          <ToggleButton value="property">Property</ToggleButton>
        </ToggleButtonGroup>
      </div>
      {renderSelectedFilters()}
      <DataSourceSelectionWidgetMenu
        handleAddFilter={handleAddFilter}
        getFilterStatus={getFilterStatus}
      />
      <Grid container justifyContent="flex-end">
        <TextButton
          translationKey="common.buttons.resetFilters"
          onClick={() => handleReset()}
        />
        <Tooltip
          title={
            isGetSourcesDisabled() && !isFetching
              ? 'At least one filter other than Time Period must be specified'
              : ''
          }
        >
          <span>
            <ContainedButton
              className={classes.sourceButton}
              disabled={isGetSourcesDisabled()}
              onClick={() => handleGetSources()}
              translationKey="dashboards.getSources"
            />
          </span>
        </Tooltip>
      </Grid>
    </>
  );
};

export default DataSourceSelectionWidgetDisplay;
