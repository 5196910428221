/* eslint-disable import/prefer-default-export */
export const validateUrl = (
  urlText: string,
  message: string
): boolean | string => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const url = new URL(urlText);
    return true;
  } catch (e) {
    return message;
  }
};

export const validateNumber = (value, message: string): boolean | string => {
  if (!isNaN(Number(value))) {
    return true;
  }
  return message;
};
