import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle } from 'base-components';
import DataPreviewWidgetConfigContent from 'domain/Widgets/Config/DataPreviewWidgetConfigContent';
import {
  CancelButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';

const useStyles = makeStyles((theme) => ({
  formButtons: {
    marginTop: theme.spacing(1),
    float: 'right',
  },
}));

type DataPreviewWidgetConfigValues = {
  title?: string;
  previewUrl?: string;
  onSave?: (values: any) => Promise<void>;
  onCancel?: () => unknown;
  showTitle?: boolean;
};

type DataPreviewWidgetConfigPropsType = {
  previewUrl: string;
  showTitle: boolean;
  title: string;
};

const DataPreviewWidgetConfig = ({
  title = undefined,
  previewUrl = undefined,
  onSave = undefined,
  onCancel = undefined,
  showTitle = undefined,
}: DataPreviewWidgetConfigValues) => {
  const classes = useStyles();

  const formMethods = useForm<DataPreviewWidgetConfigPropsType>({
    defaultValues: {
      previewUrl,
      showTitle,
      title,
    },
    mode: 'onBlur',
  });

  return (
    <Dialog open fullWidth>
      <DialogTitle>Configure Data Preview Widget</DialogTitle>
      <DialogContent>
        <Form onSubmit={onSave} formMethods={formMethods}>
          <DataPreviewWidgetConfigContent formMethods={formMethods} />
          <div className={classes.formButtons}>
            <CancelButton onClick={onCancel} />
            <SaveButton />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DataPreviewWidgetConfig;
