import { PureComponent } from 'react';

import ToggleItemsResponsiveLayout from 'library/CompositeComponents/grid-layout/ToggleItemsResponsiveLayout';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

import DEFAULT_LAYOUT from './layouts';

const ROW_HEIGHT = 25;
const BREAKPOINTS = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 150 };
const COLS = { lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 };

/**
 * ExpeditionPageLayout component responsible for the layout of
 * ExpeditionLandingPage.
 *
 * TODO: add save functionality to layout add share functionality to layout put
 * items into WidgetService instead of hard-coding better selection of
 * default/user selected layout
 */
class ExpeditionPageLayout extends PureComponent {
  state = {
    items: [
      { key: 'expedition-map', name: 'Map' },
      { key: 'expedition-list', name: 'Expedition List' },
      {
        key: 'expedition-details',
        name: 'Details',
      },
    ],
  };

  render() {
    const { items } = this.state;
    return (
      <ToggleItemsResponsiveLayout
        breakpoints={BREAKPOINTS}
        cols={COLS}
        draggableCancel=".onc-panel-content"
        items={items}
        rowHeight={ROW_HEIGHT}
        defaultLayout={DEFAULT_LAYOUT}
        {...this.props}
      />
    );
  }
}

export default withSnackbars(ExpeditionPageLayout);
