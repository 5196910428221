import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import TextWidgetConfig from './TextWidgetConfig';
import TextWidgetDisplay from './TextWidgetDisplay';

const useStyles = makeStyles(() =>
  createStyles({
    contentDiv: {},
  })
);

type TextWidgetProps = {
  classes?: {
    contentDiv: string;
  };
  onSave: (values: any) => Promise<void>;
  onConfigFinish: () => void;
  htmlValue: string;
  showTitle: boolean;
  title: string;
  showDisplay: boolean;
};

const TextWidget: React.FC<TextWidgetProps> = ({
  classes = {},
  onSave,
  onConfigFinish,
  htmlValue,
  showTitle,
  title,
  showDisplay,
}) => {
  const allClasses = { ...useStyles(), ...classes };

  const handleSave = async (values) => {
    await onSave(values);
    onConfigFinish();
  };

  return (
    <>
      {showDisplay ? (
        <TextWidgetDisplay
          title={title}
          showTitle={showTitle}
          htmlValue={htmlValue}
          classes={{ contentDiv: allClasses.contentDiv }}
        />
      ) : (
        <TextWidgetConfig
          title={title}
          showTitle={showTitle}
          onSave={handleSave}
          onCancel={onConfigFinish}
          htmlValue={htmlValue}
        />
      )}
    </>
  );
};

export default TextWidget;
