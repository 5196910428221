import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import JWFastForward from 'assets/icon/jwplayer/JWFastForward';
import JWForward30 from 'assets/icon/jwplayer/JWForward30';
import JWNext from 'assets/icon/jwplayer/JWNext';
import JWRecord from 'assets/icon/jwplayer/JWRecord';
import JWReplay30 from 'assets/icon/jwplayer/JWReplay30';
import JWSettings from 'assets/icon/jwplayer/JWSettings';
import JWShare from 'assets/icon/jwplayer/JWShare';
import JWVideocam from 'assets/icon/jwplayer/JWVideocam';
import Share from 'library/CompositeComponents/share/Share';
import ContinuousVideo from 'library/CompositeComponents/video/ContinuousVideo';
import ContinuousVideoFiles from 'library/CompositeComponents/video/ContinuousVideoFiles';
import Recording from 'library/CompositeComponents/video/Recording';
import Snapshots from 'library/CompositeComponents/video/Snapshots';
import UTCTimestamp from 'library/CompositeComponents/video/UTCTimestamp';
import UTCTooltip from 'library/CompositeComponents/video/UTCTooltip';
import VideoPlayerMenu from 'library/CompositeComponents/video/VideoPlayerMenu';

const SHARE_TITLE = 'Check out this cool video!';

const styles = {
  buttons: {
    '& div[aria-label="More Videos"]': {
      display: 'none',
    },
    '& div[aria-label="Next"]': {
      display: 'none',
    },
    '& div[aria-label="timestamp"]': {
      order: 11,
    },
    '& div[aria-label="Share"]': {
      order: 12,
    },
    '& div[aria-label="Start Recording"], div[aria-label="Stop Recording"]': {
      order: 13,
    },
    '& div[aria-label="Stop Recording"]': {
      '& .jw-svg-icon': {
        fill: 'rgba(255,0,0,0.8)',
      },
      '&:hover': {
        '& .jw-svg-icon': {
          fill: 'rgba(255,0,0,1)',
        },
      },
    },
  },
};

class DiveVideoTemplate extends PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    menuProps: PropTypes.shape({
      onClose: PropTypes.func.isRequired,
      open: PropTypes.bool.isRequired,
      anchorEl: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
      captionProps: PropTypes.shape({}),
      qualityProps: PropTypes.shape({}),
      speedProps: PropTypes.shape({}),
    }).isRequired,
    playerId: PropTypes.string.isRequired,
    ready: PropTypes.bool.isRequired,
    videoProps: PropTypes.shape({
      onCaptionListChange: PropTypes.func.isRequired,
      onPlayerReady: PropTypes.func.isRequired,
      onTime: PropTypes.func.isRequired,
      speed: PropTypes.number.isRequired,
      captions: PropTypes.number,
      seekTo: PropTypes.number,
    }).isRequired,
    buttonProps: PropTypes.shape({
      onToggleMenuOpen: PropTypes.func,
      onSkipForward: PropTypes.func,
      onSkipBackward: PropTypes.func,
      onNextClip: PropTypes.func,
      onPreviousClip: PropTypes.func,
      onFirstClip: PropTypes.func,
      onLatestClip: PropTypes.func,
    }).isRequired,
    recordingProps: PropTypes.shape({
      recording: PropTypes.bool.isRequired,
      onStart: PropTypes.func.isRequired,
      onStop: PropTypes.func.isRequired,
    }).isRequired,
    snapshotProps: PropTypes.shape({
      onSnapshot: PropTypes.func.isRequired,
      takeSnapshots: PropTypes.bool,
    }).isRequired,
    shareProps: PropTypes.shape({
      onShare: PropTypes.func,
      searchTreeNodeId: PropTypes.number,
    }).isRequired,
    videoInformationProps: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      onClose: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    files: PropTypes.instanceOf(ContinuousVideoFiles).isRequired,
    isLoggedIn: PropTypes.bool,
    recording: PropTypes.bool,
    time: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    time: undefined,
    isLoggedIn: false,
    recording: false,
  };

  componentDidUpdate(prev) {
    const { videoProps, files, onError } = this.props;
    const { videoProps: prevVideoProps } = prev;

    if (
      videoProps.seekTo === prevVideoProps.seekTo ||
      videoProps.seekTo === undefined
    )
      return;
    if (!files.getTimeElapsedAtDate(videoProps.seekTo)) {
      onError('No video exists for the current timestamp.');
    }
  }

  createButtons = () => {
    const { buttonProps, recordingProps, shareProps, ready, isLoggedIn } =
      this.props;

    if (!buttonProps || !ready) return undefined;
    const {
      onToggleMenuOpen,
      onSkipForward,
      onSkipBackward,
      onNextClip,
      onPreviousClip,
      onFirstClip,
      onLatestClip,
    } = buttonProps;
    const { onShare, searchTreeNodeId } = shareProps;

    const settingButton = {
      img: JWSettings,
      tooltip: 'Settings',
      callback: onToggleMenuOpen,
      id: 'settings',
    };

    const shareButton = {
      img: JWShare,
      tooltip: 'Share',
      callback: onShare,
      id: 'share',
    };

    const searchTreeNodeButtons = {
      add: [
        settingButton,
        {
          img: JWFastForward,
          tooltip: 'Latest Clip',
          callback: onLatestClip,
          id: 'latest-clip',
        },
        {
          img: JWFastForward,
          tooltip: 'First Clip',
          callback: onFirstClip,
          id: 'first-clip',
        },
        {
          img: JWNext,
          tooltip: 'Next Clip',
          callback: onNextClip,
          id: 'next-clip',
        },
        {
          img: JWNext,
          tooltip: 'Previous Clip',
          callback: onPreviousClip,
          id: 'prev-clip',
        },
        shareButton,
      ],
      remove: [],
    };

    const diveButtons = {
      add: [
        settingButton,
        {
          img: JWForward30,
          tooltip: 'Forward 30 Seconds',
          callback: onSkipForward,
          id: 'forward-30',
        },
        {
          img: JWReplay30,
          tooltip: 'Back 30 Seconds',
          callback: onSkipBackward,
          id: 'backward-30',
        },
        shareButton,
      ],
      remove: [],
    };

    const buttons = searchTreeNodeId ? searchTreeNodeButtons : diveButtons;

    if (isLoggedIn) {
      const { recording, onStart, onStop } = recordingProps;
      const startButton = {
        img: JWVideocam,
        tooltip: 'Start Recording',
        callback: onStart,
        id: 'record',
      };

      const stopButton = {
        img: JWRecord,
        tooltip: 'Stop Recording',
        callback: onStop,
        id: 'stop-record',
      };
      buttons.add.push(recording ? stopButton : startButton);
      buttons.remove.push(recording ? startButton.id : stopButton.id);
    }
    return buttons;
  };

  createMenuOption = () => {
    const { snapshotProps, videoInformationProps } = this.props;
    return [
      {
        key: 'snapshots',
        label: 'Take Snapshots',
        onClick: snapshotProps.onSnapshot,
      },
      {
        key: 'videoInformation',
        label: 'Video Information',
        onClick: videoInformationProps.onClick,
      },
    ];
  };

  renderMenu = () => {
    const { menuProps, playerId, ready } = this.props;
    const { anchorEl, onClose, open, captionProps, qualityProps, speedProps } =
      menuProps;
    if (!ready) return undefined;
    return (
      <VideoPlayerMenu
        playerId={playerId}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        qualityProps={qualityProps}
        speedProps={{ ...speedProps, speedOptions: [0.5, 1, 1.5, 2, 4] }}
        captionProps={captionProps}
        menuOptions={this.createMenuOption()}
      />
    );
  };

  renderTimestamp = () => {
    const { playerId, ready, time } = this.props;
    if (!ready || !time) return undefined;
    return <UTCTimestamp playerId={playerId} time={time} />;
  };

  renderTooltip = () => {
    const { playerId, ready, time, files } = this.props;
    if (!ready) return undefined;
    const tooltip = (
      <UTCTooltip playerId={playerId} files={files} time={time} />
    );
    return tooltip;
  };

  renderRecording = () => {
    const { isLoggedIn, ready, recordingProps, onError, onInfo } = this.props;
    if (!ready || !isLoggedIn || !recordingProps) return undefined;
    return <Recording {...recordingProps} onError={onError} onInfo={onInfo} />;
  };

  renderShare = () => {
    const { shareProps, ready } = this.props;
    if (!ready) return undefined;
    return <Share {...shareProps} title={SHARE_TITLE} />;
  };

  renderSnapshots = () => {
    const { ready, snapshotProps, time, onError, onInfo } = this.props;
    if (!ready || !snapshotProps || !snapshotProps?.takeSnapshots)
      return undefined;
    return (
      <Snapshots
        {...snapshotProps}
        time={time}
        onError={onError}
        onInfo={onInfo}
      />
    );
  };

  render() {
    const { classes, playerId, videoProps, files } = this.props;
    const {
      onPlayerReady,
      onTime,
      speed,
      captions,
      onCaptionListChange,
      ...rest
    } = videoProps;

    return (
      <ContinuousVideo
        className={classes.buttons}
        autoPlay
        playerId={playerId}
        onPlayerReady={onPlayerReady}
        onTime={onTime}
        buttons={this.createButtons()}
        speed={speed}
        captions={captions}
        onCaptionListChange={onCaptionListChange}
        files={files}
        {...rest}
      >
        {this.renderTimestamp()}
        {this.renderTooltip()}
        {this.renderMenu()}
        {this.renderRecording()}
        {this.renderSnapshots()}
        {this.renderShare()}
      </ContinuousVideo>
    );
  }
}

export default withStyles(styles)(DiveVideoTemplate);
