/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { memo, useState } from 'react';

import * as React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { makeStyles } from '@mui/styles';
import { ArrowUpward } from '@onc/icons';
import { Tooltip, Typography } from 'base-components';

import { SortingProps } from './ColumnSorting';

// Define the props interface for the custom sorting label component
interface CustomSortLabelProps extends TableHeaderRow.SortLabelProps {
  sort: SortingProps; // Array of sorting objects
}

// Define the styling for the component using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  iconComponent: {
    marginLeft: theme.spacing(),
    opacity: (props: {
      isSorted: boolean;
      isHovered: boolean;
      disableSort: boolean;
    }) => {
      if (props.isSorted) {
        return 1;
      }
      return props.isHovered && !props.disableSort ? 0.54 : 0;
    },
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  number: {
    marginLeft: '-2px',
    marginTop: '4px',
  },
}));

// Custom sorting label component
const SortingLabelComponent: React.FC<CustomSortLabelProps> = memo(
  ({ onSort, children = <></>, column, sort }: CustomSortLabelProps) => {
    const { sorting, columnExtensions } = sort;
    const columnSorting = sorting?.find((s) => s.columnName === column.name);
    const sortOrder = columnSorting ? columnSorting.direction : undefined;
    const sortIndex = sorting?.findIndex((s) => s.columnName === column.name);
    const disableSort =
      !sort ||
      columnExtensions?.find((c) => c.columnName === column.name)
        ?.sortingEnabled === false;
    const [isHovered, setIsHovered] = useState(false);
    const classes = useStyles({
      disableSort,
      isHovered,
      isSorted: !!sortOrder,
    });

    const toggleSortDirection = (event: React.MouseEvent) => {
      let direction: 'asc' | 'desc' | null =
        sortOrder !== 'asc' ? 'asc' : 'desc';
      let keepOther = event.shiftKey;

      if (event.ctrlKey) {
        direction = null;
        keepOther = true;
      }
      onSort({ direction, keepOther });
    };

    // Render sorting controls based on the sort order and index
    const renderControls = () => (
      <>
        <ArrowUpward
          fontSize="small"
          color="action"
          className={`${classes.iconComponent} ${
            sortOrder === 'desc' ? classes.rotated : ''
          }`}
        />
        {sortIndex != null && sortIndex >= 0 && sorting?.length > 1 && (
          <Typography className={classes.number} variant="caption">
            {sortIndex + 1}
          </Typography>
        )}
      </>
    );
    // Render the component
    return (
      <Tooltip title={disableSort ? '' : 'Sort'} placement="bottom-start">
        <span
          role="button"
          onClick={disableSort ? null : toggleSortDirection}
          className={classes.flexContainer}
          style={{ pointerEvents: disableSort ? 'none' : 'auto' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {children}
          {renderControls()}
        </span>
      </Tooltip>
    );
  }
);

export default SortingLabelComponent;
