import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

const PamguardPSFUploadService = {
  getAllPSFNames: async () => {
    const response = await get('PamguardPSFUploadService', {
      method: 'getPSFFileNames',
    });
    const payload = parseDmasAPIResponse(response);
    return payload;
  },
  uploadPSFFile: async (file) => {
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append('method', 'uploadPSF');
    formData.append('fileName', file.name);
    formData.append('uploadFile', file, file.name);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const response = await post('PamguardPSFUploadService', formData, config);
    const payload = parseDmasAPIResponse(response);
    return payload;
  },
};

export default PamguardPSFUploadService;
