import { useState } from 'react';
import * as React from 'react';
import withStyles from '@mui/styles/withStyles';

import { Add, DragIndicator } from '@onc/icons';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Grid,
  Paper,
  TypographyWithTranslation,
} from 'base-components';
import {
  EditIconButton,
  DeleteIconButton,
} from 'domain/AppComponents/IconButtons';

import { TextButton } from 'library/CompositeComponents/button/Buttons';
import SimpleGridLayout from 'library/CompositeComponents/grid-layout/SimpleGridLayout';
import ObjectUtils from 'util/ObjectUtils';
import DataSourceSelector from './DataSourceSelector';

const STYLES = (theme) => ({
  addDataSourceButton: {
    marginLeft: 'auto',
  },
  dataSourcePaper: {
    minHeight: theme.spacing(8),
  },
  listSubheader: {
    lineHeight: 1.5,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  draggableListItemDiv: {
    '&:active': {
      cursor: 'grabbing',
      backgroundColor: theme.palette.grey[300],
    },
    cursor: 'grab',
    width: '100%',
  },
  draggableListItem: {
    height: '100%',
    width: '90%',
  },
});

export interface DataSourceType {
  nodeId: number;
  id: number;
  pathName: string[];
  resourceTypeId?: number;
  resourceId?: number;
}

type Props = {
  classes: any;
  treeTypePreset: string;
  propertyPreset: string[];
  dataSources: DataSourceType[];
  dataSourceCallback: (e: any) => void;
  multiple: boolean;
  disableDateRange: boolean;
};

const DataSourceList: React.VFC<Props> = ({
  classes,
  treeTypePreset,
  propertyPreset,
  dataSources,
  dataSourceCallback,
  multiple,
  disableDateRange,
}: Props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [editData, setEditData] = useState<DataSourceType>(undefined);

  const openDialog = () => {
    setShowDialog(true);
  };

  const onEditSource = (source: DataSourceType) => {
    openDialog();
    setEditData(source);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onAddSources = (sources: DataSourceType) => {
    let newSources = dataSources.concat(sources);
    if (editData) {
      const index = dataSources.findIndex(
        (item) => item.nodeId === editData.nodeId
      );
      newSources.splice(index, 1);
      setEditData(undefined);
    }
    newSources = newSources.filter(
      (obj, pos, arr) => arr.map((source) => source.id).indexOf(obj.id) === pos
    );
    dataSourceCallback(newSources);
    onCloseDialog();
    return newSources;
  };

  const onDataSourceRemove = (source) => {
    const newArray = [...dataSources];
    const index = dataSources.findIndex(
      (item) => item.nodeId === source.nodeId
    );
    newArray.splice(index, 1);
    dataSourceCallback(newArray);
  };

  const renderDialog = () => (
    <DataSourceSelector
      onCloseDialog={onCloseDialog}
      showDialog={showDialog}
      treeTypePreset={treeTypePreset}
      propertyPreset={propertyPreset || []}
      editData={editData}
      onSelectNodes={onAddSources}
      title="Select Data Source"
      multiple={multiple}
      disableDateRange={disableDateRange}
    />
  );

  /* When a user drags a data source into another position this code
   * will assign the correct heirarchical value to that item and then
   * sort the list of items so that they appear in the correct order on
   * the next render
   */
  const handleLayoutChange = (layout) => {
    const newDataSources = ObjectUtils.deepClone(dataSources);
    // layout will contain all items in an affected list section
    layout.forEach((item) => {
      for (let i = 0; i < newDataSources.length; i += 1) {
        // parse the string value for the items nodeId
        const itemKey = parseInt(item.i, 10);
        // find the correct data source from the current layout change
        if (itemKey === parseInt(newDataSources[i].nodeId, 10)) {
          // assign a new layout sequence based on vertical position in list
          newDataSources[i].sequence = item.y + 1 + 1000 * layout.sectionIndex;
          break;
        }
      }
    });
    // sort the freshly assigned values from lowest to highest
    newDataSources.sort((a, b) => a.sequence - b.sequence);
    dataSourceCallback(newDataSources);
  };

  const renderListItems = (items) => {
    const sourceList = [];
    for (let i = 0; i < items.length; i += 1) {
      sourceList.push(
        <div
          key={items[i].nodeId}
          data-grid={{ x: 0, y: i, w: 1, h: 1, isResizable: false }}
          className={classes.draggableListItemDiv}
        >
          <ListItem key={items[i].nodeId} className={classes.draggableListItem}>
            <DragIndicator style={{ color: 'grey', paddingRight: '10px' }} />
            <ListItemText primary={items[i].name} />
            <ListItemIcon>
              <EditIconButton onClick={() => onEditSource(items[i])} />
              <DeleteIconButton onClick={() => onDataSourceRemove(items[i])} />
            </ListItemIcon>
          </ListItem>
        </div>
      );
    }
    return sourceList;
  };

  const renderListSections = (sections) =>
    sections.map((section, index) => (
      <div key={section.pathName}>
        <ListSubheader key={section.pathName} className={classes.listSubheader}>
          {section.pathName}
        </ListSubheader>
        <SimpleGridLayout
          isStatic={false}
          cols={1}
          rowHeight={40}
          width={600}
          onLayoutChange={(layout) => {
            const newLayout = layout;
            newLayout.sectionIndex = index;
            handleLayoutChange(newLayout);
          }}
        >
          {renderListItems(section.items)}
        </SimpleGridLayout>
        {index < sections.length - 1 ? (
          <Divider className={classes.divider} />
        ) : undefined}
      </div>
    ));

  const renderList = () => {
    const sections = [];
    dataSources.forEach((source) => {
      const itemSection = [...source.pathName];
      itemSection.pop();
      if (treeTypePreset === 'propertiesByLocation') {
        itemSection.pop(); // Pop the site device if using sensors
      }
      const itemSectionTwo = itemSection.join(' / ');
      if (!sections.some((section) => itemSectionTwo === section.pathName)) {
        sections.push({ pathName: itemSectionTwo, items: [] });
      }
      const section = sections.find((item) => item.pathName === itemSectionTwo);
      section.items.push(source);
    });
    return renderListSections(sections);
  };

  const renderAddSourceButton = () => {
    if (!multiple && dataSources.length > 0) {
      return null;
    }
    return (
      <Grid item className={classes.addDataSourceButton}>
        <TextButton
          translationKey="dashboards.addDataSource"
          startIcon={<Add />}
          onClick={() => openDialog()}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={0.5} alignItems="center">
      {renderDialog()}
      <Grid item>
        <TypographyWithTranslation
          variant="subtitle1"
          translationKey="dashboards.dataSources"
        />
      </Grid>
      {renderAddSourceButton()}

      <Grid item xs={12}>
        <Paper className={classes.dataSourcePaper}>{renderList()}</Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(STYLES)(DataSourceList);
