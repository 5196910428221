import { get } from 'util/WebRequest';

const USER_PREFERENCE_SERVICE = 'UserPreferenceService';

class UserPreferenceService {
  /**
   * Returns array of UserPreference label: name, value: userpreferenceId pairs,
   * sorted by name with empty names removed.
   */
  static async getUserPreferences(userPreferenceTypeId) {
    try {
      const response = await get(USER_PREFERENCE_SERVICE, {
        operation: 2,
        preferencetypeid: userPreferenceTypeId,
      });

      // Transform the response into a list of name, id pairs
      const savedPlotOptions = response.data.preferencelist
        .map((obj) => ({
          label: JSON.parse(obj.userPreference).name,
          value: obj.userPreferenceId,
          published: obj.published,
        }))
        .filter(
          (userPreference) =>
            userPreference.label && userPreference.label !== ''
        )
        .sort((a, b) => {
          const aName = a.label.toLowerCase();
          const bName = b.label.toLowerCase();

          if (aName < bName) return -1;
          if (bName < aName) return 1;
          return 0;
        });

      return Promise.resolve(savedPlotOptions);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Returns a single UserPreference label: name, value: userpreferenceId, */
  static async getUserPreference(userPreferenceTypeId, userPreferenceId) {
    try {
      const response = await get(USER_PREFERENCE_SERVICE, {
        operation: 4,
        preferencetypeid: userPreferenceTypeId,
        userpreferenceid: userPreferenceId,
      });
      return Promise.resolve(response.data.preferencelist);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default UserPreferenceService;
