import { useState, useEffect, ReactNode } from 'react';
import MaterialSnackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { Close as CloseIcon, OpenInNew as OpenInNewIcon } from '@onc/icons';
import Button from '../button/Button';
import IconButton from '../icon-button/IconButton';

const styles = {
  error: {
    backgroundColor: 'error.dark',
  },
  info: {
    backgroundColor: 'primary.dark',
  },
  actionSnackbar: {
    backgroundColor: 'success.dark',
  },
};

type SnackbarProps = {
  duration?: number;
  message: string | Record<string, unknown>;
  variant: 'info' | 'error' | 'actionSnackbar' | 'loading';
  action?: () => void;
  onClose?: () => void | undefined;
  initialActionButton?: ReactNode;
  anchorOrigin?: SnackbarOrigin;
};

const Snackbar = ({
  duration = null,
  message,
  variant,
  action = undefined,
  onClose = undefined,
  initialActionButton = undefined,
  anchorOrigin = undefined,
}: SnackbarProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [actionButton, setActionButton] = useState<ReactNode>(undefined);

  useEffect(() => {
    if (action && !initialActionButton) {
      setActionButton(
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={action}
          endIcon={<OpenInNewIcon />}
          translationKey="common.buttons.open"
          aria-label="open"
        />
      );
    } else {
      setActionButton(initialActionButton);
    }
  }, [action, initialActionButton]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const renderSnackbarContent = () => {
    if (variant === 'error') {
      return (
        <SnackbarContent
          id="onc-error-snackbar"
          sx={styles.error}
          message={String(message)}
        />
      );
    }

    if (variant === 'info') {
      return (
        <SnackbarContent
          id="onc-info-snackbar"
          sx={styles.info}
          message={String(message)}
        />
      );
    }

    if (variant === 'actionSnackbar') {
      return (
        <SnackbarContent
          id="onc-action-snackbar"
          sx={styles.actionSnackbar}
          message={String(message)}
          action={
            <>
              {actionButton}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      );
    }

    if (variant === 'loading') {
      return (
        <SnackbarContent
          id="onc-loading-snackbar"
          sx={styles.info}
          message={String(message)}
        />
      );
    }

    return null;
  };

  // use passed in onClose handler, default to standard behaviour if none provided
  const onCloseHandler = onClose !== undefined ? onClose : handleClose;

  if (variant === 'loading') {
    return (
      <MaterialSnackbar
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
        open={open}
        autoHideDuration={duration}
      >
        {renderSnackbarContent()}
      </MaterialSnackbar>
    );
  }
  return (
    <MaterialSnackbar
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'left',
        }
      }
      open={open}
      autoHideDuration={duration}
      onClose={onCloseHandler}
    >
      {renderSnackbarContent()}
    </MaterialSnackbar>
  );
};

export default Snackbar;
