import { useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import { Moment } from 'moment';
import { ErrorSnackbar } from '@onc/composite-components';
import { CircularProgress } from 'base-components';
import TokenUtils from 'domain/AppComponents/Dashboard/services/TokenUtils';
import { WidgetTitle } from 'domain/AppComponents/Dashboard/Titles';
import {
  WidgetConfigError,
  getWidgetConfigErrorMessage,
} from 'domain/AppComponents/Dashboard/widget-error/WidgetConfigError';
import ArchiveFileService from 'domain/services/ArchiveFileService';
import AudioPlayer from 'library/BaseComponents/AudioPlayer';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';
import { FlacFile } from './AudioPlayerWidget';

export type Location = [
  {
    stationCode: string;
    deviceCategoryCode: string;
  },
];
type AudioPlayerWidgetDisplayProps = {
  audioFormat: string;
  classes: {
    circularProgress: string;
    contentDiv: string;
  };
  clipSelectorValue: 'latestClip' | 'timeRange' | string;
  dataSourceType: string;
  device: {
    deviceCategoryCode: string;
    deviceCode: string;
    deviceId: number;
    name: string;
  };
  endDate: Moment;
  isAutoRefreshing: boolean;
  isBroadcasting: boolean;
  locations: Location;
  loopPlayback: boolean;
  showTitle: boolean;
  startDate: Moment;
  title: string;
  widgetId: string;
  flacData?: FlacFile;
  latestSpectrogramFlac?: string;
};

type errorObject = {
  condition: string;
  data: any;
};

type latestFileData = {
  file: string;
  title: string;
};

type invalidField = any[];

const AudioPlayerWidgetDisplay: React.FC<AudioPlayerWidgetDisplayProps> = ({
  audioFormat,
  classes,
  clipSelectorValue,
  dataSourceType,
  device,
  endDate,
  isAutoRefreshing,
  isBroadcasting,
  locations,
  loopPlayback,
  showTitle,
  startDate,
  title,
  widgetId,
  flacData = undefined,
  latestSpectrogramFlac = undefined,
}) => {
  const [error, setError] = useState<errorObject | null>(null);
  const [fileNames, setFileNames] = useState<latestFileData[] | []>([]);
  const [isAutoRefreshingVar, setIsAutoRefreshingVar] = useState<any | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  const AUTO_REFRESH_INTERVAL_MILLIS = 60000;

  const fileNameFormatter = (fileObject) =>
    `${fileObject.archiveLocation}/${fileObject.path}/${fileObject.filename}`;

  const getInvalidFields = useCallback(() => {
    const invalidFields: invalidField = [];
    if (dataSourceType === 'location' && !locations.length) {
      invalidFields.push('Location');
    }
    if (dataSourceType === 'device' && !device) {
      invalidFields.push('Device');
    }
    if (clipSelectorValue === 'timeRange' && !startDate) {
      invalidFields.push('Start Date');
    }
    if (clipSelectorValue === 'timeRange' && !endDate) {
      invalidFields.push('End Date');
    }
    return invalidFields;
  }, [
    clipSelectorValue,
    dataSourceType,
    device,
    endDate,
    locations,
    startDate,
  ]);

  const fetchLatestAudioFile = useCallback(async () => {
    const dateFrom = new Date(0).toISOString();
    const dateTo = new Date().toISOString();
    try {
      const response = await ArchiveFileService.getFileListByDevice(
        device.deviceCode,
        dateFrom,
        dateTo,
        audioFormat,
        undefined,
        null,
        1,
        true
      );
      if (response && response.data.files.length > 0) {
        const latestFile = [
          {
            title: `${response.data.files[0].filename}`,
            file: `${Environment.getADProxyUrl()}${fileNameFormatter(
              response.data.files[0]
            )}`,
          },
        ];
        setFileNames(latestFile);
        setLoading(false);
        return Promise.resolve();
      }
      setError({ condition: 'noData', data: {} });
      setLoading(false);
      return Promise.resolve();
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  }, [audioFormat, device]);

  const fetchAudioFilesOnInterval = useCallback(() => {
    fetchLatestAudioFile()
      .then(() => {
        if (isAutoRefreshing) {
          // Set auto refresh interval
          const isAutoRefreshingInterval = setInterval(
            fetchLatestAudioFile,
            AUTO_REFRESH_INTERVAL_MILLIS
          );
          setIsAutoRefreshingVar(isAutoRefreshingInterval);
        }
        return Promise.resolve();
      })
      .catch((err) => {
        const updatedError = TokenUtils.getTokenError(err);
        setLoading(false);
        setError({ condition: 'service', data: updatedError });
      });
  }, [fetchLatestAudioFile, isAutoRefreshing]);

  const getWidgetDataErrorMessage = (err) => {
    let dataSourceName = dataSourceType;
    const userLoggedIn = Environment.isUserLoggedIn();
    if (dataSourceName === 'deviceId') {
      dataSourceName = 'device';
    }
    let message;
    switch (err.condition) {
      case 'config':
        message = getWidgetConfigErrorMessage(err.data);
        break;
      case 'service':
        message = 'A service Error has occurred';
        message += userLoggedIn ? `: ${err.data}` : '';
        break;
      default:
        message = `No data found for this ${dataSourceName}`;
        message +=
          clipSelectorValue === 'timeRange'
            ? ` from UTC ${DateFormatUtils.formatDate(
                startDate,
                'full'
              )} to UTC ${DateFormatUtils.formatDate(endDate, 'full')}`
            : '';
        break;
    }
    return message;
  };

  const fetchDateRangeAudioFiles = useCallback(async () => {
    const dateFrom = DateFormatUtils.formatDate(startDate, 'ISO8601_EXTENDED');
    const dateTo = DateFormatUtils.formatDate(endDate, 'ISO8601_EXTENDED');
    try {
      const response = await ArchiveFileService.getFileListByDevice(
        device.deviceCode,
        dateFrom,
        dateTo,
        audioFormat
      );
      const filesMatchingFormat = response.data.files.filter((file) =>
        // might need more robust checking method?
        file.filename.includes(`.${audioFormat}`)
      );
      const audioFileNames = filesMatchingFormat.map((file) => ({
        title: `${file.filename}`,
        file: `${Environment.getADProxyUrl()}${fileNameFormatter(file)}`,
      }));
      let errorData: any = null;
      if (audioFileNames.length <= 0) {
        errorData = { condition: 'noData', data: {} };
      }
      setFileNames(audioFileNames);
      setLoading(false);
      setError(errorData);
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }, [audioFormat, device, endDate, startDate]);

  const fetchAudioFilesForDateRange = useCallback(() => {
    fetchDateRangeAudioFiles().catch((err) => {
      const updatedError = TokenUtils.getTokenError(err);
      setLoading(false);
      setError({ condition: 'service', data: updatedError });
    });
  }, [fetchDateRangeAudioFiles]);

  const fetchAudioFilesForLocation = useCallback(async () => {
    const location = locations[0];
    const isLatest = clipSelectorValue === 'latestClip';
    const rowLimit = isLatest ? 1 : undefined;
    try {
      const response = await ArchiveFileService.getFileListByLocation(
        location.stationCode,
        location.deviceCategoryCode,
        isLatest ? undefined : startDate,
        isLatest ? undefined : endDate,
        audioFormat,
        rowLimit,
        isLatest
      );
      const byLocationFileNames = response.data.files.map((record) => ({
        title: `${record.filename}`,
        file: `${Environment.getADProxyUrl()}${record.archiveLocation}/${
          record.path
        }/${record.filename}`,
      }));
      if (byLocationFileNames.length > 0) {
        if (isLatest) {
          setFileNames([byLocationFileNames[0]]);
          setLoading(false);
          return;
        }
        setFileNames(byLocationFileNames);
        setLoading(false);
        return;
      }
      let errorData: any = null;
      errorData = { condition: 'noData', data: {} };
      setFileNames(byLocationFileNames);
      setLoading(false);
      setError(errorData);
    } catch (err: any) {
      const updatedError = TokenUtils.getTokenError(err);
      setError({ condition: 'service', data: updatedError });
      setLoading(false);
    }
  }, [audioFormat, clipSelectorValue, endDate, locations, startDate]);

  useEffect(() => {
    setError(null);
    const invalidFields = getInvalidFields();
    if (invalidFields.length > 0) {
      setLoading(false);
      setError({ condition: 'config', data: invalidFields });
    } else {
      setLoading(true);
      if (dataSourceType === 'device' && device) {
        if (clipSelectorValue === 'latestClip') {
          fetchAudioFilesOnInterval();
        } else {
          fetchAudioFilesForDateRange();
        }
      } else {
        fetchAudioFilesForLocation();
      }
    }
  }, [
    clipSelectorValue,
    dataSourceType,
    device,
    fetchAudioFilesForDateRange,
    fetchAudioFilesForLocation,
    fetchAudioFilesOnInterval,
    getInvalidFields,
  ]);

  useEffect(() => {
    // Note: Currently there is no auto-refresh toggle on the config. This was removed in DMAS-49269.
    if (isAutoRefreshingVar !== null) {
      clearInterval(isAutoRefreshingVar);
    }
  });

  const renderAudioPlayer = () => {
    if (fileNames.length > 0) {
      return (
        <div className="audio">
          <AudioPlayer
            isBroadcasting={isBroadcasting}
            playerId={`audioPlayerId-${widgetId}`}
            playlist={fileNames}
            isLooping={loopPlayback}
            flacData={flacData}
            latestSpectrogramFlac={latestSpectrogramFlac}
          />
        </div>
      );
    }
    return null;
  };

  const renderTitle = () => {
    if (showTitle) {
      return <WidgetTitle titleText={title} />;
    }
    return null;
  };

  const renderDisplayContent = () => {
    if (error) {
      const message = getWidgetDataErrorMessage(error);
      return (
        <>
          <ErrorSnackbar message={message} />
          <WidgetConfigError message={message} />
        </>
      );
    }
    return renderAudioPlayer();
  };

  if (loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress color="primary" />
      </div>
    );
  }
  return (
    <>
      {renderTitle()}
      <div className={classes.contentDiv}>{renderDisplayContent()}</div>
    </>
  );
};
export default AudioPlayerWidgetDisplay;
