import JiraLink from 'domain/AppComponents/link/JiraLink';

export type CableListing = {
  extensionId?: number;
  comment?: string;
  cableName?: string;
  cableType?: any;
  connectorType1?: any;
  connectorType2?: any;
  length?: string;
  colorBand?: string;
  characteristics?: string;
  jira?: any;
  modifyBy?: any;
  modifyDate?: string;
};

export type CableListingRow = {
  cableId?: number;
  cableName?: string;
  cableTypeId?: number;
  cableTypeName?: string;
  connectorTypeId1?: number;
  connectorTypeName1?: string;
  connectorTypeId2?: number;
  connectorTypeName2?: string;
  length?: string;
  colorBand?: string;
  characteristics?: string;
  jira?: any;
  modifyBy?: string;
  modifyDate?: string;
};

const getJira = (jiraId) => {
  if (!jiraId) {
    return '';
  }
  return <JiraLink jiraId={jiraId} />;
};

const formatModifyDate = (modifyDate: string) => {
  const strArr = modifyDate.split('T');
  strArr[1] = strArr[1].substring(0, 8);
  return `${strArr[0]} ${strArr[1]}`;
};

export const transformCableListingToRows = (
  cableListings: CableListing[]
): CableListingRow[] =>
  cableListings.map((cableListing) => ({
    cableId: cableListing.extensionId,
    cableName: cableListing.comment,
    cableTypeId:
      cableListing.cableType === undefined
        ? undefined
        : cableListing.cableType.cableTypeId,
    cableTypeName:
      cableListing.cableType === undefined
        ? undefined
        : cableListing.cableType.name,
    connectorTypeId1:
      cableListing.connectorType1 === undefined
        ? undefined
        : cableListing.connectorType1.connectorTypeId,
    connectorTypeName1:
      cableListing.connectorType1 === undefined
        ? undefined
        : cableListing.connectorType1.name,
    connectorTypeId2:
      cableListing.connectorType2 === undefined
        ? undefined
        : cableListing.connectorType2.connectorTypeId,
    connectorTypeName2:
      cableListing.connectorType2 === undefined
        ? undefined
        : cableListing.connectorType2.name,
    length: cableListing.length,
    colorBand: cableListing.colorBand,
    characteristics: cableListing.characteristics,
    jira: getJira(cableListing.jira),
    modifyBy: `${cableListing.modifyBy.firstname} ${cableListing.modifyBy.lastname}`,
    modifyDate: formatModifyDate(cableListing.modifyDate),
  }));
