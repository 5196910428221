import moment from 'moment';

class DateUtils {
  static formatDateAsString(date) {
    if (
      (date instanceof Date && !isNaN(date.getTime())) ||
      (date instanceof moment && date.isValid())
    ) {
      return this.formatISOString(date.toISOString());
    }
    return null;
  }

  static formatISOString(dateString) {
    const regexISO = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (regexISO.test(dateString)) {
      return dateString.slice(0, 19).replace('T', ' ');
    }
    return null;
  }

  static parseMoment(datestr) {
    return moment(datestr);
  }

  static formatDisplayDate(date) {
    if (date instanceof moment) {
      return date.format('MMM Do YYYY');
    }
    if (date instanceof Date && !isNaN(date.getTime())) {
      // TODO: Support Date
      return undefined;
    }
    return undefined;
  }

  static getDurationInSeconds(startDate, endDate) {
    // Parse the date strings into Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    const durationMillis = end.getTime() - start.getTime();

    if (durationMillis < 0) {
      throw new Error(
        'End date must be greater than or equal to the start date.'
      );
    }

    return Math.floor(durationMillis / 1000);
  }

  static getDurationBetweenDates(startDate, endDate) {
    // Parse the date strings into Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    let durationMillis = end.getTime() - start.getTime();

    if (durationMillis < 0) {
      throw new Error(
        'End date must be greater than or equal to the start date.'
      );
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(durationMillis / (1000 * 60 * 60));
    durationMillis %= 1000 * 60 * 60;

    const minutes = Math.floor(durationMillis / (1000 * 60));
    durationMillis %= 1000 * 60;

    const seconds = Math.floor(durationMillis / 1000);

    // Convert values to string with padded zeros if needed
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = seconds.toString().padStart(2, '0');

    // Return formatted string, omitting hours if they're 0
    return hours > 0
      ? `${hours}:${minutesStr}:${secondsStr}`
      : `${minutesStr}:${secondsStr}`;
  }

  // Takes a date object and returns a relative time-ago as a string
  static timeAgo = (prevDate) => {
    const prevDateTime = prevDate.getTime();
    const diff = new Date().getTime() - prevDateTime;
    let val;
    const second = 1000;
    const minute = 60 * second;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    switch (true) {
      case diff < second:
        return 'Just now';
      case diff < minute:
        val = Math.round(diff / second);
        return `${val} ${val > 1 ? 'seconds' : 'second'} ago`;
      case diff < hour:
        val = Math.round(diff / minute);
        return `${val} ${val === 1 ? 'minute' : 'minutes'} ago`;
      case diff < day:
        val = Math.round(diff / hour);
        return `${val} ${val === 1 ? 'hour' : 'hours'} ago`;
      case diff < month:
        val = Math.round(diff / day);
        return `${val} ${val === 1 ? 'day' : 'days'} ago`;
      case diff < year:
        val = Math.round(diff / month);
        return `${val} ${val === 1 ? 'month' : 'months'} ago`;
      case diff > year:
        val = Math.round(diff / year);
        return `${val} ${val === 1 ? 'year' : 'years'} ago`;
      default:
        return 'Invalid Date';
    }
  };

  /**
   * @param {string} a - A string representing a date
   * @param {string} b - A string representing a date
   * @returns {number} The number of milliseconds calculated from 'a' subtracted
   *   from 'b'
   */
  static isDateBefore = (a, b) => new Date(b) - new Date(a);

  /**
   * @param {string} a - A string representing a date
   * @param {string} b - A string representing a date
   * @returns {number} The number of milliseconds calculated from 'b' subtracted
   *   from 'a'
   */
  static isDateAfter = (a, b) => new Date(a) - new Date(b);

  static findMidpointDate = (isoDate1, isoDate2) => {
    const date1 = new Date(isoDate1);
    const date2 = new Date(isoDate2);
    // find middle point time
    const midpointTime = (date1.getTime() + date2.getTime()) / 2;
    // convert midpoint time back to iso date string
    const midpointDate = new Date(midpointTime).toISOString();

    return midpointDate;
  };
}

export default DateUtils;
