import DataPreview from 'domain/Apps/data-preview-management/DataPreview';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

const DATA_PREVIEW_MANAGEMENT_SERVICE = 'DataPreviewManagementService';

class DataPreviewManagementService {
  static getDataPreviewInfo = () =>
    get(
      DATA_PREVIEW_MANAGEMENT_SERVICE,
      {
        operation: 5,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static createDataPreview(dataPreview: DataPreview) {
    const parameters = {
      operation: 1,
      datapreviewrule: JSON.stringify({
        dataPreviewRuleId: dataPreview.dataPreviewRuleId,
        description: dataPreview.description,
        ruleString: dataPreview.ruleString,
        keepSummaryTab: dataPreview.keepSummaryTab,
        enabled: dataPreview.enabled,
        modifyBy: dataPreview.modifyBy,
        modifyDate: dataPreview.modifyDate,
        createdDate: dataPreview.createdDate,
        modifyByString: dataPreview.modifyByString,
        clauses: dataPreview.clauses,
      }),
    };
    return post(DATA_PREVIEW_MANAGEMENT_SERVICE, parameters).then((response) =>
      parseDmasAPIResponse(response)
    );
  }

  static updateDataPreview(dataPreview: DataPreview) {
    const parameters = {
      operation: 2,
      datapreviewrule: JSON.stringify({
        dataPreviewRuleId: dataPreview.dataPreviewRuleId,
        description: dataPreview.description,
        ruleString: dataPreview.ruleString,
        keepSummaryTab: dataPreview.keepSummaryTab,
        enabled: dataPreview.enabled,
        modifyBy: dataPreview.modifyBy,
        modifyDate: dataPreview.modifyDate,
        createdDate: dataPreview.createdDate,
        modifyByString: dataPreview.modifyByString,
        clauses: dataPreview.clauses,
      }),
    };
    return post(DATA_PREVIEW_MANAGEMENT_SERVICE, parameters).then((response) =>
      parseDmasAPIResponse(response)
    );
  }

  static deleteDataPreview(dataPreviewRuleId: number) {
    const parameters = {
      operation: 3,
      datapreviewruleid: `${dataPreviewRuleId}`,
    };
    return post(DATA_PREVIEW_MANAGEMENT_SERVICE, parameters).then((response) =>
      parseDmasAPIResponse(response)
    );
  }
}

export default DataPreviewManagementService;
