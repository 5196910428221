import { Grid } from 'base-components';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';

const ChartWidgetReadonlyDateFilters = () => (
  <Grid container spacing={1}>
    <Grid item xs={6}>
      <FormDateTimePicker
        translationKey="common.datepickers.startDate"
        fullWidth
        name="startDate"
        readOnly
      />
    </Grid>
    <Grid item xs={6}>
      <FormDateTimePicker
        translationKey="common.datepickers.endDate"
        fullWidth
        name="endDate"
        readOnly
      />
    </Grid>
  </Grid>
);

export default ChartWidgetReadonlyDateFilters;
