import { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckCircle, RadioButtonUnchecked } from '@onc/icons';
import { Chip } from 'base-components';

class FilterChip extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: false,
  };

  render() {
    const { label, onClick, selected } = this.props;
    return (
      <Chip
        label={label}
        color="default"
        variant={selected ? 'default' : 'outlined'}
        icon={selected ? <CheckCircle /> : <RadioButtonUnchecked />}
        onClick={onClick}
        aria-pressed={selected}
      />
    );
  }
}

export default FilterChip;
