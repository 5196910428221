import * as React from 'react';
import type { PropsWithChildren } from 'react';
import { Draggable } from '@hello-pangea/dnd';

interface Props {
  id: string;
  index: number;
  containerClass?: string;
  dragHandleClass?: string;
  isDragDisabled?: boolean;
  HandleComponent: React.JSX.Element;
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  display: 'flex',
  alignItems: 'center',

  // styles we need to apply on draggables
  ...draggableStyle,
});

// The DraggableListItem component is a drag-and-drop sortable item,
// utilizing the useSortable hook from the dnd-kit library.
export default function DraggableListItem({
  children = undefined,
  id,
  index,
  containerClass = '',
  dragHandleClass = '',
  isDragDisabled = false,
  HandleComponent,
}: PropsWithChildren<Props>) {
  // The DraggableListItem component provides its context for children
  // and assigns ref and styles to facilitate dragging and dropping.
  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={containerClass}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <div
            aria-label="drag-handle"
            {...provided.dragHandleProps}
            className={dragHandleClass}
          >
            {HandleComponent}
          </div>
          {children}
        </div>
      )}
    </Draggable>
  );
}
