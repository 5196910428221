import { Component } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { InputLabel } from 'base-components';
import FilterChip from 'library/CompositeComponents/chips/FilterChip';

class FilterChipGroup extends Component {
  static propTypes = {
    label: PropTypes.string,
    filterList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.node.isRequired,
      })
    ).isRequired,
    activeFilters: PropTypes.arrayOf(PropTypes.node).isRequired,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
  };

  isFiltered = (value) => {
    const { activeFilters } = this.props;
    return activeFilters.includes(value);
  };

  renderFilters = () => {
    const { filterList, onClick } = this.props;
    return filterList.map((filter) => (
      <FilterChip
        label={filter.label}
        selected={this.isFiltered(filter.value)}
        onClick={() => onClick(filter.value)}
      />
    ));
  };

  render() {
    const { label } = this.props;
    return (
      <Box display="block" flexWrap="wrap">
        <InputLabel shrink>{label}</InputLabel>
        <Box display="flex" flexWrap="wrap">
          {this.renderFilters()}
        </Box>
      </Box>
    );
  }
}

export default FilterChipGroup;
