import { FC, useContext } from 'react';
import { FabProps } from '@mui/material';
import { ControlPosition } from 'leaflet';
import { useMap } from 'react-leaflet';
import Control from './Control';
import MapFAB from './MapFAB';
import { MaterialControlProps } from './MaterialControlProps';
import MapContext from '../MapContext';

export interface MapFABControlProps
  extends Omit<FabProps, 'ref'>,
    MaterialControlProps {
  /**
   * TooltipProps Props to forwards to the Tooltip. See
   * https://mui.com/material-ui/api/tooltip/
   */
  TooltipProps: any;
  /** OnClick the function to call when this button is clicked */
  onClick: (e: any) => void;
}
/**
 * A floating action button that will be rendered as a leaflet control
 * (https://leafletjs.com/reference.html#control)
 */
const MapFABControl: FC<MapFABControlProps> = ({
  options = { position: 'topleft' as ControlPosition },
  prepend = false,
  ...rest
}) => {
  const { mapId } = useContext(MapContext);
  const map = useMap();
  return (
    <Control position={options.position} prepend={prepend}>
      {/* @ts-expect-error It's fine to allow this to pass down */}
      <MapFAB {...rest} mapId={mapId} map={map} />
    </Control>
  );
};

export default MapFABControl;
