import { parseDmasAPIResponse, get } from 'util/WebRequest';

class SeaTubeBroadAnnotationService {
  /**
   * Phase one call to start a broad search, excluding search tree nodes
   *
   * @param {object} params
   * @param {string} params.filterText - Comment field contents
   * @param {number} params.taxonomyId - Id of selected taxonomy
   * @param {number} params.taxonIds - Ids of selected taxons (phase one is only
   *   one selected taxon)
   * @returns {object} SearchInfo
   * @returns {number} SearchInfo.searchHdrId - the searchHdrId to use for
   *   polling and exporting
   * @returns {Array} SearchInfo.generatedSearches - searchIds that were
   *   generated
   * @returns {string} SearchInfo.generator - the generator of the searches
   */
  static startBroadSearch = async (params) => {
    const getSearchesParams = {
      operation: 1,
      ...params,
    };

    const response = await get('seatubeV3/broadannotations', getSearchesParams);
    return parseDmasAPIResponse(response);
  };

  /**
   * Phase one call to check the status of the current broad search
   *
   * @param {number} searchHdrId - The broad search header id
   * @returns {Array} SubsearchResults - each entry will be an array of a
   *   subsearch's results
   */
  static checkStatus = async (searchHdrId) => {
    const pollSearchesParams = { operation: 2, searchHdrId };

    const response = await get(
      'seatubeV3/broadannotations',
      pollSearchesParams
    );
    return parseDmasAPIResponse(response);
  };

  /**
   * Phase one call to start an export of a broad search's results
   *
   * @param {object} params
   * @param {number} params.searchHdrId - Id of the search to export
   * @param {boolean} params.includeSpecialCharacters - Boolean for special
   *   characters
   * @param {string} params.format - Either csv or json
   * @returns The raw data of the export
   */
  static startExport = async (params) => {
    const startParams = {
      operation: 3,
      ...params,
    };

    const response = await get('seatubeV3/broadannotations', startParams);

    return response.data.message;
  };

  /**
   * Call to poll an export currently in progress
   *
   * @param {object} params
   * @param {number} params.searchHdrId - Id of the search to export
   * @returns The raw data of the export
   */
  static pollExport = async (params) => {
    const pollParams = {
      operation: 4,
      ...params,
    };

    const response = await get('seatubeV3/broadannotations', pollParams, {
      responseType: 'blob',
    });

    const { type } = response.data;

    return type === 'application/json'
      ? 'Export is in progress.'
      : response.data;
  };
}

export default SeaTubeBroadAnnotationService;
