import { ChangeEvent, memo, useEffect, useState } from 'react';
import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Moment } from 'moment';
import { DatePicker, type DropdownOption } from 'base-components';
import StepPartCruiseAndDiveSelector from 'domain/AppComponents/seatube/wizards/chat-log-ingestion/step-parts/StepPartCruiseAndDiveSelector';
import { CruiseJSON } from 'domain/services/CruiseService';
import { DiveJSON } from 'domain/services/DiveListingService';
import FileSelector from 'library/CompositeComponents/file-selector/FileSelector';
import StepHeader from 'library/CompositeComponents/stepper/step-parts/StepHeader';
import IngestionUtil from '../IngestionUtil';

const styles = () =>
  createStyles({
    stepDiv: {
      minWidth: '600px',
    },
  });

interface Props extends WithStyles<typeof styles> {
  date?: Moment | null;
  cruise?: number;
  cruises?: CruiseJSON[];
  dive?: number;
  dives?: DiveJSON[];
  file?: File;
  onFileChange: (file: File | undefined) => void;
  onDateChange: (date: Moment | null) => void;
  onCruiseChange: (id: number | undefined) => void;
  onDiveChange: (id: number | undefined) => void;
}

const ChatLogInformationStep: React.VFC<Props> = ({
  date = undefined,
  cruise = undefined,
  cruises = undefined,
  dive = undefined,
  dives = undefined,
  file = undefined,
  onFileChange,
  onDateChange,
  onCruiseChange,
  onDiveChange,
  classes,
}) => {
  const [cruiseOptions, setCruiseOptions] = useState<
    DropdownOption[] | undefined
  >(undefined);
  const [diveOptions, setDiveOptions] = useState<DropdownOption[] | undefined>(
    undefined
  );

  const sortByLabel = (a: DropdownOption, b: DropdownOption) => {
    if (a.label === b.label) return 0;
    return a.label < b.label ? -1 : 1;
  };

  useEffect(
    () => {
      if (dives && cruise && file) {
        const sortedDives = dives
          .map(({ referenceDiveId, diveId }) => ({
            label: referenceDiveId,
            value: diveId,
            key: diveId.toString(),
          }))
          .sort(sortByLabel);
        setDiveOptions(sortedDives);
        if (sortedDives.length === 1 && !dive) {
          // Selects the option if only one option
          onDiveChange(sortedDives[0].value);
        }
      } else {
        setDiveOptions(undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file, cruise, dives]
  );

  useEffect(
    () => {
      if (cruises) {
        const sortedCruises = cruises
          .map(({ cruiseName, cruiseId }) => ({
            label: cruiseName,
            value: cruiseId,
            key: cruiseId.toString(),
          }))
          .sort(sortByLabel);
        setCruiseOptions(sortedCruises);
        // Autoselect the value when file is changed
        if (sortedCruises.length === 1 && file && file.name && !cruise) {
          onCruiseChange(sortedCruises[0].value);
        }
      } else {
        setCruiseOptions(undefined);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file, cruises, date]
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const newFile = fileList && fileList.length > 0 ? fileList[0] : file;
    onFileChange(newFile);
    if (newFile && newFile.name) {
      onCruiseChange(undefined);
      onDiveChange(undefined);
      onDateChange(IngestionUtil.getDateFromFileName(newFile.name));
    }
  };

  const handleCruiseChange = (event: ChangeEvent<HTMLInputElement>) => {
    onDiveChange(undefined);
    onCruiseChange(parseInt(event.target.value, 10));
  };

  const handleDiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    onDiveChange(parseInt(event.target.value, 10));
  };

  const handleDateChange = (newDate: Moment | null) => {
    onCruiseChange(undefined);
    onDiveChange(undefined);
    if (newDate) {
      onDateChange(newDate);
    } else {
      onDateChange(null);
    }
  };

  return (
    <>
      <div className={classes.stepDiv}>
        <StepHeader
          header="Provide Chat Log Information"
          description="Provide information about the chat log."
        />

        <FileSelector
          acceptedFileTypes="text/plain"
          onChange={handleFileChange}
          initialFileName={file ? file.name : undefined}
        />

        <DatePicker
          translationKey="common.datepickers.date"
          value={date}
          onChange={handleDateChange}
          disabled={!file}
        />

        <StepPartCruiseAndDiveSelector
          cruiseId={cruise}
          diveId={dive}
          onCruiseChange={handleCruiseChange}
          onDiveChange={handleDiveChange}
          cruiseOptions={cruiseOptions}
          diveOptions={diveOptions}
          fileName={file ? file.name : undefined}
          date={date}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(memo(ChatLogInformationStep));
