import React, { useCallback, useEffect, useState } from 'react';
import { ErrorSnackbar, InfoSnackbar } from '@onc/composite-components';
import DiverImage from 'assets/images/standard_divers_med.jpg';
import { CircularProgress } from 'base-components';
import DataPreviewWidgetConfig from 'domain/AppComponents/Dashboard/DataPreviewWidgetConfig';
import DataPreviewWidgetDisplay from 'domain/AppComponents/Dashboard/DataPreviewWidgetDisplay';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

const NO_PLOT_FOUND_IMAGE = DiverImage;
const NO_PLOT_FOUND_MESSAGE =
  'Preview not found! We expect these guys will find it soon.';

type DataPreviewWidgetProps = {
  showDisplay?: boolean;
  previewUrl?: string;
  title?: string;
  showTitle?: boolean;
  onSave: (values: any) => Promise<void>;
  onConfigFinish: () => void;
};

const DataPreviewWidget: React.FC<DataPreviewWidgetProps> = ({
  showDisplay = true,
  previewUrl = '',
  title = 'Sample Preview Plot',
  showTitle = true,
  onSave,
  onConfigFinish,
}: DataPreviewWidgetProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDataPreviewImage = useCallback(async () => {
    // Call service to get image URL
    try {
      const response = await get(previewUrl);
      const payload = parseDmasAPIResponse(response);

      if (payload.filePath) {
        setImageUrl(payload.filePath);
        setInfo(null);
        setLoading(false);
      } else {
        setImageUrl(NO_PLOT_FOUND_IMAGE);
        setInfo(NO_PLOT_FOUND_MESSAGE);
        setLoading(false);
      }
      return Promise.resolve();
    } catch (newError) {
      setError(newError);
      setLoading(false);
      throw new Error(String(newError));
    }
  }, [previewUrl]);

  useEffect(() => {
    setLoading(true);
    fetchDataPreviewImage();
  }, [fetchDataPreviewImage]);

  const handleSave = async (values: any) => {
    await onSave(values);
    onConfigFinish();
    setError(null);
    fetchDataPreviewImage();
  };

  if (loading) {
    return (
      <div>
        <CircularProgress color="primary" />
      </div>
    );
  }
  let errorComp;
  if (error) {
    errorComp = <ErrorSnackbar message={error.message} />;
  }

  let infoComp;
  if (info) {
    infoComp = <InfoSnackbar message={info} />;
  }

  if (showDisplay) {
    return (
      <>
        {infoComp}
        {errorComp}
        <DataPreviewWidgetDisplay
          showTitle={showTitle}
          title={title}
          imageUrl={imageUrl}
        />
      </>
    );
  }
  return (
    <>
      {errorComp}
      <DataPreviewWidgetConfig
        onSave={handleSave}
        onCancel={onConfigFinish}
        showTitle={showTitle}
        title={title}
        previewUrl={previewUrl}
      />
    </>
  );
};

export default DataPreviewWidget;
