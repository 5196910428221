import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from 'base-components';
import { FormDialog } from 'domain/AppComponents/dialogs/FormDialog';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const EmbedDialog = ({
  dialogTitle,
  fieldLabel,
  fieldOptions = {},
  embedCode,
  open,
  onCancel,
  onInfo,
}) => {
  const fieldRef = useRef(null);

  const onCopy = () => {
    selectAll();
    navigator.clipboard.writeText(`${embedCode}`);
    onInfo('Copied to Clipboard');
  };

  const selectAll = () => {
    fieldRef.current.select();
  };

  return (
    <FormDialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth="sm"
      confirmButtonTranslationKey="common.buttons.copyToClipboard"
      cancelButtonContent="common.buttons.cancel"
      onSubmit={onCopy}
      title={dialogTitle}
      container={document.getElementById('dashboardLayout')}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            translationKey={fieldLabel}
            translationOptions={fieldOptions}
            title="urlEmbedField"
            inputRef={fieldRef}
            value={embedCode}
            onFocus={selectAll}
            fullWidth
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

EmbedDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldOptions: PropTypes.shape({ dashboardName: PropTypes.string }),
  embedCode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onInfo: PropTypes.func.isRequired,
};

export default withSnackbars(EmbedDialog);
