import * as React from 'react';
import MaterialAutocomplete from '@mui/material/Autocomplete';
import { TranslationType, TOptions } from '@onc/i18n';
import Chip from './Chip';
import Listbox from './Listbox';
import TextField from './text/TextField';
import Typography from './text/Typography';

export type AutocompleteProps = {
  /**
   * Text field label from the json file
   * util/i18n/src/translation/en/translation.json
   */
  translationKey?: TranslationType;

  /** Options to pass in with the translation. */
  translationOptions?: TOptions;

  /**
   * Used as the name of the input when passing back the onChange. This is
   * important for form submission and handling the input data.
   */
  name: string;

  /**
   * An array of options that can be selected in the input. Each option can
   * either be a simple string or an object with a key-value pair.
   */
  options: Array<any>;

  /** The value of the input. */
  value?: any;

  /** The id of the input. */
  id?: string;

  /** CSS class name applied to the root element of the component. */
  className?: string;

  /** If true, the input will be non-interactive and appear in a disabled state. */
  disabled?: boolean;

  /**
   * If true, the input will display an error state. This is typically used to
   * indicate validation errors or other issues to the user.
   */
  error?: boolean;

  /**
   * If error true, text provided will display under the text field This is
   * typically used to indicate to the user how to remove an error
   */
  helperText?: string;

  /**
   * If true, allows the user to enter a value that is not present in the
   * options list. This is useful for autocomplete inputs where the user can
   * enter a new value.
   */
  freeSolo?: boolean;

  /** If true, extends the input to the full width of the parent container. */
  fullWidth?: boolean;

  /**
   * The value of the autocomplete input. This is used to control the input when
   * the user types.
   */
  inputValue?: string;

  /** If true, the input will display text that says "Loading...". */
  loading?: boolean;

  /** The text to display when the input is loading. */
  loadingText?: string;

  /**
   * If true, allows the selection of multiple options. Useful for creating
   * multi-select inputs.
   */
  multiple?: boolean;

  /** The text to display when there are no options. */
  noOptionsText?: string;

  /**
   * If true, the options list will be virtualized. This is beneficial for
   * performance when dealing with a large set of options.
   */
  virtualized?: boolean;

  /** If true, the input will be required. */
  required?: boolean;

  /** A function that determines the filtered options to be rendered on search. */
  filterOptions?: (options: any, state: any) => any;

  /**
   * A function that is called when the input's value changes. It receives the
   * change event and the new value of the input as parameters.
   */
  onChange?: (event: any, value: any) => void;

  /** A callback function fired when the input is unfocused. */
  onBlur?: (event: any) => void;

  /** A callback function fired when the input value changes. */
  onInputChange?: (event: object, value: string, reason: string) => void;

  /**
   * A function used to determine the string representation of each option. It
   * receives an option object and should return a string.
   */
  getOptionLabel?: (option: any) => string;

  /** A function used to determine if an option is selected. */
  isOptionEqualToValue?: (option: any, value: any) => boolean;

  /** A component that replaces the default Paper component. */
  PaperComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
};

/**
 * See https://v4.mui.com/components/autocomplete for usage
 *
 * **Note:** There is considerable room for refactoring the many "any" typings
 * in this file. Doing so will require a lot of tweaking components that use
 * Autocomplete.
 */
const Autocomplete: React.FC<AutocompleteProps> = ({
  translationKey = undefined,
  translationOptions = undefined,
  name,
  options,
  value = undefined,
  className = undefined,
  disabled = false,
  error = false,
  helperText = undefined,
  freeSolo = false,
  fullWidth = false,
  id = undefined,
  inputValue = undefined,
  loading = false,
  multiple = false,
  virtualized = false,
  onChange = () => {},
  onInputChange = undefined,
  getOptionLabel = (option) => option?.label ?? '',
  isOptionEqualToValue = undefined,
  filterOptions = undefined,
  PaperComponent = undefined,
  required = false,
  ...rest
}: AutocompleteProps) => (
  <MaterialAutocomplete
    {...rest}
    PaperComponent={PaperComponent}
    filterOptions={filterOptions}
    value={value}
    id={id}
    className={className}
    freeSolo={freeSolo}
    fullWidth={fullWidth}
    inputValue={inputValue}
    loading={loading}
    options={options}
    multiple={multiple}
    disabled={disabled}
    ListboxComponent={virtualized ? Listbox : undefined}
    onChange={(event, val) => {
      const newEvent = {
        ...event,
        target: {
          ...event.target,
          name,
          value: val,
        },
      };
      if (onChange) {
        onChange(newEvent, val);
      }
    }}
    onInputChange={onInputChange}
    getOptionLabel={getOptionLabel}
    isOptionEqualToValue={isOptionEqualToValue}
    renderTags={(val, getTagProps) =>
      val?.map((option, index) => {
        const { key, ...tagProps } = getTagProps({ index });
        return (
          <Chip
            variant="filled"
            label={
              getOptionLabel !== undefined ? getOptionLabel(option) : option
            }
            size="small"
            color="primary"
            key={key}
            {...tagProps}
          />
        );
      })
    }
    renderInput={(params) => (
      <TextField
        {...params}
        error={error}
        helperText={helperText}
        translationKey={translationKey}
        translationOptions={translationOptions}
        disabled={disabled}
        name={name}
        required={required}
      />
    )}
    renderOption={(props, option) => (
      <li {...props}>
        <Typography variant="body2">
          {getOptionLabel ? getOptionLabel(option) : ''}
        </Typography>
      </li>
    )}
  />
);

export default Autocomplete;
