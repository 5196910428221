import { Alert, type AlertProps, AlertTitle } from 'base-components';

type CommonAlertProps = AlertProps & {
  title?: string;
  fullWidth?: boolean;
};

const renderAlertTitle = (title) => {
  if (title) {
    return (
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>
    );
  }
  return null;
};

const WarningAlert = ({
  title = undefined,
  children = undefined,
  ...otherProps
}: CommonAlertProps) => (
  <Alert severity="warning" {...otherProps}>
    {renderAlertTitle(title)}
    {children}
  </Alert>
);

const ErrorAlert = ({
  title = undefined,
  children = undefined,
  ...otherProps
}: CommonAlertProps) => (
  <Alert severity="error" {...otherProps}>
    {renderAlertTitle(title)}
    {children}
  </Alert>
);

const InfoAlert = ({
  title = undefined,
  children = undefined,
  ...otherProps
}: CommonAlertProps) => (
  <Alert severity="info" {...otherProps}>
    {renderAlertTitle(title)}
    {children}
  </Alert>
);

const SuccessAlert = ({
  title = undefined,
  children = undefined,
  ...otherProps
}: CommonAlertProps) => (
  <Alert severity="success" {...otherProps}>
    {renderAlertTitle(title)}
    {children}
  </Alert>
);

export { WarningAlert, ErrorAlert, InfoAlert, SuccessAlert };
