import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import FormFieldService from 'domain/services/FormFieldService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import FormField from './FormField';
import FormFieldDialogBox from './FormFieldDialogBox';
import FormFieldTable from './FormFieldTable';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
});

type FormFieldPageProps = {
  onError: (message: any, callback?: any) => void;
};

const FormFieldPage = (props: FormFieldPageProps) => {
  const { onError } = props;
  const userHasWritePermission = Environment.getDmasUserPrivilege() === 'RW';
  const [formFields, setFormFields] = useState<FormField[] | null>(null);
  const [editFormField, setEditFormField] = useState<FormField>(null);

  const openAndSaveEditData = (form: FormField) => {
    setEditFormField(form);
    return true;
  };

  useEffect(() => {
    const refreshTableData = async () => {
      const payload: FormField[] = await FormFieldService.getAllFormFields();
      setFormFields(payload);
    };
    refreshTableData();
  }, []);

  const renderDialogBox = () => (
    <FormFieldDialogBox
      label="Form Field"
      onClose={() => setEditFormField(null)}
      userHasWritePermission={userHasWritePermission}
      formField={editFormField}
    />
  );

  return (
    <>
      {editFormField ? renderDialogBox() : <></>}
      <FormFieldTable
        userHasWritePermission={userHasWritePermission}
        onError={onError}
        formFields={formFields}
        openAndSaveEditData={openAndSaveEditData}
      />
    </>
  );
};

export default withStyles(styles)(withSnackbars(FormFieldPage));
