import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DeviceService from 'domain/services/DeviceService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DiveDetailsTemplate from './DiveDetailsTemplate';
import DiveService from '../../../services/DiveService';

class DiveDetails extends PureComponent {
  static propTypes = {
    diveId: PropTypes.number.isRequired,
    onError: PropTypes.func.isRequired,
    showExtraDiveInfo: PropTypes.bool,
    createDiveOpen: PropTypes.bool,
  };

  static defaultProps = {
    showExtraDiveInfo: false,
    createDiveOpen: false,
  };

  state = {
    diveDetails: undefined,
  };

  componentDidMount() {
    const { diveId } = this.props;
    this.getDiveDetails(diveId);
  }

  componentDidUpdate(prevProps) {
    const { diveId, createDiveOpen } = this.props;
    const { diveId: prevDiveId, createDiveOpen: prevDiveOpen } = prevProps;
    if (
      (diveId && prevDiveId !== diveId) ||
      (!createDiveOpen && prevDiveOpen)
    ) {
      this.getDiveDetails(diveId);
    }
  }

  getDiveDetails = async (diveId) => {
    const { onError } = this.props;
    const diveDetails = await DiveService.getDiveDetails(diveId, onError);
    if (diveDetails) {
      if (diveDetails.deviceId) {
        const response = await DeviceService.getOne(diveDetails.deviceId);
        diveDetails.platform = response.devices.device;
      }
      if (diveDetails.cameraId) {
        const response = await DeviceService.getOne(diveDetails.cameraId);
        diveDetails.camera = response.devices.device;
      }

      this.setState({ diveDetails });
    }
  };

  render() {
    const { showExtraDiveInfo } = this.props;
    const { diveDetails } = this.state;
    return (
      <DiveDetailsTemplate
        diveDetails={diveDetails}
        showExtraDiveInfo={showExtraDiveInfo}
        stillLoading={!diveDetails}
      />
    );
  }
}

export default withSnackbars(DiveDetails);
