import { openDeviceDetails } from 'domain/AppComponents/link/DeviceDetailsLink';
import { openSiteDevice } from 'domain/AppComponents/link/SiteDeviceLink';
import { openSites } from 'domain/AppComponents/link/SitesLink';

const redirect = (props) => {
  const { isFromSite, siteId, deviceId } = props;
  if (isFromSite && !isNaN(siteId)) {
    openSites(siteId);
  } else if (!isNaN(deviceId)) {
    openDeviceDetails(deviceId, 'site_tab');
  } else {
    openSiteDevice({});
  }
};

const handleRedirect = (props) => () => {
  redirect(props);
};

export { handleRedirect, redirect };
