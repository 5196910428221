import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';

import { IconButton, Grid, Typography } from 'base-components';
import CopyLinkButton from './CopyLinkButton';
import ShareButton from './ShareButton';

const styles = (theme) => ({
  item: {
    padding: theme.spacing(),
  },
  typography: {
    marginLeft: theme.spacing(),
  },

  reactShareButton: {
    outline: 'none',
    padding: '0px',

    '&:hover': {
      opacity: 0.75,
    },
  },
});

/**
 * ShareButtons component that returns default social media buttons: Facebook,
 * Twitter, and Copy Link.
 */
class ShareButtons extends PureComponent {
  static propTypes = {
    shareUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    classes: PropTypes.shape({
      item: PropTypes.string,
      reactShareButton: PropTypes.string,
      typography: PropTypes.string,
    }).isRequired,
    onCopy: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onCopy: undefined,
    onClose: undefined,
  };

  renderFacebookButton = () => {
    const { classes, shareUrl, title } = this.props;

    // Slight hack putting the icon in an icon button,
    // so that we get the pretty css
    return this.renderButton(
      <FacebookShareButton url={shareUrl} quote={title} aria-label="facebook">
        <IconButton className={classes.reactShareButton}>
          <FacebookIcon size={48} round />
        </IconButton>
      </FacebookShareButton>,
      'Facebook'
    );
  };

  renderTwitterButton = () => {
    const { classes, shareUrl, title } = this.props;
    return this.renderButton(
      // Slight hack putting the icon in an icon button,
      // so that we get the pretty css
      <TwitterShareButton
        className={classes.reactShareButton}
        url={shareUrl}
        title={title}
        aria-label="twitter"
      >
        <IconButton className={classes.reactShareButton}>
          <TwitterIcon size={48} round />
        </IconButton>
      </TwitterShareButton>,
      'Twitter'
    );
  };

  renderLinkButton = () => {
    const { shareUrl, onCopy, onClose } = this.props;
    return this.renderButton(
      <CopyLinkButton
        shareUrl={shareUrl}
        buttonSize="small"
        onCopy={onCopy}
        onClose={onClose}
      />,
      'Copy Link'
    );
  };

  renderButton = (button, label) => {
    const { classes } = this.props;
    return (
      <Grid item className={classes.item}>
        <ShareButton label={label}>{button}</ShareButton>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Typography variant="h6" className={classes.typography}>
          Share
        </Typography>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          direction="row"
        >
          {this.renderFacebookButton()}
          {this.renderTwitterButton()}
          {this.renderLinkButton()}
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(ShareButtons);
