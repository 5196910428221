import moment from 'moment';
import DeviceWebServiceWithToken from 'domain/services/DeviceWebServiceWithToken';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

import {
  NON_TOKEN_DEVICE_SERVICE,
  API_DATA_PRODUCT_DISCOVERY_SERVICE,
  REGEX_TO_GET_DIGITS_AFTER_DP,
  RDI,
  DATA_PRODUCT_CODE_DATA_PRODUCT_FORMAT_ID_MAP,
  DEVICE_CATEGORY_CODE_DEVICE_CATEGORY_ID_OBJ,
  DATE_FORMAT_DMAS_SERVICE_CALLS,
  OPERATION_GETDEVICECAT_SITEDEVICE_ID,
  OPERATION_GETDATAPRODUCTFORMATDEVICE_FROM_SITEDEVICE_ID,
  IMAGE_FILE_EXTENSION,
  ADCP_DEVICE_CATEGORY_CODES,
  REGEX_TO_CHECK_IF_DEVICE_DEPLOYED_AND_GET_DATE,
} from './DataPlayerConstants';
import DataPlayerHelper from './DataPlayerHelper';

/*
 * handles service calls for things that rely on device,
 * dataProductOptions, dataProductCode, deviceCode etc
 */
class DataPlayerDeviceService {
  // Base Service Calls
  static performDeviceServiceCall(deviceId, onError) {
    return DeviceWebServiceWithToken.get({
      method: 'get',
      deviceId,
    }).catch((error) => {
      onError(error);
    });
  }

  static performDataProductDiscoveryServiceCall(params, onError) {
    return get(API_DATA_PRODUCT_DISCOVERY_SERVICE, params).catch((error) =>
      onError(error)
    );
  }

  static performNonTokenDeviceServiceCall(params, onError) {
    return get(NON_TOKEN_DEVICE_SERVICE, params).catch((error) =>
      onError(error)
    );
  }

  // wrappers to base service calls
  static performDeviceCategorysAndSiteDevicesServiceCall(onError) {
    return this.performNonTokenDeviceServiceCall(
      {
        operationtype: OPERATION_GETDEVICECAT_SITEDEVICE_ID,
      },
      onError
    );
  }

  static async getDeviceCodeForThisDevice(deviceId, onError) {
    const deviceResponse = await this.performDeviceServiceCall(
      deviceId,
      onError
    );
    return deviceResponse?.data?.[0]?.deviceCode;
  }

  static async getDataProductForDevice(deviceId, fileExtension, onError) {
    const deviceCode = await this.getDeviceCodeForThisDevice(deviceId, onError);
    // gets all dataProducts for this device that have extensions matching IMAGE_FILE_EXTENSION
    const dataProductDiscoveryResponse =
      await this.performDataProductDiscoveryServiceCall(
        {
          deviceCode,
          method: 'get',
          extension: fileExtension,
        },
        onError
      );
    if (dataProductDiscoveryResponse === undefined) {
      return undefined;
    }
    return DataPlayerHelper.getSupportedDataProduct(
      dataProductDiscoveryResponse
    );
  }

  // glue methods
  static async getResourceIdByDeviceAndDate(
    dateSelectorValue,
    deviceId,
    dateOfMiddleImage,
    deviceCategorySiteDevicePromise,
    onError
  ) {
    // deviceCategorySiteDevicePromise is passed in as a promise since it is a slow service call (5 seconds in bad cases),
    // so make the request early and then pass in promise in hopes that by the time the promise is needed it has gotten a head start in resolving
    const dataProduct = await this.getDataProductForDevice(
      deviceId,
      IMAGE_FILE_EXTENSION,
      onError
    );
    dataProduct.dataProductFormatId = this.getDataProductFormatIdForDataProduct(
      dataProduct.dataProductCode
    );
    if (dataProduct.dataProductFormatId !== undefined) {
      return this.getResourceIdByDeviceAndDataProductFormat(
        dateSelectorValue,
        deviceId,
        dataProduct,
        dateOfMiddleImage,
        deviceCategorySiteDevicePromise,
        onError
      );
    }
    // else return undefined
    return undefined;
  }

  static async getResourceIdByDeviceAndDataProductFormat(
    dateSelectorValue,
    deviceId,
    dataProduct,
    dateOfMiddleImage,
    deviceCategorySiteDevicePromise,
    onError
  ) {
    const siteDeviceId = await this.getSiteDeviceIdForDeviceAndTime(
      dateSelectorValue,
      deviceId,
      await deviceCategorySiteDevicePromise,
      dateOfMiddleImage,
      onError
    );
    if (siteDeviceId === undefined) {
      return undefined;
    }

    // using found siteDeviceId get resourceId
    const resourceId =
      await this.getResourceIdForThisSiteDeviceAndDataProductFormat(
        siteDeviceId,
        dataProduct,
        onError
      );
    return resourceId;
  }

  static async getSiteDeviceIdForDeviceAndTime(
    dateSelectorValue,
    deviceId,
    siteDeviceAndDeviceCategoryResponse,
    dateOfMiddleImage,
    onError
  ) {
    const deviceInformation = await this.performDeviceServiceCall(
      deviceId,
      onError
    );
    return this.filterDeviceCategoryResponseToGetSiteDeviceId(
      dateSelectorValue,
      siteDeviceAndDeviceCategoryResponse,
      deviceInformation.data[0],
      dateOfMiddleImage
    );
  }

  static async getResourceIdForThisSiteDeviceAndDataProductFormat(
    siteDeviceId,
    dataProduct,
    onError
  ) {
    // make a device service call using the found siteDeviceId
    const responseFromDeviceServiceCallWithSiteDevice =
      await this.performNonTokenDeviceServiceCall(
        {
          operationtype:
            OPERATION_GETDATAPRODUCTFORMATDEVICE_FROM_SITEDEVICE_ID,
          siteDeviceId,
        },
        onError
      );
    return this.filterSiteDeviceResponseToGetResourceId(
      responseFromDeviceServiceCallWithSiteDevice,
      dataProduct
    );
  }

  // logic methods
  static getDataProductFormatIdForDataProduct(dataProductCode) {
    if (dataProductCode !== undefined) {
      return DATA_PRODUCT_CODE_DATA_PRODUCT_FORMAT_ID_MAP.get(dataProductCode);
    }
    // else cannot find dataProductFormatId
    return undefined;
  }

  static filterDeviceCategoryResponseToGetSiteDeviceId(
    dateSelectorValue,
    siteDeviceAndDeviceCategoryResponse,
    deviceData,
    dateOfImage
  ) {
    // pull out the information for the device category we are working with and then get info for specific device
    const siteDeviceInfoThisDeviceCat =
      siteDeviceAndDeviceCategoryResponse.data.payload.categories.filter(
        (deviceCategory) =>
          deviceCategory.id ===
          DEVICE_CATEGORY_CODE_DEVICE_CATEGORY_ID_OBJ[
            deviceData.deviceCategoryCode
          ]
      );
    if (siteDeviceInfoThisDeviceCat.length !== 1) {
      // device categoryIds should be unique, something went wrong
      return undefined;
    }
    const siteDeviceInfoThisDevice = siteDeviceInfoThisDeviceCat[0].els.filter(
      (siteDeviceInformationPerDevice) =>
        siteDeviceInformationPerDevice.id === deviceData.deviceId
    );
    if (siteDeviceInfoThisDevice.length !== 1) {
      // deviceIds should be unique, something went wrong
      return undefined;
    }
    // Filter further by date requested
    return this.getSiteDeviceIdFromInfoThisDevice(
      dateSelectorValue,
      siteDeviceInfoThisDevice,
      dateOfImage
    );
  }

  static getSiteDeviceIdFromInfoThisDevice(
    dateSelectorValue,
    siteDeviceInfoThisDevice,
    dateOfImages
  ) {
    const dateOfImage = moment.utc(dateOfImages).clone(); // convert to UTC and clone
    const siteDevice = siteDeviceInfoThisDevice[0].els.filter(
      (siteDeviceInfo) => {
        const parsedDateRangeOfSiteDevice =
          this.parseDateRangeStringIntoStartAndEnd(siteDeviceInfo.dateRange);
        return dateOfImage.isBetween(
          parsedDateRangeOfSiteDevice.startDate,
          parsedDateRangeOfSiteDevice.endDate
        );
      }
    );
    if (dateSelectorValue === 'latest') {
      const latestSiteDeviceId = siteDeviceInfoThisDevice[0].els[0].id;
      return latestSiteDeviceId;
    }
    if (siteDevice.length !== 1) {
      // since the image date was not in the previous deployments check if the date is after the start date of the current deployment
      return this.getSiteDeviceIdForCurrentDeploymentIfValid(
        siteDeviceInfoThisDevice[0].els,
        dateOfImage
      );
    }
    return siteDevice[0].id;
  }

  static parseDateRangeStringIntoStartAndEnd(dateRangeString) {
    const indexOfTo = dateRangeString.indexOf('to');
    const startDateString = dateRangeString.slice(0, indexOfTo).trim();
    const endDateString = dateRangeString.slice(indexOfTo + 2).trim(); // plus 2 as 'to' is 2 char long
    const startDate = moment.utc(
      startDateString,
      DATE_FORMAT_DMAS_SERVICE_CALLS
    );
    const endDate = moment.utc(endDateString, DATE_FORMAT_DMAS_SERVICE_CALLS);
    return { startDate, endDate };
  }

  static getSiteDeviceIdForCurrentDeploymentIfValid(
    siteDevicesThisDevice,
    dateOfImages
  ) {
    // returns undefined if device is not currently deployed or if date of image is not in current deployment
    const currentlyDeployedSiteDevice = siteDevicesThisDevice.filter(
      (siteDeviceThisDevice) =>
        REGEX_TO_CHECK_IF_DEVICE_DEPLOYED_AND_GET_DATE.test(
          siteDeviceThisDevice.dateRange
        )
    );
    if (currentlyDeployedSiteDevice.length !== 1) {
      // device is either not deployed currently or has two siteDevices with a dateTo of 'to current'
      return undefined;
    }

    const startDateOfCurrentDeployment = moment.utc(
      currentlyDeployedSiteDevice[0].dateRange,
      DATE_FORMAT_DMAS_SERVICE_CALLS
    );

    // return the sitedeviceId if requested image is in the current deployment date range
    if (startDateOfCurrentDeployment.isBefore(dateOfImages)) {
      return currentlyDeployedSiteDevice[0].id;
    }
    return undefined;
  }

  static filterSiteDeviceResponseToGetResourceId(
    responseFromDeviceServiceCallWithSiteDevice,
    dataProduct
  ) {
    // do a check on the inputs
    const { dataProductFormatId, helpDocument } = dataProduct;
    if (
      responseFromDeviceServiceCallWithSiteDevice === undefined ||
      dataProductFormatId === undefined ||
      helpDocument === undefined
    ) {
      return undefined;
    }
    // get dataProductId from link to help document (this seems not great, but we are tying to use two seperate service systems,
    // the internal one that operates on deviceIds, dataProductIds, etc and the external services that operate on deviceCode,
    // dataProductCode, etc)
    const dataProductId =
      this.getDataProductIdFromHelpDocumentLink(helpDocument);

    if (dataProductId === undefined) {
      return undefined;
    }

    // filter to correct dataproduct and then filter dataproduct with
    // dataproductformat to get dataproductformatdeviceid (which is resourceId in FormSectionResouce)
    const dataProductInQuestion =
      responseFromDeviceServiceCallWithSiteDevice.data.payload.devices.products.product.filter(
        (dataProductsForDevice) => dataProductsForDevice.id === dataProductId
      );
    const dataProductFormatForDevice =
      dataProductInQuestion[0].dataproductformats.filter(
        (dataProductFormat) => dataProductFormat.id === dataProductFormatId
      );

    if (
      dataProductFormatForDevice === undefined ||
      dataProductFormatForDevice.length !== 1
    ) {
      // something went wrong
      return undefined;
    }
    return dataProductFormatForDevice[0].dataProductFormatDeviceId;
  }

  static getDataProductIdFromHelpDocumentLink(helpDocument) {
    // find DP in the string, the next number will be the dataProductId
    const indexOfDP = helpDocument.indexOf('DP');
    if (indexOfDP === -1) {
      // no DP in string or no string at all
      return undefined;
    }
    const everythingAfterDP = helpDocument.slice(indexOfDP);
    const match = everythingAfterDP.match(REGEX_TO_GET_DIGITS_AFTER_DP);
    if (match === null || match.length === 0) {
      // this should not happen
      return undefined;
    }
    // return first capturing group
    return parseInt(match[1], 10);
  }

  // miscellaneous methods
  static async isRdiAdcp(deviceId, deviceCategoryCode, onError) {
    // check if an ADCP first or if deviceCategoryCode is undefined
    // assume that if deviceCategoryCode is undefined this is being called on an ADCP
    if (
      ADCP_DEVICE_CATEGORY_CODES.includes(deviceCategoryCode) ||
      deviceCategoryCode === undefined
    ) {
      const deviceInformation = await this.performDeviceServiceCall(
        deviceId,
        onError
      );
      const { deviceName } = deviceInformation.data[0];
      if (deviceName.includes(RDI)) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param {number} resourceId
   * @param {Function} onError
   */
  static getSearchOptions(resourceId, onError) {
    return get('FormService', {
      formTypeId: 2,
      resourceTypeId: 1500,
      resourceId,
      sourceId: 3,
    })
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => payload[0][0].fields)
      .catch((error) => {
        onError(error);
      });
  }
}

export default DataPlayerDeviceService;
