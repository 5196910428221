import { useContext, useEffect, useState } from 'react';
import * as React from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from 'base-components';
import {
  EditIconButton,
  CopyLinkIconButton,
} from 'domain/AppComponents/IconButtons';
import { ONC_DATA } from 'domain/AppComponents/organization-details/OrganizationServiceData';
import CollapsibleText from 'domain/AppComponents/sea-tube/playlist-playback/CollapsibleText';
import PlaylistContext from 'domain/AppComponents/sea-tube/playlist-playback/PlaylistContext';
import EditClipDialog from 'domain/AppComponents/sea-tube/playlists/dialogs/EditClipDialog';
import UserDetailsContext from 'domain/AppComponents/sea-tube/UserDetailsContext';
import PlaylistLineService, {
  PlaylistLine,
} from 'domain/services/PlaylistLineService';
import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import InfoItem from 'library/CompositeComponents/list-items/InfoItem';
import OrganizationLogo from 'library/CompositeComponents/logo/OrganizationLogo';
import Environment from 'util/Environment';
import useBroadcast from 'util/hooks/useBroadcast';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import BroadcastChannel from './BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget from '../../library/CompositeComponents/dashboard/Widget';

const FIXED_CAMERA_RESOURCE_TYPE_ID = 1000;

const useStyles = makeStyles((theme: Theme) => ({
  infoPadding: {
    marginLeft: theme.spacing(1),
  },
  infoHeader: {
    padding: theme.spacing(1),
    display: 'flex',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  logo: { marginLeft: theme.spacing() },
  title: { alignSelf: 'center', marginLeft: theme.spacing(2) },
  actions: {
    marginLeft: 'auto',
  },
  iconButton: { marginRight: theme.spacing() },
  desc: {
    paddingLeft: theme.spacing(2),
  },
  clipDates: {
    display: 'grid',
    gridTemplateColumns: '30% 30% 1fr',
    alignItems: 'start',
  },
  errorContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ClipDetailsWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  // a new context may need to be defined here for a PlaylistLine being passed
  // in, as well as expedition details if not already attached
  const { id, dashboardId } = props;
  const { playlist, playlistLines, clipId } = useContext(PlaylistContext);
  const { userId } = useContext(UserDetailsContext);
  const [editClip, setEditClip] = React.useState<PlaylistLine>(undefined);
  const [selectedPlaylistLineId] = useBroadcast<number>(
    dashboardId,
    BroadcastChannel.PlaylistLineId,
    clipId || playlistLines[0]?.playlistLineId,
    id
  );

  const [, setRefreshVideos] = useBroadcast(
    dashboardId,
    BroadcastChannel.RefreshVideos,
    false,
    id
  );

  const classes = useStyles();
  const { onError, onInfo } = useSnackbars();
  const [currentClip, setCurrentClip] = useState<PlaylistLine>(undefined);
  const [clipMetadata, , fetchClipMetadata] = useWebService({
    method: PlaylistLineService.getMetadata,
  });

  useEffect(() => {
    if (selectedPlaylistLineId !== -1 && playlistLines?.length > 0) {
      const playlistLine = playlistLines.find(
        (line) => line.playlistLineId === selectedPlaylistLineId
      );
      if (playlistLine) {
        setCurrentClip(playlistLine);
        fetchClipMetadata(playlistLine.playlistLineId);
      }
    }
  }, [playlistLines, selectedPlaylistLineId, fetchClipMetadata]);

  const handleCopyClipLink = () => {
    const url = `${Environment.getLinkUrl()}/app/playlists/${
      playlist.playlistHdrId
    }?clipId=${currentClip.playlistLineId}`;
    navigator.clipboard.writeText(`${url}`).then(
      () => {
        onInfo('Link Copied to Clipboard');
      },
      () => {
        onError('Unable to copy link');
      }
    );
  };

  const EditButton = (
    <EditIconButton
      key="editbutton"
      onClick={() => {
        setEditClip(currentClip);
      }}
      className={classes.iconButton}
      disabled={playlist.locked}
      aria-label={playlist.locked ? 'Playlist is currently locked' : 'Edit'}
    />
  );

  const CopyLinkButton = (
    <CopyLinkIconButton
      key="copylink"
      onClick={handleCopyClipLink}
      className={classes.iconButton}
    />
  );

  const renderActionContent = () => {
    const actions = [];
    actions.push(CopyLinkButton);
    userId === playlist.createdBy && actions.push(EditButton);
    return <div className={classes.actions}> {actions} </div>;
  };

  const renderOrganizationLogo = (resourceTypeId: number) => {
    let orgId = ONC_DATA.organizationId;
    if (resourceTypeId === 600) {
      orgId = clipMetadata.organizationId;
    }
    return <OrganizationLogo id={orgId} className={classes.logo} />;
  };

  const renderDiveMetadata = () => (
    <div className={classes.clipDates}>
      <InfoItem
        id="clip-details-expedition"
        primary="Expedition"
        secondary={clipMetadata.expeditionName}
      />
      <InfoItem
        id="clip-details-dive"
        primary="Dive"
        secondary={clipMetadata.diveName}
      />
    </div>
  );

  const renderFixedCameraMetadata = () => (
    <div className={classes.clipDates}>
      <InfoItem
        id="clip-details-location"
        primary="Site Location"
        secondary={clipMetadata.siteLocation}
      />
      <InfoItem
        id="clip-details-name"
        primary="Site Name"
        secondary={clipMetadata.siteName}
      />
    </div>
  );

  const renderMetaData = () => {
    if (clipMetadata.resourceTypeId === FIXED_CAMERA_RESOURCE_TYPE_ID) {
      return renderFixedCameraMetadata();
    }
    return renderDiveMetadata();
  };

  const getSourceLinkUrl = () => {
    let sourceUrl: string;
    let resourceId: number;
    let baseUrl: string = `${Environment.getLinkUrl()}/`;
    if (clipMetadata.resourceTypeId === FIXED_CAMERA_RESOURCE_TYPE_ID) {
      sourceUrl = 'SeaTube';
      resourceId = currentClip.resourceId;
      baseUrl += `${sourceUrl}?resourceTypeId=${clipMetadata.resourceTypeId}&resourceId=${resourceId}&`;
    } else {
      sourceUrl = 'app/dive-logs';
      resourceId = clipMetadata.diveId;
      baseUrl += `${sourceUrl}/${resourceId}?`;
    }
    return `${baseUrl}time=${currentClip.dateFrom}`;
  };

  const renderContent = () => (
    <>
      <EditClipDialog
        open={editClip !== undefined}
        onCancel={() => setEditClip(undefined)}
        initialValue={editClip}
        onSuccess={() => setRefreshVideos(true)}
      />
      <div className={classes.infoHeader}>
        {renderOrganizationLogo(clipMetadata.resourceTypeId)}
        <Typography className={classes.title} variant="h6">
          {currentClip.playlistLineName}
        </Typography>
        {renderActionContent()}
      </div>
      <div className={classes.infoPadding}>
        <div className={classes.desc}>
          <CollapsibleText
            id="clip-description"
            text={currentClip.description}
            variant="body2"
          />
        </div>
        <div className={classes.clipDates}>
          <InfoItem
            id="clip-details-date-from"
            primary="Date From"
            secondary={currentClip.dateFrom}
          />
          <InfoItem
            id="clip-details-date-to"
            primary="Date To"
            secondary={currentClip.dateTo}
          />
        </div>
        {renderMetaData()}
        <InfoItem
          id="clip-details-source"
          primary="Video Source"
          secondary={
            <OpenInNewLink href={getSourceLinkUrl()}>
              {getSourceLinkUrl()}
            </OpenInNewLink>
          }
        />
      </div>
    </>
  );

  const renderError = () => (
    <div className={classes.errorContainer}>
      <Typography>No Clip Selected</Typography>
    </div>
  );

  return (
    <Widget
      ariaLabel="clip-details-widget"
      {...props}
      title="Clip Details"
      hideHeader
    >
      {currentClip && clipMetadata ? renderContent() : renderError()}
    </Widget>
  );
};

ClipDetailsWidget.widgetKey = 'clip-details';
ClipDetailsWidget.widgetTitle = 'Clip Details';
ClipDetailsWidget.defaultDataGrid = {
  i: 'clip-details',
  x: 0,
  y: 6,
  w: 3,
  h: 5,
};

export default ClipDetailsWidget;
