import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';

const jiraUrl = 'https://jira.oceannetworks.ca/browse/';

type Props = { jiraId: string };
const JiraLink = ({ jiraId }: Props) => (
  <OpenInNewLink href={`${jiraUrl}${jiraId}`}>{jiraId}</OpenInNewLink>
);

export default JiraLink;
