import { useMediaQuery, useTheme } from '@mui/material';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import ShareDrawer from './ShareDrawer';
import SharePopover from './SharePopover';

type ShareProps = {
  title: string;
  onClose: (e) => void;
  getFullscreen?: () => boolean;
  time?: Date;
  diveId?: number;
  playlistHdrId?: number;
  playlistLineId?: number;
  searchTreeNodeId?: number;
  open?: boolean;
  drawerAnchor?: 'top' | 'bottom';
  shareUrl?: string;
  popoverAnchorEl?: HTMLElement;
  popoverAnchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  popoverTransformOrigin?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
};

const Share: React.FC<ShareProps> = ({
  title,
  onClose,
  getFullscreen = () => false,
  time = undefined,
  diveId = undefined,
  playlistHdrId = undefined,
  playlistLineId = undefined,
  searchTreeNodeId = undefined,
  open = false,
  drawerAnchor = 'bottom',
  shareUrl = '',
  popoverAnchorEl = null,
  popoverAnchorOrigin = { vertical: 'top', horizontal: 'center' },
  popoverTransformOrigin = { vertical: 'bottom', horizontal: 'center' },
}: ShareProps) => {
  const theme = useTheme();
  const useDrawer = useMediaQuery(theme.breakpoints.down('md'));
  const { onInfo } = useSnackbars();

  const getUrl = () => {
    const { href } = window.location;

    if (!time) return href;

    const base = Environment.getDmasUrl();
    const currentTime = time.toISOString();

    if (diveId) {
      return `${base}/app/dive-logs/${diveId}?time=${currentTime}`;
    }

    if (playlistHdrId && playlistLineId) {
      return `${base}/SeaTubeV3?resourceTypeId=${SeaTubeResourceTypes.PLAYLIST}&resourceId=${playlistHdrId}&playlistLineId=${playlistLineId}&time=${currentTime}`;
    }

    if (searchTreeNodeId) {
      return `${base}/SeaTubeV3?resourceTypeId=${SeaTubeResourceTypes.SEARCH_TREE_NODE}&resourceId=${searchTreeNodeId}&time=${currentTime}`;
    }

    return href;
  };

  const getVideoContainer = () => {
    if (!getFullscreen) return null;
    return getFullscreen() ? document.querySelector('.jw-wrapper') : null;
  };

  const handleCopy = () => {
    if (onInfo) {
      onInfo('Copied link to clipboard');
    }
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClose(e);
  };

  const renderDrawer = () => (
    <ShareDrawer
      open={open}
      anchor={drawerAnchor}
      onClose={onClose}
      shareUrl={shareUrl || getUrl()}
      title={title}
      onCopy={handleCopy}
      ModalProps={{
        container: getVideoContainer(),
      }}
    />
  );

  const renderPopover = () => (
    <SharePopover
      open={open}
      onClose={(e) => handleClose(e)}
      anchorOrigin={popoverAnchorOrigin}
      transformOrigin={popoverTransformOrigin}
      anchorEl={popoverAnchorEl}
      shareUrl={shareUrl || getUrl()}
      title={title}
      onCopy={handleCopy}
      container={getVideoContainer()}
    />
  );

  // Renders a drawer at the xs or sm breakpoint and a popover at the md breakpoint and up
  return useDrawer ? renderDrawer() : renderPopover();
};

export default Share;
