import { useContext, useEffect, useState } from 'react';
import { Tab, Tabs } from 'base-components';
import UserDetailsContext from 'domain/AppComponents/sea-tube/UserDetailsContext';
import AnnotationFilter from 'domain/AppComponents/seatube/filter/AnnotationFilter';
import AnnotationUtil from 'domain/Apps/seatube/util/AnnotationUtil';
import DeviceCategories from 'domain/Apps/seatube/util/DeviceCategories';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import { useUserOptions } from 'domain/services/AnnotationService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import Widget from 'library/CompositeComponents/dashboard/Widget';

const EMPTY_FILTER = {
  cruiseIds: [],
  diveIds: [],
  searchTreeNodeIds: { key: -1, label: '', value: -1 },
  creators: [],
  modifiers: [],
  comment: '',
  toBeReviewed: false,
  public: true,
  unlisted: true,
};

const SearchFormWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId } = props;

  const { userId } = useContext(UserDetailsContext);

  const [tabValue, setTabValue] = useState(
    SeaTubeResourceTypes.SEARCH_TREE_NODE
  );
  const [filter, setFilter] = useState(EMPTY_FILTER);
  const { setDashboardState: setConfig } = useDashboardState();

  const { data: userOptions, invalidateQuery: fetchUserOptions } =
    useUserOptions({ searchTreeNodeIds: filter.searchTreeNodeIds?.value });

  useEffect(() => {
    fetchUserOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.searchTreeNodeIds]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const handleSearchSubmit = () => {
    setConfig({
      filter: AnnotationUtil.buildAnnotationFilter(filter),
      searchTreeNodeIds: filter.searchTreeNodeIds?.value,
      sortAnnotationsByMostRecent: true,
      deviceCategoryIds: DeviceCategories.FIXED_CAMERA,
    });
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
    setFilter(EMPTY_FILTER);
  };

  const renderTabs = () => (
    <Tabs
      value={tabValue}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
    >
      {/* not implementing expedition search at the moment */}
      {/* <Tab label="Expedition" value={SeaTubeResourceTypes.EXPEDITION} /> */}
      <Tab label="Location" value={SeaTubeResourceTypes.SEARCH_TREE_NODE} />
    </Tabs>
  );

  return (
    <Widget id={id} dashboardId={dashboardId} title="Search" {...props}>
      {renderTabs()}
      <AnnotationFilter
        context="search"
        initialFilterValues={EMPTY_FILTER}
        name="Search"
        onChange={handleFilterChange}
        filter={filter}
        creatorOptions={userOptions?.creators || []}
        modifierOptions={userOptions?.modifiers || []}
        isLoggedIn={userId > 0}
        onSubmit={handleSearchSubmit}
        onReset={() => setFilter(EMPTY_FILTER)}
        tabValue={tabValue}
      />
    </Widget>
  );
};

SearchFormWidget.widgetKey = 'seatube-search-form';
SearchFormWidget.widgetTitle = 'Search';
SearchFormWidget.defaultDataGrid = {
  i: 'seatube-search-form',
  x: 0,
  y: 0,
  w: 4,
  h: 18,
};

export default SearchFormWidget;
