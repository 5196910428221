import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CheckCircle,
  Error,
  RadioButtonUnchecked,
  RemoveCircle,
} from '@onc/icons';
import { CircularProgress, Grid, Typography } from 'base-components';

class StatusIcon extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    showText: PropTypes.bool,
    justifyContent: PropTypes.string,
  };

  static defaultProps = {
    showText: false,
    justifyContent: 'flex-start',
  };

  renderText(statusText) {
    const { showText } = this.props;
    if (showText) {
      return (
        <Grid item style={{ marginTop: '-2px', marginLeft: '2px' }}>
          {statusText}
        </Grid>
      );
    }
    return null;
  }

  render() {
    const { status, justifyContent } = this.props;
    let icon = null;
    let statusText = '';

    switch (status) {
      case 'RUNNING':
        icon = <CircularProgress size={15} color="primary" />;
        statusText = <Typography color="primary">Running</Typography>;
        break;
      case 'CANCELLING':
        icon = <CircularProgress size={15} color="secondary" />;
        statusText = <Typography color="secondary">Stopping</Typography>;
        break;
      case 'COMPLETED':
        icon = <CheckCircle fontSize="small" color="primary" />;
        statusText = <Typography color="primary">Passed</Typography>;
        break;
      case 'ERROR':
        icon = <Error fontSize="small" color="error" />;
        statusText = <Typography color="error">Error</Typography>;
        break;
      case 'PENDING':
        icon = <RadioButtonUnchecked fontSize="small" color="disabled" />;
        statusText = <Typography color="textSecondary">Pending</Typography>;
        break;
      case 'SKIPPED':
        icon = <RemoveCircle fontSize="small" color="disabled" />;
        statusText = <Typography color="textSecondary">Skipped</Typography>;
        break;
      default:
        icon = <Error color="inherit" />;
        statusText = <Typography color="inherit">Unknown Status</Typography>;
    }
    return (
      <Grid
        container
        justifyContent={justifyContent}
        direction="row"
        alignItems="center"
      >
        <Grid item>{icon}</Grid>
        {this.renderText(statusText)}
      </Grid>
    );
  }
}

export default StatusIcon;
