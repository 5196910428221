import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import EndeavourEarthquakeService from 'domain/services/EndeavourEarthquakeService';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { EarthquakeSite } from '../EarthquakeData';

type EndeavourEarthquakeAccelerationPlotProps = {
  earthquakeSite: EarthquakeSite;
};
const Plot = createPlotlyComponent(Plotly);

const EndeavourEarthquakeAccelerationPlot: React.VFC<
  EndeavourEarthquakeAccelerationPlotProps
> = (props: EndeavourEarthquakeAccelerationPlotProps) => {
  const { earthquakeSite: earthquakeSiteProp } = props;

  const { onError } = useSnackbars();
  const [plotData, setPlotData] = useState<any>();
  useEffect(() => {
    const getIrisData = async (channel) => {
      const baseTime = moment
        .utc(earthquakeSiteProp.arrivalTime)
        .subtract(10, 'seconds');
      const start = baseTime.format('YYYY-MM-DDTHH:mm:ss');
      const end = moment
        .utc(earthquakeSiteProp.arrivalTime)
        .add(1, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss');
      await EndeavourEarthquakeService.getIrisData(
        earthquakeSiteProp.station,
        start,
        end,
        channel
      )
        .then((result) => {
          // console.log(result.data);
          // remove header
          const data = result.data.substring(result.data.indexOf('\n') + 1);
          const dataLines = data
            .trim()
            .split('\n')
            .filter((line) => line.trim() !== '');
          const xValues = [];
          const yValues = [];

          dataLines.forEach((line) => {
            const [time, value] = line.split(/\s+/);
            const timeMoment = moment.utc(time); // Parse time using moment.js
            const timeOffset = timeMoment.diff(baseTime, 'seconds'); // Calculate seconds from baseTime
            // this is needed because of how iris filters the data to prevent a sharp spike at the start
            if (timeOffset > 5) {
              // Exclude the first 5 seconds
              xValues.push(time);
              yValues.push(parseFloat(value));
            }
          });
          setPlotData({ channel, xValues, yValues });
        })
        .catch((error) => {
          if (channel === 'CHZ') {
            getIrisData('HHZ');
          } else if (channel === 'HHZ' || channel === 'EHZ') {
            getIrisData('MHZ');
          } else if (channel === 'MHZ') {
            onError(`failed to get acceleration data ${error.message}`);
          }
        });
    };
    if (earthquakeSiteProp.deviceCategory.includes('Short')) {
      getIrisData('EHZ');
    } else {
      getIrisData('CHZ');
    }
  }, [
    earthquakeSiteProp.arrivalTime,
    earthquakeSiteProp.deviceCategory,
    earthquakeSiteProp.station,
    onError,
  ]);
  if (!plotData) {
    return <></>;
  }
  return (
    <Plot
      style={{ width: '100%' }}
      data={[
        {
          x: plotData.xValues,
          y: plotData.yValues,
          type: 'scatter',
          mode: 'lines',
          name: 'Acceleration',
        },
      ]}
      layout={{
        title: `Acceleration Values From Channel ${plotData.channel}`,
        xaxis: {
          title: 'Time',
        },
        yaxis: {
          title: 'm/s',
        },
      }}
    />
  );
};

export default EndeavourEarthquakeAccelerationPlot;
