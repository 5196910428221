import { Component } from 'react';
import { grey } from '@mui/material/colors';
import { withStyles, withTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { ArrowDropDown, Close } from '@onc/icons';
import { IconButton, MenuItem, Tooltip, Typography } from 'base-components';

const styles = (theme) => ({
  typography: {
    padding: theme.spacing(),
  },
  iconGrey: {
    color: 'grey',
  },
});

class TaxonAutoComplete extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    classes: PropTypes.shape({
      iconGrey: PropTypes.string,
      typography: PropTypes.string,
    }).isRequired,
    theme: PropTypes.shape({
      palette: PropTypes.shape({
        error: PropTypes.shape({
          main: PropTypes.string,
        }),
        primary: PropTypes.shape({
          dark: PropTypes.string,
          light: PropTypes.string,
        }),
        text: PropTypes.shape({
          disabled: PropTypes.string,
          primary: PropTypes.string,
          secondary: PropTypes.string,
        }),
        type: PropTypes.string,
        mode: PropTypes.string,
      }),
      shape: PropTypes.shape({
        borderRadius: PropTypes.number,
      }),
      typography: PropTypes.shape({
        body1: PropTypes.shape(),
        subtitle1: PropTypes.shape(),
      }),
    }),
    loadOptions: PropTypes.func,
    onInputChange: PropTypes.func,
    inputValue: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object]),
    variant: PropTypes.string,
    userDefined: PropTypes.bool,
  };

  static defaultProps = {
    onChange: undefined,
    disabled: undefined,
    onBlur: undefined,
    theme: undefined,
    loadOptions: undefined,
    onInputChange: undefined,
    inputValue: '',
    className: '',
    placeholder: 'Taxon',
    value: undefined,
    variant: '',
    userDefined: false,
  };

  customMenuStyles = (base) => {
    const { ...rest } = base;
    return {
      ...rest,
      zIndex: 2,
    };
  };

  customOptions = (props) => {
    const { theme } = this.props;
    return (
      <Tooltip title={props.data.label}>
        <div>
          <MenuItem
            selected={props.isFocused}
            {...props}
            {...props.innerProps}
            theme={theme}
          />
        </div>
      </Tooltip>
    );
  };

  noOptionsMessage = (messageProps) => {
    const { inputValue } = messageProps.selectProps;
    const { classes, userDefined } = this.props;
    const charLimit = userDefined ? 0 : 2;
    const charPart = userDefined ? 'One Letter' : 'Three Letters';

    let message = '';
    if (inputValue && inputValue.length > charLimit) {
      message = 'No Taxons Found';
    } else {
      message = `Type ${charPart} to Start a Search`;
    }
    return (
      <Typography
        color="textSecondary"
        className={classes.typography}
        {...messageProps.innerChildren}
      >
        {message}
      </Typography>
    );
  };

  customDropdownIndicator = (indicatorProps) => {
    const { disabled, classes } = this.props;
    const className = disabled ? classes.iconGrey : '';
    return (
      <IconButton {...indicatorProps.innerProps}>
        <ArrowDropDown className={className} />
      </IconButton>
    );
  };

  customClearIndicator = (indicatorProps) => {
    const { disabled, classes } = this.props;
    const className = disabled ? classes.iconGrey : '';
    return (
      <IconButton {...indicatorProps.innerProps}>
        <Close className={className} color="error" />
      </IconButton>
    );
  };

  customContainerStyles = (base, state) => {
    const { theme, variant } = this.props;
    const { isFocused } = state;
    const light = theme.palette.mode === 'light';
    let transformValue;
    let borderBottomValue;
    if (isFocused) {
      transformValue = 'scaleX(1)';
      borderBottomValue = `2px solid ${
        theme.palette.primary[light ? 'dark' : 'light']
      }`;
    }
    return {
      ...base,
      marginTop: variant === 'filter' ? '' : '8px',
      marginBottom: variant === 'filter' ? '' : '4px',
      '&:after': {
        left: 0,
        right: 0,
        content: '""',
        bottom: 0,
        position: 'absolute',
        transform: transformValue,
        borderBottom: borderBottomValue,
      },
      '&$focused:after': {
        transform: 'scaleX(1)',
      },
      '&$error:after': {
        borderBottomColor: theme.palette.error.main,
        transform: 'scaleX(1)', // error is always underlined in red
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        borderBottom: `1px solid ${theme.palette.text.primary}`,
      },
      '&$disabled:before': {
        borderBottomStyle: 'dotted',
      },
    };
  };

  customOptionStyles = (base) => {
    const { theme } = this.props;
    return {
      ...base,
      ...theme.typography.body1,
    };
  };

  customControlStyles = (base) => {
    const { theme } = this.props;
    const light = theme.palette.mode === 'light';
    const bottomLineColor = light
      ? 'rgba(0, 0, 0, 0.42)'
      : 'rgba(255, 255, 255, 0.7)';
    const backgroundColor = light ? grey[100] : 'rgba(255, 255, 255, 0.09)';
    const {
      borderRadius,
      borderStyle,
      borderWidth,
      borderColor,
      boxShadow,
      '&:hover': hover,
      ...rest
    } = base;
    return {
      ...rest,
      backgroundColor,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottom: `1px solid ${bottomLineColor}`,
      minHeight: 48,
      '&:hover': {
        backgroundColor: light
          ? 'rgba(0, 0, 0, 0.13)'
          : 'rgba(255, 255, 255, 0.13)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor,
        },
      },
      '&$focused': {
        backgroundColor: light
          ? 'rgba(0, 0, 0, 0.09)'
          : 'rgba(255, 255, 255, 0.09)',
      },
      '&$disabled': {
        backgroundColor: light
          ? 'rgba(0, 0, 0, 0.12)'
          : 'rgba(255, 255, 255, 0.12)',
      },
    };
  };

  customPlaceholderStyles = (base, state) => {
    const { theme } = this.props;
    const { isFocused } = state;
    const { letterSpacing, ...rest } = base;

    let color = theme.palette.text.secondary;
    if (isFocused) {
      color =
        theme.palette.primary[
          theme.palette.mode === 'light' ? 'dark' : 'light'
        ];
    }
    return {
      ...rest,
      ...theme.typography.subtitle1,
      color,
      '&$disabled': {
        color: theme.palette.text.disabled,
      },
      '&$error': {
        color: theme.palette.error.main,
      },
    };
  };

  render() {
    const {
      disabled,
      className,
      onBlur,
      onChange,
      loadOptions,
      onInputChange,
      inputValue,
      placeholder,
      value,
    } = this.props;

    /* Pseudo Dropdown theme. This tries to make the dropdown look as close to the standard material theme.
     * Most of the CSS is taken from the dropdown material component and its children. This mimics the
     * default FilledInput
     */
    const customStyles = {
      container: this.customContainerStyles,
      option: this.customOptionStyles,
      control: this.customControlStyles,
      placeholder: this.customPlaceholderStyles,
      menu: this.customMenuStyles,
    };

    return (
      <AsyncSelect
        inputValue={inputValue}
        className={className}
        isDisabled={disabled}
        loadOptions={loadOptions}
        onChange={onChange}
        onInputChange={onInputChange}
        onBlur={onBlur}
        components={{
          NoOptionsMessage: this.noOptionsMessage,
          Option: this.customOptions,
          DropdownIndicator: this.customDropdownIndicator,
          ClearIndicator: this.customClearIndicator,
        }}
        isClearable
        styles={customStyles}
        placeholder={placeholder}
        value={value}
      />
    );
  }
}

export default withTheme(withStyles(styles)(TaxonAutoComplete));
