import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';

const styles = (theme) => ({
  selector: {
    marginLeft: theme.spacing(1),
  },
});
/** Implements the dropdown for choosing your system command. */
class SystemCommandSelector extends PureComponent {
  static propTypes = {
    formatDropdown: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    defaultArgs: PropTypes.objectOf(PropTypes.any).isRequired,
    commands: PropTypes.arrayOf(PropTypes.shape({})),
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    commands: [],
  };

  render() {
    const { formatDropdown, onChange, value, commands, defaultArgs, classes } =
      this.props;
    return (
      <Dropdown
        className={classes.selector}
        disabled={false}
        helperText="please select your command"
        label="command"
        fullWidth
        id="id"
        options={formatDropdown(
          commands,
          (com) => `${com.className},${com.systemcommand}`,
          (com) => com.systemcommand
        )}
        value={value}
        onChange={onChange(defaultArgs)}
      />
    );
  }
}

export default withStyles(styles)(SystemCommandSelector);
