import { useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ArrowForward } from '@onc/icons';
import { TextButton } from 'library/CompositeComponents/button/Buttons';

const STYLES = (theme) => ({
  diffStyle: {
    display: 'flex',
  },
  field: {
    display: 'flex',
    alignSelf: 'center',
    minWidth: theme.spacing(20),
    flexBasis: theme.spacing(24),
    flexGrow: theme.spacing(0.5),
    flexShrink: theme.spacing(0.5),
  },
  fieldName: {
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    flexGrow: 0,
    flexShrink: 0,
  },
  seeButton: {
    marginLeft: '-7px',
    flexGrow: 0,
    flexShrink: 0,
  },
  arrow: {
    justifyContent: 'center',
    alignSelf: 'center',
    minWidth: theme.spacing(4),
    flexBasis: theme.spacing(6),
    flexGrow: theme.spacing(0.5),
    flexShrink: theme.spacing(0.5),
  },
  change: {
    wordWrap: 'break-word',
    height: 'auto',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    flexBasis: theme.spacing(33),
    flexGrow: theme.spacing(0.5),
    flexShrink: theme.spacing(0.5),
  },
});

const DifferenceEntry = ({ diff = undefined, classes = undefined }) => {
  DifferenceEntry.propTypes = {
    diff: PropTypes.shape({
      fieldName: PropTypes.string,
    }),
    classes: PropTypes.shape({
      diffStyle: PropTypes.string,
      pre: PropTypes.string,
      post: PropTypes.string,
      commentPopup: PropTypes.string,
    }),
  };

  const [commentExpanded, setCommentExpanded] = useState(false);

  const handleClick = () => () => setCommentExpanded(!commentExpanded);

  const checkIfCommentValid = (value, fieldName) =>
    fieldName === 'comment' && value?.length > 30;

  const crop = (value, fieldName) => {
    if (checkIfCommentValid(value, fieldName)) {
      let valueCut = value.slice(0, 30);
      valueCut = valueCut.concat('...');
      return valueCut;
    }
    return value;
  };

  const process = (value, fieldName) => {
    const zeroWidthSpace = '\u200b';

    if (fieldName === 'startDate' || fieldName === 'endDate') {
      return `${value.slice(0, 10)}${zeroWidthSpace}${value.slice(10, 24)}`;
    }

    if (
      fieldName === 'referenceLat' ||
      fieldName === 'referenceLon' ||
      fieldName === 'referenceDepth'
    ) {
      return parseFloat(value.toFixed(4));
    }

    return value;
  };

  return (
    <div className={classes.diffStyle}>
      <div className={classes.field}>
        <div className={classes.fieldName} id="fieldName">
          {diff.fieldName}
        </div>
        <div className={classes.seeButton}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {checkIfCommentValid(diff.right, diff.fieldName) ||
          checkIfCommentValid(diff.left, diff.fieldName) ? (
            commentExpanded ? (
              <TextButton
                translationKey="common.buttons.seeLess"
                onClick={handleClick()}
                style={{ textTransform: 'lowercase' }}
                id="seeLess"
                underline="hover"
                size="small"
              />
            ) : (
              <TextButton
                translationKey="common.buttons.seeMore"
                onClick={handleClick()}
                style={{ textTransform: 'lowercase' }}
                id="seeMore"
                underline="hover"
                size="small"
              />
            )
          ) : null}
        </div>
      </div>
      <div className={classes.change} id="pre">
        {/* eslint-disable-next-line no-nested-ternary */}
        {diff.left
          ? commentExpanded
            ? process(diff.left, diff.fieldName)
            : crop(process(diff.left, diff.fieldName), diff.fieldName)
          : 'NaN'}
      </div>
      <ArrowForward fontSize="small" className={classes.arrow} />
      <div className={classes.change} id="post">
        {/* eslint-disable-next-line no-nested-ternary */}
        {diff.right
          ? commentExpanded
            ? process(diff.right, diff.fieldName)
            : crop(process(diff.right, diff.fieldName), diff.fieldName)
          : 'NaN'}
      </div>
    </div>
  );
};

export default withStyles(STYLES)(DifferenceEntry);
