import React from 'react';
import {
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'base-components';
import SeaTubeDeleteButton from 'domain/AppComponents/sea-tube/SeaTubeDeleteButton';
import { CancelButton } from 'library/CompositeComponents/button/Buttons';

type DeleteDialogProps = {
  /** Open: Flag that controls whether or not the dialog is showing */
  open: boolean;

  /**
   * Title: A string that represents the title of the dialog. It should be
   * formatted as 'Delete [Item Name]?' where [Item Name] is a dynamic string.
   */
  title: `Delete ${string}?`;

  /**
   * OnDelete: A function that will be called when the delete action is
   * confirmed. This function should handle the logic for deleting the item.
   */
  onDelete: () => void;

  /**
   * OnCancel: A function that will be called when the cancel action is
   * triggered. This function should handle the logic for closing the dialog
   * without deleting the item.
   */
  onCancel: () => void;

  /** Message: A string that represents the message of the dialog. */
  message?: string;

  /**
   * Loading: A boolean that controls whether or not the dialog is in a loading
   * state.
   */
  loading?: boolean;

  /**
   * Children (optional): React nodes or components that are rendered inside the
   * dialog. This can be used to add additional content or custom elements to
   * the dialog.
   */
  children?: React.ReactNode;

  /**
   * MaxWidth (optional): Specifies the maximum width of the dialog. It can take
   * predefined string values 'xs', 'sm', 'md', 'lg', or 'xl', which represent
   * different size options.
   */
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  title,
  onCancel,
  onDelete,
  loading = false,
  message = undefined,
  children = undefined,
  maxWidth = 'xs',
}) => (
  <Dialog open={open} fullWidth onClose={onCancel} maxWidth={maxWidth}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {message && <DialogContentText>{message}</DialogContentText>}
      {children}
    </DialogContent>
    <DialogActions>
      <CancelButton disabled={loading} onClick={onCancel} />
      <SeaTubeDeleteButton disabled={loading} onClick={onDelete} />
    </DialogActions>
  </Dialog>
);

export default DeleteDialog;
