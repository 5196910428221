import { memo, useState } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormControlLabel,
} from 'base-components';
import {
  SaveButton,
  CancelButton,
} from 'library/CompositeComponents/button/Buttons';

const styles = (theme: Theme) =>
  createStyles({
    buttonText: {
      color: theme.palette.error.main,
    },
    saveButton: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  onCancel: () => void;
  onConfirm: (doNotShow: boolean) => void;
}

const HistoricalConfirmationDialog: React.VFC<Props> = (props: Props) => {
  const { classes, open, onCancel, onConfirm } = props;
  const [doNotShow, setDoNotShow] = useState(false);

  const handleConfirm = () => {
    onConfirm(doNotShow);
    setDoNotShow(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are currently in Historical mode. Are you sure that you want to
          save the current annotation?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={doNotShow}
              onChange={() => setDoNotShow(!doNotShow)}
            />
          }
          label="Do not show this message again"
        />
        <CancelButton className={classes.buttonText} onClick={onCancel} />
        <SaveButton className={classes.saveButton} onClick={handleConfirm} />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(memo(HistoricalConfirmationDialog));
