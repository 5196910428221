import * as React from 'react';
import { Grid, TextField } from 'base-components';
import Filter, {
  FilterImplementationProps,
  FilterValue,
  SummaryValues,
} from 'library/CompositeComponents/filter/Filter';

export interface DateFilterValue extends FilterValue {
  minDepth?: number;
  maxDepth?: number;
}

interface Props extends FilterImplementationProps {
  // FilterEvent causes errors in annotationSearchFilters.tsx
  onChange?: any;
  name: string;
  value?: DateFilterValue;
  title?: string;
}

const DepthFilter: React.FC<Props> = ({
  onChange = undefined,
  name,
  value = undefined,
  onExpand,
  expanded,
  title = undefined,
}: Props) => {
  const renderSummaryValues = () => {
    if (value) {
      const summary: SummaryValues[] = [];
      const { minDepth, maxDepth } = value;
      if (minDepth && maxDepth) {
        summary.push({ label: `${minDepth}  to  ${maxDepth}` });
      } else if (minDepth && !maxDepth) {
        summary.push({ label: `Minimum Depth: ${minDepth}` });
      } else if (!minDepth && maxDepth) {
        summary.push({ label: `Maximum Depth: ${maxDepth}` });
      }
      return summary;
    }
    return undefined;
  };

  return (
    <Filter
      title={title || 'Depth (KM)'}
      value={value}
      name={name}
      onChange={onChange}
      summaryValues={renderSummaryValues()}
      onExpand={onExpand}
      expanded={expanded}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            translationKey="earthquake.minDepth"
            name="minDepth"
            fullWidth
            value={value.minDepth || ''}
            onChange={(e) => onChange(e.target.value)}
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            translationKey="earthquake.maxDepth"
            name="maxDepth"
            fullWidth
            value={value.maxDepth || ''}
            onChange={(e) => onChange(e.target.value)}
            disabled={false}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default DepthFilter;
