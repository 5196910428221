// import the original type declarations
import i18next, {
  type TOptions as i18nextToptions,
  t as i18nextT,
} from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './translations/en/translations.json';

import frTranslations from './translations/fr/translations.json';

// Recursive type to handle any depth of nested keys
type NestedKeys<T> = {
  [K in keyof T]: T[K] extends object
    ? `${string & K}.${NestedKeys<T[K]>}` | `${string & K}`
    : `${string & K}`;
}[keyof T];

// Create the translation type. This does allow keys of "button", "seatube" or whatever but it works pretty well otherwise.
// TODO: Only allow leaf nodes to be in this type
export type TranslationType = NestedKeys<typeof translations>;
export type TOptions = i18nextToptions;
export const t = i18nextT;

i18next.use(initReactI18next).init({
  lng: 'en',
  debug: false,
  resources: {
    en: { translation: translations },
    fr: { translation: frTranslations },
  },
});

export default i18next;
