import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ThemeProvider } from '@emotion/react';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Loading } from '@onc/composite-components';
import { AddAPhoto, InfoOutlined } from '@onc/icons';
import { DenseThemeProvider, oceans3ThemeDark } from '@onc/theme';
import { NestedMenuItem, PipWindow } from 'base-components';
import PlaylistItemEndCard from 'domain/AppComponents/video/PlaylistItemEndCard';
import VideoInformationDialog from 'domain/AppComponents/video/VideoInformationDialog';
import DeviceWebServiceWithToken from 'domain/services/DeviceWebServiceWithToken';
import NextVideoButton from 'library/CompositeComponents/video/controls/NextVideoButton';
import PrevVideoButton from 'library/CompositeComponents/video/controls/PrevVideoButton';
import PlaylistVideoSlider from 'library/CompositeComponents/video/playlist-player/PlaylistVideoSlider';
import Environment from 'util/Environment';
import usePipWindow from 'util/hooks/usePipWindow';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import ControlPanel from '../ControlPanel';
import PictureInPictureButton from '../controls/PictureInPictureButton';
import PlaybackRateMenuItem from '../controls/PlaybackRateMenuItem';
import PlayPauseButton from '../controls/PlayPauseButton';
import RecordVideoButton from '../controls/RecordVideoButton';
import OncVideoPlayer, { VideoProgress } from '../OncVideoPlayer';
import SeamlessVideoVolume from '../SeamlessVideoVolume';
import Snapshots from '../Snapshots';
import VideoRestartButton from '../VideoRestartButton';
import VideoSeekOptions from '../VideoSeekOptions';
import VideoShareControl from '../VideoShareControl';

export type VideoClip = {
  url: string;
  filename: string;
  dateFrom: Moment;
  dateTo?: Moment;
  deviceCode?: string;
  fileLocation?: string;
};

export type PlaylistVideoPlayerProps = {
  id: string;
  files: VideoClip[];
  currentFileName: string;
  seekToDate?: Moment;
  onProgress?: (timestamp: Moment) => void;
  onSeekComplete?: () => void;
  onClipChange?: (filename: string) => void;
  shareUrl?: string;
  autoPlayNext?: boolean;
};

const PlaylistVideoPlayer: React.FC<PlaylistVideoPlayerProps> = ({
  id,
  files,
  currentFileName,
  seekToDate = undefined,
  onProgress = () => {},
  onSeekComplete = () => {},
  onClipChange = () => {},
  shareUrl = undefined,
  autoPlayNext = true,
}) => {
  const { onError } = useSnackbars();
  const [device, isLoading, fetchDevice] = useWebService({
    method: DeviceWebServiceWithToken.get,
  });

  const isLoggedIn = Environment.isUserLoggedIn();

  // State Variables
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [seekTo, setSeekTo] = useState<number | null>(null);
  const [secondsPlayed, setSecondsPlayed] = useState(0);
  const [currentTimestamp, setCurrentTimestamp] = useState(undefined);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isErrored, setIsErrored] = useState(false);
  const [showEndCard, setShowEndCard] = useState<boolean>(false);
  const [volume, setVolume] = useState(0);
  const [openSnapshotDialog, setOpenSnapshotDialog] = useState(false);
  const [snapshotTime, setSnapshotTime] = useState<Moment | null>(null);
  const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);

  const [metadata, setMetadata] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0,
  });

  const currentFile = files[currentFileIndex];

  const [playbackRate, setPlaybackRate] = useState(1);

  const prevFilesRef = useRef(files);
  const containerRef = useRef<HTMLDivElement>(null);

  const memoizedUrl = useMemo(() => {
    if (
      _.isEqual(currentFile?.url, prevFilesRef.current[currentFileIndex]?.url)
    ) {
      prevFilesRef.current = files;
      return prevFilesRef.current[currentFileIndex]?.url;
    }
    return currentFile?.url;
  }, [currentFile?.url, currentFileIndex, files]);

  const { isSupported, requestPipWindow, pipWindow, closePipWindow } =
    usePipWindow();

  const startPip = useCallback(() => {
    requestPipWindow(640, 360);
  }, [requestPipWindow]);

  useEffect(() => {
    if (seekToDate) {
      if (seekToDate !== currentTimestamp) {
        setCurrentTimestamp(seekToDate);
      }
      const totalseconds = seekToDate.diff(currentFile.dateFrom, 'seconds');
      setSeekTo(totalseconds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seekToDate]);

  useEffect(() => {
    fetchDevice({ deviceCode: currentFile?.deviceCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile]);

  useEffect(() => {
    prevFilesRef.current = files;
  }, [currentTimestamp, files]);

  useEffect(() => {
    if (currentFileName !== files[currentFileIndex]?.filename) {
      const newFileIndex = files.findIndex(
        (file) => file.filename === currentFileName
      );
      setCurrentFileIndex(newFileIndex);
      setCurrentTimestamp(files[newFileIndex]?.dateFrom);
      setSeekTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFileName]);

  // Call the onClipChange callback when the current file changes
  useEffect(() => {
    setShowEndCard(false);
    onClipChange(files[currentFileIndex]?.filename);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFileIndex]);

  const handleProgress = (progress: VideoProgress) => {
    if (isSeeking) return;
    const updatedTimestamp = currentFile.dateFrom
      .clone()
      .add(progress.playedSeconds * 1000, 'milliseconds');
    setCurrentTimestamp(updatedTimestamp);
    setSecondsPlayed(progress.playedSeconds);
    onProgress(updatedTimestamp);

    // Check if the played seconds have reached the duration of the current file
    if (progress.played >= 1) {
      if (autoPlayNext) {
        const nextFileIndex = currentFileIndex + 1;
        if (nextFileIndex < files.length) {
          // Move to the next file and reset the seek position
          setCurrentFileIndex(nextFileIndex);
          setSeekTo(0);
        } else {
          // Show the end card if there are no more files to play
          setShowEndCard(true);
        }
      } else {
        setIsPlaying(false);
        setShowEndCard(true);
      }
    }
  };

  const handleSeekComplete = () => {
    // Reset the seek position after the seek is completed
    setIsSeeking(false);
    setSeekTo(null);
    onSeekComplete();
  };

  const handleForward = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newTimestamp = currentTimestamp?.add(30, 'seconds');
    setCurrentTimestamp(newTimestamp);
    setSecondsPlayed(secondsPlayed + 30);
    setSeekTo(secondsPlayed + 30);
    e.stopPropagation();
  };

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newTimestamp = currentTimestamp?.subtract(30, 'seconds');
    setCurrentTimestamp(newTimestamp);
    setSecondsPlayed(secondsPlayed - 30);
    setSeekTo(secondsPlayed - 30);
    e.stopPropagation();
  };

  const handleNextVideo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const nextFileIndex = currentFileIndex + 1;
    if (nextFileIndex < files.length) {
      setCurrentFileIndex(nextFileIndex);
      setCurrentTimestamp(files[nextFileIndex].dateFrom);
      setIsPlaying(true);
    }
    setShowEndCard(false);
  };

  const handlePrevVideo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const prevFileIndex = currentFileIndex - 1;
    if (prevFileIndex >= 0) {
      setCurrentFileIndex(prevFileIndex);
      setCurrentTimestamp(files[prevFileIndex].dateFrom);
      setIsPlaying(true);
    }
    setShowEndCard(false);
  };

  const handleReplay = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setCurrentTimestamp(files[currentFileIndex].dateFrom);
    setSeekTo(0);
    setShowEndCard(false);
    setIsPlaying(true);
  };

  const handleFullscreenToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const buildRightControls = () => {
    const rightControls = [];
    if (isLoggedIn) {
      rightControls.push(
        <RecordVideoButton
          deviceId={device?.data[0].deviceId}
          currentTimestamp={currentTimestamp}
          onIsPlaying={setIsPlaying}
          containerRef={containerRef.current}
        />
      );
    }

    if (isSupported) {
      rightControls.push(
        <PictureInPictureButton
          onClick={pipWindow ? closePipWindow : startPip}
        />
      );
    }

    rightControls.push(
      <VideoShareControl
        containerRef={containerRef.current}
        disabled={!!document.fullscreenElement}
        shareUrl={shareUrl}
      />
    );
    return rightControls;
  };

  const renderSnapshots = () => (
    <Snapshots
      takeSnapshots={openSnapshotDialog}
      deviceId={device.data[0].deviceId}
      time={snapshotTime.toISOString()}
      onSnapshotComplete={() => setOpenSnapshotDialog(false)}
      getFullscreen={() => !!document.fullscreenElement}
      videoContainer={containerRef.current}
    />
  );
  const getInfoMap = (): Map<string, any> => {
    const infoMap = new Map()
      .set('Device Id', device?.data[0]?.deviceId)
      .set('File Name', files[currentFileIndex]?.filename)
      .set('File Path', files[currentFileIndex]?.url);

    if (metadata.height && metadata.width) {
      infoMap.set('Resolution', `${metadata.width} x ${metadata.height}`);
    }
    return infoMap;
  };
  const renderInfoDialog = () => (
    <ThemeProvider theme={oceans3ThemeDark}>
      <DenseThemeProvider>
        <VideoInformationDialog
          open={isInfoDialogOpen}
          container={containerRef.current}
          onClose={() => setInfoDialogOpen(false)}
          infoMap={getInfoMap()}
        />
      </DenseThemeProvider>
    </ThemeProvider>
  );

  /* // TODO - Device ID needed for the below features

  const renderQualityMenuItem = () => {
    if (!qualityOptions) return null;
    return (
      <QualityMenuItem
        options={qualityOptions}
        currentQuality={currentQuality}
        onClick={onChangeQuality}
        containerRef={document.fullscreenElement ? containerRef : undefined}
      />
    );
  };
  */

  const hasNext = currentFileIndex < files.length - 1;
  const hasPrevious = currentFileIndex > 0;

  const renderContent = () => (
    <>
      {renderInfoDialog()}
      {showEndCard && (
        <PlaylistItemEndCard
          onNext={hasNext && handleNextVideo}
          onPrevious={hasPrevious && handlePrevVideo}
          onReplay={handleReplay}
        />
      )}
      <OncVideoPlayer
        id={id}
        containerRef={containerRef}
        url={memoizedUrl}
        seekTo={seekTo}
        isPlaying={isPlaying}
        isMuted={volume === 0}
        volume={volume}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onReady={() => {
          setIsPlaying(true);
          setIsErrored(false);
        }}
        onError={() => {
          if (!isErrored) {
            onError('An error occurred while playing the video.');
            setIsErrored(true);
          }
          setCurrentFileIndex(currentFileIndex + 1);
        }}
        onProgress={handleProgress}
        onMetadata={setMetadata}
        onSeek={handleSeekComplete}
        playbackRate={playbackRate}
        ControlsComponent={
          <ControlPanel
            SeekbarComponent={
              <PlaylistVideoSlider
                startDate={moment.utc(currentFile?.dateFrom)}
                endDate={moment.utc(currentFile?.dateTo)}
                currentTimestamp={currentTimestamp}
                setIsSeeking={setIsSeeking}
                setSeekTo={setSeekTo}
              />
            }
            leftControls={[
              <PrevVideoButton
                disabled={currentFileIndex === 0}
                onClick={handlePrevVideo}
                containerRef={containerRef.current}
              />,
              <PlayPauseButton
                isPlaying={isPlaying}
                onClick={(e) => {
                  setIsPlaying(!isPlaying);
                  e.stopPropagation();
                }}
                containerRef={containerRef.current}
              />,
              <NextVideoButton
                disabled={currentFileIndex === files.length - 1}
                onClick={handleNextVideo}
                containerRef={containerRef.current}
              />,
              <VideoSeekOptions
                handleForward={handleForward}
                handleBack={handleBack}
                containerRef={containerRef.current}
              />,
              <SeamlessVideoVolume
                volume={volume}
                setVolume={setVolume}
                containerRef={containerRef.current}
              />,
            ]}
            rightControls={buildRightControls()}
            moreControls={[
              <PlaybackRateMenuItem
                options={[0.5, 1, 2, 4, 8]}
                playbackRate={playbackRate}
                onClick={setPlaybackRate}
                containerRef={
                  document.fullscreenElement ? containerRef : undefined
                }
              />,
              <NestedMenuItem
                value="video-info"
                label="Video Information"
                name="video-info"
                IconComponent={<InfoOutlined />}
                onClick={() => {
                  setInfoDialogOpen(true);
                }}
              />,
              <NestedMenuItem
                value="take-snapshots"
                label="Take Snapshots"
                name="take-snapshots"
                IconComponent={<AddAPhoto />}
                onClick={() => {
                  setOpenSnapshotDialog(true);
                  setSnapshotTime(currentTimestamp);
                }}
              />,
            ]}
            timestamp={currentTimestamp}
            startDate={currentFile?.dateFrom}
            endDate={currentFile?.dateTo}
            onFullscreen={handleFullscreenToggle}
            containerRef={containerRef.current}
          />
        }
        SplashScreenComponent={
          <VideoRestartButton
            onClick={(e) => {
              setCurrentFileIndex(0);
              setCurrentTimestamp(files[0]?.dateFrom);
              setSeekTo(0);
              e.stopPropagation();
            }}
            endDate={files[files.length - 1]?.dateTo}
            currentTimestamp={currentTimestamp}
            containerRef={containerRef.current}
          />
        }
        showControls={false}
      />
    </>
  );

  const renderPip = () => (
    <PipWindow pipWindow={pipWindow}>{renderContent()}</PipWindow>
  );

  const renderVideoContent = () => {
    if (isLoading) {
      return <Loading />;
    }
    return (
      <>
        {pipWindow ? renderPip() : renderContent()}
        {openSnapshotDialog ? renderSnapshots() : undefined}
      </>
    );
  };

  return renderVideoContent();
};

export default PlaylistVideoPlayer;
