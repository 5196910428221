import * as React from 'react';
import { Moment } from 'moment';
import DataPlayerForm from 'domain/Apps/data-player/DataPlayerForm';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

type DataPlayerWidgetConfigProps = {
  onSave: (values: any) => void;
  onCancel: () => void;
  showTitle: boolean;
  title: string;
  startDate: Moment;
  dataSourceType: 'location' | 'deviceCategory';
  dateSelectorValue: 'latest' | 'dateRange' | string;
  device: {
    deviceCategory: string;
    deviceId: number;
    name: string;
  };
  locations: any[];
  deviceId: number;
  deviceCategoryCode: string;
  isBroadcasting: boolean;
  timeRange: string;
};

const DataPlayerWidgetConfig: React.FC<DataPlayerWidgetConfigProps> = ({
  onSave,
  onCancel,
  title,
  showTitle,
  startDate,
  dataSourceType,
  dateSelectorValue,
  device,
  deviceId,
  deviceCategoryCode,
  isBroadcasting,
  locations,
  timeRange,
}) => (
  <DataPlayerForm
    config={{ device, deviceId, deviceCategoryCode, timeRange, startDate }}
    dataSourceType={dataSourceType}
    dateSelectorValue={dateSelectorValue}
    isForWidget
    isBroadcasting={isBroadcasting}
    locations={locations}
    onCancel={onCancel}
    onSave={onSave}
    title={title}
    showTitle={showTitle}
  />
);
export default withSnackbars(DataPlayerWidgetConfig);
