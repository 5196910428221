import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Loading } from '@onc/composite-components';
import { Box, Divider, ErrorPage, Typography } from 'base-components';
import { HydrophoneToolboxRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import {
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

import useGet from 'util/hooks/useDmasAPI/useGet';
import {
  CUSTOM_HYDROPHONE_LIMIT_OPTION,
  DEFAULT_HYDROPHONE_LIMIT_OPTION,
  FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE,
  RESOURCE_ID_FOR_HYDROPHONE_SEARCH_OPTIONS,
} from './util/DataPlayerConstants';
import ToolboxUtil from './util/ToolboxUtil';

const classes = {
  root: {
    padding: 0,
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
  },
  dropdownPadding: {
    paddingTop: 1,
    paddingBottom: 1,
    minWidth: 35,
  },
  textField: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  typography: {
    paddingTop: 1,
  },
};

// I don't know why the service returns data like this but it does
type FormServicePayload = {
  0: [
    {
      fields: [
        {
          allowAny: {
            upperBound: string;
            lowerBound: string;
          };
          name: string;
        },
      ];
    },
  ];
};

type HydrophoneToolboxFormType = {
  colourPalette: number;
  spectrogramSource: number;
  hydrophoneDataAcquisition: number;
  hydrophoneDataDiversion: number;
  hydrophoneChannel: number;
  upperColourLimit: string;
  lowerColourLimit: string;
  upperFrequencyLimit: string;
};

type HydrophoneToolboxFormProps = {
  onSpectroSubmit: (values: object) => void;
  resourceId?: number;
  hydrophonePresetOptions?: {
    colourPalette: number | string;
    upperColourLimit: number | string;
    lowerColourLimit: number | string;
    spectrogramSource: number | string;
    hydrophoneDataAcquisition: number | string;
    hydrophoneDataDiversion: number | string;
    hydrophoneChannel: number | string;
    spectrogramFrequencyUpperLimit: number | string;
  };
};

const HydrophoneToolboxForm = ({
  onSpectroSubmit,
  resourceId = undefined,
  hydrophonePresetOptions = undefined,
}: HydrophoneToolboxFormProps) => {
  const [useDefaultLimits, setUseDefaultLimits] = useState<boolean>(true); // radio value
  const [renderedOnce, setRenderedOnce] = useState<boolean>(false);

  const formMethods = useForm<HydrophoneToolboxFormType>({ mode: 'onChange' });
  const upperColourLimit = formMethods.watch('upperColourLimit');
  const lowerColourLimit = formMethods.watch('lowerColourLimit');

  const searchOptions = useGet<
    FormServicePayload,
    {
      formTypeId: number;
      resourceTypeId: number;
      sourceId: number;
      resourceId: number;
    }
  >(
    'FormService',
    {
      operation: undefined,
    },
    {
      formTypeId: 2,
      resourceTypeId: 1500,
      sourceId: 3,
      // default to hard coded resourceId if id for this device not found
      resourceId: resourceId || RESOURCE_ID_FOR_HYDROPHONE_SEARCH_OPTIONS,
    }
  );

  if (searchOptions.isLoading) {
    return <Loading />;
  }

  if (searchOptions.error) {
    return <ErrorPage />;
  }

  const allOptions = searchOptions.data?.[0]?.[0]?.fields;

  const getDropdownOptions = (formatFieldId) => {
    const option = allOptions?.find((opt) => opt?.name === formatFieldId);
    return option
      ? ToolboxUtil.getLabelAndValueFromOption(option).labelValueArray
      : [];
  };

  const getMinMaxValues = (formatFieldId) => ({
    verifyMin: (val) => {
      const lowerBound = Number(
        allOptions?.find((option) => option.name === formatFieldId)?.allowAny
          ?.lowerBound
      );
      return (
        isNaN(lowerBound) ||
        Number(val) >= lowerBound ||
        `Value must be greater than ${lowerBound}`
      );
    },
    verifyMax: (val) => {
      const upperBound = Number(
        allOptions?.find((option) => option.name === formatFieldId)?.allowAny
          ?.upperBound
      );
      return (
        isNaN(upperBound) ||
        Number(val) <= upperBound ||
        `Value must be less than ${upperBound}`
      );
    },
  });

  const colourPaletteDropdownOptions = getDropdownOptions(
    FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.COLOUR_PALETTE_ID
  );
  const spectrogramSourceDropdownOptions = getDropdownOptions(
    FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.SPECTROGRAM_SOURCE_ID
  );
  const hydrophoneDataAcquisitionDrowndownOptions = getDropdownOptions(
    FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.HYDROPHONE_DATA_ACQUISITION_ID
  );
  const hydrophoneDataDiversionDropdownOptions = getDropdownOptions(
    FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.HYDROPHONE_DATA_DIVERSION_ID
  );
  const hydrophoneChannelDropdownOptions = getDropdownOptions(
    FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.HYDROPHONE_CHANNEL_ID
  );
  const colourPaletteInitialValue =
    hydrophonePresetOptions?.colourPalette ||
    ToolboxUtil.generateInitialValue(colourPaletteDropdownOptions);
  const spectrogramSourceInitialValue =
    hydrophonePresetOptions?.spectrogramSource ||
    ToolboxUtil.generateInitialValue(spectrogramSourceDropdownOptions);
  const hydrophoneDataAcquisitionInitialValue =
    hydrophonePresetOptions?.hydrophoneDataAcquisition ||
    ToolboxUtil.generateInitialValue(hydrophoneDataAcquisitionDrowndownOptions);
  const hydrophoneDataDiversionInitialValue =
    hydrophonePresetOptions?.hydrophoneDataDiversion ||
    ToolboxUtil.generateInitialValue(hydrophoneDataDiversionDropdownOptions);
  const hydrophoneChannelInitialValue =
    hydrophonePresetOptions?.hydrophoneChannel ||
    ToolboxUtil.generateInitialValue(hydrophoneChannelDropdownOptions);
  const upperColourLimitInitialValue =
    hydrophonePresetOptions?.upperColourLimit?.toString() ||
    allOptions?.find(
      (option) =>
        option.name ===
        FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.UPPER_COLOUR_LIMIT_ID
    )?.allowAny?.upperBound ||
    '0';
  const lowerColourLimitInitialValue =
    hydrophonePresetOptions?.lowerColourLimit?.toString() ||
    allOptions?.find(
      (option) =>
        option.name ===
        FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.LOWER_COLOUR_LIMIT_ID
    )?.allowAny?.lowerBound ||
    '0';
  const upperFrequencyLimitInitialValue =
    hydrophonePresetOptions?.spectrogramFrequencyUpperLimit?.toString() ||
    allOptions?.find(
      (option) =>
        option.name ===
        FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.SPECTROGRAM_FREQUENCY_UPPER_LIMIT_ID
    )?.allowAny?.lowerBound ||
    '0';

  const renderSpectrogramOptions = () => (
    <>
      <FormDropdown
        name="colourPalette"
        label="Colour Palette"
        sx={classes.dropdownPadding}
        options={colourPaletteDropdownOptions}
        fullWidth
      />
      {spectrogramSourceInitialValue ? (
        <FormDropdown
          sx={classes.dropdownPadding}
          name="spectrogramSource"
          label="Spectrogram Source"
          options={spectrogramSourceDropdownOptions}
          fullWidth
        />
      ) : (
        <> </>
      )}
      {hydrophoneDataAcquisitionInitialValue ? (
        <FormDropdown
          sx={classes.dropdownPadding}
          name="hydrophoneDataAcquisition"
          label="Hydrophone Data Acquisition"
          options={hydrophoneDataAcquisitionDrowndownOptions}
          fullWidth
        />
      ) : (
        <> </>
      )}
      {hydrophoneDataDiversionInitialValue ? (
        <FormDropdown
          sx={classes.dropdownPadding}
          name="hydrophoneDataDiversion"
          label="Hydrophone Data Diversion"
          options={hydrophoneDataDiversionDropdownOptions}
          fullWidth
        />
      ) : (
        <> </>
      )}
      {hydrophoneChannelInitialValue ? (
        <FormDropdown
          sx={classes.dropdownPadding}
          name="hydrophoneChannel"
          label="Hydrophone Channel"
          options={hydrophoneChannelDropdownOptions}
          fullWidth
        />
      ) : (
        <> </>
      )}
      <Typography variant="subtitle1">Intensity Bounds</Typography>
      <Divider />

      <HydrophoneToolboxRadioGroup
        title="customLimit"
        value={
          useDefaultLimits
            ? DEFAULT_HYDROPHONE_LIMIT_OPTION
            : CUSTOM_HYDROPHONE_LIMIT_OPTION
        }
        presetValue={DEFAULT_HYDROPHONE_LIMIT_OPTION}
        customValue={CUSTOM_HYDROPHONE_LIMIT_OPTION}
        defaultOptionLabel="Default (use device calibration values)"
        customOptionLabel="Custom limits"
        presetClick={() => {
          setUseDefaultLimits(true);
          formMethods.resetField('upperColourLimit');
          formMethods.resetField('lowerColourLimit');
          formMethods.resetField('upperFrequencyLimit');
        }}
        customClick={() => {
          setUseDefaultLimits(false);
        }}
      />

      <Typography
        sx={classes.typography}
        variant="subtitle2"
        color="textSecondary"
      >
        Upper Colour Limit
      </Typography>

      <FormTextField
        sx={classes.textField}
        name="upperColourLimit"
        id="upperColourLimitField"
        translationKey="device.upperColourLimit"
        disabled={useDefaultLimits}
        fullWidth
        rules={{
          validate: {
            compareOtherField: (val) =>
              Number(val) >= Number(lowerColourLimit) ||
              'Upper colour limit cannot be less than lower colour limit',
            ...getMinMaxValues(
              FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.UPPER_COLOUR_LIMIT_ID
            ),
          },
        }}
        type="number"
      />
      <Typography
        sx={classes.typography}
        variant="subtitle2"
        color="textSecondary"
      >
        Lower Colour Limit
      </Typography>

      <FormTextField
        sx={classes.textField}
        name="lowerColourLimit"
        id="lowerColourLimitField"
        translationKey="device.lowerColourLimit"
        disabled={useDefaultLimits}
        fullWidth
        rules={{
          validate: {
            compareOtherField: (val) =>
              Number(val) <= Number(upperColourLimit) ||
              'Lower colour limit cannot be greater than upper colour limit',
            ...getMinMaxValues(
              FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.LOWER_COLOUR_LIMIT_ID
            ),
          },
        }}
        type="number"
      />

      <Typography
        sx={classes.typography}
        variant="subtitle2"
        color="textSecondary"
      >
        Upper Frequency Limit
      </Typography>

      <FormTextField
        sx={classes.textField}
        name="upperFrequencyLimit"
        id="upperFrequencyLimitField"
        translationKey="device.upperFrequencyLimit"
        disabled={useDefaultLimits}
        fullWidth
        rules={{
          validate: getMinMaxValues(
            FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.SPECTROGRAM_FREQUENCY_UPPER_LIMIT_ID
          ),
        }}
        type="number"
      />
    </>
  );

  /**
   * UseForm needs to be called non-conditionally, but values are undefined
   * while useGet is querying, so we reset and re-render after useGets are done
   */
  if (!renderedOnce) {
    formMethods.reset({
      colourPalette: colourPaletteInitialValue,
      spectrogramSource: spectrogramSourceInitialValue,
      hydrophoneDataAcquisition: hydrophoneDataAcquisitionInitialValue,
      hydrophoneDataDiversion: hydrophoneDataDiversionInitialValue,
      hydrophoneChannel: hydrophoneChannelInitialValue,
      upperColourLimit: upperColourLimitInitialValue,
      lowerColourLimit: lowerColourLimitInitialValue,
      upperFrequencyLimit: upperFrequencyLimitInitialValue,
    });
    if (
      (hydrophonePresetOptions?.upperColourLimit &&
        hydrophonePresetOptions?.upperColourLimit !==
          upperColourLimitInitialValue) ||
      (hydrophonePresetOptions?.lowerColourLimit &&
        hydrophonePresetOptions?.lowerColourLimit !==
          lowerColourLimitInitialValue) ||
      (hydrophonePresetOptions?.spectrogramFrequencyUpperLimit &&
        hydrophonePresetOptions?.spectrogramFrequencyUpperLimit !==
          upperFrequencyLimitInitialValue)
    ) {
      setUseDefaultLimits(false);
    }
    setRenderedOnce(true);
  }

  const handleSubmit = (values: HydrophoneToolboxFormType) => {
    onSpectroSubmit({
      ...values,
      customLimit: useDefaultLimits
        ? DEFAULT_HYDROPHONE_LIMIT_OPTION
        : CUSTOM_HYDROPHONE_LIMIT_OPTION,
      // formMethods defaults to undefined if a field is disabled
      upperColourLimit: useDefaultLimits
        ? Number(upperColourLimitInitialValue)
        : Number(values.upperColourLimit),
      lowerColourLimit: useDefaultLimits
        ? Number(lowerColourLimitInitialValue)
        : Number(values.lowerColourLimit),
      upperFrequencyLimit: useDefaultLimits
        ? Number(upperFrequencyLimitInitialValue)
        : Number(values.upperFrequencyLimit),
    });
  };

  return (
    <Box sx={classes.root}>
      <Typography variant="subtitle1" color="inherit">
        Spectrogram
      </Typography>
      <Divider />
      <Form onSubmit={handleSubmit} formMethods={formMethods}>
        {renderSpectrogramOptions()}
        <Box sx={classes.formButtons}>
          <ContainedButton
            translationKey="common.buttons.apply"
            type="submit"
          />
          <TextButton
            translationKey="common.buttons.reset"
            onClick={() => {
              formMethods.reset();
              setUseDefaultLimits(true);
            }}
          />
        </Box>
      </Form>
    </Box>
  );
};

export default HydrophoneToolboxForm;
