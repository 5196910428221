import { ProductNames } from './GeospatialSearchTypes';

export const PLOT_DATA_PRODUCT = 'CSMPP';
export const COR_AND_CSV_DATA_PRODUCT = 'OTFCPD';
export const XML_DATA_PRODUCT = 'CFISOXML';
export const ANNOTATION_DATA_PRODUCT = 'CFCSVAN';
export const ALL_DATA_PRODUCTS = 'ALL';
export const COMPLETED = 'Completed';
export const NOT_DOWNLOADED = 'Not Downloaded';
export const UNASSIGNED_CAST_SEARCH_TREE_NODE_ID = 1852;
export const UNASSIGNED_CASTS = 'Unassigned Casts';
export const ERROR = 'Error';
export const USER_NOT_LOGGED_IN = 'Error: User Not Logged In';
export const RESTRICTED = 'Restricted';
export const SELECTED_PRODUCTS = 'Selected Products';
export const TASK_COMPLETE = 'Completed';
export const UNASSINGNED_STNID = 1852;

export const CAST_PRODUCT_DETAILS = {
  CSMPP: {
    deviceCategoryCode: 'CTD',
    dpoParams: {
      dpo_includePiggybackSensors: 0,
      dpo_sensorstoinclude: 1,
      dpo_binSize: -1,
      dpo_percentAcceptance: -1,
      dpo_sensorPlotPosition1: 'Default',
      dpo_sensorPlotPosition2: 'Default',
      dpo_sensorPlotPosition3: 'Default',
      dpo_sensorPlotPosition4: 'Default',
      dpo_sensorPlotPosition5: 'No',
      dpo_sensorPlotPosition6: 'No',
    },
  },
  OTFCPD: {
    deviceCategoryCode: 'CTD',
    dpoParams: {
      dpo_fileBreaks: 0,
      dpo_includePiggybackSensors: 0,
      dpo_sensorstoinclude: 1,
      dpo_binSize: -1,
      dpo_percentAcceptance: -1,
    },
  },
  CFISOXML: {
    deviceCategoryCode: 'CTD',
  },
  CFCSVAN: {
    deviceCategoryCode: 'NAV',
  },
};

export const DEFAULT_PRODUCT_MAPPING = [
  {
    key: 'csv',
    productName: 'OTFCPD' as ProductNames,
    productType: 'CSV',
    extension: 'csv',
  },
  {
    key: 'cor',
    productName: 'OTFCPD' as ProductNames,
    productType: 'COR',
    extension: 'cor',
  },
  {
    key: 'plot',
    productName: 'CSMPP' as ProductNames,
    productType: 'Plot',
    extension: 'png',
  },
  {
    key: 'annotation',
    productName: 'CFCSVAN' as ProductNames,
    productType: 'Annotation',
    extension: 'csv',
  },
  {
    key: 'metadata',
    productName: 'CFISOXML' as ProductNames,
    productType: 'Metadata',
    extension: 'xml',
  },
];
