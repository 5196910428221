/* eslint-disable react/require-default-props */
import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add as AddIcon } from '@onc/icons';
import { TableOld as Table, TextField, Typography } from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  EditIconButton,
  DeleteIconButton,
} from 'domain/AppComponents/IconButtons';
import MultiQueueService from 'domain/AppComponents/multi-queue/service/MultiQueueService';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';

import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import Panel from 'library/CompositeComponents/panel/Panel';
import DateFormatUtils from 'util/DateFormatUtils';
import CreateShorestationDialog from './CreateShorestationDialog';

const styles = (theme) => ({
  container: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'sticky',
    willChange: 'transform',
    bottom: '2px',
    backgroundColor: 'white',
  },
});

const SHORESTATION_HEADERS = [
  { title: 'JVM ID', name: 'shorestationId' },
  { title: 'Hostname', name: 'shorestation' },
  { title: 'Primary Shorestation', name: 'primaryShorestation' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: ' ', name: 'icons' },
];

const CONFIGURE_ENABLE = 'enable';
const CONFIGURE_DISABLE = 'disable';

const CreateShorestationButton = (props) => {
  const { onClick, disabled } = props;
  return (
    <ContainedButton
      translationKey="multiqueue.createShorestation"
      onClick={onClick}
      startIcon={<AddIcon />}
      disabled={disabled}
    />
  );
};

CreateShorestationButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const DisableMultiQueueButton = (props) => {
  const { onClick, disabled } = props;
  return (
    <ContainedButton
      translationKey="multiqueue.disableMultiqueue"
      onClick={onClick}
      startIcon={<AddIcon />}
      disabled={disabled}
    />
  );
};

DisableMultiQueueButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const EnableMultiQueueButton = (props) => {
  const { onClick, disabled } = props;
  return (
    <ContainedButton
      translationKey="multiqueue.enableMultiqueue"
      onClick={onClick}
      startIcon={<AddIcon />}
      disabled={disabled}
    />
  );
};

EnableMultiQueueButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

class MultiQueueLocationPanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    refreshTree: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    node: NodePropType.isRequired,
    permission: PropTypes.string.isRequired,
  };

  getRowId = (row) => row.shorestationId;

  state = {
    showCreateDialog: false,
    showDeleteDialog: false,
    showConfigureDialog: false,
    selections: [],
  };

  componentDidUpdate(prevProps) {
    const { node } = this.props;
    const { node: prevNode } = prevProps;
    if (node && prevNode !== node) {
      this.clearSelections();
    }
  }

  clearSelections = () => {
    this.setState({
      selections: [],
    });
  };

  openCreateDialog = () => {
    this.setState({
      showCreateDialog: true,
    });
  };

  closeCreateDialog = () => {
    this.setState({
      showCreateDialog: false,
      rowToBeEdited: undefined,
    });
  };

  openDeleteDialog = () => {
    this.setState({
      showDeleteDialog: true,
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      showDeleteDialog: false,
    });
  };

  openConfigureEnableDialog = () => {
    this.setState({
      configureType: CONFIGURE_ENABLE,
      showConfigureDialog: true,
    });
  };

  openConfigureDisableDialog = () => {
    this.setState({
      configureType: CONFIGURE_DISABLE,
      showConfigureDialog: true,
    });
  };

  closeConfigureDialog = () => {
    this.setState({
      configureType: undefined,
      showConfigureDialog: false,
    });
  };

  onSelectionChange = (selections) => {
    this.setState({
      selections,
    });
  };

  renderConfirmDeleteDialog = () => {
    const { selections, showDeleteDialog } = this.state;
    const shorestationIds = selections ? selections.join(',') : '';
    const content = (
      <Typography variant="subtitle1" align="center">
        Are you sure you want to delete jvmIds: {shorestationIds}.
      </Typography>
    );
    return (
      <ConfirmationDialog
        open={showDeleteDialog}
        disableBackdropClick
        title="Delete Queues"
        content={content}
        onCancel={this.closeDeleteDialog}
        onConfirm={this.handleDeleteShorestation}
      />
    );
  };

  renderConfigureDialog = () => {
    const { node } = this.props;
    const { showConfigureDialog, configureType } = this.state;
    let content = <div />;
    if (configureType === CONFIGURE_DISABLE) {
      content = (
        <Typography variant="subtitle1" align="center">
          Are you sure you want to disable multi queue at{' '}
          {node.multiQueueTreeNodeName}? All data will flow through the primary
          shorestation for this location. The primary shorestation will need to
          be restarted for this change to take effect.
        </Typography>
      );
    } else if (configureType === CONFIGURE_ENABLE) {
      content = (
        <Typography variant="subtitle1" align="center">
          Are you sure you want to enable multi queue at{' '}
          {node.multiQueueTreeNodeName}? Data will be redirected to
          shorestations based on the queues defined at this location. All
          shorestations at this location will need to be restarted for this
          change to take effect.
        </Typography>
      );
    }
    return (
      <ConfirmationDialog
        open={showConfigureDialog}
        disableBackdropClick
        title="Configure MultiQueue"
        content={content}
        onCancel={this.closeConfigureDialog}
        onConfirm={this.handleConfigureMultiQueue}
      />
    );
  };

  handleConfigureMultiQueue = () => {
    const { node, onInfo, onError, refreshTree } = this.props;
    const { configureType } = this.state;
    const isEnabled = configureType === CONFIGURE_ENABLE;
    MultiQueueService.configureMultiQueue(isEnabled, node.objectId)
      .then(() => {
        refreshTree();
        onInfo(`Successfully ${configureType}d multiqueue.`);
      })
      .catch(() => {
        onError(`failed to ${configureType} multiqueue.`);
      });
    this.closeConfigureDialog();
  };

  handleDeleteShorestation = () => {
    const { refreshTree, onError } = this.props;
    const { selections } = this.state;
    if (selections.length > 0) {
      MultiQueueService.deleteShorestation(selections.join(','))
        .then(() => {
          refreshTree();
          this.setState({
            selections: [],
            showDeleteDialog: false,
          });
        })
        .catch(() => {
          onError(`failed to delete queueId ${selections.join(',')}`);
        });
    }
  };

  renderShorestations = () => {
    const { permission } = this.props;
    if (permission !== 'RW') {
      return this.renderShorestationsRO();
    }
    return this.renderShorestationsRW();
  };

  renderShorestationsRW = () => {
    const { node } = this.props;
    return node.children.map((row) => ({
      shorestationId: row.objectId,
      shorestation: row.multiQueueTreeNodeName,
      primaryShorestation: String(row.primaryShorestation),
      modifyDate: DateFormatUtils.formatDate(row.modifyDate, 'full'),
      modifyBy: row.modifyBy,
      icons: (
        <>
          <EditIconButton
            onClick={() => {
              this.setState({
                showCreateDialog: true,
                rowToBeEdited: row,
              });
            }}
            aria-label="Edit JVM"
            size="small"
          />
          <DeleteIconButton
            onClick={() => {
              this.setState({
                selections: [row.objectId],
              });
              this.openDeleteDialog();
            }}
            aria-label="Delete JVM"
            size="small"
          />
        </>
      ),
    }));
  };

  renderShorestationsRO = () => {
    const { node } = this.props;
    return node.children.map((row) => ({
      shorestationId: row.objectId,
      shorestation: row.multiQueueTreeNodeName,
      primaryShorestation: String(row.primaryShorestation),
      modifyDate: DateFormatUtils.formatDate(row.modifyDate, 'full'),
      modifyBy: row.modifyBy,
    }));
  };

  renderConfigureButtons = () => {
    const { node } = this.props;
    if (node.enabled) {
      return (
        <DisableMultiQueueButton onClick={this.openConfigureDisableDialog} />
      );
    }
    return <EnableMultiQueueButton onClick={this.openConfigureEnableDialog} />;
  };

  renderButtons = () => {
    const { classes, node, refreshTree, onInfo, onError, permission } =
      this.props;
    const { showCreateDialog, rowToBeEdited } = this.state;
    if (permission !== 'RW') {
      return null;
    }
    return (
      <div className={classes.buttonContainer}>
        <CreateShorestationButton onClick={this.openCreateDialog} />
        <CreateShorestationDialog
          locationNode={node}
          shorestationNode={rowToBeEdited}
          showDialog={showCreateDialog}
          closeDialog={this.closeCreateDialog}
          refreshTree={refreshTree}
          onInfo={onInfo}
          onError={onError}
        />
        {this.renderConfirmDeleteDialog()}
        {this.renderConfigureButtons()}
        {this.renderConfigureDialog()}
      </div>
    );
  };

  renderLocationNodeInformation() {
    const { classes, node } = this.props;
    const { selections } = this.state;
    return (
      <>
        <TextField
          translationKey="multiqueue.defaultQueue"
          classes={{ textField: classes.textField }}
          fullWidth
          value={node.defaultQueue ? node.defaultQueue : ''}
          disabled
        />
        <Table
          showBorders
          columns={SHORESTATION_HEADERS}
          elevation={0}
          showHeaderRow
          rows={this.renderShorestations()}
          onSelectionChange={this.onSelectionChange}
          selection={selections}
          getRowId={this.getRowId}
        />
        {this.renderButtons()}
      </>
    );
  }

  renderPanelTitle() {
    const { node } = this.props;
    if (node.enabled) {
      return `${node.multiQueueTreeNodeName}`;
    }
    return `${node.multiQueueTreeNodeName} - Multiqueue Disabled`;
  }

  render() {
    const { classes } = this.props;

    return (
      <Panel title={this.renderPanelTitle()}>
        <div className={classes.container}>
          {this.renderLocationNodeInformation()}
        </div>
      </Panel>
    );
  }
}

export default withStyles(styles)(MultiQueueLocationPanel);
