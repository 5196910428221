import { useState } from 'react';
import * as React from 'react';
import { Column } from '@devexpress/dx-react-grid';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { Clear, Add } from '@onc/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LabelledCheckbox,
} from 'base-components';
import {
  ContainedButton,
  OutlinedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import { getEmptyFilter, TableFilter, TableFilterGroup } from './ToolbarFilter';
import ToolbarFilterGroup from './ToolbarFilterGroup';

const useStyles = makeStyles((theme: Theme) => ({
  menuContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
  },
  addFilterButton: {
    marginLeft: theme.spacing(1),
  },
  floatRightButton: {
    marginLeft: 'auto',
  },
}));

const emptyFilterGroup: TableFilterGroup = {
  logic: 'And',
  filterLines: [
    {
      column: '',
      operator: '',
      dataType: 'String',
      value: '',
    },
  ],
};

type Props = {
  open: boolean;
  onClose: () => void;
  filterState: TableFilter;
  columns: Column[];
  rows: any;
  onStateChange: (filter: TableFilter) => void;
  onChange: (filter: TableFilter) => void;
  filterButtons?: React.ReactNode[];
};

const ToolbarFilterMenu: React.VFC<Props> = ({
  open,
  onClose,
  filterState,
  columns,
  rows,
  onChange,
  onStateChange,
  filterButtons = [],
}: Props) => {
  const [expandedGroup, setExpandedGroup] = useState<number | undefined>(0);

  const classes = useStyles();

  const handleGroupChange = (filterGroup, index) => {
    const updatedFilter = { ...filterState };
    updatedFilter.filterGroups[index] = filterGroup;
    onStateChange(updatedFilter);
  };

  const handleToggleEnable = () => {
    const updatedFilter = { ...filterState };
    updatedFilter.enabled = !updatedFilter.enabled;
    onStateChange(updatedFilter);
  };

  const handleLogicChange = (e) => {
    e.stopPropagation();
    const updatedFilter = { ...filterState };
    updatedFilter.logic = e.target.value;
    onStateChange(updatedFilter);
  };

  const handleRemoveFilterGroup = (index: number) => {
    const updatedFilter = { ...filterState };
    updatedFilter.filterGroups.splice(index, 1);
    if (updatedFilter.filterGroups.length === 0) {
      updatedFilter.filterGroups.push(_.cloneDeep(emptyFilterGroup));
    }
    if (!updatedFilter.filterGroups[index]) {
      if (updatedFilter.filterGroups[index - 1]) {
        setExpandedGroup(index - 1);
      } else {
        setExpandedGroup(0);
      }
    }
    onStateChange(updatedFilter);
  };

  const handleAddFilterGroup = () => {
    const updatedFilter = { ...filterState };
    updatedFilter.filterGroups.push(_.cloneDeep(emptyFilterGroup));
    setExpandedGroup(updatedFilter.filterGroups.length - 1);
    onStateChange(updatedFilter);
  };

  const handleClose = () => {
    onClose();
  };

  const handleClearFilters = () => {
    onStateChange(getEmptyFilter());
  };

  const handleApplyFilter = () => {
    const updatedFilter = _.cloneDeep(filterState);
    updatedFilter.filterGroups.map((group) => {
      const updatedGroup = { ...group };
      updatedGroup.filterLines = group.filterLines.filter(
        (line) => !!line.column
      );
      return updatedGroup;
    });
    onStateChange(_.cloneDeep(updatedFilter));
    onChange(_.cloneDeep(updatedFilter));
    onClose();
  };

  return (
    <Dialog
      open={open}
      id="table-filter"
      fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
      <DialogTitle>Table Filters</DialogTitle>
      <DialogContent>
        <div className={classes.menuContainer}>
          {filterState && filterState.filterGroups
            ? filterState.filterGroups.map(
                (filterGroup: TableFilterGroup, index: number) => (
                  <ToolbarFilterGroup
                    index={index}
                    expanded={expandedGroup === index}
                    setExpanded={setExpandedGroup}
                    value={filterGroup}
                    columns={columns}
                    onChange={handleGroupChange}
                    onLogicChange={handleLogicChange}
                    handleRemoveFilterGroup={handleRemoveFilterGroup}
                    filter={filterState}
                    rows={rows}
                  />
                )
              )
            : undefined}
        </div>
        <TextButton
          translationKey="common.buttons.addFilterGroup"
          startIcon={<Add />}
          onClick={handleAddFilterGroup}
          className={classes.buttonContainer}
        />
      </DialogContent>
      <DialogActions>
        {filterButtons.map((button) => button)}
        <LabelledCheckbox
          label="Enable Filter"
          value={filterState.enabled}
          onClick={handleToggleEnable}
        />
        <OutlinedButton
          translationKey="seatube.clearFilters"
          onClick={handleClearFilters}
          className={classes.floatRightButton}
          startIcon={<Clear />}
        />
        <ContainedButton
          translationKey="common.buttons.apply"
          onClick={handleApplyFilter}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ToolbarFilterMenu;
