import useGet from 'util/hooks/useDmasAPI/useGet';
import { post, get, deleteImpl, patch } from 'util/WebRequest';

export type TaxonomyAttributeJson = {
  attributeId: number;
  groupName: string;
  selectable: boolean;
  dataType: 'String' | 'Select' | 'Boolean' | 'Integer' | 'Number';
  groupId: number;
  name: string;
  attributeValues: {
    label: string;
    value: number;
  }[];
  canBeUpdated: boolean;
};

export type TaxonomyAttributeParameters = {
  filterSelectable: boolean;
};

export const useAttributes = () =>
  useGet<TaxonomyAttributeJson[], TaxonomyAttributeParameters>(
    'internal/taxonomies/attributes',
    {
      operation: undefined,
      transform: (response) => response.data,
    },
    { filterSelectable: true }
  );

class TaxonomyAttributeService {
  static async getAll(): Promise<TaxonomyAttributeJson[]> {
    const response = await get('internal/taxonomies/attributes', {
      filterSelectable: false,
    });
    return response.data;
  }

  static async getAllSelectable(): Promise<TaxonomyAttributeJson[]> {
    const response = await get('internal/taxonomies/attributes', {
      filterSelectable: true,
    });
    return response.data;
  }

  static async create(params) {
    const response = await post('internal/taxonomies/attributes', params, {
      headers: { 'content-type': 'application/json' },
    });
    return response.data;
  }

  static async update(attributeId, params) {
    const response = await patch(
      `internal/taxonomies/attributes/${attributeId}`,
      params,
      {
        headers: { 'content-type': 'application/json' },
      }
    );
    return response.data;
  }

  static async delete(attributeId) {
    const response = await deleteImpl(
      `internal/taxonomies/attributes/${attributeId}`
    );
    return response.data;
  }
}

export default TaxonomyAttributeService;
