import { useContext } from 'react';
import moment from 'moment';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../../library/CompositeComponents/dashboard/DashboardTypes';
import EndeavourContext from '../context/EndeavourContext';
import ActivityHistogram from '../plots/ActivityHistogram';

const EndeavourActivityPlotWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;
  const { earthquakes } = useContext(EndeavourContext);

  const getTimeSpan = () => {
    if (earthquakes.length === 0) return 'Day';

    const times: number[] = earthquakes.map((eq) =>
      new Date(eq.originTime).getTime()
    );

    // Find the min and max timestamps
    const minTime = Math.min(...times);
    const maxTime = Math.max(...times);

    // Calculate time span in milliseconds
    const timeSpan = maxTime - minTime;
    if (timeSpan <= moment.duration(6, 'hours').asMilliseconds()) {
      return 'Minute';
    }
    if (timeSpan <= moment.duration(7, 'days').asMilliseconds()) {
      return 'Hour';
    }
    return 'Day';
  };

  return (
    <Widget key={id} title="Earthquake Activity Plot" {...props}>
      <ActivityHistogram
        earthquakes={earthquakes}
        initialGroupBy={getTimeSpan()}
      />
    </Widget>
  );
};

EndeavourActivityPlotWidget.widgetKey = 'endeavour-earthquake-activity-plot';
EndeavourActivityPlotWidget.widgetTitle = 'Earthquake Activity Plot';
EndeavourActivityPlotWidget.defaultDataGrid = {
  i: 'endeavour-earthquake-activity-plot',
  x: 0,
  y: 0,
  w: 6,
  h: 5,
};

export default EndeavourActivityPlotWidget;
