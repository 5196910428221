import React from 'react';
import LatestReadingsWidgetConfig from 'domain/AppComponents/Dashboard/latest-reading-widget/LatestReadingsWidgetConfig';
import LatestReadingsWidgetDisplay from 'domain/AppComponents/Dashboard/latest-reading-widget/LatestReadingsWidgetDisplay';

export type Location = {
  archived: 'Y' | 'N';
  nodeId: number;
  id: number;
  pathName: string[];
  datasetRef?: string;
  datefrom?: string;
  deviceCategoryCode?: string;
  deviceCategoryName?: string;
  deviceId?: number;
  els?: any;
  name?: string;
  ns?: string;
  sensorCodeName?: string;
  sensorTypeCode?: string;
  sensortypeid?: number;
  sequence?: number;
  siteDeviceList?: Array<{
    deviceId?: number;
    siteDeviceId?: number;
  }>;
  sitedeviceid?: number;
  stationCode?: string;
  type?: number;
};

type LatestReadingsWidgetProps = {
  onSave: (values: any) => Promise<void>;
  onConfigFinish: () => void;
  showDisplay: boolean;
  sensorIds?: string;
  locations?: Location[];
  dataSourceSelection?: 'sensorId' | 'location';
  showTitle?: boolean;
  autoRefreshEnabled?: boolean;
  title?: string;
  simpleDateEnabled?: boolean;
  dataWarningEnabled?: boolean;
  dataAgeLimit?: number;
};

const LatestReadingsWidget: React.FC<LatestReadingsWidgetProps> = ({
  onSave,
  onConfigFinish,
  showDisplay,
  sensorIds = undefined,
  locations = [],
  dataSourceSelection = 'sensorId',
  showTitle = false,
  autoRefreshEnabled = true,
  title = '',
  simpleDateEnabled = false,
  dataWarningEnabled = false,
  dataAgeLimit = 7,
}) => {
  const handleSave = async (values: any) => {
    await onSave(values);
    onConfigFinish();
  };

  const getSensors = () => {
    if (dataSourceSelection === 'location' && locations.length > 0) {
      return locations
        .filter((sensor) => sensor !== undefined)
        .map((sensor) => (sensor.archived === 'Y' ? sensor.id : undefined))
        .join();
    }
    return sensorIds ?? '';
  };

  return showDisplay ? (
    <LatestReadingsWidgetDisplay
      title={title}
      sensorIds={getSensors()}
      autoRefresh={autoRefreshEnabled}
      showTitle={showTitle}
      dataWarningEnabled={dataWarningEnabled}
      dataAgeLimit={dataAgeLimit}
      simpleDateEnabled={simpleDateEnabled}
    />
  ) : (
    <LatestReadingsWidgetConfig
      sensorIds={sensorIds}
      dataAgeLimit={dataAgeLimit}
      locations={locations}
      autoRefreshEnabled={autoRefreshEnabled}
      showTitle={showTitle}
      title={title}
      onSave={handleSave}
      onCancel={onConfigFinish}
      simpleDateEnabled={simpleDateEnabled}
      dataWarningEnabled={dataWarningEnabled}
      dataSourceSelection={dataSourceSelection}
    />
  );
};

export default LatestReadingsWidget;
