import { useCallback, useEffect, useState } from 'react';
import { TOptions, TranslationType } from '@onc/i18n';
import { Autocomplete, LinearProgress, Paper } from 'base-components';
import { useSnackbars } from './snackbars/useSnackbars';

export interface AsyncAutocompleteProps<T> {
  /**
   * Text field label from the json file
   * util/i18n/src/translation/en/translation.json
   */
  translationKey: TranslationType;

  /** Options to pass in with the translation. */
  translationOptions?: TOptions;

  /** The value of the input. This should be a number or string */
  value: T;

  /** The minimum number of letters required to start a search */
  minLetters: number;

  /** The name of the resource to be used in the error message */
  resourceName: string;

  /** The name of the text field */
  name: string;

  /** Whether or not the text field is disabled */
  disabled?: boolean;

  /**
   * If true, the input will display an error state. This is typically used to
   * indicate validation errors or other issues to the user.
   */
  error?: boolean;

  /**
   * If error true, text provided will display under the text field This is
   * typically used to indicate to the user how to remove an error
   */
  helperText?: string;

  /** Function to call when the value changes */
  onChange: (event: any, value: T) => void;

  /** Function to load the options from the backend */
  loadOptions: (input: string) => Promise<T[]>;

  /** Function to get the label from the object */
  getOptionLabel: (obj: T) => string;

  /** Function to get the value from the object */
  isOptionEqualToValue?: (obj: T) => string | number;

  /** A ref to the cancel function for the request */
  cancelRef?: React.MutableRefObject<() => void>;

  /** Loading text, will cycle through an array of strings */
  loadingText?: string[];

  /** Time in ms before changing the loading text */
  loadingTextInterval?: number;
}

// eslint-disable-next-line react/prop-types
const CustomPaperComponent = ({ children, loading, ...other }) => (
  <Paper {...other}>
    {loading && (
      <LinearProgress style={{ position: 'absolute', width: '100%' }} />
    )}
    {children}
  </Paper>
);

const AsyncAutocomplete = <T,>({
  translationKey,
  translationOptions = undefined,
  minLetters,
  name,
  resourceName,
  value,
  getOptionLabel,
  loadOptions,
  onChange,
  cancelRef = undefined,
  disabled = false,
  error = undefined,
  helperText = undefined,
  loadingText = ['Loading...'],
  loadingTextInterval = 1000,
}: AsyncAutocompleteProps<T>) => {
  const [input, setInput] = useState('');
  const [optionList, setOptionList] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);
  const { onError } = useSnackbars();

  const memoizedPaperComponent = useCallback(
    (props) => <CustomPaperComponent {...props} loading={loading} />,
    [loading]
  );

  useEffect(() => {
    // If not loading, immediately reset the loading text index to 0
    // and bail out so we don't create the interval at all.
    if (!loading) {
      setLoadingTextIndex(0);
      return undefined;
    }

    // Otherwise (loading === true), set up an interval
    const id = setInterval(() => {
      setLoadingTextIndex((prevIndex) =>
        prevIndex >= loadingText.length - 1 ? 0 : prevIndex + 1
      );
    }, loadingTextInterval);

    // Cleanup the interval when `loading` changes to false or the component unmounts
    return () => {
      clearInterval(id);
    };
  }, [loading, loadingText, loadingTextInterval]);

  const handleInputChange = (event, val) => {
    setInput(val);
    if (!disabled && val && val.length >= minLetters) {
      setLoading(true);
      cancelRef?.current?.();

      loadOptions(val)
        .then((result) => {
          setLoading(false);
          setOptionList(result);
        })
        // catch error and continue if it's an cancelled request
        .catch((e) => {
          if (e.name !== 'CanceledError') {
            setLoading(false);
            onError(e.message || 'An error occurred while loading the options');
          }
        });
    } else {
      setOptionList([]);
      setLoading(false);
    }
  };

  const handleChange = (e, val) => {
    if (!e || !e.target.value) {
      setInput('');
    }
    onChange(e, val);
  };

  return (
    <Autocomplete
      translationKey={translationKey}
      translationOptions={translationOptions}
      name={name}
      value={value}
      inputValue={input}
      disabled={disabled}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      options={optionList}
      getOptionLabel={(option) => getOptionLabel(option)}
      onChange={handleChange}
      loading={loading}
      PaperComponent={memoizedPaperComponent}
      loadingText={loadingText[loadingTextIndex]}
      noOptionsText={
        (input && input.length) >= minLetters
          ? `No ${resourceName} Found`
          : `Type ${minLetters} Letter${
              minLetters > 1 ? 's' : ''
            } to Start a Search`
      }
      error={error}
      helperText={helperText}
    />
  );
};

export default AsyncAutocomplete;
