/* eslint-disable no-cond-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Error } from '@onc/icons';
import {
  Skeleton,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  VirtualList,
} from 'base-components';
import ChatLogListItem from 'domain/AppComponents/seatube/chat-log/ChatLogListItem';
import { ChatLogMessage } from 'domain/services/SeaTubeChatLogService';

const EmptyContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

const EmptyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

export type MessageMatch = {
  messageIndex: number;
  matchStart: number;
  matchEnd: number;
  isCurrentMatch: boolean;
  isUserName: boolean;
};

type VirtualChatLogListProps = {
  config: ChatLogConfig;
  deletePermissions: boolean;
  loading: boolean;
  messages: ChatLogMessage[];
  selectedChatMessageId?: number;
  searchQuery: string;
  currentMatchIndex: number;
  onMatchIndicesUpdate: (indices: number[]) => void;
  onChatDelete: (messages: ChatLogMessage[]) => void;
  onMessageClick: (message: ChatLogMessage) => void;
};

type ChatLogConfig = {
  displaySystemMessages: boolean;
  displayNavMessages: boolean;
  displayDeleteIcon: boolean;
  coloredUsernames: boolean;
  fullTimestamp: boolean;
};

const NAV_USERNAME = 'okexnav';

const VirtualChatLogList: React.FC<VirtualChatLogListProps> = ({
  config,
  deletePermissions,
  loading,
  messages,
  selectedChatMessageId = undefined,
  searchQuery,
  currentMatchIndex,
  onMatchIndicesUpdate,
  onChatDelete,
  onMessageClick,
}: VirtualChatLogListProps) => {
  const [scrollToIndex, setScrollToIndex] = useState<number>(null);
  const [matches, setMatches] = useState<MessageMatch[]>([]);

  const [chatUserList, setChatUserList] = useState<
    { username: string; color: string }[]
  >([]);
  const [filteredMessages, setFilteredMessages] = useState<ChatLogMessage[]>(
    []
  );

  const escapeRegExp = (string) =>
    string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const filterChatLog = () => {
    if (!messages) return;

    const { displaySystemMessages, displayNavMessages } = config;
    let filteredListItems = [...messages];

    filteredListItems = filteredListItems.filter((item) => {
      if (!item) return false;
      if (!displaySystemMessages && !item.username) return false;
      if (!displayNavMessages && item.username === NAV_USERNAME) return false;
      return true;
    });

    // Sort by date
    filteredListItems.sort(
      (a, b) => new Date(a.msgDate).getTime() - new Date(b.msgDate).getTime()
    );

    const newMatches = [];
    filteredListItems.forEach((item, messageIndex) => {
      if (searchQuery) {
        const regex = new RegExp(escapeRegExp(searchQuery), 'gi');
        let match;

        while ((match = regex.exec(item.username)) !== null) {
          newMatches.push({
            messageIndex,
            matchStart: match.index,
            matchEnd: match.index + match[0].length,
            isCurrentMatch: newMatches.length === currentMatchIndex,
            isUserName: true,
          });
        }

        while ((match = regex.exec(item.msg)) !== null) {
          newMatches.push({
            messageIndex,
            matchStart: match.index,
            matchEnd: match.index + match[0].length,
            isCurrentMatch: newMatches.length === currentMatchIndex,
            isUserName: false,
          });
        }
      }
    });

    setMatches(newMatches);
    onMatchIndicesUpdate(newMatches);
    setFilteredMessages(filteredListItems);
  };

  const updateChatUserMap = () => {
    if (!messages) return;
    const colorList = [
      '#a31a1a',
      '#b05f16',
      '#3c7a1a',
      '#149fa8',
      '#0d3795',
      '#a20a92',
    ];
    // Collect unique usernames
    const uniqueUsernames = Array.from(
      new Set(messages.filter((msg) => msg.username).map((msg) => msg.username))
    );

    // Map usernames to colors
    const updatedUserList = uniqueUsernames.map((username, index) => ({
      username,
      color: colorList[index % colorList.length],
    }));

    setChatUserList(updatedUserList);
  };

  const updateScrollToIndex = () => {
    if (!filteredMessages) return;

    if (searchQuery && matches.length > 0) {
      const index = matches[currentMatchIndex].messageIndex;
      setScrollToIndex(index);
    } else if (selectedChatMessageId) {
      const index = filteredMessages.findIndex(
        (message) => message.chatLogMsgId === selectedChatMessageId
      );
      setScrollToIndex(index);
    } else {
      setScrollToIndex(null);
    }
  };

  useEffect(filterChatLog, [config, messages, searchQuery, currentMatchIndex]);
  useEffect(updateChatUserMap, [messages]);
  useEffect(updateScrollToIndex, [
    currentMatchIndex,
    matches,
    selectedChatMessageId,
    filteredMessages,
  ]);

  if (loading) {
    return (
      <List>
        {Array.from({ length: 20 }).map(() => (
          <>
            <Divider />
            <ListItem>
              <ListItemAvatar sx={{ pr: '8px' }}>
                <Skeleton width="100%" height="20px" />
              </ListItemAvatar>
              <ListItemText>
                <Skeleton width="100%" height="20px" />
              </ListItemText>
            </ListItem>
          </>
        ))}
      </List>
    );
  }

  if (filteredMessages.length === 0) {
    return (
      <EmptyContainer>
        <Error color="disabled" fontSize="large" />
        <EmptyText variant="h6">No Chat Messages</EmptyText>
      </EmptyContainer>
    );
  }

  return (
    <VirtualList scrollToIndex={scrollToIndex} scrollToAlignment="center">
      {filteredMessages.map((message, index) => {
        const messageMatches = matches
          .filter((m) => m.messageIndex === index)
          .map((m) => ({
            ...m,
            isCurrent:
              matches[currentMatchIndex] &&
              m.messageIndex === matches[currentMatchIndex].messageIndex &&
              m.matchStart === matches[currentMatchIndex].matchStart,
          }));

        return (
          <ChatLogListItem
            key={message.chatLogMsgId}
            coloredUsernames={config.coloredUsernames}
            deletePermissions={deletePermissions}
            message={message}
            chatUserMap={chatUserList}
            onChatDelete={onChatDelete}
            onMessageClick={onMessageClick}
            displayDeleteIcon={config.displayDeleteIcon}
            displayFullTimestamp={config.fullTimestamp}
            listIndex={index}
            selected={selectedChatMessageId === message.chatLogMsgId}
            matches={messageMatches}
          />
        );
      })}
      <Divider />
    </VirtualList>
  );
};

export default VirtualChatLogList;
