import { Loading } from '@onc/composite-components';
import { ErrorPage } from 'base-components';

import useGet from 'util/hooks/useDmasAPI/useGet';
import ADCPToolboxForm, { ADCPToolboxFormType } from './ADCPToolboxForm';

export type ADCPNortekPresets = {
  velocityBinMapping: string;
  horizontalCurrentPlotLimits: string;
  verticalCurrentPlotLimits: string;
  backscatterColourMap: string;
  backscatterLowerPlotLimits: string;
  backscatterUpperPlotLimits: string;
};

export type ADCPRDIPresets = ADCPNortekPresets & {
  threeBeamSolution: string;
  lowCorrelationScreenThreshold: string;
  errCorrelationScreenThreshold: string;
  falseTargetMaxThreshold: string;
};

type ADCPToolboxProps = {
  onADCPFilterSubmit: (values: ADCPToolboxFormType) => void;
  adcpNortekPresetOptions?: ADCPNortekPresets;
  adcpRdiPresetOptions?: ADCPRDIPresets;
  deviceId: number;
  resourceId?: number;
};

const ADCPToolbox = ({
  onADCPFilterSubmit,
  adcpNortekPresetOptions = undefined,
  adcpRdiPresetOptions = undefined,
  deviceId,
  resourceId = undefined,
}: ADCPToolboxProps) => {
  const isRdiDevice = useGet<boolean, { deviceId: number }>(
    'api/devices',
    {
      operation: undefined,
      transform: (response) =>
        response.data[0].deviceName.includes('RDI') || false,
    },
    { deviceId }
  );

  if (isRdiDevice.isLoading) {
    return <Loading />;
  }

  if (isRdiDevice.error) {
    return <ErrorPage />;
  }

  return (
    <ADCPToolboxForm
      onADCPFilterSubmit={onADCPFilterSubmit}
      adcpNortekPresetOptions={adcpNortekPresetOptions}
      adcpRdiPresetOptions={adcpRdiPresetOptions}
      resourceId={resourceId}
      isRdi={isRdiDevice.data}
    />
  );
};

export default ADCPToolbox;
