/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import qs from 'qs';
import { ErrorAlert } from '@onc/composite-components';
import { DenseThemeProvider } from '@onc/theme';
import { CircularProgress } from 'base-components';
import AnnotationTableLogic from 'domain/AppComponents/sea-tube/annotation-table/AnnotationTableLogic';
import DiveLogDashboard from 'domain/AppComponents/sea-tube/dive-log/DiveLogDashboard';
import SeaTubeConfigContext from 'domain/AppComponents/sea-tube/SeaTubeConfigContext';
import SeaTubeLogContext, {
  SeaTubeLogContextProps,
  SeaTubeLogType,
} from 'domain/AppComponents/sea-tube/SeaTubeLogContext';
import SeaTubePermissionContext from 'domain/AppComponents/sea-tube/SeaTubePermissionContext';
import QueryParameterContext from 'domain/Apps/menu/QueryParameterContext';
import DiveService from 'domain/services/DiveService';
import SeaTubeConfigurationService from 'domain/services/SeaTubeConfigurationService';
import SeaTubePermissionsService from 'domain/services/SeaTubePermissionsService';
import Environment from 'util/Environment';
import { PipProvider } from 'util/hooks/usePipWindow';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#EEE',
    height: '100%',
  },
  loading: {
    position: 'fixed',
    top: '40%',
    left: '50%',
  },
}));

interface DiveLogParams {
  diveId: string;
  isLiveMode: boolean;
}

type Props = {
  location: {
    search: string;
  };
  match: {
    isExact: boolean;
    params: DiveLogParams;
    path: string;
    url: string;
  };
};

const DiveLogApp: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { location, match } = props;

  const { diveId } = match.params;

  // Handle Query parameters
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const params = { ...match.params, ...queryParams };

  const [primaryWindowId, setPrimaryWindowId] = useState<string>(
    queryParams.primaryWindowId as string
  );

  const [logContext, setLogContext] =
    useState<SeaTubeLogContextProps>(undefined);

  const { onError } = useSnackbars();

  const [dive, loadingDive, fetchDive] = useWebService({
    method: DiveService.getDive,
    onError,
  });

  const [seaTubeConfig, loadingConfig, fetchSeaTubeConfig] = useWebService({
    method: SeaTubeConfigurationService.getOrganizationConfig,
    onError,
  });

  const [annotationPermissions, loadingPermissions, fetchPermissions] =
    useWebService({
      method: SeaTubePermissionsService.getUserPermissions,
      onError,
      parser: AnnotationTableLogic.buildPermissionObj,
    });

  useEffect(() => {
    if (!dive) {
      return;
    }
    if (Environment.isUserLoggedIn()) {
      fetchSeaTubeConfig(dive.organizationId);
    }
  }, [dive]);

  useEffect(() => {
    fetchDive(Number(diveId)).then((result) => {
      setLogContext({
        startDate: result?.dateFrom,
        endDate: result?.dateTo,
        cruiseId: result?.cruiseId,
        logType: SeaTubeLogType.DIVE,
        dive: result,
      });
    });

    fetchPermissions({ diveId: Number(diveId) });
    if (!primaryWindowId) {
      setPrimaryWindowId(crypto.getRandomValues(new Uint32Array(1)).toString());
    }
  }, []);

  const renderContent = () => {
    if (dive === null) {
      return (
        <ErrorAlert title="Error">
          There was a problem getting dive id: {diveId}
        </ErrorAlert>
      );
    }

    if (
      dive === undefined ||
      loadingDive ||
      loadingPermissions ||
      loadingConfig
    ) {
      return <CircularProgress className={classes.loading} size={120} />;
    }

    return (
      <DenseThemeProvider>
        <PipProvider>
          <QueryParameterContext.Provider value={params}>
            <SeaTubeLogContext.Provider value={logContext}>
              <SeaTubePermissionContext.Provider value={annotationPermissions}>
                <SeaTubeConfigContext.Provider value={seaTubeConfig}>
                  <DiveLogDashboard primaryWindowId={primaryWindowId} />
                </SeaTubeConfigContext.Provider>
              </SeaTubePermissionContext.Provider>
            </SeaTubeLogContext.Provider>
          </QueryParameterContext.Provider>
        </PipProvider>
      </DenseThemeProvider>
    );
  };

  return <div className={classes.root}>{renderContent()}</div>;
};

export default DiveLogApp;
