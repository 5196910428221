import React, { useContext, useState } from 'react';
import { Lock, LockOpen } from '@onc/icons';
import { IconButton } from 'base-components';
import PlaylistContext from './PlaylistContext';
import PlaylistLockingDialog from '../dialogs/PlaylistLockingDialog';
import UserDetailsContext from '../UserDetailsContext';

const PlaylistLockingControl: React.FC = () => {
  const userContext = useContext(UserDetailsContext);
  const { userId } = userContext;
  const playlistContext = useContext(PlaylistContext);
  const { playlist } = playlistContext;
  const { locked, createdBy } = playlist;
  const [lockDialogOpen, setLockDialogOpen] = useState<boolean>(false);

  const toggleLockDialog = () => {
    setLockDialogOpen(!lockDialogOpen);
  };

  return userId === createdBy ? (
    <>
      <PlaylistLockingDialog open={lockDialogOpen} onClose={toggleLockDialog} />
      <IconButton
        aria-label={locked ? 'Unlock Playlist' : 'Lock Playlist'}
        id="lock-control-button"
        onClick={toggleLockDialog}
      >
        {locked ? <Lock /> : <LockOpen />}
      </IconButton>
    </>
  ) : null;
};

export default PlaylistLockingControl;
