import { memo } from 'react';
import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
} from 'base-components';

const styles = () =>
  createStyles({
    placeholder: {
      cursor: 'not-allowed',
    },
  });

interface IngestionCardProps extends WithStyles<typeof styles> {
  url?: string | undefined;
  title?: string;
  description?: string;
}

const IngestionCard: React.VFC<IngestionCardProps> = ({
  url = undefined,
  title = 'Future Ingestion Option',
  description = 'This is a placeholder for a future ingestion option.',
  classes,
}) => {
  const handleCardClick = () => {
    window.open(url);
  };

  return (
    <Card>
      <CardActionArea
        id={`card-${title.toLowerCase().replace(/\s/g, '-')}`}
        className={url ? '' : classes.placeholder}
        onClick={url ? handleCardClick : undefined}
      >
        <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} />
        <CardContent>
          <Typography variant="body2">{description}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default withStyles(styles)(memo(IngestionCard));
