/**
 * This can be used with the Slider's ValueLabelComponent prop to display a
 * tooltip with the slider's value instead of the default bubble This can be
 * useful for longer values such as strings and dates that won't display nicely
 * in the default Slider value label
 */

import { ReactElement } from 'react';
import Tooltip from '../Tooltip';

type SliderTooltipLabelProps = {
  children?: ReactElement;
  open: boolean;
  value: number;
};
function SliderTooltipLabel({
  children = undefined,
  open,
  value,
}: SliderTooltipLabelProps) {
  return (
    <Tooltip open={open} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

export default SliderTooltipLabel;
