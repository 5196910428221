class CookieUtils {
  static saveCookie = (name, value) => {
    const cookie = `${name} = ${JSON.stringify(value)}`;
    document.cookie = cookie;
  };

  static getCookie = (name) => {
    let result = document.cookie.match(new RegExp(`${name}=([^;]+)`));
    result && (result = JSON.parse(result[1]));
    return result;
  };
}

export default CookieUtils;
