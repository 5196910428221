export type WidgetConfigMapAction =
  | { type: 'DELETE_WIDGET_CONFIG'; widgetId: string }
  | { type: 'SET_WIDGET_CONFIG'; widgetId: string; config: any }
  | {
      type: 'SET_WIDGET_CONFIG_PROPERTY';
      widgetId: string;
      key: PropertyKey;
      value: any;
    };

export type WidgetConfigMap = Map<string, any>;

/**
 * Manages the widget config, handling widget registration and configuration
 * updates.
 *
 * @param state - Current widget config map
 * @param action - Action to perform on the registry
 * @returns Updated widget config map
 */
const widgetConfigMapReducer = (
  state: WidgetConfigMap,
  action: WidgetConfigMapAction
): WidgetConfigMap => {
  const nextState = new Map(state);

  switch (action.type) {
    case 'DELETE_WIDGET_CONFIG':
      nextState.delete(action.widgetId);
      return nextState;

    case 'SET_WIDGET_CONFIG': {
      const current = state.get(action.widgetId) || {};
      const updatedConfig = { ...current, ...action.config };
      nextState.set(action.widgetId, updatedConfig);
      return nextState;
    }

    case 'SET_WIDGET_CONFIG_PROPERTY': {
      const current = state.get(action.widgetId) || {};
      const updatedConfig = { ...current, [action.key]: action.value };
      nextState.set(action.widgetId, updatedConfig);
      return nextState;
    }

    default:
      return state;
  }
};

export default widgetConfigMapReducer;
