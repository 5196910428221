import { Controller, useFormContext } from 'react-hook-form';
import {
  FormHelperText,
  LabelledCheckbox,
  type LabelledCheckboxProps,
} from 'base-components';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';

type FormCheckboxProps = FormFieldType<LabelledCheckboxProps>;

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  rules,
  defaultValue,
  disabled,
  shouldUnregister,
  ...rest
}: FormCheckboxProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      disabled={disabled}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <>
          <LabelledCheckbox
            {...rest}
            {...field}
            required={rest?.required || Boolean(rules?.required)}
            // NOTE: Errors and helper text are not handled by LabelledCheckbox
          />
          {fieldState.error && (
            <FormHelperText error>{fieldState.error.message}</FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default FormCheckbox;
