import { get } from 'util/WebRequest';

export type Parameters = {
  locationCode: string;
  deviceCategoryCode?: string;
  fields: object;
};

class SearchTreeAnnotationService {
  static getAnnotations = async (
    locationCode,
    deviceCategoryCode,
    dateFrom,
    dateTo,
    fields
  ) =>
    get(`/internal/searchtrees/${locationCode}/annotations`, {
      deviceCategoryCode,
      dateFrom,
      dateTo,
      fields,
    }).then((response) => response.data);
}

export default SearchTreeAnnotationService;
