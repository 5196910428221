import moment from 'moment';
import DeviceWebServiceWithToken from 'domain/services/DeviceWebServiceWithToken';

class DeviceUtils {
  static async getDevicesOneDC(
    deviceCategoryCode,
    locationCode,
    deviceCode,
    dateFrom,
    dateTo
  ) {
    let deviceList = [];
    let errorMessage;
    try {
      const response = await DeviceWebServiceWithToken.get({
        method: 'get',
        deviceCategoryCode,
        locationCode,
        deviceCode,
        dateFrom: dateFrom ? moment.utc(dateFrom).toDate() : undefined,
        dateTo: dateTo ? moment.utc(dateTo).toDate() : undefined,
      });

      deviceList = response.data.map((record) => ({
        deviceId: record.deviceId,
        name: record.deviceName,
        deviceCode: record.deviceCode,
        deviceCategoryCode: record.deviceCategoryCode,
        dataRating: record.dataRating,
      }));
    } catch (e) {
      errorMessage = e.message;
    }
    return [errorMessage, deviceList];
  }

  static async getDevicesMultiDC(
    deviceCategoryCodes,
    locationCode,
    deviceCode,
    dateFrom,
    dateTo
  ) {
    const results = [];
    let deviceList = [];
    let errorMessage;
    try {
      // Make multiple service calls for each Device Category Call
      for (const deviceCategoryCode of deviceCategoryCodes) {
        results.push(
          DeviceUtils.getDevicesOneDC(
            deviceCategoryCode,
            locationCode,
            deviceCode,
            dateFrom,
            dateTo
          )
        );
      }
      const responses = await Promise.allSettled(results);

      responses.forEach((response) => {
        deviceList = deviceList.concat(response.value[1]);
      });
      deviceList.sort((a, b) => (a.name > b.name ? 1 : -1));
    } catch (e) {
      errorMessage = e.message;
    }
    return [errorMessage, deviceList];
  }
}

export default DeviceUtils;
