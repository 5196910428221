import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import oceans2Theme from './oceans2Theme';

const oncDefaultTheme = createTheme({
  typography: {
    fontSize: 14,
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
          '&:hover': {
            backgroundColor: grey[300],
          },

          '&:active': {
            backgroundColor: grey[300],
          },
        },
      },
    },
  },
  ...oceans2Theme,
});

export default oncDefaultTheme;
