import { TableColumnDataTypes } from 'library/BaseComponents/table/StatelessTable';

export type Attribute = {
  taxonomyAttributeLineId?: number;
  attributeId: number;
  dataType: TableColumnDataTypes;
  name: string;
  selectable?: boolean;
  value?: any;
  groupId: number;
  attributeValues?: {
    label: string;
    value: number | string;
  }[];
  canBeUpdated?: boolean;
  groupName: string;
};

export type TableAnnotationProps = {
  id: number;
  startDate: string;
  createdBy: string;
  modifiedBy: string;
  createdDate: string;
  modifiedDate: string;
  taxon: string;
  taxonomy: string;
  attributes: Attribute[];
  comment: string;
  toBeReviewed: boolean;
  numPositiveReviews: number;
  numTotalReviews: number;
  lat: number;
  lon: number;
  depth: number;
  heading: number;
  cruiseName: string;
  logName: string;
};

export default class TableAnnotation {
  id: number;

  startDate: string;

  createdBy: string;

  createdUserId: number;

  modifiedBy: string;

  createdDate: string;

  modifiedDate: string;

  taxon: string;

  taxonomy: string;

  attributes: Attribute[];

  comment: string;

  toBeReviewed: boolean;

  numPositiveReviews: number;

  numTotalReviews: number;

  lat: number;

  lon: number;

  depth: number;

  heading: number;

  resourceId: number;

  resourceTypeId: number;

  taxonomyId: number;

  taxonId: number;

  videoResourceId: number;

  videoResourceTypeId: number;

  cruiseName: string;

  logName: string;

  constructor(annotation: any) {
    const {
      annotationId,
      startDate,
      createdBy,
      modifiedBy,
      createdDate,
      modifiedDate,
      comment,
      toBeReviewed,
      numPositiveReviews,
      numTotalReviews,
      lat,
      lon,
      depth,
      heading,
      resourceId,
      resourceTypeId,
      videoResourceId,
      videoResourceTypeId,
      cruiseName,
      logName,
    } = annotation;
    const taxon = annotation.taxons ? annotation.taxons[0] : undefined;
    const attributes = taxon ? taxon.attributes : [];
    this.id = annotationId;
    this.startDate = startDate;
    this.createdBy = `${createdBy.firstName} ${createdBy.lastName}`;
    this.createdUserId = createdBy.userId;
    this.modifiedBy = `${modifiedBy.firstName} ${modifiedBy.lastName}`;
    this.createdDate = createdDate;
    this.modifiedDate = modifiedDate;
    this.taxon = taxon ? taxon.displayText : '';
    this.taxonomy = taxon ? taxon.taxonomyCode : '';
    this.attributes = attributes.sort((a: Attribute, b: Attribute) =>
      a.name > b.name ? 1 : -1
    );
    this.comment = comment;
    this.toBeReviewed = toBeReviewed;
    this.numPositiveReviews = numPositiveReviews;
    this.numTotalReviews = numTotalReviews;
    this.lat = lat;
    this.lon = lon;
    this.depth = depth;
    this.heading = heading;
    this.resourceId = resourceId;
    this.resourceTypeId = resourceTypeId;
    this.taxonomyId = taxon ? taxon.taxonomyId : '';
    this.taxonId = taxon ? taxon.taxonId : '';
    this.videoResourceId = videoResourceId || 0;
    this.videoResourceTypeId = videoResourceTypeId || 0;
    this.cruiseName = cruiseName || '';
    this.logName = logName || '';
  }
}
