import { ErrorSnackbar, Loading } from '@onc/composite-components';
import { Divider, Typography } from 'base-components';

import Panel from 'library/CompositeComponents/panel/Panel';

import useGet from 'util/hooks/useDmasAPI/useGet';

import NamePlateForm from './NamePlateForm';

type NamePlateMaintenanceRWProps = {
  namePlateId?: number;
  deviceId?: number;
};

type DeviceNamePlatePayload = {
  namePlate: {
    manufactureDate?: string;
    device: {
      deviceName: string;
    };
  };
  producer: {
    organizationName: string;
    organizationId: number;
  };
  supplier?: {
    organizationName: string;
    organizationId: number;
  };
  dmasUser: {
    firstname: string;
    lastname: string;
  };
};

type DevicePayload = {
  devices: {
    device: {
      name: string;
    };
  };
};

const NamePlateMaintenanceRW = ({
  namePlateId = undefined,
  deviceId = undefined,
}: NamePlateMaintenanceRWProps) => {
  const deviceData = useGet<
    DevicePayload,
    { deviceid: number; operationtype: number; network: number }
  >(
    'DeviceService',
    {
      operation: 2,
      transform: (response) => {
        const { data } = response;
        if (!data.payload) {
          throw new Error(data);
        }
        return data.payload;
      },
      options: { enabled: !namePlateId },
    },
    {
      deviceid: deviceId,
      operationtype: 2,
      network: -1,
    }
  );

  const namePlateData = useGet<
    DeviceNamePlatePayload,
    { deviceId: number; namePlateId: number }
  >(
    'DeviceNamePlateService',
    { operation: 4, options: { enabled: namePlateId !== undefined } },
    {
      deviceId,
      namePlateId,
    }
  );

  if (
    (namePlateId && namePlateData.isLoading) ||
    (!namePlateId && deviceData.isLoading)
  ) {
    return <Loading />;
  }

  if (namePlateId && namePlateData.error) {
    return <ErrorSnackbar message={namePlateData.error} />;
  }

  if (!namePlateId && deviceData.error) {
    return <ErrorSnackbar message={deviceData.error} />;
  }

  /*
   * DeviceService doesn't return a statusCode in its response, so we
   * need to manually check if the request succeeded by comparing against
   * devices.device
   */
  if (!namePlateId && deviceData.data?.devices?.device === null) {
    return <ErrorSnackbar message="Error fetching device" />;
  }

  return (
    /*
     * This div is included so that the panel inherits the size of 'div'
     * element and the scrollbar which appears in a panel can be hidden
     */

    <div>
      <Panel
        title={
          <>
            <Typography variant="body1">Device Nameplate ID:</Typography>
            <Typography variant="body1">
              {!namePlateId ? 'Undefined' : namePlateId}
            </Typography>
            {}
          </>
        }
      >
        <NamePlateForm
          deviceId={deviceId}
          namePlateData={namePlateId ? namePlateData.data : undefined}
          deviceData={namePlateId ? undefined : deviceData.data}
          namePlateId={namePlateId}
        />
        <Divider variant="middle" />
      </Panel>
    </div>
  );
};

export default NamePlateMaintenanceRW;
