import { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { Grid, Paper, TextField, Typography } from 'base-components';
import BatchTable from 'domain/AppComponents/batch/batch-table/BatchTable';
import BatchTaskContainer from 'domain/AppComponents/batch/batch-task-table/BatchTaskContainer';
import BatchAddContainer from 'domain/AppComponents/batch/dialog/BatchAddContainer';
import {
  BATCH_TASK_PLACEHOLDER,
  BATCH_TASK_QUERY_NAME,
  useBatchTaskQuery,
} from 'domain/AppComponents/batch/hooks/BatchTaskQueryHooks';
import {
  useBatchFiltering,
  useBatchSorting,
  useBatchTaskType,
  useBatchPaging,
  useBatchSearching,
} from 'domain/AppComponents/batch/hooks/BatchTaskStateHooks';
import BatchFilters from 'domain/AppComponents/batch/util/BatchFilters';
import {
  DEFAULT_SELECTED_FILTERS_BATCHES,
  FILTERS_FOR_BATCHES,
  PAGE_SIZE,
} from 'domain/AppComponents/batch/util/BatchUtils';
import useDeepCompareEffect from 'domain/hooks/useDeepCompareEffect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addBatchButton: {
      marginLeft: 'auto',
    },
    searchBar: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
    },
  })
);

const BatchContainer = () => {
  const classes = useStyles();
  const [selectedBatchId, setSelectedBatchId] = useState<number>(0);

  const filtering = useBatchFiltering(
    DEFAULT_SELECTED_FILTERS_BATCHES,
    FILTERS_FOR_BATCHES
  );

  const sorting = useBatchSorting(BATCH_TASK_QUERY_NAME, [], {
    columnName: 'taskId',
    direction: 'desc',
  });
  const [searchValue, setSearchValue] = useBatchSearching(
    BATCH_TASK_QUERY_NAME
  );
  const { taskType } = useBatchTaskType();
  const paging = useBatchPaging(0);
  const { data } = useBatchTaskQuery(
    paging?.currentPage ?? 0,
    filtering.selectedFilters.toString(),
    taskType,
    sorting,
    searchValue
  );

  // if the page is not full, weve reached the last page
  if ((data?.records?.length ?? PAGE_SIZE) < PAGE_SIZE) {
    paging.totalCount =
      PAGE_SIZE * (paging?.currentPage ?? 1) + (data?.records.length ?? 1);
  }

  useDeepCompareEffect(() => {
    paging?.onCurrentPageChange?.(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering, searchValue]);

  useEffect(() => {
    if (
      // if the selected batch is not still in the data, we don't want it to remain the focus
      !data?.records.some((row) => row.task.taskId === selectedBatchId)
    ) {
      setSelectedBatchId(0);
    }
  }, [data?.records, selectedBatchId]);

  const selectedBatch = data?.records.find(
    (record) => record.task.taskId === selectedBatchId
  );
  return (
    <>
      <Paper role="region" aria-label="batches">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h6">Batches</Typography>
              </Grid>
              <Grid item xsOffset="auto" className={classes.addBatchButton}>
                <BatchAddContainer />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} md={7}>
            <BatchFilters filterOptions={filtering} />
          </Grid>
          <Grid item xsOffset="auto" className={classes.searchBar}>
            <TextField
              translationKey="common.search"
              value={searchValue}
              onChange={(event) => setSearchValue(event.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <BatchTable
              data={data ?? BATCH_TASK_PLACEHOLDER}
              paging={paging}
              sorting={sorting.current}
              selectedTaskId={selectedBatchId}
              setSelectedBatchId={setSelectedBatchId}
            />
          </Grid>
        </Grid>
      </Paper>
      <BatchTaskContainer selectedBatch={selectedBatch?.task} />
    </>
  );
};

export default BatchContainer;
