import { Component } from 'react';
import PropTypes from 'prop-types';
import { ErrorSnackbar } from '@onc/composite-components';
import {
  DFEditIconButton,
  DFShowStatisticsIconButton,
  DFOpenInBrowserIconButton,
} from 'domain/AppComponents/digital-fishers/DFIconButtons';
import PageWithPadding from 'library/CompositeComponents/page/PageWithPadding';

import { parseDmasAPIResponse, get } from 'util/WebRequest';
import DFManagementLandingPage from './DFManagementLandingPage';

const GET_ALL_CAMPAIGNS = 8;

class DFManagementLandingPageContainer extends Component {
  static propTypes = {
    editPath: PropTypes.string,
    statisticsPath: PropTypes.string,
  };

  static defaultProps = {
    editPath: undefined,
    statisticsPath: undefined,
  };

  state = {
    campaignList: [],
    error: null,
  };

  componentDidMount() {
    this.getAllCampaigns();
  }

  getAllCampaigns = async () =>
    get('DigitalFishersService', {
      operation: GET_ALL_CAMPAIGNS,
    })
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => {
        this.setState({
          campaignList: payload.campaigns.map((campaign) => {
            const transfromedCampaign = { ...campaign };
            transfromedCampaign.active = campaign.active.toString();
            transfromedCampaign.icons = this.renderIcons(campaign);
            return transfromedCampaign;
          }),
        });
      })
      .catch((error) => {
        this.setState({ error });
      });

  onErrorClose = () => {
    this.setState({ error: undefined });
  };

  renderIcons = (campaign) => {
    const { editPath, statisticsPath } = this.props;
    return (
      <>
        <DFEditIconButton editPath={editPath} campaign={campaign} />
        <DFOpenInBrowserIconButton campaign={campaign} />
        <DFShowStatisticsIconButton
          statisticsPath={statisticsPath}
          campaign={campaign}
        />
      </>
    );
  };

  render() {
    const { error, campaignList } = this.state;
    const { editPath, statisticsPath } = this.props;

    if (error) {
      return (
        <ErrorSnackbar message={error.message} onClose={this.onErrorClose} />
      );
    }

    return (
      <PageWithPadding>
        <DFManagementLandingPage
          campaignList={campaignList}
          editPath={editPath}
          statisticsPath={statisticsPath}
        />
      </PageWithPadding>
    );
  }
}

export default DFManagementLandingPageContainer;
