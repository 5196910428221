import { EditIconButton } from 'domain/AppComponents/IconButtons';
import { DeploymentTableRow } from '../DeploymentsTableLogic';

type DeploymentActionProps = {
  row: DeploymentTableRow;
  onEdit?: (...args: any[]) => any;
};

const DeploymentsActionFormatter: React.FC<DeploymentActionProps> = ({
  row,
  onEdit = undefined,
}: DeploymentActionProps) =>
  onEdit ? <EditIconButton onClick={() => onEdit(row)} /> : null;

export default DeploymentsActionFormatter;
