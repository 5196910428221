import { useEffect, useState } from 'react';
import * as React from 'react';
import moment from 'moment';
import { ErrorSnackbar } from '@onc/composite-components';
import { WidgetTitle } from 'domain/AppComponents/Dashboard/Titles';
import DataPlayerContainer from 'domain/Apps/data-player/DataPlayerContainer';
import DeploymentsWebServiceAPI from 'domain/services/DeploymentsWebServiceAPI';
import DeviceWebServiceWithToken from 'domain/services/DeviceWebServiceWithToken';
import { WidgetConfigError } from './widget-error/WidgetConfigError';

type DataPlayerWidgetDisplayProps = {
  dataSourceType: 'deviceCategory' | 'location';
  dateSelectorValue: 'latest' | 'dateRange' | string;
  deviceCategoryCode: string;
  deviceId: number;
  isCommunicating: boolean;
  showTitle: boolean;
  showToolbox: boolean;
  startDate: Date;
  timeRange: string;
  title: string;
  handleToolboxClose: () => void;
  locations?: any[];
};

const DataPlayerWidgetDisplay: React.FC<DataPlayerWidgetDisplayProps> = ({
  dataSourceType,
  dateSelectorValue,
  deviceId,
  deviceCategoryCode,
  handleToolboxClose,
  isCommunicating,
  showTitle,
  showToolbox,
  startDate,
  timeRange,
  title,
  locations = undefined,
}) => {
  const [latestDevice, setLatestDevice] = useState<any>(null);

  // Fetches the latest hydrophone at the requested location
  useEffect(() => {
    const getLatestHydrophoneDevice = async () => {
      if (dateSelectorValue === 'latest' && locations.length > 0) {
        // TODO: Service calls can likely be replaced with one call to archivefiles to retrieve the latest
        // hydrophone spectrogram and passed down to the DataPlayerImage component to be displayed
        const deploymentsResponse = await DeploymentsWebServiceAPI.get({
          method: 'get',
          deviceCategoryCode,
          locationCode: locations[0].stationCode,
        });
        const latestDeployment = deploymentsResponse.data.reduce(
          (latest, deployment) => {
            const deploymentDate = new Date(deployment.begin);
            if (!latest || deploymentDate > latest.date) {
              return {
                date: deploymentDate,
                deviceCode: deployment.deviceCode,
              };
            }
            return latest;
          },
          null
        );

        const deviceResponse = await DeviceWebServiceWithToken.get({
          method: 'get',
          deviceCategoryCode,
          deviceCode: latestDeployment.deviceCode,
          locationCode: locations[0].stationCode,
        });
        const latestDeviceObj = {
          deviceCategory: deviceResponse.data[0].deviceCategoryCode,
          deviceId: deviceResponse.data[0].deviceId,
          name: deviceResponse.data[0].deviceName,
        };
        setLatestDevice(latestDeviceObj);
      }
    };

    getLatestHydrophoneDevice();
  }, [dateSelectorValue, locations, deviceCategoryCode]);

  const renderTitle = () => {
    if (showTitle) {
      return <WidgetTitle titleText={title} />;
    }
    return null;
  };

  const renderDisplayContent = () => {
    if (
      (dateSelectorValue === 'dateRange' && (!deviceId || !startDate)) ||
      (dateSelectorValue === 'latest' &&
        dataSourceType === 'location' &&
        locations.length === 0)
    ) {
      return (
        <>
          <ErrorSnackbar message="Incomplete Configuration" />
          <WidgetConfigError message="Incomplete Configuration" />
        </>
      );
    }
    return (
      <DataPlayerContainer
        isForWidget
        startDate={moment(startDate)}
        dateSelectorValue={dateSelectorValue}
        deviceId={
          dateSelectorValue === 'latest' && latestDevice
            ? latestDevice.deviceId
            : deviceId
        }
        timeRange={timeRange}
        deviceCategoryCode={deviceCategoryCode}
        showWidgetToolbox={showToolbox}
        handleWidgetToolboxClose={handleToolboxClose}
        isCommunicating={isCommunicating}
      />
    );
  };

  return (
    <>
      {renderTitle()}
      {renderDisplayContent()}
    </>
  );
};

export default DataPlayerWidgetDisplay;
