import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import ExpeditionDetailsTemplate from './ExpeditionDetailsTemplate';
import ExpeditionService from './ExpeditionService';

const EXPEDITION_MANAGEMENT_LINK = `${Environment.getDmasUrl()}/Cruises`;

class ExpeditionDetails extends PureComponent {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    expeditionId: PropTypes.number.isRequired,
    onError: PropTypes.func.isRequired,
  };

  state = {
    expeditionDetails: undefined,
  };

  componentDidMount() {
    const { expeditionId } = this.props;
    this.getExpeditionDetails(expeditionId);
  }

  componentDidUpdate(prevProps) {
    const { expeditionId } = this.props;
    const { expeditionId: prevExpeditionId } = prevProps;
    if (expeditionId !== prevExpeditionId && expeditionId) {
      this.getExpeditionDetails(expeditionId);
    }
  }

  getExpeditionDetails = async (expeditionId) => {
    const { onError } = this.props;
    const payload = await ExpeditionService.getExpeditionDetails(
      expeditionId,
      onError
    );
    if (payload) {
      this.setState({ expeditionDetails: payload });
    }
  };

  render() {
    const { organizationId, expeditionId } = this.props;
    const { expeditionDetails } = this.state;
    return (
      <ExpeditionDetailsTemplate
        organizationId={organizationId}
        expeditionDetails={expeditionDetails}
        stillLoading={!expeditionDetails}
        expeditionManagementLink={`${EXPEDITION_MANAGEMENT_LINK}?cruiseId=${expeditionId}`}
      />
    );
  }
}

export default withSnackbars(ExpeditionDetails);
