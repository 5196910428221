import { Tune } from '@onc/icons';
import { NestedMenuItem } from 'base-components';
import { SeaTubeResolution } from 'domain/services/SeaTubeVideoService';

type QualityMenuItemProps = {
  options: SeaTubeResolution[];
  currentQuality: string;
  onClick: (val: string) => void;
  containerRef?: React.RefObject<HTMLDivElement>;
};

const QualityMenuItem: React.FC<QualityMenuItemProps> = ({
  options,
  currentQuality,
  containerRef = undefined,
  onClick,
}: QualityMenuItemProps) => (
  <NestedMenuItem
    value="quality"
    label="Quality"
    name="quality"
    container={containerRef}
    IconComponent={<Tune />}
    onClick={(e) => {
      onClick(e.target.value);
    }}
    childrenItems={options.map((quality) => ({
      value: quality.code,
      label: quality.description,
      key: quality.code,
      selected: quality.code === currentQuality,
    }))}
  />
);

// Used by AnchoredHiddenMenu to override the onClick
QualityMenuItem.displayName = 'MenuItem';

export default QualityMenuItem;
