import useGet from 'util/hooks/useDmasAPI/useGet';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

const SERVICE_NAME = 'SeaTubeChatLogService';

export type ChatLogMessage = {
  msgDate: string;
  username?: string;
  msg: string;
  chatLogMsgId: number;
};

export const useChatLog = (params: { diveId: number }) =>
  useGet<ChatLogMessage[], { diveId: number }>(SERVICE_NAME, 1, params);

class SeaTubeChatLogService {
  /**
   * Gets chat log for a dive
   *
   * @param {int} diveId - The dive id
   */
  static getChatLogForDive = (diveId, onError) =>
    get(SERVICE_NAME, { diveId })
      .then((response) => {
        if (response.data.statusCode === 403) {
          return [];
        }
        return parseDmasAPIResponse(response);
      })
      .catch(() => onError(`Error getting chat log for dive ${diveId}`));

  /**
   * Ingests the given chat log messages to a dive
   *
   * @param {int} diveId - The dive id
   * @param {object} messages - The messages to ingest
   */
  static ingestChatLogMessages = (diveId, messages) =>
    post(SERVICE_NAME, {
      operation: 1,
      diveId,
      messages: JSON.stringify(messages),
    })
      .then((response) => {
        const { statusCode } = response.data;
        if (statusCode !== 0) {
          if (statusCode === 403) {
            return new Error(
              'You do not have the proper permissions to ingest to this dive.'
            );
          }
        }
        return parseDmasAPIResponse(response);
      })
      .catch((error) => new Error(error));

  /**
   * Deletes the chat log message with the given ID.
   *
   * @param {int} msgId - The chat log message's ID
   */
  static deleteChatLogMessage = (msgId) =>
    post(SERVICE_NAME, {
      operation: 3,
      msgId,
    })
      .then((response) => {
        const { statusCode } = response.data;
        if (statusCode !== 0) {
          if (statusCode === 403) {
            return new Error(
              'You do not have the proper permissions to ingest to this dive.'
            );
          }
        }
        return parseDmasAPIResponse(response);
      })
      .catch((error) => new Error(error));

  /**
   * Deletes chat log messages of a certain dive.
   *
   * @param {int} diveId - The dive's ID
   */
  static deleteAllChatLogMessagesOfDive = (diveId) =>
    post(SERVICE_NAME, {
      operation: 3,
      diveId,
    })
      .then((response) => {
        const { statusCode } = response.data;
        if (statusCode !== 0) {
          if (statusCode === 403) {
            return new Error(
              'You do not have the proper permissions to ingest to this dive.'
            );
          }
        }
        return parseDmasAPIResponse(response);
      })
      .catch((error) => new Error(error));
}

export default SeaTubeChatLogService;
