import * as React from 'react';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
  TemplateConnector,
} from '@devexpress/dx-react-core';
import { Refresh } from '@onc/icons';
import { IconButton } from 'base-components';

interface Props {
  onRefresh: () => void;
}

const pluginDependencies = [{ name: 'Toolbar' }];

const ToolbarFilter: React.VFC<Props> = ({ onRefresh }: Props) => (
  <Plugin dependencies={pluginDependencies}>
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <TemplateConnector>
        {() => (
          <IconButton
            aria-controls="table-refresh-content"
            data-test="table-refresh-content"
            aria-label="Refresh Table Contents"
            onClick={onRefresh}
            style={{ width: '48px' }}
          >
            <Refresh />
          </IconButton>
        )}
      </TemplateConnector>
    </Template>
  </Plugin>
);

export default ToolbarFilter;
