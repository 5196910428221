import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'base-components';
import DeviceDetailsLink from 'domain/AppComponents/link/DeviceDetailsLink';

import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';

const headers = [
  { title: 'Device ID', name: 'deviceId' },
  { title: 'Device Name', name: 'deviceName' },
  { title: 'Category', name: 'deviceCategory' },
  { title: 'Owner', name: 'ownerName' },
  { title: 'Region', name: 'regionName' },
  { title: 'Location', name: 'locationName' },
  { title: 'IP', name: 'ipAddress' },
  { title: 'DNS', name: 'dns' },
  { title: 'Serial #', name: 'serialNumber' },
  { title: 'PI', name: 'pi' },
  { title: 'Security Tier', name: 'tier' },
  { title: 'DeviceIdForSearch', name: 'deviceIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'deviceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'deviceCategory',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'ownerName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'regionName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'locationName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'ipAddress',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'dns',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'serialNumber',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'pi',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'tier',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceIdForSearch',
    width: ColumnInfo.mini,
  },
];

class DeviceSearchResultsTab extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    regionId: PropTypes.number,
    locationId: PropTypes.number,
    deviceCategoryId: PropTypes.number,
    deviceId: PropTypes.number,
    deviceName: PropTypes.string,
    ip: PropTypes.string,
    dns: PropTypes.string,
    serialnr: PropTypes.string,
    tier: PropTypes.number,
    pi: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
    permission: PropTypes.string.isRequired,
    devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  static defaultProps = {
    regionId: -1,
    locationId: -1,
    deviceCategoryId: -1,
    deviceId: undefined,
    deviceName: undefined,
    ip: undefined,
    dns: undefined,
    serialnr: undefined,
    tier: -1,
    pi: -1,
  };

  compareDeviceId = (a, b) => {
    const idA = a.props.deviceId;
    const idB = b.props.deviceId;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      filteredDevices: [],
    };
  }

  componentDidMount() {
    this.filterDevices();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.filterDevices();
    }
  }

  verifyDevice = (device) => {
    const {
      regionId,
      locationId,
      deviceCategoryId,
      deviceId,
      deviceName,
      ip,
      dns,
      serialnr,
      tier,
      pi,
    } = this.props;
    if (regionId !== -1 && device.currentRegionId !== regionId) {
      return null;
    }
    if (locationId !== -1 && device.currentLocationId !== locationId) {
      return null;
    }
    if (
      deviceCategoryId !== -1 &&
      device.deviceCategoryId !== deviceCategoryId
    ) {
      return null;
    }
    if (deviceId && device.deviceId !== deviceId) {
      return null;
    }
    if (ip) {
      if (device.ipAddress) {
        const displayIp =
          device.ipPort !== 0
            ? `${device.ipAddress}:${device.ipPort}`
            : device.ipAddress;
        if (!displayIp.toLowerCase().includes(ip.toLowerCase())) {
          return null;
        }
      } else {
        return null;
      }
    }
    if (deviceName) {
      if (device.deviceName) {
        if (
          !device.deviceName.toLowerCase().includes(deviceName.toLowerCase())
        ) {
          return null;
        }
      } else {
        return null;
      }
    }
    if (dns) {
      if (device.dns) {
        if (!device.dns.toLowerCase().includes(dns.toLowerCase())) {
          return null;
        }
      } else {
        return null;
      }
    }
    if (serialnr) {
      if (device.serialNumber) {
        if (
          !device.serialNumber.toLowerCase().includes(serialnr.toLowerCase())
        ) {
          return null;
        }
      } else {
        return null;
      }
    }
    if (tier !== -1 && device.tier !== tier) {
      return null;
    }
    if (pi !== -1 && device.principalInvestigatorId !== pi.userId) {
      return null;
    }
    return device;
  };

  buildTableRows() {
    const { filteredDevices } = this.state;
    const { permission, pi } = this.props;
    const tableRows = [];

    filteredDevices.forEach((device) => {
      const tableData = {
        deviceId: <DeviceDetailsLink deviceId={device.deviceId} />,
        deviceName: device.deviceName,
        deviceCategory: device.deviceCategory,
        ownerName: device.ownerName,
        regionName: device.currentRegionName,
        locationName: device.currentLocationName,
        dns: device.dns,
        serialNumber: device.serialNumber,
        deviceIdForSearch: device.deviceId,
      };
      if (device.tier === 1) {
        tableData.tier = 'High';
      } else if (device.tier === 2) {
        tableData.tier = 'Medium';
      } else {
        tableData.tier = 'Low';
      }
      if (permission === 'RW') {
        device.ipPort !== 0
          ? (tableData.ipAddress = `${device.ipAddress}:${device.ipPort}`)
          : (tableData.ipAddress = device.ipAddress);
      }
      tableData.pi = pi.userName;
      tableRows.push(tableData);
    });
    this.setState({ tableRows });
  }

  filterDevices() {
    const { devices } = this.props;
    const filteredDevices = [];

    for (let x = 0; x < devices.length; x += 1) {
      const verificationResult = this.verifyDevice(devices[x]);
      if (verificationResult) {
        filteredDevices.push(verificationResult);
      }
    }
    this.setState({ filteredDevices }, this.buildTableRows);
  }

  render() {
    const { permission, classes } = this.props;
    const { tableRows } = this.state;

    if (!tableRows) return undefined;

    return (
      <div className={classes.tableDiv}>
        <Typography variant="h6">Results</Typography>
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={15}
          pageSizes={[15, 30, 60, 0]}
          rows={tableRows}
          searchable
          stripedRows
          hiddenColumns={
            permission !== 'RW'
              ? ['ipAddress', 'deviceIdForSearch', 'tier']
              : ['deviceIdForSearch']
          }
          sortExtensions={[
            {
              columnName: 'deviceId',
              compare: this.compareDeviceId,
            },
          ]}
        />
      </div>
    );
  }
}

export default DeviceSearchResultsTab;
