import SensorInfo from 'domain/Apps/sensor-listing/SensorInfo';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

const SENSOR_TYPE_SERVICE = 'SensorTypeService';

class SensorTypeService {
  static #get(params) {
    return get('SensorTypeService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getAllSensorTypes(): Promise<SensorInfo[]> {
    return SensorTypeService.#get({ operation: 5 });
  }

  static getSensorTypeById(sensorTypeId: number): Promise<SensorInfo[]> {
    return SensorTypeService.#get({ operation: 4, sensorTypeId });
  }

  static createSensorType(params) {
    const parameters = {
      operation: 1,
      ...params,
    };
    return post(SENSOR_TYPE_SERVICE, parameters);
  }

  static updateSensorType(params) {
    const parameters = {
      operation: 2,
      ...params,
    };
    return post(SENSOR_TYPE_SERVICE, parameters);
  }
}
export default SensorTypeService;
