import { get } from 'util/WebRequest';

const SERVICE = 'apiproxy/devices';

class DeviceWebService {
  static get(params) {
    return get(SERVICE, params);
  }
}
export default DeviceWebService;
