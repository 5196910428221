import * as React from 'react';
import { AnchoredHiddenMenu, MenuItem } from 'base-components';
import { AddIconButton } from 'domain/AppComponents/IconButtons';
import { WidgetOption } from './DashboardTypes';

type Props = {
  widgetOptions?: WidgetOption[];
  onAddWidget: (widgetOption: WidgetOption) => void;
  disabled: boolean;
};

const AddWidgetButton: React.VFC<Props> = ({
  widgetOptions = [],
  onAddWidget,
  disabled,
}: Props) => (
  <AnchoredHiddenMenu
    variant="custom"
    customAnchor={
      <AddIconButton
        disabled={disabled}
        color="inherit"
        id="add-widget-button"
      />
    }
    getContentAnchorEl={undefined}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
    {widgetOptions.map((option) => (
      <MenuItem key={option.label} onClick={() => onAddWidget(option)}>
        {option.label}
      </MenuItem>
    ))}
  </AnchoredHiddenMenu>
);

export default AddWidgetButton;
