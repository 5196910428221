import { Autocomplete, type AutocompleteProps } from 'base-components';
import useFilteredDeviceCategories from 'domain/hooks/useFilteredDeviceCategories';
import type { DeviceCategory } from 'domain/AppComponents/Dashboard/data-source-widget/DataSourceSelectionWidgetFilters';

type DeviceCategoriesAutocompleteProps = {
  value?: DeviceCategory | null;
  onChange?: (newCategory: DeviceCategory) => void;
  multiple?: boolean;
  propertyCode?: string;
  locationCode?: string;
  deviceCategoryCode?: string;
  required?: boolean;
} & Omit<
  AutocompleteProps,
  'options' | 'getOptionLabel' | 'isOptionEqualToValue' | 'value' | 'onChange'
>;

const DeviceCategoriesAutocomplete: React.FC<
  DeviceCategoriesAutocompleteProps
> = ({
  onChange = () => {},
  value = undefined,
  multiple = false,
  propertyCode = undefined,
  locationCode = undefined,
  deviceCategoryCode = undefined,
  required = false,
  ...autocompleteProps
}: DeviceCategoriesAutocompleteProps) => {
  const deviceCategories = useFilteredDeviceCategories(
    propertyCode,
    locationCode,
    deviceCategoryCode
  );

  return (
    <Autocomplete
      {...autocompleteProps}
      multiple={multiple}
      options={deviceCategories}
      onChange={(event, newCategory) => onChange(newCategory)}
      value={value}
      virtualized
      noOptionsText="No Device Categories Available"
      getOptionLabel={(category: DeviceCategory) =>
        `${category.name} (${category.deviceCategoryCode})`
      }
      isOptionEqualToValue={(a: DeviceCategory, b: DeviceCategory) =>
        a.deviceCategoryCode === b.deviceCategoryCode
      }
      name="DeviceCategory"
      translationKey="device.deviceCategory"
      required={required}
    />
  );
};

export default DeviceCategoriesAutocomplete;
