import React, { useEffect, useState } from 'react';
import UserOrganizationTable from 'domain/AppComponents/user-organization-listing/UserOrganizationTable';
import {
  transformUserOrganizationRows,
  UserOrganizationRow,
} from 'domain/AppComponents/user-organization-listing/UserOrganizationTableLogic';
import PageWithPadding from 'library/CompositeComponents/page/PageWithPadding';
import Environment from 'util/Environment';
import mockDataUserOrganization from './mockUserOrganization.json';

const UserOrganizationListingPage: React.FC = () => {
  const [userOrganizations, setUserOrganizations] = useState<
    UserOrganizationRow[]
  >([]);
  const [, setOpen] = useState(false);
  const [, setSelectedUserOrganization] = useState(null);
  const permission = Environment.getDmasUserPrivilege();

  useEffect(() => {
    const fetchUserOrganizations = async () => {
      // TODO: Replace mockDataUserOrganization with actual data from the web service
      setUserOrganizations(
        transformUserOrganizationRows(mockDataUserOrganization)
      );
    };

    fetchUserOrganizations().catch();
  }, []);

  const handleEditClick = (row: UserOrganizationRow) => {
    setSelectedUserOrganization(row);
    setOpen(true);
  };

  const handleDeleteClick = (row: UserOrganizationRow) => {
    setSelectedUserOrganization(row);
  };

  const handleCreateClick = () => {
    setSelectedUserOrganization({
      userId: 0,
      organizationId: 0,
      userInfo: '',
      organizationInfo: '',
      modifyBy: '',
      modifyDate: '',
    });
    setOpen(true);
  };

  return (
    <PageWithPadding>
      <UserOrganizationTable
        rows={userOrganizations || []}
        permission={permission}
        onEdit={handleEditClick}
        onDelete={handleDeleteClick}
        onCreate={handleCreateClick}
      />
    </PageWithPadding>
  );
};

export default UserOrganizationListingPage;
