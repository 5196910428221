import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'base-components';
import PageWithPadding from 'library/CompositeComponents/page/PageWithPadding';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import SystemConsoleCommandPanel from './SystemConsoleCommandPanel';
import SystemConsoleService from './SystemConsoleService';
import SystemConsoleTable from './SystemConsoleTable';

/**
 * This is the stateful component that dislays the System Console page. It keeps
 * track of three main types of state:
 *
 * - System command: the class name of the command you're trying to send (ex.
 *   ReinitializeCache)
 * - Arguments: the parameters of the arguments (if applicable)
 * - Destinations: 0 or more destinations you're sending the System Command
 *   Message to. (ex. PA, Shorestations, pashore1.dc.local)
 *
 * We want to build our state into something like:
 *
 * ```javascript
 * command: "<command>" (ex. ReinitializeCache)
 * arguments: {
 *     "<optional-key>": "<optional-value>",
 *     "cache": "Hibernate Managed Cache"
 * }
 * destinations: [
 *     {
 *         "location":   "<location>",
 *         "host":       "<host>",
 *         "role":       "<role>"
 *     },
 *     {
 *         "location":   "PA",
 *         "host":       "pashore1.dc.local",
 *         "role":       "SHORESTATION"
 *     }
 *  ]
 * ```
 */

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '90vh',
  },
  table: {
    width: '100%',
    marginBottom: 3,
    overflow: 'scroll',
    justifyContent: 'flex-start',
  },
  commandPanel: { paddingBottom: 3, justifyContent: 'flex-end' },
};

class SystemConsolePage extends PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
  };

  getDestinationString = (destinations) => {
    let destinationString = '';
    destinationString = destinations[0].host.toString();
    for (let i = 1; i < destinations.length; i += 1) {
      destinationString += `, ${destinations[i].host.toString()}`;
    }
    return destinationString;
  };

  formatSelections = (dests) => dests.map((dest) => dest.rowId);

  state = {
    systemcommand: '',
    selectedCommand: '',
    arguments: {},
    destinations: [],
    systemCommandList: [],
    defaultArgs: {},
  };

  componentDidMount() {
    SystemConsoleService.get().then((response) => {
      const { data } = response;
      const status = data.statusCode;
      if (status === 0) {
        this.setState({
          systemCommandList: data.payload,
          defaultArgs: SystemConsoleService.generateDefaultArgs(data.payload),
        });
      }
    });
  }

  handleSystemCommandChange = (defaultArgs) => (event) => {
    const value = event.target.value.split(',');
    const selectedCommand = value[1];
    const systemcommand = value[0];
    this.setState({
      systemcommand,
      selectedCommand,
      arguments: defaultArgs[selectedCommand],
    });
  };

  // set state using spread operator and computed properties.
  handleArgumentChange = (paramName, valueExtractor) => (event) => {
    const fn = valueExtractor || ((value) => value);
    this.setState((prevState) => ({
      arguments: {
        ...prevState.arguments,
        [paramName]: fn(event.target.value),
      },
    }));
  };

  handleRefreshMessage = () => {
    const { onInfo } = this.props;
    onInfo('Table Refreshed');
  };

  handleSubmit = () => {
    const { systemcommand, arguments: args, destinations } = this.state;
    const { onInfo, onError } = this.props;
    SystemConsoleService.post({
      payload: JSON.stringify({
        command: systemcommand,
        arguments: args,
        destinations,
      }),
    });
    if (destinations.length >= 1) {
      const destinationString = this.getDestinationString(destinations);
      const message = `${systemcommand.toString()} Sent To ${destinationString}`;
      onInfo(message);
    } else {
      onError('No destinations selected');
    }

    this.setState({
      systemcommand: '',
      selectedCommand: '',
      arguments: {},
    });
  };

  handleTableChange = (selections) => {
    this.setState({
      destinations: selections.map((selection) => {
        // the rowId is of the form <host>:<location>:<role>, which means we just have to split it out to get our information.
        const result = selection.split(':');
        return {
          host: result[0],
          location: result[1],
          role: result[2],
          rowId: selection, // keep the row id for easy rerender
        };
      }),
    });
  };

  render() {
    const {
      selectedCommand,
      systemcommand,
      arguments: args,
      destinations,
      defaultArgs,
      systemCommandList,
    } = this.state;

    const selections = this.formatSelections(destinations);
    return (
      <PageWithPadding>
        <Box sx={classes.container}>
          <Box sx={classes.table}>
            <SystemConsoleTable
              refreshMessage={this.handleRefreshMessage}
              showSelections={systemCommandList.length > 0}
              destinations={selections}
              onSelectionChange={this.handleTableChange}
            />
          </Box>
          {selections?.length > 0 ? (
            <Box sx={classes.commandPanel}>
              <SystemConsoleCommandPanel
                systemcommand={selectedCommand}
                commandClassName={systemcommand}
                args={args}
                defaultArgs={defaultArgs}
                systemCommandList={systemCommandList}
                onParamChange={this.handleArgumentChange}
                onChange={this.handleSystemCommandChange}
                onSubmit={this.handleSubmit}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </PageWithPadding>
    );
  }
}

export default withSnackbars(SystemConsolePage);
