import { cloneElement, Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Info } from '@onc/icons';
import { Chip } from 'base-components';

const styles = () => ({
  chip: {
    margin: '0',
  },
});

class DialogChip extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    theme: PropTypes.shape({
      palette: PropTypes.shape({
        error: PropTypes.shape({ main: PropTypes.string }),
        grey: PropTypes.shape(),
        secondary: PropTypes.shape({ main: PropTypes.string }),
        primary: PropTypes.shape({ main: PropTypes.string }),
      }),
    }).isRequired,
    classes: PropTypes.shape({ chip: PropTypes.string }).isRequired,
    dialog: PropTypes.node,
  };

  static defaultProps = {
    type: 'primary',
    dialog: undefined,
  };

  state = {
    showDialog: false,
  };

  closeDialog = () => {
    this.setState({ showDialog: false });
  };

  renderDialog = () => {
    const { dialog } = this.props;
    const { showDialog } = this.state;
    if (dialog) {
      return cloneElement(dialog, {
        open: showDialog,
        onClose: this.closeDialog,
      });
    }
    return null;
  };

  getColor = () => {
    const { type, theme } = this.props;
    switch (type) {
      case 'error':
        return theme.palette.error.main;
      case 'info':
        return theme.palette.grey[500];
      case 'secondary':
        return theme.palette.secondary.main;
      default:
        return theme.palette.primary.main;
    }
  };

  render() {
    const { label, dialog, classes } = this.props;
    return (
      <>
        <Chip
          className={classes.chip}
          label={label}
          color="primary"
          style={{ backgroundColor: this.getColor() }}
          icon={dialog ? <Info /> : null}
          onClick={
            dialog ? () => this.setState({ showDialog: true }) : undefined
          }
        />
        {this.renderDialog()}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DialogChip);
