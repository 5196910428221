import { useEffect, useRef } from 'react';
import _ from 'lodash';

/**
 * A custom hook that invokes a callback function when any of the deeply
 * compared values change.
 *
 * This hook is useful when you need to perform side effects based on changes to
 * complex objects or arrays, where shallow comparison would not suffice.
 *
 * @example
 *   useDeepCompareEffect(() => {
 *     console.log('Values changed!');
 *   }, [complexObject, nestedArray]);
 *
 * @param callback - The function to call when any of the deeply compared values
 *   change.
 * @param values - An array of values to be deeply compared.
 * @returns {void}
 */
const useDeepCompareEffect = (callback: () => void, values: any[]) => {
  const ref = useRef<any[]>([]);

  useEffect(() => {
    if (!_.isEqual(values, ref.current)) {
      callback();
    }

    ref.current = values;
  }, [callback, values]);

  return ref.current;
};

export default useDeepCompareEffect;
