import { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { Divider, Grid, Typography } from 'base-components';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { handleOpenDeviceDetails } from 'domain/AppComponents/link/DeviceDetailsLink';
import DevicePortService from 'domain/services/DevicePortService';

import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateUtils from 'util/DateUtils';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(1),
  },
});

const defaultState = {
  portCode: undefined,
  direction: undefined,
  device: undefined,
  protocol: undefined,
  portLabel: undefined,
  power: undefined,
  voltage: undefined,
  breakerNumber: undefined,
  switchPort: undefined,
  modifyBy: undefined,
  deviceName: undefined,
  modifyDate: DateUtils.formatDateAsString(new Date()),
};

class DevicePortRO extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
      roDivider: PropTypes.string,
    }).isRequired,
    portId: PropTypes.number,
    deviceId: PropTypes.number,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    portId: undefined,
    deviceId: undefined,
  };

  constructor(props) {
    super(props);
    const { portId, deviceId } = this.props;
    this.state = {
      portId,
      deviceId,
      ...defaultState,
    };
  }

  componentDidMount() {
    const { portId } = this.state;

    if (portId) {
      this.getDevicePortService();
    }
  }

  getDevicePortService = () => {
    const { onError } = this.props;
    const { portId, deviceId } = this.state;

    if (!portId || isNaN(portId)) return;

    DevicePortService.getOne(portId, deviceId)
      .then((payload) => {
        const { portCode, datePerformed, ...rest } = payload[0];
        this.setState({
          datePerformed: DateUtils.formatDateAsString(
            new Date(payload[0].datePerformed)
          ),
          portCode: payload[0].devicePortCode,
          portLabel: payload[0].devicePortLabel,
          switchPort: payload[0].switchPortNumber,
          ...rest,
        });
      })
      .catch((e) => {
        onError(e.message);
        this.setState(defaultState);
      });
  };

  render() {
    const { classes } = this.props;
    const {
      portId,
      direction,
      protocol,
      portLabel,
      power,
      voltage,
      breakerNumber,
      switchPort,
      modifyBy,
      modifyDate,
      portCode,
      deviceId,
      deviceName,
    } = this.state;

    let color;
    return (
      // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
      <div>
        <Panel
          title={
            <>
              <Typography variant="body1">Device Port ID:</Typography>
              <Typography variant="body1" color={color}>
                {portId}
              </Typography>
            </>
          }
        >
          <Grid
            container
            direction="row"
            className={classes.entryFormContainer}
          >
            <Grid item xs={6}>
              <MarginedReadOnlyField
                labelText="Direction"
                valueText={direction}
                title="directionTextField"
              />
              <MarginedReadOnlyField
                labelText="Device"
                valueText={deviceName}
                title="deviceField"
              />
              <MarginedReadOnlyField
                labelText="Protocol"
                valueText={protocol}
                title="protocolField"
              />
              <MarginedReadOnlyField
                labelText="Port Label"
                valueText={portLabel}
                title="portLabelField"
              />
              <MarginedReadOnlyField
                labelText="Port Code"
                valueText={portCode}
                title="portCodeTextField"
              />
              <MarginedReadOnlyField
                labelText="Power"
                valueText={power}
                title="powerField"
              />
              <MarginedReadOnlyField
                labelText="Voltage"
                valueText={voltage}
                title="voltageField"
              />
              <MarginedReadOnlyField
                labelText="Breaker Number"
                valueText={breakerNumber}
                title="breakerNumberField"
              />
              <MarginedReadOnlyField
                labelText="Switch Port"
                valueText={switchPort}
                title="switchPortField"
              />
              <MarginedReadOnlyField
                labelText="Last Modified Date"
                valueText={DateUtils.formatDateAsString(new Date(modifyDate))}
                title="modifyDateField"
              />
              <MarginedReadOnlyField
                labelText="Last Modified By"
                valueText={modifyBy}
                title="modifyByField"
              />
            </Grid>
          </Grid>
          <div className={classes.formButtons}>
            <ContainedButton
              translationKey="common.buttons.done"
              onClick={handleOpenDeviceDetails(deviceId)}
            />
          </div>
          <Divider variant="middle" className={classes.roDivider} />
        </Panel>
      </div>
    );
  }
}
export default withStyles(STYLES)(withSnackbars(DevicePortRO));
