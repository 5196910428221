const defaultAnonymousLayout = {
  xl: [],
  lg: [
    {
      w: 12,
      h: 24,
      x: 0,
      y: 0,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 10,
      h: 24,
      x: 0,
      y: 0,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 8,
      h: 24,
      x: 0,
      y: 0,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 6,
      h: 24,
      x: 0,
      y: 0,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
  ],
  xxs: [
    {
      w: 4,
      h: 24,
      x: 0,
      y: 0,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
  ],
};

const defaultUserLayout = {
  xl: [],
  lg: [
    { w: 5, h: 8, x: 0, y: 0, i: 'button-set-1', moved: false, static: false },
    {
      w: 7,
      h: 24,
      x: 5,
      y: 0,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
    {
      w: 5,
      h: 16,
      x: 0,
      y: 5,
      i: 'manual-entry-1',
      moved: false,
      static: false,
    },
  ],
  md: [
    { w: 4, h: 12, x: 0, y: 0, i: 'button-set-1', moved: false, static: false },
    {
      w: 4,
      h: 12,
      x: 0,
      y: 5,
      i: 'manual-entry-1',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 24,
      x: 4,
      y: 0,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 4,
      h: 12,
      x: 0,
      y: 0,
      i: 'button-set-1',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 12,
      x: 4,
      y: 0,
      i: 'manual-entry-1',
      moved: false,
      static: false,
    },
    {
      w: 8,
      h: 12,
      x: 0,
      y: 12,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 6,
      h: 8,
      x: 0,
      y: 0,
      i: 'button-set-1',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 8,
      x: 0,
      y: 6,
      i: 'manual-entry-1',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 8,
      x: 0,
      y: 11,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
  ],
  xxs: [
    {
      w: 4,
      h: 5,
      x: 0,
      y: 5,
      i: 'manual-entry-1',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 9,
      x: 0,
      y: 10,
      i: 'annotation-table-1',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 5,
      x: 0,
      y: 0,
      i: 'button-set-1',
      moved: false,
      static: false,
    },
  ],
};

const defaultAnonymousWidgets = [
  {
    i: 'annotation-table-1',
    x: 0,
    y: 99999,
    w: 9,
    h: 9,
    widgetKey: 'annotation-table',
    title: 'Annotation Table',
  },
];

const defaultUserWidgets = [
  {
    i: 'button-set-1',
    x: 0,
    y: 99999,
    w: 5,
    h: 5,
    widgetKey: 'button-set',
    title: 'Quick Button Sets',
    config: { showIndexes: false },
  },
  {
    i: 'annotation-table-1',
    x: 0,
    y: 99999,
    w: 12,
    h: 6,
    widgetKey: 'annotation-table',
    title: 'Annotation Table',
  },
  {
    i: 'manual-entry-1',
    x: 0,
    y: 99999,
    w: 5,
    h: 5,
    widgetKey: 'manual-entry',
    title: 'Annotation Entry',
  },
];

export {
  defaultAnonymousLayout,
  defaultUserLayout,
  defaultAnonymousWidgets,
  defaultUserWidgets,
};
