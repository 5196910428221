import { useEffect, useState } from 'react';

import OrganizationService from 'domain/services/OrganizationService';

import FormAutocomplete, {
  FormAutocompleteProps,
} from 'library/CompositeComponents/form/FormAutocomplete';
import { useSnackbars } from 'util/hooks/useSnackbars';

type option = {
  label: string;
  value?: number;
};

type OrganizationDropdownProps = Omit<FormAutocompleteProps, 'options'> & {
  useNone?: boolean;
};

const OrganizationDropdown = ({
  useNone = true,
  ...props
}: OrganizationDropdownProps) => {
  const [optionsLocal, setOptionsLocal] = useState<option[]>([]);
  const { onError } = useSnackbars();

  const getDeviceOptions = () => {
    OrganizationService.getOrganizations()
      .then((response) => {
        const options = response.map((option) => ({
          label: `${option.organizationName}`,
          value: option.organizationId,
        }));
        const sortedOptions = useNone
          ? [{ label: 'None', value: undefined }].concat(options.sort())
          : options.sort();
        setOptionsLocal(sortedOptions);
      })
      .catch(() => onError('Failed to get Device Types'));
  };

  useEffect(getDeviceOptions, [onError, useNone]);

  return <FormAutocomplete options={optionsLocal} {...props} />;
};

export default OrganizationDropdown;
