import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CsvIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 360 360">
    <g>
      <path d="M345.253 124.865L294.746 124.865L294.746 77.834L211.724 0L14.747 0L14.747 360L294.747 360L294.747 307.592L345.254 307.592L345.254 124.865L345.253 124.865zM315.253 277.592L105.25299 277.592L105.25299 154.865L315.253 154.865L315.253 277.592z" />
      <path
        d="M135.366 234.136C 138.034 237.159 141.218 239.607 144.914 241.48401C 148.60901 243.36201 152.686 244.3 157.145 244.3C 159.668 244.3 162.25 243.977 164.889 243.332C 167.529 242.687 170.007 
241.748 172.326 240.516C 174.642 239.28401 176.725 237.774 178.574 235.98401C 180.42201 234.195 181.84401 232.12701 182.84201 229.78001L182.84201 229.78001L172.89801 224.06001C 172.19402 225.76201 171.255 227.21402 170.08301 228.41602C 168.908 229.61902 167.63301 230.60202 166.255 231.36401C 164.876 232.12701 163.42401 232.68402 161.899 233.03601C 160.373 233.38802 158.907 233.56401 157.49901 233.56401C 154.8 233.56401 152.32 232.97801 150.06201 231.80402C 147.80301 230.63101 145.867 229.07602 144.25401 227.14001C 142.64001 225.20401 141.39401 222.96002 140.514 220.40802C 139.634 217.85602 139.194 215.20203 139.194 212.44402C 139.194 209.98001 139.575 207.50302 140.339 205.00801C 141.101 202.51501 142.231 200.28601 143.727 198.32C 145.223 196.35501 147.1 194.75601 149.35901 193.524C 151.617 192.292 154.272 191.676 157.32301 191.676C 158.73102 191.676 160.169 191.83899 161.63602 192.15999C 163.10202 192.48299 164.51001 192.99599 165.86002 193.69998C 167.21002 194.40398 168.47002 195.35799 169.64401 196.55998C 170.81702 197.76299 171.81401 199.24399 172.63602 201.00398L172.63602 201.00398L181.96402 194.57999C 179.90903 190.53198 176.78502 187.24698 172.59203 184.72398C 168.39702 182.20198 163.42502 180.93999 157.67603 180.93999C 152.80603 180.93999 148.46503 181.84999 144.65202 183.66798C 140.83902 185.48698 137.61203 187.86299 134.97202 190.79599C 132.33202 193.73 130.32202 197.05899 128.94402 200.784C 127.56502 204.51 126.877014 208.27899 126.877014 212.092C 126.877014 216.082 127.625015 219.998 129.12102 223.84C 130.613 227.683 132.695 231.115 135.366 234.136z"
      />
      <path
        d="M212.849 233.564C 210.443 233.564 208.097 233.271 205.809 232.68399C 203.52101 232.09799 201.438 231.379 199.561 230.52798C 197.683 229.67798 196.05501 228.82698 194.677 227.97598C 193.298 227.12598 192.345 226.43599 191.81801 225.90799L191.81801 225.90799L186.45001 236.37999C 190.087 238.96298 194.19402 240.956 198.76901 242.36398C 203.34502 243.77199 207.97902 244.47598 212.67302 244.47598C 
215.72302 244.47598 218.64201 244.13799 221.42902 243.46399C 224.21501 242.79 226.67902 241.719 228.82202 240.25198C 230.96303 238.78598 232.66402 236.86398 233.92603 234.48798C 235.18703 232.11197 235.81903 
229.25198 235.81903 225.90797C 235.81903 223.03398 235.34804 220.61397 234.41103 218.64798C 233.47203 216.68298 232.13702 214.99599 230.40703 213.58798C 228.67503 212.17998 226.56303 211.00699 224.07103 210.06798C 221.57603 209.12997 218.80403 208.24998 215.75504 207.42798C 213.40804 206.84297 211.34004 206.28398 209.55104 205.75598C 207.76105 205.22798 206.28004 204.64198 205.10704 203.99599C 203.93304 203.35098 203.04004 202.60298 202.42303 201.75198C 201.80704 200.90198 201.49904 199.83098 201.49904 198.53998C 201.49904 196.31097 202.31905 194.57997 203.96304 193.34798C 205.60504 192.11598 208.09904 191.49997 211.44304 191.49997C 213.31905 191.49997 215.16805 191.73497 216.98705 192.20396C 218.80405 192.67397 220.49205 193.24597 222.04605 193.91997C 223.60005 194.59497 224.92004 195.28397 226.00606 195.98796C 227.09106 196.69196 227.83905 197.24995 228.25006 197.65996L228.25006 197.65996L233.61806 187.80396C 230.85905 185.92696 227.63406 184.29895 223.93706 182.91995C 220.24205 181.54195 216.19406 180.85196 211.79405 180.85196C 208.62605 180.85196 205.66306 181.27795 202.90605 182.12796C 200.14705 182.97896 197.72705 184.22597 195.64505 185.86797C 193.56206 187.51097 191.93405 189.56396 190.76105 192.02797C 189.58705 194.49197 189.00105 197.30797 189.00105 200.47597C 189.00105 202.88197 189.36606 204.94997 190.10106 206.67996C 190.83406 208.41096 191.93405 209.93596 193.40205 211.25597C 194.86705 212.57597 196.70206 213.71997 198.90205 214.68797C 201.10106 215.65598 203.69806 216.55098 206.68906 217.37198C 209.15405 218.07597 211.38205 218.72198 213.37805 219.30798C 215.37206 219.89499 217.07405 220.53998 218.48206 221.24399C 219.89006 221.94798 220.97505 222.76999 221.73906 223.708C 222.50105 224.64699 222.88306 225.79099 222.88306 227.14C 222.881 231.423 219.537 233.564 212.849 233.564z"
      />
      <path d="M271.985 243.772L296.361 181.292L283.601 181.292L266.881 228.548L249.985 181.292L237.225 181.292L261.777 243.772L271.985 243.772" />
    </g>
  </SvgIcon>
);

export default CsvIcon;
