import { useState } from 'react';
import { Share } from '@onc/icons';
import { IconButton } from 'base-components';
import ShareMenu from 'library/CompositeComponents/share/Share';

type VideoShareControlProps = {
  containerRef?: HTMLElement;
  disabled: boolean;
  shareUrl?: string;
};

const VideoShareControl: React.FC<VideoShareControlProps> = ({
  containerRef = undefined,
  disabled,
  shareUrl = '',
}: VideoShareControlProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleShare = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="Share"
        onClick={handleShare}
        color="inherit"
        tooltipProps={{
          slotProps: {
            popper: { container: containerRef },
          },
        }}
        disabled={disabled}
      >
        <Share />
      </IconButton>
      <ShareMenu
        open={Boolean(anchorEl)}
        title="Check out this video!"
        onClose={handleClose}
        popoverAnchorEl={anchorEl}
        shareUrl={shareUrl}
      />
    </div>
  );
};

export default VideoShareControl;
