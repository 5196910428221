import { createContext } from 'react';

export interface EndeavourContextInterface {
  earthquakes: any;
  mapSites: any;
  dashboardId: string;
  selectedEarthquake: number;
  mapSelectedEarthquakes: number[];
  setMapSelectedEarthquakes: (selectedEarthquake: any) => void;
  setSelectedEarthquake: (selectedEarthquake: any) => void;
  filters: any;
  setFilters: (filters: any) => void;
  filteredEarthquakeList: any[];
}

const EndeavourContext = createContext<EndeavourContextInterface>({
  mapSelectedEarthquakes: [],
  earthquakes: [],
  mapSites: [],
  dashboardId: '1',
  selectedEarthquake: undefined,
  setSelectedEarthquake: () => {},
  setMapSelectedEarthquakes: () => {},
  filters: {},
  setFilters: () => {},
  filteredEarthquakeList: [],
});

export default EndeavourContext;
