import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import {
  DateTimePicker,
  Dropdown,
  type DropdownOption,
  Toggle,
} from 'base-components';

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  datePicker: {
    width: '60%',
    marginRight: theme.spacing(2),
  },
}));

export type SyncForm = {
  deploymentId: number;
  synchronizerId: number;
  organizationId: number;
  mqlocationId: number;
  startNow: boolean;
  dateFrom: Moment;
  dateTo: Moment;
};

type SyncFormValue<T extends keyof SyncForm> = SyncForm[T];

export type SyncFormErrors = Partial<{
  [key in keyof SyncForm]: string;
}>;

type Props = {
  onSubmit: () => void;
  onChange: <T extends keyof SyncForm>(key: T, value: SyncFormValue<T>) => void;
  onError: (key: keyof SyncForm, error: string) => void;
  value: SyncForm;
  errors: SyncFormErrors;
  syncOptions: DropdownOption[];
  organizations: DropdownOption[];
  locOptions: DropdownOption[];
  inProgress?: boolean;
};

const CreateDeploymentForm: React.VFC<Props> = ({
  onChange,
  onSubmit,
  onError,
  syncOptions,
  organizations,
  locOptions,
  errors,
  value,
  inProgress = false,
}: Props) => {
  const classes = useStyles();

  const getMaxDateTime = () => {
    if (value.dateTo) {
      return moment.utc().isAfter(value.dateTo) ? moment.utc() : value.dateTo;
    }
    return undefined;
  };

  return (
    <form onSubmit={() => onSubmit()}>
      <Dropdown
        id="synchronizer"
        label="Synchronizer"
        options={syncOptions}
        value={value.synchronizerId}
        onChange={(e) => onChange('synchronizerId', Number(e.target.value))}
        error={!!errors.synchronizerId}
        helperText={errors.synchronizerId}
        fullWidth
      />
      <Dropdown
        id="organization"
        label="Organization"
        options={organizations}
        value={value.organizationId}
        onChange={(e) => onChange('organizationId', Number(e.target.value))}
        error={!!errors.organizationId}
        helperText={errors.organizationId}
        fullWidth
      />
      <Dropdown
        id="mqlocation"
        label="Location"
        options={locOptions}
        value={value.mqlocationId}
        fullWidth
        onChange={(e) => onChange('mqlocationId', Number(e.target.value))}
        error={!!errors.mqlocationId}
        helperText={errors.mqlocationId}
      />
      <div className={classes.flexContainer}>
        <DateTimePicker
          className={classes.datePicker}
          translationKey="common.datepickers.dateFrom"
          fullWidth
          onChange={(val) => onChange('dateFrom', val)}
          value={value.dateFrom}
          disabled={inProgress || value.startNow}
          onError={(message) => onError('dateFrom', message)}
          helperText={errors.dateFrom}
          disablePast={!inProgress}
          maxDateTime={getMaxDateTime()}
          error={!!errors.dateFrom}
        />

        <Toggle
          label="Start Now"
          value={value.startNow}
          onChange={() => onChange('startNow', !value.startNow)}
          disabled={inProgress}
        />
      </div>

      <DateTimePicker
        translationKey="common.datepickers.dateTo"
        fullWidth
        onChange={(val) => onChange('dateTo', val)}
        value={value.dateTo}
        onError={(message) => onError('dateTo', message)}
        helperText={errors.dateTo}
        minDateTime={
          value.startNow ? moment.utc() : value.dateFrom || undefined
        }
        error={!!errors.dateTo}
      />
    </form>
  );
};

export default CreateDeploymentForm;
