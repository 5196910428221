import { useState } from 'react';
import { DenseThemeProvider } from '@onc/theme';
import { CableTypeRow } from 'domain/AppComponents/cabletype/CableTypeTableLogic';
import { ConnectorTypeRow } from 'domain/Apps/connectortype/ConnectorTypeTableLogic';
import Environment from 'util/Environment';
import CableListingCreateDialog from './CableListingCreateDialog';
import CableListingTable from './CableListingTable';
import { CableListingRow } from './CableListingTableLogic';

type CableListingProps = {
  cableListings: CableListingRow[];
  connectorTypes: ConnectorTypeRow[];
  cableTypes: CableTypeRow[];
  fetchCableListings: () => void;
};

const CableListingTab = ({
  cableListings,
  connectorTypes,
  cableTypes,
  fetchCableListings,
}: CableListingProps) => {
  const permission = Environment.getDmasUserPrivilege();

  const [isCableListingDialogOpen, setCableListingDialog] = useState(false);

  return (
    <DenseThemeProvider>
      <CableListingTable
        rows={cableListings}
        permission={permission}
        onCreate={() => setCableListingDialog(true)}
      />
      <CableListingCreateDialog
        open={isCableListingDialogOpen}
        onClose={() => setCableListingDialog(false)}
        onSuccess={() => fetchCableListings()}
        cableTypeList={cableTypes}
        connectorTypeList={connectorTypes}
      />
    </DenseThemeProvider>
  );
};

export default CableListingTab;
