import { memo, useEffect, useState } from 'react';
import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import moment, { Moment } from 'moment';

import { Box, StepDisplayInfo } from 'base-components';
import { CruiseJSON } from 'domain/services/CruiseService';
import { DiveJSON } from 'domain/services/DiveListingService';
import SeaTubeChatLogService from 'domain/services/SeaTubeChatLogService';
import LinearOncStepper from 'library/CompositeComponents/stepper/LinearOncStepper';
import Step from 'library/CompositeComponents/stepper/Step';
import Environment from 'util/Environment';
import ChatLogConfirmationDialog from './ChatLogConfirmationDialog';
import ChatLogIngestionContext from './ChatLogIngestionContext';
import ChatLogIngestionParser from './ChatLogIngestionParser';
import IngestionUtil from './IngestionUtil';
import ChatLogInformationStep from './steps/ChatLogInformationStep';
import ChatLogIngestionStep from './steps/ChatLogIngestionStep';
import ChatLogParsingMethodSelectionStep from './steps/ChatLogParsingMethodSelectionStep';
import ChatLogPreviewStep from './steps/ChatLogPreviewStep';

const styles = () =>
  createStyles({
    stepperContainer: {
      display: 'table',
      'max-width': '1024px',
      'min-width': '0px',
      margin: '0px auto',
    },
  });

interface ChatLogIngestionStepperProps extends WithStyles<typeof styles> {
  initialStepNum?: number;
}

export type ChatLogMessage = {
  chatLogMsgId: number;
  username?: string;
  msg: string;
  msgDate: string;
};

export type ParsingErrorLine = {
  number: number;
  line: string;
  error: string;
};

const ChatLogIngestionStepper: React.VFC<ChatLogIngestionStepperProps> = ({
  initialStepNum = 1,
  classes,
}) => {
  const [stepNum, setStepNum] = useState<number>(initialStepNum);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [date, setDate] = useState<Moment | null>(null);
  const [cruises, setCruises] = useState<CruiseJSON[] | undefined>([]);
  const [dives, setDives] = useState<DiveJSON[] | undefined>([]);
  const [cruiseId, setCruiseId] = useState<number | undefined>(undefined);
  const [diveId, setDiveId] = useState<number | undefined>(undefined);
  const [diveDateFrom, setDiveDateFrom] = useState<Moment | null>(null);
  const [diveDateTo, setDiveDateTo] = useState<Moment | null>(null);
  const [parsingMethod, setParsingMethod] = useState<string | null>(null);
  const [fileContents, setFileContents] = useState<string[] | undefined>(
    undefined
  );

  const [chatLogMessages, setChatLogMessages] = useState<ChatLogMessage[]>([]);
  const [errorLines, setErrorLines] = useState<ParsingErrorLine[]>([]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [ingesting, setIngesting] = useState<boolean>(false);
  const [ingestError, setIngestError] = useState<string | undefined>(undefined);

  const displayInfo: StepDisplayInfo[] = [
    {
      label: 'Provide Chat Log Info',
    },
    {
      label: 'Select Parsing Method',
    },
    {
      label: 'Preview',
      hasError: stepNum === 3 && errorLines.length > 0,
    },
    {
      label: 'Ingest',
      hasError: !!ingestError,
    },
  ];

  const PREVIEW_LINES = 500;

  const context = React.useContext(ChatLogIngestionContext);
  const { getDives, getCruises } = context;

  useEffect(
    () => {
      setCruises(undefined);
      async function fetchCruises() {
        if (date && date.isValid()) {
          const response = await getCruises(date.toISOString());
          setCruises(response);
        }
      }
      fetchCruises();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );

  useEffect(
    () => {
      setDives(undefined);
      setDiveId(undefined);
      async function fetchDives() {
        if (date && cruiseId && date.isValid()) {
          const response = await getDives(date.toISOString(), cruiseId);
          setDives(response);
        }
      }
      fetchDives();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, cruiseId]
  );

  useEffect(() => {
    if (file) {
      IngestionUtil.readFile(file, 5000).then((value) => {
        // get rid of any empty objects and lines with navigation data
        setFileContents(
          value
            .filter((a) => a)
            .filter((a) => !IngestionUtil.hasNavigationData(a))
        );
      });
    }
  }, [file]);

  useEffect(() => {
    if (diveId && dives) {
      for (const dive of dives) {
        if (dive.diveId === diveId) {
          setDiveDateFrom(
            dive.dateFrom === null ? null : moment.utc(dive.dateFrom)
          );
          setDiveDateTo(dive.dateTo === null ? null : moment.utc(dive.dateTo));
          return;
        }
      }
    }

    // set to null if there is no diveId, no list of dives, or the diveId is not in the list of dives
    setDiveDateFrom(null);
    setDiveDateTo(null);
  }, [diveId, dives]);

  const decrementStep = () => setStepNum(stepNum - 1);

  const incrementStep = () => setStepNum(stepNum + 1);

  const toggleConfirmationDialog = () =>
    setConfirmDialogOpen(!confirmDialogOpen);

  const ingestChatLogMessages = async () => {
    toggleConfirmationDialog();
    setIngesting(true);
    incrementStep();

    await SeaTubeChatLogService.ingestChatLogMessages(diveId, chatLogMessages)
      .then((response) => {
        // if it was a permissions error, it'll have a message field
        if (response.message) {
          setIngestError(response.message);
        }
      })
      .catch((error) => setIngestError(error.message))
      .finally(() => setIngesting(false));
  };

  const openDive = () =>
    window.open(`${Environment.getDmasUrl()}/app/dive-logs/${diveId}`);

  const resetIngestionProcess = () => {
    setStepNum(1);
    setFile(undefined);
    setDate(null);
    setCruises(undefined);
    setDives(undefined);
    setCruiseId(undefined);
    setDiveId(undefined);
    setParsingMethod(null);
    setFileContents(undefined);
    setChatLogMessages([]);
    setErrorLines([]);
    setConfirmDialogOpen(false);
    setIngesting(false);
    setIngestError(undefined);
  };

  return (
    <Box className={classes.stepperContainer}>
      <ChatLogConfirmationDialog
        open={confirmDialogOpen}
        onIngestCancel={toggleConfirmationDialog}
        onIngestConfirm={ingestChatLogMessages}
      />
      <LinearOncStepper
        displayInfo={displayInfo}
        showLabelBelow
        stepNum={stepNum}
      >
        <Step
          content={
            <ChatLogInformationStep
              onFileChange={(newFile) => setFile(newFile)}
              onDateChange={(dateTime) =>
                setDate(moment.utc(dateTime).startOf('day'))
              }
              onCruiseChange={(id) => setCruiseId(id)}
              onDiveChange={(id) => setDiveId(id)}
              file={file}
              cruises={cruises}
              cruise={cruiseId}
              dive={diveId}
              dives={dives}
              date={date}
            />
          }
          isBackDisabled={() => true}
          isNextDisabled={() => !(cruiseId && diveId && date && file)}
          onBack={() => {}}
          onNext={incrementStep}
        />
        <Step
          content={
            <ChatLogParsingMethodSelectionStep
              fileSample={
                fileContents
                  ? fileContents.slice(0, PREVIEW_LINES).join('\n')
                  : ''
              }
              onRadioChange={(event) => {
                setParsingMethod(event.target.value);
              }}
              value={parsingMethod}
            />
          }
          isBackDisabled={() => false}
          isNextDisabled={() => !parsingMethod}
          onBack={decrementStep}
          onNext={() => {
            setStepNum(stepNum + 1);
            const parsed: {
              chatLogMessages: ChatLogMessage[];
              errorLines: ParsingErrorLine[];
            } = ChatLogIngestionParser.parseChatLog(
              parsingMethod,
              fileContents,
              date,
              diveDateFrom,
              diveDateTo
            );
            setChatLogMessages(parsed.chatLogMessages);
            setErrorLines(parsed.errorLines);
          }}
        />
        <Step
          content={
            <ChatLogPreviewStep
              messages={chatLogMessages}
              errorLines={errorLines}
            />
          }
          isBackDisabled={() => false}
          isNextDisabled={() => errorLines.length > 0}
          onBack={decrementStep}
          onNext={toggleConfirmationDialog}
          nextButtonTranslationKey="seatube.ingest"
        />
        <Step
          content={
            <ChatLogIngestionStep loading={ingesting} error={ingestError} />
          }
          isBackDisabled={() => ingesting}
          isNextDisabled={() => ingesting}
          backButtonTranslationKey="seatube.goToDive"
          nextButtonTranslationKey="seatube.ingestAnother"
          onBack={openDive}
          onNext={resetIngestionProcess}
        />
      </LinearOncStepper>
    </Box>
  );
};

export default withStyles(styles)(memo(ChatLogIngestionStepper));
