import React from 'react';
import { Autocomplete, TextField } from 'base-components';

type GroupOptions = {
  label: string;
  value: number;
};

export type CollectionForm = {
  groupName: string;
  playlistsToAdd: GroupOptions[];
};

export type CollectionFormErrors = Partial<
  Record<keyof CollectionForm, string>
>;

type AddCollectionFormProps = {
  errors: CollectionFormErrors;
  value: CollectionForm;
  groupOptions: GroupOptions[];
  onChange: (key: keyof CollectionForm, value: any) => void;
  onSubmit: () => void;
};

const AddCollectionForm: React.FC<AddCollectionFormProps> = ({
  errors,
  value,
  groupOptions,
  onChange,
  onSubmit,
}) => (
  <form onSubmit={() => onSubmit()}>
    <TextField
      id="form-field-collection-name"
      translationKey="seatube.collectionName"
      fullWidth
      value={value.groupName}
      onChange={(e) => onChange('groupName', e.target.value)}
      error={!!errors.groupName}
      helperText={errors.groupName}
    />
    <Autocomplete
      translationKey="seatube.uncategorizedPlaylists"
      name="uncategorized-playlist-autocomplete"
      value={value.playlistsToAdd}
      fullWidth
      options={groupOptions}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      onChange={(e, v) => onChange('playlistsToAdd', v)}
      error={!!errors.playlistsToAdd}
      helperText={errors.playlistsToAdd}
      multiple
    />
  </form>
);

export default AddCollectionForm;
