import { useRef, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { AvailabilityChart, Typography } from 'base-components';
import useDeepCompareEffect from 'domain/hooks/useDeepCompareEffect';
import DataAvailabilityChartUtil from './DataAvailabilityChartUtil';
import SearchServiceAjax from '../../services/SearchServiceAjax';
import type { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '120px',
      marginTop: theme.spacing(1),
    },
    typography: {
      marginBottom: '-20px',
      display: 'block',
      position: 'relative',
      zIndex: 1,
      width: theme.spacing(19),
    },
  })
);

export type DeviceMapItem = {
  deviceId: number;
  siteDeviceId: number;
};

export type DataAvailabilityChartProps = {
  deviceMap?: DeviceMapItem[];
  deviceId?: number | number[];
  canSelectDateRange?: boolean;
  onDateRangeSelected?: (event: any) => void;
  showTitle?: boolean;
  backgroundColour?: string;
};

const DataAvailabilityChart: React.FC<DataAvailabilityChartProps> = ({
  deviceMap = undefined,
  deviceId = undefined,
  canSelectDateRange = false,
  onDateRangeSelected = () => {},
  showTitle = true,
  backgroundColour = undefined,
}: DataAvailabilityChartProps) => {
  const classes = useStyles();
  const [availabilityDateRanges, setAvailabilityDateRanges] = useState<any[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const cancelRequests = useRef<(() => void)[]>([]);

  useDeepCompareEffect(() => {
    if (cancelRequests.current.length) {
      cancelRequests.current.forEach((cancel) => cancel());
      cancelRequests.current = [];
    }

    setLoading((val) => !val && true);
    const loadAvailabilityDateRanges = async () => {
      const requests: Promise<unknown>[] = [];

      if (deviceMap) {
        deviceMap.forEach(({ siteDeviceId, deviceId: devId }) => {
          const { promise, cancel } =
            SearchServiceAjax.getForSiteDeviceCancellablePromise(
              devId,
              siteDeviceId
            );

          requests.push(promise);
          cancelRequests.current.push(cancel);
        });
      }

      if (deviceId) {
        if (Array.isArray(deviceId)) {
          deviceId.forEach((id) => {
            const { promise, cancel } =
              SearchServiceAjax.getForDeviceCancellablePromise(id);
            requests.push(promise);
            cancelRequests.current.push(cancel);
          });
        } else {
          const { promise, cancel } =
            SearchServiceAjax.getForDeviceCancellablePromise(deviceId);
          requests.push(promise);
          cancelRequests.current.push(cancel);
        }
      }

      const responses = await Promise.allSettled(requests);
      setAvailabilityDateRanges(
        DataAvailabilityChartUtil.formatDateArray(
          [].concat(
            ...responses.map((response) => {
              if (!response || response.status === 'rejected') return [];

              return response.value ? [].concat(response.value) : [];
            })
          )
        )
      );
      setLoading(false);
    };

    loadAvailabilityDateRanges();
  }, [deviceMap, deviceId]);

  return (
    <div className={classes.root}>
      {showTitle ? (
        <Typography variant="subtitle1" className={classes.typography}>
          Data Availability
        </Typography>
      ) : null}
      <AvailabilityChart
        availabilityDateRanges={availabilityDateRanges}
        displayDateRange={DataAvailabilityChartUtil.getAvailableRange(
          availabilityDateRanges
        )}
        stillLoading={loading}
        canSelectDateRange={canSelectDateRange}
        onDateRangeSelected={onDateRangeSelected}
        displayDefaultTitle={false}
        backgroundColour={backgroundColour}
      />
    </div>
  );
};

export default DataAvailabilityChart;
