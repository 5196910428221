import React from 'react';
import { SkipNext } from '@onc/icons';
import { IconButton } from 'base-components';

type NextVideoButtonProps = {
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  containerRef?: HTMLDivElement;
};

const NextVideoButton: React.FC<NextVideoButtonProps> = ({
  disabled = false,
  onClick,
  containerRef = undefined,
}: NextVideoButtonProps) => (
  <IconButton
    disabled={disabled}
    color="inherit"
    onClick={onClick}
    aria-label="Next Video"
    tooltipProps={{
      slotProps: {
        popper: { container: containerRef },
      },
    }}
  >
    <SkipNext color="inherit" />
  </IconButton>
);

export default NextVideoButton;
