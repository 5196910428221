import { Loading } from '@onc/composite-components';
import { Playlist } from 'domain/services/PlaylistService';

import useGet from 'util/hooks/useDmasAPI/useGet';
import { useSnackbars } from 'util/hooks/useSnackbars';
import DFManagementEditorForm from './DFManagementEditorForm';

export type CampaignPayload = {
  campaign: {
    active: boolean;
    campaignImage: string;
    campaignName: string;
    dfCampaignId: number;
    startDate: string;
    endDate: string;
    missionStatement: string;
    missionTitle: string;
    formSectionId?: number;
    videos?: string;
    playlistHdrId?: number;
    modifiedBy: number;
    modifiedDate: string;
    taxonomyMatrixId?: number;
  };
};

export type TaxonsPayload = [{ commonName: string; taxonomyMatrixId: number }];

export type DFManagementEditorProps = {
  landingPath: string;
  match?: {
    params: {
      campaignId: string;
    };
  };
};

const DFManagementPlaylistHandler = ({
  campaignData,
  landingPath,
  match = undefined,
  taxonsData,
  onError,
  onInfo,
}: DFManagementEditorProps & {
  campaignData: CampaignPayload;
  taxonsData: TaxonsPayload;
  onError: (message: string) => void;
  onInfo: (message: string) => void;
}) => {
  const playlistName = useGet<Playlist, { playlistHdrId: number }>(
    `internal/playlists/${campaignData.campaign.playlistHdrId}`,
    {
      operation: undefined,
      transform: (response) => response.data,
    }
  );

  if (playlistName.isLoading) {
    return <Loading />;
  }

  if (playlistName.error) {
    onError(playlistName.error.message);
    return undefined;
  }

  return (
    <DFManagementEditorForm
      landingPath={landingPath}
      match={match}
      campaignData={campaignData}
      taxonsData={taxonsData}
      playlistName={playlistName.data.name}
      onError={onError}
      onInfo={onInfo}
    />
  );
};

const DFManagementEditor = ({
  landingPath,
  match = undefined,
}: DFManagementEditorProps) => {
  const { onInfo, onError } = useSnackbars();
  const campaignId = Number(match.params.campaignId);

  const campaignData = useGet<CampaignPayload, { dfCampaignId: number }>(
    'DigitalFishersService',
    { operation: 7, options: { enabled: campaignId > 0 } },
    {
      dfCampaignId: campaignId,
    }
  );

  // TaxonomyService has no 'operation' parameter, so I just set it to 0.
  const taxonsData = useGet<TaxonsPayload>(
    'internal/taxonomies/40/nodetypes/66',
    {
      operation: undefined,
      transform: (response) => response.data,
    }
  );

  if (taxonsData.isLoading || (campaignId !== 0 && campaignData.isLoading)) {
    return <Loading />;
  }

  if (taxonsData.error) {
    onError(taxonsData.error.message);
    return undefined;
  }

  if (campaignId !== 0 && campaignData.error) {
    onError(campaignData.error.message);
    return undefined;
  }

  if (campaignId !== 0 && campaignData?.data?.campaign.playlistHdrId) {
    return (
      <DFManagementPlaylistHandler
        landingPath={landingPath}
        match={match}
        campaignData={campaignData.data}
        taxonsData={taxonsData.data}
        onError={onError}
        onInfo={onInfo}
      />
    );
  }
  return (
    <DFManagementEditorForm
      landingPath={landingPath}
      match={match}
      campaignData={campaignData?.data}
      taxonsData={taxonsData.data}
      onError={onError}
      onInfo={onInfo}
    />
  );
};

export default DFManagementEditor;
