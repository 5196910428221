import { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Grid } from 'base-components';
import DraggableToolbox from 'domain/AppComponents/annotations/entry/DraggableToolbox';
import {
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import {
  SaveButton,
  CancelButton,
} from 'library/CompositeComponents/button/Buttons';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateFormatUtils from 'util/DateFormatUtils';
import DeviceType from './DeviceType';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
});

type DeviceTypeDialogBoxProps = {
  onError: (message: any, callback?: any) => void;
  onClose: () => void;
  onSubmit: (deviceType: DeviceType) => void;
  label: string;
  deviceType: DeviceType;
};

const validate = (
  deviceType: DeviceType | undefined,
  fieldName: keyof DeviceType
) => (deviceType && deviceType[fieldName] ? String(deviceType[fieldName]) : '');

const createTextField = (
  value,
  setValue,
  label,
  isRequired,
  attemptedSubmit,
  validator = (val) => val,
  helperText = ''
) => (
  <Grid item xs={12}>
    {isRequired ? (
      <TextField
        fullWidth
        variant="filled"
        value={validator(value)}
        onChange={(event) => setValue(validator(event.target.value))}
        label={label}
        error={attemptedSubmit && !value}
        helperText={attemptedSubmit && !value ? 'Required field' : helperText}
        required
      />
    ) : (
      <TextField
        fullWidth
        variant="filled"
        value={validator(value)}
        onChange={(event) => setValue(validator(event.target.value))}
        label={label}
        helperText={helperText}
      />
    )}
  </Grid>
);

const DeviceTypeDialogBox = ({
  onError,
  onClose,
  onSubmit,
  label,
  deviceType,
}: DeviceTypeDialogBoxProps) => {
  const [deviceTypeName, setDeviceTypeName] = useState<string>(
    validate(deviceType, 'deviceTypeName')
  );
  const [deviceTypeCode, setDeviceTypeCode] = useState<string>(
    validate(deviceType, 'deviceTypeCode')
  );
  const [deviceTypeDescription, setDeviceTypeDescription] = useState<string>(
    validate(deviceType, 'description')
  );
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const handleOnSubmit = () => {
    setAttemptedSubmit(true);
    if (!deviceTypeName) {
      return;
    }
    if (!deviceTypeName.trim() || !deviceTypeCode.trim()) {
      onError('Name and Code cannot be blank ');
    } else {
      onSubmit({
        deviceTypeId: deviceType ? deviceType.deviceTypeId : undefined,
        deviceTypeName,
        deviceTypeCode,
        description: deviceTypeDescription,
      });
    }
  };

  const renderFormButtons = () => (
    <Grid item xs={12}>
      <SaveButton onClick={() => handleOnSubmit()} />
      <CancelButton
        onClick={() => {
          onClose();
        }}
      />
    </Grid>
  );

  useEffect(() => {
    if (deviceType) {
      setDeviceTypeName(deviceType?.deviceTypeName || '');
      setDeviceTypeCode(deviceType?.deviceTypeCode || '');
      setDeviceTypeDescription(deviceType?.description || '');
      setAttemptedSubmit(false);
    }
  }, [deviceType]);

  const validateDeviceTypeCode = (value) => {
    const newValue = value.toUpperCase();
    return /^[A-Z0-9_-]*$/.test(newValue) ? newValue : deviceTypeCode;
  };

  return (
    <>
      <DraggableToolbox
        title={label}
        initiallyExpanded
        onClose={() => onClose()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              value={validate(deviceType, 'deviceTypeId')}
              label="Device Type ID"
              variant="filled"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            {createTextField(
              deviceTypeName,
              setDeviceTypeName,
              'Device Type Name',
              true,
              attemptedSubmit
            )}
          </Grid>
          <Grid item xs={12}>
            {createTextField(
              deviceTypeCode,
              setDeviceTypeCode,
              'Device Type Code',
              true,
              attemptedSubmit,
              validateDeviceTypeCode,
              'Only uppercase letters, digits, underscores and hyphens are allowed.'
            )}
          </Grid>
          <Grid item xs={12}>
            {createTextField(
              deviceTypeDescription,
              setDeviceTypeDescription,
              'Device Type Description',
              false,
              attemptedSubmit
            )}
          </Grid>
          <Grid item xs={12}>
            <ModifyBy
              username={deviceType.modifyBy ? deviceType.modifyByName : ''}
            />
            <ModifyDate
              date={
                deviceType.modifyDate
                  ? DateFormatUtils.formatDate(deviceType.modifyDate, 'full')
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            {renderFormButtons()}
          </Grid>
        </Grid>
      </DraggableToolbox>
    </>
  );
};

export default withStyles(styles)(withSnackbars(DeviceTypeDialogBox));
