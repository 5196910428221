import * as React from 'react';
import moment from 'moment';
import DataPlayerWidgetConfig from 'domain/AppComponents/Dashboard/DataPlayerWidgetConfig';
import DataPlayerWidgetDisplay from 'domain/AppComponents/Dashboard/DataPlayerWidgetDisplay';

type DataPlayerWidgetProps = {
  onSave: (values: any) => void;
  onConfigFinish: () => void;
  handleToolboxClose: () => void;
  showTitle: boolean;
  title: string;
  startDate: Date;
  dataSourceType: 'location' | 'deviceCategory';
  dateSelectorValue: 'latest' | 'dateRange' | string;
  deviceId: number;
  deviceCategoryCode: string;
  timeRange: string;
  isBroadcasting: boolean;
  locations: any[];
  showDisplay?: boolean;
  showToolbox?: boolean;
  device?: {
    deviceCategoryCode: string;
    deviceCode: string;
    deviceId: number;
    name: string;
  };
};

const DataPlayerWidget: React.FC<DataPlayerWidgetProps> = ({
  onSave,
  onConfigFinish,
  handleToolboxClose,
  showTitle,
  title,
  startDate,
  dataSourceType,
  dateSelectorValue,
  deviceId,
  locations,
  timeRange,
  deviceCategoryCode,
  showDisplay = undefined,
  showToolbox = undefined,
  device = undefined,
  isBroadcasting,
}) => {
  if (showDisplay) {
    return (
      <DataPlayerWidgetDisplay
        showTitle={showTitle}
        title={title}
        startDate={startDate}
        dataSourceType={dataSourceType || 'deviceCategory'}
        dateSelectorValue={dateSelectorValue}
        deviceId={deviceId}
        deviceCategoryCode={deviceCategoryCode}
        timeRange={timeRange}
        showToolbox={showToolbox}
        handleToolboxClose={handleToolboxClose}
        isCommunicating={isBroadcasting}
        locations={locations}
      />
    );
  }
  return (
    <DataPlayerWidgetConfig
      onSave={onSave}
      onCancel={onConfigFinish}
      showTitle={showTitle}
      title={title}
      startDate={moment(startDate)}
      dataSourceType={dataSourceType}
      dateSelectorValue={dateSelectorValue || 'deviceCategory'}
      device={device}
      deviceId={deviceId}
      deviceCategoryCode={deviceCategoryCode}
      isBroadcasting={isBroadcasting}
      locations={locations}
      timeRange={timeRange}
    />
  );
};

export default DataPlayerWidget;
