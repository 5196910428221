import { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import DeviceTypeService from 'domain/services/DeviceTypeService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import { AbsoluteRightButtonStyle } from 'library/CompositeComponents/button/CommonButtonStyles';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import DeviceType from './DeviceType';
import DeviceTypeDialogBox from './DeviceTypeDialogBox';
import DeviceTypeTable from './DeviceTypeTable';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(2)})`,
      minWidth: '400px',
    },
    button: {
      ...AbsoluteRightButtonStyle(theme),
      position: 'absolute',
      zIndex: 4,
    },
  })
);

type DeviceTypePageProps = {
  onError: (message: any, callback?: any) => void;
};

const DeviceTypePage = (props: DeviceTypePageProps) => {
  const { onError } = props;
  const userHasWritePermission = Environment.getDmasUserPrivilege() === 'RW';
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[] | null>(null);
  const [editDeviceType, setEditDeviceType] = useState<DeviceType>(null);
  const classes = useStyles();

  const renderCreateDeviceTypeButton = () => (
    <ContainedButton
      translationKey="device.createDeviceType"
      className={classes.button}
      onClick={() => {
        setEditDeviceType({});
      }}
      disabled={editDeviceType !== null || !userHasWritePermission}
    />
  );

  const openAndSaveEditData = (device: DeviceType) => {
    setEditDeviceType(device);
    return true;
  };

  const handlePostCall =
    (call: (deviceType: DeviceType) => any) => async (value) => {
      try {
        const response = await call(value);
        if (response.data.statusCode === 0) {
          const payload: DeviceType[] =
            await DeviceTypeService.getAllDeviceTypes();
          setDeviceTypes(payload);
          if (editDeviceType !== null) {
            setEditDeviceType(null);
          }
        } else {
          onError('Device Type Name and Code must be unique');
        }
      } catch (e) {
        onError(e);
      }
    };

  useEffect(() => {
    const refreshTableData = async () => {
      const payload: DeviceType[] = await DeviceTypeService.getAllDeviceTypes();
      setDeviceTypes(payload);
    };
    refreshTableData();
  }, []);

  const renderDialogBox = () => {
    if (Object.keys(editDeviceType).length !== 0) {
      return (
        <DeviceTypeDialogBox
          label="Edit Device Type"
          onClose={() => setEditDeviceType(null)}
          onSubmit={handlePostCall(DeviceTypeService.updateDeviceType)}
          userHasWritePermission={userHasWritePermission}
          deviceType={editDeviceType}
        />
      );
    }
    return (
      <DeviceTypeDialogBox
        label="Create Device Type"
        onClose={() => setEditDeviceType(null)}
        onSubmit={handlePostCall(DeviceTypeService.createDeviceType)}
        userHasWritePermission={userHasWritePermission}
        deviceType={editDeviceType}
      />
    );
  };

  return (
    <>
      {renderCreateDeviceTypeButton()}
      {editDeviceType ? renderDialogBox() : <></>}
      <DeviceTypeTable
        userHasWritePermission={userHasWritePermission}
        onError={onError}
        deviceTypes={deviceTypes}
        openAndSaveEditData={openAndSaveEditData}
      />
    </>
  );
};

export default withSnackbars(DeviceTypePage);
