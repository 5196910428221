import * as React from 'react';
import { Grid } from 'base-components';
import { EndeavourEarthquakeFixedDepthRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import Filter, {
  FilterImplementationProps,
  FilterValue,
  SummaryValues,
} from 'library/CompositeComponents/filter/Filter';

export interface EarthquakeDepthValue extends FilterValue {
  depthType: 'all' | 'fixedDepthOnly' | 'variableDepthOnly';
}

interface Props extends FilterImplementationProps {
  value?: EarthquakeDepthValue;
}

const EarthquakeDepthFilter: React.FC<Props> = ({
  onChange,
  name,
  value = {
    depthType: 'all',
  },
  onExpand,
  expanded,
}: Props) => {
  const renderSummaryValues = () => {
    if (value) {
      const summary: SummaryValues[] = [];
      summary.push({ label: `${value.depthType}` });
      return summary;
    }
    return undefined;
  };

  return (
    <Filter
      title="Earthquake Depth Type"
      value={value || undefined}
      name={name}
      summaryValues={renderSummaryValues()}
      onChange={onChange}
      onExpand={onExpand}
      expanded={expanded}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EndeavourEarthquakeFixedDepthRadioGroup
            name={name}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </Filter>
  );
};

export default EarthquakeDepthFilter;
