import { ClipForm, ClipFormErrors } from './PlaylistClipForm';

export default function PlaylistClipFormValidation(values: ClipForm) {
  const errors: ClipFormErrors = {};
  if (values.name.trim() === '') {
    errors.name = 'Name Required';
  }

  return errors;
}
