import React, { useRef, FC, ReactNode, Children, isValidElement } from 'react';
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';

type ListRowProps = {
  key: string;
  index: number;
  style: React.CSSProperties;
  parent: List;
};

export interface VirtualListProps {
  /**
   * The children components to be rendered within the list. Each child
   * component represents a row in the list.
   */
  children: ReactNode | ReactNode[];

  /**
   * The index of the row to automatically scroll to upon initial render.
   * Defaults to -1, which disables auto-scrolling.
   */
  scrollToIndex?: number;

  /**
   * Controls the alignment of the scrolled-to row. It can be one of 'start',
   * 'center', 'end', or 'auto'. Defaults to 'auto'.
   */
  scrollToAlignment?: 'start' | 'center' | 'end' | 'auto';
}

/**
 * `VirtualList` is a component that efficiently renders large lists and tabular
 * data. It dynamically loads and unloads the data based on the scroll position,
 * greatly enhancing performance for large data sets.
 */
const VirtualList: FC<VirtualListProps> = ({
  children,
  scrollToIndex = -1,
  scrollToAlignment = 'auto',
}) => {
  const listRef = useRef<List>(null);
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 50,
  });

  const childArray = Children.toArray(children);

  /**
   * The row renderer function that renders each row in the list. It wraps each
   * child component in a `CellMeasurer` component to measure the height of each
   * row and ensure that the list is rendered efficiently.
   */
  const rowRenderer = ({ key, index, style, parent }: ListRowProps) => {
    const child = childArray[index];

    if (!isValidElement(child)) {
      return null;
    }

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div key={key} style={style}>
          {child}
        </div>
      </CellMeasurer>
    );
  };

  /**
   * The resize handler that is called when the window is resized. It clears the
   * cache to recalculate the row heights and recomputes the row heights for the
   * entire list.
   */
  const onResize = () => {
    cache.clearAll(); // Clear cache to recalculate row heights
    if (listRef.current) {
      listRef.current.recomputeRowHeights(); // Recompute row heights for the entire list
    }
  };

  return (
    <AutoSizer onResize={onResize}>
      {({ height, width }) => (
        <List
          height={height}
          width={width}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={rowRenderer}
          rowCount={childArray.length}
          ref={listRef}
          overscanRowCount={3}
          scrollToIndex={scrollToIndex}
          scrollToAlignment={scrollToAlignment}
        />
      )}
    </AutoSizer>
  );
};

export default VirtualList;
