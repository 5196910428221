import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid } from 'base-components';

const styles = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  inputGrid: {
    paddingRight: theme.spacing(3),
  },
});

class ResponsiveDataGridLayout extends Component {
  static propTypes = {
    formFields: PropTypes.arrayOf(PropTypes.shape({})),
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      inputGrid: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    formFields: [],
  };

  getCell = (field, classes) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      className={classes.inputGrid}
      key={field.toString()}
    >
      {field}
    </Grid>
  );

  getGrid = (items, classes) => (
    <Grid container direction="row">
      {items.map((item) => this.getCell(item, classes))}
    </Grid>
  );

  render() {
    const { classes, formFields } = this.props;
    const primaryFields = (
      <Grid container direction="column" className={classes.entryFormContainer}>
        {this.getGrid(formFields, classes)}
      </Grid>
    );
    return primaryFields;
  }
}

export default withStyles(styles)(ResponsiveDataGridLayout);
