import { useState, useEffect, useCallback, FC, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import L from 'leaflet';

import Environment from '@onc/environment';
import { Link as LinkIcon } from '@onc/icons';
import {
  ESRIDynamicMapLayer,
  ESRIImageLayer,
  MapFABControl,
  LeafletIcon,
  Marker,
  MarkerClusterGroup,
  LeafletTooltip as Tooltip,
  SizeMe,
  WMSTileLayer,
} from 'base-components';

import MapContext from 'library/CompositeComponents/map/MapContext';
import SimpleMap, {
  SimpleMapProps,
} from 'library/CompositeComponents/map/SimpleMap';
import DateFormatUtils from 'util/DateFormatUtils';

import './ONCMap.css';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    '& .leaflet-control-scale-line, .leaflet-control-attribution': {
      '& a': {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: '0.750rem',
      },
    },
  },
}));

/**
 * Counts the number of original markers in a cluster. (i.e. a marker that
 * represents 2 annotations is counted as 2)
 */
const countCondensedClusterMarkers = (cluster) => {
  let total = 0;
  // eslint-disable-next-line no-underscore-dangle
  for (const marker of cluster._markers) {
    total += marker.options.count ? marker.options.count : 1;
  }
  // eslint-disable-next-line no-underscore-dangle
  for (const subCluster of cluster._childClusters) {
    total += countCondensedClusterMarkers(subCluster);
  }
  return total;
};

const createClusterIcon = (cluster, count, markerColour) => {
  const text = count || countCondensedClusterMarkers(cluster);
  if (markerColour) {
    return L.divIcon({
      html: `<div style="background-color: ${markerColour};"><span>${text}</span></div>`,
      className: `leaflet-marker-icon marker-cluster marker-cluster-icon leaflet-zoom-animated leaflet-interactive`,
      iconSize: L.point(40, 40, true),
    });
  }
  return L.divIcon({
    html: `<div><span>${text}</span></div>`,
    className: `leaflet-marker-icon marker-cluster marker-cluster-icon leaflet-zoom-animated leaflet-interactive`,
    iconSize: L.point(40, 40, true),
  });
};

export interface Marker {
  /** Id of the marker */
  markerId?: number;
  /** Latitude to place the marker at */
  lat?: number;
  /** Longtitude to place the marker at */
  lon?: number;
  /** Earliest date for this marker to exist */
  startDate?: string;
  /** Tooltip to render for the marker */
  tooltip?: string;
  /** Function to call if the marker is clicked */
  onClick?: () => void;
  /** Color of the marker. Will be used as a backgroundColor in CSS */
  colour?: string;
}

export interface MarkerConfig {
  /** Name of the config */
  name: string;
  /** Whether or not the marker layer starts enabled in the menu */
  checked: boolean;
  /** Function to call if the generated marker overlay is clicked (Maybe unused?) */
  onClick: (selectedMarker: Marker) => void;
  /** Markers to render */
  markers?: Marker[];
  /** Whether or not to condense same date & location markers */
  condenseSameDateLocation?: boolean;
}

export interface ONCMapProps extends SimpleMapProps {
  /** Map ID used for the map container */
  mapId: string;
  /** Whether or not to get and render bathymetries */
  bathymetry?: boolean;
  /** Overlay layers that can be enabled and rendered */
  overlayLayers?: unknown[];
  /** Marker configuration */
  markerConfig?: MarkerConfig;
  /** ID of the currently selected marker */
  selectedMarkerId?: number;
  /** Icon for the currently selected marker */
  selectedMarkerIcon?: L.Icon;
  /** Whether to render the bathymetry permissions link */
  bathymetryPermissions?: boolean;
  /**
   * Default overlays to enable. Should be names inside overlayLayers or
   * returned from the bathymetries service
   */
  defaultOverlays?: string[];
  /** Fuction called when an overlay is selected. Passed to SimpleMap */
  onOverlaySelect?: (e: any) => void;
  /** Override action that leaflet calls if an overlay is removed */
  onOverlayRemove?: (e: any) => void;
  /** Override action that leaflet calls when the base layer is changed */
  onBaseLayerChange?: (e: any) => void;
}

const ONCMap: FC<ONCMapProps> = ({
  mapId,
  bathymetry = false,
  overlayLayers = [],
  markerConfig = undefined,
  selectedMarkerId = undefined,
  selectedMarkerIcon = LeafletIcon.GREEN,
  children = undefined,
  bathymetryPermissions = false,
  defaultOverlays = undefined,
  onOverlaySelect = () => {},
  onOverlayRemove = () => {},
  onBaseLayerChange = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const { onError } = useSnackbars();
  const [bathymetryLayers, setBathymetryLayers] = useState([]);
  const [markerLayer, setMarkerLayer] = useState(undefined);

  const mapContext = useContext(MapContext);
  const [map, setMap] = useState<any>();
  useEffect(() => {
    setMap(mapContext?.mapRef);
  }, [mapContext]);

  const parseWMSLayers = (data) => {
    const htmlObj = document.createElement('div');
    htmlObj.innerHTML = data;
    const potentialNames = htmlObj.getElementsByTagName('Name');
    const layerNames = [];
    for (let i = 0; i < potentialNames.length; i += 1) {
      // if the parent of this Name node is a Layer that is queryable
      const { parentElement } = potentialNames[i];
      if (
        parentElement.nodeName === 'LAYER' &&
        parentElement.attributes.length > 0 &&
        parentElement.getAttribute('queryable').indexOf('1') > -1
      ) {
        layerNames.push(potentialNames[i].textContent);
      }
    }
    return layerNames.join(',');
  };

  const handleWMSOverlay = useCallback(
    async (e) => {
      // check that it's a WMS server
      if (e.layer.wmsParams) {
        const { name } = e;
        const { _url: wmsServerUrl } = e.layer;
        const url = `${wmsServerUrl}?request=GetCapabilities&service=WMS`;
        // run the check, like in createWMSLayer right now
        try {
          const response = await get(url);
          const { data } = response;
          const layers = parseWMSLayers(data);
          // Create the WMS Component
          const subLayers = (
            <WMSTileLayer
              url={wmsServerUrl}
              layers={layers}
              format="image/png"
              transparent
            />
          );
          // Append this new overlay to the state
          const overlayLayer = {
            name,
            subLayers,
            checked: defaultOverlays?.includes(e.name),
          };
          setBathymetryLayers((prevLayers) =>
            prevLayers.map((obj) =>
              obj.name === overlayLayer.name ? overlayLayer : obj
            )
          );
        } catch (error) {
          onError(`Failed to load bathymetry ${name}`);
        }
      }
      // call the passed-down function
      onOverlaySelect(e);
    },
    [defaultOverlays, onError, onOverlaySelect]
  );

  useEffect(() => {
    if (map?.current) {
      if (onBaseLayerChange) {
        map.current.on('baselayerchange', onBaseLayerChange);
      }
      if (onOverlayRemove) {
        map.current.on('overlayremove', onOverlayRemove);
      }
      map.current.on('onOverlayAdd', handleWMSOverlay);
    }
  }, [map, handleWMSOverlay, onBaseLayerChange, onOverlayRemove]);

  /**
   * Collapses any annotations that have the same lat/lon/time into a single
   * point, while preserving the proper counts to display on the markers Note
   * that becasue the selected annotation is marked separately, it isn't
   * preserved here.
   */
  const condenseMarkerConfig = (config) => {
    // pseudo-hash to find/count/remove duplicates in O(n) time
    const table = {};
    const simpleHash = (marker) =>
      `${marker.lat.toString()}-${marker.lon.toString()}-${marker.startDate}`;

    for (const marker of config.markers) {
      const hashVal = simpleHash(marker);
      if (Object.prototype.hasOwnProperty.call(table, hashVal)) {
        table[hashVal].count += 1;
      } else {
        const { markerId, lat, lon, tooltip, onClick, startDate } = marker;
        table[hashVal] = {
          markerId,
          lat,
          lon,
          tooltip,
          onClick,
          count: 1,
          startDate,
        };
      }
    }
    return Object.values(table);
  };

  /**
   * Prefers a pre-specified tooltip. If that doesn't exist, build one out of
   * the start date if possible
   */
  const renderMarkerTooltip = (marker) => {
    let text = marker.tooltip;
    if (!text && marker.startDate)
      text = DateFormatUtils.formatDate(marker.startDate, 'full');
    if (!text) return undefined;

    return <Tooltip permanent={false}>{text}</Tooltip>;
  };

  /**
   * No return - sets the generated configuration to this.state Note that the
   * selected marker pin is set further down
   */
  const createMarkerOverlay = useCallback(
    (config) => {
      const markerProtos = config.condenseSameDateLocation
        ? condenseMarkerConfig(config)
        : config.markers;
      // Create jsx marker object for each marker
      const markers = markerProtos.map((marker) => {
        // Allows for individual markers to have unique onClick
        let onClickFunc = config.onClick;
        if (marker.onClick) onClickFunc = marker.onClick;

        return (
          <Marker
            key={marker.markerId}
            position={[marker.lat, marker.lon]}
            eventHandlers={{
              click: () => {
                onClickFunc(marker);
              },
            }}
            icon={createClusterIcon(
              undefined,
              marker.count ? marker.count : 1,
              marker.colour
            )}
          >
            {renderMarkerTooltip(marker)}
          </Marker>
        );
      });

      // Cluster the white markers
      const subLayers = (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <MarkerClusterGroup iconCreateFunction={createClusterIcon}>
          {markers}
        </MarkerClusterGroup>
      );
      setMarkerLayer({
        name: markerConfig?.name,
        subLayers,
        checked: markerConfig?.checked,
      });
    },
    [markerConfig?.checked, markerConfig?.name]
  );

  const createWMSLayer = useCallback(
    (url, name) => {
      const wmsServerUrl = url.substring(0, url.indexOf('?'));
      // Create the WMS Component
      const subLayers = (
        <WMSTileLayer url={wmsServerUrl} format="image/png" transparent />
      );
      const overlayLayer = {
        name,
        subLayers,
        checked: defaultOverlays?.includes(name),
      };
      return overlayLayer;
    },
    [defaultOverlays]
  );

  const createESRIDynamicMapLayer = useCallback(
    (url, name) => ({
      name,
      subLayers: <ESRIDynamicMapLayer url={url} />,
      checked: defaultOverlays?.includes(name),
    }),
    [defaultOverlays]
  );

  const createESRIImageLayer = useCallback(
    (url, name) => {
      const imageServerUrl = `${url.substring(
        0,
        url.indexOf('ImageServer')
      )}ImageServer`;
      return {
        name,
        subLayers: <ESRIImageLayer url={imageServerUrl} />,
        checked: defaultOverlays?.includes(name),
      };
    },
    [defaultOverlays]
  );

  const createBaseOverlays = useCallback(
    (bathymetries) => {
      const newLayers = bathymetries.map((bath) => {
        const { url, name } = bath;
        if (url.includes('WMSServer')) {
          return createWMSLayer(url, name);
        }
        if (url.includes('MapServer')) {
          return createESRIDynamicMapLayer(url, name);
        }
        return createESRIImageLayer(url, name);
      });
      setBathymetryLayers(newLayers);
    },
    [createESRIDynamicMapLayer, createESRIImageLayer, createWMSLayer]
  );

  const getBathymetries = useCallback(async () => {
    try {
      const payload = await get('BathymetryService', {
        operation: 1,
      }).then((response) => parseDmasAPIResponse(response));
      createBaseOverlays(payload);
    } catch (error: any) {
      onError(error);
    }
  }, [createBaseOverlays, onError]);

  useEffect(() => {
    if (markerConfig) createMarkerOverlay(markerConfig);
  }, [createMarkerOverlay, markerConfig]);

  useEffect(() => {
    if (bathymetry) getBathymetries();
  }, [bathymetry, getBathymetries]);

  /** Opens the bathymetry page in a new tab */
  const openBathymetry = () => {
    window.open(`${Environment.getDmasUrl()}/Bathymetry`, '_blank');
  };

  /**
   * Adds the bathymetry and marker layers to the passed in overlays to be
   * passed to the Simple Map
   */
  const concatOverlays = (layers) => {
    const results = [];
    layers.forEach((layer) => results.push(layer));
    bathymetryLayers.forEach((layer) => results.push(layer));
    if (markerLayer) results.push(markerLayer);
    return results;
  };

  const renderSelectedMarkerPin = () => {
    if (!selectedMarkerId || !markerConfig) return undefined;
    const selectedMarker = markerConfig.markers.find(
      (marker) => marker.markerId === selectedMarkerId
    );

    // if no marker is selected, don't place a pin
    if (!selectedMarker) return undefined;

    let onClickFunc = markerConfig.onClick;
    if (selectedMarker.onClick) onClickFunc = selectedMarker.onClick;
    return (
      <Marker
        title="Annotation Pin"
        key={selectedMarker.markerId}
        position={[selectedMarker.lat, selectedMarker.lon]}
        eventHandlers={{
          click: () => {
            onClickFunc(selectedMarker);
          },
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        icon={selectedMarkerIcon}
        zIndexOffset={1000}
      >
        {renderMarkerTooltip(selectedMarker)}
      </Marker>
    );
  };

  const renderBathymetryLink = () => {
    if (!bathymetryPermissions) return null;
    return (
      <MapFABControl
        options={{ position: 'topright' }}
        onClick={openBathymetry}
        color="primary"
        TooltipProps={{ title: 'Bathymetry' }}
        size="small"
      >
        <LinkIcon />
      </MapFABControl>
    );
  };

  const concatOverlay = concatOverlays(overlayLayers);

  return (
    <div className={classes.root}>
      <SizeMe>
        <SimpleMap overlayLayers={concatOverlay} mapId={mapId} {...rest}>
          {renderBathymetryLink()}
          {children}
          {renderSelectedMarkerPin()}
        </SimpleMap>
      </SizeMe>
    </div>
  );
};

export default ONCMap;
