import { get } from 'util/WebRequest';

export interface DeviceServiceGetParams {
  operationtype: number;
  deviceCategoryId?: number;
  deviceTypeId?: number;
  deviceid?: number;
  network?: number;
  effectivedate?: string;
  deviceids?: number;
}

class DeviceService {
  static getDevicesForDeviceCategoryId(deviceCategoryId: number) {
    return DeviceService.get({ operationtype: 4, deviceCategoryId });
  }

  static getDevicesForDeviceTypeId(deviceTypeId: number) {
    return DeviceService.get({ operationtype: 5, deviceTypeId });
  }

  static getAllDevices() {
    return DeviceService.get({ operationtype: 12 });
  }

  static getDevicesForDeviceTypeAndCategoryId(
    deviceTypeId: number,
    deviceCategoryId: number
  ) {
    return DeviceService.get({
      operationtype: 6,
      deviceTypeId,
      deviceCategoryId,
    });
  }

  static getOne(deviceid: number) {
    return DeviceService.get({ operationtype: 2, deviceid, network: -1 });
  }

  static getByEffectiveDate(effectivedate: string) {
    return get('DeviceService', {
      operationtype: 1,
      effectivedate,
    }).then((response) => response);
  }

  static get(params: DeviceServiceGetParams) {
    return get('DeviceService', {
      ...params,
    }).then((response) => response.data.payload);
  }

  static getData(deviceids: number) {
    return DeviceService.get({ operationtype: 3, deviceids });
  }
}
export default DeviceService;
