import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { TranslationType } from '@onc/i18n';
import { DialogContent } from 'base-components';
import {
  CancelButton,
  ContainedButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    float: 'right',
  },
}));

type DialogFormProps = {
  onSubmit: () => void;
  onCancel: () => void;
  primaryButtonTranslationKey?: TranslationType;
  children: React.ReactNode;
};

const DialogForm = ({
  onSubmit,
  onCancel,
  primaryButtonTranslationKey = 'common.buttons.save',
  children,
}: DialogFormProps) => {
  const classes = useStyles();

  const renderFormActions = () => {
    const buttons = [
      <CancelButton key="edit-form-cancel-button" onClick={onCancel} />,
      <ContainedButton
        translationKey={primaryButtonTranslationKey}
        type="submit"
        key="edit-form-submit-button"
      />,
    ];
    return <div className={classes.buttonContainer}>{buttons}</div>;
  };

  const renderFormContent = () => <DialogContent>{children}</DialogContent>;

  const formMethods = useForm({
    mode: 'onBlur',
  });

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods}>
      {renderFormContent()}
      {renderFormActions()}
    </Form>
  );
};

export default DialogForm;
