import { useEffect, useState } from 'react';

import FormAutocomplete, {
  FormAutocompleteProps,
} from 'library/CompositeComponents/form/FormAutocomplete';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

type option = {
  label: string;
  value: number;
};

type CfStandardNameDropdownProps = Omit<FormAutocompleteProps, 'options'> & {
  onOptionsLoaded: (newOptions: option[]) => void;
};

const CfStandardNameDropdown = ({
  onOptionsLoaded,
  ...props
}: CfStandardNameDropdownProps) => {
  const [optionsLocal, setOptionsLocal] = useState<option[]>([]);
  const { onError } = useSnackbars();

  const getTypeOptions = () => {
    get('CfStandardNameService', {})
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => {
        const options = payload.map(({ cfStandardName, cfStandardNameId }) => ({
          label: cfStandardName,
          value: cfStandardNameId,
        }));
        const sortedOptions = [{ label: 'None', value: undefined }].concat(
          options.sort()
        );
        setOptionsLocal(sortedOptions);
        onOptionsLoaded(sortedOptions);
      })
      .catch(() => onError('Failed to get options'));
  };

  useEffect(getTypeOptions, [onError, onOptionsLoaded]);

  return <FormAutocomplete options={optionsLocal} {...props} />;
};

export default CfStandardNameDropdown;
