import moment, { Moment } from 'moment';
import ChatLogIngestionAnalyzer from './ChatLogIngestionAnalyzer';
import ChatLogIngestionParsingMethods, {
  ParsingMethod,
} from './ChatLogIngestionParsingMethods';
import { ChatLogMessage, ParsingErrorLine } from './ChatLogIngestionStepper';

const LINE_LIMIT = 50000;

class ChatLogIngestionParser {
  static getLineGroups = (line: string, parsingMethod: ParsingMethod) => {
    const matchResults = line.match(parsingMethod.regex);
    if (!matchResults) {
      return {};
    }
    const { groups } = matchResults;
    return groups || {};
  };

  static parseChatLog = (
    parsingMethod: string | null,
    fileContents?: string[],
    date?: Moment | null,
    diveDateFrom?: Moment | null,
    diveDateTo?: Moment | null
  ) => {
    const messageArray: ChatLogMessage[] = [];
    const errorLines: ParsingErrorLine[] = [];
    if (fileContents && fileContents.length > LINE_LIMIT) {
      errorLines.push({
        number: 0,
        line: '0',
        error: `Maximum number of lines (${LINE_LIMIT}) exceeded.  The current file has ${fileContents.length} lines`,
      });
    } else if (parsingMethod) {
      const parsingMethodObj =
        ChatLogIngestionParsingMethods.getByLabel(parsingMethod);
      if (parsingMethodObj && fileContents) {
        fileContents.forEach((line, index) => {
          const groups = ChatLogIngestionParser.getLineGroups(
            line,
            parsingMethodObj
          );
          const analyzer = new ChatLogIngestionAnalyzer(parsingMethodObj);
          const error = analyzer.areValuesSuspicious(groups, date);
          if (error) {
            errorLines.push({
              number: index,
              line,
              error,
            });
          } else if (
            analyzer.isInTimeRange(groups, date, diveDateFrom, diveDateTo)
          ) {
            messageArray.push(
              ChatLogIngestionParser.parseChatLogLine(groups, date)
            );
          }
        });
      }
    }
    return {
      chatLogMessages: messageArray,
      errorLines,
    };
  };

  static parseChatLogLine = (
    groups: { [key: string]: string },
    date?: Moment | null
  ) => {
    const { year, month, day, hour, minute, second, username, message } =
      groups;
    let msgDate: string;
    if (date && !year) {
      msgDate = date
        .set({
          hour: Number(hour),
          minute: Number(minute),
          second: Number(second),
        })
        .toISOString();
    } else {
      msgDate = moment
        .utc(`${year}-${month}-${day} ${hour}:${minute}:${second}`)
        .toISOString();
    }
    return {
      chatLogMsgId: 0,
      msg: message,
      msgDate,
      username,
    };
  };
}

export default ChatLogIngestionParser;
