// coreDateRange.ts
import { useCallback } from 'react';
import moment, { DurationInputArg1, DurationInputArg2, Moment } from 'moment';
import useInterval from 'util/hooks/useInterval';
import useWindowFocus from './useWindowFocus';

export type PresetDateRange =
  | '10m'
  | '2h'
  | '24h'
  | '7d'
  | 'custom'
  | 'dataRange';

export type DateRange = [Moment, Moment];

export type DateRangeState = {
  dateRange: DateRange;
  preset?: PresetDateRange;
};

const calculateShiftAhead = (dateRange: DateRange): DateRange => {
  const diff = dateRange[1].diff(dateRange[0], 'ms');
  const newStart = dateRange[1].clone();
  const newEnd = dateRange[1].clone().add(diff, 'ms');
  return [newStart, newEnd];
};

const calculateShiftBack = (dateRange: DateRange): DateRange => {
  const diff = dateRange[1].diff(dateRange[0], 'ms');
  const newStart = dateRange[0].clone().subtract(diff, 'ms');
  const newEnd = dateRange[0].clone();
  return [newStart, newEnd];
};

const calculateKeepLive = (dateRange: DateRange): DateRange => {
  const currentTime = moment.utc();
  const diff = dateRange[1].diff(dateRange[0], 'ms');
  const newStart = currentTime.clone().subtract(diff, 'ms');
  return [newStart, currentTime];
};

const calculatePresetRange = (
  subtractNumber: DurationInputArg1,
  subtractInterval: DurationInputArg2
): DateRange => [
  moment.utc().subtract(subtractNumber, subtractInterval),
  moment.utc(),
];

const calculateZeroedTime = (dateRange: DateRange): DateRange => {
  const newStart = dateRange[0].clone().startOf('day');
  const newEnd = dateRange[1].clone().startOf('day');
  return [newStart, newEnd];
};

export const useDateRangeCore = (
  dateRange: DateRange,
  onStateChange: (newState: DateRangeState) => void,
  presetValue: PresetDateRange = undefined,
  refreshInterval: number = 0,
  autoRefresh: boolean = false
) => {
  const setDateRange = useCallback(
    (newRange: DateRange, newPreset?: PresetDateRange) => {
      onStateChange({ dateRange: newRange, preset: newPreset });
    },
    [onStateChange]
  );

  const shiftDateRangeAhead = useCallback(() => {
    const newRange = calculateShiftAhead(dateRange);
    setDateRange(newRange, 'custom');
  }, [dateRange, setDateRange]);

  const shiftDateRangeBack = useCallback(() => {
    const newRange = calculateShiftBack(dateRange);
    setDateRange(newRange, 'custom');
  }, [dateRange, setDateRange]);

  const keeplive = useCallback(() => {
    const newRange = calculateKeepLive(dateRange);
    setDateRange(newRange, 'custom');
  }, [dateRange, setDateRange]);

  const setPresetDateRange = useCallback(
    (
      subtractNumber: DurationInputArg1,
      subtractInterval: DurationInputArg2,
      presetForRange?: PresetDateRange
    ) => {
      const newRange = calculatePresetRange(subtractNumber, subtractInterval);
      setDateRange(newRange, presetForRange);
    },
    [setDateRange]
  );

  const zeroOutTime = useCallback(() => {
    const newRange = calculateZeroedTime(dateRange);
    setDateRange(newRange, 'custom');
  }, [dateRange, setDateRange]);

  const focused = useWindowFocus(() => {
    if (autoRefresh) keeplive();
  });

  useInterval(
    () => {
      if (autoRefresh) keeplive();
    },
    autoRefresh ? refreshInterval : null,
    focused
  );

  return {
    dateRange,
    setDateRange,
    shiftDateRangeAhead,
    shiftDateRangeBack,
    keeplive,
    setPresetDateRange,
    zeroOutTime,
    preset: presetValue,
    refreshing: focused && autoRefresh,
  };
};
