import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class DeviceActionService {
  static getOne(deviceActionId) {
    return DeviceActionService.get({ operation: 4, deviceActionId });
  }

  static getMany(deviceId) {
    return DeviceActionService.get({ operation: 5, deviceId });
  }

  static getFiltered(params) {
    return DeviceActionService.get({ operation: 7, ...params });
  }

  static get(params) {
    return get('DeviceActionService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(params) {
    return post('DeviceActionService', {
      operation: 1,
      ...params,
    }).then((response) => response);
  }

  static update(params) {
    return post('DeviceActionService', {
      operation: 2,
      ...params,
    }).then((response) => response);
  }

  static delete = (params) =>
    post('DeviceActionService', {
      operation: 3,
      ...params,
    }).then((response) => response);
}
export default DeviceActionService;
