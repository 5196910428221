import { parseDmasAPIResponse, get } from 'util/WebRequest';

export type DiveDetailsJSON = {
  area: string;
  cameraId: number;
  cruiseId: number;
  cruiseName: string;
  dateFrom: string;
  dateTo: string;
  defaultDeviceId: number;
  deviceCode: string;
  deviceId: number;
  diveComment: string;
  diveId: number;
  isDiveLive: boolean;
  organizationId: number;
  organizationName: string;
  ready: boolean;
  referenceDiveId: string;
  scientistId: string;
  liveStreamUrl?: string;
};

class DiveService {
  // Simplified service call so that formatting / parsing can be done in a util file
  static getDive = (diveId: number) =>
    get('seatube/details', { diveId }).then((response) =>
      parseDmasAPIResponse(response)
    );

  // This is the old web service call we use for SeaTube
  static getDiveDetails = async (diveId, onError) => {
    try {
      const payload = await get('seatube/details', { diveId }).then(
        (response) => parseDmasAPIResponse(response)
      );
      if (payload.dateFrom) {
        payload.dateFrom = new Date(payload.dateFrom);
      }
      if (payload.dateTo) {
        payload.dateTo = new Date(payload.dateTo);
      }
      return payload;
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
    return undefined;
  };
}

export default DiveService;
