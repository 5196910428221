import { useContext, useEffect, useState } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';
import TaxonomyService from 'domain/services/TaxonomyService';
import { SaveButton } from 'library/CompositeComponents/button/Buttons';
import useWebService from 'util/hooks/useWebService';
import { TaxonomyContext } from './TaxonomyApp';
import { TaxonomyItem } from '../../Widgets/TaxonomyTreeWidget';

type Props = {
  taxonomy: TaxonomyItem;
  taxonomies: TaxonomyItem[];
  isEditEnabled: boolean;
  onError: (message: string) => void;
  onInfo: (message: string) => void;
};

export type TaxonomyFormErrors = {
  [key in keyof TaxonomyItem]?: string;
};

const TaxonomyForm = ({
  taxonomy,
  taxonomies,
  isEditEnabled,
  onError,
  onInfo,
}: Props) => {
  const { setTaxonomy, setMatrixId, clickTaxonomyItem } =
    useContext(TaxonomyContext);
  const userPrivilege = isEditEnabled ? 'RW' : 'RO';
  const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const [taxonomyValue, setTaxonomyValue] = useState<TaxonomyItem>(taxonomy);
  const [errors, setErrors] = useState<TaxonomyFormErrors>({});

  const [, , addTaxonomy] = useWebService({
    method: TaxonomyService.addTaxonomy,
    onError,
  });

  const [, , updateTaxonomy] = useWebService({
    method: TaxonomyService.updateTaxonomy,
    onError,
  });

  const handleTaxonomyChange = (key: keyof TaxonomyItem, val) => {
    setErrors((prev) => ({ ...prev, [key]: undefined }));
    setTaxonomyValue((prev) => ({ ...prev, [key]: val }));
  };

  const TaxonomyFormValidation = (value: TaxonomyItem) => {
    const err: TaxonomyFormErrors = {};
    if (value.taxonomyName.trim() === '') {
      err.taxonomyName = 'Name Required';
    }
    if (value.taxonomyCode.trim() === '') {
      err.taxonomyCode = 'Taxonomy Code Required';
    }
    if (value.version.trim() === '') {
      err.version = 'Version Required';
    }
    if (
      value.taxonomyCode.trim() !== '' &&
      taxonomies.some(
        (txy) =>
          txy.taxonomyId !== value.taxonomyId &&
          txy.taxonomyCode === value.taxonomyCode
      )
    ) {
      err.taxonomyCode =
        'This taxonomy code has already been taken. Please use another';
    }
    if (
      value.taxonomyName.trim() !== '' &&
      taxonomies.some(
        (txy) =>
          txy.taxonomyId !== value.taxonomyId &&
          txy.taxonomyName === value.taxonomyName
      )
    ) {
      err.taxonomyName =
        'This taxonomy name has already been taken. Please use another';
    }
    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formErrors = TaxonomyFormValidation(taxonomyValue);
    if (!_.isEmpty(formErrors)) {
      onError('Please fix the errors in the form');
      return setErrors(formErrors);
    }
    return taxonomy.taxonomyMatrixId
      ? updateTaxonomy(taxonomyValue.taxonomyId, taxonomyValue)
          .then((payload) => {
            setMatrixId(taxonomyValue.taxonomyMatrixId);
            setTaxonomy({
              ...taxonomyValue,
              modifiedBy: payload.modifiedBy,
              modifiedDate: payload.modifiedDate,
            });
            onInfo('Successfully updated taxonomy');
          })
          .catch((e) => onError(e.message))
      : addTaxonomy(taxonomyValue)
          .then((payload) => {
            setMatrixId(taxonomies[2].taxonomyMatrixId);
            setTaxonomy({
              ...taxonomyValue,
              taxonomyMatrixId: payload.taxonomyMatrixId,
              taxonomyId: payload.taxonomyId,
              modifiedBy: payload.modifiedBy,
              modifiedDate: payload.modifiedDate,
            });
            clickTaxonomyItem(
              payload.taxonomyMatrixId,
              {
                ...taxonomyValue,
                taxonomyMatrixId: payload.taxonomyMatrixId,
                taxonomyId: payload.taxonomyId,
                modifiedBy: payload.modifiedBy,
                modifiedDate: payload.modifiedDate,
              },
              null
            );
            onInfo(`Successfully added ${taxonomyValue.taxonomyName}`);
          })
          .catch((e) => onError(e.message));
  };

  useEffect(() => {
    setTaxonomyValue(taxonomy);
  }, [taxonomy]);

  return (
    <form
      id="taxonomy-details-form"
      className={classes.contentContainer}
      onSubmit={handleSubmit}
    >
      <Typography variant="h6">
        {/* eslint-disable-next-line no-nested-ternary */}
        {isEditEnabled
          ? taxonomy.taxonomyId !== null
            ? 'Edit Taxonomy'
            : 'Add Taxonomy'
          : 'Taxonomy'}
      </Typography>
      {taxonomy.taxonomyId !== null && (
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <RORWTextField
            translationKey="taxonomy.taxonomyId"
            value={taxonomy.taxonomyId}
            permission="RO"
          />
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          name="name"
          translationKey="common.textfields.name"
          value={taxonomyValue.taxonomyName}
          permission={userPrivilege}
          onChange={(e) => handleTaxonomyChange('taxonomyName', e.target.value)}
          required
          error={!!errors.taxonomyName}
          helperText={errors.taxonomyName}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          name="taxonomyCode"
          translationKey="taxonomy.taxonomyCode"
          value={taxonomyValue.taxonomyCode}
          permission={userPrivilege}
          onChange={(e) =>
            handleTaxonomyChange('taxonomyCode', e.target.value.toUpperCase())
          }
          required
          error={!!errors.taxonomyCode}
          helperText={errors.taxonomyCode}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          name="version"
          translationKey="taxonomy.version"
          value={taxonomyValue.version}
          permission={userPrivilege}
          onChange={(e) => handleTaxonomyChange('version', e.target.value)}
          required
          error={!!errors.version}
          helperText={errors.version}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          name="taxonomyTopUrl"
          translationKey="taxonomy.taxonomyTopUrl"
          value={taxonomyValue.taxonomyTopUrl}
          permission={userPrivilege}
          onChange={(e) =>
            handleTaxonomyChange('taxonomyTopUrl', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          name="referenceUrl"
          translationKey="taxonomy.referenceUrl"
          value={taxonomyValue.referenceUrl}
          permission={userPrivilege}
          onChange={(e) => handleTaxonomyChange('referenceUrl', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          name="description"
          translationKey="common.textfields.description"
          value={taxonomyValue.description}
          permission={userPrivilege}
          onChange={(e) => handleTaxonomyChange('description', e.target.value)}
        />
      </Grid>
      {isEditEnabled && (
        <SaveButton
          disabled={_.isEqual(taxonomyValue, taxonomy)}
          onClick={handleSubmit}
        />
      )}
    </form>
  );
};

export default TaxonomyForm;
