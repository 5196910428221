import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import SiteDeviceMaintenanceRO from 'domain/AppComponents/site-device-maintenance/SiteDeviceMaintenanceRO';
import SiteDeviceMaintenanceRW from 'domain/AppComponents/site-device-maintenance/SiteDeviceMaintenanceRW';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(2),
  },
});

const defaultState = {};

class SiteDeviceMaintenance extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
    }),
    siteDeviceId: PropTypes.number,
    siteId: PropTypes.number,
    deviceId: PropTypes.number,
    isFromSite: PropTypes.bool,
    history: PropTypes.shape().isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: undefined,
    siteDeviceId: undefined,
    deviceId: undefined,
    siteId: undefined,
    isFromSite: false,
  };

  constructor(props) {
    super(props);
    const { siteDeviceId, deviceId, siteId } = this.props;
    this.state = {
      siteDeviceId,
      deviceId,
      siteId,
      ...defaultState,
    };
  }

  render() {
    const {
      siteDeviceId,
      deviceId,
      siteId,
      isFromSite,
      onInfo,
      onError,
      history,
    } = this.props;

    if (Environment.getDmasUserPrivilege() === 'RW') {
      return (
        <SiteDeviceMaintenanceRW
          siteId={siteId}
          siteDeviceId={siteDeviceId}
          deviceId={deviceId}
          isFromSite={isFromSite}
          history={history}
          onInfo={onInfo}
          onError={onError}
        />
      );
    }

    return (
      <SiteDeviceMaintenanceRO
        siteId={siteId}
        siteDeviceId={siteDeviceId}
        deviceId={deviceId}
        isFromSite={isFromSite}
        history={history}
        onError={onError}
      />
    );
  }
}
export default withStyles(STYLES)(withSnackbars(SiteDeviceMaintenance));
