import { useContext, useState } from 'react';
import EarthquakeDetailLink from 'domain/AppComponents/earthquake-catalog/EarthquakeDetailLink';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import StatelessTable from 'library/BaseComponents/table/StatelessTable';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import DateFormatUtils from 'util/DateFormatUtils';
import useBroadcast from 'util/hooks/useBroadcast';
import { useLocalStorage } from 'util/hooks/useStorage';
import EarthquakeContext from './EarthquakeContext';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../library/CompositeComponents/dashboard/DashboardTypes';

const headers = [
  { title: 'ID', name: 'earthquakeId' },
  { title: 'Origin Time', name: 'originTime' },
  { title: 'Epicentre', name: 'epicentre' },
  { title: 'Magnitude', name: 'magnitude' },
  { title: 'ONC Detected', name: 'oncDetected' },
  { title: 'NRcan Detected', name: 'nrcanDetected' },
  { title: 'USGS Detected', name: 'usgsDetected' },
];

const EarthquakeTableWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;
  const { earthquakes, dashboardId } = useContext(EarthquakeContext);

  const [earthquakeList] = useBroadcast<any>(
    dashboardId,
    BroadcastChannel.EarthquakeList,
    earthquakes,
    id
  );

  const storageKey = 'earthquake-catalog-table';

  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'id', direction: 'asc' }],
    hiddenColumnNames: [],
  };
  // Column Sorting
  const [config] = useLocalStorage<any>(storageKey, defaultConfig);
  const [sorting, setSorting] = useState(config.sorting);

  const renderTableRows = () =>
    earthquakeList.map((row) => ({
      id: row.earthquakeId,
      earthquakeId: (
        <EarthquakeDetailLink earthquakeId={row.earthquakeId}>
          {row.earthquakeId}
        </EarthquakeDetailLink>
      ),
      originTime: DateFormatUtils.formatDate(row.originTime, 'full'),
      epicentre: `${row.latitude},${row.longitude}`,
      magnitude: row.magnitude,
      oncDetected: `${row.oncDetected}`,
      nrcanDetected: `${row.nrcanDetected}`,
      usgsDetected: `${row.usgsDetected}`,
    }));

  return (
    <Widget key={id} title="Earthquake Table" {...props}>
      <StatelessTable
        rows={renderTableRows()}
        columns={headers}
        virtual={{ virtualized: true }}
        sort={{
          sorting,
          handleSortingChange: setSorting,
        }}
        visible={{ hiddenColumnNames: [] }}
      />
    </Widget>
  );
};

EarthquakeTableWidget.widgetKey = 'earthquake-table';
EarthquakeTableWidget.widgetTitle = 'Earthquake Table';
EarthquakeTableWidget.defaultDataGrid = {
  i: 'earthquake-table',
  x: 0,
  y: 0,
  w: 6,
  h: 5,
};

export default EarthquakeTableWidget;
