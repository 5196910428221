import { memo } from 'react';
import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import ParsingOptionsDetailsTemplate from 'domain/Apps/seatube/details/ParsingOptionsDetailsTemplate';
import StepHeader from 'library/CompositeComponents/stepper/step-parts/StepHeader';
import StepPartRadioGroup, {
  RadioOption,
} from 'library/CompositeComponents/stepper/step-parts/StepPartRadioGroup';
import StepTextDisplayer from 'library/CompositeComponents/stepper/step-parts/StepPartTextDisplayer';
import ChatLogParsingOptionLabel from '../step-content/ChatLogParsingOptionLabel';

const styles = () =>
  createStyles({
    stepDiv: {
      margin: '25px 10px',
    },
    textDisplay: {
      width: '85%',
      'margin-left': '10px',
    },
  });

interface Props extends WithStyles<typeof styles> {
  fileSample: string;
  onRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | null;
}

const ChatLogParsingMethodSelectionStep: React.VFC<Props> = (props: Props) => {
  const {
    fileSample,
    onRadioChange,
    value,
    classes, // comes from WithStyles
  } = props;

  const radioOptions: RadioOption[] = [
    {
      value: '[hh:mm:ss] <username> message',
      label: (
        <ChatLogParsingOptionLabel
          option="[hh:mm:ss] <username> message"
          descriptionTitle="[hh:mm:ss] <username> message"
          description={
            <ParsingOptionsDetailsTemplate
              parsingOption="[hh:mm:ss] <username> message"
              example="[01:23:45] <user1> hello world"
              systemExample="[01:23:45] user2 joins the room"
              additionalInfo={
                'For a system message, the lack of angle brackets means there is no username. ' +
                'In the given system mesage example above, there is no username and the message is "user2 joins the room".'
              }
            />
          }
        />
      ),
    },
    {
      value: 'MM/dd/yyyy,hh:mm:ss,username,message',
      label: (
        <ChatLogParsingOptionLabel
          option="MM/dd/yyyy,hh:mm:ss,username,message"
          descriptionTitle="MM/dd/yyyy,hh:mm:ss,username,message"
          description={
            <ParsingOptionsDetailsTemplate
              parsingOption="MM/dd/yyyy,hh:mm:ss,username,message"
              example="02/28/2021,01:23:45,user1,hello world"
              additionalInfo="The chat log format that this parsing option is intended for does not have system messages."
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <div className={classes.stepDiv}>
        <StepHeader
          header="Select Parsing Method"
          description="Select the parsing method that best matches the chat log. Here are the some of the lines:"
        />
      </div>
      <div className={(classes.stepDiv, classes.textDisplay)}>
        <StepTextDisplayer
          value={fileSample}
          textAreaStyle={{ width: '100%' }} // 100% of the containing div; make changes to div's width
          maxRows={10}
          minRows={10}
          wrap="soft"
        />
      </div>
      <div className={classes.stepDiv}>
        <StepPartRadioGroup
          value={value}
          onChange={onRadioChange}
          radioOptions={radioOptions}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(memo(ChatLogParsingMethodSelectionStep));
