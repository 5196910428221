import {
  Autocomplete,
  Grid,
  LabelledCheckbox,
  TextField,
} from 'base-components';
import SearchTreeNodeChipSelect from 'domain/AppComponents/seatube/search/SearchTreeNodeChipSelect';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import Filter, { FilterEvent } from 'library/CompositeComponents/filter/Filter';

import CruiseChipSelect from '../../search/CruiseChipSelect';
import LogChipSelect from '../../search/LogChipSelect';

type Props = {
  initialFilterValues?: {
    cruiseIds: number[];
    diveIds: number[];
    searchTreeNodeIds?: number[];
    comment: string;
  };
  value: any;
  creatorOptions: object[];
  modifierOptions: object[];
  name: string;
  context: 'search' | 'list';
  tabValue?: number;
  onChange: (event: FilterEvent) => void;
  onDiveOptionsLoaded: (e: object) => void;
};

const GeneralFilter = ({
  initialFilterValues = { cruiseIds: [], diveIds: [], comment: '' },
  value,
  creatorOptions,
  modifierOptions,
  name,
  context,
  tabValue = undefined,
  onChange,
  onDiveOptionsLoaded,
}: Props) => {
  const renderChipSelect = () => {
    if (tabValue && tabValue === SeaTubeResourceTypes.SEARCH_TREE_NODE) {
      return (
        <Grid item xs={12}>
          <SearchTreeNodeChipSelect
            name="searchTreeNodeIds"
            id="searchTreeNodeIds"
            value={value.searchTreeNodeIds || []}
          />
        </Grid>
      );
    }
    return (
      <>
        <Grid item xs={12}>
          <CruiseChipSelect
            name="cruises"
            id="cruises"
            preselectedCruises={
              initialFilterValues ? initialFilterValues.cruiseIds : []
            }
            value={value.cruises || []}
          />
        </Grid>
        <Grid item xs={12}>
          <LogChipSelect
            name="logs"
            id="logs"
            cruiseIds={value.cruises?.map((option) => option.value) || []}
            onDiveOptionsLoaded={onDiveOptionsLoaded}
          />
        </Grid>
      </>
    );
  };

  const renderFixedCameraAnnotationCheckboxes = () => {
    if (tabValue && tabValue === SeaTubeResourceTypes.SEARCH_TREE_NODE) {
      return (
        <>
          <LabelledCheckbox label="Public" name="public" value={value.public} />
          <LabelledCheckbox
            label="Unlisted"
            name="unlisted"
            value={value.unlisted}
          />
        </>
      );
    }
    return undefined;
  };

  return (
    <Filter title="General" onChange={onChange} name={name} value={value}>
      <Grid container spacing={1}>
        {context === 'search' ? renderChipSelect() : undefined}
        <Grid item xs={12}>
          <TextField
            value=""
            fullWidth
            name="comment"
            translationKey="common.textfields.comment"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            translationKey="seatube.creators"
            fullWidth
            name="creators"
            multiple
            options={creatorOptions}
            getOptionLabel={(option: any) => option.label}
            isOptionEqualToValue={(option: any, val: any) =>
              option.value === val.value
            }
            value={value.creators ? value.creators : []}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            translationKey="seatube.modifiers"
            fullWidth
            name="modifiers"
            multiple
            options={modifierOptions}
            getOptionLabel={(option: any) => option.label}
            isOptionEqualToValue={(option: any, val: any) =>
              option.value === val.value
            }
            value={value.modifiers ? value.modifiers : []}
          />
        </Grid>
        <Grid item xs={12}>
          <LabelledCheckbox
            label="To Be Reviewed"
            name="toBeReviewed"
            value={value.toBeReviewed}
          />
          {renderFixedCameraAnnotationCheckboxes()}
        </Grid>
      </Grid>
    </Filter>
  );
};

export default GeneralFilter;
