import { useState } from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Draggable from 'react-draggable';
import { Close, ExpandMore } from '@onc/icons';
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
} from 'base-components';
import ADCPToolbox from 'domain/Apps/data-player/ADCPToolbox';
import HydrophoneToolboxForm from './HydrophoneToolboxForm';
import {
  HYDROPHONE_DEVICE_CATEGORY_CODE,
  ADCP_DEVICE_CATEGORY_CODES,
} from './util/DataPlayerConstants';

const styles = (theme) => ({
  root: {
    width: 'auto',
    position: 'absolute',
    padding: theme.spacing(),
    top: '10%',
    left: '70%',
    zIndex: 3,
  },
  header: {
    height: '45px',
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    padding: '5px',
    margin: 0,
    cursor: 'pointer',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    marginTop: theme.spacing(),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    marginTop: theme.spacing(),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  title: {
    userSelect: 'none',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    marginBottom: 'auto',
    marginLeft: theme.spacing(1),
  },
  closeButton: {
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(1),
  },
});

const Toolbox = ({
  classes,
  onCloseClick = undefined,
  deviceCategoryCode,
  deviceId = undefined,
  onADCPFilterSubmit = undefined,
  onHydrophoneFilterSubmit,
  resourceId = undefined,
  adcpNortekPresetOptions = undefined,
  adcpRdiPresetOptions = undefined,
  hydrophonePresetOptions = undefined,
  handleWidgetToolboxClose = undefined,
  isDashboard = undefined,
}) => {
  Toolbox.propTypes = {
    classes: PropTypes.shape({
      closeButton: PropTypes.string,
      expand: PropTypes.string,
      expandOpen: PropTypes.string,
      header: PropTypes.string,
      root: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    handleWidgetToolboxClose: PropTypes.func,
    onCloseClick: PropTypes.func,
    deviceCategoryCode: PropTypes.string.isRequired,
    deviceId: PropTypes.number,
    onADCPFilterSubmit: PropTypes.func,
    onHydrophoneFilterSubmit: PropTypes.func.isRequired,
    resourceId: PropTypes.number,
    isDashboard: PropTypes.bool,
    adcpNortekPresetOptions: PropTypes.shape({
      velocityBinMapping: PropTypes.string,
      horizontalCurrentPlotLimits: PropTypes.string,
      verticalCurrentPlotLimits: PropTypes.string,
      backscatterColourMap: PropTypes.string,
      backscatterLowerPlotLimits: PropTypes.string,
      backscatterUpperPlotLimits: PropTypes.string,
    }),
    adcpRdiPresetOptions: PropTypes.shape({
      velocityBinMapping: PropTypes.string,
      horizontalCurrentPlotLimits: PropTypes.string,
      verticalCurrentPlotLimits: PropTypes.string,
      threeBeamSolution: PropTypes.string,
      lowCorrelationScreenThreshold: PropTypes.string,
      errCorrelationScreenThreshold: PropTypes.string,
      falseTargetMaxThreshold: PropTypes.string,
      backscatterColourMap: PropTypes.string,
      backscatterLowerPlotLimits: PropTypes.string,
      backscatterUpperPlotLimits: PropTypes.string,
    }),
    hydrophonePresetOptions: PropTypes.shape({
      colourPalette: PropTypes.string,
      upperColourLimit: PropTypes.string,
      lowerColourLimit: PropTypes.string,
      spectrogramSource: PropTypes.string,
      hydrophoneDataAcquisition: PropTypes.string,
      hydrophoneDataDiversion: PropTypes.string,
      hydrophoneChannel: PropTypes.string,
      spectrogramFrequencyUpperLimit: PropTypes.number,
    }),
  };

  const [expanded, setExpanded] = useState(true);
  const handleExpanded = () => setExpanded(!expanded);
  const renderToolBox = () => {
    if (ADCP_DEVICE_CATEGORY_CODES.includes(deviceCategoryCode)) {
      return (
        <ADCPToolbox
          onADCPFilterSubmit={onADCPFilterSubmit}
          resourceId={resourceId}
          adcpNortekPresetOptions={adcpNortekPresetOptions}
          adcpRdiPresetOptions={adcpRdiPresetOptions}
          deviceId={deviceId}
        />
      );
    }
    if (deviceCategoryCode === HYDROPHONE_DEVICE_CATEGORY_CODE) {
      return (
        <HydrophoneToolboxForm
          resourceId={resourceId}
          onSpectroSubmit={onHydrophoneFilterSubmit}
          hydrophonePresetOptions={hydrophonePresetOptions}
        />
      );
    }
    return null;
  };

  const changeToolboxHeader = () => {
    if (deviceCategoryCode === HYDROPHONE_DEVICE_CATEGORY_CODE) {
      return 'Hydrophone';
    }
    if (ADCP_DEVICE_CATEGORY_CODES.includes(deviceCategoryCode)) {
      return 'ADCP';
    }
    return undefined;
  };

  return (
    <Draggable bounds="body" handle="#toolbox-handle">
      <div className={classes.root} title="toolboxDrag">
        <Card>
          <CardContent className={classes.header} id="toolbox-handle">
            <CardActions disableSpacing>
              <Typography
                title="header"
                className={classes.title}
                variant="h6"
                color="inherit"
              >
                {`${changeToolboxHeader()} Toolbox`}
              </Typography>

              <IconButton
                className={expanded ? classes.expandOpen : classes.expand}
                id="expand-button"
                onClick={handleExpanded}
                aria-expanded={expanded}
                size="small"
                color="inherit"
              >
                <ExpandMore fontSize="small" />
              </IconButton>
              <IconButton
                className={classes.closeButton}
                onClick={isDashboard ? handleWidgetToolboxClose : onCloseClick}
                aria-expanded={expanded}
                size="small"
                color="inherit"
              >
                <Close fontSize="small" />
              </IconButton>
            </CardActions>
          </CardContent>
          <Collapse in={expanded} timeout="auto">
            <CardContent>{renderToolBox()}</CardContent>
          </Collapse>
        </Card>
      </div>
    </Draggable>
  );
};

export default withStyles(styles)(Toolbox);
