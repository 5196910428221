import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  INSTRUMENT,
  OBSERVATORY,
  REGION,
  STATION,
  STUDY_AREA,
  DEVICE_AND_SENSOR,
} from 'domain/services/QaqcAutotestsConstants';
import QaqcAutotestsService from 'domain/services/QaqcAutotestsService';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import QaqcFinderDropdowns from './QaqcFinderDropdowns';
import QaqcFinderTable from './QaqcFinderTable';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
});

const defaultState = {
  qaqcTests: [],
  searchActive: false,
  isLoading: false,
};

class QaqcFinderPage extends PureComponent {
  static propTypes = {
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  handleClear = () => this.setState({ ...defaultState });

  handleFind = async (testLevel, idValues) => {
    const {
      qaqcTestGroupId,
      searchTreeNodeId,
      sensorId,
      sensorTypeId,
      studyAreaId,
      deviceAutocomplete,
      sensorIds,
      activeOnly,
    } = idValues;
    this.setState({ isLoading: true });

    switch (testLevel) {
      case DEVICE_AND_SENSOR:
        this.handleSensorDeviceGet(
          deviceAutocomplete == null ? '' : deviceAutocomplete.deviceId,
          sensorIds,
          activeOnly
        );
        break;
      case INSTRUMENT:
        await this.handleGet(
          QaqcAutotestsService.getByInstrument(
            sensorId,
            sensorTypeId,
            activeOnly
          )
        );
        break;
      case OBSERVATORY:
        await this.handleGet(
          QaqcAutotestsService.getByObservatory(
            qaqcTestGroupId,
            sensorTypeId,
            activeOnly
          )
        );
        break;
      case REGION:
        await this.handleGet(
          QaqcAutotestsService.getByRegion(
            qaqcTestGroupId,
            sensorTypeId,
            activeOnly
          )
        );
        break;
      case STATION:
        await this.handleGet(
          QaqcAutotestsService.getByStation(
            searchTreeNodeId,
            sensorTypeId,
            activeOnly
          )
        );
        break;
      case STUDY_AREA:
        await this.handleGet(
          QaqcAutotestsService.getByStudyArea(
            studyAreaId,
            sensorTypeId,
            activeOnly
          )
        );
        break;
      default:
        await this.handleGet(QaqcAutotestsService.getByActiveOnly(activeOnly));
    }
  };

  handleSensorDeviceGet = async (deviceId, sensorIds, activeOnly) => {
    const { onInfo, onError } = this.props;
    await QaqcAutotestsService.getQAQCBySensorIdsDeviceIds(
      deviceId,
      sensorIds,
      activeOnly
    )
      .then((response) => {
        this.setState({
          searchActive: true,
          qaqcTests: response.data.payload,
          isLoading: false,
        });
        if (response.data.statusCode === 0) {
          onInfo('Search Complete');
        } else if (response.data.statusCode === 9001) {
          onError(response.data.message);
        } else {
          onError(
            `Failed to search with error code: ${response.data.statusCode}`
          );
        }
      })
      .catch((response) => {
        onError(response.message);
        this.setState({ isLoading: false });
      });
  };

  handleGet = (promise) => {
    const { onInfo, onError } = this.props;
    promise
      .then((result) => {
        onInfo('Search Complete');
        this.setState({
          searchActive: true,
          qaqcTests: result,
          isLoading: false,
        });
      })
      .catch((result) => {
        onError(result.message);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { classes, onInfo, onError } = this.props;
    const { qaqcTests, isLoading } = this.state;

    return (
      <div>
        <Panel classes={classes}>
          <QaqcFinderDropdowns
            onClear={this.handleClear}
            onFind={this.handleFind}
            onInfo={onInfo}
            onError={onError}
          />
        </Panel>
        <Panel classes={classes}>
          <QaqcFinderTable
            qaqcTests={qaqcTests}
            stripedRows
            isLoading={isLoading}
          />
        </Panel>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(QaqcFinderPage));
