import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Checkbox,
  DateTimePicker,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from 'base-components';
import ActionDropdown from 'domain/AppComponents/dropdowns/ActionDropdown';
import DeviceCategorySelect from 'domain/AppComponents/dropdowns/DeviceCategorySelect';
import DeviceDropdown from 'domain/AppComponents/dropdowns/DeviceDropdown';
import { TextButton } from 'library/CompositeComponents/button/Buttons';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  dropdown: {
    marginBottom: theme.spacing(2),
  },
  abcd: {
    margin: theme.spacing(2),
  },
  checkboxes: {
    paddingLeft: theme.spacing(3),
  },
});

const defaultState = {
  searchActive: false,
  values: {
    deviceCategorySel: -1,
    deviceId: -1,
    selectedAction: -1,
    dateFrom: new Date(2005, 11, 31, 16),
    dateTo: new Date(),
    Maintenance: true,
    Repair: true,
    Status: true,
    Cruise: true,
    Latest: true,
  },
};

class DeviceActionsParameters extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onError: PropTypes.func.isRequired,
    onFind: PropTypes.func,
  };

  static defaultProps = {
    onFind: () => {},
  };

  constructor(props) {
    super(props);
    const tempState = defaultState;
    tempState.values['Non-Cruise'] = true;
    const { dateTo } = defaultState.values;
    const date = new Date();
    dateTo.setFullYear(date.getFullYear() + 2);
    this.state = {
      ...tempState,
      dateTo,
    };
  }

  handleChange = (event) => {
    const { values } = this.state;
    this.setState({
      values: { ...values, [event.target.name]: event.target.value },
    });

    if (event.target.name === 'deviceCategorySel') {
      this.setState({ deviceId: -1 });
    }
  };

  handleCheckboxChange = (event, value) => {
    const { values } = this.state;
    this.setState({
      values: { ...values, [event.target.name]: value },
    });
  };

  handleStartDateChange = (value) => {
    const { values } = this.state;
    this.setState({
      values: {
        ...values,
        dateFrom: value.toDate(),
      },
    });
  };

  handleEndDateChange = (value) => {
    const { values } = this.state;
    this.setState({
      values: {
        ...values,
        dateTo: value.toDate(),
      },
    });
  };

  render() {
    const { classes, onFind, onError } = this.props;
    const { values } = this.state;

    return (
      <>
        <Grid container direction="row">
          <Grid item lg={4} md={6} sm={12}>
            <div className={classes.abcd}>
              <DeviceCategorySelect
                fullWidth
                onError={() => {}}
                value={values.deviceCategorySel}
                name="deviceCategorySel"
                prependOption={[{ label: 'All', value: -1, key: -1 }]}
                onChange={this.handleChange}
                className={classes.dropdown}
              />
              <DeviceDropdown
                fullWidth
                value={values.deviceId}
                deviceCategoryId={values.deviceCategorySel}
                prependOptions={[{ label: 'All', value: -1, key: -1 }]}
                onChange={this.handleChange}
                name="deviceId"
                className={classes.dropdown}
                onError={onError}
              />
            </div>
            <div className={classes.checkboxes}>
              <FormControl>
                <FormLabel>Action Category</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={this.handleCheckboxChange}
                        name="Repair"
                        checked={values.Repair}
                      />
                    }
                    label="Repair"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={this.handleCheckboxChange}
                        name="Status"
                        checked={values.Status}
                      />
                    }
                    label="Status"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={this.handleCheckboxChange}
                        name="Maintenance"
                        checked={values.Maintenance}
                      />
                    }
                    label="Maintenance"
                    labelPlacement="start"
                  />
                </FormGroup>
                <FormGroup row className={classes.dropdown}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={this.handleCheckboxChange}
                        name="Cruise"
                        checked={values.Cruise}
                      />
                    }
                    label="Cruise"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={this.handleCheckboxChange}
                        name="Non-Cruise"
                        checked={values['Non-Cruise']}
                      />
                    }
                    label="Non-Cruise"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div className={classes.abcd}>
              <ActionDropdown
                fullWidth
                onChange={this.handleChange}
                name="selectedAction"
                title="Action"
                value={values.selectedAction}
                className={classes.dropdown}
                prependOptions={[{ label: 'All', value: -1, key: -1 }]}
              />
              <DateTimePicker
                translationKey="common.datepickers.startDate"
                value={moment.utc(values.dateFrom)}
                onChange={this.handleStartDateChange}
                className={classes.dropdown}
                fullWidth
              />
              <DateTimePicker
                translationKey="common.datepickers.endDate"
                value={moment.utc(values.dateTo)}
                onChange={this.handleEndDateChange}
                className={classes.dropdown}
                fullWidth
              />
            </div>
          </Grid>
        </Grid>

        <div className={classes.dropdown}>
          <TextButton
            translationKey="common.buttons.find"
            onClick={() => {
              onFind(values);
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.Latest}
                onChange={this.handleCheckboxChange}
                name="Latest"
              />
            }
            label="Last Entry Only"
            labelPlacement="start"
          />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(DeviceActionsParameters);
