import * as React from 'react';
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';

export interface ResizingProps {
  columnWidths: TableColumnWidthInfo[];
  handleColumnWidths?: (nextColumnWidths: TableColumnWidthInfo[]) => void;
  columnExtensions?: TableColumnResizing.ColumnExtension[];
  resizingMode?: string;
}

const ColumnResizing: React.VFC<ResizingProps> = ({
  columnWidths,
  handleColumnWidths = undefined,
  columnExtensions = undefined,
  resizingMode = undefined,
}) => {
  if (handleColumnWidths) {
    return (
      <TableColumnResizing
        defaultColumnWidths={columnWidths}
        columnWidths={columnWidths}
        columnExtensions={columnExtensions}
        onColumnWidthsChange={(newWidths) => {
          handleColumnWidths(newWidths);
        }}
        resizingMode={resizingMode}
      />
    );
  }
  return <TableColumnResizing defaultColumnWidths={columnWidths} />;
};
export default ColumnResizing;
