import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';

import DeviceWebService from 'domain/services/DeviceWebService';

class DeviceSelect extends Component {
  static propTypes = {
    deviceCategoryCode: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onError: PropTypes.func,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
  };

  static defaultProps = {
    name: undefined,
    title: undefined,
    onBlur: undefined,
    onChange: undefined,
    helperText: undefined,
    error: undefined,
    className: undefined,
    value: undefined,
    initialValue: undefined,
    onError: undefined,
    disabled: false,
    deviceCategoryCode: undefined,
    fullWidth: false,
  };

  constructor(props) {
    super(props);
    this.state = { options: [] };
  }

  componentDidMount() {
    const { deviceCategoryCode } = this.props;
    if (deviceCategoryCode) this.getDeviceOptions(deviceCategoryCode);
  }

  shouldComponentUpdate(nextProps) {
    const { deviceCategoryCode } = this.props;
    if (nextProps.deviceCategoryCode !== deviceCategoryCode) {
      this.getDeviceOptions(nextProps.deviceCategoryCode);
    }
    return true;
  }

  getDeviceOptions = async (deviceCategoryCode) => {
    const { onError } = this.props;
    try {
      const response = await DeviceWebService.get({
        deviceCategoryCode,
        method: 'get',
      });
      if (response.data) {
        const options = response.data.map((option) => ({
          label: `${option.deviceName} (${option.deviceId})`,
          value: option.deviceId,
        }));
        this.setState({ options });
      }
    } catch (error) {
      onError(error);
    }
  };

  render() {
    const {
      name,
      title,
      onBlur,
      onChange,
      helperText,
      error,
      className,
      value,
      initialValue,
      disabled,
      fullWidth,
    } = this.props;
    const { options } = this.state;
    return (
      <Dropdown
        options={options}
        name={name}
        title={title}
        onBlur={onBlur}
        onChange={onChange}
        helperText={helperText}
        error={error}
        className={className}
        label="Device"
        value={value}
        initialValue={initialValue}
        disabled={disabled}
        fullWidth={fullWidth}
      />
    );
  }
}

export default DeviceSelect;
