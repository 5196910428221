import { useEffect, useState } from 'react';
import SensorType from 'domain/AppComponents/sensor-type/SensorType';
import SensorTypeDialog from 'domain/AppComponents/sensor-type/SensorTypeDialog';
import SensorTypeService from 'domain/services/SensorTypeService';
import UnitOfMeasureService from 'domain/services/UnitOfMeasureService';
import Environment from 'util/Environment';
import SnackbarProvider, { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import SensorTypeTable from '../../AppComponents/sensor-type/SensorTypeTable';
import {
  SensorTypeRow,
  transformRowToSyncForm,
  transformSensorTypeRows,
} from '../../AppComponents/sensor-type/SensorTypeTableLogic';

const SensorTypePage = () => {
  const { onError } = useSnackbars();
  const [sensorTypes, , fetchSensorTypes] = useWebService({
    method: SensorTypeService.getAllSensorTypes,
    onError,
    parser: transformSensorTypeRows,
  });
  const [unitOfMeasures, , fetchUnitOfMeasures] = useWebService({
    method: UnitOfMeasureService.getAll,
  });

  const permission = Environment.getDmasUserPrivilege();
  const [isSensorTypeDialogOpen, setSensorTypeDialog] = useState(false);
  const [initialSensorType, setInitialSensorType] =
    useState<SensorType>(undefined);

  const handleEditClick = (row: SensorTypeRow) => {
    setInitialSensorType(transformRowToSyncForm(row));
    setSensorTypeDialog(true);
  };

  const handleCreateClick = () => {
    setInitialSensorType(undefined);
    setSensorTypeDialog(true);
  };

  const handleCancelClick = () => {
    setSensorTypeDialog(false);
    setInitialSensorType(undefined);
  };

  const handleSuccess = () => {
    fetchSensorTypes();
  };

  useEffect(() => {
    fetchSensorTypes();
    fetchUnitOfMeasures();
  }, [fetchSensorTypes, fetchUnitOfMeasures]);

  return (
    <>
      <SnackbarProvider>
        <SensorTypeTable
          rows={sensorTypes || []}
          permission={permission}
          onEdit={handleEditClick}
          onCreate={handleCreateClick}
        />
      </SnackbarProvider>
      <SensorTypeDialog
        unitOfMeasures={unitOfMeasures}
        open={isSensorTypeDialogOpen}
        onCancel={handleCancelClick}
        initialValue={initialSensorType}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export default SensorTypePage;
