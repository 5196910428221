import { useState, useCallback } from 'react';
import { useSnackbars } from './useSnackbars';

// Define the types for the parameters

/**
 * Type for a generic service method that returns a Promise with the result.
 *
 * @template T The type of the result.
 * @template A The type of the arguments array.
 */
type ServiceMethod<T, A extends any[]> = (...args: A) => Promise<T>;

/**
 * Type for a parser function that converts data of type T to type U.
 *
 * @template T The input type.
 * @template U The output type.
 */
type Parser<T, U> = (data: T) => U;

/** Type for an error handling function that accepts an error message string. */
type HandleError = (errorMessage: string) => void;

/** The configuration object for the useWebService hook. */
interface UseWebServiceConfig<T, U = T, A extends any[] = any[]> {
  method: ServiceMethod<T, A>;
  parser?: Parser<T, U>;
  onError?: HandleError;
}

/**
 * The return type of the useWebService hook. It's a tuple where:
 *
 * - The first item is the parsed result or null if not yet received,
 * - The second item is a boolean indicating the loading state,
 * - The third item is the fetch function to call the service method.
 */
type UseWebServiceReturnType<T, A extends any[]> = [
  T | null,
  boolean,
  (...args: A) => Promise<T>,
];

/**
 * React Hook to call a web service, track its loading state, and store its
 * result.
 *
 * @template T The type of the data returned from the service method.
 * @template U The type of the data after parsing. Defaults to T if not
 *   provided.
 * @template A The type of the arguments array passed to the service method.
 *   Defaults to any[].
 * @param {UseWebServiceConfig} config The configuration object for the hook.
 * @returns {UseWebServiceReturnType} The result, the loading state, and the
 *   fetch function.
 */
function useWebService<T, U = T, A extends any[] = any[]>({
  method,
  parser,
  onError: handleError,
}: UseWebServiceConfig<T, U, A>): UseWebServiceReturnType<U, A> {
  // State to hold the result
  const [result, setResult] = useState<U | null | undefined>(undefined);
  // State to track if a request is loading
  const [loading, setLoading] = useState(false);
  // Default onError handler using Snackbar for UI feedback
  const { onError } = useSnackbars();

  // Fetch function
  const fetch = useCallback(
    async (...args: A) => {
      // Indicate that the request has started
      setLoading(true);
      try {
        // Make the service call
        const serviceResult = await method(...args);
        // Parse the result if a parser is provided, else use the result as is
        const finalResult = parser ? parser(serviceResult) : serviceResult;
        // Store the result in the state
        setResult(finalResult as U);
        return finalResult as U;
      } catch (error: any) {
        setResult(null);
        // If a custom error handler is provided, use it; otherwise, fall back to the default one
        const errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          'An error occurred';
        if (handleError) {
          handleError(errorMessage);
        } else {
          onError(errorMessage);
        }
        return null;
      } finally {
        // Indicate that the request has finished
        setLoading(false);
      }
    },
    // The fetch function depends on these variables
    [method, parser, handleError, onError]
  );

  // Return result, loading state, and the fetch function as an array
  return [result, loading, fetch];
}

export default useWebService;
