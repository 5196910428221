import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import DF_LOGO from 'assets/images/df-banner-white.png';

const styles = (theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  img: {
    width: 250,
    height: 'auto',
  },
});

class DFLogo extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string,
      img: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <img src={DF_LOGO} alt="digital fishers logo" className={classes.img} />
      </div>
    );
  }
}

export default withStyles(styles)(DFLogo);
