import { Info } from '@onc/icons';
import { IconButton } from 'base-components';
import DateUtils from 'util/DateUtils';
import { useBatchDialogsContext } from '../hooks/BatchTaskStateHooks';
import type { Task } from '../types/Task';

const BatchTaskInfoTooltipAction = ({
  input = {},
  additionalInfo = {},
  dateQueued = '',
}: {
  input?: any;
  additionalInfo?: Task['additionalInfo'];
  dateQueued?: string;
}) => {
  const { setBatchTaskInfo } = useBatchDialogsContext();
  return (
    <IconButton
      onClick={() => {
        setBatchTaskInfo?.({
          ...additionalInfo,
          ...input,
          dateQueued: DateUtils.formatISOString(dateQueued),
        });
      }}
      aria-label="Task Information"
    >
      <Info />
    </IconButton>
  );
};

export default BatchTaskInfoTooltipAction;
