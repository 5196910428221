import { memo } from 'react';
import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from 'base-components';
import IngestionCard from 'domain/AppComponents/seatube/ingestion-landing-page/IngestionCard';
import CardGrid from 'library/CompositeComponents/card-grid/CardGrid';
import Environment from 'util/Environment';

const styles = () =>
  createStyles({
    container: {
      margin: 0,
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '600px',
    },
  });

interface IngestionDialogProps extends WithStyles<typeof styles> {}

const IngestionDialog: React.VFC<IngestionDialogProps> = (
  props: IngestionDialogProps
) => {
  const { classes } = props;
  return (
    <Paper className={classes.container} elevation={5}>
      <DialogTitle>SeaTube Ingestion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the type of ancillary text to be ingested:
        </DialogContentText>
        <CardGrid placeholder={<IngestionCard />}>
          <IngestionCard
            url={`${Environment.getLinkUrl()}/ChatLogIngestion`}
            title="Chat Log Ingestion"
            description="A chat log from a specific dive, provided as a plain text file."
          />
        </CardGrid>
      </DialogContent>
      <DialogActions />
    </Paper>
  );
};

export default withStyles(styles)(memo(IngestionDialog));
