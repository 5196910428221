import { Theme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { Fullscreen, Settings } from '@onc/icons';
import { DenseThemeProvider, oceans3ThemeDark } from '@onc/theme';
import { AnchoredHiddenMenu, IconButton, Typography } from 'base-components';

const useStyles = makeStyles((theme: Theme) => ({
  panelContainer: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    color: theme.palette.getContrastText('rgba(0,0,0,0.7)'),
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0,0,0,1), rgba(0,0,0,0.7), rgba(0,0,0,0))',
    height: '100%',
    width: '100%',
  },

  seekbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  spacer: {
    marginLeft: 'auto',
  },

  customizableControls: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },

  controlItem: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    color: theme.palette.getContrastText('rgba(0,0,0,0.7)'),
  },

  moreIcon: {
    color: theme.palette.getContrastText('rgba(0,0,0,0.7)'),
  },

  controlItemWithSlider: {
    marginLeft: theme.spacing(2),
  },

  leftControlsContainer: {
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.3s ease',
  },
}));

type ControlProps = {
  leftControls?: JSX.Element[];
  SeekbarComponent: JSX.Element;
  startDate: Moment;
  timestamp: Moment;
  onFullscreen: (e: React.MouseEvent<HTMLButtonElement>) => void;
  endDate: Moment;
  moreControls?: JSX.Element[];
  rightControls?: JSX.Element[];
  containerRef?: HTMLDivElement;
};

const ControlPanel = ({
  SeekbarComponent,
  leftControls = [],
  rightControls = [],
  moreControls = [],
  timestamp,
  startDate,
  onFullscreen,
  endDate,
  containerRef = undefined,
}: ControlProps) => {
  const classes = useStyles();

  const renderMoreControls = () => {
    if (moreControls.length) {
      return (
        <div className={classes.controlItem}>
          <AnchoredHiddenMenu
            aria-label="More"
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            container={document.fullscreenElement ? containerRef : undefined}
            icon={Settings}
            tooltipPosition="top"
          >
            {moreControls}
          </AnchoredHiddenMenu>
        </div>
      );
    }
    return undefined;
  };
  const renderLeftControls = () => {
    const duration =
      moment.duration(endDate?.diff(startDate)) || moment.duration(0);
    const totalHours = Math.floor(duration.asHours()); // Get the total hours
    const minutes = duration.minutes(); // Get the minutes
    const seconds = duration.seconds(); // Get the seconds
    const totalTime = `${totalHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    const progressLabel = moment
      .utc(timestamp?.diff(startDate))
      .format('HH:mm:ss');

    return (
      <div className={classes.leftControlsContainer}>
        {leftControls.map((control) => (
          <div className={classes.controlItem}>{control}</div>
        ))}
        <Typography className={classes.controlItem} variant="caption" noWrap>
          {`${progressLabel} / ${totalTime}`}
        </Typography>
      </div>
    );
  };

  const renderRightControls = () => (
    <>
      <Typography noWrap className={classes.controlItem} variant="caption">
        {timestamp?.format('yyyy-MM-DD HH:mm:ss')}
      </Typography>
      {rightControls.map((control) => (
        <div className={classes.controlItem}>{control}</div>
      ))}
    </>
  );

  return (
    <ThemeProvider theme={oceans3ThemeDark}>
      <DenseThemeProvider>
        <div
          className={classes.panelContainer}
          role="region"
          aria-label="Video Control Panel"
        >
          <div className={classes.seekbar}>{SeekbarComponent}</div>
          <div className={classes.customizableControls}>
            {renderLeftControls()}
            <div className={classes.spacer} />
            {renderRightControls()}
            {renderMoreControls()}
            <IconButton
              aria-label="Fullscreen"
              onClick={onFullscreen}
              color="inherit"
              className={classes.controlItem}
              tooltipProps={{
                slotProps: {
                  popper: { container: containerRef },
                },
              }}
            >
              <Fullscreen />
            </IconButton>
          </div>
        </div>
      </DenseThemeProvider>
    </ThemeProvider>
  );
};

export default ControlPanel;
