import { post, get, deleteImpl, patch } from 'util/WebRequest';

class TaxonButtonSetService {
  static async getAll(noEmptySets = false) {
    const response = await get(
      'internal/taxonomies/taxons/buttonsets',
      {
        noEmptySets,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async create(buttonSetName) {
    const response = await post(
      'internal/taxonomies/taxons/buttonsets',
      {
        payload: JSON.stringify({
          name: buttonSetName,
          enabled: false,
          taxonButtonSetLines: [],
        }),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async clone(buttonSetConfig) {
    const response = await post(
      'internal/taxonomies/taxons/buttonsets',
      {
        payload: JSON.stringify(buttonSetConfig),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async updateConfig(params) {
    const response = await post(
      `internal/taxonomies/taxons/buttonsets`,
      {
        payload: JSON.stringify(params),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async update(buttonSetId, buttonConfig) {
    const { comment } = buttonConfig[0];
    const newComment = comment === '' ? undefined : comment;
    const finalButtonConfig = [{ ...buttonConfig[0], comment: newComment }];
    const response = await patch(
      `internal/taxonomies/taxons/buttonsets/${buttonSetId}`,
      {
        payload: JSON.stringify(finalButtonConfig),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async updateAttributeButton(buttonSetId, buttonConfig) {
    const response = await patch(
      `internal/taxonomies/taxons/buttonsets/${buttonSetId}`,
      {
        payload: JSON.stringify(buttonConfig),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async delete(buttonSetId) {
    const response = await deleteImpl(
      `internal/taxonomies/taxons/buttonsets/${buttonSetId}`,
      {
        isHdr: true,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async deleteButton(buttonSetId) {
    const response = await deleteImpl(
      `internal/taxonomies/taxons/buttonsets/${buttonSetId}`,
      {
        isHdr: false,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async deleteAttributeButton(buttonSetId, sequenceNumber) {
    const response = await deleteImpl(
      `internal/taxonomies/taxons/buttonsets/${buttonSetId}`,
      {
        isHdr: true,
        sequenceNumber,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }
}
export default TaxonButtonSetService;
