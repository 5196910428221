/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { createStyles, makeStyles } from '@mui/styles';
import { Loading } from '@onc/composite-components';

import QUALITY_OPTIONS from 'domain/AppComponents/sea-tube/playlist-playback/PlaylistConstants';
import PlaylistContext from 'domain/AppComponents/sea-tube/playlist-playback/PlaylistContext';
import VideoNotFoundError from 'domain/AppComponents/video/VideoNotFoundError';
import VideoPlayer from 'domain/AppComponents/video/VideoPlayer';
import PlaylistVideoService from 'domain/services/PlaylistVideoService';
import useBroadcast from 'util/hooks/useBroadcast';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { useLocalStorage, useSessionStorage } from 'util/hooks/useStorage';
import useWebService from 'util/hooks/useWebService';
import BroadcastChannel from './BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget from '../../library/CompositeComponents/dashboard/Widget';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      overflow: 'hidden',
    },
  })
);

const VideoWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId } = props;

  const classes = useStyles();

  // Change this to use session storage in later ticket
  const [currentQuality, setCurrentQuality] = useSessionStorage<string>(
    'default-quality',
    'D'
  );

  const [defaultAutoPlay] = useLocalStorage<boolean>(
    'playlist-playback-auto-play',
    true
  );
  const { onError } = useSnackbars();
  const { playlist, playlistLines, clipId } = useContext(PlaylistContext);
  const [videoList, setVideoList] = useState([]);
  const [selectedPlaylistLineId, setSelectedPlaylistLineId] =
    useBroadcast<number>(
      dashboardId,
      BroadcastChannel.PlaylistLineId,
      clipId || playlistLines[0]?.playlistLineId,
      id
    );

  const [videos, , fetchVideos] = useWebService({
    method: PlaylistVideoService.getByPlaylistHdrId,
    onError: () => {},
    parser: (data: any) =>
      data.map((d: any) => ({
        ...d,
        qualityOptions: QUALITY_OPTIONS,
        currentQuality,
      })),
  });

  const [autoPlay] = useBroadcast<boolean>(
    dashboardId,
    BroadcastChannel.AutoPlayFlag,
    clipId ? false : defaultAutoPlay,
    id
  );

  const [refreshVideos, setRefreshVideos] = useBroadcast(
    dashboardId,
    BroadcastChannel.RefreshVideos,
    false,
    id
  );

  const getVideos = () => {
    if (currentQuality !== 'D') {
      fetchVideos(playlist.playlistHdrId, currentQuality);
    } else {
      fetchVideos(playlist.playlistHdrId);
    }
  };

  useEffect(() => {
    getVideos();
  }, [currentQuality]);

  useEffect(() => {
    if (refreshVideos) {
      getVideos();
      setRefreshVideos(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshVideos]);

  useEffect(() => {
    if (playlistLines?.length > 0 && videos?.length > 0) {
      // reorder videoList
      const reorderedVideoList = [];
      playlistLines.forEach((playlistLine) => {
        const found = videos.find(
          (v) => v.playlistLineId === playlistLine.playlistLineId
        );
        found && reorderedVideoList.push(found);
      });
      setVideoList(reorderedVideoList);
    } else {
      setVideoList(videos);
    }
  }, [playlistLines, videos]);

  const handleQualityChange = (quality: string) => {
    setCurrentQuality(quality);
  };

  const renderLoading = () => <Loading />;

  const renderVideo = () => {
    if (videoList.length === 0) {
      return (
        <div style={{ overflow: 'hidden', height: '100%' }}>
          <VideoNotFoundError />;
        </div>
      );
    }
    return (
      <VideoPlayer
        classes={classes}
        playlistLineId={selectedPlaylistLineId}
        files={videoList || []}
        playerId="playlist-playback-player"
        onVideoChange={setSelectedPlaylistLineId}
        autoPlay={autoPlay}
        onQualityChange={handleQualityChange}
        onError={onError}
      />
    );
  };

  return (
    <Widget
      key={id}
      title="Video Player"
      ariaLabel="video-player-widget"
      hideHeader
      {...props}
    >
      {videoList === undefined ? renderLoading() : renderVideo()}
    </Widget>
  );
};

VideoWidget.widgetKey = 'video-player';
VideoWidget.widgetTitle = 'Video Player';
VideoWidget.defaultDataGrid = {
  i: 'video-player',
  x: 0,
  y: 0,
  w: 5,
  h: 5,
};

export default VideoWidget;
