import { Box, LinearProgress, Typography } from 'base-components';

type LinearProgressWithPercentageProps = {
  value: number;
  width?: number;
  isLoading?: boolean;
  showPercentage?: boolean;
};
const LinearProgressWithPercentage = ({
  value,
  width = 100,
  isLoading = false,
  showPercentage = true,
  ...props
}: LinearProgressWithPercentageProps) => (
  <Box display="flex" width={`${width}%`} alignItems="center" {...props}>
    <Box width="100%" mr={1}>
      <LinearProgress
        value={value}
        variant={isLoading ? 'indeterminate' : 'determinate'}
        {...props}
      />
    </Box>
    {showPercentage && (
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    )}
  </Box>
);

export default LinearProgressWithPercentage;
