import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import SearchableList from 'library/CompositeComponents/list-container/SearchableList';
import Panel from 'library/CompositeComponents/panel/Panel';
import Environment from 'util/Environment';

const styles = (theme) => ({
  contentContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

class GroupListPanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    groupList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleChangeGroup: PropTypes.func.isRequired,
    handleDeleteGroup: PropTypes.func.isRequired,
    handleCreateGroup: PropTypes.func.isRequired,
    selectedGroup: PropTypes.shape({ key: PropTypes.number }).isRequired,
  };

  sortFunction = (a, b) => {
    if (a.name === 'All Attributes') {
      return -1;
    }
    if (b.name === 'All Attributes') {
      return 1;
    }
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  };

  state = {
    searchInput: '',
  };

  handleSearchInput = (e) => {
    this.setState({ searchInput: e.target.value });
  };

  renderAddGroupButton = () => {
    const { handleCreateGroup } = this.props;
    return Environment.getDmasUserPrivilege() === 'RW' ? (
      <ContainedButton
        translationKey="taxonomy.addGroup"
        startIcon={<Add />}
        onClick={handleCreateGroup}
      />
    ) : undefined;
  };

  actionComponents = (item) => {
    const { handleDeleteGroup } = this.props;
    if (
      item.key !== 'All Attributes' &&
      Environment.getDmasUserPrivilege() === 'RW'
    ) {
      return <DeleteIconButton onClick={handleDeleteGroup} />;
    }
    return undefined;
  };

  render() {
    const { groupList, classes, selectedGroup, handleChangeGroup } = this.props;
    const { searchInput } = this.state;
    return (
      <Panel
        title="Attribute Groups"
        actionContent={this.renderAddGroupButton()}
      >
        <div className={classes.contentContainer}>
          <SearchableList
            searchText={searchInput}
            handleClickItem={handleChangeGroup}
            selectedItem={selectedGroup.key}
            actionComponents={this.actionComponents}
            sortFunction={this.sortFunction}
            handleSearchInput={this.handleSearchInput}
            listItems={groupList.map((attribute) => ({
              key: attribute.attributeId,
              ...attribute,
            }))}
          />
        </div>
      </Panel>
    );
  }
}

export default withStyles(styles)(GroupListPanel);
