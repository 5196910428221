import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'base-components';

class TabPanel extends Component {
  static propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  static defaultProps = {
    children: undefined,
  };

  render() {
    const { children, value, index, ...other } = this.props;
    return (
      <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>
    );
  }
}

export default TabPanel;
