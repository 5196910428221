import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@onc/composite-components';
import PlaylistVideoService from 'domain/services/PlaylistVideoService';

import VideoNotFoundError from './VideoNotFoundError';

const withSeatubePlaylist = (playlistHdrId) => (VideoComponent) => {
  class WithSeatubePlaylist extends PureComponent {
    static propTypes = {
      onError: PropTypes.func.isRequired,
      playlistLineId: PropTypes.number,
      playerId: PropTypes.string.isRequired,
    };

    static defaultProps = {
      playlistLineId: undefined,
    };

    state = {
      files: undefined,
      index: undefined,
      playlistLineId: undefined,
      error: undefined,
      autoPlay: true,
    };

    componentDidMount() {
      this.getFiles();
    }

    getFiles = async (resolution) => {
      const { onError } = this.props;
      try {
        const files = await PlaylistVideoService.getByPlaylistHdrId(
          playlistHdrId,
          resolution
        );
        if (files.length === 0) {
          onError('No video files were found for this playlist');
          this.setState({ error: true });
          return;
        }
        const index = this.getIndex(files);
        this.setState({
          files,
          index,
          playlistLineId: this.getPlaylistLineId(files, index),
        });
      } catch (error) {
        onError(error.message);
        this.setState({ error: true });
      }
    };

    handleResolutionChange = (resolution) => {
      this.getFiles(resolution);
    };

    /**
     * Gets the appropriate file index
     *
     * If there is already an index in state use that, else if there is a
     * playlistLineId prop find the corresponding index, else start at the
     * beginning i.e. 0
     */
    getIndex = (files) => {
      const { playlistLineId } = this.props;
      const { index } = this.state;
      return index || playlistLineId
        ? files.findIndex((line) => line.playlistLineId === playlistLineId)
        : 0;
    };

    /**
     * Gets the appropriate playlistLineId
     *
     * If there is a prop id and nothing in state use that, otherwise use the id
     * at the given index
     */
    getPlaylistLineId = (files, index) => {
      const { playlistLineId: propId } = this.props;
      const { playlistLineId: stateId } = this.state;
      return propId && !stateId ? propId : files[index || 0].playlistLineId;
    };

    /**
     * Handles when a JWPlay playlist is complete.
     *
     * If there are other clips increment the index otherwise the SeaTube
     * playlist is finished
     */
    handlePlaylistComplete = () => {
      this.handleNext();
    };

    handleVideoLoad = () => {
      const { autoPlay } = this.state;
      const { playerId } = this.props;
      if (autoPlay) {
        window.jwplayer(playerId).play();
      }
    };

    handleNext = () => {
      const { files, index } = this.state;
      // Loop back to first file in the playlist if we are at the end
      if (index >= files.length - 1) {
        return this.setState({
          index: 0,
          playlistLineId: files[0].playlistLineId,
          autoPlay: false,
        });
      }
      return this.setState({
        index: index + 1,
        playlistLineId: files[index + 1].playlistLineId,
        autoPlay: true,
      });
    };

    handlePrevious = () => {
      const { files, index } = this.state;
      if (index <= 0) return;

      this.setState({
        index: index - 1,
        playlistLineId: files[index - 1].playlistLineId,
      });
    };

    render() {
      const { files, playlistLineId, index, error } = this.state;

      if (error) {
        return <VideoNotFoundError />;
      }

      if (files) {
        return (
          <VideoComponent
            {...this.props}
            onVideoLoad={this.handleVideoLoad}
            playlistHdrId={playlistHdrId}
            playlistLineId={playlistLineId}
            files={files[index]}
            onQualityChange={this.handleResolutionChange}
            onPlaylistComplete={this.handlePlaylistComplete}
            onNext={this.handleNext}
            onPrevious={index > 0 ? this.handlePrevious : undefined}
            seekTo={files[index].getDateAt(0).getTime() / 1000}
          />
        );
      }

      return <Loading size={50} />;
    }
  }
  return WithSeatubePlaylist;
};

export default withSeatubePlaylist;
