import { memo } from 'react';
import * as React from 'react';
import MaterialDialogTitle, {
  DialogTitleProps,
} from '@mui/material/DialogTitle';

const DialogTitle: React.VFC<DialogTitleProps> = (props: DialogTitleProps) => (
  <MaterialDialogTitle {...props} />
);

export default memo(DialogTitle);
