import useFetch, { QueryOptions } from 'util/hooks/useFetch';
import { get } from 'util/WebRequest';

const SERVICE = 'api/dataAvailability/dataproducts';

type DataAvailabilityParameters = {
  locationCode?: string;
  deviceCategoryCode?: string;
  deviceCode?: string;
  propertyCode?: string;
  dateFrom?: string;
  dateTo?: string;
  dataProductCode?: string;
  extension?: string;
  minimumCoverage?: number;
  maximumCoverage?: number;
  getLatest?: boolean;
  groupBy?: 'day' | 'week' | 'month' | 'year';
  mergeGaps?: number;
  includeEmptyDays?: boolean;
  appToken?: string;
};

type DataAvailabilityResponse = {
  availableDataProducts: {
    averageFileCoverage: number;
    dataProductCode: string;
    extension: string;
    dateFrom: string;
    dateTo: string;
    deviceCode: string;
    fileCount: number;
    totalFileSize: number;
    totalUncompressedFileSize: number;
  }[];
  messages: string[];
  next: null | string;
  queryUrl: string;
};

export const useDataAvailability = (
  params: DataAvailabilityParameters,
  queryOptions: Partial<QueryOptions<DataAvailabilityResponse, Error>> = {}
) =>
  useFetch<DataAvailabilityResponse, DataAvailabilityParameters>(
    SERVICE,
    { queryKey: ['data-availability'], ...queryOptions },
    { params },
    (response) => response.data as DataAvailabilityResponse
  );

class DataAvailabilityWebService {
  static get(params) {
    return get(SERVICE, params);
  }
}
export default DataAvailabilityWebService;
