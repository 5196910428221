import { get } from 'util/WebRequest';

class LastReadingJSONService {
  /*
   * Service response is of the form:
   * sensorInfos =
   * <JSON RESPONSE>
   * And needs to be parsed appropriately
   */
  static transformResponse = (response) => {
    try {
      return JSON.parse(response.substring(response.indexOf('\n')));
    } catch (e) {
      return { error: e, data: [] };
    }
  };

  static get(params) {
    return get('LastReadingJSON', params, {
      transformResponse: LastReadingJSONService.transformResponse,
    });
  }
}

export default LastReadingJSONService;
