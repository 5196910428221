/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'base-components';
import SeekbarArrowLeft from './SeekbarArrowLeft';
import SeekbarArrowRight from './SeekbarArrowRight';
import SeekbarTooltip from './SeekbarTooltip';

const DEFAULT_HEIGHT = 5;
const ZOOM_HEIGHT = 52;
const TOTAL = 100;
const DEFAULT_CENTER = TOTAL / 2;

class ZoomInSeekbar extends PureComponent {
  static propTypes = {
    percentConversion: PropTypes.func.isRequired,
    maxZoom: PropTypes.func.isRequired,
    scaler: PropTypes.func.isRequired,
    slider: PropTypes.func.isRequired,
    onRelease: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      height: DEFAULT_HEIGHT,
      center: DEFAULT_CENTER,
      zoom: false,
      isDragging: false,
      upper: TOTAL,
      lower: 0,
      scale: 1,
      step: 1,
      mouseX: 0,
      mouseY: 0,
      percent: 50,
      width: 430,
      xOffset: 0,
      yOffset: 0,
      position: 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.move, 35); // Starting timer
    if (document.getElementById('container') !== null) {
      const width = document.getElementById('container').clientWidth;
      this.setState({ width });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval); // Stoping timer
  }

  getRange = (scale) => {
    const { scaler } = this.props;
    return TOTAL / 2 / scaler(scale);
  };

  rightRange = (scale) => {
    const { scaler } = this.props;
    const { center } = this.state;
    return (100 - center) / scaler(scale);
  };

  leftRange = (scale) => {
    const { scaler } = this.props;
    const { center } = this.state;
    return center / scaler(scale);
  };

  handleDragStart = (event) => {
    const { zoom } = this.state;
    if (event.buttons === 1 && !zoom) {
      this.setState({
        height: ZOOM_HEIGHT,
        zoom: true,
        isDragging: true,
      });
    }
  };

  move = () => {
    const {
      scale,
      center,
      isDragging,
      mouseY,
      step,
      percent,
      height,
      position,
      yOffset,
      width,
    } = this.state;
    const { maxZoom, slider } = this.props;

    let newHeight = mouseY > yOffset - 30 ? ZOOM_HEIGHT : yOffset - mouseY;
    let newScale = 1 + Math.floor((newHeight - ZOOM_HEIGHT) / 1.5) / 10;
    if (this.getRange(newScale) < maxZoom() / 2 || newScale < 1) {
      newScale = scale;
      newHeight = height;
    }

    if (isDragging) {
      let newCenter = center;
      let hi = newCenter + this.rightRange(scale);
      let lo = newCenter - this.leftRange(scale);
      const rangeSize = hi - lo;
      const newStep = rangeSize / slider(scale);

      // Moving center w/ arrows
      if (position < 20) {
        newCenter -= step;
      }
      if (position > width - 20) {
        newCenter += step;
      }

      // Moving center from scaling
      if (hi > TOTAL) {
        newCenter -= hi - TOTAL;
        hi = 100;
      }
      if (lo < 0) {
        newCenter -= lo;
        lo = 0;
      }

      let newPercent = (Math.round(position / 5) * 5) / width;
      newPercent = newPercent * rangeSize + lo;
      if (newPercent > 100) newPercent = 100;
      if (newPercent < 0) newPercent = 0;

      if (newScale !== scale) {
        newCenter = percent;
      }

      this.setState({
        percent: newPercent,
        upper: hi,
        lower: lo,
        center: newCenter,
        step: newStep,
        scale: newScale,
        height: newHeight,
      });
    }
  };

  handleDrag = (event) => {
    const { width } = this.state;

    const xOffset = document
      .getElementById('container')
      .getBoundingClientRect().x;
    const yOffset = document
      .getElementById('container')
      .getBoundingClientRect().y;

    let position = event.pageX - xOffset;
    if (position > width) position = width;
    if (position < 0) position = 0;
    this.setState({
      mouseX: event.pageX,
      mouseY: event.pageY,
      position,
      yOffset,
      xOffset,
    });
  };

  handleRelease = () => {
    this.setState({
      height: DEFAULT_HEIGHT,
      center: DEFAULT_CENTER,
      isDragging: false,
      zoom: false,
      upper: TOTAL,
      lower: 0,
      scale: 1,
    });
    const { percent } = this.state;
    const { onRelease } = this.props;
    onRelease(percent);
  };

  handleLeave = () => {
    const { zoom } = this.state;
    if (zoom) {
      this.setState({
        height: DEFAULT_HEIGHT,
        center: DEFAULT_CENTER,
        isDragging: false,
        zoom: false,
        upper: TOTAL,
        lower: 0,
        scale: 1,
      });
    }
  };

  render() {
    const {
      height,
      zoom,
      lower,
      upper,
      scale,
      percent,
      position,
      yOffset,
      xOffset,
    } = this.state;
    const { percentConversion } = this.props;
    const lowerVal = percentConversion(lower);
    const cursorVal = percentConversion(percent);
    const upperVal = percentConversion(upper);
    return (
      <div
        id="container"
        style={{
          position: 'absolute',
          bottom: '25px',
          width: '96%',
          height: '0',
          cursor: 'pointer',
        }}
      >
        <div
          onMouseEnter={this.handleDragStart}
          onFocus={this.handleDragStart}
          onMouseMove={this.handleDrag}
          onMouseUp={this.handleRelease}
          style={
            zoom
              ? null
              : {
                  width: '100%',
                  height: '25px',
                  position: 'absolute',
                  bottom: '40px',
                  left: '0',
                  zIndex: '998',
                }
          }
        />
        <div
          onMouseEnter={this.handleDragStart}
          onFocus={this.handleDragStart}
          onMouseMove={this.handleDrag}
          onMouseUp={this.handleRelease}
          onMouseLeave={this.handleLeave}
          style={
            zoom
              ? {
                  width: '100vw',
                  height: '100vh',
                  position: 'absolute',
                  bottom: `-${yOffset - 126}px`,
                  left: `-${xOffset}px`,
                  zIndex: '998',
                }
              : null
          }
        />
        {zoom ? (
          <SeekbarTooltip
            cursorVal={cursorVal}
            height={height}
            position={position}
          />
        ) : null}

        {zoom ? (
          <Card
            onMouseDown={this.handleDragStart}
            onMouseMove={this.handleDrag}
            onMouseUp={this.handleRelease}
            style={{
              width: '100%',
              cursor: 'pointer',
              height: `${height}px`,
              border: 'solid',
              borderWidth: '1px',
              position: 'absolute',
              userSelect: 'none',
              bottom: '32px',
              zIndex: '998',
            }}
          >
            <div>
              <SeekbarArrowLeft text={lowerVal} />
              <span
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  left: '50%',
                }}
              >
                {scale}
              </span>
              <SeekbarArrowRight text={upperVal} />
            </div>
          </Card>
        ) : null}
      </div>
    );
  }
}

export default ZoomInSeekbar;
