import { Component, createRef } from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  ErrorSnackbar,
  InfoSnackbar,
  LoadingSnackbar,
} from '@onc/composite-components';
import { DragScroll, SizeMe } from 'base-components';
import {
  SECONDS_PER_FIVE_MINUTES,
  DAILY_TIMERANGE,
  WEEKLY_TIMERANGE,
  SECONDS_PER_DAY,
  IMAGE_LOAD_BUFFER,
  ERROR_CODE,
  HYDROPHONE_DEVICE_CATEGORY_CODE,
  SCROLLING_DIRECTION,
  DATA_STATE,
  MINUTES_WHEN_PUSHING_SHIFTING,
  NUMBER_OF_FILES_WHEN_PUSHING_SHIFTING,
  RETRIEVING_SPLASH_FILENAME,
  CUSTOM_HYDROPHONE_LIMIT_OPTION,
  IMAGE_FILE_EXTENSION,
  ADCP_DEVICE_CATEGORY_CODES,
  IMAGE_WIDTH,
  INITIAL_WIDGET_IMAGE_WIDTH,
  EXPECTED_WINDOW_SIZE,
  WIDGET_IMAGE_CONTAINER_OFFSET,
  WIDGET_IMAGE_ITEM_DIVISOR,
  WIDGET_IMAGE_ITEM_GAP,
} from 'domain/Apps/data-player/util/DataPlayerConstants';
import ToolboxUtil from 'domain/Apps/data-player/util/ToolboxUtil';
import ArchiveFileService from 'domain/services/ArchiveFileService';
import DataAvailabilityWebService from 'domain/services/DataAvailabilityWebService';
import DeviceService from 'domain/services/DeviceService';

import Environment from 'util/Environment';

import DataPlayerImage from './DataPlayerImage';
import SpectraAxis from './SpectraAxis';
import SpectraScale from './SpectraScale';
import SpectraTimeLine from './SpectraTimeLine';
import Toolbox from './Toolbox';
import DataPlayerDeviceService from './util/DataPlayerDeviceService';
import DataPlayerHelper from './util/DataPlayerHelper';
import DataPlayerImageService from './util/DataPlayerImageService';

const styles = () => ({
  root: {
    display: 'flex',
    width: '96%',
  },
  widgetRoot: {
    overflow: 'hidden',
    display: 'flex',
    width: '99%',
    height: '99%',
  },
  playerContainer: {
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
  },
  widgetPlayerContainer: {
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
    overflow: 'hidden',
    width: '100%',
  },
  playerWrapper: {
    width: 'calc(100% - 178px)',
  },
  widgetWrapper: {
    display: 'flex',
    width: '100%',
  },
  line: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    pointerEvents: 'none',
    background:
      'linear-gradient(to right, transparent 0%, transparent calc(50% - 0.81px), red calc(50% - 0.8px), red calc(50% + 0.8px), transparent calc(50% + 0.81px), transparent 100%)',
  },
  lineForWidget: {
    width: '100%',
    height: '78%',
    position: 'absolute',
    top: '6.66%',
    left: '0px',
    pointerEvents: 'none',
    background:
      'linear-gradient(to right, transparent 0%, transparent calc(50% - 0.81px), red calc(50% - 0.8px), red calc(50% + 0.8px), transparent calc(50% + 0.81px), transparent 100%)',
  },
});

class DataPlayer extends Component {
  isMounted = false;

  static propTypes = {
    deviceCategoryCode: PropTypes.string.isRequired,
    timeRange: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(moment).isRequired,
    dateSelectorValue: PropTypes.string,
    deviceId: PropTypes.number,
    resourceId: PropTypes.number.isRequired,
    onCloseTool: PropTypes.func,
    toolBox: PropTypes.bool,
    classes: PropTypes.shape({
      line: PropTypes.string,
      root: PropTypes.string,
      playerContainer: PropTypes.string,
      playerWrapper: PropTypes.string,
      lineForWidget: PropTypes.string,
      widgetRoot: PropTypes.string,
      widgetWrapper: PropTypes.string,
      widgetPlayerContainer: PropTypes.string,
    }),
    isCommunicating: PropTypes.bool,
    isDashboard: PropTypes.bool,
    handleWidgetToolboxClose: PropTypes.func,
  };

  static defaultProps = {
    dateSelectorValue: 'dateRange',
    deviceId: undefined,
    classes: undefined,
    onCloseTool: undefined,
    toolBox: undefined,
    isDashboard: false,
    isCommunicating: false,
    handleWidgetToolboxClose: undefined,
  };

  handleScrollUpdate = () => {};

  // ResizeObserver to report image size changes in the widget
  observeWidgetResize = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      if (this.isMounted) {
        this.setState({
          widgetImageWidth: entry.contentRect.width,
        });
      }
    });
  });

  constructor(props) {
    super(props);
    this.imgRef = createRef();
    this.state = {
      translate: 0,
      initialTranslate: 0,
      firstDate: moment(),
      lastDate: moment(),
      files: [],
      flacFile: undefined,
      isRdiDevice: undefined,
      isLoading: false,
      isRetrievingImages: false,
      isError: false,
      errorMessage: undefined,
      isMissingData: false,
      fileCount: 0,
      fileTotal: 0,
      fileRetrivalCount: 0,
      adcpOptions: undefined,
      hydrophoneOptions: undefined,
      nextSplashImageKey: 0,
      initialWindowSize: EXPECTED_WINDOW_SIZE,
      widgetImageWidth: 0,
    };
    this.pushing = false;
    this.shifting = false;
    this.secondsFromPreviousDate = undefined;
    this.previousScrollValue = undefined;

    // widget dimensions
    this.widgetInitialWidth = undefined;
    this.widgetWidth = undefined;
    this.widgetInitialHeight = undefined;
    this.widgetHeight = undefined;
  }

  async componentDidMount() {
    this.isMounted = true;

    const { deviceId, startDate, resourceId, deviceCategoryCode, timeRange } =
      this.props;

    if (
      resourceId &&
      deviceId &&
      startDate &&
      deviceCategoryCode &&
      timeRange
    ) {
      await this.fetchDefaultSearchOptions(
        resourceId,
        deviceCategoryCode,
        deviceId
      );
    }
  }

  async shouldComponentUpdate(nextProps) {
    const { deviceId, timeRange, startDate, deviceCategoryCode, resourceId } =
      this.props;
    // check if configuration has changed, and if all values are present
    if (
      (resourceId !== nextProps.resourceId ||
        deviceId !== nextProps.deviceId ||
        timeRange !== nextProps.timeRange ||
        startDate !== nextProps.startDate ||
        deviceCategoryCode !== nextProps.deviceCategoryCode) &&
      nextProps.resourceId &&
      nextProps.deviceId &&
      nextProps.timeRange &&
      nextProps.startDate &&
      nextProps.deviceCategoryCode
    ) {
      // wipe out user set options when changing devices
      this.setState({
        adcpOptions: undefined,
        hydrophoneOptions: undefined,
      });

      // get new default options
      await this.fetchDefaultSearchOptions(
        nextProps.resourceId,
        nextProps.deviceCategoryCode,
        nextProps.deviceId
      );
      this.initializeFiles(
        nextProps.deviceId,
        nextProps.startDate,
        nextProps.deviceCategoryCode,
        nextProps.timeRange
      );
    }
    return true;
  }

  componentWillUnmount() {
    const { isDashboard } = this.props;
    if (isDashboard) {
      // Perform cleanup on resizeObserver
      this.isMounted = false;
      const imageContainer = document.querySelector('#imageContainer-0');
      if (imageContainer) {
        this.observeWidgetResize.unobserve(imageContainer);
        this.observeWidgetResize.disconnect();
      }
    }
  }

  updateSecondsFromPreviousDate = (date) => {
    // Upon resizing by width, retrieve the pixel difference
    // and store the new translate value
    const {
      isRetrievingImages,
      isLoading,
      fileTotal,
      translate,
      initialWindowSize,
      initialTranslate,
    } = this.state;
    const { startDate, isDashboard } = this.props;

    if (!isLoading && !isRetrievingImages && isDashboard && fileTotal === 0) {
      // calculate the translate value when resized
      const windowDifference =
        (initialWindowSize - window.innerWidth * 0.96) / 2;
      const diff = translate - initialTranslate - windowDifference;
      const totalSecondsByScrolling = diff / this.getPixelPerSecond();
      const prevDate = startDate
        .clone()
        .utc()
        .add(totalSecondsByScrolling, 'seconds');

      this.secondsFromPreviousDate = date
        .clone()
        .utc()
        .diff(prevDate, 'seconds');
    }
  };

  updateWidgetDimensions = (initialWidth, initialHeight, width, height) => {
    const { translate, initialWindowSize, initialTranslate } = this.state;
    const windowDifference = (initialWindowSize - window.innerWidth * 0.96) / 2;
    const diff = translate - initialTranslate - windowDifference;
    const totalSecondsByScrolling = diff / this.getPixelPerSecond();
    this.previousScrollValue = totalSecondsByScrolling;
    this.widgetInitialWidth = initialWidth;
    this.widgetInitialHeight = initialHeight;
    this.widgetWidth = width;
    this.widgetHeight = height;
  };

  changeDate = (date, modifiedDate) => {
    date.set({
      year: modifiedDate.year(),
      month: modifiedDate.month(),
      date: modifiedDate.date(),
      hour: modifiedDate.hour(),
      minute: modifiedDate.minute(),
      second: modifiedDate.second(),
      millisecond: modifiedDate.millisecond(),
    });
    return date;
  };

  fetchDefaultSearchOptions = async (
    resourceId,
    deviceCategoryCode,
    deviceId
  ) => {
    const { isDashboard } = this.props;
    let adcpNortekPresetOptions;
    let adcpRdiPresetOptions;
    let hydrophonePresetOptions;

    // get if device is not RDI but do not await so that next service call can run while this resolves
    const isRdiDevicePromise = DataPlayerDeviceService.isRdiAdcp(
      deviceId,
      deviceCategoryCode,
      this.onError
    );
    const defaultSearchOptionsForDevice =
      await ToolboxUtil.fetchDefaultSearchOptionsForDevice(
        resourceId,
        deviceCategoryCode,
        deviceId,
        this.onError
      );
    const isRdiDevice = await isRdiDevicePromise;
    if (ADCP_DEVICE_CATEGORY_CODES.includes(deviceCategoryCode)) {
      if (isRdiDevice === true) {
        adcpRdiPresetOptions = defaultSearchOptionsForDevice;
      } else {
        adcpNortekPresetOptions = defaultSearchOptionsForDevice;
      }
    } else {
      hydrophonePresetOptions = defaultSearchOptionsForDevice;
    }

    if (isDashboard) {
      const { timeRange, startDate } = this.props;
      this.setState(
        {
          adcpNortekPresetOptions,
          adcpRdiPresetOptions,
          hydrophonePresetOptions,
          isRdiDevice,
        },
        () =>
          this.initializeFiles(
            deviceId,
            startDate,
            deviceCategoryCode,
            timeRange
          )
      );
    } else {
      this.setState({
        adcpNortekPresetOptions,
        adcpRdiPresetOptions,
        hydrophonePresetOptions,
        isRdiDevice,
      });
    }
  };

  handleHydrophoneFilterSubmit = (values) => {
    const { deviceId, startDate, timeRange, deviceCategoryCode } = this.props;
    const { hydrophonePresetOptions } = this.state;
    const upperColourLimit =
      values.customLimit === CUSTOM_HYDROPHONE_LIMIT_OPTION
        ? values.upperColourLimit
        : hydrophonePresetOptions.upperColourLimit;

    const lowerColourLimit =
      values.customLimit === CUSTOM_HYDROPHONE_LIMIT_OPTION
        ? values.lowerColourLimit
        : hydrophonePresetOptions.lowerColourLimit;

    const spectrogramFrequencyUpperLimit =
      values.customLimit === CUSTOM_HYDROPHONE_LIMIT_OPTION
        ? values.upperFrequencyLimit
        : hydrophonePresetOptions.spectrogramFrequencyUpperLimit;

    this.setState({
      hydrophoneOptions: {
        colourPalette: values.colourPalette,
        upperColourLimit,
        lowerColourLimit,
        spectrogramSource: values.spectrogramSource,
        hydrophoneDataAcquisition: values.hydrophoneDataAcquisition,
        hydrophoneDataDiversion: values.hydrophoneDataDiversion,
        hydrophoneChannel: values.hydrophoneChannel,
        spectrogramFrequencyUpperLimit,
      },
    });
    this.initializeFiles(deviceId, startDate, deviceCategoryCode, timeRange);
  };

  initializeFiles = (deviceId, startDate, deviceCategoryCode, timeRange) => {
    const { isDashboard } = this.props;
    const { widgetImageWidth } = this.state;
    const dataPlayerRootWidth =
      document.getElementById('dataplayer-root')?.offsetWidth;

    const timeRangeSetup = DataPlayerHelper.getStartEndDateAndOffset(
      startDate,
      timeRange,
      isDashboard,
      widgetImageWidth === 0 ? INITIAL_WIDGET_IMAGE_WIDTH : widgetImageWidth,
      dataPlayerRootWidth
    );
    this.setState(
      {
        files: [],
        firstDate: timeRangeSetup.firstDate,
        lastDate: timeRangeSetup.lastDate,
        initialWindowSize: timeRangeSetup.windowWidth,
      },
      () =>
        this.getFiles(
          deviceId,
          timeRangeSetup.firstDate,
          timeRangeSetup.lastDate,
          deviceCategoryCode,
          timeRange,
          SCROLLING_DIRECTION.STATIONARY,
          timeRangeSetup.translateOffset
        )
    );
  };

  pushFiles = async (translate) => {
    if (this.pushing) return;
    const { deviceId, deviceCategoryCode, timeRange } = this.props;
    this.pushing = true;
    const { lastDate } = this.state;
    const newLastDate = lastDate
      .utc()
      .clone()
      .add(MINUTES_WHEN_PUSHING_SHIFTING, 'minutes');
    this.setState({ lastDate: newLastDate });
    await this.getFiles(
      deviceId,
      lastDate,
      newLastDate,
      deviceCategoryCode,
      timeRange,
      SCROLLING_DIRECTION.RIGHT,
      translate
    );
    this.setState({ translate });
    this.pushing = false;
  };

  shiftFiles = async (translate) => {
    if (this.shifting) return;
    const { deviceId, deviceCategoryCode, timeRange } = this.props;
    this.shifting = true;
    const { firstDate } = this.state;
    const newFirstDate = firstDate
      .utc()
      .clone()
      .subtract(MINUTES_WHEN_PUSHING_SHIFTING, 'minutes');
    this.setState({ firstDate: newFirstDate });
    await this.getFiles(
      deviceId,
      newFirstDate,
      firstDate,
      deviceCategoryCode,
      timeRange,
      SCROLLING_DIRECTION.LEFT,
      translate
    );
    this.shifting = false;
  };

  getFiles = async (
    deviceId,
    startDate,
    endDate,
    deviceCategoryCode,
    timeRange,
    direction,
    translateOffset
  ) => {
    const { resourceId, dateSelectorValue } = this.props;
    const deviceServiceData = await DeviceService.getData(deviceId);
    if (
      DataPlayerHelper.checkIfUserHasAccess(
        deviceServiceData.devices[0].userGroups,
        deviceServiceData.devices[0].restrictions,
        resourceId,
        startDate,
        endDate
      )
    ) {
      const plotRequestsOrArchiveFiles = await this.getPlotsWithOptions(
        deviceServiceData.devices[0]?.device?.devicecode,
        deviceId,
        deviceCategoryCode,
        startDate,
        endDate,
        timeRange,
        translateOffset,
        direction
      );

      if (
        dateSelectorValue === 'latest' &&
        plotRequestsOrArchiveFiles.length > 1
      ) {
        const flacObj = plotRequestsOrArchiveFiles[0].files[0];
        const latestPng = plotRequestsOrArchiveFiles[1].files
          .filter((file) => file.filename.endsWith('spect.png'))
          .reduce(
            (latestFile, currentFile) =>
              !latestFile ||
              new Date(currentFile.dateTo) > new Date(latestFile.dateTo)
                ? currentFile
                : latestFile,
            null
          );
        this.setState({
          files: latestPng ? [latestPng] : [],
          flacFile: flacObj,
        });
      } else {
        this.waitForSinglePlotToFinish(
          plotRequestsOrArchiveFiles,
          translateOffset,
          direction
        );
      }
    } else {
      const dateRanges = DataPlayerHelper.generateDates(
        startDate,
        endDate,
        timeRange,
        direction
      );
      const plotRequestPromises = new Array(dateRanges.length);
      for (let i = 0; i < dateRanges.length; i += 1) {
        plotRequestPromises[i] = {
          dateFrom: dateRanges[i].dateFrom.toDate(),
          dateTo: dateRanges[i].dateTo.toDate(),
          errorType: ERROR_CODE.RESTRICTED,
        };
      }
      this.handleErrorRequests(
        plotRequestPromises,
        deviceCategoryCode,
        translateOffset,
        direction
      );
    }
    this.setState({
      isRetrievingImages: false,
    });
  };

  handleErrorRequests = async (
    plotRequestPromises,
    deviceCategoryCode,
    translateOffset,
    direction
  ) => {
    const splashImages = plotRequestPromises
      .filter((plotRequestPromise) => plotRequestPromise.errorType)
      .map((errorPlotRequestPromise) =>
        DataPlayerHelper.handleSplashImageForRequestAndDownloadError(
          errorPlotRequestPromise,
          deviceCategoryCode
        )
      );
    if (splashImages.length !== 0) {
      // if there were error handled requests load appropriate splash images into
      // image buffer and put up missing data snackbar
      const files = this.generateNewFilesArray(splashImages);
      files.forEach((file) =>
        DataPlayerHelper.addToMissingTimes(file.dateFrom, file.dateTo)
      );
      this.setFileStateAndCalculateTranslate(
        files.filter((file) => file),
        translateOffset,
        direction
      );
      this.setState({
        isMissingData: true,
      });
    }
  };

  getPlotsWithOptions = async (
    deviceCode,
    deviceId,
    deviceCategoryCode,
    startDate,
    endDate,
    timeRange,
    translateOffset,
    direction
  ) => {
    const { isDashboard, dateSelectorValue } = this.props;
    const { hydrophonePresetOptions, hydrophoneOptions } = this.state;
    let dateRanges;
    this.setState({ isLoading: true });
    // Use placeholder images when generating a range of spectrograms
    if (dateSelectorValue === 'dateRange') {
      dateRanges = DataPlayerHelper.generateDates(
        startDate,
        endDate,
        timeRange,
        direction,
        isDashboard
      );
      this.putUpRetrievingSplashImages(
        dateRanges,
        deviceCategoryCode,
        translateOffset,
        direction
      );
    }

    let plotRequestPromises = [];
    if (deviceCategoryCode === HYDROPHONE_DEVICE_CATEGORY_CODE) {
      // use the options passed in from the toolbox if they exist
      const dataProductOptionsForServiceCall =
        hydrophoneOptions || hydrophonePresetOptions;
      if (dateSelectorValue === 'dateRange') {
        plotRequestPromises = dateRanges.map(async (dateRange) => {
          const plotRequestResponse =
            await DataPlayerImageService.getSpectrogramUrlsByDeviceCodeDateRangeWithFilter(
              deviceCode,
              dateRange.dateFrom,
              dateRange.dateTo,
              dataProductOptionsForServiceCall,
              this.onError,
              timeRange
            );
          return plotRequestResponse;
        });
      } else {
        // Narrow the date range by fetching the date of the latest spectrogram using data availability service
        const dataAvailabilityResponse = await DataAvailabilityWebService.get({
          deviceCode,
          extension: 'flac',
          dataProductCode: 'AD',
          groupBy: 'day',
          includeEmptyDays: false,
        });

        const latestData =
          dataAvailabilityResponse.data.availableDataProducts.reduce(
            (latest, current) =>
              new Date(current.dateFrom) > new Date(latest.dateFrom)
                ? current
                : latest
          );

        const latestDateFromPlusOneDay = new Date(
          new Date(latestData.dateFrom).getTime() + 24 * 60 * 60 * 1000
        );

        // Fetch flac files and valid spectrograms using the archivefile service
        const archiveFileHSDResponse =
          await ArchiveFileService.getFileListByDevice(
            deviceCode,
            latestData.dateFrom,
            latestDateFromPlusOneDay.toISOString(),
            'png',
            '.*Z(?!-FFT)[^ ]*-spect.png',
            'HSD',
            1,
            true
          );

        const archiveFileFlacResponse =
          await ArchiveFileService.getFileListByDevice(
            deviceCode,
            latestData.dateFrom,
            archiveFileHSDResponse.data.files[0]?.dateTo,
            'flac',
            undefined,
            'AD',
            1,
            true
          );

        // Return archive response data and forgo image service calls if there is valid spectrogram data
        if (
          archiveFileHSDResponse.data.files.length > 0 &&
          archiveFileHSDResponse.data.files.some((file) =>
            file.filename.endsWith('spect.png')
          )
        ) {
          this.setState({
            fileCount: 1,
            fileTotal: 1,
          });
          const flacAndPngData = [
            archiveFileFlacResponse.data,
            archiveFileHSDResponse.data,
          ];
          return flacAndPngData;
        }

        // Choose latest dates based on response data files
        const latestStartDate = moment(
          archiveFileFlacResponse.data?.files[0]?.dateFrom
            ? archiveFileFlacResponse.data.files[0]?.dateFrom
            : archiveFileHSDResponse.data.files[0]?.dateFrom
        );
        const latestEndDate = moment(
          archiveFileFlacResponse.data?.files[0]?.dateTo
            ? archiveFileFlacResponse.data.files[0]?.dateTo
            : archiveFileHSDResponse.data.files[0]?.dateTo
        );
        // Return single response when requesting latest spectrogram
        const plotRequestResponse =
          await DataPlayerImageService.getSpectrogramUrlsByDeviceCodeDateRangeWithFilter(
            deviceCode,
            latestStartDate,
            latestEndDate,
            dataProductOptionsForServiceCall,
            this.onError,
            timeRange
          );
        this.setState({
          isLoading: false,
        });
        return [plotRequestResponse];
      }
    } else {
      const {
        adcpNortekPresetOptions,
        adcpRdiPresetOptions,
        adcpOptions,
        isRdiDevice,
      } = this.state;
      const dataProductOptionsForServiceCall =
        adcpOptions ||
        (isRdiDevice ? adcpRdiPresetOptions : adcpNortekPresetOptions);
      // Nortek and RDI have diffrent dataproduct codes for current plots.
      // Get the correct data product and code for this device here so that
      // the service call is only made once.
      const dataProductForThisAdcpDevice =
        await DataPlayerDeviceService.getDataProductForDevice(
          deviceId,
          IMAGE_FILE_EXTENSION,
          this.onError
        );
      plotRequestPromises = dateRanges.map(async (dateRange) => {
        const plotRequestResponse = await DataPlayerImageService.getAdcpPlots(
          deviceId,
          dateRange.dateFrom,
          dateRange.dateTo,
          dataProductOptionsForServiceCall,
          isRdiDevice,
          dataProductForThisAdcpDevice.dataProductCode,
          this.onError
        );
        return plotRequestResponse;
      });
    }

    this.setState({
      fileTotal: plotRequestPromises.length,
      fileCount: 0,
    });

    let count = 0;
    plotRequestPromises.forEach(async (retrivalPromise) => {
      await Promise.all([retrivalPromise]).then(() => {
        count += 1;
        this.setState({
          fileCount: count,
        });
      });
    });
    plotRequestPromises = await Promise.all(plotRequestPromises);

    this.setState({
      isLoading: false,
    });
    return plotRequestPromises;
  };

  waitForSinglePlotToFinish = async (
    plotRequestPromises,
    translateOffset,
    direction
  ) => {
    const { deviceCategoryCode, deviceId, dateSelectorValue } = this.props;
    const plotDownloadPromises =
      await this.waitForDownload(plotRequestPromises);
    let fileCount = 0;
    let isMissingData = false;
    const fileTotal = plotDownloadPromises.length;
    plotDownloadPromises.forEach(async (downloadPromise) => {
      let newFile = await downloadPromise;
      if (newFile.errorType) {
        // the download response had no data or was restricted
        // and was handled by the error handler in DataPlayerHelper
        isMissingData = true;
        if (dateSelectorValue === 'dateRange')
          DataPlayerHelper.addToMissingTimes(newFile.dateFrom, newFile.dateTo);
        newFile = DataPlayerHelper.handleSplashImageForRequestAndDownloadError(
          newFile,
          deviceCategoryCode
        );
      }
      newFile.deviceId = deviceId;
      fileCount += 1;
      this.setState({
        isRetrievingImages: true,
        fileCount,
        fileTotal,
        isMissingData,
      });
      const files = this.generateNewFilesArray(newFile);
      this.setFileStateAndCalculateTranslate(
        files.filter((file) => file),
        translateOffset,
        direction
      );
    });

    // check for request responses that had no data or was restricted
    // and was handled by the error handler in DataPlayerHelper
    this.handleErrorRequests(
      plotRequestPromises,
      deviceCategoryCode,
      translateOffset,
      direction
    );
  };

  generateNewFilesArray = (filesToAdd) => {
    const { files } = this.state;
    return DataPlayerHelper.sortImagesByDateFromAndRemoveSplash(
      files,
      filesToAdd
    );
  };

  setFileStateAndCalculateTranslate = (files, translateOffset, direction) => {
    const { isDashboard } = this.props;
    const { widgetImageWidth, initialTranslate, translate } = this.state;
    // Dashboard images need to account for dynamic margin adjustment
    const widgetImageRightMargin =
      (-widgetImageWidth + WIDGET_IMAGE_CONTAINER_OFFSET) /
        WIDGET_IMAGE_ITEM_DIVISOR -
      WIDGET_IMAGE_ITEM_GAP;
    if (direction === SCROLLING_DIRECTION.STATIONARY) {
      let firstTranslate;
      if (!isDashboard) {
        firstTranslate =
          (files.length % 2 === 1
            ? ((files.length - 1) / 2) * IMAGE_WIDTH
            : (files.length / 2) * IMAGE_WIDTH) + translateOffset;
      }
      if (isDashboard && widgetImageWidth !== 0) {
        firstTranslate =
          (files.length % 2 === 1
            ? ((files.length - 1) / 2) * widgetImageWidth -
              (widgetImageWidth + widgetImageRightMargin) * 2
            : (files.length / 2) * widgetImageWidth) + translateOffset;
      }
      // Case when no images have not yet loaded in widget, use initial widget image width
      if (isDashboard && widgetImageWidth === 0) {
        // widgetImageWidth = 0 TWICE during a whole run. Once on the first load, once on the last.
        // This causes it to shift by two extra plots.
        firstTranslate =
          (files.length % 2 === 1
            ? ((files.length - 1) / 2) * INITIAL_WIDGET_IMAGE_WIDTH -
              // Subtract 2 images' worth of time from the translate to account for the zero width calls.
              (INITIAL_WIDGET_IMAGE_WIDTH + widgetImageRightMargin) * 2
            : (files.length / 2) * INITIAL_WIDGET_IMAGE_WIDTH) +
          translateOffset;
      }
      this.setState({
        files,
        translate: firstTranslate,
        initialTranslate: firstTranslate,
      });
    }
    if (direction === SCROLLING_DIRECTION.RIGHT) {
      this.setState({
        files,
        translate: translateOffset,
      });
    }
    if (direction === SCROLLING_DIRECTION.LEFT) {
      if (isDashboard) {
        this.setState((prevState) => ({
          files,
          translate: this.shifting
            ? prevState.translate +
              (widgetImageWidth + widgetImageRightMargin) *
                (files.length - prevState.files.length)
            : translate,
          initialTranslate: this.shifting
            ? initialTranslate +
              (widgetImageWidth + widgetImageRightMargin) *
                (files.length - prevState.files.length)
            : initialTranslate,
        }));
      } else {
        this.setState({
          files,
          translate:
            translateOffset +
            IMAGE_WIDTH * NUMBER_OF_FILES_WHEN_PUSHING_SHIFTING,
          initialTranslate: this.shifting
            ? initialTranslate +
              IMAGE_WIDTH * NUMBER_OF_FILES_WHEN_PUSHING_SHIFTING
            : initialTranslate,
        });
      }
    }
  };

  waitForDownload = async (plotRequestPromises) => {
    const plotDownloadPromises = plotRequestPromises
      .filter((x) => !x.errorType && !x.url)
      .map(async (request) => {
        const plotDownloadResponse =
          await DataPlayerImageService.checkDownloadStatus(
            request,
            this.onError
          );
        return plotDownloadResponse;
      });

    this.setState({
      isRetrievingImages: true,
      fileTotal: plotDownloadPromises.length,
      fileCount: 0,
    });
    let count = 0;
    plotDownloadPromises.forEach(async (downloadPromise) => {
      await Promise.all([downloadPromise]).then(() => {
        count += 1;
        this.setState({
          fileCount: count,
        });
      });
    });
    return plotDownloadPromises;
  };

  putUpRetrievingSplashImages = (
    dateRanges,
    deviceCategoryCode,
    translateOffset,
    direction
  ) => {
    const splashImage = DataPlayerHelper.handleSplashImage(
      deviceCategoryCode,
      DATA_STATE.RETRIEVING
    );
    const { deviceId } = this.props;
    const splashFiles = dateRanges.map((dateRange) => ({
      url: splashImage,
      filename: RETRIEVING_SPLASH_FILENAME,
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
      key: dateRange.dateFrom,
      deviceId,
    }));
    const files = this.generateNewFilesArray(splashFiles);
    this.setFileStateAndCalculateTranslate(files, translateOffset, direction);
  };

  handleScrollComplete = (translate) => {
    const { files, widgetImageWidth } = this.state;
    const { isDashboard } = this.props;
    const widgetImageRightMargin =
      (-widgetImageWidth + WIDGET_IMAGE_CONTAINER_OFFSET) /
        WIDGET_IMAGE_ITEM_DIVISOR -
      WIDGET_IMAGE_ITEM_GAP;
    /* Indices denote the current image the user is on. Pushing/shifting occurs when the
    threshold image has hit the left edge of the scrolling container. */
    const index = Math.abs(Math.floor(translate / IMAGE_WIDTH));
    const widgetIndex = Math.abs(
      Math.floor(translate / (widgetImageWidth + widgetImageRightMargin))
    );
    /* Checks if we have hit the last image while scrolling, subtracts 1 to hit the rightmost image. */
    if (
      (!isDashboard && files.length - 1 - index < IMAGE_LOAD_BUFFER) ||
      (isDashboard && files.length - 1 - widgetIndex < IMAGE_LOAD_BUFFER)
    ) {
      this.pushFiles(translate);
    } else if (
      (index < IMAGE_LOAD_BUFFER && !isDashboard) ||
      (widgetIndex < IMAGE_LOAD_BUFFER && isDashboard)
    ) {
      this.setState({ translate });
      this.shiftFiles(translate);
    } else {
      this.setState({ translate });
    }
  };

  onError = (message) => {
    this.setState({
      isError: true,
      errorMessage: message,
    });
  };

  handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      // ignore clickaways for error snackbars on dataPlayer, so they persist for default 6 secs
      // the error snackbars contain information about data gaps
      return;
    }
    this.setState({
      isError: false,
    });
  };

  setStateForMissingData = () => {
    // turn off isMissingData flag and raise error flag
    this.setState({
      isMissingData: false,
    });
    this.onError(DataPlayerHelper.formatMissingImageTimes());
    DataPlayerHelper.clearMissingTimes();
  };

  getWidthOnImageLoad = () => {
    const { isDashboard } = this.props;
    // Save the widget image widths to state after they are loaded in
    if (isDashboard) {
      const spectragramElement = document.querySelector('#imageContainer-0');
      if (spectragramElement) {
        this.observeWidgetResize.observe(spectragramElement);
      }
    }
  };

  renderImages = () => {
    const { files, flacFile } = this.state;
    const {
      deviceCategoryCode,
      timeRange,
      deviceId,
      isDashboard,
      dateSelectorValue,
      isCommunicating,
    } = this.props;

    const appToken = Environment.getCurrentApplicationToken();
    const archiveFilesUrl = `${Environment.getDmasUrl()}/api/archivefiles?method=getFile&appToken=${appToken}&filename=`;
    return files
      .filter((file) => !file.deviceId || file.deviceId === deviceId)
      .map((file) => (
        <DataPlayerImage
          dateSelectorValue={dateSelectorValue}
          flacFile={flacFile}
          index={files.indexOf(file)}
          isCommunicating={isCommunicating}
          ref={this.imgRef}
          src={file.url ? file.url : archiveFilesUrl + file.filename}
          dateFrom={moment(file.dateFrom)}
          dateTo={moment(file.dateTo)}
          deviceCategoryCode={deviceCategoryCode}
          timeRange={timeRange}
          isDashboard={isDashboard}
          onLoad={this.getWidthOnImageLoad}
        />
      ));
  };

  getMomentByTranslate = () => {
    const {
      translate,
      initialTranslate,
      initialWindowSize,
      isRetrievingImages,
      fileTotal,
    } = this.state;
    const { startDate, isDashboard } = this.props;
    const windowDifference = (initialWindowSize - window.innerWidth * 0.96) / 2;
    const diff = translate - initialTranslate - windowDifference;
    const totalSecondsByScrolling = diff / this.getPixelPerSecond();
    const displayDate = startDate.clone().utc();
    displayDate.add(totalSecondsByScrolling, 'seconds');

    // Use the updated date when scrolling images OR when expanded by width without scrolling.
    if (
      isDashboard &&
      !isRetrievingImages &&
      !fileTotal &&
      ((totalSecondsByScrolling &&
        // Avoid case where translate is shifted slightly on shrinking to default size (small negative #)
        (totalSecondsByScrolling < -1 || totalSecondsByScrolling > 0) &&
        // Trigger upon scrolling width and/or height is expanded
        totalSecondsByScrolling !== this.previousScrollValue &&
        this.widgetHeight >= this.widgetInitialHeight) ||
        // OR if the widget has expanded horizontally
        this.widgetWidth > this.widgetInitialWidth)
    ) {
      displayDate.add(this.secondsFromPreviousDate, 'seconds');
    }
    return displayDate;
  };

  getPixelPerSecond = () => {
    const { timeRange, isDashboard } = this.props;
    const { widgetImageWidth } = this.state;
    const widgetImageRightMargin =
      (-widgetImageWidth + WIDGET_IMAGE_CONTAINER_OFFSET) /
        WIDGET_IMAGE_ITEM_DIVISOR -
      WIDGET_IMAGE_ITEM_GAP;
    let imageWidth = IMAGE_WIDTH;
    if (isDashboard && widgetImageWidth !== 0) {
      imageWidth = widgetImageWidth + widgetImageRightMargin;
    }
    if (timeRange === DAILY_TIMERANGE) {
      return imageWidth / SECONDS_PER_DAY;
    }
    if (timeRange === WEEKLY_TIMERANGE) {
      return imageWidth / (SECONDS_PER_DAY * 7);
    }
    return imageWidth / SECONDS_PER_FIVE_MINUTES;
  };

  handleADCPToolboxSubmit = (values) => {
    const { deviceId, startDate, timeRange, deviceCategoryCode } = this.props;
    this.setState({
      adcpOptions: values,
    });
    this.initializeFiles(deviceId, startDate, deviceCategoryCode, timeRange);
  };

  renderTimeLine = () => {
    const {
      files,
      isRetrievingImages,
      isLoading,
      fileTotal,
      translate,
      initialTranslate,
    } = this.state;
    const { isDashboard, startDate } = this.props;
    if (!files || files.length < 1) return <div key="time-line" />;
    return (
      <SpectraTimeLine
        translate={translate}
        initialTranslate={initialTranslate}
        date={this.getMomentByTranslate()}
        ratio={this.getPixelPerSecond()}
        key="time-line"
        isDashboard={isDashboard}
        isRetrieving={isRetrievingImages}
        isLoading={isLoading}
        fileTotal={fileTotal}
        numFiles={files.length}
        updateSecondsFromPreviousDate={this.updateSecondsFromPreviousDate}
        updateWidgetDimensions={this.updateWidgetDimensions}
        startDate={startDate}
        changeDate={this.changeDate}
      />
    );
  };

  renderRedCenterLine = () => {
    const { files } = this.state;
    const { classes, isDashboard, dateSelectorValue } = this.props;
    const lineForWidget = isDashboard ? classes.lineForWidget : classes.line;
    if (dateSelectorValue === 'latest' || !files || files.length < 1)
      return <></>;
    return <div className={`${lineForWidget}`} key="red-line" id="red-line" />;
  };

  renderYAxis = () => {
    const { files } = this.state;
    const { deviceCategoryCode, timeRange, isDashboard } = this.props;
    if (!files || files.length < 1) return <div />;
    return (
      <SpectraAxis
        src={files[0].url}
        deviceCategoryCode={deviceCategoryCode}
        timeRange={timeRange}
        isDashboard={isDashboard}
      />
    );
  };

  renderSpectraScale = () => {
    const { files } = this.state;
    const { deviceCategoryCode, timeRange, isDashboard } = this.props;
    if (!files || files.length < 1) return <div />;
    return (
      <SpectraScale
        src={files[0].url}
        deviceCategoryCode={deviceCategoryCode}
        timeRange={timeRange}
        isDashboard={isDashboard}
      />
    );
  };

  renderSnackbars = () => {
    const {
      isLoading,
      isRetrievingImages,
      isError,
      errorMessage,
      isMissingData,
      fileCount,
      fileTotal,
    } = this.state;
    const { dateSelectorValue } = this.props;
    const hasFiles = fileCount > 0;
    const isDone = fileCount === fileTotal;
    let snackBarComp = <div />;
    if (isLoading) {
      if (!hasFiles) {
        snackBarComp = <LoadingSnackbar message="Requesting images" />;
      } else if (hasFiles && !isDone) {
        snackBarComp = (
          <LoadingSnackbar
            message={`Requesting image ${fileCount} out of ${fileTotal}`}
          />
        );
      } else {
        snackBarComp = <InfoSnackbar message="Finished requesting images" />;
      }
      return snackBarComp;
    }

    if (isRetrievingImages) {
      if (!hasFiles) {
        snackBarComp = (
          <LoadingSnackbar message="Starting to retrieve images" />
        );
      } else if (hasFiles && !isDone) {
        snackBarComp = (
          <LoadingSnackbar
            message={`Retrieved image ${fileCount} out of ${fileTotal}`}
          />
        );
      } else {
        snackBarComp = (
          <InfoSnackbar
            message="Finished retrieving images"
            onClose={() =>
              this.setState({
                isRetrievingImages: false,
                fileCount: 0,
                fileTotal: 0,
              })
            }
            onLoad={this.getWidthOnImageLoad()}
          />
        );
      }
      return snackBarComp;
    }
    if (isMissingData) {
      if (dateSelectorValue === 'latest') {
        snackBarComp = (
          <ErrorSnackbar message="Error occurred with request, or no data was found for this device." />
        );
        return snackBarComp;
      }
      this.setStateForMissingData();
    }

    if (isError) {
      return (
        <ErrorSnackbar message={errorMessage} onClose={this.handleClose} />
      );
    }
    return snackBarComp;
  };

  renderToolBox = () => {
    const {
      adcpNortekPresetOptions,
      adcpRdiPresetOptions,
      hydrophonePresetOptions,
      isRdiDevice,
      hydrophoneOptions,
      adcpOptions,
    } = this.state;
    const {
      toolBox,
      handleWidgetToolboxClose,
      onCloseTool,
      deviceCategoryCode,
      deviceId,
      resourceId,
      isDashboard,
    } = this.props;
    if (!toolBox) return null;
    // would prefer to pass in defaultSearchOptionsThisDevice but it will vary
    // in structure so instead assign defaultSearchOptionsThisDevice to
    // variable with matching proptype validation
    let adcpRdiOptionValues;
    let adcpNortekOptionValues;
    let hydrophoneOptionValues;
    if (ADCP_DEVICE_CATEGORY_CODES.includes(deviceCategoryCode)) {
      if (isRdiDevice === true) {
        adcpRdiOptionValues = adcpOptions || adcpRdiPresetOptions;
      } else {
        adcpNortekOptionValues = adcpOptions || adcpNortekPresetOptions;
      }
    } else {
      hydrophoneOptionValues = hydrophoneOptions || hydrophonePresetOptions;
    }
    return (
      <Toolbox
        onCloseClick={onCloseTool}
        deviceCategoryCode={deviceCategoryCode}
        deviceId={deviceId}
        onADCPFilterSubmit={this.handleADCPToolboxSubmit}
        onHydrophoneFilterSubmit={this.handleHydrophoneFilterSubmit}
        adcpNortekPresetOptions={adcpNortekOptionValues}
        adcpRdiPresetOptions={adcpRdiOptionValues}
        hydrophonePresetOptions={hydrophoneOptionValues}
        resourceId={resourceId}
        handleWidgetToolboxClose={handleWidgetToolboxClose}
        isDashboard={isDashboard}
      />
    );
  };

  render() {
    const { classes, isDashboard, dateSelectorValue } = this.props;
    const { translate } = this.state;
    let isDraggable = true;
    if (isDashboard && dateSelectorValue === 'latest') isDraggable = false;

    const isDashboardWidget = isDashboard ? classes.widgetRoot : classes.root;
    const containerForWidget = isDashboard
      ? classes.widgetPlayerContainer
      : classes.playerContainer;
    const wrapperForWidget = isDashboard
      ? classes.widgetWrapper
      : classes.playerWrapper;
    return (
      <div className={`${isDashboardWidget}`} id="dataplayer-root">
        {this.renderSnackbars()}
        {isDashboard ? null : this.renderYAxis()}
        <div className={`${wrapperForWidget}`}>
          {isDashboard && dateSelectorValue === 'dateRange' ? (
            this.renderYAxis()
          ) : (
            <></>
          )}
          <div className={`${containerForWidget}`} id="widgetplayer-container">
            <SizeMe monitorHeight>
              <DragScroll
                id="hydrophone-spectra"
                dateSelectorValue={dateSelectorValue}
                translate={translate}
                onScrollUpdate={
                  !(isDashboard && dateSelectorValue === 'latest')
                    ? this.handleScrollUpdate
                    : null
                }
                onScrollComplete={
                  !(isDashboard && dateSelectorValue === 'latest')
                    ? this.handleScrollComplete
                    : null
                }
                draggable={isDraggable}
                key="spectra-drag-scroll"
                isDashboard={isDashboard}
                isShift={this.shifting}
              >
                {this.renderImages()}
              </DragScroll>
              {isDashboard && dateSelectorValue === 'dateRange' ? (
                this.renderTimeLine()
              ) : (
                <></>
              )}
              {this.renderRedCenterLine()}
            </SizeMe>
          </div>
          {isDashboard && dateSelectorValue === 'dateRange'
            ? this.renderSpectraScale()
            : null}
        </div>
        {isDashboard ? null : this.renderSpectraScale()}
        {this.renderToolBox()}
      </div>
    );
  }
}

export default withStyles(styles)(DataPlayer);
