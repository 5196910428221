import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle } from 'base-components';
import {
  CancelButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormCheckbox from 'library/CompositeComponents/form/FormCheckbox';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';

const useStyles = makeStyles((theme) => ({
  formButtons: {
    marginTop: theme.spacing(1),
    float: 'right',
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
}));

export type NodeType = {
  children?: Array<any>;
  enabled?: boolean;
  modifyBy?: string;
  modifyDate?: string;
  multiQueueTreeNodeId?: number;
  multiQueueTreeNodeName?: string;
  multiQueueTypeId?: number;
  objectId?: number;
  primaryShorestation?: boolean;
  defaultQueue?: string;
};

type CreateShorestationForm = {
  jvmId: number;
  hostname: string;
  stationId: number;
  primary: boolean;
};

type ShorestationPostProps = {
  payload: string;
  type: string;
};

type CreateShorestationDialogProps = {
  closeDialog: () => void;
  refreshTree: () => void;
  showDialog: boolean;
  locationNode: NodeType;
  shorestationNode?: NodeType;
};

const CreateShorestationDialog = ({
  closeDialog,
  refreshTree,
  showDialog,
  locationNode,
  shorestationNode = undefined,
}: CreateShorestationDialogProps) => {
  const classes = useStyles();
  const { onError, onInfo } = useSnackbars();

  const formMethods = useForm<CreateShorestationForm>({
    mode: 'onBlur',
    values: {
      stationId: locationNode.objectId,
      jvmId: shorestationNode?.objectId || 0,
      hostname: shorestationNode?.multiQueueTreeNodeName || '',
      primary: shorestationNode?.primaryShorestation || false,
    },
  });

  const { mutate: saveShorestation } = usePost<ShorestationPostProps>(
    'MultiQueueService',
    {
      onSuccess: () => {
        onInfo('Shorestation saved!');
        refreshTree();
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    1
  );

  const handleSave = (values: CreateShorestationForm) => {
    const props = {
      stationId: locationNode.objectId,
      jvmId: shorestationNode?.objectId || 0,
      hostname: values.hostname,
      primary: values.primary,
    };
    saveShorestation({ type: 'jvm', payload: JSON.stringify(props) });
    closeDialog();
  };

  return (
    <Dialog open={showDialog} onClose={closeDialog} fullWidth>
      <DialogTitle>Create Shorestation</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSave} formMethods={formMethods}>
          <FormTextField
            className={classes.textField}
            fullWidth
            disabled
            translationKey="common.textfields.variable"
            translationOptions={{
              variableName: locationNode.multiQueueTreeNodeName,
            }}
            name="stationId"
          />
          <FormTextField
            className={classes.textField}
            translationKey="multiqueue.jvmId"
            name="jvmId"
            fullWidth
            disabled
            variant="filled"
            rules={{ required: 'A JVM ID is required' }}
          />
          <FormTextField
            className={classes.textField}
            translationKey="multiqueue.hostName"
            name="hostname"
            fullWidth
            variant="filled"
            rules={{ required: 'A hostname is required' }}
          />
          <FormCheckbox name="primary" label="Make Primary Shorestation" />
          <div className={classes.formButtons}>
            <CancelButton onClick={closeDialog} />
            <SaveButton type="submit" />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateShorestationDialog;
