import Environment from 'util/Environment';
import useFetch from 'util/hooks/useFetch';
import { get } from 'util/WebRequest';

const GET_LIST_BY_LOCATION = 'getListByLocation';
const GET_LIST_BY_DEVICE = 'getListByDevice';

type Citation = {
  citation: string;
  doi: string;
  landingPageUrl: string;
  queryPid: number | null;
};

export type ArchiveFile = {
  archiveLocation: string;
  archivedDate: string;
  filename: string;
  compression: string | null;
  dataProductCode: string;
  dateFrom: string;
  dateTo: string;
  deviceCode: string;
  fileSize: number;
  modifyDate: string;
  path: string;
  uncompressedSize: number;
};

type ArchiveFileByLocationParams = {
  method: string;
  locationCode: string;
  returnOptions?: string;
  deviceCategoryCode?: string;
  dateFrom: string;
  dateTo: string;
  dataProductCode: string;
  rowLimit?: number;
  token: string;
};

export type ArchiveFilePayload = {
  citations: Citation[];
  files: ArchiveFile[];
  messages: [];
  next: string | null;
  queryUrl: string;
  returnOption: string;
};

export const useArchiveFileByLocation = (params) =>
  useFetch<ArchiveFilePayload, ArchiveFileByLocationParams>(
    'api/archivefiles',
    { queryKey: ['archivefiles'], enabled: false },

    { params }
  );

class ArchiveFileService {
  static async getFileListByLocation(
    locationCode,
    deviceCategoryCode,
    dateFrom,
    dateTo,
    fileExtension,
    rowLimit,
    getLatest
  ) {
    const params = {
      method: GET_LIST_BY_LOCATION,
      deviceCategoryCode,
      locationCode,
      dateFrom,
      dateTo,
      fileExtension,
      returnOptions: 'all',
      rowLimit,
      getLatest: getLatest || false,
    };
    return get('api/archivefiles', params);
  }

  static async getFileListByDevice(
    deviceCode,
    dateFrom,
    dateTo,
    fileExtension,
    filename = undefined,
    dataProductCode = undefined,
    rowLimit = undefined,
    getLatest = undefined
  ) {
    const params = {
      method: GET_LIST_BY_DEVICE,
      deviceCode,
      dateFrom,
      dateTo,
      fileExtension,
      filename,
      dataProductCode,
      returnOptions: 'all',
      rowLimit,
      getLatest: getLatest || false,
    };
    return get('api/archivefiles', params);
  }

  static generateArchiveFileUrl = (file: ArchiveFile) =>
    `${Environment.getMediaBaseUrl()}${file.archiveLocation}/${file.path}/${file.filename}/playlist.m3u8`;
}

export default ArchiveFileService;
