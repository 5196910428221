import { FC } from 'react';
import Plotly from 'plotly.js-gl2d-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import { Earthquake } from '../EarthquakeData';

interface LatitudeOverTimeProps {
  earthquakes: Earthquake[];
}

const Plot = createPlotlyComponent(Plotly);

const LatitudeOverTime: FC<LatitudeOverTimeProps> = ({ earthquakes }) => {
  if (!earthquakes) {
    return <></>;
  }
  return (
    <Plot
      data={[
        {
          x: earthquakes.map((earthquake) => earthquake.originTime),
          y: earthquakes.map((earthquake) => earthquake.latitude),
          type: 'scattergl',
          mode: 'markers',
          name: 'Earthquake',
        },
      ]}
      layout={{
        autosize: true,
        xaxis: {
          title: 'Time',
        },
        yaxis: {
          title: 'Latitude',
        },
      }}
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default LatitudeOverTime;
