import _ from 'lodash';
import moment from 'moment';

class ObjectUtils {
  /**
   * Clones an object so that object is unique and not just a reference.
   *
   * @param {object} objectToClone Object The object you want to deep clone
   * @returns {object}
   */
  static deepClone = (objectToClone: object) =>
    JSON.parse(JSON.stringify(objectToClone));

  static unpackToLabelValueKey = (valueToUnpack: object) => {
    const keys = Object.keys(valueToUnpack);
    const values = Object.values(valueToUnpack);

    return keys.map((k, i) => ({
      label: values[i],
      value: k,
      key: values[i],
    }));
  };

  /**
   * Compares two objects and returns the fields that have changed.
   *
   * @param {object} original The original object
   * @param {object} updated The updated object
   * @returns {object} An object containing the fields that have changed
   */
  static getChangedFields<T>(original: T, updated: T): Partial<T> {
    const isObject = (obj: any) => obj && typeof obj === 'object';
    const isMoment = (obj: any) => moment.isMoment(obj);

    function compareObjects(innerOriginal: any, innerUpdated: any): any {
      const changedFields: any = {};
      Object.keys(innerUpdated).forEach((key) => {
        const originalValue = innerOriginal[key];
        const updatedValue = innerUpdated[key];

        if (isMoment(originalValue) && isMoment(updatedValue)) {
          if (!originalValue.isSame(updatedValue)) {
            changedFields[key] = updatedValue;
          }
        } else if (isObject(originalValue) && isObject(updatedValue)) {
          const nestedChanges = compareObjects(originalValue, updatedValue);
          if (Object.keys(nestedChanges).length > 0) {
            changedFields[key] = nestedChanges;
          }
        } else if (originalValue !== updatedValue) {
          changedFields[key] = updatedValue;
        }
      });
      return changedFields;
    }

    return compareObjects(original, updated);
  }

  /**
   * Does a deep comparison to determine object equality.
   *
   * @param {object} object1 The first object to compare
   * @param {object} object2 The second object to compare
   * @param {string[]} ignoredProps Properties to ignore when comparing
   * @returns {boolean} True if objects are equal, False otherwise.
   */
  static isEqual = (
    object1: object,
    object2: object,
    ignoredProps?: string[]
  ): boolean =>
    _.isEqual(
      _.omit(object1, ignoredProps || []),
      _.omit(object2, ignoredProps || [])
    );
}

export default ObjectUtils;
