import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import JWForward30 from 'assets/icon/jwplayer/JWForward30';
import JWNext from 'assets/icon/jwplayer/JWNext';
import JWReplay30 from 'assets/icon/jwplayer/JWReplay30';
import JWSettings from 'assets/icon/jwplayer/JWSettings';
import JWShare from 'assets/icon/jwplayer/JWShare';
import Share from 'library/CompositeComponents/share/Share';
import ContinuousVideo from 'library/CompositeComponents/video/ContinuousVideo';
import ContinuousVideoFiles from 'library/CompositeComponents/video/ContinuousVideoFiles';
import Snapshots from 'library/CompositeComponents/video/Snapshots';
import UTCTimestamp from 'library/CompositeComponents/video/UTCTimestamp';
import UTCTooltip from 'library/CompositeComponents/video/UTCTooltip';
import VideoPlayerMenu from 'library/CompositeComponents/video/VideoPlayerMenu';

const SHARE_TITLE = 'Check out this cool video!';

const styles = {
  '@global': {
    '& div[aria-label="More Videos"]': {
      display: 'none !important',
    },
    // Hide the default next button
    '& .jw-icon-next': {
      display: 'none !important',
    },
    '& div[aria-label="Next"]': {},
    '& div[aria-label="Previous"]': {
      '& svg': {
        transform: 'scale(-1,1) !important',
      },
    },
    '& div[aria-label="timestamp"]': {
      order: '11 !important',
    },
    '& div[aria-label="Share"]': {
      order: '12 !important',
    },
    '& div[aria-label="Start Recording"], div[aria-label="Stop Recording"]': {
      order: '13 !important',
    },
    '& div[aria-label="Stop Recording"]': {
      '& .jw-svg-icon': {
        fill: 'rgba(255,0,0,0.8) !important',
      },
      '&:hover': {
        '& .jw-svg-icon': {
          fill: 'rgba(255,0,0,1) !important',
        },
      },
    },
  },
};

class SeaTubePlaylistTemplate extends PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    menuProps: PropTypes.shape({
      anchorEl: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
      onClose: PropTypes.func.isRequired,
      open: PropTypes.bool.isRequired,
      captionProps: PropTypes.shape({}),
      qualityProps: PropTypes.shape({}),
      speedProps: PropTypes.shape({}),
    }).isRequired,
    playerId: PropTypes.string.isRequired,
    ready: PropTypes.bool.isRequired,
    videoProps: PropTypes.shape({
      onPlayerReady: PropTypes.func.isRequired,
      onTime: PropTypes.func.isRequired,
      speed: PropTypes.number.isRequired,
      captions: PropTypes.number,
      onCaptionListChange: PropTypes.func.isRequired,
    }).isRequired,
    files: PropTypes.instanceOf(ContinuousVideoFiles).isRequired,
    buttonProps: PropTypes.shape({
      onToggleMenuOpen: PropTypes.func,
      onSkipForward: PropTypes.func,
      onSkipBackward: PropTypes.func,
      onNext: PropTypes.func,
      onPrevious: PropTypes.func,
    }).isRequired,
    snapshotProps: PropTypes.shape({
      onSnapshot: PropTypes.func.isRequired,
      takeSnapshots: PropTypes.bool,
    }).isRequired,
    shareProps: PropTypes.shape({
      onShare: PropTypes.func,
    }).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    time: PropTypes.instanceOf(Date),
    isLoggedIn: PropTypes.bool,
    recording: PropTypes.bool,
    videoInformationProps: PropTypes.shape({
      onClick: PropTypes.func,
    }),
  };

  static defaultProps = {
    time: undefined,
    isLoggedIn: false,
    recording: false,
    videoInformationProps: {
      onClick: undefined,
    },
  };

  createButtons = () => {
    const { buttonProps, shareProps, ready } = this.props;

    if (!buttonProps || !ready) return undefined;
    const {
      onToggleMenuOpen,
      onSkipForward,
      onSkipBackward,
      onNext,
      onPrevious,
    } = buttonProps;
    const { onShare } = shareProps;
    const buttons = {
      add: [
        {
          img: JWSettings,
          tooltip: 'Settings',
          callback: onToggleMenuOpen,
          id: 'settings',
        },
        {
          img: JWForward30,
          tooltip: 'Forward 30 Seconds',
          callback: onSkipForward,
          id: 'forward-30',
        },
        {
          img: JWReplay30,
          tooltip: 'Back 30 Seconds',
          callback: onSkipBackward,
          id: 'backward-30',
        },
        {
          img: JWShare,
          tooltip: 'Share',
          callback: onShare,
          id: 'share',
        },
      ],
      remove: [],
    };

    if (onNext) {
      buttons.add.push({
        img: JWNext,
        tooltip: 'Next',
        callback: onNext,
        id: 'next',
      });
    }

    if (onPrevious) {
      buttons.add.push({
        img: JWNext,
        tooltip: 'Previous',
        callback: onPrevious,
        id: 'previous',
      });
    }
    return buttons;
  };

  createMenuOption = () => {
    const { isLoggedIn, snapshotProps, videoInformationProps } = this.props;
    const options = [];
    if (isLoggedIn && snapshotProps) {
      options.push({
        key: 'snapshots',
        label: 'Take Snapshots',
        onClick: snapshotProps.onSnapshot,
      });
    }
    if (videoInformationProps) {
      options.push({
        key: 'videoInformation',
        label: 'Video Information',
        onClick: videoInformationProps.onClick,
      });
    }

    return options;
  };

  renderMenu = () => {
    const { menuProps, playerId, ready } = this.props;
    const { anchorEl, onClose, open, captionProps, speedProps } = menuProps;
    if (!ready) return undefined;
    return (
      <VideoPlayerMenu
        playerId={playerId}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        speedProps={{ ...speedProps, speedOptions: [0.5, 1, 1.5, 2, 4] }}
        captionProps={captionProps}
        menuOptions={this.createMenuOption()}
        qualityProps={menuProps.qualityProps}
      />
    );
  };

  renderTimestamp = () => {
    const { playerId, ready, time } = this.props;
    if (!ready || !time) return undefined;
    return <UTCTimestamp playerId={playerId} time={time} />;
  };

  renderTooltip = () => {
    const { playerId, ready, files } = this.props;
    if (!ready) return undefined;
    return <UTCTooltip playerId={playerId} files={files} />;
  };

  renderShare = () => {
    const { shareProps, ready } = this.props;
    if (!ready) return undefined;
    return <Share {...shareProps} title={SHARE_TITLE} />;
  };

  renderSnapshots = () => {
    const { isLoggedIn, ready, snapshotProps, time, onError, onInfo } =
      this.props;
    if (
      !ready ||
      !isLoggedIn ||
      !snapshotProps ||
      !snapshotProps?.takeSnapshots
    )
      return undefined;
    return (
      <Snapshots
        {...snapshotProps}
        time={time}
        onError={onError}
        onInfo={onInfo}
      />
    );
  };

  render() {
    const { classes, files, playerId, videoProps } = this.props;
    const {
      onPlayerReady,
      onTime,
      speed,
      captions,
      onCaptionListChange,
      ...rest
    } = videoProps;
    return (
      <ContinuousVideo
        className={classes.buttons}
        autoPlay
        playerId={playerId}
        files={files}
        onPlayerReady={onPlayerReady}
        onTime={onTime}
        buttons={this.createButtons()}
        speed={speed}
        captions={captions}
        onCaptionListChange={onCaptionListChange}
        {...rest}
      >
        {this.renderMenu()}
        {this.renderTimestamp()}
        {this.renderTooltip()}
        {this.renderSnapshots()}
        {this.renderShare()}
      </ContinuousVideo>
    );
  }
}

export default withStyles(styles)(SeaTubePlaylistTemplate);
