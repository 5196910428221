import React, { useEffect, useState } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TaxonAttributeService, {
  MatrixAttribute,
} from 'domain/services/TaxonAttributeService';
import { TaxonomyItem, TaxonItem } from 'domain/Widgets/TaxonomyTreeWidget';
import StatelessTable from 'library/BaseComponents/table/StatelessTable';
import useWebService from 'util/hooks/useWebService';

type Props = {
  taxonomy: TaxonomyItem;
  taxon: TaxonItem;
  onError: (message: string) => void;
};

const DefaultAttributeTable = ({ taxonomy, taxon, onError }: Props) => {
  const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const colWidths: Table.ColumnExtension[] = [
    { columnName: 'group', width: 'auto' },
    { columnName: 'name', width: 'auto' },
    { columnName: 'value', width: 'auto', wordWrapEnabled: true },
  ];

  const [defaultAttributes, setDefaultAttributes] = useState<MatrixAttribute[]>(
    []
  );

  const [, , fetchAttributeList] = useWebService({
    method: TaxonAttributeService.getAttributes,
    onError,
  });

  useEffect(() => {
    fetchAttributeList(taxonomy.taxonomyId, taxon?.taxonId).then(
      (attrList) =>
        attrList !== null &&
        setDefaultAttributes(attrList.getCurrentAttributes(true))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxon, taxonomy]);

  const getTableRows = () =>
    defaultAttributes.map((attr) => {
      const { name, groupName, value } = attr;
      return {
        group: groupName,
        name,
        value: value === '' || value === null ? 'null' : value,
      };
    });

  return (
    <div id="default-attribute-table" className={classes.contentContainer}>
      {getTableRows().length !== 0 && (
        <StatelessTable
          title="Default Attributes"
          rows={getTableRows()}
          columns={[
            { name: 'group', title: 'Attribute Group' },
            { name: 'name', title: 'Attribute Name' },
            { name: 'value', title: 'Attribute Value' },
          ]}
          columnExtensions={colWidths}
          searchable
        />
      )}
    </div>
  );
};

export default DefaultAttributeTable;
