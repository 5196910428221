import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from 'base-components';

const useStyles = makeStyles({
  progress: {
    position: 'absolute',
    top: '50%',
    right: '50%',
  },
  progressContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
});

interface LoadingProps {
  stillLoading: boolean;
  classes?: any;
}

const withCircularProgress = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  type Props = P & LoadingProps;
  return (props: Props) => {
    const { stillLoading } = props;
    const classes = useStyles();
    if (stillLoading) {
      return (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      );
    }
    return <WrappedComponent {...(props as P)} />;
  };
};

export default withCircularProgress;
