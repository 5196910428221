import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Checkbox,
  DateTimePicker,
  Tooltip,
  FormControlLabel,
  Typography,
  TextField,
} from 'base-components';
import { ResolutionSelect } from 'domain/AppComponents/dropdowns/Dropdowns';
import DataProductDeliveryService from 'domain/services/DataProductDeliveryService';
import DataSearchService from 'domain/services/DataSearchService';
import SeaTubeVideoService from 'domain/services/SeaTubeVideoService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ExportProgress from './ExportProgress';
import SeaTubeExportButton from './SeaTubeExportButton';

const CHECK_INTERVAL = 5000;

const styles = (theme) => ({
  containerField: {
    width: '48.5%',
    marginLeft: theme.spacing(),
  },
  dateTimePickerContainer: {
    paddingTop: theme.spacing(),
  },
  exportContainer: {
    width: '99%',
    textAlign: 'end',
    paddingTop: theme.spacing(),
  },
  flexContainer: {
    display: 'flex',
  },
  header: {
    marginLeft: theme.spacing() * 1.5,
    paddingTop: theme.spacing(),
  },
  progress: {
    display: 'inline-block',
  },
  resolutionField: {
    width: '97.75%',
    marginLeft: theme.spacing(),
  },
  textField: {
    marginTop: theme.spacing(),
  },
});

class SeaTubeStillImageExportForm extends PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      containerField: PropTypes.string,
      dateTimePickerContainer: PropTypes.string,
      exportContainer: PropTypes.string,
      flexContainer: PropTypes.string,
      header: PropTypes.string,
      progress: PropTypes.string,
      resolutionField: PropTypes.string,
      textField: PropTypes.string,
    }).isRequired,
    isLoggedIn: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    diveList: PropTypes.arrayOf(PropTypes.any),
  };

  static defaultProps = {
    isLoggedIn: undefined,
    diveList: [],
  };

  state = {
    startDate: null,
    endDate: null,
    fixedTimeInterval: 30,
    burstSize: 1,
    resolution: undefined,
    includeSeaTubeLinks: false,
    availableResolutions: [],
    missingDives: undefined,
  };

  /* LIFECYCLE METHODS */
  componentDidMount() {
    this.getCommonResolutions();
  }

  componentDidUpdate(prevProps) {
    const { diveList } = this.props;

    if (prevProps.diveList !== diveList) {
      this.getCommonResolutions();
    }
  }
  /* ---------------- */

  getCommonResolutions = async () => {
    const { diveList, onError } = this.props;

    if (diveList.length === 0) {
      this.setState({
        availableResolutions: [],
        missingDives: undefined,
      });
      return;
    }

    let diveIdString;
    if (diveList.length === 1) {
      diveIdString = diveList[0].diveId.toString();
    } else {
      diveIdString = diveList.join();
    }

    try {
      const payload =
        await SeaTubeVideoService.getCommonResolutions(diveIdString);

      const { availableResolutions, missingDives } = payload;
      const missingDivesFinal =
        Object.keys(missingDives).length !== 0 ? missingDives : undefined;

      this.setState({
        availableResolutions,
        missingDives: missingDivesFinal,
      });
    } catch (error) {
      onError(error);
    }
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date.toDate() });
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date.toDate() });
  };

  handleParamChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleSeaTubeLinkChange = () => {
    this.setState((prevState) => ({
      includeSeaTubeLinks: !prevState.includeSeaTubeLinks,
    }));
  };

  determineExportButtonDisabled = () => {
    const {
      fixedTimeInterval,
      burstSize,
      resolution,
      availableResolutions,
      missingDives,
      exportStatus,
    } = this.state;

    // undefined case
    if (availableResolutions.length === 0 && !missingDives) {
      return true;
    }

    // if export is currently in progress
    if (
      exportStatus &&
      exportStatus !== DataProductDeliveryService.Statuses.COMPLETE
    ) {
      return true;
    }

    // if all required form fields aren't properly filled in
    return (
      fixedTimeInterval <= 0 || burstSize < 1 || burstSize > 9 || !resolution
    );
  };

  handleExportSubmit = async () => {
    const { diveList, onError } = this.props;
    const {
      startDate,
      endDate,
      fixedTimeInterval,
      burstSize,
      resolution,
      includeSeaTubeLinks,
      exportStatus,
    } = this.state;

    if (
      !exportStatus ||
      exportStatus === DataProductDeliveryService.Statuses.COMPLETE
    ) {
      let diveIdString;
      let finalDateFrom;
      let finalDateTo;
      if (diveList.length === 1) {
        diveIdString = diveList[0].diveId.toString();
        finalDateFrom = startDate || diveList[0].dateFrom;
        finalDateTo = endDate || diveList[0].dateTo;
      } else {
        diveIdString = diveList.join();
        finalDateFrom = new Date();
        finalDateTo = new Date();
      }

      const requestParams = {
        diveIds: diveIdString,
        dateFrom: finalDateFrom,
        dateTo: finalDateTo,
        timeBetweenSnapshots: fixedTimeInterval,
        snapshotsPerAnnotation: burstSize,
        resolution,
        includeSeaTubeLinks: includeSeaTubeLinks ? 1 : 0,
      };

      try {
        await DataProductDeliveryService.requestStillImageExport(
          requestParams
        ).then(async (dpRequestId) => {
          this.searchHdrId = dpRequestId;

          await DataProductDeliveryService.runDataProductSearch(
            dpRequestId
          ).then((runResponse) => {
            const { dpRunId: searchId, status: latestExportStatus } =
              runResponse;
            if (
              latestExportStatus !==
              DataProductDeliveryService.Statuses.COMPLETE
            ) {
              this.checkStatus();
            }

            this.setState({ searchId, exportStatus: latestExportStatus });
          });
        });
      } catch (error) {
        onError(error);
      }
    }
  };

  checkStatus = async () => {
    const { onError } = this.props;

    try {
      const results = await DataSearchService.checkSearchStatus(
        this.searchHdrId
      );

      if (results) {
        const { status, filename } = results;

        if (status > 1) {
          this.setState({
            exportStatus: DataProductDeliveryService.Statuses.COMPLETE,
            fileName: filename,
          });
        } else {
          setTimeout(() => this.checkStatus(), CHECK_INTERVAL);
          this.setState({
            exportStatus:
              DataProductDeliveryService.Statuses.DATA_PRODUCT_RUNNING,
          });
        }
      } else {
        setTimeout(() => this.checkStatus(), CHECK_INTERVAL);
        this.setState({
          exportStatus: DataProductDeliveryService.Statuses.QUEUED,
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  renderDateFields = () => {
    const { classes, diveList } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <Tooltip
        title="Dates are only available when a single dive is selected."
        disableHoverListener={diveList.length === 1}
        disableFocusListener
        disableTouchListener
        placement="top"
      >
        <span>
          <div className={classes.dateTimePickerContainer}>
            <DateTimePicker
              translationKey="common.datepickers.startDate"
              value={moment.utc(startDate)}
              onChange={this.handleStartDateChange}
              disabled={diveList.length !== 1}
              fullWidth
            />
          </div>
          <div className={classes.dateTimePickerContainer}>
            <DateTimePicker
              translationKey="common.datepickers.endDate"
              value={moment.utc(endDate)}
              onChange={this.handleEndDateChange}
              disabled={diveList.length !== 1}
              fullWidth
            />
          </div>
        </span>
      </Tooltip>
    );
  };

  render() {
    const { classes, isLoggedIn } = this.props;
    const {
      exportStatus,
      fileName,
      searchId,
      fixedTimeInterval,
      burstSize,
      resolution,
      includeSeaTubeLinks,
      availableResolutions,
    } = this.state;

    return (
      <>
        <Typography className={classes.header} variant="h6">
          Still Image Export Options:
        </Typography>
        {this.renderDateFields()}
        <div className={classes.flexContainer}>
          <TextField
            className={`${classes.containerField} ${classes.textField}`}
            translationKey="seatube.fixedTimeInterval"
            type="number"
            value={fixedTimeInterval}
            onChange={this.handleParamChange('fixedTimeInterval')}
            error={fixedTimeInterval <= 0}
          />
          <TextField
            className={`${classes.containerField} ${classes.textField}`}
            translationKey="seatube.burstSize"
            type="number"
            value={burstSize}
            onChange={this.handleParamChange('burstSize')}
            error={burstSize < 1 || burstSize > 9}
          />
        </div>
        <div className={classes.flexContainer}>
          <Tooltip
            title="No common resolutions between current dives selected."
            disableHoverListener={availableResolutions.length > 0}
            disableFocusListener
            disableTouchListener
          >
            <span className={`${classes.resolutionField} ${classes.textField}`}>
              <ResolutionSelect
                value={resolution}
                options={availableResolutions.map((option) => ({
                  label: option.description,
                  value: option.code,
                }))}
                onChange={this.handleParamChange('resolution')}
                disabled={availableResolutions.length === 0}
              />
            </span>
          </Tooltip>
        </div>
        <div className={classes.header}>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeSeaTubeLinks}
                onChange={this.handleSeaTubeLinkChange}
              />
            }
            label="Include SeaTube Links"
          />
        </div>
        <div className={classes.exportContainer}>
          <div id="progress-area" className={classes.progress}>
            <ExportProgress
              status={exportStatus}
              fileName={fileName}
              searchId={searchId}
            />
          </div>
          <SeaTubeExportButton
            isLoggedIn={isLoggedIn}
            exportType="images"
            onClick={this.handleExportSubmit}
            disabled={this.determineExportButtonDisabled()}
          />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbars(SeaTubeStillImageExportForm));
