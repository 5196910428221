import { memo } from 'react';
import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Grid } from 'base-components';
import ChatLogErrorTable from 'domain/AppComponents/seatube/chat-log/ChatLogErrorTable';
import ChatLogList from 'domain/AppComponents/seatube/chat-log/ChatLogList';
import StepHeader from 'library/CompositeComponents/stepper/step-parts/StepHeader';
import CookieUtils from 'util/CookieUtils';
import Environment from 'util/Environment';
import { ChatLogMessage, ParsingErrorLine } from '../ChatLogIngestionStepper';

const styles = () =>
  createStyles({
    stepDiv: {
      margin: '25px 10px',
    },
    chatLog: {
      margin: '25px 0',
    },
  });

interface Props extends WithStyles<typeof styles> {
  messages?: ChatLogMessage[];
  errorLines?: ParsingErrorLine[];
}

const ChatLogPreviewStep: React.VFC<Props> = ({
  messages = [],
  errorLines = [],
  classes, // comes from WithStyles
}) => {
  const generateUserList = () => {
    let currentIndex = 0;
    const colorList = [
      '#a31a1a',
      '#b05f16',
      '#3c7a1a',
      '#149fa8',
      '#0d3795',
      '#a20a92',
    ];
    const userMap: { username: string; color: string }[] = [];
    messages.forEach((item) => {
      if (item.username) {
        if (
          !userMap.find(
            (user: { username: string }) => user.username === item.username
          )
        ) {
          if (currentIndex >= colorList.length) {
            currentIndex = 0;
          }
          userMap.push({
            username: item.username,
            color: colorList[currentIndex],
          });
          currentIndex += 1;
        }
      }
    });
    return userMap;
  };

  const renderDescription = () => {
    if (errorLines.length > 0) {
      return (
        'Errors were detected during the preliminary parse. The error messages' +
        ' with line numbers are listed below; please make the necessary changes' +
        ' if possible or contact the SeaTube team.'
      );
    }

    if (messages.length === 0) {
      return 'No messages could be parsed from the given file for the selected dive.  Please check the file for any formatting errors, and ensure the selected date, selected dive, and chat log line timestamps are in agreement.';
    }

    return 'No errors found during a preliminary parse. Below is a chat log preview:';
  };

  const handleUpdateWidgetConfig = (key: string, config: object) => {
    CookieUtils.saveCookie(`user-${key}-config`, config);
  };

  const renderMainContent = () => {
    if (errorLines.length > 0) {
      return <ChatLogErrorTable errorMessages={errorLines} />;
    }

    return (
      <ChatLogList
        name="chat-log"
        userType={Environment.getDmasUserPrivilege() ? 'user' : 'anonymous'}
        messages={messages}
        onMessageClick={() => {}}
        chatUserMap={generateUserList()}
        totalListItems={messages.length}
        listHeight={400}
        onUpdateConfig={handleUpdateWidgetConfig}
        isPreviewMode
        deletePermissions={false} // no point in deleting from the preview chat log
        onChatMessageDeleteClick={() => {}} // don't attempt to delete what doesn't exist
      />
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.stepDiv}>
        <StepHeader
          header={errorLines.length === 0 ? 'Preview' : 'Errors'}
          description={renderDescription()}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={10} className={classes.chatLog}>
        {renderMainContent()}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default withStyles(styles)(memo(ChatLogPreviewStep));
