import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import OrganizationService from 'domain/services/OrganizationService';

class OrganizationSelect extends Component {
  static propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    name: undefined,
    title: undefined,
    onBlur: undefined,
    onChange: undefined,
    helperText: undefined,
    className: undefined,
    value: undefined,
  };

  state = { options: [] };

  componentDidMount() {
    this.getOrganizations();
  }

  getOrganizations = async () => {
    const organizations = OrganizationService.getBroadSearchOrganizations();
    this.setState({ options: organizations });
  };

  render() {
    const {
      name,
      title,
      onBlur,
      onChange,
      helperText,
      className,
      value,
      ...rest
    } = this.props;
    const { options } = this.state;
    return (
      <Dropdown
        options={options}
        name={name}
        title={title}
        onBlur={onBlur}
        onChange={onChange}
        helperText={helperText}
        className={className}
        label="Organization"
        value={value}
        {...rest}
      />
    );
  }
}

export default OrganizationSelect;
