/* eslint-disable no-underscore-dangle */
/* Ignoring underscores because we are overriding Leaflet things that use them. */
import { FC, useContext, useEffect } from 'react';
import Environment from '@onc/environment';
import { ControlPosition } from 'leaflet';
import * as L from 'leaflet';
import { useMap } from 'react-leaflet';
import { Box } from 'base-components';
import Control from './Control';
import lassoIcon from './images/lasso-tool-icon.png';
import MapFAB from './MapFAB';

import 'leaflet-fullscreen';
import { MapFABControlProps } from './MapFABControl';
import { MaterialControlProps } from './MaterialControlProps';
import MapContext from '../MapContext';
import 'leaflet-lasso';

interface LassoControlProps
  extends MaterialControlProps,
    Omit<MapFABControlProps, 'onClick' | 'TooltipProps'> {
  /** Callback to use when lasso has been selected is entered or exited */
  onLassoEnd?: (isFullscreen: any) => void;
  /** Whether or not this is in the loading state */
  loading?: boolean;
}

const LassoControl: FC<LassoControlProps> = ({
  options = { position: 'topleft' as ControlPosition },
  loading = false,
  onLassoEnd = undefined,
  prepend = false,
  containerId = undefined,
  size = undefined,
  ...rest
}) => {
  const { mapId } = useContext(MapContext);
  const map = useMap();
  const authenticated = Environment.isUserLoggedIn();

  useEffect(() => {
    if (!map) {
      return;
    }
    map.on(
      'lasso.finished',
      (event: any) => {
        map.dragging.enable();
        onLassoEnd(event.layers);
      },
      [map]
    );
  });

  const handleLasso = () => {
    map.dragging.disable();
    const lasso = L.lasso(map, { intersect: true });

    lasso.enable();
  };

  return (
    <Control position={options.position} prepend={prepend}>
      <MapFAB
        TooltipProps={{
          title: authenticated ? 'Lasso Tool' : 'Log in to use the Lasso Tool',
        }}
        onClick={handleLasso}
        disabled={!authenticated || loading}
        color="primary"
        map={map}
        mapId={mapId}
        idTag="lasso-control"
        size={size}
        containerId={containerId}
        ignorePointerInAndOut
        {...rest}
      >
        {/* This created a 32 pixel image centered in a medium FAB button. This should really be converted into an SVG icon... */}
        <Box
          sx={{
            background: `url(${lassoIcon}) !important`,
            backgroundSize: '30px !important',
            backgroundRepeat: 'no-repeat !important',
            position: 'relative !important',
            top: '1px !important',
            left: ' 1px !important',
            height: '32px',
            width: '32px',
          }}
        />
      </MapFAB>
    </Control>
  );
};

export default LassoControl;
