import {
  type LayerProps,
  createElementObject,
  createTileLayerComponent,
  updateGridLayer,
  withPane,
} from '@react-leaflet/core';
import { type TileLayerOptions, TileLayer as LeafletTileLayer } from 'leaflet';
import BingLayer from './LeafletBingLayer';

interface TileLayerProps extends TileLayerOptions, LayerProps {
  bingkey: string;
}
/** Create a TileLayer using Bing as a provider */
const BingTileLayer = createTileLayerComponent<
  LeafletTileLayer,
  TileLayerProps
>(
  ({ ...options }, context) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const layer = new BingLayer(withPane(options, context));
    return createElementObject(layer, context);
  },
  (layer, props, prevProps) => {
    updateGridLayer(layer, props, prevProps);
  }
);

export default BingTileLayer;
