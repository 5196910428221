import { Component } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from 'base-components';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class CruiseChipSelect extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    preselectedCruises: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
    ]),
    value: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    disabled: false,
    value: [],
    preselectedCruises: undefined,
  };

  state = {
    cruiseOptions: [],
  };

  componentDidMount() {
    this.getExpeditionOptionsList();
  }

  getExpeditionOptionsList = async () => {
    const { preselectedCruises } = this.props;
    const params = {
      operation: 11,
      onlyCruisesWithDives: true,
      dropdownOptions: true,
    };

    try {
      const cruiseOptions = await get('CruiseService', params).then(
        (response) => parseDmasAPIResponse(response).cruises
      );
      cruiseOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
      this.setState({ cruiseOptions }, () => {
        if (preselectedCruises) {
          this.handleCruiseChange(preselectedCruises);
        }
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  handleCruiseChange = (cruises) => {
    const { onError, onChange, name } = this.props;
    const { cruiseOptions } = this.state;
    const cruiseList = Array.isArray(cruises) ? cruises : [cruises];
    const filteredCruises = [];
    for (const cruise of cruiseList) {
      const selectedOption = cruiseOptions.find(
        (option) => option.value === cruise
      );

      if (selectedOption) {
        filteredCruises.push(selectedOption);
      } else {
        onError(new Error('Cruise option not found.'));
      }
    }
    if (filteredCruises.length > 0) {
      const event = { target: { name, value: filteredCruises } };
      onChange(event);
    } else {
      onChange({ target: { name, value: [] } });
    }
  };

  render() {
    const { id, onChange, value, disabled, name } = this.props;
    const { cruiseOptions } = this.state;

    return (
      <Autocomplete
        id={id}
        onChange={onChange}
        options={cruiseOptions}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, val) => option.value === val.value}
        translationKey="seatube.expedition"
        name={name}
        multiple
        fullWidth
        value={value}
        disabled={disabled}
      />
    );
  }
}

export default withSnackbars(CruiseChipSelect);
