import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import SiteDeviceService from 'domain/services/SiteDeviceService';
import type { DeploymentDataSource } from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import type { DeploymentResponse } from 'domain/hooks/useFilteredDeployments';

const useDeployments = (
  dataSources: DeploymentDataSource[],
  disabled: boolean = false
) => {
  // Extract device IDs in a render-safe way
  const deviceIds = useMemo(
    () => dataSources.map((dataSource) => dataSource.deviceId),
    [dataSources]
  );

  // queries will be a different object on each render.
  // TODO: when we upgrade to tanstack query v5 all of the following memos can be replaced by using the combine property
  const queries = useQueries({
    queries: deviceIds.map((deviceId) => ({
      queryKey: ['multipleDeployments', deviceId],
      queryFn: async (): Promise<DeploymentResponse[]> => {
        if (!disabled) {
          return SiteDeviceService.getDeployments(deviceId);
        }
        return [];
      },
    })),
  });

  const deploymentsData = useMemo(
    () => queries.flatMap((query) => query.data ?? []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queries)]
  );

  const isFetching = useMemo(
    () => queries.some((query) => query.isFetching),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queries)]
  );

  const errors = useMemo(
    () => queries.filter((query) => query.isError).map((query) => query.error),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(queries)]
  );

  const result = useMemo(
    () => ({
      deploymentsData,
      isFetching,
      queries,
      errors,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deploymentsData, isFetching, errors, JSON.stringify(queries)]
  );

  return result;
};

export default useDeployments;
