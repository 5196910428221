const ShowAllContentFormatter = ({ row, value, selected }: any) => {
  if (row && selected.includes(row.id)) {
    return (
      <div style={{ whiteSpace: 'normal', padding: '8px 0px' }}>{value}</div>
    );
  }
  return value;
};

export default ShowAllContentFormatter;
