import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Moment } from 'moment';
import AudioPlayerWidgetConfig from 'domain/AppComponents/Dashboard/audio-widget/AudioPlayerWidgetConfig';
import AudioPlayerWidgetDisplay from 'domain/AppComponents/Dashboard/audio-widget/AudioPlayerWidgetDisplay';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import Environment from 'util/Environment';
import useBroadcast from 'util/hooks/useBroadcast';

const useStyles = makeStyles(() =>
  createStyles({
    contentDiv: {},
    circularProgress: {},
  })
);

type AudioPlayerWidgetProps = {
  audioFormat: string;
  isBroadcasting: boolean;
  showDisplay: boolean;
  widgetId: string;
  classes: {
    circularProgress: string;
    contentDiv: string;
  };
  startDate: Moment;
  endDate: Moment;
  device: {
    dataRating: any[];
    deviceCategoryCode: string;
    deviceCode: string;
    deviceId: number;
    name: string;
  };
  showTitle: boolean;
  loopPlayback: boolean;
  autoRefresh: boolean;
  title: string;
  clipSelectorValue: 'timeRange' | 'latestClip';
  dataSourceType: 'device' | 'location';
  locations: [
    {
      stationCode: string;
      deviceCategoryCode: string;
    },
  ];
  onSave: (values: any) => void;
  onConfigFinish: () => void;
};

export type FlacFile =
  | {
      archivedDate: string;
      archiveLocation: string;
      compression: null | string;
      dataProductCode: string;
      dateFrom: string;
      dateTo: string;
      deviceCode: string;
      filename: string;
      fileSize: number;
      modifyDate: string;
      path: string;
      uncompressedFileSize: number;
    }
  | undefined;

const AudioPlayerWidget: React.FC<AudioPlayerWidgetProps> = (
  props: AudioPlayerWidgetProps
) => {
  const {
    onSave,
    showDisplay,
    startDate,
    isBroadcasting,
    endDate,
    showTitle,
    loopPlayback,
    autoRefresh,
    title,
    onConfigFinish,
    classes,
    clipSelectorValue,
    widgetId,
    device,
    dataSourceType,
    locations,
    audioFormat,
  } = props;

  const allClasses = { ...useStyles(), ...classes };
  const [flacData] = useBroadcast<FlacFile>(
    '0',
    BroadcastChannel.FlacData,
    undefined
  );
  const fileNameFormatter = (fileObject) =>
    `${fileObject.archiveLocation}/${fileObject.path}/${fileObject.filename}`;
  const latestSpectrogramFlac = flacData
    ? `${Environment.getADProxyUrl()}${fileNameFormatter(flacData)}`
    : null;

  const audioPlayerDisplay = (
    <AudioPlayerWidgetDisplay
      locations={locations}
      widgetId={widgetId}
      loopPlayback={loopPlayback}
      showTitle={showTitle}
      isBroadcasting={isBroadcasting}
      title={title}
      device={device}
      isAutoRefreshing={autoRefresh}
      clipSelectorValue={clipSelectorValue}
      startDate={startDate}
      dataSourceType={dataSourceType}
      endDate={endDate}
      classes={{
        contentDiv: allClasses.contentDiv,
        circularProgress: allClasses.circularProgress,
      }}
      audioFormat={audioFormat}
      flacData={flacData}
      latestSpectrogramFlac={latestSpectrogramFlac}
    />
  );
  const audioPlayerConfig = (
    <AudioPlayerWidgetConfig
      locations={locations}
      loopPlayback={loopPlayback}
      clipSelectorValue={clipSelectorValue}
      isBroadcasting={isBroadcasting}
      showTitle={showTitle}
      dataSourceType={dataSourceType}
      onSave={onSave}
      title={title}
      startDate={startDate}
      endDate={endDate}
      device={device}
      onCancel={onConfigFinish}
      audioFormat={audioFormat}
    />
  );

  if (showDisplay) {
    return audioPlayerDisplay;
  }
  return audioPlayerConfig;
};

export default AudioPlayerWidget;
