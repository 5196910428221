class DomUtil {
  /**
   * Calculates the height the given text will take up when the styles and
   * modifiers are applied.
   *
   * @param {string} text
   * @param {long} width
   * @param {object} config
   * @param {object} config.style The style to apply to the text
   * @param {string} config.id The id of the canvas element
   * @param {number} config.modifier The modifier to apply to the height
   */
  static calculateTextHeight(text, width, { style, id, modifier }) {
    let canvas = document.getElementById(id);
    if (!canvas) {
      canvas = document.createElement('div');
      canvas.id = id;
      Object.keys(style).forEach((key) => {
        canvas.style[key] = style[key];
      });
      canvas.style.position = 'absolute';
      canvas.style.visibility = 'hidden';
      canvas.style.height = 'auto';
      document.body.appendChild(canvas);
    }
    canvas.style.width = `${width}px`;
    canvas.innerText = text;
    if (modifier) return canvas.clientHeight * modifier;
    return canvas.clientHeight;
  }
}

export default DomUtil;
