import { memo } from 'react';
import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from 'base-components';
import {
  CancelButton,
  ContainedButton,
} from 'library/CompositeComponents/button/Buttons';

interface Props {
  open: boolean;
  onIngestCancel: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onIngestConfirm: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChatLogConfirmationDialog: React.VFC<Props> = (props: Props) => {
  const { open, onIngestCancel, onIngestConfirm } = props;

  return (
    <Dialog open={open} onBackdropClick={() => {}} disableEscapeKeyDown>
      <DialogContent>
        <DialogContentText>Are you sure you want to ingest?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onIngestCancel} />
        <ContainedButton
          translationKey="seatube.ingest"
          onClick={onIngestConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};

export default memo(ChatLogConfirmationDialog);
