import MaterialTooltip, {
  TooltipProps as MaterialTooltipProps,
} from '@mui/material/Tooltip';

// Have you tried CompositeComponents/withTooltip?
/**
 * Wrapper class for Material UI's Tooltip. See
 * https://material-ui.com/api/tooltip/ for more information.
 */
interface TooltipProps extends MaterialTooltipProps {
  containerId?: string;
}
const Tooltip = ({ containerId = undefined, ...otherProps }: TooltipProps) => (
  <MaterialTooltip
    PopperProps={{ container: document.querySelector(`#${containerId}`) }}
    {...otherProps}
  />
);
export default Tooltip;
