import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SavePlaylistDialog from 'domain/AppComponents/playlist/SavePlaylistDialog';
import ContinuousVideoFiles from './ContinuousVideoFiles';

const DEVICE_DATA = 1000;

class Recording extends PureComponent {
  static propTypes = {
    files: PropTypes.instanceOf(ContinuousVideoFiles).isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    recording: PropTypes.bool.isRequired,
    play: PropTypes.func,
    pause: PropTypes.func,
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    play: () => {},
    pause: () => {},
    startTime: null,
    endTime: null,
  };

  state = {
    line: null,
    open: false,
  };

  componentDidUpdate(prevProps) {
    const { recording, startTime, endTime, onError, onInfo } = this.props;
    // Recording started
    if (recording && !prevProps.recording) {
      onInfo('Video recording on');
    }

    // Recording ended
    if (!recording && prevProps.recording) {
      if (!startTime || !endTime) {
        onError('An error occured while recording');
      } else {
        onInfo('Video recording off');
        this.generatePlaylistLine();
      }
    }
  }

  generatePlaylistLine = () => {
    const { files, startTime, endTime, onError, pause } = this.props;

    if (startTime.getTime() === endTime.getTime()) {
      onError('Recordings must be at least 1 second long');
      return;
    }

    const startElapsedTime = files.getTimeElapsedAtDate(startTime);
    const endElapsedTime = files.getTimeElapsedAtDate(endTime);

    if (
      files.getDeviceIdAt(startElapsedTime) !==
      files.getDeviceIdAt(endElapsedTime)
    ) {
      onError('An error occured during recording');
      return;
    }

    pause();
    this.setState({
      open: true,
      line: {
        resourceId: files.getDeviceIdAt(startElapsedTime),
        resourceTypeId: DEVICE_DATA,
        startDate: startTime.toISOString(),
        endDate: endTime.toISOString(),
        quality: files.currentQuality,
      },
    });
  };

  handleClose = () => {
    const { onClose, play } = this.props;
    onClose();
    play();
    this.setState({ line: null, open: false });
  };

  render() {
    const { onError, onInfo } = this.props;
    const { line, open } = this.state;
    return (
      <SavePlaylistDialog
        open={open}
        onClose={this.handleClose}
        playlistLines={[line]}
        onInfo={onInfo}
        onError={onError}
      />
    );
  }
}

export default Recording;
