import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import UTCTimestamp from 'library/CompositeComponents/video/UTCTimestamp';
import Video from 'library/CompositeComponents/video/Video';

const styles = {
  live: {
    '& div[aria-label="timestamp"]': {
      order: 11,
    },
  },
};

class LiveVideoTemplate extends PureComponent {
  static propTypes = {
    playerId: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    time: PropTypes.instanceOf(Date).isRequired,
  };

  render() {
    const { classes, playerId, time, ...rest } = this.props;
    return (
      <Video autoPlay playerId={playerId} className={classes.live} {...rest}>
        <UTCTimestamp playerId={playerId} time={time} />
      </Video>
    );
  }
}

export default withStyles(styles)(LiveVideoTemplate);
