import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from 'base-components';
import {
  DeleteIconButton,
  EditIconButton,
} from 'domain/AppComponents/IconButtons';

type PlaylistCollectionListProps = {
  groups: string[];
  selectedGroup: string;

  onDeleteGroup: (group: string) => void;
  onEditGroup: (group: string) => void;
  onSelectGroup: (group: string) => void;
};

const PlaylistCollectionList: React.VFC<PlaylistCollectionListProps> = ({
  groups,
  selectedGroup,
  onDeleteGroup,
  onEditGroup,
  onSelectGroup,
}: PlaylistCollectionListProps) => {
  const isSelected = (groupName: string) => groupName === selectedGroup;

  const renderGroupListItems = () =>
    groups.map((groupName) => (
      <ListItem
        key={groupName}
        selected={isSelected(groupName)}
        onClick={() => onSelectGroup(groupName)}
        button
      >
        <ListItemText primary={groupName} />
        <ListItemSecondaryAction>
          <EditIconButton
            onClick={() => {
              onEditGroup(groupName);
            }}
          />
          <DeleteIconButton
            onClick={() => {
              onDeleteGroup(groupName);
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    ));

  return (
    <List>
      <ListItem
        key="all-playlists"
        selected={isSelected('All Playlists')}
        onClick={() => onSelectGroup('All Playlists')}
        button
      >
        <ListItemText primary="All Playlists" />
      </ListItem>
      <ListItem
        key="uncategorized-playlists"
        selected={isSelected('(Uncategorized)')}
        onClick={() => onSelectGroup('(Uncategorized)')}
        button
      >
        <ListItemText primary="(Uncategorized)" />
      </ListItem>
      {renderGroupListItems()}
    </List>
  );
};

export default PlaylistCollectionList;
