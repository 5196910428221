/* eslint-disable react-hooks/exhaustive-deps */
import { Component } from 'react';
import CruiseService from 'domain/services/CruiseService';
import DiveListingService, {
  DiveJSON,
} from 'domain/services/DiveListingService';
import TopologyService, {
  TopologyByDeviceParams,
  TopologyJSON,
} from 'domain/services/TopologyService';
import UserManagementService, {
  UserManagementJSON,
} from 'domain/services/UserManagementService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DiveForm from './DiveForm';
import DiveFormContext from './DiveFormContext';
import DiveFormUtil from './DiveFormUtil';

const DIVE_CHIEF_GROUP = 260;

interface ContainerProps {
  onError: (message: any, callback?: any) => void;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onInfo: (message: any, callback?: any) => void;
  initialCruiseId?: number;
  diveId: number;
}

class DiveFormContainer extends Component<ContainerProps, []> {
  static defaultProps = {
    initialCruiseId: undefined,
  };

  getCruises = async () => {
    const { onError } = this.props;
    return CruiseService.getCruises()
      .then((response) => {
        const sortedCruises = response.cruises.sort((a, b) => {
          const aName = a.cruiseName.toLowerCase();
          const bName = b.cruiseName.toLowerCase();

          if (aName < bName) return -1;
          if (bName < aName) return 1;
          return 0;
        });
        return sortedCruises;
      })
      .catch((error) => {
        onError(error.message);
        return [];
      });
  };

  getDiveChiefs = async (): Promise<UserManagementJSON[]> => {
    const { onError } = this.props;
    return UserManagementService.getUsersInGroup(DIVE_CHIEF_GROUP).catch(
      (error) => {
        onError(error.message);
        return [];
      }
    );
  };

  getDive = async (id: number): Promise<DiveJSON> => {
    const { onError } = this.props;
    return DiveListingService.getDive(id).catch((error) => {
      onError(error.message);
      return {};
    });
  };

  getTopologyForDevice = async (
    params: TopologyByDeviceParams
  ): Promise<TopologyJSON[]> => {
    const { onError } = this.props;

    return TopologyService.getTopologyForDevice(params).catch((error) => {
      onError(error.message);
      return [];
    });
  };

  handleSubmit = async (formData) => {
    const { onInfo, onError, onClose, onSubmit } = this.props;
    const parameters = DiveFormUtil.buildServiceParameters(formData);
    if (parameters.diveId) {
      return DiveListingService.updateDive(parameters)
        .then(() => {
          onInfo('Dive updated successfully');
          onSubmit();
          onClose();
        })
        .catch((error) => onError(error.message));
    }
    return DiveListingService.createDive(parameters)
      .then(() => {
        onInfo('Dive created successfully');
        onSubmit();
        onClose();
      })
      .catch((error) => {
        if (error.message) {
          return onError(error.message);
        }
        return onError('Failed to create dive');
      });
  };

  render() {
    const { open, onClose, initialCruiseId, diveId } = this.props;
    return (
      <DiveFormContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          getCruises: this.getCruises,
          getDiveChiefs: this.getDiveChiefs,
          getTopologyForDevice: this.getTopologyForDevice,
          getDive: this.getDive,
          onSubmit: this.handleSubmit,
        }}
      >
        <DiveForm
          open={open}
          onClose={onClose}
          initialCruiseId={initialCruiseId}
          diveId={diveId}
        />
      </DiveFormContext.Provider>
    );
  }
}

export default withSnackbars(DiveFormContainer);
