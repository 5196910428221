import * as React from 'react';
import { Typography } from 'base-components';
import { OutlinedButton } from '../button/Buttons';

interface FileSelectorProps {
  acceptedFileTypes: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  // use if the parent component already has the file and wants to show it by displaying the name
  initialFileName?: string;
}

interface FileSelectorState {
  fileName: string | null;
}

/**
 * A component that allows the user to choose a file, and displays the chosen
 * file's file name.
 */
class FileSelector extends React.PureComponent<
  FileSelectorProps,
  FileSelectorState
> {
  static defaultProps = {
    initialFileName: undefined,
  };

  constructor(props: FileSelectorProps) {
    super(props);
    this.state = {
      fileName: props.initialFileName || null,
    };
  }

  handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { onChange } = this.props;
    const { fileName } = this.state;

    // a way for a parent to get file information
    if (onChange) {
      onChange(event);
    }

    // get the file's name, or null if the file is null
    const chosenFile = event.target.files ? event.target.files[0] : undefined;
    this.setState({ fileName: chosenFile ? chosenFile.name : fileName });
  }

  render() {
    const { acceptedFileTypes } = this.props;
    const { fileName } = this.state;

    /* 
      TypeScript doesn't like MediumEmphasisButton with a component prop for some reason 
      (and component="label" is necessary to get the button to work, for some reason),
      so use a (custom-made for this file) MediumEmphasisChooseFileButton instead.
     */
    return (
      <Typography>
        <OutlinedButton
          translationKey="common.buttons.chooseFile"
          component="label"
        >
          <input
            type="file"
            onChange={(event) => this.handleOnChange(event)}
            accept={acceptedFileTypes}
            style={{ display: 'none' }} // or the html <input> will also show up
          />
        </OutlinedButton>
        {fileName || 'No file selected.'}
      </Typography>
    );
  }
}

export default FileSelector;
