import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class MultiQueueService {
  static getTree = async () =>
    get('MultiQueueService', {
      operation: 1,
    }).then((response) => parseDmasAPIResponse(response));

  static getQueueDevices = async (queueId) =>
    get('MultiQueueService', {
      operation: 2,
      queueId,
    }).then((response) => parseDmasAPIResponse(response));

  static saveShorestation = async (jvm) =>
    post('MultiQueueService', {
      operation: 1,
      type: 'jvm',
      payload: jvm,
    }).then((response) => parseDmasAPIResponse(response));

  static saveQueue = async (queue) =>
    post('MultiQueueService', {
      operation: 1,
      type: 'queue',
      payload: queue,
    }).then((response) => parseDmasAPIResponse(response));

  static saveQueueDevice = async (
    destinationQueueId,
    deviceId,
    deviceTypeId,
    forceMapping
  ) =>
    post('MultiQueueService', {
      operation: 1,
      type: 'queueDevice',
      destinationQueueId,
      deviceId,
      deviceTypeId,
      forceMapping,
    }).then((response) => parseDmasAPIResponse(response));

  static moveQueueDevice = async (
    destinationQueueId,
    sourceQueueId,
    queueDeviceIds,
    forceMapping
  ) =>
    post('MultiQueueService', {
      operation: 2,
      type: 'queueDevice',
      destinationQueueId,
      sourceQueueId,
      queueDeviceIds,
      forceMapping,
    }).then((response) => response);

  static deleteQueue = async (queueIds) =>
    post('MultiQueueService', {
      operation: 3,
      type: 'queue',
      queueIds,
    }).then((response) => parseDmasAPIResponse(response));

  static deleteShorestation = async (jvmIds) =>
    post('MultiQueueService', {
      operation: 3,
      type: 'jvm',
      jvmIds,
    }).then((response) => parseDmasAPIResponse(response));

  static configureMultiQueue = async (isEnabled, locationId) =>
    post('MultiQueueService', {
      operation: 2,
      type: 'configure',
      isEnabled,
      locationId,
    }).then((response) => parseDmasAPIResponse(response));
}

export default MultiQueueService;
