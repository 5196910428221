import { OncLayouts } from 'library/CompositeComponents/dashboard/DashboardTypes';

const onShipLayout: OncLayouts = {
  lg: [
    {
      w: 6,
      h: 17,
      x: 6,
      y: 3,
      i: 'annotation-list-1',
    },
    {
      w: 6,
      h: 17,
      x: 0,
      y: 3,
      i: 'manual-entry-1',
    },
    {
      w: 12,
      h: 7,
      x: 0,
      y: 0,
      i: 'button-set-1',
    },
  ],
  md: [
    {
      w: 5,
      h: 17,
      x: 5,
      y: 3,
      i: 'annotation-list-1',
    },
    {
      w: 5,
      h: 17,
      x: 0,
      y: 3,
      i: 'manual-entry-1',
    },
    {
      w: 10,
      h: 7,
      x: 0,
      y: 0,
      i: 'button-set-1',
    },
  ],
  sm: [
    {
      w: 8,
      h: 22,
      x: 0,
      y: 11,
      i: 'annotation-list-1',
    },
    {
      w: 8,
      h: 8,
      x: 0,
      y: 3,
      i: 'manual-entry-1',
    },
    {
      w: 8,
      h: 7,
      x: 0,
      y: 0,
      i: 'button-set-1',
    },
  ],
  xs: [
    {
      w: 6,
      h: 22,
      x: 0,
      y: 10,
      i: 'annotation-list-1',
    },
    {
      w: 6,
      h: 17,
      x: 0,
      y: 3,
      i: 'manual-entry-1',
    },
    {
      w: 6,
      h: 7,
      x: 0,
      y: 0,
      i: 'button-set-1',
    },
  ],
};

export default onShipLayout;
