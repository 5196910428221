import React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import ReactHtmlParser from 'react-html-parser';
import { WidgetTitle } from './Titles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textDisplay: {
      display: 'block',
      '& ins': {
        textDecoration: 'underline',
      },
      overflow: 'auto',
      paddingRight: theme.spacing(1),
    },
  })
);

type TextWidgetDisplayProps = {
  classes?: {
    contentDiv: string;
  };
  htmlValue: string;
  showTitle: boolean;
  title: string;
};

const TextWidgetDisplay: React.FC<TextWidgetDisplayProps> = ({
  classes = { contentDiv: undefined },
  htmlValue,
  showTitle,
  title,
}) => {
  const styles = useStyles();

  return (
    <>
      {showTitle ? <WidgetTitle titleText={title} /> : null}
      <div className={`${classes && classes.contentDiv} ${styles.textDisplay}`}>
        {ReactHtmlParser(htmlValue)}
      </div>
    </>
  );
};

export default TextWidgetDisplay;
