import { Component } from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  DateTimePicker,
  Collapse,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from 'base-components';
import {
  TreeTypeSelect,
  DatePresetSelect,
} from 'domain/AppComponents/dropdowns/Dropdowns';
import DepthRangeSlider from './DepthRangeSlider';

const styles = (theme) => ({
  filterItem: {
    marginTop: theme.spacing(1),
  },
  datePreset: {
    marginTop: theme.spacing(0.5),
  },
});

class DataSourceFilters extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      datePreset: PropTypes.string,
      filterItem: PropTypes.string,
    }).isRequired,
    treeType: PropTypes.oneOf([
      'instrumentsByLocation',
      'propertyByLocation',
      'instrumentByCategory',
    ]),
    treeTypePreset: PropTypes.oneOf([
      'instrumentsByLocation',
      'propertyByLocation',
      'instrumentByCategory',
    ]),
    propertyPreset: PropTypes.arrayOf(PropTypes.string),
    onDatePresetChange: PropTypes.func.isRequired,
    onChangeTreeType: PropTypes.func.isRequired,
    onEndDateChange: PropTypes.func.isRequired,
    onPropertiesChange: PropTypes.func,
    onStartDateChange: PropTypes.func.isRequired,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    datePreset: PropTypes.string,
    propertyOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    properties: PropTypes.arrayOf(PropTypes.string),
    onMinDepthFieldChange: PropTypes.func.isRequired,
    onMaxDepthFieldChange: PropTypes.func.isRequired,
    depthFrom: PropTypes.number.isRequired,
    depthTo: PropTypes.number.isRequired,
    onDateSelectionChange: PropTypes.func.isRequired,
    dateSelection: PropTypes.string.isRequired,
  };

  static defaultProps = {
    treeType: 'instrumentsByLocation',
    propertyPreset: [],
    startDate: undefined,
    endDate: undefined,
    datePreset: undefined,
    treeTypePreset: undefined,
    onPropertiesChange: undefined,
    properties: [],
  };

  state = {
    datePreset: undefined,
  };

  onSliderChange = (event, newValue) => {
    const { onMinDepthFieldChange, onMaxDepthFieldChange } = this.props;
    onMinDepthFieldChange(newValue[0]);
    onMaxDepthFieldChange(newValue[1]);
  };

  renderTreeTypeDropdown = () => {
    const { treeType, treeTypePreset, onChangeTreeType } = this.props;
    if (!treeTypePreset) {
      return (
        <Grid item>
          <TreeTypeSelect onChange={onChangeTreeType} value={treeType} />
        </Grid>
      );
    }
    return null;
  };

  renderPropertiesAutocomplete = () => {
    const {
      classes,
      onPropertiesChange,
      propertyPreset,
      propertyOptions,
      properties,
    } = this.props;
    if (propertyPreset.length === 0) {
      return (
        <Grid item>
          <Autocomplete
            multiple
            translationKey="dataSource.properties"
            fullWidth
            getOptionLabel={(option) => option}
            virtualized
            name="propertiesAutocomplete"
            className={classes.filterItem}
            options={propertyOptions}
            onChange={(event, value) => onPropertiesChange(value)}
            value={properties}
          />
        </Grid>
      );
    }
    return null;
  };

  renderDateFilters = () => {
    const {
      classes,
      onDatePresetChange,
      onEndDateChange,
      onStartDateChange,
      startDate,
      endDate,
      datePreset,
      dateSelection,
    } = this.props;
    return (
      <Collapse in={dateSelection === 'dateRange'}>
        <Grid
          className={classes.datePreset}
          data-test="date-preset-select"
          item
          xs={12}
        >
          <DatePresetSelect value={datePreset} onChange={onDatePresetChange} />
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DateTimePicker
              translationKey="common.datepickers.startDate"
              onChange={(date) => onStartDateChange(date.toDate())}
              value={moment(startDate)}
              fullWidth
            />
          </Grid>
          <Grid data-test="end-date-time-picker" item xs={6}>
            <DateTimePicker
              translationKey="common.datepickers.endDate"
              onChange={(date) => onEndDateChange(date.toDate())}
              value={moment(endDate)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Collapse>
    );
  };

  render() {
    const {
      classes,
      depthFrom,
      depthTo,
      onDateSelectionChange,
      dateSelection,
    } = this.props;

    return (
      <Grid container direction="column" spacing={1}>
        {this.renderTreeTypeDropdown()}
        {this.renderPropertiesAutocomplete()}
        <Grid item>
          <Typography
            fullWidth
            variant="caption"
            style={{ marginTop: '8px', display: 'block' }}
            gutterTop
            gutterBottom
          >
            Deployment Date Range
          </Typography>
          <ToggleButtonGroup
            exclusive
            onChange={(event, value) =>
              value ? onDateSelectionChange(value) : () => {}
            }
            value={dateSelection}
          >
            <ToggleButton value="all">All Available</ToggleButton>
            <ToggleButton value="current">Currently Deployed</ToggleButton>
            <ToggleButton value="dateRange">Custom</ToggleButton>
          </ToggleButtonGroup>
          {this.renderDateFilters()}
        </Grid>
        <Grid item>
          <div className={classes.filterItem}>
            <DepthRangeSlider
              onChangeCommitted={this.onSliderChange}
              depthRange={[depthFrom, depthTo]}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(DataSourceFilters);
