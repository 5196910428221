import { createRef, PureComponent } from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Info } from '@onc/icons';
import { ExpansionSubMenu, IconButton, Paper } from 'base-components';
import { GenericInfoDialog } from 'domain/AppComponents/dialogs/Dialogs';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import EarthquakeSiteDetailTable from './EarthquakeSiteDetailTable';

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
  },
  grid: {
    padding: theme.spacing(1),
  },
});

const oncHeaders = [
  { title: 'Hostname', name: 'hostname' },
  { title: 'Reported Time', name: 'reportedTime' },
  { title: 'Origin Time', name: 'originTime' },
  { title: 'Epicentre', name: 'epicentre' },
  { title: 'Epi Algorithm', name: 'epicentreUsed' },
  { title: 'Magnitude', name: 'magnitude' },
  { title: 'Mag Algorithm', name: 'magnitudeUsed' },
  { title: 'DGS', name: 'dgsEpicentre' },
  { title: 'DGS DTRMS', name: 'dgsDeltaTRMS' },
  { title: 'LLS', name: 'llsEpicentre' },
  { title: 'LLS QI', name: 'llsQualityIndicator' },
  { title: 'LLS CN', name: 'llsConditionNumber' },
  { title: 'LLS DTRMS', name: 'llsDeltaTRMS' },
  { title: 'P-wave Velocity', name: 'pWaveVelocity' },
  { title: 'Tau', name: 'tau' },
  { title: 'Seismic PD', name: 'seismicPd' },
  { title: 'Geodetic PD', name: 'geodeticPd' },
  { title: 'Geodetic PGD', name: 'geodeticPgd' },
];

const oncColumnSizeInfo = [
  { columnName: 'originTime', width: ColumnInfo.medium },
  { columnName: 'epicentre', width: ColumnInfo.mini },
  { columnName: 'epicentreUsed', width: ColumnInfo.mini },
  { columnName: 'magnitude', width: ColumnInfo.mini },
  { columnName: 'magnitudeUsed', width: ColumnInfo.mini },
  { columnName: 'reportedTime', width: ColumnInfo.mini },
  { columnName: 'hostname', width: ColumnInfo.mini },
  { columnName: 'dgsEpicentre', width: ColumnInfo.mini },
  { columnName: 'dgsDeltaTRMS', width: ColumnInfo.mini },
  { columnName: 'llsEpicentre', width: ColumnInfo.mini },
  { columnName: 'llsQualityIndicator', width: ColumnInfo.mini },
  { columnName: 'llsConditionNumber', width: ColumnInfo.mini },
  { columnName: 'llsDeltaTRMS', width: ColumnInfo.mini },
  { columnName: 'tau', width: ColumnInfo.mini },
  { columnName: 'seismicPd', width: ColumnInfo.mini },
  { columnName: 'geodeticPd', width: ColumnInfo.mini },
  { columnName: 'geodeticPgd', width: ColumnInfo.mini },
  { columnName: 'pWaveVelocity', width: ColumnInfo.mini },
];

const ToggleCellComponent = ({ row, ...restProps }) => (
  <TableRowDetail.ToggleCell row={row} {...restProps} />
);

ToggleCellComponent.propTypes = {
  row: PropTypes.objectOf().isRequired,
};

class EarthquakeDetectionDetailTable extends PureComponent {
  static propTypes = {
    earthquakeDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleDetailClick: PropTypes.func.isRequired,
  };

  formatGeodetic = (value, error) => {
    if (!value) {
      return '';
    }
    return `${value} ± ${error}`;
  };

  compareGeodetic = (a, b) => {
    const valueStrA = a.substring(0, a.indexOf('±') - 1).trim();
    const valueStrB = b.substring(0, b.indexOf('±') - 1).trim();
    if (valueStrA.length === 0 && valueStrB.length === 0) {
      return 0;
    }
    if (valueStrA.length === 0 && valueStrB.length > 0) {
      return -1;
    }
    if (valueStrA.length > 0 && valueStrB.length === 0) {
      return 1;
    }
    const geodeticA = parseFloat(valueStrA);
    const geodeticB = parseFloat(valueStrB);
    if (geodeticA < geodeticB) {
      return -1;
    }
    if (geodeticA > geodeticB) {
      return 1;
    }
    return 0;
  };

  constructor(props) {
    super(props);
    this.state = {
      openInfoDialog: false,
      detailWidth: 0,
      selection: [],
    };
    this.detailRef = createRef(null);
    this.tableRef = createRef(null);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener('resize', null);
  }

  handleOpenInfoDialog = (event) => {
    event.stopPropagation();
    this.setState({ openInfoDialog: true });
  };

  handleResize = () => {
    this.setState({
      detailWidth:
        this.detailRef.current.offsetWidth - this.tableRef.current.offsetLeft,
    });
  };

  /** Row Detail represents the collapsible table */
  renderRowDetail = ({ row }) => {
    this.setState({
      detailWidth:
        this.detailRef.current.offsetWidth - this.tableRef.current.offsetLeft,
    });
    return (
      // eslint-disable-next-line react/destructuring-assignment
      <Paper style={{ width: this.state.detailWidth }}>
        <EarthquakeSiteDetailTable earthquakeDetails={row.sites} />
      </Paper>
    );
  };

  getHiddenColumns = () => {
    const { earthquakeDetails } = this.props;
    let hidePPP = true;
    earthquakeDetails.forEach((row) => {
      if (row.geodeticPD || row.geodeticPGD) {
        hidePPP = false;
      }
    });
    if (hidePPP) {
      return ['geodeticPd', 'geodeticPgd'];
    }
    return [];
  };

  renderTableRows = () => {
    const { earthquakeDetails } = this.props;
    return earthquakeDetails.map((row) => ({
      ...row,
      epicentre: `${row.latitude},${row.longitude}`,
      originTime: DateFormatUtils.formatDate(row.originTime, 'milliseconds'),
      magnitude: row.magnitude,
      reportedTime: DateFormatUtils.formatDate(
        row.reportedTime,
        'milliseconds'
      ),
      hostname: row.hostname,
      llsEpicentre: `${row.llsLatitude},${row.llsLongitude}`,
      dgsEpicentre: `${row.dgsLatitude},${row.dgsLongitude}`,
      tau: row.tau,
      seismicPd: row.seismicPD,
      geodeticPd: this.formatGeodetic(row.geodeticPD, row.geodeticPDError),
      geodeticPgd: this.formatGeodetic(row.geodeticPGD, row.geodeticPGDError),
      pWaveVelocity: row.pWaveVelocity,
      sites: row.sites,
    }));
  };

  handleRowClick = (selectedRow) => {
    const { selection } = this.state;
    const { earthquakeDetails, handleDetailClick } = this.props;
    const newSelection = [];
    if (earthquakeDetails) {
      const index = earthquakeDetails
        .map((row) => row.oncEarthquakeDetailId)
        .indexOf(selectedRow.row.oncEarthquakeDetailId);
      if (index >= 0 && !selection.includes(index)) {
        newSelection.push(index);
      }
    }
    if (newSelection.length !== 0) {
      handleDetailClick(selectedRow.row);
    } else {
      handleDetailClick(undefined);
    }
    return this.setState({ selection: newSelection });
  };

  render() {
    const { openInfoDialog, selection } = this.state;
    return (
      <div ref={this.detailRef}>
        <GenericInfoDialog
          open={openInfoDialog}
          onClose={() => this.setState({ openInfoDialog: false })}
          title="ONC Detection Table Information"
          message="This table has one entry for each earthquake updated earthquake detection by the ONC associator.
           The associator updates its earthquake detection any time the magnitude, epicentre latitude,
            or epicentre longitude change by more than 0.01. The table includes the output of both the epicentre
            algorithms and all 4 magnitude algorithms. geodetic magnitudes are only included in the table if
            geodetic data contributed to the detection. Each detection has a drop down to show the sites that contributed 
            to the selected detection. This site sub table will show the site detections as they were at the time the detection
            was reported. The site table at the bottom of the page shows the final values for each site detection"
        />
        <ExpansionSubMenu
          menuTitle="ONC Detections"
          expand
          infoContent={
            <IconButton
              Icon={Info}
              onClick={this.handleOpenInfoDialog}
              aria-label="Filter Information"
            />
          }
        >
          <div ref={this.tableRef}>
            <SortableTable
              selection={selection}
              highlighted
              rowOnClick={this.handleRowClick}
              sortExtensions={[
                {
                  columnName: 'geodeticPd',
                  compare: this.compareGeodetic,
                },
                {
                  columnName: 'geodeticPgd',
                  compare: this.compareGeodetic,
                },
              ]}
              columnSizes={oncColumnSizeInfo}
              columns={oncHeaders}
              columnBands={[
                {
                  title: 'Magnitudes',
                  children: [
                    { columnName: 'tau' },
                    { columnName: 'seismicPd' },
                    { columnName: 'geodeticPd' },
                    { columnName: 'geodeticPgd' },
                  ],
                },
              ]}
              rows={this.renderTableRows()}
              hasDetail
              RowDetail={this.renderRowDetail}
              toggleCellComponent={ToggleCellComponent}
              hiddenColumns={this.getHiddenColumns()}
              pageSize={10}
              pageSizes={[10, 30, 60, 0]}
            />
          </div>
        </ExpansionSubMenu>
      </div>
    );
  }
}

export default withStyles(styles)(
  withSnackbars(EarthquakeDetectionDetailTable)
);
