import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { Toolbar, Typography } from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  EditIconButton,
  DeleteIconButton,
  RefreshButton,
} from 'domain/AppComponents/IconButtons';
import ItemService from 'domain/services/ItemService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';

import ItemConfig from './ItemConfig';

const headers = [
  { title: 'Item ID', name: 'itemId' },
  { title: 'Name', name: 'name' },
  { title: 'Type', name: 'type' },
  { title: 'Supplier', name: 'supplier' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: ' ', name: 'icons' },
];

const tableColumnExtensions = [
  {
    columnName: 'itemId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'name',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'type',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'supplier',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'icons',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class ItemsListingTab extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      addItemButton: PropTypes.string,
      grow: PropTypes.string,
      panelDiv: PropTypes.string,
    }).isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
    itemIdParam: PropTypes.number,
    hasChangedTabs: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    itemIdParam: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      openConfig: false,
      openDelete: false,
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { itemIdParam, hasChangedTabs } = this.props;
    await this.refreshTableData();

    if (itemIdParam) {
      const { tableRows } = this.state;
      this.moveItemToFront(itemIdParam);
      const row = tableRows.find((tableRow) => tableRow.itemId === itemIdParam);
      if (row !== undefined && !hasChangedTabs) {
        this.setState({ rowToBeEdited: row, openConfig: true });
      }
    }
  }

  moveItemToFront = (itemId) => {
    const { tableRows } = this.state;
    const row = tableRows.find((tableRow) => tableRow.itemId === itemId);
    const index = tableRows.indexOf(row);
    if (index !== -1) {
      tableRows.splice(index, 1);
      tableRows.unshift(row);
      this.setState({ tableRows: [...tableRows] });
    }
  };

  refreshTableData = async () => {
    const { onError } = this.props;
    this.setState({ isLoading: true });
    await ItemService.getMany()
      .then((result) => {
        this.buildTableRows(result);
      })
      .catch((result) => {
        onError(result);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  icons = (row) => {
    const { permission } = this.props;
    if (permission !== 'RW') {
      return null;
    }

    return (
      <>
        <EditIconButton
          onClick={() => {
            this.setState({
              openConfig: true,
              rowToBeEdited: row,
            });
          }}
          aria-label="Edit Item"
          size="small"
        />
        <DeleteIconButton
          onClick={() => {
            this.setState({
              openDelete: true,
              rowToBeDeleted: row,
            });
          }}
          aria-label="Delete Item"
          size="small"
        />
      </>
    );
  };

  buildTableRows = (items) => {
    this.setState({
      tableRows: items.map((data) => {
        const itemForRow = { ...data };
        itemForRow.modifyDate = DateFormatUtils.formatDate(
          data.modifyDate,
          'full'
        );
        itemForRow.itemId = data.itemId;
        itemForRow.name = data.description;
        itemForRow.modifyBy = `${data.modifyBy.firstname} ${data.modifyBy.lastname}`;
        itemForRow.type = data.itemType.name;
        itemForRow.typeId = data.itemType.itemTypeId;
        itemForRow.supplier = data.supplier.organizationName;
        itemForRow.supplierId = data.supplier.organizationId;
        itemForRow.icons = this.icons(itemForRow);
        return itemForRow;
      }),
    });
  };

  actionContent = () => {
    const { permission, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceActionRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refreshTableData} />
    );
    if (permission !== 'RW') {
      return <DeviceActionRefreshButton />;
    }
    return (
      <>
        <DeviceActionRefreshButton />
        <ContainedButton
          translationKey="cables.addItem"
          startIcon={<Add />}
          className={classes.addItemButton}
          onClick={() => {
            this.setState({ openConfig: true });
          }}
        />
      </>
    );
  };

  handleCancel = () =>
    this.setState({
      openConfig: false,
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
      openDelete: false,
    });

  addEventDialogue = () => {
    const { openConfig, rowToBeEdited } = this.state;
    const { permission } = this.props;
    if (!openConfig) {
      return null;
    }
    return (
      <ItemConfig
        permission={permission}
        title={rowToBeEdited ? 'Edit Item' : 'Add New Item'}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        item={rowToBeEdited}
        actionCategoryMap={this.actionCategoryMap}
      />
    );
  };

  deleteEventDialogue = () => {
    const { openDelete, rowToBeDeleted } = this.state;
    if (!openDelete) {
      return null;
    }
    return (
      <ConfirmationDialog
        open
        title="Delete Detail"
        content={`Are you sure you want to delete item ${rowToBeDeleted.itemId}?`}
        onCancel={this.handleCancel}
        onConfirm={this.handleDelete}
      />
    );
  };

  handleSave = (form) => {
    const { onInfo, onError, itemIdParam } = this.props;
    const paramsForPost = {
      itemDescription: form.itemName,
      typeId: form.itemType,
      supplierId: form.supplier,
    };

    if (form.itemId) {
      paramsForPost.itemId = form.itemId;
      ItemService.update(paramsForPost)
        .then(() => {
          this.setState({ openConfig: false, rowToBeEdited: undefined });
          onInfo('Item updated!');
          this.refreshTableData();
          if (itemIdParam) {
            this.moveItemToFront(itemIdParam);
          }
        })
        .catch((error) => onError(error));
    } else {
      ItemService.create(paramsForPost)
        .then(() => {
          this.setState({ openConfig: false, rowToBeEdited: undefined });
          onInfo('Item created!');
          this.refreshTableData();
          if (itemIdParam) {
            this.moveItemToFront(itemIdParam);
          }
        })
        .catch((error) => onError(error));
    }
  };

  handleDelete = async () => {
    const { onError, onInfo, itemIdParam } = this.props;
    const { rowToBeDeleted } = this.state;
    await ItemService.delete(rowToBeDeleted.itemId)
      .then(() => {
        this.setState({ openConfig: false, openDelete: false });
        onInfo('Deleted Successfully');
        this.refreshTableData();
        if (itemIdParam) {
          this.moveItemToFront(itemIdParam);
        }
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  render() {
    const { classes, permission } = this.props;
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    return (
      <div className={classes.panelDiv}>
        {this.addEventDialogue()}
        {this.deleteEventDialogue()}
        <Toolbar variant="dense" id="panelHeader">
          <Typography variant="h6">Item Listing</Typography>
          <div className={classes.grow} />
          {this.actionContent()}
        </Toolbar>
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={15}
          rows={tableRows}
          searchable
          searchBarMoveable={permission === 'RW'}
          stripedRows
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default ItemsListingTab;
