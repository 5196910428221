import { useState, useEffect } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import StatelessTable from 'library/BaseComponents/table/StatelessTable';

type Props = {
  jsonTaxonData: string;
};

const TaxonProperties = ({ jsonTaxonData }: Props) => {
  const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(0);
  const [expandSearchBar, setExpandSearchBar] = useState(true);
  // const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setCurrentPage(0);
    setExpandSearchBar(true);
  }, [jsonTaxonData]);

  const colWidths: Table.ColumnExtension[] = [
    { columnName: 'name', width: 'auto' },
    { columnName: 'value', width: 'auto', wordWrapEnabled: true },
  ];

  const getTableRows = () => {
    const parsedJsonData = JSON.parse(jsonTaxonData);
    return Object.entries(parsedJsonData)
      .map((rowData) => ({ name: rowData[0], value: rowData[1] }))
      .sort((propertyA, propertyB) =>
        propertyA.name.localeCompare(propertyB.name)
      );
  };

  return (
    <div id="taxon-properties-table" className={classes.contentContainer}>
      {getTableRows().length !== 0 && (
        <StatelessTable
          title="Properties"
          rows={getTableRows()}
          columns={[
            { name: 'name', title: 'Property Name' },
            { name: 'value', title: ' Property Value' },
          ]}
          columnExtensions={colWidths}
          searchable
          paging={{
            currentPage,
            onCurrentPageChange: (newPage) => setCurrentPage(newPage),
            pageSize: 3,
          }}
          expandSearchBar={expandSearchBar}
          onExpand={(condition: boolean) => setExpandSearchBar(condition)}
        />
      )}
    </div>
  );
};

export default TaxonProperties;
