/* eslint-disable react/prop-types */
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Moment } from 'moment';
import { Grid, ToggleButton, Typography, Collapse } from 'base-components';
import { ConfigDataSourceSelectorFormController } from 'domain/AppComponents/Dashboard/chart-widget/ChartWidgetFormControllers';
import ChartWidgetDataAvailability from 'domain/AppComponents/Dashboard/chart-widget/config/ChartWidgetDataAvailability';
import ChartWidgetDateFilters from 'domain/AppComponents/Dashboard/chart-widget/config/ChartWidgetDateFilters';
import ChartWidgetReadonlyDateFilters from 'domain/AppComponents/Dashboard/chart-widget/config/ChartWidgetReadonlyDateFilters';
import {
  LocationDataSource,
  DeploymentDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import { type ConfigFields } from 'library/CompositeComponents/dashboard/DashboardTypes';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';
import FormToggleButtonGroup from 'library/CompositeComponents/form/FormToggleButtonGroup';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: theme.spacing(80),
  },
}));

export type TimeSeriesScalarDataChartConfigType = {
  autoRefreshEnabled: boolean;
  chartTitle: string;
  datePreset: string;
  dateSelection: 'dateRange' | 'latest';
  isMinMaxEnabled: boolean;
  showHeader: boolean;
  isleftOverride?: boolean;
  isrightOverride?: boolean;
  isRawCleanEnabled?: boolean;
  showChartTitle: boolean;
  showWidgetTitle: boolean;
  widgetTitle: string;
  leftmax?: string;
  leftmin?: string;
  rightmin?: string;
  rightmax?: string;
  endDate?: Moment;
  startDate?: Moment;
  locationsLeftAxis?: Array<LocationDataSource | DeploymentDataSource>;
  locationsRightAxis?: Array<LocationDataSource | DeploymentDataSource>;
  displayQaqc: boolean;
  dateRangeIsInherited?: boolean;
};

const ChartWidgetConfigContent: ConfigFields<
  TimeSeriesScalarDataChartConfigType
> = ({ formMethods }) => {
  const classes = useStyles();
  const { watch } = formMethods;

  const {
    dateSelection,
    showHeader,
    showChartTitle,
    showWidgetTitle,
    locationsLeftAxis = [],
    locationsRightAxis = [],
    dateRangeIsInherited = false,
  } = watch();

  const numberOfDataSources =
    (locationsLeftAxis?.length ?? 0) + (locationsRightAxis?.length ?? 0);

  return (
    <Grid container spacing={1} className={classes.form}>
      <Grid item xs={6}>
        <FormToggle
          name="showWidgetTitle"
          label={showHeader ? 'Show Widget Header' : 'Show Widget Title'}
        />
      </Grid>
      <Grid item xs={6}>
        <FormToggle name="showChartTitle" label="Show Chart Title" />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showWidgetTitle}>
          <FormTextField
            name="widgetTitle"
            translationKey="common.textfields.title"
            fullWidth
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showChartTitle}>
          <FormTextField
            name="chartTitle"
            translationKey="common.textfields.title"
            fullWidth
          />
        </Collapse>
      </Grid>
      <Grid item xs={6}>
        <FormToggle name="isMinMaxEnabled" label="Plot Min/Max Values" />
      </Grid>
      <Grid item xs={6}>
        <FormToggle name="isRawCleanEnabled" label="Enable Raw/Clean" />
      </Grid>
      <Grid item xs={12}>
        <ConfigDataSourceSelectorFormController
          name="locationsLeftAxis"
          title="Left Axis"
          axis="left"
        />
      </Grid>
      <Grid item xs={12}>
        <ConfigDataSourceSelectorFormController
          name="locationsRightAxis"
          title="Right Axis"
          axis="right"
        />
      </Grid>
      <Grid item xs={12}>
        <ChartWidgetDataAvailability
          sourcesLeftAxis={locationsLeftAxis}
          sourcesRightAxis={locationsRightAxis}
          canSelectDateRange={!dateRangeIsInherited}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          fullWidth
          variant="subtitle1"
          style={{ marginTop: '8px', display: 'block' }}
          gutterTop
          gutterBottom
        >
          {`Date Range${dateRangeIsInherited ? ' (Inherited)' : ''}`}
        </Typography>
        {!dateRangeIsInherited && (
          <FormToggleButtonGroup name="dateSelection" exclusive>
            <ToggleButton value="latest">Latest</ToggleButton>
            <ToggleButton value="dateRange">Date Range</ToggleButton>
          </FormToggleButtonGroup>
        )}
      </Grid>
      <Grid item xs={12}>
        {dateRangeIsInherited ? (
          <ChartWidgetReadonlyDateFilters />
        ) : (
          <ChartWidgetDateFilters dateSelection={dateSelection} />
        )}
      </Grid>
      {!dateRangeIsInherited && (
        <Grid item xs={12}>
          <FormToggle name="autoRefresh" label="Auto Refresh" />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormToggle
          name="displayQaqc"
          label="Display QAQC Flags"
          disabled={numberOfDataSources > 1}
        />
      </Grid>
    </Grid>
  );
};

export default ChartWidgetConfigContent;
