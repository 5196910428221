import { ONC_DATA, NOAA_DATA, DFO_DATA } from './OrganizationServiceData';

class OrganizationService {
  static getOrganizationDetails = async (organizationId) => {
    switch (organizationId) {
      case NOAA_DATA.organizationId:
        return NOAA_DATA;
      case DFO_DATA.organizationId:
        return DFO_DATA;
      default:
        return ONC_DATA;
    }
  };
}

export default OrganizationService;
