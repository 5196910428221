import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { TableOld as Table } from 'base-components';
import MultiQueueService from 'domain/AppComponents/multi-queue/service/MultiQueueService';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';
import {
  ContainedButton,
  OutlinedButton,
} from 'library/CompositeComponents/button/Buttons';
import Panel from 'library/CompositeComponents/panel/Panel';
import DateFormatUtils from 'util/DateFormatUtils';
import MapDeviceDialog from './MapDeviceDialog';
import MoveDeviceDialog from './MoveDeviceDialog';

const styles = (theme) => ({
  container: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'sticky',
    willChange: 'transform',
    bottom: '2px',
    backgroundColor: 'white',
  },
});

const HEADERS = [
  { title: 'Queue Device ID', name: 'queueDeviceId' },
  { title: 'Device ID', name: 'deviceId' },
  { title: 'Device', name: 'device' },
  { title: 'Device Type', name: 'deviceType' },
  { title: 'State', name: 'state' },
  { title: 'Modify By', name: 'modifyBy' },
  { title: 'Modify Date', name: 'modifyDate' },
];

class MultiQueuePanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    refreshTree: PropTypes.func.isRequired,
    node: NodePropType.isRequired,
    locationNode: NodePropType.isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  getRowId = (row) => row.queueDeviceId;

  formatRowDates = (rows) => {
    const allRows = rows;
    let singleRow;
    if (rows) {
      allRows.map((row) => {
        singleRow = row;
        singleRow.modifyDate = DateFormatUtils.formatDate(
          row.modifyDate,
          'full'
        );
        return singleRow;
      });
    }
    return allRows;
  };

  state = {
    showMoveDialog: false,
    showMapDialog: false,
    mapDeviceType: false,
    queueName: undefined,
    selection: [],
    queueDevices: [],
  };

  constructor(props) {
    super(props);
    const { node } = props;
    this.state = {
      tableRows: [],
      selection: [],
      queueName: node.multiQueueTreeNodeName,
      queueDevices: [],
      showMoveDialog: false,
      showMapDialog: false,
      mapDeviceType: false,
    };
  }

  componentDidMount() {
    const { node } = this.props;
    this.getQueueDevices(node.objectId);
  }

  componentDidUpdate(prevProps) {
    const { node } = this.props;
    const { node: prevNode } = prevProps;
    if (node && prevNode !== node) {
      this.getQueueDevices(node.objectId);
    }
  }

  openMoveDialog = () => {
    this.setState({
      showMoveDialog: true,
    });
  };

  openMapDialog = (e) => {
    if (e.target.textContent === 'Map Device') {
      this.setState({
        showMapDialog: true,
        mapDeviceType: false,
      });
    } else if (e.target.textContent === 'Map Device Type') {
      this.setState({
        showMapDialog: true,
        mapDeviceType: true,
      });
    }
  };

  closeMoveDialog = () => {
    this.setState({
      showMoveDialog: false,
    });
  };

  closeMapDialog = () => {
    this.setState({
      showMapDialog: false,
      mapDeviceType: false,
    });
  };

  onSelectionChange = (selection) => {
    this.setState({
      selection,
    });
  };

  getQueueDevices = (queueId) => {
    const { onError } = this.props;
    const { queueDevices } = this.state;
    MultiQueueService.getQueueDevices(queueId)
      .then((response) => {
        let scheduled = false;
        response.forEach((queueDevice) => {
          if (
            !scheduled &&
            !queueDevice.state.toUpperCase().includes('COMPLETE')
          ) {
            setTimeout(() => this.getQueueDevices(queueId), 15000);
            scheduled = true;
          }
        });
        const newQueueDevices = this.formatRowDates(response);
        if (
          JSON.stringify(queueDevices.toSorted()) !==
          JSON.stringify(newQueueDevices.toSorted())
        ) {
          this.setState({
            queueDevices: newQueueDevices,
            selection: [],
          });
        }
      })
      .catch(() => {
        onError(`failed get queueDevices for ${queueId}`);
      });
  };

  renderButtons() {
    const {
      classes,
      node,
      onError,
      refreshTree,
      onInfo,
      locationNode,
      permission,
    } = this.props;
    const {
      showMoveDialog,
      showMapDialog,
      selection,
      queueDevices,
      mapDeviceType,
    } = this.state;
    if (permission !== 'RW') {
      return null;
    }
    const availableQueues = [].concat(
      [],
      ...locationNode.children.map((shorestation) =>
        shorestation.children.map((queue) => ({
          id: queue.objectId,
          name: queue.multiQueueTreeNodeName,
        }))
      )
    );
    const selectedQueueDevices = [];
    if (selection.length > 0) {
      queueDevices.forEach((qd) => {
        if (selection.includes(qd.queueDeviceId)) {
          selectedQueueDevices.push(qd);
        }
      });
    }
    return (
      <div className={classes.buttonContainer}>
        <ContainedButton
          translationKey="device.mapDevice"
          startIcon={<Add />}
          name="deviceMap"
          onClick={this.openMapDialog}
          disabled={node.defaultQueue !== undefined}
        />
        <ContainedButton
          translationKey="device.mapDeviceType"
          startIcon={<Add />}
          name="deviceTypeMap"
          onClick={this.openMapDialog}
          disabled={node.defaultQueue !== undefined}
        />
        <MapDeviceDialog
          node={node}
          showDialog={showMapDialog}
          closeDialog={this.closeMapDialog}
          refreshTree={refreshTree}
          onInfo={onInfo}
          onError={onError}
          mapDeviceType={mapDeviceType}
        />
        <OutlinedButton
          translationKey="multiqueue.moveSelectedDevices"
          onClick={this.openMoveDialog}
          disabled={selection.length === 0}
        />
        <MoveDeviceDialog
          devices={selectedQueueDevices}
          node={node}
          showDialog={showMoveDialog}
          queues={availableQueues}
          closeDialog={this.closeMoveDialog}
          refreshTree={refreshTree}
          onInfo={onInfo}
          onError={onError}
        />
      </div>
    );
  }

  renderNodeInformation() {
    const { selection, queueDevices } = this.state;

    return (
      <>
        <Table
          showBorders={false}
          columns={HEADERS}
          elevation={0}
          showHeaderRow
          onSelectionChange={this.onSelectionChange}
          selection={selection}
          rows={queueDevices}
          getRowId={this.getRowId}
          selectable
        />
        {this.renderButtons()}
      </>
    );
  }

  render() {
    const { classes, node } = this.props;
    return (
      <Panel title={node.multiQueueTreeNodeName}>
        <div className={classes.container}>{this.renderNodeInformation()}</div>
      </Panel>
    );
  }
}

export default withStyles(styles)(MultiQueuePanel);
