import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import DataRatingRO from 'domain/AppComponents/data-rating-maintenance/DataRatingRO';
import DataRatingRW from 'domain/AppComponents/data-rating-maintenance/DataRatingRW';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(2),
  },
});

const defaultState = {};

class DataRating extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
    }),
    dataRatingId: PropTypes.number,
    deviceId: PropTypes.number,
    sensorId: PropTypes.number,
    history: PropTypes.shape().isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: undefined,
    dataRatingId: undefined,
    deviceId: undefined,
    sensorId: undefined,
  };

  constructor(props) {
    super(props);
    const { dataRatingId, deviceId, sensorId } = this.props;
    this.state = {
      dataRatingId,
      deviceId,
      sensorId,
      ...defaultState,
    };
  }

  render() {
    const { dataRatingId, deviceId, sensorId, onInfo, onError, history } =
      this.props;

    if (Environment.getDmasUserPrivilege() === 'RW') {
      return (
        <DataRatingRW
          dataRatingId={dataRatingId}
          deviceId={deviceId}
          sensorId={sensorId}
          history={history}
          onInfo={onInfo}
          onError={onError}
        />
      );
    }

    return (
      <DataRatingRO
        dataRatingId={dataRatingId}
        deviceId={deviceId}
        sensorId={sensorId}
        history={history}
        onError={onError}
      />
    );
  }
}
export default withStyles(STYLES)(withSnackbars(DataRating));
