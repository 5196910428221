import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InfoItem from './InfoItem';

class InfoItemNullable extends PureComponent {
  static propTypes = {
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
  };

  static defaultProps = {
    secondary: 'n/a',
  };

  render() {
    const { primary, secondary } = this.props;
    return <InfoItem primary={primary} secondary={secondary} />;
  }
}

export default InfoItemNullable;
