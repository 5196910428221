import { useEffect, useState } from 'react';
import { Box, ExpandableSearchBar } from 'base-components';
import FixedCameraLocationTree from 'domain/Apps/expedition-management/tree/FixedCameraLocationTree';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import useBroadcast from 'util/hooks/useBroadcast';
import BroadcastChannel from '../BroadcastChannel';

const FixedCameraLocationTreeWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id, dashboardId } = props;

  const [selectedNodeId, setSearchTreeNodeId] = useBroadcast<number>(
    dashboardId,
    BroadcastChannel.SearchTreeNodeId,
    undefined,
    id
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [localSelectedNodeId, setLocalSelectedNodeId] = useState<number | null>(
    null
  );
  const [expandToNodeId, setExpandToNodeId] = useState<string | null>(null);

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  const SearchBar = (
    <Box sx={{ mr: 1 }}>
      <ExpandableSearchBar
        onValueChange={handleSearchChange}
        searchValue={searchQuery}
      />
    </Box>
  );

  useEffect(() => {
    if (selectedNodeId !== localSelectedNodeId) {
      setExpandToNodeId(selectedNodeId?.toString());
      setLocalSelectedNodeId(selectedNodeId);
    }
  }, [selectedNodeId, localSelectedNodeId]);

  return (
    <Widget
      key={id}
      title="Location Tree"
      actionComponents={[SearchBar]}
      {...props}
    >
      <FixedCameraLocationTree
        onNodeSelect={(e, nodeId: string) => {
          setLocalSelectedNodeId(Number(nodeId));
          setSearchTreeNodeId(Number(nodeId));
        }}
        selectedNodeId={localSelectedNodeId?.toString()}
        expandToNodeId={expandToNodeId}
        searchQuery={searchQuery}
      />
    </Widget>
  );
};

FixedCameraLocationTreeWidget.widgetKey = 'fixed-camera-location-tree-widget';
FixedCameraLocationTreeWidget.widgetTitle = 'Location Tree';
FixedCameraLocationTreeWidget.defaultDataGrid = {
  i: 'fixed-camera-location-tree-widget',
  x: 3,
  y: 0,
  w: 6,
  h: 9,
};

export default FixedCameraLocationTreeWidget;
