import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class SiteDeviceService {
  static getOne(siteDeviceId) {
    return SiteDeviceService.get({ operation: 4, siteDeviceId });
  }

  static getMany(deviceId) {
    return SiteDeviceService.get({ operation: 5, deviceId });
  }

  static getDeployments(deviceId) {
    return SiteDeviceService.get({ operation: 100, deviceId });
  }

  static getBySearchParameters(params) {
    return SiteDeviceService.get({
      operation: 7,
      ...params,
    });
  }

  static get(params) {
    return get('SiteDeviceService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static save(params) {
    return post('SiteDeviceService', {
      operation: 1,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static update(params) {
    return post('SiteDeviceService', {
      operation: 2,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static delete = (params) =>
    post('SiteDeviceService', {
      operation: 3,
      ...params,
    });
}
export default SiteDeviceService;
