import { parseDmasAPIResponse, get } from 'util/WebRequest';

const GEOSPATIAL_AREA_SERVICE = 'GeospatialAreaService';

class GeospatialAreaService {
  static getDrawableAreas = () =>
    get(
      GEOSPATIAL_AREA_SERVICE,
      { method: 'getDrawableAreas' },
      undefined
    ).then((response) => parseDmasAPIResponse(response));
}

export default GeospatialAreaService;
