import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link } from 'base-components';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateUtils from 'util/DateUtils';

const styles = () => ({
  root: {
    overflow: 'hidden',
  },
});

const headers = [
  { title: 'ID', name: 'classifierInstanceId' },
  { title: 'File Name', name: 'fileMode' },
  { title: 'Modify Date (UTC)', name: 'modifyDate' },
  { title: 'Comment', name: 'comment' },
];

const columnSizeInfo = [
  {
    columnName: 'classifierInstanceId',
    width: ColumnInfo.mini,
    align: 'center',
  },
  { columnName: 'fileMode', width: ColumnInfo.large, align: 'center' },
  { columnName: 'modifyDate', width: ColumnInfo.large, align: 'center' },
  { columnName: 'comment', width: ColumnInfo.large, align: 'left' },
];

class PamguardPSFUploadRecordTable extends PureComponent {
  static propTypes = {
    classifierList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  renderTableRows = () => {
    const { classifierList } = this.props;
    return classifierList.map((row) => ({
      classifierInstanceId: row.classifierInstanceId,
      modifyDate: DateUtils.formatDateAsString(new Date(row.modifyDate)),
      fileMode: (
        <Link
          href={`PamguardPSFUploadService?method=downloadPSF&psfFileName=${row.fileMode}`}
          download
        >
          {row.fileMode}
        </Link>
      ),
    }));
  };

  render() {
    return (
      <Panel title="Available Profile Files in Database">
        <SortableTable
          columnSizes={columnSizeInfo}
          stripedRows
          columns={headers}
          rows={this.renderTableRows()}
          defaultSorting={[
            {
              columnName: 'classifierInstanceId',
              direction: 'asc',
            },
          ]}
        />
      </Panel>
    );
  }
}

export default withStyles(styles)(withSnackbars(PamguardPSFUploadRecordTable));
