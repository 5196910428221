import { get } from 'util/WebRequest';

export type Parameters = {
  startTime?: Date;
  endTime?: Date;
  minDepth?: number;
  maxDepth?: number;
  minMagnitude?: number;
  maxMagnitude?: number;
  isComplete?: boolean;
  isFixed?: boolean;
  limit?: number;
  getSiteData?: boolean;
  format?: string;
};

class EndeavourEarthquakeService {
  static getEarthquakes = async (params) =>
    get('/internal/earthquakes/endeavour', params).then(
      (response) => response.data
    );

  static getEarthquakesById = (id) =>
    get(`/internal/earthquakes/endeavour/${id}`);

  static getIrisData = (station, startDate, endDate, channel) =>
    get('https://service.iris.edu/irisws/timeseries/1/query', {
      net: 'NV',
      sta: station,
      cha: channel,
      start: startDate,
      end: endDate,
      demean: 'true',
      hp: '2.00',
      lp: '15.0',
      output: 'ascii2',
      loc: '--',
      scale: 'AUTO',
    });
}

export default EndeavourEarthquakeService;
