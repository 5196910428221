import { Drawer, DrawerProps } from 'base-components';
import DefaultShareButtons from './DefaultShareButtons';

/**
 * See Drawer API for more props: https://material-ui.com/api/drawer/ At
 * minimum, use open and onClose
 */
type ShareDrawerProps = DrawerProps & {
  shareUrl: string;
  title: string;
  anchor?: 'top' | 'left' | 'bottom' | 'right';
  onCopy?: () => void;
};

/**
 * ShareDrawer component that is a drawer with DefaultShareButtons component
 * inside it. This should be used for smaller screen sizes.
 */
const ShareDrawer = ({
  shareUrl,
  title,
  anchor = 'bottom',
  onCopy = undefined,
  ...rest
}: ShareDrawerProps) => (
  <Drawer role="dialog" aria-label="Share Drawer" anchor={anchor} {...rest}>
    <DefaultShareButtons shareUrl={shareUrl} title={title} onCopy={onCopy} />
  </Drawer>
);

export default ShareDrawer;
