import { createContext, useMemo } from 'react';
import * as React from 'react';

interface BatchContextProps {
  children: React.ReactNode;
  taskType: string;
}

export const BatchContext = createContext<{ taskType: string | null }>({
  taskType: null,
});

const BatchTaskTypeProvider: React.FC<BatchContextProps> = ({
  children,
  taskType,
}: BatchContextProps) => {
  const value = useMemo(() => ({ taskType }), [taskType]);
  return (
    <BatchContext.Provider value={value}>{children}</BatchContext.Provider>
  );
};

export default BatchTaskTypeProvider;
