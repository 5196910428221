import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import OrganizationDetails from 'domain/AppComponents/organization-details/OrganizationDetails';
import CatalogDetails from 'domain/Apps/expedition-management/details/CatalogDetails';
import ExpeditionDetails from 'domain/Apps/expedition-management/details/ExpeditionDetails';
import DiveDetails from 'domain/Apps/seatube/details/DiveDetails';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';

const CATALOG_RESOURCE_TYPE = 0;

class DetailsFactory extends PureComponent {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    resourceId: PropTypes.number.isRequired,
    resourceTypeId: PropTypes.number.isRequired,
    location: PropTypes.bool,
    showExtraDiveInfo: PropTypes.bool,
    createDiveOpen: PropTypes.bool,
  };

  static defaultProps = {
    location: false,
    showExtraDiveInfo: false,
    createDiveOpen: false,
  };

  render() {
    const {
      organizationId,
      resourceId,
      resourceTypeId,
      location,
      showExtraDiveInfo,
      createDiveOpen,
      ...rest
    } = this.props;
    switch (resourceTypeId) {
      case SeaTubeResourceTypes.DIVE:
        return (
          <DiveDetails
            organizationId={organizationId}
            diveId={resourceId}
            showExtraDiveInfo={showExtraDiveInfo}
            createDiveOpen={createDiveOpen}
            {...rest}
          />
        );
      case SeaTubeResourceTypes.EXPEDITION:
        return (
          <ExpeditionDetails
            organizationId={organizationId}
            expeditionId={resourceId}
            {...rest}
          />
        );
      case SeaTubeResourceTypes.ORGANIZATION:
        return <OrganizationDetails organizationId={resourceId} {...rest} />;
      case CATALOG_RESOURCE_TYPE:
        return <CatalogDetails location={location} />;
      default:
        return null;
    }
  }
}

export default DetailsFactory;
