import SeaTubePlaylistFiles from 'library/CompositeComponents/video/SeaTubePlaylistFiles';
import * as WebRequest from 'util/WebRequest';

class PlaylistVideoService {
  static getByPlaylistHdrId = async (playlistHdrId, resolution = undefined) =>
    WebRequest.get(`internal/playlists/${playlistHdrId}/files`, {
      resolution,
    }).then((response) => SeaTubePlaylistFiles.from(response.data));

  static createSearchTaskForPlaylist = async (
    playlistId: number,
    stitchFiles?: boolean,
    quality?: string,
    playlistLineIds?
  ): Promise<{
    searchHdrId: number;
  }> => {
    const response = await WebRequest.post(
      `internal/playlists/${playlistId}/files`,
      {
        stitchFiles,
        quality,
        playlistLineIds,
      }
    );
    return response.data;
  };
}

export default PlaylistVideoService;
