import { Skeleton as MaterialSkeleton } from '@mui/material/';

export interface SkeletonProps {
  /** The variant to use. */
  variant?: 'text' | 'rectangular' | 'circular';

  /** The height of the skeleton */
  height?: number | string;

  /** The width of the skeleton */
  width?: number | string;
}

/**
 * See https://v4.mui.com/api/skeleton/ for usage
 *
 * Skeleton component that wraps Material-UI's Skeleton. Used for loading
 * placeholders.
 */
const Skeleton = ({
  variant = 'text',
  height = undefined,
  width = undefined,
}: SkeletonProps) => (
  <MaterialSkeleton
    role="status"
    aria-live="polite"
    variant={variant}
    height={height}
    width={width}
  />
);

export default Skeleton;
