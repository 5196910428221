import React, { useState } from 'react';
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { Button, Input, MenuItem, Select, styled } from '@mui/material';
import { ChevronLeft, ChevronRight, FirstPage } from '@onc/icons';
import { IconButton } from 'base-components';

const PREFIX = 'Pager';
const PREFIX1 = 'Pagination';
const PREFIX2 = 'PageSizeSelector';
const IS_LEGACY_EDGE_MEDIA_QUERY =
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)';
const classes = {
  pager: `${PREFIX}-pager`,
  button: `${PREFIX1}-button`,
  activeButton: `${PREFIX1}-activeButton`,
  text: `${PREFIX1}-text`,
  pagination: `${PREFIX1}-pagination`,
  rowsLabel: `${PREFIX1}-rowsLabel`,
  arrowButton: `${PREFIX1}-arrowButton`,
  prev: `${PREFIX1}-prev`,
  next: `${PREFIX1}-next`,
  pageSizeSelector: `${PREFIX2}-pageSizeSelector`,
  label: `${PREFIX2}-label`,
  selectIcon: `${PREFIX2}-selectIcon`,
  selectMenu: `${PREFIX2}-selectMenu`,
  inputRoot: `${PREFIX2}-inputRoot`,
};
const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.button}`]: {
    minWidth: theme.spacing(2),
  },
  [`&.${classes.activeButton}`]: {
    fontWeight: 'bold',
    cursor: 'default',
  },
  [`&.${classes.text}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
    bottom: '2px',
  },
  '@media(max-width: 768px)': {
    [`&.${classes.button}`]: {
      display: 'none',
    },
  },
}));

const StyledDiv = styled('div')(({ theme }) => ({
  [`&.${classes.pager}`]: {
    overflow: 'hidden',
    padding: theme.spacing(1.5),
    display: 'flex',
    flex: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  [`&.${classes.pagination}`]: {
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px',
  },
  [`& .${classes.rowsLabel}`]: {
    ...theme.typography.caption,
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
  [`& .${classes.arrowButton}`]: {
    display: 'inline-block',
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : null,
    msTransform: theme.direction === 'rtl' ? 'rotate(180deg)' : null,
  },
  [`& .${classes.prev}`]: {
    marginRight: 0,
  },
  [`& .${classes.next}`]: {
    marginLeft: 0,
  },
  '@media(max-width: 768px)': {
    [`& .${classes.rowsLabel}`]: {
      paddingRight: theme.spacing(2),
    },
    [`& .${classes.prev}`]: {
      marginRight: theme.spacing(1),
    },
    [`& .${classes.next}`]: {
      marginLeft: theme.spacing(1),
    },
  },
  [`&.${classes.pageSizeSelector}`]: {
    ...theme.typography.caption,
    paddingRight: theme.spacing(5),
    // NOTE: fixes vertical alignment in FF
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.label}`]: {
    paddingRight: theme.spacing(3),
  },
  [`& .${classes.selectIcon}`]: {
    top: 2,
  },
  [`& .${classes.selectMenu}`]: {
    // NOTE: fix position for non-chromium Edge (issues 2234, 2788)
    [`${IS_LEGACY_EDGE_MEDIA_QUERY}`]: {
      position: 'absolute !important',
    },
  },
  [`& .${classes.inputRoot}`]: {
    fontSize: theme.spacing(1.75),
    textAlign: 'right',
  },
  '@media (max-width: 768px)': {
    [`&.${classes.pageSizeSelector}`]: {
      paddingRight: theme.spacing(2),
    },
    [`& .${classes.label}`]: {
      display: 'none',
    },
  },
}));

const NoTotalCountPagingContainer: React.FC<PagingPanel.ContainerProps> = (
  props: PagingPanel.ContainerProps
) => {
  const {
    currentPage,
    pageSize,
    pageSizes,
    totalCount,
    onPageSizeChange,
    onCurrentPageChange,
  } = props;
  const [pageIndex, setPageIndex] = useState<number>(currentPage);
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSize);

  const computePageSpanMessage = () => {
    const from = pageIndex * currentPageSize + 1;
    const rowCount =
      totalCount >= currentPageSize ? currentPageSize : totalCount;
    return `${from} - ${from + rowCount - 1}`;
  };

  const handlePageSizeChange = (newSize: number) => {
    onPageSizeChange(newSize);
    setCurrentPageSize(newSize);
    setPageIndex(0);
  };

  const handlePageChange = (newPage: number) => {
    onCurrentPageChange(newPage);
    setPageIndex(newPage !== -1 ? newPage : 0);
  };

  return (
    <StyledDiv className={classes.pager}>
      <StyledDiv className={classes.pageSizeSelector}>
        <span className={classes.label}>Rows per page:</span>
        <Select
          value={currentPageSize}
          onChange={(event) => handlePageSizeChange(Number(event.target.value))}
          classes={{
            icon: classes.selectIcon,
          }}
          MenuProps={{
            className: classes.selectMenu,
          }}
          input={
            <Input disableUnderline classes={{ root: classes.inputRoot }} />
          }
        >
          {pageSizes.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </StyledDiv>
      <StyledDiv className={classes.pagination}>
        <span className={classes.rowsLabel}>{computePageSpanMessage()}</span>
        <IconButton
          className={`${classes.arrowButton} ${classes.prev}`}
          disabled={pageIndex === 0}
          onClick={() => pageIndex > 0 && handlePageChange(-1)}
          aria-label="First Page"
          size="small"
          Icon={FirstPage}
        />
        <IconButton
          className={`${classes.arrowButton} ${classes.prev}`}
          disabled={pageIndex === 0}
          onClick={() =>
            pageIndex > 0 &&
            handlePageChange(pageIndex - 1 !== 0 ? pageIndex - 1 : -1)
          }
          aria-label="Previous"
          size="small"
          Icon={ChevronLeft}
        />
        <StyledButton
          className={`${classes.button} ${classes.activeButton} ${classes.text}`}
        >
          {pageIndex + 1}
        </StyledButton>
        <IconButton
          className={`${classes.arrowButton} ${classes.next}`}
          disabled={totalCount < currentPageSize || totalCount === 0}
          onClick={() =>
            totalCount === currentPageSize && handlePageChange(pageIndex + 1)
          }
          aria-label="Next"
          size="small"
          Icon={ChevronRight}
        />
      </StyledDiv>
    </StyledDiv>
  );
};

export default NoTotalCountPagingContainer;
