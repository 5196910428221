import { useState } from 'react';
import * as React from 'react';
import { Theme, MenuList, MenuItem } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import {
  HelpOutline,
  LibraryBooks,
  NetworkCheck,
  AccountCircle,
} from '@onc/icons';
import {
  AnchoredHiddenMenu,
  Grid,
  IconButton,
  Link,
  Typography,
} from 'base-components';
import { ExternalLinkIconButton } from 'domain/AppComponents/IconButtons';
import SupportRequestContainer from 'domain/Apps/issue-collector/SupportRequestContainer';
import Environment from 'util/Environment';
import { post } from 'util/WebRequest';

const DOCUMENTATION_URL = 'https://wiki.oceannetworks.ca/display/O2KB';
const NETWORK_STATUS_URL = 'https://oceannetworkscanada.statuspage.io';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: { color: theme.palette.primary.main },
    list: {
      padding: '0px',
      '& ul': { padding: '0px' },
      color: theme.palette.primary.main,
    },
    loggedInText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.main,
      paddingRight: theme.spacing(1),
    },
    loginIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loginText: {
      paddingLeft: theme.spacing(1),
    },
  })
);

const LandingPageHelpButton = () => {
  const classes = useStyles();

  const [showSupportRequest, setShowSupportRequest] = useState(false);

  const renderSupportRequest = () => {
    if (showSupportRequest) {
      return (
        <SupportRequestContainer
          user={{
            name: Environment.getDmasUserName(),
            email: Environment.getDmasUserEmail(),
          }}
          jiraDomain={Environment.getTicketManagerUrl()}
          onClose={() => setShowSupportRequest(false)}
          pageAffiliation=""
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderSupportRequest()}
      <IconButton
        onClick={() => setShowSupportRequest(true)}
        Icon={HelpOutline}
        aria-label="Create Support Request"
        className={classes.buttonRoot}
      >
        <HelpOutline />
      </IconButton>
    </>
  );
};

const LandingPageDocsButton = () => {
  const classes = useStyles();

  return (
    <ExternalLinkIconButton
      link={DOCUMENTATION_URL}
      tooltipTitle="Go to Oceans 3.0 Documentation"
      icon={LibraryBooks}
      iconProps={{ classes: { root: classes.buttonRoot } }}
      aria-label="go to documentation"
    />
  );
};

const LandingPageNetworkCheckButton = () => {
  const classes = useStyles();
  return (
    <ExternalLinkIconButton
      link={NETWORK_STATUS_URL}
      tooltipTitle="Go to System Status"
      icon={NetworkCheck}
      iconProps={{ classes: { root: classes.buttonRoot } }}
      aria-label="go to network status"
    />
  );
};

const LandingPageLoginButton = () => {
  const classes = useStyles();

  const logout = () => {
    post('Logout', { redirectPage: 'LandingPage' }, {})
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        window.location.reload();
      });
  };
  const profile = () => {
    window.open(`${Environment.getDmasUrl()}/Profile`, '_blank');
  };

  const personButton = (
    <IconButton data-test="landingPageProfileIcon" aria-label="Profile Menu">
      <AccountCircle className={classes.buttonRoot} />
    </IconButton>
  );
  if (Environment.isUserLoggedIn()) {
    return (
      <AnchoredHiddenMenu
        data-test="landingPageProfileMenu"
        className={classes.list}
        variant="custom"
        customAnchor={personButton}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          {' '}
          <MenuItem onClick={profile}>Profile</MenuItem>
          <MenuItem onClick={logout}>Log Out</MenuItem>
        </MenuList>
      </AnchoredHiddenMenu>
    );
  }
  return (
    <Link
      className={classes.loginText}
      href={`${Environment.getLinkUrl()}/Login?service=${Environment.getLinkUrl()}/LandingPage`}
    >
      <Typography style={{ fontWeight: 'bold' }}>Log In</Typography>
    </Link>
  );
};

const LoginStatus = () => {
  if (Environment.isUserLoggedIn()) {
    return (
      <>
        <Typography>Logged in as&nbsp;</Typography>
        <Typography
          style={{ fontWeight: 'bold' }}
        >{`${Environment.getDmasUserName()}`}</Typography>
      </>
    );
  }
  return null;
};
// TODO Should this include styling to place buttons
// at the top right or should that be done by the parent ?
const LandingPageButtons: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item className={classes.loggedInText}>
        <LoginStatus />
      </Grid>
      <Grid item>
        <LandingPageHelpButton />
      </Grid>
      <Grid item>
        <LandingPageDocsButton />
      </Grid>
      <Grid item>
        <LandingPageNetworkCheckButton />
      </Grid>
      <Grid item className={classes.loginIcon}>
        <LandingPageLoginButton />
      </Grid>
    </Grid>
  );
};

export default LandingPageButtons;
