import * as React from 'react';
import { Apps } from '@onc/icons';
import { Grid, LabelledCheckbox } from 'base-components';
import Filter, {
  SummaryValues,
  FilterValue,
  FilterImplementationProps,
} from 'library/CompositeComponents/filter/Filter';

export interface SourceFilterValue extends FilterValue {
  ['1']: boolean;
  ['2']: boolean;
  ['3']: boolean;
  ['5']: boolean;
  ['6']: boolean;
}

interface Props extends FilterImplementationProps {
  onChange?: any; // (event: FilterEvent) => void;
  name: string;
  value?: SourceFilterValue;
}

const SourceFilter: React.FC<Props> = ({
  onChange = undefined,
  name,
  value = undefined,
  onExpand,
  expanded,
}: Props) => {
  const renderSummaryValues = () => {
    const summaryValues: SummaryValues[] = [];
    if (value) {
      if (value['1'] && value['2'] && value['5'] && value['6'] && value['3']) {
        return [{ label: 'All' }];
      }
      !value['1'] || summaryValues.push({ label: 'Digital Fishers' });
      !value['2'] || summaryValues.push({ label: 'IRLS' });
      !value['5'] || summaryValues.push({ label: 'Hydrophone Viewer' });
      !value['6'] || summaryValues.push({ label: 'SeaTube / SeaScribe' });
      !value['3'] || summaryValues.push({ label: 'Oceans 3.0' });
    }
    return summaryValues;
  };

  return (
    <Filter
      title="Source"
      icon={<Apps data-test="filterIcon" color="action" />}
      value={value}
      name={name}
      summaryValues={renderSummaryValues()}
      onChange={onChange}
      onExpand={onExpand}
      expanded={expanded}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <LabelledCheckbox name="1" label="Digital Fishers" />
          <LabelledCheckbox name="2" label="IRLS" />
          <LabelledCheckbox name="5" label="Hydrophone Viewer" />
          <LabelledCheckbox name="6" label="SeaTube / SeaScribe" />
          <LabelledCheckbox name="3" label="Oceans 3.0" />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default SourceFilter;
