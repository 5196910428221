import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import DataRatingTable from './DataRatingTable';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
  tableDiv: {
    margin: theme.spacing(1),
  },
  button: {
    paddingBottom: theme.spacing(7),
  },
});

class DataRatingPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    deviceId: PropTypes.number,
    sensorId: PropTypes.number,
  };

  static defaultProps = {
    deviceId: undefined,
    sensorId: undefined,
  };

  render() {
    const { classes, onInfo, onError, deviceId, sensorId } = this.props;

    if (!sensorId) {
      return (
        <div className={classes.tableDiv}>
          <DataRatingTable
            permission={Environment.getDmasUserPrivilege()}
            deviceId={deviceId}
            className={classes.root}
            classes={classes}
            stripedRows
            onInfo={onInfo}
            onError={onError}
          />
        </div>
      );
    }

    return (
      <div className={classes.tableDiv}>
        <DataRatingTable
          permission={Environment.getDmasUserPrivilege()}
          sensorId={sensorId}
          deviceId={deviceId}
          className={classes.root}
          classes={classes}
          stripedRows
          onInfo={onInfo}
          onError={onError}
          noTitle
        />
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(DataRatingPage));
