import {
  useMutation,
  type MutationOptions as ReactMutationOptions,
  type UseMutationResult as ReactUseMutationResult,
} from '@tanstack/react-query';
import {
  type AxiosResponse,
  type AxiosError,
  type AxiosPromise,
  type AxiosRequestConfig,
} from 'axios';
import DmasError from 'util/DmasError';
import { post } from 'util/WebRequest';
import {
  parseDmasAPIResponse,
  type DmasResponseData,
  type UnknownValueRecord,
} from './DmasAPIResponse';

type UseMutationResult<TPayload, TVariables> = ReactUseMutationResult<
  TPayload,
  DmasError | AxiosError,
  TVariables
>;

type UsePost = {
  <TVariables extends UnknownValueRecord, TPayload = undefined>(
    servicePath: string,
    options: ReactMutationOptions<TPayload, DmasError | AxiosError, TVariables>,
    operationOrConfig:
      | number
      | { axiosConfig?: AxiosRequestConfig; operation: number }
  ): UseMutationResult<TPayload, TVariables>;
  <
    TVariables extends UnknownValueRecord,
    TPayload = undefined,
    TData = unknown,
  >(
    servicePath: string,
    options: ReactMutationOptions<TPayload, DmasError | AxiosError, TVariables>,
    operationOrConfig:
      | number
      | {
          axiosConfig?: AxiosRequestConfig;
          operation: number;
          transform: (response: AxiosResponse<TData>) => TPayload;
        }
  ): UseMutationResult<TPayload, TVariables>;
  <TVariables, TPayload = undefined>(
    servicePath: string,
    options: ReactMutationOptions<TPayload, DmasError | AxiosError, TVariables>,
    operationOrConfig:
      | number
      | {
          axiosConfig?: AxiosRequestConfig;
          operation: number;
        }
  ): UseMutationResult<TPayload, TVariables>;
  <TVariables, TPayload = undefined, TData = unknown>(
    servicePath: string,
    options: ReactMutationOptions<TPayload, DmasError | AxiosError, TVariables>,
    operationOrConfig:
      | number
      | {
          axiosConfig?: AxiosRequestConfig;
          transform: (response: AxiosResponse<TData>) => TPayload;
        }
  ): UseMutationResult<TPayload, TVariables>;
};

const usePost: UsePost = <
  TVariables,
  TPayload = undefined,
  TData = DmasResponseData<TPayload>,
>(
  servicePath: string,
  options: ReactMutationOptions<TPayload, DmasError | AxiosError, TVariables>,
  operationOrConfig:
    | number
    | {
        operation?: number;
        axiosConfig?: AxiosRequestConfig;
        transform?: (response: AxiosResponse<TData>) => TPayload;
      }
): UseMutationResult<TPayload, TVariables> => {
  const {
    operation,
    axiosConfig = undefined,
    transform = undefined,
  } = typeof operationOrConfig === 'number'
    ? { operation: operationOrConfig }
    : operationOrConfig;
  const mutationFn = (variables: TVariables): Promise<TPayload> =>
    (
      post(
        servicePath,
        operation ? { operation, ...variables } : variables,
        axiosConfig
      ) as AxiosPromise
    ).then((response) =>
      transform ? transform(response) : parseDmasAPIResponse(response)
    );
  return useMutation<TPayload, DmasError | AxiosError, TVariables>({
    mutationFn,
    ...options,
  });
};

export default usePost;
