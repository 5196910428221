import { createContext } from 'react';

export type UserContext = {
  userId: number;
};

const UserDetailsContext = createContext<UserContext>({
  userId: 0,
});

export default UserDetailsContext;
