import { useCallback } from 'react';
import { withStyles } from '@mui/styles';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import DeviceType from './DeviceType';
import SortableLink from '../network-console/SortableLink';

const styles = (theme) => ({
  table: {
    'overflow-x': 'hidden',
    margin: theme.spacing(1),
    minWidth: theme.spacing(75),
  },
});

const headers = [
  { title: 'Device Type ID', name: 'deviceTypeId' },
  { title: 'Device Type Name', name: 'deviceTypeName' },
  { title: 'Device Type Code', name: 'deviceTypeCode' },
  { title: 'Description', name: 'description' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Device Type ID For Search', name: 'deviceTypeIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'deviceTypeId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceTypeName',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceTypeCode',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'deviceTypeIdForSearch',
    width: ColumnInfo.mini,
  },
];

type DeviceTypeTableProps = {
  userHasWritePermission?: boolean;
  classes: { table: string };
  deviceTypes: DeviceType[] | null;
  openAndSaveEditData: (device: DeviceType) => void;
};

interface DeviceTypeRowInfo extends DeviceType {
  deviceTypeId?: any;
  deviceTypeIdForSearch?: number | string;
}

const getFullUserName = (deviceType: DeviceType) => deviceType.modifyByName;

const DeviceTypeTable = ({
  userHasWritePermission = false,
  classes,
  deviceTypes,
  openAndSaveEditData,
}: DeviceTypeTableProps) => {
  const isLoading = deviceTypes === null;

  const buildTableRows = useCallback(() => {
    if (!deviceTypes) {
      return [];
    }
    let newTableRows = deviceTypes;
    newTableRows = newTableRows.map((device) => {
      const returnValue: DeviceTypeRowInfo = { ...device };
      userHasWritePermission
        ? (returnValue.deviceTypeId = (
            <SortableLink
              id={device.deviceTypeId}
              onClick={() => openAndSaveEditData(device)}
            />
          ))
        : (returnValue.deviceTypeId = device.deviceTypeId);
      returnValue.deviceTypeName = device.deviceTypeName;
      returnValue.deviceTypeCode = device.deviceTypeCode;
      returnValue.description = device.description;
      returnValue.modifyDate = DateFormatUtils.formatDate(
        device.modifyDate,
        'full'
      );
      returnValue.modifyBy = getFullUserName(device);
      returnValue.deviceTypeIdForSearch = device.deviceTypeId;
      return returnValue;
    });
    return newTableRows;
  }, [deviceTypes, openAndSaveEditData, userHasWritePermission]);

  const tableRows = buildTableRows();

  return (
    <>
      <SortableTable
        columns={headers}
        columnSizes={tableColumnExtensions}
        columnExtensions={tableColumnExtensions}
        elevation={0}
        pageSize={15}
        pageSizes={[15, 30, 60, 300, 0]}
        rows={tableRows}
        defaultSorting={[{ columnName: 'deviceTypeName', direction: 'asc' }]}
        searchable
        searchBarMoveable
        stripedRows
        classes={classes.table}
        hiddenColumns={['deviceTypeIdForSearch']}
        isLoading={isLoading}
        sortExtensions={
          userHasWritePermission
            ? [
                {
                  columnName: 'deviceTypeId',
                  compare: (a, b) => (a.props.id > b.props.id ? 1 : -1),
                },
              ]
            : [
                {
                  columnName: 'deviceTypeId',
                  compare: (a, b) => (a > b ? 1 : -1),
                },
              ]
        }
      />
    </>
  );
};

export default withStyles(styles)(withSnackbars(DeviceTypeTable));
