/* eslint-disable react/prop-types */
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid } from 'base-components';
import { type ConfigFields } from 'library/CompositeComponents/dashboard/DashboardTypes';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';
import { validateUrl } from 'library/CompositeComponents/form/FormValidator';

export type ImageWidgetConfigType = {
  imageUrl: string;
  title: string;
  showTitle: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: theme.spacing(80),
  },
}));

const ImageWidgetConfigContent: ConfigFields<ImageWidgetConfigType> = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.form}>
      <Grid item xs={12}>
        <FormTextField
          translationKey="dashboards.imageUrl"
          name="imageUrl"
          fullWidth
          rules={{
            required: 'A url is required!',
            validate: (imageUrl) =>
              validateUrl(imageUrl, 'This needs to be a valid url'),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormToggle label="Show Title" name="showTitle" />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          translationKey="common.textfields.title"
          name="title"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default ImageWidgetConfigContent;
