import {
  deleteImpl,
  get,
  getAbortable,
  patch,
  post,
  put,
} from 'util/WebRequest';

class TaxonService {
  static async getTaxon(taxonomyId, taxonId) {
    const response = await get(
      `internal/taxonomies/${taxonomyId}/taxons/${taxonId}`
    );
    return response.data;
  }

  static async getFilteredTaxonsByTaxonomy(taxonomyId, inputValue) {
    const response = await get(
      `internal/taxonomies/${taxonomyId}/taxons`,
      {
        commonName: inputValue,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static getAbortableFilteredTaxonsByTaxonomy(taxonomyId, inputValue) {
    return getAbortable(
      `internal/taxonomies/${taxonomyId}/taxons`,
      {
        commonName: inputValue,
      },
      { headers: { 'content-type': 'application/json' } }
    );
  }

  static async getTaxonChildren(taxonomyId: number, taxonId?: number) {
    const response = await get(
      `internal/taxonomies/${taxonomyId}/taxons/${taxonId ? `${taxonId}/` : ''}children`,
      { getChildrenObjects: true },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async getTaxonDescendantsCount(taxonomyId, taxonId) {
    const response = await get(
      `internal/taxonomies/${taxonomyId}/taxons/${taxonId ? `${taxonId}/` : ''}children`,
      { getChildrenObjects: false },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async addTaxon(taxon) {
    const response = await post(
      'internal/taxonomies/taxons',
      {
        payload: JSON.stringify({
          taxonomyId: taxon.originTaxonomyId,
          parentTaxonomyMatrixId: taxon.taxonomyMatrixId,
          referenceId: taxon.referenceId,
          referenceUrl: taxon.referenceUrl,
          jsonTaxonData: taxon.jsonTaxonData,
          commonName: taxon.commonName,
          nodeTypeId: taxon.nodeTypeId,
          englishNames: taxon.englishNames,
          version: taxon.version,
          deprecated: taxon.deprecated,
        }),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async copyTaxonomyBranch(
    sourceTaxonomyId,
    sourceTaxonId,
    destinationTaxonomyId,
    destinationTaxonId
  ) {
    const response = await post(
      `internal/taxonomies/${sourceTaxonomyId}/taxons/${sourceTaxonId}`,
      {
        destinationTaxonomyId,
        destinationTaxonId,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async addTaxonToTaxonomy(taxonomyId, taxonId, parentTaxonomyMatrixId) {
    const response = await patch(
      `internal/taxonomies/${taxonomyId}/taxons/${taxonId}`,
      {
        parentTaxonomyMatrixId,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async updateTaxon(taxonId, taxon) {
    const response = await patch(
      `internal/taxonomies/taxons/${taxonId}`,
      {
        payload: JSON.stringify(taxon),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async removeTaxon(taxonomyId, taxonId) {
    const response = await deleteImpl(
      `internal/taxonomies/${taxonomyId}/taxons/${taxonId}`
    );
    return response.data;
  }

  static put(params) {
    const formData = new FormData();
    formData.append('uploadFile', params);
    return put('internal/taxonomies/taxons', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default TaxonService;
