import { Component } from 'react';
import PamguardPSFUploadRecordTable from './PamguardPSFUploadRecordTable';
import PamguardPSFUploadService from './PamguardPSFUploadService';

class PamguardPSFUploadComponent extends Component {
  state = {
    classifierList: [],
    selectedFile: null,
    error: null,
  };

  componentDidMount() {
    this.getAllPSFNames();
  }

  // Programatically click the hidden file input element
  // when the Button component is clicked
  handleClick = () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      this.handleFile(selectedFile);
    } else {
      alert('Please select a file first!'); // eslint-disable-line no-alert
    }
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // Update the state
    this.setState({ selectedFile: fileUploaded });
  };

  handleFile = async (fileUploaded) => {
    // alert(fileUploaded.name);
    const payload = await PamguardPSFUploadService.uploadPSFFile(fileUploaded);
    if (payload) {
      alert('Upload: Success!'); // eslint-disable-line no-alert
      this.getAllPSFNames();
    } else {
      alert(payload); // eslint-disable-line no-alert
    }
  };

  getAllPSFNames = async () => {
    const payload = await PamguardPSFUploadService.getAllPSFNames();
    if (payload) {
      // The following line will show the existing PSF files in our database
      this.setState({ classifierList: payload });
    }
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>Last Modified: {selectedFile.lastModified.toString()}</p>
        </div>
      );
    }
    return (
      <div>
        <br />
        <h4>Choose before Pressing the Upload button</h4>
      </div>
    );
  };

  render() {
    // const { error, selectedFile } = this.state;
    const { classifierList } = this.state;
    return (
      <div>
        <h1> Profile Settings </h1>
        <table>
          <tr>
            <input type="file" onChange={this.handleChange} />
            <button type="button" onClick={this.handleClick}>
              Upload!
            </button>
          </tr>
          <tr>{this.fileData()}</tr>
        </table>
        <p>
          Please note: this page is designed to let users choose a PSF file from
          their local computer and upload it to our DMAS database.
        </p>
        <PamguardPSFUploadRecordTable classifierList={classifierList} />
      </div>
    );
  }
}

export default PamguardPSFUploadComponent;
