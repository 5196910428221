/* eslint-disable react/require-default-props */
// Taken mostly from: https://codesandbox.io/s/material-ui-nested-menu-item-simpler-version-ginu9
import * as React from 'react';
import { Menu, MenuItem, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowRight as ArrowRightIcon } from '@onc/icons';
import { NestedItemType } from './NestedItem';
import ListItemIcon from '../list/ListItemIcon';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    backgroundColor: ({ isSubMenuOpen }: { isSubMenuOpen: boolean }) =>
      isSubMenuOpen ? theme.palette.action.hover : 'transparent',
    minWidth: '12rem',
    paddingRight: theme.spacing(),
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

/**
 * A menu that allows nested menus that expand when hovered over. Should not be
 * used in a mobile context.
 */
interface NestedMenuItemProps {
  /** Value of the nested menu item */
  value: string;
  /** Name of the nested menu item */
  name: string;
  /** Label for the nested menu item */
  label: string;
  /** Any children to nest into the menu */
  childrenItems?: NestedItemType[];
  /** Icon to add before this menu item */
  IconComponent?: any;
  /** Ref for the container element */
  container?: React.RefObject<HTMLDivElement>;
  /** If true this menu item will not be clickable */
  disabled?: boolean;
  /** If true this menu item is selected */
  selected?: boolean;
  /** What to do when this menu item is clicked */
  onClick: (e: any) => void;
}

const NestedMenuItem = React.forwardRef<any, NestedMenuItemProps>(
  (
    {
      value: parentValue,
      label: parentName,
      name,
      childrenItems: parentChildrenItems = [],
      onClick,
      IconComponent,
      container,
      disabled,
      selected,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isSubMenuOpen = Boolean(anchorEl);
    const classes = useStyles({ isSubMenuOpen });
    const hasChildrenItems = parentChildrenItems
      ? !!parentChildrenItems.length
      : false;
    const isLeafNode = !hasChildrenItems;

    const handleMouseEnter = (event: React.MouseEvent<any>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<any>) => {
      event.stopPropagation();
      if (isLeafNode) {
        onClick({ target: { name, label: parentName, value: parentValue } });
      }
    };

    const renderIcon = () => {
      if (IconComponent) {
        return (
          <ListItemIcon style={{ minWidth: '40px' }}>
            {IconComponent}
          </ListItemIcon>
        );
      }
      return <></>;
    };

    return (
      <MenuItem
        ref={ref}
        disableRipple
        className={classes.menuItem}
        disabled={disabled}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleClose}
        selected={selected}
      >
        {renderIcon()}
        <div className={classes.contentContainer}>
          <Typography variant="body2">{parentName}</Typography>
          {hasChildrenItems && <ArrowRightIcon color="action" />}
        </div>
        {hasChildrenItems && (
          <>
            <Menu
              // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
              style={{ pointerEvents: 'none' }}
              anchorEl={anchorEl}
              open={isSubMenuOpen}
              disableEnforceFocus
              container={container?.current}
              disableAutoFocus
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                elevation: 4,
              }}
            >
              {/* reset pointer event here so that the menu items could receive mouse events */}
              <div style={{ pointerEvents: 'auto' }}>
                {parentChildrenItems.map((item) => {
                  const {
                    value,
                    label,
                    disabled: childDisabled,
                    children,
                    IconComponent: Icon,
                    selected: childSelected,
                  } = item;
                  return (
                    <NestedMenuItem
                      key={label}
                      value={value}
                      label={label}
                      name={name}
                      disabled={childDisabled}
                      childrenItems={children}
                      IconComponent={Icon}
                      onClick={onClick}
                      selected={childSelected}
                    />
                  );
                })}
              </div>
            </Menu>
          </>
        )}
      </MenuItem>
    );
  }
);

// Used by AnchoredHiddenMenu to override the onClick
NestedMenuItem.displayName = 'MenuItem';

export default NestedMenuItem;
