import { Fragment, memo } from 'react';
import { makeStyles } from '@mui/styles';

import moment from 'moment';
import { Edit, Delete, Link, Close, VideocamOff } from '@onc/icons';
import {
  Divider,
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from 'base-components';
import { ListAnnotation } from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';
import AnnotationUtil from 'domain/Apps/seatube/util/AnnotationUtil';
import { SeaTubePermissionObj } from 'domain/services/SeaTubePermissionsService';
import { AnnotationListConfig } from 'domain/Widgets/AnnotationListWidget';
import AnnotationActions from 'library/CompositeComponents/annotation-list/AnnotationActions';
import { VideoInterval } from 'library/CompositeComponents/video/SeamlessVideoUtil';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';
import ObjectUtils from 'util/ObjectUtils';

const NO_VIDEO_MESSAGE =
  'No video archived for current resolution;\ntry another resolution if available';

const useStyles = makeStyles((theme) => ({
  selected: {
    background: `${theme.palette.highlight.main} !important`,
  },
  selectedDivider: {
    background: theme.palette.secondary.main,
    height: '2px',
  },
  toBeReviewed: {
    background: '#ea9999',
  },
  lastEdited: {
    width: theme.spacing(),
    backgroundColor: theme.palette.primary.main, // light blue
  },
  editing: {
    outlineOffset: '-2px',
    outline: `2px dashed ${theme.palette.primary.main}`, // light blue
  },
  attributes: {
    display: 'inline-block',
    textIndent: theme.spacing(2),
  },
  blockSpan: {
    display: 'block',
  },
  flexContainer: {
    display: 'flex',
  },
  annotationTimestamp: {
    marginRight: '16px',
    minWidth: '0',
    marginTop: '0',
    position: 'relative',
    userSelect: 'text',
  },
  annotationContent: {
    paddingRight: '80px',
    marginTop: 0,
    marginBottom: 0,
    userSelect: 'text',
  },
  tooltip: {
    display: 'flex',
    width: '100%',
  },
}));

type AnnotationListItemProps = {
  annotation: ListAnnotation;
  key: number;
  onClick: (annotation: ListAnnotation) => void;
  onEdit: (annotation: ListAnnotation) => void;
  onDelete: (annotation: ListAnnotation) => void;
  onCopyLink: (annotation: ListAnnotation) => void;
  onReviewClick: (id: number, value: 'up' | 'down' | undefined) => void;
  selected: boolean;
  config: AnnotationListConfig;
  permissions: SeaTubePermissionObj;
  lastEditedAnnotationId: number;
  editAnnotationId: number;
  isLiveMode: boolean;
  videoIntervals: VideoInterval[];
};

const AnnotationListItem: React.FC<AnnotationListItemProps> = ({
  annotation,
  config,
  key,
  onClick,
  onEdit,
  onDelete,
  onCopyLink,
  onReviewClick,
  selected,
  permissions,
  lastEditedAnnotationId,
  editAnnotationId,
  isLiveMode,
  videoIntervals,
}: AnnotationListItemProps) => {
  const classes = useStyles();

  const {
    displayFullTimestamp,
    displayPositionalData,
    displayModifiedBy,
    displayCopyLink,
    displayEditIcon,
    displayDeleteIcon,
    displayVoteIcons,
    displayCreatedBy,
    displayTaxonAttributes,
    highlightToBeReviewed,
  } = config;

  const {
    annotationPrivilege,
    deleteAnnotationPrivilege,
    selfDeleteAnnotationPrivilege,
    selfDeletePublicAnnotations,
    reviewPrivilege,
  } = permissions;

  // TODO: eventually use a user code for Environment
  const userId = Number(Environment.getDmasUserId());

  /* User can delete if they have the privilege to delete any annotation or if they 
  created the annotation and have the privilege to delete their own annotations */
  const canDelete =
    // Delete own unlisted annotations
    (selfDeleteAnnotationPrivilege &&
      annotation.createdBy.userCode === userId &&
      !annotation.isPublic) ||
    // Delete own public annotations
    (selfDeletePublicAnnotations &&
      annotation.createdBy.userCode === userId &&
      annotation.isPublic) ||
    // delete any annotation
    deleteAnnotationPrivilege;

  const generateLocationString = (lat, lon, heading, depth) => {
    let coordinateText = '';
    let elevationText = '';

    if (!lat && !lon && !depth && !heading) {
      return undefined;
    }

    if (lat === 0 && lon === 0) {
      coordinateText = 'LAT LON: N/A';
    } else {
      coordinateText = `LAT LON: ${Number.parseFloat(lat).toFixed(
        6
      )}, ${Number.parseFloat(lon).toFixed(6)}, `;
    }

    elevationText = 'Depth: ';
    elevationText += depth !== 0 ? `${Math.round(depth)}m` : 'N/A';

    elevationText += ', Heading: ';
    elevationText += heading !== 0 ? `${heading}°` : 'N/A';

    return `${coordinateText} ${elevationText}`;
  };

  const sortAttributes = (attributes) =>
    attributes.sort((a, b) => (a.name < b.name ? -1 : 1));

  const renderTaxon = () => {
    const { taxons } = annotation;

    let taxonText;
    let attributeText;

    if (taxons) {
      taxonText = (
        <Typography variant="body2" className={classes.blockSpan}>
          {AnnotationUtil.constructDisplayText(annotation, false)}
        </Typography>
      );
      if (taxons[0].attributes && displayTaxonAttributes) {
        const attributes = ObjectUtils.deepClone(taxons[0].attributes);
        attributeText = sortAttributes(attributes).map(({ name, value }) => (
          <Fragment key={`${name}-${value}`}>
            <span className={classes.attributes}>{`${name}: ${value}`}</span>
            <br />
          </Fragment>
        ));
        attributeText = (
          <Typography variant="body2">{attributeText}</Typography>
        );
      }

      return (
        <div>
          {taxonText}
          {attributeText}
        </div>
      );
    }

    return null;
  };

  const renderComment = () => {
    let finalComment = annotation.comment;
    if (annotation.comment === '') {
      if (!annotation.taxons) finalComment = '-';
      else return undefined;
    }
    return (
      <Typography variant="body2" className={classes.blockSpan}>
        {finalComment}
      </Typography>
    );
  };

  const renderUserText = () => {
    if (!displayCreatedBy && !displayModifiedBy) return undefined;

    const { createdBy, createdDate, modifiedBy, modifiedDate } = annotation;
    const creator = displayCreatedBy ? (
      <Typography
        className={classes.blockSpan}
        variant="caption"
      >{`Created By: ${createdBy.firstName} ${
        createdBy.lastName
      } (${DateFormatUtils.formatDate(createdDate, 'full')})`}</Typography>
    ) : undefined;

    const modifier =
      displayModifiedBy && createdDate !== modifiedDate ? (
        <Typography className={classes.blockSpan} variant="caption">
          {`Modified By: ${modifiedBy.firstName} ${
            modifiedBy.lastName
          } (${DateFormatUtils.formatDate(modifiedDate, 'full')})`}
        </Typography>
      ) : undefined;

    return (
      <div>
        {creator}
        {modifier}
      </div>
    );
  };

  const renderPositionalTags = () => {
    const { lat, lon, depth, heading } = annotation;

    if (!displayPositionalData) {
      return undefined;
    }

    const positionText = generateLocationString(lat, lon, heading, depth);

    return (
      <div>
        <span className={classes.blockSpan}>
          <Typography variant="caption">
            <span>{positionText}</span>
          </Typography>
        </span>
      </div>
    );
  };

  const renderAnnotationActions = () => {
    const { taxons } = annotation;
    const options = [];
    // Copy Link
    if (displayCopyLink) {
      options.push({
        label: 'Copy Link',
        onClick: (e) => {
          e.stopPropagation();
          onCopyLink(annotation);
        },
        icon: Link,
      });
    }
    // Edit Action
    if (annotationPrivilege && displayEditIcon) {
      const isBeingEdited = annotation.annotationId === editAnnotationId;
      options.push({
        label: isBeingEdited ? 'Cancel' : 'Edit Annotation',
        onClick: (e) => {
          e.stopPropagation();
          onEdit(isBeingEdited ? undefined : annotation);
        },
        icon: isBeingEdited ? Close : Edit,
      });
    }
    // Delete Action
    if (canDelete && displayDeleteIcon) {
      options.push({
        label: 'Delete Annotation',
        onClick: (e) => {
          e.stopPropagation();
          onDelete(annotation);
        },
        icon: Delete,
      });
    }

    let reviewProps;
    if (
      reviewPrivilege &&
      displayVoteIcons &&
      taxons &&
      taxons[0].taxonomyCode === 'WoRMS'
    ) {
      const { annotationReview } = taxons[0];
      reviewProps = {
        annotationId: annotation.annotationId,
        reviewValue: annotationReview
          ? annotationReview.reviewValue.toLowerCase()
          : undefined,
        onReviewClick,
      };
    }
    return <AnnotationActions options={options} {...reviewProps} />;
  };

  const renderDisplayText = () => (
    <>
      {renderTaxon()}
      {renderComment()}
      {renderPositionalTags()}
      {renderUserText()}
    </>
  );

  const timeFormat = displayFullTimestamp ? 'full' : 'time-no-seconds';
  const timestamp = DateFormatUtils.formatDate(
    annotation.startDate,
    timeFormat
  );

  const isDateInIntervals = (date: string): boolean => {
    if (!videoIntervals) return false;
    const dateToCheck = moment.utc(date);

    return videoIntervals.some((interval) => {
      const startDate = moment.utc(interval.startDate);
      const endDate = moment.utc(interval.endDate);
      return dateToCheck.isBetween(startDate, endDate, undefined, '[)');
    });
  };

  let annotationClasses = '';
  if (highlightToBeReviewed)
    annotationClasses += annotation.toBeReviewed
      ? ` ${classes.toBeReviewed}`
      : '';

  if (annotation.annotationId === editAnnotationId)
    annotationClasses += ` ${classes.editing}`;

  const renderWarning = () => {
    if (isLiveMode) return null;
    if (!videoIntervals) return null;
    if (!isDateInIntervals(annotation.startDate)) {
      return (
        <Tooltip
          placement="top"
          title={
            !isDateInIntervals(annotation.startDate) ? (
              <span style={{ whiteSpace: 'pre-line' }}>{NO_VIDEO_MESSAGE}</span>
            ) : (
              ''
            )
          }
        >
          <VideocamOff fontSize="small" sx={{ mr: 1 }} />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <span>
      {config.sortMostRecent && (
        <Divider className={selected ? classes.selectedDivider : undefined} />
      )}
      <div className={classes.flexContainer}>
        {lastEditedAnnotationId === annotation.annotationId && (
          <div
            className={classes.lastEdited}
            data-cy="last-edited-annotation"
          />
        )}
        <ListItem
          className={annotationClasses}
          key={key}
          button={!isLiveMode}
          alignItems="flex-start"
          onClick={!isLiveMode ? () => onClick(annotation) : undefined}
          role="listitem"
          selected={!isLiveMode ? selected : false}
          classes={{
            root: annotationClasses,
            selected: classes.selected,
          }}
        >
          <ListItemIcon className={classes.annotationTimestamp}>
            {renderWarning()}
            <Typography variant="body2">{timestamp}</Typography>
          </ListItemIcon>
          <ListItemText
            primary={renderDisplayText()}
            className={classes.annotationContent}
          />
          <ListItemSecondaryAction>
            {renderAnnotationActions()}
          </ListItemSecondaryAction>
        </ListItem>
      </div>
      {!config.sortMostRecent && (
        <Divider className={selected ? classes.selectedDivider : undefined} />
      )}
    </span>
  );
};

export default memo(AnnotationListItem);
