import { Moment } from 'moment';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

export type SensorValue = {
  sampleDate: string;
  sensorId: number;
  sensorName: string;
  uom: string;
  value: number | string;
};

type ServiceParameters = {
  resourceTypeId: number;
  resourceId: number;
};

export interface SeatubeSensorData {
  intervalDate: string;
  sensorValues: SensorValue[];
}

export interface SeatubeSample extends SeatubeSensorData {
  intervalDateObj: Moment;
}

export const useSeaTubeSensorData = (params: ServiceParameters) =>
  useGet<SeatubeSensorData[], ServiceParameters>(
    'seatube/sensordata',
    1,
    params
  );

class SeatubeSensorDataService {
  static getSensorData = async ({ resourceTypeId, resourceId }) =>
    get('seatube/sensordata', { resourceTypeId, resourceId }).then((response) =>
      parseDmasAPIResponse(response)
    );
}

export default SeatubeSensorDataService;
