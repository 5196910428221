import { useContext, useState, useCallback, useEffect } from 'react';
import { TableColumnWidthInfo, Sorting } from '@devexpress/dx-react-grid';
import { Table, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import EndeavourEarthquakeService from 'domain/services/EndeavourEarthquakeService';
import StatelessTable, {
  TableColumn,
} from 'library/BaseComponents/table/StatelessTable';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import DateFormatUtils from 'util/DateFormatUtils';
import { useSnackbars } from 'util/hooks/useSnackbars';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../../library/CompositeComponents/dashboard/DashboardTypes';
import EndeavourContext from '../context/EndeavourContext';
import { EarthquakeData } from '../EarthquakeData';
import AccelerationPlot from '../plots/EndeavourEarthquakeAccelerationPlot';

const ToggleCellComponent = ({ row, ...restProps }: any) => (
  <TableRowDetail.ToggleCell row={row} {...restProps} aria-label="expand-row" />
);
const columns: TableColumn[] = [
  { name: 'deviceCategory', title: 'Device Category', dataType: 'String' },
  { name: 'station', title: 'Station', dataType: 'String' },
  { name: 'phase', title: 'Phase', dataType: 'String' },
  {
    name: 'predictedTravelTime',
    title: 'Predicted Travel Time',
    dataType: 'Number',
  },
  {
    name: 'travelTimeResidual',
    title: 'Travel Time Residual',
    dataType: 'Number',
  },
  { name: 'weight', title: 'Weight', dataType: 'Number' },
  { name: 'arrivalTime', title: 'Arrival Time', dataType: 'Date' },
  { name: 'moment', title: 'Moment', dataType: 'Number' },
  { name: 'momentMagnitude', title: 'Moment Magnitude', dataType: 'Number' },
  { name: 'latitude', title: 'Latitude', dataType: 'Number' },
  { name: 'longitude', title: 'Longitude', dataType: 'Number' },
  { name: 'depth', title: 'Depth (km)', dataType: 'Number' },
];

const columnExtensions: Table.ColumnExtension[] = [
  { columnName: 'deviceCategory', width: 200, align: 'left' },
  { columnName: 'station', width: 100, align: 'left' },
  { columnName: 'phase', width: 100, align: 'left' },
  { columnName: 'predictedTravelTime', width: 200, align: 'left' },
  { columnName: 'travelTimeResidual', width: 200, align: 'left' },
  { columnName: 'weight', width: 100, align: 'left' },
  { columnName: 'arrivalTime', width: 200, align: 'left' },
  { columnName: 'moment', width: 100, align: 'left' },
  { columnName: 'momentMagnitude', width: 200, align: 'left' },
  { columnName: 'latitude', width: 100, align: 'left' },
  { columnName: 'longitude', width: 120, align: 'left' },
  { columnName: 'depth', width: 100, align: 'left' },
];

const resizingExtensions = [
  {
    columnName: 'deviceCategory',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'station',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'predictedTravelTime',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'travelTimeResidual',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'weight',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'arrivalTime',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'moment',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'momentMagnitude',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'latitude',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'longitude',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
  {
    columnName: 'depth',
    minWdith: ColumnInfo.mini,
    maxWdith: ColumnInfo.large,
  },
];

const EndeavourSiteDetectionTableWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;
  const { selectedEarthquake } = useContext(EndeavourContext);

  const defaultSorting: Sorting[] = [
    { columnName: 'arrivalTime', direction: 'asc' },
  ];
  const defaultConfig = {
    sorting: defaultSorting,
    hiddenColumns: [],
    columnOrder: columns.map((header) => header.name),
  };
  // Column Sorting
  const [sorting, setSorting] = useState(defaultConfig.sorting);
  const [currentPage, setCurrentPage] = useState(0);
  const [earthquakeData, setEarthquakeData] = useState<EarthquakeData | null>(
    undefined
  );
  const [expandedRows, setExpandedRows] = useState<(string | number)[]>([]);
  const RowDetail = useCallback(
    ({ row }) => <AccelerationPlot earthquakeSite={row} />,
    []
  );
  const { onError } = useSnackbars();
  const defaultWidths = columnExtensions.map(
    ({ align, ...rest }) => rest as TableColumnWidthInfo
  );

  const [columnWidths, setColumnWidths] =
    useState<TableColumnWidthInfo[]>(defaultWidths);

  // Column Ordering
  const [columnOrder, setColumnOrder] = useState<string[]>(
    defaultConfig.columnOrder
  );
  // Hidden Columns
  const [hiddenColumnNames, setHiddenColumns] = useState<string[]>(
    defaultConfig.hiddenColumns
  );

  const handleResetView = () => {
    setHiddenColumns(defaultConfig.hiddenColumns);
    setSorting(defaultConfig.sorting);
    setColumnOrder(defaultConfig.columnOrder);
    setColumnWidths(defaultWidths);
  };

  useEffect(() => {
    const getData = async () => {
      await EndeavourEarthquakeService.getEarthquakesById(selectedEarthquake)
        .then((result) => {
          setEarthquakeData(result.data);
        })
        .catch((error) => {
          onError(error.message);
        });
    };
    if (selectedEarthquake) {
      getData();
    } else {
      setEarthquakeData(undefined);
    }
  }, [selectedEarthquake, onError]);

  const renderTableRows = () =>
    earthquakeData?.siteDetections
      ? earthquakeData.siteDetections.map((row) => ({
          ...row,
          phase: row.phase !== undefined ? row.phase : undefined,
          arrivalTime:
            row.arrivalTime !== undefined
              ? DateFormatUtils.formatDate(row.arrivalTime, 'milliseconds')
              : undefined,
          moment:
            row.moment !== undefined ? row.moment.toExponential(2) : undefined,
          momentMagnitude:
            row.momentMagnitude !== undefined
              ? row.momentMagnitude.toFixed(4)
              : undefined,
          latitude:
            row.latitude !== undefined ? row.latitude.toFixed(2) : undefined,
          longitude:
            row.longitude !== undefined ? row.longitude.toFixed(2) : undefined,
          weight: row.weight !== undefined ? row.weight.toFixed(3) : undefined,
          depth:
            row.depth !== undefined ? (row.depth / 1000).toFixed(3) : undefined,
        }))
      : [];

  return (
    <Widget key={id} title="Site Detections" {...props}>
      <StatelessTable
        onReset={handleResetView}
        getRowId={(row) => row.id}
        rows={renderTableRows()}
        columns={columns}
        columnExtensions={columnExtensions}
        paging={{
          currentPage,
          onCurrentPageChange: (newPage) => setCurrentPage(newPage),
          pageSize: 10,
        }}
        virtual={{ virtualized: true }}
        messages={{ noData: 'No earthquake selected' }}
        expandRow={{
          ToggleCellComponent,
          expandedRows,
          RowDetail,
          handleExpandRowChange: (expandedRowIds) => {
            setExpandedRows(expandedRowIds);
          },
        }}
        visible={{
          hiddenColumnNames,
          handleChangeVisibility: setHiddenColumns,
        }}
        sort={{
          sorting,
          handleSortingChange: setSorting,
          customSorting: [
            {
              columnName: 'predictedTravelTime',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'travelTimeResidual',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'moment',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'momentMagnitude',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'latitude',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'longitude',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'depth',
              compare: (a, b) => a - b,
            },
          ],
        }}
        reorder={{
          columnOrder,
          handleColumnOrder: setColumnOrder,
          frozenLeftColumns: [],
          frozenRightColumns: [],
        }}
        resize={{
          columnWidths,
          handleColumnWidths: setColumnWidths,
          columnExtensions: resizingExtensions,
        }}
      />
    </Widget>
  );
};

EndeavourSiteDetectionTableWidget.widgetKey =
  'endeavour-earthquake-site-detection-table';
EndeavourSiteDetectionTableWidget.widgetTitle =
  'endeavour-earthquake-site-detection-table';
EndeavourSiteDetectionTableWidget.defaultDataGrid = {
  i: 'endeavour-earthquake-site-detection-table',
  x: 0,
  y: 0,
  w: 6,
  h: 5,
};

export default EndeavourSiteDetectionTableWidget;
