const defaultLayout = {
  xl: [
    {
      w: 8,
      h: 16,
      x: 0,
      y: 0,
      i: 'video-player-1',
      moved: false,
      static: false,
    },
    {
      w: 8,
      h: 4,
      x: 0,
      y: 6,
      i: 'clip-details-1',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 24,
      x: 8,
      y: 0,
      i: 'playlist-list-1',
      moved: false,
      static: false,
    },
  ],
  lg: [
    {
      w: 8,
      h: 16,
      x: 0,
      y: 0,
      i: 'video-player-1',
      moved: false,
      static: false,
    },
    {
      w: 8,
      h: 8,
      x: 0,
      y: 6,
      i: 'clip-details-1',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 24,
      x: 8,
      y: 0,
      i: 'playlist-list-1',
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 6,
      h: 16,
      x: 0,
      y: 0,
      i: 'video-player-1',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 8,
      x: 0,
      y: 6,
      i: 'clip-details-1',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 24,
      x: 6,
      y: 0,
      i: 'playlist-list-1',
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 8,
      h: 16,
      x: 0,
      y: 0,
      i: 'video-player-1',
      moved: false,
      static: false,
    },
    {
      w: 8,
      h: 8,
      x: 0,
      y: 6,
      i: 'clip-details-1',
      moved: false,
      static: false,
    },
    {
      w: 8,
      h: 24,
      x: 0,
      y: 12,
      i: 'playlist-list-1',
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 6,
      h: 16,
      x: 0,
      y: 0,
      i: 'video-player-1',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 8,
      x: 0,
      y: 5,
      i: 'clip-details-1',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 12,
      x: 0,
      y: 8,
      i: 'playlist-list-1',
      moved: false,
      static: false,
    },
  ],
};

const defaultWidgets = [
  {
    i: 'video-player-1',
    x: 0,
    y: 99999,
    w: 5,
    h: 5,
    widgetKey: 'video-player',
    title: 'Video Player',
  },
  {
    i: 'clip-details-1',
    x: 0,
    y: 99999,
    w: 5,
    h: 5,
    widgetKey: 'clip-details',
    title: 'Clip Details',
  },
  {
    i: 'playlist-list-1',
    x: 0,
    y: 99999,
    w: 12,
    h: 6,
    widgetKey: 'playlist-list',
    title: 'Playlist',
  },
];

export { defaultLayout, defaultWidgets };
