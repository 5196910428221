import React, { useCallback, useEffect, useRef } from 'react';
import { Index, List, ListRowRenderer } from 'react-virtualized';

type SizeableVirtualListProps = {
  /** Height of the list */
  height: number;
  /** Width of the list */
  width: number;
  /** Function to calculate the height of each row */
  rowHeight?: number | ((params: Index) => number);
  /** Number of rows */
  rowCount?: number;
  /** Function that will apply to each row to be rendered */
  rowRenderer?: ListRowRenderer;
  /** Allows parent to set the Ref for the listRef */
  setRef?: (ref: List) => void;
};

const SizeableVirtualList: React.FC<SizeableVirtualListProps> = ({
  height,
  width,
  rowHeight = 0,
  rowCount = 0,
  rowRenderer = () => null,
  setRef = () => {},
}) => {
  // Create ref to react-virtualized List component to access recomputeRowHeights method per documentation:
  // https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md

  // This library relies on refs, see their example on displaying items in reverse order:
  // https://github.com/bvaughn/react-virtualized/blob/master/docs/reverseList.md
  const listRef = useRef<List | null>(null);

  // Provide the ref to the parent via setRef callback
  useEffect(() => {
    setRef(listRef.current);
  }, [setRef]);

  /**
   * Call react-virtualized List ref to recompute row height. Call as a ref
   * because List only rerenders if the number of items in a list changes, not
   * the order, and the documentation and examples use refs. ¯_(ツ)_/¯ See
   * comment in listRef state at top of file.
   */
  const recomputeRowHeights = useCallback(() => {
    if (listRef.current) {
      listRef.current.recomputeRowHeights(0);
    }
  }, []);

  // Watch for width changes to recompute row heights
  useEffect(() => {
    recomputeRowHeights();
  }, [width, recomputeRowHeights]);

  return (
    <List
      height={height}
      width={width}
      ref={listRef}
      rowHeight={rowHeight}
      rowCount={rowCount}
      rowRenderer={rowRenderer}
    />
  );
};

export default SizeableVirtualList;
