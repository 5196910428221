import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle, Grid } from 'base-components';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import {
  CancelButton,
  ContainedButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import Environment from 'util/Environment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  leftJustify: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  leftAlign: {
    marginLeft: theme.spacing(1),
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type ExtensionConfigForm = {
  extensionId: number;
  extensionComment: string;
  extensionColorBand: string;
  length: number;
  modifyDate: string;
};

type Extension = {
  extensionId: number;
  comment: string;
  colorBand: string;
  length: number;
  modifyDate: string;
  modifyBy: string;
};

type ExtensionConfigProps = {
  title: string;
  onSave: (form: ExtensionConfigForm) => void;
  onCancel: () => void;
  extension?: Extension;
};

const ExtensionConfig = ({
  title,
  onSave,
  onCancel,
  extension = undefined,
}: ExtensionConfigProps) => {
  const classes = useStyles();

  const extensionId = () => {
    if (extension?.extensionId !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="extensionId"
            labelText="Extension ID"
            valueText={extension?.extensionId}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const modifyDate = () => {
    if (extension?.modifyDate !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="modifyDate"
            labelText="Modify Date"
            valueText={extension?.modifyDate}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const colorBand = () => {
    if (Environment.getDmasUserPrivilege() === 'RO') {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="extensionColorBand"
            labelText="Color Band"
            valueText={extension?.colorBand}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <FormTextField
          name="extensionColorBand"
          fullWidth
          translationKey="cables.colourBand"
        />
      </Grid>
    );
  };

  const comment = () => {
    if (Environment.getDmasUserPrivilege() === 'RO') {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="extensionComment"
            labelText="Extension Comment"
            valueText={extension?.comment}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <FormTextField
          translationKey="common.textfields.comment"
          fullWidth
          name="extensionComment"
        />
      </Grid>
    );
  };

  const length = () => {
    if (Environment.getDmasUserPrivilege() === 'RO') {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="length"
            labelText="Length"
            valueText={extension?.length}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <FormTextField
          name="length"
          fullWidth
          translationKey="cables.length"
          type="number"
        />
      </Grid>
    );
  };

  const renderButtons = () => {
    if (Environment.getDmasUserPrivilege() === 'RO') {
      return (
        <ContainedButton
          translationKey="common.buttons.done"
          onClick={onCancel}
        />
      );
    }
    return (
      <>
        <CancelButton onClick={onCancel} />
        <SaveButton type="submit" />
      </>
    );
  };

  const handleSave = (values) => {
    onSave({
      extensionId: values.extensionId,
      extensionComment: values.extensionComment,
      extensionColorBand: values.extensionColorBand,
      length: values.length === '' ? values.length : Number(values.length),
      modifyDate: values.modifyDate,
    });
  };

  const formMethods = useForm<ExtensionConfigForm>({
    mode: 'onBlur',
    defaultValues: {
      extensionId: extension?.extensionId,
      extensionComment: extension?.comment || '',
      extensionColorBand: extension?.colorBand || '',
      length: extension?.length,
      modifyDate: extension?.modifyDate,
    },
  });

  return (
    <Dialog open fullWidth>
      <DialogTitle>
        {Environment.getDmasUserPrivilege() === 'RO' ? 'View Extension' : title}
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSave} formMethods={formMethods}>
          <Grid container spacing={1} className={classes.root}>
            {extensionId()}
            {comment()}
            {colorBand()}
            {length()}
            {modifyDate()}
          </Grid>
          <Grid item className={classes.rightAlign}>
            {renderButtons()}
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ExtensionConfig;
