import { get } from 'util/WebRequest';
import {
  QAQC_AUTOTESTS_SERVICE,
  INSTRUMENT,
  OBSERVATORY,
  REGION,
  STATION,
  STUDY_AREA,
} from './QaqcAutotestsConstants';

class QaqcAutotestsService {
  static getByInstrument = (sensorId, sensorTypeId, activeOnly) =>
    QaqcAutotestsService.getQAQCFindBy({
      testLevel: INSTRUMENT,
      sensorId,
      sensorTypeId,
      activeOnly,
    });

  static getByObservatory = (qaqcTestGroupId, sensorTypeId, activeOnly) =>
    QaqcAutotestsService.getQAQCFindBy({
      testLevel: OBSERVATORY,
      qaqcTestGroupId,
      sensorTypeId,
      activeOnly,
    });

  static getByRegion = (qaqcTestGroupId, sensorTypeId, activeOnly) =>
    QaqcAutotestsService.getQAQCFindBy({
      testLevel: REGION,
      qaqcTestGroupId,
      sensorTypeId,
      activeOnly,
    });

  static getByStation = (searchTreeNodeId, sensorTypeId, activeOnly) =>
    QaqcAutotestsService.getQAQCFindBy({
      testLevel: STATION,
      searchTreeNodeId,
      sensorTypeId,
      activeOnly,
    });

  static getByStudyArea = (studyAreaId, sensorTypeId, activeOnly) =>
    QaqcAutotestsService.getQAQCFindBy({
      testLevel: STUDY_AREA,
      studyAreaId,
      sensorTypeId,
      activeOnly,
    });

  static getByActiveOnly = (activeOnly) =>
    QaqcAutotestsService.getQAQCFindBy({
      activeOnly,
    });

  static getQAQCFindBy = (params) =>
    get(QAQC_AUTOTESTS_SERVICE, {
      operation: 5,
      ...params,
    }).then((response) => response.data.payload);

  static getTestLevels = () =>
    get(QAQC_AUTOTESTS_SERVICE, {
      operation: 1,
    }).then((response) => response.data.payload);

  static getQAQCStudyAreas = () =>
    get(QAQC_AUTOTESTS_SERVICE, {
      operation: 2,
    }).then((response) => response.data.payload);

  static getSensorTypes = () =>
    get(QAQC_AUTOTESTS_SERVICE, {
      operation: 3,
    }).then((response) => response.data.payload);

  static getSensors = () =>
    get(QAQC_AUTOTESTS_SERVICE, {
      operation: 4,
    }).then((response) => response.data.payload);

  static getQAQCStations = () =>
    get(QAQC_AUTOTESTS_SERVICE, {
      operation: 6,
    }).then((response) => response.data.payload);

  static getQAQCTestGroups = () =>
    get(QAQC_AUTOTESTS_SERVICE, {
      operation: 7,
    }).then((response) => response.data.payload);

  static getQAQCBySensorIdsDeviceIds = (deviceIds, sensorIds, activeOnly) =>
    get(QAQC_AUTOTESTS_SERVICE, {
      operation: 8,
      deviceIds,
      sensorIds,
      activeOnly,
    });
}
export default QaqcAutotestsService;
