import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class SensorService {
  static get(sensorId) {
    return get('SensorService', {
      sensorId,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static update(params) {
    return post('SensorService', {
      operation: 2,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getSensorsForSensorCategoryId(params) {
    return post('SensorService', {
      operation: 2,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getSensorsForSensorTypeId(params) {
    return post('SensorService', {
      operation: 2,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getSensorsForSensorTypeAndCategoryId(params, param2) {
    return post('SensorService', {
      operation: 2,
      ...params,
      ...param2,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getAllSensors(params) {
    return post('SensorService', {
      operation: 2,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default SensorService;
