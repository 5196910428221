import { useState } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles, createStyles } from '@mui/styles';
import { Chip } from 'base-components';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

const STYLES = (theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      width: '400px',
      padding: '2rem',
      boxShadow: '0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1)',
      borderRadius: '0.3rem',
    },
    input: {
      width: '100%',
      height: '45px',
      padding: '0 1rem',
      marginTop: '1rem',
      boxSizing: 'border-box',
      font: 'inherit',
      borderRadius: '0.2rem',
      border: '2px solid #d4d5d6',
      color: '#565656',
    },
    tag: {
      backgroundColor: theme.palette.grey[500],
      display: 'inline-flex',
      fontSize: '14px',
      borderRadius: '30px',
      height: '30px',
      padding: '0 4px 0 1rem',
      alignItems: 'center',
      margin: '0 0.3rem 0.3rem 0',
    },
    button: {
      backgroundColor: 'white',
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
      font: 'inherit',
      marginLeft: '10px',
      fontWeight: 'bold',
      padding: '0',
      lineHeight: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

interface Props {
  handleChange: (value: string[]) => void;
  classes: any;
  emailItems: string[];
}

const MultiEmailField: React.VFC<Props> = ({
  classes,
  handleChange,
  emailItems,
}: Props) => {
  const [valueState, setValueState] = useState<string>('');
  const [error, setError] = useState<string>(null);

  const isInList = (email: string) => emailItems.includes(email);

  const isEmail = (email: string) =>
    /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);

  const isValid = (email: string) => {
    let errors = null;

    if (isInList(email)) {
      errors = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      errors = `${email} is not a valid email address.`;
    }

    if (errors) {
      setError(errors);
      return false;
    }
    return true;
  };

  const handleKeyDown = (evt: any) => {
    if (['Enter', 'Tab', ',', ' '].includes(evt.key)) {
      evt.preventDefault();
      const val = valueState.trim();
      if (val && isValid(val)) {
        handleChange([...emailItems, valueState]);
        setValueState('');
      }
    }
  };
  const handleEmailFieldNotEmpty = (evt) => {
    const newEvt = evt;
    newEvt.key = 'Enter';
    handleKeyDown(newEvt);
  };
  const onChange = (evt: any) => {
    setValueState(evt.target.value);
    setError(null);
  };

  const handleDelete = (item) => {
    handleChange(emailItems.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData('text');
    const pastedEmails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (pastedEmails) {
      const toBeAdded = pastedEmails.filter((email) => !isInList(email));
      handleChange([...emailItems, ...toBeAdded]);
    } else {
      setValueState(valueState + paste);
    }
  };

  return (
    <div>
      <FormTextField
        InputProps={{
          value: valueState,
          placeholder: 'Type or paste email and press `Enter`...',
          onKeyDown: handleKeyDown,
          onChange,
          onPaste: handlePaste,
          onMouseLeave: handleEmailFieldNotEmpty,
          onBlur: handleEmailFieldNotEmpty,
        }}
        name="emails"
        translationKey="common.textfields.email"
        multiline={false}
        fullWidth
      />
      {emailItems.map((item) => (
        <Chip label={item} onDelete={() => handleDelete(item)} />
      ))}
      {error && <p className={classes.error}>{error}</p>}
    </div>
  );
};

export default withStyles(STYLES)(MultiEmailField);
