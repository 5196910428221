import { post, get } from 'util/WebRequest';
import { QAQC_TEST_DETAILS_SERVICE } from './QaqcAutotestsConstants';

class QaqcTestDetailsService {
  static createQAQC = (payload) =>
    post(QAQC_TEST_DETAILS_SERVICE, {
      operation: 1,
      payload: JSON.stringify({ ...payload }),
    }).then((response) => response);

  static updateQAQC = (payload) =>
    post(QAQC_TEST_DETAILS_SERVICE, {
      operation: 2,
      payload: JSON.stringify({ ...payload }),
    }).then((response) => response);

  static getQAQCTDStudyAreas = () =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 1,
    }).then((response) => response.data.payload);

  static getQAQCTDSensorTypes = () =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 2,
    }).then((response) => response.data.payload);

  static getQAQCTDDeviceCategories = () =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 3,
    }).then((response) => response.data.payload);

  static getQAQCTDDevices = (deviceCategoryId) =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 4,
      deviceCategoryId,
    }).then((response) => response.data.payload);

  static getQAQCTDSensors = (deviceId) =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 5,
      deviceId,
    }).then((response) => response.data.payload);

  static getQAQCTDFormulas = (testLevel) =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 6,
      testLevel,
    }).then((response) => response.data.payload);

  static getQAQCTDAttributes = (sensorId) =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 7,
      sensorId,
    }).then((response) => response.data.payload);

  static getQAQCTD = (qaqcId) =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 8,
      qaqcId,
    }).then((response) => response.data.payload);

  static getQAQCTDStation = () =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 9,
    }).then((response) => response.data.payload);

  static getQAQCTestGroups = (qaqcTestGroupTypeId) =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 10,
      qaqcTestGroupTypeId,
    }).then((response) => response.data.payload);

  static getQAQCTestLevels = () =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 11,
    }).then((response) => response.data.payload);

  static createAttribute = (payload) =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 12,
      qaqcId: payload.qaqcId,
      attributeId: payload.attributeId,
      attributeValue: payload.attributeValue,
      effectiveDateFrom: payload.dateFrom,
      attributeComment: payload.attributeComment,
    });

  static updateAttribute = (payload) =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 13,
      sensorAttributeId: payload.sensorAttributeId,
      resourceAttributeId: payload.resourceAttributeId,
      attributeValue: payload.attributeValue,
      effectiveDateFrom: payload.dateFrom,
      attributeComment: payload.attributeComment,
    });

  static getQAQCDeviceSensor = (sensorId) =>
    get(QAQC_TEST_DETAILS_SERVICE, {
      operation: 14,
      sensorId,
    }).then((response) => response.data.payload);

  static deleteAttribute = (payload) =>
    post(QAQC_TEST_DETAILS_SERVICE, {
      operation: 3,
      sensorAttributeId: payload.sensorAttributeId,
      resourceAttributeId: payload.resourceAttributeId,
    });
}
export default QaqcTestDetailsService;
