import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import { WidgetOption } from 'library/CompositeComponents/dashboard/DashboardTypes';
import DeploymentsTable from './DeploymentsTableWidget';
import SeaTubeDashboard from '../sea-tube/SeaTubeDashboard';

const defaultAnonymousLayout = {
  xl: [],
  lg: [
    {
      w: 12,
      h: 24,
      x: 0,
      y: 0,
      i: 'deployments-table',
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 12,
      h: 24,
      x: 0,
      y: 0,
      i: 'deployments-table',
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 10,
      h: 24,
      x: 0,
      y: 0,
      i: 'deployments-table',
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 6,
      h: 24,
      x: 0,
      y: 0,
      i: 'deployments-table',
      moved: false,
      static: false,
    },
  ],
  xxs: [
    {
      w: 4,
      h: 24,
      x: 0,
      y: 0,
      i: 'deployments-table',
      moved: false,
      static: false,
    },
  ],
};

const defaultAnonymousWidgets = [
  {
    i: 'deployments-table',
    x: 0,
    y: 99999,
    w: 12,
    h: 24,
    widgetKey: 'deployments-table',
    title: 'Deployments Table',
  },
];

const widgetOptions: WidgetOption[] = [
  {
    Component: DeploymentsTable,
    label: 'Deployments',
    multiple: false,
  },
];

const SynchronizerDeploymentsDashboard = () => {
  const widgetLibrary = new WidgetLibrary(widgetOptions);

  return (
    <SeaTubeDashboard
      title="Deployments"
      id="synchronizer-deployments"
      layoutKey="User-synch-deployments"
      widgetLibrary={widgetLibrary}
      defaultLayout={defaultAnonymousLayout}
      defaultWidgets={defaultAnonymousWidgets}
      isStatic
    />
  );
};

export default SynchronizerDeploymentsDashboard;
