import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid, Link, Typography } from 'base-components';
import Environment from 'util/Environment';

const styles = (theme) => ({
  headingStyle: {
    paddingTop: theme.spacing(2),
  },
  linkStyle: {
    paddingTop: theme.spacing(1),
  },
});
class DOIDataLinks extends Component {
  static propTypes = {
    doiDataSearchURL: PropTypes.string,
    deviceDetails: PropTypes.shape({
      deviceId: PropTypes.number,
      name: PropTypes.string,
      resourceId: PropTypes.number,
    }),
    classes: PropTypes.shape({
      headingStyle: PropTypes.string,
      indentLevel: PropTypes.string,
      linkStyle: PropTypes.string,
    }).isRequired,
    isForSubset: PropTypes.bool,
  };

  static defaultProps = {
    doiDataSearchURL: undefined,
    deviceDetails: undefined,
    isForSubset: false,
  };

  renderLink = (linkText, linkUrl) => {
    const { classes } = this.props;
    if (linkUrl) {
      return (
        <Link
          className={classes.linkStyle}
          href={`${Environment.getDmasUrl()}/${linkUrl}`}
          target="_blank"
        >
          {linkText}
        </Link>
      );
    }
    return null;
  };

  render() {
    const { doiDataSearchURL, deviceDetails, isForSubset, classes } =
      this.props;
    let dataSearchUrl;
    let deviceListUrl;
    let metadataUrl;
    let deviceName = 'this device';
    let siteDeviceId = 'NaN';
    if (doiDataSearchURL) {
      dataSearchUrl = `DataSearch?${doiDataSearchURL.toString()}`;
    }
    if (deviceDetails) {
      deviceListUrl = `DeviceListing?DeviceId=${deviceDetails.deviceId.toString()}`;
      deviceName = deviceDetails.name;
      if (deviceDetails.resourceId) {
        siteDeviceId = deviceDetails.resourceId.toString();
        metadataUrl = isForSubset
          ? `ISO19115XMLService?siteDeviceSubsetId=${siteDeviceId}`
          : `ISO19115XMLService?siteDeviceId=${siteDeviceId}`;
      }
    }
    if (doiDataSearchURL || deviceDetails) {
      return (
        <>
          <Typography className={classes.headingStyle} variant="h6">
            Data Links
          </Typography>
          <Grid
            key="Data Links"
            className={classes.indentLevel}
            container
            direction="column"
            name="Data Links"
          >
            {!isForSubset && (
              <>
                <Grid
                  key="Data Links - Data Download"
                  item
                  xs={12}
                  className={classes.indentLevel}
                  name="Data Download"
                >
                  <>
                    {this.renderLink(
                      'Download data using Data Search',
                      dataSearchUrl
                    )}
                  </>
                </Grid>

                <Grid
                  key="Data Links - Device Listing"
                  item
                  xs={12}
                  className={classes.indentLevel}
                  name="Device Listing"
                >
                  <>
                    {this.renderLink(
                      `View device details for ${deviceName}`,
                      deviceListUrl
                    )}
                  </>
                </Grid>
              </>
            )}
            <Grid
              key="Data Links - ISO19115 Metadata Download"
              item
              xs={12}
              className={classes.indentLevel}
              name="ISO19115 Metadata Download"
            >
              <>
                {this.renderLink(
                  `Download latest ISO 19115 XML metadata`,
                  metadataUrl
                )}
              </>
            </Grid>
          </Grid>
        </>
      );
    }
    return null;
  }
}
export default withStyles(styles)(DOIDataLinks);
