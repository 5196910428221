import { useEffect, useState } from 'react';
import * as React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { Divider, Paper, Tab } from 'base-components';
import SeaTubeConfigurationService from 'domain/services/SeaTubeConfigurationService';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { GLOBAL_USER } from './GlobalConfigConstants';
import ObisConfiguration from './ObisConfiguration';
import ResourceConfiguration from './ResourceConfiguration';

export type OrganiziationConfiguration = {
  configurationCategory: string;
  configurationId: number;
  configurationName: string;
  configurationValue: ConfigValue;
  ownerId: number;
  ownerTypeId: number | null;
};
export type ConfigValue = {
  noTaxonomy?: ResourceConfigLine;
  worms?: ResourceConfigLine;
  cmecs?: ResourceConfigLine;
  decklog?: ResourceConfigLine;
  nullableAttributes?: boolean;
  keepTaxonomy?: boolean;
};

type ResourceConfigLine = {
  resource: string;
  resourceType: number;
};

interface ConfigurationContentProps {
  ownerId: number;
  ownerTypeId: number;
  permission: boolean;
  configs: OrganiziationConfiguration[];
  reloadConfigs: (...args: any[]) => Promise<any>;
}

// add makestyles so that the tabpanel is centered with a max width
const useStyles = makeStyles((theme: Theme) => ({
  panelContent: {
    maxWidth: '900px',
    margin: 'auto',
  },
  contentPaper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

const ConfigurationContent: React.VFC<ConfigurationContentProps> = (
  props: ConfigurationContentProps
) => {
  const { ownerId, ownerTypeId, configs, permission, reloadConfigs } = props;
  const classes = useStyles();
  const [tabValue, setTabValue] = useState<string>('resource');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentConfig, setCurrentConfig] = useState<ConfigValue>();
  const [savedConfig, setSavedConfig] = useState<ConfigValue>();
  const [configurationId, setConfigurationId] = useState<number>();
  const [globalConfig, setGlobalConfig] = useState<ConfigValue>();
  const { onError, onInfo } = useSnackbars();

  const getCurrentConfig = () => {
    // Handle setting the global config
    const globalConfiguration = configs.find(
      (config) =>
        config.ownerId === GLOBAL_USER.ID &&
        config.ownerTypeId === GLOBAL_USER.OWNER_TYPE &&
        config.configurationCategory === tabValue
    );
    const selectedConfig = configs.find(
      (config) =>
        config.ownerId === ownerId &&
        config.ownerTypeId === ownerTypeId &&
        config.configurationCategory === tabValue
    );
    const currentConfiguration: ConfigValue = _.cloneDeep(
      selectedConfig ? selectedConfig.configurationValue : {}
    );
    const savedConfiguration: ConfigValue = _.cloneDeep(currentConfiguration);

    setCurrentConfig(currentConfiguration);
    setSavedConfig(savedConfiguration);
    setConfigurationId(selectedConfig?.configurationId);
    const globalConf: ConfigValue = globalConfiguration
      ? { ...globalConfiguration.configurationValue }
      : undefined;
    if (globalConf) {
      setGlobalConfig(globalConf);
    }
  };
  useEffect(() => {
    getCurrentConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetConfig = () => {
    setSavedConfig(undefined);
    setCurrentConfig(undefined);
    setTabValue('resource');
    getCurrentConfig();
  };

  useEffect(() => {
    resetConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerId, ownerTypeId]);

  const handleTabChange = (event, value: string) => setTabValue(value);

  const handleConfigChange = (config: ConfigValue) => {
    setCurrentConfig(config);
  };

  const handleCancel = () => {
    setCurrentConfig(savedConfig);
  };

  const handleSaveSuccess = (config: ConfigValue) => {
    setSavedConfig(_.cloneDeep(config));
    setLoading(false);
    reloadConfigs();
    onInfo('Saved changes to the configuration');
  };

  const handleSaveError = (error) => {
    setLoading(false);
    onError(error);
  };

  const handleConfigSave = async () => {
    if (savedConfig && configurationId) {
      return SeaTubeConfigurationService.update(configurationId, currentConfig)
        .then(() => {
          handleSaveSuccess(currentConfig);
        })
        .catch((error) => {
          handleSaveError(error);
        });
    }
    return SeaTubeConfigurationService.create(
      ownerId,
      ownerTypeId,
      tabValue,
      currentConfig
    )
      .then(() => {
        handleSaveSuccess(currentConfig);
      })
      .catch((error) => {
        handleSaveError(error);
      });
  };

  const handleSaveClick = () => {
    handleConfigSave();
  };

  const hasUnsavedChanges = () => !_.isEqual(savedConfig, currentConfig);

  const renderObisTab = () => (
    <TabPanel value="obis" className={classes.panelContent}>
      <Paper className={classes.contentPaper}>
        <ObisConfiguration />
      </Paper>
    </TabPanel>
  );

  return (
    <TabContext value={tabValue}>
      <TabList
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
        onChange={handleTabChange}
      >
        <Tab label="Annotation Entry" value="resource" />
        {ownerId === GLOBAL_USER.ID ? (
          <Tab label="OBIS" value="obis" />
        ) : (
          <Tab label="" value="null" disabled />
        )}
        {/* At least two tabs must be defined or a console error is thrown */}
        {/* TODO
          <Tab label="Button Sets" value="buttonSets" disabled />
          <Tab label="Layouts" value="layouts" disabled />
          
          <Tab label="Taxonomies" value="taxonomies" />
          */}
      </TabList>
      <Divider />
      <TabPanel value="resource" className={classes.panelContent}>
        <Paper className={classes.contentPaper}>
          <ResourceConfiguration
            globalConfig={globalConfig}
            config={currentConfig}
            onChange={handleConfigChange}
            ownerId={ownerId}
            ownerTypeId={ownerTypeId}
            permission={permission}
            onSave={handleSaveClick}
            onCancel={handleCancel}
            saveDisabled={!hasUnsavedChanges()}
            isLoading={loading}
          />
        </Paper>
      </TabPanel>
      {ownerId === GLOBAL_USER.ID ? renderObisTab() : undefined}
    </TabContext>
  );
};

export default ConfigurationContent;
