import * as React from 'react';
import { Moment } from 'moment';
import { DateRange } from '@onc/icons';
import { DateTimePicker, Grid } from 'base-components';
import Filter, {
  FilterImplementationProps,
  FilterValue,
  SummaryValues,
} from 'library/CompositeComponents/filter/Filter';
import DateUtils from 'util/DateUtils';

export interface DateFilterValue extends FilterValue {
  fromDate?: Moment;
  toDate?: Moment;
}

interface Props extends FilterImplementationProps {
  // FilterEvent causes errors in annotationSearchFilters.tsx
  onChange?: any;
  name: string;
  hideIcon?: boolean;
  value?: DateFilterValue;
  title?: string;
}

const DateFilter: React.FC<Props> = ({
  onChange = undefined,
  name,
  value = undefined,
  hideIcon = false,
  onExpand,
  expanded,
  title = undefined,
}: Props) => {
  const renderSummaryValues = () => {
    if (value) {
      const summary: SummaryValues[] = [];
      const fromDateString = DateUtils.formatDateAsString(value.fromDate);
      const toDateString = DateUtils.formatDateAsString(value.toDate);
      if (fromDateString && toDateString) {
        summary.push({ label: `${fromDateString}  -  ${toDateString}` });
      } else if (fromDateString && !toDateString) {
        summary.push({ label: `After: ${fromDateString}` });
      } else if (!fromDateString && toDateString) {
        summary.push({ label: `Before: ${toDateString}` });
      }
      return summary;
    }
    return undefined;
  };

  const handleOnClear = () => {
    if (onChange) {
      onChange({ target: { name, value: { fromDate: null, toDate: null } } });
    }
  };

  const handleOnChange = (newValue: DateFilterValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Filter
      title={title || 'Date (UTC)'}
      icon={
        hideIcon ? null : <DateRange data-test="filterIcon" color="action" />
      }
      value={value}
      name={name}
      onChange={handleOnChange}
      summaryValues={renderSummaryValues()}
      onClear={handleOnClear}
      onExpand={onExpand}
      expanded={expanded}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <DateTimePicker
            name="fromDate"
            translationKey="common.datepickers.startDate"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateTimePicker
            name="toDate"
            translationKey="common.datepickers.endDate"
            fullWidth
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default DateFilter;
