import { Grid, Typography } from 'base-components';

type ShareButtonProps = {
  children: React.ReactNode;
  label?: string;
};

/**
 * ShareButton component that wraps its children in a grid container and adds a
 * label, if desired.
 */
const ShareButton = ({ children, label = undefined }: ShareButtonProps) => {
  const renderLabel = () => {
    if (label) {
      return (
        <Grid item>
          <Typography>{label}</Typography>
        </Grid>
      );
    }
    return null;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>{children}</Grid>
      {renderLabel()}
    </Grid>
  );
};

export default ShareButton;
