import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import {
  ShowChart,
  Description,
  XmlIcon,
  Bookmarks,
  CsvIcon,
} from '@onc/icons';
import {
  PLOT_DATA_PRODUCT,
  COR_AND_CSV_DATA_PRODUCT,
  ANNOTATION_DATA_PRODUCT,
  XML_DATA_PRODUCT,
  COMPLETED,
  ERROR,
  RESTRICTED,
} from '../definitions/GeospatialSearchConstants';
import {
  ProductNames,
  SiteDeviceSubsetProduct,
} from '../definitions/GeospatialSearchTypes';

const styles = (theme: Theme) =>
  createStyles({
    default: {
      color: theme.palette.primary.main,
    },
    done: {
      color: theme.palette.secondary.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    restricted: {
      color: theme.palette.warning.main,
    },
    divStyle: {
      'margin-right': '5px',
      display: 'inline-block',
    },
  });

interface Props extends WithStyles<typeof styles> {
  products: SiteDeviceSubsetProduct[];
}

const SelectedProductIcons: React.VFC<Props> = (props: Props) => {
  const { products, classes } = props;

  const createIconForGivenProduct = (
    productName: ProductNames,
    IconForProduct,
    extension: string
  ) => {
    if (
      !products.some(
        (prod) =>
          prod.productName === productName && prod.extension === extension
      )
    ) {
      return <></>;
    }
    const product = products.find(
      (prod) => prod.productName === productName && prod.extension === extension
    );
    let classToUse = classes.default;
    switch (product?.status) {
      case COMPLETED:
        classToUse = classes.done;
        break;
      case ERROR:
        classToUse = classes.error;
        break;
      case RESTRICTED:
        classToUse = classes.restricted;
        break;
      default:
        classToUse = classes.default;
    }

    return (
      <div className={classes.divStyle}>
        <IconForProduct className={classToUse} title="product icon" />
      </div>
    );
  };

  return (
    <span>
      {createIconForGivenProduct(PLOT_DATA_PRODUCT, ShowChart, 'png')}
      {createIconForGivenProduct(COR_AND_CSV_DATA_PRODUCT, Description, 'cor')}
      {createIconForGivenProduct(ANNOTATION_DATA_PRODUCT, Bookmarks, 'csv')}
      {createIconForGivenProduct(XML_DATA_PRODUCT, XmlIcon, 'xml')}
      {createIconForGivenProduct(COR_AND_CSV_DATA_PRODUCT, CsvIcon, 'csv')}
    </span>
  );
};

export default withStyles(styles)(SelectedProductIcons);
