import { useState, useEffect } from 'react';
import { Grid } from 'base-components';
import { useUpdateBatchDetailsMutation } from 'domain/AppComponents/batch/hooks/BatchTaskMutationHooks';
import { FormDialog } from 'domain/AppComponents/dialogs/FormDialog';
import BatchTaskDetailTextFields from './BatchTaskDetailTextFields';

const BatchTaskUpdateDialog = ({
  open,
  onClose,
  defaultInput,
  taskId = 0,
}: {
  open: boolean;
  taskId?: number;
  defaultInput: any[] | null;
  onClose: () => void;
}) => {
  const [input, setInput] = useState<{ [key: string]: any }[]>([]);
  const updateBatchDetailsMutation = useUpdateBatchDetailsMutation();

  useEffect(() => {
    if (!defaultInput) return;
    const inputArray: { [key: string]: any }[] = [];
    for (const [key, value] of Object.entries(defaultInput)) {
      inputArray.push({ key, value });
    }
    setInput(inputArray);
  }, [defaultInput]);

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      onSubmit={() => {
        updateBatchDetailsMutation.mutate({ taskId, taskDetails: [...input] });
        onClose();
      }}
      confirmButtonTranslationKey="common.buttons.update"
      title="Update Batch Task"
    >
      <Grid container spacing={2}>
        <BatchTaskDetailTextFields
          taskDetails={input || []}
          onTextFieldChange={setInput}
        />
      </Grid>
    </FormDialog>
  );
};

export default BatchTaskUpdateDialog;
