import { get } from 'util/WebRequest';

const FILTER_SERVICE = 'DeviceFilterService';

class DeviceFilterService {
  static async getFilteredSiteDevices(depthFrom, depthTo, dateFrom, dateTo) {
    const hasDepthFilter = depthFrom > -1 || depthTo < 2001;
    return get(FILTER_SERVICE, {
      operationtype: '100',
      depthFrom: hasDepthFilter ? depthFrom : undefined,
      depthTo: hasDepthFilter ? depthTo : undefined,
      dateFrom,
      dateTo,
      drifterFilterValue: false,
    });
  }

  static async getFixedDepthMap() {
    return get(FILTER_SERVICE, {
      operationtype: '101',
    });
  }
}
export default DeviceFilterService;
