import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CardList from 'library/CompositeComponents/card-list/CardList';
import DFCard from './DFCard';

const styles = () => ({});
class CampaignList extends Component {
  static propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.shape({})),
    classes: PropTypes.shape({
      link: PropTypes.string,
      root: PropTypes.string,
    }),
  };

  static defaultProps = {
    campaigns: [],
    classes: undefined,
  };

  renderCards = () => {
    const { campaigns, classes } = this.props;
    return campaigns
      .filter((campaign) => campaign.active)
      .map((campaign) => (
        <DFCard
          key={campaign.dfCampaignId}
          campaign={campaign}
          classes={classes}
        />
      ));
  };

  render() {
    const { classes } = this.props;
    return <CardList className={classes.root}>{this.renderCards()}</CardList>;
  }
}

export default withStyles(styles)(CampaignList);
