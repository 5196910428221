import { Component } from 'react';
import { CheckCircle, Cancel } from '@onc/icons';
import {
  ClickAwayListener,
  Grid,
  IconButton,
  TextField,
} from 'base-components';

type InlineEditFieldProps = {
  value: string;
  handleSave: (event: any) => void;
  label: string;
};

type InlineEditFieldState = {
  editedValue: string;
  edit: boolean;
};

class InlineEditField extends Component<
  InlineEditFieldProps,
  InlineEditFieldState
> {
  constructor(props) {
    super(props);
    this.state = {
      editedValue: props.value,
      edit: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.edit && nextProps.value !== prevState.value) {
      return { editedValue: nextProps.value };
    }
    return null;
  }

  handleSaveValue() {
    const { handleSave } = this.props;
    const { editedValue, edit } = this.state;
    if (edit) {
      handleSave(editedValue);
      this.setState({ edit: false });
    }
  }

  renderField() {
    const { label } = this.props;
    const { editedValue } = this.state;
    return (
      <Grid item xs={12}>
        <TextField
          translationKey="common.textfields.comment"
          onChange={(e) => this.setState({ editedValue: e.target.value })}
          onFocus={() => this.setState({ edit: true })}
          title={label}
          value={editedValue}
          name={label}
          fullWidth
          multiline
          variant="outlined"
        />
      </Grid>
    );
  }

  renderEditControls() {
    const { edit } = this.state;
    if (edit) {
      return (
        <>
          <IconButton onClick={() => this.handleSaveValue()} aria-label="ok">
            <CheckCircle color="primary" />
          </IconButton>
          <IconButton
            onClick={() => this.setState({ edit: false })}
            aria-label="cancel"
          >
            <Cancel />
          </IconButton>
        </>
      );
    }
    return null;
  }

  render() {
    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={() => this.handleSaveValue()}
      >
        <Grid container justifyContent="flex-end" spacing={1}>
          {this.renderField()}
          {this.renderEditControls()}
        </Grid>
      </ClickAwayListener>
    );
  }
}

export default InlineEditField;
