import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class ElectricalRatingService {
  static get(deviceId) {
    return get('DeviceElectricalRatingService', {
      operation: 4,
      deviceId,
    }).then((response) => response);
  }

  static save(deviceId, params) {
    return post('DeviceElectricalRatingService', {
      operation: 1,
      deviceId,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static update(deviceId, params) {
    return post('DeviceElectricalRatingService', {
      operation: 2,
      deviceId,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static delete(electricalRatingId) {
    return post('DeviceElectricalRatingService', {
      operation: 3,
      electricalRatingId,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default ElectricalRatingService;
