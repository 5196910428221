import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class WidgetService {
  static get(params) {
    return get('WidgetService', params).then((response) =>
      parseDmasAPIResponse(response)
    );
  }

  static post(params) {
    return post('WidgetService', params).then((response) =>
      parseDmasAPIResponse(response)
    );
  }
}
export default WidgetService;
