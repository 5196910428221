import { useState } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import Draggable from 'react-draggable';
import { Close, ExpandMore } from '@onc/icons';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from 'base-components';

const STYLES = (theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      minWidth: '350px',
      maxWidth: '550px',
      position: 'sticky',
      left: '40%',
      zIndex: 50,
      height: 0,
    },
    header: {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      width: '100%',
    },
    variableScroll: {
      overflow: 'auto',
      maxHeight: '65vh',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    title: {
      margin: 'none',
      marginLeft: theme.spacing(1),
    },
    closeButton: {
      marginLeft: theme.spacing(),
    },
  });

interface Props extends WithStyles<typeof STYLES> {
  title: string;
  initiallyExpanded?: boolean;
  onClose: (event: object) => void;
  actions?: any;
  children?: any;
}

const DraggableToolbox: React.FC<Props> = ({
  title,
  classes,
  children = undefined,
  initiallyExpanded = undefined,
  onClose,
  actions = undefined,
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  const expandClass = expanded ? classes.expandOpen : classes.expand;
  return (
    <Draggable
      bounds="body"
      handle="#toolbox-handle"
      positionOffset={{ x: 0, y: 80 }}
    >
      <div className={classes.root} data-test="draggable-toolbox">
        <Accordion
          expanded={expanded}
          className={classes.variableScroll}
          role="dialog"
          aria-label={title}
        >
          <AccordionSummary
            className={classes.header}
            id="toolbox-handle"
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}
          >
            <div className={classes.flexContainer}>
              <Typography
                title="header"
                className={classes.title}
                variant="h6"
                color="inherit"
              >
                {`${title}`}
              </Typography>

              <IconButton
                className={expandClass}
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                color="inherit"
                id="expand"
                aria-label={expanded ? 'Show Less' : 'Show More'}
              >
                <ExpandMore />
              </IconButton>
              <IconButton
                className={classes.closeButton}
                onClick={onClose}
                aria-expanded={expanded}
                color="inherit"
                aria-label="Close"
              >
                <Close />
              </IconButton>
            </div>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
          <AccordionActions
            sx={{
              backgroundColor: 'white',
              position: 'sticky',
              bottom: 0,
              zIndex: 1,
            }}
          >
            {actions}
          </AccordionActions>
        </Accordion>
      </div>
    </Draggable>
  );
};

export default withStyles(STYLES)(DraggableToolbox);
