const defaultLayout = {
  name: 'Public',
  key: 'default-public-layout',
  permission: true,
  selected: true,
  isPublic: true,
  layout: {
    lg: [
      {
        h: 23,
        i: 'expedition-list',
        w: 4,
        x: 0,
        y: 0,
        minH: 9,
        minW: 2,
      },
      {
        h: 23,
        i: 'expedition-map',
        w: 5,
        x: 4,
        y: 0,
        minH: 9,
        minW: 2,
      },
      {
        h: 23,
        i: 'expedition-details',
        w: 3,
        x: 9,
        y: 0,
        minH: 9,
        minW: 3,
      },
    ],
    md: [
      {
        h: 10,
        i: 'expedition-list',
        w: 12,
        x: 0,
        y: 0,
        minH: 9,
        minW: 2,
      },
      {
        h: 10,
        i: 'expedition-map',
        w: 12,
        x: 0,
        y: 10,
        minH: 9,
        minW: 2,
      },
      {
        h: 10,
        i: 'expedition-details',
        w: 12,
        x: 0,
        y: 20,
        minH: 9,
        minW: 4,
      },
    ],
    sm: [
      {
        h: 10,
        i: 'expedition-list',
        w: 6,
        x: 0,
        y: 0,
        minH: 10,
        minW: 3,
      },
      {
        h: 10,
        i: 'expedition-map',
        w: 6,
        x: 0,
        y: 10,
        minH: 10,
        minW: 3,
      },
      {
        h: 10,
        i: 'expedition-details',
        w: 6,
        x: 0,
        y: 20,
        minH: 10,
        minW: 3,
      },
    ],
    xs: [
      {
        h: 10,
        i: 'expedition-list',
        w: 3,
        x: 0,
        y: 0,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'expedition-map',
        w: 3,
        x: 0,
        y: 10,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'expedition-details',
        w: 3,
        x: 0,
        y: 20,
        minH: 9,
        minW: 3,
      },
    ],
    xxs: [
      {
        h: 10,
        i: 'expedition-list',
        w: 3,
        x: 0,
        y: 0,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'expedition-map',
        w: 3,
        x: 0,
        y: 10,
        minH: 9,
        minW: 3,
      },
      {
        h: 10,
        i: 'expedition-details',
        w: 3,
        x: 0,
        y: 20,
        minH: 9,
        minW: 3,
      },
    ],
  },
};

export default defaultLayout;
