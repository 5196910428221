import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';

const STYLES = (theme: Theme) =>
  createStyles({
    highlighting: {
      backgroundColor: 'rgba(151, 215, 0, 1)',
      height: theme.spacing(1),
      width: '100%',
      bottom: theme.spacing(0),
      position: 'absolute',
      zIndex: 1,
    },
  });

interface PanelCardHighlightProps extends WithStyles<typeof STYLES> {
  highlighted: boolean;
}

const PanelCardHighlighting: React.FC<PanelCardHighlightProps> = (
  props: PanelCardHighlightProps
) => {
  const { classes, highlighted } = props;
  return highlighted === true ? (
    <div className={classes.highlighting} data-testid="highlight" />
  ) : null;
};

export default withStyles(STYLES)(PanelCardHighlighting);
