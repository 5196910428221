import { makeStyles } from '@mui/styles';
import { Loading } from '@onc/composite-components';
import Panel from 'library/CompositeComponents/panel/Panel';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { useSnackbars } from 'util/hooks/useSnackbars';
import SensorMaintenancePanel from './SensorMaintenancePanel';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '950px',
    height: theme.spacing(37),
  },
}));

type SensorServicePayload = {
  sensor: any;
};

type SensorMaintenancePageProps = {
  sensorId: number;
};

const SensorQueryHandler = ({ sensorId }: SensorMaintenancePageProps) => {
  const classes = useStyles();
  const { onError, onInfo } = useSnackbars();

  const sensor = useGet<SensorServicePayload, { sensorId: number }>(
    'SensorService',
    { operation: 0 },
    { sensorId }
  );

  if (sensor.isLoading) {
    return <Loading />;
  }

  if (sensor.error) {
    onError(sensor.error.message);
    return undefined;
  }

  return (
    <div className={classes.root}>
      <Panel>
        <SensorMaintenancePanel
          onError={onError}
          onInfo={onInfo}
          sensor={sensor.data.sensor}
        />
      </Panel>
    </div>
  );
};

const SensorMaintenancePage = ({ sensorId }: SensorMaintenancePageProps) => (
  <SensorQueryHandler sensorId={sensorId} />
);

export default SensorMaintenancePage;
