import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import { Sensor } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';

class QaqcDetailsSensorDropdown extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    permission: PropTypes.string.isRequired,
    value: PropTypes.number,
    deviceId: PropTypes.number,
    onGetSensors: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
    disabled: false,
    initialValue: 0,
    value: 0,
    deviceId: 0,
    onGetSensors: () => {},
  };

  state = {
    sensors: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getSensors();
  }

  componentDidUpdate(prevProps) {
    const { deviceId } = this.props;
    if (prevProps.deviceId !== deviceId) {
      this.getSensors();
    }
  }

  getSensor = () => {
    const { value } = this.props;
    const { sensors } = this.state;

    const currSensor = sensors.find((sensor) => sensor.value === value);
    if (!currSensor || value === 0) return '';
    return currSensor.label;
  };

  getSensors = () => {
    const { deviceId, onGetSensors } = this.props;

    if (deviceId === 0) {
      this.setState({
        sensors: [{ label: 'Select', value: 0 }],
      });
      return;
    }

    QaqcTestDetailsService.getQAQCTDSensors(deviceId)
      .then((results) => {
        const sensors = results.map((sensor) => ({
          label: sensor.sensorName,
          value: sensor.sensorId,
        }));
        this.setState({
          sensors: [{ label: 'Select', value: 0 }, ...sensors],
        });
        onGetSensors(sensors);
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const {
      classes,
      disabled,
      initialValue,
      permission,
      value,
      onChange,
      ...rest
    } = this.props;
    const { sensors } = this.state;

    if (permission === 'RW') {
      return (
        <Dropdown
          disabled={disabled}
          initialValue={initialValue}
          label="Sensor"
          options={sensors}
          value={value}
          onChange={onChange}
          {...rest}
        />
      );
    }

    return (
      <Sensor
        classes={{ root: classes.roFieldRoot }}
        sensor={this.getSensor()}
      />
    );
  }
}
export default QaqcDetailsSensorDropdown;
