import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid } from 'base-components';
import MultiQueueInformationPanel from 'domain/AppComponents/multi-queue/MultiQueueInformationPanel';
import MultiQueueTreePanel from 'domain/AppComponents/multi-queue/MultiQueueTreePanel';
import MultiQueueService from 'domain/AppComponents/multi-queue/service/MultiQueueService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';

const styles = (theme) => ({
  // TODO: This is the padding used to add whitespace around the page.
  // These defaults are useful for a number of cases but should likely be changed.
  // In general you want no more than 1 unit of spacing for the smallest breakpoint "theme.breakpoints.down('sm')"
  // and enough padding for larger breakpoints to only use as much of the page as needed to display your
  // AppComponent effectively

  root: {
    overflow: 'hidden',
  },
  grid: {
    padding: theme.spacing(1),
  },
});

class MultiQueuePage extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  getUpdatedNode = (treeData, oldNode) => {
    let newNode;
    const findNode = (treeItem, node) =>
      treeItem.forEach((item) => {
        if (
          item.objectId === node.objectId &&
          item.multiQueueTypeId === node.multiQueueTypeId
        ) {
          newNode = item;
        }
        if (Array.isArray(item.children)) {
          if (item.children.length) {
            findNode(item.children, node);
          }
        }
        return null;
      });
    findNode(treeData, oldNode);
    return newNode;
  };

  constructor(props) {
    super(props);
    this.state = {
      queueList: [],
      selectedQueue: undefined,
      initialLoad: false,
    };
  }

  componentDidMount() {
    this.setState({ permission: Environment.getDmasUserPrivilege() });
    this.getTree();
  }

  getTree = () => {
    const { onError } = this.props;
    const { selectedNode } = this.state;
    MultiQueueService.getTree()
      .then((payload) => {
        if (selectedNode) {
          const updatedNode = this.getUpdatedNode(payload, selectedNode);
          this.setState({
            treeData: payload,
            selectedNode: updatedNode,
          });
        } else {
          this.setState({ treeData: payload });
        }
      })
      .catch(() => {
        onError('failed to load mutli queue tree');
      });
  };

  selectNode = async (event, node) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      selectedNode: node,
    });
  };

  render() {
    const { classes, onInfo, onError } = this.props;
    const { selectedNode, treeData, permission } = this.state;
    return (
      <div className={classes.root}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} lg={12} xl={12}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <MultiQueueTreePanel
                  treeData={treeData}
                  selectedNode={selectedNode}
                  selectNode={this.selectNode}
                />
              </Grid>
              <Grid item xs={9}>
                <MultiQueueInformationPanel
                  node={selectedNode}
                  refreshTree={this.getTree}
                  treeData={treeData}
                  onInfo={onInfo}
                  onError={onError}
                  permission={permission}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(MultiQueuePage));
