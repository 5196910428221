import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import QaqcAutotestsService from 'domain/services/QaqcAutotestsService';

class QaqcFinderSensorDropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    sensorTypeId: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    initialValue: 0,
    sensorTypeId: 0,
    value: 0,
  };

  state = {
    sensors: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getSensors();
  }

  getSensors = () => {
    QaqcAutotestsService.getSensors()
      .then((results) => {
        const sensors = results.map((sensor) => ({
          label: `${sensor.sensorId} on ${sensor.deviceName}`,
          value: sensor.sensorId,
          sensorTypeId: sensor.sensorTypeId,
        }));
        this.setState({
          sensors: [{ label: 'Find All', value: 0 }, ...sensors],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  getFilteredSensors() {
    const { sensorTypeId } = this.props;
    const { sensors } = this.state;

    if (sensorTypeId === 0) {
      return sensors;
    }
    return sensors.filter(
      (sensor) => sensor.value === 0 || sensor.sensorTypeId === sensorTypeId
    );
  }

  render() {
    const { disabled, initialValue, value, onChange, ...rest } = this.props;
    return (
      <Dropdown
        disabled={disabled}
        initialValue={initialValue}
        label="Sensor"
        options={this.getFilteredSensors()}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }
}
export default QaqcFinderSensorDropdown;
