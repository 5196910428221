import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid, Slider, SliderTooltipLabel, Typography } from 'base-components';

const styles = (theme) => ({
  slider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
});

const getMarks = () => {
  const markList = [];
  markList.push({ value: -500, label: 'Above Sea Level' });
  for (let x = 0; x <= 2000; x += 50) {
    markList.push({ value: x, label: x % 500 === 0 ? x : undefined });
  }
  markList.push({ value: 2400, label: '2000+' });
  return markList;
};

const marks = getMarks();

const DepthRangeSlider = (props) => {
  DepthRangeSlider.propTypes = {
    classes: PropTypes.shape({ slider: PropTypes.string }).isRequired,
    depthRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    onChangeCommitted: PropTypes.func.isRequired,
  };

  const { classes, depthRange, onChangeCommitted } = props;

  const renderLabelValue = (val) => {
    if (val < 0) {
      return 'Above Sea Level';
    }
    if (val > 2000) {
      return '2000+';
    }
    return val;
  };

  const [depthFrom, setDepthFrom] = useState(depthRange[0]);
  const [depthTo, setDepthTo] = useState(depthRange[1]);

  useEffect(() => {
    setDepthFrom(depthRange[0]);
    setDepthTo(depthRange[1]);
  }, [depthRange]);

  const handleSliderChange = (event, newValue) => {
    setDepthFrom(newValue[0]);
    setDepthTo(newValue[1]);
  };

  return (
    <>
      <Typography variant="caption" id="discrete-slider-custom" gutterBottom>
        Deployment Depth Range (m)
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Slider
            ValueLabelComponent={SliderTooltipLabel}
            className={classes.slider}
            value={[depthFrom, depthTo]}
            aria-labelledby="discrete-slider-custom"
            onChange={handleSliderChange}
            marks={marks}
            max={2400}
            min={-500}
            step={null}
            valueLabelFormat={renderLabelValue}
            valueLabelDisplay="auto"
            onChangeCommitted={onChangeCommitted}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(DepthRangeSlider);
