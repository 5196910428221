import React from 'react';
import { Delete } from '@onc/icons';
import { Button } from 'base-components';

type SeaTubeDeleteButtonProps = {
  onClick: () => void;
  id?: string;
  disabled?: boolean;
  className?: string;
};

const SeaTubeDeleteButton: React.FC<SeaTubeDeleteButtonProps> = ({
  id = 'delete-button',
  disabled = false,
  className = undefined,
  onClick,
}) => (
  <Button
    id={id}
    disabled={disabled}
    className={className}
    color="error"
    translationKey="common.buttons.delete"
    variant="contained"
    startIcon={<Delete />}
    onClick={onClick}
  />
);

export default SeaTubeDeleteButton;
