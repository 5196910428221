import { FC, useState } from 'react';
import { Grid, TextField } from 'base-components';
import LocationsTreeData from './LocationsTreeData';

type Props = {
  data: any;
  setSelectedNodes: (any) => void;
};

const LocationsTree: FC<Props> = ({ data, setSelectedNodes }) => {
  const [filterText, setFilterText] = useState<string>();

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      direction="column"
      alignItems="stretch"
      style={{ height: '100%' }}
    >
      <Grid item>
        <TextField
          translationKey="common.search"
          name="searchInput"
          data-test="search-input"
          helperText="Minimum 3 characters"
          fullWidth
          onChange={(e) => setFilterText(e.target.value)}
          value={filterText}
        />
      </Grid>
      <Grid item xs style={{ overflowY: 'auto' }}>
        <LocationsTreeData
          filterText={filterText}
          treeData={data}
          setSelectedNodes={setSelectedNodes}
        />
      </Grid>
    </Grid>
  );
};

export default LocationsTree;
