import useGet from 'util/hooks/useDmasAPI/useGet';
import { post, get, patch, deleteImpl } from 'util/WebRequest';

type TaxonomyJson = {
  taxonomyId: number;
  taxonomyName: string;
  taxonomyCode: string;
  version: string;
  taxonomyTopUrl: string;
  referenceUrl: string;
  description?: string;
  editable: boolean;
  modifiedBy: number;
  modifiedDate: string;
  taxonomyMatrixId: number;
};

export const useTaxonomies = () =>
  useGet<TaxonomyJson[], { method: string }>('internal/taxonomies', {
    operation: undefined,
    transform: (response) =>
      response.data.filter((taxonomy) => taxonomy.taxonomyName !== 'WoRDSS'),
  });

class TaxonomyService {
  static async getAll() {
    const response = await get('internal/taxonomies');
    return response.data.filter(
      (taxonomy) => taxonomy.taxonomyName !== 'WoRDSS'
    );
  }

  static async getTaxonomy(taxonomyId) {
    const response = await get(`internal/taxonomies/${taxonomyId}`);
    return response.data;
  }

  static async addTaxonomy(taxonomy) {
    const response = await post(
      'internal/taxonomies',
      {
        payload: JSON.stringify({
          taxonomyName: taxonomy.taxonomyName,
          taxonomyCode: taxonomy.taxonomyCode,
          version: taxonomy.version,
          taxonomyTopUrl: taxonomy.taxonomyTopUrl,
          referenceUrl: taxonomy.referenceUrl,
          description: taxonomy.description,
          editable: taxonomy.editable,
        }),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async updateTaxonomy(taxonomyId, taxonomy) {
    const response = await patch(
      `internal/taxonomies/${taxonomyId}`,
      {
        payload: JSON.stringify({
          taxonomyName: taxonomy.taxonomyName,
          taxonomyCode: taxonomy.taxonomyCode,
          version: taxonomy.version,
          taxonomyTopUrl: taxonomy.taxonomyTopUrl,
          referenceUrl: taxonomy.referenceUrl,
          description: taxonomy.description,
          editable: taxonomy.editable,
        }),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async deleteTaxonomy(taxonomyId) {
    const response = await deleteImpl(`internal/taxonomies/${taxonomyId}`);
    return response.data;
  }
}
export default TaxonomyService;
