import { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Moment } from 'moment';
import { FiberManualRecord, Videocam } from '@onc/icons';
import { oceans3Theme } from '@onc/theme';
import { IconButton } from 'base-components';

import { useSnackbars } from 'util/hooks/useSnackbars';
import SeamlessVideoRecording from '../SeamlessVideoRecording';

type RecordVideoButtonProps = {
  deviceId: number;
  currentTimestamp: Moment;
  containerRef?: HTMLDivElement;
  onIsPlaying: (isPlaying: boolean) => void;
};

const RecordVideoButton: React.FC<RecordVideoButtonProps> = ({
  deviceId,
  currentTimestamp,
  containerRef = undefined,
  onIsPlaying,
}: RecordVideoButtonProps) => {
  const { onInfo } = useSnackbars();
  const [isRecording, setIsRecording] = useState(false);
  const [endRecordingTime, setEndRecordingTime] = useState(null);
  const [startRecordingTime, setStartRecordingTime] = useState(null);

  const handleRecording = (e) => {
    if (isRecording) {
      setEndRecordingTime(currentTimestamp);
      setIsRecording(false);
    } else {
      setIsRecording(true);
      onInfo('Started recording');
      setStartRecordingTime(currentTimestamp);
    }
    e.stopPropagation();
  };

  const handleSavePlaylistDialog = () => {
    onIsPlaying(true);
    setStartRecordingTime(null);
    setEndRecordingTime(null);
  };
  const renderRecording = () => {
    if (!startRecordingTime || !endRecordingTime) {
      return undefined;
    }
    return (
      <ThemeProvider theme={oceans3Theme}>
        <SeamlessVideoRecording
          deviceId={deviceId}
          onClose={handleSavePlaylistDialog}
          recording={isRecording}
          startTime={startRecordingTime}
          endTime={endRecordingTime}
          onPause={() => onIsPlaying(false)}
        />
      </ThemeProvider>
    );
  };

  return (
    <div>
      <IconButton
        aria-label={isRecording ? 'Stop Recording' : 'Start Recording'}
        disabled={!!document.fullscreenElement}
        onClick={(e) => {
          handleRecording(e);
        }}
        color="inherit"
        tooltipProps={{
          slotProps: {
            popper: { container: containerRef },
          },
        }}
      >
        {isRecording ? <FiberManualRecord color="error" /> : <Videocam />}
      </IconButton>
      {renderRecording()}
    </div>
  );
};

export default RecordVideoButton;
