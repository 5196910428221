import PropTypes from 'prop-types';
import DataProductDownloadProgress from 'domain/AppComponents/geospatial-search/community-fisher/DataProductDownloadProgress';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import { NOT_DOWNLOADED } from '../definitions/GeospatialSearchConstants';

const DATA_PRODUCT_TYPE = 'Plot';

const DataProductPlotProgress = ({
  classes = undefined,
  status = undefined,
  errorStatus,
  restrictedStatus,
  doneStatus,
  handleOpenPlotById,
  siteDeviceSubsetId,
}) => {
  DataProductPlotProgress.propTypes = {
    classes: PropTypes.shape({
      linkButtonPrimaryOverride: PropTypes.string,
      thinButton: PropTypes.string,
    }),
    status: PropTypes.string,
    errorStatus: PropTypes.string.isRequired,
    restrictedStatus: PropTypes.string.isRequired,
    doneStatus: PropTypes.string.isRequired,
    handleOpenPlotById: PropTypes.func.isRequired,
    siteDeviceSubsetId: PropTypes.number.isRequired,
  };

  if (!status || status === NOT_DOWNLOADED) return null;

  if (status !== doneStatus) {
    return (
      <DataProductDownloadProgress
        status={status}
        dataProductType={DATA_PRODUCT_TYPE}
        errorStatus={errorStatus}
        restrictedStatus={restrictedStatus}
      />
    );
  }
  return (
    <TextButton
      translationKey="communityFishers.displayDataProductType"
      translationOptions={{ dataProductType: DATA_PRODUCT_TYPE }}
      onClick={() => handleOpenPlotById(siteDeviceSubsetId)}
      size="small"
      className={`${classes.linkButtonPrimaryOverride} ${classes.thinButton}`}
    />
  );
};

export default DataProductPlotProgress;
