const defaultLayout = {
  lg: [
    {
      w: 12,
      h: 12,
      x: 0,
      y: 0,
      i: 'endeavour-earthquake-filters',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 14,
      x: 0,
      y: 12,
      i: 'endeavour-earthquake-table',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 14,
      x: 6,
      y: 12,
      i: 'endeavour-earthquake-map',
      moved: false,
      static: false,
    },
    {
      i: 'endeavour-earthquake-activity-plot',
      x: 0,
      y: 26,
      w: 5,
      h: 14,
      widgetKey: 'endeavour-earthquake-activity-plot',
      title: 'Earthquake Activity Plot',
    },
    {
      i: 'endeavour-earthquake-site-detection-table',
      x: 7,
      y: 26,
      w: 7,
      h: 14,
      widgetKey: 'endeavour-earthquake-site-detection-table',
      title: 'Earthquake Site Detection Table',
    },
    {
      i: 'endeavour-earthquake-latitude-plot',
      x: 0,
      y: 40,
      w: 5,
      h: 14,
      widgetKey: 'endeavour-earthquake-latitude-plot',
      title: 'Earthquake Latitude Plot',
    },
    {
      i: 'endeavour-earthquake-magnitude-plot',
      x: 5,
      y: 40,
      w: 5,
      h: 14,
      widgetKey: 'endeavour-earthquake-magnitude-plot',
      title: 'Earthquake Magnitude Plot',
    },
  ],
  md: [
    {
      w: 12,
      h: 12,
      x: 0,
      y: 0,
      i: 'endeavour-earthquake-filters',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 12,
      x: 0,
      y: 12,
      i: 'endeavour-earthquake-table',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 12,
      x: 6,
      y: 12,
      i: 'endeavour-earthquake-map',
      moved: false,
      static: false,
    },
    {
      i: 'endeavour-earthquake-site-detection-table',
      x: 7,
      y: 24,
      w: 5,
      h: 14,
      widgetKey: 'endeavour-earthquake-site-detection-table',
      title: 'Earthquake Site Detection Table',
    },
    {
      i: 'endeavour-earthquake-activity-plot',
      x: 0,
      y: 24,
      w: 5,
      h: 14,
      widgetKey: 'endeavour-earthquake-activity-plot',
      title: 'Earthquake Activity Plot',
    },
    {
      i: 'endeavour-earthquake-latitude-plot',
      x: 0,
      y: 34,
      w: 5,
      h: 14,
      widgetKey: 'endeavour-earthquake-latitude-plot',
      title: 'Earthquake Latitude Plot',
    },
    {
      i: 'endeavour-earthquake-magnitude-plot',
      x: 5,
      y: 21,
      w: 5,
      h: 14,
      widgetKey: 'endeavour-earthquake-magnitude-plot',
      title: 'Earthquake Magnitude Plot',
    },
  ],
  sm: [
    {
      w: 8,
      h: 12,
      x: 0,
      y: 0,
      i: 'endeavour-earthquake-filters',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 12,
      x: 0,
      y: 12,
      i: 'endeavour-earthquake-table',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 12,
      x: 4,
      y: 12,
      i: 'endeavour-earthquake-map',
      moved: false,
      static: false,
    },
    {
      i: 'endeavour-earthquake-activity-plot',
      x: 0,
      y: 24,
      w: 4,
      h: 12,
      widgetKey: 'endeavour-earthquake-activity-plot',
      title: 'Earthquake Activity Plot',
    },
    {
      i: 'endeavour-earthquake-site-detection-table',
      x: 4,
      y: 24,
      w: 4,
      h: 12,
      widgetKey: 'endeavour-earthquake-site-detection-table',
      title: 'Earthquake Site Detection Table',
    },
    {
      i: 'endeavour-earthquake-latitude-plot',
      x: 0,
      y: 36,
      w: 4,
      h: 12,
      widgetKey: 'endeavour-earthquake-latitude-plot',
      title: 'Earthquake Latitude Plot',
    },
    {
      i: 'endeavour-earthquake-magnitude-plot',
      x: 4,
      y: 36,
      w: 4,
      h: 12,
      widgetKey: 'endeavour-earthquake-magnitude-plot',
      title: 'Earthquake Magnitude Plot',
    },
  ],
  xs: [
    {
      w: 3,
      h: 12,
      x: 0,
      y: 0,
      i: 'endeavour-earthquake-filters',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 12,
      x: 0,
      y: 12,
      i: 'endeavour-earthquake-table',
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 12,
      x: 3,
      y: 12,
      i: 'endeavour-earthquake-map',
      moved: false,
      static: false,
    },
    {
      i: 'endeavour-earthquake-site-detection-table',
      x: 0,
      y: 24,
      w: 4,
      h: 12,
      widgetKey: 'endeavour-earthquake-site-detection-table',
      title: 'Earthquake Site Detection Table',
    },
    {
      i: 'endeavour-earthquake-activity-plot',
      x: 0,
      y: 24,
      w: 4,
      h: 12,
      widgetKey: 'endeavour-earthquake-activity-plot',
      title: 'Earthquake Activity Plot',
    },
    {
      i: 'endeavour-earthquake-latitude-plot',
      x: 0,
      y: 36,
      w: 4,
      h: 12,
      widgetKey: 'endeavour-earthquake-latitude-plot',
      title: 'Earthquake Latitude Plot',
    },
    {
      i: 'endeavour-earthquake-magnitude-plot',
      x: 4,
      y: 36,
      w: 4,
      h: 12,
      widgetKey: 'endeavour-earthquake-magnitude-plot',
      title: 'Earthquake Magnitude Plot',
    },
  ],
};

const defaultWidgets = [
  {
    i: 'endeavour-earthquake-filters',
    x: 0,
    y: 10,
    w: 2,
    h: 8,
    widgetKey: 'endeavour-earthquake-filters',
    title: 'Earthquake Filters',
  },
  {
    i: 'endeavour-earthquake-table',
    x: 0,
    y: 10,
    w: 6,
    h: 8,
    widgetKey: 'endeavour-earthquake-table',
    title: 'Earthquake Table',
  },
  {
    i: 'endeavour-earthquake-map',
    x: 0,
    y: 10,
    w: 6,
    h: 5,
    widgetKey: 'endeavour-earthquake-map',
    title: 'Earthquake Map',
  },
  {
    i: 'endeavour-earthquake-activity-plot',
    x: 0,
    y: 10,
    w: 5,
    h: 7,
    widgetKey: 'endeavour-earthquake-activity-plot',
    title: 'Earthquake Activity Plot',
  },
  {
    i: 'endeavour-earthquake-site-detection-table',
    x: 0,
    y: 10,
    w: 4,
    h: 7,
    widgetKey: 'endeavour-earthquake-site-detection-table',
    title: 'Earthquake Site Detection Table',
  },
  {
    i: 'endeavour-earthquake-latitude-plot',
    x: 0,
    y: 15,
    w: 5,
    h: 7,
    widgetKey: 'endeavour-earthquake-latitude-plot',
    title: 'Earthquake Latitude Plot',
  },
  {
    i: 'endeavour-earthquake-magnitude-plot',
    x: 5,
    y: 15,
    w: 5,
    h: 7,
    widgetKey: 'endeavour-earthquake-magnitude-plot',
    title: 'Earthquake Magnitude Plot',
  },
];

export { defaultLayout, defaultWidgets };
