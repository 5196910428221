import { useForm } from 'react-hook-form';
import { DialogContent } from 'base-components';
import { useCancelBatchMutation } from 'domain/AppComponents/batch/hooks/BatchTaskMutationHooks';
import FormDialog from 'library/CompositeComponents/dialog/FormDialog_new';

const BatchCancelDialog = ({
  open,
  onClose,
  taskId = 0,
}: {
  open: boolean;
  onClose: () => void;
  taskId?: number;
}) => {
  const cancelBatchMutation = useCancelBatchMutation();
  const formMethods = useForm({});

  return (
    <FormDialog
      open={open}
      formMethods={formMethods}
      onClose={onClose}
      title="Cancel Task"
      onSubmit={() => {
        cancelBatchMutation.mutate(taskId);
        onClose();
      }}
      confirmButtonTranslationKey="taskmanagement.cancelTask"
      cancelButtonTranslationKey="taskmanagement.doNotCancel"
    >
      <DialogContent>
        Are you sure you want to cancel task {taskId}?
      </DialogContent>
    </FormDialog>
  );
};

export default BatchCancelDialog;
