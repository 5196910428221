import { parseDmasAPIResponse, get } from 'util/WebRequest';

class DeviceSensorService {
  static getMany(deviceId) {
    return DeviceSensorService.get({ deviceId });
  }

  static get(params) {
    return get('DeviceSensorService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default DeviceSensorService;
