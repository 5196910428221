import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { Typography, TextField } from 'base-components';
import { BaseReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import {
  CancelButton,
  ContainedButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import SearchableList from 'library/CompositeComponents/list-container/SearchableList';
import Panel from 'library/CompositeComponents/panel/Panel';
import Environment from 'util/Environment';

const styles = (theme) => ({
  contentContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  readOnlyField: {
    flexShrink: 1,
  },
  buttonPairContainer: {
    textAlign: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
});

class GroupInformationPanel extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    attributeList: PropTypes.arrayOf(
      PropTypes.shape({
        groupId: PropTypes.number,
      })
    ).isRequired,
    handleChangeAttribute: PropTypes.func.isRequired,
    selectedAttribute: PropTypes.shape({ attributeId: PropTypes.number }),
    selectedGroup: PropTypes.shape({
      groupId: PropTypes.number,
      key: PropTypes.number,
      name: PropTypes.string,
    }).isRequired,
    handleDeleteAttribute: PropTypes.func.isRequired,
    handleCreateAttribute: PropTypes.func.isRequired,
    handleUpdateGroup: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedAttribute: undefined,
  };

  state = {
    searchInput: '',
    currentGroup: undefined,
  };

  componentDidMount() {
    this.createCurrentGroup();
  }

  componentDidUpdate(prevProps) {
    const { selectedGroup } = this.props;
    const { selectedGroup: prevSelectedGroup } = prevProps;
    if (selectedGroup !== prevSelectedGroup) {
      this.createCurrentGroup();
    }
  }

  createCurrentGroup = () => {
    const { selectedGroup } = this.props;
    this.setState({ currentGroup: selectedGroup });
  };

  handleSearchInput = (e) => {
    this.setState({ searchInput: e.target.value });
  };

  handleGroupName = (e) => {
    const { currentGroup } = this.state;
    const updatedGroup = { ...currentGroup };
    updatedGroup.name = e.target.value;
    this.setState({
      currentGroup: updatedGroup,
    });
  };

  getAttributesForGroup = () => {
    const { attributeList, selectedGroup } = this.props;
    if (selectedGroup.name === 'All Attributes') {
      return attributeList;
    }

    return attributeList
      .filter((attribute) => attribute.groupId === selectedGroup.groupId)
      .map((attr) => ({
        key: attr.attributeId,
        ...attr,
      }));
  };

  actionComponents = (item) => {
    const { handleDeleteAttribute } = this.props;
    if (Environment.getDmasUserPrivilege() === 'RW') {
      return <DeleteIconButton onClick={() => handleDeleteAttribute(item)} />;
    }
    return undefined;
  };

  renderAddAttributeButton = () => {
    const { handleCreateAttribute } = this.props;
    return Environment.getDmasUserPrivilege() === 'RW' ? (
      <ContainedButton
        translationKey="common.buttons.addAttribute"
        startIcon={<Add />}
        onClick={handleCreateAttribute}
      />
    ) : undefined;
  };

  renderGroupNameField = () => {
    const { selectedGroup, handleUpdateGroup, classes } = this.props;
    const { currentGroup } = this.state;
    if (Environment.getDmasUserPrivilege() === 'RW') {
      return (
        <>
          <TextField
            translationKey="taxonomy.groupName"
            disabled={selectedGroup.key === 'All Attributes'}
            value={currentGroup ? currentGroup.name : ''}
            onChange={(e) => this.handleGroupName(e)}
          />
          {selectedGroup.groupId !== 0 ? (
            <div className={classes.buttonPairContainer}>
              <CancelButton onClick={this.createCurrentGroup} />
              <SaveButton onClick={() => handleUpdateGroup(currentGroup)} />
            </div>
          ) : undefined}
        </>
      );
    }
    return (
      <BaseReadOnlyField
        labelText="Group Name"
        valueText={selectedGroup.name}
        className={classes.readOnlyField}
      />
    );
  };

  render() {
    const { classes, handleChangeAttribute, selectedAttribute } = this.props;
    const { searchInput } = this.state;
    return (
      <Panel
        title="Group Information"
        actionContent={this.renderAddAttributeButton()}
      >
        <div className={classes.contentContainer}>
          {this.renderGroupNameField()}
          <Typography variant="subtitle1" className={classes.subtitle}>
            Attributes
          </Typography>
          <SearchableList
            searchText={searchInput}
            handleClickItem={handleChangeAttribute}
            actionComponents={this.actionComponents}
            selectedItem={
              selectedAttribute ? selectedAttribute.attributeId : undefined
            }
            handleSearchInput={this.handleSearchInput}
            listItems={this.getAttributesForGroup()}
          />
        </div>
      </Panel>
    );
  }
}

export default withStyles(styles)(GroupInformationPanel);
