import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from 'base-components';
import DOIDatasetConstants from 'domain/AppComponents/datasets/DOIDatasetConstants';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import useHeaderStyles from './useHeaderStyles';

const DOIDatasetLinks = ({ resourceId = undefined, fetchDOIDataset }) => {
  const [doiDataset, setDOIDataset] = useState([]);
  useEffect(() => {
    const getDataset = () => {
      if (!isNaN(resourceId) && resourceId !== 0) {
        fetchDOIDataset(resourceId)
          .then((payload) => {
            if (payload.dataset && Object.keys(payload.dataset).length > 0) {
              setDOIDataset(payload.dataset);
            }
          })
          .catch(() => {});
      }
    };
    getDataset();
  }, [fetchDOIDataset, resourceId]);

  const renderDOIDataset = () => {
    if (doiDataset && doiDataset.DOIDataset) {
      return (
        <Grid item xs={12}>
          <OpenInNewLink
            href={`/DatasetLandingPage?doidataset=${doiDataset.DOIDataset}`}
          >
            {doiDataset.DOIDataset}
          </OpenInNewLink>
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <ReadOnlyField
          labelText=""
          valueText={DOIDatasetConstants.NO_DOI_DATASET_FOUND}
          title="datasetLinkField"
        />
      </Grid>
    );
  };

  const classes = useHeaderStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography className={classes.header}>DOI Datasets</Typography>
      </Grid>
      {renderDOIDataset()}
    </Grid>
  );
};

DOIDatasetLinks.propTypes = {
  fetchDOIDataset: PropTypes.func.isRequired,
  resourceId: PropTypes.number,
};

export default withSnackbars(DOIDatasetLinks);
