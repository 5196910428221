import { memo } from 'react';
import * as React from 'react';
import MaterialDialogContent, {
  DialogContentProps,
} from '@mui/material/DialogContent';

const DialogContent: React.VFC<DialogContentProps> = (
  props: DialogContentProps
) => <MaterialDialogContent {...props} />;

export default memo(DialogContent);
