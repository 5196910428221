import {
  CustomTreeData,
  DataTypeProvider,
  TreeDataState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { OpenInNew } from '@onc/icons';
import { Link, Typography } from 'base-components';
import Environment from 'util/Environment';

interface Device {
  siteDevice: number;
  name: string;
  deviceCode: string;
  deviceId: number;
  dataSearchURL: string;
}

interface RelatedDevicesTableProps {
  primaryDevice?: Device;
  devices?: Device[];
}

export const RELATED_DEVICES_TABLE_COLUMNS = [
  { title: 'Device Id', name: 'deviceId' },
  { title: 'Device Name', name: 'name' },
  { title: 'Device Code', name: 'deviceCode' },
  { title: 'Data Search', name: 'dataSearch' },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headingStyle: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    table: {
      paddingBottom: theme.spacing(2),
    },
  })
);

export const RELATED_DEVICES_HEADER = 'Related Devices';

const RelatedDevicesTable = ({
  primaryDevice = null,
  devices = [],
}: RelatedDevicesTableProps) => {
  const classes = useStyles();

  return (
    <div role="region" aria-label="Related Devices">
      <Typography className={classes.headingStyle} variant="h6">
        {RELATED_DEVICES_HEADER}
      </Typography>
      <Grid
        rows={[primaryDevice]}
        columns={RELATED_DEVICES_TABLE_COLUMNS}
        getRowId={(row) => row.deviceId}
      >
        <DataTypeProvider
          for={['dataSearch']}
          // eslint-disable-next-line react/no-unstable-nested-components
          formatterComponent={({ row }) => (
            <>
              <Link
                href={`${Environment.getDmasUrl()}/DataSearch?${row.dataSearchURL}`}
                target="_blank"
                name="Data Search Link"
              >
                <OpenInNew />
              </Link>
            </>
          )}
        />
        <DataTypeProvider
          for={['deviceId']}
          // eslint-disable-next-line react/no-unstable-nested-components
          formatterComponent={({ row }) => (
            <>
              <Link
                href={`${Environment.getDmasUrl()}/DeviceListing?deviceId=${row.deviceId}`}
                target="_blank"
              >
                {row.deviceId}
              </Link>
            </>
          )}
        />
        <DataTypeProvider
          for={['name']}
          // eslint-disable-next-line react/no-unstable-nested-components
          formatterComponent={({ row }) => (
            <>
              <Link
                href={`${Environment.getDmasUrl()}/DeviceListing?deviceId=${row.deviceId}`}
                target="_blank"
              >
                {row.name}
              </Link>
            </>
          )}
        />
        <TreeDataState defaultExpandedRowIds={[primaryDevice.deviceId]} />
        <CustomTreeData
          getChildRows={(row: Device) => {
            if (row === null) {
              return [primaryDevice];
            }

            if (row.deviceId === primaryDevice.deviceId) {
              return devices;
            }

            return null;
          }}
        />
        <Table />
        <TableHeaderRow />
        {devices.length > 0 && <TableTreeColumn for="deviceId" />}
      </Grid>
    </div>
  );
};

export default RelatedDevicesTable;
