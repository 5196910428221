import PropTypes from 'prop-types';
import { Grid, LinearProgress, Typography } from 'base-components';
import Environment from 'util/Environment';

const DataProductDownloadProgress = ({
  status,
  dataProductType,
  errorStatus,
  restrictedStatus,
  restrictedMessage = undefined,
}) => {
  DataProductDownloadProgress.propTypes = {
    status: PropTypes.string.isRequired,
    dataProductType: PropTypes.string.isRequired,
    errorStatus: PropTypes.string.isRequired,
    restrictedStatus: PropTypes.string.isRequired,
    restrictedMessage: PropTypes.string,
  };

  let textComp;
  let linearProgressComp;
  if (status === errorStatus) {
    const errorMessage = Environment.isUserLoggedIn()
      ? `Error requesting download for ${dataProductType.toLowerCase()}`
      : 'Please try logging in to access this data product.';
    textComp = <Typography>{errorMessage}</Typography>;
  } else if (status === restrictedStatus) {
    const errorMessage = Environment.isUserLoggedIn()
      ? `Error requesting dataproduct. ${restrictedMessage}`
      : 'Please try logging in to access this data product.';
    textComp = <Typography>{errorMessage}</Typography>;
  } else {
    textComp = <Typography>{`Downloading ${dataProductType}`}</Typography>;
    linearProgressComp = <LinearProgress />;
  }
  return (
    <Grid container direction="column">
      {textComp}
      {linearProgressComp}
    </Grid>
  );
};

export default DataProductDownloadProgress;
