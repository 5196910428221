import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import TaskHistoryService from 'domain/AppComponents/batch/service/TaskHistoryService';
import { parseDmasAPIResponse } from 'util/WebRequest';

class BatchDropdown extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    validation: PropTypes.func,
    disabled: PropTypes.bool,
    startIndex: PropTypes.number,
    status: PropTypes.string,
    numberOfRecords: PropTypes.number,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    validation: () => {},
    disabled: false, // disabled if user does not have >= RO privilege for resource TASK_MANAGEMENT
    startIndex: 0,
    status: '8',
    numberOfRecords: 50,
    onChange: this.handleBatchChange,
  };

  state = {
    batches: [
      {
        key: '-1',
        value: '-1',
        label: 'No Batch',
      },
    ],
  };

  componentDidMount() {
    this.getBatches();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleBatchChange = (input) => {
    if (input.target) {
      this.setState({
        batchId: input.target.value,
      });
    }
  };

  getBatches = () => {
    const { startIndex, status, numberOfRecords } = this.props;
    TaskHistoryService.getBatchTasks(
      startIndex,
      status,
      numberOfRecords,
      'BATCH_TASK'
    )
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => {
        const batchList = payload.records.map(({ task }) => ({
          label: task.input ? task.input.batchName : `Batch ID: ${task.taskId}`,
          value: task.taskId.toString(),
          key: task.taskId.toString(),
        }));
        this.setState({
          batches: batchList,
        });
      });
  };

  render() {
    const { title, value, validation, disabled, ...otherProps } = this.props;
    const { batches } = this.state;
    return (
      <Dropdown
        label="Batch Name*"
        options={batches}
        title={title}
        value={value}
        validation={validation}
        disabled={disabled}
        {...otherProps}
      />
    );
  }
}
export default BatchDropdown;
