import { useForm } from 'react-hook-form';
import { Loading } from '@onc/composite-components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ErrorPage,
  Grid,
} from 'base-components';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import {
  CancelButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';

import useGet from 'util/hooks/useDmasAPI/useGet';

const classes = {
  root: {
    display: 'flex',
    width: '100%',
  },
  leftAlign: {
    marginLeft: 1,
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

type DeviceListPayload = [
  {
    deviceId: number;
    deviceName: string;
  },
];

type DeviceSyncConfigForm = {
  delay: number;
  enabled: boolean;
  leadDeviceId: number | { label: string; value: number; key: string };
  followDeviceId: number | { label: string; value: number; key: string };
};

type DeviceSyncConfigProps = {
  title: string;
  onSave: (form: DeviceSyncConfigForm) => void;
  onCancel: () => void;
  data: {
    eventId: number;
    leadId: number;
    followId: number;
    enabled: boolean;
    delay: number;
    dateFrom: string;
  };
};

const DeviceSyncConfig = ({
  title,
  onSave,
  onCancel,
  data,
}: DeviceSyncConfigProps) => {
  const listDeviceData = useGet<DeviceListPayload, { operationtype: number }>(
    'DataFileService',
    {
      operation: 2,
      transform: (response) => {
        if (response.data?.length <= 0) {
          throw new Error('Could not get data');
        }
        return response.data;
      },
      options: {
        enabled: isNaN(data.eventId),
      },
    },
    {
      operationtype: 2,
    }
  );

  const formMethods = useForm<DeviceSyncConfigForm>({
    mode: 'onBlur',
    defaultValues: {
      delay: data.delay,
      enabled: data.enabled || false,
      leadDeviceId: data.dateFrom ? data.leadId : undefined,
      followDeviceId: data.dateFrom ? data.followId : undefined,
    },
  });

  if (isNaN(data.eventId) && listDeviceData.isLoading) {
    return (
      <Dialog open fullWidth>
        <DialogContent>
          <Loading />
        </DialogContent>
      </Dialog>
    );
  }

  if (isNaN(data.eventId) && listDeviceData.error) {
    return <ErrorPage />;
  }

  const options = listDeviceData?.data?.map((option) => ({
    label: option.deviceName,
    value: option.deviceId,
    key: option.deviceId.toString(),
  }));

  const getOptionLabel: (option: { label: string; value: any }) => string = (
    option
  ) => `${option.label} (${option.value}) `;

  const isOptionEqualToValue = (option, value) => option.key === value.key;

  const idField = () => {
    if (data.dateFrom !== undefined) {
      return (
        <Grid item xs={12} sx={classes.leftAlign}>
          <ReadOnlyField
            title="eventId"
            labelText="Event ID"
            valueText={data.eventId}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const followField = () => {
    if (data.dateFrom !== undefined) {
      return (
        <Grid item xs={12} sx={classes.leftAlign}>
          <ReadOnlyField
            title="followDeviceId"
            labelText="Follow Device ID"
            valueText={data.followId}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <FormAutocomplete
          name="followDeviceId"
          translationKey="device.followDeviceId"
          options={options}
          fullWidth
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
        />
      </Grid>
    );
  };

  const leadField = () => {
    if (data.dateFrom !== undefined) {
      return (
        <Grid item xs={12} sx={classes.leftAlign}>
          <ReadOnlyField
            title="leadDeviceId"
            labelText="Lead Device ID"
            valueText={data.leadId}
          />
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <FormAutocomplete
          name="leadDeviceId"
          translationKey="device.leadDeviceId"
          options={options}
          fullWidth
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
        />
      </Grid>
    );
  };

  const handleSave = (values) => {
    const form = {
      delay: values.delay,
      enabled: values.enabled,
      followDeviceId:
        typeof values.followDeviceId === 'number'
          ? values.followDeviceId
          : values.followDeviceId.value,
      leadDeviceId:
        typeof values.leadDeviceId === 'number'
          ? values.leadDeviceId
          : values.leadDeviceId.value,
    };
    onSave(form);
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSave} formMethods={formMethods}>
          <Grid container spacing={1} sx={classes.root}>
            {idField()}
            {leadField()}
            {followField()}
            <Grid item xs={12}>
              <FormTextField
                name="delay"
                translationKey="device.delay"
                rules={{ required: 'A delay is required' }}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <FormToggle name="enabled" label="Enabled" />
            </Grid>
          </Grid>
          <Grid item sx={classes.rightAlign}>
            <CancelButton onClick={onCancel} />
            <SaveButton type="submit" />
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DeviceSyncConfig;
