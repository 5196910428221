import { useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { FloatingActionButton as Fab, Skeleton } from 'base-components';
import CommunityFishersDevicePackageAccordion from 'domain/AppComponents/community-fishers/CommunityFishersDevicePackageAccordion';
import CommunityFishersDevicePackageService from 'domain/services/CommunityFishersDevicePackageService';
import PageWithPadding from 'library/CompositeComponents/page/PageWithPadding';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { CommunityFishersDevicePackage } from './CommunityFishersDevicePackageTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      'z-index': 1,
    },
  })
);

const CommunityFishersDevicePackageInfoPage = () => {
  const { onError } = useSnackbars();

  const [devicePackages, setDevicePackages] = useState<
    CommunityFishersDevicePackage[]
  >([]);

  const [expandedAccordions, setExpandedAccordions] = useState<
    Record<string, boolean>
  >({});

  const classes = useStyles();

  useEffect(() => {
    const getDevicePackages = async () => {
      const payload: CommunityFishersDevicePackage[] | string =
        await CommunityFishersDevicePackageService.getAllDevicePackages().catch(
          () => {
            window.location.href = `${Environment.getDmasUrl()}/boooom`;
            return [];
          }
        );
      setDevicePackages(payload);
    };
    getDevicePackages();
  }, [onError]);

  const handleChange = (setName: string) => (_event, isExpanded: boolean) => {
    setExpandedAccordions({ ...expandedAccordions, [setName]: isExpanded });
  };

  const renderAccordions = () => {
    if (devicePackages.length === 0) {
      return <Skeleton variant="rectangular" height="90%" width="100%" />;
    }

    const accordions = devicePackages.map((devicePackage) => {
      const { setName } = devicePackage.setInformation;
      return (
        <CommunityFishersDevicePackageAccordion
          devicePackage={devicePackage}
          key={setName}
          accordionIdentifier={setName}
          expanded={Boolean(expandedAccordions[setName])}
          handleChange={handleChange}
        />
      );
    });
    return accordions;
  };

  const areAnyAccordionsExpanded = () =>
    Object.values(expandedAccordions).some((item) => item);

  const onButtonClick = (expandedStateToApplyToAllAccordions: boolean) => {
    let newExpandedStateForAllAccordions = {};
    devicePackages.forEach((devicePackage) => {
      const { setName } = devicePackage.setInformation;
      newExpandedStateForAllAccordions = {
        ...newExpandedStateForAllAccordions,
        [setName]: expandedStateToApplyToAllAccordions,
      };
    });
    setExpandedAccordions(newExpandedStateForAllAccordions);
  };

  return (
    <PageWithPadding>
      <Fab
        containerClasses={classes.fab}
        color="primary"
        variant="extended"
        onClick={() => onButtonClick(!areAnyAccordionsExpanded())}
        aria-label={areAnyAccordionsExpanded() ? 'Collapse All' : 'Expand All'}
      >
        {areAnyAccordionsExpanded() ? 'Collapse All' : 'Expand All'}
      </Fab>
      {renderAccordions()}
    </PageWithPadding>
  );
};

export default CommunityFishersDevicePackageInfoPage;
