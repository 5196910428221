import { DenseThemeProvider } from '@onc/theme';
import CompromisedInstrumentsTable from 'domain/AppComponents/compromised-instruments/CompromisedInstrumentsTable';
import JiraLink from 'domain/AppComponents/link/JiraLink';
import useGet from 'util/hooks/useDmasAPI/useGet';

export type CompromisedAnnotationRow = {
  annotationHdrId: number;
  resourceType: string;
  resource: string;
  comment: string;
  startDate: string;
  endDate: string;
  taxon?: string;
};

const CompromisedInstrumentsPage = () => {
  const extractJiraId = (comment: string) => {
    const id = comment.match(/(INSTR|NEPDATA|DSO)-{1}([0-9]{3,5})/);
    return id !== null ? id[0] : null;
  };
  const transformToRows = (data) =>
    data.map((anno) => ({
      annotationHdrId: anno.annotationHdrId.toString(),
      resourceType: anno.resourceTypeName,
      deviceName: anno.resourceName,
      deviceId: anno.resourceId.toString(),
      comment: anno.comment,
      taxon: anno.taxons ? anno.taxons[0].displayText : '',
      startDate: anno.startDate,
      endDate: anno.endDate || '',
      jiraLink: extractJiraId(anno.comment) ? (
        <JiraLink jiraId={extractJiraId(anno.comment)} />
      ) : (
        ''
      ),
    }));

  const { data: compromisedRows } = useGet('internal/devices/compromised', {
    operation: undefined,
    transform: (response) => transformToRows(response.data),
  });

  return (
    <DenseThemeProvider>
      <CompromisedInstrumentsTable rows={compromisedRows} />
    </DenseThemeProvider>
  );
};

export default CompromisedInstrumentsPage;
