import { cloneElement, ReactNode, isValidElement } from 'react';

import { SizeMe as ReactSizeMe, withSize } from 'react-sizeme';

type SizeMeProps = {
  children: ReactNode;
  monitorHeight?: boolean;
};

/**
 * Component SizeMe. Use when you want the children to know what their
 * width/height is. An example of a component needing this is SimpleMap using
 * ResponsiveGridLayout(s).
 */
const SizeMe = ({ children, monitorHeight = true }: SizeMeProps) => {
  /**
   * Returns children with new width/height props.
   *
   * @returns Cloned children with height/width props.
   */
  const renderChildren = (width, height) => {
    const newProps = { width, height };
    if (!Array.isArray(children)) {
      return isValidElement(children)
        ? cloneElement(children, newProps)
        : children;
    }
    // iterate through children and clone element w/ width and height
    return children.map((child, index) =>
      cloneElement(child, {
        ...newProps,
        key: child.key ?? `child-${index}`, // Use existing key or fallback to index-based key
      })
    );
  };

  return (
    <ReactSizeMe monitorHeight={monitorHeight}>
      {({ size }) => (
        <div style={{ width: '100%', height: '100%' }}>
          {renderChildren(size.width, size.height)}
        </div>
      )}
    </ReactSizeMe>
  );
};

export { withSize };
export default SizeMe;
