import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'base-components';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import DeviceSensorService from 'domain/services/DeviceSensorService';

import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const headers = [
  { title: 'Sensor ID', name: 'sensorId' },
  { title: 'Sensor Name', name: 'sensorName' },
  { title: 'Sensor Type', name: 'sensorType' },
  { title: 'Sensor Code Name', name: 'sensorCodeName' },
  { title: 'Unit of Measure', name: 'unitOfMeasure' },
  { title: 'Scale Reference', name: 'scaleReference' },
  { title: 'Modified Date', name: 'lastModifiedDate' },
  { title: 'Modified By', name: 'lastModifiedBy' },
  { title: 'ID For Search', name: 'deviceSensorIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'sensorId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'sensorName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'sensorType',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'sensorCodeName',
    width: ColumnInfo.large,
    align: 'left',
  },
  {
    columnName: 'unitOfMeasure',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'scaleReference',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'lastModifiedDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'lastModifiedBy',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'lastModifiedDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'deviceSensorIdForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class DeviceSensorTable extends PureComponent {
  static propTypes = {
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError, deviceId } = this.props;
    this.setState({ isLoading: true });
    DeviceSensorService.getMany(deviceId)
      .then((result) => {
        this.buildTableRows(result);
      })
      .catch((result) => {
        onError(result);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  sensorContent = () => {
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceSensorRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    return <DeviceSensorRefreshButton />;
  };

  buildTableRows = (sensors) => {
    this.setState({
      tableRows: sensors.map((sensor) => {
        const sensorFor = {
          ...sensor,
          sensorId: this.deviceSensorLink(sensor.sensorId),
          lastModifiedDate: DateFormatUtils.formatDate(
            sensor.lastModifiedDate,
            'full'
          ),
          deviceSensorIdForSearch: sensor.sensorId,
        };
        return sensorFor;
      }),
    });
  };

  compareSensorId = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  deviceSensorLink = (sensorId) => {
    const { deviceId } = this.props;
    return (
      <a
        href={`${Environment.getDmasUrl()}/SensorListing?DeviceId=${deviceId}&SensorId=${sensorId}#calibration_tab`}
      >
        {sensorId}
      </a>
    );
  };

  render() {
    const { tableRows, isLoading } = this.state;

    if (!tableRows) return undefined;

    return (
      <Panel
        title={<Typography variant="h6">Device Sensor List</Typography>}
        actionContent={this.sensorContent()}
      >
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={tableRows.length}
          rows={tableRows}
          searchable
          searchBarMoveable={false}
          sortExtensions={[
            { columnName: 'sensorId', compare: this.compareSensorId },
          ]}
          stripedRows
          hiddenColumns={['deviceSensorIdForSearch']}
          isLoading={isLoading}
        />
      </Panel>
    );
  }
}

export default DeviceSensorTable;
