import { createContext } from 'react';
import { PlaylistLine } from 'domain/services/PlaylistLineService';
import { Playlist } from 'domain/services/PlaylistService';

export interface PlaylistContextInterface {
  playlist: Playlist;
  playlistLines: PlaylistLine[];
  groups: string[];
  clipId: number;
  refreshPlaylistLines: (playlistHdrId: number) => void;
  refreshPlaylist: (playlistHdrId: number) => void;
}

const PlaylistContext = createContext<PlaylistContextInterface>({
  playlist: {
    playlistHdrId: 0,
    playlistLines: [],
    name: '',
    description: '',
    groupName: '',
    createdBy: 0,
    modifyDate: '',
    createdDate: '',
    locked: false,
    lockReason: '',
    permission: '',
  },
  groups: [],
  playlistLines: [],
  clipId: undefined,
  refreshPlaylistLines: () => {},
  refreshPlaylist: () => {},
});

export default PlaylistContext;
