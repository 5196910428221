import { get } from 'util/WebRequest';

export type Process = {
  processId?: number;
  processTypeId?: number;
  dateFrom?: Date;
  modifyBy?: number;
  modifyDate?: Date;
};

export type AreasOfResponsibility = {
  areasOfResponsibilityId?: number;
  areasOfResponsibilityName?: string;
};

export type Phase = {
  phaseId?: number;
  phaseTypeId?: number;
  dateFrom?: Date;
  orderNumber?: number;
  modifyBy?: number;
  modifyDate?: Date;
};

export type PhaseTask = {
  taskId?: number;
  taskName?: string;
  areaOfResponsibility?: AreasOfResponsibility;
  references?: string;
  modifyBy?: number;
  modifyDate?: Date;
};

class ProcessService {
  static getAllProcesses = async (
    processGroupIds?: number[]
  ): Promise<Process[]> => {
    const response = await get('ProcessService', {
      method: 'getAllProcessTypes',
      processGroupIds:
        processGroupIds && processGroupIds.length > 0
          ? processGroupIds.join(',')
          : undefined,
    });
    return response.data.payload.processTypes;
  };

  static getAllPhases = async (
    processGroupIds?: number[]
  ): Promise<Phase[]> => {
    const response = await get('ProcessService', {
      method: 'getAllPhaseTypes',
      processGroupIds:
        processGroupIds && processGroupIds.length > 0
          ? processGroupIds.join(',')
          : undefined,
    });
    return response.data.payload.phaseTypes;
  };

  static getAllTasks = async (
    processGroupIds?: number[]
  ): Promise<PhaseTask[]> => {
    const response = await get('ProcessService', {
      method: 'getAllTasks',
      processGroupIds:
        processGroupIds && processGroupIds.length > 0
          ? processGroupIds.join(',')
          : undefined,
    });
    return response.data.payload.tasks;
  };

  static getAllAreasOfResponsibility = async (): Promise<
    AreasOfResponsibility[]
  > => {
    const response = await get('ProcessService', {
      method: 'getAllAreasOfResponsibility',
    });
    return response.data.payload.areasOfResponsibility;
  };
}

export default ProcessService;
