import { memo } from 'react';
import { Box } from 'base-components';
import {
  ClearButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import FilterChipGroup from 'library/CompositeComponents/chips/FilterChipGroup';

export interface FilteringProps {
  selectedFilters: number[];
  setSelectedFilters: (newFilters: number[]) => void;
  filters: { value: number; label: string }[];
  selectAll: () => void;
  clearAll: () => void;
}

const BatchFilters = ({ filterOptions }: { filterOptions: FilteringProps }) => {
  const { selectedFilters, setSelectedFilters, filters, selectAll, clearAll } =
    filterOptions;
  return (
    <Box display="flex" flexWrap="wrap">
      <FilterChipGroup
        label="Status Filter"
        filterList={filters}
        activeFilters={selectedFilters}
        onClick={(filter) => {
          if (selectedFilters.includes(filter)) {
            setSelectedFilters(selectedFilters.filter((val) => val !== filter));
          } else {
            setSelectedFilters([...selectedFilters, filter]);
          }
        }}
      />
      <TextButton
        onClick={() => selectAll()}
        translationKey="common.buttons.all"
      />
      <ClearButton onClick={() => clearAll()} />
    </Box>
  );
};

export default memo(BatchFilters);
