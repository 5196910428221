export type DMASConfig = {
  Application: {
    token;
  };
  Config: {
    DMAS_USER_PRIVILEGE: 'RW' | 'RO' | undefined;
    DMAS_URL;
    DMAS_USER_ID;
    DMAS_USER_FIRSTNAME;
    DMAS_USER_LASTNAME;
    DMAS_USER_EMAIL;
    DMAS_USER_TOKEN;
    ERDDAP_URL;
    FTP_PATH;
    DIVE_HLS_STREAMER;
    TICKET_MANAGER_URL;
  };
};

export const DefaultDMASConfig: DMASConfig = {
  Application: { token: undefined },
  Config: {
    DMAS_USER_PRIVILEGE: undefined,
    DMAS_URL: undefined,
    DMAS_USER_ID: undefined,
    DMAS_USER_FIRSTNAME: undefined,
    DMAS_USER_LASTNAME: undefined,
    DMAS_USER_EMAIL: undefined,
    DMAS_USER_TOKEN: undefined,
    ERDDAP_URL: undefined,
    FTP_PATH: undefined,
    DIVE_HLS_STREAMER: undefined,
    TICKET_MANAGER_URL: undefined,
  },
};

if (!window.DMAS) {
  window.DMAS = DefaultDMASConfig;
}

class Environment {
  /** The URL of Oceans 3.0 for access to web services, etc. */
  static getDmasUrl() {
    // if running with `start:webpack`
    if (window.DMAS.Config.DMAS_URL) {
      return window.DMAS.Config.DMAS_URL;
    }

    // if running with `start` against an arbitrary instance of Oceans 3
    if (process.env.ONC_BASE_URL) {
      return process.env.ONC_BASE_URL;
    }

    // if running with `start` against prod
    if (process.env.ONC_ENV === 'PROD') {
      return 'https://data.oceannetworks.ca';
    }

    // if running with `start` against QA
    if (process.env.ONC_ENV === 'TEST') {
      return 'https://qa.oceannetworks.ca';
    }

    // if running with `start` against the SeaTube demo server
    if (process.env.ONC_ENV === 'DEMO') {
      return 'https://demo.oceannetworks.ca';
    }

    // if running with `start` against dev
    return `http://${window.location.hostname}:8080`;
  }

  /** Returns true if the environment is deployed in production */
  static isEnvironmentProd() {
    return window.DMAS.Config.DMAS_URL === 'https://data.oceannetworks.ca';
  }

  /**
   * Return the URL for redirection to other pages. Will link to DMAS if running
   * in server mode and the local instance if not
   *
   * @returns URL to link to
   */
  static getLinkUrl() {
    if (process.env.ONC_SERVER || process.env.ONC_ENV === 'PROD') {
      return this.getDmasUrl();
    }
    return `${window.location.protocol}//${window.location.host}`;
  }

  /*
   * If there is a permission variable set, return it, otherwise return the highest setting.
   * if there is no permissions variable set, then the user is running the dev environment
   */
  static getDmasUserPrivilege() {
    return window.DMAS.Config.DMAS_USER_PRIVILEGE;
  }

  /**
   * The base URL for static resources provided by Oceans 3.0 resources from
   * thex
   *
   * @deprecated Since oceans-next was created. This exists only to support the
   *   PlottingUtility port.
   */
  static getStaticResourcePath() {
    // if running with `start:webpack
    if (window.DMAS.Config.DMAS_URL) {
      return `${window.DMAS.Config.DMAS_URL}/onc-static`;
    }

    // if running with `start` against an arbitrary instance of Oceans 3
    if (process.env.ONC_BASE_URL) {
      return `${process.env.ONC_BASE_URL}/onc-static`;
    }

    switch (process.env.ONC_ENV) {
      case 'PROD':
        // if running with `start` against prod
        return 'https://data.oceannetworks.ca/onc-static';
      case 'TEST':
        // if running with `start` against QA
        return 'https://qa.oceannetworks.ca/onc-static';
      case 'DEMO':
        // if running with `start` against the SeaTube demo server
        return 'https://demo.oceannetworks.ca/onc-static';
      default:
        // if running with `start` against dev
        return `http://${window.location.hostname}:8080/onc-static`;
    }
  }

  /** Get the path to ONC's external wiki page */
  static getExternalWikiPath() {
    return 'https://wiki.oceannetworks.ca';
  }

  /**
   * Get the path to the logged-in user's FTP directory. This only works
   * correctly in a production build (whether run locally, in QA, or in prod):
   * when run with <code>npm start</code> it will always return user 0's FTP
   * directory.
   */
  static getFtpPath() {
    // if running with `start:webpack`
    if (window.DMAS.Config.FTP_PATH) {
      return window.DMAS.Config.FTP_PATH;
    }

    switch (process.env.ONC_ENV) {
      case 'PROD':
        // if running with `start` against prod
        return 'https://ftp.oceannetworks.ca/pub/user0';
      default:
        // if running with `start` against QA or dev
        return 'ftp://qa.oceannetworks.ca/pub/user0';
    }
  }

  static getMediaBaseUrl() {
    // if running with `start:webpack`
    if (window.DMAS.Config.DIVE_HLS_STREAMER) {
      return window.DMAS.Config.DIVE_HLS_STREAMER;
    }

    switch (process.env.ONC_ENV) {
      case 'PROD':
        // if running with `start` against prod
        return 'https://media.oceannetworks.ca:4935/simplevideostreaming/_definst_';
      case 'DEMO':
        return 'https://demo.oceannetworks.ca:4935/simplevideostreaming/_definst_';
      default:
        // if running with `start` against QA or dev
        return 'https://devmedia2.oceannetworks.ca:4935/simplevideostreaming/_definst_';
    }
  }

  static getADProxyUrl() {
    if (this.getDmasUrl() === 'https://data.oceannetworks.ca') {
      return 'https://data.oceannetworks.ca/archive';
    }
    return 'https://qa.oceannetworks.ca';
  }

  static getErddapUrl() {
    // if running with `start:webpack`
    if (window.DMAS.Config.ERDDAP_URL) {
      return window.DMAS.Config.ERDDAP_URL;
    }

    switch (process.env.ONC_ENV) {
      case 'PROD':
        // if running with `start` against prod
        return 'http://dap.onc.uvic.ca/erddap';
      default:
        // if running with `start` against QA or dev
        return 'http://qadap.onc.uvic.ca/erddap';
    }
  }

  /**
   * Returns the dmas user id for the logged in user, or an empty string if no
   * user is logged in.
   */
  static getDmasUserId() {
    return window.DMAS.Config.DMAS_USER_ID;
  }

  static isUserLoggedIn() {
    return (
      this.getDmasUserId() !== undefined &&
      this.getDmasUserId() !== '' &&
      this.getDmasUserId() !== 0
    );
  }

  static getDmasUserEmail() {
    return window.DMAS.Config.DMAS_USER_EMAIL
      ? window.DMAS.Config.DMAS_USER_EMAIL
      : '';
  }

  static getDmasUserFirstName() {
    return window.DMAS.Config.DMAS_USER_FIRSTNAME
      ? window.DMAS.Config.DMAS_USER_FIRSTNAME
      : '';
  }

  static getDmasUserLastName() {
    return window.DMAS.Config.DMAS_USER_LASTNAME
      ? window.DMAS.Config.DMAS_USER_LASTNAME
      : '';
  }

  static getDmasUserName() {
    return `${Environment.getDmasUserFirstName()} ${Environment.getDmasUserLastName()}`.trim();
  }

  static getDmasUserToken() {
    return window.DMAS.Config.DMAS_USER_TOKEN;
  }

  static getTicketManagerUrl() {
    return window.DMAS.Config.TICKET_MANAGER_URL;
  }

  /**
   * Returns the current Application object associated with the window. Note
   * that by default it contains an undefined token.
   */
  static getCurrentApplicationToken() {
    if (!window.DMAS) {
      window.DMAS = DefaultDMASConfig;
    }

    if (!window.DMAS.Application) {
      window.DMAS.Application = { token: undefined };
    }

    return window.DMAS.Application.token;
  }

  static setCurrentApplicationToken(token) {
    if (!window.DMAS) {
      window.DMAS = DefaultDMASConfig;
    }

    if (!window.DMAS.Application) {
      window.DMAS.Application = { token };
    } else {
      window.DMAS.Application.token = token;
    }
  }
}

export default Environment;
