import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { withTheme } from '@mui/styles';
import PropTypes from 'prop-types';

const ExpeditionManagementThemeProvider = ({ theme, children = undefined }) => {
  const isDense = useMediaQuery(theme.breakpoints.up('md'));
  const expeditionManagementTheme = (outerTheme) => {
    let themeProps = {};
    if (isDense) {
      themeProps = {
        MuiFilledInput: {
          margin: 'dense',
        },
        MuiFormControl: {
          margin: 'dense',
        },
        MuiFormHelperText: {
          margin: 'dense',
        },
        MuiIconButton: {
          size: 'small',
        },
        MuiInputBase: {
          margin: 'dense',
        },
        MuiInputLabel: {
          margin: 'dense',
        },
        MuiOutlinedInput: {
          margin: 'dense',
        },
        MuiFab: {
          size: 'small',
        },
        MuiTable: {
          size: 'small',
        },
        MuiTextField: {
          margin: 'dense',
        },
        MuiToolbar: {
          variant: 'dense',
        },
      };
    }

    return createTheme(
      adaptV4Theme({
        ...outerTheme,
        props: themeProps,
      })
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={expeditionManagementTheme}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ExpeditionManagementThemeProvider.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.shape({
    breakpoints: PropTypes.shape({ up: PropTypes.func }),
  }).isRequired,
};

export default withTheme(ExpeditionManagementThemeProvider);
