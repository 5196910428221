import { SortingState } from '@devexpress/dx-react-grid';
import BatchOrderActions from 'domain/AppComponents/batch/actions/BatchOrderActions';
import BatchRowActions from 'domain/AppComponents/batch/actions/BatchRowActions';
import BatchTaskInfoTooltipAction from 'domain/AppComponents/batch/actions/BatchTaskInfoTooltipAction';
import BatchToggleAutorunAction from 'domain/AppComponents/batch/actions/BatchToggleAutorunAction';
import BatchStatusChip from 'domain/AppComponents/batch/util/BatchStatusChip';
import TaskStatus from 'domain/AppComponents/batch/util/TaskStatus';
import { SortingProps } from 'library/BaseComponents/table/plugins/ColumnSorting';
import {
  DataFormatProvider,
  TableColumn,
} from 'library/BaseComponents/table/StatelessTable';

export const PAMGUARD = 'PAMBatch';
export const DOI = 'DOI Batch';

export const BATCH_TASK_TYPES_PAMGUARD_ONLY = [PAMGUARD];
export const BATCH_TASK_TYPES = [DOI, PAMGUARD];

export const BATCH_TASK_TYPES_MAP = new Map<string, string>();
BATCH_TASK_TYPES_MAP.set('BATCH_TASK', DOI);
BATCH_TASK_TYPES_MAP.set('Batch', DOI);
BATCH_TASK_TYPES_MAP.set('PAM_TASK', PAMGUARD);
BATCH_TASK_TYPES_MAP.set('Pamguard Batch', PAMGUARD);

export const PAGE_SIZE = 6;
export const PAM_TASK_DEFINITION_ID = 392;

export const FILTERS_FOR_BATCH_TASKS = [
  TaskStatus.OPEN,
  TaskStatus.QUEUED,
  TaskStatus.RUNNING,
  TaskStatus.CANCELLING,
  TaskStatus.COMPLETED,
  TaskStatus.CANCELLED,
  TaskStatus.ABORTED,
  TaskStatus.COMPLETED_WITH_ERRORS,
];

export const FILTERS_FOR_BATCHES = [
  TaskStatus.OPEN,
  TaskStatus.RUNNING,
  TaskStatus.COMPLETED,
  TaskStatus.CANCELLED,
  TaskStatus.ABORTED,
  TaskStatus.COMPLETED_WITH_ERRORS,
];

export const DEFAULT_SELECTED_FILTERS_BATCHES = [
  TaskStatus.OPEN.value,
  TaskStatus.RUNNING.value,
];

export const DEFAULT_SELECTED_FILTERS_BATCH_TASK = [
  TaskStatus.OPEN.value,
  TaskStatus.QUEUED.value,
  TaskStatus.RUNNING.value,
  TaskStatus.CANCELLING.value,
  TaskStatus.COMPLETED.value,
  TaskStatus.CANCELLED.value,
  TaskStatus.ABORTED.value,
  TaskStatus.COMPLETED_WITH_ERRORS.value,
];

export const BATCH_TABLE_COLUMNS = [
  { title: 'Task Id', name: 'taskId' },
  { title: 'Batch Name', name: 'batchName' },
  { title: 'Reason', name: 'reason' },
  { title: 'Date Started', name: 'dateStarted' },
  { title: 'Date Completed', name: 'dateCompleted' },
  { title: 'Status', name: 'status' },
  { title: '', name: 'actions' },
];

export const BATCH_TASK_TABLE_COLUMNS: TableColumn[] = [
  { title: 'Run Order', name: 'sequenceNumber' },
  { title: 'AutoRun', name: 'autoRun' },
  { title: 'Task Type', name: 'taskTypeName' },
  { title: 'Task Definition Id', name: 'taskDefinitionId' },
  { title: 'Task Id', name: 'taskId' },
  { title: 'Host Name', name: 'hostName' },
  { title: 'Date Started', name: 'dateStarted' },
  { title: 'Date Completed', name: 'dateCompleted' },
  { title: 'Task Info', name: 'info' },
  { title: 'Status', name: 'status' },
  { title: '', name: 'actions' },
];

export const BATCH_TASK_TABLE_SORTING_EXTENSIONS: SortingState.ColumnExtension[] =
  [
    {
      columnName: 'sequenceNumber',
      sortingEnabled: false,
    },
    { columnName: 'taskTypeName', sortingEnabled: false },
    { columnName: 'taskDefinitionId', sortingEnabled: false },
    { columnName: 'taskId', sortingEnabled: false },
    { columnName: 'hostName', sortingEnabled: false },
    { columnName: 'dateStarted', sortingEnabled: false },
    { columnName: 'dateCompleted', sortingEnabled: false },
    { columnName: 'status', sortingEnabled: false },
    { columnName: 'info', sortingEnabled: false },
    { columnName: 'autoRun', sortingEnabled: false },
    { columnName: 'actions', sortingEnabled: false },
  ];

export const BATCH_TASK_TABLE_SORTING: SortingProps = {
  sorting: [
    {
      columnName: 'sequenceNumber',
      direction: 'desc',
    },
  ],
  columnExtensions: BATCH_TASK_TABLE_SORTING_EXTENSIONS,
  customSorting: [
    {
      columnName: 'sequenceNumber',
      compare: (a, b) => {
        if (a instanceof Object && b instanceof Object) {
          return (
            parseInt(b.props?.sequenceNumber, 10) -
            parseInt(a.props?.sequenceNumber, 10)
          );
        }
        return b - a;
      },
    },
  ],
};

export const BATCH_TABLE_COLUMN_FORMATTER: DataFormatProvider[] = [
  {
    name: 'ActionsFormatter',
    for: ['actions'],
    formatterComponent: ({ row }) => (
      <BatchRowActions
        taskId={row.taskId}
        status={row.status}
        taskType={row.taskType}
        isRunnable={row.isRunnable}
        isBatchTask={false}
        batchName={row.batchName}
        batchReason={row.reason}
      />
    ),
  },
  {
    name: 'StatusFormatter',
    for: ['status'],
    formatterComponent: ({ row }) => (
      <BatchStatusChip status={row.status} errorMessage={row.errorMessage} />
    ),
  },
];

export const BATCH_TASK_TABLE_COLUMN_FORMATTER: DataFormatProvider[] = [
  {
    name: 'SequenceFormatter',
    for: ['sequenceNumber'],
    formatterComponent: ({ row }) => (
      <BatchOrderActions
        taskId={row.taskId}
        sequenceNumber={row.sequenceNumber}
        canIncrement={row.ableToIncrementSequenceNumber}
        canDecrement={row.ableToDecrementSequenceNumber}
      />
    ),
  },
  {
    name: 'StatusFormatter',
    for: ['status'],
    formatterComponent: ({ row }) => <BatchStatusChip status={row.status} />,
  },
  {
    name: 'AutoRunFormatter',
    for: ['autoRun'],
    formatterComponent: ({ row }) => (
      <BatchToggleAutorunAction autorun={row.autorun} taskId={row.taskId} />
    ),
  },
  {
    name: 'ActionsFormatter',
    for: ['actions'],
    formatterComponent: ({ row }) => (
      <BatchRowActions
        taskId={row.taskId}
        status={row.status}
        taskType={row.taskTypeName}
        isRunnable={row.runnable}
        input={row.input}
        isBatchTask
      />
    ),
  },
  {
    name: 'InfoFormatter',
    for: ['info'],
    formatterComponent: ({ row }) =>
      row.additionalInfo ? (
        <BatchTaskInfoTooltipAction
          input={row.input}
          additionalInfo={row.additionalInfo}
          dateQueued={row.dateQueued}
        />
      ) : null,
  },
];
