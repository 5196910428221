import { useState, useCallback } from 'react';
import * as React from 'react';
import { Sorting } from '@devexpress/dx-react-grid';
import { Grid, LinearProgress } from 'base-components';
import SearchHistoryFilter from 'domain/AppComponents/search-history/SearchHistoryFilter';
import SearchHistoryTable from 'domain/AppComponents/search-history/SearchHistoryTable';
import useSearchHistoryService from 'domain/hooks/useSearchHistoryService';
import { FilterValue } from 'library/CompositeComponents/filter/Filter';

const SearchHistoryPage: React.VFC = () => {
  const [filterState, setFilterState] = useState<FilterValue[]>(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState(30);
  // Column Sorting
  const [sortOrder, setSortOrder] = useState<Sorting[]>([
    { columnName: 'dateCreated', direction: 'desc' },
  ]);
  const [isFilterGroupExpanded] = useState<boolean>(false);

  let count = 0;

  const { data, isLoading, refetch } = useSearchHistoryService(
    filterState,
    `${page * pageSize}`,
    pageSize,
    `${sortOrder[0].columnName}`,
    `${sortOrder[0].direction}`
  );

  const filterExpand = (
    <SearchHistoryFilter
      filterGroupExpanded={isFilterGroupExpanded}
      onSubmit={(event: any) => {
        setFilterState(event.target.value);
        refetch();
      }}
    />
  );

  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      setPage(Math.trunc((page * pageSize) / newPageSize));
      setPageSize(newPageSize);
    },
    [pageSize, page]
  );

  if (data) {
    count = data.count || 0;
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} className="table">
          {filterExpand}
          {isLoading ? <LinearProgress role="progressbar" /> : <></>}
          <SearchHistoryTable
            pagingOnTop
            paging={{
              page,
              setPage,
              pageSize,
              setPageSize: handlePageSizeChange,
              count,
            }}
            rows={data !== undefined ? data.records : undefined}
            sorting={{
              sortOrder,
              setSortOrder,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchHistoryPage;
