import {
  createRef,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import qs, { ParsedQs } from 'qs';
import DownloadHistoryTableFAB from 'domain/AppComponents/geospatial-search/community-fisher/fab-buttons/DownloadHistoryTableFAB';
import FilterFAB from 'domain/AppComponents/geospatial-search/community-fisher/fab-buttons/FilterFAB';
import HelpFAB from 'domain/AppComponents/geospatial-search/community-fisher/fab-buttons/HelpFAB';
import LassoFAB from 'domain/AppComponents/geospatial-search/community-fisher/fab-buttons/LassoFAB';
import { SelectedStation } from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchTypes';
import GeospatialAreaService from 'domain/services/GeospatialAreaService';
import SiteDeviceSubsetService from 'domain/services/SiteDeviceSubsetService';
import MapContext from 'library/CompositeComponents/map/MapContext';
import SimpleMap from 'library/CompositeComponents/map/SimpleMap';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import CookieUtils from 'util/CookieUtils';
import { CastDataProvider } from './CastDataContext';
import AssignedCFOverlay from './community-fisher/AssignedCFOverlay';
import DecommissionedAssignedCFOverlay from './community-fisher/DecommissionedAssignedCFOverlay';
import GeospatialSearchHelper from './community-fisher/GeospatialSearchHelper';
import GeospatialSearchTools from './community-fisher/GeospatialSearchTools';

import UnassignedCFOverlay from './community-fisher/UnassignedCFOverlay';
import SelectedStationContext, {
  SelectedStationsProvider,
} from './SelectedStationsContext';

const ACTIVE_STATIONS_CF_OVERLAY_NAME = 'Active Casting Stations';
const DECOMMISSIONED_STATIONS_CF_OVERLAY_NAME =
  'Decommissioned Casting Stations';
const UNASSIGNED_CF_OVERLAY_NAME = 'Unassigned Casts';
const LOCATION_QUERY = 'LOCATION';
const ZOOM_QUERY = 'ZOOM';
const GEOSPATIAL_MAP_ID = 'geospatial-search-map';

interface GeospatialSearchProps {
  onError: ({ message }: { message: string }) => void;
  onInfo: ({ message }: { message: string }) => void;
  height?: string;
  width?: string;
}

const GeospatialSearch: FC<GeospatialSearchProps> = ({
  onError,
  onInfo,
  height = '800px',
  width = '100%',
}) => {
  const mapContext = useContext(MapContext);
  const [map, setMap] = useState<any>();

  useEffect(() => {
    setMap(mapContext?.mapRef);
  }, [mapContext]);

  const [dateRange, setDateRange] = useState({
    dateFrom: undefined,
    dateTo: undefined,
  });
  const [displayCastLayer, setDisplayCastLayer] = useState(true);
  const [displayUnassignedCastLayer, setDisplayUnassignedCastLayer] =
    useState(true);
  const [unassignedCastData, setUnassignedCastData] = useState(undefined);
  const [assignedCastData, setAssignedCastData] =
    useState<SelectedStation[]>(undefined);

  const [displayCastSelectionDialog, setDisplayCastSelectionDialog] =
    useState(undefined);

  const [displayDownloadTableDialog, setDisplayDownloadTableDialog] =
    useState(undefined);

  const [selectedLayers, setSelectedLayers] = useState<any>([]);

  const { selectedStations, updateSelectedStations } = useContext(
    SelectedStationContext
  );

  const [hideCFPolygons, setHideCFPolygons] = useState<boolean>(false);

  // necessary at this level to allow AssignedCFOverlay icons to be properly adjusted at depth
  const recolourIcons = useCallback(() => {
    // eslint-disable-next-line no-underscore-dangle
    GeospatialSearchHelper.setAllLayersToUnselectedIcon(map?.current?._layers);
    GeospatialSearchHelper.setLayersToSelectedIcon(selectedLayers);
  }, [selectedLayers, map]);

  const decideToRenderAreaPolygons = useCallback(() => {
    if (!map) {
      return;
    }
    const zoom = map.current.getZoom();
    if (zoom > 11 && hideCFPolygons) {
      setHideCFPolygons(false);
    } else if (zoom < 12 && !hideCFPolygons) {
      setHideCFPolygons(true);
    }
    recolourIcons();
  }, [hideCFPolygons, map, recolourIcons]);

  // check every render if zoom to hide marker polygons when zoomed out
  useEffect(() => {
    if (map?.current) {
      map.current.on('zoomend', decideToRenderAreaPolygons);
      map.current.on('overlayadd', recolourIcons);
    }
    decideToRenderAreaPolygons();
  });

  // necessary at this level to allow both the CastSelectionDialog and the CastPopup of an assigned station to select all the casts of a station.
  const setSelectedStationParentCheckbox = (
    selectedStationCode,
    checkboxSelected
  ) => {
    const selectedStationsCopy = selectedStations.map((station) => {
      const stationCopy = station;
      if (station.code === selectedStationCode) {
        stationCopy.checkboxSelected = checkboxSelected;
        stationCopy.partialCheckboxSelected = checkboxSelected;
        stationCopy.siteDeviceSubsets = stationCopy.siteDeviceSubsets.map(
          (siteDeviceSubset) => {
            const siteDeviceSubsetCopy = siteDeviceSubset;
            siteDeviceSubsetCopy.checkboxSelected = checkboxSelected;
            return siteDeviceSubsetCopy;
          }
        );
      }
      return stationCopy;
    });
    updateSelectedStations(selectedStationsCopy);
  };

  // only render one (castSelectionDialog or downloadTableDialog) at a time
  const showCastSelectionDialog = () => {
    setDisplayDownloadTableDialog(false);
    setDisplayCastSelectionDialog(true);
  };
  const showDownloadTableDialog = () => {
    setDisplayDownloadTableDialog(true);
    setDisplayCastSelectionDialog(false);
  };

  const downloadAllUnrestrictedCastsFromStation = (code) => {
    // if station is not yet part of selectedStations, find and add it
    if (
      !selectedStations.some(
        (someCurrentStation) => someCurrentStation.code === code
      )
    ) {
      assignedCastData.forEach((station) => {
        if (station.code === code) {
          const selectedStationsCopy = selectedStations;
          selectedStationsCopy.push(station);
          updateSelectedStations(selectedStationsCopy);
        }
      });
    }

    setSelectedStationParentCheckbox(code, true);

    if (selectedStations.length === 1) {
      showDownloadTableDialog();
    } else {
      showCastSelectionDialog();
    }
  };

  const assignedCastDataWithPolygons =
    GeospatialSearchHelper.generatePolygonPositions(assignedCastData, onError);

  const handleOverlayChange = (e) => {
    const newDisplayValue = e.type === 'overlayadd';
    if (e.name === ACTIVE_STATIONS_CF_OVERLAY_NAME) {
      setDisplayCastLayer(newDisplayValue);
    }
    if (e.name === UNASSIGNED_CF_OVERLAY_NAME) {
      setDisplayUnassignedCastLayer(newDisplayValue);
    }
  };

  interface Config {
    location: {
      lat: number;
      lng: number;
    };
    zoom: number;
  }

  const setLocation = useCallback(() => {
    let config: RegExpMatchArray | Config = {
      location: { lat: null, lng: null },
      zoom: null,
    };
    const parsedLocation: ParsedQs = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    // center map on url location or cookie location
    if (
      parsedLocation[LOCATION_QUERY] &&
      typeof parsedLocation[LOCATION_QUERY] === 'string'
    ) {
      const location = parsedLocation[LOCATION_QUERY].split(',');
      config.location.lat = parseFloat(location[0]);
      config.location.lng = parseFloat(location[1]);
      if (typeof parsedLocation[ZOOM_QUERY] === 'string') {
        config.zoom =
          parseInt(parsedLocation[ZOOM_QUERY], 10) < 20
            ? parseInt(parsedLocation[ZOOM_QUERY], 10)
            : 8;
      }
    } else {
      const cookie = CookieUtils.getCookie(`geospatialMap-config`);
      config = cookie as unknown as Config;
    }

    if (config && config.location.lat && config.location.lng) {
      map.current.setView(
        [config.location.lat, config.location.lng],
        config.zoom
      );
    }
  }, [map]);

  const storeLocation = useCallback(() => {
    // store a cookie
    const config = { location: undefined, zoom: undefined };
    config.location = map.current.getCenter();
    config.zoom = map.current.getZoom();
    CookieUtils.saveCookie(`geospatialMap-config`, config);

    // update url
    const location = `${config.location.lat},${config.location.lng}`;
    const windowURL = window.location.pathname.split('/').pop();
    const param = `/${windowURL}?${LOCATION_QUERY}=${location}&${ZOOM_QUERY}=${config.zoom}`;

    window.history.pushState({}, '', param);
  }, [map]);

  const getCastData = useCallback(async () => {
    let newUnassignedCastData;
    try {
      newUnassignedCastData = SiteDeviceSubsetService.getUnassignedCasts();
    } catch (error: any) {
      onError(error.message);
    }

    let newAssignedCastData;
    try {
      newAssignedCastData = GeospatialAreaService.getDrawableAreas();
    } catch (error: any) {
      onError(error.message);
    }

    const newUnassignedCastDataProcessed =
      GeospatialSearchHelper.filterOutUnassignedCastsWithNoPosition(
        await newUnassignedCastData
      );
    setUnassignedCastData(newUnassignedCastDataProcessed);
    setAssignedCastData(await newAssignedCastData);
  }, [onError]);

  const generateActiveAssignedLayer = () => {
    // if we build this layer before the mapref is set, the lasso button cannot be added
    if (!map?.current) {
      return undefined;
    }
    const communityFishersLayer = {
      name: ACTIVE_STATIONS_CF_OVERLAY_NAME,
      checked: true,
      subLayers: (
        <AssignedCFOverlay
          dateRange={dateRange}
          assignedCastData={assignedCastDataWithPolygons}
          recolourIcons={recolourIcons}
          hideCFPolygons={hideCFPolygons}
          downloadAllUnrestrictedCastsFromStation={
            downloadAllUnrestrictedCastsFromStation
          }
        />
      ),
    };
    return communityFishersLayer;
  };

  const generateDecommissionedAssignedLayer = () => {
    // if we build this layer before the mapref is set, the lasso button cannot be added
    if (!map?.current) {
      return undefined;
    }
    const communityFishersLayer = {
      name: DECOMMISSIONED_STATIONS_CF_OVERLAY_NAME,
      checked: true,
      subLayers: (
        <DecommissionedAssignedCFOverlay
          dateRange={dateRange}
          assignedCastData={assignedCastDataWithPolygons}
          recolourIcons={recolourIcons}
          hideCFPolygons={hideCFPolygons}
          downloadAllUnrestrictedCastsFromStation={
            downloadAllUnrestrictedCastsFromStation
          }
        />
      ),
    };
    return communityFishersLayer;
  };

  const generateUnassignedLayer = () => {
    const UnassignedCommunityFishersLayer = {
      name: UNASSIGNED_CF_OVERLAY_NAME,
      checked: true,
      subLayers: (
        <UnassignedCFOverlay
          filterRange={dateRange}
          onError={onError}
          onInfo={onInfo}
          unassignedCastData={unassignedCastData}
        />
      ),
    };
    return UnassignedCommunityFishersLayer;
  };

  const buildOverlayArray = () => {
    const overlayLayers = [];
    const assignedActiveOverlayLayer = generateActiveAssignedLayer();
    const assignedDecommissionedOverlayLayer =
      generateDecommissionedAssignedLayer();
    if (assignedActiveOverlayLayer) {
      overlayLayers.push(assignedActiveOverlayLayer);
    }
    if (assignedDecommissionedOverlayLayer) {
      overlayLayers.push(assignedDecommissionedOverlayLayer);
    }
    const unAssignedOverlayLayer = generateUnassignedLayer();
    overlayLayers.push(unAssignedOverlayLayer);
    return overlayLayers;
  };

  const overlayArray = buildOverlayArray();

  useEffect(() => {
    getCastData();
    // map must be loaded
    if (!map?.current) {
      return;
    }

    // set events and set map if previous state exists
    map.current.on('moveend', storeLocation);
    map.current.on('overlayadd', handleOverlayChange);
    map.current.on('overlayremove', handleOverlayChange);
    setLocation();
  }, [map, getCastData, setLocation, storeLocation]);

  return (
    <CastDataProvider>
      <SimpleMap
        isCFMap
        height={height}
        width={width}
        overlayLayers={overlayArray}
        mapId={GEOSPATIAL_MAP_ID}
        initialZoom={4}
        center={[66, -95]}
        defaultLayerName="Open Street"
        shouldRenderCastSearch
        displayCastLayer={displayCastLayer}
        displayUnassignedCastLayer={displayUnassignedCastLayer}
        assignedCastData={assignedCastData}
        unAssignedCastData={unassignedCastData}
      >
        <DownloadHistoryTableFAB />
        <LassoFAB
          assignedCastData={assignedCastDataWithPolygons}
          unassignedCastData={unassignedCastData}
          showCastSelectionDialog={showCastSelectionDialog}
          setSelectedLayers={setSelectedLayers}
          onError={onError}
        />
        <HelpFAB />
        <FilterFAB dateRange={dateRange} setDateRange={setDateRange} />
        <GeospatialSearchTools
          dateRange={dateRange}
          showCastSelectionDialog={showCastSelectionDialog}
          showDownloadTableDialog={showDownloadTableDialog}
          displayCastSelectionDialog={displayCastSelectionDialog}
          displayDownloadTableDialog={displayDownloadTableDialog}
          setSelectedLayers={setSelectedLayers}
          setSelectedStationParentCheckbox={setSelectedStationParentCheckbox}
          setDisplayCastSelectionDialog={setDisplayCastSelectionDialog}
        />
      </SimpleMap>
    </CastDataProvider>
  );
};

const value = { mapRef: createRef(), mapId: GEOSPATIAL_MAP_ID };
const GeospatialSearchProvider = (props) => (
  <MapContext.Provider value={value}>
    <SelectedStationsProvider>
      <GeospatialSearch {...props} />
    </SelectedStationsProvider>
  </MapContext.Provider>
);

export default withSnackbars(GeospatialSearchProvider);
