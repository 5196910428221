import JiraLink from 'domain/AppComponents/link/JiraLink';

export type BulkUpdateRow = {
  rowId: number;
  device?: string;
  processGroup?: string;
  process?: string;
  phase?: string;
  phaseTask?: string;
  areaOfResponsibility?: string;
  jiraIssueId?: any;
  comment?: string;
  status?: string;
  modifyBy?: string;
  modifyDate?: string;
};

const statuses = [
  'Incomplete (1)',
  'Complete (2)',
  'Not Required (3)',
  'Did Not Complete (4)',
  'Unknown (5)',
];

const getJira = (jiraIds) => {
  const jiraOutputList = [];
  if (!jiraIds) {
    return '';
  }
  const jiraIdList = jiraIds.split(',');

  jiraIdList.forEach((jiraId) => {
    jiraOutputList.push(<JiraLink jiraId={jiraId} />);
  });

  return jiraOutputList;
};

const getStatus = (statusId) => {
  if (statusId < 1) {
    return statusId;
  }
  return statuses[statusId - 1];
};

const formatModifyDate = (modifyDate: string) => {
  const strArr = modifyDate.split('T');
  strArr[1] = strArr[1].substring(0, 8);
  return `${strArr[0]} ${strArr[1]}`;
};

export const transformToRows = (deviceWorkflows: any[]): BulkUpdateRow[] =>
  deviceWorkflows.map((workflow) => ({
    rowId: workflow.deviceProcessGroupDetailsId,
    device: `${workflow.deviceName} (${workflow.deviceId})`,
    processGroup: `${workflow.processGroup.processGroupName} (${workflow.processGroup.processGroupId})`,
    process: `${workflow.processType.processTypeName} (${workflow.processType.processTypeId})`,
    phase: `${workflow.phase.phaseTypeName} (${workflow.phase.phaseTypeId})`,
    phaseTask: `${workflow.phaseTask.phaseTaskName} (${workflow.phaseTask.phaseTaskId})`,
    areaOfResponsibility: `${workflow.areaOfResponsibility.areaOfResponsibilityName} (${workflow.areaOfResponsibility.areaOfResponsibilityId})`,
    jiraIssueId: getJira(workflow.jiraId),
    comment: workflow.comment,
    status: getStatus(workflow.status),
    modifyBy: `${workflow.modifyBy.firstname} ${workflow.modifyBy.lastname}`,
    modifyDate: formatModifyDate(workflow.modifyDate),
  }));
