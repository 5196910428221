import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';

class DataciteEnvironmentSelect extends Component {
  static propTypes = {
    environment: PropTypes.string,
    onEnviroChange: PropTypes.func.isRequired,
    noAdmin: PropTypes.bool,
  };

  static defaultProps = {
    environment: 'TEST',
    noAdmin: false,
  };

  render() {
    const { environment, onEnviroChange, noAdmin, ...otherProps } = this.props;
    const options = [
      { label: 'Test', value: 'TEST', disabled: false },
      { label: 'Production', value: 'PROD', disabled: noAdmin },
    ];
    return (
      <Dropdown
        label="DataCite Environment*"
        value={environment}
        onChange={onEnviroChange}
        options={options}
        {...otherProps}
      />
    );
  }
}

export default DataciteEnvironmentSelect;
