import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle, Grid } from 'base-components';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import {
  CancelButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  leftJustify: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  leftAlign: {
    marginLeft: theme.spacing(1),
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type ActionConfigProps = {
  title: string;
  onSave: (form: object) => void;
  onCancel: () => void;
  actionListing?: {
    actionId: number;
    actionName: string;
    needsCruise: string;
    modifyBy: string;
    modifyDate: string;
    actionCategoryId: number;
  };
  actionCategoryMap: object;
};

type ActionConfigForm = {
  actionId: number;
  actionCategoryId: { label: string; value: number };
  needsCruise: { label: string; value: string };
  actionName: string;
  modifyDate: string;
};

const ActionConfig = ({
  title,
  onSave,
  onCancel,
  actionListing = {
    actionId: undefined,
    actionName: undefined,
    needsCruise: undefined,
    modifyBy: undefined,
    modifyDate: undefined,
    actionCategoryId: undefined,
  },
  actionCategoryMap,
}: ActionConfigProps) => {
  const classes = useStyles();

  const actionId = () => {
    if (actionListing.actionId !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="actionId"
            labelText="Action ID"
            valueText={actionListing.actionId}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const getCategoryOptions = () =>
    Object.entries(actionCategoryMap).map(([key, value]) => ({
      label: value,
      value: Number(key),
    }));

  const categoryPicker = () => (
    <Grid item xs={12}>
      <FormAutocomplete
        name="actionCategoryId"
        translationKey="device.actionCategoryId"
        rules={{ required: 'A category is required!' }}
        fullWidth
        options={getCategoryOptions()}
      />
    </Grid>
  );

  const cruiseNeeded = () => {
    const options = [
      { label: 'True', value: 'True' },
      { label: 'False', value: 'False' },
    ];
    return (
      <Grid item xs={12}>
        <FormAutocomplete
          name="needsCruise"
          translationKey="device.needsCruise"
          options={options}
          fullWidth
          rules={{ required: 'A cruise option is required!' }}
        />
      </Grid>
    );
  };

  const action = () => (
    <Grid item xs={12}>
      <FormTextField
        fullWidth
        name="actionName"
        translationKey="device.actionName"
        rules={{ required: 'A name is required!' }}
      />
    </Grid>
  );

  const modifyDate = () => {
    if (actionListing.modifyDate !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="modifyDate"
            labelText="Date Modified"
            valueText={actionListing.modifyDate}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const handleSave = (values: ActionConfigForm) => {
    const form = {
      ...(values.actionId ? { actionId: values.actionId } : {}),
      ...(values.modifyDate ? { modifyDate: values.modifyDate } : {}),
      actionName: values.actionName,
      needsCruise: values.needsCruise.value,
      actionCategoryId: values.actionCategoryId.value,
    };
    onSave(form);
  };

  const formMethods = useForm<ActionConfigForm>({
    mode: 'onBlur',
    defaultValues: {
      actionId: actionListing.actionId,
      actionCategoryId: actionListing.actionCategoryId
        ? {
            label: actionCategoryMap[actionListing.actionCategoryId],
            value: actionListing.actionCategoryId,
          }
        : undefined,
      needsCruise: actionListing.needsCruise
        ? {
            label: actionListing.needsCruise,
            value: actionListing.needsCruise,
          }
        : undefined,
      actionName: actionListing.actionName,
      modifyDate: actionListing.modifyDate,
    },
  });

  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSave} formMethods={formMethods}>
          <Grid container spacing={1} className={classes.root}>
            {actionId()}
            {categoryPicker()}
            {cruiseNeeded()}
            {action()}
            {modifyDate()}
          </Grid>
          <Grid item className={classes.rightAlign}>
            <CancelButton onClick={onCancel} />
            <SaveButton type="submit" />
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ActionConfig;
