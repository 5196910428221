import * as React from 'react';
import {
  OpenDeckLogIconButton,
  PlayArrowIconButton,
} from 'domain/AppComponents/IconButtons';
import TableAnnotation from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';

type Props = {
  row: TableAnnotation;
};

const SeaTubeSearchResultsTableActions: React.VFC<Props> = ({ row }: Props) => {
  const diveLogUrl = `/app/dive-logs/${row.videoResourceId}?time=${row.startDate}&annotationId=${row.id}`;
  const deckLogUrl = `/app/expedition-logs/${row.resourceId}?annotationId=${row.id}`;
  if (row.resourceTypeId === 601) {
    return (
      <OpenDeckLogIconButton
        onClick={() => window.open(deckLogUrl, '_blank')}
      />
    );
  }
  return (
    <PlayArrowIconButton
      title="Play"
      onClick={() => window.open(diveLogUrl, '_blank')}
    />
  );
};

export default SeaTubeSearchResultsTableActions;
