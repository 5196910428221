import { Component } from 'react';
import { withStyles, createStyles } from '@mui/styles';
import { TableOld as Table, TableOldProps } from 'base-components';

const styles: any = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
  });

type Column = {
  name: string;
  title?: string;
};

type GroupableTableProps = TableOldProps & {
  columns: Column[];
  rows: Record<string, any>[]; // Generic object structure for rows
  classes?: Record<string, string>; // Object where keys are strings and values are strings
  columnSizes: Record<string, any>[]; // Assuming it's an array of objects
  className?: { root: string };
};

// @deprecated prefer stateless table
class GroupableTable extends Component<GroupableTableProps> {
  static defaultProps = {
    classes: undefined,
    className: undefined,
  };

  // add the 8px padding here and pass it down, so when the tables are groupable they don't have that weird 3/8px split
  render() {
    const { classes } = this.props;
    return <Table groupable className={classes.root} {...this.props} />;
  }
}

// @ts-expect-error This works, not sure why it complains
export default withStyles(styles)(GroupableTable);
