import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'base-components';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import DeviceDetailsLink from 'domain/AppComponents/link/DeviceDetailsLink';
import DeviceStatusHelperService from 'domain/services/DeviceStatusHelperService';

import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';

const headers = [
  { title: 'Device Category', name: 'deviceCategory' },
  { title: 'Device ID', name: 'deviceId' },
  { title: 'Serial #', name: 'serial' },
  { title: 'Model #', name: 'model' },
  { title: 'Producer', name: 'producer' },
  { title: 'Last Action', name: 'lastProcurementStatus' },
  { title: 'Device ID For Search', name: 'hiddenDeviceIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'deviceCategory',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'serial',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'model',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'producer',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'lastProcurementStatus',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'hiddenDeviceIdForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class DeviceStatusTable extends PureComponent {
  static propTypes = {
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  /* unused sortRows = (a, b) => {
    if (a.deviceCategory > b.deviceCategory) {
      return 1;
    }
    if (a.deviceCategory < b.deviceCategory) {
      return -1;
    }
    if (a.deviceId > b.deviceId) {
      return 1;
    }
    if (a.deviceId < b.deviceId) {
      return -1;
    }
    return 0;
  }; */

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  refresh = async () => {
    const { onInfo } = this.props;
    await this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = async () => {
    const { onError, onInfo } = this.props;
    onInfo('Loading...');
    await DeviceStatusHelperService.get()
      .then((result) => {
        this.buildTableRows(result);
      })
      .catch((result) => {
        onError(result.message);
      });
  };

  actionContent = () => {
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceStatusRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    return <DeviceStatusRefreshButton />;
  };

  buildTableRows = (response) => {
    const tableRows = response.map((entry) => ({
      ...entry,
      deviceId: <DeviceDetailsLink deviceId={entry.device.deviceId} />,
      deviceCategory: entry.device.deviceCategory.deviceCategoryName,
      serial: entry.namePlate ? entry.namePlate.serialnr : 'NA',
      model: entry.namePlate ? entry.namePlate.modelnr : 'NA',
      producer: entry.producer ? entry.producer.organizationName : 'NA',
      lastProcurementStatus: entry.lastAction
        ? entry.lastAction.action.actionName
        : 'NA',
      hiddenDeviceIdForSearch: entry.device.deviceId,
    }));

    this.setState({ tableRows });
  };

  render() {
    const { tableRows } = this.state;

    if (!tableRows) return undefined;

    return (
      <Panel
        title={<Typography variant="h6">Device Statuses</Typography>}
        actionContent={this.actionContent()}
      >
        <SortableTable
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          pageSize={20}
          rows={tableRows}
          searchable
          stripedRows
          defaultSorting={[
            {
              columnName: 'deviceCategory',
              direction: 'asc',
            },
            {
              columnName: 'hiddenDeviceIdForSearch',
              direction: 'asc',
            },
          ]}
          hiddenColumns={['hiddenDeviceIdForSearch']}
        />
      </Panel>
    );
  }
}

export default DeviceStatusTable;
