import { get } from 'util/WebRequest';

class DataProductDeliveryService {
  static Statuses = Object.freeze({
    COMPLETE: 'complete',
    DATA_PRODUCT_RUNNING: 'data product running',
    DATA_PRODUCT_COMPLETE: 'data product complete',
    METADATA_PRODUCT_RUNNING: 'metadata product running',
    METADATA_PRODUCT_COMPLETE: 'metadata product complete',
    QUEUED: 'queued',
  });

  /**
   * Function for initiating an annotation export
   *
   * @param {object} params
   * @param {string} params.diveIds - Dive ids to be exported from
   * @param {object} params.filter - The annotation filter
   * @param {number} params.includeSpecialCharacters - 1 if yes, 0 if no
   * @param {number} params.includeSnapshots - 1 if yes, 0 if no
   * @param {number} params.timeBetweenSnapshots
   * @param {string} params.format - Export format
   */
  static requestAnnotationExport = async (params) => {
    const requestParams = { method: 'request', ...params };

    return get('apiproxy/dataProductDelivery', requestParams).then(
      (response) => response.data.dpRequestId
    );
  };

  /**
   * Function for initiating a still image export
   *
   * @param {object} params - The pertinent parameters for the call
   * @param {string} params.diveIds - Dive ids to be exported from
   * @param {Date} params.dateFrom
   * @param {Date} params.dateTo
   * @param {number} params.fixedTimeInterval - The time between snapshots
   * @param {number} params.burstSize - The number of snapshots taken at each
   *   interval
   * @param {string} params.resolution - A character indicating the resolution
   *   to use
   */
  static requestStillImageExport = async (params) => {
    const requestParams = {
      method: 'request',
      ...params,
    };

    return get('apiproxy/dataProductDelivery', requestParams).then(
      (response) => response.data.dpRequestId
    );
  };

  /**
   * Function for running a data product search
   *
   * @param {number} dpRequestId - The id returned by a request call
   */
  static runDataProductSearch = async (dpRequestId) => {
    const params = {
      method: 'run',
      dpRequestId,
    };

    return get('apiproxy/dataProductDelivery', params).then(
      (response) => response.data[0]
    );
  };
}

export default DataProductDeliveryService;
