import { useEffect } from 'react';
import SearchTreeAnnotationService from 'domain/Apps/data-preview/service/SearchTreeAnnotationService';
import SiteDeviceAnnotationService from 'domain/Apps/data-preview/service/SiteDeviceAnnotationService';
import DateFormatUtils from 'util/DateFormatUtils';
import useWebService from 'util/hooks/useWebService';

type Props = {
  locationCode: string;
  deviceCategoryCode: string;
  siteDeviceId: number;
  onMoreClick: (annotationId: number) => void;
  dateFrom: string;
  dateTo: string;
};

const DataPreviewAnnotationDisplay: React.FC<Props> = (props: Props) => {
  const {
    locationCode,
    deviceCategoryCode,
    siteDeviceId,
    onMoreClick,
    dateFrom,
    dateTo,
  } = props;
  const [sdAnnotations, , fetchSiteDeviceAnnotations] = useWebService({
    method: SiteDeviceAnnotationService.getAnnotations,
  });
  const [locationAnnotations, , fetchLocationnAnotations] = useWebService({
    method: SearchTreeAnnotationService.getAnnotations,
  });

  useEffect(() => {
    if (siteDeviceId) {
      fetchSiteDeviceAnnotations(siteDeviceId, dateFrom, dateTo, {
        126: 'high',
        123: '%%',
      });
    } else {
      fetchLocationnAnotations(
        locationCode,
        deviceCategoryCode,
        dateFrom,
        dateTo,
        {
          126: 'high',
          123: '%%',
        }
      );
    }
  }, [
    deviceCategoryCode,
    fetchLocationnAnotations,
    fetchSiteDeviceAnnotations,
    locationCode,
    siteDeviceId,
    dateFrom,
    dateTo,
  ]);

  const getText = (annotation) => {
    let text = annotation.annotationSummary;
    if (annotation.startDate) {
      text += ` (Applies to: ${DateFormatUtils.formatDate(
        annotation.startDate,
        'full'
      )} UTC - `;
      if (annotation.endDate) {
        text += `${DateFormatUtils.formatDate(annotation.endDate, 'full')} UTC`;
      } else {
        text += 'Now';
      }
      text += ')';
    }
    text += ' ';
    return text;
  };

  return (
    <div style={{ background: '#ff8080', border: '1px solid #660000' }}>
      {sdAnnotations?.map((annotation) => (
        <div>
          <span>{getText(annotation)}</span>
          <a href="#" onClick={() => onMoreClick(annotation.annotationId)}>
            More
          </a>
        </div>
      ))}
      {locationAnnotations?.map((annotation) => (
        <div>
          <span>{getText(annotation)}</span>
          <a href="#" onClick={() => onMoreClick(annotation.annotationId)}>
            More
          </a>
        </div>
      ))}
    </div>
  );
};

export default DataPreviewAnnotationDisplay;
