import { useState, useEffect, useRef } from 'react';
import { Box, TextField } from 'base-components';
import { SketchPicker } from 'react-color';

const styles = {
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
};

type ColorPickerFieldProps = {
  value?: string;
  onInputChange: (value: string) => void;
  onColorChange: (color: any) => void;
  onCloseDialog?: () => void;
  disableAlpha?: boolean;
  className?: string;
  fullWidth?: boolean;
};

const ColorPickerField = ({
  value = '#000000',
  onInputChange,
  onColorChange,
  onCloseDialog = undefined,
  disableAlpha = false,
  fullWidth = false,
  className = undefined,
}: ColorPickerFieldProps) => {
  const usePrevious = (val) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = val;
    });
    return ref.current;
  };

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const prevDisplayed = usePrevious(displayColorPicker);

  useEffect(
    () => {
      if (prevDisplayed && !displayColorPicker && onCloseDialog) {
        return onCloseDialog();
      }
      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayColorPicker]
  );

  const renderDialog = () => {
    if (displayColorPicker) {
      return (
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', zIndex: '2' }}>
            <Box
              aria-hidden="true"
              id="cover"
              sx={styles.cover}
              onClick={() => setDisplayColorPicker(!displayColorPicker)}
            />
            <SketchPicker
              disableAlpha={disableAlpha}
              color={value}
              onChange={onColorChange}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <TextField
        onChange={(e) => {
          onInputChange(e.target.value);
        }}
        InputProps={{
          style: { color: value === undefined ? '#000000' : value },
        }}
        value={value}
        translationKey="taxonomy.selectColour"
        onClick={() => setDisplayColorPicker(!displayColorPicker)}
        fullWidth={fullWidth}
        className={className}
      />
      {renderDialog()}
    </>
  );
};

export default ColorPickerField;
