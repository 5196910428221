/* eslint-disable prefer-destructuring */
import { useEffect, useState } from 'react';

import {
  Control,
  Controller,
  useFieldArray,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { Add, ExpandMore } from '@onc/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  NestedItem,
  Typography,
} from 'base-components';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import { Attribute } from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import NestedMenuAutocomplete from 'library/CompositeComponents/select/nested-menu-autocomplete/NestedMenuAutocomplete';
import AttributeValueField from './AttributeValueField';
import { SeaTubeManualEntryFormType } from './SeaTubeManualEntryForm';

type Props = {
  attributeOptions: any[];
  nullableAttributes: boolean;
  control: Control<SeaTubeManualEntryFormType>;
  setValue: UseFormSetValue<SeaTubeManualEntryFormType>;
};

export interface AttributeOption {
  attributeId: number;
  groupName: string;
  selectable?: true;
  dataType: string;
  groupId: number;
  name: string;
  attributeValues?: { label: string; value: number }[];
  canBeUpdated?: boolean;
}

export interface AttributeLine {
  attr?: Attribute;
  val: any;
  touched: boolean;
}

export interface EntryAttributesSection {
  attributes: AttributeLine[];
}

const styles = {
  flexContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'top',
    columnGap: 1,
  },
  summaryContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  chipContainer: {
    marginLeft: 'auto',
    marginRight: 1,
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: 1,
  },

  chip: {
    margin: 0,
  },
  fullWidth: {
    textAlign: 'center',
    width: '100%',
  },
};

const ManualEntryFormAttributes = ({
  attributeOptions,
  control,
  nullableAttributes,
  setValue,
}: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attributes', // Name matches the key in ManualEntryFormType
  });

  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(!expanded);

  const formatOptions = () => {
    const attributesByGroup: NestedItem[] = [];
    if (!attributeOptions) {
      return attributesByGroup;
    }
    attributeOptions.forEach((attr) => {
      const existingGroup = attributesByGroup.find(
        (group) => group.value === attr.groupId
      );
      if (existingGroup && existingGroup.children) {
        existingGroup.children.push(
          new NestedItem({
            label: attr.name,
            value: attr,
            key: attr.attributeId.toString(),
          })
        );
      } else {
        attributesByGroup.push(
          new NestedItem({
            label: attr.groupName,
            value: attr.groupId,
            key: attr.groupName,
            children: [
              {
                label: attr.name,
                value: attr,
                key: attr.attributeId.toString(),
              },
            ],
          })
        );
      }
    });
    return attributesByGroup;
  };

  const handleAddLine = () => {
    append({ attr: undefined, val: '', touched: false }); // Add a new line
  };

  const watchedAttributes = useWatch({
    control,
    name: 'attributes', // Watch the entire attributes array
  });

  useEffect(() => {
    if (watchedAttributes && watchedAttributes.length > 0) {
      setExpanded(true);
    }
  }, [watchedAttributes]);

  const renderAttributeChips = () =>
    watchedAttributes?.map((line) => {
      const { val } = line;
      let displayValue = val;
      if (typeof val === 'object') {
        displayValue = line.val.label;
      }
      if (line && line.attr) {
        return (
          <Chip
            sx={styles.chip}
            variant="outlined"
            label={
              <>
                <b>{line.attr.name}</b>
                {`: ${displayValue}`}
              </>
            }
          />
        );
      }
      return <></>;
    });

  const getOptions = (attributeId: number): any[] =>
    attributeOptions?.find((attr) => attr.attributeId === attributeId)
      ?.attributeValues || [];

  return (
    <Accordion
      expanded={expanded}
      onChange={toggleExpand}
      role="region"
      data-test="attributes-accordion"
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box sx={styles.summaryContainer}>
          <Typography>Attributes</Typography>
          <Box sx={styles.chipContainer}>
            {expanded ? <></> : renderAttributeChips()}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {fields.map((field, index) => {
          const watchedAttr = watchedAttributes?.[index]?.attr;
          return (
            <Box sx={styles.flexContainer} key={field.id}>
              <Controller
                name={`attributes.${index}.attr`}
                control={control}
                rules={{ required: 'Attribute is required' }}
                render={({ field: { onChange, value }, fieldState }) => (
                  <NestedMenuAutocomplete
                    label={
                      watchedAttr
                        ? `Attribute / ${watchedAttr.groupName}`
                        : 'Attribute'
                    }
                    menuItems={formatOptions()}
                    value={value ? { label: value.name, value } : null}
                    onChange={(attr) => onChange(attr.value)}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message || ''}
                    id={`attribute-select-${index}`}
                  />
                )}
              />
              <Controller
                name={`attributes.${index}.val`}
                control={control}
                rules={
                  nullableAttributes ? {} : { required: 'Value is required' }
                }
                render={({ field: line, fieldState: lineState }) => (
                  <AttributeValueField
                    disabled={!watchedAttr}
                    dataType={watchedAttr?.dataType}
                    options={getOptions(watchedAttr?.attributeId)}
                    error={Boolean(lineState.error)}
                    helperText={lineState.error?.message || ''}
                    id={`attributes.${index}.val`}
                    {...line}
                    onChange={(val) => {
                      line.onChange(val);
                      // set the touched value to true
                      setValue(`attributes.${index}.touched`, true);
                    }}
                  />
                )}
              />
              <DeleteIconButton
                onClick={() => remove(index)}
                sx={{
                  mt: 2,
                }}
              />
            </Box>
          );
        })}
        <Box sx={styles.fullWidth}>
          <TextButton
            onClick={handleAddLine}
            startIcon={<Add />}
            translationKey="common.buttons.addAttribute"
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ManualEntryFormAttributes;
