import { Component } from 'react';
import PropTypes from 'prop-types';

import { RORWDropdown } from 'domain/AppComponents/dropdowns/RORWDropdowns';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class DeviceCategorySelect extends Component {
  static propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onError: PropTypes.func,
    permission: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    prependOption: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })
    ),
  };

  static defaultProps = {
    name: undefined,
    title: undefined,
    onBlur: undefined,
    onChange: undefined,
    helperText: undefined,
    error: undefined,
    className: undefined,
    value: undefined,
    initialValue: undefined,
    onError: undefined,
    permission: true,
    disabled: false,
    fullWidth: undefined,
    prependOption: [],
  };

  constructor(props) {
    super(props);
    this.state = { options: [] };
  }

  componentDidMount() {
    this.getDeviceCategoryOptions();
  }

  getDeviceCategoryOptions = async () => {
    const { onError, prependOption } = this.props;
    try {
      const response = await get('DeviceCategoryService', {
        operation: 7,
      });
      const payload = parseDmasAPIResponse(response);
      const options = payload.map((option) => ({
        label: option.description
          ? option.description
          : option.deviceCategoryCode,
        value: option.deviceCategoryId,
        key: option.deviceCategoryId.toString(),
      }));
      this.setState({ options: prependOption.concat(options) });
    } catch (error) {
      onError(error);
    }
  };

  render() {
    const {
      name,
      title,
      onBlur,
      onChange,
      helperText,
      error,
      className,
      value,
      initialValue,
      permission,
      disabled,
      fullWidth,
    } = this.props;
    const { options } = this.state;
    return (
      <RORWDropdown
        options={options}
        name={name}
        title={title}
        onBlur={onBlur}
        onChange={onChange}
        helperText={helperText}
        error={error}
        className={className}
        label="Device Category"
        value={value}
        initialValue={initialValue}
        disabled={disabled}
        permission={permission}
        id="device-category-selector"
        fullWidth={fullWidth}
      />
    );
  }
}

export default DeviceCategorySelect;
