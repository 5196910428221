import {
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  Tooltip,
} from '@mui/material';
import Switch from './Switch';

interface LabelInterface extends FormControlLabelProps {
  value: boolean;
  tooltip?: string;
  disableRipple?: boolean;
  onChange: any;
}

export type ToggleProps = Omit<LabelInterface, 'control'>;

const Toggle: React.FC<ToggleProps> = ({
  value,
  tooltip,
  disableRipple = false,
  classes = undefined,
  onChange,
  ...rest
}: ToggleProps) => (
  <FormGroup row>
    <Tooltip disableHoverListener={tooltip === ''} title={tooltip}>
      <FormControlLabel
        checked={value}
        control={
          <Switch
            onClick={onChange}
            disableRipple={disableRipple}
            classes={classes}
          />
        }
        {...rest}
      />
    </Tooltip>
  </FormGroup>
);

export default Toggle;
