import { Component } from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { PlayArrow, ExpandMore } from '@onc/icons';
import ONCLogo from 'assets/logos/logo-onc.png';
import {
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Card,
  Collapse,
  IconButton,
  Typography,
} from 'base-components';
import {
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import DateUtils from 'util/DateUtils';
import Environment from 'util/Environment';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
  },
  actionContainer: {
    width: 'auto',
    height: 197,
    position: 'relative',
    margin: '0 auto',
  },
  actionContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  playIcon: {
    zIndex: 9999,
    right: theme.spacing(),
    top: theme.spacing(),
    fontSize: '8em',
    pointerEvents: 'none',
  },
  img: {
    paddingTop: '56.25%', // 16:9,
  },
  card: {
    width: 350,
    border: 'solid 1px black',
    backgroundColor: '#3b4d3c',
    color: '#fff',
  },
  text: {
    paddingTop: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: '#fff',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  subheader: {
    opacity: 0.6,
  },
  closeButton: {
    color: '#fff',
  },
  playButton: {
    color: '#000',
    backgroundColor: '#fff',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#818181',
    },
  },
  playAnchor: {
    textDecoration: 'initial',
    color: 'inherit',
  },
});

class DFCard extends Component {
  static propTypes = {
    campaign: PropTypes.shape({
      dfCampaignId: PropTypes.number,
      campaignImage: PropTypes.string,
      active: PropTypes.bool,
      campaignName: PropTypes.string,
      missionStatement: PropTypes.string,
      missionTitle: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    classes: PropTypes.shape({
      link: PropTypes.string,
      grow: PropTypes.string,
      actionContainer: PropTypes.string,
      playIcon: PropTypes.string,
      img: PropTypes.string,
      card: PropTypes.string,
      text: PropTypes.string,
      expand: PropTypes.string,
      expandOpen: PropTypes.string,
      actionContent: PropTypes.string,
      subheader: PropTypes.string,
      playAnchor: PropTypes.string,
      playButton: PropTypes.string,
      closeButton: PropTypes.string,
    }),
  };

  static defaultProps = {
    classes: undefined,
    campaign: {
      campaignImage: ONCLogo,
    },
  };

  state = {
    focused: false,
    expanded: false,
  };

  handleFocusChange = () => {
    this.setState((prevState) => ({
      focused: !prevState.focused,
    }));
  };

  handleExpandClick = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  render() {
    const { campaign, classes } = this.props;
    const { focused, expanded } = this.state;
    const focusIcon = focused ? (
      <PlayArrow
        className={`${classes.actionContent} ${classes.playIcon}`}
        fontSize="inherit"
        color="disabled"
      />
    ) : undefined;
    const displayDate = `${DateUtils.formatDisplayDate(
      DateUtils.parseMoment(campaign.startDate)
    )} - ${DateUtils.formatDisplayDate(
      DateUtils.parseMoment(campaign.endDate)
    )}`;
    return (
      <Card className={classes.card} key={campaign.dfCampaignId}>
        <CardHeader
          title={campaign.campaignName}
          subheader={displayDate}
          align="left"
          titleTypographyProps={{ color: 'inherit' }}
          subheaderTypographyProps={{
            color: 'inherit',
            className: classes.subheader,
          }}
        />
        <CardActionArea
          title={`Play ${campaign.campaignName}`}
          onMouseOver={this.handleFocusChange}
          onMouseOut={this.handleFocusChange}
          onFocus={this.handleFocusChange}
          onBlur={this.handleFocusChange}
        >
          <a
            href={`${Environment.getDmasUrl()}/DigitalFishers?campaignId=${
              campaign.dfCampaignId
            }`}
          >
            <div className={classes.actionContainer}>
              {focusIcon}
              <CardMedia
                className={`${classes.actionContent} ${classes.img}`}
                image={campaign.campaignImage}
              />
            </div>
          </a>
        </CardActionArea>
        <CardActions>
          <div className={classes.grow} />
          <Typography variant="subtitle1" align="left" color="inherit">
            Description
          </Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={this.handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMore />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="subtitle1" align="left" color="inherit">
              {campaign.missionTitle}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              color="inherit"
              component="p"
              // Note: We currently allow markup in our digital fishers text.
              // To maintain this functionality I'm setting the html directly
              // If this can be changed it should.
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: campaign.missionStatement,
              }}
            />
          </CardContent>
          <CardActions>
            <div className={classes.grow} />
            <TextButton
              translationKey="common.buttons.close"
              onClick={this.handleExpandClick}
              className={classes.closeButton}
            />
            <a
              href={`${Environment.getDmasUrl()}/DigitalFishers?campaignId=${
                campaign.dfCampaignId
              }`}
              className={classes.playAnchor}
            >
              <ContainedButton
                className={classes.playButton}
                translationKey="common.buttons.play"
              />
            </a>
          </CardActions>
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(styles)(DFCard);
