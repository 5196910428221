import { post, get } from 'util/WebRequest';

class SystemConsoleService {
  static get = () => get('SystemConsoleService');

  static generateDefaultArgs = (sysCommands) =>
    sysCommands.reduce((defaultArgsObject, command) => {
      // this is literally the purpose of the reduce method...
      // eslint-disable-next-line no-param-reassign
      defaultArgsObject[command.systemcommand] = {};
      // just aliasing the object for easy reference below
      const args = defaultArgsObject[command.systemcommand];

      args.className = command.className;
      command.parameters.forEach((parameter) => {
        // non-eslint version: args[parameter.name] = parameter.allowed[0]
        [args[parameter.name]] = parameter.allowed;
      });
      return defaultArgsObject;
    }, {});

  static post = (payload) => {
    post('SystemConsoleService', payload);
  };
}

export default SystemConsoleService;
