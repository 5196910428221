import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useTranslation, TranslationType } from '@onc/i18n';
import { Moment } from 'moment';

export interface DatePickerProps {
  /**
   * Input label from the json file
   * util/i18n/src/translation/en/translation.json TODO: Create an
   * input-specific translation file?
   */
  translationKey: TranslationType;

  /** The class name of the date picker for custom styling */
  className?: string;

  /** When set to true the field will be unclickable and greyed out */
  disabled?: boolean;

  /** The maximum date that can be selected */
  maxDate?: Moment;

  /** The minimum date that can be selected */
  minDate?: Moment;

  /** The value of the date picker. If null, the date picker will be empty */
  value: Moment | null;

  /** Function to call when the date picker value is changed */
  onChange: (newValue: Moment | null) => void;

  /** If true, the date picker will take up the full width of its container */
  fullWidth?: boolean;

  /** Function to determine if a date should be disabled */
  shouldDisableDate?: (date: Moment) => boolean;

  /** Function to determine if a year should be disabled */
  shouldDisableYear?: (year: Moment) => boolean;

  /** Function to determine if a month should be disabled */
  shouldDisableMonth?: (month: Moment) => boolean;

  /** Function to call when the date picker is closed */
  onClose?: () => void;

  /** The views that should be displayed in the date picker */
  views?: ('year' | 'month' | 'day')[];

  /** If true, the date picker will be focused when it is rendered */
  autoFocus?: boolean;
}

/* A DatePicker component that uses the Material UI DatePicker.  To be used when time selection isn't needed */
const DatePicker = ({
  autoFocus = undefined,
  translationKey,
  value,
  onChange,
  className = undefined,
  disabled = false,
  fullWidth = false,
  maxDate = undefined,
  minDate = undefined,
  shouldDisableDate = undefined,
  shouldDisableYear = undefined,
  shouldDisableMonth = undefined,
  onClose = undefined,
  views = undefined,
}: DatePickerProps) => {
  const { t } = useTranslation();

  return (
    <DesktopDatePicker
      format="YYYY-MM-DD"
      slotProps={{
        textField: {
          variant: 'filled',
          fullWidth,
        },
      }}
      className={className}
      disabled={disabled}
      label={t(translationKey)}
      maxDate={maxDate}
      minDate={minDate}
      value={value}
      autoFocus={autoFocus}
      shouldDisableDate={shouldDisableDate}
      shouldDisableYear={shouldDisableYear}
      shouldDisableMonth={shouldDisableMonth}
      views={views}
      timezone="UTC"
      onChange={(val) => {
        onChange(val);
      }}
      onClose={onClose}
    />
  );
};

export default DatePicker;
