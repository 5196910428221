import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { Tooltip, Slider, SliderTooltipLabel } from 'base-components';

type PlaylistVideoSliderProps = {
  startDate: Moment;
  endDate: Moment;
  currentTimestamp: Moment;
  setIsSeeking: (sliderValue: boolean) => void;
  setSeekTo: (seekTo: number) => void;
};

const PlaylistVideoSlider: React.FC<PlaylistVideoSliderProps> = ({
  startDate,
  endDate,
  currentTimestamp,
  setIsSeeking,
  setSeekTo,
}) => {
  const [sliderValue, setSliderValue] = useState(startDate.valueOf());
  const [sliderTooltip, setSliderTooltip] = useState('');

  useEffect(() => {
    setSliderValue(currentTimestamp?.valueOf());
  }, [currentTimestamp]);

  const sliderLabel = (value: number) => {
    const date = moment.utc(value / 1000, 'X');
    return date.format('yyyy-MM-DD HH:mm:ss');
  };

  const calculateRailTimeSeconds = () =>
    moment.duration(endDate.diff(startDate)).asSeconds();

  const debouncedSeek = (seconds: number) =>
    debounce(() => setSeekTo(seconds), 50)(); // Adjust the debounce delay as needed

  const handleSliderChange = (event: Event, value: any) => {
    setIsSeeking(true);
    setSliderValue(value);
    debouncedSeek((value - startDate.valueOf()) / 1000);
  };

  const handleSliderChangeCommitted = (
    event: React.SyntheticEvent | Event,
    value: any
  ) => {
    setIsSeeking(false);
    setSeekTo((value - startDate.valueOf()) / 1000);
    event.stopPropagation();
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const step = 5000;
      if (event.key === 'ArrowRight') {
        setSliderValue((prev) => Math.min(prev + step, endDate.valueOf()));
        setIsSeeking(true);
      }

      if (event.key === 'ArrowLeft') {
        setSliderValue((prev) => Math.max(prev - step, startDate.valueOf()));
        setIsSeeking(true);
      }
    },
    [endDate, startDate, setIsSeeking]
  );

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        setIsSeeking(false);
        setSeekTo(sliderValue);
      }
    },
    [setIsSeeking, setSeekTo, sliderValue]
  );

  function calculateWidthOfOneSecond(sliderElement: HTMLSpanElement) {
    const sliderWidth =
      sliderElement.getBoundingClientRect().right -
      sliderElement.getBoundingClientRect().left;
    return sliderWidth / calculateRailTimeSeconds();
  }

  const handleSliderHover = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const mouseRelativeX =
        event.clientX - event.currentTarget.getBoundingClientRect().left;

      const currentSecondsFromStart =
        mouseRelativeX / calculateWidthOfOneSecond(event.currentTarget);

      const labelTime =
        moment.utc(startDate).valueOf() + currentSecondsFromStart * 1000;

      setSliderTooltip(sliderLabel(labelTime));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSliderTooltip, startDate]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  return (
    <Tooltip title={sliderTooltip} placement="top" followCursor>
      <div onMouseMove={handleSliderHover} style={{ width: '100%' }}>
        <Slider
          aria-label="Position"
          name="playlist-video-slider"
          min={startDate.valueOf()}
          max={endDate.valueOf()}
          value={sliderValue}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeCommitted}
          onClick={(e) => e.stopPropagation()}
          valueLabelFormat={sliderLabel}
          valueLabelDisplay="off"
          slots={{
            valueLabel: SliderTooltipLabel,
          }}
        />
      </div>
    </Tooltip>
  );
};

export default PlaylistVideoSlider;
