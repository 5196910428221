import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class SensorCalibrationService {
  static getMany(sensorId) {
    return SensorCalibrationService.get({ operation: 5, sensorId });
  }

  static getOne(calibrationId) {
    return SensorCalibrationService.get({ operation: 4, calibrationId });
  }

  static get(params) {
    return get('SensorCalibrationService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(params) {
    return post('SensorCalibrationService', {
      operation: 1,
      ...params,
    }).then((response) => response);
  }

  static update(params) {
    return post('SensorCalibrationService', {
      operation: 2,
      ...params,
    }).then((response) => response);
  }

  static delete(calibrationId, sensorId, batchId) {
    return post('SensorCalibrationService', {
      operation: 3,
      calibrationId,
      sensorId,
      batchId,
    }).then((response) => response);
  }
}
export default SensorCalibrationService;
