import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Delete } from '@onc/icons';
import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from 'base-components';

import DateFormatUtils from 'util/DateFormatUtils';
import AnnotationActions from './AnnotationActions';

const styles = (theme) => ({
  lineBase: {
    cursor: 'pointer',
  },
  selected: {
    background: '#c5debb !important',
  },

  toBeReviewed: {
    background: '#ea9999',
  },
  attributes: {
    display: 'inline-block',
    textIndent: theme.spacing(2),
  },
  blockSpan: {
    display: 'block',
  },
  usernameSpan: {
    display: 'block',
    fontWeight: 'bold',
  },
  lineTimestamp: {
    marginRight: '16px',
    minWidth: '0',
    marginTop: '0',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  timestamp: {
    color: theme.palette.grey[700],
  },
  listItemTextRoot: {
    marginBottom: '0',
    marginTop: '0',
    flex: 'none',
    wordWrap: 'break-word',
  },
});

const FULL_TIMESTAMP_WIDTH = 133;
const DEFAULT_TIMESTAMP_WIDTH = 36;
const ICON_WIDTH = 26;
const ANNOTATION_PADDING_WIDTH = 16;

class ChatMessageLine extends Component {
  static propTypes = {
    chatMessage: PropTypes.shape({
      chatLogMsgId: PropTypes.number,
      username: PropTypes.string,
      msg: PropTypes.string,
      msgDate: PropTypes.string,
    }).isRequired,
    classes: PropTypes.shape({
      attributes: PropTypes.string,
      blockSpan: PropTypes.string,
      left: PropTypes.string,
      lineBase: PropTypes.string,
      lineTimestamp: PropTypes.string,
      listItemTextRoot: PropTypes.string,
      right: PropTypes.string,
      selected: PropTypes.string,
      timestamp: PropTypes.string,
      toBeReviewed: PropTypes.string,
      usernameSpan: PropTypes.string,
    }).isRequired,
    key: PropTypes.number,
    selected: PropTypes.bool,
    displayFullTimestamp: PropTypes.bool,
    displayDeleteIcon: PropTypes.bool,
    deletePermissions: PropTypes.bool,
    onChatMessageDeleteClick: PropTypes.func,
    style: PropTypes.shape({
      height: PropTypes.number,
      left: PropTypes.number,
      position: PropTypes.string,
      top: PropTypes.number,
      width: PropTypes.string,
    }),
    chatUserMap: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    coloredUsernames: PropTypes.bool.isRequired,
    onChatMessageClick: PropTypes.func.isRequired,
    listIndex: PropTypes.number.isRequired,
  };

  static defaultProps = {
    key: undefined,
    selected: false,
    displayFullTimestamp: false,
    displayDeleteIcon: false,
    deletePermissions: false,
    onChatMessageDeleteClick: undefined,
    style: undefined,
  };

  static getAnnotationContentWidth(iconArray, fullTimestamp) {
    let buffer = 0;
    // Timestamp
    if (fullTimestamp) {
      buffer += FULL_TIMESTAMP_WIDTH;
    } else {
      buffer += DEFAULT_TIMESTAMP_WIDTH;
    }
    // Inner left padding
    buffer += ANNOTATION_PADDING_WIDTH; // Padding between time and content

    // Icons
    const iconsShowing = iconArray.filter(Boolean).length;
    if (iconsShowing > 0) {
      // Action icon padding
      buffer += ANNOTATION_PADDING_WIDTH;

      if (iconsShowing > 2) {
        // Compression into a single three-dot menu
        buffer += ICON_WIDTH;
      } else {
        // However many icons there are
        buffer += iconsShowing * ICON_WIDTH;
      }
    }
    return buffer;
  }

  formatUrl = (url) => {
    const illegalLastChars = ['.', ',', '!', ';'];
    let illegalChar = '';
    let parsedUrl = url;
    if (illegalLastChars.includes(url.charAt(url.length - 1))) {
      illegalChar = url.slice(-1);
      parsedUrl = url.slice(0, url.length - 1);
    }
    return (
      <>
        <Link href={parsedUrl} target="_blank">
          {parsedUrl}
        </Link>
        {illegalChar}
      </>
    );
  };

  getContentStyle = () => {
    const { displayFullTimestamp, displayDeleteIcon } = this.props;
    const buffer = ChatMessageLine.getAnnotationContentWidth(
      [displayDeleteIcon],
      displayFullTimestamp
    );
    return { width: `calc(100% - ${buffer}px` };
  };

  getUserStyle = (username) => {
    const { chatUserMap, coloredUsernames } = this.props;
    const user = chatUserMap.find((item) => item.username === username);
    if (user && coloredUsernames) {
      return { color: user.color };
    }
    return { color: `#000` };
  };

  generateFormattedText = (text) => {
    const { chatUserMap } = this.props;
    const parts = text.split(/(\S+[^\s])|(\s+[^\S])/g);
    // Format any @mentions
    const tagRegex = /(@[^\s]+)/g;
    const urlRegex = /https?:\/\/[^\s]+/g;
    return parts
      .map((part) => {
        if (part && part.match(tagRegex)) {
          return <b style={this.getUserStyle(part.substring(1))}>{part}</b>;
        }
        if (part && part.match(urlRegex)) {
          return this.formatUrl(part);
        }
        if (chatUserMap.find((user) => user.username === part)) {
          return <b style={this.getUserStyle(part)}>{part}</b>;
        }
        return part;
      })
      .filter((part) => part !== undefined && part !== '');
  };

  renderDisplayText = () => {
    const { chatMessage, classes } = this.props;
    return (
      <>
        <Typography
          variant="body2"
          className={classes.usernameSpan}
          style={this.getUserStyle(chatMessage.username)}
        >
          {chatMessage.username ? chatMessage.username : '(System Message)'}
        </Typography>
        <Typography variant="body2" className={classes.blockSpan}>
          {this.generateFormattedText(chatMessage.msg)}
        </Typography>
      </>
    );
  };

  renderChatLogActions = () => {
    const {
      chatMessage,
      displayDeleteIcon,
      deletePermissions,
      onChatMessageDeleteClick,
    } = this.props;

    const options = [];

    // Delete Action
    if (deletePermissions && displayDeleteIcon) {
      options.push({
        label: 'Delete Annotation',
        onClick: () => onChatMessageDeleteClick(chatMessage),
        icon: Delete,
      });
    }

    return <AnnotationActions options={options} />;
  };

  render() {
    const {
      chatMessage,
      classes,
      key,
      selected,
      displayFullTimestamp,
      onChatMessageClick,
      listIndex,
      style,
    } = this.props;
    const timeFormat = displayFullTimestamp ? 'full' : 'time-no-seconds';
    const timestamp = DateFormatUtils.formatDate(
      chatMessage.msgDate,
      timeFormat
    );

    let chatClasses = classes.lineBase;
    chatClasses += selected ? ` ${classes.selected}` : '';

    return (
      <ListItem
        style={style}
        className={chatClasses}
        key={key}
        divider
        alignItems="flex-start"
        onClick={() => onChatMessageClick(chatMessage, listIndex)}
      >
        <ListItemIcon className={classes.lineTimestamp}>
          <Typography variant="body2" className={classes.timestamp}>
            {timestamp}
          </Typography>
        </ListItemIcon>
        <ListItemText
          className={classes.listItemTextRoot}
          style={this.getContentStyle()}
          primary={this.renderDisplayText()}
        />
        <ListItemSecondaryAction>
          {this.renderChatLogActions()}
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export default withStyles(styles)(ChatMessageLine);
