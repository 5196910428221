import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from 'base-components';

import ToggleItemsResponsiveLayout from 'library/CompositeComponents/grid-layout/ToggleItemsResponsiveLayout';

import defaultLayout from './layouts';

const ROW_HEIGHT = 25;
const BREAKPOINTS = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 150 };
const COLS = { lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 };
const SEATUBE_RESOURCE_ID = 2;
// Not sure what this refers to?
const RESOURCE_TYPE_ID = 2000;

/**
 * SeaTubeLayout component responsible for the layout of SeaTube.
 *
 * TODO: add save functionality to layout add share functionality to layout put
 * items into WidgetService instead of hard-coding better selection of
 * default/user selected layout
 */
class SeaTubeLayout extends PureComponent {
  static propTypes = {
    annotationPermissions: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    onRemove: PropTypes.func,
    onLayoutClick: PropTypes.func,
    chatLog: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    annotationPermissions: false,
    onRemove: undefined,
    onLayoutClick: undefined,
    isLoggedIn: undefined,
    chatLog: undefined,
  };

  renderBottomDrawerContent = () => {
    const { isLoggedIn } = this.props;

    if (isLoggedIn) return null;
    return (
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Log in/create an account to access more layout features!
        </Typography>
      </Grid>
    );
  };

  render() {
    const { annotationPermissions, onRemove, chatLog, ...otherProps } =
      this.props;

    const items = [
      {
        key: 'annotation-entry',
        name: 'Annotation Entry',
        permission: !!annotationPermissions,
      },
      { key: 'annotation-list', name: 'Annotation List', permission: true },
      {
        key: 'chat-log',
        name: 'Chat Log',
        permission: !!annotationPermissions,
      },
      {
        key: 'seatube-dive-details',
        name: 'Dive Details',
        permission: true,
        hidden: true,
      },
      { key: 'seatube-map', name: 'Map', permission: true },
      {
        key: 'seatube-sensor-display',
        name: 'Sensor Display',
        permission: true,
      },
      { key: 'seatube-video', name: 'Video', permission: true },
    ];

    return (
      <ToggleItemsResponsiveLayout
        breakpoints={BREAKPOINTS}
        cols={COLS}
        draggableCancel=".onc-panel-content"
        items={items}
        requiresPermission
        rowHeight={ROW_HEIGHT}
        bottomDrawerContent={this.renderBottomDrawerContent()}
        defaultLayout={defaultLayout}
        resourceId={SEATUBE_RESOURCE_ID}
        resourceTypeId={RESOURCE_TYPE_ID}
        annotationPermissions={annotationPermissions}
        chatLog={chatLog}
        {...otherProps}
      />
    );
  }
}

export default SeaTubeLayout;
