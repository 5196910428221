import { useCallback, useEffect } from 'react';
import ReactJWPlayer from 'react-jw-player';
import { withSize } from './size-me/SizeMe';

type ResizableJWPlayerProps = {
  playerId: string;
  playerScript?: string;
  customProps?: Record<string, unknown>;
  size?: {
    height?: number | string;
    width?: number | string;
  };
  onError?: (message: string) => void;
};

declare global {
  interface Window {
    jwplayer: any;
  }
}

const ResizableJWPlayer = ({
  playerId,
  playerScript = 'https://content.jwplatform.com/libraries/wJEeyFgY.js',
  customProps = undefined,
  size = { height: 360, width: 480 },
  onError = undefined,
  ...rest
}: ResizableJWPlayerProps) => {
  const getPlayer = useCallback(() => {
    if (window.jwplayer) {
      return window.jwplayer(playerId);
    }
    return undefined;
  }, [playerId]);

  const resizePlayer = useCallback(() => {
    const player = getPlayer();
    if (player) {
      try {
        player.resize(size.width, size.height);
      } catch (error) {
        onError('An error occurred resizing the video player');
      }
    }
  }, [getPlayer, size.width, size.height, onError]);

  useEffect(() => {
    resizePlayer();
  }, [size.width, size.height, getPlayer, resizePlayer]);

  // Some high order components rewrite playerId to undefined. Create a default id in such cases
  let jwPlayerId = '1';
  if (playerId) {
    jwPlayerId = playerId;
  }

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div style={{ position: 'absolute', width: '100%', height: size.height }}>
        <ReactJWPlayer
          playerId={jwPlayerId}
          playerScript={playerScript}
          customProps={{
            ...customProps,
            height: size.height,
            width: size.width,
          }}
          {...rest}
        />
      </div>
    </div>
  );
};

export default withSize({ monitorHeight: true })(ResizableJWPlayer);
