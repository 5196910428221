import { parseDmasAPIResponse, get } from 'util/WebRequest';

class TaskDefinitionService {
  /**
   * Gets all TaskDefinition's from the database by it's type for this user.
   * User is the currently logged in user.
   *
   * @param {any} taskTypeId Id of the taskDefinition to get
   */
  static getTaskDefinitionByTaskTypeAndOwner(taskTypeId) {
    return get('TaskDefinitionService', {
      query: 1,
      taskTypeId,
    });
  }

  /**
   * Gets a TaskDefinition from the database by it's id
   *
   * @param {any} taskDefinitionId Id of the taskDefinition to get
   */
  static getTaskDefinition(taskDefinitionId) {
    return get('TaskDefinitionService', {
      query: 2,
      taskDefinitionId,
    });
  }

  /**
   * Gets a TaskDefinition from the database by it's id
   *
   * @param {any} taskId Id of the taskDefinition to get
   */
  static runTaskNow(taskId) {
    return get('TaskDefinitionService', {
      query: 4,
      taskId,
    });
  }

  /** Gets all tasks that are allowed in batches */
  static getBatchableTasks() {
    return get('TaskDefinitionService', {
      query: 5,
    }).then((response) => parseDmasAPIResponse(response));
  }
}

export default TaskDefinitionService;
