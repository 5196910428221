import { useMemo } from 'react';
import { DenseThemeProvider } from '@onc/theme';
import FixedCameraPlaybackDashboard from 'domain/AppComponents/sea-tube/fixed-camera-playback/FixedCameraPlaybackDashboard';
import QueryParameterContext from 'domain/Apps/menu/QueryParameterContext';
import { PipProvider } from 'util/hooks/usePipWindow';

interface FixedCameraPlaybackParams {
  searchTreeNodeId: number;
}

type Props = {
  match: {
    isExact: boolean;
    params: FixedCameraPlaybackParams;
    path: string;
    url: string;
  };
};
const FixedCameraPlaybackApp = (props: Props) => {
  const { match } = props;

  const params = useMemo(() => match.params, [match.params]);

  return (
    <DenseThemeProvider>
      <QueryParameterContext.Provider value={params}>
        <PipProvider>
          <FixedCameraPlaybackDashboard />
        </PipProvider>
      </QueryParameterContext.Provider>
    </DenseThemeProvider>
  );
};

export default FixedCameraPlaybackApp;
