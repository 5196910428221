import { ReactNode } from 'react';

import { AccordionActions, AccordionSummary, Paper } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@onc/icons';
import Accordion from '../accordion/Accordion';
import Typography from '../text/Typography';

const styles = {
  container: {
    margin: '1vh 5vw 1vh 5vw',
  },
};

/**
 * An expandable container that allows you to hide any react elements.
 * Properties:
 */
type ExpandableContainerProps = {
  /** The text which is displayed in the not-collapsed section of the element */
  summary: string;
  /**
   * A react element which will be wrapped in AccordionActions. This should be
   * used for any call-to-actions
   */
  actions?: ReactNode;
  /* child elements of the component): this element expects to have child components to wrap. */
  children: ReactNode;
};

const ExpandableContainer = ({
  summary,
  actions = undefined,
  children,
}: ExpandableContainerProps) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{summary}</Typography>
    </AccordionSummary>
    <Paper sx={styles.container} elevation={0}>
      {children}
      {actions && <AccordionActions>{actions}</AccordionActions>}
    </Paper>
  </Accordion>
);

export default ExpandableContainer;
