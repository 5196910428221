import { memo, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { DenseThemeProvider } from '@onc/theme';
import { StepDisplayInfo, Paper } from 'base-components';
import DeviceWorkflowBulkUpdateService from 'domain/services/DeviceWorkflowBulkUpdateService';
import { ProcessGroup } from 'domain/services/ProcessGroupService';
import {
  AreasOfResponsibility,
  Phase,
  PhaseTask,
  Process,
} from 'domain/services/ProcessService';
import LinearOncStepper from 'library/CompositeComponents/stepper/LinearOncStepper';
import Step from 'library/CompositeComponents/stepper/Step';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import DeviceWorkflowBulkUpdateActionTab from './DeviceWorkflowBulkUpdateActionTab';
import DeviceWorkflowBulkUpdateFilterTab from './DeviceWorkflowBulkUpdateFilterTab';
import DeviceWorkflowBulkUpdateResultsTab from './DeviceWorkflowBulkUpdateResultsTab';
import { transformToRows } from './DeviceWorkflowBulkUpdateTableLogic';

const useStyles = makeStyles((theme) =>
  createStyles({
    deviceworkflowbulkupdatepage: {
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(),
        paddingLeft: theme.spacing(),
      },
    },
  })
);

const DeviceWorkflowBulkUpdatePage = () => {
  const classes = useStyles();
  const [devices, setDevices] = useState<any[]>([]);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [processGroups, setProcessGroups] = useState<ProcessGroup[]>([]);
  const [phases, setPhases] = useState<Phase[]>([]);
  const [phaseTasks, setPhaseTasks] = useState<PhaseTask[]>([]);
  const [areasOfResponsibility, setAreasOfResponsibility] = useState<
    AreasOfResponsibility[]
  >([]);
  const [isApplyingFilters, setIsApplyingFilters] = useState<boolean>(false);

  const [selection, setSelection] = useState<number[]>([]);

  const [results, , fetchResults] = useWebService({
    method: DeviceWorkflowBulkUpdateService.getAll,
    parser: transformToRows,
  });
  const { onInfo, onError } = useSnackbars();

  const handleDeviceChange = (event, option) => {
    setDevices(option);
  };

  const handleProcessChange = (event, option) => {
    setProcesses(option);
  };

  const handleProcessGroupChange = (event, option) => {
    setProcessGroups(option);
    // When ProcessGroup filter is empty, clear any selected
    // devices in the Device filter
    if (option.length === 0) {
      setDevices(option);
    }
  };

  const handlePhaseChange = (event, option) => {
    setPhases(option);
  };

  const handlePhaseTaskChange = (event, option) => {
    setPhaseTasks(option);
  };

  const handleAreasOfResponsibility = (event, option) => {
    setAreasOfResponsibility(option);
  };

  const clearAll = () => {
    setDevices([]);
    setProcesses([]);
    setProcessGroups([]);
    setPhases([]);
    setPhaseTasks([]);
    setAreasOfResponsibility([]);
  };

  const [stepNum, setStepNum] = useState<number>(1);
  const displayInfo: StepDisplayInfo[] = [
    {
      label: 'Filters',
    },
    {
      label: 'Results',
    },
    {
      label: 'Action',
    },
  ];

  const decrementStep = () => {
    if (stepNum === 2) {
      setSelection([]);
    }
    setStepNum(stepNum - 1);
  };

  const incrementStep = () => setStepNum(stepNum + 1);

  const [, , bulkUpdate] = useWebService({
    method: DeviceWorkflowBulkUpdateService.update,
  });

  const parseStatus = (curStatus) => {
    const splitStatus = curStatus.split('(');
    const val = splitStatus[1].split(')');
    return val[0];
  };

  const applyFilters = async () => {
    const deviceId: number[] = devices.map((d) => d.deviceId);

    const processGroupId: number[] = processGroups.map(
      (pg) => pg.processGroupId
    );
    const processTypeId: number[] = processes.map((p) => p.processTypeId);

    const phaseId: number[] = phases.map((p) => p.phaseTypeId);

    const phaseTaskId: number[] = phaseTasks.map((pt) => pt.taskId);

    const areasOfResponsibilityId: number[] = areasOfResponsibility.map(
      (aor) => aor.areasOfResponsibilityId
    );

    await fetchResults({
      deviceIdFilter: deviceId.join(','),
      processGroupIdFilter: processGroupId.join(','),
      processTypeIdFilter: processTypeId.join(','),
      phaseIdFilter: phaseId.join(','),
      phaseTaskIdFilter: phaseTaskId.join(','),
      areasOfResponsibilityIdFilter: areasOfResponsibilityId.join(','),
    });
  };

  const submitFilters = async () => {
    setIsApplyingFilters(true);
    await applyFilters();
    incrementStep();
    setIsApplyingFilters(false);
  };

  const handleSubmit = ({
    curStatus,
    comment,
    jiraIssueId,
    appendComment,
    appendJiraIssueId,
  }) => {
    bulkUpdate({
      deviceProcessGroupDetailsIds: selection.join(','),
      status: parseStatus(curStatus),
      comment,
      jiraTicketId: jiraIssueId,
      appendComment,
      appendJiraTicketId: appendJiraIssueId,
    }).then((response) => {
      if (response.data.statusCode === 0) {
        onInfo('Bulk Update status, comment, and jira issue id updated');
        applyFilters();
        decrementStep();
      } else {
        onError('Bulk Update failed to save');
      }
    });
  };

  return (
    <div className={classes.deviceworkflowbulkupdatepage}>
      <DenseThemeProvider>
        <LinearOncStepper
          displayInfo={displayInfo}
          showLabelBelow
          stepNum={stepNum}
        >
          <Step
            content={
              <Paper elevation={1}>
                <DeviceWorkflowBulkUpdateFilterTab
                  selectedDevices={devices}
                  selectedProcesses={processes}
                  selectedPhases={phases}
                  selectedProcessGroups={processGroups}
                  selectedTasks={phaseTasks}
                  areasOfResponsibility={areasOfResponsibility}
                  handleDeviceChange={handleDeviceChange}
                  handleProcessChange={handleProcessChange}
                  handleProcessGroupChange={handleProcessGroupChange}
                  handlePhaseChange={handlePhaseChange}
                  handlePhaseTaskChange={handlePhaseTaskChange}
                  handleAreasOfResponsibilityList={handleAreasOfResponsibility}
                  clearAll={clearAll}
                />
              </Paper>
            }
            isNextDisabled={() => processGroups.length === 0}
            isNextLoading={isApplyingFilters}
            isBackDisabled={() => true}
            onBack={() => {}}
            onNext={submitFilters}
          />
          <Step
            content={
              <DeviceWorkflowBulkUpdateResultsTab
                rows={results}
                selection={selection}
                setSelection={setSelection}
              />
            }
            isNextDisabled={() => selection.length === 0}
            onBack={decrementStep}
            onNext={incrementStep}
          />
          <Step
            content={
              <DeviceWorkflowBulkUpdateActionTab onSubmit={handleSubmit} />
            }
            isNextDisabled={() => true}
            onBack={decrementStep}
            onNext={() => {}}
          />
        </LinearOncStepper>
      </DenseThemeProvider>
    </div>
  );
};

export default memo(DeviceWorkflowBulkUpdatePage);
