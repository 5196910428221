import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import VideoWidgetConfig from 'domain/AppComponents/Dashboard/video-widget/VideoWidgetConfig';
import VideoWidgetDisplay from 'domain/AppComponents/Dashboard/video-widget/VideoWidgetDisplay';
import { type option } from 'domain/AppComponents/dropdowns/PlaylistsDropdown';
import PlaylistService from 'domain/services/PlaylistService';

const useStyles = makeStyles(() =>
  createStyles({
    circularProgress: {},
    contentDiv: {},
    videoDiv: {
      height: '100%',
      width: '100%',
      paddingLeft: '8px',
    },
  })
);

type VideoWidgetProps = {
  classes?: {
    circularProgress: string;
    contentDiv: string;
  };
  onSave: (values: any) => Promise<void>;
  onConfigFinish: () => void;
  showDisplay?: boolean;
  showTitle?: boolean;
  autoRefresh?: boolean;
  title?: string;
  dataSource?: string;
  timeSource?: string;
  loopPlayback?: boolean;
  startDate?: Moment;
  endDate?: Moment;
  device?: {
    deviceId: number;
    name: string;
    deviceCode: string;
  };
  liveStreamUrl?: string;
  widgetId?: string;
  playlistHdrId?: string;
  locations?: Array<{
    deviceCategoryCode: string;
    stationCode: string;
    pathName: string[];
    els: { deviceId: number; sitedeviceid: number }[];
  }>;
  onError: () => void;
  onInfo: () => void;
};

const VideoWidget: React.FC<VideoWidgetProps> = ({
  showDisplay = false,
  showTitle = true,
  loopPlayback = false,
  title = '',
  dataSource = 'deviceId',
  timeSource = 'latestClip',
  startDate = moment(new Date()),
  endDate = moment(new Date()),
  device = undefined,
  liveStreamUrl = undefined,
  widgetId = undefined,
  playlistHdrId = undefined,
  locations = [],
  classes,
  onSave,
  onConfigFinish,
  onError,
  onInfo,
}) => {
  const allClasses = { ...useStyles(), ...classes };
  const [playlistHdrState, setPlaylistHdrState] = useState<option | undefined>({
    key: undefined,
    label: undefined,
    value: undefined,
  });

  const handleSave = async (values: any) => {
    const { playlistHdr, ...valuesToSave } = values;
    await onSave({ ...valuesToSave, playlistHdrId: playlistHdr.value });
    onConfigFinish();
  };

  useEffect(() => {
    const getPlaylistHdr = async () => {
      const p = await PlaylistService.getPlaylist(Number(playlistHdrId));
      return {
        key: p.playlistHdrId,
        value: p.playlistHdrId,
        label: p.name,
      };
    };

    if (playlistHdrId) {
      getPlaylistHdr().then((playlist) => {
        setPlaylistHdrState(playlist);
      });
    }
  }, [playlistHdrId]);

  const renderContent = () => {
    if (showDisplay) {
      return (
        <VideoWidgetDisplay
          device={device}
          liveStreamUrl={liveStreamUrl}
          startDate={startDate}
          endDate={endDate}
          showTitle={showTitle}
          title={title}
          loopPlayback={loopPlayback}
          widgetId={widgetId}
          dataSource={dataSource}
          timeSource={timeSource}
          playlistHdrId={playlistHdrId}
          locations={locations}
          classes={{
            contentDiv: allClasses.contentDiv,
            circularProgress: allClasses.circularProgress,
            videoDiv: allClasses.videoDiv,
          }}
          onInfo={onInfo}
          onError={onError}
        />
      );
    }

    return (
      <VideoWidgetConfig
        onSave={handleSave}
        onCancel={onConfigFinish}
        showTitle={showTitle}
        title={title}
        dataSource={dataSource}
        timeSource={timeSource}
        loopPlayback={loopPlayback}
        startDate={startDate}
        endDate={endDate}
        device={device}
        liveStreamUrl={liveStreamUrl}
        playlistHdr={playlistHdrState}
        locations={locations}
        open
      />
    );
  };

  return renderContent();
};

export default VideoWidget;
