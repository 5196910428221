/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { DenseThemeProvider } from '@onc/theme';
import { Grid } from 'base-components';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import TaxonButtonSetDetails from './TaxonButtonSetDetails';
import TaxonButtonSetList from './TaxonButtonSetList';
import TaxonButtonSetService from '../../services/TaxonButtonSetService';
import TaxonomyAttributeGroupService from '../../services/TaxonomyAttributeGroupService';
import TaxonomyAttributeService from '../../services/TaxonomyAttributeService';
import TaxonomyService from '../../services/TaxonomyService';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    float: 'left',
    height: `calc(100% - ${theme.spacing(2)})`,
  },
  item: {
    height: '100%',
    overflowY: 'hidden',
  },
});

const TaxonButtonSetConfigPage = ({ classes, onInfo, onError }) => {
  TaxonButtonSetConfigPage.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  const [selectedConfig, setSelectedConfig] = useState(undefined);
  const [attributeList, setAttributeList] = useState([]);
  const [taxonomyList, setTaxonomyList] = useState([]);
  const [taxonButtonSetConfigList, setTaxonButtonSetConfigList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    TaxonomyAttributeService.getAllSelectable()
      .then((response) => {
        setAttributeList(response);
      })
      .catch((error) => {
        onError(error);
      });
  }, [onError]);

  useEffect(() => {
    TaxonomyService.getAll()
      .then((response) => {
        setTaxonomyList(
          response.sort((a, b) => (a.taxonomyName > b.taxonomyName ? 1 : -1))
        );
      })
      .catch((error) => {
        onError(error);
      });
  }, [onError]);

  useEffect(() => {
    TaxonomyAttributeGroupService.getAll()
      .then((response) => {
        response.push({ groupId: -1, name: '(All Attributes)' });
        setGroupList(
          response.sort((a, b) =>
            a.name < b.name || a.groupId === -1 ? -1 : 1
          )
        );
      })
      .catch((error) => {
        onError(error);
      });
  }, [onError]);

  useEffect(() => {
    loadTaxonButtonSets();
  }, []);

  const loadTaxonButtonSets = (selectedId) =>
    TaxonButtonSetService.getAll()
      .then((response) => {
        setTaxonButtonSetConfigList(response);
        if (selectedId) {
          setSelectedConfig(
            response.find((config) => config.taxonButtonSetHdrId === selectedId)
          );
        }
      })
      .catch((error) => {
        onError(error.response?.data?.message);
      });

  const renderDetails = () => {
    if (selectedConfig) {
      return (
        <TaxonButtonSetDetails
          attributeList={attributeList}
          taxonomyList={taxonomyList}
          selectedConfig={selectedConfig}
          reloadConfigList={loadTaxonButtonSets}
          onError={onError}
          onInfo={onInfo}
          groupList={groupList}
        />
      );
    }
    return null;
  };

  return (
    <DenseThemeProvider>
      <Grid className={classes.root} container spacing={1}>
        <Grid item xs={6} sm={4} className={classes.item}>
          <TaxonButtonSetList
            taxonButtonSetConfigList={taxonButtonSetConfigList}
            reloadConfigList={loadTaxonButtonSets}
            selectedConfig={selectedConfig}
            setSelectedConfig={setSelectedConfig}
            onInfo={onInfo}
            onError={onError}
            style={{ height: '100%' }}
          />
        </Grid>
        <Grid item xs={6} sm={8} className={classes.item}>
          {renderDetails()}
        </Grid>
      </Grid>
    </DenseThemeProvider>
  );
};

export default withStyles(styles)(withSnackbars(TaxonButtonSetConfigPage));
