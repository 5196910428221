import PropTypes from 'prop-types';

import { Edit, OpenInBrowser, ShowChart } from '@onc/icons';
import { IconButton, RouterLink } from 'base-components';
import Environment from 'util/Environment';

const DFOpenInBrowserIconButton = (props) => {
  const { campaign } = props;

  const url = `${Environment.getDmasUrl()}/DigitalFishers?campaignId=${
    campaign.dfCampaignId
  }`;

  return (
    <IconButton
      component="a"
      href={url}
      aria-label="Open"
      Icon={OpenInBrowser}
    />
  );
};

const DFEditIconButton = ({ editPath = '', campaign = undefined }) => (
  <IconButton
    component={RouterLink}
    to={`${editPath}/${campaign.dfCampaignId}`}
    params={campaign}
    aria-label="Edit"
    Icon={Edit}
  />
);

const DFShowStatisticsIconButton = ({
  statisticsPath = '',
  campaign = undefined,
}) => (
  <IconButton
    component={RouterLink}
    to={`${statisticsPath}/${campaign.dfCampaignId}`}
    params={campaign}
    aria-label="Statistics"
    Icon={ShowChart}
  />
);

DFOpenInBrowserIconButton.propTypes = {
  campaign: PropTypes.shape({
    dfCampaignId: PropTypes.number,
  }).isRequired,
};

DFEditIconButton.propTypes = {
  editPath: PropTypes.string,
  campaign: PropTypes.shape({
    dfCampaignId: PropTypes.number,
  }),
};

DFShowStatisticsIconButton.propTypes = {
  statisticsPath: PropTypes.string,
  campaign: PropTypes.shape({
    dfCampaignId: PropTypes.number,
  }),
};

export {
  DFOpenInBrowserIconButton,
  DFShowStatisticsIconButton,
  DFEditIconButton,
};
