import {
  createTheme,
  Theme,
  adaptV4Theme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { oceans3Theme } from '@onc/theme';
import { Grid } from 'base-components';
import APIImage from 'domain/Apps/oceans-3-landing-page/images/API-Oceans3.jpg';
import DashboardsImage from 'domain/Apps/oceans-3-landing-page/images/Dashboards-Oceans3.jpg';
import DataPreviewImage from 'domain/Apps/oceans-3-landing-page/images/DataPreview-Oceans3.jpg';
import DataSearchImage from 'domain/Apps/oceans-3-landing-page/images/DataSearch-Oceans3.jpg';
import DigitalFishersImage from 'domain/Apps/oceans-3-landing-page/images/DigitalFishers-Oceans3.jpg';
import EndeavourEarthquakeCatalogImage from 'domain/Apps/oceans-3-landing-page/images/EndeavourEarthquakeCatalog-Oceans3.jpg';
import ExpeditionVideoImage from 'domain/Apps/oceans-3-landing-page/images/ExpeditionVideo-Oceans3.jpg';
import FixedCameraImage from 'domain/Apps/oceans-3-landing-page/images/FixedCamera-Oceans3.jpg';
import GeospatialMapImage from 'domain/Apps/oceans-3-landing-page/images/GeospatialMap-Oceans3.jpg';
import HydrophoneImage from 'domain/Apps/oceans-3-landing-page/images/Hydrophone-Oceans3.jpg';
import PlottingUtilityImage from 'domain/Apps/oceans-3-landing-page/images/PlottingUtility-Oceans3.jpg';
import VintageDmasHardware from 'domain/Apps/oceans-3-landing-page/images/VintageDmasHardware_16x9-Oceans3.jpg';
import PanelCard from 'domain/Apps/oceans-3-landing-page/PanelCard';
import Environment from 'util/Environment';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const STYLES = (theme: Theme) =>
  createStyles({
    page: {
      marginTop: theme.spacing(0),
      width: '100%',
      padding: theme.spacing(1),
    },
    titleFormat: {
      '& h4': {
        whiteSpace: 'pre-line',
      },
    },
  });

const theme = createTheme(
  adaptV4Theme({
    ...oceans3Theme,
    breakpoints: {
      values: {
        xs: 600,
        sm: 845,
        md: 1245,
        lg: 1645,
        xl: 1800,
      },
    },
  })
);

type LandingPageApplicationsProps = WithStyles<typeof STYLES>;

const LandingPageApplications = (props: LandingPageApplicationsProps) => {
  const { classes } = props;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div>
          <Grid container spacing={1} className={classes.page}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={DataPreviewImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2KB/Data+Preview+Help"
                pageUrl={`${Environment.getDmasUrl()}/DataPreview`}
                titleText="Data Preview"
                bodyText="At-a-glance pre-generated plots for ONC data, including lifetime climate data, latest images from coastal radars and previews for all instruments from the previous day and month updated nightly."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={DataSearchImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2KB/Data+Search+Help"
                pageUrl={`${Environment.getDmasUrl()}/DataSearch`}
                titleText="Data Search"
                bodyText="Access all ONC archived data via search tree and map. Instrument data can be searched by location, by category or property. Download searched data and accompanying metadata reports."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={PlottingUtilityImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2KB/Plotting+Utility+Help"
                pageUrl={`${Environment.getDmasUrl()}/PlottingUtility`}
                titleText="Plotting Utility"
                bodyText="Interactive plotting tool for time series data."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={HydrophoneImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2KB/Hydrophone+Viewer+Help"
                pageUrl={`${Environment.getDmasUrl()}/SearchHydrophoneData`}
                titleText="Hydrophone Viewer"
                bodyText="Search, visualize and download hydrophone data."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={GeospatialMapImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2KB/Geospatial+Map+Help"
                pageUrl={`${Environment.getLinkUrl()}/GeospatialMap`}
                titleText="Community Fishers Map"
                bodyText="Browse, preview and download profile data (casts) from CTDs (Conductivity-Temperature-Depth and more) collected as part of the Community Fishers program."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={FixedCameraImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+Help"
                pageUrl={`${Environment.getDmasUrl()}/SeaTube`}
                titleText="Fixed Cameras"
                bodyText="Access video and annotation data from any ONC fixed camera location via SeaTube Pro."
              />
            </Grid>
            <Grid
              className={classes.titleFormat}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
            >
              <PanelCard
                imageUrl={ExpeditionVideoImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help"
                pageUrl={`${Environment.getLinkUrl()}/ExpeditionManagement`}
                titleText="SeaTube V3
                Expedition Management"
                bodyText="Access video, annotation data, and expedition logs collected during at sea expeditions via SeaTube V3: Expedition Management."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={DigitalFishersImage}
                infoUrl="https://www.oceannetworks.ca/learning/post-secondary-education/citizen-science-resources/digital-fishers/"
                pageUrl={`${Environment.getDmasUrl()}/DigitalFishers`}
                titleText="Digital Fishers"
                bodyText="Citizen scientists - annotate or describe the objects and animals you see in ONC undersea videos by selecting from the fields on your screen"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={DashboardsImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2KB/Dashboards+Help"
                pageUrl={`${Environment.getLinkUrl()}/Dashboards`}
                titleText="Dashboards"
                bodyText="Create your own collection of data on a single web page. Embed individual widgets or the full Dashboard into any web page"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={APIImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2A/OpenAPI"
                pageUrl={`${Environment.getLinkUrl()}/OpenAPI`}
                titleText="Web Services API"
                bodyText="Access ONC data through API (Application Programming Interface)"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={EndeavourEarthquakeCatalogImage}
                infoUrl="https://wiki.oceannetworks.ca/display/O2KB/Endeavour+Earthquake+Catalogue+Help"
                pageUrl={`${Environment.getDmasUrl()}/EndeavourEarthquakeCatalog`}
                titleText="Endeavour Earthquake Catalogue"
                bodyText="Filter, plot and map earthquakes local to the Endeavour Hydrothermal Vents."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <PanelCard
                imageUrl={VintageDmasHardware}
                pageUrl={`${Environment.getDmasUrl()}/DataPreview`}
                titleText="Legacy Menu"
                bodyText="Feeling Nostalgic?"
              />
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default withStyles(STYLES)(LandingPageApplications);
