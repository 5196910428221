import { makeStyles } from '@mui/styles';
import { DenseThemeProvider } from '@onc/theme';
import FixedCameraLocationDashboard from 'domain/AppComponents/sea-tube/fixed-camera-locations/FixedCameraLocationDashboard';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#EEE',
    height: '100%',
  },
  loading: {
    position: 'fixed',
    top: '40%',
    left: '50%',
  },
}));

const FixedCameraLocationApp: React.FC = () => {
  const classes = useStyles();

  const renderContent = () => (
    <DenseThemeProvider>
      <FixedCameraLocationDashboard />
    </DenseThemeProvider>
  );

  return <div className={classes.root}>{renderContent()}</div>;
};

export default FixedCameraLocationApp;
