import moment from 'moment';
import ShowAllContentFormatter from './ShowAllContentFormatter';

const DateFormatter = ({ row, value, selected }: any) => {
  let displayValue = '';
  if (moment(value).isValid()) {
    displayValue = moment.utc(value).format('YYYY-MM-DD HH:mm:ss');
  }
  return ShowAllContentFormatter({ row, value: displayValue, selected });
};

export default DateFormatter;
