import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  EditIconButton,
  DeleteIconButton,
  RefreshButton,
} from 'domain/AppComponents/IconButtons';
import ExtensionDetailService from 'domain/services/ExtensionDetailService';
import ExtensionService from 'domain/services/ExtensionService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import ExtensionConfig from './ExtensionConfig';
import ExtensionDetailsTable from './ExtensionDetailsTable';
import FilterCell from './FilterCell';

const headers = [
  { title: 'Extension ID', name: 'extensionId' },
  { title: 'Comment', name: 'comment' },
  { title: 'Color Band', name: 'colorBand' },
  { title: 'Agreement', name: 'agreement' },
  { title: 'Length (m)', name: 'length' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: ' ', name: 'icons' },
];

const tableColumnExtensions = [
  {
    columnName: 'extensionId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'comment',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'colorBand',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'agreement',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'length',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'icons',
    width: ColumnInfo.medium,
    align: 'left',
  },
];

class ExtensionsListingTab extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      addExtensionButton: PropTypes.string,
      panelDiv: PropTypes.string,
    }).isRequired,
    extensionIdParam: PropTypes.number,
    extensionDetailIdParam: PropTypes.number,
    permission: PropTypes.string.isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    hasChangedTabs: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    extensionIdParam: undefined,
    extensionDetailIdParam: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      extensionDetails: [],
      disableAddDetail: true,
      openConfig: false,
      openDelete: false,
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
      sendDetailIdParam: true,
      selection: [],
      isLoadingListing: false,
      isLoadingDetails: false,
    };
  }

  async componentDidMount() {
    const { extensionIdParam, extensionDetailIdParam, hasChangedTabs } =
      this.props;
    await this.refreshTableData();

    if (extensionDetailIdParam) {
      this.getOneExtensionDetail();
      this.setState({ disableAddDetail: false });
    } else if (extensionIdParam) {
      this.getDetails(extensionIdParam);
      const { tableRows } = this.state;
      this.moveExtensionToFront(extensionIdParam);
      const extensionRow = tableRows.find((row) => row.id === extensionIdParam);
      if (extensionRow && !hasChangedTabs) {
        this.setState({
          rowToBeEdited: extensionRow,
          openConfig: true,
          tableRows: [...tableRows],
          extensionId: extensionIdParam,
          disableAddDetail: false,
        });
      } else {
        this.setState({
          tableRows: [...tableRows],
          extensionId: extensionIdParam,
          disableAddDetail: false,
        });
      }
    }
  }

  moveExtensionToFront = (extensionId) => {
    const { tableRows } = this.state;
    const extensionRow = tableRows.find((row) => row.id === extensionId);
    const index = tableRows.indexOf(extensionRow);
    if (index !== -1) {
      tableRows.splice(index, 1);
      tableRows.unshift(extensionRow);
      this.setState({ tableRows: [...tableRows], selection: [0] });
    }
  };

  moveDetailToFront = () => {
    const { extensionDetails } = this.state;
    const { extensionDetailIdParam, hasChangedTabs } = this.props;
    const detail = extensionDetails.find(
      (row) => row.extensionDetailId === extensionDetailIdParam
    );
    const index = extensionDetails.indexOf(detail);
    if (index !== -1) {
      extensionDetails.splice(index, 1);
      extensionDetails.unshift(detail);

      if (!hasChangedTabs) {
        this.setState({
          extensionDetails: [...extensionDetails],
          detailForConfig: detail,
        });
      } else {
        this.setState({
          extensionDetails: [...extensionDetails],
          sendDetailIdParam: false,
        });
      }
    }
  };

  getOneExtensionDetail = async () => {
    const { history, extensionDetailIdParam } = this.props;
    this.setState({ isLoadingDetails: true });
    await ExtensionDetailService.getOne(extensionDetailIdParam)
      .then(async (detail) => {
        const { extensionId } = detail.extension;
        await this.getDetails(extensionId);
        history.push(
          `Cables?extensionId=${extensionId}&extensionDetailId=${extensionDetailIdParam}`
        );
        this.moveExtensionToFront(extensionId);
        this.moveDetailToFront();
      })
      .finally(() => this.setState({ isLoadingDetails: false }));
  };

  icons = (row) => {
    const { permission } = this.props;
    if (permission !== 'RW') {
      return null;
    }

    return (
      <>
        <EditIconButton
          onClick={() => {
            this.setState({
              openConfig: true,
              rowToBeEdited: row,
            });
          }}
          aria-label="Edit Extension"
          size="small"
        />
        <DeleteIconButton
          onClick={() => {
            this.setState({
              openDelete: true,
              rowToBeDeleted: row,
            });
          }}
          aria-label="Delete Extension"
          size="small"
        />
      </>
    );
  };

  handleCancel = () =>
    this.setState({
      openConfig: false,
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
      openDelete: false,
    });

  addEventDialogue = () => {
    const { openConfig, rowToBeEdited } = this.state;
    const { permission } = this.props;
    if (!openConfig) {
      return null;
    }
    return (
      <ExtensionConfig
        permission={permission}
        title={rowToBeEdited ? 'Edit Extension' : 'Add New Extension'}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        extension={rowToBeEdited}
      />
    );
  };

  deleteEventDialogue = () => {
    const { openDelete, rowToBeDeleted } = this.state;
    if (!openDelete) {
      return null;
    }
    return (
      <ConfirmationDialog
        open
        title="Delete Extension"
        content={`Are you sure you want to delete extension ${rowToBeDeleted.extensionId}?`}
        onCancel={this.handleCancel}
        onConfirm={this.handleDelete}
      />
    );
  };

  refreshTableData = async () => {
    const { onError } = this.props;
    this.setState({ isLoadingListing: true });
    await ExtensionService.getMany()
      .then((result) => {
        this.buildTableRows(result);
      })
      .catch((result) => {
        onError(result);
      })
      .finally(() => {
        this.setState({ isLoadingListing: false });
      });
  };

  refreshDetails = () => {
    const { extensionDetailIdParam } = this.props;
    const { extensionId } = this.state;
    if (extensionDetailIdParam) {
      this.getOneExtensionDetail();
      this.setState({ disableAddDetail: false });
    } else {
      this.getDetails(extensionId);
    }
  };

  actionContent = () => {
    const { permission, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceActionRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refreshTableData} />
    );
    if (permission !== 'RW') {
      return <DeviceActionRefreshButton />;
    }
    return (
      <>
        <DeviceActionRefreshButton />
        <ContainedButton
          translationKey="cables.addExtension"
          startIcon={<Add />}
          className={classes.addExtensionButton}
          onClick={() => {
            this.setState({ openConfig: true });
          }}
        />
      </>
    );
  };

  // takes the result of the service call and modifies the returned data for display in the table
  buildTableRows = (extensions) => {
    this.setState({
      tableRows: extensions.map((data) => {
        const extensionForRow = { ...data };
        extensionForRow.modifyDate = DateFormatUtils.formatDate(
          data.modifyDate,
          'full'
        );
        extensionForRow.agreement = data.agreement
          ? data.agreement.name
          : undefined;
        extensionForRow.modifyBy = `${data.modifyBy.firstname} ${data.modifyBy.lastname}`;
        extensionForRow.id = data.extensionId;
        extensionForRow.icons = this.icons(extensionForRow);
        return extensionForRow;
      }),
    });
  };

  getDetails = async (extensionId) => {
    const { onError } = this.props;

    this.setState({ isLoadingDetails: true });
    await ExtensionDetailService.getMany()
      .then((details) => {
        const extensionDetails = details.filter(
          (data) => data.extension.extensionId === extensionId
        );
        this.setState({ extensionDetails });
      })
      .catch(() => onError('Failed to get Details'))
      .finally(() => this.setState({ isLoadingDetails: false }));
  };

  handleRowClick = (data) => {
    this.getDetails(data.row.id);
    this.setState({
      disableAddDetail: false,
      extensionId: data.row.id,
      selection: [data.rowId],
    });
  };

  cancelDetailFromUrl = () => {
    this.setState({ sendDetailIdParam: false });
  };

  handleSave = (form) => {
    const { onInfo, onError, extensionIdParam } = this.props;
    const paramsForPost = {
      comment: form.extensionComment,
      colorBand: form.extensionColorBand,
      agreementId: form.agreement,
      length: form.length,
    };

    if (form.extensionId) {
      paramsForPost.extensionId = form.extensionId;
      ExtensionService.update(paramsForPost)
        .then(async () => {
          this.setState({ rowToBeEdited: undefined, openConfig: false });
          onInfo('Extension saved!');
          await this.refreshTableData();
          if (extensionIdParam) {
            this.moveExtensionToFront(extensionIdParam);
          }
        })
        .catch(() => {
          this.setState({ rowToBeEdied: undefined, openConfig: false });
          onError('Failed to save extension');
        });
    } else {
      ExtensionService.create(paramsForPost)
        .then(async () => {
          this.setState({ rowToBeEdited: undefined, openConfig: false });
          onInfo('Extension created!');
          await this.refreshTableData();
          if (extensionIdParam) {
            this.moveExtensionToFront(extensionIdParam);
          }
        })
        .catch(() => {
          this.setState({ rowToBeEdited: undefined, openConfig: false });
          onError('Failed to create Extension!');
        });
    }
  };

  handleDelete = async () => {
    const { onError, onInfo, extensionIdParam } = this.props;
    const { rowToBeDeleted } = this.state;
    await ExtensionService.delete({ extensionId: rowToBeDeleted.extensionId })
      .then(async () => {
        this.setState({ openDelete: false, rowToBeDeleted: undefined });
        onInfo('Deleted Successfully');
        await this.refreshTableData();
        if (extensionIdParam) {
          this.moveExtensionToFront(extensionIdParam);
        }
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  render() {
    const {
      classes,
      permission,
      extensionDetailIdParam,
      hasChangedTabs,
      onInfo,
      onError,
    } = this.props;
    const {
      tableRows,
      extensionDetails,
      disableAddDetail,
      detailForConfig,
      extensionId,
      sendDetailIdParam,
      selection,
      isLoadingListing,
      isLoadingDetails,
    } = this.state;

    if (!tableRows) return undefined;

    return (
      <>
        <Panel
          title={<Typography variant="h6">Extension Listing</Typography>}
          actionContent={this.actionContent()}
        >
          {this.addEventDialogue()}
          {this.deleteEventDialogue()}
          <SortableTable
            columns={headers}
            columnSizes={tableColumnExtensions}
            columnExtensions={tableColumnExtensions}
            filterExtensions={[
              { columnName: 'icons', filteringEnabled: false },
            ]}
            elevation={0}
            defaultCurrentPage={1}
            pageSize={200}
            rows={tableRows}
            highlighted
            selection={selection}
            filterable
            filterCell={FilterCell}
            stripedRows={false}
            rowOnClick={this.handleRowClick}
            isLoading={isLoadingListing}
          />
        </Panel>
        <div className={classes.panelDiv}>
          <Panel>
            <ExtensionDetailsTable
              extensionDetails={extensionDetails}
              extensionId={extensionId}
              permission={permission}
              onError={onError}
              onInfo={onInfo}
              disableAdd={disableAddDetail}
              detailForConfig={detailForConfig}
              extensionDetailIdParam={
                sendDetailIdParam ? extensionDetailIdParam : undefined
              }
              cancelDetailFromUrl={this.cancelDetailFromUrl}
              refreshTable={this.refreshDetails}
              hasChangedTabs={hasChangedTabs}
              isLoading={isLoadingDetails}
              classes={classes}
            />
          </Panel>
        </div>
      </>
    );
  }
}

export default ExtensionsListingTab;
