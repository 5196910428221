import { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ExpandMore as ExpandMoreIcon } from '@onc/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import {
  VibrationSusceptibilitySelect,
  ShockSusceptibilitySelect,
  TemperatureChangeSusceptibilitySelect,
  ProtrusionSelect,
  CoatingSelect,
  AnodesSelect,
  RORWTextField,
} from 'domain/AppComponents/Form/Fields/RORWFields';
import CharacteristicService from 'domain/services/CharacteristicService';
import {
  SaveButton,
  DeleteButton,
  CreateButton,
} from 'library/CompositeComponents/button/Buttons';
import ResponsiveDataGridLayout from 'library/CompositeComponents/grid-layout/ResponsiveDataGridLayout';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const styles = (theme) => ({
  accordionDetails: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  accordionSummary: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  deviceContainer: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  dropdown: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  readOnlyStyle: {
    fontSize: '1rem',
    transform: 'translate(0, 1.5px) scale(0.75)',
    color: 'rgba(0, 0, 0, 0.54)',
    transformOrigin: 'top left',
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  modifyRoot: {
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

const defaultState = {
  dcExists: true,
  deviceName: undefined,
  openDeleteDialog: false,
  usedFields: {},
  deviceCharacteristics: {
    characteristicId: '',
    deviceId: '',

    targetoperatingdepth: '',
    maxdepth: '',
    vibrationsusceptibility: '',
    shocksusceptibility: '',
    temperaturesusceptibility: '',

    storagetemperaturelow: '',
    storagetemperaturehigh: '',
    operatingtemperaturelow: '',
    operatingtemperaturehigh: '',
    shape: '',

    diameter: '',
    length: '',
    protrusion: '',
    sizenotes: '',
    weightinair: '',

    weightinwater: '',
    housingmaterialtype: '',
    housingmaterialgrade: '',
    surfacefinish: '',
    housematerialcoating: '',

    housematerialcoatspec: '',
    anodes: '',
    anodematerial: '',
    housematerialnote: '',
    fastenertype: '',

    fastenerthread: '',
    fastenermaterialtype: '',
    fastenermaterialgrade: '',
    fastenermaterialcoating: '',
    fastenermaterialcoatspec: '',

    productapplication: '',
    torque: '',
    fastenermaterialnotes: '',
    mountingdevicetype: '',
    mountingdevicenotes: '',

    orientation: '',
    modifyby: '',
    modifyByName: '',
    modifydate: '',
    operationalImpact: '',
    softwareVersionInfo: '',
  },
};

class CharacteristicPanel extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      accordionDetails: PropTypes.string,
      accordionSummary: PropTypes.string,
      deviceContainer: PropTypes.string,
      dropdown: PropTypes.string,
      formButtons: PropTypes.string,
      modifyRoot: PropTypes.string,
      readOnlyStyle: PropTypes.string,
      root: PropTypes.string,
      textField: PropTypes.string,
    }).isRequired,
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };

  pushField = (value, section, usedFields, component) => {
    if (value !== undefined && value !== '') {
      usedFields.push(component);
    } else {
      section.push(component);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = async () => {
    const { deviceId, onError } = this.props;
    await CharacteristicService.get(deviceId).then((response) => {
      if (
        response.data.statusCode === 0 &&
        response.data.payload !== undefined
      ) {
        this.setState({ dcExists: true });
        this.fillForm(response.data.payload);
      } else if (response.data.statusCode === 106) {
        this.setState({ dcExists: false });
      } else {
        this.setState({ dcExists: false });
        onError(response.data.message);
      }
    });
  };

  deleteEventDialogue = () => {
    const {
      deviceCharacteristics: { characteristicId },
      openDeleteDialog,
    } = this.state;
    if (!openDeleteDialog) {
      return null;
    }
    return (
      <ConfirmationDialog
        open
        title="Delete Physical Characteristics"
        content={`Are you sure you want to delete device characteristic ${characteristicId}?`}
        onCancel={this.handleCancel}
        onConfirm={this.onDelete}
      />
    );
  };

  fillForm = (result) => {
    this.setState({
      deviceCharacteristics: { ...result },
      usedFields: { ...result },
    });
  };

  fillButtons = (permission) => {
    const { dcExists } = this.state;

    if (permission !== 'RW') return null;
    if (dcExists) {
      return (
        <>
          <SaveButton onClick={this.onSave} />
          <DeleteButton
            onClick={() => this.setState({ openDeleteDialog: true })}
          />
        </>
      );
    }
    return <CreateButton onClick={this.onCreate} />;
  };

  handleCancel = () => {
    this.setState({ openDeleteDialog: false });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { deviceCharacteristics } = this.state;
    this.setState({
      deviceCharacteristics: {
        ...deviceCharacteristics,
        [name]: value,
      },
    });
  };

  onCreate = async () => {
    const { deviceCharacteristics } = this.state;
    const { onInfo, onError, deviceId } = this.props;
    const keys = Object.keys(deviceCharacteristics);

    for (let x = 0; x < keys.length; x += 1) {
      const key = keys[x];
      deviceCharacteristics[key] =
        deviceCharacteristics[key] !== ''
          ? deviceCharacteristics[key]
          : undefined;
    }

    await CharacteristicService.create({ ...deviceCharacteristics, deviceId })
      .then((response) => {
        onInfo(
          `New Physical Characteristics created with id ${response.characteristicId}`
        );
        this.refreshData();
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  onDelete = async () => {
    const {
      deviceCharacteristics: { characteristicId },
    } = this.state;
    const { onInfo, onError } = this.props;

    await CharacteristicService.delete(characteristicId)
      .then(() => {
        onInfo(`Deleted Physical Characteristics ${characteristicId}`);
        this.setState({
          dcExists: false,
          deviceName: undefined,
          openDeleteDialog: false,
          usedFields: {},
          deviceCharacteristics: {
            characteristicId: '',
            deviceId: '',

            targetoperatingdepth: '',
            maxdepth: '',
            vibrationsusceptibility: '',
            shocksusceptibility: '',
            temperaturesusceptibility: '',

            storagetemperaturelow: '',
            storagetemperaturehigh: '',
            operatingtemperaturelow: '',
            operatingtemperaturehigh: '',
            shape: '',

            diameter: '',
            length: '',
            protrusion: '',
            sizenotes: '',
            weightinair: '',

            weightinwater: '',
            housingmaterialtype: '',
            housingmaterialgrade: '',
            surfacefinish: '',
            housematerialcoating: '',

            housematerialcoatspec: '',
            anodes: '',
            anodematerial: '',
            housematerialnote: '',
            fastenertype: '',

            fastenerthread: '',
            fastenermaterialtype: '',
            fastenermaterialgrade: '',
            fastenermaterialcoating: '',
            fastenermaterialcoatspec: '',

            productapplication: '',
            torque: '',
            fastenermaterialnotes: '',
            mountingdevicetype: '',
            mountingdevicenotes: '',

            orientation: '',
            modifyby: '',
            modifyByName: '',
            modifydate: '',
            operationalImpact: '',
            softwareVersionInfo: '',
          },
        });
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  onSave = async () => {
    const { deviceCharacteristics } = this.state;
    const { onInfo, onError, deviceId } = this.props;
    const keys = Object.keys(deviceCharacteristics);

    for (let x = 0; x < keys.length; x += 1) {
      const key = keys[x];
      deviceCharacteristics[key] =
        deviceCharacteristics[key] !== ''
          ? deviceCharacteristics[key]
          : undefined;
    }

    await CharacteristicService.update({ ...deviceCharacteristics, deviceId })
      .then(() => {
        onInfo(
          `Updated Physical Characteristics ${deviceCharacteristics.characteristicId}`
        );
        this.refreshData();
      })
      .catch((response) => onError(response.message));
  };

  renderAccordion = (summaryText, fields) => {
    const { classes } = this.props;

    if (fields.length > 0) {
      return (
        <Accordion>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>{summaryText}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <ResponsiveDataGridLayout formFields={fields} />
          </AccordionDetails>
        </Accordion>
      );
    }
    return null;
  };

  getUsedFields = () => {
    const { permission, classes } = this.props;
    const { deviceCharacteristics, usedFields } = this.state;
    const targetOperatingDepthTextField = (
      <RORWTextField
        name="targetoperatingdepth"
        translationKey="characteristics.targetOperatingDepth"
        onChange={this.onChange}
        value={deviceCharacteristics.targetoperatingdepth}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    const maxDepthTextField = (
      <RORWTextField
        name="maxdepth"
        translationKey="characteristics.maxDepth"
        onChange={this.onChange}
        value={deviceCharacteristics.maxdepth}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    const vibrationsusceptibilitySelect = (
      <VibrationSusceptibilitySelect
        value={deviceCharacteristics.vibrationsusceptibility}
        permission={permission}
        classes={{ dropdown: classes.dropdown }}
        onChange={(event) => {
          this.setState((previousState) => ({
            deviceCharacteristics: {
              ...previousState.deviceCharacteristics,
              vibrationsusceptibility: event.target.value,
            },
          }));
        }}
      />
    );
    const shocksusceptibilitySelect = (
      <ShockSusceptibilitySelect
        onChange={(event) => {
          this.setState((previousState) => ({
            deviceCharacteristics: {
              ...previousState.deviceCharacteristics,
              shocksusceptibility: event.target.value,
            },
          }));
        }}
        value={deviceCharacteristics.shocksusceptibility}
        permission={permission}
        classes={{ textField: classes.textField }}
      />
    );
    const temperaturesusceptibilitySelect = (
      <TemperatureChangeSusceptibilitySelect
        onChange={(event) => {
          this.setState((previousState) => ({
            deviceCharacteristics: {
              ...previousState.deviceCharacteristics,
              temperaturesusceptibility: event.target.value,
            },
          }));
        }}
        value={deviceCharacteristics.temperaturesusceptibility}
        permission={permission}
        classes={{ dropdown: classes.dropdown }}
      />
    );
    const storagetemperaturelowTextField = (
      <RORWTextField
        name="storagetemperaturelow"
        translationKey="characteristics.storageTemperatureLow"
        onChange={this.onChange}
        value={deviceCharacteristics.storagetemperaturelow}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    const storagetemperaturehighTextField = (
      <RORWTextField
        name="storagetemperaturehigh"
        translationKey="characteristics.storageTemperatureHigh"
        onChange={this.onChange}
        value={deviceCharacteristics.storagetemperaturehigh}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    const operatingtemperaturelowTextField = (
      <RORWTextField
        name="operatingtemperaturelow"
        translationKey="characteristics.operatingTemperatureLow"
        onChange={this.onChange}
        value={deviceCharacteristics.operatingtemperaturelow}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    const operatingtemperaturehighTextField = (
      <RORWTextField
        name="operatingtemperaturehigh"
        translationKey="characteristics.operatingTemperatureHigh"
        onChange={this.onChange}
        value={deviceCharacteristics.operatingtemperaturehigh}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    const shapeTextField = (
      <RORWTextField
        name="shape"
        translationKey="characteristics.shape"
        onChange={this.onChange}
        value={deviceCharacteristics.shape}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const diameterTextField = (
      <RORWTextField
        name="diameter"
        translationKey="characteristics.diameter"
        onChange={this.onChange}
        value={deviceCharacteristics.diameter}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    const lengthTextField = (
      <RORWTextField
        name="length"
        translationKey="characteristics.length"
        onChange={this.onChange}
        value={deviceCharacteristics.length}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    const protrusionSelectField = (
      <ProtrusionSelect
        onChange={(event) => {
          this.setState((previousState) => ({
            deviceCharacteristics: {
              ...previousState.deviceCharacteristics,
              protrusion: event.target.value,
            },
          }));
        }}
        value={deviceCharacteristics.protrusion}
        permission={permission}
        classes={{ dropdown: classes.dropdown }}
      />
    );
    const weightinairTextField = (
      <RORWTextField
        name="weightinair"
        translationKey="characteristics.weightInAir"
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        value={deviceCharacteristics.weightinair}
        type="number"
      />
    );
    const weightinwaterTextField = (
      <RORWTextField
        name="weightinwater"
        translationKey="characteristics.weightInWater"
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        value={deviceCharacteristics.weightinwater}
        type="number"
      />
    );
    const housingmaterialtypeTextField = (
      <RORWTextField
        name="housingmaterialtype"
        translationKey="characteristics.housingMaterialType"
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        value={deviceCharacteristics.housingmaterialtype}
        type="string"
      />
    );
    const housingmaterialgradeTextField = (
      <RORWTextField
        name="housingmaterialgrade"
        translationKey="characteristics.housingMaterialGrade"
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        value={deviceCharacteristics.housingmaterialgrade}
        type="string"
      />
    );
    const surfacefinishTextField = (
      <RORWTextField
        name="surfacefinish"
        translationKey="characteristics.housingMaterialSurfaceFinish"
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        value={deviceCharacteristics.surfacefinish}
        type="string"
      />
    );
    const housematerialcoatingField = (
      <CoatingSelect
        value={deviceCharacteristics.housematerialcoating}
        permission={permission}
        classes={{ dropdown: classes.dropdown }}
        onChange={(event) => {
          this.setState((previousState) => ({
            deviceCharacteristics: {
              ...previousState.deviceCharacteristics,
              housematerialcoating: event.target.value,
            },
          }));
        }}
      />
    );
    const housematerialcoatspecTextField = (
      <RORWTextField
        name="housematerialcoatspec"
        translationKey="characteristics.housingMaterialCoatingSpec"
        value={deviceCharacteristics.housematerialcoatspec}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const anodesField = (
      <AnodesSelect
        value={deviceCharacteristics.anodes}
        permission={permission}
        classes={{ dropdown: classes.dropdown }}
        onChange={(event) => {
          this.setState((previousState) => ({
            deviceCharacteristics: {
              ...previousState.deviceCharacteristics,
              anodes: event.target.value,
            },
          }));
        }}
      />
    );
    const anodematerialTextField = (
      <RORWTextField
        name="anodematerial"
        translationKey="characteristics.anodeMaterials"
        value={deviceCharacteristics.anodematerial}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const housematerialnoteTextField = (
      <RORWTextField
        name="housematerialnote"
        translationKey="characteristics.housingMaterialNotes"
        value={deviceCharacteristics.housematerialnote}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        rows={3}
        multiline
        type="string"
      />
    );
    const sizenotesTextField = (
      <RORWTextField
        name="sizenotes"
        translationKey="characteristics.sizeNotes"
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        value={deviceCharacteristics.sizenotes}
        rows={3}
        multiline
        type="string"
      />
    );
    const fastenertypeTextField = (
      <RORWTextField
        name="fastenertype"
        translationKey="characteristics.fasteneTtype"
        value={deviceCharacteristics.fastenertype}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const fastenerthreadTextField = (
      <RORWTextField
        name="fastenerthread"
        translationKey="characteristics.fastenerThread"
        value={deviceCharacteristics.fastenerthread}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const fastenermaterialtypeTextField = (
      <RORWTextField
        name="fastenermaterialtype"
        translationKey="characteristics.fastenerMaterialType"
        value={deviceCharacteristics.fastenermaterialtype}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const fastenermaterialgradeTextField = (
      <RORWTextField
        name="fastenermaterialgrade"
        translationKey="characteristics.fastenerMaterialGrade"
        value={deviceCharacteristics.fastenermaterialgrade}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const fastenermaterialcoatingTextField = (
      <RORWTextField
        name="fastenermaterialcoating"
        translationKey="characteristics.fastenerMaterialCoating"
        value={deviceCharacteristics.fastenermaterialcoating}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const fastenermaterialcoatspecTextField = (
      <RORWTextField
        name="fastenermaterialcoatspec"
        translationKey="characteristics.fastenerMaterialCoatingSpec"
        value={deviceCharacteristics.fastenermaterialcoatspec}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const productapplicationTextField = (
      <RORWTextField
        name="productapplication"
        translationKey="characteristics.fastenerMaterialProductApplication"
        value={deviceCharacteristics.productapplication}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const torqueTextField = (
      <RORWTextField
        name="torque"
        translationKey="characteristics.fastenerMaterialTorque"
        value={deviceCharacteristics.torque}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="number"
      />
    );
    const fastenermaterialnotesTextField = (
      <RORWTextField
        name="fastenermaterialnotes"
        translationKey="characteristics.fastenerMaterialNotes"
        value={deviceCharacteristics.fastenermaterialnotes}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        rows={3}
        multiline
        type="string"
      />
    );
    const mountingdevicetypeTextField = (
      <RORWTextField
        name="mountingdevicetype"
        translationKey="characteristics.mountingDeviceType"
        value={deviceCharacteristics.mountingdevicetype}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const mountingdevicenotesTextField = (
      <RORWTextField
        name="mountingdevicenotes"
        translationKey="characteristics.mountingDeviceNotes"
        value={deviceCharacteristics.mountingdevicenotes}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        rows={3}
        multiline
        type="string"
      />
    );
    const orientationTextField = (
      <RORWTextField
        name="orientation"
        translationKey="characteristics.orientation"
        value={deviceCharacteristics.orientation}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        type="string"
      />
    );
    const operationalImpactTextField = (
      <RORWTextField
        name="operationalImpact"
        translationKey="characteristics.operationalImpact"
        value={deviceCharacteristics.operationalImpact}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        rows={3}
        multiline
        type="string"
      />
    );
    const softwareVersionInfoTextField = (
      <RORWTextField
        name="softwareVersionInfo"
        translationKey="characteristics.softwareVersionInfo"
        value={deviceCharacteristics.softwareVersionInfo}
        onChange={this.onChange}
        permission={permission}
        classes={{ textField: classes.textField }}
        rows={3}
        multiline
        type="string"
      />
    );

    const handlingFields = [];
    const tempRangeFields = [];
    const mountingConsiderationFields = [];
    const fastenerMaterialFields = [];
    const mountingDeviceFields = [];
    const orientationFields = [];
    const operationField = [];
    const softwareField = [];
    const fieldsToRender = [targetOperatingDepthTextField, maxDepthTextField];

    this.pushField(
      usedFields.vibrationsusceptibility,
      handlingFields,
      fieldsToRender,
      vibrationsusceptibilitySelect
    );
    this.pushField(
      usedFields.shocksusceptibility,
      handlingFields,
      fieldsToRender,
      shocksusceptibilitySelect
    );
    this.pushField(
      usedFields.temperaturesusceptibility,
      handlingFields,
      fieldsToRender,
      temperaturesusceptibilitySelect
    );
    this.pushField(
      usedFields.storagetemperaturelow,
      tempRangeFields,
      fieldsToRender,
      storagetemperaturelowTextField
    );
    this.pushField(
      usedFields.storagetemperaturehigh,
      tempRangeFields,
      fieldsToRender,
      storagetemperaturehighTextField
    );
    this.pushField(
      usedFields.operatingtemperaturelow,
      tempRangeFields,
      fieldsToRender,
      operatingtemperaturelowTextField
    );
    this.pushField(
      usedFields.operatingtemperaturehigh,
      tempRangeFields,
      fieldsToRender,
      operatingtemperaturehighTextField
    );
    this.pushField(
      usedFields.shape,
      mountingConsiderationFields,
      fieldsToRender,
      shapeTextField
    );
    this.pushField(
      usedFields.diameter,
      mountingConsiderationFields,
      fieldsToRender,
      diameterTextField
    );
    this.pushField(
      usedFields.length,
      mountingConsiderationFields,
      fieldsToRender,
      lengthTextField
    );
    this.pushField(
      usedFields.protrusion,
      mountingConsiderationFields,
      fieldsToRender,
      protrusionSelectField
    );
    this.pushField(
      usedFields.weightinair,
      mountingConsiderationFields,
      fieldsToRender,
      weightinairTextField
    );
    this.pushField(
      usedFields.weightinwater,
      mountingConsiderationFields,
      fieldsToRender,
      weightinwaterTextField
    );
    this.pushField(
      usedFields.housingmaterialtype,
      mountingConsiderationFields,
      fieldsToRender,
      housingmaterialtypeTextField
    );
    this.pushField(
      usedFields.housingmaterialgrade,
      mountingConsiderationFields,
      fieldsToRender,
      housingmaterialgradeTextField
    );
    this.pushField(
      usedFields.surfacefinish,
      mountingConsiderationFields,
      fieldsToRender,
      surfacefinishTextField
    );
    this.pushField(
      usedFields.housematerialcoating,
      mountingConsiderationFields,
      fieldsToRender,
      housematerialcoatingField
    );
    this.pushField(
      usedFields.housematerialcoatspec,
      mountingConsiderationFields,
      fieldsToRender,
      housematerialcoatspecTextField
    );
    this.pushField(
      usedFields.anodes,
      mountingConsiderationFields,
      fieldsToRender,
      anodesField
    );
    this.pushField(
      usedFields.anodematerial,
      mountingConsiderationFields,
      fieldsToRender,
      anodematerialTextField
    );
    this.pushField(
      usedFields.housematerialnote,
      mountingConsiderationFields,
      fieldsToRender,
      housematerialnoteTextField
    );
    this.pushField(
      usedFields.sizenotes,
      mountingConsiderationFields,
      fieldsToRender,
      sizenotesTextField
    );
    this.pushField(
      usedFields.fastenertype,
      fastenerMaterialFields,
      fieldsToRender,
      fastenertypeTextField
    );
    this.pushField(
      usedFields.fastenerthread,
      fastenerMaterialFields,
      fieldsToRender,
      fastenerthreadTextField
    );
    this.pushField(
      usedFields.fastenermaterialtype,
      fastenerMaterialFields,
      fieldsToRender,
      fastenermaterialtypeTextField
    );
    this.pushField(
      usedFields.fastenermaterialgrade,
      fastenerMaterialFields,
      fieldsToRender,
      fastenermaterialgradeTextField
    );
    this.pushField(
      usedFields.fastenermaterialcoating,
      fastenerMaterialFields,
      fieldsToRender,
      fastenermaterialcoatingTextField
    );
    this.pushField(
      usedFields.fastenermaterialcoatspec,
      fastenerMaterialFields,
      fieldsToRender,
      fastenermaterialcoatspecTextField
    );
    this.pushField(
      usedFields.productapplication,
      fastenerMaterialFields,
      fieldsToRender,
      productapplicationTextField
    );
    this.pushField(
      usedFields.torque,
      fastenerMaterialFields,
      fieldsToRender,
      torqueTextField
    );
    this.pushField(
      usedFields.fastenermaterialnotes,
      fastenerMaterialFields,
      fieldsToRender,
      fastenermaterialnotesTextField
    );
    this.pushField(
      usedFields.mountingdevicetype,
      mountingDeviceFields,
      fieldsToRender,
      mountingdevicetypeTextField
    );
    this.pushField(
      usedFields.mountingdevicenotes,
      mountingDeviceFields,
      fieldsToRender,
      mountingdevicenotesTextField
    );
    this.pushField(
      usedFields.orientation,
      orientationFields,
      fieldsToRender,
      orientationTextField
    );
    this.pushField(
      usedFields.operationalImpact,
      operationField,
      fieldsToRender,
      operationalImpactTextField
    );
    this.pushField(
      usedFields.softwareVersionInfo,
      softwareField,
      fieldsToRender,
      softwareVersionInfoTextField
    );
    return {
      usedFields: fieldsToRender,
      handlingFields,
      tempRangeFields,
      mountingConsiderationFields,
      fastenerMaterialFields,
      mountingDeviceFields,
      orientationFields,
      operationField,
      softwareField,
    };
  };

  render() {
    const { deviceId, permission, classes } = this.props;
    const {
      dcExists,
      deviceCharacteristics: { characteristicId, modifyByName, modifydate },
    } = this.state;
    const fields = this.getUsedFields();

    if (!dcExists && permission !== 'RW') {
      return (
        <Panel
          title={
            <Typography variant="body1">
              No Physical Characteristics found for device {deviceId}
            </Typography>
          }
        />
      );
    }

    let characteristicIdText;
    let color;
    if (dcExists) {
      characteristicIdText = characteristicId;
    } else {
      characteristicIdText = 'NaN';
      color = 'textSecondary';
    }

    return (
      <>
        {this.deleteEventDialogue()}
        <Panel
          title={
            <>
              <Typography variant="body1">
                Device Physical Characteristics ID:{' '}
              </Typography>
              <Typography variant="body1" color={color}>
                {characteristicIdText}
              </Typography>
            </>
          }
        >
          <div className={classes.modifyRoot}>
            <ResponsiveDataGridLayout formFields={fields.usedFields} />
            {this.renderAccordion('Handling', fields.handlingFields)}
            {this.renderAccordion('Temperature Range', fields.tempRangeFields)}
            {this.renderAccordion(
              'Mounting Considerations',
              fields.mountingConsiderationFields
            )}
            {this.renderAccordion(
              'Fastener Materials',
              fields.fastenerMaterialFields
            )}
            {this.renderAccordion(
              'Mounting Device',
              fields.mountingDeviceFields
            )}
            {this.renderAccordion('Orientation', fields.orientationFields)}
            {this.renderAccordion('Operations', fields.operationField)}
            {this.renderAccordion(
              'Software Version Info',
              fields.softwareField
            )}
          </div>
          <ModifyBy
            username={modifyByName}
            classes={{ root: classes.modifyRoot }}
          />
          <ModifyDate
            date={modifydate}
            classes={{ root: classes.modifyRoot }}
          />
          <div className={classes.formButtons}>
            {this.fillButtons(permission)}
          </div>
        </Panel>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbars(CharacteristicPanel));
