import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class DeviceIpService {
  static getMany(deviceId) {
    return DeviceIpService.get({ deviceId });
  }

  static getOne(ipId) {
    return DeviceIpService.get({ operation: 4, ipId });
  }

  static get(params) {
    return get('DeviceIPService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(params) {
    return post('DeviceIPService', {
      operation: 1,
      ...params,
    }).then((response) => response);
  }

  static update(params) {
    return post('DeviceIPService', {
      operation: 2,
      ...params,
    }).then((response) => response);
  }

  static delete(ipId) {
    return post('DeviceIPService', {
      operation: 3,
      ipId,
    }).then((response) => response);
  }
}
export default DeviceIpService;
