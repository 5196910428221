import * as React from 'react';
import { Delete, FileCopy, GetApp, Share } from '@onc/icons';
import {
  AnchoredHiddenMenu,
  IconButton,
  ListItemIcon,
  MenuItem,
  Typography,
} from 'base-components';
import { EditIconButton } from 'domain/AppComponents/IconButtons';
import { Playlist } from 'domain/services/PlaylistService';

type Props = {
  playlist: Playlist;
  isLocked: boolean;
  onOpenDeleteForm: (playlist: Playlist) => void;
  onDownload: (playlist: Playlist) => void;
  onEdit: (playlist: Playlist) => void;
  onClone: (playlist: Playlist) => void;
  onShare: (
    event: React.MouseEvent<HTMLButtonElement>,
    playlist: Playlist
  ) => void;
};

const PlaylistTableActions: React.VFC<Props> = ({
  playlist,
  isLocked,
  onOpenDeleteForm,
  onClone,
  onDownload,
  onEdit,
  onShare,
}: Props) => (
  <>
    <IconButton
      Icon={Share}
      aria-label="Share"
      onClick={(e) => onShare(e, playlist)}
    />
    <EditIconButton
      disabled={isLocked}
      onClick={() => onEdit(playlist)}
      title={playlist.locked ? 'Playlist is currently locked' : 'Edit'}
    />
    <AnchoredHiddenMenu aria-label="More">
      <MenuItem onClick={() => onClone(playlist)}>
        <ListItemIcon>
          <FileCopy fontSize="small" />
        </ListItemIcon>
        <Typography>Clone</Typography>
      </MenuItem>

      <MenuItem onClick={() => onDownload(playlist)}>
        <ListItemIcon>
          <GetApp fontSize="small" />
        </ListItemIcon>
        <Typography>Download</Typography>
      </MenuItem>

      <MenuItem disabled={isLocked} onClick={() => onOpenDeleteForm(playlist)}>
        <ListItemIcon>
          <Delete fontSize="small" />
        </ListItemIcon>
        <Typography>Delete</Typography>
      </MenuItem>
    </AnchoredHiddenMenu>
  </>
);

export default PlaylistTableActions;
