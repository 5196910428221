import { FC, useState, useEffect, useContext } from 'react';
import { useMap } from 'react-leaflet';
import CastSelectionDialog from 'domain/AppComponents/geospatial-search/community-fisher/cf-dialogs/CastSelectionDialog';
import LassoSelectionTable from 'domain/AppComponents/geospatial-search/community-fisher/LassoSelectionTable';
import { UNASSIGNED_CAST_SEARCH_TREE_NODE_ID } from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchConstants';

import GeospatialSearchHelper from './GeospatialSearchHelper';
import SelectedStationsContext from '../SelectedStationsContext';

type DateRange = {
  dateTo: Date;
  dateFrom: Date;
};

interface GeospatialSearchToolsProps {
  dateRange: DateRange;
  setSelectedLayers: (layerObjects: any) => void;
  setSelectedStationParentCheckbox: (
    stationCode: string,
    hookValue: boolean
  ) => void;
  displayDownloadTableDialog: boolean;
  showDownloadTableDialog: () => void;
  displayCastSelectionDialog: boolean;
  showCastSelectionDialog: () => void;
  setDisplayCastSelectionDialog: (hookValue: boolean) => void;
}

const GeospatialSearchTools: FC<GeospatialSearchToolsProps> = ({
  dateRange,
  setSelectedLayers,
  setSelectedStationParentCheckbox,
  displayDownloadTableDialog,
  showDownloadTableDialog,
  displayCastSelectionDialog,
  showCastSelectionDialog,
  setDisplayCastSelectionDialog,
}) => {
  const map: any = useMap();
  const { selectedStations, updateSelectedStations } = useContext(
    SelectedStationsContext
  );
  const [toggleAllCastsValue, setToggleAllCastsValue] =
    useState<boolean>(false);

  // checks if all the checkboxes have been selected for the currently selected stations and sorts selectedStations
  useEffect(() => {
    const currentlySelectedStations = selectedStations
      .flatMap((station) => station.siteDeviceSubsets)
      .filter((siteDeviceSubset) => !siteDeviceSubset.checkboxSelected);

    setToggleAllCastsValue(currentlySelectedStations.length > 0);

    selectedStations.sort((x, y) => {
      if (x.id === UNASSIGNED_CAST_SEARCH_TREE_NODE_ID) {
        return 1;
      }
      if (y.id === UNASSIGNED_CAST_SEARCH_TREE_NODE_ID) {
        return -1;
      }
      return x.id > y.id ? 1 : -1;
    });
  }, [selectedStations]);

  const closeCastSelection = () => {
    setDisplayCastSelectionDialog(false);
  };

  const childCheckBoxOnChangeCallback = (subset) => {
    // invert the current value of the child's checkboxSelected bool
    const selectedStationsCopy = selectedStations.map((station) => {
      const stationCopy = station;
      stationCopy.siteDeviceSubsets = stationCopy.siteDeviceSubsets.map(
        (siteDeviceSubset) => {
          const siteDeviceSubsetCopy = siteDeviceSubset;
          siteDeviceSubsetCopy.checkboxSelected =
            siteDeviceSubset.siteDeviceSubsetName ===
            subset.siteDeviceSubsetName
              ? !siteDeviceSubset.checkboxSelected
              : siteDeviceSubset.checkboxSelected;
          return siteDeviceSubsetCopy;
        }
      );
      return stationCopy;
    });

    // set parent checkbox to true if all children (siteDeviceSubsets) are marked true
    selectedStationsCopy.map((station) => {
      const stationCopy = station;

      const doesSiteDeviceSubsetMarkedFalseExist =
        station.siteDeviceSubsets.some(
          (siteDeviceSubset) => siteDeviceSubset.checkboxSelected === false
        );
      stationCopy.checkboxSelected = !doesSiteDeviceSubsetMarkedFalseExist;

      const doesSiteDeviceSubsetMarkedTrueExist =
        station.siteDeviceSubsets.some(
          (siteDeviceSubset) => siteDeviceSubset.checkboxSelected === true
        );
      stationCopy.partialCheckboxSelected = doesSiteDeviceSubsetMarkedTrueExist;

      return stationCopy;
    });

    updateSelectedStations(selectedStationsCopy);
  };

  const parentCheckBoxOnChangeCallback = (selectedStation) => {
    setSelectedStationParentCheckbox(
      selectedStation.code,
      !selectedStation.checkboxSelected
    );
  };

  const clearAllSubsets = () => {
    // Reset icons to blue from yellow
    // eslint-disable-next-line no-underscore-dangle
    GeospatialSearchHelper.setAllLayersToUnselectedIcon(map?._layers);
    updateSelectedStations([]);
    setSelectedLayers([]);
  };

  const filterCasts = (casts) => {
    const { dateTo, dateFrom } = dateRange;
    const filteredCasts = casts.filter(
      (cast) =>
        (!dateFrom ||
          new Date(cast.startDate).getTime() >= dateRange.dateFrom.getTime()) &&
        (!dateTo ||
          new Date(cast.startDate).getTime() <= dateRange.dateTo.getTime())
    );

    // sort by most recent
    filteredCasts.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    );
    return filteredCasts;
  };

  const toggleAllSubsets = () => {
    const selectedStationsCopy = selectedStations.map((station) => {
      const stationCopy = station;
      stationCopy.checkboxSelected = toggleAllCastsValue;
      stationCopy.siteDeviceSubsets.map((siteDeviceSubset) => {
        const siteDeviceSubsetCopy = siteDeviceSubset;
        siteDeviceSubsetCopy.checkboxSelected = toggleAllCastsValue;
        return siteDeviceSubsetCopy;
      });
      return stationCopy;
    });
    updateSelectedStations(selectedStationsCopy);
  };

  return (
    <>
      {displayDownloadTableDialog && (
        <LassoSelectionTable
          filterCasts={filterCasts}
          onCloseClick={showCastSelectionDialog}
          childCheckBoxOnChange={childCheckBoxOnChangeCallback}
        />
      )}
      {displayCastSelectionDialog && (
        <CastSelectionDialog
          filterCasts={filterCasts}
          toggleAllCastsValue={toggleAllCastsValue}
          onContinueButtonClick={showDownloadTableDialog}
          childCheckBoxOnChange={childCheckBoxOnChangeCallback}
          parentCheckBoxOnChange={parentCheckBoxOnChangeCallback}
          clearAllSubsets={clearAllSubsets}
          toggleAllSubsets={toggleAllSubsets}
          closeCastSelection={closeCastSelection}
        />
      )}
    </>
  );
};

export default GeospatialSearchTools;
