import { createPortal } from 'react-dom';
import ContinuousVideoFiles from 'library/CompositeComponents/video/ContinuousVideoFiles';
import DateUtils from 'util/DateUtils';
import ZoomInSeekbar from './ZoomInSeekbar';

const SECONDS_AT_MAX = 100;
type UTCSeekbarProps = {
  files: ContinuousVideoFiles;
  seek: (time: number) => void;
  querySelector?: (selector: string) => Element | null;
};

const UTCSeekbar = ({
  files,
  seek,
  querySelector = undefined,
}: UTCSeekbarProps) => {
  const slider = (scale) => scale ** 3;

  const getTime = (percent) =>
    DateUtils.formatDateAsString(files.getDateAtFractionalTime(percent / 100));

  const scaler = (scale) => scale ** Math.log10(files.getDuration());

  const onRelease = (percent) => {
    seek((files.getDuration() * percent) / 100);
  };

  const maxZoom = () => (SECONDS_AT_MAX / files.getDuration()) * 100;

  if (!querySelector) return null;
  const container = querySelector('.jw-controlbar');
  if (!container) return null;

  const bar = (
    <ZoomInSeekbar
      totalTime={files.getDuration()}
      percentConversion={getTime}
      maxZoom={maxZoom}
      scaler={scaler}
      slider={slider}
      onRelease={onRelease}
    />
  );
  return createPortal(bar, container);
};

export default UTCSeekbar;
