import { Playlist } from 'domain/services/PlaylistService';

export const validateCollectionName = (
  name: string,
  collections: string[]
): string => {
  if (name === '') {
    return 'Collection name is required';
  }
  return collections.some((collection) => collection === name)
    ? `${name} already exists`
    : '';
};

export const validatePlaylistAutocomplete = (value: Array<any>): string =>
  value.length === 0 ? 'Please select at least one playlist' : '';

export const getPlaylistsIdsForCollection = (
  collection: string,
  playlists: Playlist[]
): number[] =>
  playlists
    .filter((playlist) => playlist.groupName === collection)
    .map((playlist) => playlist.playlistHdrId);
