export const VIDEO_WIDGET_TYPE_ID = 2;
export const INTERACTIVE_PLOT_WIDGET_TYPE_ID = 3;
export const DATA_PREVIEW_WIDGET_TYPE_ID = 4;
export const LATEST_READINGS_WIDGET_TYPE_ID = 5;
export const AUDIO_PLAYER_WIDGET_TYPE_ID = 6;
export const TEXT_WIDGET_TYPE_ID = 7;
export const IMAGE_WIDGET_TYPE_ID = 9;
export const DATA_PLAYER_WIDGET_TYPE_ID = 1;
export const USER_DEFINED_TASKS_WIDGET_TYPE_ID = 10;
export const CHART_WIDGET_TYPE_ID = 11;
export const DATA_SOURCE_SELECTION_WIDGET_TYPE_ID = 12;
