import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid, Typography, TypographyWithTranslation } from 'base-components';

const propTypes = {
  classes: PropTypes.shape({ root: PropTypes.string }),
};

const UnstyledPageTitle = ({ titleText, classes = undefined }) => (
  <TypographyWithTranslation
    variant="h5"
    className={classes.root}
    translationKey="common.textfields.variable"
    translationOptions={{ variableName: titleText }}
  />
);
UnstyledPageTitle.propTypes = {
  ...propTypes,
  titleText: PropTypes.string.isRequired,
};

const PageTitleStyles = (theme) => ({
  root: {
    margin: theme.spacing(),
  },
});

const PageTitle = withStyles(PageTitleStyles)(UnstyledPageTitle);

const UnstyledWidgetTitle = ({ titleText, classes = undefined }) => (
  <TypographyWithTranslation
    variant="subtitle1"
    className={classes.root}
    translationKey="common.textfields.variable"
    translationOptions={{ variableName: titleText }}
  />
);
UnstyledWidgetTitle.propTypes = {
  ...propTypes,
  titleText: PropTypes.string.isRequired,
};

const WidgetTitleStyles = {
  root: {
    position: 'absolute',
    top: 10,
  },
};

const WidgetTitle = withStyles(WidgetTitleStyles)(UnstyledWidgetTitle);

const formatId = (ID) => (isNaN(ID) ? 'N/A' : ID);
const formatIdNew = (ID) => (isNaN(ID) ? 'New' : ID);

const UnStyledPageTitleWithID = ({
  titleText,
  id = undefined,
  classes = undefined,
}) => (
  <Grid container direction="row">
    <Grid item>
      <PageTitle titleText={titleText} />
    </Grid>
    <Grid item>
      <TypographyWithTranslation
        variant="h5"
        className={classes.root}
        translationKey="dashboards.formatId"
        translationOptions={{ format: formatId(id) }}
      />
    </Grid>
  </Grid>
);
UnStyledPageTitleWithID.propTypes = {
  id: PropTypes.number,
  ...propTypes,
};

const PageTitleWithID = withStyles(PageTitleStyles)(UnStyledPageTitleWithID);
const DialogTitleWithIdsStyle = (theme) => ({
  root: {
    marginLeft: theme.spacing(),
  },
});

const UnStyledDialogTitleWith2Ids = ({
  titleText1,
  titleText2,
  id1 = undefined,
  id2 = undefined,
  classes = undefined,
}) => (
  <Grid container direction="row">
    <Typography variant="h5" className={classes.root}>
      {titleText1} Id: {formatIdNew(id1)} / {titleText2} Id: {formatIdNew(id2)}
    </Typography>
  </Grid>
);
UnStyledDialogTitleWith2Ids.propTypes = {
  id1: PropTypes.number,
  id2: PropTypes.number,
  titleText1: PropTypes.string.isRequired,
  titleText2: PropTypes.string.isRequired,
  ...propTypes,
};

const DialogTitleWith2Ids = withStyles(DialogTitleWithIdsStyle)(
  UnStyledDialogTitleWith2Ids
);

export { PageTitle, WidgetTitle, PageTitleWithID, DialogTitleWith2Ids };
