import { PureComponent } from 'react';

import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { DynamicFeedIcon, ExploreIcon, DnsOutlinedIcon } from '@onc/icons';
import { Typography } from 'base-components';

import NodePropType from 'domain/Apps/automation-testing/NodePropType';

const styles = (theme) => ({
  testIcon: {
    marginRight: theme.spacing(1),
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
});

class MultiQueueTreeItem extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    selectNode: PropTypes.func.isRequired,
    node: NodePropType.isRequired,
    selectedNode: NodePropType,
  };

  static defaultProps = {
    selectedNode: undefined,
  };

  renderNodeIcon = (node) => {
    const { classes } = this.props;
    const color = node.enabled ? 'primary' : 'disabled';
    if (node.multiQueueTypeId === 1) {
      return (
        <ExploreIcon
          className={classes.queueIcon}
          color={color}
          fontSize="small"
        />
      );
    }

    if (node.multiQueueTypeId === 2) {
      return (
        <DnsOutlinedIcon
          className={classes.queueIcon}
          color={color}
          fontSize="small"
        />
      );
    }
    // node is a queue
    return (
      <DynamicFeedIcon
        className={classes.queueIcon}
        color={color}
        fontSize="small"
      />
    );
  };

  isSelected = () => {
    const { selectedNode, node } = this.props;
    return (
      selectedNode &&
      node.multiQueueTreeNodeId === selectedNode.multiQueueTreeNodeId
    );
  };

  renderName = () => {
    const { selectedNode, node } = this.props;
    return this.isSelected()
      ? selectedNode.multiQueueTreeNodeName
      : node.multiQueueTreeNodeName;
  };

  render() {
    const { node, selectNode, classes } = this.props;
    return (
      <Box
        style={{ position: 'relative' }}
        display="flex"
        onClick={(e) => selectNode(e, node)}
        className={this.isSelected() ? classes.selected : null}
      >
        {this.renderNodeIcon(node)}
        <Typography variant="body2">{this.renderName()}</Typography>
      </Box>
    );
  }
}

export default withStyles(styles)(MultiQueueTreeItem);
