import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { TextField } from 'base-components';

const styles = (theme) => ({
  textField: {
    width: `calc(100% - ${theme.spacing(2)})`,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[800],
  },
  input: {
    color: `${theme.palette.secondary.main} !important`,
  },
});

class ResultTextArea extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    rows: PropTypes.number,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    error: PropTypes.bool,
    classes: PropTypes.shape({
      textField: PropTypes.string,
      input: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    title: 'resultTextArea',
    name: 'resultTextArea',
    rows: 25,
    value: undefined,
    error: false,
  };

  render() {
    const { title, name, rows, value, error, classes, ...rest } = this.props;

    return (
      <TextField
        disabled
        fullWidth
        multiline
        translationKey="common.textfields.results"
        title={title}
        name={name}
        rows={rows}
        value={value}
        error={error}
        className={classes.textField}
        inputProps={{ className: classes.input }}
        InputLabelProps={{
          className: classes.input,
          shrink: !!value || value === 0,
        }}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(ResultTextArea);
