import { forwardRef, useEffect, useRef } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import useBroadcast from 'util/hooks/useBroadcast';
import { SECONDS_PER_FIVE_MINUTES } from './util/DataPlayerConstants';

const styles = (theme) => ({
  image: {
    position: 'relative',
    '&:active': {
      cursor: 'grabbing',
    },
    cursor: 'grab',
  },
  imagesForDashboard: {
    clipPath: 'inset(7% 12.7% 3.1% 8.2%)',
    position: 'relative',
    '&:active': {
      cursor: 'grabbing',
    },
    cursor: 'grab',
    height: '100%',
    width: 'auto',
    transform: 'translateX(-8.89%)',
  },
  container: {
    overflow: 'hidden',
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
  },
  containerForDashboard: {
    position: 'relative',
    overflow: 'hidden',
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
    height: '100%',
  },
  imageForLatest: {
    position: 'relative',
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block',
  },
  containerForLatest: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  redLine: {
    position: 'relative',
    boxSizing: 'border-box',
    borderLeft: '.1px solid red',
  },
  root: {
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
    padding: 2 * theme.spacing(),
  },
  date: {
    borderLeft: 'solid 2px black',
    textAlign: 'left',
    paddingLeft: theme.spacing(),
    display: 'inline-block',
    fontSize: 'small',
    borderRight: 'solid 2px black',
    paddingTop: '10px',
  },
  imageFooter: {
    top: '-10px',
    position: 'relative',
  },
});

const IMAGE_WIDTH = 1200;
const IMAGE_HEIGHT = 900;

const SPECTROGRAM_WIDTH = 932;
const SPECTROGRAM_HEIGHT = 740;

const X_OFFSET = -109;
const Y_OFFSET = -69;

const HI_RES_SPECTROGRAM_WIDTH = 926;
const HI_RES_SPECTROGRAM_HEIGHT = 722;

const HI_RES_X_OFFSET = -110;
const HI_RES_Y_OFFSET = -90;

const HI_RES_IMAGE_WIDTH = 3195;
const HI_RES_IMAGE_HEIGHT = 2400;

const ADCP_PLOT_WIDTH = 2536;
const ADCP_PLOT_HEIGHT = 2015;

const ADCP_X_OFFSET = -229;
const ADCP_Y_OFFSET = -171;

const DataPlayerImage = forwardRef((props, ref) => {
  DataPlayerImage.propTypes = {
    src: PropTypes.string.isRequired,
    deviceCategoryCode: PropTypes.string.isRequired,
    isCommunicating: PropTypes.bool.isRequired,
    flacFile: PropTypes.string,
    dateSelectorValue: PropTypes.string,
    timeRange: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    alt: PropTypes.string,
    isDashboard: PropTypes.bool,
    index: PropTypes.number,
    classes: PropTypes.shape({
      container: PropTypes.string,
      image: PropTypes.string,
      containerForDashboard: PropTypes.string,
      containerForLatest: PropTypes.string,
      imagesForDashboard: PropTypes.string,
      imageForLatest: PropTypes.string,
      redLine: PropTypes.string,
    }),
    onLoad: PropTypes.func,
  };

  const {
    src,
    deviceCategoryCode,
    dateSelectorValue,
    flacFile,
    timeRange,
    height,
    classes,
    width,
    alt,
    isCommunicating,
    isDashboard,
    onLoad,
    index,
  } = props;

  const [audioTime] = useBroadcast('0', BroadcastChannel.AudioTime, 0);
  const [, broadcastFlacData] = useBroadcast(
    '0',
    BroadcastChannel.FlacData,
    undefined
  );
  const spectrogramRef = useRef(null);
  /* Retrieve the initial position of the playing line if loaded. Used to determine 
    start of spectrogram data for audio synchronization.
  */
  const playline = useRef(null);

  useEffect(() => {
    if (isCommunicating && dateSelectorValue === 'latest' && flacFile) {
      broadcastFlacData(flacFile);
    }
  }, [broadcastFlacData, isCommunicating, flacFile, dateSelectorValue]);

  const calculateXOffset = () => {
    let imageWidth;
    let offset;
    if (deviceCategoryCode === 'HYDROPHONE') {
      if (timeRange === '5') {
        imageWidth = SPECTROGRAM_WIDTH;
        offset = X_OFFSET;
      } else {
        imageWidth = HI_RES_SPECTROGRAM_WIDTH;
        offset = HI_RES_X_OFFSET;
      }
    } else {
      imageWidth = ADCP_PLOT_WIDTH;
      offset = ADCP_X_OFFSET;
    }
    return Math.floor((width / imageWidth) * offset);
  };

  const calculateYOffset = () => {
    let imageHeight;
    let offset;
    if (deviceCategoryCode === 'HYDROPHONE') {
      if (timeRange === '5') {
        imageHeight = SPECTROGRAM_HEIGHT;
        offset = Y_OFFSET;
      } else {
        imageHeight = HI_RES_SPECTROGRAM_HEIGHT;
        offset = HI_RES_Y_OFFSET;
      }
    } else {
      imageHeight = ADCP_PLOT_HEIGHT;
      offset = ADCP_Y_OFFSET;
    }
    return Math.floor((height / imageHeight) * offset);
  };

  const calculateImageWidth = () => {
    let imageWidth;
    let plotWidth;
    if (deviceCategoryCode === 'HYDROPHONE') {
      if (timeRange === '5') {
        imageWidth = IMAGE_WIDTH;
        plotWidth = SPECTROGRAM_WIDTH;
      } else {
        imageWidth = IMAGE_WIDTH;
        plotWidth = HI_RES_SPECTROGRAM_WIDTH;
      }
    } else {
      imageWidth = HI_RES_IMAGE_WIDTH;
      plotWidth = ADCP_PLOT_WIDTH;
    }
    return Math.floor((width / plotWidth) * imageWidth);
  };

  const playingLine = () => {
    if (
      isCommunicating &&
      isDashboard &&
      dateSelectorValue === 'latest' &&
      spectrogramRef.current
    ) {
      return (
        <div
          ref={playline}
          className={classes.redLine}
          role="region"
          aria-label="playline"
          style={{
            height: `${spectrogramRef.current.height * 0.85}px`,
            /* Spectrogram starts at ~8.89% and ends at ~86.83% of the whole image.
            Negatively translated in order to start the line from the left. */
            translate: `${-spectrogramRef.current.width * 0.9111}px`,
            width: spectrogramRef.current.width,
            maxHeight: spectrogramRef.current.height,
          }}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    const pixelsPerSecond = () => {
      if (spectrogramRef.current !== null) {
        const imageWidth = spectrogramRef.current.width;
        // Spectrogram takes roughly: 86.83% - 8.89% = 77.94% of the whole image
        const spectrogramDataWidth = imageWidth * 0.7794;
        return spectrogramDataWidth / SECONDS_PER_FIVE_MINUTES;
      }
      return null;
    };

    const movePlayline = () => {
      const newLeftValue = pixelsPerSecond() * audioTime;
      if (playline.current) playline.current.style.left = `${newLeftValue}px`;
    };

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentBoxSize) {
          // Adjusts line to correct time upon resizing the widget
          movePlayline();
        }
      });
    });

    if (isCommunicating && playline && spectrogramRef.current) {
      movePlayline(audioTime);
    }

    if (spectrogramRef.current && playline) {
      resizeObserver.observe(spectrogramRef.current);
      if (!isCommunicating) {
        resizeObserver.unobserve(spectrogramRef.current);
      }
    }
  }, [isCommunicating, audioTime, playline, spectrogramRef]);

  const calculateImageHeight = () => {
    let imageHeight;
    let plotHeight;
    if (deviceCategoryCode === 'HYDROPHONE') {
      if (timeRange === '5') {
        imageHeight = IMAGE_HEIGHT;
        plotHeight = SPECTROGRAM_HEIGHT;
      } else {
        imageHeight = IMAGE_HEIGHT;
        plotHeight = HI_RES_SPECTROGRAM_HEIGHT;
      }
    } else {
      imageHeight = HI_RES_IMAGE_HEIGHT;
      plotHeight = ADCP_PLOT_HEIGHT;
    }
    return Math.floor((height / plotHeight) * imageHeight);
  };

  if (isDashboard === true) {
    return (
      <div
        className={
          dateSelectorValue === 'latest'
            ? classes.containerForLatest
            : classes.containerForDashboard
        }
        id={`imageContainer-${index}`}
        ref={ref}
      >
        <img
          id={`image-${index}`}
          ref={spectrogramRef}
          src={src}
          alt={alt}
          className={`${
            dateSelectorValue === 'latest'
              ? classes.imageForLatest
              : classes.imagesForDashboard
          }`}
          draggable={false}
          onLoad={onLoad}
        />
        {playingLine()}
      </div>
    );
  }
  return (
    <div
      className={classes.container}
      style={{ height: `${height}px`, width: `${width}px` }}
      id="imageContainer"
    >
      <img
        src={src}
        alt={alt}
        className={classes.image}
        style={{
          left: `${calculateXOffset()}px`,
          top: `${calculateYOffset()}px`,
        }}
        height={calculateImageHeight()}
        width={calculateImageWidth()}
        draggable={false}
      />
    </div>
  );
});

DataPlayerImage.defaultProps = {
  alt: 'Missing',
  classes: undefined,
  dateSelectorValue: 'dateRange',
  flacFile: undefined,
  height: SPECTROGRAM_HEIGHT,
  index: null,
  isDashboard: false,
  onLoad: undefined,
  timeRange: '5',
  width: SPECTROGRAM_WIDTH,
};

export default withStyles(styles)(DataPlayerImage);
