import * as React from 'react';
import { Box, InputLabel, Toggle, Typography } from 'base-components';
import { useUpdateTaskInputMutation } from '../hooks/BatchTaskMutationHooks';
import { TaskInput } from '../types/TaskInput';

const BatchNotifications: React.FC<{
  taskInput: TaskInput;
  taskId: number;
}> = ({ taskInput, taskId }) => {
  const mutation = useUpdateTaskInputMutation();
  const onToggle = (fieldKey: string) => {
    const newInput = structuredClone(taskInput);
    if (fieldKey in taskInput) delete newInput[fieldKey];
    else newInput[fieldKey] = 'requested';

    mutation.mutate({ taskInput: newInput, taskId });
  };
  return (
    <Box display="block" flexWrap="wrap">
      <InputLabel shrink>Email</InputLabel>
      <Box display="flex" flexWrap="wrap">
        <Toggle
          tooltip="Receive an email when the batch completes"
          label={<Typography variant="body2">Batch Completed</Typography>}
          value={taskInput.notifyOnCompleted === 'requested'}
          onChange={() => onToggle('notifyOnCompleted')}
        />
        <Toggle
          tooltip="Receive an email when the batch pauses to wait for manual input"
          label={<Typography variant="body2">Input Required</Typography>}
          value={taskInput.notifyOnPause === 'requested'}
          onChange={() => onToggle('notifyOnPause')}
        />
        <Toggle
          tooltip="Receive an email when the batch stops due to error"
          label={<Typography variant="body2">Error Occurred</Typography>}
          value={taskInput.notifyOnError === 'requested'}
          onChange={() => onToggle('notifyOnError')}
        />
      </Box>
    </Box>
  );
};

export default BatchNotifications;
