import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';

import BatchDropdown from 'domain/AppComponents/dropdowns/BatchDropdown';
import DataciteEnvironmentSelect from 'domain/AppComponents/dropdowns/DataciteEnvironmentSelect';
import UserPreferenceSelect from 'domain/AppComponents/dropdowns/UserPreferenceSelect';

const AttributeSelect = (props) => {
  AttributeSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };
  const { options } = props;
  return <Dropdown label="Attribute" options={options} {...props} autoWidth />;
};

const GenericDropdown = (props) => <Dropdown {...props} />;

const ResourceTypeSelect = (props) => {
  const options = [
    { label: '(none)', value: '-1' },
    { label: 'Device', value: '2' },
    { label: 'Device Data', value: '1000' },
    { label: 'Dive', value: '600' },
  ];

  return (
    <Dropdown
      name="resource-type"
      label="Resource Type"
      options={options}
      {...props}
    />
  );
};

// eslint-disable-next-line react/require-default-props
const SearchTreeNodeResourceTypeSelect = ({ initialValue = '1000' }) => {
  const options = [
    { label: 'Device', value: '2' },
    { label: 'Device Data', value: '1000' },
  ];

  return (
    <Dropdown
      initialValue={initialValue}
      label="Resource Type"
      options={options}
      {...props}
    />
  );
};
SearchTreeNodeResourceTypeSelect.propTypes = {
  initialValue: PropTypes.string,
};

const DeprecateDOIReasonSelect = (props) => {
  const options = [
    {
      name: 'Created in Error',
      label: 'Created in Error',
      value: 'This dataset was created in error and is no longer valid.',
    },
    {
      name: 'Duplicate',
      label: 'Duplicate',
      value: 'This dataset is a duplicate of another dataset.',
    },
    { name: 'Other', label: 'Other', value: 'other' },
  ];

  return <Dropdown label="Reason" options={options} {...props} />;
};

const AttributeValueSelect = (props) => (
  <Dropdown label="Attribute Value" {...props} />
);

const PermissionSelect = (props) => {
  const options = [
    { label: 'Can View', value: 'RO' },
    { label: 'Can Edit', value: 'RW' },
  ];
  return <Dropdown label="Permission" options={options} {...props} />;
};

const TaskDefinitionSelect = (props) => {
  const { options } = props;
  return (
    <Dropdown label="Task Definition" options={options} {...props} fullwidth />
  );
};

TaskDefinitionSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const TreeTypeSelect = (props) => {
  TreeTypeSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  const { value, onChange } = props;
  return (
    <Dropdown
      label="Display"
      fullWidth
      value={value}
      options={[
        {
          value: 'instrumentsByLocation',
          label: 'Instruments by Location',
        },
        {
          value: 'propertiesByLocation',
          label: 'Properties by Location (NOT IMPLEMENTED)',
        },
      ]}
      onChange={onChange}
    />
  );
};

const DatePresetSelect = ({
  value,
  onChange,
  options = [
    { value: 'day', label: 'Last 24 hours' },
    { value: 'week', label: 'Last Week' },
    { value: 'month', label: 'Last Month' },
    { value: 'year', label: 'Last Year' },
  ],
}) => {
  DatePresetSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.shape({}),
  };

  return (
    <Dropdown
      label="Date Preset"
      fullWidth
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

const TimePeriodSelect = ({
  value,
  onChange,
  options = [
    { value: 'all', label: 'All' },
    { value: 'day', label: 'Last 24 hours' },
    { value: 'week', label: 'Last Week' },
    { value: 'month', label: 'Last Month' },
    { value: 'year', label: 'Last Year' },
    { value: 'custom', label: 'Custom' },
  ],
}) => {
  TimePeriodSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.shape({}),
  };

  return (
    <Dropdown
      label="Time Period"
      fullWidth
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

const ResolutionSelect = (props) => (
  <Dropdown label="Resolution" fullWidth {...props} />
);

const UserDefinedTasksRunSelect = (props) => {
  const { options } = props;
  return (
    <Dropdown
      label="User Defined Tasks Runs"
      name="userDefinedTasksRuns"
      options={options}
      {...props}
      fullwidth
    />
  );
};

UserDefinedTasksRunSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const FileTypeSelect = (props) => {
  FileTypeSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
  };
  const { onChange } = props;
  return (
    <Dropdown
      label="Upload Type"
      fullWidth
      onChange={onChange}
      options={[
        {
          value: 'SelectType',
          label: '-- select file type --',
        },
        {
          value: 'Annotation',
          label: 'Annotation Upload (.an)',
        },
        {
          value: 'Translation',
          label: 'Translation Upload (.csv)',
        },
      ]}
      {...props}
    />
  );
};

const QuickButtonTypeSelect = (props) => {
  QuickButtonTypeSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
  };
  const { onChange } = props;
  return (
    <Dropdown
      label="Button Type"
      fullWidth
      onChange={onChange}
      options={[
        {
          value: 'Attribute',
          label: 'Attribute',
        },
        {
          value: 'Taxonomy',
          label: 'Taxonomy',
        },
      ]}
      {...props}
    />
  );
};

const TaxonomySelect = (props) => {
  TaxonomySelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };
  const { options } = props;
  return <Dropdown label="Taxonomy" options={options} {...props} autoWidth />;
};

const GroupSelect = (props) => {
  GroupSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };
  const { options } = props;
  return <Dropdown label="Group" options={options} {...props} autoWidth />;
};

const DataTypeSelect = (props) => {
  DataTypeSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };
  const { options } = props;
  return <Dropdown label="Data Type" options={options} {...props} autoWidth />;
};

const SecurityTierDropdown = (props) => {
  SecurityTierDropdown.propTypes = {
    onChange: PropTypes.func.isRequired,
  };
  const { onChange } = props;
  return (
    <Dropdown
      label="Security Tier"
      fullWidth
      onChange={onChange}
      options={[
        { value: -1, label: 'All' },
        {
          value: 3,
          label: 'Low',
        },
        {
          value: 2,
          label: 'Medium',
        },
        {
          value: 1,
          label: 'High',
        },
      ]}
      {...props}
    />
  );
};

const BatchTypeSelect = (props) => {
  const { options } = props;
  const { onChange } = props;
  return (
    <Dropdown
      label="Batch Type"
      options={options}
      onChange={onChange}
      {...props}
      fullwidth
    />
  );
};

BatchTypeSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};

export {
  AttributeSelect,
  AttributeValueSelect,
  ResourceTypeSelect,
  GenericDropdown,
  SearchTreeNodeResourceTypeSelect,
  UserPreferenceSelect,
  PermissionSelect,
  BatchDropdown,
  DataciteEnvironmentSelect,
  TaskDefinitionSelect,
  DeprecateDOIReasonSelect,
  TreeTypeSelect,
  DatePresetSelect,
  TimePeriodSelect,
  UserDefinedTasksRunSelect,
  ResolutionSelect,
  QuickButtonTypeSelect,
  TaxonomySelect,
  FileTypeSelect,
  GroupSelect,
  DataTypeSelect,
  SecurityTierDropdown,
  BatchTypeSelect,
};
