import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ListItemIcon } from 'base-components';

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    display: 'flex',
  },
  logo: {
    paddingRight: theme.spacing(2),
    display: 'block',
  },
});

class InfoItemWithIcon extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    classes: PropTypes.shape({
      logo: PropTypes.string,
      root: PropTypes.string,
    }),
  };

  static defaultProps = {
    classes: {},
    children: undefined,
  };

  render() {
    const { classes, children } = this.props;
    const [icon, ...otherChildren] = Array.isArray(children)
      ? children
      : [children];
    return (
      <div className={classes.root}>
        <ListItemIcon className={classes.logo}>{icon}</ListItemIcon>
        {otherChildren}
      </div>
    );
  }
}

export default withStyles(styles)(InfoItemWithIcon);
