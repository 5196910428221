import { FC } from 'react';
import CFMarkerClusterGroup from 'domain/AppComponents/geospatial-search/community-fisher/markergroup/CFMarkerClusterGroup';
import {
  DateRange,
  SelectedStation,
} from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchTypes';

interface AssignedCFOverlayProps {
  dateRange: DateRange;
  assignedCastData?: SelectedStation[];
  hideCFPolygons?: boolean;
  recolourIcons: () => void;
  downloadAllUnrestrictedCastsFromStation: (stationCode: string) => void;
}

const AssignedCFOverlay: FC<AssignedCFOverlayProps> = ({
  dateRange,
  assignedCastData = [],
  hideCFPolygons = false,
  recolourIcons,
  downloadAllUnrestrictedCastsFromStation,
}) => {
  const filterCasts = (casts) => {
    const { dateTo, dateFrom } = dateRange;
    const filteredCasts = casts.filter(
      (cast) =>
        (!dateFrom ||
          new Date(cast.startDate).getTime() >= dateRange.dateFrom.getTime()) &&
        (!dateTo ||
          new Date(cast.startDate).getTime() <= dateRange.dateTo.getTime())
    );

    // sort by most recent
    filteredCasts.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    );
    return filteredCasts;
  };

  const activeAssignedCastData = assignedCastData?.filter(
    (station) => station.dateTo === undefined || station.dateTo > new Date()
  );

  return (
    <CFMarkerClusterGroup
      assignedCastData={activeAssignedCastData}
      filterCasts={filterCasts}
      downloadAllUnrestrictedCastsFromStation={
        downloadAllUnrestrictedCastsFromStation
      }
      recolourIcons={recolourIcons}
      hideCFPolygons={hideCFPolygons}
      isDecommissioned={false}
    />
  );
};

export default AssignedCFOverlay;
