import { useEffect } from 'react';
import moment, { Moment } from 'moment';
import { Theaters } from '@onc/icons';
import {
  Divider,
  ListItemText,
  Typography,
  ListItemButton,
  ListItemIcon,
  VirtualList,
} from 'base-components';
import AutoPlaySwitch from 'domain/AppComponents/sea-tube/playlist-playback/AutoPlaySwitch';
import {
  useArchiveFileByLocation,
  ArchiveFile,
} from 'domain/services/ArchiveFileService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import DateUtils from 'util/DateUtils';
import { useLocalStorage } from 'util/hooks/useStorage';

type FixedCameraClipListConfig = {
  date: Moment;
  currentClip: string;
  searchTreeNodeCode: string;
  autoPlay: boolean;
};

const FixedCameraClipListWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;
  const [, saveAutoPlayToStorage] = useLocalStorage<boolean>(
    'fixed-camera-playback-auto-play',
    true
  );
  const {
    dashboardState: config,
    setDashboardStateProperty: setConfigProperty,
  } = useDashboardState<FixedCameraClipListConfig>();

  const { autoPlay } = config;

  const AutoPlayToggleButton = (
    <AutoPlaySwitch
      checked={!!autoPlay}
      onChange={() => {
        setConfigProperty('autoPlay', !autoPlay);
        saveAutoPlayToStorage(!autoPlay);
      }}
    />
  );

  const {
    data: archiveFiles,
    refetch: fetchArchiveFiles,
    isLoading,
  } = useArchiveFileByLocation({
    method: 'getListByLocation',
    locationCode: config.searchTreeNodeCode,
    returnOptions: 'all',
    deviceCategoryCode: 'VIDEOCAM',
    dateFrom: config.date?.utc().toISOString(),
    dateTo: config.date?.clone().add(1, 'day').utc().toISOString(),
    dataProductCode: 'MP4V',
  });

  useEffect(() => {
    if (config.date?.utc().isAfter(moment('2007-01-01').startOf('day'))) {
      fetchArchiveFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.date]);

  const renderClipText = (clipData) => (
    <ListItemText
      primary={clipData.dateFrom}
      secondary={
        <Typography component="span" variant="body2" color="action">
          {`${DateUtils.getDurationBetweenDates(
            clipData.dateFrom,
            clipData.dateTo
          )}`}
        </Typography>
      }
    />
  );

  const handleChangeClip = (clipData: ArchiveFile) => {
    setConfigProperty('currentClip', clipData.filename);
  };

  const renderClipItem = (clipData) => (
    <>
      <ListItemButton
        dense
        title={clipData.dateFrom}
        onClick={() => handleChangeClip(clipData)}
        selected={config.currentClip === clipData.filename}
        sx={{
          '&.Mui-selected': {
            backgroundColor: (theme) => theme.palette.highlight.main,
          },
          '&.Mui-selected:hover': {
            backgroundColor: (theme) => theme.palette.secondary.light,
          },
        }}
      >
        <ListItemIcon>
          <Theaters />
        </ListItemIcon>

        {renderClipText(clipData)}
      </ListItemButton>
      <Divider />
    </>
  );

  return (
    <Widget
      id={id}
      title="Clip List"
      loading={isLoading}
      actionComponents={[AutoPlayToggleButton]}
      {...props}
    >
      <VirtualList>
        {archiveFiles?.files.map((archiveFile) => renderClipItem(archiveFile))}
      </VirtualList>
    </Widget>
  );
};

FixedCameraClipListWidget.widgetKey = 'fixed-camera-clip-list-widget';
FixedCameraClipListWidget.widgetTitle = 'Clip List';
FixedCameraClipListWidget.defaultDataGrid = {
  i: 'fixed-camera-clip-list-widget',
  x: 9,
  y: 0,
  w: 3,
  h: 9,
};

export default FixedCameraClipListWidget;
