export interface Option {
  child?: {
    description: string;
    displayValue: string;
    formFieldId: number;
    label: string;
    value: string;
  };
  description: string;
  displayValue: string;
  formFieldId: number;
  label: string;
  value: string;
}

export const SEARCH_DETAIL_COLUMNS = [
  { name: 'queryPidLink', title: 'Query PID' },
  { name: 'deviceNameLink', title: 'Device' },
  { name: 'dateFrom', title: 'Date From' },
  { name: 'dateTo', title: 'Date To' },
  { name: 'siteIdLink', title: 'Site ID' },
  { name: 'doiDatasetLink', title: 'Dataset DOI' },
  { name: 'copyCitation', title: 'Copy Citation' },
];
export const COLUMNS = [
  { name: 'path', title: 'Path' },
  { name: 'searchId', title: 'Search ID' },
  { name: 'dataProduct', title: 'Data Product' },
  { name: 'dateFrom', title: 'Date From' },
  { name: 'dateTo', title: 'Date To' },
  { name: 'optionsTable', title: 'Options' },
  { name: 'dateCreated', title: 'Query Date' },
  { name: 'searchSource', title: 'Search Source' },
];
