import moment from 'moment';

export type SeamlessVideoFile = {
  url: string;
  duration: number;
  offset: number;
  deviceId: number;
  fileName: string;
  path: string;
};

export type VideoInterval = {
  startDate: string;
  endDate: string;
};

export const findSeekPosition = (
  files: SeamlessVideoFile[],
  seekTarget: number
): { fileIndex: number; localSeekTo: number; seekError?: string } => {
  let fileIndex = 0;
  let localSeekTo = seekTarget;
  let seekError;

  // Check if seek target is beyond the last file
  const lastFile = files[files.length - 1];
  if (seekTarget >= lastFile.offset + lastFile.duration) {
    fileIndex = files.length - 1;
    localSeekTo = lastFile.duration;
    seekError = 'Requested time is beyond the last video file.';
    return { fileIndex, localSeekTo, seekError };
  }

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    const nextAccumulatedDuration = file.offset + file.duration;

    if (seekTarget < nextAccumulatedDuration) {
      if (seekTarget >= file.offset) {
        fileIndex = i;
        localSeekTo = seekTarget - file.offset;
        break;
      } else {
        seekError =
          'No video found for this time. Skipping to next available video.';
        fileIndex = i;
        localSeekTo = 0;
        break;
      }
    }
  }

  return { fileIndex, localSeekTo, seekError };
};

/** Find intervals between video files */
export const findIntervals = (
  dataFiles: SeamlessVideoFile[],
  dateStartSeconds: number
): VideoInterval[] => {
  const intervals: VideoInterval[] = [];

  if (dataFiles.length === 0) return intervals;

  // Initialize the first interval
  let currentStart = dateStartSeconds + dataFiles[0].offset;
  let currentEnd = currentStart + dataFiles[0].duration;

  // Iterate over the files to find and merge intervals
  for (let i = 1; i < dataFiles.length; i += 1) {
    const currentFileStart = dateStartSeconds + dataFiles[i].offset;
    const currentFileEnd = currentFileStart + dataFiles[i].duration;

    if (currentFileStart <= currentEnd + 1) {
      // Merge intervals
      currentEnd = Math.max(currentEnd, currentFileEnd);
    } else {
      // Push the current interval and start a new one
      intervals.push({
        startDate: moment.utc(currentStart * 1000).toISOString(),
        endDate: moment.utc(currentEnd * 1000).toISOString(),
      });
      currentStart = currentFileStart;
      currentEnd = currentFileEnd;
    }
  }

  // Push the last interval
  intervals.push({
    startDate: moment.utc(currentStart * 1000).toISOString(),
    endDate: moment.utc(currentEnd * 1000).toISOString(),
  });

  return intervals;
};
