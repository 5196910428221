import { useForm } from 'react-hook-form';
import { Typography } from 'base-components';
import {
  useDeleteTaskMutation,
  useDetachTaskMutation,
} from 'domain/AppComponents/batch/hooks/BatchTaskMutationHooks';
import TaskStatus from 'domain/AppComponents/batch/util/TaskStatus';
import FormDialog from 'library/CompositeComponents/dialog/FormDialog_new';
import FormCheckbox from 'library/CompositeComponents/form/FormCheckbox';

export type BatchDeleteData = { status?: number; taskId?: number };

type BatchDeleteDialogFields = { permanentDeleteCheckbox: boolean };

const BatchDeleteDialog = ({
  open,
  onClose,
  data = { status: 0, taskId: 0 },
}: {
  open: boolean;
  onClose: () => void;
  data?: BatchDeleteData;
}) => {
  const { status = 0, taskId = 0 } = data;

  const deleteTaskMutation = useDeleteTaskMutation();
  const detachTaskMutation = useDetachTaskMutation();

  const formMethods = useForm<BatchDeleteDialogFields>({
    mode: 'onBlur',
    defaultValues: { permanentDeleteCheckbox: false },
  });
  const { handleSubmit } = formMethods;
  const onSubmit = (values: BatchDeleteDialogFields) => {
    values.permanentDeleteCheckbox
      ? deleteTaskMutation.mutate(taskId)
      : detachTaskMutation.mutate(taskId);
    onClose();
  };

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      formMethods={formMethods}
      title="Remove Task"
      onSubmit={handleSubmit(onSubmit)}
      confirmButtonTranslationKey="taskmanagement.removeTask"
    >
      <>
        <Typography>Are you sure you want to remove task {taskId}?</Typography>
        {status === TaskStatus.OPEN.value && (
          <FormCheckbox
            name="permanentDeleteCheckbox"
            label="Permanently Delete Task"
          />
        )}
      </>
    </FormDialog>
  );
};

export default BatchDeleteDialog;
