/* eslint-disable no-cond-assign */
import { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from 'base-components';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import { ChatLogMessage } from 'domain/services/SeaTubeChatLogService';
import {
  getMatchRanges,
  getURLRanges,
  getMentionRanges,
  processSegmentsWithRanges,
  Segment,
  Range,
  getUserStyle,
} from './ChatLogUtil';
import { MessageMatch } from './VirtualChatLogList';

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  background: selected
    ? `${theme.palette.highlight.main} !important`
    : 'inherit',
}));

const UsernameTypography = styled(Typography)(({ theme, color }) => ({
  display: 'block',
  fontWeight: 'bold',
  color: color || theme.palette.text.primary,
}));

const TimestampTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  marginRight: theme.spacing(2),
}));

type ChatLogListItemProps = {
  chatUserMap: { username: string; color: string }[];
  coloredUsernames: boolean;
  deletePermissions: boolean;
  displayDeleteIcon: boolean;
  displayFullTimestamp: boolean;
  listIndex: number;
  message: ChatLogMessage;
  selected: boolean;
  matches: MessageMatch[];
  onMessageClick: (message: ChatLogMessage, index: number) => void;
  onChatDelete: (messages: ChatLogMessage[]) => void;
};

const ChatLogListItem: React.FC<ChatLogListItemProps> = ({
  chatUserMap,
  coloredUsernames,
  deletePermissions,
  displayDeleteIcon,
  displayFullTimestamp,
  listIndex,
  message,
  selected,
  matches,
  onMessageClick,
  onChatDelete,
}: ChatLogListItemProps) => {
  const renderSegment = (segment: Segment): React.ReactNode => {
    const { styles } = segment;
    let element: React.ReactNode = segment.text;

    const styleProps: React.CSSProperties = {};

    if (styles.color) {
      styleProps.color = styles.color;
    }

    if (styles.highlight) {
      styleProps.backgroundColor = styles.currentMatch ? 'orange' : 'yellow'; // Or use a theme color
    }

    if (Object.keys(styleProps).length > 0) {
      element = <span style={styleProps}>{element}</span>;
    }

    if (styles.bold) {
      element = <b>{element}</b>;
    }

    if (styles.link) {
      element = (
        <Link href={styles.link} target="_blank">
          {element}
        </Link>
      );
    }

    return element;
  };

  const generateFormattedText = (text: string): React.ReactNode => {
    const relevantMatches = matches.filter(
      (match) => match.messageIndex === listIndex && !match.isUserName
    );

    const ranges = [
      ...getMatchRanges(relevantMatches),
      ...getURLRanges(text),
      ...getMentionRanges(text, chatUserMap, coloredUsernames),
    ];

    ranges.sort((a, b) => a.start - b.start);

    const initialSegment: Segment = {
      text,
      start: 0,
      end: text.length,
      styles: {},
    };
    const segments = processSegmentsWithRanges([initialSegment], ranges);

    return segments.map((segment, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>{renderSegment(segment)}</Fragment>
    ));
  };

  const generateFormattedUsername = (
    username: string,
    usernameMatches: MessageMatch[]
  ): React.ReactNode => {
    // If there are no matches, return the username styled
    if (!usernameMatches || usernameMatches.length === 0) {
      return (
        <span
          style={{
            ...getUserStyle(username, chatUserMap, coloredUsernames),
            fontWeight: 'bold',
          }}
        >
          {username}
        </span>
      );
    }

    // Build ranges for the matches
    const ranges: Range[] = usernameMatches.map((match) => ({
      start: match.matchStart,
      end: match.matchEnd,
      styles: {
        highlight: true,
        currentMatch: match.isCurrentMatch,
      },
    }));

    ranges.sort((a, b) => a.start - b.start);

    const initialSegment: Segment = {
      text: username,
      start: 0,
      end: username.length,
      styles: {
        color: getUserStyle(username, chatUserMap, coloredUsernames).color,
        bold: true,
      },
    };

    const segments = processSegmentsWithRanges([initialSegment], ranges);

    return segments.map((segment) => <>{renderSegment(segment)}</>);
  };

  const renderDisplayText = () => {
    const username = message.username ? message.username : '(System Message)';
    const usernameMatches = matches.filter(
      (match) => match.messageIndex === listIndex && match.isUserName
    );

    return (
      <>
        <UsernameTypography variant="body2">
          {generateFormattedUsername(username, usernameMatches)}
        </UsernameTypography>
        <Typography variant="body2">
          {generateFormattedText(message.msg)}
        </Typography>
      </>
    );
  };

  const renderChatLogActions = () =>
    deletePermissions && displayDeleteIcon ? (
      <DeleteIconButton onClick={() => onChatDelete([message])} />
    ) : null;

  const timestamp = moment
    .utc(message.msgDate)
    .format(displayFullTimestamp ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm');

  return (
    <StyledListItem
      divider
      button
      alignItems="flex-start"
      selected={selected}
      onClick={() => onMessageClick(message, listIndex)}
      secondaryAction={renderChatLogActions()}
      role="listitem"
    >
      <ListItemIcon>
        <TimestampTypography variant="body2">{timestamp}</TimestampTypography>
      </ListItemIcon>
      <ListItemText primary={renderDisplayText()} />
    </StyledListItem>
  );
};

export default ChatLogListItem;
