import { ServiceAnnotationV3 } from 'domain/services/AnnotationService';
import { DisplayAnnotation } from './LiveExpeditionAnnotations';

class LiveExpeditionEmbedUtil {
  // Convert ServiceAnnotation to DisplayAnnotation
  static convertAnnotations = (
    annotations: ServiceAnnotationV3[]
  ): DisplayAnnotation[] => {
    if (!annotations) return [];
    const displayAnnotations: DisplayAnnotation[] = [];
    annotations.forEach((annotation) => {
      const attributes = annotation.taxons?.[0]?.attributes;
      const displayAnnotation: DisplayAnnotation = {
        id: annotation.annotationId,
        timestamp: new Date(Date.parse(annotation.startDate)),
        taxon: annotation.taxons?.[0]?.displayText,
        taxonomy: annotation.taxons?.[0]?.taxonomyCode,
        attributes: attributes
          ? attributes.map((attr) => ({
              attributeName: attr.name,
              attributeValue: attr.value,
            }))
          : [],
        comment: annotation.comment,
      };
      displayAnnotations.push(displayAnnotation);
    });
    return displayAnnotations.sort(
      (a, b) => b.timestamp.getTime() - a.timestamp.getTime()
    );
  };

  static getTimeRemaining(timestamp: Date): string {
    const currentTime = Date.now();
    const timeDifference = timestamp.getTime() - currentTime;

    if (timeDifference <= 0) {
      return 'a few moments';
    }

    const seconds = Math.floor((timeDifference / 1000) % 60);
    const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
    const hours = Math.floor((timeDifference / 1000 / 60 / 60) % 24);
    const days = Math.floor(timeDifference / 1000 / 60 / 60 / 24);

    const timeParts = [];
    if (days > 0) {
      timeParts.push(`${days} day${days === 1 ? '' : 's'}`);
    }
    if (hours > 0 || days > 0) {
      timeParts.push(`${hours} hour${hours === 1 ? '' : 's'}`);
    }
    if (minutes > 0 || hours > 0 || days > 0) {
      timeParts.push(`${minutes} minute${minutes === 1 ? '' : 's'}`);
    }
    timeParts.push(`${seconds} second${seconds === 1 ? '' : 's'}`);

    return timeParts.join(', ');
  }
}

export default LiveExpeditionEmbedUtil;
