import { useContext, useEffect } from 'react';
import { OpenInNew } from '@onc/icons';
import { type QuickEntryButton } from 'base-components';
import { AnnotationFormData } from 'domain/AppComponents/manual-entry/ManualEntryLogic';
import TableAnnotation from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import ManualEntryForm from 'domain/AppComponents/sea-tube/manual-entry/ManualEntryForm';
import SeaTubeLogContext from 'domain/AppComponents/sea-tube/SeaTubeLogContext';
import Environment from 'util/Environment';
import { useLocalStorage, useSessionStorage } from 'util/hooks/useStorage';
import BroadcastChannel from './BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget, {
  WidgetMenuItem,
  WidgetMenuItemCheckbox,
} from '../../library/CompositeComponents/dashboard/Widget';
import useBroadcast from '../../util/hooks/useBroadcast';

const ManualEntryWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId } = props;

  const { dive, cruise, logType } = useContext(SeaTubeLogContext);

  const logId = dive ? dive.diveId : cruise.cruiseId;

  const TaxonomyManagementMenuItem = WidgetMenuItem(
    'taxonomy-management-link',
    () => {
      window.open(`${Environment.getDmasUrl()}/Taxonomy`);
    },
    'Taxonomy Management',
    OpenInNew,
    false
  );

  const [showSidebar, setShowSidebar] = useLocalStorage<boolean>(
    'seatube-show-annotation-sidebar',
    false
  );

  const SidebarCheckboxItem = (
    <WidgetMenuItemCheckbox
      key="sidebar-checkbox"
      onClick={() => {
        setShowSidebar(!showSidebar);
      }}
      label="Show Drafts"
      checked={showSidebar}
    />
  );

  const [quickButton] = useBroadcast<QuickEntryButton>(
    dashboardId,
    BroadcastChannel.QuickButton,
    'None',
    id
  );
  const [savedEditAnnotation, storeEditAnnotation] =
    useSessionStorage<TableAnnotation>(
      `${logType}-${logId}-edit-annotation`,
      null
    );

  const [editAnnotation, setEditAnnotation] = useBroadcast<TableAnnotation>(
    dashboardId,
    BroadcastChannel.Annotation,
    savedEditAnnotation,
    id
  );
  const [, setTriggerRefresh] = useBroadcast(
    dashboardId,
    BroadcastChannel.RefreshAnnotations,
    false,
    id
  );

  const [currentTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.CurrentTimestamp,
    null,
    id
  );

  const [, setLastEditedAnnotationId] = useBroadcast<number>(
    dashboardId,
    BroadcastChannel.LastEditedAnnotationId,
    undefined,
    id
  );

  useEffect(() => {
    storeEditAnnotation(editAnnotation || null);
  }, [editAnnotation, storeEditAnnotation]);

  return (
    <Widget
      key={id}
      title="Annotation Entry"
      MenuItems={[SidebarCheckboxItem, TaxonomyManagementMenuItem]}
      ariaLabel="Annotation Entry"
      {...props}
    >
      <ManualEntryForm
        quickButton={quickButton}
        editAnnotation={editAnnotation}
        onSubmitSuccess={(annotation: AnnotationFormData) => {
          setLastEditedAnnotationId(annotation.annotationId);
          setTriggerRefresh(true);
        }}
        onClearEditedAnnotation={() => setEditAnnotation(null)}
        currentTimestamp={currentTimestamp}
        showSidebar={showSidebar}
      />
    </Widget>
  );
};

ManualEntryWidget.widgetKey = 'manual-entry';
ManualEntryWidget.widgetTitle = 'Annotation Entry';
ManualEntryWidget.defaultDataGrid = {
  i: 'manual-entry',
  x: 0,
  y: 0,
  w: 4,
  h: 18,
};

export default ManualEntryWidget;
