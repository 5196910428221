const PropertyMap = [
  { value: 'Audio', dc: [19], sc: [] },
  { value: 'DataPlayer', dc: [19, 68, 69, 70, 71, 73, 84, 102], sc: [] },
  { value: 'Backscatter', dc: [16, 33, 34, 74], sc: [] },
  { value: 'Chlorophyll', dc: [], sc: [14] },
  { value: 'CO2', dc: [], sc: [37] },
  { value: 'Conductivity', dc: [], sc: [9] },
  { value: 'Currents - Single Point', dc: [], sc: [32, 50, 51, 52, 78] },
  {
    value: 'Currents - Water Column',
    dc: [2, 68, 69, 70, 71, 72, 73, 84],
    sc: [],
  },
  { value: 'Density', dc: [], sc: [24] },
  { value: 'Eh', dc: [], sc: [76] },
  { value: 'Gravity', dc: [], sc: [59] },
  { value: 'Iron', dc: [], sc: [94] },
  { value: 'Irradiance - Radiometer', dc: [29], sc: [] },
  { value: 'Irradiance - Biospherical', dc: [], sc: [45] },
  { value: 'Methane', dc: [], sc: [42] },
  { value: 'Nitrate', dc: [], sc: [27] },
  { value: 'Oxygen', dc: [], sc: [4, 41] },
  { value: 'Pressure', dc: [], sc: [10, 57] },
  { value: 'Salinity', dc: [], sc: [11] },
  { value: 'Seismic', dc: [66, 67], sc: [] },
  { value: 'Sigma-t', dc: [], sc: [28] },
  { value: 'Sound Velocity', dc: [], sc: [12] },
  { value: 'Temperature', dc: [], sc: [8] },
  { value: 'Turbidity', dc: [], sc: [13, 56] },
  { value: 'Video', dc: [107, 14, 53], sc: [] },
];

export default PropertyMap;
