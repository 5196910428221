import { PureComponent } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { PlayArrow, Refresh } from '@onc/icons';
import { IconButton, Tooltip, Typography } from 'base-components';
import Panel from 'library/CompositeComponents/panel/Panel';
import LinearProgressWithPercentage from 'library/CompositeComponents/progress/LinearProgressWithPercentage';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import GroupableTable from 'library/CompositeComponents/table/GroupableTable';
import BroadSearchGroupingGenerator from './BroadSearchGroupingGenerator';
import SearchResultsActionTabs from './SearchResultsActionTabs';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(),
  },
  table: {
    height: '80%',
    overflow: 'auto',
  },
  playButton: {
    padding: '0px',
  },
});

class SeaTubeBroadSearchResultsTable extends PureComponent {
  static propTypes = {
    playlists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    onPlaylistAdd: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string,
      table: PropTypes.string,
      playButton: PropTypes.string,
    }).isRequired,
    searchResults: PropTypes.arrayOf(PropTypes.shape({})),
    generator: PropTypes.string,
    newSearchResults: PropTypes.bool,
    onReload: PropTypes.func,
    loading: PropTypes.bool,
    searchHdrId: PropTypes.number,
    percentComplete: PropTypes.number,
  };

  static defaultProps = {
    searchResults: [],
    generator: '',
    newSearchResults: false,
    onReload: undefined,
    loading: false,
    searchHdrId: undefined,
    percentComplete: 0,
  };

  buildGroupRowContent = ({ column, row }) => {
    if (column.title === 'Taxon') {
      const taxonPath = row.value[0];
      const taxon = taxonPath[taxonPath.length - 1];
      const taxonPathString = taxonPath.join(' / ');

      return (
        <Tooltip
          id={`${taxon}-tooltip`}
          title={taxonPathString}
          placement="right"
        >
          <span>
            <strong>{`${column.title}: `}</strong>
            {taxon}
          </span>
        </Tooltip>
      );
    }

    return (
      <span>
        <strong>{`${column.title}: `}</strong>
        {row.value}
      </span>
    );
  };

  buildTaxonCellContent = ({ value }) => {
    if (!value) return <div>(none)</div>;

    const taxonPath = value[0];

    return (
      <Tooltip title={taxonPath.join(' / ')}>
        <span>{taxonPath[taxonPath.length - 1]}</span>
      </Tooltip>
    );
  };

  state = {
    grouping: undefined,
    currentRows: [],
  };

  componentDidMount() {
    this.determineDefaultGrouping();
    this.buildTableContent();
  }

  determineDefaultGrouping = () => {
    const { generator } = this.props;
    const grouping = BroadSearchGroupingGenerator.getDefaultGrouping(generator);

    this.setState({ grouping });
  };

  buildTableContent = () => {
    const { searchResults, classes, onReload } = this.props;
    const currentRows = searchResults.map((item) => {
      const watchButton = (
        <IconButton
          className={classes.playButton}
          title="Play"
          target="_blank"
          href={`/SeaTubeV3?resourceTypeId=${item.videoResourceTypeId}&resourceId=${item.videoResourceId}&time=${item.startDate}&annotationId=${item.annotationId}`}
        >
          <PlayArrow />
        </IconButton>
      );

      return { ...item, watchButton };
    });

    if (onReload) onReload();

    this.setState({ currentRows });
  };

  handleGroupingChange = (grouping) => {
    this.setState({ grouping });
  };

  renderActionContent = () => {
    const { newSearchResults, percentComplete } = this.props;

    const refreshButton = newSearchResults ? (
      <IconButton
        onClick={this.buildTableContent}
        aria-label="Refresh"
        Icon={Refresh}
      />
    ) : undefined;

    return (
      <>
        <LinearProgressWithPercentage
          width={75}
          pr={2} // padding-right
          value={percentComplete}
        />
        {refreshButton}
      </>
    );
  };

  render() {
    const {
      classes,
      searchResults,
      loading,
      playlists,
      onError,
      onInfo,
      onPlaylistAdd,
      isLoggedIn,
      searchHdrId,
      generator,
    } = this.props;
    const { currentRows, grouping } = this.state;

    const titleContent = (
      <Typography variant="subtitle1">Search Results</Typography>
    );

    const disabledGroupings = [
      { columnName: 'watchButton', groupingEnabled: false },
      { columnName: 'startDate', groupingEnabled: false },
      { columnName: 'comment', groupingEnabled: false },
    ];

    if (generator !== 'GenerateByTaxons') {
      disabledGroupings.push({
        columnName: 'taxonPaths',
        groupingEnabled: false,
      });
    }

    return (
      <div className={classes.root}>
        <Panel
          className={classes.table}
          title={titleContent}
          actionContent={this.renderActionContent()}
        >
          <GroupableTable
            columns={[
              { name: 'watchButton', title: ' ' },
              { name: 'startDate', title: 'Date' },
              { name: 'comment', title: 'Comment' },
              { name: 'taxonPaths', title: 'Taxon' },
              { name: 'videoResourceTypeName', title: 'Catalog Type' },
              { name: 'videoResourceName', title: 'Catalog Name' },
            ]}
            rows={currentRows}
            columnSizes={[
              { columnName: 'watchButton', width: ColumnInfo.mini },
              { columnName: 'startDate', width: ColumnInfo.medium },
              { columnName: 'comment', width: ColumnInfo.small },
              { columnName: 'taxonPaths', width: ColumnInfo.medium },
              { columnName: 'videoResourceTypeName', width: ColumnInfo.small },
              { columnName: 'videoResourceName', width: ColumnInfo.small },
            ]}
            GroupingStateProps={{
              grouping,
              onGroupingChange: this.handleGroupingChange,
              columnExtensions: disabledGroupings,
            }}
            TableGroupRowProps={{
              contentComponent:
                searchResults.length !== 0
                  ? this.buildGroupRowContent
                  : undefined,
            }}
            pageSize={8}
          >
            <DataTypeProvider
              formatterComponent={this.buildTaxonCellContent}
              for={['taxonPaths']}
            />
          </GroupableTable>
          {loading ? undefined : (
            <SearchResultsActionTabs
              playlists={playlists}
              onError={onError}
              onInfo={onInfo}
              onPlaylistAdd={onPlaylistAdd}
              isLoggedIn={isLoggedIn}
              searchResults={searchResults}
              broadSearchContext
              searchHdrId={searchHdrId}
            />
          )}
        </Panel>
      </div>
    );
  }
}

export default withStyles(styles)(SeaTubeBroadSearchResultsTable);
