import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { AppBar, Toolbar } from 'base-components';

const styles = (theme) => ({
  secondaryAppBar: {
    backgroundColor: theme.palette.primary.light,
    position: 'relative',
    overflow: 'auto',
  },
});

class DashboardAppBar extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      add: PropTypes.string,
      secondaryAppBar: PropTypes.string,
    }).isRequired,
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { classes, title, children } = this.props;

    return (
      <AppBar position="relative">
        <Toolbar className={classes.secondaryAppBar}>
          {title}
          {children}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(DashboardAppBar);
