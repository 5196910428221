import { useEffect, useState, useRef, FC } from 'react';
import { useTheme } from '@mui/material';
import moment from 'moment';
import Plotly, { Config, Layout } from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import WithCircularProgress from '../with-circular-progress/withCircularProgress';

const Plot = createPlotlyComponent(Plotly);

interface AvailabilityChartProps {
  /**
   * Date ranges where data is available. The plot will have color between each
   * pair of dates in this array
   */
  availabilityDateRanges: {
    /** Lower cound for colored section of the chart */
    dateFrom: Date;
    /** Upper cound for colored section of the chart */
    dateTo: Date;
  }[];
  /**
   * The date range for the plot to show. Using a availabilityDateRange outside
   * of this window will not display
   */
  displayDateRange: {
    /** Lower bound for the date range displayed in the chart */
    dateFrom: Date;
    /** Upper bound for the date range displayed in the chart */
    dateTo: Date;
  };
  /**
   * Whether or not a date range can be selected. If true onDateRangeSelected
   * likely should be passed as well
   */
  canSelectDateRange?: boolean;
  /** Callback function for when a date range is selected */
  onDateRangeSelected?: (selected: {
    dateFrom: Date;
    dateTo: Date;
    availabilityDateRanges: { dateFrom: Date; dateTo: Date }[];
  }) => void;
  /** Whether or not to display the default title 'Data Availability' */
  displayDefaultTitle?: boolean;
  /** Named CSS color or Hexidecimal string to change the plot background to */
  backgroundColour?: string;
}

interface PlotData {
  x: any[];
  y: any[];
  type: 'scatter';
  fill: 'tozeroy';
  fillcolor: string; // Replace string with the actual type of theme.palette.secondary.main
  mode: 'markers';
  marker: {
    size: number;
  };
  hoverinfo: 'x';
}
/**
 * Creates a chart that shows data avilability by coloring segments of a plot.
 * This is not hooked to any services so it's up to the parent component to pass
 * down the correct range(s)
 */
const AvailabilityChart: FC<AvailabilityChartProps> = ({
  availabilityDateRanges,
  displayDateRange,
  canSelectDateRange = false,
  onDateRangeSelected = () => {},
  displayDefaultTitle = true,
  backgroundColour = undefined,
}) => {
  const configRef = useRef<Config>({
    modeBarButtonsToRemove: [
      'toImage',
      'autoScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
      'select2d',
    ],
    displaylogo: false,
    scrollZoom: true,
  });
  const theme = useTheme();

  const layoutRef = useRef<Layout>({
    xaxis: {
      range: [displayDateRange.dateFrom, displayDateRange.dateTo],
      showgrid: true,
      mirror: true,
      showline: true,
      visible: false,
      linecolor: 'grey',
      type: 'date' as const,
      tickformatstops: [
        {
          dtickrange: [null, 24 * 60 * 60 * 1000],
          value: '%b %e\n%Y',
        },
      ],
    },
    yaxis: {
      fixedrange: true,
      showgrid: false,
      showticklabels: false,
      showline: true,
      visible: false,
      mirror: true,
      linecolor: 'grey',
      range: [0, 1],
      zeroline: false,
    },
    annotations: [],
    autosize: true,
    margin: { t: 30, b: 40, l: 30, r: 30 },
    dragmode: 'zoom',
    selectdirection: 'h',
    plot_bgcolor: backgroundColour,
    paper_bgcolor: backgroundColour,
    title: undefined,
  });

  const dataAvailabilityStyles = {
    width: '100%',
    height: 120,
  };

  const [data, setData] = useState<PlotData[]>();

  useEffect(() => {
    const modeBarButtonsToRemove = [
      'toImage',
      'autoScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
    ];
    if (!canSelectDateRange) {
      modeBarButtonsToRemove.push('select2d');
    }
    configRef.current.modeBarButtonsToRemove = modeBarButtonsToRemove;
    configRef.current.displaylogo = false;
    configRef.current.scrollZoom = true;
  }, [canSelectDateRange]);

  useEffect(() => {
    const xNew = [];
    const yNew = [];
    const buildData = () => {
      availabilityDateRanges.forEach((pair) => {
        xNew.push(pair.dateFrom);
        yNew.push(0);
        xNew.push(pair.dateFrom);
        yNew.push(1);
        xNew.push(pair.dateTo);
        yNew.push(1);
        xNew.push(pair.dateTo);
        yNew.push(0);
      });
    };

    buildData();
    const newData: PlotData[] = [
      {
        x: xNew,
        y: yNew,
        type: 'scatter',
        fill: 'tozeroy',
        fillcolor: theme.palette.secondary.main,
        mode: 'markers',
        marker: { size: 1 },
        hoverinfo: 'x',
      },
    ];
    setData(newData);
  }, [availabilityDateRanges, theme]);

  useEffect(() => {
    if (availabilityDateRanges && availabilityDateRanges.length > 0) {
      layoutRef.current.xaxis.visible = true;
      layoutRef.current.yaxis.visible = true;
      layoutRef.current.xaxis.range = [
        displayDateRange.dateFrom,
        displayDateRange.dateTo,
      ];
      layoutRef.current.annotations = [];
    } else {
      layoutRef.current.xaxis.visible = false;
      layoutRef.current.yaxis.visible = false;
      layoutRef.current.annotations = [{}];
      layoutRef.current.annotations[0].text = 'No data available';
      layoutRef.current.annotations[0].xref = 'paper';
      layoutRef.current.annotations[0].yref = 'paper';
      layoutRef.current.annotations[0].showarrow = false;
      layoutRef.current.annotations[0].font = {};
      layoutRef.current.annotations[0].font.size = 24;
    }
    if (displayDefaultTitle) {
      layoutRef.current.title = {
        text: 'Data Availability',
        y: 0.95,
        x: 0,
        xanchor: 'left',
        yanchor: 'top',
      };
    }
  }, [displayDateRange, availabilityDateRanges, displayDefaultTitle]);

  const handleDateSelected = (event) => {
    if (canSelectDateRange) {
      // event dates may not come in order
      const valueOne = moment.utc(event.range.x[0]);
      const valueTwo = moment.utc(event.range.x[1]);
      const selectStartUTC =
        valueOne.valueOf() < valueTwo.valueOf() ? valueOne : valueTwo;
      const selectEndUTC =
        valueOne.valueOf() > valueTwo.valueOf() ? valueOne : valueTwo;
      const startRoundDown = selectStartUTC.startOf('day');
      const endRoundUp = selectEndUTC.add(1, 'days').startOf('day');

      onDateRangeSelected({
        dateFrom: startRoundDown.toDate(),
        dateTo: endRoundUp.toDate(),
        availabilityDateRanges,
      });
    }
  };

  return (
    <Plot
      style={dataAvailabilityStyles}
      data={data}
      layout={layoutRef.current}
      config={configRef.current}
      onSelected={handleDateSelected}
    />
  );
};

export default WithCircularProgress(AvailabilityChart);
