import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import MultiQueueLocationPanel from 'domain/AppComponents/multi-queue/MultiQueueLocationPanel';
import MultiQueuePanel from 'domain/AppComponents/multi-queue/MultiQueuePanel';
import MultiQueueShorestationPanel from 'domain/AppComponents/multi-queue/MultiQueueShorestationPanel';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';
import Panel from 'library/CompositeComponents/panel/Panel';

const styles = (theme) => ({
  container: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
});

class MultiQueueInformationPanel extends PureComponent {
  static propTypes = {
    node: NodePropType,
    refreshTree: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    treeData: PropTypes.arrayOf(NodePropType),
    permission: PropTypes.string.isRequired,
  };

  static defaultProps = {
    node: undefined,
    treeData: undefined,
  };

  getParent = (node) => {
    const { treeData } = this.props;
    let parentNode;
    const findNode = (treeItem, currentNode) =>
      treeItem.forEach((item) => {
        if (
          item.objectId === node.objectId &&
          item.multiQueueTypeId === node.multiQueueTypeId
        ) {
          parentNode = currentNode;
        }
        if (Array.isArray(item.children)) {
          if (item.children.length) {
            findNode(item.children, item);
          }
        }
        return null;
      });
    findNode(treeData, node);
    return parentNode;
  };

  renderNodeInformation() {
    const { node, refreshTree, onInfo, onError, permission } = this.props;
    let panelContents = <Panel title={this.renderPanelTitle()} />;
    if (node) {
      panelContents = (
        <>
          {(() => {
            switch (node.multiQueueTypeId) {
              case 1:
                return (
                  <MultiQueueLocationPanel
                    node={node}
                    refreshTree={refreshTree}
                    onInfo={onInfo}
                    onError={onError}
                    permission={permission}
                  />
                );
              case 2:
                return (
                  <MultiQueueShorestationPanel
                    node={node}
                    locationNode={this.getParent(node)}
                    refreshTree={refreshTree}
                    onInfo={onInfo}
                    onError={onError}
                    permission={permission}
                  />
                );
              case 3:
                return (
                  <MultiQueuePanel
                    node={node}
                    locationNode={this.getParent(this.getParent(node))}
                    refreshTree={refreshTree}
                    onInfo={onInfo}
                    onError={onError}
                    permission={permission}
                  />
                );
              default:
                return null;
            }
          })()}
        </>
      );
    }
    return panelContents;
  }

  renderPanelTitle() {
    const { node } = this.props;
    return node ? `${node.multiQueueTreeNodeName}` : `Select a Node...`;
  }

  render() {
    return <>{this.renderNodeInformation()}</>;
  }
}

export default withStyles(styles)(MultiQueueInformationPanel);
