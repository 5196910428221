import { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { ListItemIcon, TextField } from 'base-components';
import {
  DeleteIconButton,
  EditIconButton,
} from 'domain/AppComponents/IconButtons';
import type { DataSource } from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';

type DataSourceDisplayProps = {
  source: DataSource;
  editDataSource: (source: DataSource) => void;
  removeDataSource: (source: DataSource) => void;
  putDataSource: (source: DataSource) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      marginTop: 0,
      marginBottom: 0,
    },
  })
);

const DataSourceDisplay = ({
  source,
  editDataSource,
  removeDataSource,
  putDataSource,
}: DataSourceDisplayProps) => {
  const classes = useStyles();
  const [name, setName] = useState(
    source.traceName ? source.traceName : source.name
  );
  return (
    <>
      <TextField
        translationKey="dashboards.traceName"
        translationOptions={{ traceName: source.sensorCodeName }}
        value={name}
        name={source.nodeId}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value);
          const newSource = { ...source, traceName: event.target.value };
          putDataSource(newSource);
        }}
        fullWidth
        className={classes.textField}
      />
      <ListItemIcon>
        <EditIconButton
          onClick={() => {
            editDataSource(source);
          }}
        />
        <DeleteIconButton
          onClick={() => {
            removeDataSource(source);
          }}
        />
      </ListItemIcon>
    </>
  );
};

export default DataSourceDisplay;
