import { post, get } from 'util/WebRequest';

class ActionService {
  static get = () =>
    get('ActionListingService').then((payload) => payload.data.payload);

  static create = (payload) =>
    post('ActionListingService', {
      operation: 1,
      actionCategoryId: payload.actionCategoryId,
      actionCategoryName: payload.actionCategoryName,
      needsCruise: payload.needsCruise,
      actionName: payload.actionName,
    });

  static delete = (actionId) =>
    post('ActionListingService', {
      operation: 3,
      actionId,
    });

  static update = (payload) =>
    post('ActionListingService', {
      operation: 2,
      actionId: payload.actionId,
      actionCategoryId: payload.actionCategoryId,
      actionCategoryName: payload.actionCategoryName,
      needsCruise: payload.needsCruise,
      actionName: payload.actionName,
    });
}
export default ActionService;
