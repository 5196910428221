import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { ShowChart, TableView } from '@onc/icons';
import { IconButton, Typography } from 'base-components';
import { NOAA_DATA } from 'domain/AppComponents/organization-details/OrganizationServiceData';
import SeaTubeLogContext from 'domain/AppComponents/sea-tube/SeaTubeLogContext';
import SensorReadingDisplay from 'domain/AppComponents/table/SensorReadingDisplay';
import {
  convertSamples,
  formatSamples,
  getFormattedUnit,
} from 'domain/Apps/seatube/sensor-display/util/SensorUtil';
import SensorPlotDisplay from 'domain/Apps/seatube/sensor-plot/SensorPlotDisplay';
import {
  SeatubeSample,
  SeatubeSensorData,
  useSeaTubeSensorData,
} from 'domain/services/SeatubeSensorDataService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import DateFormatUtils from 'util/DateFormatUtils';
import useBroadcast from 'util/hooks/useBroadcast';
import BroadcastChannel from './BroadcastChannel';

/**
 * Sensors that have a preferred index in sensor display @readonly @enum
 * {number}
 */
const PREFERRED_SENSORS = { Latitude: 0, Longitude: 1, Heading: 2, Depth: 3 };

const SensorReadingsWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId } = props;

  const { dive } = useContext(SeaTubeLogContext);
  const { diveId, organizationId, dateFrom, dateTo } = dive;

  const { data: sensorDataPayload, isFetching } = useSeaTubeSensorData({
    resourceId: diveId,
    resourceTypeId: 600,
  });

  const [currentTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.CurrentTimestamp,
    null,
    id
  );

  const [plottableData, setPlottableData] = useState<SeatubeSensorData[]>();
  const [formattedSensorData, setFormattedSensorData] = useState<
    SeatubeSample[]
  >([]);
  const [showGraph, setShowGraph] = useState(false);

  useEffect(() => {
    if (sensorDataPayload) {
      const convertedPayload =
        organizationId === NOAA_DATA.organizationId
          ? convertSamples(sensorDataPayload)
          : sensorDataPayload;

      setPlottableData(convertedPayload);
      const formattedSamples = formatSamples(convertedPayload);
      setFormattedSensorData(formattedSamples);
    }
  }, [sensorDataPayload, organizationId]);

  /**
   * For our Sensor Reading display, Latitude, Longitude, Heading and Depth have
   * preferred indexes. The rest of the sensors are ordered alphabetically
   */
  const reorderSensorValues = (sensorValues) => {
    const preferred = [];
    const others = [];
    sensorValues.forEach((value) => {
      const index = PREFERRED_SENSORS[value.sensorName];
      if (index !== undefined) preferred[index] = value;
      else others.push(value);
    });
    others.sort((a, b) =>
      a.sensorName.toLowerCase().localeCompare(b.sensorName.toLowerCase())
    );
    preferred.push(...others);
    // strip unused positions generated by ordering of preferred
    return preferred.filter((ele) => ele !== undefined);
  };

  const getRowsForSensorValueDisplay = () => {
    if (
      !currentTimestamp ||
      !formattedSensorData ||
      formattedSensorData.length === 0
    ) {
      return [];
    }

    const mostRecentDateObj = moment
      .utc(currentTimestamp)
      .subtract(30, 'seconds');
    const leastRecentDateObj = mostRecentDateObj
      .clone()
      .subtract(30, 'seconds');

    // Use filter to get all rows within the 30-second window
    const rowsWithinTimeWindow = formattedSensorData.filter(
      (row) =>
        row.intervalDateObj <= mostRecentDateObj &&
        row.intervalDateObj > leastRecentDateObj
    );

    // Extract and collect all sensor values from the filtered rows
    const sensorValues = rowsWithinTimeWindow.flatMap(
      (row) => row.sensorValues
    );

    // Process and format sensor values, ensuring uniqueness
    const result = [];
    const sensorNamesFound = new Set();

    sensorValues.forEach((reading) => {
      if (reading && !sensorNamesFound.has(reading.sensorName)) {
        sensorNamesFound.add(reading.sensorName);
        const formattedReading = {
          ...reading,
          sampleDate: DateFormatUtils.formatDate(reading.sampleDate, 'full'),
          value: `${reading.value}${getFormattedUnit(reading.uom)}`,
        };
        result.push(formattedReading);
      }
    });

    return reorderSensorValues(result);
  };

  const inputRows = getRowsForSensorValueDisplay();
  const titleTimestamp = (
    <Typography title="title timestamp">
      {currentTimestamp
        ? DateFormatUtils.formatDate(currentTimestamp, 'full')
        : ''}
    </Typography>
  );

  return (
    <Widget
      title="Sensor Readings"
      loading={isFetching}
      key={id}
      id={id}
      dashboardId={dashboardId}
      MenuItems={[]}
      titleComponents={titleTimestamp}
      actionComponents={[
        <IconButton
          onClick={() => setShowGraph(!showGraph)}
          aria-label={showGraph ? 'Show Table' : 'Show Graph'}
          Icon={showGraph ? TableView : ShowChart}
        />,
      ]}
      {...props}
    >
      {showGraph ? (
        <SensorPlotDisplay
          plottableData={plottableData || []}
          currentDate={moment.utc(currentTimestamp)}
          startDate={moment.utc(dateFrom)}
          endDate={moment.utc(dateTo)}
        />
      ) : (
        <SensorReadingDisplay rows={inputRows} />
      )}
    </Widget>
  );
};

SensorReadingsWidget.widgetKey = 'sensor-readings';
SensorReadingsWidget.widgetTitle = 'Sensor Readings';
SensorReadingsWidget.defaultDataGrid = {
  i: 'sensor-readings',
  x: 0,
  y: 6,
  w: 6,
  h: 12,
};

export default SensorReadingsWidget;
