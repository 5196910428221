import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class DeviceNamePlateService {
  static getMany(deviceId) {
    return DeviceNamePlateService.get({ operation: 5, deviceId });
  }

  static getOne(nameplateId, deviceId) {
    return DeviceNamePlateService.get({
      operation: 4,
      namePlateId: nameplateId,
      deviceId,
    });
  }

  static get(params) {
    return get('DeviceNamePlateService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(params) {
    return post('DeviceNamePlateService', {
      operation: 1,
      ...params,
    }).then((response) => response);
  }

  static update(params) {
    return post('DeviceNamePlateService', {
      operation: 2,
      ...params,
    }).then((response) => response);
  }

  static delete = (params) =>
    post('DeviceNamePlateService', {
      operation: 3,
      ...params,
    }).then((response) => response);
}
export default DeviceNamePlateService;
