import React from 'react';
import { Moment } from 'moment';
import { PlayArrow } from '@onc/icons';
import { IconButton } from 'base-components';

type VideoRestartButtonProps = {
  currentTimestamp: Moment;
  endDate: Moment;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  containerRef?: HTMLDivElement;
};

const VideoRestartButton: React.FC<VideoRestartButtonProps> = ({
  currentTimestamp,
  endDate,
  onClick,
  containerRef = undefined,
}) => (
  <>
    {currentTimestamp?.isSameOrAfter(endDate) && (
      <IconButton
        color="inherit"
        onClick={onClick}
        aria-label="Play From the Beginning"
        tooltipProps={{
          slotProps: {
            popper: { container: containerRef },
          },
        }}
      >
        <PlayArrow fontSize="large" />
      </IconButton>
    )}
  </>
);

export default VideoRestartButton;
