import { PureComponent, createRef } from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import L from 'leaflet';
import PropTypes from 'prop-types';
import qs from 'qs';
import { flushSync } from 'react-dom';
import { Loading } from '@onc/composite-components';
import { LabelledCheckbox, MenuItem, SizeMe } from 'base-components';

import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import DiveFormContainer from 'domain/AppComponents/dive-management/DiveFormContainer';
import DetailsFactory from 'domain/AppComponents/factory/DetailsFactory';
import {
  SearchIconButton,
  PlayIconButton,
  OpenDeckLogIconButton,
} from 'domain/AppComponents/IconButtons';
import ONCMap from 'domain/AppComponents/map/ONCMap';
import {
  ONC_DATA,
  NOAA_DATA,
  DFO_DATA,
} from 'domain/AppComponents/organization-details/OrganizationServiceData';
import ExpeditionTree from 'domain/Apps/expedition-management/tree/ExpeditionTree';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import DiveListingService from 'domain/services/DiveListingService';
import SeaTubePermissionsService from 'domain/services/SeaTubePermissionsService';

import MapContext from 'library/CompositeComponents/map/MapContext';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import CookieUtils from 'util/CookieUtils';
import Environment from 'util/Environment';
import ExpeditionService from './details/ExpeditionService';
import ExpeditionPageLayout from './layout/ExpeditionPageLayout';
import ExpeditionManagementThemeProvider from './theme/ExpeditionManagementThemeProvider';
import FixedCameraLocationTree from './tree/FixedCameraLocationTree';

const DISPLAY_ORGANIZATION_INFO = true;
const DISPLAY_CATALOG_INFO = true;
const MAX_ZOOM = 12;

const CATALOG_RESOURCE_TYPE = 0;

const SHOW_EXTRA_DIVE_INFO = 'show-extra-dive-info';

const GRAY = '#f5f5f5';
const styles = () => ({
  layout: {
    background: GRAY,
  },
  noPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

let INDEX = 0;

const EXPEDITION_MAP_ID = 'expedition-map';
class ExpeditionLandingPage extends PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      layout: PropTypes.string,
    }).isRequired,
  };

  findOrganizationId = (path) => {
    if (path.length < 2) return ONC_DATA.organizationId;

    const node = path[1];
    if (node.indexOf('NOAA') > -1) {
      return NOAA_DATA.organizationId;
    }
    if (node.indexOf('Department of Fisheries and Oceans') > -1) {
      return DFO_DATA.organizationId;
    }

    return ONC_DATA.organizationId;
  };

  state = {
    currentFilter: ['Expeditions'],
    info: undefined,
    createDiveOpen: false,
    deleteDiveOpen: false,
    permissions: new Map(),
    showExtraDiveInfo: CookieUtils.getCookie(SHOW_EXTRA_DIVE_INFO) || false,
    loggedIn: false,
    expeditionTree: undefined,
    treeLoading: false,
  };

  value = { mapRef: createRef(), mapId: EXPEDITION_MAP_ID };

  async componentDidMount() {
    const parsedLocation = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const location = parsedLocation.location === 'true' || false;
    this.setState({ location, loading: true });

    Promise.all([
      this.checkAuthenticated(),
      this.getUserPermissions(),
      this.getExpeditionTree(),
    ])
      .then(() => {
        this.buildExpeditionTree();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentFilter } = this.state;
    const prevFilter = prevState.currentFilter;
    if (!prevFilter || !currentFilter) return;

    const current = currentFilter.slice(-1).pop();
    const previous = prevFilter.slice(-1).pop();
    if (
      (current.diveId &&
        (!previous.diveId || previous.diveId !== current.diveId)) ||
      (current.expeditionId &&
        (!previous.expeditionId ||
          previous.expeditionId !== current.expeditionId))
    ) {
      this.fitBoundsToMarkers();
    }
  }

  checkAuthenticated = () =>
    this.setState({ loggedIn: Environment.isUserLoggedIn() });

  getUserPermissions = () => {
    const { onError } = this.props;

    return SeaTubePermissionsService.getOrganizationPermissions()
      .then((response) => {
        const permissionMap = new Map(
          response.map((i) => [i.organization.organizationId, i.permission])
        );
        this.setState({ permissions: permissionMap });
      })
      .catch((error) => {
        onError(error?.message);
      });
  };

  assignIdsToNodes = (siblings) =>
    siblings.map((sibling) => {
      INDEX += 1;
      return {
        ...sibling,
        nodeId: INDEX,
        children: this.assignIdsToNodes(sibling.children),
      };
    });

  buildExpeditionTree = () => {
    const { expeditionTree } = this.state;
    if (expeditionTree) {
      const { videoTreeConfig } = expeditionTree;
      INDEX = 0;
      const modifiedConfig = this.assignIdsToNodes(videoTreeConfig);
      this.setState({
        expeditionTreeConfig: modifiedConfig,
      });
    }
  };

  getExpeditionTree = async () => {
    const { onError } = this.props;
    this.setState({ treeLoading: true });
    return ExpeditionService.getExpeditionTree()
      .then((response) => {
        // set the state synchronously
        flushSync(() => {
          this.setState({
            expeditionTree: response,
          });
        });
      })
      .catch((error) => {
        onError(error?.message);
      })
      .finally(() => {
        this.setState({ treeLoading: false });
      });
  };

  handleDeleteDive = async () => {
    const { onError, onInfo } = this.props;
    const { info } = this.state;

    return DiveListingService.deleteDive(info.diveId)
      .then(async (response) => {
        // Service can return a 200 statusCode 0 with the message "Dive xxxx has annotations. Did not delete."
        if (response && response.data && response.data.message) {
          onError(response.data.message);
        } else {
          onInfo('Dive deleted');
          await this.getExpeditionTree();
          this.buildExpeditionTree();
        }
        this.setState({
          deleteDiveOpen: false,
          selectedNodeId: undefined,
        });
        this.handleExpeditionClicked(
          info.expeditionId,
          info.organizationId,
          info.deckLogReady
        );
      })
      .catch((error) => {
        if (error.message) {
          return onError(error.message);
        }
        return onError('Failed to delete dive');
      });
  };

  handleWatchVideoLink = (resourceId) => {
    const urlContext = Environment.getLinkUrl();
    if (resourceId) {
      window.open(`${urlContext}/app/dive-logs/${resourceId}`, '_blank');
    }
  };

  handleSeatubeSearchLink = (info) => {
    const urlContext = Environment.getLinkUrl();
    let params = '';

    if (info) {
      if (info.expeditionId) {
        params = info
          ? `?cruiseIds=${info.expeditionId}${
              info.diveId ? `&diveIds=${info.diveId}` : ''
            }`
          : ``;
      }
      if (info.deckLogReady) {
        params = info ? `?cruiseIds=${info.expeditionId}&includeDeckLog` : ``;
      }
    }
    // TODO: make this a back end parameter passed to this page!
    window.open(`${urlContext}/SeaTubeSearch${params}`, '_blank');
  };

  handleDeckLogLink = (info) => {
    const urlContext = Environment.getLinkUrl();

    if (info.includeDeckLog) {
      const params = `?cruiseIds=${info.expeditionId}&includeDeckLog`;
      window.open(`${urlContext}/SeaTubeSearch${params}`, '_blank');
    } else {
      window.open(
        `${urlContext}/app/expedition-logs/${info.expeditionId}`,
        '_blank'
      );
    }
  };

  handleMenuItemClicked = async (path, nodeId, deckLogReady) => {
    const lastItem = path.slice(-1).pop();
    const secondLastItem = path[path.length - 2];
    const newState = { currentFilter: path, selectedNodeId: nodeId };

    const organizationId = this.findOrganizationId(path);
    if (lastItem.expeditionId) {
      this.handleExpeditionClicked(
        lastItem.expeditionId,
        organizationId,
        deckLogReady
      );
    } else if (lastItem.diveId) {
      this.handleDiveClicked(
        lastItem.diveId,
        secondLastItem.expeditionId,
        organizationId
      );
    } else if (
      (lastItem.indexOf && lastItem.indexOf('Ocean Networks Canada') > -1) ||
      (!isNaN(lastItem) && secondLastItem.indexOf('Ocean Networks Canada') > -1)
    ) {
      this.handleOrganizationClicked(ONC_DATA.organizationId);
    } else if (
      (lastItem.indexOf && lastItem.indexOf('NOAA') > -1) ||
      (!isNaN(lastItem) && secondLastItem.indexOf('NOAA') > -1)
    ) {
      this.handleOrganizationClicked(NOAA_DATA.organizationId);
    } else if (
      (lastItem.indexOf &&
        lastItem.indexOf('Department of Fisheries and Oceans') > -1) ||
      (!isNaN(lastItem) &&
        secondLastItem.indexOf('Department of Fisheries and Oceans') > -1)
    ) {
      this.handleOrganizationClicked(DFO_DATA.organizationId);
    } else {
      newState.info = undefined;
    }
    this.setState(newState);
  };

  handleOrganizationClicked = (organizationId) => {
    this.setState({ info: { organizationId } });
  };

  handleExpeditionClicked = (expeditionId, organizationId, deckLogReady) => {
    this.setState({ info: { expeditionId, organizationId, deckLogReady } });
  };

  handleDiveClicked = (diveId, expeditionId, organizationId) => {
    this.setState({ info: { diveId, expeditionId, organizationId } });
  };

  fitBoundsToMarkers = () => {
    const markerConfig = this.buildMarkerConfig();
    if (this.value.mapRef.current && markerConfig && markerConfig.markers) {
      this.value.mapRef.current.fitBounds(
        L.latLngBounds(markerConfig.markers),
        {
          maxZoom: MAX_ZOOM,
        }
      );
    }
  };

  filterConfig = (config, path) => {
    if (!path || path.length === 0) return config;
    const newPath = path.slice(0);
    let newConfig;
    const identifier = newPath.shift();
    if (identifier.expeditionId) {
      newConfig = config.find(
        (element) => element.id === identifier.expeditionId
      );
    } else if (identifier.diveId) {
      return config.filter((element) => element.id === identifier.diveId);
    } else {
      newConfig = config.find((element) => element.title === identifier);
    }
    return this.filterConfig(newConfig.children, newPath);
  };

  buildMarkerInfo = (leaf, expeditionId) => {
    let markerId;
    let lat;
    let lon;
    let onClick;
    let tooltip;

    // Dive
    if (expeditionId) {
      lat = leaf.referenceLat;
      lon = leaf.referenceLon;
      if (!lat || !lon) return undefined;
      markerId = leaf.id;
      tooltip = leaf.title;
      onClick = () => this.handleDiveClicked(leaf.id, expeditionId);
    }

    return { markerId, lat, lon, onClick, tooltip };
  };

  traverseBranch = (branch, markers) => {
    const { info } = this.state;
    if (branch.xtype === 'CRUISE') {
      for (const leaf of branch.children) {
        if (leaf.xtype === 'DIVE') {
          markers.push(this.buildMarkerInfo(leaf, branch.id));
        }
      }
      // Should only be reached if the current tree is only one dive
      // In this case, we can get expeditionId from state
    } else if (branch.xtype === 'DIVE') {
      if (info && info.expeditionId)
        markers.push(this.buildMarkerInfo(branch, info.expeditionId));
    } else {
      branch.children.map((child) => this.traverseBranch(child, markers));
    }
  };

  buildExpeditionMarkers = () => {
    const { expeditionTreeConfig, currentFilter } = this.state;
    if (!expeditionTreeConfig) return undefined;
    const markers = [];
    this.filterConfig(expeditionTreeConfig, currentFilter).map((branch) =>
      this.traverseBranch(branch, markers)
    );
    const actualMarkers = markers.filter((marker) => marker);
    if (actualMarkers.length < 1) return undefined;
    return {
      name: 'Dives',
      checked: true,
      markers: actualMarkers,
      onClick: () => {},
    };
  };

  buildMarkerConfig = () => {
    const { currentFilter } = this.state;
    if (!currentFilter) return undefined;
    const currentTopNode = currentFilter.slice(0, 1).pop();

    // Expedition Tree
    if (currentTopNode === 'Expeditions') {
      return this.buildExpeditionMarkers();
    }
    return undefined;
  };

  renderDetailsHeaderIcons = () => {
    const { info } = this.state;
    if (!info) return undefined;

    let playIcon;
    let deckLogIcon;
    let searchIcon;

    // Dive Play Icon
    if (info.diveId) {
      playIcon = (
        <PlayIconButton
          onClick={() => {
            this.handleWatchVideoLink(info.diveId);
          }}
        />
      );
    }

    if (info.expeditionId && info.deckLogReady) {
      deckLogIcon = (
        <OpenDeckLogIconButton
          onClick={(e) => {
            e.stopPropagation();
            this.handleDeckLogLink(info);
          }}
        />
      );
    }

    // Expedition or Search Tree Node Search Icon
    if (info.expeditionId) {
      searchIcon = (
        <SearchIconButton
          onClick={() => {
            this.handleSeatubeSearchLink(info);
          }}
        />
      );
    }

    return (
      <>
        {playIcon}
        {deckLogIcon}
        {searchIcon}
      </>
    );
  };

  determineSelectedMarker = () => {
    const { info } = this.state;
    if (!info) return undefined;

    if (info.diveId) return info.diveId;

    return undefined;
  };

  handleOpenCreateDiveDialog = () => {
    this.setState({ createDiveOpen: true });
  };

  handleCloseCreateDiveDialog = () => {
    this.setState({ createDiveOpen: false });
  };

  handleOpenDeleteDiveDialog = () => {
    this.setState({ deleteDiveOpen: true });
  };

  handleCloseDeleteDiveDialog = () => {
    this.setState({ deleteDiveOpen: false });
  };

  handleFormSubmit = async () => {
    await this.getExpeditionTree();
    this.buildExpeditionTree();
  };

  hasPrivilege = () => {
    const { info } = this.state;
    if (info) {
      return this.hasPrivilegeForOrganization(info.organizationId);
    }
    return false;
  };

  hasPrivilegeForOrganization = (organizationId) => {
    const { permissions } = this.state;
    return permissions.get(organizationId) === 'RW' || false;
  };

  filterTreeData = (treeData) => {
    if (treeData) {
      const filteredData = [...treeData];
      const organizations = filteredData[0].children;
      filteredData[0].children = organizations.map((organization) => {
        const modifiedOrg = { ...organization };
        if (!this.hasPrivilegeForOrganization(organization.organizationId)) {
          modifiedOrg.children = this.filterNodes(modifiedOrg.children);
        }
        return modifiedOrg;
      });
      return filteredData;
    }
    return treeData;
  };

  filterNodes = (children) =>
    children
      .map((child) => {
        // Hide dives that are not marked ready
        if (child.xtype === 'DIVE' && !child.ready) {
          return undefined;
        }
        // Hide cruises that do not have any dives
        if (child.xtype === 'CRUISE' && !child.children.length) {
          return undefined;
        }
        return {
          ...child,
          children: this.filterNodes(child.children),
        };
      })
      .filter((child) => child !== undefined);

  handleToggleExtraInfo = (e) => {
    e.preventDefault();
    const { showExtraDiveInfo } = this.state;
    CookieUtils.saveCookie(SHOW_EXTRA_DIVE_INFO, !showExtraDiveInfo);
    this.setState({ showExtraDiveInfo: !showExtraDiveInfo });
  };

  renderDetailsMenu = () => {
    const { info, showExtraDiveInfo, loggedIn } = this.state;
    const options = [];
    if (info && info.expeditionId) {
      // No dive id means a cruise is selected
      if (!info.diveId && this.hasPrivilege()) {
        options.push(
          <MenuItem key="create-dive" onClick={this.handleOpenCreateDiveDialog}>
            Create Dive
          </MenuItem>
        );
      }
      // Give option to show more details if user logged in
      if (info.diveId && loggedIn) {
        options.push(
          <MenuItem onClick={this.handleToggleExtraInfo}>
            <LabelledCheckbox
              label="Show more details"
              value={showExtraDiveInfo}
              onClick={this.handleToggleExtraInfo}
            />
          </MenuItem>
        );
        // Add options to edit and delete dives if the user has permission
        if (this.hasPrivilege()) {
          options.push(
            <MenuItem key="edit-dive" onClick={this.handleOpenCreateDiveDialog}>
              Edit Dive
            </MenuItem>
          );
          options.push(
            <MenuItem
              key="delete-dive"
              onClick={this.handleOpenDeleteDiveDialog}
            >
              Delete Dive
            </MenuItem>
          );
        }
      }
    }

    return options;
  };

  render() {
    const { classes } = this.props;
    const {
      expeditionTreeConfig,
      bathymetryPermissions,
      info,
      location,
      selectedNodeId,
      createDiveOpen,
      deleteDiveOpen,
      showExtraDiveInfo,
      loggedIn,
      loading,
      treeLoading,
    } = this.state;

    let resourceId = 0;
    let resourceTypeId = DISPLAY_CATALOG_INFO ? CATALOG_RESOURCE_TYPE : 0;
    const organizationId =
      (info && info.organizationId) || ONC_DATA.organizationId;
    if (info) {
      if (info.diveId) {
        resourceId = info.diveId;
        resourceTypeId = SeaTubeResourceTypes.DIVE;
      } else if (info.expeditionId) {
        resourceId = info.expeditionId;
        resourceTypeId = SeaTubeResourceTypes.EXPEDITION;
      } else if (DISPLAY_ORGANIZATION_INFO && info.organizationId) {
        resourceId = info.organizationId;
        resourceTypeId = SeaTubeResourceTypes.ORGANIZATION;
      }
    }

    const expeditionTreeActionContent = (
      <SearchIconButton onClick={this.handleSeatubeSearchLink} />
    );

    const markerConfig = this.buildMarkerConfig();
    const selectedMarker = this.determineSelectedMarker();

    if (loading) {
      return <Loading />;
    }

    return (
      <ExpeditionManagementThemeProvider>
        <MapContext.Provider value={this.value}>
          {info && this.hasPrivilege() ? (
            <DiveFormContainer
              open={createDiveOpen}
              onClose={this.handleCloseCreateDiveDialog}
              initialCruiseId={info ? info.expeditionId : null}
              diveId={info ? info.diveId : undefined}
              onSubmit={this.handleFormSubmit}
            />
          ) : undefined}

          <ConfirmationDialog
            title="Delete Dive"
            content="Are you sure you want to delete this dive?"
            open={deleteDiveOpen}
            onConfirm={this.handleDeleteDive}
            onCancel={this.handleCloseDeleteDiveDialog}
          />

          <ExpeditionPageLayout
            containerId="expedition-root-id"
            className={classes.layout}
            onError={() => {}}
            onLayoutClick={this.handleLayoutClick}
          >
            <Panel
              key="expedition-list"
              title={<Typography variant="body1">List</Typography>}
              headerDraggable
              actionContent={expeditionTreeActionContent}
              loading={treeLoading}
            >
              <ExpeditionTree
                treeConfig={this.filterTreeData(expeditionTreeConfig)}
                onMenuItemClicked={this.handleMenuItemClicked}
                onWatchButtonClicked={this.handleWatchVideoLink}
                onSearchButtonClicked={this.handleSeatubeSearchLink}
                onDeckLogButtonClicked={this.handleDeckLogLink}
                selectedNodeId={selectedNodeId}
              />
              {location && (
                <FixedCameraLocationTree
                  onNodeSelect={() => /* Just here for testing */ {}}
                />
              )}
            </Panel>
            <Panel
              key={EXPEDITION_MAP_ID}
              title={<Typography variant="body1">Map</Typography>}
              headerDraggable
            >
              <SizeMe key="seatube-map" monitorHeight monitorWidth>
                <ONCMap
                  mapId={EXPEDITION_MAP_ID}
                  zoomControl={{ position: 'topleft' }}
                  layersControl={{ position: 'topright' }}
                  bathymetry
                  bathymetryPermissions={bathymetryPermissions}
                  markerConfig={markerConfig}
                  maxZoom={MAX_ZOOM}
                  selectedMarkerId={selectedMarker}
                />
              </SizeMe>
            </Panel>
            <Panel
              key="expedition-details"
              title={
                <Typography variant="body1">SeaTube Catalog Details</Typography>
              }
              headerDraggable
              menu={this.renderDetailsMenu()}
              actionContent={this.renderDetailsHeaderIcons()}
            >
              <DetailsFactory
                organizationId={organizationId}
                resourceId={resourceId}
                resourceTypeId={resourceTypeId}
                location={location}
                createDiveOpen={createDiveOpen}
                showExtraDiveInfo={loggedIn && showExtraDiveInfo}
              />
            </Panel>
          </ExpeditionPageLayout>
        </MapContext.Provider>
      </ExpeditionManagementThemeProvider>
    );
  }
}

export default withStyles(styles)(withSnackbars(ExpeditionLandingPage));
