import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Done } from '@onc/icons';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SequentialSubMenu,
} from 'base-components';

const styles = {
  menu: {
    boxSizing: 'unset !important',
  },
};

class VideoPlayerMenu extends Component {
  static propTypes = {
    playerId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    getFullscreen: PropTypes.func,
    anchorEl: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
    classes: PropTypes.objectOf(PropTypes.string),
    menuOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
      })
    ),
    qualityProps: PropTypes.shape({
      currentQuality: PropTypes.string,
      qualityOptions: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      onQualityChange: PropTypes.func,
    }),
    speedProps: PropTypes.shape({
      currentSpeed: PropTypes.number,
      speedOptions: PropTypes.arrayOf(PropTypes.number),
      onSpeedChange: PropTypes.func,
    }),
    captionProps: PropTypes.shape({
      currentCaptions: PropTypes.number,
      captionOptions: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string })
      ),
      onCaptionChange: PropTypes.func,
    }),
  };

  static defaultProps = {
    classes: undefined,
    anchorEl: undefined,
    getFullscreen: undefined,
    menuOptions: undefined,
    qualityProps: {
      currentQuality: null,
      qualityOptions: null,
      onQualityChange: null,
    },
    speedProps: {
      currentSpeed: 1,
      speedOptions: [0.5, 1, 1.5, 2, 4],
      onSpeedChange: null,
    },
    captionProps: {
      currentCaptions: 0,
      captionOptions: null,
      onCaptionChange: null,
    },
  };

  renderMenuItems = (options, current, onClick, key) =>
    options.map((option) => (
      <MenuItem key={`${key}-item-${option}`} onClick={() => onClick(option)}>
        <ListItemIcon label={current === option ? 'Selected' : undefined}>
          <Done
            style={{
              display: current === option ? undefined : 'none',
            }}
          />
        </ListItemIcon>
        <ListItemText primary={`${option}`} />
      </MenuItem>
    ));

  /**
   * Only return the video container when fullscreen so it renders on top of the
   * player
   */
  getVideoContainer = () => {
    const { getFullscreen } = this.props;
    if (!getFullscreen) return undefined;
    return getFullscreen() ? document.querySelector('.jw-wrapper') : undefined;
  };

  handleQualityChange = (quality) => {
    const {
      qualityProps: { qualityOptions, onQualityChange },
    } = this.props;
    onQualityChange(
      qualityOptions.find((option) => option.description === quality).code
    );
  };

  handleSpeedChange = (speed) => {
    const {
      speedProps: { onSpeedChange },
    } = this.props;
    onSpeedChange(speed);
  };

  handleCaptionChange = (caption) => {
    const {
      captionProps: { captionOptions, onCaptionChange },
    } = this.props;
    onCaptionChange(
      captionOptions.findIndex((option) => option.label === caption)
    );
  };

  handleMenuItemClick = (onClick) => {
    const { onClose } = this.props;
    onClick();
    onClose();
  };

  handleSubMenuItemClick = (onClick) => (value) => {
    const { onClose } = this.props;
    onClick(value);
    onClose();
  };

  renderQualityMenu = (playerId) => {
    const {
      qualityProps: { currentQuality, qualityOptions },
    } = this.props;

    if (!currentQuality || qualityOptions.length < 2) {
      return undefined;
    }

    return (
      <SequentialSubMenu
        key={`${playerId}-speed-menu`}
        onBackClick={this.closeQuality}
        menuTitle="Quality"
      >
        {this.renderMenuItems(
          qualityOptions.map((option) => option.description),
          qualityOptions.find((option) => option.code === currentQuality)
            ?.description,
          this.handleQualityChange,
          'quality'
        )}
      </SequentialSubMenu>
    );
  };

  renderSpeedMenu = (playerId) => {
    const {
      speedProps: { currentSpeed, speedOptions },
    } = this.props;

    if (!speedOptions) {
      return undefined;
    }

    return (
      <SequentialSubMenu
        key={`${playerId}-speed-menu`}
        onBackClick={this.closeSpeed}
        menuTitle="Speed"
      >
        {this.renderMenuItems(
          speedOptions,
          currentSpeed,
          this.handleSpeedChange,
          'speed'
        )}
      </SequentialSubMenu>
    );
  };

  renderCaptionMenu = (playerId) => {
    const {
      onClose,
      captionProps: { currentCaptions, captionOptions },
    } = this.props;

    if (!captionOptions || captionOptions.length < 2) {
      return undefined;
    }

    return (
      <SequentialSubMenu
        key={`${playerId}-captions-menu`}
        onBackClick={onClose}
        menuTitle="Captions"
      >
        {this.renderMenuItems(
          captionOptions.map((option) => option.label),
          captionOptions[currentCaptions].label,
          this.handleCaptionChange,
          'caption'
        )}
      </SequentialSubMenu>
    );
  };

  /** Renders addition menu options from the menuOptions prop */
  renderMenuOptions = () => {
    const { menuOptions, playerId, onClose } = this.props;
    if (!menuOptions || menuOptions.length < 1) return undefined;
    return menuOptions.map((option) => {
      const { key, label, onClick } = option;
      // If the option contains sub options render a subquential sub menu
      if (option.options) {
        const { options, selected } = option;
        return (
          <SequentialSubMenu
            key={`${playerId}-${key}-menu`}
            onBackClick={onClose}
            menuTitle={label}
          >
            {this.renderMenuItems(
              options,
              selected,
              this.handleSubMenuItemClick(onClick),
              key
            )}
          </SequentialSubMenu>
        );
      }
      // Otherwise render a top level menu item
      return (
        <MenuItem
          key={`${playerId}-${key}`}
          onClick={() => this.handleMenuItemClick(onClick)}
        >
          {label}
        </MenuItem>
      );
    });
  };

  render() {
    const { classes, playerId, open, anchorEl, onClose } = this.props;

    return (
      <Menu
        id={`${playerId}-onc-player-menu`}
        className={classes.menu}
        // Render the menu on top of the video, even in fullscreen
        container={this.getVideoContainer()}
        open={open}
        collapseOnClose
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        updatePosition
      >
        {this.renderQualityMenu()}
        {this.renderSpeedMenu(playerId)}
        {this.renderCaptionMenu(playerId)}
        {this.renderMenuOptions()}
      </Menu>
    );
  }
}
export default withStyles(styles)(VideoPlayerMenu);
