class AnnotationResourceTypes {
  static ATTRIBUTE = { label: 'Attribute', value: 1700 };

  static CALIBRATION = { label: 'Calibration', value: 1100 };

  static DEVICE = { label: 'Device', value: 2 };

  static DEVICE_DATA = { label: 'Device Data', value: 1000 };

  static DIVE = { label: 'Dive', value: 600 };

  static JVM = { label: 'JVM', value: 501 };

  static LOCATION = { label: 'Location', value: 4 };

  static REGION = { label: 'Region', value: 3 };

  static SENSOR = { label: 'Sensor', value: 102 };

  static SENSOR_DATA = { label: 'Sensor Data', value: 103 };

  static SEARCH_TREE_NODE = { label: 'Search Tree Node', value: 11 };

  static STATION = { label: 'Station', value: 1 };

  static SITE = { label: 'Site', value: 5 };
}

export default AnnotationResourceTypes;
