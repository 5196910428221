import { useState } from 'react';
import * as React from 'react';
import { FilterList } from '@onc/icons';
import { FilterValue } from 'library/CompositeComponents/filter/Filter';
import FilterGroup from 'library/CompositeComponents/filter/FilterGroup';
import DateFilter from './filters/DateFilter';
import FieldsFilter from './filters/FieldsFilter';
import OwnerFilter from './filters/OwnerFilter';
import ResourceFilter from './filters/ResourceFilter';
import SourceFilter from './filters/SourceFilter';
import { CopyLinkIconButton } from '../IconButtons';

type Props = {
  name: string;
  onSubmit: (event: any) => void;
  onCopyLink: (filterObj: FilterValue) => void;
  filter: FilterValue;
};

const AnnotationSearchFilters: React.VFC<Props> = (props: Props) => {
  const { onSubmit, filter, name, onCopyLink } = props;
  const [expanded, setExpanded] = useState('resourceFilter');
  const handleExpand = (filterName: string) => {
    filterName === expanded ? setExpanded('') : setExpanded(filterName);
  };

  const [filterValue, setFilterValue] = useState<FilterValue>(filter);

  const renderActions = () => (
    <CopyLinkIconButton
      onClick={(e) => {
        e.stopPropagation();
        onCopyLink(filterValue);
      }}
    />
  );

  return (
    <FilterGroup
      title="Annotation Filters"
      name={name}
      onChange={(e) =>
        e.target.value ? setFilterValue(e.target.value) : undefined
      }
      onSubmit={onSubmit}
      icon={<FilterList color="action" />}
      initialValue={filter}
      value={filterValue}
      submitTranslationKey="common.search"
      actions={renderActions()}
    >
      <ResourceFilter
        name="resourceFilter"
        expanded={expanded === 'resourceFilter'}
        onExpand={handleExpand}
      />

      <DateFilter
        name="dateFilter"
        expanded={expanded === 'dateFilter'}
        onExpand={handleExpand}
      />

      <SourceFilter
        name="sourceFilter"
        expanded={expanded === 'sourceFilter'}
        onExpand={handleExpand}
      />

      <FieldsFilter
        name="fieldFilter"
        expanded={expanded === 'fieldFilter'}
        onExpand={handleExpand}
      />

      <OwnerFilter
        name="ownerFilter"
        expanded={expanded === 'ownerFilter'}
        onExpand={handleExpand}
      />
    </FilterGroup>
  );
};
export default AnnotationSearchFilters;
