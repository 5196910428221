import { ReactNode } from 'react';
import { ArrowBackIos } from '@onc/icons';
import { Box, Divider, IconButton, ListItemText } from 'base-components';

import MenuItem from './MenuItem';

type SequentialSubMenuPRops = {
  /** Title of the menu */
  menuTitle: string;
  /** React children */
  children?: ReactNode;
  /** Callback triggered after back button is clicked */
  onBackClick?: () => void;
};

const SequentialSubMenu = ({
  children = null,
  onBackClick = undefined,
  menuTitle,
}: SequentialSubMenuPRops) => (
  <Box>
    <MenuItem onClick={onBackClick} selected={false}>
      <IconButton aria-label="back">
        <ArrowBackIos fontSize="small" />
      </IconButton>
      <ListItemText primary={menuTitle} />
    </MenuItem>
    <Divider />
    {children}
  </Box>
);

export default SequentialSubMenu;
