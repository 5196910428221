import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid, Typography } from 'base-components';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';

const styles = (theme) => ({
  indentLevel: {
    paddingRight: theme.spacing(2),
  },
  root: {
    paddingBottom: theme.spacing(2),
  },
  headingStyle: {
    paddingTop: theme.spacing(2),
  },
});
class DOICitation extends Component {
  static propTypes = {
    doicitation: PropTypes.objectOf(PropTypes.string),
    querycitation: PropTypes.objectOf(PropTypes.string),
    classes: PropTypes.shape({
      indentLevel: PropTypes.string,
      headingStyle: PropTypes.string,
      root: PropTypes.string,
    }),
  };

  static defaultProps = {
    doicitation: undefined,
    querycitation: undefined,
    classes: undefined,
  };

  renderMarginedReadOnlyFields = (key, value, classes) => (
    <Grid
      key={key}
      className={classes.indentLevel}
      container
      direction="column"
      name={key}
    >
      <MarginedReadOnlyField
        labelText={key}
        valueText={value.toString()}
        title={key}
      />
    </Grid>
  );

  renderUndefined = (key, value, classes) => (
    <Grid
      key={key}
      className={classes.indentLevel}
      container
      direction="column"
      name={key}
    >
      <MarginedReadOnlyField
        labelText={key}
        valueText="undefined"
        title={key}
      />
    </Grid>
  );

  /*
   * Function for future if Citation text seperates the fields. Currently, fields of citation text are concatenated to a single string
   *
  generateCitationText = data => {
    let rows = [
      {
        key: 'Citation',
        value: data.citation,
      },
      {
        key: 'Author',
        value: data.author,
      }
      { etc }
    ];
    return rows;
  };
   *
   */

  renderItem = (key, value, classes) => {
    if (typeof value !== 'undefined') {
      return this.renderMarginedReadOnlyFields(key, value, classes);
    }
    return this.renderUndefined(key, value, classes);
  };

  render() {
    const { doicitation, querycitation, classes } = this.props;
    let citation;
    if (doicitation) {
      // currently only one citation will be loaded whether it is for a DOI Dataset or Query PID
      citation = this.renderItem('DOI Citation', doicitation, classes);
    } else if (querycitation) {
      citation = this.renderItem('Query Citation', querycitation, classes);
    } else {
      return null;
    }
    return (
      <>
        <Typography className={classes.headingStyle} variant="h6">
          Citation
        </Typography>
        <div className={classes.root}>{citation}</div>
      </>
    );
  }
}

export default withStyles(styles)(DOICitation);
