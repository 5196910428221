import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { ErrorSnackbar } from '@onc/composite-components';

import { get } from 'util/WebRequest';
import DFLandingPage from './DFLandingPage';

const LIST_CAMPAIGNS = 8;
class DigitalFishers extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string,
    }),
  };

  static defaultProps = {
    match: {
      path: undefined,
    },
  };

  state = {
    campaigns: [],
    error: null,
  };

  componentDidMount() {
    this.getCampaignList();
  }

  getCampaignList = async () => {
    try {
      this.setState({ error: null });
      const response = await get('DigitalFishersService', {
        operation: LIST_CAMPAIGNS,
      });
      if (response.data.statusCode === 0) {
        this.setState({ campaigns: response.data.payload.campaigns });
      } else {
        const error = { message: response.data.message };
        this.setState({ error });
      }
    } catch (error) {
      this.setState({ error });
    }
  };

  renderDFLandingPage = () => {
    const { campaigns } = this.state;
    return <DFLandingPage campaigns={campaigns} />;
  };

  render() {
    const { match } = this.props;
    const { error } = this.state;

    let errorSnackbar = null;
    if (error) {
      errorSnackbar = <ErrorSnackbar message={error.message} />;
    }

    return (
      <>
        {errorSnackbar}
        <Switch>
          <Route
            exact
            path={`${match.path}`}
            component={this.renderDFLandingPage}
          />
        </Switch>
      </>
    );
  }
}

export default DigitalFishers;
