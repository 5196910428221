import { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Autocomplete,
  Collapse,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from 'base-components';
import TaskDefinitionService from 'domain/AppComponents/batch/service/TaskDefinitionService';
import TMTaskService from 'domain/AppComponents/batch/service/TMTaskService';
import { FormDialog } from 'domain/AppComponents/dialogs/FormDialog';
import { UserDefinedTasksRunSelect } from 'domain/AppComponents/dropdowns/Dropdowns';
import { TitleToggle } from 'domain/AppComponents/Form/Fields/Toggles';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

import { parseDmasAPIResponse } from 'util/WebRequest';

const USER_DEFINED_TASK_TYPE_ID = 8;

class UserDefinedTasksWidgetConfig extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    initialShowTitle: PropTypes.bool.isRequired,
    initialTitle: PropTypes.string,
    initialUserDefinedTasksImagePath: PropTypes.string,
    initialUserDefinedTasksType: PropTypes.string,
    initialSelectedTaskDefinition: PropTypes.shape({ value: PropTypes.string }),
    initialUserDefinedTasksRunSelection: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onError: PropTypes.func,
  };

  static defaultProps = {
    initialTitle: '',
    initialUserDefinedTasksType: 'latest',
    initialSelectedTaskDefinition: null,
    initialUserDefinedTasksImagePath: undefined,
    initialUserDefinedTasksRunSelection: undefined,
    onError: () => {},
  };

  constructor(props) {
    super();

    const {
      initialTitle,
      initialShowTitle,
      initialUserDefinedTasksImagePath,
      initialUserDefinedTasksType,
      initialSelectedTaskDefinition,
      initialUserDefinedTasksRunSelection,
    } = props;
    this.state = {
      selectedTaskDefinition: initialSelectedTaskDefinition,
      autocompleteTaskDefinitions: [initialSelectedTaskDefinition],
      title: initialTitle,
      showTitle: initialShowTitle,
      userDefinedTasksImagePath: initialUserDefinedTasksImagePath,
      userDefinedTasksType: initialUserDefinedTasksType,
      allRunsPaths: [],
      userDefinedTasksRunSelection:
        initialUserDefinedTasksRunSelection || undefined,
    };
  }

  componentDidMount() {
    const { initialSelectedTaskDefinition } = this.props;
    this.getTaskDefinitions();
    if (initialSelectedTaskDefinition && initialSelectedTaskDefinition.value) {
      this.getRunData(initialSelectedTaskDefinition.value);
    }
  }

  async getTaskDefinitions() {
    const { onError } = this.props;
    TaskDefinitionService.getTaskDefinitionByTaskTypeAndOwner(
      USER_DEFINED_TASK_TYPE_ID
    )
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => {
        this.setState({
          autocompleteTaskDefinitions: payload.map((taskDefinition) => ({
            label: taskDefinition.name,
            value: taskDefinition.taskDefinitionId,
          })),
        });
      })
      .catch((e) => onError(e));
  }

  getRunData = (taskDefinitionId) => {
    const { onError } = this.props;

    TMTaskService.getAllRunsByTaskDefinitionId(taskDefinitionId)
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => {
        const allRunsPaths = [];

        payload.tasks.forEach((task) => {
          const label = `${task.input.taskDefinitionName} / ${task.input['image.name']}`;

          let isUnique = true;
          allRunsPaths.forEach((runPath) => {
            if (runPath.label === label) {
              isUnique = false;
            }
          });

          if (isUnique) {
            allRunsPaths.push({
              key: task.taskId.toString(),
              label,
              imageName: task.input['image.name'],
              value: task.taskId,
            });
          }
        });

        this.setState({ allRunsPaths });
      })
      .catch((e) => {
        onError(e);
      });
  };

  onUserDefinedTasksTypeChange = (event, val) => {
    const { selectedTaskDefinition } = this.state;

    if (val) {
      this.setState({ userDefinedTasksType: val });

      if (val === 'latest') {
        this.setState({ userDefinedTasksRunSelection: undefined });
      }
      if (selectedTaskDefinition) {
        this.getRunData(selectedTaskDefinition.value);
      }
    }
  };

  onTitleFieldChange = (event) => {
    this.setState({ title: event.target.value });
  };

  onShowTitleChange = () => {
    const { showTitle } = this.state;
    this.setState({ showTitle: !showTitle });
  };

  onUserDefinedTasksPathToImageChange = (event) => {
    this.setState({ userDefinedTasksImagePath: event.target.value });
  };

  onUserDefinedTasksAutocompleteChange = (event, option) => {
    this.setState({ selectedTaskDefinition: option });
    if (option && option.value !== undefined) {
      this.getRunData(option.value);
    } else {
      this.setState({ allRunsPaths: [] });
    }
  };

  onUserDefinedTasksRunChange = (event) => {
    const { allRunsPaths } = this.state;
    this.setState({
      userDefinedTasksRunSelection: allRunsPaths.find(
        // triple equals doesn't work when comparing different objects. Linter is wrong here
        // eslint-disable-next-line eqeqeq
        (item) => item.key == event.target.value
      ),
    });
  };

  render() {
    const { onSave, onCancel, open } = this.props;

    const {
      userDefinedTasksType,
      selectedTaskDefinition,
      autocompleteTaskDefinitions,
      title,
      showTitle,
      userDefinedTasksImagePath,
      allRunsPaths,
      userDefinedTasksRunSelection,
    } = this.state;

    return (
      <FormDialog
        title="Configure User Defined Tasks Widget"
        confirmButtonTranslationKey="common.buttons.save"
        cancelButtonTranslationKey="common.buttons.cancel"
        maxWidth="sm"
        open={open}
        onClose={onCancel}
        onSubmit={() => onSave(this.state)}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TitleToggle
              title="showTitle"
              name="showTitle"
              value={showTitle}
              onChange={this.onShowTitleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={showTitle}>
              <TextField
                translationKey="common.textfields.title"
                title="title"
                name="title"
                value={title}
                onChange={this.onTitleFieldChange}
              />
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              virtualized
              getOptionLabel={(option) => (option ? option.label : undefined)}
              translationKey="dashboards.taskDefinitionSelection"
              name="taskDefinition"
              options={autocompleteTaskDefinitions}
              onChange={this.onUserDefinedTasksAutocompleteChange}
              value={selectedTaskDefinition}
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              exclusive
              onChange={this.onUserDefinedTasksTypeChange}
              value={userDefinedTasksType}
            >
              <ToggleButton value="latest">Latest</ToggleButton>
              <ToggleButton value="custom">Custom</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={userDefinedTasksType === 'custom'}>
              <UserDefinedTasksRunSelect
                options={allRunsPaths}
                value={
                  userDefinedTasksRunSelection
                    ? userDefinedTasksRunSelection.key
                    : undefined
                }
                onChange={this.onUserDefinedTasksRunChange}
                fullWidth
              />
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <TextField
              translationKey="dashboards.pathToImage"
              name="userDefinedTasksPath"
              fullWidth
              disabled={selectedTaskDefinition === undefined}
              value={userDefinedTasksImagePath}
              onChange={this.onUserDefinedTasksPathToImageChange}
            />
          </Grid>
        </Grid>
      </FormDialog>
    );
  }
}

export default withSnackbars(UserDefinedTasksWidgetConfig);
