import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import SensorCalibrationMaintenance from './SensorCalibrationMaintenance';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(2),
  },
});

const defaultState = {};

class SensorCalibrationMaintenancePage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
    }),
    calibrationId: PropTypes.number,
    sensorId: PropTypes.number,
  };

  static defaultProps = {
    classes: undefined,
    calibrationId: undefined,
    sensorId: undefined,
  };

  constructor(props) {
    super(props);
    const { calibrationId, sensorId } = this.props;
    this.state = {
      ...defaultState,
      sensorId,
      calibrationId,
    };
  }

  render() {
    const { calibrationId, sensorId } = this.props;

    return (
      <SensorCalibrationMaintenance
        calibrationId={calibrationId}
        sensorId={sensorId}
      />
    );
  }
}
export default withStyles(STYLES)(SensorCalibrationMaintenancePage);
