import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import UserPreferenceService from 'domain/AppComponents/UserPreferenceService';

class UserPreferenceSelect extends PureComponent {
  static propTypes = { preferenceTypeId: PropTypes.number.isRequired };

  state = { options: [] };

  componentDidMount() {
    this.fetchSavedPlots();
  }

  fetchSavedPlots = async () => {
    const { preferenceTypeId } = this.props;
    const options =
      await UserPreferenceService.getUserPreferences(preferenceTypeId);

    this.setState({ options });
  };

  render() {
    const { preferenceTypeId, ...rest } = this.props;
    const { options } = this.state;
    return <Dropdown options={options} fullWidth {...rest} />;
  }
}

export default UserPreferenceSelect;
