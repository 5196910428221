/* eslint-disable react/prop-types */
import { Collapse, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ToggleButton, TypographyWithTranslation } from 'base-components';
import { DataSourceListSortableFormController } from 'domain/AppComponents/Dashboard/latest-reading-widget/DataSourceListSortableFormController';
import { type Location } from 'domain/AppComponents/Dashboard/latest-reading-widget/LatestReadingsWidget';
import { type ConfigFields } from 'library/CompositeComponents/dashboard/DashboardTypes';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';
import FormToggleButtonGroup from 'library/CompositeComponents/form/FormToggleButtonGroup';
import { validateNumber } from 'library/CompositeComponents/form/FormValidator';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: theme.spacing(80),
  },
  formButtons: {
    marginTop: 10,
    float: 'right',
  },
}));

export type LatestReadingsWidgetFormType = {
  dataSourceSelection: string;
  locations?: Location[];
  title: string;
  sensorIds: string;
  simpleDateEnabled?: boolean;
  showTitle?: boolean;
  dataWarningEnabled?: boolean;
  autoRefreshEnabled?: boolean;
  dataAgeLimit: number;
};

const LatestReadingsWidgetConfigContent: ConfigFields<
  LatestReadingsWidgetFormType
> = ({ formMethods }) => {
  const classes = useStyles();
  const { watch } = formMethods;

  const showTitle = watch('showTitle');
  const dataSourceSelection = watch('dataSourceSelection');
  const dataWarningEnabled = watch('dataWarningEnabled');

  const dataSourceInput = (source: string) => {
    if (source === 'sensorId') {
      return (
        <Grid item xs={12}>
          <FormTextField
            translationKey="device.sensorIds"
            name="sensorIds"
            rules={{
              required: 'At least one valid sensor id is required',
            }}
          />
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <DataSourceListSortableFormController
          name="locations"
          treeTypePreset="propertiesByLocation"
          propertyPreset={[]}
          multiple
          disableDateRange
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={1} className={classes.form}>
      <Grid item xs={12}>
        <FormToggle label="Show Title" name="showTitle" />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showTitle}>
          <FormTextField
            translationKey="common.textfields.title"
            name="title"
            fullWidth
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <FormToggle
          tooltip="Enable to show data age in days, disable to show data creation date"
          name="simpleDateEnabled"
          label="Show Simple Date"
        />
      </Grid>
      <Grid item xs={12}>
        <FormToggle
          tooltip="Show a small warning icon beside data that is older than selected age"
          name="dataWarningEnabled"
          label="Show old data warning"
        />
      </Grid>
      <Collapse in={dataWarningEnabled}>
        <Grid item xs={12}>
          <TypographyWithTranslation
            id="data-selector"
            translationKey="dashboards.showWarningOldData"
          />
          <FormTextField
            translationKey="dashboards.days"
            name="dataAgeLimit"
            rules={{
              required: 'A valid data age limit is required',
              validate: (dataAgeLimit) =>
                validateNumber(
                  dataAgeLimit,
                  'A valid data age limit is required'
                ),
            }}
          />
        </Grid>
      </Collapse>

      <Grid item xs={12}>
        <TypographyWithTranslation
          id="data-selector"
          translationKey="dashboards.selectDataBy"
        />
      </Grid>
      <FormToggleButtonGroup
        name="dataSourceSelection"
        exclusive
        requireSelection
      >
        <ToggleButton value="sensorId">Sensor ID</ToggleButton>
        <ToggleButton value="location">Location</ToggleButton>
      </FormToggleButtonGroup>
      <Grid item xs={12}>
        {dataSourceInput(dataSourceSelection)}
      </Grid>
      <Grid item xs={12}>
        <FormToggle name="autoRefreshEnabled" label="Auto Refresh" />
      </Grid>
    </Grid>
  );
};

export default LatestReadingsWidgetConfigContent;
