export default class MapUtil {
  /**
   * Given an array find the closest to a point
   *
   * @param {Array[Array]} latlngs [[lat, lon],]
   * @param {Array} point [lat, lon]
   * @returns {object} { index: int, value: [lat, lon] }
   */
  static closest(latlngs, point) {
    if (!point || point.length !== 2 || !latlngs || latlngs.length < 2) {
      throw new Error(
        'Invalid arguments passed in. Requires an array of coords and a point'
      );
    }
    const lat = point[0];
    const lon = point[1];

    let minDif = 99999;
    let closest;

    latlngs.forEach((latlng, index) => {
      if (latlng.length !== 2) return;
      const dif = MapUtil.PythagorasEquirectangular(
        lat,
        lon,
        latlng[0],
        latlng[1]
      );
      if (dif < minDif) {
        closest = index;
        minDif = dif;
      }
    });
    return {
      index: closest,
      value: latlngs[closest],
    };
  }

  /**
   * Converts degrees to radians
   *
   * @param {double} deg Degrees
   * @returns Radians
   */
  static Deg2Rad(deg) {
    return (deg * Math.PI) / 180;
  }

  /**
   * @param {Array} lat1 [lat, lon]
   * @param {Array} lon1 [lat, lon]
   * @param {Array} lat2 [lat, lon]
   * @param {Array} lon2 [lat, lon]
   * @returns Distance between the two points
   */
  static PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
    const corrLat1 = MapUtil.Deg2Rad(lat1);
    const corrLat2 = MapUtil.Deg2Rad(lat2);
    const corrLon1 = MapUtil.Deg2Rad(lon1);
    const corrLon2 = MapUtil.Deg2Rad(lon2);
    const R = 6371; // km
    const x = (corrLon2 - corrLon1) * Math.cos((corrLat1 + corrLat2) / 2);
    const y = corrLat2 - corrLat1;
    const d = Math.sqrt(x * x + y * y) * R;
    return d;
  }

  static findIndexOfTimestamp(
    sortedTimestamps,
    timestamp,
    startIndex,
    endIndex
  ) {
    const time = new Date(timestamp).getTime();
    const middleIndex = Math.floor((startIndex + endIndex) / 2);
    const middleTime = new Date(sortedTimestamps[middleIndex]).getTime();
    if (time === middleTime) return middleIndex;
    if (endIndex - 1 === startIndex || startIndex === endIndex)
      return Math.abs(new Date(sortedTimestamps[startIndex]).getTime() - time) >
        Math.abs(new Date(sortedTimestamps[endIndex]).getTime() - time)
        ? endIndex
        : startIndex;
    if (time > middleTime)
      return MapUtil.findIndexOfTimestamp(
        sortedTimestamps,
        timestamp,
        middleIndex,
        endIndex
      );
    // if (time < middleTime)
    return MapUtil.findIndexOfTimestamp(
      sortedTimestamps,
      timestamp,
      startIndex,
      middleIndex
    );
  }
}
