import { useState } from 'react';
import { DenseThemeProvider } from '@onc/theme';
import CompromisedAnnotationEntry from 'domain/AppComponents/compromised-instruments/CompromisedAnnotationEntry';

type Props = {
  device: {
    deviceId: number;
    deviceName: string;
  };
};

const CompromisedAnnotationEntryPage = ({ device }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(true);

  const handleCloseDialog = () => setDialogOpen(false);

  return (
    <DenseThemeProvider>
      <CompromisedAnnotationEntry
        device={device}
        open={dialogOpen}
        onClose={handleCloseDialog}
      />
    </DenseThemeProvider>
  );
};

export default CompromisedAnnotationEntryPage;
