export const QAQC_AUTOTESTS_SERVICE = 'QaqcAutotestsService';
export const QAQC_TEST_DETAILS_SERVICE = 'QaqcTestDetailsService';

export const INSTRUMENT = 'Instrument';
export const MANUAL = 'Manual';
export const OBSERVATORY = 'Observatory';
export const REGION = 'Region';
export const STATION = 'Station';
export const STUDY_AREA = 'StudyArea';
export const STUDY_AREA_FROM_SERVICE = 'Study Area';
export const DEVICE_AND_SENSOR = 'DeviceAndSensor';

export const QAQC_TEST_GROUP_OBSERVATORY_ID = 1;
export const QAQC_TEST_GROUP_REGION_ID = 2;
