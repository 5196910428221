import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TicketManagerService from 'domain/services/TicketManagerService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import SupportRequestForm from './SupportRequestForm';

const SupportRequestContainer = ({
  jiraDomain,
  pageAffiliation,
  user,
  onInfo,
  onError,
  onClose = () => {},
}) => {
  const [isOpen, setOpen] = useState(true);
  const [isReady, setReady] = useState(false);
  const [requestTypes, setRequestTypes] = useState([]);

  // this should probably be implemented in SupportRequestForm instead, so
  // that the Submit button isn't enabled until request types have been loaded
  useEffect(() => {
    const loadRequestTypes = async () => {
      try {
        const types = await TicketManagerService.getRequestTypes('OC');
        setReady(true);
        setRequestTypes(types);
      } catch (error) {
        onError('Failed to load. Try refreshing the page.');
      }
    };

    if (!requestTypes || requestTypes.length === 0) {
      loadRequestTypes();
    }
  }, [requestTypes, onError]);

  // prevents npe crashes in dev
  const domain = jiraDomain !== undefined ? jiraDomain.replace(/\/$/, '') : '';

  const handleSubmit = async (project, summary, fields) => {
    setReady(false);
    try {
      await TicketManagerService.create(project, summary, {
        pageAffiliation,
        ...fields,
      });

      onInfo('Request submitted');
      handleClose();
    } catch (error) {
      onError(error);
    }
    setReady(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <SupportRequestForm
      jiraDomain={domain}
      user={user}
      requestTypes={requestTypes}
      open={isOpen}
      ready={isReady}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

SupportRequestContainer.propTypes = {
  onInfo: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,

  jiraDomain: PropTypes.string.isRequired,
  pageAffiliation: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

export default withSnackbars(SupportRequestContainer);
