// Custom Hook: useDashboardData.ts
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocalStorage } from 'util/hooks/useStorage';

function useDashboardData<T>(
  isStatic: boolean,
  key: string,
  initialValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [stateData, setStateData] = useState<T>(initialValue);
  const [localStorageData, setLocalStorageData] = useLocalStorage<T>(
    key,
    isStatic ? undefined : initialValue
  );

  /**
   * This useEffect is mostly for helping with development, so changing a layout
   * updates the page immediately instead of needing a refresh (at least when
   * using vite to hot deploy). It shouldn't cause issues... but could be
   * removed if it does.
   *
   * It would only be problematic if a dashboard was changing the
   * 'defaultLayout' prop for some reason.
   */
  useEffect(() => {
    if (isStatic) {
      // Compare old vs. new
      if (!_.isEqual(stateData, initialValue)) {
        setStateData(initialValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatic, initialValue]);

  // Select the appropriate state and setter
  const data = isStatic ? stateData : localStorageData;
  const setData = isStatic ? setStateData : setLocalStorageData;

  return [data, setData];
}

export default useDashboardData;
