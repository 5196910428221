import { useEffect } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import { Dropdown, TextField } from 'base-components';
import { FilterProps } from './TableFilterFactory';

const styles = (theme: Theme) =>
  createStyles({
    flexItem: {
      margin: theme.spacing(0.5),
    },
  });

const operatorOptions = [
  {
    key: 'contains',
    label: 'contains',
    value: 'contains',
  },
  {
    key: 'doesNotContain',
    label: 'does not contain',
    value: 'doesNotContain',
  },
  {
    key: 'equals',
    label: 'equals',
    value: 'equals',
  },
  {
    key: 'doesNotEqual',
    label: 'does not equal',
    value: 'doesNotEqual',
  },
  {
    key: 'isNull',
    label: 'is null',
    value: 'isNull',
  },
  {
    key: 'isNotNull',
    label: 'is not null',
    value: 'isNotNull',
  },
];

const TableStringFilter: React.VFC<FilterProps> = ({
  operator,
  value,
  classes,
  handleChangeOperator,
  handleChangeValue,
}) => {
  useEffect(() => {
    if (operator === undefined) {
      handleChangeOperator(operatorOptions[0].value);
    }
  }, [operator, handleChangeOperator]);
  return (
    <>
      <Dropdown
        label="Operator"
        name="operator"
        fullWidth
        className={classes ? classes.flexItem : undefined}
        value={operator}
        options={operatorOptions}
        onChange={(e) => handleChangeOperator(e.target.value)}
      />
      <TextField
        translationKey="common.textfields.value"
        name="value"
        fullWidth
        className={classes ? classes.flexItem : undefined}
        disabled={operator === 'isNull' || operator === 'isNotNull'}
        value={value}
        onChange={(e) => handleChangeValue(e.target.value)}
      />
    </>
  );
};

export default withStyles(styles)(TableStringFilter);
