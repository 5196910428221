import { Children } from 'react';

import * as yup from 'yup';

class FormValidation {
  schema = {};

  initialValues = {};

  clearValues = {};

  buildValidationSchema = (fields) => {
    this.schema = yup.object().shape();
    this.createSchema(fields);
  };

  createSchema = (children) => {
    Children.map(children, (child) => {
      if (child && child.props && child.props.children) {
        this.createSchema(child.props.children);
      }
      this.addValidationsToSchema(child);
    });
  };

  addValidationsToSchema = (child) => {
    if (child && child.props && child.props.title) {
      const val = child.props.title;
      switch (typeof child.props.initialValue) {
        case 'boolean':
          this.initialValues[val] = child.props.initialValue;
          this.clearValues[val] = false;
          break;
        case 'number':
          this.initialValues[val] = child.props.initialValue || 0;
          this.clearValues[val] = 0;
          break;
        default:
          this.initialValues[val] = child.props.initialValue || '';
          this.clearValues[val] = '';
      }
    }
    if (child && child.props && child.props.validation) {
      const val = child.props.title;
      this.schema.fields[val] = child.props.validation;
      // eslint-disable-next-line no-underscore-dangle
      this.schema._nodes.push(val);
      // this next else if, I have to dig deep if there is a withStyles on an
      // input which is super annoying
    } else if (
      child &&
      child.type &&
      child.type.Naked &&
      child.type.Naked.defaultProps &&
      child.type.Naked.defaultProps.validation
    ) {
      const val = child.props.title;
      this.schema.fields[val] = child.type.Naked.defaultProps.validation;
      // eslint-disable-next-line no-underscore-dangle
      this.schema._nodes.push(val);
    }
  };
}

export default FormValidation;
