const REQUEST_FORMAT = 'playlist.m3u8';

class MediaUtils {
  static getRequestFormat = () => REQUEST_FORMAT;

  static sortFileList = (fileList) =>
    fileList
      .map((file) => ({
        ...file,
        dateFromObj: new Date(file.dateFrom),
      }))
      .sort((a, b) => a.dateFromObj.getTime() - b.dateFromObj.getTime());
}

export default MediaUtils;
