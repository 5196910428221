import { type AxiosResponse } from 'axios';
import DmasError from 'util/DmasError';

/**
 * Response data structure for DMAS requests.
 *
 * @template ResponsePayload The type of the payload data.
 */
export type DmasResponseData<ResponsePayload> = {
  statusCode: number;
  message?: string;
  payload?: ResponsePayload;
};

/**
 * Axios response with DMAS-specific data structure.
 *
 * @template ResponsePayload The type of the payload data.
 */
export type DmasAxiosResponse<ResponsePayload> = AxiosResponse<
  DmasResponseData<ResponsePayload>
>;

/**
 * Promise that resolves to a DMAS-specific axios response.
 *
 * @template ResponsePayload The type of the payload data.
 */
export type DmasAxiosPromise<ResponsePayload> = Promise<
  DmasAxiosResponse<ResponsePayload>
>;

/** Represents a record of unknown values. */
export type UnknownValueRecord = Record<string, unknown>;

/**
 * Parses the Axios response from a DMAS API request, extracting the payload or
 * throwing an error based on the statusCode.
 *
 * @template ResponsePayload The expected type of the payload in the response.
 * @param {AxiosResponse<string>} response The Axios response to be parsed,
 *   expecting a JSON string.
 * @returns {DmasResponseData<ResponsePayload>} The parsed DMAS response data.
 */
export const parseDmasAPIResponse = <ResponsePayload>(
  response: AxiosResponse<DmasResponseData<ResponsePayload>>
): ResponsePayload => {
  const { data } = response;
  if (data.statusCode !== 0) {
    throw new DmasError(data.message, data.statusCode);
  }
  return data.payload;
};
