import { ComponentType, useState } from 'react';

interface HocProps {
  onTime: (Date) => void;
}

interface ChildProps {
  isLive: true;
  playlist: object;
  onTime: (Date) => void;
  time: Date;
}

const withLiveStream =
  (liveStreamUrl: string) =>
  <P extends ChildProps>(VideoComponent: ComponentType<P>) =>
  (props: HocProps) => {
    const { onTime } = props;

    const [time, setTime] = useState<Date>(new Date());

    const playlist = [
      {
        title: 'Live',
        description: 'Live',
        file: liveStreamUrl,
        type: 'live',
      },
    ];

    const handleTimeChange = (currentTime) => {
      if (time.getTime() !== currentTime.getTime()) {
        onTime(currentTime);
        setTime(currentTime);
      }
    };

    return (
      <VideoComponent
        {...(props as P)}
        isLive
        playlist={playlist}
        onTime={handleTimeChange}
        time={time}
      />
    );
  };

export default withLiveStream;
