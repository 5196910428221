import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Info, ShowChart } from '@onc/icons';
import {
  ExpansionSubMenu,
  IconButton,
  Dialog,
  DialogContent,
} from 'base-components';
import { GenericInfoDialog } from 'domain/AppComponents/dialogs/Dialogs';
import EarthquakeDetailPlot from 'domain/AppComponents/earthquake-detail/EarthquakeDetailPlot';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
  },
  grid: {
    padding: theme.spacing(1),
  },
  dialogStyle: {
    width: 'auto',
    display: 'block',
  },
});

const orgHeaders = [
  { title: 'Organization', name: 'organization' },
  { title: 'Origin Time', name: 'originTime' },
  { title: 'Epicentre', name: 'epicentre' },
  { title: 'Magnitude', name: 'magnitude' },
  { title: 'Event Id', name: 'eventId' },
  { title: 'Magnitude Evolution', name: 'icons' },
];

const orgColumnSizeInfo = [
  { columnName: 'organization', width: ColumnInfo.small },
  { columnName: 'originTime', width: ColumnInfo.medium },
  { columnName: 'epicentre', width: ColumnInfo.medium },
  { columnName: 'magnitude', width: ColumnInfo.medium },
  { columnName: 'eventId', width: ColumnInfo.medium },
  { columnName: 'icons', width: ColumnInfo.mini },
];

const disabledSort = [{ columnName: 'icons', sortingEnabled: false }];

class EarthquakeOrganizationDetailTable extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    earthquakeDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    oncDetections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    selectedEarthquake: PropTypes.string,
  };

  static defaultProps = { selectedEarthquake: undefined };

  constructor(props) {
    super(props);
    this.state = {
      openInfoDialog: false,
      openPlot: false,
    };
  }

  handleOpenInfoDialog = (event) => {
    event.stopPropagation();
    this.setState({ openInfoDialog: true });
  };

  renderTableRows = () => {
    const { earthquakeDetails } = this.props;
    return earthquakeDetails.map((row) => ({
      ...row,
      originTime: DateFormatUtils.formatDate(row.originTime, 'full'),
      epicentre: `${row.latitude},${row.longitude}`,
      eventId: row.eventUrl ? (
        <a href={row.eventUrl} target="_blank" rel="noreferrer noopener">
          {row.eventId}
        </a>
      ) : (
        row.eventId
      ),
      icons: this.icons(row),
    }));
  };

  icons = (row) => {
    if (row.organization !== 'Ocean Networks Canada Society') {
      return null;
    }

    return (
      <IconButton
        onClick={() => {
          this.setState({
            openPlot: true,
          });
        }}
        aria-label="Show Graph"
        Icon={ShowChart}
      />
    );
  };

  renderSelectedRow = () => {
    const { earthquakeDetails, selectedEarthquake } = this.props;
    if (earthquakeDetails) {
      const index = earthquakeDetails
        .map((row) => row.earthquakeDetailId)
        .indexOf(selectedEarthquake);
      if (index >= 0) {
        return [index];
      }
    }
    return [];
  };

  render() {
    const { openInfoDialog, openPlot } = this.state;
    const { earthquakeDetails, oncDetections, classes } = this.props;
    return (
      <>
        <Dialog
          open={openPlot}
          onClose={() => this.setState({ openPlot: false })}
          fullWidth
        >
          <DialogContent className={classes.dialogStyle}>
            <EarthquakeDetailPlot
              oncDetections={oncDetections}
              organizationDetections={earthquakeDetails}
            />
          </DialogContent>
        </Dialog>
        <GenericInfoDialog
          open={openInfoDialog}
          onClose={() => this.setState({ openInfoDialog: false })}
          title="Organization Table Information"
          message="This table shows how ONC, NRCan, and USGS organizations reported an earthquake.
           Different organization earthquake detections are determined to be the same earthquake
           if their origin times are within one minute, and epicentres are within 80km."
        />
        <ExpansionSubMenu
          menuTitle="Organization Detections"
          expand
          infoContent={
            <IconButton
              Icon={Info}
              onClick={this.handleOpenInfoDialog}
              aria-label="Filter Information"
            />
          }
        >
          <SortableTable
            columnSizes={orgColumnSizeInfo}
            pageSize={10}
            columns={orgHeaders}
            rows={this.renderTableRows()}
            highlighted
            selection={this.renderSelectedRow()}
            disabledSort={disabledSort}
          />
        </ExpansionSubMenu>
      </>
    );
  }
}

export default withStyles(styles)(
  withSnackbars(EarthquakeOrganizationDetailTable)
);
