import { useEffect, useState } from 'react';
import { Add } from '@onc/icons';
import { type DropdownOption } from 'base-components';
import SynchronizerManagementService from 'domain/services/SynchronizerManagementService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import CreateDeploymentDialog from './CreateDeploymentDialog';
import { SyncForm } from './CreateDeploymentForm';
import DeploymentsTable from './DeploymentsTable';
import DeploymentsTableLogic, {
  DeploymentTableRow,
} from './DeploymentsTableLogic';
import {
  DFO_DATA,
  NOAA_DATA,
  ONC_DATA,
} from '../organization-details/OrganizationServiceData';

const DeploymentsTableWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;
  const { onError } = useSnackbars();
  const [initialDeployment, setInitialDeployment] =
    useState<SyncForm>(undefined);

  const [deployments, loadingDeployments, fetchDeployments] = useWebService({
    method: SynchronizerManagementService.getActiveAndFutureDeployments,
    onError,
    parser: DeploymentsTableLogic.formatRows,
  });
  const [synchronizers, , fetchSynchronizers] = useWebService({
    method: SynchronizerManagementService.getSynchronizers,
    parser: (data) =>
      data.sort((a, b) => a.synchronizerName.localeCompare(b.synchronizerName)),
  });
  const [mqLocations, , fetchMqLocations] = useWebService({
    method: SynchronizerManagementService.getMqLocations,
    parser: (data) =>
      data.sort((a, b) => a.mqLocationCode.localeCompare(b.mqLocationCode)),
  });

  useEffect(() => {
    fetchDeployments();
    fetchMqLocations();
    fetchSynchronizers({ excludedTypes: 4 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [mqLocationOptions, setMqLocationOptions] =
    useState<DropdownOption[]>();
  const [synchronizerOptions, setSynchronizerOptions] =
    useState<DropdownOption[]>();
  const [isDeploymentDialogOpen, setDeploymentDialogOpen] = useState(false);
  const CreateDeploymentButton = (
    <ContainedButton
      translationKey="synchronizer.createDeployment"
      startIcon={<Add />}
      onClick={() => setDeploymentDialogOpen(true)}
    />
  );

  useEffect(() => {
    if (mqLocations) {
      setMqLocationOptions(
        mqLocations.map((loc) => ({
          key: loc.mqLocationCode,
          label: loc.mqLocationCode,
          value: loc.mqLocationId,
        }))
      );
    }
    if (synchronizers) {
      setSynchronizerOptions(
        synchronizers.map((sync) => ({
          key: sync.synchronizerName,
          label: sync.synchronizerName,
          value: sync.synchronizerId,
        }))
      );
    }
  }, [mqLocations, synchronizers]);

  const handleEditClick = (row: DeploymentTableRow) => {
    setDeploymentDialogOpen(true);
    setInitialDeployment(DeploymentsTableLogic.tableRowToSyncForm(row));
  };

  const handleDialogClose = () => {
    setDeploymentDialogOpen(false);
    setInitialDeployment(undefined);
  };

  const handleDialogSave = () => {
    setDeploymentDialogOpen(false);
    setInitialDeployment(undefined);
    fetchDeployments();
  };

  return (
    <>
      <Widget
        key={id}
        title="Deployments Table"
        loading={loadingDeployments}
        ariaLabel="deployments-table-widget"
        actionComponents={
          Environment.getDmasUserPrivilege() === 'RW'
            ? [CreateDeploymentButton]
            : []
        }
        {...props}
      >
        <DeploymentsTable rows={deployments || []} onEdit={handleEditClick} />
      </Widget>
      <CreateDeploymentDialog
        synchronizers={synchronizerOptions}
        organizations={[NOAA_DATA, ONC_DATA, DFO_DATA]
          .map((organization) => ({
            key: organization.organizationName,
            label: organization.organizationName,
            value: organization.organizationId,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))}
        mqlocations={mqLocationOptions}
        open={isDeploymentDialogOpen}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        initialValue={initialDeployment}
      />
    </>
  );
};

DeploymentsTableWidget.widgetKey = 'deployments-table';
DeploymentsTableWidget.widgetTitle = 'Deployments Table';
DeploymentsTableWidget.defaultDataGrid = {
  i: 'deployments-table',
  x: 0,
  y: 0,
  w: 12,
  h: 9,
};

export default DeploymentsTableWidget;
