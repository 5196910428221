import { FC, useEffect, useState } from 'react';
import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import { Earthquake } from '../EarthquakeData';

interface ActivityHistorgramProps {
  earthquakes: Earthquake[];
  initialGroupBy: 'Day' | 'Hour' | 'Minute';
}

const Plot = createPlotlyComponent(Plotly);

const ActivityHistorgram: FC<ActivityHistorgramProps> = ({
  earthquakes,
  initialGroupBy,
}) => {
  const [groupBy, setGroupBy] = useState<'Day' | 'Hour' | 'Minute'>(
    initialGroupBy
  );
  const [xRange, setXRange] = useState<[string | null, string | null]>([
    null,
    null,
  ]);

  useEffect(() => {
    setGroupBy(initialGroupBy);
  }, [initialGroupBy]);

  if (!earthquakes) {
    return <></>;
  }

  // Function to count earthquakes per day
  const countEarthquakes = () => {
    const counts: Record<string, number> = {};
    earthquakes.forEach((earthquake) => {
      const date = new Date(earthquake.originTime);
      let binKey;
      switch (groupBy) {
        case 'Minute':
          binKey = date.toISOString().slice(0, 16); // YYYY-MM-DDTHH:mm
          break;
        case 'Hour':
          binKey = date.toISOString().slice(0, 13); // YYYY-MM-DDTHH
          break;
        case 'Day':
        default:
          [binKey] = date.toISOString().split('T'); // YYYY-MM-DD
          break;
      }

      counts[binKey] = (counts[binKey] || 0) + 1;
    });
    return counts;
  };

  const handleRelayout = (event: any) => {
    let start;
    let end;
    if (event['xaxis.range'] && event['xaxis.range'].length === 2) {
      // this happens when you move the range slider with the mouse
      [start, end] = event['xaxis.range'];
    } else if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      // this happens when you press a range selector button
      start = event['xaxis.range[0]'];
      end = event['xaxis.range[1]'];
    }
    if (!start || !end) {
      setGroupBy('Day');
      setXRange([null, null]);
      return;
    }
    const rangeStart = new Date(start);
    const rangeEnd = new Date(end);
    const timeDiff =
      (rangeEnd.getTime() - rangeStart.getTime()) / (1000 * 60 * 60); // Difference in hours

    // Adjust grouping logic based on the visible range
    if (timeDiff <= 6) {
      setGroupBy('Minute'); // Group by minute for 5 hours or less
    } else if (timeDiff <= 7 * 24) {
      setGroupBy('Hour'); // Group by hour for 7 days or less
    } else {
      setGroupBy('Day'); // Default to group by day
    }
    setXRange([start, end]);
  };

  const earthquakeCounts = countEarthquakes();
  const xData = Object.keys(earthquakeCounts);
  const yData = Object.values(earthquakeCounts);
  return (
    <Plot
      data={[
        {
          x: xData,
          y: yData,
          name: 'Earthquakes Detected',
          type: 'bar',
          marker: {
            color: 'blue', // Adjust color as needed
          },
        },
      ]}
      layout={{
        autosize: true,
        title: 'Histogram of Earthquakes Activity',
        xaxis: {
          title: groupBy,
          rangeselector: {
            buttons: [
              {
                step: 'day',
                stepmode: 'backward',
                count: 1,
                label: '1d',
              },
              {
                step: 'day',
                stepmode: 'backward',
                count: 7,
                label: '1w',
              },
              {
                step: 'month',
                stepmode: 'backward',
                count: 1,
                label: '1m',
              },
              {
                step: 'month',
                stepmode: 'backward',
                count: 6,
                label: '6m',
              },
              {
                step: 'year',
                stepmode: 'todate',
                count: 1,
                label: 'YTD',
              },
              {
                step: 'year',
                stepmode: 'backward',
                count: 1,
                label: '1y',
              },
              {
                step: 'all',
              },
            ],
          },
          rangeslider: {},
          type: 'date',
          range: xRange,
        },
        yaxis: {
          title: 'Number of Earthquakes',
        },
        legend: {
          traceorder: 'normal',
          itemsizing: 'constant',
          itemwidth: 20,
          bgcolor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white background
          bordercolor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black border
          borderwidth: 1, // Border width
        },
      }}
      onRelayout={handleRelayout}
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default ActivityHistorgram;
