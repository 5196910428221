import { Typography } from 'base-components';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import TreeMenuItem from 'library/CompositeComponents/tree-menu/TreeMenuItem';
import DeckLogMenuItem from './DeckLogMenuItem';
import DiveMenuItem from './DiveMenuItem';
import TreeActionContent from './TreeActionContent';
import { CruiseTreeNode, DeckLogTreeNode, DiveTreeNode } from './VideoTreeItem';

type Props = {
  item: CruiseTreeNode;
  path: string[];
  onMenuItemClicked: (
    path: (string | { expeditionId: number })[],
    id: number,
    ready?: boolean
  ) => void;
  onSearchButtonClicked: () => void;
  onWatchButtonClicked: () => void;
  onDeckLogButtonClicked: () => void;
  selectedNodeId: number;
};

const CruiseMenuItem = ({
  item,
  path,
  onMenuItemClicked,
  onSearchButtonClicked,
  onWatchButtonClicked,
  onDeckLogButtonClicked,
  selectedNodeId,
}: Props) => {
  const newPath = [...path, { expeditionId: item.id }];

  const renderDeckLogNode = (child: DeckLogTreeNode) => (
    <DeckLogMenuItem
      key={`${child.xtype} - ${child.id}`}
      onDeckLogButtonClicked={onDeckLogButtonClicked}
      item={child}
      path={newPath}
      expeditionId={item.id}
      selectedNodeId={selectedNodeId}
      onMenuItemClicked={onMenuItemClicked}
    />
  );

  const renderDiveNode = (child: DiveTreeNode) => (
    <DiveMenuItem
      key={`${child.xtype} - ${child.id}`}
      item={child}
      path={newPath}
      expeditionId={item.id}
      selectedNodeId={selectedNodeId}
      onMenuItemClicked={onMenuItemClicked}
      onSearchButtonClicked={onSearchButtonClicked}
      onWatchButtonClicked={onWatchButtonClicked}
    />
  );

  return (
    <TreeMenuItem
      label={
        <div style={{ display: 'flex' }}>
          <TreeActionContent
            onSearchButtonClicked={onSearchButtonClicked}
            resourceTypeId={SeaTubeResourceTypes.EXPEDITION}
            resourceId={item.id}
          />
          <Typography variant="body1" style={{ margin: 'auto 0' }}>
            {item.html}
          </Typography>
        </div>
      }
      key={item.id}
      selected={selectedNodeId === item.nodeId}
      onClick={() => onMenuItemClicked(newPath, item.nodeId)}
    >
      {item.children.map((child) => {
        switch (child.xtype) {
          case 'DIVE':
            return renderDiveNode(child as DiveTreeNode);

          case 'DECK LOG':
            return renderDeckLogNode(child as DeckLogTreeNode);

          default:
            return undefined;
        }
      })}
      {}
    </TreeMenuItem>
  );
};

export default CruiseMenuItem;
