import * as React from 'react';
import { ResponsiveProps } from 'react-grid-layout';
import { GridItem, OncLayouts } from './DashboardTypes';
import ResponsiveGridLayoutWrapper from './ResponsiveGridLayout';

const BREAKPOINTS = { xl: 1400, lg: 1200, md: 996, sm: 768, xs: 480 };
const COLS = { lg: 12, md: 10, sm: 8, xs: 6, xxs: 4 };
const CONTAINER_ID = 'dashboard-container';

interface DashboardLayoutProps extends ResponsiveProps {
  onLayoutChange: (currentLayout: GridItem[], allLayouts: OncLayouts) => void;
  layouts: OncLayouts;
  children: React.ReactNode;
  rowHeight: number;
  isStatic?: boolean;
}

const DashboardLayout: React.VFC<DashboardLayoutProps> = ({
  children,
  cols = COLS,
  breakpoints = BREAKPOINTS,
  isStatic = false,
  ...props
}: ResponsiveProps) => (
  <div id={CONTAINER_ID}>
    <ResponsiveGridLayoutWrapper
      cols={cols}
      breakpoints={breakpoints}
      isDraggable={!isStatic}
      isResizable={!isStatic}
      draggableCancel=".onc-panel-content, [role='presentation'], [role='dialog'], [role='tooltip']"
      style={{
        backgroundColor: '#EEE',
      }}
      {...props}
    >
      {children}
    </ResponsiveGridLayoutWrapper>
  </div>
);

export default DashboardLayout;
