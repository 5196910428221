import {
  Grouping,
  TableColumnWidthInfo,
  Sorting,
} from '@devexpress/dx-react-grid';
import { TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import {
  getEmptyFilter,
  TableFilter,
} from 'library/BaseComponents/table/plugins/filter/ToolbarFilter';

export type TableConfigProps = {
  columnOrder: string[];
  columnWidths: TableColumnWidthInfo[];
  grouping: Grouping[];
  sorting: Sorting[];
  hiddenColumnNames: string[];
  filter: TableFilter;
  resizingExtensions: TableColumnResizing.ColumnExtension[];
};

const defaultColumnOrder = [
  'cruiseName',
  'logName',
  'startDate',
  'createdBy',
  'createdDate',
  'modifiedBy',
  'modifiedDate',
  'taxonomy',
  'taxon',
  'attributes',
  'comment',
  'toBeReviewed',
  'numPositiveReviews',
  'numTotalReviews',
  'lat',
  'lon',
  'depth',
  'heading',
  'actions',
];

const FIXED_DATE_WIDTH = 180;
const LAT_LON_WIDTH = 150;
const ACTIONS_WIDTH = 175;
const DEPTH_WIDTH = 120;
const HEADING_WIDTH = 130;

const defaultColumnWidth = [
  { columnName: 'logName', width: 200 },
  { columnName: 'cruiseName', width: 200 },
  { columnName: 'startDate', width: FIXED_DATE_WIDTH },
  { columnName: 'createdBy', width: 200 },
  { columnName: 'createdDate', width: FIXED_DATE_WIDTH },
  { columnName: 'modifiedBy', width: 200 },
  { columnName: 'modifiedDate', width: FIXED_DATE_WIDTH },
  { columnName: 'taxonomy', width: 200 },
  { columnName: 'taxon', width: 200 },
  { columnName: 'attributes', width: 400 },
  { columnName: 'comment', width: 400 },
  { columnName: 'toBeReviewed', width: 200 },
  { columnName: 'numPositiveReviews', width: 200 },
  { columnName: 'numTotalReviews', width: 200 },
  { columnName: 'lat', width: LAT_LON_WIDTH },
  { columnName: 'lon', width: LAT_LON_WIDTH },
  { columnName: 'depth', width: DEPTH_WIDTH },
  { columnName: 'heading', width: HEADING_WIDTH },
  { columnName: 'actions', width: ACTIONS_WIDTH },
];

const resizingExtensions = [
  {
    columnName: 'startDate',
    minWidth: FIXED_DATE_WIDTH,
    maxWidth: FIXED_DATE_WIDTH,
  },
  {
    columnName: 'createdDate',
    minWidth: FIXED_DATE_WIDTH,
    maxWidth: FIXED_DATE_WIDTH,
  },
  {
    columnName: 'modifiedDate',
    minWidth: FIXED_DATE_WIDTH,
    maxWidth: FIXED_DATE_WIDTH,
  },
  {
    columnName: 'actions',
    minWidth: ACTIONS_WIDTH,
    maxWidth: ACTIONS_WIDTH,
  },
  {
    columnName: 'lat',
    minWidth: LAT_LON_WIDTH,
    maxWidth: LAT_LON_WIDTH,
  },
  {
    columnName: 'lon',
    minWidth: LAT_LON_WIDTH,
    maxWidth: LAT_LON_WIDTH,
  },
  {
    columnName: 'depth',
    minWidth: DEPTH_WIDTH,
    maxWidth: DEPTH_WIDTH,
  },
  {
    columnName: 'heading',
    minWidth: HEADING_WIDTH,
    maxWidth: HEADING_WIDTH,
  },
];

const defaultHidden = [
  'lat',
  'lon',
  'depth',
  'heading',
  'createdBy',
  'createdDate',
  'modifiedBy',
  'modifiedDate',
  'toBeReviewed',
  'numPositiveReviews',
  'numTotalReviews',
];

const defaultConfig: TableConfigProps = {
  columnOrder: defaultColumnOrder,
  columnWidths: defaultColumnWidth,
  grouping: [],
  sorting: [{ columnName: 'startDate', direction: 'asc' }],
  hiddenColumnNames: defaultHidden,
  filter: getEmptyFilter(),
  resizingExtensions,
};

export default defaultConfig;
