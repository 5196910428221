import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { WarningAlert } from '@onc/composite-components';
import EarthquakeDetailService from 'domain/AppComponents/earthquake-detail/service/EarthquakeDetailService';
import PageWithPadding from 'library/CompositeComponents/page/PageWithPadding';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import EarthquakeDetailMap from '../../AppComponents/earthquake-detail/EarthquakeDetailMap';
import EarthquakeDetectionDetailTable from '../../AppComponents/earthquake-detail/EarthquakeDetectionDetailTable';
import EarthquakeOrganizationDetailTable from '../../AppComponents/earthquake-detail/EarthquakeOrganizationDetailTable';
import EarthquakeSiteDetailTable from '../../AppComponents/earthquake-detail/EarthquakeSiteDetailTable';

class EarthquakeDetailPage extends PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    const params = this.parseURLParams();
    this.state = {
      organizationDetections: [],
      oncDetections: [],
      siteDetections: [],
      mapMarkers: [],
      detectionArea: [],
      mmiContours: {},
      reprocessed: false,
      earthquakeId: Number(params.earthquakeId),
      selectedEarthquake: undefined,
      selectedOncDetection: undefined,
    };
  }

  componentDidMount() {
    this.getEarthquakeDetails();
  }

  parseURLParams = () => {
    const { history } = this.props;

    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  };

  getEarthquakeDetails = () => {
    const { onError } = this.props;
    const { earthquakeId } = this.state;
    EarthquakeDetailService.getEarthquakeDetails(earthquakeId)
      .then((payload) => {
        this.setState({
          organizationDetections: payload.organizationDetections,
          oncDetections: payload.oncDetections,
          siteDetections: payload.siteDetections,
          mapMarkers: payload.mapMarkers,
          earthquakes: payload.earthquake,
          reprocessed: payload.reprocessedEarthquake,
          mmiContours: payload.mmiContour,
          epicentre: payload.epicentre,
          detectionArea: payload.detectionArea,
        });
      })
      .catch(() => {
        onError('failed to load earthquakes');
      });
  };

  additionalInfo = () => {
    const { reprocessed, oncDetections } = this.state;
    if (reprocessed) {
      return (
        <WarningAlert title="Reprocessed Earthquake">
          This earthquake was generated by re-running site data through the
          associator software as it was on{' '}
          {oncDetections[0].reportedTime.split('T')[0]}. Any PNSN data that may
          have been included at the time was not included in the re-run. The
          reported time field is inaccurate it only serves to show the ordering
          the event progressed in.
        </WarningAlert>
      );
    }
    return null;
  };

  onEarthquakeMarkerClick = (eq) => {
    const selectedEarthquake = eq.earthquakeDetailId;
    this.setState({ selectedEarthquake });
  };

  onOncDetailClick = (selectedOncDetection) => {
    this.setState({ selectedOncDetection });
  };

  render() {
    const {
      organizationDetections,
      oncDetections,
      siteDetections,
      earthquakes,
      mmiContours,
      epicentre,
      selectedEarthquake,
      mapMarkers,
      detectionArea,
      selectedOncDetection,
    } = this.state;
    return (
      <PageWithPadding>
        {this.additionalInfo()}
        <EarthquakeDetailMap
          siteMarkers={mapMarkers}
          earthquakes={earthquakes}
          mmiContours={mmiContours}
          epicentre={epicentre}
          detectionArea={detectionArea}
          selectedOncDetection={selectedOncDetection}
          onEarthquakeMarkerClick={this.onEarthquakeMarkerClick}
        />
        <EarthquakeOrganizationDetailTable
          earthquakeDetails={organizationDetections}
          selectedEarthquake={selectedEarthquake}
          oncDetections={oncDetections}
        />
        <EarthquakeDetectionDetailTable
          earthquakeDetails={oncDetections}
          handleDetailClick={this.onOncDetailClick}
        />
        <EarthquakeSiteDetailTable earthquakeDetails={siteDetections} />
      </PageWithPadding>
    );
  }
}

export default withSnackbars(EarthquakeDetailPage);
