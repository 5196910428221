import { memo } from 'react';
import * as React from 'react';
import MaterialDialogActions, {
  DialogActionsProps,
} from '@mui/material/DialogActions';

const DialogActions: React.VFC<DialogActionsProps> = (
  props: DialogActionsProps
) => <MaterialDialogActions {...props} />;

export default memo(DialogActions);
