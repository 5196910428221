import { OncLayouts } from 'library/CompositeComponents/dashboard/DashboardTypes';

const defaultUserLayout: OncLayouts = {
  lg: [
    {
      w: 5,
      h: 12,
      x: 3,
      y: 5,
      i: 'button-set-1',
    },
    {
      w: 4,
      h: 10,
      x: 8,
      y: 0,
      i: 'annotation-list-1',
    },
    {
      w: 4,
      h: 14,
      x: 8,
      y: 4,
      i: 'manual-entry-1',
    },
    {
      w: 5,
      h: 12,
      x: 3,
      y: 0,
      i: 'dive-video-1',
    },
    {
      w: 3,
      h: 12,
      x: 0,
      y: 5,
      i: 'sensor-readings',
    },
    { w: 3, h: 12, x: 0, y: 0, i: 'map' },
  ],
  md: [
    {
      w: 10,
      h: 7,
      x: 0,
      y: 12,
      i: 'button-set-1',
    },
    {
      w: 5,
      h: 17,
      x: 0,
      y: 5,
      i: 'annotation-list-1',
    },
    {
      w: 5,
      h: 17,
      x: 5,
      y: 5,
      i: 'manual-entry-1',
    },
    {
      w: 5,
      h: 12,
      x: 0,
      y: 0,
      i: 'dive-video-1',
    },
    {
      w: 10,
      h: 10,
      x: 0,
      y: 15,
      i: 'sensor-readings',
    },
    { w: 5, h: 12, x: 5, y: 0, i: 'map' },
  ],
  sm: [
    {
      w: 8,
      h: 7,
      x: 0,
      y: 17,
      i: 'button-set-1',
    },
    {
      w: 8,
      h: 12,
      x: 0,
      y: 6,
      i: 'annotation-list-1',
    },
    {
      w: 8,
      h: 14,
      x: 0,
      y: 11,
      i: 'manual-entry-1',
    },
    {
      w: 8,
      h: 14,
      x: 0,
      y: 0,
      i: 'dive-video-1',
    },
    {
      w: 8,
      h: 10,
      x: 0,
      y: 20,
      i: 'sensor-readings',
    },
    { w: 8, h: 10, x: 0, y: 24, i: 'map' },
  ],
  xs: [
    {
      w: 6,
      h: 10,
      x: 0,
      y: 16,
      i: 'button-set-1',
    },
    {
      w: 6,
      h: 14,
      x: 0,
      y: 5,
      i: 'annotation-list-1',
    },
    {
      w: 6,
      h: 12,
      x: 0,
      y: 11,
      i: 'manual-entry-1',
    },
    {
      w: 6,
      h: 12,
      x: 0,
      y: 0,
      i: 'dive-video-1',
    },
    {
      w: 6,
      h: 10,
      x: 0,
      y: 20,
      i: 'sensor-readings',
    },
    { w: 6, h: 14, x: 0, y: 24, i: 'map' },
  ],
};

export default defaultUserLayout;
