import _ from 'lodash';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import { GridItem, OncLayouts, WidgetOption } from './DashboardTypes';

class DashboardLogic {
  static getUniqueIndex = (currentItems: GridItem[], name: string) => {
    let index = 1;
    // eslint-disable-next-line no-loop-func
    while (currentItems.find((item) => item.i === `${name}-${index}`)) {
      index += 1;
    }
    return index;
  };

  static cleanupWidgets = (
    widgets: GridItem[],
    widgetLibrary: WidgetLibrary
  ) => {
    const newWidgets = _.cloneDeep(widgets);
    return newWidgets.filter((widget) => widgetLibrary.has(widget.widgetKey));
  };

  static cleanupLayouts = (oldLayouts: OncLayouts, widgets: GridItem[]) => {
    const newLayouts = _.cloneDeep(oldLayouts);
    for (const [key, value] of Object.entries(newLayouts)) {
      newLayouts[key] = value.filter((layout: GridItem) =>
        widgets.find((widget) => layout.i === widget.i)
      );
    }
    return newLayouts;
  };

  static buildInitialWidgets = (
    widgetConfig: any,
    widgetLibrary: WidgetLibrary
  ) => {
    const widgets: GridItem[] = [];
    widgetConfig.forEach((config) => {
      const Widget = widgetLibrary.getWidgetByKey(config.widgetKey);
      if (Widget) {
        widgets.push({ ...Widget.defaultDataGrid, ...config });
      }
    });
    return widgets;
  };

  static buildWidgetsFromLayout = (
    layouts: OncLayouts,
    existingWidgets: GridItem[],
    widgetLibrary: WidgetLibrary
  ) => {
    const widgets: GridItem[] = [];
    for (const [, value] of Object.entries(layouts)) {
      value.forEach((widget: GridItem) => {
        // return if widget already in the array
        if (widgets.find((item) => item.i === widget.i)) return;
        // try to replace it if it already exists
        const existingWidget = existingWidgets.find(
          (item) => item.i === widget.i
        );
        if (existingWidget) {
          widgets.push(existingWidget);
          return;
        }

        // Generate a new widget if it doesn't exist
        const Widget = widgetLibrary.getWidgetByGridKey(widget.i);
        if (Widget && !widgets.find((item) => item.i === widget.i)) {
          widgets.push({
            ...Widget.defaultDataGrid,
            widgetKey: Widget.widgetKey,
            title: Widget.widgetTitle,
            config: Widget.defaultConfig,
            ...widget,
          });
        }
      });
    }
    return widgets;
  };

  static filterWidgetOptions = (
    items: GridItem[],
    widgetLibrary: WidgetLibrary
  ) => {
    const filteredOptions: WidgetOption[] = [];
    widgetLibrary.getWidgetOptions().forEach((option) => {
      if (option.multiple) {
        return filteredOptions.push(option);
      }
      if (
        !items?.find((item) => item.widgetKey === option.Component.widgetKey)
      ) {
        return filteredOptions.push(option);
      }
      return undefined;
    });
    return filteredOptions;
  };
}

export default DashboardLogic;
