import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type DropdownOption,
  LoadingButton,
} from 'base-components';
import SynchronizerManagementService from 'domain/services/SynchronizerManagementService';
import { CancelButton } from 'library/CompositeComponents/button/Buttons';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import ObjectUtils from 'util/ObjectUtils';
import CreateDeploymentForm, {
  SyncForm,
  SyncFormErrors,
} from './CreateDeploymentForm';

const EMPTY_FORM: SyncForm = {
  deploymentId: null,
  synchronizerId: null,
  organizationId: null,
  mqlocationId: null,
  startNow: false,
  dateFrom: null,
  dateTo: null,
};

type Props = {
  mqlocations: DropdownOption[];
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  organizations: DropdownOption[];
  synchronizers: DropdownOption[];
  initialValue?: SyncForm;
};

const CreateDeploymentDialog = ({
  mqlocations,
  organizations,
  synchronizers,
  open,
  onClose,
  onSave,
  initialValue = EMPTY_FORM,
}: Props) => {
  const [value, setValue] = useState<SyncForm>(initialValue);
  const [errors, setErrors] = useState<SyncFormErrors>({});
  const [isSaving, setIsSaving] = useState(false);
  const { onError, onInfo } = useSnackbars();
  const [, , createDeployment] = useWebService({
    method: SynchronizerManagementService.createDeployment,
    onError,
  });
  const [, , updateDeployment] = useWebService({
    method: SynchronizerManagementService.updateDeployment,
    onError,
  });
  const isEditMode = !!value.deploymentId;

  const handleError = (key: keyof SyncForm, error: string) => {
    setErrors((prev) => ({ ...prev, [key]: error }));
  };
  useEffect(() => {
    setValue(initialValue || EMPTY_FORM);
  }, [initialValue]);

  const handleChange = (key: keyof SyncForm, val) => {
    setValue((prev) => ({ ...prev, [key]: val }));
    if (key === 'dateFrom' || key === 'dateTo') {
      return;
    }
    if (key === 'startNow') {
      setErrors((prev) => ({
        ...prev,
        dateFrom: '',
      }));
      if (val) {
        setValue((prev) => ({
          ...prev,
          dateFrom: null,
        }));
      }
    } else {
      handleError(key, undefined);
    }
  };

  const handleCancel = () => {
    setValue(EMPTY_FORM);
    setErrors({});
    onClose();
  };

  const getFormErrors = () => {
    const newErrors: SyncFormErrors = {};
    if (value.synchronizerId === null) {
      newErrors.synchronizerId = 'Synchronizer is required';
    }
    if (!value.organizationId) {
      newErrors.organizationId = 'Organization is required';
    }
    if (!value.mqlocationId) {
      newErrors.mqlocationId = 'Location is required';
    }
    if (!value.dateFrom && !value.startNow) {
      newErrors.dateFrom = 'Date From is required';
    }
    if (!value.dateTo) {
      newErrors.dateTo = 'Date To is required';
    }
    setErrors(newErrors);
    return newErrors;
  };

  const getDeploymentData = () => {
    const data = isEditMode
      ? ObjectUtils.getChangedFields(initialValue, value)
      : value;

    return {
      synchronizerId: data.synchronizerId,
      organizationId: data.organizationId,
      locationId: data.mqlocationId,
      startNow: data.startNow,
      dateFrom: data.dateFrom?.toISOString(),
      dateTo: data.dateTo?.toISOString(),
      isPrimary: false,
    };
  };

  const handleSave = () => {
    if (Object.keys(getFormErrors()).length > 0) {
      return;
    }
    setIsSaving(true);
    const performAction = () => {
      if (isEditMode) {
        return updateDeployment(value.deploymentId, getDeploymentData());
      }
      return createDeployment(getDeploymentData());
    };

    performAction()
      .then((result) => {
        if (result) {
          const actionMessage = isEditMode ? 'updated' : 'created';
          onInfo(`Deployment ${result.deploymentId} ${actionMessage}.`);
          onSave();
          setValue(EMPTY_FORM);
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const hasChanged = !ObjectUtils.isEqual(initialValue, value);

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="xs">
      <DialogTitle>{`${
        isEditMode ? 'Edit' : 'Create'
      } Synchronizer Deployment`}</DialogTitle>
      <DialogContent>
        <CreateDeploymentForm
          onChange={handleChange}
          onError={handleError}
          value={value}
          onSubmit={handleSave}
          syncOptions={synchronizers}
          organizations={organizations}
          locOptions={mqlocations}
          errors={errors}
          inProgress={
            isEditMode && initialValue?.dateFrom?.isBefore(moment.utc())
          }
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleCancel} />
        <LoadingButton
          loading={isSaving}
          variant="contained"
          translationKey="common.buttons.save"
          onClick={handleSave}
          disabled={
            !hasChanged || Object.keys(errors).some((key) => !!errors[key])
          }
        />
      </DialogActions>
    </Dialog>
  );
};
export default CreateDeploymentDialog;
