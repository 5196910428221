import { Dropdown, ErrorPage } from 'base-components';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';
import useGet from 'util/hooks/useDmasAPI/useGet';

type RegionDropdownProps = Omit<
  FormFieldType<React.ComponentProps<typeof Dropdown>>,
  'options'
>;

type RegionPayload = [
  {
    region: {
      regionName: string;
      regionId: number;
    };
  },
];

const RegionDropdown = ({ ...props }: RegionDropdownProps) => {
  const regionData = useGet<RegionPayload>('RegionService', 5);

  if (regionData.isLoading) {
    return <FormDropdown {...props} options={[]} />;
  }

  if (regionData.error) {
    return <ErrorPage />;
  }

  const allRegions = regionData.data?.sort((a, b) =>
    a.region.regionName.localeCompare(b.region.regionName)
  );

  const options = [{ label: 'All', value: -1, key: '-1' }].concat(
    allRegions?.map((option) => ({
      label: option.region.regionName,
      value: option.region.regionId,
      key: option.region.regionId.toString(),
    }))
  );

  return <FormDropdown options={options} {...props} />;
};

export default RegionDropdown;
