export interface ParsingMethod {
  label: string;
  regex: RegExp;
}

const separatorSpaceSquareTimeAngleUsernameMessage: ParsingMethod = {
  label: '[hh:mm:ss] <username> message',
  regex:
    /^\[(?<hour>\d{2}):(?<minute>\d{2}):(?<second>\d{2})\]\s+(?:<(?<username>[^<>]+)>\s+)?(?<message>.+)$/,
};

const separatorCommaMDYDateTimeUsernameMessage: ParsingMethod = {
  label: 'MM/dd/yyyy,hh:mm:ss,username,message',
  regex:
    /^(?<month>\d{2})\/(?<day>\d{2})\/(?<year>\d{4,}),(?<hour>\d{2}):(?<minute>\d{2}):(?<second>\d{2}),(?<username>[^,]+),(?<message>.+)$/,
};

const methods = [
  separatorSpaceSquareTimeAngleUsernameMessage,
  separatorCommaMDYDateTimeUsernameMessage,
];

class ChatLogIngestionParsingMethods {
  // there must be a better naming convention
  static separatorSpaceSquareTimeAngleUsernameMessage =
    separatorSpaceSquareTimeAngleUsernameMessage;

  static separatorCommaMDYDateTimeUsernameMessage =
    separatorCommaMDYDateTimeUsernameMessage;
  // name the groups with (?<groupName>...) to allow access by name rather than index

  static getByLabel = (label: string) =>
    methods.find((method) => method.label === label);
}

export default ChatLogIngestionParsingMethods;
