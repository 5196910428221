import { parseDmasAPIResponse, get } from 'util/WebRequest';

class ResourceService {
  static getResourcesByResourceTypeId(resourceTypeId: string) {
    return this.get({ resourceTypeId, query: '1' });
  }

  static getResourceTypeList() {
    return this.get({ query: '3' });
  }

  static getResource(resourceTypeId: string, resourceId: number) {
    return this.get({ resourceTypeId, resourceId, query: '5' });
  }

  static get(params: object) {
    return get(
      'ResourceService',
      {
        ...params,
      },
      {}
    ).then((response) => parseDmasAPIResponse(response));
  }
}

export default ResourceService;
