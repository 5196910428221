import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CardOld as Card,
  ExpandableContainer,
  Grid,
  LinearProgress,
} from 'base-components';
import TreeTable from 'library/CompositeComponents/table/TreeTable';
import Environment from 'util/Environment';

import LiveDafTestService from '../LiveDafTestService';
import Status from '../Status';
import StatusIcon from '../StatusIcon';

class TestDetails extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    classes: PropTypes.shape({
      list: PropTypes.string,
    }),
  };

  static defaultProps = {
    classes: undefined,
  };

  // If there is a step running, display that step name and the message if it has one
  // If there is an error and no step running, display the error message
  // If there are multiple errors in the stage, display "Multiple errors have occured"
  determineStageMessage = (steps) => {
    let message;
    for (const step of steps) {
      if (step.status === Status.RUNNING) {
        message = step.name;
        if (step.comment) {
          message += ` - ${step.comment}`;
        }
        break;
      }
      if (step.status === Status.ERROR) {
        if (message !== undefined) {
          message = 'Multiple errors have occured';
        } else {
          message = step.comment;
        }
      }
    }
    return message;
  };

  // Returns the highest priority status. In this case:
  // Running < Error < Completed < Skipped < Pending
  determineStageStatus = (steps) => {
    if (steps.some((step) => step.status === Status.RUNNING)) {
      return Status.RUNNING;
    }
    if (steps.some((step) => step.status === Status.ERROR)) {
      return Status.ERROR;
    }
    if (steps.some((step) => step.status === Status.COMPLETED)) {
      return Status.COMPLETED;
    }
    if (steps.some((step) => step.status === Status.SKIPPED)) {
      return Status.SKIPPED;
    }
    return Status.PENDING;
  };

  getExpandedRowIds = (rows) => {
    const expandedRowIds = [];
    rows.forEach((stage) => {
      if (
        stage.status.props.status !== Status.COMPLETED &&
        stage.status.props.status !== Status.SKIPPED
      ) {
        expandedRowIds.push(stage.id);
      }
    });

    return expandedRowIds;
  };

  renderRowMessage = (step) => {
    const comment = step.comment || null;
    const progress = step.timeElapsed ? (
      <LinearProgress
        variant="determinate"
        value={(step.timeElapsed.seconds / step.duration.seconds) * 100}
      />
    ) : null;

    return (
      <>
        {comment}
        {progress}
      </>
    );
  };

  state = { test: { comment: '' } };

  componentDidMount() {
    this.loadDetails();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  loadDetails() {
    const { match } = this.props;

    return LiveDafTestService.getOne(match.params.id)
      .then((response) => {
        this.setState({
          test: {
            ...response,
            duration: response.duration.seconds / 60,
          },
        });

        if (
          (response.status === Status.RUNNING ||
            response.status === Status.CANCELLING) &&
          !this.interval
        ) {
          this.interval = setInterval(() => this.loadDetails(), 1000);
        } else if (
          response.status !== Status.RUNNING &&
          response.status !== Status.CANCELLING &&
          this.interval
        ) {
          clearInterval(this.interval);
          this.interval = undefined;
        }
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  }

  renderTableRows() {
    const { test } = this.state;
    let i = -1;
    const steps = test.details.map((stage) => {
      i += 1;
      return {
        id: i,
        name: <b>{stage.stage}</b>,
        status: (
          <StatusIcon
            showText
            status={this.determineStageStatus(stage.steps)}
          />
        ),
        message: this.determineStageMessage(stage.steps),
        steps: stage.steps.map((step) => {
          i += 1;
          return {
            status: <StatusIcon showText status={step.status} />,
            message: this.renderRowMessage(step),
            name: step.name,
          };
        }),
      };
    });
    return steps;
  }

  renderPlots() {
    const { test } = this.state;
    return Object.keys(test.plotDetails)
      .sort((a, b) =>
        test.plotDetails[a].name > test.plotDetails[b].name ? 1 : -1
      )
      .map((sensorId) => (
        <Grid item key={sensorId} xs={12} md={6} lg={4}>
          <Card
            imageURL={`${Environment.getDmasUrl()}${
              test.plotDetails[sensorId].url
            }`}
            title={test.plotDetails[sensorId].name}
          />
        </Grid>
      ));
  }

  renderPlotContainer() {
    const { test } = this.state;
    if (test.plotDetails && Object.keys(test.plotDetails).length > 0) {
      return (
        <ExpandableContainer summary="Plots">
          <Grid container spacing={1}>
            {this.renderPlots()}
          </Grid>
        </ExpandableContainer>
      );
    }
    return null;
  }

  render() {
    const { test } = this.state;

    if (!test.dafTestId) {
      return null;
    }
    const columns = [
      { title: 'Status', name: 'status' },
      { title: 'Step', name: 'name' },
      { title: 'Message', name: 'message' },
    ];

    const columnExtensions = [
      { columnName: 'name', wordWrapEnabled: true },
      { columnName: 'status' },
      { columnName: 'message', wordWrapEnabled: true },
    ];

    const rows = this.renderTableRows();
    return (
      <>
        <Grid item xs={12}>
          <TreeTable
            columns={columns}
            rows={rows}
            columnExtensions={columnExtensions}
            defaultExpandedRowIds={this.getExpandedRowIds(rows)}
            getChildRows={(row, rootRows) => (row ? row.steps : rootRows)}
            treeColumn="name"
          />
        </Grid>
        <Grid item xs={12}>
          {this.renderPlotContainer()}
        </Grid>
      </>
    );
  }
}

export default TestDetails;
