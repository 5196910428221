import { useEffect, useState } from 'react';

import ItemTypeService from 'domain/services/ItemTypeService';

import FormAutocomplete, {
  FormAutocompleteProps,
} from 'library/CompositeComponents/form/FormAutocomplete';
import { useSnackbars } from 'util/hooks/useSnackbars';

type option = {
  label: string;
  value?: number;
};

type ItemTypeDropdownProps = Omit<FormAutocompleteProps, 'options'>;

const ItemTypeDropdown = ({
  translationKey = undefined,
  name = undefined,
  fullWidth = true,
}: ItemTypeDropdownProps) => {
  const [optionsLocal, setOptionsLocal] = useState<option[]>([]);
  const { onError } = useSnackbars();

  const getItemTypeOptions = () => {
    ItemTypeService.getMany()
      .then((response) => {
        const options = response.map((type) => ({
          label: `${type.name}`,
          value: type.itemTypeId,
        }));
        setOptionsLocal(
          [{ label: 'None', value: undefined }].concat(options.sort())
        );
      })
      .catch(() => onError('Failed to get Item Types'));
  };

  useEffect(getItemTypeOptions, [onError]);

  return (
    <FormAutocomplete
      name={name}
      translationKey={translationKey}
      fullWidth={fullWidth}
      options={optionsLocal}
    />
  );
};

export default ItemTypeDropdown;
