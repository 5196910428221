import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from 'base-components';
import SystemCommandSelector from './SystemCommandSelector';
import SystemCommandSelectorDetails from './SystemCommandSelectorDetails';

const classes = {
  root: {
    paddingBottom: 3,
    paddingTop: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  submitPaper: {
    // submit button returns outside paper otherwise
    paddingTop: 1,
  },
};

class SystemConsoleCommandPanel extends PureComponent {
  static propTypes = {
    systemcommand: PropTypes.string.isRequired,
    systemCommandList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    defaultArgs: PropTypes.shape({}).isRequired,
    args: PropTypes.objectOf(PropTypes.shape({})).isRequired,
    onParamChange: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    commandClassName: PropTypes.string.isRequired,
  };

  formatDropdown = (commands, extract, extract2) => {
    // if the second extract isn't defined, fallback to using the first one
    const labelExtractor = extract2 || extract;
    return commands.map((command) => ({
      value: extract(command),
      label: labelExtractor(command),
    }));
  };

  findSystemCommand = (systemcommand, commands) =>
    commands.find((command) => command.systemcommand === systemcommand);

  renderFormOptions = () => {
    const { systemcommand, systemCommandList, ...rest } = this.props;
    systemCommandList.sort((a, b) =>
      a.systemcommand.localeCompare(b.systemcommand)
    );
    if (systemcommand) {
      return (
        <Grid item xs={10}>
          <SystemCommandSelectorDetails
            sysCommand={this.findSystemCommand(
              systemcommand,
              systemCommandList
            )}
            {...rest}
          />
        </Grid>
      );
    }
    return null;
  };

  render() {
    const {
      systemcommand,
      commandClassName,
      systemCommandList,
      defaultArgs,
      ...rest
    } = this.props;

    // SystemConsoleService only returns options the user is allowed to execute
    if (systemCommandList.length <= 0) {
      return <></>;
    }

    return (
      <div>
        <Paper sx={classes.submitPaper}>
          <Typography sx={classes.root}>System Command</Typography>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <SystemCommandSelector
                commands={systemCommandList}
                defaultArgs={defaultArgs}
                value={`${commandClassName},${systemcommand}`}
                formatDropdown={this.formatDropdown}
                {...rest}
              />
            </Grid>
            {this.renderFormOptions()}
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default SystemConsoleCommandPanel;
