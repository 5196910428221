import { useState } from 'react';
import * as React from 'react';
import { FilterList } from '@onc/icons';
import { DenseThemeProvider } from '@onc/theme';
import DateFilter from 'domain/AppComponents/annotations/filters/DateFilter';
import FilterGroup from 'library/CompositeComponents/filter/FilterGroup';

interface SearchHistoryFilterProps {
  filterGroupExpanded: boolean;
  onSubmit: (event: any) => void;
}

const SearchHistoryFilter: React.VFC<SearchHistoryFilterProps> = ({
  filterGroupExpanded,
  onSubmit,
}: SearchHistoryFilterProps) => {
  const [expanded, setExpanded] = useState('resourceFilter');

  const handleExpand = (filterName: string) => {
    filterName === expanded ? setExpanded('') : setExpanded(filterName);
  };

  return (
    <DenseThemeProvider>
      <FilterGroup
        title="Search History Filter"
        name="Search History Filter"
        onSubmit={(event) => {
          setExpanded('');
          onSubmit(event);
        }}
        icon={<FilterList color="action" />}
        submitTranslationKey="common.search"
        expanded={filterGroupExpanded}
      >
        <DateFilter
          title="Query Date (UTC)"
          name="queryDateFilter"
          expanded={expanded === 'queryDateFilter'}
          onExpand={handleExpand}
        />
      </FilterGroup>
    </DenseThemeProvider>
  );
};
export default SearchHistoryFilter;
