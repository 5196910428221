import { Component } from 'react';
import PropTypes from 'prop-types';
import UserDefinedTasksWidgetConfig from './UserDefinedTasksWidgetConfig';
import UserDefinedTasksWidgetDisplay from './UserDefinedTasksWidgetDisplay';

class UserDefinedTasksWidget extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onConfigFinish: PropTypes.func.isRequired,
    showTitle: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    showDisplay: PropTypes.bool.isRequired,
    selectedTaskDefinition: PropTypes.shape({}),
    userDefinedTasksType: PropTypes.string.isRequired,
    userDefinedTasksImagePath: PropTypes.string,
    userDefinedTasksRunSelection: PropTypes.number,
  };

  static defaultProps = {
    selectedTaskDefinition: null,
    userDefinedTasksRunSelection: null,
    userDefinedTasksImagePath: '',
  };

  handleSave = async (values) => {
    const { onSave, onConfigFinish } = this.props;
    await onSave(values);
    onConfigFinish();
  };

  renderWidgetMode = () => {
    const {
      onConfigFinish,
      showDisplay,
      showTitle,
      title,
      selectedTaskDefinition,
      userDefinedTasksType,
      userDefinedTasksImagePath,
      userDefinedTasksRunSelection,
    } = this.props;

    if (showDisplay) {
      return (
        <UserDefinedTasksWidgetDisplay
          title={title}
          showTitle={showTitle}
          userDefinedTasksType={userDefinedTasksType}
          userDefinedTasksRunSelection={userDefinedTasksRunSelection}
          selectedTaskDefinition={selectedTaskDefinition}
          userDefinedTasksImagePath={userDefinedTasksImagePath}
          imageHoverText="Hover Text"
        />
      );
    }
    return (
      <UserDefinedTasksWidgetConfig
        initialTitle={title}
        initialShowTitle={showTitle}
        onSave={this.handleSave}
        onCancel={onConfigFinish}
        initialUserDefinedTasksImagePath={userDefinedTasksImagePath}
        initialUserDefinedTasksType={userDefinedTasksType}
        initialUserDefinedTasksRunSelection={userDefinedTasksRunSelection}
        initialSelectedTaskDefinition={selectedTaskDefinition}
        open
      />
    );
  };

  render() {
    return <>{this.renderWidgetMode()}</>;
  }
}

export default UserDefinedTasksWidget;
