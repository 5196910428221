import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Paper, Typography } from 'base-components';
import { AddIconButton } from 'domain/AppComponents/IconButtons';
import { Playlist } from 'domain/services/PlaylistService';
import PlaylistCollectionList from './PlaylistCollectionList';

type PlaylistCollectionPanelProps = {
  className: string;
  groups: string[];
  playlists: Playlist[];
  selectedGroup: string;
  onAddGroup: () => void;
  onEditGroup: (group: string) => void;
  onSelectGroup: (group: string) => void;
  onDeleteGroup: (group: string) => void;
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(),
    paddingLeft: theme.spacing(2),
  },
}));

const PlaylistCollectionPanel: React.FC<PlaylistCollectionPanelProps> = ({
  className,
  groups,
  playlists,
  selectedGroup,
  onAddGroup,
  onEditGroup,
  onSelectGroup,
  onDeleteGroup,
}: PlaylistCollectionPanelProps) => {
  const classes = useStyles();

  return (
    <Paper className={className}>
      <div className={classes.header}>
        <Typography variant="h6">Playlist Collections</Typography>
        <AddIconButton
          name="add"
          aria-label="Create Collection"
          onClick={onAddGroup}
          disabled={
            !playlists.find((playlist) => playlist.groupName === undefined)
          }
        />
      </div>
      <Divider />
      <PlaylistCollectionList
        groups={groups}
        selectedGroup={selectedGroup}
        onSelectGroup={onSelectGroup}
        onEditGroup={onEditGroup}
        onDeleteGroup={onDeleteGroup}
      />
    </Paper>
  );
};

export default PlaylistCollectionPanel;
