import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from 'base-components';
import FormDialog from 'library/CompositeComponents/dialog/FormDialog_new';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

type CopyCitationDialogProps = {
  onCopy: (event: any) => void;
  open: boolean;
  onClose: () => void;
  citationValue: any;
};

const CopyCitationDialog = ({
  onCopy,
  open,
  onClose,
  citationValue,
}: CopyCitationDialogProps) => {
  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: { copyCitation: citationValue },
  });

  const { reset, handleSubmit } = formMethods;

  useEffect(() => {
    if (citationValue) {
      reset({ copyCitation: citationValue });
    }
  }, [citationValue, reset]);

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      confirmButtonTranslationKey="common.buttons.copyToClipboard"
      cancelButtonTranslationKey="common.buttons.cancel"
      onSubmit={handleSubmit(onCopy)}
      title="Copy Citation"
      formMethods={formMethods}
    >
      <Grid container>
        <Grid item xs={12}>
          <FormTextField
            translationKey="searchHistory.copyCitation"
            name="copyCitation"
            fullWidth
            multiline
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default CopyCitationDialog;
