import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import QaqcAutotestsService from 'domain/services/QaqcAutotestsService';

class QaqcFinderSensorTypeDropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    sensorTypes: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getSensorTypes();
  }

  getSensorTypes = () => {
    QaqcAutotestsService.getSensorTypes()
      .then((results) => {
        const sensorTypes = results.map((sensorType) => ({
          label: sensorType.description,
          value: sensorType.sensorTypeId,
        }));
        this.setState({
          sensorTypes: [{ label: 'Find All', value: 0 }, ...sensorTypes],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const { disabled, initialValue, value, onChange, ...rest } = this.props;
    const { sensorTypes } = this.state;
    return (
      <Dropdown
        disabled={disabled}
        initialValue={initialValue}
        label="Sensor Type"
        options={sensorTypes}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }
}
export default QaqcFinderSensorTypeDropdown;
