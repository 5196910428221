import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { WarningAlert } from '@onc/composite-components';
import { EditIconButton } from 'domain/AppComponents/IconButtons';

import Environment from 'util/Environment';

const styles = (theme) => ({
  alertStyle: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  editButton: {
    paddingRight: theme.spacing(2),
  },
});

const TombstoneAlert = ({
  message = undefined,
  onEdit,
  classes,
  ...otherProps
}) => {
  const renderEditIcon = () => {
    if (Environment.getDmasUserPrivilege() === 'RW') {
      return <EditIconButton onClick={onEdit} className={classes.editButton} />;
    }
    return null;
  };
  return (
    <WarningAlert
      className={classes.alertStyle}
      title="This dataset is no longer available"
      action={renderEditIcon()}
      {...otherProps}
    >
      {message}
    </WarningAlert>
  );
};

TombstoneAlert.propTypes = {
  message: PropTypes.string,
  classes: PropTypes.shape({
    alertStyle: PropTypes.string,
    editButton: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default withStyles(styles)(TombstoneAlert);
