import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import qs from 'qs';

import { Tab, Tabs } from 'base-components';
import {
  AbsoluteRightButtonStyle,
  FloatRightButtonLowStyles,
} from 'library/CompositeComponents/button/CommonButtonStyles';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import ExtensionsListingTab from './ExtensionsListingTab';
import ItemsListingTab from './ItemsListingTab';
import TabPanel from '../device-search/TabPanel';

const styles = (theme) => ({
  otherRoot: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
  },
  tableDiv: {
    margin: theme.spacing(1),
  },
  field: {
    marginBottom: theme.spacing(2),
    maxWidth: theme.spacing(50),
    minWidth: theme.spacing(50),
  },
  parameterDiv: {
    paddingLeft: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  panelDiv: {
    marginTop: theme.spacing(1),
  },
  addExtensionButton: {
    ...AbsoluteRightButtonStyle(theme),
    right: theme.spacing(4),
  },
  addItemButton: FloatRightButtonLowStyles,
});

class CablesPage extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape().isRequired,
    classes: PropTypes.shape().isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const params = this.parseURLParams();
    const value = params.itemId !== undefined ? 1 : 0;
    this.state = {
      itemId: Number(params.itemId),
      extensionId: Number(params.extensionId),
      extensionDetailId: Number(params.extensionDetailId),
      value,
      hasChangedTabs: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => this.updatePage());
  }

  parseURLParams = () => {
    const { history } = this.props;

    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  };

  tabChange = (_event, value) => {
    this.setState({ value, hasChangedTabs: true });
  };

  updatePage() {
    const params = this.parseURLParams();
    this.setState({
      itemId: Number(params.itemId),
      extensionId: Number(params.extensionId),
      extensionDetailId: Number(params.extensionDetailId),
    });
  }

  render() {
    const { classes, history, onError, onInfo } = this.props;
    const { value, hasChangedTabs, extensionDetailId, extensionId, itemId } =
      this.state;
    return (
      <div className={classes.root}>
        <Panel classes={{ root: classes.root }}>
          <Tabs
            value={value}
            onChange={this.tabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Extensions" />
            <Tab label="Items" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <ExtensionsListingTab
              permission={Environment.getDmasUserPrivilege()}
              classes={classes}
              onError={onError}
              onInfo={onInfo}
              extensionIdParam={extensionId}
              history={history}
              extensionDetailIdParam={extensionDetailId}
              hasChangedTabs={hasChangedTabs}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ItemsListingTab
              classes={classes}
              permission={Environment.getDmasUserPrivilege()}
              itemIdParam={itemId}
              onError={onError}
              onInfo={onInfo}
              hasChangedTabs={hasChangedTabs}
            />
          </TabPanel>
        </Panel>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(CablesPage));
