import { useEffect, useState } from 'react';
import { Dialog } from 'base-components';
import SensorTypeService from 'domain/services/SensorTypeService';
import { UnitOfMeasure } from 'domain/services/UnitOfMeasureService';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import SensorType from './SensorType';
import SensorTypeForm from './SensorTypeForm';

type Props = {
  initialValue: Partial<SensorType>;
  unitOfMeasures: UnitOfMeasure[];
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

const EMPTY_FORM: SensorType = {
  sensorTypeId: 0,
  sensorTypeName: '',
  description: '',
  scalar: false,
  averageable: false,
  sensorTypeCode: '',
  unitOfMeasure: undefined,
};

const SensorTypeDialog = ({
  initialValue,
  unitOfMeasures,
  open,
  onCancel,
  onSuccess,
}: Props) => {
  const [sensorTypeForm, setSensorTypeForm] = useState<SensorType>(EMPTY_FORM);
  const { onInfo, onError } = useSnackbars();

  const [, , createSensorType] = useWebService({
    method: SensorTypeService.createSensorType,
  });

  const [, , updateSensorType] = useWebService({
    method: SensorTypeService.updateSensorType,
  });

  // When true, create a new CableType
  // when false, edit an existing CableType
  const [isCreate, setIsCreate] = useState(false);

  useEffect(() => {
    if (!initialValue) {
      setIsCreate(true);
      setSensorTypeForm(EMPTY_FORM);
      return;
    }
    setIsCreate(false);
    setSensorTypeForm({
      sensorTypeId: initialValue.sensorTypeId,
      sensorTypeName: initialValue.sensorTypeName,
      description: initialValue.description,
      scalar: initialValue.scalar,
      averageable: initialValue.averageable,
      sensorTypeCode: initialValue.sensorTypeCode,
      unitOfMeasure: initialValue.unitOfMeasure,
    });
  }, [initialValue]);

  const handleSubmit = ({
    sensorTypeName,
    sensorTypeCode,
    description,
    isScalar,
    isAverageable,
    unitOfMeasure,
  }) => {
    if (unitOfMeasure.unitOfMeasureId === 0) {
      onError(`UnitOfMeasure is required`);
    }
    if (isCreate) {
      return createSensorType({
        sensorTypeName,
        sensorTypeCode,
        description,
        scalar: isScalar === undefined ? false : isScalar,
        averageable: isAverageable === undefined ? false : isAverageable,
        unitOfMeasureId: unitOfMeasure.unitOfMeasureId,
      })
        .then((response) => {
          if (response.data.statusCode === 0) {
            onInfo('Sensor Type saved successfully!');
            onCancel();
            onSuccess();
          } else {
            onError(
              `Sensor Type failed to save. Ensure Sensor Type Code is lowercase and doesn't have spaces.`
            );
          }
        })
        .catch((err) => {
          onError(err);
        });
    }
    return updateSensorType({
      sensorTypeId: sensorTypeForm.sensorTypeId,
      sensorTypeName,
      sensorTypeCode,
      description,
      scalar: isScalar === undefined ? false : isScalar,
      averageable: isAverageable === undefined ? false : isAverageable,
      unitOfMeasureId: unitOfMeasure.unitOfMeasureId,
    })
      .then((response) => {
        if (response.data.statusCode === 0) {
          onInfo('Sensor Type saved successfully!');
          onCancel();
          onSuccess();
        } else {
          onError(
            `Sensor Type failed to save. Ensure Sensor Type Code is lowercase and doesn't have spaces.`
          );
        }
      })
      .catch((err) => {
        onError(err);
      });
  };

  return (
    <Dialog open={open}>
      <SensorTypeForm
        unitOfMeasureList={unitOfMeasures}
        onClose={onCancel}
        onSubmit={handleSubmit}
        sensorTypeName={sensorTypeForm.sensorTypeName}
        sensorTypeCode={sensorTypeForm.sensorTypeCode}
        description={sensorTypeForm.description}
        isScalar={sensorTypeForm.scalar}
        isAverageable={sensorTypeForm.averageable}
        unitOfMeasure={{
          unitOfMeasureId:
            sensorTypeForm.unitOfMeasure !== undefined
              ? sensorTypeForm.unitOfMeasure.unitOfMeasureId
              : undefined,
          unitOfMeasureName:
            sensorTypeForm.unitOfMeasure !== undefined
              ? sensorTypeForm.unitOfMeasure.unitOfMeasureName
              : undefined,
        }}
        isCreate={isCreate}
      />
    </Dialog>
  );
};

export default SensorTypeDialog;
