import { useEffect, useState } from 'react';
import { Switch, Tooltip } from 'base-components';
import { useChangeBatchTaskAutorunMutation } from 'domain/AppComponents/batch/hooks/BatchTaskMutationHooks';

const BatchToggleAutorunAction = ({
  autorun,
  taskId,
}: {
  autorun: boolean;
  taskId: number;
}) => {
  const [isAutorun, setIsAutorun] = useState<boolean>(autorun);
  useEffect(() => {
    setIsAutorun(autorun);
  }, [autorun]);

  const mutation = useChangeBatchTaskAutorunMutation();

  return (
    <Tooltip
      title={
        autorun
          ? 'Starts automatically after the preceding task completes'
          : 'Pauses for user input after the preceding task completes'
      }
    >
      <Switch
        checked={autorun}
        onChange={() => {
          setIsAutorun((prev) => !prev);
          mutation.mutate({ taskId, autorun: !isAutorun });
        }}
      />
    </Tooltip>
  );
};

export default BatchToggleAutorunAction;
