import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import RequestSupportService from 'domain/services/RequestSupportService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import RequestSupport from './RequestSupport';
import RequestSupportDialogBox from './RequestSupportDialogBox';
import RequestSupportTable from './RequestSupportTable';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
});

type RequestSupportPageProps = {
  onError: (message: any, callback?: any) => void;
};

const RequestSupportPage = (props: RequestSupportPageProps) => {
  const { onError } = props;
  const userHasWritePermission = Environment.getDmasUserPrivilege() === 'RW';
  const [requestSupports, setRequestSupports] = useState<
    RequestSupport[] | null
  >(null);
  const [editRequestSupport, setEditRequestSupport] =
    useState<RequestSupport>(null);

  const openAndSaveEditData = (form: RequestSupport) => {
    setEditRequestSupport(form);
    return true;
  };

  useEffect(() => {
    const refreshTableData = async () => {
      const payload: RequestSupport[] =
        await RequestSupportService.getAllRequestSupports();
      setRequestSupports(payload);
    };
    refreshTableData();
  }, []);

  const renderDialogBox = () => (
    <RequestSupportDialogBox
      label="Support Request"
      onClose={() => setEditRequestSupport(null)}
      userHasWritePermission={userHasWritePermission}
      requestSupport={editRequestSupport}
    />
  );

  return (
    <>
      {editRequestSupport ? renderDialogBox() : <></>}
      <RequestSupportTable
        userHasWritePermission={userHasWritePermission}
        onError={onError}
        requestSupports={requestSupports}
        openAndSaveEditData={openAndSaveEditData}
      />
    </>
  );
};

export default withStyles(styles)(withSnackbars(RequestSupportPage));
