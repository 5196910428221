import { useState } from 'react';
import { Box } from '@mui/material';
import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { Loading } from '@onc/composite-components';
import {
  Divider,
  ErrorPage,
  Grid,
  LabelledCheckbox,
  Typography,
} from 'base-components';
import {
  CancelButton,
  DeleteButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

import Panel from 'library/CompositeComponents/panel/Panel';
import DateFormatUtils from 'util/DateFormatUtils';
import DateUtils from 'util/DateUtils';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import IpTypeDropdown from '../dropdowns/IpTypeDropdown';
import {
  MarginedReadOnlyField,
  ModifyBy,
  ModifyDate,
} from '../Form/Fields/ReadOnlyFields';
import {
  handleOpenDeviceDetails,
  openDeviceDetails,
} from '../link/DeviceDetailsLink';

type DevicePayload = {
  devices: {
    device: {
      name: string;
    };
  };
};

type DeviceIpPayload = {
  ip: {
    dateFrom: string;
    dateTo: string;
    deviceId: number;
    dnsName: string;
    ipAddress: string;
    ipId: number;
    ipPort: number;
    ipTypeId: number;
    macAddress: string;
    modifyBy: number;
    modifyByName: string;
    modifyDate: string;
  };
  dmasUser: {
    firstname: string;
    lastname: string;
  };
  device: {
    deviceName: string;
  };
  ipType: {
    ipTypeId: number;
    ipTypeName: string;
  };
};

type IpPostProps = {
  dnsName: string;
  ipAddress: string;
  ipPort: number;
  dateToDateTime: string;
  ipTypeId: number;
  macAddress: string;
  dateFromDateTime: string;
  ipId?: number;
  deviceId: number;
};

type IpForm = {
  dnsName: string;
  ipPort: number;
  ipType: number;
  ipAddress: string;
  macAddress: string;
  dateFrom: Moment;
  dateTo: Moment;
  device: string;
};

type IpMaintenanceRWProps = {
  ipId?: number;
  deviceId: number;
};

const IpMaintenanceRW = ({
  ipId = undefined,
  deviceId,
}: IpMaintenanceRWProps) => {
  const classes = {
    entryFormContainer: {
      paddingLeft: 3,
    },
    formButtons: {
      flexDirection: 'row-reverse',
      display: 'flex',
      paddingRight: 2,
    },
    panelDiv: {
      margin: 1,
    },
  };
  const { onError, onInfo } = useSnackbars();
  const [renderedOnce, setRenderedOnce] = useState<boolean>(false);
  const hasIp = ipId !== undefined && !isNaN(ipId);
  const hasDevice = deviceId !== undefined && !isNaN(deviceId);
  const editingIp = hasIp && hasDevice;
  const creatingIp = !hasIp && hasDevice;

  const ipData = useGet<DeviceIpPayload, { ipId: number }>(
    'DeviceIPService',
    {
      operation: 4,
      options: {
        enabled: editingIp,
        retry: 1,
      },
    },
    {
      ipId,
    }
  );

  const deviceData = useGet<
    DevicePayload,
    { deviceid: number; operationtype: number; network: number }
  >(
    'DeviceService',
    {
      operation: 2,
      transform: (response) => {
        const { data } = response;
        if (!data.payload) {
          throw new Error(data);
        }
        return data.payload;
      },
      options: {
        enabled: creatingIp,
        retry: 1,
      },
    },
    {
      deviceid: deviceId,
      operationtype: 2,
      network: -1,
    }
  );

  const [nullDateTo, setNullDateTo] = useState<boolean>(false);

  const { mutate: saveIpData } = usePost<IpPostProps>(
    'DeviceIPService',
    {
      onSuccess: () => {
        onInfo('IP saved!');
        openDeviceDetails(deviceId, 'ip_tab');
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    1
  );

  const { mutate: updateIpData } = usePost<IpPostProps>(
    'DeviceIPService',
    {
      onSuccess: () => {
        onInfo('IP updated!');
        openDeviceDetails(deviceId, 'ip_tab');
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    2
  );

  const { mutate: deleteIpData } = usePost<{ ipId: number }>(
    'DeviceIPService',
    {
      onSuccess: () => {
        onInfo(`IP deleted!`);
        openDeviceDetails(deviceId, 'ip_tab');
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    3
  );

  const formMethods = useForm<IpForm>({
    mode: 'onBlur',
    defaultValues: {
      dnsName: ipData?.data?.ip?.dnsName,
      ipPort: ipData?.data?.ip?.ipPort,
      ipType: ipData?.data?.ip?.ipTypeId,
      ipAddress: ipData?.data?.ip?.ipAddress,
      macAddress: ipData?.data?.ip?.macAddress,
      dateFrom: moment(ipData?.data?.ip?.dateFrom),
      dateTo: moment(ipData?.data?.ip?.dateTo),
    },
  });

  if ((editingIp && ipData.isLoading) || (creatingIp && deviceData.isLoading)) {
    return <Loading />;
  }

  if (
    (editingIp && ipData.error) ||
    (creatingIp && deviceData.error) ||
    (creatingIp && deviceData.data?.devices?.device === null)
  ) {
    return <ErrorPage />;
  }

  const parametersForPostReq = (values) => {
    const startDateTimeStr = `${DateFormatUtils.formatDate(
      values.dateFrom,
      'time-with-month-name-string'
    )} ${DateFormatUtils.formatDate(values.dateFrom, 'time')}`;

    const params = {
      dnsName: values.dnsName,
      ipAddress: values.ipAddress,
      ipPort: values.ipPort !== '' ? values.ipPort : undefined,
      dateToDateTime:
        !nullDateTo && values.dateTo !== ''
          ? `${DateFormatUtils.formatDate(
              values.dateTo,
              'time-with-month-name-string'
            )} ${DateFormatUtils.formatDate(values.dateTo, 'time')}`
          : undefined,
      ipTypeId: values.ipType,
      macAddress: values.macAddress,
      dateFromDateTime: startDateTimeStr,
      deviceId,
      ...(isNaN(ipId) || ipId === undefined ? {} : { ipId }),
    };

    return params;
  };

  // Buttons cannot take the mutate from usePost as a param
  const handleDelete = () => {
    deleteIpData({ ipId });
  };

  const handleSubmit = (values) => {
    const props = parametersForPostReq(values);
    if (!ipId || isNaN(ipId)) {
      saveIpData(props);
    } else {
      updateIpData(props);
    }
  };

  /*
   * useForm needs to be called non-conditionally, but values are undefined
   * while useGet is querying, so we reset and re-render after useGets are
   * done
   */
  if (!renderedOnce) {
    formMethods.reset({
      dnsName: ipData?.data?.ip?.dnsName,
      ipPort: ipData?.data?.ip?.ipPort,
      ipType: ipData?.data?.ip?.ipTypeId,
      ipAddress: ipData?.data?.ip?.ipAddress,
      macAddress: ipData?.data?.ip?.macAddress,
      dateFrom: moment(ipData?.data?.ip?.dateFrom),
      dateTo: ipData?.data?.ip?.dateTo
        ? moment(ipData?.data?.ip?.dateTo)
        : undefined,
    });
    setNullDateTo(
      ipData?.data?.ip?.dateTo === null ||
        ipData?.data?.ip?.dateTo === undefined
    );
    setRenderedOnce(true);
  }

  return (
    // This Box is included so that the panel inherits the size of the box and the scrollbar which appears in a panel can be hidden
    <Box sx={classes.panelDiv}>
      <Panel
        title={
          <>
            <Typography variant="body1">IP ID:</Typography>
            <Typography variant="body1">{ipId || 'New'}</Typography>
          </>
        }
      >
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <Grid container direction="row" sx={classes.entryFormContainer}>
            <Grid item xs={6}>
              <FormTextField
                name="dnsName"
                translationKey="device.dnsName"
                fullWidth
              />
              <FormTextField
                name="ipPort"
                translationKey="device.ipPort"
                fullWidth
                type="number"
              />
              <IpTypeDropdown
                name="ipType"
                label="IP Type"
                fullWidth
                rules={{ required: 'An IP Type is required' }}
              />
              <FormTextField
                name="ipAddress"
                fullWidth
                translationKey="device.ipAddress"
                rules={{ required: 'An IP Address is required' }}
              />
              <FormTextField
                name="macAddress"
                translationKey="device.macAddress"
                fullWidth
              />
              <FormDateTimePicker
                translationKey="common.datepickers.startDate"
                name="dateFrom"
                fullWidth
              />
              <FormDateTimePicker
                translationKey="common.datepickers.endDate"
                name="dateTo"
                disabled={nullDateTo}
                fullWidth
              />
              <LabelledCheckbox
                label="Set End Date to Null (use this to set an IP to continue on with no defined end date)"
                value={nullDateTo}
                onChange={(event, value) => setNullDateTo(value)}
              />
              <MarginedReadOnlyField
                labelText="Device"
                valueText={
                  deviceData?.data?.devices?.device?.name ||
                  ipData?.data?.device?.deviceName
                }
                title="deviceField"
              />
              <ModifyBy username={ipData?.data?.ip?.modifyByName} />
              <ModifyDate
                date={DateUtils.formatDateAsString(
                  new Date(ipData?.data?.ip?.modifyDate)
                )}
              />
            </Grid>
          </Grid>
          <Box sx={classes.formButtons}>
            <SaveButton type="submit" />
            <CancelButton
              onClick={handleOpenDeviceDetails(deviceId, 'ip_tab')}
            />
            <DeleteButton
              onClick={handleDelete}
              disabled={isNaN(ipId) || !ipId}
            />
          </Box>
        </Form>
        <Divider variant="middle" />
      </Panel>
    </Box>
  );
};

export default IpMaintenanceRW;
