import * as React from 'react';
import { GroupingState, Grouping } from '@devexpress/dx-react-grid';

export interface GroupingProps {
  grouping: Grouping[];
  columnExtensions?: GroupingState.ColumnExtension[];
  handleGroupingChange?: (grouping: Grouping[]) => void;
}

const ColumnGrouping: React.VFC<GroupingProps> = ({
  grouping,
  columnExtensions = undefined,
  handleGroupingChange = undefined,
}) => {
  if (handleGroupingChange) {
    return (
      <GroupingState
        grouping={grouping}
        columnExtensions={columnExtensions}
        onGroupingChange={handleGroupingChange}
      />
    );
  }
  return <GroupingState defaultGrouping={grouping} />;
};
export default ColumnGrouping;
