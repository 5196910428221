import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip, Skeleton } from 'base-components';
import useSnapshots from '../seatube/util/useSnapshots';

interface Props {
  deviceId: number;
  thumbnailDate: string;
  width: number;
  height?: number;
  className?: string;
  quality?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center' /* For horizontal centering */,
    alignItems: 'center' /* For vertical centering */,
    overflow: 'hidden',
  },
}));

const Thumbnail: React.FC<Props> = ({
  deviceId,
  thumbnailDate,
  width,
  height = width * (9 / 16),
  className = '',
  quality = 'S',
}) => {
  const [snapshotUrls, loadingUrls] = useSnapshots(
    deviceId,
    thumbnailDate,
    1,
    1,
    quality
  );

  const [loadingImage, setLoadingImage] = useState(true);
  const classes = useStyles();
  const [snapshotUrl] = snapshotUrls;
  const loading = loadingUrls || loadingImage;

  useEffect(() => {
    if (snapshotUrls.length > 0) {
      setLoadingImage(true);
    }
    if (!loadingUrls && snapshotUrls.length === 0) {
      setLoadingImage(false);
    }
  }, [loadingUrls, snapshotUrls.length]);

  const renderThumbnail = () => {
    if (!snapshotUrl) {
      return (
        <Tooltip title="Video is missing for the clip" placement="top-start">
          <span>
            <img
              width={width}
              height={height}
              style={{ backgroundColor: '#000' }}
              alt=""
            />
          </span>
        </Tooltip>
      );
    }
    return (
      <img
        src={snapshotUrl}
        width={loading ? 0 : width}
        alt="clip-thumbnail"
        onLoad={() => setLoadingImage(false)}
      />
    );
  };

  return (
    <>
      <div
        className={`${classes.container} ${className}`}
        style={{ height, width }}
      >
        {loading && (
          <Skeleton variant="rectangular" height={height} width={width} />
        )}
        {renderThumbnail()}
      </div>
    </>
  );
};

export default Thumbnail;
