interface ExportFormat {
  name: string;
  label: string;
}

// TypeScript doesn't support Java-like object-valued enums. Inspired by https://stackoverflow.com/a/51398471.
export default class SeaTubeSearchExportFormat {
  static readonly CSV = { name: 'csv', label: 'CSV' } as Readonly<ExportFormat>;

  static readonly JSON = {
    name: 'json',
    label: 'JSON',
  } as Readonly<ExportFormat>;

  static readonly DARWIN_CORE = {
    name: 'darwin_core',
    label: 'DwC-A',
  } as Readonly<ExportFormat>;

  static readonly INGESTION_CSV = {
    name: 'ingestion_csv',
    label: 'Ingestion CSV',
  } as Readonly<ExportFormat>;

  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-unused-vars
  private constructor(_ignored?: any) {
    // don't allow public constructor access
  }
}
