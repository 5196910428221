import Environment from '@onc/environment';

type DmasLinkProps = {
  contents: string;
  page: string;
};

const DmasLink = ({ contents, page }: DmasLinkProps) => (
  <a href={`${Environment.getDmasUrl()}${page}`}>{contents}</a>
);

export default DmasLink;
