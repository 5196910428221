import { createStyles, makeStyles } from '@mui/styles';
import { RegisterOptions, useForm } from 'react-hook-form';
import { Box, LoadingButton } from 'base-components';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormCheckbox from 'library/CompositeComponents/form/FormCheckbox';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

const useStyles = makeStyles((theme) =>
  createStyles({
    styling: {
      paddingTop: theme.spacing(5),
    },
  })
);

type BulkUpdateActionTabProps = {
  onSubmit: ({
    curStatus,
    comment,
    jiraIssueId,
    appendComment,
    appendJiraIssueId,
  }: {
    curStatus: string;
    comment: string;
    jiraIssueId: string;
    appendComment: boolean;
    appendJiraIssueId: boolean;
  }) => void;
};

type BulkUpdateActionFormFields = {
  curStatus: string;
  comment: string;
  jiraIssueId: string;
  appendComment: boolean;
  appendJiraIssueId: boolean;
};

const statuses = [
  'Incomplete (1)',
  'Complete (2)',
  'Not Required (3)',
  'Did Not Complete (4)',
  'Unknown (5)',
];

const DeviceWorkflowBulkUpdateActionTab = ({
  onSubmit,
}: BulkUpdateActionTabProps) => {
  const classes = useStyles();
  const formMethods = useForm<BulkUpdateActionFormFields>({
    values: {
      appendComment: true,
      appendJiraIssueId: true,
      curStatus: '',
      comment: '',
      jiraIssueId: '',
    },
  });
  const {
    formState: { isSubmitting },
  } = formMethods;

  const validateJiraIssueId = (value: string) => {
    if (value === undefined || value === '') {
      return true;
    }
    const jiraEntries = value.split(',');
    let isValid = true;

    jiraEntries.forEach((entry) => {
      const entryArr = entry.split('-');
      if (isNaN(Number(entryArr[1]))) {
        isValid = false;
      }
    });
    return isValid;
  };

  type FormRules<T> = {
    [P in keyof T]?: RegisterOptions;
  };

  const rules: FormRules<BulkUpdateActionFormFields> = {
    curStatus: {
      required: 'Status is required',
    },
    jiraIssueId: {
      validate: {
        isValid: (value: string) => validateJiraIssueId(value),
      },
    },
  };

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <FormAutocomplete
        name="curStatus"
        translationKey="device.status"
        options={statuses}
        getOptionLabel={(option) => option}
        rules={rules.curStatus}
      />
      <div className={classes.styling} />
      <FormTextField
        name="comment"
        translationKey="common.textfields.comment"
        fullWidth
      />
      <div className={classes.styling} />
      <FormTextField
        name="jiraIssueId"
        translationKey="device.jiraIssueId"
        fullWidth
        rules={rules.jiraIssueId}
      />
      <div className={classes.styling} />
      <FormCheckbox name="appendComment" label="Append Comment" />
      <FormCheckbox name="appendJiraIssueId" label="Append Jira Issue Id" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: '24px',
        }}
      >
        <LoadingButton
          variant="contained"
          translationKey="common.buttons.save"
          type="submit"
          loading={isSubmitting}
        />
      </Box>
    </Form>
  );
};

export default DeviceWorkflowBulkUpdateActionTab;
