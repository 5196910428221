import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Typography } from 'base-components';

const STYLES = (theme: Theme) =>
  createStyles({
    textBoxHighlited: {
      overflow: 'hidden',
      marginRight: theme.spacing(1),
      height: theme.spacing(17),
      top: '10%',
      left: '5%',
      position: 'absolute',
      zIndex: 1,
    },
    textBox: {
      left: '5%',
      top: '30%',
      position: 'absolute',
      zIndex: 1,
    },
    overlayHeaderText: {
      fontFamily: 'Rajdhani',
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      zIndex: 1,
      textShadow: 'black 0px 0px 4px',
    },
    overlayBodyText: {
      color: theme.palette.primary.contrastText,
      zIndex: 1,
    },
  });

interface PanelCardTextProps extends WithStyles<typeof STYLES> {
  highlighted: boolean;
  titleText: string;
  bodyText: string;
}

const PanelCardText: React.FC<PanelCardTextProps> = (
  props: PanelCardTextProps
) => {
  const { classes, highlighted, titleText, bodyText } = props;

  if (highlighted) {
    return (
      <div className={classes.textBoxHighlited}>
        <Typography classes={{ h5: classes.overlayHeaderText }} variant="h5">
          {titleText}
        </Typography>
        <Typography classes={{ body1: classes.overlayBodyText }}>
          {bodyText}
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.textBox}>
      <Typography classes={{ h4: classes.overlayHeaderText }} variant="h4">
        {titleText}
      </Typography>
    </div>
  );
};

export default withStyles(STYLES)(PanelCardText);
