import * as React from 'react';
import { Grid } from 'base-components';
import { EndeavourEarthquakeCompleteRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import Filter, {
  FilterImplementationProps,
  FilterValue,
  SummaryValues,
} from 'library/CompositeComponents/filter/Filter';

export interface StatusFilterValue extends FilterValue {
  status: 'all' | 'completeOnly' | 'incompleteOnly';
}

interface Props extends FilterImplementationProps {
  value?: StatusFilterValue;
}

const StatusFilter: React.FC<Props> = ({
  onChange,
  name,
  value = {
    status: 'all',
  },
  onExpand,
  expanded,
}: Props) => {
  const renderSummaryValues = () => {
    if (value) {
      const summary: SummaryValues[] = [];
      summary.push({ label: `${value.status}` });
      return summary;
    }
    return undefined;
  };

  return (
    <Filter
      title="Status"
      value={value || undefined}
      name={name}
      summaryValues={renderSummaryValues()}
      onChange={onChange}
      onExpand={onExpand}
      expanded={expanded}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EndeavourEarthquakeCompleteRadioGroup
            name={name}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </Filter>
  );
};

export default StatusFilter;
