const NOAA_DATA = {
  organizationName: 'NOAA',
  organizationLink: 'https://oceanexplorer.noaa.gov/okeanos/welcome.html',
  organizationDescription:
    'Dive recordings from expeditions of the NOAA ship  Okeanos Explorer are catalogued in SeaTube. The Okeanos Explorer is the only United States federal vessel dedicated to exploring our largely unknown ocean for the purpose of discovery and the advancement of knowledge about the deep ocean. NOAA’s Office of Ocean Exploration and Research (OER) has led expeditions in many deep-sea locations around the globe.\n\nOER explores the ocean to make discoveries of scientific, economic, and cultural value; support innovations in exploration tools and capabilities; and encourage the next generation of ocean explorers, scientists, and engineers to pursue careers in ocean exploration and related fields. The data collected during expeditions and the research they support are publicly available through Oceans 3.0 and other data repositories.',
  organizationId: 340,
};

const ONC_DATA = {
  organizationName: 'Ocean Networks Canada',
  organizationLink: 'https://www.oceannetworks.ca/',
  organizationDescription:
    'The University of Victoria’s Ocean Networks Canada (ONC) monitors the west and east coasts of Canada and the Arctic to continuously deliver data in real-time for scientific research that helps communities, governments and industry make informed decisions about our future. Using cabled observatories, remote control systems and interactive sensors, and big data management ONC enables evidence-based decision-making on ocean management, disaster mitigation, and environmental protection.\n\nEvery year, ONC leads maintenance expeditions to recover and deply scientific instruments, maintain observatory infrastructure, collect scientific specimens and support deep-sea research experiments. These expeditions sail aboard a variety of ships with dive operations performed by a variety of remotely operated vehicle (ROV) teams. All dive recordings are catalogued in SeaTube.',
  organizationId: 134,
};

const DFO_DATA = {
  organizationName: 'Department of Fisheries and Oceans Canada',
  organizationLink: 'https://www.dfo-mpo.gc.ca/index-eng.html',
  organizationDescription:
    "Fisheries and Oceans Canada (DFO) is a federal institution, responsible for safeguarding our waters and managing Canada's fisheries and oceans resources by:\n\n- sustainably managing fisheries and aquaculture\n- working with fishers, coastal and Indigenous communities to enable their continued prosperity from fish and seafood\n- ensuring that Canada’s oceans and other aquatic ecosystems are protected from negative impacts\n- ensuring commercial vessels and recreational boaters can safely navigate our waters\n- being there to save lives and protect our environment when emergencies arise\n\nDFO helps to ensure healthy and sustainable aquatic ecosystems through habitat protection and sound science. DFO supports economic growth in the marine and fisheries sectors, and innovation in areas such as aquaculture and biotechnology. Fisheries and Oceans Canada and the Canadian Coast Guard work in over 400 locations across Canada on 4 core responsibilities, fisheries, aquatic ecosystems, marine navigation and marine operations and response.",
  organizationId: 559,
};

const IFREMER_DATA = {
  organizationId: 34,
};

export { NOAA_DATA, ONC_DATA, DFO_DATA, IFREMER_DATA };
