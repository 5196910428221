import {
  type CreateLicence,
  type Licence,
  type UpdateLicence,
} from 'domain/AppComponents/licence/Licence.types';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';

export const useFetchAll = () => useGet<Licence[]>('LicenceService', 5);

export const useFetch = (params) =>
  useGet<Licence, { licenceId: number }>('LicenceService', 4, params);

export const useCreate = (options) =>
  usePost<CreateLicence, Licence>('LicenceService', options, 1);

export const useUpdate = (options) =>
  usePost<UpdateLicence, Licence>('LicenceService', options, 2);

export const useDelete = (options) =>
  usePost<{ licenceId: number }>('LicenceService', options, 3);
