import { useState, MouseEvent } from 'react';
import * as React from 'react';
import { Add } from '@onc/icons';
import { IconButton, Menu, MenuItem } from 'base-components';
import {
  ProductNames,
  SiteDeviceSubsetProduct,
} from '../definitions/GeospatialSearchTypes';

interface Props {
  onAddClick: (selectedItem: ProductNames, extension: string) => void;
  options: SiteDeviceSubsetProduct[];
}

const ProductMenu: React.VFC<Props> = (props: Props) => {
  const { onAddClick, options } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelectWithinComp = (
    _event: MouseEvent<HTMLLIElement>,
    item: SiteDeviceSubsetProduct
  ) => {
    handleClose();
    onAddClick(item.productName, item.extension);
  };

  const createMenuItems = (option: SiteDeviceSubsetProduct) => (
    <MenuItem
      onClick={(event) => handleItemSelectWithinComp(event, option)}
      key={option.productName}
    >
      {option.productName}
    </MenuItem>
  );

  const menuItems = options.map(createMenuItems);

  if (options.length === 0) {
    return <></>;
  }

  return (
    <>
      <IconButton onClick={handleClick} aria-label="Add Data Products">
        <Add fontSize="small" />
      </IconButton>
      <Menu
        id="product-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default ProductMenu;
