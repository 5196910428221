import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { Typography } from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  RefreshButton,
  EditIconButton,
  DeleteIconButton,
} from 'domain/AppComponents/IconButtons';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import { FloatRightButtonLowStyles } from 'library/CompositeComponents/button/CommonButtonStyles';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import ResourceRoleConfig from './ResourceRoleConfig';
import ResourceRoleService from './ResourceRoleService';

const styles = {
  actionContent: FloatRightButtonLowStyles,
};

const headers = [
  { title: 'ID', name: 'deviceResourceRoleId' },
  { title: 'Date From', name: 'dateFrom' },
  { title: 'Party', name: 'resource' },
  { title: 'Role', name: 'resourceRoleName' },
  { title: 'Party Type', name: 'resourceTypeName' },
  { title: 'Comment', name: 'roleComment' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: ' ', name: 'icons' },
];

const tableColumnExtensions = [
  {
    columnName: 'deviceResourceRoleId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'dateFrom',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'resource',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'resourceRoleName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'resourceTypeName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'roleComment',
    width: ColumnInfo.xLarge,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    wordWrapEnabled: true,
    align: 'left',
  },
  {
    columnName: 'icons',
    width: ColumnInfo.small,
    align: 'left',
  },
];

class ResourceRoleTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    stripedRows: PropTypes.bool,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      actionContent: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    stripedRows: false,
  };

  titleContent = () => <Typography variant="h6">Resource-Role List</Typography>;

  columnSizeInfo = [
    { columnName: 'deviceResourceRoleId', width: ColumnInfo.mini },
    { columnName: 'dateFrom', width: ColumnInfo.medium },
    { columnName: 'resource', width: ColumnInfo.medium },
    { columnName: 'resourceRoleName', width: ColumnInfo.medium },
    { columnName: 'resourceTypeName', width: ColumnInfo.medium },
    { columnName: 'roleComment', width: ColumnInfo.xLarge },
    { columnName: 'modifyBy', width: ColumnInfo.medium },
    { columnName: 'modifyDate', width: ColumnInfo.medium },
    { columnName: 'icons', width: ColumnInfo.mini },
  ];

  disabledSort = [{ columnName: 'icons', sortingEnabled: false }];

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
      openCreateDialog: false,
      openDeleteDialog: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.refreshTableData();
  }

  actionContent = () => {
    const { permission, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const RefreshResourceRoleButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    if (permission !== 'RW') {
      return <RefreshResourceRoleButton />;
    }
    return (
      <div className={classes.actionContent}>
        <RefreshResourceRoleButton />
        <ContainedButton
          translationKey="resource-role.addResourceRole"
          startIcon={<Add />}
          onClick={this.handleAddRole}
        />
      </div>
    );
  };

  refresh = () => {
    const { onInfo } = this.props;
    this.refreshTableData();
    onInfo('Table Refreshed');
  };

  refreshTableData = () => {
    const { onError, deviceId } = this.props;
    if (!isNaN(deviceId) && deviceId !== null) {
      this.setState({ isLoading: true });
      ResourceRoleService.get(deviceId)
        .then((result) => {
          this.buildTableRows(result);
        })
        .catch((result) => {
          onError(result);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      onError('Device ID is not defined');
    }
  };

  resourceRoleSwitch = (resourceRoleId) => {
    const { onError } = this.props;
    switch (resourceRoleId) {
      case 3:
        return 'Device Owner';
      case 8:
        return 'Principal Investigator';
      default:
        onError(`${resourceRoleId} is an invalid resourceRoleId`);
        return undefined;
    }
  };

  resourceTypeSwitch = (resourceTypeId) => {
    const { onError } = this.props;
    switch (resourceTypeId) {
      case 14:
        return 'Oceans 3.0 User';
      case 15:
        return 'Organization';
      default:
        onError(`${resourceTypeId} is an invalid partyTypeId`);
        return undefined;
    }
  };

  // takes the result of the service call and modifies the returned data for display in the table
  buildTableRows = (resourceRoles) => {
    this.setState({
      tableRows: resourceRoles.map((resourceRole) => {
        const resourceRoleWithIcons = { ...resourceRole };
        resourceRoleWithIcons.resourceRoleName = this.resourceRoleSwitch(
          resourceRole.resourceRoleId
        );
        resourceRoleWithIcons.resourceTypeName = this.resourceTypeSwitch(
          resourceRole.resourceTypeId
        );
        resourceRoleWithIcons.dateFrom = DateFormatUtils.formatDate(
          resourceRole.dateFrom,
          'date'
        );
        resourceRoleWithIcons.icons = this.icons(resourceRoleWithIcons);
        return resourceRoleWithIcons;
      }),
    });
  };

  icons = (row) => {
    const { permission } = this.props;
    if (permission !== 'RW') {
      return null;
    }

    return (
      <>
        <EditIconButton
          onClick={() => {
            this.setState({
              openCreateDialog: true,
              rowToBeEdited: row,
            });
          }}
          aria-label="Edit Role"
          size="small"
        />
        <DeleteIconButton
          onClick={() => {
            this.setState({
              openDeleteDialog: true,
              rowToBeDeleted: row,
            });
          }}
          aria-label="Delete Role"
          size="small"
        />
      </>
    );
  };

  addEventDialogue = () => {
    const { openCreateDialog, rowToBeEdited } = this.state;
    const { permission } = this.props;
    if (!openCreateDialog) {
      return null;
    }
    return (
      <ResourceRoleConfig
        permission={permission}
        title={rowToBeEdited ? 'Edit Role' : 'Add New Role'}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        resourceRole={rowToBeEdited}
      />
    );
  };

  deleteEventDialogue = () => {
    const { openDeleteDialog, rowToBeDeleted } = this.state;
    if (!openDeleteDialog) {
      return null;
    }
    return (
      <ConfirmationDialog
        open
        title="Delete Role"
        content={`Are you sure you want to delete resource role ${rowToBeDeleted.deviceResourceRoleId}?`}
        onCancel={this.handleCancel}
        onConfirm={this.handleDelete}
      />
    );
  };

  // open dialog to add new resource role
  handleAddRole = () =>
    this.setState({ openCreateDialog: true, openDeleteDialog: false });

  // cancel add/edit and close dialog
  handleCancel = () =>
    this.setState({
      openCreateDialog: false,
      openDeleteDialog: false,
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
    });

  handleDelete = async () => {
    const { onError, onInfo } = this.props;
    const { rowToBeDeleted } = this.state;
    await ResourceRoleService.delete(rowToBeDeleted.deviceResourceRoleId)
      .then((response) => {
        this.setState({ openCreateDialog: false, openDeleteDialog: false });
        if (response.data.statusCode === 0) {
          onInfo('Deleted Successfully');
        } else {
          onError(
            `Failed to delete with error code: ${response.data.statusCode}`
          );
        }
      })
      .then(() => {
        this.refresh();
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  handleSave = async (form) => {
    const { onError, onInfo, deviceId } = this.props;
    const { rowToBeEdited } = this.state;
    const updatedInfo = { ...form };
    // add all row data that is missing from form back into the post
    updatedInfo.deviceId = deviceId;
    if (rowToBeEdited) {
      updatedInfo.deviceResourceRoleId = rowToBeEdited.deviceResourceRoleId;
    }
    // Change date to correct format
    updatedInfo.dateFrom = DateFormatUtils.formatDate(
      updatedInfo.dateFrom,
      'ISO8601_EXTENDED'
    );
    await ResourceRoleService.create(updatedInfo)
      .then((response) => {
        this.setState({ openCreateDialog: false });
        if (response.data.statusCode === 0) {
          onInfo('Saved Successfully');
        } else if (response.data.statusCode === 9001) {
          onError(response.data.message);
        } else {
          onError(
            `Failed to save with error code: ${response.data.statusCode}`
          );
        }
      })
      .then(() => {
        this.refresh();
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  render() {
    const { stripedRows, permission } = this.props;

    const { tableRows, isLoading } = this.state;
    if (!tableRows) return undefined;

    return (
      <>
        {this.addEventDialogue()}
        {this.deleteEventDialogue()}
        <Panel title={this.titleContent()} actionContent={this.actionContent()}>
          <SortableTable
            searchBarMoveable={permission === 'RW'}
            pageSize={15}
            searchable
            columnSizes={this.columnSizeInfo}
            columnExtensions={tableColumnExtensions}
            elevation={0}
            stripedRows={stripedRows}
            columns={headers}
            rows={tableRows}
            disabledSort={this.disabledSort}
            isLoading={isLoading}
          />
        </Panel>
      </>
    );
  }
}

export default withStyles(styles)(ResourceRoleTable);
