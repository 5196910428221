import moment from 'moment';
import DeviceUtils from 'domain/hooks/filteredDevicesUtils';
import { DateRange } from 'domain/hooks/useDateRange';
import ChartUtils from './ChartUtils';
import {
  isScalarDataChartLocationSource,
  type ChartQuality,
  type Resampling,
  type ScalarDataChartDeploymentSource,
  type ScalarDataChartLocationSource,
} from './types/TimeSeriesScalarDataChart.types';
import type { TraceData } from 'library/BaseComponents/time-series-charts/TimeSeriesChart';

export const buildTraceData = (
  firstSensor: any,
  source: any,
  propertyColor: string,
  resampling: Resampling,
  chartQuality: ChartQuality,
  isMinMaxEnabled: boolean
): TraceData => ({
  name: source.traceName,
  data: {
    times: firstSensor.data.sampleTimes,
    values: firstSensor.data.values,
    max: firstSensor.data.max,
    min: firstSensor.data.min,
    qaqcFlags: firstSensor.data.qaqcFlags,
  },
  chartQuality,
  yaxisSide: source.yaxisSide,
  showTrace:
    (isMinMaxEnabled && firstSensor.data.min) ||
    (chartQuality === 'raw' && firstSensor.data.max),
  color: propertyColor,
  minMaxColor: ChartUtils.setColorOpacity(propertyColor, 40),
  isCleanAvg:
    chartQuality === 'raw' &&
    firstSensor.data.max &&
    firstSensor.data.min &&
    !!firstSensor.data.values,
  resamplePeriod: resampling.resamplePeriod,
  unitOfMeasure: firstSensor.unitOfMeasure,
});

export const buildTraceNoData = (
  source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource,
  resampling: Resampling,
  propertyColor: string,
  chartQuality: ChartQuality
): TraceData => ({
  name: source.traceName,
  chartQuality,
  data: {
    times: [null],
    values: [null],
    max: undefined,
    min: undefined,
  },
  color: propertyColor,
  minMaxColor: ChartUtils.setColorOpacity(propertyColor, 40),
  showTrace: false,
  yaxisSide: source.yaxisSide,
  resamplePeriod: resampling.resamplePeriod,
  isCleanAvg: chartQuality === 'raw',
});

export const getQueryIdentifier = (
  source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource,
  dateRange: DateRange,
  chartQuality?: ChartQuality
) => {
  if (isScalarDataChartLocationSource(source)) {
    return `${source.deviceCategoryCode}-${source.locationCode}-${source.sensorName}-${dateRange[0].format()}-${dateRange[1].format()}-${chartQuality}`;
  }
  return `${source.deviceCode}-${source.sensorName}-${dateRange[0].format()}-${dateRange[1].format()}-${chartQuality}`;
};

export const fetchTraceData = async (
  source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource,
  resampling: Resampling,
  propertyColor: string,
  dateRange: DateRange,
  chartQuality: ChartQuality,
  isMinMaxEnabled: boolean,
  signal: AbortSignal
): Promise<TraceData> => {
  const cancellableRequest = ChartUtils.createCancellableRequest(source, {
    resampleType: resampling.resampleType,
    resamplePeriod: resampling.resamplePeriod,
    dateRange,
    chartQuality,
    signal,
  });

  let response = await cancellableRequest.request;

  if (!response || response[0] === null) {
    return buildTraceNoData(source, resampling, propertyColor, chartQuality);
  }

  if (isScalarDataChartLocationSource(source))
    response = response.filter((el) => el.sensorName === source.sensorName);

  const firstSensor = response[0];

  return buildTraceData(
    firstSensor,
    source,
    propertyColor,
    resampling,
    chartQuality,
    isMinMaxEnabled
  );
};

export const fetchMinMaxData = async (
  source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource,
  resampling: Resampling,
  propertyColor: string,
  dateRange: DateRange,
  chartQuality: ChartQuality,
  isMinMaxEnabled: boolean,
  signal: AbortSignal
) => {
  const isLocationSource = isScalarDataChartLocationSource(source);

  const resampleType =
    isLocationSource && !resampling.resamplePeriod
      ? resampling.resampleType
      : 'avg';

  const cancellableRequest = ChartUtils.createCancellableRequest(source, {
    resampleType,
    resamplePeriod: resampling.resamplePeriod,
    dateRange,
    chartQuality: 'clean',
    signal,
  });

  let response = await cancellableRequest.request;

  if (response === null || response[0] === null) {
    return buildTraceNoData(source, resampling, propertyColor, chartQuality);
  }

  if (isLocationSource)
    response = response.filter((el) => el.sensorName === source.sensorName);

  const firstSensor = response[0];

  return buildTraceData(
    firstSensor,
    source,
    propertyColor,
    resampling,
    chartQuality,
    isMinMaxEnabled
  );
};

export const getSourceIdentifier = (
  source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource
) => {
  if (isScalarDataChartLocationSource(source)) {
    return `${source.deviceCategoryCode}-${source.locationCode}-${source.sensorName}`;
  }
  return `${source.deviceCode}-${source.sensorName}-${source.dateFrom}-${source.dateTo}`;
};

export const getDateRangeIdentifier = (dateRange: DateRange) =>
  `${dateRange[0]}-${dateRange[1]}`;

export const getDevicesOneDCQueryFn = async (
  source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource,
  dateRange: DateRange,
  onError: (message: string) => void
) => {
  if (dateRange[0].isAfter(dateRange[1])) {
    return [];
  }

  const res = isScalarDataChartLocationSource(source)
    ? await DeviceUtils.getDevicesOneDC(
        source.deviceCategoryCode,
        source.locationCode,
        undefined,
        dateRange[0].format(),
        dateRange[1].format()
      )
    : await DeviceUtils.getDevicesOneDC(
        undefined,
        undefined,
        source.deviceCode,
        dateRange[0].format(),
        dateRange[1].format()
      );
  if (res[0]) {
    if (res[0] !== 'Request failed with status code 404') {
      onError(res[0]);
    }
  }
  return res;
};

export const getAdjustedDateRange = (
  source: ScalarDataChartDeploymentSource | ScalarDataChartLocationSource,
  dateRange: DateRange
) => {
  const adjustedDateRange: DateRange = [...dateRange];
  if (!isScalarDataChartLocationSource(source)) {
    if (source.dateFrom) {
      adjustedDateRange[0] = moment.max(dateRange[0], source.dateFrom);
    }
    if (source.dateTo) {
      adjustedDateRange[1] = moment.min(dateRange[1], source.dateTo);
    }
  }

  return adjustedDateRange;
};
