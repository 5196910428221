import { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@mui/styles';
import DeviceDetailsLink from 'domain/AppComponents/link/DeviceDetailsLink';
import DeviceInfo from 'domain/Apps/device-listing/DeviceInfo';
import DeviceService from 'domain/services/DeviceService';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';

const styles = (theme) => ({
  table: {
    'overflow-x': 'hidden',
    margin: theme.spacing(1),
    minWidth: theme.spacing(75),
  },
});

const headers = [
  { title: 'Device ID', name: 'deviceId' },
  { title: 'Device Name', name: 'deviceName' },
  { title: 'Security Tier', name: 'securityTier' },
  { title: 'Device Id For Search', name: 'deviceIdForSearch' },
  { title: 'Device Type ID', name: 'deviceTypeId' },
  { title: 'Device Type Name', name: 'deviceTypeName' },
  { title: 'Device Category ID', name: 'deviceCategoryId' },
  { title: 'Device Category Name', name: 'deviceCategoryName' },
];

const tableColumnExtensions = [
  {
    columnName: 'deviceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceName',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'securityTier',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceIdForSearch',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'deviceTypeId',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'deviceTypeName',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'deviceCategoryId',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'deviceCategoryName',
    width: ColumnInfo.mini,
  },
];

type Props = {
  permission: string;
  deviceCategoryId: number;
  deviceTypeId: number;
  onError: (message: any, callback?: any) => void;
  classes: { table: string };
};

interface DeviceTableRowInfo extends DeviceInfo {
  deviceId: any;
  deviceIdForSearch: number;
  securityTier?: any;
}

const DeviceListingTable = ({
  permission,
  deviceCategoryId,
  onError,
  classes,
  deviceTypeId,
}: Props) => {
  const [tableRows, setTableRows] = useState<Array<DeviceTableRowInfo>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const AllDropDownValue = -1;

  const buildTableRows = (deviceInfos: DeviceInfo[]) => {
    if (!deviceInfos) {
      return [];
    }
    const newTableRows = deviceInfos.map((device) => {
      const returnValue: DeviceTableRowInfo = {
        deviceIdForSearch: device.deviceId,
        deviceName: device.deviceName,
        deviceId: <DeviceDetailsLink deviceId={device.deviceId} />,
        deviceTypeId: device.deviceTypeId,
        deviceTypeName: device.deviceTypeName,
        deviceCategoryName: device.deviceCategoryName,
        deviceCategoryId: device.deviceCategoryId,
      };
      if (device.security === 3) {
        returnValue.securityTier = 'Low';
      } else if (device.security === 2) {
        returnValue.securityTier = 'Medium';
      } else {
        returnValue.securityTier = 'High';
      }
      return returnValue;
    });
    return newTableRows;
  };

  const refreshTableData = useCallback(async () => {
    try {
      let payload;
      setIsLoading(true);
      if (
        deviceCategoryId !== AllDropDownValue &&
        deviceTypeId === AllDropDownValue
      ) {
        payload =
          await DeviceService.getDevicesForDeviceCategoryId(deviceCategoryId);
      } else if (
        deviceTypeId !== AllDropDownValue &&
        deviceCategoryId === AllDropDownValue
      ) {
        payload = await DeviceService.getDevicesForDeviceTypeId(deviceTypeId);
      } else if (
        deviceCategoryId !== AllDropDownValue &&
        deviceTypeId !== AllDropDownValue
      ) {
        payload = await DeviceService.getDevicesForDeviceTypeAndCategoryId(
          deviceTypeId,
          deviceCategoryId
        );
      } else {
        payload = await DeviceService.getAllDevices();
      }
      setTableRows(buildTableRows(payload.devices));
    } catch (e) {
      onError(e);
      setTableRows([]);
    } finally {
      setIsLoading(false);
    }
  }, [deviceCategoryId, AllDropDownValue, deviceTypeId, onError]);

  useEffect(() => {
    refreshTableData();
  }, [refreshTableData]);

  const compareDeviceId = (a, b) => {
    const idA = a.props.deviceId;
    const idB = b.props.deviceId;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  if (!tableRows) return undefined;

  return (
    <Panel>
      <SortableTable
        columns={headers}
        columnSizes={tableColumnExtensions}
        columnExtensions={tableColumnExtensions}
        elevation={0}
        pageSize={15}
        pageSizes={[15, 30, 60, 300]}
        rows={tableRows}
        defaultSorting={[{ columnName: 'deviceName', direction: 'asc' }]}
        searchable
        searchBarMoveable={permission === 'RW'}
        stripedRows
        classes={classes.table}
        hiddenColumns={
          permission !== 'RW'
            ? ['securityTier', 'deviceIdForSearch']
            : ['deviceIdForSearch']
        }
        isLoading={isLoading}
        sortExtensions={[
          {
            columnName: 'deviceId',
            compare: compareDeviceId,
          },
        ]}
      />
    </Panel>
  );
};

export default withStyles(styles)(withSnackbars(DeviceListingTable));
