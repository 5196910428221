import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid } from 'base-components';
import AllDevicesAutocomplete from 'domain/AppComponents/dropdowns/AllDevicesAutocomplete';
import QaqcDetailsDeviceCategoryDropdown from 'domain/AppComponents/dropdowns/QaqcDetailsDeviceCategoryDropdown';
import QaqcDetailsDeviceDropdown from 'domain/AppComponents/dropdowns/QaqcDetailsDeviceDropdown';
import QaqcDetailsFormulaDropdown from 'domain/AppComponents/dropdowns/QaqcDetailsFormulaDropdown';
import QaqcDetailsSensorDropdown from 'domain/AppComponents/dropdowns/QaqcDetailsSensorDropdown';
import QaqcDetailsSensorTypeDropdown from 'domain/AppComponents/dropdowns/QaqcDetailsSensorTypeDropdown';
import QaqcDetailsStationDropdown from 'domain/AppComponents/dropdowns/QaqcDetailsStationDropdown';
import QaqcDetailsStudyAreaDropdown from 'domain/AppComponents/dropdowns/QaqcDetailsStudyAreaDropdown';
import QaqcDetailsTestGroupDropdown from 'domain/AppComponents/dropdowns/QaqcDetailsTestGroupDropdown';
import QaqcDetailsTestLevelDropdown from 'domain/AppComponents/dropdowns/QaqcDetailsTestLevelDropdown';
import {
  INSTRUMENT,
  MANUAL,
  OBSERVATORY,
  QAQC_TEST_GROUP_OBSERVATORY_ID,
  QAQC_TEST_GROUP_REGION_ID,
  REGION,
  STATION,
  STUDY_AREA,
} from 'domain/services/QaqcAutotestsConstants';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  containerDiv: {
    margin: theme.spacing(1),
  },
  dropdown: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
  roFieldRoot: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
});

class QaqcDetailsPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    idValues: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    permission: PropTypes.string.isRequired,
    qaqcExists: PropTypes.bool,
    sensorExists: PropTypes.bool,
    testLevel: PropTypes.string,
    testLevelValue: PropTypes.number,
    onChangeFormula: PropTypes.func,
    onGetFormulas: PropTypes.func,
    onGetSensors: PropTypes.func,
    onIdValuesChange: PropTypes.func,
    onTestLevelValueChange: PropTypes.func,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    idValues: {
      formulaId: 0,
      deviceCategoryId: 0,
      deviceAutocomplete: null,
      deviceId: 0,
      qaqcTestGroupId: 0,
      searchTreeNodeId: 0,
      sensorId: 0,
      sensorTypeId: 0,
      studyAreaId: 0,
    },
    qaqcExists: false,
    sensorExists: false,
    testLevel: '',
    testLevelValue: 0,
    onChangeFormula: () => {},
    onGetFormulas: () => {},
    onGetSensors: () => {},
    onIdValuesChange: () => {},
    onTestLevelValueChange: () => {},
  };

  getDropdownComponent = () => {
    const {
      classes,
      idValues,
      permission,
      qaqcExists,
      sensorExists,
      testLevel,
      onChangeFormula,
      onGetFormulas,
      onGetSensors,
      onIdValuesChange,
      onInfo,
      onError,
    } = this.props;
    const {
      formulaId,
      deviceCategoryId,
      deviceAutocomplete,
      deviceId,
      qaqcTestGroupId,
      searchTreeNodeId,
      sensorId,
      sensorTypeId,
      studyAreaId,
    } = idValues;
    switch (testLevel) {
      case INSTRUMENT:
        return (
          <>
            {qaqcExists || sensorExists ? (
              <>
                <QaqcDetailsDeviceCategoryDropdown
                  classes={classes}
                  className={classes.dropdown}
                  disabled={qaqcExists || sensorExists}
                  fullWidth
                  name="deviceCategoryId"
                  permission={permission}
                  value={deviceCategoryId}
                  onChange={onIdValuesChange}
                  onInfo={onInfo}
                  onError={onError}
                />
                <QaqcDetailsDeviceDropdown
                  classes={classes}
                  className={classes.dropdown}
                  deviceCategoryId={deviceCategoryId}
                  disabled={qaqcExists || sensorExists}
                  fullWidth
                  name="deviceId"
                  permission={permission}
                  value={deviceId}
                  onChange={onIdValuesChange}
                  onInfo={onInfo}
                  onError={onError}
                />
              </>
            ) : (
              <AllDevicesAutocomplete
                name="device"
                onChange={onIdValuesChange}
                onError={onError}
                value={deviceAutocomplete}
                disabled={qaqcExists || sensorExists}
              />
            )}
            <QaqcDetailsSensorDropdown
              classes={classes}
              className={classes.dropdown}
              deviceId={deviceId}
              disabled={deviceId === 0 || qaqcExists || sensorExists}
              fullWidth
              name="sensorId"
              permission={permission}
              value={sensorId}
              onGetSensors={onGetSensors}
              onChange={onIdValuesChange}
              onInfo={onInfo}
              onError={onError}
            />
            <QaqcDetailsFormulaDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={sensorId === 0 || qaqcExists}
              fullWidth
              name="formulaId"
              permission={permission}
              testLevel={testLevel}
              value={formulaId}
              onGetFormulas={onGetFormulas}
              onChange={onChangeFormula}
              onInfo={onInfo}
              onError={onError}
            />
          </>
        );
      case REGION:
      case OBSERVATORY:
        return (
          <>
            <QaqcDetailsTestGroupDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={qaqcExists}
              fullWidth
              name="qaqcTestGroupId"
              permission={permission}
              testGroupTypeId={
                testLevel === REGION
                  ? QAQC_TEST_GROUP_REGION_ID
                  : QAQC_TEST_GROUP_OBSERVATORY_ID
              }
              value={qaqcTestGroupId}
              onChange={onIdValuesChange}
              onInfo={onInfo}
              onError={onError}
            />
            <QaqcDetailsSensorTypeDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={qaqcTestGroupId === 0 || qaqcExists}
              fullWidth
              name="sensorTypeId"
              permission={permission}
              value={sensorTypeId}
              onChange={onIdValuesChange}
              onInfo={onInfo}
              onError={onError}
            />
            <QaqcDetailsFormulaDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={sensorTypeId === 0 || qaqcExists}
              fullWidth
              name="formulaId"
              permission={permission}
              value={formulaId}
              testLevel={testLevel}
              onGetFormulas={onGetFormulas}
              onChange={onChangeFormula}
              onInfo={onInfo}
              onError={onError}
            />
          </>
        );
      case STATION:
        return (
          <>
            <QaqcDetailsStationDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={qaqcExists}
              fullWidth
              name="searchTreeNodeId"
              permission={permission}
              value={searchTreeNodeId}
              onChange={onIdValuesChange}
              onInfo={onInfo}
              onError={onError}
            />
            <QaqcDetailsSensorTypeDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={searchTreeNodeId === 0 || qaqcExists}
              fullWidth
              name="sensorTypeId"
              permission={permission}
              value={sensorTypeId}
              onChange={onIdValuesChange}
              onInfo={onInfo}
              onError={onError}
            />
            <QaqcDetailsFormulaDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={sensorTypeId === 0 || qaqcExists}
              fullWidth
              name="formulaId"
              permission={permission}
              testLevel={testLevel}
              value={formulaId}
              onGetFormulas={onGetFormulas}
              onChange={onChangeFormula}
              onInfo={onInfo}
              onError={onError}
            />
          </>
        );
      case STUDY_AREA:
        return (
          <>
            <QaqcDetailsStudyAreaDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={qaqcExists}
              fullWidth
              name="studyAreaId"
              permission={permission}
              value={studyAreaId}
              onChange={onIdValuesChange}
              onInfo={onInfo}
              onError={onError}
            />
            <QaqcDetailsSensorTypeDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={studyAreaId === 0 || qaqcExists}
              fullWidth
              name="sensorTypeId"
              permission={permission}
              value={sensorTypeId}
              onChange={onIdValuesChange}
              onInfo={onInfo}
              onError={onError}
            />
            <QaqcDetailsFormulaDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={sensorTypeId === 0 || qaqcExists}
              fullWidth
              name="formulaId"
              permission={permission}
              testLevel={testLevel}
              value={formulaId}
              onGetFormulas={onGetFormulas}
              onChange={onChangeFormula}
              onInfo={onInfo}
              onError={onError}
            />
          </>
        );
      case MANUAL:
        return (
          <QaqcDetailsFormulaDropdown
            classes={classes}
            className={classes.dropdown}
            disabled={qaqcExists}
            fullWidth
            name="formulaId"
            permission={permission}
            testLevel={testLevel}
            value={formulaId}
            onGetFormulas={onGetFormulas}
            onChange={onChangeFormula}
            onInfo={onInfo}
            onError={onError}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      classes,
      permission,
      sensorExists,
      qaqcExists,
      testLevel,
      testLevelValue,
      onTestLevelValueChange,
      onInfo,
      onError,
    } = this.props;

    return (
      <div className={classes.containerDiv}>
        <Grid container direction="column">
          <Grid item lg={4} md={6} sm={12}>
            <QaqcDetailsTestLevelDropdown
              classes={classes}
              className={classes.dropdown}
              disabled={sensorExists || qaqcExists || permission !== 'RW'}
              fullWidth
              permission={permission}
              testLevel={testLevel}
              value={testLevelValue}
              onChange={onTestLevelValueChange}
              onInfo={onInfo}
              onError={onError}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            {this.getDropdownComponent()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(QaqcDetailsPage);
