/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import * as React from 'react';
import { Grid } from 'base-components';
import TaxonAsyncAutocomplete, {
  TaxonOption,
} from 'domain/AppComponents/dropdowns/TaxonAsyncAutocomplete';
import TaxonomySelect from 'domain/AppComponents/dropdowns/TaxonomySelect';
import Filter, { FilterEvent } from 'library/CompositeComponents/filter/Filter';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

type Props = {
  value: any;
  name: string;
  onChange: (event: FilterEvent) => void;
};

const TaxonomyFilter: React.VFC<Props> = (props: Props): React.ReactElement => {
  const { onChange, name, value } = props;
  const [taxonomy, setTaxonomy] = useState(value ? value.taxonomy : undefined);
  const [taxon, setTaxon] = useState(value ? value.taxon : null);
  const [userDefined, setUserDefined] = useState<boolean>(false);
  const IMPORTED_TAXONOMIES = ['1', '3']; // WoRMS and CMECS

  useEffect(() => {
    if (!value) {
      setTaxonomy(undefined);
      setTaxon(null);
    } else {
      setTaxonomy(value.taxonomy);
      setTaxon(value.taxon ? value.taxon : null);
    }
  }, [value]);
  // Call onChange any time we update the values
  useEffect(() => {
    onChange({
      target: {
        value: { taxonomy, taxon },
        name,
      },
    });
  }, [taxonomy, taxon]);

  const handleTaxonomyChange = (e) => {
    setUserDefined(!IMPORTED_TAXONOMIES.includes(e.target.value));
    setTaxonomy(e.target.value);
    setTaxon(null);
  };

  const handleClearTaxonomy = () => {
    setTaxonomy(undefined);
    setTaxon(null);
  };

  const handleTaxonChange = (e, val: TaxonOption) => {
    val ? setTaxon(val) : setTaxon(null);
  };

  return (
    <Filter title="Taxonomy" onChange={onChange} name={name} value={value}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TaxonomySelect
            fullWidth
            onChange={handleTaxonomyChange}
            value={value ? value.taxonomy : undefined}
            onClear={handleClearTaxonomy}
          />
        </Grid>
        <Grid item xs={6}>
          <TaxonAsyncAutocomplete
            taxonomyId={taxonomy}
            onChange={handleTaxonChange}
            taxonId={taxon?.taxonId}
            searchLettersRequired={userDefined ? 1 : 3}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default withSnackbars(TaxonomyFilter);
