import { FC } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Close } from '@onc/icons';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
} from 'base-components';
import CastLassoSelection from 'domain/Apps/geospatial-search/community-fisher/CastLassoSelection';
import Environment from 'util/Environment';
import useFabDialogStyles from '../fab-buttons/useFabDialogStyles';

interface CastSelectionDialogProps {
  /* Whether all casts should be selected or empty */
  toggleAllCastsValue?: boolean;
  /* Filter function to call to refine casts */
  filterCasts: (casts: any) => any;
  /* Callback when a child checkbox is toggled */
  childCheckBoxOnChange: (subset: any) => void;
  /* Callback when a parent checkbox is toggled */
  parentCheckBoxOnChange: (subset: any) => void;
  /* Callback to clear all sitedevicesubsets */
  clearAllSubsets: () => void;
  /* Callback when selecting all subsets */
  toggleAllSubsets: () => void;
  /* Callback when the cast selection is closed */
  closeCastSelection: () => void;
  /* Enable download table */
  onContinueButtonClick: () => void;
}

const CastSelectionDialog: FC<CastSelectionDialogProps> = ({
  toggleAllCastsValue = false,
  onContinueButtonClick,
  filterCasts,
  childCheckBoxOnChange,
  parentCheckBoxOnChange,
  clearAllSubsets,
  toggleAllSubsets,
  closeCastSelection,
}) => {
  const classes = useFabDialogStyles();
  const theme = useTheme();

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      fullScreen={false}
      hideBackdrop
      disableEnforceFocus
      className={classes.dialog}
    >
      <DialogContent>
        <Grow in style={{ transformOrigin: 'bottom right 0' }}>
          <Box className={classes.boxOuter}>
            <Box className={classes.boxInner}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <DialogTitle>Cast Selection</DialogTitle>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={closeCastSelection}
                    className={classes.closeButton}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <DialogContent>
                {!Environment.isUserLoggedIn() && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      color: theme.palette.warning.main,
                      marginBottom: '16px',
                      marginTop: '0px',
                    }}
                  >
                    You can only download the most recent cast data. Please log
                    in to access older data.
                  </Typography>
                )}
                <CastLassoSelection
                  filterCasts={filterCasts}
                  childCheckBoxOnChange={childCheckBoxOnChange}
                  parentCheckBoxOnChange={parentCheckBoxOnChange}
                  continueButtonOnClick={onContinueButtonClick}
                  clearAllSubsets={clearAllSubsets}
                  toggleAllSubsets={toggleAllSubsets}
                  toggleCastsValue={toggleAllCastsValue}
                />
              </DialogContent>
            </Box>
          </Box>
        </Grow>
      </DialogContent>
    </Dialog>
  );
};

export default CastSelectionDialog;
