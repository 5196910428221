import { Dropdown, ErrorPage } from 'base-components';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';
import useGet from 'util/hooks/useDmasAPI/useGet';

type LocationDropdownProps = Omit<
  FormFieldType<React.ComponentProps<typeof Dropdown>>,
  'options'
> & {
  regionId?: number;
  doneLoading?: () => void;
};

type LocationPayload = [
  {
    locationName: string;
    locationId: number;
    regionId: number;
  },
];

const LocationDropdown = ({
  regionId = -1,
  doneLoading = () => {},
  ...props
}: LocationDropdownProps) => {
  const locationData = useGet<LocationPayload, { regionId: number }>(
    'LocationService',
    5
  );

  if (locationData.isLoading) {
    return <FormDropdown {...props} options={[]} />;
  }

  if (locationData.error) {
    return <ErrorPage />;
  }

  const optionsSorted = locationData?.data?.sort((a, b) =>
    a.locationName.localeCompare(b.locationName)
  );

  const options = [{ label: 'All', value: -1, key: '-1' }].concat(
    optionsSorted
      ?.filter((option) => regionId === -1 || option.regionId === regionId)
      ?.map((option) => ({
        label: option.locationName,
        value: option.locationId,
        key: option.locationId.toString(),
      }))
  );

  doneLoading();

  return <FormDropdown options={options} {...props} />;
};

export default LocationDropdown;
