import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class PortService {
  static getOne(portid, deviceid) {
    return PortService.get({ operation: 4, portid, deviceid });
  }

  static getMany(deviceid) {
    return PortService.get({ operation: 5, deviceid });
  }

  static get(params) {
    return get('DevicePortService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static update(params) {
    return post('DevicePortService', {
      operation: 2,
      ...params,
    }).then((response) => response);
  }

  static create(params) {
    return post('DevicePortService', {
      operation: 1,
      ...params,
    }).then((response) => response);
  }

  static delete = (params) =>
    post('DevicePortService', {
      operation: 3,
      ...params,
    }).then((response) => response);
}
export default PortService;
