import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import Moment from 'moment';
import Plotly from 'plotly.js-basic-dist-min';
import PropTypes from 'prop-types';
import createPlotlyComponent from 'react-plotly.js/factory';
import { Dropdown } from 'base-components';

const Plot = createPlotlyComponent(Plotly);

const styles = {
  root: {
    height: '100%',
    width: '100%',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

class EarthquakeDetailPlot extends PureComponent {
  static propTypes = {
    organizationDetections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    oncDetections: PropTypes.arrayOf(
      PropTypes.shape({
        earthquakeDetailId: PropTypes.number,
        hostname: PropTypes.string,
      })
    ).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { hostname: 'All' };
  }

  getHostOptions = () => {
    const { oncDetections } = this.props;
    const hostnames = [{ label: 'All', value: 'All' }];
    const detectionHostnames = [
      ...new Set(oncDetections.map((obj) => obj.hostname)),
    ].map((hostname) => ({ label: hostname, value: hostname }));
    return hostnames.concat(detectionHostnames);
  };

  getPlotData = () => {
    const { organizationDetections, oncDetections } = this.props;
    const { hostname } = this.state;
    if (!oncDetections || oncDetections.length === 0) {
      return [];
    }
    let filteredOncDetections = oncDetections;
    if (hostname !== 'All') {
      filteredOncDetections = oncDetections.filter(
        (det) => det.hostname === hostname
      );
    }
    const oncOrgDetection = organizationDetections.filter(
      (det) => det.earthquakeDetailId === oncDetections[0].earthquakeDetailId
    );
    if (oncOrgDetection.length === 0) {
      return [];
    }
    const originTime = Moment.utc(oncOrgDetection[0].originTime);
    const xData = filteredOncDetections.map(
      (det) => (Moment.utc(det.reportedTime) - originTime) / 1000
    );
    const yData = filteredOncDetections.map((det) => det.magnitude);
    return [xData, yData];
  };

  handleHostChange = (input) => {
    this.setState({ hostname: input.target.value });
  };

  render() {
    const { className } = this.props;
    const { hostname } = this.state;
    const plotData = this.getPlotData();
    const hostnames = this.getHostOptions();
    return (
      <>
        <Dropdown
          disabled={false}
          options={hostnames}
          onChange={this.handleHostChange}
          value={hostname}
          label="Magnitudes From Host"
          className={className}
          fullWidth
        />
        <Plot
          data={[
            {
              x: plotData[0],
              y: plotData[1],
              type: 'scatter',
              mode: 'lines+markers',
              marker: { color: 'red' },
            },
          ]}
          layout={{
            title: 'Magnitude Evolution',
            xaxis: { title: 'Time after earthquake (sec)' },
            yaxis: { title: 'Magnitude' },
          }}
          style={{ width: '100%', height: '100%' }}
        />
      </>
    );
  }
}

export default withStyles(styles)(EarthquakeDetailPlot);
