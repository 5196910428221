import { useTheme } from '@mui/material/styles';
import { CardOld as Card, ReadOnlyTextField } from 'base-components';
import {
  CalibratedDevice,
  NavDevice,
} from 'domain/Apps/community-fishers/CommunityFishersDevicePackageTypes';
import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import DeviceDetailsLink from '../link/DeviceDetailsLink';

type Props = {
  deviceInformation: NavDevice & CalibratedDevice;
  deviceType: 'CTD' | 'Tablet' | 'Piggyback';
};

const CommunityFishersDevicePackageCard: React.FC<Props> = ({
  deviceInformation,
  deviceType,
}: Props) => {
  const theme = useTheme();

  if (!deviceInformation) {
    return (
      <Card
        title={
          <span style={{ color: theme.palette.error.main }}>
            {`${deviceType}: `}
            <abbr title="Not Assigned">N/A</abbr>
          </span>
        }
      />
    );
  }

  const isTablet = deviceType === 'Tablet';

  const firstProcurementOrLatestCalibration = isTablet
    ? deviceInformation.firstProcurement
    : deviceInformation.latestDeviceCalibration;

  const { latestDeviceDataValidation } = deviceInformation;

  const { latestDeviceAction } = deviceInformation;
  let alreadyDisplayedLatestDeviceAction = false;

  if (latestDeviceAction) {
    const deviceActions = [
      latestDeviceDataValidation,
      firstProcurementOrLatestCalibration,
    ];
    alreadyDisplayedLatestDeviceAction = deviceActions.some(
      (deviceAction) =>
        deviceAction &&
        deviceAction.datePerformed === latestDeviceAction.datePerformed
    );
  }

  return (
    <Card
      title={
        <DeviceDetailsLink
          deviceId={deviceInformation.deviceId}
          tabName="workflow_tab"
        >
          {`${deviceType}: ${deviceInformation.deviceId}`}
        </DeviceDetailsLink>
      }
      supportingContent={
        <>
          <ReadOnlyTextField
            label="Device Name"
            value={deviceInformation.deviceName}
          />
          <ReadOnlyTextField
            label="Device Type"
            value={
              <OpenInNewLink href="/DeviceType">
                {deviceInformation.deviceTypeName} (
                {deviceInformation.deviceTypeId})
              </OpenInNewLink>
            }
          />
          {deviceInformation.deviceOwner && (
            <ReadOnlyTextField
              label="Device Owner"
              value={deviceInformation.deviceOwner}
            />
          )}
          {deviceInformation.attributedDataOwners?.length > 0 && (
            <ReadOnlyTextField
              label="Attributed Data Owner"
              value={deviceInformation?.attributedDataOwners?.join(', ')}
            />
          )}
          {firstProcurementOrLatestCalibration && (
            <>
              <br />
              <br />
              <ReadOnlyTextField
                label={isTablet ? 'First Procurement' : 'Latest Calibration'}
                value={firstProcurementOrLatestCalibration.datePerformed}
              />
              <ReadOnlyTextField
                label="Description"
                value={firstProcurementOrLatestCalibration.description}
              />
              <ReadOnlyTextField
                label="Action"
                value={firstProcurementOrLatestCalibration.action}
              />
            </>
          )}
          {latestDeviceDataValidation && (
            <>
              <br />
              <br />
              <ReadOnlyTextField
                label="Latest Data Validation"
                value={latestDeviceDataValidation.datePerformed}
              />
              <ReadOnlyTextField
                label="Description"
                value={latestDeviceDataValidation.description}
              />
              <ReadOnlyTextField
                label="Action"
                value={latestDeviceDataValidation.action}
              />
            </>
          )}
          {latestDeviceAction && (
            <>
              <br />
              <br />
            </>
          )}
          {alreadyDisplayedLatestDeviceAction && (
            <ReadOnlyTextField
              label="Latest Device Action"
              value={latestDeviceAction.action}
            />
          )}
          {!alreadyDisplayedLatestDeviceAction && latestDeviceAction && (
            <>
              <ReadOnlyTextField
                label="Latest Device Action"
                value={latestDeviceAction.datePerformed}
              />
              <ReadOnlyTextField
                label="Description"
                value={latestDeviceAction.description}
              />
              <ReadOnlyTextField
                label="Action"
                value={latestDeviceAction.action}
              />
            </>
          )}
        </>
      }
    />
  );
};
export default CommunityFishersDevicePackageCard;
