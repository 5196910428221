import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Loading } from '@onc/composite-components';
import { ErrorPage, Grid, Typography } from 'base-components';
import { CastReviewRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import SiteDeviceSubsetSelector from 'domain/AppComponents/site-device-maintenance/SiteDeviceSubsetSelector';
import SiteDeviceSubsetService from 'domain/services/SiteDeviceSubsetService';
import {
  ClearButton,
  ContainedButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { useSnackbars } from 'util/hooks/useSnackbars';
import SiteDeviceSubsetTable from './SiteDeviceSubsetTable';

const classes = {
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    marginBottom: 1,
  },
  gridPadding: {
    paddingLeft: 3,
    paddingTop: 1,
    paddingRight: 3,
  },
};

type SiteDeviceSubsetsPayload = {
  records: [
    {
      comment: string;
      castReviewed: boolean;
      siteDeviceSubsetId: number;
      siteDeviceSubsetType: number;
    },
  ];
};

const CastReviewManagement = () => {
  const { onError, onInfo } = useSnackbars();

  const [selectedSiteDeviceSubsets, setSelectedSiteDeviceSubsets] = useState(
    []
  );
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [radioButtonValue, setRadioButtonValue] = useState<string>('');

  const siteDeviceSubsetsData = useGet<
    SiteDeviceSubsetsPayload,
    { expanded?: boolean }
  >('SiteDeviceSubsetService', 5, {
    expanded: true,
  });

  const castSubsetTypes = useGet<number[]>('SiteDeviceSubsetService', 12);

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: {
      comment: '',
    },
  });

  if (siteDeviceSubsetsData.isLoading) {
    return <Loading />;
  }

  // prevent double redirect caused by failing service calls
  if (
    siteDeviceSubsetsData.error &&
    // eslint-disable-next-line no-underscore-dangle
    !(window as any).__hasRedirectedToErrorPage
  ) {
    // eslint-disable-next-line no-underscore-dangle
    (window as any).__hasRedirectedToErrorPage = true;
    return <ErrorPage />;
  }

  const siteDeviceSubsets = siteDeviceSubsetsData.data?.records?.filter(
    (subset) => castSubsetTypes.data?.includes(subset.siteDeviceSubsetType)
  );

  const onClearClick = () => {
    setRadioButtonValue('');
    formMethods.reset();
    setSelectedSiteDeviceSubsets([]);
    setDisableSubmit(true);
  };

  const handleSubmit = (values) => {
    let castCount = 0;
    let successCount = 0;
    const successOutput = [];
    if (selectedSiteDeviceSubsets.length === 0) {
      onError('Please select at least one Site Device Subset');
      return;
    }
    const castReviewed = radioButtonValue === 'true';
    selectedSiteDeviceSubsets.forEach(async (subset) => {
      try {
        await SiteDeviceSubsetService.setCastReviewedStatus(
          subset.siteDeviceSubsetId,
          castReviewed,
          values.comment
        );
        castCount += 1;
        successCount += 1;
        if (successCount < 5) {
          successOutput.push(subset.siteDeviceSubsetName);
        }
        // subsets are now out of date but cache is to slow for a reload to work 100%
        const storedSubset = siteDeviceSubsets.find(
          (element) => element.siteDeviceSubsetId === subset.siteDeviceSubsetId
        );
        storedSubset.castReviewed = castReviewed;
        if (values.comment !== '') {
          storedSubset.comment = values.comment;
        }
      } catch (error) {
        castCount += 1;
        onError(
          `Failed to set review status of ${subset.siteDeviceSubsetName}`
        );
      }
      if (castCount === selectedSiteDeviceSubsets.length) {
        if (successCount < 5 && successCount > 0) {
          onInfo(`${successOutput.join(', ')} updated.`);
        }
        onInfo(`${successCount} casts have been updated.`);
      }
    });
    onClearClick();
  };

  return (
    <>
      <SiteDeviceSubsetSelector
        siteDeviceSubsets={siteDeviceSubsets}
        selectedSubsets={selectedSiteDeviceSubsets}
        selectionCallBack={(subsets) => {
          setSelectedSiteDeviceSubsets(
            selectedSiteDeviceSubsets.concat(subsets)
          );
        }}
      />

      <Form onSubmit={handleSubmit} formMethods={formMethods}>
        <Grid container>
          <Grid item xs={12}>
            <SiteDeviceSubsetTable
              onInfo={onInfo}
              onError={onError}
              siteDeviceSubsetRows={selectedSiteDeviceSubsets}
              pageSize={10}
              customDeleteActionOnChange={(siteDeviceSubsetId) => {
                setSelectedSiteDeviceSubsets(
                  selectedSiteDeviceSubsets.filter(
                    (subset) => subset.siteDeviceSubsetId !== siteDeviceSubsetId
                  )
                );
              }}
              renderAddButton={false}
              showReviewStatus
            />
          </Grid>
          <Grid item xs={12} sx={classes.formButtons}>
            <ClearButton onClick={onClearClick} />
          </Grid>
          <Grid item xs={12} sx={classes.gridPadding}>
            <Typography>Set cast review status to:</Typography>
            <CastReviewRadioGroup
              value={radioButtonValue}
              title="reviewRadio"
              name="reviewRadio"
              onChange={(e) => {
                setRadioButtonValue(e.target.value);
                setDisableSubmit(false);
              }}
            />
          </Grid>
          <Grid item xs={12} sx={classes.gridPadding}>
            <FormTextField
              name="comment"
              translationKey="common.textfields.comment"
              multiline
              rows={9}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={classes.formButtons}>
            <ContainedButton
              translationKey="common.buttons.submit"
              type="submit"
              disabled={disableSubmit}
            />
            <ClearButton onClick={onClearClick} />
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default CastReviewManagement;
