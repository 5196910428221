import { Component } from 'react';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Divider,
  HelpLink,
  LabelledCheckbox,
  Typography,
} from 'base-components';
import Panel from 'library/CompositeComponents/panel/Panel';

import DiveDetails from './DiveDetails';

class DiveDetailsPanel extends Component {
  static propTypes = {
    menu: PropTypes.node,
    permissions: PropTypes.bool,
    showExtraDiveInfo: PropTypes.bool,
    onToggleShowMoreInfo: PropTypes.func,
  };

  static defaultProps = {
    menu: undefined,
    permissions: false,
    showExtraDiveInfo: false,
    onToggleShowMoreInfo: () => {},
  };

  state = {
    diveDetails: undefined,
  };

  renderMenu = () => {
    const { menu, showExtraDiveInfo, onToggleShowMoreInfo, permissions } =
      this.props;
    return (
      <div>
        {permissions ? (
          <MenuItem onClick={this.handleToggleExtraInfo}>
            <LabelledCheckbox
              label="Show more details"
              value={showExtraDiveInfo}
              onClick={onToggleShowMoreInfo}
            />
          </MenuItem>
        ) : null}
        ;
        <HelpLink url="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help#SeaTubeV3Help-DiveDetails" />
        <Divider />
        {menu}
      </div>
    );
  };

  render() {
    const { menu, ...rest } = this.props;
    return (
      <Panel
        title={<Typography variant="body1">Dive Details</Typography>}
        headerDraggable
        menu={this.renderMenu()}
        menuProps={{
          buttonLabel: 'Dive Details Menu',
        }}
      >
        <DiveDetails {...rest} />
      </Panel>
    );
  }
}

export default DiveDetailsPanel;
