import { InputLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid, Link, Typography } from 'base-components';

const STYLES = (theme) => ({
  readOnlyStyle: {
    fontSize: '1rem',
    transform: 'translate(0, 1.5px) scale(0.75)',
    color: 'rgba(0, 0, 0, 0.54)',
    transformOrigin: 'top left',
  },
  root: {},
  readOnlyMarginTop: {
    marginTop: theme.spacing(2),
  },
  readOnlyContainer: {
    paddingLeft: '12px',
  },
});
const propTypes = {
  title: PropTypes.string,
  classes: PropTypes.shape({
    readOnlyStyle: PropTypes.string,
    root: PropTypes.string,
  }),
  dense: PropTypes.bool,
  id: PropTypes.string,
};

/* Base Components */

const UnstyledReadOnlyField = ({
  labelText,
  valueText = undefined,
  classes = undefined,
  dense = false,
}) => (
  <Grid className={classes.root} item xs={12}>
    <Typography className={classes.readOnlyStyle}>{labelText}</Typography>
    <Typography
      variant={dense ? 'body1' : 'body2'}
      aria-label={valueText ? labelText : undefined}
    >
      {valueText}
    </Typography>
  </Grid>
);

const UnstyledBaseReadOnlyField = ({
  labelText,
  valueText = undefined,
  classes = undefined,
  dense = false,
}) => (
  <div className={classes.readOnlyContainer}>
    <Typography className={classes.readOnlyStyle}>{labelText}</Typography>
    <Typography variant={dense ? 'body1' : 'body2'}>{valueText}</Typography>
  </div>
);

const BaseReadOnlyField = withStyles(STYLES)(UnstyledBaseReadOnlyField);
const ReadOnlyField = withStyles(STYLES)(UnstyledReadOnlyField);

/* Exported Fields */

const ModifyBy = ({ username = undefined, classes = undefined }) => (
  <ReadOnlyField
    labelText="Modified By"
    valueText={username}
    classes={classes}
  />
);

const ModifyDate = ({ date = undefined, classes = undefined }) => (
  <ReadOnlyField labelText="Modified Date" valueText={date} classes={classes} />
);

const ColourField = (props) => (
  <MarginedReadOnlyField labelText="Color" {...props} />
);

const ButtonTypeField = (props) => (
  <MarginedReadOnlyField labelText="Button Type" {...props} />
);

const TaxonomyField = (props) => (
  <MarginedReadOnlyField labelText="Taxonomy" {...props} />
);

const TaxonField = (props) => (
  <MarginedReadOnlyField labelText="Taxon" {...props} />
);

const AttributeValueField = (props) => (
  <MarginedReadOnlyField labelText="Attribute Value" {...props} />
);

const AttributeField = (props) => (
  <MarginedReadOnlyField labelText="Attribute" {...props} />
);

const DateFrom = ({ date = undefined, classes = undefined }) => (
  <ReadOnlyField
    labelText="Date From (UTC)"
    valueText={date}
    classes={classes}
  />
);

const DateTo = ({ date = undefined, classes = undefined }) => (
  <ReadOnlyField labelText="Date To (UTC)" valueText={date} classes={classes} />
);

const Device = ({ device = undefined, classes = undefined }) => (
  <ReadOnlyField labelText="Device" valueText={device} classes={classes} />
);

const DeviceCategory = ({
  deviceCategory = undefined,
  classes = undefined,
}) => (
  <ReadOnlyField
    labelText="Device Category"
    valueText={deviceCategory}
    classes={classes}
  />
);

const Formula = ({ formula = undefined, classes = undefined }) => (
  <ReadOnlyField labelText="Formula" valueText={formula} classes={classes} />
);

const QaqcTestGroup = ({ qaqcTestGroup = undefined, classes = undefined }) => (
  <ReadOnlyField
    labelText="Qaqc Test Group"
    valueText={qaqcTestGroup}
    classes={classes}
  />
);

const Sensor = ({ sensor = undefined, classes = undefined }) => (
  <ReadOnlyField labelText="Sensor" valueText={sensor} classes={classes} />
);

const SensorType = ({ sensorType = undefined, classes = undefined }) => (
  <ReadOnlyField
    labelText="Sensor Type"
    valueText={sensorType}
    classes={classes}
  />
);

const Station = ({ station = undefined, classes = undefined }) => (
  <ReadOnlyField labelText="Station" valueText={station} classes={classes} />
);

const StudyArea = ({ studyArea = undefined, classes = undefined }) => (
  <ReadOnlyField
    labelText="Study Area"
    valueText={studyArea}
    classes={classes}
  />
);

const TestLevel = ({ testLevel = undefined, classes = undefined }) => (
  <ReadOnlyField
    labelText="Test Level"
    valueText={testLevel}
    classes={classes}
  />
);

const UnstyledMarginedReadOnlyField = ({
  labelText,
  valueText = undefined,
  classes = undefined,
  dense = false,
  id,
}) => (
  <Grid item xs={12}>
    <InputLabel
      className={`${classes.readOnlyStyle} ${classes.readOnlyMarginTop}`}
      htmlFor={id}
    >
      {labelText}
    </InputLabel>
    <Typography
      variant={dense ? 'body1' : 'body2'}
      id={id}
      // only set an aria-label if there is text to draw
      aria-label={valueText ? labelText : undefined}
    >
      {valueText}
    </Typography>
  </Grid>
);

const UnstyledLinkField = ({
  labelText,
  valueText,
  url,
  classes = undefined,
  dense = false,
  id,
}) => (
  <Grid item xs={12}>
    <InputLabel
      className={`${classes.readOnlyStyle} ${classes.readOnlyMarginTop}`}
    >
      {labelText}
    </InputLabel>
    <Link
      target="_blank"
      rel="noopener"
      href={url}
      variant={dense ? 'body1' : 'body2'}
      id={id}
      aria-label={labelText}
    >
      {valueText}
    </Link>
  </Grid>
);

const MarginedReadOnlyField = withStyles(STYLES)(UnstyledMarginedReadOnlyField);
const MarginedLinkField = withStyles(STYLES)(UnstyledLinkField);

/* PropTypes */

UnstyledBaseReadOnlyField.propTypes = {
  ...propTypes,
  labelText: PropTypes.string.isRequired,
  valueText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UnstyledReadOnlyField.propTypes = {
  ...propTypes,
  labelText: PropTypes.string.isRequired,
  valueText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UnstyledMarginedReadOnlyField.propTypes = {
  ...propTypes,
  labelText: PropTypes.string.isRequired,
  valueText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UnstyledLinkField.propTypes = {
  ...propTypes,
  labelText: PropTypes.string.isRequired,
  valueText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

ModifyBy.propTypes = {
  ...propTypes,
  username: PropTypes.string,
};

ModifyDate.propTypes = {
  ...propTypes,
  date: PropTypes.string,
};

DateFrom.propTypes = {
  ...propTypes,
  date: PropTypes.string,
};

DateTo.propTypes = {
  ...propTypes,
  date: PropTypes.string,
};

Device.propTypes = {
  ...propTypes,
  device: PropTypes.string,
};

DeviceCategory.propTypes = {
  ...propTypes,
  deviceCategory: PropTypes.string,
};

Formula.propTypes = {
  ...propTypes,
  formula: PropTypes.string,
};

QaqcTestGroup.propTypes = {
  ...propTypes,
  qaqcTestGroup: PropTypes.string,
};

Sensor.propTypes = {
  ...propTypes,
  sensor: PropTypes.string,
};

SensorType.propTypes = {
  ...propTypes,
  sensorType: PropTypes.string,
};

Station.propTypes = {
  ...propTypes,
  station: PropTypes.string,
};

StudyArea.propTypes = {
  ...propTypes,
  studyArea: PropTypes.string,
};

TestLevel.propTypes = {
  ...propTypes,
  testLevel: PropTypes.string,
};

/* Default props */

export {
  ModifyBy,
  ModifyDate,
  DateFrom,
  DateTo,
  Device,
  DeviceCategory,
  Formula,
  QaqcTestGroup,
  Sensor,
  SensorType,
  Station,
  StudyArea,
  TestLevel,
  ColourField,
  ButtonTypeField,
  TaxonomyField,
  TaxonField,
  AttributeValueField,
  AttributeField,
  ReadOnlyField,
  BaseReadOnlyField,
  MarginedReadOnlyField,
  MarginedLinkField,
};
