import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  TextField,
} from 'base-components';
import PlaylistService, { Playlist } from 'domain/services/PlaylistService';
import { CancelButton } from 'library/CompositeComponents/button/Buttons';

import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import {
  validateCollectionName,
  getPlaylistsIdsForCollection,
} from './CollectionUtil';

type EditCollectionDialogProps = {
  open: boolean;
  groups: string[];
  groupName: string;
  playlists: Playlist[];
  onClose: () => void;
  onRefresh: () => void;
  onSelectGroup: (group: string) => void;
};

const EditCollectionDialog: React.FC<EditCollectionDialogProps> = ({
  open,
  groups,
  groupName: originalName,
  playlists,
  onClose,
  onRefresh,
  onSelectGroup,
}) => {
  const [, isSaving, updateCollection] = useWebService({
    method: PlaylistService.updateGroupNameMultiplePlaylists,
  });
  const { onError, onInfo } = useSnackbars();
  const [collectionName, setCollectionName] = useState(originalName);
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event?.preventDefault();
    if (error) {
      onError(error);
      return false;
    }
    return updateCollection({
      groupName: collectionName,
      playlistHdrIds: getPlaylistsIdsForCollection(originalName, playlists),
    }).then(() => {
      onInfo('Collection name updated');
      onSelectGroup(collectionName);
      onRefresh();
      onClose();
    });
  };

  const handleChange = (value) => {
    setError(validateCollectionName(value, groups));
    setCollectionName(value);
  };

  useEffect(() => {
    setCollectionName(originalName);
  }, [originalName]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Edit Collection</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            id="collection-name-field"
            translationKey="seatube.collectionName"
            fullWidth
            value={collectionName}
            onChange={(e) => handleChange(e.target.value)}
            error={!!error}
            helperText={error}
            autoFocus
          />
        </form>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <LoadingButton
          disabled={!!error || collectionName === originalName}
          loading={isSaving}
          variant="contained"
          translationKey="common.buttons.save"
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditCollectionDialog;
