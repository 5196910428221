import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type DropdownOption,
  LoadingButton,
} from 'base-components';
import PlaylistLineService, {
  PlaylistLine,
} from 'domain/services/PlaylistLineService';
import SeaTubeVideoService from 'domain/services/SeaTubeVideoService';
import { CancelButton } from 'library/CompositeComponents/button/Buttons';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import PlaylistContext from '../../playlist-playback/PlaylistContext';
import PlaylistClipForm, {
  ClipForm,
  ClipFormErrors,
} from '../forms/PlaylistClipForm';
import PlaylistClipFormValidation from '../forms/PlaylistClipFormValidation';

type Props = {
  initialValue?: Partial<PlaylistLine>;
  open: boolean;
  onCancel: () => void;
  onSuccess?: () => void;
};

const EMPTY_FORM: ClipForm = {
  name: '',
  description: '',
  quality: '',
  dateFrom: null,
  dateTo: null,
};

const EditClipDialog: React.VFC<Props> = ({
  initialValue = undefined,
  open,
  onCancel,
  onSuccess = () => {},
}: Props) => {
  const { playlist, refreshPlaylistLines } = useContext(PlaylistContext);
  const [value, setValue] = useState<ClipForm>(EMPTY_FORM);
  const [errors, setErrors] = useState<ClipFormErrors>({});
  const { onInfo, onError } = useSnackbars();

  const parseQualityOptions = (
    response: { code: string; description: string }[]
  ): DropdownOption[] =>
    response.map((option) => ({
      key: option.code,
      label: option.description,
      value: option.code,
    }));

  const [qualityOptions, , fetchQualityOptions] = useWebService({
    method: SeaTubeVideoService.getAvailableResolutions,
    onError,
    parser: parseQualityOptions,
  });

  const [, isSaving, updatePlaylistLine] = useWebService({
    method: PlaylistLineService.update,
  });

  useEffect(() => {
    if (!initialValue) {
      return;
    }
    fetchQualityOptions(
      initialValue.resourceId,
      initialValue.dateFrom,
      initialValue.dateTo
    );
    setValue({
      name: initialValue.playlistLineName,
      description: initialValue.description,
      dateFrom: moment.utc(initialValue.dateFrom),
      dateTo: moment.utc(initialValue.dateTo),
      quality: initialValue.quality,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleSubmit = () => {
    const formErrors = PlaylistClipFormValidation(value);
    if (!_.isEmpty(formErrors)) {
      onError('Please fix the errors in the form');
      return setErrors(formErrors);
    }
    return updatePlaylistLine({
      playlistLineId: initialValue?.playlistLineId,
      playlistLineName: value.name,
      description: value.description,
      quality: value.quality ? value.quality : undefined,
      startDate: value.dateFrom.toISOString(),
      endDate: value.dateTo.toISOString(),
    })
      .then((response) => {
        if (response) {
          onInfo('Clip updated successfully');
          onSuccess();
          refreshPlaylistLines(playlist.playlistHdrId);
          onCancel();
        }
      })
      .catch((err) => {
        onError(err);
      });
  };

  const handleChange = (key: keyof ClipForm, val) => {
    setErrors((prev) => ({ ...prev, [key]: null }));

    setValue((prev) => ({ ...prev, [key]: val }));
  };

  const handleError = (key: keyof ClipForm, error: string) => {
    setErrors((prev) => ({ ...prev, [key]: error }));
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Edit Clip</DialogTitle>
      <DialogContent>
        <PlaylistClipForm
          value={value}
          errors={errors}
          qualityOptions={qualityOptions}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onError={handleError}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onCancel} />
        <LoadingButton
          loading={isSaving}
          variant="contained"
          translationKey="common.buttons.save"
          disabled={
            errors && Object.values(errors).some((error) => error !== null)
          }
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditClipDialog;
