/* eslint-disable camelcase */
import { CAST_PRODUCT_DETAILS } from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchConstants';
import { get } from 'util/WebRequest';

const dataProductDeliveryRequest = (cast: {
  siteDeviceSubsetName: string;
  siteDeviceSubsetId: number;
  dataProduct: string;
  startDate: Date | string;
  endDate: Date | string;
  code: string;
  extension: string;
}) =>
  get(
    'apiproxy/dataProductDelivery',
    {
      ...CAST_PRODUCT_DETAILS[cast.dataProduct].dpoParams,
      method: 'request',
      dataProductCode: cast.dataProduct,
      extension: cast.extension,
      dateFrom: cast.startDate,
      dateTo: cast.endDate,
      locationCode: cast.code,
      deviceCategoryCode:
        CAST_PRODUCT_DETAILS[cast.dataProduct].deviceCategoryCode,
    },
    {}
  );

export default dataProductDeliveryRequest;
