import moment from 'moment';

class DataAvailabilityChartUtil {
  /**
   * Converts the date strings into proper date objects
   *
   * @param dateStringArray An array of date pairs each containing a dateFrom
   *   and dateTo in string format
   * @returns An array of date pairs with dateFrom and dateTo Date objects
   */
  static formatDateArray(dateStringArray) {
    const newArray = dateStringArray.map((dateObj) => ({
      dateFrom: moment(dateObj.dateFrom).toDate(),
      dateTo: moment(dateObj.dateTo).add(1, 'days').toDate(),
    }));
    return newArray;
  }

  /**
   * Gets the overall date range of an array of date pairs
   *
   * @param dateArray An array of date pairs each containing a dateFrom and
   *   dateTo
   * @returns The overall date range spanning a list of date pairs
   */
  static getAvailableRange(dateArray) {
    let dateFrom;
    let dateTo;
    dateArray.forEach((dateObj) => {
      dateFrom =
        dateObj.dateFrom < dateFrom || !dateFrom ? dateObj.dateFrom : dateFrom;
      dateTo = dateObj.dateTo > dateTo || !dateTo ? dateObj.dateTo : dateTo;
    });
    return { dateFrom, dateTo };
  }
}
export default DataAvailabilityChartUtil;
