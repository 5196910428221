import { useEffect } from 'react';
import { GeoSearchControl } from 'leaflet-geosearch';
import { useMap } from 'react-leaflet';
import 'leaflet-geosearch/assets/css/leaflet.css';
import './Search.css';

interface SearchProps {
  // Should have class structure similar to providers at https://smeijer.github.io/leaflet-geosearch/
  provider: any;
  searchLabel: string;
}

const Search = ({ provider, searchLabel }: SearchProps) => {
  const map = useMap();

  useEffect(() => {
    const searchControl = GeoSearchControl({
      provider,
      showMarker: false,
      searchLabel,
    });

    map.addControl(searchControl);

    return () => {
      map.removeControl(searchControl);
    };
  });
  return <> </>;
};

export default Search;
