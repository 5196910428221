import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import QaqcAutotestsService from 'domain/services/QaqcAutotestsService';

class QaqcFinderTestLevelDropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    testLevels: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getTestLevels();
  }

  getTestLevels = () => {
    QaqcAutotestsService.getTestLevels()
      .then((results) => {
        const testLevels = results.map((testLevel, index) => ({
          label: testLevel,
          value: index + 1,
        }));
        this.setState({
          testLevels: [{ label: 'Find All', value: 0 }, ...testLevels],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  handleChange = (event) => {
    const { onChange } = this.props;
    const { testLevels } = this.state;
    const selectedTestLevel = testLevels.find(
      (testLevel) => testLevel.value === event.target.value
    );
    onChange(event, selectedTestLevel ? selectedTestLevel.label : '');
  };

  render() {
    const { disabled, initialValue, value, onChange, ...rest } = this.props;
    const { testLevels } = this.state;
    return (
      <Dropdown
        disabled={disabled}
        initialValue={initialValue}
        label="Test Level"
        options={testLevels}
        value={value}
        onChange={(event) => this.handleChange(event)}
        {...rest}
      />
    );
  }
}
export default QaqcFinderTestLevelDropdown;
