import Environment from 'util/Environment';

class SeaTubeSubPlaylist {
  getPlaylistTitle = (mediaFile, dataFile) => {
    const { deviceCode, defaultFileNamePostFix, dateStartSeconds } = mediaFile;

    const millis = Number(dataFile[4]) || 0;
    const dateFrom = new Date(
      (dateStartSeconds + Number(dataFile[0])) * 1000 + millis
    )
      .toISOString()
      .replace(/[-:]/gi, '');

    return `${deviceCode}_${dateFrom}${defaultFileNamePostFix}`;
  };

  getPlaylistFile = (fullname) => {
    const mediaUrl = Environment.getMediaBaseUrl();

    return `${mediaUrl}/${fullname}/playlist.m3u8`.replace(
      /([^:]\/)\/+/g, // Remove duplicate forward slashes excluding ://
      '$1'
    );
  };

  constructor() {
    this.contents = [];
  }

  /**
   * Add an explicitly-defined file (ie., an object with deviceId and fullname
   * keys) to the playlist
   */
  addFromRaw = (title, file) => {
    this.contents.push({
      description: file.deviceId.toString(),
      title,
      file: this.getPlaylistFile(file.fullname),
    });
  };

  /**
   * Add a data file from {@link SeaTubeMediaFiles} to the playlist
   *
   * @param mediaFile A SeaTube mediaFile
   * @param dataFile A dataFile from the above mediaFile
   */
  addFromMediaFile = (mediaFile, dataFile) => {
    const playlistEntry = {
      description: mediaFile.deviceId.toString(),
      title: this.getPlaylistTitle(mediaFile, dataFile),
    };
    playlistEntry.file = this.getPlaylistFile(
      `${dataFile[2]}${playlistEntry.title}`
    );

    this.contents.push(playlistEntry);
  };
}

export default SeaTubeSubPlaylist;
