import { useEffect, useState, useRef, FC, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';
import moment, { Moment } from 'moment';
import Plotly, { Config, Layout } from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);

interface DailyAvailabilityChartProps {
  /** Array of days and their availability values (0-1) */
  /**
   * There is a reason "date"" is a string, and it's to better represent UTC
   * dates in the chart. See more discussion here:
   * https://github.com/plotly/plotly.js/issues/1532
   */
  availabilityData: {
    date: string;
    value: number;
  }[];
  /** The date range for the plot to show */
  displayDateRange: {
    dateFrom: Date;
    dateTo: Date;
  };
  /** The day to highlight as selected */
  selectedDay?: Moment;
  /** Callback for when a day is selected */
  onDaySelected?: (selected: { date: Moment; value: number }) => void;
  /** Whether or not to display the default title 'Data Availability' */
  displayDefaultTitle?: boolean;
  /** Named CSS color or Hexidecimal string to change the plot background to */
  backgroundColour?: string;
  style?: object;
}

interface PlotData {
  x: string[];
  y: number[];
  type: 'bar';
  marker?: {
    color?: string[];
    opacity?: number;
    line?: {
      width: number;
      color: string;
    };
  };
  hoverinfo?: 'x';
  hoveron?: 'points';
  hoverlabel?: {
    bgcolor?: string;
    bordercolor?: string;
    font?: {
      color?: string;
    };
  };
  name?: string;
  showlegend?: boolean;
  width?: number;
}

const DailyAvailabilityChart: FC<DailyAvailabilityChartProps> = ({
  availabilityData,
  displayDateRange,
  selectedDay = undefined,
  onDaySelected = () => {},
  displayDefaultTitle = true,
  backgroundColour = undefined,
  style = {
    width: '100%',
    height: 120,
  },
}) => {
  const theme = useTheme();
  const plotDivId = 'daily-availability-chart';
  const containerRef = useRef(null);
  const [markerLineWidth, setMarkerLineWidth] = useState(1);

  const configRef = useRef<Config>({
    modeBarButtonsToRemove: [
      'toImage',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
      'select2d',
    ],
    displaylogo: false,
    scrollZoom: true,
    responsive: true,
  });

  const [layout, setLayout] = useState<Layout>({
    xaxis: {
      range: [displayDateRange.dateFrom, displayDateRange.dateTo],
      showgrid: true,
      mirror: true,
      showline: true,
      visible: false,
      linecolor: 'grey',
      type: 'date',
      tickformat: '%b %e\n%Y',
      nticks: 8,
    },
    yaxis: {
      fixedrange: true,
      showgrid: false,
      showticklabels: false,
      showline: true,
      visible: false,
      mirror: true,
      linecolor: 'grey',
      range: [0, 1],
      zeroline: false,
    },
    annotations: [],
    autosize: true,
    margin: { t: 30, b: 40, l: 30, r: 30 },
    dragmode: true,
    plot_bgcolor: backgroundColour,
    paper_bgcolor: backgroundColour,
    title: undefined,
  });

  const handleRelayout = (relayoutData) => {
    if (relayoutData['xaxis.range[0]'] && relayoutData['xaxis.range[1]']) {
      const start = moment(relayoutData['xaxis.range[0]']);
      const end = moment(relayoutData['xaxis.range[1]']);
      const diffInDays = end.diff(start, 'days');
      // Handles hiding marker lines when really zoomed out
      if (diffInDays > 150) {
        setMarkerLineWidth(0);
      } else {
        setMarkerLineWidth(1);
      }
    }
  };

  const generatePlotData = (): PlotData[] => {
    // Calculate bar width based on data density
    const msPerDay = 25 * 60 * 60 * 1000;
    const barWidth = msPerDay + 1000;
    const plotData: PlotData[] = [
      {
        x: availabilityData.map((d) => d.date),
        y: availabilityData.map((d) => d.value),
        type: 'bar',
        marker: {
          color: availabilityData.map((d) =>
            moment(d.date).isSame(selectedDay?.format('YYYY-MM-DD'), 'day')
              ? theme.palette.primary.main
              : theme.palette.secondary.main
          ),
          opacity: 1,
          line: {
            width: markerLineWidth,
            color: theme.palette.divider,
          },
        },
        hoverinfo: 'x',
        hoveron: 'points',
        hoverlabel: {
          bgcolor: theme.palette.background.paper,
          bordercolor: theme.palette.divider,
          font: {
            color: theme.palette.text.primary,
          },
        },
        name: 'availability',
        showlegend: false,
        width: barWidth,
      },
    ];

    return plotData;
  };

  const handlePlotClick = useCallback(
    (event: any) => {
      if (!event.points?.[0]) return;
      onDaySelected({
        date: moment.utc(event.points[0].x),
        value: event.points[0].x,
      });
    },
    [onDaySelected]
  );

  // Update layout based on data and display options
  useEffect(() => {
    const updatedLayout = structuredClone(layout);
    if (availabilityData?.length > 0) {
      updatedLayout.xaxis.visible = true;
      updatedLayout.xaxis.range = [
        displayDateRange.dateFrom,
        displayDateRange.dateTo,
      ];
      updatedLayout.annotations = [];
    } else {
      updatedLayout.xaxis.visible = false;
      updatedLayout.annotations = [
        {
          text: 'No data available',
          xref: 'paper',
          yref: 'paper',
          showarrow: false,
          font: { size: 24 },
        },
      ];
    }

    if (displayDefaultTitle) {
      updatedLayout.title = {
        text: 'Data Availability',
        y: 0.95,
        x: 0,
        xanchor: 'left',
        yanchor: 'top',
      };
    }
    setLayout(updatedLayout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayDateRange, availabilityData, displayDefaultTitle]);

  // Handle resizing
  useEffect(() => {
    if (typeof ResizeObserver === 'undefined' || !containerRef.current)
      return null;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === containerRef.current) {
          const plotElement = document.getElementById(plotDivId);
          if (plotElement) {
            Plotly.Plots.resize(plotElement);
          }
        }
      }
    });

    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const handleHover = useCallback(
    (event) => {
      if (!event?.points?.[0]) return;
      const pt = event.points[0];
      Plotly.restyle(
        plotDivId,
        `marker.color[${pt.pointNumber}]`, // "attribute" argument
        theme.palette.highlight.main, // "value" argument
        [pt.curveNumber] // trace index array
      );
    },
    [theme]
  );

  const handleUnhover = useCallback(
    (event) => {
      if (!event.points || !event.points.length) return;

      event.points.forEach((pt) => {
        const { curveNumber, pointNumber } = pt;
        const hoveredDate = moment.utc(pt.x);

        // Choose color based on whether it's the selected day
        const color =
          selectedDay && hoveredDate.isSame(selectedDay, 'day')
            ? theme.palette.primary.main
            : theme.palette.secondary.main;

        Plotly.restyle(plotDivId, `marker.color[${pointNumber}]`, color, [
          curveNumber,
        ]);
      });
    },
    [selectedDay, theme.palette.primary.main, theme.palette.secondary.main]
  );

  const plotData = useMemo(
    () => generatePlotData(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availabilityData, selectedDay, theme, markerLineWidth]
  );

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <Plot
        divId={plotDivId}
        style={style}
        data={plotData}
        layout={layout}
        config={configRef.current}
        onClick={handlePlotClick}
        onRelayout={handleRelayout}
        onHover={handleHover}
        onUnhover={handleUnhover}
        useResizeHandler
      />
    </div>
  );
};

export default DailyAvailabilityChart;
