/* eslint-disable react/no-unstable-nested-components */
import { memo, useState } from 'react';

import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { Error } from '@onc/icons';
import { type DropdownOption, Grid, Typography } from 'base-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from 'base-components';
import CruiseDropdownNew from 'domain/AppComponents/dropdowns/CruiseDropdownNew';
import DiveDropdown from 'domain/AppComponents/dropdowns/DiveDropdown';
import { InfoIconButton } from 'domain/AppComponents/IconButtons';
import DiveDetails from 'domain/Apps/seatube/details/DiveDetails';
import { TextButton } from 'library/CompositeComponents/button/Buttons';

const styles = () =>
  createStyles({
    dropdown: {
      width: '100%', // so both dropdowns use full width of shared Grid item
      'min-width': '140px', // wide enough for "Cruise Name"
    },
    icon: {
      alignItems: 'center',
      display: 'flex',
    },
    noMarginTop: {
      marginTop: '0',
    },
  });

interface Props extends WithStyles<typeof styles> {
  cruiseId?: number;
  diveId?: number;
  fileName?: string;
  date?: Moment | null;
  onCruiseChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDiveChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  cruiseOptions?: DropdownOption[];
  diveOptions?: DropdownOption[];
}

const StepPartCruiseAndDiveSelector: React.VFC<Props> = ({
  cruiseId = undefined,
  diveId = undefined,
  onCruiseChange,
  onDiveChange,
  cruiseOptions = undefined,
  diveOptions = undefined,
  fileName = undefined,
  classes,
  date = undefined,
}) => {
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showDiveError, setShowDiveError] = useState(false);
  const [showCruiseError, setShowCruiseError] = useState(false);

  const handleCloseInfo = () => setShowInfoDialog(false);
  const handleOpenInfo = () => setShowInfoDialog(true);
  const handleOpenDiveError = () => setShowDiveError(true);
  const handleCloseDiveError = () => setShowDiveError(false);
  const handleOpenCruiseError = () => setShowCruiseError(true);
  const handleCloseCruiseError = () => setShowCruiseError(false);

  const DiveInfoDialog = () => (
    <Dialog open={showInfoDialog} onClose={handleCloseInfo}>
      <DialogTitle id="dive-info-title">Dive Details</DialogTitle>
      <DialogContent>
        <DiveDetails diveId={diveId} onError={() => {}} />
      </DialogContent>
      <DialogActions>
        <TextButton
          translationKey="common.buttons.close"
          onClick={handleCloseInfo}
        />
      </DialogActions>
    </Dialog>
  );

  const DiveErrorDialog = () => (
    <Dialog open={showDiveError} onClose={handleCloseDiveError}>
      <DialogTitle id="dive-error-title">Error</DialogTitle>
      <DialogContent>
        <Typography>
          No Dives found for the given date:{' '}
          {date ? moment(date).format('YYYY-MM-DD') : ''}
        </Typography>
      </DialogContent>
      <DialogActions>
        <TextButton
          translationKey="common.buttons.close"
          onClick={handleCloseDiveError}
        />
      </DialogActions>
    </Dialog>
  );

  const CruiseErrorDialog = () => (
    <Dialog open={showCruiseError} onClose={handleCloseCruiseError}>
      <DialogTitle id="cruise-error-title">Error</DialogTitle>
      <DialogContent>
        <Typography>
          No Cruises found for the given date:{' '}
          {date ? moment(date).format('YYYY-MM-DD') : ''}
        </Typography>
      </DialogContent>
      <DialogActions>
        <TextButton
          translationKey="common.buttons.close"
          onClick={handleCloseCruiseError}
        />
      </DialogActions>
    </Dialog>
  );

  const renderDiveIconButton = () => {
    if (diveId) {
      return (
        <InfoIconButton
          aria-label="Dive Info"
          color="primary"
          onClick={handleOpenInfo}
        />
      );
    }
    if (cruiseId && date && diveOptions && diveOptions.length === 0) {
      return (
        <IconButton
          aria-label="No Dives Found"
          title="No Dives Found"
          onClick={handleOpenDiveError}
          color="error"
          Icon={Error}
        />
      );
    }
    return undefined;
  };

  const renderCruiseErrorIcon = () => {
    if (date && cruiseOptions && cruiseOptions.length === 0) {
      return (
        <IconButton
          aria-label="No Cruises Found"
          title="No Cruises Found"
          onClick={handleOpenCruiseError}
          color="error"
          Icon={Error}
        />
      );
    }
    return undefined;
  };

  return (
    <>
      {/* One row with two items */}
      <Grid container spacing={1}>
        {/* First item contains both dropdowns. */}
        <Grid item xs={9} sm={6}>
          <CruiseDropdownNew
            className={classes.dropdown}
            value={cruiseId}
            onChange={onCruiseChange}
            options={cruiseOptions || []}
            disabled={
              !fileName || !date || !cruiseOptions || cruiseOptions.length === 0
            }
            fullWidth
          />

          <DiveDropdown
            className={classes.dropdown}
            cruiseId={cruiseId}
            value={diveId}
            onChange={onDiveChange}
            options={diveOptions || []}
            disabled={
              !fileName ||
              !date ||
              !diveOptions ||
              !cruiseOptions ||
              diveOptions.length === 0 ||
              cruiseOptions.length === 0
            }
            fullWidth
          />
        </Grid>
        {/* second item contains another Grid with 2 items in a column */}
        <Grid item xs={1} container direction="column">
          {/* first item in column is empty to push the icon to the bottom */}
          <Grid item xs={6} className={classes.icon}>
            {renderCruiseErrorIcon()}
          </Grid>
          {/* second item in column has the icon, and uses flexbox to center it vertically */}
          <Grid item xs={6} className={classes.icon}>
            {renderDiveIconButton()}
          </Grid>
        </Grid>
      </Grid>

      {/* Dive Information Dialog */}
      <DiveInfoDialog />

      {/* No Dives Found Error Dialog */}
      <DiveErrorDialog />

      {/* No Cruises Found Error Dialog */}
      <CruiseErrorDialog />
    </>
  );
};

export default withStyles(styles)(memo(StepPartCruiseAndDiveSelector));
