/* eslint-disable no-underscore-dangle */
// The above is disabled due to Leaflet using underscore's internally to refer to private variables.
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { Layers } from '@onc/icons';
import { oncDefaultTheme } from '@onc/theme';
import { Control, DomUtil, DomEvent, Browser, Util, Map } from 'leaflet';
import { createRoot } from 'react-dom/client';
import { Checkbox, MenuItem, RadioButton } from 'base-components';

import MapFAB from './MapFAB';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export interface LeafletLayersOptions extends Control.LayersOptions {
  /** ID of the map */
  mapId: string;
  /** Tag to add to Leaflet's auto generated ID */
  idTag: string;
}

/** Types needed for extending Leaflet's build in LayersControl */
interface LeafletLayersControl extends Control.Layers {
  _buttonContainer: HTMLElement;
  _container: HTMLElement;
  _innerContainer: HTMLElement;
  _section: HTMLElement;
  baseLayers: any[];
  overlays: any[];
  _baseLayersList: HTMLElement;
  _separator: HTMLElement;
  options: LeafletLayersOptions;
  _overlaysList: HTMLElement;
  _layerControlInputs: any;
  _layers: any[];
  _onLayerChange: () => any;
  _initLayout: () => void;
  _update: () => any;
  renderMenuElement: (obj: any, input: HTMLElement) => void;
  _toggleBaseLayers: (obj: any, toggleState: boolean) => void;
  _getLayer: (layerId: number) => any;
  _checkDisabledLayers: () => void;
  _map: Map;
}
/** Custom Leaflet control for use with LayersControl */
const LeafletLayersControl = Control.Layers.extend({
  // Creates the menu container
  _initLayout(this: LeafletLayersControl) {
    const className = 'leaflet-control-layers';
    const outerContainer = DomUtil.create('div', `layers-container`);
    const container = DomUtil.create('div');

    const { mapId, idTag } = this.options;
    container.setAttribute('id', `${mapId}-${idTag}-leaflet-control-layers`);

    // To enabled & disable the menu we just hide this button by setting style.display to 'none'
    this._buttonContainer = DomUtil.create('div');
    this._buttonContainer.style.display = 'inline-flex';
    container.append(this._buttonContainer);

    createRoot(this._buttonContainer).render(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={oncDefaultTheme}>
          <MapFAB
            idTag={idTag}
            ariaLabel="Layers"
            aria-haspopup="true"
            TooltipProps={{ title: 'Layers' }}
            color="primary"
            size="medium"
            mapId={mapId}
            map={this._map}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={this.expand.bind(this)}
          >
            <Layers />
          </MapFAB>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // hard-code because the border just won't go away
    container.style.border = 'none';
    // DomUtil.addClass(container, className);
    // DomUtil.addClass(container, classes.controlLayersContainer);

    const { collapsed } = this.options;

    // makes this work on IE touch devices by stopping it from firing a mouseout event when the touch is released
    DomEvent.disableClickPropagation(container);
    DomEvent.disableScrollPropagation(container);

    const sectionContainer = DomUtil.create('div', className);
    sectionContainer.style.border = 'none';
    const section = DomUtil.create('section', `${className}-list`);
    sectionContainer.append(section);
    this._section = section;

    if (collapsed) {
      this._map.on('click', this.collapse, this);

      if (!Browser.android) {
        DomEvent.on(
          container,
          {
            mouseleave: this.collapse,
          },
          this
        );
      }
    }

    this.baseLayers = [];
    this.overlays = [];
    this._baseLayersList = DomUtil.create('div', `${className}-base`, section);
    this._separator = DomUtil.create('div', `${className}-separator`, section);
    this._overlaysList = DomUtil.create(
      'div',
      `${className}-overlays`,
      section
    );

    container.appendChild(sectionContainer);
    outerContainer.appendChild(container);
    this._container = outerContainer;
    this._innerContainer = container;
  },

  // Adds items to the menu container.
  _addItem(this: LeafletLayersControl, obj) {
    const input = document.createElement('div');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    input.layerId = Util.stamp(obj.layer);

    this.renderMenuElement(obj, input);
    this._layerControlInputs.push(input);

    const container = obj.overlay ? this._overlaysList : this._baseLayersList;
    container.appendChild(input);

    this._checkDisabledLayers();
    return input;
  },
  // Renders menu element to input div.
  // TODO: This should be extracted out and put into a menu
  renderMenuElement(this: LeafletLayersControl, obj, input) {
    const { disabled } = input;
    const checked = this._map.hasLayer(obj.layer);

    let menuItem;

    if (obj.overlay) {
      menuItem = (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={oncDefaultTheme}>
            <MenuItem
              disabled={disabled}
              onClick={() => {
                this._toggleBaseLayers(obj, false);
              }}
            >
              <Checkbox
                disabled={disabled}
                checked={checked}
                color="secondary"
              />
              {obj.name}
            </MenuItem>
          </ThemeProvider>
        </StyledEngineProvider>
      );
      // Push to overlays list only if it isn't there
      if (this.overlays.indexOf(obj) <= -1) {
        this.overlays.push(obj);
      }
    } else {
      menuItem = (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={oncDefaultTheme}>
            <MenuItem
              disabled={disabled}
              onClick={() => {
                this._toggleBaseLayers(obj, true);
              }}
            >
              <RadioButton
                disabled={disabled}
                checked={checked}
                color="secondary"
              />
              {obj.name}
            </MenuItem>
          </ThemeProvider>
        </StyledEngineProvider>
      );
      // Push to base layers list only if it isn't there
      if (this.baseLayers.indexOf(obj) <= -1) {
        this.baseLayers.push(obj);
      }
    }

    createRoot(input).render(menuItem);
  },

  _toggleBaseLayers(this: LeafletLayersControl, obj, isBaseLayer) {
    if (isBaseLayer) {
      // Only one base layer can be visible at a time
      for (let i = 0; i < this.baseLayers.length; i += 1) {
        const { layer } = this.baseLayers[i];
        if (layer !== obj.layer) {
          if (this._map.hasLayer(layer)) {
            this._map.removeLayer(layer);
          }
        } else if (!this._map.hasLayer(layer)) {
          // Add selected layer to map if it isn't added already
          this._map.addLayer(layer);
        }
      }
    } else if (this._map.hasLayer(obj.layer)) {
      // Add or remove overlay from map
      this._map.removeLayer(obj.layer);
    } else {
      this._map.addLayer(obj.layer);
    }
  },

  // Disables input if the current zoom level exceeds the layer's
  // min/max zoom boundaries.
  _checkDisabledLayers(this: LeafletLayersControl) {
    const inputs = this._layerControlInputs;
    const zoom = this._map.getZoom();

    for (let i = inputs.length - 1; i >= 0; i -= 1) {
      const input = inputs[i];
      const obj = this._getLayer(input.layerId);
      const { layer } = obj;

      input.disabled =
        (layer.options.minZoom !== undefined && zoom < layer.options.minZoom) ||
        (layer.options.maxZoom !== undefined && zoom > layer.options.maxZoom);

      this.renderMenuElement(obj, input);
    }
  },

  // Adds outer container containing everything to map as a leaflet-control
  onAdd(this: LeafletLayersControl, map: Map) {
    this._initLayout();
    this._update();

    this._map = map;
    this._map.on('zoomend', this._checkDisabledLayers, this);

    for (let i = 0; i < this._layers.length; i += 1) {
      this._layers[i].layer.on('add remove', this._onLayerChange, this);
    }

    return this._container;
  },

  // Expand the menu but hide the button
  expand(this: LeafletLayersControl) {
    const container = this._innerContainer;
    DomUtil.addClass(container, 'leaflet-control-layers-expanded');
    this._section.style.height = null;
    const acceptableHeight = this._map.getSize().y - (container.offsetTop + 50);
    if (acceptableHeight < this._section.clientHeight) {
      DomUtil.addClass(this._section, 'leaflet-control-layers-scrollbar');
      this._section.style.height = `${acceptableHeight}px`;
    } else {
      DomUtil.removeClass(this._section, 'leaflet-control-layers-scrollbar');
    }
    this._checkDisabledLayers();
    this._buttonContainer.style.display = 'none';
    return this;
  },

  // @method collapse(): this
  // Collapse the control container if expanded.
  // hide the menu and show the button again.
  collapse(this: LeafletLayersControl) {
    DomUtil.removeClass(
      this._innerContainer,
      'leaflet-control-layers-expanded'
    );
    this._buttonContainer.style.display = 'inline-flex';
    return this;
  },
  onRemove(this: LeafletLayersControl) {
    this._map.off('click', this.collapse, this);
    this._map.off('zoomend', this._checkDisabledLayers, this);

    if (!Browser.android) {
      DomEvent.off(
        this._container,
        {
          mouseleave: this.collapse,
        },
        this
      );
    }

    for (let i = 0; i < this._layers.length; i += 1) {
      this._layers[i].layer.off('add remove', this._onLayerChange, this);
    }
  },
});

export default LeafletLayersControl;
