import { createStyles, withStyles } from '@mui/styles';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
  });

type ResizingInfoListProps = {
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
  classes: {
    root: string;
    body: string;
  };
};
/**
 * A list that will size to 100% of its parent height, and create its own
 * scrollbar as required Intended to be used with any "InfoItem"s, and provides
 * setup for "InfoItemScrollable"
 */
const ResizingInfoList = ({
  headerContent = undefined,
  children = undefined,
  classes,
}: ResizingInfoListProps) => (
  <div className={classes.root}>
    {headerContent ? <div>{headerContent}</div> : undefined}
    {children ? <div className={classes.body}>{children}</div> : undefined}
  </div>
);

export default withStyles(styles)(ResizingInfoList);
