import { ReactNode, useState } from 'react';
import { withStyles } from '@mui/styles';
import { ExpandMore, ChevronRight } from '@onc/icons';
import { Collapse, Menu, MenuItem } from 'base-components';

/* TODO: This is a quick recreation of the Material 4 TreeView. Please replace this with the real thing once we upgrade to Mui 4 */
const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(2),
    minWidth: 'fit-content',
    '& li': {
      minWidth: 'fit-content',
      paddingRight: 0,
    },
  },
  treeMenuItem: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    overflow: 'visible',
    marginLeft: theme.spacing(),
    paddingLeft: 0,
  },
  textContainer: {
    width: '100%',
  },
});

type TreeMenuItemProps = {
  label: any;
  onClick?: () => void;
  expand?: boolean;
  classes?: {
    nested?: string;
    treeMenuItem?: string;
    textContainer?: string;
  };
  children?: ReactNode;
  hoverContent?: ReactNode;
  selected?: boolean;
};

const TreeMenuItem = ({
  classes = undefined,
  children = undefined,
  label,
  onClick = () => {},
  expand = false,
  hoverContent = undefined,
  selected = false,
}: TreeMenuItemProps) => {
  const [isExpanded, setIsExpanded] = useState(expand);
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };

  const renderChildren = () => {
    if (children) {
      return (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Menu
            menuList
            menuListProps={{ className: classes.nested, disablePadding: true }}
          >
            {children}
          </Menu>
        </Collapse>
      );
    }

    return null;
  };

  const handleToggleNode = () => {
    onClick();
    setIsExpanded(!isExpanded);
  };

  const renderExpandIcon = () => {
    if (!children) {
      return null;
    }
    return isExpanded ? (
      <ExpandMore color="action" fontSize="small" data-isexpanded />
    ) : (
      <ChevronRight color="action" fontSize="small" data-isexpanded={false} />
    );
  };

  return (
    <>
      <MenuItem
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        className={classes.treeMenuItem}
        onClick={handleToggleNode}
        selected={selected}
        data-isexpanded={!children ? false : isExpanded}
      >
        {renderExpandIcon()}
        <div className={classes.textContainer}>{label}</div>
        {isHovered ? hoverContent : undefined}
      </MenuItem>
      {renderChildren()}
    </>
  );
};

export default withStyles(styles)(TreeMenuItem);
