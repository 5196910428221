import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { t } from '@onc/i18n';
import { ButtonProps } from './Button';

type LoadingButtonBaseProps = ButtonProps & {
  /** Displays loading spinner and disables button when true */
  loading: boolean;
  loadingIndicator?: React.ReactNode;
};

/**
 * MUI states that if changing the loading position to 'start' or 'end', the
 * icon prop should be provided.
 */
type StartIconProps = {
  loadingPosition: 'start';
  startIcon: React.ReactNode;
};

type EndIconProps = {
  loadingPosition: 'end';
  endIcon: React.ReactNode;
};

type NoIconProps = {
  loadingPosition?: undefined;
};

type LoadingButtonProps =
  | (LoadingButtonBaseProps & StartIconProps)
  | (LoadingButtonBaseProps & EndIconProps)
  | (LoadingButtonBaseProps & NoIconProps);

const styles = {
  button: {
    margin: 1,
  },
};

const LoadingButton: React.FC<LoadingButtonProps> = ({
  translationKey,
  translationOptions = undefined,
  component = 'button',
  onClick = undefined,
  variant,
  className = undefined,
  id = undefined,
  disabled = false,
  color = 'primary',
  endIcon = null,
  startIcon = null,
  type = undefined,
  to = undefined,
  href = undefined,
  target = undefined,
  'aria-label': ariaLabel = undefined,
  fullWidth = false,
  loading = false,
  loadingPosition,
  loadingIndicator = (
    <CircularProgress
      color="inherit"
      size={16}
      data-testid="loading-indicator"
    />
  ),
}: LoadingButtonProps) => (
  <MuiLoadingButton
    id={id}
    sx={!className ? styles.button : undefined}
    className={className}
    component={component}
    onClick={onClick}
    disabled={disabled}
    variant={variant}
    color={color}
    startIcon={startIcon}
    endIcon={endIcon}
    type={type}
    href={href}
    target={target}
    to={to}
    aria-label={ariaLabel}
    fullWidth={fullWidth}
    loading={loading}
    loadingPosition={loadingPosition}
    loadingIndicator={loadingIndicator}
  >
    {t(translationKey, translationOptions)}
  </MuiLoadingButton>
);

export default LoadingButton;
