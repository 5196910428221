import { get } from 'util/WebRequest';

class DataProductOptionService {
  static getOptions = async (params) =>
    get('DataProductOptionService', {
      operation: 1,
      ...params,
    }).then((response) => response.data);
}

export default DataProductOptionService;
