/* eslint-disable no-underscore-dangle */
import { latLng } from 'leaflet';
import { LeafletIcon } from 'base-components';
import {
  UNASSIGNED_CASTS,
  UNASSIGNED_CAST_SEARCH_TREE_NODE_ID,
} from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchConstants';
import { SelectedStation } from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchTypes';
import TextParsingUtils from 'util/TextParsingUtils';

class GeospatialSearchHelper {
  /**
   * Generates position array from boundingArea for Leaflet's Polygon component.
   *
   * @param {object[]} assignedCastData - AssignedCastData.payload return from
   *   GeospatialAreaService
   * @returns {object[]} - Updated payload with new fields:
   *   {Array.<Array.<number>>} positions, {boolean} positionError
   */

  static calculateCenterPoint = (latLngList) => {
    const centerPoint = latLngList[0].lat
      ? latLng(latLngList[2].lat, latLngList[0].lng)
      : latLng(Number(latLngList[2][0]), latLngList[0][1]);

    return centerPoint;
  };

  static generatePolygonPositions = (assignedCastData, onError) => {
    if (!assignedCastData) {
      return undefined;
    }
    return assignedCastData.map((polygon) => {
      const { boundingArea, code } = polygon;
      const { positions, error } = TextParsingUtils.parseWktPolygonString(
        boundingArea,
        code
      );

      const invalidPositions = positions.length < 1;
      if (error || invalidPositions) {
        const message = invalidPositions
          ? `No polygon points generated for ${code}.`
          : error;
        onError(message);
        return {
          ...polygon,
          positions,
          positionError: error || invalidPositions,
        };
      }

      const centerPoint = this.calculateCenterPoint(positions);
      return {
        ...polygon,
        positions,
        positionError: error || invalidPositions,
        centerPoint,
      };
    });
  };

  static generateLabelPosition = (assignedCastData) => {
    if (!assignedCastData) {
      return undefined;
    }
    return assignedCastData.map((polygon) => {
      const { boundingArea, code } = polygon;
      const { positions } = TextParsingUtils.parseWktPolygonString(
        boundingArea,
        code
      );
      const centerPoint = this.calculateCenterPoint(positions);
      return {
        label: polygon.name,
        x: centerPoint.lng,
        y: centerPoint.lat,
      };
    });
  };

  /**
   * Remove casts with no lat or long
   *
   * @param {object[]} unassignedCastData - UnassignedCastData.payload return
   *   from SiteDeviceSubsetService
   */
  static filterOutUnassignedCastsWithNoPosition = (unassignedCastData) => {
    if (!unassignedCastData) {
      return undefined;
    }
    return unassignedCastData.filter(
      (cast) => cast.referenceLat && cast.referenceLon
    );
  };

  private static getAllMarkersRecursively = (someLayerObject) => {
    let markers = [];

    if (someLayerObject._markers?.length > 0) {
      markers = markers.concat(someLayerObject._markers);
    }

    if (someLayerObject._childClusters?.length > 0) {
      someLayerObject._childClusters.forEach((layerObject) => {
        markers = markers.concat(
          GeospatialSearchHelper.getAllMarkersRecursively(layerObject)
        );
      });
    }

    return markers;
  };

  static setAllLayersToUnselectedIcon = (layers) => {
    Object.entries(layers)
      .map((keyValue) => keyValue[1])
      .filter((layer: any) => typeof layer.setIcon === 'function')
      .forEach((layer: any) => {
        if (layer?.options?.className === 'unassigned_cast_marker') {
          layer.setIcon(LeafletIcon.UNASSIGNED);
        } else if (
          layer?.options?.className === 'decommissioned_casting_station_marker'
        ) {
          layer.setIcon(LeafletIcon.GREY);
        } else if (
          layer?.options?.className === 'active_casting_station_marker'
        ) {
          layer.setIcon(LeafletIcon.DEFAULT);
        }
      });
  };

  static getUnrestrictedCastsFromLayers = (layers) =>
    layers.filter(
      (layer) =>
        layer.options?.children?.some instanceof Function &&
        layer.options?.children?.some((childEl) =>
          childEl?.props?.casts?.some(
            (cast) => cast.accessRestrictionLevel === 'RW'
          )
        )
    );

  static setLayersToSelectedIcon = (layers) => {
    const layersWithSelectedIcons = [];
    let markerLayers = [];

    layers.forEach((layer) => {
      markerLayers = markerLayers.concat(
        GeospatialSearchHelper.getAllMarkersRecursively(layer)
      );
    });

    const unrestrictedMarkerLayers =
      GeospatialSearchHelper.getUnrestrictedCastsFromLayers(markerLayers);
    const unrestrictedLayers =
      GeospatialSearchHelper.getUnrestrictedCastsFromLayers(layers);

    // set indirectly selected icons to yellow
    unrestrictedMarkerLayers
      .filter(
        (marker) =>
          typeof marker.setIcon === 'function' &&
          !marker.options?.icon?._icon?.className?.includes('marker-cluster')
      )
      .forEach((marker) => {
        marker.setIcon(LeafletIcon.YELLOW);
        layersWithSelectedIcons.push(marker);
      });

    // set selected icons to yellow
    unrestrictedLayers
      .filter(
        (layer) =>
          typeof layer.setIcon === 'function' &&
          !layer.options?.icon?._icon?.className?.includes('marker-cluster')
      )
      .forEach((layer) => layer.setIcon(LeafletIcon.YELLOW));

    return layersWithSelectedIcons;
  };

  static createUpdatedSelectedStations: (
    newSelectedStations: any,
    selectedUnassignedCasts: any[],
    prevSelectedStations: SelectedStation[]
  ) => SelectedStation[] = (
    newSelectedStations,
    selectedUnassignedCasts,
    prevSelectedStations
  ) => {
    let copyNewSelectedStations = newSelectedStations.filter(
      (newSelectedStation) =>
        !prevSelectedStations
          .map((station) => station.code)
          .includes(newSelectedStation.code)
    );
    let copySelectedUnassignedCasts = selectedUnassignedCasts;

    if (
      prevSelectedStations.some(
        (station) => station.id === UNASSIGNED_CAST_SEARCH_TREE_NODE_ID
      )
    ) {
      const unassignedNode = prevSelectedStations.find(
        (station) => station.id === UNASSIGNED_CAST_SEARCH_TREE_NODE_ID
      );
      const existingUnassignedCasts = unassignedNode.siteDeviceSubsets;
      copySelectedUnassignedCasts = selectedUnassignedCasts
        .filter(
          (newCast) =>
            !existingUnassignedCasts.some(
              (existingCast) =>
                existingCast.siteDeviceSubsetId === newCast.siteDeviceSubsetId
            )
        )
        .map((newCast) => {
          const newCastCopy = newCast;
          newCastCopy.checkboxSelected = false;
          return newCastCopy;
        });
      unassignedNode.siteDeviceSubsets = existingUnassignedCasts.concat([
        ...new Set(copySelectedUnassignedCasts),
      ]);
      // the unnassigned node is a special case due to being modified and not a new node
      unassignedNode.checkboxSelected = false;
      unassignedNode.partialCheckboxSelected =
        unassignedNode.siteDeviceSubsets.some(
          (siteDeviceSubset) => siteDeviceSubset.checkboxSelected === true
        );
    } else if (copySelectedUnassignedCasts.length > 0) {
      copyNewSelectedStations = newSelectedStations.concat({
        code: 'CAST',
        name: UNASSIGNED_CASTS,
        id: UNASSIGNED_CAST_SEARCH_TREE_NODE_ID,
        siteDeviceSubsets: [...new Set(copySelectedUnassignedCasts)],
      });
    }

    // set default checkbox values (false) for stations (parents) (inc. unsassigned node) and sitedevicesubsets (children)
    copyNewSelectedStations = copyNewSelectedStations
      .map((station) => {
        const stationCopy = station;
        stationCopy.checkboxSelected = false;
        stationCopy.partialCheckboxSelected = false;
        stationCopy.siteDeviceSubsets = stationCopy.siteDeviceSubsets
          .filter(
            (siteDeviceSubset) =>
              siteDeviceSubset.accessRestrictionLevel === 'RW'
          )
          .map((siteDeviceSubset) => {
            const siteDeviceSubsetCopy = siteDeviceSubset;
            siteDeviceSubsetCopy.checkboxSelected = false;
            return siteDeviceSubsetCopy;
          });

        return stationCopy;
      })
      .filter((station) => station.siteDeviceSubsets.length > 0);

    return prevSelectedStations.concat(copyNewSelectedStations);
  };
}

export default GeospatialSearchHelper;
