export default class DiveFormUtil {
  static buildServiceParameters = (formData) => {
    const params = {
      diveId: undefined,
      deviceId: undefined,
      referenceDiveId: undefined,
      scientistId: undefined,
      diveComment: undefined,
      area: undefined,
      cruiseId: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      ready: undefined,
      defaultDeviceId: undefined,
    };
    if (formData.cruise) {
      params.cruiseId = formData.cruise.cruiseId;
    }
    if (formData.diveId) {
      params.diveId = formData.diveId;
    }
    if (formData.platform && formData.platform.deviceToId) {
      params.deviceId = formData.platform.deviceToId;
    }
    if (formData.diveName) {
      params.referenceDiveId = formData.diveName;
    }
    if (formData.diveChief && formData.diveChief.dmasUserId) {
      params.scientistId = formData.diveChief.dmasUserId;
    }
    if (formData.area) {
      params.area = formData.area;
    }
    if (formData.comment) {
      params.diveComment = formData.comment;
    }
    if (formData.dateFrom && formData.dateFrom.isValid()) {
      params.dateFrom = formData.dateFrom.toISOString();
    }
    if (formData.dateTo && formData.dateTo.isValid()) {
      params.dateTo = formData.dateTo.toISOString();
    }
    if (formData.defaultCamera && formData.defaultCamera.deviceToId) {
      params.defaultDeviceId = formData.defaultCamera.deviceToId;
    }

    params.ready = formData.seaTubeReady;

    return params;
  };
}
