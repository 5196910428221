import { useRef } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { Moment } from 'moment';
import {
  WIDGET_IMAGE_CONTAINER_OFFSET,
  WIDGET_IMAGE_ITEM_DIVISOR,
  WIDGET_IMAGE_ITEM_GAP,
} from './util/DataPlayerConstants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      zIndex: 3,
    },
    centerDate: {
      textAlign: 'center',
      zIndex: 3,
      background: 'white',
      position: 'relative',
      top: theme.spacing(),
      width: '33.3%',
    },
    leftDate: {
      textAlign: 'start',
      zIndex: 3,
      background: 'white',
      position: 'relative',
      top: theme.spacing(),
      width: '33.3%',
    },
    rightDate: {
      textAlign: 'end',
      zIndex: 3,
      background: 'white',
      position: 'relative',
      top: theme.spacing(),
      width: '33.3%',
    },
  })
);

type SpectraTimeLineProps = {
  height: number;
  width: number;
  date: Moment;
  ratio: number;
  isDashboard?: boolean;
  isRetrieving?: boolean;
  isLoading?: boolean;
  fileTotal: number;
  numFiles: number;
  updateSecondsFromPreviousDate: (previousDate: Moment) => any;
  updateWidgetDimensions: (
    playerContainerInitialWidth: any,
    playerContainerInitialHeight: any,
    width: any,
    height: any
  ) => any;
  startDate: Moment;
  changeDate: (date: any, modifiedDate: any) => Moment;
};

const SpectraTimeLine: React.FC<SpectraTimeLineProps> = ({
  height,
  width,
  date,
  ratio,
  isDashboard = false,
  isRetrieving = false,
  isLoading = false,
  fileTotal,
  numFiles,
  updateSecondsFromPreviousDate,
  updateWidgetDimensions,
  startDate,
  changeDate,
}: SpectraTimeLineProps) => {
  const classes = useStyles();
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';

  // dimensions of the widget player container div
  const lastHeight = useRef(undefined);
  const lastWidth = useRef(undefined);
  const widgetPlayerContainerInitialWidth = useRef(undefined);
  const widgetPlayerContainerInitialHeight = useRef(undefined);

  // Determine where the center line is with respect to the image. Used to simulate center line translation upon resizing
  const centerLineInitialPosition = useRef(undefined);
  const centerLineImageRatio = useRef(undefined);

  const imageBeforeCenter = document.getElementById('imageContainer-10');
  const centerImage = document.getElementById('imageContainer-11');
  const centerLine = document.getElementById('red-line');

  const getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      right: rect.right + window.scrollX,
    };
  };

  const calculateCenterLine = () => {
    // Container size is resolution dependent, get while loading
    if (isDashboard && isLoading) {
      widgetPlayerContainerInitialWidth.current = width;
      widgetPlayerContainerInitialHeight.current = height;
    }
    /* 
    Once the plots have finished loading, calculate and store the initial position of the red line
    in the center image in the dataviewer. Used to calculate dates when the widget is resized.
    */
    if (
      centerImage &&
      centerLine &&
      isRetrieving === false &&
      isLoading === false &&
      fileTotal === 0
    ) {
      const imageWidth = centerImage.getBoundingClientRect().width;
      const lineBounds = centerLine.getBoundingClientRect();
      const lineCenter = lineBounds.left + lineBounds.width / 2;
      // Calculate the distance between the center image and the red line
      const centerImageAndLineDifference =
        getOffset(centerImage).left - lineCenter;
      // Store and recalculate the line only if width changed, or on initial load
      if (
        (!centerLineInitialPosition.current || width !== lastWidth.current) &&
        height === lastHeight.current &&
        isDashboard === true
      ) {
        centerLineInitialPosition.current = lineCenter;
        centerLineImageRatio.current =
          centerImageAndLineDifference / imageWidth;
      }
    }
  };

  const calculateCenterDate = () => {
    updateWidgetDimensions(
      widgetPlayerContainerInitialWidth.current,
      widgetPlayerContainerInitialHeight.current,
      width,
      height
    );
    if (lastWidth.current && height === lastHeight.current) {
      const difference = (lastWidth.current - width) / 2;
      const timeOffset = difference / ratio;
      // check if resized by width
      if (Math.abs(timeOffset) > 0 && isDashboard) {
        calculateCenterLine();
        // update seconds for the dataplayer component, when expanding by width & scrolling
        updateSecondsFromPreviousDate(
          date.clone().subtract(timeOffset, 'seconds')
        );
      }
      date.subtract(timeOffset, 'seconds');
    }

    // Calculate date upon widget shrinking to the initial height, where width has also changed
    if (
      isDashboard &&
      lastHeight.current &&
      height < lastHeight.current &&
      width !== widgetPlayerContainerInitialWidth.current &&
      height === widgetPlayerContainerInitialHeight.current &&
      isLoading === false &&
      isRetrieving === false
    ) {
      lastHeight.current = height;
      const difference =
        (widgetPlayerContainerInitialWidth.current - width) / 2;
      const timeOffset = difference / ratio;
      const modifiedDate = startDate
        .clone()
        .utc()
        .subtract(timeOffset, 'seconds');
      updateSecondsFromPreviousDate(modifiedDate);
      changeDate(date, modifiedDate);
      return date;
    }
    // Date calculation upon expanding the widget
    if (
      isDashboard &&
      lastHeight.current &&
      (height > lastHeight.current || height < lastHeight.current) &&
      isLoading === false
    ) {
      lastHeight.current = height;
      const imageWidth = centerImage.getBoundingClientRect().width;
      const rightMargin =
        (-imageWidth + WIDGET_IMAGE_CONTAINER_OFFSET) /
          WIDGET_IMAGE_ITEM_DIVISOR -
        WIDGET_IMAGE_ITEM_GAP;
      const redLineInitial = centerLineInitialPosition.current;
      const centerImageCurrent = getOffset(centerImage).left;
      const centerImageAndLineDifference = centerImageCurrent - redLineInitial;
      // Calculate visible white space between images when resizing the widget
      const whiteSpaceGap =
        centerImageCurrent - (getOffset(imageBeforeCenter).right + rightMargin);
      const previousCenterLinePosition = Math.abs(
        centerLineImageRatio.current * imageWidth
      );
      /* # of pixels that the centerline has translated. Centerline movement is simulated by
       calculating its expected previous position on the center image with respect to its new size.
       Image gaps are accounted by subtracting the whitespace and distance between the actual line and the center image. */
      const pixelsTranslated =
        previousCenterLinePosition +
        centerImageAndLineDifference -
        whiteSpaceGap * Math.round(numFiles / 2);
      const pixelsToTime = pixelsTranslated / ratio;
      // enable for height scrolling
      updateSecondsFromPreviousDate(
        date.clone().subtract(pixelsToTime, 'seconds')
      );
      return date.subtract(pixelsToTime, 'seconds');
    }
    lastWidth.current = width;
    lastHeight.current = height;
    return date;
  };

  const calculateLeftDate = () => {
    const halfWidth = width / 2;
    const timeOffset = halfWidth / ratio;
    // If widget is resized, the current date will change; update the date
    if (
      (isDashboard && width !== lastWidth.current) ||
      height !== lastHeight.current
    ) {
      calculateCenterDate();
    }
    return date.clone().subtract(timeOffset, 'seconds');
  };

  const calculateRightDate = () => {
    const halfWidth = width / 2;
    const timeOffset = halfWidth / ratio;
    return date.clone().add(timeOffset, 'seconds');
  };

  calculateCenterLine();
  return (
    <div className={classes.root}>
      <div id="start" className={classes.leftDate}>
        {calculateLeftDate().format(dateFormat)}
      </div>
      <div id="current" className={classes.centerDate}>
        {calculateCenterDate().format(dateFormat)}
      </div>
      <div id="end" className={classes.rightDate}>
        {calculateRightDate().format(dateFormat)}
      </div>
    </div>
  );
};

export default SpectraTimeLine;
