import { get } from 'util/WebRequest';

class EndeavourEarthquakeExportService {
  static exportEarthquakes = async (params) =>
    get('/EndeavourEarthquakeExportService', params).then((response) => {
      let blob;
      if (params.format === 'json') {
        // If the format is JSON, download it as a JSON file
        blob = new Blob([JSON.stringify(response.data.payload)], {
          type: 'application/json',
        });
      } else {
        // Otherwise, treat it as CSV (or another text-based format)
        blob = new Blob([response.data.payload], { type: `text/csv` });
      }
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `earthquakes.${params.format === 'json' ? 'json' : 'csv'}`;
      link.click();
    });
}

export default EndeavourEarthquakeExportService;
