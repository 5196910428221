import * as React from 'react';
import { Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import { Close } from '@onc/icons';
import { IconButton, Dropdown, type DropdownOption } from 'base-components';
import TableFilterFactory from './filters/TableFilterFactory';
import { TableFilterLine } from './ToolbarFilter';
import { TableColumn } from '../../StatelessTable';

type Props = {
  index: number;
  groupIndex: number;
  value: TableFilterLine;
  columns: TableColumn[];
  rows: any;
  classes: any;
  logic: 'And' | 'Or';
  onChange: (filterLine: TableFilterLine, index: number) => void;
  onLogicChange: (logic: 'And' | 'Or', index: number) => void;
  handleRemoveFilter: (index: number) => void;
};

const styles = (theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    flexItem: {
      margin: theme.spacing(0.5),
    },
    logic: {
      margin: theme.spacing(0.5),
      flexBasis: '80px',
      flexGrow: 0,
      flexShrink: 0,
    },
  });

const logicOptions = [
  {
    key: 'And',
    label: 'And',
    value: 'And',
  },
  {
    key: 'Or',
    label: 'Or',
    value: 'Or',
  },
];

const ToolbarFilterLine: React.VFC<Props> = ({
  index,
  value,
  columns,
  rows,
  classes,
  logic,
  onChange,
  handleRemoveFilter,
  onLogicChange,
  groupIndex,
}: Props) => {
  const getColumnObj = (columnName: string) => {
    const column = columns.find((cols) => cols.name === columnName);
    if (column) {
      return column;
    }
    return undefined;
  };

  const handleChangeColumn = (columnName: string) => {
    const updatedValue = { ...value };
    const columnObj = getColumnObj(columnName);
    updatedValue.column = columnName;
    updatedValue.dataType = columnObj ? columnObj.dataType : undefined;
    updatedValue.operator = undefined;
    onChange(updatedValue, index);
    if (columnObj && columnObj.onFilterColumn) {
      columnObj.onFilterColumn(groupIndex, index);
    }
  };
  const handleChangeOperator = (operator: string) => {
    const updatedValue = { ...value };
    updatedValue.operator = operator;
    onChange(updatedValue, index);
  };

  const handleChangeLogic = (e) => {
    onLogicChange(e.target.value, index);
  };

  const handleChangeValue = (newValue: string) => {
    const updatedValue = { ...value };
    updatedValue.value = newValue;
    onChange(updatedValue, index);
  };

  const getSelectOptions = () => {
    if (value.options) {
      return value.options;
    }
    if (value.dataType === 'Select') {
      return rows
        .map((row) => row[value.column])
        .filter((v, i, self) => self.indexOf(v) === i);
    }
    return [];
  };

  const getColumnOptions = () => {
    const options: DropdownOption[] = [];
    if (!columns.find((item) => value.column === item.name)) {
      options.push({
        key: value.column,
        label: value.column,
        value: value.column,
      });
    }
    const columnOptions: DropdownOption[] = columns.map((item) => ({
      key: item.name,
      label: item.title ? item.title : 'n/a',
      value: item.name,
    }));

    options.push(...columnOptions);
    return options;
  };

  const renderLogicColumn = () => {
    if (index > 0) {
      return (
        <Dropdown
          label="Logic"
          name="logic"
          className={classes.logic}
          fullWidth
          disabled={index !== 1}
          value={logic}
          options={logicOptions}
          onChange={handleChangeLogic}
        />
      );
    }
    return <div className={classes.logic} />;
  };
  return (
    <div
      className={classes.flexContainer}
      data-test="filter-line"
      key={index.toString()}
      style={{ minWidth: '600px' }}
    >
      {renderLogicColumn()}
      <Dropdown
        label="Column"
        name="column"
        className={classes.flexItem}
        fullWidth
        value={value.column}
        options={getColumnOptions()}
        onChange={(e) => handleChangeColumn(e.target.value)}
      />
      <TableFilterFactory
        handleChangeOperator={handleChangeOperator}
        handleChangeValue={handleChangeValue}
        operator={value.operator}
        value={value.value}
        options={getSelectOptions()}
        dataType={value.dataType}
      />
      <IconButton aria-label="Close" onClick={() => handleRemoveFilter(index)}>
        <Close />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(ToolbarFilterLine);
