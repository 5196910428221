import { post, get, patch, deleteImpl } from 'util/WebRequest';

class TaxonResourceService {
  static async getAll(params: {
    resourceTypeId?: number;
    resourceId?: number;
  }) {
    const { resourceTypeId, resourceId } = params;
    if (!isNaN(resourceId) && isNaN(resourceTypeId)) {
      throw new Error('resourceTypeId is required if searching by resourceId!');
    }
    const response = await get(
      'internal/taxonomies/taxons/resources',
      {
        ...(!isNaN(resourceTypeId) ? { resourceTypeId } : {}),
        ...(!isNaN(resourceId) ? { resourceId } : {}),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async create(taxonomyId, taxonId, resourceTypeId, resourceId) {
    const response = await post(
      'internal/taxonomies/taxons/resources',
      {
        taxonomyId,
        taxonId,
        resourceTypeId,
        resourceId,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async update(
    taxonResourceId,
    taxonomyId,
    taxonId,
    resourceTypeId,
    resourceId
  ) {
    const response = await patch(
      `internal/taxonomies/taxons/resources/${taxonResourceId}`,
      {
        taxonomyId,
        taxonId,
        resourceTypeId,
        resourceId,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async delete(taxonResourceId) {
    const response = await deleteImpl(
      `internal/taxonomies/taxons/resources/${taxonResourceId}`
    );
    return response.data;
  }
}
export default TaxonResourceService;
