import { useCallback } from 'react';
import { withStyles } from '@mui/styles';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import RequestSupport from './RequestSupport';
import SortableLink from '../network-console/SortableLink';

const styles = (theme) => ({
  table: {
    'overflow-x': 'hidden',
    margin: theme.spacing(1),
    minWidth: theme.spacing(75),
  },
});

const headers = [
  { title: 'Support Request ID', name: 'supportRequestId' },
  { title: 'Support Request Type ID', name: 'supportRequestTypeId' },
  { title: 'Summary', name: 'summary' },
  { title: 'Project', name: 'project' },
  { title: 'Description', name: 'description' },
  { title: 'Name', name: 'name' },
  { title: 'Email', name: 'email' },
  { title: 'User Affiliation', name: 'useraffiliation' },
  { title: 'Location', name: 'location' },
  { title: 'Referrer', name: 'referrer' },
  { title: 'User Agent', name: 'useragent' },
  { title: 'Screen Resolution', name: 'screenresolution' },
  { title: 'Create Date', name: 'createDate' },
  { title: 'Form Field ID For Search', name: 'supportRequestIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'supportRequestId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'supportRequestTypeId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'supportRequestIdForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'summary',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'project',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'name',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'email',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'useraffiliation',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'location',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'referrer',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'useragent',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'screenresolution',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'createDate',
    width: ColumnInfo.mini,
  },
];

type RequestSupportTableProps = {
  userHasWritePermission?: boolean;
  classes: { table: string };
  requestSupports: RequestSupport[] | null;
  openAndSaveEditData: (sr: RequestSupport) => void;
};

interface RequestSupportRowInfo extends RequestSupport {
  supportRequestId?: any;
  supportRequestIdForSearch?: number | string;
}
const RequestSupportTable = ({
  userHasWritePermission = false,
  classes,
  requestSupports,
  openAndSaveEditData,
}: RequestSupportTableProps) => {
  const isLoading = requestSupports === null;

  const BuildTableRows = useCallback(() => {
    if (!requestSupports) {
      return [];
    }
    let newTableRows = requestSupports;
    newTableRows = newTableRows.map((sr) => {
      const returnValue: RequestSupportRowInfo = { ...sr };
      userHasWritePermission
        ? (returnValue.supportRequestId = (
            <SortableLink
              id={sr.supportRequestId}
              onClick={() => openAndSaveEditData(sr)}
            />
          ))
        : (returnValue.supportRequestId = sr.supportRequestId);
      returnValue.supportRequestTypeId = sr.supportRequestTypeId;
      returnValue.supportRequestIdForSearch = sr.supportRequestId;
      returnValue.summary = sr.summary;
      returnValue.project = sr.project;
      returnValue.description = sr.description;
      returnValue.name = sr.name;
      returnValue.email = sr.email;
      returnValue.useraffiliation = sr.useraffiliation;
      returnValue.location = sr.location;
      returnValue.referrer = sr.referrer;
      returnValue.useragent = sr.useragent;
      returnValue.screenresolution = sr.screenresolution;
      returnValue.createDate = sr.createDate;
      return returnValue;
    });
    return newTableRows;
  }, [requestSupports, openAndSaveEditData, userHasWritePermission]);

  const tableRows = BuildTableRows();

  return (
    <>
      <SortableTable
        columns={headers}
        columnSizes={tableColumnExtensions}
        columnExtensions={tableColumnExtensions}
        elevation={0}
        pageSize={15}
        pageSizes={[15, 30, 60, 300, 0]}
        rows={tableRows}
        searchable
        searchBarMoveable
        stripedRows
        classes={classes.table}
        hiddenColumns={['supportRequestIdForSearch']}
        isLoading={isLoading}
        sortExtensions={
          userHasWritePermission
            ? [
                {
                  columnName: 'supportRequestId',
                  compare: (a, b) => (a.props.id > b.props.id ? 1 : -1),
                },
              ]
            : [
                {
                  columnName: 'supportRequestId',
                  compare: (a, b) => (a > b ? 1 : -1),
                },
              ]
        }
      />
    </>
  );
};

export default withStyles(styles)(withSnackbars(RequestSupportTable));
