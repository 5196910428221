import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from 'base-components';
import {
  CancelButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { NodeType } from './CreateShorestationDialog';
import { ConfirmationDialog } from '../dialogs/Dialogs';

const classes = {
  formButtons: {
    marginTop: 1,
    float: 'right',
  },
  textField: {
    marginBottom: 1,
  },
};

type MultiQueuePostProps = {
  type: string;
  destinationQueueId: number;
  deviceId: number;
  deviceTypeId: number;
  forceMapping: boolean;
};

type MapDeviceDialogProps = {
  closeDialog: () => void;
  refreshTree: () => void;
  showDialog: boolean;
  mapDeviceType: boolean;
  node: NodeType;
};

const MapDeviceDialog = ({
  closeDialog,
  refreshTree,
  showDialog,
  mapDeviceType,
  node,
}: MapDeviceDialogProps) => {
  const { onError } = useSnackbars();

  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(undefined);

  const formMethods = useForm<{ deviceId: string }>({ mode: 'onBlur' });
  const deviceId = formMethods.watch('deviceId');

  const openConfirmationDialog = (err) => {
    setShowConfirmationDialog(true);
    setErrorMessage(err);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
    setErrorMessage(undefined);
    formMethods.reset();
  };

  const { mutate: saveQueueDevice } = usePost<MultiQueuePostProps>(
    'MultiQueueService',
    {
      onSuccess: () => {
        refreshTree();
        closeConfirmationDialog();
        formMethods.reset();
      },
      onError: (response) => {
        if (
          !showConfirmationDialog &&
          (response.message.includes('not connected in the topology') ||
            response.message.includes('dependent deviceIds'))
        ) {
          openConfirmationDialog(response.message);
        } else {
          onError(`failed to map device to queue: ${response.message}`);
          closeConfirmationDialog();
          formMethods.reset();
        }
      },
    },
    1
  );

  const handleSave = () => {
    saveQueueDevice({
      destinationQueueId: node.objectId,
      deviceId: mapDeviceType ? undefined : Number(deviceId),
      deviceTypeId: mapDeviceType ? Number(deviceId) : undefined,
      forceMapping: showConfirmationDialog,
      type: 'queueDevice',
    });
    closeDialog();
  };

  const renderConfirmMappingDialog = () => {
    const parsedErrors = errorMessage ? JSON.parse(errorMessage).join() : '';
    const content = (
      <Typography variant="subtitle1" align="center">
        {parsedErrors} <br />
        Would you like to proceed with mapping {deviceId}?
      </Typography>
    );
    return (
      <ConfirmationDialog
        open={showConfirmationDialog}
        title="Map Device"
        content={content}
        onCancel={closeConfirmationDialog}
        onConfirm={handleSave}
      />
    );
  };

  return (
    <>
      <Dialog
        open={showDialog}
        onClose={() => {
          closeDialog();
          formMethods.reset();
        }}
        fullWidth
      >
        <DialogTitle>Create Queue Device</DialogTitle>
        <DialogContent>
          <Form onSubmit={handleSave} formMethods={formMethods}>
            <FormTextField
              defaultValue={node.objectId}
              translationKey="common.textfields.variable"
              translationOptions={{
                variableName: node.multiQueueTreeNodeName,
              }}
              sx={{ textField: classes.textField }}
              fullWidth
              disabled
              title="destinationQueueId"
              name="destinationQueueId"
            />
            <FormTextField
              translationKey={
                mapDeviceType ? 'device.deviceTypeId' : 'device.deviceId'
              }
              variant="filled"
              InputLabelProps={{ required: false }}
              placeholder={`Enter Device${mapDeviceType ? ' Type' : ''} ID`}
              name="deviceId"
              type="number"
              rules={{
                required: `A Device${mapDeviceType ? ' Type' : ''} ID is required!`,
              }}
              fullWidth
            />
            <Box sx={classes.formButtons}>
              <CancelButton
                onClick={() => {
                  formMethods.reset();
                  closeDialog();
                }}
              />
              <SaveButton type="submit" />
            </Box>
          </Form>
        </DialogContent>
      </Dialog>
      {renderConfirmMappingDialog()}
    </>
  );
};

export default MapDeviceDialog;
