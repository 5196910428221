import { useState, useContext, MouseEvent } from 'react';
import { grey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { useMap } from 'react-leaflet';
import { HelpIcon } from '@onc/icons';
import {
  Divider,
  Grow,
  Link,
  MapFABControl,
  Popper,
  Typography,
} from 'base-components';
import SupportRequestContainer from 'domain/Apps/issue-collector/SupportRequestContainer';
import MapContext from 'library/CompositeComponents/map/MapContext';
import Environment from 'util/Environment';
import useFabDialogStyles from './useFabDialogStyles';

const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      zIndex: 2000,
    },
    linkButton: {
      color: '#0078A8',
      textDecoration: 'underline',
    },
    helpMenu: {
      display: 'block',
    },
    lightMap: {
      textDecoration: 'none',
    },
  })
);

/**
 * Help Floating Action Button. Will only work and render properly if contained
 * in a react-leaflet Map such as SimpleMap
 */
const HelpFAB = () => {
  const [popperStyle, setPopperStyle] = useState({});
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const [showSupportRequest, setShowSupportRequest] = useState(false);
  // Merge local styles with common FAB styles
  const classes = { ...useStyles(), ...useFabDialogStyles() };

  /** Can I just color the button instead? */
  const theme = createTheme({
    palette: {
      secondary: {
        // dark grey color for the button
        main: '#2C2C2C',
      },
    },
  });

  const { mapId } = useContext(MapContext);
  const map = useMap();

  const handlePointerOut = () => {
    if (map) {
      map.options.dragging = true;
      map.options.scrollWheelZoom = true;
      map.options.doubleClickZoom = true;
    }
  };

  const handlePointerOver = () => {
    if (map) {
      map.options.dragging = false;
      map.options.scrollWheelZoom = false;
      map.options.doubleClickZoom = false;
    }
  };

  /**
   * Calculate where the popper should go on the anchor element
   *
   * @param anchorEl Anchor element to position the popper on
   */
  const calculatePopperPosition = (anchorEl) => {
    if (!anchorEl) return;
    const rect = anchorEl.getBoundingClientRect();

    // Use styles & state over makeStyles to allow dynamically moving the popper if the button moves
    setPopperStyle({
      zIndex: 2000,
      position: 'absolute',
      top: `${rect.top}px`,
      left: `${rect.left - 520}px`,
    });
  };

  const toggleHelpMenu = (e: MouseEvent<HTMLElement>) => {
    const newAnchorEl = helpAnchorEl ? null : e.currentTarget;
    setHelpAnchorEl(newAnchorEl);
    calculatePopperPosition(newAnchorEl);
  };

  const title = helpAnchorEl ? 'Hide Help' : 'Help';

  const renderHelp = () => {
    const CAST_SCALAR_PLOT_DATA_PRODUCT_ID = 139;
    const ALINGED_AND_DEPTH_BINNED_PROFILE_DATA = 152;
    const GEOSPACIAL_MAP_HELP = 'O2KB/Community+Fishers+Map+Help';

    return (
      <div
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
        className={classes.controlLayersContainer}
      >
        <Typography id="help-menu-title" gutterBottom>
          Geospatial Map Help and Support
        </Typography>
        <Divider />
        <div>
          <Link
            href={`${Environment.getExternalWikiPath()}/display/${GEOSPACIAL_MAP_HELP}`}
            id="helplink"
            target="_blank"
            rel="noreferrer"
            underline="always"
          >
            <Typography>
              Quick start guide for finding, viewing and downloading casts
            </Typography>
          </Link>
          <Link
            className={classes.helpMenu}
            href={`${Environment.getExternalWikiPath()}/display/DP/${ALINGED_AND_DEPTH_BINNED_PROFILE_DATA}`}
            id="corlink"
            target="_blank"
            rel="noreferrer"
            underline="always"
          >
            <Typography>What are .COR files?</Typography>
          </Link>
          <Link
            href={`${Environment.getExternalWikiPath()}/display/DP/${CAST_SCALAR_PLOT_DATA_PRODUCT_ID}`}
            id="plotlink"
            target="_blank"
            rel="noreferrer"
            underline="always"
          >
            <Typography>
              What plots are being displayed in the map popups?
            </Typography>
          </Link>
          <Link
            className={classes.lightMap}
            href={`${Environment.getDmasUrl()}/communityfishers`}
            id="lightmaplink"
            target="_blank"
            rel="noreferrer"
            underline="always"
          >
            <Typography>
              Having troubles loading? Try using our light page <u>here</u>
            </Typography>
          </Link>
          <br />
          <Link
            id="supportlink"
            className={classes.linkButton}
            component="button"
            onClick={() => setShowSupportRequest(true)}
          >
            <Typography>Request Support / Give Feedback</Typography>
          </Link>
        </div>
      </div>
    );
  };

  const renderSupportRequest = () => {
    if (showSupportRequest) {
      return (
        <SupportRequestContainer
          user={{
            name: Environment.getDmasUserName(),
            email: Environment.getDmasUserEmail(),
          }}
          jiraDomain={Environment.getTicketManagerUrl()}
          onClose={() => setShowSupportRequest(false)}
        />
      );
    }
    return null;
  };

  const open = Boolean(helpAnchorEl);
  return (
    <ThemeProvider theme={theme}>
      <MapFABControl
        name="helpbutton"
        TooltipProps={{ title }}
        onClick={toggleHelpMenu}
        size="medium"
        color="secondary"
        options={{ position: 'topright' }}
        prepend
      >
        <HelpIcon style={{ color: grey[300], fontSize: '420%' }} />
      </MapFABControl>
      <Popper
        transition
        open={open}
        anchorEl={helpAnchorEl}
        style={popperStyle}
        container={document.querySelector(`#${mapId}`)}
      >
        <Grow in style={{ transformOrigin: 'top left 0' }}>
          {renderHelp()}
        </Grow>
      </Popper>
      {renderSupportRequest()}
    </ThemeProvider>
  );
};

export default HelpFAB;
