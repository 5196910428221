import { FormFieldLineValue } from '../form-fields/FormFieldLine';

interface SimpleFormGroup {
  label: string;
  name: string;
}

export type FormFieldType =
  | 'boolean'
  | 'multiselect'
  | 'autocomplete'
  | 'menu'
  | 'select'
  | 'text'
  | 'group';

export interface FormFieldMenuItem {
  text: string;
  value?: string;
  submenu?: {
    itemdata: FormFieldMenuItem[];
  };
}

export interface FormField {
  attributes: any;
  disabled: boolean;
  groups: Map<string, SimpleFormGroup>;
  label?: string;
  menuItems?: FormFieldMenuItem[];
  datasource?: string[];
  choices?: object[];
  name: string;
  required: boolean;
  resourceId: number;
  resourceTypeId: number;
  rightLabel?: string;
  taxonId: number;
  taxonomyId: number;
  type: FormFieldType;
}

interface FormServiceGroup extends FormField {
  attributes: {
    privilege: 'RW' | 'RO';
  };
  fields: FormField[];
  type: 'group';
}

interface FormServiceData {
  0: FormServiceGroup[];
  1: FormServiceGroup[];
  2: FormServiceGroup[];
  3: FormServiceGroup[];
  4: FormServiceGroup[];
}

class FormFieldParser {
  static parseData = (data: FormServiceData) => {
    const formFields = new Map<string, FormField>();
    for (let x = 0; x <= 5; x += 1) {
      if (data[x]) {
        const groups = data[x];
        groups.forEach((group) => {
          const basicGroup = {
            name: group.name,
            label: group.label,
          };
          group.fields.forEach((field: FormField) => {
            const newField = { ...field };
            if (newField.label === '&nbsp;') {
              newField.label = newField.rightLabel;
            }
            if (x !== 0) {
              newField.label = `${field.label} (${x})`;
            }
            if (newField.datasource) {
              newField.choices = newField.datasource.map((item) => ({
                value: item,
                label: item,
              }));
            }
            if (!formFields.get(newField.name)) {
              formFields.set(newField.name, newField);
            }
            const savedField = formFields.get(newField.name);
            if (savedField) {
              const updatedField = { ...savedField };
              updatedField.groups = updatedField.groups || new Map();
              updatedField.groups.set(basicGroup.name, basicGroup);
              formFields.set(field.name, updatedField);
            }
          });
        });
      }
    }
    return formFields;
  };

  static getFormFieldsByGroup = (formFieldMap: Map<string, FormField>) => {
    const groupMap = new Map();
    formFieldMap.forEach((field: FormField, fieldId: string) => {
      field.groups.forEach((value, key) => {
        const updatedValue = { ...(groupMap.get(key) || value) };
        updatedValue.fields = updatedValue.fields || new Map();
        updatedValue.fields.set(fieldId, field);
        groupMap.set(key, updatedValue);
      });
    });
    return groupMap;
  };

  static extractStringValue = (
    type: FormFieldType | undefined,
    fieldValue: any
  ) => {
    /**
     * Typing the fieldValue is difficult since it can be a string, number,
     * object, or array of object The line "fieldValue.forEach" errors if you
     * try to type it with the above type, since forEach doesn't exist on a
     * string/number/object.
     *
     * This line "fieldValue.value" errors out because that doesn't exist on a
     * string / number.
     */

    let valueString = '';
    switch (type) {
      case 'multiselect':
        fieldValue.forEach((option, i) => {
          valueString += option.value;
          if (i < fieldValue.length - 1) {
            valueString += ',';
          }
        });
        return valueString;
      default:
        return `${fieldValue}`;
    }
  };

  static buildServiceString = (formFields: FormFieldLineValue[]) =>
    JSON.stringify(
      formFields
        .filter(
          (line) =>
            line?.field &&
            line?.fieldValue !== undefined &&
            line?.fieldValue !== null
        )
        .reduce((obj, line) => {
          // eslint-disable-next-line no-param-reassign
          obj[line.field.value] = FormFieldParser.extractStringValue(
            line.fieldType,
            line.fieldValue
          );
          return obj;
        }, {})
    );

  static getGroupOptions = (
    formFields: Map<string, FormField>,
    selectedField?: { value: string; label: string } | null
  ) => {
    if (formFields) {
      const groupMap = new Map();
      formFields.forEach((field) => {
        field.groups.forEach((group, key) => {
          if (
            group.label &&
            (!selectedField || selectedField.value === field.name)
          ) {
            groupMap.set(key, group);
          }
        });
      });
      const options: object[] = [];
      groupMap.forEach((group) => {
        options.push({ label: group.label, value: group.name });
      });
      return options.sort((a: any, b: any) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
      );
    }
    return [];
  };

  static getFieldOptions = (
    formFields: Map<string, FormField>,
    selectedGroup?: { value: string; label: string } | null
  ) => {
    if (formFields) {
      const options: object[] = [];
      formFields.forEach((field) => {
        if (!selectedGroup || field.groups.get(selectedGroup.value)) {
          options.push({
            label: field.label,
            value: field.name,
          });
        }
      });
      return options.sort((a: any, b: any) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
      );
    }
    return [];
  };
}

export default FormFieldParser;
