import { useState, useEffect } from 'react';
import { get } from 'util/WebRequest';
import type Sensor from 'domain/AppComponents/Dashboard/chart-widget/types/Sensor';
import type { Moment } from 'moment';

const useSensors = (
  deviceCode: string,
  dateFrom?: Moment,
  dateTo?: Moment
): Sensor[] => {
  const [sensors, setSensors] = useState([]);
  useEffect(() => {
    const fetchSensors = async () => {
      if (deviceCode.length === 0) return;
      const response = await get('api/scalardata/device', {
        deviceCode,
        dateFrom: dateFrom?.toISOString(),
        dateTo: dateTo?.toISOString(),
        rowLimit: 80000,
        returnOptions: 'excludeScalarData',
      });
      setSensors(response.data.sensorData);
    };
    fetchSensors();
  }, [dateFrom, dateTo, deviceCode]);

  return sensors;
};

export default useSensors;
