import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import OrganizationDetailsTemplate from './OrganizationDetailsTemplate';
import OrganizationService from './OrganizationService';

class OrganizationDetails extends PureComponent {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
  };

  state = {
    organizationDetails: undefined,
  };

  componentDidMount() {
    const { organizationId } = this.props;
    this.getOrganizationDetails(organizationId);
  }

  componentDidUpdate(prevProps) {
    const { organizationId } = this.props;
    const { organizationId: prevOrganizationId } = prevProps;
    if (organizationId !== prevOrganizationId && organizationId) {
      this.getOrganizationDetails(organizationId);
    }
  }

  getOrganizationDetails = async (organizationId) => {
    const payload =
      await OrganizationService.getOrganizationDetails(organizationId);
    if (payload) {
      this.setState({ organizationDetails: payload });
    }
  };

  render() {
    const { organizationDetails } = this.state;
    return (
      <OrganizationDetailsTemplate
        organizationDetails={organizationDetails}
        stillLoading={!organizationDetails}
      />
    );
  }
}

export default OrganizationDetails;
