import { useEffect, useState } from 'react';

import PlaylistService from 'domain/services/PlaylistService';
import FormAutocomplete, {
  FormAutocompleteProps,
} from 'library/CompositeComponents/form/FormAutocomplete';
import { useSnackbars } from 'util/hooks/useSnackbars';

export type option = {
  key: number;
  value: number;
  label: string;
};

type PlaylistDropdownProps = Omit<FormAutocompleteProps, 'options'> & {
  excludePlaylistHdrId?: number;
};

const PlaylistsDropdown = ({
  name = 'playlistHdrId',
  translationKey = 'seatube.playlist',
  excludePlaylistHdrId = undefined,
  onInputChange = undefined,
  ...props
}: PlaylistDropdownProps) => {
  const [userPlaylists, setUserPlaylists] = useState<option[]>([]);
  const { onError } = useSnackbars();

  const getUserPlaylists = () => {
    PlaylistService.getPlaylists()
      .then((payload) => {
        if (payload) {
          const options = payload
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((playlistHdr) => playlistHdr.locked === false)
            .filter(
              (playlistHdr) =>
                playlistHdr.playlistHdrId !== excludePlaylistHdrId
            )
            .map((playlistHdr) => ({
              key: playlistHdr.playlistHdrId,
              value: playlistHdr.playlistHdrId,
              label: playlistHdr.name,
            }));
          setUserPlaylists(options);
        }
      })
      .catch(() => {
        onError('Unable to retrieve playlists');
      });
  };

  useEffect(getUserPlaylists, [excludePlaylistHdrId, onError]);

  return (
    <FormAutocomplete
      name={name}
      translationKey={translationKey}
      options={userPlaylists}
      onInputChange={onInputChange}
      {...props}
    />
  );
};

export default PlaylistsDropdown;
