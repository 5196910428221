import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Divider, Grid, TableOld as Table, Typography } from 'base-components';
import {
  MarginedReadOnlyField,
  MarginedLinkField,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import Environment from 'util/Environment';

const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  headingStyle: {
    paddingTop: theme.spacing(2),
  },
  tableTitle: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    color: 'rgba(0, 0, 0, 0.54)',
    transformOrigin: 'top left',
    marginTop: theme.spacing(2),
  },
  indentLevel: {
    paddingRight: theme.spacing(2),
  },
});

const KEYS = {
  dataProduct: 'Data Product',
  dateCreated: 'Query Date Created',
  dateFrom: 'Query Date From',
  dateTo: 'Query Date To',
  variables: 'Variables',
  format: 'Format',
  parameters: 'Data Product Options',
};

class DOIQueryFields extends Component {
  static propTypes = {
    querydata: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
      ])
    ),
    classes: PropTypes.shape({
      root: PropTypes.string,
      headingStyle: PropTypes.string,
    }),
    formFields: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
      ])
    ),
  };

  static defaultProps = {
    querydata: undefined,
    classes: undefined,
    formFields: undefined,
  };

  renderFieldValueType = (key, value, url) => {
    if (url) {
      return (
        <MarginedLinkField
          labelText={key}
          url={url}
          valueText={value.toString()}
          title={key}
          id={key}
        />
      );
    }
    return (
      <MarginedReadOnlyField
        labelText={key}
        valueText={value.toString()}
        title={key}
        id={key}
      />
    );
  };

  generateDataProductionOptionValue = (parameter) => {
    if (parameter.child !== undefined && parameter.child.displayValue !== '') {
      return `${parameter.displayValue} / ${parameter.child.displayValue}`;
    }
    return parameter.displayValue;
  };

  renderUndefined = (key, message, classes) => (
    <Grid
      key={key}
      className={classes.indentLevel}
      container
      direction="column"
      name={key}
    >
      <MarginedReadOnlyField labelText={key} valueText={message} title={key} />
    </Grid>
  );

  renderTable = (key, value, classes) => (
    <Grid
      key={`${key} - ${value}`}
      item
      xs={12}
      className={classes.indentLevel}
      name={key}
    >
      <Typography
        variant="subtitle1"
        align="left"
        className={classes.tableTitle}
      >
        {key}
      </Typography>
      <Divider />
      <Table
        noDataMessage="No options available"
        rows={value.map((row) => {
          const { formFields } = this.props;
          if (formFields !== undefined) {
            return {
              type: (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${formFields.get(row.type)}`}
                >
                  {row.type}
                </a>
              ),
              value: row.value,
            };
          }
          return {
            type: row.type,
            value: row.value,
          };
        })}
        columns={[
          { name: 'type', title: 'type' },
          { name: 'value', title: 'value' },
        ]}
        columnExtensions={[{ columnName: 'value', align: 'right' }]}
        showHeaderRow={false}
        elevation={0}
      />
    </Grid>
  );

  renderReadOnlyField = (key, value, url, classes) => (
    <Grid
      key={key}
      className={classes.indentLevel}
      container
      direction="column"
      name={key}
    >
      {this.renderFieldValueType(key, value, url)}
    </Grid>
  );

  generateDataProductionOptionTable = (parsedParameters) =>
    parsedParameters.map((parameter) => ({
      type: parameter.label,
      value: this.generateDataProductionOptionValue(parameter),
    }));

  renderItem = (key, value, url, classes) => {
    // Render a table for contributors and geolocations.
    if (key === KEYS.parameters) {
      return this.renderTable(key, value, classes);
    }
    if (typeof value !== 'undefined') {
      return this.renderReadOnlyField(key, value, url, classes);
    }
    if (key === KEYS.variables) {
      return this.renderUndefined(key, 'All', classes);
    }
    return this.renderUndefined(key, 'undefined', classes);
  };

  generateRows = (querydata) => {
    const rows = [
      {
        key: KEYS.dataProduct,
        value: querydata.dataProduct,
        url: `${Environment.getExternalWikiPath()}/display/DP/${
          querydata.dataProductId
        }`,
      },
      {
        key: KEYS.dateCreated,
        value: querydata.dateCreated,
      },
      {
        key: KEYS.dateFrom,
        value: querydata.dateFrom,
      },
      {
        key: KEYS.dateTo,
        value: querydata.dateTo,
      },
      {
        key: KEYS.variables,
        value: querydata.sensorName,
      },
      {
        key: KEYS.format,
        value: querydata.format,
      },
      {
        key: KEYS.parameters,
        value: this.generateDataProductionOptionTable(
          querydata.parsedParameters
        ),
      },
    ];
    return rows;
  };

  render() {
    const { querydata, classes } = this.props;
    if (querydata) {
      const rows = this.generateRows(querydata).map((row) =>
        this.renderItem(row.key, row.value, row.url, classes)
      );
      return (
        <>
          <Typography className={classes.headingStyle} variant="h6">
            Query Details
          </Typography>
          <div className={classes.root}>{rows}</div>
        </>
      );
    }
    return null;
  }
}
export default withStyles(styles)(DOIQueryFields);
