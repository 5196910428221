import { useEffect, useState } from 'react';
import moment from 'moment';
import { Loading } from '@onc/composite-components';
import { Error } from '@onc/icons';
import { Box, Typography } from 'base-components';
import ArchiveFileService, {
  useArchiveFileByLocation,
} from 'domain/services/ArchiveFileService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import PlaylistVideoPlayer, {
  VideoClip,
} from 'library/CompositeComponents/video/playlist-player/PlaylistVideoPlayer';

import Environment from 'util/Environment';
import useBroadcast from 'util/hooks/useBroadcast';
import BroadcastChannel from '../BroadcastChannel';

const FixedCameraVideoWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id, dashboardId } = props;
  const {
    dashboardState: config,
    setDashboardStateProperty: setConfigProperty,
  } = useDashboardState();
  const {
    resourceId: locationId,
    currentClip,
    autoPlay,
    selectedAnnotation,
  } = config;

  const [playlist, setPlaylist] = useState<VideoClip[]>([]);

  const [currentTimestamp, setCurrentTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.CurrentTimestamp,
    undefined,
    id
  );

  const [seekToTimestamp, setSeekToTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.SeekToTimestamp,
    undefined,
    id
  );

  const {
    data: archiveFiles,
    isLoading,
    refetch: fetchArchiveFiles,
  } = useArchiveFileByLocation({
    method: 'getListByLocation',
    locationCode: config.searchTreeNodeCode,
    returnOptions: 'all',
    deviceCategoryCode: 'VIDEOCAM',
    dateFrom: config.date?.utc().toISOString(),
    dateTo: config.date?.clone().add(1, 'day').utc().toISOString(),
    dataProductCode: 'MP4V',
  });

  const calculateVideoIntervals = (videoFiles: VideoClip[]) => {
    const intervals = [];
    videoFiles
      ?.sort((a, b) => (a.dateFrom < b.dateFrom ? -1 : 1))
      .forEach((videoclip) => {
        const startDate = videoclip.dateFrom;
        const endDate = videoclip.dateTo;

        intervals.push({ startDate, endDate });
      });
    setConfigProperty('videoIntervals', intervals);
  };

  const renderNoVideo = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Error color="disabled" fontSize="large" />
      <Typography variant="h6" sx={{ color: 'text.disabled' }}>
        No Video Found
      </Typography>
    </Box>
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (moment.utc().isBefore(moment.utc(config.date).add(3, 'day'))) {
        fetchArchiveFiles();
      }
    }, 900000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.date]);

  // Sets the timestamp at the time of the video start instead of waiting for onProgress
  useEffect(() => {
    const files: VideoClip[] = archiveFiles?.files.map((file) => ({
      url: ArchiveFileService.generateArchiveFileUrl(file),
      filename: file.filename,
      dateFrom: moment.utc(file.dateFrom),
      dateTo: moment.utc(file.dateTo),
      deviceCode: file.deviceCode,
    }));
    setPlaylist(files || []);
    setConfigProperty('currentClip', files?.[0]?.filename);
    calculateVideoIntervals(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveFiles]);

  useEffect(() => {
    if (selectedAnnotation) {
      for (const clip of playlist) {
        if (
          moment
            .utc(selectedAnnotation.startDate)
            .isBetween(clip.dateFrom, clip.dateTo)
        ) {
          setConfigProperty('currentClip', clip.filename);
          setSeekToTimestamp(selectedAnnotation.startDate);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnnotation, playlist]);

  const renderContent = () => {
    if (isLoading) {
      return <Loading />;
    }
    if (playlist.length === 0 && !isLoading) {
      return renderNoVideo();
    }
    return (
      <div style={{ overflow: 'hidden', height: '100%' }}>
        <PlaylistVideoPlayer
          id="historical-dive-player"
          files={playlist}
          currentFileName={currentClip}
          autoPlayNext={autoPlay}
          onClipChange={(clip) => {
            setConfigProperty('currentClip', clip);
          }}
          onProgress={(date) => {
            // Don't update the timestamp if we're seeking
            if (seekToTimestamp) return;
            setCurrentTimestamp(date.toISOString());
          }}
          seekToDate={seekToTimestamp ? moment.utc(seekToTimestamp) : undefined}
          shareUrl={`${Environment.getLinkUrl()}/app/fixed-camera-locations/${locationId}?time=${currentTimestamp}Z`}
          onSeekComplete={() => {
            setSeekToTimestamp(null);
          }}
        />
      </div>
    );
  };

  return (
    <Widget
      key={id}
      title="Video"
      MenuItems={[]}
      {...props}
      onRemoveAction={() => {
        setConfigProperty('videoIntervals', null);
      }}
    >
      <Box
        sx={{
          height: '100%',
        }}
      >
        {renderContent()}
      </Box>
    </Widget>
  );
};

FixedCameraVideoWidget.widgetKey = 'fixed-camera-video';
FixedCameraVideoWidget.widgetTitle = 'Fixed Camera Video';
FixedCameraVideoWidget.defaultDataGrid = {
  i: 'fixed-camera-video',
  x: 0,
  y: 0,
  w: 5,
  h: 6,
};

export default FixedCameraVideoWidget;
