import React from 'react';
import { type Theme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from 'base-components';

export type LegendItem = {
  name: string;
  colour: string;
  tooltipName?: string;
};

type LegendProps = {
  items: LegendItem[];
  width: number;
  title: string;
  showBorder?: boolean;
};

const Legend: React.FC<LegendProps> = ({
  items,
  width,
  title,
  showBorder = false,
}) => (
  <Grid
    container
    direction="column"
    spacing={1}
    sx={(theme: Theme) => ({
      border: showBorder ? `1px solid ${theme.palette.divider}` : 'none',
      boxSizing: 'border-box',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(4),
      width,
    })}
    role="list"
    aria-label={title}
  >
    {items.map((item, index) => (
      <Stack
        key={item.name}
        direction="row"
        alignItems="center"
        title={item.tooltipName}
        sx={{
          flexGrow: 1,
          paddingBottom: index === items.length - 1 ? 1 : 0,
          paddingTop: index === 0 ? 1 : 0,
        }}
        aria-label={`${item.name} ${item.tooltipName} - ${item.colour}`}
        role="listitem"
      >
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor: item.colour,
            marginRight: 0.5,
            marginLeft: 0.5,
            border: '1px solid black',
            flexShrink: 0,
          }}
        />
        <Typography sx={{ flexGrow: 1 }}>{item.name}</Typography>
      </Stack>
    ))}
  </Grid>
);

export default Legend;
