import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import DeviceService from 'domain/AppComponents/Dashboard/DeviceService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

class ReadOnlyResourceSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    timestamp: PropTypes.instanceOf(Date).isRequired,
    searchTreeNodeId: PropTypes.number.isRequired,
    deviceCategoryId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.value = 0;
    this.state = { resources: [], options: [] };
  }

  componentDidMount() {
    this.getSearchTreeResources();
  }

  shouldComponentUpdate(nextProps) {
    const { timestamp: nextTimestamp } = nextProps;
    const newValue = this.getResourceIdByTimestamp(nextTimestamp);
    if (newValue !== this.value) {
      this.value = newValue;
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    const { onChange } = this.props;
    onChange(this.value);
  }

  async getSearchTreeResources() {
    const { searchTreeNodeId, deviceCategoryId, onError } = this.props;

    DeviceService.getFixedSearchTreeNodeDevicesByDeviceCategory(
      searchTreeNodeId,
      deviceCategoryId,
      onError
    )
      .then((resources) => {
        const { devices } = resources;
        const options = devices.map((device) => ({
          label: `${device.deviceName} (${device.deviceId})`,
          value: device.deviceId,
        }));
        this.setState({ resources: devices, options });
      })
      .catch((error) => {
        onError(error);
      });
  }

  getResourceIdByTimestamp = (timestamp) => {
    const { resources } = this.state;

    if (resources.length === 0) {
      return 0;
    }

    for (let i = 0; i < resources.length; i += 1) {
      if (
        timestamp > Date.parse(resources[i].dateFrom) &&
        timestamp < Date.parse(resources[i].dateTo)
      ) {
        return resources[i].deviceId;
      }
    }
    // eslint-disable-next-line
    // TODO: This should render an error snackbar, and set the dropdown to blank
    return 0;
  };

  render() {
    const { options } = this.state;
    const { timestamp } = this.props;
    const value = this.getResourceIdByTimestamp(timestamp);

    return (
      <Dropdown
        disabled
        value={value}
        title="readOnlyResourceSelect"
        label="Resource"
        options={options}
        fullWidth
      />
    );
  }
}

export default withSnackbars(ReadOnlyResourceSelect);
