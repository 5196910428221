import * as React from 'react';
import { ClassNameMap } from '@mui/styles/withStyles';
import TableBooleanFilter from './TableBooleanFilter';
import TableDateFilter from './TableDateFilter';
import TableNumberFilter from './TableNumberFilter';
import TableSelectFilter from './TableSelectFilter';
import TableStringFilter from './TableStringFilter';
import { TableColumnDataTypes } from '../../../StatelessTable';

export interface FilterProps {
  operator?: string | null;
  value?: string | string[];
  dataType: TableColumnDataTypes;
  options?: any[];
  handleChangeOperator: (operator: string) => void;
  handleChangeValue: (newValue: string) => void;
  classes?: ClassNameMap;
}

const TableFilterFactory: React.VFC<FilterProps> = ({ dataType, ...rest }) => {
  switch (dataType) {
    case 'Number':
      return <TableNumberFilter dataType={dataType} {...rest} />;

    case 'Integer':
      return <TableNumberFilter dataType={dataType} {...rest} />;

    case 'Boolean':
      return <TableBooleanFilter dataType={dataType} {...rest} />;

    case 'Select':
      return <TableSelectFilter dataType={dataType} {...rest} />;

    case 'Date':
      return <TableDateFilter dataType={dataType} {...rest} />;

    default:
      return <TableStringFilter dataType={dataType} {...rest} />;
  }
};

export default TableFilterFactory;
