import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';

const options = [
  {
    label: '1 MHz Acoustic Doppler Current Profiler',
    value: 'ADCP1MHZ',
    key: 'ADCP1MHZ',
  },
  {
    label: 'Hydrophone',
    value: 'HYDROPHONE',
    key: 'HYDROPHONE',
  },
  {
    label: '600 KHz Acoustic Doppler Current Profiler',
    value: 'ADCP600KHZ',
    key: 'ADCP600KHZ',
  },
  {
    label: '300 KHz Acoustic Doppler Current Profiler',
    value: 'ADCP300KHZ',
    key: 'ADCP300KHZ',
  },
];

class DataPlayerCategorySelect extends Component {
  static propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onError: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    name: undefined,
    title: undefined,
    onBlur: undefined,
    onChange: undefined,
    helperText: undefined,
    error: undefined,
    className: undefined,
    value: undefined,
    onError: undefined,
    disabled: false,
  };

  render() {
    const {
      name,
      title,
      onBlur,
      onChange,
      helperText,
      error,
      className,
      value,
      disabled,
      onError,
    } = this.props;
    return (
      <Dropdown
        options={options}
        name={name}
        title={title}
        onBlur={onBlur}
        onChange={onChange}
        helperText={helperText}
        error={error}
        onError={onError}
        className={className}
        label="Device Category"
        id="deviceCategory"
        value={value}
        disabled={disabled}
        fullWidth
      />
    );
  }
}

export default DataPlayerCategorySelect;
