import moment from 'moment';
import { SynchronizerDeployment } from 'domain/services/SynchronizerManagementService';
import { SyncForm } from './CreateDeploymentForm';

export interface DeploymentTableRow {
  id: number;
  deploymentId: number;
  synchronizerId: number;
  synchronizerName: string;
  organization: string;
  organizationId: number;
  location: string;
  locationId: number;
  dateFrom: string;
  dateTo: string;
  modifyBy: string;
  modifyDate: string;
}

class DeploymentsTableLogic {
  static formatRows = (
    deployments: SynchronizerDeployment[]
  ): DeploymentTableRow[] =>
    deployments.map((deployment, i) => ({
      id: i,
      deploymentId: deployment.deploymentId,
      synchronizerId: deployment.synchronizer.synchronizerId,
      synchronizerName: deployment.synchronizer.synchronizerName,
      organization: deployment.organization.organizationName,
      organizationId: deployment.organization.organizationId,
      location: deployment.mqLocation.mqLocationCode,
      locationId: deployment.mqLocation.mqLocationId,
      dateFrom: deployment.dateFrom,
      dateTo: deployment.dateTo,
      modifyBy: `${deployment.modifyBy.lastname}, ${deployment.modifyBy.firstname}`,
      modifyDate: deployment.modifyDate,
    }));

  static tableRowToSyncForm = (row: DeploymentTableRow): SyncForm => ({
    deploymentId: row.deploymentId,
    synchronizerId: row.synchronizerId,
    organizationId: row.organizationId,
    mqlocationId: row.locationId,
    startNow: false,
    dateFrom: moment.utc(row.dateFrom),
    dateTo: moment.utc(row.dateTo),
  });
}

export default DeploymentsTableLogic;
