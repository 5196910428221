import React from 'react';
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete } from 'base-components';
import DataAvailabilityChartV2 from 'domain/AppComponents/charts/DataAvailabilityChartV2';
import DataSourceList from 'domain/AppComponents/data-source/DataSourceList';
import useFilteredDevices from 'domain/hooks/useFilteredDevices';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';

const DEVICE_CATEGORIES = ['HYDROPHONE'];

type FormDataSourceListProps = FormFieldType<
  Omit<
    React.ComponentProps<typeof DataSourceList>,
    'dataSourceCallback' | 'dataSources'
  >
>;

export const FormDataSourceList = ({
  name,
  ...rest
}: FormDataSourceListProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DataSourceList
          {...rest}
          dataSourceCallback={onChange}
          dataSources={value}
        />
      )}
    />
  );
};

type FormDataAvailabilityChartProps = FormFieldType<
  React.ComponentProps<typeof DataAvailabilityChartV2>
>;

export const FormDataAvailabilityChart: React.FC<
  FormDataAvailabilityChartProps
> = ({ name, dataSources, ...rest }: FormDataAvailabilityChartProps) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <DataAvailabilityChartV2
          {...rest}
          dataSources={dataSources}
          onDateRangeSelected={(event) => {
            const { dateFrom, dateTo } = event;
            setValue('startDate', moment(dateFrom));
            setValue('endDate', moment(dateTo));
          }}
        />
      )}
    />
  );
};

type FormDeviceAutocompleteProps = FormFieldType<
  Omit<
    React.ComponentProps<typeof Autocomplete>,
    'dataSourceCallback' | 'dataSources' | 'options'
  >
>;

export const FormDeviceAutocomplete = ({
  name,
  ...rest
}: FormDeviceAutocompleteProps) => {
  const { control } = useFormContext();
  const options = useFilteredDevices(DEVICE_CATEGORIES, undefined, undefined);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          {...rest}
          onChange={onChange}
          value={value}
          translationKey="device.device"
          name={name}
          options={options}
          virtualized
          noOptionsText="No Devices Available"
          getOptionLabel={(option) =>
            `${option.name} ${option.deviceId ? `(${option.deviceId})` : ''}`
          }
        />
      )}
    />
  );
};
