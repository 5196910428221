import useGet from 'util/hooks/useDmasAPI/useGet';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

export type SeaTubePermissionObj = {
  annotationPrivilege: boolean;
  deleteAnnotationPrivilege: boolean;
  selfDeleteAnnotationPrivilege: boolean;
  selfDeletePublicAnnotations: boolean;
  reviewPrivilege: boolean;
};

export type SeaTubePermissionJSON = {
  name: string;
  value: boolean;
}[];

export const useFixedCameraPermissions = () =>
  useGet<SeaTubePermissionJSON>('/seatube/permissions', 3);

class SeaTubePermissionsService {
  static getUserPermissions = async (props: {
    diveId?: number;
    cruiseId?: number;
  }) => {
    const payload = await get(
      'seatube/permissions',
      { operation: 1, ...props },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

    return payload;
  };

  static getOrganizationPermissions = async () =>
    get('seatube/permissions', {
      operation: 2,
    }).then((response) => parseDmasAPIResponse(response));
}

export default SeaTubePermissionsService;
