import {
  useCallback,
  useEffect,
  useState,
  SyntheticEvent,
  ChangeEvent,
} from 'react';
import { Loading } from '@onc/composite-components';
import { PlayCircleOutline, Search } from '@onc/icons';
import { IconButton, TreeItem, TreeView } from 'base-components';
import {
  SearchTreeNode,
  useFixedCameraLocations,
} from 'domain/services/SearchTreeService';

import Environment from 'util/Environment';

const FIXED_CAMERA_NODE_ID = '11';

type FixedCameraLocationTreeProps = {
  onNodeSelect: (event: ChangeEvent | SyntheticEvent, nodeId: string) => void;
  selectedNodeId?: string;
  expandToNodeId?: string;
  searchQuery?: string;
};

const renderActionButtons = (node: SearchTreeNode) => (
  <>
    <IconButton
      aria-label="Watch Video"
      onClick={() => {
        window.open(
          `${Environment.getLinkUrl()}/app/fixed-camera-locations/${node.searchTreeNodeId}`,
          '_blank'
        );
      }}
    >
      <PlayCircleOutline color="action" />
    </IconButton>
    <IconButton
      aria-label="Search Annotations"
      onClick={() => {
        window.open(
          `${Environment.getLinkUrl()}/SeaTubeSearch?searchTreeNodeIds=${node.searchTreeNodeId}`,
          '_blank'
        );
      }}
    >
      <Search color="action" />
    </IconButton>
  </>
);

const highlightMatchingText = (text: string, query: string) => {
  if (!query) return text;
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} style={{ backgroundColor: 'yellow' }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};

const FixedCameraLocationTree = ({
  selectedNodeId = null,
  onNodeSelect,
  searchQuery = undefined,
  expandToNodeId = null,
}: FixedCameraLocationTreeProps) => {
  const { data, isLoading } = useFixedCameraLocations();
  const [expandedNodes, setExpandedNodes] = useState<string[]>([
    FIXED_CAMERA_NODE_ID,
  ]);
  const [matchingNodes, setMatchingNodes] = useState<string[]>([]);

  const handleNodeSelect = (
    event: React.SyntheticEvent,
    nodeId: string | string[]
  ) => {
    if (typeof nodeId !== 'string') {
      throw new Error('Not implemented');
    }
    onNodeSelect(event, nodeId); // Call the parent-provided onNodeSelect

    // Toggle expansion state for the selected node
    setExpandedNodes((prevExpanded) => {
      if (prevExpanded.includes(nodeId)) {
        // Collapse the node if it's already expanded
        return prevExpanded.filter((id) => id !== nodeId);
      }
      // Expand the node if it's not already expanded
      return [...prevExpanded, nodeId];
    });
  };

  // Recursive function to find matches and paths
  const findMatchingNodes = useCallback(
    (node: SearchTreeNode, query: string): string[] => {
      let matches: string[] = [];

      if (node.html.toLowerCase().includes(query.toLowerCase())) {
        matches.push(node.searchTreeNodeId.toString());
      }

      if (node.children) {
        for (const child of node.children) {
          const childMatches = findMatchingNodes(child, query);
          if (childMatches.length > 0) {
            matches = [...matches, ...childMatches];
            matches.push(node.searchTreeNodeId.toString()); // Include parent node for expansion
          }
        }
      }

      return matches;
    },
    []
  );

  const findPathToNode = useCallback((nodeId: string, node: SearchTreeNode) => {
    if (node.searchTreeNodeId === Number(nodeId)) {
      return [node.searchTreeNodeId.toString()];
    }
    if (node.children) {
      for (const child of node.children) {
        const path = findPathToNode(nodeId, child);
        if (path) {
          return [node.searchTreeNodeId.toString(), ...path];
        }
      }
    }
    return null;
  }, []);

  // Handle search query change
  useEffect(() => {
    if (searchQuery && data) {
      const matches = findMatchingNodes(data, searchQuery);
      setMatchingNodes(matches);
      setExpandedNodes(matches); // Expand all matches' paths
    } else {
      setMatchingNodes([]);
      setExpandedNodes([FIXED_CAMERA_NODE_ID]); // Reset to default state
    }
  }, [searchQuery, data, findMatchingNodes]);

  // Handle Selected Node Change
  useEffect(() => {
    if (expandToNodeId && data) {
      const path = findPathToNode(expandToNodeId, data);
      if (path) {
        setExpandedNodes([...path]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandToNodeId, data, findPathToNode]);

  const renderNode = (node: SearchTreeNode) => {
    const hasMatchingText = node.html
      .toLowerCase()
      .includes(searchQuery?.toLowerCase() || '');
    const isHighlighted =
      hasMatchingText &&
      matchingNodes.includes(node.searchTreeNodeId.toString());
    return (
      <TreeItem
        nodeId={node?.searchTreeNodeId.toString()}
        label={
          isHighlighted
            ? highlightMatchingText(node.html, searchQuery || '')
            : node.html
        }
        endIcon={renderActionButtons(node)}
        sx={{
          '& .MuiTreeItem-iconContainer': {
            width: 'auto !important',
          },
        }}
      >
        {Array.isArray(node.children)
          ? node?.children.map((childNode) => renderNode(childNode))
          : null}
      </TreeItem>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <TreeView
      expanded={expandedNodes}
      onNodeSelect={handleNodeSelect}
      selected={selectedNodeId}
    >
      {renderNode(data)}
    </TreeView>
  );
};

export default FixedCameraLocationTree;
