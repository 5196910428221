import React, { ReactNode } from 'react';
import { InputLabel } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Typography from './Typography';

const useStyles = makeStyles((theme: Theme) => ({
  readOnlyText: {
    color: theme.palette.text.primary,
    margin: theme.spacing(1, 0),
  },
}));

type ReadOnlyTextFieldProps = {
  label: string;
  value: ReactNode;
  inputClassName?: string;
  containerClassName?: string;
};

const ReadOnlyTextField: React.VFC<ReadOnlyTextFieldProps> = ({
  label,
  value,
  inputClassName = undefined,
  containerClassName = undefined,
}) => {
  const classes = useStyles();

  const inputClasses = `${inputClassName || ''} ${classes.readOnlyText}`;

  return (
    <div className={containerClassName}>
      <InputLabel shrink>{label}</InputLabel>
      <Typography className={inputClasses}>{value}</Typography>
    </div>
  );
};

export default ReadOnlyTextField;
