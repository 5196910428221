import PropTypes from 'prop-types';

import { HelpOutline } from '@onc/icons';
import { Grid, TextField, Tooltip } from 'base-components';

const UserAffiliationField = (props) => {
  const { affiliation, onChange } = props;

  const handleChange = (event) => onChange(event.target.value);

  return (
    <Grid item container direction="row" spacing={2} alignItems="center">
      <Grid item>
        <TextField
          translationKey="issueCollector.affiliation"
          id="user-affiliation-field"
          value={affiliation}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <Tooltip title="If you are working for/with a particular organizaton or program, please indicate that here.">
          <HelpOutline />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

UserAffiliationField.propTypes = {
  affiliation: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UserAffiliationField;
