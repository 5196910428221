import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class DerivedSensorParserDefinitionService {
  static getMany(sensorId) {
    return DerivedSensorParserDefinitionService.get({ operation: 5, sensorId });
  }

  static getOne(sensorId, pdId) {
    return DerivedSensorParserDefinitionService.get({
      operation: 4,
      sensorId,
      pdId,
    });
  }

  static getVersions(sensorId, pdId) {
    return DerivedSensorParserDefinitionService.get({
      operation: 9,
      sensorId,
      pdId,
    });
  }

  static get(params) {
    return get('DerivedSensorParserDefinitionService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(params) {
    return post('DerivedSensorParserDefinitionService', {
      operation: 1,
      ...params,
    }).then((response) => response);
  }

  static update(params) {
    return post('DerivedSensorParserDefinitionService', {
      operation: 2,
      ...params,
    }).then((response) => response);
  }

  static delete(pdId, sensorId) {
    return post('DerivedSensorParserDefinitionService', {
      operation: 3,
      sensorId,
      pdId,
    }).then((response) => response);
  }
}
export default DerivedSensorParserDefinitionService;
