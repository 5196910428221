/* eslint-disable react-hooks/exhaustive-deps */
import '@fontsource/rajdhani';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import {
  CircularProgress,
  Theme,
  useMediaQuery,
  ButtonBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Add, InfoOutlined, OpenInNew, Remove } from '@onc/icons';
import { Collapse, Link, ReactPlayer, Typography } from 'base-components';
import LiveExpeditionAnnotations from 'domain/AppComponents/sea-tube/live-expedition-embed/LiveExpeditionAnnotations';
import LiveExpeditionDetails from 'domain/AppComponents/sea-tube/live-expedition-embed/LiveExpeditionDetails';
import LiveExpeditionEmbedUtil from 'domain/AppComponents/sea-tube/live-expedition-embed/LiveExpeditionEmbedUtil';
import AnnotationService, {
  AnnotationsV3Parameters,
} from 'domain/services/AnnotationService';
import CruiseService from 'domain/services/CruiseService';
import DiveListingService, {
  DiveJSON,
} from 'domain/services/DiveListingService';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    minHeight: '900px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
  },
  errorContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  panelContent: {
    margin: theme.spacing(1),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  videoPanel: {
    overflow: 'hidden',
    flex: '0 1 content',
    maxWidth: '100%',
    // Breakpoint dependent properties
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 100%',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 content',
      width: '100%',
      order: 2,
    },
    [theme.breakpoints.up('lg')]: {
      order: 1,
      width: '70%',
    },
    [theme.breakpoints.up('xl')]: {
      flex: '1 0 100%',
      order: 1,
    },
  },
  detailsPanel: {
    flex: '0 1 auto',
    minWidth: '350px',
    // Breakpoint dependent properties

    [theme.breakpoints.down('lg')]: {
      flex: '0 1 40%',
      height: (lowerPanelHeight) => `${lowerPanelHeight}px`,
    },
    [theme.breakpoints.down('sm')]: {
      order: 1,
      flex: '0 1 auto',
      width: '100%',
      height: () => 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
      flex: 'none',
      height: (lowerPanelHeight) => `${lowerPanelHeight}px`,
      order: 2,
    },
    [theme.breakpoints.up('xl')]: {
      width: '30%',
      flex: '0 1 auto',
      order: 1,
      height: () => 'auto',
    },
  },
  annotationsPanel: {
    flex: '1 0 60%',
    overflow: 'hidden',
    // Breakpoint dependent properties
    [theme.breakpoints.up('sm')]: {
      flex: '1 0',
      height: (lowerPanelHeight) => `${lowerPanelHeight}px`,
    },
    [theme.breakpoints.down('sm')]: {
      order: 3,
      flex: '1 0',
      height: () => `100%`,
    },

    [theme.breakpoints.up('lg')]: {
      flex: '1 0 30%',
      width: '30%',
      height: () => `100%`,
      order: 3,
    },
    [theme.breakpoints.up('xl')]: {
      flex: '1 0 60%',
      order: 3,
    },
  },
  scrollable: { overflow: 'auto', height: '100%' },

  header: {
    fontFamily: 'Rajdhani',
    fontWeight: 'bold',
    fontSize: '1.375rem',
    color: theme.palette.primary.dark,
  },
  externalLink: {
    marginLeft: 'auto',
    fontFamily: 'Rajdhani',
    fontWeight: 'bold',
    fontSize: '1rem',
  },

  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  expandIcon: {
    color: theme.palette.primary.dark,
  },
  infoIcon: {
    fontSize: '7rem',
  },
}));

interface Props {
  match: {
    params: {
      cruiseId: string;
    };
  };
  maxAnnotations?: number;
  preferredExpeditionName?: string;
  livestreamUrl?: string;
}

const LiveExpeditionEmbed: React.VFC<Props> = ({
  match,
  maxAnnotations = undefined,
  preferredExpeditionName = undefined,
  livestreamUrl = undefined,
}: Props) => {
  const { cruiseId } = match.params;
  const videoPanelRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [lowerPanelHeight, setLowerPanelHeight] = useState<number | undefined>(
    undefined
  );
  const [timeRemaining, setTimeRemaining] = useState<string | undefined>(
    undefined
  );
  const [isPolling, setIsPolling] = useState<boolean>(true);
  const classes = useStyles(lowerPanelHeight);
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);

  const { onError } = useSnackbars();
  const [activeDive, setActiveDive] = React.useState<any>(null);
  const [dives, loadingDives, fetchDives] = useWebService({
    method: DiveListingService.getDives,
    onError,
    parser: (data) => data.dives,
  });
  const [cruise, , fetchCruise] = useWebService({
    method: CruiseService.getCruisePublic,
    onError,
  });
  const [annotations, , fetchAnnotations] = useWebService({
    method: AnnotationService.getAnnotations,
    onError,
    parser: (data) =>
      LiveExpeditionEmbedUtil.convertAnnotations(data.annotations),
  });

  const handleVisibilityChange = () => {
    setIsPolling(!document.hidden);
  };

  // Effect to add and remove the visibilitychange event listener
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Find the current dive from the list of dives
  const findCurrentDive = (diveList: DiveJSON[]) => {
    // Filter the list of dives to only include dives that are currently active
    const currentDives: DiveJSON[] = diveList.filter(
      (dive: DiveJSON) => dive.isDiveLive
    );
    // If there is only one current dive, return it
    if (currentDives.length === 1) {
      return currentDives[0];
    }
    // If there are multiple current dives, find the one with the default device
    // that matches the livestream device
    if (currentDives.length > 1) {
      return (
        currentDives.find(
          (dive: DiveJSON) => dive.defaultDeviceId === cruise.livestreamDeviceId
        ) || currentDives[0]
      );
    }
    // If there are no current dives, return null
    return null;
  };

  // Trigger useEffect every 5 seconds to check for active dive / annotations
  useEffect(() => {
    const fetchData = () => {
      if (isPolling) {
        fetchCruise(Number(cruiseId));
        fetchDives({ cruiseId: Number(cruiseId), seaTubeDivesOnly: true });
      }
    };

    fetchData(); // Execute the function once before setting the interval

    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, [isPolling]);

  // When the dives have been retrieved, get the first dive with currentDive="y" and
  // get all annotations for that dive.
  // If there is no current dive, get all annotations for the cruise
  // In the future, we will need to deal with multiple active dives
  useEffect(() => {
    if (cruise && dives && !loadingDives) {
      const currentDive = findCurrentDive(dives);
      let annotationParameters: AnnotationsV3Parameters = {
        sortAnnotationsByMostRecent: true,
        maxAnnotations,
        includeSensorData: false,
      };
      if (currentDive) {
        setActiveDive(currentDive);
        annotationParameters = {
          diveIds: [currentDive.diveId],
          ...annotationParameters,
        };
      } else {
        setActiveDive(null);
        annotationParameters = {
          cruiseIds: [parseInt(cruiseId, 10)],
          ...annotationParameters,
        };
      }
      fetchAnnotations(annotationParameters);
    }
  }, [dives, loadingDives, cruise]);

  const renderLiveVideo = () => (
    <div style={{ aspectRatio: '16/9' }}>
      <ReactPlayer
        id="live-video-player"
        url={livestreamUrl || cruise.livestreamUrl}
      />
    </div>
  );

  const updateLowerPanelHeight = () => {
    if (videoPanelRef.current && containerRef.current) {
      const containerHeight =
        containerRef.current.getBoundingClientRect().height;
      const videoPanelHeight =
        videoPanelRef.current.getBoundingClientRect().height;
      setLowerPanelHeight(containerHeight - videoPanelHeight - 10);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (cruise) {
        const newRemainingTime = LiveExpeditionEmbedUtil.getTimeRemaining(
          new Date(cruise.startDate)
        );
        setTimeRemaining(newRemainingTime);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [cruise?.startDate]);

  useEffect(() => {
    updateLowerPanelHeight();
  }, [videoPanelRef.current, containerRef.current]);

  useEffect(() => {
    window.addEventListener('resize', updateLowerPanelHeight);
    return () => window.removeEventListener('resize', updateLowerPanelHeight);
  }, []);

  const renderExternalLink = () => {
    let displayText = 'Open Deck Log in SeaTube';
    let link = `${Environment.getDmasUrl()}/app/expedition-logs/${cruise?.cruiseId}`;
    if (activeDive) {
      displayText = 'Open Dive Log in SeaTube';
      link = `${Environment.getDmasUrl()}/app/dive-logs/${activeDive.diveId}`;
    }

    return (
      <Typography variant="body1" className={classes.externalLink}>
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.flexContainer}
          id={activeDive ? 'dive-log-link' : 'deck-log-link'}
        >
          <OpenInNew fontSize="small" style={{ marginRight: '4px' }} />
          {displayText}
        </Link>
      </Typography>
    );
  };

  const renderVideoPanel = () => (
    <div className={classes.videoPanel} ref={videoPanelRef} id="video-panel">
      <div className={classes.panelContent}>
        <div className={classes.flexContainer}>
          <Typography
            id="live-video-header"
            variant="h6"
            className={classes.header}
          >
            Live Video
          </Typography>
          {renderExternalLink()}
        </div>
        {renderLiveVideo()}
      </div>
    </div>
  );

  const renderMiniDetailsPanel = () => (
    <div className={classes.detailsPanel} id="details-panel">
      <div className={classes.panelContent}>
        <ButtonBase
          className={classes.flexContainer}
          onClick={() => setDetailsExpanded(!detailsExpanded)}
          id="expand-details-button"
        >
          <Typography
            id="details-header"
            variant="h6"
            className={classes.header}
          >
            {`${activeDive ? 'Dive' : 'Expedition'} Details`}
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            {detailsExpanded ? (
              <Remove className={classes.expandIcon} />
            ) : (
              <Add className={classes.expandIcon} />
            )}
          </div>
        </ButtonBase>
        <Collapse in={isXs && detailsExpanded}>
          <div className={classes.scrollable}>
            <LiveExpeditionDetails cruise={cruise} dive={activeDive} />
          </div>
        </Collapse>
      </div>
    </div>
  );

  const renderDetailsPanel = () => (
    <div className={classes.detailsPanel} id="details-panel">
      <div className={classes.panelContent}>
        <Typography id="details-header" variant="h6" className={classes.header}>
          {`${activeDive ? 'Dive' : 'Expedition'} Details`}
        </Typography>

        <div className={classes.scrollable}>
          <LiveExpeditionDetails cruise={cruise} dive={activeDive} />
        </div>
      </div>
    </div>
  );

  const renderAnnotationsPanel = () => (
    <div className={classes.annotationsPanel} id="annotations-panel">
      <div className={classes.panelContent}>
        <Typography
          id="annotations-header"
          variant="h6"
          className={classes.header}
        >
          {`${activeDive ? 'Dive' : 'Deck Log'} Annotations`}
        </Typography>
        <div className={classes.scrollable}>
          <LiveExpeditionAnnotations annotations={annotations || []} />
        </div>
      </div>
    </div>
  );

  // Can't use the loading variables here because the spinner would appear every time we polled for new data
  // Undefined represents the initial state of the variables before the first fetch
  if (dives === undefined || cruise === undefined) {
    return <CircularProgress size={60} className={classes.spinner} />;
  }

  if (cruise) {
    const isBefore = moment.utc().isBefore(moment.utc(cruise.startDate));
    const isComplete = moment.utc().isAfter(moment.utc(cruise.endDate));
    const { cruiseName } = cruise;
    const beforeMessage = (
      <>
        <Typography variant="h4" color="textSecondary" align="center">
          {`${preferredExpeditionName || cruiseName} will begin in`}
        </Typography>
        <Typography variant="h4" color="textSecondary" align="center">
          {timeRemaining}
        </Typography>
      </>
    );

    // afterMessage should be #ONCabyss Summer Expedition is complete. then a break, then Thank you for joining us!
    const afterMessage = (
      <>
        <Typography variant="h4" color="textSecondary" align="center">
          {preferredExpeditionName || cruiseName} is complete.
        </Typography>
        <br />
        <Typography variant="h4" color="textSecondary" align="center">
          Thank you for joining us!
        </Typography>
      </>
    );

    if (isBefore || isComplete) {
      return (
        <div className={classes.errorContainer}>
          <InfoOutlined className={classes.infoIcon} color="primary" />
          <br />
          {isBefore ? beforeMessage : afterMessage}
        </div>
      );
    }
  }
  return (
    <div className={classes.container} ref={containerRef}>
      {renderVideoPanel()}
      {isXs ? renderMiniDetailsPanel() : renderDetailsPanel()}
      {renderAnnotationsPanel()}
    </div>
  );
};

export default LiveExpeditionEmbed;
