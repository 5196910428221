import { Component } from 'react';
import PropTypes from 'prop-types';

import { SubdirectoryArrowRight as Arrow } from '@onc/icons';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'base-components';
import DeviceDetailsLink, {
  handleOpenDeviceDetails,
} from 'domain/AppComponents/link/DeviceDetailsLink';

class TestDeviceLink extends Component {
  static propTypes = {
    deviceId: PropTypes.number.isRequired,
    deviceName: PropTypes.string.isRequired,
    piggyBacks: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    piggyBacks: undefined,
  };

  renderPiggyBacks() {
    const { piggyBacks } = this.props;

    return piggyBacks
      .sort((a, b) => a.deviceId - b.deviceId || a.label - b.label)
      .map((piggy) => (
        <ListItem
          button
          key={piggy.id}
          onClick={handleOpenDeviceDetails(piggy.deviceId)}
        >
          <ListItemIcon>
            <Arrow />
          </ListItemIcon>
          <ListItemText
            primary={
              <DeviceDetailsLink deviceId={piggy.deviceId}>
                {piggy.deviceName}
              </DeviceDetailsLink>
            }
            secondary={
              <>
                <b>DeviceId:</b> {piggy.deviceId} | <b>Label:</b> {piggy.label}
              </>
            }
          />
        </ListItem>
      ));
  }

  renderPiggyBackContainer() {
    const { piggyBacks } = this.props;
    if (piggyBacks && piggyBacks.length) {
      return (
        <>
          <Divider />
          {this.renderPiggyBacks()}
        </>
      );
    }
    return null;
  }

  render() {
    const { deviceId, deviceName } = this.props;
    return (
      <List dense>
        <ListItem
          button
          key={deviceId}
          onClick={handleOpenDeviceDetails(deviceId)}
        >
          <ListItemText
            primary={
              <DeviceDetailsLink deviceId={deviceId}>
                {deviceName}
              </DeviceDetailsLink>
            }
          />
        </ListItem>
        {this.renderPiggyBackContainer()}
      </List>
    );
  }
}

export default TestDeviceLink;
