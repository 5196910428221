import { OncLayouts } from 'library/CompositeComponents/dashboard/DashboardTypes';

const locationsLayout: OncLayouts = {
  lg: [
    { w: 4, h: 24, x: 0, y: 0, i: 'fixed-camera-location-tree-widget' },
    { w: 5, h: 24, x: 4, y: 0, i: 'fixed-camera-map-widget' },
    { w: 3, h: 24, x: 9, y: 0, i: 'fixed-camera-details-widget' },
  ],
  md: [
    { w: 4, h: 24, x: 0, y: 0, i: 'fixed-camera-location-tree-widget' },
    { w: 4, h: 24, x: 4, y: 0, i: 'fixed-camera-map-widget' },
    { w: 2, h: 24, x: 8, y: 0, i: 'fixed-camera-details-widget' },
  ],
  sm: [
    { w: 8, h: 12, x: 0, y: 0, i: 'fixed-camera-location-tree-widget' },
    { w: 8, h: 10, x: 0, y: 4, i: 'fixed-camera-map-widget' },
    { w: 8, h: 12, x: 0, y: 8, i: 'fixed-camera-details-widget' },
  ],
  xs: [
    { w: 6, h: 12, x: 0, y: 0, i: 'fixed-camera-location-tree-widget' },
    { w: 6, h: 10, x: 0, y: 4, i: 'fixed-camera-map-widget' },
    { w: 6, h: 12, x: 0, y: 8, i: 'fixed-camera-details-widget' },
  ],
};

export default locationsLayout;
