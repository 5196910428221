import { useState } from 'react';
import { Box } from '@mui/material';
import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { Loading } from '@onc/composite-components';
import { TranslationType, useTranslation } from '@onc/i18n';
import { Divider, ErrorPage, Grid, Typography } from 'base-components';
import {
  MarginedReadOnlyField,
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import {
  handleOpenSensorDetails,
  openSensorDetails,
} from 'domain/AppComponents/link/SensorDetailsLink';
import {
  CancelButton,
  DeleteButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

import Panel from 'library/CompositeComponents/panel/Panel';
import DateFormatUtils from 'util/DateFormatUtils';
import DateUtils from 'util/DateUtils';
import Environment from 'util/Environment';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';

const classes = {
  entryFormContainer: {
    paddingLeft: 3,
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: 2,
  },
  panelDiv: {
    margin: 1,
  },
};

type SensorCalibrationPayload = {
  calibration: {
    calibrationId: number;
    calibrationName: string;
    calibrationTriggerDeviceId: number;
    correctionFormula: string;
    dateFrom: string;
    description: string;
    excludeQAQCs: string;
    modifyByName: string;
    modifyDate: string;
    qaqcDependencies: string;
    sensorId: number;
  };
};

type SensorCalibrationPostProps = {
  calibrationName: string;
  calibrationTriggerDeviceId: number;
  correctionFormula: string;
  description: string;
  excludeQAQCs: string;
  qaqcDependencies: string;
  dateFromDateTime: string;
  calibrationId: number;
  sensorId: number;
  batchId: string;
};

type SensorCalibrationForm = {
  dateFrom: Moment;
  calibrationName: string;
  correctionFormula: string;
  qaqcDependencies: string;
  description: string;
  calibrationTriggerDeviceId: string;
  excludeQaqcIds: string;
};

type SensorCalibrationMaintenanceProps = {
  sensorId?: number;
  calibrationId?: number;
};

const SensorCalibrationMaintenance = ({
  sensorId = undefined,
  calibrationId = undefined,
}: SensorCalibrationMaintenanceProps) => {
  const hasRW = Environment.getDmasUserPrivilege() === 'RW';
  const { onError, onInfo } = useSnackbars();

  const [renderedOnce, setRenderedOnce] = useState<boolean>(false);
  const [sensorIdDecl, setSensorIdDecl] = useState<number>(sensorId);

  const calibrationData = useGet<
    SensorCalibrationPayload,
    { calibrationId: number }
  >(
    'SensorCalibrationService',
    {
      operation: 4,
      options: { enabled: !!calibrationId },
    },
    { calibrationId }
  );

  /**
   * The backend actually sends the 'create' and 'update' operations to the same
   * function, but out of an abundance of caution I'm not combining these post
   * requests
   */
  const { mutate: createCalibration } = usePost<SensorCalibrationPostProps>(
    'SensorCalibrationService',
    {
      onSuccess: () => {
        onInfo('Calibration saved!');
        openSensorDetails(sensorIdDecl);
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    1
  );

  const { mutate: updateCalibration } = usePost<SensorCalibrationPostProps>(
    'SensorCalibrationService',
    {
      onSuccess: () => {
        onInfo('Calibration updated!');
        openSensorDetails(sensorIdDecl);
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    2
  );

  const { mutate: deleteCalibration } = usePost<{
    calibrationId: number;
    sensorId: number;
    batchId: string;
  }>(
    'SensorCalibrationService',
    {
      onSuccess: () => {
        onInfo('Calibration deleted!');
        openSensorDetails(sensorIdDecl);
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    3
  );

  const formMethods = useForm<SensorCalibrationForm>({
    mode: 'onBlur',
  });

  const { t } = useTranslation();

  if (!!calibrationId && calibrationData.isLoading) {
    return <Loading />;
  }

  if (
    (!!calibrationId && calibrationData.error) ||
    (!calibrationId && !sensorId)
  ) {
    return <ErrorPage />;
  }

  /**
   * UseForm needs to be called non-conditionally, but values are undefined
   * while useGet is querying, so we reset and re-render after useGets are done
   */
  if (!renderedOnce) {
    formMethods.reset({
      dateFrom: moment.utc(calibrationData.data?.calibration.dateFrom),
      calibrationName: calibrationData.data?.calibration.calibrationName,
      correctionFormula: calibrationData.data?.calibration.correctionFormula,
      qaqcDependencies: calibrationData.data?.calibration.qaqcDependencies,
      description: calibrationData.data?.calibration.description,
      calibrationTriggerDeviceId:
        calibrationData.data?.calibration.calibrationTriggerDeviceId?.toString(),
      excludeQaqcIds: calibrationData.data?.calibration.excludeQAQCs,
    });
    /**
     * My overly-cautious handling of sensorId may not be necessary, but
     * redundancies are never a bad thing
     */
    setSensorIdDecl(calibrationData.data?.calibration.sensorId || sensorId);
    setRenderedOnce(true);
  }

  const getBatchId = () => {
    const batchDropdown = document.getElementById(
      'batch-drop-down'
    ) as HTMLSelectElement;
    if (batchDropdown) {
      const batchId = batchDropdown.options[batchDropdown.selectedIndex]?.value;
      return batchId === 'undefined' ? undefined : batchId;
    }
    return undefined;
  };

  const renderDateFrom = () => {
    if (hasRW) {
      return (
        <FormDateTimePicker
          translationKey="common.datepickers.startDate"
          fullWidth
          name="dateFrom"
        />
      );
    }

    return (
      <MarginedReadOnlyField
        labelText="Date From"
        valueText={calibrationData.data?.calibration.dateFrom}
      />
    );
  };

  /**
   * An RORWTextField component exists, but uses TextField instead of
   * FormTextField
   */
  const renderRORWFormTextField = (
    name: string,
    translationKey: TranslationType,
    value,
    rules = undefined,
    type = undefined
  ) => {
    if (hasRW) {
      return (
        <FormTextField
          name={name}
          translationKey={translationKey}
          fullWidth
          {...(rules ? { rules } : {})}
          {...(type ? { type } : {})}
        />
      );
    }

    return (
      <MarginedReadOnlyField
        labelText={t(translationKey)}
        valueText={value}
        id={name}
      />
    );
  };

  const renderDescriptionField = () => {
    if (hasRW) {
      return (
        <FormTextField
          translationKey="common.textfields.description"
          name="description"
          title="description"
          rows={4}
          fullWidth
          multiline
        />
      );
    }

    return (
      <MarginedReadOnlyField
        labelText="Description"
        valueText={calibrationData.data?.calibration.description}
      />
    );
  };

  const parametersForPostReq: (
    values: SensorCalibrationForm
  ) => SensorCalibrationPostProps = (values) => ({
    calibrationName: values.calibrationName,
    calibrationTriggerDeviceId:
      Number(values.calibrationTriggerDeviceId) || undefined,
    correctionFormula:
      values.correctionFormula !== '' ? values.correctionFormula : undefined,
    description: values.description !== '' ? values.description : undefined,
    excludeQAQCs:
      values.excludeQaqcIds !== '' ? values.excludeQaqcIds : undefined,
    qaqcDependencies:
      values.qaqcDependencies !== '' ? values.qaqcDependencies : undefined,
    dateFromDateTime: `${DateFormatUtils.formatDate(
      values.dateFrom,
      'time-with-month-name-string'
    )} ${DateFormatUtils.formatDate(values.dateFrom, 'time')}`,
    calibrationId: !isNaN(calibrationId) ? calibrationId : 0,
    sensorId: sensorIdDecl,
    batchId: getBatchId(),
  });

  const handleSubmit = (values) => {
    const params = parametersForPostReq(values);
    if (!calibrationId) {
      createCalibration(params);
    } else {
      updateCalibration(params);
    }
  };

  return (
    <Box sx={classes.panelDiv}>
      <Panel
        title={
          <>
            <Typography variant="body1">Sensor ID:</Typography>
            <Typography variant="body1">{sensorIdDecl}</Typography>
          </>
        }
      >
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <Grid container direction="row" sx={classes.entryFormContainer}>
            <Grid item xs={6}>
              <Typography variant="subtitle2">
                {`Calibration ID: ${calibrationId || 'New'}`}
              </Typography>
              {renderDateFrom()}
              {renderRORWFormTextField(
                'calibrationName',
                'device.calibrationName',
                calibrationData.data?.calibration.calibrationName,
                { required: 'A name is required!' }
              )}
              {renderRORWFormTextField(
                'correctionFormula',
                'device.correctionFormula',
                calibrationData.data?.calibration.correctionFormula
              )}
              {renderRORWFormTextField(
                'qaqcDependencies',
                'device.qaqcDependencies',
                calibrationData.data?.calibration.qaqcDependencies
              )}
              {renderDescriptionField()}
              {renderRORWFormTextField(
                'calibrationTriggerDeviceId',
                'device.calibrationTriggerDeviceId',
                calibrationData.data?.calibration.calibrationTriggerDeviceId,
                undefined,
                'number'
              )}
              {renderRORWFormTextField(
                'excludeQaqcIds',
                'device.excludeQaqcIds',
                calibrationData.data?.calibration.excludeQAQCs
              )}
              <ModifyBy
                username={calibrationData.data?.calibration.modifyByName}
              />
              <ModifyDate
                date={DateUtils.formatDateAsString(
                  new Date(calibrationData.data?.calibration.modifyDate)
                )}
              />
            </Grid>
          </Grid>
          <Box sx={classes.formButtons}>
            {hasRW ? <SaveButton type="submit" /> : <></>}
            <CancelButton
              onClick={handleOpenSensorDetails(sensorIdDecl, 'calibration_tab')}
            />
            {hasRW ? (
              <DeleteButton
                onClick={() => {
                  deleteCalibration({
                    calibrationId,
                    sensorId: sensorIdDecl,
                    batchId: getBatchId(),
                  });
                }}
                disabled={!calibrationId}
              />
            ) : (
              <></>
            )}
          </Box>
        </Form>
        <Divider variant="middle" />
      </Panel>
    </Box>
  );
};

export default SensorCalibrationMaintenance;
