import PropTypes from 'prop-types';

/** Class defining the layout of a Test Tree Node */
export default PropTypes.shape({
  children: PropTypes.arrayOf(PropTypes.shape),
  testTreeNode: PropTypes.shape({
    description: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
    parentTestTreeNodeId: PropTypes.number.isRequired,
    sequenceNo: PropTypes.number.isRequired,
    testTreeNodeId: PropTypes.number.isRequired,
    testTypeId: PropTypes.number.isRequired,
    testTreeNodeName: PropTypes.string.isRequired,
  }),
});
