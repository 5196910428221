import { get } from 'util/WebRequest';

const LOCATION_SERVICE = 'api/locations';
const LOCATION_TREE_SERVICE = 'api/locations/tree';

export type LocationDetails = {
  deployments: number;
  locationName: string;
  depth: number;
  bbox: {
    maxDepth: number;
    maxLat: number;
    maxLon: number;
    minDepth: number;
    minLat: number;
    minLon: number;
  };
  description: string;
  hasDeviceData: string;
  lon: number;
  locationCode: string;
  hasPropertyData: string;
  lat: number;
  dataSearchURL: string;
};

export type LocationDetailsPayload = LocationDetails[];

class LocationsWebServiceWithToken {
  static get(params) {
    return get(LOCATION_SERVICE, params);
  }

  static getTree(params) {
    return get(LOCATION_TREE_SERVICE, params);
  }
}
export default LocationsWebServiceWithToken;
