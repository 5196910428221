import { parseDmasAPIResponse, get } from 'util/WebRequest';

const ErddapManagementService = {
  getDatasetForSiteDevice: (siteDeviceId) =>
    get('ErddapManagementService', {
      operation: 11,
      resourceId: siteDeviceId,
      resourceTypeId: 7,
    }).then((response) => parseDmasAPIResponse(response)),
};

export default ErddapManagementService;
