import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import QaqcAutotestsService from 'domain/services/QaqcAutotestsService';

class QaqcFinderStudyAreaDropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    studyAreas: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getStudyAreas();
  }

  getStudyAreas = () => {
    QaqcAutotestsService.getQAQCStudyAreas()
      .then((results) => {
        const studyAreas = results.map((studyArea) => ({
          label: studyArea.description,
          value: studyArea.studyAreaId,
        }));
        this.setState({
          studyAreas: [{ label: 'Find All', value: 0 }, ...studyAreas],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const { disabled, initialValue, value, onChange, ...rest } = this.props;
    const { studyAreas } = this.state;
    return (
      <Dropdown
        disabled={disabled}
        initialValue={initialValue}
        label="Study Area"
        options={studyAreas}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }
}
export default QaqcFinderStudyAreaDropdown;
