/* eslint-disable max-classes-per-file */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from 'base-components';
import { ResourceTypeSelect } from 'domain/AppComponents/dropdowns/Dropdowns';
import ResourceService from 'domain/services/ResourceService';

class ResourceTypeDropdown extends Component {
  static propTypes = {
    resourceTypeId: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    resourceTypeId: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { resourceTypeList: [] };
  }

  componentDidMount() {
    this.loadResourceTypes();
  }

  loadResourceTypes = async () => {
    await ResourceService.getResourceTypeList()
      .then((results) => {
        const resourceTypeList = results.map((resourceType) => ({
          label: resourceType.resourceTypeName,
          value: resourceType.resourceTypeId,
        }));
        this.setState({
          resourceTypeList,
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const { resourceTypeId, onChange } = this.props;
    const { resourceTypeList } = this.state;

    return (
      <ResourceTypeSelect
        title="resourceTypeId"
        name="resourceTypeId"
        initialValue={resourceTypeId}
        value={resourceTypeId}
        options={resourceTypeList}
        fullWidth
        onChange={onChange}
      />
    );
  }
}

class ResourceSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    resourceTypeId: PropTypes.number,
    resource: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  };

  static defaultProps = {
    resourceTypeId: undefined,
    resource: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { resourceList: [] };
  }

  componentDidMount() {
    const { resourceTypeId } = this.props;
    if (resourceTypeId) {
      this.loadResources(resourceTypeId);
    }
  }

  componentDidUpdate(prevProps) {
    const { resourceTypeId } = this.props;
    if (resourceTypeId && resourceTypeId !== prevProps.resourceTypeId) {
      this.loadResources(resourceTypeId);
    }
  }

  loadResources = async (resourceTypeId) => {
    await ResourceService.getResourcesByResourceTypeId(resourceTypeId)
      .then((results) => {
        this.setState({
          resourceList: results,
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const { resource, onChange } = this.props;
    const { resourceList } = this.state;

    return (
      <Autocomplete
        name="resource"
        data-test="resource-autocomplete"
        getOptionLabel={(option) => (option ? option.name : undefined)}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        translationKey="common.autoCompletes.resource"
        virtualized
        value={resource}
        onChange={onChange}
        options={resourceList}
      />
    );
  }
}
export { ResourceTypeDropdown, ResourceSelect };
