import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Grid } from 'base-components';
import DraggableToolbox from 'domain/AppComponents/annotations/entry/DraggableToolbox';
import { ModifyDate } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import FormField from './FormField';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
});

type FormFieldDialogBoxProps = {
  onClose: () => void;
  label: string;
  formField: FormField;
};

const validate = (
  formField: FormField | undefined,
  fieldName: keyof FormField
) => (formField && formField[fieldName] ? String(formField[fieldName]) : '');

const FormFieldDialogBox = ({
  onClose,
  label,
  formField,
}: FormFieldDialogBoxProps) => (
  <>
    <DraggableToolbox title={label} initiallyExpanded onClose={() => onClose()}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            value={validate(formField, 'formFieldId')}
            label="Form Field ID"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(formField, 'fieldLabel')}
            label="Form Field Lable"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(formField, 'description')}
            label="Description"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(formField, 'displayInfo')}
            label="Display Infomation"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(formField, 'fieldCode')}
            label="Form Field Code"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(formField, 'allowAny')}
            label="Allow Any"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(formField, 'formFieldDocumentation')}
            label="Form Field Documentation"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(formField, 'fieldType')}
            label="Form Field Type"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <ModifyDate
            date={formField.modifyDate ? String(formField.modifyDate) : ''}
          />
        </Grid>
      </Grid>
    </DraggableToolbox>
  </>
);

export default withStyles(styles)(withSnackbars(FormFieldDialogBox));
