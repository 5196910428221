/* eslint-disable react/no-unused-class-component-methods */
/** SimpleMap Component. Basic map with Zoom buttons and layer control. */

import {
  FC,
  ReactElement,
  ReactNode,
  cloneElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ControlPosition,
  latLngBounds,
  LatLngTuple,
  Marker as LeafletMarker,
} from 'leaflet';
import {
  LayersControl,
  ScaleControl,
  FeatureGroup,
  MapContainer,
} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import {
  BingTileLayer as BingLayer,
  LayersControl as CustomLayersControl,
  EditControl,
  FullscreenControl,
  Grid,
  LeafletIcon,
  Search,
  TileLayer,
  WMSTileLayer,
  ZoomControl,
} from 'base-components';
import { SelectedStation } from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchTypes';
import StationProvider from 'domain/Apps/geospatial-search/community-fisher/StationProvider';

import 'leaflet/dist/leaflet.css';

import { BASE_LAYERS, BASE_LAYERS_WITH_LINK } from './MapAPIKeys';
import MapContext from './MapContext';

// Have to override Marker icon because leaflet-react and importing leaflet css collides.
// See https://github.com/PaulLeCam/react-leaflet/issues/453

LeafletMarker.prototype.options.icon = LeafletIcon.DEFAULT;

const { BaseLayer, Overlay } = LayersControl;

interface BaseLayer {
  name: string;
  type: string;
  initialDisplay?: boolean;
  url?: string;
  key?: string;
  layers?: string;
  attribution?: string;
}

interface OverlayLayer {
  name?: string;
  checked?: boolean;
  subLayers?: ReactNode;
}

interface EditConfig {
  onCreated?: () => void;
  editConfig?: { edit?: boolean };
  drawConfig?: {
    rectangle?: boolean;
    polygon?: boolean;
    circle?: boolean;
    marker?: boolean;
    circlemarker?: boolean;
    polyline?: {
      shapeOptions?: {
        color?: string;
        weight?: number;
      };
    };
  };
}

interface Position {
  position?: ControlPosition;
}

export interface SimpleMapProps {
  isCFMap?: boolean;
  mapId: string;
  shouldRenderCastSearch?: boolean;
  width?: string | number;
  height?: string | number;
  center?: number[];
  initialZoom?: number;
  minZoom?: number;
  maxZoom?: number;
  onZoomLevelsChange?: (zoomLevel: any) => void;
  onMapBoundsChange?: (mapBounds: any) => void;
  attributionControl?: boolean;
  fullscreenControl?: boolean | Position;
  layersControl?: boolean | Position;
  zoomControl?: boolean | Position;
  scaleControl?: boolean | Position;
  baseLayers?: BaseLayer[];
  overlayLayers?: OverlayLayer[];
  children?: ReactNode | ReactNode[];
  editControl?: EditConfig;
  defaultLayerName?: string | RegExpMatchArray;
  displayCastLayer?: boolean;
  displayUnassignedCastLayer?: boolean;
  assignedCastData?: SelectedStation[];
  unAssignedCastData?: SelectedStation[];
}

const MAX_BOUNDS = latLngBounds([
  [-89.98155760646617, -180],
  [89.99346179538875, 180],
]);

const SimpleMap: FC<SimpleMapProps> = ({
  mapId,
  isCFMap = false,
  shouldRenderCastSearch = false,
  width = '600px',
  height = '600px',
  center = [0, 0],
  initialZoom = 2,
  minZoom = 1,
  maxZoom = 30,
  onZoomLevelsChange = undefined,
  onMapBoundsChange = undefined,
  editControl = undefined,
  attributionControl = true,
  fullscreenControl = false,
  layersControl = true,
  zoomControl = true,
  scaleControl = true,
  baseLayers = BASE_LAYERS,
  overlayLayers = [],
  children = undefined,
  defaultLayerName = 'GMRT',
  displayCastLayer = false,
  displayUnassignedCastLayer = false,
  assignedCastData = undefined,
  unAssignedCastData = undefined,
}) => {
  const mapContext = useContext(MapContext);

  const [containerId, setContainerId] = useState<any>(undefined);
  const [map, setMap] = useState<any>();
  useEffect(() => {
    setMap(mapContext?.mapRef);
  }, [mapContext]);

  // set the default layer as the initial display layer
  let defaultLayer: any =
    BASE_LAYERS.find((layer) => layer.name === defaultLayerName) ||
    BASE_LAYERS[0];

  if (isCFMap) {
    defaultLayer =
      BASE_LAYERS_WITH_LINK.find((layer) => layer.name === defaultLayerName) ||
      BASE_LAYERS_WITH_LINK[0];
  }

  defaultLayer.initialDisplay = true;

  useEffect(() => {
    // The setTime out takes the enclosed code block off the running thread and places it in the "timer map".
    // The "timer map" will get inspected to run once the running thread has finish and before it starts it's next cycle.
    // This is being done because of the HTML structure that is be returned by the Leaflet Library.
    if (shouldRenderCastSearch) {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        document
          .querySelector('a.leaflet-bar-part.leaflet-bar-part-single')
          ?.setAttribute('title', '');
      }, 1);
    }
  }, [shouldRenderCastSearch]);

  useEffect(() => {
    // Update the tiles so that they look correct on size change
    if (map?.current) {
      map.current.invalidateSize();
    }
  }, [map, width, height]);

  const getMapBounds = useCallback(
    () => map.current.leafletElement.getBounds(),
    [map]
  );

  const getZoom = useCallback(() => map.current.getZoom(), [map]);

  const handleZoomLevelChange = useCallback(() => {
    const zoomLevel = getZoom();
    onZoomLevelsChange(zoomLevel);
  }, [getZoom, onZoomLevelsChange]);

  const onMapBoundChange = useCallback(() => {
    const bounds = getMapBounds();
    onMapBoundsChange(bounds);
  }, [getMapBounds, onMapBoundsChange]);

  const handleFullscreenChange = (isFullscreen) => {
    setContainerId(isFullscreen ? mapId : undefined);
  };

  const renderOverlayLayers = () =>
    overlayLayers.map((layer) => (
      <Overlay key={layer.name} name={layer.name} checked={layer.checked}>
        {layer.subLayers}
      </Overlay>
    ));

  const renderScaleControl = () => {
    if (!scaleControl) return null;
    let position: ControlPosition = 'bottomleft';
    if (typeof scaleControl === 'object') {
      position = scaleControl.position;
    }

    return <ScaleControl position={position} />;
  };

  const renderFullscreenControl = () => {
    if (!fullscreenControl) return null;
    let position: ControlPosition = 'topright';
    if (typeof fullscreenControl === 'object') {
      position = fullscreenControl.position;
    }
    return (
      <FullscreenControl
        idTag="fullscreen"
        options={{ position }}
        size="medium"
        containerId={containerId}
        onFullscreenChange={handleFullscreenChange}
      />
    );
  };

  const renderZoomControl = () => {
    if (!zoomControl) return null;
    let position: ControlPosition = 'topright';
    if (typeof zoomControl === 'object') {
      position = zoomControl.position;
    }
    return (
      <ZoomControl
        idTag="zoom"
        options={{ position }}
        size="medium"
        containerId={containerId}
      />
    );
  };

  const renderLayerControl = () => {
    let layers = baseLayers;

    if (isCFMap) {
      layers = BASE_LAYERS_WITH_LINK;
    }

    // If no control don't render
    if (!layersControl) return null;
    let position: ControlPosition = 'bottomright';
    if (typeof layersControl === 'object') {
      position = layersControl.position;
    }

    return (
      <CustomLayersControl
        idTag="layers"
        position={position}
        size="medium"
        color="primary"
        mapId={mapId}
        containerId={containerId}
      >
        {layers.map((layer) => (
          <BaseLayer
            key={layer.name}
            checked={layer.initialDisplay}
            name={layer.name}
          >
            {layer.type === 'google' && (
              <ReactLeafletGoogleLayer
                apiKey={layer.key}
                attribution={layer.attribution}
              />
            )}
            {layer.type === 'bing' && (
              <BingLayer bingkey={layer.key} attribution={layer.attribution} />
            )}
            {layer.type === 'wms' && (
              <WMSTileLayer
                attribution={layer.attribution}
                url={layer.url}
                layers={layer.layers}
              />
            )}
            {layer.type !== 'google' &&
              layer.type !== 'bing' &&
              layer.type !== 'wms' && (
                <TileLayer
                  key={layer.type}
                  attribution={layer.attribution}
                  url={layer.url}
                />
              )}
          </BaseLayer>
        ))}
        {renderOverlayLayers()}
      </CustomLayersControl>
    );
  };

  const renderChildren = () => {
    if (!children) return undefined;
    if (!Array.isArray(children)) {
      return cloneElement(children as ReactElement, { containerId, mapId });
    }
    return children.map((child) =>
      child && child.props.idTag
        ? cloneElement(child, { containerId, mapId, key: child.props.idTag })
        : child
    );
  };

  const renderFeatureGroup = () => {
    if (!editControl) {
      return null;
    }
    return (
      <FeatureGroup>
        <EditControl
          position="topleft"
          edit={editControl.editConfig}
          onCreated={editControl.onCreated}
          draw={editControl.drawConfig}
        />
      </FeatureGroup>
    );
  };

  const renderCastSearch = () => {
    if (shouldRenderCastSearch) {
      let searchLabel;
      if (displayCastLayer && displayUnassignedCastLayer) {
        searchLabel = 'Enter station or unassigned cast';
      } else if (displayCastLayer) {
        searchLabel = 'Enter station';
      } else if (displayUnassignedCastLayer) {
        searchLabel = 'Enter unassigned cast';
      }

      return (
        <Search
          provider={
            new StationProvider(
              displayCastLayer,
              displayUnassignedCastLayer,
              assignedCastData,
              unAssignedCastData
            )
          }
          searchLabel={searchLabel}
        />
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (map?.current) {
      if (onZoomLevelsChange) {
        map.current.on('zoomend', handleZoomLevelChange);
      }
      if (onMapBoundsChange) {
        map.current.on('zoomend', onMapBoundChange);
        map.current.on('dragend', onMapBoundChange);
      }
    }
  }, [
    map,
    onZoomLevelsChange,
    handleZoomLevelChange,
    onMapBoundsChange,
    onMapBoundChange,
  ]);

  // Wrap map in grid so that map resizes based on browser
  return (
    <Grid container style={{ width, height }}>
      <Grid item style={{ width: '100%', height: '100%' }}>
        <MapContainer
          ref={mapContext.mapRef}
          id={mapContext.mapId}
          attributionControl={attributionControl}
          center={center as LatLngTuple}
          className="onc-map"
          zoom={initialZoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          // prevents default zoom controls
          zoomControl={false}
          maxBoundsViscosity={1}
          maxBounds={MAX_BOUNDS}
          // TODO: use makeStyles
          style={{
            height: '100%',
            width: '100%',
            borderRadius: '4px',
          }}
        >
          {renderZoomControl()}
          {renderFullscreenControl()}
          {renderLayerControl()}
          {renderScaleControl()}
          {renderChildren()}
          {renderFeatureGroup()}
          {renderCastSearch()}
        </MapContainer>
      </Grid>
    </Grid>
  );
};

export default SimpleMap;
