import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Grid } from 'base-components';
import DraggableToolbox from 'domain/AppComponents/annotations/entry/DraggableToolbox';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import RequestSupport from './RequestSupport';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
});

type RequestSupportDialogBoxProps = {
  onClose: () => void;
  label: string;
  requestSupport: RequestSupport;
};

const validate = (
  requestSupport: RequestSupport | undefined,
  fieldName: keyof RequestSupport
) =>
  requestSupport && requestSupport[fieldName]
    ? String(requestSupport[fieldName])
    : '';

const RequestSupportDialogBox = ({
  onClose,
  label,
  requestSupport,
}: RequestSupportDialogBoxProps) => (
  <>
    <DraggableToolbox title={label} initiallyExpanded onClose={() => onClose()}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'supportRequestId')}
            label="Support Request ID"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'supportRequestTypeId')}
            label="Support Request Type ID"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'summary')}
            label="Summary"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'project')}
            label="Project"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'description')}
            label="Description"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'name')}
            label="Name"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'email')}
            label="Email"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'useraffiliation')}
            label="User Affiliation"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'location')}
            label="Location"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'referrer')}
            label="Referrer"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'useragent')}
            label="User Agent"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'screenresolution')}
            label="Screen Resolution"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={validate(requestSupport, 'createDate')}
            label="Create Date"
            variant="filled"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </DraggableToolbox>
  </>
);

export default withStyles(styles)(withSnackbars(RequestSupportDialogBox));
