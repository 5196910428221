import { useState, useEffect } from 'react';
import { getDevicesAsync } from 'domain/hooks/useFilteredDevices';
import DeviceWebService from 'domain/services/DeviceWebService';

const useDataPlayerDevices = (onError, deviceCategoryCodes) => {
  const [devices, setDevices] = useState([]);
  useEffect(
    () => {
      async function getDevices(deviceCategoryCode) {
        const deviceList = [];
        await DeviceWebService.get({ method: 'get', deviceCategoryCode })
          .then((response) => {
            response.data.map((record) =>
              deviceList.push({
                deviceCode: record.deviceCode,
                deviceId: record.deviceId,
                name: record.deviceName,
                deviceCategoryCode: record.deviceCategoryCode,
              })
            );
          })
          .catch(() => {
            onError(
              'User is not logged in or no devices have been found. Please log in and try again.'
            );
          });
        return deviceList;
      }
      getDevicesAsync(setDevices, getDevices, deviceCategoryCodes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deviceCategoryCodes]
  );
  return devices;
};

export default useDataPlayerDevices;
