import { getAbortable } from 'util/WebRequest';

const SERVICE = 'SearchServiceAjax';

class SearchServiceAjax {
  /**
   * The axios get call
   *
   * @param params A device id. In theory the service probably should need
   *   this... but it does.
   * @returns The promise with all of the date pairs for the parameters and a
   *   cancel function
   */
  static getCancellablePromise(params) {
    const cancellableRequest = getAbortable(SERVICE, {
      useParent: true,
      ...params,
    });
    const promise = cancellableRequest.request.then(
      (response) => response.data.dates
    );
    const { cancel } = cancellableRequest;
    return { promise, cancel };
  }

  /**
   * Gets the availability for the specified site devices of a device
   *
   * @param {number} deviceid A device id. In theory the service probably should
   *   need this... but it does.
   * @param {number} siteDeviceId An array of site device ids
   * @returns The promise with all of the date pairs for the parameters
   */
  static getForSiteDeviceCancellablePromise(deviceid, siteDeviceId) {
    return SearchServiceAjax.getCancellablePromise({
      operation: '3',
      deviceid,
      siteDeviceId,
    });
  }

  /**
   * Gets the availability for the specified device
   *
   * @param deviceid A device id.
   * @returns The promise with all of the date pairs for the parameters
   */
  static getForDeviceCancellablePromise(deviceid) {
    return SearchServiceAjax.getCancellablePromise({
      operation: '3',
      deviceid,
    });
  }
}
export default SearchServiceAjax;
