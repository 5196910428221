import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { OpenInNew } from '@onc/icons';
import {
  AnchoredHiddenMenu,
  Checkbox,
  Divider,
  HelpLink,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from 'base-components';

class ManualEntryThreeDot extends PureComponent {
  static propTypes = {
    checkedAttributes: PropTypes.bool.isRequired,
    checkedTime: PropTypes.bool.isRequired,
    checkedQuickEntry: PropTypes.bool.isRequired,
    onAttributesToggle: PropTypes.func.isRequired,
    onCaptureTimeToggle: PropTypes.func.isRequired,
    onQuickEntryToggle: PropTypes.func.isRequired,
    onLinkClick: PropTypes.func.isRequired,
    menu: PropTypes.node,
  };

  static defaultProps = {
    menu: undefined,
  };

  handleButtonSetLink = () => {
    const { onLinkClick } = this.props;
    onLinkClick('TaxonButtonSetConfig');
  };

  handleTaxonomyLink = () => {
    const { onLinkClick } = this.props;
    onLinkClick('Taxonomy');
  };

  render() {
    const {
      checkedAttributes,
      checkedTime,
      checkedQuickEntry,
      onAttributesToggle,
      onCaptureTimeToggle,
      onQuickEntryToggle,
      menu,
    } = this.props;
    return (
      <AnchoredHiddenMenu buttonLabel="Annotation Entry Menu">
        <MenuItem key="quick-entry" onClick={onQuickEntryToggle}>
          <Checkbox checked={checkedQuickEntry} onChange={onQuickEntryToggle} />
          <ListItemText primary="Quick Entry" />
        </MenuItem>
        <MenuItem key="capture-time" onClick={onCaptureTimeToggle}>
          <Checkbox checked={checkedTime} onChange={onCaptureTimeToggle} />
          <ListItemText primary="Capture Time" />
        </MenuItem>
        <MenuItem key="attributes" onClick={onAttributesToggle}>
          <Checkbox checked={checkedAttributes} onChange={onAttributesToggle} />
          <ListItemText primary="Attributes" />
        </MenuItem>
        <Divider />
        <MenuItem
          key="button-set-management"
          onClick={this.handleButtonSetLink}
        >
          <ListItemText primary="Button Set Management" />
          <ListItemIcon>
            <OpenInNew fontSize="small" style={{ marginLeft: '4px' }} />
          </ListItemIcon>
        </MenuItem>
        <MenuItem key="taxonomy-management" onClick={this.handleTaxonomyLink}>
          <ListItemText primary="Taxonomy Management" />
          <ListItemIcon>
            <OpenInNew fontSize="small" style={{ marginLeft: '4px' }} />
          </ListItemIcon>
        </MenuItem>
        <Divider />
        <HelpLink url="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help#SeaTubeV3Help-MakingAnnotations" />
        <Divider />
        {menu}
      </AnchoredHiddenMenu>
    );
  }
}

export default ManualEntryThreeDot;
