import PropTypes from 'prop-types';

import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import Environment from 'util/Environment';

const getUrl = (siteId) => `${Environment.getDmasUrl()}/Sites?siteId=${siteId}`;

const openSites = (siteId) => {
  global.open(getUrl(siteId), '_self');
};

const handleOpenSites = (siteId) => () => {
  openSites(siteId);
};

const SitesLink = ({ siteId, children = undefined }) => (
  <OpenInNewLink href={getUrl(siteId)}>{children || siteId}</OpenInNewLink>
);

SitesLink.propTypes = {
  siteId: PropTypes.number.isRequired,
  children: PropTypes.node,
};

export { handleOpenSites, openSites };
export default SitesLink;
