import moment from 'moment';
import { Autocomplete } from 'base-components';
import useFilteredDeployments, {
  type DeploymentResponse,
} from 'domain/hooks/useFilteredDeployments';

type DeploymentsAutocompleteProps = {
  onChange: (event: any, newDeployment: DeploymentResponse) => void;
  value?: DeploymentResponse;
  deviceId?: number;
};

export const getDeploymentLabel = (option: DeploymentResponse): string =>
  `${option.locationName} (${moment.utc(option.dateFrom).format('DD-MMM-YYYY')} to ${option.dateTo ? moment.utc(option.dateTo).format('DD-MMM-YYYY') : 'current'})`;

const DeploymentsAutocomplete = ({
  onChange,
  value = undefined,
  deviceId = undefined,
}: DeploymentsAutocompleteProps) => {
  const deployments = useFilteredDeployments(deviceId, !deviceId);

  return (
    <Autocomplete
      options={deployments.filteredDeployments}
      onChange={onChange}
      value={value}
      noOptionsText="No Deployments Available"
      getOptionLabel={(option: DeploymentResponse) =>
        getDeploymentLabel(option)
      }
      isOptionEqualToValue={(a, b) => a.deploymentId === b.deploymentId}
      translationKey="dashboards.deployment"
      name="deploymentAutocomplete"
      fullWidth
      disabled={!deviceId}
    />
  );
};

export default DeploymentsAutocomplete;
