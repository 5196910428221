import { createContext } from 'react';
import { CruiseJSON } from 'domain/services/CruiseService';
import { DiveJSON } from 'domain/services/DiveListingService';

export interface IngestionContextInterface {
  getCruises: (date: string) => Promise<CruiseJSON[]>;
  getDives: (date: string, cruiseId: number) => Promise<DiveJSON[]>;
}

const ChatLogIngestionContext = createContext<IngestionContextInterface>({
  getCruises: () =>
    new Promise((resolve) => {
      resolve([]);
    }),
  getDives: () =>
    new Promise((resolve) => {
      resolve([]);
    }),
});

export default ChatLogIngestionContext;
