export type NestedItemType = {
  value: any;
  label: string;
  IconComponent?: any;
  key: string;
  disabled?: boolean;
  children?: NestedItemType[];
  selected?: boolean;
};

export default class NestedItem {
  value: any;

  key: string;

  label: string;

  path: string;

  children: NestedItem[] | undefined;

  constructor(item: NestedItemType, path = 'Uncategorized') {
    this.value = item.value;
    this.label = item.label;
    this.key = item.key || item.value;
    this.children = [];
    this.path = path;
    if (item.children) {
      const newPath =
        path !== 'Uncategorized' ? `${path} / ${item.label}` : item.label;
      this.children = item.children.map(
        (child) => new NestedItem(child, newPath)
      );
    }
  }

  /**
   * Does the text filtering for a flat list Nested categories are concatenated
   * with a "/" on the flat list Root level leaves are given the group
   * "Uncategorized"
   */
  getFilteredLeafs = (input: string, filterMap: Map<string, any>) => {
    const newPath = this.path || 'Uncategorized';
    if (this.children && this.children.length) {
      this.children.forEach((child) => {
        child.getFilteredLeafs(input, filterMap);
      });
    } else if (this.label.toLowerCase().includes(input.toLowerCase())) {
      if (filterMap.has(newPath)) {
        const updatedList = [...filterMap.get(newPath)];
        updatedList.push(this);
        filterMap.set(newPath, updatedList);
      } else {
        filterMap.set(newPath, [this]);
      }
    }
  };
}
