import { useEffect, useState } from 'react';
import * as React from 'react';
import moment from 'moment';
import qs from 'qs';
import { Switch, Route } from 'react-router-dom';
import { DenseThemeProvider } from '@onc/theme';
import { Grid } from 'base-components';
import AnnotationSearchFilters from 'domain/AppComponents/annotations/AnnotationSearchFilters';
import AnnotationTable from 'domain/AppComponents/annotations/AnnotationTable';
import AnnotationEntry from 'domain/AppComponents/annotations/entry/AnnotationEntry';
import { FilterValue } from 'library/CompositeComponents/filter/Filter';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ObjectUtils from 'util/ObjectUtils';

type Props = {
  match: any;
  onInfo: any;
};

const defaultFilter = {
  dateFilter: { fromDate: null, toDate: null },
  sourceFilter: { '3': true, '5': true },
  resourceFilter: {
    resourceTypeId: undefined,
    resource: undefined,
    includeTopology: true,
  },
};

const AnnotationSearchPage: React.VFC<Props> = (props: Props) => {
  const { match, onInfo } = props;
  const [selectedAnnotation, setSelectedAnnotation] = useState<
    number | undefined
  >(undefined);

  const parseFilter = (filterString) => {
    const location: FilterValue = qs.parse(filterString, {
      ignoreQueryPrefix: true,
      allowDots: true,
      strictNullHandling: true,
      decoder: (str, decoder) => {
        const decodedStr = decoder(str);
        // NaN isn't a robust way to test for numbers, but adequate for this
        // component
        if (
          !isNaN(+decodedStr) ||
          decodedStr === 'true' ||
          decodedStr === 'false'
        ) {
          // JSON.parse parses booleans and numbers - it's good enough (if
          // expensive) for my purposes
          return JSON.parse(decodedStr);
        }
        if (
          decodedStr.match(
            '^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{3}Z$'
          )
        ) {
          return moment.utc(decodedStr);
        }
        return decodedStr;
      },
    });

    if (Object.keys(location).length === 0) {
      return defaultFilter;
    }

    // see "source filter" note in stringifyFilter
    let { sourceFilter } = location;
    sourceFilter = Array.isArray(sourceFilter) ? sourceFilter : [sourceFilter];
    location.sourceFilter = sourceFilter.reduce(
      (acc, value) => ({ ...acc, [value]: true }),
      {}
    );

    // with dot notation (see note in stringifyFilter) qs can't distinguish an
    // array of objects from an object of arrays
    const { fieldFilter } = location;
    if (fieldFilter && Array.isArray(fieldFilter.fieldType)) {
      const fields = fieldFilter.field.label.map((label, index) => ({
        field: {
          label,
          value: fieldFilter.field.value[index],
        },
        fieldGroup: {
          label: fieldFilter.fieldGroup.label[index],
          value: fieldFilter.fieldGroup.value[index],
        },
        fieldType: fieldFilter.fieldType[index],
        fieldValue: fieldFilter.fieldValue[index],
      }));
      location.fieldFilter = fields;
    } else {
      location.fieldFilter = [fieldFilter];
    }

    return location;
  };

  const [filter, setFilter] = useState<FilterValue>(
    parseFilter(window.location.search)
  );
  const [entryOpen, setEntryOpen] = useState(!!selectedAnnotation);
  const [refreshTable, setRefreshTable] = useState(false);

  useEffect(() => {
    setEntryOpen(!!selectedAnnotation);
  }, [selectedAnnotation]);

  useEffect(() => {
    if (filter) {
      setRefreshTable(true);
    }
  }, [filter]);

  const getBaseUrl = (route: any) => {
    if (route.match.url.indexOf('/id') >= 0) {
      return route.match.url.substr(0, route.match.url.indexOf('/id'));
    }
    return route.match.url;
  };

  const stringifyFilter = (value: any) => {
    if (!value) {
      return '';
    }

    const temp = ObjectUtils.deepClone(value);

    // convert the source filter from { 1: true, 3: true } to [ 1, 3 ]:
    // qs.parse can't distinguish arrays from objects with numeric keys and it
    // gets confused by the original format
    temp.sourceFilter = Object.keys(value.sourceFilter).filter(
      (key) => value.sourceFilter[key]
    );

    return qs.stringify(temp, {
      addQueryPrefix: true,
      indices: false,
      // I'm using dot notation instead of brackets because Firefox
      // "helpfully" decodes URL-encoded characters in the address bar, and
      // unencoded brackets are forbidden by the version of Tomcat we're using
      // in QA/prod in Java 17. Don't change this without testing in QA2.
      allowDots: true,
      strictNullHandling: true,
    });
  };

  const handleCopyLinkToClipboard = (filterObj: any) => {
    let baseUrl = window.location.href;
    // Remove existing filter in the url if there is one
    if (baseUrl.includes('?')) {
      baseUrl = baseUrl.substring(0, baseUrl.indexOf('?'));
    }
    navigator.clipboard.writeText(`${baseUrl}${stringifyFilter(filterObj)}`);
    onInfo('Copied to Clipboard');
  };

  const handleCloseEntry = (
    triggerRefresh: any,
    baseUrl: string,
    filterString: any,
    route: any
  ) => {
    setEntryOpen(false);
    setRefreshTable(triggerRefresh);
    setSelectedAnnotation(0);
    route.history.push(`${baseUrl}${stringifyFilter(filterString)}`);
  };

  const handleAnnotationIdClick = (id: number, route: any, baseUrl: string) => {
    if (selectedAnnotation !== id) {
      setSelectedAnnotation(id);
    }
    route.history.push(`${baseUrl}/id/${id}${stringifyFilter(filter)}`);
  };

  const handleSubmit = (e: any, route: any, baseUrl: string) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
    route.history.push(`${baseUrl}${stringifyFilter(newFilter)}`);
  };

  const renderPage = (route: any) => {
    const baseUrl = getBaseUrl(route);

    if (route.match.params.id > 0 && selectedAnnotation !== 0) {
      setSelectedAnnotation(route.match.params.id);
    }

    return (
      <Grid>
        <AnnotationEntry
          selectedAnnotation={selectedAnnotation || undefined}
          open={entryOpen}
          onClose={(triggerRefresh: any) =>
            handleCloseEntry(triggerRefresh, baseUrl, filter, route)
          }
          initialValues={{
            resourceTypeId: filter.resourceFilter.resourceTypeId,
            resource: filter.resourceFilter.resource,
          }}
        />
        <div />
        <AnnotationSearchFilters
          name="annotationSearchFilters"
          onSubmit={(e: any) => handleSubmit(e, route, baseUrl)}
          filter={filter}
          onCopyLink={handleCopyLinkToClipboard}
        />
        <AnnotationTable
          name="annotationTable"
          filter={filter}
          setFilter={setFilter}
          entryOpen={entryOpen}
          onAddAnnotation={() => setEntryOpen(true)}
          onAnnotationIdClick={(id: number) =>
            handleAnnotationIdClick(id, route, baseUrl)
          }
          baseUrl={baseUrl}
          refreshTable={refreshTable}
          setRefreshTable={(val: any) => setRefreshTable(val)}
          pageSizes={[30, 50, 100]}
        />
      </Grid>
    );
  };

  return (
    <DenseThemeProvider>
      <Switch>
        <Route
          path={`${match.path}/(id)?/:id?`}
          render={(routeProps) => renderPage(routeProps)}
        />
      </Switch>
    </DenseThemeProvider>
  );
};

export default withSnackbars(AnnotationSearchPage);
