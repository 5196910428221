import React from 'react';
import { Checkbox as MaterialCheckbox, CheckboxProps } from '@mui/material';

/**
 * Wrapper for MUI checkbox. See https://material-ui.com/api/checkbox/ for more
 * information.
 */
const Checkbox: React.FC<CheckboxProps> = ({
  color = 'secondary',
  ...props
}: CheckboxProps) => <MaterialCheckbox color={color} {...props} />;

export default Checkbox;
