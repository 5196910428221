import Search from 'util/Search';
import ContinuousVideoFiles from './ContinuousVideoFiles';
import SeaTubeSubPlaylist from './SeaTubeSubPlaylist';

const PLAYLIST_LENGTH = 1;

/**
 * A representation of the video files returned by /seatube/videos. Before I get
 * into its structure, I need to make clear that there are two different types
 * of "times" in this file, "clock time" and "video time". Clock time is the
 * time the video was recorded, and can be used to construct a date (eg
 * 2021-03-26 14:49:00). Video time is the time elapsed in the video: if the
 * video is 2h long, then the video time counts from 0 to 7200. Elapsed clock
 * time _may or may not_ be equal to elapsed video time. When this class uses
 * the term `elapsedTime` or `secondsSinceStart`, it _always_ refers to video
 * time (unless I messed up...).
 *
 * The input is a JSON string that looks like this
 *
 * { availableResolutions: [{ code: 'S', description: 'Low' }], mediaFiles: [ {
 * count: 175, dataFileDurationSeconds: 52501, dataFiles: [ ['0', '300',
 * '/NA_Archive_02/03/98/50/', '0'], ['300', '300', '/NA_Archive_02/03/98/51/',
 * '300'], ['600', '300', '/NA_Archive_02/03/98/52/', '600'], ['900', '300',
 * '/NA_Archive_02/03/98/54/', '900'], ... ], dateStartSeconds: 1573038001,
 * defaultFileNamePostFix: '-LOW.mp4', deviceCode:
 * 'INSITEZEUSPLUS_DEEPDISCOVERER', deviceId: 23621, }, ], resolution: 'S',
 * totalDurationSeconds: 52501, statusCode: 0, }
 *
 * And here's what everything means:
 *
 * `mediaFiles[i]`: a contiguous collection of video clips with a consistent
 * deviceId and resolution. A new mediaFile denotes a new deployment at this
 * searchTreeNode, or a change in the camera's resolution within a deployment
 * `dateStartSeconds`: the start time of the media file's first clip, in seconds
 * since epoch `count`: the length of dataFiles `dataFiles[j]`: a video clip,
 * which is a single file in AD `dataFiles[j][0]`: the number of seconds of
 * clock time elapsed since the start of the media file. If there are data gaps
 * within the video, then this value will be larger than `dataFiles[j][3]`. The
 * clip's start time in seconds since epoch is `mediaFiles[i].dateStartSeconds +
 * mediaFiles[i].dataFiles[j][0]` `dataFiles[j][1]`: the duration of this clip
 * `dataFiles[j][2]`: the path to this clip in AD. To get the full filename
 * you'll need to concatenate it together from this value, the clip's calculated
 * start (and end?) time, `deviceCode`, and `defaultFileNamePostFix`
 * `dataFiles[j][3]`: the number of seconds of video elapsed from the start of
 * `mediaFiles[0].dataFiles[0]` to this dataFile `dataFiles[j][4]`: an optional
 * time in milliseconds since... something. I'll figure out exactly what in
 * DMAS-57790 `dataFileDurationSeconds`: the duration of the media file, equal
 * to `dataFiles[-1][1] + dataFiles[-1][3]` `totalDurationSeconds: the total
 * duration of video, equal to `mediaFiles[-1].dataFiles[-1][1] +
 * mediaFiles[-1].dataFiles[-1][3]`
 */
class SeaTubeMediaFiles extends ContinuousVideoFiles {
  static from(json) {
    if (json.mediaFiles.length === 0) {
      return undefined;
    }

    const files = new SeaTubeMediaFiles(
      json.availableResolutions,
      json.resolution
    );
    files.duration = json.totalDurationSeconds;
    files.mediaFiles = json.mediaFiles;

    return files;
  }

  constructor(qualityOptions, currentQuality) {
    super(qualityOptions, currentQuality);
    this.mediaFileIndex = 0;
    this.dataFileIndex = 0;
    this.isReset = false;
  }

  /* *****************
   * Cursor movement *
   ***************** */

  /** Set the cursor to the clip at the given number of seconds of video elapsed */
  selectClipAtTime = (secondsSinceStart) => {
    if (secondsSinceStart === undefined) return undefined;

    const mediaIndex = this.getMediaFileIndexAtTime(secondsSinceStart);
    if (mediaIndex < 0) return undefined;

    this.mediaFileIndex = mediaIndex;
    this.dataFileIndex = this.getDataFileIndexAtTime(
      this.mediaFileIndex,
      secondsSinceStart
    );

    return this.getClipAt(this.mediaFileIndex, this.dataFileIndex);
  };

  /**
   * Advance the cursor to the next clip.
   *
   * @see {@link selectClipAtTime}
   */
  selectNextClip = () => this.selectClipAtTime(this.getTimeElapsedToNextClip());

  /* ****************
   * Get real dates *
   **************** */

  /**
   * Return the clock time partway through the video. If the elapsed time is
   * after the end of the video, return the time at the end of the last file
   *
   * @returns {Date}
   */
  getDateAt = (secondsSinceStart) => {
    const mediaIndex = this.getMediaFileIndexAtTime(secondsSinceStart);

    let mediaFile;
    let secondsSinceStartOfMediaFile;
    if (mediaIndex >= 0) {
      mediaFile = this.mediaFiles[mediaIndex];

      const dataIndex = this.getDataFileIndexAtTime(
        mediaIndex,
        secondsSinceStart
      );

      const clip = this.getClipAt(mediaIndex, dataIndex);
      secondsSinceStartOfMediaFile =
        Number(clip[0]) + secondsSinceStart - Number(clip[3]);
    } else if (secondsSinceStart < 0) {
      // before the beginning
      [mediaFile] = this.mediaFiles;
      secondsSinceStartOfMediaFile = 0;
    } else {
      // past the end
      mediaFile = this.mediaFiles[this.mediaFiles.length - 1];
      secondsSinceStartOfMediaFile = mediaFile.dataFileDurationSeconds;
    }

    return new Date(
      (mediaFile.dateStartSeconds + secondsSinceStartOfMediaFile) * 1000
    );
  };

  /* ********************************************
   * Get elapsed time (seconds of video played) *
   ******************************************** */

  getDuration = () => this.duration;

  /** Return the device of the media file at the given time */
  getDeviceIdAt = (secondsSinceStart) => {
    const index = this.getMediaFileIndexAtTime(secondsSinceStart);
    if (index < 0) return undefined;

    return this.mediaFiles[index].deviceId;
  };

  /**
   * Return the number of seconds of video elapsed to the given date
   *
   * @param {Date | number} date - The date to search for, as a Date or seconds
   *   since epoch
   */
  getTimeElapsedAtDate = (date) => {
    if (!date) return undefined;

    const epochSeconds = date instanceof Date ? date.getTime() / 1000 : date;

    const mediaIndex = this.getMediaFileIndexAtDate(epochSeconds);
    if (mediaIndex < 0) return undefined;

    const dataIndex = this.getDataFileIndexAtDate(mediaIndex, epochSeconds);
    if (dataIndex < 0) return undefined;

    const dataFile = this.getClipAt(mediaIndex, dataIndex);

    return (
      epochSeconds -
      this.mediaFiles[mediaIndex].dateStartSeconds -
      // calculations up to this point have used clock time: replace that with
      // the video time
      Number(dataFile[0]) +
      Number(dataFile[3])
    );
  };

  // Internal method
  getTimeElapsedToMediaFile = (mediaFileIndex) =>
    this.mediaFiles[mediaFileIndex].dateStartSeconds +
    Number(this.mediaFiles[mediaFileIndex].dataFiles[0][0]);

  /**
   * Return the next clip-start after the given date
   *
   * @param {Date | number} date - The date to search for, as a Date or seconds
   *   since epoch
   */
  getStartDateOfClipAfter = (date) => {
    if (!date) return undefined;

    const epochSeconds = date instanceof Date ? date.getTime() / 1000 : date;

    let mediaIndex = this.getMediaFileIndexAtDate(epochSeconds);
    if (mediaIndex < 0) mediaIndex = -mediaIndex - 1;
    if (mediaIndex >= this.mediaFiles.length) return undefined;

    let dataIndex = this.getDataFileIndexAtDate(mediaIndex, epochSeconds);
    if (dataIndex < 0) dataIndex = -dataIndex - 2;

    const nextIndices = this.getIndexOfClipAfter(mediaIndex, dataIndex);
    if (!nextIndices) return undefined;

    return this.getDateAt(this.getTimeElapsedToClipAt(...nextIndices));
  };

  /**
   * Return the latest clip-start before the given date
   *
   * @param {Date | number} date - The date to search for, as a Date or seconds
   *   since epoch
   */
  getEndDateOfClipBefore = (date) => {
    if (!date) return undefined;

    const epochSeconds = date instanceof Date ? date.getTime() / 1000 : date;

    let mediaIndex = this.getMediaFileIndexAtDate(epochSeconds);
    if (mediaIndex < 0) mediaIndex = -mediaIndex - 1;
    if (mediaIndex >= this.mediaFiles.length) mediaIndex -= 1;

    let dataIndex = this.getDataFileIndexAtDate(mediaIndex, epochSeconds);
    if (dataIndex < 0) dataIndex = -dataIndex - 1;

    const prevIndices = this.getIndexOfClipBefore(mediaIndex, dataIndex);
    if (!prevIndices) return undefined;

    const endDate = this.getDateAt(
      this.getTimeElapsedToClipAt(...prevIndices) +
        Number(this.getClipAt(...prevIndices)[1] - 1)
    );

    // Subtract a second, then add 1000 milliseconds, because this.getDateAt
    // will return the following clip at a clip boundary
    return new Date(endDate.getTime() + 1000);
  };

  /**
   * Return the start of the current clip, in seconds since the start of the
   * first clip
   */
  getTimeElapsedToCurrentClip = () =>
    this.getTimeElapsedToClipAt(this.mediaFileIndex, this.dataFileIndex);

  /**
   * Return the elapsed time to the next media clip, or reset the player and
   * return 0 if this is the last clip.
   */
  getTimeElapsedToNextClip = () => {
    const indices = this.getIndexOfClipAfter(
      this.mediaFileIndex,
      this.dataFileIndex
    );

    if (!indices) {
      this.isReset = true;
      return 0;
    }

    return this.getTimeElapsedToClipAt(...indices);
  };

  /** Return the previous media clip, or undefined if this is the beginning */
  getTimeElapsedToPreviousClip = () => {
    const indices = this.getIndexOfClipBefore(
      this.mediaFileIndex,
      this.dataFileIndex
    );
    if (!indices) return 0;

    return this.getTimeElapsedToClipAt(...indices);
  };

  /** Return the information map for the video player info menu */
  getInfoMap = (time) => {
    const mediaFile =
      this.mediaFiles[
        this.getMediaFileIndexAtTime(this.getTimeElapsedAtDate(time))
      ];
    const datafile = mediaFile.dataFiles[this.dataFileIndex];
    const { deviceCode, dateStartSeconds, defaultFileNamePostFix } = mediaFile;

    const millis = Number(datafile[4]) || 0;
    const dateFrom = new Date(
      (dateStartSeconds + Number(datafile[0])) * 1000 + millis
    )
      .toISOString()
      .replace(/[-:]/gi, '');

    const infoMap = new Map();

    const path = datafile[2];
    const deviceId = this.getDeviceIdAt(this.getTimeElapsedAtDate(time));
    const fileName = `${deviceCode}_${dateFrom}${defaultFileNamePostFix}`;

    infoMap.set('Path', path);
    infoMap.set('Device Id', deviceId);
    infoMap.set('File Name', fileName);

    return infoMap;
  };

  /** Return the elapsed time to the first video clip, which is "0". */
  getTimeElapsedToFirstClip = () => this.getTimeElapsedToClipAt(0, 0);

  /** Return the last video clip */
  getTimeElapsedToFinalClip = () => {
    const lastMediaFileIndex = this.mediaFiles.length - 1;
    const lastDataFileIndex = this.mediaFiles[lastMediaFileIndex].count - 1;

    return this.getTimeElapsedToClipAt(lastMediaFileIndex, lastDataFileIndex);
  };

  // Internal method
  getMediaFileIndexAtTime = (secondsSinceStart) =>
    Search.binarySearch(
      this.mediaFiles,
      secondsSinceStart,

      (mediaFile, value) => {
        const startTime = Number(mediaFile.dataFiles[0][3]);
        const endTime = startTime + mediaFile.dataFileDurationSeconds;

        if (value < startTime) return 1;
        if (value >= endTime) return -1;
        return 0;
      }
    );

  // Internal method
  getDataFileIndexAtTime = (mediaFileIndex, secondsSinceStart) =>
    Search.binarySearch(
      this.mediaFiles[mediaFileIndex].dataFiles,
      secondsSinceStart,

      (dataFile, value) => {
        const startTime = Number(dataFile[3]);
        const endTime = startTime + Number(dataFile[1]);

        if (value < startTime) return 1;
        if (value >= endTime) return -1;
        return 0;
      }
    );

  // Internal method
  getMediaFileIndexAtDate = (epochSeconds) =>
    Search.binarySearch(this.mediaFiles, epochSeconds, (mediaFile, value) => {
      // Video may start some time after dateStartSeconds if resolution
      // changes within a deployment
      const startTime =
        mediaFile.dateStartSeconds + Number(mediaFile.dataFiles[0][0]);

      // Clock seconds elapsed within a mediaFile may be greater than
      // dateStartSeconds if there were gaps
      const finalDataFile = mediaFile.dataFiles[mediaFile.dataFiles.length - 1];
      const endTime =
        startTime + Number(finalDataFile[0]) + Number(finalDataFile[1]);

      if (value < startTime) return 1;
      if (value >= endTime) return -1;
      return 0;
    });

  // internal method
  getDataFileIndexAtDate = (mediaFileIndex, epochSeconds) =>
    Search.binarySearch(
      this.mediaFiles[mediaFileIndex].dataFiles,
      epochSeconds - this.getTimeElapsedToMediaFile(mediaFileIndex),
      (dataFile, value) => {
        const startClockTime = Number(dataFile[0]);
        const endClockTime = startClockTime + Number(dataFile[1]);

        if (value < startClockTime) return 1;
        if (value >= endClockTime) return -1;
        return 0;
      }
    );

  // Internal method
  getClipAt = (mediaFileIndex, dataFileIndex) => {
    if (
      this.mediaFiles[mediaFileIndex] &&
      this.mediaFiles[mediaFileIndex].dataFiles &&
      this.mediaFiles[mediaFileIndex].dataFiles[dataFileIndex]
    ) {
      return this.mediaFiles[mediaFileIndex].dataFiles[dataFileIndex];
    }
    return undefined;
  };

  // Internal method
  getTimeElapsedToClipAt = (mediaFileIndex, dataFileIndex) =>
    Number(this.getClipAt(mediaFileIndex, dataFileIndex)[3]);

  // Internal Method
  getIndexOfClipAfter = (mediaFileIndex, dataFileIndex) => {
    if (this.getClipAt(mediaFileIndex, dataFileIndex + 1)) {
      return [mediaFileIndex, dataFileIndex + 1];
    }
    if (this.getClipAt(mediaFileIndex + 1, 0)) {
      return [mediaFileIndex + 1, 0];
    }
    return undefined;
  };

  // Internal method
  getIndexOfClipBefore = (mediaFileIndex, dataFileIndex) => {
    if (this.getClipAt(mediaFileIndex, dataFileIndex - 1)) {
      return [mediaFileIndex, dataFileIndex - 1];
    }
    if (
      this.mediaFiles[mediaFileIndex - 1] &&
      this.mediaFiles[mediaFileIndex - 1].dataFiles &&
      this.mediaFiles[mediaFileIndex - 1].dataFiles[
        this.mediaFiles[mediaFileIndex - 1].count - 1
      ]
    ) {
      return [
        mediaFileIndex - 1,
        this.mediaFiles[mediaFileIndex - 1].count - 1,
      ];
    }
    return undefined;
  };

  /* ********************
   * JWPlayer playlists *
   ******************** */

  /**
   * Build a new JWPlayer playlist array, starting from the current file
   * inclusive
   */
  buildPlaylist = (length = PLAYLIST_LENGTH) => {
    let mediaIndex = this.mediaFileIndex;
    let dataIndex = this.dataFileIndex;

    const playlist = new SeaTubeSubPlaylist();
    playlist.start = this.getTimeElapsedToCurrentClip();

    playlist.isReset = this.isReset;
    this.isReset = false;

    for (let i = 0; i < length; i += 1) {
      const mediaFile = this.mediaFiles[mediaIndex];
      const dataFile = mediaFile.dataFiles[dataIndex];

      playlist.addFromMediaFile(mediaFile, dataFile);

      if (this.getClipAt(mediaIndex, dataIndex + 1)) {
        dataIndex += 1;
      } else if (this.getClipAt(mediaIndex + 1, 0)) {
        mediaIndex += 1;
        dataIndex = 0;
      } else {
        break;
      }
    }

    return playlist;
  };
}

export default SeaTubeMediaFiles;
