import { parseDmasAPIResponse, get } from 'util/WebRequest';

class FerryPVCSCommandsService {
  static enableSampling(deviceid) {
    return get('FerryPVCSCommandsService', {
      command: 'enablesampling',
      userId: 0,
      deviceId: deviceid,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static turnOff(deviceid) {
    return get('FerryPVCSCommandsService', {
      command: 'turnoff',
      userId: 0,
      deviceId: deviceid,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static enableFlush(deviceid) {
    return get('FerryPVCSCommandsService', {
      command: 'enableflush',
      userId: 0,
      deviceId: deviceid,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default FerryPVCSCommandsService;
