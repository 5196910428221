import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withCircularProgress } from 'base-components';
import ResizingInfoList from 'library/CompositeComponents/list-container/ResizingInfoList';
import InfoItemScrollableMultiParagraph from 'library/CompositeComponents/list-items/InfoItemScrollableMultiParagraph';
import InfoItemWithIcon from 'library/CompositeComponents/list-items/InfoItemWithIcon';
import InfoItemWithLink from 'library/CompositeComponents/list-items/InfoItemWithLink';
import OrganizationLogo from 'library/CompositeComponents/logo/OrganizationLogo';

class OrganizationDetailsTemplate extends PureComponent {
  static propTypes = {
    organizationDetails: PropTypes.shape({
      organizationId: PropTypes.number.isRequired,
      organizationName: PropTypes.string,
      organizationDescription: PropTypes.string,
      organizationLink: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { organizationDetails } = this.props;
    return (
      <ResizingInfoList
        headerContent={
          <InfoItemWithIcon>
            <OrganizationLogo id={organizationDetails.organizationId} />
            <InfoItemWithLink
              primary="Organization"
              linkText={organizationDetails.organizationName}
              linkURL={organizationDetails.organizationLink}
            />
          </InfoItemWithIcon>
        }
      >
        <InfoItemScrollableMultiParagraph
          primary="Organization Description"
          secondary={organizationDetails.organizationDescription}
        />
      </ResizingInfoList>
    );
  }
}

export default withCircularProgress(OrganizationDetailsTemplate);
