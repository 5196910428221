import { useRef } from 'react';

type CustomPlotlyIcon = {
  width: number;
  height: number;
  path: string;
};

type ModebarProps = {
  onClick: () => void;
  icon: CustomPlotlyIcon;
  name: string;
  title: string;
  active?: boolean;
};

/**
 * Hook to create and manage a Plotly modebar button with a persistent `onClick`
 * handler.
 *
 * This hook is necessary because Plotly's modebar buttons do not seem to update
 * their `onClick` handlers after a rerender. It ensures that the `onClick`
 * handler is properly updated and applied after the plot is initialized.
 *
 * @example
 *   const { button: customButton, handlePlotInitialized } = useModebarButton(
 *     {
 *       onClick: myCustomFunction,
 *       icon: customIcon,
 *       name: 'custom-button',
 *       title: 'My Custom Button',
 *     }
 *   );
 *
 *   const config: Config = {
 *     modeBarButtonsToAdd: [customButton],
 *   };
 *
 *   <Plot
 *     config={config}
 *     onInitialized={handlePlotInitialized}
 *     data={plotData}
 *     layout={plotLayout}
 *   />;
 *
 * @param {ModebarProps} props - The properties needed to create the custom
 *   modebar button.
 * @param {() => void} props.onClick - The function to call when the button is
 *   clicked.
 * @param {CustomPlotlyIcon} props.icon - The custom icon to display for the
 *   button.
 * @param {string} props.name - A unique name to identify the modebar button.
 * @param {string} props.title - The tooltip text displayed when hovering over
 *   the button.
 * @param {boolean} props.active - Whether the button should be active
 * @returns {object} Returns an object containing:
 *
 *   - `button`: The custom modebar button object to be added to Plotly's
 *       `modeBarButtonsToAdd` config.
 *   - `handlePlotInitialized`: A function that must be passed to Plotly's
 *       `onInitialized` prop to ensure the button is properly initialized and
 *       updated after the plot renders.
 */

const useModebarButton = ({
  onClick,
  icon,
  name,
  title,
  active = false,
}: ModebarProps) => {
  const graphDivRef = useRef(null);

  const activeClass = active
    ? {
        [`[data-title="${title}"] > svg > path`]: {
          fill: 'rgba(68, 68, 68, 0.7) !important',
        },
      }
    : {};

  const updateButtonOnClick = () => {
    if (!graphDivRef.current) return;
    const modeBar = graphDivRef.current?.querySelector('.modebar');
    const button = modeBar.querySelector(`[data-title="${title}"]`);

    if (button) {
      button.onclick = onClick;
    }
  };

  const handlePlotInitialized = (figure, graphDiv) => {
    graphDivRef.current = graphDiv;
    updateButtonOnClick();
  };

  const button = {
    name,
    title,
    icon,
    click: () => {},
  };

  return { button, updateButtonOnClick, handlePlotInitialized, activeClass };
};

export default useModebarButton;
