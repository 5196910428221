import { Popover, PopoverProps } from 'base-components';
import DefaultShareButtons from './DefaultShareButtons';

interface SharePopoverProps extends PopoverProps {
  shareUrl: string;
  title: string;
  onCopy: () => void;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
}
/**
 * SharePopover component that is a popover with DefaultShareButtons component
 * inside it.
 */
const SharePopover = ({
  shareUrl,
  title,
  onCopy,
  onClose,
  ...props
}: SharePopoverProps) => (
  // class SharePopover extends PureComponent {
  /* See Popover API for more props: https://material-ui.com/api/popover/
   * At minimum, you should use the following props for popper:
   * open, onClose, anchorEl
   *
   */
  <Popover
    role="dialog"
    aria-label="Share Popover"
    onClose={onClose}
    {...props}
  >
    <DefaultShareButtons
      shareUrl={shareUrl}
      title={title}
      onCopy={onCopy}
      onClose={onClose}
    />
  </Popover>
);

export default SharePopover;
