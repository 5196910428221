/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Theme, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Typography } from 'base-components';

/**
 * The CollapsibleText component is a text component that can be collapsed to a
 * certain number of lines of text
 */

interface CollapsibleTextProps {
  /** The text to display */
  text: string;
  /** The number of lines to show before collapsing */
  linesToShow?: number;
  /** The variant of the typography component */
  variant?: TypographyProps['variant'];
  /** The id of the component */
  id?: string;
  /** The color of the typography component */
  color?: TypographyProps['color'];
}
interface StyleProps {
  isExpanded: boolean;
  linesToShow: number;
  canExpand: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  container: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: (props) =>
      props.isExpanded ? 'unset' : props.linesToShow,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-line',
    cursor: (props) => (props.canExpand ? 'pointer' : 'default'),
  },
}));

const CollapsibleText: React.FC<CollapsibleTextProps> = ({
  text,
  linesToShow = 3,
  id = 'collapsible-text',
  variant = 'body2',
  color = undefined,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [canExpand, setCanExpand] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles({ isExpanded, linesToShow, canExpand });

  const [mouseDownPosition, setMouseDownPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const checkOverflow = useCallback((): void => {
    const el = contentRef.current;
    if (el) {
      const isOverflowing = el.scrollHeight > el.clientHeight;
      if (canExpand !== isOverflowing) {
        setCanExpand(isOverflowing);
      }
    }
  }, [canExpand]);

  const toggleExpanded = (): void => {
    setIsExpanded(!isExpanded);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setMouseDownPosition({ x: e.clientX, y: e.clientY });
  };

  // If the mouse is released in the same position as it was pressed, toggle the expanded state
  // Otherwise, let the user select text without triggering the toggle
  const handleMouseUp = (e: React.MouseEvent) => {
    if ((isExpanded || canExpand) && mouseDownPosition) {
      const { x, y } = mouseDownPosition;
      if (Math.abs(e.clientX - x) < 5 && Math.abs(e.clientY - y) < 5)
        toggleExpanded();
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (contentRef.current) {
      checkOverflow();
      resizeObserver.observe(contentRef.current);
    }
    return () => {
      if (contentRef.current) {
        resizeObserver.unobserve(contentRef.current);
      }
    };
  }, [text, linesToShow, isExpanded]);

  const showText = isExpanded || canExpand;

  return (
    <div
      id={`${id}-container`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div
        id={`${id}-collapse-button`}
        ref={contentRef}
        className={classes.container}
      >
        <Typography variant={variant} color={color} id={`${id}-text`}>
          {text}
        </Typography>
      </div>
      {showText && (
        <Typography variant={variant} color={color}>
          <b>{isExpanded ? 'Show Less' : '...More'}</b>
        </Typography>
      )}
    </div>
  );
};

export default CollapsibleText;
