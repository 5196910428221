import { useEffect, useRef, useState } from 'react';
import { AsyncAutocomplete } from 'base-components';
import TaxonService from 'domain/services/TaxonService';
import { useSnackbars } from 'util/hooks/useSnackbars';

// TODO put this in the service file
export type TaxonOption = {
  commonName: string;
  parentName: string;
  englishNames?: string[];
  taxonId: number;
  referenceId: string;
};

type Taxon = {
  originTaxonomyId: number;
  commonName: string;
  deprecated: boolean;
  allowedAttributes: any[];
  englishNames?: string[];
  taxonId: number;
  nodeTypeId: number;
  referenceId: string;
  taxonomyMatrixId: number;
  parentName: string;
  modifiedDate: string;
  information: string;
  modifiedBy: string;
  jsonTaxonData: string;
};

type TaxonAsyncAutocompleteProps = {
  taxonomyId?: number;
  taxonId?: number;
  searchLettersRequired: number;
  error?: boolean;
  errorText?: string;
  onChange: (event: any, val: TaxonOption) => void;
};

const TaxonAsyncAutocomplete = ({
  taxonomyId = undefined,
  taxonId = undefined,
  searchLettersRequired,
  error = undefined,
  errorText = undefined,
  onChange,
}: TaxonAsyncAutocompleteProps) => {
  const [taxon, setTaxon] = useState<TaxonOption | null>(null);
  const cancelRequestRef = useRef<() => void>();
  const { onError } = useSnackbars();

  const getTaxonLabel = (tax: TaxonOption) => {
    let label = '';
    if (tax && tax.commonName) {
      label = tax.parentName
        ? `${tax.parentName} / ${tax.commonName}`
        : tax.commonName;
      if (tax.englishNames) {
        label += ` (${tax.englishNames.join(', ')})`;
      }
    }

    return label;
  };

  // Clear the field if taxonomyId changes
  useEffect(() => {
    setTaxon(null);
  }, [taxonomyId]);

  // use the function below in a useEffect whenever taxonId changes
  useEffect(() => {
    if (!taxonId) {
      setTaxon(null);
      return;
    }

    if (taxonomyId && taxonId) {
      TaxonService.getTaxon(taxonomyId, taxonId)
        .then((result: Taxon) => {
          const newTaxon = {
            commonName: result.commonName,
            parentName: result.parentName,
            englishNames: result.englishNames,
            taxonId: result.taxonId,
            referenceId: result.referenceId,
          };

          setTaxon(newTaxon);
        })
        .catch((e) => {
          onError(e.message);
          return null;
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxonId]);

  const getOptionList = async (input: string): Promise<TaxonOption[]> => {
    if (taxonomyId) {
      const { request, cancel } =
        TaxonService.getAbortableFilteredTaxonsByTaxonomy(taxonomyId, input);
      cancelRequestRef.current = cancel;
      return request.then((response) => response?.data || []);
    }
    return [];
  };

  const handleChange = (e: any, value: TaxonOption) => {
    const { target } = e;
    const newEvent = {
      ...e,
      target: {
        ...target,
        value: target.value?.taxonId || null,
      },
    };
    setTaxon(value);
    onChange(newEvent, e.target.value);
  };

  return (
    <AsyncAutocomplete
      value={taxon}
      minLetters={searchLettersRequired}
      resourceName="Taxon"
      translationKey="taxonomy.taxon"
      name="taxonId"
      disabled={!taxonomyId}
      onChange={handleChange}
      getOptionLabel={getTaxonLabel}
      loadOptions={getOptionList}
      cancelRef={cancelRequestRef}
      error={error}
      helperText={errorText}
    />
  );
};

export default TaxonAsyncAutocomplete;
