import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import LastSensorReadingsService from 'domain/services/LastSensorReadingsService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import SortableTable from 'library/CompositeComponents/table/SortableTable';

const styles = (theme) => ({
  table: {
    overflowX: 'hidden',
    margin: theme.spacing(1),
    minWidth: theme.spacing(75),
  },
});

const headers = [
  { title: 'Sensor Name', name: 'sensorName' },
  { title: 'Sensor Description', name: 'sensorDesc' },
  { title: 'Data Description', name: 'description' },
  { title: 'Data Value', name: 'value' },
];

const tableColumnExtensions = [
  {
    columnName: 'sensorName',
    align: 'center',
  },
  {
    columnName: 'sensorDesc',
    align: 'center',
    wordWrapEnabled: true,
  },
  {
    columnName: 'description',
    align: 'center',
    wordWrapEnabled: true,
  },
  {
    columnName: 'value',
    align: 'center',
  },
];

const pvcsSensorCodeData = {
  SYSTEMSTATUS: {
    sensorName: 'System State',
    sensorDesc: 'System state',
    description: '0=WAIT, 1=PUMP, 2=LEAK',
    value: '---',
  },
  valve_position_status: {
    sensorName: 'Valve Position',
    sensorDesc: 'Position of valves',
    description: '0=OFF, 1=SAMPLE, 2=FLUSH, 3=MOVING',
    value: '---',
  },
  current: {
    sensorName: 'Pump Current',
    sensorDesc: 'Pump electrical current',
    description: '(2-3.5A typical)',
    value: '---',
  },
  flow: {
    sensorName: 'Outlet Flow',
    sensorDesc: 'Flow from instruments',
    description: '(2-3GPM typical)',
    value: '---',
  },
  volume_resevoir: {
    sensorName: 'Tank Level',
    sensorDesc: 'Freshwater tank level sensor',
    description: '0 to 100%',
    value: '---',
  },
};

class PVCSViewTable extends PureComponent {
  static propTypes = {
    permission: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    doneWaitingForPromise: PropTypes.func,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({ table: PropTypes.string }),
  };

  static defaultProps = {
    classes: undefined,
    doneWaitingForPromise: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
    };
  }

  componentDidMount() {
    const { deviceId } = this.props;
    this.refreshTableData(deviceId);
  }

  componentDidUpdate(prevProps) {
    const { deviceId } = this.props;
    if (deviceId !== prevProps.deviceId) {
      this.refreshTableData(deviceId);
    }
  }

  refreshTableData = (deviceId) => {
    const { onError, doneWaitingForPromise } = this.props;
    LastSensorReadingsService.get(deviceId)
      .then((result) => {
        this.buildTableRows(result);
        doneWaitingForPromise();
      })
      .catch(() => {
        onError(`Failed to retrieve Sensor Info for deviceId ${deviceId}`);
        doneWaitingForPromise();
      });
  };

  buildTableRows = (payload) => {
    if (payload.length > 0) {
      this.setState({
        tableRows: payload
          .slice(1)
          .filter((sensorReading) =>
            Object.keys(pvcsSensorCodeData).includes(sensorReading.readingName)
          )
          .map((sensorReading) => {
            const sensorFor = {
              sensorName:
                pvcsSensorCodeData[sensorReading.readingName].sensorName,
              sensorDesc:
                pvcsSensorCodeData[sensorReading.readingName].sensorDesc,
              description:
                pvcsSensorCodeData[sensorReading.readingName].description,
              value: sensorReading.correctedReading,
            };
            return sensorFor;
          }),
      });
    } else {
      this.setState({
        tableRows: Object.values(pvcsSensorCodeData).map((sensorCode) => {
          const sensorFor = sensorCode;
          return sensorFor;
        }),
      });
    }
  };

  render() {
    const { permission, classes } = this.props;
    const { tableRows } = this.state;
    return (
      <SortableTable
        columns={headers}
        columnExtensions={tableColumnExtensions}
        elevation={0}
        pageSize={20}
        rows={tableRows}
        searchBarMoveable={permission === 'RW'}
        classes={{ root: classes.table }}
      />
    );
  }
}

export default withStyles(styles)(withSnackbars(PVCSViewTable));
