import { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ExpandMore, ExpandLess } from '@onc/icons';
import { IconButton, TableOld as Table, Typography } from 'base-components';

import { TextButton } from 'library/CompositeComponents/button/Buttons';
import PanelHeader from 'library/CompositeComponents/panel/PanelHeader';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

import DifferenceEntry from './DifferenceEntry';
import SiteDeviceSubsetCastDefinitionTransactionService from './SiteDeviceSubsetCastDefinitionTransactionService';

const STYLES = (theme) => ({
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(3),
  },
  tableStyle: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  diffStyle: {
    display: 'flex',
  },
  pre: {
    flex: 0.7,
  },
  post: {
    flex: 1,
  },
});

const SiteDeviceSubsetHistoryTable = ({
  siteDeviceSubsetId = undefined,
  classes = undefined,
  siteDeviceSubsetTransactionRows = undefined,
  pageSize = 5,
  onError,
}) => {
  SiteDeviceSubsetHistoryTable.propTypes = {
    siteDeviceSubsetId: PropTypes.number,
    classes: PropTypes.shape({
      formButtons: PropTypes.string,
      tableStyle: PropTypes.string,
      diffStyle: PropTypes.string,
      pre: PropTypes.string,
      post: PropTypes.string,
      commentPopup: PropTypes.string,
    }),
    siteDeviceSubsetTransactionRows: PropTypes.arrayOf(PropTypes.shape({})),
    onError: PropTypes.func.isRequired,
    pageSize: PropTypes.number,
  };

  const [subsetTransactionRows, setSubsetTransactionRows] = useState(
    siteDeviceSubsetTransactionRows || []
  );

  useEffect(() => {
    setSubsetTransactionRows(subsetTransactionRows);
  }, [subsetTransactionRows]);

  const [tableExpandedBool, setExpanded] = useState(false);

  useEffect(
    () => {
      if (tableExpandedBool) {
        getSiteDeviceSubsetCastDefinitionTransactions();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableExpandedBool]
  );

  const getSiteDeviceSubsetCastDefinitionTransactions = async () => {
    if (!siteDeviceSubsetId || isNaN(siteDeviceSubsetId)) return;
    try {
      const payload =
        await SiteDeviceSubsetCastDefinitionTransactionService.getSiteDeviceSubsetTransactions(
          siteDeviceSubsetId
        );
      setSubsetTransactionRows(payload.records);
    } catch (error) {
      onError(`Failed to get site device subset transactions`);
    }
  };

  const getColumns = () => {
    const columns = [
      {
        name: 'changeDescription',
        title: 'Change Description',
      },
      {
        name: 'difference',
        title: 'Change Diff',
      },
      {
        name: 'modifyDate',
        title: 'Modified Date',
      },
      {
        name: 'modifyBy',
        title: 'Modified By',
      },
    ];
    return columns;
  };

  const parseDifference = (diffList) => {
    const parsedDifference = diffList.map((diff) => (
      <li key={diff.fieldName}>
        <DifferenceEntry diff={diff} />
      </li>
    ));
    return <ul style={{ paddingLeft: 15 }}>{parsedDifference}</ul>;
  };

  const getRenderedTransactionRows = useCallback(() => {
    let renderedTransactionRows = [];
    renderedTransactionRows = subsetTransactionRows.map(
      (siteDeviceSubsetTransaction) => ({
        modifyBy: siteDeviceSubsetTransaction.modifyBy,
        modifyDate: siteDeviceSubsetTransaction.modifyDate,
        changeDescription: siteDeviceSubsetTransaction.changeDescription,
        difference: parseDifference(siteDeviceSubsetTransaction.difference),
      })
    );
    return renderedTransactionRows;
  }, [subsetTransactionRows]);

  const handleClick = () => {
    const isTableExpanded = tableExpandedBool;
    setExpanded(!isTableExpanded);
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <PanelHeader
          title={
            <>
              <Typography variant="body1" style={{ marginRight: -20 }}>
                <TextButton
                  translationKey="communityFishers.castModificationHistory"
                  onClick={() => handleClick()}
                  underline="hover"
                  size="large"
                />
              </Typography>
              <IconButton onClick={() => handleClick()} id="iconButton">
                {tableExpandedBool ? (
                  <ExpandMore fontSize="large" color="primary" />
                ) : (
                  <ExpandLess fontSize="large" color="primary" />
                )}
              </IconButton>
            </>
          }
        />
      </div>
      {tableExpandedBool ? (
        <div className={classes.tableStyle}>
          <Table
            sortable
            sort={[{ columnName: 'modifyDate', direction: 'desc' }]}
            columns={getColumns()}
            rows={getRenderedTransactionRows()}
            pageSize={pageSize}
            disabledSort={[
              {
                columnName: 'difference',
                sortingEnabled: false,
              },
            ]}
          />
        </div>
      ) : null}
    </>
  );
};

export default withStyles(STYLES)(withSnackbars(SiteDeviceSubsetHistoryTable));
