import {
  Children,
  memo,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  FC,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid } from 'base-components';

interface CardGridProps {
  /** While this grid will work for most bordered things Cards are preffered */
  children: ReactNode | ReactNode[];
  /** Number of columns wanted */
  columns?: number;
  /** Number of rows wanted */
  rows?: number;
  /**
   * Placeholder in case Cards are needed to fill the rest of the screen.
   * Defaults to an invisible div
   */
  placeholder?: ReactNode;
}

const useStyles = makeStyles(() => ({
  cardFullHeight: {
    height: '100%',
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

const CardGrid: FC<CardGridProps> = ({
  children,
  columns = 2,
  rows = 2,
  placeholder = <div />,
}) => {
  const classes = useStyles();
  const childrenArray = Children.toArray(children);

  const placeholderArray = Children.toArray(placeholder);
  const minCards = columns * rows;
  const placeholderCardCount = minCards - childrenArray.length;
  const allCards = childrenArray;

  for (let i = 0; i < placeholderCardCount; i += 1) {
    allCards.push(placeholderArray);
  }

  return (
    <Grid container spacing={2} alignItems="stretch">
      {allCards.map((card) => (
        <Grid
          item
          className={classes.flexGrow}
          key={Math.random()} // Consider using a more stable key for production
          xs={12}
          sm={Math.max(12 / (columns / 2), 1)}
          lg={Math.max(12 / columns, 1)} // Ensure we're not dividing by zero and respect minimum of 1
        >
          <Box className={classes.cardContent}>
            {isValidElement(card)
              ? cloneElement(card as ReactElement<any>, {
                  className: classes.cardFullHeight,
                })
              : card}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default memo(CardGrid);
