import { useState, useEffect } from 'react';
import * as React from 'react';

import { Dropdown } from 'base-components';
import DeviceType from 'domain/Apps/device-type/DeviceType';
import DeviceTypeService from 'domain/services/DeviceTypeService';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onError: (message: any, callback?: any) => void;
  prependOption: any[];
  value: number;
  className: string;
  fullWidth?: boolean;
};

const DeviceTypeSelect = ({
  onError,
  onChange,
  prependOption,
  value,
  className,
  fullWidth = false,
}: Props) => {
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);

  useEffect(() => {
    const callService = async () => {
      setDeviceTypes(await DeviceTypeService.getAllDeviceTypes());
    };
    callService();
  }, []);

  const createDropdownOptions = () => {
    try {
      const options = deviceTypes.map((deviceType) => ({
        label: deviceType.deviceTypeName,
        value: deviceType.deviceTypeId,
        key: deviceType.deviceTypeId,
      }));
      return prependOption.concat(options);
    } catch (e) {
      onError(e);
    }
    return [];
  };
  const options = createDropdownOptions();
  return (
    <>
      <Dropdown
        options={options}
        value={value}
        onChange={onChange}
        label="Device Type"
        className={className}
        fullWidth={fullWidth}
      />
    </>
  );
};

export default DeviceTypeSelect;
