import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import ParserDefinitionMaintenance from './ParserDefinitionMaintenance';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(2),
  },
});

const defaultState = {};

class ParserDefinitionMaintenancePage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
    }),
    pdId: PropTypes.number,
    sensorId: PropTypes.number,
    history: PropTypes.shape().isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: undefined,
    pdId: undefined,
    sensorId: undefined,
  };

  constructor(props) {
    super(props);
    const { pdId, sensorId } = this.props;
    this.state = {
      ...defaultState,
      sensorId,
      pdId,
    };
  }

  render() {
    const { pdId, sensorId, onInfo, onError, history } = this.props;

    return (
      <ParserDefinitionMaintenance
        pdId={pdId}
        sensorId={sensorId}
        history={history}
        onInfo={onInfo}
        onError={onError}
        permission={Environment.getDmasUserPrivilege()}
      />
    );
  }
}
export default withStyles(STYLES)(
  withSnackbars(ParserDefinitionMaintenancePage)
);
