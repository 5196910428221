import FormField from 'domain/Apps/form-field/FormField';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class FormFieldService {
  static get(params) {
    return get('FormFieldService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static getAllFormFields(): Promise<FormField[]> {
    return FormFieldService.get({ operation: 5 });
  }
}
export default FormFieldService;
