import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from 'base-components';
import { GeospatialAreaIconButton } from 'domain/AppComponents/IconButtons';

class GeospatialTextAreaButtonSet extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    initialValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({}),
    ]),
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    classes: PropTypes.shape({
      textField: PropTypes.string,
      root: PropTypes.string,
    }),
  };

  static defaultProps = {
    title: undefined,
    initialValue: undefined,
    onClick: undefined,
    onChange: undefined,
    fullWidth: false,
    classes: undefined,
  };

  validateWKTPolygon = (value) => {
    // Matches latitude and longitude in format like 'LONGITUDE LATITUDE'
    const latAndLongRegex =
      '[-+]?(?:180(?:\\.0+)?|(?:(?:1[0-7]\\d)|(?:[1-9]?\\d))' +
      '(?:\\.\\d+)?)\\s+[-+]?(?:[1-8]?\\d(?:\\.\\d+)?|90(?:\\.0+)?)';
    const depthRegex = '-?(?:0|[1-9]\\d*)(?:\\.\\d+)?';
    const boundingAreaRegex = new RegExp(
      `^\\s*POLYGONZ?\\s*(?:\\(\\s*(?:\\(\\s*(?:${latAndLongRegex}\\s*${depthRegex}?\\s*,\\s*){1,}${latAndLongRegex}\\s*${depthRegex}?\\s*)\\)\\s*)\\)\\s*$`
    );
    if (value === '' || boundingAreaRegex.test(value)) {
      return true;
    }
    return false;
  };

  handleClick = (textValid) => () => {
    const { onClick } = this.props;

    if (textValid === true) {
      onClick();
    }
  };

  render() {
    const { title, initialValue, onChange, fullWidth, classes } = this.props;

    const textValid = this.validateWKTPolygon(initialValue);

    const helperText = textValid ? '' : 'Invalid text for geospatial area';
    const error = !textValid;

    return (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={11}>
          <TextField
            translationKey="device.geospatialArea"
            name="geospatialArea"
            title={title}
            value={initialValue}
            onChange={onChange}
            fullWidth={fullWidth}
            classes={classes}
            helperText={helperText}
            error={error}
            multiline
            rows={3}
          />
        </Grid>
        <Grid item container xs={1}>
          <GeospatialAreaIconButton onClick={this.handleClick(textValid)} />
        </Grid>
      </Grid>
    );
  }
}
export default GeospatialTextAreaButtonSet;
