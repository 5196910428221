import { useEffect, useState } from 'react';
import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import _ from 'lodash';
import { TextField } from 'base-components';
import StatelessTable from 'library/BaseComponents/table/StatelessTable';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { useFetchLabels } from './LocalContextsService';
import {
  useFetchProjectId,
  useUpdateProjectId,
} from './OrganizationAgreementService';

type LocalContextsPageProps = {
  organizationAgreementId: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      margin: theme.spacing(2),
    },
    section: {
      padding: theme.spacing(2),
    },
    textField: {
      width: '342px',
    },
  })
);

const LocalContextsPage: React.FC<LocalContextsPageProps> = ({
  organizationAgreementId,
}: LocalContextsPageProps) => {
  const { onInfo, onError } = useSnackbars();
  const [projectIdField, setProjectIdField] = useState('');

  const classes = useStyles();

  const {
    data: projectId,
    error: fetchProjectIdError,
    refetch: refetchProjectId,
  } = useFetchProjectId(organizationAgreementId);

  const {
    data: bcLabels,
    error: fetchLabelsError,
    status: fetchLabelsStatus,
    isLoading: fetchLabelsIsLoading,
  } = useFetchLabels(projectId);

  const { mutate: updateProjectId } = useUpdateProjectId(
    () => {
      refetchProjectId();
      onInfo('Project ID Updated');
    },
    (e) => onError(e.message)
  );

  useEffect(() => {
    if (!_.isNil(projectId)) setProjectIdField(projectId ?? '');
  }, [projectId, setProjectIdField]);

  useEffect(() => {
    if (fetchProjectIdError) onError(fetchProjectIdError.message);
  }, [fetchProjectIdError, onError]);

  useEffect(() => {
    if (projectId && fetchLabelsError && !fetchLabelsIsLoading)
      onError(`No project exists for project ID: ${projectId}`);
  }, [projectId, fetchLabelsIsLoading, fetchLabelsError, onError]);

  useEffect(() => {});

  useEffect(() => {
    if (bcLabels && fetchLabelsStatus === 'success')
      onInfo('Fetched project labels');
  }, [bcLabels, fetchLabelsStatus, onInfo]);

  return (
    <div className={classes.page}>
      <div className={classes.section}>
        <TextField
          translationKey="localContexts.projectId"
          value={projectIdField}
          onChange={(e) => setProjectIdField(e.target.value)}
          className={classes.textField}
        />
        <ContainedButton
          translationKey="common.buttons.update"
          onClick={() =>
            updateProjectId({
              organizationAgreementId,
              localContextsProjectId: projectIdField,
            })
          }
        />
      </div>
      <div className={classes.section}>
        <StatelessTable
          rows={(bcLabels ?? []).map((label) => ({
            id: `${label.name}-${label.svg_url}`,
            img: (
              <img
                src={label.svg_url}
                alt={label.name}
                style={{ maxWidth: '70px', padding: '8px' }}
              />
            ),
            name: label.name,
            community: label.community,
            labelText: label.label_text,
          }))}
          columns={[
            { name: 'img', title: ' ' },
            { name: 'name', title: 'Label Name' },
            { name: 'community', title: 'Community' },
            { name: 'labelText', title: 'Label Description' },
          ]}
          columnExtensions={[
            { columnName: 'img', width: '120px' },
            { columnName: 'name', width: '200px', wordWrapEnabled: true },
            { columnName: 'community', width: '200px' },
            { columnName: 'labelText', wordWrapEnabled: true },
          ]}
        />
      </div>
    </div>
  );
};

export default LocalContextsPage;
