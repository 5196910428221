import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import BACKGROUND_IMG from 'assets/images/df-background-tile.png';
import CampaignList from 'domain/AppComponents/digital-fishers/CampaignList';
import DFLogo from 'domain/AppComponents/digital-fishers/DFLogo';
import DFTitleBar from 'domain/AppComponents/digital-fishers/DFTitleBar';

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${BACKGROUND_IMG})`,
    backgroundPositionX: '0px',
    backgroundPositionY: 'center',
    backgroundSize: 'initial',
    backgroundRepeat: 'repeat',
    backgroundClip: 'initial',
    height: '100%',
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(8),
      paddingLeft: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(),
      paddingLeft: theme.spacing(),
    },
  },
  scrollableContainer: {
    overflow: 'auto',
    height: 'calc(100% - 37px)',
  },
});

class DFLandingPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string,
      container: PropTypes.string,
      scrollableContainer: PropTypes.string,
    }),
  };

  static defaultProps = {
    classes: undefined,
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      // eslint-disable-next-line react/no-unknown-property
      <div align="center" className={classes.root}>
        <DFTitleBar />
        <div className={classes.scrollableContainer}>
          <DFLogo />
          <div className={classes.container}>
            <CampaignList {...rest} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DFLandingPage);
