import { useState } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { InfoAlert, WarningAlert } from '@onc/composite-components';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from 'base-components';
import { ExportOptionsRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import ExportProgress from './ExportProgress';
import SeaTubeExportButton from './SeaTubeExportButton';
import SeaTubeSearchExportFormat from './SeaTubeSearchExportFormat';

const styles = (theme) => ({
  exportContainer: {
    width: '100%',
    textAlign: 'end',
  },
  progress: {
    display: 'inline-block',
  },
  buttonOne: {
    paddingRight: theme.spacing(),
  },
});
const SeaTubeExportActionsTab = ({
  classes,
  inclSpecialChars,
  inclSeaTubeLinks,
  inclVideoSnapshots,
  snapshotsPer,
  timeBetweenSnapshots,
  onInclSpecialCharsChange,
  onInclSeaTubeLinksChange,
  onInclVideoSnapshotsChange,
  onSnapshotsPerChange,
  onTimeBetweenSnapshotsChange,
  onExportSubmit,
  exportStatus = '',
  searchId = undefined,
  fileName = undefined,
  disabled,
  isLoggedIn = undefined,
  broadSearchContext = false,
  canExportDarwinCore = false,
}) => {
  const [format, setFormat] = useState('csv');
  const isButtonDisabled =
    disabled ||
    snapshotsPer <= 0 ||
    timeBetweenSnapshots <= 0 ||
    (!canExportDarwinCore &&
      format === SeaTubeSearchExportFormat.DARWIN_CORE.name);

  const renderInvalidDarwinCoreAlert = () => (
    <WarningAlert>
      <Typography variant="subtitle2">
        Darwin Core export is currently only available when a single dive is
        selected.
      </Typography>
    </WarningAlert>
  );

  const renderSTStandardMessage = () => (
    <InfoAlert>
      <Typography variant="subtitle2">
        Ingestion CSV export will only export annotations on a dive&apos;s
        default camera.
      </Typography>
    </InfoAlert>
  );

  const renderExtraDataFields = () => {
    if (!broadSearchContext) {
      if (
        format === SeaTubeSearchExportFormat.DARWIN_CORE.name ||
        format === SeaTubeSearchExportFormat.INGESTION_CSV.name
      ) {
        return undefined;
      }

      return (
        <>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inclSpecialChars}
                  onChange={onInclSpecialCharsChange}
                />
              }
              label="Include Special Characters <&quot;,>'&"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inclSeaTubeLinks}
                  onChange={onInclSeaTubeLinksChange}
                />
              }
              label="Include SeaTube Links"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inclVideoSnapshots}
                  onChange={onInclVideoSnapshotsChange}
                />
              }
              label="Include Video Snapshots"
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.buttonOne}
              id="snapshots-per-annotation"
              translationKey="seatube.snapshotsPerAnnotation"
              type="number"
              value={snapshotsPer}
              onChange={onSnapshotsPerChange}
              disabled={!inclVideoSnapshots}
              error={snapshotsPer <= 0}
            />
            <TextField
              id="time-between-snapshots"
              translationKey="seatube.timeBetweenSnapshots"
              type="number"
              value={timeBetweenSnapshots}
              onChange={onTimeBetweenSnapshotsChange}
              disabled={!inclVideoSnapshots || snapshotsPer < 2}
              error={timeBetweenSnapshots < 0}
            />
          </Grid>
        </>
      );
    }

    return undefined;
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <ExportOptionsRadioGroup
          onChange={(event, newFormat) => {
            setFormat(newFormat);
          }}
          value={format}
        />
      </Grid>
      {renderExtraDataFields()}
      {!canExportDarwinCore &&
      format === SeaTubeSearchExportFormat.DARWIN_CORE.name
        ? renderInvalidDarwinCoreAlert()
        : undefined}
      {format === SeaTubeSearchExportFormat.INGESTION_CSV.name
        ? renderSTStandardMessage()
        : undefined}
      <div className={classes.exportContainer}>
        <div id="progress-area" className={classes.progress}>
          <ExportProgress
            status={exportStatus}
            fileName={fileName}
            searchId={searchId}
          />
        </div>
        <SeaTubeExportButton
          isLoggedIn={isLoggedIn}
          exportType="annotations"
          onClick={() => onExportSubmit(format)}
          disabled={isButtonDisabled}
        />
      </div>
    </Grid>
  );
};

SeaTubeExportActionsTab.propTypes = {
  inclSpecialChars: PropTypes.bool.isRequired,
  inclSeaTubeLinks: PropTypes.bool.isRequired,
  inclVideoSnapshots: PropTypes.bool.isRequired,
  snapshotsPer: PropTypes.number.isRequired,
  timeBetweenSnapshots: PropTypes.number.isRequired,
  onInclSpecialCharsChange: PropTypes.func.isRequired,
  onInclSeaTubeLinksChange: PropTypes.func.isRequired,
  onInclVideoSnapshotsChange: PropTypes.func.isRequired,
  onSnapshotsPerChange: PropTypes.func.isRequired,
  onTimeBetweenSnapshotsChange: PropTypes.func.isRequired,
  onExportSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    exportContainer: PropTypes.string,
    progress: PropTypes.string,
    buttonOne: PropTypes.string,
  }).isRequired,
  exportStatus: PropTypes.string,
  searchId: PropTypes.number,
  fileName: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  broadSearchContext: PropTypes.bool,
  canExportDarwinCore: PropTypes.bool,
};

export default withStyles(styles)(SeaTubeExportActionsTab);
