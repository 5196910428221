import * as React from 'react';
import {
  IntegratedSorting,
  SortingState,
  Sorting,
} from '@devexpress/dx-react-grid';

export interface SortingProps {
  sorting: Sorting[];
  columnExtensions?: SortingState.ColumnExtension[];
  customSorting?: IntegratedSorting.ColumnExtension[];
  handleSortingChange?: (sorting: Sorting[]) => void;
}

const ColumnSorting: React.VFC<SortingProps> = ({
  sorting,
  columnExtensions = undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customSorting = undefined,
  handleSortingChange = undefined,
}) => {
  if (handleSortingChange) {
    return (
      <SortingState
        sorting={sorting}
        columnExtensions={columnExtensions}
        onSortingChange={handleSortingChange}
      />
    );
  }
  return <SortingState defaultSorting={sorting} />;
};
export default ColumnSorting;
