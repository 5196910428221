import PropTypes from 'prop-types';
import { Grid } from 'base-components';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';

const OptionalReadOnlyField = (props) => {
  const { label, value } = props;

  if (!value) {
    return null;
  }

  return (
    <Grid item>
      <MarginedReadOnlyField labelText={label} valueText={value} />
    </Grid>
  );
};

OptionalReadOnlyField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default OptionalReadOnlyField;
