import { ChangeEvent } from 'react';
import * as React from 'react';
import { Autocomplete, TextField } from 'base-components';
import { AttributeValueSelect } from 'domain/AppComponents/dropdowns/Dropdowns';

type Props = {
  id: string;
  dataType: string;
  disabled: boolean;
  options?: Option[];
  value: any;
  error?: boolean;
  helperText?: string;
  onChange: (value: any) => void;
  onBlur?: (value: any) => void;
};

type Option = {
  value: any;
  label: string;
};

// eslint-disable-next-line react/require-default-props
const AttributeValueField: React.VFC<Props> = (props: Props) => {
  const { dataType, onChange, value, options } = props;
  // -----  Render ----- //
  switch (dataType) {
    case 'String':
      return (
        <TextField
          {...props}
          translationKey="common.textfields.value"
          fullWidth
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case 'Number':
    case 'Integer':
      return (
        <TextField
          {...props}
          translationKey="common.textfields.value"
          type="number"
          fullWidth
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case 'Boolean':
      return (
        <AttributeValueSelect
          {...props}
          label="Value"
          options={[
            { label: 'True', value: 'True' },
            { label: 'False', value: 'False' },
          ]}
          fullWidth
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
        />
      );

    case 'Select': {
      // If a string is passed in, find the option object that matches the label
      if (typeof value === 'string') {
        onChange(options?.find((o) => o.label === value) || null);
      }

      return (
        <Autocomplete
          {...props}
          translationKey="common.textfields.value"
          name="value"
          options={options}
          fullWidth
          getOptionLabel={(option: Option) => option.label || ''}
          onChange={(e: any, val: any) => {
            onChange(val);
          }}
          isOptionEqualToValue={(option: Option, val: Option) =>
            option.value === val.value
          }
          value={value}
        />
      );
    }

    default:
      return (
        <TextField
          {...props}
          translationKey="common.textfields.value"
          fullWidth
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          value={value.value}
        />
      );
  }
};

export default AttributeValueField;
