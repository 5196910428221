import {
  INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK,
  INDICES_OF_DATA_PRODUCT_OPTIONS_RDI,
  FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE,
  ADCP_DEVICE_CATEGORY_CODES,
  NUMBER_OF_ADCP_SEARCH_PARAMETERS,
} from 'domain/Apps/data-player/util/DataPlayerConstants';

import DataPlayerDeviceService from './DataPlayerDeviceService';

class ToolboxUtil {
  /** @param {object} field */
  static getLabelAndValueFromOption(field) {
    const labelValueArray = field.choices.map((choice) => ({
      label: choice.label,
      value: choice.value,
    }));
    const optionLabel = field.label;
    const formFieldId = field.name;
    return { formFieldId, optionLabel, labelValueArray };
  }

  /** @param {Array} optionsArray */
  static generateInitialValue(optionsArray) {
    if (optionsArray === undefined || optionsArray.length === 0) {
      return undefined;
    }
    return optionsArray[0].value;
  }

  static async getLabelsAndOptions(resourceId, onError) {
    // gets all the search options and passes them one at a time to getLabelAndValueFromOption
    const searchOptions = await DataPlayerDeviceService.getSearchOptions(
      resourceId,
      onError
    );
    return searchOptions.map((searchOption) =>
      ToolboxUtil.getLabelAndValueFromOption(searchOption)
    );
  }

  static async fetchDefaultSearchOptionsForDevice(
    resourceId,
    deviceCategoryCode,
    deviceId,
    onError
  ) {
    // make service call
    const options = await DataPlayerDeviceService.getSearchOptions(
      resourceId,
      onError
    );

    // parse options if ADCP device
    if (ADCP_DEVICE_CATEGORY_CODES.includes(deviceCategoryCode)) {
      const isRdiAdcp = await DataPlayerDeviceService.isRdiAdcp(
        deviceId,
        deviceCategoryCode,
        onError
      );
      return this.parseAdcpOptions(isRdiAdcp, options);
    }

    // parse options if Hydrophone device
    return this.parseHydrophoneOptions(options);
  }

  // TODO an implementation that is easier to add new device types to.
  static parseAdcpOptions(isRdiAdcp, options) {
    if (isRdiAdcp === true) {
      if (
        options.length !==
        NUMBER_OF_ADCP_SEARCH_PARAMETERS.NUMBER_SEARCH_OPTIONS_RDI
      ) {
        return {
          velocityBinMapping: undefined,
          horizontalCurrentPlotLimits: undefined,
          verticalCurrentPlotLimits: undefined,
          threeBeamSolution: undefined,
          lowCorrelationScreenThreshold: undefined,
          errCorrelationScreenThreshold: undefined,
          falseTargetMaxThreshold: undefined,
          backscatterColourMap: undefined,
          backscatterLowerPlotLimits: undefined,
          backscatterUpperPlotLimits: undefined,
          imageSize: undefined,
        };
      }
      const parsedOptions = options.map((option) =>
        ToolboxUtil.getLabelAndValueFromOption(option)
      );
      const velocityBinMapping = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI.VELOCITY_BIN_MAPPING_IDX
        ].labelValueArray
      );
      const horizontalCurrentPlotLimits = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI.HORIZONTAL_CURRENT_LIMITS_IDX
        ].labelValueArray
      );
      const verticalCurrentPlotLimits = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI.VERTICAL_CURRENT_LIMITS_IDX
        ].labelValueArray
      );
      const backscatterColourMap = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI.BACKSCATTER_COLOUR_IDX
        ].labelValueArray
      );
      const backscatterLowerPlotLimits = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI.LOWER_BACKSCATTER_LIMIT_IDX
        ].labelValueArray
      );
      const backscatterUpperPlotLimits = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI.UPPER_BACKSCATTER_LIMIT_IDX
        ].labelValueArray
      );
      const threeBeamSolution = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI.THREE_BEAM_SOLUTIONS_IDX
        ].labelValueArray
      );
      const lowCorrelationScreenThreshold = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI
            .LOW_CORRELATION_SCREEN_THRESHOLD_IDX
        ].labelValueArray
      );
      const errCorrelationScreenThreshold = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI
            .ERR_CORRELATION_SCREEN_THRESHOLD_IDX
        ].labelValueArray
      );
      const falseTargetMaxThreshold = ToolboxUtil.generateInitialValue(
        parsedOptions[
          INDICES_OF_DATA_PRODUCT_OPTIONS_RDI.FALSE_TARGET_THRESHOLD_MAX_IDX
        ].labelValueArray
      );
      const imageSize = ToolboxUtil.generateInitialValue(
        parsedOptions[INDICES_OF_DATA_PRODUCT_OPTIONS_RDI.IMAGE_SIZE_IDX]
          .labelValueArray
      );
      return {
        velocityBinMapping,
        horizontalCurrentPlotLimits,
        verticalCurrentPlotLimits,
        threeBeamSolution,
        lowCorrelationScreenThreshold,
        errCorrelationScreenThreshold,
        falseTargetMaxThreshold,
        backscatterColourMap,
        backscatterLowerPlotLimits,
        backscatterUpperPlotLimits,
        imageSize,
      };
    }
    if (
      options.length !==
      NUMBER_OF_ADCP_SEARCH_PARAMETERS.NUMBER_SEARCH_OPTIONS_NORTEK
    ) {
      return {
        velocityBinMapping: undefined,
        horizontalCurrentPlotLimits: undefined,
        verticalCurrentPlotLimits: undefined,
        backscatterColourMap: undefined,
        backscatterLowerPlotLimits: undefined,
        backscatterUpperPlotLimits: undefined,
        imageSize: undefined,
      };
    }
    // else Nortek device
    const parsedOptions = options.map((option) =>
      ToolboxUtil.getLabelAndValueFromOption(option)
    );
    const velocityBinMapping = ToolboxUtil.generateInitialValue(
      parsedOptions[
        INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK.VELOCITY_BIN_MAPPING_IDX
      ].labelValueArray
    );
    const horizontalCurrentPlotLimits = ToolboxUtil.generateInitialValue(
      parsedOptions[
        INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK.HORIZONTAL_CURRENT_LIMITS_IDX
      ].labelValueArray
    );
    const verticalCurrentPlotLimits = ToolboxUtil.generateInitialValue(
      parsedOptions[
        INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK.VERTICAL_CURRENT_LIMITS_IDX
      ].labelValueArray
    );
    const backscatterColourMap = ToolboxUtil.generateInitialValue(
      parsedOptions[
        INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK.BACKSCATTER_COLOUR_IDX
      ].labelValueArray
    );
    const backscatterLowerPlotLimits = ToolboxUtil.generateInitialValue(
      parsedOptions[
        INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK.LOWER_BACKSCATTER_LIMIT_IDX
      ].labelValueArray
    );
    const backscatterUpperPlotLimits = ToolboxUtil.generateInitialValue(
      parsedOptions[
        INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK.UPPER_BACKSCATTER_LIMIT_IDX
      ].labelValueArray
    );
    const imageSize = ToolboxUtil.generateInitialValue(
      parsedOptions[INDICES_OF_DATA_PRODUCT_OPTIONS_NORTEK.IMAGE_SIZE_IDX]
        .labelValueArray
    );
    return {
      velocityBinMapping,
      horizontalCurrentPlotLimits,
      verticalCurrentPlotLimits,
      backscatterColourMap,
      backscatterLowerPlotLimits,
      backscatterUpperPlotLimits,
      imageSize,
    };
  }

  static parseHydrophoneOptions(options) {
    const parsedOptions = options.map((option) =>
      ToolboxUtil.getLabelAndValueFromOption(option)
    );
    const colourPalette = ToolboxUtil.getHydrophoneOptionsIfExists(
      FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.COLOUR_PALETTE_ID,
      parsedOptions
    );
    const upperColourLimit = ToolboxUtil.getHydrophoneOptionsIfExists(
      FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.UPPER_COLOUR_LIMIT_ID,
      parsedOptions
    );
    const lowerColourLimit = ToolboxUtil.getHydrophoneOptionsIfExists(
      FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.LOWER_COLOUR_LIMIT_ID,
      parsedOptions
    );
    const spectrogramSource = ToolboxUtil.getHydrophoneOptionsIfExists(
      FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.SPECTROGRAM_SOURCE_ID,
      parsedOptions
    );
    const hydrophoneDataAcquisition = ToolboxUtil.getHydrophoneOptionsIfExists(
      FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.HYDROPHONE_DATA_ACQUISITION_ID,
      parsedOptions
    );
    const hydrophoneDataDiversion = ToolboxUtil.getHydrophoneOptionsIfExists(
      FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.HYDROPHONE_DATA_DIVERSION_ID,
      parsedOptions
    );
    const hydrophoneChannel = ToolboxUtil.getHydrophoneOptionsIfExists(
      FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.HYDROPHONE_CHANNEL_ID,
      parsedOptions
    );
    const spectrogramFrequencyUpperLimit =
      ToolboxUtil.getHydrophoneOptionsIfExists(
        FORMFIELD_IDS_OF_DATA_PRODUCT_OPTIONS_HYDROPHONE.SPECTROGRAM_FREQUENCY_UPPER_LIMIT_ID,
        parsedOptions
      );
    return {
      colourPalette,
      upperColourLimit,
      lowerColourLimit,
      spectrogramSource,
      hydrophoneDataAcquisition,
      hydrophoneDataDiversion,
      hydrophoneChannel,
      spectrogramFrequencyUpperLimit,
    };
  }

  static getHydrophoneOptionsIfExists(formFieldId, parsedOptions) {
    const hydrophoneOption = parsedOptions.find(
      (option) => option.formFieldId === formFieldId
    );
    if (hydrophoneOption) {
      return ToolboxUtil.generateInitialValue(hydrophoneOption.labelValueArray);
    }
    return undefined;
  }
}
export default ToolboxUtil;
