import { Link } from 'react-router-dom';
import { Public, Group } from '@onc/icons';
import { Tooltip, TypographyWithTranslation } from 'base-components';
import { ICON_STYLES } from 'domain/AppComponents/Dashboard/dashboard-table/DashboardTableConstants';
import type { DashboardLayout } from 'domain/AppComponents/Dashboard/dashboard-table/DashboardTableTypes';

type DashboardLinkProps = {
  layout: DashboardLayout;
  match: { path: string };
};

const getShared = (layout: DashboardLayout) => {
  if (!layout.isPublic) {
    if (
      layout.sharedAccounts.length === 0 &&
      layout.adminAccounts.length === 0
    ) {
      return null;
    }
    return (
      <Tooltip title="Shared with Others">
        <Group fontSize="small" style={ICON_STYLES.icons} color="disabled" />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Public">
      <Public fontSize="small" style={ICON_STYLES.icons} color="disabled" />
    </Tooltip>
  );
};

const DashboardLink: React.FC<DashboardLinkProps> = ({
  layout,
  match,
}: DashboardLinkProps) => {
  if (layout === null) {
    return null;
  }

  const shared = getShared(layout);

  if (layout && layout.layoutName && layout.layoutId) {
    return (
      <Link to={`${match.path}/id/${layout.layoutId}`}>
        {layout.layoutName}
        {shared}
      </Link>
    );
  }
  return (
    <TypographyWithTranslation
      translationKey="common.textfields.variable"
      translationOptions={{ variableName: layout.layoutName }}
    />
  );
};

export default DashboardLink;
