import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withCircularProgress } from 'base-components';
import ResizingInfoList from 'library/CompositeComponents/list-container/ResizingInfoList';
import InfoItemDate from 'library/CompositeComponents/list-items/InfoItemDate';
import InfoItemScrollable from 'library/CompositeComponents/list-items/InfoItemScrollable';
import InfoItemWithIcon from 'library/CompositeComponents/list-items/InfoItemWithIcon';
import InfoItemWithLink from 'library/CompositeComponents/list-items/InfoItemWithLink';

import OrganizationLogo from 'library/CompositeComponents/logo/OrganizationLogo';

class ExpeditionDetailsTemplate extends PureComponent {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    expeditionManagementLink: PropTypes.string,
    expeditionDetails: PropTypes.shape({
      cruiseName: PropTypes.string,
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      cruiseDescription: PropTypes.string,
    }),
  };

  static defaultProps = {
    expeditionManagementLink: '',
    expeditionDetails: undefined,
  };

  render() {
    const { organizationId, expeditionDetails, expeditionManagementLink } =
      this.props;
    if (!expeditionDetails) {
      return null;
    }
    return (
      <ResizingInfoList
        headerContent={
          <InfoItemWithIcon>
            <OrganizationLogo id={organizationId} />
            <InfoItemWithLink
              primary="Expedition"
              linkText={expeditionDetails.cruiseName}
              linkURL={expeditionManagementLink}
            />
          </InfoItemWithIcon>
        }
      >
        <InfoItemDate primary="Start Date" date={expeditionDetails.startDate} />
        <InfoItemDate primary="End Date" date={expeditionDetails.endDate} />
        <InfoItemScrollable
          primary="Expedition Description"
          secondary={expeditionDetails.cruiseDescription}
        />
      </ResizingInfoList>
    );
  }
}

export default withCircularProgress(ExpeditionDetailsTemplate);
