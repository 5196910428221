import { useEffect } from 'react';
import * as React from 'react';
import { TableColumnReordering } from '@devexpress/dx-react-grid-material-ui';

export interface ReorderingProps {
  columnOrder: string[];
  handleColumnOrder?: (nextOrder: string[]) => void;
  frozenLeftColumns?: string[];
  frozenRightColumns?: string[];
}

const ColumnReordering: React.VFC<ReorderingProps> = ({
  columnOrder,
  handleColumnOrder = undefined,
  frozenLeftColumns = [],
  frozenRightColumns = [],
}) => {
  const handleFrozenColumns = (newColumnOrder: string[]) => {
    frozenLeftColumns.forEach((column) => {
      const index = newColumnOrder.indexOf(column);
      if (index !== -1) {
        newColumnOrder.splice(index, 1);
        newColumnOrder.unshift(column);
      }
    });
    frozenRightColumns.forEach((column) => {
      const index = newColumnOrder.indexOf(column);
      if (index !== -1) {
        newColumnOrder.splice(index, 1);
        newColumnOrder.push(column);
      }
    });

    handleColumnOrder(newColumnOrder);
  };

  useEffect(() => {
    if (handleColumnOrder) {
      handleFrozenColumns(columnOrder);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (handleColumnOrder) {
    return (
      <TableColumnReordering
        order={columnOrder}
        onOrderChange={handleFrozenColumns}
      />
    );
  }

  return <TableColumnReordering defaultOrder={columnOrder} />;
};

export default ColumnReordering;
