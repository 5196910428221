import { createStyles, makeStyles } from '@mui/styles';
import { List, ListItem } from 'base-components';
import GroupedDataSourcesDisplay from 'domain/AppComponents/Dashboard/chart-widget/GroupedDataSourcesDisplay';
import {
  isDeploymentDataSource,
  isLocationDataSource,
} from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';
import { getDeploymentLabel } from './util/ChartWidgetUtil';
import type { DataSource } from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';

type DataSourceListProps = {
  sources: DataSource[];
  removeDataSource: (location: DataSource) => void;
  editDataSource: (location: DataSource) => void;
  putDataSource: (location: DataSource) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    dataSourceDisplay: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 0,
    },
  })
);

const DataSourceList: React.FC<DataSourceListProps> = ({
  sources,
  removeDataSource,
  editDataSource,
  putDataSource,
}: DataSourceListProps) => {
  const classes = useStyles();
  const groupedSources: Record<string, DataSource[]> = sources.reduce<
    Record<string, DataSource[]>
  >(
    (acc, source) => {
      if (isLocationDataSource(source)) {
        const pathName = source.pathName.slice(0, -2).join(' / ');
        if (!acc[pathName]) {
          acc[pathName] = [];
        }
        acc[pathName].push(source);
      } else if (isDeploymentDataSource(source)) {
        const deviceName = getDeploymentLabel(source);

        if (!deviceName) return acc;
        if (!acc[deviceName]) {
          acc[deviceName] = [];
        }
        acc[deviceName].push(source);
      }
      return acc;
    },
    {} as Record<string, DataSource[]>
  );

  return (
    <List>
      {Object.entries(groupedSources).map(([name, dataSources]) => (
        <ListItem key={name} className={classes.dataSourceDisplay}>
          <GroupedDataSourcesDisplay
            pathName={name}
            dataSources={dataSources}
            removeDataSource={removeDataSource}
            editDataSource={editDataSource}
            putDataSource={putDataSource}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default DataSourceList;
