import {
  createContext,
  FC,
  useState,
  useMemo,
  useCallback,
  ReactNode,
} from 'react';
import { SiteDeviceSubsetRow } from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchTypes';

type SiteDeviceSubsetRowContextValue = {
  siteDeviceSubsetRows: SiteDeviceSubsetRow[];
  updateSiteDeviceSubsetRows: (
    newSiteDeviceSubsetRows:
      | SiteDeviceSubsetRow[]
      | ((
          prevSiteDeviceSubsetRows: SiteDeviceSubsetRow[]
        ) => SiteDeviceSubsetRow[])
  ) => void;
};
const SiteDeviceSubsetRowsContext = createContext<
  SiteDeviceSubsetRowContextValue | undefined
>(undefined);

type SiteDeviceSubsetRowsProviderProps = {
  children?: ReactNode;
};

export const SiteDeviceSubsetRowsProvider: FC<
  SiteDeviceSubsetRowsProviderProps
> = ({ children = undefined }) => {
  const [siteDeviceSubsetRows, setSiteDeviceSubsetRows] = useState<
    SiteDeviceSubsetRow[]
  >([]);

  const updateSiteDeviceSubsetRows = useCallback((newSiteDeviceSubsetRows) => {
    if (typeof newSiteDeviceSubsetRows === 'function') {
      setSiteDeviceSubsetRows((prev) => newSiteDeviceSubsetRows(prev));
    } else {
      setSiteDeviceSubsetRows(newSiteDeviceSubsetRows);
    }
  }, []);

  const contextValue = useMemo(
    () => ({ siteDeviceSubsetRows, updateSiteDeviceSubsetRows }),
    [siteDeviceSubsetRows, updateSiteDeviceSubsetRows]
  );

  return (
    <SiteDeviceSubsetRowsContext.Provider value={contextValue}>
      {children}
    </SiteDeviceSubsetRowsContext.Provider>
  );
};

export default SiteDeviceSubsetRowsContext;
