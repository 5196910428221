import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import { TestLevel } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';

class QaqcDetailsTestLevelDropdown extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    permission: PropTypes.string.isRequired,
    testLevel: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
    disabled: false,
    initialValue: 0,
    testLevel: '',
    value: 0,
  };

  state = {
    testLevels: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    const { testLevel, value } = this.props;

    this.getTestLevels();
    if (testLevel !== '' && value === 0) {
      this.setValueFromTestLevel();
    }
  }

  componentDidUpdate(prevProps) {
    const { testLevel, value } = this.props;
    if (testLevel !== '' && value === 0 && testLevel !== prevProps.testLevel) {
      this.setValueFromTestLevel();
    }
  }

  getTestLevels = () => {
    QaqcTestDetailsService.getQAQCTestLevels()
      .then((results) => {
        const testLevels = results.map((testLevel, index) => ({
          label: testLevel,
          value: index + 1,
        }));
        this.setState({
          testLevels: [{ label: 'Select', value: 0 }, ...testLevels],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  handleChange = (event) => {
    const { onChange } = this.props;
    const { testLevels } = this.state;
    const selectedTestLevel = testLevels.find(
      (x) => x.value === event.target.value
    );
    onChange(
      event.target.value,
      selectedTestLevel ? selectedTestLevel.label : ''
    );
  };

  setValueFromTestLevel = () => {
    const { testLevel, onChange } = this.props;
    const { testLevels } = this.state;

    let selectedTestLevel = testLevels.find((x) => x.label === testLevel);

    // getQAQCTestLevels doesn't return observatory or studyArea so it has to be added if it's sent
    if (!selectedTestLevel) {
      selectedTestLevel = { label: testLevel, value: -1 };
      this.setState({
        testLevels: [...testLevels, selectedTestLevel],
      });
    }
    onChange(selectedTestLevel.value, testLevel);
  };

  render() {
    const {
      classes,
      disabled,
      initialValue,
      permission,
      testLevel,
      value,
      onChange,
      ...rest
    } = this.props;
    const { testLevels } = this.state;

    if (permission === 'RW') {
      return (
        <Dropdown
          disabled={disabled}
          initialValue={initialValue}
          label="Test Level"
          options={testLevels}
          value={value}
          onChange={(event) => this.handleChange(event)}
          {...rest}
        />
      );
    }

    return (
      <TestLevel
        classes={{ root: classes.roFieldRoot }}
        testLevel={testLevel}
      />
    );
  }
}
export default QaqcDetailsTestLevelDropdown;
