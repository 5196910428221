/* eslint-disable no-underscore-dangle */
/* Ignoring underscores because we are overriding Leaflet things that use them. */

import { createRef, PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Polygon } from 'react-leaflet';
import { DrawControl } from 'base-components';

import { CancelConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  SaveButton,
  CancelButton,
} from 'library/CompositeComponents/button/Buttons';
import SimpleMap from 'library/CompositeComponents/map/SimpleMap';
import TextParsingUtils from 'util/TextParsingUtils';

const STYLES = {
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
  },
};

const mapId = 'site-device-subset-geospatial-area-map';

class SiteDeviceSubsetGeospatialArea extends PureComponent {
  map = createRef();

  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    width: PropTypes.string,
    geospatialAreaWKT: PropTypes.string,
    classes: PropTypes.shape({
      formButtons: PropTypes.string,
    }),
  };

  static defaultProps = {
    width: '100%',
    geospatialAreaWKT: '',
    classes: undefined,
  };

  state = {
    geospatialArea: [[]],
    geospatialAreaWKT: '',
    isDrawing: false,
    openConfirmationDialog: false,
  };

  componentDidMount() {
    const { geospatialAreaWKT } = this.props;
    const result = TextParsingUtils.parseWktPolygonString(geospatialAreaWKT);
    if (!result.error)
      this.setState({
        geospatialArea: result.positions,
      });
  }

  updateDrawingState = (isDrawing) => {
    this.setState({ isDrawing });
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    const { isDrawing } = this.state;
    this.setState({ openConfirmationDialog: false });
    onCancel(isDrawing);
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { geospatialAreaWKT } = this.state;
    onSave(geospatialAreaWKT);
  };

  handleLayerChange = (layer) => {
    let newGeospatialAreaWKT;
    if (!layer) {
      newGeospatialAreaWKT = '';
    } else {
      const latLngs = layer.getLatLngs();
      newGeospatialAreaWKT = TextParsingUtils.parseLatLngsToWKT(latLngs);
    }

    this.setState({
      geospatialAreaWKT: newGeospatialAreaWKT,
    });
  };

  renderGeospatialArea = () => {
    const { geospatialArea } = this.state;

    if (geospatialArea && geospatialArea[0] && geospatialArea[0][0]) {
      return <Polygon positions={geospatialArea} />;
    }
    return null;
  };

  cancelConfirmationDialog = () => {
    const { openConfirmationDialog } = this.state;
    if (!openConfirmationDialog) {
      return null;
    }
    return (
      <CancelConfirmationDialog
        open
        title="Cancelling Map Drawing"
        content="You are in the middle of a drawing! Are you sure you want to cancel?"
        onCancel={this.handleCancel}
        onGoBack={this.handleGoBack}
      />
    );
  };

  handleGoBack = () => {
    this.setState({
      openConfirmationDialog: false,
    });
  };

  handleCancelClick = () => {
    const { isDrawing } = this.state;
    if (isDrawing) {
      this.setState({
        openConfirmationDialog: true,
      });
    } else {
      this.handleCancel();
    }
  };

  render() {
    const { width, classes } = this.props;

    return (
      <>
        {this.cancelConfirmationDialog()}
        <SimpleMap
          width={width}
          ref={this.map}
          mapId={mapId}
          initialZoom={4}
          center={[50, -125]}
        >
          {this.renderGeospatialArea()}
          <DrawControl
            mapId={mapId}
            handleLayerChange={this.handleLayerChange}
            updateDrawingState={this.updateDrawingState}
          />
        </SimpleMap>
        <div className={classes.formButtons}>
          <SaveButton onClick={this.handleSave} />
          <CancelButton onClick={this.handleCancelClick} />
        </div>
      </>
    );
  }
}
export default withStyles(STYLES)(SiteDeviceSubsetGeospatialArea);
