import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Divider, HelpLink, Typography } from 'base-components';

import Panel from 'library/CompositeComponents/panel/Panel';

class SeaTubeVideoPanel extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    menu: PropTypes.node,
  };

  static defaultProps = {
    menu: undefined,
  };

  renderMenu = () => {
    const { menu } = this.props;
    return (
      <div>
        <HelpLink url="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help#SeaTubeV3Help-VideoWidget" />
        <Divider />
        {menu}
      </div>
    );
  };

  render() {
    const { children } = this.props;

    return (
      <Panel
        title={<Typography variant="body1">Video</Typography>}
        headerDraggable
        enableOverflow
        menu={this.renderMenu()}
        menuProps={{
          buttonLabel: 'Video Menu',
        }}
      >
        {children}
      </Panel>
    );
  }
}

export default SeaTubeVideoPanel;
