/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from 'react';

import * as React from 'react';
import { makeStyles } from '@mui/styles';
import L, { LatLng } from 'leaflet';

import SelectedPin from 'assets/icon/map/annotationPin.png';
import RovPin from 'assets/icon/map/rovPin.png';
import {
  CircleMarker,
  LayerGroup,
  Marker,
  MapContext,
  LeafletTooltip as Tooltip,
  Polyline,
} from 'base-components';
import ONCMap, { ONCMapProps } from 'domain/AppComponents/map/ONCMap';

import CookieUtils from 'util/CookieUtils';
import DateFormatUtils from 'util/DateFormatUtils';

import { useSnackbars } from 'util/hooks/useSnackbars';
import MapUtils from 'util/MapUtils';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

interface DivePathPoint {
  lat: number;
  lon: number;
  timestamp: number | string;
}

const mapId = 'seatube-map-id';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
  },
}));

const createMapIcon = (iconUrl: string) =>
  L.icon({
    iconUrl,
    iconSize: [30, 40],
    iconAnchor: [15, 39],
  });

const MapIconRov = createMapIcon(RovPin);
const MapIconSelected = createMapIcon(SelectedPin);

const BASE_LAYER_COOKIE = 'seatube-map-base-layer';
const OVERLAYS_COOKIE = 'seatube-map-overlay-layers';

interface SeaTubeMapProps extends ONCMapProps {
  diveId?: string | number;
  annotations?: Array<Record<string, any>>;
  selectedAnnotationId?: number;
  currentTimestamp?: Date;
  onDivePathClick?: (timestamp: string | number) => void;
  onAnnotationClick?: (
    annotation: { annotationId: number } & Record<string, any>
  ) => void;
  height: number;
  width: number;
}

const SeaTubeMap: React.VFC<SeaTubeMapProps> = ({
  currentTimestamp = undefined,
  diveId = undefined,
  annotations = undefined,
  selectedAnnotationId = undefined,
  onAnnotationClick = undefined,
  onDivePathClick = undefined,
  height,
  width,
  ...props
}: SeaTubeMapProps) => {
  const classes = useStyles();
  const { onError } = useSnackbars();

  const mapContext = useContext(MapContext);

  /**
   * Find the object with the closest timestamp
   *
   * @param {any} divePath
   * @param {any} timestamp
   * @returns {number} { Index into dive path }
   */
  const findIndexInPathByTimestamp = (divePath, timestamp) => {
    const timestamps = divePath.map((point) => point.timestamp);
    return MapUtils.findIndexOfTimestamp(
      timestamps,
      timestamp,
      0,
      timestamps.length - 1
    );
  };

  const getDefaultLayerName = () =>
    CookieUtils.getCookie(BASE_LAYER_COOKIE) || 'GMRT';

  const handleBaseLayerChange = (e) =>
    CookieUtils.saveCookie(BASE_LAYER_COOKIE, e.name);

  const line = useRef<null | any>(null);

  const [divePath, setDivePath] = React.useState<DivePathPoint[]>(undefined);
  const [index, setIndex] = React.useState<number>(0);
  const [defaultOverlays, setDefaultOverlays] = React.useState<undefined | any>(
    CookieUtils.getCookie(OVERLAYS_COOKIE) || ['Dive Annotations', 'Dive Path']
  );
  const [overlayLayers, setOverlayLayers] = React.useState<undefined | any>(
    undefined
  );
  const [annotationConfig, setAnnotationConfig] = React.useState<
    undefined | any
  >(undefined);

  /** Fit the map to the dive path when it is loaded */
  useEffect(() => {
    if (mapContext?.mapRef?.current && divePath) {
      const latlngs = divePath.map((point) => [point.lat, point.lon]);
      mapContext?.mapRef?.current?.fitBounds(latlngs);
    }
  }, [mapContext.mapRef.current, divePath]);

  /** Get the dive path when the dive id changes */
  useEffect(() => {
    const getDivePaths = async () => {
      if (!diveId) return;
      try {
        const payload = await get('seatube/maps', {
          diveId,
        }).then((response) => parseDmasAPIResponse(response));
        const { positionalData } = payload;
        const newDivePath = positionalData
          .filter(
            (point) => point.latitude && point.longitude && point.timeStamp
          )
          .map((point) => ({
            lat: point.latitude,
            lon: point.longitude,
            timestamp: point.timeStamp,
          }));
        if (newDivePath.length > 0) {
          setDivePath(newDivePath);
        } else throw new Error('No positional data found for dive.');
      } catch (error: any) {
        onError(error.message);
      }
    };

    if (diveId) {
      getDivePaths();
    }
  }, [diveId]);

  /** Update the dive path index when the current timestamp changes */
  useEffect(() => {
    if (divePath && currentTimestamp) {
      const newIndex = findIndexInPathByTimestamp(divePath, currentTimestamp);
      if (index !== newIndex) {
        setIndex(newIndex);
      }
    }
  }, [currentTimestamp, divePath, index]);

  /** Update the dive path index when the selected annotation changes */
  const handleDivePathClicked = (event) => {
    const { latlng } = event;
    if (!onDivePathClick) return;
    const latlngs = divePath.map((pathPoint) => [pathPoint.lat, pathPoint.lon]);
    const closest = MapUtils.closest(latlngs, [latlng.lat, latlng.lng]);
    onDivePathClick(divePath[closest.index].timestamp);
  };

  /** Handle the annotation click event */
  const handleAnnotationClick = (marker) => {
    if (!onAnnotationClick) return;
    const { markerId, ...rest } = marker;
    const annotation = { annotationId: markerId, ...rest };
    onAnnotationClick(annotation);
  };

  /** Generate the current time marker */
  const generateCurrentTimeMarker = () => {
    if (!divePath || index >= divePath.length || index < 0) {
      return undefined;
    }
    const point = divePath[index];
    return (
      <Marker
        title="Current Location"
        position={[point.lat, point.lon]}
        icon={MapIconRov}
        zIndexOffset={900}
      >
        <Tooltip permanent={false} direction="center">
          Current Location
        </Tooltip>
      </Marker>
    );
  };

  const generatePositionalMarkers = () =>
    divePath.map((point) => (
      <CircleMarker
        key={point.timestamp}
        center={[point.lat, point.lon]}
        color="red"
        fillOpacity={0}
        stroke={false}
        radius={2}
        eventHandlers={{
          click: handleDivePathClicked,
        }}
      >
        <Tooltip>{DateFormatUtils.formatDate(point.timestamp, 'full')}</Tooltip>
      </CircleMarker>
    ));

  const generateDivePath = () => {
    if (!divePath) return null;
    const latlngs = divePath.map((point) => new LatLng(point.lat, point.lon));
    const currentTimeMarker = generateCurrentTimeMarker();
    const positionalMarkers = generatePositionalMarkers();
    const subLayers = (
      <LayerGroup>
        <Polyline
          positions={latlngs}
          ref={line}
          color="red"
          opacity={0.7}
          eventHandlers={{
            click: handleDivePathClicked,
          }}
        />
        {currentTimeMarker}
        {positionalMarkers}
      </LayerGroup>
    );
    return {
      name: 'Dive Path',
      subLayers,
      checked: defaultOverlays.includes('Dive Path'),
    };
  };

  const buildOverlays = () => {
    const overlayLayer = [];
    const newDivePath = generateDivePath();
    if (newDivePath) overlayLayer.push(newDivePath);
    return overlayLayer;
  };

  const buildAnnotationConfig = () => {
    if (!annotations) return undefined;
    return {
      markers: annotations
        .filter((annotation) => annotation.lat && annotation.lon)
        .map((annotation) => {
          const { annotationId, ...rest } = annotation;
          return {
            markerId: annotation.annotationId,
            ...rest,
          };
        }),
      onClick: handleAnnotationClick,
      name: 'Dive Annotations',
      checked: defaultOverlays.includes('Dive Annotations'),
      condenseSameDateLocation: true,
    };
  };

  const handleOverlayAdd = (e: any) => {
    const updatedOverlays = [...defaultOverlays];
    if (!updatedOverlays.includes(e.name)) {
      updatedOverlays.push(e.name);
      setDefaultOverlays(updatedOverlays);
      CookieUtils.saveCookie(OVERLAYS_COOKIE, updatedOverlays);
    }
  };

  const handleOverlayRemove = (e: any) => {
    let updatedOverlays = [...defaultOverlays];
    updatedOverlays = updatedOverlays.filter((value) => value !== e.name);
    setDefaultOverlays(updatedOverlays);
    CookieUtils.saveCookie(OVERLAYS_COOKIE, updatedOverlays);
  };

  useEffect(() => {
    setOverlayLayers(buildOverlays());
    setAnnotationConfig(buildAnnotationConfig());
  }, [
    divePath,
    annotations,
    defaultOverlays,
    selectedAnnotationId,
    currentTimestamp,
  ]);

  if (!height || !width) return null;

  return (
    <div className={classes.root}>
      <ONCMap
        mapId={mapId}
        zoomControl={{ position: 'topleft' }}
        layersControl={{ position: 'topright' }}
        bathymetry
        overlayLayers={overlayLayers}
        markerConfig={annotationConfig}
        selectedMarkerId={selectedAnnotationId}
        selectedMarkerIcon={MapIconSelected}
        maxZoom={24}
        onBaseLayerChange={handleBaseLayerChange}
        onOverlaySelect={handleOverlayAdd}
        onOverlayRemove={handleOverlayRemove}
        defaultLayerName={getDefaultLayerName()}
        defaultOverlays={defaultOverlays}
        height={height}
        width={width}
        {...props}
      />
    </div>
  );
};

const value = { mapRef: React.createRef(), mapId };
const SeaTubeMapProvider = (props) => (
  <MapContext.Provider value={value}>
    <SeaTubeMap {...props} />
  </MapContext.Provider>
);

export default SeaTubeMapProvider;
