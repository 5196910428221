import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import DeviceActionService from 'domain/services/DeviceActionService';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateFormatUtils from 'util/DateFormatUtils';
import DeviceActionsParameters from './DeviceActionsParameters';
import DeviceActionsTable from './DeviceActionsTable';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
});

const defaultState = {
  deviceActions: [],
  searchActive: false,
};

class DeviceActionsPage extends PureComponent {
  static propTypes = {
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  filterBools = (value) => (value ? 1 : -1);

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  handleFind = (values) => {
    const { onError } = this.props;
    const params = { ...values };
    this.setState({ searchActive: true, deviceActions: [] });

    params.Maintenance = this.filterBools(params.Maintenance);
    params.Repair = this.filterBools(params.Repair);
    params.Status = this.filterBools(params.Status);
    params.Cruise = this.filterBools(params.Cruise);
    params['Non-Cruise'] = this.filterBools(params['Non-Cruise']);
    params.Latest = this.filterBools(params.Latest);
    params.dateFrom = DateFormatUtils.formatDate(
      params.dateFrom,
      'ISO8601_EXTENDED'
    );
    params.dateTo = DateFormatUtils.formatDate(
      params.dateTo,
      'ISO8601_EXTENDED'
    );

    DeviceActionService.getFiltered(params)
      .then((result) => {
        this.setState({ deviceActions: result, searchActive: false });
      })
      .catch((result) => {
        this.setState({ searchActive: false });
        onError(result.message);
      });
  };

  render() {
    const { classes, onInfo, onError } = this.props;
    const { deviceActions, searchActive } = this.state;

    return (
      <div>
        <Panel classes={classes}>
          <DeviceActionsParameters
            onClear={this.handleClear}
            onFind={this.handleFind}
            onInfo={onInfo}
            onError={onError}
          />
        </Panel>
        <Panel classes={classes}>
          <DeviceActionsTable
            deviceActions={deviceActions}
            searchActive={searchActive}
          />
        </Panel>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(DeviceActionsPage));
