import { memo } from 'react';
import * as React from 'react';
import MaterialDialog, {
  DialogProps as MaterialDialogProps,
} from '@mui/material/Dialog';

export type DialogProps = MaterialDialogProps;
const Dialog: React.VFC<DialogProps> = (props: DialogProps) => (
  <MaterialDialog {...props} />
);
export default memo(Dialog);
