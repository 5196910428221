import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Tooltip from '../Tooltip';

export interface QuickButtonAttribute {
  attributeValues?: { label: string; value: string | number }[];
  attributeValue?: string | number | null;
  dataType: string;
  modifyBy: object;
  modifyDate: string;
  taxonButtonSetLineId: number;
  taxonomyAttributeId: number;
  taxonomyAttributeLineId?: number;
}

export interface QuickEntryButton {
  attributes?: QuickButtonAttribute[];
  colour: string;
  comment?: string;
  label: string;
  sequenceNumber: number;
  taxonButtonSetLineId?: number;
  taxonId?: number;
  taxonName?: string;
  taxonomyId?: number;
  taxonomyName?: string;
  quickSave?: boolean;
}

export type QuickButtonProps = {
  onClick: () => void;
  buttonData: QuickEntryButton;
  disableEmptyButtons: boolean;
  // isSelected is used in the styling. It is not used in the component itself.
  // eslint-disable-next-line react/no-unused-prop-types
  isSelected: boolean;
  showIndexes: boolean;
};

/* Based of this algoritm: https://24ways.org/2010/calculating-color-contrast */
const getContrastColor = (color) => {
  const col = color.replace('#', '');
  const r = parseInt(col.substr(0, 2), 16);
  const g = parseInt(col.substr(2, 2), 16);
  const b = parseInt(col.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 148 ? 'black' : 'white';
};

const useStyles = makeStyles<Theme, QuickButtonProps>((theme) => ({
  button: {
    minHeight: theme.spacing(4),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    justifyContent: 'left',
    margin: theme.spacing(0.25),
    borderColor: (props: QuickButtonProps) =>
      props.disableEmptyButtons
        ? `${props.buttonData.colour} !important`
        : '#DDDDDD',
    backgroundColor: (props: QuickButtonProps) =>
      props.isSelected
        ? props.buttonData.colour.concat('E6')
        : props.buttonData.colour.concat('59'),
    color: (props: QuickButtonProps) =>
      props.isSelected
        ? getContrastColor(props.buttonData.colour)
        : 'rgba(0,0,0,0.5)',
    textTransform: 'none',
    '&:hover': {
      filter: 'brightness(80%) !important',
      borderColor: (props: QuickButtonProps) =>
        `${props.buttonData.colour} !important`,
      backgroundColor: (props: QuickButtonProps) =>
        props.isSelected
          ? props.buttonData.colour.concat('E6')
          : props.buttonData.colour.concat('59'),
    },
  },
}));

/**
 * Quick Button is a custom style of button meant for use in Taxonomy Button
 * sets. It should not be used outside of that application.
 */
const QuickButton: React.FC<QuickButtonProps> = (props: QuickButtonProps) => {
  const { onClick, buttonData, disableEmptyButtons, showIndexes } = props;
  const classes = useStyles(props);

  const renderButtonText = () => {
    if (buttonData.label === '') {
      return null;
    }
    if (showIndexes) {
      // Using \u00A0 instead of &nbsp as &nsbp isn't rendered correctly here
      return `${buttonData.sequenceNumber}${'\u00A0\u00A0'}${buttonData.label}`;
    }
    return buttonData.label;
  };

  if (disableEmptyButtons && !buttonData.label) {
    return null;
  }

  return (
    <Tooltip title={buttonData.label} placement="top" followCursor>
      <Button
        onClick={onClick}
        disabled={!buttonData.label && disableEmptyButtons}
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        value={buttonData.sequenceNumber}
      >
        <Typography variant="button" align="left" noWrap>
          {renderButtonText()}
        </Typography>
      </Button>
    </Tooltip>
  );
};
export default QuickButton;
