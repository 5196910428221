import FIXED_CAMERA_APP_TOKEN from 'domain/Apps/seatube/SeaTubeAppTokens';
import FixedCameraDetailsWidget from 'domain/Widgets/fixed-camera/FixedCameraDetailsWidget';
import FixedCameraLocationTreeWidget from 'domain/Widgets/fixed-camera/FixedCameraLocationTreeWidget';
import FixedCameraMapWidget from 'domain/Widgets/fixed-camera/FixedCameraMapWidget';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import { WidgetOption } from 'library/CompositeComponents/dashboard/DashboardTypes';
import Environment from 'util/Environment';
import locationsLayout from './layouts/locationsLayout';
import SeaTubeDashboard from '../SeaTubeDashboard';

const FixedCameraLocationDashboard = () => {
  Environment.setCurrentApplicationToken(FIXED_CAMERA_APP_TOKEN);
  const widgets: WidgetOption[] = [
    {
      Component: FixedCameraMapWidget,
      label: 'Map',
      multiple: false,
    },
    {
      Component: FixedCameraLocationTreeWidget,
      label: 'Location Tree',
      multiple: false,
    },
    {
      Component: FixedCameraDetailsWidget,
      label: 'Details',
      multiple: false,
    },
  ];

  return (
    <SeaTubeDashboard
      id="fixed-camera-locations"
      title="Fixed Video Camera Locations"
      layoutKey="fixed-camera"
      widgetLibrary={new WidgetLibrary(widgets)}
      isStatic
      defaultLayout={locationsLayout}
    />
  );
};

export default FixedCameraLocationDashboard;
