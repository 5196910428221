import { useState, useEffect } from 'react';
import PropertiesWebServiceWithToken from 'domain/services/PropertiesWebServiceWithToken';

function useFilteredProperties(deviceCategoryCode, deviceCode, locationCode) {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const propertyList = [];
    PropertiesWebServiceWithToken.get({
      method: 'get',
      deviceCategoryCode,
      deviceCode,
      locationCode,
    })
      .then((response) => {
        response.data.map((record) =>
          propertyList.push({
            description: record.description,
            propertyCode: record.propertyCode,
            name: record.propertyName,
          })
        );
        setProperties(propertyList);
      })
      // Return a empty list if no property data is found
      .catch(() => setProperties([]));
    return () => {
      setProperties([]);
    };
  }, [deviceCode, locationCode, deviceCategoryCode]);

  useEffect(() => () => {}, []);

  return properties;
}
export default useFilteredProperties;
