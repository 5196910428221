import PropTypes from 'prop-types';
import { CircularProgress } from 'base-components';
import ExportDownloadLink from 'domain/AppComponents/seatube/search/ExportDownloadLink';
import DataProductDeliveryService from 'domain/services/DataProductDeliveryService';

const ExportProgress = ({
  status = undefined,
  fileName = undefined,
  searchId = undefined,
}) => {
  switch (status) {
    case DataProductDeliveryService.Statuses.QUEUED:
      return 'Export Queued';
    case DataProductDeliveryService.Statuses.DATA_PRODUCT_RUNNING:
    case DataProductDeliveryService.Statuses.DATA_PRODUCT_COMPLETE:
    case DataProductDeliveryService.Statuses.METADATA_PRODUCT_RUNNING:
    case DataProductDeliveryService.Statuses.METADATA_PRODUCT_COMPLETE:
      return (
        <>
          Generating Files
          <CircularProgress size={20} variant="indeterminate" />
        </>
      );
    case DataProductDeliveryService.Statuses.COMPLETE:
      return <ExportDownloadLink fileName={fileName} searchId={searchId} />;
    default:
      return '';
  }
};

ExportProgress.propTypes = {
  fileName: PropTypes.string,
  searchId: PropTypes.number,
  status: PropTypes.string,
};

export default ExportProgress;
