import { ReactElement, memo } from 'react';
import * as React from 'react';
import ResizingInfoList from 'library/CompositeComponents/list-container/ResizingInfoList';

interface Props {
  headerContent?: ReactElement;
  contents: ReactElement[]; // intended for use with InfoItems, but could be anything
}

const DetailsTemplate: React.VFC<Props> = ({
  headerContent = null,
  contents,
}) => (
  <ResizingInfoList headerContent={headerContent}>
    {contents.map((content) => content)}
  </ResizingInfoList>
);

export default memo(DetailsTemplate);
