import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { Grid, Typography } from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  EditIconButton,
  DeleteIconButton,
  RefreshButton,
} from 'domain/AppComponents/IconButtons';
import ExtensionDetailService from 'domain/services/ExtensionDetailService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';

import DateFormatUtils from 'util/DateFormatUtils';
import ExtensionDetailConfig from './ExtensionDetailConfig';

const headers = [
  { title: 'Detail ID', name: 'extensionDetailId' },
  { title: 'Item Type', name: 'type' },
  { title: 'Item Description', name: 'description' },
  { title: 'Quantity', name: 'quantity' },
  { title: 'Notes', name: 'notes' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: ' ', name: 'icons' },
];

const tableColumnExtensions = [
  {
    columnName: 'extensionDetailId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'type',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'quantity',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'notes',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'icons',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

class ExtensionDetailsTable extends PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
    extensionDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    extensionId: PropTypes.number,
    disableAdd: PropTypes.bool.isRequired,
    extensionDetailIdParam: PropTypes.number,
    cancelDetailFromUrl: PropTypes.func.isRequired,
    hasChangedTabs: PropTypes.bool.isRequired,
    refreshTable: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    classes: PropTypes.shape({ addItemButton: PropTypes.string }).isRequired,
  };

  static defaultProps = {
    extensionDetailIdParam: undefined,
    extensionId: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
      openConfig: false,
      openDelete: false,
      filteredDevices: [],
      rowToBeDeleted: undefined,
      rowToBeEdited: undefined,
    };
  }

  componentDidMount() {
    const { extensionDetailIdParam, hasChangedTabs } = this.props;
    this.buildTableRows();
    if (extensionDetailIdParam && !hasChangedTabs) {
      this.setState({ openConfig: true });
    }
  }

  icons = (row) => {
    const { permission } = this.props;
    if (permission !== 'RW') {
      return null;
    }

    return (
      <>
        <EditIconButton
          onClick={() => {
            this.setState({
              openConfig: true,
              rowToBeEdited: row,
            });
          }}
          aria-label="Edit Detail"
          size="small"
        />
        <DeleteIconButton
          onClick={() => {
            this.setState({
              openDelete: true,
              rowToBeDeleted: row,
            });
          }}
          aria-label="Delete Detail"
          size="small"
        />
      </>
    );
  };

  handleCancel = () => {
    const { cancelDetailFromUrl } = this.props;
    cancelDetailFromUrl();
    this.setState({
      openConfig: false,
      openDelete: false,
      rowToBeEdited: undefined,
      rowToBeDeleted: undefined,
    });
  };

  addEventDialogue = () => {
    const { openConfig, rowToBeEdited } = this.state;
    const { permission, extensionDetailIdParam } = this.props;
    if (!openConfig) {
      return null;
    }
    const tableRows = this.buildTableRows();
    let detail = rowToBeEdited;
    if (extensionDetailIdParam) {
      [detail] = tableRows;
      if (
        detail === undefined ||
        detail.extensionDetailId !== extensionDetailIdParam
      ) {
        return null;
      }
    }
    return (
      <ExtensionDetailConfig
        permission={permission}
        title={detail ? 'Edit Detail' : 'Add New Detail'}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
        detail={detail}
      />
    );
  };

  deleteEventDialogue = () => {
    const { openDelete, rowToBeDeleted } = this.state;
    if (!openDelete) {
      return null;
    }
    return (
      <ConfirmationDialog
        open
        title="Delete Detail"
        content={`Are you sure you want to delete detail ${rowToBeDeleted.extensionDetailId}?`}
        onCancel={this.handleCancel}
        onConfirm={this.handleDelete}
      />
    );
  };

  actionContent = () => {
    const { permission, disableAdd, refreshTable, classes } = this.props;
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceActionRefreshButton = () => (
      <RefreshButton onClick={refreshTable} />
    );
    if (permission !== 'RW') {
      return <DeviceActionRefreshButton />;
    }
    return (
      <>
        <DeviceActionRefreshButton />
        <ContainedButton
          translationKey="cables.addDetail"
          startIcon={<Add />}
          className={classes.addItemButton}
          onClick={() => {
            this.setState({ openConfig: true });
          }}
          disabled={disableAdd}
        />
      </>
    );
  };

  // takes the result of the service call and modifies the returned data for display in the table
  buildTableRows = () => {
    const { extensionDetails } = this.props;

    const tableRows = extensionDetails.map((detail) => {
      const extensionForRow = { ...detail };
      extensionForRow.modifyDate = DateFormatUtils.formatDate(
        detail.modifyDate,
        'full'
      );
      extensionForRow.modifyBy = `${detail.modifyBy.firstname} ${detail.modifyBy.lastname}`;
      extensionForRow.type = detail.item.itemType.name;
      extensionForRow.description = detail.item.description;
      extensionForRow.itemId = detail.item.itemId;
      extensionForRow.icons = this.icons(extensionForRow);
      return extensionForRow;
    });
    return tableRows;
  };

  handleSave = (form) => {
    const { extensionId, onInfo, onError, refreshTable } = this.props;
    const paramsForPost = {
      extensionId,
      itemId: form.item,
      quantity: form.quantity,
      notes: form.notes,
    };

    if (form.detailId) {
      paramsForPost.extensionDetailId = form.detailId;
      ExtensionDetailService.update(paramsForPost)
        .then(() => {
          this.setState({ openConfig: false, rowToBeEdited: undefined });
          refreshTable();
          onInfo('Extension Detail updated!');
        })
        .catch((error) => onError(error));
    } else {
      ExtensionDetailService.create(paramsForPost)
        .then(() => {
          this.setState({ openConfig: false, rowToBeEdited: undefined });
          refreshTable();
          onInfo('Extension Detail created!');
        })
        .catch(() => onError('Failed to create Extension Detail'));
    }
  };

  handleDelete = async () => {
    const { onError, onInfo, refreshTable } = this.props;
    const { rowToBeDeleted } = this.state;
    await ExtensionDetailService.delete({
      extensionDetailId: rowToBeDeleted.extensionDetailId,
    })
      .then(() => {
        this.setState({ openDelete: false, rowToBeDeleted: false });
        refreshTable();
        onInfo('Deleted Successfully');
      })
      .catch((response) => {
        onError(response.message);
      });
  };

  render() {
    const { permission, isLoading } = this.props;
    const tableRows = this.buildTableRows();
    if (!tableRows) return undefined;

    return (
      <>
        {this.addEventDialogue()}
        {this.deleteEventDialogue()}
        <Grid container direction="row">
          <Panel
            title={<Typography variant="h6">Extension Details</Typography>}
            actionContent={this.actionContent()}
          >
            <SortableTable
              columns={headers}
              columnSizes={tableColumnExtensions}
              columnExtensions={tableColumnExtensions}
              elevation={0}
              pageSize={200}
              rows={tableRows}
              searchable
              searchBarMoveable={permission === 'RW'}
              stripedRows
              fullWidth
              isLoading={isLoading}
            />
          </Panel>
        </Grid>
      </>
    );
  }
}

export default ExtensionDetailsTable;
