import { Autocomplete } from 'base-components';
import useAllDevices from 'domain/hooks/useAllDevices';

type AllDevicesAutocompleteProps = {
  onError: (e: string) => void;
  onChange: (event: any, value: any) => void;
  multiple?: boolean;
  value: object;
};

const AllDevicesAutocomplete = ({
  onError,
  onChange,
  multiple = false,
  value,
}: AllDevicesAutocompleteProps) => {
  const devices = useAllDevices(onError);

  return (
    <Autocomplete
      multiple={multiple}
      options={devices}
      onChange={onChange}
      value={value}
      virtualized
      getOptionLabel={(device) => `${device.name} (${device.deviceId})`}
      translationKey="device.deviceSelection"
      name="deviceAutocomplete"
    />
  );
};

export default AllDevicesAutocomplete;
