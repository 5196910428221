import { ReactNode, useState } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { ExpandMore } from '@onc/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from 'base-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    details: {
      paddingTop: 0,
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    title: {
      marginLeft: theme.spacing(),
    },
    chip: {
      marginLeft: theme.spacing(),
      maxWidth: 200,
    },
  })
);

type Props = {
  name: string;
  title: string;
  initiallyExpanded?: boolean;
  children: ReactNode;
  className?: string;
};

const FormFieldEntrySection: React.VFC<Props> = ({
  title,
  name,
  initiallyExpanded = false,
  children,
  className = undefined,
}: Props) => {
  const classes = useStyles();
  const [expandedState, setExpandedState] = useState<boolean | undefined>(
    initiallyExpanded
  );

  return (
    <Accordion
      onChange={() => setExpandedState(!expandedState)}
      expanded={expandedState}
      className={className}
    >
      <AccordionSummary
        aria-label={name}
        expandIcon={<ExpandMore data-test="expand" name="expand" />}
      >
        <div className={classes.flexContainer}>
          <Typography className={classes.title}>{title}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default FormFieldEntrySection;
