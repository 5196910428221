/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Grouping } from '@devexpress/dx-react-grid';
import DateFormatter from 'library/BaseComponents/table/formatters/DateFormatter';
import StatelessTable, {
  TableColumn,
} from 'library/BaseComponents/table/StatelessTable';
import Environment from 'util/Environment';
import { useLocalStorage } from 'util/hooks/useStorage';
import { DeploymentTableRow } from './DeploymentsTableLogic';
import DeploymentsActionFormatter from './formatters/DeploymentsActionFormatter';

interface Props {
  rows: DeploymentTableRow[];
  onEdit: (row: DeploymentTableRow) => void;
}

const DeploymentsTable: React.FC<Props> = ({ rows, onEdit }: Props) => {
  const storageKey = 'deployments-table-hidden-columns';
  const defaultHidden = ['deploymentId', 'synchronizerId'];
  const alwaysVisible = ['synchronizerName', 'location', 'dateFrom', 'dateTo'];

  const columns: TableColumn[] = [
    { name: 'deploymentId', title: 'Deployment Id', dataType: 'Number' },
    { name: 'synchronizerId', title: 'Synchronizer Id', dataType: 'Number' },
    {
      name: 'synchronizerName',
      title: 'Synchronizer',
      dataType: 'String',
    },
    { name: 'organization', title: 'Organization', dataType: 'String' },
    { name: 'location', title: 'Location', dataType: 'String' },
    { name: 'dateFrom', title: 'Date From', dataType: 'Date' },
    { name: 'dateTo', title: 'Date To', dataType: 'Date' },
    { name: 'modifyBy', title: 'Modified By', dataType: 'String' },
    { name: 'modifyDate', title: 'Modified Date', dataType: 'Date' },
  ];

  const defaultConfig = {
    grouping: [],
    sorting: [{ columnName: 'startDate', direction: 'asc' }],
    hiddenColumnNames: defaultHidden,
  };

  const [config, setConfig] = useLocalStorage<any>(storageKey, defaultConfig);

  // Hidden Columns
  const [hiddenColumnNames, setHiddenColumns] = useState<string[]>(
    config.hiddenColumnNames
  );

  // Column Sorting
  const [sorting, setSorting] = useState(config.sorting);

  // Grouping
  // Only allow grouping on the organization column
  const groupingStateColumnExtensions = columns.map((column) => ({
    columnName: column.name,
    groupingEnabled: column.name === 'organization',
  }));

  const [grouping, setGrouping] = useState<Grouping[]>(config.grouping);

  const handleResetView = () => {
    setHiddenColumns(defaultConfig.hiddenColumnNames);
    setSorting(defaultConfig.sorting);
    setGrouping(defaultConfig.grouping);
  };

  useEffect(() => {
    const newConfig = {
      grouping,
      sorting,
      hiddenColumnNames,
    };
    setConfig(newConfig);
  }, [grouping, sorting, hiddenColumnNames, storageKey]);

  return (
    <StatelessTable
      rows={rows}
      columns={
        Environment.getDmasUserPrivilege() === 'RW'
          ? [
              ...columns,
              { name: 'actions', title: 'Actions', dataType: 'Other' },
            ]
          : columns
      }
      getRowId={(row) => row.deploymentId}
      onReset={handleResetView}
      sort={{
        sorting,
        columnExtensions: [{ columnName: 'actions', sortingEnabled: false }],
        customSorting: [
          {
            columnName: 'synchronizerName',
            compare: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
          },
        ],
        handleSortingChange: setSorting,
      }}
      group={{
        grouping,
        columnExtensions: groupingStateColumnExtensions,
        handleGroupingChange: setGrouping,
      }}
      columnExtensions={[{ columnName: 'actions', align: 'right' }]}
      fixed={{
        leftColumns: [],
        rightColumns: ['actions'],
      }}
      columnFormatProviders={[
        {
          name: 'DateFormatter',
          for: ['dateFrom', 'dateTo', 'modifyDate'],
          formatterComponent: ({ row, value }) =>
            DateFormatter({ row, value, selected: '' }),
        },
        {
          name: 'ActionFormatter',
          for: ['actions'],
          formatterComponent: ({ row }) =>
            DeploymentsActionFormatter({ row, onEdit }),
        },
      ]}
      visible={{
        hiddenColumnNames,
        handleChangeVisibility: setHiddenColumns,
        disableColumnNames: alwaysVisible,
      }}
    />
  );
};

export default DeploymentsTable;
