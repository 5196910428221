import {
  createContext,
  FC,
  useState,
  useMemo,
  useCallback,
  ReactNode,
} from 'react';
import {
  DataProductSelectionRow,
  SelectedStation,
} from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchTypes';

type SeletedStationContextValue = {
  selectedStations: SelectedStation[];
  updateSelectedStations: (
    newSelectedStations:
      | SelectedStation[]
      | ((prevSelectedstations: SelectedStation[]) => SelectedStation[])
  ) => void;
  getCheckboxStateArrayMapFromSelectedStations: (
    defaultValue: boolean
  ) => DataProductSelectionRow[];
};
const SelectedStationsContext = createContext<
  SeletedStationContextValue | undefined
>(undefined);

type SelectedStationsProviderProps = {
  initialValue?: SelectedStation[];
  children?: ReactNode;
};

export const SelectedStationsProvider: FC<SelectedStationsProviderProps> = ({
  children = undefined,
  initialValue = [],
}) => {
  const [selectedStations, setSelectedStations] =
    useState<SelectedStation[]>(initialValue);

  const updateSelectedStations = useCallback((newSelectedStations) => {
    if (typeof newSelectedStations === 'function') {
      setSelectedStations((prev) => newSelectedStations(prev));
    } else {
      const unrestrictedNewSelectedStation = newSelectedStations
        .map((station) => ({
          ...station,
          siteDeviceSubsets: station.siteDeviceSubsets.filter(
            (cast) => cast.accessRestrictionLevel === 'RW'
          ),
        }))
        .filter((station) => station.siteDeviceSubsets.length > 0);

      setSelectedStations(unrestrictedNewSelectedStation);
    }
  }, []);

  const getCheckboxStateArrayMapFromSelectedStations = useCallback(
    (defaultValue: boolean = false): DataProductSelectionRow[] => {
      const checkboxStateMap: DataProductSelectionRow[] = selectedStations
        .flatMap((station) => station.siteDeviceSubsets)
        .map((siteDeviceSubset) => ({
          siteDeviceSubsetId: siteDeviceSubset.siteDeviceSubsetId,
          csv: defaultValue,
          cor: defaultValue,
          plot: defaultValue,
          annotation: defaultValue,
          metadata: defaultValue,
        }));

      return checkboxStateMap;
    },
    [selectedStations]
  );

  const contextValue = useMemo(
    () => ({
      selectedStations,
      updateSelectedStations,
      getCheckboxStateArrayMapFromSelectedStations,
    }),
    [
      selectedStations,
      updateSelectedStations,
      getCheckboxStateArrayMapFromSelectedStations,
    ]
  );

  return (
    <SelectedStationsContext.Provider value={contextValue}>
      {children}
    </SelectedStationsContext.Provider>
  );
};

export default SelectedStationsContext;
