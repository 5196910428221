import { useContext, useEffect, useState } from 'react';
import { Grid, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { FormControlLabel, RadioButton, RadioGroup } from 'base-components';
import TaxonService from 'domain/services/TaxonService';
import { SaveButton } from 'library/CompositeComponents/button/Buttons';
import useWebService from 'util/hooks/useWebService';
import TaxonDetailsForm, { TaxonDetailsFormErrors } from './TaxonDetailsForm';
import { TaxonDetailsFormValidation } from './TaxonEditForm';
import TaxonImportForm, { ImportForm } from './TaxonImportForm';
import { TaxonomyContext } from './TaxonomyApp';
import { TaxonItem, TaxonomyItem } from '../../Widgets/TaxonomyTreeWidget';

type Props = {
  taxon: TaxonItem;
  taxonomies: TaxonomyItem[];
  nodeTypes: any[];
  onError: (message: string) => void;
  onInfo: (message: string) => void;
};

const TaxonAddForm = ({
  taxon,
  taxonomies,
  nodeTypes,
  onError,
  onInfo,
}: Props) => {
  const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const [taxonValue, setTaxonValue] = useState<TaxonItem>(taxon);
  const [importValue, setImportValue] = useState<ImportForm>({
    taxonomyId: 0,
    includeChildren: false,
    taxonChoice: null,
  });
  const [enabledForm, setEnabledForm] = useState<string>('Imported');
  const [errors, setErrors] = useState<TaxonDetailsFormErrors>({});

  const { setTaxon, setMatrixId } = useContext(TaxonomyContext);

  const [, , addTaxon] = useWebService({
    method: TaxonService.addTaxon,
    onError,
  });

  const [, , copyTaxonomyBranch] = useWebService({
    method: TaxonService.copyTaxonomyBranch,
    onError,
  });

  const [, , addTaxonToTaxonomy] = useWebService({
    method: TaxonService.addTaxonToTaxonomy,
    onError,
  });

  useEffect(() => {
    setTaxonValue(taxon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxon]);

  const transformNodeTypeArr = () =>
    nodeTypes.map((nodeType) => ({
      label: nodeType.name,
      value: nodeType.nodeTypeId,
    }));

  const handleTaxonChange = (key: keyof TaxonItem, val) => {
    setErrors((prev) => ({ ...prev, [key]: undefined }));
    key === 'englishNames'
      ? setTaxonValue((prev) => ({
          ...prev,
          [key]: val.split(',').filter((name) => name !== ' '),
        }))
      : setTaxonValue((prev) => ({ ...prev, [key]: val }));
  };

  const handleImportChange = (key: keyof ImportForm, val) => {
    setImportValue((prev) => ({ ...prev, [key]: val }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formErrors = TaxonDetailsFormValidation(taxonValue);
    if (!_.isEmpty(formErrors) && enabledForm === 'CreateNew') {
      onError('Please fix the errors in the form');
      setErrors(formErrors);
      return;
    }
    if (enabledForm === 'Imported') {
      importValue.includeChildren
        ? copyTaxonomyBranch(
            importValue.taxonomyId,
            importValue.taxonChoice.taxonId,
            taxon.originTaxonomyId,
            taxon.taxonomyMatrixId
          )
            .then((payload) => {
              setTaxon({
                ...taxonValue,
                originTaxonomyId: importValue.taxonomyId,
                taxonomyMatrixId: payload.taxonomyMatrixId,
                taxonId: payload.taxonId,
                modifiedBy: payload.modifiedBy,
                modifiedDate: payload.modifiedDate,
              });
              setMatrixId(taxon.taxonomyMatrixId);
              onInfo(
                `${taxonValue.commonName} and its children have been successfully imported`
              );
            })
            .catch((e) => onError(e.message))
        : addTaxonToTaxonomy(
            taxon.originTaxonomyId,
            importValue.taxonChoice.taxonId,
            taxon.taxonomyMatrixId
          )
            .then((payload) => {
              setTaxon({
                ...taxonValue,
                originTaxonomyId: importValue.taxonomyId,
                taxonomyMatrixId: payload.taxonomyMatrixId,
                taxonId: payload.taxonId,
                modifiedBy: payload.modifiedBy,
                modifiedDate: payload.modifiedDate,
              });
              setMatrixId(taxon.taxonomyMatrixId);
              onInfo(`${taxonValue.commonName} has been successfully imported`);
            })
            .catch((e) => onError(e.message));
    } else {
      addTaxon(taxonValue)
        .then((payload) => {
          setTaxon({
            ...taxonValue,
            taxonomyMatrixId: payload.taxonomyMatrixId,
            taxonId: payload.taxonId,
            modifiedBy: payload.modifiedBy,
            modifiedDate: payload.modifiedDate,
          });
          setMatrixId(taxon.taxonomyMatrixId);
          onInfo(`${taxonValue.commonName} has been successfully created`);
        })
        .catch((e) => onError(e.message));
    }
  };

  if (!nodeTypes || !taxonomies) {
    return <LinearProgress />;
  }

  return (
    <div className={classes.contentContainer}>
      <Typography variant="h6">Add Taxon</Typography>
      <RadioGroup
        name="import-radio-group"
        onChange={(e) => setEnabledForm(e.target.value)}
        value={enabledForm}
        row
      >
        <FormControlLabel
          value="Imported"
          control={<RadioButton />}
          label="Import a taxon"
        />
        <TaxonImportForm
          importValue={importValue}
          isEnabled={enabledForm === 'Imported'}
          onChange={handleImportChange}
          taxonomies={taxonomies}
        />
        <FormControlLabel
          value="CreateNew"
          control={<RadioButton />}
          label="Create a new taxon"
        />
        <Grid container className={classes.contentContainer}>
          <TaxonDetailsForm
            permission="RW"
            isEnabled={enabledForm === 'CreateNew'}
            onChange={handleTaxonChange}
            onSubmit={handleSubmit}
            taxon={taxonValue}
            nodeTypes={transformNodeTypeArr()}
            errors={errors}
          />
        </Grid>
      </RadioGroup>
      <SaveButton
        disabled={
          enabledForm === 'Imported'
            ? !importValue.taxonChoice?.commonName
            : _.isEqual(taxonValue, taxon)
        }
        onClick={handleSubmit}
      />
    </div>
  );
};

export default TaxonAddForm;
