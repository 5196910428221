import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DatePicker, Grid, TextField } from 'base-components';
import {
  DateFrom,
  DateTo,
  ModifyBy,
  ModifyDate,
  ReadOnlyField,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  containerDiv: {
    margin: theme.spacing(1),
  },
  roFieldRoot: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(1.5),
  },
});

class QaqcDetailsAttributes extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    dataValues: PropTypes.objectOf(PropTypes.any),
    permission: PropTypes.string.isRequired,
    onDateChange: PropTypes.func,
    onDescriptionChange: PropTypes.func,
  };

  static defaultProps = {
    dataValues: {
      description: '',
      effectiveDateFrom: '',
      effectiveDateTo: '',
      modifyBy: '',
      modifyDate: '',
    },
    onDateChange: () => {},
    onDescriptionChange: () => {},
  };

  render() {
    const {
      classes,
      dataValues,
      permission,
      onDateChange,
      onDescriptionChange,
    } = this.props;

    const {
      description,
      effectiveDateFrom,
      effectiveDateTo,
      modifyBy,
      modifyDate,
    } = dataValues;

    return (
      <div className={classes.containerDiv}>
        <Grid container direction="column">
          <Grid item lg={4} md={6} sm={12}>
            {permission === 'RW' || permission === 'RO' ? (
              <>
                <TextField
                  translationKey="common.textfields.description"
                  value={description}
                  onChange={onDescriptionChange}
                  title="qaqcDescriptionTextArea"
                  fullWidth
                  multiline
                  rows={2}
                />
                <Grid container direction="row">
                  <DatePicker
                    translationKey="common.datepickers.dateFrom"
                    value={moment.utc(effectiveDateFrom)}
                    onChange={onDateChange('effectiveDateFrom')}
                  />
                  <TextField
                    value={
                      effectiveDateFrom
                        ? effectiveDateFrom.format('HH:mm:ss')
                        : null
                    }
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    InputLabelProps={{ shrink: true }}
                    translationKey="device.time"
                  />
                </Grid>
                <DateTo
                  date={
                    effectiveDateTo
                      ? moment
                          .utc(effectiveDateTo)
                          .format('YYYY-MM-DD HH:mm:ss')
                      : ''
                  }
                  classes={{ root: classes.roFieldRoot }}
                />
              </>
            ) : (
              <>
                <ReadOnlyField
                  classes={{ root: classes.roFieldRoot }}
                  fullwidth
                  labelText="Description"
                  valueText={description}
                />
                <DateFrom
                  date={effectiveDateFrom}
                  classes={{ root: classes.roFieldRoot }}
                />
                <DateTo
                  date={effectiveDateTo}
                  classes={{ root: classes.roFieldRoot }}
                />
              </>
            )}
            <ModifyBy
              username={modifyBy}
              classes={{ root: classes.roFieldRoot }}
            />
            <ModifyDate
              date={modifyDate}
              classes={{ root: classes.roFieldRoot }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(QaqcDetailsAttributes);
