import { parseDmasAPIResponse, get } from 'util/WebRequest';

class PortService {
  static getOne(portid) {
    return PortService.get({ operation: 4, portid });
  }

  static getMany(deviceid) {
    return PortService.get({ operation: 5, deviceid });
  }

  static get(params) {
    return get('DevicePortService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }
}
export default PortService;
