/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect } from 'react';
import AnnotationTable from 'domain/AppComponents/sea-tube/annotation-table/AnnotationTable';
import TableAnnotation from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import SeaTubeSearchResultsTableActions from 'domain/AppComponents/seatube/search/SeaTubeSearchResultsTableActions';
import { useSeaTubeAnnotations } from 'domain/services/AnnotationService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import { useSnackbars } from 'util/hooks/useSnackbars';

const SearchResultsTableWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id, dashboardId } = props;
  const { onError, onInfo } = useSnackbars();
  const { dashboardState: config } = useDashboardState();

  const {
    data: annotations,
    invalidateQuery: fetchAnnotations,
    isFetching,
  } = useSeaTubeAnnotations(config);

  useEffect(() => {
    fetchAnnotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <Widget
      id={id}
      title="Search Results"
      loading={isFetching}
      dashboardId={dashboardId}
      {...props}
    >
      <AnnotationTable
        rows={
          annotations?.annotations.map(
            (annotation) => new TableAnnotation(annotation)
          ) || []
        }
        ActionFormatter={SeaTubeSearchResultsTableActions}
        storageKey="seatube-search-results-table"
        onError={onError}
        onInfo={onInfo}
        expeditionId={undefined}
        onRefresh={undefined}
        onEdit={undefined}
        hideFilter
        showNumberOfRecords
      />
    </Widget>
  );
};

SearchResultsTableWidget.widgetKey = 'seatube-search-results';
SearchResultsTableWidget.widgetTitle = 'Search Results';
SearchResultsTableWidget.defaultDataGrid = {
  i: 'seatube-search-results',
  x: 5,
  y: 0,
  w: 8,
  h: 12,
};

export default SearchResultsTableWidget;
