import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { type ConfigFields } from 'library/CompositeComponents/dashboard/DashboardTypes';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';
import { validateUrl } from 'library/CompositeComponents/form/FormValidator';

export type DataPreviewWidgetConfigType = {
  previewUrl: string;
  title: string;
  showTitle: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  formButtons: {
    marginTop: theme.spacing(1),
    float: 'right',
  },
}));

const DataPreviewWidgetConfigContent: ConfigFields<
  DataPreviewWidgetConfigType
> = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.formButtons}>
      <FormTextField
        translationKey="dashboards.previewPermalink"
        name="previewUrl"
        fullWidth
        rules={{
          required: 'A permalink is required!',
          validate: (previewUrl) =>
            validateUrl(
              previewUrl,
              'This needs to be a valid Data Preview Permalink'
            ),
        }}
      />
      <FormToggle label="Show Title" name="showTitle" />
      <FormTextField
        translationKey="common.textfields.title"
        name="title"
        fullWidth
      />
    </Grid>
  );
};

export default DataPreviewWidgetConfigContent;
