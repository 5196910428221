import { memo } from 'react';
import * as React from 'react';
import MaterialDialogContentText, {
  DialogContentTextProps,
} from '@mui/material/DialogContentText';

const DialogContentText: React.VFC<DialogContentTextProps> = (
  props: DialogContentTextProps
) => <MaterialDialogContentText {...props} />;

export default memo(DialogContentText);
