import { createTheme } from '@mui/material/styles';

const oceans2Theme = createTheme({
  palette: {
    // Oceans 2.0 branding colours
    primary: {
      main: '#4DB3D0', // light blue
      contrastText: '#fff', // white
    },
    secondary: {
      main: '#BADC76', // light green
      light: '#e7f0d5',
    },
    highlight: {
      main: '#d6ebac',
    },
  },
});

export default oceans2Theme;
