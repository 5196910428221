import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

const useFabDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      top: '8% !important',
      width: 'fit-content',
      height: 'min-content',
    },
    controlLayersContainer: {
      minWidth: '520px',
      margin: '10px 5px 5px 15px',
      padding: theme.spacing(),
      backgroundColor: grey[300],
      borderRadius: '4px',
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px',
    },
    boxOuter: {
      width: '100%',
      backgroundColor: 'white',
    },
    boxInner: {
      borderBottom: 1,
      borderColor: 'divider',
    },
    closeButton: {
      padding: '20px',
    },
  })
);

export default useFabDialogStyles;
