import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link, ListItem, ListItemText } from 'base-components';

const styles = () => ({
  root: {
    display: 'block',
  },
});

class InfoItemWithLink extends PureComponent {
  static propTypes = {
    primary: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    linkURL: PropTypes.string.isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string,
    }),
  };

  static defaultProps = {
    classes: {},
  };

  render() {
    const { primary, linkText, linkURL, classes } = this.props;
    return (
      <ListItem className={classes.root}>
        <ListItemText primary={primary} />
        <Link href={linkURL}>{linkText}</Link>
      </ListItem>
    );
  }
}

export default withStyles(styles)(InfoItemWithLink);
