class DelayUtil {
  /* The throttle function allows the initial call to execute 
  and then ignores all additional calls until a set time has passed. */

  static throttle(func, timeout) {
    let timer;
    return (...args) => {
      if (!timer) {
        func.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  }

  /* The debounce function holds onto a call to do some work 
  until a set time after the last call to the debounce. */

  static debounce(func, timeout) {
    let timer;
    return (...args) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = undefined;
        func.apply(this, args);
      }, timeout);
    };
  }
}

export default DelayUtil;
