import { useContext, useState } from 'react';
import { TableColumnWidthInfo, Sorting } from '@devexpress/dx-react-grid';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Dialog, DialogContent, DialogTitle } from 'base-components';
import StatelessTable, {
  TableColumn,
} from 'library/BaseComponents/table/StatelessTable';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import DateFormatUtils from 'util/DateFormatUtils';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../../library/CompositeComponents/dashboard/DashboardTypes';
import EndeavourContext from '../context/EndeavourContext';

const columns: TableColumn[] = [
  { name: 'type', title: 'Type', dataType: 'String' },
  { name: 'complete', title: 'Complete', dataType: 'Boolean' },
  {
    name: 'horizontalErrorKM',
    title: 'Horizontal Error (km)',
    dataType: 'Number',
  },
  { name: 'verticalErrorKM', title: 'Vertical Error (km)', dataType: 'Number' },
  { name: 'fixedDepth', title: 'Fixed Depth', dataType: 'Number' },
  { name: 'latitude', title: 'Latitude', dataType: 'Number' },
  { name: 'longitude', title: 'Longitude', dataType: 'Number' },
  { name: 'depthKM', title: 'Depth (km)', dataType: 'Number' },
  { name: 'magnitude', title: 'Magnitude', dataType: 'Number' },
  { name: 'originTime', title: 'Origin Time', dataType: 'Date' },
  { name: 'covarianceMatrix', title: 'Covariance Matrix', dataType: 'String' },
];

const tableColumnExtensions: Table.ColumnExtension[] = [
  {
    columnName: 'type',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'complete',
    wordWrapEnabled: true,
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'horizontalErrorKM',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'verticalErrorKM',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'fixedDepth',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'latitude',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'longitude',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'depthKM',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'magnitude',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'originTime',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'covarianceMatrix',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
    align: 'left',
  },
];

const resizingExtensions = [
  {
    columnName: 'type',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'complete',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'horizontalErrorKM',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'verticalErrorKM',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'fixedDepth',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'latitude',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'longitude',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'depthKM',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'magnitude',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'originTime',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
  {
    columnName: 'covarianceMatrix',
    minWidth: ColumnInfo.mini,
    maxWidth: ColumnInfo.large,
  },
];

const EndeavourTableWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id } = props;
  const {
    earthquakes,
    selectedEarthquake,
    setSelectedEarthquake,
    mapSelectedEarthquakes,
    setMapSelectedEarthquakes,
  } = useContext(EndeavourContext);

  const defaultSorting: Sorting[] = [
    { columnName: 'originTime', direction: 'desc' },
  ];
  const defaultConfig = {
    sorting: defaultSorting,
    hiddenColumns: [
      'covarianceMatrix',
      'complete',
      'horizontalErrorKM',
      'verticalErrorKM',
      'fixedDepth',
      'covarianceMatrix',
    ],
    columnOrder: columns.map((column) => column.name),
  };
  // Column Sorting
  const [sorting, setSorting] = useState<Sorting[]>(defaultConfig.sorting);
  const [openCovarianceDialog, setOpenCovarianceDialog] =
    useState<boolean>(false);
  const [covariance, setCovariance] = useState<number[][]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  // Column Ordering
  const [columnOrder, setColumnOrder] = useState<string[]>(
    defaultConfig.columnOrder
  );
  // Hidden Columns
  const [hiddenColumnNames, setHiddenColumns] = useState<string[]>(
    defaultConfig.hiddenColumns
  );

  const defaultWidths = tableColumnExtensions.map(
    ({ align, ...rest }) => rest as TableColumnWidthInfo
  );
  const [columnWidths, setColumnWidths] =
    useState<TableColumnWidthInfo[]>(defaultWidths);

  const onCovarianceClick = (cov) => {
    setCovariance(cov);
    setOpenCovarianceDialog(true);
  };

  const handleResetView = () => {
    setHiddenColumns(defaultConfig.hiddenColumns);
    setSorting(defaultConfig.sorting);
    setColumnOrder(defaultConfig.columnOrder);
    setColumnWidths(defaultWidths);
    setSelectedEarthquake(undefined);
    setMapSelectedEarthquakes([]);
  };

  const renderTableRows = () => {
    const filteredEarthquakes =
      mapSelectedEarthquakes.length > 0
        ? earthquakes.filter((earthquake) =>
            mapSelectedEarthquakes.includes(earthquake.endeavourEventId)
          )
        : earthquakes;
    return filteredEarthquakes.map((row) => ({
      ...row,
      originTime: DateFormatUtils.formatDate(row.originTime, 'milliseconds'),
      fixedDepth: `${row.fixedDepth}`,
      complete: `${row.complete}`,
      latitude:
        row.latitude !== undefined ? row.latitude.toFixed(2) : undefined,
      longitude:
        row.longitude !== undefined ? row.longitude.toFixed(2) : undefined,
      horizontalErrorKM:
        row.horizontalErrorKM !== undefined
          ? row.horizontalErrorKM.toFixed(4)
          : undefined,
      verticalErrorKM:
        row.verticalErrorKM !== undefined
          ? row.verticalErrorKM.toFixed(4)
          : undefined,
      depthKM: row.depthKM !== undefined ? row.depthKM.toFixed(3) : undefined,
      magnitude:
        row.magnitude !== undefined ? row.magnitude.toFixed(4) : undefined,
      covarianceMatrix: (
        <a onClick={() => onCovarianceClick(row.covarianceMatrix)} href="#">
          Covariance Matrix
        </a>
      ),
    }));
  };

  const covarianceRows = () => {
    const result = [];
    for (let i = 0; i < covariance.length; i += 1) {
      const mappedObj = { direction: `${String.fromCharCode(88 + i)}` };
      for (let j = 0; j < covariance[i].length; j += 1) {
        const key = `${String.fromCharCode(120 + j)}`;
        mappedObj[key] = covariance[i][j];
      }
      result.push(mappedObj);
    }

    return result;
  };

  return (
    <Widget key={id} title="Earthquake Table" {...props}>
      <Dialog
        open={openCovarianceDialog}
        onClose={() => setOpenCovarianceDialog(false)}
        fullWidth
      >
        <DialogTitle>Covariance Matrix</DialogTitle>
        <DialogContent>
          <StatelessTable
            rows={covarianceRows()}
            columns={[
              { name: 'direction', title: 'Direction' },
              { name: 'x', title: 'X' },
              { name: 'y', title: 'Y' },
              { name: 'z', title: 'Z' },
            ]}
          />
        </DialogContent>
      </Dialog>
      <StatelessTable
        onReset={handleResetView}
        getRowId={(row) => row.endeavourEventId}
        rows={renderTableRows()}
        columns={columns}
        paging={{
          currentPage,
          onCurrentPageChange: (newPage) => setCurrentPage(newPage),
          pageSize: 10,
        }}
        virtual={{ virtualized: true }}
        visible={{
          hiddenColumnNames,
          handleChangeVisibility: setHiddenColumns,
        }}
        sort={{
          sorting,
          customSorting: [
            {
              columnName: 'horizontalErrorKM',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'verticalErrorKM',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'fixedDepth',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'latitude',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'longitude',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'depthKM',
              compare: (a, b) => a - b,
            },
            {
              columnName: 'magnitude',
              compare: (a, b) => a - b,
            },
          ],
          handleSortingChange: setSorting,
        }}
        title="Earthquake Info"
        selection={{
          selection: [selectedEarthquake],
          onChange: (selected: number[]) => {
            if (selected[1] !== selectedEarthquake) {
              setSelectedEarthquake(selected[1]);
            }
          },
          highlightRow: true,
          selectByRowClick: true,
          showSelectionColumn: false,
        }}
        columnExtensions={tableColumnExtensions}
        reorder={{
          columnOrder,
          handleColumnOrder: setColumnOrder,
          frozenLeftColumns: [],
          frozenRightColumns: [],
        }}
        resize={{
          columnWidths,
          handleColumnWidths: setColumnWidths,
          columnExtensions: resizingExtensions,
        }}
      />
    </Widget>
  );
};

EndeavourTableWidget.widgetKey = 'endeavour-earthquake-table';
EndeavourTableWidget.widgetTitle = 'Earthquake Table';
EndeavourTableWidget.defaultDataGrid = {
  i: 'endeavour-earthquake-table',
  x: 0,
  y: 0,
  w: 6,
  h: 5,
};

export default EndeavourTableWidget;
