const chatLogDefault = {
  lg: [
    {
      w: 9,
      h: 14,
      x: 0,
      y: 17,
      i: 'annotation-entry',
      minW: 4,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 17,
      x: 0,
      y: 0,
      i: 'seatube-map',
      minW: 2,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 17,
      x: 3,
      y: 0,
      i: 'seatube-video',
      minW: 2,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 17,
      x: 7,
      y: 0,
      i: 'chat-log',
      minW: 2,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 17,
      x: 9,
      y: 0,
      i: 'annotation-list',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 14,
      x: 9,
      y: 17,
      i: 'seatube-sensor-display',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 9,
      h: 10,
      x: 0,
      y: 16,
      i: 'annotation-entry',
      minW: 4,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 7,
      x: 9,
      y: 10,
      i: 'chat-log',
      minW: 2,
      minH: 7,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 16,
      x: 0,
      y: 0,
      i: 'seatube-map',
      minW: 2,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 16,
      x: 3,
      y: 0,
      i: 'seatube-video',
      minW: 2,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 8,
      x: 9,
      y: 0,
      i: 'annotation-list',
      minW: 3,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 9,
      y: 16,
      i: 'seatube-sensor-display',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 6,
      h: 10,
      x: 0,
      y: 10,
      i: 'annotation-entry',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 10,
      x: 0,
      y: 25,
      i: 'chat-log',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 10,
      x: 0,
      y: 35,
      i: 'seatube-map',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 10,
      x: 0,
      y: 0,
      i: 'seatube-video',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 10,
      x: 3,
      y: 25,
      i: 'annotation-list',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 10,
      x: 3,
      y: 35,
      i: 'seatube-sensor-display',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 3,
      h: 9,
      x: 0,
      y: 9,
      i: 'annotation-entry',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 0,
      y: 35,
      i: 'seatube-map',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 0,
      y: 0,
      i: 'seatube-video',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 0,
      y: 18,
      i: 'annotation-list',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 8,
      x: 0,
      y: 27,
      i: 'chat-log',
      minW: 3,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 0,
      y: 44,
      i: 'seatube-sensor-display',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
  ],
  xxs: [
    {
      h: 10,
      i: 'seatube-video',
      w: 3,
      x: 0,
      y: 0,
      minH: 9,
      minW: 3,
    },
    {
      h: 10,
      i: 'annotation-entry',
      w: 3,
      x: 0,
      y: 10,
      minH: 9,
      minW: 3,
    },
    {
      h: 20,
      i: 'annotation-list',
      w: 3,
      x: 0,
      y: 20,
      minH: 9,
      minW: 3,
    },
    {
      h: 10,
      i: 'seatube-map',
      w: 3,
      x: 0,
      y: 40,
      minH: 9,
      minW: 3,
    },
    {
      h: 10,
      i: 'seatube-sensor-display',
      w: 3,
      x: 0,
      y: 50,
      minH: 9,
      minW: 3,
    },
  ],
};

const chatLogAllComponents = {
  lg: [
    {
      w: 6,
      h: 14,
      x: 3,
      y: 17,
      i: 'annotation-entry',
      minW: 4,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 17,
      x: 0,
      y: 0,
      i: 'seatube-map',
      minW: 2,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 17,
      x: 3,
      y: 0,
      i: 'seatube-video',
      minW: 2,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 17,
      x: 7,
      y: 0,
      i: 'chat-log',
      minW: 2,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 17,
      x: 9,
      y: 0,
      i: 'annotation-list',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 14,
      x: 9,
      y: 17,
      i: 'seatube-sensor-display',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 14,
      x: 0,
      y: 17,
      i: 'seatube-dive-details',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 6,
      h: 10,
      x: 3,
      y: 16,
      i: 'annotation-entry',
      minW: 4,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 7,
      x: 9,
      y: 10,
      i: 'chat-log',
      minW: 2,
      minH: 7,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 16,
      x: 0,
      y: 0,
      i: 'seatube-map',
      minW: 2,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 16,
      x: 3,
      y: 0,
      i: 'seatube-video',
      minW: 2,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 8,
      x: 9,
      y: 0,
      i: 'annotation-list',
      minW: 3,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 9,
      y: 16,
      i: 'seatube-sensor-display',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 10,
      x: 0,
      y: 16,
      i: 'seatube-dive-details',
      minW: 3,
      minH: 8,
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 6,
      h: 10,
      x: 0,
      y: 10,
      i: 'annotation-entry',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 20,
      i: 'chat-log',
      minW: 6,
      minH: 5,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 10,
      x: 0,
      y: 25,
      i: 'seatube-map',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 10,
      x: 0,
      y: 0,
      i: 'seatube-video',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 10,
      x: 3,
      y: 25,
      i: 'annotation-list',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 10,
      x: 3,
      y: 35,
      i: 'seatube-sensor-display',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 10,
      x: 0,
      y: 35,
      i: 'seatube-dive-details',
      minW: 3,
      minH: 10,
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 3,
      h: 9,
      x: 0,
      y: 9,
      i: 'annotation-entry',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 0,
      y: 35,
      i: 'seatube-map',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 0,
      y: 0,
      i: 'seatube-video',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 0,
      y: 18,
      i: 'annotation-list',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 8,
      x: 0,
      y: 27,
      i: 'chat-log',
      minW: 3,
      minH: 8,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 0,
      y: 44,
      i: 'seatube-sensor-display',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 9,
      x: 0,
      y: 53,
      i: 'seatube-dive-details',
      minW: 3,
      minH: 9,
      moved: false,
      static: false,
    },
  ],
  xxs: [
    {
      h: 10,
      i: 'seatube-video',
      w: 3,
      x: 0,
      y: 0,
      minH: 9,
      minW: 3,
    },
    {
      h: 10,
      i: 'annotation-entry',
      w: 3,
      x: 0,
      y: 10,
      minH: 9,
      minW: 3,
    },
    {
      h: 20,
      i: 'annotation-list',
      w: 3,
      x: 0,
      y: 20,
      minH: 9,
      minW: 3,
    },
    {
      h: 10,
      i: 'seatube-map',
      w: 3,
      x: 0,
      y: 40,
      minH: 9,
      minW: 3,
    },
    {
      h: 10,
      i: 'seatube-sensor-display',
      w: 3,
      x: 0,
      y: 50,
      minH: 9,
      minW: 3,
    },
    {
      h: 10,
      i: 'seatube-dive-details',
      w: 3,
      x: 0,
      y: 60,
      minH: 9,
      minW: 3,
    },
  ],
};

export { chatLogAllComponents, chatLogDefault };
