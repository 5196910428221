import { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import UTCSeekbar from 'library/CompositeComponents/seekbar/UTCSeekbar';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DateUtils from 'util/DateUtils';
import Time from 'util/TimeUtils';
import ContinuousVideoFiles from './ContinuousVideoFiles';
import VideoSubPlaylist from './VideoSubPlaylist';

class ContinuousVideo extends PureComponent {
  static propTypes = {
    playerId: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    files: PropTypes.instanceOf(ContinuousVideoFiles).isRequired,
    onPlayerReady: PropTypes.func,
    onPlaylistComplete: PropTypes.func,
    onTime: PropTypes.func,
    onSeek: PropTypes.func,
    seekTo: PropTypes.number, // seconds since epoch (why? just give me a Date!)
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: undefined,
    onPlayerReady: () => {},
    onPlaylistComplete: () => {},
    onTime: () => {},
    onSeek: () => {},
    seekTo: undefined,
  };

  pad = (num, size) => `000${num}`.slice(size * -1);

  constructor(props) {
    super(props);

    this.player = {};
    this.eventListeners = [];

    this.totalTimeElapsed = 0;

    this.state = {
      seeking: false,
    };

    this.currentPlaylist = props.files.buildPlaylist();
  }

  componentDidUpdate(prev) {
    const { seekTo, files, onError } = this.props;
    const { seekTo: prevSeekTo, files: prevFiles } = prev;

    if (files === prevFiles && seekTo === prevSeekTo) return;
    if (!_.isEqual(files, prevFiles)) {
      this.currentPlaylist = files.buildPlaylist();
    }
    if (files.getTimeElapsedAtDate(seekTo) >= 0) {
      this.playVideoAtTime(files.getTimeElapsedAtDate(seekTo));
    } else {
      // If seekTo is intentianally undefined, don't show an error
      if (seekTo === undefined) return;

      const nextStart = DateUtils.formatDateAsString(
        files.getStartDateOfClipAfter(seekTo)
      );
      const prevEnd = DateUtils.formatDateAsString(
        files.getEndDateOfClipBefore(seekTo)
      );

      if (nextStart && prevEnd) {
        onError(
          `Video is missing in this resolution from ${prevEnd} to ${nextStart}`
        );
      } else if (nextStart) {
        onError(`Video is missing in this resolution until ${nextStart}`);
      } else if (prevEnd) {
        onError(`Video is missing in this resolution after ${prevEnd}`);
      } else {
        onError('Video is missing at this time in the current resolution');
      }
    }
  }

  componentWillUnmount() {
    this.eventListeners.forEach(({ query, event }) => {
      const element = this.player.querySelector(query);
      if (element) element.removeEventListener(event.name, event.onEvent);
    });
    this.eventListeners = [];
  }

  handlePlayerReady = (JWPlayerAPI) => {
    const { files, onPlayerReady, seekTo } = this.props;
    // Set up JWPlayerAPI function calls
    this.player = JWPlayerAPI;

    // Add custom event handlers to the slider
    this.clonePlayerElement('.jw-slider-time.jw-slider-horizontal', [
      { name: 'pointerdown', onEvent: this.handleSliderDown },
      { name: 'pointermove', onEvent: this.handleSliderMove },
      { name: 'pointerout', onEvent: this.handleSliderOut },
      { name: 'pointerup', onEvent: this.handleSliderUp },
    ]);

    // Create custom time elements that we update and JWPlayer can't
    this.clonePlayerElement('.jw-text-elapsed');
    this.clonePlayerElement('.jw-text-duration');

    // Bubble playerReady up if needed
    onPlayerReady({
      handleSkipForward: this.handleSkipForward,
      handleSkipBackward: this.handleSkipBackward,
      handleNextClip: this.handleNextClip,
      handlePreviousClip: this.handlePreviousClip,
      handleLatestClip: this.handleLatestClip,
      handleFirstClip: this.handleFirstClip,
    });

    this.playVideoAtTime(files.getTimeElapsedAtDate(seekTo));
  };

  /**
   * Clones an element of the video play and adds custom event listeners if
   * given
   *
   * @param {string} query - The locator for the element to clone
   * @param {{
   *   name: string;
   *   onEvent: Function;
   * }} events - Event handlers
   *   to add to the the element
   */
  clonePlayerElement = (query, events) => {
    // Get the element
    const element = this.player.querySelector(query);
    if (!element) return;

    // Clone the element
    const customElement = element.cloneNode(true);

    // Add new eventListeners
    if (events) {
      events.forEach((event) => {
        customElement.addEventListener(event.name, event.onEvent);
        this.eventListeners.push({ query, event });
      });
    }

    // Replase the original element with the custom element
    element.parentNode.insertBefore(customElement, element);
    element.remove();
  };

  handleSliderDown = () => {
    this.setState({ seeking: true });
  };

  secondsTohms = (time) => {
    const hours = Math.floor(time / 60 / 60);
    const minutes = this.pad(Math.floor(time / 60) % 60, 2);
    const seconds = this.pad(Math.floor(time - minutes * 60 - hours * 3600), 2);
    return hours === 0
      ? `${minutes}:${seconds}`
      : `${hours}:${minutes}:${seconds}`;
  };

  handleSliderMove = (event) => {
    const { files } = this.props;
    const { seeking } = this.state;
    const elapsed = this.calculateTimeElapsedFromEvent(event);

    if (seeking) {
      this.updateSlider(elapsed);
      this.updateTime(elapsed);
    }

    const tooltip = this.player.querySelector('.jw-tooltip-time');
    if (tooltip) {
      const timeSpan = tooltip.querySelector('.jw-time-tip span');
      if (timeSpan) {
        const time = this.secondsTohms(elapsed);
        timeSpan.textContent = time;
        tooltip.classList.add('jw-open');
        tooltip.style.left = `${(elapsed / files.getDuration()) * 100}%`;
      }
    }
  };

  handleSliderOut = () => {
    const tooltip = this.player.querySelector('.jw-tooltip-time');
    if (tooltip) {
      tooltip.classList.remove('jw-open');
    }
  };

  handleSliderUp = (event) => {
    const elapsed = this.calculateTimeElapsedFromEvent(event);
    this.playVideoAtTime(elapsed);
    this.finishSeeking(elapsed);
  };

  handleSkipForward = () => {
    const elapsed = this.totalTimeElapsed + 30;
    this.playVideoAtTime(elapsed);
    this.finishSeeking(elapsed);
  };

  handleSkipBackward = () => {
    const elapsed = Math.max(this.totalTimeElapsed - 30, 0);
    this.playVideoAtTime(elapsed);
    this.finishSeeking(elapsed);
  };

  handleNextClip = () => {
    const { files } = this.props;

    const elapsed = files.getTimeElapsedToNextClip();
    this.playVideoAtTime(elapsed);
    this.finishSeeking(elapsed);
  };

  handlePreviousClip = () => {
    const { files } = this.props;

    const elapsed = files.getTimeElapsedToPreviousClip();
    this.playVideoAtTime(elapsed);
    this.finishSeeking(elapsed);
  };

  handleFirstClip = () => {
    const { files } = this.props;

    const elapsed = files.getTimeElapsedToFirstClip();
    this.playVideoAtTime(elapsed);
    this.finishSeeking(elapsed);
  };

  handleLatestClip = () => {
    const { files } = this.props;

    const elapsed = files.getTimeElapsedToFinalClip();
    this.playVideoAtTime(elapsed);
    this.finishSeeking(elapsed);
  };

  handleTimeChange = (timeElapsedInCurrentFile) => {
    const { files, onTime } = this.props;
    const { seeking } = this.state;

    // How much of the total playlist has elapsed
    this.totalTimeElapsed =
      files.getTimeElapsedToCurrentClip() + timeElapsedInCurrentFile;

    // Update player elements if the user isn't moving the slider
    if (!seeking) {
      this.updateTime(this.totalTimeElapsed);
      this.updateSlider(this.totalTimeElapsed);
    }

    onTime(files.getDateAt(Math.round(this.totalTimeElapsed)));

    this.checkAndHandleVideoComplete(this.totalTimeElapsed);
  };

  checkAndHandleVideoComplete = (elapsed) => {
    const { files } = this.props;

    if (!elapsed || files.getDuration() > elapsed) return false;

    // Don't call handlePlaylistComplete directly: JWPlayer will execute
    // handleTimeChange again, potentially causing this method to be executed
    // twice
    this.player.finish();
    return true;
  };

  handlePlaylistComplete = () => {
    const { onPlaylistComplete } = this.props;

    // This method is called both when checkAndHandleVideoComplete triggers
    // the 'playlistComplete' event, and after handlePlaylistItemComplete when
    // JWPlayer detects a sub-playlist has finished. We only want it to be
    // executed when the full playlist completes.
    if (this.playlistIsNotComplete) {
      this.playlistIsNotComplete = false;
      return;
    }

    this.player.stop();
    onPlaylistComplete();
  };

  handlePlaylistItemComplete = () => {
    const { files } = this.props;

    files.selectNextClip();
    this.player.load(files.buildPlaylist(), 0);
    this.playlistIsNotComplete = true;
  };

  /**
   * Calculates the new time elapsed in the video after a seek event
   *
   * @param {PointerEvent} event - Pointer event
   * @returns {number} - The time that has elapsed in seconds
   */
  calculateTimeElapsedFromEvent = (event) => {
    const { files } = this.props;
    const time = this.player.querySelector('.jw-slider-time .jw-old-rail');
    if (!time) return 0;

    time.style.height = '3px';
    time.style.background = 'rgb(255, 255, 255, 0.4)';

    const width = parseFloat(window.getComputedStyle(time).width, 10);
    const offset = time.getBoundingClientRect().left;

    const position = event.clientX - offset;
    const ratio = position / width;

    // Clamp ratio between 0 and 1
    return files.getDuration() * Math.max(0, Math.min(1, ratio));
  };

  /**
   * Plays the file that matches the time given
   *
   * @private
   * @param {number} elapsed - The time to play the video at
   */
  playVideoAtTime = (elapsed) => {
    const { files } = this.props;

    if (elapsed === undefined) return;

    if (this.checkAndHandleVideoComplete(elapsed)) return;

    // Running into the occasional issue where the player is not ready
    if (typeof this.player.load !== 'function') return;

    files.selectClipAtTime(elapsed);
    this.player.load(
      files.buildPlaylist(),
      elapsed - files.getTimeElapsedToCurrentClip()
    );

    this.setState({ seeking: false });
  };

  /**
   * Called after seeking the video and returns the timestamp at which the video
   * will start playing
   *
   * @param {number} elapsed - The total elapsed time in seconds
   */
  finishSeeking = (elapsed) => {
    const { files, onSeek } = this.props;

    if (elapsed === undefined) return;
    onSeek(files.getDateAt(elapsed));
  };

  /** Updates the slider / seek bar */
  updateSlider = (elapsed) => {
    const { files } = this.props;

    const jwProgress = this.player.querySelector('.jw-old-progress');
    const jwKnob = this.player.querySelector('.jw-knob');

    const position = Math.round((elapsed / files.getDuration()) * 100);

    jwProgress.style.width = `${position}%`;
    jwProgress.style.height = `3px`;
    jwProgress.style.background = `#4DB3D0`;
    jwProgress.style.opacity = 1;
    jwKnob.style.left = `${position}%`;
  };

  /** Updates the elapsed and duration display */
  updateTime = (elapsed) => {
    const { files } = this.props;

    const jwDuration = this.player.querySelector('.jw-text-duration');
    const jwElapsed = this.player.querySelector('.jw-text-elapsed');
    const jwCountdown = this.player.querySelector('.jw-text-countdown');

    jwDuration.textContent = Time.toTimeString(files.getDuration());
    jwElapsed.textContent = Time.toTimeString(elapsed);
    jwCountdown.textContent = Time.toTimeString(files.getDuration() - elapsed);
  };

  seekFromZoom = (elapsed) => {
    this.playVideoAtTime(elapsed);
    this.updateSlider(elapsed);
    this.updateTime(elapsed);
    this.finishSeeking(elapsed);
  };

  renderSeekbar = () => {
    const { files } = this.props;
    const seekbar = (
      <UTCSeekbar
        seek={this.seekFromZoom}
        files={files}
        querySelector={this.player.querySelector}
        time={this.totalTimeElapsed}
      />
    );
    return seekbar;
  };

  render() {
    const { children, playerId, ...rest } = this.props;

    return (
      <>
        <VideoSubPlaylist
          {...rest}
          playerId={playerId}
          playlist={this.currentPlaylist}
          onPlayerReady={this.handlePlayerReady}
          onTime={this.handleTimeChange}
          onSeek={undefined} // Stop onSeek from getting passed down
          onPlaylistComplete={this.handlePlaylistComplete}
          onPlaylistItemComplete={this.handlePlaylistItemComplete}
        >
          {children}
        </VideoSubPlaylist>
        {this.renderSeekbar()}
      </>
    );
  }
}

export default withSnackbars(ContinuousVideo);
