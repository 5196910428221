import { useCallback } from 'react';
import { useDashboardStateContext } from '../context/DashboardStateProvider';

export interface useDashboardStateReturn<TDashboardState> {
  dashboardState: TDashboardState;
  setDashboardState: (newConfig: Partial<TDashboardState>) => void;
  setDashboardStateProperty: <K extends keyof TDashboardState>(
    key: K,
    value: TDashboardState[K]
  ) => void;
}

/**
 * A custom hook for managing dashboard configuration state.
 *
 * @example
 *   const { dashboardState, setDashboardState, setDashboardStateProperty } =
 *     useDashboardState<MyDashboardState>();
 *
 * @template TDashboardState - The type of the dashboard configuration object
 * @returns {useDashboardStateReturn<TDashboardState>} An object containing:
 *
 *   - DashboardState: Current dashboard configuration
 *   - SetDashboardState: Function to update multiple config properties
 *   - SetDashboardStateProperty: Function to update a single config property
 */
const useDashboardState = <
  TDashboardState = any,
>(): useDashboardStateReturn<TDashboardState> => {
  const { dashboardState, setDashboardState: setDashboardStateContext } =
    useDashboardStateContext<TDashboardState>();

  const setDashboardState = useCallback(
    (newConfig: Partial<TDashboardState>) => {
      setDashboardStateContext((prevConfig: TDashboardState) => ({
        ...prevConfig,
        ...newConfig,
      }));
    },
    [setDashboardStateContext]
  );

  const setDashboardStateProperty = useCallback(
    <K extends keyof TDashboardState>(key: K, value: TDashboardState[K]) => {
      setDashboardStateContext((prevConfig: TDashboardState) => ({
        ...prevConfig,
        [key]: value,
      }));
    },
    [setDashboardStateContext]
  );

  return {
    dashboardState,
    setDashboardState,
    setDashboardStateProperty,
  };
};

export default useDashboardState;
