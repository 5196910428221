import { useEffect, useState } from 'react';
import moment from 'moment';
import { Loading } from '@onc/composite-components';
import { DropdownOption, QuickEntryButton } from 'base-components';
import { ListAnnotation } from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import { ServiceAnnotationV3 } from 'domain/services/AnnotationService';
import { useDeployments } from 'domain/services/DeploymentsWebService';
import useDevices from 'domain/services/DevicesService';
import { useFixedCameraPermissions } from 'domain/services/SeaTubePermissionsService';
import SeaTubeManualEntryForm from './SeaTubeManualEntryForm';

type FixedCameraAnnotationEntryProps = {
  currentTimestamp: string;
  searchTreeNodeCode: string;
  quickButton?: QuickEntryButton;
  taxonomyOptions: DropdownOption[];
  editAnnotation?: ListAnnotation;
  onSubmitSuccess: (annotation: ServiceAnnotationV3) => void;
  onClearEditedAnnotation: () => void;
};

const FixedCameraAnnotationEntry = ({
  currentTimestamp,
  taxonomyOptions,
  searchTreeNodeCode,
  quickButton = null,
  editAnnotation = null,
  onSubmitSuccess,
  onClearEditedAnnotation,
}: FixedCameraAnnotationEntryProps) => {
  const { data: deployments, isLoading: isLoadingDeployments } = useDeployments(
    {
      locationCode: searchTreeNodeCode,
      deviceCategoryCode: 'VIDEOCAM',
    }
  );

  const { data: devices, isLoading: isLoadingDevices } = useDevices({
    locationCode: searchTreeNodeCode,
    deviceCategoryCode: 'VIDEOCAM',
  });

  const { data: permissions, isLoading: isLoadingPermissions } =
    useFixedCameraPermissions();

  const [resourceOptions, setResourceOptions] = useState<DropdownOption[]>([]);
  const [deploymentDates, setDeploymentDates] = useState<{
    startDate?: string;
    endDate?: string;
  }>({});

  useEffect(() => {
    if (deployments && devices) {
      // Find the matching deployment for the current timestamp
      const matchingDeployment = deployments.find((deployment) => {
        const dateFrom = moment.utc(deployment.begin);
        const dateTo = deployment.end
          ? moment.utc(deployment.end)
          : moment.utc();
        return moment.utc(currentTimestamp).isBetween(dateFrom, dateTo);
      });

      if (matchingDeployment) {
        // Find the corresponding device for the deployment
        const device = devices.find(
          (d) => d.deviceCode === matchingDeployment.deviceCode
        );

        if (device) {
          setResourceOptions([
            {
              key: `${matchingDeployment.deviceCode}`,
              value: device.deviceId,
              label: device.deviceName || matchingDeployment.deviceCode,
            },
          ]);

          setDeploymentDates({
            startDate: matchingDeployment.begin,
            endDate: matchingDeployment.end || moment.utc().toISOString(),
          });
        }
      } else {
        setResourceOptions([]);
        setDeploymentDates(null);
      }
    }
  }, [deployments, devices, currentTimestamp]);

  if (isLoadingDeployments || isLoadingDevices || isLoadingPermissions) {
    return <Loading />;
  }

  return (
    <SeaTubeManualEntryForm
      quickButton={quickButton}
      onSubmitSuccess={onSubmitSuccess}
      currentTimestamp={currentTimestamp}
      editAnnotation={editAnnotation}
      onClearEditedAnnotation={onClearEditedAnnotation}
      resourceTypeOptions={[
        {
          key: `${SeaTubeResourceTypes.DEVICE_DATA}`,
          value: SeaTubeResourceTypes.DEVICE_DATA,
          label: 'Device Data',
        },
      ]}
      resourceOptions={resourceOptions}
      defaultResource={resourceOptions[0]?.value}
      defaultResourceType={SeaTubeResourceTypes.DEVICE_DATA}
      defaultPublish={false}
      taxonomyOptions={taxonomyOptions}
      options={{
        hideResource: true,
        nullableAttributes: false,
        canPublish: permissions?.[0]?.value,
      }}
      minDate={
        deploymentDates ? moment.utc(deploymentDates.startDate) : undefined
      }
      maxDate={
        deploymentDates ? moment.utc(deploymentDates.endDate) : undefined
      }
    />
  );
};

export default FixedCameraAnnotationEntry;
