import * as React from 'react';
import { Error } from '@onc/icons';
import { Typography } from 'base-components';

const VideoNotFoundError: React.VFC = () => (
  <div
    style={{
      height: 'inherit',
      width: 'inherit',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Error style={{ color: '#D32F2F', height: '150px', width: '150px' }} />
    <Typography>Video not found</Typography>
  </div>
);

export default VideoNotFoundError;
