import { useEffect } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import { Autocomplete, Dropdown } from 'base-components';
import { FilterProps } from './TableFilterFactory';

const styles = (theme: Theme) =>
  createStyles({
    flexItem: {
      margin: theme.spacing(0.5),
    },
  });

const operatorOptions = [
  {
    key: 'isOneOf',
    label: 'is one of',
    value: 'isOneOf',
  },
  {
    key: 'isNotOneOf',
    label: 'is not one of',
    value: 'isNotOneOf',
  },
  {
    key: 'isNull',
    label: 'is null',
    value: 'isNull',
  },
  {
    key: 'isNotNull',
    label: 'is not null',
    value: 'isNotNull',
  },
];

const TableBooleanFilter: React.VFC<FilterProps> = ({
  operator,
  value,
  options,
  classes,
  handleChangeOperator,
  handleChangeValue,
}) => {
  useEffect(() => {
    if (operator === undefined) {
      handleChangeOperator(operatorOptions[0].value);
    }
  }, [operator, handleChangeOperator]);
  return (
    <>
      <Dropdown
        label="Operator"
        name="operator"
        data-test="operator"
        fullWidth
        className={classes ? classes.flexItem : undefined}
        value={operator}
        options={operatorOptions}
        onChange={(e) => handleChangeOperator(e.target.value)}
      />
      <Autocomplete
        translationKey="common.textfields.value"
        name="value"
        data-test="select-value"
        multiple
        fullWidth
        disabled={operator === 'isNull' || operator === 'isNotNull'}
        className={classes ? classes.flexItem : undefined}
        value={Array.isArray(value) ? value : undefined}
        options={options || []}
        getOptionLabel={(option: any) => (option.label ? option.label : option)}
        onChange={(e) => handleChangeValue(e.target.value)}
      />
    </>
  );
};

export default withStyles(styles)(TableBooleanFilter);
