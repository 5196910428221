import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

export interface Device {
  deviceId: number;
  deviceName: string;
  deviceCode: string;
  deviceCategoryId: number;
  l22TermName: string;
  l22TermLink: string;
  l05TermName: string;
  l05TermLink: string;
  deviceType: string;
  deviceTypeId: number;
  tier: string;
  powerable: number;
  neptuneSearchable: boolean;
  memo: string;
  modifyBy: string;
  modifyDate: string;
  ippGroupName: string;
  ippGroupMembers: IPPGroupInfo[];
}

export interface IPPGroupInfo {
  name: string;
  department: string;
  contactOrder: number;
}

class DeviceGeneralTabService {
  static getDevice = async (deviceId): Promise<Device> => {
    const response = await get('DeviceGeneralTabService', {
      operation: 1,
      deviceId,
    });
    return parseDmasAPIResponse(response);
  };

  static updateDevice = async (deviceId, device): Promise<Device> => {
    const response = await post('DeviceGeneralTabService', {
      operation: 2,
      deviceId,
      ...device,
    });
    return parseDmasAPIResponse(response);
  };

  static cloneDevice = async (deviceId, device): Promise<Device> => {
    const response = await post('DeviceGeneralTabService', {
      operation: 100,
      deviceId,
      ...device,
    });
    return parseDmasAPIResponse(response);
  };

  static deleteClone = async (deviceId, device): Promise<Device> => {
    const response = await post('DeviceGeneralTabService', {
      operation: 101,
      DeviceId: deviceId,
      ...device,
    });
    return parseDmasAPIResponse(response);
  };
}

export default DeviceGeneralTabService;
