import { Component } from 'react';
import PropTypes from 'prop-types';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './GridLayout.css';

/** See https://github.com/STRML/react-grid-layout for full props and usage. */
class SimpleGridLayout extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    isStatic: PropTypes.bool,
  };

  static defaultProps = {
    isStatic: true,
  };

  /**
   * Wrap child in div because react-grid-layout works best with divs.
   *
   * @param child React child
   * @returns Div containing child parameter, or returns child if already a div
   */
  wrapChild = (child) => {
    if (child && child.type !== 'div') {
      return <div key={child.key}>{child}</div>;
    }
    return child;
  };

  /**
   * Iterates through children and wraps them in a div if they are not already
   * one.
   *
   * @returns Either one wrapped child or an array of wrapped children,
   *   depending on the amount of children.
   */
  renderChildren = () => {
    const { children } = this.props;

    if (!Array.isArray(children)) {
      return this.wrapChild(children);
    }

    return children.map((child) =>
      // Wrap non-divs so that they work with layout.
      this.wrapChild(child)
    );
  };

  render() {
    const { children, isStatic, ...otherProps } = this.props;
    return (
      <GridLayout
        isDraggable={!isStatic}
        isResizable={!isStatic}
        {...otherProps}
      >
        {this.renderChildren()}
      </GridLayout>
    );
  }
}

export default SimpleGridLayout;
