/**
 * Make HTTP GET and POST queries. If the query's URL doesn't include a domain
 * name, it will be assumed to be Oceans 3.0.
 *
 * @see {@link https://axios-http.com/docs|Axios Documentation} for details on
 * parameters
 */

import { type AxiosResponse, type AxiosRequestConfig } from 'axios';
import { createDmasAPIAxiosInstance } from './axiosInstance';
import DmasError from './DmasError';

/**
 * Handle a promise returned from a request to an implementation of
 * DmasAPIService. A successful (2xx) query will return a data object like
 *
 * ```json
 * { statusCode: <number>, payload: <object> }
 * ```
 *
 * If statusCode is 0 or
 *
 * ```json
 *  { statusCode: <number>, message: <string>}
 * ```
 *
 * If statusCode is non-zero. The first case will resolve and return the
 * payload; the second case will fail and throw the message and the status
 * code.
 */
export const parseDmasAPIResponse = (response) => {
  if (response.data.statusCode !== 0) {
    const error = new DmasError(
      response.data.message,
      response.data.statusCode
    );
    throw error;
  }
  return response.data.payload;
};

/**
 * Make a GET request
 *
 * @param {string} url - The URL of the service to call. You don't need to
 *   include the domain for Oceans 3.0 services (ie., services hosted at
 *   ONC_BASE_URL)
 * @param {object} params - Query parameters
 * @param {object} config - Axios config object
 * @returns {Promise}
 */
export const get = <TResponseData = any, TParams = any, TRequestData = any>(
  url: string,
  params: TParams = undefined,
  config: AxiosRequestConfig<TRequestData> = undefined
): Promise<AxiosResponse<TResponseData, TRequestData>> =>
  createDmasAPIAxiosInstance().request({
    method: 'get',
    url,
    params,
    ...config,
  });

/**
 * Make a GET request that can be cancelled. If the request is cancelled, the
 * response promise will be rejected.
 *
 * @param {string} url - See {@link get}
 * @param {object} params - See {@link get}
 * @param {object} config - See {@link get}
 * @returns {{ request: Promise; cancel: () => void }} - An object with the
 *   response promise (see {@link get}, and a function to call to cancel the
 *   request.
 */
export const getAbortable = (url, params = undefined, config = undefined) => {
  const controller = new AbortController();
  const { signal } = controller;

  const request = get(url, params, {
    signal,
    ...config,
  });

  return {
    request,
    cancel: () => {
      controller.abort();
    },
  };
};

/**
 * Make a GET request that can be cancelled. If the request is cancelled, the
 * response promise will be rejected.
 *
 * @param {string} url - The URL to fetch.
 * @param {AbortSignal} signal - The AbortSignal instance to manage
 *   cancellation.
 * @param {object} params - Optional parameters for the GET request.
 * @param {object} config - Optional configuration for the GET request.
 * @returns {{ request: Promise; cancel: () => void }} - An object with the
 *   response promise and a function to cancel the request.
 */
export const getControlledAbortable = (
  url: string,
  signal: AbortSignal,
  params = undefined,
  config = undefined
) => {
  const request = get(url, params, {
    signal,
    ...config,
  });

  return {
    request,
  };
};

const postImpl = (method, url, data, config = undefined) =>
  createDmasAPIAxiosInstance().request({
    method,
    url,
    data,
    ...config,
  });

/**
 * Make a PUT request. Input is assumed to be application/x-www-form-urlencoded
 * if not otherwise specified.
 *
 * @param {string} url - The URL of the service to call. You don't need to
 *   include the domain for Oceans 3.0 services (ie., services hosted at
 *   ONC_BASE_URL)
 * @param {object} data - PUT data
 * @param {object} config - Optional axios config object. See
 *   https://github.com/axios/axios#request-config
 * @returns {Promise}
 */
export const put = (url, data, config = undefined) =>
  postImpl('put', url, data, config);

/**
 * Make a PATCH request
 *
 * @param {string} url - The URL of the service to call. You don't need to
 *   include the domain for Oceans 3.0 services (ie., services hosted at
 *   ONC_BASE_URL)
 * @param {object} data - PATCH data
 * @param {object} config - Optional axios config object. See
 *   https://github.com/axios/axios#request-config
 * @returns {Promise}
 */
export const patch = (url, data, config = undefined) =>
  postImpl('patch', url, data, config);

/**
 * Make a POST request. Input is assumed to be application/x-www-form-urlencoded
 * if not otherwise specified.
 *
 * @param {string} url - The URL of the service to call. You don't need to
 *   include the domain for Oceans 3.0 services (ie., services hosted at
 *   ONC_BASE_URL)
 * @param {object} data - POST data
 * @param {object} config - Optional axios config object. See
 *   https://github.com/axios/axios#request-config
 * @returns {Promise}
 */
export const post = (url, data, config = undefined) =>
  postImpl('post', url, data, config);

/**
 * Make a DELETE request. Input is assumed to be
 * application/x-www-form-urlencoded if not otherwise specified.
 *
 * Name for function must be 'deleteImpl' because 'delete' is protected
 *
 * @param {string} url - The URL of the service to call. You don't need to
 *   include the domain for Oceans 3.0 services (ie., services hosted at
 *   ONC_BASE_URL)
 * @param {object} data - DELETE data
 * @param {object} config - Optional axios config object. See
 *   https://github.com/axios/axios#request-config
 * @returns {Promise}
 */
export const deleteImpl = (url, data = undefined, config = undefined) =>
  postImpl('delete', url, data, config);
