import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import IpMaintenanceRO from 'domain/AppComponents/ip-maintenance/IpMaintenanceRO';
import IpMaintenanceRW from 'domain/AppComponents/ip-maintenance/IpMaintenanceRW';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(2),
  },
});

const defaultState = {};

class IpMaintenance extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
    }),
    ipId: PropTypes.number,
    deviceId: PropTypes.number,
    history: PropTypes.shape().isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: undefined,
    ipId: undefined,
    deviceId: undefined,
  };

  constructor(props) {
    super(props);
    const { ipId, deviceId } = this.props;
    this.state = {
      ...defaultState,
      deviceId,
      ipId,
    };
  }

  render() {
    const { ipId, deviceId, onError, history } = this.props;

    if (Environment.getDmasUserPrivilege() === 'RW') {
      return (
        <IpMaintenanceRW ipId={ipId} deviceId={deviceId} history={history} />
      );
    }

    return (
      <IpMaintenanceRO
        ipId={ipId}
        deviceId={deviceId}
        history={history}
        onError={onError}
      />
    );
  }
}
export default withStyles(STYLES)(withSnackbars(IpMaintenance));
