import { useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import useDeepCompareEffect from 'domain/hooks/useDeepCompareEffect';
import { TraceData } from 'library/BaseComponents/time-series-charts/TimeSeriesChart';

const useTraceList = (
  traceResults: UseQueryResult<TraceData, Error>[],
  devicesResult: UseQueryResult[],
  isLoading: boolean
) => {
  const [traceList, setTraceList] = useState<TraceData[]>(() => []);

  useDeepCompareEffect(() => {
    if (!isLoading) {
      if (!traceResults.every((traceResult) => traceResult.isError)) {
        setTraceList(
          traceResults
            .filter((traceResult) => traceResult.isSuccess)
            .map((traceResult) => traceResult.data as TraceData)
        );
      } else {
        setTraceList([]);
      }
    }
  }, [isLoading, devicesResult, traceResults]);

  return traceList;
};

export default useTraceList;
