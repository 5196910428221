/* eslint-disable react/no-unused-class-component-methods */
import { createRef, Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { AutoSizer } from 'react-virtualized';
import { GetApp, Refresh } from '@onc/icons';
import {
  HelpLink,
  Checkbox,
  IconButton,
  Divider,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SizeableVirtualList,
  Typography,
} from 'base-components';
import { ShowFilterButton } from 'domain/AppComponents/IconButtons';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import Panel from 'library/CompositeComponents/panel/Panel';
import CookieUtils from 'util/CookieUtils';
import DateFormatUtils from 'util/DateFormatUtils';
import DomUtil from 'util/DomUtils';
import Environment from 'util/Environment';
import AnnotationLine from './AnnotationLine';
import withSnackbars from '../snackbars/withSnackbars';

const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  title: {
    paddingRight: '10px',
  },
  listItem: {
    padding: '0',
    paddingRight: theme.spacing(4),
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '95%',
  },
});
const ANNOTATION_PADDING_WIDTH = 16;
const SMALL_TEXT_EXPECTED_LINE_HEIGHT = 17;
const SMALL_TEXT_ACTUAL_LINE_HEIGHT = 24;
const SCROLLBAR_WIDTH = 17;
const DEFAULT_MENU = {
  'List Options': {
    autoRefresh: 'Auto Refresh',
    displayFullTimestamp: 'Full Timestamp',
    sortAnnotations: 'Sort By Most Recent',
  },
  'Annotation Options': {
    displayCopyLink: 'Copy Link',
    displayEditIcon: 'Edit',
    displayDeleteIcon: 'Delete',
    displayVoteIcons: 'Vote',
    displayCreatedBy: 'Created By',
    displayModifiedBy: 'Modified By',
    displayPositionalData: 'Positional Data',
    displayTaxonAttributes: 'Taxon Attributes',
    toBeReviewed: 'To Be Reviewed',
  },
};

class AnnotationList extends Component {
  static propTypes = {
    onInfo: PropTypes.func.isRequired,
    onListItemClick: PropTypes.func.isRequired,
    loadAnnotations: PropTypes.func.isRequired,
    onAnnotationSort: PropTypes.func.isRequired,
    onToggleFilter: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    classes: PropTypes.shape({
      listItem: PropTypes.string,
      noData: PropTypes.string,
      root: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
    filter: PropTypes.shape({
      toBeReviewed: PropTypes.bool,
    }),
    totalListItems: PropTypes.number,
    onAnnotationEditClick: PropTypes.func,
    onAnnotationDeleteClick: PropTypes.func,
    deletePermissions: PropTypes.bool,
    selfDeletePermissions: PropTypes.bool,
    annotationSortByMostRecent: PropTypes.bool,
    annotationPermissions: PropTypes.bool,
    reviewPermissions: PropTypes.bool,
    menu: PropTypes.node,
    onApplyFilter: PropTypes.func,
    loading: PropTypes.bool,
    currentUserId: PropTypes.number,
    fullTimestamp: PropTypes.bool,
    resourceTypeId: PropTypes.number,
    resourceId: PropTypes.number,
    renderRefreshButton: PropTypes.bool,
    renderDownloadButton: PropTypes.bool,
    menuOptions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
    onUpdateConfig: PropTypes.func,
    onDownloadClick: PropTypes.func,
    userType: PropTypes.string,
    listItems: PropTypes.arrayOf(PropTypes.shape({})),
    currentTimestamp: PropTypes.instanceOf(Date),
    selectedAnnotationId: PropTypes.number,
    listHeight: PropTypes.number,
    onReviewClick: PropTypes.func,
    lastEditedAnnotationId: PropTypes.number,
    editAnnotationId: PropTypes.number,
  };

  static defaultProps = {
    totalListItems: 0,
    onAnnotationEditClick: undefined,
    onAnnotationDeleteClick: undefined,
    deletePermissions: undefined,
    selfDeletePermissions: undefined,
    annotationPermissions: undefined,
    reviewPermissions: undefined,
    menu: undefined,
    onApplyFilter: undefined,
    loading: false,
    annotationSortByMostRecent: false,
    currentUserId: -1,
    fullTimestamp: false,
    resourceTypeId: undefined,
    resourceId: undefined,
    filter: undefined,
    onUpdateConfig: () => {},
    onDownloadClick: undefined,
    renderRefreshButton: true,
    renderDownloadButton: false,
    userType: undefined,
    currentTimestamp: undefined,
    listItems: [],
    selectedAnnotationId: undefined,
    menuOptions: DEFAULT_MENU,
    listHeight: undefined,
    onReviewClick: undefined,
    lastEditedAnnotationId: undefined,
    editAnnotationId: undefined,
  };

  shouldDisplayCopyLink = (menuOptions, config) => {
    if (
      menuOptions &&
      menuOptions['Annotation Options'] &&
      menuOptions['Annotation Options'].displayCopyLink
    ) {
      return config ? config.displayCopyLink : true;
    }
    return false;
  };

  primaryTextStyle = {
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
  };

  secondaryTextStyle = {
    fontSize: '0.74rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.03333em',
  };

  constructor(props) {
    super(props);
    let config;
    if (props.userType) {
      config = CookieUtils.getCookie(`${props.userType}-${props.name}-config`);
    }

    this.state = {
      autoRefresh: config ? config.autoRefresh : false,
      displayCopyLink: this.shouldDisplayCopyLink(props.menuOptions, config),
      displayEditIcon: config ? config.displayEditIcon : true,
      displayDeleteIcon: config ? config.displayDeleteIcon : true,
      displayVoteIcons: config ? config.displayVoteIcons : true,
      sortAnnotations: config ? config.sortAnnotations : false,
      displayCreatedBy: config ? config.displayCreatedBy : false,
      displayFullTimestamp: config
        ? config.displayFullTimestamp
        : props.fullTimestamp,
      displayModifiedBy: config ? config.displayModifiedBy : false,
      displayPositionalData: config ? config.displayPositionalData : false,
      displayTaxonAttributes: config ? config.displayTaxonAttributes : false,
      toBeReviewed: config ? config.toBeReviewed : false,
      menuState: props.menuOptions,
      filterCount: 0,
      filteredListItems: props.listItems,
      listRef: createRef(),
      selectedIndex: undefined,
    };
    this.interval = null;
  }

  /* LIFECYCLE METHODS */
  componentDidMount() {
    this.startAutoRefresh();
  }

  componentDidUpdate(prevProps, prevState) {
    // Current
    const {
      listItems,
      annotationSortByMostRecent,
      onAnnotationSort,
      currentTimestamp,
      annotationPermissions,
      deletePermissions,
      selfDeletePermissions,
      reviewPermissions,
    } = this.props;
    const { autoRefresh, sortAnnotations: sortState } = this.state;
    // Previous
    const {
      annotationSortByMostRecent: oldAnnotationSortByMostRecent,
      listItems: prevListItems,
      currentTimestamp: prevTimestamp,
      annotationPermissions: prevAnnotationPermissions,
      deletePermissions: prevDeletePermissions,
      selfDeletePermissions: prevSelfDeletePermissions,
      reviewPermissions: prevReviewPermisssions,
    } = prevProps;
    const { autoRefresh: oldAutoRefresh, sortAnnotations: prevSortState } =
      prevState;

    if (sortState !== prevSortState) {
      this.setFilteredListItems(listItems);
    }

    if (
      prevAnnotationPermissions !== annotationPermissions ||
      prevDeletePermissions !== deletePermissions ||
      prevSelfDeletePermissions !== selfDeletePermissions ||
      prevReviewPermisssions !== reviewPermissions
    ) {
      this.buildMenuObject();
    }

    if (prevTimestamp !== currentTimestamp) {
      this.selectClosestListItem(currentTimestamp);
    }
    if (autoRefresh !== oldAutoRefresh) this.startAutoRefresh();
    if (listItems !== prevListItems) {
      this.setFilteredListItems(listItems);
      this.selectClosestListItem(currentTimestamp);
      onAnnotationSort(sortState);
      this.recomputeRowHeights();
    }
    if (
      oldAnnotationSortByMostRecent !== annotationSortByMostRecent &&
      annotationSortByMostRecent !== sortState
    ) {
      onAnnotationSort(annotationSortByMostRecent);
      this.setFilteredListItems(listItems);
      this.setSortAnnotations(annotationSortByMostRecent);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /* ----------------- */

  buildMenuObject = () => {
    const {
      annotationPermissions,
      deletePermissions,
      selfDeletePermissions,
      reviewPermissions,
    } = this.props;
    const { menuState } = this.state;
    const menu = { ...menuState };

    if (!annotationPermissions && menu['Annotation Options'].displayEditIcon) {
      delete menu['Annotation Options'].displayEditIcon;
    }

    if (
      !deletePermissions &&
      !selfDeletePermissions &&
      menu['Annotation Options'].displayDeleteIcon
    ) {
      delete menu['Annotation Options'].displayDeleteIcon;
    }

    if (!reviewPermissions && menu['Annotation Options'].displayVoteIcons) {
      delete menu['Annotation Options'].displayVoteIcons;
    }

    this.setState({ menuState: menu });
  };

  selectClosestListItem = (timestamp) => {
    const { filteredListItems, sortAnnotations, selectedIndex } = this.state;
    const timeStampSeconds = Math.floor(new Date(timestamp).getTime() / 1000);

    /** Prevents weird behavior when annotations have the same timestamp */
    if (filteredListItems[selectedIndex]) {
      const currentSeconds = Math.floor(
        new Date(filteredListItems[selectedIndex].startDate).getTime() / 1000
      );

      if (currentSeconds === timeStampSeconds) {
        return;
      }
    }

    if (!filteredListItems || !filteredListItems.length) {
      return;
    }
    let index = 0;
    let currAnnotationTimestamp;
    for (index; index < filteredListItems.length; index += 1) {
      currAnnotationTimestamp = new Date(filteredListItems[index].startDate);
      const currentTimeStampSeconds = Math.floor(
        currAnnotationTimestamp.getTime() / 1000
      );
      // if the current annotation is "past" the timestamp, stop!
      if (
        (sortAnnotations && currentTimeStampSeconds < timeStampSeconds) ||
        (!sortAnnotations && currentTimeStampSeconds > timeStampSeconds)
      ) {
        break;
      }
    }

    // grab the annotation and index of the "correct" annotation
    if (!sortAnnotations) {
      index -= 1;
      index = index < 0 ? 0 : index; // if it's less than 0, make it 0
    }

    const item = filteredListItems[index];

    if (item) {
      this.setState({
        selectedIndex: index,
        scrollToIndex: index,
        selectedAnnotation: item.annotationId ? item : undefined,
      });
    }
  };

  startAutoRefresh = () => {
    // question: whether auto-refresh should close-over the annotations prop, or retrieve it on every refresh
    // source: https://overreacted.io/how-are-function-components-different-from-classes/
    const { loadAnnotations } = this.props;
    const { autoRefresh } = this.state;

    clearInterval(this.interval);
    if (autoRefresh)
      this.interval = setInterval(() => {
        loadAnnotations();
      }, 30 * 1000);
  };

  setSortAnnotations = (sortByMostRecent) => {
    this.setState(
      { sortAnnotations: sortByMostRecent },
      this.recomputeRowHeights
    );
  };

  updateConfig = () => {
    const { name, onUpdateConfig } = this.props;
    const {
      autoRefresh,
      sortAnnotations,
      displayCopyLink,
      displayEditIcon,
      displayDeleteIcon,
      displayVoteIcons,
      displayCreatedBy,
      displayFullTimestamp,
      displayModifiedBy,
      displayPositionalData,
      displayTaxonAttributes,
      toBeReviewed,
    } = this.state;
    const config = {
      autoRefresh,
      sortAnnotations,
      displayCopyLink,
      displayEditIcon,
      displayDeleteIcon,
      displayVoteIcons,
      displayCreatedBy,
      displayFullTimestamp,
      displayModifiedBy,
      displayPositionalData,
      displayTaxonAttributes,
      toBeReviewed,
    };
    onUpdateConfig(name, config);
  };

  renderAnnotations = () => {
    const { classes, loading, listHeight } = this.props;
    const { filteredListItems, scrollToIndex } = this.state;

    if (filteredListItems && filteredListItems.length === 0 && !loading) {
      return <div className={classes.noData}>No Data</div>;
    }
    if (!loading) {
      return (
        <AutoSizer disableHeight={listHeight}>
          {({ height, width }) => (
            <SizeableVirtualList
              height={listHeight || height}
              width={width}
              rowHeight={(info) =>
                this.decideRowHeight(info, width, filteredListItems)
              }
              rowRenderer={(info) =>
                this.renderListLine(info, filteredListItems)
              }
              rowCount={filteredListItems.length}
              setRef={this.setRef}
              scrollToIndex={scrollToIndex}
              scrollToAlignment="center"
            />
          )}
        </AutoSizer>
      );
    }
    return undefined;
  };

  setFilteredListItems = (listItems) => {
    const { selectedAnnotationId } = this.props;
    const { sortAnnotations } = this.state;
    let filteredListItems = [...listItems];

    filteredListItems = filteredListItems.sort((a, b) => {
      const date1 = a.startDate;
      const date2 = b.startDate;
      if (sortAnnotations) {
        return new Date(date2) - new Date(date1);
      }
      return new Date(date1) - new Date(date2);
    });
    let selectedIndex;
    filteredListItems.forEach((item, index) => {
      if (item.annotationId && item.annotationId === selectedAnnotationId) {
        selectedIndex = index;
      }
    });
    return this.setState({
      filteredListItems,
      selectedIndex,
      scrollToIndex: selectedIndex,
    });
  };

  calculatePrimaryTextHeight = (text, width) =>
    DomUtil.calculateTextHeight(text, width, {
      style: this.primaryTextStyle,
      id: 'primary-text-height-div',
    });

  calculateSecondaryTextHeight = (text, width) =>
    DomUtil.calculateTextHeight(text, width, {
      style: this.secondaryTextStyle,
      id: 'secondary-text-height-div',
      modifier: SMALL_TEXT_ACTUAL_LINE_HEIGHT / SMALL_TEXT_EXPECTED_LINE_HEIGHT,
    });

  getAnnotationWidth = (width, annotation) => {
    const {
      displayFullTimestamp,
      displayCopyLink,
      displayEditIcon,
      displayDeleteIcon,
      displayVoteIcons,
    } = this.state;

    let currWidth = width;
    // Outer left padding
    currWidth -= ANNOTATION_PADDING_WIDTH;
    // Annotation width
    currWidth -= AnnotationLine.getAnnotationContentWidth(
      [displayCopyLink, displayEditIcon, displayDeleteIcon],
      displayFullTimestamp,
      displayVoteIcons &&
        annotation.taxons &&
        annotation.taxons[0].taxonomyCode === 'WoRMS'
    );
    // Outer right padding
    currWidth -= ANNOTATION_PADDING_WIDTH;
    // Assumes it has scrollbar, if no scroll bar needed spacing might be wonkey
    currWidth -= SCROLLBAR_WIDTH;
    return currWidth;
  };

  decideRowHeight = ({ index }, width, listItems) => {
    const item = listItems[index];
    if (item.annotationId) {
      return this.decideAnnotationRowHeight(item, width);
    }
    return 0;
  };

  decideAnnotationRowHeight = (item, width) => {
    const {
      displayCreatedBy,
      displayModifiedBy,
      displayPositionalData,
      displayTaxonAttributes,
      displayFullTimestamp,
    } = this.state;
    const annotation = { ...item };
    // Top/Bottom padding (8/8)
    let height = ANNOTATION_PADDING_WIDTH;
    const currWidth = this.getAnnotationWidth(width, annotation);

    // Calculate height needed for comment if enabled and exist
    if (annotation.comment === '') {
      if (!annotation.taxons)
        height += this.calculatePrimaryTextHeight('-', currWidth);
    } else {
      height += this.calculatePrimaryTextHeight(annotation.comment, currWidth);
    }

    // Calculate height needed for positional data if enabled and exist
    if (displayPositionalData && annotation.lat !== 0 && annotation.lon !== 0) {
      const positionString = AnnotationLine.generateLocationString(
        annotation.lat,
        annotation.lon,
        annotation.heading,
        annotation.depth
      );
      height += this.calculateSecondaryTextHeight(positionString, currWidth);
    }

    // Calculate height needed for taxons if enabled and exist
    if (annotation.taxons && annotation.taxons[0].displayText) {
      height += this.calculatePrimaryTextHeight(
        `${annotation.taxons[0].displayText} [${annotation.taxons[0].taxonomyCode}]`,
        currWidth
      );
    }

    // Calculate height needed for attributes if enabled and exist
    if (displayTaxonAttributes) {
      if (annotation.taxons && annotation.taxons[0].attributes) {
        annotation.taxons[0].attributes.forEach((attribute) => {
          const attributeText = `${attribute.name}: ${attribute.value}`;
          height += this.calculatePrimaryTextHeight(attributeText, currWidth);
        });
      }
    }
    let displayCreatedOrModifyBy = false;
    // Calculate height needed for created by if enabled and exist
    if (displayCreatedBy) {
      displayCreatedOrModifyBy = true;
      const createdByText = `Created By: ${annotation.createdBy.firstName} ${
        annotation.createdBy.lastName
      } (${DateFormatUtils.formatDate(annotation.createdDate, 'full')})`;
      height += this.calculateSecondaryTextHeight(createdByText, currWidth);
    }

    // Calculate height needed for modified by if enabled and exist
    if (
      displayModifiedBy &&
      annotation.createdDate !== annotation.modifiedDate
    ) {
      displayCreatedOrModifyBy = true;
      const modifiedByText = `Modified By: ${annotation.modifiedBy.firstName} ${
        annotation.modifiedBy.lastName
      } (${DateFormatUtils.formatDate(annotation.modifiedDate, 'full')})`;
      height += this.calculateSecondaryTextHeight(modifiedByText, currWidth);
    }
    if (displayFullTimestamp && !displayCreatedOrModifyBy) {
      // The fulltimestamp often cuts off some of the comment so the height needs to be expanded.
      height += 6;
    }
    // Border width
    height += 1;

    return height;
  };

  setRef = (ref) => {
    this.setState({ listRef: ref });
  };

  renderListLine = (info, listItems) => {
    const item = listItems[info.index];
    if (item.annotationId) {
      return this.renderAnnotationLine(info, item);
    }
    return undefined;
  };

  handleItemClick = (item, index) => {
    const { onListItemClick } = this.props;
    onListItemClick(item, index);
    this.setState({ selectedIndex: index });
  };

  renderAnnotationLine = (info, item) => {
    const {
      onAnnotationDeleteClick,
      onAnnotationEditClick,
      deletePermissions,
      selfDeletePermissions,
      reviewPermissions,
      currentUserId,
      onReviewClick,
      lastEditedAnnotationId,
      editAnnotationId,
    } = this.props;
    const {
      displayFullTimestamp,
      displayPositionalData,
      displayCopyLink,
      displayEditIcon,
      displayDeleteIcon,
      displayVoteIcons,
      displayModifiedBy,
      displayCreatedBy,
      displayTaxonAttributes,
      toBeReviewed,
      selectedIndex,
    } = this.state;
    const annotation = { ...item };
    const { annotationId } = annotation;
    let selected;

    if (selectedIndex !== undefined) {
      selected = info.index === selectedIndex;
    }

    return (
      <AnnotationLine
        style={info.style}
        listIndex={info.index}
        key={annotationId}
        annotation={annotation}
        onAnnotationEditClick={onAnnotationEditClick}
        onAnnotationClick={() => this.handleItemClick(item, info.index)}
        onAnnotationDeleteClick={onAnnotationDeleteClick}
        onCopyLink={this.handleCopyAnnotationLink}
        selected={selected}
        displayFullTimestamp={displayFullTimestamp}
        displayPositionalData={displayPositionalData}
        displayModifiedBy={displayModifiedBy}
        displayCopyLink={displayCopyLink}
        displayEditIcon={displayEditIcon}
        displayDeleteIcon={displayDeleteIcon}
        displayVoteIcons={displayVoteIcons}
        displayCreatedBy={displayCreatedBy}
        displayTaxonAttributes={displayTaxonAttributes}
        highlightToBeReviewed={toBeReviewed}
        deletePermissions={
          deletePermissions ||
          (selfDeletePermissions &&
            annotation.createdBy.userId === currentUserId)
        }
        annotationPermissions={this.determineAnnotationPermissions(annotation)}
        reviewPermissions={reviewPermissions}
        onReviewClick={onReviewClick}
        lastEditedAnnotationId={lastEditedAnnotationId}
        editAnnotationId={editAnnotationId}
      />
    );
  };

  determineAnnotationPermissions = (annotation) => {
    const { annotationPermissions, currentUserId, resourceTypeId } = this.props;

    switch (resourceTypeId) {
      case SeaTubeResourceTypes.SEARCH_TREE_NODE:
        return (
          annotationPermissions && annotation.createdBy.userId === currentUserId
        );
      default:
        return annotationPermissions;
    }
  };

  handleCopyAnnotationLink = (annotation) => {
    const { resourceTypeId, resourceId, onInfo } = this.props;
    const env = Environment.getDmasUrl();
    let url = `${env}/SeaTubeV3?`;
    url += `resourceTypeId=${resourceTypeId}&`;
    url += `resourceId=${resourceId}&`;
    url += `time=${annotation.startDate}&`;
    url += `annotationId=${annotation.annotationId}`;
    navigator.clipboard.writeText(`${url}`);
    onInfo('Link Copied to Clipboard');
  };

  handleFilterCountChange = (e) => {
    this.setState({ filterCount: e });
  };

  handleApplyFilter = () => {
    const { onApplyFilter, onToggleFilter } = this.props;
    onApplyFilter();
    onToggleFilter();
  };

  handleBooleanChange = (value) => () => {
    // here, it's grabbing by key name from the state, and renaming it to currValue
    const { [value]: currValue } = this.state;
    const { onAnnotationSort } = this.props;

    if (value === 'sortAnnotations') {
      onAnnotationSort(!currValue);
    }

    // force react-virtualized list to update after state change so the row heights are correct
    this.setState({ [value]: !currValue }, () => {
      this.recomputeRowHeights();
      this.updateConfig();
    });
  };

  /**
   * Call react-virtualized List ref to recompute row height. Call as a ref
   * because List only rerenders if the number of items in a list changes, not
   * the order, and the documentation and examples use refs. ¯_(ツ)_/¯ See
   * comment in listRef state at top of file.
   */
  recomputeRowHeights = () => {
    const { listRef } = this.state;
    if (listRef && listRef.recomputeRowHeights) {
      listRef.recomputeRowHeights(0);
    }
  };

  createMenuItem = (key, checked, label) => {
    const { classes } = this.props;
    return (
      <MenuItem
        className={classes.listItem}
        key={key}
        onClick={this.handleBooleanChange(key)}
      >
        <Checkbox checked={checked} label={key} />
        {label}
      </MenuItem>
    );
  };

  renderMenu = () => {
    const { menu } = this.props;
    const { menuState } = this.state;
    return (
      <div>
        {Object.entries(menuState).map(([menuOption, subOptions]) => (
          <div key={menuOption}>
            <ListItem>
              <ListItemText disableTypography>
                <Typography variant="subtitle1">{`${menuOption}:`}</Typography>
              </ListItemText>
            </ListItem>
            <Menu key={menuOption} menuList>
              {Object.entries(subOptions).map(([key, label]) => {
                const { [key]: checked } = this.state;
                return this.createMenuItem(key, checked, label);
              })}
            </Menu>
            <Divider />
          </div>
        ))}
        <HelpLink url="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help#SeaTubeV3Help-AnnotationListWidget" />
        <Divider />
        {menu}
      </div>
    );
  };

  render() {
    const {
      classes,
      totalListItems,
      loadAnnotations,
      loading,
      filter,
      renderRefreshButton,
      renderDownloadButton,
      onToggleFilter,
    } = this.props;
    const { filteredListItems } = this.state;

    const titleContent = (
      <Typography className={classes.title} variant="body1">
        Annotation List
      </Typography>
    );
    let filterButton;
    if (filter) {
      filterButton = <ShowFilterButton onClick={() => onToggleFilter(true)} />;
    }
    let refreshButton;
    if (renderRefreshButton) {
      refreshButton = (
        <IconButton
          onClick={loadAnnotations}
          aria-label="Refresh"
          Icon={Refresh}
        />
      );
    }
    let downloadButton;
    if (renderDownloadButton) {
      const { onDownloadClick } = this.props;
      downloadButton = (
        <IconButton
          aria-label="Download Data Product"
          onClick={onDownloadClick}
          Icon={GetApp}
        />
      );
    }
    const actionContent = (
      <>
        {refreshButton}
        {filterButton}
        {downloadButton}
        <Typography variant="body1" label="Annotation Count">
          {`${
            filteredListItems ? filteredListItems.length : 0
          } of ${totalListItems}`}
        </Typography>
      </>
    );
    return (
      <div className={classes.root}>
        <Panel
          id="seatube-annotation-list-panel"
          title={titleContent}
          actionContent={actionContent}
          menu={this.renderMenu()}
          menuProps={{
            closeOnClick: false,
            buttonLabel: 'Annotation List Menu',
          }}
          headerDraggable
          loading={loading}
          enableOverflow
        >
          <Divider />
          {this.renderAnnotations()}
        </Panel>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(AnnotationList));
