import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import DateUtils from 'util/DateUtils';

class UTCTimestamp extends PureComponent {
  static propTypes = {
    playerId: PropTypes.string.isRequired,
    time: PropTypes.instanceOf(Date),
    querySelector: PropTypes.func,
  };

  static defaultProps = {
    time: undefined,
    querySelector: undefined,
  };

  render() {
    const { playerId, querySelector, time } = this.props;

    if (!querySelector) return null;
    const container = querySelector('.jw-button-container');
    if (!container) return null;

    const TimeStamp = (
      <div
        id={`${playerId}-utc-timestamp`}
        className="jw-icon jw-icon-inline jw-text utc-timestamp"
        style={{ whiteSpace: 'nowrap', width: 'auto' }}
        aria-label="timestamp"
      >
        {DateUtils.formatDateAsString(time)}
      </div>
    );
    return createPortal(TimeStamp, container);
  }
}

export default UTCTimestamp;
