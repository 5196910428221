/** Represents an error that occurs during a DMAS API request. */
export default class DmasError extends Error {
  statusCode: number;

  /**
   * Creates a new instance of the DmasError class.
   *
   * @param {string} message The error message.
   * @param {number} statusCode The HTTP status code associated with the error.
   */
  constructor(message: string, statusCode: number) {
    super(message);
    this.name = 'DmasError';
    this.statusCode = statusCode;
  }
}
