import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ErrorSnackbar } from '@onc/composite-components';
import Environment from 'util/Environment';
import MediaUtils from './MediaUtils';

const withArchiveFilesNotContinuous =
  (files, title, description) => (VideoComponent) => {
    class WithArchiveFilesNotContinuous extends PureComponent {
      static propTypes = {
        classes: PropTypes.shape({
          circularProgress: PropTypes.string,
        }),
      };

      static defaultProps = {
        classes: {},
      };

      state = { playlist: undefined, error: undefined };

      componentDidMount() {
        this.setState({ loading: true });
        //   this.setVideoPlayList();
        this.setState({ playlist: this.getPlaylist(), loading: false });
      }

      getPlaylist = () =>
        files.map((file) => ({
          title,
          description: `${description}${description ? ' (' : ''}${
            file.dateFrom
          } - ${file.dateTo}${description ? ')' : ''}`,
          file: `${Environment.getMediaBaseUrl()}${file.archiveLocation}/${
            file.path
          }/${file.filename}/${MediaUtils.getRequestFormat()}`,
        }));

      render() {
        const { playlist, error } = this.state;
        const { classes, ...rest } = this.props;
        let errorSnackbar = null;
        if (error) {
          errorSnackbar = <ErrorSnackbar message={error.message} />;
        }

        if (playlist) {
          return (
            <>
              {errorSnackbar}
              <VideoComponent
                playlist={playlist}
                baseURL={Environment.getMediaBaseUrl()}
                requestFormat={MediaUtils.getRequestFormat()}
                autoPlay
                customProps={{ displaydescription: true }}
                {...rest}
              />
            </>
          );
        }

        return null;
      }
    }

    return WithArchiveFilesNotContinuous;
  };

export default withArchiveFilesNotContinuous;
