import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import CANARIE_IMG from 'assets/images/canarie-hsm.png';
import GLOBAL_STUDIES_IMG from 'assets/images/cfgs-hsm.png';
import { Typography } from 'base-components';

const styles = {
  container: {
    backgroundImage:
      '-webkit-linear-gradient(top,#4b4842 0,#2e2e2d 90%)!important',
    width: '100%',
    minHeight: '35px',
    borderBottom: '2px solid #000',
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  onc: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 600,
    textDecoration: 'none',
    float: 'left',
    padding: '6px 0px 0px 18px',
  },
  gc: {
    float: 'left',
    padding: '4px 20px 4px 20px',
  },
  canarie: {
    float: 'right',
    padding: '4px 8px 4px 0px',
  },
};

class DFTitleBar extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      backgroundImage: PropTypes.string,
      canarie: PropTypes.string,
      container: PropTypes.string,
      gc: PropTypes.string,
      onc: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography>
          <a
            href="http://www.oceannetworks.ca/"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.onc}
          >
            OCEAN NETWORKS CANADA
          </a>
        </Typography>
        <a
          href="http://www.globalcentres.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src={GLOBAL_STUDIES_IMG}
            alt="center for global studies"
            className={classes.gc}
          />
        </a>
        <a
          href="http://www.canarie.ca/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={CANARIE_IMG} alt="canarie" className={classes.canarie} />
        </a>
      </div>
    );
  }
}

export default withStyles(styles)(DFTitleBar);
