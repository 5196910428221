import { useState, useEffect, useCallback } from 'react';
import { Dropdown, type DropdownOption, Grid } from 'base-components';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import { Clause } from 'domain/Apps/data-preview-management/DataPreview';
import ResourceService from 'domain/services/ResourceService';

type Props = {
  onChange: (value: Clause, index: number) => void;
  handleRemove: (index: number) => void;
  index: number;
  value: Clause;
  numberOfComp: number;
};

// value is resourceTypeId. It's labelled as value for the dropdown
const dropDownOptions: DropdownOption[] = [
  { label: 'Search Tree Node', value: 11, key: '11' },
  { label: 'Device Category', value: 100, key: '100' },
  { label: 'Sensor Code', value: 106, key: '106' },
  { label: 'Time Config', value: 108, key: '108' },
  { label: 'Data Product', value: 6, key: '6' },
  { label: 'Data Product Format', value: 107, key: '107' },
  { label: 'Device', value: 2, key: '2' },
  { label: 'Sensor', value: 102, key: '102' },
];

const DataPreviewClauseSelects = ({
  onChange,
  handleRemove,
  index,
  value,
  numberOfComp,
}: Props) => {
  const [selectBottomOptions, setSelectBottomOptions] = useState<any[]>([]);

  const handleOptions = useCallback(
    async (selection: number) => {
      let options: DropdownOption[] = [];
      const response = await ResourceService.getResourcesByResourceTypeId(
        selection.toString()
      );
      options = response.map((option) => ({
        label: option.name,
        value: option.id,
        key: option.id,
      }));
      setSelectBottomOptions(options);
      if (!value.resourceId) {
        onChange(
          {
            resourceTypeId: value.resourceTypeId,
            resourceId: options[0] ? (options[0].value as number) : undefined,
          },
          index
        );
      }
    },
    [index, onChange, value.resourceId, value.resourceTypeId]
  );

  const handleTopChange = (e) => {
    onChange(
      {
        resourceTypeId: e.target.value,
      },
      index
    );
  };

  const handleBottomChange = (e) => {
    onChange(
      {
        resourceId: e.target.value,
        resourceTypeId: value.resourceTypeId,
      },
      index
    );
  };

  const isBottomOptionPresent = () =>
    value.resourceId &&
    selectBottomOptions
      .map((option) => option.value)
      .includes(value.resourceId);

  useEffect(() => {
    if (value.resourceTypeId) {
      handleOptions(value.resourceTypeId);
    }
  }, [value.resourceTypeId, handleOptions]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Dropdown
            value={value.resourceTypeId ? value.resourceTypeId.toString() : ''}
            fullWidth
            onChange={handleTopChange}
            variant="filled"
            label="Resource Type Select"
            aria-label="Resource Type Select"
            options={dropDownOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            value={isBottomOptionPresent() ? value.resourceId.toString() : ''}
            fullWidth
            onChange={handleBottomChange}
            variant="filled"
            label="Resource Select"
            aria-label="Resource Select"
            options={selectBottomOptions}
          />
          <Grid item xs={12}>
            <DeleteIconButton
              onClick={() => handleRemove(index)}
              disabled={numberOfComp <= 1}
              aria-label="delete"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DataPreviewClauseSelects;
