import { MenuItem } from 'base-components';

type HelpLinkProps = {
  url: string;
};
const HelpLink = ({ url }: HelpLinkProps) => (
  <MenuItem
    onClick={() => {
      window.open(url);
    }}
  >
    Help
  </MenuItem>
);

export default HelpLink;
