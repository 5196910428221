import { Button, ButtonProps } from 'base-components';

// These buttons should cover 99% of cases for Text based buttons. If a new one is needed please consult with your senior developer first.
type VariantButtonProps = Omit<ButtonProps, 'variant'>;
export const ContainedButton = ({ ...props }: VariantButtonProps) => (
  <Button variant="contained" {...props} />
);
export const OutlinedButton = ({ ...props }: VariantButtonProps) => (
  <Button variant="outlined" {...props} />
);
export const TextButton = ({ ...props }: VariantButtonProps) => (
  <Button variant="text" {...props} />
);

type LabelledButtonProps = Omit<VariantButtonProps, 'translationKey'>;
export const SaveButton = ({ ...props }: LabelledButtonProps) => (
  <ContainedButton
    translationKey="common.buttons.save"
    type="submit"
    {...props}
  />
);

export const CreateButton = ({ ...props }: LabelledButtonProps) => (
  <ContainedButton
    translationKey="common.buttons.create"
    type="submit"
    {...props}
  />
);

type CommonButtonProps = Omit<ButtonProps, 'translationKey' | 'variant'> & {
  variant?: 'text' | 'contained' | 'outlined';
};
/**
 * A standard cancel button. Note that even though variant is exposed it should
 * not be set away from the default in almost every case see buttons.mdx for
 * usage.
 */
export const CancelButton = ({
  variant = 'text',
  ...props
}: CommonButtonProps) => (
  <Button variant={variant} translationKey="common.buttons.cancel" {...props} />
);

/** A standard delete button. See Buttons.mdx for usage. */
export const DeleteButton = ({
  variant = 'text',
  ...props
}: CommonButtonProps) => (
  <Button variant={variant} translationKey="common.buttons.delete" {...props} />
);

/** A standard clear button. See Buttons.mdx for usage. */
export const ClearButton = ({
  variant = 'text',
  ...props
}: CommonButtonProps) => (
  <Button variant={variant} translationKey="common.buttons.clear" {...props} />
);
