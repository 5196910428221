import React, { useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import SavePlaylistDialog from 'domain/AppComponents/playlist/SavePlaylistDialog';
import { useSnackbars } from 'util/hooks/useSnackbars';

const DEVICE_DATA = 1000;
type SeamlessVideoRecordingProps = {
  deviceId: number;
  onClose: () => void;
  recording: boolean;
  startTime: Moment;
  endTime: Moment;
  onPause?: () => void;
};

const SeamlessVideoRecording: React.FC<SeamlessVideoRecordingProps> = ({
  deviceId,
  onClose,
  recording,
  startTime,
  endTime,
  onPause = undefined,
}: SeamlessVideoRecordingProps) => {
  const { onError, onInfo } = useSnackbars();
  const prevRecording = useRef(recording);

  const [openSavePlaylistDialog, setOpenSavePlaylistDialog] = useState(false);
  const [playlistLine, setPlaylistLine] = useState(null);

  const generatePlaylistLine = () => {
    if (moment(endTime).isSame(startTime)) {
      onError('Recordings must be at least 1 second long');
      return;
    }

    onPause();
    setOpenSavePlaylistDialog(true);
    setPlaylistLine({
      resourceId: deviceId,
      resourceTypeId: DEVICE_DATA,
      startDate: startTime.toDate(),
      endDate: endTime.toDate(),
    });
  };

  useEffect(() => {
    // Recording ended
    if (!recording && prevRecording) {
      if (!startTime || !endTime) {
        onError('An error occured while recording');
      } else {
        onInfo('Stopped recording');
        generatePlaylistLine();
      }
      prevRecording.current = recording;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording, prevRecording]);

  const handleClosePlaylistDialog = () => {
    onClose();
    setPlaylistLine(null);
    setOpenSavePlaylistDialog(false);
  };

  return (
    <SavePlaylistDialog
      open={openSavePlaylistDialog}
      onClose={handleClosePlaylistDialog}
      playlistLines={[playlistLine]}
      onInfo={onInfo}
      onError={onError}
    />
  );
};

export default SeamlessVideoRecording;
