import { Children, ReactNode } from 'react';

import { Grid } from 'base-components';

type CardListProps = {
  children: ReactNode;
};
const CardList = ({ children }: CardListProps) => {
  const renderGridCards = () =>
    Children.map(children, (c) => (
      <Grid item xs={12} sm={6} lg={3}>
        {c}
      </Grid>
    ));

  return (
    <Grid container justifyContent="left" spacing={4}>
      {renderGridCards()}
    </Grid>
  );
};

export default CardList;
