// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { object } from 'prop-types';
import Environment from 'util/Environment';

class AdFileService {
  static getAllDownloadFiles(selectedRowFileNames, archiveReadyName) {
    let params = '';
    const len = selectedRowFileNames.length;

    for (let i = 0; i < len; i += 1) {
      params += `filelistselect=${selectedRowFileNames[i]}`;
      if (i < len - 1) {
        params += '&';
      }
    }

    if (archiveReadyName !== null && archiveReadyName !== undefined) {
      if (params !== '') {
        params += '&';
      }
      params += 'archiveReadyName=';
      params += archiveReadyName;
    }

    const link = document.createElement('a');
    link.href = `${Environment.getDmasUrl()}/AdFile?${params}`;
    link.setAttribute('download', 'download');

    document.body.appendChild(link);

    link.click();
    link.remove();
  }
}

export default AdFileService;
