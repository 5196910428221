import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from 'base-components';
import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import ErddapManagementService from './ErddapManagementService';
import useHeaderStyles from './useHeaderStyles';
import { ReadOnlyField } from '../Form/Fields/ReadOnlyFields';

const ErddapDatasetLinks = ({ siteDeviceId = undefined, onError }) => {
  const [erddapDatasets, setErddapDatasets] = useState([]);
  useEffect(() => {
    const getDatasets = () => {
      if (!isNaN(siteDeviceId)) {
        ErddapManagementService.getDatasetForSiteDevice(siteDeviceId)
          .then((payload) => {
            if (payload && payload.length > 0) {
              setErddapDatasets(payload);
            }
          })
          .catch((error) => {
            onError(`ERDDAP datasets could not be found. ${error}`);
          });
      }
    };
    getDatasets();
  }, [onError, siteDeviceId]);

  const classes = useHeaderStyles();

  const renderDatasets = () => {
    const datasets = [];
    let keyId = 0;
    if (erddapDatasets && erddapDatasets.length > 0) {
      for (const dataset of erddapDatasets) {
        keyId += 1;
        datasets.push(
          <Grid item xs={12} key={keyId}>
            <OpenInNewLink
              href={`${Environment.getErddapUrl()}/tabledap/${
                dataset.erddapDatasetIdString
              }.html`}
            >
              {dataset.erddapDatasetIdString}
            </OpenInNewLink>
          </Grid>
        );
      }
    } else {
      datasets.push(
        <Grid item xs={12} key={keyId}>
          <ReadOnlyField
            labelText=""
            valueText="No Datasets Found"
            title="datasetLinkField"
          />
        </Grid>
      );
    }
    return datasets;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography className={classes.header}>ERDDAP Datasets</Typography>
      </Grid>
      {renderDatasets()}
      <Grid item xs={12}>
        <OpenInNewLink href={`${Environment.getDmasUrl()}/ErddapManagement`}>
          ERDDAP Management
        </OpenInNewLink>
      </Grid>
    </Grid>
  );
};

ErddapDatasetLinks.propTypes = {
  onError: PropTypes.func.isRequired,
  siteDeviceId: PropTypes.number,
};

export default withSnackbars(ErddapDatasetLinks);
