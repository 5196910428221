import { useContext, useEffect, useState } from 'react';
import { ErrorAlert, Loading } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'base-components';
import { InfoIconButton } from 'domain/AppComponents/IconButtons';
import QueryParameterContext from 'domain/Apps/menu/QueryParameterContext';
import FIXED_CAMERA_APP_TOKEN from 'domain/Apps/seatube/SeaTubeAppTokens';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import LocationsWebServiceWithToken from 'domain/services/LocationsWebServiceWithToken';
import { useSearchTreeNodeDetails } from 'domain/services/SearchTreeNodeService';
import ButtonSetWidget from 'domain/Widgets/ButtonSetWidget';
import FixedCameraAnnotationListWidget from 'domain/Widgets/fixed-camera/FixedCameraAnnotationListWidget';
import FixedCameraClipListWidget from 'domain/Widgets/fixed-camera/FixedCameraClipListWidget';
import FixedCameraDatePickerWidget from 'domain/Widgets/fixed-camera/FixedCameraDatePickerWidget';
import FixedCameraVideoWidget from 'domain/Widgets/fixed-camera/FixedCameraVideoWidget';
import SearchTreeNodeDetails from 'domain/Widgets/fixed-camera/SearchTreeNodeDetails';
import GeneralManualEntryWidget from 'domain/Widgets/GeneralManualEntryWidget';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import { WidgetOption } from 'library/CompositeComponents/dashboard/DashboardTypes';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { useLocalStorage } from 'util/hooks/useStorage';
import useWebService from 'util/hooks/useWebService';
import defaultPlaybackLayout from './layouts/defaultPlaybackLayout';
import SeaTubeDashboard from '../SeaTubeDashboard';

const FixedCameraPlaybackDashboard = () => {
  const { searchTreeNodeId } = useContext(QueryParameterContext);

  Environment.setCurrentApplicationToken(FIXED_CAMERA_APP_TOKEN);

  const [autoPlayStorage] = useLocalStorage<boolean>(
    'fixed-camera-playback-auto-play',
    true
  );

  const initialDashboardState = {
    resourceTypeId: SeaTubeResourceTypes.SEARCH_TREE_NODE,
    resourceId: searchTreeNodeId,
    currentClip: 0,
    autoPlay: autoPlayStorage,
    date: null,
    selectedButton: null,
    hideQuickSave: true,
    videoIntervals: [],
    editAnnotation: null,
  };

  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const widgets: WidgetOption[] = [
    {
      Component: FixedCameraVideoWidget,
      label: 'Fixed Camera Video',
    },
    {
      Component: FixedCameraDatePickerWidget,
      label: 'Video Availability',
    },
    { Component: FixedCameraClipListWidget, label: 'Clip List' },
    { Component: FixedCameraAnnotationListWidget, label: 'Annotation List' },
  ];
  const { onError } = useSnackbars();

  if (Environment.isUserLoggedIn()) {
    widgets.push({
      Component: GeneralManualEntryWidget,
      label: 'Annotation Entry',
    });
    widgets.push({
      Component: ButtonSetWidget,
      label: 'Quick Button Set',
      multiple: true,
    });
  }

  const {
    data: searchTreeNodeDetails,
    isFetched,
    isLoading,
  } = useSearchTreeNodeDetails({
    searchTreeNodeId,
  });

  const [locationData, , getLocations] = useWebService({
    method: LocationsWebServiceWithToken.get,
    onError,
  });

  useEffect(() => {
    if (isFetched) {
      getLocations({ locationCode: searchTreeNodeDetails?.stationCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTreeNodeDetails]);

  const handleClose = () => {
    setOpenDetailsDialog(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (searchTreeNodeDetails?.stationCode === undefined) {
    return <ErrorAlert title="Location not found" />;
  }

  return (
    <>
      <SeaTubeDashboard
        id="fixed-camera-playback"
        title={searchTreeNodeDetails?.name}
        layoutKey="fixed-camera-playback"
        appName="fixed-camera-playback"
        widgetLibrary={new WidgetLibrary(widgets)}
        defaultLayout={defaultPlaybackLayout}
        initialDashboardState={{
          ...initialDashboardState,
          searchTreeNodeCode: searchTreeNodeDetails?.stationCode,
        }}
        actionComponents={[
          <InfoIconButton
            color="inherit"
            onClick={() => setOpenDetailsDialog(true)}
          />,
        ]}
      />
      <Dialog
        open={openDetailsDialog}
        fullWidth
        onClose={() => setOpenDetailsDialog(false)}
      >
        <DialogContent>
          <DialogTitle>Details</DialogTitle>
          <SearchTreeNodeDetails
            searchTreeNodeDetails={locationData?.data[0]}
          />
        </DialogContent>
        <DialogActions>
          <TextButton
            onClick={handleClose}
            translationKey="common.buttons.close"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FixedCameraPlaybackDashboard;
