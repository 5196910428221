import { makeStyles } from '@mui/styles';
import { RegisterOptions, useForm } from 'react-hook-form';
import { Grid, LoadingButton, Paper, Typography } from 'base-components';
import { UnitOfMeasure } from 'domain/services/UnitOfMeasureService';

import { CancelButton } from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormCheckbox from 'library/CompositeComponents/form/FormCheckbox';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

const useStyles = makeStyles(() => ({
  style: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '-8px',
  },
}));

type SensorTypeFormFields = {
  sensorTypeName: string;
  description: string;
  sensorTypeCode: string;
  isScalar: boolean;
  isAverageable: boolean;
  unitOfMeasure: any;
};

type FormRules<T> = {
  [P in keyof T]?: RegisterOptions;
};

const rules: FormRules<SensorTypeFormFields> = {
  sensorTypeName: {
    required: 'Sensor Type Name Required',
  },
  sensorTypeCode: {
    required: 'Sensor Type Code Required',
  },
  unitOfMeasure: {
    required: 'Unit of Measure Required',
  },
};

type SensorTypeProps = {
  unitOfMeasureList: UnitOfMeasure[];
  sensorTypeName: string;
  sensorTypeCode: string;
  description: string;
  isScalar: boolean;
  isAverageable: boolean;
  unitOfMeasure: { unitOfMeasureId: number; unitOfMeasureName: string };
  onClose: () => void;
  onSubmit: ({
    sensorTypeName,
    description,
    sensorTypeCode,
    isScalar,
    isAverageable,
    unitOfMeasure,
  }: {
    sensorTypeName: string;
    description: string;
    sensorTypeCode: string;
    isScalar: boolean;
    isAverageable: boolean;
    unitOfMeasure: { unitOfMeasureId: number; unitOfMeasureName: string };
  }) => void;
  isCreate: boolean;
};

const FormStoryWrapper: React.FC<SensorTypeProps> = ({
  unitOfMeasureList,
  sensorTypeName,
  sensorTypeCode,
  description,
  isScalar,
  isAverageable,
  unitOfMeasure,
  onClose,
  onSubmit,
  isCreate,
}: SensorTypeProps) => {
  const classes = useStyles();
  const formMethods = useForm<SensorTypeFormFields>({
    values: {
      sensorTypeName,
      sensorTypeCode,
      description,
      isScalar,
      isAverageable,
      unitOfMeasure,
    },
  });
  const {
    formState: { isSubmitting },
  } = formMethods;
  return (
    <Paper
      style={{
        paddingLeft: '16px',
        paddingRight: '16px',
        maxWidth: '600px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Typography variant="h6" gutterBottom>
        {isCreate ? 'Create Sensor Type' : 'Edit Sensor Type'}
      </Typography>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Grid container rowSpacing={0} columnSpacing={1}>
          <Grid item xs={6}>
            <FormTextField
              name="sensorTypeName"
              translationKey="device.sensorTypeName"
              fullWidth
              rules={rules.sensorTypeName}
            />
            <FormTextField
              name="description"
              translationKey="common.textfields.description"
              fullWidth
            />
            <FormTextField
              name="sensorTypeCode"
              translationKey="device.sensorTypeCode"
              fullWidth
              rules={rules.sensorTypeCode}
            />
            <Grid item xs={12}>
              <FormCheckbox name="isScalar" label="Is Scalar" />
              <FormCheckbox name="isAverageable" label="Is Averageable" />
            </Grid>
            <FormAutocomplete
              name="unitOfMeasure"
              translationKey="device.unitOfMeasure"
              fullWidth
              options={unitOfMeasureList}
              getOptionLabel={(option) =>
                option.unitOfMeasureId === undefined
                  ? ''
                  : `${option.unitOfMeasureName} (${option.unitOfMeasureId})`
              }
              rules={rules.unitOfMeasure}
            />
          </Grid>
        </Grid>
        <div className={classes.style}>
          <CancelButton onClick={onClose} />
          <LoadingButton
            variant="contained"
            translationKey="common.buttons.save"
            type="submit"
            loading={isSubmitting}
          />
        </div>
      </Form>
    </Paper>
  );
};

export default FormStoryWrapper;
