import { AnchoredHiddenMenu, MenuItem } from 'base-components';
import type {
  DashboardLayout,
  ShowDialogIndicator,
  UserDetailsDashboardResponse,
} from './DashboardTableTypes';

type DashboardActionsProps = {
  layout: DashboardLayout;
  handleToggle: () => void;
  showDialog: (dialogIndicator: ShowDialogIndicator) => void;
  user?: UserDetailsDashboardResponse;
};

const DashboardActions: React.FC<DashboardActionsProps> = ({
  layout,
  handleToggle,
  showDialog,
  user = null,
}: DashboardActionsProps) => {
  if (
    layout.permission !== 'RW' ||
    user?.DMAS_USER_PRIVILEGE !== 'RW' ||
    user?.DMAS_USER_ID !== layout.ownerId
  ) {
    return <></>;
  }
  return (
    <AnchoredHiddenMenu>
      <MenuItem key="1" onClick={() => handleToggle()}>
        Make {layout.isPublic ? 'Private' : 'Public'}
      </MenuItem>
      <MenuItem
        key="2"
        onClick={() =>
          showDialog({
            dialog: 'DELETE',
            layout,
          })
        }
      >
        Delete
      </MenuItem>
      <MenuItem
        key="3"
        onClick={() =>
          showDialog({
            dialog: 'SHARE',
            layout,
          })
        }
      >
        Share
      </MenuItem>
      <MenuItem
        key="4"
        onClick={() =>
          showDialog({
            dialog: 'CLONE',
            layout,
          })
        }
      >
        Clone
      </MenuItem>
    </AnchoredHiddenMenu>
  );
};

export default DashboardActions;
