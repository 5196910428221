import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';

const RequestTypeDropdown = (props) => {
  const { requestTypes, requestType, onChange } = props;

  const options = requestTypes.map((type) => ({
    key: String(type.supportRequestTypeId),
    value: String(type.supportRequestTypeId),
    label: type.name,
  }));

  const handleChange = (event) => onChange(event.target.value);

  return (
    <Dropdown
      options={options}
      label="Type of request"
      id="request-type-dropdown"
      value={requestType}
      disabled={options.length === 0}
      required
      onChange={handleChange}
      fullWidth
    />
  );
};

RequestTypeDropdown.propTypes = {
  requestTypes: PropTypes.arrayOf(
    PropTypes.shape({
      supportRequestTypeId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  requestType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RequestTypeDropdown;
