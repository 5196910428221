import { get } from 'util/WebRequest';

const params = {
  operation: 1010,
  configKeys: 'systemStatusMessage',
};

class NetworkConsoleService {
  static getSystemStatusMessage = () =>
    get('NetworkConsoleService', params).then((response) => {
      const { data } = response;
      const { statusCode } = data;
      if (statusCode === 0 && data.payload) {
        return data.payload.systemStatusMessage;
      }
      return '';
    });
}
export default NetworkConsoleService;
