import * as React from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import SizeableVirtualList from './SizeableVirtualList';

interface ListboxProps extends React.HTMLAttributes<HTMLElement> {}

const Listbox = React.forwardRef<HTMLDivElement, ListboxProps>(
  (props: ListboxProps, ref) => {
    const { children } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const cache = new CellMeasurerCache({});

    const renderRow = (item: any) => {
      const { index, key, parent, style } = item;
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          {React.cloneElement(children[index] as React.ReactElement, {
            style,
          })}
        </CellMeasurer>
      );
    };

    return (
      <div ref={ref} style={{ height: '300px' }} {...props}>
        <AutoSizer>
          {({ height, width }) => (
            <SizeableVirtualList
              height={height}
              width={width}
              rowHeight={cache.rowHeight}
              rowCount={itemCount}
              rowRenderer={renderRow}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
);

export default Listbox;
