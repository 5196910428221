import { parseDmasAPIResponse, get } from 'util/WebRequest';

class EarthquakeDetailService {
  static getEarthquakeDetails = async (earthquakeId) =>
    get('EarthquakeDetailService', {
      operation: 1,
      earthquakeId,
    }).then((response) => parseDmasAPIResponse(response));
}

export default EarthquakeDetailService;
