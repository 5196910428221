import { Typography } from 'base-components';
import withDataFiles from 'domain/AppComponents/video/withDataFiles';
import withDiveId from 'domain/AppComponents/video/withDiveId';
import withSearchTreeNodeId from 'domain/AppComponents/video/withSearchTreeNodeId';
import withSeatubePlaylist from 'domain/AppComponents/video/withSeatubePlaylist';
import DiveVideoTemplate from 'library/CompositeComponents/video/templates/DiveVideoTemplate';
import LiveVideoTemplate from 'library/CompositeComponents/video/templates/LiveVideoTemplate';
import SeaTubePlaylistTemplate from 'library/CompositeComponents/video/templates/SeaTubePlaylistTemplate';
import Video from 'library/CompositeComponents/video/Video';
import withArchiveFiles from './withArchiveFiles';
import withArchiveFilesNotContinuous from './withArchiveFilesNotContinuous';
import withLiveStream from './withLiveStream';
import withPlaylistLineId from './withPlaylistLineId';
import withProgramDateTime from './withProgramDateTime';

class VideoGenerator {
  static build(props) {
    let VideoComponent;

    if (props.diveId && !props.isLive) {
      // Dive
      VideoComponent = withDiveId(props.diveId)(
        withDataFiles(DiveVideoTemplate)
      );
    } else if (props.isLive) {
      if (!props.liveStreamUrl) {
        return (
          <Typography id="live-video-error" variant="body1">
            Live video is not available at this time
          </Typography>
        );
      }
      // Live Stream
      VideoComponent = withLiveStream(props.liveStreamUrl)(
        withProgramDateTime(LiveVideoTemplate)
      );
    } else if (props.playlistHdrId) {
      // Playlist
      VideoComponent = withSeatubePlaylist(props.playlistHdrId)(
        withDataFiles(SeaTubePlaylistTemplate)
      );
    } else if (props.playlistLineId && props.files) {
      // Playlist With Files and PlaylistLineId provided
      VideoComponent = withPlaylistLineId()(
        withDataFiles(SeaTubePlaylistTemplate)
      );
    } else if (props.searchTreeNodeId && !props.isLive) {
      // Search Tree Node
      VideoComponent = withSearchTreeNodeId(props.searchTreeNodeId)(
        withDataFiles(DiveVideoTemplate)
      );
    } else if (props.archiveFiles) {
      // Station Code + Device Category Code
      if (props.useContinuous) {
        VideoComponent = withArchiveFiles(props.archiveFiles)(
          withDataFiles(DiveVideoTemplate)
        );
      } else {
        VideoComponent = withArchiveFilesNotContinuous(
          props.archiveFiles,
          props.title,
          props.description,
          props.loopPlayback
        )(Video);
      }
    } else {
      VideoComponent = Video;
    }

    return <VideoComponent {...props} />;
  }
}

export default VideoGenerator;
