import { createStyles, makeStyles } from '@mui/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { DialogContent, TextEditor } from 'base-components';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';

const useStyles = makeStyles(() =>
  createStyles({
    toolbarClassName: {
      background: 'rgba(232,232,232,1)',
    },
    textEditorDiv: {
      minHeight: 250,
      border: 'solid',
      borderWidth: 1,
      borderColor: 'rgba(0,0,0,0.09)',
      borderBlockEndColor: 'rgba(118,118,118,1)',
    },
    textEditorClassName: {
      maxHeight: 460,
      minHeight: 250,
      overflow: 'auto',
    },
  })
);

export type TextWidgetFormType = {
  showTitle: boolean;
  htmlValue?: string;
  title?: string;
};

const TextWidgetFormContents = () => {
  const classes = useStyles();
  const formMethods = useFormContext<TextWidgetFormType>();

  const { control } = formMethods;

  return (
    <DialogContent>
      <FormTextField name="title" translationKey="common.textfields.title" />
      <FormToggle name="showTitle" label="Show Title" />
      <div className={classes.textEditorDiv}>
        <Controller
          control={control}
          name="htmlValue"
          render={({ field }) => (
            <TextEditor
              title="htmlValue"
              name="htmlValue"
              htmlValue={field.value}
              setFieldValue={(_, newValue) => field.onChange(newValue)}
              toolbarClassName={classes.toolbarClassName}
              textEditorClassName={classes.textEditorClassName}
            />
          )}
        />
      </div>
    </DialogContent>
  );
};

export default TextWidgetFormContents;
