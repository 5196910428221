import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const IMAGE_WIDTH = 1200;
const IMAGE_HEIGHT = 900;

const SPECTROGRAM_WIDTH = 70;
const SPECTROGRAM_HEIGHT = 748;

const X_OFFSET = 35;
const Y_OFFSET = -71;

const HI_RES_SPECTROGRAM_WIDTH = 60;
const HI_RES_SPECTROGRAM_HEIGHT = 755;

const HI_RES_X_OFFSET = 45;
const HI_RES_Y_OFFSET = -75;

const HI_RES_IMAGE_WIDTH = 3200;
const HI_RES_IMAGE_HEIGHT = 2375;

const ADCP_PLOT_WIDTH = 165;
const ADCP_PLOT_HEIGHT = 2035;

const ADCP_X_OFFSET = 60;
const ADCP_Y_OFFSET = -150;

const styles = (theme) => ({
  root: {
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
    paddingLeft: theme.spacing(),
    display: 'flex',
  },
  image: {
    position: 'relative',
  },
  hydrophoneImageForDashboard: {
    position: 'absolute',
    clipPath: 'inset(0% 91.35% 9% 0%)',
    zIndex: 0,
    transform: 'translate(-4.5%, 0%)',
    height: '100%',
    width: 'auto',
  },
  adcpImageForDashboard: {
    position: 'absolute',
    clipPath: 'inset(0% 93.1% 9% 0%)',
    zIndex: 0,
    transform: 'translate(-2.8%, 0%)',
    height: '100%',
    width: 'auto',
  },
  imageContainer: {
    overflow: 'hidden',
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
    display: 'inline-block',
  },
  imageContainerForDashboard: {
    position: 'relative',
    textAlign: 'center',
    overflow: 'hidden',
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
    display: 'inline-block',
    height: `90%`,
  },
});

const SpectraAxis = ({
  src,
  deviceCategoryCode,
  timeRange = '5',
  height = SPECTROGRAM_HEIGHT,
  width = SPECTROGRAM_WIDTH,
  alt = 'Missing',
  isDashboard = false,
  classes = undefined,
}) => {
  SpectraAxis.propTypes = {
    src: PropTypes.string.isRequired,
    deviceCategoryCode: PropTypes.string.isRequired,
    timeRange: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    alt: PropTypes.string,
    isDashboard: PropTypes.bool,
    classes: PropTypes.shape({
      root: PropTypes.string,
      image: PropTypes.string,
      imageContainer: PropTypes.string,
    }),
  };

  const isHydrophone = deviceCategoryCode === 'HYDROPHONE';

  const calculateXOffset = () => {
    let imageWidth;
    let offset;
    if (isHydrophone) {
      if (timeRange === '5') {
        imageWidth = SPECTROGRAM_WIDTH;
        offset = X_OFFSET;
      } else {
        imageWidth = HI_RES_SPECTROGRAM_WIDTH;
        offset = HI_RES_X_OFFSET;
      }
    } else {
      imageWidth = ADCP_PLOT_WIDTH;
      offset = ADCP_X_OFFSET;
    }
    return Math.floor((width / imageWidth) * offset);
  };

  const calculateYOffset = () => {
    let imageHeight;
    let offset;
    if (isHydrophone) {
      if (timeRange === '5') {
        imageHeight = SPECTROGRAM_HEIGHT;
        offset = Y_OFFSET;
      } else {
        imageHeight = HI_RES_SPECTROGRAM_HEIGHT;
        offset = HI_RES_Y_OFFSET;
      }
    } else {
      imageHeight = ADCP_PLOT_HEIGHT;
      offset = ADCP_Y_OFFSET;
    }
    return Math.floor((height / imageHeight) * offset);
  };

  const calculateImageWidth = () => {
    let imageWidth;
    let plotWidth;
    if (isHydrophone) {
      if (timeRange === '5') {
        imageWidth = IMAGE_WIDTH;
        plotWidth = SPECTROGRAM_WIDTH;
      } else {
        imageWidth = IMAGE_WIDTH;
        plotWidth = SPECTROGRAM_WIDTH;
      }
    } else {
      imageWidth = HI_RES_IMAGE_WIDTH;
      plotWidth = ADCP_PLOT_WIDTH;
    }
    return Math.floor((width / plotWidth) * imageWidth);
  };

  const calculateImageHeight = () => {
    let imageHeight;
    let plotHeight;
    if (isHydrophone) {
      if (timeRange === '5') {
        imageHeight = IMAGE_HEIGHT;
        plotHeight = SPECTROGRAM_HEIGHT;
      } else {
        imageHeight = IMAGE_HEIGHT;
        plotHeight = SPECTROGRAM_HEIGHT;
      }
    } else {
      imageHeight = HI_RES_IMAGE_HEIGHT;
      plotHeight = ADCP_PLOT_HEIGHT;
    }
    return Math.floor((height / plotHeight) * imageHeight);
  };
  if (isDashboard === true) {
    return (
      <div
        className={classes.root}
        style={{
          paddingLeft: `0px`,
        }}
      >
        <div
          className={classes.imageContainerForDashboard}
          style={{
            width: `${width}px`,
          }}
          id="leftAxis"
        >
          <img
            src={src}
            alt={alt}
            className={
              isHydrophone
                ? classes.hydrophoneImageForDashboard
                : classes.adcpImageForDashboard
            }
            draggable={false}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div
        className={classes.imageContainer}
        style={{ height: `${height}px`, width: `${width}px` }}
        id="leftAxis"
      >
        <img
          src={src}
          alt={alt}
          className={classes.image}
          style={{
            right: `${calculateXOffset()}px`,
            top: `${calculateYOffset()}px`,
          }}
          height={calculateImageHeight()}
          width={calculateImageWidth()}
          draggable={false}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(SpectraAxis);
