import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import QaqcAutotestsService from 'domain/services/QaqcAutotestsService';

class QaqcFinderTestGroupDropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    testGroupTypeId: PropTypes.number.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    testGroups: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getTestGroups();
  }

  getTestGroups = () => {
    const { testGroupTypeId } = this.props;
    QaqcAutotestsService.getQAQCTestGroups()
      .then((results) => {
        const testGroups = results
          .filter(
            (testGroup) => testGroup.qaqcTestGroupTypeId === testGroupTypeId
          )
          .map((testGroup) => ({
            label: testGroup.name,
            value: testGroup.qaqcTestGroupId,
          }));
        this.setState({
          testGroups: [{ label: 'Find All', value: 0 }, ...testGroups],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const { disabled, initialValue, value, onChange, ...rest } = this.props;
    const { testGroups } = this.state;
    return (
      <Dropdown
        disabled={disabled}
        initialValue={initialValue}
        label="Qaqc Test Group"
        options={testGroups}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }
}
export default QaqcFinderTestGroupDropdown;
