import { post, get, patch, deleteImpl } from 'util/WebRequest';

class TaxonomyAttributeGroupService {
  static async getAll() {
    const response = await get('internal/taxonomies/attributes/groups');
    return response.data;
  }

  static async create(params) {
    const response = await post(
      'internal/taxonomies/attributes/groups',
      params,
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async update(groupId, params) {
    const response = await patch(
      `internal/taxonomies/attributes/groups/${groupId}`,
      {
        ...params,
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async delete(groupId) {
    const response = await deleteImpl(
      `internal/taxonomies/attributes/groups/${groupId}`
    );
    return response.data;
  }
}

export default TaxonomyAttributeGroupService;
