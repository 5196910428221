/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import * as React from 'react';
import { Grid } from 'base-components';
import Filter, { FilterEvent } from 'library/CompositeComponents/filter/Filter';
import NumberRangeField from './NumberRangeField';

type Props = {
  value: any;
  name: string;
  label: string;
  uom?: string;
  onChange: (event: FilterEvent) => void;
};

const NumberRangeFilter: React.VFC<Props> = ({
  onChange,
  name,
  value,
  label,
  uom = '',
}: Props): React.ReactElement => {
  const [greaterThan, setGreaterThan] = useState(
    value ? value.greaterThan : undefined
  );
  const [lessThan, setLessThan] = useState(value ? value.lessThan : undefined);

  // Call onChange any time we update the values
  useEffect(() => {
    onChange({
      target: {
        value: { greaterThan, lessThan },
        name,
      },
    });
  }, [greaterThan, lessThan]);

  const getSummaryValues = () => {
    if (value) {
      const hasGreaterThan = greaterThan !== '' && !isNaN(Number(greaterThan));
      const hasLessThan = lessThan !== '' && !isNaN(Number(lessThan));

      if (hasGreaterThan && hasLessThan) {
        if (lessThan > greaterThan) {
          return [
            { label: `Between ${greaterThan}${uom} and ${lessThan}${uom}` },
          ];
        }
        return [
          {
            label: `Greater than ${greaterThan}${uom} or less than ${lessThan}${uom}`,
          },
        ];
      }
      if (hasGreaterThan) {
        return [{ label: `Greater than or equal to ${greaterThan}${uom}` }];
      }
      if (hasLessThan) {
        return [{ label: `Less than or equal to ${lessThan}${uom}` }];
      }
    }
    return [];
  };

  return (
    <Filter
      title={label}
      onChange={onChange}
      name={name}
      value={value}
      summaryValues={getSummaryValues()}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <NumberRangeField
            value={value}
            setGreaterThan={setGreaterThan}
            setLessThan={setLessThan}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default NumberRangeFilter;
