import { useState } from 'react';
import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { Loading } from '@onc/composite-components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from 'base-components';
import SiteDeviceSubsetDOIDatasetLinks from 'domain/AppComponents/datasets/SiteDeviceSubsetDOIDatasetLinks';
import { LabeledCheckBoxWithModifyByModifyDate } from 'domain/AppComponents/Form/Fields/Checkboxes';
import { RePostprocessRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import {
  ModifyBy,
  ModifyDate,
  ReadOnlyField,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import SiteDeviceSubsetHistoryTable from 'domain/AppComponents/site-device-maintenance/SiteDeviceSubsetHistoryTable';
import Form from 'library/CompositeComponents/form/Form';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import Panel from 'library/CompositeComponents/panel/Panel';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { useSnackbars } from 'util/hooks/useSnackbars';
import ObjectUtils from 'util/ObjectUtils';
import GeospatialTextAreaButtonSet from './GeospatialTextAreaButtonSet';
import SiteDeviceSubsetGeospatialArea from './SiteDeviceSubsetGeospatialArea';
import SiteDeviceSubsetMaintenanceFormSubmission from './SiteDeviceSubsetMaintenanceFormSubmission';

const classes = {
  entryFormFields: {
    marginBottom: 1,
  },
  gridPadding: {
    paddingLeft: 3,
  },
};

// service returns all site device subsets given a site device
type SiteDevicePayload = {
  records: [
    {
      castReviewed: boolean;
      comment: string;
      deviceId: number;
      endDate: string;
      generationType: number;
      generationTypeId: number;
      modifyBy: string;
      modifyDate: string;
      referenceDepth: number;
      referenceLat: number;
      referenceLon: number;
      siteDeviceId: number;
      siteDeviceSubsetId: number;
      siteDeviceSubsetName: string;
      siteDeviceSubsetType: number;
      siteDeviceSubsetTypeId: number;
      startDate: string;
      wasRefLatLonModified: boolean;
    },
  ];
};

type GeospatialAreaPayload = {
  boundingArea: string;
  geospatialAreaId: number;
  modifyBy: number;
  modifyDate: string;
};

type CastReviewPayload = {
  castDefinition: string;
  castReviewed: boolean;
  modifyBy: string;
  modifyDate: string;
  siteDeviceSubsetCastDefinitionId: number;
  siteDeviceSubsetId: number;
};

export type SiteDeviceSubsetMaintenanceForm = {
  deviceId: string;
  siteDeviceId: string;
  siteDeviceSubsetName: string;
  siteDeviceSubsetType: number;
  generationType: number;
  referenceLat: string;
  referenceLon: string;
  referenceDepth: string;
  startDate: Moment;
  endDate: Moment;
  comment: string;
};

type SiteDeviceSubsetMaintenanceProps = {
  siteDeviceSubsetId?: number;
  siteDeviceId?: number;
};

const SiteDeviceSubsetMaintenance = ({
  siteDeviceSubsetId = undefined,
  siteDeviceId = undefined,
}: SiteDeviceSubsetMaintenanceProps) => {
  // variable initialization
  const { onError } = useSnackbars();

  // used to reset formMethods' default value after the get reqs have finished
  const [renderedOnce, setRenderedOnce] = useState<boolean>(false);

  const [isCast, setIsCast] = useState<boolean>(false);
  const [geoOpen, setGeoOpen] = useState<boolean>(false);
  const [geospatialAreaWKT, setGeospatialAreaWKT] = useState<string>('');
  const [rePostProcessOption, setRePostProcessOption] = useState<string>('');
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);

  const siteDeviceIdDefined = !isNaN(siteDeviceId) && siteDeviceId !== null;

  const [isExistingSubsetBeingEdited, setIsExistingSubsetBeingEdited] =
    useState<boolean>(
      !isNaN(siteDeviceSubsetId) && siteDeviceSubsetId !== null
    );

  const gettingSiteDeviceSubsets =
    siteDeviceIdDefined &&
    isExistingSubsetBeingEdited &&
    siteDeviceSubsetId > 0;

  // GET requests

  const siteDeviceData = useGet<SiteDevicePayload, { siteDeviceId: number }>(
    'SiteDeviceSubsetService',
    {
      operation: 7,
      options: {
        enabled: gettingSiteDeviceSubsets,
      },
    },
    {
      siteDeviceId,
    }
  );

  const geosAreaData = useGet<
    GeospatialAreaPayload,
    { siteDeviceSubsetId: number }
  >(
    'SiteDeviceSubsetService',
    {
      operation: 8,
      options: {
        enabled: gettingSiteDeviceSubsets,
      },
    },
    {
      siteDeviceSubsetId,
    }
  );

  // Site Device Subset Type dropdown options
  const siteDeviceSubsetTypes = useGet<object>('SiteDeviceSubsetService', 9);

  // Generation Type dropdown options
  const generationTypes = useGet<object>('SiteDeviceSubsetService', 10);

  const castSiteDeviceData = useGet<Array<number>>(
    'SiteDeviceSubsetService',
    12
  );

  const castReviewData = useGet<
    CastReviewPayload,
    { siteDeviceSubsetId: number }
  >(
    'SiteDeviceSubsetService',
    {
      operation: 13,
      options: {
        enabled: gettingSiteDeviceSubsets,
      },
    },
    {
      siteDeviceSubsetId,
    }
  );

  // initialize empty form
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      deviceId: '',
      siteDeviceId: siteDeviceIdDefined ? siteDeviceId.toString() : '',
      siteDeviceSubsetName: '',
      siteDeviceSubsetType: 2,
      generationType: 1000,
      referenceLat: '',
      referenceLon: '',
      referenceDepth: '',
      startDate: moment.utc(),
      endDate: moment.utc(),
      comment: '',
    },
  });
  // the following four fields affect some dynamic rendering in the form
  let startDate = formMethods.watch('startDate');
  let endDate = formMethods.watch('endDate');
  let referenceLat = formMethods.watch('referenceLat');
  let referenceLon = formMethods.watch('referenceLon');
  const formSiteDeviceId = formMethods.watch('siteDeviceId');
  formMethods.watch('comment');

  // ensure GET requests are finished before continuing
  if (
    (gettingSiteDeviceSubsets && siteDeviceData.isLoading) ||
    (gettingSiteDeviceSubsets && geosAreaData.isLoading) ||
    (gettingSiteDeviceSubsets && castReviewData.isLoading) ||
    castSiteDeviceData.isLoading ||
    siteDeviceSubsetTypes.isLoading
  ) {
    return <Loading />;
  }

  // misc helpers

  const isCastBeingCreated = () => !isExistingSubsetBeingEdited && isCast;

  const compareStringsForEqualityUndefinedEqualsEmpty = (
    firstString: string,
    secondString: string
  ) => {
    // convert empty string to undefined then compare
    const firstStringConv = firstString === '' ? undefined : firstString;
    const secondStringConv = secondString === '' ? undefined : secondString;
    return firstStringConv === secondStringConv;
  };

  // Geospatial area selection helpers

  const handleGeoTextChange = (e) => {
    setGeospatialAreaWKT(e.target.value);
  };

  const handleGeoOpen = () => {
    setGeoOpen(true);
  };

  const handleGeoClose = () => {
    setGeoOpen(false);
  };

  const handleGeoSave = (geospatialArea) => {
    setGeospatialAreaWKT(geospatialArea);
    setGeoOpen(false);
  };

  // Helpers pertaining to whether values have been changed

  // grab correct site device subset from list of all site device subsets
  // for given site device
  const siteDeviceSubset = siteDeviceData.data?.records?.filter(
    (subset) => subset.siteDeviceSubsetId === siteDeviceSubsetId
  )?.[0];

  // if site device subset does not exist, we are now creating a new site device subset
  if (
    (!siteDeviceSubset || siteDeviceSubset?.siteDeviceId !== siteDeviceId) &&
    isExistingSubsetBeingEdited
  ) {
    setIsExistingSubsetBeingEdited(false);
    onError('Could not find site device subset from ID.');
  }

  // initial values to compare against for dynamic rendering
  const initialEndDate = isExistingSubsetBeingEdited
    ? moment.utc(siteDeviceSubset?.endDate)
    : moment.utc();
  const initialStartDate = isExistingSubsetBeingEdited
    ? moment.utc(siteDeviceSubset?.startDate)
    : moment.utc();
  const initialRefLat = isExistingSubsetBeingEdited
    ? siteDeviceSubset?.referenceLat?.toString() || ''
    : '';
  const initialRefLon = isExistingSubsetBeingEdited
    ? siteDeviceSubset?.referenceLon?.toString() || ''
    : '';

  const hasStartDateChanged = () =>
    !initialStartDate?.isSame(startDate, 'milliseconds');

  const hasEndDateChanged = () =>
    !initialEndDate?.isSame(endDate, 'milliseconds');

  const hasRefLatChanged = () =>
    !compareStringsForEqualityUndefinedEqualsEmpty(initialRefLat, referenceLat);

  const hasRefLonChanged = () =>
    !compareStringsForEqualityUndefinedEqualsEmpty(initialRefLon, referenceLon);

  const dateAndRefLatLongNotChanged = () =>
    !(hasStartDateChanged() || hasEndDateChanged()) &&
    !(hasRefLatChanged() || hasRefLonChanged());

  const hasOnlyCastReviewedStatusChanged = () => {
    // checkbox state has changed from initial value, no ref values have changed,
    // and no re-post-process radio buttons have been clicked
    const castReviewedStatusChanged =
      checkboxChecked !== castReviewData.data?.castReviewed;
    return (
      castReviewedStatusChanged &&
      dateAndRefLatLongNotChanged() &&
      rePostProcessOption.length <= 0
    );
  };

  // Conditionally rendered component declaration

  // site device
  const renderSiteDeviceField = () => {
    // existing cast site device subsets cannot have their IDs edited
    if (siteDeviceIdDefined) {
      return (
        <ReadOnlyField
          valueText={`${siteDeviceId}`}
          title="siteDeviceId"
          labelText="Site Device ID"
        />
      );
    }
    return (
      <FormTextField
        name="siteDeviceId"
        fullWidth
        translationKey="device.siteDeviceId"
        type="number"
        rules={{ required: 'A site device ID is required!' }}
      />
    );
  };

  // site device subset name
  const renderSiteDeviceSubsetNameTextField = () => {
    // existing cast site device subsets cannot have their names edited
    if (isCast && isExistingSubsetBeingEdited) {
      if (siteDeviceSubset?.siteDeviceSubsetName) {
        return (
          <ReadOnlyField
            valueText={`${siteDeviceSubset?.siteDeviceSubsetName}`}
            title="siteDeviceSubsetName"
            labelText="Site Device Subset Name"
          />
        );
      }
      return <></>;
    }
    if (!isCastBeingCreated()) {
      return (
        <FormTextField
          name="siteDeviceSubsetName"
          translationKey="device.siteDeviceSubsetName"
          fullWidth
        />
      );
    }
    return <></>;
  };

  // site device subset type (determines if subset is cast)
  const renderSiteDeviceSubsetTypeSelect = () => {
    // existing cast site device subsets cannot have their site device types edited
    if (isCast && isExistingSubsetBeingEdited) {
      if (siteDeviceSubset?.siteDeviceSubsetType) {
        return (
          <ReadOnlyField
            valueText={`${siteDeviceSubsetTypes.data?.[siteDeviceSubset?.siteDeviceSubsetType]}`}
            title="siteDeviceSubsetType"
            labelText="Site Device Subset Type"
          />
        );
      }
      return <></>;
    }
    return (
      <FormDropdown
        name="siteDeviceSubsetType"
        label="Site Device Subset Type"
        options={ObjectUtils.unpackToLabelValueKey(
          siteDeviceSubsetTypes.data
        ).map((option) => ({
          label: option.label,
          value: Number(option.value),
          key: option.key,
        }))}
        fullWidth
        rules={{
          validate: {
            // this basically functions as an onChange because react-hook-form fields
            // don't have those by default
            updateType: (siteDeviceSubsetTypeVal) => {
              setIsCast(
                castSiteDeviceData.data?.includes(siteDeviceSubsetTypeVal)
              );
              return true;
            },
          },
        }}
      />
    );
  };

  // generation type
  const renderGenerationTypeSelect = () => {
    // existing cast site device subsets cannot have their generation types edited
    if (isCast && isExistingSubsetBeingEdited) {
      if (siteDeviceSubset?.generationType) {
        return (
          <ReadOnlyField
            valueText={`${generationTypes.data?.[siteDeviceSubset?.generationType]}`}
            title="generationType"
            labelText="Generation Type"
          />
        );
      }
      return <></>;
    }
    if (!isCastBeingCreated()) {
      return (
        <FormDropdown
          name="generationType"
          label="Generation Type"
          options={ObjectUtils.unpackToLabelValueKey(generationTypes.data).map(
            (option) => ({
              label: option.label,
              value: Number(option.value),
              key: option.key,
            })
          )}
          fullWidth
        />
      );
    }
    return <></>;
  };

  // reference latitude
  const renderReferenceLatTextField = () => {
    // if start or end date have been edited, reference lat cannot be changed
    if (
      (hasStartDateChanged() || hasEndDateChanged()) &&
      siteDeviceSubset?.referenceLat
    ) {
      return (
        <ReadOnlyField
          valueText={`${initialRefLat}`}
          title="referenceLat"
          labelText={`Reference Latitude (Degrees)${siteDeviceSubset?.wasRefLatLonModified ? ' Modified' : ''}`}
        />
      );
    }
    if (!isCastBeingCreated()) {
      return (
        <FormTextField
          type="number"
          name="referenceLat"
          translationKey="device.referenceLatitude"
          translationOptions={{
            modified: siteDeviceSubset?.wasRefLatLonModified ? ' Modified' : '',
          }}
          fullWidth
          rules={{
            validate: {
              // this basically functions as an onChange because react-hook-form fields
              // don't have those by default
              updateRefLat: (val) => {
                referenceLat = val;
                return true;
              },
            },
          }}
        />
      );
    }
    return <></>;
  };

  // reference longitude
  const renderReferenceLonTextField = () => {
    // if start or end date have been edited, reference lon cannot be changed
    if (
      (hasStartDateChanged() || hasEndDateChanged()) &&
      siteDeviceSubset?.referenceLon
    ) {
      return (
        <ReadOnlyField
          valueText={`${initialRefLon}`}
          title="referenceLon"
          labelText={`Reference Longitude (Degrees)${siteDeviceSubset?.wasRefLatLonModified ? ' Modified' : ''}`}
        />
      );
    }
    if (!isCastBeingCreated()) {
      return (
        <FormTextField
          type="number"
          name="referenceLon"
          translationKey="device.referenceLongitude"
          translationOptions={{
            modified: siteDeviceSubset?.wasRefLatLonModified ? ' Modified' : '',
          }}
          fullWidth
          rules={{
            validate: {
              // this basically functions as an onChange because react-hook-form fields
              // don't have those by default
              updateRefLon: (val) => {
                referenceLon = val;
                return true;
              },
            },
          }}
        />
      );
    }
    return <></>;
  };

  // reference depth
  const renderReferenceDepthTextField = () => {
    // existing cast site device subsets cannot have their reference depths edited
    if (isCast && isExistingSubsetBeingEdited) {
      if (siteDeviceSubset?.referenceDepth) {
        return (
          <ReadOnlyField
            valueText={`${siteDeviceSubset?.referenceDepth?.toString()}`}
            title="referenceDepth"
            labelText="Reference Depth (Meters)"
          />
        );
      }
      return <></>;
    }
    if (!isCastBeingCreated()) {
      return (
        <FormTextField
          type="number"
          name="referenceDepth"
          translationKey="device.referenceDepth"
          fullWidth
        />
      );
    }
    return <></>;
  };

  // radio group
  const shouldRenderRadioGroup = () =>
    isExistingSubsetBeingEdited && isCast && dateAndRefLatLongNotChanged();

  const renderRadioGroup = () => (
    <>
      <Typography>
        Re-Post-Process Data for Unaltered Cast Using Scalar Latitude and
        Longitude
      </Typography>
      <RePostprocessRadioGroup
        value={rePostProcessOption}
        onChange={(e) => setRePostProcessOption(e.target.value)}
      />
    </>
  );

  // comment text area
  const isCommentRequired = () =>
    isExistingSubsetBeingEdited &&
    isCast &&
    dateAndRefLatLongNotChanged() &&
    !hasOnlyCastReviewedStatusChanged();

  const renderCommentTextArea = () => (
    <FormTextField
      name="comment"
      translationKey="common.textfields.comment"
      helperText="Comment is required if not modifying values"
      rules={{
        validate: {
          validateComment: (comment) =>
            (isCommentRequired() && comment?.length > 0) ||
            !isCommentRequired() ||
            'Comment is required if not modifying values',
        },
      }}
      fullWidth
      multiline
      rows={9}
    />
  );

  // checkbox
  const shouldDisableCheckbox = () =>
    !dateAndRefLatLongNotChanged() || rePostProcessOption.length > 0;

  const renderCastReviewedCheckbox = () => {
    if (isCast) {
      const disableCheckBox = shouldDisableCheckbox();
      // uncheck if it is disabled, otherwise use checkboxChecked
      const checkboxStatus = disableCheckBox ? false : checkboxChecked;
      // only show metadata if cast was reviewed when page loaded and checkbox is checked
      const showMetaData = checkboxStatus
        ? castReviewData.data?.castReviewed
        : false;
      return (
        <LabeledCheckBoxWithModifyByModifyDate
          label="Cast Reviewed Status"
          modifyBy={castReviewData.data?.modifyBy}
          modifyDate={castReviewData.data?.modifyDate}
          checked={checkboxStatus}
          showMetaData={showMetaData}
          disabled={disableCheckBox}
          onChange={(e) => setCheckboxChecked(e.target.checked)}
        />
      );
    }
    return <> </>;
  };

  // populate form with GET request data if we haven't already
  if (!renderedOnce) {
    if (siteDeviceSubset) {
      setIsCast(
        castSiteDeviceData.data?.includes(
          siteDeviceSubset?.siteDeviceSubsetType
        )
      );

      setCheckboxChecked(siteDeviceSubset?.castReviewed);

      if (geosAreaData.data?.boundingArea) {
        setGeospatialAreaWKT(geosAreaData.data?.boundingArea);
      }

      formMethods.reset({
        deviceId: '',
        siteDeviceId: siteDeviceId.toString(),
        siteDeviceSubsetName: siteDeviceSubset?.siteDeviceSubsetName,
        siteDeviceSubsetType: siteDeviceSubset?.siteDeviceSubsetType,
        generationType: siteDeviceSubset?.generationType,
        referenceLat: initialRefLat,
        referenceLon: initialRefLon,
        referenceDepth: siteDeviceSubset?.referenceDepth?.toString() || '',
        startDate: initialStartDate,
        endDate: initialEndDate,
        comment: siteDeviceSubset.comment,
      });
    } else {
      setIsCast(
        castSiteDeviceData.data?.includes(
          formMethods.getValues().siteDeviceSubsetType
        )
      );
    }
    setRenderedOnce(true);
  }

  return (
    <div>
      <Panel
        title={
          <>
            <Typography variant="body1">Site Device Subset ID:</Typography>
            <Typography
              variant="body1"
              color={isExistingSubsetBeingEdited ? undefined : 'textSecondary'}
            >
              {isExistingSubsetBeingEdited ? siteDeviceSubsetId : 'New'}
            </Typography>
          </>
        }
      >
        {/* submission is handled in SiteDeviceSubsetMaintenanceFormSubmission */}
        <Form onSubmit={() => {}} formMethods={formMethods}>
          <div className="wrap">
            <Grid item xs={6} sx={classes.gridPadding}>
              {isCastBeingCreated() ? (
                <FormTextField
                  name="deviceId"
                  fullWidth
                  rules={{ required: 'A device ID is required!' }}
                  translationKey="device.deviceId"
                  type="number"
                />
              ) : (
                renderSiteDeviceField()
              )}

              {renderSiteDeviceSubsetNameTextField()}
              {renderSiteDeviceSubsetTypeSelect()}
              {renderGenerationTypeSelect()}

              {renderReferenceLatTextField()}
              {renderReferenceLonTextField()}
              {renderReferenceDepthTextField()}

              {isCast ? (
                <> </>
              ) : (
                <GeospatialTextAreaButtonSet
                  title="geospatialArea"
                  initialValue={geospatialAreaWKT}
                  onChange={handleGeoTextChange}
                  onClick={handleGeoOpen}
                  fullWidth
                />
              )}

              <FormDateTimePicker
                useMilliseconds
                name="startDate"
                translationKey="common.datepickers.startDate"
                rules={{
                  required: 'A start date is required',
                  validate: {
                    startDateBeforeEndDate: (dateFrom: Moment) => {
                      startDate = dateFrom;
                      return (
                        dateFrom.isSameOrBefore(endDate) ||
                        'Start date must be before end date'
                      );
                    },
                    elapsedLessThanOneHour: () =>
                      (isCast ? endDate?.diff(startDate, 'hours') < 1 : true) ||
                      'Total time elapsed cannot be more than one hour',
                  },
                }}
              />
              <FormDateTimePicker
                useMilliseconds
                name="endDate"
                translationKey="common.datepickers.endDate"
                rules={{
                  required: 'A start date is required',
                  validate: {
                    startDateBeforeEndDate: (dateTo: Moment) => {
                      endDate = dateTo;
                      return (
                        startDate.isSameOrBefore(dateTo) ||
                        'Start date must be before end date'
                      );
                    },
                    elapsedLessThanOneHour: () =>
                      (isCast ? endDate?.diff(startDate, 'hours') < 1 : true) ||
                      'Total time elapsed cannot be more than one hour',
                  },
                }}
              />

              {shouldRenderRadioGroup() ? renderRadioGroup() : <></>}

              {isCastBeingCreated() ? <></> : renderCommentTextArea()}

              {!isCast || isCastBeingCreated() ? (
                <> </>
              ) : (
                renderCastReviewedCheckbox()
              )}

              {isExistingSubsetBeingEdited ? (
                <>
                  <ModifyBy username={siteDeviceSubset?.modifyBy} />
                  <ModifyDate date={siteDeviceSubset?.modifyDate} />
                </>
              ) : (
                <> </>
              )}

              <SiteDeviceSubsetDOIDatasetLinks
                siteDeviceSubsetId={siteDeviceSubsetId}
              />
            </Grid>
            {isExistingSubsetBeingEdited ? (
              <SiteDeviceSubsetHistoryTable
                siteDeviceSubsetId={siteDeviceSubsetId}
              />
            ) : (
              <> </>
            )}
          </div>
          <SiteDeviceSubsetMaintenanceFormSubmission
            siteDeviceId={Number(formSiteDeviceId) || undefined}
            siteDeviceSubsetId={siteDeviceSubsetId}
            geospatialAreaWKT={geospatialAreaWKT}
            startDate={startDate}
            endDate={endDate}
            rePostProcessOption={rePostProcessOption}
            checkboxChecked={checkboxChecked}
            isCast={isCast}
            isExistingSubsetBeingEdited={isExistingSubsetBeingEdited}
            isCommentRequired={isCommentRequired}
            hasOnlyCastReviewedStatusChanged={hasOnlyCastReviewedStatusChanged}
            hasStartDateChanged={hasStartDateChanged}
            hasEndDateChanged={hasEndDateChanged}
            hasRefLatChanged={hasRefLatChanged}
            hasRefLonChanged={hasRefLonChanged}
            formMethods={formMethods}
          />
        </Form>
      </Panel>
      <Dialog open={geoOpen} maxWidth="md" fullWidth>
        <DialogTitle>Geospatial Area</DialogTitle>
        <DialogContent>
          <SiteDeviceSubsetGeospatialArea
            geospatialAreaWKT={geospatialAreaWKT}
            onSave={handleGeoSave}
            onCancel={handleGeoClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SiteDeviceSubsetMaintenance;
