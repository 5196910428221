/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DataSourceListSortable from 'domain/AppComponents/data-source/DataSourceListSortable';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';

type DataSourceListSortableFormControllerProps = FormFieldType<
  Omit<
    React.ComponentProps<typeof DataSourceListSortable>,
    'dataSourceCallback' | 'dataSources'
  >
>;

export const DataSourceListSortableFormController = ({
  name,
  ...rest
}: DataSourceListSortableFormControllerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DataSourceListSortable
          {...rest}
          dataSourceCallback={onChange}
          dataSources={value}
        />
      )}
    />
  );
};
