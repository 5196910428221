import { Sorting } from '@devexpress/dx-react-grid';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class TaskHistoryService {
  /**
   * Get all child tasks for a specific parent
   *
   * @param {any} parentTaskId The taskId of the parent
   */
  static getChildTasks(parentTaskId: number) {
    return get('TaskHistoryBatchService', {
      operation: 4,
      parentTaskId,
    });
  }

  static getTopLevelChildTasks(
    parentTaskId: number,
    currentPage: number,
    pageSize: number,
    filter: number[],
    field?: string,
    ascending: boolean = false,
    search: string = ''
  ) {
    if (!field) {
      return get('TaskHistoryBatchService', {
        operation: 5,
        parentTaskId,
        currentPage,
        pageSize,
        filter: filter.toString(),
        search,
      });
    }
    return get('TaskHistoryBatchService', {
      operation: 5,
      parentTaskId,
      currentPage,
      pageSize,
      filter: filter.toString(),
      field,
      ascending,
      search,
    });
  }

  static getTaskOutput(taskId: number) {
    return get('TaskHistoryBatchService', {
      operation: 1,
      taskHistoryId: taskId,
    });
  }

  /**
   * Get all child tasks for a specific parent
   *
   * @param {any} startIndex Index to grab records from in query
   * @param {any} status Comma separated list of statuses to filter on
   * @param {any} numberOfRecords Number of records for paging
   */
  static getBatchTasks(
    startIndex,
    status,
    numberOfRecords,
    taskType,
    sort = [] as Sorting[],
    search = ''
  ) {
    if (sort.length > 0) {
      return get('TaskHistoryBatchService', {
        operation: 2,
        taskType,
        numberOfRecords,
        startIndex,
        status,
        field: sort[0].columnName,
        ascending: !sort[0].direction.localeCompare('asc'),
        search,
      });
    }
    return get('TaskHistoryBatchService', {
      operation: 2,
      taskType,
      numberOfRecords,
      startIndex,
      status,
      search,
    });
  }

  /**
   * Cancel a task
   *
   * @param {string} taskHistoryIds Comma separated list of task history ids
   */
  static cancelBatchTask(taskHistoryIds: string) {
    return post('TaskHistoryBatchService', {
      operation: 6,
      taskHistoryIds,
    }).then((response) => parseDmasAPIResponse(response));
  }
}

export default TaskHistoryService;
