import { useContext } from 'react';
import * as React from 'react';
import SeaTubeDashboard from 'domain/AppComponents/sea-tube/SeaTubeDashboard';
import ClipDetailsWidget from 'domain/Widgets/ClipDetailsWidget';
import PlaylistWidget from 'domain/Widgets/PlaylistWidget';
import VideoWidget from 'domain/Widgets/VideoWidget';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';

import { defaultLayout, defaultWidgets } from './layouts';
import PlaylistContext from './PlaylistContext';

const PlaylistPlaybackDashboard: React.VFC = () => {
  const { playlist } = useContext(PlaylistContext);
  const { name } = playlist;

  const widgetLibrary = new WidgetLibrary([
    {
      Component: VideoWidget,
      label: 'Video Player',
      multiple: false,
    },
    {
      Component: PlaylistWidget,
      label: 'Playlist',
      multiple: false,
    },
    {
      Component: ClipDetailsWidget,
      label: 'Clip Details',
      multiple: false,
    },
  ]);

  return (
    <SeaTubeDashboard
      layoutKey="playlist-playback"
      title={`Playlist - ${name}`}
      // Using a uuid here will prevent broadcasting to other tabs / windows
      id={crypto.getRandomValues(new Uint32Array(1)).toString()}
      isStatic
      widgetLibrary={widgetLibrary}
      defaultLayout={defaultLayout}
      defaultWidgets={defaultWidgets}
      showToolbar={false}
    />
  );
};

export default PlaylistPlaybackDashboard;
