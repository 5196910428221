import { isValidElement, ReactNode, useEffect, useRef } from 'react';
import { SizeMe as ReactSizeMe } from 'react-sizeme';
import Box from '../box/Box';

// TODO: Duplicated from DataPLayerConstants
// Values used to dynamically calculate container and image item margins for the widget
export const WIDGET_IMAGE_CONTAINER_OFFSET = 522;
export const WIDGET_IMAGE_ITEM_DIVISOR = 5;
export const WIDGET_IMAGE_ITEM_GAP = 121;

const classes = {
  container: {
    '&:active': {
      cursor: 'grabbing',
    },
    position: 'relative',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s',
    willChange: 'transform',
    userSelect: 'none',
    cursor: 'pointer',
  },
  containerForWidget: {
    '&:active': {
      cursor: 'grabbing',
    },
    position: 'relative',
    height: '90%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s',
    willChange: 'transform',
    userSelect: 'none',
    cursor: 'pointer',
  },
  containerForLatest: {
    position: 'relative',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s',
    willChange: 'transform',
    userSelect: 'none',
  },
  item: {
    display: 'inline-block',
  },
  itemForWidget: {
    display: 'inline-block',
    height: '100%',
  },
  itemForLatest: {
    display: 'inline-block',
    height: '100%',
    width: '100%',
  },
};

type DragScrollProps = {
  id: string;

  /** The child components that should be made draggable. */
  children?: ReactNode;

  /**
   * Determines if the DragScroll component container should be adjusted for the
   * latest or a range of spectrograms. Default value is dateRange.
   */
  dateSelectorValue?: string;

  /**
   * Determines whether the child components should be draggable or not. Default
   * value is true.
   */
  draggable?: boolean;

  /** The amount of horizontal scroll. */
  translate?: number;

  /** A function that is called when the scroll position is updated. */
  onScrollUpdate?: (value: number) => void;

  /** A function that is called when the scroll is completed. */
  onScrollComplete?: (value: number) => void;

  /**
   * Determines whether the component is used in a Dashboard context. Default
   * value is false.
   */
  isDashboard?: boolean;

  /**
   * Determines whether the component should shift or not in a Dashboard
   * context. Default value is false.
   */
  isShift?: boolean;
};

/**
 * DragScroll is a component that allows its children components to be
 * horizontally scrollable by dragging.
 */
const DragScroll = ({
  id,
  children = undefined,
  dateSelectorValue = 'dateRange',
  draggable = true,
  translate = undefined,
  onScrollUpdate = undefined,
  onScrollComplete = undefined,
  isDashboard = false,
  isShift = false,
}: DragScrollProps) => {
  const slider = useRef<HTMLElement>();
  const isDown = useRef<boolean>(false);
  const startX = useRef<number>();
  const scrollLeft = useRef<number>();

  useEffect(() => {
    const mouseUpHandle = () => {
      if (!slider.current) return;
      isDown.current = false;
      slider.current.classList.remove('active');
      if (onScrollComplete) onScrollComplete(slider.current.scrollLeft);
    };

    const mouseDownHandle = (e) => {
      if (!slider.current) return;
      isDown.current = true;
      slider.current.classList.add('active');
      startX.current = e.pageX - slider.current.offsetLeft;
      scrollLeft.current = slider.current.scrollLeft;
    };

    const mouseMoveHandle = (e) => {
      if (!isDown.current || !draggable) return;
      e.preventDefault();
      const x = e.pageX - slider.current.offsetLeft;
      const walk = (x - startX.current) * 3; // scroll-fast
      slider.current.scrollLeft = scrollLeft.current - walk;
      if (onScrollUpdate) onScrollUpdate(slider.current.scrollLeft);
    };

    const mouseLeaveHandle = () => {
      if (!slider.current) return;
      isDown.current = false;
      slider.current.classList.remove('active');
    };

    slider.current = document.getElementById(`scroll-container-${id}`);
    slider.current?.addEventListener('mousedown', mouseDownHandle);
    slider.current?.addEventListener('mouseleave', mouseLeaveHandle);
    slider.current?.addEventListener('mouseup', mouseUpHandle);
    slider.current?.addEventListener('mousemove', mouseMoveHandle);

    // Cleanup function to remove event listeners on unmount
    return () => {
      slider.current?.removeEventListener('mousedown', mouseDownHandle);
      slider.current?.removeEventListener('mouseleave', mouseLeaveHandle);
      slider.current?.removeEventListener('mouseup', mouseUpHandle);
      slider.current?.removeEventListener('mousemove', mouseMoveHandle);
    };
  }, [draggable, id, onScrollComplete, onScrollUpdate]);

  useEffect(() => {
    // Ensure the slider reference is set
    if (!slider.current) return;

    if (isDashboard && translate && !isShift) {
      slider.current.scrollLeft = translate;
    }
  }, [translate, children, isDashboard, isShift]);

  const renderChildren = () => {
    if (!children) return undefined;
    if (Array.isArray(children)) {
      return children.map((item, index) => (
        <Box sx={classes.item} key={item.key ? item.key : index}>
          {item}
        </Box>
      ));
    }
    if (isValidElement(children)) {
      return (
        <Box sx={classes.item} key={children.key}>
          {children}
        </Box>
      );
    }
    return undefined;
  };

  const renderChildrenForDashboard = () => {
    const containerType =
      // render different container dimensions for latest and dateRange modes
      dateSelectorValue === 'dateRange'
        ? classes.itemForWidget
        : classes.itemForLatest;
    if (!children) return [];
    if (Array.isArray(children)) {
      return children.map((item) => (
        // Adjusts item container width dynamically when widget is resized
        <ReactSizeMe
          key={item.key}
          refreshMode="debounce"
          refreshRate={128}
          noPlaceholder
        >
          {({ size }) => (
            <Box
              sx={containerType}
              key={item.key}
              style={{
                marginRight: `calc(
                  (-${size.width}px + ${WIDGET_IMAGE_CONTAINER_OFFSET}px)
                  / ${WIDGET_IMAGE_ITEM_DIVISOR}
                  - ${WIDGET_IMAGE_ITEM_GAP}px)`,
              }}
            >
              {item}
            </Box>
          )}
        </ReactSizeMe>
      ));
    }
    return [];
  };

  const containerType =
    dateSelectorValue === 'dateRange'
      ? classes.containerForWidget
      : classes.containerForLatest;
  if (isDashboard) {
    return (
      <Box id={`scroll-container-${id}`} sx={containerType}>
        {renderChildrenForDashboard()}
      </Box>
    );
  }
  return (
    <Box id={`scroll-container-${id}`} sx={classes.container}>
      {renderChildren()}
    </Box>
  );
};

export { DragScroll as DragScrollForStorybook };
export default DragScroll;
