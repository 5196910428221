/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import * as React from 'react';
import { Theme, Menu } from '@mui/material';
import TextField from '@mui/material/TextField';
import { withStyles, createStyles } from '@mui/styles';
import { ArrowDropDown } from '@onc/icons';
import {
  ListItem,
  ListItemText,
  NestedItem,
  NestedItemType,
  NestedMenuItem,
  Typography,
} from 'base-components';

export type NestedMenuAutocompleteProps = {
  id?: string;
  label: string;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  value?: any;
  onChange: (e: any) => void;
  menuItems: NestedItemType[];
  classes?: any;
};

const styles = (theme: Theme) =>
  createStyles({
    menu: {
      width: 'fit-content',
      minWidth: '200px',
    },
    subtitle: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(),
    },
    listItem: {
      paddingLeft: theme.spacing(3),
    },
    helpText: {
      padding: theme.spacing(),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
      color: '#BBB',
    },
  });

const NestedMenuAutocomplete = ({
  id = 'nested-menu-autocomplete',
  label,
  disabled = false,
  error = false,
  required = false,
  value = undefined,
  helperText = '',
  menuItems,
  onChange,
  classes = undefined,
}: NestedMenuAutocompleteProps) => {
  const [valueState, setValueState] = useState<NestedItemType | undefined>(
    value
  );
  const [inputValue, setInputValue] = useState('');
  const [shrink, setShrink] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorTop, setAnchorTop] = useState(false);
  const [maxHeight, setMaxHeight] = useState(9999);
  const rootItems = menuItems.map((item) => new NestedItem(item));

  // When controlled, update the value state whenever the prop updates
  useEffect(() => {
    setValueState(value);
  }, [value]);

  useEffect(() => {
    if (anchorEl) {
      if (
        anchorEl.getBoundingClientRect().top >
        window.innerHeight - anchorEl.getBoundingClientRect().bottom
      ) {
        setAnchorTop(true);
        setMaxHeight(anchorEl.getBoundingClientRect().top - 50);
        return;
      }
      setAnchorTop(false);
      setMaxHeight(
        window.innerHeight - anchorEl.getBoundingClientRect().bottom - 50
      );
    }
  }, [anchorEl, inputValue]);

  // When closing, the user input should be cleared automatically
  const handleClose = () => {
    setInputValue('');
    setAnchorEl(null);
  };

  // if onChange is defined, use that, else set the state
  const handleChange = (e) => {
    onChange === undefined ? setValueState(e.target) : onChange(e.target);
    handleClose();
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Swaps from selected object to user input
  const handleInputChange = (e) => {
    setValueState(undefined);
    setInputValue(e.target.value);
  };

  const handleFocus = () => {
    setShrink(true);
  };

  const handleOnBlur = () => {
    setShrink(false);
  };

  // Renders the nested menu
  const renderNestedMenuSelect = () => [
    <Typography key="menu-helper-text" className={classes.helpText}>
      Begin Typing to Search...
    </Typography>,
    rootItems.map((item) => (
      <NestedMenuItem
        key={`nested-${item.key}`}
        value={item.value}
        label={item.label}
        name={item.label}
        childrenItems={item.children}
        onClick={handleChange}
      />
    )),
  ];

  // Renders the flat list if user is typing
  const renderFlatList = () => {
    const filterMap = new Map();
    rootItems.forEach((item) => {
      item.getFilteredLeafs(inputValue, filterMap);
    });
    const list: any[] = [];
    filterMap.forEach((val, key) => {
      const subtitle = key;
      list.push(
        <Typography
          variant="subtitle2"
          color="textSecondary"
          className={classes.subtitle}
          key={`flat-${subtitle}`}
        >
          {subtitle}
        </Typography>
      );
      val.forEach((item) => {
        list.push(
          <ListItem
            dense
            button
            className={classes.listItem}
            onClick={() => handleChange({ target: item })}
            key={`flat-${item.key}`}
          >
            <ListItemText primary={item.label} />
          </ListItem>
        );
      });
    });
    if (list.length === 0) {
      list.push(
        <Typography
          variant="subtitle2"
          color="textSecondary"
          className={classes.subtitle}
          key="no-results-found"
          onClose={handleClose}
        >
          No Results Found
        </Typography>
      );
    }
    return list;
  };

  const renderMenu = () => {
    const noInput = !inputValue || inputValue === '';
    return (
      <Menu
        onClose={handleClose}
        open={!!anchorEl}
        disableAutoFocusItem
        autoFocus={false}
        anchorEl={anchorEl}
        // getContentAnchorEl={null}
        disableRestoreFocus
        disableEnforceFocus
        disableAutoFocus
        anchorOrigin={{
          vertical: anchorTop ? 'top' : 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: anchorTop ? 'bottom' : 'top',
          horizontal: 'left',
        }}
      >
        <div
          className={classes.menu}
          style={{ maxHeight }}
          id="nested-autocomple-menu"
        >
          {noInput ? renderNestedMenuSelect() : renderFlatList()}
        </div>
      </Menu>
    );
  };

  return (
    <>
      <TextField
        id={id}
        label={label}
        onClick={handleOpen}
        onFocus={handleFocus}
        onBlur={handleOnBlur}
        onChange={handleInputChange}
        value={valueState ? valueState.label : inputValue}
        disabled={disabled}
        helperText={helperText}
        fullWidth
        variant="filled"
        error={error}
        required={required}
        InputProps={{ endAdornment: <ArrowDropDown />, autoComplete: 'off' }}
        InputLabelProps={{ shrink: !!valueState || !!inputValue || shrink }}
      />
      {renderMenu()}
    </>
  );
};

export default withStyles(styles)(NestedMenuAutocomplete);
