import React from 'react';
import { makeStyles } from '@mui/styles';
import { QuickButton, type QuickEntryButton } from 'base-components';

const useStyles = makeStyles((theme) => ({
  buttonSet: {
    display: 'flex',
    flexWrap: 'nowrap', // Prevent wrapping of columns
    width: `calc(100% - ${theme.spacing(2)})`,
    margin: theme.spacing(),
  },
  column: {
    flex: '1 1 0', // Flex-grow: 1, Flex-shrink: 1, Flex-basis: 0 (all columns same width)
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0, // Allow the column to shrink below its content size
    maxWidth: '300px', // Prevent buttons from stretching too wide
  },
}));

type ButtonSetProps = {
  onButtonClick: (button: QuickEntryButton, autoSave?: boolean) => void;
  currentButtonSet?: QuickEntryButton[];
  autoSave?: boolean;
  currentButton?: { sequenceNumber: number };
  disableEmptyButtons?: boolean;
  showIndexes?: boolean;
  maxColumns?: number;
};

const ButtonSet: React.FC<ButtonSetProps> = ({
  onButtonClick,
  currentButtonSet = [],
  autoSave = false,
  currentButton = undefined,
  disableEmptyButtons = true,
  showIndexes = true,
  maxColumns = 10,
}) => {
  const classes = useStyles();

  // Function to convert the button set into a 2D array (columns)
  const createButtonColumns = () => {
    if (!currentButtonSet) return [];

    // Initialize columns as an array of arrays
    const columns: QuickEntryButton[][] = Array.from(
      { length: maxColumns },
      () => []
    );

    // Distribute buttons into columns
    currentButtonSet.forEach((button, index) => {
      const columnIndex = index % maxColumns; // Determine which column to put the button in
      if (disableEmptyButtons && !button.label) return; // Skip empty buttons
      columns[columnIndex].push(button);
    });

    // Remove empty columns
    return columns.filter((column) => column.length > 0);
  };

  const buttonColumns = createButtonColumns();

  return (
    <div className={classes.buttonSet}>
      {buttonColumns.map((column, index) => (
        // I don't have anything other than the index to use as a key
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={classes.column}>
          {column.map((button) => (
            <QuickButton
              key={button.sequenceNumber}
              buttonData={button}
              onClick={() => onButtonClick(button, autoSave)}
              disableEmptyButtons={disableEmptyButtons}
              showIndexes={showIndexes}
              isSelected={
                currentButton?.sequenceNumber === button.sequenceNumber
              }
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default ButtonSet;
