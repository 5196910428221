/* eslint-disable react/no-unused-prop-types */
import { createStyles, makeStyles } from '@mui/styles';
import { Moment } from 'moment';
import {
  Collapse,
  Grid,
  ToggleButton,
  TypographyWithTranslation,
} from 'base-components';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import FormToggle from 'library/CompositeComponents/form/FormToggle';
import FormToggleButtonGroup from 'library/CompositeComponents/form/FormToggleButtonGroup';
import {
  FormDataAvailabilityChart,
  FormDataSourceList,
  FormDeviceAutocomplete,
} from './AudioPlayerWidgetFormControllers';

const useStyles = makeStyles(() =>
  createStyles({
    formButtons: {
      marginTop: 10,
      float: 'right',
    },
    titleField: {
      marginTop: 0,
    },
  })
);

export type AudioPlayerWidgetFormType = {
  audioFormat: string;
  clipSelectorValue: 'latestClip' | 'timeRange';
  dataSourceType: 'location' | 'device';
  device: any;
  endDate?: Moment;
  isBroadcasting: boolean;
  locations: any;
  loopPlayback: boolean;
  startDate?: Moment;
  showTitle: boolean;
  title: string;
};

type deviceMap = any[];

const AudioPlayerWidgetFormContents = ({
  clipSelectorValue,
  device,
  locations,
  dataSourceType,
  showTitle,
  audioFormat,
}: AudioPlayerWidgetFormType) => {
  const classes = useStyles();

  const renderDatePickers = () => (
    <Collapse in={clipSelectorValue === 'timeRange'}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormDateTimePicker
            translationKey="common.datepickers.startDate"
            name="startDate"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateTimePicker
            translationKey="common.datepickers.endDate"
            name="endDate"
            fullWidth
          />
        </Grid>
      </Grid>
    </Collapse>
  );

  const renderDeviceDataAvailability = () => (
    <Collapse in={device && device.deviceId}>
      <FormDataAvailabilityChart
        name="timeRange"
        dataSources={[
          {
            dataSourceType: 'device',
            deviceCode: device ? device.deviceCode : undefined,
            dataProductCode: 'AD',
            extensions: [audioFormat],
          },
        ]}
        canSelectDateRange
      />
    </Collapse>
  );

  const renderLocationDataAvailability = () => {
    const newDeviceMap: deviceMap = [];
    locations.forEach((node) => {
      node.els.forEach((siteDevice) => {
        newDeviceMap.push({
          dataSourceType: 'location',
          locationCode: siteDevice.stationCode,
          deviceCategoryCode: siteDevice.deviceCategoryCode,
          dataProductCode: 'AD',
          extensions: [audioFormat],
        });
      });
    });
    return (
      <Collapse in={locations && locations.length > 0}>
        <FormDataAvailabilityChart
          name="timeRange"
          canSelectDateRange
          dataSources={newDeviceMap}
        />
      </Collapse>
    );
  };

  const renderDataAvailability = () => {
    if (dataSourceType === 'location') {
      return renderLocationDataAvailability();
    }
    if (dataSourceType === 'device') {
      return renderDeviceDataAvailability();
    }
    return <></>;
  };

  const renderDateSection = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {renderDataAvailability()}
      </Grid>
      <TypographyWithTranslation
        variant="subtitle1"
        translationKey="dashboards.dateSelect"
      />
      <Grid item xs={12}>
        <FormToggleButtonGroup
          data-test="date-select-buttongroup"
          exclusive
          label="Date Select"
          name="clipSelectorValue"
        >
          <ToggleButton value="latestClip">Latest</ToggleButton>
          <ToggleButton value="timeRange">Date Range</ToggleButton>
        </FormToggleButtonGroup>
      </Grid>
      {renderDatePickers()}
    </Grid>
  );

  const renderDataSourceType = () => {
    if (dataSourceType === 'location') {
      return (
        <FormDataSourceList
          propertyPreset={['Audio']}
          treeTypePreset="instrumentsByLocation"
          name="locations"
        />
      );
    }
    return (
      <Grid item xs={12}>
        <FormDeviceAutocomplete name="device" />
      </Grid>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormToggle label="Show Title" name="showTitle" />
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showTitle}>
          <FormTextField
            fullWidth
            translationKey="common.textfields.title"
            name="title"
            className={classes.titleField}
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <FormToggle name="loopPlayback" label="Loop Playback" disableRipple />
      </Grid>
      <Grid item xs={12}>
        <FormToggle
          name="isBroadcasting"
          label="Enable Audio Player Synchronize"
          tooltip="Synchronizes time with the Data Player image. Control of time is handled via the Audio widget."
        />
      </Grid>
      <Grid item xs={12}>
        <TypographyWithTranslation
          variant="subtitle1"
          translationKey="dashboards.audioType"
        />
        <FormToggleButtonGroup
          name="audioFormat"
          label="Audio"
          exclusive
          requireSelection
        >
          <ToggleButton value="flac"> flac </ToggleButton>
          <ToggleButton value="mp3"> mp3 </ToggleButton>
        </FormToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <TypographyWithTranslation
          variant="subtitle1"
          translationKey="dashboards.dataSource"
        />
        <FormToggleButtonGroup
          name="dataSourceType"
          label="Data Source"
          exclusive
          requireSelection
        >
          <ToggleButton value="device"> Device </ToggleButton>
          <ToggleButton value="location"> Location </ToggleButton>
        </FormToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        {renderDataSourceType()}
      </Grid>
      <Grid item xs={12}>
        {renderDateSection()}
      </Grid>
    </Grid>
  );
};

export default AudioPlayerWidgetFormContents;
