import * as React from 'react';
import { createStyles, withStyles } from '@mui/styles';
import { oceans3Theme } from '@onc/theme';
import { TypographyWithTranslation } from 'base-components';
import Environment from 'util/Environment';

const STYLES = () =>
  createStyles({
    warning: {
      margin: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    typography: {
      color: oceans3Theme.palette.secondary.main,
      textAlign: 'center',
    },
  });

interface WidgetConfigErrorProps {
  message?: string;
  classes?: any;
}
const UnstyledWidgetConfigError: React.VFC<WidgetConfigErrorProps> = ({
  message = undefined,
  classes = undefined,
}: WidgetConfigErrorProps) => {
  if (message) {
    return (
      <div className={classes.warning} role="status">
        <TypographyWithTranslation
          className={classes.typography}
          translationKey="common.textfields.variable"
          translationOptions={{ variableName: message }}
        />
      </div>
    );
  }
  return null;
};

const getWidgetConfigErrorMessage = (
  invalidConfigFields: string[]
): string | undefined => {
  const ERROR_MESSAGE = 'Incomplete Configuration';
  if (invalidConfigFields && invalidConfigFields.length > 0) {
    if (Environment.isUserLoggedIn()) {
      return `${ERROR_MESSAGE}: ${invalidConfigFields.toString()}`;
    }
    return ERROR_MESSAGE;
  }
  return undefined;
};

const WidgetConfigError = withStyles(STYLES)(UnstyledWidgetConfigError);
export { WidgetConfigError, getWidgetConfigErrorMessage };
