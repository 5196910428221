import { useContext, useState } from 'react';
import { Description } from '@onc/icons';
import { MapFABControl } from 'base-components';
import CastDataContext from 'domain/Apps/geospatial-search/CastDataContext';
import SiteDeviceSubsetDownloadTableDialog from 'domain/Apps/geospatial-search/community-fisher/SiteDeviceSubsetDownloadTableDialog';
import { useSnackbars } from 'util/hooks/useSnackbars';

/**
 * Contains a Floating Action Button that displays a popup for Download History
 * when clicked
 */
const DownloadHistoryTableFAB = () => {
  const { downloadedCasts, updateDate } = useContext(CastDataContext);
  const { onInfo, onError } = useSnackbars();
  const [renderDownloadHistoryTable, setRenderDownloadHistoryTable] =
    useState<boolean>(false);

  const title = renderDownloadHistoryTable
    ? 'Hide History Table'
    : 'Show History Table';

  const toggleDownloadHistoryTable = () => {
    setRenderDownloadHistoryTable(!renderDownloadHistoryTable);
  };

  return (
    <>
      <MapFABControl
        TooltipProps={{ title }}
        onClick={toggleDownloadHistoryTable}
        size="medium"
        color="primary"
        options={{ position: 'bottomleft' }}
        prepend
      >
        <Description />
      </MapFABControl>
      {renderDownloadHistoryTable && (
        <SiteDeviceSubsetDownloadTableDialog
          onInfo={onInfo}
          onError={onError}
          siteDeviceSubsetRows={downloadedCasts}
          renderSearchField
          onCloseClick={() => setRenderDownloadHistoryTable(false)}
          key={updateDate}
        />
      )}
    </>
  );
};

export default DownloadHistoryTableFAB;
