/* eslint-disable react/prop-types */
import { Table } from '@devexpress/dx-react-grid-material-ui';

const CellComponent = ({ ...props }) => {
  const { row, column, value, children, style, tableRow, tableColumn } = props;
  return (
    <Table.Cell
      {...props}
      tableRow={tableRow}
      tableColumn={tableColumn}
      value={value}
      row={row}
      column={column}
      style={style}
      aria-label={`cell-${column.name}`}
    >
      {children}
    </Table.Cell>
  );
};

export default CellComponent;
