import { post, get, patch, deleteImpl } from 'util/WebRequest';

class TaxonomyAttributeLineService {
  static async getByAttributeId(taxonomyAttributeId) {
    const response = await get(
      `internal/taxonomies/attributes/${taxonomyAttributeId}/lines`
    );
    return response.data;
  }

  static async create(attributeId, attributeValue) {
    const response = await post(
      `internal/taxonomies/attributes/${attributeId}/lines`,
      { attributeValue },
      {
        headers: { 'content-type': 'application/json' },
      }
    );
    return response.data;
  }

  static async update(taxonomyAttributeLineId, params) {
    const response = await patch(
      `internal/taxonomies/attributes/lines/${taxonomyAttributeLineId}`,
      params,
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async delete(taxonomyAttributeLineId) {
    const response = await deleteImpl(
      `internal/taxonomies/attributes/lines/${taxonomyAttributeLineId}`
    );
    return response.data;
  }
}
export default TaxonomyAttributeLineService;
