/* eslint-disable react/prop-types */
import { DurationInputArg1, DurationInputArg2, Moment } from 'moment';
import {
  Button,
  DateTimePicker,
  Dropdown,
  type DropdownOption,
  Grid,
} from 'base-components';
import { DashboardConfigComponent } from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardDateRange from '../hooks/useDashboardDateRange';
import type { PresetDateRange } from 'domain/hooks/useDateRangeCore';

export type DataViewerDashboardConfigType = {
  startDate: Moment | undefined;
  endDate: Moment | undefined;
  dateRangeIsInherited: boolean;
  preset: PresetDateRange;
};

const presetOptions: {
  id: PresetDateRange;
  label: string;
  subtractNumber?: DurationInputArg1;
  subtractInterval?: DurationInputArg2;
}[] = [
  {
    id: '10m',
    label: 'Last 10 Minutes',
    subtractNumber: 10,
    subtractInterval: 'minutes',
  },
  {
    id: '2h',
    label: 'Last 2 Hours',
    subtractNumber: 2,
    subtractInterval: 'hours',
  },
  {
    id: '24h',
    label: 'Last 24 Hours',
    subtractNumber: 24,
    subtractInterval: 'hours',
  },
  {
    id: '7d',
    label: 'Last 7 Days',
    subtractNumber: 7,
    subtractInterval: 'days',
  },
  {
    id: 'custom',
    label: 'Custom',
  },
  {
    id: 'dataRange',
    label: 'Data Range',
  },
];

const dropdownOptions: DropdownOption[] = presetOptions.map((option) => ({
  key: option.id,
  label: option.label,
  value: option.id,
}));

const DataViewerDashboardConfig: DashboardConfigComponent<
  DataViewerDashboardConfigType
> = () => {
  const {
    dateRange,
    setDateRange,
    setPresetDateRange,
    shiftDateRangeAhead,
    shiftDateRangeBack,
    zeroOutTime,
    preset,
    setPreset,
  } = useDashboardDateRange();

  return (
    <Grid container spacing={2} columns={16}>
      <Grid item xs={6}>
        <DateTimePicker
          translationKey="common.datepickers.startDate"
          value={dateRange[0]}
          onChange={(newStartDate) => {
            setDateRange([newStartDate, dateRange[1]]);
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          translationKey="common.datepickers.endDate"
          value={dateRange[1]}
          onChange={(newEndDate) => {
            setDateRange([dateRange[0], newEndDate]);
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <Dropdown
          label="Time Presets"
          options={dropdownOptions}
          value={preset}
          onChange={(event) => {
            const selectedPreset = presetOptions.find(
              (option) => option.id === event.target.value
            );
            if (selectedPreset) {
              if (
                selectedPreset.id !== 'custom' &&
                selectedPreset.id !== 'dataRange'
              ) {
                setPresetDateRange(
                  selectedPreset.subtractNumber,
                  selectedPreset.subtractInterval,
                  selectedPreset.id
                );
              } else {
                setPreset(selectedPreset.id);
              }
            }
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          translationKey="dataViewer.previous"
          variant="contained"
          onClick={shiftDateRangeBack}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          translationKey="dataViewer.next"
          variant="contained"
          onClick={shiftDateRangeAhead}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          translationKey="dataViewer.reset"
          variant="text"
          onClick={zeroOutTime}
        />
      </Grid>
    </Grid>
  );
};

export default DataViewerDashboardConfig;
