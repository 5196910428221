import { useState, useEffect } from 'react';
import DeviceCategoryWebServiceWithToken from 'domain/services/DeviceCategoryWebServiceWithToken';

function useFilteredDeviceCategories(
  propertyCode,
  locationCode,
  deviceCategoryCode
) {
  const [deviceCategories, setDeviceCategories] = useState([]);
  useEffect(() => {
    const deviceCategoryList = [];
    DeviceCategoryWebServiceWithToken.get({
      method: 'get',
      propertyCode,
      locationCode,
      deviceCategoryCode,
    })
      .then((response) => {
        response.data.map((record) =>
          deviceCategoryList.push({
            description: record.description,
            deviceCategoryCode: record.deviceCategoryCode,
            name: record.deviceCategoryName,
          })
        );
        setDeviceCategories(deviceCategoryList);
      })
      .catch(() => {
        setDeviceCategories([]);
      });
    return () => setDeviceCategories([]);
  }, [propertyCode, locationCode, deviceCategoryCode]);
  return deviceCategories;
}
export default useFilteredDeviceCategories;
