import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Loading } from '@onc/composite-components';
import { FiberManualRecord } from '@onc/icons';
import { Chip } from 'base-components';
import DiveVideoPlayer from 'domain/AppComponents/sea-tube/dive-log/DiveVideoPlayer';
import SeaTubeLogContext from 'domain/AppComponents/sea-tube/SeaTubeLogContext';
import QueryParameterContext from 'domain/Apps/menu/QueryParameterContext';
import SeaTubeVideoService, {
  SeaTubeResolution,
} from 'domain/services/SeaTubeVideoService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import {
  VideoInterval,
  findIntervals,
} from 'library/CompositeComponents/video/SeamlessVideoUtil';
import useBroadcast from 'util/hooks/useBroadcast';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import BroadcastChannel from './BroadcastChannel';

const priorityOrder = {
  High: 1,
  Medium: 2,
  Low: 3,
};

// Sort qualities by priority
const sortQualities = (qualities: SeaTubeResolution[]) =>
  qualities?.sort((a, b) => {
    const priorityA = priorityOrder[a.description] || Number.MAX_SAFE_INTEGER;
    const priorityB = priorityOrder[b.description] || Number.MAX_SAFE_INTEGER;

    return priorityA - priorityB;
  });

const DiveVideoWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId } = props;

  const { dive } = useContext(SeaTubeLogContext);
  const { isLiveMode } = useContext(QueryParameterContext);
  const { isDiveLive, liveStreamUrl, diveId } = dive;

  const { onError } = useSnackbars();

  const [currentTimestamp, setCurrentTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.CurrentTimestamp,
    undefined,
    id
  );

  const [seekToTimestamp, setSeekToTimestamp] = useBroadcast<string>(
    dashboardId,
    BroadcastChannel.SeekToTimestamp,
    undefined,
    id
  );

  const [, setVideoIntervals] = useBroadcast<VideoInterval[]>(
    dashboardId,
    BroadcastChannel.VideoIntervals,
    null,
    id
  );

  const [videoInfo, , fetchVideoInfo] = useWebService({
    method: SeaTubeVideoService.getSeamlessByDiveId,
    onError,
    parser: SeaTubeVideoService.toSeamlessVideoInfo,
  });

  const videoInfoRef = useRef(videoInfo);

  const [, setIntervalId] = useState<NodeJS.Timeout>();

  const handleClearPollingInterval = () => {
    setIntervalId((interval) => {
      clearInterval(interval);
      return null;
    });
  };

  useEffect(() => {
    videoInfoRef.current = videoInfo;
  }, [videoInfo]);

  useEffect(() => {
    fetchVideoInfo(diveId, videoInfoRef.current?.currentQuality);

    if (
      !isLiveMode &&
      moment.utc().isBefore(moment.utc(dive.dateTo).add(2, 'hours'))
    ) {
      setIntervalId(
        setInterval(() => {
          fetchVideoInfo(diveId, videoInfoRef.current?.currentQuality);
        }, 60000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeQuality = (quality) => {
    fetchVideoInfo(diveId, quality);
  };

  useEffect(() => {
    if (videoInfo && videoInfo.startDate) {
      const intervals = findIntervals(
        videoInfo.files || [],
        videoInfo.startDate.unix()
      );
      setVideoIntervals(intervals);
    }
  }, [setVideoIntervals, videoInfo]);

  const loading = !isLiveMode && videoInfo === undefined;
  const renderLiveVideoChip = () => [
    <Chip
      aria-label="live video"
      icon={<FiberManualRecord color="inherit" />}
      label="Live"
      variant="outlined"
      color="primary"
    />,
  ];

  return (
    <Widget
      key={id}
      title="Dive Video"
      titleComponents={isLiveMode ? renderLiveVideoChip() : undefined}
      MenuItems={[]}
      onRemoveAction={() => {
        setVideoIntervals(null);
        handleClearPollingInterval();
      }}
      {...props}
    >
      {loading ? (
        <Loading />
      ) : (
        <div style={{ overflow: 'hidden', height: '100%' }}>
          <DiveVideoPlayer
            files={videoInfo?.files || []}
            startDate={videoInfo?.startDate}
            qualityOptions={sortQualities(videoInfo?.availableResolutions)}
            currentQuality={videoInfo?.currentQuality}
            onProgress={(date) => {
              // Don't update the timestamp if we're seeking
              if (seekToTimestamp) return;
              setCurrentTimestamp(date.toISOString());
            }}
            isLive={isDiveLive}
            liveStreamUrl={liveStreamUrl || ''}
            seekToTimestamp={seekToTimestamp}
            onChangeQuality={handleChangeQuality}
            currentTimestamp={moment.utc(currentTimestamp)}
            onSeekComplete={() => {
              setSeekToTimestamp(null);
            }}
          />
        </div>
      )}
    </Widget>
  );
};

DiveVideoWidget.widgetKey = 'dive-video';
DiveVideoWidget.widgetTitle = 'Dive Video';
DiveVideoWidget.defaultDataGrid = {
  i: 'dive-video',
  x: 0,
  y: 0,
  w: 5,
  h: 12,
};

export default DiveVideoWidget;
