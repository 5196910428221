import { useContext } from 'react';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../../library/CompositeComponents/dashboard/DashboardTypes';
import EndeavourContext from '../context/EndeavourContext';
import LatitudeOverTime from '../plots/LatitudeOverTime';

const EndeavourLatitudePlotWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;
  const { earthquakes } = useContext(EndeavourContext);

  return (
    <Widget key={id} title="Earthquake Latitude Plot" {...props}>
      <LatitudeOverTime earthquakes={earthquakes} />
    </Widget>
  );
};

EndeavourLatitudePlotWidget.widgetKey = 'endeavour-earthquake-latitude-plot';
EndeavourLatitudePlotWidget.widgetTitle = 'Earthquake Latitude Plot';
EndeavourLatitudePlotWidget.defaultDataGrid = {
  i: 'endeavour-earthquake-latitude-plot',
  x: 0,
  y: 0,
  w: 6,
  h: 5,
};

export default EndeavourLatitudePlotWidget;
