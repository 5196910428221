import json5 from 'json5';

import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

export type Licence = {
  licenceId: number;
  licenceName: string;
  licenceAbbreviation: string;
};

export type ExistingAgreementSiteDevice = {
  siteDevice: {
    siteDeviceId: number;
    device: {
      deviceId: number;
      deviceName: string;
    };
    site: {
      siteId: number;
      siteName: string;
    };
    dateFrom: Date;
    dateTo?: Date;
  };
  minStartDate?: Date;
  maxEndDate?: Date;
  organizationAgreementResource: {
    organizationAgreementResourceId: number;
    organizationAgreement: {
      organizationAgreementId: number;
      organizationAgreementName: string;
      licence: Licence;
    };
    startDate?: Date;
    endDate?: Date;
    licence?: Licence;
    modifyBy: string;
    modifyDate: Date;
  };
  licence?: Licence;
};

export type AvailableAgreementSiteDevice = Omit<
  ExistingAgreementSiteDevice,
  'organizationAgreementResource'
>;

export type SearchParams = {
  siteDeviceId?: number;
  siteId?: number;
  deviceCategoryId?: number;
  deviceId?: number;
  startDate?: Date;
  endDate?: Date;
};

export type CreateAgreementSiteDevice = {
  organizationAgreementId: number;
  siteDeviceId: number;
  dateFrom?: Date;
  dateTo?: Date;
};

export type UpdateAgreementSiteDevice = {
  organizationAgreementResourceId: number;
  startDate?: Date;
  endDate?: Date;
  licenceId: number | null;
};

// FIXME axios takes an array of transformers: this should be [
// WebRequest.transformResponse, this.transformResponse ], where this
// transformResponse parses dates in JSON with a recursive reducer instead
// of parsing the JSON again
const transformResponse = (data) =>
  json5.parse(data, (key, value) => {
    // Check if the value is a string matching the ISO8601 date format
    if (
      typeof value === 'string' &&
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?$/.test(value)
    ) {
      const date = new Date(value);
      // Check if the parsed date is a valid Date object
      return !isNaN(date.getTime()) ? date : undefined;
    }
    // Check that value is a number, because apparently objects with NaNs are NaN
    if (typeof value === 'number' && isNaN(value)) {
      return null;
    }
    return value;
  });

class AgreementSiteDeviceService {
  static getByOrganizationAgreementId = (
    organizationAgreementId: number
  ): Promise<ExistingAgreementSiteDevice[]> =>
    get(
      'AgreementSiteDeviceService',
      {
        operation: 10,
        organizationAgreementId,
      },
      { transformResponse }
    ).then((response) => parseDmasAPIResponse(response));

  static searchForAvailableAgreementSiteDevices = (
    searchParams: SearchParams
  ): Promise<AvailableAgreementSiteDevice[]> =>
    get(
      'AgreementSiteDeviceService',
      {
        operation: 11,
        ...searchParams,
      },
      { transformResponse }
    ).then((response) => parseDmasAPIResponse(response));

  static create = (params: CreateAgreementSiteDevice) =>
    post('AgreementSiteDeviceService', {
      operation: 1,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));

  static update = (params: UpdateAgreementSiteDevice) =>
    post('AgreementSiteDeviceService', {
      operation: 2,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));

  static delete = (organizationAgreementResourceId: number) =>
    post('AgreementSiteDeviceService', {
      operation: 3,
      organizationAgreementResourceId,
    }).then((response) => parseDmasAPIResponse(response));
}
export default AgreementSiteDeviceService;
