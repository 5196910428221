import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, DatePickerProps } from 'base-components';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';

type FormDatePickerProps = FormFieldType<DatePickerProps>;

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  rules,
  defaultValue,
  disabled,
  shouldUnregister,
  ...rest
}: FormDatePickerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      disabled={disabled}
      shouldUnregister={shouldUnregister}
      render={({ field }) => <DatePicker {...rest} {...field} />}
    />
  );
};

export default FormDatePicker;
