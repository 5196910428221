import { FC } from 'react';

import {
  divIcon as DivIcon,
  Marker,
  MarkerClusterGroup,
  Point,
  LeafletTooltip as Tooltip,
} from 'base-components';

import CFMarker from './CFMarker';
import { SiteDeviceSubset } from '../../definitions/GeospatialSearchTypes';

type CFMarkerClusterProps = {
  /** All assigned cast data */
  assignedCastData: any[];
  /** Boolean on whether to hide GA polygons */
  hideCFPolygons;
  /** Function to recoluorIcons when overlay or zoom changes */
  recolourIcons: () => void;
  /** Function to filter casts based on SubsetDevice parameters */
  filterCasts: (siteDeviceSubsets: SiteDeviceSubset[]) => SiteDeviceSubset[];
  /** Sets a station's checkbox based on it's code */
  downloadAllUnrestrictedCastsFromStation: (code: string) => void;
  /** If this clusterGroup is decommissioned */
  isDecommissioned: boolean;
};

const CFMarkerClusterGroup: FC<CFMarkerClusterProps> = ({
  assignedCastData,
  hideCFPolygons,
  recolourIcons,
  filterCasts,
  downloadAllUnrestrictedCastsFromStation,
  isDecommissioned,
}) => {
  const createClusterIcon = (cluster) =>
    DivIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: isDecommissioned
        ? 'custom-marker-cluster grey-marker-cluster'
        : 'custom-marker-cluster blue-marker-cluster',
      iconSize: Point(33, 33, true),
    });

  return (
    <MarkerClusterGroup
      iconCreateFunction={createClusterIcon}
      showCoverageOnHover={false}
      eventHandlers={{
        // @ts-expect-error animationed is added by a third party leaflet extension and is therefore not typechecked
        animationend: recolourIcons,
      }}
    >
      {assignedCastData &&
        assignedCastData.map((station) => (
          <CFMarker
            station={station}
            filterCasts={filterCasts}
            hideCFPolygons={hideCFPolygons}
            downloadAllUnrestrictedCastsFromStation={
              downloadAllUnrestrictedCastsFromStation
            }
            isDecommissioned={isDecommissioned}
          />
        ))}
      <Marker position={[48.649494, -123.44568]}>
        <Tooltip permanent direction="center">
          MTC
        </Tooltip>
      </Marker>
    </MarkerClusterGroup>
  );
};

export default CFMarkerClusterGroup;
