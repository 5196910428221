import MaterialTextField, { TextFieldProps } from '@mui/material/TextField';
import { TOptions, useTranslation, TranslationType } from '@onc/i18n';

export type TextFieldPropTypes = Omit<TextFieldProps, 'label'> & {
  /**
   * Text field label from the json file
   * util/i18n/src/translation/en/translation.json
   */
  translationKey?: TranslationType;

  /** Options to pass in with the translation. */
  translationOptions?: TOptions;

  /**
   * Prop to disable the field. Avoids value from being cleared when wrapped in
   * a Controller.
   */
  disabledTextField?: boolean;
};

const TextField = ({
  translationKey = undefined,
  translationOptions = undefined,
  variant = 'filled',
  rows = undefined,
  ...rest
}: TextFieldPropTypes) => {
  const { t } = useTranslation();
  return (
    <MaterialTextField
      label={t(translationKey, translationOptions)}
      variant={variant}
      minRows={rows}
      {...rest}
    />
  );
};

export default TextField;
