/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { FilterEvent } from 'library/CompositeComponents/filter/Filter';
import NumberRangeFilter from './NumberRangeFilter';

type Props = {
  value: any;
  name: string;
  onChange: (event: FilterEvent) => void;
};

const DepthFilter: React.VFC<Props> = (props: Props): React.ReactElement => {
  const { onChange, name, value } = props;
  return (
    <NumberRangeFilter
      value={value}
      name={name}
      onChange={onChange}
      label="Depth (m)"
      uom="m"
    />
  );
};

export default DepthFilter;
