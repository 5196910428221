import React from 'react';
import { Pause, PlayArrow } from '@onc/icons';
import { IconButton } from 'base-components';

type PlayPauseButtonProps = {
  isPlaying: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  containerRef?: HTMLDivElement;
};

const PlayPauseButton: React.FC<PlayPauseButtonProps> = ({
  isPlaying,
  onClick,
  containerRef = undefined,
}: PlayPauseButtonProps) => (
  <IconButton
    color="inherit"
    onClick={onClick}
    aria-label={isPlaying ? 'Pause' : 'Play'}
    tooltipProps={{
      slotProps: {
        popper: { container: containerRef },
      },
    }}
  >
    {isPlaying ? <Pause color="inherit" /> : <PlayArrow color="inherit" />}
  </IconButton>
);

export default PlayPauseButton;
