import * as React from 'react';
import moment from 'moment';
import { DateTimePicker, Grid } from 'base-components';

interface Props {
  startDate: Date;
  endDate: Date;
  timePeriod: string;
  handleStartDateChange: (event: any) => void;
  handleEndDateChange: (event: any) => void;
}

const DataSourceWidgetDatePicker: React.FC<Props> = (props: Props) => {
  const {
    startDate,
    endDate,
    timePeriod,
    handleStartDateChange,
    handleEndDateChange,
  } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <DateTimePicker
          translationKey="common.datepickers.startDate"
          value={moment(startDate)}
          onChange={(event) => handleStartDateChange(event)}
          disabled={timePeriod !== 'custom'}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <DateTimePicker
          translationKey="common.datepickers.endDate"
          value={moment(endDate)}
          onChange={(event) => handleEndDateChange(event)}
          disabled={timePeriod !== 'custom'}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default DataSourceWidgetDatePicker;
