import { useState } from 'react';
import ReactLightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

type LightboxProps = {
  /** Array of image URLS to display in the light box */
  imageUrls: string[];
  /** Callback function to call when lightbox is closed */
  onClose: () => void;
  /**
   * The initial image URL to display. Must be in imageUrls or it will default
   * to the first image
   */
  initialImageUrl?: string;
};
/**
 * Lightbox to display images. See
 * https://www.npmjs.com/package/react-image-lightbox for more information and
 * other cool props you can use with your Lightbox.
 */
const Lightbox = ({
  imageUrls,
  onClose,
  initialImageUrl = '',
  ...otherProps
}: LightboxProps) => {
  const getInitialIndex = () => {
    const index = imageUrls.indexOf(initialImageUrl);
    if (index < 0) {
      return 0;
    }
    return index;
  };

  const [currentIndex, setCurrentIndex] = useState<number>(getInitialIndex());

  const getNextIndex = () => (currentIndex + 1) % imageUrls.length;

  const getPreviousIndex = () =>
    (currentIndex + imageUrls.length - 1) % imageUrls.length;

  const handleMoveNext = () => {
    setCurrentIndex(getNextIndex());
  };

  const handleMovePrevious = () => {
    setCurrentIndex(getPreviousIndex());
  };

  const getNextSrc = () => {
    // don't display arrows if only one image
    if (imageUrls.length < 2) return undefined;
    return imageUrls[getNextIndex()];
  };

  const getPrevSrc = () => {
    // don't display arrows if only one image
    if (imageUrls.length < 2) return undefined;
    return imageUrls[getPreviousIndex()];
  };

  return (
    <ReactLightbox
      mainSrc={imageUrls[currentIndex]}
      nextSrc={getNextSrc()}
      prevSrc={getPrevSrc()}
      onCloseRequest={onClose}
      onMovePrevRequest={handleMovePrevious}
      onMoveNextRequest={handleMoveNext}
      {...otherProps}
    />
  );
};

export default Lightbox;
