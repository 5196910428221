import { useEffect } from 'react';
import LocationsWebServiceWithToken from 'domain/services/LocationsWebServiceWithToken';
import { useSearchTreeNodeDetails } from 'domain/services/SearchTreeNodeService';
import SearchTreeNodeDetails from 'domain/Widgets/fixed-camera/SearchTreeNodeDetails';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import useBroadcast from 'util/hooks/useBroadcast';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import BroadcastChannel from '../BroadcastChannel';

const FixedCameraDetailsWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id, dashboardId } = props;
  const [searchTreeNodeId] = useBroadcast<number>(
    dashboardId,
    BroadcastChannel.SearchTreeNodeId,
    undefined,
    id
  );
  const { onError } = useSnackbars();

  const { setDashboardStateProperty } = useDashboardState();

  const { data: searchTreeNodeDetails, isFetched } = useSearchTreeNodeDetails({
    searchTreeNodeId,
  });

  const [locationData, isLoading, getLocations] = useWebService({
    method: LocationsWebServiceWithToken.get,
    onError,
  });

  useEffect(() => {
    if (isFetched) {
      getLocations({
        locationCode: searchTreeNodeDetails?.stationCode,
        aggregateDeployments: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTreeNodeDetails]);

  useEffect(() => {
    if (locationData?.data) {
      setDashboardStateProperty('locationData', locationData.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData?.data]);

  return (
    <Widget id={id} loading={isLoading} title="Details" {...props}>
      <SearchTreeNodeDetails searchTreeNodeDetails={locationData?.data[0]} />
    </Widget>
  );
};

FixedCameraDetailsWidget.widgetKey = 'fixed-camera-details-widget';
FixedCameraDetailsWidget.widgetTitle = 'Details';
FixedCameraDetailsWidget.defaultDataGrid = {
  i: 'fixed-camera-details-widget',
  x: 9,
  y: 0,
  w: 3,
  h: 9,
};

export default FixedCameraDetailsWidget;
