import { parseDmasAPIResponse, get } from 'util/WebRequest';

class SiteDeviceSubsetCastDefinitionTransactionService {
  static getSiteDeviceSubsetTransactions = (siteDeviceSubsetId: number) =>
    get(
      'SiteDeviceSubsetCastDefinitionTransactionService',
      {
        operation: 7,
        siteDeviceSubsetId,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));
}
export default SiteDeviceSubsetCastDefinitionTransactionService;
