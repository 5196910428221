import { get } from 'util/WebRequest';

export type Parameters = {
  siteDeviceId: number;
  fields: object;
};

class SiteDeviceAnnotationService {
  static getAnnotations = async (siteDeviceId, dateFrom, dateTo, fields) =>
    get(`/internal/sitedevices/${siteDeviceId}/annotations`, {
      dateFrom,
      dateTo,
      fields,
    }).then((response) => response.data);
}

export default SiteDeviceAnnotationService;
