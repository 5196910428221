import React from 'react';
import {
  DeleteIconButton,
  EditIconButton,
} from 'domain/AppComponents/IconButtons';
import { UserOrganizationRow } from '../UserOrganizationTableLogic';

type UserOrganizationActionProps = {
  row: UserOrganizationRow;
  onEdit?: (...args: any[]) => any;
  onDelete?: (...args: any[]) => any;
};

const UserOrganizationActionFormatter: React.FC<
  UserOrganizationActionProps
> = ({
  row,
  onEdit = undefined,
  onDelete = undefined,
}: UserOrganizationActionProps) => (
  <div>
    {onEdit && <EditIconButton onClick={() => onEdit(row)} />}
    {onDelete && <DeleteIconButton onClick={() => onDelete(row)} />}
  </div>
);

export default UserOrganizationActionFormatter;
