import { memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Grid, Paper, Typography } from 'base-components';
import BatchTaskTableContainer from 'domain/AppComponents/batch/batch-task-table/BatchTaskTableContainer';
import BatchTaskAddContainer from 'domain/AppComponents/batch/dialog/BatchTaskAddContainer';
import { Task } from 'domain/AppComponents/batch/types/Task';

const useStyles = makeStyles(() =>
  createStyles({
    addBatchButton: {
      marginLeft: 'auto',
    },
  })
);

const BatchTaskContainer = ({
  selectedBatch = undefined,
}: {
  selectedBatch?: Task | undefined;
}) => {
  const classes = useStyles();
  return (
    <Paper>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="h6">Batch Tasks</Typography>
            </Grid>
            <Grid className={classes.addBatchButton} item>
              <BatchTaskAddContainer selectedBatchId={selectedBatch?.taskId} />
            </Grid>
          </Grid>
        </Grid>
        <BatchTaskTableContainer selectedBatch={selectedBatch} />
      </Grid>
    </Paper>
  );
};

export default memo(BatchTaskContainer);
