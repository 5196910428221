import { PureComponent } from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { FiberManualRecord } from '@onc/icons';
import { Collapse, Chip } from 'base-components';

import Panel from 'library/CompositeComponents/panel/Panel';
import CookieUtils from 'util/CookieUtils';
import Environment from 'util/Environment';
import ManualEntryThreeDot from './manual-entry-helpers/ManualEntryThreeDot';
import ManualEntry from './ManualEntry';
import QuickButtonSet from '../quick-entry/QuickButtonSet';

const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  liveOutline: {
    border: `1px solid ${theme.palette.success.main}`,
  },
  liveColor: {
    color: theme.palette.success.main,
  },
});

class ManualEntryPanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string,
      liveOutline: PropTypes.string,
      liveColor: PropTypes.string,
    }),
    menu: PropTypes.node,
    name: PropTypes.string,
    onUpdateConfig: PropTypes.func,
    userType: PropTypes.string,
    config: PropTypes.shape({}),
    isLive: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    classes: undefined,
    menu: undefined,
    config: undefined,
    userType: undefined,
    onUpdateConfig: () => {},
    name: undefined,
  };

  handleLinkClick = (destination) => {
    window.open(`${Environment.getDmasUrl()}/${destination}`);
  };

  constructor(props) {
    super(props);
    let config;
    if (props.userType) {
      config = CookieUtils.getCookie(`${props.userType}-${props.name}-config`);
    }
    this.state = {
      autosave: false,
      captureTime: config ? config.captureTime : true,
      attributes: config ? config.attributes : true,
      quickEntry: config ? config.quickEntry : false,
      currentButton: undefined,
    };
  }

  updateConfig = () => {
    const { name, onUpdateConfig } = this.props;
    const { captureTime, attributes, quickEntry } = this.state;
    const config = { captureTime, attributes, quickEntry };
    onUpdateConfig(name, config);
  };

  handleCaptureTimeToggle = () => {
    const { captureTime } = this.state;
    this.setState({ captureTime: !captureTime }, () => this.updateConfig());
  };

  handleAttributesToggle = () => {
    const { attributes } = this.state;
    this.setState({ attributes: !attributes }, () => this.updateConfig());
  };

  handleQuickEntryToggle = () => {
    const { quickEntry } = this.state;
    this.setState({ quickEntry: !quickEntry }, () => this.updateConfig());
  };

  handleQuickButtonClick = (e, autoSave) => {
    if (e.attributes) {
      this.setState({ attributes: true, currentButton: e, autoSave });
    } else {
      this.setState({ currentButton: e, autoSave });
    }
  };

  handleQuickButtonReset = () => {
    this.setState({ currentButton: undefined });
  };

  render() {
    const { attributes, captureTime, quickEntry, currentButton, autoSave } =
      this.state;
    const { classes, menu, isLive, ...rest } = this.props;
    return (
      <div className={classes.root}>
        <Panel
          title={<Typography variant="body1">Annotation Entry</Typography>}
          actionContent={
            <>
              {isLive ? (
                <Chip
                  className={`${classes.liveColor} ${classes.liveOutline}`}
                  variant="outlined"
                  label="Live"
                  icon={<FiberManualRecord className={classes.liveColor} />}
                />
              ) : undefined}
              <ManualEntryThreeDot
                onCaptureTimeToggle={this.handleCaptureTimeToggle}
                onAttributesToggle={this.handleAttributesToggle}
                onQuickEntryToggle={this.handleQuickEntryToggle}
                onLinkClick={this.handleLinkClick}
                checkedTime={captureTime}
                checkedAttributes={attributes}
                checkedQuickEntry={quickEntry}
                menu={menu}
              />
            </>
          }
          headerDraggable
        >
          <Collapse in={quickEntry}>
            <QuickButtonSet
              currentButton={currentButton}
              onButtonClick={this.handleQuickButtonClick}
            />
          </Collapse>
          <ManualEntry
            captureTime={captureTime}
            showAttributes={attributes}
            currentButton={currentButton}
            autoSave={autoSave}
            attributeToggle={this.handleAttributesToggle}
            captureTimeToggle={this.handleCaptureTimeToggle}
            onQuickButtonSave={this.handleQuickButtonReset}
            isLive={isLive}
            {...rest}
          />
        </Panel>
      </div>
    );
  }
}

export default withStyles(styles)(ManualEntryPanel);
