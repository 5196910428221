import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid } from 'base-components';

const DEFAULT_IMAGE_WIDTH = 400;
const DEFAULT_IMAGE_HEIGHT = 300;
const MOBILE_SIZE_REDUCTION_PERCENT = 0.75;

const CastPreviewImage = ({
  onError,
  alt = 'A four plot graph of Temperature, Practical Salinity, Chlorophyll and Oxygen Saturation.',
  title,
  src,
}) => {
  const theme = useTheme();
  let width = DEFAULT_IMAGE_WIDTH;
  let height = DEFAULT_IMAGE_HEIGHT;
  // If the screen is smaller than 600px, use 75% of the default width and height.
  if (useMediaQuery(theme.breakpoints.down('md'))) {
    width = DEFAULT_IMAGE_WIDTH * MOBILE_SIZE_REDUCTION_PERCENT;
    height = DEFAULT_IMAGE_HEIGHT * MOBILE_SIZE_REDUCTION_PERCENT;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <img
          onError={onError}
          width={width}
          height={height}
          alt={alt}
          title={title}
          src={src}
        />
      </Grid>
    </Grid>
  );
};

CastPreviewImage.propTypes = {
  onError: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default CastPreviewImage;
