import React, { useEffect, useRef, useState } from 'react';
import { TextField, IconButton, InputAdornment, Box } from '@mui/material';
import { Search, NavigateBefore, NavigateNext, Close } from '@onc/icons';
import { Tooltip, Typography } from 'base-components';

interface ExpandableSearchBarProps {
  onValueChange: (value: string) => void;
  expandSearchBar?: boolean;
  onExpand?: (condition: boolean) => void;
  searchValue?: string;
  totalMatches?: number; // Made optional
  currentMatchIndex?: number; // Made optional
  onNextMatch?: () => void; // Made optional
  onPreviousMatch?: () => void; // Made optional
  onClearSearch?: () => void; // Made optional
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    maxWidth: '300px',
  },
  input: {
    width: '0px',
    minWidth: '100px',
  },
  expandedInput: {
    width: '100%',
    mt: '4px',
  },
  matchInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
  },
};

const ExpandableSearchBar: React.FC<ExpandableSearchBarProps> = ({
  onValueChange,
  expandSearchBar = false,
  onExpand = undefined,
  searchValue = '',
  totalMatches = undefined,
  currentMatchIndex = undefined,
  onNextMatch = undefined,
  onPreviousMatch = undefined,
  onClearSearch = undefined,
}) => {
  const [searchValueState, setSearchValue] = useState(searchValue);
  const [isExpandedState, setIsExpanded] = useState(expandSearchBar);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleExpand = (condition: boolean) => {
    if (onExpand) {
      onExpand(condition);
    } else {
      setIsExpanded(condition);
    }
  };

  useEffect(() => {
    setSearchValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setIsExpanded(expandSearchBar);
  }, [expandSearchBar]);

  useEffect(() => {
    if (isExpandedState && inputRef.current) {
      const inputElement = inputRef.current.querySelector('input');
      inputElement?.focus();
    }
  }, [isExpandedState]);

  const handleClearSearch = () => {
    setSearchValue('');
    onValueChange('');
    onClearSearch?.();
    if (!searchValue.trim()) {
      handleExpand(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onNextMatch) {
      e.preventDefault();
      onNextMatch();
    }
  };

  const renderMatchControls = () =>
    searchValueState.trim() &&
    totalMatches !== undefined && (
      <Box sx={styles.matchInfo}>
        <Typography variant="body2">
          {totalMatches > 0 && currentMatchIndex !== undefined
            ? `${currentMatchIndex + 1} of ${totalMatches}`
            : 'No matches'}
        </Typography>
        <IconButton
          aria-label="Previous match"
          onClick={onPreviousMatch}
          disabled={totalMatches === 0}
          size="small"
        >
          <NavigateBefore />
        </IconButton>
        <IconButton
          aria-label="Next match"
          onClick={onNextMatch}
          disabled={totalMatches === 0}
          size="small"
        >
          <NavigateNext />
        </IconButton>
      </Box>
    );

  return (
    <Box sx={styles.root}>
      {!isExpandedState ? (
        <Tooltip title="Search">
          <IconButton aria-label="search" onClick={() => handleExpand(true)}>
            <Search />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <TextField
            value={searchValueState}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onValueChange(e.target.value);
            }}
            variant="outlined"
            size="small"
            aria-label="search"
            sx={styles.expandedInput}
            InputProps={{
              ref: inputRef,
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {renderMatchControls()}
                  <IconButton
                    aria-label="clear search"
                    onClick={handleClearSearch}
                    edge="end"
                    size="small"
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
    </Box>
  );
};

export default ExpandableSearchBar;
