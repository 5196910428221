import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';
import ElectricalRatingPanel from './ElectricalRatingPanel';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: '400px',
  },
  panelDiv: {
    margin: theme.spacing(1),
  },
});

class ElectricalRatingPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  render() {
    const { classes, onInfo, onError, deviceId } = this.props;
    return (
      <div className={classes.panelDiv}>
        <ElectricalRatingPanel
          permission={Environment.getDmasUserPrivilege()}
          className={classes.root}
          deviceId={deviceId}
          onInfo={onInfo}
          onError={onError}
        />
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(ElectricalRatingPage));
