import MaterialDivider, {
  DividerProps as MaterialDividerProps,
} from '@mui/material/Divider';

/**
 * Wrapper of Material UI's divider with some added default props. Preferably
 * should use the other Divider which is a lighter wrapper instead.
 */
interface DividerProps extends MaterialDividerProps {
  /** Variation of divider see https://mui.com/api/divider/ */
  variant?: 'fullWidth' | 'inset' | 'middle';
  /**
   * Whether to use a darker & thicker or slimmer and lighter line see
   * https://mui.com/api/divider/
   */
  light?: boolean;
}

const Divider = ({
  variant = 'fullWidth',
  light = false,
  ...rest
}: DividerProps) => (
  <MaterialDivider variant={variant} light={light} {...rest} />
);

export default Divider;
