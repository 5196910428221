import { Component } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Add, Cancel, CheckCircle } from '@onc/icons';
import { LinearProgress, Paper } from 'base-components';
import AnnotationExportDialog from 'domain/AppComponents/annotations/AnnotationExportDialog';
import AnnotationUtil from 'domain/AppComponents/annotations/AnnotationUtil';
import AnnotationService from 'domain/services/AnnotationService';
import {
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';
import { FormFieldLineValue } from './form-fields/FormFieldLine';

const STYLES = () =>
  createStyles({
    paper: {
      display: 'grid',
      width: '100%',
    },
    buttons: {
      float: 'right',
      minWidth: '100px',
      maxHeight: '60px',
    },
  });

const COLUMNS = [
  { name: 'annotationId', title: 'ID' },
  { name: 'campaignName', title: 'Campaign' },
  { name: 'resourceTypeName', title: 'Resource Type' },
  { name: 'resourceName', title: 'Resource Name' },
  { name: 'annotationSummary', title: 'Annotation Summary' },
  { name: 'startDate', title: 'Start Date (UTC)' },
  { name: 'endDate', title: 'End Date (UTC)' },
  { name: 'createdBy', title: 'Owner ID' },
  { name: 'modifiedBy', title: 'Modified By' },
  { name: 'flagged', title: 'Flagged' },
  { name: 'published', title: 'Shared' },
  { name: 'modifiedDate', title: 'Modified Date' },
];
const disabledSort = [
  { columnName: 'annotationSummary', sortingEnabled: false },
  { columnName: 'resourceName', sortingEnabled: false },
];

const tableColumnExtensions = [
  {
    columnName: 'annotationId',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
  },
  {
    columnName: 'campaignName',
    wordWrapEnabled: true,
    width: ColumnInfo.mini,
  },
  {
    columnName: 'resourceTypeName',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
  },
  {
    columnName: 'resourceName',
    wordWrapEnabled: true,
    width: ColumnInfo.large,
  },
  {
    columnName: 'annotationSummary',
    wordWrapEnabled: true,
    width: ColumnInfo.large,
  },
  {
    columnName: 'startDate',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
  },
  {
    columnName: 'endDate',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
  },
  {
    columnName: 'createdBy',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
  },
  {
    columnName: 'modifiedBy',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
  },
  {
    columnName: 'flagged',
    wordWrapEnabled: true,
    width: ColumnInfo.mini,
  },
  {
    columnName: 'published',
    wordWrapEnabled: true,
    width: ColumnInfo.mini,
  },
  {
    columnName: 'modifiedDate',
    wordWrapEnabled: true,
    width: ColumnInfo.small,
  },
];

interface FilterObject {
  columnName: string;
  direction: 'asc' | 'desc';
}

interface AnnotationTableRow {
  annotationId: number | { props: { children: number } };
  published: boolean | any;
  flagged: boolean | any;
  resourceId?: number;
  resourceName?: string;
  resourceTypeId: number;
  resourceTypeName?: string;
  startDate?: string;
  endDate?: string;
  modifiedDate?: string;
}
interface AnnotationTableState {
  rowState?: any[];
  totalNumOfPages: number;
  totalRecords: number;
  pageSize: number;
  pageNum: number;
  sorting?: FilterObject[];
  exportOpen: boolean;
  loading: boolean;
}

interface AnnotationTableProps extends WithStyles<typeof STYLES> {
  paper: string;
  buttons: string;
  entryOpen: boolean;
  onAddAnnotation: () => void;
  onError: (error: string) => void;
  onAnnotationIdClick: (id: number) => void;
  filter: {
    resourceFilter: {
      resourceTypeId?: number;
      resourceId?: number;
    };
    annotationSources: string;
    levelIndexes: string;
    resourceId?: number;
    fieldFilter?: FormFieldLineValue[];
    fromDate?: string;
    toDate?: string;
    userId?: number;
    published?: string;
    flagged?: string;
    includeTopology?: boolean;
    length?: number;
  };
  pageSizes: number[];
  baseUrl: string;
  refreshTable: boolean;
  setRefreshTable: (refreshTable: boolean) => void;
}

class AnnotationTable extends Component<
  AnnotationTableProps,
  AnnotationTableState
> {
  hasUpdated = (currentObject, previousObject) => {
    let hasUpdated = false;
    hasUpdated =
      JSON.stringify(currentObject) !== JSON.stringify(previousObject);
    return hasUpdated;
  };

  sortById = (a: any, b: any) => (a.props.children > b.props.children ? 1 : -1);

  formatDate = (date: string | undefined) => {
    /* prevents blank dates from being returned as current dates
     *  also prevents null from being returned from formatDate
     */
    if (date) {
      const notNullDate = DateFormatUtils.formatDate(date, 'full');
      if (notNullDate === null) {
        return undefined;
      }
      return notNullDate;
    }
    return date;
  };

  getIcon = (bool: any) =>
    bool ? (
      <CheckCircle style={{ color: '#4caf50' }} name="checkIcon" />
    ) : (
      <Cancel style={{ color: '#f44336' }} name="cancelIcon" />
    );

  constructor(props: AnnotationTableProps) {
    super(props);
    this.state = {
      rowState: undefined,
      totalNumOfPages: 0,
      totalRecords: 0,
      pageSize: 30,
      pageNum: 0, // table is 0 indexed, but database isn't
      sorting: [],
      exportOpen: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { filter } = this.props;
    if (filter) {
      this.fetchAnnotations();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { filter, refreshTable, setRefreshTable } = this.props;
    const { sorting, pageSize, pageNum } = this.state;
    // For handling refresh requests from outside AnnotationTable
    if (refreshTable && !prevProps.refreshTable) {
      setRefreshTable(false);
      this.fetchAnnotations();
      return;
    }
    if (
      filter &&
      prevProps &&
      prevState &&
      (this.hasUpdated(sorting, prevState.sorting) ||
        pageSize !== prevState.pageSize ||
        pageNum !== prevState.pageNum)
    ) {
      if (
        this.hasUpdated(
          filter.resourceFilter,
          prevProps.filter.resourceFilter
        ) ||
        (this.hasUpdated(sorting, prevState.sorting) &&
          sorting &&
          sorting.length > 0 &&
          filter.resourceFilter &&
          filter.resourceFilter.resourceTypeId)
      ) {
        // when selecting a new resource type page should be reset to 0
        this.fetchAnnotations(true);
      } else {
        this.fetchAnnotations();
      }
    }
  }

  setSorting = (value: FilterObject[]) => {
    this.setState({ sorting: value });
  };

  enableLoading = () => {
    this.setState({ loading: true });
  };

  disableLoading = () => {
    this.setState({ loading: false });
  };

  fetchAnnotations = async (resetPageNum?: boolean) => {
    const { onError, filter } = this.props;
    const { pageSize, pageNum, sorting } = this.state;
    let currentPageNum = pageNum;
    if (resetPageNum) {
      currentPageNum = 0;
      this.handlePageNumChange(0);
    }
    const updatedFilter = AnnotationUtil.sortFilterValues(filter, sorting);

    updatedFilter.pageSize = pageSize;
    // table is 0 indexed, but database isn't
    updatedFilter.pageNum = currentPageNum + 1;
    if (updatedFilter.resourceTypeId && updatedFilter.annotationSources) {
      this.enableLoading();
      await AnnotationService.getAnnotationsFromFilter(updatedFilter, onError)
        .then((payload) => {
          const formattedData = this.formatRows(payload.naiveAnnotationList);
          this.setState({
            rowState: formattedData,
            totalNumOfPages: payload.totalNumOfPages,
            totalRecords: payload.totalRecords,
            loading: false,
          });
        })
        .catch((responseError) => {
          onError(`Failed to retrieve annotations: ${responseError}`);
          this.disableLoading();
        });
    }
  };

  exportAnnotations = (exportAnnotation?: any) => {
    const { filter } = this.props;
    const { sorting } = this.state;
    const updatedFilter = AnnotationUtil.sortFilterValues(filter, sorting);
    updatedFilter.fileName = exportAnnotation.fileName;
    updatedFilter.responseType = exportAnnotation.responseType;
    const urlParams = new URLSearchParams(updatedFilter).toString();
    // this works but isn't a good solution
    window.location.href = `${Environment.getDmasUrl()}/AnnotationServiceV3?${urlParams}`;
    return updatedFilter;
  };

  formatRows = (rows: AnnotationTableRow[]) => {
    const { pageSize } = this.state;
    let formattedRows = [...rows];
    formattedRows = formattedRows.map((row) => {
      const formattedRow = { ...row };
      formattedRow.annotationId = this.formatAnnotationLink(row);
      formattedRow.startDate = this.formatDate(row.startDate);
      formattedRow.endDate = this.formatDate(row.endDate);
      formattedRow.modifiedDate = this.formatDate(row.modifiedDate);
      formattedRow.flagged = this.getIcon(row.flagged);
      formattedRow.published = this.getIcon(row.published);
      return formattedRow;
    });
    // if service returns more than pageSize rows, trim it
    if (formattedRows.length > pageSize) {
      formattedRows = formattedRows.slice(0, pageSize);
    }
    return formattedRows;
  };

  formatAnnotationLink = (row: any) => {
    const { onAnnotationIdClick, baseUrl } = this.props;
    return (
      <a
        href={`${baseUrl}/id/${row.annotationId}`}
        onClick={(e) => {
          e.preventDefault();
          onAnnotationIdClick(row.annotationId);
        }}
      >
        {row.annotationId}
      </a>
    );
  };

  handlePageSizeChange = (newPageSize: number) => {
    const { pageNum, pageSize } = this.state;
    let newPageNum = 1;
    const firstAnnotationIndex = pageSize * pageNum + 1;
    newPageNum = Math.trunc(firstAnnotationIndex / newPageSize);
    this.setState({ pageSize: newPageSize, pageNum: newPageNum });
  };

  handlePageNumChange = (pageNum: number) => {
    this.setState({ pageNum });
  };

  handleCloseExport = () => {
    this.setState({ exportOpen: false });
  };

  handleOpenExport = () => {
    this.setState({ exportOpen: true });
  };

  handleExportAnnotation = (fileName: string, responseType: number) => {
    this.exportAnnotations({ fileName, responseType });
    this.handleCloseExport();
  };

  renderExportDialog = () => {
    const { exportOpen } = this.state;
    if (exportOpen) {
      return (
        <AnnotationExportDialog
          onClose={this.handleCloseExport}
          onSubmit={this.handleExportAnnotation}
        />
      );
    }
    return undefined;
  };

  renderLoadingBar = () => {
    const { loading } = this.state;
    if (loading) {
      return <LinearProgress />;
    }
    return null;
  };

  render() {
    const { classes, pageSizes, onAddAnnotation, entryOpen } = this.props;

    // eslint-disable-next-line react/no-unstable-nested-components
    const ButtonAddAnnotation = (onClick: () => void) => {
      const userLoggedIn =
        Environment.getDmasUserId() !== undefined &&
        Environment.getDmasUserId() !== '' &&
        Environment.getDmasUserId() !== 0;

      if (userLoggedIn) {
        return (
          <ContainedButton
            translationKey="annotations.addAnnotation"
            startIcon={<Add />}
            onClick={onClick}
            disabled={entryOpen}
            className={classes.buttons}
          />
        );
      }
      return undefined;
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const ButtonExportAnnotation = (onClick: () => void) => (
      <div className={classes.buttons}>
        <TextButton
          translationKey="annotations.exportAnnotationsEllipsis"
          onClick={onClick}
        />
      </div>
    );
    const {
      rowState,
      totalNumOfPages,
      totalRecords,
      pageNum,
      pageSize,
      sorting,
    } = this.state;

    return (
      <Paper className={classes.paper}>
        <div>
          {this.renderExportDialog()}
          {ButtonAddAnnotation(onAddAnnotation)}
          {ButtonExportAnnotation(this.handleOpenExport)}
        </div>
        {this.renderLoadingBar()}
        <div />
        <SortableTable
          setPageSize={this.handlePageSizeChange}
          columns={COLUMNS}
          rows={rowState || []}
          pageSizes={pageSizes}
          pageSize={pageSize}
          totalNumOfPages={totalNumOfPages}
          totalRecords={totalRecords}
          currentPage={pageNum}
          onCurrentPageChange={this.handlePageNumChange}
          remotePaging
          sorting={sorting}
          setSorting={this.setSorting}
          columnExtensions={tableColumnExtensions}
          sortExtensions={[
            { columnName: 'annotationId', compare: this.sortById },
          ]}
          columnSizes={tableColumnExtensions}
          paginationOnTop
          disabledSort={disabledSort}
        />
      </Paper>
    );
  }
}

export default withStyles(STYLES)(withSnackbars(AnnotationTable));
