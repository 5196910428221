import useFetch from 'util/hooks/useFetch';
import { get } from 'util/WebRequest';

const SERVICE = 'apiproxy/deployments';

type DeploymentsParams = {
  locationCode?: string;
  deviceCategoryCode?: string;
  deviceCode?: string;
  propertyCode?: string;
  dateFrom?: string;
  dateTo?: string;
};

export type Deployment = {
  begin: string;
  end: string;
  depth: number;
  deviceCode: string;
  deviceCategoryCode: string;
  hasDeviceData: boolean;
  heading: number | null;
  lat: number;
  lon: number;
  locationCode: string;
  pitch: number | null;
  roll: number | null;
};

export const useDeployments = (params: DeploymentsParams) =>
  useFetch<Deployment[], DeploymentsParams>(
    SERVICE,
    { queryKey: ['deployments'] },
    { params },
    (response) => response.data as Deployment[]
  );

class DeploymentsWebService {
  static get(params) {
    return get(SERVICE, params);
  }
}
export default DeploymentsWebService;
