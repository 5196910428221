import { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import * as yup from 'yup';
import { DateTimePicker } from 'base-components';

const schema = yup.string();

class CaptureTimeInput extends Component {
  static propTypes = {
    // Validation is used by higher up components
    // eslint-disable-next-line react/no-unused-prop-types
    validation: PropTypes.shape({}),
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    className: PropTypes.string,
    onEditTime: PropTypes.func.isRequired,
  };

  static defaultProps = {
    validation: schema,
    className: undefined,
    value: null,
  };

  handleChange = (event) => {
    const { handleChange } = this.props;
    handleChange(event);
  };

  handleOnFocus = () => {
    const { onEditTime } = this.props;
    onEditTime(true);
  };

  handleOnBlur = () => {
    const { onEditTime } = this.props;
    onEditTime(false);
  };

  render() {
    const { value, className } = this.props;
    return (
      <div className={className}>
        <DateTimePicker
          translationKey="common.datepickers.date"
          value={moment.utc(value)}
          onChange={this.handleChange}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
        />
      </div>
    );
  }
}

export default CaptureTimeInput;
