import { Component, ComponentType, ReactElement, ReactNode } from 'react';
import {
  SortingState,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
  PagingState,
  CustomPaging,
  IntegratedPaging,
  SearchState,
  GroupingState,
  IntegratedGrouping,
  SelectionState,
  IntegratedSelection,
  RowDetailState,
  TreeDataState,
  CustomTreeData,
  TableGroupRowProps as DxTableGroupRowProps,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable as DXVirtualTable,
  Table as DXTable,
  TableHeaderRow,
  TableFilterRow,
  TableColumnVisibility,
  TableColumnResizing,
  PagingPanel,
  SearchPanel,
  Toolbar,
  TableGroupRow,
  GroupingPanel,
  TableSelection,
  TableRowDetail,
  TableTreeColumn,
  TableBandHeader,
  DragDropProvider,
} from '@devexpress/dx-react-grid-material-ui';
import { Theme } from '@mui/material/styles';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { LinearProgress, Paper } from 'base-components';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
    },
    '@global': {
      table: {
        tableLayout: 'auto !important',
      },
    },
    tableRow: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Color taken from Material UI Table examples
        cursor: 'pointer',
      },
    },
    tableRowSelected: {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'pointer',
    },

    // this implementation of css will prevent the styling from propagating to all children
    stripedTable: {
      '& > div > div > div > table > tbody > tr:nth-of-type(odd)': {
        backgroundColor: theme.palette.grey[200],
      },
      '& > div > div > div > table > tbody > tr > td > div': {
        backgroundColor: 'inherit',
      },
      '& > div > div > div > table > tbody > tr > td': {
        padding: theme.spacing(1),
      },
      '& > div > div > div > table > thead > tr > th > div ': {
        flexDirection: 'row-reverse',
      },
      '& > div > div > div > table > thead > tr > th > div > div > svg ': {
        marginRight: '10px',
      },
    },
    movedSearchBar: {
      '& > div > div > div ': {
        right: theme.spacing(36),
      },
    },
    paginationOnTop: {
      '& > div > div:nth-child(2)': {
        position: 'absolute',
        zIndex: '+10',
        right: '10px',
      },
      '& table': {
        marginTop: theme.spacing(8),
      },
    },
  });

const HeaderCell = ({ ...props }) => (
  // @ts-expect-error Contained in props
  <TableHeaderRow.Cell {...props} style={{ fontWeight: 'bold' }} />
);

// @ts-expect-error Contained in props
const DefaultCell = ({ ...props }) => <DXTable.Cell {...props} />;

export interface TableProps extends WithStyles<typeof styles> {
  columns: {
    name: string;
    title: string;
  }[];
  rows: any[];
  sortable?: boolean;
  filterable?: boolean;
  filterCell?: ComponentType<any>;
  groupingPanelMessages?: Record<string, unknown>;
  sort?: {
    columnName: string;
    direction: 'asc' | 'desc';
    compare: () => void;
  }[];
  sortExtensions?: any;
  stripedRows?: boolean;
  paginationOnTop?: boolean;
  columnBands?: {
    title: string;
    children: {
      columnName: string;
    }[];
  }[];
  columnExtensions?: { columnName: string; [key: string]: unknown }[];
  filterExtensions?: any;
  IntegratedFilteringExtensions?: any;
  GroupingStateProps?: any;
  TableGroupRowProps?: DxTableGroupRowProps;
  pageSize?: number;
  searchable?: boolean;
  searchBarMoveable?: boolean;
  groupable?: boolean;
  selectable?: boolean;
  hiddenColumns?: string[];
  columnSizes?: { columnName: string; width: number }[];
  hasDetail?: boolean;
  RowDetail?: ComponentType<any>;
  rowOnClick?: (row: any) => void;
  elevation?: number;
  getRowId?: (row: any) => string | number;
  onSelectionChange?: () => void;
  getChildRows?: (currentRow: any, rootRows: any[]) => any[];
  selection?: (number | string)[];
  treeColumn?: string;
  defaultExpandedRowIds?: number[];
  height?: number | string;
  showHeaderRow?: boolean;
  highlighted?: boolean;
  disabledSort?: any;
  sorting?: { columnName: string; direction: 'asc' | 'desc' }[];
  setSorting?: (value: any) => void;
  cellComponent?: ComponentType<any>;
  selectAll?: boolean;
  toggleCellComponent?: ComponentType<any>;
  noDataMessage?: string;
  loadingMessage?: string;
  children?: ReactElement;
  pageSizes?: number[];
  setPageSize?: (pageSize: number) => void;
  totalRecords?: number;
  currentPage?: number;
  onCurrentPageChange?: (pageNum: number) => void;
  remotePaging?: boolean;
  customHeaderCellProvider?: (column: any) => ReactNode;
  isLoading?: boolean;
  isRowCountLoading?: boolean;
  // I don't know if these are a thing. They're used in AnnotationTable and I'm not confident enough to delete them.
  wordWrapEnabled?: boolean;
  totalNumOfPages?: number;
}

// @Deprecated use the other Table
class Table extends Component<TableProps> {
  static defaultProps = {
    searchBarMoveable: false,
    sortable: false,
    filterable: false,
    filterCell: TableFilterRow.Cell,
    sort: [],
    sortExtensions: [],
    pageSize: 0,
    searchable: false,
    groupable: false,
    selectable: false,
    selectAll: true,
    hiddenColumns: [],
    columnBands: undefined,
    columnSizes: [],
    hasDetail: false,
    RowDetail: undefined,
    rowOnClick: () => {},
    onSelectionChange: () => {},
    getChildRows: undefined,
    getRowId: undefined,
    selection: [],
    columnExtensions: [],
    filterExtensions: [],
    IntegratedFilteringExtensions: [],
    GroupingStateProps: undefined,
    TableGroupRowProps: undefined,
    defaultExpandedRowIds: [],
    elevation: 1,
    treeColumn: undefined,
    height: undefined,
    showHeaderRow: true,
    groupingPanelMessages: undefined,
    stripedRows: false,
    paginationOnTop: false,
    highlighted: false,
    disabledSort: [],
    sorting: undefined,
    setSorting: undefined,
    cellComponent: DefaultCell,
    toggleCellComponent: undefined,
    noDataMessage: 'No Records Found',
    loadingMessage: 'Loading...',
    children: undefined,
    pageSizes: undefined,
    setPageSize: () => {},
    totalRecords: 0,
    currentPage: undefined,
    onCurrentPageChange: () => {},
    remotePaging: false,
    customHeaderCellProvider: undefined,
    isLoading: false,
    isRowCountLoading: false,
    wordWrapEnabled: undefined,
    totalNumOfPages: undefined,
  };

  CustomHeaderCell = ({ column, ...props }) => {
    const { customHeaderCellProvider } = this.props;
    return (
      // @ts-expect-error Contained in props
      <TableHeaderRow.Cell
        {...props}
        column={column}
        style={{ fontWeight: 'bold' }}
      >
        {customHeaderCellProvider(column)}
      </TableHeaderRow.Cell>
    );
  };

  selectionState() {
    const { selection, onSelectionChange, selectable, highlighted } =
      this.props;
    if (selectable || highlighted) {
      return (
        <SelectionState
          selection={selection}
          onSelectionChange={onSelectionChange}
        />
      );
    }
    return <SelectionState />;
  }

  checkHasCustomPaging() {
    const { remotePaging, totalRecords } = this.props;
    if (remotePaging) {
      return <CustomPaging totalCount={totalRecords} />;
    }
    return <IntegratedPaging />;
  }

  renderFilterBar() {
    const { filterable, filterCell } = this.props;
    if (filterable) {
      return <TableFilterRow cellComponent={filterCell} />;
    }
    return null;
  }

  renderPagingPanel() {
    const { isRowCountLoading, pageSize, pageSizes } = this.props;
    if (!pageSize) return null;

    if (isRowCountLoading) {
      return (
        <PagingPanel
          // @ts-expect-error Contained in props
          pageSize={pageSize}
          pageSizes={pageSizes}
          messages={{ info: () => 'Loading...' }}
        />
      );
    }

    // @ts-expect-error Contained in props
    return <PagingPanel pageSize={pageSize} pageSizes={pageSizes} />;
  }

  renderToolbar() {
    const { searchable, groupable } = this.props;
    if (searchable || groupable) {
      return <Toolbar />;
    }
    return null;
  }

  renderSearchPanel() {
    const { searchable } = this.props;
    if (searchable) {
      return <SearchPanel />;
    }
    return null;
  }

  renderGroupingPanel() {
    const { groupable, groupingPanelMessages } = this.props;
    if (groupable) {
      return (
        <GroupingPanel showGroupingControls messages={groupingPanelMessages} />
      );
    }
    return null;
  }

  renderSelectionRowComponent = ({
    tableRow,
    selectByRowClick, // unused but this removes it from the restProps
    highlighted = false,
    ...restProps
  }: {
    tableRow: any;
    selectByRowClick?: any;
    highlighted?: boolean;
    [x: string]: any;
  }) => {
    const { rowOnClick, classes } = this.props;
    let className = classes.tableRow;
    if (highlighted) {
      className = classes.tableRowSelected;
    }
    return (
      // @ts-expect-error Contained in props
      <DXVirtualTable.Row
        className={className}
        {...restProps}
        onClick={() => rowOnClick(tableRow)}
      />
    );
  };

  renderSelectionColumn() {
    const { selectable, selectAll, highlighted } = this.props;
    if (selectable) {
      return <TableSelection highlightRow showSelectAll={selectAll} />;
    }
    if (highlighted) {
      return (
        <TableSelection
          highlightRow
          showSelectionColumn={false}
          showSelectAll={false}
          rowComponent={this.renderSelectionRowComponent}
        />
      );
    }
    return null;
  }

  renderHiddenColumns() {
    const { hiddenColumns } = this.props;
    if (hiddenColumns.length > 0) {
      return <TableColumnVisibility hiddenColumnNames={hiddenColumns} />;
    }
    return null;
  }

  renderColumnResizing() {
    const { columnSizes } = this.props;
    if (columnSizes.length > 0) {
      return (
        <TableColumnResizing
          defaultColumnWidths={
            columnSizes as { columnName: string; width: number }[]
          }
        />
      );
    }
    return null;
  }

  renderRowDetail() {
    const { hasDetail, RowDetail, toggleCellComponent } = this.props;

    if (hasDetail && RowDetail) {
      return (
        <TableRowDetail
          toggleCellComponent={toggleCellComponent}
          contentComponent={RowDetail as ComponentType<any>}
        />
      );
    }
    return null;
  }

  renderCustomTreeData() {
    const { getChildRows } = this.props;
    if (getChildRows) {
      return <CustomTreeData getChildRows={getChildRows} />;
    }
    return null;
  }

  renderTreeColumn() {
    const { treeColumn } = this.props;
    if (treeColumn) {
      return <TableTreeColumn for={treeColumn} />;
    }
    return null;
  }

  renderColumnBands() {
    const { columnBands } = this.props;
    if (!columnBands) return undefined;
    return <TableBandHeader columnBands={columnBands} />;
  }

  renderRowComponent = ({ row, ...restProps }) => {
    const { rowOnClick } = this.props;
    return (
      // @ts-expect-error Missing prop is supplied
      <DXVirtualTable.Row {...restProps} onClick={() => rowOnClick(row)} />
    );
  };

  renderTableType() {
    const {
      height,
      columnExtensions,
      cellComponent,
      noDataMessage,
      loadingMessage,
      isLoading,
    } = this.props;
    const message = isLoading
      ? { noData: loadingMessage }
      : { noData: noDataMessage };
    if (height) {
      return (
        <DXVirtualTable
          messages={message}
          height={height}
          columnExtensions={
            columnExtensions as { columnName: string; [key: string]: unknown }[]
          }
          cellComponent={cellComponent}
          rowComponent={this.renderRowComponent}
        />
      );
    }
    return (
      <DXTable
        messages={message}
        columnExtensions={columnExtensions}
        cellComponent={cellComponent}
        rowComponent={this.renderRowComponent}
      />
    );
  }

  renderTableHeader() {
    const { showHeaderRow, sortable, groupable, customHeaderCellProvider } =
      this.props;
    let headerCell: ComponentType<{ [x: string]: any; column?: any }> =
      HeaderCell;
    // if exists use passed in headerCellProvider
    if (customHeaderCellProvider) {
      headerCell = this.CustomHeaderCell as ComponentType<{
        [x: string]: any;
        column?: any;
      }>;
    }
    if (showHeaderRow) {
      return (
        <TableHeaderRow
          showSortingControls={sortable}
          showGroupingControls={groupable}
          // @ts-expect-error Can't figure how to make this compatible
          cellComponent={headerCell}
        />
      );
    }

    return null;
  }

  renderPagingState() {
    const { pageSize, setPageSize, currentPage, onCurrentPageChange } =
      this.props;
    return (
      <PagingState
        defaultCurrentPage={0}
        currentPage={currentPage}
        onCurrentPageChange={onCurrentPageChange}
        defaultPageSize={pageSize}
        onPageSizeChange={setPageSize}
      />
    );
  }

  renderSortingState() {
    const { sort, disabledSort, setSorting, sorting } = this.props;
    if (setSorting && sorting) {
      return (
        <SortingState
          defaultSorting={sort}
          columnExtensions={disabledSort}
          sorting={sorting}
          onSortingChange={setSorting}
        />
      );
    }
    return (
      <SortingState defaultSorting={sort} columnExtensions={disabledSort} />
    );
  }

  renderIsLoading() {
    const { isLoading, searchable } = this.props;
    if (isLoading) {
      if (searchable) {
        return (
          <div style={{ position: 'relative', top: '65px' }}>
            <LinearProgress />
          </div>
        );
      }
      return (
        <div>
          <LinearProgress />
        </div>
      );
    }
    return <></>;
  }

  render() {
    const {
      classes,
      children,
      rows,
      columns,
      filterExtensions,
      IntegratedFilteringExtensions,
      GroupingStateProps,
      TableGroupRowProps,
      sortExtensions,
      elevation,
      getRowId,
      stripedRows,
      paginationOnTop,
      defaultExpandedRowIds,
      searchBarMoveable,
    } = this.props;
    let rootClass = stripedRows
      ? `${classes.root} ${classes.stripedTable}`
      : classes.root;
    rootClass = searchBarMoveable
      ? `${classes.root} ${classes.stripedTable} ${classes.movedSearchBar}`
      : rootClass;
    rootClass += paginationOnTop ? ` ${classes.paginationOnTop}` : '';
    return (
      <Paper className={rootClass} elevation={elevation}>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          {this.renderSortingState()}
          <IntegratedSorting columnExtensions={sortExtensions} />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={filterExtensions}
          />
          <SearchState />
          <IntegratedFiltering
            columnExtensions={IntegratedFilteringExtensions}
          />
          <DragDropProvider />
          <GroupingState {...GroupingStateProps} />
          <TreeDataState defaultExpandedRowIds={defaultExpandedRowIds} />
          {this.renderCustomTreeData()}
          <IntegratedGrouping />
          {this.selectionState()}
          <RowDetailState />
          <IntegratedSelection />
          {this.renderPagingState()}
          {this.checkHasCustomPaging()}
          {this.renderTableType()}
          {this.renderSelectionColumn()}
          {this.renderColumnResizing()}
          {this.renderTableHeader()}
          {/* children is meant for DataTypeProviders, which must be placed before TableGroupRow */}
          {this.renderIsLoading()}
          {children}
          <TableGroupRow {...TableGroupRowProps} />
          {this.renderHiddenColumns()}
          {this.renderToolbar()}
          {this.renderFilterBar()}
          {this.renderPagingPanel()}
          {this.renderSearchPanel()}
          {this.renderGroupingPanel()}
          {this.renderRowDetail()}
          {this.renderTreeColumn()}
          {this.renderColumnBands()}
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Table);
