import { FormAutocompleteProps } from 'library/CompositeComponents/form/FormAutocomplete';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { useSnackbars } from 'util/hooks/useSnackbars';

type IpTypeDropdownProps = Omit<
  FormAutocompleteProps,
  'options' | 'translationKey' | 'translationOptions'
> & { label: string };

type IpTypePayload = [{ ipTypeId: number; ipTypeName: string }];

const IpTypeDropdown = ({ ...props }: IpTypeDropdownProps) => {
  const { onError } = useSnackbars();

  const ipTypeData = useGet<IpTypePayload>('IpTypeService', {
    operation: 5,
    options: {
      retry: 1,
    },
  });

  if (ipTypeData.isLoading) {
    return <FormDropdown {...props} options={[]} />;
  }

  if (ipTypeData.error) {
    onError(ipTypeData.error.message);
    return <></>;
  }

  const options = ipTypeData?.data?.map((option) => ({
    label: option.ipTypeName,
    value: option.ipTypeId,
    key: option.ipTypeId.toString(),
  }));

  return <FormDropdown options={options} {...props} />;
};

const IpTypeDropdownWrapper = ({ ...props }: IpTypeDropdownProps) => (
  <IpTypeDropdown {...props} />
);

export default IpTypeDropdownWrapper;
