import PropTypes from 'prop-types';
import { TableOld as Table } from 'base-components';

/** @constant */
const HEADERS = [
  {
    title: 'Sensor',
    name: 'sensorName',
    align: 'left',
  },
  {
    title: 'Reading',
    name: 'value',
    align: 'right',
  },
  {
    title: 'Updated (UTC)',
    name: 'sampleDate',
    align: 'left',
  },
];

/**
 * Opinionated Table display for Sensor Readings.
 *
 * Format:
 *
 * | Sensor     | Reading | Updated    |
 * | ---------- | :-----: | ---------- |
 * | sensorName |  value  | sampleDate |
 */
function SensorReadingDisplay(props) {
  return (
    <Table
      showBorders={false}
      columns={HEADERS}
      elevation={0}
      showHeaderRow={false}
      {...props}
    />
  );
}

SensorReadingDisplay.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      sensorName: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      sampleDate: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SensorReadingDisplay;
