import Environment from 'util/Environment';

export const BASE_LAYERS = [
  {
    name: 'Bing',
    type: 'bing',
    key: 'AvOjI0xjDMTr3ou5B6XaERIx0F4uYf4TXaInV0Mp3PrG6NI9Z0R1AQCu5yiGvQPn',
  },
  {
    name: 'Google',
    type: 'google',
    mapType: 'satellite',
    key: 'AIzaSyBqtaDtp5We-4Vdc2EjaU4iDUKzSn8_XII',
  },
  {
    name: 'GMRT',
    type: 'wms',
    url: 'https://www.gmrt.org/services/mapserver/wms_merc?',
    layers: 'GMRT',
    attribution:
      '<a href="http://www.marine-geo.org/portals/gmrt/about.php">Marine Geoscience Data System</a>',
  },
  {
    name: 'Open Street',
    type: 'openstreet',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
];

const attributionLinks = {
  Bing: `<a target="_blank" rel="noreferrer" href="${Environment.getDmasUrl()}/communityfishers"> Troubles loading? Try the light map </a> |`,
  Google: ` <a target="_blank" rel="noreferrer" href="${Environment.getDmasUrl()}/communityfishers"> Troubles loading? Try the light map`,
  GMRT: `<a href="http://www.marine-geo.org/portals/gmrt/about.php">Marine Geoscience Data System</a> | <a target="_blank" rel="noreferrer" href="${Environment.getDmasUrl()}/communityfishers"> Troubles loading? Try the light map</a>`,
  'Open Street': `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | <a target="_blank" rel="noreferrer" href="${Environment.getDmasUrl()}/communityfishers"> Troubles loading? Try the light map</a>`,
};

const generateLayersWithLink = (layers) =>
  layers.map((layer) => ({
    ...layer,
    attribution: attributionLinks[layer.name],
  }));

export const BASE_LAYERS_WITH_LINK = generateLayersWithLink(BASE_LAYERS);
