import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from 'base-components';

class InfoItem extends PureComponent {
  static propTypes = {
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]).isRequired,
  };

  render() {
    const { primary, secondary } = this.props;
    return (
      <ListItem title={primary}>
        <ListItemText
          style={{ userSelect: 'text' }}
          primary={primary}
          secondary={secondary}
        />
      </ListItem>
    );
  }
}

export default InfoItem;
