import { useState } from 'react';
import * as React from 'react';

import { createStyles, makeStyles } from '@mui/styles';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from 'base-components';
import { AgreementSiteDeviceDate } from 'domain/AppComponents/agreement-site-devices/AgreementSiteDeviceComponents';
import AgreementSiteDeviceService, {
  AvailableAgreementSiteDevice,
} from 'domain/services/AgreementSiteDeviceService';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import Environment from 'util/Environment';
import {
  SiteDeviceSearchParameterSelect,
  type SearchParams,
} from './SiteDeviceSearchParameterSelect';
import {
  SiteDeviceSearchTable,
  type OrganizationAgreementSiteDeviceRow,
} from './SiteDeviceSearchTable';

const useStyles = makeStyles(() =>
  createStyles({
    accordionRoot: {
      width: '100%',
    },
    detailRoot: {
      display: 'block',
    },
    italicText: {
      fontStyle: 'italic',
    },
    displayDate: {
      fontSize: '0.875rem',
    },
  })
);

type SiteDeviceSearchDialogProps = {
  organizationAgreementName: string;
  organizationAgreementId: number;
  open: boolean;
  onClose: () => void;
  onInfo: (message: string) => void;
  onError: (error: string) => void;
};

const SiteDeviceSearchDialog: React.VFC<SiteDeviceSearchDialogProps> = (
  props: SiteDeviceSearchDialogProps
): React.ReactElement => {
  const {
    organizationAgreementName,
    organizationAgreementId,
    open,
    onClose,
    onInfo,
    onError,
  } = props;
  const classes = useStyles();

  const initSearchParams = {
    siteDeviceId: undefined,
    site: null,
    deviceCategory: null,
    device: null,
    startDate: undefined,
    endDate: undefined,
  };

  const [searchParams, setSearchParams] = useState(initSearchParams);
  const [searchResultRows, setSearchResultRows] = useState([]);
  const [selectedSiteDevices, setSelectedSiteDevices] = useState<string[]>([]);
  const [parametersExpanded, setParametersExpanded] = useState(true);
  const [resultsExpanded, setResultsExpanded] = useState(false);

  const handleChange = (
    newParams: Record<string, SearchParams[keyof SearchParams]>
  ) => {
    setSearchParams({ ...searchParams, ...newParams });
  };

  const buildTableRows = (
    results: AvailableAgreementSiteDevice[]
  ): OrganizationAgreementSiteDeviceRow[] =>
    results.map((availableOAR) => {
      const {
        minStartDate,
        maxEndDate,
        siteDevice: { siteDeviceId, device, site, dateFrom, dateTo },
      } = availableOAR;
      const isMinStartDateDerived = availableOAR.minStartDate === dateFrom;
      const isMaxEndDateDerived = availableOAR.maxEndDate === dateTo;
      return {
        id: `${siteDeviceId}-${minStartDate}-${maxEndDate}`,
        siteDeviceId,
        site: (
          <a href={`${Environment.getDmasUrl()}/Sites?siteId=${site.siteId}`}>
            {site.siteName}
          </a>
        ),
        device: `${device.deviceName} (${device.deviceId})`,
        dateFrom: isMinStartDateDerived ? undefined : minStartDate,
        dateTo: isMaxEndDateDerived ? undefined : maxEndDate,
        formattedDateFrom: (
          <AgreementSiteDeviceDate
            date={minStartDate}
            siteDeviceDate={dateFrom}
            variant="table"
          />
        ),
        formattedDateTo: (
          <AgreementSiteDeviceDate
            date={maxEndDate}
            siteDeviceDate={dateTo}
            variant="table"
          />
        ),
      };
    });

  const handleSearch = (completionMessage) => {
    const { siteDeviceId, site, deviceCategory, device, startDate, endDate } =
      searchParams;
    if (!searchParams.startDate || !searchParams.endDate) {
      onError(
        'A date was unspecified, search may take longer to generate results'
      );
    }
    AgreementSiteDeviceService.searchForAvailableAgreementSiteDevices({
      siteDeviceId,
      siteId: site?.siteId,
      deviceCategoryId: deviceCategory?.deviceCategoryId,
      deviceId: device?.deviceId,
      startDate,
      endDate,
    })
      .then((results) => {
        setSearchResultRows(buildTableRows(results));
        onInfo(completionMessage);
        setParametersExpanded(false);
        setResultsExpanded(true);
      })
      .catch((e: Error) => {
        onError(`Error completing search: ${e.message}`);
      });
  };

  const handleSave = async () => {
    const successfulSiteDeviceSaves = [];
    const failedSiteDeviceSaves = [];
    await Promise.allSettled(
      searchResultRows
        .filter((r) => selectedSiteDevices.includes(r.id))
        .map(({ siteDeviceId, dateFrom, dateTo }) =>
          AgreementSiteDeviceService.create({
            organizationAgreementId,
            siteDeviceId,
            dateFrom,
            dateTo,
          })
            .then(() => {
              successfulSiteDeviceSaves.push(siteDeviceId);
            })
            .catch(() => {
              failedSiteDeviceSaves.push(siteDeviceId);
            })
        )
    )
      .then(() => {
        if (successfulSiteDeviceSaves.length > 0) {
          onInfo(
            `Saved Organization Agreement Site Device IDs: ${successfulSiteDeviceSaves.join(
              ','
            )}`
          );
        }
        if (failedSiteDeviceSaves.length > 0) {
          onError(
            `Failed to Organization Agreement Site Device IDs: ${failedSiteDeviceSaves.join(
              ','
            )}`
          );
        }
      })
      .then(() => {
        setSelectedSiteDevices([]);
        handleSearch('Search results refreshed');
      });
  };

  return (
    <Dialog open={open} maxWidth={false} onClose={onClose}>
      <DialogTitle>
        {`Add Site Devices for Organization Agreement: ${organizationAgreementName}`}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Accordion
            expanded={parametersExpanded}
            onChange={() => setParametersExpanded(!parametersExpanded)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary>
              <Typography>Filter</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.detailRoot }}>
              <Grid item xs={12} lg={6}>
                <SiteDeviceSearchParameterSelect
                  searchParams={searchParams}
                  onSearch={() => {
                    handleSearch('Search complete');
                  }}
                  onChange={handleChange}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={resultsExpanded}
            onChange={() => setResultsExpanded(!resultsExpanded)}
            classes={{ root: classes.accordionRoot }}
          >
            <AccordionSummary>
              <Typography>Site Devices</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.detailRoot }}>
              <Grid item xs={12}>
                <SiteDeviceSearchTable
                  searchResultRows={searchResultRows}
                  selectedSiteDevices={selectedSiteDevices}
                  onSelectionChange={setSelectedSiteDevices}
                  onSave={handleSave}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </DialogContent>
      <DialogActions>
        <TextButton
          translationKey="common.buttons.close"
          onClick={() => {
            setSearchResultRows([]);
            setSelectedSiteDevices([]);
            setSearchParams(initSearchParams);
            onClose();
          }}
          aria-label="close dialog"
        />
      </DialogActions>
    </Dialog>
  );
};

export default SiteDeviceSearchDialog;
