const Status = {
  CANCELLING: 'CANCELLING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  SKIPPED: 'SKIPPED',
};

export default Status;
