import { SlowMotionVideo } from '@onc/icons';
import { NestedMenuItem } from 'base-components';

type PlaybackRateMenuItemProps = {
  options: number[];
  playbackRate: number;
  containerRef?: React.RefObject<HTMLDivElement>;
  onClick: (val: number) => void;
};

const PlaybackRateMenuItem: React.FC<PlaybackRateMenuItemProps> = ({
  options,
  playbackRate,
  containerRef = undefined,
  onClick,
}: PlaybackRateMenuItemProps) => (
  <NestedMenuItem
    value="playback-speed"
    label="Playback Speed"
    name="playback-speed"
    container={containerRef}
    IconComponent={<SlowMotionVideo />}
    onClick={(e) => {
      onClick(e.target.value);
    }}
    childrenItems={options.map((speed) => ({
      value: speed,
      label: `${speed}x`,
      key: `${speed}`,
      selected: speed === playbackRate,
    }))}
  />
);

// Used by AnchoredHiddenMenu to override the onClick
PlaybackRateMenuItem.displayName = 'MenuItem';

export default PlaybackRateMenuItem;
