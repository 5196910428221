import * as React from 'react';

import DeleteDialog from 'domain/AppComponents/dialogs/DeleteDialog';
import PlaylistService from 'domain/services/PlaylistService';
import { useSnackbars } from 'util/hooks/useSnackbars';

interface DeletePlaylistDialogProps {
  playlistHdrId: number;
  playlistHdrName: string;
  open: boolean;
  closeDeleteForm: () => void;
  actionAfterDelete: () => void;
}

const DeletePlaylistDialog: React.FC<DeletePlaylistDialogProps> = ({
  playlistHdrId,
  playlistHdrName,
  open,
  closeDeleteForm,
  actionAfterDelete,
}) => {
  const { onError, onInfo } = useSnackbars();

  /**
   * Deletes a PlaylistHdr by making a delete call to PlaylistService
   *
   * @returns {Promise}
   */
  const deletePlaylist = () =>
    PlaylistService.delete(playlistHdrId)
      .then(() => {
        closeDeleteForm();
        actionAfterDelete();
        onInfo(`Deleted playlist ${playlistHdrName}.`);
      })
      .catch((error) => {
        onError(error.message);
      });

  return (
    <DeleteDialog
      open={open}
      title={`Delete "${playlistHdrName}"?`}
      onCancel={closeDeleteForm}
      onDelete={deletePlaylist}
      message="Are you sure you want to delete this playlist?"
    />
  );
};

export default DeletePlaylistDialog;
