/* eslint-disable max-classes-per-file */
import { Component } from 'react';
import {
  ActionSnackbar,
  ErrorSnackbar,
  InfoSnackbar,
} from '@onc/composite-components';

class WithSnackbarStatics {
  /**
   * @type {{
   *   message: string;
   *   variant: string;
   *   callback: Function;
   *   action: Function;
   * }[]}
   */
  static queue = [];

  static notifyMaster = undefined;
}

type WithSnackbarsState = {
  open: boolean;
  updateTicker: number;
  isMaster: boolean;
};
/**
 * HOC component withSnackbars that handles displaying snackbars one-at-a-time
 * and in root div (needed for react grid layout).
 *
 * @param WrappedComponent - Component you want to have the onError and onInfo
 *   props applied to.
 */
const withSnackbars = (WrappedComponent) => {
  class WithSnackbars extends Component<any, WithSnackbarsState> {
    // stolen from
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
    getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return undefined;
          }
          seen.add(value);
        }
        return value;
      };
    };

    constructor(props) {
      super(props);

      // updateTicker is simply to cause state change re-renders. See comment above `triggerUpdate`
      this.state = {
        open: false,
        updateTicker: 0,
        isMaster: false,
      };

      // Any new Snackbar will take over the master when first constructed
      WithSnackbarStatics.notifyMaster = this.triggerUpdate;
    }

    componentDidMount() {
      this.takeOverMaster();
      if (WithSnackbarStatics.queue.length === 0) {
        this.releaseMaster();
      }
    }

    // Note: a render without any snackbar is required between snackbars to ensure that
    // the old snackbar instance isn't re-used
    componentDidUpdate() {
      const { open } = this.state;
      if (!open) {
        this.checkSnackbarQueue();
      }
      if (WithSnackbarStatics.queue.length === 0) {
        this.releaseMaster();
      }
    }

    componentWillUnmount() {
      const { isMaster } = this.state;
      // Allow another instance to become the master. Also clear the queue
      // to ensure consistent behaviour
      if (isMaster) {
        WithSnackbarStatics.notifyMaster = undefined;
        WithSnackbarStatics.queue = [];
      }
    }

    /**
     * Take over the master snackbar when the component a) construct b) mount c)
     * add new message to the static queue
     */
    takeOverMaster = () => {
      WithSnackbarStatics.notifyMaster = this.triggerUpdate;
    };

    /** Release the master snackbar when the component a) didUpdate b) closed */
    releaseMaster = () => {
      WithSnackbarStatics.notifyMaster = undefined;
    };

    /** Removes the first snackbar in the queue to pass to setSnackbarMessage. */
    checkSnackbarQueue = () => {
      if (WithSnackbarStatics.queue.length > 0) {
        const snackBar = WithSnackbarStatics.queue[0];
        this.setState(
          {
            open: true,
          },
          snackBar.callback
        );
      }
    };

    // Allows for other withSnackbar instances to trigger a re-render of the master instance
    // forceUpdate didn't like running when assigned to a static variable
    triggerUpdate = () => {
      const { updateTicker } = this.state;
      this.setState({ updateTicker: updateTicker + 1 });
    };

    setInfo = (message, callback) => {
      this.addToSnackbarQueue(message, 'info', callback);
    };

    setError = (message, callback) => {
      if (message instanceof Error) {
        // Prevent empty error snackbars from being added to the queue
        if (message.message !== '') {
          this.addToSnackbarQueue(message.message, 'error', callback);
        }
      }
      this.addToSnackbarQueue(message, 'error', callback);
    };

    setActionSnackbar = (message, action, callback) => {
      this.addToSnackbarQueue(message, 'ActionSnackbar', callback, action);
    };

    /**
     * Adds snackbars into a queue so that only one is displayed on the screen
     * at a time.
     *
     * @param {string} message Message to display
     * @param {string} variant 'error', 'info', or 'action'
     * @param {Function} [callback]
     * @param {Function} [action]
     */
    addToSnackbarQueue = (message, variant, callback, action = undefined) => {
      WithSnackbarStatics.queue.push({
        message,
        variant,
        callback,
        action,
      });
      if (WithSnackbarStatics.notifyMaster) {
        WithSnackbarStatics.notifyMaster();
      } else {
        WithSnackbarStatics.notifyMaster = this.triggerUpdate;
        this.setState({ isMaster: true });
      }
    };

    handleClose = () => {
      WithSnackbarStatics.queue.shift();
      this.setState({ open: false });
      if (WithSnackbarStatics.queue.length === 0) {
        this.releaseMaster();
        this.setState({ isMaster: false });
      }
    };

    /**
     * Renders either ErrorSnackbar or InfoSnackbar depending on current
     * snackbar information.
     */
    renderSnackbar = () => {
      const { open } = this.state;

      if (!open || WithSnackbarStatics.queue.length === 0) {
        return null;
      }

      const { variant, message, action } = WithSnackbarStatics.queue[0];

      const messageStr =
        message instanceof Object
          ? JSON.stringify(message, this.getCircularReplacer())
          : message;
      if (variant === 'error') {
        return (
          <ErrorSnackbar message={messageStr} onClose={this.handleClose} />
        );
      }
      if (variant === 'ActionSnackbar') {
        return (
          <ActionSnackbar
            message={messageStr}
            onClose={this.handleClose}
            actionButton={action}
          />
        );
      }
      return <InfoSnackbar message={messageStr} onClose={this.handleClose} />;
    };

    render() {
      const { isMaster } = this.state;
      return (
        <>
          {isMaster ? this.renderSnackbar() : undefined}
          <WrappedComponent
            onError={this.setError}
            onInfo={this.setInfo}
            onAction={this.setActionSnackbar}
            {...this.props}
          />
        </>
      );
    }
  }

  return WithSnackbars;
};

export default withSnackbars;
