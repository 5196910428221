import { ReactNode, useState } from 'react';
import MaterialCard, {
  type CardProps as MaterialCardProps,
} from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@onc/icons';

import { Collapse } from 'base-components';

const styles = {
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  card: {
    height: '100%',
  },
  iconButton: {
    marginLeft: 'auto',
  },
  // the header has a gross padding which makes the card look bad, get rid of the bottom padding
  headerWithContent: {
    paddingBottom: '0px !important',
  },
  headerNoContent: {
    paddingBottom: '16px',
  },
  contentWithExpand: {
    paddingBottom: '0px !important',
  },
  contentNoExpand: {
    paddingBottom: '16px',
  },
};

type CardProps = Omit<MaterialCardProps, 'title'> & {
  imageURL?: string;
  title?: any;
  subheader?: ReactNode;
  supportingContent?: ReactNode;
  actionContent?: ReactNode;
  expandContent?: ReactNode;
  expandHeight?: string;
  expandOverflow?: string;
  mediaLocation?: 'middle' | 'top';
};

// @Deprecated Use components from card/Card
const Card = ({
  imageURL = null,
  title = null,
  subheader = null,
  supportingContent = null,
  actionContent = null,
  expandContent = null,
  expandHeight = null,
  expandOverflow = 'none',
  mediaLocation = 'middle',
  ...rest
}: CardProps) => {
  const [expandOpen, setExpandOpen] = useState<boolean>(false);

  const handleExpandClicked = () => {
    setExpandOpen(!expandOpen);
  };

  const renderCardHeader = () => {
    let mediaRender = null;
    if (imageURL) {
      mediaRender = <CardMedia image={imageURL} sx={styles.media} role="img" />;
    }

    let expandIcon = <ExpandMoreIcon />;
    if (expandOpen) {
      expandIcon = <ExpandLessIcon />;
    }
    let expandHeader = null;
    if (expandContent && !supportingContent) {
      expandHeader = (
        <IconButton sx={styles.iconButton} onClick={handleExpandClicked}>
          {expandIcon}
        </IconButton>
      );
    }

    let headerClass = styles.headerNoContent;
    if (supportingContent) {
      headerClass = styles.headerWithContent;
    }

    if (title || subheader) {
      if (mediaLocation === 'top') {
        return (
          <>
            {mediaRender}
            <CardHeader
              title={title}
              subheader={subheader}
              sx={headerClass}
              action={expandHeader}
            />
          </>
        );
      }

      return (
        <>
          <CardHeader
            title={title}
            subheader={subheader}
            sx={headerClass}
            action={expandHeader}
          />
          {mediaRender}
        </>
      );
    }
    return mediaRender;
  };

  const renderCardContent = () => {
    let contentClass = styles.contentNoExpand;
    if (expandContent) {
      contentClass = styles.contentWithExpand;
    }
    if (supportingContent) {
      return <CardContent sx={contentClass}>{supportingContent}</CardContent>;
    }
    return null;
  };

  const renderCardActions = () => {
    let renderExpandButton = () => null;
    let iconRender = <ExpandMoreIcon />;
    if (expandOpen) {
      iconRender = <ExpandLessIcon />;
    }
    if (expandContent && supportingContent) {
      renderExpandButton = () => (
        <IconButton sx={styles.iconButton} onClick={handleExpandClicked}>
          {iconRender}
        </IconButton>
      );
    }

    if (actionContent || (expandContent && supportingContent)) {
      return (
        <CardActions>
          {actionContent}
          {renderExpandButton()}
        </CardActions>
      );
    }

    return null;
  };

  return (
    <MaterialCard sx={styles.card} raised role="complementary" {...rest}>
      {renderCardHeader()}
      {renderCardContent()}
      {renderCardActions()}

      <Collapse
        in={expandOpen}
        style={{ maxHeight: expandHeight, overflow: expandOverflow }}
        role="contentinfo"
      >
        <CardContent>{expandContent}</CardContent>
      </Collapse>
    </MaterialCard>
  );
};

export default Card;
