import { createContext } from 'react';
import { CruiseJSON } from 'domain/services/CruiseService';
import { DiveJSON } from 'domain/services/DiveListingService';
import {
  TopologyByDeviceParams,
  TopologyJSON,
} from 'domain/services/TopologyService';
import { UserManagementJSON } from 'domain/services/UserManagementService';

export interface DiveFormContextInterface {
  getCruises: () => Promise<CruiseJSON[]>;
  getDive: (diveId: number) => Promise<DiveJSON | undefined>;
  getDiveChiefs: () => Promise<UserManagementJSON[]>;
  getTopologyForDevice: (
    params: TopologyByDeviceParams
  ) => Promise<TopologyJSON[]>;
  onSubmit: (data: any) => void;
}

const DiveFormContext = createContext<DiveFormContextInterface>({
  getCruises: () =>
    new Promise((resolve) => {
      resolve([]);
    }),
  getDiveChiefs: () =>
    new Promise((resolve) => {
      resolve([]);
    }),
  getDive: () =>
    new Promise((resolve) => {
      resolve(undefined);
    }),
  getTopologyForDevice: () =>
    new Promise((resolve) => {
      resolve([]);
    }),
  onSubmit: () =>
    new Promise((resolve) => {
      resolve([]);
    }),
});

export default DiveFormContext;
