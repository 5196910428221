import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InfoItemScrollable from 'library/CompositeComponents/list-items/InfoItemScrollable';

class CatalogDetails extends PureComponent {
  static propTypes = {
    location: PropTypes.bool.isRequired,
  };

  render() {
    const { location } = this.props;
    const fixedCameraMention = location ? (
      <>
        or <b>Fixed Cameras</b>
      </>
    ) : undefined;
    return (
      <InfoItemScrollable
        primary="About the Catalog"
        secondary={
          <>
            The SeaTube Catalog contains video recordings from Remotely Operated
            Vehicle (ROV) dives (during expeditions at sea), fixed cameras at
            the seafloor, and time-lapse cameras at coastal shore stations.
            <br />
            <br />
            Click <b>Expeditions</b> {fixedCameraMention} to get started.
          </>
        }
      />
    );
  }
}

export default CatalogDetails;
