import PropTypes from 'prop-types';

import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import Environment from 'util/Environment';

const getSensorListingUrl = (sensorId, tabName) => {
  if (tabName == null) {
    return `${Environment.getDmasUrl()}/SensorListing?SensorId=${sensorId}`;
  }
  return `${Environment.getDmasUrl()}/SensorListing?SensorId=${sensorId}#${tabName}`;
};

const openSensorDetails = (sensorId, tabName) => {
  global.open(getSensorListingUrl(sensorId, tabName), '_self');
};

const handleOpenSensorDetails = (sensorId, tabName) => () => {
  openSensorDetails(sensorId, tabName);
};

const SensorDetailsLink = ({ sensorId, children = undefined }) => (
  <OpenInNewLink href={getSensorListingUrl(sensorId)}>
    {children || sensorId}
  </OpenInNewLink>
);

SensorDetailsLink.propTypes = {
  sensorId: PropTypes.number.isRequired,
  children: PropTypes.node,
};

export { handleOpenSensorDetails, openSensorDetails };
export default SensorDetailsLink;
