import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckCircle, RemoveCircle } from '@onc/icons';
import { Dialog, DialogActions, DialogContent, Grid } from 'base-components';
import { EditIconButton } from 'domain/AppComponents/IconButtons';
import StatelessTable from 'library/BaseComponents/table/StatelessTable';
import {
  SaveButton,
  CancelButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormCheckbox from 'library/CompositeComponents/form/FormCheckbox';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import EEWRegistrationService from './service/EEWRegistrationService';
import { Subscription } from './SubscriptionData';

type Props = {
  subscriptions: Subscription[];
  onSave: () => void;
};

type formMethods = {
  active: boolean;
  destination: string;
};

const SubscriptionTable: FC<Props> = (props: Props) => {
  const { onSave, subscriptions } = props;

  const columns = [
    { name: 'active', title: 'Active' },
    { name: 'notificationType', title: 'NotificationType' },
    { name: 'destination', title: 'Destination' },
    { name: 'subMetAttrValueId', title: 'subMetAttrValueId' },
    { name: 'edit', title: ' ' },
  ];

  const [rowToBeEdited, setRowToBeEdited] = useState<Subscription>(undefined);
  const formMethods = useForm<formMethods>({
    mode: 'onBlur',
    defaultValues: {
      active: false,
      destination: undefined,
    },
  });

  const getIcon = (active: any) =>
    active ? (
      <CheckCircle sx={{ color: 'success.main' }} name="checkIcon" />
    ) : (
      <RemoveCircle sx={{ color: 'error.main' }} name="cancelIcon" />
    );

  const closeEditDialog = () => {
    setRowToBeEdited(undefined);
  };

  const openEditDialog = (row) => {
    formMethods.setValue('active', row.active);
    formMethods.setValue('destination', row.destination);
    setRowToBeEdited(row);
  };

  const formatRows = (rows) =>
    rows.map((row) => {
      const formattedRow = { ...row };
      formattedRow.active = getIcon(formattedRow.active);
      formattedRow.edit = (
        <EditIconButton
          onClick={() => {
            openEditDialog(row);
          }}
          aria-label="Edit Subscription"
          size="small"
        />
      );
      return formattedRow;
    });

  const handleSave = async () => {
    await EEWRegistrationService.subscribe(
      rowToBeEdited.notificationType,
      formMethods.getValues().destination,
      formMethods.getValues().active
    );
    closeEditDialog();
    onSave();
  };

  const renderEditDialog = () => {
    if (!rowToBeEdited) {
      return <></>;
    }
    return (
      <Dialog
        open
        fullWidth
        fullScreen={false}
        hideBackdrop
        disableEnforceFocus
      >
        <DialogContent>
          <Form onSubmit={handleSave} formMethods={formMethods}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7} lg={7}>
                <FormCheckbox name="active" label="Active" />
              </Grid>
              <Grid item xs={12} md={7} lg={7}>
                <FormTextField
                  name="destination"
                  translationKey="earthquake.subscriptionDestination"
                  id="subscriptionDestinationId"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions>
          <CancelButton
            onClick={() => closeEditDialog()}
            aria-label="Close Edit Dialog"
          />
          <SaveButton type="submit" onClick={() => handleSave()} />
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <StatelessTable
        getRowId={(row) => row.notificationType}
        rows={formatRows(subscriptions)}
        columns={columns}
        title="Subscriptions"
        visible={{
          hiddenColumnNames: ['subMetAttrValueId'],
        }}
      />
      {renderEditDialog()}
    </>
  );
};

export default SubscriptionTable;
