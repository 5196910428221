import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';

const STYLES = (theme: Theme) =>
  createStyles({
    lightGreyMask: {
      position: 'absolute',
      top: theme.spacing(0),
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,.3)',
      zIndex: 0,
    },
    darkGreyMask: {
      position: 'absolute',
      top: theme.spacing(0),
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,.5)',
      zIndex: 0,
    },
    noMask: {
      position: 'absolute',
      top: theme.spacing(0),
      height: '100%',
      width: '100%',
      zIndex: 0,
    },
  });

interface PanelCardComponentsProps extends WithStyles<typeof STYLES> {
  noMask?: boolean;
  highlighted: boolean;
}

const PanelCardMask: React.FC<PanelCardComponentsProps> = ({
  noMask = false,
  classes,
  highlighted,
}: PanelCardComponentsProps) => {
  let mask = classes.lightGreyMask;
  if (noMask) {
    mask = classes.noMask;
  } else if (highlighted) {
    mask = classes.darkGreyMask;
  }
  return <div className={mask} />;
};

export default withStyles(STYLES)(PanelCardMask);
