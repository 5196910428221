import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import FormFieldParser, {
  FormField,
} from 'domain/AppComponents/annotations/util/FormFieldParser';
import FormService, { FormFieldParameters } from 'domain/services/FormService';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useFormFields = (
  onError: (error: Error) => void,
  parameters: FormFieldParameters
) => {
  const [data, setData] = useState<Map<string, FormField>>(new Map());
  const prevParameters = usePrevious(parameters);

  useEffect(() => {
    if (
      parameters.resourceTypeId &&
      parameters.sourceIds &&
      !_.isEqual(prevParameters, parameters)
    ) {
      FormService.getFormFields(parameters)
        .then((response) => setData(FormFieldParser.parseData(response)))
        .catch((error) => onError(error));
    }
  }, [prevParameters, parameters, onError]);

  return data;
};

export default useFormFields;
