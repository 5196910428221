import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Add } from '@onc/icons';
import { RefreshButton } from 'domain/AppComponents/IconButtons';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import { FloatRightButtonLowStyles } from 'library/CompositeComponents/button/CommonButtonStyles';
import Panel from 'library/CompositeComponents/panel/Panel';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  abcd: {
    margin: theme.spacing(1),
  },
  button: FloatRightButtonLowStyles,
});

const headers = [
  { title: 'Device Action Id', name: 'deviceActionId' },
  { title: 'Device Id', name: 'deviceId' },
  { title: 'Device Name', name: 'deviceName' },
  { title: 'Action', name: 'actionName' },
  { title: 'Cruise', name: 'cruiseName' },
  { title: 'Performed Date', name: 'datePerformed' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'Modified By', name: 'modifyByName' },
  { title: 'Device Action Id For Search', name: 'deviceActionIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'deviceActionId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'deviceName',
    width: ColumnInfo.large,
    align: 'left',
  },
  {
    columnName: 'actionName',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'cruiseName',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'datePerformed',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'modifyByName',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'deviceActionIdForSearch',
    width: ColumnInfo.medium,
    align: 'left',
  },
];

class DeviceActionsTable extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    searchActive: PropTypes.bool,
    deviceActions: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    searchActive: false,
    deviceActions: [],
  };

  compareActionId = (a, b) => {
    const idA = a.props.children;
    const idB = b.props.children;
    if (idA === idB) return 0;
    return Number(idA) < Number(idB) ? -1 : 1;
  };

  addDeviceActionLink = (deviceActionId) => (
    <a
      href={`${Environment.getDmasUrl()}/DeviceActionMaintenance?ID=${deviceActionId}`}
    >
      {deviceActionId}
    </a>
  );

  buildTableRows = () => {
    const { deviceActions } = this.props;
    return deviceActions.map((deviceAction) => ({
      ...deviceAction,
      modifyDate: DateFormatUtils.formatDate(deviceAction.modifyDate, 'full'),
      datePerformed: DateFormatUtils.formatDate(
        deviceAction.datePerformed,
        'full'
      ),
      deviceActionId: this.addDeviceActionLink(deviceAction.deviceActionId),
      deviceActionIdForSearch: deviceAction.deviceActionId,
    }));
  };

  actionContent = () => {
    const { classes } = this.props;
    const permission = Environment.getDmasUserPrivilege();
    // eslint-disable-next-line react/no-unstable-nested-components
    const DeviceActionRefreshButton = () => (
      // eslint-disable-next-line react/no-this-in-sfc
      <RefreshButton onClick={this.refresh} />
    );
    if (permission !== 'RW') {
      return <DeviceActionRefreshButton />;
    }
    return (
      <>
        <DeviceActionRefreshButton />
        <a href={`${Environment.getDmasUrl()}/DeviceActionMaintenance`}>
          <ContainedButton
            translationKey="device.addDeviceAction"
            startIcon={<Add />}
            className={classes.button}
          />
        </a>
      </>
    );
  };

  render() {
    const { classes, searchActive } = this.props;
    const permission = Environment.getDmasUserPrivilege();

    return (
      <Panel actionContent={this.actionContent()}>
        <SortableTable
          className={classes.abcd}
          columns={headers}
          columnSizes={tableColumnExtensions}
          columnExtensions={tableColumnExtensions}
          elevation={0}
          noDataMessage={searchActive ? 'Loading...' : 'No Data'}
          pageSize={15}
          rows={this.buildTableRows()}
          searchable
          searchBarMoveable={permission === 'RW'}
          sortExtensions={[
            { columnName: 'deviceActionId', compare: this.compareActionId },
          ]}
          stripedRows
          hiddenColumns={['deviceActionIdForSearch']}
        />
      </Panel>
    );
  }
}

export default withStyles(styles)(DeviceActionsTable);
