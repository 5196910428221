import { createRef, PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Environment from 'util/Environment';
import { get } from 'util/WebRequest';

const STYLES = {
  circularProgress: {
    blue: '%233f51b5',
  },
};

const RESOURCE_URL = Environment.getStaticResourcePath();
const ROOT_URL = Environment.getDmasUrl();

const CUSTOM_CSS = `
.dmas-common-assets-icons-sprites {
  background-image: url("${RESOURCE_URL}/static/src/core/common/assets/icons-sprites.png");
}

.react-grid-item .yui-layout,
.react-grid-item .yui-layout-doc,
.react-grid-item .yui-layout-unit-center,
.react-grid-item .yui-layout-wrap,
.react-grid-item .yui-layout-bd {
  height: 100% !important;
  width: 100% !important;
  border: 0px !important;
  top: 0px !important;
  left: 0px !important;
}

.dmas-header-status {
  display: none;
}

.plottitle {
  display: none; /* Hide plot number bar */
}

/* Styling to make Plot Options Menu like almost like Material */
.yui-skin-sam .yuimenubarnav .yuimenubaritemlabel-hassubmenu,
.yui-skin-sam .yuimenubar,
.yui-skin-sam .yuimenubaritem-selected {
  background: none;
  border-width: 0px;
}

.yui-skin-sam .yuimenu .bd {
  border-width: 0px;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
  border-radius: 4px;
}

.yui-skin-sam .yui-menu-shadow-visible {
  display: none;
}

.yui-skin-sam .yuimenubaritem {
  z-index: 99;
}

.yui-skin-sam .yuimenubarnav .yuimenubaritemlabel {
  padding-right: 10px;
}

.yui-skin-sam .yuimenubaritem-selected,
.yui-skin-sam .yuimenuitem-selected {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.yuimenubaritemlabel,
.yuimenuitemlabel {
  cursor: pointer !important;
}

.yuimenubarnav {
  top: 20px !important; /* Move menu popup under the menu */
}

.dmas-has-left-submenu .yuimenubarnav {
  top: 0px !important; /* Position the submenu */
  left: -101px !important;
}

.plot-menu-drop-down li {
  line-height: inherit;
}

.yui-skin-sam .yuimenubarnav .yuimenubaritem {
  border-right-width: 0px;
}

.plot-menu-drop-down-div {
  right: 2px;
}

/* Override the chart library's font */
.ejschart span {
  font-family: inherit !important;
  font-size: 11px !important;
}

/* Move bottom axis caption down */
.ejsc-bottom-axis-caption {
  bottom: -1px  !important;
}

/* Replace splash page with Material-esque loading spinner */
.dmas-pu-charts > div:not(.singleplotcontainer) {
  visibility: hidden;
  position: relative;
}

.dmas-pu-charts > div:not(.singleplotcontainer)::after {
  content: '';
  visibility: visible;
  position: absolute;
  display: flex;
  top: 15%;
  left: 45%;
  /* Spinner taken from https://codepen.io/tigt/post/semantic-single-element-loading-spinners-with-html-css */
  background: url("data:image/svg+xml,%3csvg class='spinner' viewBox='-33 -33 66 66' xmlns='http://www.w3.org/2000/svg'%3e%3cstyle%3ecircle%7Banimation:d 1.5s ease-in-out infinite%7d@keyframes d%7b50%25%7bstroke-dashoffset:47;transform:rotate(135deg)%7dto%7btransform:rotate(450deg)%7d%7d%3c/style%3e%3ccircle fill='none' stroke-width='5' stroke-dasharray='187' stroke-dashoffset='187' stroke='${STYLES.circularProgress.blue}' r='30'/%3e%3c/svg%3e");
  width: 6em;
  height: 6em;
  animation: material-spin 1.5s linear infinite;
}

@keyframes material-spin {
  to { transform: rotate(270deg) }
}
`;

const CSS_SCRIPTS = [
  '/design/js/common/widget/SVG2PNG/SVG2PNGWidget.css',
  '/static/build/dmasCoreCss.css',
  '/static/build/extension/plottingutility/plottingutility.min.css',
];

const JS_SCRIPTS = [
  '/static/lib/ejschart-developer_2_3/src/EJSChart_source.js',
  '/static/lib/ejschart-developer_2_3/src/EJSChart_SVGExport_source.js',
  '/static/lib/ejschart-developer_2_3/dist/EJSChart_DMAS_Ext.js',
  '/static/lib/ejschart-developer_2_3/dist/EJSChart_SVGExport_DMAS_Ext.js',
  '/static/lib/yui/build/yuiCombo-min.js',
  '/static/build/dmasCoreJs.js',
  '/static/src/core/common/util/base.js',
  '/design/js/common/widget/SVG2PNG/SVG2PNGWidget.js',
  '/design/js/common/element/devicetree.js',
  '/static/src/core/common/widget/treeview/DeepLinker.js',
];

const PLOTTINGUTILITY_SCRIPT =
  '/static/build/extension/plottingutility/plottingutility.min.js';

class PlottingUtility extends PureComponent {
  static propTypes = {
    isPlotsOnlyMode: PropTypes.bool,
    savedPlotId: PropTypes.number,
    onError: PropTypes.func,
  };

  static defaultProps = {
    isPlotsOnlyMode: false,
    savedPlotId: null,
    onError: undefined,
  };

  isScriptLoaded = (script) => {
    const existingScripts = document.getElementsByTagName('script');
    for (let i = 0; i < existingScripts.length; i += 1) {
      const existingScript = existingScripts[i].getAttribute('src');
      if (existingScript && existingScript.includes(script)) {
        return true;
      }
    }
    return false;
  };

  /** Attach a remote Javascript file as a script tag. */
  attachJsScript = (script) => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = `${RESOURCE_URL}${script}`;
    scriptTag.async = false;
    document.head.appendChild(scriptTag);
    return scriptTag;
  };

  /** Attach a remote CSS file as a link tag. */
  linkCssScript = (css) => {
    const linkTag = document.createElement('link');
    linkTag.rel = 'stylesheet';
    linkTag.href = `${RESOURCE_URL}${css}`;
    document.head.appendChild(linkTag);
    return linkTag;
  };

  /** Attach an inline CSS script as a style tag. */
  attachCustomCss = (css) => {
    const styleTag = document.createElement('style');
    const textTag = document.createTextNode(css);
    styleTag.appendChild(textTag);
    styleTag.type = 'text/css';
    document.head.appendChild(styleTag);
    return styleTag;
  };

  constructor(props) {
    super(props);

    this.state = {
      containerDivRef: createRef(),
      jsScripts: JS_SCRIPTS.filter((script) => !this.isScriptLoaded(script)),
    };
  }

  componentDidMount() {
    this.renderPlottingUtility();
  }

  renderPlottingUtility() {
    const { isPlotsOnlyMode, savedPlotId } = this.props;
    const { jsScripts } = this.state;

    // Set up DMAS namespace
    window.DMAS.Config.services = window.DMAS.Config.services || {
      DEVICE_SERVICE: `${ROOT_URL}/DeviceService`,
      I18N_SERVICE: `${ROOT_URL}/I18nService`,
      USER_PREFERENCE_SERVICE: `${ROOT_URL}/UserPreferenceService`,
      SCALAR_DATA_SERVICE: `${ROOT_URL}/ScalarDataAPIService`,
      SVG2PNG_SERVICE: `${ROOT_URL}/SVG2PNGService`,
      USER_FOLDER_SERVICE: `${ROOT_URL}/UserFolderService`,
    };
    window.DMAS.Config.DMAS_URL = ROOT_URL;
    window.DMAS.Config.STATIC_PATH = `${RESOURCE_URL}/static/src`;
    window.DMAS.Config.WIKI_URL = 'https://wiki.oceannetworks.ca';

    // Plotting Utility CSS needs yui-skin-sam on the root HTML element,
    // append if not already there.
    const htmlClassName = document.getElementsByTagName('HTML')[0].className;
    if (htmlClassName.match(/\byui-skin-sam\b/) === null) {
      document.getElementsByTagName('HTML')[0].className =
        `${htmlClassName} yui-skin-sam`;
    }

    // Load dependencies
    CSS_SCRIPTS.forEach((css) => this.linkCssScript(css));
    this.attachCustomCss(CUSTOM_CSS);
    jsScripts.forEach((script) => this.attachJsScript(script));

    // Load Plotting Utility
    this.script = this.attachJsScript(PLOTTINGUTILITY_SCRIPT);
    this.script.onload = () => {
      const { containerDivRef } = this.state;
      const pageLayout = new window.YAHOO.widget.Layout(
        containerDivRef.current,
        {
          units: [
            {
              position: 'center',
              body: '',
              gutter: '5 8 5 5',
              scroll: false,
            },
          ],
        }
      );
      pageLayout.on('render', async () => {
        const { onError } = this.props;
        try {
          const appUnit = pageLayout.getUnitByPosition('center');

          // Build AJAX Status div
          const status = document.createElement('div');
          status.setAttribute('class', 'dmas-header-status');
          document.body.appendChild(status);

          // Build Plot Properties div
          const propertiesDialogDiv = document.createElement('div');
          propertiesDialogDiv.setAttribute('id', 'propertiesdialog');
          document.body.appendChild(propertiesDialogDiv);

          // Init DMAS AJAX
          window.DMAS.core.common.util.ajax.init();
          window.DMAS.core.common.util.ajax.setGETIndicator(status);
          window.DMAS.core.common.util.ajax.setPOSTIndicator(document.body);

          window.DMAS.common.event.APPUNIT_READY.fire(appUnit);

          // Fetch language data map
          const result = await get(window.DMAS.Config.services.I18N_SERVICE, {
            module: 'ca.uvic.dmas.extension.plottingutility',
          });
          window.DMAS.core.common.data.I18nData.setMap(result.data.payload);

          // DMAS constructor name violates ESLint
          // eslint-disable-next-line new-cap
          const plottingUtility =
            // eslint-disable-next-line new-cap
            new window.DMAS.extension.plottingutility.plottingutilitynew(
              appUnit,
              null,
              'true',
              isPlotsOnlyMode,
              savedPlotId
            );

          this.setState({ plottingUtility });
          return Promise.resolve();
        } catch (error) {
          if (onError) {
            onError(error);
          }
          return Promise.reject(error);
        }
      });
      return pageLayout.render();
    };
  }

  render() {
    const { containerDivRef } = this.state;
    return <div ref={containerDivRef} style={{ height: '500px' }} />;
  }
}

export default withStyles(STYLES)(PlottingUtility);
