import MaterialPaper, {
  type PaperProps as MaterialPaperProps,
} from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(),
  },
});

const useStyles = makeStyles(styles);

type PaperProps = MaterialPaperProps & {
  classes?: {
    paper?: string;
  };
  className?: string;
  elevation?: number;
  children?: React.ReactNode;
};

const Paper = ({ classes = undefined, ...rest }: PaperProps) => {
  const defaultClasses = useStyles();

  return (
    <MaterialPaper
      className={
        classes && classes.paper ? classes.paper : defaultClasses.paper
      }
      {...rest}
    />
  );
};
export default Paper;
