import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid, TextField } from 'base-components';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { ATTRIBUTE_LABEL_CODE } from './QaqcDetailsAttributesConstants';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: theme.spacing(50),
  },
  containerDiv: {
    margin: theme.spacing(1),
  },
  dropdown: {
    marginBottom: theme.spacing(2),
  },
  roFieldRoot: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0),
  },
});

const defaultState = { attributeValues: {} };

class QaqcDetailsAttributes extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    attributeValues: PropTypes.objectOf(PropTypes.string),
    formulaAttributes: PropTypes.arrayOf(PropTypes.string),
    permission: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    attributeValues: {},
    formulaAttributes: [],
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  render() {
    const {
      attributeValues,
      classes,
      formulaAttributes,
      permission,
      onChange,
      onInfo,
      onError,
    } = this.props;

    if (Object.keys(attributeValues).length === 0) {
      return (
        <div className={classes.containerDiv}>
          {permission === 'RW' ? (
            <TextField disabled classes={{ textField: classes.textField }} />
          ) : (
            <ReadOnlyField
              classes={{ root: classes.roFieldRoot }}
              fullwidth
              valueText="No Attributes"
            />
          )}
        </div>
      );
    }
    return (
      <div className={classes.containerDiv}>
        <Grid container direction="column">
          {Object.keys(attributeValues).map((attribute) => {
            const attributeLabel = formulaAttributes.find((x) => {
              const matches = x.match(/\d+/g);
              return x === attribute || (matches && matches[0] === attribute);
            });
            const attributeDisplay =
              ATTRIBUTE_LABEL_CODE[attributeLabel] + attributeLabel;
            if (!attributeLabel) return <div key={attribute} />;
            return (
              <Grid item lg={4} md={6} sm={12} key={attribute}>
                {permission === 'RW' ? (
                  <TextField
                    classes={{ textField: classes.textField }}
                    fullwidth
                    translationKey="common.textfields.variable"
                    translationOptions={{ variableName: attributeDisplay }}
                    name={attribute}
                    type="number"
                    value={attributeValues[attribute]}
                    onChange={onChange}
                    onInfo={onInfo}
                    onError={onError}
                  />
                ) : (
                  <ReadOnlyField
                    classes={{ root: classes.roFieldRoot }}
                    fullwidth
                    labelText={attributeDisplay}
                    valueText={attributeValues[attribute]}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(QaqcDetailsAttributes);
