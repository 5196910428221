import { Grid, Collapse } from 'base-components';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';

interface DateFiltersProps {
  dateSelection: 'dateRange' | 'latest';
}

const DATE_PRESET_OPTIONS = [
  { key: '1', value: 'tenMinutes', label: 'Last 10 Minutes' },
  { key: '2', value: 'twoHours', label: 'Last 2 Hours' },
  { key: '3', value: 'day', label: 'Last 24 hours' },
  { key: '4', value: 'sevenDays', label: 'Last 7 Days' },
];

const ChartWidgetDateFilters = ({ dateSelection }: DateFiltersProps) => (
  <Grid>
    <Collapse in={dateSelection === 'dateRange'}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormDateTimePicker
            translationKey="common.datepickers.startDate"
            fullWidth
            name="startDate"
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateTimePicker
            translationKey="common.datepickers.endDate"
            fullWidth
            name="endDate"
          />
        </Grid>
      </Grid>
    </Collapse>
    <Collapse in={dateSelection === 'latest'}>
      <Grid item xs={12}>
        <FormDropdown name="datePreset" options={DATE_PRESET_OPTIONS} />
      </Grid>
    </Collapse>
  </Grid>
);

export default ChartWidgetDateFilters;
