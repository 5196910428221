import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { DenseThemeProvider } from '@onc/theme';
import { Grid } from 'base-components';
import { SaveDialog } from 'domain/AppComponents/dialogs/Dialogs';
import TaxonomyAttributeGroupService from 'domain/services/TaxonomyAttributeGroupService';
import TaxonomyAttributeLineService from 'domain/services/TaxonomyAttributeLineService';
import TaxonomyAttributeService from 'domain/services/TaxonomyAttributeService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import AttributeDetailsPanel from './AttributeDetailsPanel';
import GroupInformationPanel from './GroupInformationPanel';
import GroupListPanel from './GroupListPanel';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    float: 'left',
    height: `calc(100% - ${theme.spacing(2)})`,
  },
  item: {
    height: '100%',
    overflowY: 'hidden',
  },
});

const ALL_ATTRIBUTE_GROUP = {
  name: 'All Attributes',
  key: -1,
  groupId: 0,
};

const DATA_TYPE_MAP = [
  {
    id: 1,
    dataTypeName: 'Integer',
  },
  {
    id: 2,
    dataTypeName: 'String',
  },
  {
    id: 3,
    dataTypeName: 'Boolean',
  },
  {
    id: 4,
    dataTypeName: 'Floating Point',
  },
  {
    id: 5,
    dataTypeName: 'Number',
  },
  {
    id: 6,
    dataTypeName: 'Select',
  },
];

class TaxonomyAttributeManagementPage extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
  };

  state = {
    attributeList: [],
    groupList: [],
    selectedGroup: ALL_ATTRIBUTE_GROUP,
    unsavedChanges: false,
    unsavedAttribute: undefined,
    attributeLines: [],
    showDialog: false,
  };

  componentDidMount() {
    this.getGroups();
    this.getAttributes();
  }

  /*
   *------------------------
   * ATTRIBUTE CRUD OPERATIONS
   *------------------------
   */

  getAttributes = async (id) => {
    const { onError } = this.props;
    try {
      const response = await TaxonomyAttributeService.getAllSelectable();
      const attributeList = response.map((attribute) => ({
        key: attribute.attributeId,
        dataTypeId: DATA_TYPE_MAP.find(
          (dataType) => dataType.dataTypeName === attribute.dataType
        ).id,
        ...attribute,
      }));
      const selectedAttribute = attributeList.find(
        (attribute) => attribute.attributeId === id
      );
      this.setState({ attributeList, selectedAttribute });
    } catch (error) {
      onError(error);
    }
  };

  handleCreateAttribute = () => {
    const { onError, onInfo } = this.props;
    const { selectedGroup, unsavedChanges } = this.state;
    if (unsavedChanges) {
      this.setState({ callback: this.handleCreateAttribute });
      return this.handleUnsavedAttribute();
    }
    return TaxonomyAttributeService.create({
      name: this.getUniqueAttributeName('New Attribute'),
      groupId: selectedGroup.groupId || 1, // 1 = Common group
      dataTypeId: 2, // 2 = String
    })
      .then((response) => {
        onInfo('New attribute created');
        this.getAttributes(response.id);
        this.setState({ attributeLines: [] });
      })
      .catch((error) => {
        onError(error);
      });
  };

  handleDeleteAttribute = (attribute) => {
    const { onError, onInfo } = this.props;
    return TaxonomyAttributeService.delete(attribute.attributeId)
      .then((response) => {
        if (response.wasDeleted) {
          onInfo(`"${attribute.name}" was deleted`);
          this.getAttributes();
          this.setState({ selectedAttribute: undefined });
        } else {
          onError('Error deleting attribute');
        }
      })
      .catch((error) => {
        onError(error);
      });
  };

  handleUpdateAttribute = (
    attributeData,
    attributeLinesData,
    navigatingAway,
    oldAttributeData
  ) => {
    const { onError, onInfo } = this.props;
    const { attributeLines } = this.state;
    const updatedAttribute = { ...attributeData };
    const promises = [];
    // The function trigger_fct_audit_required_columns() raises an exception if there is no change to an attribute aside from its modifyby and modifydate columns
    let onlyOptionsChanged = false;
    if (
      oldAttributeData !== undefined &&
      oldAttributeData.name === updatedAttribute.name &&
      oldAttributeData.dataTypeId === updatedAttribute.dataTypeId &&
      oldAttributeData.groupName === updatedAttribute.groupName
    ) {
      onlyOptionsChanged = true;
    }
    if (attributeLinesData) {
      // Handles deleting lines
      attributeLines.forEach((line) => {
        const exists = attributeLinesData.find((updatedLine) =>
          updatedLine.taxonomyAttributeLineId
            ? updatedLine.taxonomyAttributeLineId ===
              line.taxonomyAttributeLineId
            : false
        );
        if (!exists) {
          promises.push(
            this.handleDeleteAttributeLine(line.taxonomyAttributeLineId)
          );
        }
      });
      // Handles creating / updating lines
      attributeLinesData.forEach((updatedLine) => {
        const exists = attributeLines.find((line) =>
          updatedLine.taxonomyAttributeLineId
            ? updatedLine.taxonomyAttributeLineId ===
              line.taxonomyAttributeLineId
            : false
        );
        const hasChanged = updatedAttribute.attributeValues.find(
          (line) =>
            updatedLine.taxonomyAttributeLineId === line.value &&
            updatedLine.attributeValue !== line.label
        );
        if (exists) {
          if (hasChanged) {
            promises.push(this.handleUpdateAttributeLine(updatedLine));
          }
        } else {
          promises.push(
            this.handleCreateAttributeLine(updatedLine.attributeValue)
          );
        }
      });
    }
    // Updates the attribute itself
    if (!onlyOptionsChanged || oldAttributeData === undefined) {
      promises.push(
        new Promise((resolve, reject) => {
          TaxonomyAttributeService.update(
            updatedAttribute.attributeId,
            updatedAttribute
          )
            .then(() => {
              this.setUnsavedChanges(false);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
      );
    }

    return Promise.all(promises)
      .then(() => {
        onInfo('Attribute updated');
        if (!navigatingAway) {
          this.getAttributes(attributeData.attributeId);
          this.getAttributeLines(attributeData.attributeId);
        }
      })
      .catch((error) => {
        onError(error?.message);
      });
  };

  /*
   *------------------------
   * GROUP CRUD OPERATIONS
   *------------------------
   */

  getGroups = async (id) => {
    const { onError } = this.props;
    try {
      const response = await TaxonomyAttributeGroupService.getAll();
      const groups = [ALL_ATTRIBUTE_GROUP];
      const responseGroups = response.map((group) => ({
        name: group.name,
        key: group.groupId,
        groupId: group.groupId,
      }));
      groups.push(...responseGroups);

      const selectedGroup = groups.find((group) => group.groupId === id);
      this.setState({ groupList: groups });
      if (selectedGroup) {
        this.setState({ selectedGroup });
      }
    } catch (error) {
      onError(error);
    }
  };

  handleCreateGroup = () => {
    const { onError, onInfo } = this.props;
    const { unsavedChanges } = this.state;
    if (unsavedChanges) {
      this.setState({ callback: this.handleCreateGroup });
      return this.handleUnsavedAttribute();
    }
    return TaxonomyAttributeGroupService.create({
      name: this.getUniqueGroupName('New Group'),
    })
      .then((response) => {
        this.setState({ selectedAttribute: undefined });
        this.getGroups(response.id);
        onInfo('New attribute group created');
      })
      .catch((error) => {
        onError(error);
      });
  };

  handleUpdateGroup = (group) => {
    const { onError, onInfo } = this.props;
    return TaxonomyAttributeGroupService.update(group.groupId, group)
      .then(() => {
        onInfo(`"${group.name}" was updated`);
        this.getGroups();
        this.setState({ selectedGroup: group });
      })
      .catch((error) => {
        onError(error);
      });
  };

  handleDeleteGroup = () => {
    const { onError, onInfo } = this.props;
    const { selectedGroup } = this.state;
    return TaxonomyAttributeGroupService.delete(selectedGroup.groupId)
      .then(() => {
        onInfo(`"${selectedGroup.name}" was deleted`);
        this.getGroups();
        this.setState({ selectedGroup: ALL_ATTRIBUTE_GROUP });
      })
      .catch((error) => {
        onError(error.message);
      });
  };

  /*
   *------------------------
   * ATTRIBUTE LINE CRUD OPERATIONS
   *------------------------
   */

  getAttributeLines = async (id) =>
    TaxonomyAttributeLineService.getByAttributeId(id).then((response) => {
      const sortedResponse = [...response].sort((a, b) =>
        a.attributeValue.localeCompare(b.attributeValue)
      );
      this.setState({
        attributeLines: sortedResponse,
      });
    });

  handleCreateAttributeLine = (value) => {
    const { onError } = this.props;
    const { selectedAttribute } = this.state;
    return TaxonomyAttributeLineService.create(
      selectedAttribute.attributeId,
      value
    ).catch((error) => {
      onError(error);
    });
  };

  handleUpdateAttributeLine = (attributeLine) => {
    const { onError } = this.props;
    return TaxonomyAttributeLineService.update(
      attributeLine.taxonomyAttributeLineId,
      attributeLine
    ).catch((error) => {
      onError(error);
    });
  };

  handleDeleteAttributeLine = (id) => {
    const { onError } = this.props;
    return TaxonomyAttributeLineService.delete(id).catch((error) => {
      onError(error);
    });
  };

  //---------------------------------------

  groupNameExists = (name) => {
    const { groupList } = this.state;
    return groupList.some((group) => group.name === name);
  };

  getUniqueGroupName = (name) => {
    let newName = name;
    let attempt = 1;
    for (;;) {
      if (!this.groupNameExists(newName)) {
        break;
      }
      newName = `${name} (${attempt})`;
      attempt += 1;
    }
    return newName;
  };

  attributeNameExists = (name) => {
    const { attributeList } = this.state;
    return attributeList.some((attribute) => attribute.name === name);
  };

  getUniqueAttributeName = (name) => {
    let newName = name;
    let attempt = 1;
    for (;;) {
      if (!this.attributeNameExists(newName)) {
        break;
      }
      newName = `${name} (${attempt})`;
      attempt += 1;
    }
    return newName;
  };

  handleChangeGroup = (group) => {
    const { unsavedChanges } = this.state;
    if (unsavedChanges) {
      this.setState({ callback: () => this.handleChangeGroup(group) });
      return this.handleUnsavedAttribute();
    }
    return this.setState({
      selectedGroup: group,
      attributeLines: [],
      selectedAttribute: undefined,
    });
  };

  handleUnsavedAttribute = () => {
    const { unsavedChanges, selectedAttribute } = this.state;
    if (unsavedChanges && selectedAttribute) {
      this.setState({
        showDialog: true,
      });
      return true;
    }
    return false;
  };

  handleChangeAttribute = async (attribute) => {
    const { unsavedChanges } = this.state;
    if (unsavedChanges) {
      this.setState({ callback: () => this.handleChangeAttribute(attribute) });
      return this.handleUnsavedAttribute();
    }

    if (attribute) {
      await this.getAttributeLines(attribute.attributeId);
    }
    return this.setState({ selectedAttribute: attribute });
  };

  handleDialogClose = (callback) => {
    this.setState(
      {
        showDialog: false,
        unsavedAttribute: undefined,
        unsavedAttributeLines: [],
        unsavedChanges: false,
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );

    this.setState({
      callback: undefined,
    });
  };

  handleDialogCancel = async () => {
    const { callback } = this.state;
    this.handleDialogClose(callback);
  };

  handleDialogSave = async () => {
    const { unsavedAttribute, unsavedAttributeLines, attributeList, callback } =
      this.state;
    await this.handleUpdateAttribute(
      unsavedAttribute,
      unsavedAttributeLines,
      true,
      unsavedAttribute !== undefined
        ? attributeList.find(
            (attr) => attr.attributeId === unsavedAttribute.attributeId
          )
        : undefined
    );
    this.handleDialogClose(callback);
  };

  setUnsavedChanges = (
    unsavedChanges,
    unsavedAttribute,
    unsavedAttributeLines
  ) => {
    this.setState({ unsavedChanges, unsavedAttribute, unsavedAttributeLines });
  };

  render() {
    const { classes } = this.props;
    const {
      attributeList,
      groupList,
      selectedGroup,
      attributeLines,
      selectedAttribute,
      showDialog,
    } = this.state;
    return (
      <DenseThemeProvider>
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={6} md={4} lg={4} xl={3} className={classes.item}>
            <GroupListPanel
              handleCreateGroup={this.handleCreateGroup}
              handleChangeGroup={this.handleChangeGroup}
              handleDeleteGroup={this.handleDeleteGroup}
              groupList={groupList}
              selectedGroup={selectedGroup}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={4} xl={3} className={classes.item}>
            <GroupInformationPanel
              handleChangeAttribute={this.handleChangeAttribute}
              handleCreateAttribute={this.handleCreateAttribute}
              handleDeleteAttribute={this.handleDeleteAttribute}
              handleUpdateGroup={this.handleUpdateGroup}
              attributeList={attributeList}
              selectedAttribute={selectedAttribute}
              selectedGroup={selectedGroup}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={6} className={classes.item}>
            <AttributeDetailsPanel
              selectedAttribute={selectedAttribute}
              handleUpdateAttribute={this.handleUpdateAttribute}
              setUnsavedChanges={this.setUnsavedChanges}
              groupList={groupList}
              attributeLines={attributeLines}
            />
          </Grid>
        </Grid>
        <SaveDialog
          title="Unsaved Changes"
          content="Changes have been made to the current attribute.  Would you like to save?"
          open={showDialog}
          onCancel={this.handleDialogCancel}
          onSave={this.handleDialogSave}
        />
      </DenseThemeProvider>
    );
  }
}

export default withStyles(styles)(
  withSnackbars(TaxonomyAttributeManagementPage)
);
