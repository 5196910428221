import { Collapse } from 'base-components';
import { DataAvailabilityChartFormController } from '../ChartWidgetFormControllers';
import {
  LocationDataSource,
  DeploymentDataSource,
  isLocationDataSource,
  isDeploymentDataSource,
} from '../types/ChartWidgetConfig.types';
import type { DataSource } from 'domain/AppComponents/charts/DataAvailabilityChartV2';

type DataAvailabilitySectionProps = {
  sourcesLeftAxis?: Array<LocationDataSource | DeploymentDataSource>;
  sourcesRightAxis?: Array<LocationDataSource | DeploymentDataSource>;
  canSelectDateRange?: boolean;
};

const ChartWidgetDataAvailability = ({
  sourcesLeftAxis = [],
  sourcesRightAxis = [],
  canSelectDateRange = true,
  ...rest
}: DataAvailabilitySectionProps) => {
  const leftAxisHasData = sourcesLeftAxis.length > 0;
  const rightAxisHasData = sourcesRightAxis.length > 0;

  const sourceList = [...sourcesLeftAxis, ...sourcesRightAxis];

  const locationSourceDeviceIds: DataSource[] = sourceList
    .filter(isLocationDataSource)
    .flatMap((lDataSource) => ({
      extensions: ['txt'],
      dataProductCode: 'LF',
      dataSourceType: 'location',
      locationCode: lDataSource.stationCode,
      deviceCategoryCode: lDataSource.deviceCategoryCode,
    }));

  const deviceSourceDeviceIds: DataSource[] = sourceList
    .filter(isDeploymentDataSource)
    .flatMap((dDataSource) => ({
      dataSourceType: 'device',
      deviceCode: dDataSource.deviceCode,
    }));

  const allDataSources = deviceSourceDeviceIds.concat(locationSourceDeviceIds);

  return (
    <Collapse in={leftAxisHasData || rightAxisHasData}>
      <DataAvailabilityChartFormController
        name="dateRange"
        canSelectDateRange={canSelectDateRange}
        dataSources={allDataSources}
        {...rest}
      />
    </Collapse>
  );
};

export default ChartWidgetDataAvailability;
