import { Component } from 'react';
import { withStyles, createStyles } from '@mui/styles';
import _ from 'lodash';
import { FilterList, TextFields, Warning } from '@onc/icons';
import { Autocomplete, Dropdown, TextField } from 'base-components';
import OperatorIconButton from './OperatorIconButton';

const STRING_OPTIONS = [
  {
    label: 'Any Value',
    value: 'exists',
    icon: FilterList,
    disable: true,
  },
  {
    label: 'Contains String',
    value: 'containsString',
    icon: TextFields,
  },
  {
    label: 'Null',
    value: 'isNull',
    icon: Warning,
    disable: true,
  },
];

const NUMBER_OPTIONS = [
  {
    label: 'Any Value',
    value: 'exists',
    icon: FilterList,
    disable: true,
  },
  {
    label: 'Equals',
    value: 'equals',
    icon: '=',
  },
  {
    label: 'Does Not Equal',
    value: 'notEquals',
    icon: '≠',
  },
  {
    label: 'Greater Than',
    value: 'greaterThan',
    icon: '>',
  },
  {
    label: 'Greater Than or Equal',
    value: 'greaterThanOrEquals',
    icon: '≥',
  },
  {
    label: 'Less Than',
    value: 'lessThan',
    icon: '<',
  },
  {
    label: 'Less Than or Equal',
    value: 'lessThanOrEquals',
    icon: '≤',
  },
  {
    label: 'Null',
    value: 'isNull',
    icon: Warning,
    disable: true,
  },
];

const styles = () =>
  createStyles({
    flexContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  });

type Props = {
  classes: any;
  attributeLine: any;
  index: number;
  onChange: (newProps: any, index: number) => void;
};
type State = any;

class AttributeFilterValueField extends Component<Props, State> {
  componentDidUpdate(prevProps: any) {
    const { attributeLine, onChange, index } = this.props;
    const { attribute } = attributeLine;
    const prevAttribute = prevProps.attributeLine.attribute;
    const value = attribute ? attribute.value : undefined;
    const prevValue = prevAttribute ? prevAttribute.value : undefined;
    if (!_.isEqual(value, prevValue)) {
      onChange(
        { attribute, operator: this.getDefaultOperator(attribute) },
        index
      );
    }
  }

  getDefaultOperator = (value) => {
    if (value && value.dataType === 'Boolean') {
      return { value: 'exists' };
    }
    if (this.getOptions().length > 0) {
      return this.getOptions()[0];
    }
    return undefined;
  };

  // Method is only used during tests.
  // eslint-disable-next-line react/no-unused-class-component-methods
  getBooleanOperator = (val) => {
    if (val === 'all') {
      return { value: 'exists' };
    }
    if (val === 'null') {
      return { value: 'isNull' };
    }
    return undefined;
  };

  getOptions = () => {
    const { attributeLine } = this.props;
    if (attributeLine.attribute) {
      switch (attributeLine.attribute.dataType) {
        case 'String':
          return STRING_OPTIONS;
        case 'Integer':
          return NUMBER_OPTIONS;
        case 'Number':
          return NUMBER_OPTIONS;
        default:
          return [];
      }
    }
    return [];
  };

  static getBooleanOperator = (val) => {
    if (val === 'all') {
      return { value: 'exists' };
    }
    if (val === 'null') {
      return { value: 'isNull' };
    }
    return undefined;
  };

  getIconValue = () => {
    const { attributeLine } = this.props;
    let value;
    if (attributeLine.attribute && attributeLine.operator) {
      if (attributeLine.operator.icon) {
        if (attributeLine.attribute.dataType === 'String') {
          value = STRING_OPTIONS.find(
            (option) => option.value === attributeLine.operator.value
          );
        } else {
          value = NUMBER_OPTIONS.find(
            (option) => option.value === attributeLine.operator.value
          );
        }
      } else {
        value = attributeLine.operator;
      }
    }
    return value || STRING_OPTIONS[0];
  };

  renderOperatorButton = () => {
    const { index, onChange } = this.props;
    const options = this.getOptions();
    const value = this.getIconValue();
    if (options.length) {
      return (
        <OperatorIconButton
          options={options}
          value={value}
          index={index}
          onChange={onChange}
        />
      );
    }
    return undefined;
  };

  renderValueField = () => {
    const { attributeLine, index, onChange } = this.props;
    const { attribute } = attributeLine;
    const isDisabled = attributeLine.operator && attributeLine.operator.disable;
    const { filterValue } = attributeLine;
    if (attribute) {
      if (attribute.dataType === 'Select') {
        return (
          <Autocomplete
            translationKey="common.textfields.value"
            fullWidth
            multiple
            name="valueField"
            options={attribute.attributeValues}
            value={Array.isArray(filterValue) ? filterValue : undefined}
            getOptionLabel={(option: any) => option.label}
            onChange={(e: any) =>
              onChange({ filterValue: e.target.value }, index)
            }
          />
        );
      }
      if (attribute.dataType === 'Boolean') {
        return (
          <Dropdown
            fullWidth
            label="Value"
            onChange={(e: any) => {
              onChange(
                {
                  operator: AttributeFilterValueField.getBooleanOperator(
                    e.target.value
                  ),
                  filterValue:
                    e.target.value !== 'all' ? e.target.value : undefined,
                },
                index
              );
            }}
            options={[
              { value: 'all', label: 'Any Value', key: 'all' },
              { value: 'true', label: 'True', key: 'true' },
              { value: 'false', label: 'False', key: 'false' },
              { value: 'null', label: 'Null', key: 'null' },
            ]}
            value={filterValue || 'all'}
          />
        );
      }
      return (
        <TextField
          fullWidth
          disabled={isDisabled}
          translationKey="common.textfields.value"
          type={
            !isDisabled &&
            (attribute.dataType === 'Number' ||
              attribute.dataType === 'Integer')
              ? 'number'
              : 'string'
          }
          onChange={(e) => {
            onChange({ filterValue: e.target.value }, index);
          }}
          value={isDisabled ? attributeLine.operator.label : filterValue || ''}
        />
      );
    }
    return undefined;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.flexContainer}>
        {this.renderOperatorButton()}
        {this.renderValueField()}
      </div>
    );
  }
}

export default withStyles(styles)(AttributeFilterValueField);
