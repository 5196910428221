import * as React from 'react';
import { Grid, TextField } from 'base-components';
import Filter, {
  FilterImplementationProps,
  FilterValue,
  SummaryValues,
} from 'library/CompositeComponents/filter/Filter';

export interface DateFilterValue extends FilterValue {
  minMag?: number;
  maxMag?: number;
}

interface Props extends FilterImplementationProps {
  // FilterEvent causes errors in annotationSearchFilters.tsx
  onChange?: any;
  name: string;
  value?: DateFilterValue;
  title?: string;
}

const MagnitudeFilter: React.FC<Props> = ({
  onChange = undefined,
  name,
  value = undefined,
  onExpand,
  expanded,
  title = undefined,
}: Props) => {
  const renderSummaryValues = () => {
    if (value) {
      const summary: SummaryValues[] = [];
      const { minMag, maxMag } = value;
      if (minMag && maxMag) {
        summary.push({ label: `${minMag}  to  ${maxMag}` });
      } else if (minMag && !maxMag) {
        summary.push({ label: `Minimum Magnitude: ${minMag}` });
      } else if (!minMag && maxMag) {
        summary.push({ label: `Maximum Magnitude: ${maxMag}` });
      }
      return summary;
    }
    return undefined;
  };

  return (
    <Filter
      title={title || 'Magnitude'}
      value={value}
      name={name}
      onChange={onChange}
      summaryValues={renderSummaryValues()}
      onExpand={onExpand}
      expanded={expanded}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            translationKey="earthquake.minMag"
            name="minMag"
            aria-label="minMag"
            fullWidth
            value={value.minMagnitude || ''}
            onChange={(e) => onChange(e.target.value)}
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            translationKey="earthquake.maxMag"
            name="maxMag"
            aria-label="maxMag"
            fullWidth
            value={value.maxMagnitude || ''}
            onChange={(e) => onChange(e.target.value)}
            disabled={false}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

export default MagnitudeFilter;
