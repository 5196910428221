import {
  type UseQueryResult as ReactQueryResult,
  type UseQueryOptions as ReactQueryOptions,
  useQuery as ReactQuery,
} from '@tanstack/react-query';
import {
  type AxiosResponse,
  type AxiosRequestConfig,
  type AxiosPromise,
  AxiosError,
} from 'axios';
import { get } from 'util/WebRequest';

/**
 * Expected config, omitting the `signal` and `params` property. The `signal` is
 * expected to be provided by the `useQuery` hook. The `params` property is
 * defined as `any` by the base `AxiosRequestConfig`, so it is redefined here to
 * be type safe.
 *
 * @template Params - The type of the `params` property of the
 *   `AxiosRequestConfig`, redefined to be type safe. Becomes the query string
 *   of the URL. For services extending `DmasAPIService`, this becomes the
 *   `formParameters`.
 * @template RequestData - The type of the `data` property of the
 *   `AxiosRequestConfig`.
 */
export type RequestConfig<Params = undefined, RequestData = undefined> = Omit<
  AxiosRequestConfig<RequestData>,
  'signal' | 'params'
> & {
  params?: Params;
};

/**
 * A string or an array of strings and numbers that will be used as the path for
 * the request. If it's an array, the values will be concatenated. As per
 * `WebRequest`, the URL to connect to Oceans 3.0 services does not need to be
 * added.
 */
type Path = string | Array<string | number>;

/**
 * Concatenate the path if it is an array.
 *
 * @param path
 * @returns The concatenated path.
 */
const concatPath = (path: Path): string =>
  typeof path === 'string'
    ? path
    : path.map(String).join('/').replace(/\/+/g, '/');

/**
 * The options for the `useFetch` hook. It includes all the properties of
 * {@link ReactQueryOptions} except the `queryFn` as that is defined manually by
 * this wrapper to make requests using `get` from `WebRequest`. If this is not
 * desirable for your purposes, you can use the `useQuery` hook directly.
 *
 * It also mandates the inclusion of `queryKey`. For information on how to write
 * and use query keys, see the
 * {@link https://tanstack.com/query/v4/docs/framework/react/guides/query-keys|tanstack query keys documentation}.
 *
 * Refer to
 * {@link https://tanstack.com/query/v4/docs/framework/react/reference/useQuery|tanstack useQuery
 * documentation}
 * for more information about query options.
 */
export type QueryOptions<ResponseData, CustomError> = Omit<
  ReactQueryOptions<ResponseData, CustomError | AxiosError>,
  'queryFn'
> & {
  queryKey: NonNullable<ReactQueryOptions<ResponseData>['queryKey']>;
};

/**
 * The result of the `useFetch` hook. It includes all the properties of
 * {@link ReactQueryResult} where `data` is the `response` after it has been
 * transformed by the provided `transform` function. The
 * {@link https://tanstack.com/query/v4/docs/framework/react/reference/useQuery|tanstack useQuery
 * documentation}
 * provides details on the query result properties that aren't defined or
 * redefined here.
 *
 * @property data - The transformed `data` from the response. If no `transform`
 *   is provided, it is the value of the `data` property of the `response`.
 */
export type QueryResult<Data, CustomError extends Error = Error> = Omit<
  ReactQueryResult<AxiosResponse<Data, CustomError | AxiosError>>,
  'data'
> & {
  data: Data | undefined;
};

/**
 * The type of the `useFetch` hook. If no `transform` function is provided, the
 * type of the `data` property of the `QueryResult` is the same as the
 * `ResponseData`. If a `transform` function is provided, the type of the `data`
 * property is the return type of the `transform` function.
 */
type UseFetch = {
  <
    Data,
    Params = undefined,
    RequestData = undefined,
    CustomError extends Error = Error,
    ServicePath extends Path = Path,
    ResponseData extends AxiosResponse<Data> = AxiosResponse<Data>,
  >(
    servicePath: ServicePath,
    queryOptions: QueryOptions<Data, CustomError>,
    config: RequestConfig<Params, RequestData>,
    transform?: (response: ResponseData) => Data
  ): QueryResult<Data, CustomError>;
  <
    Data,
    Params,
    CustomError extends Error = Error,
    ServicePath extends Path = Path,
    ResponseData extends AxiosResponse<Data> = AxiosResponse<Data>,
  >(
    servicePath: ServicePath,
    queryOptions: QueryOptions<Data, CustomError>,
    config: RequestConfig<Params, undefined>,
    transform?: (response: ResponseData) => Data
  ): QueryResult<Data, CustomError>;
  <
    Data,
    RequestData,
    CustomError extends Error = Error,
    ServicePath extends Path = Path,
    ResponseData extends AxiosResponse<Data> = AxiosResponse<Data>,
  >(
    servicePath: ServicePath,
    queryOptions: QueryOptions<Data, CustomError>,
    config: RequestConfig<undefined, RequestData>,
    transform?: (response: ResponseData) => Data
  ): QueryResult<Data, CustomError>;

  <
    TransformedData,
    Params = undefined,
    RequestData = undefined,
    CustomError extends Error = Error,
    ServicePath extends Path = Path,
  >(
    servicePath: ServicePath,
    queryOptions: QueryOptions<TransformedData, CustomError>,
    config: RequestConfig<Params, RequestData>,
    transform: (response: AxiosResponse) => TransformedData
  ): QueryResult<TransformedData, CustomError>;
  <
    TransformedData,
    Params,
    CustomError extends Error = Error,
    ServicePath extends Path = Path,
  >(
    servicePath: ServicePath,
    queryOptions: QueryOptions<TransformedData, CustomError>,
    config: RequestConfig<Params, undefined>,
    transform: (response: AxiosResponse) => TransformedData
  ): QueryResult<TransformedData, CustomError>;
  <
    TransformedData,
    RequestData,
    CustomError extends Error = Error,
    ServicePath extends Path = Path,
  >(
    servicePath: ServicePath,
    queryOptions: QueryOptions<TransformedData, CustomError>,
    config: RequestConfig<undefined, RequestData>,
    transform: (response: AxiosResponse) => TransformedData
  ): QueryResult<TransformedData, CustomError>;
  <
    TransformedData,
    ResponseData,
    Params = undefined,
    RequestData = undefined,
    CustomError extends Error = Error,
    ServicePath extends Path = Path,
  >(
    servicePath: ServicePath,
    queryOptions: QueryOptions<TransformedData, CustomError>,
    config: RequestConfig<Params, RequestData>,
    transform: (response: AxiosResponse<ResponseData>) => TransformedData
  ): QueryResult<TransformedData, CustomError>;
  <
    TransformedData,
    ResponseData,
    Params,
    CustomError extends Error = Error,
    ServicePath extends Path = Path,
  >(
    servicePath: ServicePath,
    queryOptions: QueryOptions<TransformedData, CustomError>,
    config: RequestConfig<Params, undefined>,
    transform: (response: AxiosResponse<ResponseData>) => TransformedData
  ): QueryResult<TransformedData, CustomError>;
  <
    TransformedData,
    ResponseData,
    RequestData,
    CustomError extends Error = Error,
    ServicePath extends Path = Path,
  >(
    servicePath: ServicePath,
    queryOptions: QueryOptions<TransformedData, CustomError>,
    config: RequestConfig<undefined, RequestData>,
    transform: (response: AxiosResponse<ResponseData>) => TransformedData
  ): QueryResult<TransformedData, CustomError>;
};

/**
 * A wrapper for tanstack `useQuery` for making fetch requests to Oceans 3.0.
 * This simple wrapper allows you to make a request using `get` from
 * `WebRequest` with the `useQuery` hook.
 *
 * See the
 * {@link https://tanstack.com/query/v4/docs/framework/react/reference/useQuery|tanstack useQuery
 * documentation}
 * for more information about using `useQuery`.
 *
 * @param servicePath
 * @param queryOptions
 * @param config
 * @param transform
 * @returns
 */
const useFetch: UseFetch = <
  Data,
  ResponseData = unknown,
  Params = undefined,
  RequestData = undefined,
  CustomError extends Error = Error,
  ServicePath extends Path = Path,
>(
  servicePath: ServicePath,
  queryOptions: QueryOptions<Data, CustomError>,
  config: RequestConfig<Params, RequestData>,
  transform: (response: AxiosResponse<ResponseData>) => Data = undefined
) =>
  ReactQuery<Data, AxiosError | CustomError>({
    ...queryOptions,
    queryFn: ({ signal }) =>
      (
        get<ResponseData, Params, RequestData>(
          concatPath(servicePath),
          undefined,
          {
            ...(config ?? {}),
            signal,
          }
        ) as AxiosPromise
      ).then(transform || (({ data }) => data as Data)),
  });

export default useFetch;
