import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid } from 'base-components';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';

import OptionalReadOnlyField from './OptionalReadOnlyField';

const useStyles = makeStyles({
  formControlLabel: {
    alignItems: 'baseline',
  },
});

const EnvironmentSelector = (props) => {
  const { environment, value, onToggle } = props;
  const classes = useStyles();

  const environmentDataFields = value && (
    <>
      <OptionalReadOnlyField label="Referrer" value={environment.referrer} />
      <OptionalReadOnlyField label="User Agent" value={environment.userAgent} />
      <OptionalReadOnlyField
        label="Screen Resolution"
        value={environment.resolution}
      />
    </>
  );

  const handleToggle = (event) => onToggle(event.target.checked);

  return (
    <>
      <Grid item>
        <MarginedReadOnlyField
          labelText="Location"
          valueText={environment.location}
        />
      </Grid>

      <Grid item>
        <FormControlLabel
          className={classes.formControlLabel}
          checked={value}
          control={<Checkbox id="environment-field" onChange={handleToggle} />}
          label={
            <>
              <Grid item>Include data about your current environment</Grid>
              {environmentDataFields}
            </>
          }
        />
      </Grid>
    </>
  );
};

EnvironmentSelector.propTypes = {
  onToggle: PropTypes.func.isRequired,
  environment: PropTypes.shape({
    location: PropTypes.string.isRequired,
    referrer: PropTypes.string.isRequired,
    resolution: PropTypes.string.isRequired,
    userAgent: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.bool.isRequired,
};

export default EnvironmentSelector;
