import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Dropdown,
  FormControlLabel,
  Grid,
  LabelledCheckbox,
  TextField,
} from 'base-components';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import CookieUtils from 'util/CookieUtils';
import ButtonSet from './ButtonSet';
import TaxonButtonSetService from '../../../services/TaxonButtonSetService';

const styles = (theme) => ({
  buttonSetSelect: {
    width: `calc(100% - ${theme.spacing(2)})`,
    margin: theme.spacing(),
  },
  keyboardEntry: {
    width: '150px',
    margin: theme.spacing(),
  },
  autoSaveCheckBox: {
    margin: theme.spacing(),
  },
  showIndexCheckbox: {
    marginLeft: theme.spacing(),
  },
});

const BUTTON_SET_COOKIE = 'seatube-entry-button-set';
const INDEX_COOKIE = 'show-button-set-indexes';

class QuickButtonSet extends PureComponent {
  static propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      autoSaveCheckBox: PropTypes.string,
      buttonSetSelect: PropTypes.string,
      keyboardEntry: PropTypes.string,
      showIndexCheckbox: PropTypes.string,
    }).isRequired,
    currentButton: PropTypes.shape({}),
  };

  static defaultProps = {
    currentButton: undefined,
  };

  getInitialShowIndex = () => {
    const showIndexCookie = CookieUtils.getCookie(INDEX_COOKIE);
    if (showIndexCookie !== null) {
      return showIndexCookie;
    }
    return true;
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonSets: [],
      currentButtonSetIndex: null,
      keyboardEntry: '',
      currentButtonSet: null,
      autoSave: false,
      currentButton: undefined,
      showIndexes: this.getInitialShowIndex(),
    };
  }

  componentDidMount() {
    this.getButtonSets();
  }

  getButtonSets = () => {
    const { onError } = this.props;
    const cookieValue = CookieUtils.getCookie(BUTTON_SET_COOKIE);
    let preselectedIndex;
    let preselectedButtonSet;
    return TaxonButtonSetService.getAll(true)
      .then((response) => {
        const options = response;
        options.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        options.forEach((buttonSet, index) => {
          if (cookieValue === buttonSet.taxonButtonSetHdrId) {
            preselectedIndex = index;
            preselectedButtonSet = buttonSet.buttons;
          }
          options[index].value = index;
          options[index].label = buttonSet.name;
        });
        this.setState({
          buttonSets: options,
          currentButtonSetIndex: preselectedIndex,
          currentButtonSet: preselectedButtonSet,
        });
      })
      .catch((error) => {
        onError(error);
      });
  };

  handleButtonSetChange = (event) => {
    const { buttonSets } = this.state;
    CookieUtils.saveCookie(
      BUTTON_SET_COOKIE,
      buttonSets[event.target.value].taxonButtonSetHdrId
    );
    this.setState({
      currentButtonSetIndex: event.target.value,
      currentButtonSet: buttonSets[event.target.value].buttons,
    });
  };

  handleKeyboardEntryChange = (event) => {
    // Only allow positive integers (and zeros, and empty strings)
    const numberRegex = /^[1-9]+[0-9]*$/;
    if (event.target.value === '' || numberRegex.test(event.target.value)) {
      this.setState({ keyboardEntry: event.target.value });
    }
  };

  handleKeyboardEntryKeyPress = (event) => {
    const { onButtonClick, onError } = this.props;
    // Submit on 'enter' keypress, but only if the keyboard entry box isn't empty
    const { currentButtonSet, keyboardEntry, autoSave } = this.state;
    if (event.key === 'Enter' && keyboardEntry) {
      if (
        currentButtonSet[keyboardEntry - 1] !== undefined &&
        currentButtonSet[keyboardEntry - 1].label
      ) {
        onButtonClick(currentButtonSet[keyboardEntry - 1], autoSave);
      } else {
        onError(`'${keyboardEntry}' not a valid keyboard entry value`);
      }
      this.setState({ keyboardEntry: '' });
    }
  };

  handleAutoSaveChange = () => {
    this.setState((prevState) => ({ autoSave: !prevState.autoSave }));
  };

  handleShowIndexes = () => {
    const { showIndexes } = this.state;
    CookieUtils.saveCookie(INDEX_COOKIE, !showIndexes);
    this.setState({ showIndexes: !showIndexes });
  };

  render() {
    const {
      buttonSets,
      currentButtonSetIndex,
      keyboardEntry,
      currentButtonSet,
      autoSave,
      currentButton,
      showIndexes,
    } = this.state;
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item md={4} xs={12}>
            <Dropdown
              options={buttonSets}
              label="Select a Button Set"
              className={classes.buttonSetSelect}
              onChange={this.handleButtonSetChange}
              value={currentButtonSet ? currentButtonSetIndex : ''}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              onKeyPress={this.handleKeyboardEntryKeyPress}
              translationKey="seatube.keyboardEntry"
              className={classes.keyboardEntry}
              value={keyboardEntry}
              onChange={this.handleKeyboardEntryChange}
              InputLabelProps={{ shrink: !!keyboardEntry }}
              disabled={!currentButtonSet}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoSave}
                  onChange={this.handleAutoSaveChange}
                />
              }
              label="Auto Save"
              labelPlacement="end"
              className={classes.autoSaveCheckBox}
            />
            <LabelledCheckbox
              name="showIndexes"
              label="Show Button Indexes"
              onChange={this.handleShowIndexes}
              value={showIndexes}
              className={classes.showIndexCheckbox}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonSet
            currentButtonSet={currentButtonSet}
            currentButton={currentButton}
            autoSave={autoSave}
            showIndexes={showIndexes}
            {...rest}
          />
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(withSnackbars(QuickButtonSet));
