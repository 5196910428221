import { Dropdown, ErrorPage } from 'base-components';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';
import useGet from 'util/hooks/useDmasAPI/useGet';

type SiteDropdownProps = Omit<
  FormFieldType<React.ComponentProps<typeof Dropdown>>,
  'options'
>;

type SitePayload = [
  {
    sitename: string;
    siteid: string;
  },
];

const SiteDropdown = ({ ...props }: SiteDropdownProps) => {
  const siteData = useGet<SitePayload>('SiteService', { operation: undefined });

  if (siteData.isLoading) {
    return <FormDropdown {...props} options={[]} />;
  }

  if (siteData.error) {
    return <ErrorPage />;
  }

  const options = siteData.data?.map((option) => ({
    label: option.sitename,
    value: option.siteid,
    key: option.siteid,
  }));

  return <FormDropdown options={options} {...props} />;
};

export default SiteDropdown;
