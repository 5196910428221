import { useEffect, useState } from 'react';
import * as React from 'react';
import { Getter, Plugin, Action } from '@devexpress/dx-react-core';
import { getEmptyFilter, TableFilter } from './ToolbarFilter';

interface Props {
  filter: TableFilter;
  filterForm?: TableFilter;
  onChange: (filter: TableFilter) => void;
  onFormChange?: (filter: TableFilter) => void;
}

const ToolbarFilterState: React.VFC<Props> = ({
  filter,
  filterForm = undefined,
  onChange,
  onFormChange = undefined,
}: Props) => {
  const [filterFormState, setFilterFormState] = useState(
    filterForm || filter || getEmptyFilter()
  );
  const [filterState, setFilterState] = useState(filter || getEmptyFilter());
  const [open, setOpen] = useState(false);

  // Getters
  const getColumns = ({ columns }: any) => columns;
  const getRows = ({ rows }: any) => rows;
  const getFilterForm = () => filterFormState;
  const getFilter = () => filterState;
  const getOpen = () => open;
  const getFilterCount = () => {
    let count = 0;
    if (filter && filter.filterGroups) {
      filter.filterGroups.forEach((group) => {
        group.filterLines.forEach((line) => {
          if (line.column && line.operator) {
            count += 1;
          }
        });
      });
    }
    return count;
  };

  // Changers
  const changeFilterForm = (value) => {
    onFormChange ? onFormChange(value) : setFilterFormState(value);
  };
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const applyFilter = () => {
    onChange ? onChange(filterFormState) : setFilterState(filterFormState);
  };

  useEffect(() => {
    if (filterForm) {
      setFilterFormState(filterForm);
    }
  }, [filterForm]);

  useEffect(() => {
    if (filter) {
      setFilterState(filter);
    }
  }, [filter]);

  return (
    <Plugin name="ToolbarFilterState">
      <Getter name="columns" computed={getColumns} />
      <Getter name="rows" computed={getRows} />
      <Getter name="filterForm" computed={getFilterForm} />
      <Getter name="filter" computed={getFilter} />
      <Getter name="filterCount" computed={getFilterCount} />
      <Getter name="open" computed={getOpen} />

      <Action name="onChangeFilterForm" action={changeFilterForm} />
      <Action name="onApplyFilter" action={applyFilter} />
      <Action name="onOpen" action={onOpen} />
      <Action name="onClose" action={onClose} />
    </Plugin>
  );
};

export default ToolbarFilterState;
