import * as React from 'react';
import { Autocomplete, Grid } from 'base-components';
import { TimePeriodSelect } from 'domain/AppComponents/dropdowns/Dropdowns';
import LocationsAutocomplete from 'domain/AppComponents/dropdowns/LocationsAutocomplete';
import { DeleteIconButton } from 'domain/AppComponents/IconButtons';
import useFilteredDeviceCategories from 'domain/hooks/useFilteredDeviceCategories';
import useFilteredDevices from 'domain/hooks/useFilteredDevices';
import useFilteredProperties from 'domain/hooks/useFilteredProperties';
import DataSourceWidgetDatePicker from './DataSourceWidgetDatePicker';

export interface Device {
  dataRating: any[];
  deviceCategoryCode: string;
  deviceCode: string;
  deviceId: number;
  name: string;
}

export interface DeviceCategory {
  description: string;
  deviceCategoryCode: string;
  name: string;
}

export interface Location {
  deployments: number;
  depth: number;
  description: string;
  locationCode: string;
  name: string;
}

export interface Property {
  description: string;
  name: string;
  propertyCode: string;
}

interface Props {
  option: any;
  deviceValue: Device;
  deviceCategoryValue: DeviceCategory;
  locationValue: Location;
  propertyValue: Property;
  handleDeviceChange: (event: any, value: any) => void;
  handleDeviceCategoryChange: (event: any, value: any) => void;
  handleLocationChange: (event: any, value: any) => void;
  handlePropertyChange: (event: any, value: any) => void;
  handleShowLocationTree: () => void;
  handleTimeChange: (event: any) => void;
  isTreeOpen: boolean;
  startDate: Date;
  endDate: Date;
  timePeriod: string;
  handleStartDateChange: (event: any) => void;
  handleEndDateChange: (value: Date) => void;
  onRemoveFilter: (option: any, filterType: any) => void;
}

const DataSourceSelectionWidgetFilters: React.FC<Props> = (props: Props) => {
  const {
    option,
    deviceValue,
    deviceCategoryValue,
    locationValue,
    propertyValue,
    handleDeviceChange,
    handleDeviceCategoryChange,
    handleLocationChange,
    handlePropertyChange,
    handleShowLocationTree,
    handleTimeChange,
    isTreeOpen,
    startDate,
    endDate,
    timePeriod,
    handleStartDateChange,
    handleEndDateChange,
    onRemoveFilter,
  } = props;

  const filterType = option.name.toLowerCase().replaceAll(' ', '');

  const deviceOptions = useFilteredDevices(
    [deviceCategoryValue.deviceCategoryCode],
    propertyValue.propertyCode,
    locationValue.locationCode
  );
  const deviceCategories = useFilteredDeviceCategories(
    propertyValue.propertyCode,
    locationValue.locationCode,
    deviceValue.deviceCategoryCode
  );
  const properties = useFilteredProperties(
    deviceCategoryValue.deviceCategoryCode,
    deviceValue.deviceCode,
    locationValue.locationCode
  );

  return (
    <Grid item xs={12} key={filterType} container alignItems="center">
      <Grid item xs>
        {filterType === 'device' && (
          <Autocomplete
            options={deviceOptions}
            onChange={handleDeviceChange}
            virtualized
            noOptionsText="No Devices Available"
            getOptionLabel={(device) =>
              `${device.name} ${device.deviceId ? `(${device.deviceId})` : ''}`
            }
            translationKey="device.device"
            name="deviceAutocomplete"
            value={deviceValue}
          />
        )}
        {filterType === 'devicecategory' && (
          <Autocomplete
            options={deviceCategories}
            virtualized
            getOptionLabel={(deviceCategory) => `${deviceCategory.name}`}
            translationKey="device.deviceCategory"
            name="deviceCategoryAutocomplete"
            onChange={handleDeviceCategoryChange}
            value={deviceCategoryValue}
          />
        )}
        {filterType === 'location' && (
          <LocationsAutocomplete
            onChange={handleLocationChange}
            onClick={handleShowLocationTree}
            value={locationValue}
            deviceCategoryCode={deviceCategoryValue.deviceCategoryCode}
            propertyCode={propertyValue.propertyCode}
            deviceCode={deviceValue.deviceCode}
            showTree={isTreeOpen}
          />
        )}
        {filterType === 'property' && (
          <Autocomplete
            options={properties}
            noOptionsText="No Property Data Available"
            virtualized
            getOptionLabel={(property) => `${property.name}`}
            translationKey="dataSource.property"
            name="propertyAutocomplete"
            onChange={handlePropertyChange}
            value={propertyValue}
          />
        )}
        {filterType === 'timeperiod' && (
          <TimePeriodSelect value={timePeriod} onChange={handleTimeChange} />
        )}
      </Grid>
      <DeleteIconButton
        aria-label="Remove"
        size="small"
        onClick={() => onRemoveFilter(option, filterType)}
      />
      {filterType === 'timeperiod' && (
        <DataSourceWidgetDatePicker
          startDate={startDate}
          endDate={endDate}
          timePeriod={timePeriod}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
        />
      )}
    </Grid>
  );
};

export default DataSourceSelectionWidgetFilters;
