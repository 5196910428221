import DeviceCategory from 'domain/Apps/network-console/device-category/DeviceCategory';
import { post, get } from 'util/WebRequest';

const DEVICE_CATEGORY_SERVICE = 'DeviceCategoryService';

class DeviceCategoryService {
  static getAllDeviceCategories() {
    const parameters = {
      operation: 7,
    };
    return get(DEVICE_CATEGORY_SERVICE, parameters, null);
  }

  static create(newDeviceCategory: DeviceCategory) {
    const parameters = {
      payload: JSON.stringify({ deviceCategory: newDeviceCategory }),
      operation: 1,
    };
    return post(DEVICE_CATEGORY_SERVICE, parameters, null);
  }

  static update(newDeviceCategory: DeviceCategory) {
    const parameters = {
      payload: JSON.stringify({ deviceCategory: newDeviceCategory }),
      operation: 2,
    };
    return post(DEVICE_CATEGORY_SERVICE, parameters, null);
  }
}
export default DeviceCategoryService;
