import { CommunityFishersDevicePackage } from 'domain/Apps/community-fishers/CommunityFishersDevicePackageTypes';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class CommunityFishersDevicePackageService {
  static getAllDevicePackages(): Promise<CommunityFishersDevicePackage[]> {
    return get('CommunityFishersDevicePackageService').then((response) => {
      const devicePackages = parseDmasAPIResponse(response);
      return devicePackages.sort(
        (
          a: CommunityFishersDevicePackage,
          b: CommunityFishersDevicePackage
        ) => {
          const baseSetNameA = a?.setInformation?.setName;
          const baseSetNameB = b?.setInformation?.setName;
          if (baseSetNameA < baseSetNameB) {
            return -1;
          }
          if (baseSetNameA > baseSetNameB) {
            return 1;
          }
          return 0;
        }
      );
    });
  }
}
export default CommunityFishersDevicePackageService;
