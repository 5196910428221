import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  LabelledCheckbox,
  ListItem,
  withCircularProgress,
} from 'base-components';
import ResizingInfoList from 'library/CompositeComponents/list-container/ResizingInfoList';
import InfoItem from 'library/CompositeComponents/list-items/InfoItem';
import InfoItemDate from 'library/CompositeComponents/list-items/InfoItemDate';
import InfoItemNullable from 'library/CompositeComponents/list-items/InfoItemNullable';
import InfoItemScrollable from 'library/CompositeComponents/list-items/InfoItemScrollable';
import InfoItemWithIcon from 'library/CompositeComponents/list-items/InfoItemWithIcon';
import OrganizationLogo from 'library/CompositeComponents/logo/OrganizationLogo';

class DiveDetailsTemplate extends PureComponent {
  static propTypes = {
    diveDetails: PropTypes.shape({
      area: PropTypes.string,
      ready: PropTypes.string,
      diveId: PropTypes.string,
      cruiseName: PropTypes.string,
      currentDive: PropTypes.string,
      dateFrom: PropTypes.instanceOf(Date),
      dateTo: PropTypes.instanceOf(Date),
      diveComment: PropTypes.string,
      organizationId: PropTypes.number,
      referenceDiveId: PropTypes.string,
      scientistId: PropTypes.string,
      camera: PropTypes.shape({
        name: PropTypes.string,
      }),
      platform: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    showExtraDiveInfo: PropTypes.bool,
  };

  static defaultProps = {
    diveDetails: undefined,
    showExtraDiveInfo: false,
  };

  renderExtraInfo = () => {
    const { showExtraDiveInfo, diveDetails } = this.props;
    const { platform, camera } = diveDetails;
    if (showExtraDiveInfo) {
      return (
        <>
          <InfoItem
            primary="Platform"
            secondary={platform ? platform.name : '(Not Set)'}
          />
          <InfoItem
            primary="Default Camera"
            secondary={camera ? camera.name : '(Not Set)'}
          />
          <ListItem>
            <LabelledCheckbox
              disabled
              label="SeaTube Ready"
              value={diveDetails.ready}
            />
          </ListItem>
        </>
      );
    }
    return undefined;
  };

  render() {
    const { diveDetails, showExtraDiveInfo } = this.props;
    if (!diveDetails) {
      return null;
    }
    return (
      <ResizingInfoList
        headerContent={
          <InfoItemWithIcon>
            <OrganizationLogo id={diveDetails.organizationId} />
            <InfoItem primary="Dive" secondary={diveDetails.referenceDiveId} />
          </InfoItemWithIcon>
        }
      >
        {showExtraDiveInfo ? (
          <InfoItem
            primary="Dive Id"
            secondary={diveDetails.diveId.toString()}
          />
        ) : null}
        <InfoItem primary="Expedition" secondary={diveDetails.cruiseName} />
        <InfoItem primary="Dive Lead" secondary={diveDetails.scientistId} />
        <InfoItemNullable primary="Area" secondary={diveDetails.area} />
        <InfoItemDate primary="Start Date" date={diveDetails.dateFrom} />
        <InfoItemDate primary="End Date" date={diveDetails.dateTo} />
        <InfoItemScrollable
          primary="Dive Plan"
          secondary={diveDetails.diveComment}
        />
        {this.renderExtraInfo()}
      </ResizingInfoList>
    );
  }
}

export default withCircularProgress(DiveDetailsTemplate);
