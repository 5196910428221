import { memo } from 'react';
import * as React from 'react';
import DetailsTemplate from 'library/CompositeComponents/details/DetailsTemplate';
import InfoItem from 'library/CompositeComponents/list-items/InfoItem';
import InfoItemNullable from 'library/CompositeComponents/list-items/InfoItemNullable';
import InfoItemScrollable from 'library/CompositeComponents/list-items/InfoItemScrollable';

interface Props {
  parsingOption: string;
  example: string;
  systemExample?: string; // some chat log formats may not have system messages
  additionalInfo: string;
}

const ParsingOptionsDetailsTemplate: React.VFC<Props> = ({
  parsingOption,
  example,
  systemExample = undefined,
  additionalInfo,
}) => (
  <DetailsTemplate
    contents={[
      <InfoItem primary="Parsing Option" secondary={parsingOption} />,
      <InfoItem primary="Example" secondary={example} />,
      <InfoItemNullable
        primary="Example (System Message)"
        secondary={systemExample}
      />,
      <InfoItemScrollable
        primary="Additional Information"
        secondary={additionalInfo}
      />,
    ]}
  />
);

export default memo(ParsingOptionsDetailsTemplate);
