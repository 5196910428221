import { memo, useMemo } from 'react';
import { BatchServiceResponse } from 'domain/AppComponents/batch/types/BatchServiceResponse';
import getBatchRows from 'domain/AppComponents/batch/util/BatchTablesRowCreationLogic';
import {
  BATCH_TABLE_COLUMNS,
  BATCH_TABLE_COLUMN_FORMATTER,
} from 'domain/AppComponents/batch/util/BatchUtils';
import { SortingProps } from 'library/BaseComponents/table/plugins/ColumnSorting';
import { PagingProps } from 'library/BaseComponents/table/plugins/Pagination';
import StatelessTable from 'library/BaseComponents/table/StatelessTable';

const BatchTable = ({
  data = { records: [], totalRecords: 0 },
  paging,
  sorting,
  setSelectedBatchId,
  selectedTaskId,
}: {
  data?: BatchServiceResponse;
  paging: PagingProps;
  sorting: SortingProps;
  setSelectedBatchId: (newTaskId: number) => void;
  selectedTaskId: number;
}) => {
  const rows = useMemo(() => getBatchRows(data.records), [data.records]);

  return (
    <StatelessTable
      rows={rows}
      getRowId={(row) => row.taskId}
      columns={BATCH_TABLE_COLUMNS}
      paging={paging}
      sort={sorting}
      selection={{
        selection: [selectedTaskId],
        onChange: (selected: number[]) => {
          if (selected[1] !== selectedTaskId) {
            setSelectedBatchId(selected[1]);
          }
        },
        highlightRow: true,
        selectByRowClick: true,
        showSelectionColumn: false,
      }}
      showNumberOfRecords={false}
      columnFormatProviders={BATCH_TABLE_COLUMN_FORMATTER}
    />
  );
};

export default memo(BatchTable);
