import { memo, useState } from 'react';
import { Add } from '@onc/icons';
import BatchTaskAddDialog from 'domain/AppComponents/batch/dialog/BatchTaskAddDialog';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';

const BatchTaskAddContainer = ({
  selectedBatchId = undefined,
}: {
  selectedBatchId?: number;
}) => {
  const [openBatchTaskDialog, setOpenTaskBatchDialog] =
    useState<boolean>(false);
  return (
    <>
      <BatchTaskAddDialog
        open={openBatchTaskDialog}
        onClose={() => setOpenTaskBatchDialog(false)}
        selectedBatchId={selectedBatchId}
      />
      <ContainedButton
        translationKey="taskmanagement.addBatchTask"
        startIcon={<Add />}
        onClick={() => {
          setOpenTaskBatchDialog(true);
        }}
        disabled={!selectedBatchId}
      />
    </>
  );
};

export default memo(BatchTaskAddContainer);
