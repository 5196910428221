import { createStyles, makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle } from 'base-components';
import {
  CancelButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import AudioPlayerWidgetConfigFormContents from './AudioPlayerWidgetFormContents';

const useStyles = makeStyles(() =>
  createStyles({
    formButtons: {
      marginTop: 10,
      float: 'right',
    },
    titleField: {
      marginTop: 0,
    },
  })
);

type AudioPlayerWidgetConfigValues = {
  audioFormat: string;
  clipSelectorValue: 'latestClip' | 'timeRange';
  dataSourceType: 'location' | 'device';
  device: any;
  endDate: Moment;
  isBroadcasting: boolean;
  locations: any;
  loopPlayback: boolean;
  startDate: Moment;
  showTitle: boolean;
  title: string;
};

type AudioPlayerWidgetConfigForm = AudioPlayerWidgetConfigValues;

type AudioPlayerWidgetConfigProps = {
  onCancel: () => void;
  onSave: (values: any) => void;
} & AudioPlayerWidgetConfigValues;

const AudioPlayerWidgetConfig = ({
  onCancel,
  onSave,
  startDate,
  endDate,
  ...rest
}: AudioPlayerWidgetConfigProps) => {
  const formMethods = useForm<AudioPlayerWidgetConfigForm>({
    values: {
      startDate: startDate ? moment(startDate) : undefined,
      endDate: endDate ? moment(endDate) : undefined,
      ...rest,
    },
    mode: 'onBlur',
  });

  const classes = useStyles();

  const { watch } = formMethods;
  const showTitle = watch('showTitle');
  const locations = watch('locations');
  const device = watch('device');
  const clipSelectorValue = watch('clipSelectorValue');
  const dataSourceType = watch('dataSourceType');
  const audioFormat = watch('audioFormat');

  return (
    <Dialog open fullWidth>
      <DialogTitle>Configure Audio Player Widget</DialogTitle>
      <DialogContent>
        <Form onSubmit={onSave} formMethods={formMethods}>
          <AudioPlayerWidgetConfigFormContents
            audioFormat={audioFormat}
            clipSelectorValue={clipSelectorValue}
            dataSourceType={dataSourceType}
            device={device}
            endDate={moment(endDate)}
            isBroadcasting={rest.isBroadcasting}
            locations={locations}
            loopPlayback={rest.loopPlayback}
            startDate={moment(startDate)}
            showTitle={showTitle}
            title={rest.title}
          />
          <div className={classes.formButtons}>
            <CancelButton onClick={onCancel} />
            <SaveButton />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AudioPlayerWidgetConfig;
