import { get } from 'util/WebRequest';

const DEVICE_SERVICE = 'DeviceService';
const DEVICE_CATEGORY = 100;
const SITE_DEVICE = 2;
let iterator = 0;

class DeviceService {
  // Used to append a unique id to each tree node
  static appendNodeIdToTree(
    data,
    pathName,
    stationCode,
    deviceCategoryCode,
    deviceCategoryName,
    datasetRef,
    sitedeviceid,
    deviceId
  ) {
    const newTree = data;
    for (const i in data) {
      if (data[i]) {
        const item = data[i];
        item.stationCode = item.stationCode ? item.stationCode : stationCode;
        item.sitedeviceid = item.sitedeviceid
          ? item.sitedeviceid
          : sitedeviceid;
        item.deviceId = item.type === SITE_DEVICE ? item.id : deviceId;
        item.siteDeviceList = [];
        item.datasetRef = item.datasetReferenceFlag ? item.name : datasetRef;
        item.deviceCategoryName =
          item.type === DEVICE_CATEGORY ? item.name : deviceCategoryName;
        item.deviceCategoryCode = item.deviceCategoryCode
          ? item.deviceCategoryCode
          : deviceCategoryCode;
        item.nodeId = iterator.toString();
        const path = [...pathName, item.name];
        item.pathName = path;
        iterator += 1;
        if (item.els && item.els.length > 0) {
          this.appendNodeIdToTree(
            item.els,
            item.pathName,
            item.stationCode,
            item.deviceCategoryCode,
            item.deviceCategoryName,
            item.datasetRef,
            item.sitedeviceid,
            item.deviceId
          );
        }
        item.pathName.shift();
      }
    }
    return newTree;
  }

  static async getSearchTree(onError) {
    try {
      const response = await get(DEVICE_SERVICE, {
        operationtype: '26',
        network: '8',
        effectivedate: new Date().toISOString(),
        compress: true,
      });
      const tree = this.appendNodeIdToTree(response.data.payload.devices, []);
      return tree;
    } catch (error) {
      onError(error);
    }
    return false;
  }

  static getDevices() {
    return get(DEVICE_SERVICE, { operationtype: 24 });
  }

  static async getFixedSearchTreeNodeDevicesByDeviceCategory(
    searchTreeNodeId,
    deviceCategoryIds,
    onError
  ) {
    try {
      const response = await get(DEVICE_SERVICE, {
        operationtype: '28',
        searchTreeNodeId,
        deviceCategoryIds,
      });
      return response.data.payload;
    } catch (error) {
      onError(error);
    }
    return false;
  }
}
export default DeviceService;
