import Environment from 'util/Environment';

interface WebRequestError extends Error {
  config: any;
  code?: string;
  request?: any;
  response?: any;
}

class TokenUtils {
  static getTokenError(errors: WebRequestError) {
    let errorArray: any[] = [
      {
        parameter: String,
      },
    ];
    // improve readability
    if (errors.response && errors.response.data) {
      errorArray = errors.response.data.errors;
    }

    if (errorArray && errorArray.length > 0) {
      // add all applicable messages to empty string
      let customMessage = 'Token Error: ';
      // create a new error and modify message to include token errors
      const customError = errors;
      for (let i = 0; i < errorArray.length; i += 1) {
        // if current error is a token error, add it to message
        if (errorArray[i].parameter) {
          if (errorArray[i].parameter === 'token') {
            customMessage += `Your token has changed or has been disabled: ${Environment.getDmasUserToken()}. `;
          }
          if (errorArray[i].parameter === 'appToken') {
            customMessage +=
              'Data retrieval for the dashboard has been diabled. ';
          }
        }
      }
      // there was no token errors
      if (customMessage === 'Token Error: ') {
        customMessage = '';
      }
      // if there is an existing message, add a leading space
      if (customError.message !== undefined && customMessage) {
        customMessage = `. ${customMessage}`;
      }
      // add token errors to error message
      customError.message += customMessage;
      return customError;
    }
    // return error as is
    return errors;
  }
}
export default TokenUtils;
