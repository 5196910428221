import * as React from 'react';
import { Autocomplete } from 'base-components';
import { CruiseJSON } from 'domain/services/CruiseService';

type Props = {
  onChange: (e: any, value: CruiseJSON) => void;
  value: CruiseJSON | null;
  options: CruiseJSON[];
};

const CruiseAutocomplete: React.VFC<Props> = (props: Props) => {
  const { onChange, value, options } = props;
  return (
    <Autocomplete
      fullWidth
      onChange={onChange}
      value={value}
      translationKey="seatube.expedition"
      name="expedition-autocomplete"
      options={options}
      getOptionLabel={(option) => option.cruiseName}
      isOptionEqualToValue={(a: CruiseJSON, b: CruiseJSON) =>
        a.cruiseId === b.cruiseId
      }
    />
  );
};

export default CruiseAutocomplete;
