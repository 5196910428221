import * as L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import blackIcon from './images/marker-icon-black.png';
import greenIcon from './images/marker-icon-green.png';
import greyIcon from './images/marker-icon-grey.png';
import orangeIcon from './images/marker-icon-orange.png';
import redIcon from './images/marker-icon-red.png';
import unassignedIcon from './images/marker-icon-unassigned.png';
import violetIcon from './images/marker-icon-violet.png';
import yellowIcon from './images/marker-icon-yellow.png';

const iconSize = [24, 36] as L.PointTuple;
const iconAnchor = [12, 36] as L.PointExpression;

// Have to override Marker icon because leaflet-react and importing leaflet css collides.
// See https://github.com/PaulLeCam/react-leaflet/issues/453
export default class LeafletIcon {
  static DEFAULT = L.icon({
    iconUrl: markerIcon,
    shadowUrl: iconShadow,
    iconSize,
    iconAnchor,
  });

  static RED = L.icon({
    iconUrl: redIcon,
    shadowUrl: iconShadow,
    iconSize,
    iconAnchor,
  });

  static ORANGE = L.icon({
    iconUrl: orangeIcon,
    shadowUrl: iconShadow,
    iconSize,
    iconAnchor,
  });

  static VIOLET = L.icon({
    iconUrl: violetIcon,
    shadowUrl: iconShadow,
    iconSize,
    iconAnchor,
  });

  static YELLOW = L.icon({
    iconUrl: yellowIcon,
    shadowUrl: iconShadow,
    iconSize,
    iconAnchor,
  });

  static GREY = L.icon({
    iconUrl: greyIcon,
    shadowUrl: iconShadow,
    iconSize,
    iconAnchor,
  });

  static BLACK = L.icon({
    iconUrl: blackIcon,
    shadowUrl: iconShadow,
    iconSize,
    iconAnchor,
  });

  static GREEN = L.icon({
    iconUrl: greenIcon,
    shadowUrl: iconShadow,
    iconSize,
    iconAnchor,
  });

  static UNASSIGNED = L.icon({
    iconUrl: unassignedIcon,
    shadowUrl: iconShadow,
    iconSize,
    iconAnchor,
  });
}
