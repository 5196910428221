import { Component } from 'react';
import { TableOld as Table, TableOldProps } from 'base-components';

type Column = {
  name: string;
  title?: string;
};

type TableProps = TableOldProps & {
  columns: Column[];
  rows: Record<string, any>[];
  treeColumn: string;
  defaultExpandedRowIds?: number[];
  getChildRows?: (row: any, rootRows: any[]) => any[];
};

// @deprecated prefer stateless table
class TreeTable extends Component<TableProps> {
  static defaultProps = {
    defaultExpandedRowIds: [],
    getChildRows: undefined,
  };

  render() {
    const { treeColumn, defaultExpandedRowIds, getChildRows } = this.props;
    return (
      <Table
        treeColumn={treeColumn}
        defaultExpandedRowIds={defaultExpandedRowIds}
        getChildRows={getChildRows}
        {...this.props}
      />
    );
  }
}

export default TreeTable;
