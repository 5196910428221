import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class ItemService {
  static getMany() {
    return ItemService.get({ operation: 5 });
  }

  static getOne(itemId) {
    return ItemService.get({ operation: 4, itemId });
  }

  static get(params) {
    return get('CableItemService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(params) {
    return post('CableItemService', {
      operation: 1,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static update(params) {
    return post('CableItemService', {
      operation: 2,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static delete = (itemId) =>
    post('CableItemService', {
      operation: 3,
      itemId,
    }).then((response) => parseDmasAPIResponse(response));
}
export default ItemService;
