import TreeMenu from 'library/CompositeComponents/tree-menu/TreeMenu';

import VideoTreeItem from './VideoTreeItem';

export interface UiTreeNode {
  html: string;
  title: string;
  children: UiTreeNode[];
  type: string;
  xtype: string | null;
}

export interface ExpeditionTreeNode extends UiTreeNode {
  id: number;
  nodeId: number;
  children: ExpeditionTreeNode[];
}

type Props = {
  onMenuItemClicked: () => void;
  onWatchButtonClicked: () => void;
  onSearchButtonClicked: () => void;
  onDeckLogButtonClicked: () => void;
  treeConfig: ExpeditionTreeNode[] | null;
  selectedNodeId: number;
};

const ExpeditionTree = ({
  treeConfig,
  onMenuItemClicked,
  onSearchButtonClicked,
  onWatchButtonClicked,
  onDeckLogButtonClicked,
  selectedNodeId,
}: Props) => {
  if (!treeConfig) return <TreeMenu />;

  return (
    <TreeMenu>
      <VideoTreeItem
        config={treeConfig}
        onMenuItemClicked={onMenuItemClicked}
        onSearchButtonClicked={onSearchButtonClicked}
        onWatchButtonClicked={onWatchButtonClicked}
        onDeckLogButtonClicked={onDeckLogButtonClicked}
        selectedNodeId={selectedNodeId}
      />
    </TreeMenu>
  );
};

export default ExpeditionTree;
