/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useCallback } from 'react';
import { DragDropContext, Droppable, useMouseSensor } from '@hello-pangea/dnd';

import { makeStyles } from '@mui/styles';
import { DragHandle } from '@onc/icons';
import DraggableListItem from '../list-items/DraggableListItem';

interface BaseItem {
  id: string;
  selected?: boolean;
  disabled?: boolean;
}

interface Props<T extends BaseItem> {
  /**
   * The ordered list of items to be rendered. Note that it should have an id
   * property.
   */
  items: T[];
  dragHandle?: JSX.Element;
  /** Event that is fired when the order of the list changes. */
  onChange(items: T[]): void;
  /** Render function for each item in the list. */
  renderItem(item: T): ReactNode;
}

const DefaultHandle = <DragHandle color="action" sx={{ margin: '8px' }} />;

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

/** This function is used for rendering a "clone" of the item being dragged. */
const getRenderItem =
  (items, renderFn, dragHandle) => (provided, snapshot, rubric) => {
    const { draggableProps, innerRef } = provided;
    return (
      <div {...draggableProps} ref={innerRef}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {dragHandle}
          {renderFn(items[rubric.source.index])}
        </div>
      </div>
    );
  };

export default function SortableList<T extends BaseItem>({
  items,
  dragHandle = DefaultHandle,
  onChange,
  renderItem,
}: Props<T>) {
  const classes = useStyles();

  // a little function to help us with reordering the result
  const reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const renderListItem = (item, index, handleIcon) => (
    <DraggableListItem
      key={item.id}
      id={item.id}
      index={index}
      isDragDisabled={item.disabled}
      HandleComponent={handleIcon}
      containerClass={`${item.selected ? classes.selected : ''}`}
    >
      {renderItem(item)}
    </DraggableListItem>
  );

  const handleDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;
      if (!destination) {
        return;
      }

      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }
      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );
      onChange(newItems);
    },
    [items, onChange]
  );

  const clonedItem = getRenderItem(items, renderItem, dragHandle);

  return (
    <DragDropContext sensors={[useMouseSensor]} onDragEnd={handleDragEnd}>
      <Droppable droppableId="dnd-list" renderClone={clonedItem}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) =>
              renderListItem(item, index, dragHandle)
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
