import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import SelectedPin from 'assets/icon/map/annotationPin.png';
import {
  divIcon as DivIcon,
  Marker,
  MarkerClusterGroup,
  Point,
  LeafletTooltip as Tooltip,
  icon as Icon,
} from 'base-components';
import CastPopupv2 from './CastPopupv2';

const styles = (theme) => ({
  controlLayersContainer: {
    maxWidth: '520px',
    margin: '10px 5px 5px 15px',
    padding: theme.spacing(),
    backgroundColor: '#e0e0e0',
    borderRadius: '4px',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px',
  },
  dateToPicker: {
    marginTop: '5px',
  },
  filterButtonContainer: {
    textAlign: 'end',
  },
  hideFilter: {
    transform: 'rotate(180deg)',
  },
  popper: {
    zIndex: 700,
  },
  helpMenu: {
    display: 'block',
  },
});
const UNASSIGNED_CAST_SEARCH_TREE_NODE_ID = 1852;

const unassignedMapIcon = Icon({
  iconUrl: SelectedPin,
  iconSize: [30, 40],
  iconAnchor: [15, 39],
});

class UnassignedCFOverlayv2 extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    filterRange: PropTypes.shape({
      dateFrom: PropTypes.instanceOf(Date),
      dateTo: PropTypes.instanceOf(Date),
    }),
    unassignedCastData: PropTypes.arrayOf(
      PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        referenceLat: PropTypes.number,
        referenceLon: PropTypes.number,
      })
    ),
  };

  static defaultProps = {
    filterRange: { dateFrom: undefined, dateTo: undefined },
    unassignedCastData: undefined,
  };

  createClusterIcon = (cluster) =>
    DivIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: 'custom-marker-cluster green-marker-cluster',
      iconSize: Point(33, 33, true),
    });

  filterCastDates = (casts) => {
    const { filterRange } = this.props;
    const { dateTo, dateFrom } = filterRange;
    const filteredCasts = casts.filter(
      (cast) =>
        (!dateFrom ||
          new Date(cast.startDate).getTime() >= dateFrom.getTime()) &&
        (!dateTo || new Date(cast.startDate).getTime() <= dateTo.getTime())
    );

    // sort by most recent
    filteredCasts.sort(
      (a, b) =>
        new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    );
    return filteredCasts;
  };

  renderDataLayer = () => {
    const { onError, onInfo, unassignedCastData } = this.props;
    if (!unassignedCastData) return undefined;
    return this.filterCastDates(unassignedCastData).map((cast) => {
      const {
        siteDeviceSubsetName,
        referenceLon,
        referenceLat,
        siteDeviceSubsetId,
      } = cast;

      const position = [referenceLat, referenceLon];
      return (
        <Marker
          className="unassigned_cast_marker"
          position={position}
          icon={unassignedMapIcon}
          key={siteDeviceSubsetId}
        >
          <Tooltip permanent direction="right" maxZoom={0} interactive>
            {siteDeviceSubsetName || siteDeviceSubsetId}
          </Tooltip>
          <CastPopupv2
            code={siteDeviceSubsetName}
            name="Unassigned"
            searchTreeNodeId={UNASSIGNED_CAST_SEARCH_TREE_NODE_ID}
            casts={[cast]}
            onError={onError}
            onInfo={onInfo}
            isAssigned={false}
          />
        </Marker>
      );
    });
  };

  render() {
    return (
      <MarkerClusterGroup
        iconCreateFunction={this.createClusterIcon}
        showCoverageOnHover={false}
      >
        {this.renderDataLayer()}
      </MarkerClusterGroup>
    );
  }
}

export default withStyles(styles)(UnassignedCFOverlayv2);
