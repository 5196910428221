import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';
import { Moment } from 'moment';
import { AddAPhoto, InfoOutlined } from '@onc/icons';
import { DenseThemeProvider, oceans3ThemeDark } from '@onc/theme';
import { PipWindow, NestedMenuItem } from 'base-components';
import VideoInformationDialog from 'domain/AppComponents/video/VideoInformationDialog';
import { SeaTubeResolution } from 'domain/services/SeaTubeVideoService';
import QualityMenuItem from 'library/CompositeComponents/video/controls/QualityMenuItem';
import Environment from 'util/Environment';
import usePipWindow from 'util/hooks/usePipWindow';
import { useSnackbars } from 'util/hooks/useSnackbars';
import ControlPanel from './ControlPanel';
import PictureInPictureButton from './controls/PictureInPictureButton';
import PlaybackRateMenuItem from './controls/PlaybackRateMenuItem';
import PlayPauseButton from './controls/PlayPauseButton';
import RecordVideoButton from './controls/RecordVideoButton';
import OncVideoPlayer, { VideoProgress } from './OncVideoPlayer';
import SeamlessVideoSlider from './SeamlessVideoSlider';
import { SeamlessVideoFile, findSeekPosition } from './SeamlessVideoUtil';
import SeamlessVideoVolume from './SeamlessVideoVolume';
import Snapshots from './Snapshots';
import VideoRestartButton from './VideoRestartButton';
import VideoSeekOptions from './VideoSeekOptions';
import VideoShareControl from './VideoShareControl';

export type SeamlessVideoProps = {
  id: string;
  startDate: Moment;
  files: SeamlessVideoFile[];
  qualityOptions?: SeaTubeResolution[];
  currentQuality: string;
  seekToDate?: Moment;
  onProgress?: (timestamp: Moment) => void;
  onChangeQuality?: (quality: string) => void;
  onSeekComplete?: () => void;
  shareUrl?: string;
};

const SeamlessVideo: React.FC<SeamlessVideoProps> = ({
  id,
  files,
  seekToDate = undefined,
  startDate,
  qualityOptions = undefined,
  currentQuality,
  onProgress = () => {},
  onChangeQuality = () => {},
  onSeekComplete = () => {},
  shareUrl = undefined,
}) => {
  const { onError } = useSnackbars();
  const isLoggedIn = Environment.isUserLoggedIn();

  // State Variables
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [seekTo, setSeekTo] = useState<number | null>(null);
  const [currentTimestamp, setCurrentTimestamp] = useState(startDate);
  const [isSeeking, setIsSeeking] = useState(false);
  const [localSeekToDate, setLocalSeekToDate] = useState<Moment | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [volume, setVolume] = useState(0);
  const [metadata, setMetadata] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0,
  });

  const [openSnapshotDialog, setOpenSnapshotDialog] = useState(false);
  const [snapshotTime, setSnapshotTime] = useState<Moment | null>(null);
  const [playbackRate, setPlaybackRate] = useState(1);
  const lastFile = files[files.length - 1];
  const endDate = startDate
    .clone()
    .add(lastFile.duration + lastFile.offset, 'seconds');
  // Use refs to store the previous values of seekToDate and files
  const prevSeekToDateRef = useRef(seekToDate);
  const prevLocalSeekToDateRef = useRef(localSeekToDate);
  const prevFilesRef = useRef(files);
  const containerRef = useRef<HTMLDivElement>(null);

  const memoizedUrl = useMemo(() => {
    if (
      _.isEqual(
        files[currentFileIndex]?.url,
        prevFilesRef.current[currentFileIndex]?.url
      )
    ) {
      prevFilesRef.current = files;
      return prevFilesRef.current[currentFileIndex]?.url;
    }
    return files[currentFileIndex]?.url;
  }, [currentFileIndex, files]);

  const { isSupported, requestPipWindow, pipWindow, closePipWindow } =
    usePipWindow();

  const startPip = useCallback(() => {
    requestPipWindow(640, 360);
  }, [requestPipWindow]);

  useEffect(() => {
    if (seekToDate && seekToDate !== currentTimestamp) {
      setCurrentTimestamp(seekToDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleSeek = (seekDate: Moment | null) => {
      if (!seekDate) return;

      setCurrentTimestamp(seekDate);
      // Calculate the total seconds from the start date to the seek date
      const totalSeconds = seekDate.diff(startDate, 'seconds');
      const { fileIndex, localSeekTo, seekError } = findSeekPosition(
        files,
        totalSeconds
      );
      if (seekError) {
        onError(seekError);
      }
      setCurrentFileIndex(fileIndex);
      setSeekTo(localSeekTo);
    };

    if (seekToDate && seekToDate !== prevSeekToDateRef.current) {
      handleSeek(seekToDate);
    }

    if (
      localSeekToDate &&
      !localSeekToDate.isSame(prevLocalSeekToDateRef?.current)
    ) {
      handleSeek(localSeekToDate);
      setLocalSeekToDate(null);
    }
    prevLocalSeekToDateRef.current = localSeekToDate;
    prevSeekToDateRef.current = seekToDate;
    // files not needed here, it's triggered in the useEffect below to change localSeekToDate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSeekToDate, onError, seekToDate]);

  useEffect(() => {
    if (files && files !== prevFilesRef.current) {
      setLocalSeekToDate(currentTimestamp);
    }
    prevFilesRef.current = files;
  }, [currentTimestamp, files, startDate]);

  const handleProgress = (progress: VideoProgress) => {
    if (isSeeking) return;
    const updatedTimestamp = startDate
      .clone()
      .add(files[currentFileIndex].offset * 1000, 'milliseconds')
      .add(progress.playedSeconds * 1000, 'milliseconds');
    setCurrentTimestamp(updatedTimestamp);
    onProgress(updatedTimestamp);

    // Check if the played seconds have reached the duration of the current file
    if (progress.played >= 1) {
      const nextFileIndex = currentFileIndex + 1;
      if (nextFileIndex < files.length) {
        // Move to the next file and reset the seek position
        setCurrentFileIndex(nextFileIndex);
        setSeekTo(0);
      }
    }
  };

  const handleSeekComplete = () => {
    // Reset the seek position after the seek is completed
    setIsSeeking(false);
    setSeekTo(null);
    onSeekComplete();
  };

  const handleForward = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newTimestamp = currentTimestamp.add(30, 'seconds');
    const targetTimestamp = newTimestamp.isAfter(endDate)
      ? endDate
      : newTimestamp;
    setCurrentTimestamp(targetTimestamp);
    setLocalSeekToDate(targetTimestamp);
    e.stopPropagation();
  };

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newTimestamp = currentTimestamp.subtract(30, 'seconds');
    const targetTimestamp = newTimestamp.isBefore(startDate)
      ? startDate
      : newTimestamp;
    setCurrentTimestamp(targetTimestamp);
    setLocalSeekToDate(targetTimestamp);
    e.stopPropagation();
  };

  const handleFullscreenToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const renderSeekbar = () => (
    <SeamlessVideoSlider
      startDate={startDate}
      endDate={endDate}
      currentTimestamp={currentTimestamp}
      setIsSeeking={setIsSeeking}
      setSeekToDate={setLocalSeekToDate}
    />
  );
  const renderSnapshots = () => (
    <Snapshots
      takeSnapshots={openSnapshotDialog}
      deviceId={files[currentFileIndex].deviceId}
      time={snapshotTime.toISOString()}
      onSnapshotComplete={() => setOpenSnapshotDialog(false)}
      getFullscreen={() => !!document.fullscreenElement}
      videoContainer={containerRef.current}
    />
  );

  const buildRightControls = () => {
    const rightControls = [];
    if (isLoggedIn) {
      rightControls.push(
        <RecordVideoButton
          deviceId={files[0].deviceId}
          currentTimestamp={currentTimestamp}
          onIsPlaying={setIsPlaying}
          containerRef={containerRef.current}
        />
      );
    }

    if (isSupported) {
      rightControls.push(
        <PictureInPictureButton
          onClick={pipWindow ? closePipWindow : startPip}
        />
      );
    }

    rightControls.push(
      <VideoShareControl
        containerRef={containerRef.current}
        disabled={!!document.fullscreenElement}
        shareUrl={shareUrl}
      />
    );
    return rightControls;
  };

  const getInfoMap = (): Map<string, any> => {
    const infoMap = new Map()
      .set('Device Id', files[currentFileIndex]?.deviceId)
      .set('File Name', files[currentFileIndex]?.fileName)
      .set('File Path', files[currentFileIndex]?.path);

    if (metadata.height && metadata.width) {
      infoMap.set('Resolution', `${metadata.width} x ${metadata.height}`);
    }
    return infoMap;
  };

  const renderInfoDialog = () => (
    <ThemeProvider theme={oceans3ThemeDark}>
      <DenseThemeProvider>
        <VideoInformationDialog
          open={isInfoDialogOpen}
          container={containerRef.current}
          onClose={() => setInfoDialogOpen(false)}
          infoMap={getInfoMap()}
        />
      </DenseThemeProvider>
    </ThemeProvider>
  );

  const renderQualityMenuItem = () => {
    if (!qualityOptions) return null;
    return (
      <QualityMenuItem
        options={qualityOptions}
        currentQuality={currentQuality}
        onClick={onChangeQuality}
        containerRef={document.fullscreenElement ? containerRef : undefined}
      />
    );
  };

  const renderContent = () => (
    <>
      {renderInfoDialog()}
      <OncVideoPlayer
        id={id}
        containerRef={containerRef}
        url={memoizedUrl}
        seekTo={seekTo}
        isPlaying={isPlaying}
        isMuted={volume === 0}
        volume={volume}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onReady={() => {
          setIsPlaying(true);
          setIsErrored(false);
        }}
        onError={() => {
          if (!isErrored) {
            onError('An error occurred while playing the video.');
            setIsErrored(true);
          }
          setCurrentFileIndex(currentFileIndex + 1);
        }}
        onProgress={handleProgress}
        onMetadata={setMetadata}
        onSeek={handleSeekComplete}
        playbackRate={playbackRate}
        ControlsComponent={
          <ControlPanel
            SeekbarComponent={renderSeekbar()}
            leftControls={[
              <PlayPauseButton
                isPlaying={isPlaying}
                onClick={(e) => {
                  setIsPlaying(!isPlaying);
                  e.stopPropagation();
                }}
                containerRef={containerRef.current}
              />,
              <VideoSeekOptions
                handleForward={handleForward}
                handleBack={handleBack}
                containerRef={containerRef.current}
              />,
              <SeamlessVideoVolume
                volume={volume}
                setVolume={setVolume}
                containerRef={containerRef.current}
              />,
            ]}
            rightControls={buildRightControls()}
            moreControls={[
              <PlaybackRateMenuItem
                options={[0.5, 1, 2, 4, 8]}
                playbackRate={playbackRate}
                onClick={setPlaybackRate}
                containerRef={
                  document.fullscreenElement ? containerRef : undefined
                }
              />,
              <NestedMenuItem
                value="video-info"
                label="Video Information"
                name="video-info"
                IconComponent={<InfoOutlined />}
                onClick={() => {
                  setInfoDialogOpen(true);
                }}
              />,
              <NestedMenuItem
                value="take-snapshots"
                label="Take Snapshots"
                name="take-snapshots"
                IconComponent={<AddAPhoto />}
                onClick={() => {
                  setOpenSnapshotDialog(true);
                  setSnapshotTime(currentTimestamp);
                }}
              />,
              renderQualityMenuItem(),
            ]}
            timestamp={currentTimestamp}
            startDate={startDate}
            endDate={endDate}
            onFullscreen={handleFullscreenToggle}
            containerRef={containerRef.current}
          />
        }
        SplashScreenComponent={
          <VideoRestartButton
            onClick={(e) => {
              setCurrentTimestamp(startDate);
              setLocalSeekToDate(startDate);
              e.stopPropagation();
            }}
            currentTimestamp={currentTimestamp}
            endDate={endDate}
            containerRef={containerRef.current}
          />
        }
        showControls={false}
      />
    </>
  );

  const renderPip = () => (
    <PipWindow pipWindow={pipWindow}>{renderContent()}</PipWindow>
  );

  return (
    <>
      {pipWindow ? renderPip() : renderContent()}
      {openSnapshotDialog ? renderSnapshots() : undefined}
    </>
  );
};

export default SeamlessVideo;
