import { withStyles } from '@mui/styles';
import { Box, CircularProgress } from 'base-components';

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

type LoadingProps = {
  size?: number;
};

const Loading = ({ size = 80, ...rest }: LoadingProps) => (
  <Box sx={styles.container}>
    <CircularProgress size={size} {...rest} />
  </Box>
);

export default withStyles(styles)(Loading);
