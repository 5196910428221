import { cloneElement, Component } from 'react';
import { MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Code, Settings, Delete, Build, Copy } from '@onc/icons';
import { ListItemIcon } from 'base-components';
import WidgetService from 'domain/AppComponents/Dashboard/WidgetService';
import {
  DATA_PLAYER_WIDGET_TYPE_ID,
  DATA_SOURCE_SELECTION_WIDGET_TYPE_ID,
} from 'domain/AppComponents/Dashboard/WidgetTypeConstants';
import Panel from 'library/CompositeComponents/panel/Panel';
import Environment from 'util/Environment';
import EmbedDialog from './dashboard-embedding/EmbedDialog';
import { EmbedUrlIconButton } from '../IconButtons';

const STYLES = (theme) => ({
  panelChildrenWrapper: {
    overflow: 'hidden',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '97%',
  },
  contentDiv: {
    margin: 'auto',
    width: '97%',
    height: 'calc(100% - 4.75em)',
    position: 'absolute',
    top: '3.75em',
  },
  circularProgress: {
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },
  menuIcon: {
    minWidth: theme.spacing(5),
  },
});

class WidgetContainer extends Component {
  static propTypes = {
    onRemove: PropTypes.func.isRequired,
    containerContent: PropTypes.node,
    title: PropTypes.string,
    widgetId: PropTypes.string,
    widgetTypeId: PropTypes.number,
    classes: PropTypes.shape({
      actionButton: PropTypes.string,
      circularProgress: PropTypes.string,
      contentDiv: PropTypes.string,
      menuIcon: PropTypes.string,
      panelChildrenWrapper: PropTypes.string,
    }).isRequired,
    canEdit: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    onClone: PropTypes.func.isRequired,
  };

  static defaultProps = {
    containerContent: <></>,
    title: '',
    widgetId: undefined,
    canEdit: false,
    widgetTypeId: undefined,
    isLoggedIn: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDisplay: true,
      showToolbox: false,
    };
  }

  handleConfigFinish = () => {
    this.setState({ showDisplay: true, showToolbox: false });
  };

  handleConfigureClick = () => {
    this.setState({ showDisplay: false });
  };

  handleToolboxClick = () => {
    this.setState({ showToolbox: true });
  };

  handleToolboxClose = () => {
    this.setState({ showToolbox: false });
  };

  handleRemove = () => {
    const { onRemove, widgetId } = this.props;
    WidgetService.post({ operation: 3, widgetId });
    onRemove(widgetId);
  };

  renderChild = () => {
    const { containerContent, onRemove, widgetId, classes } = this.props;
    const { showDisplay, showToolbox } = this.state;
    const contentplusprops = cloneElement(containerContent, {
      showDisplay,
      showToolbox,
      handleToolboxClose: this.handleToolboxClose,
      onConfigFinish: this.handleConfigFinish,
      onRemove,
      widgetId,
      classes: {
        contentDiv: classes.contentDiv,
        circularProgress: classes.circularProgress,
      },
    });
    return contentplusprops;
  };

  handleOpenEmbedDialog = () => {
    this.setState({ showEmbedDialog: true });
  };

  handleCancelEmbedDialog = () => {
    this.setState({ showEmbedDialog: false });
  };

  handleCloneWidget = () => {
    const { onClone, widgetId } = this.props;
    onClone(widgetId);
  };

  renderItem = (label, icon, onClick) => {
    const { classes } = this.props;
    return (
      <MenuItem key={label} onClick={onClick}>
        <ListItemIcon className={classes.menuIcon}>{icon}</ListItemIcon>
        {label}
      </MenuItem>
    );
  };

  renderReadWriteMenuItems = () => {
    const { widgetTypeId } = this.props;
    return [
      widgetTypeId === DATA_SOURCE_SELECTION_WIDGET_TYPE_ID
        ? null
        : this.renderItem('Configure', <Settings />, this.handleConfigureClick),
      widgetTypeId === DATA_PLAYER_WIDGET_TYPE_ID
        ? this.renderItem('Toolbox', <Build />, this.handleToolboxClick)
        : null,
      this.renderItem('Embed', <Code />, this.handleOpenEmbedDialog),
      this.renderItem('Clone', <Copy />, this.handleCloneWidget),
      this.renderItem('Remove', <Delete />, this.handleRemove),
    ];
  };

  renderEmbedDialog = () => {
    const { widgetId } = this.props;
    const { showEmbedDialog } = this.state;
    if (showEmbedDialog) {
      const dashboardEmbedUrl = `${Environment.getDmasUrl()}/Widgets/embed/id/${widgetId}`;
      const code = `<iframe src="${dashboardEmbedUrl}" style="overflow:hidden;height:100%;width:100%" name="Widget ${widgetId} iFrame" allowfullscreen />`;
      return (
        <EmbedDialog
          open={showEmbedDialog}
          onCancel={this.handleCancelEmbedDialog}
          embedCode={code}
          fieldLabel="dashboards.iFrameSnippet"
          dialogTitle="Embed Widget"
        />
      );
    }
    return <></>;
  };

  renderEmbedButton = () => {
    const { classes, isLoggedIn } = this.props;
    if (isLoggedIn) {
      return (
        <div style={{ paddingTop: '5px' }}>
          <div style={{ textAlign: 'right' }}>
            <EmbedUrlIconButton
              className={classes.actionButton}
              onClick={this.handleOpenEmbedDialog}
            />
          </div>
        </div>
      );
    }
    return <></>;
  };

  render() {
    const { canEdit, title, classes } = this.props;
    let menuChildren;
    let actionContent;
    if (canEdit) {
      menuChildren = [];
      Array.prototype.push.apply(menuChildren, this.renderReadWriteMenuItems());
    } else {
      actionContent = this.renderEmbedButton();
    }

    return (
      <Panel
        title={title}
        menu={menuChildren}
        headerDraggable
        actionContent={actionContent}
        readOnly={!canEdit}
        classes={{ childrenWrapper: classes.panelChildrenWrapper }}
      >
        {this.renderEmbedDialog()}
        {this.renderChild()}
      </Panel>
    );
  }
}

export default withStyles(STYLES)(WidgetContainer);
