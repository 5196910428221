import { ListSubheader, Paper } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { List, ListItem } from 'base-components';
import DataSourceDisplay from 'domain/AppComponents/Dashboard/chart-widget/DataSourceDisplay';
import type { DataSource } from 'domain/AppComponents/Dashboard/chart-widget/types/ChartWidgetConfig.types';

type GroupedDataSourcesDisplayProps = {
  pathName: string;
  dataSources: DataSource[];
  editDataSource: (source: DataSource) => void;
  removeDataSource: (source: DataSource) => void;
  putDataSource: (source: DataSource) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    dataSource: {
      width: '100%',
    },
    listSubheader: {
      lineHeight: 1.5,
      paddingTop: 4,
    },
    list: {
      padding: 0,
    },
    item: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 0,
      paddingBottom: 4,
    },
  })
);

const GroupedDataSourcesDisplay: React.FC<GroupedDataSourcesDisplayProps> = ({
  pathName,
  dataSources,
  editDataSource,
  removeDataSource,
  putDataSource,
}: GroupedDataSourcesDisplayProps) => {
  const classes = useStyles();

  return (
    <section aria-label={pathName} className={classes.dataSource}>
      <Paper>
        <ListSubheader className={classes.listSubheader}>
          {pathName}
        </ListSubheader>
        <List className={classes.list}>
          {dataSources.map((source) => (
            <ListItem key={source.nodeId} className={classes.item}>
              <DataSourceDisplay
                key={source.nodeId}
                source={source}
                editDataSource={editDataSource}
                removeDataSource={removeDataSource}
                putDataSource={putDataSource}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </section>
  );
};

export default GroupedDataSourcesDisplay;
