import { useContext, useRef, useState } from 'react';
import * as React from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Share, Delete, GetApp, Edit, FileCopy } from '@onc/icons';
import SavePlaylistDialog from 'domain/AppComponents/playlist/SavePlaylistDialog';
import AutoPlaySwitch from 'domain/AppComponents/sea-tube/playlist-playback/AutoPlaySwitch';
import CollapsibleText from 'domain/AppComponents/sea-tube/playlist-playback/CollapsibleText';
import PlaylistContext from 'domain/AppComponents/sea-tube/playlist-playback/PlaylistContext';
import PlaylistList from 'domain/AppComponents/sea-tube/playlist-playback/PlaylistList';
import PlaylistLockingControl from 'domain/AppComponents/sea-tube/playlist-playback/PlaylistLockingControl';
import EditClipDialog from 'domain/AppComponents/sea-tube/playlists/dialogs/EditClipDialog';
import UserDetailsContext from 'domain/AppComponents/sea-tube/UserDetailsContext';
import DeletePlaylistDialog from 'domain/Apps/playlist-management/dialogs/DeletePlaylistDialog';
import DownloadClipsDialog from 'domain/Apps/playlist-management/dialogs/DownloadClipsDialog';
import EditPlaylistDialog from 'domain/Apps/playlist-management/dialogs/EditPlaylistDialog';
import PlaylistLineService, {
  PlaylistLine,
} from 'domain/services/PlaylistLineService';
import ShareMenu from 'library/CompositeComponents/share/Share';
import Environment from 'util/Environment';
import useBroadcast from 'util/hooks/useBroadcast';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { useLocalStorage } from 'util/hooks/useStorage';

import BroadcastChannel from './BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget, {
  WidgetMenuItem,
} from '../../library/CompositeComponents/dashboard/Widget';

const PlaylistWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id, dashboardId } = props;
  const { playlist, playlistLines, clipId, groups, refreshPlaylist } =
    useContext(PlaylistContext);
  const { locked } = playlist;
  const { onInfo, onError } = useSnackbars();

  const useStyles = makeStyles((theme: Theme) => ({
    description: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }));

  const { userId } = useContext(UserDetailsContext);

  const [defaultAutoPlay, setDefaultAutoPlay] = useLocalStorage<boolean>(
    'playlist-playback-auto-play',
    true
  );

  // Broadcasts whether the video player should autoplay the next video
  // We'll eventually need another broadcast variable for when the user clicks on a video in the playlist
  const [autoPlay, setAutoPlay] = useBroadcast(
    dashboardId,
    BroadcastChannel.AutoPlayFlag,
    clipId ? false : defaultAutoPlay,
    id
  );

  const [selectedPlaylistLineId, setSelectedPlaylistLineId] =
    useBroadcast<number>(
      dashboardId,
      BroadcastChannel.PlaylistLineId,
      clipId || playlistLines[0]?.playlistLineId,
      id
    );

  const [, setRefreshVideos] = useBroadcast(
    dashboardId,
    BroadcastChannel.RefreshVideos,
    false,
    id
  );

  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedClips, setSelectedClips] = useState([]);
  const [savePlaylistDialogOpen, setSavePlaylistDialogOpen] = useState(false);
  const [isCloneAction, setIsCloneAction] = useState(false);
  const [editClip, setEditClip] = React.useState<PlaylistLine>(undefined);
  const menuRef = useRef<HTMLDivElement>(null);

  const AutoPlayToggleButton = (
    <AutoPlaySwitch
      checked={!!autoPlay}
      onChange={() => {
        setAutoPlay(!autoPlay);
        setDefaultAutoPlay(!autoPlay);
      }}
    />
  );

  const EditMenuItem = WidgetMenuItem(
    'edit-playlist-link',
    () => setEditDialogOpen(true),
    'Edit',
    Edit,
    locked
  );

  const DownloadMenuItem = WidgetMenuItem(
    'download-playlist-link',
    () => {
      setDownloadDialogOpen(true);
      setSelectedClips(playlistLines);
    },
    'Download',
    GetApp,
    false
  );

  const ShareMenuItem = WidgetMenuItem(
    'share-playlist-link',
    () => {
      setShareAnchorEl(menuRef.current);
    },
    'Share',
    Share,
    false
  );

  const closeDeleteForm = () => {
    setShowDeleteForm(false);
  };

  const openDeleteForm = () => {
    setShowDeleteForm(true);
  };

  const DeleteMenuItem = WidgetMenuItem(
    'delete-playlist-link',
    () => {
      openDeleteForm();
    },
    'Delete',
    Delete,
    locked
  );
  const onSetRedirect = () => {
    setRedirect(true);
  };

  const handleOpenSavePlaylistDialog = () => {
    setSavePlaylistDialogOpen(true);
  };

  const handleCloseSavePlaylistDialog = () => {
    setSavePlaylistDialogOpen(false);
    setIsCloneAction(false);
  };

  const CloneMenuItem = WidgetMenuItem(
    'clone-playlist-link',
    () => {
      handleOpenSavePlaylistDialog();
      setSelectedClips(playlistLines);
      setIsCloneAction(true);
    },
    'Clone',
    FileCopy,
    false
  );

  const handleClipClick = (playlistLineId: number) => {
    setSelectedPlaylistLineId(playlistLineId);
  };

  const handleDownloadCancel = () => {
    setSelectedClips([]);
    setDownloadDialogOpen(false);
  };

  const handleSelectedClipsDownload = (checkedClips: PlaylistLine[]) => {
    setSelectedClips(checkedClips);
    setDownloadDialogOpen(true);
  };

  const handleCopyToPlaylistRequest = (checkedClips: PlaylistLine[]) => {
    setSelectedClips(checkedClips);
    setSavePlaylistDialogOpen(true);
  };

  const classes = useStyles();
  const isEditable = userId === playlist.createdBy;
  return (
    <Widget
      actionComponents={[<PlaylistLockingControl />, AutoPlayToggleButton]}
      MenuItems={[
        isEditable ? EditMenuItem : undefined,
        ShareMenuItem,
        CloneMenuItem,
        DownloadMenuItem,
        isEditable ? DeleteMenuItem : undefined,
      ]}
      ariaLabel="playlist-widget"
      {...props}
      title={playlist.name}
    >
      <EditPlaylistDialog
        open={editDialogOpen}
        closeEditDialog={() => setEditDialogOpen(false)}
        actionAfterEdit={() => refreshPlaylist(playlist.playlistHdrId)}
        playlistHdrId={playlist.playlistHdrId}
        playlistName={playlist.name}
        description={playlist.description}
        groupName={playlist.groupName}
        groupOptions={groups} // TODO add group options
      />
      <EditClipDialog
        open={editClip !== undefined}
        onCancel={() => setEditClip(undefined)}
        initialValue={editClip}
        onSuccess={() => setRefreshVideos(true)}
      />
      <DownloadClipsDialog
        playlistHdrId={playlist.playlistHdrId}
        open={downloadDialogOpen}
        onCancel={handleDownloadCancel}
        selectedClips={PlaylistLineService.playlistLinesToPlaylistLineInfo(
          selectedClips
        )}
        entirePlaylist={selectedClips.length === playlistLines.length}
        playlistName={playlist.name}
      />
      <div ref={menuRef} />
      <div className={classes.description}>
        <CollapsibleText
          id="playlist-description"
          text={playlist.description}
        />
      </div>
      <PlaylistList
        playlist={playlist}
        playlistLines={playlistLines}
        selectedPlaylistLineId={selectedPlaylistLineId}
        onClick={handleClipClick}
        onEditClip={setEditClip}
        onDownloadRequest={handleSelectedClipsDownload}
        onCopyToPlaylistRequest={handleCopyToPlaylistRequest}
      />
      {showDeleteForm ? (
        <DeletePlaylistDialog
          playlistHdrId={playlist.playlistHdrId}
          playlistHdrName={playlist.name}
          open={showDeleteForm}
          closeDeleteForm={closeDeleteForm}
          actionAfterDelete={onSetRedirect}
        />
      ) : undefined}

      <ShareMenu
        open={Boolean(shareAnchorEl)}
        title="Check out this playlist!"
        onClose={() => setShareAnchorEl(null)}
        popoverAnchorEl={shareAnchorEl}
        playlistHdrId={playlist.playlistHdrId}
        popoverAnchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        shareUrl={`${Environment.getLinkUrl()}/app/playlists/${
          playlist.playlistHdrId
        }`}
      />
      <SavePlaylistDialog
        open={savePlaylistDialogOpen}
        onClose={handleCloseSavePlaylistDialog}
        playlistLines={selectedClips.length > 0 ? selectedClips : playlistLines}
        playlistName={playlist?.name}
        playlistDescription={playlist?.description}
        onInfo={onInfo}
        onError={onError}
        isCloneAction={isCloneAction}
        excludePlaylistHdrId={playlist?.playlistHdrId}
      />
      {redirect
        ? window.open(`${Environment.getLinkUrl()}/app/playlists`)
        : undefined}
    </Widget>
  );
};

PlaylistWidget.widgetKey = 'playlist-list';
PlaylistWidget.widgetTitle = 'Playlist';
PlaylistWidget.defaultDataGrid = {
  i: 'playlist-list',
  x: 0,
  y: 0,
  w: 3,
  h: 5,
};

export default PlaylistWidget;
