import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import DeviceService from 'domain/services/DeviceService';

class DeviceDropdown extends Component {
  static propTypes = {
    deviceCategoryId: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onError: PropTypes.func,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    prependOptions: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    name: undefined,
    title: undefined,
    onBlur: undefined,
    onChange: undefined,
    helperText: undefined,
    error: undefined,
    className: undefined,
    value: undefined,
    initialValue: undefined,
    onError: undefined,
    disabled: false,
    deviceCategoryId: undefined,
    fullWidth: false,
    prependOptions: [],
  };

  constructor(props) {
    super(props);
    this.state = { options: [] };
  }

  componentDidMount() {
    const { deviceCategoryId } = this.props;
    if (deviceCategoryId) this.getDeviceOptions(deviceCategoryId);
  }

  shouldComponentUpdate(nextProps) {
    const { deviceCategoryId } = this.props;
    if (nextProps.deviceCategoryId !== deviceCategoryId) {
      this.getDeviceOptions(nextProps.deviceCategoryId);
    }
    return true;
  }

  getDeviceOptions = (deviceCategoryId) => {
    const { onError, prependOptions } = this.props;
    if (deviceCategoryId) {
      DeviceService.getDevicesForDeviceCategoryId(deviceCategoryId)
        .then((response) => {
          if (response.devices) {
            const options = response.devices.map((option) => ({
              label: `${option.deviceName} ID:${option.deviceId}`,
              value: option.deviceId,
            }));
            this.setState({ options: prependOptions.concat(options) });
          }
        })
        .catch((e) => {
          onError(e);
        });
    }
  };

  render() {
    const {
      name,
      title,
      onBlur,
      onChange,
      helperText,
      error,
      className,
      value,
      initialValue,
      disabled,
      fullWidth,
    } = this.props;
    const { options } = this.state;
    return (
      <Dropdown
        options={options}
        name={name}
        title={title}
        onBlur={onBlur}
        onChange={onChange}
        helperText={helperText}
        error={error}
        className={className}
        label="Device"
        value={value}
        initialValue={initialValue}
        disabled={disabled}
        fullWidth={fullWidth}
        id="device-selector"
      />
    );
  }
}

export default DeviceDropdown;
