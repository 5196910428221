import { Component } from 'react';
import { withStyles } from '@mui/styles';
import Moment from 'moment';
import PropTypes from 'prop-types';

import { Info } from '@onc/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from 'base-components';
import VideoPlayer from 'domain/AppComponents/video/VideoPlayer';
import AnnotationUtil from 'domain/Apps/seatube/util/AnnotationUtil';
import AnnotationService from 'domain/services/AnnotationService';
import SearchTreeNodeService from 'domain/services/SearchTreeNodeService';
import SearchTreeNodeDetails from 'domain/Widgets/fixed-camera/SearchTreeNodeDetails';
import AnnotationList from 'library/CompositeComponents/annotation-list/AnnotationList';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import ToggleItemsResponsiveLayout from 'library/CompositeComponents/grid-layout/ToggleItemsResponsiveLayout';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Search from 'util/Search';
import Time from 'util/TimeUtils';
import ManualEntryPanel from './manual-entry/ManualEntryPanel';
import FilterUtil from './util/FilterUtil';
import SeaTubeResourceTypes from './util/SeaTubeResourceTypes';

const ROW_HEIGHT = 25;
const BREAKPOINTS = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 150 };
const COLS = { lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 };
const styles = {
  root: {
    backgroundColor: '#f5f5f5',
  },
  container: {
    height: '100%',
    width: '100%',
  },
};

const LAYOUT_NO_LOGIN = {
  key: 'seatube-playlist-layout',
  name: 'Default Layout',
  selected: true,
  isPublic: true,
  layout: {
    lg: [
      {
        i: 'fixed-camera-video',
        x: 0,
        y: 0,
        w: 8,
        h: 23,
      },
      {
        i: 'annotation-list',
        x: 9,
        y: 0,
        w: 4,
        h: 23,
      },
    ],
  },
};

const LAYOUT = {
  key: 'seatube-playlist-layout',
  name: 'Default Layout',
  selected: true,
  isPublic: true,
  layout: {
    lg: [
      {
        i: 'fixed-camera-video',
        x: 0,
        y: 0,
        w: 8,
        h: 14,
      },
      {
        i: 'annotation-entry',
        x: 0,
        y: 14,
        w: 8,
        h: 9,
      },
      {
        i: 'annotation-list',
        x: 9,
        y: 0,
        w: 4,
        h: 23,
      },
    ],
  },
};

const ITEMS_NO_LOGIN = [
  { key: 'fixed-camera-video', name: 'Video', permission: true },
  { key: 'annotation-list', name: 'Annotation List', permission: true },
];

const ITEMS = [
  { key: 'fixed-camera-video', name: 'Video', permission: true },
  { key: 'annotation-entry', name: 'Annotation Entry', permission: true },
  { key: 'annotation-list', name: 'Annotation List', permission: true },
];

class SeaTubeSearchTreeNode extends Component {
  static propTypes = {
    searchTreeNodeId: PropTypes.number.isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    currentTimestamp: PropTypes.instanceOf(Moment),
    time: PropTypes.instanceOf(Moment),
    classes: PropTypes.shape({
      root: PropTypes.string,
      container: PropTypes.string,
    }),
    isLoggedIn: PropTypes.bool,
    currentUserId: PropTypes.number,
    annotationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    seatubeSearch: PropTypes.string,
  };

  static defaultProps = {
    classes: undefined,
    currentTimestamp: undefined,
    time: undefined,
    isLoggedIn: false,
    currentUserId: -1,
    annotationId: undefined,
    seatubeSearch: '/SeaTubeSearch',
  };

  ascendingComparator = (annotation, timestamp) => {
    const seconds = Math.floor(new Date(annotation.startDate).getTime());
    if (seconds < timestamp) return -1;
    if (seconds > timestamp) return 1;
    return 0;
  };

  descendingComparator = (annotation, timestamp) => {
    const seconds = Math.floor(new Date(annotation.startDate).getTime());
    if (seconds > timestamp) return -1;
    if (seconds < timestamp) return 1;
    return 0;
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTreeNodeName: 'Invalid searchTreeNodeId',
      seekTime: props.time
        ? Time.convertMillisecondsToSeconds(props.time.valueOf())
        : undefined,
      showInfoDialog: false,
      // showDeleteDialog: false,
      filter: {},
      appliedFilter: {},
      sortAnnotationsByMostRecent: false,
      creatorOptions: undefined,
      modifierOptions: undefined,
      selectedAnnotation: undefined,
      annotations: [],
      annotationsTotal: 0,
      currentTimestamp: props.currentTimestamp,
    };
  }

  componentDidMount() {
    this.getFilteredAnnotations();
    this.getSearchTreeNodeName();
  }

  componentDidUpdate(prevProps) {
    const { searchTreeNodeId: prevSearchTreeNodeId, time: prevTime } =
      prevProps;
    const { searchTreeNodeId, time } = this.props;
    if (time && time !== prevTime) {
      this.updateSeekTime(time);
    }

    if (searchTreeNodeId !== prevSearchTreeNodeId) {
      this.getSearchTreeNodeName();
    }
  }

  getFilteredAnnotations = async () => {
    const { searchTreeNodeId, onError, annotationId } = this.props;
    const {
      appliedFilter,
      sortAnnotationsByMostRecent,
      creatorOptions,
      modifierOptions,
      selectedAnnotation,
    } = this.state;
    this.setState({ loading: true });

    try {
      const response =
        await AnnotationService.getSearchTreeNodeAnnotationsWithId(
          searchTreeNodeId,
          appliedFilter,
          sortAnnotationsByMostRecent,
          onError
        );
      const processedAnnotations = AnnotationUtil.processAnnotations(
        response.annotations
      );

      let annotation = selectedAnnotation;
      if (annotationId && !selectedAnnotation) {
        annotation = processedAnnotations.find(
          (currAnnotation) => currAnnotation.annotationId === annotationId
        );
      }

      this.setState((prevState) => ({
        annotations: processedAnnotations,
        loading: false,
        annotationsTotal:
          Object.keys(appliedFilter).length === 0
            ? processedAnnotations.length
            : prevState.annotationsTotal,
        selectedAnnotation: annotation,
      }));
      if (!creatorOptions || !modifierOptions) {
        this.deriveFilterOptions();
      }
    } catch (error) {
      onError(error);
      this.setState({ loading: false });
    }
  };

  getSearchTreeNodeName = async () => {
    const { searchTreeNodeId, onError } = this.props;
    try {
      const payload = await SearchTreeNodeService.getSearchTreeNodeDetails(
        searchTreeNodeId,
        onError
      );
      this.setState({
        searchTreeNodeName: payload.name,
      });
    } catch (error) {
      onError(error);
    }
  };

  deriveFilterOptions = () => {
    const { annotations } = this.state;
    const creatorOptions = [];
    const modifierOptions = [];

    annotations.forEach((annotation) => {
      const { createdBy, modifiedBy, createdDate, modifiedDate } = annotation;
      const creatorExists = creatorOptions.find(
        (person) => person.value === createdBy.userId
      );
      const modifierExists = modifierOptions.find(
        (person) => person.value === modifiedBy.userId
      );

      if (!creatorExists) {
        creatorOptions.push({
          label: `${createdBy.firstName} ${createdBy.lastName}`,
          value: createdBy.userId,
        });
      }

      if (!modifierExists && createdDate !== modifiedDate) {
        modifierOptions.push({
          label: `${modifiedBy.firstName} ${modifiedBy.lastName}`,
          value: modifiedBy.userId,
        });
      }
    });

    creatorOptions.sort((a, b) => a.label > b.label);
    modifierOptions.sort((a, b) => a.label > b.label);

    this.setState({ creatorOptions, modifierOptions });
  };

  handleAnnotationSort = (sortByMostRecent) => {
    const { annotations } = this.state;
    let sortedAnnotations = annotations;
    if (sortByMostRecent) {
      sortedAnnotations = annotations.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );
    } else {
      sortedAnnotations = annotations.sort(
        (a, b) => new Date(a.startDate) - new Date(b.startDate)
      );
    }
    this.setState({
      sortAnnotationsByMostRecent: sortByMostRecent,
      annotations: sortedAnnotations,
    });
  };

  handleTimeChange = (time) => {
    const { currentTimestamp } = this.state;
    const currentTime = new Date(time);

    if (
      currentTimestamp &&
      currentTimestamp.getTime() === currentTime.getTime()
    ) {
      return;
    }
    this.setState({ currentTimestamp: currentTime });
    this.selectCurrentAnnotation(currentTime);
  };

  selectCurrentAnnotation = (newTimestamp) => {
    const { annotations, sortAnnotationsByMostRecent, selectedAnnotation } =
      this.state;

    if (!annotations || annotations.length < 1) return;

    if (!selectedAnnotation) {
      this.setState({ selectedAnnotation: annotations[0] });
      return;
    }

    const comparator = sortAnnotationsByMostRecent
      ? this.descendingComparator
      : this.ascendingComparator;

    const seconds = Math.floor(newTimestamp.getTime());

    const index = Search.binarySearch(annotations, seconds, comparator);
    if (index >= 0) {
      this.setState({ selectedAnnotation: annotations[index] });
    }
  };

  handleSeek = (time) => {
    this.selectClosestAnnotation(time);
  };

  selectClosestAnnotation = (timestamp) => {
    const { annotations, sortAnnotationsByMostRecent } = this.state;
    const timestampDate = new Date(timestamp);

    if (!annotations) {
      return;
    }

    let index = 0;
    let currAnnotationTimestamp;
    for (index; index < annotations.length; index += 1) {
      currAnnotationTimestamp = new Date(annotations[index].startDate);

      // if the current annotation is "past" the timestamp, stop!
      if (
        (sortAnnotationsByMostRecent &&
          currAnnotationTimestamp < timestampDate) ||
        (!sortAnnotationsByMostRecent &&
          currAnnotationTimestamp > timestampDate)
      ) {
        break;
      }
    }

    // grab the annotation and index of the "correct" annotation
    if (!sortAnnotationsByMostRecent) {
      index -= 1;
      index = index < 0 ? 0 : index; // if it's less than 0, make it 0
    }

    this.setState({
      selectedAnnotation: annotations[index],
      annotationIndex: index,
    });
  };

  handleAnnotationClicked = (annotation, jumpToAnnotation) => {
    const { annotations, annotationIndex } = this.state;
    const index = jumpToAnnotation
      ? annotations.indexOf(
          annotations.find(
            (curr) => curr.annotationId === annotation.annotationId
          )
        )
      : annotationIndex;

    this.seekVideoTo(annotation.startDate);
    this.setState({ selectedAnnotation: annotation, annotationIndex: index });
  };

  handleAnnotationEditClicked = (annotation) => {
    this.setState({ editAnnotation: annotation });
  };

  // handleAnnotationDeleteClicked = annotation => {
  //   const { annotationId } = annotation;
  //   this.setState({ showDeleteDialog: true, deleteAnnotationId: annotationId });
  // };

  seekVideoTo = (timestamp) => {
    const time = new Date(timestamp).getTime();
    let seekTime = Time.convertMillisecondsToSeconds(Math.floor(time));
    if (seekTime < 0) seekTime = 0;
    this.setState({
      seekTime,
    });
  };

  handleFilterChange = (filterName, value) => {
    const { filter } = this.state;
    const newFilter = FilterUtil.handleFilterChange(filter, filterName, value);
    this.setState({ filter: newFilter });
  };

  handleApplyFilter = () => {
    const { filter } = this.state;
    this.setState({ appliedFilter: filter }, this.getFilteredAnnotations);
  };

  handleClearFilter = () => {
    this.setState(
      { appliedFilter: {}, filter: {} },
      this.getFilteredAnnotations
    );
  };

  updateSeekTime = (newSeekDate) => {
    this.setState({
      seekTime: Time.convertMillisecondsToSeconds(newSeekDate.valueOf()),
    });
  };

  handleOpenInfoDialog = () => {
    this.setState({
      showInfoDialog: true,
    });
  };

  handleCloseInfoDialog = () => {
    this.setState({
      showInfoDialog: false,
    });
  };

  renderSearchTreeNodeInfoAction = () => (
    <IconButton
      aria-label="Search Tree Node Info"
      color="inherit"
      onClick={this.handleOpenInfoDialog}
      Icon={Info}
    />
  );

  determineCurrentInfoContent = () => {
    const { searchTreeNodeId, onError } = this.props;

    return (
      <>
        <DialogTitle id="search-tree-node-info-title">
          Search Tree Node Details
        </DialogTitle>
        <DialogContent>
          <SearchTreeNodeDetails
            searchTreeNodeId={parseInt(searchTreeNodeId, 10)}
            onError={onError}
          />
        </DialogContent>
      </>
    );
  };

  renderCurrentInfo = () => {
    const { showInfoDialog } = this.state;
    return (
      <Dialog
        open={showInfoDialog || false}
        onClose={this.handleCloseInfoDialog}
      >
        {this.determineCurrentInfoContent()}
        <DialogActions>
          <TextButton
            translationKey="common.buttons.close"
            onClick={this.handleCloseInfoDialog}
          />
        </DialogActions>
      </Dialog>
    );
  };

  // confirmDeleteClicked = () => {
  //   const { diveDetails, deleteAnnotationId, annotationTotal } = this.state;
  //   const { diveId, onError, onInfo } = this.props;
  //   const { defaultDeviceId } = diveDetails;

  //   this.closeDeleteDialog();
  //   onInfo('Deleting annotation...');
  //   return post('/seatube/annotations', {
  //     operation: 3,
  //     diveId,
  //     deviceid: defaultDeviceId,
  //     annotationHdrId: deleteAnnotationId,
  //   })
  //     .then(response => {
  //       parseDmasAPIResponse(response);
  //       this.getFilteredAnnotations();
  //       this.setState({ annotationTotal: annotationTotal - 1 });
  //       onInfo('Annotation deleted.');
  //     })
  //     .catch(error => {
  //       onError(error);
  //     });
  // };

  // closeDeleteDialog = () => {
  //   this.setState({ showDeleteDialog: false, deleteAnnotationId: null });
  // };

  // renderDeleteDialog = () => {
  //   const { showDeleteDialog } = this.state;
  //   return (
  //     <Dialog open={showDeleteDialog} onClose={this.closeDeleteDialog}>
  //       <DialogTitle id="delete-title">Delete Annotation?</DialogTitle>
  //       <DialogContent>
  //         Warning, you are deleting an annotation. It will no longer be viewable
  //         to any user. This cannot be undone.
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={this.closeDeleteDialog}>Cancel</Button>
  //         <Button color="primary" onClick={this.confirmDeleteClicked}>
  //           Delete Annotation
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };

  render() {
    const {
      classes,
      onError,
      onInfo,
      isLoggedIn,
      currentUserId,
      searchTreeNodeId,
      seatubeSearch,
    } = this.props;
    const {
      seekTime,
      searchTreeNodeName,
      annotations,
      annotationsTotal,
      creatorOptions,
      modifierOptions,
      filter,
      selectedAnnotation,
      annotationIndex,
      sortAnnotationsByMostRecent,
      loading,
      currentTimestamp,
      editAnnotation,
    } = this.state;

    return (
      <div className={classes.root} id="seatube-root-id">
        {this.renderCurrentInfo()}
        {/* {this.renderDeleteDialog()} */}
        <ToggleItemsResponsiveLayout
          defaultLayout={isLoggedIn ? LAYOUT : LAYOUT_NO_LOGIN}
          items={isLoggedIn ? ITEMS : ITEMS_NO_LOGIN}
          title={searchTreeNodeName}
          onError={onError}
          rowHeight={ROW_HEIGHT}
          cols={COLS}
          breakpoints={BREAKPOINTS}
          appBarContent={this.renderSearchTreeNodeInfoAction()}
          draggableCancel=".onc-panel-content"
        >
          <Panel
            key="fixed-camera-video"
            title={<Typography variant="body1">Video</Typography>}
            headerDraggable
            enableOverflow
            menuProps={{
              buttonLabel: 'Video Menu',
            }}
          >
            <VideoPlayer
              playerId="seatube-playlist-player"
              searchTreeNodeId={searchTreeNodeId}
              seekTo={seekTime}
              isLoggedIn={isLoggedIn}
              onError={onError}
              onInfo={onInfo}
              onSeek={this.handleSeek}
              onTime={this.handleTimeChange}
            />
          </Panel>
          {isLoggedIn ? (
            <ManualEntryPanel
              key="annotation-entry"
              onSave={this.handleAnnotationSave}
              searchTreeNodeId={searchTreeNodeId}
              currentTimestamp={currentTimestamp}
              editAnnotation={editAnnotation}
              onClear={this.handleClearEntry}
            />
          ) : null}
          <AnnotationList
            key="annotation-list"
            annotations={annotations}
            annotationsTotal={annotationsTotal}
            loadAnnotations={this.getFilteredAnnotations}
            onAnnotationClick={this.handleAnnotationClicked}
            onAnnotationEditClick={this.handleAnnotationEditClicked}
            // onAnnotationDeleteClick={this.handleAnnotationDeleteClicked}
            filter={filter}
            onClearFilter={this.handleClearFilter}
            onFilterChange={this.handleFilterChange}
            selectedAnnotation={selectedAnnotation}
            onAnnotationSort={this.handleAnnotationSort}
            creators={creatorOptions}
            modifiers={modifierOptions}
            resourceTypeId={SeaTubeResourceTypes.SEARCH_TREE_NODE}
            resourceId={searchTreeNodeId}
            // deletePermissions={isLoggedIn}
            // selfDeletePermissions={isLoggedIn}
            annotationPermissions={isLoggedIn}
            onApplyFilter={this.handleApplyFilter}
            annotationIndex={annotationIndex}
            annotationAlignment={sortAnnotationsByMostRecent ? 'end' : 'start'}
            annotationSortByMostRecent={sortAnnotationsByMostRecent}
            loading={loading}
            currentUserId={currentUserId}
            seatubeSearch={seatubeSearch}
            fullTimestamp
          />
        </ToggleItemsResponsiveLayout>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(SeaTubeSearchTreeNode));
