import { get } from 'util/WebRequest';

export type ProcessGroup = {
  processGroupId?: number;
  processGroupName?: string;
  modifyBy?: number;
  modifyDate?: Date;
};

class ProcessGroupService {
  static getDevices = async (id): Promise<any[]> => {
    const response = await get('ProcessGroupService', {
      operation: 5,
      id,
    });
    return response.data.payload.deviceProcessStatuses;
  };

  static getAll = async (): Promise<ProcessGroup[]> => {
    const response = await get('ProcessGroupService', {
      operation: 6,
    });
    return response.data.payload.processGroups;
  };
}

export default ProcessGroupService;
