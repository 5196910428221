/** Handles custom prop type validation for DMAS. */
class Types {
  /*
   * To write a custom prop type, there are a few rules
   *  * a prop is considered valid if its validator returns null
   *  * a prop is considered invalid if its validator returns an Error
   *  * a prop-type can't return anything other than null or Error
   *
   * Take note of the function signature in the underlying curried function. A std prop-type MUST
   * be executed with ...rest. This is because React passes it a 'secret' object at dev-runtime. It will
   * throw an error otherwise.
   *
   * If you need to execute a proptype outside of validation
   * (NOT in this class, or in the static props object, i.e. in your component logic for some reason), then
   * you must use PropTypes.checkPropTypes(prop, propsObj, propName, compName).
   * This func will validate at dev, but do nothing at Prod time.
   */

  /**
   * @template T
   * @typedef {import('prop-types').Requireable<T>} proptype
   */
  /**
   * @template T
   * @typedef {import('prop-types').Validator<T>} validator
   */

  /**
   * Marks a Prop as required iff the condition returns true. Intended to
   * represent implicit dependencies.
   *
   * @template T
   * @param {proptype<T>} type
   * @param {Function} condition
   * @returns {validator<T>} -- Custom Prop Type validator
   */
  static requiredIf(type, condition) {
    return (props, propName, componentName, ...rest) => {
      if (typeof type !== 'function' || typeof condition !== 'function')
        return new Error(
          'Invalid parameters. Type and Condition should be funcs'
        );
      const test = condition(props) ? type.isRequired : type;
      return test(props, propName, componentName, ...rest);
    };
  }

  /**
   * Two arguments:
   *
   * - Types: applies PropType to the prop
   * - Exclusive: either String or Array[String]
   *
   * The prop-under-test _must not_ be defined when the listed prop-types are
   * defined. If any of the props listed in 'exclusive' are defined, and the
   * prop-under-test is defined, this test fails.
   *
   * @template T
   * @param {proptype<T>} type
   * @param {string | string[]} exclusive
   * @returns {validator<T>} -- Custom Prop Type validator
   */
  static mutuallyExclusiveWith(type, exclusive) {
    return (props, propName, componentName, ...rest) => {
      let exclusiveProps = [];
      if (typeof exclusive === 'string') {
        exclusiveProps = [exclusive];
      } else if (Array.isArray(exclusive)) {
        exclusiveProps = exclusive;
      } else {
        return new Error(
          'Argument #2 should be either a string, or Array[string]'
        );
      }

      if (typeof props[propName] === 'undefined') return null;

      const failures = exclusiveProps.filter(
        (prop) => typeof props[prop] !== 'undefined'
      );
      if (failures.length > 0)
        return new Error(
          `when ${propName} is defined, these props should be undefined: ${failures}`
        );

      return type(props, propName, componentName, ...rest);
    };
  }
}

export default Types;
