import * as React from 'react';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
  TemplateConnector,
} from '@devexpress/dx-react-core';
import { SettingsBackupRestore } from '@onc/icons';
import { IconButton } from 'base-components';

interface Props {
  onReset: () => void;
}

const pluginDependencies = [{ name: 'Toolbar' }];

const ToolbarFilter: React.VFC<Props> = ({ onReset }: Props) => (
  <Plugin dependencies={pluginDependencies}>
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <TemplateConnector>
        {() => (
          <IconButton
            aria-controls="table-clear-config"
            data-test="table-clear-config"
            aria-label="Reset Table View"
            onClick={onReset}
          >
            <SettingsBackupRestore />
          </IconButton>
        )}
      </TemplateConnector>
    </Template>
  </Plugin>
);

export default ToolbarFilter;
