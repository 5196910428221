import { useState } from 'react';
import Environment from 'util/Environment';
import SnackbarProvider from 'util/hooks/useSnackbars';
import CableTypeDialog from './CableTypeDialog';
import CableTypeTable from './CableTypeTable';
import { CableTypeRow, transformRowToSyncForm } from './CableTypeTableLogic';

type CableTypeProps = {
  cableTypes: CableTypeRow[];
  handleSuccess: () => void;
};

const CableTypeTab = ({ cableTypes, handleSuccess }: CableTypeProps) => {
  const permission = Environment.getDmasUserPrivilege();

  const [isCableTypeDialogOpen, setCableTypeDialog] = useState(false);
  const [initialCableType, setInitialCableType] = useState(undefined);

  const handleEditClick = (row: CableTypeRow) => {
    setInitialCableType(transformRowToSyncForm(row));
    setCableTypeDialog(true);
  };

  const handleCreateClick = () => {
    setInitialCableType(undefined);
    setCableTypeDialog(true);
  };

  const handleCloseClick = () => {
    setCableTypeDialog(false);
  };

  return (
    <>
      <SnackbarProvider>
        <CableTypeTable
          rows={cableTypes}
          permission={permission}
          onEdit={handleEditClick}
          onCreate={handleCreateClick}
        />
      </SnackbarProvider>
      <CableTypeDialog
        initialValue={initialCableType}
        open={isCableTypeDialogOpen}
        onClose={handleCloseClick}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export default CableTypeTab;
