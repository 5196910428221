import { useEffect, useMemo, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import { DateRange } from '@onc/icons';
import {
  ClickAwayListener,
  DailyAvailabilityChart,
  DatePicker,
  IconButton,
  Paper,
  Popper,
} from 'base-components';

import { useDataAvailability } from 'domain/services/DataAvailabilityWebService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from 'library/CompositeComponents/dashboard/DashboardTypes';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import Widget from 'library/CompositeComponents/dashboard/Widget';

type FixedCameraDatePickerConfig = {
  date: Moment | null;
  searchTreeNodeCode: string;
};

const FixedCameraDatePickerWidget: DashboardWidget = (
  props: DashboardWidgetProps
) => {
  const { id } = props;

  const [showDatePicker, setShowDatePicker] = useState(false);
  const ref = useRef<HTMLButtonElement | null>(null);

  const {
    dashboardState: config,
    setDashboardStateProperty: setConfigProperty,
  } = useDashboardState<FixedCameraDatePickerConfig>();
  const { date, searchTreeNodeCode } = config;
  const [localDate, setLocalDate] = useState(date);

  const handleClickAway = () => {
    if (showDatePicker) {
      setShowDatePicker(false);
    }
  };

  const { data: dataAvailability, isLoading } = useDataAvailability(
    {
      locationCode: searchTreeNodeCode,
      deviceCategoryCode: 'VIDEOCAM',
      extension: 'mp4',
      groupBy: 'day',
      dateFrom: '2000-01-01',
      includeEmptyDays: false,
    },
    { enabled: !!searchTreeNodeCode }
  );

  useEffect(() => {
    const { availableDataProducts } = dataAvailability || {};
    if (availableDataProducts && availableDataProducts.length > 0) {
      // Set date to most  recent available date
      const mostRecentDate = moment.utc(
        availableDataProducts[availableDataProducts.length - 1].dateFrom
      );
      setConfigProperty('date', mostRecentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAvailability]);

  useEffect(() => {
    if (!showDatePicker && localDate?.isValid()) {
      setConfigProperty('date', localDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDatePicker]);

  const { availableDatesSet, availableYearsMap, chartData } = useMemo(() => {
    // Instead of a monthsSet, create a map: year -> Set of months
    const datesSet = new Set<string>();
    const yearsMap = new Map<number, Set<number>>();

    // store the earliest & latest date for later iteration
    let earliestDate: moment.Moment | null = null;
    let latestDate: moment.Moment | null = null;
    const { availableDataProducts } = dataAvailability || {};
    if (availableDataProducts) {
      for (const item of availableDataProducts) {
        const availableDate = moment.utc(item.dateFrom);
        const year = availableDate.year();
        const month = availableDate.month();

        // Add the full date (YYYY-MM-DD) to set
        const dayStr = item.dateFrom.split('T')[0];
        datesSet.add(dayStr);

        // Update earliest & latest date trackers
        if (!earliestDate || availableDate.isBefore(earliestDate)) {
          earliestDate = availableDate.clone();
        }
        if (!latestDate || availableDate.isAfter(latestDate)) {
          latestDate = availableDate.clone();
        }

        // Ensure the year key exists, then add the month
        if (!yearsMap.has(year)) {
          yearsMap.set(year, new Set<number>());
        }
        yearsMap.get(year)?.add(month);
      }
    }

    const filledChartData: { date: string; value: number }[] = [];

    // Only fill chartData if we have valid earliestDate & latestDate
    if (earliestDate && latestDate) {
      const dayCursor = earliestDate.clone().startOf('day');
      const endDay = latestDate.clone().startOf('day');

      // Walk from earliestDate to latestDate, inclusive
      while (dayCursor.isSameOrBefore(endDay, 'day')) {
        const dayStr = dayCursor.format('YYYY-MM-DD');
        // If this day is in the set, it's 1; otherwise 0
        // This could be a percentage at some point
        const value = datesSet.has(dayStr) ? 1 : 0;

        filledChartData.push({
          date: dayStr,
          value,
        });

        // Move to the next day
        dayCursor.add(1, 'day');
      }
    }

    return {
      availableDatesSet: datesSet,
      availableYearsMap: yearsMap,
      chartData: filledChartData, // Now includes 0 for missing days
    };
  }, [dataAvailability]);

  const displayDateRange = useMemo(() => {
    const { availableDataProducts } = dataAvailability || {};
    if (!availableDataProducts || availableDataProducts.length === 0) {
      return {
        dateFrom: new Date('2007-01-01'),
        dateTo: new Date(),
      };
    }

    // Find the earliest dateFrom and the latest dateFrom in the array
    let earliestDate = moment.utc(availableDataProducts[0].dateFrom);
    let latestDate = moment.utc(availableDataProducts[0].dateFrom);

    availableDataProducts.forEach((item) => {
      const currentDate = moment.utc(item.dateFrom);
      if (currentDate.isBefore(earliestDate)) {
        earliestDate = currentDate;
      }
      if (currentDate.isAfter(latestDate)) {
        latestDate = currentDate;
      }
    });

    return {
      dateFrom: earliestDate.add(-1, 'day').toDate(),
      dateTo: latestDate.add(1, 'day').toDate(),
    };
  }, [dataAvailability]);

  const shouldDisableDate = (pickerDate: Moment) => {
    const dateString = pickerDate.utc().format('YYYY-MM-DD');
    // If we're trying to disable dates that are not available, invert logic as needed.
    return !availableDatesSet.has(dateString);
  };

  const shouldDisableYear = (yearMoment: Moment) => {
    const yearValue = yearMoment.utc().year();
    // If the year is not in the map, it's not available
    return !availableYearsMap.has(yearValue);
  };

  const shouldDisableMonth = (monthMoment: Moment) => {
    const yearValue = monthMoment.utc().year();
    const monthValue = monthMoment.utc().month();

    // Check if the year's months set contains this month
    const monthsForYear = availableYearsMap.get(yearValue);
    return !monthsForYear || !monthsForYear.has(monthValue);
  };

  return (
    <Widget
      id={id}
      loading={isLoading}
      actionComponents={[
        <ClickAwayListener key="date-picker" onClickAway={handleClickAway}>
          <div>
            <IconButton
              onClick={() => setShowDatePicker(!showDatePicker)}
              aria-label="Date Picker"
              Icon={DateRange}
              ref={ref}
            />
            <Popper
              open={showDatePicker}
              anchorEl={ref.current}
              sx={{
                zIndex: 999,
              }}
            >
              <Paper>
                <DatePicker
                  autoFocus
                  translationKey="common.datepickers.date"
                  value={date}
                  minDate={moment.utc('2007-01-01')}
                  maxDate={moment.utc()}
                  shouldDisableDate={shouldDisableDate}
                  shouldDisableYear={shouldDisableYear}
                  shouldDisableMonth={shouldDisableMonth}
                  onClose={() => {
                    setShowDatePicker(false);
                  }}
                  onChange={(val) => setLocalDate(val)}
                  views={['year', 'month', 'day']}
                />
              </Paper>
            </Popper>
          </div>
        </ClickAwayListener>,
      ]}
      {...props}
    >
      {!isLoading && (
        <DailyAvailabilityChart
          availabilityData={chartData}
          displayDateRange={displayDateRange}
          selectedDay={date}
          displayDefaultTitle={false}
          style={{
            width: '100%',
            height: '100%',
          }}
          onDaySelected={(selected) => {
            setConfigProperty('date', selected.date);
          }}
        />
      )}
    </Widget>
  );
};

FixedCameraDatePickerWidget.widgetKey = 'fixed-camera-date-picker-widget';
FixedCameraDatePickerWidget.widgetTitle = 'Video Availability';
FixedCameraDatePickerWidget.defaultDataGrid = {
  i: 'fixed-camera-date-picker-widget',
  x: 0,
  y: 0,
  w: 12,
  h: 4,
};

export default FixedCameraDatePickerWidget;
