import { Dropdown } from 'base-components';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';
import useGet from 'util/hooks/useDmasAPI/useGet';

type DerivedParserDefinitionVersionSelectProps = Omit<
  FormFieldType<React.ComponentProps<typeof Dropdown>>,
  'options'
> & { sensorId: number; pdId: number; onError: (message: string) => void };

type VersionPayload = Array<number>;

const DerivedParserDefinitionVersionSelect = ({
  sensorId,
  pdId,
  onError,
  ...props
}: DerivedParserDefinitionVersionSelectProps) => {
  const versionData = useGet<
    VersionPayload,
    { sensorId: number; pdId: number }
  >('DerivedSensorParserDefinitionService', 9, { sensorId, pdId });

  if (versionData.isLoading) {
    return <FormDropdown {...props} options={[]} />;
  }

  if (versionData.error) {
    onError('Failed to get version types');
    return <FormDropdown {...props} options={[]} />;
  }

  const options = versionData.data?.map((option) => ({
    label: `Version ${option}`,
    value: option,
    key: option.toString(),
  }));

  return <FormDropdown options={options} {...props} />;
};

export default DerivedParserDefinitionVersionSelect;
