/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import * as React from 'react';
import useDashboardData from 'domain/AppComponents/sea-tube/useDashboardData';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import useBroadcast from 'util/hooks/useBroadcast';
import LocalStorageUtil from 'util/LocalStorageUtil';
import Dashboard from '../../../library/CompositeComponents/dashboard/Dashboard';
import DashboardLogic from '../../../library/CompositeComponents/dashboard/DashboardLogic';
import {
  GridItem,
  OncLayouts,
  WidgetConfig,
} from '../../../library/CompositeComponents/dashboard/DashboardTypes';
import { WindowController } from '../../../library/CompositeComponents/dashboard/useWindowController';
import WidgetLayoutMenu, {
  LayoutOption,
} from '../../../library/CompositeComponents/dashboard/WidgetLayoutMenu';
import WidgetLibrary from '../../Widgets/WidgetLibrary';
import { LayoutApplication } from '../Dashboard/LayoutService';

type SeaTubeDashboardProps<TConfig = any> = {
  id: string;
  layoutKey: string;
  appName?: string;
  title: string;
  widgetLibrary: WidgetLibrary;
  actionComponents?: React.ReactNode[];
  defaultLayout?: OncLayouts;
  defaultWidgets?: WidgetConfig[];
  isStatic?: boolean;
  predefinedLayouts?: LayoutOption[];
  titleComponents?: React.ReactNode;
  windowController?: WindowController;
  showToolbar?: boolean;
  applicationId?: LayoutApplication;
  initialDashboardState?: TConfig;
};

export type DashboardData = {
  layouts: OncLayouts;
  widgets: GridItem[];
};

const emptyLayout = { xl: [], lg: [], md: [], sm: [], xs: [] };

const SeaTubeDashboard = ({
  id,
  layoutKey,
  title,
  widgetLibrary,
  actionComponents = [],
  defaultLayout = emptyLayout,
  appName = 'dashboard',
  defaultWidgets = undefined,
  initialDashboardState = undefined,
  isStatic = false,
  titleComponents = undefined,
  windowController = undefined,
  showToolbar = true,
  predefinedLayouts = undefined,
  applicationId = undefined,
}: SeaTubeDashboardProps) => {
  let storageKey = layoutKey;
  if (windowController) {
    const { windowIndex, numWindows } = windowController;
    storageKey = `${layoutKey}-${windowIndex}/${numWindows}`;
  }
  const [dashboardData, setDashboardData] = useDashboardData<DashboardData>(
    isStatic,
    `${storageKey}-dashboard`,
    {
      layouts: defaultLayout,
      widgets: defaultWidgets
        ? DashboardLogic.buildInitialWidgets(defaultWidgets, widgetLibrary)
        : DashboardLogic.buildWidgetsFromLayout(
            defaultLayout,
            [],
            widgetLibrary
          ),
    }
  );

  const { widgets: localWidgets } = dashboardData;

  const [widgets, setWidgets] = useBroadcast<GridItem[]>(
    layoutKey,
    BroadcastChannel.LayoutUpdate,
    localWidgets,
    layoutKey
  );

  const getCurrentWidgets = () => {
    if (!windowController) {
      return localWidgets;
    }
    const allWidgets = [];
    const { numWindows } = windowController;
    for (let index = 1; index <= numWindows; index += 1) {
      const windowData = LocalStorageUtil.getItem(
        `${layoutKey}-${index}/${numWindows}-dashboard`,
        []
      );
      allWidgets.push(...(windowData?.widgets || []));
    }
    return allWidgets;
  };

  useEffect(() => {
    setWidgets(getCurrentWidgets());
  }, [dashboardData]);

  /**
   * Even though we use the hook for local storage, it doesn't seem to handle
   * the key changing properly. This is a workaround to ensure that the correct
   * widgets are loaded when the windowController changes
   */
  useEffect(() => {
    if (isStatic) {
      return;
    }
    const savedData = LocalStorageUtil.getItem(
      `${storageKey}-dashboard`,
      undefined
    );
    if (savedData === undefined) {
      setDashboardData({ ...dashboardData, widgets: localWidgets });
    } else {
      setDashboardData({
        layouts: savedData.layouts,
        widgets: savedData.widgets,
      });
    }
  }, [windowController?.numWindows]);

  const getDashboardTitle = () => {
    if (windowController && windowController.numWindows > 1) {
      return `[${windowController.windowIndex} / ${windowController.numWindows}] ${title}`;
    }
    return title;
  };

  if (windowController?.numWindows === 0) {
    return null;
  }

  const renderActionComponents = () => [
    ...actionComponents,

    applicationId &&
    (!windowController || windowController.numWindows === 1) ? (
      <WidgetLayoutMenu
        applicationId={applicationId}
        currentLayout={dashboardData.layouts}
        predefinedLayouts={predefinedLayouts}
        onChange={(selectedLayout) => {
          setDashboardData({
            layouts: selectedLayout,
            widgets: DashboardLogic.buildWidgetsFromLayout(
              selectedLayout,
              localWidgets,
              widgetLibrary
            ),
          });
        }}
      />
    ) : null,
  ];

  return (
    <Dashboard
      title={getDashboardTitle()}
      id={id}
      appName={appName}
      widgetLibrary={widgetLibrary}
      windowController={windowController}
      actionComponents={renderActionComponents()}
      isStatic={isStatic}
      titleComponents={titleComponents}
      onDashboardDataChange={(data) => {
        setDashboardData(data);
      }}
      dashboardData={dashboardData}
      widgetOptions={DashboardLogic.filterWidgetOptions(widgets, widgetLibrary)}
      showToolbar={showToolbar}
      initialDashboardState={initialDashboardState}
    />
  );
};

export default SeaTubeDashboard;
