import { parseDmasAPIResponse, get } from 'util/WebRequest';

class ExpeditionService {
  static getExpeditionDetails = async (expeditionId, onError) => {
    try {
      const payload = await get('CruiseService', {
        cruiseId: expeditionId,
        operation: 25,
      }).then((response) => parseDmasAPIResponse(response));
      if (payload.startDate) {
        payload.startDate = new Date(payload.startDate);
      }
      if (payload.endDate) {
        payload.endDate = new Date(payload.endDate);
      }
      return payload;
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
    return undefined;
  };

  static getExpeditionTree = async () => {
    const response = await get('expedition/tree', {});
    return parseDmasAPIResponse(response);
  };
}

export default ExpeditionService;
