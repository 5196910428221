import * as React from 'react';
import DFOLogo from 'assets/logos/logo-dfo.png';
import IFREMERLogo from 'assets/logos/logo-ifremer.png';
import NOAALogo from 'assets/logos/logo-noaa.png';
import ONCLogo from 'assets/logos/logo-onc.png';

// FIXME CompositeComponents shouldn't access AppComponents
import {
  ONC_DATA,
  NOAA_DATA,
  DFO_DATA,
  IFREMER_DATA,
} from 'domain/AppComponents/organization-details/OrganizationServiceData';

interface OrganizationLogoProps {
  id: number;
  className?: string;
}

const OrganizationLogo: React.VFC<OrganizationLogoProps> = ({
  id,
  className = undefined,
}) => {
  switch (id) {
    case NOAA_DATA.organizationId:
      return (
        <img
          src={NOAALogo}
          alt="NOAA Logo"
          className={className}
          title="NOAA Logo"
        />
      );
    case IFREMER_DATA.organizationId:
      return (
        <img
          src={IFREMERLogo}
          alt="IFREMER Logo"
          className={className}
          title="IFREMER Logo"
        />
      );
    case DFO_DATA.organizationId:
      return (
        <img
          src={DFOLogo}
          alt="DFO Logo"
          className={className}
          title="DFO Logo"
        />
      );
    case ONC_DATA.organizationId:
      return (
        <img
          src={ONCLogo}
          alt="ONC Logo"
          className={className}
          title="ONC Logo"
        />
      );
    default:
      return null;
  }
};

export default OrganizationLogo;
