/* eslint-disable react/require-default-props */
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';

const rorwDropdownPropTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  permission: PropTypes.bool,
  classes: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

const dropdownPropTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  permission: PropTypes.bool,
  classes: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  InputProps: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

const RORWDropdown = ({
  id,
  label,
  value = undefined,
  permission,
  options = [],
  InputProps = undefined,
  disabled = undefined,
  ...rest
}) => {
  const val = options.find((option) => option.value === value) || {
    label: 'n/a',
  };

  if (permission) {
    return (
      <Dropdown
        select
        fullWidth
        id={id || _.uniqueId()}
        label={label}
        value={value}
        options={options}
        InputProps={InputProps}
        disabled={disabled}
        {...rest}
      />
    );
  }

  return (
    <MarginedReadOnlyField
      fullWidth
      labelText={label}
      valueText={val.label}
      {...rest}
    />
  );
};
RORWDropdown.propTypes = rorwDropdownPropTypes;

const NodeTypeDropdown = (props) => {
  const name = 'nodeTypeSelect';
  const label = 'Type';
  const id = 'node-type-select';
  return <RORWDropdown id={id} name={name} label={label} {...props} />;
};
NodeTypeDropdown.propTypes = dropdownPropTypes;

export { NodeTypeDropdown, RORWDropdown };
