import { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Environment from 'util/Environment';
import LayoutService from './LayoutService';
import WidgetGenerator from './WidgetGenerator';
import WidgetService from './WidgetService';

const STYLES = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
};

const WidgetDisplay = (props) => {
  WidgetDisplay.propTypes = {
    widgetId: PropTypes.number.isRequired,
    onError: PropTypes.func.isRequired,
    classes: PropTypes.shape({ container: PropTypes.string }).isRequired,
  };
  const { widgetId, onError, classes } = props;

  // State Variables
  const [data, setData] = useState(undefined);

  useEffect(() => {
    const getWidget = async () => {
      try {
        const widgetResponse = await WidgetService.get({
          operation: 4,
          widgetId,
        });
        const layoutResponse = await LayoutService.get({
          operation: 4,
          layoutId: widgetResponse.widget.layoutId,
        });
        Environment.setCurrentApplicationToken(
          `${layoutResponse.layout.token}`
        );
        setData({
          widgetid: widgetResponse.widget.widgetId,
          widgettypeid: widgetResponse.widget.widgetTypeId,
          widgetconfig: widgetResponse.widget.widgetConfig,
          layoutId: widgetResponse.widget.layoutId,
          onRemove: () => {},
          onClone: () => {},
        });
      } catch (error) {
        onError(error.message);
      }
    };
    getWidget();
  }, [onError, widgetId]);

  if (data) {
    return (
      <div className={classes.container}>{WidgetGenerator.build(data)}</div>
    );
  }
  return null;
};

export default withStyles(STYLES)(WidgetDisplay);
