import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class CharacteristicService {
  static get = (deviceId) =>
    get('DevicePhysicalCharacteristicsService', {
      deviceId,
    }).then((payload) => payload);

  static update = (params) =>
    post('DevicePhysicalCharacteristicsService', {
      operation: 2,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));

  static create = (params) =>
    post('DevicePhysicalCharacteristicsService', {
      operation: 1,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));

  static delete = (characteristicId) =>
    post('DevicePhysicalCharacteristicsService', {
      operation: 3,
      characteristicId,
    }).then((response) => parseDmasAPIResponse(response));
}
export default CharacteristicService;
