import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import Moment from 'moment';
import PropTypes from 'prop-types';

import { WarningAlert } from '@onc/composite-components';
import { OpenInNew } from '@onc/icons';
import { Tab, Tabs } from 'base-components';
import AnnotationUtil from 'domain/Apps/seatube/util/AnnotationUtil';
import DataProductDeliveryService from 'domain/services/DataProductDeliveryService';
import DataSearchService from 'domain/services/DataSearchService';
import PlaylistLineService from 'domain/services/PlaylistLineService';
import PlaylistService from 'domain/services/PlaylistService';
import SeaTubeBroadAnnotationService from 'domain/services/SeaTubeBroadAnnotationService';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import DateUtils from 'util/DateUtils';
import Environment from 'util/Environment';
import SeaTubeExistingPlaylistTab from './SeaTubeExistingPlaylistTab';
import SeaTubeExportActionsTab from './SeaTubeExportActionsTab';
import SeaTubeNewPlaylistTab from './SeaTubeNewPlaylistTab';
import SeaTubeSearchExportFormat from './SeaTubeSearchExportFormat';

const DEVICE_DATA_ID = 1000;
const CHECK_INTERVAL = 5000;
const EXPORT_CHECK_INTERVAL = 30000;
const EXPORT_CHECK_SNACKBAR_INTERVAL = 60000;

let lastPollExportSnackbarTime = 0;

const styles = (theme) => ({
  actionContainer: {
    paddingLeft: theme.spacing(),
  },
  tabContainer: {
    height: '20%',
  },
});

class SearchResultsActionTabs extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    playlists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    onPlaylistAdd: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    filter: PropTypes.objectOf(PropTypes.any),
    diveIdString: PropTypes.string,
    deckLogIdString: PropTypes.string,
    searchResults: PropTypes.arrayOf(PropTypes.shape({})),
    broadSearchContext: PropTypes.bool,
    searchHdrId: PropTypes.number,
  };

  static defaultProps = {
    filter: {},
    diveIdString: undefined,
    deckLogIdString: undefined,
    searchResults: [],
    broadSearchContext: false,
    searchHdrId: undefined,
  };

  renderLoggedInWarningAlert = () => (
    <WarningAlert>
      You must be logged in to access functions such as exporting and playlist
      management
    </WarningAlert>
  );

  state = {
    tabValue: 0,
    inclSpecialChars: true,
    inclSeaTubeLinks: false,
    inclVideoSnapshots: false,
    snapshotsPer: 1,
    timeBetweenSnapshots: 5,
    selectedPlaylist: undefined,
    paddingBefore: 2,
    paddingAfter: 2,
    newPlaylistName: '',
    exportStatus: 'none',
    loadingPlaylist: false,
  };

  canExportDarwinCore = () => {
    const { diveIdString, deckLogIdString } = this.props;

    const diveLogs = diveIdString?.split(',').filter(Boolean) || [];
    const deckLogs = deckLogIdString?.split(',').filter(Boolean) || [];

    if (diveLogs.length !== 1 || deckLogs.length > 0) {
      return false;
    }
    return true;
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handlePlaylistChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleNumberChange =
    (name, minValue = 0) =>
    (event) => {
      if (event.target.valueAsNumber >= minValue) {
        this.setState({ [name]: event.target.valueAsNumber });
      }
    };

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleExportSubmit = async (format) => {
    const { filter, diveIdString, deckLogIdString, onError } = this.props;
    const {
      inclSpecialChars,
      inclSeaTubeLinks,
      inclVideoSnapshots,
      snapshotsPer,
      timeBetweenSnapshots,
      exportStatus,
    } = this.state;
    // check that the Export has either not been clicked before, or has completed,
    // but another export is requested
    if (
      exportStatus === 'none' ||
      exportStatus === DataProductDeliveryService.Statuses.COMPLETE
    ) {
      const params = {
        diveIds: diveIdString,
        cruiseIds: deckLogIdString,
        filter,
        includeSpecialCharacters:
          inclSpecialChars ||
          format === SeaTubeSearchExportFormat.INGESTION_CSV.name
            ? 1
            : 0,
        includeSeaTubeLinks: inclSeaTubeLinks ? 1 : 0,
        includeSnapshots: inclVideoSnapshots ? 1 : 0,
        snapshotsPerAnnotation: snapshotsPer,
        timeBetweenSnapshots,
        format,
      };

      params.filter = AnnotationUtil.buildAnnotationFilter(filter);
      if (
        format === SeaTubeSearchExportFormat.DARWIN_CORE.name &&
        !filter.reviews
      ) {
        params.filter = {
          ...params.filter,
          minimumReviews: '0',
          positiveReviewPercentage: '0',
        };
      }

      try {
        const dpRequestId =
          await DataProductDeliveryService.requestAnnotationExport(params);
        this.searchHdrId = dpRequestId;

        const runResponse =
          await DataProductDeliveryService.runDataProductSearch(dpRequestId);
        this.handleRunSuccess(runResponse);
      } catch (error) {
        onError(typeof error === 'string' ? error : error.message);
      }
    }
  };

  handleRunSuccess = (data) => {
    const { dpRunId: searchId, status: exportStatus } = data;

    if (exportStatus !== DataProductDeliveryService.Statuses.COMPLETE) {
      this.checkStatus();
    }

    this.setState({ searchId, exportStatus });
  };

  checkStatus = async () => {
    const { onError } = this.props;

    try {
      const results = await DataSearchService.checkSearchStatus(
        this.searchHdrId
      );

      if (results) {
        const { status, filename: fileName } = results;

        if (status > 1) {
          this.setState({
            exportStatus: DataProductDeliveryService.Statuses.COMPLETE,
            fileName,
          });
        } else {
          setTimeout(() => this.checkStatus(), CHECK_INTERVAL);
          this.setState({
            exportStatus:
              DataProductDeliveryService.Statuses.DATA_PRODUCT_RUNNING,
          });
        }
      } else {
        setTimeout(() => this.checkStatus(), CHECK_INTERVAL);
        this.setState({
          exportStatus: DataProductDeliveryService.Statuses.QUEUED,
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  handleBroadSearchExportSubmit = (format) => {
    this.setState(
      {
        exportStatus: DataProductDeliveryService.Statuses.DATA_PRODUCT_RUNNING,
      },
      () => this.startBroadSearchExport(format)
    );
  };

  startBroadSearchExport = async (format) => {
    const { searchHdrId, onError, onInfo } = this.props;
    const { inclSpecialChars, inclSeaTubeLinks } = this.state;

    const params = {
      format,
      searchHdrId,
      includeSpecialCharacters: inclSpecialChars,
      includeSeaTubeLinks: inclSeaTubeLinks,
    };

    try {
      const response = await SeaTubeBroadAnnotationService.startExport(params);

      onInfo(response);
      setTimeout(() => this.checkExportStatus(format), CHECK_INTERVAL);
    } catch (error) {
      onError(error);
    }
  };

  checkExportStatus = async (format) => {
    const { searchHdrId, onError, onInfo } = this.props;
    const params = { searchHdrId, format };

    try {
      const response = await SeaTubeBroadAnnotationService.pollExport(params);

      // still exporting
      if (typeof response === 'string') {
        // send a snackbar at most once a minute
        if (
          new Date().valueOf() - lastPollExportSnackbarTime >=
          EXPORT_CHECK_SNACKBAR_INTERVAL
        ) {
          onInfo(response);
          lastPollExportSnackbarTime = new Date().valueOf();
        }
        setTimeout(() => this.checkExportStatus(format), EXPORT_CHECK_INTERVAL);
        return;
      }

      // export completed
      const fileName = `SeaTubeAnnotations_${DateUtils.formatDateAsString(
        new Date()
      )}.${format}`;
      const url = window.URL.createObjectURL(
        new Blob([response], { type: `application/${format}` })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      this.setState({ exportStatus: undefined });
      lastPollExportSnackbarTime = 0;
    } catch (error) {
      onError(error);
    }
  };

  buildPlaylistLines = () => {
    const { searchResults } = this.props;
    const { paddingBefore, paddingAfter } = this.state;
    const playlistLines = [];

    searchResults.forEach((annotation) => {
      // Add time before and after annotations
      const dateFrom = Moment.utc(annotation.startDate)
        .subtract(paddingBefore, 'seconds')
        .toISOString();
      const dateTo = Moment.utc(annotation.endDate)
        .add(paddingAfter, 'seconds')
        .toISOString();

      // DMAS-48178: resourceId is actually annotation.deviceId and
      // resourceTypeId = DEVICE_DATA until playlist is extended to using
      // more than just video data.
      const resourceId = annotation.deviceId.toString();
      const resourceTypeId = DEVICE_DATA_ID.toString();

      playlistLines.push({
        resourceId,
        resourceTypeId,
        dateFrom,
        dateTo,
      });
    });

    return playlistLines;
  };

  createLinkToPlaylist = (playlistHdrId) => (
    <TextButton
      translationKey="seatube.viewPlaylist"
      href={`${Environment.getDmasUrl()}/app/playlists/${playlistHdrId}`}
      target="_blank"
      endIcon={<OpenInNew />}
    />
  );

  handleCreatePlaylistSubmit = async () => {
    const { onError, onInfo, onPlaylistAdd } = this.props;
    const { newPlaylistName } = this.state;
    const playlistLines = this.buildPlaylistLines();

    const params = {
      operation: 1,
      playlistHdrName: newPlaylistName,
    };

    this.setState({ loadingPlaylist: true });

    try {
      const payload = await PlaylistService.createNewPlaylist(params);
      const response = await PlaylistLineService.create(
        payload.playlistHdrId,
        playlistLines
      );
      const { doAllClipsHaveVideo } = response;
      const hasAllVideoMessage = doAllClipsHaveVideo
        ? ' successfully'
        : ', but some clips have missing video';
      onInfo(`Playlist "${newPlaylistName}" created${hasAllVideoMessage}`, {
        action: this.createLinkToPlaylist(payload.playlistHdrId),
        duration: null,
      });
      onPlaylistAdd(); // refresh the existing playlists to add the new one
      this.setState({ newPlaylistName: '' }); // clear the field
    } catch (e) {
      onError(e.message);
    } finally {
      this.setState({ loadingPlaylist: false });
    }
  };

  handleUpdatePlaylistSubmit = async () => {
    const { onError, onInfo } = this.props;
    const { selectedPlaylist } = this.state;
    const playlistLines = this.buildPlaylistLines();

    this.setState({ loadingPlaylist: true });

    try {
      const response = await PlaylistLineService.create(
        selectedPlaylist,
        playlistLines
      );
      const { doAllClipsHaveVideo } = response;
      const hasAllVideoMessage = doAllClipsHaveVideo
        ? ' successfully!'
        : ', but some clips have missing video';
      onInfo(`Playlist updated${hasAllVideoMessage}`, {
        action: this.createLinkToPlaylist(selectedPlaylist),
        duration: null,
      });
      this.setState({ selectedPlaylist: undefined }); // clear the field
    } catch (error) {
      onError(error);
    } finally {
      this.setState({ loadingPlaylist: false });
    }
  };

  renderExportTab = () => {
    const { classes, isLoggedIn, broadSearchContext, searchResults } =
      this.props;
    const {
      inclSpecialChars,
      inclSeaTubeLinks,
      inclVideoSnapshots,
      snapshotsPer,
      timeBetweenSnapshots,
      exportStatus,
      searchId,
      fileName,
    } = this.state;

    return (
      <div className={classes.actionContainer}>
        <SeaTubeExportActionsTab
          canExportDarwinCore={this.canExportDarwinCore()}
          inclSpecialChars={inclSpecialChars}
          inclSeaTubeLinks={inclSeaTubeLinks}
          inclVideoSnapshots={inclVideoSnapshots}
          snapshotsPer={snapshotsPer}
          timeBetweenSnapshots={timeBetweenSnapshots}
          onInclSpecialCharsChange={this.handleCheckboxChange(
            'inclSpecialChars'
          )}
          onInclSeaTubeLinksChange={this.handleCheckboxChange(
            'inclSeaTubeLinks'
          )}
          onInclVideoSnapshotsChange={this.handleCheckboxChange(
            'inclVideoSnapshots'
          )}
          onSnapshotsPerChange={this.handleNumberChange('snapshotsPer', 1)}
          onTimeBetweenSnapshotsChange={this.handleNumberChange(
            'timeBetweenSnapshots'
          )}
          onExportSubmit={
            broadSearchContext
              ? this.handleBroadSearchExportSubmit
              : this.handleExportSubmit
          }
          exportStatus={exportStatus}
          searchId={searchId}
          fileName={fileName}
          disabled={
            searchResults.length === 0 ||
            (exportStatus !== 'none' &&
              exportStatus !== DataProductDeliveryService.Statuses.COMPLETE)
          }
          isLoggedIn={isLoggedIn}
          broadSearchContext={broadSearchContext}
        />
      </div>
    );
  };

  renderExistingPlaylistTab = () => {
    const { classes, playlists, searchResults, isLoggedIn } = this.props;
    const { selectedPlaylist, paddingBefore, paddingAfter, loadingPlaylist } =
      this.state;

    return (
      <div className={classes.actionContainer}>
        <SeaTubeExistingPlaylistTab
          playlists={playlists}
          selectedPlaylist={selectedPlaylist}
          paddingBefore={paddingBefore}
          paddingAfter={paddingAfter}
          onSelectedPlaylistChange={this.handlePlaylistChange(
            'selectedPlaylist'
          )}
          onPaddingBeforeChange={this.handleNumberChange('paddingBefore')}
          onPaddingAfterChange={this.handleNumberChange('paddingAfter')}
          onUpdateSubmit={this.handleUpdatePlaylistSubmit}
          disabled={searchResults.length === 0 || !selectedPlaylist}
          isLoggedIn={isLoggedIn}
          loadingPlaylist={loadingPlaylist}
        />
      </div>
    );
  };

  renderNewPlaylistTab = () => {
    const { classes, searchResults, isLoggedIn } = this.props;
    const { newPlaylistName, paddingBefore, paddingAfter, loadingPlaylist } =
      this.state;

    return (
      <div className={classes.actionContainer}>
        <SeaTubeNewPlaylistTab
          newPlaylistName={newPlaylistName}
          paddingBefore={paddingBefore}
          paddingAfter={paddingAfter}
          onNewPlaylistNameChange={this.handlePlaylistChange('newPlaylistName')}
          onPaddingBeforeChange={this.handleNumberChange('paddingBefore')}
          onPaddingAfterChange={this.handleNumberChange('paddingAfter')}
          onCreateSubmit={this.handleCreatePlaylistSubmit}
          disabled={searchResults.length === 0 || newPlaylistName.length === 0}
          isLoggedIn={isLoggedIn}
          loadingPlaylist={loadingPlaylist}
        />
      </div>
    );
  };

  renderTabs = () => {
    const { tabValue } = this.state;
    return (
      <>
        {tabValue === 0 && this.renderExportTab()}
        {tabValue === 1 && this.renderExistingPlaylistTab()}
        {tabValue === 2 && this.renderNewPlaylistTab()}
      </>
    );
  };

  render() {
    const { classes, isLoggedIn } = this.props;
    const { tabValue } = this.state;

    return (
      <div className={classes.tabContainer}>
        <Tabs value={tabValue} onChange={this.handleTabChange}>
          <Tab label="Export Data" />
          <Tab label="Add To Existing Playlist" />
          <Tab label="Create New Playlist" />
        </Tabs>
        {isLoggedIn ? this.renderTabs() : this.renderLoggedInWarningAlert()}
      </div>
    );
  }
}

export default withStyles(styles)(SearchResultsActionTabs);
