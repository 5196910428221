import React, { useEffect, useState } from 'react';
import { ExpandMore, ExpandLess } from '@onc/icons';

import { Box, Collapse, ListItem } from 'base-components';

import Menu from './Menu';

const styles = {
  nested: {
    paddingLeft: 4,
  },
  listItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center',
  },
};

type ExpansionSubMenuProps = {
  /** Title of this menu */
  menuTitle: string;
  /** React children */
  children?: React.ReactNode;
  /** Callback to add onClick behaviour after expansion */
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  /** Whether or not this menu is expanded */
  expand?: boolean;
  /** Any info added before the menu title */
  infoContent?: React.ReactNode;
};

/** A collapsable Menu that can nest other clickable menu's under it */
const ExpansionSubMenu: React.FC<ExpansionSubMenuProps> = ({
  menuTitle,
  children = undefined,
  onClick = undefined,
  expand = false,
  infoContent = undefined,
}: ExpansionSubMenuProps) => {
  const [expanded, setExpanded] = useState(expand);

  useEffect(() => {
    setExpanded(expand);
  }, [expand]);

  const handleClick = (e: any) => {
    setExpanded(!expanded);
    if (onClick) {
      onClick(e);
    }
  };

  const renderExpandIcon = () =>
    expanded ? <ExpandLess color="action" /> : <ExpandMore color="action" />;

  const renderInfoContent = () => {
    if (infoContent) {
      return infoContent;
    }
    return null;
  };

  return (
    <>
      <ListItem sx={styles.listItem} button onClick={handleClick}>
        <Box sx={styles.flexItem}>
          {renderInfoContent()}
          {menuTitle}
        </Box>
        <Box sx={styles.flexItem}>{renderExpandIcon()}</Box>
      </ListItem>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Menu menuList sx={styles.nested}>
          {children}
        </Menu>
      </Collapse>
    </>
  );
};

export default ExpansionSubMenu;
