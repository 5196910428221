import { FormProvider, UseFormReturn } from 'react-hook-form';

type FormProps<FieldValues> = {
  formMethods: UseFormReturn<FieldValues>;
  onSubmit: (data: FieldValues) => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  noValidate?: boolean; // disables native browser validation
};

const Form = <TFieldValues,>({
  formMethods,
  onSubmit,
  children,
  style = undefined,
  noValidate = false,
}: FormProps<TFieldValues>): React.ReactElement => (
  <FormProvider {...formMethods}>
    <form
      onSubmit={formMethods.handleSubmit(onSubmit)}
      style={style}
      noValidate={noValidate}
    >
      {children}
    </form>
  </FormProvider>
);

export default Form;
