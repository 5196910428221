import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  SearchIconButton,
  PlayIconButton,
  OpenDeckLogIconButton,
} from 'domain/AppComponents/IconButtons';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';

const styles = (theme) => ({
  floatingButtonContainer: {
    marginRight: theme.spacing(0.5),
  },
  iconStyles: {
    fontSize: '1em',
  },
});

class TreeActionContent extends Component {
  static propTypes = {
    onWatchButtonClicked: PropTypes.func,
    onDeckLogButtonClicked: PropTypes.func,
    onSearchButtonClicked: PropTypes.func,
    resourceTypeId: PropTypes.number,
    resourceId: PropTypes.number,
    expeditionId: PropTypes.number,
    classes: PropTypes.shape({
      floatingButtonContainer: PropTypes.string,
      iconStyles: PropTypes.string,
    }),
  };

  static defaultProps = {
    onWatchButtonClicked: undefined,
    onSearchButtonClicked: undefined,
    onDeckLogButtonClicked: undefined,
    resourceTypeId: undefined,
    resourceId: undefined,
    expeditionId: undefined,
    classes: {},
  };

  clickSearchButton = () => {
    const { onSearchButtonClicked, resourceTypeId, resourceId, expeditionId } =
      this.props;

    switch (resourceTypeId) {
      case SeaTubeResourceTypes.EXPEDITION:
        onSearchButtonClicked({ expeditionId: resourceId });
        break;
      case SeaTubeResourceTypes.DIVE:
        onSearchButtonClicked({ expeditionId, diveId: resourceId });
        break;
      case SeaTubeResourceTypes.SEARCH_TREE_NODE:
        onSearchButtonClicked({ searchTreeNodeId: resourceId });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      classes,
      onWatchButtonClicked,
      onSearchButtonClicked,
      onDeckLogButtonClicked,
      resourceTypeId,
      resourceId,
    } = this.props;

    return (
      <div className={classes.floatingButtonContainer}>
        {resourceTypeId !== SeaTubeResourceTypes.EXPEDITION &&
        onWatchButtonClicked ? (
          <PlayIconButton
            iconProps={{ className: classes.iconStyles }}
            onClick={(e) => {
              e.stopPropagation();
              onWatchButtonClicked(resourceId);
            }}
          />
        ) : undefined}
        {resourceTypeId === SeaTubeResourceTypes.EXPEDITION &&
        onDeckLogButtonClicked ? (
          <>
            <OpenDeckLogIconButton
              iconProps={{ className: classes.iconStyles }}
              onClick={(e) => {
                e.stopPropagation();
                onDeckLogButtonClicked({ expeditionId: resourceId });
              }}
            />
            <SearchIconButton
              iconProps={{ className: classes.iconStyles }}
              onClick={(e) => {
                e.stopPropagation();
                onDeckLogButtonClicked({
                  expeditionId: resourceId,
                  includeDeckLog: true,
                });
              }}
            />
          </>
        ) : undefined}
        {onSearchButtonClicked ? (
          <SearchIconButton
            iconProps={{ className: classes.iconStyles }}
            onClick={(e) => {
              e.stopPropagation();
              this.clickSearchButton();
            }}
          />
        ) : undefined}
      </div>
    );
  }
}
export default withStyles(styles)(TreeActionContent);
