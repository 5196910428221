import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import BatchContainer from 'domain/AppComponents/batch/BatchManagement';

const STYLES = (theme) => ({
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    marginBottom: theme.spacing(),
  },
  entryFormFields: {
    marginBottom: theme.spacing(),
  },
  gridPadding: {
    paddingLeft: theme.spacing(3),
  },
});

class PamguardTaskCreateComponent extends Component {
  state = {
    error: null,
  };

  static propTypes = {
    classes: PropTypes.shape({
      formButtons: PropTypes.string,
      entryFormFields: PropTypes.string,
      gridPadding: PropTypes.string,
    }),
  };

  static defaultProps = {
    classes: undefined,
  };

  render() {
    return (
      <div>
        <BatchContainer taskType="PAM_TASK" />
      </div>
    );
  }
}

export default withStyles(STYLES)(PamguardTaskCreateComponent);
