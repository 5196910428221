import { PureComponent, createRef } from 'react';
import { withStyles } from '@mui/styles';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {
  Circle,
  Grid,
  LayerGroup,
  LeafletIcon,
  List,
  ListItem,
  ListItemText,
  MapContext,
  Marker,
  LeafletTooltip as Tooltip,
  Polyline,
} from 'base-components';

import ONCMap from 'domain/AppComponents/map/ONCMap';

import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
  },
  grid: {
    padding: theme.spacing(1),
  },
});
class EarthquakeDetailMap extends PureComponent {
  static propTypes = {
    siteMarkers: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.string,
        associated: PropTypes.bool,
        earthquakeSiteId: PropTypes.number,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      })
    ).isRequired,
    earthquakes: PropTypes.arrayOf(PropTypes.shape({})),
    mmiContours: PropTypes.shape({}),
    epicentre: PropTypes.string,
    onEarthquakeMarkerClick: PropTypes.func.isRequired,
    detectionArea: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
      .isRequired,
    selectedOncDetection: PropTypes.number,
  };

  static defaultProps = {
    selectedOncDetection: undefined,
    epicentre: undefined,
    mmiContours: {},
    earthquakes: [],
  };

  mapRef = createRef();

  mmiColors = [
    '#ffffff',
    '#bfccff',
    '#99f',
    '#8ff',
    '#7df894',
    '#ff0',
    '#fd0',
    '#ff9100',
    '#f00',
    '#d00',
    '#800',
    '#400',
  ];

  constructor(props) {
    super(props);
    this.state = {
      selectedMarker: undefined,
    };
  }

  onPolyLineCreated = (a) => {
    const latlngs = a.layer.editing.latlngs[0];
    let distanceKM = 0;
    for (let i = 1; i < latlngs.length; i += 1) {
      const first = latlngs[i - 1];
      const second = latlngs[i];
      distanceKM += first.distanceTo(second) / 1000;
    }
    a.layer.bindTooltip(`${distanceKM.toFixed(3)} km`);
  };

  renderSiteLayer() {
    const { siteMarkers, selectedOncDetection } = this.props;
    let displayedMarkers = siteMarkers;
    if (selectedOncDetection) {
      const dict = displayedMarkers.reduce(
        (result, obj) => ({
          ...result,
          [obj.location]: { ...obj, associated: false, earthquakeSiteId: 0 },
        }),
        {}
      );

      for (let i = 0; i < selectedOncDetection.sites.length; i += 1) {
        const site = selectedOncDetection.sites[i];
        dict[site.location] = site;
      }
      displayedMarkers = Object.values(dict);
    }
    const markerMap = { associated: [], unassociated: [], noDetection: [] };
    for (let i = 0; i < displayedMarkers.length; i += 1) {
      const site = displayedMarkers[i];
      let colour = LeafletIcon.DEFAULT;
      let markerList = markerMap.associated;
      if (site.earthquakeSiteId === 0) {
        colour = LeafletIcon.GREY;
        markerList = markerMap.noDetection;
      } else if (!site.associated) {
        colour = LeafletIcon.RED;
        markerList = markerMap.unassociated;
      }
      const mapMarker = (
        <Marker
          key={site.location}
          position={[site.latitude, site.longitude]}
          icon={colour}
          eventHandlers={{
            click: (marker) => this.setState({ selectedMarker: marker }),
          }}
          siteInfo={site}
        >
          <Tooltip permanent={false}>{site.location}</Tooltip>
        </Marker>
      );
      markerList.push(mapMarker);
    }
    return markerMap;
  }

  renderMMIContours() {
    const { mmiContours, epicentre } = this.props;
    let i;
    const layers = [];
    if (epicentre) {
      const parts = epicentre.split(',');
      const lat = parseFloat(parts[0]);
      const lon = parseFloat(parts[1]);
      const keys = Object.keys(mmiContours);
      for (i = 0; i < keys.length; i += 1) {
        const mmi = keys[i];
        let mmiColorIndex = mmi - 1;
        if (mmiColorIndex >= this.mmiColors.length) {
          mmiColorIndex = this.mmiColors.length - 1;
        }
        const radius = mmiContours[mmi];
        const layerName = `MMI ${mmi} contour`;
        layers.push({
          name: layerName,
          subLayers: (
            <LayerGroup>
              <Circle
                center={[lat, lon]}
                color={this.mmiColors[mmiColorIndex]}
                fill={false}
                radius={radius}
              />
            </LayerGroup>
          ),
          checked: true,
        });
      }
    }
    return layers;
  }

  renderOverlays() {
    const { detectionArea } = this.props;
    const mmiContours = this.renderMMIContours();
    const mapMarkers = this.renderSiteLayer();
    const layers = [
      {
        name: 'Sites: associated',
        subLayers: <LayerGroup>{mapMarkers.associated}</LayerGroup>,
        checked: true,
      },
      {
        name: 'Sites: unassociated',
        subLayers: <LayerGroup>{mapMarkers.unassociated}</LayerGroup>,
        checked: true,
      },
      {
        name: 'Sites: no detection',
        subLayers: <LayerGroup>{mapMarkers.noDetection}</LayerGroup>,
        checked: true,
      },
      {
        name: 'Detection Area',
        subLayers: (
          <LayerGroup>
            <Polyline positions={detectionArea} color="red" opacity={0.7} />
          </LayerGroup>
        ),
        checked: true,
      },
    ];
    return layers.concat(mmiContours);
  }

  renderMarkerConfig() {
    const { earthquakes, onEarthquakeMarkerClick } = this.props;
    const markers = [];
    if (earthquakes) {
      earthquakes.forEach((eq) => {
        let colour = '#2E8BC0';
        let organization = 'ONC';
        if (eq.organizationId === 29) {
          colour = '#DF362D';
          organization = 'NRCan';
        } else if (eq.organizationId === 126) {
          colour = '#94C973';
          organization = 'USGS';
        }
        markers.push({
          markerId: eq.earthquakeId,
          earthquakeDetailId: eq.earthquakeDetailId,
          lon: eq.longitude,
          lat: eq.latitude,
          tooltip: `${organization}: ${eq.originTime}`,
          colour,
          onClick: onEarthquakeMarkerClick,
        });
      });
    }
    return {
      onClick: () => {},
      name: 'Earthquakes',
      checked: true,
      markers,
    };
  }

  renderDeviceLink = (deviceId, deviceName) => {
    if (deviceId) {
      const deviceUrl = `${Environment.getDmasUrl()}/DeviceListing?DeviceId=${deviceId}`;
      return <OpenInNewLink href={deviceUrl}>{deviceName}</OpenInNewLink>;
    }
    return <></>;
  };

  renderDeviceContributions = (siteInfo) => {
    if (
      siteInfo.accelerometerName &&
      siteInfo.accelerometerName.includes('PNSN')
    ) {
      return <></>;
    }
    return (
      <ListItem>
        <ListItemText
          primary="Devices Contributing To Detection"
          secondary={
            <>
              {this.renderDeviceLink(
                siteInfo.accelerometerDeviceId,
                siteInfo.accelerometerName
              )}
              {this.renderDeviceLink(
                siteInfo.floatDeviceId,
                siteInfo.floatName
              )}
              {this.renderDeviceLink(
                siteInfo.integerDeviceId,
                siteInfo.integerName
              )}
              {this.renderDeviceLink(
                siteInfo.orbitDeviceId,
                siteInfo.orbitName
              )}
            </>
          }
        />
      </ListItem>
    );
  };

  renderLocationLink = (searchTreeNodeId, searchTreeNodeName, location) => {
    if (searchTreeNodeId) {
      const href = `${Environment.getDmasUrl()}/SearchTreeMaintenance?searchTreeNodeId=${searchTreeNodeId}`;
      return (
        <OpenInNewLink href={href}>
          {searchTreeNodeName} - {location}
        </OpenInNewLink>
      );
    }
    const parts = location.split('.');
    if (parts.length === 1) {
      const href = `https://ds.iris.edu/gmap/#network=UW&station=${parts[0]}&channel=HN*&planet=earth`;
      return <OpenInNewLink href={href}>{location}</OpenInNewLink>;
    }
    const href = `https://ds.iris.edu/gmap/#network=UW&station=${parts[0]}&location=${parts[1]}&channel=HN*&planet=earth`;
    return <OpenInNewLink href={href}>{location}</OpenInNewLink>;
  };

  renderMarkerInfoPanel = () => {
    const { selectedMarker } = this.state;
    if (selectedMarker) {
      const { siteInfo } = selectedMarker.target.options;
      return (
        <Panel title={<h3>Selected Site details</h3>}>
          <List>
            <ListItem>
              <ListItemText
                primary="Location"
                secondary={
                  <>
                    {this.renderLocationLink(
                      siteInfo.searchTreeNodeId,
                      siteInfo.searchTreeNodeName,
                      siteInfo.location
                    )}
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="latitude, longitude"
                secondary={`${selectedMarker.latlng.lat},${selectedMarker.latlng.lng}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Distance To Epicentre"
                secondary={`${selectedMarker.target.options.siteInfo.distanceToEpi} km`}
              />
            </ListItem>
            {this.renderDeviceContributions(siteInfo)}
          </List>
        </Panel>
      );
    }
    return <Panel title={<h3>Selected Site details</h3>} />;
  };

  render() {
    L.drawLocal.draw.toolbar.buttons.polyline = 'Measure distance';
    const { selectedMarker } = this.state;
    let selectedMarkerId;
    if (selectedMarker) {
      selectedMarkerId = selectedMarker.target.options.siteInfo.location;
    }

    return (
      <MapContext.Provider value={this.mapRef}>
        <Grid item xs={12} lg={12} xl={12}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Panel>
                <div style={{ width: '100%', height: '600px' }}>
                  <ONCMap
                    selectedMarkerId={selectedMarkerId}
                    initialZoom={6}
                    center={[49, -127]}
                    defaultLayerName="Open Street"
                    mapId="onc-map"
                    bathymetry={false}
                    markerConfig={this.renderMarkerConfig()}
                    overlayLayers={this.renderOverlays()}
                    maxZoom={12}
                    editControl={{
                      onCreated: this.onPolyLineCreated,
                      drawConfig: {
                        rectangle: false,
                        polygon: false,
                        polyline: {
                          shapeOptions: {
                            color: '#f357a1',
                            weight: 10,
                          },
                        },
                        circle: false,
                        marker: false,
                        circlemarker: false,
                      },
                      editConfig: { edit: false },
                    }}
                  />
                </div>
              </Panel>
            </Grid>
            <Grid item xs={3}>
              {this.renderMarkerInfoPanel()}
            </Grid>
          </Grid>
        </Grid>
      </MapContext.Provider>
    );
  }
}

export default withStyles(styles)(withSnackbars(EarthquakeDetailMap));
