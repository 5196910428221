import * as React from 'react';
import Checkbox from './Checkbox';
import FormControlLabel, {
  type FormControlLabelProps,
} from './form/FormControlLabel';

interface LabelInterface extends FormControlLabelProps {
  value?: boolean;
}

// control prop is handled by this component and shouldn't be accepted
export type LabelledCheckboxProps = Omit<LabelInterface, 'control'>;

const LabelledCheckbox: React.FC<LabelledCheckboxProps> = (
  props: LabelledCheckboxProps
) => {
  const { labelPlacement = 'end', value = false } = props;
  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      control={<Checkbox checked={value} />}
      {...props}
    />
  );
};

export default LabelledCheckbox;
