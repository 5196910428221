import * as WebRequest from 'util/WebRequest';

export interface Playlist {
  playlistHdrId: number;
  groupName: string;
  name: string;
  description: string;
  createdBy: number;
  createdDate: string;
  modifyDate: string;
  permission: string;
  playlistLines: PlaylistLineInfo[];
  locked: boolean;
  lockReason?: string;
}

export interface PlaylistLineInfo {
  playlistLineId: number;
  playlistLineName: string;
  playlistHdrId: number;
  dateFrom: string;
  duration: number;
  resource: {
    name: string;
    resourceId: number;
  };
}

export interface PlaylistHdrInfo {
  playlistHdrId: number;
  playlistHdrName: string;
  playlistHdrDescription: string;
  groupName: string;
  locked?: boolean;
  lockReason?: string;
}

export interface PlaylistInfo extends PlaylistHdrInfo {
  createdBy: number;
  modifyBy: number;
  modifyDate: string;
}

class PlaylistService {
  /**
   * Function for creating a new playlist from an annotation search
   *
   * @param {object} params
   * @param {string} params.playlistHdrName - The name for the new playlist
   */
  static async createNewPlaylist(params: {
    playlistHdrName: number;
  }): Promise<PlaylistInfo> {
    const response = await WebRequest.post('internal/playlists', params);

    return response.data;
  }

  /**
   * Function that updates a playlistHdr's info
   *
   * @param params See PlaylistHdrInfo above
   */
  static async updatePlaylistHdr(
    params: PlaylistHdrInfo
  ): Promise<{ playlistHdrId: number; playlistHdrName: string }> {
    const response = await WebRequest.patch(
      `internal/playlists/${params.playlistHdrId}`,
      params,
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  /**
   * Function for updating the groupName on multiple playlists
   *
   * @param {object} params
   * @param {string} params.groupName
   * @param {number[]} params.playlistHdrIds
   */
  static async updateGroupNameMultiplePlaylists({
    groupName,
    playlistHdrIds,
  }): Promise<{
    updatedPlaylists: Array<{
      playlistHdrId: number;
      playlistHdrName: string;
      groupName: string;
    }>;
  }> {
    const response = await WebRequest.patch(
      'internal/playlists',
      {
        groupName,
        playlistHdrIds: playlistHdrIds.toString(),
      },
      { headers: { 'content-type': 'application/json' } }
    );
    return response.data;
  }

  static async getPlaylists(): Promise<Playlist[]> {
    const response = await WebRequest.get('internal/playlists');
    return response.data;
  }

  static async getPlaylist(playlistHdrId: number): Promise<Playlist> {
    const response = await WebRequest.get(
      `internal/playlists/${playlistHdrId}`
    );
    return response.data;
  }

  /**
   * Deletes a PlaylistHdr
   *
   * @param {number} playlistHdrId
   * @returns {Promise}
   */
  static async delete(playlistHdrId: number): Promise<PlaylistHdrInfo> {
    const response = await WebRequest.deleteImpl(
      `internal/playlists/${playlistHdrId}`
    );
    return response.data;
  }
}

export default PlaylistService;
