import { parseDmasAPIResponse, get } from 'util/WebRequest';

class DataSearchService {
  /**
   * Function for checking the status of a data product search
   *
   * @param {number} id - The searchHdrId
   */
  static checkSearchStatus = async (id) =>
    get('DataSearchService', { id }).then(
      (response) => parseDmasAPIResponse(response)[0]
    );
}

export default DataSearchService;
