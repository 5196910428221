import * as React from 'react';
import { Plugin } from '@devexpress/dx-react-core';
import {
  PagingState,
  CustomPaging,
  PagingStateProps,
  CustomPagingProps,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  PagingPanel,
  PagingPanelProps,
} from '@devexpress/dx-react-grid-material-ui';

/**
 * Pagination component for displaying and controlling paging functionality.
 *
 * The `Pagination` component provides paging functionality for displaying a
 * large set of data in smaller chunks. It supports custom paging or integrated
 * paging based on the presence of the `totalCount` prop. If `totalCount` is
 * provided, the `CustomPaging` component will be used, otherwise the
 * `IntegratedPaging` component will be used.
 *
 * @param {PagingProps} props - The props for the Pagination component.
 */

export type PagingProps = PagingStateProps &
  CustomPagingProps &
  PagingPanelProps;

const Pagination: React.FC<PagingProps> = (props: PagingProps) => {
  const {
    currentPage,
    defaultCurrentPage = 0,
    onCurrentPageChange,
    pageSize,
    onPageSizeChange,
    totalCount,
    pageSizes,
    containerComponent = PagingPanel.Container,
  } = props;

  return (
    <Plugin>
      <PagingState
        defaultCurrentPage={defaultCurrentPage}
        currentPage={currentPage}
        onCurrentPageChange={onCurrentPageChange}
        defaultPageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
      />
      {totalCount !== undefined ? (
        <CustomPaging totalCount={totalCount} />
      ) : (
        <IntegratedPaging />
      )}
      <PagingPanel
        aria-label="pagingPanel"
        pageSizes={pageSizes}
        containerComponent={containerComponent}
      />
    </Plugin>
  );
};
export default Pagination;
