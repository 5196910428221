import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Cancel, Delete, Copy } from '@onc/icons';
import { Grid, IconButton } from 'base-components';

import Status from '../Status';
import { isTestRunning } from '../TestUtil';

class TestActionsBar extends Component {
  static propTypes = {
    test: PropTypes.shape({
      dafTestId: PropTypes.number.isRequired,
      deviceId: PropTypes.number.isRequired,
      duration: PropTypes.shape({ seconds: PropTypes.number.isRequired })
        .isRequired,
      ipAddress: PropTypes.string.isRequired,
      status: PropTypes.string,
    }).isRequired,

    handleStopTest: PropTypes.func.isRequired,
  };

  secondAction = () => {
    const { test, handleStopTest } = this.props;
    if (test.status === Status.RUNNING) {
      handleStopTest(test.dafTestId);
    }
  };

  renderSecondaryActionIcon = () => {
    const { test } = this.props;
    if (test.status === Status.RUNNING || test.status === Status.CANCELLING) {
      return <Cancel />;
    }
    return <Delete />;
  };

  render() {
    const { test } = this.props;
    return (
      <Grid container justifyContent="flex-end">
        <IconButton
          component={Link}
          to={{
            pathname: `${window.location.pathname}/test/`,
            search: `?deviceId=${test.deviceId}&duration=${
              test.duration.seconds / 60
            }&ipAddress=${test.ipAddress}`,
          }}
        >
          <Copy />
        </IconButton>

        <IconButton
          disabled={!isTestRunning(test.dafTestId, test.status)}
          onClick={this.secondAction}
        >
          {this.renderSecondaryActionIcon()}
        </IconButton>
      </Grid>
    );
  }
}

export default TestActionsBar;
