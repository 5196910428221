import {
  Fab as MaterialFab,
  FabProps as MaterialFabProps,
  TooltipProps,
} from '@mui/material';
import Tooltip from './Tooltip';

/** PropTypes for Material's FAB */
export type FabProps = MaterialFabProps & {
  'aria-label': string;
  children: React.ReactNode;
  tooltipProps?: Partial<TooltipProps>;
  containerClasses?: string; // Something used by withToolip so carrying it over here
};
/**
 * Wrapper for Fab material ui component. See https://material-ui.com/api/fab/
 * for more information.
 */

const Fab = ({
  children,
  'aria-label': ariaLabel,
  tooltipProps = undefined,
  containerClasses = '',
  ...props
}: FabProps) => (
  <Tooltip title={ariaLabel} {...tooltipProps}>
    <span className={containerClasses}>
      <MaterialFab aria-label={ariaLabel} {...props}>
        {children}
      </MaterialFab>
    </span>
  </Tooltip>
);

export default Fab;
