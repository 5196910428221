/* eslint-disable react/require-default-props */
// Above disable is due to eslint getting false positives on forward refs
import { forwardRef, memo, type ReactElement } from 'react';
import { TooltipProps } from '@mui/material';
import MaterialIconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '../Tooltip';

export interface OncIconButtonProps
  extends Omit<IconButtonProps, 'aria-label'> {
  'aria-label': string;
  children?: ReactElement;
  tooltipProps?: Partial<TooltipProps>;
  Icon?: React.ElementType;
}

const IconButton = forwardRef<HTMLButtonElement, OncIconButtonProps>(
  (
    {
      children = undefined,
      'aria-label': ariaLabel,
      tooltipProps = {},
      Icon = undefined,
      ...props
    },
    ref
  ) => (
    <Tooltip title={ariaLabel} {...tooltipProps}>
      <span>
        <MaterialIconButton ref={ref} aria-label={ariaLabel} {...props}>
          {Icon ? <Icon /> : children}
        </MaterialIconButton>
      </span>
    </Tooltip>
  )
);

export default memo(IconButton);
