import { memo, useEffect, useState } from 'react';
import * as React from 'react';
import { Dropdown, type DropdownOption } from 'base-components';
import CruiseService, { CruiseJSON } from 'domain/services/CruiseService';

interface Props {
  options?: DropdownOption[];
  value?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onError?: (error: any) => void;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

const CruiseDropdownNew: React.VFC<Props> = ({
  options = undefined,
  value = undefined,
  onChange,
  label = 'Cruise Name',
  className = undefined,
  fullWidth = undefined,
  disabled = undefined,
  onError = () => {},
}: Props) => {
  // use controlled options if they are defined as a prop, otherwise default to a service call
  const [optionsState, setOptionsState] = useState(options || []);

  useEffect(
    () => {
      if (!options) {
        CruiseService.getCruises()
          .then((response) => {
            setOptionsState(
              response.cruises
                .map((cruise: CruiseJSON) => ({
                  key: cruise.cruiseId,
                  label: cruise.cruiseName,
                  value: cruise.cruiseId,
                }))
                .sort((a: DropdownOption, b: DropdownOption) => {
                  const labelA = a.label.toUpperCase();
                  const labelB = b.label.toUpperCase();
                  return labelA <= labelB ? -1 : 1;
                })
            );
          })
          .catch((error) => {
            onError(error);
            return [];
          });
      } else {
        setOptionsState(options);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  return (
    <Dropdown
      options={optionsState}
      value={value}
      onChange={onChange}
      label={label}
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
    />
  );
};

export default memo(CruiseDropdownNew);
