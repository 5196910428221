class SeaTubeResourceTypes {
  static DEVICE = 2;

  static DEVICE_DATA = 1000;

  static DIVE = 600;

  static DMAS_USER = 14;

  static EXPEDITION = 601;

  static ORGANIZATION = 15;

  static PLAYLIST = 20;

  static SEARCH_TREE_NODE = 11;
}

export default SeaTubeResourceTypes;
