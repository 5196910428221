/* eslint-disable react/require-default-props */
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from '@onc/i18n';
import { Dropdown, TextField } from 'base-components';

import { MarginedReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';

const rorwTextFieldPropTypes = {
  translationKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  permission: PropTypes.string,
  classes: PropTypes.shape({}),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string,
};

const RORWTextField = (props) => {
  const { translationKey, value, permission, name, ...rest } = props;
  const { t } = useTranslation();

  if (permission === 'RW') {
    return (
      <TextField
        fullWidth
        translationKey={translationKey}
        value={value}
        id={_.uniqueId()}
        name={name}
        {...rest}
      />
    );
  }

  return (
    <MarginedReadOnlyField
      fullWidth
      labelText={t(translationKey, {})}
      valueText={value}
      id={name}
      {...rest}
    />
  );
};
RORWTextField.propTypes = rorwTextFieldPropTypes;

const VibrationSusceptibilitySelect = (props) => {
  VibrationSusceptibilitySelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };
  const { value, onChange, permission } = props;
  const label = 'Susceptibility to vibration: ';
  const name = 'vibrationsusceptibility';
  if (permission === 'RW') {
    return (
      <Dropdown
        name={name}
        label={label}
        fullWidth
        value={value}
        onChange={onChange}
        options={[
          { value: undefined, label: 'None' },
          { value: 'Low', label: 'Low' },
          { value: 'Medium', label: 'Medium' },
          { value: 'High', label: 'High' },
        ]}
        {...props}
      />
    );
  }
  return (
    <MarginedReadOnlyField
      name={name}
      fullWidth
      labelText={label}
      valueText={value}
      {...props}
    />
  );
};

const ShockSusceptibilitySelect = (props) => {
  ShockSusceptibilitySelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };
  const { value, onChange, permission } = props;
  const label = 'Susceptibility to shock: ';
  const name = 'shocksusceptibility';
  if (permission === 'RW') {
    return (
      <Dropdown
        name={name}
        label={label}
        fullWidth
        value={value}
        onChange={onChange}
        options={[
          { value: undefined, label: 'None' },
          { value: 'Low', label: 'Low' },
          { value: 'Medium', label: 'Medium' },
          { value: 'High', label: 'High' },
        ]}
      />
    );
  }
  return (
    <MarginedReadOnlyField
      fullWidth
      name={name}
      labelText={label}
      valueText={value}
      {...props}
    />
  );
};

const TemperatureChangeSusceptibilitySelect = (props) => {
  TemperatureChangeSusceptibilitySelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };
  const { value, onChange, permission } = props;
  const label = 'Susceptibility to temperature change: ';
  const name = 'temperaturesusceptibility';
  if (permission === 'RW') {
    return (
      <Dropdown
        name={name}
        label={label}
        fullWidth
        value={value}
        onChange={onChange}
        options={[
          { value: undefined, label: 'None' },
          { value: 'Low', label: 'Low' },
          { value: 'Medium', label: 'Medium' },
          { value: 'High', label: 'High' },
        ]}
      />
    );
  }
  return (
    <MarginedReadOnlyField
      name={name}
      fullWidth
      labelText={label}
      valueText={value}
      {...props}
    />
  );
};

const ProtrusionSelect = (props) => {
  ProtrusionSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };
  const { value, onChange, permission } = props;
  const label = 'Protrusion: ';
  const name = 'protrusion';
  if (permission === 'RW') {
    return (
      <Dropdown
        name={name}
        label={label}
        fullWidth
        value={value}
        onChange={onChange}
        options={[
          { value: undefined, label: 'None' },
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
      />
    );
  }
  return (
    <MarginedReadOnlyField
      name={name}
      fullWidth
      labelText={label}
      valueText={value}
      {...props}
    />
  );
};

const CoatingSelect = (props) => {
  CoatingSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };
  const { value, onChange, permission } = props;
  const label = 'Coating: ';
  const name = 'coating';
  if (permission === 'RW') {
    return (
      <Dropdown
        name={name}
        label={label}
        fullWidth
        value={value}
        onChange={onChange}
        options={[
          { value: undefined, label: 'None' },
          { value: 'Anodised', label: 'Anodised' },
          { value: 'Painted', label: 'Painted' },
        ]}
      />
    );
  }
  return (
    <MarginedReadOnlyField
      name={name}
      fullWidth
      labelText={label}
      valueText={value}
      {...props}
    />
  );
};

const AnodesSelect = (props) => {
  AnodesSelect.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };
  const { value, onChange, permission } = props;
  const label = 'Anodes: ';
  const name = 'anodes';
  if (permission === 'RW') {
    return (
      <Dropdown
        name={name}
        label={label}
        fullWidth
        value={value}
        onChange={onChange}
        options={[
          { value: undefined, label: 'None' },
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
      />
    );
  }
  return (
    <MarginedReadOnlyField
      name={name}
      fullWidth
      labelText={label}
      valueText={value}
      {...props}
    />
  );
};

export {
  VibrationSusceptibilitySelect,
  ShockSusceptibilitySelect,
  TemperatureChangeSusceptibilitySelect,
  ProtrusionSelect,
  CoatingSelect,
  AnodesSelect,
  RORWTextField,
};
