import { Component } from 'react';
import { createStyles, withStyles } from '@mui/styles';
import DeviceCategory from 'domain/Apps/network-console/device-category/DeviceCategory';
import DeviceCategoryService from 'domain/Apps/network-console/device-category/DeviceCategoryService';
import SortableLink from 'domain/Apps/network-console/SortableLink';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import { AbsoluteRightButtonStyle } from 'library/CompositeComponents/button/CommonButtonStyles';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';
import DeviceCategoryDialogBox from './DeviceCategoryDialogBox';

const styles = (theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(2)})`,
      minWidth: theme.spacing(50),
    },
    button: {
      ...AbsoluteRightButtonStyle(theme),
      position: 'absolute',
      zIndex: 4,
    },
  });

type Props = {
  onError: (message: any, callback?: any) => void;
  classes: {
    root: string;
    button: string;
  };
};

type State = {
  tableData: Array<DeviceCategory>;
  openCreateNewDialog: boolean;
  openEditDialog: boolean;
  deviceCategoryToEdit: DeviceCategory | null;
  isLoading: boolean;
};

const columns = [
  { title: 'Device Category ID', name: 'id' },
  { title: 'hiddenId', name: 'hiddenId', hidden: true },
  { title: 'Device Category Name', name: 'name' },
  { title: 'Device Category Code', name: 'deviceCategoryCode' },
  { title: 'Description', name: 'description' },
  { title: 'Long Description', name: 'longDescription' },
  { title: 'Additional Info', name: 'additionalInfo' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'Modified By', name: 'modifyByName' },
];

const columnExtensions = [
  {
    columnName: 'id',
    align: 'center',
  },
  {
    columnName: 'name',
    align: 'center',
  },
  {
    columnName: 'deviceCategoryCode',
    align: 'center',
  },
  {
    columnName: 'description',
    align: 'center',
    wordWrapEnabled: true,
  },
  {
    columnName: 'longDescription',
    align: 'center',
    wordWrapEnabled: true,
  },
  {
    columnName: 'additionalInfo',
    align: 'center',
    wordWrapEnabled: true,
  },
  {
    columnName: 'hiddenDeviceCatId',
  },
  {
    columnName: 'modifyBy',
    align: 'center',
  },
  {
    columnName: 'modifyDate',
    align: 'center',
  },
  {
    columnName: 'modifyByName',
    align: 'center',
  },
];

class DeviceCategoryPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tableData: [],
      openCreateNewDialog: false,
      openEditDialog: false,
      deviceCategoryToEdit: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getDeviceCategories();
  }

  onDeviceCategoryIdClick = (deviceCategory: DeviceCategory) => {
    this.setState({
      openEditDialog: true,
      deviceCategoryToEdit: deviceCategory,
      openCreateNewDialog: false,
    });
  };

  getDeviceCategories = async () => {
    const { onError } = this.props;
    try {
      this.setState({ isLoading: true });
      const response = await DeviceCategoryService.getAllDeviceCategories();
      const permission = Environment.getDmasUserPrivilege() === 'RW';
      const tableData: Array<DeviceCategory> = response.data.payload.map(
        (deviceCategory) => ({
          additionalInfo: deviceCategory.additionalInfo,
          description: deviceCategory.description,
          deviceCategoryCode: deviceCategory.deviceCategoryCode,
          id: permission ? (
            <SortableLink
              onClick={() => {
                this.onDeviceCategoryIdClick({
                  additionalInfo: deviceCategory.additionalInfo,
                  description: deviceCategory.description,
                  deviceCategoryCode: deviceCategory.deviceCategoryCode,
                  id: deviceCategory.deviceCategoryId,
                  longDescription: deviceCategory.longDescription,
                  name: deviceCategory.deviceCategoryName,
                  modifyBy: deviceCategory.modifyBy,
                  modifyDate: deviceCategory.modifyDate,
                  modifyByName: deviceCategory.modifyByName,
                });
              }}
              id={deviceCategory.deviceCategoryId}
            />
          ) : (
            deviceCategory.deviceCategoryId
          ),
          longDescription: deviceCategory.longDescription,
          name: deviceCategory.deviceCategoryName,
          modifyBy: deviceCategory.modifyBy,
          modifyDate: DateFormatUtils.formatDate(
            deviceCategory.modifyDate,
            'full'
          ),
          modifyByName: deviceCategory.modifyByName,
          hiddenId: deviceCategory.deviceCategoryId,
        })
      );
      this.setState({
        tableData,
        openCreateNewDialog: false,
        openEditDialog: false,
        deviceCategoryToEdit: null,
        isLoading: false,
      });
    } catch (e) {
      onError(e);
      this.setState({ isLoading: false });
    }
  };

  handleResponse = (response: any): void => {
    if (!response || !response.data) {
      return;
    }
    if (response.data.statusCode === 0) {
      this.getDeviceCategories();
    } else if (response.data.statusCode === 105) {
      const { onError } = this.props;
      onError(`${response.data.message}. Device Category Code must be unique`);
    } else if (response.data.statusCode === 403) {
      const { onError } = this.props;
      onError('Please login to create');
    }
  };

  createNewDeviceCategory = async (deviceCategory: DeviceCategory) => {
    const response = await DeviceCategoryService.create(deviceCategory);
    this.handleResponse(response);
  };

  updateDeviceCategory = async (deviceCategory: DeviceCategory) => {
    const response = await DeviceCategoryService.update(deviceCategory);
    this.handleResponse(response);
  };

  renderCreateDeviceCategoryButton = () => {
    const { classes } = this.props;
    const permission = Environment.getDmasUserPrivilege();
    const disabled = permission === 'RW';
    return (
      <ContainedButton
        translationKey="device.createDeviceCategory"
        className={classes.button}
        disabled={!disabled}
        onClick={() =>
          this.setState({
            openCreateNewDialog: true,
            openEditDialog: false,
          })
        }
      />
    );
  };

  render() {
    const {
      tableData,
      openCreateNewDialog,
      openEditDialog,
      deviceCategoryToEdit,
      isLoading,
    } = this.state;
    const permission = Environment.getDmasUserPrivilege();
    const readWritePermission = permission === 'RW';
    return (
      <div>
        {this.renderCreateDeviceCategoryButton()}
        {openCreateNewDialog ? (
          <DeviceCategoryDialogBox
            onClose={() => {
              this.setState({ openCreateNewDialog: false });
            }}
            onSubmit={this.createNewDeviceCategory}
            label="Create New Device Category"
          />
        ) : null}
        {openEditDialog ? (
          <DeviceCategoryDialogBox
            onClose={() => {
              this.setState({ openEditDialog: false });
            }}
            onSubmit={this.updateDeviceCategory}
            deviceCategory={deviceCategoryToEdit}
            label="Edit Device Category"
          />
        ) : null}
        <Panel>
          <SortableTable
            searchBarMoveable
            pageSize={15}
            searchable
            columns={columns}
            columnExtensions={columnExtensions}
            sortExtensions={
              readWritePermission
                ? [
                    {
                      columnName: 'id',
                      compare: (a, b) => (a.props.id > b.props.id ? 1 : -1),
                    },
                  ]
                : []
            }
            rows={tableData}
            stripedRows
            elevation={0}
            pageSizes={[15, 30, 60, 0]}
            hiddenColumns={['hiddenId', 'modifyBy']}
            isLoading={isLoading}
          />
        </Panel>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(DeviceCategoryPage));
