import { createRef, Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { AutoSizer } from 'react-virtualized';
import { Search } from '@onc/icons';
import {
  Checkbox,
  Divider,
  HelpLink,
  InputAdornment,
  ListItem,
  ListItemText,
  SizeableVirtualList,
  TextField,
  Typography,
  Menu,
  MenuItem,
} from 'base-components';
import { SearchIconButton } from 'domain/AppComponents/IconButtons';
import ChatMessageLine from 'library/CompositeComponents/annotation-list/ChatMessageLine';
import Panel from 'library/CompositeComponents/panel/Panel';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import CookieUtils from 'util/CookieUtils';
import DomUtil from 'util/DomUtils';

const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  title: {
    paddingRight: '10px',
  },
  listItem: {
    padding: '0',
    paddingRight: theme.spacing(4),
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '95%',
  },
  searchBar: {
    width: '100%',
  },
});
const ANNOTATION_PADDING_WIDTH = 16;
const SCROLLBAR_WIDTH = 17;
const NAV_USERNAME = 'okexnav';
const DEFAULT_MENU = {
  'Chat Log Options': {
    displayFullTimestamp: 'Full Timestamp',
    coloredUsernames: 'Colored Usernames',
    displayNavMessages: 'Show Navigation Messages',
    displaySystemMessages: 'Show System Messages',
  },
  'Chat Log Line Options': {
    displayDeleteIcon: 'Display Delete Icon',
  },
};

class ChatLogList extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onMessageClick: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string,
      title: PropTypes.string,
      listItem: PropTypes.string,
      noData: PropTypes.string,
      searchBar: PropTypes.string,
      threeDotMenu: PropTypes.string,
    }).isRequired,
    menu: PropTypes.node,
    loading: PropTypes.bool,
    menuOptions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
    onUpdateConfig: PropTypes.func,
    userType: PropTypes.string,
    chatUserMap: PropTypes.arrayOf(PropTypes.shape({})),
    messages: PropTypes.arrayOf(PropTypes.shape({})),
    headerDraggable: PropTypes.bool,
    listHeight: PropTypes.number,
    currentTimestamp: PropTypes.instanceOf(Date),
    isPreviewMode: PropTypes.bool,
    deletePermissions: PropTypes.bool,
    onChatMessageDeleteClick: PropTypes.func,
    onChatMessageDeleteAllClick: PropTypes.func,
  };

  static defaultProps = {
    menu: undefined,
    loading: false,
    chatUserMap: undefined,
    onUpdateConfig: () => {},
    userType: undefined,
    messages: [],
    menuOptions: DEFAULT_MENU,
    headerDraggable: false,
    listHeight: undefined,
    currentTimestamp: undefined,
    isPreviewMode: false,
    deletePermissions: false,
    onChatMessageDeleteClick: () => {},
    onChatMessageDeleteAllClick: () => {},
  };

  primaryTextStyle = {
    fontSize: '0.875rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
  };

  /* secondaryTextStyle = {
    fontSize: '0.74rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.03333em',
  }; */

  constructor(props) {
    super(props);
    let config;
    if (props.userType) {
      config = CookieUtils.getCookie(`${props.userType}-${props.name}-config`);
    }

    this.state = {
      displayFullTimestamp: config ? config.displayFullTimestamp : false,
      displayDeleteIcon: config ? config.displayDeleteIcon : false,
      coloredUsernames: config ? config.coloredUsernames : true,
      displaySystemMessages: config ? config.displaySystemMessages : true,
      displayNavMessages: config ? config.displayNavMessages : true,
      filteredListItems: props.messages,
      listRef: createRef(),
      selectedIndex: undefined,
      searchBarVisible: false,
      menuState: props.menuOptions,
    };
    this.interval = null;
  }

  /* LIFECYCLE METHODS */
  componentDidMount() {
    this.buildMenuObject();
    this.setFilteredListItems();
  }

  componentDidUpdate(prevProps, prevState) {
    // Current
    const { messages, currentTimestamp, deletePermissions } = this.props;
    const { displaySystemMessages, displayNavMessages } = this.state;
    // Previous
    const {
      messages: prevMessages,
      currentTimestamp: prevTimestamp,
      deletePermissions: prevDeletePermissions,
    } = prevProps;
    const {
      displaySystemMessages: prevDisplaySystemMessages,
      displayNavMessages: prevDisplayNavMessages,
    } = prevState;

    if (
      displaySystemMessages !== prevDisplaySystemMessages ||
      displayNavMessages !== prevDisplayNavMessages ||
      messages !== prevMessages
    ) {
      this.setFilteredListItems();
      this.recomputeRowHeights();
    }

    if (currentTimestamp !== prevTimestamp) {
      this.selectClosestListItem(currentTimestamp);
    }

    if (deletePermissions !== prevDeletePermissions) {
      this.buildMenuObject();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  /* ------------------- */

  buildMenuObject = () => {
    const { isPreviewMode, deletePermissions } = this.props;
    const { menuState } = this.state;

    const menu = { ...menuState };

    if (
      isPreviewMode ||
      (!deletePermissions && menu['Chat Log Line Options'].displayDeleteIcon)
    ) {
      delete menu['Chat Log Line Options'].displayDeleteIcon;
    }

    if (
      menu['Chat Log Line Options'] &&
      Object.keys(menu['Chat Log Line Options']).length === 0
    ) {
      delete menu['Chat Log Line Options'];
    }

    this.setState({ menuState: menu });
  };

  selectClosestListItem = (timestamp) => {
    const { filteredListItems } = this.state;
    const timeStampSeconds = Math.floor(new Date(timestamp).getTime() / 1000);

    if (!filteredListItems || !filteredListItems.length) {
      return;
    }
    let index = 0;
    let currAnnotationTimestamp;
    for (index; index < filteredListItems.length; index += 1) {
      currAnnotationTimestamp = new Date(filteredListItems[index].msgDate);
      const currentTimestampSeconds = Math.floor(
        currAnnotationTimestamp.getTime() / 1000
      );
      // if the current annotation is "past" the timestamp, stop!
      if (currentTimestampSeconds > timeStampSeconds) {
        break;
      }
    }

    // grab the annotation and index of the "correct" annotation
    index -= 1;
    index = index < 0 ? 0 : index; // if it's less than 0, make it 0

    const item = filteredListItems[index];

    if (item) {
      this.setState({
        selectedIndex: index,
        scrollToIndex: index,
        selectedAnnotation: item.annotationId ? item : undefined,
      });
    }
  };

  updateConfig = () => {
    const { name, onUpdateConfig } = this.props;
    const {
      displayFullTimestamp,
      displayDeleteIcon,
      coloredUsernames,
      displaySystemMessages,
      displayNavMessages,
    } = this.state;
    const config = {
      displayFullTimestamp,
      displayDeleteIcon,
      coloredUsernames,
      displaySystemMessages,
      displayNavMessages,
    };
    onUpdateConfig(name, config);
  };

  renderChatLogMessages = () => {
    const { classes, loading, listHeight } = this.props;
    const { filteredListItems, scrollToIndex, searchBarVisible } = this.state;

    if (filteredListItems && filteredListItems.length === 0 && !loading) {
      return <div className={classes.noData}>No Data</div>;
    }

    let searchBarHeight = 0;
    if (
      searchBarVisible &&
      document.querySelector(`.${classes.searchBar}`) !== null
    ) {
      searchBarHeight = document.querySelector(
        `.${classes.searchBar}`
      ).clientHeight;
    }
    if (!loading) {
      return (
        <AutoSizer disableHeight={listHeight}>
          {({ height, width }) => (
            <SizeableVirtualList
              height={
                listHeight
                  ? listHeight - searchBarHeight
                  : height - searchBarHeight
              }
              width={width}
              rowHeight={(info) =>
                this.decideRowHeight(info, width, filteredListItems)
              }
              rowRenderer={(info) =>
                this.renderListLine(info, filteredListItems)
              }
              rowCount={filteredListItems.length}
              setRef={this.setRef}
              scrollToIndex={scrollToIndex}
              scrollToAlignment="center"
            />
          )}
        </AutoSizer>
      );
    }
    return undefined;
  };

  setFilteredListItems = () => {
    const { messages } = this.props;
    const { displaySystemMessages, displayNavMessages } = this.state;
    let filteredListItems = [...messages];
    if (!displaySystemMessages) {
      filteredListItems = filteredListItems.filter(
        (item) => item && item.username
      );
    }
    if (!displayNavMessages) {
      filteredListItems = filteredListItems.filter(
        (item) => item && item.username !== NAV_USERNAME
      );
    }

    filteredListItems = filteredListItems.sort((a, b) => {
      const date1 = a.msgDate;
      const date2 = b.msgDate;

      return new Date(date1) - new Date(date2);
    });
    return this.setState({
      filteredListItems,
      // selectedIndex,
      // scrollToIndex: selectedIndex,
    });
  };

  calculatePrimaryTextHeight = (text, width) =>
    DomUtil.calculateTextHeight(text, width, {
      style: this.primaryTextStyle,
      id: 'primary-text-height-div',
    });

  getChatMessageWidth = (width) => {
    const { displayFullTimestamp, displayDeleteIcon } = this.state;
    let currWidth = width;
    // Outer left padding
    currWidth -= ANNOTATION_PADDING_WIDTH;
    // Annotation width
    currWidth -= ChatMessageLine.getAnnotationContentWidth(
      [displayDeleteIcon],
      displayFullTimestamp
    );
    // Outer right padding
    currWidth -= ANNOTATION_PADDING_WIDTH;
    // Assumes it has scrollbar, if no scroll bar needed spacing might be wonkey
    currWidth -= SCROLLBAR_WIDTH;
    return currWidth;
  };

  decideRowHeight = ({ index }, width, messages) => {
    const item = messages[index];
    if (item.chatLogMsgId >= 0) {
      return this.decideChatMessageRowHeight(item, width);
    }
    return 0;
  };

  shouldHideMessage = (item) => {
    const { displayNavMessages, displaySystemMessages } = this.state;
    if (!item.username) {
      return !displaySystemMessages;
    }
    if (item.username === NAV_USERNAME) {
      return !displayNavMessages;
    }
    return false;
  };

  decideChatMessageRowHeight = (item, width) => {
    if (this.shouldHideMessage(item)) {
      return 0;
    }
    let height = ANNOTATION_PADDING_WIDTH;
    const currWidth = this.getChatMessageWidth(width, item);
    height += this.calculatePrimaryTextHeight(item.username, currWidth);
    height += this.calculatePrimaryTextHeight(item.msg, currWidth);
    return height;
  };

  setRef = (ref) => {
    this.setState({ listRef: ref });
  };

  renderListLine = (info, messages) => {
    const item = messages[info.index];
    if (item.chatLogMsgId >= 0) {
      return this.renderChatLine(info, item);
    }
    return undefined;
  };

  handleItemClick = (item, index) => {
    const { onMessageClick } = this.props;
    onMessageClick(item, index);
    this.setState({ selectedIndex: index });
  };

  renderChatLine = (info, item) => {
    const { chatUserMap, deletePermissions, onChatMessageDeleteClick } =
      this.props;
    const {
      displayFullTimestamp,
      displayDeleteIcon,
      coloredUsernames,
      selectedIndex,
    } = this.state;
    if (this.shouldHideMessage(item)) {
      return undefined;
    }
    let selected = false;
    if (selectedIndex !== undefined) {
      selected = info.index === selectedIndex;
    }
    return (
      <ChatMessageLine
        style={info.style}
        listIndex={info.index}
        chatMessage={item}
        onChatMessageClick={() => this.handleItemClick(item, info.index)}
        displayFullTimestamp={displayFullTimestamp}
        displayDeleteIcon={displayDeleteIcon}
        deletePermissions={deletePermissions}
        onChatMessageDeleteClick={onChatMessageDeleteClick}
        chatUserMap={chatUserMap}
        coloredUsernames={coloredUsernames}
        selected={selected}
      />
    );
  };

  handleBooleanChange = (value) => () => {
    // here, it's grabbing by key name from the state, and renaming it to currValue
    const { [value]: currValue } = this.state;

    // force react-virtualized list to update after state change so the row heights are correct
    this.setState({ [value]: !currValue }, () => {
      this.recomputeRowHeights();
      this.updateConfig();
    });
  };

  toggleSearchBar = () => {
    const { searchBarVisible } = this.state;
    this.setState({ searchBarVisible: !searchBarVisible });
  };

  handleChatLogMessageSearch = (event) => {
    const { messages } = this.props;
    const { value: searchValue } = event.target;

    const filteredListItems = messages.filter(
      (message) =>
        message.msg.toLowerCase().includes(searchValue) ||
        (message.username &&
          message.username.toLowerCase().includes(searchValue))
    );

    this.setState({ filteredListItems, searchValue });
  };

  /**
   * Call react-virtualized List ref to recompute row height. Call as a ref
   * because List only rerenders if the number of items in a list changes, not
   * the order, and the documentation and examples use refs. ¯_(ツ)_/¯ See
   * comment in listRef state at top of file.
   */
  recomputeRowHeights = () => {
    const { listRef } = this.state;
    if (listRef && listRef.recomputeRowHeights) {
      listRef.recomputeRowHeights(0);
    }
  };

  createMenuItem = (key, checked, label) => {
    const { classes } = this.props;
    return (
      <MenuItem
        className={classes.listItem}
        key={key}
        onClick={this.handleBooleanChange(key)}
      >
        <Checkbox checked={checked} label={key} />
        {label}
      </MenuItem>
    );
  };

  renderMenu = () => {
    const {
      classes,
      menu,
      isPreviewMode,
      deletePermissions,
      onChatMessageDeleteAllClick,
    } = this.props;
    const { menuState } = this.state;
    const showDeleteAll = !isPreviewMode && deletePermissions;

    return (
      <div>
        {Object.entries(menuState).map(([menuOption, subOptions]) => (
          <div key={menuOption}>
            <ListItem>
              <ListItemText disableTypography>
                <Typography variant="subtitle1">{`${menuOption}:`}</Typography>
              </ListItemText>
            </ListItem>
            <Menu key={menuOption} menuList className={classes.threeDotMenu}>
              {Object.entries(subOptions).map(([key, label]) => {
                const { [key]: checked } = this.state;
                return this.createMenuItem(key, checked, label);
              })}
            </Menu>
            <Divider />
          </div>
        ))}
        {showDeleteAll && (
          <>
            <MenuItem onClick={onChatMessageDeleteAllClick}>
              Delete All
            </MenuItem>
            <Divider />
          </>
        )}
        <HelpLink url="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help#SeaTubeV3Help-AnnotationListWidget" />
        <Divider />
        {menu}
      </div>
    );
  };

  render() {
    const { classes, messages, loading, headerDraggable } = this.props;
    const { filteredListItems, searchBarVisible, searchValue } = this.state;

    const titleContent = (
      <Typography className={classes.title} variant="body1">
        Chat Log
      </Typography>
    );
    const actionContent = (
      <>
        <SearchIconButton onClick={this.toggleSearchBar} />
        <Typography variant="body1" label="Annotation Count">
          {`${filteredListItems ? filteredListItems.length : 0} of ${
            messages.length
          }`}
        </Typography>
      </>
    );
    return (
      <div className={classes.root}>
        <Panel
          title={titleContent}
          actionContent={actionContent}
          menu={this.renderMenu()}
          menuProps={{
            closeOnClick: false,
            buttonLabel: 'Chat Log Menu',
          }}
          headerDraggable={headerDraggable}
          loading={loading}
          enableOverflow
        >
          {searchBarVisible ? (
            <TextField
              translationKey="common.search"
              sx={classes.searchBar}
              fullWidth={false}
              onChange={this.handleChatLogMessageSearch}
              value={searchValue}
              title="searchField"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="action" />
                  </InputAdornment>
                ),
              }}
            />
          ) : undefined}
          <Divider />
          {this.renderChatLogMessages()}
        </Panel>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(ChatLogList));
