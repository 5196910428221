import * as React from 'react';
import {
  CopyLinkIconButton,
  DeleteIconButton,
  EditIconButton,
} from 'domain/AppComponents/IconButtons';
import { SeaTubePermissionObj } from 'domain/services/SeaTubePermissionsService';
import TableAnnotation from '../TableAnnotation';

export type AnnotationActionProps = {
  onCopyLink: (annotation: TableAnnotation) => void;
  onEdit: (...args: any[]) => any;
  onDelete: (annotationId: number) => void;
  row: TableAnnotation;
  permissions: SeaTubePermissionObj;
  userId: number;
};

const ActionFormatter: React.VFC<AnnotationActionProps> = ({
  onCopyLink,
  onEdit,
  onDelete,
  row,
  permissions,
  userId,
}: AnnotationActionProps) => {
  const {
    annotationPrivilege,
    deleteAnnotationPrivilege,
    selfDeleteAnnotationPrivilege,
  } = permissions;
  const selfDeleteOnly =
    !deleteAnnotationPrivilege &&
    selfDeleteAnnotationPrivilege &&
    row.createdUserId !== userId;
  return (
    <>
      <CopyLinkIconButton onClick={() => onCopyLink(row)} />
      {annotationPrivilege ? (
        <EditIconButton onClick={() => onEdit(row)} />
      ) : undefined}
      {deleteAnnotationPrivilege || selfDeleteAnnotationPrivilege ? (
        <DeleteIconButton
          onClick={() => onDelete(row.id)}
          disabled={selfDeleteOnly}
          aria-label={
            selfDeleteOnly ? "Cannot delete other users' annotations" : 'Delete'
          }
        />
      ) : undefined}
    </>
  );
};

export default ActionFormatter;
