import DeploymentsWebService from 'domain/services/DeploymentsWebService';
import DeviceWebService from 'domain/services/DeviceWebService';
import DeviceWebServiceWithToken from 'domain/services/DeviceWebServiceWithToken';
import LocationsWebServiceWithToken from 'domain/services/LocationsWebServiceWithToken';
import PropertiesWebServiceWithToken from 'domain/services/PropertiesWebServiceWithToken';
import DateFormatUtils from 'util/DateFormatUtils';

/**
 * Generates data sources for the Data Source Selection Widget based on filter
 * parameters
 */
class DataSourceSelectionWidgetHelper {
  static fetchDeviceDataSources = async (
    deviceCategoryCode,
    propertyCode,
    locationCode,
    deviceCode,
    startDate,
    endDate,
    onError
  ) => {
    // Initialize a key for each data source, used for selection
    let keyValue = 0;

    // Fetch device sources according to filter criteria, null if call is invalid
    const deviceResponse = await DeviceWebServiceWithToken.get({
      method: 'get',
      deviceCategoryCode,
      propertyCode,
      locationCode,
      deviceCode,
      dateFrom: startDate ? new Date(startDate) : undefined,
      dateTo: endDate ? new Date(endDate) : undefined,
    }).catch(() => onError('No devices found, adjust filters for valid data'));
    try {
      const deploymentsPromises = deviceResponse.data.map(
        async (deviceRecord) => {
          try {
            // Make a deployment service call for each device, return the promise
            const deploymentsResponse = await DeploymentsWebService.get({
              method: 'get',
              deviceCategoryCode: deviceRecord.deviceCategoryCode,
              propertyCode,
              locationCode,
              deviceCode: deviceRecord.deviceCode,
              dateFrom: startDate ? new Date(startDate) : undefined,
              dateTo: endDate ? new Date(endDate) : undefined,
            });

            const latestDeployment = deploymentsResponse.data.reduce(
              (latest, deployment) => {
                const deploymentDate = new Date(deployment.begin);
                if (!latest || deploymentDate > latest.date) {
                  return {
                    date: deploymentDate,
                    locationCode: deployment.locationCode,
                  };
                }
                return latest;
              },
              null
            );

            // Fetch device's latest location
            const locationTreeResponse =
              await LocationsWebServiceWithToken.getTree({
                method: 'get',
                locationCode: latestDeployment.locationCode,
              });
            keyValue += 1;
            return {
              deviceId: deviceRecord.deviceId,
              name: deviceRecord.deviceName,
              deviceCode: deviceRecord.deviceCode,
              deviceCategoryCode: deviceRecord.deviceCategoryCode,
              deviceLink: deviceRecord.deviceLink,
              latestDeployment: DateFormatUtils.formatDate(
                latestDeployment.date.toISOString(),
                'full'
              ),
              location: locationTreeResponse.data[0].locationName,
              locationCode: locationTreeResponse.data[0].locationCode,
              key: keyValue,
            };
          } catch (error) {
            keyValue += 1;
            return {
              deviceId: deviceRecord.deviceId,
              name: deviceRecord.deviceName,
              deviceCode: deviceRecord.deviceCode,
              deviceCategoryCode: deviceRecord.deviceCategoryCode,
              latestDeployment: 'No deployment data',
              location: 'No location data',
              locationCode: '',
              key: keyValue,
            };
          }
        }
      );
      const deviceSources = await Promise.all(deploymentsPromises);
      return deviceSources;
    } catch (e) {
      return onError('No sources found, adjust filters for valid data');
    }
  };

  static fetchPropertyDataSources = async (
    deviceCategoryCode,
    propertyCode,
    locationCode,
    deviceCode,
    startDate,
    endDate,
    onError
  ) => {
    let keyValue = 0;
    let propertySources;

    const propertyResponse = await PropertiesWebServiceWithToken.get({
      method: 'get',
      deviceCategoryCode,
      propertyCode,
      locationCode,
      deviceCode,
    }).catch(() =>
      onError('No properties found, adjust filters for valid data')
    );

    if (propertyResponse) {
      const deploymentPromises = propertyResponse.data.map(
        async (propertyRecord) => {
          try {
            // Deployment call for each property
            const deploymentsResponse = await DeploymentsWebService.get({
              method: 'get',
              deviceCategoryCode,
              propertyCode: propertyRecord.propertyCode,
              locationCode,
              deviceCode,
              dateFrom: startDate ? new Date(startDate) : undefined,
              dateTo: endDate ? new Date(endDate) : undefined,
            });

            const fetchAdditionalData = await Promise.all(
              deploymentsResponse.data.map(async (record) => {
                const deviceResponse = await DeviceWebService.get({
                  method: 'get',
                  deviceCode: record.deviceCode,
                });
                const locationResponse =
                  await LocationsWebServiceWithToken.getTree({
                    method: 'get',
                    locationCode: record.locationCode,
                  });

                return {
                  deviceCode: record.deviceCode,
                  deviceName: deviceResponse.data[0].deviceName,
                  deviceLink: deviceResponse.data[0].deviceLink,
                  deviceId: deviceResponse.data[0].deviceId,
                  locationName: locationResponse.data[0].locationName,
                };
              })
            );

            // Append corresponding device data to reponse
            const deploymentsWithAdditionalData = deploymentsResponse.data.map(
              (deployment) => {
                const deviceInfo = fetchAdditionalData.find(
                  (info) => info.deviceCode === deployment.deviceCode
                );
                keyValue += 1;
                const deploymentRange = `${DateFormatUtils.formatDate(deployment.begin, 'full')}${deployment.end ? ` to ${DateFormatUtils.formatDate(deployment.end, 'full')}` : ' to Present'}`;
                const uniqueKey = keyValue;
                return {
                  ...deployment,
                  deploymentRange,
                  name: propertyRecord.propertyName,
                  deviceLink: deviceInfo ? deviceInfo.deviceLink : null,
                  deviceName: deviceInfo ? deviceInfo.deviceName : null,
                  deviceId: deviceInfo ? deviceInfo.deviceId : null,
                  location: deviceInfo ? deviceInfo.locationName : null,
                  key: uniqueKey,
                };
              }
            );
            return deploymentsWithAdditionalData;
          } catch (error) {
            return {
              name: propertyRecord.propertyName,
              begin: 'No deployment data',
              end: '',
            };
          }
        }
      );
      propertySources = (await Promise.all(deploymentPromises))?.flat() ?? null;
    }
    return propertySources;
  };
}

export default DataSourceSelectionWidgetHelper;
