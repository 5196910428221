import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Link } from '@onc/icons';
import { IconButton } from 'base-components';

type CopyLinkButtonProps = {
  shareUrl: string;
  buttonSize?: 'small' | 'medium' | 'large';
  onCopy?: () => void;
  onClose?: (e: React.MouseEvent) => void;
};
/**
 * CopyLinkButton component that has copy-to-clipboard functionality and a
 * snazzy Font Awesome icon. This is only because Material UI does not have an
 * icon for copy. :(
 */
const CopyLinkButton = ({
  shareUrl,
  buttonSize = 'small',
  onCopy = undefined,
  onClose = undefined,
}: CopyLinkButtonProps) => {
  const copyToClipboard = (e) => {
    e.stopPropagation();
    if (onCopy) {
      onCopy();
    }

    onClose(e);
  };

  /** Use react-copy-to-clipboard library */
  return (
    <CopyToClipboard text={shareUrl}>
      <IconButton
        onClick={(e) => copyToClipboard(e)}
        size={buttonSize}
        aria-label="copy link"
      >
        <Link fontSize="large" />
      </IconButton>
    </CopyToClipboard>
  );
};

export default CopyLinkButton;
