import { useState } from 'react';
import { FormGroup, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import CfStandardNameDropdown from 'domain/AppComponents/dropdowns/CfStandardNameDropdown';
import SensorService from 'domain/services/SensorService';
import { SaveButton } from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormAutocomplete from 'library/CompositeComponents/form/FormAutocomplete';
import FormCheckbox from 'library/CompositeComponents/form/FormCheckbox';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import Environment from 'util/Environment';

const useStyles = makeStyles((theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  panelDiv: {
    margin: theme.spacing(1),
  },
  thing: {
    paddingTop: theme.spacing(4),
  },
  icon: {
    minWidth: '30px',
  },
  field: {
    marginTop: theme.spacing(2),
  },
}));

type SensorMaintenanceFormProps = {
  devicePortId: number;
  derived: { label: string; value: string };
  cfStandardName: { label: string; value: number };
  archived: boolean;
  neptuneSearchable: boolean;
};

type SensorMaintenancePanelProps = {
  sensor: {
    archived: boolean;
    cfStandardNameId: number;
    devicePortId: number;
    derivedValue: string;
    neptuneSearchable: boolean;
    sensorId: number;
  };
  onError: (message: string) => void;
  onInfo: (message: string) => void;
};

const SensorMaintenancePanel = ({
  sensor,
  onError,
  onInfo,
}: SensorMaintenancePanelProps) => {
  const classes = useStyles();
  const [cfStandardOptions, setCfOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  const renderCheckBox = (name, label) => {
    if (_.isEmpty(sensor)) {
      return null;
    }
    return (
      <FormCheckbox
        name={name}
        label={label}
        disabled={Environment.getDmasUserPrivilege() !== 'RW'}
      />
    );
  };

  const renderSaveButton = () => {
    if (Environment.getDmasUserPrivilege() === 'RW') {
      return (
        <div className={classes.formButtons}>
          <SaveButton type="submit" />
        </div>
      );
    }
    return null;
  };

  const handleSubmit = (values) => {
    const params = {
      devicePortId: values.devicePortId
        ? Number(values.devicePortId)
        : undefined,
      derived: values.derived.value,
      cfStandardName: values.cfStandardName?.value,
      archived: values.archived,
      neptuneSearchable: values.neptuneSearchable,
      sensorId: sensor.sensorId,
    };
    SensorService.update(params)
      .then(() => onInfo('Successfully updated!'))
      .catch(() => onError('Failed to update'));
  };

  const derivedOptions = [
    {
      value: 'D',
      label: 'Dmas-Derived',
    },
    {
      value: 'E',
      label: 'Externally-Derived',
    },
    {
      value: 'I',
      label: 'Instrument-Derived',
    },
    {
      value: 'N',
      label: 'Not Derived',
    },
  ];

  const formMethods = useForm<SensorMaintenanceFormProps>({
    defaultValues: {
      devicePortId: sensor.devicePortId,
      derived: sensor.derivedValue
        ? {
            value: sensor.derivedValue,
            label: derivedOptions.find(
              (derivedOption) => derivedOption.value === sensor.derivedValue
            ).label,
          }
        : undefined,
      cfStandardName: sensor.cfStandardNameId
        ? {
            value: sensor.cfStandardNameId,
            label: undefined,
          }
        : undefined,
      archived: sensor.archived,
      neptuneSearchable: sensor.neptuneSearchable,
    },
    mode: 'onBlur',
  });

  if (cfStandardOptions.length > 0) {
    formMethods.setValue(
      'cfStandardName.label',
      cfStandardOptions.find(
        (option) => option.value === sensor.cfStandardNameId
      ).label
    );
  }

  return (
    // This div is included so that the panel inherits the size of 'div' element and the scrollbar which appears in a panel can be hidden
    <div>
      <Form onSubmit={handleSubmit} formMethods={formMethods}>
        <Grid container direction="row" className={classes.entryFormContainer}>
          <Grid item xs={6}>
            <FormTextField
              name="devicePortId"
              translationKey="device.devicePortId"
              disabled={Environment.getDmasUserPrivilege() !== 'RW'}
              className={classes.field}
              size="small"
              variant="outlined"
              rules={{
                validate: {
                  verifyPortId: (portId) => {
                    if (isNaN(portId)) return 'Port ID should be a number';
                    return true;
                  },
                },
              }}
            />
            <FormAutocomplete
              translationKey="device.derivedSensor"
              options={derivedOptions}
              fullWidth
              name="derived"
              disabled={Environment.getDmasUserPrivilege() !== 'RW'}
              className={classes.field}
              rules={{ required: 'This field is required' }}
            />
            <FormGroup row>
              {renderCheckBox('neptuneSearchable', 'Oceans 3.0 Accessible')}
              {renderCheckBox('archived', 'Archived Sensor')}
            </FormGroup>
            <CfStandardNameDropdown
              name="cfStandardName"
              translationKey="device.cfStandardName"
              fullWidth
              onOptionsLoaded={setCfOptions}
              className={classes.field}
              disabled={Environment.getDmasUserPrivilege() !== 'RW'}
            />
          </Grid>
        </Grid>
        {renderSaveButton()}
      </Form>
    </div>
  );
};

export default SensorMaintenancePanel;
