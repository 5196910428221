import {
  createElementObject,
  createLayerComponent,
  extendContext,
} from '@react-leaflet/core';
import * as ESRI from 'esri-leaflet';

const createDynamicMapLayer = (props, context) => {
  const dynamicMapLayer = ESRI.dynamicMapLayer({
    url: props.url,
    alt: props.alt || 'dynamic-map-layer',
  });
  return createElementObject(
    dynamicMapLayer,
    extendContext(context, { overlayContainer: dynamicMapLayer })
  );
};

const updateDynamicMapLayer = (instance, props, prevProps) => {
  if (props.url !== prevProps.url) {
    instance.setUrl(props.url);
  }
  if (props.opacity !== prevProps.opacity) {
    instance.setOpacity(props.opacity);
  }
  if (props.zIndex !== prevProps.zIndex) {
    instance.setZIndex(props.zIndex);
  }
};

export default createLayerComponent(
  createDynamicMapLayer,
  updateDynamicMapLayer
);
