import { memo } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from '@onc/icons';
import { IconButton } from 'base-components';

const styles = (theme: Theme) =>
  createStyles({
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
  });

interface Props extends WithStyles<typeof styles> {
  annotationId: number;
  reviewValue: string | undefined;
  onReviewClick: (annotationId: number, value: string | undefined) => void;
}

const AnnotationReviewButtons: React.VFC<Props> = (props: Props) => {
  const { annotationId, reviewValue, onReviewClick, classes } = props;

  const handleReviewButtonClick = (event: React.BaseSyntheticEvent) => {
    const { id } = event.currentTarget;
    event.stopPropagation();
    let review: string | undefined;

    if (id.includes('upvote') && reviewValue !== 'up') {
      review = 'up';
    }

    if (id.includes('downvote') && reviewValue !== 'down') {
      review = 'down';
    }

    onReviewClick(annotationId, review);
  };

  const VoteUp = reviewValue === 'up' ? ThumbUp : ThumbUpOutlined;
  const VoteDown = reviewValue === 'down' ? ThumbDown : ThumbDownOutlined;

  return (
    <>
      <IconButton
        id={`upvote-${annotationId}`}
        aria-label="Thumbs Up"
        onClick={handleReviewButtonClick}
      >
        <VoteUp
          className={reviewValue === 'up' ? classes.success : undefined}
          fontSize="small"
        />
      </IconButton>

      <IconButton
        id={`downvote-${annotationId}`}
        aria-label="Thumbs Down"
        onClick={handleReviewButtonClick}
      >
        <VoteDown
          className={reviewValue === 'down' ? classes.error : undefined}
          fontSize="small"
        />
      </IconButton>
    </>
  );
};

export default withStyles(styles)(memo(AnnotationReviewButtons));
