import { useContext } from 'react';
import { LassoControl } from 'base-components';
import {
  SelectedStation,
  UnassignedCastData,
} from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchTypes';

import GeospatialSearchHelper from 'domain/Apps/geospatial-search/community-fisher/GeospatialSearchHelper';
import SelectedStationsContext from 'domain/Apps/geospatial-search/SelectedStationsContext';

const getCoordinatesFromLayers = (layers) => {
  const coordinatesInLayers = layers
    // eslint-disable-next-line no-underscore-dangle
    .map((layer) => layer._latlng)
    .filter((layer) => layer)
    .concat(
      layers
        // eslint-disable-next-line no-underscore-dangle
        .flatMap((layer) => layer._latlngs)
        .filter((layer) => layer)
        .flat()
    );
  return coordinatesInLayers;
};

type LassoFABProps = {
  assignedCastData: SelectedStation[];
  unassignedCastData: UnassignedCastData[];
  setSelectedLayers: any;
  showCastSelectionDialog: any;
  onError: (message: any, callback?: any) => void;
};
const LassoFAB = ({
  assignedCastData,
  unassignedCastData,
  setSelectedLayers,
  showCastSelectionDialog,
  onError,
}: LassoFABProps) => {
  const { selectedStations, updateSelectedStations } = useContext(
    SelectedStationsContext
  );

  const getNewAssignedCastStationsFromCoordinates = (
    coordinates
  ): SelectedStation[] => {
    if (!assignedCastData) return [];

    // get any station that exists for each latLng object in the lasso
    const assignedCastStations = [
      ...new Set(
        coordinates.map((stationCoordinate) =>
          assignedCastData.find(
            (station) =>
              stationCoordinate.lat ===
                (
                  GeospatialSearchHelper.calculateCenterPoint(
                    station.positions
                  ) as any
                ).lat &&
              stationCoordinate.lng ===
                (
                  GeospatialSearchHelper.calculateCenterPoint(
                    station.positions
                  ) as any
                ).lng
          )
        )
      ),
    ];

    const onlyNewCastStations = assignedCastStations
      .filter((newStation) => newStation)
      .filter(
        (newStation: any) =>
          !selectedStations.some(
            (existingStation) => existingStation.id === newStation.id
          )
      );

    return onlyNewCastStations as SelectedStation[];
  };

  const getNewUnassignedCastsFromCoordinates = (coordinates) => {
    if (!unassignedCastData) return [];
    const selectedUnassignedCasts = coordinates.flatMap((stationCoordinate) =>
      unassignedCastData.filter(
        (unassignedCast) =>
          unassignedCast.referenceLat === stationCoordinate.lat &&
          unassignedCast.referenceLon === stationCoordinate.lng
      )
    );
    return selectedUnassignedCasts;
  };

  const setLassoSelection = (layers) => {
    // recursively get all layers and then remove all layers that are not markers
    const allLayers = layers
      .concat(GeospatialSearchHelper.setLayersToSelectedIcon(layers))
      .filter(
        (layer) =>
          // eslint-disable-next-line no-underscore-dangle
          !layer.options?.icon?._icon?.className?.includes('marker-cluster')
      );

    const selectedCoordinates = getCoordinatesFromLayers(allLayers);

    const newSelectedStations =
      getNewAssignedCastStationsFromCoordinates(selectedCoordinates);

    // notify user if they lassoed inaccessible casts.
    if (
      newSelectedStations.some((station) =>
        station.siteDeviceSubsets.some(
          (siteDeviceSubset) => siteDeviceSubset.accessRestrictionLevel === 'RO'
        )
      )
    ) {
      onError('Restricted casts cannot be lassoed');
    }

    // find unassigned casts with available coordinates
    const selectedUnassignedCasts =
      getNewUnassignedCastsFromCoordinates(selectedCoordinates);

    updateSelectedStations((prevSelectedStations) =>
      GeospatialSearchHelper.createUpdatedSelectedStations(
        newSelectedStations,
        selectedUnassignedCasts,
        prevSelectedStations
      )
    );

    setSelectedLayers((prevState) => prevState.concat(allLayers));
    showCastSelectionDialog();
  };

  return (
    <LassoControl
      prepend
      onLassoEnd={setLassoSelection}
      options={{ position: 'bottomleft' }}
      size="medium"
      loading={
        !(assignedCastData?.length > 0 && unassignedCastData?.length > 0)
      }
    />
  );
};

export default LassoFAB;
