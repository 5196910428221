import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Divider, HelpLink, SizeMe, Typography } from 'base-components';

import Panel from 'library/CompositeComponents/panel/Panel';
import SeaTubeMap from './SeaTubeMap';

class SeaTubeMapPanel extends PureComponent {
  static propTypes = {
    diveId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annotations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    selectedAnnotationId: PropTypes.number,
    currentTimestamp: PropTypes.instanceOf(Date),
    handleDivePathClicked: PropTypes.func.isRequired,
    handleAnnotationClicked: PropTypes.func.isRequired,
    bathymetryPermissions: PropTypes.bool.isRequired,
    menu: PropTypes.node,
  };

  static defaultProps = {
    currentTimestamp: undefined,
    diveId: undefined,
    annotations: undefined,
    selectedAnnotationId: undefined,
    menu: undefined,
  };

  renderMenu = () => {
    const { menu } = this.props;
    return (
      <div>
        <HelpLink url="https://wiki.oceannetworks.ca/display/O2KB/SeaTube+V3+Help#SeaTubeV3Help-MapWidget" />
        <Divider />
        {menu}
      </div>
    );
  };

  render() {
    const {
      currentTimestamp,
      annotations,
      handleDivePathClicked,
      handleAnnotationClicked,
      diveId,
      bathymetryPermissions,
      selectedAnnotationId,
    } = this.props;

    return (
      <Panel
        id="seatube-map-panel"
        title={<Typography variant="body1">Map</Typography>}
        headerDraggable
        menu={this.renderMenu()}
        menuProps={{
          buttonLabel: 'Map Menu',
        }}
      >
        <SizeMe>
          <SeaTubeMap
            onAnnotationClick={handleAnnotationClicked}
            onDivePathClick={handleDivePathClicked}
            annotations={annotations}
            diveId={diveId}
            currentTimestamp={currentTimestamp}
            bathymetryPermissions={bathymetryPermissions}
            selectedAnnotationId={selectedAnnotationId}
          />
        </SizeMe>
      </Panel>
    );
  }
}

export default SeaTubeMapPanel;
