import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'base-components';
import {
  LAYOUT_RESOURCE_TYPE_ID,
  useCreateLayout,
} from 'domain/AppComponents/Dashboard/LayoutService';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { OncLayouts } from './DashboardTypes';
import { CancelButton, SaveButton } from '../button/Buttons';
import Form from '../form/Form';
import FormTextField from '../form/FormTextField';

type SaveWidgetLayoutDialogProps = {
  applicationId: number;
  currentLayout: OncLayouts;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
};

type LayoutForm = {
  layoutName: string;
};

const SaveWidgetLayoutDialog = ({
  applicationId,
  currentLayout,
  open,
  onClose,
  onSave,
}: SaveWidgetLayoutDialogProps) => {
  const { onInfo, onError } = useSnackbars();

  const formMethods = useForm<LayoutForm>({
    defaultValues: {
      layoutName: 'New Layout',
    },
    mode: 'onBlur',
  });

  const { mutate: createLayout } = useCreateLayout(
    () => {
      onInfo('Layout saved');
      onSave();
    },
    () => onError('Error saving layout')
  );

  const handleSubmit = (data: LayoutForm) => {
    createLayout({
      resourceTypeId: LAYOUT_RESOURCE_TYPE_ID,
      resourceId: applicationId,
      layoutName: data.layoutName,
      widgetLayout: JSON.stringify(currentLayout),
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Save Layout As</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <FormTextField
            rules={{
              required: 'Layout Name is required',
              validate: {
                noWhitespace: (value) =>
                  value.trim() !== '' || 'Layout Name is required',
              },
              maxLength: {
                value: 200,
                message: 'Layout Name must be less than 200 characters',
              },
            }}
            fullWidth
            name="layoutName"
            translationKey="dashboards.layoutName"
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton onClick={formMethods.handleSubmit(handleSubmit)} />
      </DialogActions>
    </Dialog>
  );
};

export default SaveWidgetLayoutDialog;
