import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LabelledCheckbox } from 'base-components';
import { NodeTypeDropdown } from 'domain/AppComponents/dropdowns/RORWDropdowns';
import { MarginedLinkField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';
import { TaxonItem } from '../../Widgets/TaxonomyTreeWidget';

type TaxonItemValue<T extends keyof TaxonItem> = TaxonItem[T];

export type TaxonDetailsFormErrors = {
  [key in keyof TaxonItem]?: string;
};

type Props = {
  // permission controls whether the user is allowed to edit a field
  permission: string;
  // isEnabled controls which form (Create vs Import) is active in the New
  // Taxon dialog
  isEnabled: boolean;
  onChange?: <T extends keyof TaxonItem>(
    key: T,
    value: TaxonItemValue<T>
  ) => void;
  onSubmit: (event: any) => void;
  taxon: TaxonItem;
  nodeTypes: { label: string; value: number }[];
  errors: TaxonDetailsFormErrors;
  taxonomyDetails?: { referenceUrl: string; code: string };
};

const TaxonDetailsForm = ({
  permission,
  isEnabled,
  onChange = () => {},
  onSubmit,
  taxon,
  nodeTypes,
  errors,
  taxonomyDetails = { referenceUrl: '', code: '' },
}: Props) => {
  const useStyles = makeStyles(() => ({
    contentContainer: {
      width: '100%',
    },
  }));

  const classes = useStyles();
  return (
    <form
      aria-label="taxon details form"
      id="taxon-details-form"
      onSubmit={onSubmit}
      className={classes.contentContainer}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <RORWTextField
          name="name"
          translationKey="common.textfields.name"
          value={taxon.commonName}
          permission={permission}
          onChange={(e) => onChange('commonName', e.target.value)}
          disabled={!isEnabled}
          required
          error={!!errors.commonName}
          helperText={errors.commonName}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          translationKey="taxonomy.commonNames"
          value={taxon.englishNames ? taxon.englishNames.join(',') : ''}
          permission={permission}
          onChange={(e) => onChange('englishNames', e.target.value)}
          disabled={!isEnabled}
          error={!!errors.englishNames}
          helperText={errors.englishNames}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        {taxonomyDetails.referenceUrl &&
        taxon.referenceId &&
        permission !== 'RW' ? (
          <MarginedLinkField
            labelText="Reference ID"
            valueText={`${taxon.referenceId} [${taxonomyDetails.code}]`}
            url={taxonomyDetails.referenceUrl.replace(
              '[id]',
              taxon.referenceId
            )}
            id="referenceId"
          />
        ) : (
          <RORWTextField
            translationKey="taxonomy.referenceId"
            value={taxon.referenceId}
            permission={permission}
            onChange={(e) => onChange('referenceId', e.target.value)}
            disabled={!isEnabled}
          />
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          translationKey="taxonomy.referenceUrl"
          value={taxon.referenceUrl}
          permission={permission}
          onChange={(e) => onChange('referenceUrl', e.target.value)}
          disabled={!isEnabled}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <NodeTypeDropdown
          options={nodeTypes}
          value={taxon.nodeTypeId}
          disabled={permission !== 'RW' || !isEnabled}
          permission={permission === 'RW'}
          onChange={(e) => onChange('nodeTypeId', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          translationKey="taxonomy.version"
          value={taxon.version}
          permission={permission}
          onChange={(e) => onChange('version', e.target.value)}
          disabled={!isEnabled}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <LabelledCheckbox
          label="Deprecated"
          value={taxon.deprecated}
          onChange={() => onChange('deprecated', !taxon.deprecated)}
          disabled={permission !== 'RW' || !isEnabled}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <RORWTextField
          translationKey="taxonomy.information"
          value={taxon.information}
          permission={permission}
          onChange={(e) => onChange('information', e.target.value)}
          disabled={!isEnabled}
        />
      </Grid>
    </form>
  );
};

export default TaxonDetailsForm;
