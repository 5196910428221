import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Info } from '@onc/icons';
import { ExpansionSubMenu, IconButton } from 'base-components';
import { GenericInfoDialog } from 'domain/AppComponents/dialogs/Dialogs';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
  },
  grid: {
    padding: theme.spacing(1),
  },
});

const siteHeaders = [
  { title: 'Location', name: 'location' },
  { title: 'Associated', name: 'associated' },
  { title: 'P-wave Arrival', name: 'pWaveArrival' },
  { title: 'S-wave Arrival', name: 'sWaveArrival' },
  { title: 'Distance to Epicentre (km)', name: 'distanceToEpi' },
  { title: 'Tau (s)', name: 'tau' },
  { title: 'Seismic PD (m)', name: 'seismicPd' },
  { title: 'Orbit PD (m)', name: 'orbitPd' },
  { title: 'Orbit PGD (m)', name: 'orbitPgd' },
  { title: 'Float PD (m)', name: 'floatPd' },
  { title: 'Float PGD (m)', name: 'floatPgd' },
  { title: 'Integer PD (m)', name: 'integerPd' },
  { title: 'Integer PGD (m)', name: 'integerPgd' },
  { title: 'Tau', name: 'tauMag' },
  { title: 'Seismic PD', name: 'seismicPdMag' },
  { title: 'SPD Magnitude Offset', name: 'magnitudeOffset' },
  { title: 'Geodetic PD', name: 'geodeticPdMag' },
  { title: 'Geodetic PGD', name: 'geodeticPgdMag' },
];

const siteColumnSizeInfo = [
  { columnName: 'location', width: ColumnInfo.mini },
  { columnName: 'associated', width: ColumnInfo.mini },
  { columnName: 'pWaveArrival', width: ColumnInfo.medium },
  { columnName: 'sWaveArrival', width: ColumnInfo.medium },
  { columnName: 'distanceToEpi', width: ColumnInfo.medium },
  { columnName: 'tau', width: ColumnInfo.mini },
  { columnName: 'seismicPd', width: ColumnInfo.mini },
  { columnName: 'orbitPd', width: ColumnInfo.mini },
  { columnName: 'orbitPgd', width: ColumnInfo.mini },
  { columnName: 'floatPd', width: ColumnInfo.mini },
  { columnName: 'floatPgd', width: ColumnInfo.mini },
  { columnName: 'integerPd', width: ColumnInfo.mini },
  { columnName: 'integerPgd', width: ColumnInfo.mini },
  { columnName: 'tauMag', width: ColumnInfo.mini },
  { columnName: 'seismicPdMag', width: ColumnInfo.mini },
  { columnName: 'geodeticPdMag', width: ColumnInfo.mini },
  { columnName: 'geodeticPgdMag', width: ColumnInfo.mini },
  { columnName: 'magnitudeOffset', width: ColumnInfo.mini },
];

class EarthquakeSiteDetailTable extends PureComponent {
  static propTypes = {
    earthquakeDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  formatGeodetic = (value, error) => {
    if (!value) {
      return '';
    }
    return `${value} ± ${error}`;
  };

  compareGeodetic = (a, b) => {
    const valueStrA = a.substring(0, a.indexOf('±') - 1).trim();
    const valueStrB = b.substring(0, b.indexOf('±') - 1).trim();
    if (valueStrA.length === 0 && valueStrB.length === 0) {
      return 0;
    }
    if (valueStrA.length === 0 && valueStrB.length > 0) {
      return -1;
    }
    if (valueStrA.length > 0 && valueStrB.length === 0) {
      return 1;
    }
    const geodeticA = parseFloat(valueStrA);
    const geodeticB = parseFloat(valueStrB);
    if (geodeticA < geodeticB) {
      return -1;
    }
    if (geodeticA > geodeticB) {
      return 1;
    }
    return 0;
  };

  formatDate = (date) => {
    if (date) {
      return DateFormatUtils.formatDate(date, 'milliseconds');
    }
    return '';
  };

  constructor(props) {
    super(props);
    this.state = {
      openInfoDialog: false,
    };
  }

  handleOpenInfoDialog = (event) => {
    event.stopPropagation();
    this.setState({ openInfoDialog: true });
  };

  renderTableRows = () => {
    const { earthquakeDetails } = this.props;
    return earthquakeDetails.map((row) => ({
      ...row,
      associated: `${row.associated}`,
      geodeticPdMag: this.formatGeodetic(
        row.geodeticPdMag,
        row.geodeticPdMagError
      ),
      geodeticPgdMag: this.formatGeodetic(
        row.geodeticPgdMag,
        row.geodeticPgdMagError
      ),
      orbitPd: this.formatGeodetic(row.orbitPd, row.orbitPdError),
      orbitPgd: this.formatGeodetic(row.orbitPgd, row.orbitPgdError),
      floatPd: this.formatGeodetic(row.floatPd, row.floatPdError),
      floatPgd: this.formatGeodetic(row.floatPgd, row.floatPgdError),
      integerPd: this.formatGeodetic(row.integerPd, row.integerPdError),
      integerPgd: this.formatGeodetic(row.integerPgd, row.integerPgdError),
      pWaveArrival: this.formatDate(row.pWaveArrival),
      sWaveArrival: this.formatDate(row.sWaveArrival),
    }));
  };

  getHiddenColumns = () => {
    const { earthquakeDetails } = this.props;
    let hideOrbit = true;
    let hideFloat = true;
    let hideInteger = true;
    earthquakeDetails.forEach((row) => {
      if (row.orbitPd || row.orbitPgd) {
        hideOrbit = false;
      }
      if (row.floatPd || row.floatPgd) {
        hideFloat = false;
      }
      if (row.integerPd || row.integerPgd) {
        hideInteger = false;
      }
    });
    const result = [];
    if (hideOrbit) {
      result.push('orbitPd');
      result.push('orbitPgd');
    }
    if (hideFloat) {
      result.push('floatPd');
      result.push('floatPgd');
    }
    if (hideInteger) {
      result.push('integerPd');
      result.push('integerPgd');
    }
    if (hideInteger && hideFloat && hideOrbit) {
      result.push('geodeticPdMag');
      result.push('geodeticPgdMag');
    }
    return result;
  };

  render() {
    const { openInfoDialog } = this.state;
    return (
      <>
        <GenericInfoDialog
          open={openInfoDialog}
          onClose={() => this.setState({ openInfoDialog: false })}
          title="ONC Site Information"
          message="
          This table shows site specific information for the earthquake.
          If the site was used in at least one earthquake detection the associated
          column will be true even if it wasn't used in the final detection.
          The magnitudes in the table are the magnitude experienced at the given site.
          "
        />
        <ExpansionSubMenu
          menuTitle="ONC Site Detections"
          expand
          infoContent={
            <IconButton
              Icon={Info}
              onClick={this.handleOpenInfoDialog}
              aria-label="Filter Information"
            />
          }
        >
          <SortableTable
            defaultSorting={[
              {
                columnName: 'distanceToEpi',
                direction: 'asc',
              },
            ]}
            sortExtensions={[
              {
                columnName: 'geodeticPdMag',
                compare: this.compareGeodetic,
              },
              {
                columnName: 'geodeticPgdMag',
                compare: this.compareGeodetic,
              },
              {
                columnName: 'orbitPd',
                compare: this.compareGeodetic,
              },
              {
                columnName: 'orbitPgd',
                compare: this.compareGeodetic,
              },
              {
                columnName: 'floatPd',
                compare: this.compareGeodetic,
              },
              {
                columnName: 'floatPgd',
                compare: this.compareGeodetic,
              },
              {
                columnName: 'integerPd',
                compare: this.compareGeodetic,
              },
              {
                columnName: 'integerPgd',
                compare: this.compareGeodetic,
              },
            ]}
            columnSizes={siteColumnSizeInfo}
            stripedRows
            columns={siteHeaders}
            columnBands={[
              {
                title: 'Magnitudes',
                children: [
                  { columnName: 'tauMag' },
                  { columnName: 'seismicPdMag' },
                  { columnName: 'magnitudeOffset' },
                  { columnName: 'geodeticPdMag' },
                  { columnName: 'geodeticPgdMag' },
                ],
              },
              {
                title: 'Site Magnitude Parameters',
                children: [
                  { columnName: 'tau' },
                  { columnName: 'seismicPd' },
                  { columnName: 'orbitPd' },
                  { columnName: 'orbitPgd' },
                  { columnName: 'floatPd' },
                  { columnName: 'floatPgd' },
                  { columnName: 'integerPd' },
                  { columnName: 'integerPgd' },
                ],
              },
            ]}
            rows={this.renderTableRows()}
            hiddenColumns={this.getHiddenColumns()}
            pageSize={10}
            pageSizes={[10, 30, 60, 0]}
          />
        </ExpansionSubMenu>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbars(EarthquakeSiteDetailTable));
