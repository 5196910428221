import { Fragment } from 'react';
import AudioPlayerWidget from 'domain/AppComponents/Dashboard/audio-widget/AudioPlayerWidget';
import ChartWidget from 'domain/AppComponents/Dashboard/chart-widget/ChartWidget';
import DataSourceSelectionWidget from 'domain/AppComponents/Dashboard/data-source-widget/DataSourceSelectionWidget';
import DataPlayerWidget from 'domain/AppComponents/Dashboard/DataPlayerWidget';
import DataPreviewWidget from 'domain/AppComponents/Dashboard/DataPreviewWidget';
import ImageWidget from 'domain/AppComponents/Dashboard/ImageWidget';
import InteractivePlotWidget from 'domain/AppComponents/Dashboard/InteractivePlotWidget';
import LatestReadingsWidget from 'domain/AppComponents/Dashboard/latest-reading-widget/LatestReadingsWidget';
import TextWidget from 'domain/AppComponents/Dashboard/TextWidget';
import UserDefinedTasksWidget from 'domain/AppComponents/Dashboard/user-defined-tasks-widget/UserDefinedTasksWidget';
import VideoWidget from 'domain/AppComponents/Dashboard/video-widget/VideoWidget';
import WidgetContainer from 'domain/AppComponents/Dashboard/WidgetContainer';
import {
  VIDEO_WIDGET_TYPE_ID,
  INTERACTIVE_PLOT_WIDGET_TYPE_ID,
  DATA_PREVIEW_WIDGET_TYPE_ID,
  LATEST_READINGS_WIDGET_TYPE_ID,
  AUDIO_PLAYER_WIDGET_TYPE_ID,
  TEXT_WIDGET_TYPE_ID,
  IMAGE_WIDGET_TYPE_ID,
  DATA_PLAYER_WIDGET_TYPE_ID,
  USER_DEFINED_TASKS_WIDGET_TYPE_ID,
  CHART_WIDGET_TYPE_ID,
  DATA_SOURCE_SELECTION_WIDGET_TYPE_ID,
} from 'domain/AppComponents/Dashboard/WidgetTypeConstants';
import withWidgetId from 'domain/AppComponents/Dashboard/withWidgetId';
import { READ_WRITE } from 'util/PermissionsUtils';

class WidgetGenerator {
  static build(data) {
    let WidgetComp;
    const dbWidgetFn = withWidgetId(data.widgetid);
    let dataGrid;
    switch (data.widgettypeid) {
      case VIDEO_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(VideoWidget);
        dataGrid = { x: 0, y: 0, w: 4, h: 12 };
        break;
      case INTERACTIVE_PLOT_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(InteractivePlotWidget);
        dataGrid = { x: 0, y: 0, w: 4, h: 18 };
        break;
      case DATA_PREVIEW_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(DataPreviewWidget);
        dataGrid = { x: 0, y: 0, w: 4, h: 13 };
        break;
      case LATEST_READINGS_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(LatestReadingsWidget);
        dataGrid = { x: 0, y: 0, w: 4, h: 8 };
        break;
      case AUDIO_PLAYER_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(AudioPlayerWidget);
        dataGrid = { x: 0, y: 0, w: 4, h: 6 };
        break;
      case TEXT_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(TextWidget);
        dataGrid = { x: 0, y: 0, w: 4, h: 11 };
        break;
      case USER_DEFINED_TASKS_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(UserDefinedTasksWidget);
        dataGrid = { x: 0, y: 0, w: 4, h: 11 };
        break;
      case CHART_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(ChartWidget);
        dataGrid = { x: 0, y: 0, w: 4, h: 15 };
        break;
      case IMAGE_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(ImageWidget);
        dataGrid = { x: 0, y: 0, w: 4, h: 11 };
        break;
      case DATA_PLAYER_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(DataPlayerWidget);
        dataGrid = {
          x: 0,
          y: 0,
          w: 5,
          h: 17,
        };
        break;
      case DATA_SOURCE_SELECTION_WIDGET_TYPE_ID:
        WidgetComp = dbWidgetFn(DataSourceSelectionWidget);
        dataGrid = {
          x: 0,
          y: 0,
          w: 5,
          h: 20,
          maxH: 20,
        };
        break;
      default:
        return <Fragment key={data.widgetid.toString()} />;
    }
    return (
      <WidgetContainer
        dataGrid={dataGrid}
        key={data.widgetid.toString()}
        widgetId={data.widgetid.toString()}
        widgetTypeId={data.widgettypeid}
        canEdit={data.permission === READ_WRITE}
        onRemove={data.onRemove}
        isLoggedIn={data.isLoggedIn}
        containerContent={<WidgetComp />}
        onClone={data.onClone}
      />
    );
  }
}

export default WidgetGenerator;
