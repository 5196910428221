import { Component } from 'react';
import { Grid } from 'base-components';
import Filter, { FilterEvent } from 'library/CompositeComponents/filter/Filter';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import AttributeFilterList from './AttributeFilterList';

type Props = {
  value: any;
  name: string;
  onChange: (event: FilterEvent) => void;
  onError: (e: object) => void;
};
type State = {
  currentValue?: { expeditions?: object[] };
};
class AttributeFilter extends Component<Props, State> {
  render() {
    const { value, name, onChange, onError } = this.props;

    return (
      <Filter title="Attributes" onChange={onChange} name={name} value={value}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AttributeFilterList
              onChange={onChange}
              onError={onError}
              value={value}
            />
          </Grid>
        </Grid>
      </Filter>
    );
  }
}
export default withSnackbars(AttributeFilter);
