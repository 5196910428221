import { memo, useState } from 'react';
import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from 'base-components';
import { InfoIconButton } from 'domain/AppComponents/IconButtons';
import { TextButton } from 'library/CompositeComponents/button/Buttons';

interface Props {
  option: string; // the parsing option used as the label
  descriptionTitle?: string;
  description?: React.ReactNode;
}

const ChatLogParsingOptionLabel: React.VFC<Props> = ({
  option,
  descriptionTitle = undefined,
  description = undefined,
}) => {
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const handleOpenInfoDialog = () => {
    setShowInfoDialog(true);
  };
  const handleCloseInfoDialog = () => {
    setShowInfoDialog(false);
  };

  return (
    <Typography>
      {option}
      {description && (
        <>
          <InfoIconButton
            aria-label="More info"
            color="primary"
            onClick={handleOpenInfoDialog}
          />
          <Dialog open={showInfoDialog} onClose={handleCloseInfoDialog}>
            {descriptionTitle && (
              <DialogTitle id="more-info-title">{descriptionTitle}</DialogTitle>
            )}
            <DialogContent>{description}</DialogContent>
            <DialogActions>
              <TextButton
                translationKey="common.buttons.close"
                onClick={handleCloseInfoDialog}
              />
            </DialogActions>
          </Dialog>
        </>
      )}
    </Typography>
  );
};

export default memo(ChatLogParsingOptionLabel);
