import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Typography } from 'base-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setInfo: {
      'margin-bottom': theme.spacing(2),
    },
  })
);

type Props = {
  dateOfLastCastByCtd?: string;
  communityNamesViaDataAttributions?: string[];
};

const CommunityFishersSetInfo: React.FC<Props> = ({
  dateOfLastCastByCtd = undefined,
  communityNamesViaDataAttributions = undefined,
}: Props) => {
  const classes = useStyles();

  const latestCastEl = dateOfLastCastByCtd ? (
    <Typography
      aria-label="Date of Last Cast"
      key="dateOfCast"
    >{`Date of Last Cast By CTD: ${dateOfLastCastByCtd}`}</Typography>
  ) : undefined;

  const communityNamesViaAttributionForWholeSet =
    communityNamesViaDataAttributions?.join(', ');
  const communityNamesEl = communityNamesViaAttributionForWholeSet ? (
    <Typography
      aria-label="Community Names"
      key="CommunityNames"
    >{`Community Names Via Data Owner Attributions: ${communityNamesViaAttributionForWholeSet}`}</Typography>
  ) : undefined;

  const setInfos = [latestCastEl, communityNamesEl].filter(
    (setInfo) => setInfo
  );
  return setInfos.length > 0 ? (
    <div className={classes.setInfo}>{setInfos}</div>
  ) : null;
};

export default CommunityFishersSetInfo;
