import { useForm } from 'react-hook-form';
import { Dialog, DialogActions, DialogTitle } from 'base-components';
import {
  SaveButton,
  CancelButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import TextWidgetFormContents from './TextWidgetFormContents';
import { type TextWidgetFormType } from './TextWidgetFormContents';

type TextWidgetConfigProps = TextWidgetFormType & {
  onSave: (values: any) => void;
  onCancel: (values: any) => void;
};

const TextWidgetConfig = ({
  onSave,
  onCancel,
  ...formDefaultValues
}: TextWidgetConfigProps) => {
  const formMethods = useForm<TextWidgetFormType>({
    defaultValues: formDefaultValues,
    mode: 'onBlur',
  });

  return (
    <Dialog open maxWidth="md">
      <DialogTitle>Configure Text Widget</DialogTitle>
      <Form formMethods={formMethods} onSubmit={onSave}>
        <TextWidgetFormContents />
        <DialogActions>
          <CancelButton onClick={onCancel} />
          <SaveButton />
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default TextWidgetConfig;
