import { Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Pause, PlayArrow } from '@onc/icons';
import { Switch } from 'base-components';

const useStyles = makeStyles((theme: Theme) => ({
  switchBase: {
    width: '24px',
    height: '24px',
    top: '50%',
    padding: '4px',
    marginTop: 'calc(-18px / 2 - 4px)',
    marginLeft: '6px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  checked: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  checkedIcon: {
    color: theme.palette.primary.contrastText,
  },
}));

type Props = {
  checked: boolean;
  onChange: () => void;
};

const AutoPlayToggleButton = ({ checked, onChange }: Props) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={checked ? 'Autoplay is on' : 'Autoplay is off'}
      placement="top"
    >
      <Switch
        id="auto-play-toggle"
        checked={checked}
        onChange={onChange}
        icon={<Pause fontSize="small" color="action" />}
        checkedIcon={
          <PlayArrow fontSize="small" className={classes.checkedIcon} />
        }
        color="primary"
        classes={{
          switchBase: classes.switchBase,
          checked: classes.checked,
        }}
      />
    </Tooltip>
  );
};

export default AutoPlayToggleButton;
