import { useEffect, useState } from 'react';
import VideoSnapshotService from 'domain/services/VideoSnapshotService';
import Environment from 'util/Environment';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { useSessionStorage } from 'util/hooks/useStorage';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

function useSnapshots(
  deviceId: number,
  time: string,
  numberOfPictures: number,
  maxSnapshots: number,
  quality?: string
): [string[], boolean] {
  const [snapshotUrls, setSnapshotUrls] = useState<string[]>([]);
  const [cachedImages, setCachedImages] = useSessionStorage<string[]>(
    `snapshot-${deviceId}-${time}`,
    []
  );
  const [loading, setLoading] = useState(true);
  const { onError } = useSnackbars();

  const createSnapshotUrl = (path: string, currentFrame: number) => {
    let filename = `/snapshot-00${currentFrame}.jpeg`;
    /* This splits a path looking like seatube/mp4filename_YYYYmmddTHHMMSS.mp4/YYYYmmddTHHMMSS/tn
      so that we can get mp4filename_ and the second timestamp. Then, we look for this file
      using UserFileService.
    */
    const regex = /\/(.*T)\d+.+\..*\/.*T(\d*\.\d*Z)/g;
    const results = regex.exec(path);
    if (results != null) {
      filename = `/${results[1]}${results[2]}-00${currentFrame}.jpeg`;
    }

    const url = `${Environment.getDmasUrl()}/UserFileService?path=${path}${filename}&responseType=7`;
    return url.replace('/tn/', '/hd/');
  };

  const createSnapshotUrls = (path, numberSnapshotsGenerated) => {
    let numberOfSnapshots = maxSnapshots;
    if (numberSnapshotsGenerated < maxSnapshots) {
      numberOfSnapshots = numberSnapshotsGenerated;
    }
    const createdSnapshotUrls = [];
    for (let i = 0; i < numberOfSnapshots; i += 1) {
      createdSnapshotUrls.push(createSnapshotUrl(path, i + 1));
    }
    setSnapshotUrls(createdSnapshotUrls);
    setCachedImages(createdSnapshotUrls);
    return createdSnapshotUrls;
  };

  const getSnapshotUrls = (path) =>
    get('UserFileService', {
      path: `seatube/${path}/hd`,
      responseType: 1,
    })
      .then((response) => parseDmasAPIResponse(response))
      .then(
        // generate snapshots and make Image Dialog visible with images
        (payload) => {
          const snapshotsGenerated = payload.length;
          setLoading(false);
          if (snapshotsGenerated > 0) {
            const pathStr = `seatube/${path}/tn`;
            return createSnapshotUrls(pathStr, snapshotsGenerated);
          }
          return onError('No snapshots generated.');
        }
      )
      .catch((error) => {
        setLoading(false);
        onError(error.message);
      });

  const createSnapshots = () => {
    VideoSnapshotService.createSnapshots({
      deviceId,
      currentDate: time,
      numberOfPictures,
      applicationName: 'seatube',
      quality,
    })
      .then((response) => getSnapshotUrls(response.path))
      .catch((error) => {
        setLoading(false);
        onError(error.message);
      });
  };

  useEffect(() => {
    if (cachedImages.length > 0) {
      setSnapshotUrls(cachedImages);
      setLoading(false);
    } else {
      createSnapshots();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [snapshotUrls, loading];
}

export default useSnapshots;
