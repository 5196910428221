import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ErrorSnackbar } from '@onc/composite-components';
import InteractivePlotWidgetConfig from 'domain/AppComponents/Dashboard/InteractivePlotWidgetConfig';
import {
  WidgetConfigError,
  getWidgetConfigErrorMessage,
} from 'domain/AppComponents/Dashboard/widget-error/WidgetConfigError';
import PlottingUtility from 'domain/Apps/PlottingUtility';

import { WidgetTitle } from './Titles';
import UserPreferenceService from '../UserPreferenceService';

const STYLES = {
  circularProgress: {},
  contentDiv: {},
};

class InteractivePlotWidget extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      contentDiv: PropTypes.string,
    }).isRequired,
    showDisplay: PropTypes.bool,
    showTitle: PropTypes.bool,
    title: PropTypes.string,
    onSave: PropTypes.func,
    onConfigFinish: PropTypes.func,
    savedPlot: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      published: PropTypes.bool,
    }).isRequired,
  };

  static defaultProps = {
    showDisplay: true,
    showTitle: false,
    title: undefined,
    onSave: undefined,
    onConfigFinish: undefined,
  };

  state = {
    savedPlotState: undefined,
  };

  async componentDidMount() {
    await this.getSavedPlot();
  }

  handleSave = async (values) => {
    const { onSave } = this.props;
    await onSave(values);
    this.setState({ savedPlotState: values.savedPlot });
  };

  getInvalidFields = () => {
    const { savedPlot } = this.props;
    const invalidFields = [];
    // Saved plots is the only significant field in the configuration
    if (!savedPlot || !savedPlot.value) {
      invalidFields.push('Saved Plot');
    }
    return invalidFields;
  };

  getSavedPlot = async () => {
    const { savedPlot } = this.props;
    // check if savedplot is public now
    if (savedPlot && savedPlot.value) {
      return UserPreferenceService.getUserPreference(1, savedPlot.value).then(
        (userPreference) => {
          if (userPreference && userPreference[0]) {
            this.setState({
              savedPlotState: {
                label: userPreference[0].name,
                published: userPreference[0].published,
                value: userPreference[0].userPreferenceId,
              },
            });
          }
        }
      );
    }
    return undefined;
  };

  renderDisplayContent = () => {
    const { savedPlot, classes } = this.props;
    const { savedPlotState } = this.state;
    const updatedSavedPlot = savedPlotState || savedPlot;
    let message = getWidgetConfigErrorMessage(this.getInvalidFields());
    if (message) {
      return (
        <>
          <ErrorSnackbar message={message} />
          <WidgetConfigError message={message} />
        </>
      );
    }
    if (savedPlotState) {
      return (
        <div className={classes.contentDiv}>
          <PlottingUtility
            isPlotsOnlyMode
            savedPlotId={updatedSavedPlot ? updatedSavedPlot.value : undefined}
          />
        </div>
      );
    }
    message = 'Saved plot is currently private';
    return (
      <>
        <ErrorSnackbar message={message} />
        <WidgetConfigError message={message} />
      </>
    );
  };

  render() {
    const { showTitle, title, savedPlot, showDisplay, onConfigFinish } =
      this.props;

    const { savedPlotState } = this.state;
    const updatedSavedPlot = savedPlotState || savedPlot;

    if (showDisplay) {
      let titleComp = null;
      if (showTitle) {
        titleComp = <WidgetTitle titleText={title} />;
      }
      return (
        <>
          {titleComp}
          {this.renderDisplayContent()}
        </>
      );
    }
    return (
      <InteractivePlotWidgetConfig
        onSave={this.handleSave}
        onCancel={onConfigFinish}
        showTitle={showTitle}
        title={title}
        savedPlot={updatedSavedPlot}
      />
    );
  }
}

export default withStyles(STYLES)(InteractivePlotWidget);
