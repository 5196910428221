import { ReactNode, MouseEvent, ChangeEvent, ElementType } from 'react';
import MaterialButton from '@mui/material/Button';
import { TOptions, useTranslation, TranslationType } from '@onc/i18n';

export interface ButtonProps {
  /**
   * Button label from the json file
   * util/i18n/src/translation/en/translation.json
   */
  translationKey: TranslationType;

  /** Options to pass in with the translation. */
  translationOptions?: TOptions;

  /** Function to call when the button is clicked */
  onClick?:
    | ((event: MouseEvent) => unknown)
    | ((event: ChangeEvent<HTMLInputElement>) => void);

  /** The class name of the button for custom styling */
  className?: string;

  /** When set to true the button will be unclickable and greyed out */
  disabled?: boolean;

  /** The id of the button */
  id?: string;

  /**
   * A Component (usually a MaterialIcon) that will be placed at the start of
   * the button
   */
  startIcon?: ReactNode;
  /**
   * A Component (usually a MaterialIcon) that will be placed at the start of
   * the button
   */
  endIcon?: ReactNode;
  /**
   * Change this at your own risk, the defaults should work for most cases! This
   * changes the color of the button In MUI v5, the "error" color (and more!)
   * will be supported natively so we can remove the "error" color from this
   * list once that happens See here:
   * https://mui.com/material-ui/api/button/#Button-prop-color
   */

  /**
   * The href to link to when the button is clicked. If this is set, the button
   * will be rendered as an anchor tag instead of a button tag
   */
  href?: string;

  /** The target of the link. Only used if href is set */
  target?: string;

  color?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
  /**
   * The type of button. Switch to submit when used in a form to submit all
   * values contained within the form
   */
  type?: undefined | 'submit';

  /**
   * The component to render the button as. This is useful for when you want to
   * use a file input as a button. For example:
   * https://mui.com/material-ui/react-button/#file-upload
   *
   * Shows how to use a file input as a button using component="label"
   */
  component?: ElementType;

  /** If component={RouterLink}, this is the URL that the button will redirect to */
  to?: string;

  /**
   * Material UI's Variant. This should match the Mockup created during the
   * design process
   */
  variant: 'contained' | 'outlined' | 'text';

  /** Aria label to help with screen readers */
  'aria-label'?: string;

  /** Whether the button should span the entire space it */
  fullWidth?: boolean;

  /** Size of the button */
  size?: 'small' | 'medium' | 'large';

  children?: ReactNode;
}

const buttonStyles = {
  margin: 1,
};

/**
 * Button that wraps Material-UI's Button with translations found in
 * translations.json. Buttons.tsx and Buttons.stories.tsx should provide most
 * use cases
 */
const Button = ({
  translationKey,
  translationOptions = undefined,
  component = 'button',
  onClick = undefined,
  variant,
  className = undefined,
  id = undefined,
  disabled = false,
  color = 'primary',
  endIcon = null,
  startIcon = null,
  type = undefined,
  to = undefined,
  href = undefined,
  target = undefined,
  'aria-label': ariaLabel = undefined,
  fullWidth = false,
  size = undefined,
  children = undefined,
}: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <MaterialButton
      id={id}
      className={className}
      sx={buttonStyles}
      component={component}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      href={href}
      target={target}
      to={to}
      aria-label={ariaLabel}
      fullWidth={fullWidth}
      size={size}
    >
      {t(translationKey, translationOptions)}
      {children}
    </MaterialButton>
  );
};

export default Button;
