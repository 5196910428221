import { useState, useCallback, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  useDateRangeCore,
  type DateRange,
  type PresetDateRange,
  type DateRangeState,
} from './useDateRangeCore';

/**
 * A custom React hook that manages a date range with options to set, shift
 * forward, shift back, and keep the range live with auto-refresh
 * functionality.
 */
const useDateRange = (
  initialDateRange?: DateRange,
  autoRefresh: boolean = false,
  refreshInterval: number = 1000
) => {
  const localInitialDateRange = useRef<DateRange>(initialDateRange);

  const [state, setState] = useState<DateRangeState>(() => ({
    dateRange: initialDateRange || [
      moment.utc().subtract(1, 'hour'),
      moment.utc(),
    ],
    preset: undefined,
  }));

  const handleStateChange = useCallback((newState: DateRangeState) => {
    setState(newState);
  }, []);

  const {
    dateRange,
    setDateRange,
    shiftDateRangeAhead,
    shiftDateRangeBack,
    keeplive,
    setPresetDateRange,
    zeroOutTime,
    refreshing,
  } = useDateRangeCore(
    state.dateRange,
    handleStateChange,
    state.preset,
    refreshInterval,
    autoRefresh
  );

  if (!_.isEqual(initialDateRange, localInitialDateRange.current)) {
    localInitialDateRange.current = initialDateRange;
    setDateRange(initialDateRange);
  }

  return {
    dateRange,
    setDateRange,
    shiftDateRangeAhead,
    shiftDateRangeBack,
    keeplive,
    refreshing,
    setPresetDateRange,
    zeroOutTime,
  };
};

export default useDateRange;
export type { DateRange, PresetDateRange };
