import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Loading } from '@onc/composite-components';
import { Box, Divider, ErrorPage, Grid, Typography } from 'base-components';
import DerivedParserDefinitionVersionSelect from 'domain/AppComponents/dropdowns/DerivedParserDefinitionVersionSelect';
import {
  MarginedReadOnlyField,
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import {
  handleOpenSensorDetails,
  openSensorDetails,
} from 'domain/AppComponents/link/SensorDetailsLink';
import {
  CancelButton,
  DeleteButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

import Panel from 'library/CompositeComponents/panel/Panel';
import DateUtils from 'util/DateUtils';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';

const classes = {
  entryFormContainer: {
    paddingLeft: 3,
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: 2,
  },
  panelDiv: {
    margin: 1,
  },
};

type DerivedSensorParserPayload = {
  dmasUser: {
    firstname: string;
    lastname: string;
  };
  sensor: {
    sensorName: string;
  };
  dspd: {
    derivedSensorParserDefinitionId: number;
    description: string;
    modifyBy: number;
    modifyDate: string;
    parserDefinitionDerivedVersion: {
      deviceTypeId: number;
      parserDefinitionDerivedVersionId: number;
      parserDefinitionId: number;
      versionNumber: number;
    };
    sensorId: number;
  };
};

type DerivedSensorPostProps = {
  description: string;
  versionNumber: string;
  dspdId: number;
  pdId: number;
  sensorId: number;
};

type ParserDefinitionMaintenanceForm = {
  version: number;
  description: string;
};

type ParserDefinitionMaintenanceProps = {
  pdId?: number;
  sensorId: number;
  permission: string;
};

const ParserDefinitionMaintenance = ({
  pdId = undefined,
  sensorId,
  permission,
}: ParserDefinitionMaintenanceProps) => {
  const { onError, onInfo } = useSnackbars();
  const [renderedOnce, setRenderedOnce] = useState<boolean>(false);

  const idsDefined =
    !isNaN(pdId) && !isNaN(sensorId) && pdId !== null && sensorId !== null;
  const pdData = useGet<
    DerivedSensorParserPayload,
    { sensorId: number; pdId: number }
  >(
    'DerivedSensorParserDefinitionService',
    {
      operation: 4,
      options: {
        enabled: idsDefined,
      },
    },
    {
      sensorId,
      pdId,
    }
  );

  const { mutate: saveDspdData } = usePost<DerivedSensorPostProps>(
    'DerivedSensorParserDefinitionService',
    {
      onSuccess: () => {
        onInfo('Dspd saved!');
        openSensorDetails(sensorId);
      },
      onError: (e) => {
        onError(`Dspd not saved! ${e.message}`);
      },
    },
    1
  );

  const { mutate: updateDspdData } = usePost<DerivedSensorPostProps>(
    'DerivedSensorParserDefinitionService',
    {
      onSuccess: () => {
        onInfo('Dspd updated!');
        openSensorDetails(sensorId);
      },
      onError: (e) => {
        onError(`Dspd not updated! ${e.message}`);
      },
    },
    2
  );

  const { mutate: deleteDspdData } = usePost<{
    pdId: number;
    sensorId: number;
  }>(
    'DerivedSensorParserDefinitionService',
    {
      onSuccess: () => {
        onInfo(`Dspd deleted!`);
        openSensorDetails(sensorId);
      },
      onError: (e) => {
        onError(`Dspd not deleted! ${e.message}`);
      },
    },
    3
  );

  const formMethods = useForm<ParserDefinitionMaintenanceForm>({
    mode: 'onBlur',
    defaultValues: {
      version: undefined,
      description: '',
    },
  });

  if (idsDefined && pdData.isLoading) {
    return <Loading />;
  }

  if (idsDefined && pdData.error) {
    return <ErrorPage />;
  }

  const renderDescriptionField = () => {
    if (permission === 'RW') {
      return (
        <FormTextField
          name="description"
          multiline
          rows={4}
          fullWidth
          translationKey="common.textfields.description"
        />
      );
    }
    return (
      <MarginedReadOnlyField
        labelText="Description"
        valueText={pdData.data?.dspd?.description}
      />
    );
  };

  const handleDelete = () => {
    deleteDspdData({ pdId, sensorId });
  };

  const handleSubmit = (values: ParserDefinitionMaintenanceForm) => {
    const params = {
      description: values.description !== '' ? values.description : undefined,
      versionNumber: values.version?.toString(),
      dspdId: pdData.data?.dspd.derivedSensorParserDefinitionId,
      pdId: !isNaN(pdId) ? pdId : 0,
      sensorId,
    };

    if (!isNaN(pdId)) {
      updateDspdData(params);
    } else {
      saveDspdData(params);
    }
  };

  if (!renderedOnce) {
    formMethods.reset({
      version: pdData.data?.dspd.parserDefinitionDerivedVersion.versionNumber,
      description: pdData.data?.dspd.description,
    });
    setRenderedOnce(true);
  }

  return (
    <Box sx={classes.panelDiv}>
      <Panel
        title={
          <>
            <Typography variant="body1">
              Derived Sensor Parser Definition ID:
            </Typography>
            <Typography variant="body1">
              {pdData.data?.dspd?.derivedSensorParserDefinitionId}
            </Typography>
          </>
        }
      >
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <Grid container direction="row" sx={classes.entryFormContainer}>
            <Grid item xs={6}>
              <DerivedParserDefinitionVersionSelect
                sensorId={sensorId}
                pdId={!isNaN(pdId) ? pdId : undefined}
                onError={onError}
                fullWidth
                name="version"
                label="Version"
              />
              <MarginedReadOnlyField
                labelText="Parser Definition Derived Version ID:"
                valueText={pdId}
              />
              <MarginedReadOnlyField
                labelText="Sensor Name"
                valueText={pdData.data?.sensor.sensorName}
              />
              {renderDescriptionField()}
              <ModifyBy
                username={
                  pdData.data
                    ? `${pdData.data?.dmasUser?.firstname} ${pdData.data?.dmasUser?.lastname}`
                    : ''
                }
              />
              <ModifyDate
                date={DateUtils.formatDateAsString(
                  new Date(pdData.data?.dspd.modifyDate)
                )}
              />
            </Grid>
          </Grid>
          <Box sx={classes.formButtons}>
            <SaveButton type="submit" />
            <CancelButton
              onClick={handleOpenSensorDetails(sensorId, 'derivation_tab')}
            />
            <DeleteButton
              onClick={handleDelete}
              disabled={isNaN(pdId) || pdId === null}
            />
          </Box>
        </Form>
        <Divider variant="middle" />
      </Panel>
    </Box>
  );
};

export default ParserDefinitionMaintenance;
