import {
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import * as React from 'react';
import L, { Marker as LeafletMarker } from 'leaflet';
import LatLngGraticule from 'react-leaflet-lat-lng-graticule';
import {
  LayerGroup,
  LeafletIcon,
  MapContext,
  Marker,
  Popup,
  LeafletTooltip as Tooltip,
  Typography,
} from 'base-components';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import SimpleMap from 'library/CompositeComponents/map/SimpleMap';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../../library/CompositeComponents/dashboard/DashboardTypes';
import EndeavourContext from '../context/EndeavourContext';
import endbottomBoundary from '../geojson/endbottom_boundary.json';
import endCenterBoundary from '../geojson/endcenter_boundary.json';
import endtopBoundary from '../geojson/endtop_boundary.json';
import ringSpur from '../geojson/RingSpur.json';
import '@asymmetrik/leaflet-d3';
import './EndeavourMapWidget.css';

const ENDEAVOUR_MAP_ID = 'endeavour-map';
const MAP_ICON_LIMIT = 1000;
LeafletMarker.prototype.options.icon = LeafletIcon.DEFAULT;

const EarthquakeMapWidget: React.FC<object> = () => {
  const {
    earthquakes,
    selectedEarthquake,
    mapSites,
    setMapSelectedEarthquakes,
  } = useContext(EndeavourContext);

  const mapContext = useContext(MapContext);
  const [map, setMap] = useState<any>();
  const [showLegend, setShowLegend] = useState(true);
  const isHandlerAttached = useRef(false);
  const hexLayerRef = useRef(null); // Store hexLayer
  const [isHypocentresChecked, setIsHypocentresChecked] = useState(true);

  // handles resizing the map when the widget changes size
  useEffect(() => {
    const handleOverlayAdd = (event) => {
      if (event.name === 'Legend') {
        setShowLegend(true); // Show legend when 'Legend' overlay is added
      }
      if (event.name === 'Hypocentres') {
        setIsHypocentresChecked(true); // Show legend when 'Legend' overlay is added
      }
    };

    const handleOverlayRemove = (event) => {
      if (event.name === 'Legend') {
        setShowLegend(false); // Hide legend when 'Legend' overlay is removed
      }
      if (event.name === 'Hypocentres') {
        setIsHypocentresChecked(false); // Show legend when 'Legend' overlay is added
      }
    };
    if (map?.current) {
      map.current.invalidateSize();
      if (!isHandlerAttached.current) {
        map.current.on('overlayadd', handleOverlayAdd);
        map.current.on('overlayremove', handleOverlayRemove);
        isHandlerAttached.current = true;
      }
    }
  });

  useEffect(() => {
    setMap(mapContext?.mapRef);
  }, [mapContext]);

  useEffect(() => {
    if (!map?.current) {
      return;
    }

    L.geoJSON(endbottomBoundary as any, {
      style() {
        return { color: 'black', weight: 5 };
      },
    }).addTo(map.current);

    L.geoJSON(endtopBoundary as any, {
      style() {
        return { color: 'black', weight: 5 };
      },
    }).addTo(map.current);

    L.geoJSON(endCenterBoundary as any, {
      style() {
        return { color: 'black', weight: 5 };
      },
    }).addTo(map.current);

    L.geoJSON(ringSpur as any).addTo(map.current);
  }, [map]);

  useEffect(() => {
    if (map?.current) {
      if (selectedEarthquake) {
        const selectedEvent = earthquakes.find(
          (event) => event.endeavourEventId === selectedEarthquake
        );
        if (selectedEvent) {
          map.current.setView(
            [selectedEvent.latitude, selectedEvent.longitude],
            10 // zoom level when an earthquake is selected
          );
        }
      } else {
        map.current.setView(
          [48, -129], // default coordinates
          8 // default zoom level
        );
      }
    }
  }, [selectedEarthquake, earthquakes, map]);

  const rgbToHex = (rgb) => `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;

  const viridisColors = useMemo(
    () => [
      [253, 231, 37], // Yellow
      [121, 209, 81], // Yellow-green
      [31, 180, 115], // Light green
      [51, 120, 142], // Green-blue
      [72, 40, 120], // Dark blue-purple
      [68, 1, 84], // Deep purple
    ],
    [] // No dependencies, this will only compute once
  );

  const memoizedSetMapSelectedEarthquakes = useCallback(
    (ids) => {
      setMapSelectedEarthquakes(ids);
    },
    [setMapSelectedEarthquakes] // Only changes if the context function changes
  );

  const getColorFromViridis = (colorArray, percent) => {
    const index = Math.floor(percent * (colorArray.length - 1));
    const nextIndex = Math.min(index + 1, colorArray.length - 1);
    const remainder = percent * (colorArray.length - 1) - index;

    const interpolatedColor = [
      Math.round(
        colorArray[index][0] +
          remainder * (colorArray[nextIndex][0] - colorArray[index][0])
      ),
      Math.round(
        colorArray[index][1] +
          remainder * (colorArray[nextIndex][1] - colorArray[index][1])
      ),
      Math.round(
        colorArray[index][2] +
          remainder * (colorArray[nextIndex][2] - colorArray[index][2])
      ),
    ];

    return interpolatedColor;
  };

  const createCircleIcon = (magnitude, depth) => {
    const normalizedMagnitude = magnitude / 7;

    const size = 10 + normalizedMagnitude * 40;
    const minDepth = 0; // Minimum depth
    const maxDepth = 3; // Maximum depth
    const percent = Math.min(1, (depth - minDepth) / (maxDepth - minDepth));
    const color = getColorFromViridis(viridisColors, percent);
    const rgbColor = rgbToHex(color);

    return L.divIcon({
      className: 'circle-icon',
      iconSize: [size, size], // Adjust the size of the circle dynamically
      html: `<div style="border-radius: 50%; width: ${size}px; height: ${size}px; background-color: ${rgbColor};"></div>`,
    });
  };

  // SVG content
  const triangleSvg = `
  <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <polygon points="50,0 100,100 0,100" fill="red"/>
  </svg>
`;

  // Encoding the SVG
  const encodedSvg = encodeURIComponent(triangleSvg);
  const svgUrl = `data:image/svg+xml,${encodedSvg}`;

  const greenTriangleIcon = new L.Icon({
    iconUrl: svgUrl,
    iconSize: [18, 18], // Size of the icon
    iconAnchor: [9, 9], // Point of the icon which will correspond to marker's location
  });

  const buildEventMarkers = () => {
    let filteredData;
    if (selectedEarthquake) {
      filteredData = earthquakes.filter(
        (event) => event.endeavourEventId === selectedEarthquake
      );
    } else {
      filteredData = earthquakes
        .sort((a, b) => (a.originTime < b.originTime ? 1 : -1))
        .slice(0, MAP_ICON_LIMIT);
    }
    return filteredData.map((event) => (
      <Marker
        key={event.endeavourEventId}
        position={[event.latitude, event.longitude]}
        icon={createCircleIcon(event.magnitude, event.depthKM)}
      >
        <Popup>
          <div>
            <b>Public ID: </b> {event.endeavourEventId}
          </div>
          <div>
            <b>Time:</b> {event.originTime.replace('T', ' ')} (UTC)
          </div>
          <div>
            <b>Location: </b>
            {`${event.latitude.toFixed(2)},${event.longitude.toFixed(2)}`}
          </div>
          <div>
            <b>Magnitude:</b> {event.magnitude.toFixed(2)}
          </div>
          <div>
            <b>Depth</b>: {event.depthKM.toFixed(1)} km
          </div>
        </Popup>
      </Marker>
    ));
  };

  const buildSiteMarkers = () =>
    mapSites.map((site) => (
      <Marker
        key={site.station}
        position={[site.latitude, site.longitude]}
        icon={greenTriangleIcon}
        zIndexOffset={1000}
      >
        <Tooltip permanent={false}>
          <div>
            <b>Station:</b> {site.station}
          </div>
          <div>
            <b>Location: </b>
            {`${site.latitude.toFixed(2)},${site.longitude.toFixed(2)}`}
          </div>
          <div>
            <b>Instrument Type:</b> {site.deviceCategory}
          </div>
          <div>
            <b>Depth:</b> {(site.depth / 100).toFixed(2)} km
          </div>
        </Tooltip>
      </Marker>
    ));

  const eventMarkers = buildEventMarkers();
  const siteMarkers = buildSiteMarkers();

  const generateGradientImage = (width, height) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, width, 0);
    viridisColors.forEach((color, index) => {
      const stopPosition = index / (viridisColors.length - 1); // Calculate stop position (0 to 1)
      gradient.addColorStop(stopPosition, rgbToHex(color));
    });

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, width, height);

    // Return the canvas data URL as a PNG image
    return canvas.toDataURL('image/png');
  };

  const createCircleSize = (magnitude) => {
    const normalizedMagnitude = magnitude / 7;
    return 10 + normalizedMagnitude * 40; // The same formula from your `createCircleIcon`
  };

  const renderLegend = () => {
    if (!showLegend) {
      return <></>;
    }
    return (
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: 9001,
          borderRadius: '5px',
          padding: '5px',
          marginTop: '5px',
          marginLeft: '5px',
          width: '125px',
        }}
      >
        <h4>Legend</h4>
        <h5>Depth</h5>
        <img src={generateGradientImage(120, 20)} alt="legend" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>0 km</span>
          <span>3 km</span>
        </div>
        <h5>Magnitude</h5>
        {[1, 2, 3, 4, 5].map((magnitude) => {
          const size = createCircleSize(magnitude);
          return (
            <div
              key={magnitude}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <div
                style={{
                  width: `${size}px`,
                  height: `${size}px`,
                  backgroundColor: '#0000FF', // Use a constant color for the size legend
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              />
              <span>{magnitude}</span>
            </div>
          );
        })}
        {isHypocentresChecked && (
          <>
            {MAP_ICON_LIMIT > earthquakes.length ? (
              <Typography variant="body4">
                {earthquakes.length} hypocentres shown
              </Typography>
            ) : (
              <>
                <Typography variant="body4">
                  {MAP_ICON_LIMIT} of {earthquakes.length} hypocentres shown
                </Typography>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!map?.current) return;

    // Cleanup the previous hex layer
    if (hexLayerRef.current) {
      map.current.removeLayer(hexLayerRef.current);
    }

    // Create the hex layer
    const hexLayer = L.hexbinLayer({
      radius: 30,
      opacity: 0.5,
      colorRange: viridisColors.map(rgbToHex), // Use the viridis color array
      radiusRange: [5, 30],
      duration: 200,
    })
      .hoverHandler(
        L.HexbinHoverHandler.tooltip({
          tooltipContent: (i) => {
            const magnitudes = i.map((item) => item.o[3]);
            const depths = i.map((item) => item.o[4]);
            const minMagnitude = Math.min(...magnitudes);
            const maxMagnitude = Math.max(...magnitudes);
            const minDepth = Math.min(...depths);
            const maxDepth = Math.max(...depths);
            const count = i.length;

            return `
            Count: ${count}
            <br>Min Magnitude: ${minMagnitude.toFixed(2)}
            <br>Max Magnitude: ${maxMagnitude.toFixed(2)}
            <br>Min Depth: ${minDepth.toFixed(3)} km
            <br>Max Depth: ${maxDepth.toFixed(3)} km
          `;
          },
        })
      )
      .colorValue((counts) => {
        // Use the logarithm of the count for coloring
        const count = counts.length;
        return count > 0 ? Math.log10(count) : 0;
      });
    hexLayerRef.current = hexLayer;

    // Attach event handlers
    hexLayer.dispatch().on('click', (d, i) => {
      const ids = i.map((item) => item.o[2]);
      memoizedSetMapSelectedEarthquakes(ids);
    });

    // Add data and the hex layer to the map
    const data = earthquakes.map((eq) => [
      eq.longitude,
      eq.latitude,
      eq.endeavourEventId,
      eq.magnitude,
      eq.depthKM,
    ]);
    hexLayer.data(data);
    hexLayer.addTo(map.current);
  }, [map, earthquakes, viridisColors, memoizedSetMapSelectedEarthquakes]);

  const buildDataOverlayLayers = () => {
    const overlayLayers = [];
    overlayLayers.push({
      name: 'Lat/Lon_Grid_Lines',
      subLayers: (
        <LayerGroup>
          <LatLngGraticule checked name="Lat/Lon_Grid_Lines" />
        </LayerGroup>
      ),
      checked: true,
    });
    overlayLayers.push({
      name: 'Legend',
      subLayers: <LayerGroup>{renderLegend()}</LayerGroup>,
      checked: true,
    });
    overlayLayers.push({
      name: 'Hypocentres',
      subLayers: <LayerGroup>{eventMarkers}</LayerGroup>,
      checked: true,
    });
    overlayLayers.push({
      name: 'Sites',
      subLayers: <LayerGroup>{siteMarkers}</LayerGroup>,
      checked: true,
    });
    return overlayLayers;
  };

  return (
    <SimpleMap
      mapId={ENDEAVOUR_MAP_ID}
      initialZoom={8}
      center={[48, -129]}
      overlayLayers={buildDataOverlayLayers()}
      width="100%"
      height="100%"
    />
  );
};

const value = { mapRef: React.createRef(), ENDEAVOUR_MAP_ID };
const EndeavourMapProvider: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id } = props;
  return (
    <Widget key={id} title="Endeavour Map" {...props}>
      <MapContext.Provider value={value}>
        <EarthquakeMapWidget {...props} />
      </MapContext.Provider>
    </Widget>
  );
};

EndeavourMapProvider.widgetKey = 'endeavour-earthquake-map';
EndeavourMapProvider.widgetTitle = 'Earthquake Map';
EndeavourMapProvider.defaultDataGrid = {
  i: 'endeavour-earthquake-map',
  x: 0,
  y: 0,
  w: 4,
  h: 7,
};

export default EndeavourMapProvider;
