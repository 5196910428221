import { useEffect, useState, useRef } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { FlacFile } from 'domain/AppComponents/Dashboard/audio-widget/AudioPlayerWidget';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import useBroadcast from 'util/hooks/useBroadcast';

const useStyles = makeStyles(() =>
  createStyles({
    player: {
      width: '100%',
    },
  })
);

type AudioPlayerProps = {
  playlist: any[];
  playerId: string;
  flacData?: FlacFile;
  isBroadcasting?: boolean;
  isLooping?: boolean;
  latestSpectrogramFlac?: string;
};

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  playlist,
  playerId,
  flacData = undefined,
  isBroadcasting = false,
  isLooping = false,
  latestSpectrogramFlac = undefined,
}: AudioPlayerProps) => {
  const classes = useStyles();
  const audioElement = useRef(null);
  const [currentSrcFileIndex, setCurrentSrcFileIndex] = useState(0);
  const [, broadcastAudioTime] = useBroadcast(
    '0',
    BroadcastChannel.AudioTime,
    0
  );

  const currentSrcFile = playlist[currentSrcFileIndex].file;
  const fileName = playlist[currentSrcFileIndex].title;
  const fileExtension = fileName.split('.').pop();

  const handleCurrentSrcFileEnded = () => {
    const newIndex = currentSrcFileIndex + 1;
    if (newIndex < playlist.length) {
      setCurrentSrcFileIndex(newIndex);
    }
  };

  useEffect(() => {
    // Broadcasts the audio's playing time when audio synchronization is enabled
    const audioSource = document.getElementById('audioPlayerClip');

    if (isBroadcasting && audioElement && audioSource) {
      audioElement.current.ontimeupdate = () => {
        broadcastAudioTime(audioElement.current.currentTime);
      };
    }
  }, [isBroadcasting, playerId, broadcastAudioTime]);

  /* Note: There is currently a bug when playing FLAC audio in Chrome, it does not account for files with empty seektables.
    This may be causing a bug where seeking near the end of a file can cause the play controls to break.
    See: https://issues.chromium.org/issues/40946577
  */
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioElement}
        id={playerId}
        className={classes.player}
        loop={isLooping}
        controls
        onEnded={handleCurrentSrcFileEnded}
      >
        <source
          src={flacData ? latestSpectrogramFlac : currentSrcFile}
          type={`audio/${fileExtension}`}
        />
      </audio>
      <p id="audioPlayerClip" data-cy={flacData ? 'latest-hy-flac' : null}>
        {flacData ? flacData.filename : fileName}
      </p>
    </>
  );
};

export default AudioPlayer;
