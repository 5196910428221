import { useState, useEffect, useCallback } from 'react';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import DataPreviewManagementService from 'domain/services/DataPreviewManagementsService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import { AbsoluteRightButtonStyle } from 'library/CompositeComponents/button/CommonButtonStyles';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import DateFormatUtils from 'util/DateFormatUtils';
import Environment from 'util/Environment';
import DataPreview from './DataPreview';
import DataPreviewManagemetDialogBox from './DataPreviewManagementDialogBox';
import SortableLink from '../network-console/SortableLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(2)})`,
      minWidth: theme.spacing(50),
    },
    button: {
      ...AbsoluteRightButtonStyle(theme),
      position: 'absolute',
      zIndex: 4,
    },
  })
);

type Props = {
  onError: (message: any, callback?: any) => void;
};

const columns = [
  { title: 'ID', name: 'dataPreviewRuleId' },
  { title: 'Description', name: 'description' },
  { title: 'Rule', name: 'ruleString' },
  { title: 'Keep Summary', name: 'keepSummaryTab' },
  { title: 'Enabled', name: 'enabled' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'Created Date (UTC)', name: 'createdDate' },
  { title: 'hiddenId', name: 'hiddenId' },
];

const columnExtensions = [
  {
    columnName: 'dataPreviewRuleId',
    align: 'left',
  },
  {
    columnName: 'description',
    align: 'left',
    width: ColumnInfo.large,
    wordWrapEnabled: true,
  },
  {
    columnName: 'ruleString',
    align: 'left',
    width: ColumnInfo.xLarge,
    wordWrapEnabled: true,
  },
  {
    columnName: 'keepSummaryTab',
    align: 'left',
  },
  {
    columnName: 'enabled',
    align: 'left',
  },
  {
    columnName: 'modifyBy',
    align: 'left',
  },
  {
    columnName: 'modifyDate',
    align: 'left',
  },
  {
    columnName: 'createdDate',
    align: 'left',
  },
];

const getFullUserName = (dataPreview: DataPreview) =>
  dataPreview.modifyByString;

const DataPreviewManagementPage = ({ onError }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataPreviewManagementRules, setDataPreviewManagementRules] = useState<
    Array<DataPreview>
  >([]);
  const [isDataPreviewOpen, setIsDataPreviewOpen] = useState<boolean>(false);
  const [editDataPreview, setEditDataPreview] = useState<DataPreview>(null);
  const userHasWritePermission = Environment.getDmasUserPrivilege() === 'RW';
  const classes = useStyles();

  const getDataPreview = useCallback(async () => {
    try {
      setIsLoading(true);
      let newDataPreviews =
        await DataPreviewManagementService.getDataPreviewInfo();
      newDataPreviews = newDataPreviews.map((dataPreview) => {
        const newDataPreview = { ...dataPreview };
        if (userHasWritePermission) {
          newDataPreview.dataPreviewRuleId = (
            <SortableLink
              id={dataPreview.dataPreviewRuleId}
              onClick={() => setEditDataPreview(dataPreview)}
            />
          );
          newDataPreview.enabled = dataPreview.enabled.toString();
          newDataPreview.keepSummaryTab = dataPreview.keepSummaryTab.toString();
          newDataPreview.hiddenId = dataPreview.dataPreviewRuleId;
        }
        newDataPreview.modifyBy = getFullUserName(dataPreview);
        newDataPreview.modifyDate = DateFormatUtils.formatDate(
          dataPreview.modifyDate,
          'full'
        );
        newDataPreview.createdDate = DateFormatUtils.formatDate(
          dataPreview.createdDate,
          'full'
        );
        return newDataPreview;
      });
      setDataPreviewManagementRules(newDataPreviews);
    } catch (e) {
      onError('Could not get Data Preview Information.');
    } finally {
      setIsLoading(false);
    }
  }, [userHasWritePermission, onError]);

  useEffect(() => {
    getDataPreview();
  }, [getDataPreview]);

  useEffect(() => {
    setIsDataPreviewOpen(Boolean(editDataPreview));
  }, [editDataPreview]);

  const handlePostCall =
    (
      call: (
        dataPreview: DataPreview
      ) => Promise<{ payload: DataPreview; statusCode: number }>
    ) =>
    async (value) => {
      try {
        await call(value);
        getDataPreview();
      } catch (e: any) {
        onError(e.message);
      }
    };

  const renderCreateRuleButton = () => (
    <ContainedButton
      translationKey="dataPreview.createRule"
      className={classes.button}
      onClick={() => {
        setIsDataPreviewOpen(true);
      }}
      disabled={isDataPreviewOpen || !userHasWritePermission}
    />
  );

  const renderDialogBox = () => {
    if (!isDataPreviewOpen) {
      return <></>;
    }
    if (editDataPreview) {
      return (
        <DataPreviewManagemetDialogBox
          label="Edit Rule"
          onClose={() => setEditDataPreview(null)}
          onSubmit={handlePostCall(
            DataPreviewManagementService.updateDataPreview
          )}
          userHasWritePermission={userHasWritePermission}
          dataPreview={editDataPreview}
          onDelete={getDataPreview}
        />
      );
    }
    return (
      <DataPreviewManagemetDialogBox
        label="Create Rule"
        onClose={() => setIsDataPreviewOpen(false)}
        onSubmit={handlePostCall(
          DataPreviewManagementService.createDataPreview
        )}
        userHasWritePermission={userHasWritePermission}
        dataPreview={editDataPreview}
      />
    );
  };

  return (
    <>
      {renderCreateRuleButton()}
      {renderDialogBox()}

      <SortableTable
        pageSize={dataPreviewManagementRules.length}
        searchable
        searchBarMoveable
        hiddenColumns={['hiddenId']}
        columns={columns}
        columnExtensions={columnExtensions}
        rows={dataPreviewManagementRules}
        stripedRows
        elevation={0}
        pageSizes={[15, 30, 60, 0]}
        isLoading={isLoading}
        sortExtensions={
          userHasWritePermission
            ? [
                {
                  columnName: 'dataPreviewRuleId',
                  compare: (a, b) => (a.props.id > b.props.id ? 1 : -1),
                },
              ]
            : []
        }
      />
    </>
  );
};

export default withSnackbars(DataPreviewManagementPage);
