import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import '@onc/i18n';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import { oncDefaultTheme } from '@onc/theme';
import { AdapterMoment, LocalizationProvider } from 'base-components';
import 'babel-polyfill';

import BaseBatchContainer from 'domain/AppComponents/batch/BatchManagement';
import BaseTaskOutputTable from 'domain/AppComponents/batch/task-table/TaskOutputTable';
import BaseSearchHistoryPage from 'domain/AppComponents/search-history/SearchHistoryPage';
import BaseAction from 'domain/Apps/action-listing/ActionPage';
import BaseAgreementSiteDeviceTable from 'domain/Apps/agreement-site-devices/AgreementSiteDevicesTable';
import AnnotationEntryPage from 'domain/Apps/annotations/AnnotationEntryPage';
import BaseAnnotationSearchPage from 'domain/Apps/annotations/AnnotationSearchPage';
import BaseAppliedQaqc from 'domain/Apps/applied-qaqc-listing/AppliedQaqcPage';
import BaseCableManagementPage from 'domain/Apps/cable-management/CableManagementPage';
import BaseCablesPage from 'domain/Apps/cables/CablesPage';
import BaseCharacteristic from 'domain/Apps/characteristic-listing/CharacteristicPage';
import CommunityFishersDevicePackageInfoPage from 'domain/Apps/community-fishers/CommunityFishersDevicePackageInfoPage';
import CompromisedAnnotationEntryPage from 'domain/Apps/compromised-instruments/CompromisedAnnotationEntryPage';
import CompromisedInstrumentsPage from 'domain/Apps/compromised-instruments/CompromisedInstrumentsPage';
import BaseDashboards from 'domain/Apps/dashboards/DashboardsPage';
import BaseWidgets from 'domain/Apps/dashboards/Widgets';
import BaseDataPlayer from 'domain/Apps/data-player/DataPlayerContainer';
import DataPreviewAnnotationDisplay from 'domain/Apps/data-preview/DataPreviewAnnotationDisplay';
import DataPreviewManagementPage from 'domain/Apps/data-preview-management/DataPreviewManagementPage';
import BaseDataRating from 'domain/Apps/data-rating-listing/DataRatingPage';
import BaseDataRatingMaintenance from 'domain/Apps/data-rating-maintenance/DataRatingContainer';
import BaseDataViewer from 'domain/Apps/data-viewer/DataViewerDashboard';
import BaseDerivedSensorParserDefintion from 'domain/Apps/derived-sensor-parser-definition-listing/ParserDefinitionPage';
import BaseDerivedSensorParserDefinitionMaintenance from 'domain/Apps/derived-sensor-parser-definition-maintenance/ParserDefinitionMaintenanceContainer';
import BaseDeviceAction from 'domain/Apps/device-action/DeviceActionPage';
import BaseDeviceActionsPage from 'domain/Apps/device-action-listing/DeviceActionsPage';
import BaseDeviceActionMaintenance from 'domain/Apps/device-action-maintenance/DeviceActionMaintenanceContainer';
import BaseIP from 'domain/Apps/device-ip-listing/DeviceIpPage';
import BaseDeviceListing from 'domain/Apps/device-listing/DeviceListingPage';
import BaseNameplate from 'domain/Apps/device-nameplate/NamePlatePage';
import BaseDeviceNameplateMaintenance from 'domain/Apps/device-nameplate-maintenance/NamePlateMaintenanceContainer';
import BaseDevicePort from 'domain/Apps/device-port/DevicePortContainer';
import BasePVCSViewPage from 'domain/Apps/device-presentation/PVCSViewPage';
import BaseProcurement from 'domain/Apps/device-procurement/DeviceProcurementPage';
import BaseDeviceSearch from 'domain/Apps/device-search/DeviceSearchPage';
import BaseDeviceSensor from 'domain/Apps/device-sensor/DeviceSensorPage';
import BaseDeviceStatus from 'domain/Apps/device-status/DeviceStatusPage';
import BaseDeviceSynchronization from 'domain/Apps/device-synchronization/DeviceSynchronizationPage';
import BaseDeviceTypePage from 'domain/Apps/device-type/DeviceTypePage';
import BaseDeviceWorkflowBulkUpdatePage from 'domain/Apps/device-workflow-bulk-update/DeviceWorkflowBulkUpdatePage';
import BaseDigitalFishers from 'domain/Apps/digital-fishers/game/DigitalFishers';
import BaseDigitalFishersCampaignManagement from 'domain/Apps/digital-fishers/management/DFManagement';
import BaseDatasetLandingPage from 'domain/Apps/doi-dataset-landing-page/DatasetLandingPageContainer';
import BaseDriverSchedulePage from 'domain/Apps/driver-scheduler/DriverSchedulePage';
import EarthquakeCatalogPage from 'domain/Apps/earthquake-catalog/EarthquakeCatalogPage';
import EarthquakeDetailPage from 'domain/Apps/earthquake-detail/EarthquakeDetailPage';
import EarthquakeNotificationRegistrationContainer from 'domain/Apps/earthquake-notification-registration/EarthquakeNotificationRegistrationContainer';
import BaseElectricalRating from 'domain/Apps/electrical-rating/ElectricalRatingPage';
import EndeavourEarthquakeCatalogPage from 'domain/Apps/endeavour-earthquake-catalog/EndeavourEarthquakeCatalogPage';
import BaseExpeditionManagement from 'domain/Apps/expedition-management/ExpeditionLandingPage';
import FileManagementPage from 'domain/Apps/file-management/FileManagementPage.jsx';
import BaseFileUpload from 'domain/Apps/file-upload/FileUploadPage';
import FormFieldPage from 'domain/Apps/form-field/FormFieldPage';
import BaseDeviceGeneral from 'domain/Apps/general-tab/GeneralTabPage';
import BaseGeospatialSearch from 'domain/Apps/geospatial-search/GeospatialSearch';
import BaseGeospatialSearchv2 from 'domain/Apps/geospatial-search/GeospatialSearchv2';
import BaseIpMaintenance from 'domain/Apps/ip-maintenance/IpMaintenanceContainer';
import SupportRequestContainer from 'domain/Apps/issue-collector/SupportRequestContainer';
import BaseLicencePage from 'domain/Apps/licence/LicenceTable';
import BaseDafTest from 'domain/Apps/live-daf-testing/test-details/TestForm';
import BaseDafTestHistory from 'domain/Apps/live-daf-testing/test-history/TestHistoryTable';
import BaseLocalContextsPage from 'domain/Apps/local-contexts/LocalContextsPage';
import BaseMultiQueuePage from 'domain/Apps/multi-queue/MultiQueuePage';
import DeviceCategoryPage from 'domain/Apps/network-console/device-category/DeviceCategoryPage';
import OrganizationsPage from 'domain/Apps/network-console/organizations/OrganizationsPage';
import BaseLandingPage from 'domain/Apps/oceans-3-landing-page/LandingPage';
import BaseOpenApiPage from 'domain/Apps/openApi/OpenApiPage';
import BasePamguardPSFUploadPage from 'domain/Apps/pamguard/PamguardPSFUploadPage';
import BasePamguardTaskCreatePage from 'domain/Apps/pamguard/PamguardTaskCreatePage';
import BaseParserDefinitionViewPage from 'domain/Apps/parser-definition/ParserDefinitionViewPage';
import BasePort from 'domain/Apps/port-listing/PortPage';
import BaseQaqcDetailsContainer from 'domain/Apps/qaqc-details/QaqcDetailsContainer';
import BaseQaqcFinderPage from 'domain/Apps/qaqc-finder/QaqcFinderPage';
import BaseResourceRole from 'domain/Apps/resource-role/ResourceRolePage';
import BaseDeckLogApp from 'domain/Apps/sea-tube/DeckLogApp';
import BaseDiveLogApp from 'domain/Apps/sea-tube/dive-log/DiveLogApp';
import BaseFixedCameraLocationsApp from 'domain/Apps/sea-tube/fixed-cameras/FixedCameraLocationApp';
import BaseFixedCameraPlaybackApp from 'domain/Apps/sea-tube/fixed-cameras/FixedCameraPlaybackApp';
import BaseLiveExpeditionContainer from 'domain/Apps/sea-tube/LiveExpeditionContainer';
import BasePlaylistPlayback from 'domain/Apps/sea-tube/PlaylistPlaybackApp';
import BasePlaylistsApp from 'domain/Apps/sea-tube/PlaylistsApp';
import BaseSeaTubeSearchApp from 'domain/Apps/sea-tube/search/SeaTubeSearchApp';
import BaseChatLogIngestion from 'domain/Apps/seatube/ChatLogIngestionContainer';
import BaseSeaTubeConfigurationPage from 'domain/Apps/seatube/seatube-config/SeaTubeConfigurationPage';
import BaseSeaTube from 'domain/Apps/seatube/SeaTubeContainer';
import BaseSeaTubeIngestion from 'domain/Apps/seatube/SeaTubeIngestion';
import BaseSeaTubeSearch from 'domain/Apps/seatube/SeaTubeSearch';
import BaseCalibration from 'domain/Apps/sensor-calibration-listing/CalibrationPage';
import BaseSensorCalibrationMaintenance from 'domain/Apps/sensor-calibration-maintenance/SensorCalibrationMaintenanceContainer';
import BaseSensorMaintenancePage from 'domain/Apps/sensor-maintenance/SensorMaintenancePage';
import BaseSensorTypePage from 'domain/Apps/sensor-type/SensorTypePage';
import BaseCastReviewManagement from 'domain/Apps/site-device-maintenance/CastReviewManagement';
import BaseSiteDeviceMaintenance from 'domain/Apps/site-device-maintenance/SiteDeviceMaintenanceContainer';
import BaseSiteDeviceSubsetMaintenance from 'domain/Apps/site-device-maintenance/SiteDeviceSubsetMaintenanceContainer';
import BaseSiteDeviceSubsetRePostProcess from 'domain/Apps/site-device-maintenance/SiteDeviceSubsetRePostProcess';
import BaseSiteDeviceSubsetSearch from 'domain/Apps/site-device-maintenance/SiteDeviceSubsetSearch';
import BaseSite from 'domain/Apps/site-listing/SitePage';
import RequestSupportPage from 'domain/Apps/support-request/RequestSupportPage';
import BaseSynchronizerManagementApp from 'domain/Apps/synchronizer-management/SynchronizerManagementApp';
import BaseSystemConsole from 'domain/Apps/system-console/SystemConsolePage';
import BaseTaxonResourceTable from 'domain/Apps/taxon-resource/TaxonResourceTable';
import BaseTaxonButtonSetConfig from 'domain/Apps/taxonomy/TaxonButtonSetConfigPage';
import BaseTaxonomyApp from 'domain/Apps/taxonomy/TaxonomyApp';
import TaxonomyAttributeManagementPage from 'domain/Apps/taxonomy-attribute-management/TaxonomyAttributeManagementPage';
import BaseUserOrganizationListingPage from 'domain/Apps/user-organization-listing/UserOrganizationListingPage';
import Environment from 'util/Environment';
import SnackbarProvider from 'util/hooks/useSnackbars';
import ReactQueryProvider from 'util/providers/ReactQueryProvider';

const ExportWrapper = ({ children = undefined }) => {
  // Clear the current application token to ensure it doesn't have a value from the
  // last application that was rendered.
  Environment.setCurrentApplicationToken(undefined);

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={oncDefaultTheme}>
            <ReactQueryProvider>
              <SnackbarProvider>{children}</SnackbarProvider>
            </ReactQueryProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

ExportWrapper.propTypes = {
  children: PropTypes.node,
};

const DataViewer = (props) => (
  <ExportWrapper>
    <BaseDataViewer {...props} />
  </ExportWrapper>
);

const AnnotationSearchPage = (props) => (
  <ExportWrapper>
    <BaseAnnotationSearchPage {...props} />
  </ExportWrapper>
);
const DafTest = (props) => (
  <ExportWrapper>
    <BaseDafTest {...props} />
  </ExportWrapper>
);

const DeviceSynchronization = (props) => (
  <ExportWrapper>
    <BaseDeviceSynchronization {...props} />
  </ExportWrapper>
);

const ResourceRole = (props) => (
  <ExportWrapper>
    <BaseResourceRole {...props} />
  </ExportWrapper>
);

const Action = (props) => (
  <ExportWrapper>
    <BaseAction {...props} />
  </ExportWrapper>
);

const DafTestHistory = (props) => (
  <ExportWrapper>
    <BaseDafTestHistory {...props} />
  </ExportWrapper>
);

const Dashboards = (props) => (
  <ExportWrapper>
    <BaseDashboards {...props} />
  </ExportWrapper>
);

const Widgets = (props) => (
  <ExportWrapper>
    <BaseWidgets {...props} />
  </ExportWrapper>
);

const DigitalFishers = (props) => (
  <ExportWrapper>
    <BaseDigitalFishers {...props} />
  </ExportWrapper>
);

const DigitalFishersCampaignManagement = (props) => (
  <ExportWrapper>
    <BaseDigitalFishersCampaignManagement {...props} />
  </ExportWrapper>
);

const ExpeditionManagement = (props) => (
  <ExportWrapper>
    <BaseExpeditionManagement {...props} />
  </ExportWrapper>
);

const SeaTubeSearch = (props) => (
  <ExportWrapper>
    <BaseSeaTubeSearch {...props} />
  </ExportWrapper>
);

const GeospatialSearch = (props) => (
  <ExportWrapper>
    <BaseGeospatialSearch {...props} />
  </ExportWrapper>
);

const GeospatialSearchv2 = (props) => (
  <ExportWrapper>
    <BaseGeospatialSearchv2 {...props} />
  </ExportWrapper>
);

const PlaylistPlaybackApp = (props) => (
  <ExportWrapper>
    <BasePlaylistPlayback {...props} />
  </ExportWrapper>
);

const SeaTube = (props) => (
  <ExportWrapper>
    <BaseSeaTube {...props} />
  </ExportWrapper>
);

const SystemConsole = (props) => (
  <ExportWrapper>
    <BaseSystemConsole {...props} />
  </ExportWrapper>
);

const SiteDeviceMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseSiteDeviceMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const SiteDeviceSubsetMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseSiteDeviceSubsetMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const DatasetLandingPageContainer = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDatasetLandingPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const DataPlayerContainer = (props) => (
  <ExportWrapper>
    <BaseDataPlayer {...props} />
  </ExportWrapper>
);

const BatchContainer = (props) => (
  <ExportWrapper>
    <BaseBatchContainer {...props} />
  </ExportWrapper>
);

const TaskOutputTable = (props) => (
  <ExportWrapper>
    <BaseTaskOutputTable {...props} />
  </ExportWrapper>
);

const Site = (props) => (
  <ExportWrapper>
    <BaseSite {...props} />
  </ExportWrapper>
);

const DeviceGeneral = (props) => (
  <ExportWrapper>
    <BaseDeviceGeneral {...props} />
  </ExportWrapper>
);

const ElectricalRating = (props) => (
  <ExportWrapper>
    <BaseElectricalRating {...props} />
  </ExportWrapper>
);

const Port = (props) => (
  <ExportWrapper>
    <BasePort {...props} />
  </ExportWrapper>
);

const DevicePort = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => <BaseDevicePort {...routerProps} {...props} />}
    />
  </ExportWrapper>
);

const DeviceAction = (props) => (
  <ExportWrapper>
    <BaseDeviceAction {...props} />
  </ExportWrapper>
);

const DeviceActionMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDeviceActionMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const DeviceSensor = (props) => (
  <ExportWrapper>
    <BaseDeviceSensor {...props} />
  </ExportWrapper>
);

const DataRating = (props) => (
  <ExportWrapper>
    <BaseDataRating {...props} />
  </ExportWrapper>
);
const PhysicalCharacteristics = (props) => (
  <ExportWrapper>
    <BaseCharacteristic {...props} />
  </ExportWrapper>
);

const DataRatingMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDataRatingMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const Procurement = (props) => (
  <ExportWrapper>
    <BaseProcurement {...props} />
  </ExportWrapper>
);

const Nameplate = (props) => (
  <ExportWrapper>
    <BaseNameplate {...props} />
  </ExportWrapper>
);

const IP = (props) => (
  <ExportWrapper>
    <BaseIP {...props} />
  </ExportWrapper>
);

const IpMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseIpMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const NameplateMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDeviceNameplateMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const FileUpload = (props) => (
  <ExportWrapper>
    <BaseFileUpload {...props} />
  </ExportWrapper>
);

const DeviceStatus = (props) => (
  <ExportWrapper>
    <BaseDeviceStatus {...props} />
  </ExportWrapper>
);

const DeviceListing = (props) => (
  <ExportWrapper>
    <BaseDeviceListing {...props} />
  </ExportWrapper>
);

const QaqcDetailsContainer = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseQaqcDetailsContainer {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const QaqcFinderPage = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseQaqcFinderPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const TaxonButtonSetConfig = (props) => (
  <ExportWrapper>
    <BaseTaxonButtonSetConfig {...props} />
  </ExportWrapper>
);

const DeviceSearch = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => <BaseDeviceSearch {...routerProps} {...props} />}
    />
  </ExportWrapper>
);

const PamguardPSFUploadPage = (props) => (
  <ExportWrapper>
    <BasePamguardPSFUploadPage {...props} />
  </ExportWrapper>
);

const PamguardTaskCreatePage = (props) => (
  <ExportWrapper>
    <BasePamguardTaskCreatePage {...props} />
  </ExportWrapper>
);

const SupportRequest = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <SupportRequestContainer {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const SynchronizerManagement = (props) => (
  <ExportWrapper>
    <BaseSynchronizerManagementApp {...props} />
  </ExportWrapper>
);

const TaxonomyAttributeManagement = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <TaxonomyAttributeManagementPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const TaxonomyApp = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => <BaseTaxonomyApp {...routerProps} {...props} />}
    />
  </ExportWrapper>
);

const DeviceActions = (props) => (
  <ExportWrapper>
    <BaseDeviceActionsPage {...props} />
  </ExportWrapper>
);

const Cables = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => <BaseCablesPage {...routerProps} {...props} />}
    />
  </ExportWrapper>
);

const AppliedQaqc = (props) => (
  <ExportWrapper>
    <BaseAppliedQaqc {...props} />
  </ExportWrapper>
);

const Calibration = (props) => (
  <ExportWrapper>
    <BaseCalibration {...props} />
  </ExportWrapper>
);

const DerivedSensorParserDefinition = (props) => (
  <ExportWrapper>
    <BaseDerivedSensorParserDefintion {...props} />
  </ExportWrapper>
);

const AnnotationEntry = (props) => (
  <ExportWrapper>
    <AnnotationEntryPage {...props} />
  </ExportWrapper>
);

const SensorCalibrationMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseSensorCalibrationMaintenance {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const ParserDefinitionMaintenance = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDerivedSensorParserDefinitionMaintenance
          {...routerProps}
          {...props}
        />
      )}
    />
  </ExportWrapper>
);

const SensorMaintenance = (props) => (
  <ExportWrapper>
    <BaseSensorMaintenancePage {...props} />
  </ExportWrapper>
);

const SeaTubeConfiguration = (props) => (
  <ExportWrapper>
    <BaseSeaTubeConfigurationPage {...props} />
  </ExportWrapper>
);

const DriverSchedule = (props) => (
  <ExportWrapper>
    <BaseDriverSchedulePage {...props} />
  </ExportWrapper>
);

const MultiQueue = (props) => (
  <ExportWrapper>
    <BaseMultiQueuePage {...props} />
  </ExportWrapper>
);

const OpenApi = (props) => (
  <ExportWrapper>
    <BaseOpenApiPage {...props} />
  </ExportWrapper>
);

const TaxonResource = (props) => (
  <ExportWrapper>
    <BaseTaxonResourceTable {...props} />
  </ExportWrapper>
);

const SeaTubeIngestion = (props) => (
  <ExportWrapper>
    <BaseSeaTubeIngestion {...props} />
  </ExportWrapper>
);

const SiteDeviceSubsetRePostProcess = (props) => (
  <ExportWrapper>
    <BaseSiteDeviceSubsetRePostProcess {...props} />
  </ExportWrapper>
);

const CastReviewManagement = (props) => (
  <ExportWrapper>
    <BaseCastReviewManagement {...props} />
  </ExportWrapper>
);

const EarthquakeCatalog = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <EarthquakeCatalogPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const EarthquakeNotificationRegistration = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <EarthquakeNotificationRegistrationContainer
          {...routerProps}
          {...props}
        />
      )}
    />
  </ExportWrapper>
);

const EndeavourEarthquakeCatalog = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <EndeavourEarthquakeCatalogPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const EarthquakeDetail = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <EarthquakeDetailPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const ChatLogIngestion = (props) => (
  <ExportWrapper>
    <BaseChatLogIngestion {...props} />{' '}
  </ExportWrapper>
);
const LandingPage = (props) => (
  <ExportWrapper>
    <BaseLandingPage {...props} />
  </ExportWrapper>
);

const SiteDeviceSubsetSearch = (props) => (
  <ExportWrapper>
    <BaseSiteDeviceSubsetSearch {...props} />
  </ExportWrapper>
);

const FileManagement = (props) => (
  <ExportWrapper>
    <FileManagementPage {...props} />
  </ExportWrapper>
);

const DeviceCategory = (props) => (
  <ExportWrapper>
    <DeviceCategoryPage {...props} />
  </ExportWrapper>
);

const FormField = (props) => (
  <ExportWrapper>
    <FormFieldPage {...props} />
  </ExportWrapper>
);

const RequestSupport = (props) => (
  <ExportWrapper>
    <RequestSupportPage {...props} />
  </ExportWrapper>
);

const Organizations = (props) => (
  <ExportWrapper>
    <OrganizationsPage {...props} />
  </ExportWrapper>
);

const DataPreviewAnnotation = (props) => (
  <ExportWrapper>
    <DataPreviewAnnotationDisplay {...props} />
  </ExportWrapper>
);

const DataPreviewManagement = (props) => (
  <ExportWrapper>
    <DataPreviewManagementPage {...props} />
  </ExportWrapper>
);

const DeviceTypePage = (props) => (
  <ExportWrapper>
    <BaseDeviceTypePage {...props} />
  </ExportWrapper>
);

const SensorTypePage = (props) => (
  <ExportWrapper>
    <BaseSensorTypePage {...props} />
  </ExportWrapper>
);

const LicencePage = (props) => (
  <ExportWrapper>
    <BaseLicencePage {...props} />
  </ExportWrapper>
);

const PVCSViewPage = (props) => (
  <ExportWrapper>
    <BasePVCSViewPage {...props} />
  </ExportWrapper>
);

const ParserDefinitionViewPage = (props) => (
  <ExportWrapper>
    <BaseParserDefinitionViewPage {...props} />
  </ExportWrapper>
);

const DeckLogApp = (props) => (
  <ExportWrapper>
    <BaseDeckLogApp {...props} />
  </ExportWrapper>
);

const LiveExpeditionEmbed = (props) => (
  <ExportWrapper>
    <BaseLiveExpeditionContainer {...props} />
  </ExportWrapper>
);

const SearchHistoryPage = (props) => (
  <ExportWrapper>
    <BaseSearchHistoryPage {...props} />
  </ExportWrapper>
);

const AgreementSiteDeviceTable = (props) => (
  <ExportWrapper>
    <BaseAgreementSiteDeviceTable {...props} />
  </ExportWrapper>
);

const PlaylistsApp = (props) => (
  <ExportWrapper>
    <BasePlaylistsApp {...props} />
  </ExportWrapper>
);

const DiveLogApp = (props) => (
  <ExportWrapper>
    <BaseDiveLogApp {...props} />
  </ExportWrapper>
);

const LocalContextsPage = (props) => (
  <ExportWrapper>
    <BaseLocalContextsPage {...props} />
  </ExportWrapper>
);

const CommunityFishersDevicePackageInfo = (props) => (
  <ExportWrapper>
    <CommunityFishersDevicePackageInfoPage {...props} />
  </ExportWrapper>
);

const CompromisedAnnotationEntry = (props) => (
  <ExportWrapper>
    <CompromisedAnnotationEntryPage {...props} />
  </ExportWrapper>
);

const CompromisedInstruments = (props) => (
  <ExportWrapper>
    <CompromisedInstrumentsPage {...props} />
  </ExportWrapper>
);

const CableManagementPage = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseCableManagementPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const DeviceWorkflowBulkUpdatePage = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseDeviceWorkflowBulkUpdatePage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const UserOrganizationListingPage = (props) => (
  <ExportWrapper>
    <Route
      render={(routerProps) => (
        <BaseUserOrganizationListingPage {...routerProps} {...props} />
      )}
    />
  </ExportWrapper>
);

const FixedCameraLocations = (props) => (
  <ExportWrapper>
    <BaseFixedCameraLocationsApp {...props} />;
  </ExportWrapper>
);

const FixedCameraPlayback = (props) => (
  <ExportWrapper>
    <BaseFixedCameraPlaybackApp {...props} />
  </ExportWrapper>
);

const SeaTubeSearchApp = (props) => (
  <ExportWrapper>
    <BaseSeaTubeSearchApp {...props} />
  </ExportWrapper>
);

const exports = {
  Action,
  AgreementSiteDeviceTable,
  AnnotationEntry,
  AnnotationSearchPage,
  AppliedQaqc,
  BatchContainer,
  Cables,
  Calibration,
  CastReviewManagement,
  ChatLogIngestion,
  DafTest,
  DafTestHistory,
  Dashboards,
  DataPlayerContainer,
  DataPreviewAnnotation,
  DataPreviewManagement,
  DataRating,
  DataRatingMaintenance,
  DatasetLandingPageContainer,
  DeckLogApp,
  DerivedSensorParserDefinition,
  DeviceAction,
  DeviceActionMaintenance,
  DeviceActions,
  DeviceCategory,
  DeviceListing,
  DevicePort,
  DeviceSearch,
  DeviceSensor,
  DeviceStatus,
  DeviceSynchronization,
  DeviceTypePage,
  DigitalFishers,
  DigitalFishersCampaignManagement,
  DiveLogApp,
  DriverSchedule,
  EarthquakeCatalog,
  EarthquakeNotificationRegistration,
  EarthquakeDetail,
  EndeavourEarthquakeCatalog,
  ElectricalRating,
  ExpeditionManagement,
  FileManagement,
  FileUpload,
  FixedCameraLocations,
  FixedCameraPlayback,
  FormField,
  RequestSupport,
  DeviceGeneral,
  GeospatialSearch,
  GeospatialSearchv2,
  IP,
  IpMaintenance,
  LandingPage,
  LicencePage,
  LiveExpeditionEmbed,
  LocalContextsPage,
  MultiQueue,
  Nameplate,
  NameplateMaintenance,
  OpenApi,
  Organizations,
  PamguardPSFUploadPage,
  PamguardTaskCreatePage,
  ParserDefinitionMaintenance,
  ParserDefinitionViewPage,
  PhysicalCharacteristics,
  PlaylistPlaybackApp,
  Port,
  Procurement,
  PVCSViewPage,
  QaqcDetailsContainer,
  QaqcFinderPage,
  ResourceRole,
  SearchHistoryPage,
  SeaTube,
  SeaTubeConfiguration,
  SeaTubeIngestion,
  SeaTubeSearch,
  SeaTubeSearchApp,
  SensorCalibrationMaintenance,
  SensorMaintenance,
  SensorTypePage,
  Site,
  SiteDeviceMaintenance,
  SiteDeviceSubsetMaintenance,
  SiteDeviceSubsetRePostProcess,
  SiteDeviceSubsetSearch,
  SupportRequest,
  SynchronizerManagement,
  SystemConsole,
  TaskOutputTable,
  TaxonButtonSetConfig,
  TaxonomyAttributeManagement,
  TaxonomyApp,
  TaxonResource,
  Widgets,
  PlaylistsApp,
  CommunityFishersDevicePackageInfo,
  CompromisedAnnotationEntry,
  CompromisedInstruments,
  CableManagementPage,
  DeviceWorkflowBulkUpdatePage,
  UserOrganizationListingPage,
  DataViewer,
};

export default exports;
