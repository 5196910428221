import { WidgetOption } from 'library/CompositeComponents/dashboard/DashboardTypes';
import AnnotationTableWidget from './AnnotationTableWidget';
import ButtonSetWidget from './ButtonSetWidget';
import ManualEntryWidget from './ManualEntryWidget';

const ALL_WIDGETS: WidgetOption[] = [
  {
    Component: AnnotationTableWidget,
    label: 'Annotation Table',
    multiple: false,
  },
  {
    Component: ButtonSetWidget,
    label: 'Button Sets',
    multiple: true,
  },
  {
    Component: ManualEntryWidget,
    label: 'Manual Entry',
    multiple: false,
  },
];

export default class WidgetLibrary {
  widgetOptions: WidgetOption[];

  constructor(widgetOptions?: WidgetOption[]) {
    this.widgetOptions = widgetOptions || ALL_WIDGETS;
  }

  getWidgetOptions = () => this.widgetOptions;

  /**
   * The Dashboard will use an index on the widget key by appending -{index} to
   * the key. This function will remove the number and trailing dash from the
   * key and return the appropriate widget.
   */
  getWidgetByGridKey = (gridKey: string) => {
    // Use a regular expression to remove the last occurrence of `-{index}`
    const key = gridKey.replace(/-\d+$/, '');
    return this.getWidgetByKey(key);
  };

  getWidgetByKey = (name: string) => {
    const targetOption = this.widgetOptions.find(
      (option) => option.Component.widgetKey === name
    );
    return targetOption ? targetOption.Component : undefined;
  };

  has = (name: string) => !!this.getWidgetByKey(name);
}
