import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import DeviceActionMaintenanceRO from 'domain/AppComponents/device-action-maintenance/DeviceActionMaintenanceRO';
import DeviceActionMaintenanceRW from 'domain/AppComponents/device-action-maintenance/DeviceActionMaintenanceRW';

import Environment from 'util/Environment';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(2),
  },
});

const defaultState = {};

class DeviceActionMaintenance extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
    }),
    deviceActionId: PropTypes.number,
    deviceId: PropTypes.number,
    cruiseId: PropTypes.number,
    isFromDevice: PropTypes.bool,
    isFromCruise: PropTypes.bool,
    history: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    classes: undefined,
    deviceActionId: undefined,
    deviceId: undefined,
    cruiseId: undefined,
    isFromDevice: false,
    isFromCruise: false,
  };

  constructor(props) {
    super(props);
    const { deviceActionId, deviceId } = this.props;
    this.state = {
      deviceActionId,
      deviceId,
      ...defaultState,
    };
  }

  render() {
    const {
      deviceActionId,
      deviceId,
      cruiseId,
      isFromDevice,
      isFromCruise,
      history,
    } = this.props;

    if (Environment.getDmasUserPrivilege() === 'RW') {
      return (
        <DeviceActionMaintenanceRW
          deviceActionId={deviceActionId}
          deviceId={deviceId}
          cruiseId={cruiseId}
          isFromDevice={isFromDevice}
          isFromCruise={isFromCruise}
          history={history}
        />
      );
    }

    return (
      <DeviceActionMaintenanceRO
        deviceActionId={deviceActionId}
        isFromDevice={isFromDevice}
        isFromCruise={isFromCruise}
        cruiseId={cruiseId}
        history={history}
      />
    );
  }
}
export default withStyles(STYLES)(DeviceActionMaintenance);
