class TextParsingUtils {
  static parseWktPolygonString = (wktString, code) => {
    // Parser Well Known Text String stored in area.boundingArea
    if (!wktString) {
      const errorMessage = `Bounding area ${
        code ? `of ${code} ` : ``
      }is empty.`;
      return {
        positions: [],
        error: errorMessage,
      };
    }
    const polygon = wktString
      .replace(/^\s*\w*\s*\(\s*\(\s*/, '')
      .replace(/\s*\)\s*\)\s*$/, '');

    const polygonPoints = [];
    // split by commas

    let errorMessage = '';

    try {
      for (const point of polygon.split(',')) {
        const pair = point.trim().split(' ');

        const lat = pair[1];
        const lon = pair[0];

        // check that points are valid
        if (isNaN(lat) || isNaN(lon)) {
          const message = `Invalid polygon coordinate (${lat}, ${lon})`;
          const codeMessage = code ? ` for location ${code}` : ``;
          throw new Error(`${message}${codeMessage}.`);
        }
        polygonPoints.push([pair[1], pair[0]]);
      }
    } catch (error) {
      errorMessage = error.message;
    }
    return { positions: polygonPoints, error: errorMessage };
  };

  static parseLatLngsToWKT = (latLngs) => {
    /*
     * If length is less than one, there are no polygons. If length is greater than one, there are multiple polygons
     * We currently only support one polygon at a time, if that changes this will have to change as well
     */
    if (latLngs.length !== 1) return '';
    const polygon = latLngs[0];
    const coords = [];
    const { lat, lng } = polygon[0];

    for (let k = 0; k < polygon.length; k += 1) {
      coords.push(`${polygon[k].lng} ${polygon[k].lat}`);
    }

    return `POLYGON((${coords.join(',')},${lng} ${lat}))`;
  };
}
export default TextParsingUtils;
