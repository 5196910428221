/* eslint-disable react/no-unused-class-component-methods */
import { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@onc/composite-components';
import { MenuItem } from 'base-components';
import LayoutService from 'domain/AppComponents/Dashboard/LayoutService';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import LocalStorageUtil from 'util/LocalStorageUtil';
import ObjectUtils from 'util/ObjectUtils';
import { chatLogAllComponents, chatLogDefault } from './layouts';
import ResponsiveGridLayout from './ResponsiveGridLayout';
import ToggleItemsMenu from './ToggleItemsMenu';

/**
 * Class ToggleItemsResponsiveLayout. Shows/hides children on page and save
 * layout and children hide/show preferences to local storage.
 */

/**
 * @typedef {object} Item
 * @property {string} key
 * @property {string} name
 * @property {bool} hidden
 */

/**
 * @typedef {object} Layout
 * @property {string} i
 * @property {number} x
 * @property {number} y
 * @property {number} w
 * @property {number} h
 * @property {number} [minW]
 * @property {number} [maxW]
 * @property {number} [minH]
 * @property {number} [maxH]
 * @property {bool} [static]
 * @property {bool} [isDraggable]
 * @property {bool} [isResizable]
 */

/**
 * @typedef {object} ReactGridLayouts
 * @property {Layout} lg
 * @property {Layout} md
 * @property {Layout} sm
 * @property {Layout} xs
 * @property {Layout} xxs
 */

/**
 * @typedef {object} LayoutsObj
 * @property {string} key
 * @property {string} name
 * @property {bool} [permission]
 * @property {bool} [selected]
 * @property {ReactGridLayouts} layout
 */

class ToggleItemsResponsiveLayout extends Component {
  static nameComparator = (a, b) => (a.name < b.name ? -1 : 1);

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    onError: PropTypes.func.isRequired,
    rowHeight: PropTypes.number.isRequired,
    cols: PropTypes.shape({}).isRequired,
    items: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          hidden: PropTypes.bool,
          permission: PropTypes.bool,
        })
      ),
      PropTypes.objectOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            hidden: PropTypes.bool,
            permission: PropTypes.bool,
          })
        )
      ),
    ]),
    breakpoints: PropTypes.shape({
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
      xs: PropTypes.number,
      xxs: PropTypes.number,
    }),
    title: PropTypes.string,
    appBarContent: PropTypes.node,
    titleContent: PropTypes.node,
    bottomDrawerContent: PropTypes.node,
    requiresPermission: PropTypes.bool,
    isStatic: PropTypes.bool,
    onLayoutChange: PropTypes.func,
    onLayoutClick: PropTypes.func,
    GridItemDefaults: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      w: PropTypes.number,
      h: PropTypes.number,
      minW: PropTypes.number,
      maxW: PropTypes.number,
      minH: PropTypes.number,
      maxH: PropTypes.number,
      static: PropTypes.bool,
      isDraggable: PropTypes.bool,
      isResizable: PropTypes.bool,
    }),
    defaultLayout: PropTypes.shape({}),
    resourceId: PropTypes.number,
    resourceTypeId: PropTypes.number,
    annotationPermissions: PropTypes.bool,
    containerId: PropTypes.string,
    className: PropTypes.string,
    margin: PropTypes.arrayOf(PropTypes.number),
    userType: PropTypes.string,
    chatLog: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    items: [],
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 150 },
    isStatic: false,
    title: undefined,
    appBarContent: undefined,
    titleContent: undefined,
    bottomDrawerContent: undefined,
    requiresPermission: false,
    onLayoutChange: undefined,
    onLayoutClick: undefined,
    GridItemDefaults: {
      x: 0,
      y: 0,
      w: 2,
      h: 2,
    },
    defaultLayout: undefined,
    resourceId: undefined,
    resourceTypeId: undefined,
    annotationPermissions: undefined,
    containerId: undefined,
    userType: undefined,
    className: '',
    margin: [10, 10],
    chatLog: undefined,
  };

  /**
   * Fills breakpoints so that the layout has all items for each breakpoint
   *
   * @param {ReactGridLayouts} layout
   * @returns {ReactGridLayouts}
   */
  fillBreakpoints = (layout) => {
    const layoutKeys = ['lg', 'md', 'sm', 'xs', 'xxs'];
    const keys = layout ? Object.keys(layout) : [];

    // return default empty layout for each breakpoint
    if (keys.length < 1) {
      const returnLayout = {};
      layoutKeys.forEach((key) => {
        returnLayout[key] = [];
      });
      return ObjectUtils.deepClone(returnLayout);
    }

    const updatedLayouts = ObjectUtils.deepClone(layout);
    if (keys.length < 5) {
      // find missing keys
      const missingKeys = layoutKeys.filter((key) => keys.indexOf(key) < 0);
      const baseKey = keys.find((key) => missingKeys.indexOf(key) < 0);
      missingKeys.forEach((key) => {
        updatedLayouts[key] = layout[baseKey];
      });
    }

    return updatedLayouts;
  };

  getLayoutConfigs = (layouts) => {
    const userLayouts = layouts.sort().map((layout) => {
      const { layoutId, layoutName, widgetLayout, isPublic, permission } =
        layout;
      return {
        permission: permission || true,
        key: `layout-${layoutId}`,
        name: layoutName,
        layout: JSON.parse(widgetLayout),
        isPublic: !!isPublic,
      };
    });

    return userLayouts.sort(ToggleItemsResponsiveLayout.nameComparator);
  };

  constructor(props) {
    super(props);
    let localLayout;
    let layouts = [this.createDefaultLayout(props.items)];
    if (props.userType) {
      localLayout = LocalStorageUtil.getItem(
        `${props.userType}-${props.containerId}-layout`
      );
    }
    const initSelectedLayout = localLayout || layouts[0];
    layouts = this.setSelectedLayout(layouts, initSelectedLayout.key);

    // set default layout
    const defaultLayout = ObjectUtils.deepClone(initSelectedLayout);
    defaultLayout.layout = this.fillBreakpoints(defaultLayout.layout);

    const itemsForLayout = this.getItemsForLayout(
      props.items,
      initSelectedLayout.key
    );

    initSelectedLayout.layout = this.getVisibleLayouts(
      itemsForLayout,
      initSelectedLayout.layout
    );

    const disableScroll = !!initSelectedLayout.isPublic;
    const currentBreakpoint = this.getCurrentBreakpoint();
    this.state = {
      currentItems: itemsForLayout.sort(
        ToggleItemsResponsiveLayout.nameComparator
      ),
      currentLayout: initSelectedLayout,
      layouts,
      defaultLayout,
      currentBreakpoint,
      disableScroll,
      localLayout,
      loading: true,
      layoutRef: null,
    };
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    const { annotationPermissions } = this.props;
    if (annotationPermissions) {
      await this.getLayouts();
    } else {
      this.setState({ loading: false });
    }
  }

  // Resize layout when the ref changes
  onRefChange = (node) => {
    const { currentLayout, currentBreakpoint } = this.state;
    this.setState({ ref: node });
    this.resizeLayout(currentLayout, currentBreakpoint);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleUserLayoutChange = (layout) => {
    const { currentLayout, currentItems } = this.state;

    const newLayout = { ...currentLayout };
    newLayout.layout[this.getCurrentBreakpoint()] = layout;
    this.updateLocalStorage(newLayout, currentItems, true);
  };

  updateLocalStorage = (newLayout, newItems, isCustom) => {
    const { containerId, userType } = this.props;
    const { layouts } = this.state;
    const updatedLayout = { ...newLayout };
    updatedLayout.selected = true;
    updatedLayout.isPublic = true;
    if (isCustom) {
      updatedLayout.key = 'custom-layout';
      this.setState({
        layouts: this.setSelectedLayout(layouts, 'custom-layout'),
      });
    }
    LocalStorageUtil.setItem(
      `${userType}-${containerId}-layout`,
      updatedLayout
    );
    LocalStorageUtil.setItem(`${userType}-${containerId}-items`, newItems);
    this.setState({ localLayout: updatedLayout });
  };

  getLayouts = async () => {
    const { resourceId, resourceTypeId, onError, items } = this.props;
    const { currentBreakpoint, currentLayout, localLayout } = this.state;
    return LayoutService.get({
      operation: 100,
      resourceTypeId,
      resourceId,
    })
      .then((payload) => {
        let layouts = this.getSortedLayoutConfigs(payload.layouts);
        layouts = this.injectOverridableLayouts(layouts);

        const layoutsWithSelected = this.setSelectedLayout(
          layouts,
          currentLayout.key
        );
        const selectedLayout =
          layoutsWithSelected.filter((layout) => layout.selected)[0] ||
          layoutsWithSelected[0];

        // resize from old screen size to attempted fit of new layout

        const resizedLayout = this.resizeLayout(
          localLayout || selectedLayout,
          currentBreakpoint
        );
        this.setState({
          currentItems: this.getItemsForLayout(items, currentLayout.key),
          defaultLayout: resizedLayout,
          layouts: layoutsWithSelected,
          currentLayout: resizedLayout,
          disableScroll: !!resizedLayout.isPublic,
        });
      })
      .catch((error) => {
        onError(error.message);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getSortedLayoutConfigs = (layouts) => {
    const publicLayouts = this.getLayoutConfigs(
      layouts.filter((layout) => layout.isPublic)
    );
    const readOnlyLayouts = this.getLayoutConfigs(
      layouts.filter((layout) => !layout.isPublic && layout.permission === 'RO')
    );
    const readWriteLayouts = this.getLayoutConfigs(
      layouts.filter((layout) => !layout.isPublic && layout.permission === 'RW')
    );
    return publicLayouts.concat(readOnlyLayouts).concat(readWriteLayouts);
  };

  injectOverridableLayouts = (layouts) => {
    const { chatLog, annotationPermissions } = this.props;
    if (layouts) {
      let updatedLayouts = [...layouts];
      const canShowChatLog = annotationPermissions && chatLog.length;
      updatedLayouts = updatedLayouts.map((layout) => {
        if (layout.name === 'All Components' && canShowChatLog) {
          const overiddenLayout = { ...layout };
          overiddenLayout.layout = chatLogAllComponents;
          return overiddenLayout;
        }
        if (
          (layout.name === 'Public (Default)' || layout.name === 'Public') &&
          canShowChatLog
        ) {
          const overiddenLayout = { ...layout };
          overiddenLayout.layout = chatLogDefault;
          return overiddenLayout;
        }
        return layout;
      });
      updatedLayouts = this.setSelectedLayout(updatedLayouts);
      return updatedLayouts;
    }
    return layouts;
  };

  createDefaultLayout = (items) => {
    const { defaultLayout } = this.props;
    if (defaultLayout) return defaultLayout;

    let tempItems = items;
    // If items is a non-empty object (i.e. not an array), pick any entry
    if (items.constructor === Object) {
      const keys = Object.keys(items);
      tempItems = keys.length ? items[keys[0]] : [];
    }
    return {
      isPublic: true,
      key: 'default-layout',
      name: 'Default',
      layout: this.getVisibleLayouts(tempItems, {}),
      permission: true,
      selected: true,
    };
  };

  /** Takes layouts prop and configures them so they have a selected variable. */
  /**
   * @param {Array} layouts
   * @param {string} selectedKey - Key of layout that is currently selected
   * @returns {LayoutsObj}
   */
  setSelectedLayout = (layouts, selectedKey) => {
    const { items, annotationPermissions } = this.props;
    if (layouts.length < 1) return [this.createDefaultLayout(items)];
    let hasSelectedLayout = false;
    const tempLayouts = layouts.map((layoutObj) => {
      const { selected, key, permission, ...rest } = layoutObj;

      // if there is no key, check if selected
      const isSelected = selectedKey ? selectedKey === key : !!selected;
      // only want one layout selected
      if (isSelected && !hasSelectedLayout && this.hasPermission(permission)) {
        hasSelectedLayout = true;
        return { key, selected: true, permission, ...rest };
      }
      return { key, selected: false, permission, ...rest };
    });

    if (!hasSelectedLayout && selectedKey !== 'custom-layout') {
      if (annotationPermissions) {
        const index = tempLayouts.indexOf(
          tempLayouts.find((layout) => layout.name === 'Public (Default)')
        );
        if (index && tempLayouts[index]) {
          tempLayouts[index].selected = true;
        }
      }
    }
    return ObjectUtils.deepClone(tempLayouts);
  };

  /**
   * Gets the first layout with selected = true of layout key that equals
   * selectedKey, if parameter given
   *
   * @param {string} [selectedKey]
   * @returns {LayoutsObj}
   */
  getSelectedLayout = (selectedKey) => {
    const { items } = this.props;
    const { currentItems, layouts } = this.state;
    // layout object is empty - make a default layout
    if (layouts.length < 1) {
      return this.createDefaultLayout(items);
    }
    // find layout that is selected and has permission
    for (let i = 0; i < layouts.length; i += 1) {
      const { selected, permission, key, layout, ...rest } = layouts[i];
      const isSelected = selectedKey ? key === selectedKey : !!selected;
      if (isSelected && this.hasPermission(permission)) {
        return {
          layout: this.getVisibleLayouts(currentItems, layout),
          key,
          selected: true,
          permission,
          ...rest,
        };
      }
    }

    // can't find a layout that is selected and with permission - just return first layout
    const { layout, selected, ...rest } = layouts[0];
    return {
      layout: this.getVisibleLayouts(currentItems, layout),
      selected: true,
      ...rest,
    };
  };

  getCurrentBreakpoint = () => {
    const { breakpoints } = this.props;
    const currentWidth = window.innerWidth;
    const { lg, md, sm, xs } = breakpoints;
    if (currentWidth > lg) {
      return 'lg';
    }
    if (currentWidth > md) {
      return 'md';
    }
    if (currentWidth > sm) {
      return 'sm';
    }
    if (currentWidth > xs) {
      return 'xs';
    }
    return 'xxs';
  };

  /**
   * Removes the chat log option if it exists and a chat log doesn't Adds it if
   * a local layout previously didn't have chat-log and it should
   *
   * @param {{ items; key?: string }[]} itemList
   */
  checkForChatLog = (itemList) => {
    const { chatLog, items } = this.props;
    if (itemList && itemList.length) {
      let updatedItems = [...itemList];
      if (!chatLog || !chatLog.length) {
        updatedItems = updatedItems.filter((item) => item.key !== 'chat-log');
      }
      if (chatLog && chatLog.length) {
        if (!updatedItems.find((item) => item.key === 'chat-log')) {
          const chatLogItem = items.find((item) => item.key === 'chat-log');
          if (chatLogItem) {
            updatedItems.push(chatLogItem);
          }
        }
      }
      return updatedItems;
    }
    return itemList;
  };

  /**
   * Gets items based on key argument, or just clones items if items is an
   * array.
   *
   * @param items
   * @param {string} [key] - Layout key
   */
  getItemsForLayout = (items, key) => {
    const localItems = this.readItemsFromLocal(items);
    if (Array.isArray(localItems) && localItems.length) {
      return this.checkForChatLog(localItems);
    }
    if (Array.isArray(items)) {
      return ObjectUtils.deepClone(items).filter(this.checkForChatLog);
    }

    if (!key || !items[key]) {
      return [];
    }
    return ObjectUtils.deepClone(items[key]);
  };

  /**
   * Iterate through items and see if a child matches an item and if it is
   * hidden.
   *
   * @param {string} childKey Key to search for in items
   * @returns True if childKey has an item match that is hidden, false
   *   otherwise.
   */
  shouldHideChild = (childKey) => {
    const { currentItems } = this.state;
    // Make everything visible if no items defined
    if (currentItems.length < 1) {
      return false;
    }

    // hide if child is not in the current items
    const child = currentItems.filter(
      (item) =>
        item.key === childKey &&
        !item.hidden &&
        this.hasPermission(item.permission)
    );
    if (child.length < 1) return true;
    return false;
  };

  /**
   * Validates if the layout requires permission to access, or if the user has
   * sufficient permissions.
   *
   * @param {bool} permission - Permission to test
   * @returns {bool}
   */
  hasPermission = (permission) => {
    const { requiresPermission } = this.props;
    return !requiresPermission || permission;
  };

  /**
   * Returns the visible layouts for a given set of items.
   *
   * @param {Item[]} items
   * @param {ReactGridLayouts} layouts
   * @returns {ReactGridLayouts} Visible layouts
   */
  getVisibleLayouts = (items, layouts) => {
    const { GridItemDefaults } = this.props;
    if (items.length < 1) {
      // just return layouts with filled breakpoints
      return this.fillBreakpoints(ObjectUtils.deepClone(layouts));
    }

    // get hidden items/items that user does not have permission to see
    const hiddenItems = items.reduce((acc, item) => {
      const { key, hidden, permission } = item;
      return { ...acc, [key]: !!hidden || !this.hasPermission(permission) };
    }, {});

    const visibleItems = items.filter((item) => !hiddenItems[item.key]);
    const newLayout = {};

    // check if layout is empty
    if (Object.keys(layouts).length === 0) {
      // put all visible items in layout at default size
      newLayout.lg = visibleItems.map((item) => ({
        i: item.key,
        ...GridItemDefaults,
      }));
    } else {
      // remove all items in hiddenItems from all breakpoints
      Object.keys(layouts).forEach((breakpoint) => {
        const breakpointLayout = Object.values(layouts[breakpoint]);
        const newBreakpointLayout = breakpointLayout.filter(
          (gridItems) => !hiddenItems[gridItems.i]
        );
        const visibleItemLayout = [];
        visibleItems.forEach((item) => {
          const itemInLayout = breakpointLayout.filter(
            (gridItem) => item.key === gridItem.i
          );
          if (itemInLayout.length <= 0) {
            visibleItemLayout.push({ i: item.key, ...GridItemDefaults });
          }
        });

        newLayout[breakpoint] = newBreakpointLayout.concat(visibleItemLayout);
      });
    }

    return this.fillBreakpoints(newLayout);
  };

  /**
   * Updates default layout state.
   *
   * @param {LayoutsObj} newLayout
   */
  updateDefaultLayout = (newLayout) => {
    this.setState({
      defaultLayout: newLayout,
    });
  };

  /**
   * Updates layout preference
   *
   * @param {LayoutsObj | ReactGridLayouts} layout
   * @param {LayoutsObj | ReactGridLayouts} newLayout
   */
  updateLayoutPreference = (layout, newLayout) => {
    const { onLayoutChange } = this.props;
    const { currentLayout, currentBreakpoint } = this.state;
    const newClonedLayout = ObjectUtils.deepClone(newLayout);

    // if react-grid-layout calls this function, we update currentLayout state object.
    const shouldUpdateCurrent = !newClonedLayout.key;

    const clonedCurrentLayout = ObjectUtils.deepClone(currentLayout);
    clonedCurrentLayout.layout = newClonedLayout;
    const actualSavedLayout = shouldUpdateCurrent
      ? clonedCurrentLayout
      : newClonedLayout;
    this.setState(
      {
        currentLayout: this.resizeLayout(actualSavedLayout, currentBreakpoint),
      },
      onLayoutChange ? onLayoutChange(actualSavedLayout.layout) : undefined
    );
  };

  updateCurrentLayout = (newLayout) => {
    this.setState({ currentLayout: newLayout });
  };

  /** Updates current layouts, setting the default layout as currentLayout state */
  updateCurrentLayouts = (key) => {
    const { layouts } = this.state;
    this.setState({
      layouts: this.setSelectedLayout(layouts, key),
    });
  };

  readItemsFromLocal = (items) => {
    const { containerId, userType } = this.props;

    const localItems = LocalStorageUtil.getItem(
      `${userType}-${containerId}-items`
    );
    if (
      userType &&
      localItems &&
      localItems.length > 0 &&
      Array.isArray(items)
    ) {
      return items.map((item) => {
        if (item.permission) {
          const localItem =
            localItems.find((item2) => item.key === item2.key) || item;
          if (localItem && !localItem.permission) {
            localItem.permission = true;
          }
          return localItem;
        }
        return item;
      });
    }
    return undefined;
  };

  /** Need to update the items and update the layout so that they display nicely. */
  updateCurrentItems = (items, key) => {
    const { defaultLayout } = this.state;
    const newItems = this.getItemsForLayout(items, key);
    const newLayout = ObjectUtils.deepClone(defaultLayout);
    newLayout.layout = this.getVisibleLayouts(newItems, defaultLayout.layout);
    return this.setState({
      currentItems: newItems.sort(ToggleItemsResponsiveLayout.nameComparator),
      currentLayout: newLayout,
    });
  };

  /**
   * Sets newItems and newLayouts to state. Saves newItems to localStorage.
   *
   * @param {Item[]} newItems
   * @param {LayoutsObj} newLayouts
   */
  updateItemPreference = (newItems, newLayouts) => {
    // Make sure that objects are unique
    const newClonedItems = ObjectUtils.deepClone(newItems);

    this.updateLayoutPreference(null, newLayouts);

    this.setState({
      currentItems: newClonedItems,
    });
  };

  addMenuToItems = () => {
    const { children } = this.props;
    if (!Array.isArray(children)) {
      return [this.addMenuToItem(children)];
    }
    const itemsPlusMenu = children.map((child) => this.addMenuToItem(child));
    return itemsPlusMenu;
  };

  addMenuToItem = (child) => {
    if (!child) return undefined;
    const customMenu = child.props.menu || [];
    const menu = [
      <MenuItem key={child.key} id={child.key} onClick={this.handleItemClick}>
        Remove Panel
      </MenuItem>,
    ];

    return cloneElement(child, { menu: customMenu.concat(menu) });
  };

  enableScroll = () => {
    this.setState({ disableScroll: false });
  };

  /**
   * Toggles item visiblity on item click
   *
   * @param {Item} item - Item clicked
   */
  handleItemClick = (item) => {
    const { currentItems, currentLayout, defaultLayout } = this.state;
    const newItems = ObjectUtils.deepClone(currentItems);

    let isHidden = false;
    let componentIdentifier;
    if (item.key) {
      componentIdentifier = item.key;
    } else {
      componentIdentifier = item.target.id;
    }
    // find item and update hidden status
    for (let i = 0; i < newItems.length; i += 1) {
      const newItem = newItems[i];
      if (newItem.key === componentIdentifier) {
        isHidden = !newItem.hidden;
        newItems[i].hidden = isHidden;
        break;
      }
    }

    const newLayout = ObjectUtils.deepClone(currentLayout);

    // update layouts to hide/show the item for each breakpoint
    Object.keys(newLayout.layout).forEach((key) => {
      const breakpointLayout = newLayout.layout[key];

      if (isHidden) {
        // hide layout for item at breakpoint if item is hidden
        newLayout.layout[key] = breakpointLayout.filter(
          (layout) => layout.i !== item.key
        );
      } else {
        // add back item at breakpoint if not hidden
        // Have to find layout for breakpoint in initial layouts
        const initialBreakpointLayout = ObjectUtils.deepClone(
          defaultLayout.layout[key]
        );

        const newItemLayout = initialBreakpointLayout.filter(
          (initialLayout) => initialLayout.i === item.key
        );

        if (newItemLayout.length === 0) {
          newItemLayout.push({ i: item.key, h: 10, w: 6, minH: 10, minW: 2 });
        }

        newItemLayout[0].x = 0;
        newItemLayout[0].y = 99999;
        // Update to use item's initial layout for current breakpoint
        newLayout.layout[key] = breakpointLayout
          .filter((layout) => layout.i !== item.key)
          .concat(newItemLayout);
      }
    });
    this.updateLocalStorage(newLayout, newItems, true);
    this.updateItemPreference(newItems, newLayout);
    this.enableScroll();
  };

  /**
   * Handles when layout is clicked from the ToggleItemsDrawer
   *
   * @param {LayoutsObj} clickedLayout
   */
  handleLayoutClick = (clickedLayout) => {
    const { items, onLayoutClick } = this.props;
    const { layouts } = this.state;
    const { key: clickedKey } = clickedLayout;

    const newLayouts = ObjectUtils.deepClone(layouts);
    // update layouts so that clicked layout is selected
    for (let i = 0; i < newLayouts.length; i += 1) {
      const { key } = newLayouts[i];
      newLayouts[i].selected = clickedKey === key;
    }

    const updatedItems = Array.isArray(items) ? items : items[clickedKey];
    updatedItems.forEach((item, index) => {
      updatedItems[index].hidden = !clickedLayout.layout.lg.some(
        (e) => e.i === item.key
      );
    });

    // get the clicked layout and update it to the default layout
    const newLayout = clickedLayout;
    if (onLayoutClick) {
      onLayoutClick(newLayout);
    }
    newLayout.layout = this.getVisibleLayouts(updatedItems, newLayout.layout);

    this.updateDefaultLayout(newLayout);

    // update current items for given layout
    // this also sets currentLayout state
    this.updateItemPreference(updatedItems, newLayout);
    this.updateLocalStorage(newLayout, updatedItems, false);
    const disableScroll = newLayout.isPublic;
    this.setState(
      { layouts: newLayouts, disableScroll },
      this.handleWindowResize
    );
  };

  handleBreakpointChange = (newBreakpoint) => {
    this.setState(
      { currentBreakpoint: newBreakpoint },
      this.handleWindowResize
    );
  };

  /**
   * If disableScroll = true, updates the grid item heights if the browser size
   * changes.
   */
  handleWindowResize = () => {
    const { currentBreakpoint, currentLayout, disableScroll } = this.state;

    if (!disableScroll || !currentLayout.isPublic) return;
    const newLayout = this.resizeLayout(currentLayout, currentBreakpoint);
    this.setState({
      currentLayout: newLayout,
    });
  };

  /**
   * Resizes layout so that all grid items fit in the browser window. Algorithm
   * isn't perfect, needs fine-tuning.
   *
   * @param {LayoutsObj} layout
   * @param {string} currentBreakpoint One of 'lg', 'md, 'sm', 'xs', xxs'
   */
  resizeLayout = (layout, currentBreakpoint) => {
    const { containerId, rowHeight, margin, cols } = this.props;
    const { localLayout } = this.state;
    const newLayout = ObjectUtils.deepClone(layout);

    const containerElement =
      containerId && document.querySelector(`.${containerId}-layout`);
    if (
      !containerElement ||
      (localLayout && localLayout.key === 'custom-layout')
    ) {
      return newLayout;
    }

    // Calculate the max usable height for react grid layout without scroll bars
    const usableHeight =
      window.innerHeight - containerElement.getBoundingClientRect().top;

    // Calculate the height of each column of react grid layout
    const heights = new Array(cols[currentBreakpoint]).fill(0);
    newLayout.layout[currentBreakpoint].forEach((widget) => {
      const { x, w, h } = widget;
      for (let i = x; i < x + w; i += 1) {
        heights[i] += h;
      }
    });
    // Find the max height to fit inside the window
    const maxH = Math.max(...heights);
    const numRows = Math.floor(
      (usableHeight - margin[1]) / (rowHeight + margin[1])
    );

    // Scale the h of each widget to fit inside the window
    const numGridItems = newLayout.layout[currentBreakpoint].length;
    for (let i = 0; i < numGridItems; i += 1) {
      const { y, h, minH } = newLayout.layout[currentBreakpoint][i];
      // Calcuate the new h as a ratio of useable space to the percentage of the height the widget should occupy
      let newH = Math.round((h / maxH) * numRows);
      const newY = Math.round((y / maxH) * numRows);

      // Enforce the layout is larger than the minH and smaller than the screen height
      if (newH < minH) newH = minH;
      else if (newH > numRows) newH = numRows;
      newLayout.layout[currentBreakpoint][i].h = newH;
      newLayout.layout[currentBreakpoint][i].y = newY;
    }
    return newLayout;
  };

  /**
   * Iterates through children and renders visible ones based on items array
   * prop.
   *
   * @returns Array of visible children
   */
  renderChildren = () => {
    const children = this.addMenuToItems();
    return children.filter(
      (child) => child && !this.shouldHideChild(child.key)
    );
  };

  render() {
    const {
      children,
      className,
      title,
      appBarContent,
      bottomDrawerContent,
      items,
      isStatic,
      requiresPermission,
      onLayoutChange,
      containerId,
      titleContent,
      ...otherProps
    } = this.props;
    const { currentItems, currentLayout, layouts, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <>
        <ToggleItemsMenu
          items={currentItems}
          layouts={layouts}
          onItemClick={this.handleItemClick}
          onLayoutClick={this.handleLayoutClick}
          appBarTitle={title}
          titleContent={titleContent}
          appBarContent={appBarContent}
          bottomDrawerContent={bottomDrawerContent}
          requiresPermission={requiresPermission}
        />
        <ResponsiveGridLayout
          className={`${className} ${containerId}-layout`}
          layouts={currentLayout ? currentLayout.layout : []}
          onDrag={this.enableScroll}
          onResize={this.enableScroll}
          onLayoutChange={this.updateLayoutPreference}
          onBreakpointChange={this.handleBreakpointChange}
          onDragStop={this.handleUserLayoutChange}
          onResizeStop={this.handleUserLayoutChange}
          isStatic={isStatic}
          ref={this.onRefChange}
          {...otherProps}
        >
          {this.renderChildren()}
        </ResponsiveGridLayout>
      </>
    );
  }
}

export default withSnackbars(ToggleItemsResponsiveLayout);
