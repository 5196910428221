import { FormAutocompleteProps } from 'library/CompositeComponents/form/FormAutocomplete';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import useGet from 'util/hooks/useDmasAPI/useGet';
import { useSnackbars } from 'util/hooks/useSnackbars';

type ProtocolSelectProps = Omit<
  FormAutocompleteProps,
  'options' | 'translationKey' | 'translationOptions'
> & { label: string };

type ProtocolPayload = Array<{
  protocolId: number;
  protocolName: string;
}>;

const ProtocolSelect = ({ ...props }: ProtocolSelectProps) => {
  const { onError } = useSnackbars();

  const protocolData = useGet<ProtocolPayload>('ProtocolService', {
    operation: 5,
    options: {
      retry: 1,
    },
  });

  if (protocolData.isLoading) {
    return (
      <FormDropdown
        {...props}
        options={[{ label: 'None', value: undefined, key: undefined }]}
      />
    );
  }

  if (protocolData.error) {
    onError(protocolData.error.message);
    return <></>;
  }

  const optionsMapped = protocolData?.data?.map((option) => ({
    label: option.protocolName,
    value: option.protocolId,
    key: option.protocolId.toString(),
  }));
  const options = [{ label: 'None', value: undefined, key: undefined }].concat(
    optionsMapped
  );

  return <FormDropdown options={options} {...props} />;
};

const ProtocolSelectWrapper = ({ ...props }: ProtocolSelectProps) => (
  <ProtocolSelect {...props} />
);

export default ProtocolSelectWrapper;
