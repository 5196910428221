import { useEffect, useState } from 'react';
import * as React from 'react';

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from 'base-components';
import PlaylistService from 'domain/services/PlaylistService';
import {
  SaveButton,
  CancelButton,
} from 'library/CompositeComponents/button/Buttons';
import { useSnackbars } from 'util/hooks/useSnackbars';

interface EditPlaylistDialogProps {
  description: string;
  groupName: string;
  open: boolean;
  playlistHdrId: number;
  playlistName: string;

  groupOptions?: string[];

  actionAfterEdit: () => void;
  closeEditDialog: () => void;
}

const EditPlaylistDialog: React.FC<EditPlaylistDialogProps> = ({
  description,
  groupName,
  open,
  playlistHdrId,
  playlistName,
  groupOptions = [],
  actionAfterEdit,
  closeEditDialog,
}) => {
  const { onError, onInfo } = useSnackbars();
  const [editedName, setEditedName] = useState(playlistName);
  const [editedGroupName, setEditedGroupName] = useState(groupName);
  const [editedDescription, setEditedDescription] = useState(description);

  useEffect(() => {
    setEditedName(playlistName);
    setEditedDescription(description);
    setEditedGroupName(groupName);
  }, [playlistName, description, groupName]);

  /**
   * Updates a PlaylistHdr by making an update call to PlaylistService
   *
   * @returns {Promise}
   */
  const editPlaylist = () =>
    PlaylistService.updatePlaylistHdr({
      playlistHdrId,
      playlistHdrName: editedName,
      playlistHdrDescription: editedDescription,
      groupName: editedGroupName,
    })
      .then(() => {
        closeEditDialog();
        actionAfterEdit();
        onInfo(`${editedName} has been updated`);
      })
      .catch((error) => {
        onError(error.message);
      });

  return (
    <Dialog
      open={open}
      onClose={closeEditDialog}
      id="edit-dialog"
      className="dialog"
      fullWidth
    >
      <DialogTitle>Edit Playlist</DialogTitle>
      <DialogContent>
        <TextField
          translationKey="seatube.playlistName"
          title="playlistHdrName"
          name="playlistHdrName"
          fullWidth
          value={editedName}
          onChange={(e) => setEditedName(e.target.value)}
          error={editedName?.length === 0}
          required
          helperText={
            editedName?.length === 0 ? 'Playlist Name is required' : ''
          }
        />
        <Autocomplete
          translationKey="seatube.collectionName"
          name="collection-name-autocomplete"
          value={editedGroupName}
          options={groupOptions}
          onChange={(e, v: string) => setEditedGroupName(v)}
          onInputChange={(e, v: string) => setEditedGroupName(v)}
          getOptionLabel={(option) => option}
          freeSolo
        />
        <TextField
          translationKey="seatube.playlistDescription"
          fullWidth
          multiline
          title="playlistHdrDescription"
          name="playlistHdrDescription"
          rows={3}
          value={editedDescription}
          onChange={(e) => setEditedDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={closeEditDialog} />
        <SaveButton
          onClick={editPlaylist}
          disabled={editedName?.length === 0}
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditPlaylistDialog;
