/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import useBroadcast from 'util/hooks/useBroadcast';
import { useLocalStorage } from 'util/hooks/useStorage';

/**
 * This is a custom hook that allows a primary window to spawn secondary windows
 *
 * - Closing the primary window will close all secondary windows
 * - The number of windows for a particular group are tracked through local
 *   storage
 * - When closing, a window broadcasts it's index so others can be updated
 * - When the primary window is unloaded, it clears the variable from local
 *   storage
 *
 * It's highly recommended that the id for this hook be something completely
 * unique to the tab/window the user is in, such as a uuid. Otherwise,
 * unexpected behaviour could occur
 */

export type WindowController = {
  windowIndex: number;
  numWindows: number;
  openNewWindow: () => void;
  loading: boolean;
  parentWindowId: string;
};

export default function useWindowController(
  windowControllerId: string
): WindowController {
  const [loading, setLoading] = useState(true);
  const [closedWindowId, setClosedWindowId] = useBroadcast(
    windowControllerId,
    BroadcastChannel.WindowController,
    undefined,
    'useWindowController'
  );
  const [windowIds, updateWindowIds, clearWindowIds] = useLocalStorage<
    string[]
  >(`${windowControllerId}_connections`, []);
  const [windowId, setWindowId] = useState<string | null>(null);

  const handleUnload = (uuid: string, windows: string[]) => {
    // If the primary window is closed, close all windows by setting the closedWindowId to window controller id
    setClosedWindowId(uuid);
    if (uuid === windowIds[0]) {
      clearWindowIds();
    } else {
      const updatedWindows = windows.filter((itemId) => itemId !== uuid);

      if (updatedWindows.length === 0) {
        clearWindowIds();
      } else {
        updateWindowIds(updatedWindows);
      }
    }
  };

  const openNewWindow = () => {
    // Might be nice if this was more configurable, though I'm not sure how to pass along these
    // parameters in something other than the url
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('primaryWindowId', windowControllerId);
    window.open(newUrl, '', 'width=1280,height=720');
  };

  useEffect(() => {
    // Note crypto.randomUUID is not supported on dive loggers (DMAS-75716)
    const newWindowId = crypto.getRandomValues(new Uint32Array(1)).toString();
    setWindowId(newWindowId);
    updateWindowIds([...windowIds, newWindowId]);
    setLoading(false);

    return () => {
      handleUnload(newWindowId, windowIds);
    };
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => handleUnload(windowId, windowIds);
    return () => {
      window.onbeforeunload = null;
    };
  }, [windowIds, windowId]);

  useEffect(() => {
    if (!closedWindowId || windowIds.length === 0) return;
    if (closedWindowId === windowIds[0] && windowId !== windowIds[0]) {
      window.close();
    } else {
      const updatedWindowIds = windowIds.filter(
        (itemId) => itemId !== closedWindowId
      );
      if (updatedWindowIds.length > 0) {
        updateWindowIds(updatedWindowIds);
      }
    }
  }, [closedWindowId]);

  const windowIndex = windowIds.indexOf(windowId) + 1;

  return {
    windowIndex,
    numWindows: windowIds.length,
    openNewWindow,
    loading,
    parentWindowId: windowControllerId,
  };
}
