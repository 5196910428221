import { useEffect, useState } from 'react';
import * as React from 'react';
import { LibraryBooks } from '@onc/icons';
import {
  Autocomplete,
  Collapse,
  Grid,
  LabelledCheckbox,
} from 'base-components';
import { ResourceTypeSelect } from 'domain/AppComponents/dropdowns/Dropdowns';
import ResourceService from 'domain/services/ResourceService';
import Filter, {
  FilterEvent,
  SummaryValues,
  FilterValue,
  FilterImplementationProps,
} from 'library/CompositeComponents/filter/Filter';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import AnnotationResourceTypes from '../AnnotationResourceTypes';

export interface ResourceFilterValue extends FilterValue {
  resourceTypeId?: string;
  resourceId?: number;
}

interface Props extends FilterImplementationProps {
  onChange?: (event: FilterEvent) => void;
  onError: (event: any) => void;
  name: string;
  options?: any;
  value?: ResourceFilterValue;
}

const defaultOptions = Object.values(AnnotationResourceTypes);

const ResourceFilter: React.FC<Props> = ({
  onChange = undefined,
  onError,
  name,
  value = undefined,
  options = defaultOptions,
  onExpand,
  expanded,
}: Props) => {
  const [resourceOptions, setResourceOptions] = useState([]);

  const compareValues = (newValue: any) => {
    const newValueCopy = { ...newValue };
    // compare resourceTypeId to previousValue
    if (
      value &&
      newValueCopy.target.value.resourceTypeId !== value.resourceTypeId
    ) {
      newValueCopy.target.value.resource = null;
    }
    if (onChange) {
      onChange(newValueCopy);
    }
    return newValueCopy;
  };

  useEffect(() => {
    if (value && value.resourceTypeId) {
      ResourceService.getResourcesByResourceTypeId(value.resourceTypeId)
        .then((response: any) => {
          setResourceOptions(response);
        })
        .catch((error: any) => {
          onError(error.message);
        });
    }
  }, [value, onError]);
  const getSelectedOption = (optionsValue) =>
    options.find((element) => element.value === optionsValue);

  const renderSummaryValues = () => {
    if (value) {
      const summary: SummaryValues[] = [];
      const selectedOption = getSelectedOption(value.resourceTypeId);
      if (value.resourceTypeId && selectedOption) {
        summary.push({
          label: `${selectedOption.label}`,
        });
      }
      if (value.resource) {
        summary.push({ label: `${value.resource.name}` });
      }
      return summary;
    }
    return undefined;
  };

  return (
    <Filter
      title="Resource"
      value={value}
      name={name}
      onChange={compareValues}
      summaryValues={renderSummaryValues()}
      icon={<LibraryBooks data-test="filterIcon" color="action" />}
      onExpand={onExpand}
      expanded={expanded}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ResourceTypeSelect
            name="resourceTypeId"
            title="resourceTypeId"
            fullWidth
            options={options}
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse
            in={value !== undefined && value.resourceTypeId !== undefined}
          >
            <Autocomplete
              data-test="resource-autocomplete"
              getOptionLabel={(option) => (option ? option.name : undefined)}
              isOptionEqualToValue={(option, val) => option.id === val.id}
              translationKey="common.autoCompletes.resource"
              virtualized
              value={undefined}
              name="resource"
              fullWidth
              options={resourceOptions}
              onChange={onChange}
            />
          </Collapse>
          <Grid item xs={12}>
            <LabelledCheckbox
              name="includeTopology"
              label="Include annotations up topology tree"
            />
          </Grid>
        </Grid>
      </Grid>
    </Filter>
  );
};

export default withSnackbars(ResourceFilter);
