import { useEffect, useState } from 'react';
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Moment } from 'moment';

import { WarningAlert } from '@onc/composite-components';
import {
  Collapse,
  DateTimePicker,
  Dropdown,
  TextField,
  type DropdownOption,
} from 'base-components';

export type ClipForm = {
  name: string;
  description: string;
  quality: string;
  dateFrom: Moment;
  dateTo: Moment;
};

type ClipFormValue<T extends keyof ClipForm> = ClipForm[T];

export type ClipFormErrors = {
  [key in keyof ClipForm]?: string;
};

type Props = {
  onSubmit: (form: ClipForm) => void;
  onChange: <T extends keyof ClipForm>(key: T, value: ClipFormValue<T>) => void;
  onError: (key: keyof ClipForm, error: string) => void;
  value: ClipForm;
  errors: ClipFormErrors;
  qualityOptions: DropdownOption[];
};

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    gap: theme.spacing(),
    width: '100%',
  },
}));

const PlaylistClipForm: React.VFC<Props> = ({
  value,
  errors,
  qualityOptions,
  onChange,
  onSubmit,
  onError,
}: Props) => {
  const [showWarning, setShowWarning] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!qualityOptions) {
      return;
    }
    const found = qualityOptions.find(
      (q: DropdownOption) => q.value === value.quality
    );
    if (!found) {
      if (value.quality) {
        onChange('quality', null);
      }
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualityOptions, value.quality]);

  return (
    <form onSubmit={() => onSubmit(value)}>
      <TextField
        id="form-field-clip-name"
        translationKey="common.textfields.name"
        fullWidth
        value={value.name || ''}
        onChange={(e) => onChange('name', e.target.value)}
        error={!!errors.name}
        helperText={errors.name}
      />

      <TextField
        id="form-field-clip-description"
        translationKey="common.textfields.description"
        multiline
        rows={3}
        fullWidth
        value={value.description || ''}
        onChange={(e) => onChange('description', e.target.value)}
        error={!!errors.description}
        helperText={errors.description}
      />

      <div className={classes.flexContainer}>
        <DateTimePicker
          translationKey="common.datepickers.dateFrom"
          onChange={(val) => onChange('dateFrom', val)}
          onError={(error) => onError('dateFrom', error)}
          maxDateTime={value?.dateTo}
          helperText={errors.dateFrom}
          value={value.dateFrom || null}
          fullWidth
        />

        <DateTimePicker
          translationKey="common.datepickers.dateTo"
          onChange={(val) => onChange('dateTo', val)}
          onError={(error) => onError('dateTo', error)}
          helperText={errors.dateTo}
          minDateTime={value?.dateFrom}
          value={value.dateTo || null}
          fullWidth
        />
      </div>

      <Dropdown
        id="form-field-clip-quality"
        label="Default Quality"
        fullWidth
        value={value.quality || null}
        onChange={(e) => onChange('quality', e.target.value)}
        error={!!errors.quality}
        helperText={errors.quality}
        options={qualityOptions}
      />

      <Collapse in={showWarning}>
        <WarningAlert title="Warning" fullWidth>
          The currently saved quality is not available for this clip. Please
          select a default quality.
        </WarningAlert>
      </Collapse>
    </form>
  );
};

export default PlaylistClipForm;
