import { useContext } from 'react';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Autocomplete, LabelledCheckbox } from 'base-components';
import TaxonAsyncAutocomplete, {
  TaxonOption,
} from 'domain/AppComponents/dropdowns/TaxonAsyncAutocomplete';
import { TaxonomyContext } from './TaxonomyApp';
import { TaxonomyItem } from '../../Widgets/TaxonomyTreeWidget';

export type ImportForm = {
  taxonomyId: number;
  includeChildren: boolean;
  taxonChoice: TaxonOption;
};

type ImportFormValue<T extends keyof ImportForm> = ImportForm[T];

type Props = {
  importValue: ImportForm;
  isEnabled: boolean;
  onChange: <T extends keyof ImportForm>(
    key: T,
    value: ImportFormValue<T>
  ) => void;
  taxonomies: TaxonomyItem[];
};

const TaxonImportForm = ({
  importValue,
  isEnabled,
  onChange,
  taxonomies,
}: Props) => {
  const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const { taxonomy } = useContext(TaxonomyContext);

  const taxonomyOptions = taxonomies
    ? taxonomies
        .map((item) => ({
          label: item.taxonomyName,
          value: item.taxonomyId,
          key: item.taxonomyCode,
        }))
        .filter((txy) => txy.value !== taxonomy.taxonomyId)
    : [];

  const IMPORTED_TAXONOMIES = [1, 3]; // WoRMS and CMECS

  const userDefined = !IMPORTED_TAXONOMIES.includes(importValue.taxonomyId);

  return (
    <Grid container className={classes.contentContainer} id="taxon-import-form">
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Autocomplete
          name="taxonomy-select"
          translationKey="taxonomy.originTaxonomy"
          onChange={(e, v) => {
            onChange('taxonomyId', v ? Number(v.value) : null);
            onChange('taxonChoice', null);
          }}
          value={
            taxonomyOptions.find(
              (option) => option.value === importValue.taxonomyId
            ) || null
          }
          isOptionEqualToValue={(option, val) => option.value === val.value}
          getOptionLabel={(option) => option.label}
          options={taxonomyOptions}
          disabled={!isEnabled}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <TaxonAsyncAutocomplete
          taxonomyId={isEnabled ? importValue.taxonomyId : null}
          onChange={(e, taxon) => {
            onChange('taxonChoice', taxon);
          }}
          taxonId={importValue.taxonChoice?.taxonId || null}
          searchLettersRequired={userDefined ? 1 : 3}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <LabelledCheckbox
          name="includeChildren"
          label="Include child taxons (this may take some time)"
          value={importValue.includeChildren}
          onChange={() =>
            onChange('includeChildren', !importValue.includeChildren)
          }
          disabled={!isEnabled}
        />
      </Grid>
    </Grid>
  );
};

export default TaxonImportForm;
