import PropTypes from 'prop-types';
import DataProductDownloadProgress from 'domain/AppComponents/geospatial-search/community-fisher/DataProductDownloadProgress';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import Environment from 'util/Environment';
import { NOT_DOWNLOADED } from '../definitions/GeospatialSearchConstants';

const DataProductCorProgress = ({
  classes = undefined,
  status = undefined,
  errorStatus,
  restrictedStatus,
  doneStatus,
  restrictedMessage = undefined,
  dpRunId = undefined,
  dataProductType,
}) => {
  DataProductCorProgress.propTypes = {
    classes: PropTypes.shape({
      linkButtonPrimaryOverride: PropTypes.string,
      thinButton: PropTypes.string,
    }),
    errorStatus: PropTypes.string.isRequired,
    restrictedStatus: PropTypes.string.isRequired,
    doneStatus: PropTypes.string.isRequired,
    dataProductType: PropTypes.string.isRequired,
    status: PropTypes.string,
    restrictedMessage: PropTypes.string,
    dpRunId: PropTypes.number,
  };

  if (!status || status === NOT_DOWNLOADED) return null;

  if (status !== doneStatus) {
    return (
      <DataProductDownloadProgress
        status={status}
        dataProductType={dataProductType}
        errorStatus={errorStatus}
        restrictedStatus={restrictedStatus}
        restrictedMessage={restrictedMessage}
      />
    );
  }

  return (
    <TextButton
      translationKey="communityFishers.downloadDateProductType"
      translationOptions={{ dataProductType }}
      className={`${classes.linkButtonPrimaryOverride} ${classes.thinButton}`}
      href={`${Environment.getDmasUrl()}/SearchResultService?id=${dpRunId}`}
      download=""
      rel="noopener noreferrer"
      size="small"
    />
  );
};

export default DataProductCorProgress;
