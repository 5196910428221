/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-underscore-dangle */
/* Ignoring underscores because we are overriding Leaflet things that use them. */
import { FC, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Control, DomUtil, DomEvent, ControlPosition } from 'leaflet';
import { createRoot } from 'react-dom/client';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import { useMap } from 'react-leaflet';
import MapFAB from './MapFAB';
import MapContext from '../MapContext';

const useStyles = makeStyles((theme: any) => ({
  mapButtonStyles: {
    margin: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  mainContainerStyles: {
    cursor: 'inherit',
  },
}));

interface DrawControlProps {
  /** Position to put the control on the map */
  position?: ControlPosition;
  /** Callback to use when drawing on the layer */
  handleLayerChange?: (layer) => void;
  /** Callback to use when the user stops or starts drawing */
  updateDrawingState?: (drawingState: boolean) => void;
}
/**
 * Control used to draw polygons on a map
 *
 * @returns
 */
const DrawControl: FC<DrawControlProps> = ({
  position = 'topright',
  handleLayerChange = undefined,
  updateDrawingState = undefined,
}) => {
  const { mapId } = useContext(MapContext);
  const map = useMap();
  const classes = useStyles();

  useEffect(() => {
    const drawMode = {
      position,
      drawMarker: false,
      drawPolygon: true,
      drawCircle: false,
      drawRectangle: true,
      drawPolyline: false,
      drawCircleMarker: false,
      editPolygon: false,
      cutPolygon: false,
      deleteLayer: false,
      dragMode: false,
    };

    const editMode = {
      position,
      drawMarker: false,
      drawPolygon: false,
      drawCircle: false,
      drawRectangle: false,
      drawPolyline: false,
      editPolygon: true,
      cutPolygon: false,
      deleteLayer: true,
      dragMode: true,
    };

    /*
     * Note: This code is overridding code from the geoman plugin and it copies most of it.
     * If the geoman plugin is updated, this may need to be changed
     * This override is to override the buttons created by the plugin to fit our standards better
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    Control.PMButton.include({
      buttonOnClick(e) {
        if (this._button.disableOtherButtons && this._map) {
          this._map.pm.Toolbar.triggerClickOnToggledButtons(this);
        }
        this._triggerClick();
        e.stopPropagation();
      },

      _makeButton(button) {
        const mapIconStyles = {
          height: '24px',
          width: '24px',
        };
        // Button container
        const buttonContainer = DomUtil.create(
          'div',
          'button-container',
          this._container
        );
        // Render the button within the container
        createRoot(buttonContainer).render(
          <MapFAB
            mapId={mapId}
            map={this._map}
            idTag={button.title}
            size="medium"
            TooltipProps={{ title: button.title }}
            onClick={(e) => this.buttonOnClick(e)}
            className={classes.mapButtonStyles}
          >
            <div className={button.className} style={mapIconStyles} />
          </MapFAB>
        );

        // the button's actions
        const actionContainer = DomUtil.create(
          'div',
          'leaflet-pm-actions-container',
          buttonContainer
        );

        // This is the method to customize the css of elements created by DomUtil
        DomUtil.addClass(this._container, classes.mainContainerStyles);
        this._container.style.border = '0px';
        this._container.style.boxShadow = 'none';
        actionContainer.style.top = '9px';
        actionContainer.style.left = '48px';

        const activeActions = button.actions;

        const actions = {
          cancel: {
            text: 'Cancel',
            onClick() {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */
              this._triggerClick();
            },
          },
          removeLastVertex: {
            text: 'Remove Last Vertex',
            onClick() {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */
              this._map.pm.Draw[button.jsClass]._removeLastVertex();
            },
          },
          finish: {
            text: 'Finish',
            onClick(e) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */
              this._map.pm.Draw[button.jsClass]._finishShape(e);
            },
          },
        };

        activeActions.forEach((name) => {
          const action = actions[name];
          if (action === undefined) {
            return;
          }
          const actionNode = DomUtil.create(
            'a',
            `leaflet-pm-action action-${name}`,
            actionContainer
          );
          actionNode.innerHTML = action.text;

          DomEvent.addListener(actionNode, 'click', action.onClick, this);
          DomEvent.disableClickPropagation(actionNode);
        });

        return buttonContainer;
      },
    });

    /*
     * Note: This code is creating a subclass of leaflet Control, giving it the functionality that we require
     * This extension adds custom event handlers, so that we can get data back from the map
     */
    const Draw = Control.extend({
      // Add the first set of controls to the map
      addInitControls(leafletMap) {
        leafletMap.pm.addControls(drawMode);

        // If a polygon exists, then we start with edit controls
        leafletMap.eachLayer((layer) => {
          if (layer.getLatLngs) {
            // Set handlers for a new shape
            this.handleCreate(layer, leafletMap);
          }
        });
      },

      // Handler for when a shape is completed
      handleCreate(layer, leafletMap) {
        layer.bindPopup('');
        // Add handlers that can only be added on specific layers
        layer.on('click', () => {
          this.layerOnClick(layer);
        });
        layer.on('pm:edit', () => {
          this.handleLayerEdit(leafletMap, layer);
        });
        leafletMap.pm.addControls(editMode);
        handleLayerChange(layer);
      },

      handleLayerEdit(leafletMap, layer) {
        if (layer.getLatLngs()[0].length === 0) {
          this.handleRemove(leafletMap);
        } else {
          handleLayerChange(layer);
        }
      },

      // Add popup
      layerOnClick(layer) {
        if (layer.getRadius) {
          layer
            .getPopup()
            .setContent(
              `${layer
                .getLatLng()
                .toString()
                .replace(/LatLng\(/g, '[')
                .replace(/\)/g, ']')} radius: ${layer.getRadius().toString()}`
            )
            .openPopup();
        } else {
          layer
            .getPopup()
            .setContent(
              layer
                .getLatLngs()
                .toString()
                .replace(/LatLng\(/g, '[')
                .replace(/\)/g, ']')
            )
            .openPopup();
        }
      },

      // Handler for erasing shape
      handleRemove(leafletMap) {
        leafletMap.pm.addControls(drawMode);
        handleLayerChange(undefined);
      },

      handleDrawStart() {
        updateDrawingState(true);
      },

      handleDrawEnd() {
        updateDrawingState(false);
      },

      onAdd(leafletMap) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        this._container = DomUtil.get(
          `${mapId}-leaflet-control-draw-map-buttons`
        );

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        const container = this._container;
        this.addInitControls(leafletMap);

        leafletMap.on('pm:create', (e) => {
          const { layer } = e;
          this.handleCreate(layer, leafletMap);
        });
        leafletMap.on('pm:remove', () => {
          this.handleRemove(leafletMap);
        });
        leafletMap.on('pm:drawstart', this.handleDrawStart);
        leafletMap.on('pm:drawend', this.handleDrawEnd);

        return container;
      },

      onRemove(leafletMap) {
        // Removing the handlers
        leafletMap.off('pm:create', (e) => {
          const { layer } = e;
          this.handleCreate(layer, leafletMap);
        });
        leafletMap.off('pm:remove', () => {
          this.handleRemove(leafletMap);
        });
        leafletMap.off('pm:drawstart', this.handleDrawStart);
        leafletMap.off('pm:drawend', this.handleDrawEnd);

        leafletMap.eachLayer((layer) => {
          // for each layer polygon layer, remove it's handlers
          if (layer.getLatLngs) {
            layer.off('click', () => {
              this.layerOnClick(layer);
            });
            layer.off('pm:edit', () => {
              this.handleLayerEdit(leafletMap, layer);
            });
          }
        });
      },
    });

    const control = new Draw();
    map.addControl(control);
    return () => {
      map.removeControl(control);
    };
  }, [
    classes.mainContainerStyles,
    classes.mapButtonStyles,
    handleLayerChange,
    map,
    mapId,
    position,
    updateDrawingState,
  ]);

  return <div id={`${mapId}-leaflet-control-draw-map-buttons`} />;
};

export default DrawControl;
