import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { Dropdown } from 'base-components';
import { useCreateTaskForBatchMutation } from 'domain/AppComponents/batch/hooks/BatchTaskMutationHooks';
import {
  useTaskDefinitionQuery,
  useBatchableTasksQuery,
} from 'domain/AppComponents/batch/hooks/BatchTaskQueryHooks';
import { FormDialog } from 'domain/AppComponents/dialogs/FormDialog';
import BatchTaskDetailTextFields from './BatchTaskDetailTextFields';

interface BatchTaskDialogProps {
  onClose: () => void;
  open?: boolean;
  selectedBatchId?: number;
}

const BatchTaskAddDialog = ({
  onClose,
  open = false,
  selectedBatchId = undefined,
}: BatchTaskDialogProps) => {
  const [taskDefinitionId, setTaskDefinitionId] = useState<number | undefined>(
    undefined
  );
  const [taskDetails, setTaskDetails] = useState([]);
  const { data, isLoading } = useTaskDefinitionQuery(taskDefinitionId);
  const { data: batchableTasks } = useBatchableTasksQuery();
  const createTaskForBatchMutation = useCreateTaskForBatchMutation();

  useEffect(() => {
    if (!isLoading && data) {
      setTaskDetails(data.taskDetail);
    }
  }, [isLoading, data]);

  return (
    <FormDialog
      open={open}
      title="Add Task to Batch"
      onClose={() => onClose()}
      confirmButtonTranslationKey="taskmanagement.addBatchTask"
      onSubmit={() => {
        if (selectedBatchId && taskDefinitionId && taskDetails) {
          createTaskForBatchMutation.mutate({
            selectedBatchId,
            taskDefinitionId,
            taskDetails,
          });
        }
        onClose();
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Dropdown
            inputProps={{ 'aria-label': 'Task Definition' }}
            label="Task Definition"
            options={batchableTasks}
            value={taskDefinitionId}
            onChange={(event) =>
              setTaskDefinitionId(parseInt(event.target.value, 10))
            }
            fullWidth
          />
        </Grid>
        <BatchTaskDetailTextFields
          taskDetails={taskDetails}
          onTextFieldChange={(newTaskDefinitionDetails) =>
            setTaskDetails(newTaskDefinitionDetails)
          }
        />
      </Grid>
    </FormDialog>
  );
};
export default BatchTaskAddDialog;
