import {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  ReactElement,
  FC,
} from 'react';
import {
  ANNOTATION_DATA_PRODUCT,
  COR_AND_CSV_DATA_PRODUCT,
  NOT_DOWNLOADED,
  PLOT_DATA_PRODUCT,
  TASK_COMPLETE,
  XML_DATA_PRODUCT,
} from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchConstants';

type CastDataProviderProps = {
  children: ReactElement;
};

type CastContextValue = {
  downloadedCasts: any[]; // Change 'any' to the actual type of downloadedCasts
  updateDate: string;
  onCastDownloaded: (castsThatFinishedDownloading: any) => void;
  onCastDeleted: (siteDeviceSubsetId: number) => void;
  addSubscriber: (code: string, subscriberFunction: () => void) => void;
  removeSubscriber: (code: string) => void;
  updateDownloadedCastsFromSingleCast: (cast: any, isAssigned: boolean) => void;
};

const CastDataContext = createContext<CastContextValue | undefined>(undefined);

const CastDataProvider: FC<CastDataProviderProps> = ({ children }) => {
  const [downloadedCasts, setDownloadedCasts] = useState([]);

  // for use where key prop is disagreeable
  const [subscribers, setSubscribers] = useState<Record<string, () => void>>(
    {}
  );

  // for use with key prop
  const [updateDate, setUpdateDate] = useState<string>();

  // for components that are disagreeable with the key prop
  const addSubscriber = useCallback((code, subscriberFunction) => {
    setSubscribers((prevSubscribers) => {
      const newSubscribers = { ...prevSubscribers };
      newSubscribers[code] = subscriberFunction;
      return newSubscribers;
    });
  }, []);

  // for components that are disagreeable with the key prop
  const removeSubscriber = useCallback((code) => {
    setSubscribers((prevSubscribers) => {
      const newSubscribers = { ...prevSubscribers };
      if (code in newSubscribers) {
        delete newSubscribers[code];
      }
      return newSubscribers;
    });
  }, []);

  const onCastDeleted = useCallback(
    (siteDeviceSubsetId) => {
      const filteredDownloadedCasts = downloadedCasts.filter(
        (subset) => !(subset.siteDeviceSubsetId === siteDeviceSubsetId)
      );
      setDownloadedCasts(filteredDownloadedCasts);
      setUpdateDate(new Date().toISOString());
    },
    [downloadedCasts]
  );

  const onCastDownloaded = useCallback(
    (castsThatFinishedDownloading) => {
      const indexOfCast = downloadedCasts.findIndex(
        (cast) =>
          cast.siteDeviceSubsetId ===
          castsThatFinishedDownloading.siteDeviceSubsetId
      );
      if (indexOfCast === -1) {
        // first product for cast, push into array
        downloadedCasts.push(castsThatFinishedDownloading);
      } else {
        // replace existing object with updated object
        downloadedCasts[indexOfCast] = castsThatFinishedDownloading;
      }
      setUpdateDate(new Date().toISOString());
    },
    [downloadedCasts]
  );

  const updateDownloadedCastsFromSingleCast = useCallback(
    (cast, isAssigned) => {
      const castForHistoryTable = {
        code: cast.code,
        endDate: cast.endDate,
        generationType: cast.generationType,
        isAssigned,
        productsForCast: [
          {
            dpRequestId: cast.cor?.dpRequestId,
            dpRunId: cast.cor?.dpRunId,
            isSelected: cast.cor?.status !== undefined,
            productName: COR_AND_CSV_DATA_PRODUCT,
            productType: 'COR Data File',
            status: cast.cor?.status ?? NOT_DOWNLOADED,
            extension: 'cor',
          },
          {
            dpRequestId: cast.png?.dpRequestId,
            dpRunId: cast.png?.dpRunId,
            isSelected: cast.png?.status !== undefined,
            productName: PLOT_DATA_PRODUCT,
            productType: 'Profile Plot',
            status: cast.png?.status ?? NOT_DOWNLOADED,
            extension: 'png',
          },
          {
            dpRequestId: undefined,
            dpRunId: undefined,
            isSelected: false,
            productName: ANNOTATION_DATA_PRODUCT,
            productType: 'Annotation File',
            status: NOT_DOWNLOADED,
            extension: 'csv',
          },
          {
            dpRequestId: cast.xml?.dpRequestId,
            dpRunId: cast.xml?.dpRunId,
            isSelected: cast.xml?.status !== undefined,
            productName: XML_DATA_PRODUCT,
            productType: 'Meta Data File',
            status: cast.xml?.status ?? NOT_DOWNLOADED,
            extension: 'xml',
          },
          {
            dpRequestId: cast.csv?.dpRequestId,
            dpRunId: cast.csv?.dpRunId,
            isSelected: cast.csv?.status !== undefined,
            productName: COR_AND_CSV_DATA_PRODUCT,
            productType: 'CSV Data File',
            status: cast.csv?.status ?? NOT_DOWNLOADED,
            extension: 'csv',
          },
        ],
        referenceDepth: cast.referenceDepth,
        referenceLat: cast.referenceLat,
        referenceLon: cast.referenceLon,
        siteDeviceId: cast.siteDeviceId,
        siteDeviceSubsetId: cast.siteDeviceSubsetId,
        siteDeviceSubsetName: cast.siteDeviceSubsetName,
        siteDeviceSubsetType: cast.siteDeviceSubsetType,
        status: TASK_COMPLETE,
        startDate: cast.startDate,
      };
      // to ensure tests pass. TODO: remove context confirmation when tests converted to cypress
      onCastDownloaded(castForHistoryTable);
    },
    [onCastDownloaded]
  );
  // after any update to downloadedCasts or new subscriber, ensure subscribers are up to date
  useEffect(() => {
    Object.values(subscribers).forEach((fUpdate) => fUpdate());
  }, [updateDate, downloadedCasts, subscribers]);

  // useMemo to prevent callback loop
  const contextValue = useMemo(
    () => ({
      downloadedCasts,
      updateDate,
      onCastDownloaded,
      onCastDeleted,
      addSubscriber,
      removeSubscriber,
      updateDownloadedCastsFromSingleCast,
    }),
    [
      downloadedCasts,

      updateDate,
      onCastDownloaded,
      onCastDeleted,
      addSubscriber,
      removeSubscriber,
      updateDownloadedCastsFromSingleCast,
    ]
  );

  return (
    <CastDataContext.Provider value={contextValue}>
      {children}
    </CastDataContext.Provider>
  );
};

export { CastDataProvider };
export default CastDataContext;
