import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import { DeviceCategory } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import QaqcTestDetailsService from 'domain/services/QaqcTestDetailsService';

class QaqcDetailsDeviceCategoryDropdown extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string),
    disabled: PropTypes.bool,
    initialValue: PropTypes.number,
    permission: PropTypes.string.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: {},
    disabled: false,
    initialValue: 0,
    value: 0,
  };

  state = {
    deviceCategories: [{ label: 'Select', value: 0 }],
  };

  componentDidMount() {
    this.getDeviceCategories();
  }

  getDeviceCategory = () => {
    const { value } = this.props;
    const { deviceCategories } = this.state;

    const currDeviceCategory = deviceCategories.find(
      (deviceCategory) => deviceCategory.value === value
    );
    if (!currDeviceCategory || value === 0) return '';
    return currDeviceCategory.label;
  };

  getDeviceCategories = () => {
    QaqcTestDetailsService.getQAQCTDDeviceCategories()
      .then((results) => {
        const deviceCategories = results.map((deviceCategory) => ({
          label: deviceCategory.deviceCategoryName,
          value: deviceCategory.deviceCategoryId,
        }));
        this.setState({
          deviceCategories: [
            { label: 'Select', value: 0 },
            ...deviceCategories,
          ],
        });
      })
      .catch((response) => {
        const { onError } = this.props;
        onError(response.message);
      });
  };

  render() {
    const {
      classes,
      disabled,
      initialValue,
      permission,
      value,
      onChange,
      ...rest
    } = this.props;
    const { deviceCategories } = this.state;

    if (permission === 'RW') {
      return (
        <Dropdown
          disabled={disabled}
          initialValue={initialValue}
          label="Device Category"
          options={deviceCategories}
          value={value}
          onChange={onChange}
          {...rest}
        />
      );
    }

    return (
      <DeviceCategory
        classes={{ root: classes.roFieldRoot }}
        deviceCategory={this.getDeviceCategory()}
      />
    );
  }
}
export default QaqcDetailsDeviceCategoryDropdown;
