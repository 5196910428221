import PropTypes from 'prop-types';
import { TextField, Typography } from 'base-components';

import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';

const IssueDescriptionField = (props) => {
  const { jiraDomain, description, onChange } = props;

  const handleChange = (event) => onChange(event.target.value);

  return (
    <>
      <TextField
        translationKey="support.supportDescription"
        id="issue-description-field"
        fullWidth
        multiline
        minRows="8"
        maxRows="16"
        value={description}
        onChange={handleChange}
      />
      <OpenInNewLink
        href={`${jiraDomain}/secure/WikiRendererHelpAction.jspa?section=texteffects`}
      >
        <Typography variant="body2">Formatting help</Typography>
      </OpenInNewLink>
    </>
  );
};

IssueDescriptionField.propTypes = {
  description: PropTypes.string.isRequired,
  jiraDomain: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IssueDescriptionField;
