import { PlayArrow } from '@onc/icons';
import { IconButton } from 'base-components';
import { useRunTaskMutation } from 'domain/AppComponents/batch/hooks/BatchTaskMutationHooks';

const BatchRunAction = ({
  taskId,
  isRunnable = false,
}: {
  taskId: number;
  isRunnable?: boolean;
}) => {
  const runTaskMutation = useRunTaskMutation();
  if (isRunnable) {
    return (
      <IconButton
        aria-label="Run"
        onClick={() => runTaskMutation.mutate(taskId)}
      >
        <PlayArrow />
      </IconButton>
    );
  }

  return (
    <IconButton
      aria-label="Task cannot be run yet"
      disabled
      onClick={() => runTaskMutation.mutate(taskId)}
    >
      <PlayArrow />
    </IconButton>
  );
};

export default BatchRunAction;
