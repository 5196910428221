import { Component } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Dropdown, TextField } from 'base-components';
import DataFileService from 'domain/AppComponents/Dashboard/DataFileService';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
  dropdown: {
    margin: theme.spacing(1),
    minWidth: '400px',
    maxWidth: '400px',
  },
});

const defaultState = {
  options: [],
};

class FileManagementDeviceSelect extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    device: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.number])
      .isRequired,
  };

  static defaultProps = {
    onChange: () => {},
  };

  renderInput = (params) => (
    <TextField {...params} variant="outlined" translationKey="device.device" />
  );

  getOptionLabel = (option) => `${option.label} (${option.value}) `;

  getOptionSelected = (option, value) => option.key === value.key;

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  componentDidMount() {
    this.getListDevicesDropdown();
  }

  getListDevicesDropdown = () => {
    const { onError } = this.props;
    DataFileService.getListDevices()
      .then((result) => {
        const options = result.data.map((option) => ({
          label: option.deviceName,
          value: option.deviceId,
          key: option.deviceId.toString(),
        }));
        this.setState({
          options,
        });
      })
      .catch((result) => {
        onError(result.message);
      });
  };

  render() {
    const { onChange, device } = this.props;
    const { options } = this.state;

    if (options.length === 0) {
      return (
        <Dropdown fullWidth options={options} disabled label="Loading..." />
      );
    }
    return (
      <Autocomplete
        id="deviceSelect"
        options={options}
        renderInput={this.renderInput}
        getOptionLabel={this.getOptionLabel}
        onChange={onChange}
        value={device.value === 0 ? null : device}
        autoSelect
        autoHighlight
        fullWidth
        isOptionEqualToValue={this.getOptionSelected}
        label="Device"
      />
    );
  }
}

export default withStyles(styles)(withSnackbars(FileManagementDeviceSelect));
