import { Grid, LabelledCheckbox, Typography } from 'base-components';
import DeviceCategorySelect from 'domain/AppComponents/dropdowns/DeviceCategorySelect';
import { SecurityTierDropdown } from 'domain/AppComponents/dropdowns/Dropdowns';
import { RORWDropdown } from 'domain/AppComponents/dropdowns/RORWDropdowns';
import {
  MarginedReadOnlyField,
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';
import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';

export type GeneralTabFormType = {
  deviceName: string;
  deviceCode: string;
  deviceCategoryId: number;
  l22TermName: string;
  l22TermLink: string;
  l05TermName: string;
  l05TermLink: string;
  deviceType: string;
  deviceTypeId: number;
  tier: string;
  powerable: number;
  neptuneSearchable: boolean;
  memo: string;
  modifyBy: string;
  modifyDate: string;
};

type GeneralTabFormValue<T extends keyof GeneralTabFormType> =
  GeneralTabFormType[T];

type Props = {
  onChange: <T extends keyof GeneralTabFormType>(
    key: T,
    value: GeneralTabFormValue<T>
  ) => void;
  onSubmit: (event: any) => void;
  value: GeneralTabFormType;
  permission: string;
};

const POWERABLE_OPTIONS = [
  {
    value: 2,
    label: 'Never',
  },
  {
    value: 1,
    label: 'Intermittent',
  },
  {
    value: 0,
    label: 'Always',
  },
];

const GeneralTabForm: React.FC<Props> = ({
  onChange,
  onSubmit,
  value,
  permission,
}: Props) => {
  const renderSecurity = () =>
    permission === 'RW' ? (
      <SecurityTierDropdown
        disabled={permission !== 'RW'}
        name="Security Tier"
        value={value.tier}
        onChange={(e) => onChange('tier', e.target.value)}
      />
    ) : null;
  const renderMemo = () =>
    permission === 'RW' ? (
      <RORWTextField
        translationKey="device.memo"
        permission={permission}
        value={value.memo}
        onChange={(e) => onChange('memo', e.target.value)}
      />
    ) : null;

  return (
    <form onSubmit={onSubmit}>
      <RORWTextField
        translationKey="device.deviceName"
        permission={permission}
        value={value.deviceName}
        onChange={(e) => onChange('deviceName', e.target.value)}
      />
      <RORWTextField
        translationKey="device.deviceCode"
        permission={permission}
        value={value.deviceCode}
        onChange={(e) => onChange('deviceCode', e.target.value)}
      />
      <DeviceCategorySelect
        translationKey="device.deviceCategory"
        fullWidth
        permission={permission === 'RW'}
        value={value.deviceCategoryId}
        onChange={(e) => onChange('deviceCategoryId', e.target.value)}
        onError={() => {}}
      />
      <Grid>
        <MarginedReadOnlyField labelText="L22 (SeaVoX Device Catalogue)" />
        <OpenInNewLink href={value.l22TermLink}>
          <Typography
            variant="body2"
            aria-label="L22 (SeaVoX Device Catalogue)"
          >
            {value.l22TermName}
          </Typography>
        </OpenInNewLink>
      </Grid>
      <Grid>
        <MarginedReadOnlyField labelText="L05 (SeaDataNet Device Category)" />
        <OpenInNewLink href={value.l05TermLink}>
          <Typography
            variant="body2"
            aria-label="L05 (SeaDataNet Device Category)"
          >
            {value.l05TermName}
          </Typography>
        </OpenInNewLink>
      </Grid>
      <MarginedReadOnlyField
        labelText="Device Type"
        valueText={`${value.deviceType} (${value.deviceTypeId})`}
      />
      {renderSecurity()}
      <RORWDropdown
        id="powerable"
        label="Powerable"
        permission={permission === 'RW'}
        options={POWERABLE_OPTIONS}
        value={value.powerable}
        onChange={(e) => onChange('powerable', e.target.value)}
      />
      {renderMemo()}
      <LabelledCheckbox
        name="Oceans3.0Accessible"
        label="Oceans 3.0 Accessible"
        disabled={permission === 'RO'}
        value={value.neptuneSearchable}
        onChange={(e, checked) => onChange('neptuneSearchable', checked)}
      />
      <Grid container item spacing={3}>
        <Grid item>
          <ModifyBy username={value.modifyBy} />
        </Grid>
        <Grid item>
          <ModifyDate date={value.modifyDate} />
        </Grid>
      </Grid>
    </form>
  );
};

export default GeneralTabForm;
