import { parseDmasAPIResponse, get } from 'util/WebRequest';

const SERVICE = 'TopologyService';

export interface TopologyJSON {
  dateFrom: string;
  dateTo: string | null;
  deviceFromId: number;
  deviceFromName: string;
  devicePortFromId: number;
  devicePortToId: number;
  deviceToId: number;
  deviceToName: string;
  extensionId: number | null;
  extensionLength: number;
  extensionName: string | null;
  portFromName: string;
  portToName: string;
  topologyId: number;
}

export interface TopologyByDeviceParams {
  deviceId: number;
  dateFrom: string;
  dateTo: string;
  directDecendentsOnly?: boolean;
  deviceCategoryIds?: string;
}

class TopologyService {
  static getTopology = (effectivedate: string): Promise<TopologyJSON[]> =>
    get(SERVICE, { operationtype: 2, effectivedate }, {}).then((response) => {
      if (response.data.message !== '') {
        const error = new Error(response.data.message);
        throw error;
      }
      return response.data.topology;
    });

  static getTopologyForDevice = async (params: TopologyByDeviceParams) => {
    const response = await get(SERVICE, { operationtype: 6, ...params }, {});
    return parseDmasAPIResponse(response);
  };
}
export default TopologyService;
