import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from 'base-components';
import {
  CancelButton,
  ContainedButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import DmasError from 'util/DmasError';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { NodeType } from './CreateShorestationDialog';
import { ConfirmationDialog, GenericInfoNodeDialog } from '../dialogs/Dialogs';

const classes = {
  formButtons: {
    marginTop: 1,
    float: 'right',
  },
  textField: {
    marginBottom: 1,
  },
};

type MoveDeviceDialogProps = {
  closeDialog: () => void;
  showDialog: boolean;
  devices?: Array<{ queueDeviceId: number }>;
  queues: Array<{ id: number; name: string }>;
  refreshTree: () => void;
  node: NodeType;
};

const MoveDeviceDialog = ({
  closeDialog,
  showDialog,
  devices = undefined,
  queues,
  refreshTree,
  node,
}: MoveDeviceDialogProps) => {
  const { onError } = useSnackbars();

  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(undefined);

  const [showMoveInfoDialog, setShowMoveInfoDialog] = useState<boolean>(false);
  const [moveInfoMessage, setMoveInfoMessage] = useState<string>(undefined);

  const formMethods = useForm<{ queueId: number }>({ mode: 'onBlur' });
  const queueId = formMethods.watch('queueId');

  const openConfirmationDialog = (err: string) => {
    setShowConfirmationDialog(true);
    setErrorMessage(err);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
    setErrorMessage(undefined);
  };

  const openMoveInfoDialog = (err: string) => {
    setShowMoveInfoDialog(true);
    setMoveInfoMessage(err);
  };

  const closeMoveInfoDialog = () => {
    setShowMoveInfoDialog(false);
    setMoveInfoMessage(undefined);
  };

  const { mutate: moveQueueDevice } = usePost<
    {
      destinationQueueId: number;
      sourceQueueId: number;
      queueDeviceIds: string;
      type: string;
      forceMapping: boolean;
    },
    { data: { message?: string; statusCode: number } }
  >(
    'MultiQueueService',
    {
      onSuccess: (response) => {
        if (response?.data?.message) {
          openMoveInfoDialog(response?.data?.message);
        } else {
          formMethods.reset();
        }
        refreshTree();
        if (showConfirmationDialog) {
          closeConfirmationDialog();
        }
      },
      onError: (err) => {
        if (
          !showConfirmationDialog &&
          err.message.includes('dependent deviceIds')
        ) {
          openConfirmationDialog(err.message);
        } else {
          onError(
            `failed to move device(s) to queue ${queueId}: ${err.message}`
          );
          formMethods.reset();
        }
        if (showConfirmationDialog) {
          closeConfirmationDialog();
        }
      },
    },
    {
      operation: 2,
      transform: (response) => {
        const { data } = response;
        if (data.statusCode !== 0) {
          throw new DmasError(data.message, data.statusCode);
        }
        return response;
      },
    }
  );

  const handleMove = () => {
    const queueDeviceIds = [];

    devices.forEach((qd) => {
      queueDeviceIds.push(qd.queueDeviceId);
    });
    moveQueueDevice({
      destinationQueueId: queueId,
      sourceQueueId: node.objectId,
      queueDeviceIds: queueDeviceIds.join(','),
      forceMapping: showConfirmationDialog,
      type: 'queueDevice',
    });
    closeDialog();
  };

  const renderConfirmMappingDialog = () => {
    const parsedErrors = errorMessage ? JSON.parse(errorMessage).join() : '';
    const content = (
      <Typography variant="subtitle1" align="center">
        {parsedErrors} <br />
        Would you like to proceed with the mapping?
      </Typography>
    );
    return (
      <ConfirmationDialog
        open={showConfirmationDialog}
        title="Move Device"
        content={content}
        onCancel={() => {
          closeConfirmationDialog();
          formMethods.reset();
        }}
        onConfirm={handleMove}
      />
    );
  };

  const renderMoveInfoDialog = () => {
    if (!showMoveInfoDialog) return null;
    const content = (
      <Typography variant="subtitle1" align="center">
        {moveInfoMessage.split('\\n').map((message) => (
          <div>{message}</div>
        ))}
      </Typography>
    );
    return (
      <GenericInfoNodeDialog
        open={showMoveInfoDialog}
        onClose={() => {
          closeMoveInfoDialog();
          formMethods.reset();
        }}
        title="Redirect Queues"
        message={content}
      />
    );
  };

  if (devices) {
    return (
      <>
        <Dialog
          open={showDialog}
          onClose={() => {
            closeDialog();
            formMethods.reset();
          }}
          fullWidth
        >
          <DialogTitle>
            Move {devices.length > 1 ? `${devices.length} devices` : 'device'}{' '}
            to another queue
          </DialogTitle>
          <DialogContent>
            <Form onSubmit={handleMove} formMethods={formMethods}>
              <>
                <FormTextField
                  translationKey="common.textfields.variable"
                  translationOptions={{
                    variableName: node.multiQueueTreeNodeName,
                  }}
                  sx={{ textField: classes.textField }}
                  defaultValue={node.objectId}
                  fullWidth
                  disabled
                  title="sourceQueueId"
                  name="sourceQueueId"
                />
                <FormDropdown
                  options={queues.map((queue) => ({
                    key: String(queue.id),
                    value: String(queue.id),
                    label: queue.name,
                  }))}
                  label="Queue"
                  name="queueId"
                  fullWidth
                />
              </>
              <Box sx={classes.formButtons}>
                <CancelButton
                  onClick={() => {
                    closeDialog();
                    formMethods.reset();
                  }}
                />
                <ContainedButton
                  translationKey="common.buttons.move"
                  type="submit"
                />
              </Box>
            </Form>
          </DialogContent>
        </Dialog>
        {renderConfirmMappingDialog()}
        {renderMoveInfoDialog()}
      </>
    );
  }
  return null;
};

export default MoveDeviceDialog;
