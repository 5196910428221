/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Tooltip,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from 'base-components';
import DateUtils from 'util/DateUtils';

export interface DisplayAnnotation {
  id: number;
  timestamp: Date;
  taxon: string;
  taxonomy: string;
  attributes: {
    attributeName: string;
    attributeValue: string;
  }[];
  comment: string;
}

interface Props {
  annotations?: DisplayAnnotation[];
}

const LiveExpeditionAnnotations: React.FC<Props> = ({
  annotations = [],
}: Props) => {
  const [prevAnnotations, setPrevAnnotations] = useState<DisplayAnnotation[]>(
    []
  );
  const [expandedStates, setExpandedStates] = useState<{
    [id: string]: boolean;
  }>({});

  // Update expanded states for new annotations
  useEffect(() => {
    const newAnnotations = annotations.filter(
      (annotation) =>
        !prevAnnotations.some((expanded) => expanded.id === annotation.id)
    );
    if (newAnnotations.length > 0) {
      setPrevAnnotations((prev) => [
        ...newAnnotations,
        ...prev.filter((anno) =>
          annotations.find((anno2) => anno2.id === anno.id)
        ),
      ]);

      // Update expanded states for new annotations
      const newExpandedStates = newAnnotations.reduce(
        (acc, annotation) => {
          acc[annotation.id] = false;
          return acc;
        },
        {} as { [id: string]: boolean }
      );
      setExpandedStates((prev) => ({ ...newExpandedStates, ...prev }));
    }

    // Expand new annotations
    if (prevAnnotations.length > 0) {
      newAnnotations.forEach((annotation) => {
        setTimeout(() => {
          setExpandedStates((prev) => ({ ...prev, [annotation.id]: true }));
        }, 100);
      });
      // If there are no previous annotations, expand all new annotations
      // Used for the initial load of annotations
    } else {
      newAnnotations.forEach((annotation) => {
        setExpandedStates((prev) => ({ ...prev, [annotation.id]: true }));
      });
    }
  }, [annotations]);

  if (!annotations || annotations.length === 0) {
    return (
      <Typography
        color="textSecondary"
        variant="body2"
        id="no-annotations-message"
      >
        No annotations found
      </Typography>
    );
  }

  return (
    <List id="live-expedition-annotation-list">
      {prevAnnotations.map((annotation) => (
        <>
          <Collapse
            key={annotation.id}
            in={expandedStates[annotation.id]}
            timeout={500}
          >
            <Divider />

            <ListItem key={annotation.id}>
              <ListItemText
                primary={
                  <Box display="flex" alignItems="top">
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      style={{ minWidth: '120px' }}
                      id={`annotation-${annotation.id}-timestamp`}
                    >
                      <Tooltip
                        title={`${DateUtils.formatDateAsString(
                          annotation.timestamp
                        )}`}
                      >
                        <b>{DateUtils.timeAgo(annotation.timestamp)}</b>
                      </Tooltip>
                    </Typography>
                    <Typography
                      variant="body2"
                      id={`annotation-${annotation.id}-content`}
                    >
                      {annotation.taxon ? (
                        <>
                          <b>{annotation.taxon}</b>
                          {` [${annotation.taxonomy}]`}
                        </>
                      ) : null}

                      {annotation.attributes.map((attribute) => (
                        <>
                          <span style={{ display: 'flex' }}>
                            <Typography color="textSecondary" variant="caption">
                              &nbsp;&nbsp;{attribute.attributeName}:
                            </Typography>
                            <Typography color="textPrimary" variant="caption">
                              &nbsp;{attribute.attributeValue}
                            </Typography>
                          </span>
                        </>
                      ))}
                      <Typography
                        variant="body2"
                        id={`annotation-${annotation.id}-comment`}
                      >
                        {annotation.comment}
                      </Typography>
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          </Collapse>
        </>
      ))}
      <Divider />
    </List>
  );
};

export default LiveExpeditionAnnotations;
