import '@fontsource/rajdhani';
import { useState } from 'react';
import * as React from 'react';
import CardMui from '@mui/material/Card';
import CardMediaMui from '@mui/material/CardMedia';
import { Theme } from '@mui/material/styles';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { ArrowForward as Arrow } from '@onc/icons';
import { Link } from 'base-components';
import PanelCardHighlighting from 'domain/Apps/oceans-3-landing-page/PanelCardHighlight';
import PanelCardInfoLink from 'domain/Apps/oceans-3-landing-page/PanelCardInfoLink';
import PanelCardMask from 'domain/Apps/oceans-3-landing-page/PanelCardMask';
import PanelCardText from 'domain/Apps/oceans-3-landing-page/PanelCardText';

const STYLES = (theme: Theme) =>
  createStyles({
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    card: {
      position: 'relative',
      borderRadius: '0.5rem',
    },
    disabledCard: {
      position: 'relative',
      borderRadius: '0.5rem',
      boxShadow: 'none',
    },
    overlay: {
      position: 'absolute',
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0)',
      zIndex: 2,
    },
    arrow: {
      color: theme.palette.highlight.main,
      position: 'absolute',
      bottom: theme.spacing(4),
      left: theme.spacing(2),
      width: theme.spacing(5),
      height: theme.spacing(5),
      zIndex: 1,
    },
    info: {
      color: theme.palette.primary.contrastText,
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      width: theme.spacing(5),
      height: theme.spacing(5),
      zIndex: 3,
    },
    infoIcon: {
      position: 'absolute',
      bottom: theme.spacing(0),
    },
  });

interface PanelCardProps extends WithStyles<typeof STYLES> {
  titleText: string;
  bodyText: string;
  imageUrl: string;
  pageUrl: string;
  infoUrl?: string;
  disabled?: boolean;
  noMask?: boolean;
}

const PanelCard: React.FC<PanelCardProps> = ({
  classes,
  titleText,
  bodyText,
  pageUrl,
  imageUrl,
  infoUrl = undefined,
  disabled = undefined,
  noMask = undefined,
}) => {
  const [highlighted, setHighlighted] = useState(false);
  const [cursor, setCursor] = useState('cursor');

  const enableHighlight = () => setHighlighted(true);
  const disableHighlight = () => setHighlighted(false);

  if (!disabled) {
    return (
      <CardMui className={classes.card}>
        <Link
          title={titleText}
          onMouseEnter={enableHighlight}
          onMouseLeave={disableHighlight}
          onFocus={enableHighlight}
          onBlur={disableHighlight}
          className={classes.overlay}
          href={pageUrl}
          target="_blank"
        >
          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
          {''}
        </Link>
        <PanelCardInfoLink
          infoUrl={infoUrl}
          highlighted={highlighted}
          enableHighlight={enableHighlight}
          disableHighlight={disableHighlight}
          titleText={titleText}
          setCursor={setCursor}
          cursorType={cursor}
        />
        <CardMediaMui className={classes.media} image={imageUrl} />
        <PanelCardText
          titleText={titleText}
          bodyText={bodyText}
          highlighted={highlighted}
        />
        <PanelCardHighlighting highlighted={highlighted} />
        <Arrow className={classes.arrow} />
        <PanelCardMask noMask={noMask} highlighted={highlighted} />
      </CardMui>
    );
  }

  return (
    <CardMui className={classes.disabledCard}>
      <CardMediaMui className={classes.media} image={imageUrl} />
    </CardMui>
  );
};

export default withStyles(STYLES)(PanelCard);
