import { useEffect } from 'react';
import { TabData } from '@onc/composite-components';
import { OpenInNew } from '@onc/icons';
import { QuickEntryButton, Toggle } from 'base-components';
import TabbedButtonSetSelector from 'domain/AppComponents/sea-tube/button-set/TabbedButtonSetSelector';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import useWidgetConfig from 'library/CompositeComponents/dashboard/hooks/useWidgetConfig';
import Environment from 'util/Environment';
import { useLocalStorage } from 'util/hooks/useStorage';
import BroadcastChannel from './BroadcastChannel';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget, {
  WidgetMenuItem,
  WidgetMenuItemCheckbox,
} from '../../library/CompositeComponents/dashboard/Widget';
import useBroadcast from '../../util/hooks/useBroadcast';

const defaultConfig: ButtonSetConfig = {
  showIndexes: false,
  enableQuickSave: false,
};

type ButtonSetConfig = {
  showIndexes: boolean;
  initialIndex?: number;
  initialTabs?: TabData[];
  enableQuickSave?: boolean;
};

const ButtonSetWidget: DashboardWidget<ButtonSetConfig> = (
  props: DashboardWidgetProps
) => {
  const { id, hasFocus, appName, dashboardId } = props;
  const [savedConfig, setSavedConfig] = useLocalStorage<ButtonSetConfig>(
    `${appName}-${id}-config`,
    defaultConfig
  );
  const { widgetConfig, setConfigProperty } = useWidgetConfig<ButtonSetConfig>(
    id,
    savedConfig
  );

  const { dashboardState, setDashboardStateProperty } = useDashboardState();

  const ButtonSetManagementMenuItem = WidgetMenuItem(
    'button-set-management-link',
    () => {
      window.open(`${Environment.getDmasUrl()}/TaxonButtonSetConfig`);
    },
    'Button Set Management',
    OpenInNew,
    false
  );

  const ShowIndexesCheckbox = (
    <WidgetMenuItemCheckbox
      key="show-indexes-option"
      onClick={() => {
        setConfigProperty('showIndexes', !widgetConfig.showIndexes);
      }}
      label="Show Button Indexes"
      checked={widgetConfig.showIndexes}
    />
  );

  const [, setSelectedButton] = useBroadcast(
    dashboardId,
    BroadcastChannel.QuickButton,
    undefined,
    id
  );

  const handleQuickSave = () => {
    setConfigProperty('enableQuickSave', !widgetConfig.enableQuickSave);
  };

  const handleSubmit = (button: QuickEntryButton) => {
    setSelectedButton(button);
    setDashboardStateProperty('selectedButton', button);
  };

  const QuickSaveButton = (
    <Toggle
      id="quick-save-toggle"
      label="Quick Save"
      tooltip="When on will automatically save annotation when quick buttons are used."
      value={widgetConfig.enableQuickSave}
      onChange={handleQuickSave}
    />
  );

  useEffect(() => {
    setSavedConfig(widgetConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetConfig]);

  return (
    <Widget
      key={id}
      title="Quick Button Sets"
      MenuItems={[ShowIndexesCheckbox, ButtonSetManagementMenuItem]}
      actionComponents={dashboardState?.hideQuickSave ? [] : [QuickSaveButton]}
      {...props}
    >
      <TabbedButtonSetSelector
        onSubmit={handleSubmit}
        showIndexes={widgetConfig.showIndexes}
        quickSave={widgetConfig.enableQuickSave}
        hasFocus={hasFocus}
        initialIndex={widgetConfig.initialIndex}
        initialTabs={widgetConfig.initialTabs}
        onIndexChange={(newIndex: number) => {
          setConfigProperty('initialIndex', newIndex);
        }}
        onTabsChange={(newTabs: TabData[]) => {
          setConfigProperty('initialTabs', newTabs);
        }}
      />
    </Widget>
  );
};

ButtonSetWidget.widgetKey = 'button-set';
ButtonSetWidget.widgetTitle = 'Quick Button Sets';
ButtonSetWidget.defaultDataGrid = { i: 'button-set', x: 0, y: 0, w: 9, h: 6 };

export default ButtonSetWidget;
