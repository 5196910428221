import { get } from 'util/WebRequest';

const SERVICE = 'api/devices';

class DeviceWebServiceWithToken {
  static get(params) {
    return get(SERVICE, params);
  }
}
export default DeviceWebServiceWithToken;
