import { Component } from 'react';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import TableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';

class TabPanel extends Component {
  static propTypes = {
    column: PropTypes.shape({ name: PropTypes.string }).isRequired,
  };

  render() {
    const { column } = this.props;
    if (column.name === 'icons') return <TableCell />;
    return <TableFilterRow.Cell {...this.props} />;
  }
}

export default TabPanel;
