import { OncLayouts } from 'library/CompositeComponents/dashboard/DashboardTypes';

const defaultAnonymous: OncLayouts = {
  lg: [
    { w: 3, h: 14, x: 0, y: 0, i: 'map' },
    {
      w: 4,
      h: 24,
      x: 8,
      y: 0,
      i: 'annotation-list-1',
    },
    {
      w: 5,
      h: 14,
      x: 3,
      y: 0,
      i: 'dive-video-1',
    },
    {
      w: 8,
      h: 10,
      x: 0,
      y: 6,
      i: 'sensor-readings',
    },
  ],
  md: [
    {
      w: 4,
      h: 12,
      x: 6,
      y: 0,
      i: 'annotation-list-1',
    },
    {
      w: 6,
      h: 12,
      x: 0,
      y: 0,
      i: 'dive-video-1',
    },
    {
      w: 6,
      h: 10,
      x: 0,
      y: 5,
      i: 'sensor-readings',
    },
    { w: 4, h: 10, x: 6, y: 5, i: 'map' },
  ],
  sm: [
    {
      w: 8,
      h: 14,
      x: 0,
      y: 6,
      i: 'annotation-list-1',
    },
    {
      w: 8,
      h: 14,
      x: 0,
      y: 0,
      i: 'dive-video-1',
    },
    {
      w: 8,
      h: 17,
      x: 0,
      y: 12,
      i: 'sensor-readings',
    },
    { w: 8, h: 10, x: 0, y: 15, i: 'map' },
  ],
  xs: [
    {
      w: 6,
      h: 10,
      x: 0,
      y: 4,
      i: 'annotation-list-1',
    },
    {
      w: 6,
      h: 10,
      x: 0,
      y: 0,
      i: 'dive-video-1',
    },
    {
      w: 6,
      h: 10,
      x: 0,
      y: 8,
      i: 'sensor-readings',
    },
    { w: 6, h: 14, x: 0, y: 12, i: 'map' },
  ],
};
export default defaultAnonymous;
