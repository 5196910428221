import { Dropdown, ErrorPage } from 'base-components';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';
import useGet from 'util/hooks/useDmasAPI/useGet';

type PrincipalInvestigatorDropdownProps = Omit<
  FormFieldType<React.ComponentProps<typeof Dropdown>>,
  'options'
>;

type PrincipalInvestigatorPayload = [
  {
    principalInvestigator: {
      dmasUserId: number;
      firstname: string;
      lastname: string;
    };
  },
];

const PrincipalInvestigatorDropdown = ({
  ...props
}: PrincipalInvestigatorDropdownProps) => {
  const principalInvestigatorData = useGet<PrincipalInvestigatorPayload>(
    'DeviceResourceRoleService',
    {
      operation: 5,
    }
  );

  if (principalInvestigatorData.isLoading) {
    return <FormDropdown {...props} options={[]} />;
  }

  if (principalInvestigatorData.error) {
    return <ErrorPage />;
  }

  const optionsSorted = principalInvestigatorData?.data?.sort(
    (a, b) =>
      a.principalInvestigator.lastname.localeCompare(
        b.principalInvestigator.lastname
      ) ||
      a.principalInvestigator.firstname.localeCompare(
        b.principalInvestigator.firstname
      )
  );

  const options = [{ label: 'All', value: -1, key: '-1' }].concat(
    optionsSorted?.map((option) => ({
      label: `${option.principalInvestigator.firstname} ${option.principalInvestigator.lastname}`,
      value: option.principalInvestigator.dmasUserId,
      key: option.principalInvestigator.dmasUserId.toString(),
    }))
  );

  return <FormDropdown options={options} {...props} />;
};

export default PrincipalInvestigatorDropdown;
