import { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Plotly, { Config, Layout, PlotData } from 'plotly.js-basic-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import {
  ToggleLegendIcon,
  MaterialZoomIcon,
  MaterialSettingsIcon,
  MaterialRemoveIcon,
} from '@onc/icons';
import {
  LEGEND_MAPPING,
  SHOW_LEGEND_TITLE,
} from 'domain/AppComponents/charts/ChartUtils';
import useModebarButton from './useModebarButton';
import type {
  ChartQuality,
  ResamplePeriod,
  YAxisRanges,
} from 'domain/AppComponents/charts/types/TimeSeriesScalarDataChart.types';

const Plot = createPlotlyComponent(Plotly);

const STYLES = {
  root: {
    width: '100%',
    height: '90%',
  },
  legendActive: {
    width: '100%',
    height: '100%',
  },
};

const useStyles = makeStyles(STYLES);

const REFRESHING_INDICATOR_TEXT =
  '<span style="color:forestgreen"><i> (Refreshing)</i></span>';

const DEFAULT_MARGIN = { l: 60, r: 60, t: 75, b: 0 };

type OnDataSelectionChangeAction =
  | 'panselected'
  | 'reset'
  | 'autoscale'
  | 'daterangechange'
  | 'yaxischange';

export type YAxisSide = 'left' | 'right';

export type OnDataSelectionChange = (
  action: string,
  axesRange: {
    xaxis?: [string, string];
    leftYaxis?: [number, number];
    rightYaxis?: [number, number];
  }
) => void;

export type TraceData = {
  name: string;
  data: {
    times: string[];
    values: number[];
    min?: number[];
    max?: number[];
    qaqcFlags?: number[];
  };
  color?: string;
  minMaxColor?: string;
  yaxisSide: YAxisSide;
  chartQuality?: ChartQuality;
  showTrace?: boolean;
  width?: number;
  isCleanAvg?: boolean;
  resamplePeriod?: ResamplePeriod;
  unitOfMeasure?: string;
};

type SubChart = {
  title: string;
  traces: TraceData[];
};

type TimeSeriesChartProps = {
  subChart: SubChart;
  dateRange: string[];
  alwaysShowModebar?: boolean;
  leftYaxisTitle?: string;
  rightYaxisTitle?: string;
  onDataSelectionChange?: OnDataSelectionChange;
  shapes?: Layout['shapes'];
  resamplePeriod?: number;
  showMinMax?: boolean;
  showRefreshingIndicator?: boolean;
  margin?: Layout['margin'];
  displayQaqc?: boolean;
  classes?: {
    root?: string;
  };
  shiftDateRangeAhead?: () => void;
  shiftDateRangeBack?: () => void;
  handleUnsummarizedZoom?: () => void;
  showLegend?: boolean;
  toggleLegend?: () => void;
  chartQuality?: 'raw' | 'clean';
  onSettingsClick?: () => void;
  onRemoveClick?: () => void;
  yAxisRange?: YAxisRanges;
};

const handleRelayout =
  (
    onDataSelectionChange: OnDataSelectionChange,
    layout: Partial<Plotly.Layout>
  ) =>
  (event: any) => {
    const eventData: {
      xaxis?: [string, string];
      leftYaxis?: [number, number];
      rightYaxis?: [number, number];
    } = {};
    let action: OnDataSelectionChangeAction | undefined;

    if (event.dragmode === 'pan') {
      action = 'panselected';
    } else if (event['xaxis.showspikes'] !== undefined) {
      // showspikes is only ever in the event when reset has been selected
      action = 'reset';
    } else if (event['xaxis.autorange'] !== undefined) {
      // if showspikes is not in the event but autorange is then autoscale has been selected
      action = 'autoscale';
    } else if (
      event['xaxis.range[0]'] ||
      event['yaxis.range[0]'] ||
      event['yaxis2.range[0]']
    ) {
      // it is a manual change of either or both the x and y axes
      if (event['xaxis.range[0]']) {
        action = 'daterangechange';
      } else {
        action = 'yaxischange';
      }
      if (event['xaxis.range[0]']) {
        eventData.xaxis = [event['xaxis.range[0]'], event['xaxis.range[1]']];
      }
      if (
        event['xaxis.range[1]'] &&
        !event['yaxis.range[0]'] &&
        !event['yaxis2.range[0]']
      ) {
        // Use the current yaxis range if only the xaxis is shifted/dragged
        eventData.leftYaxis = layout?.yaxis?.range ?? eventData.leftYaxis;
        eventData.rightYaxis = layout?.yaxis2?.range ?? eventData.rightYaxis;
      }
      if (event['yaxis.range[0]']) {
        eventData.leftYaxis = [
          event['yaxis.range[0]'],
          event['yaxis.range[1]'],
        ];
      }
      if (event['yaxis2.range[0]']) {
        eventData.rightYaxis = [
          event['yaxis2.range[0]'],
          event['yaxis2.range[1]'],
        ];
      }
    }
    if (action) {
      onDataSelectionChange(action, eventData);
    }
  };

const PREVIOUS_LABEL = '< Prev';
const NEXT_LABEL = 'Next >';

/**
 * TimeSeriesChart displays a line chart time range and date values given as
 * props. The x-axis name and sub-chart name must also be supplied.
 *
 * For now, only one subchart with many traces, and either one or two axes is
 * supported. Note that the data.times values should be date time strings. If
 * passed as dates then plotly will convert them to local time which instead of
 * leaving them as UTC.
 *
 * The onDataSelectionChange event is passed two range values, the first for x
 * the second for y.
 */
const TimeSeriesChart: React.FC<TimeSeriesChartProps> = ({
  subChart,
  leftYaxisTitle = undefined,
  rightYaxisTitle = undefined,
  onDataSelectionChange = () => {},
  alwaysShowModebar = false,
  dateRange,
  shapes = undefined,
  resamplePeriod = undefined,
  showRefreshingIndicator = false,
  showMinMax = false,
  margin = DEFAULT_MARGIN,
  classes = {},
  shiftDateRangeAhead = undefined,
  shiftDateRangeBack = undefined,
  handleUnsummarizedZoom = undefined,
  showLegend = false,
  toggleLegend = () => {},
  displayQaqc = false,
  chartQuality = undefined,
  onSettingsClick = undefined,
  onRemoveClick = undefined,
  yAxisRange = undefined,
}: TimeSeriesChartProps) => {
  const allClasses = { ...useStyles(), ...classes };

  const onButtonClicked = useCallback(
    (eventData) => {
      switch (eventData?.button?.label) {
        case NEXT_LABEL:
          shiftDateRangeAhead();
          break;
        case PREVIOUS_LABEL:
          shiftDateRangeBack();
          break;
        default:
          break;
      }
    },
    [shiftDateRangeAhead, shiftDateRangeBack]
  );

  const {
    button: unSummarizedZoomButton,
    handlePlotInitialized: setupUnsummarizedZoomButton,
    updateButtonOnClick: updateUnsummarizedZoomButton,
  } = useModebarButton({
    onClick: handleUnsummarizedZoom,
    icon: MaterialZoomIcon,
    name: 'zoom-to-unsummarized',
    title: 'Zoom to unsummarized',
  });

  const {
    button: toggleLegendButton,
    handlePlotInitialized: setupLegendButton,
    activeClass,
  } = useModebarButton({
    onClick: toggleLegend,
    icon: ToggleLegendIcon,
    name: SHOW_LEGEND_TITLE,
    title: SHOW_LEGEND_TITLE,
    active: showLegend,
  });

  const modeBarButtonsToAdd = useMemo(() => {
    const buttons = [toggleLegendButton];

    onSettingsClick &&
      buttons.push({
        name: 'chartsettings',
        title: 'Chart Settings',
        icon: MaterialSettingsIcon,
        click: onSettingsClick,
      });

    onRemoveClick &&
      buttons.push({
        name: 'removechart',
        title: 'Remove Chart',
        icon: MaterialRemoveIcon,
        click: onRemoveClick,
      });

    return buttons;
  }, [toggleLegendButton, onSettingsClick, onRemoveClick]);

  if (handleUnsummarizedZoom && resamplePeriod) {
    modeBarButtonsToAdd.unshift(unSummarizedZoomButton);
  }

  const config: Config = {
    responsive: true,
    scrollZoom: true,
    modeBarButtonsToRemove: ['lasso2d', 'select2d'],
    modeBarButtonsToAdd,
    displaylogo: false,
    displayModeBar: alwaysShowModebar ? true : 'hover',
  };

  const frames = [];

  const layout: Layout = useMemo(() => {
    const updatemenus = [];

    if (shiftDateRangeBack) {
      updatemenus.push({
        buttons: [
          {
            label: PREVIOUS_LABEL,
            method: 'skip',
            args: [{ shiftDateRangeBack: true }],
            execute: true,
            title: 'Previous',
            pad: { l: 5, r: 1, b: 1 },
          },
        ],
        type: 'buttons',
        x: 0,
        xref: 'x domain',
        y: 1.04,
        yref: 'y',
        xanchor: 'right',
        yanchor: 'bottom',
        bgcolor: 'white',
        font: { color: 'black', size: 10 },
        showactive: false,
      });
    }

    if (shiftDateRangeAhead) {
      updatemenus.push({
        buttons: [
          {
            label: NEXT_LABEL,
            method: 'skip',
            args: [{ shiftDateRangeAhead: true }],
            execute: true,
            title: 'Next',
            pad: { l: 1, r: 5, b: 1 },
          },
        ],
        type: 'buttons',
        x: 1,
        xanchor: 'left',
        y: 1.04,
        yanchor: 'bottom',
        showactive: false,
        bgcolor: 'white',
        font: { color: 'black', size: 10 },
      });
    }

    return {
      title: { text: subChart.title, font: { size: 15 }, y: 0.93 },
      autosize: true,
      showlegend: true,
      legend: {
        orientation: 'h',
        y: '0',
        yref: 'container',
        font: { size: 11 },
        groupclick: 'toggleitem',
      },
      margin,
      xaxis: {
        type: 'date',
        showline: true,
        showgrid: false,
        title: `UTC${showRefreshingIndicator ? REFRESHING_INDICATOR_TEXT : ''}`,
        automargin: true,
        range: [...dateRange],
        autorange: false,
        zeroline: false,
      },
      hovermode: 'x unified',
      shapes,
      ...(updatemenus.length > 0 && { updatemenus }),
    };
  }, [
    shiftDateRangeBack,
    shiftDateRangeAhead,
    subChart.title,
    margin,
    showRefreshingIndicator,
    dateRange,
    shapes,
  ]);

  const capitalize = (str: string) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return str;
  };

  const getTraceData = useCallback(() => {
    const tempData: PlotData[] = [];

    subChart.traces.forEach((trace) => {
      if (showMinMax && trace?.data?.max) {
        const minValues = trace.data.min
          ? trace.data.min.filter((val) => val !== null)
          : [];

        const maxValues = trace.data.max
          ? trace.data.max.filter((val) => val !== null)
          : [];

        const nonEmptyTimes = trace.data.times.filter(
          (_, i) => trace.data.min![i] !== null
        );

        const avgValuesNoNulls = trace.data.values
          ? trace.data.values?.filter((val) => val !== null)
          : [];

        const barWidths = Array.from(
          nonEmptyTimes,
          () => resamplePeriod! * 1000
        );

        const barHeights = maxValues.map((max, i) => max - minValues[i]);
        const isMinMaxSame = barHeights.every((height) => height === 0);

        tempData.push({
          type: isMinMaxSame ? 'scatter' : 'bar',
          x: nonEmptyTimes,
          y: isMinMaxSame ? maxValues : barHeights,
          mode: 'lines',
          width: barWidths,
          name: `${capitalize(trace.chartQuality!)} Min/Max`,
          yaxis: trace.yaxisSide === 'left' ? 'y' : 'y2',
          legendgroup: `${trace.name}_${trace.color}`,
          legendgrouptitle: { text: `${trace.name}` },
          showlegend: true,
          visible: trace.showTrace,
          base: minValues,
          marker: {
            color: trace.minMaxColor,
          },
          customdata: minValues.map((min, i) => ({
            min,
            max: maxValues[i],
            values: avgValuesNoNulls[i],
          })),
          hovertemplate: `Time: %{x}<br><span>Min: %{customdata.min}</span><br><span>Max: %{customdata.max}</span><extra></extra>`,
        });
      }

      if (trace?.data?.values) {
        tempData.push({
          type: 'scatter',
          mode: 'lines',
          x: trace.data.times,
          y: trace.data.values,
          legendgroup: `${trace.name}_${trace.color}`,
          legendgrouptitle: { text: `${trace.name}` },
          line: {
            width: trace.width ? trace.width : 1,
            color: trace.color ? trace.color : '#FF0000',
          },
          name: resamplePeriod
            ? `Clean average data`
            : `${capitalize(trace?.chartQuality)} Value`,
          yaxis: trace.yaxisSide === 'left' ? 'y' : 'y2',
        });
      }

      // Add left axis if it doesn't exist and the data contains left axis data
      if (!layout.yaxis && trace.yaxisSide === 'left') {
        layout.yaxis = {
          title: { text: leftYaxisTitle },
          showline: true,
          showgrid: false,
          zeroline: false,
          automargin: true,
          side: 'left',
          ticks: 'outside',
          range: [
            yAxisRange?.yAxisLeft[0] || null,
            yAxisRange?.yAxisLeft[1] || null,
          ],
        };
      }
      if (layout.yaxis && yAxisRange?.yAxisLeft) {
        // Update y-axis on date change & reset
        // TODO: If no limit is set, use the trace data's absolute min and max values
        layout.yaxis.range = [yAxisRange.yAxisLeft[0], yAxisRange.yAxisLeft[1]];
      } else if (layout.yaxis && yAxisRange === null) {
        // otherwise set nulls for autoscale
        layout.yaxis.range = [null, null];
      }
      // The titles can come in later than the initial empty traces so add them
      // in if they are not already there
      if (leftYaxisTitle && layout.yaxis.title && !layout.yaxis.title?.text) {
        layout.yaxis.title.text = leftYaxisTitle;
      }

      // Add right axis if it doesn't exist and the data contains right axis data
      if (!layout.yaxis2 && trace.yaxisSide === 'right') {
        layout.yaxis2 = {
          title: { text: rightYaxisTitle },
          showline: true,
          showgrid: false,
          zeroline: false,
          automargin: true,
          overlaying: 'y',
          side: 'right',
          ticks: 'outside',
          range: [
            yAxisRange?.yAxisRight[0] || null,
            yAxisRange?.yAxisRight[1] || null,
          ],
        };
      }
      if (layout.yaxis2 && yAxisRange?.yAxisRight) {
        layout.yaxis2.range = [
          yAxisRange.yAxisRight[0],
          yAxisRange.yAxisRight[1],
        ];
      } else if (yAxisRange === null && layout.yaxis2) {
        layout.yaxis2.range = [null, null];
      }

      if (rightYaxisTitle && layout.yaxis2 && !layout.yaxis2.title.text) {
        layout.yaxis2.title.text = rightYaxisTitle;
      }
    });

    if (displayQaqc) {
      const trace =
        subChart.traces.find((t) => {
          if (chartQuality === 'raw') {
            return !t.isCleanAvg;
          }
          return t.isCleanAvg;
        }) ?? subChart.traces[0];
      if (trace?.data?.qaqcFlags) {
        layout.yaxis.domain = [0.02, 1];
        if (layout.yaxis2) {
          layout.yaxis2.domain = [0.02, 1];
        }
        layout.yaxis3 = {
          domain: [0, 0.02],
          showgrid: false,
          showline: true,
          showticklabels: false,
          fixedrange: true,
          range: [0, 1],
          autorange: false,
          side: trace.yaxisSide,
        };
        layout.xaxis.anchor = 'y3';

        // Calculate as current time to next time
        const widths = Array.from(trace.data.times, (x0, i) => {
          const x1 = trace.data.times[i + 1];
          if (x1) {
            return new Date(x1).getTime() - new Date(x0).getTime();
          }
          if (i > 0) {
            return (
              new Date(x0).getTime() -
              new Date(trace.data.times[i - 1]).getTime()
            );
          }

          // Handles the case where there is only one data point.
          return 0;
        });

        // Shift the x values right to the center of the bar
        const x = Array.from(trace.data.times, (x0, i) =>
          new Date(new Date(x0).getTime() + widths[i] / 2).toISOString()
        );

        const color = trace.data.qaqcFlags.map(
          (qaqcValue) =>
            LEGEND_MAPPING.find(
              (legendVal) => Number(legendVal.name) === qaqcValue
            ).colour
        );

        const y = Array.from(trace.data.qaqcFlags, (flag) => (flag ? 1 : 0));

        tempData.push({
          type: 'bar',
          showlegend: false,
          yaxis: 'y3',
          x,
          y,
          widths,
          marker: {
            color,
          },
          customdata: trace.data.times.map((_, idx) => ({
            qaqc: trace.data.qaqcFlags[idx],
          })),
          hovertemplate: `<span>QAQC Flag: %{customdata.qaqc}</span><extra></extra>`,
        });
      }
    }
    return tempData;
  }, [
    layout,
    leftYaxisTitle,
    resamplePeriod,
    rightYaxisTitle,
    showMinMax,
    subChart.traces,
    yAxisRange,
    displayQaqc,
    chartQuality,
  ]);

  const traceData: PlotData[] = useMemo(() => getTraceData(), [getTraceData]);

  return (
    <Box className={allClasses.legendActive} sx={activeClass}>
      <Plot
        className={allClasses.root}
        data={traceData}
        layout={layout}
        frames={frames}
        config={config}
        onRelayout={handleRelayout(onDataSelectionChange, layout)}
        onAfterPlot={() => {
          updateUnsummarizedZoomButton();
        }}
        onButtonClicked={onButtonClicked}
        onInitialized={(figure, graphDiv) => {
          setupUnsummarizedZoomButton(figure, graphDiv);
          setupLegendButton(figure, graphDiv);
        }}
      />
    </Box>
  );
};

export default TimeSeriesChart;
export { handleRelayout };
