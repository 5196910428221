import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid } from 'base-components';
import { DeprecateDOIDialog } from 'domain/AppComponents/dialogs/Dialogs';
import DataSetToolbar from 'domain/AppComponents/doi-dataset-landing-page/DataSetToolbar';
import DOICitation from 'domain/AppComponents/doi-dataset-landing-page/DOICitation';
import DOIDataFields from 'domain/AppComponents/doi-dataset-landing-page/DOIDataFields';
import DOIDataLinks from 'domain/AppComponents/doi-dataset-landing-page/DOIDataLinks';
import DOIHistoryTable from 'domain/AppComponents/doi-dataset-landing-page/DOIHistoryTable';
import DOIQueryFields from 'domain/AppComponents/doi-dataset-landing-page/DOIQueryFields';
import RelatedDevicesTable from 'domain/AppComponents/doi-dataset-landing-page/RelatedDevicesTable';
import TombstoneAlert from 'domain/AppComponents/doi-dataset-landing-page/TombstoneAlert';
import DOIDatasetService from 'domain/AppComponents/doi-util/DOIDatasetService';
import FormFieldService from 'domain/services/FormFieldService';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const styles = (theme) => ({
  paddingStyle: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(8),
      paddingLeft: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(),
      paddingLeft: theme.spacing(),
    },
  },
  gridContainer: {
    margin: 0,
    width: '100%',
  },
  headingStyle: {
    paddingTop: theme.spacing(2),
  },
});

class DatasetLandingPage extends Component {
  static propTypes = {
    doidataset: PropTypes.string,
    queryPid: PropTypes.string,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape().isRequired,
    classes: PropTypes.shape({
      gridContainer: PropTypes.string,
      gridItem: PropTypes.string,
      headingStyle: PropTypes.string,
      paddingStyle: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    doidataset: undefined,
    queryPid: undefined,
  };

  getParameter = (value) => {
    if (isNaN(value)) {
      return { doidataset: value };
    }
    return { queryPid: value };
  };

  state = {
    doidata: undefined,
    querydata: undefined,
    doicitation: undefined,
    deviceDetails: undefined,
    devices: undefined,
    doiDataSearchURL: undefined,
    querycitation: undefined,
    doi: undefined,
    textFieldValue: '',
    showDeprecateDialog: false,
    deprecateMessage: 'This is where the message set by Data Stewardship goes',
    reason: 'other',
    formFields: undefined,
  };

  componentDidMount() {
    const { doidataset, queryPid } = this.props;
    if (doidataset || queryPid) {
      this.fetchDataFromURL();
      this.getFormFields();
    }
  }

  componentDidUpdate(prevProps) {
    const { doidataset, queryPid } = this.props;
    if (
      doidataset !== prevProps.doidataset ||
      queryPid !== prevProps.queryPid
    ) {
      this.fetchDataFromURL();
    }
  }

  getFormFields = async () => {
    const payload = await FormFieldService.getAllFormFields();
    const formFields = new Map(
      payload.map((field) => [field.fieldLabel, field.formFieldDocumentation])
    );

    this.setState({ formFields });
  };

  setTextField = (textFieldValue) => {
    this.setState({ textFieldValue });
  };

  updateURL = (param) => {
    const { history } = this.props;
    history.push(`?${Object.keys(param)[0]}=${Object.values(param)[0]}`);
  };

  fetchDatasets = (params) =>
    DOIDatasetService.get(params)
      .then((payload) => {
        this.setState({
          doidata: payload.resource,
          doiDataSearchURL: payload.dataSearchURL,
          deviceDetails: payload.device,
          devices: payload.devices,
          doiHistory: payload.doiHistory,
          doicitation: payload.doicitation,
          querycitation: payload.querycitation,
          organizations: payload.organizations,
          doi: payload.doi,
          deprecateMessage: payload.doi.deprecateMessage,
        });
        if (payload.query) {
          this.setState({ querydata: payload.query });
        } else {
          this.setState({ querydata: undefined });
        }
      })
      .catch((error) => {
        this.setState({ doidata: undefined });
        throw error;
      });

  handleSearch = async () => {
    const { onError } = this.props;
    const { textFieldValue } = this.state;
    const param = this.getParameter(textFieldValue);
    await this.fetchDatasets(param).catch((error) =>
      onError(`Failed to load metadata for ${textFieldValue}. ${error}`)
    );
    this.updateURL(param);
  };

  handleTextField = (event) => {
    this.setState({ textFieldValue: event.target.value });
  };

  handleCloseDeprecateDialog = () =>
    this.setState({
      showDeprecateDialog: false,
    });

  handleOpenDeprecateDialog = () =>
    this.setState({
      showDeprecateDialog: true,
    });

  handleReasonChange = (event) => {
    this.setState({
      reason: event.target.value,
    });
    if (event.target.value === 'other') {
      return this.handleDeprecateMessageChange({ target: { value: '' } });
    }
    return this.handleDeprecateMessageChange(event);
  };

  handleDeprecateMessageChange = (event) => {
    this.setState({
      deprecateMessage: event.target.value,
    });
  };

  handleDeprecateDOI = () => {
    const { onInfo, onError, doidataset } = this.props;
    const { deprecateMessage, doi } = this.state;
    const deprecating = doi && doi.state === 'findable';
    this.handleCloseDeprecateDialog();
    return DOIDatasetService.update(doidataset, deprecateMessage)
      .then((payload) => {
        this.setState({ ...payload });
        this.fetchDataFromURL();
        if (deprecating) {
          onInfo(`Successfully created job to deprecate DOI`);
        } else {
          onInfo(`Successfully updated message`);
        }
      })
      .catch(() => {
        onError('Dataset could not be updated!');
      });
  };

  fetchDataFromURL = async () => {
    const { doidataset, queryPid, onError } = this.props;
    if (doidataset) {
      await this.fetchDatasets(this.getParameter(doidataset)).catch((error) =>
        onError(`Failed to load metadata for DOI: ${doidataset}. ${error}`)
      );
      this.setTextField(doidataset);
    } else if (queryPid) {
      await this.fetchDatasets(this.getParameter(queryPid)).catch((error) =>
        onError(`Failed to load metadata for query: ${queryPid}. ${error}`)
      );
      this.setTextField(queryPid);
    } else {
      this.setState({ doidata: undefined });
      this.setTextField('');
    }
  };

  renderTombstoneAlert() {
    const { doi } = this.state;
    if (doi && doi.state === 'registered') {
      return (
        <TombstoneAlert
          message={doi.deprecateMessage}
          onEdit={this.handleOpenDeprecateDialog}
        />
      );
    }
    return null;
  }

  renderDataset() {
    const { classes, onError } = this.props;
    const { organizations, doidata } = this.state;

    if (doidata) {
      return (
        <Panel>
          {this.renderTombstoneAlert()}
          <Grid container className={classes.gridContainer} spacing={4}>
            <Grid item className={classes.gridItem} xs={12} lg={6}>
              <DOIDataFields
                doidata={doidata}
                organizations={organizations}
                onError={onError}
              />
            </Grid>
            <Grid item className={classes.gridItem} xs={12} lg={6}>
              {this.renderQuery()}
              {this.renderCitation()}
              {this.renderDataLinks()}
              {this.renderRelatedDevicesTable()}
              {this.renderHistoryTable()}
            </Grid>
          </Grid>
        </Panel>
      );
    }
    return null;
  }

  renderRelatedDevicesTable() {
    const { deviceDetails, devices, doi } = this.state;

    // only render for cast DOIs
    if (doi.resourceTypeId !== 2700) {
      return null;
    }

    return (
      <RelatedDevicesTable primaryDevice={deviceDetails} devices={devices} />
    );
  }

  renderDataLinks() {
    const { doiDataSearchURL, deviceDetails, doi } = this.state;
    return (
      <DOIDataLinks
        isForSubset={doi.resourceTypeId === 2700}
        doiDataSearchURL={doiDataSearchURL}
        deviceDetails={deviceDetails}
      />
    );
  }

  renderHistoryTable() {
    const { match, doidataset } = this.props;
    const { doiHistory } = this.state;
    return doiHistory ? (
      <DOIHistoryTable
        match={match}
        data={doiHistory}
        currentDOI={doidataset}
      />
    ) : null;
  }

  renderQuery() {
    const { querydata, formFields } = this.state;
    return <DOIQueryFields querydata={querydata} formFields={formFields} />;
  }

  renderCitation() {
    const { doicitation, querycitation } = this.state;
    if (doicitation || querycitation) {
      return (
        <DOICitation doicitation={doicitation} querycitation={querycitation} />
      );
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    const {
      textFieldValue,
      showDeprecateDialog,
      deprecateMessage,
      reason,
      doi,
    } = this.state;

    const dialogContent =
      doi && doi.state === 'findable' ? 'Deprecate Dataset' : 'Update Message';

    return (
      <>
        <DataSetToolbar
          handleSearch={this.handleSearch}
          handleTextField={this.handleTextField}
          handleDeprecateButton={this.handleOpenDeprecateDialog}
          textFieldValue={textFieldValue}
          doi={doi}
        />
        <DeprecateDOIDialog
          title={dialogContent}
          confirmButtonContent={dialogContent}
          open={showDeprecateDialog}
          onClose={this.handleCloseDeprecateDialog}
          onSubmit={this.handleDeprecateDOI}
          message={deprecateMessage}
          reason={reason}
          onReasonChange={this.handleReasonChange}
          onMessageChange={this.handleDeprecateMessageChange}
        />
        <div className={classes.paddingStyle}>{this.renderDataset()}</div>
      </>
    );
  }
}
export default withStyles(styles)(withSnackbars(DatasetLandingPage));
