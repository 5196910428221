import { createTheme } from '@mui/material/styles';
import oceans3Theme from './oceans3Theme';

const oceans3ThemeDark = createTheme({
  ...oceans3Theme,
  palette: {
    mode: 'dark',
    primary: {
      /**
       * The 200 tone of our oceans 3 blue
       * (https://m2.material.io/inline-tools/color/) and as recommended by
       * https://m2.material.io/design/color/dark-theme.html#ui-application
       */
      main: '#7fd5ee',
    },
  },
});

export default oceans3ThemeDark;
