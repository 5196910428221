import { useCallback, useEffect } from 'react';
import {
  useDashboardStateContext,
  useWidgetConfigMapContext,
} from '../context/DashboardStateProvider';

export interface UseWidgetConfigReturn<TConfig> {
  config: TConfig;
  setConfigProperty: <K extends keyof TConfig>(
    key: K,
    value: TConfig[K]
  ) => void;
  setConfig: (newConfig: Partial<TConfig>) => void;
}

/**
 * Returns widget config managed by the dashboard.
 *
 * @template TConfig - Widget config type.
 * @param widgetId - Unique widget ID.
 * @param [initialConfig] - Optional starting config.
 * @returns
 *
 *   - Config: Merged config (dashboard overrides widget values),
 *   - WidgetConfig: The original widget config,
 *   - SetConfig: Function to update entire config,
 *   - SetConfigProperty: Function to update a single property.
 */
const useWidgetConfig = <TConfig = any,>(
  widgetId: string,
  initialConfig?: TConfig
) => {
  const { dashboardState } = useDashboardStateContext();
  const { dispatch, widgetConfigMap } = useWidgetConfigMapContext();

  useEffect(() => {
    dispatch({
      type: 'SET_WIDGET_CONFIG',
      widgetId,
      config: initialConfig,
    });
    return () => {
      dispatch({ type: 'DELETE_WIDGET_CONFIG', widgetId });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const widgetConfig = widgetConfigMap.get(widgetId) || initialConfig;

  const config: TConfig = { ...widgetConfig, ...dashboardState };

  const setConfig = useCallback(
    (newConfig: TConfig) => {
      dispatch({
        type: 'SET_WIDGET_CONFIG',
        widgetId,
        config: newConfig,
      });
    },
    [dispatch, widgetId]
  );

  const setConfigProperty = useCallback(
    <K extends keyof TConfig>(key: K, value: TConfig[K]) => {
      dispatch({
        type: 'SET_WIDGET_CONFIG_PROPERTY',
        widgetId,
        key,
        value,
      });
    },
    [dispatch, widgetId]
  );

  return {
    config,
    widgetConfig,
    setConfigProperty,
    setConfig,
  };
};

export default useWidgetConfig;
