import moment from 'moment';
import { DeploymentDataSource } from '../types/ChartWidgetConfig.types';

export const getDeploymentIdentifier = (
  source: DeploymentDataSource
): string => {
  if (source.deployment) {
    const { deployment } = source;

    return `${source.device?.name} - ${source.sensor?.sensorName} (${moment.utc(deployment.dateFrom).format('DD-MMM-YYYY')} to ${deployment.dateTo ? moment.utc(deployment.dateTo).format('DD-MMM-YYYY') : 'current'})`;
  }
  return `${source.device?.name} - ${source.sensor?.sensorName}`;
};

export const getDeploymentLabel = (source: DeploymentDataSource): string => {
  if (source.deployment) {
    const { deployment } = source;

    return `${source.device?.name} - ${deployment.locationName} (${moment.utc(deployment.dateFrom).format('DD-MMM-YYYY')} to ${deployment.dateTo ? moment.utc(deployment.dateTo).format('DD-MMM-YYYY') : 'current'})`;
  }
  return source.device?.name;
};
