import _ from 'lodash';
import { GLOBAL_USER } from 'domain/AppComponents/sea-tube/configuration/GlobalConfigConstants';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

export interface SeaTubeConfig {
  configurationId: number;
  configurationCategory: string;
  configurationName: string;
  ownerId: number;
  ownerTypeId: number;
  configurationValue: Partial<AnnotationEntryConfig>; // This can eventually be other types of configs
}

export type AnnotationEntryConfig = {
  worms: {
    resource: string;
    resourceType: number;
  };
  deckLog: {
    resource: string;
    resourceType: number;
  };
  cmecs: {
    resource: string;
    resourceType: number;
  };
  noTaxonomy: {
    resource: string;
    resourceType: number;
  };
  nullableAttributes: boolean;
  keepTaxonomy: boolean;
};

export type OrganizationConfig = {
  annotationEntry: AnnotationEntryConfig;
};

class SeaTubeConfigurationService {
  static SERVICE_NAME = 'SeaTubeConfigurationService';

  /** Gets all SeaTube Configurations */
  static getAll = (): Promise<SeaTubeConfig[]> =>
    get(this.SERVICE_NAME, {}).then((response) =>
      parseDmasAPIResponse(response)
    );

  /**
   * Creates a new Configuration
   *
   * @param {int} ownerId - The Owner Id
   * @param {int} ownerType - The type of owner (either organization or user)
   * @param {string} configurationCategory - The category of the configuration
   * @param {object} configurationValue - The config object containing the
   *   values
   */
  static create = (
    ownerId,
    ownerType,
    configurationCategory,
    configurationValue
  ) =>
    post(this.SERVICE_NAME, {
      operation: 1,
      ownerId,
      ownerType,
      configurationCategory,
      configurationValue: JSON.stringify(configurationValue),
    }).then((response) => parseDmasAPIResponse(response));

  /**
   * Updates an existing category
   *
   * @param {int} configurationId - The configurationId
   * @param {object} configurationValue - The value object
   */
  static update = (configurationId, configurationValue) =>
    post(this.SERVICE_NAME, {
      operation: 2,
      configurationId,
      configurationValue: JSON.stringify(configurationValue),
    }).then((response) => parseDmasAPIResponse(response));

  /**
   * Gets the final resource configuration for the given organization and/or
   * user
   *
   * @param {number} organizationId
   * @returns {object} The final config, where organization overrides global,
   */
  static getOrganizationConfig = async (
    organizationId: number
  ): Promise<OrganizationConfig> => {
    const allConfigs = await this.getAll();

    const annotationEntryConfig = this.getCategoryConfig(
      organizationId,
      'resource',
      allConfigs
    );

    return {
      annotationEntry: annotationEntryConfig,
    } as OrganizationConfig;
  };

  static getCategoryConfig = (
    organizationId: number,
    category: string,
    configs: SeaTubeConfig[]
  ) => {
    const categoryConfigs: SeaTubeConfig[] = configs.filter(
      (config) => config.configurationCategory === category
    );
    const globalConfig = categoryConfigs.find(
      (config) =>
        config.ownerTypeId === SeaTubeResourceTypes.DMAS_USER &&
        config.ownerId === GLOBAL_USER.ID
    );
    const orgConfig = categoryConfigs.find(
      (config) =>
        config.ownerTypeId === SeaTubeResourceTypes.ORGANIZATION &&
        config.ownerId === organizationId
    );

    return _.merge(
      {},
      globalConfig?.configurationValue || {},
      orgConfig?.configurationValue || {}
    );
  };
}

export default SeaTubeConfigurationService;
