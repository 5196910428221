import { useState, useCallback, useEffect } from 'react';
import { Box, Collapse } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import { LinearProgress } from 'base-components';
import ChartUtils, {
  LEGEND_MAPPING,
  LEGEND_TITLE,
} from 'domain/AppComponents/charts/ChartUtils';
import { ChartWidgetRawCleanRadioGroup } from 'domain/AppComponents/Form/Fields/RadioGroups';
import useDateRange, { DateRange } from 'domain/hooks/useDateRange';
import TimeSeriesChart, {
  type OnDataSelectionChange,
} from 'library/BaseComponents/time-series-charts/TimeSeriesChart';
import Legend from 'library/CompositeComponents/chart/Legend';
import useScalarDataTraces from './hooks/useScalarDataTraces';
import type {
  ChartQuality,
  ScalarDataChartDeploymentSource,
  ScalarDataChartLocationSource,
  YAxisRanges,
} from 'domain/AppComponents/charts/types/TimeSeriesScalarDataChart.types';

const AUTO_REFRESH_INTERVAL_MILLIS = 30 * 1000;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    loadingBarDiv: {
      height: theme.spacing(0.5), // loading bar is 4px wide
    },
    radio: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  })
);

type TimeSeriesScalarDataChartProps = {
  autoRefresh?: boolean;
  autoRefreshIntervalMilliseconds?: number;
  initialDateRange: DateRange;
  alwaysShowModebar: boolean;
  isMinMaxEnabled: boolean;
  isRawCleanEnabled: boolean;
  sources: Array<
    ScalarDataChartDeploymentSource | ScalarDataChartLocationSource
  >;
  title: string;
  displayQaqc?: boolean;
  leftmin?: number;
  leftmax?: number;
  rightmin?: number;
  rightmax?: number;
  onSettingsClick?: () => void;
  onRemoveClick?: () => void;
  onChangeDateRange?: (range: DateRange) => void;
};

const TimeSeriesScalarDataChart = ({
  autoRefresh = false,
  autoRefreshIntervalMilliseconds = AUTO_REFRESH_INTERVAL_MILLIS,
  initialDateRange,
  alwaysShowModebar,
  isMinMaxEnabled,
  isRawCleanEnabled,
  sources,
  title,
  displayQaqc = false,
  leftmin = undefined,
  leftmax = undefined,
  rightmin = undefined,
  rightmax = undefined,
  onSettingsClick = undefined,
  onRemoveClick = undefined,
  onChangeDateRange = undefined,
}: TimeSeriesScalarDataChartProps) => {
  const classes = useStyles();
  const {
    dateRange,
    setDateRange,
    shiftDateRangeAhead,
    shiftDateRangeBack,
    refreshing,
  } = useDateRange(
    initialDateRange,
    autoRefresh,
    autoRefreshIntervalMilliseconds
  );
  const effectiveSetDateRange = onChangeDateRange ?? setDateRange;

  const [chartWidth, setChartWidth] = useState(100);

  const [chartQuality, setChartQuality] = useState<ChartQuality | undefined>(
    'clean'
  );
  const [showLegend, setShowLegend] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [yAxisRange, setYAxisRange] = useState<YAxisRanges>({
    yAxisLeft: [leftmin ?? null, leftmax ?? null],
    yAxisRight: [rightmin ?? null, rightmax ?? null],
  });

  useEffect(() => {
    // Necessary to update yAxisRange when the config limits change
    setYAxisRange({
      yAxisLeft: [leftmin, leftmax],
      yAxisRight: [rightmin, rightmax],
    });
  }, [leftmin, leftmax, rightmin, rightmax]);

  const toggleLegend = useCallback(() => {
    setShowLegend((prev) => !prev);
  }, []);

  const { traceList, devices, isLoading } = useScalarDataTraces(
    sources,
    chartWidth,
    dateRange,
    isMinMaxEnabled,
    chartQuality
  );

  const onDataSelectionChange: OnDataSelectionChange = (action, axesRanges) => {
    if (action === 'autoscale') {
      // Set range to dynamic limits
      setYAxisRange(null);
    } else if (action === 'reset') {
      effectiveSetDateRange(initialDateRange);
      // Return to configured limits if set
      setYAxisRange({
        yAxisLeft: [leftmin || null, leftmax || null],
        yAxisRight: [rightmin || null, rightmax || null],
      });
    } else if (action === 'daterangechange') {
      // Set range to plotly's limits
      effectiveSetDateRange([
        moment.utc(axesRanges.xaxis[0]),
        moment.utc(axesRanges.xaxis[1]),
      ]);
      setYAxisRange({
        yAxisLeft: axesRanges?.leftYaxis,
        yAxisRight: axesRanges?.rightYaxis,
      });
    }
  };

  const handleUnsummarizedZoom = () => {
    if (devices.length === 0) return;
    const dataRating = ChartUtils.getDataRating(devices);
    const unsumarrizedDateRange = ChartUtils.getUnsummarizedDateRange(
      dateRange,
      chartWidth,
      dataRating
    );

    effectiveSetDateRange(unsumarrizedDateRange);
  };

  const leftYaxisTitle = Array.from(
    new Set(
      traceList
        .filter((trace) => trace.yaxisSide === 'left')
        .map((trace) => trace.unitOfMeasure)
    )
  ).join(', ');

  const rightYaxisTitle = Array.from(
    new Set(
      traceList
        .filter((trace) => trace.yaxisSide === 'right')
        .map((trace) => trace.unitOfMeasure)
    )
  ).join(', ');

  const initializeChartWidth = useCallback(
    (el: HTMLElement | null) => {
      if (el) {
        setChartWidth(el.offsetWidth);
      }
    },
    [setChartWidth]
  );

  return (
    <>
      <div className={classes.loadingBarDiv}>
        {isLoading && <LinearProgress />}
      </div>
      <>
        <div className={classes.radio}>
          {isRawCleanEnabled ? (
            <ChartWidgetRawCleanRadioGroup
              value={chartQuality}
              onChange={(e) => setChartQuality(e.target.value)}
            />
          ) : null}
        </div>
        <Box display="flex" className={classes.root} ref={initializeChartWidth}>
          <Collapse
            in={showLegend}
            orientation="horizontal"
            onEntered={() => setUpdateFlag((prev) => !prev)}
            onExited={() => setUpdateFlag((prev) => !prev)}
          >
            <Legend
              items={LEGEND_MAPPING}
              width={65}
              title={LEGEND_TITLE}
              showBorder
            />
          </Collapse>
          <TimeSeriesChart
            key={String(updateFlag)}
            showMinMax={isMinMaxEnabled || chartQuality === 'raw'}
            subChart={{
              title,
              traces: traceList,
            }}
            alwaysShowModebar={alwaysShowModebar}
            leftYaxisTitle={leftYaxisTitle}
            rightYaxisTitle={rightYaxisTitle}
            resamplePeriod={
              traceList[0]?.resamplePeriod
                ? Number(traceList[0]?.resamplePeriod)
                : undefined
            }
            dateRange={[
              dateRange[0] ? dateRange[0].format() : '',
              dateRange[1] ? dateRange[1].format() : '',
            ]}
            onDataSelectionChange={onDataSelectionChange}
            shiftDateRangeAhead={
              onChangeDateRange ? undefined : shiftDateRangeAhead
            }
            shiftDateRangeBack={
              onChangeDateRange ? undefined : shiftDateRangeBack
            }
            showRefreshingIndicator={refreshing}
            handleUnsummarizedZoom={handleUnsummarizedZoom}
            showLegend={showLegend}
            toggleLegend={toggleLegend}
            displayQaqc={displayQaqc}
            chartQuality={chartQuality}
            onSettingsClick={onSettingsClick}
            onRemoveClick={onRemoveClick}
            yAxisRange={yAxisRange}
          />
        </Box>
      </>
    </>
  );
};

export default TimeSeriesScalarDataChart;
