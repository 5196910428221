/* eslint-disable no-console */
import { useEffect, useState, useCallback, useRef } from 'react';
import BroadcastChannelType from 'domain/Widgets/BroadcastChannel';

const ON_LOAD = 'REQUEST_ON_LOAD';

export default function useBroadcast<T>(
  windowId: string,
  broadcastType: BroadcastChannelType,
  initialState: any,
  source?: string,
  validSources?: string[]
): [state: T, setState: (value: T) => void] {
  const channelName = `${windowId}-${broadcastType}`;
  const [state, setStateVal] = useState<T>(initialState);

  // Use useRef to keep the BroadcastChannel instance persistent across re-renders
  const channelRef = useRef<BroadcastChannel | null>(
    new BroadcastChannel(channelName)
  );

  // Function to send messages via the BroadcastChannel
  const sendMessage = useCallback(
    (value: any) => {
      if (channelRef.current) {
        try {
          channelRef.current.postMessage({ value, source });
        } catch (error) {
          console.error('Failed to post message:', error);
        }
      }
    },
    [source] // Re-create sendMessage callback if source changes
  );

  // Request data from other tabs upon component mount
  useEffect(() => {
    sendMessage(ON_LOAD);
  }, [sendMessage]);

  // Handle incoming messages from the BroadcastChannel
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { data } = event;
      const { value, source: messageSource } = data;

      // Ignore messages from invalid sources
      if (validSources && !validSources.includes(messageSource)) {
        return;
      }

      // If the message is a load request, respond with the current state
      if (value === ON_LOAD) {
        if (source !== messageSource) {
          sendMessage(state);
        }
        return;
      }

      // Update the state with the received value
      setStateVal(value);
    };

    const currentChannel = channelRef.current;
    // Add event listener for incoming messages
    currentChannel?.addEventListener &&
      currentChannel.addEventListener('message', handleMessage);

    // Cleanup event listener on component unmount
    return () => {
      currentChannel?.removeEventListener &&
        currentChannel?.removeEventListener('message', handleMessage);
    };
  }, [validSources, sendMessage, state, source]);

  // Function to set state and broadcast the new state to other tabs
  const setState = useCallback(
    (value: T) => {
      try {
        sendMessage(value);
      } catch (error) {
        console.error(error);
      }
      setStateVal(value);
    },
    [sendMessage]
  );

  // Cleanup BroadcastChannel on component unmount
  useEffect(
    () => () => {
      if (channelRef.current) {
        channelRef.current.close();
        channelRef.current = null; // Ensure we don't try to use a closed channel
      }
    },
    []
  );

  return [state, setState];
}
