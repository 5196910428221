import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Error } from '@onc/icons';
import { Typography } from 'base-components';
import VideoPlayer from 'domain/AppComponents/video/VideoPlayer';
import QueryParameterContext, {
  QueryParameters,
} from 'domain/Apps/menu/QueryParameterContext';
import { SeaTubeResolution } from 'domain/services/SeaTubeVideoService';
import SeamlessVideo from 'library/CompositeComponents/video/SeamlessVideo';
import { SeamlessVideoFile } from 'library/CompositeComponents/video/SeamlessVideoUtil';
import Environment from 'util/Environment';
import SeaTubeLogContext from '../SeaTubeLogContext';

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  emptyText: {
    color: theme.palette.text.disabled,
  },
  videoPlayer: {
    height: '100%',
    overflow: 'hidden',
  },
}));

type DiveVideoPlayerProps = {
  isLive: boolean;
  currentQuality: string;
  qualityOptions: SeaTubeResolution[];
  files?: SeamlessVideoFile[];
  liveStreamUrl?: string;
  seekToTimestamp?: string;
  startDate?: Moment;
  onProgress: (timestamp: Moment) => void;
  onChangeQuality: (quality: string) => void;
  onSeekComplete: () => void;
  currentTimestamp: Moment;
};

const DiveVideoPlayer: React.FC<DiveVideoPlayerProps> = ({
  isLive,
  qualityOptions,
  currentQuality,
  files = undefined,
  liveStreamUrl = undefined,
  seekToTimestamp = undefined,
  startDate = undefined,
  onProgress,
  onChangeQuality,
  onSeekComplete,
  currentTimestamp,
}: DiveVideoPlayerProps) => {
  const { dive } = useContext(SeaTubeLogContext);
  const { diveId } = dive;
  const prevSeekTo = useRef(seekToTimestamp);
  const [seekTo, setSeekTo] = useState<Moment | null>(null);
  const { time: urlTimestamp, isLiveMode } = useContext<QueryParameters>(
    QueryParameterContext
  );

  const classes = useStyles();
  const prevFilesRef = useRef<SeamlessVideoFile[] | undefined>(files);

  const memoizedFiles = useMemo(() => {
    if (_.isEqual(files, prevFilesRef.current)) {
      return prevFilesRef.current;
    }
    prevFilesRef.current = files;
    return files;
  }, [files]);

  useEffect(() => {
    if (urlTimestamp) {
      setSeekTo(moment(urlTimestamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ'));
    }
    if (seekToTimestamp && seekToTimestamp !== prevSeekTo.current) {
      setSeekTo(moment.utc(seekToTimestamp));
    }
    prevSeekTo.current = seekToTimestamp;
  }, [seekToTimestamp, urlTimestamp]);

  if (isLiveMode) {
    return (
      <VideoPlayer
        playerId="live-dive-player"
        isLive={isLive}
        liveStreamUrl={liveStreamUrl}
        // empty onTime function to reduce errors for now
        onTime={() => {}}
        classes={{ root: classes.videoPlayer }}
      />
    );
  }

  if (!memoizedFiles || memoizedFiles.length === 0) {
    return (
      <div className={classes.emptyContainer}>
        <Error color="disabled" fontSize="large" />
        <Typography variant="h6" className={classes.emptyText}>
          No Video Found
        </Typography>
      </div>
    );
  }

  return (
    <SeamlessVideo
      id="historical-dive-player"
      startDate={startDate}
      files={memoizedFiles}
      onProgress={onProgress}
      seekToDate={seekTo}
      qualityOptions={qualityOptions}
      onChangeQuality={onChangeQuality}
      currentQuality={currentQuality}
      shareUrl={`${Environment.getLinkUrl()}/app/dive-logs/${diveId}?time=${currentTimestamp.format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`}
      onSeekComplete={onSeekComplete}
    />
  );
};

export default DiveVideoPlayer;
