import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class ActionDropdown extends Component {
  static propTypes = {
    validation: PropTypes.func,
    title: PropTypes.string.isRequired,
    initialValue: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    prependOptions: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    validation: () => {},
    disabled: false,
    onChange: this.handleActionChange,
    initialValue: undefined,
    prependOptions: [],
  };

  sortActions = (a, b) => {
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  };

  state = {
    actions: [],
  };

  componentDidMount() {
    this.getSites();
  }

  getSites = () => {
    const { prependOptions } = this.props;
    get('ActionListingService')
      .then((response) => parseDmasAPIResponse(response))
      .then((payload) => {
        const actionList = payload.map(({ actionName, actionId }) => ({
          label: actionName,
          value: actionId,
        }));

        actionList.sort(this.sortActions);

        this.setState({
          actions: prependOptions.concat(actionList),
        });
      });
  };

  render() {
    const { title, initialValue, validation, disabled } = this.props;
    const { actions } = this.state;
    return (
      <Dropdown
        label="Action Name"
        options={actions}
        title={title}
        initialValue={initialValue}
        validation={validation}
        disabled={disabled}
        {...this.props}
      />
    );
  }
}
export default ActionDropdown;
