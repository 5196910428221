import {
  createElementObject,
  createLayerComponent,
  extendContext,
} from '@react-leaflet/core';
import * as ESRI from 'esri-leaflet';

const createLeafletElement = (props, context) => {
  const ImageLayer = ESRI.imageMapLayer({ url: props.url });
  return createElementObject(
    ImageLayer,
    extendContext(context, { overlayContainer: ImageLayer })
  );
};

const updateLeafletElement = (instance, props, prevProps) => {
  if (props.url !== prevProps.url) {
    instance.setUrl(props.url);
  }
  if (props.opacity !== prevProps.opacity) {
    instance.setOpacity(props.opacity);
  }
  if (props.zIndex !== prevProps.zIndex) {
    instance.setZIndex(props.zIndex);
  }
};

export default createLayerComponent(createLeafletElement, updateLeafletElement);
