import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const IMAGE_WIDTH = 1200;
const IMAGE_HEIGHT = 900;

const SPECTROGRAM_WIDTH = 120;
const SPECTROGRAM_HEIGHT = 740;

const X_OFFSET = 1044;
const Y_OFFSET = -75;

const HI_RES_SPECTROGRAM_WIDTH = 106;
const HI_RES_SPECTROGRAM_HEIGHT = 723;

const HI_RES_X_OFFSET = 1047;
const HI_RES_Y_OFFSET = -93;

const HI_RES_IMAGE_WIDTH = 3200;
const HI_RES_IMAGE_HEIGHT = 2400;

const ADCP_PLOT_WIDTH = 300;
const ADCP_PLOT_HEIGHT = 2030;

const ADCP_X_OFFSET = 2830;
const ADCP_Y_OFFSET = -205;

const styles = (theme) => ({
  root: {
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    display: 'flex',
  },
  imageForDashboard: {
    position: 'absolute',
    clipPath: 'inset(0% 0% 8% 87.4%)',
    transform: 'translate(-87%, 0%)',
    height: '100%',
    width: 'auto',
    maxHeight: `${IMAGE_HEIGHT}px`,
    maxWidth: `${IMAGE_WIDTH}px`,
  },
  image: {
    position: 'relative',
  },
  imageContainer: {
    overflow: 'hidden',
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    display: 'inline-block',
  },
  imageContainerForDashboard: {
    overflow: 'hidden',
    '& .menu-item-wrapper.active': {
      border: 'none',
    },
    '& .menu-item-wrapper:focus': {
      outline: 'none',
    },
    height: `90%`,
    display: 'inline-block',
    position: 'relative',
  },
});

const SpectraScale = ({
  classes = undefined,
  src,
  deviceCategoryCode,
  timeRange = '5',
  height = SPECTROGRAM_HEIGHT,
  width = SPECTROGRAM_WIDTH,
  alt = 'Missing',
  isDashboard = false,
}) => {
  SpectraScale.propTypes = {
    src: PropTypes.string.isRequired,
    deviceCategoryCode: PropTypes.string.isRequired,
    timeRange: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    alt: PropTypes.string,
    isDashboard: PropTypes.bool,
    classes: PropTypes.shape({
      root: PropTypes.string,
      image: PropTypes.string,
      imageContainer: PropTypes.string,
    }),
  };

  const calculateXOffset = () => {
    let imageWidth;
    let offset;
    if (deviceCategoryCode === 'HYDROPHONE') {
      if (timeRange === '5') {
        imageWidth = SPECTROGRAM_WIDTH;
        offset = X_OFFSET;
      } else {
        imageWidth = HI_RES_SPECTROGRAM_WIDTH;
        offset = HI_RES_X_OFFSET;
      }
    } else {
      imageWidth = ADCP_PLOT_WIDTH;
      offset = ADCP_X_OFFSET;
    }
    return Math.floor((width / imageWidth) * offset);
  };

  const calculateYOffset = () => {
    let imageHeight;
    let offset;
    if (deviceCategoryCode === 'HYDROPHONE') {
      if (timeRange === '5') {
        imageHeight = SPECTROGRAM_HEIGHT;
        offset = Y_OFFSET;
      } else {
        imageHeight = HI_RES_SPECTROGRAM_HEIGHT;
        offset = HI_RES_Y_OFFSET;
      }
    } else {
      imageHeight = ADCP_PLOT_HEIGHT;
      offset = ADCP_Y_OFFSET;
    }
    return Math.floor((height / imageHeight) * offset);
  };

  const calculateImageWidth = () => {
    let imageWidth;
    let plotWidth;
    if (deviceCategoryCode === 'HYDROPHONE') {
      if (timeRange === '5') {
        imageWidth = IMAGE_WIDTH;
        plotWidth = SPECTROGRAM_WIDTH;
      } else {
        imageWidth = IMAGE_WIDTH;
        plotWidth = HI_RES_SPECTROGRAM_WIDTH;
      }
    } else {
      imageWidth = HI_RES_IMAGE_WIDTH;
      plotWidth = ADCP_PLOT_WIDTH;
    }
    return Math.floor((width / plotWidth) * imageWidth);
  };

  const calculateImageHeight = () => {
    let imageHeight;
    let plotHeight;
    if (deviceCategoryCode === 'HYDROPHONE') {
      if (timeRange === '5') {
        imageHeight = IMAGE_HEIGHT;
        plotHeight = SPECTROGRAM_HEIGHT;
      } else {
        imageHeight = IMAGE_HEIGHT;
        plotHeight = HI_RES_SPECTROGRAM_HEIGHT;
      }
    } else {
      imageHeight = HI_RES_IMAGE_HEIGHT;
      plotHeight = ADCP_PLOT_HEIGHT;
    }
    return Math.floor((height / plotHeight) * imageHeight);
  };

  if (isDashboard === true) {
    return (
      <div
        className={classes.root}
        style={{
          paddingRight: `0px`,
          paddingLeft: `0px`,
        }}
      >
        <div
          className={classes.imageContainerForDashboard}
          style={{
            width: `calc(${width}px - 25px)`,
          }}
          id="rightAxis"
        >
          <img
            src={src}
            alt={alt}
            className={classes.imageForDashboard}
            draggable={false}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div
        className={classes.imageContainer}
        style={{ height: `${height}px`, width: `${width}px` }}
        id="rightAxis"
      >
        <img
          src={src}
          alt={alt}
          className={classes.image}
          style={{
            right: `${calculateXOffset()}px`,
            top: `${calculateYOffset()}px`,
          }}
          height={calculateImageHeight()}
          width={calculateImageWidth()}
          draggable={false}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(SpectraScale);
