import { useCallback, useEffect, useMemo } from 'react';
import { Moment } from 'moment';
import {
  type DateRangeState,
  PresetDateRange,
  useDateRangeCore,
} from 'domain/hooks/useDateRangeCore';
import useDashboardState from 'library/CompositeComponents/dashboard/hooks/useDashboardState';
import useWidgetDateRange from './useWidgetDateRange';
import type { DataViewerDashboardConfigType } from '../config/DataViewerDashboardConfig';

export type DateRange = [Moment, Moment];

const useDashboardDateRange = () => {
  const { dashboardState, setDashboardState } =
    useDashboardState<DataViewerDashboardConfigType>();

  const preset = useMemo(
    () => dashboardState?.preset,
    [dashboardState?.preset]
  );

  const dateRange = useMemo(
    (): DateRange => [dashboardState?.startDate, dashboardState?.endDate],
    [dashboardState?.startDate, dashboardState?.endDate]
  );

  const handleStateChange = useCallback(
    (newState: DateRangeState) => {
      setDashboardState({
        ...dashboardState,
        startDate: newState.dateRange[0],
        endDate: newState.dateRange[1],
        preset: newState.preset || 'custom',
      });
    },
    [dashboardState, setDashboardState]
  );

  const dateRangeOps = useDateRangeCore(
    dateRange,
    handleStateChange,
    dashboardState?.preset
  );

  const setPreset = useCallback(
    (newPreset: PresetDateRange) => {
      setDashboardState({
        ...dashboardState,
        preset: newPreset,
      });
    },
    [dashboardState, setDashboardState]
  );

  const dataDateRange = useWidgetDateRange();

  useEffect(() => {
    if (preset === 'dataRange') {
      dateRangeOps.setDateRange(dataDateRange, 'dataRange');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDateRange, preset]);

  // Return the current dateRange and the functions to manage it.
  return {
    ...dateRangeOps,
    preset,
    setPreset,
  };
};

export default useDashboardDateRange;
