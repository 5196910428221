import { post, get } from 'util/WebRequest';

class DeviceSyncService {
  static get = () =>
    get('DeviceSyncService').then(
      (payload) => payload.data.simpleEventViewList
    );

  static post = (payload) =>
    post('DeviceSyncService', {
      eventId: payload.eventId,
      masterDeviceId: payload.leadDeviceId,
      slaveDeviceId: payload.followDeviceId,
      enabled: payload.enabled === true ? 'Y' : 'N',
      triggerDelay: payload.delay,
      dateFrom: payload.dateFrom,
    });
}

export default DeviceSyncService;
