import { memo, useCallback, useEffect, useState } from 'react';
import { Table, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { Paper } from 'base-components';
import BatchTaskTableContainer from 'domain/AppComponents/batch/batch-task-table/BatchTaskTableContainer';
import { BatchTaskServiceResponse } from 'domain/AppComponents/batch/types/BatchTaskServiceResponse';
import {
  BATCH_TASK_TABLE_COLUMN_FORMATTER,
  BATCH_TASK_TABLE_COLUMNS,
} from 'domain/AppComponents/batch/util/BatchUtils';
import { SortingProps } from 'library/BaseComponents/table/plugins/ColumnSorting';
import { PagingProps } from 'library/BaseComponents/table/plugins/Pagination';
import StatelessTable from 'library/BaseComponents/table/StatelessTable';

const ToggleCellComponent = ({ row, ...restProps }: any) => {
  if (row.hasChildren) {
    return (
      <TableRowDetail.ToggleCell
        row={row}
        {...restProps}
        aria-label="expand-row"
      />
    );
  }
  return <Table.Cell row={row} {...restProps} />;
};

const BatchTaskTable = ({
  data,
  paging,
  sorting,
  shouldPage,
}: {
  data: BatchTaskServiceResponse;
  paging: PagingProps;
  sorting: SortingProps;
  shouldPage: boolean;
}) => {
  const RowDetail = useCallback(
    ({ row }) => <BatchTaskTableContainer selectedBatch={row} />,
    []
  );

  const [expandedRows, setExpandedRows] = useState<(string | number)[]>([]);

  useEffect(() => {
    setExpandedRows([]);
  }, [data]);

  return (
    <Paper>
      <StatelessTable
        rows={data?.childTasks ?? []}
        getRowId={(row) => row.taskId}
        columns={BATCH_TASK_TABLE_COLUMNS}
        paging={shouldPage ? paging : undefined}
        showNumberOfRecords={false}
        sort={sorting}
        expandRow={{
          ToggleCellComponent,
          expandedRows,
          RowDetail,
          handleExpandRowChange: (expandedRowIds) => {
            setExpandedRows(expandedRowIds);
          },
        }}
        columnFormatProviders={BATCH_TASK_TABLE_COLUMN_FORMATTER}
      />
    </Paper>
  );
};

export default memo(BatchTaskTable);
