import { openDeviceDetails } from 'domain/AppComponents/link/DeviceDetailsLink';
import Environment from 'util/Environment';

const redirect = (props) => {
  const { isFromDevice, deviceId, isFromCruise, cruiseId } = props;
  if (isFromDevice && !isNaN(deviceId)) {
    openDeviceDetails(deviceId, 'da_tab');
  } else if (isFromCruise && !isNaN(cruiseId)) {
    global.open(
      `${Environment.getDmasUrl()}/Cruises?cruiseId=${cruiseId}`,
      '_self'
    );
  } else {
    global.open(`${Environment.getDmasUrl()}/DeviceActions`, '_self');
  }
};

const handleRedirect = (props) => () => {
  redirect(props);
};

export { handleRedirect, redirect };
