import * as React from 'react';
import { Column } from '@devexpress/dx-react-grid';
import { Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import { Add, ExpandMore, Delete } from '@onc/icons';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Dropdown,
  Typography,
} from 'base-components';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import {
  TableFilter,
  TableFilterGroup,
  TableFilterLine,
} from './ToolbarFilter';
import ToolbarFilterLine from './ToolbarFilterLine';

type Props = {
  index: number;
  value: TableFilterGroup;
  columns: Column[];
  rows: any;
  filter: TableFilter;
  expanded: boolean;
  classes: any;
  setExpanded: (index: number) => void;
  onChange: (filterLine: TableFilterGroup, index: number) => void;
  onLogicChange: (e: any) => void;
  handleRemoveFilterGroup: (index: number) => void;
};

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    flexItem: {
      margin: theme.spacing(0.5),
    },
    logic: {
      margin: '0px',
      marginRight: theme.spacing(),
      flexBasis: '75px',
      flexGrow: 0,
      flexShrink: 0,
    },
    lessPadding: {
      paddingTop: theme.spacing(),
    },
  });

const emptyFilterLine: TableFilterLine = {
  column: '',
  dataType: 'String',
  operator: '',
  value: '',
};

const logicOptions = [
  {
    key: 'And',
    label: 'And',
    value: 'And',
  },
  {
    key: 'Or',
    label: 'Or',
    value: 'Or',
  },
];

const ToolbarFilterGroup: React.VFC<Props> = ({
  index,
  value,
  columns,
  classes,
  filter,
  expanded,
  rows,
  setExpanded,
  onChange,
  onLogicChange,
  handleRemoveFilterGroup,
}: Props) => {
  const handleAddFilterLine = () => {
    const updatedGroup = { ...value };
    updatedGroup.filterLines.push(emptyFilterLine);
    onChange(updatedGroup, index);
  };

  const handleRemoveFilterLine = (i: number) => {
    const updatedGroup = { ...value };
    updatedGroup.filterLines.splice(i, 1);
    if (updatedGroup.filterLines.length === 0) {
      updatedGroup.filterLines.push(emptyFilterLine);
    }
    onChange(updatedGroup, index);
  };

  const handleLineChange = (filterLine, i: number) => {
    const updatedGroup = { ...value };
    updatedGroup.filterLines[i] = filterLine;
    onChange(updatedGroup, index);
  };

  const handleLineLogicChange = (logic: 'And' | 'Or') => {
    const updatedGroup = { ...value };
    updatedGroup.logic = logic;
    onChange(updatedGroup, index);
  };

  const renderGroupLogic = () => {
    if (index > 0) {
      return (
        <Dropdown
          options={logicOptions}
          name="group-logic"
          onChange={onLogicChange}
          value={filter.logic}
          disabled={index > 1}
          className={classes.logic}
          InputProps={{
            classes: {
              input: classes.lessPadding,
            },
          }}
        />
      );
    }
    if (filter.filterGroups.length > 1) {
      return <div className={classes.logic} />;
    }
    return null;
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(expanded ? -1 : index)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{
          content: classes.flexContainer,
        }}
      >
        {renderGroupLogic()}

        <Typography variant="subtitle1">Filter Group {index + 1}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        {value.filterLines.map((filterLine, i) => (
          <ToolbarFilterLine
            index={i}
            groupIndex={index}
            value={filterLine}
            logic={value.logic}
            columns={columns}
            onChange={handleLineChange}
            handleRemoveFilter={handleRemoveFilterLine}
            onLogicChange={handleLineLogicChange}
            rows={rows}
          />
        ))}
      </AccordionDetails>
      <AccordionActions style={{ justifyContent: 'space-between' }}>
        <TextButton
          translationKey="common.buttons.addFilter"
          startIcon={<Add />}
          onClick={handleAddFilterLine}
        />
        <TextButton
          translationKey="common.buttons.deleteFilterGroup"
          startIcon={<Delete />}
          onClick={() => handleRemoveFilterGroup(index)}
          disabled={filter.filterGroups.length <= 1}
          data-test="delete-group"
        />
      </AccordionActions>
    </Accordion>
  );
};

export default withStyles(styles)(ToolbarFilterGroup);
