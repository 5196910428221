import { parseDmasAPIResponse, post } from 'util/WebRequest';

class AnnotationReviewService {
  static createAnnotationReview = async (
    annotationHdrId: number,
    taxonId: number,
    reviewValue: string | undefined
  ) =>
    post(
      'AnnotationReviewService',
      { operation: 1, annotationHdrId, taxonId, reviewValue },
      undefined
    ).then((response) => parseDmasAPIResponse(response));

  static deleteAnnotationReview = async (annotationReviewId: number) =>
    post(
      'AnnotationReviewService',
      { operation: 3, annotationReviewId },
      undefined
    );

  static updateAnnotationReview = async (
    annotationReviewId: number,
    annotationHdrId: number,
    taxonId: number,
    reviewValue: string | undefined
  ) =>
    post(
      'AnnotationReviewService',
      {
        operation: 2,
        annotationReviewId,
        annotationHdrId,
        taxonId,
        reviewValue,
      },
      undefined
    ).then((response) => parseDmasAPIResponse(response));
}

export default AnnotationReviewService;
