import { createStyles, withStyles } from '@mui/styles';
import { Search } from '@onc/icons';
import {
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
} from 'base-components';

const styles = () =>
  createStyles({
    item: {
      flexGrow: 1,
      overflowY: 'auto',
    },
  });

type SearchableListProps = {
  listItems?: Array<{ key: string | number; name: string }>;
  searchText?: string;
  selectedItem?: string | number;
  handleClickItem?: (item: { key: string | number; name: string }) => void;
  handleSearchInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  actionComponents?: (item: {
    key: string | number;
    name: string;
  }) => React.ReactNode | (() => void);
  classes: { item: string };
  sortFunction?: (a: { name: string }, b: { name: string }) => number;
};
const SearchableList = ({
  handleSearchInput,
  classes,
  handleClickItem = undefined,
  selectedItem = undefined,
  actionComponents = () => null,
  searchText = '',
  listItems = [],
  sortFunction = (a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
}: SearchableListProps) => {
  const renderListItems = () => {
    const filteredList = listItems
      .filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      )
      .sort(sortFunction);

    return filteredList.map((item) => {
      const isSelected = selectedItem && item.key === selectedItem;
      return (
        <ListItem
          button={!!handleClickItem}
          onClick={() => handleClickItem(item)}
          selected={isSelected}
          key={item.key}
        >
          <ListItemText primary={item.name} />
          {isSelected ? actionComponents(item) : null}
        </ListItem>
      );
    });
  };

  return (
    <>
      <TextField
        translationKey="common.search"
        title="searchField"
        fullWidth
        onChange={handleSearchInput}
        value={searchText}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="action" />
            </InputAdornment>
          ),
        }}
      />
      <List className={classes.item} dense>
        {renderListItems()}
      </List>
    </>
  );
};

export default withStyles(styles)(SearchableList);
