import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { Grid, TextField } from 'base-components';
import BatchTaskTable from 'domain/AppComponents/batch/batch-task-table/BatchTaskTable';
import {
  TOP_LEVEL_CHILD_TASKS_QUERY_NAME,
  CHILD_TASK_PLACEHOLDER,
  useTopLevelChildTasksQuery,
} from 'domain/AppComponents/batch/hooks/BatchTaskQueryHooks';
import {
  useBatchFiltering,
  useBatchPaging,
  useBatchSearching,
} from 'domain/AppComponents/batch/hooks/BatchTaskStateHooks';
import { Task } from 'domain/AppComponents/batch/types/Task';
import BatchFilters from 'domain/AppComponents/batch/util/BatchFilters';
import {
  BATCH_TASK_TABLE_SORTING,
  DEFAULT_SELECTED_FILTERS_BATCH_TASK,
  FILTERS_FOR_BATCH_TASKS,
  PAGE_SIZE,
} from 'domain/AppComponents/batch/util/BatchUtils';
import { useSnackbars } from 'util/hooks/useSnackbars';
import BatchNotifications from '../util/BatchNotifications';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBar: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
    },
    table: {
      top: theme.spacing(2),
    },
  })
);

const BatchTaskTableContainer: React.FC<{
  selectedBatch: Task | undefined;
}> = ({ selectedBatch }) => {
  const classes = useStyles();
  const { onError } = useSnackbars();

  const filtering = useBatchFiltering(
    DEFAULT_SELECTED_FILTERS_BATCH_TASK,
    FILTERS_FOR_BATCH_TASKS
  );
  const paging = useBatchPaging(0, selectedBatch?.taskId);

  const [searchValue, setSearchValue] = useBatchSearching(
    TOP_LEVEL_CHILD_TASKS_QUERY_NAME
  );

  const response = useTopLevelChildTasksQuery(
    selectedBatch?.taskId,
    selectedBatch?.shouldPage ? (paging?.currentPage ?? 0) : 0,
    selectedBatch?.shouldPage ? (paging?.pageSize ?? PAGE_SIZE) : 0,
    filtering.selectedFilters,
    BATCH_TASK_TABLE_SORTING.sorting,
    searchValue
  );

  if (response.isError) {
    onError(`Error fetching batch tasks for taskid: ${selectedBatch?.taskId}`);
  }

  const { data } = response;
  paging.totalCount = data?.totalRecords ? data.totalRecords : 0;
  return (
    <>
      <Grid container className={classes.table}>
        <Grid item xs={5}>
          <BatchFilters filterOptions={filtering} />
        </Grid>
        <Grid item xs={4}>
          {/* Only batch groups (only with batchName) should show email notifications, not reprocess, doi, trigger, etc */}
          {selectedBatch && selectedBatch?.input?.batchName ? (
            <BatchNotifications
              taskInput={selectedBatch.input}
              taskId={selectedBatch.taskId}
              key={selectedBatch.taskId}
            />
          ) : null}
        </Grid>
        <Grid item xsOffset="auto" className={classes.searchBar}>
          <TextField
            translationKey="common.search"
            value={searchValue}
            onChange={(event) => setSearchValue(event.currentTarget.value)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BatchTaskTable
          data={data ?? CHILD_TASK_PLACEHOLDER}
          paging={paging}
          sorting={BATCH_TASK_TABLE_SORTING}
          shouldPage={selectedBatch?.shouldPage ?? false}
        />
      </Grid>
    </>
  );
};

export default BatchTaskTableContainer;
