import Environment from 'util/Environment';
import { get } from 'util/WebRequest';
import {
  ZAP_SERVICE,
  ADCP_SERVICE,
  API_DATA_PRODUCT_SERVICE,
  HYDROPHONE_PRODUCT_CODE,
  PERFORM_REQUEST_SERVICE_CALL,
  ERROR_LEVEL,
} from './DataPlayerConstants';
import DataPlayerDeviceService from './DataPlayerDeviceService';
import DataPlayerHelper from './DataPlayerHelper';

class DataPlayerImageService {
  /**
   * @param {number} deviceId
   * @param {number} threshold
   * @param {number} maxBin
   * @param {number} timeRange
   * @param {string} dateStr
   */
  static getZapUrl(deviceId, threshold, maxBin, timeRange, dateStr) {
    let url = `${Environment.getDmasUrl()}/${ZAP_SERVICE}`;
    url += `?deviceid=${deviceId}`;
    url += `&maxbin=${maxBin}`;
    url += `&maxthreshold=${threshold}`;
    url += `&duration=${timeRange}`;
    url += `&starttime=${dateStr}`;
    url += '&minthreshold=0';
    url += '&height=588';
    url += '&width=501';
    url += '&minbin=0';
    return url;
  }

  /**
   * @param {number} deviceId
   * @param {number} threshold
   * @param {number} maxBin
   * @param {number} timeRange
   * @param {Array} dateStrings
   */
  static getZapUrls(deviceId, threshold, maxBin, timeRange, dateStrings) {
    return dateStrings.map((dateStr) =>
      DataPlayerImageService.getZapUrl(
        deviceId,
        threshold,
        maxBin,
        timeRange,
        dateStr
      )
    );
  }

  /**
   * @param {number} deviceId
   * @param {number} timeRange
   * @param {string} dateStr
   */
  static getAdcpUrl(deviceId, timeRange, dateStr) {
    // let url = `${Environment.getDmasUrl()}/${ADCP_SERVICE}`;
    let url = `${Environment.getDmasUrl()}/${ADCP_SERVICE}`;
    url += `?deviceid=${deviceId}`;
    url += `&duration=${timeRange}`;
    url += `&starttime=${dateStr}`;
    url += `&type=velocity_u`;
    url += `&beam=0`;
    url += '&height=588';
    url += '&width=501';
    url += `&vmin=-0.5`;
    url += `&vmax=0.5`;
    url += '&subsample=false';
    return url;
  }

  /**
   * @param {number} deviceId
   * @param {number} timeRange
   * @param {Array} dateStrings
   */
  static getAdcpUrls(deviceId, timeRange, dateStrings) {
    return dateStrings.map((dateStr) =>
      DataPlayerImageService.getAdcpUrl(deviceId, timeRange, dateStr)
    );
  }

  static getAdFilePath = () => `${Environment.getDmasUrl()}/AdFile?filename=`;

  /**
   * Generates a search to produce new temp spectra
   *
   * @param {string} deviceCode
   * @param {moment} startDate
   * @param {moment} endDate
   * @param {object} dataProductOptionsForServiceCall
   * @param {Function} onError
   * @param {string} timeRange
   */
  static async getSpectrogramUrlsByDeviceCodeDateRangeWithFilter(
    deviceCode,
    startDate,
    endDate,
    dataProductOptionsForServiceCall,
    onError,
    timeRange
  ) {
    const paramsForRequest =
      DataPlayerHelper.buildReqParamsForHydrophonePlotRequest(
        deviceCode,
        startDate,
        endDate,
        HYDROPHONE_PRODUCT_CODE,
        dataProductOptionsForServiceCall,
        timeRange
      );
    return this.processDataProductDeliveryRequest(paramsForRequest, onError);
  }

  static async checkDownloadStatus(downloadStatus, onError) {
    const downloadResponse = await this.performDownloadServiceCall(
      downloadStatus,
      onError
    );
    if (downloadResponse.status !== 200 && !downloadResponse.errorType) {
      // check every 4 seconds so we do not DOS DMAS.
      await this.delay(4000);
      return this.checkDownloadStatus(downloadStatus, onError);
    }
    if (downloadResponse.errorType) {
      // an error occoured and was picked up by the error handler in DataPlayerHelper
      return downloadResponse;
    }
    return {
      url: downloadResponse.request.responseURL,
      dateFrom: downloadStatus.dateFrom,
      dateTo: downloadStatus.dateTo,
      key: downloadResponse.request.responseURL,
    };
  }

  /**
   * Generates the ADCP plots by making a Data Product Delivery Service Request
   *
   * @param {number} deviceId
   * @param {moment} startDate
   * @param {moment} endDate
   * @param {object} dataProductOptionsForServiceCall
   * @param {boolean} isRdiDevice
   * @param {string} dataProductCode
   * @param {Function} onError
   */
  static async getAdcpPlots(
    deviceId,
    startDate,
    endDate,
    dataProductOptionsForServiceCall,
    isRdiDevice,
    dataProductCode,
    onError
  ) {
    const deviceCode = await DataPlayerDeviceService.getDeviceCodeForThisDevice(
      deviceId,
      onError
    );
    const reqParams = DataPlayerHelper.buildReqParamsForAdcpPlotRequest(
      deviceCode,
      startDate,
      endDate,
      dataProductCode,
      dataProductOptionsForServiceCall,
      isRdiDevice
    );
    return this.processDataProductDeliveryRequest(reqParams, onError);
  }

  static async processDataProductDeliveryRequest(reqParams, onError) {
    const requestResponse = await this.performRequestServiceCall(
      reqParams,
      onError
    );
    // Checks request response if there is "no data available for the selected type."
    if (requestResponse.errorType) {
      return requestResponse;
    }
    const { dpRequestId } = requestResponse.data;
    const runResponse = await this.performRunServiceCall(dpRequestId, onError);
    if (runResponse.errorType) {
      return runResponse;
    }
    const { dpRunId } = runResponse.data[0];

    const statusResponse = await this.performStatusServiceCall(
      dpRequestId,
      onError
    );
    if (statusResponse.errorType) {
      return statusResponse;
    }
    const { dateFrom, dateTo } = statusResponse.data.newSearches[0];

    return { dpRunId, dateFrom, dateTo, key: dpRunId };
  }

  static async performRequestServiceCall(reqParams, onError) {
    return get(PERFORM_REQUEST_SERVICE_CALL, reqParams).catch((error) =>
      DataPlayerHelper.handleImageRequestAndDownloadError(
        error,
        error.response
          ? ERROR_LEVEL.IMAGE_REQUEST_WITH_ERROR_CODE
          : ERROR_LEVEL.IMAGE_REQUEST_WITHOUT_ERROR_CODE,
        onError
      )
    );
  }

  static async performRunServiceCall(dpRequestId, onError) {
    return get(API_DATA_PRODUCT_SERVICE, {
      method: 'run',
      dpRequestId,
    }).catch((error) =>
      DataPlayerHelper.handleImageRequestAndDownloadError(
        error,
        ERROR_LEVEL.IMAGE_REQUEST_WITHOUT_ERROR_CODE,
        onError
      )
    );
  }

  static async performStatusServiceCall(dpRequestId, onError) {
    return get(API_DATA_PRODUCT_SERVICE, {
      method: 'status',
      dpRequestId,
    }).catch((error) =>
      DataPlayerHelper.handleImageRequestAndDownloadError(
        error,
        ERROR_LEVEL.IMAGE_REQUEST_WITHOUT_ERROR_CODE,
        onError
      )
    );
  }

  static async performDownloadServiceCall(downloadStatus, onError) {
    return get(API_DATA_PRODUCT_SERVICE, {
      method: 'download',
      dpRunId: downloadStatus.dpRunId,
      index: '1',
    }).catch((error) =>
      DataPlayerHelper.handleImageRequestAndDownloadError(
        { downloadStatus, error },
        ERROR_LEVEL.IMAGE_DOWNLOAD,
        onError
      )
    );
  }

  static delay(timeInMilliSeconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeInMilliSeconds);
    });
  }
}

export default DataPlayerImageService;
