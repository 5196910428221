import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { OpenInNew } from '@onc/icons';
import { AppBar, Toolbar } from 'base-components';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import SearchField from 'library/CompositeComponents/SearchField';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';

const styles = (theme) => ({
  buttonContainer: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: theme.spacing(1),
    float: 'right',
  },
  secondaryAppBar: {
    backgroundColor: theme.palette.primary.light,
  },
});

class DataSetToolbar extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      actionButton: PropTypes.string,
      buttonContainer: PropTypes.string,
      secondaryAppBar: PropTypes.string,
    }).isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleTextField: PropTypes.func.isRequired,
    handleDeprecateButton: PropTypes.func.isRequired,
    textFieldValue: PropTypes.string.isRequired,
    doi: PropTypes.shape({ state: PropTypes.string }),
  };

  static defaultProps = {
    doi: undefined,
  };

  renderDeprecateButton = () => {
    const { classes, doi, handleDeprecateButton } = this.props;
    if (Environment.getDmasUserPrivilege() === 'RW') {
      return (
        <TextButton
          onClick={handleDeprecateButton}
          disabled={!doi || doi.state !== 'findable'}
          className={classes.actionButton}
          color="inherit"
          translationKey="common.buttons.deprecate"
        />
      );
    }
    return null;
  };

  render() {
    const { classes, handleSearch, handleTextField, textFieldValue } =
      this.props;
    return (
      <AppBar position="sticky">
        <Toolbar className={classes.secondaryAppBar}>
          <SearchField
            handleSearch={handleSearch}
            handleTextField={handleTextField}
            textFieldValue={textFieldValue}
            placeholderText="DOI or Query PID..."
          />
          <div className={classes.buttonContainer}>
            {this.renderDeprecateButton()}
            <TextButton
              target="_blank"
              href="https://wiki.oceannetworks.ca/display/DP/Data+Citations"
              endIcon={<OpenInNew />}
              className={classes.actionButton}
              color="inherit"
              translationKey="common.buttons.about"
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withSnackbars(DataSetToolbar));
