import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Dialog, DialogContent } from 'base-components';
import { WidgetTitle } from 'domain/AppComponents/Dashboard/Titles';

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '100%',
      maxHeight: '90%',
    },
    // prevents scrolling or distorted aspect ratio on fullscreen
    fullImage: {
      width: 'auto',
      maxHeight: 'calc(90vh - 64px)',
    },
    dialogContent: {
      zIndex: '+10',
    },
  })
);

interface ImageProps {
  imageUrl: string;
  onClick: () => void;
  className: string;
  title: string;
}

const ImageComponent: React.FC<ImageProps> = ({
  imageUrl,
  onClick,
  className,
  title,
}) => (
  <Box onClick={onClick}>
    <img src={imageUrl} alt={imageUrl} className={className} title={title} />
  </Box>
);

type DataPreviewWidgetDisplayProps = {
  imageUrl: string;
  showTitle?: boolean;
  title?: string;
};

const DataPreviewWidgetDisplay: React.FC<DataPreviewWidgetDisplayProps> = ({
  imageUrl,
  showTitle = undefined,
  title = '',
}) => {
  const classes = useStyles();

  const [fullImage, setFullImage] = useState(false);

  const onClose = () => {
    setFullImage(false);
  };

  const enlargeImage = () => {
    setFullImage(true);
  };

  const renderTitle = () =>
    showTitle ? <WidgetTitle titleText={title} /> : null;

  const renderImage = () => {
    if (fullImage) {
      return (
        <Dialog
          open
          maxWidth={false}
          onClose={onClose}
          container={document.getElementById('dashboardLayout')}
        >
          <DialogContent className={classes.dialogContent}>
            <ImageComponent
              imageUrl={imageUrl}
              onClick={onClose}
              className={classes.fullImage}
              title="Click to minimize"
            />
          </DialogContent>
        </Dialog>
      );
    }
    return (
      <ImageComponent
        imageUrl={imageUrl}
        onClick={enlargeImage}
        className={classes.image}
        title="Click to enlarge"
      />
    );
  };

  return (
    <>
      {renderTitle()}
      {renderImage()}
    </>
  );
};

export default DataPreviewWidgetDisplay;
