import { useContext } from 'react';
import SeaTubeDashboard from 'domain/AppComponents/sea-tube/SeaTubeDashboard';
import UserDetailsContext from 'domain/AppComponents/sea-tube/UserDetailsContext';
import WidgetLibrary from 'domain/Widgets/WidgetLibrary';

import { defaultLayout, defaultWidgets } from './layouts';
import EndeavourActivityPlotWidget from '../widgets/EndeavourActivityPlotWidget';
import EndeavourFilterWidget from '../widgets/EndeavourFilterWidget';
import EndeavourLatitudePlotWidget from '../widgets/EndeavourLatitudePlotWidget';
import EndeavourMagnitudePlotWidget from '../widgets/EndeavourMagnitudePlotWidget';
import EndeavourMapWidget from '../widgets/EndeavourMapWidget';
import EndeavourSiteDetectionTableWidget from '../widgets/EndeavourSiteDetectionTableWidget';
import EndeavourTableWidget from '../widgets/EndeavourTableWidget';

const EarthquakeCatalogDashboard: React.VFC = () => {
  const { userId } = useContext(UserDetailsContext);

  const isLoggedIn = userId !== undefined && userId !== -1;
  const userType = isLoggedIn ? 'User' : 'Anonymous';

  const widgetLibrary = new WidgetLibrary([
    {
      Component: EndeavourTableWidget,
      label: 'Earthquake Table',
      multiple: false,
    },
    {
      Component: EndeavourFilterWidget,
      label: 'Earthquake Filters',
      multiple: false,
    },
    {
      Component: EndeavourActivityPlotWidget,
      label: 'Earthquake Activity Plot',
      multiple: false,
    },
    {
      Component: EndeavourMagnitudePlotWidget,
      label: 'Earthquake Magnitude Plot',
      multiple: false,
    },
    {
      Component: EndeavourLatitudePlotWidget,
      label: 'Earthquake Latitude Plot',
      multiple: false,
    },
    {
      Component: EndeavourMapWidget,
      label: 'Earthquake Map',
      multiple: false,
    },
    {
      Component: EndeavourSiteDetectionTableWidget,
      label: 'Earthquake Site Detection Table',
      multiple: false,
    },
  ]);

  return (
    <SeaTubeDashboard
      title="Endeavour Earthquake Catalogue"
      id={crypto.getRandomValues(new Uint32Array(1)).toString()}
      layoutKey={`${userType}-Endeavour-Earthquake-Catalog`}
      widgetLibrary={widgetLibrary}
      defaultLayout={defaultLayout}
      defaultWidgets={defaultWidgets}
    />
  );
};

export default EarthquakeCatalogDashboard;
