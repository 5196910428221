import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  ShowChart,
  Description,
  XmlIcon,
  Bookmarks,
  CsvIcon,
} from '@onc/icons';
import { Checkbox } from 'base-components';

const styles = (theme) => ({
  checkbox: {
    padding: 0,
    color: theme.palette.primary.main,
    'margin-right': '5px',
  },
});

const CheckboxArray = (props) => {
  CheckboxArray.propTypes = {
    onClickPlot: PropTypes.func.isRequired,
    onClickCor: PropTypes.func.isRequired,
    onClickAnnotations: PropTypes.func.isRequired,
    onClickXml: PropTypes.func.isRequired,
    onClickCsv: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      checkbox: PropTypes.string,
    }).isRequired,
  };

  const {
    onClickPlot,
    onClickCor,
    onClickAnnotations,
    onClickXml,
    onClickCsv,
    classes,
  } = props;

  const plotIcon = <ShowChart size="small" />;
  const corIcon = <Description size="small" />;
  const xmlIcon = <XmlIcon size="small" />;
  const annotationIcon = <Bookmarks size="small" />;
  const csvIcon = <CsvIcon size="small" />;

  return (
    <span>
      <Checkbox
        icon={plotIcon}
        onChange={onClickPlot}
        title="Add plot product to every cast"
        className={classes.checkbox}
      />
      <Checkbox
        icon={corIcon}
        onChange={onClickCor}
        title="Add cor product to every cast"
        className={classes.checkbox}
      />
      <Checkbox
        icon={annotationIcon}
        onChange={onClickAnnotations}
        title="Add annotation product to every cast"
        className={classes.checkbox}
      />
      <Checkbox
        icon={xmlIcon}
        onChange={onClickXml}
        title="Add xml product to every cast"
        className={classes.checkbox}
      />
      <Checkbox
        icon={csvIcon}
        onChange={onClickCsv}
        title="Add csv product to every cast"
        className={classes.checkbox}
      />
    </span>
  );
};

export default withStyles(styles)(CheckboxArray);
