import React from 'react';
import ImageWidgetConfig from 'domain/AppComponents/Dashboard/ImageWidgetConfig';
import ImageWidgetDisplay from 'domain/AppComponents/Dashboard/ImageWidgetDisplay';

type ImageWidgetProps = {
  showDisplay?: boolean;
  imageUrl?: string;
  showTitle?: boolean;
  title?: string;
  onSave?: (values: any) => Promise<void>;
  onConfigFinish?: () => void;
};

const ImageWidget: React.FC<ImageWidgetProps> = ({
  showDisplay = true,
  imageUrl = undefined,
  showTitle = true,
  title = 'Sample Image',
  onSave = undefined,
  onConfigFinish = undefined,
}) => {
  const handleSave = async (values: any) => {
    // If a parent save has been specified wait for it finish
    // before switching to display
    await onSave(values);
    onConfigFinish();
  };

  if (showDisplay) {
    return (
      <ImageWidgetDisplay
        showTitle={showTitle}
        title={title}
        imageUrl={imageUrl}
      />
    );
  }
  return (
    <ImageWidgetConfig
      onSave={handleSave}
      onCancel={onConfigFinish}
      showTitle={showTitle}
      title={title}
      imageUrl={imageUrl}
    />
  );
};

export default ImageWidget;
