import { useState } from 'react';
import { ShoppingCartIcon } from '@onc/icons';
import { MapFABControl } from 'base-components';
import DataProductCart from '../cf-dialogs/DataProductCart';
/**
 * Contains a Floating Action Button that displays a popup for the Data Product
 * Cart when clicked
 */

type DataProductCartFABProps = {
  showDialog: boolean;
  setShowDialog: any;
};

const DataProductCartFAB = ({
  showDialog,
  setShowDialog,
}: DataProductCartFABProps) => {
  const [renderDataProductCart, setRenderDataProductCart] =
    useState<boolean>(false);

  const title = renderDataProductCart ? 'Hide Cart' : 'Show Cart';

  const toggleDataProductCart = () => {
    setRenderDataProductCart(!renderDataProductCart);
  };

  const onClose = () => {
    setRenderDataProductCart(false);
    setShowDialog(false);
  };

  return (
    <>
      <MapFABControl
        TooltipProps={{ title }}
        onClick={toggleDataProductCart}
        size="medium"
        color="primary"
        options={{ position: 'bottomleft' }}
        prepend
      >
        <ShoppingCartIcon />
      </MapFABControl>
      <DataProductCart
        open={renderDataProductCart || showDialog}
        onClose={onClose}
      />
    </>
  );
};

export default DataProductCartFAB;
