import { Typography } from 'base-components';
import TreeMenuItem from 'library/CompositeComponents/tree-menu/TreeMenuItem';

import CruiseMenuItem from './CruiseMenuItem';
import { ExpeditionTreeNode } from './ExpeditionTree';

type Props = {
  config: ExpeditionTreeNode[];
  path?: string[];
  onMenuItemClicked: (
    path: (string | { expeditionId: number } | { diveId: number })[],
    id: number,
    ready?: boolean
  ) => void;
  onWatchButtonClicked: () => void;
  onSearchButtonClicked: () => void;
  onDeckLogButtonClicked: () => void;
  selectedNodeId: number;
};

export interface CruiseTreeNode extends ExpeditionTreeNode {
  startDate: string;
  ready: boolean;
}

export type DeckLogTreeNode = ExpeditionTreeNode;

export interface DiveTreeNode extends ExpeditionTreeNode {
  startDate: string;
  endDate: string;
  ready: boolean;
  defaultDeviceId: number;
  deviceId: number;
}

const VideoTreeItem = ({
  config,
  path = [],
  onMenuItemClicked,
  onSearchButtonClicked,
  onWatchButtonClicked,
  onDeckLogButtonClicked,
  selectedNodeId,
}: Props) => (
  <>
    {config.map((item) => {
      if (item.xtype === 'CRUISE') {
        return (
          <CruiseMenuItem
            key={item.id}
            item={item as CruiseTreeNode}
            path={path}
            selectedNodeId={selectedNodeId}
            onMenuItemClicked={onMenuItemClicked}
            onSearchButtonClicked={onSearchButtonClicked}
            onWatchButtonClicked={onWatchButtonClicked}
            onDeckLogButtonClicked={onDeckLogButtonClicked}
          />
        );
      }

      const newPath = [...path, item.title];
      return (
        <TreeMenuItem
          label={<Typography variant="body1">{item.title}</Typography>}
          key={item.title}
          selected={selectedNodeId === item.nodeId}
          expand={path.length === 0}
          onClick={() => onMenuItemClicked(newPath, item.nodeId)}
        >
          <VideoTreeItem
            config={item.children}
            path={newPath}
            onMenuItemClicked={onMenuItemClicked}
            onSearchButtonClicked={onSearchButtonClicked}
            onWatchButtonClicked={onWatchButtonClicked}
            onDeckLogButtonClicked={onDeckLogButtonClicked}
            selectedNodeId={selectedNodeId}
          />
        </TreeMenuItem>
      );
    })}
  </>
);

export default VideoTreeItem;
