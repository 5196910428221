import { useState } from 'react';
import { DialogContent } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, Grid, Link } from 'base-components';
import LayoutService from 'domain/AppComponents/Dashboard/LayoutService';
import ShareSimple from 'domain/AppComponents/Dashboard/ShareSimple';
import ShareAdvanced from './ShareAdvanced';

const useStyles = makeStyles((theme) =>
  createStyles({
    entryFieldsContainer: {
      borderRadius: 3,
      height: 'fit-content',
      marginTop: theme.spacing(1),
    },
    advancedSharingOptions: {
      marginTop: theme.spacing(3),
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    formButtons: {
      marginTop: theme.spacing(),
      float: 'right',
    },
  })
);

type ShareDialogProps = {
  dashboardId: number;
  dashboardTitle: string;
  sharedWith:
    | Array<{
        accountName: string;
        accountId: number;
        accountType: string;
      }>
    | number[];
  onCancel: () => void;
  onPermissionChange: (
    dashboardId: number,
    closeDialog: boolean
  ) => void | Promise<void>;
  onError: (message: string) => void;
  open?: boolean;
};

const ShareDialog = ({
  dashboardId,
  dashboardTitle,
  sharedWith,
  onCancel,
  onPermissionChange,
  onError,
  open = true,
}: ShareDialogProps) => {
  const classes = useStyles();

  const [isShareAdvanced, setShareAdvanced] = useState(false);
  const [layout, setLayout] = useState(undefined);
  const [error, setError] = useState(undefined);

  const handleCancel = () => {
    setShareAdvanced(false);
    onCancel();
  };
  const handleShare = async (values: any) => {
    const addFn =
      values.sharePermission === 'RW'
        ? LayoutService.addRWPermission
        : LayoutService.addROPermission;
    await addFn(dashboardId, 'User', values.accountName).catch((newError) =>
      onError(
        `Failed to share dashboard ${dashboardId} with ${values.accountName}. ${newError}`
      )
    );
    onPermissionChange(dashboardId, !isShareAdvanced);
  };

  const fetchLayout = async () => {
    let layoutObject;
    await LayoutService.get({
      operation: 4,
      layoutId: dashboardId,
    })
      .then((payload) => {
        layoutObject = payload.layout;
      })
      .catch((newError) => {
        setError(newError.response);
        onError(
          `Failed to get saved layout for dashboard ${dashboardId}. ${error.response.statusText}`
        );
      });
    setLayout(layoutObject);
  };

  const handleDisplayShareAdvanced = () => {
    setShareAdvanced(true);
  };

  const renderShareAdvancedButton = () => (
    <Link
      className={classes.advancedSharingOptions}
      component="button"
      onClick={handleDisplayShareAdvanced}
    >
      Advanced
    </Link>
  );

  const renderShareSimpleDialog = () => {
    const advancedLinkButton = renderShareAdvancedButton();
    return (
      <Grid container spacing={3}>
        <ShareSimple
          onCancel={handleCancel}
          onShare={handleShare}
          classes={{
            entryFieldsContainer: classes.entryFieldsContainer,
            formButtons: classes.formButtons,
          }}
          advancedLinkButton={advancedLinkButton}
        />
      </Grid>
    );
  };

  if (!layout && open) {
    fetchLayout();
  }

  const renderShareAdvancedDialog = () => (
    <ShareAdvanced
      onCancel={handleCancel}
      sharedWith={sharedWith}
      onShare={handleShare}
      dashboardId={dashboardId}
      onPermissionChange={onPermissionChange}
      layout={layout}
      onLayoutChange={fetchLayout}
      onError={onError}
      classes={{
        entryFieldsContainer: classes.entryFieldsContainer,
        formButtons: classes.formButtons,
      }}
    />
  );

  const shareDialogContent = isShareAdvanced
    ? renderShareAdvancedDialog()
    : renderShareSimpleDialog();

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>{`Share Dashboard - ${dashboardTitle}`}</DialogTitle>
      <DialogContent>{shareDialogContent}</DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
