import PropTypes from 'prop-types';

/**
 * Class defining the layouts PropType. This is based off react-grid-layout's
 * layouts for ResponsiveGridLayout.
 */
export default PropTypes.arrayOf(
  PropTypes.shape({
    // item's key
    i: PropTypes.string.isRequired,

    // column that item is in
    x: PropTypes.number,

    // row that item is in
    y: PropTypes.number,

    // width
    w: PropTypes.number,

    // height
    h: PropTypes.number,

    minW: PropTypes.number,
    maxW: PropTypes.number,
    minH: PropTypes.number,
    maxH: PropTypes.number,

    // is not draggable/resizable
    static: PropTypes.bool,

    // can be dragged around
    isDraggable: PropTypes.bool,

    // can be resized
    isResizable: PropTypes.bool,
  })
);
