import { Component } from 'react';

import PamguardTaskCreateComponent from 'domain/AppComponents/pamguard/PamguardTaskCreateComponent';
import PageWithPadding from 'library/CompositeComponents/page/PageWithPadding';

class PamguardTaskCreatePage extends Component {
  render() {
    return (
      <PageWithPadding>
        <PamguardTaskCreateComponent />
      </PageWithPadding>
    );
  }
}

export default PamguardTaskCreatePage;
