import { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import DFManagementLandingPageContainer from './DFManagementLandingPageContainer';
import DFManagementEditor from './editor/DFManagementEditor';
import DFManagementStatistics from './statistics/DFManagementStatistics';

export default class DFManagement extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string,
    }),
  };

  static defaultProps = {
    match: {
      path: undefined,
    },
  };

  renderLandingPage = () => {
    const { match } = this.props;
    return (
      <DFManagementLandingPageContainer
        editPath={`${match.path}/edit`}
        statisticsPath={`${match.path}/statistics`}
      />
    );
  };

  renderDFManagementEditor = (routeProps) => {
    const { match } = this.props;
    return <DFManagementEditor landingPath={match.path} {...routeProps} />;
  };

  renderDFManagementStatistics = (routeProps) => {
    const { match } = this.props;
    return <DFManagementStatistics landingPath={match.path} {...routeProps} />;
  };

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={match.path} component={this.renderLandingPage} />
        <Route
          path={`${match.path}/edit/:campaignId`}
          component={this.renderDFManagementEditor}
        />
        <Route
          path={`${match.path}/statistics/:campaignId`}
          component={this.renderDFManagementStatistics}
        />
      </Switch>
    );
  }
}
