import DOIDatasetLinks from 'domain/AppComponents/datasets/DOIDatasetLinks';
import DOIDatasetService from 'domain/AppComponents/doi-util/DOIDatasetService';

interface Props {
  siteDeviceId?: number;
}

const SiteDeviceDOIDatasetLinks: React.FC<Props> = ({
  siteDeviceId = undefined,
}: Props) => (
  <DOIDatasetLinks
    resourceId={siteDeviceId}
    fetchDOIDataset={(resourceId: number) =>
      DOIDatasetService.getDatasetForSiteDevice(resourceId)
    }
  />
);

export default SiteDeviceDOIDatasetLinks;
