import { memo, useEffect } from 'react';
import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { CheckCircle, Error } from '@onc/icons';
import { CircularProgress, Grid } from 'base-components';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import StepHeader from 'library/CompositeComponents/stepper/step-parts/StepHeader';

const styles = (theme: Theme) =>
  createStyles({
    stepDiv: {
      margin: '25px 10px',
    },
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    progress: {
      display: 'block',
      margin: '25px auto',
      padding: '25px',
    },
    result: {
      display: 'block',
      margin: '25px auto',
      height: '200px',
      width: '200px',
    },
  });

interface Props extends WithStyles<typeof styles> {
  loading: boolean;
  onError: (message: string) => void;
  onInfo: (message: string) => void;
  error?: string | undefined;
}

const ChatLogIngestionStep: React.VFC<Props> = ({
  loading,
  onError,
  onInfo,
  error = undefined,
  classes,
}) => {
  useEffect(
    () => {
      if (loading) {
        onInfo('Ingesting...');
      }

      if (!loading && !error) {
        onInfo('Ingestion completed successfully.');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  );

  useEffect(
    () => {
      if (error) {
        onError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error]
  );

  const renderProgress = () => {
    if (loading) {
      return <CircularProgress className={classes.progress} size="150px" />;
    }

    if (error) {
      return <Error className={`${classes.error} ${classes.result}`} />;
    }

    return <CheckCircle className={`${classes.success} ${classes.result}`} />;
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.stepDiv}>
        <StepHeader
          header={error ? 'Error' : 'Ingestion'}
          description={error}
        />
      </Grid>
      <Grid item xs={12}>
        {renderProgress()}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withSnackbars(memo(ChatLogIngestionStep)));
