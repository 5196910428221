import { parseDmasAPIResponse, get } from 'util/WebRequest';

class EarthquakeCatalogService {
  static getEarthquakes = async () =>
    get('EarthquakeCatalogService', {
      operation: 1,
    }).then((response) => parseDmasAPIResponse(response));

  static getFilteredEarthquakes = async (filters) =>
    get('EarthquakeCatalogService', {
      operation: 1,
      ...filters,
    }).then((response) => parseDmasAPIResponse(response));
}

export default EarthquakeCatalogService;
