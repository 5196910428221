import { ServiceAnnotation } from 'domain/services/AnnotationService';
import FormFieldParser from '../util/FormFieldParser';

interface AnnotationSource {
  annotation: string;
  formField: {
    formFieldId: string;
  };
  annotationAttributes: null;
  taxonId: number;
  taxonomyId: number;
}

class AnnotationEntryUtil {
  static buildServiceAnnotation = (
    resourceType: { label: string; value: number },
    resource: { name: string; id: string },
    formFields: Map<number, any>,
    shared: boolean,
    flagged: boolean,
    startTime: Date,
    endTime: Date | null,
    annotationId?: number,
    sourceId?: number
  ) => {
    const formFieldArray: AnnotationSource[] = [];
    formFields.forEach((field, id) => {
      if (field.value !== undefined && field.value !== null) {
        formFieldArray.push({
          annotation: FormFieldParser.extractStringValue(
            field.type,
            field.value
          ),
          formField: {
            formFieldId: `${id}`,
          },
          annotationAttributes: null,
          taxonId: 0,
          taxonomyId: 0,
        });
      }
    });
    const serviceAnnotation: ServiceAnnotation = {
      annotationId,
      resourceType: {
        resourceTypeId: resourceType.value,
        resourceTypeName: resourceType.label,
      },
      resource: {
        resourceId: Number(resource.id),
        resourceName: resource.name,
      },
      startDate: startTime.toISOString(),
      endDate:
        endTime && !isNaN(endTime.getTime()) ? endTime.toISOString() : null,
      annotationContents: formFieldArray,
      flagged,
      published: shared,
      annotationSource: {
        annotationSource: 'Oceans 3.0 Users',
        annotationSourceId: sourceId || 3,
      },
      levelIndex: 0,
      dfCampaignId: null,
      permissionToEdit: null,
    };
    return serviceAnnotation;
  };
}

export default AnnotationEntryUtil;
