import { Component } from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ObjectUtils from 'util/ObjectUtils';
import TaxonomyAttributeService from '../../services/TaxonomyAttributeService';

class AttributeSelect extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    name: PropTypes.string,
    title: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    validDataTypes: PropTypes.arrayOf(PropTypes.string),
    excluded: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    name: undefined,
    title: undefined,
    onBlur: undefined,
    onChange: () => {},
    helperText: undefined,
    error: undefined,
    className: undefined,
    value: undefined,
    validDataTypes: [],
    excluded: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { options: [] };
  }

  componentDidMount() {
    this.getAttributeOptions();
  }

  getFilteredOptions = () => {
    const { excluded, value } = this.props;
    const { options } = this.state;
    const filteredOptions = options.filter(
      (a) => !excluded.includes(a.label) || value === a.value
    );
    return filteredOptions;
  };

  getAttributeOptions = () => {
    const { onError, validDataTypes } = this.props;
    return TaxonomyAttributeService.getAll()
      .then((response) => {
        const payload = response;
        const options = [];
        const attributes = [];
        payload.forEach((option, index) => {
          if (
            validDataTypes.length === 0 ||
            validDataTypes.includes(option.dataType)
          ) {
            attributes[index] = ObjectUtils.deepClone(payload[index]);
            options[index] = payload[index];
            options[index].label = option.name;
            options[index].value = option.attributeId;
          }
        });

        options.sort((a, b) => a.label > b.label);
        this.setState({ options, attributes });
      })
      .catch((error) => {
        onError(error);
      });
  };

  handleAttributeChange = (e) => {
    const { onChange } = this.props;
    const attribute = this.findAttributeObjectFromId(e.target.value);
    onChange(e, attribute);
  };

  // Based on an attributeId, return the entire attribute object
  findAttributeObjectFromId = (attributeId) => {
    const { attributes } = this.state;
    const nameHelper = attributes.filter(
      (option) => attributeId === option.attributeId
    );
    return nameHelper[0];
  };

  render() {
    const { name, title, onBlur, helperText, error, className, value } =
      this.props;
    return (
      <Dropdown
        options={this.getFilteredOptions()}
        name={name}
        title={title}
        onBlur={onBlur}
        onChange={this.handleAttributeChange}
        helperText={helperText}
        error={error}
        className={className}
        label="Select an Attribute"
        value={value}
      />
    );
  }
}

export default withSnackbars(AttributeSelect);
