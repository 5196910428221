import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class DeviceParserDefinitionService {
  static async update(parserdefinitionid, deviceid, update, batchId) {
    const response = await post('DeviceParserDefinitionService', {
      operation: 2,
      parserDefinitionId: parserdefinitionid,
      deviceId: deviceid,
      parserDefinition: update,
      batchId,
    });
    return parseDmasAPIResponse(response);
  }

  static async create(deviceid, parserDefinitionJSON, batchId) {
    const response = await post('DeviceParserDefinitionService', {
      operation: 1,
      deviceId: deviceid,
      parserDefinition: parserDefinitionJSON,
      batchId,
    });
    return parseDmasAPIResponse(response);
  }

  static getWithHistory(parserdefinitionid) {
    return DeviceParserDefinitionService.get({
      parserDefinitionId: parserdefinitionid,
    });
  }

  static get(params) {
    return get('DeviceParserDefinitionService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static async runTests(deviceid, update) {
    const response = await post('DeviceParserDefinitionService', {
      operation: 11,
      parserDefinition: update,
      deviceId: deviceid,
    });
    return parseDmasAPIResponse(response);
  }
}
export default DeviceParserDefinitionService;
