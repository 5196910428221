import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import DataAvailabilityChartV2 from 'domain/AppComponents/charts/DataAvailabilityChartV2';
import ConfigDataSourceSelector from 'domain/AppComponents/Dashboard/chart-widget/ConfigDataSourceSelector';
import { FormFieldType } from 'library/CompositeComponents/form/FormFieldType';

type DataAvailabilityChartFormControllerProps = FormFieldType<
  React.ComponentProps<typeof DataAvailabilityChartV2>
>;

export const DataAvailabilityChartFormController: React.FC<
  DataAvailabilityChartFormControllerProps
> = ({ name, ...rest }: DataAvailabilityChartFormControllerProps) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <DataAvailabilityChartV2
          {...rest}
          onDateRangeSelected={(event) => {
            const { dateFrom, dateTo } = event;
            setValue('startDate', moment(dateFrom));
            setValue('endDate', moment(dateTo));
          }}
        />
      )}
    />
  );
};

type ConfigDataSourceSelectorProps = React.ComponentProps<
  typeof ConfigDataSourceSelector
>;

type ConfigDataSourceSelectorFormControllerProps = FormFieldType<
  Omit<ConfigDataSourceSelectorProps, 'locations' | 'onLocationsChange'>
>;

export const ConfigDataSourceSelectorFormController: React.FC<
  ConfigDataSourceSelectorFormControllerProps
> = ({
  name,
  ...dataSourceSelectorProps
}: ConfigDataSourceSelectorFormControllerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ConfigDataSourceSelector
          {...dataSourceSelectorProps}
          locations={field.value}
          onLocationsChange={field.onChange}
        />
      )}
    />
  );
};
