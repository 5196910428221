import { useEffect, useState } from 'react';
import * as React from 'react';
import { Dropdown, type DropdownOption } from 'base-components';
import DiveListingService, {
  DiveJSON,
} from 'domain/services/DiveListingService';

interface Props {
  cruiseId?: number; // can be used to limit options to only dives of a certain cruise
  onError?: (error: any) => void;
  options?: DropdownOption[];
  value?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

const DiveDropdown: React.VFC<Props> = ({
  options = undefined,
  value = undefined,
  onChange,
  label = 'Dive Name',
  className = undefined,
  fullWidth = undefined,
  disabled = undefined,
  cruiseId = undefined,
  onError = () => {},
}) => {
  // use controlled options if they are defined as a prop, otherwise default to a service call
  const [optionsState, setOptionsState] = useState(options || []);

  useEffect(
    () => {
      if (!options) {
        DiveListingService.getDives({ cruiseId })
          .then((response) =>
            setOptionsState(
              response.dives
                .map((dive: DiveJSON) => ({
                  key: dive.diveId,
                  label: dive.referenceDiveId,
                  value: dive.diveId,
                  cruiseId: dive.cruiseId,
                  dateFrom: dive.dateFrom,
                  dateTo: dive.dateTo,
                }))
                .sort((a: DropdownOption, b: DropdownOption) => {
                  const labelA = a.label.toUpperCase();
                  const labelB = b.label.toUpperCase();
                  return labelA <= labelB ? -1 : 1;
                })
            )
          )
          .catch((error) => {
            onError(error);
            return [];
          });
      } else {
        setOptionsState(options);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, cruiseId]
  );

  return (
    <Dropdown
      options={optionsState}
      value={value}
      onChange={onChange}
      label={label}
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
    />
  );
};

export default DiveDropdown;
