import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { MoreVert } from '@onc/icons';
import {
  AnchoredHiddenMenu,
  AppBar,
  Checkbox,
  Divider,
  IconButton,
  Toolbar,
  ListSubheader,
  Grid,
  Menu,
  MenuItem,
  RadioButton,
  Typography,
} from 'base-components';

import LayoutPropType from './LayoutPropType';

/*
  Make sure that the icon button floats above all items in the grid layout
*/
const styles = (theme) => ({
  iconButton: {
    zIndex: 800,
    float: 'right',
  },
  menu: {
    width: '225px',
  },
  menuTitle: {
    paddingLeft: theme.spacing(),
  },
  grow: {
    flexGrow: 1,
  },
  expansionMenu: {
    paddingLeft: '0px',
  },
  buttonContainer: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  secondaryAppBar: {
    backgroundColor: theme.palette.primary.light,
  },
});

const MENU_TITLE = 'Layout Settings';
class ToggleItemsMenu extends Component {
  static propTypes = {
    /* onItemClick is the same for all menu items, you pass in the item
     * clicked as your argument to this function.
     */
    onItemClick: PropTypes.func.isRequired,
    onLayoutClick: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      buttonContainer: PropTypes.string,
      expansionMenu: PropTypes.string,
      grow: PropTypes.string,
      iconButton: PropTypes.string,
      menu: PropTypes.string,
      menuTitle: PropTypes.string,
      secondaryAppBar: PropTypes.string,
    }).isRequired,
    requiresPermission: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        hidden: PropTypes.bool,
        permission: PropTypes.bool,
      })
    ),
    layouts: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        selected: PropTypes.bool,
        permission: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        layout: PropTypes.shape({
          lg: LayoutPropType,
          md: LayoutPropType,
          sm: LayoutPropType,
          xs: LayoutPropType,
          xxs: LayoutPropType,
        }).isRequired,
      })
    ),
    appBarTitle: PropTypes.string,
    appBarContent: PropTypes.node,
    bottomMenuContent: PropTypes.node,
    titleContent: PropTypes.node,
  };

  static defaultProps = {
    items: [],
    layouts: [],
    requiresPermission: false,
    appBarTitle: undefined,
    appBarContent: undefined,
    titleContent: undefined,
    bottomMenuContent: undefined,
  };

  /**
   * Iterates through all the items in props and creates a MenuItem for each
   * item. These items may have permissions and need a permissions check before
   * being added to the menu.
   */
  getItemList = () => {
    const { items, onItemClick, requiresPermission } = this.props;
    return items.map((item) => {
      const { hidden, key, permission } = item;
      if (!requiresPermission || permission) {
        return (
          <MenuItem key={`menuitem-${key}`} onClick={() => onItemClick(item)}>
            <Checkbox checked={!hidden} />
            {item.name}
          </MenuItem>
        );
      }
      return null;
    });
  };

  /**
   * Iterates through all layouts in props and creates a MenuItem for each item.
   * These layouts may have permissions and need a permissions check before
   * being added to the menu.
   */
  getLayoutList = () => {
    const { layouts, onLayoutClick, requiresPermission } = this.props;
    return layouts.map((layout) => {
      const { key, permission, selected } = layout;

      const isDisabled = requiresPermission && !permission;
      return (
        <MenuItem
          key={`layout-menuitem-${key}`}
          disabled={isDisabled}
          onClick={() => onLayoutClick(layout)}
        >
          <RadioButton checked={!!selected} />
          {layout.name}
        </MenuItem>
      );
    });
  };

  /** Renders ExpansionSubMenu for layouts. */
  renderLayoutMenu = () => {
    const { layouts } = this.props;
    if (layouts.length > 0) {
      return <Menu menuList>{this.getLayoutList()}</Menu>;
    }
    return null;
  };

  /** Renders ExpansionSubMenu for items. */
  renderItemsMenu = () => {
    const { items } = this.props;
    if (items.length > 0) {
      return <Menu menuList>{this.getItemList()}</Menu>;
    }
    return null;
  };

  renderBottomMenuContent = () => {
    const { classes, bottomMenuContent } = this.props;
    if (bottomMenuContent) {
      return (
        <>
          <Divider />
          <Grid container className={classes.buttonContainer}>
            {bottomMenuContent}
          </Grid>
        </>
      );
    }
    return null;
  };

  renderMenuTitle = () => {
    const { classes } = this.props;
    return (
      <Typography className={classes.menuTitle} variant="h6" color="inherit">
        {MENU_TITLE}
      </Typography>
    );
  };

  render() {
    const { appBarTitle, classes, appBarContent, titleContent } = this.props;
    const threeDotButton = (
      <IconButton aria-label="Layout Menu" Icon={MoreVert} color="inherit" />
    );
    const appbartitle = appBarTitle ? (
      <Typography variant="body1" color="inherit">
        {appBarTitle}
      </Typography>
    ) : null;

    return (
      <AppBar position="static" className={classes.secondaryAppBar}>
        <Toolbar variant="dense">
          {appbartitle}
          {titleContent}
          <div className={classes.grow} />
          {appBarContent}
          <AnchoredHiddenMenu
            closeOnClick={false}
            variant="custom"
            customAnchor={threeDotButton}
          >
            <div className={classes.menu}>
              {/* commented since no functionality implemented yet
              * <MenuItem key="clone" onClick={() => {}}>
                Clone...
              </MenuItem> */}
              {this.renderMenuTitle()}
              <ListSubheader>Widgets</ListSubheader>
              {this.renderItemsMenu()}
              <Divider />
              <ListSubheader>Predefined Layouts</ListSubheader>
              {this.renderLayoutMenu()}
              {this.renderBottomMenuContent()}
            </div>
          </AnchoredHiddenMenu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(ToggleItemsMenu);
