const defaultLayout = {
  lg: [
    {
      w: 2,
      h: 24,
      x: 0,
      y: 0,
      i: 'earthquake-filters',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 24,
      x: 2,
      y: 0,
      i: 'earthquake-table',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 24,
      x: 8,
      y: 0,
      i: 'earthquake-map',
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 4,
      h: 12,
      x: 0,
      y: 12,
      i: 'earthquake-filters',
      moved: false,
      static: false,
    },
    {
      w: 10,
      h: 12,
      x: 0,
      y: 0,
      i: 'earthquake-table',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 12,
      x: 4,
      y: 12,
      i: 'earthquake-map',
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 3,
      h: 12,
      x: 0,
      y: 12,
      i: 'earthquake-filters',
      moved: false,
      static: false,
    },
    {
      w: 8,
      h: 12,
      x: 0,
      y: 0,
      i: 'earthquake-table',
      moved: false,
      static: false,
    },
    {
      w: 5,
      h: 12,
      x: 3,
      y: 12,
      i: 'earthquake-map',
      moved: false,
      static: false,
    },
  ],
  xs: [
    {
      w: 6,
      h: 12,
      x: 0,
      y: 0,
      i: 'earthquake-filters',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 12,
      x: 0,
      y: 5,
      i: 'earthquake-table',
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 12,
      x: 0,
      y: 5,
      i: 'earthquake-map',
      moved: false,
      static: false,
    },
  ],
};

const defaultWidgets = [
  {
    i: 'earthquake-filters',
    x: 0,
    y: 99999,
    w: 2,
    h: 6,
    widgetKey: 'earthquake-filters',
    title: 'Earthquake Filter',
  },
  {
    i: 'earthquake-table',
    x: 0,
    y: 99999,
    w: 6,
    h: 8,
    widgetKey: 'earthquake-table',
    title: 'Earthquake Table',
  },
  {
    i: 'earthquake-map',
    x: 0,
    y: 99999,
    w: 5,
    h: 5,
    widgetKey: 'earthquake-map',
    title: 'Earthquake Map',
  },
];

export { defaultLayout, defaultWidgets };
