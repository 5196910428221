import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ArrowBack, PushPin, ExpandLess } from '@onc/icons';
import {
  AnchoredHiddenMenu,
  IconButton,
  LinearProgress,
  Toolbar,
} from 'base-components';

const styles = (theme) => ({
  action: {
    marginTop: '0px',
  },
  grow: {
    flexGrow: 0,
  },
  titleComponents: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  backButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  pin: {
    marginLeft: theme.spacing(1),
    color: 'white',
  },
  menuIcon: {
    marginRight: theme.spacing(1),
  },
  menuIconColor: {
    color: 'white',
  },
  toolbar: {
    paddingRight: 0,
    flexShrink: 0,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarHover: {
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? 'rgba(0, 0, 0, 0.13)'
          : 'rgba(255, 255, 255, 0.13)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor:
          theme.palette.mode === 'light'
            ? 'rgba(0, 0, 0, 0.09)'
            : 'rgba(255, 255, 255, 0.09)',
      },
    },
    '&:hover .hidden': {
      display: 'block',
    },
  },
  toolbarHoverRW: {
    cursor: 'grab',
  },
  loading: {
    height: 2,
  },
});

class PanelHeader extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onBackButtonClick: PropTypes.func,
    menu: PropTypes.node,
    actionContent: PropTypes.node,
    title: PropTypes.node,
    titleComponents: PropTypes.node,
    menuProps: PropTypes.oneOfType([PropTypes.object]),
    headerDraggable: PropTypes.bool,
    loading: PropTypes.bool,
    readOnly: PropTypes.bool,
    ariaLabel: PropTypes.string,
    collapseHeader: PropTypes.bool,
    isHeaderPinned: PropTypes.bool,
    setIsHeaderPinned: PropTypes.func,
  };

  static defaultProps = {
    title: undefined,
    titleComponents: undefined,
    actionContent: undefined,
    menu: undefined,
    onBackButtonClick: undefined,
    menuProps: undefined,
    headerDraggable: false,
    loading: false,
    readOnly: false,
    ariaLabel: '',
    collapseHeader: undefined,
    isHeaderPinned: undefined,
    setIsHeaderPinned: undefined,
  };

  handlePinClick = () => {
    const { setIsHeaderPinned } = this.props;
    setIsHeaderPinned((prev) => !prev);
  };

  renderPinButton = () => {
    const { classes, isHeaderPinned, collapseHeader } = this.props;
    if (collapseHeader !== undefined)
      return (
        <IconButton
          className={`onc-panel-content ${isHeaderPinned ? undefined : classes.pin}`}
          Icon={isHeaderPinned ? ExpandLess : PushPin}
          aria-label={isHeaderPinned ? 'Collapse' : 'Pin'}
          onClick={this.handlePinClick}
        />
      );
    return null;
  };

  handleClasses = () => {
    const { headerDraggable, readOnly, classes } = this.props;
    let classList = `${classes.toolbar} `;
    if (headerDraggable) {
      classList = `${classList} ${classes.toolbarHover}`;
    }
    if (headerDraggable && !readOnly) {
      classList = `${classList} ${classes.toolbarHoverRW}`;
    }
    return classList;
  };

  renderBackButton = () => {
    const { classes, onBackButtonClick } = this.props;
    if (!onBackButtonClick) return undefined;
    return (
      <IconButton
        className={`onc-panel-content ${classes.backButton}`}
        color="inherit"
        aria-label="Menu"
        onClick={onBackButtonClick}
        edge="start"
        Icon={ArrowBack}
      />
    );
  };

  renderMenu = () => {
    const { menu, menuProps, classes, collapseHeader, isHeaderPinned } =
      this.props;
    if (menu) {
      return (
        <AnchoredHiddenMenu
          iconClasses={`${classes.menuIcon} ${collapseHeader !== undefined && !isHeaderPinned ? classes.menuIconColor : undefined}`}
          {...menuProps}
        >
          {menu}
        </AnchoredHiddenMenu>
      );
    }
    return undefined;
  };

  renderActionContent = () => {
    const { actionContent, readOnly } = this.props;
    if (readOnly) {
      return <div className="hidden">{actionContent}</div>;
    }
    return actionContent;
  };

  renderLoading = () => {
    const { loading } = this.props;
    if (!loading) return null;
    return <LinearProgress />;
  };

  render() {
    const {
      title,
      titleComponents,
      classes,
      onBackButtonClick,
      actionContent,
      menu,
      ariaLabel,
      isHeaderPinned,
    } = this.props;
    // If all props are undefined don't return a header
    if (
      !(title || onBackButtonClick || actionContent || menu || titleComponents)
    )
      return null;

    return (
      <>
        <Toolbar
          aria-label={ariaLabel}
          variant="dense"
          id={`panelHeader${ariaLabel}`}
          className={this.handleClasses()}
          role="toolbar"
          style={
            isHeaderPinned === false
              ? {
                  background: 'rgba(18, 157, 192)',
                }
              : undefined
          }
        >
          {this.renderBackButton()}
          <div className={classes.grow}>{title}</div>
          <div className={classes.titleComponents}>{titleComponents}</div>
          {this.renderPinButton()}

          <div className={`onc-panel-content ${classes.actions}`}>
            {this.renderActionContent()}
            {this.renderMenu()}
          </div>
        </Toolbar>
        <div className={classes.loading}>{this.renderLoading()}</div>
      </>
    );
  }
}

export default withStyles(styles)(PanelHeader);
