import { parseDmasAPIResponse, post, get } from 'util/WebRequest';

class ExtensionDetailService {
  static getMany() {
    return ExtensionDetailService.get({ operation: 5 });
  }

  static getOne(extensionDetailId) {
    return ExtensionDetailService.get({ operation: 4, extensionDetailId });
  }

  static get(params) {
    return get('CableExtensionDetailService', {
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static create(params) {
    return post('CableExtensionDetailService', {
      operation: 1,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static update(params) {
    return post('CableExtensionDetailService', {
      operation: 2,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
  }

  static delete = (params) =>
    post('CableExtensionDetailService', {
      operation: 3,
      ...params,
    }).then((response) => parseDmasAPIResponse(response));
}
export default ExtensionDetailService;
