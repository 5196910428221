import { RemoveIconButton } from 'domain/AppComponents/IconButtons';

const BatchDeleteAction = ({ onClick }: { onClick: () => void }) => (
  <RemoveIconButton
    onClick={() => {
      onClick();
    }}
  />
);

export default BatchDeleteAction;
