import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Error as ErrorIcon, Warning } from '@onc/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Paper,
} from 'base-components';

import AnnotationUtil from 'domain/Apps/seatube/util/AnnotationUtil';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import AnnotationService from 'domain/services/AnnotationService';
import PlaylistService from 'domain/services/PlaylistService';
import SeaTubeBroadAnnotationService from 'domain/services/SeaTubeBroadAnnotationService';
import UserDetailsService from 'domain/services/UserDetailsService';
import {
  CancelButton,
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ObjectUtils from 'util/ObjectUtils';
import { getAbortable } from 'util/WebRequest';
import BroadSearchFilter from './BroadSearchFilter';
import SeaTubeBroadSearchResultsTable from './SeaTubeBroadSearchResultsTable';
import SeaTubeSearchResultsPanel from './SeaTubeSearchResultsPanel';
import SeaTubeStillImageExportForm from './SeaTubeStillImageExportForm';
import AnnotationFilter from '../filter/AnnotationFilter';

const BROAD_SEARCH_TAB = 1;
const CHECK_INTERVAL = 5000;
const FULL_WIDTH = 12;
const ACTION_SEARCH = 'search';
const ACTION_IMAGE_EXPORT = 'image export';

const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  filterContainer: {
    height: '100%',
  },
  root: {
    height: '100%',
    width: '100%',
    paddingRight: 0,
  },
  warningIcon: {
    verticalAlign: 'bottom',
    paddingRight: '10px',
  },
  dialogParagraph: {
    paddingBottom: '15px',
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
});

class SeaTubeSearchContent extends PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
    broadSearch: PropTypes.bool,
    classes: PropTypes.shape({
      appBar: PropTypes.string,
      dialogParagraph: PropTypes.string,
      filterContainer: PropTypes.string,
      marginTop: PropTypes.string,
      root: PropTypes.string,
      warningIcon: PropTypes.string,
    }).isRequired,
    initialFilter: PropTypes.shape({
      taxonomy: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        taxonomy: PropTypes.any,
        taxon: PropTypes.shape({ taxonId: PropTypes.number }),
      }),
      // eslint-disable-next-line react/forbid-prop-types
      parentTaxonomy: PropTypes.any,
      parentTaxonomyId: PropTypes.number,

      taxon: PropTypes.shape({
        taxonId: PropTypes.number,
      }),
      parentTaxonId: PropTypes.number,
      comment: PropTypes.string,
      creators: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number })),
      cruises: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number })),
      logs: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number })),
      modifiers: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.number })
      ),
      organizationId: PropTypes.string,
      searchTreeNodeIds: PropTypes.arrayOf(PropTypes.number),
    }),
    initialFilterValues: PropTypes.shape({
      parentTaxonId: PropTypes.number,
      taxonId: PropTypes.number,
      cruiseIds: PropTypes.arrayOf(
        PropTypes.shape({
          length: PropTypes.number,
        })
      ),
      taxonomy: PropTypes.shape({
        taxonomy: PropTypes.shape({
          taxonId: PropTypes.number,
        }),
        taxon: PropTypes.shape({}),
      }),
      cruises: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number })),
      diveIds: PropTypes.arrayOf(PropTypes.number),
      dives: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number })),
      searchTreeNodeIds: PropTypes.arrayOf(PropTypes.number),
    }),
    location: PropTypes.bool,
    includeDeckLog: PropTypes.bool,
  };

  static defaultProps = {
    initialFilter: {},
    initialFilterValues: {},
    includeDeckLog: false,
    location: false,
    broadSearch: undefined,
  };

  getEmptyFilter = () => ({
    cruises: [],
    logs: [],
    modifiers: [],
    creators: [],
    comment: '',
    organizationId: '',
  });

  buildBroadSearchResults = (unprocessedResults) => {
    let newSearchResults = [];

    unprocessedResults.forEach((search) => {
      newSearchResults = newSearchResults.concat(search);
    });

    return newSearchResults;
  };

  constructor(props) {
    super(props);
    const { initialFilterValues, initialFilter, includeDeckLog } = props;
    initialFilter.cruises = initialFilter.cruises || [];
    initialFilter.logs = initialFilter.logs || [];
    initialFilter.modifiers = initialFilter.modifiers || [];
    initialFilter.creators = initialFilter.creators || [];
    initialFilter.organizationId = initialFilter.organizationId ?? '';
    initialFilter.comment = initialFilter.comment ?? '';

    if (initialFilterValues.diveIds) {
      initialFilter.logs = initialFilterValues.diveIds.map((diveId) => ({
        value: diveId,
        type: 'dive',
        label: 'Loading...',
      }));
    }

    if (includeDeckLog) {
      initialFilter.logs.push(
        ...initialFilterValues.cruiseIds.map((cruiseId) => ({
          value: cruiseId,
          type: 'deck',
          label: 'Loading...',
        }))
      );
    }

    // Ensure dependent filters are present - if not, remove any filters depending on them
    if (
      (!initialFilterValues.cruises ||
        initialFilterValues.cruises.length < 1) &&
      !initialFilterValues.searchTreeNodeIds
    ) {
      if (!initialFilterValues.cruiseIds?.length) {
        initialFilterValues.dives = [];
        initialFilter.creators = [];
        initialFilter.modifiers = [];
      }
    }

    if (initialFilter.taxonomy) {
      initialFilter.parentTaxonomy = initialFilter.taxonomy.taxonomy;
      initialFilter.taxon = initialFilter.taxonomy.taxon;
      initialFilter.parentTaxonomyId = Number(initialFilter.taxonomy.taxonomy);
      if (
        !initialFilter.parentTaxonomyId ||
        initialFilter.parentTaxonomyId !== 0
      ) {
        initialFilter.parentTaxonId = undefined;
      }
      initialFilterValues.parentTaxonId = Number(
        initialFilter.taxonomy.taxon.taxonId
      );
      // Tell filterForm to render the taxon filter - it will fix this value
      if (initialFilterValues.parentTaxonId) {
        initialFilter.parentTaxonId = 1;
      }
    } else {
      initialFilter.parentTaxonId = undefined;
    }

    const initialTabValue = SeaTubeResourceTypes.EXPEDITION;

    this.state = {
      filter: initialFilter,
      appliedFilter: initialFilter,
      defaultFilterValues: initialFilterValues,
      creatorOptions: [],
      modifierOptions: [],
      searchResults: [],
      loading: false,
      showWarningDialog: false,
      showTimeoutDialog: false,
      isLoggedIn: false,
      tabValue: initialTabValue,
      actionValue: undefined,
      completedSearchCount: 0,
      generatedSearches: [],
    };
  }

  componentDidMount() {
    const { initialFilterValues, location } = this.props;
    if (initialFilterValues.searchTreeNodeIds && location) {
      this.getCreatorsAndModifiers();
    }

    this.getExistingPlaylists();
    this.checkAuthenticated();
  }

  componentDidUpdate(prevProps, prevState) {
    const { logOptions, filter } = this.state;

    if (this.shouldUpdateCreatorsAndModifiers(prevState)) {
      this.getCreatorsAndModifiers();
    }

    if (
      prevState.filter.cruises !== filter.cruises ||
      prevState.logOptions !== logOptions ||
      prevState.filter.logs !== filter.logs
    ) {
      this.getDiveListForExport();
    }
  }

  shouldUpdateCreatorsAndModifiers = (prevState) => {
    const { logOptions, filter } = this.state;
    const logsChanged = !ObjectUtils.isEqual(
      prevState.filter.logs,
      filter.logs
    );

    const logOptionsChanged = !ObjectUtils.isEqual(
      prevState.logOptions,
      logOptions
    );

    const searchTreeNodesChanged =
      prevState.filter.searchTreeNodeIds !== filter.searchTreeNodeIds;

    return logsChanged || logOptionsChanged || searchTreeNodesChanged;
  };

  checkAuthenticated = async () => {
    const isLoggedIn = await UserDetailsService.isLoggedIn();
    this.setState({ isLoggedIn });
  };

  getExistingPlaylists = async () => {
    const { onError } = this.props;

    try {
      const playlists = await PlaylistService.getPlaylists();

      const playlistOptions = playlists
        .map((playlist) => ({
          label: playlist.name,
          value: playlist.playlistHdrId,
          locked: playlist.locked,
        }))
        .sort((a, b) => a.label > b.label);

      this.setState({ playlists: playlistOptions });
    } catch (error) {
      onError(error);
    }
  };

  getCreatorsAndModifiers = async () => {
    const { onError } = this.props;
    const { filter, logOptions, tabValue, cancelRequest } = this.state;
    let params;
    if (tabValue === SeaTubeResourceTypes.EXPEDITION) {
      const logs = filter?.logs?.length ? filter.logs : logOptions || [];
      const diveIdList = logs
        .filter((option) => option.type === 'dive')
        .map((option) => option.value);
      const deckLogIdList = logs
        .filter((option) => option.type === 'deck')
        .map((option) => option.value);

      const diveIdString = diveIdList?.join() || '';
      const deckLogIdString = deckLogIdList?.join() || '';

      // Remove any creators and modifiers if there are no dives or cruises selected
      if (diveIdString === '' && deckLogIdString === '') {
        const { creators: creatorsFilter, modifiers: modifiersFilter } = filter;
        if (creatorsFilter) this.handleFilterValueChange('creators', []);
        if (modifiersFilter) this.handleFilterValueChange('modifiers', []);
        this.setState({
          creatorOptions: [],
          modifierOptions: [],
        });
        return;
      }
      params = {
        diveIds: diveIdString === '' ? undefined : diveIdString,
        cruiseIds: deckLogIdString === '' ? undefined : deckLogIdString,
      };
    } else {
      // Search Tree Node
      const { searchTreeNodeIds } = filter;
      if (!searchTreeNodeIds) {
        this.setState({
          creatorOptions: [],
          modifierOptions: [],
        });
        return;
      }
      const searchTreeParam = searchTreeNodeIds.join(',');
      params = { searchTreeNodeIds: searchTreeParam };
    }

    try {
      if (cancelRequest) {
        cancelRequest();
      }
      const { request, cancel } = getAbortable(
        'seatube/search/user-options',
        params
      );
      this.setState({
        cancelRequest: cancel,
      });
      const response = await request;

      const { creators, modifiers } = response.data.payload;
      const creatorOptions = creators.sort((a, b) => a.label > b.label);
      const modifierOptions = modifiers.sort((a, b) => a.label > b.label);

      this.setState({
        creatorOptions,
        modifierOptions,
      });

      // Check for currently selected creators/modifiers now being invalid
      const { creators: creatorsFilter, modifiers: modifiersFilter } = filter;
      if (creatorsFilter && creatorOptions) {
        const creatorOptionIds = creatorOptions.map((creator) => creator.value);
        this.handleFilterValueChange(
          'creators',
          creatorsFilter.filter((creator) =>
            creatorOptionIds.includes(creator.value)
          )
        );
      }
      if (modifiersFilter && modifierOptions) {
        const modifierOptionIds = modifierOptions.map(
          (modifier) => modifier.value
        );
        this.handleFilterValueChange(
          'modifiers',
          modifiersFilter.filter((modifier) =>
            modifierOptionIds.includes(modifier.value)
          )
        );
      }
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        onError(error);
      }
    }
  };

  getDiveListForExport = () => {
    const { filter, logOptions } = this.state;
    let diveList = [];
    const cruiseIdList = filter.cruises?.map((option) => option.value);

    if (cruiseIdList) {
      // if there no dives selected, get all diveIds
      if (!filter.logs && logOptions) {
        diveList = logOptions
          .filter((option) => option.type === 'dive')
          .map((option) => option.value);
      } else if (filter.logs && filter.logs.length === 1 && logOptions) {
        // diveOptions are guaranteed to be defined here, since diveIds is defined
        diveList = logOptions
          .filter(
            (option) =>
              option.type === 'dive' && filter.logs[0].value === option.value
          )
          .map((option) => ({
            diveId: option.value,
            dateFrom: option.dateFrom,
            dateTo: option.dateTo,
          }));
      } else {
        diveList = filter.logs
          ? filter.logs
              .filter((option) => option.type === 'dive')
              .map((option) => option.value)
          : [];
      }
    }

    this.setState({ diveList });
  };

  handleFilterChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  handleFilterValueChange = (attribute, value) => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, [attribute]: value } });
  };

  handleReset = () => {
    const filter = this.getEmptyFilter();
    sessionStorage.clear();
    this.setState({
      filter,
      actionValue: undefined,
      completedSearchCount: 0,
      generatedSearches: [],
      loading: false,
      searchResults: [],
    });
  };

  handleTabChange = (event, value) =>
    this.setState({
      tabValue: value,
      creatorOptions: [],
      modifierOptions: [],
      filter: this.getEmptyFilter(),
    });

  handleDiveOptionsLoaded = (logOptions) => {
    this.setState({ logOptions });
  };

  handleSearchSubmit = async () => {
    this.closeWarningDialog();
    this.setState({ loading: true });

    const { onError } = this.props;
    const { tabValue, filter, logOptions } = this.state;

    let response;
    const parameters = {};
    parameters.filter = AnnotationUtil.buildAnnotationFilter(
      filter,
      logOptions
    );
    const logs = filter?.logs?.length ? filter.logs : logOptions || [];
    parameters.cruiseIds = logs
      .filter((log) => log.type === 'deck')
      .map((log) => log.value);
    parameters.diveIds = logs
      .filter((log) => log.type === 'dive')
      .map((log) => log.value);

    const handleError = (error) => {
      onError(error);

      this.setState({
        loading: false,
        showTimeoutDialog: error.response.status === 504,
      });
    };

    try {
      if (tabValue === SeaTubeResourceTypes.EXPEDITION) {
        response = await AnnotationService.getAnnotations({
          ...parameters,
        });
      } else {
        response =
          await AnnotationService.getSearchTreeNodeAnnotationsWithFilter(
            parameters,
            true,
            handleError
          );
      }

      const searchResults = AnnotationUtil.processAnnotations(
        response.annotations,
        true
      );

      this.setState({
        searchResults,
        appliedFilter: filter,
        diveIdString: parameters.diveIds?.join() || '',
        loading: false,
        actionValue: ACTION_SEARCH,
        deckLogIdString: parameters.cruiseIds?.join() || '',
      });
    } catch (error) {
      onError(error);

      this.setState({
        loading: false,
      });
    }
  };

  handleInitialBroadSearchClick = () => {
    this.setState({ loading: true }, this.handleBroadSearchSubmit);
  };

  handleBroadSearchSubmit = async () => {
    const { onError, onInfo } = this.props;
    const { filter } = this.state;
    const { comment, parentTaxonomy, taxon, organizationId } = filter;

    try {
      const startResponse =
        await SeaTubeBroadAnnotationService.startBroadSearch({
          filterText: comment !== '' ? comment : undefined,
          taxonomyId: parentTaxonomy,
          taxonIds: taxon ? taxon.taxonId : undefined,
          organizationIds: organizationId || '134,340',
        });
      const {
        searchHdrId,
        generatedSearches,
        generatorName: generator,
      } = startResponse;

      if (searchHdrId === -1) {
        onError(
          'Search parameters are too broad. Please narrow your parameters.'
        );
      } else if (searchHdrId === -2) {
        onError('Insufficient search parameters provided.');
      } else {
        onInfo(`Broad search ${searchHdrId} started.`);
        this.setState({
          searchHdrId,
          generatedSearches,
          generator,
          newSearchResults: false,
          actionValue: ACTION_SEARCH,
          completedSearchCount: 0,
        });
        this.checkBroadSearchStatus();
      }
    } catch (error) {
      onError(error);
      this.setState({ loading: false });
    }
  };

  checkBroadSearchStatus = async () => {
    const { onError, onInfo } = this.props;
    const { searchHdrId, generatedSearches, completedSearchCount } = this.state;

    try {
      const response =
        await SeaTubeBroadAnnotationService.checkStatus(searchHdrId);

      if (response.length > completedSearchCount) {
        // if it's not completed yet, schedule another status check
        if (response.length < generatedSearches.length) {
          setTimeout(() => this.checkBroadSearchStatus(), CHECK_INTERVAL);
        } else {
          // otherwise, it's completed!
          onInfo(
            `Broad search ${searchHdrId} completed. Refresh table to see annotation results.`
          );
        }

        // new search results are ready to be loaded
        this.setState({
          completedSearchCount: response.length,
          searchResults: this.buildBroadSearchResults(response),
          newSearchResults: true,
          loading: response.length !== generatedSearches.length,
        });
      } else {
        setTimeout(() => this.checkBroadSearchStatus(), CHECK_INTERVAL);

        if (response.length !== completedSearchCount)
          this.setState({ newSearchResults: false });
      }
    } catch (error) {
      onError(error);
      this.setState({ loading: false });
    }
  };

  handleBroadSearchReload = () => this.setState({ newSearchResults: false });

  handleImageExportClick = () => {
    this.setState({ actionValue: ACTION_IMAGE_EXPORT });
  };

  checkForWarnings = () => {
    const { filter, tabValue } = this.state;

    if (tabValue === SeaTubeResourceTypes.EXPEDITION) {
      if (!filter.cruises) filter.cruises = [];
      this.handleSearchSubmit();
    } else {
      if (!filter.searchTreeNodeIds) filter.searchTreeNodeIds = [];

      if (filter.searchTreeNodeIds.length === 0) {
        this.openWarningDialog();
      } else {
        this.handleSearchSubmit();
      }
    }
  };

  openWarningDialog = () => {
    const { showWarningDialog } = this.state;
    if (!showWarningDialog) {
      this.setState({ showWarningDialog: true });
    }
  };

  closeWarningDialog = () => {
    const { showWarningDialog } = this.state;
    if (showWarningDialog) {
      this.setState({ showWarningDialog: false });
    }
  };

  renderWarningDialog = () => {
    const { classes } = this.props;
    const { showWarningDialog } = this.state;
    return (
      <Dialog open={showWarningDialog} onClose={this.closeWarningDialog}>
        <DialogTitle id="warning-title">
          <Warning fontSize="large" className={classes.warningIcon} />
          Warning!
        </DialogTitle>
        <DialogContent>
          <p className={classes.dialogParagraph}>
            Your search criteria may be too broad. Active searches time out
            after
            <b> 10 minutes</b>. We recommend narrowing your search parameters to
            avoid timing out.
          </p>
          Are you sure you wish to run this search?
        </DialogContent>
        <DialogActions>
          <CancelButton variant="contained" onClick={this.closeWarningDialog} />
          <TextButton
            translationKey="common.buttons.runSearch"
            onClick={this.handleSearchSubmit}
          />
        </DialogActions>
      </Dialog>
    );
  };

  closeTimeoutDialog = () => {
    this.setState({ showTimeoutDialog: false });
  };

  renderTimeoutDialog = () => {
    const { classes } = this.props;
    const { showTimeoutDialog } = this.state;
    return (
      <Dialog open={showTimeoutDialog} onClose={this.closeTimeoutDialog}>
        <DialogTitle id="warning-title">
          <ErrorIcon fontSize="large" className={classes.warningIcon} />
          Error
        </DialogTitle>
        <DialogContent>
          <p className={classes.dialogParagraph}>
            Your search returned a high volume of results and could not be
            completed within the time out range. We recommend narrowing your
            search criteria to return less results.
          </p>
        </DialogContent>
        <DialogActions>
          <ContainedButton
            translationKey="common.buttons.ok"
            onClick={this.closeTimeoutDialog}
          />
        </DialogActions>
      </Dialog>
    );
  };

  renderActionPanel = () => {
    const { classes, onError, onInfo } = this.props;
    const {
      appliedFilter,
      playlists,
      searchResults,
      diveIdString,
      isLoggedIn,
      actionValue,
      diveList,
      tabValue,
      generator,
      newSearchResults,
      loading,
      searchHdrId,
      completedSearchCount,
      generatedSearches,
      deckLogIdString,
    } = this.state;
    if (actionValue) {
      let actionContent;

      if (actionValue === ACTION_SEARCH) {
        if (tabValue !== BROAD_SEARCH_TAB) {
          actionContent = (
            <SeaTubeSearchResultsPanel
              searchResults={searchResults}
              playlists={playlists}
              filter={appliedFilter}
              diveIdString={diveIdString}
              deckLogIdString={deckLogIdString}
              onError={onError}
              onInfo={onInfo}
              onPlaylistAdd={this.getExistingPlaylists}
              isLoggedIn={isLoggedIn}
            />
          );
        } else {
          actionContent = (
            <SeaTubeBroadSearchResultsTable
              playlists={playlists}
              searchResults={searchResults}
              onError={onError}
              onInfo={onInfo}
              onPlaylistAdd={this.getExistingPlaylists}
              isLoggedIn={isLoggedIn}
              generator={generator}
              newSearchResults={newSearchResults}
              onReload={this.handleBroadSearchReload}
              loading={loading}
              searchHdrId={searchHdrId}
              percentComplete={
                (completedSearchCount / generatedSearches.length) * 100
              }
            />
          );
        }
      }

      if (actionValue === ACTION_IMAGE_EXPORT) {
        actionContent = (
          <SeaTubeStillImageExportForm
            isLoggedIn={isLoggedIn}
            diveList={diveList}
          />
        );
      }

      return (
        <Grid
          className={classes.filterContainer}
          item
          xs={FULL_WIDTH}
          lg={8}
          md={7}
        >
          <Paper className={classes.filterContainer}>{actionContent}</Paper>
        </Grid>
      );
    }
    return undefined;
  };

  renderLoading = () => {
    const { loading } = this.state;

    if (!loading) return null;

    return <LinearProgress />;
  };

  renderTabs = () => {
    const { tabValue } = this.state;
    return (
      <Tabs
        value={tabValue}
        onChange={this.handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Expedition" value={SeaTubeResourceTypes.EXPEDITION} />
        <Tab label="Broad Search" value={BROAD_SEARCH_TAB} />
      </Tabs>
    );
  };

  render() {
    const { classes, broadSearch } = this.props;
    const {
      filter,
      creatorOptions,
      modifierOptions,
      defaultFilterValues,
      tabValue,
      actionValue,
      loading,
      isLoggedIn,
    } = this.state;

    return (
      <>
        {this.renderWarningDialog()}
        {this.renderTimeoutDialog()}
        <Grid
          container
          id="seatube-search-dialog-content"
          className={classes.root}
          justifyContent={actionValue ? 'flex-start' : 'center'}
          spacing={1}
        >
          <Grid item xs={FULL_WIDTH} lg={4} md={5}>
            <Paper className={classes.filterContainer}>
              {broadSearch ? this.renderTabs() : undefined}
              <div
                hidden={tabValue !== SeaTubeResourceTypes.EXPEDITION}
                className={classes.marginTop}
              >
                <AnnotationFilter
                  context="search"
                  initialFilterValues={defaultFilterValues}
                  creatorOptions={creatorOptions}
                  modifierOptions={modifierOptions}
                  onChange={this.handleFilterChange}
                  onReset={this.handleReset}
                  filter={filter}
                  onImageExportClick={this.handleImageExportClick}
                  tabValue={tabValue}
                  onDiveOptionsLoaded={this.handleDiveOptionsLoaded}
                  onSubmit={this.checkForWarnings}
                  isLoggedIn={isLoggedIn}
                  actionValue={!!actionValue}
                />
              </div>
              <div hidden={tabValue !== BROAD_SEARCH_TAB}>
                <BroadSearchFilter
                  context="search"
                  initialFilterValues={defaultFilterValues}
                  creatorOptions={creatorOptions}
                  modifierOptions={modifierOptions}
                  onChange={this.handleFilterChange}
                  filter={filter}
                  onImageExportClick={this.handleImageExportClick}
                  tabValue={tabValue}
                  onDiveOptionsLoaded={this.handleDiveOptionsLoaded}
                  onSubmit={this.handleInitialBroadSearchClick}
                  loading={loading}
                  onReset={this.handleReset}
                />
              </div>
              {this.renderLoading()}
            </Paper>
          </Grid>
          {this.renderActionPanel()}
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbars(SeaTubeSearchContent));
