import moment, { Moment } from 'moment';
import { useForm } from 'react-hook-form';
import { Dialog, DialogContent, DialogTitle, Grid } from 'base-components';
import { ReadOnlyField } from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import {
  CancelButton,
  SaveButton,
} from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';

const classes = {
  root: {
    display: 'flex',
    width: '100%',
  },
  leftJustify: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  leftAlign: {
    marginLeft: 1,
  },
  rightAlign: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

type DriverScheduleConfigForm = {
  driverScheduleId: number;
  description: string;
  deviceId: string;
  enabled: string;
  interfaceName: string;
  methodName: string;
  scheduleHour: string;
  scheduleMinute: string;
  scheduleSecond: string;
  repeatHour: string;
  repeatMinute: string;
  repeatSecond: string;
  param1: string;
  param2: string;
  param3: string;
  param4: string;
  param5: string;
  scheduleYear: string;
  scheduleMonth: string;
  scheduleDayOfMonth: string;
  repeatDay: string;
  endDate: Moment;
  modifyBy: string;
  modifyDate: string;
};

type DriverScheduleConfigProps = {
  title: string;
  onSave: (form: object) => void;
  onCancel: () => void;
  driverSchedule?: {
    driverScheduleId: number;
    description: string;
    deviceId: number;
    enabled: string;
    interfaceName: string;
    methodName: string;
    scheduleHour: number;
    scheduleMinute: number;
    scheduleSecond: number;
    repeatHour: number;
    repeatMinute: number;
    repeatSecond: number;
    param1: string;
    param2: string;
    param3: string;
    param4: string;
    param5: string;
    scheduleYear: number;
    scheduleMonth: number;
    scheduleDayOfMonth: number;
    repeatDay: number;
    endDate: string;
    modifyBy: string;
    modifyDate: string;
    hiddenDeviceIdForSearch: string;
  };
};

const DriverScheduleConfig = ({
  title,
  onSave,
  onCancel,
  driverSchedule = {
    driverScheduleId: undefined,
    description: undefined,
    deviceId: undefined,
    enabled: undefined,
    interfaceName: undefined,
    methodName: undefined,
    scheduleHour: undefined,
    scheduleMinute: undefined,
    scheduleSecond: undefined,
    repeatHour: undefined,
    repeatMinute: undefined,
    repeatSecond: undefined,
    param1: undefined,
    param2: undefined,
    param3: undefined,
    param4: undefined,
    param5: undefined,
    scheduleYear: undefined,
    scheduleMonth: undefined,
    scheduleDayOfMonth: undefined,
    repeatDay: undefined,
    endDate: undefined,
    modifyBy: undefined,
    modifyDate: undefined,
    hiddenDeviceIdForSearch: undefined,
  },
}: DriverScheduleConfigProps) => {
  const formMethods = useForm<DriverScheduleConfigForm>({
    mode: 'onBlur',
    defaultValues: {
      driverScheduleId: driverSchedule?.driverScheduleId,
      description: driverSchedule?.description || '',
      deviceId: driverSchedule?.hiddenDeviceIdForSearch?.toString() || '',
      enabled: driverSchedule?.enabled || 'False',
      interfaceName: driverSchedule?.interfaceName || '',
      methodName: driverSchedule?.methodName || '',
      scheduleHour: driverSchedule?.scheduleHour?.toString() || '',
      scheduleMinute: driverSchedule?.scheduleMinute?.toString() || '',
      scheduleSecond: driverSchedule?.scheduleSecond?.toString() || '',
      repeatHour: driverSchedule?.repeatHour?.toString() || '',
      repeatMinute: driverSchedule?.repeatMinute?.toString() || '',
      repeatSecond: driverSchedule?.repeatSecond?.toString() || '',
      param1: driverSchedule?.param1 || '',
      param2: driverSchedule?.param2 || '',
      param3: driverSchedule?.param3 || '',
      param4: driverSchedule?.param4 || '',
      param5: driverSchedule?.param5 || '',
      scheduleYear: driverSchedule?.scheduleYear?.toString() || '',
      scheduleMonth: driverSchedule?.scheduleMonth?.toString() || '',
      scheduleDayOfMonth: driverSchedule?.scheduleDayOfMonth?.toString() || '',
      repeatDay: driverSchedule?.repeatDay?.toString() || '',
      endDate: driverSchedule?.endDate
        ? moment.utc(driverSchedule.endDate)
        : undefined,
      modifyBy: driverSchedule?.modifyBy || '',
      modifyDate: driverSchedule?.modifyDate || '',
    },
  });

  const driverScheduleId = () => {
    if (driverSchedule?.driverScheduleId !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="driverScheduleId"
            labelText="Driver Schedule ID"
            valueText={driverSchedule.driverScheduleId}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const description = () => (
    <Grid item xs={12}>
      <FormTextField
        name="description"
        translationKey="common.textfields.description"
        placeholder="Enter Description"
        fullWidth
        rules={{ required: 'A description is required' }}
      />
    </Grid>
  );

  const deviceId = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.deviceId"
        placeholder="Enter Device ID"
        name="deviceId"
        type="number"
        rules={{ required: 'A device ID is required' }}
        fullWidth
      />
    </Grid>
  );

  const enabled = () => (
    <Grid item xs={12}>
      <FormDropdown
        label="Enabled"
        name="enabled"
        options={[
          { label: 'True', value: 'True', key: '1' },
          { label: 'False', value: 'False', key: '2' },
        ]}
        fullWidth
        rules={{ required: 'An option is required!' }}
      />
    </Grid>
  );

  const interfaceName = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.interfaceName"
        placeholder="Enter Interface Name"
        name="interfaceName"
        fullWidth
      />
    </Grid>
  );

  const methodName = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.methodName"
        placeholder="Enter Method Name"
        name="methodName"
        fullWidth
      />
    </Grid>
  );

  const scheduleHour = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.scheduleHour"
        placeholder="Enter Schedule Hour"
        name="scheduleHour"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const scheduleMinute = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.scheduleMinute"
        placeholder="Enter Schedule Minute"
        name="scheduleMinute"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const scheduleSecond = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.scheduleSecond"
        placeholder="Enter Schedule Second"
        name="scheduleSecond"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const repeatHour = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.repeatHour"
        placeholder="Enter Repeat Hour"
        name="repeatHour"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const repeatMinute = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.repeatMinute"
        placeholder="Enter Repeat Minute"
        name="repeatMinute"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const repeatSecond = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.repeatSecond"
        placeholder="Enter Repeat Second"
        name="repeatSecond"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const param1 = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.indexedParam"
        translationOptions={{ paramNumber: 1 }}
        placeholder="Enter Param 1"
        name="param1"
        fullWidth
      />
    </Grid>
  );

  const param2 = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.indexedParam"
        translationOptions={{ paramNumber: 2 }}
        placeholder="Enter Param 2"
        name="param2"
        fullWidth
      />
    </Grid>
  );

  const param3 = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.indexedParam"
        translationOptions={{ paramNumber: 3 }}
        placeholder="Enter Param 3"
        name="param3"
        fullWidth
      />
    </Grid>
  );

  const param4 = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.indexedParam"
        translationOptions={{ paramNumber: 4 }}
        placeholder="Enter Param 4"
        name="param4"
        fullWidth
      />
    </Grid>
  );

  const param5 = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.indexedParam"
        translationOptions={{ paramNumber: 5 }}
        placeholder="Enter Param 5"
        name="param5"
        fullWidth
      />
    </Grid>
  );

  const scheduleYear = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.scheduleYear"
        placeholder="Enter Schedule Year"
        name="scheduleYear"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const scheduleMonth = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.scheduleMonth"
        placeholder="Enter Schedule Month"
        name="scheduleMonth"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const scheduleDayOfMonth = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.scheduleDayOfMonth"
        placeholder="Enter Schedule Day of Month"
        name="scheduleDayOfMonth"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const repeatDay = () => (
    <Grid item xs={12}>
      <FormTextField
        translationKey="device.repeatDay"
        placeholder="Enter Repeat Day"
        name="repeatDay"
        type="number"
        fullWidth
      />
    </Grid>
  );

  const endDate = () => (
    <Grid item xs={12}>
      <FormDateTimePicker
        translationKey="common.datepickers.endDate"
        name="endDate"
        fullWidth
      />
    </Grid>
  );

  const modifyBy = () => {
    if (driverSchedule.modifyDate !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="modifyBy"
            labelText="Modify By"
            valueText={driverSchedule.modifyBy}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const modifyDate = () => {
    if (driverSchedule.modifyDate !== undefined) {
      return (
        <Grid item xs={12}>
          <ReadOnlyField
            title="modifyDate"
            labelText="Modify Date"
            valueText={driverSchedule.modifyDate}
          />
        </Grid>
      );
    }
    return <></>;
  };

  const handleSave = (form: DriverScheduleConfigForm) => {
    const values = {
      ...form,
      deviceId: Number(form.deviceId),
      scheduleHour:
        form.scheduleHour !== '' ? Number(form.scheduleHour) : undefined,
      scheduleMinute:
        form.scheduleMinute !== '' ? Number(form.scheduleMinute) : undefined,
      scheduleSecond:
        form.scheduleSecond !== '' ? Number(form.scheduleSecond) : undefined,
      repeatHour: form.repeatHour !== '' ? Number(form.repeatHour) : undefined,
      repeatMinute:
        form.repeatMinute !== '' ? Number(form.repeatMinute) : undefined,
      repeatSecond:
        form.repeatSecond !== '' ? Number(form.repeatSecond) : undefined,
      scheduleYear:
        form.scheduleYear !== '' ? Number(form.scheduleYear) : undefined,
      scheduleMonth:
        form.scheduleMonth !== '' ? Number(form.scheduleMonth) : undefined,
      scheduleDayOfMonth:
        form.scheduleDayOfMonth !== ''
          ? Number(form.scheduleDayOfMonth)
          : undefined,
      repeatDay: form.repeatDay !== '' ? Number(form.repeatDay) : undefined,
      endDate: form.endDate?.format('YYYY-MM-DD HH:mm:ss'),
    };

    Object.keys(values).forEach(
      (key) => values[key] === undefined && delete values[key]
    );

    onSave(values);
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSave} formMethods={formMethods}>
          <Grid container spacing={1} sx={classes.root}>
            {driverScheduleId()}
            {description()}
            {deviceId()}
            {enabled()}
            {interfaceName()}
            {methodName()}
            {scheduleHour()}
            {scheduleMinute()}
            {scheduleSecond()}
            {repeatHour()}
            {repeatMinute()}
            {repeatSecond()}
            {param1()}
            {param2()}
            {param3()}
            {param4()}
            {param5()}
            {scheduleYear()}
            {scheduleMonth()}
            {scheduleDayOfMonth()}
            {repeatDay()}
            {endDate()}
            {modifyBy()}
            {modifyDate()}
          </Grid>
          <Grid item sx={classes.rightAlign}>
            <CancelButton onClick={onCancel} />
            <SaveButton type="submit" />
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DriverScheduleConfig;
