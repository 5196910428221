import { Children, cloneElement, Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ResizableJWPlayer } from 'base-components';
import Types from 'util/custom-prop-types/CustomPropTypes';

const styles = {
  root: {
    // Allow the time tooltip to overflow the video container
    '& .jw-controls,[class*="jw-breakpoint-"]': {
      overflow: 'visible',
    },
    '& .jw-breakpoint-1,.jw-breakpoint-2,.jw-breakpoint-3': {
      '& .jw-button-container': {
        flexFlow: 'row nowrap',
      },
      '& .jw-reset.jw-spacer': {
        padding: '4px',
      },
    },
    '& .jw-breakpoint-0,.jw-flag-small-player': {
      '& div[aria-label="Mute"], div[aria-label="Unmute"]': {
        display: 'none',
      },

      '& .jw-text-countdown': {
        display: 'none !important',
      },

      '& .jw-button-container': {
        flexFlow: 'row nowrap',
        '& .jw-icon': {
          width: '30px',
        },
      },
    },
    '& .jw-controlbar': {
      maxHeight: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    '& .jw-button-container': {
      flexFlow: 'row wrap',
      '& .jw-button-image': {
        maxWidth: '24px',
      },
    },
    '& .jw-reset.jw-spacer': {
      order: 10,
    },
    '& div[aria-label="Fullscreen"], div[aria-label="Exit Fullscreen"]': {
      order: 14,
    },
    /*
    We need to add display: none as actually removing the settings button from the
    DOM causes an issue with playlists. By just hiding the button, we are able to resolve
    the issue.
  */
    '& .jw-icon-settings': {
      display: 'none',
    },

    '& div[aria-label="Settings"]': {
      order: 13,
    },
    '& .jw-text-duration': {
      order: 9,
    },
    '& .jw-text-elapsed': {
      order: 8,
    },
    '& .jw-text-countdown': {
      order: 7,
    },
    '& div[aria-label="Mute"], div[aria-label="Unmute"]': {
      order: 6,
    },
    '& div[aria-label="Forward 30 Seconds"]': {
      order: 5,
    },
    '& div[aria-label="Back 30 Seconds"]': {
      order: 4,
    },
    '& div[aria-label="Next"]': {
      order: 3,
    },
    '& div[aria-label="Pause"], div[aria-label="Play"], div[aria-label="Stop"]':
      {
        order: 2,
      },
    '& div[aria-label="Next Clip"]': {
      order: 4,
    },
    '& div[aria-label="Previous Clip"]': {
      order: 1,
      '& svg': {
        transform: 'scaleX(-1)',
      },
    },
    '& div[aria-label="Latest Clip"]': {
      order: 4,
    },
    '& div[aria-label="First Clip"]': {
      order: 0,
      '& svg': {
        transform: 'scaleX(-1)',
      },
    },
    '& .jw-icon-rewind': {
      display: 'none',
    },
    '& div[aria-label="Closed Captions"]': {
      display: 'none',
    },
  },
};

class Video extends Component {
  static propTypes = {
    // Required
    playerId: PropTypes.string.isRequired,
    playlist: PropTypes.arrayOf(PropTypes.shape({ file: PropTypes.string })),
    file: Types.mutuallyExclusiveWith(PropTypes.string, 'playlist'),
    // Booleans
    autoPlay: PropTypes.bool,
    isLive: PropTypes.bool,
    muted: PropTypes.bool,
    showControls: PropTypes.bool,
    showPlaylistOverlay: PropTypes.bool,
    showRateControls: PropTypes.bool,
    loopPlayback: PropTypes.bool,

    // Functions
    onTime: PropTypes.func,
    onPlayerReady: PropTypes.func,
    onCaptionListChange: PropTypes.func,
    onCompleted: PropTypes.func,
    // Other
    aspectratio: PropTypes.string,
    className: PropTypes.string,
    speed: PropTypes.number,
    captions: PropTypes.number,
    classes: PropTypes.objectOf(PropTypes.string),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    buttons: PropTypes.shape({
      add: PropTypes.arrayOf(
        PropTypes.shape({
          img: PropTypes.node.isRequired,
          tooltip: PropTypes.string.isRequired,
          callback: PropTypes.func.isRequired,
          id: PropTypes.string.isRequired,
          btnClass: PropTypes.string,
        })
      ),
      remove: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  static defaultProps = {
    autoPlay: false,
    muted: true,
    isLive: false,
    showControls: true,
    showRateControls: true,
    showPlaylistOverlay: false,
    onTime: null,
    onPlayerReady: null,
    onCaptionListChange: null,
    onCompleted: null,
    aspectratio: undefined,
    captions: 0,
    children: undefined,
    classes: undefined,
    className: '',
    playlist: undefined,
    file: undefined,
    buttons: {
      add: undefined,
      remove: undefined,
    },
    speed: 1,
    loopPlayback: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      playerReady: false,
      newPlaylist: [],
    };
  }

  componentDidUpdate(prev) {
    const {
      buttons: { add, remove },
      captions,
      file,
      playlist,
      speed,
    } = this.props;

    if (
      remove &&
      JSON.stringify(prev.buttons.remove) !== JSON.stringify(remove)
    ) {
      this.removeButtons(remove);
    }

    if (add && JSON.stringify(prev.buttons.add) !== JSON.stringify(add)) {
      this.addCustomButtons(add);
    }

    if (
      JSON.stringify(prev.playlist) !== JSON.stringify(playlist) ||
      prev.file !== file
    ) {
      this.handleCaptionListChange();
    }

    if (prev.captions !== captions) {
      this.setCaptions(captions);
    }

    if (prev.speed !== speed) {
      this.setSpeed(speed);
    }
  }

  handleTimeChange = (event) => {
    const { onTime } = this.props;
    if (!onTime) return;
    onTime(event.position);
  };

  handlePlayerReady = () => {
    const {
      onPlayerReady,
      buttons: { add, remove },
    } = this.props;

    this.handleVideoEnd();
    this.handleCaptionListChange();

    if (remove) {
      this.removeButtons(remove);
    }
    if (add) {
      this.addCustomButtons(add);
    }

    if (onPlayerReady)
      onPlayerReady({
        querySelector: this.querySelector,
        dispatchEvent: this.dispatchEvent,
        stop: this.stop,
        seek: this.seek,
        getPosition: this.getPosition,
        getPlaylistIndex: this.getPlaylistIndex,
      });
    this.setState({
      playerReady: true,
    });
  };

  handleCaptionListChange = () => {
    const { onCaptionListChange } = this.props;
    const player = this.getPlayer();
    if (!player || !onCaptionListChange) return;
    onCaptionListChange(player.getCaptionsList(), player.getCurrentCaptions());
  };

  setSpeed = (speed) => {
    const player = this.getPlayer();
    if (!player) return;
    player.setPlaybackRate(speed);
  };

  setCaptions = (index) => {
    const player = this.getPlayer();
    if (!player || index < 0) return;
    player.setCurrentCaptions(index);
  };

  getPlayer = () => {
    const { playerId } = this.props;
    return window.jwplayer ? window.jwplayer(playerId) : undefined;
  };

  getPlayerContainer = () => {
    const player = this.getPlayer();
    return player ? player.getContainer() : undefined;
  };

  querySelector = (query) => {
    const playerContainer = this.getPlayerContainer();
    return playerContainer ? playerContainer.querySelector(query) : undefined;
  };

  dispatchEvent = (event) => {
    const player = this.getPlayer();
    return player ? player.dispatchEvent(event) : undefined;
  };

  play = () => {
    const player = this.getPlayer();
    return player ? player.play() : undefined;
  };

  handleVideoEnd = () => {
    const { playlist, onCompleted } = this.props;
    const player = this.getPlayer();
    player.on('complete', async () => {
      if (onCompleted) {
        const newlist = await onCompleted();
        // Check for more recent video file & update the playlist
        if (newlist[0]?.file !== playlist[0]?.file) {
          this.setState({
            newPlaylist: newlist,
          });
        }
      }
    });
  };

  pause = () => {
    const player = this.getPlayer();
    return player ? player.pause() : undefined;
  };

  stop = () => {
    const player = this.getPlayer();
    return player ? player.stop() : undefined;
  };

  seek = (time) => {
    const player = this.getPlayer();
    return player ? player.seek(time) : undefined;
  };

  getFullscreen = () => {
    const player = this.getPlayer();
    return player ? player.getFullscreen() : undefined;
  };

  getPosition = () => {
    const player = this.getPlayer();
    return player ? player.getPosition() : 0;
  };

  getPlaylistIndex = () => {
    const player = this.getPlayer();
    return player ? player.getPlaylistIndex() : 0;
  };

  addCustomButtons = (customButtons) => {
    const player = this.getPlayer();
    if (!player) return;
    customButtons.forEach((customButton) => {
      const { img, tooltip, callback, id, btnClass } = customButton;
      player.addButton(img, tooltip, callback, id, btnClass);
    });
  };

  removeButtons = (buttons) => {
    const player = this.getPlayer();
    if (!player) return; // TODO handle error
    buttons.forEach((buttonId) => {
      player.removeButton(buttonId);
    });
  };

  renderVideoPlayerChildren = () => {
    const { children } = this.props;
    const { playerReady } = this.state;

    if (!playerReady) return undefined;

    const elements = Children.toArray(children).map((element) =>
      cloneElement(element, {
        querySelector: this.querySelector,
        play: this.play,
        pause: this.pause,
        getFullscreen: this.getFullscreen,
      })
    );

    return elements;
  };

  render() {
    const {
      aspectratio,
      autoPlay,
      children,
      classes,
      className,
      muted,
      onTime,
      showControls,
      showPlaylistOverlay,
      showRateControls,
      playlist,
      loopPlayback,
      ...otherProps
    } = this.props;
    const { newPlaylist } = this.state;
    return (
      <>
        <ResizableJWPlayer
          {...otherProps}
          className={`${className} ${classes.root}`}
          customProps={{
            controls: showControls,
            mute: muted,
            playbackRateControls: showRateControls,
            aspectratio,
            nextUpDisplay: showPlaylistOverlay,
            repeat: loopPlayback,
          }}
          isAutoPlay={autoPlay}
          playlist={newPlaylist.length ? newPlaylist : playlist}
          onReady={this.handlePlayerReady}
          onTime={this.handleTimeChange}
          onCaptionsList={this.handleCaptionListChange}
        />
        {this.renderVideoPlayerChildren()}
      </>
    );
  }
}
export default withStyles(styles)(Video);
