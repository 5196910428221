import { PureComponent } from 'react';
import qs from 'qs';
import SeaTubeSearchContent from 'domain/AppComponents/seatube/search/SeaTubeSearchContent';

const FILTERS = [
  'depth',
  'obisCount',
  'oxygen',
  'salinity',
  'temperature',
  'creators',
  'modifiers',
  'comment',
  'toBeReviewed',
  'filterText',
  'attributes',
  'date',
  'taxonomy',
  'reviews',
];

const NUMBER_FILTERS_VALIDATE = ['diveIds', 'cruiseIds', 'searchTreeNodeIds'];

class SeaTubeSearch extends PureComponent {
  constructor(props) {
    super(props);
    // TODO: assess if input validation is needed here to prevent injection attacks

    const initialFilter = {};

    for (const filterName of FILTERS) {
      if (window.sessionStorage.getItem(filterName)) {
        initialFilter[filterName] = JSON.parse(
          window.sessionStorage.getItem(filterName)
        );
      }
    }

    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    // Prepare values that need further processing
    const initialFilterValues = {};
    for (const filterName of NUMBER_FILTERS_VALIDATE) {
      if (params[filterName]) {
        initialFilterValues[filterName] = params[filterName]
          .split(',')
          .map(Number);
      }
    }

    this.state = {
      initialFilterValues,
      initialFilter,
      includeDeckLog: params.includeDeckLog !== undefined,
      location: params.location,
      broadSearch: params.broadSearch,
    };
  }

  render() {
    const {
      initialFilterValues,
      initialFilter,
      location,
      broadSearch,
      includeDeckLog,
    } = this.state;
    return (
      <SeaTubeSearchContent
        initialFilter={initialFilter}
        initialFilterValues={initialFilterValues}
        includeDeckLog={includeDeckLog}
        location={location !== undefined}
        broadSearch={broadSearch !== undefined}
      />
    );
  }
}

export default SeaTubeSearch;
