import { Component } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  ImageList,
  ImageListItem,
  Lightbox,
} from 'base-components';
import { ContainedButton } from '../button/Buttons';

const styles = {
  appBar: {
    position: 'relative',
  },
};

export class ImageListDialogBase extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    imageUrls: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    open: PropTypes.bool,
    title: PropTypes.string,
    maxBreakpoint: PropTypes.string,
    gridCols: PropTypes.number,
    classes: PropTypes.shape({
      appBar: PropTypes.string,
    }),
    container: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    loading: PropTypes.bool,
    numSnapshots: PropTypes.number.isRequired,
  };

  static defaultProps = {
    open: false,
    title: '',
    maxBreakpoint: 'sm',
    gridCols: 3,
    classes: {},
    container: undefined,
    imageUrls: [],
    loading: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      lightboxOpen: false,
      currentImage: null,
      loadedImages: {},
    };
  }

  /**
   * Sets state so that lightbox will be rendered
   *
   * @param image - String url of image to first display in lightbox
   */
  handleOpenLightbox = (image) => {
    setCurrentImage(image);
    setLightboxOpen(true);
  };

  /** Sets state so that lightbox is not rendered */

  handleImageLoad = (key) => {
    this.setState((prevState) => ({
      loadedImages: { ...prevState.loadedImages, [key]: true },
    }));
  };

  /**
   * Returns a Lightbox component if lightboxOpen state is true.
   *
   * @returns Lightbox if lightboxOpen state is true, null otherwise.
   */
  renderLightbox = (lightboxOpen) => {
    const handleCloseLightbox = () => {
      this.setState({
        currentImage: null,
        lightboxOpen: false,
      });
    };

    if (lightboxOpen) {
      return (
        <Lightbox
          onClose={handleCloseLightbox}
          imageUrls={imageUrls}
          initialImageUrl={currentImage}
        />
      );
    }
    return null;
  };

  /**
   * Returns a ImageListItem
   *
   * @param imageUrl - String url of image
   * @param key - String key for ImageListItem
   * @returns ImageListItem component
   */
  renderImageListItem = (imageUrl, key) => {
    const { loading } = this.props;
    const { loadedImages } = this.state;
    const imageLoaded = loadedImages[key];
    return (
      <ImageListItem
        key={key}
        loading={loading}
        onClick={() => {
          this.handleOpenLightbox(imageUrl);
        }}
      >
        {!imageLoaded && <Skeleton variant="rect" width={180} height="100%" />}
        <img
          src={imageUrl}
          alt={`${key}`}
          onLoad={() => this.handleImageLoad(key)}
        />
      </ImageListItem>
    );
  };

  /**
   * Renders ImageListItems for all urls in imageUrls prop
   *
   * @returns Array of ImageListItems
   */
  renderImageListItems = () => {
    const { imageUrls, numSnapshots } = this.props;
    const gridListTiles = [];
    for (let i = 0; i < numSnapshots; i += 1) {
      gridListTiles.push(this.renderImageListItem(imageUrls[i], `image-${i}`));
    }
    return gridListTiles;
  };

  render() {
    const { container, gridCols, maxBreakpoint, onClose, open, title } =
      this.props;
    const { lightboxOpen } = this.state;

    return (
      <>
        <Dialog
          open={open && !lightboxOpen}
          maxWidth={maxBreakpoint}
          onClose={onClose}
          container={container}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <ImageList cols={gridCols} style={{ overflowY: 'hidden' }}>
              {this.renderImageListItems()}
            </ImageList>
          </DialogContent>
          <DialogActions>
            <ContainedButton
              translationKey="common.buttons.done"
              onClick={onClose}
            />
          </DialogActions>
        </Dialog>
        {this.renderLightbox(lightboxOpen)}
      </>
    );
  }
}

export default withStyles(styles)(ImageListDialogBase);
