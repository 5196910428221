import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'base-components';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import ResizingInfoList from 'library/CompositeComponents/list-container/ResizingInfoList';
import InfoItem from 'library/CompositeComponents/list-items/InfoItem';

type Props = {
  open: boolean;
  title?: string;
  infoMap: Map<string, any>;
  container: HTMLElement | null;
  onClose: () => void;
};

const VideoInformationDialog: React.FC<Props> = ({
  open,
  container,
  onClose,
  infoMap,
  title = 'Video Information',
}: Props) => (
  <Dialog
    key="video-info-dialog"
    container={container}
    onClose={onClose}
    open={open}
    maxWidth="md"
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <ResizingInfoList>
        {Array.from(new Map([...infoMap].sort()), (item) => (
          <InfoItem key={item[0]} primary={item[0]} secondary={item[1]} />
        ))}
      </ResizingInfoList>
    </DialogContent>
    <DialogActions>
      <TextButton translationKey="common.buttons.close" onClick={onClose} />
    </DialogActions>
  </Dialog>
);

export default VideoInformationDialog;
