import { memo, ChangeEvent, ReactNode } from 'react';
import {
  FormControl,
  FormControlLabel,
  RadioButton,
  RadioGroup,
  Typography,
} from 'base-components';

export interface RadioOption {
  value: string;
  label: ReactNode;
}

interface Props {
  header?: string;
  radioOptions: RadioOption[];
  value?: string | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StepPartRadioGroup = ({
  header = '',
  radioOptions,
  value = '',
  onChange,
}: Props) => (
  <>
    {header && <Typography variant="h6">{header}</Typography>}
    <FormControl>
      <RadioGroup onChange={onChange} value={value}>
        {radioOptions.map((radioOption) => (
          <FormControlLabel
            key={radioOption.value} // a console warning wants a key
            value={radioOption.value}
            control={<RadioButton />}
            label={radioOption.label}
            checked={radioOption.value === value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  </>
);

export default memo(StepPartRadioGroup);
