import moment from 'moment';
import { parseDmasAPIResponse, get } from 'util/WebRequest';

class DevicePortService {
  static get(deviceId, startDate) {
    return get('DevicePortService', {
      deviceid: deviceId,
      effectivedate: startDate || moment().format('DD-MMM-YYYY h:mm:ss'),
      portdirection: 'IN',
    }).then((response) => parseDmasAPIResponse(response));
  }
}

export default DevicePortService;
