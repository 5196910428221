import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { createStyles, makeStyles } from '@mui/styles';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { Add } from '@onc/icons';
import { DenseThemeProvider } from '@onc/theme';
import { ExpandableSearchBar, Grid, Paper } from 'base-components';
import DashboardActions from 'domain/AppComponents/Dashboard/dashboard-table/DashboardActions';
import DashboardLink from 'domain/AppComponents/Dashboard/dashboard-table/DashboardLink';
import {
  COLUMNS,
  COLUMN_EXTENSIONS,
  COLUMN_SORTING,
  DEFAULT_PAGE,
  DEFAULT_PAGESIZE,
  DEFAULT_SORTING,
  DEFAULT_DIALOG_INDICATOR,
  PAGE_SIZES,
  SORTING_COLUMN_EXTENSIONS,
  LAYOUT_SERVICE,
  DASHBOARD_RESOURCE_TYPE_ID,
  DASHBOARD_RESOURCE_ID,
} from 'domain/AppComponents/Dashboard/dashboard-table/DashboardTableConstants';
import ShareDialog from 'domain/AppComponents/Dashboard/ShareDialog';
import {
  DeleteDashboardDialog,
  SaveDialog,
} from 'domain/AppComponents/dialogs/Dialogs';
import StatelessTable from 'library/BaseComponents/table/StatelessTable';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import DateFormatUtils from 'util/DateFormatUtils';
import useGet from 'util/hooks/useDmasAPI/useGet';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';
import type {
  LayoutServiceCloneParameters,
  LayoutServiceCreateParameters,
  LayoutServiceDashboardParameters,
  LayoutServiceDashboardResponse,
  LayoutServiceDeleteParameters,
  LayoutServicePostResponse,
  LayoutServiceUpdateParameters,
  ShowDialogIndicator,
  UserDetailsDashboardParameters,
  UserDetailsDashboardResponse,
} from 'domain/AppComponents/Dashboard/dashboard-table/DashboardTableTypes';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      float: 'right',
      minWidth: 100,
      maxHeight: 60,
    },
    searchButton: {
      float: 'right',
      minWidth: 50,
      maxHeight: 60,
    },
  })
);

const formatModifyBy = ({
  row,
}: PropsWithChildren<DataTypeProvider.ValueFormatterProps>) => (
  <>{DateFormatUtils.formatDate(new Date(row.modifyDate), 'full')}</>
);

type DashboardTableProps = {
  match: { path: string };
  ownerId?: number;
};

const DashboardTable: React.FC<DashboardTableProps> = ({
  match,
  ownerId = 0,
}: DashboardTableProps) => {
  const classes = useStyles();
  const { onInfo, onError } = useSnackbars();

  const [currentPage, onCurrentPageChange] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE);
  const [sorting, setSorting] = useState(DEFAULT_SORTING);
  const [search, setSearch] = useState('');

  const { data: layoutData, invalidateQuery: invalidateLayoutQuery } = useGet<
    LayoutServiceDashboardResponse,
    LayoutServiceDashboardParameters
  >(LAYOUT_SERVICE, 101, {
    resourceTypeId: DASHBOARD_RESOURCE_TYPE_ID,
    resourceId: DASHBOARD_RESOURCE_ID,
    ownerId,
    page: currentPage,
    pageSize,
    sortField: sorting[0].columnName,
    ascending: sorting[0].direction === 'asc',
    search,
  });

  useEffect(() => {
    if (layoutData) {
      if (currentPage * pageSize > layoutData.totalLayouts) {
        // if no layouts on the current page, drop page to the last page with layouts
        const newPage = Math.ceil(layoutData.totalLayouts / pageSize) - 1;
        onCurrentPageChange(newPage);
      }
    }
  }, [currentPage, layoutData, pageSize]);

  const { data: userData } = useGet<
    UserDetailsDashboardResponse,
    UserDetailsDashboardParameters
  >('UserDetailsService', 100, {
    pageName: 'Dashboards',
  });

  const [dialogIndicator, setDialogIndicator] = useState<ShowDialogIndicator>(
    DEFAULT_DIALOG_INDICATOR
  );

  const resetDialogIndicator = useCallback(() => {
    setDialogIndicator(DEFAULT_DIALOG_INDICATOR);
  }, []);

  const closeDialogsAndRefreshDashboards = useCallback(async () => {
    invalidateLayoutQuery();
    resetDialogIndicator();
  }, [invalidateLayoutQuery, resetDialogIndicator]);

  const { mutate: doDelete } = usePost<
    LayoutServiceDeleteParameters,
    LayoutServicePostResponse
  >(
    LAYOUT_SERVICE,
    {
      onSuccess: () => {
        closeDialogsAndRefreshDashboards();
        onInfo('Dashboard Deleted');
      },
      onError: () => {
        onError(
          `Failed to delete dashboard ${dialogIndicator.layout.layoutId}`
        );
      },
    },
    3
  );

  const { mutate: doShare } = usePost<
    LayoutServiceUpdateParameters,
    LayoutServicePostResponse
  >(
    LAYOUT_SERVICE,
    {
      onSuccess: () => {
        onInfo('Dashboard Shared');
      },
      onError: () => onError('Error Sharing Dashboard'),
    },
    2
  );

  const { mutate: doTogglePublic } = usePost<
    LayoutServiceUpdateParameters,
    LayoutServicePostResponse
  >(
    LAYOUT_SERVICE,
    {
      onSuccess: (response) => {
        invalidateLayoutQuery();
        onInfo(
          `${response.layout.layoutName} is now ${response.layout.isPublic ? 'Public' : 'Private'}`
        );
      },
      onError: () => onError('Error Toggling Public for Dashboard'),
    },
    2
  );

  const {
    data: cloneData,
    mutate: doClone,
    status: cloneStatus,
  } = usePost<LayoutServiceCloneParameters, LayoutServicePostResponse>(
    LAYOUT_SERVICE,
    {
      onSuccess: () => {
        // check if this is needed, its one of the redirects
        closeDialogsAndRefreshDashboards();
        onInfo('Dashboard Cloned.');
      },
      onError: () => {
        onError('Failed to clone Dashboard');
      },
    },
    105
  );

  const {
    data: addData,
    mutate: doAdd,
    status: addStatus,
  } = usePost<LayoutServiceCreateParameters, LayoutServicePostResponse>(
    LAYOUT_SERVICE,
    {
      onSuccess: () => {
        onInfo('New Dashboard Created.');
      },
      onError: () => {
        onError('Error Creating Dashboard');
      },
    },
    DASHBOARD_RESOURCE_ID
  );

  const formatLink = useCallback(
    ({ row }: PropsWithChildren<DataTypeProvider.ValueFormatterProps>) => (
      <DashboardLink layout={row} match={match} />
    ),
    [match]
  );

  const formatAction = useCallback(
    ({ row }: PropsWithChildren<DataTypeProvider.ValueFormatterProps>) => (
      <DashboardActions
        layout={row}
        user={userData}
        handleToggle={async () => {
          doTogglePublic({
            resourceTypeId: DASHBOARD_RESOURCE_TYPE_ID,
            resourceId: DASHBOARD_RESOURCE_ID,
            isPublic: !row.isPublic,
            layoutId: row.layoutId,
            ownerId: row.ownerId,
            widgetLayout: row.widgetLayout,
          });
          invalidateLayoutQuery();
        }}
        showDialog={setDialogIndicator}
      />
    ),
    [doTogglePublic, invalidateLayoutQuery, userData]
  );

  if (cloneStatus === 'success') {
    return (
      <Redirect push to={`${match.path}/id/${cloneData.layout?.layoutId}`} />
    );
  }

  if (addStatus === 'success') {
    return <Redirect push to={`${match.path}/id/${addData.layout.layoutId}`} />;
  }

  return (
    <>
      <DenseThemeProvider>
        <DeleteDashboardDialog
          open={dialogIndicator.dialog === 'DELETE'}
          onCancel={resetDialogIndicator}
          onConfirm={() => {
            doDelete({
              resourceTypeId: DASHBOARD_RESOURCE_TYPE_ID,
              resourceId: DASHBOARD_RESOURCE_ID,
              layoutId: dialogIndicator.layout?.layoutId ?? 0,
            });
          }}
          dashboardTitle={dialogIndicator.layout?.layoutName ?? ''}
        />
        <ShareDialog
          open={dialogIndicator.dialog === 'SHARE'}
          sharedWith={dialogIndicator.layout?.sharedAccounts ?? []}
          onPermissionChange={(dashboardId: number, closeDialog: boolean) => {
            if (closeDialog) {
              closeDialogsAndRefreshDashboards();
            }

            doShare({
              resourceTypeId: DASHBOARD_RESOURCE_TYPE_ID,
              resourceId: DASHBOARD_RESOURCE_ID,
              isPublic: dialogIndicator.layout?.isPublic,
              layoutId: dialogIndicator.layout?.layoutId ?? 0,
              ownerId: dialogIndicator.layout?.ownerId ?? 0,
              widgetLayout: dialogIndicator.layout?.widgetLayout ?? '',
            });
          }}
          onCancel={resetDialogIndicator}
          dashboardId={dialogIndicator.layout?.layoutId ?? 0}
          dashboardTitle={dialogIndicator.layout?.layoutName ?? ''}
          onError={onError}
        />
        <SaveDialog
          open={dialogIndicator.dialog === 'CLONE'}
          title="Clone Dashboard"
          content="Are you sure you wish to clone this dashboard"
          onCancel={resetDialogIndicator}
          onSave={async () => {
            doClone({
              resourceTypeId: DASHBOARD_RESOURCE_TYPE_ID,
              resourceId: DASHBOARD_RESOURCE_ID,
              layoutName: dialogIndicator.layout?.layoutName ?? '',
              widgetLayout: dialogIndicator.layout?.widgetLayout ?? '',
              layoutId: dialogIndicator.layout?.layoutId ?? 0,
            });
          }}
        />
        <Paper elevation={2}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              {userData?.DMAS_USER_PRIVILEGE === 'RW' ? (
                <ContainedButton
                  translationKey="dashboards.addDashboard"
                  startIcon={<Add />}
                  onClick={async () => {
                    doAdd({
                      resourceTypeId: DASHBOARD_RESOURCE_TYPE_ID,
                      resourceId: DASHBOARD_RESOURCE_ID,
                      layoutName: `New Dashboard ${moment().format('MM/DD/YYYY HH:mm:ss')}`,
                      widgetLayout: '{}',
                    });
                  }}
                  className={classes.button}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.searchButton}>
                <ExpandableSearchBar
                  searchValue={search}
                  onValueChange={setSearch}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <StatelessTable
                rows={layoutData?.layouts ?? []}
                showNumberOfRecords={false}
                columns={COLUMNS}
                paging={{
                  currentPage,
                  pageSize,
                  onCurrentPageChange,
                  onPageSizeChange: (newPageSize) => {
                    onCurrentPageChange(
                      Math.trunc((currentPage * pageSize) / newPageSize)
                    );
                    setPageSize(newPageSize);
                  },
                  pageSizes: PAGE_SIZES,
                  totalCount:
                    layoutData && layoutData.totalLayouts
                      ? layoutData.totalLayouts
                      : 0,
                }}
                columnExtensions={COLUMN_EXTENSIONS}
                sort={{
                  sorting,
                  columnExtensions: SORTING_COLUMN_EXTENSIONS,
                  customSorting: COLUMN_SORTING,
                  handleSortingChange: setSorting,
                }}
                columnFormatProviders={[
                  {
                    name: 'addLink',
                    for: ['layoutName'],
                    formatterComponent: formatLink,
                  },
                  {
                    name: 'actions',
                    for: ['actions'],
                    formatterComponent: formatAction,
                  },
                  {
                    name: 'modifyDate',
                    for: ['modifyDate'],
                    formatterComponent: formatModifyBy,
                  },
                ]}
                getRowId={({ layoutId }) => layoutId}
              />
            </Grid>
          </Grid>
        </Paper>
      </DenseThemeProvider>
    </>
  );
};

export default DashboardTable;
