import { ArrowLeft } from '@onc/icons';

type SeekbarArrowLeftProps = {
  text: string;
};

// TODO: Change styles to sx so that they respect theming
const SeekbarArrowLeft = ({ text }: SeekbarArrowLeftProps) => (
  <div
    style={{
      float: 'left',
      position: 'absolute',
      left: '3px',
      top: '0.2em',
    }}
  >
    <div style={{ textAlign: 'left' }}>
      <ArrowLeft />
      <div>{text}</div>
    </div>
  </div>
);

export default SeekbarArrowLeft;
