import { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import { Warning as WarningIcon } from '@onc/icons';
import {
  Autocomplete,
  Collapse,
  Grid,
  TextField,
  TypographyWithTranslation,
} from 'base-components';
import { FormDialog } from '../dialogs/FormDialog';
import { TitleToggle } from '../Form/Fields/Toggles';
import UserPreferenceService from '../UserPreferenceService';

const STYLES = (theme) => ({
  warningIcon: {
    margin: theme.spacing(1),
    color: theme.palette.warning.main,
  },
  publishedWarningDiv: {
    display: 'flex',
  },
  warningText: {
    display: 'flex',
    alignItems: 'center',
  },
});

class InteractivePlotWidgetConfig extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    showTitle: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    savedPlot: PropTypes.shape({}).isRequired,
    classes: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      savedPlot: props.savedPlot,
      title: props.title,
      showTitle: props.showTitle,
    };
  }

  componentDidMount() {
    this.getSavedPlots();
  }

  getSavedPlots = async () => {
    const optionList = await UserPreferenceService.getUserPreferences(1);
    this.setState({ options: optionList });
  };

  onTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  onSavedPlotChange = (event, value) => {
    this.setState({ savedPlot: value });
  };

  onToggleShowTitle = () => {
    const { showTitle } = this.state;
    this.setState({ showTitle: !showTitle });
  };

  render() {
    const { onCancel, onSave, classes } = this.props;
    const { savedPlot, title, showTitle, options } = this.state;

    return (
      <FormDialog
        title="Configure Interactive Plot Widget"
        confirmButtonTranslationKey="common.buttons.save"
        open
        onSubmit={() => onSave(this.state)}
        onClose={onCancel}
        maxWidth="sm"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TitleToggle
              title="showTitle"
              name="showTitle"
              value={showTitle}
              onChange={this.onToggleShowTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={showTitle}>
              <TextField
                translationKey="common.textfields.title"
                name="title"
                title="title"
                value={title}
                onChange={this.onTitleChange}
              />
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              translationKey="dashboards.savedPlots"
              fullWidth
              onChange={this.onSavedPlotChange}
              options={options}
              virtualized
              value={savedPlot}
              name="savedPlotsAutocomplete"
            />
          </Grid>
          {savedPlot && !savedPlot.published ? (
            <Grid item xs={12}>
              <div className={classes.publishedWarningDiv} role="status">
                <WarningIcon className={classes.warningIcon} />
                <TypographyWithTranslation
                  variant="subtitle1"
                  className={classes.warningText}
                  translationKey="dashboards.selectedPlotNotPublished"
                />
              </div>
            </Grid>
          ) : undefined}
        </Grid>
      </FormDialog>
    );
  }
}

export default withStyles(STYLES)(InteractivePlotWidgetConfig);
