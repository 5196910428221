import { Component } from 'react';
import { MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Grid } from 'base-components';
import DeviceWebServiceWithToken from 'domain/services/DeviceWebServiceWithToken';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import DataPlayer from './DataPlayer';
import DataPlayerForm from './DataPlayerForm';
import DataPlayerDeviceService from './util/DataPlayerDeviceService';

const styles = (theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(),
  },
  container: {
    /**
     * TODO
     *
     * This needs have breakpoints for mobile. As this accounts for the app bar
     * height
     */
    height: '100%',
    width: '100%',
    padding: theme.spacing(),
  },
});

class DataPlayerContainer extends Component {
  static propTypes = {
    onError: PropTypes.func,
    onInfo: PropTypes.func,
    classes: PropTypes.shape({
      root: PropTypes.string,
      container: PropTypes.string,
    }),
    isForWidget: PropTypes.bool,
    isCommunicating: PropTypes.bool,
    startDate: PropTypes.instanceOf(moment),
    dateSelectorValue: PropTypes.string,
    deviceId: PropTypes.number,
    deviceCategoryCode: PropTypes.string,
    timeRange: PropTypes.string,
    showWidgetToolbox: PropTypes.bool,
    handleWidgetToolboxClose: PropTypes.func,
  };

  static defaultProps = {
    onError: undefined,
    onInfo: undefined,
    classes: undefined,
    isForWidget: false,
    isCommunicating: false,
    startDate: moment(),
    dateSelectorValue: 'dateRange',
    deviceId: undefined,
    deviceCategoryCode: 'HYDROPHONE',
    timeRange: '5',
    showWidgetToolbox: false,
    handleWidgetToolboxClose: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      isConfig: !props.isForWidget,
      title: undefined,
      toolBox: false,
      widgetToolbox: props.showWidgetToolbox,
      deviceCategorySiteDevicePromise: undefined,
      resourceId: undefined,
      config: {
        startDate: props.startDate,
        deviceId: props.deviceId,
        deviceCategoryCode: props.deviceCategoryCode,
        timeRange: props.timeRange,
        dateSelectorValue: props.dateSelectorValue,
      },
    };
  }

  componentDidMount() {
    const { config } = this.state;
    const { deviceId } = this.props;
    // make service call that takes a long time early and only once
    // since this does not have a key as a prop it should only be called once
    this.fetchDeviceCategorySiteDevices();
    if (config.deviceId) {
      this.getTitle(config.deviceId);
    } else if (deviceId) {
      this.getTitle(deviceId);
    }
  }

  async shouldComponentUpdate(nextProps, nextState) {
    const { config } = this.state;
    const { deviceId } = this.props;
    const nextConfigDeviceId = nextState.config.deviceId;
    if (
      nextConfigDeviceId &&
      (!config.deviceId || config.deviceId !== nextConfigDeviceId)
    ) {
      this.getTitle(nextConfigDeviceId);
    }
    if (nextProps.deviceId !== deviceId) {
      this.getTitle(nextProps.deviceId);
    }
    // this needs to be resolved before passing props to the DataPlayer
    await this.getResourceIdIfNeeded(nextState);
    return true;
  }

  getTitle = async (deviceId) => {
    const { onError } = this.props;
    const response = await DeviceWebServiceWithToken.get({
      deviceId,
      method: 'get',
    }).catch((error) => {
      onError(error);
      return undefined;
    });
    const deviceName =
      response?.data?.length === 1 ? response.data[0].deviceName : undefined;
    const titleString = deviceName ? `${deviceName} (${deviceId})` : undefined;
    this.setState({ title: titleString });
  };

  fetchDeviceCategorySiteDevices = () => {
    const { onError } = this.props;
    const deviceCategorySiteDevicePromise =
      DataPlayerDeviceService.performDeviceCategorysAndSiteDevicesServiceCall(
        onError
      );
    this.setState({
      deviceCategorySiteDevicePromise,
    });
  };

  getResourceIdIfNeeded = async (nextState) => {
    // waits for deviceCategorySiteDevicePromise to resolve before loading images
    await this.state;
    const { config, resourceId, deviceCategorySiteDevicePromise } = this.state;
    const { onError, dateSelectorValue, deviceId } = this.props;

    // resourceId not yet found
    if (!resourceId) {
      // get deviceId and startDate from the prop or nextState if possible. If not then use values from config.
      const deviceIdToGetResourceIdFor =
        deviceId || nextState.config?.deviceId || config.deviceId;
      const dateToGetResourceIdFor = nextState.config
        ? nextState.config.startDate
        : config.startDate;
      // check that values are present
      if (
        deviceIdToGetResourceIdFor === undefined ||
        dateToGetResourceIdFor === undefined ||
        deviceId === undefined
      ) {
        return undefined;
      }
      const newResourceId =
        await DataPlayerDeviceService.getResourceIdByDeviceAndDate(
          dateSelectorValue,
          deviceIdToGetResourceIdFor,
          dateToGetResourceIdFor,
          deviceCategorySiteDevicePromise,
          onError
        );
      if (newResourceId !== undefined) {
        // do not set state of resourceId if not found to prevent infinte render loop.
        this.setState({
          resourceId: newResourceId,
        });
      }
      return {};
    }

    // resourceId exists, see if it needs updating
    if (
      nextState.config &&
      (nextState.config.deviceId !== config.deviceId ||
        nextState.config.startDate !== config.startDate)
    ) {
      const updatedResourceId =
        await DataPlayerDeviceService.getResourceIdByDeviceAndDate(
          nextState.config.deviceId,
          nextState.config.startDate,
          deviceCategorySiteDevicePromise,
          onError
        );
      this.setState({
        resourceId: updatedResourceId,
      });
      return {};
    }
    // else, return nothing so that eslint 'consistent-return' is not violated
    return {};
  };

  handleOpenConfig = () => {
    this.setState({ isConfig: true });
  };

  handleCloseConfig = () => {
    this.setState({ isConfig: false });
  };

  handleSubmit = (data) => {
    const { onError } = this.props;
    if (data.deviceId === '' || data.deviceId === undefined) {
      onError('No device selected');
    }
    // set the state with the value of data
    this.setState({
      config: data,
      isConfig: false,
      resourceId: undefined,
    });
  };

  handleOpenTool = () => {
    this.setState({ toolBox: true });
  };

  handleCloseTool = () => {
    this.setState({
      toolBox: false,
      showWidgetToolbox: false,
    });
  };

  /* Widgets have their own menu & config, do not render them if the
  component is being used as a widget. */
  renderForm = () => {
    const { onError, onInfo, isForWidget } = this.props;
    const { isConfig, config } = this.state;
    if (!isForWidget) {
      return (
        <Dialog open={isConfig} fullWidth>
          <DialogContent>
            <DataPlayerForm
              config={config}
              onSave={this.handleSubmit}
              onCancel={this.handleCloseConfig}
              onError={onError}
              onInfo={onInfo}
              isForWidget={false}
            />
          </DialogContent>
        </Dialog>
      );
    }
    return null;
  };

  renderMenu = () => {
    const { isForWidget } = this.props;
    if (!isForWidget) {
      return [
        <MenuItem
          key="configure"
          id="configwidget"
          onClick={this.handleOpenConfig}
        >
          Configure
        </MenuItem>,
        <MenuItem
          key="toolbox"
          id="toolboxwidget"
          onClick={this.handleOpenTool}
        >
          Toolbox
        </MenuItem>,
      ];
    }
    return undefined;
  };

  renderPlayer = () => {
    const { toolBox, config, resourceId } = this.state;
    const {
      isForWidget,
      showWidgetToolbox,
      handleWidgetToolboxClose,
      isCommunicating,
      deviceId,
    } = this.props;
    return (
      <DataPlayer
        dateSelectorValue={config.dateSelectorValue}
        deviceId={deviceId}
        deviceCategoryCode={config.deviceCategoryCode}
        startDate={config.startDate}
        timeRange={config.timeRange}
        threshold={256}
        toolBox={isForWidget ? showWidgetToolbox : toolBox}
        handleWidgetToolboxClose={handleWidgetToolboxClose}
        onCloseTool={this.handleCloseTool}
        isCommunicating={isCommunicating}
        isDashboard={isForWidget}
        resourceId={resourceId}
      />
    );
  };

  render() {
    const { classes, isForWidget } = this.props;
    const { title } = this.state;
    return (
      <>
        {this.renderForm()}
        <Grid container className={classes.root}>
          <Grid item xs={12} lg={12} className={classes.container}>
            <Panel
              menu={this.renderMenu()}
              title={title}
              enableOverflow={!isForWidget}
            >
              {this.renderPlayer()}
            </Panel>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbars(DataPlayerContainer));
