/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';

import * as React from 'react';
import { Box } from '@mui/material';

import moment, { Moment } from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { ErrorAlert } from '@onc/composite-components';
import { Update } from '@onc/icons';
import {
  DropdownOption,
  Divider,
  Grid,
  LoadingButton,
  Collapse,
  QuickEntryButton,
} from 'base-components';
import TaxonAsyncAutocomplete from 'domain/AppComponents/dropdowns/TaxonAsyncAutocomplete';
import { AttributeLine } from 'domain/AppComponents/manual-entry/ManualEntryAttributes';
import ManualEntryLogic from 'domain/AppComponents/manual-entry/ManualEntryLogic';
import { Attribute } from 'domain/AppComponents/sea-tube/annotation-table/TableAnnotation';
import { ListAnnotation } from 'domain/AppComponents/sea-tube/dive-log/VirtualAnnotationList';
import {
  ServiceAnnotationV3,
  useCreateAnnotation,
  useUpdateAnnotation,
} from 'domain/services/AnnotationService';
import { TaxonMatrixAttributes } from 'domain/services/TaxonAttributeService';
import { useAttributes } from 'domain/services/TaxonomyAttributeService';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import Form from 'library/CompositeComponents/form/Form';
import FormCheckbox from 'library/CompositeComponents/form/FormCheckbox';
import FormDateTimePicker from 'library/CompositeComponents/form/FormDateTimePicker';
import FormDropdown from 'library/CompositeComponents/form/FormDropdown';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import useGet from 'util/hooks/useDmasAPI/useGet';

import { useSnackbars } from 'util/hooks/useSnackbars';
import ManualEntryFormAttributes from './ManualEntryFormAttributes';

const styles = {
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%', // Full height container
    overflow: 'hidden', // Prevent children from overflowing
  },
  overflow: {
    flexGrow: 1,
    overflowY: 'auto', // Enable scrolling if content exceeds height
    padding: 1,
    boxSizing: 'border-box', // Ensure padding doesn't increase size
    maxHeight: '100%', // Ensure the box doesn't grow beyond parent
  },
  buttonBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto', // Push to the bottom
  },
  buttonBar: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  divider: {
    width: '100%',
  },
};

export type SeaTubeManualEntryFormProps = {
  quickButton?: QuickEntryButton;
  editAnnotation: ListAnnotation;
  taxonomyOptions: DropdownOption[];
  resourceTypeOptions: DropdownOption[];
  resourceOptions: DropdownOption[];
  defaultResourceType?: any;
  defaultResource?: any;
  defaultPublish?: boolean;
  currentTimestamp: string;
  // showSidebar: boolean;
  onSubmitSuccess: (annotation: ServiceAnnotationV3) => void;
  onClearEditedAnnotation: () => void;
  options?: {
    hideResource?: boolean;
    nullableAttributes?: boolean;
    canPublish?: boolean;
  };
  minDate?: Moment;
  maxDate?: Moment;
};

export type SeaTubeManualEntryFormType = {
  annotationId?: number;
  resourceTypeId?: number;
  resourceId?: number;
  date?: Moment | null;
  taxonomy?: number;
  taxon?: {
    taxonId: number;
    value: number;
    label: string;
    commonName?: string;
  } | null;
  taxonName?: any;
  comment?: string;
  toBeReviewed?: boolean;
  attributes: AttributeLine[];
  generalError?: string;
  published: boolean;
};

export type ManualEntryErrors = {
  dateError?: string;
  attributeErrors?: any;
  taxonError?: string;
  resourceTypeError?: string;
  resourceError?: string;
};

const ManualEntryForm = ({
  quickButton = null,
  defaultResourceType = null,
  defaultResource = null,
  defaultPublish = false,
  editAnnotation,
  currentTimestamp,
  // showSidebar,
  resourceTypeOptions,
  resourceOptions,
  taxonomyOptions,
  options = {
    hideResource: false,
    nullableAttributes: false,
    canPublish: false,
  },
  minDate = moment.utc('2000-01-01'),
  maxDate = null,
  onSubmitSuccess,
  onClearEditedAnnotation,
}: SeaTubeManualEntryFormProps) => {
  const { hideResource, nullableAttributes, canPublish } = options;
  const { onInfo, onError } = useSnackbars();
  const [isQuickButton, setIsQuickButton] = useState(false);

  const emptyForm = useMemo(
    () => ({
      resourceId: defaultResource,
      resourceTypeId: defaultResourceType,
      date: null,
      time: null,
      taxonomy: null,
      taxon: null,
      comment: '',
      toBeReviewed: false,
      attributes: [],
      touchedAttributes: [],
      published: defaultPublish,
    }),
    []
  );

  const formMethods = useForm<SeaTubeManualEntryFormType>({
    shouldFocusError: false,
    defaultValues: {
      ...emptyForm,
    },
  });

  const { reset, control, setValue, watch, formState, setError, clearErrors } =
    formMethods;
  const [watchedTaxonomy, watchedTaxon, watchedComment, watchedAttributes] =
    watch(['taxonomy', 'taxon', 'comment', 'attributes']);

  const userDefined = !(watchedTaxonomy === 1 || watchedTaxonomy === 3);

  // TaxonomyAttributeService (getting attributes)
  const { data: attributeOptions } = useAttributes();

  // TaxonAttributeService (getting attrs for taxon)
  const { data: taxonAttributes } = useGet(
    `internal/taxonomies/${watchedTaxonomy}/taxons/${watchedTaxon?.taxonId}/attributes`,
    {
      operation: undefined,
      transform: (response) => new TaxonMatrixAttributes(response.data),
      options: {
        enabled: !!watchedTaxon && !editAnnotation && !isQuickButton,
      },
    }
  );

  const handleClear = () => {
    onClearEditedAnnotation();
    reset(emptyForm);
  };

  const handleSuccess = (data: ServiceAnnotationV3) => {
    onSubmitSuccess(data);
    handleClear();
    onInfo('Annotation saved');
  };

  const { mutate: createAnnotation, isLoading: createLoading } =
    useCreateAnnotation(handleSuccess, () =>
      onError('Error saving annotation')
    );

  const { mutate: updateAnnotation, isLoading: updateLoading } =
    useUpdateAnnotation(handleSuccess, () =>
      onError('Error saving annotation')
    );

  const isLoading = createLoading || updateLoading;

  const handleSubmit = async (data: SeaTubeManualEntryFormType) => {
    if (!data.taxon && !data.comment) {
      setError('generalError', {
        type: 'custom',
        message: 'A taxon or comment is required',
      });
      return;
    }

    const serviceAnnotation = ManualEntryLogic.convertFormToServiceParams(data);
    if (editAnnotation?.annotationId) {
      serviceAnnotation.annotationId = editAnnotation?.annotationId;
      updateAnnotation({
        organizationId: 134, // Hardcoded to be ONC annotations for now
        annotation: JSON.stringify(serviceAnnotation),
      });
    } else {
      createAnnotation({
        organizationId: 134, // Hardcoded to be ONC annotations for now
        annotation: JSON.stringify(serviceAnnotation),
      });
    }
  };

  useEffect(() => {
    if (!editAnnotation) {
      handleClear();
    } else {
      const formValues =
        ManualEntryLogic.convertListAnnotationToForm(editAnnotation);
      reset(formValues);
    }
  }, [editAnnotation]);

  useEffect(() => {
    if (formState.errors?.generalError) {
      clearErrors('generalError');
    }
  }, [watchedTaxon, watchedComment, watchedTaxonomy]);

  useEffect(() => {
    // Get the matrix attribute data for the selected taxon
    const matrixAttributes =
      taxonAttributes
        ?.getCurrentAttributes()
        .map((matrixAttr) => matrixAttr.toAttribute()) || [];

    // Convert the matrix attributes to the form format
    const updatedAttributes = ManualEntryLogic.convertAttributesToForm(
      matrixAttributes,
      attributeOptions
    );

    // Replace the existing attributes with the updated ones using the logic
    const newAttrs = ManualEntryLogic.replaceAttributes(
      watchedAttributes,
      updatedAttributes
    );

    setValue('attributes', newAttrs);
  }, [taxonAttributes]);

  useEffect(() => {
    if (quickButton) {
      setIsQuickButton(true);
      if (quickButton.taxonomyId) {
        setValue('taxonomy', quickButton.taxonomyId);
      }
      if (quickButton.taxonId) {
        setValue('taxon', {
          taxonId: quickButton.taxonId,
          value: quickButton.taxonId,
          label: quickButton.taxonName || 'Test',
        });
        // Mark that the taxon came from the quick button
      }

      let attributeLines: AttributeLine[] = [];

      // Convert QuickButtonAttributes to Attributes
      const attributes: Attribute[] =
        quickButton.attributes?.map((attr) => ({
          ...attr,
          attributeId: attr.taxonomyAttributeId,
          value: attr.attributeValue,
          name: '',
          groupId: -1,
          groupName: 'unknown',
          dataType: 'String',
        })) || [];

      // Convert Attributes to Attribute Lines treat them as touched
      // since this is similar to a user entering them
      const newLines = ManualEntryLogic.convertAttributesToForm(
        attributes,
        attributeOptions
      ).map((attr) => ({ ...attr, touched: !quickButton.taxonId }));

      // If using a taxon button, use replacement logic
      if (quickButton.taxonId) {
        attributeLines = ManualEntryLogic.replaceAttributes(
          watchedAttributes,
          newLines
        );
        // Otherwise, use additive logic
      } else {
        attributeLines = ManualEntryLogic.addAttributes(
          watchedAttributes,
          newLines
        );
      }

      const comment =
        quickButton?.comment ||
        ManualEntryLogic.getCommentFromAttributes(attributes);

      setValue('attributes', attributeLines);
      setValue('comment', comment);
    }
  }, [quickButton, setValue, attributeOptions]);

  useEffect(() => {
    if (resourceOptions.length === 1) {
      setValue('resourceId', resourceOptions[0].value as number);
    }
    if (resourceTypeOptions.length === 1) {
      setValue('resourceTypeId', resourceTypeOptions[0].value as number);
    }
    if (resourceTypeOptions.length === 0) {
      setValue('resourceTypeId', null);
    }
    if (resourceOptions.length === 0) {
      setValue('resourceId', null);
    }
  }, [resourceOptions, resourceTypeOptions]);

  return (
    <Form
      formMethods={formMethods}
      onSubmit={handleSubmit}
      noValidate
      style={{ height: '100%' }}
    >
      <Box sx={styles.flexContainer}>
        {/* Content Area */}
        <Divider />
        <Box sx={styles.overflow}>
          <Grid
            container
            rowSpacing={0}
            columnSpacing={1}
            sx={{ alignItems: 'center' }}
          >
            {/* Form Fields */}
            <Grid item xs={12}>
              <Grid item xs={12} sx={{ marginTop: 1 }}>
                <Collapse
                  in={hideResource && Boolean(formState.errors?.resourceId)}
                >
                  <ErrorAlert>
                    Unable to determine an appropriate resource for this
                    annotation
                  </ErrorAlert>
                </Collapse>
              </Grid>
              <Collapse in={!hideResource}>
                <Grid container rowSpacing={0} columnSpacing={1}>
                  <Grid item xs={4}>
                    <FormDropdown
                      name="resourceTypeId"
                      label="Resource Type"
                      fullWidth
                      options={resourceTypeOptions}
                      rules={{ required: 'Resource Type is required' }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormDropdown
                      name="resourceId"
                      label="Resource"
                      fullWidth
                      options={resourceOptions}
                      rules={{ required: 'Resource is required' }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>

            <Grid item xs={6}>
              <FormDateTimePicker
                name="date"
                fullWidth
                translationKey="common.datepickers.date"
                rules={{
                  required: 'Date is required',
                  validate: {
                    afterMax: (value: Moment) =>
                      value.isBefore(maxDate || moment.utc()) ||
                      `Date must be before ${maxDate.format('YYYY-MM-DD HH:mm:ss')}`,
                    beforeMin: (value: Moment) =>
                      value.isAfter(minDate) ||
                      `Date must be after ${minDate.format('YYYY-MM-DD HH:mm:ss')}`,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextButton
                translationKey="seatube.captureTime"
                translationOptions={{ historical: '' }}
                onClick={() =>
                  setValue(
                    'date',
                    currentTimestamp
                      ? moment.utc(currentTimestamp)
                      : moment.utc()
                  )
                }
                startIcon={<Update />}
              />
            </Grid>
            <Grid item xs={5}>
              <FormDropdown
                name="taxonomy"
                label="Taxonomy"
                fullWidth
                options={taxonomyOptions}
              />
            </Grid>
            <Grid item xs={7}>
              <Controller
                name="taxon"
                control={control}
                render={({ field }) => (
                  <TaxonAsyncAutocomplete
                    searchLettersRequired={userDefined ? 1 : 3}
                    taxonomyId={watchedTaxonomy}
                    taxonId={watchedTaxon?.taxonId}
                    {...field}
                    onChange={(e, newValue) => {
                      setIsQuickButton(false);
                      field.onChange(newValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="comment"
                translationKey="common.textfields.comment"
                fullWidth
                rows={2}
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={Boolean(formState.errors?.generalError)}>
                <ErrorAlert> A taxon or comment is required </ErrorAlert>
              </Collapse>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <ManualEntryFormAttributes
                attributeOptions={attributeOptions}
                nullableAttributes={nullableAttributes}
                control={control}
                setValue={setValue}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Button Bar */}
        <Box sx={styles.buttonBarContainer}>
          <Divider sx={styles.divider} />
          <Box sx={styles.buttonBar}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              translationKey="common.buttons.save"
              type="submit"
            />
            <TextButton
              onClick={handleClear}
              translationKey={
                editAnnotation
                  ? 'common.buttons.cancel'
                  : 'common.buttons.clear'
              }
            />
            <Box sx={{ marginRight: 'auto' }} />

            <FormCheckbox
              name="published"
              label="Publish"
              sx={{ marginLeft: 1, display: canPublish ? 'inherit' : 'none' }}
            />

            <FormCheckbox
              name="toBeReviewed"
              label="To Be Reviewed"
              sx={{ marginLeft: 1 }}
            />
          </Box>
        </Box>
      </Box>
    </Form>
  );
};

export default ManualEntryForm;
