import { patch, post, get } from 'util/WebRequest';
import { DmasUser } from './UserDetailsService';

export interface CableType {
  cableTypeId?: number;
  name: string;
  description: string;
  modifyBy?: DmasUser;
  modifyDate?: string;
}

class CableTypeService {
  static get = async (cableTypeId: number): Promise<CableType> => {
    const response = await get(`/internal/cables/types/${cableTypeId}`);
    return response.data;
  };

  static getAll = async (): Promise<CableType[]> => {
    const response = await get('/internal/cables/types');
    return response.data;
  };

  static createCableType = async (params: CableType): Promise<CableType> => {
    const response = await post('/internal/cables/types', params, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  };

  static updateCableType = async (params: CableType): Promise<CableType> => {
    const response = await patch(
      `/internal/cables/types/${params.cableTypeId}`,
      params,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return response.data;
  };
}

export default CableTypeService;
