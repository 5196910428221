import PropTypes from 'prop-types';

import { DmasLink } from 'base-components';

const MAX_LINK_LENGTH = 20;

const ExportDownloadLink = (props) => {
  const { searchId, fileName } = props;

  const page = `/SearchResultService?id=${searchId}`;
  let fileString = fileName;

  if (fileName && fileName.length > MAX_LINK_LENGTH) {
    const endIndex = fileName.length - 1;
    fileString = `${fileName.substring(
      0,
      MAX_LINK_LENGTH - 5
    )}..${fileName.substring(endIndex - 3)}`;
  }

  if (fileString && fileString.length !== 0) {
    return (
      <div>
        Click to Download: <DmasLink page={page} contents={fileString} />
      </div>
    );
  }

  return 'Export Failed';
};

ExportDownloadLink.propTypes = {
  fileName: PropTypes.string.isRequired,
  searchId: PropTypes.number.isRequired,
};

export default ExportDownloadLink;
