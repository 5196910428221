import { post, get } from 'util/WebRequest';

class ResourceRoleService {
  static get = (deviceId) =>
    get('DeviceResourceRoleService', { deviceId }).then(
      (payload) => payload.data.payload
    );

  static getMany = () =>
    get('DeviceResourceRoleService', { operation: 5 }).then(
      (payload) => payload.data.payload
    );

  static create = (payload) =>
    post('DeviceResourceRoleService', {
      operation: 1,
      dateFrom: payload.dateFrom,
      deviceId: payload.deviceId,
      deviceResourceRoleId: payload.deviceResourceRoleId,
      resourceId: payload.resourceId,
      resourceRoleId: payload.resourceRoleId,
      resourceTypeId: payload.resourceTypeId,
      roleComment: payload.roleComment,
    });

  static delete = (deviceResourceRoleId) =>
    post('DeviceResourceRoleService', {
      operation: 3,
      deviceResourceRoleId,
    });
}
export default ResourceRoleService;
