import {
  createRef,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import qs, { ParsedQs } from 'qs';
import DataProductSelection from 'domain/AppComponents/geospatial-search/community-fisher/cf-dialogs/DataProductSelection';
import DataProductCartFAB from 'domain/AppComponents/geospatial-search/community-fisher/fab-buttons/DataProductCartFAB';
import FilterFAB from 'domain/AppComponents/geospatial-search/community-fisher/fab-buttons/FilterFAB';
import HelpFAB from 'domain/AppComponents/geospatial-search/community-fisher/fab-buttons/HelpFAB';
import LassoFAB from 'domain/AppComponents/geospatial-search/community-fisher/fab-buttons/LassoFAB';
import { DEFAULT_PRODUCT_MAPPING } from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchConstants';
import { ProductNames } from 'domain/AppComponents/geospatial-search/definitions/GeospatialSearchTypes';
import GeospatialAreaService from 'domain/services/GeospatialAreaService';
import SiteDeviceSubsetService from 'domain/services/SiteDeviceSubsetService';
import MapContext from 'library/CompositeComponents/map/MapContext';
import SimpleMap from 'library/CompositeComponents/map/SimpleMap';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import CookieUtils from 'util/CookieUtils';
import { CastDataProvider } from './CastDataContext';
import AssignedCFOverlayv2 from './community-fisher/AssignedCFOverlayv2';
import DecommissionedAssignedCFOverlayv2 from './community-fisher/DecommissionedAssignedCFOverlayv2';
import GeospatialSearchHelper from './community-fisher/GeospatialSearchHelper';
import UnassignedCFOverlayv2 from './community-fisher/UnassignedCFOverlayv2';
import SelectedStationContext, {
  SelectedStationsProvider,
} from './SelectedStationsContext';
import SiteDeviceSubsetRowsContext, {
  SiteDeviceSubsetRowsProvider,
} from './SiteDeviceSubsetRowContext';

const ACTIVE_STATIONS_CF_OVERLAY_NAME = 'Active Casting Stations';
const DECOMMISSIONED_STATIONS_CF_OVERLAY_NAME =
  'Decommissioned Casting Stations';
const UNASSIGNED_CF_OVERLAY_NAME = 'Unassigned Casts';
const LOCATION_QUERY = 'LOCATION';
const ZOOM_QUERY = 'ZOOM';
const GEOSPATIAL_MAP_ID = 'geospatial-search-map';

interface GeospatialSearchProps {
  onError: (message: any, callback?: any) => void;
  onInfo: ({ message }: { message: string }) => void;
  height?: string;
  width?: string;
}

interface Config {
  location: {
    lat: number;
    lng: number;
  };
  zoom: number;
}

const GeospatialSearch: FC<GeospatialSearchProps> = ({
  onError,
  onInfo,
  height = '800px',
  width = '100%',
}) => {
  const mapContext = useContext(MapContext);

  const {
    selectedStations,
    updateSelectedStations,
    getCheckboxStateArrayMapFromSelectedStations,
  } = useContext(SelectedStationContext);

  const { siteDeviceSubsetRows, updateSiteDeviceSubsetRows } = useContext(
    SiteDeviceSubsetRowsContext
  );

  const [map, setMap] = useState<any>();

  const [dateRange, setDateRange] = useState({
    dateFrom: undefined,
    dateTo: undefined,
  });
  const [displayCastLayer, setDisplayCastLayer] = useState(true);
  const [displayUnassignedCastLayer, setDisplayUnassignedCastLayer] =
    useState(true);
  const [unassignedCastData, setUnassignedCastData] = useState(undefined);
  const [assignedCastData, setAssignedCastData] = useState(undefined);

  const [
    displayDataProductSelectionDialog,
    setDisplayDataProductSelectionDialog,
  ] = useState(false);

  const [displayDataProductCartDialog, setDisplayDataProductCartDialog] =
    useState(false);

  const [selectedLayers, setSelectedLayers] = useState<any>([]);

  const [hideCFPolygons, setHideCFPolygons] = useState<boolean>(false);

  // necessary at this level to allow AssignedCFOverlay icons to be properly adjusted at depth
  const recolourIcons = useCallback(() => {
    // eslint-disable-next-line no-underscore-dangle
    GeospatialSearchHelper.setAllLayersToUnselectedIcon(map?.current?._layers);
    GeospatialSearchHelper.setLayersToSelectedIcon(selectedLayers);
  }, [selectedLayers, map]);

  const decideToRenderAreaPolygons = useCallback(() => {
    if (!map) {
      return;
    }
    const zoom = map.current.getZoom();
    if (zoom > 11 && hideCFPolygons) {
      setHideCFPolygons(false);
    } else if (zoom < 12 && !hideCFPolygons) {
      setHideCFPolygons(true);
    }
    recolourIcons();
  }, [hideCFPolygons, map, recolourIcons]);

  // check every render if zoom to hide marker polygons when zoomed out
  useEffect(() => {
    if (map?.current) {
      map.current.on('zoomend', decideToRenderAreaPolygons);
      map.current.on('overlayadd', recolourIcons);
    }
    decideToRenderAreaPolygons();
  });

  useEffect(() => {
    setMap(mapContext?.mapRef);
  }, [mapContext]);

  // necessary at this level to allow both the CastSelectionDialog and the CastPopup of an assigned station to select all the casts of a station.
  const setSelectedStationParentCheckbox = (
    selectedStationCode,
    checkboxSelected
  ) => {
    const selectedStationsCopy = selectedStations.map((station) => {
      const stationCopy = station;
      if (station.code === selectedStationCode) {
        stationCopy.checkboxSelected = checkboxSelected;
        stationCopy.partialCheckboxSelected = checkboxSelected;
        stationCopy.siteDeviceSubsets = stationCopy.siteDeviceSubsets.map(
          (siteDeviceSubset) => {
            const siteDeviceSubsetCopy = siteDeviceSubset;
            siteDeviceSubsetCopy.checkboxSelected = checkboxSelected;
            return siteDeviceSubsetCopy;
          }
        );
      }
      return stationCopy;
    });
    updateSelectedStations(selectedStationsCopy);
  };

  // only render one (castSelectionDialog or downloadTableDialog) at a time
  const showDataProductSelectionDialog = () => {
    setDisplayDataProductCartDialog(false);
    setDisplayDataProductSelectionDialog(true);
  };
  const showDataProductCartDialog = () => {
    setDisplayDataProductCartDialog(true);
    setDisplayDataProductSelectionDialog(false);
  };

  const createProduct = (
    productId: number,
    productName: ProductNames,
    productType: string,
    extension: string
  ) => ({
    productId,
    productName,
    isSelected: true,
    status: '',
    productType,
    extension,
  });

  const updateSiteDeviceSubsetRowsContextFromCheckboxState = (
    checkboxState
  ) => {
    // Filter selected subset IDs based on any of the checkboxes being checked
    const selectedIds = checkboxState
      .filter(
        (state) =>
          state.csv ||
          state.cor ||
          state.plot ||
          state.annotation ||
          state.metadata
      )
      .map((state) => state.siteDeviceSubsetId);

    // Filter subsets based on selected IDs and include parent station code
    const selectedSubsets = selectedStations
      .flatMap((station) =>
        station.siteDeviceSubsets.map((subset) => ({
          ...subset,
          code: station.code, // Including station code here
        }))
      )
      .filter((subset) => selectedIds.includes(subset.siteDeviceSubsetId));

    // Find the maximum productId in the array
    const maxProductId = siteDeviceSubsetRows.reduce((maxId, item) => {
      const maxInCurrent = item.productsForCast.reduce(
        (max, product) =>
          product.productId ? Math.max(max, product.productId) : max,
        0
      );
      return Math.max(maxId, maxInCurrent);
    }, 0);

    let productIdCounter = maxProductId + 1;

    const existingRows = [...siteDeviceSubsetRows];

    selectedSubsets.forEach((subset) => {
      // Find the corresponding checkbox state for the subset
      const checkboxStateForSubset = checkboxState.find(
        (state) => state.siteDeviceSubsetId === subset.siteDeviceSubsetId
      );

      // Add products based on the checkbox state
      if (checkboxStateForSubset) {
        const productMapping = JSON.parse(
          JSON.stringify(DEFAULT_PRODUCT_MAPPING)
        );

        productMapping.forEach((product) => {
          const existingRow = existingRows.find(
            (row) => row.siteDeviceSubsetId === subset.siteDeviceSubsetId
          );

          if (checkboxStateForSubset[product.key]) {
            const newProduct = createProduct(
              productIdCounter,
              product.productName,
              product.productType,
              product.extension
            );

            // If row exists and a product for it does not, add the product to the row
            if (existingRow) {
              if (
                !existingRow.productsForCast.some(
                  (p) => p.productType === product.productType
                )
              ) {
                existingRow.productsForCast.push(newProduct);
                productIdCounter += 1;
              }
            } else {
              // If subset does not exist, create new row with the selected product
              const newRow = {
                siteDeviceSubsetName: subset.siteDeviceSubsetName,
                siteDeviceSubsetId: subset.siteDeviceSubsetId,
                startDate: subset.startDate,
                endDate: subset.endDate,
                code: subset.code,
                productsForCast: [newProduct],
                status: '',
              };
              productIdCounter += 1;

              existingRows.push(newRow);
            }
          }
        });
      }
    });

    updateSiteDeviceSubsetRows(existingRows);
  };

  const downloadAllUnrestrictedCastsFromStation = (code) => {
    // if station is not yet part of selectedStations, find and add it
    if (
      !selectedStations.some(
        (someCurrentStation) => someCurrentStation.code === code
      )
    ) {
      assignedCastData.forEach((station) => {
        if (station.code === code) {
          const selectedStationsCopy = selectedStations;
          selectedStationsCopy.push(station);
          updateSelectedStations(selectedStationsCopy);
        }
      });
    }

    setSelectedStationParentCheckbox(code, true);

    if (selectedStations.length === 1) {
      updateSiteDeviceSubsetRowsContextFromCheckboxState(
        getCheckboxStateArrayMapFromSelectedStations(true)
      );
      showDataProductCartDialog();
    } else {
      showDataProductSelectionDialog();
    }
  };

  const assignedCastDataWithPolygons =
    GeospatialSearchHelper.generatePolygonPositions(assignedCastData, onError);

  const handleOverlayChange = (e) => {
    const newDisplayValue = e.type === 'overlayadd';
    if (e.name === ACTIVE_STATIONS_CF_OVERLAY_NAME) {
      setDisplayCastLayer(newDisplayValue);
    }
    if (e.name === UNASSIGNED_CF_OVERLAY_NAME) {
      setDisplayUnassignedCastLayer(newDisplayValue);
    }
  };

  const setLocation = useCallback(() => {
    let config: RegExpMatchArray | Config = {
      location: { lat: null, lng: null },
      zoom: null,
    };
    const parsedLocation: ParsedQs = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    // center map on url location or cookie location
    if (
      parsedLocation[LOCATION_QUERY] &&
      typeof parsedLocation[LOCATION_QUERY] === 'string'
    ) {
      const location = parsedLocation[LOCATION_QUERY].split(',');
      config.location.lat = parseFloat(location[0]);
      config.location.lng = parseFloat(location[1]);
      if (typeof parsedLocation[ZOOM_QUERY] === 'string') {
        config.zoom =
          parseInt(parsedLocation[ZOOM_QUERY], 10) < 20
            ? parseInt(parsedLocation[ZOOM_QUERY], 10)
            : 8;
      }
    } else {
      const cookie = CookieUtils.getCookie(`geospatialMap-config`);
      config = cookie as unknown as Config;
    }

    if (config && config.location.lat && config.location.lng) {
      map.current.setView(
        [config.location.lat, config.location.lng],
        config.zoom
      );
    }
  }, [map]);

  const storeLocation = useCallback(() => {
    // store a cookie
    const config = { location: undefined, zoom: undefined };
    config.location = map.current.getCenter();
    config.zoom = map.current.getZoom();
    CookieUtils.saveCookie(`geospatialMap-config`, config);

    // update url
    const location = `${config.location.lat},${config.location.lng}`;
    const windowURL = window.location.pathname.split('/').pop();
    const param = `/${windowURL}?${LOCATION_QUERY}=${location}&${ZOOM_QUERY}=${config.zoom}`;

    window.history.pushState({}, '', param);
  }, [map]);

  const getCastData = useCallback(async () => {
    let newUnassignedCastData;
    try {
      newUnassignedCastData = SiteDeviceSubsetService.getUnassignedCasts();
    } catch (error: any) {
      onError(error.message);
    }

    let newAssignedCastData;
    try {
      newAssignedCastData = GeospatialAreaService.getDrawableAreas();
    } catch (error: any) {
      onError(error.message);
    }

    const newUnassignedCastDataProcessed =
      GeospatialSearchHelper.filterOutUnassignedCastsWithNoPosition(
        await newUnassignedCastData
      );
    setUnassignedCastData(newUnassignedCastDataProcessed);
    setAssignedCastData(await newAssignedCastData);
  }, [onError]);

  const generateActiveAssignedLayer = () => {
    // if we build this layer before the mapref is set, the lasso button cannot be added
    if (!map?.current) {
      return undefined;
    }
    const communityFishersLayer = {
      name: ACTIVE_STATIONS_CF_OVERLAY_NAME,
      checked: true,
      subLayers: (
        <AssignedCFOverlayv2
          dateRange={dateRange}
          assignedCastData={assignedCastDataWithPolygons}
          recolourIcons={recolourIcons}
          hideCFPolygons={hideCFPolygons}
          downloadAllUnrestrictedCastsFromStation={
            downloadAllUnrestrictedCastsFromStation
          }
        />
      ),
    };
    return communityFishersLayer;
  };

  const generateDecommissionedAssignedLayer = () => {
    // if we build this layer before the mapref is set, the lasso button cannot be added
    if (!map?.current) {
      return undefined;
    }
    const communityFishersLayer = {
      name: DECOMMISSIONED_STATIONS_CF_OVERLAY_NAME,
      checked: true,
      subLayers: (
        <DecommissionedAssignedCFOverlayv2
          dateRange={dateRange}
          assignedCastData={assignedCastDataWithPolygons}
          recolourIcons={recolourIcons}
          hideCFPolygons={hideCFPolygons}
          downloadAllUnrestrictedCastsFromStation={
            downloadAllUnrestrictedCastsFromStation
          }
        />
      ),
    };
    return communityFishersLayer;
  };

  const generateUnassignedLayer = () => {
    const UnassignedCommunityFishersLayer = {
      name: UNASSIGNED_CF_OVERLAY_NAME,
      checked: true,
      subLayers: (
        <UnassignedCFOverlayv2
          filterRange={dateRange}
          onError={onError}
          onInfo={onInfo}
          unassignedCastData={unassignedCastData}
        />
      ),
    };
    return UnassignedCommunityFishersLayer;
  };

  const buildOverlayArray = () => {
    const overlayLayers = [];
    const assignedActiveOverlayLayer = generateActiveAssignedLayer();
    const assignedDecommissionedOverlayLayer =
      generateDecommissionedAssignedLayer();
    if (assignedActiveOverlayLayer) {
      overlayLayers.push(assignedActiveOverlayLayer);
    }
    if (assignedDecommissionedOverlayLayer) {
      overlayLayers.push(assignedDecommissionedOverlayLayer);
    }
    const unAssignedOverlayLayer = generateUnassignedLayer();
    overlayLayers.push(unAssignedOverlayLayer);
    return overlayLayers;
  };

  const overlayArray = buildOverlayArray();

  useEffect(() => {
    getCastData();
    // map must be loaded
    if (!map?.current) {
      return;
    }

    // set events and set map if previous state exists
    map.current.on('moveend', storeLocation);
    map.current.on('overlayadd', handleOverlayChange);
    map.current.on('overlayremove', handleOverlayChange);
    setLocation();
  }, [map, getCastData, setLocation, storeLocation]);

  return (
    <CastDataProvider>
      <SimpleMap
        isCFMap
        height={height}
        width={width}
        overlayLayers={overlayArray}
        mapId={GEOSPATIAL_MAP_ID}
        initialZoom={4}
        center={[66, -95]}
        defaultLayerName="Open Street"
        shouldRenderCastSearch
        displayCastLayer={displayCastLayer}
        displayUnassignedCastLayer={displayUnassignedCastLayer}
        assignedCastData={assignedCastData}
        unAssignedCastData={unassignedCastData}
      >
        <DataProductCartFAB
          showDialog={displayDataProductCartDialog}
          setShowDialog={setDisplayDataProductCartDialog}
        />
        <LassoFAB
          assignedCastData={assignedCastDataWithPolygons}
          unassignedCastData={unassignedCastData}
          showCastSelectionDialog={showDataProductSelectionDialog}
          setSelectedLayers={setSelectedLayers}
          onError={onError}
        />
        <HelpFAB />
        <FilterFAB dateRange={dateRange} setDateRange={setDateRange} />
        <DataProductSelection
          open={displayDataProductSelectionDialog}
          onClose={() => setDisplayDataProductSelectionDialog(false)}
          onGenerateDataProducts={showDataProductCartDialog}
          updateSiteDeviceSubsetRowsContextFromCheckboxState={
            updateSiteDeviceSubsetRowsContextFromCheckboxState
          }
        />
      </SimpleMap>
    </CastDataProvider>
  );
};

const value = { mapRef: createRef(), mapId: GEOSPATIAL_MAP_ID };
const GeospatialSearchProvider = (props) => (
  <MapContext.Provider value={value}>
    <SelectedStationsProvider>
      <SiteDeviceSubsetRowsProvider>
        <GeospatialSearch {...props} />
      </SiteDeviceSubsetRowsProvider>
    </SelectedStationsProvider>
  </MapContext.Provider>
);

export default withSnackbars(GeospatialSearchProvider);
