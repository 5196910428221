import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import DevicePortRO from 'domain/AppComponents/device-port/DevicePortRO';
import DevicePortRW from 'domain/AppComponents/device-port/DevicePortRW';

import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import Environment from 'util/Environment';

const STYLES = (theme) => ({
  entryFormContainer: {
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  roDivider: {
    marginTop: theme.spacing(2),
  },
});

const defaultState = {};

class DevicePort extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      entryFormContainer: PropTypes.string,
      formButtons: PropTypes.string,
    }),
    portId: PropTypes.number,
    deviceId: PropTypes.number,
    history: PropTypes.shape().isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    classes: undefined,
    portId: undefined,
    deviceId: undefined,
  };

  constructor(props) {
    super(props);
    const { portId, deviceId } = this.props;
    this.state = {
      portId,
      deviceId,
      ...defaultState,
    };
  }

  render() {
    const { portId, deviceId, onInfo, onError, history } = this.props;

    if (Environment.getDmasUserPrivilege() === 'RW') {
      return (
        <DevicePortRW
          portId={portId}
          deviceId={deviceId}
          history={history}
          onInfo={onInfo}
          onError={onError}
        />
      );
    }
    return (
      <DevicePortRO portId={portId} deviceId={deviceId} history={history} />
    );
  }
}
export default withStyles(STYLES)(withSnackbars(DevicePort));
