import {
  TableRowDetail,
  Table as DXTable,
} from '@devexpress/dx-react-grid-material-ui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { withStyles, withTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { Link, Paper, Typography } from 'base-components';

import SortableTable from 'library/CompositeComponents/table/SortableTable';
import Environment from 'util/Environment';
import DOITriggerTable from './DOITriggerTable';

const styles = (theme) => ({
  headingStyle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  table: {
    paddingBottom: theme.spacing(2),
  },
});

const RowDetail = ({ row }) => {
  if (row.provenance) {
    return (
      <Paper>
        <DOITriggerTable data={row.provenance.triggers} />
      </Paper>
    );
  }
  return null;
};

const ToggleCellComponent = ({ row, ...restProps }) => {
  if (row.provenance && row.provenance.triggers.length > 0) {
    return <TableRowDetail.ToggleCell row={row} {...restProps} />;
  }
  return <DXTable.Cell />;
};

ToggleCellComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.objectOf(PropTypes.any).isRequired,
};

RowDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.objectOf(PropTypes.any).isRequired,
};

const DOIHistoryTable = (props) => {
  const { match, data, classes, currentDOI, theme } = props;
  const isThinWindow = useMediaQuery(theme.breakpoints.down('xl'));
  const getRowId = (row) => row.id;

  const renderLinkText = (doi, truncateSize) => {
    if (isThinWindow && doi.length >= truncateSize) {
      return `${doi.slice(0, truncateSize)}...`;
    }
    return doi;
  };

  const renderRows = () =>
    data.map((row) => ({
      id: row.doi,
      doi: (
        <Link
          href={`${Environment.getDmasUrl()}${match.path.substring(
            match.path.lastIndexOf('/')
          )}?doidataset=${row.doi}`}
        >
          {renderLinkText(row.doi, 17)}
        </Link>
      ),
      createdDate: row.createdDate.replace(' ', '\n'),
      reason: row.provenance ? row.provenance.reason : '',
      provenance: row.provenance,
    }));

  const COLUMNS = [
    { name: 'doi', title: 'DOI' },
    { name: 'reason', title: 'Reason' },
    { name: 'createdDate', title: 'DOI Generation Date' },
  ];
  const SORTING = [{ columnName: 'createdDate', direction: 'desc' }];
  const DISABLED_SORTING = [
    { columnName: 'doi', sortingEnabled: false },
    { columnName: 'reason', sortingEnabled: false },
  ];
  const FORMATTING = [
    { columnName: 'createdDate', align: 'right', wordWrapEnabled: true },
    { columnName: 'reason', wordWrapEnabled: true },
  ];
  const PAGE_SIZE = 5;

  return (
    <>
      <Typography className={classes.headingStyle} variant="h6">
        Version History
      </Typography>
      <SortableTable
        className={classes.table}
        columns={COLUMNS}
        rows={renderRows()}
        pageSize={PAGE_SIZE}
        sort={SORTING}
        disabledSort={DISABLED_SORTING}
        columnExtensions={FORMATTING}
        highlighted
        getRowId={getRowId}
        selection={[currentDOI]}
        hasDetail
        RowDetail={RowDetail}
        toggleCellComponent={ToggleCellComponent}
      />
    </>
  );
};

DOIHistoryTable.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doi: PropTypes.string.isRequired,
      createdDate: PropTypes.string.isRequired,
    })
  ).isRequired,
  classes: PropTypes.shape({
    table: PropTypes.string,
    headingStyle: PropTypes.string,
  }).isRequired,
  currentDOI: PropTypes.string.isRequired,
  theme: PropTypes.shape().isRequired,
};
export default withTheme(withStyles(styles)(DOIHistoryTable));
