import { useCallback } from 'react';
import { withStyles } from '@mui/styles';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import FormField from './FormField';
import SortableLink from '../network-console/SortableLink';

const styles = (theme) => ({
  table: {
    'overflow-x': 'hidden',
    margin: theme.spacing(1),
    minWidth: theme.spacing(75),
  },
});

const headers = [
  { title: 'Form Field ID', name: 'formFieldId' },
  { title: 'Form Field Lable', name: 'fieldLabel' },
  { title: 'Description', name: 'description' },
  { title: 'Display Infomation', name: 'displayInfo' },
  { title: 'Form Field Code', name: 'fieldCode' },
  { title: 'Allow Any', name: 'allowAny' },
  { title: 'Form Field Documentation', name: 'formFieldDocumentation' },
  { title: 'Form Field Type', name: 'fieldType' },
  { title: 'Modified By', name: 'modifyBy' },
  { title: 'Modified Date', name: 'modifyDate' },
  { title: 'Form Field ID For Search', name: 'formFieldIdForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'formFieldId',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'fieldLabel',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'description',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'displayInfo',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'fieldCode',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'allowAny',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'formFieldDocumentation',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'fieldType',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'modifyBy',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'modifyDate',
    width: ColumnInfo.mini,
  },
  {
    columnName: 'formFieldIdForSearch',
    width: ColumnInfo.mini,
  },
];

type FormFieldTableProps = {
  userHasWritePermission?: boolean;
  classes: { table: string };
  formFields: FormField[] | null;
  openAndSaveEditData: (form: FormField) => void;
};

interface FormFieldRowInfo extends FormField {
  formFieldId?: any;
  formFieldIdForSearch?: number | string;
}
const FormFieldTable = ({
  userHasWritePermission = false,
  classes,
  formFields,
  openAndSaveEditData,
}: FormFieldTableProps) => {
  const isLoading = formFields === null;

  const buildTableRows = useCallback(() => {
    if (!formFields) {
      return [];
    }
    let newTableRows = formFields;
    newTableRows = newTableRows.map((form) => {
      const returnValue: FormFieldRowInfo = { ...form };
      userHasWritePermission
        ? (returnValue.formFieldId = (
            <SortableLink
              id={form.formFieldId}
              onClick={() => openAndSaveEditData(form)}
            />
          ))
        : (returnValue.formFieldId = form.formFieldId);
      returnValue.fieldLabel = form.fieldLabel;
      returnValue.description = form.description;
      returnValue.displayInfo = form.displayInfo;
      returnValue.modifyDate = form.modifyDate;
      returnValue.fieldCode = form.fieldCode;
      returnValue.allowAny = form.allowAny;
      returnValue.formFieldDocumentation = form.formFieldDocumentation;
      returnValue.fieldType = form.fieldType;
      returnValue.formFieldIdForSearch = form.formFieldId;
      return returnValue;
    });
    return newTableRows;
  }, [formFields, openAndSaveEditData, userHasWritePermission]);

  const tableRows = buildTableRows();

  return (
    <>
      <SortableTable
        columns={headers}
        columnSizes={tableColumnExtensions}
        columnExtensions={tableColumnExtensions}
        elevation={0}
        pageSize={15}
        pageSizes={[15, 30, 60, 300, 0]}
        rows={tableRows}
        searchable
        searchBarMoveable
        stripedRows
        classes={classes.table}
        hiddenColumns={['formFieldIdForSearch']}
        isLoading={isLoading}
        sortExtensions={
          userHasWritePermission
            ? [
                {
                  columnName: 'formFieldId',
                  compare: (a, b) => (a.props.id > b.props.id ? 1 : -1),
                },
              ]
            : [
                {
                  columnName: 'formFieldId',
                  compare: (a, b) => (a > b ? 1 : -1),
                },
              ]
        }
      />
    </>
  );
};

export default withStyles(styles)(withSnackbars(FormFieldTable));
