import { useState, useEffect } from 'react';
import DeviceService from 'domain/services/DeviceService';

function useAllDevices(onError) {
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    async function getDevices() {
      const deviceList = [];
      await DeviceService.getAllDevices()
        .then((response) => {
          response.devices.map((record) =>
            deviceList.push({
              deviceId: record.deviceId,
              deviceCode: record.deviceCode,
              name: record.deviceName,
            })
          );
          setDevices(deviceList);
        })
        .catch((errorMessage) => {
          onError(errorMessage);
        });
      return deviceList;
    }
    Promise.resolve(getDevices()).then((result) => setDevices(result));
  }, [onError]);
  return devices;
}
export default useAllDevices;
