import { FC } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import Draggable from 'react-draggable';
import { Close } from '@onc/icons';
import { Dialog, DialogTitle, Grid, IconButton } from 'base-components';

export interface DraggableDialogProps
  extends Omit<
    DialogProps,
    'fullWidth' | 'fullScreen' | 'hideBackdrop' | 'disableEnforceFocus'
  > {
  /* Title of the dialog */
  title: string;
  /* Callback called when dialog is closed from the Close icon at the top right */
  onClose: () => void;
}

const DraggableDialog: FC<DraggableDialogProps> = ({
  open,
  title,
  onClose,
  maxWidth = 'lg',
  children = undefined,
  ...rest
}) => (
  <Draggable handle=".cf-draggable">
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={false}
      hideBackdrop
      disableEnforceFocus
      {...rest}
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        className="cf-draggable"
        sx={{ cursor: 'grab' }}
      >
        <Grid item>
          <DialogTitle>{title}</DialogTitle>
        </Grid>
        <Grid item>
          <IconButton
            sx={{ padding: '20px' }}
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      {children}
    </Dialog>
  </Draggable>
);

export default DraggableDialog;
