import { parseDmasAPIResponse, get } from 'util/WebRequest';

class DeviceStatusHelperService {
  static get() {
    return get('DeviceStatusHelperService', {}).then((response) =>
      parseDmasAPIResponse(response)
    );
  }
}
export default DeviceStatusHelperService;
