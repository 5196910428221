import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { ExpandMore as ExpandMoreIcon } from '@onc/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from 'base-components';
import { ConfirmationDialog } from 'domain/AppComponents/dialogs/Dialogs';
import {
  ModifyBy,
  ModifyDate,
} from 'domain/AppComponents/Form/Fields/ReadOnlyFields';
import { RORWTextField } from 'domain/AppComponents/Form/Fields/RORWFields';

import ElectricalRatingService from 'domain/services/ElectricalRatingService';
import {
  CreateButton,
  SaveButton,
  DeleteButton,
} from 'library/CompositeComponents/button/Buttons';
import ResponsiveDataGridLayout from 'library/CompositeComponents/grid-layout/ResponsiveDataGridLayout';
import Panel from 'library/CompositeComponents/panel/Panel';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

const styles = (theme) => ({
  accordionDetails: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  accordionSummary: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  deviceContainer: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  formButtons: {
    flexDirection: 'row-reverse',
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  modifyRoot: {
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  fieldsRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
});

const defaultState = {
  electricalRatingExists: true,
  openDeleteDialog: false,
  electricalRating: {
    electricalRatingId: '',
    nominalVoltage: '',
    nominalCurrent: '',
    peakCurrent: '',
    power: '',
    minVoltage: '',
    maxVoltage: '',
    quiescentCurrent: '',
    powerOnInrushCurrent: '',
    maxPower: '',
    highVoltageAlarmThreshold: '',
    lowVoltageAlarmThreshold: '',
    overCurrentLimit: '',
    overCurrentTime: '',
    initialGroundResistance: '',
    modifyBy: '',
    modifyDate: '',
  },
};

class ElectricalRatingPanel extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      accordionDetails: PropTypes.string,
      accordionSummary: PropTypes.string,
      deviceContainer: PropTypes.string,
      fieldsRoot: PropTypes.string,
      formButtons: PropTypes.string,
      modifyRoot: PropTypes.string,
      root: PropTypes.string,
      textField: PropTypes.string,
    }).isRequired,
    deviceId: PropTypes.number.isRequired,
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    permission: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = async () => {
    const { deviceId, onError } = this.props;
    await ElectricalRatingService.get(deviceId).then((response) => {
      if (response.data.statusCode === 0) {
        this.setState({ electricalRatingExists: true });
        this.fillForm(response.data.payload);
      } else if (response.data.statusCode === 106) {
        this.setState({ electricalRatingExists: false });
      } else {
        this.setState({ electricalRatingExists: false });
        onError(response.data.message);
      }
    });
  };

  deleteEventDialogue = () => {
    const {
      electricalRating: { electricalRatingId },
      openDeleteDialog,
    } = this.state;
    if (!openDeleteDialog) {
      return null;
    }
    return (
      <ConfirmationDialog
        open
        title="Delete Electrical Rating"
        content={`Are you sure you want to delete electrical rating ${electricalRatingId}?`}
        onCancel={this.handleCancel}
        onConfirm={this.handleDelete}
      />
    );
  };

  fillForm = (result) => {
    const { electricalRating, dmasUser } = result;
    const { firstname, lastname, dmasUserId } = dmasUser;
    this.setState({
      electricalRating: {
        ...electricalRating,
        modifyBy: `${firstname} ${lastname} (${dmasUserId})`,
      },
    });
  };

  fillButtons = (permission) => {
    const { electricalRatingExists } = this.state;

    if (permission !== 'RW') return null;
    if (electricalRatingExists) {
      return (
        <>
          <SaveButton onClick={this.onSave} />
          <DeleteButton
            onClick={() => this.setState({ openDeleteDialog: true })}
          />
        </>
      );
    }
    return <CreateButton onClick={this.onCreate} />;
  };

  handleCancel = () => {
    this.setState({ openDeleteDialog: false });
  };

  handleDelete = async () => {
    await this.onDelete();
    this.setState({
      openDeleteDialog: false,
      electricalRating: defaultState.electricalRating,
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { electricalRating } = this.state;
    this.setState({
      electricalRating: {
        ...electricalRating,
        [name]: value,
      },
    });
  };

  onCreate = async () => {
    const { electricalRating } = this.state;
    const { deviceId, onInfo, onError } = this.props;

    await ElectricalRatingService.save(deviceId, electricalRating)
      .then((response) => {
        onInfo(
          `New Electrical Rating created with id ${response.electricalRatingId}`
        );
        this.refreshData();
      })
      .catch((response) => {
        onError(response.message);
        this.refreshData();
      });
  };

  onDelete = async () => {
    const {
      electricalRating: { electricalRatingId },
    } = this.state;
    const { onInfo, onError } = this.props;

    await ElectricalRatingService.delete(electricalRatingId)
      .then(() => {
        onInfo(`Deleted Electrical Rating ${electricalRatingId}`);
        this.refreshData();
        this.setState({
          electricalRatingExists: false,
        });
      })
      .catch((response) => {
        onError(response.message);
        this.refreshData();
      });
  };

  onSave = async () => {
    const { electricalRating } = this.state;
    const { deviceId, onInfo, onError } = this.props;

    await ElectricalRatingService.update(deviceId, electricalRating)
      .then(() => {
        onInfo(
          `Updated Electrical Rating ${electricalRating.electricalRatingId}`
        );
        this.refreshData();
      })
      .catch((response) => {
        onError(response.message);
        this.refreshData();
      });
  };

  render() {
    const { deviceId, permission, classes } = this.props;
    const {
      electricalRatingExists,
      electricalRating: {
        electricalRatingId,
        nominalVoltage,
        nominalCurrent,
        peakCurrent,
        power,
        minVoltage,
        maxVoltage,
        quiescentCurrent,
        powerOnInrushCurrent,
        maxPower,
        highVoltageAlarmThreshold,
        lowVoltageAlarmThreshold,
        overCurrentLimit,
        overCurrentTime,
        initialGroundResistance,
        modifyDate,
        modifyBy,
      },
    } = this.state;

    if (!electricalRatingExists && permission !== 'RW') {
      return (
        <Panel
          title={
            <Typography variant="body1">
              No Electrical Rating found for device {deviceId}
            </Typography>
          }
        />
      );
    }

    let electricalRatingIdText;
    let color;
    if (electricalRatingExists) {
      electricalRatingIdText = electricalRatingId;
    } else {
      electricalRatingIdText = 'NaN';
      color = 'textSecondary';
    }

    return (
      <>
        {this.deleteEventDialogue()}
        <Panel
          title={
            <>
              <Typography variant="body1">Electrical Rating ID: </Typography>
              <Typography variant="body1" color={color}>
                {electricalRatingIdText}
              </Typography>
            </>
          }
        >
          <div className={classes.fieldsRoot}>
            <ResponsiveDataGridLayout
              formFields={[
                <RORWTextField
                  name="nominalVoltage"
                  translationKey="electricalRating.nominalVoltage"
                  onChange={this.onChange}
                  value={nominalVoltage}
                  permission={permission}
                  classes={{ textField: classes.textField }}
                />,
                <RORWTextField
                  name="nominalCurrent"
                  translationKey="electricalRating.nominalCurrent"
                  onChange={this.onChange}
                  value={nominalCurrent}
                  permission={permission}
                  classes={{ textField: classes.textField }}
                />,
                <RORWTextField
                  name="peakCurrent"
                  translationKey="electricalRating.peakCurrent"
                  onChange={this.onChange}
                  value={peakCurrent}
                  permission={permission}
                  classes={{ textField: classes.textField }}
                />,
                <RORWTextField
                  name="power"
                  translationKey="device.power"
                  onChange={this.onChange}
                  value={power}
                  permission={permission}
                  classes={{ textField: classes.textField }}
                />,
                <RORWTextField
                  name="minVoltage"
                  translationKey="electricalRating.minVoltage"
                  onChange={this.onChange}
                  value={minVoltage}
                  permission={permission}
                  classes={{ textField: classes.textField }}
                />,
                <RORWTextField
                  name="maxVoltage"
                  translationKey="electricalRating.maxVoltage"
                  onChange={this.onChange}
                  value={maxVoltage}
                  permission={permission}
                  classes={{ textField: classes.textField }}
                />,
              ]}
            />
          </div>
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>Additional Information</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <ResponsiveDataGridLayout
                  formFields={[
                    <RORWTextField
                      name="quiescentCurrent"
                      translationKey="electricalRating.quiescentCurrent"
                      onChange={this.onChange}
                      value={quiescentCurrent}
                      permission={permission}
                      classes={{ textField: classes.textField }}
                    />,
                    <RORWTextField
                      name="powerOnInrushCurrent"
                      translationKey="electricalRating.powerOnInrushCurrent"
                      onChange={this.onChange}
                      value={powerOnInrushCurrent}
                      permission={permission}
                      classes={{ textField: classes.textField }}
                    />,
                    <RORWTextField
                      name="maxPower"
                      translationKey="electricalRating.maxPower"
                      onChange={this.onChange}
                      value={maxPower}
                      permission={permission}
                      classes={{ textField: classes.textField }}
                    />,
                    <RORWTextField
                      name="highVoltageAlarmThreshold"
                      translationKey="electricalRating.highVoltageAlarmThreshold"
                      onChange={this.onChange}
                      value={highVoltageAlarmThreshold}
                      permission={permission}
                      classes={{ textField: classes.textField }}
                    />,
                    <RORWTextField
                      name="lowVoltageAlarmThreshold"
                      translationKey="electricalRating.lowVoltageAlarmThreshold"
                      onChange={this.onChange}
                      value={lowVoltageAlarmThreshold}
                      permission={permission}
                      classes={{ textField: classes.textField }}
                    />,
                    <RORWTextField
                      name="overCurrentLimit"
                      translationKey="electricalRating.overCurrentLimit"
                      onChange={this.onChange}
                      value={overCurrentLimit}
                      permission={permission}
                      classes={{ textField: classes.textField }}
                    />,
                    <RORWTextField
                      name="overCurrentTime"
                      translationKey="electricalRating.overCurrentTime"
                      onChange={this.onChange}
                      value={overCurrentTime}
                      permission={permission}
                      classes={{ textField: classes.textField }}
                    />,
                    <RORWTextField
                      name="initialGroundResistance"
                      translationKey="electricalRating.initialGroundResistance"
                      onChange={this.onChange}
                      value={initialGroundResistance}
                      permission={permission}
                      classes={{ textField: classes.textField }}
                    />,
                  ]}
                />
              </AccordionDetails>
            </Accordion>
          </div>
          <ModifyBy
            username={modifyBy}
            classes={{ root: classes.modifyRoot }}
          />
          <ModifyDate
            date={modifyDate}
            classes={{ root: classes.modifyRoot }}
          />
          <div className={classes.formButtons}>
            {this.fillButtons(permission)}
          </div>
        </Panel>
      </>
    );
  }
}

export default withStyles(styles)(withSnackbars(ElectricalRatingPanel));
