import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Dropdown,
  Tooltip,
  Typography,
} from 'base-components';
import AdFileService from 'domain/services/AdFileService';
import FileManagementService from 'domain/services/FileManagementService';

import {
  CancelButton,
  ContainedButton,
  TextButton,
} from 'library/CompositeComponents/button/Buttons';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import ColumnInfo from 'library/CompositeComponents/table/ColumnInfo';
import SortableTable from 'library/CompositeComponents/table/SortableTable';
import Environment from 'util/Environment';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)})`,
    minWidth: '400px',
  },
  justifyRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  exportTitle: {
    backgroundColor: theme.palette.primary.light,
    '& h2': {
      color: 'white',
    },
  },
});

const fileStateArray = [
  { label: 'Select', value: 0 },
  { label: 'Processed (T)', value: 1 },
  { label: 'Ready to Process (P)', value: 2 },
  { label: 'CF Cast (C)', value: 3 },
  { label: 'Excluded (E)', value: 4 },
];

const headers = [
  { title: 'File Id', name: 'fileId' },
  { title: 'File Name', name: 'fileName' },
  { title: 'File State', name: 'fileState' },
  { title: 'Download Size', name: 'fileSize' },
  { title: 'Device Name', name: 'deviceName' },
  { title: 'Remove File', name: 'removeFile' },
  { title: 'File Name For Search', name: 'fileNameForSearch' },
];

const tableColumnExtensions = [
  {
    columnName: 'fileId',
    width: ColumnInfo.medium,
    align: 'left',
  },
  {
    columnName: 'fileName',
    width: ColumnInfo.large,
    align: 'left',
  },
  {
    columnName: 'fileState',
    width: ColumnInfo.mini,
    align: 'left',
  },
  {
    columnName: 'fileSize',
    width: ColumnInfo.large,
    align: 'left',
  },
  {
    columnName: 'deviceName',
    width: ColumnInfo.large,
    align: 'left',
  },
  {
    columnName: 'removeFile',
    width: ColumnInfo.small,
    align: 'left',
  },
  {
    columnName: 'fileNameForSearch',
    width: ColumnInfo.mini,
    align: 'left',
  },
];

const defaultState = {
  open: false,
  currentFileStateValue: 0,
};

class FileManagementActionPage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      removeAllButton: PropTypes.string,
      exportTitle: PropTypes.string,
      justifyRight: PropTypes.string,
      justifyCenter: PropTypes.string,
    }).isRequired,
    onError: PropTypes.func.isRequired,
    rowsForActionsTab: PropTypes.arrayOf(PropTypes.shape({})),
    selections: PropTypes.arrayOf(PropTypes.number),
    removeSelectedRow: PropTypes.func,
    removeAllRows: PropTypes.func,
    getDeviceFiles: PropTypes.func,
    startIndex: PropTypes.number,
    numberofrecords: PropTypes.number,
  };

  static defaultProps = {
    removeSelectedRow: () => {},
    removeAllRows: () => {},
    getDeviceFiles: () => {},
    rowsForActionsTab: [],
    selections: [],
    startIndex: 0,
    numberofrecords: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  handleFileStateChange = (event) => {
    this.setState({ currentFileStateValue: event.target.value });
  };

  handleEstimatedDownloadSize = () => {
    const { rowsForActionsTab } = this.props;
    let estimatedSize = 0;
    for (const actionRow of rowsForActionsTab) {
      estimatedSize += actionRow.unCompressedSize;
    }
    return estimatedSize;
  };

  setUpDeleteButton = (rowToDelete) => {
    const { removeSelectedRow } = this.props;
    return (
      <ContainedButton
        translationKey="common.buttons.remove"
        onClick={() => {
          removeSelectedRow(rowToDelete.fileId);
        }}
      />
    );
  };

  buildTableRows = () => {
    const { rowsForActionsTab } = this.props;
    return rowsForActionsTab.map((rowForActionsTab) => ({
      fileId: rowForActionsTab.fileId,
      fileName: rowForActionsTab.fileName,
      fileState: rowForActionsTab.fileState,
      fileSize: rowForActionsTab.unCompressedSize.toLocaleString(),
      deviceName: rowForActionsTab.deviceName,
      removeFile: this.setUpDeleteButton(rowForActionsTab),
      key: rowForActionsTab.fileId.toString(),
      fileNameForSearch: rowForActionsTab.fileNameForSearch,
    }));
  };

  handleFileStateButtonPress = () => {
    this.setState({ open: true });
  };

  handleFileStateUpdate = async () => {
    const { selections, getDeviceFiles, onError, startIndex, numberofrecords } =
      this.props;
    const { currentFileStateValue } = this.state;
    let currentFileStateLetter = '';
    switch (currentFileStateValue) {
      case 1:
        currentFileStateLetter = 'T';
        break;
      case 2:
        currentFileStateLetter = 'P';
        break;
      case 3:
        currentFileStateLetter = 'C';
        break;
      case 4:
        currentFileStateLetter = 'E';
        break;
      default:
        currentFileStateLetter = '';
        break;
    }
    try {
      await FileManagementService.getFileStateChange(
        selections.toString(),
        currentFileStateLetter
      );
      getDeviceFiles(startIndex, numberofrecords, currentFileStateLetter);
      this.handleClose();
    } catch (e) {
      onError(`Failed to change file state: ${e.message}`);
      this.handleClose();
    }
  };

  handleClose = () => this.setState({ open: false });

  handleFileDownloads = () => {
    const { rowsForActionsTab, onError } = this.props;
    const selectedFileNames = [];
    for (const row of rowsForActionsTab) {
      selectedFileNames.push(row.fileNameForSearch);
    }

    AdFileService.getAllDownloadFiles(selectedFileNames, false, onError);
  };

  handleFileArchiveDownloads = () => {
    const { rowsForActionsTab, onError } = this.props;
    const selectedFileNames = [];
    for (const row of rowsForActionsTab) {
      selectedFileNames.push(row.fileNameForSearch);
    }

    AdFileService.getAllDownloadFiles(selectedFileNames, true, onError);
  };

  renderRemoveAllButton = (disabled) => {
    const { removeAllRows } = this.props;
    if (disabled) {
      return (
        <Tooltip title="No records to remove">
          <span>
            <ContainedButton
              translationKey="fileManagement.removeAllFromList"
              disabled
            />
          </span>
        </Tooltip>
      );
    }
    return (
      <ContainedButton
        translationKey="fileManagement.removeAllFromList"
        onClick={removeAllRows}
      />
    );
  };

  renderChangeFileStateButton = (disabled) => {
    const permission = Environment.getDmasUserPrivilege();
    if (disabled || permission === 'RO') {
      return (
        <Tooltip
          title={
            disabled ? 'No records to change' : 'Requires read/write permission'
          }
        >
          <span>
            <ContainedButton
              translationKey="fileManagement.changeFileState"
              disabled
            />
          </span>
        </Tooltip>
      );
    }
    return (
      <ContainedButton
        translationKey="fileManagement.changeFileState"
        onClick={this.handleFileStateButtonPress}
      />
    );
  };

  renderDownloadFileButton = (disabled) => {
    if (disabled) {
      return (
        <Tooltip title="No records to download">
          <span>
            <ContainedButton
              translationKey="fileManagement.downloadSelectedFiles"
              disabled
            />
          </span>
        </Tooltip>
      );
    }
    return (
      <ContainedButton
        translationKey="fileManagement.downloadSelectedFiles"
        onClick={this.handleFileDownloads}
      />
    );
  };

  renderDownloadFileAsArchiveButton = (disabled) => {
    if (disabled) {
      return (
        <Tooltip title="No records to download">
          <span>
            <ContainedButton
              translationKey="fileManagement.downloadSelectedFilesAsArchiveReady"
              disabled
            />
          </span>
        </Tooltip>
      );
    }
    return (
      <ContainedButton
        translationKey="fileManagement.downloadSelectedFilesAsArchiveReady"
        onClick={this.handleFileArchiveDownloads}
      />
    );
  };

  renderUpdateButton = () => {
    const { currentFileStateValue } = this.state;
    if (currentFileStateValue === 0) {
      return (
        <Tooltip title="Please select a state" position="top">
          <span>
            <TextButton translationKey="common.buttons.update" disabled />
          </span>
        </Tooltip>
      );
    }
    return (
      <TextButton
        translationKey="common.buttons.update"
        onClick={this.handleFileStateUpdate}
      />
    );
  };

  render() {
    const { classes, rowsForActionsTab } = this.props;
    const { open, currentFileStateValue } = this.state;
    const rows = this.buildTableRows();

    const disabled = rowsForActionsTab.length === 0;
    return (
      <div className="parentContainer">
        <Dialog fullWidth open={open} onClose={this.handleClose}>
          <DialogTitle className={classes.exportTitle}>
            Edit File State(s)
          </DialogTitle>
          <DialogContent>
            <Typography> New File State</Typography>
            <Dropdown
              options={fileStateArray}
              fullwidth
              value={currentFileStateValue}
              onChange={this.handleFileStateChange}
              className={classes.justifyRight}
            />
            <CancelButton onClick={this.handleClose} />
            {this.renderUpdateButton()}
          </DialogContent>
        </Dialog>
        <div className="actionTable">
          <SortableTable
            columns={headers}
            columnSizes={tableColumnExtensions}
            columnExtensions={tableColumnExtensions}
            elevation={0}
            pageSize={15}
            pageSizes={[15, 30, 60, 150, 300]}
            rows={rows}
            searchable
            stripedRows
            hiddenColumns={['fileNameForSearch']}
          />
          <div className={classes.justifyRight}>
            {this.renderRemoveAllButton(disabled)}
          </div>
        </div>
        <div className="estimatedSize">
          <Typography variant="h6">
            Estimated download size: {this.handleEstimatedDownloadSize()} Bytes
            in {rowsForActionsTab.length} files.
          </Typography>
        </div>
        <div className={classes.justifyCenter}>
          {this.renderChangeFileStateButton(disabled)}
          {this.renderDownloadFileButton(disabled)}
          {this.renderDownloadFileAsArchiveButton(disabled)}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbars(FileManagementActionPage));
