import React from 'react';
import DataSourceSelectionWidgetDisplay from './DataSourceSelectionWidgetDisplay';

interface DataSourceSelectionWidgetProps {
  title: string;
}

const DataSourceSelectionWidget: React.VFC<DataSourceSelectionWidgetProps> = ({
  title,
}) => <DataSourceSelectionWidgetDisplay title={title} />;
export default DataSourceSelectionWidget;
