import { useEffect, useContext } from 'react';
import { Typography } from '@mui/material';
import { ErrorAlert, Loading } from '@onc/composite-components';
import { Add } from '@onc/icons';
import {
  TaxonomyContext,
  UserPrivilegeContext,
} from 'domain/Apps/taxonomy/TaxonomyApp';
import TaxonomyTreeItem from 'domain/Apps/taxonomy/TaxonomyTreeItem';
import TaxonomyService from 'domain/services/TaxonomyService';
import { ContainedButton } from 'library/CompositeComponents/button/Buttons';
import TreeMenu from 'library/CompositeComponents/tree-menu/TreeMenu';
import TreeMenuItem from 'library/CompositeComponents/tree-menu/TreeMenuItem';
import { useSnackbars } from 'util/hooks/useSnackbars';
import useWebService from 'util/hooks/useWebService';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../library/CompositeComponents/dashboard/DashboardTypes';
import Widget from '../../library/CompositeComponents/dashboard/Widget';

export interface TaxonomyItem {
  editable: boolean;
  modifiedBy: number;
  modifiedDate: string;
  description: string;
  referenceUrl: string;
  taxonomyCode: string;
  taxonomyId: number;
  taxonomyMatrixId: number;
  taxonomyName: string;
  taxonomyTopUrl: string;
  version: string;
}

export interface TaxonItem {
  allowedAttributes: any[];
  commonName: string;
  deprecated: boolean;
  englishNames: string[];
  information: string;
  jsonTaxonData: string;
  modifiedBy: number;
  modifiedDate: string;
  nodeTypeId: number;
  originTaxonomyId: number;
  referenceId: string;
  referenceUrl: string;
  taxonId: number;
  taxonomyMatrixId: number;
  version: string;
}

const TaxonomyTreeWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { onError } = useSnackbars();

  const userPrivilege = useContext(UserPrivilegeContext);

  const [taxonomies, , fetchTaxonomies] = useWebService({
    method: TaxonomyService.getAll,
    onError,
  });

  const {
    setTaxon,
    setTaxonomy,
    matrixId,
    setMatrixId,
    selectedTaxonomyMatrixId,
    setRefreshTaxonomies,
  } = useContext(TaxonomyContext);

  useEffect(() => {
    fetchTaxonomies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (matrixId !== 0 && taxonomies) {
      if (taxonomies.find((txy) => txy.taxonomyMatrixId === matrixId)) {
        fetchTaxonomies();
        setMatrixId(0);
        setRefreshTaxonomies(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixId]);

  const handleAddButtonClick = () => {
    setTaxonomy({
      editable: true,
      description: '',
      referenceUrl: '',
      taxonomyCode: '',
      taxonomyName: '',
      taxonomyTopUrl: '',
      version: '1',
      modifiedBy: null,
      modifiedDate: null,
      taxonomyId: null,
      taxonomyMatrixId: null,
    });
    setTaxon(null);
  };

  const renderAddUserDefinedTaxonomyButton = () =>
    userPrivilege === 'RW'
      ? [
          <ContainedButton
            translationKey="taxonomy.addUserDefinedTaxonomy"
            startIcon={<Add />}
            onClick={handleAddButtonClick}
          />,
        ]
      : [];

  const renderTaxonomies = () => {
    taxonomies.sort((taxonomyA: TaxonomyItem, taxonomyB: TaxonomyItem) =>
      taxonomyA.taxonomyName.localeCompare(taxonomyB.taxonomyName)
    );
    const importedTaxonomies = [];
    const userDefinedTaxonomies = [];
    const createTaxonomyTreeItem = (taxonomy: TaxonomyItem) => (
      <TaxonomyTreeItem
        key={taxonomy.taxonomyMatrixId}
        divTitle={`taxonomy-${taxonomy.taxonomyName}-${taxonomy.taxonomyMatrixId}`}
        taxonomy={taxonomy}
        taxon={null}
        selectedTaxonomyMatrixId={selectedTaxonomyMatrixId}
        onError={onError}
      />
    );
    taxonomies.forEach((taxonomy: TaxonomyItem) => {
      if (!taxonomy.editable) {
        importedTaxonomies.push(createTaxonomyTreeItem(taxonomy));
      } else {
        userDefinedTaxonomies.push(createTaxonomyTreeItem(taxonomy));
      }
    });
    return (
      <div>
        <TreeMenuItem
          label={<Typography variant="body1">Imported Taxonomies</Typography>}
          key={0}
          expand
        >
          {importedTaxonomies}
        </TreeMenuItem>
        <TreeMenuItem
          label={
            <Typography variant="body1">User-Defined Taxonomies</Typography>
          }
          key={1}
          expand
        >
          {userDefinedTaxonomies}
        </TreeMenuItem>
      </div>
    );
  };

  if (taxonomies === null) {
    return (
      <ErrorAlert title="Error">
        There was a problem getting the taxonomies.
      </ErrorAlert>
    );
  }

  if (taxonomies === undefined) {
    return <Loading />;
  }
  if (!taxonomies) {
    return <TreeMenu />;
  }
  return (
    <Widget
      title=""
      ariaLabel="taxonomy-tree"
      actionComponents={renderAddUserDefinedTaxonomyButton()}
      hideHeader={userPrivilege !== 'RW'}
      {...props}
    >
      <TreeMenu>{renderTaxonomies()}</TreeMenu>
    </Widget>
  );
};
TaxonomyTreeWidget.widgetKey = 'taxonomy-tree';
TaxonomyTreeWidget.widgetTitle = 'TaxonomyTree';
TaxonomyTreeWidget.defaultDataGrid = {
  i: 'taxonomy-tree',
  x: 0,
  y: 0,
  w: 3,
  h: 5,
};
export default TaxonomyTreeWidget;
