/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import * as React from 'react';
import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import moment from 'moment';
import {
  Box,
  Divider,
  LabelledCheckbox,
  LoadingButton,
  type QuickEntryButton,
} from 'base-components';
import { TaxonOption } from 'domain/AppComponents/dropdowns/TaxonAsyncAutocomplete';
import ManualEntryAttributes, {
  AttributeLine,
  EntryAttributesSection,
} from 'domain/AppComponents/manual-entry/ManualEntryAttributes';
import ManualEntryGeneral, {
  EntryGeneralSection,
} from 'domain/AppComponents/manual-entry/ManualEntryGeneral';
import Util, {
  AnnotationFormData,
} from 'domain/AppComponents/manual-entry/ManualEntryLogic';
import ManualEntryResource, {
  EntryResourceSection,
} from 'domain/AppComponents/manual-entry/ManualEntryResource';
import SeaTubeResourceTypes from 'domain/Apps/seatube/util/SeaTubeResourceTypes';
import AnnotationService from 'domain/services/AnnotationService';
import TaxonAttributeService from 'domain/services/TaxonAttributeService';
import TaxonomyAttributeService from 'domain/services/TaxonomyAttributeService';
import { TextButton } from 'library/CompositeComponents/button/Buttons';
import { useSnackbars } from 'util/hooks/useSnackbars';
import { useSessionStorage } from 'util/hooks/useStorage';
import useWebService from 'util/hooks/useWebService';
import AnnotationDrawer from './AnnotationDrawer';
import SaveWarningDialog from './dialogs/SaveWarningDialog';
import TableAnnotation, {
  Attribute,
} from '../annotation-table/TableAnnotation';
import SeaTubeConfigContext from '../SeaTubeConfigContext';
import SeaTubeLogContext, { SeaTubeLogType } from '../SeaTubeLogContext';

const useStyles = makeStyles((theme: Theme) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  hFlex: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    overflow: 'auto',
  },
  overflow: {
    flexGrow: 1,
    overflow: 'auto',
    paddingBottom: theme.spacing(),
  },
  buttonBar: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  divider: {
    marginTop: 'auto',
  },
}));

export type ManualEntryFormProps = {
  quickButton: QuickEntryButton;
  editAnnotation: TableAnnotation;
  defaultResourceType?: any;
  defaultResource?: any;
  currentTimestamp: string;
  showSidebar: boolean;
  onSubmitSuccess: (annotation: AnnotationFormData) => void;
  onClearEditedAnnotation: () => void;
};

export type ManualEntryFormType = {
  resourceSection: EntryResourceSection;
  generalSection: EntryGeneralSection;
  attributesSection: EntryAttributesSection;
};

export type ManualEntryErrors = {
  dateError?: string;
  attributeErrors?: any;
  taxonError?: string;
  resourceTypeError?: string;
  resourceError?: string;
  startDateError?: string;
  endDateError?: string;
};

const ManualEntryForm: React.FC<ManualEntryFormProps> = ({
  quickButton,
  defaultResourceType = null,
  defaultResource = null,
  editAnnotation,
  currentTimestamp,
  showSidebar,
  onSubmitSuccess,
  onClearEditedAnnotation,
}: ManualEntryFormProps) => {
  const { startDate, endDate, cruiseId, logType, dive, cruise } =
    useContext(SeaTubeLogContext);
  const { annotationEntry: config } = useContext(SeaTubeConfigContext);
  const classes = useStyles();
  const { onError, onInfo } = useSnackbars();

  const { nullableAttributes, keepTaxonomy } = config;

  const getDefaultResourceSection = useCallback(() => {
    if (cruise && cruiseId > 0) {
      return Util.getDefaultResource(config, undefined, cruiseId);
    }
    // Clearing when using Dive Log
    if (dive && cruiseId > 0) {
      return Util.getDefaultResource(config, undefined, -1, dive);
    }
    return {
      resourceTypeId: defaultResourceType,
      resourceId: defaultResource,
    };
  }, [cruise, dive, config, defaultResourceType, defaultResource]);

  const emptyForm = useMemo(
    () => ({
      resourceSection: getDefaultResourceSection(),
      generalSection: {
        date: null,
        time: null,
        taxonomyId: null,
        taxon: null,
        comment: '',
        toBeReviewed: false,
      },
      attributesSection: {
        attributes: [],
        touched: [],
      },
    }),
    [getDefaultResourceSection]
  );

  // AnnotationService (Saving annotations)
  const [, loading, submit] = useWebService({
    method: AnnotationService.saveAnnotation,
    onError,
  });

  // TaxonomyAttributeService (getting attributes)
  const [attributeOptions, , fetchAttributeOptions] = useWebService({
    method: TaxonomyAttributeService.getAllSelectable,
    onError,
  });

  // TaxonAttributeService (getting attrs for taxon)
  const [, , fetchAttributes] = useWebService({
    method: TaxonAttributeService.getAttributes,
    onError,
  });

  const logId = dive ? dive.diveId : cruise.cruiseId;

  const [storedDrafts, storeDrafts] = useSessionStorage(
    `${logType}-${logId}-draft-annotations`,
    null
  );

  const [storedIndex, storeIndex] = useSessionStorage(
    `${logType}-${logId}-selected-draft`,
    null
  );

  const [formState, setFormState] = useState<ManualEntryFormType>(
    storedDrafts?.[storedIndex] || emptyForm
  );

  const [formList, setFormList] = useState<ManualEntryFormType[]>(
    storedDrafts || [formState]
  );

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(storedIndex || 0);

  const [errors, setErrors] = useState<ManualEntryErrors>({
    dateError: undefined,
    attributeErrors: {},
    taxonError: undefined,
  });

  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (currentIndex <= formList.length - 1) {
      storeIndex(currentIndex);
      setFormState(formList[currentIndex]);
    }
  }, [currentIndex]);

  useEffect(() => {
    storeDrafts(formList);
  }, [formList]);

  useEffect(() => {
    if (currentIndex <= formList.length - 1) {
      const updatedFormList = _.cloneDeep(formList);
      updatedFormList[currentIndex] = formState;
      setFormList(updatedFormList);
    }
    if (attemptedSubmit) {
      setErrors(
        Util.performFormValidation(
          formState,
          moment.utc(startDate),
          moment.utc(endDate),
          nullableAttributes
        )
      );
    }
  }, [formState]);

  const handleClear = (keepTime: boolean, keepTaxonomyField = false) => {
    setFormState({
      resourceSection: emptyForm.resourceSection,
      generalSection: {
        ...emptyForm.generalSection,
        taxonomyId: keepTaxonomyField
          ? formState.generalSection.taxonomyId
          : null,
        date: keepTime ? formState.generalSection.date : null,
      },
      attributesSection: emptyForm.attributesSection,
    });
  };

  const handleRemoveAnnotation = (index: number) => {
    if (formList[index].generalSection.annotationId) {
      onClearEditedAnnotation();
    }
    if (formList.length === 1) {
      return handleClear(false, keepTaxonomy);
    }
    const updatedFormList = formList.filter((section, i) => i !== index);
    setFormList(updatedFormList);

    if (currentIndex > index || currentIndex > updatedFormList.length - 1) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setFormState(updatedFormList[currentIndex]);
    }
    return null;
  };

  const handleAddAnnotation = (form: ManualEntryFormType = undefined) => {
    setFormList([...formList, form || emptyForm]);

    setCurrentIndex(formList.length);
  };

  const handleReplaceAnnotation = (
    index: number,
    form: ManualEntryFormType
  ) => {
    // Don't replace if the annotation is the same as the one being replaced (being loaded from session storage)
    const oldAnnotationId = formList[index]?.generalSection?.annotationId;
    const newAnnotationId = form.generalSection.annotationId;
    if (oldAnnotationId === newAnnotationId) return;

    // Replace the annotation
    const updatedFormList = _.cloneDeep(formList);
    updatedFormList[index] = form;
    setFormList(updatedFormList);
    setFormState(form);
    setCurrentIndex(index);
  };

  const handleSubmit = async (
    form: ManualEntryFormType,
    clearForm: boolean,
    keepTime: boolean
  ) => {
    try {
      if (
        form?.attributesSection.attributes.length &&
        !form.generalSection.taxon
      ) {
        setOpenWarningDialog(true);
        return;
      }
      const formErrors = Util.performFormValidation(
        form,
        moment.utc(startDate),
        moment.utc(endDate),
        nullableAttributes
      );
      setAttemptedSubmit(true);
      setErrors(formErrors);
      if (!_.isEmpty(formErrors)) {
        onError('Could not save annotation. The form contains errors.');
        return;
      }
      const annotation = Util.convertFormToServiceData(form);
      setIsSaving(true);
      // send the async request
      const result = submit(annotation);

      // wait for the request's response to arrive
      await result.then((response) => {
        if (response.data.statusCode === 0) {
          setAttemptedSubmit(false);
          setIsSaving(false);
          if (onSubmitSuccess) {
            onSubmitSuccess(annotation);
          }
          setOpenWarningDialog(false);
          if (clearForm) {
            keepTime
              ? handleClear(keepTime, keepTaxonomy)
              : handleRemoveAnnotation(currentIndex);
          }
          onInfo('Annotation Saved');
        } else {
          onError('Error saving Annotation');
        }
      });
    } catch (error) {
      onError('Error saving Annotation');
    }
  };

  useEffect(() => {
    fetchAttributeOptions();
  }, []);

  useEffect(() => {
    const { taxonomyId } = formState.generalSection;
    const updatedForm = _.cloneDeep(formState);
    // Clear the attributes if taxonomy has been cleared
    if (!taxonomyId) {
      updatedForm.attributesSection.attributes = [];
      updatedForm.generalSection.taxon = null;
      return setFormState(updatedForm);
    }
    // Get the default resource for the selected taxonomy
    if (config) {
      const updatedResource = Util.getDefaultResource(
        config,
        taxonomyId,
        cruiseId,
        dive
      );
      if (updatedResource) {
        updatedForm.resourceSection = updatedResource;
      }
    }
    return setFormState(updatedForm);
  }, [formState?.generalSection?.taxonomyId, config]);

  // Listen for annotation from AnnotationTable or List
  useEffect(() => {
    // Clear form if edit annotation changed to null
    if (editAnnotation === null) {
      formList.forEach((form, index) => {
        if (form.generalSection.annotationId) {
          handleRemoveAnnotation(index);
        }
      });
      return undefined;
    }

    const updatedForm = _.cloneDeep(formState);

    if (editAnnotation && attributeOptions) {
      updatedForm.resourceSection = {
        resourceId: editAnnotation.resourceId,
        resourceTypeId: editAnnotation.resourceTypeId,
      };

      // use null if the annotation has no taxon
      const originalTaxon: EntryGeneralSection['taxon'] = editAnnotation.taxonId
        ? {
            taxonId: editAnnotation.taxonId,
            value: editAnnotation.taxonId,
            label: editAnnotation.taxon,
          }
        : null;

      updatedForm.generalSection = {
        annotationId: editAnnotation.id,
        date: editAnnotation.startDate,
        comment: editAnnotation.comment,
        taxonomyId: editAnnotation.taxonomyId,
        taxon: originalTaxon,
        toBeReviewed: editAnnotation.toBeReviewed,
      };

      updatedForm.attributesSection = {
        attributes: Util.convertAttributesToForm(
          editAnnotation.attributes,
          attributeOptions
        ).map((attr) => ({ ...attr, touched: true })),
      };
      const annotationFound = formList.some((form, index) => {
        if (form.generalSection.annotationId) {
          handleReplaceAnnotation(index, updatedForm);
          return true;
        }
        return false;
      });

      if (!annotationFound) {
        handleAddAnnotation(updatedForm);
      }
    }
    return undefined;
  }, [editAnnotation, attributeOptions]);

  // Listen for quick button presses
  useEffect(
    () => {
      if (quickButton && attributeOptions) {
        const updatedForm = _.cloneDeep(formState);
        const { resourceSection, generalSection, attributesSection } =
          updatedForm;
        let attributeLines: AttributeLine[] = [];

        if (quickButton.attributes?.length > 0) {
          // Convert QuickButtonAttributes to Attributes
          const attributes: Attribute[] = quickButton.attributes.map(
            (attr) => ({
              ...attr,
              attributeId: attr.taxonomyAttributeId,
              value: attr.attributeValue,
              name: '',
              groupId: -1,
              groupName: 'unknown',
              dataType: 'String',
            })
          );

          // Convert Attributes to Attribute Lines treat them as touched
          // since this is similar to a user entering them
          const newLines = Util.convertAttributesToForm(
            attributes,
            attributeOptions
          ).map((attr) => ({ ...attr, touched: !quickButton.taxonId }));

          // If using a taxon button, use replacement logic
          if (quickButton.taxonId) {
            attributeLines = Util.replaceAttributes(
              attributesSection.attributes,
              newLines
            );
            // Otherwise, use additive logic
          } else {
            attributeLines = Util.addAttributes(
              attributesSection.attributes,
              newLines
            );
          }
          attributesSection.attributes = attributeLines;
          generalSection.comment = Util.getCommentFromAttributes(attributes);
          const resourceTypeId = Util.getResourceTypeFromAttributes(attributes);
          resourceSection.resourceTypeId =
            resourceTypeId || resourceSection.resourceTypeId;
          if (resourceSection.resourceTypeId === SeaTubeResourceTypes.DIVE) {
            resourceSection.resourceId = dive?.diveId;
          }
        }
        if (quickButton.taxonId) {
          generalSection.taxonomyId = quickButton.taxonomyId;
          generalSection.taxon = {
            value: quickButton.taxonId,
            taxonId: quickButton.taxonId,
            label: quickButton.taxonName || 'n/a',
          };
          generalSection.taxonName = quickButton.taxonName;
        }
        generalSection.comment = quickButton.comment || generalSection.comment;
        if (quickButton.quickSave) {
          const { resourceTypeId } = resourceSection;
          const pageContext =
            logType === SeaTubeLogType.EXPEDITION
              ? SeaTubeResourceTypes.EXPEDITION
              : SeaTubeResourceTypes.DIVE;
          // Check that the taxon's default resource type is valid
          // on the current page
          if (
            pageContext !== resourceTypeId &&
            [
              SeaTubeResourceTypes.EXPEDITION,
              SeaTubeResourceTypes.DIVE,
            ].includes(resourceTypeId)
          ) {
            onError(
              'The taxon on this button has an invalid resource type for this log.'
            );
            return;
          }
          // Check that the current time falls in the expedition's
          // time range
          const newDate = moment.utc();
          if (!newDate.isBetween(moment(startDate), moment(endDate))) {
            onError(
              "The quick save's date does not fall in the expedition's date range."
            );
            return;
          }
          generalSection.date = newDate.toISOString();
          handleSubmit(
            {
              resourceSection,
              generalSection,
              attributesSection: {
                attributes: attributeLines,
              },
            },
            false,
            false
          );
        } else {
          setFormState(updatedForm);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quickButton, attributeOptions]
  );

  const handleToReview = (event: React.ChangeEvent<any>, checked: boolean) => {
    const updatedForm = _.cloneDeep(formState);
    updatedForm.generalSection.toBeReviewed = checked;
    setFormState(updatedForm);
  };

  const handleTaxonChange = async (val: TaxonOption) => {
    const updatedForm = await Util.handleTaxonFormChange(
      val,
      formState,
      fetchAttributes,
      attributeOptions,
      dive
    );
    setFormState(updatedForm);
  };

  const handleResourceSectionChange = useCallback(
    (updatedSection: EntryResourceSection) => {
      setFormState((prevState) =>
        Util.handleFormSectionChange(
          prevState,
          'resourceSection',
          updatedSection
        )
      );
    },
    []
  );

  const handleGeneralSectionChange = useCallback(
    (updatedSection: EntryGeneralSection) => {
      setFormState((prevState) =>
        Util.handleFormSectionChange(
          prevState,
          'generalSection',
          updatedSection
        )
      );
    },
    []
  );

  const handleAttributesSectionChange = useCallback(
    (updatedSection: EntryAttributesSection) => {
      setFormState((prevState) =>
        Util.handleFormSectionChange(
          prevState,
          'attributesSection',
          updatedSection
        )
      );
    },
    []
  );

  const mode =
    editAnnotation &&
    editAnnotation.id === formState?.generalSection?.annotationId
      ? 'edit'
      : 'create';

  return (
    <>
      <div className={classes.flexContainer}>
        <Divider />
        <div className={classes.hFlex}>
          {showSidebar && (
            <AnnotationDrawer
              onAdd={handleAddAnnotation}
              onDelete={handleRemoveAnnotation}
              onChange={setCurrentIndex}
              onOrderChange={setFormList}
              currentIndex={currentIndex}
              annotations={formList}
            />
          )}
          <Divider flexItem orientation="vertical" />
          <Box className={classes.overflow}>
            <ManualEntryResource
              value={formState.resourceSection}
              onChange={handleResourceSectionChange}
              onErrorChange={setErrors}
              errors={errors}
            />
            <ManualEntryGeneral
              errors={errors}
              value={formState.generalSection}
              onChange={handleGeneralSectionChange}
              onTaxonChange={handleTaxonChange}
              onErrorChange={setErrors}
              minDate={moment.utc(startDate)}
              maxDate={moment.utc(endDate)}
              currentTimestamp={currentTimestamp}
            />
            <ManualEntryAttributes
              value={formState.attributesSection}
              onChange={handleAttributesSectionChange}
              attributeOptions={attributeOptions}
              errors={errors}
            />
          </Box>
        </div>

        <div className={classes.divider}>
          <Divider />
          <div className={classes.buttonBar}>
            <LoadingButton
              loading={loading}
              variant="contained"
              translationKey="common.buttons.save"
              onClick={() => handleSubmit(formState, true, false)}
            />
            <LoadingButton
              loading={loading}
              variant="outlined"
              translationKey="seatube.saveAndKeepTime"
              onClick={() => {
                handleSubmit(formState, true, true);
              }}
            />
            <TextButton
              onClick={() => {
                if (mode === 'edit') {
                  onClearEditedAnnotation();
                } else {
                  handleClear(false);
                }
              }}
              translationKey={
                mode === 'edit'
                  ? 'common.buttons.cancel'
                  : 'common.buttons.clear'
              }
            />
            <LabelledCheckbox
              label="To Be Reviewed"
              style={{ marginLeft: '8px', marginRight: 'auto' }}
              value={formState.generalSection.toBeReviewed}
              onChange={handleToReview}
            />
            <SaveWarningDialog
              open={openWarningDialog}
              title="Attribute Warning"
              message="Attributes will not be saved without a taxon.  Do you still want to save?"
              isSaving={isSaving}
              onCancel={() => setOpenWarningDialog(false)}
              onSubmit={() => {
                handleSubmit(
                  {
                    resourceSection: formState.resourceSection,
                    generalSection: formState.generalSection,
                    attributesSection: emptyForm.attributesSection,
                  },
                  true,
                  false
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManualEntryForm;
