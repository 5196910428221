import { useState, useEffect, ReactNode, KeyboardEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { MoreHoriz } from '@onc/icons';
import { Box, Collapse, Paper } from 'base-components';
import PanelHeader from './PanelHeader';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '&:hover $handle': {
      opacity: 0.5,
      pointerEvents: 'auto',
    },
  },
  headerContainer: {
    position: 'relative',
    width: '100%',
  },
  reducedContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    margin: '0 auto',
  },
  handle: {
    position: 'absolute',
    bottom: -16,
    opacity: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    height: '16px',
    width: '40px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  childrenWrapper: {
    width: '100%',
    overflow: 'auto',
    flexGrow: 1,
  },
  childrenWrapperOverflow: {
    width: '100%',
    flexGrow: 1,
  },
  title: {
    color: 'white',
  },
}));

interface PanelProps {
  ariaRole?: string;
  ariaLabel?: string;
  children?: ReactNode;
  title?: ReactNode;
  titleComponents?: ReactNode;
  onBackButtonClick?: () => void;
  actionContent?: ReactNode;
  menu?: ReactNode;
  width?: string;
  height?: string;
  menuProps?: object;
  headerDraggable?: boolean;
  loading?: boolean;
  enableOverflow?: boolean;
  readOnly?: boolean;
  id?: string;
  hideHeader?: boolean;
  className?: string;
  collapseHeader?: boolean;
  reduceHeader?: boolean;
}

const Panel = ({
  ariaRole = 'region',
  ariaLabel = '',
  className = '',
  title = undefined,
  titleComponents = undefined,
  onBackButtonClick = undefined,
  actionContent = undefined,
  menu = undefined,
  width = 'inherit',
  height = 'inherit',
  children = undefined,
  menuProps = undefined,
  headerDraggable = false,
  loading = false,
  enableOverflow = false,
  readOnly = false,
  id = 'onc-panel-content',
  hideHeader = false,
  collapseHeader = undefined,
  reduceHeader = false,
}: PanelProps) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(collapseHeader);
  const [isHeaderPinned, setIsHeaderPinned] = useState(collapseHeader);
  const [isCollapsed, setIsCollapsed] = useState(!collapseHeader);

  const classes = useStyles();

  // Update the collapsible header if widget config changed
  useEffect(() => {
    setIsHeaderVisible(collapseHeader);
    setIsHeaderPinned(collapseHeader);
    setIsCollapsed(!collapseHeader);
  }, [collapseHeader]);

  const showHeader = () => {
    setIsHeaderVisible(true);
  };

  const handleHideHeader = () => {
    setIsHeaderVisible(false);
  };

  const toggleHeaderVisibility = () => {
    setIsHeaderVisible((prev) => !prev);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      toggleHeaderVisibility();
    }
  };

  const renderHandle = () =>
    isCollapsed && collapseHeader !== undefined ? (
      <Paper
        className={classes.handle}
        elevation={1}
        tabIndex={0}
        aria-label="Toggle Header"
        role="button"
        onClick={toggleHeaderVisibility}
        onKeyDown={handleKeyDown}
      >
        <MoreHoriz color="action" />
      </Paper>
    ) : null;

  const renderHeader = () => {
    if (
      !(
        title ||
        onBackButtonClick ||
        actionContent ||
        menu ||
        titleComponents
      ) ||
      hideHeader
    ) {
      return null;
    }

    return (
      <div
        className={
          reduceHeader ? classes.reducedContainer : classes.headerContainer
        }
        onMouseEnter={showHeader}
        onMouseLeave={isHeaderPinned ? null : handleHideHeader}
        aria-label="headerContainer"
        style={
          // Overlay panel header if collapsed
          isHeaderPinned === false
            ? {
                position: 'absolute',
                zIndex: '1000',
                left: 0,
                right: 0,
              }
            : undefined
        }
      >
        <Collapse
          in={isHeaderVisible || collapseHeader === undefined}
          onExited={isHeaderPinned ? undefined : () => setIsCollapsed(true)}
          onEntered={isHeaderPinned ? undefined : () => setIsCollapsed(false)}
          easing={{
            enter: 'linear',
            exit: 'ease-in-out',
          }}
          timeout={{ enter: 250, exit: 1500 }}
        >
          <PanelHeader
            title={title}
            ariaLabel={ariaLabel}
            collapseHeader={collapseHeader}
            titleComponents={
              <div
                className={
                  collapseHeader !== undefined && !isHeaderPinned
                    ? classes.title
                    : ''
                }
              >
                {titleComponents}
              </div>
            }
            onBackButtonClick={onBackButtonClick}
            actionContent={actionContent}
            menu={menu}
            menuProps={menuProps}
            headerDraggable={headerDraggable}
            loading={loading}
            readOnly={readOnly}
            isHeaderPinned={isHeaderPinned}
            setIsHeaderPinned={setIsHeaderPinned}
          />
        </Collapse>
        {renderHandle()}
      </div>
    );
  };

  const renderChildren = () => {
    const overflow = enableOverflow
      ? classes.childrenWrapperOverflow
      : classes.childrenWrapper;
    return (
      <div className={`${overflow} onc-panel-content`} id={id}>
        {children}
      </div>
    );
  };

  return (
    <Paper
      className={`panel-paper ${classes.root} ${className}`.trim()}
      aria-label={ariaLabel || (typeof title === 'string' ? title : undefined)}
      role={ariaRole}
      style={{ height, width }}
      id={`widget-${id}`}
    >
      {reduceHeader ? (
        <Box sx={{ maxWidth: 800, width: '100%', margin: '0 auto' }}>
          {renderHeader()}
        </Box>
      ) : (
        renderHeader()
      )}

      {renderChildren()}
    </Paper>
  );
};

export default Panel;
