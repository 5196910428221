const dateConstants = {
  SECONDS_PER_DAY: 86400,
  SECONDS_PER_HOUR: 3600,
  SECONDS_PER_MINUTE: 60,
  MILLISECONDS_PER_DAY: 86400000,
  MILLISECONDS_PER_HOUR: 3600000,
  MILLISECONDS_PER_MINUTE: 60000,
};

export default function calculateUptime(startStr, endStr) {
  if (!(endStr && startStr)) {
    return null;
  }

  const startDate = new Date(startStr);
  const endDate = new Date(endStr);
  if (endDate < startDate) {
    return null;
  }

  let difference = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);

  const c = dateConstants;

  let result = '';
  const days = Math.floor(difference / c.SECONDS_PER_DAY);
  difference -= days * c.SECONDS_PER_DAY;
  let hours = Math.floor(difference / c.SECONDS_PER_HOUR);
  difference -= hours * c.SECONDS_PER_HOUR;
  let minutes = Math.floor(difference / c.SECONDS_PER_MINUTE);
  difference -= minutes * c.SECONDS_PER_MINUTE;
  let seconds = difference;
  if (days > 0) {
    result += `${days} day`;
    if (days > 1) {
      result += 's';
    }
    result += ', ';
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  result += `${hours}:${minutes}:${seconds}`;
  return result;
}
