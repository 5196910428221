import { useContext } from 'react';
import {
  LayerGroup,
  LeafletIcon,
  Marker,
  LeafletTooltip as Tooltip,
  Polyline,
} from 'base-components';
import ONCMap from 'domain/AppComponents/map/ONCMap';
import BroadcastChannel from 'domain/Widgets/BroadcastChannel';
import Widget from 'library/CompositeComponents/dashboard/Widget';
import useBroadcast from 'util/hooks/useBroadcast';
import EarthquakeContext from './EarthquakeContext';
import {
  DashboardWidget,
  DashboardWidgetProps,
} from '../../../library/CompositeComponents/dashboard/DashboardTypes';

const ONC_MAP_ID = 'onc-map';
const EarthquakeMapWidget: DashboardWidget = (props: DashboardWidgetProps) => {
  const { id } = props;

  const { earthquakes, siteMap, detectionArea, dashboardId } =
    useContext(EarthquakeContext);

  const [earthquakeList] = useBroadcast<any>(
    dashboardId,
    BroadcastChannel.EarthquakeList,
    earthquakes,
    id
  );

  const renderMarkerConfig = () => ({
    onClick: () => {},
    name: 'Earthquakes',
    checked: true,
    markers: earthquakeList.map((eq) => ({
      markerId: eq.earthquakeId,
      lon: eq.longitude,
      lat: eq.latitude,
      tooltip: eq.earthquakeId.toString(),
    })),
  });

  const renderSiteMarkerConfig = () =>
    siteMap.map((site) => (
      <Marker
        key={site.location}
        position={[site.latitude, site.longitude]}
        icon={LeafletIcon.GREY}
      >
        <Tooltip permanent={false}>{site.location}</Tooltip>
      </Marker>
    ));

  const renderSiteLayer = () => (
    <LayerGroup>{renderSiteMarkerConfig()}</LayerGroup>
  );

  const renderOverlayLayers = () => {
    const overlayLayers = [];
    overlayLayers.push({
      name: 'Sites',
      subLayers: renderSiteLayer(),
      checked: true,
    });
    overlayLayers.push({
      name: 'Detection Area',
      subLayers: (
        <LayerGroup>
          <Polyline positions={detectionArea} color="red" opacity={0.7} />
        </LayerGroup>
      ),
      checked: true,
    });
    return overlayLayers;
  };

  return (
    <Widget key={id} title="Earthquake Map" {...props}>
      <ONCMap
        zoomControl={{ position: 'topleft' }}
        layersControl={{ position: 'topright' }}
        initialZoom={6}
        center={[49, -127]}
        mapId={ONC_MAP_ID}
        bathymetry={false}
        markerConfig={renderMarkerConfig()}
        overlayLayers={renderOverlayLayers()}
      />
    </Widget>
  );
};

EarthquakeMapWidget.widgetKey = 'earthquake-map';
EarthquakeMapWidget.widgetTitle = 'Earthquake Map';
EarthquakeMapWidget.defaultDataGrid = {
  i: 'earthquake-map',
  x: 0,
  y: 0,
  w: 4,
  h: 7,
};

export default EarthquakeMapWidget;
