import { Component } from 'react';
import { Grid, TextField } from 'base-components';

type Props = {
  setGreaterThan: (event: any) => void;
  setLessThan: (event: any) => void;
  value: any;
};
type State = any;
class NumberRangeField extends Component<Props, State> {
  render() {
    const { value, setGreaterThan, setLessThan } = this.props;

    const greaterThan = value && value.greaterThan ? value.greaterThan : '';
    const lessThan = value && value.lessThan ? value.lessThan : '';

    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            translationKey="seatube.gtFilter"
            type="number"
            name="greaterThan"
            value={greaterThan}
            onChange={(e) => setGreaterThan(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            translationKey="seatube.ltFilter"
            type="number"
            name="lessThan"
            value={lessThan}
            onChange={(e) => setLessThan(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }
}

export default NumberRangeField;
