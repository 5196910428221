import PropTypes from 'prop-types';

import OpenInNewLink from 'library/CompositeComponents/link/OpenInNewLink';
import Environment from 'util/Environment';

const getUrl = (ids) => {
  const { deviceId, siteDeviceId } = ids;

  if (deviceId) {
    return `${Environment.getDmasUrl()}/SiteDevice?deviceId=${deviceId}`;
  }
  if (siteDeviceId) {
    return `${Environment.getDmasUrl()}/SiteDevice?siteDeviceId=${siteDeviceId}`;
  }
  return `${Environment.getDmasUrl()}/SiteDevice`;
};

const openSiteDevice = (props) => {
  global.open(getUrl(props), '_self');
};

const handleOpenSiteDevice = (props) => () => {
  openSiteDevice(props);
};

const SiteDeviceLink = ({
  children = undefined,
  deviceId = undefined,
  siteDeviceId = undefined,
}) => (
  <OpenInNewLink href={getUrl({ deviceId, siteDeviceId })}>
    {children}
  </OpenInNewLink>
);

SiteDeviceLink.propTypes = {
  children: PropTypes.node,
  siteDeviceId: PropTypes.number,
  deviceId: PropTypes.number,
};

export { handleOpenSiteDevice, openSiteDevice };
export default SiteDeviceLink;
