import * as React from 'react';
import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';

export interface FixedColumnProps {
  leftColumns: string[] | undefined;
  rightColumns: string[] | undefined;
}

const FixedColumn: React.VFC<FixedColumnProps> = (props: FixedColumnProps) => {
  const { leftColumns, rightColumns } = props;
  return (
    <TableFixedColumns leftColumns={leftColumns} rightColumns={rightColumns} />
  );
};
export default FixedColumn;
