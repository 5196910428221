import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import {
  renderTimeViewClock,
  DesktopDateTimePicker,
} from '@mui/x-date-pickers';
import { useTranslation, TranslationType } from '@onc/i18n';
import moment, { Moment } from 'moment';

export interface DateTimePickerProps {
  /**
   * Input label from the json file
   * util/i18n/src/translation/en/translation.json TODO: Create an
   * input-specific translation file?
   */
  translationKey: TranslationType;

  /** The class name of the date time picker for custom styling */
  className?: string;

  /** When set to true the field will be unclickable and greyed out */
  disabled?: boolean;

  /** The maximum date and time that can be selected */
  maxDateTime?: Moment;

  /** The minimum date and time that can be selected */
  minDateTime?: Moment;

  /**
   * The value of the date time picker. If null, the date time picker will be
   * empty
   */
  value?: Moment | null;

  /**
   * Function to call when the date time picker value is changed, name is the
   * name of the date time picker
   */
  onChange?: (newValue: Moment | null, name: string) => void;

  /** Function to call when the date time picker value is invalid */
  onError?: (error: string | null, val: any) => void;

  /** Helper text to display below the date time picker */
  helperText?: string;

  /** Renders red highlighting on the field */
  error?: boolean;

  /** If true, the date time picker will take up the full width of its container */
  fullWidth?: boolean;

  /**
   * If true, the date time picker will display an asterisk indicating it is
   * required
   */
  required?: boolean;

  /** Function run on focus on the date time picker */
  onFocus?: () => void;

  /** Function run on blur on the date time picker */
  onBlur?: () => void;

  /**
   * The name of the date time picker. Most of the time this isn't needed, but
   * structures like Filters need a name to identify the components contributing
   * to the filter.
   */
  name?: string;

  /** If true, the date time picker will display milliseconds in the time picker */
  useMilliseconds?: boolean;

  /** If true, dates in the future are not allowed */
  disableFuture?: boolean;

  /** If true, dates in the past are not allowed */
  disablePast?: boolean;

  readOnly?: boolean;
}

/* A DateTimePicker component that uses the Material UI DateTimePicker */
const DateTimePicker = ({
  translationKey,
  className = undefined,
  disabled = false,
  error = undefined,
  fullWidth = false,
  helperText = undefined,
  maxDateTime = undefined,
  minDateTime = undefined,
  useMilliseconds = false,
  required = false,
  name = undefined,
  value = undefined,
  onBlur = undefined,
  onChange = undefined,
  onError = () => {},
  onFocus = undefined,
  disableFuture = false,
  disablePast = false,
  readOnly = false,
}: DateTimePickerProps) => {
  const { t: translate } = useTranslation();

  // both specified date restrictions and built-in date restrictions should be respected
  const earliestDate = moment.utc('1900-01-01');
  const latestDate = moment.utc().add(25, 'years');

  // DMAS-80232 - Our Form (Formik) component is setting the value to a string when the field is empty, which goes against the typing in this file
  // But there's nothing we can really do about that since it's all JSX, so we need to handle it here.
  const [milliseconds, setMilliseconds] = useState(
    typeof value === 'string' || !value
      ? '000'
      : value?.milliseconds().toString().padStart(3, '0')
  );

  // DMAS-82524 - Helper text for default input restrictions
  const [errorMessage, setErrorMessage] = useState<string>('');

  const DISPLAY_FORMAT = 'YYYY-MM-DD HH:mm:ss';

  // Update the milliseconds when a value that has milliseconds is passed in
  // Ignoring the case where milliseconds is 0 is needed because changing the date field
  // will set milliseconds to 0
  useEffect(() => {
    // If typing was respected, this wouldn't be necessary
    if (typeof value === 'string') {
      return;
    }
    if (value?.isValid() && value?.milliseconds() !== 0) {
      setMilliseconds(value.milliseconds().toString().padStart(3, '0'));
    }
  }, [value]);

  const handleError = (dateError, val) => {
    // both specified error handling and built-in date restriction should be respected
    const callError = (e, v) => {
      onError(e, v);
      setErrorMessage(e || '');
    };
    switch (dateError) {
      case 'minTime':
      case 'minDate':
        callError(
          `Cannot be before ${minDateTime?.isAfter(earliestDate) ? minDateTime.format(DISPLAY_FORMAT) : earliestDate.format(DISPLAY_FORMAT)}`,
          val
        );
        break;
      case 'maxTime':
      case 'maxDate':
        callError(
          `Cannot be after ${maxDateTime?.isBefore(latestDate) ? maxDateTime.format(DISPLAY_FORMAT) : latestDate.format(DISPLAY_FORMAT)}`,
          val
        );
        break;
      case 'invalidDate':
        callError('Invalid date', val);
        break;
      case 'invalidMilliseconds':
        callError('Milliseconds is invalid', val);
        break;
      case 'disableFuture':
        callError('Future dates are not allowed', val);
        break;
      case 'disablePast':
        callError('Past dates are not allowed', val);
        break;
      default:
        callError(null, val);
    }
  };

  const handleMillisecondsChange = (e) => {
    const milli = e.target.value;
    setMilliseconds(milli);
    const newValue = value;
    newValue.set('milliseconds', Number(milli));
    if (isNaN(Number(milli))) {
      handleError('invalidMilliseconds', milli);
      const invalidMoment = moment.invalid();
      onChange(invalidMoment, name);
    } else {
      onChange(newValue, name);
    }
  };

  const renderDateTimePicker = () => (
    <DesktopDateTimePicker
      name={name}
      ampm={false}
      views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
      format={DISPLAY_FORMAT}
      slotProps={{
        textField: {
          variant: 'filled',
          fullWidth,
          // respect custom error handling and built-in date restrictions
          helperText: errorMessage || helperText,
          error: errorMessage !== '' || error,
          onFocus,
          onBlur,
          sx: {
            minWidth: useMilliseconds ? '250px' : undefined,
            maxWidth: useMilliseconds ? '250px' : undefined,
          },
          required,
        },
      }}
      className={className}
      disabled={disabled}
      label={translate(translationKey)}
      maxDateTime={maxDateTime?.isBefore(latestDate) ? maxDateTime : latestDate}
      minDateTime={
        minDateTime?.isAfter(earliestDate) ? minDateTime : earliestDate
      }
      value={typeof value === 'string' ? null : value}
      timezone="UTC"
      onChange={(val, context) => {
        val.set('milliseconds', Number(milliseconds));
        if (context.validationError !== null) {
          handleError(context.validationError, val);
        }
        onChange(val, name);
      }}
      onError={handleError}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
      disableFuture={disableFuture}
      disablePast={disablePast}
      readOnly={readOnly}
    />
  );

  const renderMilliDateTimePicker = () => (
    <div style={{ display: 'flex', alignItems: 'top' }}>
      {renderDateTimePicker()}
      <TextField
        variant="filled"
        label="ms"
        value={milliseconds}
        onChange={handleMillisecondsChange}
        onBlur={() => {
          setMilliseconds(milliseconds.padStart(3, '0'));
        }}
        error={error}
        fullWidth={fullWidth}
        inputProps={{ maxLength: 3 }}
        sx={{ width: fullWidth ? '100%' : '70px', marginLeft: '8px' }}
      />
    </div>
  );

  return useMilliseconds ? renderMilliDateTimePicker() : renderDateTimePicker();
};

export default DateTimePicker;
