import moment from 'moment';

/*
 * One of:
 *     a) An optional minus sign, then either:
 *         one or more digits, possibly followed by a decimal point and one or more digits,
 *             e.g. 2, -42, 3.14, 0.99, -00000.12345
 *         or a decimal point followed by one or more digits.
 *             e.g. .999, -.123
 *     b) "ERROR"
 *     c) Nothing.
 */
const valueRegexStr = '(?:(?:-?(?:\\d+(?:\\.\\d+)?|\\.\\d+))|ERROR|)';
/* eslint-disable prefer-template */
const navdataRegex = new RegExp( //
  '(?:,|<)' + // a symbol before the username (crudely targets both formats since the line isn't parsed yet)
    '\\s*(?:okexnav|webbpinner|Sealog)\\s*' + // users known to produce navigation data
    '(?:,|>)' + // a symbol after the username
    '.*' + // anything after the username but before navdata
    '(?:' + // non-capturing group
    '(?:LAT|LON|HEADING|DEPTH|ALT|TEMP|SAL|DO)+' + //
    '\\s*:+\\s*' + // one or more colons surrounded by any amount of whitespace
    '\\d*\\s*' + // any number of digits followed by any amount of whitespace; e.g. the 3 in "DO :: 3         4.558 mg/L"
    valueRegexStr + // see its comment above
    '\\s*[a-zA-Z/]*' + // any amount of whitespace followed by any number of letters or slashes; for units
    ')+' // one or more of this group
);
/* eslint-enable prefer-template */

export default class IngestionUtil {
  static getDateFromFileName = (fileName) => {
    // Looks for either YYYYMMDD or YYYY-MM-DD
    const match = fileName.match(/_(\d{8})\.|(\d{4}-\d{2}-\d{2})/);
    if (match && match.length > 2) {
      const [, group1, group2] = match;
      const parsedDate = moment(group1 || group2, ['YYYYMMDD', 'YYYY-MM-DD']);
      if (parsedDate.isValid()) {
        return parsedDate;
      }
    }
    return null;
  };

  static toLines(content: string): string[] {
    let lines: string[] = [];

    // Seems to split with each in order, so order matters.
    // Doesn't use '?' for the same reason. Must split on the larger separators first,
    // or a part may get split twice.
    lines = content.split(/\r\n|\n\r|\n|\r/);

    return lines;
  }

  static readFile(
    file: File,
    timeoutMs: number,
    readDelayMs = 0 // originally intended for testing
  ): Promise<string[]> {
    // a timeout promise to keep the function from taking too long
    const timeout = new Promise<string[]>((resolve, reject) => {
      setTimeout(() => reject(new Error('File read timed out.')), timeoutMs);
    });

    const promise = new Promise<string[]>((resolve) => {
      const fileReader = new FileReader();

      // when readAsText finishes with the file, the result will be in fileReader.result
      fileReader.onloadend = () => {
        resolve(IngestionUtil.toLines(String(fileReader.result)));
      };

      setTimeout(() => fileReader.readAsText(file), readDelayMs);
    });

    // reject with the timeout if the read takes too long
    return Promise.race([promise, timeout]);
  }

  static hasNavigationData(line: string): boolean {
    return navdataRegex.test(line);
  }
}
