import { useState, useEffect, useCallback } from 'react';

/**
 * Custom hook that manages and tracks the window's focus state.
 *
 * This hook sets up event listeners for the 'focus' and 'blur' events on the
 * window, and updates the focus state accordingly. It also calls a provided
 * callback function, if any, whenever the window gains focus. The focus state
 * is returned as a boolean value.
 *
 * @param {Function} [onFocusCallback] - Optional callback function to be
 *   executed when the window gains focus.
 * @returns {boolean} Focused - Indicates whether the window is currently
 *   focused.
 */
const useWindowFocus = (onFocusCallback?: () => void) => {
  const [focused, setFocused] = useState(true);

  const handleFocus = useCallback(() => {
    onFocusCallback?.();
    setFocused(true);
  }, [onFocusCallback]);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [handleFocus, handleBlur]);

  return focused;
};

export default useWindowFocus;
